namespace aq.deviceManagement {
    export class DeviceDetailTopology {

        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private device,
            private dataStatuses
        ) {
            $scope.$on('DEVICE_UPDATED', (event, device) => {
                this.device = device;
            });
        }

        public onDeviceChange(event) {
            this.$scope.$emit('DEVICE_UPDATED', event.device);
        }
    }

    angular
        .module('deviceManagement')
        .controller('DeviceDetailTopology', DeviceDetailTopology);
}

