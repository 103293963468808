var aq;
(function (aq) {
    var dashboard;
    (function (dashboard) {
        var widgets;
        (function (widgets) {
            var TableEditService = /** @class */ (function (_super) {
                __extends(TableEditService, _super);
                /* @ngInject */
                function TableEditService(DashboardOptionsService, $translate) {
                    var _this = _super.call(this, DashboardOptionsService, $translate) || this;
                    _this.DashboardOptionsService = DashboardOptionsService;
                    _this.$translate = $translate;
                    _this.TREND_APPEND = '_TREND';
                    _this.getMeasureUnit = function (measure) {
                        var unit = _this.DashboardOptionsService.getUnitByEnumName(measure);
                        if (unit) {
                            return unit.unit;
                        }
                        return '';
                    };
                    return _this;
                }
                TableEditService.prototype.onBuildingIdsChange = function (context, data) {
                    _super.prototype.onBuildingIdsChange.call(this, context, data);
                    var cfg = context;
                    if (cfg.breakdown == 'BUILDING') {
                        var isIntervalBreakdownInvalid = !cfg.buildingIds || cfg.buildingIds.length != 1;
                        if (isIntervalBreakdownInvalid) {
                            cfg.isIntervalBreakdown = false;
                            cfg.interval = null;
                        }
                        else if (!cfg.isIntervalBreakdown) {
                            cfg.isIntervalBreakdown = true;
                            cfg.interval = 'default';
                            cfg.isStarEnergyRating = false;
                        }
                    }
                    this.generateDefaultTableTitle(cfg);
                };
                TableEditService.prototype.onSingleBuildingChange = function (context, data) {
                    var cfg = context;
                    cfg.view.buildingId = data;
                    if (cfg.view.buildingId == null) {
                        this.onBuildingIdsChange(cfg, []);
                        return;
                    }
                    cfg.buildingIds = [cfg.view.buildingId];
                    cfg.drilldown = null;
                    cfg.drilldownItems = [];
                    cfg.drilldownItemsSelectionMode = 'individual';
                    cfg.isIntervalBreakdown = false;
                    cfg.interval = null;
                    cfg.options.drilldownOptions = this.getDrilldownOptions(cfg.measures, cfg.breakdown, cfg.buildingIds, cfg);
                };
                TableEditService.prototype.onTimePeriodChange = function (cfg) {
                    if (cfg.trend) {
                        cfg.trendPeriod = TrendPeriods.getTrendPeriodForDatePeriod(cfg.timePeriod);
                    }
                    this.generateDefaultTableTitle(cfg);
                };
                TableEditService.prototype.onBreakdownChange = function (cfg) {
                    cfg.drilldown = null;
                    cfg.drilldownItems = [];
                    cfg.isIntervalBreakdown = false;
                    if (cfg.breakdown == 'INTERVAL') {
                        cfg.interval = 'default';
                    }
                    else {
                        cfg.interval = null;
                    }
                    if (cfg.breakdown != 'BUILDING') {
                        cfg.isStarEnergyRating = false;
                    }
                    cfg.buildingGroupId = null;
                    if (cfg.breakdown == 'BUILDING') {
                        cfg.buildingSelectionMode = 'individual';
                        this.onBuildingIdsChange(cfg, []);
                    }
                    else {
                        cfg.drilldownItemsSelectionMode = 'individual';
                        this.onSingleBuildingChange(cfg, null);
                    }
                    this.getAvailableSortBys(cfg);
                    this.generateDefaultTableTitle(cfg);
                };
                TableEditService.prototype.onDrilldownItemsSelectionModeChange = function (cfg) {
                    var drilldownItems = [];
                    switch (cfg.drilldownItemsSelectionMode) {
                        case 'all':
                            var options = this.getDrilldownOptions(cfg.measures, cfg.breakdown, cfg.buildingIds, cfg);
                            drilldownItems = options;
                            break;
                        case 'individual':
                            drilldownItems = [];
                            break;
                        default:
                            break;
                    }
                    this.onQueryableChange(cfg, drilldownItems);
                };
                TableEditService.prototype.onTrendChange = function (cfg) {
                    cfg.trendPeriod = cfg.trend ? TrendPeriods.getTrendPeriodForDatePeriod(cfg.timePeriod) : null;
                    this.getAvailableSortBys(cfg);
                };
                TableEditService.prototype.onQueryableChange = function (context, data) {
                    var cfg = context;
                    cfg.drilldownItems = data;
                    if (cfg.drilldownItems && cfg.drilldownItems.length == 1) {
                        cfg.drilldown = cfg.drilldownItems[0];
                        cfg.isIntervalBreakdown = true;
                        if (cfg.interval == null) {
                            cfg.interval = 'default';
                        }
                    }
                    else {
                        cfg.drilldown = null;
                        cfg.isIntervalBreakdown = false;
                        cfg.interval = null;
                    }
                    this.generateDefaultTableTitle(cfg);
                };
                TableEditService.prototype.onShowTableTitleChange = function (cfg) {
                    this.generateDefaultTableTitle(cfg);
                };
                TableEditService.prototype.onIsCustomTableTitleChange = function (cfg) {
                    this.generateDefaultTableTitle(cfg);
                };
                TableEditService.prototype.onMeasuresChange = function (cfg) {
                    var _this = this;
                    if (cfg.sortBy != 'BREAKDOWN') {
                        var isSortByMatchingMeasureOrTrend = _.some(cfg.measures, function (measure) {
                            return cfg.sortBy == measure
                                || cfg.trend && cfg.sortBy == _this.getTrendPercentCol(_this.formatTrendLabel(measure));
                        });
                        var isSortBySelectedEnergyStar = cfg.isStarEnergyRating &&
                            (cfg.sortBy == 'energyStarScore' || cfg.sortBy == 'energyStarScoreTrend' && cfg.trend);
                        if (!isSortByMatchingMeasureOrTrend && !isSortBySelectedEnergyStar) {
                            cfg.sortBy = null;
                        }
                    }
                    cfg.options.drilldownOptions = this.getDrilldownOptions(cfg.measures, cfg.breakdown, cfg.buildingIds, cfg);
                    this.getAvailableSortBys(cfg);
                    this.generateDefaultTableTitle(cfg);
                };
                TableEditService.prototype.getDrilldownOptions = function (measures, breakdown, buildingIds, cfg) {
                    var _this = this;
                    var breakdownOptions = _.flatten(_.map(buildingIds, function (buildingId) {
                        return cfg.options.breakdownOptions[breakdown][buildingId];
                    }));
                    breakdownOptions = _.filter(breakdownOptions, function (b) { return b && b.id; });
                    breakdownOptions = _.uniqBy(breakdownOptions, function (item) { return item.id; });
                    var units = _.flatten(_.map(measures, function (measure) { return _this.DashboardOptionsService.getUnitByEnumName(measure); }));
                    var drilldownOptions = _.filter(breakdownOptions, function (drilldown) {
                        return _.some(units, function (unit) { return _.includes(drilldown.metrics, unit.serviceType); });
                    });
                    return drilldownOptions;
                };
                TableEditService.prototype.getAvailableSortBys = function (cfg) {
                    var _this = this;
                    var availableOptions = {};
                    var sortByOptions = cfg.breakdown == 'BUILDING' ? cfg.options.allSortBys : cfg.options.sortBys;
                    _.each(sortByOptions, function (measureItems, key) {
                        if (key == 'GENERAL') {
                            availableOptions['GENERAL'] = measureItems;
                            return;
                        }
                        if (key == 'BUILDING') {
                            var availableBuildingAttributes = _.filter(measureItems, function (item) {
                                return cfg.isStarEnergyRating && (item.value == 'energyStarScore' || item.value == 'energyStarScoreTrend' && cfg.trend);
                            });
                            if (availableBuildingAttributes.length > 0) {
                                availableOptions[key] = availableBuildingAttributes;
                            }
                            return;
                        }
                        var selectedMeasures = _.filter(measureItems, function (item) { return _.some(cfg.measures, function (measure) {
                            return item.value == measure ||
                                cfg.trend && item.value == _this.getTrendPercentCol(_this.formatTrendLabel(measure));
                        }); });
                        if (selectedMeasures.length > 0) {
                            availableOptions[key] = selectedMeasures;
                        }
                    });
                    cfg.view.availableSortBys = availableOptions;
                };
                TableEditService.prototype.getTrendPercentCol = function (trend) {
                    return trend + '_PERCENT';
                };
                TableEditService.prototype.generateDefaultTableTitle = function (cfg) {
                    var _this = this;
                    if (cfg.isCustomTitle) {
                        return;
                    }
                    var units = _.map(cfg.measures, function (m) { return _this.getMeasureUnit(m); });
                    var unitNames = _.filter(units, function (u) { return u; }).join(', ');
                    var timePeriod = textural(cfg.timePeriod).format('capitalizehuman');
                    var title = unitNames + " - " + this.$translate.instant('timeperiod_case3.' + timePeriod);
                    if (cfg.drilldownItems && cfg.drilldownItems.length == 1) {
                        var queryableName = _.first(cfg.drilldownItems).name;
                        title = queryableName + " - " + title;
                    }
                    else if (cfg.buildingIds && cfg.buildingIds.length == 1) {
                        var building = _.find(cfg.options.buildings, function (b) { return b.id == _.first(cfg.buildingIds); });
                        if (building && building.name) {
                            title = building.name + " - " + title;
                        }
                    }
                    else if (cfg.breakdown == 'BUILDING') {
                        if (cfg.buildingSelectionMode == 'all') {
                            title = this.$translate.instant('labels.All buildings') + " - " + title;
                        }
                        else if (cfg.buildingSelectionMode == 'group' && cfg.buildingGroupId) {
                            var group = _.find(cfg.options.buildingGroups, function (g) { return g.label == 'Regions'; });
                            if (group && group.items) {
                                var item = _.find(group.items, function (gi) { return gi.id == cfg.buildingGroupId; });
                                if (item && item.label) {
                                    title = item.label + " - " + title;
                                }
                            }
                        }
                    }
                    cfg.titleText = title;
                };
                TableEditService.prototype.formatTrendLabel = function (measure) {
                    return measure + this.TREND_APPEND;
                };
                return TableEditService;
            }(aq.services.BuildingSelectorActions));
            widgets.TableEditService = TableEditService;
            angular.module('aq.dashboard.widgets').service('TableEditService', TableEditService);
        })(widgets = dashboard.widgets || (dashboard.widgets = {}));
    })(dashboard = aq.dashboard || (aq.dashboard = {}));
})(aq || (aq = {}));
