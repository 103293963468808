namespace aq.projects {

    export class OverviewController {
        /* @ngInject */
        constructor(
            private activities,
            private UserService,
            private buildingId: number,
            private accountId: number
        ) { }

    }

    angular
        .module('projects')
        .controller('OverviewController', OverviewController);
}
