namespace aq.home {
    export class ProjectLeadCtrl {

        protected subject = 'Invitation to join Aquicore';
        protected body = '';
        protected aqPreBody: string[];
        protected aqPostBody: string[];
        protected isCustomizeEmail: boolean;
        protected isLoading: boolean = false;

        public isDesktop: boolean;
        public isScreenLargeEnough: boolean;
        private initialBuildings: aq.common.models.Building[];
        private initialUsers: aq.user.User[];

        private isValidBuildingList: boolean;
        private isValidUserList: boolean;
        private isBuildingPersonnelValid: boolean;

        private savedProjectLead: aq.user.User;

        private verifyBuildingsHeader: string;
        private verifyUsersHeader: string;
        private verifyBuildingPersonnelHeader: string;

        /* @ngInject */
        constructor(
            protected $mdStepper,
            protected $scope,
            protected accountInfoObject: aq.home.AccountInfoObject,
            protected enrollmentId: string,
            protected loading,
            protected $q: ng.IQService,
            protected Messages: aq.services.Messages,
            protected Errors,
            protected $state: ng.ui.IStateService,
            private RestangularV3: restangular.IService,
            private $translate: any,
            private $mdMedia: ng.material.IMedia,
            private step
        ) {

            this.isDesktop = this.$mdMedia('(min-width: 1024px)');
            this.isScreenLargeEnough = screen && screen.width >= 1024;
            this.$scope.$watch(() => this.$mdMedia('(min-width: 1024px)'), (value: boolean) => {
                this.isDesktop = value;
            });

            const verifyBuildingsHeaderKey = 'building_user_enrollment.project_lead_wizard.PL Verify Buildings Instructions';
            this.verifyBuildingsHeader = this.$translate.instant(verifyBuildingsHeaderKey);

            const verifyUsersHeaderKey = 'building_user_enrollment.project_lead_wizard.PL Verify Users Instructions';
            this.verifyUsersHeader = this.$translate.instant(verifyUsersHeaderKey);

            const verifyBuildingPersonnelHeaderKey = 'building_user_enrollment.project_lead_wizard.PL Verify Building Personnel Instructions';
            this.verifyBuildingPersonnelHeader = this.$translate.instant(verifyBuildingPersonnelHeaderKey);

            this.accountInfoObject.users = this.filterProjectLead(this.accountInfoObject.users, this.accountInfoObject.projectLead);
            this.indexUsers();
            this.indexBuildings();
            this.setRoleOnUsers();
            this.setUsersOnEmployees();
            this.initialBuildings = angular.copy(this.accountInfoObject.buildings);
            this.initialUsers = angular.copy(this.accountInfoObject.users);

            this.isValidBuildingList = true;
            this.isValidUserList = true;
            this.isBuildingPersonnelValid = true;

            this.$scope.$on('isValidBuildingListUpdated', ($event, updatedIsValidBuildingList) => {
                this.isValidBuildingList = updatedIsValidBuildingList;
            });
            this.$scope.$on('isValidUserListUpdated', ($event, updatedIsValidUserList) => {
                this.isValidUserList = updatedIsValidUserList;
            });
            this.$scope.$on('buildingPersonnelValidation', ($event, data) => {
                this.isBuildingPersonnelValid = data.isValid;
            });
            this.reloadAQBody();
            this.loading.stop();

            this.$scope.$watch(() => this.$mdStepper('project-lead-stepper'), (value: boolean) => {
                while (this.$mdStepper('project-lead-stepper').currentStep < this.step) {
                    this.$mdStepper('project-lead-stepper').next();
                }
            });
        }

        public next(type: string): void {
            this.saveState(type).then(() => {
                this.reloadAQBody();
                this.$mdStepper('project-lead-stepper').next();
                this.updateStep();
            });
        }

        public previous(): void {
            this.$mdStepper('project-lead-stepper').back();
            this.updateStep();
        }

        public updatePersonnel(person, index: number): void {
            _.each(this.accountInfoObject.buildings, (building: aq.common.models.Building) => {
                const personIndex = _.findIndex(building.personnel, (p) => p.index == index);
                if (personIndex >= 0) {
                    building.personnel[personIndex].user = person.id;
                }
            });
        }

        public submit() {
            this.isLoading = true;
            this.loading.start();
            this.Messages.info('Please wait while the information is being submitted...');
            const customEmail = <CustomEmail>{
                subject: this.subject,
                body: this.body
            };
            this.RestangularV3.one('enrollments', this.enrollmentId).one('account').customPOST(customEmail).then(() => {
                this.Messages.success('Account information has been submitted successfully!');
                this.isLoading = false;
                this.loading.stop();
                this.Messages.info('Redirecting to dashboard...');
                this.$state.go('aq.dashboard.configurable', { accountId: this.accountInfoObject.account.id });
            });
        }

        private saveState(type: string) {
            switch (type) {
                case 'buildings':
                    return this.saveBuildings();
                case 'users':
                    return this.saveUsers();
                case 'employees':
                    return this.saveEmployees();
                default:
                    break;
            }
        }

        private saveBuildings() {
            this.isLoading = true;
            this.loading.start();
            return this.RestangularV3.one('enrollments', this.enrollmentId).all('buildings')
                .customPOST(this.accountInfoObject.buildings).then((res) => {
                    for (const building of this.accountInfoObject.buildings) {
                        if (building.id === undefined || building.id === null) {
                            building.id = res.filter((responseBuilding) => {
                                return building.name === responseBuilding.name;
                            }).map((matchingResponseBuilding) => {
                                return matchingResponseBuilding.id;
                            })[0];
                        }
                    }
                    this.isLoading = false;
                    this.loading.stop();
                    this.Messages.success('Buildings saved');
                });
        }

        private saveUsers() {
            this.isLoading = true;
            this.loading.start();
            const transitoryUsers = this.accountInfoObject.users.map((user) => {
                return {
                    id: user.id,
                    roles: [user.role.id],
                    user
                };
            });
            const transitoryProjectLead = {
                id: this.savedProjectLead.id,
                roles: [this.savedProjectLead.role],
                user: this.savedProjectLead
            } as any;
            transitoryUsers.push(transitoryProjectLead);
            return this.RestangularV3.one('enrollments', this.enrollmentId).all('profiles').customPOST(transitoryUsers).then((res) => {
                for (const user of this.accountInfoObject.users) {
                    if (user.id === undefined || user.id === null) {
                        const userResponseItem = _.find(res, (item) => item.user && item.user.email === user.email);
                        if (userResponseItem) {
                            user.id = userResponseItem.user.id;
                        }
                    }
                }
                this.isLoading = false;
                this.loading.stop();
                this.Messages.success('Users saved');
            });
        }

        private saveEmployees() {
            this.isLoading = true;
            this.loading.start();
            const transitoryEmployeesRequests = this.accountInfoObject.buildings.map((building) => {
                const employees = building.personnel.map((employee) => {
                    if ((employee as any).email === this.savedProjectLead.email) {
                        employee.user = this.savedProjectLead;
                    }
                    return (employee as any) = {
                        id: employee.id,
                        building: building.id,
                        user: employee.user.id
                    };
                });

                const lead = employees.filter((employee) => {
                    return employee.user === this.savedProjectLead.id;
                });

                if (lead.length === 0) {
                    employees.push({
                        id: undefined,
                        building: building.id,
                        user: this.savedProjectLead.id
                    });
                }

                return this.RestangularV3.one('enrollments', this.enrollmentId)
                    .one('buildings', building.id).all('personnel').customPOST(employees).then((res) => {
                        for (const employee of building.personnel) {
                            if (employee.id === undefined || employee.id === null) {
                                employee.id = res.filter((responseEmployee) => {
                                    return (employee as any).name === responseEmployee.name;
                                }).map((matchingResponseBuilding) => {
                                    return matchingResponseBuilding.id;
                                })[0];
                            }
                        }
                    });
            });
            return this.$q.all(
                transitoryEmployeesRequests
            ).then(() => {
                this.isLoading = false;
                this.loading.stop();
                this.Messages.success('Personnel saved');
            });
        }

        private setRoleOnUsers(): void {
            for (const user of this.accountInfoObject.users) {
                user.role = this.accountInfoObject.roles.find(role => role.id === (user.role as any));
            }
        }
        private setUsersOnEmployees(): void {
            for (const building of this.accountInfoObject.buildings) {
                for (const employee of building.personnel) {
                    employee.user = this.accountInfoObject.users.find(user => user.id === (employee.user as any));
                }
            }
        }

        private indexUsers() {
            for (let i = 0; i < this.accountInfoObject.users.length; i++) {
                this.accountInfoObject.users[i].index = i;
                this.indexPersonnel(this.accountInfoObject.users[i]);
            }
        }

        private filterProjectLead(users: aq.user.User[], projectLead: aq.user.User): aq.user.User[] {
            this.savedProjectLead = _.filter(users, (user: aq.user.User) => user.userId == projectLead.id)[0];
            return _.filter(users, (user: aq.user.User) => user.userId != projectLead.id);
        }

        private indexBuildings() {
            _.each(this.accountInfoObject.buildings, (building: common.models.Building, index: number) => building.index = index);
        }

        private indexPersonnel(user: aq.user.User) {
            for (const building of this.accountInfoObject.buildings) {
                for (let i = 0; i < building.personnel.length; i++) {
                    if ((building.personnel as any)[i].user === parseInt(user.id)) {
                        (building.personnel as any)[i].index = user.index;
                    }
                }
            }
        }

        private reloadAQBody() {
            this.aqPreBody = ['<span>Hi Name,</span><br/>',
                '<span></span><br/>', '<span>Welcome to Aquicore!</span><br/>',
                '<span></span><br/>', '<span>You are receiving this email because '
                + this.accountInfoObject.projectLead.firstName + ' '
                + this.accountInfoObject.projectLead.lastName + ' has added you to Aquicore.</span><br/>'];

            this.aqPostBody = ['<span>To get started, accept your invitation and create your user:</span><br/>',
                '<span></span><br/>', '<div class="md-button md-raised md-primary" style="cursor: none; width: 130px;">Accept Invite</div>',
                '<span></span><br/>', '<span>Email Aquicore at support@aquicore.com with any questions.',
                '<span></span><br/>', '<span>We can’t wait to work with you!</span><br/>',
                '<span>-The Aquicore Team</span><br/>',
                '<img src="/img/brand/logo-black.png"><br/>'];
        }

        private updateStep(): void {
            const currentStep = this.$mdStepper('project-lead-stepper').currentStep;
            this.RestangularV3.one('enrollments', this.enrollmentId).one('step', currentStep).customPOST();
        }
    }

    export class AccountInfoObject {
        public account: any;
        public buildings: aq.common.models.Building[];
        public users: aq.user.User[];
        public projectLead: aq.user.User;
        public roles?: aq.user.Role[];
        public personas?: string[];
    }

    export class CustomEmail {
        public subject: string;
        public body: string;
    }

    angular
        .module('home')
        .controller('ProjectLeadCtrl', ProjectLeadCtrl);
}


