angular
    .module('aq.settings')
    .config((RestangularProvider: restangular.IProvider, $stateProvider: ng.ui.IStateProvider) => {
        $stateProvider
            .state('aq.settings.platform.general', {
                url: '/general',
                templateUrl: 'app/settings/platform/general/general.html',
                controller: 'PlatformGeneralCtrl',
                data: {
                    breadcrumb: 'General',
                    icon: 'settings',
                    title: 'Platform Settings',
                    aria: 'Settings'
                }
            })
            .state('aq.settings.platform.metrics', {
                url: '/metrics',
                templateUrl: 'app/settings/platform/metrics/metrics.html',
                controller: 'SettingsMetricCtrl as ctrl',
                data: { breadcrumb: 'Metrics' }
            })
            .state('aq.settings.platform.energyUse', {
                url: '/energy-use',
                templateUrl: 'app/settings/platform/energyUse/energyUse.html',
                controller: 'SettingsEnergyUseCtrl as vm',
                data: { breadcrumb: 'Energy Use' }
            })
            .state('aq.settings.platform.buildingGroups', {
                url: '/building-groups',
                templateUrl: 'app/settings/platform/buildingGroups/buildingGroup.html',
                controller: 'SettingsBuildingGroupCtrl as vm',
                data: { breadcrumb: 'Building Groups' }
            })
            .state('aq.settings.platform.scheduleCategories', {
                url: '/schedule-categories',
                templateUrl: 'app/settings/platform/scheduleCategories/scheduleCategory.html',
                controller: 'SettingsScheduleCategoryCtrl as vm',
                data: { breadcrumb: 'Schedule Categories' }
            })
            .state('aq.settings.import', {
                url: '/import',
                templateUrl: 'app/admin/accounts/accounts/create/accountNew.html',
                controller: 'AccountNewCtrl as vm',
                resolve: {
                    personas(RestangularV3, waitForAuthToken) {
                        return RestangularV3.one('personas').customGET();
                    },
                    licenses(Restangular, waitForAuthToken) {
                        return Restangular.all('licenses').getList();
                    },
                    options(Restangular, waitForAuthToken) {
                        return Restangular.one('options', 'allEnums').get();
                    },
                    timeZones(Restangular, waitForAuthToken) {
                        return Restangular.one('options', 'allTimezones').get();
                    }
                },
                params: {
                    newAccountObj: null
                }
            });
    });
