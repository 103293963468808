namespace aq.propertySettings {
    export class AlertListCtrl {
        private propertySettingsAccess: common.models.AppAccessObject;
        /* @ngInject */
        constructor(
            private $scope: AlertListCtrlScope,
            private allStateAlerts: StateAlert[],
            private allUsageAlerts: RealTimeEnergyAlert[],
            private allRecoveryNotifications,
            private $state: ng.ui.IStateService,
            private DataStore: aq.common.DataStore,
            private $mdDialog: ng.material.IDialogService,
            private Messages: aq.services.Messages,
            private building: aq.common.models.Building,
            private tenants: Object[], // TODO type
            private sources: Object[], // TODO type
            private calendars: Object[], // TODO type
            private collectors: aq.common.models.Collector[],
            private units: aq.common.models.Unit[],
            private buildingDrillin: any,
            private users: aq.common.models.User[],
            private notificationSenders: aq.common.models.NotificationSender[],
            private account: aq.common.models.Account,
            private UserService,
            private Restangular: restangular.IService,
            private RestangularV3: restangular.IService,
            private authAccess: common.models.AuthAppAccess,
            private changeTimeStamp
        ) {
            this.propertySettingsAccess = this.authAccess['Property Settings'];
            this.$scope.isAdmin = UserService.currentUser.userType === 'ADMINISTRATOR';
            this.$scope.recoveryNotifications = allRecoveryNotifications;
            this.$scope.allAlerts = _.concat(allStateAlerts, allUsageAlerts);
            this.$scope.tenants = tenants;
            this.$scope.sources = sources;
            this.$scope.collectors = collectors;
            this.$scope.calendars = calendars;
            this.$scope.users = users;
        }

        public createDefaults() {
            this.Messages.info('Creating default alerts');
            this.RestangularV3
                .all('usage-alerts')
                .customPOST(null, 'create-defaults', {buildings: [this.building.id]})
                .then(_ => {
                    return this.DataStore.getList(
                        this.RestangularV3.one(''),
                        'state-alerts',
                        { buildingId: this.building.id }
                    );
                }).then(allStateAlerts => {
                    this.allStateAlerts = allStateAlerts;
                    return this.DataStore.getList(this.building, 'realTimeEnergyAlerts', { inflate: 'receivers,customRule' });
                }).then(allUsageAlerts => {
                    this.allUsageAlerts = allUsageAlerts;
                    this.$scope.allAlerts = _.concat(this.allStateAlerts, this.allUsageAlerts);
                    this.Messages.success('Default alerts created successfully');
            }).catch(reject => {
                this.Messages.warn('Default alerts could not be created');
            });
        }

        public save(alert) {
            const objectType = alert.route;
            let params = {};
            if (objectType === 'recoveryNotifications') {
                params = { inflate: 'currentState,receivers' };
            } else if (objectType === 'realTimeEnergyAlerts') {
                params = { inflate: 'receivers,customRule' };
            }
            this.DataStore.update(alert, params).then((alert) => {
                if (objectType !== 'state-alerts') {
                    this.Restangular.restangularizeElement(this.building, alert, objectType);
                }
                alert.fromServer = true;
                this.Messages.info('Alert Saved');
            }, (error) => {
                this.Messages.error('Error saving Alert');
            });
        }

        public search(searchText) {
            if (searchText) {
                const filteredUsageAlerts = this.allUsageAlerts.filter((alert) => {
                    return _.includes(alert.name.toUpperCase(), searchText.toUpperCase());
                });
                const filteredStateAlerts = this.allStateAlerts.filter((alert) => {
                    return _.includes(alert.name.toUpperCase(), searchText.toUpperCase());
                });
                this.$scope.recoveryNotifications = this.allRecoveryNotifications.filter((alert) => {
                    return _.includes(alert.name.toUpperCase(), searchText.toUpperCase());
                });
                this.$scope.allAlerts = _.concat(filteredStateAlerts, filteredUsageAlerts);
            } else {
                this.$scope.allAlerts = _.concat(this.allStateAlerts, this.allUsageAlerts);
                this.$scope.recoveryNotifications = this.allRecoveryNotifications;
            }
        }

        public create() {
            this.$mdDialog.show({
                controller: 'AlertNewCtrl as alertNewCtrl',
                templateUrl: 'app/properties/alerts/new/alertNewModal.html',
                clickOutsideToClose: true,
                parent: angular.element(document.body),
                locals: {
                    alert: {
                        building: this.building.id,
                        senders: [],
                        receivers: [],
                        type: 'REAL_TIME_ENERGY',
                        interval: 'FIFTEEN_MINUTES',
                        account: this.account.id,
                        active: true,
                        selectedUsers: []
                    },
                    building: this.building,
                    account: this.account,
                    alertOnOptions: this.$scope.alertOnOptions,
                    units: this.units,
                    buildingDrillin: this.buildingDrillin,
                    users: this.users,
                    notificationSenders: this.notificationSenders,
                    collectors: this.collectors,
                    sources: this.sources,
                    tenants: this.tenants,
                    calendars: this.calendars
                }
            });
        }

        public edit(alert) {
            this.$state.go('.details', { alertId: alert.id });
            this.search('');
        }

        public bulkUpdate() {
            this.$mdDialog.show({
                controller: 'AlertBulkCtrl as alertBulkCtrl',
                templateUrl: 'app/properties/alerts/bulk/alertBulkModal.html',
                clickOutsideToClose: true,
                parent: angular.element(document.body),
                locals: {
                    building: this.building,
                    timeout: this.getAllTimeout(),
                    active: this.getAllActive(),
                    users: this.users,
                    selectedUsers: this.getAllSelectedUsers(),
                    allAlerts: this.$scope.recoveryNotifications,
                    currentUser: this.UserService.currentUser,
                    notificationSenders: this.notificationSenders
                }
            });
        }

        public getAllTimeout() {
            let timeout: number = null;
            let timeoutsEqual = true;
            _.each(this.$scope.recoveryNotifications, (alert: MeterOfflineAlert) => {
                if (timeout == null) {
                    timeout = alert.currentState.timeout;
                } else if (timeout != alert.currentState.timeout) {
                    timeoutsEqual = false;
                }
            });
            return timeoutsEqual ? timeout : null;
        }

        public getAllActive() {
            const allActive = _.some(this.$scope.recoveryNotifications, ['active', false]);
            return allActive;
        }

        public getAllSelectedUsers() {
            let selectedUsers: aq.common.models.User[] = this.$scope.recoveryNotifications[0].receivers;
            _.each(this.$scope.recoveryNotifications, (alert: any) => {
                selectedUsers = _.unionBy(alert.receivers, selectedUsers, 'mail');
            });
            return _.filter(_.map(selectedUsers, 'user'), (user: any) => {
                return !_.isNull(user) && !_.isUndefined(user);
            });
        }

        public delete(alert) {
            this.DataStore.delete(alert).then(() => {
                this.Messages.info(`Successfully deleted ${alert.name}`);
                _.remove(this.$scope.allAlerts, (a) => a.id == alert.id);
            });
        }
    }

    export interface AlertListCtrlScope extends ng.IScope {
        allAlerts: (StateAlert | RealTimeEnergyAlert)[];
        recoveryNotifications;
        alertOnOptions: { label: string, value: string }[];
        collectors: aq.common.models.Collector[];
        sources: Object[];
        tenants: Object[];
        timeFrames: { label: string, value: string }[];
        calendars: Object[];
        users: aq.common.models.User[];
    }

    angular.module('properties').controller('AlertListCtrl', aq.propertySettings.AlertListCtrl);
}
