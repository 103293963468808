namespace aq.publicDisplay {
    export class PublicDisplaySelectService extends aq.dashboard.DashboardSelectService {
        /* @ngInject */
        constructor(
            protected AppStorageService,
            protected UserService
        ) {
            super(AppStorageService, UserService);
        }

        public getRedirect (currentDashboardId, account: aq.common.models.Account, dashboards: Array<aq.admin.dashboards.Dashboard>) {
            let redirect = super.getRedirect(currentDashboardId, account, dashboards);

            if (redirect) {
                switch (redirect.state) {
                    case 'aq.dashboard.configurable':
                        redirect.state = 'aq.publicDisplay.configurable';
                        break;
                    case 'aq.dashboard.noAvailableDashboards':
                        redirect.state = 'aq.publicDisplay.noAvailableDisplays';
                        break;
                }
            }

            return redirect;
        }
    }

    angular.module('aq.publicDisplay').service('PublicDisplaySelectService', PublicDisplaySelectService);
}