'use strict';

angular.module('properties.dataCollectors')
    .controller('BulkHistoricalUploadCtrl', function ($scope, Messages, Restangular, Errors, Auth, $stateParams, $state, loading, $q, FilePicker) {

        $scope.popover = {}

        $scope.bulkHistoricDataItem = null;
        $scope.fileName = null;
        $scope.url = null;

        $scope.bulkHistoricDataShowFilePicker = function () {
            FilePicker.pickAndStore({ mimetype: 'text/csv' }, { location: 'S3', access: 'public' }).then(function (result) {
                $scope.fileName = result[0].filename;
                $scope.url = result[0].url;
            }, function (result) {
                if (result.code != 101) Messages.error("Error uploading file");
            });
        }


        $scope.getBulkUploadTemplate = function () {
            if (!$scope.building) return;
            Restangular.one("accounts", $scope.account.id).one("buildings", $scope.building.id).all("collectors")
                .customGET('bulkHistoricDataItemsTemplate').then((response) => {
                    const csvInfo = 'data:text/csv;charset=utf-8,';
                    const fileName = `Bulk Upload Historic Data - ${$scope.building.name}.csv`;
                    const link = document.createElement('a');
                    link.setAttribute('href', `${csvInfo}${encodeURIComponent(response)}`);
                    link.setAttribute('download', fileName);
                    link.click();
                });
        }

        $scope.upload = function () {
            Restangular.one("accounts", $scope.account.id).one("buildings", $scope.building.id).all("collectors")
                .customPOST({
                    url: $scope.url,
                    filename: $scope.fileName
                }, 'bulkHistoricDataItems').then(function (result) {
                    // result holds all individual csv files that were created
                    $scope.$hide();
                }, Errors.forPromise());
        }

    });
