namespace aq.accounts.companySettings.metric {

    export class AccountMetric {
        name: string;
        unit: string;
        displayAs: string;
        enabled: boolean;

        constructor(name = '', unit = '', displayAs = '', enabled = true) {
            this.name = name;
            this.unit = unit;
            this.displayAs = displayAs;
            this.enabled = enabled;
        }
    }

}