namespace aq.admin.configuration.utilityCompany {
    export class TariffScheduleDetailsCtrl {
        private supportedMeasures;

        /* @ngInject */
        constructor(
            private $scope: TariffScheduleDetailsCtrlScope,
            private Messages,
            private utilityCompany: UtilityCompany,
            private tariffSchedule: TariffScheduleElement,
            private measures: aq.common.models.Measure[],
            private $mdDialog: ng.material.IDialogService,
            private tariffBase,
            private chargeIntervals,
            private Restangular: restangular.IService
        ) {
            $scope.chargeIntervals = chargeIntervals;
            $scope.tariffSchedule = tariffSchedule;
            $scope.chargeTypes = [{
                label: 'Demand',
                value: 'DemandCharge',
                route: 'DemandCharges'
            }, {
                label: 'Usage',
                value: 'UsageCharge',
                route: 'UsageCharges'
            }, {
                label: 'Fixed',
                value: 'FixedCharge',
                route: 'FixedCharges'
            }, {
                label: 'Percentage',
                value: 'PercentageCharge',
                route: 'PercentageCharges'
            }];
            this.$scope.tariffSchedule.charges.forEach((charge: TariffCharge) => {
                if (charge) {
                    charge.type = this.getChargeType(charge.classType);
                    if (charge.tariffRule.dateStart && typeof charge.tariffRule.dateStart !== 'object') {
                        charge.tariffRule.dateStart = new Date(charge.tariffRule.dateStart);
                    }
                    if (charge.tariffRule.dateEnd && typeof charge.tariffRule.dateEnd !== 'object') {
                        charge.tariffRule.dateEnd = new Date(charge.tariffRule.dateEnd);
                    }
                }
            });
            this.supportedMeasures = this.measures.filter(this.supportedMeasure);
        }

        save() {
            this.getTariffScheduleCopyWithoutCharges().save()
                .then((savedTariffSchedule) => {
                    this.Messages.success('Successfully saved tariff schedule');
                    this.$scope.$emit('EnergyTariffScheduleSaved', savedTariffSchedule);
                }).catch(() => {
                    this.Messages.error('Error saving tariff schedule');
                });
        }

        // charges lead to abstract gson serialization error
        getTariffScheduleCopyWithoutCharges(): TariffScheduleElement {
            const tariffScheduleCopy = <TariffScheduleElement> this.Restangular.copy(this.$scope.tariffSchedule);
            delete tariffScheduleCopy.charges;
            return tariffScheduleCopy;
        }

        createTariffCharge() {
             this.$mdDialog.show({
                controller: 'TariffChargeEditCtrl as tariffChargeEditCtrl',
                templateUrl: 'app/admin/configuration/utilityCompany/tariffSchedule/tariffCharge/edit.html',
                clickOutsideToClose: true,
                scope: this.$scope,
                preserveScope: true,
                parent: angular.element(document.body),
                locals: {
                    tariffBase: this.tariffBase,
                    tariffCharge: {schedule: this.$scope.tariffSchedule.id, tariffRule: {}}
                }
            });
        }

        editTariffCharge(charge) {
            if (!(charge.type instanceof Object)) {
                charge.type = this.getChargeType(charge.classType);
            }
            this.$mdDialog.show({
                controller: 'TariffChargeEditCtrl as tariffChargeEditCtrl',
                templateUrl: 'app/admin/configuration/utilityCompany/tariffSchedule/tariffCharge/edit.html',
                clickOutsideToClose: true,
                scope: this.$scope,
                preserveScope: true,
                parent: angular.element(document.body),
                locals: {
                    tariffBase: this.tariffBase,
                    tariffCharge: charge
                }
            });
        }

        deleteTariffCharge(charge: TariffChargeElement, ev?) {
            const confirm = this.$mdDialog.confirm()
                .title(`Are you sure you want to delete your Tariff Charge?`)
                .ariaLabel('Delete')
                .targetEvent(ev)
                .ok('Delete')
                .cancel('Cancel');
            this.$mdDialog.show(confirm).then(() => {
                return this.tariffBase.one(charge.type.route, charge.id).remove();
            }).then(() => {
                this.Messages.success('Successfully deleted tariff charge');
                _.remove(this.tariffSchedule.charges, {id: charge.id});
            });
        }

        getChargeType(classType: TariffChargeType): {label: string, value: TariffChargeType, route: string} {
            return _.find(this.$scope.chargeTypes, {value: classType});
        }

        supportedMeasure(measure: aq.common.models.Measure) {
            switch (measure.name) {
                case 'electricity':
                case 'water':
                case 'steam':
                case 'gas' :
                case 'heat':
                    return true;
                default:
                    return false;
            }
        };
    }

    export interface TariffScheduleDetailsCtrlScope extends UtilityCompanyDetailsCtrlScope {
        tariffSchedule: TariffScheduleElement;
        chargeTypes: {label: string, value: TariffChargeType, route: string}[];
        chargeIntervals: any;
    }
}
angular
    .module('utilityCompany')
    .controller('TariffScheduleDetailsCtrl', aq.admin.configuration.utilityCompany.TariffScheduleDetailsCtrl);
