namespace aq.energyInsights {
    export class CostCtrl {
        /* @ngInject */
        constructor(
            private currentBuilding: aq.common.models.Building,
            private account: aq.common.models.Account
        ) {
        }
    }
    angular.module('energyInsights').controller('CostCtrl', aq.energyInsights.CostCtrl);
}
