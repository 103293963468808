namespace aq.utilityBudgets {
    export class UtilityBillsCtrl {
        public utilityBillsChart: __Highcharts.Options;
        public temperatureSeries: __Highcharts.SeriesOptions;
        public chart: __Highcharts.ChartObject;
        public selectedType: string;
        public tableData: BillYearlyTableDataItem[];
        public temperatureCategorized: number[];
        public labels: string[];
        public selectedYearPeriod: BudgetYearPeriod;
        public availableYears: BudgetYearPeriod[];
        public selectedUtilityService: UtilityService;
        public colors: string[];
        public periodType: string;
        public isPeriodTypeDisabled: boolean;

        /* ngInject */
        constructor(
            private account: aq.common.models.Account,
            private building: aq.common.models.Building,
            private measures: aq.common.models.Measure[],
            private billMeasure: string,
            private billYear: string,
            private utilityBillAnalysisResponse: aq.utilityBudgets.UtilityBillAnalysisData,
            private utilityServices: UtilityService[],
            private billService: UtilityService,
            private temperature: Temperature,
            private temperatureUnit: MeasureUnit,
            private currencyUnitSymbol: string,
            private $state: ng.ui.IStateService,
            private $timeout: ng.ITimeoutService,
            private $filter: ng.IFilterService,
            private $translate
        ) {
            this.init();
            this.buildTableData();
            this.buildUtilityBillsChart();
            this.$timeout(() => this.chart && this.chart.reflow(), 200);
        }
        public init() {
            this.periodType = 'year';
            this.labels = [];
            this.colors = Highcharts.getOptions().colors;
            this.selectedType = this.billMeasure;
            this.temperatureCategorized = [];
            this.selectedUtilityService = this.billService;
            this.initAvailableYears();
            this.initConstantSeries();
        }
        public initAvailableYears() {
            let yearCounter = 3;
            const date = moment();
            this.availableYears = [];
            while (yearCounter > 0) {
                const year = date.format('YYYY');
                this.availableYears.push({
                    name: year,
                    year
                });
                yearCounter--;
                date.subtract(1, 'year');
            }
            this.selectedYearPeriod = _.find(this.availableYears, (item) => item.year == this.billYear);
            if (!this.selectedYearPeriod) {
                this.selectedYearPeriod = {
                    name: this.billYear,
                    year: this.billYear
                };
                this.availableYears.push(this.selectedYearPeriod);
            }
        }
        public initConstantSeries() {
            const temperatureSeriesData = [];

            if (this.utilityBillAnalysisResponse) {
                _.each(this.utilityBillAnalysisResponse.billing.data, (item: BillStatementDataItem) => {
                    const startDate = moment(item.startTimestamp).format('LLLL');
                    const endDate = moment(item.endTimestamp).format('LLLL');
                    let temperatureSum = 0, temperatureCounter = 0;
                    _.each(this.temperature.timestamps, (timestamp: number, index: number) => {
                        const ts = moment(timestamp);
                        if (ts.isSameOrAfter(startDate) && ts.isBefore(endDate)) {
                            const value = this.temperature.values[index];
                            if (value != null) {
                                temperatureCounter++;
                                temperatureSum += value;
                            }
                        }
                    });
                    const avgTemperatureValue = temperatureSum > 0 ? temperatureSum / temperatureCounter : null;
                    this.temperatureCategorized.push(avgTemperatureValue);
                });
            }
            _.each(this.temperatureCategorized, (value: number, index: number) => {
                if (value != null) {
                    const point = {
                        x: index,
                        y: this.getTemperatureValue(value)
                    };
                    temperatureSeriesData.push(point);
                }
            });
            this.temperatureSeries = {
                data: temperatureSeriesData,
                name: this.$translate.instant('budgets.Avg Temperature'),
                type: 'line',
                stacking: 'normal',
                color: seriesColors.weather,
                dashStyle: 'ShortDash',
                states: {
                    hover: {
                        enabled: true
                    }
                },
                yAxis: 1,
                index: 100
            };
        }
        public onChangeUtilityService() {
            this.$state.go(this.$state.current.name, {
                serviceId: this.selectedUtilityService.id
            });
        }
        public onChangePeriod() {
            this.$state.go(this.$state.current.name, {
                year: this.selectedYearPeriod.year
            });
        }
        public onChangeType() {
            this.$state.go(this.$state.current.name, {
                measure: this.selectedType
            });
        }
        public onSelectBill(index) {
            const billId = this.utilityBillAnalysisResponse.billing.data[index].id;
            this.$state.go('aq.utilityBudgets.bill', {
                billId,
                year: this.selectedYearPeriod.year,
                measure: this.selectedType
            });
        }
        public goToBillStatementView() {
            this.isPeriodTypeDisabled = true;
            this.$state.go('aq.utilityBudgets.bill', {
                billId: null,
                year: null,
                measure: this.selectedType
            });
        }
        public buildTableData() {
            this.tableData = [];
            if (this.utilityBillAnalysisResponse) {
                _.each(this.utilityBillAnalysisResponse.billing.data, (item: BillStatementDataItem, index: number) => {
                    const total = item.datum.charge;
                    const demand = item.datum.demandCharge || 0;
                    const consumption = item.datum.usageCharge || 0;
                    const tax = item.datum.taxCharge || 0;
                    const other = item.datum.otherCharge || 0;
                    const adjustment = item.datum.adjustmentCharge || 0;
                    const lateFee = item.datum.lateFeeCharge || 0;
                    const hasNegativeCharge: boolean = demand < 0 || consumption < 0 || tax < 0 || other < 0 || adjustment < 0 || lateFee < 0;
                    const cost = item.datum.cost || total;
                    const consumptionPercent = !hasNegativeCharge && cost > 0 ? (Math.round(consumption * 1000 / cost) / 10).toFixed(1) : '';
                    const demandPercent = !hasNegativeCharge &&  cost > 0 ? (Math.round(demand * 1000 / cost) / 10).toFixed(1) : '';
                    const taxPercent = !hasNegativeCharge &&  cost > 0 ? (Math.round(tax * 1000 / cost) / 10).toFixed(1) : '';
                    const otherPercent = !hasNegativeCharge && cost > 0 ? (Math.round(other * 1000 / cost) / 10).toFixed(1) : '';
                    const lateFeePercent = !hasNegativeCharge &&  cost > 0 ? (Math.round(lateFee * 1000 / cost) / 10).toFixed(1) : '';
                    const adjustmentPercent = !hasNegativeCharge && cost > 0 ? (Math.round(adjustment * 1000 / cost) / 10).toFixed(1) : '';
                    const degreeValue = this.temperatureCategorized[index] != null
                        ? this.getTemperatureValue(this.temperatureCategorized[index])
                        : null;
                    const startDate = moment(item.startTimestamp);
                    const endDate = moment(item.endTimestamp);
                    this.tableData.push({
                        startDate: startDate.format('MMM D'),
                        endDate: endDate.format('MMM D'),
                        temperature: degreeValue != null ? `${degreeValue} ${this.temperatureUnit.unit}` : '-',
                        charge: this.$filter('currency')(total, this.currencyUnitSymbol, 0),
                        chargeValue: total,
                        usageCharge: this.$filter('currency')(consumption, this.currencyUnitSymbol, 0),
                        usageValue: consumption,
                        usagePercent: consumptionPercent,
                        demandCharge: this.$filter('currency')(demand, this.currencyUnitSymbol, 0),
                        demandValue: demand,
                        demandPercent,
                        taxCharge: this.$filter('currency')(tax, this.currencyUnitSymbol, 0),
                        taxValue: tax,
                        taxPercent,
                        otherCharge: this.$filter('currency')(other, this.currencyUnitSymbol, 0),
                        otherValue: other,
                        otherPercent,
                        lateFeeCharge: this.$filter('currency')(lateFee, this.currencyUnitSymbol, 0),
                        lateFeeValue: lateFee,
                        lateFeePercent,
                        adjustmentCharge: this.$filter('currency')(adjustment, this.currencyUnitSymbol, 0),
                        adjustmentValue: adjustment,
                        adjustmentPercent,
                        cost: this.$filter('currency')(cost, this.currencyUnitSymbol, 0),
                        costValue: cost,
                        hasNegativeCharge
                    });
                });
                this.tableData.reverse();
            }
        }
        public buildUtilityBillsChart() {
            const series = [];
            const demandData = [];
            const consumptionData = [];
            const taxData = [];
            const otherData = [];
            const lateFeeData = [];
            const adjustmentData = [];
            if (this.utilityBillAnalysisResponse) {
                _.each(this.utilityBillAnalysisResponse.billing.data, (item: BillStatementDataItem, index: number) => {
                    const date = moment(item.startTimestamp);
                    const endDate = moment(item.endTimestamp);
                    const demand = item.datum.demandCharge || 0;
                    const other = item.datum.otherCharge || 0;
                    const lateFee = item.datum.lateFeeCharge || 0;
                    const adjustment = item.datum.adjustmentCharge || 0;
                    const consumption = item.datum.usageCharge || 0;
                    const tax = item.datum.taxCharge || 0;

                    this.labels.push(`${date.format('MMM D')} - ${endDate.format('MMM D')}`);

                    consumptionData.push({ x: index, y: consumption });
                    demandData.push({ x: index, y: demand });
                    taxData.push({ x: index, y: tax });
                    otherData.push({x: index, y: other});
                    lateFeeData.push({x: index, y: lateFee});
                    adjustmentData.push({x: index, y: adjustment});
                });
            }
            let index = 0;
            series.push({
                data: consumptionData,
                name: this.$translate.instant('budgets.Consumption'),
                type: 'column',
                colorIndex: 0,
                stacking: 'normal',
                cursor: 'pointer',
                pointPadding: 0.1,
                borderWidth: 0,
                index,
                states: {
                    hover: {
                        enabled: true
                    }
                },
                minPointLength: 2
            });
            index++;
            if (this.selectedType === 'electricity') {
                series.push({
                    data: demandData,
                    name: this.$translate.instant('budgets.Demand'),
                    type: 'column',
                    colorIndex: 1,
                    stacking: 'normal',
                    cursor: 'pointer',
                    pointPadding: 0.1,
                    borderWidth: 0,
                    index,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                });
                index++;
            }
            series.push({
                data: taxData,
                name: this.$translate.instant('budgets.Taxes and Fees'),
                type: 'column',
                colorIndex: 2,
                stacking: 'normal',
                cursor: 'pointer',
                pointPadding: 0.1,
                borderWidth: 0,
                index: 0,
                states: {
                    hover: {
                        enabled: true
                    }
                }
            });
            series.push({
                data: otherData,
                name: this.$translate.instant('budgets.Other'),
                type: 'column',
                colorIndex: 3,
                stacking: 'normal',
                cursor: 'pointer',
                pointPadding: 0.1,
                borderWidth: 0,
                index,
                states: {
                    hover: {
                        enabled: true
                    }
                },
                minPointLength: 0
            });
            index++;
            series.push({
                data: lateFeeData,
                name: this.$translate.instant('budgets.Late Fees'),
                type: 'column',
                colorIndex: 4,
                stacking: 'normal',
                cursor: 'pointer',
                pointPadding: 0.1,
                borderWidth: 0,
                index,
                states: {
                    hover: {
                        enabled: true
                    }
                },
                minPointLength: 0
            });
            index++;
            series.push({
                data: adjustmentData,
                name: this.$translate.instant('budgets.Adjustments'),
                type: 'column',
                colorIndex: 5,
                stacking: 'normal',
                cursor: 'pointer',
                pointPadding: 0.1,
                borderWidth: 0,
                index,
                states: {
                    hover: {
                        enabled: true
                    }
                },
                minPointLength: 0
            });
            series.push(this.temperatureSeries);
            this.utilityBillsChart = this.buildChart(series);
        }
        public hasNegativeCharge(): boolean {
            return this.tableData.some((data) => data.hasNegativeCharge);
        }
        public buildChart(series) {
            const labels = this.labels;
            const getContext = () => this;
            return {
                chart: {
                    height: 400,
                    animation: false
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    tickInterval: 1,
                    min: 0,
                    max: this.utilityBillAnalysisResponse ? this.utilityBillAnalysisResponse.billing.data.length - 1 : 0,
                    labels: {
                        formatter() {
                            return labels[this.value];
                        }
                    },
                    crosshair: true
                },
                yAxis: [{
                    title: { text: this.currencyUnitSymbol },
                    softMin: 0,
                    plotLines: [
                        {
                            color: '#000000',
                            width: 3,
                            value: 0
                        }
                    ]
                }, {
                    title: { text: this.temperatureUnit.unit },
                    opposite: true
                }],
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal',
                    itemStyle: {
                        fontSize: '11px'
                    },
                    itemHoverStyle: {
                        cursor: 'pointer',
                        color: 'blue'
                    }
                },
                plotOptions: {
                    column: {
                        grouping: false,
                        cursor: 'pointer'
                    },
                    series: {
                        animation: false,
                        events: {
                            click: (ev) => {
                                if (ev && ev.point) {
                                    this.onSelectBill(ev.point.x);
                                }
                            },
                            // tslint:disable-next-line:object-literal-shorthand
                            legendItemClick: function () {
                                return true;
                            }
                        },
                        marker: {
                            enabled: false
                        },
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    }
                },
                series,
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                tooltip: {
                    formatter() {
                        const context = getContext();
                        const index = this.x;
                        const tableDataIndex = context.tableData.length - this.x - 1;
                        const dayInfo = `<b>${context.labels[index]}</b>`;
                        const tableDataItem = context.tableData[tableDataIndex];
                        const degreeDaysContent = tableDataItem.temperature == '-' ? '' : `<span style="color:${seriesColors.weather};">■ </span>`
                            + `<span>${context.$translate.instant('budgets.Avg Temperature')}: ${tableDataItem.temperature}</span>`;
                        const totalLabel = context.$translate.instant('budgets.Cost');
                        const totalContent = tableDataItem.cost != '$0'
                            ? `<span>■ </span><span>${totalLabel}: ${tableDataItem.cost}</span>`
                            : '';
                        const usageLabel = context.$translate.instant('budgets.Consumption');
                        const consumptionPercent = !tableDataItem.hasNegativeCharge ? `(${tableDataItem.usagePercent}%)` : '';
                        const consumptionContent = tableDataItem.usageCharge != '$0'
                            ? `<span style="color:${context.colors[0]}">■ </span>
                                <span>${usageLabel}: ${tableDataItem.usageCharge} ${consumptionPercent}</span>`
                            : '';
                        const demandLabel = context.$translate.instant('budgets.Demand');
                        const demandPercent = !tableDataItem.hasNegativeCharge ? `(${tableDataItem.demandPercent}%)` : '';
                        const demandContent = context.selectedType === 'electricity' && tableDataItem.demandCharge != '$0'
                            ? `<span style="color:${context.colors[1]}">■ </span>
                                <span>${demandLabel}: ${tableDataItem.demandCharge} ${demandPercent}</span>`
                            : '';
                        const taxLabel = context.$translate.instant('budgets.Taxes and Fees');
                        const taxPercent = !tableDataItem.hasNegativeCharge ? `(${tableDataItem.taxPercent}%)` : '';
                        const taxContent = tableDataItem.taxCharge != '$0'
                            ? `<span style="color:${context.colors[2]}">■ </span>
                                <span>${taxLabel}: ${tableDataItem.taxCharge} ${taxPercent}</span>`
                            : '';
                        const otherLabel = context.$translate.instant('budgets.Other');
                        const otherPercent = !tableDataItem.hasNegativeCharge ? `(${tableDataItem.otherPercent}%)` : '';
                        const otherContent = tableDataItem.otherCharge != '$0'
                            ? `<span style="color:${context.colors[3]}">■ </span>
                                <span>${otherLabel}: ${tableDataItem.otherCharge} ${otherPercent}</span>`
                            : '';
                        const lateFeeLabel = context.$translate.instant('budgets.Late Fees');
                        const lateFeePercent = !tableDataItem.hasNegativeCharge ? `(${tableDataItem.lateFeePercent}%)` : '';
                        const lateFeeContent = tableDataItem.lateFeeCharge != '$0'
                            ? `<span style="color:${context.colors[4]}">■ </span>
                                <span>${lateFeeLabel}: ${tableDataItem.lateFeeCharge} ${lateFeePercent}</span>`
                            : '';
                        const adjustmentLabel = context.$translate.instant('budgets.Adjustments');
                        const adjustmentPercent = !tableDataItem.hasNegativeCharge ? `(${tableDataItem.adjustmentPercent}%)` : '';
                        const adjustmentContent = tableDataItem.adjustmentCharge != '$0'
                            ? `<span style="color:${context.colors[5]}">■ </span>
                                <span>${adjustmentLabel}: ${tableDataItem.adjustmentCharge} ${adjustmentPercent}</span>`
                            : '';
                        return [
                            dayInfo,
                            degreeDaysContent,
                            totalContent,
                            consumptionContent,
                            demandContent,
                            taxContent,
                            otherContent,
                            lateFeeContent,
                            adjustmentContent
                        ].join('<br/>');
                    }
                }
            };
        }
        private getTemperatureValue(value) {
            if (value == null) {
                return null;
            }
            return Math.round(this.$filter<Function>('toTemperatureUnit')(value, this.temperatureUnit));
        }
    }
    angular.module('aq.utilityBudgets').controller('UtilityBillsCtrl', UtilityBillsCtrl);
}
