namespace aq.utilityBudgets {
    export class UtilityAccountDetailsInfo {
        public readonly emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        public currentNavItem: string;
        public isSendBuildingPersona: boolean;
        public isSendCustomEmails: boolean;
        public notificationUsers: UserItem[];
        public sendToSelf: boolean;
        public isSendUsers: boolean;
        public customEmails: string[];
        public selectedUser;
        public personas: {
            label: string; value: string;
        }[];
        public buildingsForUtilityAccount;
        public utilityAccountBuildings;
        public searchTerm: string;
        private utilityAccountStatusView: UtilityAccountStatusViewModel;

        /* @ngInject */
        constructor(
            public utilityAccount: UtilityAccount,
            public buildings: common.models.Building[],
            private buildingId: string,
            private $scope: ng.IScope,
            private utilityCompanies,
            private users: UserItem[],
            private UtilityServiceHelper: UtilityServiceHelper,
            private UserService: aq.services.UserService,
            private RestangularV3: restangular.IService,
            private utilityAccountBuildingIds: number[],
            private Messages,
            private $mdDialog: ng.material.IDialogService,
        ) {
            this.currentNavItem = 'info';

            if (this.utilityAccount.utilityUrl && this.utilityAccount.username) {
                this.utilityAccount.autoUploadEnabled = true;
            } else {
                this.utilityAccount.autoUploadEnabled = false;
            }

            this.personas = [
                { label: 'Asset Manager', value: 'ASSET_MANAGER' },
                { label: 'Property Manager', value: 'PROPERTY_MANAGER' },
                { label: 'Building Engineer', value: 'BUILDING_ENGINEER' }
            ];

            this.sendToSelf = false;
            this.isSendUsers = false;
            this.isSendCustomEmails = false;
            this.isSendBuildingPersona = false;

            this.initNotifications(this.utilityAccount);

            this.utilityAccountStatusView = this.UtilityServiceHelper.getStatusView(this.utilityAccount.status);

            this.buildings = _.filter(this.buildings, building => building.id != buildingId)

            this.buildingsForUtilityAccount = this.buildings.filter(building => {
                if(_.includes(this.utilityAccountBuildingIds, building.id)) {
                    return building;
                }
            })

            this.utilityAccountBuildings = _.filter(this.buildings, building => _.includes(this.utilityAccountBuildingIds, building.id))

        };

        public updateSelfNotification() {
            let emailRecipients = this.utilityAccount.emailRecipients
            this.sendToSelf ?
            emailRecipients.push(this.UserService.currentUser.id) :
            emailRecipients.splice(emailRecipients.indexOf(this.UserService.currentUser.id), 1);
        }

        public initNotifications(utilityAccount) {
            this.initEmailRecipients(utilityAccount.emailRecipients);
            this.customEmails = utilityAccount.customEmails || [];
            if (this.customEmails.length > 0) {
                this.isSendCustomEmails = true;
            }
            const buildingPersonas = utilityAccount.buildingPersonas
            if (buildingPersonas != null && buildingPersonas.length > 0) { this.isSendBuildingPersona = true; }
        }

        public initEmailRecipients(emailRecipients) {
            const utilityAccountEmailRecipients = emailRecipients || [];
            const currentUserIndex = _.indexOf(utilityAccountEmailRecipients, this.UserService.currentUser.id);
            if (currentUserIndex > -1) {
                this.sendToSelf = true;
            }
            this.notificationUsers = this.users.filter((user) => utilityAccountEmailRecipients.indexOf(user.id) > -1 && user.id != this.UserService.currentUser.id);
            if (this.notificationUsers.length > 0) {
                this.isSendUsers = true;
            }
        }

        public queryUsers(search: string, assignedUsers: UserItem) {
            const searchText = search.toLowerCase();
            const users = this.users.filter((user) => {
                return user.fullName.toLowerCase().indexOf(searchText) > -1
                    && _.findIndex(assignedUsers, { fullName: user.fullName }) === -1;
            });
            return users;
        }

        public updateEmailRecipients() {
            if (this.isSendUsers) {
                this.utilityAccount.emailRecipients = this.notificationUsers.map(user => user.id)
            } else {
                this.utilityAccount.emailRecipients = [];
            }
            if (this.sendToSelf) {
                this.utilityAccount.emailRecipients.push(this.UserService.currentUser.id)
            }
        }

        public updateBuildingPersonas() {
            if (!this.isSendBuildingPersona) {
                this.utilityAccount.buildingPersonas = [];
            }
        }

        public updateCustomEmails() {
            if (!this.isSendCustomEmails) {
                this.utilityAccount.customEmails = [];
            }
        }

        public isValidNotifications() {
            const isValidCustomEmails = !(this.isSendCustomEmails && this.customEmails.length == 0);
            const isValidUsers = !(this.isSendUsers && this.notificationUsers.length == 0);
            const isValidBuildingPersona = !(this.isSendBuildingPersona && this.utilityAccount.buildingPersonas.length == 0);
            const isSend = this.sendToSelf
                || this.isSendBuildingPersona
                || this.isSendUsers
                || this.isSendCustomEmails;

            return isSend
                && isValidBuildingPersona
                && isValidUsers
                && isValidCustomEmails;
        }

        public onAddCustomEmail(item: string) {
            if (!this.validateEmail(item)) {
                this.customEmails = _.filter(this.customEmails, (email) => email != item);
                this.utilityAccount.customEmails = this.customEmails;
            }
        }

        public validateEmail(email) {
            return this.emailRegex.test(String(email).toLowerCase());
        }

        public queryBuildings(searchText) {
            return this.buildings
                .filter(building => building.id !== this.buildingId)
                .filter(building => building.name.toLocaleLowerCase().indexOf(searchText.toString().toLowerCase()) > -1);
        }

        public shareUtilityAccount(ev?) {
            const request = this.getBuildingsDiff();
            const confirm = this.$mdDialog.confirm()
                .title('Are you sure you want to update the buildings for this account?')
                .ariaLabel('Update Account')
                .targetEvent(ev)
                .ok('Ok')
                .cancel('Cancel');
            this.$mdDialog.show(confirm).then(() => {
                return this.RestangularV3
                    .one('utility-accounts', this.utilityAccount.id)
                    .customPOST(request, 'buildings')
                    .then((response) => {
                        this.utilityAccountBuildingIds = this.buildingsForUtilityAccount;
                        this.utilityAccount.shared = this.utilityAccountBuildingIds.length > 1;
                        this.$scope.$emit('UTILITY_ACCOUNT_UPDATED', this.utilityAccount);
                        this.Messages.info('Utility Account updated');
                    })
                    .catch(() => {
                        this.Messages.error('Error sharing Utility Account');
                    });
            });
        }

        public getBuildingsDiff() {
            const removeBuildings = this.utilityAccountBuildings
                .filter(building => building !== this.buildingId as any)
                .filter(building => this.buildingsForUtilityAccount.indexOf(building) < 0)
                .map(building => building.id);
            const addBuildings = this.buildingsForUtilityAccount
                .filter(building => this.utilityAccountBuildings.indexOf(building) < 0)
                .map(building => building.id);
            return { addBuildings, removeBuildings };
        }

        public isSelectedTab(name) {
            return this.currentNavItem == name;
        }
    }
    angular.module('aq.utilityBudgets').controller('UtilityAccountDetailsInfo', UtilityAccountDetailsInfo);
}
