angular
    .module('reportCenter', ['ui.router', 'aq.messages', 'restangular', 'aq.services.errors', 'ngMaterial'])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.reportCenter', {
                url: '/accounts/:accountId/reportCenter',
                templateUrl: 'app/reportCenter/main/main.html',
                controller: 'ReportCenterCtrl as vm',
                abstract: true,
                data: {
                    appName: 'Report Center'
                },
                resolve: {
                    accountId($stateParams: ng.ui.IStateParamsService) {
                        return $stateParams.accountId;
                    },
                    initUserWithPermissions(initUser, waitForAuthToken) {
                        return initUser;
                    },
                    baseReports(Auth, RestangularV3: restangular.IService, waitForAuthToken) {
                        return RestangularV3.all('reports').getList().then((items: aq.reportCenter.Report[]) => {
                            const reportTypes = ['monthlyBuildingEngineer', 'summaryMonthly', 'summaryDaily', 'portfolio'];

                            if (Auth.hasFunctionality('Trending Usage Report')) {
                                reportTypes.push('customDateRange');
                            }
                            if (Auth.hasFunctionality('Operational Summary')) {
                                reportTypes.push('portfolio-alert');
                            }
                            if (Auth.hasFunctionality('Aquicore 3.0 Projects')) {
                                reportTypes.push('projectSummary');
                            }
                            reportTypes.push('utilityNotesMonthlyStatus');
                            reportTypes.push('projectMonthlyStatus');
                            const data = _.filter(items, (item: aq.reportCenter.Report) => _.includes(reportTypes, item.name));
                            return data;
                        });
                    }
                }
            })
            .state('aq.reportCenter.reports', {
                url: '/generated-reports',
                templateUrl: 'app/reportCenter/generated/generated.html',
                controller: 'GeneratedReportsCtrl as vm',
                data: {
                    breadcrumb: 'Reports',
                    icon: '.aq-icons-report-center-reports'
                },
                resolve: {
                    account(
                        Restangular: restangular.IService,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) {
                        return Restangular.one('accounts', $stateParams.accountId).get({ single: true });
                    },
                    reportData(ReportsWidgetService, account: aq.common.models.Account) {
                        return new ReportsWidgetService(account);
                    },
                    recipients(
                        Restangular: restangular.IService,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) {
                        return Restangular.one('accounts', $stateParams.accountId).all('users').getList();
                    },
                    reports(
                        Restangular: restangular.IService,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) {
                        return Restangular.one('accounts', $stateParams.accountId).getList('reports');
                    },
                    authToken(Restangular: restangular.IService, waitForAuthToken) {
                        return Restangular.oneUrl('authToken', '/api/v1/users/me/authToken').get();
                    },
                    buildings(RestangularV3: restangular.IService, waitForAuthToken) {
                        return RestangularV3.one('').getList('buildings', { showInactive: false });
                    },
                    recommendedReports(RestangularV3: restangular.IService, waitForAuthToken) {
                        return RestangularV3.all('reports/recommended').getList();
                    }
                },
                onEnter(Segment, Auth, $state) {
                    Segment.trackPage('Report Center:Reports');
                }
            })
            .state('aq.reportCenter.history', {
                url: '/history?name?module',
                templateUrl: 'app/reportCenter/history/history.html',
                controller: 'ReportCenterHistoryCtrl',
                data: {
                    breadcrumb: 'Report History',
                    icon: '.aq-icons-report-history'
                },
                resolve: {
                    recipients(
                        Restangular: restangular.IService,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) {
                        return Restangular.one('accounts', $stateParams.accountId).all('users').getList();
                    }
                },
                onEnter(Segment) {
                    Segment.trackPage('Report Center:History');
                }
            })
            .state('aq.reportCenter.buildingReports', {
                url: '/building-reports',
                templateUrl: 'app/reportCenter/buildingReports/buildingReports.html',
                controller: 'BuildingReportsCtrl as buildingReportsCtrl',
                data: {
                    breadcrumb: 'Building Report Recipients',
                    icon: '.aq-icons-users'
                },
                resolve: {
                    profiles(
                        Restangular: restangular.IService,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) {
                        return Restangular.one('accounts', $stateParams.accountId).getList('profiles', {
                            inflate: 'user',
                            nocache: new Date().getTime()
                        });
                    },
                    userRoles(Restangular: restangular.IService, $stateParams: ng.ui.IStateParamsService, waitForAuthToken) {
                        return Restangular.one('accounts', $stateParams.accountId).all('UserRoles').getList();
                    },
                    reports(Restangular: restangular.IService, $stateParams: ng.ui.IStateParamsService, waitForAuthToken) {
                        return Restangular.one('accounts', $stateParams.accountId).getList('reports');
                    }
                },
                onEnter(Segment) {
                    Segment.trackPage('Report Center:Configuration');
                }
            })
            .state('aq.reportCenter.scheduledReports', {
                url: '/:baseReportId/config',
                templateUrl: 'app/reportCenter/config/config.html',
                controller: 'ScheduledReportCtrl as vm',
                resolve: {
                    report($stateParams: ng.ui.IStateParamsService, baseReports: aq.reportCenter.Report[]) {
                        const id = $stateParams.baseReportId;
                        return _.find(baseReports, (rpt: aq.reportCenter.Report) => rpt.id == id);
                    },
                    scheduledReports(report: aq.reportCenter.Report, RestangularV3: restangular.IService, waitForAuthToken) {
                        return RestangularV3.one('reports', report.id)
                            .all('scheduled-reports')
                            .getList({ nocache: moment().valueOf() })
                            .then((items: aq.reportCenter.ScheduledReport[]) => {
                                return _.filter(items, (item: aq.reportCenter.ScheduledReport) => item.report == report.id);
                            }); // safety check
                    },
                    buildings(RestangularV3: restangular.IService, waitForAuthToken) {
                        return RestangularV3.one('').getList('buildings', { showInactive: false });
                    },
                    users(RestangularV3: restangular.IService, waitForAuthToken) {
                        return RestangularV3.all('users').getList().catch(() => []);
                    },
                    user(initUser) {
                        return initUser;
                    },
                    authAccess(initUserWithPermissions, Auth) {
                        return Auth.access;
                    }
                }
            });
    });
