'use strict';

angular.module('properties').directive('pointAssignment', function () {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {
            circuit: '=',
            configure: '=',
            onConnect: '&'
        },
        templateUrl: 'app/properties/sources/details/common/breakerPanel/pointAssignment/pointAssignment.html',
        controller: function ($scope) {

            $scope.onMeterConnect = function($event) {
                var params = {
                    type: 'METER',
                    circuitId: $scope.circuit.id,
                    id: $event.draggedIndex
                }
                $scope.onConnect({ event: params });
            }

            $scope.onInputConnect = function($event) {
                var params = {
                    type: 'POINT',
                    circuitId: $scope.circuit.id,
                    id: $event.draggedIndex,
                    inputId: $event.droppedOnIndex
                }
                $scope.onConnect({ event: params });
            }

        }
    }
});