angular.module('properties.sources').controller('SourcesCtrl',
    function ($scope, Restangular, Errors, Messages, SourcesList, PropertySettingsOptions, $tooltip, loading, $translate, $mdDialog, account, building) {

        var firstSourceTooltip = $tooltip(angular.element(document.querySelector('#first-source')), {
            title: 'Create your first source!',
            placement: 'bottom',
            trigger: 'manual'
        })

        $tooltip(angular.element(document.querySelector('#mapped-source-question-mark')), {
            title: $translate.instant('MAPPED_SOURCE'),
            placement: 'right',
            trigger: 'hover'
        });

        $tooltip(angular.element(document.querySelector('#unmapped-source-question-mark')), {
            title: $translate.instant('UNMAPPED_SOURCE'),
            placement: 'bottom',
            trigger: 'hover'
        });

        $scope.hideTooltip = function () {
            firstSourceTooltip.$promise.then(firstSourceTooltip.hide);
        }

        PropertySettingsOptions.init($scope.account).then(function () {
            $scope.options = PropertySettingsOptions.get();
        })

        $scope.refreshList = function () {
            SourcesList.get(Restangular.one("accounts", account.id).one("buildings", building.id)).then(function (sources) {

                if (sources.mapped.childSources.length == 0 && sources.unmapped.length == 0) {
                    firstSourceTooltip.$promise.then(firstSourceTooltip.show);
                } else {
                    firstSourceTooltip.$promise.then(firstSourceTooltip.hide);
                }

                $scope.source = sources.mapped;
                $scope.unmapped = sources.unmapped;

                loading.stop();
            });

            Restangular.one("accounts", account.id).one("buildings", building.id).getList('sources', { inflate: 'circuits,sourceClass', child: true }).then(function (sources) {
                $scope.allSources = sources;
            })
        }

        $scope.$watch('building', function (building) {
            if (!building) return;
            loading.start();
            $scope.refreshList();
        })

        $scope.showAddSource = function (ev) {
            $mdDialog.show({
                preserveScope: true,
                scope: $scope,
                templateUrl: "app/properties/sources/modals/modal-add-source.html",
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controller: 'AddSourceModalCtrl',
                locals: {
                    building: building
                }
            });
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

    });