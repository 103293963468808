namespace aq.ui {

    class _ActivityPrescriptionDisplay {
        public noDataCallback: () => void;
        public contextLoadedCallback: () => void;
        public activity: aq.models.activity.ActivityResponse;
        public prescription: aq.models.prescription.Case;
        public isLoading: boolean;

        /* @ngInject */
        constructor(
            private Messages: aq.services.Messages
        ) {
        }

        public $onInit() {
            const { context } = this.activity;
            try {
                this.prescription = <aq.models.prescription.Case> context.contextObject;
                this.contextLoadedCallback();
            } catch (err) {
                this.noDataCallback();
                this.Messages.error('Data failed to load');
                throw err;
            }
        }
    }

    export const ActivityPrescriptionDisplay: ng.IComponentOptions = {
        controller: _ActivityPrescriptionDisplay,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/activities/contexts/activityPrescriptionDisplay.html',
        bindings: {
            activity: '<',
            noDataCallback: '&',
            contextLoadedCallback: '&'
        }
    };

    angular
        .module('aq.ui')
        .component('activityPrescriptionDisplay', ActivityPrescriptionDisplay);
}
