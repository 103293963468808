namespace aq.admin.dashboards {

    export class DashboardCRUDCtrl {

        /* @ngInject */
        constructor(
            private $scope: DashboardCRUDCtrlScope,
            private $mdDialog: ng.material.IDialogService,
            private $state: ng.ui.IStateService,
            private Messages: aq.services.Messages,
            private Restangular: restangular.IService,
            private dashboard: Dashboard,
            private Errors: any,
            private loading
        ) {
            this.$scope.dashboard = this.dashboard;
            this.$scope.jsonEditorOptions = this.getJsonEditorOptions();
            const config = this.$scope.dashboard.configuration;
            if (config && config.json) {
                try {
                    this.$scope.jsonObject = JSON.parse(config.json);
                } catch (e) {
                    console.log(e);
                }
            }
        }
        public hide() {
            this.$mdDialog.hide();
        }
        public cancel() {
            this.$mdDialog.cancel();
        }
        public getFreshDashboard(id): ng.IPromise<restangular.IElement> {
            return this.Restangular.one('Dashboards', id)
                .get({ inflate: 'account' })
                .then((dashboard) => {
                    return this.Restangular.restangularizeElement(null, dashboard[0], 'Dashboards');
                });
        }
        public save() {
            if (this.$scope.jsonObject instanceof Object) {
                this.dashboard.configuration.json = JSON.stringify(this.$scope.jsonObject);
            }
            this.loading.start();
            this.update(this.$scope.dashboard)
                .then((updatedDashboard) => {
                    this.loading.stop();
                    this.Messages.info('Updated Dashboard');
                    this.$mdDialog.hide(this.getFreshDashboard(updatedDashboard.id));
                });
        }
        public delete() {
            this.Restangular.all('Dashboards').customDELETE(this.$scope.dashboard.id)
                .then(() => {
                    this.loading.stop();
                    this.Messages.info('Deleted Dashboard');
                    this.$mdDialog.hide();
                })
                .catch(() => {
                    this.loading.stop();
                    this.Messages.error('Couldn\'t Delete Dashboard');
                    this.$mdDialog.cancel();
                });
        }
        public isValidJson(jsonString: string): boolean {
            try {
                JSON.parse(jsonString);
                return true;
            } catch (e) {
                // It'll be a SyntaxError, but we really don't care what type.
                return false;
            }
        }
        public viewDashboard(): void {
            this.cancel();
            this.$state.transitionTo('aq.dashboard.configurable', {
                accountId: this.$scope.dashboard.account.id,
                dashboardId: this.$scope.dashboard.id
            });
        }
        public getJsonEditorOptions(): Object {
            const modes = ['form', 'tree', 'code'];
            return { modes };
        }
        // TODO: the model was not being updated from the directive to this controller.  why?
        public changedJson(jsonObject) {
            this.$scope.jsonObject = jsonObject;
        }
        private update(dashboard) {
            return dashboard.put();
        }
    }

    interface DashboardCRUDCtrlScope extends ng.IScope {
        dashboard: Dashboard;
        jsonEditorOptions: Object;
        jsonObject: Object;
    }
}

angular.module('aq.admin').controller('DashboardCRUDCtrl', aq.admin.dashboards.DashboardCRUDCtrl);
