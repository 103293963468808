namespace aq.deviceManagement {

    export class PowerStatusCtrl {
        private powerStatus: PowerStatus[] = [];

        /* @ngInject */
        constructor(
            private $mdDialog: angular.material.IDialogService,
            private powerStatistics: PowerStatistics,
            private timeZone: string
        ) {
            this.powerStatistics.powerSource.forEach(powerSource => {
               if (powerSource.voltage !== null) {
                   this.powerStatus.push({
                       name: `${powerSource.source} Voltage`,
                       value: `${powerSource.voltage}V`
                    });
               }
               if (powerSource.capacity !== null) {
                   this.powerStatus.push({
                       name: `${powerSource.source} Percent Remaining`,
                       value: `${powerSource.capacity}%`
                   });
               }
            });
        }

        public hide() {
            this.$mdDialog.hide();
        }

    }

    angular
        .module('deviceManagement')
        .controller('powerStatusCtrl', PowerStatusCtrl);
}

type PowerStatistics  = {
    dateTime: string;
    powerSource: PowerSource[];
};

type PowerSource = {
    source: 'DC' | 'BATTERY';
    voltage?: number;
    capacity?: number;
};

type PowerStatus = {
    name: string;
    value: string;
};
