'use strict';

angular
    .module('aq.admin.meters',
            [
                'aq.admin.meters.meters',
                'aq.admin.meters.weather',
                'aq.admin.meters.copydata',
                'aq.admin.meters.updatemultiplier',
                'aq.admin.meters.management',
                'aq.admin.meters.deviceclasses',
                'aq.admin.meters.devices'
        ])
    .config(($stateProvider, $urlRouterProvider) => {
        $urlRouterProvider.when('/admin/meters', '/admin/meters/meters');
        $stateProvider
            .state('aq.admin.meters', {
                url: '/meters',
                templateUrl: 'app/admin/meters/main.html',
                data: {
                    appName: 'Admin Meter Management',
                    breadcrumb: 'Meter Management'
                }
            });
    });
