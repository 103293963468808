namespace aq.admin {
    export class EditProjectTemplateCtrl {
        private jsonObject;
        private jsonEditorOptions;
        /* @ngInject */
        constructor(
            private projectTemplate,
            private $mdDialog,
            private ProjectTemplateService: aq.services.ProjectTemplateService,
            private Messages
        ) {
            this.jsonObject = JSON.parse(projectTemplate.json);
            this.jsonEditorOptions = this.getJsonEditorOptions();
        }

        public hide() {
            this.$mdDialog.hide();
        }
        public cancel() {
            this.$mdDialog.cancel();
        }
        public save() {
            if (this.jsonObject instanceof Object) {
                this.projectTemplate.json = JSON.stringify(this.jsonObject);
            }
            return this.ProjectTemplateService.updateProjectTemplate(this.projectTemplate).then((response) => {
                this.Messages.info('Updated Project Template');
                this.$mdDialog.hide();
                return response;
            }).catch((err) => {
                this.Messages.error('Unable to Update Project Template');
            })
        }
        public isValidJson(jsonString: string): boolean {
            try {
                JSON.parse(jsonString);
                return true;
            } catch (e) {
                return false;
            }
        }
        public getJsonEditorOptions(): Object {
            const modes = ['form', 'tree', 'code'];
            return { modes };
        }
        public changedJson(jsonObject) {
            this.projectTemplate.json = jsonObject;
    }
    }

    angular
        .module('aq.admin.accounts.accounts')
        .controller('EditProjectTemplateCtrl', EditProjectTemplateCtrl);
}
