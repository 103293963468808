angular.module('aq.filters.value', []).filter('lastValue', function () {
    return function (input, scope) {
        if (!input) {
            return '-';
        }
        return _.last(input).value;
    }
});


// TODO - remove if note needed
angular.module('aq.filters.formatters')
.filter('last', function() {
    return function(input) {
        return _.last(input);
    }
});