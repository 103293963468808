namespace aq.dashboard.widgets {
    export class SortIndicator {
        public sortValue: string;
        public sortProperty: string;
        public sortAscending: boolean;
        public onSort: () => void;
        public onButtonClick() {
            this.onSort();
        }
    }
    angular.module('aq.dashboard.widgets').component('sortIndicator', {
        controller: SortIndicator,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/common/components/sortIndicator/sortIndicator.html',
        bindings: {
            sortValue: '@',
            sortProperty: '<',
            sortAscending: '<',
            onSort: '&'
        }
    });
}
