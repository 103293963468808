namespace aq.dashboard.widgets {

    interface SvgRect {
        x: number;
        rx: number;
        y: number;
        ry: number;
        width: number;
        height: number;
        fill: string;
        stroke: string;
        strokeWidth: number;
    }

    // tslint:disable-next-line:class-name
    class _PrescriptionSeverity {

        public level: number;
        public maxLevel: number;
        public height: number;
        public width: number;
        public spacing: number;
        public strokeWidth: number;

        public bars: SvgRect[] = [];

        public $onInit() {
            if (!this.level) {
                this.level = 1;
            }

            if (!this.maxLevel) {
                this.maxLevel = 3;
            }

            if (!this.height) {
                this.height = 8;
            }

            if (!this.width) {
                this.width = 38;
            }

            if (!this.spacing) {
                this.spacing = 2;
            }

            if (!this.strokeWidth) {
                this.strokeWidth = 0.5;
            }

            this.setBars();
        }

        public setBars() {
            const x: number = 0;
            const rx: number = 1;
            const ry: number = 1;
            const fillColors: string[] = ['#DDDDDD', '#FFD400', '#FF9D00', '#FF541F'];

            let y = 0;
            for (let i = this.maxLevel; i > 0; i--) {
                const rect: SvgRect = {
                    x,
                    rx,
                    y,
                    ry,
                    width: this.width,
                    height: this.height,
                    fill: this.level >= i ? fillColors[this.level] : fillColors[0],
                    stroke: '#CCCCCC',
                    strokeWidth: this.strokeWidth
                };
                y += this.height + this.spacing;
                this.bars.push(rect);
            }
        }
    }

    export const PrescriptionSeverity: ng.IComponentOptions = {
        controller: _PrescriptionSeverity,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/common/widgets/prescription/prescriptionSeverity.html',
        bindings: {
            level: '<',
            maxLevel: '<',
            height: '<',
            width: '<',
            spacing: '<',
            strokeWidth: '<'
        }
    };

    angular
        .module('aq.dashboard.widgets')
        .component('prescriptionSeverity', PrescriptionSeverity);
}
