'use strict';

angular
    .module('senseware', ['aq.ui'])
    .config(function ($stateProvider) {

        $stateProvider
            .state('aq.senseware', {
                url: 'https://my.senseware.co/dashboard/?auth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiQVFfVVNJUCIsImlzcyI6IlNlbnNld2FyZSIsImlhdCI6MTQ5NzM3NDM0Mn0.SBWCjNfyBRw_vg4JQegSea113vDNqPxcAgrsQgpT7VI',
                external: true
            });

    })
    .run(function ($rootScope, $stateParams, $window) {
        $rootScope.$on('$stateChangeStart',
            function (event, toState) {
                if (toState.external) {
                    event.preventDefault();
                    $window.open(toState.url, '_self');
                }
            });
    });