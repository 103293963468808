namespace aq.admin {
    export class AdminUsersDialogCtrl {
        constructor(
            private $scope,
            private $mdDialog,
            private thisUser,
            private Messages,
            private userTypes,
            private Restangular: restangular.IService,
            private profile,
            private personas,
            private supportTeamRoles) {
            $scope.personas = personas;
            $scope.userTypes = userTypes;
            $scope.selectedUser = thisUser;
            $scope.readyToDelete = false;
            $scope.profile = profile;
            $scope.supportTeamRoles = supportTeamRoles;
            if (profile) {
                $scope.roles = profile['roles'];
                if (profile['hasFullVisibility']) {
                    $scope.visibilities = [{name: 'All Buildings'}];
                } else {
                    $scope.visibilities = profile['visibilities'];
                }
            } else {
                $scope.roles = [{name: 'No Role'}];
                $scope.visibilities = [{name: 'No Profile'}];
            }

            $scope.cancel = () : void => {
                $mdDialog.cancel();
            };

            $scope.save = (user) : void => {
                this.Restangular.one('users', user.id).customPUT(user).then(() => {
                    this.$mdDialog.hide();
                    this.Messages.success('User updated successfully');
                }, (result) => {
                    this.$mdDialog.cancel();
                    this.Messages.error('Cannot update user: ' + captureError(result));
                });
            };
        }
    }

    angular.module('aq.admin').controller('AdminUsersDialogCtrl', AdminUsersDialogCtrl);
}
