/**
 *  Report modal, allows to choose from several reports based on the format,
 *  specify scheduling options and create a scheduled report
 *
 *  @param reportData - ReportsWidgetService instance
 *  @param reportsMetadata - expects an array with report information:
 *   For now each object in that array should have two properties:
 *      name: report name
 *      url: function which will return URL parameter for this report
 *  @param recipients - array of Aquicore users who will receive a report
 *  @param user - creator of the report
 */
angular
    .module('aq.ui.reports.modal', ['aq.ui.reports.service', 'aq.services.options', 'aq.services.user'])
    .directive('reportModal', function ($q, UserService, Messages, Errors, $modal, Restangular) {
    return {
        restrict: 'A',
        scope: {
            account: '=',
            reportData: '=',
            reportsMetadata: '=',
            loadReports: '&',
            report: '=',
            recipients: '=',
            user: '='
        },
        link: reportModal
    };

    function reportModal ($scope, $element) {

        $element.bind('click', function () {

            $scope.newReport = {};
            if ($scope.report) {
                $scope.newReport = angular.copy($scope.report);
                $scope.reportData.initSchedulingData($scope.newReport);
                if ($scope.newReport.reminders && $scope.newReport.reminders[0]) {
                    $scope.newReport.reminders[0].time = moment($scope.newReport.reminders[0].time, "HH:mm:ss");
                }

                if ($scope.newReport.recipients) {
                    let fullRecipientObjects = _.filter(
                        $scope.recipients, function (recipient) {
                            return $scope.newReport.recipients.indexOf(recipient.id) > -1;
                        }
                    ).map( (recipient) => { return recipient.plain(); });
                    $scope.newReport.recipients = fullRecipientObjects;
                }
            } else {
                $scope.newReport.account = $scope.account;
                $scope.newReport.reminders = [];
            }

            $scope.reportData.getReports().then(function (reports) {
                // filter out report types based on reports we have
                $scope.reports = _.filter(reports, function (report) {
                    return _.find($scope.reportsMetadata, {name: report.name});
                });

                if ($scope.reports.length > 0) {
                    $scope.newReport.report = $scope.reports[0].id;
                }

                $scope.isTwelveFormat = !UserService.isTwentyFourFormat();
                $modal({
                    templateUrl: 'app/common/directives/report/reportModal.html',
                    show: true,
                    scope: $scope
                });
            });

        });

        $scope.saveAsCopy = function (newReport, $hide) {
            newReport.id = undefined;
            newReport.name = "Copy of " + newReport.name;
            $scope.saveReport(newReport, $hide).then(function() {
                $scope.loadReports();
            });
        };

        $scope.saveReport = function (newReport, $hide) {
            // This is a "report type"
            var report = _.findById($scope.reports, $scope.newReport.report);
            if(report) {
                var metadata = _.find($scope.reportsMetadata, {name: report.name });
                if (metadata.url) {
                    newReport.parameters = $scope.reportData.getBasicParametersFromUrl(metadata.url());
                }
            }

            var schedulingData = $scope.reportData.parseSchedulingData(newReport);
            if ($scope.newReport.reminders && $scope.newReport.reminders[0]) {
                $scope.newReport.reminders[0].time = moment($scope.newReport.reminders[0].time).format('HH:mm');
            }

            newReport.scheduling = schedulingData;

            if (newReport.id) {
                var r = Restangular.restangularizeElement($scope.account, newReport, 'scheduledReports');
                r.recipients = _.map(r.recipients, 'id');
                return r.put({inflate: 'recipients,account,reminders'}).then(function () {
                    var func = $scope.$eval($scope.loadReports);
                    if (func) {
                        func.then(function () {
                            $hide();
                            Messages.success("Report updated successfully");
                        })
                    } else {
                        $hide();
                        Messages.success("Report updated successfully");
                    }
                }, Errors.forPromise());
            } else {
                newReport.recipients = _.map(newReport.recipients, 'id');
                newReport.account = newReport.account.id;
                return $scope.account.post('scheduledReports', newReport, {inflate: 'recipients,account,reminders'}).then(function (r) {
                    $hide();
                    r.creator = $scope.user;
                    Messages.success("Report saved successfully");
                }, Errors.forPromise());
            }
        };

        $scope.addReminder = function(report) {
            report.reminders.push({
                daysBefore: 3,
                time: moment('08:00:00', 'HH:mm:ss'),
                userSearchText: '',
                selectedUser: undefined,
                usersToRemind: [],
                type: 'SCHEDULED_REPORT'
            });
        };

        $scope.cancelReminder = function(reminder) {
            $scope.newReport.reminders.splice($scope.newReport.reminders.indexOf(reminder));
        };

        $scope.queryUsers = function(searchText, filterChoices) {
            searchText = searchText.toLowerCase();
            return $scope.recipients.filter(function(user) {
                return user.fullName.toLowerCase().indexOf(searchText) > -1 && _.findIndex(filterChoices, {fullName: user.fullName}) === -1;
            });
        };
    }
});
