namespace aq.publicDisplay {

    angular.module('aq.publicDisplay.display', ['aq-main', 'templates-app', 'aq.ui.highChart', 'dashboard'])
        .config(($stateProvider) => {
            $stateProvider.state('display', {
                url: '/public-display?accountId&dashboardId',
                templateUrl: 'app/publicDisplay/display/display.html',
                controller: 'DisplayCtrl as displayCtrl',
                resolve: {
                    account(Restangular: restangular.IService, $stateParams): ng.IPromise<aq.common.models.Account> {
                        return Restangular
                            .one('accounts', $stateParams.accountId)
                            .get({ single: true });
                    },
                    dashboard(account: aq.common.models.Account, $stateParams) {
                        const { dashboardId } = $stateParams;
                        return account
                            .one('dashboards', dashboardId)
                            .get({ single: true, inflate: 'publicDisplay,configuration' })
                            .then((dashboard) => {
                                try {
                                    dashboard.configuration.json = JSON.parse(dashboard.configuration.json);
                                } catch (e) {
                                    dashboard.configuration.json = {};
                                }
                                return dashboard;
                            });
                    },
                    building(
                        account: aq.common.models.Account,
                        RestangularV3: restangular.IService,
                        $stateParams: ng.ui.IStateParamsService
                    ) {
                        const { buildingId, accountId } = $stateParams;
                        if (buildingId) {
                            return account
                                .one('buildings', buildingId)
                                .get({ single: true });
                        }
                        if (accountId) {
                            return RestangularV3
                                .all('buildings')
                                .getList({ accountId });
                        }
                        return null;
                    }
                }
            });
        });
}
