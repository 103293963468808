namespace aq.ui {

    import ActivityActions = aq.models.segment.ActivityActions;
    import ActivityType = aq.models.activity.ActivityType;
    import ContextType = aq.models.activity.ActivityContextType;

    interface ITrackedEvent {
        account: number;
        building: any;
        user: number;
        type?: ActivityType;
        contextType?: ContextType;
    }

    export class ActivityModal {
        public now: number;
        private activity: aq.models.activity.ActivityResponse;
        private activityContextObject: any;
        private modalTitle: string;
        private unsavedChanges: boolean;

        /* @ngInject */
        constructor(
            private Messages: aq.services.Messages,
            private $mdDialog: ng.material.IDialogService,
            private ActivityService: aq.services.ActivityService,
            private PrescriptionService: aq.services.PrescriptionService,
            private resolver: aq.services.Resolver,
            private simpleActivityResponse: aq.models.activity.SimpleActivityResponse,
            private activityId: string,
            private accountId: number,
            private buildingId: any,
            private $scope: ng.IScope,
            private Segment: aq.services.SegmentService,
            private UserService: aq.services.UserService,
            private refreshActivities
        ) { }

        public $onInit() {
            this.getActivity();
            this.unsavedChanges = false;

            const localThis = this;
            this.$scope.$on('ActivityContextLoaded', function(evt, data) {
                localThis.setActivityContextObject(data);
            });
        }

        public setUnsavedChanges(value: boolean) {
            this.unsavedChanges = value;
        }

        public getActivity() {
            let activityId: string;
            if (this.simpleActivityResponse !== null) {
                activityId = this.simpleActivityResponse.id;
            } else if (this.activityId) {
                activityId = this.activityId;
            }

            const trackedEvent: ITrackedEvent = {
                account: this.accountId,
                building: this.buildingId,
                user: this.UserService.currentUser.id
            };

            if (activityId) {
                this.resolver(async () => {
                    this.activity = await this.ActivityService.getActivity(activityId);
                    const { type, context: { type: contextType } } = this.activity;
                    if (contextType === 'ALERT') {
                        this.Segment.trackChurnZeroEvent(ActivityActions.ACKNOWLEDGE_ALERT, [this.buildingId]);
                    } else {
                        this.Segment.trackChurnZeroEvent(ActivityActions.VIEW_ACTIVITY, [this.buildingId]);
                    }
                    this.$scope.$apply();
                });
            } else {
                // Rx's do not natively get ActivityId because they're pulled from external source
                const { id, title, type, context, createdAt, updatedAt } = this.simpleActivityResponse;
                const prescription = context.contextObject && (<aq.models.prescription.Case> context.contextObject);
                const { case_comments } = prescription;
                const messages: aq.models.activity.ActivityMessage[] = case_comments.map(cc => {
                    const result: aq.models.activity.ActivityMessage = {
                        id: null,
                        parent: null,
                        authorId: null,
                        author: {
                            id: null,
                            firstName: cc.created_by.first_name,
                            lastName: cc.created_by.last_name,
                            userName: this.PrescriptionService.getUserDisplayName(cc.created_by)
                        },
                        createdAt: cc.created,
                        updatedAt: cc.updated,
                        body: cc.content,
                        media: []
                    };
                    return result;
                });

                this.activity = {
                    building: this.buildingId,
                    id: parseInt(id),
                    title,
                    type,
                    context,
                    createdAt,
                    updatedAt,
                    messages
                };

                const { type: contextType } = context;
                this.Segment.trackChurnZeroEvent(ActivityActions.VIEW_ACTIVITY, [this.buildingId]);
            }
        }

        public error(message: string): void {
            this.Messages.error(message);
        }

        public success(message: string): void {
            this.Messages.success(message);
        }

        public warn(message: string): void {
            this.Messages.warn(message);
        }

        public hide(result: any): void {
            this.$mdDialog.hide(result);
        }

        public cancel(): void {
            this.$mdDialog.cancel();
        }

        public callback() {
            this.getActivity();
            this.unsavedChanges = false;
        }

        private closeDialog() {
            if (this.unsavedChanges) {
                this.Messages.prompt('You have unsaved changes', () => this.cancel())
            } else {
                this.cancel();
            }
        }

        private getModalTitle(): string {
            const activity = this.activity || this.simpleActivityResponse;
            if (!activity.title) {
                return 'Activity';
            }
            const contextTypeStr = this.ActivityService.getContextTypeStringForActivity(activity);
            return (contextTypeStr && `${contextTypeStr}: ${activity.title}`) || activity.title;
        }

        private setActivityContextObject(data) {
            if (!data) return;
            this.activityContextObject = data;
            this.$scope.$apply();
        }

        private allowMessageReplies(): boolean {
            const { context: { type } } = this.activity;
            if (type && aq.models.activity.ActivityContextType.RX === type) {
                return false;
            }
            return true;
        }
    }

    angular
        .module('aq.ui')
        .controller('activityModal', ActivityModal);
}
