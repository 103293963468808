namespace aq.propertySettings {
    export class BuildingDetailMechanicalCtrl {
        private isAqAdmin: boolean;
        constructor(
            public Messages: aq.services.Messages,
            public building: aq.common.models.Building,
            public BuildingService: aq.services.BuildingService,
            private allEnums,
            private Auth: aq.services.Auth,
            private UserService
        ) {
            this.isAqAdmin = this.UserService.isAdmin();
        }

        public save() {
            if (this.building.mainSourceOfCooling === 'CHILLERS' && !(this.building.numberOfChillers || this.building.numberOfChillers === 0)) {
                this.Messages.error('You must enter a valid number of chillers');
            } else {
                this.BuildingService.updateBuilding(this.building)
                    .then(() => {
                        this.Messages.success('Successfully updated mechanical information');
                    })
                    .catch(() => {
                        this.Messages.error('Unable to update mechanical information');
                    });
            }
        }


    }
    angular
        .module('properties')
        .controller('BuildingDetailMechanicalCtrl', BuildingDetailMechanicalCtrl);
}
