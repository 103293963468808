namespace aq.dashboard.widgets {



    enum DateRange {
        ALL_TIME = 'ALL_TIME',
        TRAILING_3_MONTHS = 'TRAILING_3_MONTHS',
        TRAILING_12_MONTHS = 'TRAILING_12_MONTHS'
    }

    interface CalcItem {
        savingsTitle: string;
        savings: number;
        costTitle: string;
        cost: number;
        impactTitle: string;
        impact: number;
        suffix: string;
    }

    interface SummaryItem {
        title: string;
        projectCount: number;
        calcItems: CalcItem[];
        paybackYears?: number;
    }

    interface StatusDetailsItem {
        caption: string;
        dataValueDisplay: string;
        dataValueIcon: string;
        diffValue: number;
        diffValueInfo: string;
        diffValueDisplay: string;
        hideDiffInfo: boolean;
    }

    export class ProjectSummaryWidgetCtrl {
        private isLoadingData: boolean;
        private selectedBuildingNames: string[];
        private displayBuildings: string[];
        private readonly displayBuildingsCount = 5;
        private intervals: DateRange[];
        private intervalLabels: Record<string, string>;
        private selectedInterval: DateRange;
        private startFormatted: string;
        private endFormatted: string;
        private selectedBuildingIds: string[];
        private request: aq.models.projects.ProjectStatusDetailsRequest;
        private statuses: {
            id: string;
            name: string;
        }[];
        private completedStatusId: string;
        private inProgressStatusId: string;
        private statusDetailsItems: StatusDetailsItem[];

        /* @ngInject */
        constructor(
            private $scope: ProjectSummaryWidgetCtrlScope,
            private config,
            private account: aq.common.models.Account,
            private buildings: aq.common.models.Building[],
            private $filter: ng.IFilterService,
            private ModelUtilService: aq.services.ModelUtilService,
            private ProjectService: aq.projectCenter.ProjectService,
            private ProjectServiceV3: aq.services.ProjectServiceV3,
            private users: aq.common.models.User[],
            private projectTypes: aq.common.models.SimpleEnum,
            private buildingGroups,
            private BuildingSelectorActions: aq.services.BuildingSelectorActions,
            private isProjectsV3: boolean,
            private Auth: aq.services.Auth,
            private $q: ng.IQService
        ) {
            this.intervals = [
                DateRange.TRAILING_3_MONTHS,
                DateRange.TRAILING_12_MONTHS,
                DateRange.ALL_TIME,
            ];
            this.intervalLabels = {
                [DateRange.TRAILING_3_MONTHS]: 'Trailing 3 months',
                [DateRange.TRAILING_12_MONTHS]: 'Trailing 12 months',
                [DateRange.ALL_TIME]: 'All time',
            }
            this.request = {} as any;

            this.isLoadingData = true;
            this.selectedBuildingNames = [];
            config.options = {
                isProjectsV3: this.isProjectsV3,
                buildings: ModelUtilService.pareProperties(this.buildings, ['buildingGroup']),
                buildingGroups: ModelUtilService.pareProperties(this.buildingGroups),
                dateRanges: this.isProjectsV3 && this.getDateRangeItems()
            };
            if (this.isProjectsV3 && !config.filterBy) {
                config.filterBy = {
                    dateRange: DateRange.TRAILING_3_MONTHS
                };
            }

            this.BuildingSelectorActions.initDefaultBuildingSelection(config);
            this.$scope.config = config;
            this.$scope.config.actions = this.BuildingSelectorActions;
            if (this.isProjectsV3) {
                this.selectedInterval = config.filterBy.dateRange;
                if (this.selectedInterval !== DateRange.TRAILING_3_MONTHS
                    && this.selectedInterval !== DateRange.TRAILING_12_MONTHS
                    && this.selectedInterval !== DateRange.ALL_TIME
                ) {
                    this.selectedInterval = DateRange.TRAILING_3_MONTHS;
                }
                this.selectedBuildingIds = config.buildingIds;
                this.request.buildingIds = this.selectedBuildingIds;
                this.initDateRangeDisplay();
                this.populateSummaryV3();
            } else {
                this.populateSummary();
            }
        }

        public selectInterval(interval: DateRange) {
            this.selectedInterval = interval;
            this.initDateRangeDisplay();
            this.populateSummaryV3();
        }

        private initDisplayBuildings() {
            this.displayBuildings = _.take(this.selectedBuildingNames, this.displayBuildingsCount);
        }

        private initStatusDetailsItems() {
            this.statusDetailsItems = [
                {
                    caption: 'Total Year-One Impact',
                    dataValueDisplay: '-',
                    dataValueIcon: null,
                    diffValue: 0,
                    diffValueInfo: '',
                    diffValueDisplay: '',
                    hideDiffInfo: true
                },
                {
                    caption: 'Total projects completed',
                    dataValueDisplay: '-',
                    dataValueIcon: 'golf_course',
                    diffValue: 0,
                    diffValueInfo: '',
                    diffValueDisplay: '',
                    hideDiffInfo: true
                },
                {
                    caption: 'Median time to completion',
                    dataValueDisplay: '-',
                    dataValueIcon: 'schedule',
                    diffValue: 0,
                    diffValueInfo: '',
                    diffValueDisplay: '',
                    hideDiffInfo: true
                }
            ];
        }

        private getDateRangeItems() {
            return Object.keys(DateRange).map(key => DateRange[key]);
        }

        private initDateRangeDisplay() {
            this.request.endDate = moment().toISOString();
            switch (this.selectedInterval) {
                case DateRange.ALL_TIME:
                    this.request.startDate = null;
                    break;
                case DateRange.TRAILING_12_MONTHS:
                    this.request.startDate = moment().subtract(12, 'months').toISOString();
                    break;
                case DateRange.TRAILING_3_MONTHS:
                default:
                    this.request.startDate = moment().subtract(3, 'months').toISOString();
                    break;
            }
            this.startFormatted = this.request.startDate && moment(this.request.startDate).format('M/D/YYYY');
            this.endFormatted = 'Today';
        }

        private async populateProjectsV3() {
            this.$scope.projects = [];
            const threeMonthsAgo = moment().subtract(3, 'month');
            const aYearAgo = moment().subtract(1, 'year');
            this.$scope.config.buildingIds.forEach((building) => {
                this.ProjectServiceV3.getProjects(building).then(projectResponse => {
                    const filteredProjects = projectResponse.projects.filter((project: aq.models.projects.Project) => {
                        if (project.status.name === 'Completed') {
                            const completed = project.completedOn ? moment(project.completedOn) : null;
                            switch (this.selectedInterval) {
                                case DateRange.TRAILING_3_MONTHS:
                                    return threeMonthsAgo.isSameOrBefore(completed);
                                case DateRange.TRAILING_12_MONTHS:
                                    return aYearAgo.isSameOrBefore(completed);
                                case DateRange.ALL_TIME:
                                default:
                                    return true;
                            }
                        }
                        return false;
                    });
                    this.$scope.projects.push(...filteredProjects);
                });
            });
        }

        private async populateSummaryV3() {
            this.initStatusDetailsItems();
            await this.populateProjectsV3();
            if (this.selectedBuildingIds.length == 0) {
                return;
            }
            if (!this.statuses) {
                const buildingId = this.selectedBuildingIds[0] as unknown as number;
                // TODO: get statuses directly
                const projectResponse = await this.ProjectServiceV3.getProjects(buildingId);
                this.statuses = projectResponse && projectResponse.statuses;
                this.completedStatusId = this.statuses.find((status) => status.name == 'Completed').id;
                this.inProgressStatusId = this.statuses.find((status) => status.name == 'In Progress').id;
            }
            this.request.buildingIds = this.selectedBuildingIds;
            this.request.statusIds = [this.completedStatusId];

            const data = await this.ProjectServiceV3.getStatusDetails(this.request);
            let trailingData = null;

            if (this.request.startDate) {
                const trailingEnd = moment(this.request.startDate);
                const diff = moment(this.request.endDate).diff(moment(this.request.startDate), 'months');
                const trailingStart = moment(trailingEnd).subtract(diff, 'months');

                const trailingRequest = { ...this.request };
                trailingRequest.startDate = trailingStart.toISOString();
                trailingRequest.endDate = trailingEnd.toISOString();
                trailingData = await this.ProjectServiceV3.getStatusDetails(trailingRequest);
            }

            let dataSavings = 0;
            const dataTimeList = [];
            if (this.Auth.hasFunctionality(PROJECT_ESTIMATION)) {
                this.$scope.projects.forEach(project => {
                    dataSavings += this.ProjectServiceV3.getImpactValue(project, true, true).projectImpact;
                })
            }
            data.forEach(item => {
                if (!this.Auth.hasFunctionality(PROJECT_ESTIMATION)) {
                    dataSavings += item.dollarSavingsMax || 0;
                }
                if (item.timeInStatus[this.inProgressStatusId]) {
                    dataTimeList.push(item.timeInStatus[this.inProgressStatusId] || 0);
                }
            });
            let trailingDataSavings = 0;
            const trailingDataTimeList = [];
            if (trailingData) {
                trailingData.forEach(item => {
                    trailingDataSavings += item.dollarSavings || 0;
                    if (item.timeInStatus[this.inProgressStatusId]) {
                        trailingDataTimeList.push(item.timeInStatus[this.inProgressStatusId] || 0);
                    }
                });
            }

            const dataSavingsDiff = dataSavings - trailingDataSavings;

            const dataTime = this.getMedian(dataTimeList);
            const trailingDataTime = this.getMedian(trailingDataTimeList);

            const dataTimeDays = this.getDaysFromMilliseconds(dataTime);
            const trailingDataTimeDays = this.getDaysFromMilliseconds(trailingDataTime);
            const dataTimeDiff = dataTimeDays - trailingDataTimeDays;
            const dataTimeDiffAbs = Math.abs(dataTimeDiff);

            const dataCount = this.Auth.hasFunctionality(PROJECT_ESTIMATION) ? this.$scope.projects.length : data.length;
            const trailingDataCount = trailingData && trailingData.length;
            const dataCountDiff = dataCount - trailingDataCount;

            let sinceInfo = '';
            if (this.request.startDate && this.request.endDate) {
                const diff = moment(this.request.endDate).diff(moment(this.request.startDate), 'months');
                sinceInfo = `since trailing ${diff} months`;
            }

            this.statusDetailsItems = [
                {
                    caption: 'Total Year-One Impact',
                    dataValueDisplay: this.$filter('currency')(dataSavings, '$', 0),
                    dataValueIcon: null,
                    diffValue: dataSavingsDiff,
                    diffValueInfo: sinceInfo,
                    diffValueDisplay: this.$filter('currency')(Math.abs(dataSavingsDiff), '$', 0),
                    hideDiffInfo: !trailingData
                },
                {
                    caption: 'Total projects completed',
                    dataValueDisplay: dataCount.toString(),
                    dataValueIcon: 'golf_course',
                    diffValue: dataCountDiff,
                    diffValueInfo: sinceInfo,
                    diffValueDisplay: Math.abs(dataCountDiff).toString(),
                    hideDiffInfo: !trailingData
                },
                {
                    caption: 'Median time to completion',
                    dataValueDisplay: `${dataTimeDays} day${dataTimeDays === 1 ? '' : 's'}`,
                    dataValueIcon: 'schedule',
                    diffValue: dataTimeDiff,
                    diffValueInfo: sinceInfo,
                    diffValueDisplay: `${dataTimeDiffAbs} day${dataTimeDiffAbs === 1 ? '' : 's'}`,
                    hideDiffInfo: !trailingData
                }
            ];
            this.$scope.$apply();
        }

        private getMedian(values: number[]) {
            if (!values || values.length === 0) {
                return 0;
            }
            const sortedValues = values.sort((a, b) => a - b);
            if (sortedValues.length % 2 === 1) {
                const index = (sortedValues.length - 1) / 2;
                return sortedValues[index];
            }
            const index1 = Math.floor((sortedValues.length - 1) / 2);
            const index2 = Math.ceil((sortedValues.length - 1) / 2);
            return Math.round((sortedValues[index1] + sortedValues[index2]) / 2);
        }

        private getDaysFromMilliseconds(ms: number) {
            const dayMilliseconds = 86400000; // 1000 * 60 * 60 * 24;
            return Math.ceil(ms / dayMilliseconds);
        }

        private populateSummary() {
            this.$scope.numberCompletedProjects = 0;
            this.$scope.numberInProgressProjects = 0;
            this.$scope.numberPlannedProjects = 0;
            this.$scope.totalInProgressCost = 0;
            this.$scope.totalPlannedCost = 0;
            this.$scope.totalCompletedCost = 0;
            this.$scope.totalSavings = 0;
            this.$scope.totalInProgressSavings = 0;
            this.$scope.totalPlannedSavings = 0;
            this.$scope.totalCompletedSavings = 0;
            this.config.buildingIds.forEach((buildingId) => {
                this.ProjectService.getList(buildingId, this.users, this.projectTypes).then((projects: aq.propertySettings.Project[]) => {
                    projects.forEach((project) => {
                        if (project.status === 'PLANNED') {
                            this.$scope.numberPlannedProjects++;
                            this.$scope.totalPlannedCost += project.cost;
                            this.$scope.totalPlannedSavings += project.expectedSavings;
                        } else if (project.status === 'IMPLEMENTATION' || project.status === 'MEASUREMENT') {
                            this.$scope.numberInProgressProjects++;
                            this.$scope.totalInProgressCost += project.cost;
                            this.$scope.totalInProgressSavings += project.expectedSavings;
                        } else if (project.status === 'COMPLETED') {
                            this.$scope.numberCompletedProjects++;
                            this.$scope.totalCompletedCost += project.cost;
                            this.$scope.totalCompletedSavings += project.expectedSavings;
                        }
                        this.$scope.totalSavings += project.expectedSavings;
                    });

                });
            });
        }
    }

    angular.module('aq.dashboard.widgets').controller('ProjectSummaryWidgetCtrl', ProjectSummaryWidgetCtrl);

    export interface ProjectSummaryWidgetCtrlScope extends ng.IScope {
        config: any;
        totalPlannedCost: number;
        totalInProgressCost: number;
        totalCompletedCost: number;
        numberPlannedProjects: number;
        numberInProgressProjects: number;
        numberCompletedProjects: number;
        totalPlannedSavings: number;
        totalInProgressSavings: number;
        totalCompletedSavings: number;
        totalSavings: number;
    }
}
