angular.module('properties').controller('MeterAssignmentsCtrl',
    function ($scope, Restangular, Messages, Errors, DataService, $state, building, account, Auth) {

        $scope.isReadonly = !Auth.check({ access: 'EDIT' });
        $scope.configure = {
            showCircuits: false
        };

        const updateAssignableMeters = (measure?) => {
            $scope.source.customGET('queryAssignableMeters', { inflate: 'points,points.collector' }).then(function(meters) {
                $scope.meters = measure
                    ? meters.filter((meter) => meter.metrics.indexOf(measure) > -1)
                    : meters;
            });
        };

        $scope.$watch('source', function(source) {
            if (!source) return;
            const measure = source.sourceClass ? source.sourceClass.measure : null;
            updateAssignableMeters(measure);

            $scope.load = _.filter($scope.source.circuits, function(c) {
                return c.type == 'LOAD';
            })[0];

            $scope.feed = _.filter($scope.source.circuits, function(c) {
                return c.type == 'FEED';
            })[0];

            $state.get('aq.properties.buildings.sources.details.meterassignments').data.breadcrumb = source.name;
        });

        $scope.$watch('source.circuits', (circuits) => {
            if (!circuits) return;

            $scope.selection = {
                from: moment().subtract(10, 'minute').format(),
                to: moment().format()
            };

            const params = {
                interval: '1min',
                start: $scope.selection.from,
                end: $scope.selection.to,
                measure: 'KWH'
            };

            $scope.source.customGET('circuits/data', params).then((res) => {
                _.each(circuits, function(c) {
                    const data = _.findById(res, c.id);
                    c.chartData = _.zip(data.timestamps, data.values);
                });
            });

            $scope.source.feeds = _.filter(circuits, (c) => {
                return c.type == 'FEED';
            });
        });

        $scope.updateCircuit = function(circuit) {

            if (!circuit.circuit.resource) return;
            var circuit = Restangular.restangularizeElement($scope.source, circuit.circuit, 'circuits');

            if (circuit.resource.type == undefined) {
                circuit.resource.type = null;
                circuit.resource.name = '';
            }

            if (circuit.resource.type) {
                if (circuit.resource.type.name == 'Child Source' && !circuit.selectedChildSource) {
                    return;
                }

                if (circuit.resource.type.name == 'Child Source') {
                    circuit.resource = circuit.selectedChildSource;
                    circuit.resource.classType = 'Source';
                    circuit.resource.type = { id: 0, name: 'Child Source' };
                    return circuit.put({ inflate: 'resource' }).catch(Errors.forPromise());
                }

                if (!circuit.resource.name) circuit.resource.name = circuit.resource.type.name;
            }

            // update existing circuit
            if (circuit.resource.id) {
                return circuit.put({ inflate: 'resource' });
            }

            const component = {
                source: $scope.source.id,
                type: circuit.resource.type,
                name: circuit.resource.name
            };

            Restangular
                .one('accounts', account.id)
                .one('buildings', building.id)
                .post('components', component, { inflate: 'type' })
                .then((result) => {
                    circuit.resource.classType = 'Component';
                    circuit.resource.id = result.model.id;
                    circuit.resource.type = result.model.type;
                    circuit.resource.source = $scope.source.id;
                    circuit.resource.space = result.model.space;
                    return circuit.put({ inflate: 'resource' });
                }, Errors.forPromise());
        };

        $scope.droppedOnConnector = function($event) {
            // handler for case when this function is called from directive,
            // we need to pass is as a object in object
            if ($event.event) $event = $event.event;

            let circuitId;
            let inputId;
            const queryParams: any = {};

            if ($event.type == 'METER') {
                queryParams.collectorId = $event.id;
                circuitId = $event.circuitId;
            } else if ($event.type == 'POINT') {
                queryParams.pointId = $event.id;
                circuitId = $event.circuitId;
                inputId = $event.inputId;
            } else {
                queryParams.collectorId = $event.draggedIndex;
                circuitId = $event.droppedOnIndex;
            }

            let circuit = _.findById($scope.source.circuits, circuitId);
            // restangularize element if not restangularized
            if (!circuit.route) {
                circuit = Restangular.restangularizeElement($scope.source, circuit, 'circuits');
            }

            let circuitOrInput = circuit;
            if (inputId) {
                const input = _.findById(circuit.inputs, inputId);
                circuitOrInput = Restangular.restangularizeElement(circuit, input, 'inputs');
            }

            circuitOrInput.customPUT(null, 'connect', queryParams).then(() => {
                updateAssignableMeters($scope.source.sourceClass.measure);
                $scope.getSource();
            }, Errors.forPromise());

        };

        $scope.detachMeter = function($event) {
            const allInputs = _.chain($scope.source.circuits).map('inputs').flatten().value();
            const input = _.findById(allInputs, $event.draggedIndex);
            const circuitId = input ? input.circuit : $event.draggedIndex;
            const circuit = _.findById($scope.source.circuits, circuitId);

            let circuitOrInput = Restangular.restangularizeElement($scope.source, circuit, 'circuits');

            if (input) {
                circuitOrInput = Restangular.restangularizeElement(circuit, input, 'inputs');
            }

            circuitOrInput.customPUT(null, 'disconnect', null).then(() => {
                updateAssignableMeters($scope.source.sourceClass.measure);
                $scope.getSource();
            }, Errors.forPromise());

        };

        $scope.merge = function(circuits) {

            const mergeData = {
                id1: circuits.circuit1.id,
                id2: circuits.circuit2.id
            };

            $scope.source.customPUT(null, 'circuits/merge', mergeData).then(() => {
                $scope.getSource();
            }, (response) => {
                Messages.error(response.data.message);
            });

        };

        $scope.split = function(circuit) {
            const circuitElement = Restangular.restangularizeElement($scope.source, circuit.circuit, 'circuits');
            circuitElement.customPUT(null, 'split').then(() => {
                $scope.getSource();
            }, (response) => {
                Messages.error(response.data.message);
            });
        };

        $scope.expandMeter = function(meterId, isLast) {
            $scope.configure.expandedMeterId ? $scope.configure.expandedMeterId = null : $scope.configure.expandedMeterId = meterId;

            if (isLast) {
                setTimeout(() => {
                    const elem = angular.element(angular.element(document.querySelector('.meter-list')));
                    elem.scrollTop(240);
                }, 0);
            }

        };

        $scope.showExpandMeter = function(meter) {

            // TODO: move this property to sourceClass model
            if ($scope.source.sourceClass.layout != 'breakerpanel' &&
                $scope.source.sourceClass.layout != 'switchgear' &&
                $scope.source.sourceClass.layout != 'disconnectswitch') return false;

            const haveOutputPoint = _.filter(meter.points, { 'type': 'OUTPUT' }).length > 0;
            return !haveOutputPoint;
        };

    });
