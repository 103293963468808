var aq;
(function (aq) {
    var deviceManagement;
    (function (deviceManagement) {
        var AddDeviceLink = /** @class */ (function (_super) {
            __extends(AddDeviceLink, _super);
            /* @ngInject */
            function AddDeviceLink($mdDialog, device, links, DeviceService, DataStore, $mdStepper, RestangularV3, createLink, showCreateAnother) {
                if (showCreateAnother === void 0) { showCreateAnother = true; }
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.links = links;
                _this.DeviceService = DeviceService;
                _this.DataStore = DataStore;
                _this.$mdStepper = $mdStepper;
                _this.RestangularV3 = RestangularV3;
                _this.linkRequest = {
                    parent: null,
                    child: null,
                    inputId: null,
                    protocol: null
                };
                _this.formModel = {
                    protocol: '',
                    connectionType: '',
                    createLink: false
                };
                _this.FROM = {
                    availablePulseInputs: [],
                    isParent: null,
                    category: '',
                    needsDeviceIdentifier: null,
                    device: null,
                    inputId: ''
                };
                _this.TO = {
                    availablePulseInputs: [],
                    isParent: null,
                    category: '',
                    needsDeviceIdentifier: null,
                    device: null,
                    inputId: ''
                };
                _this.formModel.createLink = createLink || false;
                _this.setFROMFromDevice(device);
                _this.toId = null;
                return _this;
            }
            AddDeviceLink.prototype.setFROMFromDevice = function (device) {
                var _this = this;
                this.FROM.device = this.RestangularV3.copy(device);
                this.FROM.category = device.deviceCategory;
                this.FROM.needsDeviceIdentifier = this.needsDeviceIdentifier(device);
                this.DeviceService.getAvailablePulseInputs(device)
                    .then(function (val) { return _this.FROM.availablePulseInputs = val; });
            };
            AddDeviceLink.prototype.setTOFromDeviceId = function (deviceId) {
                var _this = this;
                this.DeviceService
                    .get(deviceId)
                    .then(function (device) {
                    _this.TO.device = device;
                    _this.TO.category = device.deviceCategory;
                    _this.TO.needsDeviceIdentifier = _this.needsDeviceIdentifier(device);
                    return _this.DeviceService.getAvailablePulseInputs(device);
                })
                    .then(function (availablePulseInputs) {
                    _this.TO.availablePulseInputs = availablePulseInputs;
                });
            };
            AddDeviceLink.prototype.needsDeviceIdentifier = function (device) {
                if (_.get(device, 'deviceClass.fields.deviceIdentifier', false)) {
                    return !_.get(device, 'deviceClass.fields.deviceIdentifier.value', '');
                }
                return false;
            };
            // utility methods to reset the form state
            AddDeviceLink.prototype.connectionTypeChanged = function () {
                if (this.formModel.protocol) {
                    this.formModel.protocol = null;
                }
                if (!_.isNil(this.TO.device)) {
                    this.TO = null;
                }
            };
            AddDeviceLink.prototype.protocolTypeChanged = function () {
                if (this.FROM.inputId) {
                    this.FROM.inputId = null;
                }
                if (!_.isNil(this.TO.device)) {
                    this.TO = null;
                }
            };
            AddDeviceLink.prototype.saveFROM = function () {
                if (this.FROM.needsDeviceIdentifier && _.get(this.FROM.device, 'fields.deviceIdentifier.value', false)) {
                    this.DeviceService.save(this.FROM.device);
                }
            };
            AddDeviceLink.prototype.finalizeAndSubmit = function () {
                var _this = this;
                this.DeviceService.save(this.TO.device).then(function () {
                    _this.linkRequest.protocol = _this.formModel.protocol;
                    if (_this.TO.device.gateway) {
                        return _this.linkParentChild(_this.TO, _this.FROM);
                    }
                    if (_this.FROM.device.gateway) {
                        return _this.linkParentChild(_this.FROM, _this.TO);
                    }
                    if (_this.FROM.category == 'METER' || _this.FROM.category === 'SENSOR') {
                        return _this.linkParentChild(_this.TO, _this.FROM);
                    }
                    if (_this.FROM.category == 'NETWORKING' && (_this.TO.category == 'METER' || _this.TO.category == 'SENSOR')) {
                        return _this.linkParentChild(_this.FROM, _this.TO);
                    }
                    // example case: 8911 to bridge -- need to check up/down protocols
                    if (_this.FROM.category == 'NETWORKING' && _this.TO.category == 'NETWORKING') {
                        if (_.some(_this.FROM.device.deviceClass.upwardCommunications, function (protocol) {
                            return _.toUpper(protocol) == _.toUpper(_this.formModel.protocol);
                        })) {
                            return _this.linkParentChild(_this.TO, _this.FROM);
                        }
                        else {
                            return _this.linkParentChild(_this.FROM, _this.TO);
                        }
                    }
                });
            };
            AddDeviceLink.prototype.cancel = function (data) {
                this.$mdDialog.cancel(data);
            };
            AddDeviceLink.prototype.hide = function (data) {
                this.$mdDialog.hide(data);
            };
            AddDeviceLink.prototype.next = function () {
                this.$mdStepper('linkStepper').next();
            };
            AddDeviceLink.prototype.back = function () {
                this.$mdStepper('linkStepper').back();
            };
            AddDeviceLink.prototype.shouldUseDeviceIdentifier = function (protocol) {
                return ['BACNET', 'MODBUS', 'BACNET IP'].indexOf(protocol) > -1;
            };
            AddDeviceLink.prototype.linkParentChild = function (parent, child) {
                this.linkRequest.parent = parseInt(parent.device.id);
                this.linkRequest.child = parseInt(child.device.id);
                this.linkRequest.inputId = this.shouldUseDeviceIdentifier(this.linkRequest.protocol)
                    ? child.device.fields.deviceIdentifier.value
                    : parent.inputId;
                return this.hide({
                    createLink: this.formModel.createLink,
                    link: this.linkRequest
                });
            };
            return AddDeviceLink;
        }(aq.common.Controllers.ModalCtrl));
        deviceManagement.AddDeviceLink = AddDeviceLink;
        angular
            .module('deviceManagement')
            .controller('AddDeviceLink', AddDeviceLink);
    })(deviceManagement = aq.deviceManagement || (aq.deviceManagement = {}));
})(aq || (aq = {}));
