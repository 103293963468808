namespace aq.tenantbilling {
    export interface TenantBillingSetupStateParamsService extends ng.ui.IStateParamsService {
        accountId: string;
        buildingId: string;
    }
    interface FeeType {
        label: string;
        unit: string;
        value: string;
    }
    export class TenantBillingSetupService {
        public feeTypes: FeeType[];
        /* @ngInject */
        constructor(
            private $stateParams: TenantBillingSetupStateParamsService,
            private Messages: aq.services.Messages,
            private Errors,
            private DataStore: aq.common.DataStore,
            private RestangularV3: restangular.IService,
            private OptionsService
        ) {
            this.feeTypes = [{
                label: 'Per Tenant',
                unit: 'Tenant',
                value: 'PER_TENANT'
            }, {
                label: 'Per Meter',
                unit: 'Meter',
                value: 'PER_METER'
            }, {
                label: 'Per Sq. Ft.',
                unit: 'Sq. Ft.',
                value: 'PER_SQ_FT'
            }, {
                label: '% of Metered Usage Cost',
                unit: '%',
                value: 'PCT_METERED_USAGE'
            }];
        }
        public getFeeTypes(): FeeType[] {
            return angular.copy(this.feeTypes);
        }
        public getFeeTypeLabel(feeTypeValue) {
            const feeType: FeeType = _.find(this.feeTypes, (ft: FeeType) => ft.value == feeTypeValue);
            return feeType ? feeType.label : '';
        }
        public getFeeTypeUnit(feeTypeValue) {
            const feeType: FeeType = _.find(this.feeTypes, (ft: FeeType) => ft.value == feeTypeValue);
            return feeType ? feeType.unit : '';
        }
        public getPrefixForFeeType(feeTypeValue: string) {
            let prefix = '';
            const feeType = _.find(this.feeTypes, { value: feeTypeValue });
            if (!feeType) {
                return '';
            }
            if (feeType.value !== 'PCT_METERED_USAGE') {
                prefix += ' / ';
            }
            prefix += feeType.unit;
            return prefix;
        }
        public getPrefixForServiceType(utilityServiceType) {
            let prefix = '';
            const unitLabelByMeasure = this.OptionsService.getUnitLabelByMeasure(utilityServiceType);
            if (unitLabelByMeasure) {
                prefix = ` / ${unitLabelByMeasure.unit}`;
            }
            return prefix;
        }
        /* Setup details API */
        public saveTenantBillingSetupDetails(setup: BillingSetup) {
            return this.DataStore.update(setup)
                .then((updatedSetup: BillingSetup) => {
                    this.Messages.success('Billing setup updated');
                    return updatedSetup;
                })
                .catch((error) => {
                    this.Messages.error('Error updating tenant service');
                    return null;
                });
        }
        public createTenantFee(service: TenantService) {
            return this.DataStore.create(this.RestangularV3.all('tenant-services'), service)
                .then((newService: TenantService) => {
                    this.Messages.success('New tenant service created');
                    return newService;
                })
                .catch((error) => {
                    this.Messages.error('Error creating tenant service');
                    return null;
                });
        }
        public updateTenantFee(service: TenantService) {
            const serviceCopy = this.RestangularV3.copy(service);
            return this.DataStore.update(serviceCopy)
                .then((updatedService: TenantService) => {
                    this.Messages.success('Tenant service updated');
                    return updatedService;
                })
                .catch((error) => {
                    this.Messages.error('Error updating tenant service');
                    return null;
                });
        }
        public deleteTenantFee(service: TenantService) {
            return this.DataStore.delete(service)
                .then(() => {
                    this.Messages.success('Tenant service deleted');
                })
                .catch((error) => {
                    this.Messages.error('Error deleting tenant service');
                });
        }
        public createReminder(reminder: Reminder) {
            return this.DataStore.create(this.RestangularV3.all('reminders'), reminder, { buildingId: this.$stateParams.buildingId })
                .then((newReminder: Reminder) => {
                    this.Messages.success('New reminder created');
                    return newReminder;
                })
                .catch((error) => {
                    this.Messages.error('Error creating reminder');
                    return null;
                });
        }
        public updateReminder(reminder: Reminder) {
            const reminderCopy = this.RestangularV3.copy(reminder);
            return this.DataStore.update(reminderCopy)
                .then((updatedReminder: Reminder) => {
                    this.Messages.success('Reminder updated');
                    return updatedReminder;
                })
                .catch((error) => {
                    this.Messages.error('Error updating reminder');
                    return null;
                });
        }
        public deleteReminder(reminder: Reminder) {
            return this.DataStore.delete(reminder)
                .then(() => {
                    this.Messages.success('Tenant service deleted');
                })
                .catch((error) => {
                    this.Messages.error('Error deleting tenant service');
                });
        }
        public createTenantGroup(tenantGroup) {
            return this.DataStore.create(this.RestangularV3.all('tenant-groups'), tenantGroup, { buildingId: this.$stateParams.buildingId })
                .then((response) => {
                    this.Messages.success('New tenant group created');
                    return response;
                })
                .catch((error) => {
                    this.Messages.error('Error creating tenant group');
                    return null;
                });
        }
        public updateTenantGroup(tenantGroup) {
            const tenantGroupCopy = this.RestangularV3.copy(tenantGroup);
            return this.DataStore.update(tenantGroupCopy)
                .then((response) => {
                    this.Messages.success('Tenant group updated');
                    return response;
                })
                .catch((error) => {
                    this.Messages.error('Error updating tenant group');
                    return null;
                });
        }
        public deleteTenantGroup(tenantGroup) {
            return this.DataStore.delete(tenantGroup)
                .then(() => {
                    this.Messages.success('Tenant group deleted');
                })
                .catch((error) => {
                    this.Messages.error('Error deleting tenant group');
                });
        }
    }
    angular.module('tenantBilling').service('TenantBillingSetupService', TenantBillingSetupService);
}
