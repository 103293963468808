namespace aq.utilityBudgets {
    export class AddUtilityService extends aq.common.Controllers.ModalCtrl {
        private utilityService: aq.utilityBudgets.UtilityServiceCreate;
        private measureTypes: aq.utilityBudgets.MeasureType[];

        /* @ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private RestangularV3: restangular.IService,
            private Errors,
            private Messages,
            private utilityAccount: UtilityAccount,
            private utilityServices: UtilityService[],
            private UtilityServiceHelper: UtilityServiceHelper,
            private buildingId
        ) {
            super({}, $mdDialog);
            this.utilityService = <aq.utilityBudgets.UtilityServiceCreate>{
                utilityAccount: this.utilityAccount.id,
                billType: BillType.UTILITY
            };
            const existingServices: Array<string> = this.utilityServices.map((value) => value.type);
            this.measureTypes = this.UtilityServiceHelper.getMeasureTypes()
                .filter((measureType) => {
                    if (existingServices.indexOf(measureType.value) < 0) {
                        return true;
                    }
                });
        }
        public cancel(data?): void {
            this.$mdDialog.cancel();
        }
        public hide(result?): void {
            this.$mdDialog.hide(this.RestangularV3.copy(result));
        }
        public save() {
            return this.RestangularV3.one('')
                .customPOST(this.utilityService, 'utility-services', {buildingId: this.buildingId})
                .then((result) => {
                    this.Messages.success('Successfully created Utility Service');
                    this.hide(result);
                }, this.Errors.forCRUD('CREATE'));
        }
    }
    angular.module('aq.utilityBudgets').controller('AddUtilityService', AddUtilityService);
}
