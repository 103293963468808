namespace aq.utilityBudgets {

    export enum BudgetCreateWizardMode {
        PLANNING = 'planning',
        MANUAL = 'manual'
    };

    export class BudgetCreateWizardSelectionModalCtrl extends aq.common.Controllers.ModalCtrl {

        /* ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private Messages,
            private mode: BudgetCreateWizardMode,
            private $state: ng.ui.IStateService,
            // the following properties are for backwards compatility (used during manual budget creation wizard)
            private building: aq.common.models.Building,
            private measures: aq.common.models.Measure[],
            private months: any[],
            private years: any[],
            private types: any[]
        ) {
            super({}, $mdDialog);
        }
        public startWizard(mode: BudgetCreateWizardMode) {
            if (mode != BudgetCreateWizardMode.PLANNING && mode != BudgetCreateWizardMode.MANUAL) {
                this.Messages.error('Unexpected error occured while starting wizard, please contant your Administrator');
                this.cancel();
            }
            this.mode = mode;
            if (this.mode == BudgetCreateWizardMode.PLANNING) {
                this.$mdDialog.show({
                    controller: 'BudgetPlanningWizardCtrl as vm',
                    templateUrl: 'app/utilityBudgets/budgets/wizard/budgetPlanningWizard.html',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false,
                    targetEvent: event,
                    locals: {
                        buildingId: this.building.id,
                        accountId: this.building.account
                    },
                    multiple: false
                } as any)
                    .finally(() => {
                        this.$state.reload();
                    });
            } else {
                this.$mdDialog.show({
                    controller: 'TargetNewCtrl as targetNewCtrl',
                    templateUrl: 'app/utilityBudgets/budgets/new/budgetNewModal.html',
                    clickOutsideToClose: true,
                    parent: angular.element(document.body),
                    locals: {
                        target: {
                            building: this.building.id
                        },
                        measures: this.measures,
                        building: this.building,
                        months: this.months,
                        years: this.years,
                        types: this.types
                    },
                    multiple: false
                } as any)
                    .finally(() => {
                        this.$state.reload();
                    });
            }
        }
    }
    angular.module('aq.utilityBudgets').controller('BudgetCreateWizardSelectionModalCtrl', BudgetCreateWizardSelectionModalCtrl);
}
