var aq;
(function (aq) {
    var ui;
    (function (ui) {
        var _ActivityIssueDetail = /** @class */ (function () {
            /* @ngInject */
            function _ActivityIssueDetail(resolver, RestangularV3, activityAlertUtilService) {
                this.resolver = resolver;
                this.RestangularV3 = RestangularV3;
                this.activityAlertUtilService = activityAlertUtilService;
            }
            _ActivityIssueDetail.prototype.$onInit = function () {
                var _this = this;
                this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _a = this;
                                return [4 /*yield*/, this.RestangularV3
                                        .one('issues', this.issue)
                                        .get({ noCache: moment().valueOf() })];
                            case 1:
                                _a.issueDetail = _b.sent();
                                return [2 /*return*/];
                        }
                    });
                }); });
            };
            _ActivityIssueDetail.prototype.getIssueDurationFormatted = function (issueCreatedOn, issueClosedOn) {
                var issueDuration = null;
                if (issueCreatedOn) {
                    var start = moment(issueCreatedOn).toDate();
                    var end = issueClosedOn ? moment(issueClosedOn).toDate() : moment().toDate();
                    issueDuration = this.activityAlertUtilService.getDurationFormatted(start, end);
                }
                return issueDuration;
            };
            _ActivityIssueDetail.prototype.formatAlertDate = function (date) {
                var momentDate = moment(date);
                return momentDate
                    .isBefore(moment().subtract(1, 'day').valueOf())
                    ? momentDate.format('MMM DD YYYY - h:mm a')
                    : momentDate.format('h:mm a');
            };
            return _ActivityIssueDetail;
        }());
        ui.ActivityIssueDetail = {
            controller: _ActivityIssueDetail,
            controllerAs: 'vm',
            templateUrl: 'app/common/components/activities/issueDetail/detail.html',
            bindings: {
                issue: '<'
            }
        };
        angular
            .module('aq.ui')
            .component('activityIssueDetail', ui.ActivityIssueDetail);
    })(ui = aq.ui || (aq.ui = {}));
})(aq || (aq = {}));
