'use strict';

angular
    .module('aq.admin.reports.tenantbillingreport', [])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.admin.reports.tenantbillingreport', {
                url: '/tenantbillingreport',
                templateUrl: 'app/admin/reports/tenantbillingreport/TenantBillingReport.html',
                controller: 'TenantBillingReportCtrl as vm',
                data: {
                    breadcrumb: 'Tenant Billing Report',
                    icon: '.aq-icons-tenant-billing'
                }
            });
    });
