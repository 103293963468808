namespace aq.reports.projectStatus {
    type PSD = aq.models.projects.ProjectStatusDetails;

    interface ProjectsSummaryData {
        current: PSD[],
        trailing: PSD[]
    }

    export class ProjectStatusCtrl extends BaseDocraptorReportCtrl {
        public newProjectsItems: BuildingsProjectsItem[];
        public completedProjectsItems: BuildingsProjectsItem[];
        public inProgressProjectsItems: BuildingsProjectsItem[];
        public groupData: BuildingGroupReportItem[];
        public summaryGroupData: DataSummary;
        public reportDateView: string;
        public reportName: string;
        public currentYear: string;
        public currentMonth: string;
        public currentEndOfMonth: string;
        public pastYear: string;
        public pastMonth: string;
        public pastEndOfMonth: string;
        public currencyUnit;
        public isAnyImpactDate: boolean;
        public accountDate: moment.Moment;
        public newProjectsSummary: aq.reports.projectStatus.ProjectStatusSummary;
        public completedProjectsSummary: aq.reports.projectStatus.ProjectStatusSummary;
        public inProgressProjectsSummary: aq.reports.projectStatus.ProjectStatusSummary;
        /* ngInject */
        constructor(
            private account,
            private buildings: aq.common.models.Building[],
            private startDate,
            private statuses: aq.models.projects.ProjectStatus[],
            private projects: Record<number, BuildingsProjectsItem[]>,
            private newProjectsSummaryData: ProjectsSummaryData,
            private completedProjectsSummaryData: ProjectsSummaryData,
            private inProgressProjectsSummaryData: ProjectsSummaryData,
            private $filter,
            private OptionsService,
            protected $timeout: ng.ITimeoutService
        ) {
            super($timeout);
            const longMonthYearFormat = 'MMMM D, YYYY';
            this.newProjectsSummary = this.getProjectsSummary(this.newProjectsSummaryData);
            this.completedProjectsSummary = this.getProjectsSummary(this.completedProjectsSummaryData);
            this.inProgressProjectsSummary = this.getProjectsSummary(this.inProgressProjectsSummaryData);
            this.currencyUnit = this.OptionsService.currencyUnit();
            const currentStartMoment = moment(this.startDate);
            const pastStartMoment = moment(this.startDate).subtract(1, 'month');
            this.currentYear = currentStartMoment.format('YYYY');
            this.currentMonth = currentStartMoment.format('MMMM');
            this.currentEndOfMonth = currentStartMoment.endOf('month').format(longMonthYearFormat);
            this.pastYear = pastStartMoment.format('YYYY');
            this.pastMonth = pastStartMoment.format('MMMM');
            this.pastEndOfMonth = pastStartMoment.endOf('month').format(longMonthYearFormat);
            const formattedStartDate = currentStartMoment.format(longMonthYearFormat);
            const formattedEndDate = currentStartMoment.endOf('month').format(longMonthYearFormat);
            this.reportName = `Projects updates ${formattedStartDate} through ${formattedEndDate}`;
            this.initReportItems();

            this.notifyDocumentReady();
        }
        public getProjectsSummary(projectsSummaryData: ProjectsSummaryData): aq.reports.projectStatus.ProjectStatusSummary {
            const currentData = projectsSummaryData.current;
            const trailingData = projectsSummaryData.trailing;
            const result: aq.reports.projectStatus.ProjectStatusSummary = {
                current: {
                    count: currentData.length,
                    projectIds: currentData.map((r: PSD) => r.projectId),
                    savings: _.sumBy(currentData, (r: PSD) => r.dollarSavingsMax || 0) || 0,
                    savingsEnergy: _.sumBy(currentData, (r: PSD) => r.energySavingsMax || 0) || 0
                },
                past: {
                    count: trailingData.length,
                    projectIds: trailingData.map((r: PSD) => r.projectId),
                    savings: _.sumBy(trailingData, (r: PSD) => r.dollarSavingsMax || 0) || 0,
                    savingsEnergy: _.sumBy(trailingData, (r: PSD) => r.energySavingsMax || 0) || 0
                }
            };

            result.countIndicator = result.current.count - result.past.count;
            result.countDiff = Math.abs(result.current.count - result.past.count);
            result.countInfo = result.countIndicator == 0
                ? 'no change'
                : `${this.getIndicatorText(result.countIndicator)} ${result.countDiff} projects`;

            result.savingsIndicator = result.current.savings - result.past.savings;
            result.savingsDiff = Math.abs(result.current.savings - result.past.savings);
            const savings = this.$filter('currency')(result.savingsDiff, '$', 0);
            result.savingsInfo = result.savingsIndicator == 0
                ? 'no $ change'
                : `${this.getIndicatorText(result.savingsIndicator)} ${savings}`;

            result.savingsEnergyIndicator = result.current.savingsEnergy - result.past.savingsEnergy;
            result.savingsEnergyDiff = Math.abs(result.current.savingsEnergy - result.past.savingsEnergy);
            const savingsEnergy = this.$filter('aqnumber')(result.savingsEnergyDiff);
            result.savingsEnergyInfo = result.savingsEnergyIndicator == 0
                ? '(no kWh change)'
                : `(${this.getIndicatorText(result.savingsEnergyIndicator)} ${savingsEnergy} kWh)`;

            if (result.savingsIndicator == 0 && result.savingsEnergyIndicator == 0) {
                result.savingsInfo = '';
                result.savingsEnergyInfo = 'no change';
            }

            return result;
        }
        public getIndicatorText(value: number) {
            return `${value > 0 ? 'UP' : 'DOWN'}`;
        }
        public initReportItems() {
            this.newProjectsItems = [];
            this.completedProjectsItems = [];
            this.inProgressProjectsItems = [];

            Object.keys(this.projects).forEach(key => {
                const building = this.buildings.find(b => b.id == key);

                const newBuildingProjects: BuildingsProjectsItem[] = this.projects[key].filter((p: BuildingsProjectsItem) => {
                    return this.newProjectsSummary.current.projectIds.includes(p.projectId);
                });
                this.initBuildingGroupItems(this.newProjectsItems, building.name, newBuildingProjects);

                const completedBuildingProjects = this.projects[key].filter((p: BuildingsProjectsItem) => {
                    return this.completedProjectsSummary.current.projectIds.includes(p.projectId);
                });
                this.initBuildingGroupItems(this.completedProjectsItems, building.name, completedBuildingProjects);

                const inProgressBuildingProjects = this.projects[key].filter((p: BuildingsProjectsItem) => {
                    return this.inProgressProjectsSummary.current.projectIds.includes(p.projectId);
                });
                this.initBuildingGroupItems(this.inProgressProjectsItems, building.name, inProgressBuildingProjects);
            });
        }
        public initBuildingGroupItems(resultProjectItems: BuildingsProjectsItem[], buildingName: string, buildingProjects: BuildingsProjectsItem[]) {
            this.sortProjects(buildingProjects);
            if (buildingProjects.length > 0) {
                const buildingHeaderItem = {
                    name: buildingName,
                    projectId: 'BUILDING',
                    code: 'BUILDING',
                    estimatedSavings: _.sumBy(buildingProjects, bp => bp.estimatedSavings || 0) || 0,
                    estimatedSavingsEnergy: _.sumBy(buildingProjects, bp => bp.estimatedSavingsEnergy || 0) || 0,
                    estimatedCost: _.sumBy(buildingProjects, bp => bp.estimatedCost || 0) || 0,
                } as any;
                resultProjectItems.push(buildingHeaderItem);
                resultProjectItems.push(...buildingProjects);
            }
        }
        public sortProjects(projects: BuildingsProjectsItem[]) {
            const statusOrder = {
                'Declined': 5,
                'Needs Acceptance': 4,
                'Needs Acc.': 4,
                'Backlog': 3,
                'In Progress': 2,
                'Completed': 1
            };
            projects.sort((a, b) => {
                if (a.priorityOrder != b.priorityOrder) {
                    return b.priorityOrder - a.priorityOrder;
                }
                if (a.status != b.status) {
                    return statusOrder[a.status] - statusOrder[b.status];
                }
                return moment(a.dateCreated).valueOf() - moment(b.dateCreated).valueOf();
            });
        }
    }
    angular
        .module('aq.reports')
        .controller('ProjectStatusCtrl', ProjectStatusCtrl);
}
