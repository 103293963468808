// widget for displaying a piechart of data and the values associated with it
namespace aq.reports {

    export class ProgressbarController {
        private percent;
        private circleLength;
        private targetModel;
        private size: number;
        private viewport: string;
        private radius: number;
        private strokeWidth: number;
        private config: any;
        private backgroundBarColor: string;
        private isOver100: boolean;
        $onInit() {
            this.size = 50;
            if (!_.isNil(this.config)) {
                this.size = this.config.size;
            }
            this.viewport = `0 0 ${this.size} ${this.size}`;
            this.radius = this.size / 2.5;
            this.circleLength = Math.PI * (this.radius * 2);
            this.strokeWidth = this.radius / 4;

            if (this.targetModel) {
                let boundedPercentage = this.targetModel.percentage;
                if (!this.targetModel.percentage || this.targetModel.percentage < 0) {
                    boundedPercentage = 0;
                }
                if (this.targetModel.percentage > 100) {
                    boundedPercentage = 100;
                    this.backgroundBarColor = this.targetModel.color;
                    this.isOver100 = true;
                }
                this.percent = ((100 - boundedPercentage) / 100) * this.circleLength;
            }
        }
    }

    angular
        .module('aq.reports')
        .component('progressbar', {
            controller: ProgressbarController,
            controllerAs: 'vm',
            templateUrl: 'app/reports/summary/portfolio/components/progressbar/progressbar.html',
            bindings: {
                targetModel: '<',
                config: '<'
            }
        });
}
