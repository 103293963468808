namespace aq.dashboard.widgets {
    declare var Skycons:any;
    
    angular.module('aq.dashboard.widgets').directive('weatherIcon', () => {
        return {
            restrict: 'E',
            scope: {
                conditions: '=',
                large: '='
            },
            templateUrl: 'app/dashboard/common/widgets/weather/icon.html',
            link: ($scope: any, element: any) => {
                let skycons = new Skycons({ color: '#444444' });

                skycons.add(element.find('canvas')[0], $scope.conditions);

                skycons.play();
            }
        }
    });
}