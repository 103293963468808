angular.module('aq-main').run(function($httpBackend) {
    const regex = {
        baseloadTargets: /^\/api\/v1\/accounts\/.*\/portfolio\/insightTargets\?type=BASELOAD/,
        baseloadValues: /^\/api\/v1\/accounts\/.*\/portfolio\/insightValues\?type=BASELOAD/,
        peakTargets: /^\/api\/v1\/accounts\/.*\/portfolio\/insightTargets\?type=PEAK_DEMAND/,
        peakValues: /^\/api\/v1\/accounts\/.*\/portfolio\/insightValues\?type=PEAK_DEMAND/,
        consumptionTargets: /^\/api\/v1\/accounts\/.*\/portfolio\/insightTargets\?type=CONSUMPTION/,
        consumptionValues: /^\/api\/v1\/accounts\/.*\/portfolio\/insightValues\?type=CONSUMPTION/

    };
    const i = 0;
    const buildingIds = [
        17590307, // 100 10th Avenue
        20930010, // Fremont
        496352, // Concord Plaza
        295404, // Davis Data Center
        17505785, // Market Street
        17506186, // Redwood Office
        17505130 // Victoria Arts Building
    ];
    const baseloadTargets = {};
    const baseloadValues = {};
    const peakTargets = {};
    const peakValues = {};
    const consumptionValues = {};
    const consumptionTargets = {};

    const begin = moment().locale('en').format('YYYY-MM-01 00:00:00');
    const end = moment().locale('en').format('YYYY-MM-') + moment().daysInMonth() + ' 00:00:00';

    for (let i = 0; i < buildingIds.length; i++) {
        baseloadTargets[buildingIds[i]] = {
            startDate: begin,
            endDate: end,
            value: _.random(300000.0, 500000.0)
        };
        baseloadValues[buildingIds[i]] = {
            timestamps: [
                new Date().getTime()
            ],
            values: [
                _.random(100000000, 500000000.0)
            ]
        };
        peakTargets[buildingIds[i]] = {
            startDate: begin,
            endDate: end,
            value: _.random(500, 1000)
        };
        peakValues[buildingIds[i]] = {
            partial: [],
            missing: [],
            timestamps: [],
            timestampsWithValues: [],
            values: [],
            breakdown: null,
            id: 22485218,
            start: '2017-04-01T00:00:00-04:00',
            end: '2017-04-30T23:59:59-04:00',
            name: '100 10th Ave'
        };
        consumptionTargets[buildingIds[i]] = {
            startDate: begin,
            endDate: end,
            value: _.random(1000, 5000.0) // -155409,
        };
        consumptionValues[buildingIds[i]] = {
            partial: [],
            missing: [],
            timestamps: [
                new Date().getTime()
            ],
            timestampsWithValues: [
                new Date().getTime()
            ],
            values: [
                _.random(10, 5000.0)
            ],
            breakdown: null,
            start: '2017-04-01T00:00:00-04:00',
            end: '2017-04-30T23:59:59-04:00'
        };
    }
    MockContext('portfolioReport', () => {
        // Baseload
        $httpBackend.whenGET(regex.baseloadValues).respond((method, url, data) => {
            return [200, baseloadValues, {}];
        });
        $httpBackend.whenGET(regex.baseloadTargets).respond((method, url, data) => {
            return [200, baseloadTargets, {}];
        });

        // Consumption
        $httpBackend.whenGET(regex.consumptionValues).respond((method, url, data) => {
            return [200, consumptionValues, {}];
        });
        $httpBackend.whenGET(regex.consumptionTargets).respond((method, url, data) => {
            return [200, consumptionTargets, {}];
        });
    });
});