'use strict';

angular.module('aq.filereader', [])
.directive('aqFilereader', function($q) {

    return {
        restrict : 'A',
        require : '?ngModel',
        link : function(scope, element, attrs, ngModel: ng.INgModelController) {
            if (!ngModel)
                return;

            ngModel.$render = function() {
                if((element[0].parentNode as any).reset) {
                    (element[0].parentNode as any).reset();
                }
            }

            element.bind('change', function(e) {
                var reader = new FileReader()
                reader.onload = function(e) {
                    scope.$apply(function () {
                        ngModel.$setViewValue((e.target as any).result);
                    });
                }
                reader.onerror = function(e) {
                    console.error(e);
                }
                reader.readAsText((e.target as any).files[0]);
            });
        }
    };
});
