namespace aq.utilityBudgets {

    interface UtilityBillPeriodView {
        id: string;
        billingPeriod: string;
        invoiceDate: string;
        totalCost: number;
        totalConsumption: number;
        blendedRate: number;
        totalCostWarning: boolean;
    }

    export class UtilityAccountListServiceBills {
        public isAqAdmin;
        public budgetAccess: common.models.AppAccessObject;
        public bills: UtilityBillPeriodView[];
        public pagedBills: UtilityBillPeriodView[];
        private page: {
            size: number;
            index: number;
            total: number;
        };

        /* @ngInject */
        constructor(
            private Restangular: restangular.IService,
            private currencyUnitSymbol: string,
            private utilityAccount: UtilityAccount,
            private utilityService: UtilityService,
            private collectors: aq.common.models.Collector[],
            private utilityServiceBillPeriods: UtilityBillPeriod[],
            private accountId: string,
            private utilityCompanies: aq.admin.configuration.utilityCompany.UtilityCompany[],
            private Messages: aq.services.Messages,
            private Errors,
            public $mdDialog: ng.material.IDialogService,
            private $mdMedia: ng.material.IMedia,
            private $state: ng.ui.IStateService,
            private $stateParams: ng.ui.IStateParamsService,
            private building: aq.common.models.Building,
            private authAccess: common.models.AuthAppAccess,
            private $filter: ng.IFilterService,
            private RestangularV3: restangular.IService,
            private UserService,
            private utilityMeters: UtilityMeter[],
            private urjanetMeters: UrjanetMeter[],
            private loading: aq.services.Loading
        ) {
            this.isAqAdmin = this.UserService.currentUser.userType === 'ADMINISTRATOR';
            this.budgetAccess = this.authAccess['Budgets'];
            this.page = {
                size: 12,
                index: 1,
                total: this.utilityServiceBillPeriods.length
            };
            this.initViewBills();
        }

        public onPagingChange(data: { index: number, size: number }) {
            this.page.index = data.index;
            this.page.size = data.size;
            this.setPagingItems();
        }

        public setPagingItems() {
            this.page.total = this.utilityServiceBillPeriods.length;
            const start = (this.page.index - 1) * this.page.size;
            this.pagedBills = _.slice(this.bills, start, start + this.page.size);
        }

        public getPagingBillsInfo() {
            const start = (this.page.index - 1) * this.page.size + 1;
            const end = Math.min(start + 11, this.utilityServiceBillPeriods.length);
            return `${start}-${end}`
        }

        public initViewBills() {
            this.bills = _.chain(this.utilityServiceBillPeriods)
                .sortBy((bill: UtilityBillPeriod) => {
                    return moment(bill.endDate).valueOf() * (-1);
                })
                .map((bill) => this.getUtilityBillPeriodView(bill))
                .value();
            this.setPagingItems();
        }

        public getUtilityBillPeriodView(billPeriod: UtilityBillPeriod): UtilityBillPeriodView {
            const billPeriodView: UtilityBillPeriodView = {
                id: billPeriod.id,
                billingPeriod: this.getIntervalDisplay(billPeriod),
                invoiceDate: billPeriod.invoiceDate ? moment(billPeriod.invoiceDate).format('MMM DD, YYYY') : '-',
                totalCost: billPeriod.charge || billPeriod.cost,
                totalConsumption: billPeriod.usage,
                blendedRate: this.getBlendedRate(billPeriod),
                totalCostWarning: billPeriod.charge != billPeriod.cost
            };
            return billPeriodView;
        }

        public getIntervalDisplay(period) {
            return `${moment(period.startDate).format('MMM DD, YYYY')} - ${moment(period.endDate).format('MMM DD, YYYY')}`;
        }

        public getBlendedRate(period) {
            if (!period) {
                return null;
            }
            return period.usage > 0 ? (period.charge || period.cost) / period.usage : null;
        }

        public bulkUploadBills() {
            this.$mdDialog.show({
                controller: 'MassBillUpload as vm',
                templateUrl: 'app/utilityBudgets/utilityAccounts/actions/massBillUpload/massBillUpload.html',
                clickOutsideToClose: false,
                multiple: true,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    utilityAccountId: this.utilityAccount.id
                }
            } as any)
                .finally(() => {
                    this.$state.reload();
                });
        }

        public createUtilityBillPeriod() {
            const utilityBillingPeriod = this.Restangular.restangularizeElement(this.building, {}, 'utilityBillPeriods') as any;
            utilityBillingPeriod.service = this.utilityService;
            this.$mdDialog.show({
                controller: 'AddEditSingleBill as vm',
                templateUrl: 'app/utilityBudgets/utilityAccounts/actions/addEditSingleBill/addEditSingleBill.html',
                clickOutsideToClose: false,
                multiple: true,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    utilityServiceModel: this.getUtilityServiceBillViewModel(),
                    accountId: this.accountId,
                    viewStatement: null,
                    buildingId: this.$stateParams.buildingId,
                    readOnly: !this.budgetAccess.EDIT,
                    fromUrjanet: false,
                    isAqAdmin: this.isAqAdmin,
                    collectors: this.collectors,
                    utilityMeters: this.utilityMeters,
                    urjanetMeters: this.urjanetMeters,
                    utilityServiceBillPeriods: _.sortBy(this.utilityServiceBillPeriods, (bill) => moment(bill.endDate).valueOf()),
                    utilityBillingPeriod: utilityBillingPeriod,
                    utilityBillPeriodCharges: []
                }
            } as any).then((newUtilityBillPeriod) => {
                this.utilityServiceBillPeriods.push(newUtilityBillPeriod);
                this.initViewBills();
            });
        }

        public editBillPeriod(item) {
            const utilityBillingPeriod = _.find(this.utilityServiceBillPeriods, (p) => p.id == item.id);
            this.openEditModal(utilityBillingPeriod);
        }

        public openEditModal(utilityBillingPeriod) {
            (this.$mdDialog as any).show({
                controller: 'AddEditSingleBill as vm',
                templateUrl: 'app/utilityBudgets/utilityAccounts/actions/addEditSingleBill/addEditSingleBill.html',
                clickOutsideToClose: false,
                multiple: true,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    utilityServiceModel: this.getUtilityServiceBillViewModel(),
                    accountId: this.accountId,
                    buildingId: this.$stateParams.buildingId,
                    readOnly: !this.budgetAccess.EDIT,
                    isAqAdmin: this.isAqAdmin,
                    collectors: this.collectors,
                    utilityMeters: this.utilityMeters,
                    urjanetMeters: this.urjanetMeters,
                    utilityBillingPeriod: utilityBillingPeriod,
                    utilityServiceBillPeriods: _.sortBy(this.utilityServiceBillPeriods, (bill) => moment(bill.endDate).valueOf())
                }
            }).then((bill) => {
                _.remove(this.utilityServiceBillPeriods, (b) => b.id == bill.id);
                this.utilityServiceBillPeriods.push(bill);
                this.initViewBills();
            });
        }

        public deleteUtilityBillPeriod(utilityBillingPeriod) {
            const confirm = this.$mdDialog
                .confirm()
                .title('Are you sure you want to delete this Utility Bill Period?')
                .ok('Confirm')
                .cancel('Cancel');
            return this.$mdDialog
                .show(confirm)
                .then(() => {
                    this.loading.start(true);
                    this.RestangularV3.restangularizeElement('', utilityBillingPeriod, 'utility-bill-periods');
                    utilityBillingPeriod.remove().then(() => {
                        this.Messages.info('Utility Bill Period deleted');
                        _.remove(this.utilityServiceBillPeriods, (u) => u.id == utilityBillingPeriod.id);
                        this.initViewBills();
                    })
                        .finally(this.loading.stop);
                })
                .catch(() => {
                    this.Errors.forCRUD('DELETE');
                });
        }

        private getUtilityServiceBillViewModel() {
            return <UtilityServiceBillViewModel>{
                id: Number(this.utilityService.id),
                type: this.utilityService.type,
                utilityCompanyName: this.getCompanyName(this.utilityService.utilityCompany),
                utilityCompanyAccount: this.utilityService.account,
                usageRealUnitDisplay: this.utilityService.usageRealUnitDisplay,
                demandRealUnitDisplay: this.utilityService.demandRealUnitDisplay,
                collectors: this.utilityService.collectors,
                includeSewer: this.utilityService.includeSewer
            };
        }

        private getCompanyName(id: number) {
            const item = _.find(this.utilityCompanies, (uc) => uc.id == id);
            return item ? item.name : null;
        }

    }
    angular.module('aq.utilityBudgets').controller('UtilityAccountListServiceBills', UtilityAccountListServiceBills);
}
