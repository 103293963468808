angular.module('aq-main').run(($httpBackend, Restangular: restangular.IService) => {

    const getDeviceById = (deviceId): aq.common.models.Device => {
        return mocks.data.devicemgmt.devices.find(device => device.id == deviceId);
    };

    const getDeviceIndexById = (deviceId): number => {
        return mocks.data.devicemgmt.devices.findIndex(device => device.id == deviceId);
    };

    const removeDeviceById = (deviceId) => {
        const deviceIndex = mocks.data.devicemgmt.devices.findIndex(device => device.id == deviceId);
        mocks.data.devicemgmt.devices.splice(deviceIndex, 1);
    };

    const linkExists = (device: aq.common.models.Device, to: string | number): boolean => {
        return device.links.filter(link => link.device.id == to).length > 0;
    };

    const addDeviceAssociation = (parent, child, protocol: aq.common.models.Protocol, inputId) => {
        const fromIndex = mocks.data.devicemgmt.devices.findIndex(device => device.id == parent);
        const toIndex = mocks.data.devicemgmt.devices.findIndex(device => device.id == child);
        if (!linkExists(mocks.data.devicemgmt.devices[fromIndex], child)) {
            mocks.data.devicemgmt.devices[fromIndex].links.push({
                device: _.omit(mocks.data.devicemgmt.devices[toIndex], 'links'),
                protocol
            });
            mocks.data.devicemgmt.devices[toIndex].links.push({
                device: _.omit(mocks.data.devicemgmt.devices[fromIndex], 'links'),
                protocol
            });
        }
    };

    const removeDeviceAssociation = (fromId, toId) => {
        const fromIndex = getDeviceIndexById(fromId);
        const toIndex = getDeviceIndexById(toId);
        mocks.data.devicemgmt.devices[fromIndex].links = mocks.data.devicemgmt.devices[fromIndex].links.filter((link) => link.device.id != toId);
        mocks.data.devicemgmt.devices[toIndex].links = mocks.data.devicemgmt.devices[toIndex].links.filter((link) => link.device.id != fromId);
    };

    const getAvailableDeviceLinks = (deviceId) => {
        let availableLinks = mocks.data.devicemgmt.devices;
        if (getDeviceById(deviceId).deviceCategory == 'METER') {
            availableLinks = availableLinks.filter((device) => device.deviceCategory == 'NETWORKING');
        }

        const pulseDevice = availableLinks[_.random(0, availableLinks.length - 1)];
        const modbusDevice = availableLinks[_.random(0, availableLinks.length - 1)];
        const meshDevice = availableLinks[_.random(0, availableLinks.length - 1)];
        return {
            PULSE: [
                {
                    deviceId: pulseDevice.id,
                    name: pulseDevice.name,
                    inputOptions: ['PL01', 'PL02']
                }
            ],
            MODBUS: [
                {
                    deviceId: modbusDevice.id,
                    name: modbusDevice.name
                }
            ],
            AQ_MESH: [
                {
                    deviceId: meshDevice.id,
                    name: meshDevice.name
                }
            ]
        };
    };

    const getTopology = (deviceId): aq.common.models.NetworkTopology => {
        const hubTopology: aq.common.models.DeviceTopology = {
            deviceId: mocks.data.devicemgmt.realHub.id,
            deviceName: mocks.data.devicemgmt.realHub.name,
            deviceLocation: mocks.data.devicemgmt.realHub.location,
            deviceCategory: 'NETWORKING',
            deviceClass: {
                make: '',
                series: '',
                model: ''
            },
            protocol: 'GATEWAY',
            deviceFields: {
                deviceIdentifier: '00 13 a2 00 41 4f 8a 8c'
            },
            isQueriedElement: false,
            children: []
        };
        if (deviceId === parseInt(hubTopology.deviceId)) {
            hubTopology.isQueriedElement = true;
        }
        const bridgeTopology: aq.common.models.NetworkTopology = {
            deviceId: mocks.data.devicemgmt.realBridge.id,
            deviceName: mocks.data.devicemgmt.realBridge.name,
            deviceLocation: mocks.data.devicemgmt.realBridge.location,
            deviceCategory: 'NETWORKING',
            deviceClass: {
                make: '',
                series: '',
                model: ''
            },
            parentNetworkConnectionStatus: 'GOOD',
            parentConnectionRssi: -40,
            protocol: 'AQUAMESH',
            deviceFields: {
                deviceIdentifier: '00 11 22 33 44 55 66 77'
            },
            isQueriedElement: false,
            children: []
        };
        if (deviceId === parseInt(bridgeTopology.deviceId)) {
            bridgeTopology.isQueriedElement = true;
        }
        const realMeterConnectedToBridgeTopology: aq.common.models.NetworkTopology = {
            deviceId: mocks.data.devicemgmt.realMeterConnectedToBridge.id,
            deviceName: mocks.data.devicemgmt.realMeterConnectedToBridge.name,
            deviceLocation: mocks.data.devicemgmt.realMeterConnectedToBridge.location,
            deviceCategory: 'METER',
            deviceClass: {
                make: '',
                series: '',
                model: ''
            },
            parentNetworkConnectionStatus: 'GOOD',
            parentConnectionRssi: -40,
            protocol: 'MODBUS',
            deviceFields: {
                deviceIdentifier: '55'
            },
            isQueriedElement: false,
            children: []
        };
        if (deviceId === parseInt(realMeterConnectedToBridgeTopology.deviceId)) {
            realMeterConnectedToBridgeTopology.isQueriedElement = true;
        }

        const realMeterConnectedToHubTopology: aq.common.models.NetworkTopology = {
            deviceId: mocks.data.devicemgmt.realMeterConnectedToHub1.id,
            deviceName: mocks.data.devicemgmt.realMeterConnectedToHub1.name,
            deviceLocation: mocks.data.devicemgmt.realMeterConnectedToHub1.location,
            deviceCategory: 'METER',
            deviceClass: {
                make: '',
                series: '',
                model: ''
            },
            protocol: 'PULSE',
            deviceFields: {

            },
            parentNetworkConnectionStatus: 'GOOD',
            parentConnectionRssi: -40,
            isQueriedElement: false,
            children: []
        };
        if (deviceId === parseInt(realMeterConnectedToHubTopology.deviceId)) {
            realMeterConnectedToBridgeTopology.isQueriedElement = true;
        }
        const realObviusPulseMeterTopology: aq.common.models.NetworkTopology = {
            deviceId: mocks.data.devicemgmt.realObviusPulseMeter.id,
            deviceName: mocks.data.devicemgmt.realObviusPulseMeter.name,
            deviceLocation: mocks.data.devicemgmt.realObviusPulseMeter.location,
            deviceCategory: 'NETWORKING',
            deviceClass: {
                make: '',
                series: '',
                model: ''
            },
            parentNetworkConnectionStatus: 'POOR',
            parentConnectionRssi: -80,
            protocol: 'MODBUS',
            deviceFields: {
                deviceIdentifier: '50'
            },
            isQueriedElement: false,
            children: []
        };
        if (deviceId === parseInt(realObviusPulseMeterTopology.deviceId)) {
            realObviusPulseMeterTopology.isQueriedElement = true;
        }
        const realMeterConnectedToObviusTopology: aq.common.models.NetworkTopology = {
            deviceId: mocks.data.devicemgmt.realMeterConnectedToObvius.id,
            deviceName: mocks.data.devicemgmt.realMeterConnectedToObvius.name,
            deviceLocation: mocks.data.devicemgmt.realMeterConnectedToObvius.location,
            deviceCategory: 'METER',
            deviceClass: {
                make: '',
                series: '',
                model: ''
            },
            protocol: 'PULSE',
            deviceFields: {

            },
            isQueriedElement: false,
            children: []
        };
        if (deviceId === parseInt(realMeterConnectedToObviusTopology.deviceId)) {
            realMeterConnectedToObviusTopology.isQueriedElement = true;
        }
        bridgeTopology.children = bridgeTopology.children.concat([realMeterConnectedToBridgeTopology]);
        realObviusPulseMeterTopology.children = realObviusPulseMeterTopology.children.concat([realMeterConnectedToObviusTopology]);
        hubTopology.children = hubTopology.children.concat([bridgeTopology, realMeterConnectedToHubTopology, realObviusPulseMeterTopology]);
        return hubTopology;
    };

    const regex = {
        devices: /^\/api\/v3\/devices\?buildingId=\d*$/,
        device: /^\/api\/v3\/devices\/\d*$/,
        floors: /^\/api\/v3\/floors\?buildingId=\d*$/,
        spaces: /^\/api\/v3\/spaces\?buildingId=\d*$/,
        links: /^\/api\/v3\/devices\/\d+\/available-links\/?.*?$/,
        link: /^\/api\/v3\/devices\/links\/?$/,
        availablePulsePoints: /^\/api\/v3\/devices\/\d+\/unassigned-pulse-points\/?.*?$/,
        linkDelete: /^\/api\/v3\/devices\/\d+\/links\/\d+$/,
        topology: /^\/api\/v3\/devices\/\d+\/network-topology/,
        commissioningStatus: /^\/api\/v3\/devices\/\d+\/commissioning\/status$/,
        commissioningComplete: /^\/api\/v3\/devices\/\d+\/commissioning\/complete$/
    };

    MockContext('devicemgmt', () => {
        // Devices
    $httpBackend
        .whenGET(regex.devices)
        .respond((method, url, data) => {
            return [200, mocks.data.devicemgmt.devices, {}];
        });
    $httpBackend
        .whenPUT(regex.device)
        .respond((method, url, data) => {
            const ids = MockHelpers.getIds(url);
            const index = getDeviceIndexById(ids[0]);
            mocks.data.devicemgmt.devices[index] = MockHelpers.parseJSON(data);
            return [200, getDeviceById(ids[0]), {}];
        });
    $httpBackend
        .whenGET(regex.device)
        .respond((method, url, data) => {
            const ids = MockHelpers.getIds(url);
            return [200, getDeviceById(ids[0]), {}];
        });
    $httpBackend
        .whenDELETE(regex.device)
        .respond((method, url, data) => {
            const params = MockHelpers.getIds(url);
            console.log(params);
            removeDeviceById(params[0]);
            return [200, mocks.data.devicemgmt.devices, {}];
        });
        // TODO: clean this up and return a 'real' device
        // $httpBackend.whenPOST(regex.devices).respond((method, url, data: string): aq.common.models.Device => {
        //     const newDevice = new MockDevice();
        //     devices.push(newDevice);
        //     return newDevice;
        // });

    // Floors
    $httpBackend
        .whenGET(regex.floors)
        .respond(mocks.data.devicemgmt.floors);

    // Spaces
    $httpBackend
        .whenGET(regex.spaces)
        .respond(mocks.data.devicemgmt.spaces);

    // pulse points on a device
    $httpBackend
        .whenGET(regex.availablePulsePoints)
        .respond((method, url, data) => {
            return [
                200,
                ['PL01', 'PL02', 'PL03'],
                {}
            ];
    });

    // Links
    $httpBackend
        .whenGET(regex.links)
        .respond((method, url, data) => [
            200,
            getAvailableDeviceLinks(MockHelpers.getIds(url)[0]),
            {}
        ]);
    $httpBackend
        .whenPOST(regex.link)
        .respond((method, url, data) => {
            const { parent, child, protocol, inputId } = MockHelpers.parseJSON(data);
            addDeviceAssociation(parent, child, protocol, inputId);
        });
    $httpBackend
        .whenDELETE(regex.linkDelete)
        .respond((method, url, data) => {
            const ids = MockHelpers.getIds(url);
            removeDeviceAssociation(ids[0], ids[1]);
        });
    // Topology
    $httpBackend
        .whenGET(regex.topology)
        .respond((method, url, data) => {
            const ids = MockHelpers.getIds(url);
            return [200, getTopology(parseInt(ids[0])), {}];
        });

          // Commissioning Workflow
    $httpBackend
    .whenGET(regex.commissioningStatus)
    .respond((method, url, data) => {
        const ids = MockHelpers.getIds(url);
        return [200, {
            numStepsCompleted: 1,
            totalSteps: 5,
            steps: [{
                name: 'Online',
                description: 'Meter must be web enabled',
                order: 1,
                status: 'COMPLETE',
                actions: [{
                    type: 'URL',
                    value: '/accounts/22485145/device-management/building/22485218/device/41'
                }]
            }, {
                name: 'Receiving Data',
                description: 'Have we received data yet?',
                order: 2,
                status: 'INCOMPLETE',
                actions: [{
                    type: 'URL',
                    value: '/accounts/22485145/device-management/building/22485218/device/41'
                }]
            }, {
                name: 'First Manual Reading',
                description: 'The first manual reading.',
                order: 3,
                status: 'INCOMPLETE',
                actions: [{
                    type: 'URL',
                    value: '/accounts/22485145/device-management/building/22485218/device/41'
                }]
            }, {
                name: 'Second Manual Reading',
                description: 'The second manual reading.  Taken at least 24 hours after the first.',
                order: 4,
                status: 'INCOMPLETE',
                actions: [{
                    type: 'URL',
                    value: '/accounts/22485145/device-management/building/22485218/device/41'
                }]
            }, {
                name: 'Data Quality',
                description: 'Various data quality checks are successful',
                order: 5,
                status: 'INCOMPLETE',
                actions: [{
                    type: 'URL',
                    value: '/accounts/22485145/device-management/building/22485218/device/41'
                }]
            }]
        }, {}];
        });
    });
});
