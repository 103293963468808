angular.module('aq.accounts.calendars').controller('RuleMainCtrl', function($scope, $state, $stateParams, Restangular, $window, Messages) {

    $window.innerWidth <= 1024 ? $scope.isIPad = true : $scope.isIPad = false;

    $scope.isIPad ? $scope.showForm = false : $scope.showForm = true;

    $scope.showFormLabel = 'Show Form';

    $scope.$watch('workCalendars', function () {
        if (!$scope.workCalendars) return;
        $scope.calendar = _.findById($scope.workCalendars, $stateParams.calendarId);
        $state.get('aq.accounts.calendars.rules').data.breadcrumb = $scope.calendar.name;
        $scope.account
            .one('workCalendars', $scope.calendar.id)
            .getList('rules')
            .then(function(workCalendarRules) {
                $scope.workCalendarRules = workCalendarRules;
            }, function () {
                Messages.error('Error while getting list of events');
            });
    });

    $scope.addRule = function () {
        $state.transitionTo('aq.accounts.calendars.rules.views.form', { accountId: $scope.account.id , calendarId: $scope.calendar.id, ruleId: null});
    };

    $scope.showFormToogle = function() {
        $scope.showForm = ! $scope.showForm;
        $scope.showForm ? $scope.showFormLabel = 'Show Calendar' : $scope.showFormLabel = 'Show Form';
    };

});
