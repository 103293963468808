var aq;
(function (aq) {
    var deviceManagement;
    (function (deviceManagement) {
        var AddClassification = /** @class */ (function (_super) {
            __extends(AddClassification, _super);
            /* @ngInject */
            function AddClassification($mdDialog, category, make, series, model, measures) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                // remove measures that shouldn't be used for device classes
                _this.measures = _.remove(measures, function (measure) {
                    return _.indexOf(['weather', 'network_connection', 'expected_energy'], measure.name) === -1;
                });
                _this.newClassification = {
                    category: category,
                    make: make,
                    series: series,
                    model: model
                };
                return _this;
            }
            return AddClassification;
        }(aq.common.Controllers.ModalCtrl));
        deviceManagement.AddClassification = AddClassification;
        angular
            .module('deviceManagement')
            .controller('AddClassification', AddClassification);
    })(deviceManagement = aq.deviceManagement || (aq.deviceManagement = {}));
})(aq || (aq = {}));
