angular.module('charting').factory('Baselines', ['BaseService', function (BaseService) {

    var service: any = {};

    // TODO clean up, only the first baseline is used
    service.all = function () {
        return [{
            label: 'Last period',
            absolute: true,
            startDateFrom: function (startDate, period) {
                return startDate.subtract(period);
            }
        }, {
            label: 'Last year',
            absolute: true,
            startDateFrom: function (startDate) {
                return startDate.subtract(1, 'years');
            }
        }]
    }

    service.getBaselineParamsSetter = function (time, baseline) {
        return {
            forData: function (params) {
                var startDate = baseline.startDateFrom(moment(time.start()), time.period());
                params.start = startDate.format();
                params.end = startDate.add(time.period()).format();
            }
        }
    }


    return service;
}]);
