'use strict';

angular
    .module('aq.admin.meters.updatemultiplier', [])
    .config((RestangularProvider, $stateProvider) => {
        $stateProvider
            .state('aq.admin.meters.updatemultiplier', {
                url: '/updatemultiplier',
                templateUrl: 'app/admin/meters/updateMultiplier/main.html',
                controller: 'AdminMeterUpdateMultiplierCtrl',
                data: {
                    breadcrumb: 'Update Multiplier',
                    icon: '.aq-icons-copy-data'
                },
            });
    });
