var aq;
(function (aq) {
    var home;
    (function (home) {
        var ProjectLeadCtrl = /** @class */ (function () {
            /* @ngInject */
            function ProjectLeadCtrl($mdStepper, $scope, accountInfoObject, enrollmentId, loading, $q, Messages, Errors, $state, RestangularV3, $translate, $mdMedia, step) {
                var _this = this;
                this.$mdStepper = $mdStepper;
                this.$scope = $scope;
                this.accountInfoObject = accountInfoObject;
                this.enrollmentId = enrollmentId;
                this.loading = loading;
                this.$q = $q;
                this.Messages = Messages;
                this.Errors = Errors;
                this.$state = $state;
                this.RestangularV3 = RestangularV3;
                this.$translate = $translate;
                this.$mdMedia = $mdMedia;
                this.step = step;
                this.subject = 'Invitation to join Aquicore';
                this.body = '';
                this.isLoading = false;
                this.isDesktop = this.$mdMedia('(min-width: 1024px)');
                this.isScreenLargeEnough = screen && screen.width >= 1024;
                this.$scope.$watch(function () { return _this.$mdMedia('(min-width: 1024px)'); }, function (value) {
                    _this.isDesktop = value;
                });
                var verifyBuildingsHeaderKey = 'building_user_enrollment.project_lead_wizard.PL Verify Buildings Instructions';
                this.verifyBuildingsHeader = this.$translate.instant(verifyBuildingsHeaderKey);
                var verifyUsersHeaderKey = 'building_user_enrollment.project_lead_wizard.PL Verify Users Instructions';
                this.verifyUsersHeader = this.$translate.instant(verifyUsersHeaderKey);
                var verifyBuildingPersonnelHeaderKey = 'building_user_enrollment.project_lead_wizard.PL Verify Building Personnel Instructions';
                this.verifyBuildingPersonnelHeader = this.$translate.instant(verifyBuildingPersonnelHeaderKey);
                this.accountInfoObject.users = this.filterProjectLead(this.accountInfoObject.users, this.accountInfoObject.projectLead);
                this.indexUsers();
                this.indexBuildings();
                this.setRoleOnUsers();
                this.setUsersOnEmployees();
                this.initialBuildings = angular.copy(this.accountInfoObject.buildings);
                this.initialUsers = angular.copy(this.accountInfoObject.users);
                this.isValidBuildingList = true;
                this.isValidUserList = true;
                this.isBuildingPersonnelValid = true;
                this.$scope.$on('isValidBuildingListUpdated', function ($event, updatedIsValidBuildingList) {
                    _this.isValidBuildingList = updatedIsValidBuildingList;
                });
                this.$scope.$on('isValidUserListUpdated', function ($event, updatedIsValidUserList) {
                    _this.isValidUserList = updatedIsValidUserList;
                });
                this.$scope.$on('buildingPersonnelValidation', function ($event, data) {
                    _this.isBuildingPersonnelValid = data.isValid;
                });
                this.reloadAQBody();
                this.loading.stop();
                this.$scope.$watch(function () { return _this.$mdStepper('project-lead-stepper'); }, function (value) {
                    while (_this.$mdStepper('project-lead-stepper').currentStep < _this.step) {
                        _this.$mdStepper('project-lead-stepper').next();
                    }
                });
            }
            ProjectLeadCtrl.prototype.next = function (type) {
                var _this = this;
                this.saveState(type).then(function () {
                    _this.reloadAQBody();
                    _this.$mdStepper('project-lead-stepper').next();
                    _this.updateStep();
                });
            };
            ProjectLeadCtrl.prototype.previous = function () {
                this.$mdStepper('project-lead-stepper').back();
                this.updateStep();
            };
            ProjectLeadCtrl.prototype.updatePersonnel = function (person, index) {
                _.each(this.accountInfoObject.buildings, function (building) {
                    var personIndex = _.findIndex(building.personnel, function (p) { return p.index == index; });
                    if (personIndex >= 0) {
                        building.personnel[personIndex].user = person.id;
                    }
                });
            };
            ProjectLeadCtrl.prototype.submit = function () {
                var _this = this;
                this.isLoading = true;
                this.loading.start();
                this.Messages.info('Please wait while the information is being submitted...');
                var customEmail = {
                    subject: this.subject,
                    body: this.body
                };
                this.RestangularV3.one('enrollments', this.enrollmentId).one('account').customPOST(customEmail).then(function () {
                    _this.Messages.success('Account information has been submitted successfully!');
                    _this.isLoading = false;
                    _this.loading.stop();
                    _this.Messages.info('Redirecting to dashboard...');
                    _this.$state.go('aq.dashboard.configurable', { accountId: _this.accountInfoObject.account.id });
                });
            };
            ProjectLeadCtrl.prototype.saveState = function (type) {
                switch (type) {
                    case 'buildings':
                        return this.saveBuildings();
                    case 'users':
                        return this.saveUsers();
                    case 'employees':
                        return this.saveEmployees();
                    default:
                        break;
                }
            };
            ProjectLeadCtrl.prototype.saveBuildings = function () {
                var _this = this;
                this.isLoading = true;
                this.loading.start();
                return this.RestangularV3.one('enrollments', this.enrollmentId).all('buildings')
                    .customPOST(this.accountInfoObject.buildings).then(function (res) {
                    var _loop_1 = function (building) {
                        if (building.id === undefined || building.id === null) {
                            building.id = res.filter(function (responseBuilding) {
                                return building.name === responseBuilding.name;
                            }).map(function (matchingResponseBuilding) {
                                return matchingResponseBuilding.id;
                            })[0];
                        }
                    };
                    for (var _i = 0, _a = _this.accountInfoObject.buildings; _i < _a.length; _i++) {
                        var building = _a[_i];
                        _loop_1(building);
                    }
                    _this.isLoading = false;
                    _this.loading.stop();
                    _this.Messages.success('Buildings saved');
                });
            };
            ProjectLeadCtrl.prototype.saveUsers = function () {
                var _this = this;
                this.isLoading = true;
                this.loading.start();
                var transitoryUsers = this.accountInfoObject.users.map(function (user) {
                    return {
                        id: user.id,
                        roles: [user.role.id],
                        user: user
                    };
                });
                var transitoryProjectLead = {
                    id: this.savedProjectLead.id,
                    roles: [this.savedProjectLead.role],
                    user: this.savedProjectLead
                };
                transitoryUsers.push(transitoryProjectLead);
                return this.RestangularV3.one('enrollments', this.enrollmentId).all('profiles').customPOST(transitoryUsers).then(function (res) {
                    var _loop_2 = function (user_1) {
                        if (user_1.id === undefined || user_1.id === null) {
                            var userResponseItem = _.find(res, function (item) { return item.user && item.user.email === user_1.email; });
                            if (userResponseItem) {
                                user_1.id = userResponseItem.user.id;
                            }
                        }
                    };
                    for (var _i = 0, _a = _this.accountInfoObject.users; _i < _a.length; _i++) {
                        var user_1 = _a[_i];
                        _loop_2(user_1);
                    }
                    _this.isLoading = false;
                    _this.loading.stop();
                    _this.Messages.success('Users saved');
                });
            };
            ProjectLeadCtrl.prototype.saveEmployees = function () {
                var _this = this;
                this.isLoading = true;
                this.loading.start();
                var transitoryEmployeesRequests = this.accountInfoObject.buildings.map(function (building) {
                    var employees = building.personnel.map(function (employee) {
                        if (employee.email === _this.savedProjectLead.email) {
                            employee.user = _this.savedProjectLead;
                        }
                        return employee = {
                            id: employee.id,
                            building: building.id,
                            user: employee.user.id
                        };
                    });
                    var lead = employees.filter(function (employee) {
                        return employee.user === _this.savedProjectLead.id;
                    });
                    if (lead.length === 0) {
                        employees.push({
                            id: undefined,
                            building: building.id,
                            user: _this.savedProjectLead.id
                        });
                    }
                    return _this.RestangularV3.one('enrollments', _this.enrollmentId)
                        .one('buildings', building.id).all('personnel').customPOST(employees).then(function (res) {
                        var _loop_3 = function (employee) {
                            if (employee.id === undefined || employee.id === null) {
                                employee.id = res.filter(function (responseEmployee) {
                                    return employee.name === responseEmployee.name;
                                }).map(function (matchingResponseBuilding) {
                                    return matchingResponseBuilding.id;
                                })[0];
                            }
                        };
                        for (var _i = 0, _a = building.personnel; _i < _a.length; _i++) {
                            var employee = _a[_i];
                            _loop_3(employee);
                        }
                    });
                });
                return this.$q.all(transitoryEmployeesRequests).then(function () {
                    _this.isLoading = false;
                    _this.loading.stop();
                    _this.Messages.success('Personnel saved');
                });
            };
            ProjectLeadCtrl.prototype.setRoleOnUsers = function () {
                var _loop_4 = function (user_2) {
                    user_2.role = this_1.accountInfoObject.roles.find(function (role) { return role.id === user_2.role; });
                };
                var this_1 = this;
                for (var _i = 0, _a = this.accountInfoObject.users; _i < _a.length; _i++) {
                    var user_2 = _a[_i];
                    _loop_4(user_2);
                }
            };
            ProjectLeadCtrl.prototype.setUsersOnEmployees = function () {
                for (var _i = 0, _a = this.accountInfoObject.buildings; _i < _a.length; _i++) {
                    var building = _a[_i];
                    var _loop_5 = function (employee) {
                        employee.user = this_2.accountInfoObject.users.find(function (user) { return user.id === employee.user; });
                    };
                    var this_2 = this;
                    for (var _b = 0, _c = building.personnel; _b < _c.length; _b++) {
                        var employee = _c[_b];
                        _loop_5(employee);
                    }
                }
            };
            ProjectLeadCtrl.prototype.indexUsers = function () {
                for (var i = 0; i < this.accountInfoObject.users.length; i++) {
                    this.accountInfoObject.users[i].index = i;
                    this.indexPersonnel(this.accountInfoObject.users[i]);
                }
            };
            ProjectLeadCtrl.prototype.filterProjectLead = function (users, projectLead) {
                this.savedProjectLead = _.filter(users, function (user) { return user.userId == projectLead.id; })[0];
                return _.filter(users, function (user) { return user.userId != projectLead.id; });
            };
            ProjectLeadCtrl.prototype.indexBuildings = function () {
                _.each(this.accountInfoObject.buildings, function (building, index) { return building.index = index; });
            };
            ProjectLeadCtrl.prototype.indexPersonnel = function (user) {
                for (var _i = 0, _a = this.accountInfoObject.buildings; _i < _a.length; _i++) {
                    var building = _a[_i];
                    for (var i = 0; i < building.personnel.length; i++) {
                        if (building.personnel[i].user === parseInt(user.id)) {
                            building.personnel[i].index = user.index;
                        }
                    }
                }
            };
            ProjectLeadCtrl.prototype.reloadAQBody = function () {
                this.aqPreBody = ['<span>Hi Name,</span><br/>',
                    '<span></span><br/>', '<span>Welcome to Aquicore!</span><br/>',
                    '<span></span><br/>', '<span>You are receiving this email because '
                        + this.accountInfoObject.projectLead.firstName + ' '
                        + this.accountInfoObject.projectLead.lastName + ' has added you to Aquicore.</span><br/>'];
                this.aqPostBody = ['<span>To get started, accept your invitation and create your user:</span><br/>',
                    '<span></span><br/>', '<div class="md-button md-raised md-primary" style="cursor: none; width: 130px;">Accept Invite</div>',
                    '<span></span><br/>', '<span>Email Aquicore at support@aquicore.com with any questions.',
                    '<span></span><br/>', '<span>We can’t wait to work with you!</span><br/>',
                    '<span>-The Aquicore Team</span><br/>',
                    '<img src="/img/brand/logo-black.png"><br/>'];
            };
            ProjectLeadCtrl.prototype.updateStep = function () {
                var currentStep = this.$mdStepper('project-lead-stepper').currentStep;
                this.RestangularV3.one('enrollments', this.enrollmentId).one('step', currentStep).customPOST();
            };
            return ProjectLeadCtrl;
        }());
        home.ProjectLeadCtrl = ProjectLeadCtrl;
        var AccountInfoObject = /** @class */ (function () {
            function AccountInfoObject() {
            }
            return AccountInfoObject;
        }());
        home.AccountInfoObject = AccountInfoObject;
        var CustomEmail = /** @class */ (function () {
            function CustomEmail() {
            }
            return CustomEmail;
        }());
        home.CustomEmail = CustomEmail;
        angular
            .module('home')
            .controller('ProjectLeadCtrl', ProjectLeadCtrl);
    })(home = aq.home || (aq.home = {}));
})(aq || (aq = {}));
