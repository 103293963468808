'use strict';

angular
    .module('meterManagement', ['ui.router', 'aq.ui.loading', 'aq.services.data', 'aq.filters.units',
        'aq.filters.formatters', 'aq.ui.periodSelector', 'aq.services.user', 'aq.ui.reports'] )
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.meterManagement', {
                url: '/accounts/:accountId/meter-management',
                templateUrl: 'app/meterManagement/controllers/main/main.html',
                controller: 'MeterManagementCtrl',
                data: {
                    appName: 'Meter Management'
                },
                resolve: {
                    accountAndBuildings(Restangular, $stateParams, waitForAuthToken) {
                        return Restangular
                            .one('accounts', $stateParams.accountId)
                            .get({ inflate: 'buildings', single: true });
                    }
                }
            })
            .state('aq.meterManagement.status', {
                url: '/status',
                templateUrl: 'app/meterManagement/controllers/meterStatus/meterStatus.html',
                controller: 'MeterStatusCtrl as meterStatusCtrl',
                data: {
                    breadcrumb: 'Meter Status',
                    icon: '.aq-icons-meter-management'
                },
                resolve: {
                    accountAndBuildings: (accountAndBuildings) => {
                        return accountAndBuildings;
                    },
                    metersWithStatus: (Restangular, waitForAuthToken) => {
                        return Restangular.all('users').get('queryMeterStatusWithPaging', {
                            page: 1,
                            pageSize: 20,
                            orderBy: 'collector.name',
                            asc: true
                        });
                    },
                    meterSummary: (Restangular, waitForAuthToken) => {
                        return Restangular.all('users').get('queryMeterStatusSummary');
                    }
                },
                reloadOnSearch: false
            })
            .state('aq.meterManagement.data', {
                url: '/data?name&building',
                templateUrl: 'app/meterManagement/controllers/meterData/meterData.html',
                controller: 'MeterDataCtrl',
                data: {
                    breadcrumb: 'Meter Data',
                    icon: '.aq-icons-meter-data'
                },
                resolve: {
                    collectorsDetail: (Restangular, $stateParams, waitForAuthToken) => {
                        return Restangular
                            .one('accounts', $stateParams.accountId)
                            .getList('meterDataCollectors');
                    },
                    allUnits: (Restangular, $stateParams, waitForAuthToken) => {
                        return Restangular
                            .one('accounts', $stateParams.accountId)
                            .customGET('queryRealUnits');
                    },
                    accountAndBuildings: (accountAndBuildings) => {
                        return accountAndBuildings;
                    },
                    recipients: (Restangular, $stateParams, waitForAuthToken) => {
                        return Restangular
                            .one('accounts', $stateParams.accountId)
                            .all('users').getList();
                    }
                },
                reloadOnSearch: false
            });
    });
