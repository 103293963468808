var aq;
(function (aq) {
    var ui;
    (function (ui) {
        var _ActivityEnergyNoteDisplay = /** @class */ (function () {
            /* @ngInject */
            function _ActivityEnergyNoteDisplay(RestangularV3, Restangular, resolver, HighChartService) {
                this.RestangularV3 = RestangularV3;
                this.Restangular = Restangular;
                this.resolver = resolver;
                this.HighChartService = HighChartService;
            }
            _ActivityEnergyNoteDisplay.prototype.$onInit = function () {
                var _this = this;
                this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                    var unit, start, end, queryParams, queryString, contextualData, dataSeries, selectionSeries, series, err_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                unit = this.activity.context.data.unit;
                                start = moment(this.activity.context.data.startDate).toISOString();
                                end = moment(this.activity.context.data.endDate).toISOString();
                                queryParams = {
                                    measure: this.activity.context.data.measure,
                                    unit: unit,
                                    start: start,
                                    end: end,
                                    interval: this.activity.context.data.interval
                                };
                                queryString = 'buildings/' + this.building.id;
                                return [4 /*yield*/, this.RestangularV3.all(queryString).customGET('raw', queryParams)];
                            case 1:
                                contextualData = _a.sent();
                                dataSeries = this.getDataSeries(contextualData[unit.toLowerCase()].data);
                                selectionSeries = this.getSelectionArea(this.activity.context.data.selection);
                                series = [dataSeries];
                                if (selectionSeries)
                                    series.push(selectionSeries);
                                this.chartConfig = this.buildChartConfig(series, unit);
                                this.contextLoadedCallback();
                                return [3 /*break*/, 3];
                            case 2:
                                err_1 = _a.sent();
                                console.log('NO DATA OR ERR', err_1);
                                this.noDataCallback();
                                // TODO Change this to display an error message to users. Currently for debug purposes only
                                throw err_1;
                            case 3: return [2 /*return*/];
                        }
                    });
                }); });
            };
            _ActivityEnergyNoteDisplay.prototype.buildChartConfig = function (series, unit) {
                var _this = this;
                var getContext = function () { return _this; };
                var result = {
                    chart: {
                        plotBorderWidth: 1,
                        type: 'line'
                    },
                    title: { text: null },
                    subtitle: { text: null },
                    legend: { enabled: false },
                    tooltip: {
                        shared: true,
                        // tslint:disable-next-line:object-literal-shorthand
                        formatter: function () {
                            try {
                                var context = getContext();
                                var dateStr = moment(this.x).tz(context.building.timeZoneId).format('lll');
                                var tooltipMarkup_1 = [];
                                tooltipMarkup_1.push("" + dateStr);
                                _.each(this.points, function (p) {
                                    // tslint:disable-next-line:max-line-length
                                    tooltipMarkup_1.push("<span style=\"color:" + p.series.color + "\">\u25CF</span> " + p.series.name + ": <b>" + p.y.toFixed(0) + "</b>" + unit + "</span>");
                                });
                                return tooltipMarkup_1.join('<br/>');
                            }
                            catch (err) {
                                return null;
                            }
                        }
                    },
                    xAxis: {
                        type: 'datetime',
                        startOfWeek: 0,
                        labels: {
                            formatter: function () {
                                var context = getContext();
                                return moment(this.value).tz(context.building.timeZoneId).format('ddd MMM D<br>h:mma');
                            },
                            rotation: -45
                        },
                        tickLength: 0,
                        gridLineWidth: 1,
                        gridLineColor: '#c7c7c7'
                    },
                    yAxis: [
                        {
                            min: 0,
                            title: {
                                text: unit,
                                style: {
                                    fontSize: '14px'
                                }
                            },
                            labels: {
                                style: {
                                    fontSize: '14px'
                                }
                            }
                        }
                    ],
                    series: series,
                    navigation: {
                        buttonOptions: {
                            enabled: false
                        }
                    }
                };
                return result;
            };
            _ActivityEnergyNoteDisplay.prototype.getDataSeries = function (data) {
                var seriesDataArray = [];
                data.forEach(function (point) {
                    var seriesDataPoint = {
                        x: point.timestamp,
                        y: point.datum
                    };
                    seriesDataArray.push(seriesDataPoint);
                });
                var series = {
                    name: this.building.name,
                    data: seriesDataArray,
                    color: '#0091f1'
                };
                return series;
            };
            _ActivityEnergyNoteDisplay.prototype.isNumber = function (n) {
                return typeof n === 'number';
            };
            _ActivityEnergyNoteDisplay.prototype.getSelectionArea = function (data) {
                var series = [];
                if (!data)
                    return null;
                var xFrom = data.xFrom, yFrom = data.yFrom, xTo = data.xTo, yTo = data.yTo;
                if (!(this.isNumber(xFrom)
                    && this.isNumber(yFrom)
                    && this.isNumber(xTo)
                    && this.isNumber(yTo))) {
                    return null;
                }
                var xFromTrunc = Math.trunc(data.xFrom);
                var xToTrunc = Math.trunc(data.xTo);
                series.push({
                    x: xFromTrunc,
                    y: data.yFrom
                });
                series.push({
                    x: xToTrunc,
                    y: data.yFrom
                });
                series.push({
                    x: xToTrunc,
                    y: data.yTo
                });
                series.push({
                    x: xFromTrunc,
                    y: data.yTo
                });
                return {
                    name: 'Issue Detected',
                    type: 'polygon',
                    tooltip: null,
                    data: series,
                    color: 'rgba(255,40,40,.50)',
                    zIndex: 0,
                    showInLegend: false
                };
            };
            return _ActivityEnergyNoteDisplay;
        }());
        ui._ActivityEnergyNoteDisplay = _ActivityEnergyNoteDisplay;
        ui.ActivityEnergyNoteDisplay = {
            controller: _ActivityEnergyNoteDisplay,
            controllerAs: 'vm',
            templateUrl: 'app/common/components/activities/contexts/energyNoteDisplay.html',
            bindings: {
                activity: '<',
                accountId: '<',
                building: '<',
                noDataCallback: '&',
                contextLoadedCallback: '&'
            }
        };
        angular
            .module('aq.ui')
            .component('activityEnergyNoteDisplay', ui.ActivityEnergyNoteDisplay);
    })(ui = aq.ui || (aq.ui = {}));
})(aq || (aq = {}));
