angular.module('aq.services.errors', ['aq.messages']).factory('Errors', function ($q, Messages) {
    var service: any = {};

    service.forPromise = function () {
        return this.errorOrDuplicate('Server problem. Please try again.', 'Duplicate entry');
    };

    service.errorOrDuplicate = function (errorMsg, duplicateMsg) {
        return function (response) {
            var res = response.data;
            if (res.statusCode === 400) {
                if (res.errorType === 'GENERAL_ERROR') {
                    Messages.error(res.errorText);
                } else if (res.errorType === 'FIELDS_ERROR') {
                    var message = _.map(res.errors, 'message').join('<br>');
                    Messages.error(res.errorText + ':<br>' + message);
                }
            } else if (response.status === 403) {
                Messages.error('Access denied');
            } else if (res.statusCode === 500) {
                Messages.error((res.errorType === 'DUPLICATE_KEY') ? duplicateMsg : errorMsg);
            } else if (res.statusCode || response.status) {
                Messages.error('There\'s an error connecting to the server. Please try again later.');
            }
            return $q.reject(response);
        };
    };

    service.forCRUD = function (type, duplicateMsg) {
        return function (response) {
            if (response.data.errorType == 'DUPLICATE_KEY' && duplicateMsg) {
                Messages.error(duplicateMsg);
                return $q.reject(response);
            }
            if (type === 'CREATE') {
                if (response.data.errorType === 'FIELDS_ERROR') {
                    Messages.error(response.data.errorText);
                } else {
                    Messages.error('Unable to perform create action');
                }
            }
            if (type === 'UPDATE') {
                Messages.error(response.data.errorText);
            }
            if (type === 'DELETE') {
                Messages.error('Unable to perform delete action');
            }
            return $q.reject(response);
        };
    };

    service.forForm = function (element) {
        return function (result) {
            if (result.status === 400) {

                element.errors = {};

                var errors = result.data;
                element.errors.any = function () {
                    return errors.errorType === 'GENERAL_ERROR' ? true : errors.errors.length > 0;
                };
                if (errors.errorType === 'GENERAL_ERROR') {
                    element.errors.general = errors.errorText;
                } else {
                    element.errors.fields = errors.errors;
                    element.errors.all = function () {
                        return this.forField();
                    };
                    element.errors.forField = function (fieldName) {
                        var fieldError = _.find(errors.errors, function (fieldError) {
                            return fieldError.field === (fieldName ? 'updated.' + fieldName : 'updated');
                        });

                        if (!fieldError) {
                            return null;
                        } else {
                            return fieldError.message;
                        }
                    };
                }
            } else {
                Messages.error('There\'s an error connecting to the server. Please try again later.');
            }
            return $q.reject(result);
        };
    };

    return service;
});
