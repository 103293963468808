var aq;
(function (aq) {
    var utilityBudgets;
    (function (utilityBudgets) {
        var ManageIntervalMeters = /** @class */ (function (_super) {
            __extends(ManageIntervalMeters, _super);
            /* @ngInject */
            function ManageIntervalMeters($mdDialog, accountId, buildingId, utilityAccount, utilityService, collectors, intervalMeters, intervalMeterSelect, Messages, RestangularV3, $q) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.accountId = accountId;
                _this.buildingId = buildingId;
                _this.utilityAccount = utilityAccount;
                _this.utilityService = utilityService;
                _this.collectors = collectors;
                _this.intervalMeters = intervalMeters;
                _this.intervalMeterSelect = intervalMeterSelect;
                _this.Messages = Messages;
                _this.RestangularV3 = RestangularV3;
                _this.$q = $q;
                _this.intervalMetersToDelete = [];
                _this.intervalMetersView = angular.copy(intervalMeters);
                _this.inProgress = false;
                _this.numberOfNewIntervalMeters = 0;
                return _this;
            }
            ManageIntervalMeters.prototype.save = function (intervalMeters) {
                var _this = this;
                this.inProgress = true;
                this.numberOfNewIntervalMeters = 0;
                var _a = this.getChangedSubscriptions(intervalMeters, this.intervalMeters), subscribed = _a.subscribed, unsubscribed = _a.unsubscribed;
                var intervalMeterBulkRequest = {
                    utilityService: this.utilityService.id,
                    building: this.buildingId,
                    create: this.filterNew(intervalMeters),
                    delete: this.intervalMetersToDelete,
                    subscribed: subscribed,
                    unsubscribed: unsubscribed
                };
                this.doIntervalMeterBulkRequest(intervalMeterBulkRequest)
                    .then(function (result) {
                    _this.intervalMetersView = _this.updateIntervalMeterIds(result, _this.intervalMetersView);
                    _this.Messages.success('Interval meters successfully saved');
                    _this.hide(_this.intervalMetersView);
                    _this.inProgress = false;
                }).catch(function (error) {
                    _this.Messages.error('Failed to save Interval meters');
                    _this.inProgress = false;
                });
            };
            ManageIntervalMeters.prototype.doIntervalMeterBulkRequest = function (requestMap) {
                return this.RestangularV3.one('interval').customPOST(requestMap, 'bulkUpdate');
            };
            ManageIntervalMeters.prototype.deleteIntervalMeterAction = function (intervalMeter, index, intervalMeters, intervalMetersToDelete) {
                intervalMeters.splice(index, 1);
                if (intervalMeter.id) {
                    intervalMetersToDelete.push(intervalMeter);
                }
                if (this.numberOfNewIntervalMeters > 0) {
                    this.numberOfNewIntervalMeters--;
                }
            };
            ManageIntervalMeters.prototype.addUtilityMeterAction = function (intervalMeters) {
                this.numberOfNewIntervalMeters++;
                var intervalMeter = {
                    id: undefined,
                    smartMeterNumber: undefined,
                    aquicoreMeterID: undefined,
                    billMeterNumber: undefined,
                    subscribed: true
                };
                intervalMeters.push(intervalMeter);
            };
            ManageIntervalMeters.prototype.updateIntervalMeterIds = function (newIntervalMeters, intervalMeters) {
                var _loop_1 = function (i) {
                    var newIntervalMeterToSave = newIntervalMeters.find(function (newIntervalMeter) {
                        return intervalMeters[i].smartMeterNumber === newIntervalMeter.smartMeterNumber;
                    });
                    if (newIntervalMeterToSave) {
                        intervalMeters[i] = newIntervalMeterToSave;
                    }
                };
                for (var i = 0; i < intervalMeters.length; i++) {
                    _loop_1(i);
                }
                return intervalMeters;
            };
            ManageIntervalMeters.prototype.filterNew = function (intervalMeters) {
                return _.filter(intervalMeters, function (intervalMeter) { return intervalMeter.id == undefined; });
            };
            ManageIntervalMeters.prototype.getChangedSubscriptions = function (savedIntervalMeters, originalIntervalMeters) {
                var subscribed = [];
                var unsubscribed = [];
                var originalIntervalMetersMap = new Map(originalIntervalMeters.map(function (meter) { return [meter.id, meter]; }));
                savedIntervalMeters.forEach(function (savedIntervalMeter) {
                    var originalIntervalMeter = originalIntervalMetersMap.get(savedIntervalMeter.id);
                    if (originalIntervalMeter && originalIntervalMeter.subscribed !== savedIntervalMeter.subscribed) {
                        if (savedIntervalMeter.subscribed) {
                            subscribed.push(savedIntervalMeter);
                        }
                        else {
                            unsubscribed.push(savedIntervalMeter);
                        }
                    }
                });
                return { subscribed: subscribed, unsubscribed: unsubscribed };
            };
            return ManageIntervalMeters;
        }(aq.common.Controllers.ModalCtrl));
        utilityBudgets.ManageIntervalMeters = ManageIntervalMeters;
        angular.module('aq.utilityBudgets').controller('ManageIntervalMeters', ManageIntervalMeters);
    })(utilityBudgets = aq.utilityBudgets || (aq.utilityBudgets = {}));
})(aq || (aq = {}));
