declare const bootbox: any;
angular.module('aq.ui.confirmation', [])
    .directive('afterConfirmation', function ($parse) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                element.bind("click", function () {
                    bootbox.confirm({
                        closeButton: false,
                        message: attrs.confirmationMessage,
                        buttons: {
                            confirm: {
                                className: "aq-button red"
                            },
                            cancel: {
                                className: "aq-button"
                            }
                        },
                        callback: function (result) {
                            if (result) {
                                scope.$apply(attrs.afterConfirmation);
                            }
                        }
                    })
                });
            }
        };
    });
