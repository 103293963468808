var aq;
(function (aq) {
    var reports;
    (function (reports) {
        var InvoiceDefaultCtrl = /** @class */ (function (_super) {
            __extends(InvoiceDefaultCtrl, _super);
            /* @ngInject */
            function InvoiceDefaultCtrl(invoice, tenant, setup, billing, tenantChargeValues, historicalUsage, SeriesMapping, OptionsService, residents, TenantServiceHelper, Auth, $timeout) {
                var _this = _super.call(this, $timeout) || this;
                _this.invoice = invoice;
                _this.tenant = tenant;
                _this.setup = setup;
                _this.billing = billing;
                _this.tenantChargeValues = tenantChargeValues;
                _this.historicalUsage = historicalUsage;
                _this.SeriesMapping = SeriesMapping;
                _this.OptionsService = OptionsService;
                _this.residents = residents;
                _this.TenantServiceHelper = TenantServiceHelper;
                _this.Auth = Auth;
                _this.$timeout = $timeout;
                _this.residentsNames = 'Unoccupied';
                _this.currencyUnit = OptionsService.currencyUnit();
                _this.setUsageTotals(_this.tenantChargeValues);
                _this.chartConfig = _this.getChartConfig(_this.historicalUsage);
                var chargeValue = _this.tenantChargeValues[0];
                _this.tenantChargeStart = chargeValue && chargeValue.startDate ? chargeValue.startDate.toString() : '';
                _this.tenantChargeEnd = chargeValue && chargeValue.endDate ? chargeValue.endDate.toString() : '';
                _this.residentsNames = _this.formatResidentNames();
                _this.notifyDocumentReady();
                return _this;
            }
            InvoiceDefaultCtrl.prototype.formatResidentNames = function () {
                var _this = this;
                if (!this.invoice.lease) {
                    return 'Unoccupied';
                }
                var names = '';
                this.residents.forEach(function (resident) {
                    if (resident === _this.residents.slice().pop()) {
                        names += resident.name;
                    }
                    else {
                        names += resident.name + ", ";
                    }
                });
                return names;
            };
            InvoiceDefaultCtrl.prototype.setUsageTotals = function (chargeValues) {
                var uniqueUnits = _(chargeValues).map('usageUnit').uniq().size();
                if (uniqueUnits === 1) {
                    this.totalUsage = _(chargeValues).map('appliedTotal').sum();
                    this.totalUsageUnit = chargeValues[0].usageUnit;
                }
            };
            InvoiceDefaultCtrl.prototype.isChargeNil = function (charge) {
                return _.isNil(charge);
            };
            InvoiceDefaultCtrl.prototype.getChartConfig = function (invoices) {
                var monthLabels = this.getMonthLabels(invoices);
                var series = this.getSeries(invoices);
                return {
                    chart: {
                        type: 'column',
                        height: 400,
                        width: 810
                    },
                    title: {
                        text: null
                    },
                    subtitle: {
                        text: null
                    },
                    xAxis: {
                        categories: monthLabels,
                        crosshair: true,
                        useHtml: true,
                        reversed: true
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Cost ($)'
                        }
                    },
                    plotOptions: {
                        column: {
                            stacking: 'normal',
                            pointPadding: -0.2,
                            borderWidth: 0
                        },
                        series: {
                            // turn off animations because of screenshot tool
                            animation: false
                        }
                    },
                    series: series,
                    navigation: {
                        buttonOptions: {
                            enabled: false
                        }
                    }
                };
            };
            InvoiceDefaultCtrl.prototype.getMonthLabels = function (invoices) {
                return _.map(invoices, function (pastInvoice) {
                    var start = moment(pastInvoice.startReadingDate).format('MM/DD/YY');
                    var end = moment(pastInvoice.readingDate).format('MM/DD/YY');
                    return pastInvoice.monthlyBillingName + "<br><small style=\"font-size: 6px\">(" + start + " - " + end + ")</small>";
                });
            };
            InvoiceDefaultCtrl.prototype.getSeries = function (invoices) {
                var chartData = _.map(invoices, 'costPerService');
                return this.SeriesMapping.mapData(chartData);
            };
            InvoiceDefaultCtrl.prototype.getChargeValues = function () {
                return _.sortBy(this.tenantChargeValues, [
                    function (chargeValue) {
                        switch (chargeValue.type.toLowerCase()) {
                            case 'metered':
                                return 1;
                            case 'fixed':
                                return 2;
                            case 'tax':
                                return 3;
                            default:
                                return 4;
                        }
                    },
                    'collector.name',
                    'charge.service.serviceId'
                ]);
            };
            return InvoiceDefaultCtrl;
        }(reports.BaseDocraptorReportCtrl));
        reports.InvoiceDefaultCtrl = InvoiceDefaultCtrl;
        angular
            .module('aq.reports')
            .controller('reportsInvoiceCtrl', InvoiceDefaultCtrl);
    })(reports = aq.reports || (aq.reports = {}));
})(aq || (aq = {}));
