namespace aq.reports {
    export class ExecutiveReportCtrl extends BaseDocraptorReportCtrl {
        private data: any = {};

        /* @ngInject */
        constructor(
            private account: aq.common.models.Account,
            private buildings: aq.common.models.Building[],

            private date: string,
            protected $timeout: ng.ITimeoutService
        ) {
            super($timeout);
            this.date = moment(this.date)
                .tz(account.timeZoneId)
                .format('MMMM, YYYY');
            this.data[22485218] = { // 100 10th ave
                spending: {
                    electricity: 863447,
                    water: 202239.32,
                    gas: 103293.45,
                    steam: 32546,
                    heat: _.random(-1, 0),
                    total: 1201525.77
                },
                budget: {
                    electricity: 789099,
                    water: 233543.54,
                    gas: 123345.43,
                    steam: 31721,
                    heat: _.random(-1, 0),
                    total: 1177708.97
                },
                contact: {
                    pm: {
                        name: 'Logan Soya',
                        email: 'logan@aquicore.com'
                    },
                    engineer: {
                        name: 'Shane Vincent',
                        email: 'shane@aquicore.com'
                    },
                    operations: {
                        name: 'Kristina Niedermayer',
                        email: 'kristina@aquicore.com'
                    }
                }
            };
            this.data[22984268] = { // 1200 McLean Blvd
                spending: {
                    electricity: 301563.84,
                    water: 102435.45,
                    gas: 123321.34,
                    steam: 22454.54,
                    heat: 26789,
                    total: 576564.17
                },
                budget: {
                    electricity: 648000,
                    water: 110345,
                    gas: 180098.43,
                    steam: 23452.68,
                    heat: 23563.43,
                    total: 985459.54
                },
                contact: {
                    pm: {
                        name: 'Logan Soya',
                        email: 'logan@aquicore.com'
                    },
                    engineer: {
                        name: 'Shane Vincent',
                        email: 'shane@aquicore.com'
                    },
                    operations: {
                        name: 'Kristina Niedermayer',
                        email: 'kristina@aquicore.com'
                    }
                }
            };

            this.data[22489632] = { // 50 fremont
                spending: {
                    electricity: 1322241.75,
                    water: 98770.54,
                    gas: 54678.56,
                    steam: 236699.43,
                    heat: _.random(-1, 0),
                    total: 1712390.28
                },
                budget: {
                    electricity: 1501000,
                    water: 99568.65,
                    gas: 56787.68,
                    steam: 278930.5,
                    heat: _.random(-1, 0),
                    total: 1936286.83
                },
                contact: {
                    pm: {
                        name: 'Logan Soya',
                        email: 'logan@aquicore.com'
                    },
                    engineer: {
                        name: 'Shane Vincent',
                        email: 'shane@aquicore.com'
                    },
                    operations: {
                        name: 'Kristina Niedermayer',
                        email: 'kristina@aquicore.com'
                    }
                }
            };
            this.data[22489976] = { // concord plaza
                spending: {
                    electricity: 1090606.30,
                    water: 3455003,
                    gas: 57898.789,
                    steam: 78904.54,
                    heat: 45638.54,
                    total: 4728051.169
                },
                budget: {
                    electricity: 320000,
                    water: 6354885,
                    gas: 54506,
                    steam: 81002,
                    heat: 47898,
                    total: 8085876
                },
                contact: {
                    pm: {
                        name: 'Logan Soya',
                        email: 'logan@aquicore.com'
                    },
                    engineer: {
                        name: 'Shane Vincent',
                        email: 'shane@aquicore.com'
                    },
                    operations: {
                        name: 'Kristina Niedermayer',
                        email: 'kristina@aquicore.com'
                    }
                }
            };

            this.data[22490253] = { // redwood office
                spending: {
                    electricity: 187971.30,
                    water: 68978.678,
                    gas: _.random(-1, 0),
                    steam: _.random(-1, 0),
                    heat: 46789.345,
                    total: 303739.323
                },
                budget: {
                    electricity: 192568,
                    water: 65234,
                    gas: _.random(-1, 0),
                    steam: _.random(-1, 0),
                    heat: 48979,
                    total: 306781
                },
                contact: {
                    pm: {
                        name: 'Logan Soya',
                        email: 'logan@aquicore.com'
                    },
                    engineer: {
                        name: 'Shane Vincent',
                        email: 'shane@aquicore.com'
                    },
                    operations: {
                        name: 'Kristina Niedermayer',
                        email: 'kristina@aquicore.com'
                    }
                }
            };
            this.data[26610647] = { // john hancock tower
                spending: {
                    electricity: 123456.56,
                    water: _.random(-1, 0),
                    gas: 45678.68,
                    steam: 54678.68,
                    heat: _.random(-1, 0),
                    total: 223813.92
                },
                budget: {
                    electricity: 150023,
                    water: _.random(-1, 0),
                    gas: 48900,
                    steam: 58789,
                    heat: _.random(-1, 0),
                    total: 257712
                },
                contact: {
                    pm: {
                        name: 'Logan Soya',
                        email: 'logan@aquicore.com'
                    },
                    engineer: {
                        name: 'Shane Vincent',
                        email: 'shane@aquicore.com'
                    },
                    operations: {
                        name: 'Kristina Niedermayer',
                        email: 'kristina@aquicore.com'
                    }
                }
            };
            this.data[22485622] = { // ups distribution center
                spending: {
                    electricity: 96785.67,
                    water: 34678.998,
                    gas: 87456.567,
                    steam: 23578.54,
                    heat: 32456.32,
                    total: 274956.095
                },
                budget: {
                    electricity: 95648,
                    water: 68769,
                    gas: 103454,
                    steam: 47345,
                    heat: 45678,
                    total: 360894
                },
                contact: {
                    pm: {
                        name: 'Logan Soya',
                        email: 'logan@aquicore.com'
                    },
                    engineer: {
                        name: 'Shane Vincent',
                        email: 'shane@aquicore.com'
                    },
                    operations: {
                        name: 'Kristina Niedermayer',
                        email: 'kristina@aquicore.com'
                    }
                }
            };

            this.notifyDocumentReady();
        }

        // TODO: when this report is made real, move these methods to a service
        @Memoize()
        public getAccountTotals(buildings: aq.common.models.Building[]) {
            return this.sumTotals(buildings);
        }

        @Memoize()
        public getAccountRollupPercent(buildings: aq.common.models.Building[]) {
            const { spending, budget } = this.getAccountTotals(buildings);
            return _.percent(spending, budget);
        }

        @Memoize()
        public getGroupTotals(group: any, buildings: aq.common.models.Building[]) {
            const buildingsInGroup = buildings.filter((building) => (building.buildingGroup as any).id === group.id);
            return this.sumTotals(buildingsInGroup);
        }

        @Memoize()
        public getGroupRollupPercent(group, buildings: aq.common.models.Building[]): number {
            const { spending, budget } = this.getGroupTotals(group, buildings);
            return _.percent(spending, budget);
        }

        @Memoize()
        public getBuildingRollupPercent(building: aq.common.models.Building) {
            const { spending, budget } = this.sumTotals([building]);
            return _.percent(spending, budget);
        }

        @Memoize()
        public getTargetModel(percentage: number) {
            return { percentage, color: this.getColorFromPercent(percentage) };
        }

        @Memoize()
        public getBestBuildings(buildings: aq.common.models.Building[]) {
            const sorted = this.getSortedBuildingPerformance(buildings).slice(0, 3);
            return sorted
                .map((id) => buildings.filter((building) => building.id == id)[0])
                .sort((b1, b2) => (sorted.indexOf(b1.id) > sorted.indexOf(b2.id)) ? -1 : 1);
        }

        @Memoize()
        public getWorstBuildings(buildings: aq.common.models.Building[]) {
            const sorted = this.getSortedBuildingPerformance(buildings).slice(-3);
            return sorted
                .map((id) => buildings.filter((building) => building.id == id)[0])
                .sort((b1, b2) => (sorted.indexOf(b1.id) > sorted.indexOf(b2.id)) ? -1 : 1);
        }

        @Memoize()
        private sumTotals(buildings: aq.common.models.Building[]) {
            return {
                spending: _.sum(buildings.map((building) => this.data[building.id].spending.total)),
                budget: _.sum(buildings.map((building) => this.data[building.id].budget.total))
            };
        }

        @Memoize()
        private getSortedBuildingPerformance(buildings: aq.common.models.Building[]): any[] {
            const map = {};
            buildings.map((building) => {
                map[building.id] = {
                    percent: this.getBuildingRollupPercent(building)
                };
            });

            return Object.keys(map).sort((a, b) => map[a].percent - map[b].percent);
        }

        @Memoize()
        private getColorFromPercent(percentage: number): string {
            return percentage >= 100 ? '#E70F28' : '#7ACD46';
        }
    }

    angular
        .module('aq.reports')
        .controller('executiveReportCtrl', ExecutiveReportCtrl);
}
