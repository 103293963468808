namespace aq.utilityBudgets {

    export class UtilityServiceDetailsBills {
        public currentNavItem: string;
        public isAqAdmin;
        public budgetAccess: common.models.AppAccessObject;

        /* @ngInject */
        constructor(
            private Restangular: restangular.IService,
            private currencyUnitSymbol: string,
            private utilityAccount: UtilityAccount,
            private utilityService: UtilityService,
            private collectors: aq.common.models.Collector[],
            public utilityServiceBillPeriods: UtilityBillPeriod[],
            private accountId: string,
            private utilityCompanies: aq.admin.configuration.utilityCompany.UtilityCompany[],
            private Messages: aq.services.Messages,
            private Errors,
            public $mdDialog: ng.material.IDialogService,
            private $mdMedia: ng.material.IMedia,
            private $state: ng.ui.IStateService,
            private $stateParams: ng.ui.IStateParamsService,
            private CurrentBillPeriod: CurrentBillPeriod,
            private building: aq.common.models.Building,
            private authAccess: common.models.AuthAppAccess,
            private $filter: ng.IFilterService,
            private RestangularV3: restangular.IService,
            private UserService,
            private utilityMeters: UtilityMeter[],
            private urjanetMeters: UrjanetMeter[],
            private loading: aq.services.Loading
        ) {
            this.currentNavItem = 'bills';
            this.isAqAdmin = this.UserService.currentUser.userType === 'ADMINISTRATOR';
            this.budgetAccess = this.authAccess['Budgets'];
            this.utilityServiceBillPeriods.forEach((bill) => {
                if (bill.startDate) {
                    bill.startDateDisplay = this.transformDateForView(bill.startDate);
                }
            });
        }

        public isSelectedTab(name) {
            return this.currentNavItem == name;
        }

        public bulkUploadBills() {
            this.$mdDialog.show({
                controller: 'MassBillUpload as vm',
                templateUrl: 'app/utilityBudgets/utilityAccounts/actions/massBillUpload/massBillUpload.html',
                clickOutsideToClose: false,
                multiple: true,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    utilityAccountId: this.utilityAccount.id
                }
            } as any)
                .finally(() => {
                    this.$state.reload();
                });
        }

        public createUtilityBillPeriod() {
            const utilityBillingPeriod = this.Restangular.restangularizeElement(this.building, {}, 'utilityBillPeriods') as any;
            utilityBillingPeriod.service = this.utilityService;
            this.$mdDialog.show({
                controller: 'AddEditSingleBill as vm',
                templateUrl: 'app/utilityBudgets/utilityAccounts/actions/addEditSingleBill/addEditSingleBill.html',
                clickOutsideToClose: false,
                multiple: true,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    utilityServiceModel: this.getUtilityServiceBillViewModel(),
                    accountId: this.accountId,
                    viewStatement: null,
                    buildingId: this.$stateParams.buildingId,
                    readOnly: !this.budgetAccess.EDIT,
                    fromUrjanet: false,
                    isAqAdmin: this.isAqAdmin,
                    collectors: this.collectors,
                    utilityMeters: this.utilityMeters,
                    urjanetMeters: this.urjanetMeters,
                    utilityServiceBillPeriods: _.sortBy(this.utilityServiceBillPeriods, 'startDateDisplay'),
                    utilityBillingPeriod: utilityBillingPeriod,
                    utilityBillPeriodCharges: []
                }
            } as any).then((newUtilityBillPeriod) => {
                if (newUtilityBillPeriod.startDate) {
                    newUtilityBillPeriod.startDateDisplay = this.transformDateForView(newUtilityBillPeriod.startDate);
                }
                this.utilityServiceBillPeriods.push(newUtilityBillPeriod);
                this.currentNavItem = 'bills';
            });
        }
        public editBillPeriod(utilityBillingPeriod) {
            this.openEditModal(utilityBillingPeriod);
        }

        public openEditModal(utilityBillingPeriod) {
            (this.$mdDialog as any).show({
                controller: 'AddEditSingleBill as vm',
                templateUrl: 'app/utilityBudgets/utilityAccounts/actions/addEditSingleBill/addEditSingleBill.html',
                clickOutsideToClose: false,
                multiple: true,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    utilityServiceModel: this.getUtilityServiceBillViewModel(),
                    accountId: this.accountId,
                    buildingId: this.$stateParams.buildingId,
                    readOnly: !this.budgetAccess.EDIT,
                    isAqAdmin: this.isAqAdmin,
                    collectors: this.collectors,
                    utilityMeters: this.utilityMeters,
                    urjanetMeters: this.urjanetMeters,
                    utilityBillingPeriod: utilityBillingPeriod,
                    utilityServiceBillPeriods: _.sortBy(this.utilityServiceBillPeriods, 'startDateDisplay')
                }
            }).then((bill) => {
                if (bill.startDate) {
                    bill.startDateDisplay = this.transformDateForView(bill.startDate);
                }
                _.remove(this.utilityServiceBillPeriods, (b) => b.id == bill.id);
                this.utilityServiceBillPeriods.push(bill);
            });
        }
        /*************************/

        public deleteUtilityBillPeriod(utilityBillingPeriod) {
            const confirm = this.$mdDialog
                .confirm()
                .title('Are you sure you want to delete this Utility Bill Period?')
                .ok('Confirm')
                .cancel('Cancel');
            return this.$mdDialog
                .show(confirm)
                .then(() => {
                    this.loading.start(true);
                    this.RestangularV3.restangularizeElement('', utilityBillingPeriod, 'utility-bill-periods');
                    utilityBillingPeriod.remove().then(() => {
                        this.Messages.info('Utility Bill Period deleted');
                        _.remove(this.utilityServiceBillPeriods, (u) => u.id == utilityBillingPeriod.id);
                    })
                        .finally(this.loading.stop);
                })
                .catch(() => {
                    this.Errors.forCRUD('DELETE');
                });
        }
        public transformDateForView(dateValue) {
            return dateValue ? moment(dateValue).format('YYYY-MM-DD') : null;
        }
        private getUtilityServiceBillViewModel() {
            return <UtilityServiceBillViewModel>{
                id: Number(this.utilityService.id),
                type: this.utilityService.type,
                utilityCompanyName: this.getCompanyName(this.utilityService.utilityCompany),
                utilityCompanyAccount: this.utilityService.account,
                usageRealUnitDisplay: this.utilityService.usageRealUnitDisplay,
                demandRealUnitDisplay: this.utilityService.demandRealUnitDisplay,
                collectors: this.utilityService.collectors,
                includeSewer: this.utilityService.includeSewer
            };
        }
        private getCompanyName(id: number) {
            const item = _.find(this.utilityCompanies, (uc) => uc.id == id);
            return item ? item.name : null;
        }

    }
    angular.module('aq.utilityBudgets').controller('UtilityServiceDetailsBills', UtilityServiceDetailsBills);
}
