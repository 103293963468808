'use strict';

angular
    .module('aq.admin.reports',
        ['aq.admin.reports.summaryreports', 'aq.admin.reports.tenantbillingreport', 'aq.admin.reports.reportutils',
            'aq.admin.reports.performancereport'])
    .config(($stateProvider, $urlRouterProvider) => {
        $urlRouterProvider.when('/admin/reports', '/admin/reports/summaryreports');
        $stateProvider
            .state('aq.admin.reports', {
                url: '/reports',
                templateUrl: 'app/admin/reports/main.html',
                data: {
                    appName: 'Reports'
                }
            });
    });
