angular
    .module('home', [
        'aq.auth',
        'ui.bootstrap',
        'aq.dashboard.widgets'
    ])
    .config(($stateProvider) => {

        $stateProvider
            .state('aq.home', {
                url: '/?activityId',
                templateUrl: 'app/home/controllers/main.html',
                controller: 'HomeMainCtrl as homeCtrl',
                data: {
                    appName: 'Home'
                },
                resolve: {
                    // On mobile, send us to dashboards immediately
                    mobile($state, $mdMedia, $q, DashboardSelectService, waitForAuthToken) {
                        if (!$mdMedia('gt-sm')) {
                            // Need the defer here so we don't start rendering our home page
                            return DashboardSelectService.getRedirectFromHome()
                                .then(function (redirect) {
                                    if (redirect) {
                                        $state.go(redirect.state, redirect.params);
                                    }
                                });
                        } else {
                            return false;
                        }
                    }
                }
            })
            .state('aq.enrollment', {
                url: '/users/enrollment/:enrollmentId',
                templateUrl: 'app/home/controllers/enrollment.html',
                controller: 'EnrollmentCtrl as vm',
                data: {
                    appName: 'Enrollment'
                },
                resolve: {
                    enrollmentId($stateParams) {
                        return $stateParams.enrollmentId;
                    },
                    account(waitForAuthToken, RestangularV3, enrollmentId) {
                        return RestangularV3.one('enrollments', enrollmentId).customGET('account');
                    },
                    projectLead(waitForAuthToken, RestangularV3, enrollmentId) {
                        return RestangularV3.one('enrollments', enrollmentId).customGET('project-lead');
                    }
                }
            })
            .state('aq.enrollment.project-lead', {
                templateUrl: 'app/home/modals/projectLead.html',
                controller: 'ProjectLeadCtrl as vm',
                resolve: {
                    personas(waitForAuthToken, RestangularV3) {
                        return RestangularV3.one('personas').customGET();
                    },
                    step(waitForAuthToken, RestangularV3, enrollmentId) {
                        return RestangularV3.one('enrollments', enrollmentId).one('step').customGET();
                    },
                    transitoryUsers(waitForAuthToken, RestangularV3, enrollmentId) {
                        return RestangularV3.one('enrollments', enrollmentId).customGET('profiles?partial=true');
                    },
                    transitoryBuildings(waitForAuthToken, RestangularV3, enrollmentId) {
                        return RestangularV3.one('enrollments', enrollmentId).customGET('buildings?partial=true');
                    },
                    accountInfoObject(
                        personas,
                        account,
                        projectLead,
                        transitoryUsers,
                        transitoryBuildings,
                        RestangularV3,
                        $q,
                        enrollmentId,
                        waitForAuthToken
                    ) {
                        const data: aq.home.AccountInfoObject = <aq.home.AccountInfoObject>{};
                        data.projectLead = projectLead;
                        const users: any[] = _.map(transitoryUsers, (profile) => {
                            profile.user.userId = profile.userId;
                            profile.user.profileId = profile.profileId;
                            profile.user.role = profile.roles[0];
                            return profile.user;
                        });
                        data.users = users;
                        data.buildings = transitoryBuildings;
                        data.account = account;
                        data.personas = personas;
                        const personnelRequests = _.map(data.buildings, (building: aq.common.models.Building) => {
                            return RestangularV3
                                .one('enrollments', enrollmentId)
                                .one('buildings', building.id)
                                .customGET('personnel')
                                .then((result) => {
                                    building.personnel = result;
                                    _.each(building.personnel, (person) => {
                                        if (!person.firstName && person.name) {
                                            const split = person.name.split(' ');
                                            person.firstName = split[0];
                                            if (!person.lastName && split.length > 1) {
                                                person.lastName = split[1];
                                            }
                                        }
                                    });
                                })
                                .catch(() => building.personnel = [])
                                .finally(() => {
                                    if (!building.index) {
                                        building.index = building.id as any;
                                    }
                                });
                        });
                        const roles = RestangularV3
                            .one('accounts', data.account.id)
                            .customGET('roles')
                            .then((result) => { data.roles = result; });
                        const allRequests: any[] = personnelRequests.concat(roles);

                        return $q.all(allRequests).then(() => {
                            return data;
                        });
                    }
                }
            });
    });
