var aq;
(function (aq) {
    var ui;
    (function (ui) {
        var EditBuildingModalCtrl = /** @class */ (function (_super) {
            __extends(EditBuildingModalCtrl, _super);
            /* ngInject */
            function EditBuildingModalCtrl(editBuilding, isNew, BuildingService, $mdDialog) {
                var _this = _super.call(this, null, $mdDialog) || this;
                _this.editBuilding = editBuilding;
                _this.isNew = isNew;
                _this.BuildingService = BuildingService;
                _this.$mdDialog = $mdDialog;
                _this.building = angular.copy(_this.editBuilding);
                _this.isAutofill = true;
                return _this;
            }
            EditBuildingModalCtrl.prototype.apply = function () {
                if (!this.isAutofill) {
                    this.building.address = this.searchText;
                }
                this.hide(this.building);
            };
            EditBuildingModalCtrl.prototype.selectedItemChange = function (item) {
                if (item && item.place) {
                    var _a = item.place.properties, street = _a.street, city = _a.city, stateCode = _a.stateCode, postalCode = _a.postalCode, countryCode = _a.countryCode;
                    this.building.address = street;
                    this.building.city = city;
                    this.building.state = stateCode;
                    this.building.zipCode = postalCode;
                    this.building.country = countryCode;
                }
            };
            EditBuildingModalCtrl.prototype.queryAddress = function (searchText) {
                return this.BuildingService.findAddress(searchText);
            };
            return EditBuildingModalCtrl;
        }(aq.common.Controllers.ModalCtrl));
        ui.EditBuildingModalCtrl = EditBuildingModalCtrl;
        angular.module('aq.ui').controller('EditBuildingModalCtrl', EditBuildingModalCtrl);
    })(ui = aq.ui || (aq.ui = {}));
})(aq || (aq = {}));
