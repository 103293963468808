class VornadoTenantReportCtrl {
    public chartConfigs;
    /* @ngInject */
    constructor(
        private invoice: aq.tenantbilling.TenantInvoice,
        private tenant: aq.tenantbilling.Tenant,
        private billing: aq.tenantbilling.MonthlyTenantBilling,
        private tenantChargeValues: aq.tenantbilling.TenantChargeValue[],
        private setup: aq.tenantbilling.BillingSetup,
        private historicalUsage: aq.tenantbilling.TenantInvoiceHistoricalUsage[],
        private SeriesMapping: SeriesMapping,
        private OptionsService
    ) {
        this.chartConfigs = this.getChartConfigs(this.historicalUsage);
    }

    public getChartConfigs(invoices: aq.tenantbilling.TenantInvoiceHistoricalUsage[]) {
        const monthLabels = this.getMonthLabels(invoices);
        const series = this.getSeries(invoices);
        return _.map(series, (s) => {
            return {
                chart: {
                    type: 'column',
                    width: 810
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    categories: monthLabels,
                    crosshair: true,
                    reversed: true
                },
                legend: {
                    enabled: false
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: this.OptionsService.getUnitLabelByMeasure(textural(s.name).format('upper')).unit
                    }
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        pointPadding: -0.2,
                        borderWidth: 0
                    },
                    series: {
                        // turn off animations because of screenshot tool
                        animation: false
                    }
                },
                series: [s]
            };
        });
    }

    public getMonthLabels(invoices: aq.tenantbilling.TenantInvoiceHistoricalUsage[]) {
        return _.map(invoices, (invoice) => {
            const start = moment(invoice.startReadingDate).format('MM/DD/YY');
            const end = moment(invoice.readingDate).format('MM/DD/YY');
            return `${invoice.monthlyBillingName}<br><small style="font-size: 6px">(${start} - ${end})</small>`;
        });
    }

    public getSeries(invoices: aq.tenantbilling.TenantInvoiceHistoricalUsage[]) {
        const chartData = _.map(invoices, 'usagePerService');
        _.each(chartData, (data) => {
            if (data) {
                delete data['FEE'];
                delete data['TAX'];
            }
        });
        return this.SeriesMapping.mapData(chartData);
    }

    public getUsageUnitTotals() {
        const usageUnitTotals = {};
        const chargeValues = this.getChargeValues();

        const uniqueUnits = _(chargeValues)
            .map('usageUnit')
            .uniq()
            .value();
        _.each(uniqueUnits, (unit) => {
            usageUnitTotals[unit] = 0;
        });

        _.each(chargeValues, (chargeValue) => {
            usageUnitTotals[chargeValue.usageUnit] += chargeValue.appliedTotal;
        });

        return usageUnitTotals;
    }

    public getChargeValues() {
        const charges = _(this.tenantChargeValues)
            .filter({ type: 'Metered' })
            .sortBy('meterName')
            .value();
        return charges;
    }
}

angular
    .module('aq.reports')
    .controller('VornadoTenantReportCtrl', VornadoTenantReportCtrl);
