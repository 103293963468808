namespace aq.reports {
    export class MonthlyBuildingCtrl extends BaseBuildingReportCtrl {
        public time: moment.Moment;
        public previousTime: moment.Moment;
        public occupancyData: any;
        public monthIndex: number;
        /* ngInject */
        constructor(
            protected currentDate,
            protected account,
            protected building,
            protected energyStarScore,
            protected functionalities,
            protected OptionsService,
            protected availableMetrics,
            protected meters,
            protected $timeout: ng.ITimeoutService,
            private $q: ng.IQService,
            private ReportApiParams,
            private DataService,
            private MonthlyOccupancyData,
            private MonthlyCharts,
            private $filter
        ) {
            super(
                currentDate,
                account,
                building,
                energyStarScore,
                functionalities,
                OptionsService,
                availableMetrics,
                meters,
                $timeout
            );
            this.time = angular.copy(this.buildingReport.building.currentTime);
            this.time.subtract(1, 'month');
            const currentMonth = angular.copy(this.time);
            this.monthIndex = parseInt(currentMonth.format('M')) - 1;
            this.occupancyData = this.MonthlyOccupancyData.getMonthlyOccupancy(building, this.monthIndex);

            // TODO for now we must query temperature separately because /data endpoint doesn't support temperature
            // (it is from virtual meter which is excluded)
            const temperatureParams = new this.ReportApiParams(this.time, null);
            const temperaturePromise = building.customGET('temperature', temperatureParams.current());

            const currentYearDegreeDaysPromise = DataService.degreeDays(building,
                temperatureParams.currentDegreeDay().interval,
                temperatureParams.currentDegreeDay().start,
                temperatureParams.currentDegreeDay().end);
            const previousYearDegreeDaysPromise = DataService.degreeDays(building,
                temperatureParams.previousDegreeDay().interval,
                temperatureParams.previousDegreeDay().start,
                temperatureParams.previousDegreeDay().end);

            this.previousTime = moment(this.time).subtract(1, 'month');

            let waterBreakdownTempPromise;
            let isWaterBreakdownTempPromiseInit = false;

            const reportPromises = [];
            _.each(this.buildingReport.serviceReports, (report: BuildingServiceReport) => {
                const config = report.config;
                report.data = {
                    loading: true
                } as any;

                const apiParams = new ReportApiParams(this.time, config.measures);
                const currentYearPromise = building.customGET('data', apiParams.current());
                const previousYearPromise = building.customGET('data', apiParams.previous());

                const currentYearPeaksPromise = building.customGET('peaks', apiParams.current());
                const previousYearPeaksPromise = building.customGET('peaks', apiParams.previous());

                const demandPromise = this.DataService.peaks(building, this.time, moment(this.time).add(1, 'month'));

                const waterBreakdownParams = new ReportApiParams(this.time, config.measures, 'monthlyFull');
                const waterBreakdownPromise = config.isWaterOrGasReport
                    ? building.customGET('data', waterBreakdownParams.current())
                    : this.$q.when(null);
                if (config.isWaterOrGasReport) {
                    if (!isWaterBreakdownTempPromiseInit) {
                        waterBreakdownTempPromise = building.customGET('temperature', waterBreakdownParams.current());
                        isWaterBreakdownTempPromiseInit = true;
                    }
                } else if (!isWaterBreakdownTempPromiseInit) {
                    waterBreakdownTempPromise = this.$q.when(null);
                }

                const reportDefer = this.$q.defer();
                this.$q.all([
                    currentYearPromise,
                    previousYearPromise,
                    waterBreakdownPromise,
                    temperaturePromise,
                    currentYearDegreeDaysPromise,
                    previousYearDegreeDaysPromise,
                    demandPromise,
                    waterBreakdownTempPromise,
                    currentYearPeaksPromise,
                    previousYearPeaksPromise
                ]).then((result) => {
                    const currentValues = result[0];
                    const demandValues = result[6];
                    const previousValues = result[1];
                    const waterBreakdownValues = result[2];
                    const temperatureValues = result[7] ? result[7].values : null;
                    currentValues['temperature'] = result[3] ? result[3].values : null;
                    currentValues['degreeDays'] = result[4];
                    previousValues['degreeDays'] = result[5];

                    /***
                     * Have to use the monhtly peaks from the 'findPeaks' endpoint as the
                     * demand values from the data endpoing are average demand, not peak
                     */
                    if (currentValues.power && previousValues.power) {
                        currentValues.power.values = result[8] ? result[8].values : null;
                        previousValues.power.values = result[9] ? result[9].values : null;
                    }

                    const data = report.data;

                    data.usage = currentValues[config.usageMetric].values[this.monthIndex];

                    /***
                     * Get current month from last year as previous usage, if it doesn't
                     * exist then take previous month from this year
                     */
                    data.previousUsage = previousValues[config.usageMetric].values[this.monthIndex] ?
                        previousValues[config.usageMetric].values[this.monthIndex] :
                        currentValues[config.usageMetric].values[this.monthIndex - 1];

                    /***
                     * Get current month from last year as previous demand, if it doesn't
                     * exist then take previous month from this year
                     */
                    data.previousDemand = demandValues.previous.value;

                    data.currentHDD = currentValues.degreeDays.hdd.values[this.monthIndex];
                    data.currentCDD = currentValues.degreeDays.cdd.values[this.monthIndex];

                    /***
                     * Get current month from last year as previous HDD/CDD, if it doesn't
                     * exist then take previous month from this year
                     */
                    data.previousHDD = previousValues.degreeDays.hdd.values[this.monthIndex] ?
                        previousValues.degreeDays.hdd.values[this.monthIndex] :
                        currentValues.degreeDays.hdd.values[this.monthIndex - 1];

                    data.previousCDD = previousValues.degreeDays.cdd.values[this.monthIndex] ?
                        previousValues.degreeDays.cdd.values[this.monthIndex] :
                        currentValues.degreeDays.cdd.values[this.monthIndex - 1];

                    const currentTemperature = this.$filter('arrayToTemperatureUnit')(currentValues.temperature, config.temperatureUnit);
                    const currentData = {
                        data: currentValues,
                        temperature: currentTemperature,
                        year: currentValues.start
                    };

                    const previousTemperature = this.$filter('arrayToTemperatureUnit')(previousValues.temperature, config.temperatureUnit);
                    const previousData = {
                        data: previousValues,
                        temperature: previousTemperature,
                        year: previousValues.start
                    };

                    const monthlyCharts = new this.MonthlyCharts(currentData, previousData, report.config, currentMonth);

                    /* Usage chart data (both report types) */
                    data.usageChart = monthlyCharts.getUsageChart(config.usageMetric, config.usageUnit);

                    if (!config.isWaterOrGasReport) {
                        /* Demand chart data */
                        data.demandChart = monthlyCharts.getDemandChart();
                        data.demand = demandValues.current.value;

                        /* Weather chart data */
                        data.weatherChart = monthlyCharts.getWeatherChart();
                    }

                    if (config.isWaterOrGasReport) {
                        /* Water chart breakdown */
                        data.waterChart = monthlyCharts.getWaterBreakdownChart(waterBreakdownValues, temperatureValues, currentMonth.daysInMonth());
                        data.demand = Math.max.apply(null, waterBreakdownValues[config.demandMetric].values);
                        data.weatherChart = monthlyCharts.getWeatherChart();
                    }

                    data.loading = false;

                    reportDefer.resolve();
                }).catch(() => {
                    reportDefer.reject();
                });
                reportPromises.push(reportDefer.promise);
            });
            this.$q.all(reportPromises)
                .then(() => {
                    this.notifyDocumentReady();
                })
                .catch(() => {
                    this.isDocumentError = true;
                });
        }

        public getServiceType(usageMetric: string) {
            switch (usageMetric) {
                case 'energy':
                    return 'Electricity';
                case 'steam_mass':
                    return 'Steam';
                case 'heat_transfer':
                    return 'Heat';
                case 'gas':
                    return 'Gas';
                case 'water':
                    return 'Water';
                default:
                    return '';
            }
        }

    }
    angular.module('aq.reports').controller('MonthlyBuildingCtrl', MonthlyBuildingCtrl);
}
