namespace aq.settings {
    angular.module('aq.settings',
        [
            'ui.bootstrap',
            'aq.ui.confirmation',
            'aq.ui.elemSelector',
            'aq.ui.image',
            'aq.settings.calendars',
            'aq.auth',
            'aq.services',
            'charting',
            'colorpicker.module',
            'ngclipboard',
            'aq.services.options',
            'aq.filters.formatters',
            'aq.integrations'
        ])
        .config((RestangularProvider: restangular.IProvider, $stateProvider: ng.ui.IStateProvider, $locationProvider) => {
            $stateProvider
                .state('aq.settings', {
                    abstract: true,
                    url: '/accounts/:accountId/settings',
                    templateUrl: 'app/settings/settings.html',
                    controller: 'SettingsController as settingCtrl',
                    data: {
                        appName: 'Account Settings',
                        breadcrumb: 'Settings'
                    },
                    resolve: {
                        user: (waitForAuthToken, initUser) => {
                            return initUser;
                        },
                        accountId($stateParams) {
                            return $stateParams.accountId;
                        },
                        account(Restangular, accountId, waitForAuthToken) {
                            return Restangular.one('accounts', accountId).get({
                                single: true,
                                inflate: 'buildingGroups,accountMetrics,owner,possibleOwners,license,license.functionalities,allApps,functionalities'
                            });
                        },
                        timezones(Restangular, waitForAuthToken) {
                            return Restangular.one('options', 'allTimezones').get();
                        },
                        realUnits(account) {
                            return account.customGET('queryRealUnits');
                        },
                        options(OptionsService, account) {
                            return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
                        },
                        areaMeasurementUnit(options) {
                            return options.areaMeasurementUnit;
                        },
                        authAccess(Auth, user, waitForAuthToken) {
                            return Auth.access;
                        }
                    }
                })
                .state('aq.settings.access', {
                    template: '<div ui-view></div>',
                })
                .state('aq.settings.access.users', {
                    url: '/users',
                    template: '<div />',
                    controller: function () { },
                    data: {
                        breadcrumb: 'Users',
                        icon: 'group'
                    },
                    resolve: {
                        redirect:
                            (waitForAuthToken, $q: ng.IQService, accountId: number, RedirectService: aq.services.RedirectService) => {
                                const defer = $q.defer();
                                const destination = `accounts/${accountId}/settings/users`;
                                RedirectService.redirectWithoutParams(destination);
                                return defer.promise;
                            },
                    }
                })
                .state('aq.settings.access.roles', {
                    url: '/roles',
                    template: '<div />',
                    controller: function () { },
                    data: {
                        breadcrumb: 'Roles',
                        icon: 'assignment_ind',
                        aria: 'User Roles'
                    },
                    resolve: {
                        redirect:
                            (waitForAuthToken, $q: ng.IQService, accountId: number, RedirectService: aq.services.RedirectService) => {
                                const defer = $q.defer();
                                const destination = `accounts/${accountId}/settings/roles`;
                                RedirectService.redirectWithoutParams(destination);
                                return defer.promise;
                            },
                    }
                })
                .state('aq.settings.platform', {
                    url: '/platform',
                    templateUrl: 'app/settings/platform/platformSettings.html',
                    controller: 'PlatformSettingsCtrl as vm',
                    data: { breadcrumb: 'Platform' },
                    onEnter(Segment) {
                        Segment.trackPage('Account Settings:Platform');
                    }
                })
                .state('aq.settings.integrations', {
                    url: '/integrations',
                    template: '<div />',
                    controller: function () { },
                    resolve: {
                        redirect:
                            (waitForAuthToken, $q: ng.IQService, accountId: number, Auth: aq.services.Auth, RedirectService: aq.services.RedirectService) => {
                                const defer = $q.defer();
                                const destination = `accounts/${accountId}/settings/integrations`;
                                RedirectService.redirectWithoutParams(destination);
                                return defer.promise;
                            },
                    },
                    data: {
                        breadcrumb: 'Integrations',
                        icon: 'compare_arrows'
                    }
                })
                .state('aq.settings.marketplace', {
                    url: '/marketplace',
                    template: '<div />',
                    controller: function () { },
                    resolve: {
                        redirect:
                            (waitForAuthToken, $q: ng.IQService, accountId: number, RedirectService: aq.services.RedirectService) => {
                                const defer = $q.defer();
                                const destination = `accounts/${accountId}/settings/marketplace`;
                                RedirectService.redirectWithoutParams(destination);
                                return defer.promise;
                            }
                    },
                    data: {
                        breadcrumb: 'Marketplace',
                        icon: 'store'
                    }
                })
                .state('aq.settings.integrations.details', {
                    url: '/:integrationId/details',
                    templateUrl: 'app/settings/integrations/details/details.html',
                    controller: 'SettingsIntegrationDetailsCtrl as integrationDetailsCtrl',
                    data: { breadcrumb: 'Details' },
                    resolve: {
                        integration(account, $stateParams, waitForAuthToken) {
                            return account.one('integrations', $stateParams.integrationId).get({ single: true, inflate: 'integrationApp,workers' });
                        },
                        integrationLogs(account, $stateParams, waitForAuthToken) {
                            return account.one('integrations', $stateParams.integrationId).customGET('logs', { page: 1, pageSize: 25 });
                        },
                        integrationApp(integration) {
                            return integration.integrationApp;
                        }
                    },
                    onEnter(Segment) {
                        Segment.trackPage('Account Settings:Integrations:Details    ');
                    }
                })
                .state('aq.settings.integrations.details.mri', {
                    url: '/mri',
                    templateUrl: 'app/settings/integrations/details/mri/mri.html',
                    controller: 'MRICtrl as vm',
                    resolve: {
                        account: (
                            Restangular: restangular.IService,
                            accountId, DataStore: aq.common.DataStore,
                            waitForAuthToken
                        ): ng.IPromise<aq.common.models.Account> => {
                            return DataStore.get(Restangular.one('accounts', accountId), { single: true }, true);
                        },
                        mriIntegrations: (
                            RestangularV3: restangular.IService,
                            accountId,
                            DataStore: aq.common.DataStore,
                            waitForAuthToken
                        ) => {
                            return DataStore.getList(RestangularV3.one('mri').one('account', accountId));
                        },
                        buildings: (RestangularV3, waitForAuthToken) => {
                            return RestangularV3.all('buildings').getList();
                        }
                    }
                })
                .state('aq.settings.integrations.new', {
                    url: '/:integrationApp/new',
                    templateUrl: 'app/settings/integrations/details/details.html',
                    controller: 'SettingsIntegrationDetailsCtrl as integrationDetailsCtrl',
                    data: {
                        breadcrumb: 'Details'
                    },
                    resolve: {
                        integrationApp(Restangular, $stateParams, waitForAuthToken) {
                            return Restangular.one('integrationApps', $stateParams.integrationApp).get({ inflate: 'workers' });
                        },
                        integration() {
                            return {};
                        },
                        integrationLogs() {
                            return [];
                        }
                    }
                })
                .state('aq.settings.calendars', {
                    url: '/calendars',
                    templateUrl: 'app/settings/calendars/controllers/calendars/calendarList.html',
                    controller: 'SettingsCalendarListCtrl',
                    data: {
                        breadcrumb: 'Calendars',
                        icon: 'date_range'
                    },
                    resolve: {
                        workCalendars(account) {
                            return account.all('workCalendars').getList({ inflate: 'rates' });
                        },
                        ruleCategories(account) {
                            return account.all('RuleCategories').getList();
                        }
                    }
                })
                .state('aq.settings.properties', {
                    url: '/properties',
                    template: '<div />',
                    controller: function () { },
                    data: {
                        breadcrumb: 'Properties',
                        icon: 'location_city'
                    },
                    resolve: {
                        redirect:
                            (waitForAuthToken, $q: ng.IQService, accountId: number, RedirectService: aq.services.RedirectService) => {
                                const defer = $q.defer();
                                const destination = `accounts/${accountId}/settings/properties`;
                                RedirectService.redirectWithoutParams(destination);
                                return defer.promise;
                            }
                    }
                })
                .state('aq.settings.escalationPolicies', {
                    url: '/escalation-policies',
                    template: '<div />',
                    controller: function () { },
                    data: {
                        breadcrumb: 'Escalation Policies',
                        icon: 'assignment_late'
                    },
                    resolve: {
                        redirect:
                            (waitForAuthToken, $q: ng.IQService, accountId: number, RedirectService: aq.services.RedirectService) => {
                                const defer = $q.defer();
                                const destination = `accounts/${accountId}/settings/escalation-policies`;
                                RedirectService.redirectWithoutParams(destination);
                                return defer.promise;
                            },
                    }
                });
            $locationProvider.html5Mode({
                enabled: true,
                requireBase: false
            });
        });
}
