'use strict';

class PerformanceReportCtrl {
    public selectedAccount: aq.common.models.Account;
    public filterTerm: string;
    public availableBudgetYears;
    public reportyear: string;
    public selectedYearPeriod;
    public selectedMonth;
    public months;
    private isCreatingReport: boolean = false;
    /* @ngInject */
    constructor(
        public accounts: aq.common.models.Account[],
        private RestangularV3: restangular.IService,
        private Messages: aq.services.Messages,
        private $mdDialog: ng.material.IDialogService,
        private $q: ng.IQService,
        private $http: ng.IHttpService,
        private AQ_API_URL: string,
        private AuthToken: string,

    ) {
        accounts = accounts.sort((a, b) => {
            const aName = a.name.toLowerCase();
            const bName = b.name.toLowerCase();
            if (aName > bName) {
                return 1;
            } else if (aName < bName) {
                return -1;
            }
            return 0;
        });
        this.filterTerm = '';
        this.selectedAccount = accounts[0];
        this.updateDataComissionDate();
        const monthOptions = [];
        moment.months().forEach((month, index) => {
            index += 1;
            monthOptions.push({
                name: month,
                value: index
            });
        });
        this.months = monthOptions;
    }

    public updateDataComissionDate() {
        this.selectedMonth = null;
        this.selectedYearPeriod = null;
        const primaryPromise = this.RestangularV3
            .one('accounts', this.selectedAccount.id)
            .one('primary-buildings')
            .customGET('');
        const sharedPromise = this.RestangularV3
            .one('accounts', this.selectedAccount.id)
            .one('shared-buildings')
            .customGET('');
        this.$q.all([primaryPromise, sharedPromise]).then((result) => {
            result[0] = result[0].plain();
            result[1] = result[1].plain();
            let buildings = [];
            buildings = result[0].concat(result[1]);
            buildings = _.filter(buildings, (b: Building) => {
                return !(b.dataCommissionDate == null && b.enticImpactDate == null);
            });
            this.updateAvailableYears(buildings);
        });
    }

    public updateAvailableYears(buildings: Building[]) {
        const now = this.getNow();
        if (buildings.length < 1) {
            const before = this.getNow().subtract(5, 'years');
            this.availableBudgetYears = this.getYearOptionsBetweenDates(before, now);
            this.alertNoCommissionedBuildings();
        } else {
            const oldestCommissionDate = this.getOldestCommissionDate(buildings);
            this.availableBudgetYears = this.getYearOptionsBetweenDates(oldestCommissionDate, now);
            this.reportyear = this.availableBudgetYears[0].name;
        }
    }

    // Get year options between `start` and `end` in descending order
    // Assumes `start` is always before `end`
    public getYearOptionsBetweenDates(start: moment.Moment, end: moment.Moment): { name: string, year: number }[] {
        const yearCounter: number = start.year();
        const date = moment(end);
        const availableBudgetYears = [];
        while (date.year() >= yearCounter) {
            const year = date.format('YYYY');
            availableBudgetYears.push({
                name: `${year}`,
                year
            });
            date.subtract(1, 'year');
        }
        return availableBudgetYears;
    }

    public getOldestCommissionDate(buildings: Building[]): moment.Moment {
        let oldestCommissionDate: moment.Moment = null;
        buildings.forEach((building) => {
            if (building.dataCommissionDate) {
                const dataCommissionDate = moment(building.dataCommissionDate);
                if (!oldestCommissionDate || dataCommissionDate.isBefore(oldestCommissionDate)) {
                    oldestCommissionDate = dataCommissionDate;
                }
            }
            if (building.enticImpactDate) {
                const enticImpactDate = moment(building.enticImpactDate);
                if (!oldestCommissionDate || enticImpactDate.isBefore(oldestCommissionDate)) {
                    oldestCommissionDate = enticImpactDate;
                }
            }
        });
        return oldestCommissionDate;
    }

    public clearFilter() {
        this.filterTerm = '';
    }

    public alertNoCommissionedBuildings() {
        const accountName = this.selectedAccount.name;
        const alert = this.$mdDialog.alert()
            .title(`Data Commission Date and/or Entic Impact Date are NOT DEFINED for any building under
                    "${accountName}". The report may NOT generate until these fields are defined in Property Settings.`)
            .ariaLabel('No Commissioned Buildings')
            .ok('Ok');
        this.$mdDialog.show(alert);
    }

    public confirmCreatePerformanceReport(ev) {
        const accountName = this.selectedAccount.name;
        const confirm = this.$mdDialog.confirm()
            .title(`Are you sure you want start the Performance Report for ${accountName}?`)
            .ariaLabel('Start Performance')
            .targetEvent(ev)
            .ok('Ok')
            .cancel('Cancel');
        this.$mdDialog.show(confirm).then(() => {
            this.createPerformanceReport();
        });
    }

    public createPerformanceReport() {
        this.isCreatingReport = true;
        this.$http({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.AuthToken}`
            },
            url: `${this.AQ_API_URL}performance-report/create`,
            params: {
               accountId: this.selectedAccount.id,
               reportMonth: this.selectedMonth.value,
               reportYear: this.selectedYearPeriod.year
            }
            }).then(() => {
                this.isCreatingReport = false;
                this.Messages.success('Success! Report generation can take up to several minutes.');
            })
            .catch(() => {
                this.isCreatingReport = false;
                this.Messages.error('Error while trying to queue Performance Report request');
            });

    }

    public getNow(): moment.Moment {
        return moment();
    }
}

angular.module('aq.admin.reports.performancereport')
    .controller('PerformanceReportCtrl', PerformanceReportCtrl);
