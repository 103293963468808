namespace aq.utilityBudgets {

    export interface UtilityServicesListCtrlScope extends ng.IScope {
        utilityCompanies: aq.common.models.NamedItem[];
        utilityAccounts: aq.utilityBudgets.UtilityAccount[];
        accountId: string;
    }

    export interface UtilityAccountStatusViewModel {
        value: string;
        icon: string;
        description: string;
        helpText: string;
    }

    interface UtilityServiceMap {
        [utilityAccount: number]: UtilityService[];
    }

    interface BillPeriodData {
        intervalDisplay: string;
        blendedRate: number;
        charge: number;
        consumption: number;
    }

    export class UtilityAccountsListCtrl {
        public filteredUtilityAccounts: UtilityAccountViewModel[];
        public utilityAccountsViewItems: UtilityAccountViewModel[];
        public isEmptySearch: boolean;
        public serviceMeterCountMap: {
            [serviceId: number]: {
                utility: number;
                interval: number;
            };
        };
        public serviceBillPeriodMap: {
            [serviceId: number]: {
                lastPeriod?: BillPeriodData;
                priorPeriod?: BillPeriodData;
            };
        };
        public urjanetStatusMap: {
            [utilityAccountId: number]: {
                isLoading: boolean;
                nextExpectedBillDate: string;
            };
        };
        private lastSearchQuery: string;
        private budgetAccess: common.models.AppAccessObject;
        private utilityAccountViewHelper: UtilityAccountViewModelHelper;
        private mapBuildings: Record<number, string>;
        private expandedServiceId: number;

        /* @ngInject */
        constructor(private $scope: UtilityServicesListCtrlScope,
            private RestangularV3: restangular.IService,
            private Messages,
            private Errors,
            public $state: ng.ui.IStateService,
            private utilityCompanies: aq.common.models.NamedItem[],
            private utilityAccounts: UtilityAccount[],
            private mapUtilityAccountBuildingIds: Record<number, number[]>,
            private accountId: string,
            private buildingId: string,
            private buildings: aq.common.models.Building[],
            public $mdDialog: ng.material.IDialogService,
            private $mdMedia: ng.material.IMedia,
            private UtilityServiceHelper: UtilityServiceHelper,
            private authAccess: common.models.AuthAppAccess,
            private users: aq.user.User[],
            private UserService: aq.services.UserService,
            private utilityServices: UtilityServiceMap,
            private currencyUnitSymbol: string,
            private Segment: aq.services.SegmentService
        ) {
            if (this.$state.current.name === 'aq.utilityBudgets.utilityaccounts.utilityAccountServiceBills') {
                if (!this.isValidUtilityService()) {
                    this.$state.go('aq.utilityBudgets.utilityaccounts');
                    this.expandedServiceId = null;
                } else {
                    this.expandedServiceId = this.$state.params.serviceId;
                }
            }
            this.sortServices();
            this.budgetAccess = this.authAccess.Budgets;
            this.utilityAccountViewHelper = new UtilityAccountViewModelHelper(utilityCompanies, this.UtilityServiceHelper);
            this.serviceMeterCountMap = {};
            this.serviceBillPeriodMap = {};
            this.urjanetStatusMap = {};
            this.updateViewFromUtilityAccounts(this.utilityAccounts, this.lastSearchQuery);
            this.filteredUtilityAccounts = this.utilityAccountsViewItems;
            this.mapBuildings = this.getMapBuildings();

            this.$scope.$on('UTILITY_ACCOUNT_UPDATED', ($event, updatedUtilityAccount: UtilityAccount) => {
                const utilityAccount = _.findById(this.utilityAccounts, updatedUtilityAccount.id);
                _.extend(utilityAccount, updatedUtilityAccount);
                this.updateViewFromUtilityAccounts(this.utilityAccounts, this.lastSearchQuery);
            });
            this.$scope.$on('UTILITY_ACCOUNT_REMOVED', ($event, deletedAccountId) => {
                this.utilityAccounts = this.utilityAccounts.filter(utilityAccount => utilityAccount.id !== deletedAccountId);
                this.updateViewFromUtilityAccounts(this.utilityAccounts, this.lastSearchQuery);
            });

            const dateFormat = 'YYYY-MM-DD';
            if (this.utilityServices != null) {
                _.each(this.utilityAccounts, (account: UtilityAccount) => {
                    if (account.status != null) {
                        this.urjanetStatusMap[account.id] = { isLoading: true, nextExpectedBillDate: null };
                        this.RestangularV3.one('utility-accounts', account.id)
                            .customGET('auto-utility-upload')
                            .then((response: UrjanetEnrollmentResponse) => {
                                const nextExpectedBillDate = response && response.nextExpectedPostDate
                                    ? moment(response.nextExpectedPostDate).format('MMM D')
                                    : '';
                                this.urjanetStatusMap[account.id] = { isLoading: false, nextExpectedBillDate };
                            })
                            .catch(() => {
                                this.urjanetStatusMap[account.id] = { isLoading: false, nextExpectedBillDate: '' };
                            });
                    }
                    _.each(this.utilityServices[account.id], (service: UtilityService) => {
                        if (!service.billType) {
                            service.billType = BillType.UTILITY;
                        }
                        this.serviceMeterCountMap[service.id] = { utility: null, interval: null };
                        this.RestangularV3.all('utility-meters')
                            .customGET('', { utilityServiceId: service.id })
                            .then((meters) => {
                                this.serviceMeterCountMap[service.id].utility = meters ? meters.length : 0;
                            });
                        this.RestangularV3.all('interval')
                            .customGETLIST(`getIntervalMetersByUtilityService/${service.id}`)
                            .then((intervalMeters) => {
                                this.serviceMeterCountMap[service.id].interval = intervalMeters ? intervalMeters.length : 0;
                            });
                        this.RestangularV3.all('utility-bill-periods')
                            .customGET('by-utility-service', { serviceId: service.id })
                            .then((billPeriods) => {
                                const sortedBillPeriods = _.sortBy(billPeriods, (bill: UtilityBillPeriod) => {
                                    return moment(bill.endDate).valueOf();
                                });
                                const lastPeriod: UtilityBillPeriod = sortedBillPeriods.length > 0 ? sortedBillPeriods[sortedBillPeriods.length - 1] : null;
                                const priorPeriod: UtilityBillPeriod = sortedBillPeriods.length > 1 ? sortedBillPeriods[sortedBillPeriods.length - 2] : null;
                                this.serviceBillPeriodMap[service.id] = (lastPeriod != null || priorPeriod != null) ? {} : null;
                                if (lastPeriod != null) {
                                    const lastPeriodDisplay = this.getIntervalDisplay(lastPeriod);
                                    const lastBlendedRate = this.getBlendedRate(lastPeriod);
                                    this.serviceBillPeriodMap[service.id].lastPeriod = {
                                        intervalDisplay: lastPeriodDisplay,
                                        blendedRate: lastBlendedRate,
                                        charge: (lastPeriod.charge || lastPeriod.cost),
                                        consumption: lastPeriod.usage
                                    };
                                }
                                if (priorPeriod != null) {
                                    const priorPeriodDisplay = this.getIntervalDisplay(priorPeriod);
                                    const priorBlendedRate = this.getBlendedRate(priorPeriod);
                                    this.serviceBillPeriodMap[service.id].priorPeriod = {
                                        intervalDisplay: priorPeriodDisplay,
                                        blendedRate: priorBlendedRate,
                                        charge: (priorPeriod.charge || priorPeriod.cost),
                                        consumption: priorPeriod.usage
                                    };
                                }
                            });
                    });
                });
            }
        }

        public isValidUtilityService() {
            const utilityAccountId = parseInt(this.$state.params.utilityAccountId);
            const serviceId = this.$state.params.serviceId;
            const isValid = _.some(this.utilityServices[utilityAccountId], (us) => us.id == serviceId);
            return isValid;
        }

        public toggleExpandService(utilityAccountId: number, serviceId: number, e: JQueryEventObject) {
            e.preventDefault();
            e.stopPropagation();
            if (serviceId == this.expandedServiceId) {
                this.expandedServiceId = null;
                if (this.$state.current.name === 'aq.utilityBudgets.utilityaccounts.utilityAccountServiceBills') {
                    this.$state.go('^');
                }
            } else {
                this.expandedServiceId = serviceId;
                this.$state.go('aq.utilityBudgets.utilityaccounts.utilityAccountServiceBills', {
                    utilityAccountId,
                    serviceId
                });
            }
        }

        public getIntervalDisplay(period) {
            return `${moment(period.startDate).format('MMM DD, YYYY')} - ${moment(period.endDate).format('MMM DD, YYYY')}`;
        }

        public getBlendedRate(period) {
            if (!period) {
                return null;
            }
            return period.usage > 0 ? (period.charge || period.cost) / period.usage : null;
        }

        public getBuildingNames(ids: number[]) {
            const names = _.map(ids, id => this.mapBuildings[id]);
            return names.join(', ');
        }

        public sortServices() {
            if (!this.utilityServices) {
                return;
            }
            _.each(this.utilityAccounts, (utilityAccount: UtilityAccount) => {
                if (!this.utilityServices[utilityAccount.id]) {
                    return;
                }
                this.utilityServices[utilityAccount.id].sort((s1, s2) => (s1.type < s2.type ? -1 : 1));
            });
        }

        public addAccount(event?) {
            this.$mdDialog.show({
                controller: 'WizardSelectionModalCtrl as vm',
                templateUrl: 'app/utilityBudgets/utilityAccounts/actions/wizard/wizardSelectionModal.html',
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                targetEvent: event,
                locals: {
                    mode: null,
                    utilityCompanies: this.utilityCompanies,
                    buildingViewItems: this.getBuildingViewItems(),
                    accountId: this.accountId,
                    buildingId: this.buildingId,
                    users: this.getUserItems()
                },
                multiple: true
            } as any);
        }

        public searchBy(query: string): void {
            this.lastSearchQuery = query;
            if (query) {
                this.filteredUtilityAccounts = this.utilityAccountsViewItems
                    .filter((service) => {
                        const searchThrough: string[] = [service.utilityCompanyName, service.accountNumber];
                        return this.foundIn(searchThrough, query);
                    });
            } else {
                this.filteredUtilityAccounts = this.utilityAccountsViewItems;
            }
            this.isEmptySearch = !!query && this.filteredUtilityAccounts.length === 0;
        }

        public getBuildingViewItems() {
            if (!this.buildings) {
                return [];
            }
            const items = _.map(this.buildings, (building: aq.common.models.Building) => {
                return <aq.common.models.NamedItem>{
                    id: building.id,
                    name: building.name
                };
            });
            return items;
        }

        public getUserItems() {
            return _.map(this.users, (user: aq.user.User) => {
                return _.pick(user, ['id', 'fullName', 'email']);
            });
        }

        public editUtilityAccountItem(utilityAccountItem: UtilityAccountViewModel) {
            this.expandedServiceId = null;
            this.$state.go('aq.utilityBudgets.utilityaccounts.details.utilityaccount.info', { utilityAccountId: utilityAccountItem.id });
        }

        public createUtilityService(utilityAccountItem: UtilityAccountViewModel) {
            const utilityAccount = _.find(this.utilityAccounts, (uac) => uac.id == utilityAccountItem.id);
            this.$mdDialog.show({
                controller: 'AddUtilityService as vm',
                templateUrl: 'app/utilityBudgets/utilityAccounts/actions/addUtilityService/addUtilityService.html',
                clickOutsideToClose: false,
                multiple: true,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    utilityServices: this.utilityServices[utilityAccountItem.id],
                    utilityAccount,
                    buildingId: this.buildingId
                }
            } as any).then((newUtilityService) => {
                this.utilityServices[utilityAccountItem.id].push(newUtilityService);
                this.serviceMeterCountMap[newUtilityService.id] = { utility: 0, interval: 0 };
                this.serviceBillPeriodMap[newUtilityService.id] = null;
                this.sortServices();
                this.editUtilityService(utilityAccountItem, newUtilityService);
            });
        }

        public editUtilityService(utilityAccountItem, utilityService) {
            this.expandedServiceId = null;
            this.$state.go('aq.utilityBudgets.utilityaccounts.details.utilityservice.info', {
                utilityAccountId: utilityAccountItem.id, serviceId: utilityService.id
            });
        }

        public deleteUtilityService(service) {
            const serviceId = service.id;
            service.remove()
                .then(() => {
                    _.remove(this.utilityServices, { id: serviceId });
                    this.Messages.info('Utility Service deleted');
                })
                .catch(() => {
                    this.Errors.forCRUD('DELETE');
                });
        }

        public getSharedBuildings(utilityAccountItem: UtilityAccountViewModel) {
            return this.RestangularV3.one('utility-accounts', utilityAccountItem.id).customGET('buildings');
        }

        private foundIn(searchInArray: string[], searchFor: string): boolean {
            return searchInArray.some((searchIn) => {
                if (searchIn && _.includes(searchIn.toUpperCase(), searchFor.toUpperCase())) {
                    return true;
                }
            });
        }

        private updateViewFromUtilityAccounts(utilityAccounts, lastSearchQuery) {
            const isDisableDelete = !this.budgetAccess.FULL_ACCESS;
            this.utilityAccountsViewItems = _.map(this.utilityAccounts, (ua) => {
                return this.utilityAccountViewHelper.getUtilityAccountViewModel(ua, isDisableDelete);
            });
            this.searchBy(lastSearchQuery);
        }

        private getShortURI(uri: string): string {
            try {
                const { hostname } = new URL(uri.trim());
                // I know syntactically this is weird, but it prevents issues if there are
                // multiple subdomains 'some.energy.company.com'
                // since we only want the domain name and TLD
                const split = hostname.split('.');
                const tld = split.pop();
                const host = split.pop();
                return `${host}.${tld}/…`;
            } catch (_err) {
                return uri;
            }
        }

        private getMapBuildings() {
            const result = {};
            _.each(this.buildings, (b: Building) => {
                result[b.id] = b.name;
            });
            return result;
        }
    }
    angular.module('aq.utilityBudgets').controller('UtilityAccountsListCtrl', UtilityAccountsListCtrl);
}
