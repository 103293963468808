var aq;
(function (aq) {
    var services;
    (function (services) {
        var ImpactType = aq.models.projects.ImpactType;
        var ProjectServiceV3 = /** @class */ (function () {
            /* @ngInject */
            function ProjectServiceV3($http, resolver, AQ_API_URL, AuthToken, $location, UserService, RestangularV3) {
                this.$http = $http;
                this.resolver = resolver;
                this.AQ_API_URL = AQ_API_URL;
                this.AuthToken = AuthToken;
                this.$location = $location;
                this.UserService = UserService;
                this.RestangularV3 = RestangularV3;
            }
            ProjectServiceV3.prototype.getProjects = function (building) {
                return __awaiter(this, void 0, void 0, function () {
                    var error_1;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.UserService.getUser()];
                            case 1:
                                _a.sent();
                                return [2 /*return*/, this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                                        var response;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, this.$http({
                                                        method: 'GET',
                                                        headers: this.getHeaders(),
                                                        url: this.AQ_API_URL + "projects/v1",
                                                        params: {
                                                            buildingId: building
                                                        }
                                                    })];
                                                case 1:
                                                    response = _a.sent();
                                                    return [2 /*return*/, response.data];
                                            }
                                        });
                                    }); })];
                            case 2:
                                error_1 = _a.sent();
                                throw error_1;
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            ProjectServiceV3.prototype.getStatusDetails = function (request) {
                return __awaiter(this, void 0, void 0, function () {
                    var error_2;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.UserService.getUser()];
                            case 1:
                                _a.sent();
                                return [2 /*return*/, this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                                        var response;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, this.$http({
                                                        method: 'POST',
                                                        headers: this.getHeaders(),
                                                        data: JSON.stringify(request),
                                                        url: this.AQ_API_URL + "projects/v1/status-details",
                                                    })];
                                                case 1:
                                                    response = _a.sent();
                                                    return [2 /*return*/, response.data];
                                            }
                                        });
                                    }); })];
                            case 2:
                                error_2 = _a.sent();
                                throw error_2;
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            ProjectServiceV3.prototype.getHeaders = function () {
                var profile;
                var auth = this.AuthToken ? this.AuthToken : this.$location.search().authToken;
                if (this.UserService.currentUser.currentProfile.id) {
                    profile = this.UserService.currentUser.currentProfile.id;
                }
                else if (this.UserService.currentUser.currentProfile) {
                    profile = this.UserService.currentUser.currentProfile;
                }
                else {
                    profile = this.$location.search().profileId;
                }
                return {
                    'Content-Type': 'application/json',
                    Authorization: "Bearer " + auth,
                    'x-aq-profile': profile
                };
            };
            ProjectServiceV3.prototype.getEstimatedImpact = function (impact, minField, maxField) {
                var retVal = {
                    amount: 0,
                    isEstimated: false
                };
                var min = (impact && impact[minField]) || null;
                var max = (impact && impact[maxField]) || null;
                if (min !== null && max !== null && min !== 0 && max !== 0) {
                    retVal.amount = Math.round(((min + max) / 2) / 10) * 10; // Round average to nearest 10
                    retVal.isEstimated = true;
                }
                else if ((min === null || min === 0) && (max !== null && max !== 0)) {
                    retVal.amount = max;
                }
                else if ((max === null || max === 0) && (min !== null && min !== 0)) {
                    retVal.amount = min;
                }
                return retVal;
            };
            ProjectServiceV3.prototype.getImpactValue = function (project, isProjectEstimationEnabled, isAggregation, unit) {
                var value = project.impact ? project.impact.impactLineItems : [];
                var utilityItem = value.find(function (item) { return item.impactType === ImpactType.UTILITY; });
                var impact = unit === 'KWH' ? utilityItem : project.impact;
                var maxField = unit === 'KWH' ? 'estimatedSavingsAmountEnergyMax' : 'totalEstimatedSavingsAmountMax';
                var minField = unit === 'KWH' ? 'estimatedSavingsAmountEnergyMin' : 'totalEstimatedSavingsAmountMin';
                var actualField = unit === 'KWH' ? 'actualSavingsAmountEnergy' : 'totalActualSavingsAmount';
                var completed = project.status.name === 'Completed';
                var projectImpact = (impact && impact[maxField]) || 0;
                var estimated = false;
                var projectImpactMin = 0;
                if (isProjectEstimationEnabled && completed) {
                    if (impact && impact[actualField]) {
                        projectImpact = impact[actualField];
                    }
                    else {
                        var estimatedImpact = this.getEstimatedImpact(impact, minField, maxField);
                        projectImpact = estimatedImpact.amount;
                        estimated = estimatedImpact.isEstimated;
                    }
                }
                else if (isProjectEstimationEnabled && !completed && isAggregation) {
                    var estimatedImpact = this.getEstimatedImpact(impact, minField, maxField);
                    projectImpact = estimatedImpact.amount;
                    estimated = estimatedImpact.isEstimated;
                }
                else {
                    projectImpactMin = (impact && impact[minField]) || 0;
                }
                // If there is a min but not a max, treat it like there's only a max
                if (projectImpactMin !== 0 && (projectImpact === 0 || projectImpact === null)) {
                    return { projectImpact: projectImpactMin, estimated: estimated, projectImpactMin: projectImpact };
                }
                return {
                    projectImpact: projectImpact, estimated: estimated, projectImpactMin: projectImpactMin
                };
            };
            return ProjectServiceV3;
        }());
        services.ProjectServiceV3 = ProjectServiceV3;
        angular
            .module('aq.services')
            .service('ProjectServiceV3', ProjectServiceV3);
    })(services = aq.services || (aq.services = {}));
})(aq || (aq = {}));
