namespace aq.reports.project {
    export class ProjectCtrl extends BaseDocraptorReportCtrl {
        public data: BuildingsReportItem[];
        public groupData: BuildingGroupReportItem[];
        public summaryGroupData: DataSummary;
        public reportDateView: string;
        public reportName: string;
        public currentYear: string;
        public currencyUnit;
        public isAnyImpactDate: boolean;
        public accountDate: moment.Moment;
        public readonly personas = {
            'ASSET_MANAGER': 'Asset Manager',
            'PROPERTY_MANAGER': 'Property Manager',
            'BUILDING_ENGINEER': 'Building Engineer'
        };
        /* ngInject */
        constructor(
            private account,
            private buildings: aq.common.models.Building[],
            private buildingGroups,
            private buildingPersonnels,
            private buildingOccupancy: { [buildingId: string]: number },
            private buildingConsumptions: { [buildingId: number]: number },
            private buildingUtilitySpend: { [buildingId: number]: number[] },
            private projects: { [buildingId: number]: ProjectDataItem[] },
            private buildingStartDates: { [id: string]: moment.Moment },
            private date,
            private OptionsService,
            protected $timeout: ng.ITimeoutService
        ) {
            super($timeout);
            this.isAnyImpactDate = _.some(this.buildings, (building: aq.common.models.Building) => building.enticImpactDate);
            this.currencyUnit = this.OptionsService.currencyUnit();
            this.currentYear = moment(this.date).format('YYYY');
            const formattedDate = moment(this.date).format('YYYY-MM-DD');
            this.accountDate = moment(formattedDate + 'T00:00:00Z').tz(this.account.timeZoneId);
            this.accountDate.subtract(this.accountDate.utcOffset(), 'minute');
            this.reportName = `${this.isAnyImpactDate ? '' : 'YTD '}Projects Summary Report through ${this.accountDate.format('MMMM D, YYYY')}`;
            this.initReportItems();
            this.initBuildingGroupAndAccountSummary();

            this.notifyDocumentReady();
        }
        public initReportItems() {
            const statusesOrder = {
                'Declined': 0,
                'Needs Acceptance': 1,
                'Backlog': 2,
                'In Progress': 3,
                'Completed': 4
            };
            this.data = [];
            this.groupData = [];
            _.each(this.buildings, (building: aq.common.models.Building) => {
                const buildingProjects: ProjectDataItem[] = this.projects[building.id] || [];
                const pastSpending = this.getPastBuildingSpend(building.id);
                _.each(buildingProjects, (item: ProjectDataItem) => {
                    item.dateStartView = (item.startDate && moment(item.startDate).format('MM/DD/YYYY')) || null;
                    item.impactClass = this.getImpactClass(item.impact);
                });
                const buildingReportItem: BuildingsReportItem = {
                    building: this.getBuildingViewItem(building),
                    projectItems: buildingProjects.sort((a: ProjectDataItem, b: ProjectDataItem) => {
                        if (a.status == 'Completed' && b.status != 'Completed' || a.status != 'Completed' && b.status == 'Completed') {
                            return statusesOrder[a.status] - statusesOrder[b.status];
                        } else if (a.priority != b.priority) {
                            return b.priority - a.priority;
                        } else if (a.status != b.status) {
                            return statusesOrder[a.status] - statusesOrder[b.status];
                        } else {
                            return moment(a.startDate).valueOf() - moment(b.startDate).valueOf();
                        }
                    }),
                    summary: this.getBuildingProjectStatSummary(buildingProjects)
                };
                const openStatuses = ['Declined', 'Needs Acceptance', 'Backlog', 'In Progress'];
                _.each(buildingReportItem.projectItems, (item: ProjectDataItem, index) => {
                    if (index + 1 < buildingReportItem.projectItems.length) {
                        const next = buildingReportItem.projectItems[index + 1];
                        const currentStatusIsOpen = _.find(openStatuses, (status) => status == item.status);
                        const nextStatusIsClosed = !_.find(openStatuses, (status) => status == next.status);
                        if (currentStatusIsOpen && nextStatusIsClosed) {
                            (item as any).lastOpen = true;
                        }
                    }
                });
                buildingReportItem.summary.consumption = this.buildingConsumptions[building.id] * building.size || 0;
                buildingReportItem.summary.currentSpend = this.getCurrentBuildingSpend(building.id);
                buildingReportItem.summary.pastSpend = pastSpending;
                buildingReportItem.summary.isBuildingDataMissing = false;
                this.data.push(buildingReportItem);
                const buildingGroupItem = this.getOrCreateBuildingGroupItem(building);
                buildingGroupItem.groupBuildings.push(buildingReportItem);
            });
        }
        public getImpactClass(value: number) {
            const impactClasses = {
                1: 'Low',
                2: 'Medium',
                3: 'High'
            };
            if (!value || !impactClasses[value]) {
                return 'Undefined';
            }
            return impactClasses[value];
        }
        public getCurrentBuildingSpend(buildingId) {
            if (this.buildingUtilitySpend[buildingId]) {
                return this.buildingUtilitySpend[buildingId][1];
            }
            const month = moment(this.date).month() + 1;
            const building = _.find(this.buildings, (b: aq.common.models.Building) => b.id == buildingId);
            const fiscalStartMonth = building && building.fiscalStartMonth ? building.fiscalStartMonth : 1;
            const n = fiscalStartMonth > month
                ? 12 - (fiscalStartMonth - month) + 1
                : month - fiscalStartMonth + 1;
            return Math.round(n * 1000000 / 12);
        }
        public getPastBuildingSpend(buildingId) {
            return this.buildingUtilitySpend[buildingId] ? this.buildingUtilitySpend[buildingId][0] : 1000000;
        }
        public initBuildingGroupAndAccountSummary() {
            _.each(this.groupData, (group: BuildingGroupReportItem) => {
                group.summary.inProgressCount = _.sumBy(group.groupBuildings, (item: BuildingsReportItem) => item.summary.inProgressCount);
                group.summary.declinedCount = _.sumBy(group.groupBuildings, (item: BuildingsReportItem) => item.summary.declinedCount);
                group.summary.backlogCount = _.sumBy(group.groupBuildings, (item: BuildingsReportItem) => item.summary.backlogCount);
                group.summary.needsAcceptanceCount = _.sumBy(group.groupBuildings, (item: BuildingsReportItem) => item.summary.needsAcceptanceCount);
                group.summary.completedCount = _.sumBy(group.groupBuildings, (item: BuildingsReportItem) => item.summary.completedCount);
                group.summary.unresolvedSaving = _.sumBy(group.groupBuildings, (item: BuildingsReportItem) => item.summary.unresolvedSaving);
                group.summary.resolvedSaving = _.sumBy(group.groupBuildings, (item: BuildingsReportItem) => item.summary.resolvedSaving);
                group.summary.currentSpend = _.sumBy(group.groupBuildings, (item: BuildingsReportItem) => item.summary.currentSpend);
                group.summary.pastSpend = _.sumBy(group.groupBuildings, (item: BuildingsReportItem) => item.summary.pastSpend);
                group.summary.consumption = _.sumBy(group.groupBuildings, (item: BuildingsReportItem) => item.summary.consumption);
                group.summary.sqft = _.sumBy(group.groupBuildings, (item: BuildingsReportItem) => item.building.sqft || 0);
                group.summary.isBuildingDataMissing = false;
                this.setResolutionPercent(group.summary);
                this.setChartData(group.summary);
            });
            this.summaryGroupData = this.getEmptyDataSummary();
            this.summaryGroupData.inProgressCount = _.sumBy(this.groupData, (item: BuildingGroupReportItem) => item.summary.inProgressCount);
            this.summaryGroupData.declinedCount = _.sumBy(this.groupData, (item: BuildingsReportItem) => item.summary.declinedCount);
            this.summaryGroupData.backlogCount = _.sumBy(this.groupData, (item: BuildingsReportItem) => item.summary.backlogCount);
            this.summaryGroupData.needsAcceptanceCount = _.sumBy(this.groupData, (item: BuildingsReportItem) => item.summary.needsAcceptanceCount);
            this.summaryGroupData.completedCount = _.sumBy(this.groupData, (item: BuildingsReportItem) => item.summary.completedCount);
            this.summaryGroupData.unresolvedSaving = _.sumBy(this.groupData, (item: BuildingGroupReportItem) => item.summary.unresolvedSaving);
            this.summaryGroupData.resolvedSaving = _.sumBy(this.groupData, (item: BuildingGroupReportItem) => item.summary.resolvedSaving);
            this.summaryGroupData.currentSpend = _.sumBy(this.groupData, (item: BuildingGroupReportItem) => item.summary.currentSpend);
            this.summaryGroupData.pastSpend = _.sumBy(this.groupData, (item: BuildingGroupReportItem) => item.summary.pastSpend);
            this.summaryGroupData.consumption = _.sumBy(this.groupData, (item: BuildingGroupReportItem) => item.summary.consumption);
            this.summaryGroupData.sqft = _.sumBy(this.groupData, (item: BuildingGroupReportItem) => item.summary.sqft);
            this.summaryGroupData.isBuildingDataMissing = false;
            this.setResolutionPercent(this.summaryGroupData);
        }
        public getBuildingViewItem(building: aq.common.models.Building) {
            const buildingState = building.state ? building.state.replace('District of Columbia', '') : '';
            const buildingPersonnel = this.getBuildingPersonnel(building);
            return {
                id: building.id as any,
                name: building.name,
                imageUrl: building.imageUrl,
                streetAddress: building.address,
                cityAddress: `${building.city}, ${buildingState} ${building.zipCode}`,
                personnel: buildingPersonnel,
                fiscalStartMonth: building.fiscalStartMonth,
                sqft: building.size,
                occupancy: this.buildingOccupancy[building.id] ? `${this.buildingOccupancy[building.id]}%` : '',
                reportDateView: `${this.buildingStartDates[building.id].format('M/D/YY')} - ${this.accountDate.format('M/D/YY')}`
            };
        }
        public getBuildingProjectStatSummary(projects: ProjectDataItem[]) {
            type PDI = ProjectDataItem;
            const openStatuses = ['Declined', 'Needs Acceptance', 'Backlog', 'In Progress']
            const summary: DataSummary = {
                inProgressCount: _.filter(projects, (p: PDI) => p.status == 'In Progress').length,
                backlogCount: _.filter(projects, (p: PDI) => p.status == 'Backlog').length,
                declinedCount: _.filter(projects, (p: PDI) => p.status == 'Declined').length,
                needsAcceptanceCount: _.filter(projects, (p: PDI) => p.status == 'Needs Acceptance').length,
                completedCount: _.filter(projects, (p: PDI) => p.status == 'Completed').length,
                resolutionPercent: null,
                unresolvedSaving: _.sumBy(projects, (p: PDI) => _.find(openStatuses, (item) => item == p.status) ? p.estimatedSavings || 0 : 0),
                resolvedSaving: _.sumBy(projects, (p: PDI) => !_.find(openStatuses, (item) => item == p.status) ? p.estimatedSavings || 0 : 0),
                currentSpend: 0,
                pastSpend: 0,
                consumption: 0,
                sqft: 0,
                isIncomplete: false,
                isBuildingDataMissing: false,
                indicatorClass: null,
                showResolutionPercent: null
            };

            this.setResolutionPercent(summary);
            this.setChartData(summary);

            return summary;
        }
        public setResolutionPercent(summary: DataSummary) {
            const openCount = summary.declinedCount + summary.backlogCount + summary.needsAcceptanceCount + summary.inProgressCount;
            summary.resolutionPercent = openCount + summary.completedCount > 0
                ? Math.round(summary.completedCount * 100 / (openCount + summary.completedCount))
                : 0;
            summary.showResolutionPercent = summary.declinedCount + summary.backlogCount + summary.needsAcceptanceCount + summary.inProgressCount + summary.completedCount > 0;

            if (summary.resolutionPercent < 75) {
                summary.indicatorClass = 'bad';
            } else if (summary.resolutionPercent > 90) {
                summary.indicatorClass = 'good';
            } else {
                summary.indicatorClass = 'ok';
            }
        }
        public getStatusColor(statusName: string) {
            switch (statusName) {
                case 'Draft':
                    return '#FC7B33';
                case 'Needs Acceptance':
                    return '#A21621';
                case 'Backlog':
                    return '#533A7B';
                case 'In Progress':
                    return '#1E59AE';
                case 'Completed':
                    return '#488A49';
                default:
                    return '#3f3f3f';
            }
        };
        public setChartData(summary: DataSummary) {
            summary.chartData = {
                resolution: {
                    data: [
                        {
                            name: 'Declined',
                            value: summary.declinedCount,
                            color: this.getStatusColor('Declined')
                        },
                        {
                            name: 'Needs Acceptance',
                            value: summary.needsAcceptanceCount,
                            color: this.getStatusColor('Needs Acceptance')
                        },
                        {
                            name: 'Backlog',
                            value: summary.backlogCount,
                            color: this.getStatusColor('Backlog')
                        },
                        {
                            name: 'In Progress',
                            value: summary.inProgressCount,
                            color: this.getStatusColor('In Progress')
                        },
                        {
                            name: 'Completed',
                            value: summary.completedCount,
                            color: this.getStatusColor('Completed')
                        }
                    ],
                    options: {
                        width: 4 / 12 * 700,
                        height: 120,
                        categories: ['Declined', 'Needs Acceptance', 'Backlog', 'In Progress', 'Completed']
                    }
                }
            };
        }
        public getBuildingPersonnel(building: aq.common.models.Building) {
            const data = _.find(this.buildingPersonnels, { buildingId: building.id });
            if (data && data.personnel && data.personnel.length > 0) {
                _.each(data.personnel, (item) => {
                    if (!item.email) {
                        return;
                    }
                    const emailSplit = item.email.split('@');
                    if (emailSplit.length != 2) {
                        return;
                    }
                    item.emailUsername = emailSplit[0];
                    item.emailDomain = '@' + emailSplit[1];
                });
                const sortedPersonnel = [];
                _.each(this.personas, (persona, key) => {
                    const nextPerson = _.find(data.personnel, (item) => item.persona == key);
                    if (nextPerson) {
                        sortedPersonnel.push(nextPerson);
                    } else {
                        sortedPersonnel.push({
                            persona: key,
                            name: '-'
                        });
                    }
                });
                return sortedPersonnel;
            } else {
                return [];
            }
        }
        public getOrCreateBuildingGroupItem(building) {
            let buildingGroupItem: BuildingGroupReportItem = _.find(this.groupData,
                (group) => building.buildingGroup != null && group.groupId == building.buildingGroup
                    || building.buildingGroup == null && group.groupId == 0
            );
            if (!buildingGroupItem) {
                const buildingGroup = _.find(this.buildingGroups, (bg) => bg.id == building.buildingGroup);
                buildingGroupItem = {
                    groupId: buildingGroup ? buildingGroup.id : 0,
                    groupName: buildingGroup ? buildingGroup.name : 'Other',
                    groupBuildings: [],
                    summary: this.getEmptyDataSummary()
                };
                if (buildingGroup && buildingGroup.isIncomplete) {
                    buildingGroupItem.summary.isIncomplete = true;
                }
                this.groupData.push(buildingGroupItem);
            }
            return buildingGroupItem;
        }
        public getGlobalReportStartDate() {
            const queryDate = moment(this.date);
            let minDate = null;
            _.each(this.buildings, (building) => {
                const fiscalYearStart = this.getFiscalYearStart(building, queryDate);
                const tsFiscalYearStart = moment(fiscalYearStart);
                if (minDate == null || tsFiscalYearStart.isBefore(minDate)) {
                    minDate = tsFiscalYearStart;
                }
            });
            return minDate;
        }
        public getFiscalYearStart(building, currentDate: moment.Moment) {
            const currentMonth = currentDate.month() + 1;
            const startMonth = building.fiscalStartMonth ? building.fiscalStartMonth : 1;
            let startYear = currentDate.year();
            if (currentMonth <= startMonth) {
                startYear--;
            }
            return this.getBuildingTimezoneFirstDateOfMonth(building, startYear, startMonth);
        }
        public getBuildingTimezoneFirstDateOfMonth = (building, year, month) => {
            const yearStartString = `${year}-${month < 10 ? 0 : ''}${month}-01T00:00:00Z`;
            const offsetStart = moment(yearStartString).tz(building.timeZoneId).utcOffset();
            const start = moment(yearStartString).tz(building.timeZoneId).add(-offsetStart, 'minutes').format();
            return start;
        }
        private getEmptyDataSummary() {
            const item: DataSummary = {
                inProgressCount: 0,
                backlogCount: 0,
                completedCount: 0,
                declinedCount: 0,
                needsAcceptanceCount: 0,
                resolutionPercent: null,
                unresolvedSaving: 0,
                resolvedSaving: 0,
                currentSpend: 0,
                pastSpend: 0,
                consumption: 0,
                sqft: 0,
                isIncomplete: false,
                isBuildingDataMissing: false,
                indicatorClass: null,
                showResolutionPercent: null
            };
            return item;
        }
    }
    angular
        .module('aq.reports')
        .controller('ProjectCtrl', ProjectCtrl);
}
