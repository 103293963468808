'use strict';

angular
    .module('accounts',
    [
        'ui.bootstrap',
        'aq.ui.confirmation',
        'aq.ui.elemSelector',
        'aq.ui.image',
        'aq.accounts.calendars',
        'aq.accounts.companySettings',
        'aq.auth',
        'aq.services',
        'charting',
        'colorpicker.module',
        'ngclipboard',
        'aq.integrations'
    ])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.accounts', {
                url: '/accounts/:accountId',
                templateUrl: 'app/accounts/main/main.html',
                controller: 'AccountsMainCtrl',
                abstract: true,
                data: {
                    appName: 'Account Settings'
                },
                resolve: {
                    accountId ($stateParams) {
                        return $stateParams.accountId;
                    },
                    account (Restangular, accountId, waitForAuthToken) {
                        if (!accountId) {
                            return {};
                        }
                        return Restangular.one('accounts', accountId).get({ single: true });
                    },
                    invitations (RestangularV3, accountId, waitForAuthToken) {
                        return RestangularV3.all('users/invitations').getList({ 'accountId': accountId });
                    },
                    profiles (Restangular, accountId, waitForAuthToken) {
                        return Restangular.one('accounts', accountId).getList('profiles', {
                            inflate: 'user',
                            nocache: new Date().getTime()
                        });
                    },
                    authAccess(Auth, waitForAuthToken) {
                        return Auth.access;
                    }
                }
            })
            .state('aq.accounts.members', {
                url: '/members',
                templateUrl: 'app/accounts/members/members.html',
                controller: 'MembersCtrl',
                data: {
                    breadcrumb: 'Users'
                }
            })
            .state('aq.accounts.integrations', {
                url: '/integrations',
                templateUrl: 'app/accounts/integrations/integrations.html',
                controller: 'IntegrationsCtrl as integrationsCtrl',
                resolve: {
                    integrationApps(Restangular, waitForAuthToken) {
                        return Restangular.one('integrationApps').getList();
                    },
                    integrations(accountId, Restangular, waitForAuthToken) {
                        return Restangular.one('accounts', accountId)
                            .getList('integrations', { inflate: 'integrationApp', nocache: new Date().getTime() });
                    },
                    allServices(Restangular, waitForAuthToken) {
                        return Restangular.one('integrationApps').customGET('queryServices');
                    }
                },
                data: {
                    breadcrumb: 'Integrations'
                }
            })
            .state('aq.accounts.integrations.details', {
                url: '/:integrationId/details',
                templateUrl: 'app/accounts/integrations/details/details.html',
                controller: 'IntegrationDetailsCtrl as integrationDetailsCtrl',
                data: {
                    breadcrumb: 'Details'
                },
                resolve: {
                    "integration": function (accountId, Restangular, $stateParams, waitForAuthToken) {
                        return Restangular.one("accounts", accountId).one("integrations", $stateParams.integrationId).get({ single: true, inflate: 'integrationApp,workers' });
                    },
                    "integrationLogs": function (accountId, Restangular, $stateParams, waitForAuthToken) {
                        return Restangular.one("accounts", accountId).one("integrations", $stateParams.integrationId).customGET("logs", { page: 1, pageSize: 25 });
                    },
                    "integrationApp": function (integration) {
                        return integration.integrationApp;
                    }
                }
            })
            .state('aq.accounts.integrations.new', {
                url: '/:integrationApp/new',
                templateUrl: 'app/accounts/integrations/details/details.html',
                controller: "IntegrationDetailsCtrl as integrationDetailsCtrl",
                data: {
                    breadcrumb: 'Details'
                },
                resolve: {
                    integrationApp(accountId, Restangular, $stateParams, waitForAuthToken) {
                        return Restangular.one("integrationApps", $stateParams.integrationApp).get({ inflate: 'workers' });
                    },
                    integrationLogs() {
                        return [];
                    },
                    integration(accountId, integrationApp) {
                        let workers;
                        // workaround because current setup is depending on parameters to identify source
                        if (integrationApp.mandatoryParameters.length > 0) {
                            workers = _(integrationApp.mandatoryParameters).map('service').uniq().map(function (service) {
                                return {
                                    workerClassName: service,
                                    enabled: true
                                }
                            }).value();
                        } else {
                            workers = _(integrationApp.workers).map(function (worker) {
                                return {
                                    workerClassName: worker.workerClassName,
                                    enabled: true
                                }
                            }).value();
                        }
                        return {
                            account: accountId,
                            integrationApp: integrationApp.id,
                            workers
                        };
                    }
                }
            })
            .state('aq.accounts.roles', {
                url: '/roles',
                templateUrl: 'app/accounts/roles/roles.html',
                controller: 'RolesCtrl as rolesCtrl',
                data: {
                    breadcrumb: 'Roles'
                },
                resolve: {
                    roles(Restangular, accountId, waitForAuthToken) {
                        return Restangular.one("accounts", accountId).all('UserRoles').getList({ inflate: 'permissions' });
                    },
                    apps(Restangular, accountId, waitForAuthToken) {
                        return Restangular.one("accounts", accountId).get({ single: true, inflate: 'allApps' }).then(function (account) {
                            return account.allApps;
                        });
                    },
                    roleType() {
                        return "Roles";
                    },
                    accesses(Restangular, waitForAuthToken) {
                        return Restangular.one("options", 'accesses').getList();
                    }
                }
            })
            .state('aq.accounts.competitions', {
                url: '/competitions',
                templateUrl: 'app/accounts/competitions/template/competitions.html',
                controller: 'CompetitionsChooserCtrl',
                data: {
                    breadcrumb: 'Competitions'
                }
            })
            .state('aq.accounts.competitions.competition', {
                url: '/:competitionId/competition',
                templateUrl: 'app/accounts/competitions/template/competition.html',
                controller: 'CompetitionCtrl',
                data: {
                    hide: true
                }
            })
            .state('aq.accounts.competitions.new', {
                url: '/new',
                templateUrl: 'app/accounts/competitions/template/competition.html',
                controller: 'CompetitionCtrl',
                data: {
                    hide: true
                }
            })
            .state('aq.accounts.create', {
                url: '/create',
                templateUrl: 'app/accounts/createAccount/main.html',
                controller: 'CreateAccountCtrl',
                resolve: {
                    licenses: ['Restangular', function (Restangular, waitForAuthToken) {
                        return Restangular.all('licenses').getList();
                    }]
                }
            });
    });
