angular.module('aq.filters').filter('enumLabel', function () {
    return function(id, enums, valueField, labelField) {
        if (!id || !enums) return null;

        var item = _.findInChildren(enums, function (o) { return o[valueField || 'value'] == id; });
        if (item == null) {
            return null;
        } else {
            return item[labelField || 'label'];
        }
    }
});
