namespace aq.dashboard.widgets {
    export class DashboardInsightCtrl {
        static OUTER_COLOR = '#979797';

        private isLoadingData: boolean;
        private isBuildingInSetup: boolean;
        private insightServices: { [insightName: string]: aq.energyInsights.InsightService; } = {};

        /* @ngInject */
        constructor(
            private $scope,
            private buildings: aq.common.models.Building[],
            private DashboardOptionsService: aq.dashboard.DashboardOptionsService,
            private config,
            private account: aq.common.models.Account,
            private CostService: aq.energyInsights.CostService,
            private PeakDemandService: aq.energyInsights.PeakDemandService,
            private BaseloadService: aq.energyInsights.BaseloadService,
            private BaseloadV2Service: aq.energyInsights.BaseloadV2Service,
            private ConsumptionService: aq.energyInsights.ConsumptionService,
            private EnergyStarInsightService: aq.energyInsights.EnergyStarInsightService,
            private EnergyStarApiService: aq.energyStarApi.EnergyStarApiService,
            private intervalService,
            private $state,
            private $q,
            private ModelUtilService: aq.services.ModelUtilService,
            private $translate,
            private WidgetHelperService,
            private measureUnitMap: { [measure: string]: string }
        ) {
            config.options = {
                availablePeriods: this.getAvailablePeriods(),
                buildings: ModelUtilService.pareProperties(buildings, ['account']),
                insights: aq.energyInsights.models.insights,
                displayOptions: ['Graph', 'Target', 'Table'],
                measures: ['ELECTRICITY', 'WATER', 'GAS', 'STEAM', 'HEAT'],
                accountId: this.account.id,
                getSelectedBuilding: (buildingId) => {
                    return _.find(this.buildings, { id: buildingId });
                }
            };

            this.insightServices['CostService'] = this.CostService;
            this.insightServices['PeakDemandService'] = this.PeakDemandService;
            this.insightServices['BaseloadService'] = this.BaseloadService;
            this.insightServices['BaseloadV2Service'] = this.BaseloadV2Service;
            this.insightServices['ConsumptionService'] = this.ConsumptionService;
            this.insightServices['EnergyStarInsightService'] = this.EnergyStarInsightService;

            this.$scope.config = config;
            this.$scope.config.insightConfig = _.find(aq.energyInsights.models.insights, { id: this.$scope.config.insight }) || this.config.options.insights[0];
            // for Utility Spending insight, redirect to new Budgets Module if it's enabled
            if (this.$scope.config.insight == 1) {
                this.$scope.config.insightConfig.state = 'aq.utilityBudgets.month';
                if (this.$scope.config.displayAs == 'This Year') {
                    this.$scope.config.displayAs = 'This Month';
                }
            }
            const insight = _.find(aq.energyInsights.models.insights, { id: this.$scope.config.insight });
            const building = _.find(buildings, { id: this.$scope.config.buildingId });

            let energyStarScorePromise = $q.when({
                ...building.energyStarScore,
                score: null
            });

            if (insight.service === 'EnergyStarInsightService') {
                energyStarScorePromise = EnergyStarApiService.getEnergyStarScoreForBuilding(building.account.toString(), building.id)
                    .then(resp => {
                        return resp && Object.keys(resp).length > 0 ? resp : null;
                    })
                    .catch(() => {
                        return null;
                    });
            }
            energyStarScorePromise.then(data => {
                building.energyStarScore = data;

                const period = this.getPeriodSearch(_.find(config.options.availablePeriods, { label: this.$scope.config.period }));
                let name: string;
                this.isBuildingInSetup = this.WidgetHelperService.isBuildingInSetup(building);
                if (insight && building && period) {
                    this.$scope.insightClass = insight.class;
                    if (this.$scope.config.displayAs === 'Target') {
                        this.$scope.simpleTargetModel = this[insight.service].getSimpleTargetModel(building, this.$scope.config.measure, period, name);
                        this.$scope.simpleTargetModel.widgetIcon = insight.widgetIcon;
                        if (!this.isBuildingInSetup) {
                            if (config.isCustomTitle) {
                                name = config.titleText;
                            }
                            this.isLoadingData = true;
                            this.insightServices[insight.service].getTargetModel(building, this.$scope.config.measure, period, this.account, name)
                                .then((targetModel) => {
                                    targetModel.measureUnit = this.measureUnitMap[this.$scope.config.measure];
                                    this.$scope.targetModel = targetModel;
                                    this.$scope.targetModel.widgetIcon = insight.widgetIcon;
                                    this.$scope.gaugeConfig = this.getGaugeConfig(this.$scope.targetModel);
                                })
                                .finally(() => {
                                    this.isLoadingData = false;
                                });
                        }
                    } else if (this.$scope.config.displayAs === 'Graph') {
                        this.insightServices[insight.service].getGraphModel(building, this.$scope.config.measure, period, account).then((graphModel) => {
                            this.$scope.chartConfig = graphModel.graph;
                        });
                    }
                }
            });

            const params = { buildingId: building.id, accountId: account.id };
            if (this.$scope.config.insight == 1) {
                angular.extend(params, { measure: this.$scope.config.measure.toLowerCase() });
            }

            this.$scope.config.punchout = {
                url: this.$state.href(insight.state, params, { absolute: true }),
                title: this.$translate.instant('dashboard.adf_templates.Details'),
                isTargetSameWindow: this.$scope.config.isTargetSameWindow
            };

            this.$scope.config.actions = {
                onBuildingChange: (cfg, buildingId) => {
                    cfg.buildingId = buildingId;
                }
            };
        }

        // -----------------------

        private getPeriodSearch(period): aq.energyInsights.PeriodSearch {
            if (!period) {
                return null;
            }
            const building = _.find(this.buildings, { id: this.config.buildingId }) || this.buildings[0];
            const periodSearch = {
                start: period.value.start(building.timeZoneId),
                end: period.value.end(building.timeZoneId),
                interval: _.find(period.value.intervals, { value: period.label.indexOf('Year') ? '1mon' : '1d' }),
                label: period.label
            };
            return periodSearch;
        }

        private getAvailablePeriods() {
            const availablePeriods = [];
            _.keys(aq.common.TimeConfiguration).forEach((type) => {
                _.keys(aq.common.TimeConfiguration[type]).forEach((period) => {
                    const label = `${type} ${period}`;
                    const periodObj = {
                        label,
                        value: aq.common.TimeConfiguration[type][period]
                    };
                    availablePeriods.push(periodObj);
                });
            });
            return availablePeriods;
        }

        private getGaugeConfig(targetModel: aq.energyInsights.TargetModel) {
            let iconXOffset = 80;
            let iconYOffset = 70;
            const timeElapsedLabelDistance = -10;
            let iconSize = '15em';
            if (targetModel.widgetIcon === 'aq-icons-baseload') {
                iconSize = '9em';
                iconXOffset = 90;
                iconYOffset = 120;
            } else if (targetModel.widgetIcon === 'aq-icons-budget') {
                iconXOffset = 110;
                iconYOffset = 70;
            }
            const chartConfig = {
                chart: {
                    type: 'solidgauge',
                    borderWidth: 0,
                    plotBorderWidth: 0,
                    events: {
                        load: function () {
                            const chart = this,
                                series = chart.series[0],
                                shape = series.data[0].shapeArgs,
                                x = shape.x,
                                y = shape.y;
                            chart.renderer.label(
                                `<span style="font-size:${iconSize}"><i style="color:${targetModel.color} " class="${targetModel.widgetIcon}"></i></span>`
                                , iconXOffset, iconYOffset, null, null, null, true)
                                .translate(x, y)
                                .add(series.group);
                        }
                    }
                },
                exporting: {
                    enabled: false
                },
                title: {
                    text: null
                },
                tooltip: {
                    enabled: false
                },
                pane: {
                    startAngle: 0,
                    endAngle: 360,
                    background: [{ // Outer track
                        outerRadius: '112%',
                        innerRadius: '108%',
                        backgroundColor: '#fff',
                        borderWidth: 0
                    }, { // Inner Track
                        outerRadius: '107%',
                        innerRadius: '83%',
                        backgroundColor: Highcharts.Color(targetModel.color)['setOpacity'](0.2).get(),
                        borderWidth: 0
                    }]
                },
                yAxis: {
                    labels: {
                        distance: timeElapsedLabelDistance,
                        enabled: true,
                        align: 'left',
                        format: targetModel.timeElapsedLabel,
                        style: {
                            fontSize: 10,
                            color: '#000000'
                        }
                    },
                    min: 0,
                    max: 100,
                    gridLineColor: 'transparent',
                    lineColor: 'transparent',
                    minorTickLength: 0,
                    tickInterval: 67,
                    tickPositions: [targetModel.timeElapsedPercentage],
                    tickColor: '#000000',
                    tickPosition: 'outside',
                    tickLength: 20,
                    tickWidth: 2
                },
                plotOptions: {
                    solidgauge: {
                        dataLabels: {
                            enabled: false
                        },
                        linecap: 'round',
                        stickyTracking: false
                    }
                },
                series: [{
                    name: 'Time Elapsed',
                    borderColor: Highcharts.Color(DashboardInsightCtrl.OUTER_COLOR)['setOpacity'](0.5).get(),
                    data: [{
                        color: Highcharts.Color(DashboardInsightCtrl.OUTER_COLOR)['setOpacity'](0.5).get(),
                        radius: '112%',
                        innerRadius: '108%',
                        y: targetModel.timeElapsedPercentage
                    }]
                }, {
                    name: 'Percent Complete',
                    borderColor: targetModel.color,
                    data: [{
                        color: targetModel.color,
                        radius: '107%',
                        innerRadius: '83%',
                        y: targetModel.percentage
                    }]
                }]
            };
            return chartConfig;
        }
    }
    angular.module('aq.dashboard.widgets').controller('DashboardInsightCtrl', DashboardInsightCtrl);
}
