var aq;
(function (aq) {
    var propertySettings;
    (function (propertySettings) {
        var AlertActionsComponentCtrl = /** @class */ (function (_super) {
            __extends(AlertActionsComponentCtrl, _super);
            /* @ngInject */
            function AlertActionsComponentCtrl(Auth) {
                var _this = _super.call(this) || this;
                _this.Auth = Auth;
                return _this;
            }
            AlertActionsComponentCtrl.prototype.$onInit = function () {
                this.validatePhoneNumbers();
            };
            AlertActionsComponentCtrl.prototype.queryUsers = function (criteria) {
                var _this = this;
                return _.filter(this.users, function (user) {
                    return user.fullName.toLowerCase().includes(criteria.toLowerCase()) && !_.includes(_this.alert.selectedUsers, user);
                });
            };
            AlertActionsComponentCtrl.prototype.validatePhoneNumbers = function () {
                if (this.alert.sendTextMessage) {
                    this.usersMissingPhoneNumber = this.alert.selectedUsers.filter(function (user) { return user.phoneNumber === null || user.phoneNumber === ""; });
                    if (this.usersMissingPhoneNumber.length > 0) {
                        var userNames = this.usersMissingPhoneNumber.map(function (user) {
                            return user.fullName;
                        });
                        this.usersMissingPhoneNumberDisplayString = this.toSentence(userNames);
                    }
                }
            };
            AlertActionsComponentCtrl.prototype.toSentence = function (arr) {
                return arr.slice(0, -2).join(', ')
                    + (arr.slice(0, -2).length ? ', ' : '')
                    + arr.slice(-2).join(' and ');
            };
            return AlertActionsComponentCtrl;
        }(propertySettings.AbstractAlertComponent));
        propertySettings.AlertActionsComponentCtrl = AlertActionsComponentCtrl;
        angular.module('properties')
            .component('alertActions', {
            templateUrl: 'app/properties/alerts/directives/alertActions.html',
            bindings: {
                users: '<',
                alert: '<',
                isReadonly: '<?'
            },
            controller: AlertActionsComponentCtrl
        });
    })(propertySettings = aq.propertySettings || (aq.propertySettings = {}));
})(aq || (aq = {}));
