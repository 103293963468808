'use strict';

angular
    .module('accounts')
    .filter('readingsStatusClass', function () {
        return function (missingReadings, startDate) {
            var weekStartDay = moment().startOf('week');
            var isTodayStartOfWeek = moment().isSame(weekStartDay, 'day');
            var readingsCountToNow = moment().diff(startDate, 'days');
            var allMissing = readingsCountToNow == missingReadings.length;
            if(_.isEmpty(missingReadings)) {
                return 'aq-icons-meter-success';
            } else if (!_.isEmpty(missingReadings) && !allMissing && !isTodayStartOfWeek) {
                return 'aq-icons-actions-required-meter';
            } else if (!_.isEmpty(missingReadings) && allMissing) {
                return 'aq-icons-delete';
            }
            return 'aq-icons-meter-success';
        }
    });