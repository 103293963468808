namespace aq.models.prescription {

    export interface CasesRequestParams {
        first_published_gte?: string;
        first_published_lte?: string;
        order?: string;
        status?: string;
        page_size?: number;
    }

    export class CaseUser {
        public id: string;
        public username: string;
        public first_name: string;
        public last_name: string;
    }

    export class CaseFacility {
        public facility_code: string;
        public property_name: string;
        public building_type: string;
        public portfolio: string;
        public management_company: string;
        public address: string;
        public city: string;
        public state: string;
        public zip_code: string;
        public region: string;
        public client_name: string;
        public utility_provider_electricity: string;
        public utility_provider_natural_gas: string;
        public utility_provider_steam: string;
        public annual_utility_cost: number;
    }

    export class CaseAttachment {
        public id: number;
        public filename: string;
        public storage_path: string;
        public size: number;
        public created: string;
        public created_by: CaseUser;
    }

    export class CaseComment {
        public id: number;
        public content: string;
        public created: string;
        public created_by: CaseUser;
        public updated: string;
        public internal: boolean;
    }

    export class CaseSymptom {
        public id: number;
        public url: string;
        public display_text: string;
        public created: string;
        public created_by: CaseUser;
        public updated: string;
        public updated_by: CaseUser;
    }

    export class Case {
        public id: number;
        public facility: CaseFacility;
        public building_name: string;
        public status: string; // FIXME: ENUM? -- "Published"
        public status_note: string;
        public priority: number;
        public reference_number: string; // FIXME: called "number" but returned empty string
        public original_reference_number: string; // FIXME: called "number" but returned empty string
        public classification: string; // FIXME: ENUM?
        public date_identified: any; // FIXME: not sure what type - came in as null
        public date_completed: any; // FIXME: not sure what type - came in as null
        public device_type: string;
        public device_id: string;
        public equipment_description: string;
        public sub_system_type: string;
        public system_type: string; // FIXME: ENUM? -- "Airside"
        public tag_name: string;
        public area_served: string;
        public original_annual_estimated_savings_opportunity_monetary: any; // FIXME: not sure what type - came in as null
        public original_annual_estimated_savings_opportunity_use: any; // FIXME: not sure what type - came in as null
        public timeseries_start: any; // FIXME: not sure what type - came in as null
        public timeseries_end: any; // FIXME: not sure what type - came in as null
        public diagnosis_category: string;
        public diagnosis_title: string;
        public diagnosis: string;
        public diagnosis_description: string;
        public diagnosis_custom_notes: string;
        public potential_causes: string;
        public method_of_identification: string;
        public identified_by: string;
        public energy_units: string;
        public prescription_title: string;
        public recommended_action: string;
        public recommended_action_legacy: string;
        public prescription_category: string;
        public responsible_party: string;
        public responsible_person: string;
        public utility_impacted: any; // FIXME: not sure what type - came in as null
        public savings_impact: number; // FIXME: is this an ENUM? came in as a 1 (one)
        public estimated_energy_waste: any; // FIXME: not sure what type - came in as null
        public estimated_energy_cost: any; // FIXME: not sure what type - came in as null
        public estimated_energy_savings: any; // FIXME: not sure what type - came in as null
        public estimated_annual_avoided_energy_use: any; // FIXME: not sure what type - came in as null
        public estimated_annual_avoided_energy_cost: any; // FIXME: not sure what type - came in as null
        public estimated_cost_to_resolve: any; // FIXME: not sure what type - came in as null
        public cost_to_resolve_calc_method: string;
        public impact_areas: string; // FIXME: ENUM?
        public calculated_utility_savings_override: number;
        public calculated_utility_savings_dollar: number;
        public calculated_utility_savings_percentage: number;
        public p2_prescription_title: string;
        public p2_prescription_type: string; // FIXME: ENUM?
        public p2_details: any; // FIXME: not sure what type - came in as null
        public p2_causes: any; // FIXME: not sure what type - came in as null
        public case_attachments: CaseAttachment[];
        public case_comments: CaseComment[];
        public case_symptoms: CaseSymptom[];
        public created: string; // FIXME: it was an ISO Date string
        public created_by: CaseUser;
        public updated: string; // FIXME: it was an ISO Date string
        public updated_by: CaseUser;
        public first_published: string; // FIXME: it was an ISO Date string
    }
}
