namespace aq.accounts {
    export class RolesCtrl {
        constructor(private $scope:RolesCtrlScope, private apps: any, private roles, private roleType,
                    private Errors, private Messages, private $aq, private accesses) {
            $scope.apps = apps;
            $scope.roleType = roleType;
            $scope.appIDsToApp = {};
            $scope.apps.forEach((app) => {
                $scope.appIDsToApp[app.id] = app;
            });
            $scope.role = _.first(roles);
            $scope.roles = roles;
            $scope.rolesToApps = this.updateRolesToApps(roles, apps);
            $scope.previousRolesToApps = angular.copy($scope.rolesToApps);
            $scope.humanizeAccess = {};
            accesses.forEach((access) => {
                $scope.humanizeAccess[access.value] = access.label;
            });
        }

        updateRolesToApps = (roles, apps) => {
            let rolesToApps: {[key: string]: {}} = {};
            roles.forEach((accountRole) => {
                let appsToPermissions: {[key: string]: {}} = {};
                apps.forEach((app) => {
                    appsToPermissions[app["id"]] = this.findOrConstructPermission(app, accountRole);
                });
                rolesToApps[accountRole["id"]] = appsToPermissions;
            });
            return rolesToApps;
        };

        findOrConstructPermission = (app, role) => {
            let permission:any = _.find(role.permissions, {app: app.id});
            if (!permission) {
                permission = {
                    access: 'NONE',
                    app: app.id
                };
            }
            return permission;
        };

        addRole = () => {
            let role = {
                name: "New role",
                permissions: []
            };

            role.permissions = _.map(this.$scope.apps, (app:any) => {
                return {
                    app: app.id,
                    access: "NONE"
                }
            });

            this.roles.post(role, {inflate: 'permissions'}).then((posted) => {
                this.$scope.role = _.extend(posted, posted.model);
                this.roles.push(this.$scope.role);
                this.$scope.rolesToApps = this.updateRolesToApps(this.roles, this.apps);
                this.$scope.previousRolesToApps = angular.copy(this.$scope.rolesToApps);
            }, this.Errors.forCRUD('CREATE'));

        };

        deleteRole = (role) : void => {
            let onDelete = () => {
                this.$scope.role = _.first(this.roles);
            };
            this.$aq.delete(this.roles, role, onDelete);
        };

        saveRole = () : void => {
            this.$scope.role.put({inflate: 'permissions'}).then(() => {
                this.Messages.success("Role has been updated.");
            }).catch(this.Errors.forPromise());
        };

        savePermission = (permission) : void => {
            let reload = false;
            // replace existing role with updated role in case of created permission
            if (!_.find(this.$scope.role.permissions, {app: permission.app})) {
                this.$scope.role.permissions.push(permission);
                reload = true;
            }
            // update only if md-select changes
            let update = false;
            if (this.$scope.previousRolesToApps[this.$scope.role.id][permission.app].access !== permission.access) {
                update = true;
            }
            if (update) {
                this.$scope.role.put({inflate: 'permissions'}).then((placedRole) => {
                    this.Messages.success("Role has been updated.");
                    if (reload) {
                        this.$scope.role = _.extend(placedRole, placedRole.model);
                        let roles = _.reject(this.roles, (role:any) => {
                            return role.id === placedRole.id
                        });
                        roles.push(this.$scope.role);
                        this.roles = roles;
                        this.$scope.rolesToApps = this.updateRolesToApps(this.roles, this.apps);
                    }
                    this.$scope.previousRolesToApps = angular.copy(this.$scope.rolesToApps);
                }).catch(this.Errors.forPromise());
            }
        }
    }
    export interface RolesCtrlScope extends ng.IScope {
        apps: any[];
        roles: any[];
        role: any;
        roleType: string;
        rolesToApps: {[key: string]: {}};
        humanizeAccess: {[key: string]: {}};
        previousRolesToApps: {[key: string]: {}};
        appIDsToApp: {[key: number]: string};
    }

    angular.module('accounts').controller('RolesCtrl', RolesCtrl);
}
