namespace aq.service {

    export class CreateBuildingService {
        /* @ngInject */
        constructor(
            public $state: ng.ui.IStateService,
            public $mdDialog: ng.material.IDialogService,
            public Restangular,
            public Messages,
            public Errors
        ) { }

        public buildingTemplate(account) {
            const building = {
                account: account.id,
                id: null,
                name: '',
                address: '',
                city: '',
                state: '',
                zipCode: '',
                country: '',
                size: 0,
                floors: [],
                rate: null,
                meters: [],
                latitude: null,
                longitude: null,
                electricProvider: null,
                gasProvider: null,
                buildingPurpose: null,
                buildingGroup: null,
                occupantsCount: null,
                floorsLength_: 1
            };

            if (account.defaultBuildingGroup) {
                building.buildingGroup = account.defaultBuildingGroup.id;
            }
            return building;
        }

        public newBuildingDialog(account): void {
            const newBuilding = this.buildingTemplate(account);

            this.$mdDialog.show({
                templateUrl: 'app/common/services/CreateBuildingService/CreateBuilding.html',
                clickOutsideToClose: true,
                parent: angular.element(document.body),
                controller: 'CreateBuildingServiceDialogCtrl as vm',
                locals: {
                    newBuilding
                }
            })
                .then((result) => {
                    if (!result.$parent.newBuilding.name) {
                        result.$parent.newBuilding.name = result.$parent.newBuilding.address;
                    }
                    this.addBuilding(account, result.$parent.newBuilding);
                });
        }

        public addBuilding(account, building): void {
            if (building.zipCode) {
                building.zipCode = building.zipCode.trim();
            }
            account.all('buildings')
                .post(building, { inflate: 'weatherNormalization,buildingOccupancies' })
                .then((result) => {
                    _.extend(building,
                        this.Restangular.restangularizeElement(account, result.model, 'buildings'));
                    this.goDetail(account, building);
                    this.Messages.success('Successfully created building');
                }, this.Errors.forPromise());
        }

        public goDetail(account, building): void {
            this.$state.go('aq.properties.buildings.form.configuration', {
                accountId: account.id,
                buildingId: building.id ? building.id : undefined
            }, { reload: true });
        }
    }

    angular
        .module('aq.services')
        .service('CreateBuildingService', CreateBuildingService);
}
