declare const filepicker: any;
angular.module('aq.services.filepicker', ['aq.messages']).factory('FilePicker',
function(Messages, $rootScope, $q) {

    var pickAndStoreFn = function (options, storageOptions) {
        var deferred = $q.defer();
        filepicker.pickAndStore(options, storageOptions, function(inkBlobs) {
            deferred.resolve(inkBlobs);
        }, function (result) {
            deferred.reject(result);
        });
        return deferred.promise;
    };

    var storeFn = function (url, storageOptions) {
        var deferred = $q.defer();
        filepicker.storeUrl(url, storageOptions, function(inkBlobs) {
            $rootScope.$apply(function () {
                deferred.resolve(inkBlobs);
            });
        }, function (result) {
            $rootScope.$apply(function () {
                deferred.reject(result);
            });
        });

        return deferred.promise;
    };

    var convertFn = function(inkBlob, options, storageOptions) {
        var deferred = $q.defer();
        filepicker.convert(inkBlob, options, storageOptions,
                function(convertedInkBlob) {
                    $rootScope.$apply(function () {
                        deferred.resolve(convertedInkBlob);
                    });
                }, function (result) {
                    $rootScope.$apply(function () {
                        deferred.reject(result);
                    });
                }
            );

        return deferred.promise;
    };

    var getS3UrlFn = function (key) {
        return 'https://com-aquicore-web.s3.amazonaws.com/' + key;
    };

    var pickAndStoreImageFn = function (imgWidth, imgHeight, services) {
        var deferred = $q.defer();
        deferred.convertingImage = false;

        var pickerOptions = {mimetype: 'image/*', openTo: 'IMAGE_SEARCH'};

        if (services) {
            _.extend(pickerOptions, { services: services });
        };

        pickAndStoreFn(pickerOptions, {location: 'S3', access: 'public'}).then(function (inkBlobs) {
            deferred.convertingImage = true;
            convertFn(inkBlobs[0], {rotate: "exif", format: "png"}, {location: 'S3', access: 'public'}).then(function (convertedInkBlob) {
                convertFn(inkBlobs[0], {rotate: "exif", format: "png", width: imgWidth, height: imgHeight}, {location: 'S3', access: 'public'}).then(function (downsampledInkBlob) {
                    deferred.convertingImage = false;
                    deferred.resolve({
                        imageUrl: getS3UrlFn(convertedInkBlob.key),
                        thumbnailImageUrl: getS3UrlFn(downsampledInkBlob.key)
                    });
                }, function (result) {
                    deferred.reject(result);
                });
            }, function (result) {
                deferred.reject(result);
            });
        }, function (result) {
            deferred.reject(result);
        });

        return deferred.promise;
    };

    var storeImageFn = function (url, imgWidth, imgHeight) {
        var deferred = $q.defer();
        deferred.convertingImage = false;
        storeFn(url, {location: 'S3', access: 'public'}).then(function (inkBlobs) {
            deferred.convertingImage = true;
            convertFn(inkBlobs, {rotate: "exif", format: "png"}, {location: 'S3', access: 'public'}).then(function (convertedInkBlob) {
                convertFn(inkBlobs, {rotate: "exif", format: "png", width: imgWidth, height: imgHeight}, {location: 'S3', access: 'public'}).then(function (downsampledInkBlob) {
                    deferred.convertingImage = false;
                    deferred.resolve({
                        imageUrl: getS3UrlFn(convertedInkBlob.key),
                        thumbnailImageUrl: getS3UrlFn(downsampledInkBlob.key)
                    });
                }, function(result) {
                    deferred.reject(result);
                });
            }, function (result) {
                deferred.reject(result);
            });
        }, function (result) {
            deferred.reject(result);
        });

        return deferred.promise;
    };

    var service = {
           pickAndStore: pickAndStoreFn,
           storeImage: storeImageFn,
           convert: convertFn,
           pickAndStoreImage: pickAndStoreImageFn,
           getS3Url: getS3UrlFn
   };

   return service;
});
