namespace aq.accounts.companySettings.metric {

    export class MetricCRUDCtrl {

        private crudObjectName: string;
        private getParams: any;
        private restangularBaseAllRoute: restangular.IElement;
        private restangularBaseOneRoute: (id: string) => restangular.IElement;
        private restangularObjectName: string;
        private restangularParent: any;

        /* @ngInject */
        constructor(
            private $scope: MetricCRUDCtrlScope,
            private $mdDialog: any,
            private $state,
            private Messages: any,
            private ConfirmationService,
            private Restangular: restangular.IService,
            private Errors: any,
            private account: any,
            private object: any,
            private realUnits: any[]
        ) {
            this.$scope.object = this.Restangular.copy(object);
            this.$scope.options = {
                realUnits
            };
            this.crudObjectName = 'Metric';
            this.restangularParent = this.account;
            this.restangularObjectName = 'AccountMetrics';
            this.restangularBaseAllRoute = this.account.all('AccountMetrics');
            this.restangularBaseOneRoute = (id) => {
                return this.account.one('AccountMetrics', id);
            };
            this.getParams = {};
        }

        hide() {
            this.$mdDialog.hide();
        }

        cancel() {
            this.$mdDialog.cancel();
        }

        private getRestangularizedObject(id): ng.IPromise<restangular.IElement> {
            return this.restangularBaseOneRoute(id)
                .get(this.getParams)
                .then((object) => {
                    return this.Restangular.restangularizeElement(this.restangularParent, object[0], this.restangularObjectName);
                });
        }

        private create() {
            this.$scope.object.account = this.account.id;
            this.restangularBaseAllRoute.post(this.$scope.object)
                .then((newObject) => {
                    this.Messages.success(`Created ${this.crudObjectName}`);
                    this.$mdDialog.hide(this.getRestangularizedObject(newObject.id));
                })
                .catch((response) => {
                    this.$mdDialog.cancel();
                    this.Errors.forCRUD('UPDATE')(response);
                });
        }

        private update() {
            this.$scope.object.put()
                .then((updatedObject) => {
                    this.Messages.info(`Updated ${this.crudObjectName}`);
                    this.$mdDialog.hide(this.getRestangularizedObject(updatedObject.id));
                });
        }

        save() {
            if (this.isNewRecord()) {
                this.create();
            } else {
                this.update();
            }
        }

        delete() {
            this.restangularBaseAllRoute.customDELETE(this.$scope.object.id)
                .then(() => {
                    this.Messages.info(`Deleted ${this.crudObjectName}`);
                    this.$mdDialog.hide();
                })
                .catch(() => {
                    this.Messages.error(`Couldn\'t Delete ${this.crudObjectName}`);
                    this.$mdDialog.cancel();
                });
        }

        isNewRecord(): boolean {
            return _.isUndefined(this.$scope.object) || _.isUndefined(this.$scope.object.id);
        }

    }

    interface MetricCRUDCtrlScope extends ng.IScope {
        object: any;
        options: any;
    }
}

angular.module('accounts').controller('MetricCRUDCtrl', aq.accounts.companySettings.metric.MetricCRUDCtrl);