namespace aq.services {

    export enum DocumentTemplateType {
        BUDGET_PLANNER = 'BUDGET_PLANNER'
    }

    export class DocumentTemplateService {

        /* @ngInject */
        constructor(
            private $q: ng.IQService,
            private RedirectService: RedirectService
        ) { }

        private getS3Path(templateType: DocumentTemplateType): string {
            return `template-${templateType.toLowerCase().replace('_', '-')}/`;
        };

        private getBucket() {
            if (this.RedirectService.isProd()) {
                return 'aq-document-templates-prod';
            }
            return 'aq-document-templates-stage';
        }

        private getDocumentNameForTemplateType(templateType: DocumentTemplateType) {
            switch (templateType) {
                case DocumentTemplateType.BUDGET_PLANNER:
                    return 'BudgetPlanner';
                default:
                    return 'Template';
            }
        }

        private pickDocument(
            path: string,
            documentName: string
        ): ng.IPromise<aq.models.activity.MediaResponse[]> {
            const defer = this.$q.defer();
            const options = {
                disableStorageKey: true,
                maxFiles: 1,
                accept: [
                    '.xlsx'
                ],
                onFileSelected: (file) => {
                    // @ts-ignore
                    return { ...file, name: `${documentName}.xlsx` };
                },
                storeTo: {
                    container: this.getBucket(),
                    path,
                    region: 'us-east-1',
                    access: 'public'
                },
                fromSources: [
                    'local_file_system'
                ],
                uploadInBackground: false,
                onCancel: () => {
                    defer.reject('Cancelled');
                },
                onUploadDone: (res: MediaPickerResult) => {
                    if (res.filesUploaded && res.filesUploaded.length > 0) {
                        const files = res.filesUploaded.map((file) => {
                            return {
                                type: aq.models.activity.MediaType.EXCEL,
                                uri: file.url
                            }
                        });
                        defer.resolve(files);
                    }
                }
            };

            // _filestackClient is initialized in app/main.html
            // @ts-ignore
            const picker = _filestackClient.picker(options);
            picker.open();
            return defer.promise as ng.IPromise<aq.models.activity.MediaResponse[]>;
        }

        public getTemplateDownloadUrl(type: DocumentTemplateType) {
            const bucket = this.getBucket();
            const documentName = this.getDocumentNameForTemplateType(type);
            return `https://${bucket}.s3.amazonaws.com/${this.getS3Path(type)}${documentName}.xlsx`;
        }

        public pickDocumentTemplate(templateType: DocumentTemplateType) {
            const documentName = this.getDocumentNameForTemplateType(templateType);
            const path = this.getS3Path(templateType);
            this.pickDocument(path, documentName);
        }
    }

    angular
        .module('aq.services')
        .service('DocumentTemplateService', DocumentTemplateService);
}
