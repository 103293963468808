namespace aq.services {
    export class ProjectTemplateService {
        /* @ngInject */
        constructor(
            private DataStore: aq.common.DataStore,
            private RestangularV3: restangular.IService
        ) { }

        public createProjectTemplate(account) {
            return this.RestangularV3.one('project-template', account.id).customPOST({}, 'account').then((response) => {
                return response;
            }).catch((err) => {
                return err;
            });
        }

        public updateProjectTemplate(projectTemplate) {
            return this.RestangularV3.one('project-template', projectTemplate.id).customPUT(projectTemplate).then((response) => {
                return response;
            }).catch((err) => {
                return err;
            });
        }

        public deleteProjectTemplate(projectTemplate) {
            return this.RestangularV3.one('project-template', projectTemplate.id).customDELETE('').then((response) => {
                return response;
            }).catch((err) => {
                return err;
            });
        }

    }

    angular
        .module('aq.services')
        .service('ProjectTemplateService', ProjectTemplateService);
}
