var aq;
(function (aq) {
    var energyInsights;
    (function (energyInsights) {
        var WeatherNormalizationCtrl = /** @class */ (function () {
            /* @ngInject */
            function WeatherNormalizationCtrl($scope, metric, $filter, UserService, $q, currentBuilding, EnergyInsightsDataService, account, OptionsService, energyNotes, $mdDialog, $window, $state, loading, WeatherNormalizationService, $rootScope, Auth) {
                var _this = this;
                this.$scope = $scope;
                this.metric = metric;
                this.$filter = $filter;
                this.UserService = UserService;
                this.$q = $q;
                this.currentBuilding = currentBuilding;
                this.EnergyInsightsDataService = EnergyInsightsDataService;
                this.account = account;
                this.OptionsService = OptionsService;
                this.energyNotes = energyNotes;
                this.$mdDialog = $mdDialog;
                this.$window = $window;
                this.$state = $state;
                this.loading = loading;
                this.WeatherNormalizationService = WeatherNormalizationService;
                this.$rootScope = $rootScope;
                this.Auth = Auth;
                this.goToPropertySettings = function () {
                    _this.$state.go('aq.properties.buildings.form', { accountId: _this.account.id, buildingId: _this.currentBuilding.id });
                };
                this.$scope.toastMessage = 'Improve your analysis by enabling weather normalization for this building!';
                this.$scope.currentYear = WeatherNormalizationCtrl.CURRENT_YEAR;
                this.$scope.previousYear = WeatherNormalizationCtrl.PREVIOUS_YEAR;
                this.$scope.temperatureUnit = this.OptionsService.temperatureUnit();
                this.$scope.metric = metric;
                this.$scope.showActuals = true;
                this.$scope.showTemperatures = false;
                this.$scope.timezone = currentBuilding.timeZoneId;
                this.$scope.periodSearch = {
                    start: aq.common.TimeConfiguration.This.Year.start(this.currentBuilding.timeZoneId),
                    end: aq.common.TimeConfiguration.This.Year.end(this.currentBuilding.timeZoneId),
                    interval: _.find(aq.common.TimeConfiguration.This.Month.intervals, { value: '1mon' })
                };
                this.onPeriodChange($scope.periodSearch.start, $scope.periodSearch.end, $scope.periodSearch.interval, $scope.periodSearch.defaultPeriod);
                this.appRestructure = Auth.hasFunctionality('App Restructure');
            }
            WeatherNormalizationCtrl.prototype.onPeriodChange = function (startDate, endDate, interval, label) {
                this.$scope.comparePreviousYear = false;
                this.$scope.showTemperatures = false;
                this.$scope.periodSearch.label = label;
                this.$scope.periodSearch.start = startDate.add(1, 'day').tz(this.currentBuilding.timeZoneId).startOf('month');
                this.$scope.periodSearch.end = endDate.add(1, 'day').tz(this.currentBuilding.timeZoneId).startOf('month');
                this.queryData(startDate, endDate, interval.value);
            };
            WeatherNormalizationCtrl.prototype.queryData = function (startDate, endDate, interval) {
                var _this = this;
                this.loading.start();
                var trendStartDate = moment(startDate).subtract(1, 'years');
                var trendEndDate = moment(trendStartDate).add(1, 'years');
                this.$q.all([
                    this.EnergyInsightsDataService.getEnergy(this.currentBuilding, startDate, endDate, this.metric, interval, this.account),
                    this.EnergyInsightsDataService.getWeatherNormalizedEnergy(this.currentBuilding, startDate, endDate, this.metric, interval, this.account),
                    this.EnergyInsightsDataService.getEnergy(this.currentBuilding, trendStartDate, trendEndDate, this.metric, interval, this.account),
                    this.EnergyInsightsDataService.getWeatherNormalizedEnergy(this.currentBuilding, trendStartDate, trendEndDate, this.metric, interval, this.account),
                    this.EnergyInsightsDataService.getTemperatures(this.currentBuilding, startDate, endDate, this.$scope.temperatureUnit, interval, this.account)
                ]).then(function (results) {
                    _this.$scope.currentEnergy = results[0];
                    _this.$scope.currentNormalized = results[1];
                    _this.$scope.trendEnergy = results[2];
                    _this.$scope.trendNormalized = results[3];
                    _this.$scope.currentTemperatures = results[4];
                    var uniqueValues = _.uniq(_.map(_this.$scope.currentNormalized, 'value'));
                    _this.$scope.disableWeatherNormalization = uniqueValues.length == 1 && uniqueValues[0] == null;
                    if (!_this.$scope.disableWeatherNormalization) {
                        _this.$scope.showWeatherNormalization = true;
                    }
                    _this.months = _this.$scope.currentEnergy.map(function (dataValue) {
                        return moment(dataValue.timestamp).format('MMM');
                    });
                    _this.$scope.energyNotes = _this.sortEnergyNotes();
                    _this.$scope.chartConfig = _this.buildChartConfig();
                    _this.$scope.tableData = _this.buildTableData();
                    _this.$rootScope.$broadcast('highchartRedraw', _this.$scope.chartConfig);
                    if (_this.$scope.periodSearch.interval.value === '1mon') {
                        return _this.WeatherNormalizationService.getTargetModel(_this.currentBuilding, 'ELECTRICITY', _this.$scope.periodSearch, _this.account);
                    }
                    _this.$scope.showActuals = true;
                }).then(function (targetModel) {
                    if (targetModel) {
                        _this.$scope.questionModel = targetModel;
                    }
                }).finally(function () {
                    _this.enableWeatherToast();
                    _this.loading.stop();
                });
            };
            WeatherNormalizationCtrl.prototype.comparePreviousYear = function () {
                var _this = this;
                if (this.$scope.comparePreviousYear) {
                    var trendStart = moment(this.$scope.periodSearch.start).subtract(1, 'year');
                    var trendEnd = moment(trendStart).add(1, 'year');
                    this.$scope.chart.showLoading();
                    this.$q.all([
                        this.EnergyInsightsDataService.getEnergy(this.currentBuilding, trendStart, trendEnd, this.metric, '1mon', this.account),
                        this.EnergyInsightsDataService.getWeatherNormalizedEnergy(this.currentBuilding, trendStart, trendEnd, this.metric, '1mon', this.account),
                        this.EnergyInsightsDataService.getTemperatures(this.currentBuilding, trendStart, trendEnd, this.$scope.temperatureUnit, '1mon', this.account)
                    ]).then(function (results) {
                        _this.previousYearEnergy = results[0];
                        _this.previousYearNormalized = results[1];
                        _this.previousYearTemperature = results[2];
                        var previousActuals = _this.$scope.chart.addSeries(_this.buildPreviousYearEnergySeries(_this.previousYearEnergy));
                        if (!_this.$scope.showActuals) {
                            previousActuals.hide();
                        }
                        var previousNormalized = _this.$scope.chart.addSeries(_this.buildPreviousYearNormalizedSeries(_this.previousYearNormalized));
                        if (!_this.$scope.showWeatherNormalization) {
                            previousNormalized.hide();
                        }
                        var previousYearTemperatures = _this.$scope.chart.addSeries(_this.buildPreviousYearTemperature(_this.previousYearTemperature));
                        if (!_this.$scope.showTemperatures) {
                            previousYearTemperatures.hide();
                        }
                        else {
                            previousYearTemperatures.show();
                        }
                        _this.$scope.chart.hideLoading();
                        _this.$scope.tableData = _this.buildTableData();
                    });
                }
                else {
                    _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.PREVIOUS_YEAR_ENERGY_SERIES_NAME }).remove();
                    _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.PREVIOUS_YEAR_NORMALIZED_SERIES_NAME }).remove();
                    _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.PREVIOUS_YEAR_TEMP_SERIES_NAME }).remove();
                    this.$scope.tableData = this.buildTableData();
                    this.$scope.chart.redraw();
                }
            };
            WeatherNormalizationCtrl.prototype.showTemperatures = function () {
                var currentYearTemperatureSeries = _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.CURRENT_YEAR_TEMP_SERIES_NAME });
                this.toggleSeries(currentYearTemperatureSeries);
                var previousYearTemperatureSeries = _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.PREVIOUS_YEAR_TEMP_SERIES_NAME });
                this.toggleSeries(previousYearTemperatureSeries);
                var climateNormalSeries = _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.CLIMATE_NORMAL_SERIES_NAME });
                this.toggleSeries(climateNormalSeries);
            };
            WeatherNormalizationCtrl.prototype.showWeatherNormalization = function () {
                var currentYearNormalized = _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.CURRENT_YEAR_NORMALIZED_SERIES_NAME });
                this.toggleSeries(currentYearNormalized);
                var previousYearNormalized = _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.PREVIOUS_YEAR_NORMALIZED_SERIES_NAME });
                this.toggleSeries(previousYearNormalized);
            };
            WeatherNormalizationCtrl.prototype.showActuals = function () {
                var currentYearActuals = _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.CURRENT_YEAR_ENERGY_SERIES_NAME });
                this.toggleSeries(currentYearActuals);
                var previousYearActuals = _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.PREVIOUS_YEAR_ENERGY_SERIES_NAME });
                this.toggleSeries(previousYearActuals);
            };
            WeatherNormalizationCtrl.prototype.enableWeatherToast = function () {
                if (this.$scope.disableWeatherNormalization) {
                    this.$scope.showToast = true;
                }
            };
            WeatherNormalizationCtrl.prototype.downloadCsv = function () {
                var _this = this;
                var csvContent = 'data:text/csv;charset=utf-8,';
                var fileName = '';
                csvContent += "Date,Actual Consumption " + this.metric.unit + ", Normalized Consumption " + this.metric.unit + ", Temperature " + this.$scope.temperatureUnit.unit + ", Climate Normal Temperature " + this.$scope.temperatureUnit.unit + "\n";
                this.$scope.tableData.forEach(function (row) {
                    csvContent += moment(row.actual.timestamp).format('MMM YYYY') + ", " + _this.convertValueForDisplay(row.actual) + ", " + _this.convertValueForDisplay(row.normalized) + ", " + _this.convertValueForDisplay(row.temperature) + ", " + _this.convertValueForDisplay(row.temperature, 'climateNormal') + "\n";
                });
                csvContent += "Total," + this.$scope.totals.currentTotal + "," + this.$scope.totals.currentTotalNormalized + "," + this.$scope.totals.currentTemperature + "," + this.$scope.totals.currentTemperatureNormalized + "\n";
                fileName = this.$scope.currentYear + "_WeatherNormalization_Report.csv";
                var link = document.createElement('a');
                link.setAttribute('href', encodeURI(csvContent));
                link.setAttribute('download', fileName);
                link.click();
            };
            WeatherNormalizationCtrl.prototype.goToOptimization = function () {
                var _this = this;
                this.account.get({ single: true }).then(function (thisAccount) {
                    var optimizationURI = URI('/accounts/' + thisAccount.id + '/optimization/building')
                        .search({
                        unit: 'KWH',
                        apiUnit: 'ENERGY',
                        interval: '1mon',
                        off: _.without(thisAccount.buildings, _this.currentBuilding.id).join(','),
                        start: _this.$scope.periodSearch.start.tz(_this.currentBuilding.timeZoneId).format(),
                        end: _this.$scope.periodSearch.end.tz(_this.currentBuilding.timeZoneId).format(),
                        children: 'buildings'
                    });
                    _this.$window.location.href = optimizationURI.toString();
                });
            };
            WeatherNormalizationCtrl.prototype.convertValueForDisplay = function (val, valueField) {
                if (valueField === void 0) { valueField = 'value'; }
                if (!val || !val[valueField]) {
                    return '-';
                }
                return val[valueField];
            };
            WeatherNormalizationCtrl.prototype.toggleSeries = function (series) {
                if (!series)
                    return;
                if (series.visible) {
                    series.hide();
                }
                else {
                    series.show();
                }
            };
            WeatherNormalizationCtrl.prototype.sortEnergyNotes = function () {
                var notes = {};
                var _loop_1 = function (i) {
                    var startOfMonth = moment(this_1.$scope.currentTemperatures[i].timestamp).tz(this_1.currentBuilding.timeZoneId).startOf('month');
                    var endOfMonth = moment(this_1.$scope.currentTemperatures[i].timestamp).tz(this_1.currentBuilding.timeZoneId).endOf('month');
                    notes[moment(this_1.$scope.currentTemperatures[i].timestamp).format('MMM')] = this_1.energyNotes.filter(function (note) {
                        var noteStartDate = moment(note.dataContext.startDate);
                        var noteEndDate = moment(note.dataContext.endDate);
                        return ((noteStartDate.isAfter(startOfMonth) && noteStartDate.isBefore(endOfMonth)) ||
                            (noteEndDate.isAfter(startOfMonth) && noteStartDate.isBefore(endOfMonth)));
                    });
                };
                var this_1 = this;
                for (var i = 0; i < this.$scope.currentTemperatures.length; i++) {
                    _loop_1(i);
                }
                return notes;
            };
            WeatherNormalizationCtrl.prototype.buildTableData = function () {
                var rows = [];
                this.$scope.totals = new Totals();
                var length = (this.$scope.currentNormalized && this.$scope.currentNormalized.length > 0) ? this.$scope.currentNormalized.length : this.$scope.currentEnergy.length;
                for (var i = 0; i < length; i++) {
                    if (this.$scope.comparePreviousYear) {
                        if (this.previousYearEnergy[i]) {
                            rows.push(new Row(this.previousYearEnergy[i], this.previousYearNormalized[i], this.previousYearTemperature[i]));
                            this.$scope.totals.previousTotal += this.previousYearEnergy[i] ? this.previousYearEnergy[i].value : 0;
                            this.$scope.totals.previousTotalNormalized += this.previousYearNormalized[i] ? this.previousYearNormalized[i].value : 0;
                        }
                        else {
                            rows.push(new Row(null, null, null));
                        }
                    }
                    rows.push(new Row(this.$scope.currentEnergy[i], this.$scope.currentNormalized[i], this.$scope.currentTemperatures[i]));
                    this.$scope.totals.currentTotal += this.$scope.currentEnergy[i] ? this.$scope.currentEnergy[i].value : 0;
                    this.$scope.totals.currentTotalNormalized += this.$scope.currentNormalized[i] ? this.$scope.currentNormalized[i].value : 0;
                    //push temperature data if exists
                    if (this.$scope.currentTemperatures[i]) {
                        this.$scope.totals.currentTemperature += this.$scope.currentTemperatures[i].value ? this.$scope.currentTemperatures[i].value : 0;
                        this.$scope.totals.currentTemperatureNormalized += this.$scope.currentTemperatures[i].climateNormal ? this.$scope.currentTemperatures[i].climateNormal : 0;
                    }
                }
                this.$scope.insightAnswer = (this.$scope.totals.currentTotalNormalized > 0) ? this.$scope.totals.currentTotalNormalized : this.$scope.totals.currentTotal;
                this.$scope.totals.currentTemperature = this.$scope.totals.currentTemperature / this.$scope.currentTemperatures.length;
                this.$scope.totals.currentTemperatureNormalized = this.$scope.totals.currentTemperatureNormalized / this.$scope.currentTemperatures.length;
                return rows;
            };
            WeatherNormalizationCtrl.prototype.buildChartConfig = function () {
                var _this = this;
                var energyNotes = this.$scope.energyNotes;
                return {
                    lang: {
                        drillUpText: '< Back to previous selection',
                        noData: 'No consumption data to display.'
                    },
                    noData: {
                        position: { 'x': 0, 'y': 0, 'align': 'center', 'verticalAlign': 'middle' }
                    },
                    chart: {
                        plotBorderWidth: 1,
                    },
                    title: '',
                    exporting: {
                        buttons: {
                            contextButton: {
                                enabled: false
                            }
                        }
                    },
                    tooltip: {
                        formatter: function () {
                            return "<strong>" + this.series.name + "</strong> <br> " + this.point.tooltip;
                        }
                    },
                    xAxis: {
                        categories: this.months,
                        type: 'category',
                        tickLength: 0,
                        labels: {
                            rotation: 0,
                            events: {
                                click: function (ev) {
                                    // this doesnt work anymore!
                                    var month = _.find(ev.target.attributes, { name: 'month' })['value'];
                                    //assume there is an * at the end if there are energy notes
                                    if (_this.$scope.energyNotes[month]) {
                                        _this.$mdDialog.show({
                                            /* @ngInject */
                                            controller: function ($scope, energyNotes) {
                                                $scope.energyNotes = energyNotes;
                                                $scope.cancel = function () {
                                                    _this.$mdDialog.cancel();
                                                };
                                            },
                                            templateUrl: 'app/energyInsights/weatherNormalization/energyNotesDialog.html',
                                            parent: angular.element(document.body),
                                            locals: {
                                                energyNotes: _this.$scope.energyNotes[month]
                                            },
                                            clickOutsideToClose: true
                                        });
                                    }
                                }
                            },
                            formatter: function () {
                                var month = this.value;
                                if (energyNotes[month] && energyNotes[month].length > 0) {
                                    return "<span month='" + month + "'>" + month + "<br><span month='" + month + "' class=\"md-badge md-primary\">" + energyNotes[month].length + "</span>";
                                }
                                else {
                                    return month;
                                }
                            },
                            useHTML: true
                        }
                    },
                    yAxis: [{
                            min: 0,
                            title: {
                                text: "Energy Consumption (" + this.$scope.metric.unit + ")"
                            }
                        }, {
                            title: {
                                text: 'Temperature (' + this.$scope.temperatureUnit.unit
                            },
                            opposite: true
                        }],
                    series: this.getChartSeries()
                };
            };
            WeatherNormalizationCtrl.prototype.getChartSeries = function () {
                if (this.$scope.disableWeatherNormalization) {
                    return [this.buildCurrentYearEnergySeries(this.$scope.currentEnergy),
                        this.buildCurrentYearTemperatureSeries(this.$scope.currentTemperatures),
                        this.buildClimateNormalSeries(this.$scope.currentTemperatures)];
                }
                else {
                    return [this.buildCurrentYearEnergySeries(this.$scope.currentEnergy),
                        this.buildCurrentYearNormalizedSeries(this.$scope.currentNormalized),
                        this.buildCurrentYearTemperatureSeries(this.$scope.currentTemperatures),
                        this.buildClimateNormalSeries(this.$scope.currentTemperatures)];
                }
            };
            WeatherNormalizationCtrl.prototype.buildPreviousYearEnergySeries = function (previousYearEnergy) {
                var color = '#AA3C9B';
                return {
                    id: 'previousYearEnergy',
                    name: WeatherNormalizationCtrl.PREVIOUS_YEAR_ENERGY_SERIES_NAME,
                    type: 'column',
                    color: color,
                    legendIndex: 5,
                    zIndex: 1,
                    data: _.take(this.convertToDataPointsAndCheckForIntervalData(previousYearEnergy, this.$scope.metric, color), 12)
                };
            };
            WeatherNormalizationCtrl.prototype.buildPreviousYearNormalizedSeries = function (previousYearNormalized) {
                return {
                    id: 'previousYearNormalized',
                    name: WeatherNormalizationCtrl.PREVIOUS_YEAR_NORMALIZED_SERIES_NAME,
                    type: 'column',
                    color: '#C9B1E2',
                    legendIndex: 6,
                    zIndex: 1,
                    data: _.take(this.convertToDataPoints(previousYearNormalized, this.$scope.metric), 12)
                };
            };
            WeatherNormalizationCtrl.prototype.buildPreviousYearTemperature = function (previousYearTemperatures) {
                return {
                    id: 'previousYearTemperatures',
                    name: WeatherNormalizationCtrl.PREVIOUS_YEAR_TEMP_SERIES_NAME,
                    type: 'line',
                    color: '#AA3C9B',
                    legendIndex: 7,
                    zIndex: 2,
                    visible: false,
                    yAxis: 1,
                    data: _.take(this.convertToDataPoints(previousYearTemperatures, this.$scope.temperatureUnit), 12)
                };
            };
            WeatherNormalizationCtrl.prototype.buildCurrentYearEnergySeries = function (currentYearEnergy) {
                var color = '#7ACE46';
                return {
                    id: 'currentYearEnergy',
                    name: WeatherNormalizationCtrl.CURRENT_YEAR_ENERGY_SERIES_NAME,
                    type: 'column',
                    color: color,
                    legendIndex: 3,
                    zIndex: 1,
                    data: _.take(this.convertToDataPointsAndCheckForIntervalData(currentYearEnergy, this.$scope.metric, color), 12)
                };
            };
            WeatherNormalizationCtrl.prototype.buildCurrentYearNormalizedSeries = function (currentYearNormalized) {
                return {
                    id: 'currentYearNormalized',
                    name: WeatherNormalizationCtrl.CURRENT_YEAR_NORMALIZED_SERIES_NAME,
                    type: 'column',
                    color: '#C2DCC2',
                    legendIndex: 4,
                    zIndex: 1,
                    data: _.take(this.convertToDataPoints(currentYearNormalized, this.$scope.metric), 12)
                };
            };
            WeatherNormalizationCtrl.prototype.buildCurrentYearTemperatureSeries = function (currentYearTemperatures) {
                return {
                    id: 'currentYearTemperature',
                    name: WeatherNormalizationCtrl.CURRENT_YEAR_TEMP_SERIES_NAME,
                    type: 'line',
                    color: '#7ACE46',
                    legendIndex: 1,
                    visible: false,
                    yAxis: 1,
                    zIndex: 2,
                    data: _.take(this.convertToDataPoints(currentYearTemperatures, this.$scope.temperatureUnit), 12)
                };
            };
            WeatherNormalizationCtrl.prototype.buildClimateNormalSeries = function (climateNormalTemperatures) {
                return {
                    id: 'climateaNormalTemperature',
                    name: WeatherNormalizationCtrl.CLIMATE_NORMAL_SERIES_NAME,
                    type: 'line',
                    color: '#F2A91D',
                    dashStyle: 'longdash',
                    legendIndex: 2,
                    yAxis: 1,
                    visible: false,
                    zIndex: 2,
                    data: _.take(this.convertToDataPoints(climateNormalTemperatures, this.$scope.temperatureUnit, 'climateNormal'), 12)
                };
            };
            WeatherNormalizationCtrl.prototype.convertToDataPoints = function (dataValues, metric, valueField) {
                var _this = this;
                if (valueField === void 0) { valueField = 'value'; }
                return dataValues.map(function (energyValue) {
                    return {
                        y: energyValue[valueField],
                        timestamp: energyValue.timestamp,
                        tooltip: moment(energyValue.timestamp).format('MMM YYYY') + " - " + _this.$filter('number')(energyValue[valueField], '0,0') + " " + metric.unit
                    };
                });
            };
            WeatherNormalizationCtrl.prototype.convertToDataPointsAndCheckForIntervalData = function (dataValues, metric, color, valueField) {
                var _this = this;
                if (valueField === void 0) { valueField = 'value'; }
                return dataValues.map(function (energyValue) {
                    var tooltip = moment(energyValue.timestamp).format('MMM YYYY') + " - " + _this.$filter('number')(energyValue[valueField], '0,0') + " " + metric.unit;
                    tooltip += energyValue.isMissingData ? '<br><div style="color:red">This building uses data directly <br><div style="color:red">from your utility company.<br><br><i>Some data has not been received yet.' : '';
                    return {
                        y: energyValue[valueField],
                        timestamp: energyValue.timestamp,
                        tooltip: tooltip,
                        color: energyValue.isMissingData ? _this.EnergyInsightsDataService.transparentColor(color) : color
                    };
                });
            };
            WeatherNormalizationCtrl.CURRENT_YEAR = parseInt(moment().format('YYYY'));
            WeatherNormalizationCtrl.PREVIOUS_YEAR = WeatherNormalizationCtrl.CURRENT_YEAR - 1;
            WeatherNormalizationCtrl.CURRENT_YEAR_ENERGY_SERIES_NAME = 'Actual Consumption';
            WeatherNormalizationCtrl.CURRENT_YEAR_NORMALIZED_SERIES_NAME = 'Normalized Consumption';
            WeatherNormalizationCtrl.CURRENT_YEAR_TEMP_SERIES_NAME = 'Actual Temperature';
            WeatherNormalizationCtrl.CLIMATE_NORMAL_SERIES_NAME = 'Climate Normal Temperature';
            WeatherNormalizationCtrl.PREVIOUS_YEAR_ENERGY_SERIES_NAME = 'Previous Actual Consumption';
            WeatherNormalizationCtrl.PREVIOUS_YEAR_NORMALIZED_SERIES_NAME = 'Previous Period Normalized Consumption';
            WeatherNormalizationCtrl.PREVIOUS_YEAR_TEMP_SERIES_NAME = 'Previous Period Temperature';
            return WeatherNormalizationCtrl;
        }());
        energyInsights.WeatherNormalizationCtrl = WeatherNormalizationCtrl;
        var Row = /** @class */ (function () {
            function Row(actual, normalized, temperature) {
                this.actual = actual;
                this.normalized = normalized;
                this.temperature = temperature;
            }
            return Row;
        }());
        energyInsights.Row = Row;
        var Totals = /** @class */ (function () {
            function Totals() {
                this.previousTotal = 0;
                this.previousTotalNormalized = 0;
                this.currentTotal = 0;
                this.currentTotalNormalized = 0;
                this.currentTemperature = 0;
                this.currentTemperatureNormalized = 0;
                this.corey = false;
            }
            return Totals;
        }());
        energyInsights.Totals = Totals;
        angular.module('energyInsights').controller('WeatherNormalizationCtrl', aq.energyInsights.WeatherNormalizationCtrl);
    })(energyInsights = aq.energyInsights || (aq.energyInsights = {}));
})(aq || (aq = {}));
