'use strict';
namespace aq.reports {
    export class CustomDateRangeChartsService {
        /* @ngInject */
        constructor(
            private $filter: ng.IFilterService
        ) {}

        public getCustomDemandChart(currentData: CustomChartData, report: Report, currentMonth: moment.Moment) {
            let unit = report.demandUnit;
            let metric = report.demandMetric;
            let series = [];
            let mainLegendIndexShown = false;
            if (this.hasData(currentData.data[metric].values)) {
                series.push({
                    data: _.take(this.$filter<Function>('arrayToUnit')(currentData.data[metric].values, unit), currentData.data[metric].values.length),
                    name: moment(currentMonth).tz(report.building.timeZoneId).format('YYYY') + " " + unit.unit,
                    color: report.colors.primaryColor,
                    type: 'line',
                    legendIndex: 0
                });
                mainLegendIndexShown = true;
            };

            if (currentData.data[metric].missingIntervalValues && this.hasData(currentData.data[metric].missingIntervalValues)) {
                series.push({
                    data: this.mergePresentAndMissingData(currentData.data[metric], unit),
                    name: moment(currentMonth).tz(report.building.timeZoneId).format('YYYY') + " " + unit.unit,
                    color: this.transparentColor(report.colors.primaryColor),
                    type: 'line',
                    showInLegend: !mainLegendIndexShown
                });
            };

            if (this.hasData(currentData.temperature) && (this.hasData(currentData.data[metric].values) || this.hasData(currentData.data[metric].missingIntervalValues))) {
                series.push({
                    data: _.take(_.map(currentData.temperature, _.round), currentData.temperature.length),
                    color: report.colors.weatherSecondaryColor,
                    yAxis: 1,
                    name: moment(currentMonth).tz(report.building.timeZoneId).format('YYYY') + " Temperature",
                    stack: 'temperature',
                    type: 'line',
                    legendIndex: 4
                })
            };

            let yAxis = [{
                title: {text: unit.unit},
                tickAmount: 6
            }, {
                title: {text: report.temperatureUnit.unit},
                opposite: true,
                tickAmount: 6
            }];
            return this.buildChart(series, this.getXAxisLabels(currentData.data[metric], report), 300, false, yAxis)
        };

        public getCustomUsageChart(currentData: CustomChartData, report: Report, currentMonth: moment.Moment) {
            let unit = report.usageUnit;
            let metric = report.usageMetric;
            let series = [];
            if (this.hasData(currentData.data[metric].values) || this.hasData(currentData.data[metric].missingIntervalValues)) {
                series.push({
                    data: currentData.data[metric].values.map((value, i) => {
                        if (i >= currentData.data[metric].missingIntervalValues.length) {
                            return {
                                y: value,
                                color: report.colors.primaryColor
                            }
                        }
                        if (currentData.data[metric].missingIntervalValues[i] !== null) {
                            return {
                                y: currentData.data[metric].missingIntervalValues[i],
                                color: this.transparentColor(report.colors.primaryColor)
                            }
                        } else {
                            return {
                                y: value,
                                color: report.colors.primaryColor
                            }
                        }
                    }),
                    name: moment(currentMonth).tz(report.building.timeZoneId).format('YYYY') + " " + unit.unit,
                    color: report.colors.primaryColor,
                    type: 'column',
                    legendIndex: 0
                })
            };

            if (this.hasData(currentData.temperature) && (this.hasData(currentData.data[metric].values) || this.hasData(currentData.data[metric].missingIntervalValues))) {
                series.push({
                    data: _.take(_.map(currentData.temperature, _.round), currentData.temperature.length),
                    color: report.colors.weatherSecondaryColor,
                    yAxis: 1,
                    name: moment(currentMonth).tz(report.building.timeZoneId).format('YYYY') + " Temperature",
                    stack: 'temperature',
                    type: 'line',
                    legendIndex: 4
                })
            };

            let yAxis = [{
                title: {text: unit.unit},
                tickAmount: 6
            }, {
                title: {text: report.temperatureUnit.unit},
                opposite: true,
                tickAmount: 6
            }];
            return this.buildChart(series, this.getXAxisLabels(currentData.data[metric], report), 300, false, yAxis)
        };

        public getCustomWeatherChart(currentData: CustomChartData, previousData: CustomChartData, report: Report, currentMonth: moment.Moment) {
            let series = [];
            if (this.hasData(currentData.data.degreeDays.hdd.values)) {
                series.push({
                    data: _.take(currentData.data.degreeDays.hdd.values, currentData.data.degreeDays.hdd.values.length),
                    name: moment(currentMonth).tz(report.building.timeZoneId).format('YYYY') + " HDD",
                    color: '#99d3ff',
                    stack: 'current',
                    type: 'column',
                    legendIndex: 0
                })
            }
            ;

            if (this.hasData(currentData.data.degreeDays.cdd.values)) {
                series.push({
                    data: _.take(currentData.data.degreeDays.cdd.values, currentData.data.degreeDays.cdd.values.length),
                    name: moment(currentMonth).tz(report.building.timeZoneId).format('YYYY') + " CDD",
                    color: '#008AF4',
                    stack: 'current',
                    type: 'column',
                    legendIndex: 1
                })
            };

            if (this.hasData(previousData.data.degreeDays.hdd.values)) {
                series.push({
                    data: _.take(previousData.data.degreeDays.hdd.values, previousData.data.degreeDays.hdd.values.length),
                    name: moment(currentMonth).tz(report.building.timeZoneId).subtract(1, 'year').format('YYYY') + " HDD",
                    color: '#B4B5B5',
                    stack: 'previous',
                    type: 'column',
                    legendIndex: 2
                })
            };

            if (this.hasData(previousData.data.degreeDays.cdd.values)) {
                series.push({
                    data: _.take(previousData.data.degreeDays.cdd.values, previousData.data.degreeDays.cdd.values.length),
                    name: moment(currentMonth).tz(report.building.timeZoneId).subtract(1, 'year').format('YYYY') + " CDD",
                    color: '#737778',
                    stack: 'previous',
                    type: 'column',
                    legendIndex: 3
                })
            };

            if (this.hasData(currentData.temperature)) {
                series.push({
                    data: _.take(_.map(currentData.temperature, _.round), currentData.temperature.length),
                    color: report.colors.weatherSecondaryColor,
                    yAxis: 1,
                    name: moment(currentMonth).tz(report.building.timeZoneId).format('YYYY') + " Temperature",
                    stack: 'temperature',
                    type: 'line',
                    legendIndex: 4
                })
            };

            let yAxis = [{
                title: {text: 'HDD-CDD'},
                tickAmount: 6
            },
                {
                    title: {text: report.temperatureUnit.unit},
                    opposite: true,
                    tickAmount: 6
                }];

            return this.buildChart(series, this.getXAxisLabels(currentData.data[report.usageMetric], report), 300, true, yAxis)
        };

        public getXAxisLabels (data, report) {
            let dates = [];
            let intervalNum = 1;
            if (data.values.length > 7) {
                intervalNum = Math.round((data.values.length / 7));
            }
            data.timestamps.forEach(function (time) {
                dates.push(moment(time).tz(report.building.timeZoneId).format('ddd MMM D'));
            });
            return {labels: dates, interval: intervalNum};

        };

        public buildChart(series, xAxisLabels, height, stacked, yAxis) {
            return {
                chart: {
                    height: 225
                },
                lang: {
                    noData: "No data to display."
                },
                noData: {
                    position: {"x": 0, "y": 0, "align": "center", "verticalAlign": "middle"}
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    categories: xAxisLabels.labels,
                    labels: {
                        rotation: false
                    },
                    tickInterval: xAxisLabels.interval,
                    tickLength: 0
                },
                yAxis,
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal',
                    itemStyle: {
                        fontSize: "11px"
                    }
                },
                plotOptions: {
                    column: {
                        stacking: stacked ? 'normal' : null
                    },
                    series: {
                        // turn off animations because of screenshot tool
                        animation: false
                    }
                },
                series,
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                }
            };
        };

        public hasData(data) {
            return _.filter(data, null).length !== 0;
        };

        private transparentColor(color: string): string {
            const alpha = 0.5;
            const convertedAlpha = Math.floor(alpha * 255);
            const alphaString = convertedAlpha < 16 ? '0' + convertedAlpha.toString(16) : convertedAlpha.toString(16);
            return color + alphaString;
        }

        private mergePresentAndMissingData(data: CustomReportResultObject, unit: Unit): number[] {
            if (data.values === null || data.missingIntervalValues === null) {
                return [];
            }
            const convertedValues = this.$filter<Function>('arrayToUnit')(data.values, unit)
            const convertedMissingValues = this.$filter<Function>('arrayToUnit')(data.missingIntervalValues, unit)
            const values = [];
            for (let i = 0; i < convertedMissingValues.length; i++) {
                if (i >= convertedValues.length) {
                    break;
                }
                if (convertedValues[i] !== null && convertedMissingValues[i] === null) {
                    values.push(convertedValues[i]);
                } else {
                    values.push(convertedMissingValues[i]);
                }
            }
            return values;
        }
    }


    angular.module('aq.reports').service('CustomDateRangeChartsService', CustomDateRangeChartsService);
}
