angular.module('aq-main').run(function ($httpBackend) {
    // first id is building id, next is meter number
    const intervalRegex = /^\/api\/v3\/interval\/realTimeDataSync\/.*\/.*$/;
    const intervalData = {
        buldingId: 113434,
        meterId: 343534,
        duration: '15m',
        data: [{
            timestamp: 1566588600000,
            interval: 23.75,
            realtime: 24.00,
            percentageDifference: 1.04712
        },
        {
            timestamp: 1567276844000,
            interval: 55.45,
            realtime: 56.10,
            percentageDifference: 1.04712
        }],
        aggregatePercentageDifference: 1.129944,
        intervalSum: 80.10,
        realtimeSum: 79.2,
        status: 'COMPLETE'
    };


    MockContext('interval', () => {
        // Baseload
        $httpBackend.whenGET(intervalRegex).respond((method, url, data) => {
            return [200, intervalData, {}];
        });
    });
});
