namespace aq.propertySettings {
    export class StateAlertLevelsComponentCtrl extends AbstractAlertComponent {
        public severityOptions: string[];
        public measureUnitOptions: aq.services.MeasureUnits[];
        public queryableTypeOptions: aq.services.Queryable[];
        public queryableOptionsCache: {
            [queryableType: string]: aq.services.FetchQueryableResult[];
        };
        public expandedLevel: StateAlertLevel;
        public isFormValid: boolean;
        // bound variables
        public building: aq.common.models.Building;
        public form;
        public onChange: Function;
        public isReadonly: boolean;
        public hideError: boolean;

        private sortableConf: any;

        /* @ngInject */
        constructor(
            private $mdDialog: ng.material.IDialogService,
            private RawService: aq.services.RawService,
            private $element: JQuery
        ) {
            super();
            this.sortableConf = {
                onSort: (event) => {
                    this.moveAlertItemIntoNewPosition(event.oldIndex, event.newIndex);
                }
            };
        }

        public $onInit() {
            this.severityOptions = [LevelSeverity.NORMAL, LevelSeverity.WARNING, LevelSeverity.CRITICAL];
            this.measureUnitOptions = this.RawService.getValidMeasureUnits();
            _.each(this.alert.alertLevels, (level) => {
                // set this for our UI
                level.measureUnit = this.toMeasureUnitString(level.measure, level.unit);
            });
            this.queryableTypeOptions = this.RawService.getQueryableTypesWithBmsPoints();
            this.queryableOptionsCache = {};
            this.RawService.fetchSpaces(this.building.id)
                .then((results) => this.updateQueryableCache(aq.services.Queryable.SPACES, results));
            this.RawService.fetchTenants(this.building.id)
                .then((results) => this.updateQueryableCache(aq.services.Queryable.TENANTS, results));
            this.RawService.fetchMeters(this.building.id)
                .then((results) => this.updateQueryableCache(aq.services.Queryable.METERS, results));
            this.RawService.fetchSources(this.building.id)
                .then((results) => this.updateQueryableCache(aq.services.Queryable.SOURCES, results));
            this.RawService.fetchPoints(this.building.id)
                .then((results) => this.updateQueryableCache(aq.services.Queryable.POINTS, results));
            this.RawService.fetchBmsPoints(this.building.id)
                .then((results) => this.updateQueryableCache(aq.services.Queryable.BMS_POINTS, results));
        }

        public $onChanges(changes) {
            if (changes.alert) {
                _.each(this.alert.alertLevels, (level: StateAlertLevel) => {
                    if (level.queryableType) {
                        level.queryableType = level.queryableType.toLowerCase() as any;
                    }
                    if (!level.queryable && level.queryableId && this.queryableOptionsCache[level.queryableType]) {
                        if (level.queryableType === aq.services.Queryable.BMS_POINTS) {
                            const queryable = _.find(this.queryableOptionsCache[level.queryableType], (q) => {
                                return q.id == level.queryableId && q.bmsPointName == level.bmsPointName;
                            });
                            if (queryable) {
                                level.queryable = {
                                    id: level.queryableId,
                                    bmsPointName: level.bmsPointName,
                                    name: queryable.name
                                };
                            }
                        }
                        if (level.queryableType !== aq.services.Queryable.BMS_POINTS) {
                            const queryable = _.find(this.queryableOptionsCache[level.queryableType], (q) => {
                                return q.id == level.queryableId;
                            });
                            if (queryable) {
                                level.queryable = {
                                    id: level.queryableId,
                                    name: queryable.name
                                }
                            }
                        }
                    }
                    if (!level.measureUnit && level.measure && level.unit) {
                        level.measureUnit = this.toMeasureUnitString(level.measure, level.unit);
                    }
                });
            }
        }

        public updateQueryableCache(queryableType: aq.services.Queryable, results: aq.services.FetchQueryableResult[]) {
            this.queryableOptionsCache[queryableType] = results;
            // For our UI, we need the full {id, name} object, but we only store id
            // so here we find the full object from our list based on our selected id
            _(this.alert.alertLevels)
                .filter((level: StateAlertLevel) => level.queryableId && level.queryableType == queryableType)
                .each((level: StateAlertLevel) => {
                    if (level.queryableType === aq.services.Queryable.BMS_POINTS) {
                        level.queryable = _.find(this.queryableOptionsCache[queryableType], (result) => {
                            return result.id == level.queryableId && result.bmsPointName == level.bmsPointName;
                        });
                        if (!level.queryable) {
                            level.queryable = {
                                name: `Configuration Error: ${level.bmsPointName}`,
                                id: level.queryableId
                            };
                        }
                    }
                    if (level.queryableType !== aq.services.Queryable.BMS_POINTS) {
                        level.queryable = _.find(this.queryableOptionsCache[queryableType], (result) => {
                            return result.id == level.queryableId;
                        });
                    }
                });
            // Remove queryableType option from list if there are no values for the queryableType
            if (!results.length) {
                const index = this.queryableTypeOptions.indexOf(queryableType);
                this.queryableTypeOptions.splice(index, 1);
                return;
            }
        }

        public onAlertChange() {
            if (this.onChange) {
                this.onChange({
                    $event: {
                        alert: this.alert
                    }
                });
            }
        }

        public expandLevel(level: StateAlertLevel) {
            if (this.expandedLevel == level) {
                this.expandedLevel = null;
            } else {
                this.expandedLevel = level;
            }
        }

        public getMeasureUnitOptionsForQueryable(level: StateAlertLevel) {
            // TODO: filter this based on our queryable? not sure how we would do this just yet
            return this.measureUnitOptions;
        }

        public updateMeasureUnit(level: StateAlertLevel) {
            const measureUnit = this.getMeasureUnitFromString(level.measureUnit);
            level.measure = measureUnit.measure;
            level.unit = measureUnit.unit;
        }

        public toMeasureUnitString(measure: aq.services.Measure, unit: aq.services.Unit) {
            return `${measure}::${unit}`;
        }

        public getMeasureUnitFromString(measureUnit: string) {
            const split = measureUnit.split('::');
            if (split.length != 2) {
                throw new Error(`Invalid measure unit: ${measureUnit}`);
            }
            return {
                measure: split[0] as aq.services.Measure,
                unit: split[1] as aq.services.Unit
            };
        }

        public onQueryableTypeChange(level: StateAlertLevel) {
            if (level.queryableType === aq.services.Queryable.BUILDINGS) {
                level.queryableId = Number(this.building.id);
            } else {
                level.queryableId = null;
            }
            level.queryable = null;
            // TODO: clear measure/unit when we change???
        }

        public onQueryableIdChange(level: StateAlertLevel) {
            if (level.queryable && level.queryable.id) {
                level.queryableId = level.queryable.id;
            }
            if (level.queryableType === aq.services.Queryable.BMS_POINTS) {
                if (level.queryable) {
                    level.bmsPointName = level.queryable.bmsPointName;
                }
            }
        }

        public getQueryableOptions(queryable: aq.services.Queryable, searchText: string) {
            if (!this.queryableOptionsCache[queryable]) {
                return [];
            }
            if (!searchText) {
                searchText = '';
            }
            searchText = searchText.toLowerCase();
            return _.filter(this.queryableOptionsCache[queryable], (option: aq.services.FetchQueryableResult) => {
                return _.includes(option.name.toLowerCase(), searchText);
            });
        }

        public hasBoundary(level: StateAlertLevel) {
            return !(_.isNil(level.lowerBoundary) && _.isNil(level.upperBoundary));
        }

        public moveAlertItemIntoNewPosition(oldIndex: number, newIndex: number) {
            const alertLevelCopy = angular.copy(this.alert.alertLevels[oldIndex]);
            this.alert.alertLevels.splice(oldIndex, 1);
            this.alert.alertLevels.splice(newIndex, 0, alertLevelCopy);
            this.updateLevelOrders();
            this.onAlertChange();
        }

        public deleteLevelConfirm(ev, level: StateAlertLevel) {
            // don't need to confirm if this alert hasn't been created yet
            if (!this.alert.id) {
                return this.deleteLevel(level);
            }

            const confirm = this.$mdDialog.confirm()
                .title('Are you sure you want to delete this level?')
                .ariaLabel('Delete Level')
                .targetEvent(ev)
                .ok('Ok')
                .cancel('Cancel');
            this.$mdDialog.show(confirm)
                .then(() => {
                    this.deleteLevel(level);
                });
        }

        public deleteLevel(level: StateAlertLevel) {
            _.remove(this.alert.alertLevels, (l) => l.order == level.order);
            this.updateLevelOrders();
            this.onAlertChange();
        }

        public updateLevelOrders() {
            _.each(this.alert.alertLevels, (l, i) => {
                l.order = i + 1;
            });
        }

        public addLevel() {
            if (!this.alert.alertLevels || this.alert.alertLevels.length == 0) {
                this.alert.alertLevels = [
                    {
                        order: 1,
                        levelSeverity: LevelSeverity.NORMAL
                    }
                ];
                this.expandLevel(this.alert.alertLevels[0]);
            } else {
                const lastLevel: StateAlertLevel = angular.copy(this.alert.alertLevels[this.alert.alertLevels.length - 1]);
                lastLevel.levelName = null;
                lastLevel.actionDescription = null;
                lastLevel.lowerBoundary = lastLevel.upperBoundary;
                lastLevel.upperBoundary = null;
                delete lastLevel.id;
                this.alert.alertLevels.push(lastLevel);
                this.expandLevel(lastLevel);
            }
            this.updateLevelOrders();
            this.onAlertChange();
        }

        public isNil(val) {
            return _.isNil(val);
        }

        public isErrorLevel(index: number) {
            if (this.hideError) {
                return false;
            }
            //invalid elements which are not becoming valid
            let invalidElements = this.$element.find('.ng-invalid').not('.ng-invalid-remove');
            let invalidElementsParents = invalidElements.parents(`md-input-container[data-level="${index}"]`);
            if (invalidElementsParents.length > 0) {
                return true;
            }
            //valid elements which are becoming invalid
            invalidElements = this.$element.find('.ng-invalid-add');
            invalidElementsParents = invalidElements.parents(`md-input-container[data-level="${index}"]`);
            if (invalidElementsParents.length > 0) {
                return true;
            }
            return false;
        }
    }

    angular.module('properties')
        .component('stateAlertLevels', {
            templateUrl: 'app/properties/alerts/directives/stateAlertLevels.html',
            bindings: {
                alert: '<',
                building: '<',
                form: '=',
                onChange: '&',
                isReadonly: '<?',
                hideError: '<?'
            },
            controller: StateAlertLevelsComponentCtrl
        });
}
