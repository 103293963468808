angular.module('tenantBilling')
.filter('approvalActionType', function($filter) {
    return function(approvalActionType, modifier, isMultiFamily) {
        if (approvalActionType) {
            if (isMultiFamily) {
                approvalActionType = approvalActionType.replace("TENANT", "RESIDENT")
            }
            return $filter('translate')("approvalAction."+approvalActionType + "_" + modifier)
        }
    }
});
