namespace aq.utilityBudgets {

    export interface UtilityAccount extends restangular.IElement {
        accountNumber: string;
        utilityCompany: number;
        autoUploadEnabled: boolean;
        utilityUrl: string;
        username: string;
        password: string;
        status: string;
        services?: string[];
        accountName?: string;
        shared: boolean;
        meters: Meter[];
        emailRecipients: number[];
        buildingPersonas?: string[];
        customEmails?: string[];
        utilityAccountNotes: string[];
        urjanetEnrollment?: number;
    }

    export interface UtilityAccountRequest {
        id?: number;
        accountNumber: string;
        utilityCompany: number;
        sharedBuildingIds?: number[];
        utilityUrl?: string;
        username?: string;
        password?: string;
        services?: string[];
        accountName?: string;
        meterIds?: string[];
        meters?: Meter[];
        emailRecipients?: number[];
        buildingPersonas?: string[];
        customEmails?: string[];
        urjanetEnrollment?: number;
    }

    export interface UtilityService extends restangular.IElement {
        type: string;
        billType: BillType;
        name: string;
        nameAbbreviated: string;
        account: string;
        usageRealUnitDisplay: string;
        demandRealUnitDisplay: string;
        defaultBlendedRate: number;
        defaultBlendedDemandRate: number;
        utilityCompany: number;
        tariffSchedule: number;
        collectors: number[];
        status: string;
        url: string;
        includeSewer: boolean;
        // It is not at all clear what kind of object the md-datepicker returns, making sure it ends up as a YYYY-MM-DD
        startDate: any;
        endDate: any;
    }

    export enum BillType {
        UTILITY = 'UTILITY',
        THIRD_PARTY_SUPPLY = 'THIRD_PARTY_SUPPLY'
    }

    export interface UtilityBillPeriod extends restangular.IElement {
        id: string;
        invoiceDate: Date | moment.Moment | string;
        startDate: Date | moment.Moment | string;
        endDate: Date | moment.Moment | string;
        charge: number;
        demand?: number;
        usage: number;
        usageCharge: number;
        demandCharge: number;
        taxCharge: number;
        otherCharge: number;
        adjustmentCharge: number;
        lateFeeCharge: number;
        cost: number;
        service: any; // could be either id or full utility service object
        companyName: string;
        measure: string;
        charges: any[];
        utilityBillStatementId?: string;
        startDateDisplay?: string; // for view only, not part of actual model
        statementUrl?: string;
    }

    export interface UtilityBillPeriodCharge extends restangular.IElement {
        utilityProviderMeterName: string;
        id: string;
        charge: number;
        demand: number;
        usage: number;
        usageCharge: number;
        demandCharge: number;
        otherCharge: number;
        taxCharge: number;
        adjustmentCharge: number;
        lateFeeCharge: number;
        cost: number;
        startDate: string;
        endDate: string;
        utilityBillPeriod: number;
        utilityMeter: number;
        usageRate?: number;
        serviceType?: string;
    }

    export interface UtilityMeter extends restangular.IElement {
        collector: number;
        utilityProviderMeterId: string;
        deviceId: number;
        intervalMeter?: IntervalMeter;
    }

    export interface UrjanetMeter extends restangular.IElement {
        serviceType: string;
        meterNumber: string;
        logicalMeterId: string;
    }

    export interface IntervalMeter {
        id: string;
        smartMeterNumber: number;
        aquicoreMeterID: number;
        billMeterNumber: string;
        subscribed: boolean;
        measure?: string;
    }

    export interface DateInterval {
        startDate: string;
        endDate: string;
    }

    export interface UtilityServiceCreate extends restangular.IElement {
        type: string;
        utilityAccount: string;
        billType: string;
    }

    export interface AutoUtilityUpload extends restangular.IElement {
        status: string;
        utilityUrl: string;
        username: string;
        password: string;
    }

    export interface UrjanetStatementViewItem {
        id: string;
        status: string;
        startDate: string;
        endDate: string;
        invoiceDate: string;
        usage: number;
        demand?: number;
        charge: number;
        meters: UrjanetMeterViewItem[];
        sourceUrl: string;
        demandUnit: string;
        usageUnit: string;
    }

    export interface UrjanetMeterViewItem {
        usage: number;
        startDate: string;
        endDate: string;
        demand?: number;
        charge: number;
        serviceType: string;
        meterNumber: string;
    }

    export interface MeasureUnit {
        unit: aq.services.Unit;
        value: string;
        conversionMultiplier?: number;
    }
    export interface BlendedRateCharge {
        id: number;
        label: string;
        utilityBillPeriod: number;
        startDate: string;
        endDate: string;
        invoiceDate: string;
        consumption: number;
        charge: number;
    }
    export interface BlendedRateCalculationResponse {
        id: number;
        building: number;
        charges: BlendedRateCharge[];
        totalConsumption: number;
        totalCharge: 0;
        blendedRate: number;
    }

    export interface BlendedRateByDateResponse {
        building: number;
        measure: aq.services.Measure;
        date: string;
        blendedRate: number;
    }

    export interface UtilitySpendingCumulativeResponse {
        start: string;
        end: string;
        spending: UtilitySpendingData;
        lastAvailableFullDataTimestamp: number;
    }
    export interface UtilitySpendingAccountsResponse {
        start: string;
        end: string;
        accounts: UtilitySpendingAccount[];
    }
    export interface UtilitySpendingAccount {
        accountNumber: string;
        utilityCompanyName: string;
        name?: string;
        spending: UtilitySpendingData;
    }
    export interface UtilitySpendingData {
        data: UtilitySpendingDataItem[];
        unitSymbol: string;
        unitName: string;
    }
    export interface UtilitySpendingDataItem {
        timestamp: number;
        localDate?: string;
        datum: {
            projected: number,
            consumption: number,
            utilityBill: number,
            blendedRate: number,
            blendedRateCalculation: number,
            isMissingData: boolean,
            demandRate?: number
        };
    }

    export interface MonthTableDataItem {
        date: string;
        temperature: string;
        temperatureType: 'historical' | 'forecast' | null;
        timestamp: number;
        activities: aq.models.activity.SimpleActivityResponse[];
        showActivities: boolean;
        charge: string;
        type: string;
    }
    export interface SeriesDataChargeMap {
        [name: string]: {
            data: BudgetChartPoint[];
            charge: number;
        };
    }
    export interface SeriesDataMap {
        [name: string]: BudgetChartPoint[];
    }
    export interface BudgetChartPoint {
        x: number;
        y: number;
        name?: string;
    }
    export interface DegreeDays {
        timestamps?: number[];
        start?: string;
        end?: string;
        cdd: {
            total: number;
            values: number[];
        };
        hdd: {
            total: number;
            values: number[];
        };
    }
    export interface Temperature {
        start?: string;
        end?: string;
        timestamps: number[];
        values: number[];
        climateNormals: number[];
    }
    export interface BudgetMonthPeriod {
        name: string;
        month: string;
        year: string;
    }
    export interface BudgetYearPeriod {
        name: string;
        year: string;
    }
    export interface MonthDataQueryParams {
        start: string;
        end: string;
        interval: string;
        measure: string;
        startDate?: string;
        endDate?: string;
    }
    export interface YearTableDataItem {
        date: string;
        occupancy: string;
        temperature: string;
        temperatureType: 'historical' | 'forecast' | null;
        charge: string;
        chargeValue: number;
        budgetPercent: string;
        isRed: boolean;
        projection: string;
        consumption: string;
        budget: string;
        rate: number;
        isProjected: boolean;
        isConsumption: boolean;
        accrual: AccrualResponse;
    }
    export interface MonthBudgetItem {
        id: number;
        startDate: string;
        endDate: string;
        value: number;
    }
    export interface BillStatementDataItem {
        id: number;
        startTimestamp: string;
        endTimestamp: string;
        datum: {
            charge: number;
            usageCharge: number;
            demandCharge: number;
            taxCharge: number;
            otherCharge?: number;
            lateFeeCharge?: number;
            adjustmentCharge?: number;
            cost?: number;
            blendedRate?: number;
        };
    }
    export interface BillStatementData {
        data: BillStatementDataItem[];
        usage: number;
        usageRealUnit: string;
        demand: number;
        demandRealUnit: string;
        usageCharge: number;
        demandCharge: number;
        otherCharge: number;
        lateFeeCharge: number;
        taxCharge: number;
        adjustmentCharge: number;
        cost: number;
        unitSymbol: string;
        unitName: string;
        blendedRate?: number;
    }
    export interface BillTableDataItem {
        temperature: string;
        charge: string;
        chargeValue: number;
        usageCharge: string;
        usageValue: number;
        usagePercent?: string;
        demandCharge: string;
        demandValue: number;
        demandPercent?: string;
        taxCharge?: string;
        taxValue?: number;
        taxPercent?: string;
        otherCharge?: string;
        otherValue?: number;
        otherPercent?: string;
        lateFeeCharge?: string;
        lateFeeValue?: number;
        lateFeePercent?: string;
        adjustmentCharge?: string;
        adjustmentValue?: number;
        adjustmentPercent?: string;
        cost?: string;
        costValue?: number;
    }
    export interface BillYearlyTableDataItem extends BillTableDataItem {
        startDate: string;
        endDate: string;
        hasNegativeCharge: boolean;
    }
    export interface BillStatementTableDataItem extends BillTableDataItem {
        date: string;
    }
    export interface DegreeDaysItem {
        hdd: number;
        cdd: number;
    }
    export class UtilityBillAnalysisData {
        billing: BillStatementData;
        start: string;
        end: string;
    }
    export interface AccrualSubmitRequest {
        id: number;
        accruedExpense: number;
        sendAccounting: boolean;
        sendApprovers: boolean;
        sendMyself: boolean;
        customEmails: string[];
    }
    export interface AccrualUpdateRequest {
        id: number;
        accruedStartDate: string;
        accruedEndDate: string;
        currentConsumption: number;
        currentExpense: number;
        accruedConsumption: number;
        accruedExpense: number;
    }
    export interface AccrualResponse {
        id: number;
        building: number;
        utilityServiceId: number;
        measure: string;
        startDate: string;
        endDate: string;
        accruedStartDate: string;
        accruedEndDate: string;
        currentConsumption: number;
        currentDemand: number;
        currentExpense: number;
        accruedConsumption: number;
        accruedDemand: number;
        accruedExpense: number;
        totalExpense: number;
        status: aq.utilityBudgets.AccrualStatus;
        submitDate: string;
    }
    export interface AccrualDataItem {
        period: string;
        consumption: string;
        demand: string;
        expense: string;
    }
    export interface AccrualData {
        current: AccrualDataItem;
        accrued: AccrualDataItem;
        totalExpense: number;
        submitDate: string;
    }
    export enum AccrualStatus {
        PENDING = 'PENDING',
        SUBMITTED = 'SUBMITTED'
    }
    export interface BudgetWorkflowSchedule {
        id: number;
        frequency: BudgetWorkflowScheduleFrequency;
        dayOfMonth: number;
        reminderDays: number;
        reminderUsers: number[];
        submitToEmails: string[];
        autoEnabled: boolean;
    }
    export enum BudgetWorkflowScheduleFrequency {
        MONTHLY = 'MONTHLY',
        QUARTERLY = 'QUARTERLY'
    }
    export interface BudgetSettingsModel {
        buildingId: number;
        autoEnabled: boolean;
        frequency: BudgetWorkflowScheduleFrequency;
        dayOfMonth: number;
        reminderDays: number;
        reminderUsers: number[];
        accountantReceivers: string[];
        approverReceivers: string[];
    }
    export interface SubmitAccrualRequest {
        accruals: SubmitAccrualItem[];
        sendAccounting: boolean;
        sendApprovers: boolean;
        sendMyself: boolean;
        customEmails: string[];
    }
    export interface SubmitAccrualItem {
        id: number;
        accruedExpense: number;
    }
    export interface UserItem {
        id?: number;
        fullName: string;
        email: string;
    }
    export interface UrjanetEnrollmentResponse {
        nextExpectedPostDate?: string;
        nextExpectedConfidence?: string;
    }

    export interface UrjanetEnrollment {
        id: number;
        utilityName: string;
        accountNumber: string;
        utilityUrl: string;
        username: string;
        password: string;
        status: string;
        previousStatus: string;
        firstSyncedDate: string;
        lastModifiedDate: string;
        created: string;
    }
    export interface UrjanetEnrollmentAccount {
        id: number;
        urjanetEnrollmentId: string;
        accountId: string;
        enrollmentStatus: string;
        extractionStatus: string;
        message: string;
        monitoringStatus: string;
        nextExpectedPostDate: string;
        nextExpectedConfidence: string;
    }

    export interface UrjanetEnrollmentChangelog {
        accountNumberChange: boolean;
        utilityUrlChange: boolean;
        usernameChange: boolean;
        passwordChange: boolean;
        statusChange: boolean;
        newStatus: string;
        timestamp: number;
        changeAuthor: string;
        imageLink: string;
    }
}
