angular
    .module('projects', ['ngAnimate', 'aq.forms', 'aq.ui', 'aq.auth', 'aq.services', 'ngMaterial'])
    .config(($stateProvider: ng.ui.IStateProvider, $locationProvider) => {
        $stateProvider
            .state('aq.projects', {
                abstract: true,
                url: '/accounts/:accountId/projects',
                templateUrl: 'app/projects/projects.html',
                data: { appName: 'Projects' },
                controller: 'ProjectsController as vm',
                resolve: {
                    accountElement(
                        $stateParams: ng.ui.IStateParamsService,
                        Restangular: restangular.IService,
                        waitForAuthToken,
                    ): restangular.IElement {
                        return Restangular.one('accounts', $stateParams.accountId);
                    },
                    account(accountElement, DataStore: aq.common.DataStore, waitForAuthToken): Promise<aq.common.models.Account> {
                        return DataStore.get(accountElement, { single: true }, false);
                    },
                    accountId(account) {
                        return account.id;
                    },
                    buildings(waitForAuthToken, RestangularV3: restangular.IService) {
                        return RestangularV3.one('').getList('buildings', { showInactive: false });
                    },
                    building(
                        buildings,
                        $stateParams: ng.ui.IStateParamsService,
                        $state: ng.ui.IStateService,
                        Restangular: restangular.IService,
                        waitForAuthToken
                    ): aq.common.models.Building {
                        let { accountId, buildingId } = $stateParams;
                        if (!buildingId) {
                            buildingId = buildings[0].id;
                        } else {
                            Restangular.one('accounts', accountId).one('buildings', buildingId).customPOST({}, 'record');
                        }
                        return _.findById(buildings, buildingId);
                    },
                    buildingId(building): number {
                        return building.id;
                    },
                    activities(ActivityService: aq.services.ActivityService, building: aq.common.models.Building): Promise<aq.models.activity.ActivityListResponse> {
                        return ActivityService.getActivities({
                            start: moment().subtract({ months: 4 }).startOf('day').toISOString(),
                            end: moment().endOf('day').toISOString(),
                            building: parseInt(building.id, 10),
                            type: []
                        });
                    }
                }
            })
            .state('aq.projects.overview', {
                url: '/building/:buildingId/overview',
                templateUrl: 'app/projects/overview.html',
                controller: 'OverviewController as vm',
                data: {
                    breadcrumb: 'Activities',
                    icon: 'dashboard',
                    aria: 'Projects and Activities Overview'
                },
                resolve: {
                }
            })
            .state('aq.projects.board', {
                url: '/building/:buildingId/board',
                template: '<div class="paxxl">project board</div>',
                // templateUrl: 'app/projectsv2/projects.html',
                controller: 'OverviewController as vm',
                data: {
                    breadcrumb: 'Projects',
                    icon: 'view_column',
                    aria: 'In-Progress Projects'
                },
                resolve: {

                }
            })
            .state('aq.projects.measure-and-verify', {
                url: '/building/:buildingId/measure-and-verify',
                template: '<div class="paxxl">Measure and Verify</div>',
                // templateUrl: 'app/projectsv2/projects.html',
                controller: 'OverviewController as vm',
                data: {
                    breadcrumb: 'Measure & Verify',
                    icon: 'trending_up',
                    aria: 'Measure the Success of Projects'
                },
                resolve: {

                }
            });

        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });
    });
