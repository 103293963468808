namespace aq.propertySettings {
    export class BuildingScheduleHelperService {
        public allWeek: string[];
        public workDays: string[];
        public daysOfWeek: any[];
        private timeOptions: any[];
        constructor() {
            this.allWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
            this.workDays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];
            this.daysOfWeek = [
                { day: 'SUNDAY', selected: false },
                { day: 'MONDAY', selected: false },
                { day: 'TUESDAY', selected: false },
                { day: 'WEDNESDAY', selected: false },
                { day: 'THURSDAY', selected: false },
                { day: 'FRIDAY', selected: false },
                { day: 'SATURDAY', selected: false }
            ];
            this.generateTimeOptions();
        }
        public capitalizeString(data: string): string {
            if (!data) {
                return '';
            }
            const result = data.toLowerCase();
            return result.charAt(0).toUpperCase() + result.slice(1);
        }
        public getTimeOptions() {
            return angular.copy(this.timeOptions);
        }
        public getDisplayTime(timeValue) {
            const timeOption = _.find(this.timeOptions, (option) => option.value == timeValue);
            return timeOption ? timeOption.display : '';
        }
        public getHumanReadableEventDescription(weekDays: string[], timeStart: string, timeEnd: string, dateStart: string, dateEnd: string) {
            const timeView = this.getTimeView(timeStart, timeEnd);
            const daysView = this.getRepeatDaysView(weekDays);
            const datePeriodView = this.getPeriodView(dateStart, dateEnd);
            return `This event occurs${timeView}${daysView}${datePeriodView}.`;
        }
        private generateTimeOptions() {
            this.timeOptions = [];
            for (let i = 0; i < 48; i++) {
                const display = moment().startOf('day').add(i * 30, 'minutes').format('h:mm A');
                const value = moment().startOf('day').add(i * 30, 'minutes').format('HH:mm:ss');
                this.timeOptions.push({
                    display,
                    value
                });
            }
        }
        private getTimeView(timeStart: string, timeEnd: string) {
            if (!timeStart || !timeEnd) {
                return '';
            } else {
                const displayTimeStart = this.getDisplayTime(timeStart);
                const displayTimeEnd = this.getDisplayTime(timeEnd);
                return ` from ${displayTimeStart} to ${displayTimeEnd}`;
            }
        }
        private getRepeatDaysView(days: string[]): string {
            if (!days || days.length == 0) {
                return '';
            }
            if (days.length == 5
                && _.difference(this.workDays, days).length == 0
                && _.difference(days, this.workDays).length == 0) {
                return  ' every working day';
            }
            if (days.length == 7
                && _.difference(this.allWeek, days).length == 0
                && _.difference(days, this.allWeek).length == 0) {
                return ' every weekday';
            }
            if (days.length == 6
                && _.difference(this.allWeek, days).length == 1) {
                const diffDay = _.difference(this.allWeek, days)[0];
                return ' every day except ' + this.capitalizeString(diffDay);
            }
            let result = '';
            _.forEach(days, (day, index) => {
                const dayView = this.capitalizeString(day);
                let delimiter = ', ';
                if (index == 0) {
                    delimiter = '';
                } else if (index == days.length - 1) {
                    delimiter = ' and ';
                }
                result += (delimiter + dayView);
            });
            return ' every ' + result;
        }
        private getPeriodView(dateStart: string, dateEnd: string) {
            if (!dateStart || !dateEnd) {
                return '';
            }
            const dateStartFormatted = moment(dateStart).format('MM/DD/YYYY');
            const dateEndFormatted = moment(dateEnd).format('MM/DD/YYYY');
            return `, beginning ${dateStartFormatted} and ending on ${dateEndFormatted}`;
        }
    }
    angular.module('properties').service('BuildingScheduleHelperService', BuildingScheduleHelperService);
}
