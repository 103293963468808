var aq;
(function (aq) {
    var ui;
    (function (ui) {
        var _ActivityAidIssueDisplay = /** @class */ (function () {
            /* @ngInject */
            function _ActivityAidIssueDisplay($scope, $translate, Restangular, resolver, Messages, AidIssueService) {
                this.$scope = $scope;
                this.$translate = $translate;
                this.Restangular = Restangular;
                this.resolver = resolver;
                this.Messages = Messages;
                this.AidIssueService = AidIssueService;
            }
            _ActivityAidIssueDisplay.prototype.$onInit = function () {
                var _this = this;
                this.isLoading = true;
                this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                    var issuePromise, accountPromise, err_1;
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                issuePromise = this.AidIssueService.getIssue(this.activity.context.aidissue);
                                accountPromise = this.Restangular.one('accounts', this.accountId).get({ single: true });
                                return [4 /*yield*/, Promise.all([issuePromise, accountPromise])];
                            case 1:
                                _a = _b.sent(), this.issue = _a[0], this.account = _a[1];
                                this.isLoading = false;
                                this.contextLoadedCallback();
                                this.$scope.$emit('ActivityContextLoaded', this.issue);
                                return [3 /*break*/, 3];
                            case 2:
                                err_1 = _b.sent();
                                this.isLoading = false;
                                this.noDataCallback();
                                this.Messages.error('Data failed to load');
                                throw err_1;
                            case 3: return [2 /*return*/];
                        }
                    });
                }); });
            };
            return _ActivityAidIssueDisplay;
        }());
        ui.ActivityAidIssueDisplay = {
            controller: _ActivityAidIssueDisplay,
            controllerAs: 'vm',
            templateUrl: 'app/common/components/activities/contexts/aidIssueDisplay.html',
            bindings: {
                activity: '<',
                accountId: '<',
                building: '<',
                noDataCallback: '&',
                contextLoadedCallback: '&'
            }
        };
        angular
            .module('aq.ui')
            .component('activityAidIssueDisplay', ui.ActivityAidIssueDisplay);
    })(ui = aq.ui || (aq.ui = {}));
})(aq || (aq = {}));
