namespace aq.dashboard {
    export class NoAvailableDashboardsCtrl {
        /* @ngInject */
        constructor(
            protected $scope: DashboardMainCtrlScope,
            protected account: aq.common.models.Account,
            protected $mdDialog: ng.material.IDialogService,
            protected UserService
        ) {
        }
        public createDashboard() {
            this.$mdDialog.show({
                controller: 'AddEditDashboardCtrl as vm',
                templateUrl: 'app/dashboard/main/actions/addEditDashboard/addEditDashboard.html',
                clickOutsideToClose: false,
                locals: {
                    account: this.account,
                    dashboard: null,
                    userId: this.UserService.currentUser.id
                }
            }).then((createdDashboard) => {
                this.$scope.$emit('newDashboardEmit', { dashboard: createdDashboard });
            });
        }
    }
    angular.module('dashboard').controller('NoAvailableDashboardsCtrl', NoAvailableDashboardsCtrl);
}
