angular
    .module('aq.admin.accounts.enrollment', ['ui.router', 'aq.services.restangular', 'aq.services', 'aq.messages'])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.admin.accounts.enrollment', {
                url: '/enrollment',
                templateUrl: 'app/admin/accounts/enrollment/main.html',
                controller: 'AdminAccountEnrollmentCtrl as vm',
                resolve: {
                    reload($stateParams) {
                        return $stateParams.reload;
                    }
                },
                params: {
                    reload: null
                },
                data: {
                    breadcrumb: 'Enrollment',
                    icon: '.aq-icons-property-settings'
                }
            })
            .state('aq.admin.accounts.enrollment.forAccount', {
                url: '/for-account/:accountId',
                templateUrl: 'app/admin/accounts/enrollment/adminEnrollmentDetails.html',
                controller: 'AdminEnrollmentDetails as vm',
                resolve: {
                    account(Restangular: restangular.IService, $stateParams, waitForAuthToken) {
                        if (!$stateParams.accountId) {
                            return {
                                id: 0,
                                accountName: ''
                            };
                        }
                        return Restangular.one('accounts', $stateParams.accountId).getList()
                            .then((response) => response[0]);
                    },
                    buildings(Restangular: restangular.IService, $stateParams, waitForAuthToken) {
                        if (!$stateParams.accountId) {
                            return [];
                        }
                        if ($stateParams.buildings == null) {
                            return Restangular.one('accounts', $stateParams.accountId).all('buildings').getList();
                        }
                        return $stateParams.buildings;
                    },
                    buildingsEnrollment($q: ng.IQService, RestangularV3: restangular.IService, $stateParams, buildings, waitForAuthToken) {
                        if ($stateParams.buildingsEnrollment == null) {
                            return $q.all<aq.propertySettings.BuildingEnrollment>(_.map(buildings, (building) => {
                                return RestangularV3
                                    .one('buildings', building.id)
                                    .one('enrollment')
                                    .get()
                                    .then((response) => {
                                        return response || { building: building.id, progress: 0, currentPhase: 'Not Specified', status: 'UNKNOWN' };
                                    });
                            }));
                        }
                        return $stateParams.buildingsEnrollment;
                    }
                },
                params: {
                    buildings: null,
                    buildingsEnrollment: null
                }
            });
    });
