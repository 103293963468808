var aq;
(function (aq) {
    var tenantbilling;
    (function (tenantbilling) {
        var AddEditReminderCtrl = /** @class */ (function (_super) {
            __extends(AddEditReminderCtrl, _super);
            /* @ngInject */
            function AddEditReminderCtrl($mdDialog, reminder, reminders, users, billingReminderTypes, timeOptions, billingSetupId, TenantBillingSetupService, Auth) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.reminder = reminder;
                _this.reminders = reminders;
                _this.users = users;
                _this.billingReminderTypes = billingReminderTypes;
                _this.timeOptions = timeOptions;
                _this.billingSetupId = billingSetupId;
                _this.TenantBillingSetupService = TenantBillingSetupService;
                _this.Auth = Auth;
                if (!_this.reminder) {
                    _this.setupDefaultReminder();
                }
                _this.usersToRemind = _.map(_this.reminder.usersToRemind, function (userId) {
                    var targetUser = _.find(_this.users, function (user) { return user.id == userId; });
                    return {
                        id: targetUser.id,
                        fullName: targetUser.fullName
                    };
                });
                return _this;
            }
            AddEditReminderCtrl.prototype.cancel = function (data) {
                this.$mdDialog.cancel();
            };
            AddEditReminderCtrl.prototype.hide = function (data) {
                this.$mdDialog.hide(data);
            };
            AddEditReminderCtrl.prototype.saveReminder = function () {
                var _this = this;
                if (this.usersToRemind.length == 0) {
                    this.isUsersToRemindError = true;
                    return;
                }
                var action;
                var untypedReminder = this.reminder;
                if (untypedReminder.id) {
                    action = this.TenantBillingSetupService.updateReminder(untypedReminder);
                }
                else {
                    action = this.TenantBillingSetupService.createReminder(untypedReminder);
                }
                action.then(function (result) {
                    _this.hide(result);
                });
            };
            AddEditReminderCtrl.prototype.queryUsers = function () {
                var _this = this;
                var searchText = this.userSearchText.toLowerCase();
                var users = this.users.filter(function (user) {
                    return user.fullName.toLowerCase().indexOf(searchText) > -1
                        && _.findIndex(_this.usersToRemind, { fullName: user.fullName }) === -1;
                });
                return users;
            };
            AddEditReminderCtrl.prototype.consolidateReminderUsers = function () {
                this.isUsersToRemindError = this.usersToRemind.length == 0;
                var userIds = _.map(this.usersToRemind, function (u) { return u.id; });
                this.reminder.usersToRemind = userIds;
            };
            AddEditReminderCtrl.prototype.setupDefaultReminder = function () {
                this.reminder = {
                    setup: this.billingSetupId,
                    daysBefore: null,
                    time: '09:00:00',
                    type: 'INVOICES',
                    usersToRemind: []
                };
            };
            return AddEditReminderCtrl;
        }(aq.common.Controllers.ModalCtrl));
        tenantbilling.AddEditReminderCtrl = AddEditReminderCtrl;
        angular.module('tenantBilling').controller('AddEditReminderCtrl', AddEditReminderCtrl);
    })(tenantbilling = aq.tenantbilling || (aq.tenantbilling = {}));
})(aq || (aq = {}));
