var aq;
(function (aq) {
    var reportCenter;
    (function (reportCenter) {
        var ReportRecipientsCtrl = /** @class */ (function (_super) {
            __extends(ReportRecipientsCtrl, _super);
            function ReportRecipientsCtrl($mdDialog, userAssignment, userItems, scheduledReport, report) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.userAssignment = userAssignment;
                _this.userItems = userItems;
                _this.scheduledReport = scheduledReport;
                _this.report = report;
                if (_.includes([reportCenter.ReportName.SUMMARY_DAILY, reportCenter.ReportName.SUMMARY_MONTHLY, reportCenter.ReportName.MONTHLY_BUILDING_ENGINEER], _this.report.name)) {
                    _this.isIntervalReadOnly = true;
                }
                else {
                    _this.isIntervalReadOnly = false;
                }
                _this.isCustomDateRange = _this.report.name == reportCenter.ReportName.CUSTOM_DATE_RANGE ? true : false;
                _this.initScheduleControls();
                return _this;
            }
            ReportRecipientsCtrl.prototype.ok = function () {
                this.userAssignment.scheduling = this.buildScheduleFromControls();
                this.hide(this.userAssignment);
            };
            ReportRecipientsCtrl.prototype.queryUsers = function (searchText) {
                var _this = this;
                if (!searchText) {
                    searchText = '';
                }
                searchText = searchText.toLowerCase();
                return _.filter(this.userItems, function (usr) {
                    return usr.name && usr.name.toLowerCase().indexOf(searchText) > -1
                        && _.every(_this.userAssignment.reportUsers, function (ru) { return ru.id != usr.id; });
                });
            };
            ReportRecipientsCtrl.prototype.onChangeInterval = function () {
                this.periods = this.getPeriods(this.interval);
                this.selectedPeriod = null;
            };
            ReportRecipientsCtrl.prototype.buildScheduleFromControls = function () {
                var scheduling = {
                    dayOfMonth: this.interval == 'MONTHLY' ? this.selectedPeriod : null,
                    dayOfWeek: this.interval == 'WEEKLY' || this.interval == 'BIWEEKLY' ? this.selectedPeriod : null,
                    interval: this.interval,
                    time: this.interval != 'IMMEDIATELY' ? this.selectedTime : null
                };
                return scheduling;
            };
            ReportRecipientsCtrl.prototype.initScheduleControls = function () {
                var _this = this;
                var scheduledReport = this.scheduledReport;
                var schedule = scheduledReport.scheduling;
                this.interval = scheduledReport.scheduling && scheduledReport.scheduling.interval ? scheduledReport.scheduling.interval : 'IMMEDIATELY';
                this.periods = this.getPeriods(this.interval);
                if (this.interval == 'WEEKLY' || this.interval == 'BIWEEKLY') {
                    this.selectedPeriod = schedule.dayOfWeek;
                    this.initPeriodDisplay = 'on ' + _.find(this.periods, function (p) { return p.value == _this.selectedPeriod; }).display;
                }
                else if (this.interval == 'MONTHLY') {
                    this.selectedPeriod = schedule.dayOfMonth;
                    this.initPeriodDisplay = 'on ' + _.find(this.periods, function (p) { return p.value == _this.selectedPeriod; }).display;
                }
                else {
                    this.selectedPeriod = null;
                    if (this.interval == 'DAILY') {
                        this.initPeriodDisplay = 'every day';
                    }
                    else {
                        this.initPeriodDisplay = 'on demand';
                    }
                }
                this.times = this.getTimes(schedule.time);
                this.selectedTime = schedule.time;
                if (this.interval != 'IMMEDIATELY') {
                    this.initTimeDisplay = ', at ' + _.find(this.times, function (t) { return t.value == _this.selectedTime; }).display;
                }
                else {
                    this.initTimeDisplay = '';
                }
                this.initSchedule = {
                    interval: this.interval,
                    period: this.selectedPeriod,
                    time: this.selectedTime
                };
            };
            ReportRecipientsCtrl.prototype.getTimes = function (timeToCreateIfMissing) {
                if (timeToCreateIfMissing === void 0) { timeToCreateIfMissing = null; }
                // NOTE:
                // minimum supported time interval is 1-hour (e.g. 01:00, 02:00, 03:00, etc.)
                // if existing schedule time is not on the full hour (e.g. 10:25 am), a separate dropdown option is generated for backwards compatibilliy
                var result = [];
                var time = moment('2018-01-01T00:00:00Z');
                for (var i = 0; i < 24; i++) {
                    result.push({
                        value: time.utc().format('HH:mm:ss'),
                        display: time.utc().format('h:mm a')
                    });
                    time.add(1, 'hour');
                }
                if (timeToCreateIfMissing && !_.find(result, function (item) { return item.value == timeToCreateIfMissing; })) {
                    var insertIndex = 0;
                    while (insertIndex < result.length && result[insertIndex].value < timeToCreateIfMissing) {
                        insertIndex++;
                    }
                    var missingTime = {
                        value: timeToCreateIfMissing,
                        display: moment("2018-01-01T" + timeToCreateIfMissing + "Z").utc().format('h:mm a')
                    };
                    result.splice(insertIndex, 0, missingTime);
                }
                return result;
            };
            ReportRecipientsCtrl.prototype.getPeriods = function (interval) {
                if (interval != 'WEEKLY' && interval != 'MONTHLY' && interval != 'BIWEEKLY') {
                    return [];
                }
                var numberOfDays = interval == 'WEEKLY' || interval == 'BIWEEKLY' ? 7 : 28;
                var name = interval == 'WEEKLY' || interval == 'BIWEEKLY' ? 'week' : 'month';
                var ordinalDays = _.getOrdinalNumbers(numberOfDays);
                return _.map(ordinalDays, function (ordinalDay, index) {
                    return { value: index + 1, display: ordinalDay + " day of the " + name };
                });
            };
            return ReportRecipientsCtrl;
        }(aq.common.Controllers.ModalCtrl));
        reportCenter.ReportRecipientsCtrl = ReportRecipientsCtrl;
        angular.module('reportCenter').controller('ReportRecipientsCtrl', ReportRecipientsCtrl);
    })(reportCenter = aq.reportCenter || (aq.reportCenter = {}));
})(aq || (aq = {}));
