namespace aq.tenantbilling {
    export class CalculateRatesStepCtrl {
        public currencyUnit: any;
        public monthlyBilling: MonthlyTenantBilling;
        public periodTenantServices: PeriodTenantService[];
        public onComplete: Function;
        public progressText: string;
        public building: aq.common.models.Building;
        /* @ngAnnotate */
        constructor(
            private $q: ng.IQService,
            private $location: ng.ILocationService,
            private Messages: aq.services.Messages,
            private $mdStepper,
            private $mdDialog: ng.material.IDialogService,
            private OptionsService,
            private MonthlyBillingService: MonthlyBillingService
        ) { }

        public $onInit() {
            this.currencyUnit = this.OptionsService.currencyUnit();
            this.progressText = null;
        }

        public onStepLoad() {
            if (this.monthlyBilling.id) {
                this.progressText = 'Refreshing rates...';
                this.MonthlyBillingService.getPeriodTenantServices(this.monthlyBilling)
                .then((periodServices) => {
                    this.periodTenantServices = periodServices;
                    this.progressText = null;
                });
            }
        }

        public previousStep() {
            const previousStepNumber = this.$mdStepper('runInvoicingStepper').currentStep;
            this.$location.search('step', previousStepNumber);
            this.$mdStepper('runInvoicingStepper').back();
        }

        public saveAndContinue() {
            this.progressText = 'Saving rates...';
            // TODO: only save services that have changed
            const confirmRatesPromise = this.MonthlyBillingService.confirmRates(this.monthlyBilling);
            const promises = _.map(this.periodTenantServices, (service) => {
                return this.MonthlyBillingService.savePeriodTenantService(service);
            });
            promises.unshift(confirmRatesPromise);
            return this.$q.all(promises)
                .then((results) => {
                    this.progressText = 'Updating charges...';
                    return this.$q.all([
                        results[0] as MonthlyTenantBilling,
                        this.MonthlyBillingService.getInvoices(this.monthlyBilling),
                        this.MonthlyBillingService.getChargeValues(this.monthlyBilling),
                        this.MonthlyBillingService.getPeriodTenantServices(this.monthlyBilling)
                    ]);
                })
                .then((results) => {
                    this.onComplete({
                        $event: {
                            monthlyBilling: results[0],
                            tenantInvoices: results[1],
                            tenantChargeValues: results[2],
                            periodTenantServices: results[3],
                            backActionStateData: {
                                updatedBillingId: results[0].id,
                                timeStamp: new Date()
                            }
                        }
                    });
                    this.Messages.success('Rates successfully saved');
                    this.$location.search('step', 3);
                    this.$mdStepper('runInvoicingStepper').next();
                })
                .finally(() => {
                    this.progressText = null;
                });
        }

        public showRateCalculatorModal(service: PeriodTenantService) {
            this.$mdDialog.show({
                controller: 'RateCalculatorCtrl as vm',
                templateUrl: 'app/tenantBilling/invoicing/run/rateCalculator/rateCalculator.html',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                locals: {
                    service,
                    building: this.building
                }
            }).then((calculation) => {
                if (calculation) {
                    service.rate = calculation.blendedRate;
                    service.calculation = JSON.stringify(calculation);
                }
                this.Messages.success('Rate set');
            });
        }

        public getRateUnit(service: PeriodTenantService) {
            switch (service.method) {
                case 'METERED_USAGE':
                    return `${this.currencyUnit.symbol}/${service.usageUnit}`;
                case 'FEE':
                    return service.feeType.usageUnit;
                case 'TAX':
                    return service.usageUnit;
                default:
                    return '';
            }
        }

        public getPeriodTenantServices() {
            return _.sortBy(this.periodTenantServices, [
                (periodTenantService) => {
                    switch (periodTenantService.method) {
                        case 'METERED_USAGE':
                            return 1;
                        case 'FEE':
                            return 2;
                        case 'TAX':
                            return 3;
                        default:
                            return 4;
                    }
                },
                'serviceType',
                'name',
                'serviceId'
            ]);
        }
    }

    angular
        .module('tenantBilling')
        .component('calculateRatesStep', {
            templateUrl: 'app/tenantBilling/invoicing/run/components/calculateRatesStep/calculateRatesStep.html',
            controller: CalculateRatesStepCtrl,
            controllerAs: 'vm',
            bindings: {
                monthlyBilling: '<',
                periodTenantServices: '<',
                building: '<',
                onComplete: '&'
            }
        });
}
