namespace aq.utilityBudgets {
    export class UtilityAccountDetails {
        public legendItems: UtilityAccountStatusViewModel[];
        public currentNavItem: string;
        public buildingsForUtilityAccount: number[];
        public buildingIdToName: string[];

        private measureTypes: MeasureType[];
        private entityActions: aq.common.models.EntityMenuAction[];
        private backAction: aq.components.BackButtonData;
        private utilityAccountForStatus: UtilityAccount;
        private budgetAccess: common.models.AppAccessObject;
        private isAqAdmin: boolean;
        private tariffScheduleDisplayNameById: { [key: number]: string } = {};
        private utilityAccountStatusView: UtilityAccountStatusViewModel;

        /* @ngInject */
        constructor(
            private utilityAccount: UtilityAccount,
            private utilityServices: UtilityService[],
            private buildingId: string,
            private utilityCompanies,
            private tariffSchedules: aq.admin.configuration.utilityCompany.TariffSchedule[],
            private Messages,
            private Errors,
            private $mdDialog: ng.material.IDialogService,
            private $state: ng.ui.IStateService,
            private $mdMedia: ng.material.IMedia,
            private UtilityServiceHelper: UtilityServiceHelper,
            private UserService: aq.services.UserService,
            private users: UserItem[],
            private Auth: aq.services.Auth,
            private authAccess: common.models.AuthAppAccess,
            private readOnlyForm: boolean,
            private buildings: common.models.Building[],
            private RestangularV3: restangular.IService,
            private $scope,
            private Segment: aq.services.SegmentService,
            public utilityAccountBuildingIds: number[]
        ) {
            this.isAqAdmin = this.UserService.currentUser.userType === 'ADMINISTRATOR';
            this.budgetAccess = this.authAccess.Budgets;
            if (!this.utilityAccount) {
                this.$state.go('aq.utilityBudgets.utilityaccounts', {}, { reload: true });
            }

            this.entityActions = [];
            this.currentNavItem = 'info';
            if (this.$state.current.name.endsWith('.changelog')) {
                this.currentNavItem = 'changelog';
            }
            if (this.$state.current.name.endsWith('.admin')) {
                this.currentNavItem = 'admin';
            }

            this.backAction = {
                state: 'aq.utilityBudgets.utilityaccounts',
                isDataSetOnSaveOnly: true,
                stateData: {
                    timeStamp: moment().valueOf()
                }
            };

            this.buildingIdToName = [];

            const visibleBuildingIds = this.buildings
                .map(visibleBuilding => Number(visibleBuilding.id));
            this.buildingsForUtilityAccount = this.utilityAccountBuildingIds
                .filter(id => id !== this.buildingId as any)
                .filter(id => visibleBuildingIds.indexOf(id) > -1);
            this.buildings.forEach((visibleBuilding) => {
                this.buildingIdToName[Number(visibleBuilding.id)] = visibleBuilding.name;
            });

            this.utilityAccountForStatus = angular.copy(this.utilityAccount);
            this.legendItems = [
                this.UtilityServiceHelper.getStatusView(null),
                ..._.map(
                    this.UtilityServiceHelper.getAllStatuses(),
                    (status) => this.UtilityServiceHelper.getStatusView(status)
                )
            ];
        }

        public save(account: UtilityAccount) {
            this.Segment.trackEvent('Utility Accounts:Update Utility Account');
            delete this.utilityAccount.status;
            if (!this.utilityAccount.autoUploadEnabled) {
                delete this.utilityAccount.username;
                delete this.utilityAccount.password;
            }
            return account.save()
            .then((updatedAccount) => {
                this.Messages.success('Successfully updated Utility Account');
            })
            .catch(() => {
                this.Messages.error('Error updating Utility Account, please contact your Administrator');
            });

        }

        public delete(account) {
            const confirm = this.$mdDialog
                .confirm()
                .title('Are you sure you want to delete this Utility Account?')
                .ok('Confirm')
                .cancel('Cancel');

            return this.$mdDialog
                .show(confirm)
                .then(() => {
                    this.Segment.trackEvent('Utility Accounts:Delete Utility Account');
                    account.remove({ buildingId: this.buildingId })
                        .then(() => {
                            this.Messages.info('Utility Account deleted');
                            this.$scope.$emit('UTILITY_ACCOUNT_REMOVED', account.id);
                            this.$state.go('aq.utilityBudgets.utilityaccounts');
                        })
                        .catch(() => {
                            this.Messages.error('Unable to perform delete action');
                        });
                });
        }
    }

    angular.module('aq.utilityBudgets').controller('UtilityAccountDetails', UtilityAccountDetails);
}
