angular.module('aq-main').run(function($httpBackend) {
    const singleRegex = /^\/api\/v3\/projects\/\d{1,}$/;
    const singlePutRegex = /^\/api\/v3\/projects\/\d{1,}$/;
    const listRegex = /^\/api\/v3\/projects\?buildingId=\d{1,}$/;
    const resultsRegex = /^\/api\/v3\/projects\/\d{1,}\/results$/;
    const taskRegex = /^\/api\/v3\/projects\/\d{1,}\/tasks/;
    const singleTaskRegex = /^\/api\/v3\/projects\/\d{1,}\/tasks\/\d{1,}$/;
    const dataRegex = /^\/api\/v3\/projects\/\d{1,}\/data/;
    const typesRegex = /^\/api\/v3\/projects\/types$/;
    const statusesRegex = /^\/api\/v3\/projects\/statuses$/;
    let idCounter = 1;
    const projects = [{
        id: idCounter++,
        building: 22485218,
        name: 'Create OM for Buyer',
        summary: 'Offering Memorandum for Pembroke Real Estate',
        description: 'The OM must be ready for underwriting no later than December 1st - it will be executed by PL Real Estate Consultants, in conjunction with our team.',
        type: 'CAPITAL_REPAIRS', // ENERGY_EFFICIENCY, ADA_COMPLIANCE
        basePeriodStartDate: moment().startOf('year').format(),
        basePeriodEndDate: moment().startOf('year').add(2, 'month').format(),
        implementationPeriodStartDate: moment().startOf('year').add(2, 'month').format(),
        implementationPeriodEndDate: moment().startOf('year').add(4, 'month').format(),
        reportingPeriodStartDate: moment().startOf('year').add(4, 'month').format(),
        reportingPeriodEndDate: moment().startOf('year').add(6, 'month').format(),
        status: 'IMPLEMENTATION', // IMPLEMENTATION, BACKLOG,
        notes: 'To balance content of the project with project management technology, communicate with the partners with from the early stage. IPR management and Innovation management. A process where someone should be responsible for all activities related to Coherent work plan.',
        cost: 8935,
        expectedSavings: 0,
        budgetType: 'Allowance', // enum?
        owner: 9091598, // Sam Dib
        tasks: [{
            id: idCounter++,
            name: 'Property Description',
            details: 'Include most recent rent roll from Include most recent rent roll from Aquicore, Include most recent rent roll from Aquicore',
            owner: 9091598, // Sam Dib
            completionDate: null,
            dueDate: moment().endOf('month').format()
        }, {
            id: idCounter++,
            name: 'General Tenant Description',
            details: 'Include most recent rent roll from Aquicore',
            owner: 20958414, // caitlin
            completionDate: moment().startOf('month').format(),
            dueDate: moment().endOf('month').format()
        }, {
            id: idCounter++,
            name: 'Local Zoning Laws',
            details: 'Request from County Clerks Office',
            owner: 19429932, // miked
            completionDate: moment().startOf('month').format(),
            dueDate: moment().endOf('month').format()
        }, {
            id: idCounter++,
            name: 'Offering Procedure',
            details: 'Same as prior acquisitions',
            owner: 20958414, // caitlin
            completionDate: moment().startOf('month').format(),
            dueDate: moment().endOf('month').format()
        }]
    }, {
        id: idCounter++,
        building: 22490253,
        name: 'Central Heating Plant Expansion',
        summary: 'ECM (Option C) to expand the capacity of the existing Central Heating Plant',
        description: 'The scope of the project included the installation of new deaeration equipment capable of supporting production of 600,000 PPH steam.  Also included was a new distinct make-up water supply train with the potential to double the throughput of the present system. Larger ion exchange vessels, filters, piping, regeneration equipment and auxiliaries were required to achieve the necessary increase in capacity.   New additional pumping capacity is also essential for make-up, condensate and feed water systems to augment each system’s flows. Standby and storage requirements will be addressed by the installation of supplementary make-up water and condensate return tanks.',
        type: 'CAPITAL_REPAIRS', // ENERGY_EFFICIENCY, ADA_COMPLIANCE
        basePeriodStartDate: moment().startOf('year').format(),
        basePeriodEndDate: moment().startOf('year').add(2, 'month').format(),
        implementationPeriodStartDate: moment().startOf('year').add(2, 'month').format(),
        implementationPeriodEndDate: moment().startOf('year').add(4, 'month').format(),
        reportingPeriodStartDate: moment().startOf('year').add(4, 'month').format(),
        reportingPeriodEndDate: moment().startOf('year').add(6, 'month').format(),
        status: 'IMPLEMENTATION', // IMPLEMENTATION, BACKLOG,
        notes: 'To balance content of the project with project management technology, communicate with the partners with from the early stage. IPR management and Innovation management. A process where someone should be responsible for all activities related to Coherent work plan.',
        cost: 119000,
        expectedSavings: 50000,
        budgetType: 'Allowance', // enum?
        owner: 9091598, // Sam Dib
        tasks: [{
            id: idCounter++,
            name: 'Contract ESCO',
            details: 'See list of company approved ESCO contacts',
            owner: 9091598, // Sam Dib
            completionDate: null,
            dueDate: moment().endOf('month').format()
        }, {
            id: idCounter++,
            name: 'Hold meeting with all engineers',
            details: 'Discuss the project parameters and establish timeline',
            owner: 20958414, // caitlin
            completionDate: moment().startOf('month').format(),
            dueDate: moment().endOf('month').format()
        }, {
            id: idCounter++,
            name: 'Generate M&V Tax Exemption Petition',
            details: 'Use Aquicore M&V report + project details',
            owner: 19429932, // miked
            completionDate: moment().startOf('month').format(),
            dueDate: moment().endOf('month').format()
        }, {
            id: idCounter++,
            name: 'Generate PO for new equipment',
            details: 'Approval part of the 2017 Capital Budget',
            owner: 20958414, // caitlin
            completionDate: moment().startOf('month').format(),
            dueDate: moment().endOf('month').format()
        }]
    }, {
        id: idCounter++,
        building: 22489632,
        name: 'Window External Shading',
        summary: 'ECM (Option C) to install window external shading',
        description: `
        Window type(s): Double pane, clear, heat absorbingand low-e coated glass
        Window area(s):
        0.141 m² (33% of wall area)Shading device(s): Automated venetian blinds between panes
		Climate(s): Canada (Montreal)
		Orientation(s): South
		Year/period(s): 14 to 26 April 1987 (validation of program) / October to November`,
        type: 'CAPITAL_REPAIRS', // ENERGY_EFFICIENCY, ADA_COMPLIANCE
        basePeriodStartDate: moment().startOf('year').format(),
        basePeriodEndDate: moment().startOf('year').add(2, 'month').format(),
        implementationPeriodStartDate: moment().startOf('year').add(2, 'month').format(),
        implementationPeriodEndDate: moment().startOf('year').add(4, 'month').format(),
        reportingPeriodStartDate: moment().startOf('year').add(4, 'month').format(),
        reportingPeriodEndDate: moment().startOf('year').add(6, 'month').format(),
        status: 'MEASUREMENT', // IMPLEMENTATION, BACKLOG,
        cost: 12000,
        expectedSavings: 8000,
        budgetType: 'Allowance', // enum?
        owner: 9091598, // anna
        tasks: [{
            id: idCounter++,
            name: 'Submit PO for new venetial blinds',
            details: 'See prior PO for budget requirements',
            owner: 9091598, // anna
            completionDate: null,
            dueDate: moment().endOf('month').format()
        }, {
            id: idCounter++,
            name: 'Contract Vendor',
            details: 'Ideally install + materials by same party',
            owner: 20958414, // caitlin
            completionDate: moment().startOf('month').format(),
            dueDate: moment().endOf('month').format()
        }, {
            id: idCounter++,
            name: 'Inform Tenants',
            details: 'Install will occur during non working hours, but will affect their conference rooms',
            owner: 19429932, // miked
            completionDate: moment().startOf('month').format(),
            dueDate: moment().endOf('month').format()
        }]
    }, {
        id: idCounter++,
        building: 22485218,
        name: 'Install High Efficiency Heat Pumps',
        summary: `ECM (Option C) to replace existing heat pumps with Lennox XP21 MULTI-STAGE HEAT PUMPS for 4-12th floors`,
        description: `ECM (Option C) to replace existing heat pumps with Lennox XP21 MULTI-STAGE HEAT PUMPS for 4-12th floors`,
        type: 'CAPITAL_REPAIRS', // ENERGY_EFFICIENCY, ADA_COMPLIANCE
        basePeriodStartDate: moment().startOf('year').format(),
        basePeriodEndDate: moment().startOf('year').add(2, 'month').format(),
        implementationPeriodStartDate: moment().startOf('year').add(2, 'month').format(),
        implementationPeriodEndDate: moment().startOf('year').add(4, 'month').format(),
        reportingPeriodStartDate: moment().startOf('year').add(4, 'month').format(),
        reportingPeriodEndDate: moment().startOf('year').add(6, 'month').format(),
        status: 'BACKLOG', // IMPLEMENTATION, BACKLOG,
        notes: 'To balance content of the project with project management technology, communicate with the partners with from the early stage. IPR management and Innovation management. A process where someone should be responsible for all activities related to Coherent work plan.',
        cost: 32000,
        expectedSavings: 42150,
        budgetType: 'Allowance', // enum?
        owner: 9091598, // anna
        tasks: [{
            id: idCounter++,
            name: 'Remove HP',
            details: 'Schedule removal of all existing heat pumps.',
            owner: 9091598, // anna
            completionDate: null,
            dueDate: moment().endOf('month').format()
        }, {
            id: idCounter++,
            name: 'Hold meeting with all engineers',
            details: `We will need to coordinate the contractor's site visits and engineer's responsibilities`,
            owner: 20958414, // caitlin
            completionDate: moment().startOf('month').format(),
            dueDate: moment().endOf('month').format()
        }, {
            id: idCounter++,
            name: 'Schedule Install',
            details: 'Contact Lennox Site Team',
            owner: 19429932, // miked
            completionDate: moment().startOf('month').format(),
            dueDate: moment().endOf('month').format()
        }]
    }, {
        id: idCounter++,
        building: 22485218,
        name: 'Add Secondary Cooling Tower',
        summary: `This measure would apply to evaporative cooling towers 150 tons and larger.`,
        description: `This measure would apply to evaporative cooling towers 150 tons and larger, installed in new construction and replacement projects for commercial/ industrial/ institutional buildings covered under Title 24. Building types/ sectors most likely to be affected include those that have a cooling tower used for rejecting heat from an HVAC system (e.g., office building) or used for rejecting heat from process loads (e.g., manufacturing, food processing, etc.).`,
        type: 'CAPITAL_REPAIRS', // ENERGY_EFFICIENCY, ADA_COMPLIANCE
        basePeriodStartDate: moment().startOf('year').format(),
        basePeriodEndDate: moment().startOf('year').add(2, 'month').format(),
        implementationPeriodStartDate: moment().startOf('year').add(2, 'month').format(),
        implementationPeriodEndDate: moment().startOf('year').add(4, 'month').format(),
        reportingPeriodStartDate: moment().startOf('year').add(4, 'month').format(),
        reportingPeriodEndDate: moment().startOf('year').add(6, 'month').format(),
        status: 'MEASUREMENT', // IMPLEMENTATION, BACKLOG,
        notes: 'To balance content of the project with project management technology, communicate with the partners with from the early stage. IPR management and Innovation management. A process where someone should be responsible for all activities related to Coherent work plan.',
        cost: 18000,
        expectedSavings: 4200,
        budgetType: 'Allowance', // enum?
        owner: 9091598, // anna
        tasks: [{
            id: idCounter++,
            name: 'Research most effective CT to add',
            details: 'Needs to comply with ASHRAE STANDARD 189.1',
            owner: 9091598, // anna
            completionDate: null,
            dueDate: moment().endOf('month').format()
        }, {
            id: idCounter++,
            name: 'Hold meeting with all engineers',
            details: `We will need to coordinate the contractor's site visits and engineer's responsibilities`,
            owner: 20958414, // caitlin
            completionDate: moment().startOf('month').format(),
            dueDate: moment().endOf('month').format()
        }, {
            id: idCounter++,
            name: 'Schedule Install',
            details: 'Engineering Team 4-A to Oversee',
            owner: 19429932, // miked
            completionDate: moment().startOf('month').format(),
            dueDate: moment().endOf('month').format()
        }]
    }];

    const projectResults = {
        totalSavings: 2800,
        savingsPerUtility: [{
            utility: 'heat',
            savings: 0
        }, {
            utility: 'gas',
            savings: 0
        }, {
            utility: 'electricity',
            savings: 2800
        }, {
            utility: 'water',
            savings: 0
        }, {
            utility: 'steam',
            savings: 0
        }]
    };

    MockContext('projects-results', () => {
        $httpBackend.whenGET(dataRegex).respond((method, url, data) => {
            const params = MockHelpers.getParamsAsObject(url);
            const dataToReturn = [];
            if (params.unit === 'Expected+Utility+Spending') {
                dataToReturn.push({timestamp: 1504224000000, value: 6800}); // september
                dataToReturn.push({timestamp: 1506816000000, value: 7000}); // october
                dataToReturn.push({timestamp: 1509494400000, value: 7200}); // november
                dataToReturn.push({timestamp: 1512086400000, value: 6500}); // december
                dataToReturn.push({timestamp: 1514764800000, value: 6000}); // jan 18
                dataToReturn.push({timestamp: 1517443200000, value: 6250}); // feb 18
            } else if (params.unit === 'Actual+Utility+Spending') {
                dataToReturn.push({timestamp: 1491004800000, value: 7500}); // april
                dataToReturn.push({timestamp: 1493596800000, value: 7000}); // may
                dataToReturn.push({timestamp: 1496275200000, value: 8666}); // june
                dataToReturn.push({timestamp: 1498867200000, value: 9000}); // july
                dataToReturn.push({timestamp: 1501545600000, value: 8500}); // august
                dataToReturn.push({timestamp: 1504224000000, value: 6800}); // september
                dataToReturn.push({timestamp: 1506816000000, value: 5550}); // october
                dataToReturn.push({timestamp: 1509494400000, value: 5850}); // november
            }
            return [200, {data: dataToReturn}, {}];
        });

        $httpBackend.whenGET(resultsRegex).respond((method, url, data) => {
            const ids = MockHelpers.getIds(url);
            const projectId = parseInt(ids[0]);
            projectResults['project'] = projectId;
            return [200, projectResults, {}];
        });

    });

    MockContext('projects', () => {
        $httpBackend.whenGET(listRegex).respond((method, url, data) => {
            const queryParams = MockHelpers.getQueryParams(url);
            return [200, _.filter(projects, {building: parseInt(queryParams['buildingId'][0])}), {}];
        });
        $httpBackend.whenGET(singleRegex).respond((method, url, data) => {
            const tokens = url.split('/');
            const finalToken = tokens[tokens.length - 1];
            const idToken = finalToken.split('?')[0];
            if (parseInt(idToken)) {
                return [200, _.find(projects, {id: parseInt(idToken)}), {}];
            }
            return [200, null, {}];
        });

        $httpBackend.whenPUT(singlePutRegex).respond((method, url, data) => {
            data = JSON.parse(data);
            if (data.id) {
                _.remove(projects, {id: data.id});
            }
            projects.push(data);
            return [200, data, {}];
        });

        $httpBackend.whenPOST(listRegex).respond((method, url, data) => {
            data = JSON.parse(data);
            data.id = ++idCounter;
            projects.push(data);
            return [200, data, {}];
        });

        $httpBackend.whenPOST(taskRegex).respond((method, url, data) => {
            data = JSON.parse(data);
            data.id = ++idCounter;
            const projectId = parseInt(MockHelpers.getIds(url)[0]);
            const project = _.find(projects, {id: projectId});
            if (!project.tasks) {
                project.tasks = [];
            }
            project.tasks.push(data);
            return [200, data, {}];
        });

        $httpBackend.whenPUT(singleTaskRegex).respond((method, url, data) => {
            data = JSON.parse(data);
            const projectId = parseInt(MockHelpers.getIds(url)[0]);
            const project = _.find(projects, {id: projectId});
            _.remove(project.tasks, {id: data.id});
            project.tasks.push(data);
            return [200, data, {}];
        });

        $httpBackend.whenDELETE(singleTaskRegex).respond((method, url, data) => {
            const projectId = parseInt(MockHelpers.getIds(url)[0]);
            const taskId = parseInt(MockHelpers.getIds(url)[1]);
            const project = _.find(projects, {id: projectId});
            _.remove(project.tasks, {id: taskId});
            return [200, {}, {}];
        });

        $httpBackend.whenGET(dataRegex).respond((method, url, data) => {
            const params = MockHelpers.getParamsAsObject(url);
            const dataToReturn = [];
            if (params.unit === 'Expected+Utility+Spending') {
                dataToReturn.push({timestamp: 1504224000000, value: 6800}); // september
                dataToReturn.push({timestamp: 1506816000000, value: 7000}); // october
                dataToReturn.push({timestamp: 1509494400000, value: 7200}); // november
                dataToReturn.push({timestamp: 1512086400000, value: 6500}); // december
                dataToReturn.push({timestamp: 1514764800000, value: 6000}); // jan 18
                dataToReturn.push({timestamp: 1517443200000, value: 6250}); // feb 18
            } else if (params.unit === 'Actual+Utility+Spending') {
                dataToReturn.push({timestamp: 1491004800000, value: 7500}); // april
                dataToReturn.push({timestamp: 1493596800000, value: 7000}); // may
                dataToReturn.push({timestamp: 1496275200000, value: 8666}); // june
                dataToReturn.push({timestamp: 1498867200000, value: 9000}); // july
                dataToReturn.push({timestamp: 1501545600000, value: 8500}); // august
                dataToReturn.push({timestamp: 1504224000000, value: 6800}); // september
                dataToReturn.push({timestamp: 1506816000000, value: 5550}); // october
                dataToReturn.push({timestamp: 1509494400000, value: 5850}); // november
            }
            return [200, {data: dataToReturn}, {}];
        });

        $httpBackend.whenGET(typesRegex).respond((method, url, data) => {
            return [200, {
                'CAPITAL_REPAIRS': {
                    label: 'Capital Repairs'
                },
                'ENERGY_EFFICIENCY': {
                    label: 'Energy Efficiency'
                },
                'ADA_COMPLIANCE': {
                    label: 'ADA Compliance'
                }
            }, {}];
        });

        $httpBackend.whenGET(statusesRegex).respond((method, url, data) => {
            return [200, {
                'TODO': {
                    label: 'Todo'
                },
                'IMPLEMENTATION': {
                    label: 'Implementation'
                },
                'MEASUREMENT': {
                    label: 'Measurement'
                },
                'COMPLETE': {
                    label: 'Complete'
                }
            }, {}];
        });
    });
});
