angular.module('aq.services.options', ['restangular']).factory('OptionsService', function (Restangular, $q) {

    var service: any = {};
    var options = Restangular.all('options');
    var allUnits = [];
    var unitEnumMap = {};
    var optionsResponse = {};
    var initDefer = $q.defer();
    var initPromise = initDefer.promise;

    options.addRestangularMethod('all', 'get', 'allEnums');

    service.init = function (accountId, measurementSystemEnum, currencyUnitEnum) {
        service.accountId = accountId;
        if (measurementSystemEnum) {
            service.measurementSystemEnum = measurementSystemEnum;
        }
        if (currencyUnitEnum) {
            service.currencyUnitEnum = currencyUnitEnum;
        }
        initDefer.resolve();
        return service.fetchAll();
    }

    service.timezones = function () {
        return Restangular.one('accounts', service.accountId).customGET('queryAccountTimezones').then(function (timezones) {
            return _.chain(timezones)
                .map(function (tz) {
                    return {
                        name: tz.timezoneName,
                        fullName: tz.fullName,
                        title: tz.timezoneName + ' (' + tz.type + ')',
                        label: tz.type,
                        offset: -tz.offset / 60000,
                        modelId: tz.modelId,
                        priority: tz.priority
                    };
                })
                .sortBy(function (tz) {
                    return Math.sin(tz.priority);
                })
                .uniq(function (tz) {
                    return `${tz.type}|${tz.name}`;
                })
                .value();
        });
    };

    service.units = function (allRealUnits) {
        var unitsRoute = 'queryRealUnits';
        if (allRealUnits) {
            unitsRoute = 'queryAllRealUnits';
        }
        return Restangular.one('accounts', service.accountId).customGET(unitsRoute).then(function (results) {
            allUnits = results;
            allUnits.push({
                value: '"kWhCalculated"',
                unit: 'kWhCalculated',
                conversionMultiplier: 0.001,
                converted: true,
                apiUnit: 'energy'
            }, {
                    value: 'WhCalculated',
                    unit: 'WhCalculated',
                    conversionMultiplier: 1,
                    converted: true,
                    apiUnit: 'energy'
                });

            _.each(allUnits, function (unit) {
                unitEnumMap[unit.value.toLowerCase()] = unit;
                unit.getTotalUnit = function () {
                    if (unit.value.indexOf('CURRENCY') === 0) {
                        return service.findUnitByApiUnit(unit.noIntensityUnit, unit.apiUnit);
                    }
                    return service.getUnitLabelByMeasure(unit.serviceType);
                }
                unit.getNoIntensityUnit = function () {
                    var found = service.findUnitByApiUnit(unit.noIntensityUnit, unit.apiUnit);
                    return found ? found : unit;
                }
                unit.isElectricity = function () {
                    return unit.serviceType === 'ELECTRICITY';
                }
            });
        })
    }

    service.fetch = function (what, params) {
        options.addRestangularMethod(what, 'getList', what);
        return options[what](params);
    };

    service.fetchAll = function (allRealUnits) {
        return initPromise.then(function() {
            return $q.all([
                service.units(allRealUnits),
                Restangular.one('options', 'allEnums').get().then(function (options) {
                    // TODO don't modify inner state on 'getter' call
                    optionsResponse = options;
                    service.measurementSystem = _.find(options.measurementSystems, {'value': service.measurementSystemEnum});

                    options.sendTypes = _.filter(options.sendTypes, function (type) {
                        return type.label !== 'Sms';
                    });
                    return options;
                })
            ]).then(function(result) {
                return result[1];
            });
        });
    };

    service.getAllUnits = function () {
        return allUnits;
    };

    service.getOptions = function () {
        return optionsResponse;
    };

    service.getUnitByEnumName = function (name) {
        return unitEnumMap[name.toLowerCase()];
    }

    service.getUserDefaultUnit = function (user, availableTypes) {
        var defaultUnit = _.find(allUnits, function (unit) {
            return unit.value === user.defaultMetric;
        });
        if (availableTypes && availableTypes.length > 0 && !_.includes(availableTypes, defaultUnit.serviceType)) {
            defaultUnit = _.find(allUnits, function (unit) {
                if (availableTypes[0] === 'WATER') {
                    return unit.value === "WATER_GAL" || unit.value === "WATER_M3";
                } else {
                    return unit.serviceType === availableTypes[0];
                }
            });
        }
        return defaultUnit;
    }


    /**
     * Units that will be returned dynamically based on measurement system
     * @returns {*}
     */
    service.areaMeasurementUnit = function () {
        return service.measurementSystem !== undefined ? _.find(allUnits, { value: service.measurementSystem.areaMeasurementUnit }) : '';
    }

    service.waterUnit = function () {
        return service.measurementSystem !== undefined ? _.find(allUnits, { value: service.measurementSystem.waterUnit }) : '';
    }

    service.gasUnit = function () {
        return service.measurementSystem !== undefined ? _.find(allUnits, { value: service.measurementSystem.gasUnit }) : '';
    }

    service.steamUnit = function () {
        return service.measurementSystem !== undefined ? _.find(allUnits, { value: service.measurementSystem.steamUnit }) : '';
    }

    service.heatUnit = function () {
        return service.measurementSystem !== undefined ? _.find(allUnits, { value: service.measurementSystem.heatUnit }) : '';
    }

    service.co2Unit = function () {
        return service.measurementSystem !== undefined ? _.find(allUnits, { value: service.measurementSystem.co2Unit }) : '';
    }

    service.humidityUnit = function () {
        return service.measurementSystem !== undefined ? _.find(allUnits, { value: service.measurementSystem.humidityUnit }) : '';
    }

    service.energyUseIntensityUnit = function () {
        return service.measurementSystem !== undefined ? _.find(allUnits, { value: service.measurementSystem.energyUseIntensityUnit }) : '';
    }

    service.temperatureUnit = function () {
        return service.measurementSystem !== undefined ? _.find(allUnits, { value: service.measurementSystem.temperatureUnit }) : '';
    }

    service.currencyUnit = function () {
        return service.currencyUnitEnum !== undefined ? _.find(service.getOptions().currencyUnits, { value: service.currencyUnitEnum }) : '';
    }

    service.getUnitLabelByMeasure = function (measure) {
        switch (measure) {
            case 'ELECTRICITY':
                return _.find(allUnits, { value: 'KWH' });
            case 'WATER':
                return service.waterUnit();
            case 'GAS':
                return service.gasUnit();
            case 'POWER':
                return _.find(allUnits, { value: 'KW' });
            case 'TEMPERATURE':
                return service.temperatureUnit();
            case 'STEAM':
                return service.steamUnit();
            case 'HEAT':
                return service.heatUnit();
            case 'CO2':
                return service.co2Unit();
            case 'HUMIDITY':
                return service.humidityUnit();
            default:
                return _.find(allUnits, { value: 'KWH' });
        }
    }

    // it is not safe to search only by unit, because one unit can be used for more then one (for example M3 is used in water and gas)
    // but in case apiUnit is not supplied we will exclude this check, so we can support old emails that don't have this parameter in URL
    service.findUnitByApiUnit = function(unit, apiUnit) {
        return _.find(allUnits, function(realUnit) {
            return realUnit.unit.toLowerCase() === unit.toLowerCase() &&
                (!apiUnit || realUnit.apiUnit.toLowerCase() === apiUnit.toLowerCase());
        });
    }

    return service;
});
