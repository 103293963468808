var aq;
(function (aq) {
    var tenantbilling;
    (function (tenantbilling) {
        var AddEditTenantGroupCtrl = /** @class */ (function (_super) {
            __extends(AddEditTenantGroupCtrl, _super);
            /* @ngInject */
            function AddEditTenantGroupCtrl($mdDialog, tenantGroup, tenantGroups, tenants, Auth, TenantBillingSetupService) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.tenantGroup = tenantGroup;
                _this.tenantGroups = tenantGroups;
                _this.tenants = tenants;
                _this.Auth = Auth;
                _this.TenantBillingSetupService = TenantBillingSetupService;
                return _this;
            }
            AddEditTenantGroupCtrl.prototype.cancel = function (data) {
                this.$mdDialog.cancel();
            };
            AddEditTenantGroupCtrl.prototype.hide = function (data) {
                this.$mdDialog.hide(data);
            };
            AddEditTenantGroupCtrl.prototype.saveTenantGroup = function () {
                var _this = this;
                var action;
                if (this.tenantGroup.id) {
                    action = this.TenantBillingSetupService.updateTenantGroup(this.tenantGroup);
                }
                else {
                    action = this.TenantBillingSetupService.createTenantGroup(this.tenantGroup);
                }
                action.then(function (result) {
                    _this.hide(result);
                });
            };
            return AddEditTenantGroupCtrl;
        }(aq.common.Controllers.ModalCtrl));
        tenantbilling.AddEditTenantGroupCtrl = AddEditTenantGroupCtrl;
        angular.module('tenantBilling').controller('AddEditTenantGroupCtrl', AddEditTenantGroupCtrl);
    })(tenantbilling = aq.tenantbilling || (aq.tenantbilling = {}));
})(aq || (aq = {}));
