namespace aq.propertySettings {
    export class AddEditScheduleEvent extends aq.common.Controllers.ModalCtrl {
        public isActiveHours: boolean;
        public isDateInterval: boolean;
        public isAllDay: boolean;
        public isAllYear: boolean;
        public weekdaySelectionModel: any[];
        public timeOptions: string[];
        public dateStartView: Date;
        public dateEndView: Date;
        /* @ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private scheduleEvent: common.models.BuildingScheduleEvent,
            private buildingSchedule,
            private ruleCategories,
            private Messages: aq.services.Messages,
            private RestangularV3: restangular.IService,
            private BuildingScheduleHelperService: BuildingScheduleHelperService,
            private isReadonly: boolean
        ) {
            super({}, $mdDialog);
            this.initView();
        }
        public saveEvent() {
            this.processViewModel();
            if (this.scheduleEvent.id) {
                this.RestangularV3.one('calendars', this.buildingSchedule.id).one('rules', this.scheduleEvent.id).customPUT(this.scheduleEvent, '').then((result) => {
                    _.extend(result, result.model);
                    this.Messages.success('Successfully saved schedule event');
                    this.hide(result);
                });
            } else {
                this.RestangularV3.one('calendars', this.buildingSchedule.id).all('rules').customPOST(this.scheduleEvent, '').then((result) => {
                    _.extend(result, result.model);
                    this.Messages.success('Successfully saved schedule event');
                    this.hide(result);
                });
            }
        }
        public cancel(): void {
            this.$mdDialog.cancel();
        }
        public hide(data): void {
            this.$mdDialog.hide(data);
        }
        public initView() {
            this.timeOptions = this.BuildingScheduleHelperService.getTimeOptions();

            this.weekdaySelectionModel = angular.copy(this.BuildingScheduleHelperService.daysOfWeek);
            const selectedDays = this.scheduleEvent.id
                ? this.scheduleEvent.week
                : this.BuildingScheduleHelperService.workDays;
            _.forEach(selectedDays, (selectedDay) => {
                const day = _.find(this.weekdaySelectionModel, (dd) => dd.day == selectedDay);
                if (day) { day.selected = true; }
            });

            if (this.scheduleEvent.dateStart) {
                this.dateStartView = new Date(this.scheduleEvent.dateStart);
            }
            if (this.scheduleEvent.dateEnd) {
                this.dateEndView = new Date(this.scheduleEvent.dateEnd);
            }

            if (!this.scheduleEvent.id) {
                this.scheduleEvent.timeStart = '09:00:00';
                this.scheduleEvent.timeEnd = '17:00:00';
            }

            this.isAllYear = this.scheduleEvent.id
                ? !this.scheduleEvent.dateStart && !this.scheduleEvent.dateEnd
                : true;
            this.isAllDay = this.scheduleEvent.id
                ? !this.scheduleEvent.timeStart || !this.scheduleEvent.timeEnd
                : false;
        }
        public getHumanReadableEventDescription() {
            return this.BuildingScheduleHelperService.getHumanReadableEventDescription(
                this.getSelectedWeekdays(),
                this.isAllDay ? '' : this.scheduleEvent.timeStart,
                this.isAllDay ? '' : this.scheduleEvent.timeEnd,
                this.isAllYear ? '' : moment(this.dateStartView).format('YYYY-MM-DD'),
                this.isAllYear ? '' : moment(this.dateEndView).format('YYYY-MM-DD')
            );
        }
        private processViewModel() {
            this.scheduleEvent.week = this.getSelectedWeekdays();
            if (this.isAllDay) {
                this.scheduleEvent.timeStart = null;
                this.scheduleEvent.timeEnd = null;
            }
            if (this.isAllYear) {
                this.scheduleEvent.dateStart = null;
                this.scheduleEvent.dateEnd = null;
            } else {
                this.scheduleEvent.dateStart = moment(this.dateStartView).format('YYYY-MM-DD');
                this.scheduleEvent.dateEnd = moment(this.dateEndView).format('YYYY-MM-DD');
            }
        }
        private getSelectedWeekdays(): string[] {
            const selectedModels = _.filter(this.weekdaySelectionModel, (weekday) => weekday.selected == true);
            return _.map(selectedModels, (selected) => selected.day);
        }
        private capitalize(day: string) {
            return this.BuildingScheduleHelperService.capitalizeString(day);
        }
    }
    angular.module('properties').controller('AddEditScheduleEvent', AddEditScheduleEvent);
}
