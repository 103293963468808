var aq;
(function (aq) {
    var publicDisplay;
    (function (publicDisplay) {
        var PublicDisplayConfigurableCtrl = /** @class */ (function (_super) {
            __extends(PublicDisplayConfigurableCtrl, _super);
            /* @ngInject */
            function PublicDisplayConfigurableCtrl($scope, $rootScope, Messages, Segment, UserService, Errors, dashboard, $mdDialog, $stateParams, $location, $interval, $timeout, account, loading, AppStorageService, Auth, $state, Restangular) {
                var _this = _super.call(this, $scope, $rootScope, Messages, Segment, UserService, Errors, dashboard, $mdDialog, $stateParams, $location, $interval, $timeout, loading, AppStorageService, Auth, account, $state, Restangular) || this;
                _this.$scope = $scope;
                _this.$rootScope = $rootScope;
                _this.Messages = Messages;
                _this.Segment = Segment;
                _this.UserService = UserService;
                _this.Errors = Errors;
                _this.dashboard = dashboard;
                _this.$mdDialog = $mdDialog;
                _this.$stateParams = $stateParams;
                _this.$location = $location;
                _this.$interval = $interval;
                _this.$timeout = $timeout;
                _this.account = account;
                _this.loading = loading;
                _this.AppStorageService = AppStorageService;
                _this.Auth = Auth;
                _this.$state = $state;
                _this.Restangular = Restangular;
                $rootScope.$navigationState.currentState.data = {
                    breadcrumb: _this.$scope.name ? _this.$scope.name : 'Choose Display'
                };
                return _this;
            }
            return PublicDisplayConfigurableCtrl;
        }(aq.dashboard.ConfigurableCtrl));
        publicDisplay.PublicDisplayConfigurableCtrl = PublicDisplayConfigurableCtrl;
        angular.module('aq.publicDisplay').controller('PublicDisplayConfigurableCtrl', PublicDisplayConfigurableCtrl);
    })(publicDisplay = aq.publicDisplay || (aq.publicDisplay = {}));
})(aq || (aq = {}));
