'use strict';

angular.module('properties').directive('aqFocusOnClick', function () {
    return function (scope, elem, attrs) {
        elem.bind('click', function () {
            elem.focus();
            elem.blur();
        });
    };
} as any);
