namespace aq.utilityBudgets {
    export class BudgetHomeCtrl {
        public selectedType: string;
        public totalSpending: number;
        public totalProjection: number;
        public totalBills: number;
        public projectionConfidencePercent: number;
        public utilityBudgetDashboard: any;

        /* ngInject */
        constructor(
            private account: aq.common.models.Account,
            private building: aq.common.models.Building,
            private budgetMeasure: string,
            private measures: aq.common.models.Measure[],
            private $state: ng.ui.IStateService,
            private $timeout: ng.ITimeoutService,
            private $scope: ng.IScope
        ) {
            this.init();
            this.initDashboardWidgets();
            this.$timeout(() => this.$scope.$broadcast('highchartResize'), 200);
        }
        public init() {
            this.selectedType = this.budgetMeasure;
        }
        public initDashboardWidgets() {
            this.utilityBudgetDashboard = {
                editable: false,
                hideBorders: false,
                isCompact: false,
                structure: '33-66',
                rows: [
                    {
                        columns: [
                            {
                                flex: '33',
                                cid: '1',
                                widgets: [
                                    {
                                        type: 'insight',
                                        config: {
                                            insight: 1,
                                            buildingId: this.building.id,
                                            displayAs: 'Target',
                                            measure: this.selectedType.toUpperCase(),
                                            period: 'This Month',
                                            isTargetSameWindow: true
                                        },
                                        loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
                                        wid: '1-1'
                                    }
                                ]
                            },
                            {
                                flex: '66',
                                cid: '2',
                                widgets: [
                                    {
                                        type: 'expense',
                                        config: {
                                            buildingId: this.building.id,
                                            showTitle: true,
                                            measure: this.selectedType.toUpperCase()
                                        },
                                        loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
                                        wid: '2-1'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            };
        }
        public onChangeType() {
            this.$state.go(this.$state.current.name, {
                measure: this.selectedType
            });
        }
    }
    angular.module('aq.utilityBudgets').controller('BudgetHomeCtrl', BudgetHomeCtrl);
}
