angular.module('aq.filters')
    .filter('humanizeDurationDetailed', () => {
        // displays only two most significant units, i.e. "5 days 2 hours", "4 hours 15 mins", "10 mins 5 secs"
        return (totalSeconds) => {
            let timeLevel = '';
            const seconds = totalSeconds % 60;
            const totalMinutes = Math.round((totalSeconds - seconds) / 60);
            const minutes = totalMinutes % 60;
            const totalHours = Math.round((totalMinutes - minutes) / 60);
            const hours = totalHours % 24;
            const totalDays = Math.round((totalHours - hours) / 24);
            let time = '';
            if (totalDays > 0) {
                time = `${totalDays} days`;
                timeLevel = 'D';
            }
            if (hours > 0 || timeLevel == 'D') {
                if (timeLevel == 'D') {
                    time = `${time} ${hours} hours`;
                } else if (!timeLevel) {
                    time = `${hours} hours`;
                    timeLevel = 'H';
                }
            }
            if (minutes > 0 || timeLevel == 'H') {
                if (timeLevel == 'H') {
                    time = `${time} ${minutes} mins`;
                } else if (!timeLevel) {
                    time = `${minutes} mins`;
                    timeLevel = 'M';
                }
            }
            if (seconds > 0 || timeLevel == 'M') {
                if (timeLevel == 'M') {
                    time = `${time} ${seconds} secs`;
                } else if (!timeLevel) {
                    time = `${seconds} secs`;
                }
            }
            return time ? time : 'N/A';
        };
    });
