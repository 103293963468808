/* eslint-disable max-len */

namespace aq.reports {
    export class PassThroughSummaryCtrl extends BaseDocraptorReportCtrl {
        public formattedCharges;
        public currencyUnit;
        public remitAddress;

        /* @ngInject */
        constructor(
            private billing: aq.tenantbilling.MonthlyTenantBilling,
            private tenantChargeValues: aq.tenantbilling.TenantChargeValue[],
            private tenants,
            private setup: aq.tenantbilling.BillingSetup,
            private OptionsService,
            private building: aq.common.models.Building,
            private periodTenantServices,
            private tenantGroups,
            protected $timeout: ng.ITimeoutService
        ) {
            super($timeout);
            this.currencyUnit = OptionsService.currencyUnit();
            this.formattedCharges = this.getTenantChargesForTenantGroups();
            this.remitAddress = this.getRemitAddress();
            this.notifyDocumentReady();
        }

        public getTenantChargesForTenantGroups() {
            const chargesByTenantGroup = [];
            this.tenantGroups.forEach(group => {
                const groupCharges = this.getChargesForGroup(group);
                const groupObject = {
                    group,
                    charges: this.formatTenantChargesForGroup(groupCharges)
                };
                chargesByTenantGroup.push(groupObject);
            });
            return chargesByTenantGroup;
        }

        public getChargesForGroup(group) {
            return this.tenantChargeValues.filter(charge => _.includes(group.tenants, charge.tenantId));
        }

        public formatTenantChargesForGroup(groupTenantsCharges) {
            const serviceCharges = [];
            const uniqueServices = this.getUniqueServices(groupTenantsCharges);
            uniqueServices.forEach(serviceType => {
                const chargesForServiceByTenant = this.getChargesForServiceByTenant(serviceType, groupTenantsCharges);
                const columnTotals = this.getColumnTotalsForService(chargesForServiceByTenant);
                const periodTenantServicesForService = this.getPeriodTenantServicesForService(serviceType);
                const tenantCharges = {
                    serviceType,
                    tenantCharges: _.orderBy(chargesForServiceByTenant, ['name'], ['asc']),
                    columnTotals,
                    periodTenantServicesForService,
                };
                serviceCharges.push(tenantCharges);
            });
            return serviceCharges;
        }

        public getUniqueServices(groupTenantsCharges) {
            return _.uniq(groupTenantsCharges.filter(charge => charge.type === 'Metered').map(charge => charge.serviceType));
        }

        public getChargesForServiceByTenant(serviceType, groupTenantsCharges) {
            const returnvalue = [];
            const tenantChargesForServiceType = _.filter(groupTenantsCharges, ['serviceType', serviceType]);
            this.tenants.forEach(tenant => {
                const chargesForTenant = _.filter(tenantChargesForServiceType, ['tenantName', tenant.name]);
                if (chargesForTenant.length > 0) {
                    const totalConsumption = chargesForTenant.reduce((acc, charge) => acc + charge.appliedTotal, 0);
                    const meteredCharge = chargesForTenant.reduce((acc, charge) => acc + charge.cost, 0);
                    const meterFees = this.getMeterFeesForTenantAndServiceType(tenant, chargesForTenant);
                    const tenantCharge = {
                        name: tenant.name,
                        leaseId: tenant.accountNumber,
                        usageUnit: chargesForTenant[0].usageUnit,
                        totalConsumption,
                        meteredCharge,
                        meterFees,
                        otherFees: 0,
                        taxes: 0,
                        totalCharge: 0
                    };
                    tenantCharge.otherFees = this.getOtherFeesForTenantAndServiceType(tenantCharge);
                    tenantCharge.taxes = this.getTaxesForTenantAndServiceType(tenantCharge);
                    tenantCharge.totalCharge = _.sum([tenantCharge.meteredCharge, tenantCharge.meterFees, tenantCharge.otherFees, tenantCharge.taxes]);
                    returnvalue.push(tenantCharge);
                }
            });
            return returnvalue;
        }

        public getMeterFeesForTenantAndServiceType(tenant, chargesForTenant): number {
            const totalMeters = _.uniq(chargesForTenant.map(charge => charge.collector));
            const meterFeesForTenant = _.filter(this.tenantChargeValues, {
                tenantName: tenant.name,
                feeType: 'PER_METER'
            });
            const totalFeePerMeter = meterFeesForTenant.reduce((acc, charge) => acc + charge.costPerUnit, 0);
            return totalFeePerMeter * totalMeters.length;
        }

        public getOtherFeesForTenantAndServiceType(tenantCharge): number {
            const usageFees = _.filter(this.tenantChargeValues, {
                tenantName: tenantCharge.name,
                feeType: 'PCT_METERED_USAGE'
            });
            return _.sum(usageFees.map(fee => {
                return fee.costPerUnit * tenantCharge.meterFees;
            }));
        }

        public getTaxesForTenantAndServiceType(tenantCharge): number {
            const taxesForTenant = _.filter(this.tenantChargeValues, {tenantName: tenantCharge.name, type: 'Tax'});
            return _.sum(taxesForTenant.map(fee => {
                const totalChargesForTenant = _.sum([tenantCharge.meteredCharge, tenantCharge.meterFees, tenantCharge.otherFees]);
                return (fee.costPerUnit / 100) * totalChargesForTenant;
            }));
        }

        public getColumnTotalsForService(chargesForServiceByTenant) {
            return {
                consumptionTotal: chargesForServiceByTenant.reduce((acc, charge) => acc + charge.totalConsumption, 0),
                meteredChargeTotal: chargesForServiceByTenant.reduce((acc, charge) => acc + charge.meteredCharge, 0),
                meterFeesTotal: chargesForServiceByTenant.reduce((acc, charge) => acc + charge.meterFees, 0),
                otherFeesTotal: chargesForServiceByTenant.reduce((acc, charge) => acc + charge.otherFees, 0),
                taxesTotal: chargesForServiceByTenant.reduce((acc, charge) => acc + charge.taxes, 0),
                totalChargeTotal: chargesForServiceByTenant.reduce((acc, charge) => acc + charge.totalCharge, 0),
                usageUnit: chargesForServiceByTenant[0].usageUnit
            };
        }

        public getRemitAddress() {
            const remittanceAddresses = this.tenants.map(tenant => tenant.remittanceAddress).filter(address => address != null);
            if (remittanceAddresses.length > 0) {
                const uniqAddress = _.uniqBy(remittanceAddresses, 'address');
                return uniqAddress[0];
            }
            return null;
        }

        public getPeriodTenantServicesForService(serviceType) {
            const tenantServicesForService = _.filter(this.periodTenantServices, ['serviceType', serviceType]);
            return {
                meteredCharges: _.filter(tenantServicesForService, ['method', 'METERED_USAGE']),
                fees: tenantServicesForService.filter(service => service.method === 'FEE' && service.usageUnit !== 'metered usage'),
                percentMeterUsageFees: _.filter(this.periodTenantServices, {method: 'FEE', usageUnit: 'metered usage'}),
                taxes: _.filter(this.periodTenantServices, ['method', 'TAX'])
            };
        }

        public generateInvoiceNumber(billingId, groupId, serviceType): string {
            const billingSubStr = billingId.toString().substring(billingId.toString().length - 3, billingId.toString().length);
            const groupSubStr = groupId.toString().substring(groupId.toString().length - 3, groupId.toString().length);
            const serviceSubStr = serviceType.slice(0, 1);
            return `${billingSubStr}${groupSubStr}${serviceSubStr}`;
        }
    }

}
angular
    .module('aq.reports')
    .controller('PassThroughSummaryCtrl', aq.reports.PassThroughSummaryCtrl);
