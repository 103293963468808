namespace aq.dashboard.widgets {
    export interface ExpenseConfig {
        buildingId: string;
        isCustomTitle: boolean;
        showTitle: boolean;
        title: string;
        subtitle: string
        when: string;
        showBuildingName: boolean;
        measure: string;
    }
    export interface BaseCostBreakdownItem {
        demand: number;
        consumption: number;
        tax: number;
        other: number;
        adjustment: number;
        lateFee: number;
        cost: number;
        unitSymbol: string;
        blendedRate: number;
        billingPeriod: string;
    }
    export interface CostBreakdownItem extends BaseCostBreakdownItem {
        serviceId: number;
        name: string;
    }
    export interface ServiceUtilityBillAnalysisData extends aq.utilityBudgets.UtilityBillAnalysisData {
        serviceId: number;
        serviceName: string;
        serviceAccount: string;
    }

    export class ExpenseCtrl {
        public building: aq.common.models.Building;
        public startDate: moment.Moment;
        public costBreakdownItems: CostBreakdownItem[];
        public costTotals: BaseCostBreakdownItem;
        public costBreakdownChart: __Highcharts.AxisOptions;
        public colors: string[];
        public isDataPresent: boolean;
        public periodType: string;
        public readonly periodLabels = {
            lastBillMonth: 'Last Utility Bill(s)',
            month: 'This Month',
            quarter: 'This Quarter',
            year: 'This Fiscal Year'
        };
        public currencySymbol = '$';
        public billInterval: {
            startDate: moment.Moment;
            endDate: moment.Moment;
        };
        private isLoadingData: boolean;
        private isBuildingInSetup: boolean;

        /* @ngInject */
        constructor(
            private buildings: aq.common.models.Building[],
            private account,
            private config: ExpenseConfig,
            private $translate: any,
            private $filter: ng.IFilterService,
            private WidgetHelperService,
            private RestangularV3: restangular.IService,
            private $q: ng.IQService,
            private measureUnitMap: { [measure: string]: string }
        ) {
            this.periodType = 'lastBillMonth';
            this.init();
        }
        public init() {
            this.colors = Highcharts.getOptions().colors;
            this.building = _.find(this.buildings, (b) => b.id == this.config.buildingId);
            this.isBuildingInSetup = this.WidgetHelperService.isBuildingInSetup(this.building);
            this.config.title = this.$translate.instant('budgets.Your Utility Expenses');
            if (this.periodType === 'lastBillMonth'){
                this.config.subtitle = this.$translate.instant('budgets.Your Most Recent Bill');
            } else {
                this.config.subtitle = null;
            }
            this.isLoadingData = true;

            if (this.periodType === 'month' || this.periodType === 'quarter' || this.periodType === 'year') {
                this.getCalendarizedUtilityBillData()
            } else {
                this.getLastUtilityBillData();
            }
        }
        public getLastUtilityBillData() {
            this.getUtilityServices()
                .then((services) => {
                    const promises = _.map(services, (service: aq.utilityBudgets.UtilityService) => {
                        return this.RestangularV3
                            .all('utility-bill-analysis')
                            .customGET('last-bill', {
                                buildingId: this.building.id,
                                measure: this.config.measure,
                                utilityServiceId: service.id
                            })
                            .then((data: ServiceUtilityBillAnalysisData) => {
                                data.serviceId = service.id as any;
                                data.serviceName = service.nameAbbreviated;
                                data.serviceAccount = service.account;
                                return data;
                            })
                            .catch(() => {
                                return {
                                    serviceId: service.id,
                                    serviceName: service.nameAbbreviated,
                                    serviceAccount: service.account,
                                    billing: {
                                        blendedRate: null,
                                        data: [],
                                        unitSymbol: '$'
                                    }
                                };
                            });
                    });
                    return this.$q.all<ServiceUtilityBillAnalysisData>(promises);
                })
                .then((response: ServiceUtilityBillAnalysisData[]) => {
                    this.billInterval = this.getBillsDateInterval(response);
                    this.config.when = this.getFormattedTimeInterval(this.billInterval.startDate, this.billInterval.endDate);
                    this.buildViewItems(response);
                    this.isDataPresent = _.some(this.costBreakdownItems, (item: CostBreakdownItem) => {
                        return item.consumption > 0 || item.demand > 0 || item.tax > 0;
                    });
                    if (this.isDataPresent) {
                        this.buildCostBreakdownBar();
                    }
                })
                .finally(() => {
                    if (this.isBuildingInSetup && this.isDataPresent) {
                        this.isBuildingInSetup = false;
                    }
                    this.isLoadingData = false;
                });
        }
        public getCalendarizedUtilityBillData() {
            this.startDate = this.getStartDate();
            const periodSearch = this.getPeriodSearch();
            if (!this.config.when) {
                const initialDate = this.isBuildingInSetup ? moment() : null;
                this.config.when = this.getFormattedTimeInterval(this.startDate, initialDate);
            }

            this.getUtilityServices()
                .then((services) => {
                    const promises = _.map(services, (service: aq.utilityBudgets.UtilityService) => {
                        return this.RestangularV3
                            .all('utility-bill-analysis')
                            .customGET('', {
                                buildingId: this.building.id,
                                start: periodSearch.start.format(`YYYY-MM-DDTHH:mm:ss.000Z`),
                                end: periodSearch.end.format(`YYYY-MM-DDTHH:mm:ss.000Z`),
                                measure: this.config.measure,
                                utilityServiceId: service.id
                            })
                            .then((data: ServiceUtilityBillAnalysisData) => {
                                data.serviceId = service.id as any;
                                data.serviceName = service.nameAbbreviated;
                                data.serviceAccount = service.account;
                                return data;
                            })
                            .catch(() => {
                                return {
                                    serviceId: service.id,
                                    serviceName: service.nameAbbreviated,
                                    serviceAccount: service.account,
                                    billing: {
                                        blendedRate: null,
                                        data: [],
                                        unitSymbol: '$'
                                    }
                                };
                            });
                    });
                    return this.$q.all<ServiceUtilityBillAnalysisData>(promises);
                })
                .then((response: ServiceUtilityBillAnalysisData[]) => {
                    this.billInterval = this.getBillsDateInterval(response);
                    this.config.when = this.getFormattedTimeInterval(this.billInterval.startDate, this.billInterval.endDate);
                    this.buildViewItems(response);
                    this.isDataPresent = _.some(this.costBreakdownItems, (item: CostBreakdownItem) => {
                        return item.consumption > 0 || item.demand > 0 || item.tax > 0;
                    });
                    if (this.isDataPresent) {
                        this.buildCostBreakdownBar();
                    }
                })
                .finally(() => {
                    if (this.isBuildingInSetup && this.isDataPresent) {
                        this.isBuildingInSetup = false;
                    }
                    this.isLoadingData = false;
                });
        }
        public getFormattedTimeInterval(startDate: moment.Moment, endDate: moment.Moment) {
            const testEndDate = endDate || moment();
            const dateFormat = startDate.isSame(testEndDate, 'year') ? 'MMM D' : 'MMM D, YYYY';
            const startDateFormatted = startDate.format(dateFormat);
            const endDateFormatted = endDate ? endDate.format(dateFormat) : '';
            return `${startDateFormatted} - ${endDateFormatted}`;
        }
        public getUtilityServices() {
            return this.RestangularV3.all('utility-services')
                .getList({ buildingId: this.building.id })
                .then((response) => {
                    return _.filter(response, (item) => item.type.toLowerCase() == this.config.measure.toLowerCase());
                })
                .catch(() => {
                    return [];
                });
        }
        public getBillsDateInterval(response: ServiceUtilityBillAnalysisData[]) {
            let endDateValue = null;
            let startDateValue = null;
            _.each(response, (dataItem: ServiceUtilityBillAnalysisData) => {
                if (!dataItem.billing) {
                    return;
                }
                _.each(dataItem.billing.data, (bill: aq.utilityBudgets.BillStatementDataItem) => {
                    const billStart = moment(bill.startTimestamp, 'YYYY-MM-DD');
                    const billEnd = moment(bill.endTimestamp, 'YYYY-MM-DD');
                    if (startDateValue == null || billStart.isBefore(startDateValue)) {
                        startDateValue = moment(billStart);
                    }
                    if (endDateValue == null || billEnd.isAfter(endDateValue)) {
                        endDateValue = moment(billEnd);
                    }
                });
            });
            if (endDateValue == null) {
                endDateValue = moment();
            }
            if (startDateValue == null) {
                startDateValue = moment(this.startDate);
            }
            return {
                startDate: moment(startDateValue),
                endDate: moment(endDateValue)
            };
        }
        public buildViewItems(response: ServiceUtilityBillAnalysisData[]) {
            this.costBreakdownItems = [];
            this.costTotals = {
                consumption: 0,
                demand: 0,
                tax: 0,
                other: 0,
                adjustment: 0,
                lateFee: 0,
                cost: 0,
                unitSymbol: null,
                blendedRate: null,
                billingPeriod: null
            };
            let totalEnergy = 0;
            _.each(response, (dataItem: ServiceUtilityBillAnalysisData) => {
                let demand = null;
                if (this.config.measure === 'ELECTRICITY') {
                    demand = _.sumBy(dataItem.billing.data, (d: aq.utilityBudgets.BillStatementDataItem) => d.datum.demandCharge || 0);
                }
                const item: CostBreakdownItem = {
                    serviceId: dataItem.serviceId,
                    name: dataItem.serviceName,
                    demand,
                    consumption: _.sumBy(dataItem.billing.data, (d: aq.utilityBudgets.BillStatementDataItem) => d.datum.usageCharge || 0),
                    tax: _.sumBy(dataItem.billing.data, (d: aq.utilityBudgets.BillStatementDataItem) => d.datum.taxCharge || 0),
                    other: _.sumBy(dataItem.billing.data, (d: aq.utilityBudgets.BillStatementDataItem) => d.datum.otherCharge || 0),
                    adjustment: _.sumBy(dataItem.billing.data, (d: aq.utilityBudgets.BillStatementDataItem) => d.datum.adjustmentCharge || 0),
                    lateFee: _.sumBy(dataItem.billing.data, (d: aq.utilityBudgets.BillStatementDataItem) => d.datum.lateFeeCharge || 0),
                    cost: _.sumBy(dataItem.billing.data, (d: aq.utilityBudgets.BillStatementDataItem) => d.datum.cost || 0),
                    blendedRate: dataItem.billing.blendedRate,
                    unitSymbol: dataItem.billing.unitSymbol,
                    billingPeriod: '-'
                };
                if (dataItem.billing.data.length > 0) {
                    const minItem = _.minBy(dataItem.billing.data, (d: aq.utilityBudgets.BillStatementDataItem) => d.startTimestamp);
                    const maxItem = _.maxBy(dataItem.billing.data, (d: aq.utilityBudgets.BillStatementDataItem) => d.endTimestamp);
                    const startDate = moment(minItem.startTimestamp, 'YYYY-MM-DD');
                    const endDate = moment(maxItem.endTimestamp, 'YYYY-MM-DD');
                    item.billingPeriod = this.getFormattedTimeInterval(startDate, endDate);
                }
                this.costBreakdownItems.push(item);
                this.costTotals.consumption += item.consumption;
                this.costTotals.demand += item.demand;
                this.costTotals.tax += item.tax;
                this.costTotals.adjustment += item.adjustment;
                this.costTotals.other += item.other;
                this.costTotals.lateFee += item.lateFee;
                this.costTotals.cost += item.cost;
                if (!this.costTotals.unitSymbol) {
                    this.costTotals.unitSymbol = item.unitSymbol;
                }
                let itemCharge = item.consumption + item.tax;
                if (this.config.measure === 'ELECTRICITY') {
                    itemCharge += item.demand;
                }
                if (itemCharge > 0 && item.blendedRate > 0) {
                    totalEnergy += itemCharge / item.blendedRate;
                }
            });
            let totalCharge = this.costTotals.consumption + this.costTotals.tax;
            if (this.config.measure === 'ELECTRICITY') {
                totalCharge += this.costTotals.demand;
            }
            if (totalEnergy > 0 && totalCharge > 0) {
                this.costTotals.blendedRate = totalCharge / totalEnergy;
            }
        }
        public getSumForField(field): number {
            return Math.round(
                _.sumBy(this.costBreakdownItems, (item: CostBreakdownItem) => item[field])
            );
        }
        private getStartDate() {
            let startDate = null;
            switch (this.periodType) {
                case 'month':
                    startDate = moment().startOf('month');
                    break;
                case 'quarter':
                    startDate = this.getFiscalYearStart();
                    while (moment().isAfter(moment(startDate).add(3, 'month'))) {
                        startDate.add(3, 'month');
                    }
                    break;
                case 'year':
                default:
                    startDate = this.getFiscalYearStart();
            }
            return startDate;
        }
        private getFiscalYearStart() {
            const startMonth = this.building.fiscalStartMonth ? this.building.fiscalStartMonth : 1;
            const currentMonth = moment().month() + 1;
            let startYear = moment().year();
            if (currentMonth < startMonth) {
                startYear--;
            }
            return moment(`${startMonth} ${startYear}`, 'M YYYY');
        }
        private getPeriodSearch(): aq.energyInsights.PeriodSearch {
            const periodSearch = {
                start: moment(this.startDate).tz(this.building.timeZoneId),
                end: moment().tz(this.building.timeZoneId),
                interval: '1mon',
                label: this.periodLabels[this.periodType]
            };
            return periodSearch;
        }
        private buildCostBreakdownBar() {
            this.costBreakdownChart = this.buildChart();
        }
        private getChartSourceData(): {name: string, field: string}[] {
            return [
                {name: this.$translate.instant('budgets.Consumption'), field: 'consumption'},
                {name: this.$translate.instant('budgets.Demand'), field: 'demand'},
                {name: this.$translate.instant('budgets.Taxes and Fees'), field: 'tax'},
                {name: this.$translate.instant('budgets.Other'), field: 'other'},
                {name: this.$translate.instant('budgets.Late Fees'), field: 'lateFee'},
                {name: this.$translate.instant('budgets.Adjustments'), field: 'adjustment'}
            ];
        }
        private getIndividualSeries(seriesToAdd) {
            const {name, field} = seriesToAdd;
            const sum = this.getSumForField(field);
            return {
                name,
                data: [sum]
            };
        }
        private getSeries(): __Highcharts.SeriesOptions[] {
            const seriesToAdd = this.getChartSourceData();
            return seriesToAdd
                .map(value => this.getIndividualSeries(value))
                .filter(serie => serie.data[0] !== 0);
        }
        private getCategories(): string[] {
            return this.getSeries().map(value => value.name);
        }
        private getDataValues() {
            return this.getSeries().map(value => value.data[0]);
        }
        private buildChart() {
            const filter = this.$filter;
            return {
                legend: {
                    enabled: false
                },
                chart: {
                    height: 300,
                    animation: false,
                    backgroundColor: 'transparent',
                    plotBorderColor: '#DADADA',
                    plotBorderWidth: 1,
                    type: 'column'
                },
                title: {
                    text: null
                },
                xAxis: {
                    categories: this.getCategories(),
                    visible: true
                },
                yAxis: {
                    title: { text: ''},
                    softMin: 0,
                    plotLines: [
                        {
                            color: '#000000',
                            width: 3,
                            value: 0
                        }
                    ]
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true,
                            // tslint:disable-next-line:object-literal-shorthand
                            formatter: function() {
                                return  filter('currency')(this.y, '$', 0);
                            }
                        },
                        animation: false,
                        tooltip: {
                            valuePrefix: '$'
                        }
                    }
                },
                series: [{
                    name: 'Utility Expenses',
                    colorByPoint: true,
                    data: this.getDataValues()
                }],
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                tooltip: {
                    shared: false
                }
            };
        }
    }
    angular.module('aq.dashboard.widgets').controller('ExpenseCtrl', ExpenseCtrl);
}
