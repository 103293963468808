var aq;
(function (aq) {
    var reportCenter;
    (function (reportCenter) {
        var ReportParamsCtrl = /** @class */ (function (_super) {
            __extends(ReportParamsCtrl, _super);
            /* ngInject */
            function ReportParamsCtrl($mdDialog, $scope, buildingItems, reportName, monthlyReport, customDateReport) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.$scope = $scope;
                _this.buildingItems = buildingItems;
                _this.reportName = reportName;
                _this.monthlyReport = monthlyReport;
                _this.customDateReport = customDateReport;
                _this.today = moment().toDate();
                _this.selectedDate = _this.today;
                _this.endYear = moment().year();
                _this.endMonth = moment().month() + 1;
                _this.endDate = moment(_this.selectedDate).endOf('month').toDate();
                _this.startDate = moment(_this.selectedDate).startOf('month').toDate();
                _this.$scope.$watch(function () { return _this.selectedDate; }, function () {
                    if (_this.monthlyReport) {
                        _this.endDate = moment(_this.selectedDate).endOf('month').toDate();
                        _this.startDate = moment(_this.selectedDate).startOf('month').toDate();
                    }
                });
                return _this;
            }
            ReportParamsCtrl.prototype.onClickGenerateReport = function () {
                this.isGenerating = true;
                var reportParams = {
                    scheduledReportId: this.selectedReport ? this.selectedReport.scheduledReportId : null,
                    date: this.selectedDate ? moment(this.selectedDate).valueOf() : null,
                    startDate: this.startDate ? moment(this.startDate).valueOf() : null,
                    endDate: this.endDate ? moment(this.endDate).valueOf() : null
                };
                this.hide(reportParams);
            };
            ReportParamsCtrl.prototype.onSelectedBuildingChange = function (data) {
                this.selectedReport = data;
            };
            return ReportParamsCtrl;
        }(aq.common.Controllers.ModalCtrl));
        reportCenter.ReportParamsCtrl = ReportParamsCtrl;
        angular.module('reportCenter').controller('ReportParamsCtrl', ReportParamsCtrl);
    })(reportCenter = aq.reportCenter || (aq.reportCenter = {}));
})(aq || (aq = {}));
