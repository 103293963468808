var aq;
(function (aq) {
    var dashboard;
    (function (dashboard) {
        var widgets;
        (function (widgets) {
            var EnrollmentStatus;
            (function (EnrollmentStatus) {
                EnrollmentStatus["PLANNED"] = "PLANNED";
                EnrollmentStatus["IN_PROGRESS"] = "IN_PROGRESS";
                EnrollmentStatus["COMPLETE"] = "COMPLETE";
            })(EnrollmentStatus || (EnrollmentStatus = {}));
            var OnboardingWidgetCtrl = /** @class */ (function () {
                /* @ngInject */
                function OnboardingWidgetCtrl($scope, config, account, buildings, buildingGroups, ModelUtilService, BuildingSelectorActions, RestangularV3, $q, $mdDialog, $state, Messages, $mdMedia) {
                    var _this = this;
                    this.$scope = $scope;
                    this.config = config;
                    this.account = account;
                    this.buildings = buildings;
                    this.buildingGroups = buildingGroups;
                    this.ModelUtilService = ModelUtilService;
                    this.BuildingSelectorActions = BuildingSelectorActions;
                    this.RestangularV3 = RestangularV3;
                    this.$q = $q;
                    this.$mdDialog = $mdDialog;
                    this.$state = $state;
                    this.Messages = Messages;
                    this.$mdMedia = $mdMedia;
                    this.maxTasks = 4;
                    this.phases = [
                        'Setup', 'Connect', 'Verify', 'Train'
                    ];
                    $scope.$watch(function () { return _this.$mdMedia('xs') || _this.$mdMedia('sm') || _this.$mdMedia('gt-md'); }, function () {
                        var currentLayoutSize = 'medium';
                        if (_this.$mdMedia('xs') || _this.$mdMedia('sm')) {
                            currentLayoutSize = 'small';
                        }
                        if (_this.$mdMedia('gt-md')) {
                            currentLayoutSize = 'large';
                        }
                        if (currentLayoutSize !== _this.currentLayoutSize) {
                            switch (currentLayoutSize) {
                                case 'small':
                                    _this.maxTasks = 3;
                                    break;
                                case 'large':
                                    _this.maxTasks = 5;
                                    break;
                                case 'medium':
                                default:
                                    _this.maxTasks = 4;
                                    break;
                            }
                            if (_this.drillPhaseTasks) {
                                _this.displayDrillPhaseTasks = _.take(_this.drillPhaseTasks, _this.maxTasks);
                                _this.currentTaskIndex = 0;
                                _this.isNavigationVisible = _this.displayDrillPhaseTasks.length < _this.drillPhaseTasks.length;
                            }
                            _this.currentLayoutSize = currentLayoutSize;
                        }
                    });
                    this.config.options = {
                        buildings: this.ModelUtilService.pareProperties(this.buildings, ['buildingGroup']),
                        buildingGroups: this.ModelUtilService.pareProperties(this.buildingGroups)
                    };
                    this.BuildingSelectorActions.initDefaultBuildingSelection(this.config);
                    this.$scope.config = this.config;
                    this.$scope.config.actions = this.BuildingSelectorActions;
                    if (this.config.showBuildingImage == null) {
                        this.config.showBuildingImage = true;
                    }
                    this.isLoadingData = true;
                    this.isLoadingStaff = true;
                    this.loadingPercent = 0;
                    this.totalPromises = this.$scope.config.buildingIds.length;
                    this.completedPromises = 0;
                    this.buildingEnrollment = _.map(this.$scope.config.buildingIds, function (id) {
                        return { building: id, progress: 0 };
                    });
                    this.buildingPersonnels = {};
                    this.buildingPersonas = {};
                    this.buildingUsersMap = {};
                    this.initViewData();
                    this.loadBuildingEnrollment()
                        .then(function (data) {
                        _this.buildingEnrollment = data;
                        _this.initViewData();
                        _this.sortByTotalCompletion();
                        _this.sortAscending = true;
                        _this.isLoadingData = false;
                        return _this.getBuildingPersonnels();
                    })
                        .then(function (personaData) {
                        _this.initBuildingPersonas(personaData);
                        _this.isLoadingStaff = false;
                    });
                }
                OnboardingWidgetCtrl.prototype.loadBuildingEnrollment = function () {
                    var _this = this;
                    return this.$q.all(_.map(this.$scope.config.buildingIds, function (id) {
                        return _this.loadBuildingEnrollmentById(id)
                            .finally(function () {
                            _this.completedPromises++;
                            _this.loadingPercent = Math.round(_this.completedPromises * 100 / _this.totalPromises);
                        });
                    }));
                };
                OnboardingWidgetCtrl.prototype.loadBuildingEnrollmentById = function (id) {
                    return this.RestangularV3
                        .one('buildings', id)
                        .one('enrollment')
                        .get()
                        .then(function (response) {
                        return response || { building: id, progress: 0 };
                    });
                };
                OnboardingWidgetCtrl.prototype.initBuildingPersonas = function (data) {
                    var _this = this;
                    _.each(data, function (dataItem) {
                        if (!_this.buildingPersonnels[dataItem.buildingId]) {
                            _this.buildingPersonnels[dataItem.buildingId] = dataItem.personnel;
                        }
                        var personnel = _this.buildingPersonnels[dataItem.buildingId] || [];
                        var propertyManager = _.find(personnel, function (item) { return item.persona == 'PROPERTY_MANAGER'; });
                        var buildingEngineer = _.find(personnel, function (item) { return item.persona == 'BUILDING_ENGINEER'; });
                        _this.buildingPersonas[dataItem.buildingId] = {
                            propertyManager: propertyManager ? _this.getShortName(propertyManager.name) : '',
                            propertyManagerPhone: propertyManager ? propertyManager.phone : '-',
                            propertyManagerEmail: propertyManager ? propertyManager.email : '-',
                            buildingEngineer: buildingEngineer ? _this.getShortName(buildingEngineer.name) : '',
                            buildingEngineerPhone: buildingEngineer ? buildingEngineer.phone : '-',
                            buildingEngineerEmail: buildingEngineer ? buildingEngineer.email : '-'
                        };
                    });
                };
                OnboardingWidgetCtrl.prototype.getShortName = function (name) {
                    if (!name) {
                        return '';
                    }
                    var parts = name.split(' ');
                    if (parts.length == 1) {
                        return name;
                    }
                    if (parts.length < 2 || !parts[1].length) {
                        return '';
                    }
                    var shortName = parts[0] + " " + parts[1].substr(0, 1) + ".";
                    return shortName;
                };
                OnboardingWidgetCtrl.prototype.initViewData = function () {
                    var _this = this;
                    this.buildingEnrollmentView = _.map(this.buildingEnrollment, function (item) {
                        return _this.getBuildingEnrollmentViewItem(item);
                    });
                    this.calculatePhasesOverallProgress();
                    this.filteredBuildingEnrollmentView = this.buildingEnrollmentView;
                };
                OnboardingWidgetCtrl.prototype.getBuildingEnrollmentViewItem = function (item) {
                    var _this = this;
                    var building = _.find(this.buildings, function (b) { return b.id == item.building; });
                    var nextTask = null;
                    var viewPhases = _.map(this.phases, function (phaseName) {
                        var phase = _.find(item.phases, function (p) { return p.name.toLowerCase() == phaseName.toLowerCase(); });
                        if (!phase) {
                            return {
                                name: phaseName,
                                progress: 0,
                                viewTasks: [],
                                totalActiveTasks: 0,
                                nextTask: null
                            };
                        }
                        var viewPhase = _this.getPhaseView(phase);
                        if (nextTask == null) {
                            nextTask = viewPhase.nextTask;
                        }
                        return viewPhase;
                    });
                    return angular.extend(item, {
                        imageUrl: building ? building.imageUrl : null,
                        buildingName: building ? building.name : '',
                        viewPhases: viewPhases,
                        nextTask: nextTask
                    });
                };
                OnboardingWidgetCtrl.prototype.calculatePhasesOverallProgress = function () {
                    var _this = this;
                    this.overallPhaseProgress = {};
                    _.each(this.buildingEnrollmentView, function (item) {
                        _.each(item.viewPhases, function (viewPhase) {
                            _this.addOverallPhaseProgress(viewPhase);
                        });
                    });
                };
                OnboardingWidgetCtrl.prototype.getPhaseView = function (phase, recalcProgress) {
                    if (recalcProgress === void 0) { recalcProgress = false; }
                    var viewTasks = this.getTasksView(phase);
                    var completedTasks = _.filter(viewTasks, function (vt) { return vt.task.complete; }).length;
                    var totalActiveTasks = _.filter(viewTasks, function (vt) { return vt.task.status != 'IGNORED'; }).length;
                    if (phase.progress == null || recalcProgress) {
                        if (phase.status == EnrollmentStatus.COMPLETE && !recalcProgress) {
                            phase.progress = 100;
                        }
                        else {
                            if (totalActiveTasks != 0) {
                                phase.progress = Math.round(completedTasks * 100 / totalActiveTasks);
                            }
                            else {
                                phase.progress = viewTasks.length == 0 ? 0 : 100;
                            }
                        }
                    }
                    return angular.extend(phase, {
                        viewTasks: viewTasks,
                        completedTasks: completedTasks,
                        totalActiveTasks: totalActiveTasks,
                        nextTask: this.getNextTask(viewTasks)
                    });
                };
                OnboardingWidgetCtrl.prototype.navigateToPropertySettings = function (item, isPersonnel) {
                    if (isPersonnel === void 0) { isPersonnel = false; }
                    var state = isPersonnel ? 'aq.properties.buildings.form.personnel' : 'aq.properties.buildings.form.configuration';
                    this.$state.go(state, {
                        accountId: this.account.id,
                        buildingId: item.building
                    }, { reload: true });
                };
                OnboardingWidgetCtrl.prototype.onClickViewPhase = function (phaseName, buildingId, event) {
                    if (buildingId === void 0) { buildingId = null; }
                    if (event === void 0) { event = null; }
                    this.drillPhase = phaseName;
                    this.drillPhaseTasks = this.getAllDrillPhaseTasks(phaseName);
                    this.displayDrillPhaseTasks = _.take(this.drillPhaseTasks, this.maxTasks);
                    this.currentTaskIndex = 0;
                    this.isNavigationVisible = this.displayDrillPhaseTasks.length < this.drillPhaseTasks.length;
                    this.rebuildBuildingTaskMap();
                    if (buildingId) {
                        this.filteredBuildingEnrollmentView = _.filter(this.buildingEnrollmentView, function (item) {
                            return item.building == buildingId;
                        });
                        this.isBuildingDrill = true;
                    }
                    if (event) {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                };
                OnboardingWidgetCtrl.prototype.onSelectNextTask = function (offset) {
                    if (this.isTaskOffsetValid(offset)) {
                        this.currentTaskIndex += offset;
                        this.displayDrillPhaseTasks = this.drillPhaseTasks.slice(this.currentTaskIndex, this.currentTaskIndex + this.maxTasks);
                    }
                };
                OnboardingWidgetCtrl.prototype.isNextTaskDisabled = function (offset) {
                    var isValid = this.isTaskOffsetValid(offset);
                    return !isValid;
                };
                OnboardingWidgetCtrl.prototype.isTaskOffsetValid = function (offset) {
                    return this.currentTaskIndex + offset + this.maxTasks <= this.drillPhaseTasks.length && this.currentTaskIndex + offset >= 0;
                };
                OnboardingWidgetCtrl.prototype.getAllDrillPhaseTasks = function (phaseName) {
                    var taskNames = [];
                    _.each(this.buildingEnrollmentView, function (item) {
                        var phase = _.find(item.viewPhases, function (viewPhase) { return viewPhase.name == phaseName; });
                        if (!phase) {
                            return;
                        }
                        _.each(phase.viewTasks, function (viewTask) {
                            if (!_.find(taskNames, function (taskName) { return viewTask.task.name == taskName; })) {
                                taskNames.push(viewTask.task.name);
                            }
                        });
                    });
                    return taskNames;
                };
                OnboardingWidgetCtrl.prototype.rebuildBuildingTaskMap = function () {
                    var _this = this;
                    _.each(this.buildingEnrollmentView, function (item) {
                        item.taskMap = _this.getBuildingPhaseTaskMap(item, _this.drillPhase);
                    });
                };
                OnboardingWidgetCtrl.prototype.getBuildingPhaseTaskMap = function (buildingEnrollmentItem, phaseName) {
                    var taskMap = {};
                    var phase = _.find(buildingEnrollmentItem.viewPhases, function (viewPhase) {
                        return viewPhase.name.toLowerCase() == phaseName.toLowerCase();
                    });
                    if (!phase) {
                        return;
                    }
                    _.each(phase.viewTasks, function (viewTask) {
                        taskMap[viewTask.task.name] = viewTask;
                    });
                    return taskMap;
                };
                OnboardingWidgetCtrl.prototype.onTaskCompleteChange = function (buildingEnrollmentItem, task, drillPhase) {
                    var _this = this;
                    if (task.status != 'IGNORED') {
                        if (!task.complete) {
                            task.completionDate = null;
                            task.status = 'INCOMPLETE';
                        }
                        else {
                            task.completionDate = moment().format('YYYY-MM-DD');
                            task.status = 'COMPLETE';
                        }
                    }
                    this.updateBuildingEnrollment(buildingEnrollmentItem)
                        .then(function (updatedItem) {
                        _this.Messages.success('Task updated');
                        _this.updateCurrentViewPhase(buildingEnrollmentItem, drillPhase);
                        _this.calculatePhasesOverallProgress();
                        buildingEnrollmentItem.progress = updatedItem.progress;
                    })
                        .catch(function () {
                        _this.Messages.error('Error while updating task, please reload the page and try again');
                    });
                };
                OnboardingWidgetCtrl.prototype.onTaskIgnoreChange = function (buildingEnrollmentItem, task, drillPhase) {
                    if (task.status != 'IGNORED') {
                        task.status = 'IGNORED';
                        task.complete = false;
                    }
                    else if (task.status === 'IGNORED' && task.completionDate) {
                        task.status = 'COMPLETE';
                        task.complete = true;
                    }
                    else if (task.status === 'IGNORED' && !task.completionDate) {
                        task.status = 'INCOMPLETE';
                        task.complete = false;
                    }
                    this.onTaskCompleteChange(buildingEnrollmentItem, task, drillPhase);
                };
                OnboardingWidgetCtrl.prototype.editProjectTask = function (buildingEnrollmentItem, task) {
                    var _this = this;
                    this.getBuildingUsers(buildingEnrollmentItem.building)
                        .then(function (users) {
                        return _this.showProjectTaskEditModal(buildingEnrollmentItem, task, users);
                    })
                        .then(function (updatedTask) {
                        if (updatedTask.status != task.status) {
                            return _this.loadBuildingEnrollmentById(buildingEnrollmentItem.building);
                        }
                        else {
                            task.details = updatedTask.details;
                            task.dueDate = updatedTask.dueDate ? moment(updatedTask.dueDate).format('YYYY-MM-DD') : null;
                            task.owner = updatedTask.owner;
                            var buildingUsers = _this.buildingUsersMap[buildingEnrollmentItem.building];
                            if (buildingUsers) {
                                var ownerUser = _.find(buildingUsers, function (user) { return user.id == task.owner; });
                                if (ownerUser) {
                                    task.ownerName = ownerUser.fullName;
                                }
                            }
                            return _this.$q.when(null);
                        }
                    })
                        .then(function (item) {
                        if (item != null) {
                            var updatedViewItem_1 = _this.getBuildingEnrollmentViewItem(item);
                            if (_this.drillPhase) {
                                updatedViewItem_1.taskMap = _this.getBuildingPhaseTaskMap(updatedViewItem_1, _this.drillPhase);
                            }
                            var index = _.findIndex(_this.buildingEnrollment, function (viewItem) {
                                return viewItem.building == updatedViewItem_1.building;
                            });
                            if (index > -1) {
                                _this.buildingEnrollment.splice(index, 1, updatedViewItem_1);
                            }
                            var indexFiltered = _.findIndex(_this.filteredBuildingEnrollmentView, function (viewItem) {
                                return viewItem.building == updatedViewItem_1.building;
                            });
                            if (indexFiltered > -1) {
                                _this.filteredBuildingEnrollmentView.splice(index, 1, updatedViewItem_1);
                            }
                            _this.calculatePhasesOverallProgress();
                        }
                    });
                };
                OnboardingWidgetCtrl.prototype.showProjectTaskEditModal = function (buildingEnrollmentItem, task, users) {
                    var project = {
                        id: buildingEnrollmentItem.project,
                        building: buildingEnrollmentItem.building
                    };
                    var mappedTask = _.omit(task, ['ownerName', 'eventHandler']);
                    var options = {
                        isNameReadonly: true,
                        isActionReadonly: true,
                        isStatusReadonly: true
                    };
                    return this.$mdDialog.show({
                        controller: 'ProjectCenterTaskEditCtrl as vm',
                        templateUrl: 'app/projectCenter/project/task/projectCenterTaskEditModal.html',
                        clickOutsideToClose: true,
                        parent: angular.element(document.body),
                        locals: {
                            project: project,
                            task: mappedTask,
                            users: _.sortBy(users, ['fullName']),
                            account: buildingEnrollmentItem.account,
                            options: options
                        }
                    });
                };
                OnboardingWidgetCtrl.prototype.getBuildingUsers = function (buildingId) {
                    var _this = this;
                    if (this.buildingUsersMap[buildingId]) {
                        return this.$q.when(this.buildingUsersMap[buildingId]);
                    }
                    return this.RestangularV3
                        .all('users/users-for-building')
                        .getList({ 'buildingId': buildingId, 'showAdmin': true })
                        .then(function (users) {
                        _this.buildingUsersMap[buildingId] = users;
                        return users;
                    });
                };
                OnboardingWidgetCtrl.prototype.updateCurrentViewPhase = function (buildingEnrollmentItem, drillPhase) {
                    var phase = _.find(buildingEnrollmentItem.phases, function (p) {
                        return p.name.toLowerCase() == drillPhase.toLowerCase();
                    });
                    var updatedViewPhase = this.getPhaseView(phase, true);
                    var replaceIndex = _.findIndex(buildingEnrollmentItem.viewPhases, function (viewPhase) {
                        return viewPhase.name.toLowerCase() == drillPhase.toLowerCase();
                    });
                    buildingEnrollmentItem.viewPhases.splice(replaceIndex, 1, updatedViewPhase);
                    var nextTask = null;
                    _.each(buildingEnrollmentItem.viewPhases, function (viewPhase) {
                        if (nextTask == null) {
                            nextTask = viewPhase.nextTask;
                        }
                    });
                    buildingEnrollmentItem.nextTask = nextTask;
                    this.calculatePhasesOverallProgress();
                };
                OnboardingWidgetCtrl.prototype.goBackToSummary = function () {
                    this.drillPhase = '';
                    this.sortBy = '';
                    this.filteredBuildingEnrollmentView = this.buildingEnrollmentView;
                    this.isBuildingDrill = false;
                };
                OnboardingWidgetCtrl.prototype.sortByTotalCompletion = function () {
                    this.setSortByValue('Building');
                    this.updateViewItemsOrderByTotalCompletion();
                };
                OnboardingWidgetCtrl.prototype.sortByPhaseCompletion = function (phaseName) {
                    this.setSortByValue(phaseName);
                    this.updateViewItemsOrderByPhaseCompletion();
                };
                OnboardingWidgetCtrl.prototype.sortByTaskCompletion = function (taskName) {
                    this.setSortByValue(taskName);
                    this.updateViewItemsOrderByTaskCompletion();
                };
                OnboardingWidgetCtrl.prototype.sortByStaff = function () {
                    this.setSortByValue('Staff');
                    this.updateViewItemsOrderByStaff();
                };
                OnboardingWidgetCtrl.prototype.updateViewItemsOrderByTotalCompletion = function () {
                    var _this = this;
                    _.each(this.buildingEnrollment, function (item) {
                        if (!item.project) {
                            item.orderValue = 2000;
                            if (!_this.sortAscending) {
                                item.orderValue *= -1;
                            }
                        }
                        else {
                            if (_.every(item.phases, function (phase) { return phase.tasks.length == 0; })) {
                                item.orderValue = 1000;
                            }
                            else {
                                item.orderValue = (item.progress || 0);
                            }
                        }
                    });
                };
                OnboardingWidgetCtrl.prototype.updateViewItemsOrderByPhaseCompletion = function () {
                    var _this = this;
                    _.each(this.buildingEnrollment, function (item) {
                        var phaseName = _this.sortBy;
                        var phase = _.find(item.phases, function (p) {
                            return p.name.toLowerCase() == phaseName.toLowerCase();
                        });
                        // ordering: show items without any tasks at the bottom, but above items without project
                        if (!item.project) {
                            item.orderValue = 2000;
                            if (!_this.sortAscending) {
                                item.orderValue *= -1;
                            }
                        }
                        else {
                            if (phase.tasks.length == 0) {
                                item.orderValue = 1000;
                            }
                            else {
                                item.orderValue = phase.progress;
                            }
                        }
                    });
                };
                OnboardingWidgetCtrl.prototype.updateViewItemsOrderByTaskCompletion = function () {
                    var _this = this;
                    _.each(this.buildingEnrollmentView, function (item) {
                        var phaseName = _this.drillPhase;
                        var taskName = _this.sortBy;
                        var phase = _.find(item.phases, function (p) {
                            return p.name.toLowerCase() == phaseName.toLowerCase();
                        });
                        // ordering: show items without any tasks at the bottom, but above items without project
                        if (!item.project) {
                            item.orderValue = 20;
                            if (!_this.sortAscending) {
                                item.orderValue *= -1;
                            }
                        }
                        else {
                            if (phase.tasks.length == 0) {
                                item.orderValue = 10;
                            }
                            else {
                                item.orderValue = item.taskMap[taskName] && item.taskMap[taskName].task.complete ? 2 : 1;
                            }
                        }
                    });
                };
                OnboardingWidgetCtrl.prototype.updateViewItemsOrderByStaff = function () {
                    var _this = this;
                    _.each(this.buildingEnrollmentView, function (item) {
                        var personas = _this.buildingPersonas[item.building];
                        var propertyManager = personas ? personas.propertyManager.toLowerCase() : '';
                        var buildingEngineer = personas ? personas.buildingEngineer.toLowerCase() : '';
                        item.orderValue = (propertyManager || 'ZZZ') + "#" + (buildingEngineer || 'ZZZ');
                        if (!propertyManager && !buildingEngineer) {
                            item.orderValue = _this.sortAscending ? 'ZZZZ' : '';
                        }
                    });
                };
                OnboardingWidgetCtrl.prototype.updateBuildingEnrollment = function (buildingEnrollment) {
                    var updateRequest = this.transformForRequest(buildingEnrollment);
                    return this.RestangularV3
                        .one('buildings', buildingEnrollment.building)
                        .one('enrollment')
                        .customPUT(updateRequest);
                };
                OnboardingWidgetCtrl.prototype.getBuildingPersonnels = function () {
                    var _this = this;
                    var promises = _.map(this.buildings, function (building) {
                        if (_this.buildingPersonnels[building.id]) {
                            return {
                                buildingId: building.id,
                                personnel: _this.buildingPersonnels[building.id]
                            };
                        }
                        return building.all('personnel').getList()
                            .then(function (result) {
                            return {
                                buildingId: building.id,
                                personnel: result
                            };
                        }, function (error) {
                            return {
                                buildingId: building.id,
                                personnel: []
                            };
                        });
                    });
                    return this.$q.all(promises);
                };
                OnboardingWidgetCtrl.prototype.toggleBuildingData = function (item) {
                    if (this.$mdMedia('gt-md') && !item.isShowPersonnel) {
                        return;
                    }
                    item.isShowPersonnel = !item.isShowPersonnel;
                };
                OnboardingWidgetCtrl.prototype.setSortByValue = function (value) {
                    if (this.sortBy == value) {
                        this.sortAscending = !this.sortAscending;
                    }
                    else {
                        this.sortAscending = true;
                    }
                    this.sortBy = value;
                };
                OnboardingWidgetCtrl.prototype.addOverallPhaseProgress = function (phase) {
                    var name = phase.name.toLowerCase();
                    if (!this.overallPhaseProgress[name]) {
                        this.overallPhaseProgress[name] = {
                            total: 0,
                            completed: 0
                        };
                    }
                    if (phase.completedTasks != null) {
                        this.overallPhaseProgress[name].total++;
                    }
                    if (phase.totalActiveTasks === phase.completedTasks) {
                        this.overallPhaseProgress[name].completed++;
                    }
                };
                OnboardingWidgetCtrl.prototype.getTasksView = function (phase) {
                    var _this = this;
                    var tasks = phase.tasks;
                    var sortedTasks = _.sortBy(tasks, ['dueDate', 'task']);
                    return _.map(sortedTasks, function (task) {
                        if (task.status === 'COMPLETE') {
                            task.complete = true;
                        }
                        var taskView = {
                            phaseName: phase.name,
                            task: task,
                            dueDateFormatted: task.dueDate ? moment(task.dueDate).format('MM/DD/YYYY') : '',
                            completionDateFormatted: task.completionDate ? moment(task.completionDate).format('MM/DD/YYYY') : ''
                        };
                        _this.setOverdueInfo(taskView);
                        return taskView;
                    });
                };
                OnboardingWidgetCtrl.prototype.getNextTask = function (tasks) {
                    var viewTask = _.find(tasks, function (t) { return !t.task.complete; });
                    return viewTask;
                };
                OnboardingWidgetCtrl.prototype.setOverdueInfo = function (taskView) {
                    if (!taskView.task.complete && taskView.task.dueDate) {
                        var diff_1 = moment().diff(moment(taskView.task.dueDate), 'day');
                        if (diff_1 > 0) {
                            taskView.icon = 'cancel';
                            taskView.iconColor = 'red';
                            taskView.iconDescription = 'Task is overdue';
                        }
                        else if (diff_1 <= 2) {
                            taskView.icon = 'warning';
                            taskView.iconColor = 'orange';
                            taskView.iconDescription = 'Task due date is near';
                        }
                    }
                };
                OnboardingWidgetCtrl.prototype.transformForRequest = function (be) {
                    var request = null;
                    if (be) {
                        request = { project: be.project, phases: [] };
                        _.each(be.phases, function (phase) {
                            var phaseUpdate = { phase: phase.phase, tasks: [] };
                            _.each(phase.tasks, function (task) {
                                phaseUpdate.tasks.push(_.pick(task, ['id', 'completionDate', 'sortOrder', 'status']));
                            });
                            request.phases.push(phaseUpdate);
                        });
                    }
                    return request;
                };
                OnboardingWidgetCtrl.prototype.getExportCsv = function () {
                    var _this = this;
                    this.isExportingData = true;
                    this.getBuildingPersonnels()
                        .then(function (data) {
                        _.each(data, function (item) {
                            if (!_this.buildingPersonnels[item.buildingId]) {
                                _this.buildingPersonnels[item.buildingId] = item.personnel;
                            }
                        });
                    })
                        .finally(function () {
                        _this.isExportingData = false;
                        _this.buildAndDownloadExportCsv();
                    });
                };
                OnboardingWidgetCtrl.prototype.buildAndDownloadExportCsv = function () {
                    var content = this.buildCsvContent();
                    var fileName = "Building Enrollment Tasks - " + this.account.accountName + " - " + moment().format('YYYY-MM-DD') + ".csv";
                    var link = document.createElement('a');
                    link.setAttribute('href', encodeURI(content));
                    link.setAttribute('download', fileName);
                    link.click();
                };
                OnboardingWidgetCtrl.prototype.buildCsvContent = function () {
                    var _this = this;
                    var header = 'data:text/csv;charset=utf-8,Building Enrollment Tasks';
                    var csvItems = [];
                    var taskNamesList = [];
                    var taskNamesMap = {};
                    var buildingInformation = [
                        'Address',
                        'City',
                        'State',
                        'SQ Footage',
                        'Building',
                        'Property Manager',
                        'Building Engineer'
                    ];
                    // order tasks by phase
                    _.each(this.phases, function (phaseName) {
                        _.each(_this.buildingEnrollmentView, function (buildingItem) {
                            var phase = _.find(buildingItem.viewPhases, function (viewPhase) { return viewPhase.name == phaseName; });
                            if (!phase) {
                                return;
                            }
                            _.each(phase.viewTasks, function (viewTask) {
                                if (viewTask.task.name && !taskNamesMap[viewTask.task.name]) {
                                    taskNamesMap[viewTask.task.name] = true;
                                    taskNamesList.push(viewTask.task.name);
                                }
                            });
                        });
                    });
                    var csvHeader = buildingInformation.join(',') + ',' + taskNamesList.join(',');
                    _.each(this.buildingEnrollmentView, function (buildingItem) {
                        if (!buildingItem.project) {
                            return;
                        }
                        var currentBuildingMap = {};
                        _.each(_this.phases, function (phaseName) {
                            var phaseTaskMap = _this.getBuildingPhaseTaskMap(buildingItem, phaseName);
                            angular.extend(currentBuildingMap, phaseTaskMap);
                        });
                        var buildingTaskCompletionList = _.map(taskNamesList, function (taskName) {
                            if (!currentBuildingMap[taskName]) {
                                return 'N/A';
                            }
                            return currentBuildingMap[taskName].task.complete ? 'completed' : '';
                        });
                        var personnel = _this.buildingPersonnels[buildingItem.building] || [];
                        var propertyManager = _.find(personnel, function (item) { return item.persona == 'PROPERTY_MANAGER'; });
                        var buildingEngineer = _.find(personnel, function (item) { return item.persona == 'BUILDING_ENGINEER'; });
                        var building = _this.buildings.find(function (b) {
                            return b.id.toString() === buildingItem.building.toString();
                        });
                        var buildingCsvRow = [
                            building ? building.address : '',
                            building ? building.city : '',
                            building ? building.state : '',
                            building ? building.size.toString() : '',
                            buildingItem.buildingName ? buildingItem.buildingName : '',
                            propertyManager ? propertyManager.name : '',
                            buildingEngineer ? buildingEngineer.name : ''
                        ].concat(buildingTaskCompletionList).join(',');
                        csvItems.push(buildingCsvRow);
                    });
                    return header + "\n" + csvHeader + "\n" + csvItems.join('\n');
                };
                return OnboardingWidgetCtrl;
            }());
            widgets.OnboardingWidgetCtrl = OnboardingWidgetCtrl;
            angular.module('aq.dashboard.widgets').controller('OnboardingWidgetCtrl', OnboardingWidgetCtrl);
        })(widgets = dashboard.widgets || (dashboard.widgets = {}));
    })(dashboard = aq.dashboard || (aq.dashboard = {}));
})(aq || (aq = {}));
