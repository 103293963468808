angular.module('aq.admin.statistics.schedules').controller('BuildingsCtrl', ['$scope', 'Restangular', '$stateParams', 'Messages',
  function($scope, Restangular, $stateParams, Messages) {

    $scope.$watch('accounts', function(accounts) {
        if (!accounts) return;
        
        $scope.account = _.findById(accounts, $stateParams.accountId);
        
        $scope.account.getList('buildings').then(function(buildings) {
            $scope.buildings = buildings;
        });
        
        $scope.account.all('workCalendars').getList().then(function(calendars) {
            $scope.calendars = calendars;
            $scope.selectedCalendar = calendars[0];
        });
        
    })
    
    
}]);