var aq;
(function (aq) {
    var ui;
    (function (ui) {
        var ActivityContextType = aq.models.activity.ActivityContextType;
        var ActivityType = aq.models.activity.ActivityType;
        var GroupedActivities = aq.models.activity.GroupedActivities;
        var ProductCategory = aq.models.activity.ProductCategory;
        var ActivityActions = aq.models.segment.ActivityActions;
        var ActivityMode;
        (function (ActivityMode) {
            ActivityMode["LIST"] = "LIST";
            ActivityMode["CARD"] = "CARD";
        })(ActivityMode = ui.ActivityMode || (ui.ActivityMode = {}));
        ;
        // Reading on component lifecycle hooks https://toddmotto.com/angular-1-5-lifecycle-hooks
        var _ActivityFeed = /** @class */ (function () {
            /* @ngInject */
            function _ActivityFeed($scope, resolver, Auth, RestangularV3, BuildingSelectorActions, ModelUtilService, ActivityService, PrescriptionService, Segment, UserService, Messages) {
                var _a;
                var _this = this;
                this.$scope = $scope;
                this.resolver = resolver;
                this.Auth = Auth;
                this.RestangularV3 = RestangularV3;
                this.BuildingSelectorActions = BuildingSelectorActions;
                this.ModelUtilService = ModelUtilService;
                this.ActivityService = ActivityService;
                this.PrescriptionService = PrescriptionService;
                this.Segment = Segment;
                this.UserService = UserService;
                this.Messages = Messages;
                this.title = 'Activities';
                this.mode = ActivityMode.LIST;
                this.filter = {
                    range: {
                        start: moment().subtract({ months: 1 }).startOf('day'),
                        end: moment().endOf('day')
                    },
                    ytdRange: {
                        start: moment().subtract({ months: 1 }).startOf('day'),
                        end: moment().endOf('day')
                    },
                    types: (_a = {},
                        _a[ActivityContextType.ALERT] = true,
                        _a[ActivityContextType.AID_ISSUE] = true,
                        _a[ActivityContextType.ENERGY_NOTE] = true,
                        _a[ActivityContextType.OPTIMAL_START] = true,
                        _a[ActivityContextType.RX] = true,
                        _a[ActivityContextType.NONE] = true,
                        _a)
                };
                this.defaults = {
                    start: moment().subtract({ months: 6 }).startOf('month').startOf('day'),
                    end: moment().endOf('day')
                };
                this.isLoadingNewBuildingData = true;
                this.hasActivityResults = false;
                this.exportingActivities = false;
                this.includeUrgent = function (activity) {
                    if (activity.type === ActivityType.ISSUE) {
                        var context = activity.context;
                        var acknowledgedStatus = context.acknowledgedStatus, contextObject = context.contextObject, type = context.type;
                        if (ActivityContextType.ALERT === type && acknowledgedStatus === 'OPEN') {
                            return true;
                        }
                        if (ActivityContextType.RX === type
                            && contextObject
                            && _this.PrescriptionService.isUrgent(contextObject)) {
                            return true;
                        }
                    }
                    return false;
                };
            }
            _ActivityFeed.prototype.$onInit = function () {
                this.isAqAdmin = this.UserService.isAdmin();
                this.queryActivities(this.filter);
            };
            _ActivityFeed.prototype.$postLink = function () { };
            _ActivityFeed.prototype.$onDestroy = function () { };
            _ActivityFeed.prototype.$onChanges = function (changes) {
                if (changes.title) {
                    this.title = changes.title.currentValue;
                }
                if (changes.buildingId) {
                    this.isLoadingNewBuildingData = true;
                    this.buildingId = changes.buildingId.currentValue;
                    this.queryActivities(this.filter);
                }
                if (changes.accountId) {
                    this.isLoadingNewBuildingData = true;
                    this.accountId = changes.accountId.currentValue;
                    this.buildingId = changes.buildingId.currentValue;
                    this.queryActivities(this.filter);
                }
            };
            _ActivityFeed.prototype.toggleFilterMenu = function () {
                this.isFilterMenuOpen = !this.isFilterMenuOpen;
            };
            _ActivityFeed.prototype.toggleMode = function () {
                switch (this.mode) {
                    case ActivityMode.CARD:
                        this.mode = ActivityMode.LIST;
                        this.queryActivities(this.filter);
                        this.activitiesByType = this.groupActivities(this.activities, this.filter);
                        break;
                    case ActivityMode.LIST:
                        this.mode = ActivityMode.CARD;
                        this.queryActivities(this.filter);
                        this.activitiesByType = this.groupActivities(this.activities, this.filter);
                        break;
                    default:
                        this.mode = ActivityMode.LIST;
                }
            };
            _ActivityFeed.prototype.getIcon = function (category) {
                var prefix = 'aq-icons-product-';
                switch (category) {
                    case aq.models.activity.ProductCategory.HVAC_ANALYTICS:
                        return prefix + "hvac-analytics green";
                    case aq.models.activity.ProductCategory.LOAD_ANALYTICS:
                        return prefix + "load-analytics orange";
                    case aq.models.activity.ProductCategory.NOTE:
                        return prefix + "notes purple";
                    case aq.models.activity.ProductCategory.OPTIMIZATION_STRATEGY:
                        return prefix + "optimization-strategy blue";
                    case aq.models.activity.ProductCategory.ALERT:
                        return prefix + "alerts deep-blue";
                    default:
                        return '';
                }
            };
            _ActivityFeed.prototype.getStartDate = function (filter) {
                if (filter.range.start.isBefore(this.defaults.start)) {
                    return filter.range.start;
                }
                return this.defaults.start;
            };
            _ActivityFeed.prototype.getEndDate = function () {
                return this.defaults.end;
            };
            _ActivityFeed.prototype.queryActivities = function (filter) {
                var _this = this;
                this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                    var _a, now, startYear, fiscalStartMonth, start, end, params, response, _b, caseParams, err_1;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                _c.trys.push([0, 5, , 6]);
                                this.isLoadingNewBuildingData = true;
                                if (!(!this.building || Number(this.building.id) !== this.buildingId)) return [3 /*break*/, 2];
                                _a = this;
                                return [4 /*yield*/, this.RestangularV3.one('buildings', this.buildingId).get()];
                            case 1:
                                _a.building = _c.sent();
                                _c.label = 2;
                            case 2:
                                now = new Date();
                                startYear = now.getMonth() < this.building.fiscalStartMonth ? now.getFullYear() - 1 : now.getFullYear();
                                fiscalStartMonth = void 0;
                                if (!this.building.fiscalStartMonth) {
                                    fiscalStartMonth = '01';
                                }
                                else if (this.building.fiscalStartMonth < 10) {
                                    fiscalStartMonth = "0" + this.building.fiscalStartMonth;
                                }
                                else {
                                    fiscalStartMonth = this.building.fiscalStartMonth.toString();
                                }
                                this.filter.ytdRange = {
                                    start: moment(fiscalStartMonth + "-01-" + startYear, 'MM-DD-YYYY'),
                                    end: moment().endOf('day')
                                };
                                start = this.mode === ActivityMode.LIST ? this.getStartDate(filter).toISOString() : this.filter.ytdRange.start.toISOString();
                                end = this.mode === ActivityMode.LIST ? this.getEndDate().toISOString() : this.filter.ytdRange.end.toISOString();
                                params = { building: this.buildingId, start: start, end: end, type: [] };
                                return [4 /*yield*/, this.ActivityService.getActivities(params)];
                            case 3:
                                response = _c.sent();
                                this.activities = response.payload;
                                _b = this;
                                return [4 /*yield*/, this.ActivityService.getAnnualizedSavings(start, end, this.building)];
                            case 4:
                                _b.annualizedSavings = _c.sent();
                                caseParams = {
                                    first_published_gte: start,
                                    first_published_lte: end,
                                    order: '-priority',
                                    page_size: 1000
                                };
                                this.activitiesByType = this.groupActivities(response.payload, this.filter);
                                this.isLoadingNewBuildingData = false;
                                this.Segment.trackEvent(ActivityActions.CURRENT_ACTIVITY_LIST, this.activitiesByType);
                                this.$scope.$apply();
                                return [3 /*break*/, 6];
                            case 5:
                                err_1 = _c.sent();
                                return [3 /*break*/, 6];
                            case 6: return [2 /*return*/];
                        }
                    });
                }); });
            };
            _ActivityFeed.prototype.generateAutoDetectReport = function () {
                var _this = this;
                this.exportingActivities = true;
                this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                    var start, end, report, csvContent, fileName, link, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                start = moment(this.filter.range.start).format('YYYY-MM-DD');
                                end = moment(this.filter.range.end).format('YYYY-MM-DD');
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.RestangularV3
                                        .one('automatic-diagnostics')
                                        .one('report', this.accountId)
                                        .get({ start: start, end: end })];
                            case 2:
                                report = _a.sent();
                                csvContent = 'data:text/csv;charset=utf-8,';
                                fileName = '';
                                csvContent += "" + report;
                                fileName = "Automatic Diagnostic Report - " + moment().format('YYYY-MM-DD') + ".csv";
                                link = document.createElement('a');
                                link.setAttribute('href', encodeURI(csvContent));
                                link.setAttribute('download', fileName);
                                link.click();
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _a.sent();
                                this.Messages.error('Something went wrong generating your report!');
                                return [3 /*break*/, 4];
                            case 4:
                                this.exportingActivities = false;
                                return [2 /*return*/];
                        }
                    });
                }); });
            };
            _ActivityFeed.prototype.mapCaseToActivity = function (rx) {
                var result = {
                    id: null,
                    title: this.PrescriptionService.getTitle(rx),
                    type: aq.models.activity.ActivityType.ISSUE,
                    context: {
                        type: aq.models.activity.ActivityContextType.RX,
                        contextId: rx.id,
                        contextObject: rx
                    },
                    createdAt: rx.first_published,
                    updatedAt: rx.updated,
                    messages: (!rx.case_comments ? 0 : rx.case_comments.length)
                };
                return result;
            };
            _ActivityFeed.prototype.updateFilter = function (filter) {
                filter.range.start = moment(filter.range.start);
                filter.range.end = moment(filter.range.end);
                if (filter.range.start.isBefore(this.defaults.start)) {
                    this.queryActivities(filter);
                }
                this.filter = angular.copy(filter);
                this.Segment.trackEvent(ActivityActions.UPDATE_ACTIVITY_FILTER, __assign({}, filter, { user: this.UserService.currentUser.id }));
                this.activitiesByType = this.groupActivities(this.activities, this.filter);
            };
            _ActivityFeed.prototype.refreshActivities = function () {
                this.queryActivities(this.filter);
            };
            _ActivityFeed.prototype.groupActivities = function (activities, filter) {
                var _this = this;
                var groupedActivities = new GroupedActivities();
                if (!activities) {
                    return groupedActivities;
                }
                if (this.mode === ActivityMode.LIST) {
                    activities = activities.filter(function (a) {
                        var created = moment(a.createdAt);
                        return created.isAfter(filter.range.start) && created.isBefore(filter.range.end);
                    });
                }
                else if (this.mode === ActivityMode.CARD) {
                    var start_1 = this.getStartDate(filter);
                    activities = activities.filter(function (a) {
                        var created = moment(a.createdAt);
                        return created.isAfter(start_1) && created.isBefore(_this.defaults.end);
                    });
                }
                var typeFilter = Object.keys(filter.types)
                    .filter(function (type) { return filter.types[type] === true; });
                // if we have a filter value for specific types of activities, only keep those
                if (typeFilter.length > 0) {
                    activities = activities
                        .filter(function (act) {
                        var context = act.context;
                        var contextType = context.type;
                        if (!contextType) {
                            return false;
                        }
                        return typeFilter.indexOf(contextType) !== -1;
                    });
                }
                groupedActivities
                    .groupByClassification(filter, activities, this.annualizedSavings, this.mode, this.building)
                    .removeEmpty();
                if (this.mode === ActivityMode.CARD) {
                    groupedActivities
                        .remove(ProductCategory.ALERT)
                        .remove(ProductCategory.NOTE)
                        .remove(ProductCategory.OTHER);
                }
                this.hasActivityResults = !groupedActivities.isEmpty();
                return groupedActivities;
            };
            return _ActivityFeed;
        }());
        ui.ActivityFeed = {
            controller: _ActivityFeed,
            controllerAs: 'vm',
            templateUrl: 'app/common/components/activities/feed/activity-feed.html',
            bindings: {
                title: '<?',
                accountId: '<',
                buildingId: '<',
                mode: '<?',
                refreshActivities: '&'
            }
        };
        angular.module('aq.ui').component('activityFeed', ui.ActivityFeed);
    })(ui = aq.ui || (aq.ui = {}));
})(aq || (aq = {}));
