namespace aq.dashboard.widgets {
    export class StatusEditService {
        /* @ngInject */
        constructor(
            private RawService: aq.services.RawService
        ) { }

        public initOptions(cfg: StatusConfig) {
            cfg.options.queryables = this.RawService.getQueryableTypes();
            cfg.options.measureUnits = this.RawService.getValidMeasureUnits();
            this.fetchOptions(cfg);
        }

        public fetchOptions(cfg: StatusConfig) {
            if (!cfg.buildingId) {
                return;
            }
            // Prefetch our choices
            cfg.options.spaces = [];
            cfg.options.tenants = [];
            cfg.options.meters = [];
            cfg.options.sources = [];
            cfg.options.points = [];
            this.RawService.fetchSpaces(cfg.buildingId)
                .then((spaces) => cfg.options.spaces = spaces);
            this.RawService.fetchTenants(cfg.buildingId)
                .then((tenants) => cfg.options.tenants = tenants);
            this.RawService.fetchMeters(cfg.buildingId)
                .then((meters) => cfg.options.meters = meters);
            this.RawService.fetchSources(cfg.buildingId)
                .then((sources) => cfg.options.sources = sources);
            this.RawService.fetchPoints(cfg.buildingId)
                .then((points) => cfg.options.points = points);
        }

        public getDefaultSeries(cfg: StatusConfig): StatusSeries {
            if (cfg.options.buildings.length < 1) {
                console.error('no buildings available');
                return;
            }
            const defaultSeries: StatusSeries = {
                title: '',
                nullClass: StatusClass.NORMAL,
                showValue: true,
                measureUnit: this.toMeasureUnitString(aq.services.Measure.ELECTRICITY, aq.services.Unit.KW),
                queryableType: aq.services.Queryable.BUILDINGS,
                queryable: {
                    name: cfg.options.buildings[0].name,
                    id: parseInt(cfg.options.buildings[0].id)
                },
                thresholds: {
                    warning: {
                        lower: null,
                        upper: null
                    },
                    critical: {
                        lower: null,
                        upper: null
                    }
                }
            };
            return defaultSeries;
        }

        public onSelectedBuildingChange(cfg: StatusConfig, data: string) {
            cfg.buildingId = data;
            const newbuilding  = cfg.options.buildings.find(e => e.id === cfg.buildingId);

            if (cfg.series != undefined && newbuilding != undefined) {
                cfg.series.forEach(s => {
                    if (s.queryableType === 'buildings') {
                        s.queryable = {
                            name: newbuilding.name,
                            id: parseInt(newbuilding.id)
                        };
                    }
                });
            } else {
                this.getDefaultSeries(cfg);
            }
            this.fetchOptions(cfg);
        }

        public toggleItemDetails(cfg: StatusConfig, item: StatusSeries) {
            if (cfg.options.expandedItem === item) {
                cfg.options.expandedItem = null;
            } else {
                cfg.options.expandedItem = item;
            }
        }

        public onQueryableChange(cfg: StatusConfig, item: StatusSeries) {
            item.queryable = null;
        }

        public getQueryableOptions(cfg: StatusConfig, queryableType: aq.services.Queryable, searchText: string) {
            // TODO: fix bug where if this returns an empty list, it breaks our md-autocomplete
            let options;
            switch (queryableType) {
                case aq.services.Queryable.BUILDINGS:
                    options = cfg.options.buildings;
                    break;
                case aq.services.Queryable.SPACES:
                    options = cfg.options.spaces;
                    break;
                case aq.services.Queryable.TENANTS:
                    options = cfg.options.tenants;
                    break;
                case aq.services.Queryable.METERS:
                    options = cfg.options.meters;
                    break;
                case aq.services.Queryable.SOURCES:
                    options = cfg.options.sources;
                    break;
                case aq.services.Queryable.POINTS:
                    options = cfg.options.points;
                    break;
                default:
                    throw new Error(`Unsupported Queryable ${queryableType}`);
            }
            if (!searchText) {
                searchText = '';
            }
            searchText = searchText.toLowerCase();
            return _.filter(options, (option) => {
                return _.includes(option.name.toLowerCase(), searchText);
            });
        }

        public onDelete(cfg: StatusConfig, item: StatusSeries) {
            _.remove(cfg.series, (s) => s === item);
        }

        public onNewMeasureUnit(cfg: StatusConfig) {
            const lastSeries = cfg.series[cfg.series.length - 1] || this.getDefaultSeries(cfg);
            const newSeries: StatusSeries = {
                title: '',
                nullClass: lastSeries.nullClass,
                showValue: lastSeries.showValue,
                measureUnit: cfg.options.newMeasureUnit,
                queryableType: lastSeries.queryableType,
                queryable: lastSeries.queryable,
                thresholds: angular.copy(lastSeries.thresholds)
            };
            cfg.series.push(newSeries);
        }

        public toMeasureUnitString(measure: aq.services.Measure, unit: aq.services.Unit) {
            return `${measure}::${unit}`;
        }

        public getMeasureUnitFromString(measureUnit: string) {
            const split = measureUnit.split('::');
            if (split.length != 2) {
                throw new Error(`Invalid measure unit: ${measureUnit}`);
            }
            return {
                measure: split[0] as aq.services.Measure,
                unit: split[1] as aq.services.Unit
            };
        }
    }

    angular.module('aq.dashboard.widgets').service('StatusEditService', StatusEditService);
}
