namespace aq.integrations.mri {
    export class MRIService {
        /* @ngInject */
        constructor(
            private DataStore: aq.common.DataStore,
            private RestangularV3: restangular.IService
        ) { }

        public updateMRIIntegration(mriIntegration) {
            return this.RestangularV3.one('mri', mriIntegration.id).customPUT(mriIntegration)
            .then((response) => {
                return response;
            });
        }

        public createMRIIntegration(mriIntegration) {
            return this.RestangularV3.one('mri').customPOST(mriIntegration, '', {
                building: mriIntegration.building, mriBldg_id: mriIntegration.mriBldg_id
            }).then((response) => {
                return response;
            });
        }

    }

    angular
        .module('aq.integrations')
        .service('MRIService', MRIService);
}
