namespace aq.tenantbilling {
    export class TenantBillingCtrl {
        tenantLabel: string;
        /* @ngInject */
        constructor(
            private Auth: aq.services.Auth,
            $scope: ng.IScope,
            account: aq.common.models.Account,
            buildings: aq.common.models.Building[],
            private $state: ng.ui.IStateService
        ) {
            // TODO: remove scope updates when legacy building selector is no longer used
            $scope.account = account;
            $scope.buildings = buildings;
            $scope.selectDefaultBuildingWithRedirect(this.$state.current, this.$state.current);
            $scope.isCardLayout = () => {
                const oldLayoutStates = [
                    'aq.tenantbilling.building.pastinvoices',
                    'aq.tenantbilling.building.reports'
                ];
                const isOldLayout = _.some(oldLayoutStates, (state) => this.$state.includes(state));
                return !isOldLayout;
            };
            this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'Units' : 'Tenants';
            $scope.tenantLabel = this.tenantLabel;
        }
    }

    angular
        .module('tenantBilling')
        .controller('TenantBillingCtrl', TenantBillingCtrl);
}
