namespace aq.components {

    export class CalendarWidget {
        constructor($scope, $element, $state, EventGenerator: aq.services.EventGenerator, UserService, $timeout) {
            $scope.calendarViewMode = 'month';
            // HACK: passing viewRender function as part of calendar config does not get called on view change for some reason
            $scope.bindEventsWhenRendered = () => {
                const dayButton = $element.find('span.fc-button-agendaDay');
                const weekButton = $element.find('span.fc-button-agendaWeek');
                const monthButton = $element.find('span.fc-button-month');
                if (dayButton.length == 0 || weekButton.length == 0 || monthButton.length == 0) {
                    $timeout(() => $scope.bindEventsWhenRendered(), 200);
                    return;
                }
                dayButton.click(() => {
                    $scope.calendarViewMode = 'day';
                    $scope.$apply();
                    $('#calendar-widget').fullCalendar('refetchEvents');
                });
                weekButton.click(() => {
                    $scope.calendarViewMode = 'week';
                    $scope.$apply();
                    $('#calendar-widget').fullCalendar('refetchEvents');
                });
                monthButton.click(() => {
                    $scope.calendarViewMode = 'month';
                    $scope.$apply();
                    $('#calendar-widget').fullCalendar('refetchEvents');
                });
                $('#calendar-widget').fullCalendar('render');
            };
            $scope.bindEventsWhenRendered();

            /* event source that calls a function on every view switch */
            $scope.eventsF = function (start, end, callback) {
                const events = EventGenerator.getEvents(start, end, $scope.rules, $scope.categories, $scope.calendarViewMode);
                callback(events);
            };

            // redraw events on calendar if another calendar is set
            $scope.$watch('rules', function (rules) {
                if (!rules) return;
                $('#calendar-widget').fullCalendar('refetchEvents');
            });

            $scope.setCalendarConfig = (hourFormat) => {
                $scope.calendarConfig = {
                    calendar: {
                        height: 450,
                        editable: false,
                        header: {
                            left: 'prev, next, today',
                            center: 'title',
                            right: 'month, agendaWeek, agendaDay'
                        },
                        eventClick(calEvent, jsEvent, view) {
                            if ($scope.eventClick) {
                                $scope.eventClick({ calendarEvent: calEvent });
                            }
                            return false;
                        },
                        dayClick: $scope.alertEventOnClick,
                        eventDrop: $scope.alertOnDrop,
                        eventResize: $scope.alertOnResize,
                        firstHour: 7,
                        ignoreTimezone: true,
                        axisFormat: hourFormat,
                        eventRender: (event, element) => this.customEventRender(event, element, $scope),
                        dayRender: (date, cell) => this.customDayRender(date, cell)
                    }
                };
            };

            if (UserService.isTwentyFourFormat()) {
                $scope.setCalendarConfig('HH:mm');
            } else {
                $scope.setCalendarConfig('h(:mm)tt');
            }

            $scope.eventSources = [$scope.eventsF];

            const refreshCalendar = _.debounce(function () {
                $scope.fullCalendar.fullCalendar('refetchEvents');
            }, 1000);

            $scope.$watch('fullCalendar', function (fullCalendar) {
                if (!fullCalendar) return;
                $scope.fullCalendar.fullCalendar('render');
                refreshCalendar();
            }, true);
        }

        private customEventRender(event, element, scope) {
            element.height(20);
            element.css('font-size', '12px');
            element.css('color', this.invertColor(event.color, true));
            if (scope.calendarViewMode == 'month') {
                element.addClass('prevent-word-wrap');
            }
            if (scope.eventClick) {
                element.css('cursor', 'pointer');
            }
        }

        private customDayRender(date, cell) {
            cell.css('padding-bottom', '6px');
        }

        private invertColor(hex, bw) {
            if (hex.indexOf('#') === 0) {
                hex = hex.slice(1);
            }
            if (hex.length === 3) {
                hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
            }
            if (hex.length !== 6) {
                throw new Error('Invalid HEX color.');
            }
            const r = parseInt(hex.slice(0, 2), 16);
            const g = parseInt(hex.slice(2, 4), 16);
            const b = parseInt(hex.slice(4, 6), 16);
            if (bw) {
                return (r * 0.299 + g * 0.587 + b * 0.114) > 186
                    ? '#000000'
                    : '#FFFFFF';
            }
            const rs = (255 - r).toString(16);
            const gs = (255 - g).toString(16);
            const bs = (255 - b).toString(16);
            return '#' + this.padZero(rs) + this.padZero(gs) + this.padZero(bs);
        }
        private padZero(str, len = null) {
            len = len || 2;
            const zeros = new Array(len).join('0');
            return (zeros + str).slice(-len);
        }
    }

    angular
        .module('aq.calendar.widget', ['aq.calendar.widget.eventGenerator'])
        .directive('calendarWidget', function () {
            return {
                restrict: 'E',
                replace: true,
                templateUrl: 'app/common/directives/calendarWidget/calendarWidget.html',
                scope: {
                    rules: '=',
                    categories: '=',
                    eventClick: '&?'
                },
                controller: CalendarWidget
            };
        });
}
