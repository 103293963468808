var aq;
(function (aq) {
    var reports;
    (function (reports) {
        var DailyBuildingCtrl = /** @class */ (function (_super) {
            __extends(DailyBuildingCtrl, _super);
            /* ngInject */
            function DailyBuildingCtrl(currentDate, account, building, energyStarScore, functionalities, OptionsService, availableMetrics, meters, $timeout, $q, ReportApiParams, DataService, MonthlyOccupancyData, DailyCharts) {
                var _this = _super.call(this, currentDate, account, building, energyStarScore, functionalities, OptionsService, availableMetrics, meters, $timeout) || this;
                _this.currentDate = currentDate;
                _this.account = account;
                _this.building = building;
                _this.energyStarScore = energyStarScore;
                _this.functionalities = functionalities;
                _this.OptionsService = OptionsService;
                _this.availableMetrics = availableMetrics;
                _this.meters = meters;
                _this.$timeout = $timeout;
                _this.$q = $q;
                _this.ReportApiParams = ReportApiParams;
                _this.DataService = DataService;
                _this.MonthlyOccupancyData = MonthlyOccupancyData;
                _this.DailyCharts = DailyCharts;
                _this.time = angular.copy(_this.buildingReport.building.currentTime);
                _this.time.subtract(1, 'day');
                _this.monthIndex = parseInt(_this.time.format('M'));
                _this.occupancyData = _this.MonthlyOccupancyData.getMonthlyOccupancy(building, _this.monthIndex);
                // TODO for now we must query temperature separately because /data endpoint doesn't support temperature
                // (it is from virtual meter which is excluded)
                var temperatureParams = new _this.ReportApiParams(_this.time, null, 'daily');
                var temperaturePromise = building.customGET('temperature', temperatureParams.current());
                var previousTemperaturePromise = building.customGET('temperature', temperatureParams.previous());
                _this.previousTime = moment(temperatureParams.previousDegreeDay().start);
                var reportPromises = [];
                _.each(_this.buildingReport.serviceReports, function (report) {
                    var config = report.config;
                    report.data = {
                        loading: true
                    };
                    var apiParams = new _this.ReportApiParams(_this.time, config.measures, 'daily');
                    var dayDataPromise = building.customGET('data', apiParams.current());
                    var dayPreviousDataPromise = building.customGET('data', apiParams.previous());
                    var dayExpectedDataPromise = _this.DataService.expectedEnergy(building, apiParams.current().interval, apiParams.current().start, apiParams.current().end);
                    var degreeDaysPromise = _this.DataService.degreeDays(building, apiParams.currentDegreeDay().interval, apiParams.currentDegreeDay().start, apiParams.currentDegreeDay().end);
                    var previousDegreeDaysPromise = _this.DataService.degreeDays(building, apiParams.previousDegreeDay().interval, apiParams.previousDegreeDay().start, apiParams.previousDegreeDay().end);
                    var reportDefer = _this.$q.defer();
                    _this.$q.all([
                        dayDataPromise,
                        dayPreviousDataPromise,
                        temperaturePromise,
                        previousTemperaturePromise,
                        degreeDaysPromise,
                        previousDegreeDaysPromise,
                        dayExpectedDataPromise
                    ]).then(function (result) {
                        var dayData = result[0];
                        var previousDayData = result[1];
                        var temperatureData = result[2];
                        var previousTemperatureData = result[3];
                        var dayDegreeDaysData = result[4];
                        var previousdayDegreeDaysData = result[5];
                        var dayExpectedData = result[6];
                        var data = report.data;
                        data.currentHDD = dayDegreeDaysData.hdd.total;
                        data.currentCDD = dayDegreeDaysData.cdd.total;
                        data.previousHDD = previousdayDegreeDaysData.hdd.total;
                        data.previousCDD = previousdayDegreeDaysData.cdd.total;
                        data.usage = _.sum(dayData[config.usageMetric].values);
                        data.demand = _this.getDemand(config, dayData[config.demandMetric].values);
                        data.averageTemperature = _.mean(temperatureData.values);
                        data.previousUsage = _.sum(previousDayData[config.usageMetric].values);
                        data.previousDemand = _this.getDemand(config, previousDayData[config.demandMetric].values);
                        data.previousAverageTemperature = _.mean(previousTemperatureData.values);
                        data.hasData = _this.checkIfHasData(config, dayData) || _this.checkIfHasData(config, previousDayData);
                        data.hasExpectedDemandData = !config.isWaterOrGasReport
                            && _this.hasNonNullData(dayExpectedData[config.expectedDemandMetric].values);
                        if (!config.isWaterOrGasReport) {
                            data.expectedUsage = _this.getExpectedUsage(dayExpectedData[config.expectedDemandMetric].values);
                            data.expectedDemand = _this.getDemand(config, dayExpectedData[config.expectedDemandMetric].values);
                        }
                        var dailyCharts = new _this.DailyCharts(dayData, previousDayData, dayExpectedData, report.config);
                        if (!config.isWaterOrGasReport) {
                            /* Daily Demand chart data */
                            data.dailyDemand = dailyCharts.getDailyDemandChart();
                            /* Daily Demand chart data expected*/
                            data.dailyDemandExpected = dailyCharts.getDailyDemandChartExpected();
                            /* Variance chart data */
                            data.varianceChart = dailyCharts.getVarianceChart();
                        }
                        /* Water chart data */
                        if (config.isWaterOrGasReport) {
                            data.waterChart = dailyCharts.getWaterChart(temperatureData);
                        }
                        data.loading = false;
                        reportDefer.resolve();
                    }).catch(function () {
                        reportDefer.reject();
                    });
                    reportPromises.push(reportDefer.promise);
                });
                _this.$q.all(reportPromises)
                    .then(function () {
                    var delay = 3000;
                    _this.$timeout(function () {
                        _this.notifyDocumentReady();
                    }, delay);
                })
                    .catch(function () {
                    _this.isDocumentError = true;
                });
                return _this;
            }
            DailyBuildingCtrl.prototype.getServiceType = function (usageMetric) {
                switch (usageMetric) {
                    case 'energy':
                        return 'Electricity';
                    case 'steam_mass':
                        return 'Steam';
                    case 'heat_transfer':
                        return 'Heat';
                    case 'gas':
                        return 'Gas';
                    case 'water':
                        return 'Water';
                    default:
                        return '';
                }
            };
            DailyBuildingCtrl.prototype.checkIfHasData = function (config, elem) {
                return _.some(config.measures, function (metric) { return elem[metric.toLowerCase()].values.length > 0; });
            };
            DailyBuildingCtrl.prototype.hasNonNullData = function (data) {
                return _.filter(data, null).length !== 0;
            };
            DailyBuildingCtrl.prototype.getExpectedUsage = function (expectedDemandValues) {
                var i = 0;
                var expectedUsageValue = 0;
                while (i < expectedDemandValues.length) {
                    var hourValues = _.slice(expectedDemandValues, i, i + 4);
                    var average = _.mean(hourValues);
                    expectedUsageValue += average;
                    i = i + 4;
                }
                // convert watt hour to watt seconds
                return expectedUsageValue * 3600;
            };
            DailyBuildingCtrl.prototype.getDemand = function (config, values) {
                if (config.isWaterOrGasReport) {
                    return _.mean(values);
                }
                else {
                    return _.max(values);
                }
            };
            return DailyBuildingCtrl;
        }(reports.BaseBuildingReportCtrl));
        reports.DailyBuildingCtrl = DailyBuildingCtrl;
        angular.module('aq.reports').controller('DailyBuildingCtrl', DailyBuildingCtrl);
    })(reports = aq.reports || (aq.reports = {}));
})(aq || (aq = {}));
