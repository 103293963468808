angular.module('properties.sources').controller('AddSourceModalCtrl', function ($scope, Errors, PropertySettingsOptions, ParentSourceHandler, Restangular, $translate, $filter, $mdDialog, building) {

    $scope.s = {
        building: $scope.building,
        meterType: {},
        //voltage: {} This caused a bug with water and gas sources when adding them
    }

    $scope.availableSources = [];

    $scope.addSource = function(s) {

        s.metric = _.find($scope.options.metrics, { name: textural(s.sourceClass.measure).format('capitalize') });
        if (s.relationship != null){
            s.mapped = s.relationship.name != 'Unmapped';
        }
        else{
            s.mapped = false;
        }

        //json formatting for submit
        s.building = $scope.building.id;
        if(s.voltage)
            s.voltage = s.voltage.value;
        if(s.phase)
            s.phase = s.phase.value;

        return Restangular.one('accounts', building.account).one('buildings', building.id).all('sources').post(s).then(function(result) {
            _.extend(s, result);
            _.extend(s, result.model);

            $mdDialog.hide();

            // if child source then update selected parent source circuit
            // assign current source.id to parent circuit resource

            if (s.parentSourceSelected) {
                ParentSourceHandler.attachToParent(s, s.parentSourceCircuit).then(function() {
                    $scope.refreshList();
                })
            } else {
                $scope.refreshList();
            }

        }, Errors.forCRUD('CREATE', 'Source with the same name already exists in the building'));


    }

    $scope.$watch('s.phase', function(phase) {
        if (!phase) return;
        PropertySettingsOptions.getVoltagesForPhase(phase.value);
    }, true)

    $scope.$watch('s.sourceClass', function(sourceClass) {
        if (!sourceClass) return;
        $scope.s.parentSourceSelected = null;
        $scope.s.parentSourceCircuit = null;
        $scope.availableSources = $filter('availableSources')($scope.allSources, $scope.s);
    }, true)

    $scope.parentSourceSelected = function (parentSourceSelected) {
        if (!parentSourceSelected) return;
        $scope.s.parentSourceCircuit = null;
        $scope.parentSourceCircuits = ParentSourceHandler.circuits(parentSourceSelected.circuits);
    }

    $scope.$watch('s.sourceClass', function (sourceClass) {
        if (!sourceClass) return;

        $scope.s.voltage = sourceClass.defaultVoltage;
        $scope.s.phase = PropertySettingsOptions.getPhaseFromVoltage(sourceClass.defaultVoltage);
        $scope.s.description = $translate.instant(sourceClass.description);
    })

});