angular.module('accounts').controller('MembersCtrl', function ($scope, $q, Messages, $aq, $location, Restangular, profiles, invitations, UserManagementService) {

    $scope.showNumber = 10;
    $scope.profiles = profiles;

    $scope.applicationRoot = $location.protocol() + "://" + $location.host() + ":" + $location.port();

    $scope.mergeLists = function() {
        _.forEach(invitations, function(i) {
            i.currentProfile = $scope.getCurrentProfile(i);
            profiles.push(i);
        });
    }

    $scope.showMore = function () {
        if ($scope.showNumber + 10 > $scope.invitationsAndMembers.length) {
            $scope.showNumber = $scope.invitationsAndMembers.length;
        } else {
            $scope.showNumber += 10;
        }
    }

    $scope.updateTable = function () {
        $scope.invitationsAndMembers = $scope.profiles;
    }

    $scope.$watchCollection('profiles', function () {
        $scope.updateTable();
    })

    $scope.getRowTemplate = function (item) {
        if (item.user) {
            return '/frontend/app/accounts/members/templates/templateMember.html';
        }
        else {
            return '/frontend/app/accounts/members/templates/templateInvitation.html';
        }
    }

    $scope.inviteUsers = function (allEmails) {
        if (allEmails === null || allEmails.length === 0) {
            Messages.error("Enter e-mail addresses of users to invite");
            return $q.when();
        }
        var roleId = $scope.profiles[0].roles[0];
        return $scope.account.customPOST({}, 'sendInvitations', {
            allEmails: allEmails,
            propertyVisibilities: _.map($scope.propertyVisibilities, 'id'),
            roleId: roleId
        }).then(function (data) {
            Messages.info(data);
            $scope.allEmails = [];
            $scope.loadAccount();
        }, function () {
            Messages.error("Error sending invitations");
        });
    }

    $scope.saveInvitation = function (hide) {
        // had some problems with angular strap, moving this hide worked around it
        hide();
        if (typeof $scope.editingInvitation.currentProfile.roles[0] === 'object') {
            $scope.editingInvitation.role = $scope.editingInvitation.currentProfile.roles[0].id;
        }
        if ($scope.editingInvitation.currentProfile.buildings) {
            var buildingIdList = [];
            $scope.editingInvitation.currentProfile.buildings.forEach(function (building) {
                if (typeof building === 'object') {
                    buildingIdList.push(building.id);
                } else {
                    buildingIdList.push(building);
                }
            });
            $scope.editingInvitation.buildings = buildingIdList;
        }

        UserManagementService.saveInvitation($scope.editingInvitation, $scope.account).then(function () {
            Messages.success('Saved user profile');
            var index = _.indexOf($scope.profiles, _.find($scope.profiles, { id: $scope.editingInvitation.id }));
            $scope.profiles.splice(index, 1, Restangular.copy($scope.editingInvitation));
        }, function () {
            Messages.error('Error saving user profile');
        });
    }

    $scope.editInvitation = function (invitation) {
        // handholding inplace controls
        var editingInvitation = Restangular.copy(invitation);
        if (editingInvitation.buildings) {
            var buildingList = [];
            editingInvitation.buildings.forEach(function (building) {
                buildingList.push(_.findById($scope.buildings, building));
            });
            editingInvitation.buildings = buildingList;
        }
        $scope.editingInvitation = Restangular.copy(editingInvitation);
    }

    $scope.deleteInvitation = function (profiles, deleteInvitation) {
        $scope.account.customDELETE('invitations/' +  deleteInvitation.id, {}).then(function () {
            Messages.success('Deleted Invitation')
            var index = _.indexOf($scope.profiles, _.find($scope.profiles, { id: deleteInvitation.id }));
            $scope.profiles.splice(index, 1);
        }, function () {
            Messages.error("Error deleting invitation");
        });
    };

    $scope.saveMember = function (hide) {
        // had some problems with angular strap, moving this hide worked around it
        hide();
        $scope.editingProfile.hasFullVisibility = $scope.editingProfile.visibilities.length === 0;
        // saving roles and visibilities by ID and not by object
        if (typeof $scope.editingProfile.roles[0] === 'object') {
            $scope.editingProfile.roles[0] = $scope.editingProfile.roles[0].id;
        }
        if ($scope.editingProfile.visibilities) {
            var visibilityIdList = [];
            $scope.editingProfile.visibilities.forEach(function (visibility) {
                if (typeof visibility === 'object') {
                    visibilityIdList.push(visibility.id);
                } else {
                    visibilityIdList.push(visibility);
                }
            });
            $scope.editingProfile.visibilities = visibilityIdList;
        }
        return $scope.editingProfile.put().then(function () {
            Messages.success('Saved user profile');
            var index = _.indexOf($scope.profiles, _.find($scope.profiles, { id: $scope.editingProfile.id }));
            $scope.profiles.splice(index, 1, Restangular.copy($scope.editingProfile));
        }, function () {
            Messages.error('Error saving user profile');
        });
    }

    $scope.editMember = function (profile) {
        // handholding inplace controls
        var editingProfile = Restangular.copy(profile);
        if (editingProfile.visibilities) {
            var visibilityList = [];
            editingProfile.visibilities.forEach(function (visibility) {
                visibilityList.push(_.findById($scope.buildings, visibility));
            });
            editingProfile.visibilities = visibilityList;
        }
        $scope.editingProfile = Restangular.copy(editingProfile);
    }

    $scope.deleteMember = function (profiles, deleteMember) {
        $scope.account.customDELETE('invitations/' +  deleteMember.user.id, {}).then(function () {
            Messages.success('Deleted Member')
            var index = _.indexOf($scope.profiles, _.find($scope.profiles, { id: deleteMember.id }));
            $scope.profiles.splice(index, 1);
        }, function () {
            Messages.error("Error deleting Member");
        });
    };

    // get the current profile from invititation
    $scope.getCurrentProfile = function(invites) {
        var possibleProfiles = invites.profiles;
        if (!this.profiles || this.profiles.length === 0) {
            return null;
        }
        for (var j = 0; j < possibleProfiles.length; j++) {
            if (possibleProfiles[j].accountName === this.profiles[0].accountName) {
                return possibleProfiles[j];
            }
        }
        return null;
    }

    // merging profiles from V1 endpoint and Invitations from V3 endpoint
    // TODO: convert all profiles to V3 endpoints
    $scope.mergeLists();
});
