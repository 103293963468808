namespace aq.tenantBilling {

    import TenantCreationRequest = aq.tenantbilling.TenantCreationRequest;
    import TenantObject = aq.tenantbilling.TenantObject;

    export class BulkUploadTenantCtrl extends aq.common.Controllers.ModalCtrl {
        public uploadData: string;
        private query: string;
        private previewResponse: TenantCreationRequest[];
        private fileUploaded: boolean;
        private step: number = 1;
        /* @ngInject */
        constructor(
            private Auth: aq.services.Auth,
            public $mdDialog: ng.material.IDialogService,
            private $mdStepper,
            private Messages: aq.services.Messages,
            private RestangularV3: restangular.IService,
            private building: aq.common.models.Building,
            private TenantService: aq.services.TenantService
        ) {
            super({}, $mdDialog);
        }

        downloadTemplate(): void {
            const csvColumns = [
                'name',
                'leaseId',
                'primaryContact',
                'phoneNumber',
                'email',
                'address',
                'city',
                'state',
                'zipCode',
                'country',
                'size',
                'leaseType'
            ];
            let csvContent = 'data:text/csv;charset=utf-8,';
            csvContent += `${csvColumns}\n`;
            const fileName = `${this.building.name} - tenants upload`;
            const link = document.createElement('a');
            link.setAttribute('href', encodeURI(csvContent));
            link.setAttribute('download', fileName);
            link.click();
        }

        next(): void {
            this.$mdStepper('bulkUploadStepper').goto(this.step++);
        }

        upload(): void {
            this.fileUploaded = true;
        }

        generatePreview(): void {
            const lines = this.uploadData.split(/\r\n|\n|\r/);
            const result = [];
            const headers = lines[0].split(',');

            lines.shift();

            lines.forEach((line) => {
                const obj = {};
                const splitLine = line.split(',');
                for (let j = 0; j < headers.length; j++) {
                    obj[`${headers[j]}`] = splitLine[j];
                }
                result.push(obj);
            });
            this.generateTenantCreationRequestObjects(result);
        }

        generateTenantCreationRequestObjects(json: TenantObject[]): void {
            this.previewResponse = json.map((tenant: TenantObject) => {
                if (tenant.name === '' || tenant.leaseId === '') {
                    this.$mdDialog.hide();
                    this.Messages.error('Missing required fields');
                    throw new Error('Missing required fields');
                }
                const newTenant: TenantCreationRequest = { 
                    ...tenant,
                    accountNumber: tenant.leaseId,
                    building: this.building.id 
                };
                return newTenant;
            });
            this.createTenants(this.previewResponse);
        }

        createTenants(tenants: TenantCreationRequest[]): ng.IPromise<void> {
            return this.TenantService.bulkCreate(tenants).then((response) => {
                this.$mdDialog.hide();
                this.Messages.success('Successfully created tenants');
            }).catch((error) => {
                this.$mdDialog.hide();
                this.Messages.error('Error creating tenants');
            });
        }
    }

    angular
        .module('tenantBilling')
        .controller('BulkUploadTenantCtrl', BulkUploadTenantCtrl);
}
