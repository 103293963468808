/**
 *  Deprecated
 *
 *  This service is used for
 *   1)  'enhancing' time object for old chart directive.
 *  It adds some additional fields/methods based on specified aggregation
 *  This case is deprecated and code should be dropped once we switch to Highcharts
 *
 *  2) detecting 'default' interval based on specified start/end date
 *  This is a current logic for Optimization datepicker. Note that new date picker has a bit
 *  different logic implemented in IntervalService. Once we migrate from the old date picker
 *  relevant code should be dropped too.
 *
 **/
angular.module('charting').factory('TimeService', ['UserService', 'BaseService', function(UserService) {
    var service: any = {};

    service.configureForChart = function(time, interval) {
        var periodInformation = service.getPeriodByInterval(interval);
        periodInformation.aggregation = service.getPeriodByInterval(interval).aggregation;
        return _.extend(time, periodInformation);
    };

    var aggregations = [{
        interval: '1min',
        aggregation: moment.duration(1, 'minutes'),
        configurePeriod: function(retObj) {
            var longForm = UserService.getLongFlotTimeFormat();
            retObj.aggregation = moment.duration(1, 'minutes');
            retObj.schedulingAggregation = retObj.aggregation;
            retObj.format = {
                shortFormats: ['%M'],
                longFormat: longForm
            };
            retObj.cleanDate = function(date) {
                return moment(date);
            }
            retObj.generateGridsFor = function(ticks) {
                return _.chain(ticks)
                    .filter(function(tick) {
                        return tick.minutes() === 0;
                    })
                    .map(function(tick) {
                        return tick.valueOf();
                    }).value();
            }

            retObj.generateTicksFor = function(ticks) {
                return _.chain(ticks)
                    .filter(function(tick) {
                        return tick.minutes() === 15 || tick.minutes() === 30 || tick.minutes() === 45
                            || tick.minutes() === 0;
                    })
                    .map(function(tick) {
                        return tick.valueOf();
                    }).value();
            }
            retObj.tickAggregation = moment.duration(1, 'minutes');
            retObj.gridInterval = moment.duration(1, 'minutes');
        }
    }, {
        interval: '15min',
        aggregation: moment.duration(15, 'minutes'),
        configurePeriod: function(retObj) {
            var longForm = UserService.getLongFlotTimeFormat();
            var shortForm = UserService.getShortFlotTimeFormat();
            retObj.aggregation = moment.duration(15, 'minutes');
            retObj.schedulingAggregation = retObj.aggregation;
            retObj.generateGridsFor = function(ticks) {
                return _.chain(ticks)
                    .filter(function(tick) {
                        return tick.minutes() === 0;
                    })
                    .map(function(tick) {
                        return tick.valueOf();
                    }).value();
            };
            retObj.generateTicksFor = function(ticks) {
                return _.chain(ticks)
                .filter(function(tick) {
                    return tick.minutes() === 0 && tick.hours() % 2 === 0;
                })
                .map(function(tick) {
                    return tick.valueOf();
                }).value();
            }
            retObj.format = {
                shortFormats: [shortForm],
                longFormat: longForm
            };
            retObj.cleanDate = function(date) {
                return moment(date);
            }
            retObj.tickAggregation = moment.duration(1, 'minutes');
            retObj.gridInterval = moment.duration(15, 'minutes');
        }
    }, {
        interval: '1h',
        aggregation: moment.duration(1, 'hours'),
        configurePeriod: function(retObj) {
            var longForm = UserService.getLongFlotTimeFormat();
            retObj.aggregation = moment.duration(1, 'hours');
            retObj.schedulingAggregation = retObj.aggregation;
            retObj.format = {
                shortFormats: ['%a'],
                longFormat: longForm + ' %a, %b %d'
            };

            retObj.generateGridsFor = function(ticks) {
                return _.chain(ticks)
                    .filter(function(tick) {
                        return tick.hours() === 0;
                    })
                    .map(function(tick) {
                        return tick.valueOf();
                    }).value();
            }

            retObj.cleanDate = function(date) {
                return moment(date);
            }
            retObj.generateTicksFor = function(ticks) {
                return _.chain(ticks)
                .filter(function(tick) {
                    return tick.hours() === 12;
                })
                .map(function(tick) {
                    return tick.valueOf();
                }).value();
            }
            retObj.tickAggregation = moment.duration(1, 'hours');
            retObj.gridInterval = moment.duration(1, 'hours');
        }
    }, {
        interval: '1d',
        aggregation: moment.duration(1, 'days'),
        configurePeriod: function(retObj) {
            retObj.aggregation = moment.duration(1, 'days');
            retObj.schedulingAggregation = moment.duration(1, 'hours');
            retObj.format = {
                shortFormats: ['%d', '%b'],
                longFormat: '%a, %b %d, %Y'
            };
            retObj.cleanDate = function(date) {
                return moment(date).hours(0).minutes(0).seconds(0);
            }
            retObj.generateGridsFor = function(ticks) {
                return _.chain(ticks)
                    .filter(function(tick) {
                        return tick.day() === 1;
                    })
                    .map(function(tick) {
                        return tick.valueOf();
                    }).value();
            }
            retObj.generateTicksFor = function(ticks) {
                return _.chain(ticks)
                .filter(function(tick) {
                    return tick.hours() === 12;
                })
                .map(function(tick) {
                    return tick.valueOf();
                }).value();
            }
            retObj.tickAggregation = moment.duration(1, 'hours');
            retObj.gridInterval = moment.duration(7, 'days');
        }
    }, {
        interval: '1mon',
        aggregation: moment.duration(1, 'months'),
        configurePeriod: function(retObj) {
            retObj.aggregation = moment.duration(1, 'months');
            retObj.schedulingAggregation = moment.duration(1, 'hours');
            retObj.format = {
                shortFormats: ['%b', '%y'],
                longFormat: '%b, %Y'
            };

            retObj.generateGridsFor = function(ticks) {
                return [];
            }

            retObj.cleanDate = function(date) {
                return moment(date).date(1).hours(0).minutes(0).seconds(0);
            }

            retObj.generateTicksFor = function(ticks, start) {
                return _.chain(ticks)
                .filter(function(tick) {
                    return tick.date() === 15;
                })
                .map(function(tick) {
                    return tick.valueOf();
                }).value();
            }
            retObj.tickAggregation = moment.duration(1, 'day');
            retObj.gridInterval = moment.duration(1, 'weeks');
        }
    }];

    // Use IntervalService instead of this once we migrate to new date picker on Optimization page
    service.getPeriodByInterval = function(interval) {
        var retObj = {};
        var aggregationToUse = _.find(aggregations, { interval: interval });
        aggregationToUse.configurePeriod(retObj);
        return retObj;
    }

    service.detectInterval = function(interval) {
        return service.getPeriodByInterval(interval).interval;
    }

    return service;
}]);
