namespace aq.utilityBudgets {
    declare var Highcharts: __Highcharts.Static;
    export class YearBudgetCtrl {
        public yearlyChart: __Highcharts.Options;
        public budgetTargetSeries: __Highcharts.SeriesOptions;
        public temperatureSeries: __Highcharts.SeriesOptions;
        public temperatureForecastSeries: __Highcharts.SeriesOptions;
        public selectedType: string;
        public selectedYearPeriod: BudgetYearPeriod;
        public availableBudgetYears: BudgetYearPeriod[];
        public isCurrentYear: boolean;
        public colors: string[];
        public chart: __Highcharts.ChartObject;
        public tableData: YearTableDataItem[];
        public sortedOccupancy: aq.propertySettings.BuildingOccupancy[];
        public fiscalStartMonth: number;
        public periodType: string;
        public isPeriodTypeDisabled: boolean;
        public blendedRateUnit: string;
        public isLoadingAccrual: boolean;

        /* ngInject */
        constructor(
            private account: aq.common.models.Account,
            private building: aq.common.models.Building,
            private measures: aq.common.models.Measure[],
            private budgetYear: string,
            private budgetMeasure: string,
            private yearBudget: MonthBudgetItem[],
            private buildingSpending: UtilitySpendingCumulativeResponse,
            private temperature: Temperature,
            private temperatureUnit: MeasureUnit,
            private currencyUnitSymbol: string,
            private $timeout: ng.ITimeoutService,
            private $filter: ng.IFilterService,
            private $state: ng.ui.IStateService,
            private GraphEditService: aq.dashboard.widgets.GraphEditService,
            private occupancy: aq.propertySettings.BuildingOccupancy[],
            private $translate,
            private measureUnitMap: { [measure: string]: string },
            private $mdDialog: ng.material.IDialogService,
            private Messages: services.Messages,
            private RestangularV3: restangular.IService,
            private Auth: aq.services.Auth
        ) {
            this.isLoadingAccrual = false;
            this.init();
            this.initConstantSeries();
            this.buildYearlyChart();
            this.buildTableData();
            this.$timeout(() => this.chart && this.chart.reflow(), 200);
        }
        public init() {
            this.periodType = 'year';
            this.fiscalStartMonth = this.building.fiscalStartMonth || 1;
            this.initAvailableYears();
            this.colors = Highcharts.getOptions().colors;
            this.isCurrentYear = moment().format('YYYY') == this.budgetYear;
            this.selectedType = this.budgetMeasure;
            this.sortedOccupancy = _.sortBy(this.occupancy, (item: aq.propertySettings.BuildingOccupancy) => item.startDate);
            this.blendedRateUnit = `${this.currencyUnitSymbol}/${this.measureUnitMap[this.budgetMeasure.toUpperCase()]}`;
        }
        public initAvailableYears() {
            let yearCounter = 2;
            let fiscalYearValue = null;
            const currentYear = moment().year();
            const currentMonth = moment().month() + 1;

            if (currentMonth >= this.fiscalStartMonth) {
                // fiscal year matches current calendar year
                fiscalYearValue = currentYear;
            } else {
                // fiscal year started in the previous calendar year
                fiscalYearValue = currentYear - 1;
            }

            let fiscalYearDisplay = fiscalYearValue;
            if (this.fiscalStartMonth > 6) {
                fiscalYearDisplay++;
            }

            this.availableBudgetYears = [];

            while (yearCounter > 0) {
                this.availableBudgetYears.push({
                    name: `${fiscalYearDisplay} FY`,
                    year: fiscalYearValue.toString()
                });
                yearCounter--;
                fiscalYearDisplay--;
                fiscalYearValue--;
            }

            this.selectedYearPeriod = _.find(this.availableBudgetYears, (item) => item.year == this.budgetYear);
            if (!this.selectedYearPeriod) {
                let yearDisplay = parseInt(this.budgetYear);
                if (this.fiscalStartMonth > 6) {
                    yearDisplay++;
                }
                this.selectedYearPeriod = {
                    name: `${yearDisplay} FY`,
                    year: this.budgetYear.toString()
                };
                this.availableBudgetYears.push(this.selectedYearPeriod);
            }
        }
        public initConstantSeries() {
            this.temperatureSeries = this.getTemperatureSeries(this.temperature);
            this.temperatureForecastSeries = this.getTemperatureForecastSeries(this.temperature);
            this.budgetTargetSeries = this.getBudgetTargetSeries();
        }
        public onChangePeriod() {
            this.$state.go(this.$state.current.name, {
                year: this.selectedYearPeriod.year
            });
        }
        public goToMonthlyView() {
            this.isPeriodTypeDisabled = true;
            const year = this.selectedYearPeriod.year;
            let month = '1';
            if (year == moment().format('YYYY')) {
                month = moment().format('M');
            }
            this.$state.go('aq.utilityBudgets.month', {
                year: year.toString(),
                month: month.toString(),
                measure: this.selectedType
            });
        }
        public onSelectMonth(month) {
            let year = parseInt(this.selectedYearPeriod.year);
            if (month > 12) {
                month -= 12;
                year += 1;
            }
            this.$state.go('aq.utilityBudgets.month', {
                year: year.toString(),
                month: month.toString(),
                measure: this.selectedType
            });
        }
        public onChangeType() {
            this.$state.go(this.$state.current.name, {
                measure: this.selectedType
            });
        }
        public navigateToBudgets() {
            this.$state.go('aq.utilityBudgets.budgets', {
                accountId: this.account.id,
                buildingId: this.building.id
            });
        }
        public buildTableData() {
            this.tableData = [];
            const now = moment();
            _.each(this.buildingSpending.spending.data, (item: UtilitySpendingDataItem, index) => {
                const budgeted = index < this.yearBudget.length ? this.yearBudget[index].value : 0;
                const date = moment(item.timestamp).add(1, 'day');
                const occupancy = this.getOccupancyForMonth(date);
                const charge = item.datum.utilityBill + item.datum.consumption + item.datum.projected;
                let degreeValue, temperatureType;
                if (date.isAfter(now)) {
                    degreeValue = this.getTemperatureValue(this.temperature.climateNormals[index]);
                    temperatureType = 'forecast';
                } else {
                    degreeValue = this.getTemperatureValue(this.temperature.values[index]);
                    temperatureType = 'historical';
                }
                let budgetPercent = null, isRed = false;
                if (budgeted && charge != null) {
                    budgetPercent = (Math.round(charge * 1000 / budgeted) / 10).toFixed(1);
                    isRed = charge > budgeted;
                }
                const dateFormat = this.fiscalStartMonth == 1 ? 'MMM' : 'MMM, YYYY';
                this.tableData.push({
                    date: date.format(dateFormat),
                    occupancy: occupancy != null ? `${occupancy}%` : '-',
                    temperature: degreeValue != null ? `${degreeValue} ${this.temperatureUnit.unit}` : '-',
                    temperatureType,
                    charge: this.$filter('currency')(charge, this.currencyUnitSymbol, 0),
                    chargeValue: charge,
                    budgetPercent,
                    isRed,
                    projection: this.$filter('currency')(item.datum.projected, this.currencyUnitSymbol, 0),
                    consumption: this.$filter('currency')(item.datum.consumption, this.currencyUnitSymbol, 0),
                    budget: this.$filter('currency')(budgeted, this.currencyUnitSymbol, 0),
                    rate: item.datum.blendedRate,
                    isProjected: item.datum.projected > 0,
                    isConsumption: item.datum.consumption > 0,
                    accrual: null
                });
            });
        }
        public buildYearlyChart() {
            const series = [];
            const spendData = [];
            const projectedData = [];
            const overBudgetData = [];
            const underBudgetData = [];
            _.each(this.buildingSpending.spending.data, (item: UtilitySpendingDataItem, index) => {
                let month = moment(item.timestamp).add(1, 'day').month() + 1;
                if (month < this.fiscalStartMonth) {
                    month += 12;
                }
                spendData.push({ x: month, y: item.datum.utilityBill + item.datum.consumption });
                projectedData.push({ x: month, y: item.datum.projected });
                const budget = index < this.yearBudget.length ? this.yearBudget[index].value : 0;
                if (!budget) {
                    return;
                }
                const totalItemCharge = item.datum.utilityBill + item.datum.consumption + item.datum.projected;
                const targetBudgetData = totalItemCharge > budget ? overBudgetData : underBudgetData;
                targetBudgetData.push({
                    x: month,
                    y: 0.001
                });
            });
            if (underBudgetData.length > 0) {
                series.push({
                    data: underBudgetData,
                    name: this.$translate.instant('budgets.Under Budget'),
                    type: 'column',
                    color: seriesColors.green,
                    stack: 'budget',
                    cursor: 'pointer',
                    pointPadding: 0,
                    borderWidth: 0,
                    showInLegend: false,
                    index: 4,
                    minPointLength: 5,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                });
                series.push({
                    data: [],
                    name: this.$translate.instant('budgets.Under Budget'),
                    type: 'line',
                    color: seriesColors.green,
                    index: 4
                });
            }
            if (overBudgetData.length > 0) {
                series.push({
                    data: overBudgetData,
                    name: this.$translate.instant('budgets.Over Budget'),
                    type: 'column',
                    color: seriesColors.red,
                    stack: 'budget',
                    cursor: 'pointer',
                    pointPadding: 0,
                    borderWidth: 0,
                    showInLegend: false,
                    index: 5,
                    minPointLength: 5,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                });
                series.push({
                    data: [],
                    name: this.$translate.instant('budgets.Over Budget'),
                    type: 'line',
                    color: seriesColors.red,
                    index: 5
                });
            }
            if (_.some(projectedData, (item) => item.y > 0)) {
                series.push({
                    data: projectedData,
                    name: this.$translate.instant('budgets.Projected Spend'),
                    type: 'column',
                    color: this.GraphEditService.getLighterColor(this.colors[0]),
                    stack: 'expense',
                    stacking: 'normal',
                    cursor: 'pointer',
                    pointPadding: 0.2,
                    borderWidth: 0,
                    index: 1,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                });
            }
            series.push({
                data: spendData,
                name: this.$translate.instant('budgets.Spend'),
                type: 'column',
                colorIndex: 0,
                stack: 'expense',
                stacking: 'normal',
                cursor: 'pointer',
                pointPadding: 0.2,
                borderWidth: 0,
                index: 3,
                states: {
                    hover: {
                        enabled: true
                    }
                }
            });

            let isNoBudgetData = false;
            if (_.some(this.yearBudget, (item) => item.value && item.value > 0)) {
                series.push(this.budgetTargetSeries);
            } else {
                isNoBudgetData = true;
            }
            series.push(this.temperatureSeries);
            series.push(this.temperatureForecastSeries);
            this.yearlyChart = this.buildChart(series);
            if (isNoBudgetData) {
                this.$timeout(() => {
                    const html = [
                        `<span title="${this.$translate.instant('budgets.setup link tooltip')}">`,
                        this.$translate.instant('budgets.undefined info'),
                        ' ',
                        this.$translate.instant('budgets.setup link'),
                        '</span>'
                    ].join('');
                    const offsetX = (this.chart as any).plotLeft;
                    this.chart.renderer.text(html, offsetX + 30, 30)
                        .css({
                            color: '#4572A7',
                            fontSize: '16px',
                            cursor: 'pointer'
                        }).add().on('click', () => {
                            this.navigateToBudgets();
                        });
                }, 1000);
            }
        }
        public buildChart(series) {
            const getContext = () => this;
            const budgetYear = this.budgetYear;
            return {
                chart: {
                    height: 400,
                    animation: false
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    tickInterval: 1,
                    min: this.fiscalStartMonth,
                    max: this.fiscalStartMonth + 11,
                    labels: {
                        // tslint:disable-next-line:object-literal-shorthand
                        formatter: function () {
                            let month = this.value;
                            let year = parseInt(budgetYear);
                            if (month > 12) {
                                month -= 12;
                                year += 1;
                            }
                            return moment(`${month} ${year}`, 'M YYYY').format('MMM YYYY');
                        }
                    },
                    crosshair: true
                },
                yAxis: [{
                    title: { text: this.currencyUnitSymbol },
                    min: 0
                }, {
                    title: { text: this.temperatureUnit.unit },
                    opposite: true,
                    gridLineWidth: 0,
                    minorGridLineWidth: 0,
                    plotLines: [{
                        color: '#c8c8c8',
                        dashStyle: 'ShortDot',
                        width: 1,
                        value: 0
                    }]
                }],
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal',
                    itemStyle: {
                        fontSize: '11px'
                    },
                    itemHoverStyle: {
                        cursor: 'pointer',
                        color: 'blue'
                    }
                },
                plotOptions: {
                    column: {
                        grouping: false,
                        events: {
                            click: (ev) => {
                                if (ev && ev.point) {
                                    this.onSelectMonth(ev.point.x);
                                }
                            }
                        },
                        stacking: 'normal',
                        cursor: 'pointer'
                    },
                    series: {
                        animation: false,
                        marker: {
                            enabled: false
                        },
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                        events: {
                            // tslint:disable-next-line:object-literal-shorthand
                            legendItemClick: function () {
                                const seriesIndex = this.index;
                                const targetSeries = this.chart.series[seriesIndex];
                                _.each(this.chart.series, (s) => {
                                    if (s.name == targetSeries.name && s.index != seriesIndex) {
                                        if (targetSeries.visible) {
                                            s.hide();
                                        } else {
                                            s.show();
                                        }
                                    }
                                });
                            }
                        }
                    }
                },
                series,
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                tooltip: {
                    // tslint:disable-next-line:object-literal-shorthand
                    formatter: function () {
                        const context = getContext();
                        let month = this.x;
                        let year = parseInt(budgetYear);
                        if (month > 12) {
                            month -= 12;
                            year += 1;
                        }
                        const index = this.x - context.fiscalStartMonth;
                        const dayInfo = '<b>' + moment(`${month} ${year}`, 'M YYYY').format('MMM YYYY') + '</b>';
                        const color = context.colors[0];
                        const tableDataItem = context.tableData[index];
                        const totalContent = `<span style="color:${color};">■ </span>`
                            + `<span>${context.$translate.instant('budgets.Total Charge')}: ${tableDataItem.charge}</span>`;
                        const lighterColor = context.GraphEditService.getLighterColor(color);
                        const spendingDataItem = context.buildingSpending.spending.data[index];
                        let consumptionContent = '';
                        if (spendingDataItem && spendingDataItem.datum.consumption > 0) {
                            const value = context.$filter('currency')(spendingDataItem.datum.consumption, context.currencyUnitSymbol, 0);
                            consumptionContent = `<span style="color:${color};">■ </span>`
                                + `<span>${context.$translate.instant('budgets.Consumption')}: ${value}</span>`;
                        }
                        let projectionContent = '';
                        if (spendingDataItem && spendingDataItem.datum.projected > 0) {
                            const value = context.$filter('currency')(spendingDataItem.datum.projected, context.currencyUnitSymbol, 0);
                            projectionContent = `<span style="color:${lighterColor};">■ </span>`
                                + `<span>${context.$translate.instant('budgets.Projected')}: ${value}</span>`;
                        }
                        let subtitle = '';
                        let billedContent = '';
                        if (consumptionContent || projectionContent) {
                            if (spendingDataItem.datum.utilityBill > 0) {
                                const value = context.$filter('currency')(spendingDataItem.datum.utilityBill, context.currencyUnitSymbol, 0);
                                billedContent = `<span style="color:${color};">■ </span>`
                                    + `<span>${context.$translate.instant('budgets.Billed')}: ${value}</span>`;
                            }
                            subtitle = `<b>${context.$translate.instant('budgets.Total Charge breakdown')}:</b>`;
                        }
                        const temperatureKey = tableDataItem.temperatureType == 'historical' ? 'budgets.Temperature' : 'budgets.Temperature Forecast';
                        const degreeDaysContent = tableDataItem.temperature == '-' ? '' : `<span style="color:${seriesColors.weather};">■ </span>`
                            + `<span>${context.$translate.instant(temperatureKey)}: ${tableDataItem.temperature}</span>`;
                        let budgetPercent = null;
                        const budgetValue = index < context.yearBudget.length ? context.yearBudget[index].value : 0;
                        let totalCharge = null;
                        if (spendingDataItem) {
                            totalCharge = (spendingDataItem.datum.consumption || 0)
                                + (spendingDataItem.datum.projected || 0)
                                + (spendingDataItem.datum.utilityBill || 0);
                        }
                        let isRed = false;
                        if (budgetValue && totalCharge != null) {
                            budgetPercent = (Math.round(totalCharge * 1000 / budgetValue) / 10).toFixed(1);
                            isRed = totalCharge > budgetValue;
                        }
                        const budgetContent = index < context.yearBudget.length && context.yearBudget[index].value
                            ? `<span style="color:${isRed ? seriesColors.red : seriesColors.green};">■ </span>`
                            + `<span>${context.$translate.instant('budgets.Budget')}: ${tableDataItem.budget}</span>`
                            + (budgetPercent != null ? `<span ${isRed ? 'style="color:red"' : ''}> (${budgetPercent}%)</span>` : '')
                            : '';
                        return [
                            dayInfo,
                            totalContent,
                            budgetContent,
                            degreeDaysContent,
                            subtitle,
                            billedContent,
                            consumptionContent,
                            projectionContent
                        ].join('<br/>');
                    }
                }
            };
        }
        private getTemperatureSeries(temperature: Temperature) {
            const temperatureSeriesData = [];
            const date = moment(`${this.budgetYear} ${this.fiscalStartMonth}`, 'YYYY M');
            const now = moment();
            _.each(temperature.values, (value, index) => {
                if (moment(date).add(index, 'month').isAfter(now, 'month')) {
                    return;
                }
                const point = {
                    x: index + this.fiscalStartMonth,
                    y: this.getTemperatureValue(value)
                };
                temperatureSeriesData.push(point);
            });
            return {
                data: temperatureSeriesData,
                name: this.$translate.instant('budgets.Temperature'),
                type: 'line',
                color: seriesColors.weather,
                dashStyle: 'ShortDash',
                states: {
                    hover: {
                        enabled: true
                    }
                },
                yAxis: 1
            };
        }
        private getTemperatureForecastSeries(temperature: Temperature) {
            const temperatureForecastSeriesData = [];
            const date = moment(`${this.budgetYear} ${this.fiscalStartMonth}`, 'YYYY M');
            const now = moment();
            _.each(temperature.values, (value, index) => {
                const temp = _.isNil(value) ? temperature.climateNormals[index] : value;
                if (moment(date).add(index, 'month').isBefore(now, 'month')) {
                    return;
                }
                const point = {
                    x: index + this.fiscalStartMonth,
                    y: this.getTemperatureValue(temp)
                };
                temperatureForecastSeriesData.push(point);
            });
            return {
                data: temperatureForecastSeriesData,
                name: this.$translate.instant('budgets.Temperature Forecast'),
                type: 'line',
                color: seriesColors.weather,
                dashStyle: 'ShortDot',
                states: {
                    hover: {
                        enabled: true
                    }
                },
                yAxis: 1
            };
        }
        private getBudgetTargetSeries() {
            const budgetData = _.map(this.yearBudget, (item) => {
                let month = moment(item.startDate).add(1, 'day').month() + 1;
                if (month < this.fiscalStartMonth) {
                    month += 12;
                }
                return {
                    x: month,
                    y: item.value || 0
                };
            });
            return {
                data: budgetData,
                name: this.$translate.instant('budgets.Budget'),
                type: 'column',
                color: 'rgba(255, 255, 255, 0.0)',
                stack: 'budget',
                borderWidth: 0,
                pointPadding: 0,
                index: 6,
                showInLegend: false
            };
        }
        private getOccupancyForMonth(date: moment.Moment) {
            let occupancy = null;
            _.each(this.sortedOccupancy, (item: aq.propertySettings.BuildingOccupancy) => {
                if (date.isSameOrAfter(moment(item.startDate), 'month')) {
                    occupancy = item.occupancyPercent;
                }
            });
            return occupancy;
        }
        private getTemperatureValue(value) {
            if (value == null) {
                return null;
            }
            return Math.round(this.$filter<Function>('toTemperatureUnit')(value, this.temperatureUnit));
        }
    }
    angular.module('aq.utilityBudgets').controller('YearBudgetCtrl', YearBudgetCtrl);
}
