var aq;
(function (aq) {
    var deviceManagement;
    (function (deviceManagement) {
        var BulkUploadCtrl = /** @class */ (function (_super) {
            __extends(BulkUploadCtrl, _super);
            /* @ngInject */
            function BulkUploadCtrl(Auth, $mdDialog, $mdStepper, deviceCategories, Messages, classesByCategory, tenants, buildingId, RestangularV3, building, DeviceService) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.Auth = Auth;
                _this.$mdDialog = $mdDialog;
                _this.$mdStepper = $mdStepper;
                _this.deviceCategories = deviceCategories;
                _this.Messages = Messages;
                _this.classesByCategory = classesByCategory;
                _this.tenants = tenants;
                _this.buildingId = buildingId;
                _this.RestangularV3 = RestangularV3;
                _this.building = building;
                _this.DeviceService = DeviceService;
                _this.newDeviceList = {
                    name: '',
                    images: [],
                    building: null,
                    description: '',
                    location: '',
                    locationTag: null,
                    viewOnly: {
                        tenants: []
                    },
                    tenantLinks: [],
                    deviceCategory: null,
                    deviceClass: {
                        make: '',
                        series: '',
                        model: ''
                    },
                    serialNumber: null,
                    fields: {}
                };
                _this.measure = null;
                _this.step = 1;
                _this.measures = _this.extractMeterMeasuresFromClassesByCategory(classesByCategory);
                _this.newDeviceList.building = buildingId;
                return _this;
            }
            BulkUploadCtrl.prototype.filterName = function (query) {
                return function (item) {
                    return (item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0);
                };
            };
            BulkUploadCtrl.prototype.filterMeasure = function () {
                var _this = this;
                return function (item) {
                    if (_this.measure != null) {
                        return item.measure
                            ? item.measure.toLowerCase() === _this.measure.toLowerCase()
                            : false;
                    }
                    return true;
                };
            };
            BulkUploadCtrl.prototype.filterDeviceClassName = function (query) {
                return this.classesByCategory[this.newDeviceList.deviceCategory]
                    .filter(this.filterMeasure())
                    .filter(this.filterName(query));
            };
            BulkUploadCtrl.prototype.selectClass = function (deviceClassName) {
                if (deviceClassName) {
                    this.newDeviceList.deviceClass = _.pick(deviceClassName, ['make', 'model', 'series']);
                }
            };
            BulkUploadCtrl.prototype.cancel = function (data) {
                this.$mdDialog.cancel({ tenants: this.tenants, data: data });
            };
            BulkUploadCtrl.prototype.hide = function (data) {
                this.$mdDialog.hide({ tenants: this.tenants, data: data });
            };
            BulkUploadCtrl.prototype.next = function () {
                if (this.step === 2 && !this.hasDeviceClass(this.newDeviceList.deviceCategory)) {
                    this.step++;
                }
                this.$mdStepper('bulkUploadStepper').goto(this.step++);
            };
            BulkUploadCtrl.prototype.hasDeviceClass = function (deviceCategory) {
                return !deviceCategory || ['METER', 'NETWORKING', 'SENSOR'].indexOf(deviceCategory) >= 0;
            };
            BulkUploadCtrl.prototype.extractMeterMeasuresFromClassesByCategory = function (classesByCategory) {
                var measures = new Set(classesByCategory['METER']
                    .map(function (deviceClass) { return deviceClass.measure; })
                    .filter(function (measure) { return measure; }));
                return Array.from(measures);
            };
            BulkUploadCtrl.prototype.isAddingMeter = function () {
                return this.newDeviceList.deviceCategory === 'METER';
            };
            BulkUploadCtrl.prototype.getDeviceCategories = function () {
                return this.deviceCategories;
            };
            BulkUploadCtrl.prototype.getFields = function () {
                var _this = this;
                var deviceClassForRequest = _.pick(this.newDeviceList.deviceClass, ['make', 'model', 'series']);
                this.RestangularV3.all('devices').customGET('device-class', deviceClassForRequest).then(function (response) {
                    Object.keys(response.fields).forEach(function (key) {
                        if (response.fields[key].hasOwnProperty('value')) {
                            _this.newDeviceList.fields[key] = { value: response.fields[key].value };
                        }
                        else {
                            _this.newDeviceList.fields[key] = response.fields[key];
                        }
                    });
                }).catch(function (err) {
                    _this.Messages.error('Cannot find fields for device class');
                });
            };
            BulkUploadCtrl.prototype.downloadTemplate = function () {
                var csvColumns = ['name', 'serialNumber'].concat(Object.keys(this.newDeviceList.fields));
                var csvContent = 'data:text/csv;charset=utf-8,';
                csvContent += csvColumns + "\n";
                var fileName = this.building.name + " - " + this.newDeviceList.deviceClass.make + " " + this.newDeviceList.deviceClass.series + " " + this.newDeviceList.deviceClass.model + " upload";
                var link = document.createElement('a');
                link.setAttribute('href', encodeURI(csvContent));
                link.setAttribute('download', fileName);
                link.click();
            };
            BulkUploadCtrl.prototype.upload = function () {
                this.fileUploaded = true;
            };
            BulkUploadCtrl.prototype.generatePreview = function () {
                var lines = this.uploadData.split(/\r\n|\n|\r/);
                var result = [];
                var headers = lines[0].split(',');
                lines.shift();
                lines.forEach(function (line) {
                    var obj = {};
                    var splitLine = line.split(',');
                    for (var j = 0; j < headers.length; j++) {
                        obj["" + headers[j]] = splitLine[j];
                    }
                    result.push(obj);
                });
                this.generateDeviceCreationRequestObjects(result);
            };
            BulkUploadCtrl.prototype.generateDeviceCreationRequestObjects = function (json) {
                var _this = this;
                this.previewResponse = json.map(function (device) {
                    if (device.name === '') {
                        _this.$mdDialog.hide();
                        _this.Messages.error('Missing required field');
                        throw new Error('Missing required field');
                    }
                    var newDevice = JSON.parse(JSON.stringify(_this.newDeviceList));
                    newDevice.name = device['name'];
                    newDevice.serialNumber = device['serialNumber'];
                    Object.keys(newDevice.fields).forEach(function (key) {
                        newDevice.fields[key].value = device[key];
                    });
                    return newDevice;
                });
                this.createDevices(this.previewResponse);
            };
            ;
            BulkUploadCtrl.prototype.createDevices = function (devices) {
                var _this = this;
                return this.DeviceService.bulkCreate(devices).then(function (response) {
                    _this.$mdDialog.hide();
                    _this.Messages.success('Successfully created devices');
                }).catch(function (error) {
                    _this.$mdDialog.hide();
                    if (error.status === deviceManagement.DeviceDetail.HTTP_CODE_CONSTRAINT_VIOLATION) {
                        _this.Messages.error(error.data);
                    }
                    else {
                        _this.Messages.error('Error creating devices');
                    }
                });
            };
            return BulkUploadCtrl;
        }(aq.common.Controllers.ModalCtrl));
        deviceManagement.BulkUploadCtrl = BulkUploadCtrl;
        angular
            .module('deviceManagement')
            .controller('BulkUploadCtrl', BulkUploadCtrl);
    })(deviceManagement = aq.deviceManagement || (aq.deviceManagement = {}));
})(aq || (aq = {}));
