namespace aq.dashboard.widgets {

    export class ActivityWidgetCtrl {
        private config;
        private building: number;
        private configuredBuilding: number;
        private currentUser: aq.common.models.User;
        private isAqAdmin: boolean;

        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            config,
            private resolver: aq.services.Resolver,
            private Auth: aq.services.Auth,
            private RestangularV3: restangular.IService,
            private buildings: aq.common.models.Building[],
            private BuildingSelectorActions: aq.services.BuildingSelectorActions,
            private ModelUtilService: aq.services.ModelUtilService,
            private ActivityService: aq.services.ActivityService,
            private PrescriptionService: aq.services.PrescriptionService,
            private Segment: aq.services.SegmentService,
            private UserService: aq.services.UserService,
            private accountId: number
        ) {

            config.options = {
                buildings: this.ModelUtilService.pareProperties(this.buildings)
            };
            this.BuildingSelectorActions.initDefaultBuildingSelection(config);
            this.config = config;
            this.config.actions = {
                onBuildingChange: (cfg, buildingId) => {
                    cfg.buildingIds = [buildingId];
                }
            };
            this.$scope.config = config;
            this.onInit();
            if (!this.UserService.currentUser) {
                this.UserService.getUser().then((user) => this.currentUser = user);
            }
            this.isAqAdmin = this.UserService.isAdmin();
        }

        private onInit(): void {
            this.configuredBuilding = this.$scope.config.buildingIds[0] || void 0;
            if (!this.configuredBuilding) {
                const { id } = this.config.options.buildings[0];
                this.building = id;
            } else {
                this.building = this.configuredBuilding;
            }
        }
    }

    angular
        .module('aq.dashboard.widgets')
        .controller('ActivityWidgetCtrl', ActivityWidgetCtrl);
}
