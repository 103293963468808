namespace aq.models {

    angular.module('models', ['restangular'])
        .factory('DashboardsRestangular', function (Restangular) {
            return Restangular.withConfig(function (RestangularConfigurer) {});
        })
        .factory('DashboardService', (DashboardsRestangular: restangular.IService) => {
            const defaultInflate = 'account,publicDisplay,users';

            let service: IDashboardService = {
                list: list,
                get: get
            };

            function list(options) {
                options = options || {};
                options.page = options.page || 1;
                options.inflate = options.inflate || 'publicDisplay';
                return DashboardsRestangular.one('Dashboards').get(options);
            }

            function get(id: number, inflate = defaultInflate) {
                return DashboardsRestangular.one('Dashboards', id).get({ single:true, inflate: inflate });
            }

            return service;
        });

    export interface IDashboardService {
        list: ListFunc;
        get: GetFunc;
    }

    interface ListFunc { (options?: any): restangular.IPromise<any>; }
    interface GetFunc { (id: number, inflate?: string): restangular.IPromise<any>; }

}