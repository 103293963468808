/* eslint-disable max-len */
namespace aq.dashboard.widgets {

    interface Period {
        start: moment.Moment;
        end: moment.Moment;
    }
    interface BuildingItem {
        buildingId: string;
        name: string;
        imageUrl: string;
        dataItems: DataItem[];
    }
    export interface DataItemVariance {
        mainItem: DataItem;
        referenceItem: DataItem;
    }
    export interface DataItem {
        buildingId: string;
        measure: string;
        type: DataItemType;
        value: number | DataItemVariance;
        isBorder: boolean;
        unit?: string;
        display?: string;
    }
    enum DataItemType {
        VALUE = 'VALUE',
        VARIANCE = 'VARIANCE'
    }
    enum Comparison {
        PREVIOUS_PERIOD = 'PREVIOUS_PERIOD',
        SAME_MONTH_PRIOR_YEAR = 'SAME_MONTH_PRIOR_YEAR',
        TARGETS_AND_BUDGETS = 'TARGETS_AND_BUDGETS',
        NONE = 'NONE'
    }
    enum TimePeriod {
        LAST_MONTH = 'LAST_MONTH',
        CURRENT_MONTH = 'CURRENT_MONTH',
        LAST_YEAR = 'LAST_YEAR',
        CURRENT_YEAR = 'CURRENT_YEAR'
    }

    export class PortfolioVarianceCtrl {
        private isLoadingData: boolean;
        private selectedBuildingNames: string[];
        private displayBuildings: string[];
        private readonly displayBuildingsCount = 5;
        private promises = [];
        private loadingPercent = 0;
        private sortOrder: 'asc' | 'desc' = 'asc';
        private sortBy: number;
        private unitOrder = { '$': 1, 'kWh': 2, 'kW': 3 };

        /* @ngInject */
        constructor(
            private $scope,
            private config,
            private account,
            private buildings: aq.common.models.Building[],
            private buildingGroups,
            private ModelUtilService: aq.services.ModelUtilService,
            private DashboardOptionsService: aq.dashboard.DashboardOptionsService,
            private BuildingSelectorActions: aq.services.BuildingSelectorActions,
            private EnergyInsightsDataService: aq.energyInsights.service.EnergyInsightsDataService,
            private DataStore,
            private Restangular,
            private $q: ng.IQService,
            private Messages: aq.services.Messages,
        ) {
            this.selectedBuildingNames = [];
            if (typeof this.config.comparison === 'string') {
                const originalComp = this.config.comparison;
                this.config.comparison = [];
                if (originalComp !== Comparison.NONE) {
                    this.config.comparison.push(originalComp);
                }
            }
            if (!this.config.comparison) {
                this.config.comparison = [Comparison.PREVIOUS_PERIOD];
            }
            if (this.config.comparison.includes(Comparison.NONE)) {
                this.config.comparison = [];
            }
            if (!this.config.timePeriod) {
                this.config.timePeriod = TimePeriod.LAST_MONTH;
            }
            const timePresets = [
                {
                    value: TimePeriod.LAST_MONTH,
                    label: 'Last month'
                },
                {
                    value: TimePeriod.CURRENT_MONTH,
                    label: 'Current month'
                },
                {
                    value: TimePeriod.LAST_YEAR,
                    label: 'Last year'
                },
                {
                    value: TimePeriod.CURRENT_YEAR,
                    label: 'Current year'
                },
            ];
            const comparisons = [
                {
                    value: Comparison.PREVIOUS_PERIOD,
                    id: Comparison.PREVIOUS_PERIOD,
                    name: `Previous ${this.config.timePeriod.indexOf('_MONTH') > -1 ? 'month' : 'year'}`,
                    disabled: false
                },
                {
                    value: Comparison.SAME_MONTH_PRIOR_YEAR,
                    id: Comparison.SAME_MONTH_PRIOR_YEAR,
                    name: 'Same month prior year',
                    disabled: this.config.timePeriod.indexOf('_MONTH') === -1
                },
                {
                    value: Comparison.TARGETS_AND_BUDGETS,
                    id: Comparison.TARGETS_AND_BUDGETS,
                    name: 'Targets & Budgets',
                    disabled: false
                }
            ];
            this.config.options = {
                buildings: this.ModelUtilService.pareProperties(this.buildings, ['buildingGroup']),
                buildingGroups: this.ModelUtilService.pareProperties(this.buildingGroups),
                timePresets,
                comparisons
            };
            this.BuildingSelectorActions.initDefaultBuildingSelection(this.config);
            this.config.actions = this.BuildingSelectorActions;
            this.config.actions.onTimePeriodChange = (config) => {
                if (config.timePeriod.indexOf('_MONTH') > -1) {
                    config.options.comparisons[0].name = 'Previous month';
                    config.options.comparisons[1].disabled = false;
                } else {
                    config.options.comparisons[0].name = 'Previous year';
                    config.options.comparisons[1].disabled = true;
                }
            };
            this.config.actions.onComparisonChange = (config, data) => {
                if (!data || data.length === 0) {
                    config.isVariance = false;
                }
                config.comparison = data;
            };
            if (this.config.showBuildingImage == null) {
                this.config.showBuildingImage = true;
            }

            this.$scope.config = this.config;

            this.initOptions()
                .then(() => {
                    this.$scope.selectedMeasures = this.getSelectedMeasures(this.$scope.config.measures);
                    this.initDataItems();
                    this.getData();
                    this.initDisplayBuildings();
                });
        }

        private initDisplayBuildings() {
            this.displayBuildings = _.take(this.selectedBuildingNames, this.displayBuildingsCount);
        }

        private getData() {
            this.isLoadingData = true;

            const { buildingIds } = this.$scope.config;
            this.selectedBuildingNames = buildingIds.map(buildingId => {
                const building: Building = this.buildings.find(b => b.id === buildingId);
                return building.name;
            });

            this.$scope.selectedMeasures.forEach(measure => {
                buildingIds.forEach(buildingId => {
                    const building: Building = this.buildings.find(b => b.id === buildingId);
                    const period = this.getTimePeriodForBuilding(building);

                    if (measure.indexOf('CURRENCY_') > -1) {
                        this.promises.push(
                            this.EnergyInsightsDataService.getUtilitySpendingCostBreakdown(
                                building, period.start, period.end, '1mon', measure.replace('CURRENCY_', '')
                            ).then((response: aq.utilityBudgets.UtilitySpendingCumulativeResponse) => {
                                const value = this.getUtilitySpendingResponseValue(response);
                                this.populateValue(buildingId, measure, value);
                            })
                        );
                    } else {
                        const metric = this.getMeasureMetric(measure);
                        if (measure === 'KW') {
                            const metricRequests: ng.IPromise<any>[] = this.EnergyInsightsDataService.getPeakDataPromises(
                                building, period.start, period.end, metric, this.account
                            ) as any;
                            if (metricRequests.length > 0) {
                                this.promises.push(...metricRequests);
                                this.$q.all(metricRequests).then((responses) => {
                                    let maxValue = 0;
                                    responses.forEach(response => {
                                        const value = this.getPeakDemandResponseValue(response);
                                        if (value > maxValue) {
                                            maxValue = value;
                                        }
                                    });
                                    this.populateValue(buildingId, measure, maxValue);
                                });
                            }
                        } else {
                            this.promises.push(
                                this.EnergyInsightsDataService.getEnergy(
                                    building, period.start, period.end, metric, '1mon', this.account
                                ).then((data: aq.energyInsights.service.DataValue[]) => {
                                    const value = this.getConsumptionOrBudgetItemsResponseValue(data);

                                    if (this.$scope.config.isProjections && this.$scope.config.timePeriod.indexOf('CURRENT') > -1) {
                                        // WORKAROUND: get consumption projections from utility spending projections for current month
                                        const currentMonthStart = aq.common.TimeConfiguration.This.Month.start(building.timeZoneId);
                                        return this.EnergyInsightsDataService.getUtilitySpendingCostBreakdown(
                                            building, currentMonthStart, period.end, '1mon', 'ELECTRICITY'
                                        ).then((response: aq.utilityBudgets.UtilitySpendingCumulativeResponse) => {
                                            let projectedValue = 0;
                                            if (response.spending && response.spending.data) {
                                                response.spending.data.forEach(d => {
                                                    projectedValue += (d.datum.projected || 0) / d.datum.blendedRate;
                                                });
                                            }
                                            return value + projectedValue;
                                        });
                                    }
                                    return this.$q.when(value);
                                }).then((value) => {
                                    this.populateValue(buildingId, measure, value);
                                })
                            );
                        }
                    }

                    this.$scope.period = period;

                    if (this.$scope.config.comparison.includes(Comparison.PREVIOUS_PERIOD)) {
                        const trendPeriod = this.getTrendPeriod(period, Comparison.PREVIOUS_PERIOD);

                        if (measure.indexOf('CURRENCY_') > -1) {
                            this.promises.push(
                                this.EnergyInsightsDataService.getUtilitySpendingCostBreakdown(
                                    building, trendPeriod.start, trendPeriod.end, '1mon', measure.replace('CURRENCY_', '')
                                ).then((response: aq.utilityBudgets.UtilitySpendingCumulativeResponse) => {
                                    const value = this.getUtilitySpendingResponseValue(response);
                                    this.populateValue(buildingId, this.getMeasureReferenceIdentifier(measure, Comparison.PREVIOUS_PERIOD), value);
                                })
                            );
                        } else {
                            const metric = this.getMeasureMetric(measure);

                            if (measure === 'KW') {
                                if (this.$scope.config.timePeriod.indexOf('_MONTH') > -1) {
                                    const metricRequests = this.EnergyInsightsDataService.getPeakDataPromises(
                                        building, trendPeriod.start, trendPeriod.end, metric, this.account
                                    );
                                    if (metricRequests.length > 0) {
                                        this.promises.push(metricRequests[0]);
                                        metricRequests[0].then((response) => {
                                            const value = this.getPeakDemandResponseValue(response);
                                            this.populateValue(buildingId, this.getMeasureReferenceIdentifier(measure, Comparison.PREVIOUS_PERIOD), value);
                                        });
                                    }
                                }
                            } else {
                                this.promises.push(
                                    this.EnergyInsightsDataService.getEnergy(
                                        building, trendPeriod.start, trendPeriod.end, metric, '1mon', this.account
                                    ).then((data: aq.energyInsights.service.DataValue[]) => {
                                        const value = this.getConsumptionOrBudgetItemsResponseValue(data);
                                        this.populateValue(buildingId, this.getMeasureReferenceIdentifier(measure, Comparison.PREVIOUS_PERIOD), value);
                                    })
                                );
                            }
                        }

                        this.$scope.trendPeriod = trendPeriod;
                    }
                    if (this.$scope.config.comparison.includes(Comparison.SAME_MONTH_PRIOR_YEAR)) {
                        const trendPeriod = this.getTrendPeriod(period, Comparison.SAME_MONTH_PRIOR_YEAR);

                        if (measure.indexOf('CURRENCY_') > -1) {
                            this.promises.push(
                                this.EnergyInsightsDataService.getUtilitySpendingCostBreakdown(
                                    building, trendPeriod.start, trendPeriod.end, '1mon', measure.replace('CURRENCY_', '')
                                ).then((response: aq.utilityBudgets.UtilitySpendingCumulativeResponse) => {
                                    const value = this.getUtilitySpendingResponseValue(response);
                                    this.populateValue(buildingId, this.getMeasureReferenceIdentifier(measure, Comparison.SAME_MONTH_PRIOR_YEAR), value);
                                })
                            );
                        } else {
                            const metric = this.getMeasureMetric(measure);

                            if (measure === 'KW') {
                                if (this.$scope.config.timePeriod.indexOf('_MONTH') > -1) {
                                    const metricRequests = this.EnergyInsightsDataService.getPeakDataPromises(
                                        building, trendPeriod.start, trendPeriod.end, metric, this.account
                                    );
                                    if (metricRequests.length > 0) {
                                        this.promises.push(metricRequests[0]);
                                        metricRequests[0].then((response) => {
                                            const value = this.getPeakDemandResponseValue(response);
                                            this.populateValue(buildingId, this.getMeasureReferenceIdentifier(measure, Comparison.SAME_MONTH_PRIOR_YEAR), value);
                                        });
                                    }
                                }
                            } else {
                                this.promises.push(
                                    this.EnergyInsightsDataService.getEnergy(
                                        building, trendPeriod.start, trendPeriod.end, metric, '1mon', this.account
                                    ).then((data: aq.energyInsights.service.DataValue[]) => {
                                        const value = this.getConsumptionOrBudgetItemsResponseValue(data);
                                        this.populateValue(buildingId, this.getMeasureReferenceIdentifier(measure, Comparison.SAME_MONTH_PRIOR_YEAR), value);
                                    })
                                );
                            }
                        }

                        this.$scope.trendPeriod = trendPeriod;
                    }
                    if (this.$scope.config.comparison.includes(Comparison.TARGETS_AND_BUDGETS)) {
                        if (!this.$scope.config.comparison.includes(Comparison.SAME_MONTH_PRIOR_YEAR) && !this.$scope.config.comparison.includes(Comparison.PREVIOUS_PERIOD)) {
                            this.$scope.trendPeriod = null;
                        }

                        if (measure.indexOf('CURRENCY_') > -1) {
                            this.promises.push(
                                this.EnergyInsightsDataService.getBudgetItems(
                                    building, measure.replace('CURRENCY_', '').toLowerCase(), period.start, period.end, '1mon', this.account
                                ).then((budgetData: aq.propertySettings.TargetItem[]) => {
                                    const value = this.getConsumptionOrBudgetItemsResponseValue(budgetData, period.end.date(), building.timeZoneId);
                                    this.populateValue(buildingId, this.getMeasureReferenceIdentifier(measure, Comparison.TARGETS_AND_BUDGETS), value);
                                })
                            );
                        } else if (measure === 'KWH') { // currently targets exist only for 'kW and kWh'
                            const metric = this.getMeasureMetric(measure);
                            this.promises.push(
                                this.EnergyInsightsDataService.getTargetConsumption(
                                    building, period.start, period.end, metric, '1mon', this.account
                                ).then((targetConsumptionData: aq.propertySettings.TargetItem[]) => {
                                    const value = this.getConsumptionOrBudgetItemsResponseValue(targetConsumptionData, period.end.date(), building.timeZoneId);
                                    this.populateValue(buildingId, this.getMeasureReferenceIdentifier(measure, Comparison.TARGETS_AND_BUDGETS), value);
                                })
                            );
                        } else if (measure === 'KW') {
                            if (this.$scope.config.timePeriod.indexOf('_MONTH') > -1) {
                                this.promises.push(
                                    this.DataStore.getList(
                                        this.Restangular
                                            .one('accounts', this.account.id)
                                            .one('buildings', building.id)
                                            .one('Targets'), 'queryTargets', {
                                        startDate: period.start.format(),
                                        endDate: period.end.format(),
                                        targetType: 'PEAK_DEMAND'
                                    }
                                    ).then((targets: aq.propertySettings.TargetItem[]) => {
                                        const value = this.getPeakDemandTargetValue(targets);
                                        this.populateValue(buildingId, this.getMeasureReferenceIdentifier(measure, Comparison.TARGETS_AND_BUDGETS), value);
                                    })
                                );
                            }
                        }
                    }
                });
            });

            const totalPromises = this.promises.length;
            let completedPromises = 0;
            _.each(this.promises, (p) => {
                p.finally(() => {
                    completedPromises++;
                    this.loadingPercent = Math.round(completedPromises * 100 / totalPromises);
                    if (completedPromises === totalPromises) {
                        this.isLoadingData = false;
                    }
                });
            });
        }

        private toggleSortByField(index) {
            if (index === this.sortBy) {
                this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
            } else {
                this.sortBy = index;
                this.sortOrder = 'asc';
            }
            const items: BuildingItem[] = this.$scope.buildingItems;
            this.sortBuildingItemsByDataItemFieldIndex(items, index);
        }

        private sortBuildingItemsByDataItemFieldIndex(items: BuildingItem[], index: number) {
            items.sort((a, b) => {
                let value = 0;
                if (a.dataItems[index].type === DataItemType.VALUE) {
                    value = this.getOrderByDataItemValue(a.dataItems[index], b.dataItems[index]);
                } else if (a.dataItems[index].type === DataItemType.VARIANCE) {
                    value = this.getOrderByDataItemVariance(a.dataItems[index], b.dataItems[index]);
                }
                if (this.sortOrder === 'desc') {
                    value = -1 * value;
                }
                return value;
            });
        }

        private getOrderByDataItemValue(dataItemA: DataItem, dataItemB: DataItem): number {
            let value;
            if (!dataItemA.value) {
                value = this.sortOrder === 'desc' ? -1 : 1;
            } else if (!dataItemB.value) {
                value = this.sortOrder === 'desc' ? 1 : -1;
            } else {
                value = Math.sign((dataItemA.value as number) - (dataItemB.value as number));
            }
            return value;
        }

        private getOrderByDataItemVariance(dataItemA: DataItem, dataItemB: DataItem): number {
            const varianceItemA = dataItemA.value as DataItemVariance;
            const varianceItemB = dataItemB.value as DataItemVariance;
            let value;

            if (!varianceItemA.mainItem.value || !varianceItemA.referenceItem.value) {
                value = this.sortOrder === 'desc' ? -1 : 1;
            } else if (!varianceItemB.mainItem.value || !varianceItemB.referenceItem.value) {
                value = this.sortOrder === 'desc' ? 1 : -1;
            } else {
                const deltaA = (varianceItemA.mainItem.value as number) - (varianceItemA.referenceItem.value as number);
                const ratioA = deltaA / (varianceItemA.referenceItem.value as number);
                const deltaB = (varianceItemB.mainItem.value as number) - (varianceItemB.referenceItem.value as number);
                const ratioB = deltaB / (varianceItemB.referenceItem.value as number);
                value = Math.sign(ratioA - ratioB);
            }
            return value;
        }

        private getPeakDemandResponseValue(data: { values: number[] }) {
            let max = null;
            if (data && data.values) {
                data.values.forEach(v => {
                    if (v > 0 && (max == null || max < v)) {
                        max = v;
                    }
                });
            }
            return max ? Math.round(max / 1000) : null;
        }

        private getPeakDemandTargetValue(data: { value: number }[]) {
            if (data && data.length > 0) {
                return (data[0].value || 0);
            }
            return null;
        }

        private getConsumptionOrBudgetItemsResponseValue(data: { value: number }[], currentDay = null, timezone = null) {
            let value = 0;
            if (data) {
                data.forEach((d, index) => {
                    if (d) {
                        if (index === data.length - 1 && !this.$scope.config.isProjections && currentDay && timezone) {
                            // calculate ratio of the last item => current month without projections
                            const selectedMonth = moment(d['endDate']);
                            const totalDays = selectedMonth.daysInMonth();
                            const totalMinutesInDay = 1440;
                            const currentMinutes = moment().tz(timezone).hours() * 60 + moment().tz(timezone).minutes();
                            const currentDayRatio = (currentMinutes - 1) / totalMinutesInDay;
                            const totalRatio = currentDay === totalDays ? currentDay / totalDays : (currentDay - 1 + currentDayRatio) / totalDays;

                            value += Math.round((d.value || 0) * totalRatio);
                        } else {
                            value += (d.value || 0);
                        }
                    }
                });
            }
            return Math.round(value);
        }

        private getUtilitySpendingResponseValue(response: aq.utilityBudgets.UtilitySpendingCumulativeResponse): number {
            let value = 0;
            if (response.spending && response.spending.data) {
                response.spending.data.forEach(d => {
                    value += (d.datum.utilityBill || 0) + (d.datum.consumption || 0);
                    if (this.$scope.config.isProjections) {
                        value += (d.datum.projected || 0);
                    }
                });
            }
            return value;
        }

        private getTrendPeriod(mainPeriod: Period, comparison: string): Period {
            const { timePeriod } = this.$scope.config;
            const period: Period = {
                start: null,
                end: null
            };
            if (comparison === Comparison.PREVIOUS_PERIOD) {
                if (timePeriod.indexOf('_MONTH') > -1) {
                    period.start = moment(mainPeriod.start).subtract(1, 'month');
                    period.end = moment(mainPeriod.end).subtract(1, 'month');
                } else if (timePeriod.indexOf('_YEAR') > -1) {
                    period.start = moment(mainPeriod.start).subtract(1, 'year');
                    period.end = moment(mainPeriod.end).subtract(1, 'year');
                }
            }
            if (comparison === Comparison.SAME_MONTH_PRIOR_YEAR) {
                if (timePeriod.indexOf('_MONTH') > -1) {
                    period.start = moment(mainPeriod.start).subtract(1, 'year');
                    period.end = moment(mainPeriod.end).subtract(1, 'year');
                }
            }
            return period;
        }

        private populateValue(buildingId, measure, value) {
            const buildingItem: BuildingItem = this.$scope.buildingItems.find((item: BuildingItem) => item.buildingId == buildingId);
            const dataItem = buildingItem.dataItems.find(item => item.type == DataItemType.VALUE && item.measure == measure);
            dataItem.value = value;
        }

        private getMeasureMetric(measure: string) {
            const { allUnits } = this.$scope.config.options;
            let result = null;
            Object.keys(allUnits).forEach(key => {
                const units = allUnits[key];
                const metric = units ? units.find(unit => unit.value == measure) : null;
                if (metric) {
                    result = metric;
                }
            });
            return result;
        }

        private getTimePeriodForBuilding(building: Building): Period {
            const tz = building.timeZoneId;
            const period: Period = {
                start: null,
                end: null
            };
            switch (this.$scope.config.timePeriod) {
                case 'LAST_MONTH':
                    period.start = aq.common.TimeConfiguration.Last.Month.start(tz);
                    period.end = aq.common.TimeConfiguration.Last.Month.end(tz);
                    break;
                case 'CURRENT_MONTH':
                    period.start = aq.common.TimeConfiguration.This.Month.start(tz);
                    period.end = this.$scope.config.isProjections ? aq.common.TimeConfiguration.This.Month.end(tz) : moment().tz(tz);
                    break;
                case 'LAST_YEAR':
                    period.start = aq.common.TimeConfiguration.Last.Year.start(tz);
                    period.end = aq.common.TimeConfiguration.Last.Year.end(tz);
                    break;
                case 'CURRENT_YEAR':
                    period.start = aq.common.TimeConfiguration.This.Year.start(tz);
                    // NOTE: we calculate projections only until the end of month, so we can't set interval at the end of year
                    period.end = this.$scope.config.isProjections ? aq.common.TimeConfiguration.This.Month.end(tz) : moment().tz(tz);
                    break;
                default:
                    break;
            }
            return period;
        }

        private initDataItems() {
            this.$scope.buildingItems = this.buildings
                .filter(b => this.$scope.config.buildingIds.indexOf(b.id) > -1)
                .map(building => {
                    return {
                        buildingId: building.id,
                        name: building.name,
                        imageUrl: building.imageUrl,
                        dataItems: this.getEmptyDataItems(building.id)
                    };
                });
            this.$scope.headers = this.getHeaders();
            this.$scope.headerGroups = this.getHeaderGroups();
        }

        private getHeaders() {
            const headers = [];
            this.$scope.selectedMeasures.forEach((m, index) => {
                const isLastMeasure = index === this.$scope.selectedMeasures.length - 1;
                const mainItemHeader = this.getMainItemHeader(m);
                headers.push({
                    name: mainItemHeader,
                    isBorder: !isLastMeasure && this.$scope.config.comparison.length === 0,
                    measurement: m
                });

                this.$scope.config.comparison.forEach((comp, i) => {
                    const referenceItemHeader = this.getReferenceItemHeader(m, comp);
                    const referenceLength = this.$scope.selectedMeasures.length * this.$scope.config.comparison.length;
                    const selectedMeasurePosition = index + 1;
                    const selectedCompPosition = i + 1;
                    const combinedIndex = selectedMeasurePosition * selectedCompPosition;
                    headers.push({
                        name: referenceItemHeader,
                        isBorder: !this.$scope.config.isVariance && selectedCompPosition === this.$scope.config.comparison.length
                            && combinedIndex < referenceLength,
                        measurement: m
                    });

                    if (this.$scope.config.isVariance) {
                        const varianceItemHeader = this.getVarianceItemHeader(m, comp);
                        headers.push({
                            name: varianceItemHeader,
                            isBorder: !isLastMeasure && (i === this.$scope.config.comparison.length - 1),
                            measurement: m
                        });
                    }
                });
            });
            return headers;
        }

        private getHeaderGroups() {
            const groups = [];
            this.$scope.selectedMeasures.forEach(m => {
                const metric = this.getMeasureMetric(m);
                const header = this.getMeasureHeader(m);
                groups.push({
                    name: `${metric.serviceType} ${header} (${metric.unit})`,
                    colspan: 1 + this.$scope.config.comparison.length + (this.$scope.config.isVariance ? this.$scope.config.comparison.length : 0)
                });
            });
            return groups;
        }

        private getVarianceItemHeader(measure: string, comparison: Comparison) {
            const referencePrefix = this.getReferenceItemPrefix(measure, comparison);
            return `variance (over ${referencePrefix})`;
        }

        private getMainItemHeader(measure: string) {
            const name = this.getMeasureHeader(measure);
            const isEstimated = this.$scope.config.timePeriod.indexOf('CURRENT_') > -1
                && measure.indexOf('CURRENCY_') > -1
                && this.$scope.config.isProjections;
            if (isEstimated) {
                return `estimated ${name}`;
            }
            return name;
        }

        private getReferenceItemHeader(measure: string, comparison: Comparison) {
            let name = this.getMeasureHeader(measure);
            const prefix = this.getReferenceItemPrefix(measure, comparison);
            name = `${prefix} ${name}`;
            return name;
        }

        private getMeasureHeader(measure: string) {
            if (measure.indexOf('CURRENCY_') > -1) {
                return 'spend';
            }
            if (measure == 'KW') {
                return 'peak demand';
            }
            return 'consumption';
        }

        private getReferenceItemPrefix(measure: string, comparison: Comparison) {
            const { timePeriod } = this.$scope.config;
            let prefix = '';
            if (comparison === Comparison.PREVIOUS_PERIOD) {
                prefix = (timePeriod == TimePeriod.CURRENT_MONTH || timePeriod == TimePeriod.LAST_MONTH)
                    ? 'previous month'
                    : 'previous year';
            }
            if (comparison === Comparison.SAME_MONTH_PRIOR_YEAR) {
                prefix = 'prior year';
            }
            if (comparison === Comparison.TARGETS_AND_BUDGETS) {
                prefix = measure.indexOf('CURRENCY_') > -1 ? 'budgeted' : 'target';
            }
            return prefix;
        }

        private getEmptyDataItems(buildingId: string) {
            const dataItems: DataItem[] = [];
            this.$scope.selectedMeasures.forEach((m, index) => {
                const isLastMeasure = index === this.$scope.selectedMeasures.length - 1;
                const metric = this.getMeasureMetric(m);
                const mainItem: DataItem = {
                    buildingId,
                    measure: m,
                    type: DataItemType.VALUE,
                    value: null,
                    unit: metric.unit,
                    display: null,
                    isBorder: !isLastMeasure && this.$scope.config.comparison.length === 0
                };
                dataItems.push(mainItem);


                this.$scope.config.comparison.forEach((comp, i) => {
                    const referenceLength = this.$scope.selectedMeasures.length * this.$scope.config.comparison.length;
                    const selectedMeasurePosition = index + 1;
                    const selectedCompPosition = i + 1;
                    const combinedIndex = selectedMeasurePosition * selectedCompPosition;
                    const referenceItem: DataItem = {
                        buildingId,
                        measure: this.getMeasureReferenceIdentifier(m, comp),
                        type: DataItemType.VALUE,
                        value: null,
                        unit: metric.unit,
                        display: null,
                        isBorder: !this.$scope.config.isVariance && selectedCompPosition === this.$scope.config.comparison.length
                            && combinedIndex < referenceLength
                    };
                    dataItems.push(referenceItem);

                    if (this.$scope.config.isVariance) {
                        const varianceItem: DataItem = {
                            buildingId,
                            measure: '',
                            type: DataItemType.VARIANCE,
                            value: {
                                mainItem,
                                referenceItem
                            },
                            unit: metric.unit,
                            isBorder: !isLastMeasure && (i === this.$scope.config.comparison.length - 1)
                        };
                        dataItems.push(varianceItem);
                    }
                });
            });
            return dataItems;
        }

        private getMeasureReferenceIdentifier(measure: string, comparisonPeriod: Comparison) {
            return `${measure}/REFERENCE/${comparisonPeriod}`;
        }

        private initOptions() {
            return this.DashboardOptionsService.init(this.account.id, this.account.measurementSystem, this.account.currencyUnit).then(() => {
                const allNonConvertedUnits = this.DashboardOptionsService.getUnits();
                const allEligibleUnits = allNonConvertedUnits.filter(unit => {
                    return unit.field == 'total' && unit.isSummable == true && unit.isDefault == true || unit.unit == 'kW';
                });
                allEligibleUnits.forEach(unit => delete unit.originalElement);
                const allUnits: Record<any, any> = this.DashboardOptionsService.organizeUnitsByServiceType(allEligibleUnits);
                if (allUnits.ENVIRONMENT) {
                    delete allUnits.ENVIRONMENT;
                }
                this.$scope.config.options.allUnits = this.reorderUnits(allUnits);
            });
        }

        private sortUnits(unit: any) {
            unit.sort((a, b) => {
                const aUnit = this.unitOrder[a.unit] != null ? this.unitOrder[a.unit] : 3;
                const bUnit = this.unitOrder[b.unit] != null ? this.unitOrder[b.unit] : 3;
                return aUnit - bUnit;
            });
        }

        private reorderUnits(allUnits: Record<any, any>) {
            Object.keys(allUnits).forEach(measure => {
                this.sortUnits(allUnits[measure]);
            });
            return allUnits;
        }

        private getSelectedMeasures(measures) {
            // match measure selector ordering
            const result = [];
            const { allUnits } = this.$scope.config.options;
            Object.keys(allUnits).forEach(key => {
                if (allUnits[key]) {
                    allUnits[key].forEach(unit => {
                        if (measures.indexOf(unit.value) > -1) {
                            result.push(unit.value);
                        }
                    });
                }
            });
            return result;
        }

        private parseBuildingData(dataItems: DataItem[]) {
            return dataItems.map(datum => {
                if (datum.type !== DataItemType.VARIANCE) {
                    return `${datum.value}`;
                }

                const varianceObj = datum.value as DataItemVariance;
                const { mainItem, referenceItem } = varianceObj;
                const currVal = mainItem.value as number;
                const targetVal = referenceItem.value as number;
                const variance = (currVal - targetVal) / targetVal;
                return variance;
            });
        }

        private exportToCsv() {
            try {
                let csvContent = 'data:text/csv;charset=utf-8,';
                const fileName = `Portfolio_Variance_${moment().format('YYYY-MM-DD')}.csv`;
                const { headers, buildingItems } = this.$scope;
                let headerTitles = ['building'];
                headerTitles = headerTitles.concat(headers.map(header => { return header.measurement.includes('CURRENCY') ? `${header.name} ($)` : `${header.name} (${header.measurement})`; }));

                csvContent += headerTitles.join();
                csvContent += '\n';

                buildingItems.forEach(building => {
                    let buildingRow = [building.name];
                    const buildingData = this.parseBuildingData(building.dataItems);
                    buildingRow = buildingRow.concat(buildingData);
                    csvContent += buildingRow.join();
                    csvContent += '\n';
                });

                const link = document.createElement('a');
                link.setAttribute('href', encodeURI(csvContent));
                link.setAttribute('download', fileName);
                link.click();
            } catch {
                this.Messages.error('An error occurred while attempting to export the data');
            }
        }
    }

    angular.module('aq.dashboard.widgets').controller('PortfolioVarianceCtrl', PortfolioVarianceCtrl);
}
