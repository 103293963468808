angular.module('aq.accounts.calendars').controller('RuleListCtrl',
    function($scope, $state, $stateParams, CalendarData, EventPriority, Messages, Sortable) {

    $scope.$watch('rule', function(rule) {
        if (!rule) return;
        $state.transitionTo('aq.accounts.calendars.rules.views.form', {
            accountId: $scope.account.id ,
            calendarId: $scope.calendar.id,
            ruleId: $scope.rule.id
        });
    })

    $scope.droppedOnRules = function () {
        //
    }

    $scope.reorderEvent= function ($event) {
        var ruleFrom = $scope.workCalendarRules[$event.from];

        ruleFrom.priority = Sortable.calculatePriority($scope.workCalendarRules, $event.from, $event.to);
        ruleFrom.put();
    }

});