namespace aq.admin.dashboards {

    export class AdminDashboardsCtrl {
        /* @ngInject */
        constructor(
            private $scope: AdminDashboardsCtrlScope,
            private Restangular: restangular.IService,
            private $mdDialog: ng.material.IDialogService,
            private Messages,
            private $filter,
            private $state,
            private dashboards,
            private accounts,
            private DashboardService: aq.models.IDashboardService,
            private loading
        ) {
            // This is to make it compatible with our order by when we query the db
            this.accounts = _.map(this.accounts, (account) => {
                account.accountName = account.name;
                return account;
            });
            this.setDashboards(this.dashboards.dashboards);
            this.$scope.table = {
                searchText: '',
                order: ''
            };
            this.$scope.paging = {
                page: 1,
                limit: 10,
                total: this.dashboards.count
            };
        }

        update(dashboard) {
            this.$mdDialog.show({
                controller: 'DashboardCRUDCtrl as ctrl',
                templateUrl: 'app/admin/dashboards/crud/crud.html',
                clickOutsideToClose: true,
                locals: {
                    dashboard: this.DashboardService.get(dashboard.id, 'account,configuration')
                }
            }).then((updatedDashboard) => {
                if (updatedDashboard) {
                    _.replaceItemById(this.$scope.dashboards, updatedDashboard);
                } else {
                    _.remove(this.$scope.dashboards, (d) => { return d.id == dashboard.id; });
                }
            });
        }

        setDashboards(dashboards) {
            this.$scope.dashboards = _.map(dashboards, (dashboard: Dashboard) => {
                dashboard.account = _.find(this.accounts, {id: dashboard.account}) || null;
                return dashboard;
            });
        }

        getQueryParams() {
            const desc = this.$scope.table.order.indexOf('-') === 0;
            const orderBy = desc ? this.$scope.table.order.substring(1) : this.$scope.table.order;
            const options: any = {
                page: this.$scope.paging.page
            };
            if (this.$scope.table.searchText) {
                options.search = this.$scope.table.searchText;
            }
            if (orderBy) {
                options.orderBy = orderBy;
                options.asc = !desc;
            }
            return options;
        }

        // Need to define like this so we can pass in to md-table-pagination
        public search = () => {
            this.loading.start(true);
            return this.DashboardService.list(this.getQueryParams())
                .then((result) => {
                    this.setDashboards(result.dashboards);
                    this.$scope.paging.total = result.count;
                    this.loading.stop();
                });
        }

        viewDashboard(dashboard): void {
            this.$state.transitionTo('aq.dashboard.configurable', {
                accountId: dashboard.account.id,
                dashboardId: dashboard.id
            });
        }

        saveDashboardEnabledChange(dashboard) {
            const status = dashboard.enabled ? 'active' : 'inactive';
            if (dashboard.put === undefined) {
                dashboard = this.Restangular.restangularizeElement(null, dashboard, 'Dashboards');
            }
            dashboard.put()
                .then(() => {
                    this.Messages.info(`Toggled ${dashboard.name} to ${status}`);
                })
                .catch(() => {
                    this.Messages.info(`Unable to toggle ${dashboard.name}`);
                });
        }
    }

    interface AdminDashboardsCtrlScope extends ng.IScope {
        dashboards: Dashboard[];
        currentDashboards: Dashboard[];
        accounts: aq.common.models.Account[];
        paging: {
            page: number,
            limit: number,
            total: number
        };
        table: {
            order: string;
            searchText: string;
        };
    }

}

angular.module('aq.admin').controller('AdminDashboardsCtrl', aq.admin.dashboards.AdminDashboardsCtrl);
