namespace aq.reports {

    export class InvoiceDefaultCtrl extends BaseDocraptorReportCtrl {
        public totalUsage;
        public totalUsageUnit;
        public chartConfig;
        public currencyUnit;
        public residentsNames = 'Unoccupied';
        public tenantChargeStart: string;
        public tenantChargeEnd: string;

        /* @ngInject */
        constructor(
            private invoice: aq.tenantbilling.TenantInvoice,
            private tenant: aq.tenantbilling.Tenant,
            private setup: aq.tenantbilling.BillingSetup,
            private billing: aq.tenantbilling.MonthlyTenantBilling,
            private tenantChargeValues: aq.tenantbilling.TenantChargeValue[],
            private historicalUsage: aq.tenantbilling.TenantInvoiceHistoricalUsage[],
            private SeriesMapping: SeriesMapping,
            private OptionsService,
            private residents: aq.tenantbilling.ResidentModel[],
            private TenantServiceHelper: aq.tenantbilling.TenantServiceHelper,
            private Auth: aq.services.Auth,
            protected $timeout: ng.ITimeoutService
        ) {
            super($timeout);
            this.currencyUnit = OptionsService.currencyUnit();
            this.setUsageTotals(this.tenantChargeValues);
            this.chartConfig = this.getChartConfig(this.historicalUsage);
            const [chargeValue] = this.tenantChargeValues;
            this.tenantChargeStart = chargeValue && chargeValue.startDate ? chargeValue.startDate.toString() : '';
            this.tenantChargeEnd = chargeValue && chargeValue.endDate ? chargeValue.endDate.toString() : '';
            this.residentsNames = this.formatResidentNames();
            this.notifyDocumentReady();
        }

        public formatResidentNames(): string {
            if (!this.invoice.lease) {
                return 'Unoccupied';
            }
            let names = '';
            this.residents.forEach((resident) => {
                if (resident === [...this.residents].pop()) {
                    names += resident.name;
                } else {
                    names += `${resident.name}, `;
                }
            });
            return names;
        }

        public setUsageTotals(chargeValues: aq.tenantbilling.TenantChargeValue[]) {
            const uniqueUnits = _(chargeValues).map('usageUnit').uniq().size();
            if (uniqueUnits === 1) {
                this.totalUsage = _(chargeValues).map('appliedTotal').sum();
                this.totalUsageUnit = chargeValues[0].usageUnit;
            }
        }

        public isChargeNil(charge) {
            return _.isNil(charge);
        }

        public getChartConfig(invoices: aq.tenantbilling.TenantInvoiceHistoricalUsage[]) {
            const monthLabels = this.getMonthLabels(invoices);
            const series = this.getSeries(invoices);
            return {
                chart: {
                    type: 'column',
                    height: 400,
                    width: 810
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    categories: monthLabels,
                    crosshair: true,
                    useHtml: true,
                    reversed: true
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Cost ($)'
                    }
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        pointPadding: -0.2,
                        borderWidth: 0
                    },
                    series: {
                        // turn off animations because of screenshot tool
                        animation: false
                    }
                },
                series,
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                }
            };
        }

        public getMonthLabels(invoices: aq.tenantbilling.TenantInvoiceHistoricalUsage[]) {
            return _.map(invoices, (pastInvoice) => {
                const start = moment(pastInvoice.startReadingDate).format('MM/DD/YY');
                const end = moment(pastInvoice.readingDate).format('MM/DD/YY');
                return `${pastInvoice.monthlyBillingName}<br><small style="font-size: 6px">(${start} - ${end})</small>`;
            });
        }

        public getSeries(invoices: aq.tenantbilling.TenantInvoiceHistoricalUsage[]) {
            const chartData = _.map(invoices, 'costPerService');
            return this.SeriesMapping.mapData(chartData);
        }

        public getChargeValues() {
            return _.sortBy(this.tenantChargeValues, [
                (chargeValue) => {
                    switch (chargeValue.type.toLowerCase()) {
                        case 'metered':
                            return 1;
                        case 'fixed':
                            return 2;
                        case 'tax':
                            return 3;
                        default:
                            return 4;
                    }
                },
                'collector.name',
                'charge.service.serviceId'
            ]);
        }
    }

    angular
        .module('aq.reports')
        .controller('reportsInvoiceCtrl', InvoiceDefaultCtrl);
}
