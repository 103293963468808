var aq;
(function (aq) {
    var services;
    (function (services) {
        var ActivityContextType = aq.models.activity.ActivityContextType;
        var ActivityService = /** @class */ (function () {
            /* @ngInject */
            function ActivityService($http, resolver, Messages, AQ_API_URL, AuthToken, $location) {
                this.$http = $http;
                this.resolver = resolver;
                this.Messages = Messages;
                this.AQ_API_URL = AQ_API_URL;
                this.AuthToken = AuthToken;
                this.$location = $location;
            }
            ActivityService.prototype.getContextTypeStringForActivity = function (activity) {
                if (!activity || !activity.context || !activity.context.type) {
                    return null;
                }
                switch (activity.context.type) {
                    case ActivityContextType.ALERT:
                        return 'Alert Fired';
                    case ActivityContextType.AID_ISSUE:
                        return 'Load Analytics';
                    case ActivityContextType.ENERGY_NOTE:
                        return 'Note';
                    case ActivityContextType.RX:
                        return 'HVAC Analytics';
                    case ActivityContextType.OPTIMAL_START:
                        return 'Optimization Strategy';
                    default:
                        return null;
                }
            };
            ActivityService.prototype.getAnnualizedSavings = function (start, end, building) {
                var _this = this;
                try {
                    return this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                        var now, response;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    now = new Date();
                                    return [4 /*yield*/, this.$http({
                                            method: 'GET',
                                            headers: this.getHeaders(),
                                            url: this.AQ_API_URL + "activities/v1/annualized-aid-savings",
                                            params: {
                                                start: moment(start).toDate(),
                                                end: now,
                                                building: building.id
                                            }
                                        })];
                                case 1:
                                    response = _a.sent();
                                    return [2 /*return*/, response.data];
                            }
                        });
                    }); });
                }
                catch (error) {
                    throw error;
                }
            };
            ActivityService.prototype.createMessage = function (message, parentID) {
                var _this = this;
                try {
                    return this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                        var response;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.$http({
                                        method: 'POST',
                                        headers: this.getHeaders(),
                                        data: JSON.stringify(message),
                                        url: this.AQ_API_URL + "activities/v1/" + parentID + "/messages"
                                    })];
                                case 1:
                                    response = _a.sent();
                                    return [2 /*return*/, response.data];
                            }
                        });
                    }); });
                }
                catch (error) {
                    throw error;
                }
            };
            ActivityService.prototype.getActivity = function (id) {
                var _this = this;
                try {
                    return this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                        var response;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.$http({
                                        method: 'GET',
                                        headers: this.getHeaders(),
                                        url: this.AQ_API_URL + "activities/v1/" + id
                                    })];
                                case 1:
                                    response = _a.sent();
                                    return [2 /*return*/, response.data];
                            }
                        });
                    }); });
                }
                catch (error) {
                    throw error;
                }
            };
            ActivityService.prototype.getActivities = function (params) {
                var _this = this;
                try {
                    return this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                        var response;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.$http({
                                        method: 'GET',
                                        headers: this.getHeaders(),
                                        url: this.AQ_API_URL + "activities/v1",
                                        params: params
                                    })];
                                case 1:
                                    response = _a.sent();
                                    return [2 /*return*/, response.data];
                            }
                        });
                    }); });
                }
                catch (error) {
                    throw error;
                }
            };
            ActivityService.prototype.getHeaders = function () {
                return {
                    'Content-Type': 'application/json',
                    Authorization: "Bearer " + (this.AuthToken ? this.AuthToken : this.$location.search().authToken)
                };
            };
            return ActivityService;
        }());
        services.ActivityService = ActivityService;
        angular
            .module('aq.services')
            .service('ActivityService', ActivityService);
    })(services = aq.services || (aq.services = {}));
})(aq || (aq = {}));
