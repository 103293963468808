namespace aq.admin.configuration.utilityCompany.tariffSchedule {
    export class TariffScheduleNewCtrl {
        private tariffSchedule: TariffSchedule;
        /* @ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private Messages,
            private RestangularV3: restangular.IService,
            private newTariffSchedule: TariffSchedule,
            private measures: aq.common.models.Measure[]
        ) {
            this.tariffSchedule = newTariffSchedule;
            this.measures = this.measures.filter(this.supportedMeasure);
        }

        save() {
            return this.RestangularV3.all('energy-tariff-schedules')
                .post(this.tariffSchedule);
        }

        supportedMeasure(measure: aq.common.models.Measure) {
            switch (measure.name) {
                case 'electricity':
                case 'water':
                case 'steam':
                case 'gas' :
                case 'heat':
                    return true;
                default:
                    return false;
            }
        };
    }

    angular
        .module('utilityCompany')
        .controller('TariffScheduleNewCtrl', TariffScheduleNewCtrl);
}
