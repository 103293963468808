namespace aq.propertySettings {
    export class AlertBulkCtrl extends aq.common.Controllers.ModalCtrl {
        private aqUserRegex = /\@aquicore/igm;
        /* @ngInject */
        constructor(
            protected $scope: AlertBulkCtrlScope,
            protected $mdDialog: ng.material.IDialogService,
            protected building: aq.common.models.Building,
            protected timeout: number,
            protected active: boolean,
            protected users: aq.common.models.User[],
            protected allAlerts: any[],
            protected currentUser: aq.common.models.User,
            protected selectedUsers: aq.common.models.User[],
            protected DataStore: aq.common.DataStore,
            private Restangular,
            private Messages,
            private $q,
            private notificationSenders: aq.common.models.NotificationSender[],
            private loading

        ) {
            super($scope, $mdDialog);
            this.$scope.building = building;
            this.$scope.active = active;
            this.$scope.timeout = timeout;
            this.$scope.sendTextMessage = false;
            this.$scope.sendEmail = false;
            this.$scope.users = users;
            this.$scope.selectedUsers = [];
            this.$scope.removedUsers = [];
            this.$scope.allAlerts = allAlerts;
            this.$scope.currentUser = currentUser;
        }

        public cancel() {
            this.$mdDialog.cancel();
        }

        public updateAll() {
            this.loading.start();
            const promises: ng.IPromise<any[]>[] = [];
            _.each(this.$scope.allAlerts, (alert) => {
                this.prepareAlertForRequest(alert);
                promises.push(this.DataStore.update(alert, { inflate: 'currentState,receivers' }));
            });
            delete this.$scope.selectedUsers;
            this.$q.all(promises).then((alerts) => {
                _.each(alerts, (alert) => {
                    this.Restangular.restangularizeElement(this.$scope.building, alert, 'recoveryNotifications');
                    alert.fromServer = true;
                });
                this.$scope.allAlerts = alerts;
                this.loading.stop();
                this.Messages.info('All Offline Alerts Updated');
                this.$mdDialog.hide(this.$scope.allAlerts);
            }, (error) => {
                this.loading.stop();
                this.Messages.error('Error During Bulk Update');
                this.$mdDialog.cancel();
            });
        }

        public prepareAlertForRequest(alert) {
            // only update timeout and active if they have been changed
            alert.receivers = this.getUserList(alert.receivers);
            alert.currentState.timeout = this.$scope.enableTimeout ? this.$scope.timeout : alert.currentState.timeout;
            alert.active = this.$scope.enableActive ? this.$scope.active : alert.active;
            alert.fromServer = true;
            const notificationType = 'GEM_METER_DOWN';
            const smsNotification = _.find(this.notificationSenders, { name: 'SMS', notificationType });
            const mailNotification = _.find(this.notificationSenders, { name: 'Mail', notificationType });
            if (this.$scope.enableActions && this.$scope.sendTextMessage && smsNotification && !_.includes(alert.senders, smsNotification.id)) {
                alert.senders.push(smsNotification.id);
            }
            // @note: find selected user to send notifications to
            const selectedUsers = [];
            _.each(this.$scope.selectedUsers, (user) => {
                selectedUsers.push({
                    user: user.id,
                    classType: 'UserReceiver'
                });
            });
            alert.receivers = selectedUsers;

            // email code, just leave for now
            /* if (alert.sendEmail && mailNotification && !_.includes(alert.senders, mailNotification.id)) {
                alert.senders.push(mailNotification.id);
            } else if (!alert.sendEmail) {
                _.remove(alert.senders, s => s === mailNotification.id);
            } */
        }

        public getUserList(receivers) {
            let addUsers: {user: string; classType: string}[] = [];
            let removeUsers: {user: string; classType: string}[] = [];
            const existingUsers: aq.common.models.User[] = receivers;

            if (this.$scope.enableRemoveUser) {
                removeUsers = _.map(this.$scope.removedUsers, (user: any) => {
                    return {
                        user: user.id,
                        classType: 'UserReceiver'

                    };
                });
            }
            if (this.$scope.enableAddUser) {
                addUsers = _.map(this.$scope.selectedUsers, (user: any) => {
                    return {
                        user: user.id,
                        classType: 'UserReceiver'
                    };
                });
            }

            const removeAll: aq.common.models.User[] = _.differenceBy(existingUsers, removeUsers, 'user');
            return _.unionBy(removeAll, addUsers, 'user');
        }

        public queryUsers(criteria) {
            return this.users.filter((user: aq.common.models.User) => {
                const match = user.fullName.toLowerCase().indexOf(criteria.toLowerCase()) > -1 && !_.includes(this.$scope.selectedUsers, user);
                const aqUser = user.email.match(this.aqUserRegex) != null;
                const currentUserIsAq = this.$scope.currentUser.email.match(this.aqUserRegex) !== null;
                if (currentUserIsAq && match) {
                    return true;
                } if (!currentUserIsAq && match && !aqUser) {
                    return true;
                }
                return false;
            });
        }
    }

    export interface AlertBulkCtrlScope extends ng.IScope {
        building: aq.common.models.Building;
        timeout: number;
        active: boolean;
        users: aq.common.models.User[];
        selectedUsers: aq.common.models.User[];
        removedUsers: aq.common.models.User[];
        allAlerts: any[];
        currentUser: aq.common.models.User;
        sendTextMessage: boolean;
        sendEmail: boolean;
    }
    angular.module('properties').controller('AlertBulkCtrl', aq.propertySettings.AlertBulkCtrl);
}
