namespace aq.projectCenter {
    export class ProjectDetailConfigurationCtrl {
        public baseDateRange: any;
        public implementationDateRange: any;
        public reportingDateRange: any;
        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private project: Project,
            private projectStatuses: aq.common.models.SimpleEnum,
            private projectTypes: aq.common.models.SimpleEnum,
            private Messages: aq.services.Messages,
            private users: aq.common.models.User[],
            private DataStore: aq.common.DataStore,
            private RestangularV3: restangular.IService
        ) {
            this.baseDateRange = {
                dateStart: this.project.basePeriodStartDate,
                dateEnd: this.project.basePeriodEndDate,
                label: this.formatDateRangeDisplay(this.project.basePeriodStartDate, this.project.basePeriodEndDate)
            };
            this.implementationDateRange = {
                dateStart: this.project.implementationPeriodStartDate,
                dateEnd: this.project.implementationPeriodEndDate,
                label: this.formatDateRangeDisplay(this.project.implementationPeriodStartDate, this.project.implementationPeriodEndDate)
            };
            this.reportingDateRange = {
                dateStart: this.project.reportingPeriodStartDate,
                dateEnd: this.project.reportingPeriodEndDate,
                label: this.formatDateRangeDisplay(this.project.reportingPeriodStartDate, this.project.reportingPeriodEndDate)
            };
        }

        public updateProjectDates() {
            this.project.basePeriodStartDate = this.baseDateRange.dateStart;
            this.project.basePeriodEndDate = this.baseDateRange.dateEnd;
            this.project.implementationPeriodStartDate = this.implementationDateRange.dateStart;
            this.project.implementationPeriodEndDate = this.implementationDateRange.dateEnd;
            this.project.reportingPeriodStartDate = this.reportingDateRange.dateStart;
            this.project.reportingPeriodEndDate = this.reportingDateRange.dateEnd;
            this.$scope.$emit('PROJECT_UPDATED', this.project);
        }

        public formatDateRangeDisplay(dateStart, dateEnd) {
            if (!dateStart || !dateEnd) {
                return 'Set Dates';
            }
            return moment(dateStart).format('MMM D, YYYY') + ' - ' + moment(dateEnd).format('MMM D, YYYY');
        }
    }

    angular
        .module('projectCenter')
        .controller('ProjectDetailConfigurationCtrl', ProjectDetailConfigurationCtrl);
}

