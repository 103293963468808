namespace aq.models.alerts {

    export interface AlertConfig {
        options: AlertConfigOptions;
        actions: any;//AlertEditService;
        accountId: string;
        buildingId: string;
        alertId: string;
        updateFrequencySeconds: number;
        alertType: string;
        isCustomTitle: boolean;
        showTitle: boolean;
        title: string;
        showBuildingName: boolean;
    }

    export interface AlertConfigOptions {
        buildings: aq.common.models.Building[];
        alerts: AlertOption[];
        frequencies: { label: string, value: number }[];
        getAlertOptions: (buildingId: string, isApply: boolean) => any;
    }

    export interface AlertData {
        alertId: number;
        alertType: string;
        alertNote: string;
        alertName: string;
        alertDescription: string;
        currentLevel: AlertDataLevel;
        previousLevel: AlertDataLevel;
        nextLevel: AlertDataLevel;
    }

    export interface AlertDataLevel {
        name: string;
        value: number;
        timestamp: number;
        severity: string;
        upperBoundary: number;
        lowerBoundary: number;
        description: string;
        queryable: {
            id: number;
            name: string;
            type: string;
        };
        unit: {
            name: string;
            symbol: string;
        };
    }

    export interface AlertOption {
        label: string;
        value: string;
        type: string;
    }

    export enum LevelSeverity {
        NORMAL = 'NORMAL',
        WARNING = 'WARNING',
        CRITICAL = 'CRITICAL',
        UNKNOWN = 'UNKNOWN'
    };

    export enum SeverityIcon {
        NORMAL = 'check_circle',
        WARNING = 'warning',
        CRITICAL = 'error',
        UNKNOWN = 'help'
    }
}
