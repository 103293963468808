angular
    .module('aq.utilityBudgets')
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.utilityBudgets.budgets', {
                templateUrl: 'app/utilityBudgets/budgets/index.html',
                url: '/budgets',
                controller: 'TargetListCtrl as budgetListCtrl',
                data: {
                    breadcrumb: 'Budgets',
                    icon: 'attach_money'
                },
                resolve: {
                    occupancies: (
                        $stateParams: ng.ui.IStateParamsService,
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        waitForAuthToken
                    ) => {
                        return DataStore.getList(RestangularV3.one('building', $stateParams.buildingId), 'buildingOccupancy', {});
                    },
                    allTargets: (
                        Restangular,
                        $stateParams: ng.ui.IStateParamsService,
                        DataStore: aq.common.DataStore,
                        waitForAuthToken
                    ) => {
                        return DataStore.getList(Restangular.one('accounts', $stateParams.accountId).one('buildings', $stateParams.buildingId),
                            'budgets', { inflate: 'targetItems,measure' });
                    },
                    types: () => {
                        return [{
                            value: 'BUDGET',
                            label: 'Budget'
                        }];
                    },
                    utilityServices: ($stateParams: ng.ui.IStateParamsService, RestangularV3: restangular.IService, waitForAuthToken) => {
                        return RestangularV3.all('utility-services')
                            .customGET('', { buildingId: $stateParams.buildingId });
                    }
                }
            }).state('aq.utilityBudgets.budgets.details', {
                templateUrl: 'app/utilityBudgets/budgets/details/details.html',
                url: '/:targetId',
                controller: 'TargetDetailsCtrl as budgetDetailsCtrl',
                data: {
                    breadcrumb: 'Budget Details',
                    redirectState: 'aq.utilityBudgets.budgets'
                },
                resolve: {
                    authAccess(waitForAuthToken, Auth) {
                        return Auth.access;
                    },
                    target: (
                        waitForAuthToken,
                        building,
                        DataStore: aq.common.DataStore,
                        $stateParams: ng.ui.IStateParamsService,
                        Restangular
                    ) => {
                        return DataStore.get(Restangular.one('accounts', $stateParams.accountId)
                            .one('buildings', $stateParams.buildingId)
                            .one('budgets', $stateParams.targetId), { inflate: 'targetItems,measure', single: true },
                            false);
                    },
                    onEnter: (
                        Segment: aq.services.SegmentService,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) => {
                        Segment.trackChurnZeroEvent('VIEW_BUDGET', [$stateParams.buildingId]);
                    }
                }
            });
    });
