
/**
 * Returns a left-padded string of the number
 *
 * e.g.  1 -> 01
 */
function pad(n, width) {
    var lead = '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(lead) + n;
}

angular.module('aq.admin.meters.deviceclasses')
.filter('getConnectionPoints', function() {

    /**
     * Given a connectionGroup, return an array of point names.
     *
     * If connectionGroup.quantity is > 3, elide the middle list elements.
     *
     * Given:
     * {
     *     type: {
     *         name: BACnet,
     *     },
     *     quantity: 5
     * }
     * Will return:
     * ["B01", "...", "B05"]
     *
     * Given:
     * {
     *     type: {
     *         name: BACnet,
     *     },
     *     quantity: 3
     * }
     * Will return:
     * ["B01", "B02", "B03"]
     *
     */
    return function(connectionGroup, enumerateAllPoints) {
        if (connectionGroup.type === undefined || connectionGroup.type.name === undefined) return [];
        if (connectionGroup.quantity === undefined) return [];
        var myPrefix = connectionGroup.type.name[0];
        var quantity = parseInt(connectionGroup.quantity);
        if (quantity === NaN) return [];

        var connections = [];
        if (quantity > 3  && enumerateAllPoints !== true) {
            connections.push(myPrefix + "01");
            connections.push("...");
            connections.push(myPrefix + pad(quantity, 2));
        } else {
            for (var j = 1; j <= quantity; j++) {
                connections.push(myPrefix + pad(j, 2));
            }
        }
        return connections;
    }
});
