namespace aq.settings {
    export class PlatformSettingsCrudModal {
        public options: ModalCrudRestangularOptions;
        constructor(
            private $mdDialog: ng.material.IDialogService,
            private $q: ng.IQService,
            private Restangular,
            private Messages: aq.services.Messages,
            private Errors,
            private parameters: ModalCrudRestangularParameters,
            private object: AccountPropertyCollectionItem,
            private account: restangular.IElement
        ) {
            this.options = {
                crudObjectName: parameters.objectName,
                restangularParent: this.account,
                restangularObjectName: parameters.restangularName,
                restangularBaseAllRoute: this.account.all(parameters.restangularName),
                restangularBaseOneRoute: (id) => this.account.one(parameters.restangularName, id),
                getParams: {}
            };
        }
        public hide() {
            this.$mdDialog.hide();
        }
        public cancel() {
            this.$mdDialog.cancel();
        }
        public save() {
            return this.isNewRecord() ? this.create() : this.update();
        }
        public delete() {
            this.options.restangularBaseAllRoute.customDELETE(this.object.id)
                .then(() => {
                    this.Messages.info(`Deleted ${this.options.crudObjectName}`);
                    this.$mdDialog.hide();
                })
                .catch(() => {
                    this.Messages.error(`Couldn\'t Delete ${this.options.crudObjectName}`);
                    this.$mdDialog.cancel();
                });
        }
        public isNewRecord(): boolean {
            return !!(_.isUndefined(this.object) || _.isUndefined(this.object.id));
        }
        private getRestangularizedObject(object): ng.IPromise<restangular.IElement> {
            if (object.model) {
                return this.$q.when(this.Restangular.restangularizeElement(this.options.restangularParent, object.model, this.options.restangularObjectName));
            }
            return this.options.restangularBaseOneRoute(object.id)
                .get(this.options.getParams)
                .then((response) => {
                    return this.Restangular.restangularizeElement(this.options.restangularParent, response[0], this.options.restangularObjectName);
                });
        }
        private create() {
            this.object.account = this.account.id;
            this.options.restangularBaseAllRoute.post(this.object)
                .then((response) => {
                    this.Messages.success(`Created ${this.options.crudObjectName}`);
                    this.$mdDialog.hide(this.getRestangularizedObject(response));
                })
                .catch((response) => {
                    this.$mdDialog.cancel();
                    this.Errors.forCRUD('UPDATE')(response);
                });
        }
        private update() {
            this.object.put()
                .then((response) => {
                    this.Messages.info(`Updated ${this.options.crudObjectName}`);
                    this.$mdDialog.hide(this.getRestangularizedObject(response));
                });
        }
    }
}
