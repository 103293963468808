namespace aq.services {

    export class SidebarNavHelperService {

        /* @ngInject */
        constructor(private $state: ng.ui.IStateService) {
        }

        public populateMissingMenuItemData(item: aq.common.models.SidebarNavItem) {
            if (!item) {
                return;
            }
            if (item.state) {
                const state = this.$state.get(item.state);
                if (state && state.data) {
                    const data = state.data;
                    if (!item.title) item.title = data.title ? data.title : data.breadcrumb;
                    if (!item.icon) item.icon = data.icon;
                    if (!item.aria) item.aria = data.aria ? data.aria : item.title;
                    return;
                }
            }
            if (!item.aria) item.aria = item.title;
        }

        public getIconSizeClass(icon) {
            switch (icon) {
                case 'aq-icons-budget':
                    return 'size-30';
                case 'aq-icons-peak':
                    return 'size-30';
                case 'aq-icons-consumption':
                    return 'size-30';
                default:
                    return '';
            }
        }

        public getColorFromString(input: string) {
            let hash = this.getHashFromString(input);
            const redComponent = Math.abs(hash % 128) + 64;
            hash = Math.round(hash / 1000);
            const greenComponent = Math.abs(hash % 128) + 64;
            hash = Math.round(hash / 1000);
            const blueComponent = Math.abs(hash % 128) + 64;
            const result = '#' + this.getHtmlColorComponent(redComponent) + this.getHtmlColorComponent(greenComponent) + this.getHtmlColorComponent(blueComponent);
            return result;
        }

        private getHtmlColorComponent(input: number): string {
            let hex = input.toString(16);
            if (input < 16) {
                hex = '0' + hex;
            }
            return hex;
        }

        private getHashFromString(input: string): number {
            let hash = 0, i, chr;
            if (input.length === 0) return hash;
            for (i = 0; i < input.length; i++) {
                chr = input.charCodeAt(i);
                hash = (hash * 31 + chr) % 1000000000;
            }
            return hash;
        }
    }

    angular.module('aq.services').service('SidebarNavHelperService', SidebarNavHelperService);
}
