namespace aq.propertySettings {
    export class TargetListCtrl {
        private propertySettingsAccess: common.models.AppAccessObject;
        private budgetsAccess: common.models.AppAccessObject;
        private loading: boolean;
        /* @ngInject */
        constructor(
            private UserService,
            private $scope: TargetListCtrlScope,
            private allTargets: Target[],
            private measures: aq.common.models.Measure[],
            private Restangular: restangular.IService,
            private RestangularV3: restangular.IService,
            private RestangularV3FullResponse: restangular.IService,
            private $mdDialog: ng.material.IDialogService,
            private Messages: any,
            private building: aq.common.models.Building,
            private $state: ng.ui.IStateService,
            private DataStore: aq.common.DataStore,
            private types: any,
            private Auth: aq.services.Auth,
            private FileHandlerService: aq.services.FileHandlerService,
            private OptionsService,
            private Segment: aq.services.SegmentService,
            private utilityServices: aq.utilityBudgets.UtilityService[],

        ) {
            this.loading = false;
            this.propertySettingsAccess = this.Auth.access['Property Settings'];
            this.budgetsAccess = this.Auth.access['Budgets'];
            this.$scope.currentYear = null;
            this.$scope.targets = this.getTargetsWithYearsFromSearchResult(allTargets);
            this.$scope.yearsList = this.getYearsFromList($scope.targets, this.$scope.currentYear);
            this.$scope.measureMap = this.createMeasureMap(measures);
            this.$scope.months = moment.months();
            this.$scope.measures = measures;
            const futureYears = _.range(1, 4).map((year) => { return moment().add(year, 'year').year(); });
            const presentAndPastYears = _.range(5).map((year) => { return moment().subtract(year, 'year').year(); });
            this.$scope.years = futureYears.reverse().concat(presentAndPastYears);
            this.$scope.types = types;
        }

        public generateBudgets() {
            this.Segment.trackEvent('Budgets:Auto Generate Budgets');
            this.toggleLoading();
            this.RestangularV3.one('buildings', this.building.id).all('create-auto-budgets').customPOST()
                .then(_ => {
                    this.refreshBudgets().then(res => {
                        this.allTargets = this.getTargetsWithYearsFromSearchResult(res);
                        this.$scope.yearsList = this.getYearsFromList(this.allTargets, this.$scope.currentYear);
                        this.search('');
                        this.toggleLoading();
                    });
                });
        }

        public generateTargets() {
            this.toggleLoading();
            this.RestangularV3.one('buildings', this.building.id).all('create-auto-targets').customPOST()
                .then(_ => {
                    this.refreshTargets().then(res => {
                        this.allTargets = this.getTargetsWithYearsFromSearchResult(res);
                        this.$scope.yearsList = this.getYearsFromList(this.allTargets, this.$scope.currentYear);
                        this.search('');
                        this.toggleLoading();
                    });
                });
        }

        public getTargetsWithYearsFromSearchResult(targetResult: Target[]) {
            for (let i = 0; i < targetResult.length; i++) {
                targetResult[i].year = moment(targetResult[i].targetItems[targetResult[i].targetItems.length - 1].endDate).year();
                if (targetResult[i].status === 'IN_PROGRESS') {
                    this.$scope.currentYear = targetResult[i].year;
                }
            }
            return targetResult;
        }

        public refreshBudgets() {
            return this.DataStore.getList(this.Restangular.one('accounts', this.building.account).one('buildings', this.building.id),
                'budgets', { inflate: 'targetItems,measure' });
        }

        public refreshTargets() {
            return this.DataStore.getList(this.Restangular.one('accounts', this.building.account).one('buildings', this.building.id),
                'targets', { inflate: 'targetItems,measure' });
        }

        public getDisplayType(target) {
            return _.find(this.$scope.types, { value: target.type }).label;
        }

        public getIconType(target) {
            if (target.type === 'ENERGY_STAR') {
                return 'energy-star--glyph';
            } else {
                return this.$scope.measureMap[target.measure.id].toLowerCase();
            }
        }

        public search(searchText) {
            if (searchText) {
                this.$scope.targets = this.allTargets.filter((target) => {
                    return _.includes(target.name.toUpperCase(), searchText.toUpperCase());
                });
                this.$scope.yearsList = this.getYearsFromList(this.$scope.targets, this.$scope.currentYear);
            } else {
                this.$scope.targets = this.allTargets;
                this.$scope.yearsList = this.getYearsFromList(this.allTargets, this.$scope.currentYear);
            }
        };

        public getCount(stat: string) {
            return this.$scope.targets.filter(target => {
                return target.status === stat;
            }).length;
        }

        public downloadCsv() {
            let csvContent = '';
            const timestamp = moment().format('MM_DD_YYYY');
            const fileName = `Target_List_Report_${timestamp}.csv`;
            const link = document.createElement('a');
            csvContent = `data:text/csv;charset=utf-8,Name,Target,Measure,Status\n`;
            (this.allTargets as any).forEach((row) => {
                csvContent += `${row.name},${row.type},${row.measure.name},${row.status}\n`;
            });
            link.setAttribute('href', encodeURI(csvContent));
            link.setAttribute('download', fileName);
            link.click();
        }

        public downloadBudgetPacketExcelFromYear(year) {
            this.Segment.trackEvent('Budgets:Export Budget Packet');
            year.isDownloading = true;
            return this.RestangularV3FullResponse.one('budget').withHttpConfig({ responseType: 'blob', transformRequest: angular.identity })
                .customGET('download-budget-packet', { buildingId: this.building.id, year: year.year })
                .then((response) => {
                    this.FileHandlerService.handleFileDownload(response, 'application/vnd.ms-excel');
                    year.isDownloading = false;
                })
                .catch(() => {
                    this.Messages.error('Error while generating report');
                    year.isDownloading = false;
                });
        }

        public getTotalFromItem(item) {
            let values = _.map(item.targetItems, (targetItem) => {
                return targetItem.value;
            });
            switch (item.type) {
                case 'PEAK_DEMAND':
                case 'BASELOAD':
                case 'ENERGY_STAR':
                    return _.mean(values);
                case 'CONSUMPTION':
                case 'BUDGET':
                    return _.sum(values);
                default:
                    return null;
            }
        }

        public getDateFromTarget(target) {
            let endDate = new Date(target.targetItems[target.targetItems.length - 1].endDate);
            return endDate.getFullYear();
        }

        public getYearsFromList(targetList, currentYear) {
            let tempList = targetList.map(target => moment(target.targetItems[target.targetItems.length - 1].endDate).year()).filter(this.onlyUniqueDates);
            tempList.sort().reverse();
            for (let i = 0; i < tempList.length; i++) {
                if (tempList[i] < currentYear) {
                    tempList[i] = { year: tempList[i], isDownloading: false, collapse: true }
                } else {
                    tempList[i] = { year: tempList[i], isDownloading: false, collapse: false }
                }
            }
            return tempList;
        }

        private onlyUniqueDates(value, index, self) {
            return self.indexOf(value) === index;
        }

        public getUnit(item) {
            switch (item.type) {
                case 'PEAK_DEMAND':
                    return 'kW';
                case 'CONSUMPTION':
                    return 'kWh';
                case 'BASELOAD':
                    return 'kWh';
                case 'BUDGET':
                    return this.OptionsService.currencyUnit().symbol;
                case 'ENERGY_STAR':
                    return null;
                default:
                    return null;
            }
        }

        public create() {
            const budgetModal = _.find(this.$scope.types, (t) => {
                return t.value == 'BUDGET';
            });
            this.$mdDialog.show({
                controller: 'TargetNewCtrl as targetNewCtrl',
                templateUrl: budgetModal ? 'app/utilityBudgets/budgets/new/budgetNewModal.html' : 'app/properties/targets/new/targetNewModal.html',
                clickOutsideToClose: true,
                parent: angular.element(document.body),
                scope: this.$scope,
                preserveScope: true,
                locals: {
                    target: {
                        building: this.building.id
                    },
                    measures: this.measures,
                    building: this.building,
                    months: this.$scope.months,
                    years: this.$scope.years,
                    types: this.$scope.types
                }
            });
        }

        public startBudgetsWizard() {
            this.$mdDialog.show({
                controller: 'BudgetCreateWizardSelectionModalCtrl as vm',
                templateUrl: 'app/utilityBudgets/budgets/wizard/budgetWizardSelectionModal.html',
                clickOutsideToClose: false,
                parent: angular.element(document.body),
                locals: {
                    mode: null,
                    target: {
                        building: this.building.id
                    },
                    measures: this.measures,
                    building: this.building,
                    months: this.$scope.months,
                    years: this.$scope.years,
                    types: this.$scope.types
                },
                multiple: true
            } as any);
        }

        public edit(target: Target) {
            this.$state.go('.details', { targetId: target.id });
        }

        public collapseBudgetYear(budgetYear) {
            budgetYear.collapse = !budgetYear.collapse;
        }

        public deleteTarget(target: Target, ev) {
            // search through item for the specified label prop, currently limited to 2 properties deep.
            this.$mdDialog.show(
                this.$mdDialog.confirm()
                    .title(`Are you sure you want to delete ${target.name}?`)
                    .ariaLabel('Delete')
                    .targetEvent(ev)
                    .ok('Delete')
                    .cancel('Cancel'))
                .then(() => {
                    if (this.$state && this.$state.current && this.$state.current.data &&
                        this.$state.current.data.breadcrumb === 'Budgets') {
                        this.Segment.trackEvent('Budgets:Delete Budget');
                    }
                    this.DataStore.delete(target).then(() => {
                        this.Messages.info(`Successfully deleted ${target.name} Target`);
                        this.$scope.yearsList = this.getYearsFromList(this.allTargets, this.$scope.currentYear);
                    });
                });
        }

        public delete(target: Target) {
            if (this.$state && this.$state.current && this.$state.current.data &&
                this.$state.current.data.breadcrumb === 'Budgets') {
                this.Segment.trackEvent('Budgets:Delete Budget');
            }
            this.DataStore.delete(target).then(() => {
                this.Messages.info(`Successfully deleted ${target.name} Target`);
            }), () => {
                this.Messages.error('Error Deleting Budget');
            };
        }

        public toggleLoading() {
            this.loading = !this.loading;
        }

        public canDelete() {
            return this.budgetsAccess && (this.budgetsAccess.FULL_ACCESS || this.budgetsAccess.EDIT);
        }

        /* Displaying measure based on id instead of inflating by measure. */
        private createMeasureMap(measures: aq.common.models.Measure[]) {
            const measureMap: { [key: string]: string } = {};
            measures.forEach((measure) => {
                measureMap[measure.id] = measure.name;
            });
            return measureMap;
        };

    }
    export interface TargetListCtrlScope extends ng.IScope {
        loading: boolean;
        targets: Target[];
        measureMap: { [key: string]: string };
        measures: any;
        months: any;
        years: any;
        types: any[];
        isDownloadingExcel: boolean;
    }
    angular.module('properties').controller('TargetListCtrl', aq.propertySettings.TargetListCtrl);
}
