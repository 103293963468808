namespace aq.tenantbilling {
    export class DeviceDetailsTabCtrl {
        private device: aq.common.models.Device;
        private chargeValue: TenantChargeValue;
        private building: aq.common.models.Building;
        tenantLabel: string;
        /* @ngInject */
        constructor(
            public $mdDialog: ng.material.IDialogService,
            private Auth: aq.services.Auth
        ) {
            this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'units' : 'tenants';
        }

        public cancel(): void {
            this.$mdDialog.cancel();
        }

        getImage(): string {
            if (this.device.images && this.device.images.length) {
                return this.device.images[this.device.images.length - 1].url;
            }
            return null;
        }

    }

    angular
        .module('tenantBilling')
        .component('deviceDetailsTab', {
            templateUrl: 'app/tenantBilling/invoicing/run/editChargeValue/components/deviceDetailsTab/deviceDetailsTab.html',
            controller: DeviceDetailsTabCtrl,
            controllerAs: 'vm',
            bindings: {
                device: '<',
                chargeValue: '<',
                building: '<'
            }
        });
}
