'use strict';

angular.module('accounts')
    .filter('hasAccess', function() {
    return function(profiles, currentUser) {
        return _.filter(profiles, function(profile) {
            return currentUser.userType == 'ADMINISTRATOR' || profile.user.userType != 'ADMINISTRATOR';
        });
    }
}).filter('startFrom', function() {
    return function(input, start) {
        if(input) {
            start = +start; //parse to int
            return input.slice(start);
        }
        return [];
    }
});
