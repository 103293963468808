angular
    .module('aq.reports')
    .directive('reportRange', () => {
        return {
            restrict: 'E',
            replace: false,
            templateUrl: 'app/reports/summary/project/directives/reportRange.html',
            scope: {
                aqData: '='
            },
            link($scope, element, $attrs) {
                $scope.$watch('aqData', (aqData: any) => {

                    Highcharts.setOptions({
                        lang: {
                            thousandsSep: ','
                        }
                    });

                    if (aqData) {
                        $scope.plotOptions = {
                            chart: {
                                plotBackgroundColor: null,
                                plotBorderWidth: null,
                                plotShadow: false,
                                type: 'columnrange',
                                inverted: true,
                                height: 100,
                                width: aqData.options && aqData.options.width ? aqData.options.width : 160
                            },
                            title: {
                                text: ''
                            },
                            xAxis: {
                                min: 0,
                                max: 1,
                                categories: ['Unresolved', 'Resolved']
                            },
                            yAxis: {
                                title: {
                                    text: '',
                                    align: 'low',
                                    offset: 0
                                }
                            },
                            plotOptions: {
                                columnrange: {
                                    dataLabels: {
                                        enabled: true,
                                        format: '${y}'
                                    }
                                }
                            },
                            legend: { enabled: false },
                            exporting: {
                                enabled: false
                            },
                            series: [{
                                name: ' ',
                                colorByPoint: true,
                                data: _.map(aqData.data, (item, index) => {
                                    return {
                                        x: index,
                                        name: item.name,
                                        low: item.min,
                                        high: item.max,
                                        color: item.color
                                    };
                                })
                            }]
                        };
                    }
                });
            }
        };
    });
