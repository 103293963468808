namespace aq.utilityBudgets {
    export interface AccrualViewSettings {
        isSubmitView: boolean;
        monthFormatted: string;
        measureSymbol: string;
        currencySymbol: string;
    }
    export class AccrualViewCtrl {
        public accrualData: AccrualData;
        public accrual: AccrualResponse;
        public settings: AccrualViewSettings;
        public isDisabledInput: boolean;

        /* ngInject */
        constructor(
            private $mdDialog: ng.material.IDialogService,
            private $filter
        ) {
        }
        public $onInit() {
            if (!this.settings) {
                this.settings = { isSubmitView: false } as any;
            }
            if (this.accrual) {
                this.init();
            }
        }
        public init() {
            const now = moment();
            const currentStart = moment(this.accrual.startDate);
            const accruedStart = this.accrual.accruedStartDate != null ? moment(this.accrual.accruedStartDate) : null;
            let currentEnd = null;
            if (accruedStart != null) {
                currentEnd = currentStart.isBefore(accruedStart, 'day') ? moment(accruedStart).subtract(1, 'day') : null;
            } else {
                currentEnd = moment(this.accrual.endDate);
            }
            if (currentEnd && currentEnd.isAfter(now, 'day')) {
                currentEnd = moment(now);
            }
            const accruedEnd = this.accrual.accruedStartDate != null ? moment(this.accrual.accruedEndDate) : null;

            this.isDisabledInput = this.settings.isSubmitView;
            const formatter = this.$filter('aqnumber');
            this.accrualData = {
                current: {
                    period: this.formatDateRange(currentStart, currentEnd),
                    consumption: formatter(this.accrual.currentConsumption),
                    demand: formatter(this.accrual.currentDemand),
                    expense: formatter(this.accrual.currentExpense)
                },
                accrued: {
                    period: this.formatDateRange(accruedStart, accruedEnd),
                    consumption: formatter(this.accrual.accruedConsumption),
                    demand: formatter(this.accrual.accruedDemand),
                    expense: this.isDisabledInput ? formatter(this.accrual.accruedExpense) : Math.round(this.accrual.accruedExpense)
                },
                totalExpense: this.accrual.totalExpense,
                submitDate: this.accrual.submitDate ? moment(this.accrual.submitDate).format('MMM D') : null
            };
        }
        public showAccrualSubmit(event: MouseEvent) {
            const updateAccrual = angular.copy(this.accrual);
            updateAccrual.accruedExpense = parseInt(this.accrualData.accrued.expense);
            this.$mdDialog.show({
                controller: 'AccrualSubmitModalCtrl as vm',
                templateUrl: 'app/utilityBudgets/common/accrualSubmitModal.html',
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                targetEvent: event,
                locals: {
                    accrual: updateAccrual,
                    settings: angular.copy(this.settings)
                }
            })
            .then((accrualResponse) => {
                const data = _.pick(accrualResponse, ['status', 'submitDate', 'accruedExpense', 'totalExpense']);
                angular.extend(this.accrual, data);
                this.init();
            });
        }
        public formatDateRange(start: moment.Moment, end: moment.Moment) {
            if (!start || !end) {
                return 'not specified';
            }
            if (start.isSame(end, 'day')) {
                return start.format('MMM D');
            }
            return start.isSame(end, 'month')
                ? `${start.format('MMM D')}-${end.format('D')}`
                : `${start.format('MMM D')} - ${end.format('MMM D')}`;
        }
    }
    angular.module('aq.utilityBudgets').component('accrualView', {
        controller: AccrualViewCtrl,
        controllerAs: 'vm',
        templateUrl: 'app/utilityBudgets/common/accrualView.html',
        bindings: {
            accrual: '<',
            data: '<',
            settings: '<'
        }
    });
}
