namespace aq.integrations {
    export class IntegrationDetailsCtrl {
        private page: number = 1;
        private endOfLogs: boolean = false;
        private fetchInProgress: boolean = false;
        private addInProgress: boolean = false;
        private viewIntegration: {
            username: string;
            password: string;
            authToken: string;
            workers: any[];
        };
        private changesMade: number = 0;
        private accountSettingsAccess;
        /* @ngInject */
        constructor(
            private $scope: IntegrationDetailsCtrlScope,
            private $mdDialog: any,
            private Restangular: restangular.IService,
            private Messages: any,
            private allServices: any[],
            private integrationLogs: any,
            private integration: any,
            private $state: any,
            private Errors: any,
            private FullScreenService: aq.services.FullScreenService,
            private accountId: string,
            private integrationApp,
            private account: aq.common.models.Account,
            private loading,
            private authAccess: common.models.AuthAppAccess
        ) {
            $scope.integration = integration;
            $scope.logs = integrationLogs;
            $scope.workers = allServices;
            $scope.logStatusCount = _.countBy(integrationLogs, function (log: any) {
                return log.logStatus;
            });
            if ($scope.logStatusCount.WARNING === undefined) $scope.logStatusCount.WARNING = 0;
            if ($scope.logStatusCount.ERROR === undefined) $scope.logStatusCount.ERROR = 0;
            if ($scope.logStatusCount.INFO === undefined) $scope.logStatusCount.INFO = 0;
            $scope.integrationApp = integrationApp;
            this.accountSettingsAccess = this.authAccess['Account Settings'];
            this.resetView();
        }

        resetView() {
            this.viewIntegration = {
                username: this.$scope.integration.username,
                password: this.$scope.integration.password,
                authToken: this.$scope.integration.authToken,
                workers: _.cloneDeep(this.$scope.integration.workers)
            };
            this.changesMade = 0;
        }

        changesPerformed() {
            this.changesMade++;
        }

        deleteIntegration(ev) {
            const confirm = this.$mdDialog.confirm()
                .title('Are you sure you want to delete this integration?')
                .ariaLabel('Delete Integration')
                .targetEvent(ev)
                .ok('Ok')
                .cancel('Cancel');
            this.$mdDialog.show(confirm).then(() => {
                this.$scope.integration.remove().then(() => {
                    const integrationApp = _.find(this.$scope.integrationApps, { id: this.$scope.integration.integrationApp.id });
                    integrationApp['installed'] = false;
                    _.remove(this.$scope.myIntegrationApps, { id: this.$scope.integration.integrationApp.id });
                    this.Messages.success('Integration successfully deleted.');
                    this.$state.go('aq.accounts.integrations');
                });
            });

        }

        updateIntegration() {
            this.changesMade = 0;
            if (this.$scope.integration.integrationApp.authType === 'BASIC_AUTH') {
                this.$scope.integration.username = this.viewIntegration.username;
                this.$scope.integration.password = this.viewIntegration.password;
            } else if (this.$scope.integration.integrationApp.authType === 'AUTH_TOKEN') {
                this.$scope.integration.authToken = this.viewIntegration.authToken;
            }
            this.$scope.integration.workers = this.viewIntegration.workers;
            // GSON does not accept date time with Z suffix, moment format removes the Z
            _.each(this.$scope.integration.workers, function (worker) {
                if (worker.lastRun != null) {
                    worker.lastRun = moment(worker.lastRun).format();
                }
            });
            this.$scope.integration.put().then(() => {
                this.Messages.success('Integration successfully updated');
                this.resetView();
            }).catch(this.Errors.forPromise());
        }

        resetLogs() {
            this.$scope.integration.customPOST({ id: this.$scope.integration.id }, 'resetLogs').then(() => {
                this.$scope.logs = null;
                this.$scope.logStatusCount.WARNING = 0;
                this.$scope.logStatusCount.ERROR = 0;
                this.$scope.logStatusCount.INFO = 0;
            });
        }

        createIntegration(ev) {
            this.$mdDialog.show({
                preserveScope: true,
                scope: this.$scope,
                templateUrl: 'app/settings/integrations/details/createIntegrationDialog.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                fullscreen: this.FullScreenService.fullScreen
            });
        }

        cancel() {
            this.$mdDialog.hide();
        }

        getWorker(name) {
            return _.find(this.$scope.integration.workers, function (worker: any) {
                return worker.workerClassName === name;
            });
        }

        add() {
            if (this.addInProgress) {
                return;
            }
            this.addInProgress = true;
            this.Restangular.one('accounts', this.accountId).post('integrations', this.$scope.integration)
                .then((result) => {
                    const integrationApp = _.find(this.$scope.integrationApps, { id: this.$scope.integration.integrationApp });
                    integrationApp['installed'] = true;
                    integrationApp['integrationId'] = result.id;
                    this.$scope.myIntegrationApps.push(integrationApp);
                    this.Messages.success('Integration created successfully.');
                    let state = 'aq.accounts.integrations.details';
                    if (integrationApp.uiState) {
                        state = `${state}.${integrationApp.uiState}`;
                    }
                    this.$state.go(state, { accountId: this.accountId, integrationId: result.id });

                }, () => {
                    this.cancel();
                    this.Messages.error('Integration could not be created. Please contact an administrator.');
                })
                .finally(() => {
                    this.addInProgress = false;
                });
        }

        splitFeatures(features: string): string[] {
            return _.split(features, '|');
        }

        fetchMoreLogs(): void {
            if (this.integration && this.integration.id && !this.endOfLogs && !this.fetchInProgress) {
                this.loading.start();
                this.page += 1;
                this.fetchInProgress = true;
                this.account.one('integrations', this.integration.id).customGET('logs', { page: this.page, pageSize: 25 })
                    .then((logs) => {
                        if (logs && logs.length > 0) {
                            this.$scope.logs = _.concat(this.$scope.logs, logs);
                        } else {
                            this.endOfLogs = true;
                        }
                        this.fetchInProgress = false;
                        this.loading.stop();
                    })
                    .catch(() => {
                        this.loading.stop();
                    });
            }
        }
    }

    export interface IntegrationDetailsCtrlScope extends IntegrationsCtrlScope {
        integration: any;
        logs: any;
        logStatusCount: any;
        workers: any;
        customFullscreen: boolean;
        integrationApp: any;
    }

    angular.module('accounts').controller('IntegrationDetailsCtrl', IntegrationDetailsCtrl);
}
