namespace aq.projectCenter {
    export class ProjectDetail {
        static PROJECT_UPDATE_EVENT = 'PROJECT_UPDATE';
        private entityActions: aq.common.models.EntityMenuAction[];
        private currentNavItem;
        private isAdmin: boolean;
        private nonFormDataChangeIndicator: number;
        private backAction: aq.components.BackButtonData;
        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private project: Project,
            private DataStore: aq.common.DataStore,
            private Messages,
            private $state: ng.ui.IStateService,
            private building,
            private users,
            private account,
            private $mdDialog: ng.material.IDialogService,
            private RestangularV3: restangular.IService,
            private projectTypes: aq.common.models.SimpleEnum,
            private ProjectService: ProjectService,
            private UserService,
            private $window
        ) {
            this.backAction = {
                state: null,
                onBackAction: () => {
                    this.$window.history.back();
                }
            };
            this.currentNavItem = (this.$state.current.url as string).substring(1);
            this.entityActions = [
                { label: 'Add Task', cb: this.createProjectTask }
            ];
            this.$scope.$on('OPEN_CREATE_TASK', (event) => {
                this.createProjectTask();
            });
            this.$scope.$on('PROJECT_UPDATED', (event, project) => {
                this.project = project;
                this.nonFormDataChangeIndicator++;
            });
            this.isAdmin = this.UserService.currentUser.userType === 'ADMINISTRATOR';
        }

        createProjectTask = () => {
            this.$mdDialog.show({
                controller: 'ProjectCenterTaskEditCtrl as vm',
                templateUrl: 'app/projectCenter/project/task/projectCenterTaskEditModal.html',
                clickOutsideToClose: true,
                parent: angular.element(document.body),
                locals: {
                    project: this.project,
                    task: {
                        project: this.project.id
                    },
                    users: this.users,
                    account: this.account,
                    options: null
                }
            }).then((task) => {
                if (!this.project.tasks) {
                    this.project.tasks = [];
                }
                this.project.tasks.push(task);
                this.$scope.$broadcast(ProjectDetail.PROJECT_UPDATE_EVENT, this.project);
            });
        }

        public save() {
            this.ProjectService.save(this.project, this.users, this.projectTypes).then(result => {
                this.Messages.info('Project saved');
            }, (err) => {
                this.Messages.error('Error saving Project');
            });
        }

        public delete() {
            this.ProjectService.delete(this.project).then(result => {
                this.Messages.info('Project deleted');
                this.$state.go('aq.projectCenter.overview');
            }, (err) => {
                this.Messages.error('Error deleting project');
            });
        }

    }
    angular.module('projectCenter').controller('ProjectDetail', ProjectDetail);
}
