angular.module('aq.ui.error', ['aq.services.errors'])
.directive('error', function () {
    return {
        restrict: 'E',
        scope: {
            ngModel: '=',
            field: '='
        },
        templateUrl: 'app/common/directives/error/error.html',
        replace: true,
        link: function ($scope, $element, $attrs) {
            
            $scope.showError = true;
            $scope.$watch('ngModel.errors.forField(field)', function() {
               $scope.showError = true; 
            });
            
            var fieldWatcher = null;
            
            $scope.$watch('field', function(field) {
                if (!field) return;
                
                if (fieldWatcher) fieldWatcher();
                
                if (!_.includes(field, '[]')) {
                    fieldWatcher = $scope.$watch('ngModel.' + field, function(nv, ov) {
                        if (nv && nv !== ov) {
                            $scope.showError = false; 
                        }
                    });
                }
                
            });

        }
    };
})
