angular.module('tenantBilling').filter('formatReading', function($filter, OptionsService) {
    return function(reading, unit) {
        if (reading != null) {
            const steamUnit = OptionsService.getUnitLabelByMeasure('STEAM');
            const isNegative = reading < 0;
            const absReading = Math.abs(reading);
            let formattedReading = $filter('number')(absReading, '0,0');
            if (steamUnit.unit === unit) {
                formattedReading = $filter('number')(absReading, '0,0.[00]');
            }

            if (isNegative) {
                return `(${formattedReading})`;
            }
            return `${formattedReading}`;
        }
        return null;
    };
});
