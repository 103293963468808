namespace aq.deviceManagement {
    export class AddTenant extends aq.common.Controllers.ModalCtrl {
        private newTenant;
        tenantLabel: string;
        /* @ngInject */
        constructor(
            private Auth: aq.services.Auth,
            $mdDialog
        ) {
            super({}, $mdDialog);
            this.newTenant = { name: '' };
            this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'unit' : 'tenant';
        }

    }

    angular
        .module('deviceManagement')
        .controller('AddTenant', AddTenant);
}
