namespace aq.integrations {

    export class IntegrationsCtrl {
        /* @ngInject */
        constructor(
            private $scope: IntegrationsCtrlScope,
            private $mdDialog,
            private integrationApps: any[],
            private integrations,
            private $state: ng.ui.IStateService
        ) {
            integrationApps.forEach((app) => {
                let integration: any = _.find(integrations, { integrationApp: { name: app.name } });
                if (integration) {
                    app.integrationId = integration.id;
                    app.installed = true;
                } else {
                    app.installed = false;
                }
            });
            $scope.integrationApps = integrationApps;
            $scope.myIntegrationApps = _.filter(integrationApps, { installed: true });
        }

        navigateToIntegration(integration) {
            if (integration.installed) {
                let state = 'aq.accounts.integrations.details';
                if (integration.uiState) {
                    state = `${state}.${integration.uiState}`;
                }
                this.$state.go(state, { integrationId: integration.integrationId });
            } else {
                this.$state.go('aq.accounts.integrations.new', { integrationApp: integration.id });
            }
        }

        showRequestNewIntegrationModal = (ev) => {
            this.$mdDialog.show({
                /* @ngInject */
                controller($scope, $mdDialog) {
                    $scope.hide = function () {
                        $mdDialog.hide();
                    };
                },
                templateUrl: 'app/settings/integrations/newIntegrationModal.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true
            });
        }
    }

    export interface IntegrationsCtrlScope extends ng.IScope {
        integrationApps: any[];
        myIntegrationApps: any[];
    }

    angular.module('accounts').controller('IntegrationsCtrl', IntegrationsCtrl);
}
