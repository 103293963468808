var aq;
(function (aq) {
    var models;
    (function (models) {
        var activity;
        (function (activity_1) {
            var ProductCategory;
            (function (ProductCategory) {
                ProductCategory["LOAD_ANALYTICS"] = "Load Analytics";
                ProductCategory["HVAC_ANALYTICS"] = "HVAC Analytics";
                ProductCategory["OPTIMIZATION_STRATEGY"] = "Optimization Strategies";
                ProductCategory["NOTE"] = "Notes";
                ProductCategory["ALERT"] = "Alerts";
                ProductCategory["OTHER"] = "Other";
            })(ProductCategory = activity_1.ProductCategory || (activity_1.ProductCategory = {}));
            var ActivityListResponse = /** @class */ (function () {
                function ActivityListResponse() {
                    this.payload = [];
                    this.count = void 0;
                }
                return ActivityListResponse;
            }());
            activity_1.ActivityListResponse = ActivityListResponse;
            var ActivityContextType;
            (function (ActivityContextType) {
                ActivityContextType["NONE"] = "NONE";
                ActivityContextType["ALERT"] = "ALERT";
                ActivityContextType["AID_ISSUE"] = "AID_ISSUE";
                ActivityContextType["ENERGY_NOTE"] = "ENERGY_NOTE";
                ActivityContextType["OPTIMAL_START"] = "OPTIMAL_START";
                ActivityContextType["RX"] = "RX";
            })(ActivityContextType = activity_1.ActivityContextType || (activity_1.ActivityContextType = {}));
            var ActivityType;
            (function (ActivityType) {
                ActivityType["ISSUE"] = "ISSUE";
                ActivityType["SUGGESTION"] = "SUGGESTION";
                ActivityType["OBSERVATION"] = "OBSERVATION";
                ActivityType["EVENT"] = "EVENT";
                ActivityType["REMINDER"] = "REMINDER";
            })(ActivityType = activity_1.ActivityType || (activity_1.ActivityType = {}));
            var SimpleActivityResponse = /** @class */ (function () {
                function SimpleActivityResponse() {
                }
                return SimpleActivityResponse;
            }());
            activity_1.SimpleActivityResponse = SimpleActivityResponse;
            var FilterDateRange;
            (function (FilterDateRange) {
                FilterDateRange["WEEK"] = "WEEK";
                FilterDateRange["MONTH"] = "MONTH";
                FilterDateRange["QUARTER"] = "QUARTER";
            })(FilterDateRange = activity_1.FilterDateRange || (activity_1.FilterDateRange = {}));
            var ActivityCategory;
            (function (ActivityCategory) {
                ActivityCategory["URGENT"] = "URGENT";
                ActivityCategory["TODAY"] = "TODAY";
                ActivityCategory["THIS_WEEK"] = "THIS_WEEK";
                ActivityCategory["THIS_MONTH"] = "THIS_MONTH";
                ActivityCategory["THIS_QUARTER"] = "THIS_QUARTER";
            })(ActivityCategory = activity_1.ActivityCategory || (activity_1.ActivityCategory = {}));
            activity_1.getActivitySavings = function (activity) {
                // TODO expand to include getting annualized savings for AID issues
                if (activity.context) {
                    if (activity.context.contextObject) {
                        // RX activity
                        var _a = activity.context.contextObject, calculated_utility_savings_dollar = _a.calculated_utility_savings_dollar, calculated_utility_savings_override = _a.calculated_utility_savings_override;
                        if (calculated_utility_savings_dollar && calculated_utility_savings_dollar > 0) {
                            return calculated_utility_savings_dollar;
                        }
                        else if (calculated_utility_savings_override && calculated_utility_savings_override > 0) {
                            return calculated_utility_savings_override;
                        }
                    }
                    else if (activity.context.type === ActivityContextType.AID_ISSUE) {
                        if (!activity.savingsProfile || !activity.savingsProfile.savings || !activity.savingsProfile.savings.savingsForActivity) {
                            console.error("No savings profile for activity with Id: " + activity.id);
                            return 0;
                        }
                        return activity.savingsProfile.savings.savingsForActivity;
                    }
                }
                return 0;
            };
            var CategorizedActivities = /** @class */ (function () {
                function CategorizedActivities() {
                    var _this = this;
                    this.isEmpty = function () {
                        return _this.activities.length === 0;
                    };
                    this.add = function (activity, mode) {
                        _this.activities.push(activity);
                        _this.messageCount += activity.messages;
                        if (activity.context && activity.context.type === ActivityContextType.AID_ISSUE && mode === aq.ui.ActivityMode.CARD) {
                            _this.annualizedSavingsCategory = activity.savingsProfile.savingsForActivity;
                            _this.totalCardActivities = activity.savingsProfile.ytdActivities;
                        }
                        else {
                            _this.totalSavings += activity_1.getActivitySavings(activity);
                        }
                        return _this;
                    };
                    this.sort = function (fn) {
                        _this.activities = _this.activities.sort(fn);
                        return _this;
                    };
                    this.reverse = function () {
                        _this.activities = _this.activities.reverse();
                        return _this;
                    };
                    this.activities = [];
                    this.messageCount = 0;
                    this.totalSavings = 0;
                    this.annualizedSavingsCategory = 0;
                }
                CategorizedActivities.prototype.getActivities = function () {
                    return this.activities;
                };
                CategorizedActivities.prototype.getCardSavingsActivities = function () {
                    return this.totalCardActivities;
                };
                // TODO hacky remove soon
                CategorizedActivities.prototype.annualizedSavings = function (fiscalStartMonth) {
                    var end = new Date();
                    var startYear = end.getMonth() - 1 < fiscalStartMonth ? end.getFullYear() - 1 : end.getFullYear();
                    var start = new Date(startYear, fiscalStartMonth);
                    var numberOfDays = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);
                    this.annualizedSavingsCategory = (this.totalSavings / numberOfDays) * 365;
                };
                return CategorizedActivities;
            }());
            activity_1.CategorizedActivities = CategorizedActivities;
            var Category = /** @class */ (function () {
                function Category(category, key) {
                    this.category = category;
                    this.key = key;
                }
                return Category;
            }());
            activity_1.Category = Category;
            var GroupedActivities = /** @class */ (function () {
                function GroupedActivities() {
                    var _a;
                    this.activities = (_a = {},
                        _a[ProductCategory.LOAD_ANALYTICS] = {},
                        _a[ProductCategory.HVAC_ANALYTICS] = {},
                        _a[ProductCategory.OPTIMIZATION_STRATEGY] = {},
                        _a[ProductCategory.ALERT] = {},
                        _a[ProductCategory.NOTE] = {},
                        _a[ProductCategory.OTHER] = {},
                        _a);
                }
                GroupedActivities.prototype.remove = function (category) {
                    if (category && category in this.activities) {
                        delete this.activities[category];
                    }
                    return this;
                };
                GroupedActivities.prototype.add = function (category, key, value, mode) {
                    if (value.context
                        && value.context.data
                        && value.context.data.selection
                        && (value.context.data.selection.minValue < 1 || value.context.data.selection.maxValue < 1)) {
                        return this;
                    }
                    if (category in this.activities && key in this.activities[category]) {
                        this.activities[category][key].add(value, mode);
                    }
                    else {
                        this.activities[category][key] = new CategorizedActivities();
                        this.activities[category][key].add(value, mode);
                    }
                    return this;
                };
                GroupedActivities.prototype.removeEmpty = function () {
                    var _this = this;
                    Object.keys(this.activities)
                        .forEach(function (category) {
                        if (Object.keys(_this.activities[category]).length === 0) {
                            return delete _this.activities[category];
                        }
                        Object.keys(_this.activities[category]).forEach(function (key) {
                            if (_this.activities[category][key] === void 0 || _this.activities[category][key].isEmpty()) {
                                delete _this.activities[category][key];
                            }
                        });
                    });
                    return this;
                };
                GroupedActivities.prototype.isEmpty = function () {
                    return Object.keys(this.activities).length <= 0;
                };
                GroupedActivities.prototype.getClassification = function (activity) {
                    var type = activity.context.type, _title = activity.title;
                    var title = _title.toLowerCase();
                    var defaultCat = new Category(ProductCategory.OTHER, 'Other');
                    switch (activity.type) {
                        case ActivityType.OBSERVATION:
                            if (ActivityContextType.AID_ISSUE === type) {
                                if (title.includes('weekend')) {
                                    return new Category(ProductCategory.LOAD_ANALYTICS, 'Weekend Run');
                                }
                                if (title.includes('nighttime')) {
                                    return new Category(ProductCategory.LOAD_ANALYTICS, 'Nighttime Run');
                                }
                                if (title.includes('holiday')) {
                                    return new Category(ProductCategory.LOAD_ANALYTICS, 'Holiday Run');
                                }
                                if (title.includes('unoccupied')) {
                                    return new Category(ProductCategory.LOAD_ANALYTICS, 'Unoccupied Hour Run');
                                }
                            }
                            if (ActivityContextType.ENERGY_NOTE === type) {
                                return new Category(ProductCategory.NOTE, 'Note');
                            }
                            break;
                        case ActivityType.SUGGESTION:
                            if (ActivityContextType.OPTIMAL_START === type) {
                                return new Category(ProductCategory.OPTIMIZATION_STRATEGY, 'Optimal Start');
                            }
                            return new Category(ProductCategory.OTHER, 'Other');
                        case ActivityType.ISSUE:
                            if (type === 'RX') {
                                return new Category(ProductCategory.HVAC_ANALYTICS, this.getPrescriptionGroup(activity));
                            }
                            return new Category(ProductCategory.ALERT, _.getTitle(activity.context.acknowledgedStatus));
                        default: return defaultCat;
                    }
                    return defaultCat;
                };
                GroupedActivities.prototype.groupByClassification = function (filter, activities, annualizedSavings, mode, building) {
                    var _this = this;
                    activities.forEach(function (activity) {
                        var _a = _this.getClassification(activity), category = _a.category, key = _a.key;
                        if (category === ProductCategory.HVAC_ANALYTICS) {
                            activity.title = _this.getPrescriptionTitle(activity);
                        }
                        else if (_this.isAnnualizedSavingsCategory(category)) {
                            // TODO this should be expanded to include sub categories for HVAC and Optimal Start
                            var savingsKey = void 0;
                            switch (key) {
                                case 'Weekend Run':
                                    savingsKey = aq.models.aidIssue.EventType.WEEKEND_RUN;
                                    break;
                                case 'Nighttime Run':
                                    savingsKey = aq.models.aidIssue.EventType.NIGHTTIME_RUN;
                                    break;
                                case 'Holiday Run':
                                    savingsKey = aq.models.aidIssue.EventType.HOLIDAY_RUN;
                                    break;
                                case 'Unoccupied Hour Run':
                                    savingsKey = aq.models.aidIssue.EventType.UNOCCUPIED_HOUR_RUN;
                                    break;
                                default:
                                    break;
                            }
                            if (annualizedSavings) {
                                if (savingsKey && annualizedSavings[savingsKey]) {
                                    if (mode === aq.ui.ActivityMode.CARD) {
                                        activity.savingsProfile = annualizedSavings[savingsKey]['annualizedSavings'];
                                    }
                                    else {
                                        activity.savingsProfile = annualizedSavings[savingsKey][activity.id];
                                    }
                                }
                            }
                        }
                        _this.add(category, key, activity, mode);
                    });
                    // TODO HACKS ON HACKS ON HACKS
                    if (this.activities[ProductCategory.HVAC_ANALYTICS]['Uncategorized Prescription']) {
                        delete this.activities[ProductCategory.HVAC_ANALYTICS]['Uncategorized Prescription'];
                    }
                    // sort the resulting categories by creation date
                    this.sort(mode, building.fiscalStartMonth);
                    return this;
                };
                GroupedActivities.prototype.sort = function (mode, fiscalStartMonth) {
                    var _this = this;
                    Object.keys(this.activities)
                        .forEach(function (category) {
                        Object.keys(_this.activities[category])
                            .forEach(function (key) {
                            var val = _this.activities[category][key];
                            if (val && !val.isEmpty()) {
                                _this.activities[category][key]
                                    .sort(function (a, b) { return moment(a.createdAt).diff(moment(b.createdAt)); }).reverse();
                                // TODO this is a hack to get HVAC savings to be annualized for the 10/4 demo. Future work
                                // should remove this and make it so HVAC annualized savings are returned in the same way
                                // Load analytics savings are
                                if (mode === aq.ui.ActivityMode.CARD && category === ProductCategory.HVAC_ANALYTICS) {
                                    _this.activities[category][key].annualizedSavings(fiscalStartMonth);
                                }
                            }
                        });
                    });
                };
                GroupedActivities.prototype.getActivities = function () {
                    return this.activities;
                };
                GroupedActivities.prototype.isAnnualizedSavingsCategory = function (category) {
                    var annualizedSavingsCategories = this.getAnnualizedSavingsCategories();
                    return annualizedSavingsCategories.includes(category);
                };
                GroupedActivities.prototype.getAnnualizedSavingsCategories = function () {
                    return [
                        ProductCategory.HVAC_ANALYTICS,
                        ProductCategory.LOAD_ANALYTICS,
                        ProductCategory.OPTIMIZATION_STRATEGY
                    ];
                };
                GroupedActivities.prototype.getPrescriptionGroup = function (activity) {
                    if (activity.context && activity.context.contextObject) {
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        var _a = activity.context.contextObject, diagnosis_category = _a.diagnosis_category, device_id = _a.device_id, system_type = _a.system_type;
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        if (system_type) {
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            return system_type;
                        }
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        if (device_id) {
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            return device_id.replace('_', ' ');
                        }
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        if (diagnosis_category) {
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            return diagnosis_category;
                        }
                    }
                    return 'Uncategorized Prescription';
                };
                GroupedActivities.prototype.getPrescriptionTitle = function (activity) {
                    if (activity.context.contextObject) {
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        var device_id = activity.context.contextObject.device_id;
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        if (device_id) {
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            return device_id.replace('_', ' ') + ": " + activity.title;
                        }
                    }
                    return activity.title;
                };
                return GroupedActivities;
            }());
            activity_1.GroupedActivities = GroupedActivities;
            var MediaType;
            (function (MediaType) {
                MediaType["PDF"] = "PDF";
                MediaType["IMAGE"] = "IMAGE";
                MediaType["VIDEO"] = "VIDEO";
                MediaType["AUDIO"] = "AUDIO";
                MediaType["EXCEL"] = "EXCEL";
            })(MediaType = activity_1.MediaType || (activity_1.MediaType = {}));
            var MediaResponse = /** @class */ (function () {
                function MediaResponse() {
                }
                return MediaResponse;
            }());
            activity_1.MediaResponse = MediaResponse;
            var ActivityMessageAuthor = /** @class */ (function () {
                function ActivityMessageAuthor() {
                }
                return ActivityMessageAuthor;
            }());
            activity_1.ActivityMessageAuthor = ActivityMessageAuthor;
            var AlertIssue = /** @class */ (function () {
                function AlertIssue() {
                }
                return AlertIssue;
            }());
            activity_1.AlertIssue = AlertIssue;
            var ActivityMessage = /** @class */ (function () {
                function ActivityMessage() {
                }
                return ActivityMessage;
            }());
            activity_1.ActivityMessage = ActivityMessage;
            var ActivityResponse = /** @class */ (function () {
                function ActivityResponse() {
                }
                return ActivityResponse;
            }());
            activity_1.ActivityResponse = ActivityResponse;
            var ActivityMessageDraft = /** @class */ (function () {
                function ActivityMessageDraft() {
                }
                return ActivityMessageDraft;
            }());
            activity_1.ActivityMessageDraft = ActivityMessageDraft;
            var ActivityMessageCreateRequest = /** @class */ (function () {
                function ActivityMessageCreateRequest() {
                }
                return ActivityMessageCreateRequest;
            }());
            activity_1.ActivityMessageCreateRequest = ActivityMessageCreateRequest;
        })(activity = models.activity || (models.activity = {}));
    })(models = aq.models || (aq.models = {}));
})(aq || (aq = {}));
