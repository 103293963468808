var aq;
(function (aq) {
    var publicDisplay;
    (function (publicDisplay) {
        var PublicDisplaySelectService = /** @class */ (function (_super) {
            __extends(PublicDisplaySelectService, _super);
            /* @ngInject */
            function PublicDisplaySelectService(AppStorageService, UserService) {
                var _this = _super.call(this, AppStorageService, UserService) || this;
                _this.AppStorageService = AppStorageService;
                _this.UserService = UserService;
                return _this;
            }
            PublicDisplaySelectService.prototype.getRedirect = function (currentDashboardId, account, dashboards) {
                var redirect = _super.prototype.getRedirect.call(this, currentDashboardId, account, dashboards);
                if (redirect) {
                    switch (redirect.state) {
                        case 'aq.dashboard.configurable':
                            redirect.state = 'aq.publicDisplay.configurable';
                            break;
                        case 'aq.dashboard.noAvailableDashboards':
                            redirect.state = 'aq.publicDisplay.noAvailableDisplays';
                            break;
                    }
                }
                return redirect;
            };
            return PublicDisplaySelectService;
        }(aq.dashboard.DashboardSelectService));
        publicDisplay.PublicDisplaySelectService = PublicDisplaySelectService;
        angular.module('aq.publicDisplay').service('PublicDisplaySelectService', PublicDisplaySelectService);
    })(publicDisplay = aq.publicDisplay || (aq.publicDisplay = {}));
})(aq || (aq = {}));
