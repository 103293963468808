namespace aq.services {

    export interface RawParams {
        start: string;
        end: string;
        queryable: string;
        queryableId: number;
        measure: string;
        unit: string;
        interval: string;
        calculate?: string;
    }

    export interface FetchQueryableParams {
        accountId: string;
        buildingId: string;
    }

    export interface FetchQueryableResult {
        bmsPointName?: string;
        name: string;
        id: number;
    }

    export enum Queryable {
        BUILDINGS = 'buildings',
        SPACES = 'spaces',
        TENANTS = 'tenants',
        METERS = 'meters',
        SOURCES = 'sources',
        POINTS = 'points',
        BMS_POINTS = 'bms_points'
    }

    export enum Measure {
        ELECTRICITY = 'ELECTRICITY',
        WATER = 'WATER',
        GAS = 'GAS',
        STEAM = 'STEAM',
        HEAT = 'HEAT',
        CO2 = 'CO2',
        HUMIDITY = 'HUMIDITY',
        TEMPERATURE = 'TEMPERATURE',
        STATE = 'STATE'
    }

    export enum Unit {
        W = 'W',
        KW = 'kW',
        KWH = 'kWh',
        CCF = 'CCF',
        MLB = 'Mlb',
        L = 'L',
        L_PER_SECOND = 'L/sec',
        GAL = 'gal',
        GPM = 'gpm',
        F = 'F',
        C = 'C',
        PPM = 'ppm',
        PERCENT = '%',
        STATE = 'state'
    }

    export interface QueryableType {
        display: string;
        queryable: Queryable;
    }

    export interface MeasureUnits {
        measure: Measure;
        units: Unit[];
    }

    export interface MeasureUnit {
        measure: Measure;
        unit: Unit;
    }

    // TODO: move to backend? a lot of business logic here that's probably more suited for backend
    const VALID_MEASURE_UNITS: MeasureUnits[] = [
        { measure: Measure.ELECTRICITY, units: [Unit.KW, Unit.KWH] }, // Unit.W is valid but we don't want to query it
        { measure: Measure.WATER, units: [Unit.GAL, Unit.GPM, Unit.L, Unit.L_PER_SECOND] },
        { measure: Measure.GAS, units: [Unit.CCF] },
        { measure: Measure.STEAM, units: [Unit.MLB] },
        // { measure: Measure.HEAT, units: [Unit.] }, // ???
        { measure: Measure.CO2, units: [Unit.PPM] },
        { measure: Measure.HUMIDITY, units: [Unit.PERCENT] },
        { measure: Measure.TEMPERATURE, units: [Unit.F, Unit.C] },
        { measure: Measure.STATE, units: [Unit.STATE] }
    ];

    export class RawService {
        /* @ngInject */
        constructor(
            private RestangularV3: restangular.IService
        ) { }

        public getQueryableTypes(): Queryable[] {
            // TODO: more dynamic way to grab these
            return [
                Queryable.BUILDINGS,
                Queryable.SPACES,
                Queryable.TENANTS,
                Queryable.METERS,
                Queryable.SOURCES,
                Queryable.POINTS,
            ];
        }

        public getQueryableTypesWithBmsPoints(): Queryable[] {
            return [...this.getQueryableTypes(), Queryable.BMS_POINTS];
        }

        public getValidMeasureUnits(): MeasureUnits[] {
            return VALID_MEASURE_UNITS;
        }

        public getFlatValidMeasureUnits(): MeasureUnit[] {
            return _.flatMap(
                this.getValidMeasureUnits(),
                (measureUnits: aq.services.MeasureUnits) => {
                    return _.map(measureUnits.units, (unit: aq.services.Unit) => {
                        return {
                            measure: measureUnits.measure,
                            unit
                        };
                    });
                }
            );
        }

        public fetchQueryables(queryable: Queryable, params: FetchQueryableParams) {
            switch (queryable) {
                case Queryable.BUILDINGS:
                    return this.fetchBuildings(params.accountId);
                case Queryable.SPACES:
                    return this.fetchSpaces(params.buildingId);
                case Queryable.TENANTS:
                    return this.fetchTenants(params.buildingId);
                case Queryable.METERS:
                    return this.fetchMeters(params.buildingId);
                case Queryable.SOURCES:
                    return this.fetchSources(params.buildingId);
                case Queryable.POINTS:
                    return this.fetchPoints(params.buildingId);
                default:
                    return Promise.resolve([]);
            }
        }

        public fetchBuildings(accountId: string) {
            return this.RestangularV3.all('buildings')
                .getList({ accountId })
                .then((buildings) => {
                    return _.map(buildings, (building: aq.common.models.Building) => {
                        return {
                            name: building.name,
                            id: building.id
                        };
                    });
                });
        }

        public fetchSpaces(buildingId: string) {
            return this.RestangularV3.all('spaces')
                .getList({ buildingId })
                .then((spaces) => {
                    return _.map(spaces, (space: aq.common.models.Space) => {
                        return {
                            name: space.name,
                            id: space.id
                        };
                    });
                });
        }

        public fetchTenants(buildingId: string) {
            return this.RestangularV3.all('tenants')
                .getList({ buildingId })
                .then((tenants) => {
                    return _.map(tenants, (tenant: aq.common.models.Tenant) => {
                        return {
                            name: tenant.name,
                            id: tenant.id
                        };
                    });
                });
        }

        public fetchMeters(buildingId: string) {
            return this.RestangularV3.all('devices')
                .getList({ buildingId })
                .then((devices) => {
                    return _.map(devices, (device: aq.common.models.Device) => {
                        return {
                            name: device.name,
                            id: device.id
                        };
                    });
                });
        }

        public fetchSources(buildingId: string) {
            return this.RestangularV3.all('sources')
                .getList({ buildingId })
                .then((sources) => {
                    return _.map(sources, (source: aq.common.models.Source) => {
                        return {
                            name: source.name,
                            id: source.id
                        };
                    });
                });
        }

        public fetchPoints(buildingId: string) {
            return this.RestangularV3.all('points')
                .getList({ buildingId })
                .then((points) => {
                    return _.map(points, (point: aq.common.models.Point) => {
                        return {
                            name: `${point.collectorName} - ${point.name}`,
                            id: point.id
                        };
                    });
                });
        }

        public fetchBmsPoints(buildingId: string) {
            return this.RestangularV3.all('bms-points')
                .getList({ buildingId })
                .then((points) => {
                    return _.map(points, (point: aq.common.models.BmsPoint) => {
                        return {
                            name: `${point.deviceName}  - ${point.bmsPointName}`,
                            bmsPointName: point.bmsPointName,
                            id: point.deviceId
                        };
                    });
                });
        }

        public getLatest(params: RawParams) {
            return this.RestangularV3.one(params.queryable, params.queryableId)
                .one('raw')
                .get({
                    start: params.start,
                    end: params.end,
                    measure: params.measure,
                    unit: params.unit,
                    interval: params.interval,
                    calculate: 'last'
                });
        }
    }

    angular.module('aq.services').service('RawService', RawService);
}
