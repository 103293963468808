namespace aq.tenantbilling {
    export class ApprovePeriodStepCtrl {
        public approvalActionSetups: ApprovalActionSetup[];
        public billingReportSetups: BillingReportSetup[];
        public users: aq.common.models.User[];
        public progressText: string;
        public currencyUnit: any;
        public onComplete: Function;
        private monthlyBilling: MonthlyTenantBilling;
        private tenantInvoices: TenantInvoice[];
        private selectedUser;
        private userSearchText: string;
        tenantLabel: string;

        constructor(
            private $q: ng.IQService,
            private $location: ng.ILocationService,
            public $mdDialog: ng.material.IDialogService,
            private $interval: ng.IIntervalService,
            private Messages: aq.services.Messages,
            private $mdStepper,
            private OptionsService,
            private DataStore: aq.common.DataStore,
            private RestangularV3: restangular.IService,
            private MonthlyBillingService: MonthlyBillingService,
            private $stateParams: ng.ui.IStateParamsService,
            private Auth: aq.services.Auth
        ) {
            this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'unit' : 'tenant';
        }

        public $onInit() {
            this.currencyUnit = this.OptionsService.currencyUnit();
            this.progressText = 'Loading approval actions...';
            const promises = [
                this.DataStore.getList(this.RestangularV3.one(''), 'approval-action-setups', { buildingId: this.monthlyBilling.building }),
                this.DataStore.getList(this.RestangularV3.one(''), 'billing-report-setups', { buildingId: this.monthlyBilling.building }),
                this.DataStore.getList(this.RestangularV3.one(''), 'users', { accountId: this.$stateParams.accountId })
            ];
            this.$q.all(promises)
                .then((results) => {
                    this.progressText = null;
                    this.approvalActionSetups = results[0];
                    this.billingReportSetups = results[1];
                    this.users = results[2];
                    this.approvalActionSetups = this.mapUserIdsToUsers(this.approvalActionSetups);
                    if (this.Auth.hasFunctionality("Multifamily Tenant Billing")) {
                        this.updateBillingReportSetups();
                    }
                });
        }

        private updateBillingReportSetups() {
            this.billingReportSetups.forEach((report) => {
                if (report.reportName && report.reportName.includes("Tenant")) {
                    report.multiFamilyName = report.reportName.replace("Tenant", "Resident");
                }
            });
        }

        public mapUserIdsToUsers(actions) {
            return _.map(actions, (actionSetup) => {
                actionSetup.users = _.map(actionSetup.users, (userId) => {
                    return _.find(this.users, { id: userId });
                });
                return actionSetup;
            });
        }

        public previousStep() {
            const previousStepNumber = this.$mdStepper('runInvoicingStepper').currentStep;
            this.$location.search('step', previousStepNumber);
            this.$mdStepper('runInvoicingStepper').back();
        }

        public queryUsers(searchText, filterChoices) {
            searchText = searchText.toLowerCase();
            return this.users.filter((user) => {
                return user.fullName.toLowerCase().indexOf(searchText) > -1 && _.findIndex(filterChoices, {fullName: user.fullName}) === -1;
            });
        }

        public saveAndContinue(ev) {
            const htmlContent = this.Auth.hasFunctionality("Multifamily Tenant Billing") ? "residents" : "tenants";
            const confirm = this.$mdDialog.confirm()
                .title('Are you sure you want to approve this billing period?')
                .htmlContent(`This will lock the charges on this billing period, and generate invoices for your ${htmlContent}.`)
                .ariaLabel('Approve Billing Period and Generate Invoices')
                .targetEvent(ev)
                .ok('Ok')
                .cancel('Cancel');
            this.$mdDialog.show(confirm)
                .then(() => {
                    this.progressText = 'Updating approval actions...';
                    const promises = _.concat([],
                        _.map(this.approvalActionSetups, (actionSetup) => {
                            actionSetup = this.transformApprovalActionSetupForRequest(actionSetup);
                            return this.MonthlyBillingService.saveApprovalActionSetup(actionSetup);
                        }),
                        _.map(this.billingReportSetups, (reportSetup) => {
                            return this.MonthlyBillingService.saveBillingReportSetup(reportSetup);
                        })
                    );
                    return this.$q.all(promises);
                })
                .then(() => {
                    this.progressText = 'Approving period and generating invoices...';
                    return this.MonthlyBillingService.approve(this.monthlyBilling);
                })
                .then((billing) => {
                    this.progressText = 'Generating stats...';
                    this.monthlyBilling = billing;
                    return this.$q.all([
                        this.MonthlyBillingService.getChargeCategories(this.monthlyBilling),
                        this.MonthlyBillingService.getInvoices(this.monthlyBilling)
                    ]);
                })
                .then((results) => {
                    this.onComplete({
                        $event: {
                            monthlyBilling: this.monthlyBilling,
                            chargeCategories: results[0],
                            tenantInvoices: results[1],
                            backActionStateData: {
                                updatedBillingId: this.monthlyBilling.id,
                                timeStamp: new Date()
                            }
                        }
                    });
                    this.Messages.success('Period successfully approved and invoices have been generated');
                })
                .finally(() => {
                    this.progressText = null;
                });
        }

        private transformApprovalActionSetupForRequest(approvalActionSetup: ApprovalActionSetup) {
            approvalActionSetup.users = _.map(approvalActionSetup.users, 'id');
            return approvalActionSetup;
        }
    }

    angular
        .module('tenantBilling')
        .component('approvePeriodStep', {
            templateUrl: 'app/tenantBilling/invoicing/run/components/approvePeriodStep/approvePeriodStep.html',
            controller: ApprovePeriodStepCtrl,
            controllerAs: 'vm',
            bindings: {
                monthlyBilling: '<',
                tenantInvoices: '<',
                onComplete: '&'
            }
        });
}
