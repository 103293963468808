namespace aq.propertySettings {

    export interface TargetItem {
        startDate: string;
        endDate: string;
        value: number;
        target?: number | string;
    }

    export interface Target extends restangular.IElement {
        name?: string;
        measure: any;
        utilityService: any;
        targetItems: TargetItem[];
        type: string;
        displayType: string;
        status: string;
        year: number;
    }

    export interface Budget extends Target { }

    export interface MeterOfflineAlert {
        id?: string;
        name?: string;
        description?: string;
        currentState?: any;
    }

    export interface RealTimeEnergyAlert extends AbstractNotification, restangular.IElement {
        drillMode?: string;
        queryableId?: number;
        unit?: any;
        greaterThan?: number;
        smallerThan?: number;
        greaterThanAverage?: number;
        smallerThanAverage?: number;
        whenCondition?: string;
        calendar?: number;
        category?: number;
        triggerType?: string;
        customRule?: any;
        issueDuration: number;
        issueCreatedOn: string;
        issueClosedOn: string;
    }

    export interface AbstractNotification {
        id?: string;
        building: string;
        name: string;
        description: string;
        active: boolean;
        admin: boolean;
        type: string;
        notificationType: 'USAGE_ALERT' | 'ESCALATION' | 'STATE_ALERT';
        senders: any[];
        receivers: any[];
        dayCount: number;
        lifetimeCount: number;
        issue?: aq.models.activity.AlertIssue;
        // Below for UI only, this is used to convert to senders and receivers
        sendTextMessage?: boolean;
        sendEmail?: boolean;
        selectedUsers?: aq.common.models.User[];
    }

    export enum LevelSeverity {
        NORMAL = 'NORMAL',
        WARNING = 'WARNING',
        CRITICAL = 'CRITICAL',
        UNKNOWN = 'UNKNOWN'
    }

    export enum WhenCondition {
        CALENDAR = 'CALENDAR',
        ANYTIME = 'ANYTIME',
        CUSTOM = 'CUSTOM'
    }

    export interface StateAlert extends AbstractNotification, restangular.IElement {
        interval: string;
        maxAgeMinutes: number;
        defaultSeverity: LevelSeverity;
        alertOn: LevelSeverity.WARNING | LevelSeverity.CRITICAL;
        whenCondition: WhenCondition;
        calendar?: number;
        category?: number;
        customRule?: {
            dateStart?: string;
            dateEnd?: string;
            timeStart?: string;
            timeEnd?: string;
        };
        alertLevels: Array<StateAlertLevel>;
        active: boolean;
        admin: boolean;
        type: string;
        acknowledgedStatus: boolean;
        currentIssueId: string;
        escalations: number;
        violations: number;
        latestViolation: string;
        issueDuration: number;
        issueCreatedOn: string;
        issueClosedOn: string;
    }

    export interface StateAlertLevel {
        id?: string;
        order: number;
        levelName: string;
        actionDescription: string;
        levelSeverity: LevelSeverity;
        queryableType: aq.services.Queryable;
        queryableId: number;
        measure: aq.services.Measure;
        unit: aq.services.Unit;
        lowerBoundary?: number;
        upperBoundary?: number;
        // for UI only
        queryable?: aq.services.FetchQueryableResult;
        measureUnit?: string;
        bmsPointName?: string;
    }

    export interface Project extends restangular.IElement {
        id?: string;
        name?: string;
        basePeriodStartDate?: any;
        basePeriodEndDate?: any;
        implementationPeriodStartDate: any;
        implementationPeriodEndDate: any;
        reportingPeriodStartDate?: any;
        reportingPeriodEndDate?: any;
        status?: string;
        cost?: number;
        expectedSavings?: number;
        budgetType?: string;
        owner?: string; // user id
        tasks: Task[];
    }

    export interface Task {
        id?: string;
        name?: string;
        owner?: string; // user id
        details?: string;
        completionDate?: string;
        dueDate?: string;
    }

    export interface ProjectResults {
        totalSavings: number;
        savingsPerUtility: { utility: string, savings: number }[];
    }

    export interface RecommendedProject extends restangular.IElement {
        name: string;
        description: string;
        image: string;
    }

    export interface HistoricUploadResponse {
        buildingId: number;
        devices: HistoricUploadDeviceResponse[];
    }

    export interface HistoricUploadDeviceResponse {
        deviceId: number;
        deviceName: string;
        pointId: number;
        pointName: string;
        data: { number: HistoricUploadDeviceDataResponse };
    }

    export interface HistoricUploadDeviceDataResponse {
        consumption?: number;
        power?: number;
    }

    export class BuildingOccupancy {
        id: any;
        startDate: any;
        occupancyPercent: number;
        building: any;
    }

    export interface SelectionItem {
        id: number;
        name: string;
    }

    export interface OptimalStart {
        id?: number;
        active: boolean;
        building: number;
        buildingStart: number;
        buffer: number;
        indoorSetpoint: number;
        vavOrCav: string;
        waterCooled: boolean;
        insulationLevel: number;
        maxoutRateCooling: number;
        maxoutRateHeating: number;
        staggerCoolingEnabled: boolean;
        staggerCoolingSteps: number;
        staggerCoolingInterval: number;
        staggerHeatingEnabled: boolean;
        staggerHeatingSteps: number;
        staggerHeatingInterval: number;
        emailRecipients: SelectionItem[];
        startThreshold?: number;
        recursiveSearch?: boolean;
        peakSeasons: PeakSeason[];
    }

    export interface PeakSeason {
        id?: number;
        optimalStart?: number;
        monthStart: number;
        dayStart: number;
        monthEnd: number;
        dayEnd: number;
        timeStart: string;
        timeEnd: string;
    }

    export interface BuildingEnrollment {
        project: number;
        building: number;
        account: number;
        name: string;
        status: string;
        progress: number;
        currentPhase: string;
        phases: BuildingEnrollmentPhase[];
    }

    export interface BuildingEnrollmentPhase {
        phase: number;
        name: string;
        description?: string;
        sortOrder: number;
        status: string;
        progress: number;
        tasks: TaskEnrollmentResponse[];
    }

    export interface TaskEnrollmentResponse extends aq.projectCenter.Task {
        ownerName?: string;
        eventHandler?: string;
    }
    export interface BuildingEnrollmentUpdateRequest {
        project: number;
        phases?: BuildingEnrollmentPhaseUpdateRequest[];
    }

    export interface BuildingEnrollmentPhaseUpdateRequest {
        phase: number;
        tasks: EnrollmentPhaseTaskUpdateRequest[];
    }

    export interface EnrollmentPhaseTaskUpdateRequest {
        id: number | string;
        completionDate: string;
        sortOrder?: number;
        status: 'COMPLETE' | 'INCOMPLETE' | 'IGNORED';
    }
}
