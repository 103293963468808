namespace aq.reportCenter {
    export interface ReportView {
        reportId: number;
        sampleImg: string;
        title: string;
        description: string;
        subscribe: boolean;
        recommended: boolean;
    }
    export class ReportSignUpDialogCtrl {
        reports: ReportView[];
        subscribeRecommended: boolean = true;
        constructor(
            private $mdDialog: ng.material.IDialogService,
            private baseReports: Report[],
            private recommendedReports: Report[],
            private Auth: aq.services.Auth,
        ) {
            this.reports = [{
                reportId: _.find(this.baseReports, { name: 'summaryDaily' }).id,
                sampleImg: '/img/report-samples/daily-status.png',
                title: 'Daily Status',
                description: 'dailyStatusDescription',
                subscribe: false,
                recommended: !!_.find(this.recommendedReports, { name: 'summaryDaily' })
            }, {
                reportId: _.find(this.baseReports, { name: 'summaryMonthly' }).id,
                sampleImg: '/img/report-samples/monthly-status.png',
                title: 'Monthly Status',
                description: 'monthlyStatusDescription',
                subscribe: false,
                recommended: !!_.find(this.recommendedReports, { name: 'summaryMonthly' })
            }, {
                reportId: _.find(this.baseReports, { name: 'customDateRange' }).id,
                sampleImg: '/img/report-samples/custom-date-range.png',
                title: 'Trending Usage Report',
                description: 'customDateRangeDescription',
                subscribe: false,
                recommended: !!_.find(this.recommendedReports, { name: 'customDateRange' })
            },
            {
                reportId: _.find(this.baseReports, { name: 'monthlyBuildingEngineer' }).id,
                sampleImg: '/img/report-samples/building-engineering.png',
                title: 'Building Engineering',
                description: 'buildingEngineeringDescription',
                subscribe: false,
                recommended: !!_.find(this.recommendedReports, { name: 'monthlyBuildingEngineer' })
            }, {
                reportId: _.find(this.baseReports, { name: 'portfolio' }).id,
                sampleImg: '/img/report-samples/portfolio.png',
                title: 'Portfolio',
                description: 'portfolioDescription',
                subscribe: false,
                recommended: !!_.find(this.recommendedReports, { name: 'portfolio' })
            }];

            if (Auth.hasFunctionality('Operational Summary')) {
                this.reports.push({
                    reportId: _.find(this.baseReports, { name: 'portfolio-alert' }).id,
                    sampleImg: '/img/report-samples/portfolio.png',
                    title: 'Operational Summary',
                    description: 'operationalSummaryDescription',
                    subscribe: false,
                    recommended: !!_.find(this.recommendedReports, { name: 'portfolio-alert' })
                });
            }

            if (Auth.hasFunctionality('Aquicore 3.0 Projects')) {
                this.reports.push({
                    reportId: _.find(this.baseReports, { name: 'projectSummary' }).id,
                    sampleImg: '/img/report-samples/portfolio.png',
                    title: 'Projects Summary',
                    description: 'projectSummaryDescription',
                    subscribe: false,
                    recommended: !!_.find(this.recommendedReports, { name: 'projectSummary' })
                });
            }

            this.toggleRecommended(true);
        }

        close() {
            this.$mdDialog.cancel();
        }

        save() {
            const subscribedReports = _.filter(this.reports, (report: ReportView) => {
                if (this.subscribeRecommended) {
                    return report.subscribe && !report.recommended;
                } else {
                    return report.subscribe;
                }
            });
            this.$mdDialog.hide({
                subscribeRecommended: this.subscribeRecommended,
                subscribedReports
            });
        }

        toggleRecommended(enable: boolean) {
            _.each(this.reports, (report: ReportView) => {
                if (report.recommended) {
                    report.subscribe = enable;
                }
            });
        }
    }
    angular.module('reportCenter').controller('ReportSignUpDialogCtrl', ReportSignUpDialogCtrl);
}
