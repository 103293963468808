/**
 * resolve account and options service
 */
angular.module('dashboard').factory('resolveAccountAndOptions',
    function (OptionsService, Restangular, $q) {
        return {
            getAccount: function(accountId) {
                var account = Restangular.one('accounts', accountId);
                return account.get().then(function (result) {
                    _.extend(account, result[0]);
                    OptionsService.init(accountId, account.measurementSystem, account.currencyUnit);
                    return OptionsService.fetchAll().then(function () {
                        return account;
                    });
                })
            }
        }
    });
