namespace aq.tenantbilling {
    export class EditPeriodInfoStepCtrl {
        private monthlyBilling;
        /* @ngAnnotate */
        constructor(
            private $state
        ) {}

        public getAccountId() {
            return this.$state.params.accountId;
        }

        public getBuildingId() {
            return this.$state.params.buildingId;
        }
    }

    angular
        .module('tenantBilling')
        .component('editPeriodInfoStep', {
            templateUrl: 'app/tenantBilling/invoicing/run/editInvoicingPeriod/components/editPeriodInfoStep/editPeriodInfoStep.html',
            controller: EditPeriodInfoStepCtrl,
            controllerAs: 'vm',
            bindings: {
                monthlyBilling: '<'
            }
        });
}
