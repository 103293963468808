namespace aq.reports {
    export class PortfolioInsightService {
        /* ngInject */
        constructor() {
        }

        public getOrderedMonths(building, currentMonth): { month: number, isCounted: boolean }[] {
            const result: { month: number, isCounted: boolean }[] = [];
            const startMonth = building.fiscalStartMonth ? building.fiscalStartMonth : 1;
            result.push({
                month: startMonth,
                isCounted: true
            });
            let isCounted = true;
            for (let i = 1; i < 12; i++) {
                const month = (startMonth - 1 + i) % 12 + 1;
                if (month == currentMonth) {
                    isCounted = false;
                }
                result.push({
                    month,
                    isCounted
                });
            }
            return result;
        }

        public getMonthlyTargetsAggregate(buildingMeasureTarget: MeasureTarget[]): MonthValueMap {
            const monthValueMap: MonthValueMap = {};
            _.each(buildingMeasureTarget, (measureTarget: MeasureTarget) => {
                if (!measureTarget) {
                    return;
                }
                _.each(measureTarget.targetItems, (targetItem: TargetItem) => {
                    const month = targetItem.startDate.substring(5, 7);
                    if (!monthValueMap[month]) {
                        monthValueMap[month] = targetItem.value;
                    } else {
                        monthValueMap[month] += targetItem.value;
                    }
                });
            });
            return monthValueMap;
        }
    }

    angular
        .module('aq.reports')
        .service('PortfolioInsightService', PortfolioInsightService);
}
