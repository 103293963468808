namespace aq.propertySettings {
    export class TargetDetailsCtrl {
        private propertySettingsAccess: common.models.AppAccessObject;
        /* @ngInject */
        constructor(
            private $scope: TargetDetailsCtrlScope,
            private target: Target,
            private Messages,
            private measures,
            private OptionsService,
            private DataStore: aq.common.DataStore,
            private Restangular,
            private RestangularV3FullResponse: restangular.IService,
            private building,
            private occupancies,
            private authAccess: common.models.AuthAppAccess,
            private FileHandlerService: aq.services.FileHandlerService,
            private $mdDialog: ng.material.IDialogService,
            private $state,
            private Segment: aq.services.SegmentService
        ) {
            this.$scope.isDownloadingExcel = false;
            this.propertySettingsAccess = this.authAccess['Property Settings'];
            this.$scope.target = target;
            this.$scope.target.targetItems.forEach((targetItem) => {
                targetItem.target = target.id;
            });
            this.$scope.unit = this.getUnit();
            this.$scope.year = new Date(this.target.targetItems[this.target.targetItems.length - 1].endDate).getFullYear();
        }

        save() {
            let objectType = this.$scope.target.type == 'BUDGET' ? 'budgets' : 'targets';
            if(objectType === 'budgets'){
                this.Segment.trackEvent('Budgets:Update Budget');
            }
            this.DataStore.update(this.$scope.target, { inflate: 'targetItems,measure' }).then((target) => {
                this.Messages.info(objectType === 'budgets' ? 'Budget Saved' : 'Target Saved');
                const newTarget = this.Restangular.restangularizeElement(this.building, target, objectType);
                newTarget.year = moment(newTarget.targetItems[newTarget.targetItems.length - 1].endDate).year();
            }, (error) => {
                this.Messages.error(objectType === 'budgets' ? 'Error saving Budget' : 'Error saving Target');

            });
        }

        downloadBudgetPacketExcel() {
            this.Segment.trackEvent('Budgets:Export Budget Packet');
            this.$scope.isDownloadingExcel = true;
            return this.RestangularV3FullResponse.one('budget').withHttpConfig({ responseType: 'blob', transformRequest: angular.identity })
                .customGET('download-budget-packet', {buildingId: this.building.id, year: this.$scope.year})
                .then((response) => {
                    this.FileHandlerService.handleFileDownload(response, 'application/vnd.ms-excel');
                    this.$scope.isDownloadingExcel = false;
                })
                .catch(() => {
                    this.Messages.error('Error while generating report');
                    this.$scope.isDownloadingExcel = false;
                });
        }

        getOccupancy(date) {
            const currentDate = moment(date);
            return _.find(this.occupancies, (occupancy) => {
                return moment(occupancy.startDate).isSameOrBefore(currentDate);
            });
        }

        changeType() {
            this.$scope.unit = this.getUnit();
        }

        getUnit() {
            switch (this.$scope.target.type) {
                case 'PEAK_DEMAND':
                    return 'kW';
                case 'CONSUMPTION':
                    return 'kWh';
                case 'BASELOAD':
                    return 'kWh';
                case 'BUDGET':
                    return '$';
                case 'ENERGY_STAR':
                    return null;
                default:
                    return null;
            }
        }

        deleteTarget(ev){
            // search through item for the specified label prop, currently limited to 2 properties deep.
            if(this.$scope.target.type === 'BUDGET'){
                this.Segment.trackEvent('Budgets:Delete Budget');
            }
            this.$mdDialog.show(
                this.$mdDialog.confirm()
                    .title(`Are you sure you want to delete ${this.target.name}?`)
                    .ariaLabel('Delete')
                    .targetEvent(ev)
                    .ok('Delete')
                    .cancel('Cancel'))
                .then(() => {
                    this.DataStore.delete(this.target).then(() => {
                        this.Messages.info(`Successfully deleted ${this.target.name} Target`);
                        this.$state.go('aq.utilityBudgets.budgets');
                    });
                });
        }

        getTotal() {
            let values = _.map(this.$scope.target.targetItems, (item) => {
                return item.value;
            });
            switch (this.$scope.target.type) {
                case 'PEAK_DEMAND':
                case 'BASELOAD':
                case 'ENERGY_STAR':
                    return _.mean(values);
                case 'CONSUMPTION':
                case 'BUDGET':
                    return _.sum(values);
                default:
                    return null;
            }
        }
    }
    export interface TargetDetailsCtrlScope extends TargetListCtrlScope {
        target: Target;
        unit: string;
        isDownloadingExcel: boolean;
    }
    angular.module('properties').controller('TargetDetailsCtrl', aq.propertySettings.TargetDetailsCtrl);
}
