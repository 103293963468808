/**
 * Generic service for basic common methods
 */
angular.module('aq-main').service('$aq', function (Messages, Errors) {

    var service: any = {}

    /**
     * Array of items and item that will be deleted from array
     * Item SHOULD be restangularized
     * @param items
     * @param item
     */
    service.delete = function (items, item, onSuccess) {
        item.remove().then(function () {
            _.withoutInline(items, _.findById(items, item.id));
            if (onSuccess) onSuccess();
            Messages.info('Deleted');
        }, function (response) {
            if (response.data.message) {
                Messages.error(response.data.message)
            } else {
                Messages.error("Error deleting item.")
            }
        });
    }

    /** TODO - apply this method to TB
     * Array of time strings to use in dropdown as timepicker
     * @returns {Array}
     */
    service.timeOptions = function () {
        return _.times(48, function (i) {
            return moment().startOf('day').add(i * 30, 'minutes').format('HH:mm A');
        })
    }

    return service;

});
