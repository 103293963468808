angular
    .module('tenantBilling', ['ngAnimate', 'aq.forms', 'aq.services', 'aq.auth', 'aq.ui.loading'])
    .config((RestangularProvider: restangular.IProvider, $stateProvider: ng.ui.IStateProvider) => {
        $stateProvider
            .state('aq.tenantbilling', {
                abstract: true,
                url: '/accounts/:accountId/tenant-billing',
                templateUrl: 'app/tenantBilling/tenantBilling.html',
                controller: 'TenantBillingCtrl as tenantBillingCtrl',
                data: { appName: 'Tenant Billing' },
                resolve: {
                    accountElement(
                        $stateParams: ng.ui.IStateParamsService,
                        Restangular: restangular.IService,
                        waitForAuthToken
                    ): restangular.IElement {
                        return Restangular.one('accounts', $stateParams.accountId);
                    },
                    account(accountElement, DataStore: aq.common.DataStore, waitForAuthToken): ng.IPromise<aq.common.models.Account> {
                        return DataStore.get(accountElement, { single: true, inflate: 'buildingGroups,approvalActions' }, false);
                    },
                    buildings(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        waitForAuthToken
                    ): ng.IPromise<aq.common.models.Building[]> {
                        return DataStore.getList(RestangularV3.one(''), 'buildings', { orderByRecent: true });
                    }
                }
            })
            .state('aq.tenantbilling.building', {
                url: '/building/:buildingId',
                abstract: true,
                resolve: {
                    building(
                        buildings,
                        $stateParams: ng.ui.IStateParamsService,
                        $state: ng.ui.IStateService,
                        Restangular: restangular.IService,
                        waitForAuthToken
                    ): aq.common.models.Building {
                        let { accountId, buildingId } = $stateParams;
                        if (!buildingId) {
                            buildingId = buildings[0].id;
                            $state.go('aq.tenantbilling.building.invoicing', { accountId, buildingId });
                        } else {
                            Restangular.one('accounts', accountId).one('buildings', buildingId).customPOST({}, 'record');
                        }
                        return _.findById(buildings, buildingId);
                    }
                }
            })
            .state('aq.tenantbilling.building.invoicing', {
                url: '/invoicing',
                reloadOnSearch: false,
                views: {
                    'billingperiods@aq.tenantbilling': {
                        templateUrl: 'app/tenantBilling/invoicing/invoicing.html',
                        controller: 'TenantBillingInvoicingCtrl as vm'
                    }
                },
                params: {
                    deletedBillingId: null,
                    updatedBillingId: null,
                    timeStamp: null
                },
                resolve: {
                    meteredTenantServices(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        building: aq.common.models.Building,
                        waitForAuthToken
                    ): restangular.IPromise<any> {
                        return RestangularV3.all('tenant-services').get('active-for-billing', { buildingId: building.id });
                    },
                    updatedBillingId($stateParams) {
                        return $stateParams.updatedBillingId;
                    },
                    deletedBillingId($stateParams) {
                        return $stateParams.deletedBillingId;
                    }
                },
                onEnter(Segment) {
                    Segment.trackPage('Tenant Billing:Invoicing');
                }
            })
            .state('aq.tenantbilling.building.invoicing.run', {
                url: '/run?monthlyBillingId&step',
                reloadOnSearch: false,
                templateUrl: 'app/tenantBilling/invoicing/run/run.html',
                controller: 'RunInvoicingCtrl as vm',
                resolve: {
                    options(OptionsService, account: aq.common.models.Account) {
                        return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
                    },
                    lastBilling(
                        RestangularV3: restangular.IService,
                        building: aq.common.models.Building,
                        waitForAuthToken
                    ) {
                        return RestangularV3.all('billings').get('latest-by-tenant-service', { buildingId: building.id });
                    },
                    monthlyBilling(
                        $state: ng.ui.IStateService,
                        $stateParams: ng.ui.IStateParamsService,
                        MonthlyBillingService: aq.tenantbilling.MonthlyBillingService,
                        building: aq.common.models.Building
                    ) {
                        if ($stateParams.monthlyBillingId) {
                            return MonthlyBillingService.get($stateParams.monthlyBillingId)
                                .then((monthlyBilling) => {
                                    if (monthlyBilling.building !== building.id) {
                                        $state.go('aq.tenantbilling.building.invoicing', { buildingId: building.id });
                                        return;
                                    }
                                    return monthlyBilling;
                                });
                        }
                        return null;
                    },
                    tenantInvoices(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        monthlyBilling: aq.tenantbilling.MonthlyTenantBilling,
                        waitForAuthToken
                    ) {
                        if (monthlyBilling) {
                            return DataStore.getList(RestangularV3.one(''), 'tenant-invoices', { monthlyBillingId: monthlyBilling.id });
                        }
                        return [];
                    },
                    periodTenantServices(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        monthlyBilling: aq.tenantbilling.MonthlyTenantBilling,
                        waitForAuthToken
                    ) {
                        if (monthlyBilling) {
                            return DataStore.getList(RestangularV3.one(''), 'period-tenant-services', { monthlyBillingId: monthlyBilling.id });
                        }
                        return [];
                    },
                    tenantChargeValues(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        monthlyBilling: aq.tenantbilling.MonthlyTenantBilling,
                        waitForAuthToken
                    ) {
                        if (monthlyBilling) {
                            return DataStore.getList(RestangularV3.one(''), 'tenant-charge-values', { monthlyBillingId: monthlyBilling.id });
                        }
                        return [];
                    },
                    step($stateParams: ng.ui.IStateParamsService) {
                        if ($stateParams.step) {
                            return $stateParams.step;
                        }
                        return null;
                    },
                    integrations(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        account: aq.common.models.Account,
                        waitForAuthToken
                    ) {
                        return DataStore.getList(RestangularV3.one(''), 'integrations', { accountId: account.id });
                    }
                },
                onEnter(Segment) {
                    Segment.trackPage('Tenant Billing:Invoicing:Run');
                }
            })
            .state('aq.tenantbilling.building.tenants', {
                url: '/tenants',
                views: {
                    'billingperiods@aq.tenantbilling': {
                        templateUrl: 'app/tenantBilling/tenants/tenantList.html',
                        controller: 'TenantBillingTenantListCtrl as vm'
                    }
                },
                data: {
                    breadcrumb: 'Tenants'
                },
                params: {
                    deletedTenantId: null,
                    updatedTenantId: null,
                    timeStamp: null
                },
                resolve: {
                    accountId($stateParams: ng.ui.IStateParamsService) {
                        return $stateParams.accountId;
                    },
                    buildingId($stateParams: ng.ui.IStateParamsService) {
                        return $stateParams.buildingId;
                    },
                    building(buildings, $stateParams: ng.ui.IStateParamsService): aq.common.models.Building {
                        let { buildingId } = $stateParams;
                        if (!buildingId) {
                            buildingId = buildings[0].id;
                        }
                        return _.findById(buildings, buildingId);
                    },
                    allEnums(RestangularV3: restangular.IService, waitForAuthToken) {
                        return RestangularV3.one('options', 'all-enums').get();
                    },
                    collectors(RestangularV3: restangular.IService, buildingId: string, waitForAuthToken) {
                        return RestangularV3.all('collectors').getList({ buildingId })
                            .then((collectors: aq.common.models.Collector[]) => {
                                return _.filter(collectors, (collector: aq.common.models.Collector) => {
                                    return collector.collectorClass !== 'WORLD_WEATHER_ONLINE';
                                });
                            });
                    },
                    tenants(RestangularV3: restangular.IService, buildingId: string, waitForAuthToken) {
                        return RestangularV3.all('tenants').getList({ buildingId });
                    },
                    areaMeasurement(OptionsService, account: aq.common.models.Account) {
                        return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit)
                            .then(() => {
                                return OptionsService.areaMeasurementUnit();
                            });
                    },
                    deletedTenantId: ($stateParams) => { return $stateParams.deletedTenantId; },
                    updatedTenantId: ($stateParams) => { return $stateParams.updatedTenantId; }
                },
                onEnter(Segment) {
                    Segment.trackPage('Tenant Billing:Tenants');
                }
            })
            .state('aq.tenantbilling.building.tenants.details', {
                url: '/:tenantId',
                templateUrl: 'app/tenantBilling/tenants/tenantDetails.html',
                controller: 'TenantDetailsCtrl as vm',
                resolve: {
                    tenantId($stateParams: ng.ui.IStateParamsService) {
                        return $stateParams.tenantId;
                    },
                    tenant(
                        RestangularV3: restangular.IService,
                        $state: ng.ui.IStateService,
                        $stateParams: ng.ui.IStateParamsService,
                        buildingId: string,
                        waitForAuthToken
                    ) {
                        return RestangularV3.one('tenants', $stateParams.tenantId).get()
                            .then((tenant) => {
                                if (tenant.building != buildingId) {
                                    $state.go('aq.tenantbilling.building.tenants', { buildingId });
                                    return;
                                }
                                return tenant;
                            });
                    },
                    deviceLinks(RestangularV3, tenantId, DataStore: aq.common.DataStore, waitForAuthToken) {
                        return DataStore.getList(RestangularV3.one(''), 'device-tenant-links', { tenantId });
                    },
                    tenantServices(RestangularV3: restangular.IService, buildingId: string, waitForAuthToken) {
                        return RestangularV3.all('tenant-services').getList({ buildingId });
                    }
                },
                onEnter(Segment) {
                    Segment.trackPage('Tenant Billing:Tenants:Details');
                }
            })
            .state('aq.tenantbilling.building.setup', {
                url: '/setup',
                views: {
                    'billingperiods@aq.tenantbilling': {
                        templateUrl: 'app/tenantBilling/setup/setup.html',
                        controller: 'TenantBillingSetupCtrl as vm'
                    }
                },
                data: {
                    breadcrumb: 'Setup'
                },
                resolve: {
                    options(OptionsService, account: aq.common.models.Account) {
                        return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
                    },
                    billingReminderTypes(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        waitForAuthToken
                    ) {
                        return RestangularV3.all('options').get('billing-reminder-types', {});
                    },
                    users(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        account: aq.common.models.Account,
                        waitForAuthToken
                    ) {
                        return DataStore.getList(RestangularV3.one(''), 'users', { accountId: account.id });
                    },
                    utilityServices(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) {
                        return DataStore.getList(RestangularV3.one(''), 'utility-services', { buildingId: $stateParams.buildingId });
                    },
                    tenantServiceMethods(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        waitForAuthToken
                    ) {
                        return RestangularV3.all('options').get('tenant-service-methods', {});
                    },
                    billingSetup(
                        RestangularV3: restangular.IService,
                        $stateParams: ng.ui.IStateParamsService,
                        DataStore: aq.common.DataStore,
                        waitForAuthToken
                    ) {
                        return DataStore.get(RestangularV3.one('billing-setups'), { buildingId: $stateParams.buildingId });
                    },
                    approvalActionSetups(
                        RestangularV3: restangular.IService,
                        $stateParams: ng.ui.IStateParamsService,
                        DataStore: aq.common.DataStore,
                        waitForAuthToken
                    ) {
                        return DataStore.getList(RestangularV3.one(''), 'approval-action-setups', { buildingId: $stateParams.buildingId });
                    },
                    reportSetups(
                        RestangularV3: restangular.IService,
                        $stateParams: ng.ui.IStateParamsService,
                        DataStore: aq.common.DataStore,
                        waitForAuthToken
                    ) {
                        return DataStore.getList(RestangularV3.one(''), 'billing-report-setups', { buildingId: $stateParams.buildingId });
                    },
                    units(OptionsService, account: aq.common.models.Account) {
                        return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
                    },
                    tenantServices(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) {
                        return DataStore.getList(RestangularV3.one(''), 'tenant-services', { buildingId: $stateParams.buildingId });
                    },
                    tenantGroups(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) {
                        return DataStore.getList(RestangularV3.one(''), 'tenant-groups', { buildingId: $stateParams.buildingId });
                    },
                    tenants(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) {
                        return DataStore.getList(RestangularV3.one(''), 'tenants', { buildingId: $stateParams.buildingId });
                    },
                },
                onEnter(Segment) {
                    Segment.trackPage('Tenant Billing:Setup');
                }
            });
    })
    .run(($rootScope, loading, $state: ng.ui.IStateService) => {
        const tenantStatePrefix = 'aq.tenantbilling';
        $rootScope.$on('$stateChangeStart',
            (event, toState) => {
                const isTenantBillingNavigation = toState && toState.name && toState.name.substring(0, tenantStatePrefix.length) === tenantStatePrefix;
                if ($state.includes(tenantStatePrefix) && isTenantBillingNavigation) {
                    loading.start();
                }
            });
        $rootScope.$on('$stateChangeSuccess',
            () => {
                if ($state.includes(tenantStatePrefix)) {
                    loading.stop();
                }
            });
        $rootScope.$on('$stateChangeError',
            () => {
                if ($state.includes(tenantStatePrefix)) {
                    loading.stop();
                }
            });
    });
