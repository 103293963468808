namespace aq.tenantbilling {
    export class EditPeriodTenantsStepCtrl {
        private monthlyBilling;
        private tenantServices;
        private tenantCharges;
        private tenants;
        private enabledSettings: aq.tenantbilling.EnabledSettings;
        tenantLabel: string;
        constructor(
            private Auth: aq.services.Auth
        ) {
            this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'unit' : 'tenant';
        }

        public getService(serviceId: number): any {
            return _.find(this.tenantServices, { id: serviceId });
        }

        public getTenant(tenantId: number): any {
            return _.find(this.tenants, { id: tenantId });
        }

        public toggleTenant(tenant: any): void {
            const enabled = this.enabledSettings.tenants[tenant.id];
            _.each(this.getCharges(tenant), (charge) => {
                this.enabledSettings.charges[charge.id] = enabled;
                if (enabled || this.allChargesForServiceAreDisabled(charge.service)) {
                    this.enabledSettings.services[charge.service] = enabled;
                }
            });
        }

        public toggleCharge(charge: any): void {
            const enabled = this.enabledSettings.charges[charge.id];

            // Enable tenant if we're enabling one of its charges
            // or disable if all of its charges are disabled
            if (enabled || this.allTenantChargesAreDisabled(charge)) {
                this.enabledSettings.tenants[charge.tenant] = enabled;
            }

            // Repeat for a service
            if (enabled || this.allChargesForServiceAreDisabled(charge.service)) {
                this.enabledSettings.services[charge.service] = enabled;
            }
        }

        public getCharges(tenant: aq.tenantbilling.Tenant): any[] {
            return _.filter(this.tenantCharges, { tenant: tenant.id });
        }

        public getSortedCharges(tenant: aq.tenantbilling.Tenant): any[] {
            const charges = this.getCharges(tenant);
            return this.sortCharges(charges);
        }

        public sortCharges(charges: any[]): any[] {
            return _.orderBy(charges, ['type', (charge) => {
                const service = this.getService(charge.service);
                return service.serviceId;
            }], ['desc', 'asc']);
        }

        public allChargesForServiceAreDisabled(serviceId: number) {
            return _.chain(this.tenants)
                .flatMap((tenant) => {
                    return this.getCharges(tenant);
                })
                .filter((charge) => {
                    return charge.service === serviceId;
                })
                .every((charge) => {
                    return !this.enabledSettings.charges[charge.id];
                })
                .value();
        }

        public allTenantChargesAreDisabled(charge: any): boolean {
            const tenant = this.getTenant(charge.tenant);
            return _.every(this.getCharges(tenant), (c) => {
                return !this.enabledSettings.charges[c.id];
            });
        }

    }

    angular
        .module('tenantBilling')
        .component('editPeriodTenantsStep', {
            templateUrl: 'app/tenantBilling/invoicing/run/editInvoicingPeriod/components/editPeriodTenantsStep/editPeriodTenantsStep.html',
            controller: EditPeriodTenantsStepCtrl,
            controllerAs: 'vm',
            bindings: {
                monthlyBilling: '<',
                tenantServices: '<',
                tenantCharges: '<',
                tenants: '<',
                enabledSettings: '='
            }
        });
}
