'use strict';

angular
.module('accounts')
.controller('CompetitionsChooserCtrl',
    function(Messages, $scope, $state, Pager, loading) {

        $scope.scroller = Pager.scroller(1, function (page) {
            loading.start();
            return $scope.account.getList('competitions', {page: page, inflate: 'buildings,buildings.buildingPurpose'});
        }, function (competitions) {
            $scope.competitions = ($scope.competitions || []).concat(competitions);

            if ($state.params.competitionId) {
                $scope.competition = _.findById($scope.competitions, $state.params.competitionId);
            } else {
                $scope.competition = _.first($scope.competitions);
            }

            if ($state.is('aq.accounts.competitions') && $scope.competition && $scope.competition.id) {
                $scope.loadCompetition();
            }

            loading.stop();
        });

        $scope.status = 'PLANNED';

        $scope.loadCompetition = function () {
            $state.go('aq.accounts.competitions.competition', { competitionId: $scope.competition.id});
        }

        $scope.selectCompetition = function (competition) {
            $scope.competition = competition;
        }

        $scope.addNewCompetition = function () {
            $scope.competition = emptyCompetition();
            $scope.minCompetitionStart = null;
            $scope.minCompetitionEnd = null;
            $state.go('aq.accounts.competitions.new');
        }

        $scope.discardCompetition = function () {
            $scope.competition = null;
            if (!_.isEmpty($scope.competitions)) {
                $scope.selectCompetition(_.first($scope.competitions));
                $scope.loadCompetition();
            } else {
                $state.go('aq.accounts.competitions');
            }
        }

        $scope.setCompetitionStatus = function (competition) {
            if (!competition) return;
            $scope.status = getCompetitionStatus(competition);
        }

        function emptyCompetition () {
            var competition = {
                id: null,
                name: '',
                startDate: null,
                endDate: null,
                baselineStartDate: null,
                baselineEndDate: null,
                account: null,
                hashtag: '',
                buildings: []
            }
            return competition;
        }

        function getCompetitionStatus (competition) {
            var isStartDateNow = moment(competition.startDate).isSame(moment(), 'day');
            var isEndDateNow = moment(competition.endDate).isSame(moment(), 'day');
            var isNowBeforeStartDate = moment().isBefore(competition.startDate, 'day');
            var isNowBeforeEndDate = moment().isBefore(competition.endDate, 'day');
            var isStartDateBeforeNow = moment(competition.startDate).isBefore(moment(), 'day');
            var isEndDateBeforeNow = moment(competition.endDate).isBefore(moment(), 'day');

            if (isNowBeforeStartDate && !isEndDateNow) {
                return 'PLANNED';
            }
            if ((isStartDateNow || isStartDateBeforeNow) && isNowBeforeEndDate) {
                return 'RUNNING';
            }
            if (isEndDateBeforeNow || isEndDateNow) {
                return 'COMPLETED';
            }

            return 'PLANNED';
        }

        $scope.isAddDisabled = function () {
            if(!$scope.competitions) return;
            var completed = _.filter($scope.competitions, function (competition) {
                var status = getCompetitionStatus(competition);
                return status === 'COMPLETED';
            });
            var areAllCompleted = $scope.competitions.length == completed.length;
            return ($scope.competitions.length >= 1 && !areAllCompleted);
        }

        $scope.setCompetitionValue = function(competition) {
            $scope.competition = competition;
            if ($scope.competition) $scope.loadCompetition();
        };

    }
);