'use strict';

angular
.module('aq.settings.calendars')
.service('EventPriority', ['CalendarData', '$filter', function(CalendarData, $filter) {

    function isAllDay(rule) {
        return !!(rule.timeStart == null && rule.timeEnd == null)
    }

    function isAllWeek(rule) {
        return !!(rule.week.length == 7);
    }

    function isAllYear(rule) {
        return !!(rule.dateStart == null && rule.dateEnd == null);
    }

    this.getEventLevel = function (rule) {
        const allDay = isAllDay(rule) ? 1 : 0;
        const allWeek = isAllWeek(rule) ? 1 : 0;
        const allYear = isAllYear(rule) ? 1 : 0;
        const activeState = rule.activeState ? 1 : 0;
        const eventLevels = allYear.toString() + allWeek.toString() + allDay.toString() + activeState.toString();
        // What is this? WHAT DOES IT DO!?
        switch (eventLevels) {
        case '1110':
            return 1000;
        case '1111':
            return 2000;
        case '1100':
            return 3000;
        case '1101':
            return 4000;
        case '1010':
            return 5000;
        case '1011':
            return 6000;
        case '1000':
            return 7000;
        case '1001':
            return 8000;
        case '0010':
            return 9000;
        case '0011':
            return 10000;
        case '0000':
            return 11000;
        case '0001':
            return 12000;
        }
    };

}]);