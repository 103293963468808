namespace aq.admin.meters.deviceClasses {

    export class AdminDeviceClassesCtrl {
        /* @ngInject */
        constructor(
            private $scope: AdminDeviceClassesCtrlScope,
            private $filter: ng.IFilterService,
            private RestangularV3: restangular.IService,
            private $mdDialog: ng.material.IDialogService,
            private Messages: any,
            private Errors: any,
            private deviceClasses: DeviceClass[],
            private deviceClassTypes: DeviceClassType[],
            private collectorClasses: CollectorClass[],
            private identifierNames: IdentifierName[],
            private vendors: string[],
            private networkConnectionTypes: any[],
            private measures: aq.common.models.Measure[],
            private sensorTypes: any[],
            private protocols: any[]
        ) {
            $scope.deviceClasses = deviceClasses;
            $scope.searchItems = deviceClasses;

            $scope.order = 'series';
            $scope.paging = {
                page: 1,
                limit: 25
            };

            $scope.options = {
                vendors,
                sensorTypes,
                networkConnectionTypes,
                identifierNames,
                measures,
                deviceClassTypes,
                collectorClasses: _.map(collectorClasses, function (x) { return x.name; })
            };
        }

        search() {
            this.$scope.searchItems = this.$filter('filter')(this.$scope.deviceClasses, this.$scope.searchText);
        }

        public deleteDeviceClass(deviceClass) {
            deviceClass.remove().then(() => {
                _.remove(this.$scope.deviceClasses, (dc) => { return dc.id == deviceClass.id; });
                this.$mdDialog.hide();
                this.Messages.success('Device Class deleted successfully');
            })
            .catch((result) => {
                this.$mdDialog.cancel();
                this.Errors.forCRUD('DELETE')(result);
            });
        }

        selectItem(deviceClass) {
            this.$mdDialog.show({
                controller: 'AdminDeviceClassDialogCtrl as deviceClassDialogCtrl',
                templateUrl: 'app/admin/meters/deviceClasses/EditDialog.html',
                locals: {
                    deviceClassesCtrl: this,
                    deviceClass: this.RestangularV3.copy(deviceClass),
                    deviceClasses: this.$scope.deviceClasses,
                    options: this.$scope.options,
                    protocols: this.protocols
                }
            }).then((updatedDeviceClass) => {
                this.$scope.deviceClasses[_.indexOf(this.$scope.deviceClasses, deviceClass)] = updatedDeviceClass;
                this.$scope.searchItems[_.indexOf(this.$scope.searchItems, deviceClass)] = updatedDeviceClass;
            });
        }

        createDeviceClass() {
            this.$mdDialog.show({
                controller: 'AdminDeviceClassDialogCtrl as deviceClassDialogCtrl',
                templateUrl: 'app/admin/meters/deviceClasses/CreateDialog.html',
                locals: {
                    deviceClassesCtrl: this,
                    deviceClasses: this.deviceClasses,
                    deviceClass: this.RestangularV3.copy({ series: '', model: '' }),
                    options: this.$scope.options,
                    protocols: this.protocols
                }
            }).then((unRestangularizedDeviceClass) => {
                this.RestangularV3.one('devices').one('device-classes', unRestangularizedDeviceClass.id).get()
                    .then((deviceClassResponse) => {
                        this.$scope.deviceClasses.push(deviceClassResponse[0]);
                    });
            });
        }
    }

    export interface AdminDeviceClassesCtrlScope extends ng.IScope {
        deviceClasses: DeviceClass[];
        options: DeviceClassOptions;
        searchItems: DeviceClass[];
        searchText: string;
        order: string;
        paging: Object;
        protocols: any[];
    }

    interface DeviceClassOptions {
        vendors: string[];
        sensorTypes: any[];
        networkConnectionTypes: any[];
        collectorClasses: string[];
        deviceClassTypes: DeviceClassType[];
        identifierNames: IdentifierName[];
        measures: aq.common.models.Measure[];
    }

    export interface CollectorClass {
        identifierName: string;
        multiplierLabel: string;
        displayName: string;
        name: string;
        vendor: string;
        gateway: boolean;
        downwardCommunications: string[];
        upwardCommunications: string[];
    }

    export interface DeviceClassType {
        name: string;
        category: string;
    }

    export interface DeviceClass extends restangular.IElement {
        id: string;
        series: string;
        model: string;
        multiplierLabel: string;
        defaultTimeout: string;
        legacyCollectorClass: string;
        active: boolean;
        type: string;
        identifierName: IdentifierName;
        vendor: string[];
        deviceClassConnections: any[];
        measure: aq.common.models.Measure;
        fields: object;
    }

    export interface IdentifierName {
        id: number;
        name: string;
    }

    export class AdminDeviceClassDialogCtrl {
        /* @ngInject */
        constructor(
            private $scope: AdminDeviceClassDialogCtrlScope,
            private $filter: any,
            private RestangularV3: restangular.IService,
            private $mdDialog: ng.material.IDialogService,
            private Messages: any,
            private Errors: any,
            private deviceClassesCtrl: AdminDeviceClassesCtrl,
            private deviceClass: DeviceClass,
            private options: DeviceClassOptions,
            private protocols: any[]
        ) {
            $scope.selectedDeviceClass = deviceClass;
            $scope.options = options;
            $scope.protocols = protocols;
            $scope.sensorGroups = [];
            $scope.networkConnectionGroups = [];

            $scope.jsonEditorOptions = this.getJsonEditorOptions();

        }

        delete() {
            this.deviceClassesCtrl.deleteDeviceClass(this.deviceClass);
        };

        addSensor() {
            this.$scope.sensorGroups.push({ quantity: 1 });
        }

        addNetworkConnection() {
            this.$scope.networkConnectionGroups.push({ quantity: 1 });
        }

        cancel() {
            this.$mdDialog.cancel();
        }

        getJsonEditorOptions(): object {
            const schema = this.getFieldTemplateSchema();
            const templates = this.getFieldTemplate();
            const modes = ['form', 'tree', 'code'];
            return { schema, templates, modes };
        }

        getFieldTemplateSchema(): object {
            return {
                'title': 'DeviceFieldTemplateMap',
                'type': 'object',
                'description': 'json map based for extra information about a device (ex- CT size)\n',
                'additionalProperties': {
                    'type': 'object',
                    'additionalProperties': false,
                    'properties': {
                        'label': {
                            'type': 'string'
                        },
                        'name': {
                            'type': 'string'
                        },
                        'icon': {
                            'type': 'string'
                        },
                        'type': {
                            'type': 'string'
                        },
                        'hint': {
                            'type': 'string'
                        },
                        'pattern': {
                            'type': 'string'
                        },
                        'value': {
                            'type': 'string'
                        },
                        'unique': {
                            'type': 'boolean'
                        },
                        'hide': {
                            'type': 'boolean'
                        },
                        'required': {
                            'type': 'boolean'
                        },
                        'admin': {
                            'type': 'boolean'
                        }
                    }
                }
            };
        }

        getFieldTemplate(): object[] {
            return [
                {
                    text: 'Template Field',
                    title: 'Insert a Template Map',
                    field: 'fieldName',
                    value: {
                        'label': '',
                        'name': '',
                        'icon': '',
                        'type': '',
                        'hint': '',
                        'pattern': '',
                        'value': '',
                        'unique': false
                    }
                }
            ];
        }

        generateDeviceClassSensorObjects(): any[] {
            const sensors = [];
            for (const sensorGroup of this.$scope.sensorGroups) {
                const sensorNames = this.$filter('getConnectionPoints')(sensorGroup, true);
                for (const sensorName of sensorNames) {
                    sensors.push({
                        classType: 'DeviceClassSensor',
                        name: sensorName,
                        sensorType: sensorGroup.type
                    });
                }
            }
            return sensors;
        }

        generateDeviceClassNetworkConnectionObjects(): any[] {
            const networkConnections = [];
            for (const networkConnectionGroup of this.$scope.networkConnectionGroups) {
                const networkConnectionNames = this.$filter('getConnectionPoints')(networkConnectionGroup, true);
                for (const networkConnectionName of networkConnectionNames) {
                    networkConnections.push({
                        classType: 'DeviceClassNetworkConnection',
                        name: networkConnectionName,
                        networkConnectionType: networkConnectionGroup.type
                    });
                }
            }
            return networkConnections;
        }

        create() {
            // TODO: RestangularV3 interceptor is not looking deep
            delete this.deviceClass['originalElement'];
            const deviceClassPost = {
                deviceClass: this.RestangularV3.stripRestangular(this.deviceClass),
                deviceClassSensors: this.generateDeviceClassSensorObjects(),
                deviceClassNetworkConnections: this.generateDeviceClassNetworkConnectionObjects()
            };

            return this.RestangularV3.all('devices').all('device-classes').post(deviceClassPost)
                .then((deviceClassResponse) => {
                    return deviceClassResponse;
                })
                .then((deviceClass) => {
                    this.$mdDialog.hide(deviceClass[0]);
                    this.Messages.success('Device Class created successfully.');
                })
                .catch((response) => {
                    this.$mdDialog.cancel();
                    this.Errors.forCRUD('UPDATE')(response);
                });
        }

        update() {
            const deviceClassSensors = [];
            const deviceClassNetworkConnections = [];

            for (const connection of this.deviceClass.deviceClassConnections) {
                if (connection.classType == 'DeviceClassSensor') {
                    deviceClassSensors.push(connection);
                }
                if (connection.classType == 'DeviceClassNetworkConnection') {
                    deviceClassNetworkConnections.push(connection);
                }
            }

            // TODO: frown, interceptor only removes originalElement from main object, not embedded objects
            delete this.deviceClass['originalElement'];

            const deviceClassRequest = {
                deviceClass: this.RestangularV3.stripRestangular(this.deviceClass),
                deviceClassSensors,
                deviceClassNetworkConnections
            };
            // TODO: why are these connections outside of the deviceclass in the post?
            deviceClassRequest.deviceClass.deviceClassConnections = [];

            const deviceClassesRoute = this.RestangularV3.all('devices').all('device-classes');
            const myDeviceClass = this.RestangularV3.restangularizeElement(deviceClassesRoute, deviceClassRequest, this.deviceClass.id);

            myDeviceClass.put().then((response) => {
                this.$mdDialog.hide(response);
                this.Messages.success('Device Class updated successfully');
            })
            .catch((response) => {
                this.$mdDialog.cancel();
                this.Errors.forCRUD('UPDATE')(response);
            });
        }

        save() {
            if (this.deviceClass.id === undefined) {
                this.create();
            } else {
                return this.update();
            }
        }

    }

    export interface AdminDeviceClassDialogCtrlScope extends ng.IScope {
        selectedDeviceClass: any;
        options: DeviceClassOptions;
        protocols: any[];
        sensorGroups: any[];
        networkConnectionGroups: any[];
        jsonEditorOptions: object;
    }

    angular.module('aq.admin.meters.deviceclasses')
        .controller('AdminDeviceClassesCtrl', AdminDeviceClassesCtrl)
        .controller('AdminDeviceClassDialogCtrl', AdminDeviceClassDialogCtrl);
}
