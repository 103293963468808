angular.module('aq.reports').factory('ReportService',
    function(
        Restangular: restangular.IService,
        RestangularV3: restangular.IService,
        $q: ng.IQService,
        $location: ng.ILocationService,
        EnergyStarApiService: aq.energyStarApi.EnergyStarApiService,
        OptionsService
    ) {

        const report = <aq.reports.Report>{};
        const accountId = $location.search().accountId;
        const buildingId = $location.search().buildingId;
        let currentDate = moment();
        let localDate = currentDate.format('YYYY-MM-DDTHH:mm');
        if ($location.search().date) {
            localDate = moment
                    .unix(parseInt($location.search().date) / 1000)
                    .utc()
                    .format('YYYY-MM-DDTHH:mm');
            currentDate = moment.unix(parseInt($location.search().date) / 1000);
        }
        const accountPromise = RestangularV3.one('accounts', accountId).get().then(function(account) {

            report.account = account;

            if (report.account && report.account.accountName.toLowerCase() === 'under armour') {
                report.colors = {
                    primaryColor: '#F05660',
                    secondaryColor: '#B4B5B5',
                    weatherPrimaryColor: '#F05660'
                };
            } else {
                report.colors = {
                    primaryColor: '#008BF5',
                    secondaryColor: '#B4B5B5',
                    currentYearColor: '#008BF5',
                    secondYearColor: '#ba7dd4',
                    thirdYearColor: '#FFA100',
                    weatherPrimaryColor: '#FFA100'
                };
            }

        });

        let building = <aq.common.models.Building>{};
        return Promise.all([
            accountPromise,
            Restangular.one('accounts', accountId).one('buildings', buildingId)
                .get({ single: true, inflate: 'buildingPurpose,buildingOccupancies,collectors' }),
            EnergyStarApiService.getEnergyStarScoreForBuilding(accountId, buildingId),
            Restangular.one('accounts', accountId).getList('Functionalities')
        ]).then(result => {
            building = result[1];
            building.energyStarScore = result[2];
            report.functionalities = _.map(result[3], 'function');
            OptionsService.init(accountId, report.account.measurementSystem, report.account.currencyUnit);
            return OptionsService.fetchAll();
        }).then(function() {

            const measurementSystem = OptionsService.measurementSystem;
            report.areaMeasurementUnit = OptionsService.areaMeasurementUnit();
            report.temperatureUnit = OptionsService.temperatureUnit();

            _.extend(building, { currentTime: moment.tz(localDate, building.timeZoneId) });
            report.building = building;

            let type = 'electricity';
            if ($location.search()['type']) {
                type = $location.search()['type'];
            }

            const measurementType = {
                electricity: ['ENERGY', 'POWER', 'POWER_EXPECTED', 'POWER_STDDEV'],
                water: ['WATER', 'FLOW_RATE'],
                gas: ['GAS', 'FLOW_RATE'],
                steam: ['STEAM_MASS', 'STEAM_MASS_RATE'],
                heat: ['HEAT_TRANSFER', 'HEAT_TRANSFER_RATE']
            };

            const typeUnit = {
                electricity: ['kWh', 'kW'],
                water: [measurementSystem.waterUnit, measurementSystem.waterFlowRateUnit],
                gas: [measurementSystem.gasUnit],
                steam: [measurementSystem.steamUnit, measurementSystem.steamRateUnit],
                heat: [measurementSystem.heatUnit, measurementSystem.heatRateUnit]
            };

            report.measures = measurementType[type];
            report.metrics = measurementType[type].toString().toLowerCase();
            report.usageMetric = measurementType[type][0].toString().toLowerCase();
            report.demandMetric = measurementType[type][1].toString().toLowerCase();
            if (type == 'electricity') {
                report.expectedDemandMetric = measurementType[type][2].toString();
                report.expectedDemandVariance = measurementType[type][3].toString();
            }
            report.usageUnit = OptionsService.getUnitByEnumName(typeUnit[type][0]);

            // we don't have demand on gas so this is why we have condition here
            if (type != 'gas') {
                report.demandUnit = OptionsService.getUnitByEnumName(typeUnit[type][1]);
            }
            report.isWaterOrGasReport = type != 'electricity';

            return report;
        });

    });
