'use strict';

angular
.module('registration')
.controller('InvalidCtrl', function ($scope, RegistrationService) {

    $scope.hasToken = function () {
        return (RegistrationService.getToken()) ? true : false;
    }

});