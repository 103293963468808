namespace aq.dashboard.widgets {
    export class EmissionsCtrl {
        private building: aq.common.models.Building;
        /* @ngInject */
        constructor(private $scope,
                    private buildings,
                    private config,
                    private EmissionsData,
                    private ModelUtilService: aq.services.ModelUtilService
            ) {

            this.building = _.find(buildings, { 'id': parseInt(config.buildingId) }) || buildings[0];

            this.$scope.config = config;
            this.$scope.config.buildingId = this.building.id;

            this.$scope.params = {
                building: this.building
            };

            this.$scope.config.options = {
                layout: ['row', 'column'],
                tiles: ['homes', 'cars', 'showers'],
                buildings: ModelUtilService.pareProperties(buildings)
            };

            this.$scope.config.actions = {
                onBuildingChange: (cfg, buildingId) => {
                    cfg.buildingId = buildingId;
                }
            };

            this.$scope.tileRef = {
                homes: {
                    icon: 'aq-icons-house',
                    text: 'Homes Powered',
                    unit: 'kWh',
                    measure: 'ELECTRICITY',
                    multiplier: 0.000074
                },
                cars: {
                    icon: 'aq-icons-cars',
                    text: 'Cars Off The Road',
                    unit: 'MT CO2',
                    rawUnitMultiplier: 0.000703,
                    measure: 'ELECTRICITY',
                    multiplier: 0.00015
                },
                showers: {
                    icon: 'aq-icons-shower',
                    text: 'Showers Saved',
                    unit: 'gal',
                    measure: 'WATER',
                    multiplier: (1 / 17.2)
                }
            };

            this.$scope.config.tiles = this.$scope.config.tiles || this.$scope.config.options.tiles;

            this.$scope.config.layout = this.$scope.config.layout ||  'column';

            this.$scope.config.iconColor = this.$scope.config.iconColor || '#0091f1';

            if (typeof this.$scope.config.manualSavings === 'undefined' || this.$scope.config.manualSavings === null) {
                this.$scope.config.manualSavings = false;
            }

            this.renderSavings();
        }

        public calculateSavings(): any {
            const savings = {};
            const scope = this.$scope;
            let sum = 0;
            _.forEach(this.$scope.config.tiles, function (tile) {
                const rawValue = scope.config.savings[scope.tileRef[tile].measure.toLowerCase()];
                if (scope.tileRef[tile].rawUnitMultiplier) {
                    sum = rawValue * scope.tileRef[tile].rawUnitMultiplier;
                } else {
                    sum = rawValue;
                }

                savings[tile] = {
                    tile,
                    sum,
                    convertedSum: rawValue * scope.tileRef[tile].multiplier
                };
            });
            return savings;
        };

        private renderSavings() {
            this.$scope.config.buildingId = this.building.id;
            if (this.$scope.config.manualSavings) {
                this.$scope.savings = this.calculateSavings();
            } else {
                this.EmissionsData.getAllSavingsData(this.$scope.config, this.building, this.$scope.tileRef).then((savings) => {
                    this.$scope.savings = savings;
                });
            }
        }
    }
    angular.module('aq.dashboard.widgets').controller('EmissionsCtrl', EmissionsCtrl);
}
