namespace aq.projectCenter {
    export class ProjectDetailTasksCtrl {
        private completedTaskCount;
        private totalTaskCount;
        private timelineEvents;
        private completedTaskPercentage;
        private currentView = 'LIST';

        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private project: Project,
            private account: aq.common.models.Account,
            private users: aq.common.models.User[],
            private ProjectService: ProjectService,
            private Messages: aq.services.Messages,
            private $mdDialog: ng.material.IDialogService
        ) {
            this.setTasksInfo(this.project);
            this.$scope.$on(ProjectDetail.PROJECT_UPDATE_EVENT, (event, project) => {
                this.project = project;
                this.setTasksInfo(this.project);
            });
        }

        createTask() {
            this.$scope.$emit('OPEN_CREATE_TASK');
        }

        editProjectTask(task) {
            return this.$mdDialog.show({
                controller: 'ProjectCenterTaskEditCtrl as vm',
                templateUrl: 'app/projectCenter/project/task/projectCenterTaskEditModal.html',
                clickOutsideToClose: true,
                parent: angular.element(document.body),
                locals: {
                    project: this.project,
                    task: angular.copy(task),
                    users: this.users,
                    account: this.account,
                    options: null
                }
            }).then((task: Task) => {
                if (!this.project.tasks) {
                    this.project.tasks = [];
                }
                _.remove(this.project.tasks, { id: task.id });
                this.project.tasks.push(task);
                this.setTasksInfo(this.project);
                return this.project;
            });
        }

        deleteTask(task: Task) {
            const confirm = this.$mdDialog.confirm()
                .textContent('Are you sure you want to delete this task?')
                .ariaLabel('delete task')
                .ok('Delete')
                .cancel('Cancel');
            this.$mdDialog.show(confirm).then(() => {
                return this.ProjectService.deleteTask(this.project, task).catch((err) => {
                    this.Messages.error('Unable to delete task');
                });
            }).then(() => {
                _.remove(this.project.tasks, { id: task.id });
                this.Messages.success('Successfully deleted task');
            });
        }

        getOwner(task) {
            return _.find(this.users, { id: task.owner });
        }

        // ------------------------

        private setTasksInfo(project) {
            this.completedTaskCount = 0;
            this.totalTaskCount = 0;
            this.timelineEvents = [];
            project.tasks = _.sortBy(project.tasks, [
                (task) => moment(task.dueDate).valueOf(),
                (task) => task.name
            ]);
            _.each(project.tasks, (task) => {
                if (task.status != 'IGNORED') {
                    this.totalTaskCount++;
                    if (task.completionDate) {
                        this.completedTaskCount++;
                    }
                }

                this.timelineEvents.push({
                    class: task.completionDate ? 'bg-green' : 'bg-blue',
                    icon: task.completionDate ? 'check' : 'event_note',
                    title: task.name,
                    content: task.details,
                    dueDate: task.dueDate,
                    completionDate: task.completionDate,
                    owner: task.owner
                });
            });
            if (this.totalTaskCount > 0) {
                this.completedTaskPercentage = (this.completedTaskCount / this.totalTaskCount) * 100;
            } else if(project.tasks.length > 0) {
                this.completedTaskPercentage = 100;
            }
        }
    }
    angular
        .module('projectCenter')
        .controller('ProjectDetailTasksCtrl', ProjectDetailTasksCtrl);
}
