namespace aq.components {
    export class FieldInfo {
        private text;
        private icon;
        /* @ngInject */
        constructor(private $mdSticky) {}

        $onInit() {
            this.icon = this.icon ? this.icon : 'help_outline';
            this.text = this.text ? this.text : 'Please provide text for this tooltip';
        }

        $onChanges(changes) {}

        $postLink() {}
    }

    angular
    .module('aq.ui')
    .component('fieldInfo', {
        templateUrl: 'app/common/components/fieldInfo/fieldInfo.tpl.html',
        controller: FieldInfo,
        controllerAs: 'vm',
        bindings: {
            icon: '<?',
            text: '<?'
        }
    });
};