namespace aq.services {

    export class ModelUtilService {
        /* @ngInject */
        constructor() { }

        /**
         * Given a list of model objects, remove all extra properties we don't need
         * This is used to cut down on the number of watches we have when we use this on scope.
         *
         * @param {any[]} models: list of model objects that have an id and name
         * @param {string[]} [extraProperties]: any properties other than id and name that we want to keep
         * @returns {any[]}
         *
         * @memberOf ModelUtilService
         */
        pareProperties (models:any[], extraProperties?: string[]): any[] {
            let properties = ['id', 'name'];
            if (extraProperties) {
                properties = _.concat(properties, extraProperties);
            }
            let paredModels = _.map(models, (building) => {
                return _.pick(building, properties);
            });
            return paredModels;
        }
    }

    angular.module('aq.services').service('ModelUtilService', ModelUtilService);
}
