var aq;
(function (aq) {
    var ui;
    (function (ui) {
        // Reading on component lifecycle hooks https://toddmotto.com/angular-1-5-lifecycle-hooks
        var _ActivityMessages = /** @class */ (function () {
            /* @ngInject */
            function _ActivityMessages(UserService, resolver, ActivityService, $interval) {
                var _this = this;
                this.UserService = UserService;
                this.resolver = resolver;
                this.ActivityService = ActivityService;
                this.$interval = $interval;
                this.now = moment().valueOf();
                this.interval = this.$interval(function () {
                    _this.now = moment().valueOf();
                }, 60000);
                this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                // @ts-ignore
                                _a = this;
                                return [4 /*yield*/, UserService.getUser()];
                            case 1:
                                // @ts-ignore
                                _a.currentUser = _b.sent();
                                return [2 /*return*/];
                        }
                    });
                }); });
            }
            _ActivityMessages.prototype.$onDestroy = function () {
                if (this.interval) {
                    this.$interval.cancel(this.interval);
                }
            };
            _ActivityMessages.prototype.$onChanges = function (changes) {
                if (changes.messages) {
                    this.messages = angular.copy(changes.messages.currentValue);
                }
            };
            return _ActivityMessages;
        }());
        var ActivityMessages = {
            controller: _ActivityMessages,
            controllerAs: 'vm',
            templateUrl: 'app/common/components/activities/messages/messages.html',
            bindings: {
                messages: '<',
                noMessagesText: '<'
            }
        };
        angular
            .module('aq.ui')
            .component('activityMessages', ActivityMessages);
    })(ui = aq.ui || (aq.ui = {}));
})(aq || (aq = {}));
