namespace aq.propertySettings {
    export class BuildingDetailCtrl {
        private backButton: aq.components.BackButtonData;
        private propertySettingsAccess: common.models.AppAccessObject;
        private entityActions;
        private currentNavItem: string;
        constructor(
            public readOnlyForm,
            public weatherNormalization,
            public baselineYears,
            public buildings,
            public UserService,
            public building,
            public account,
            public optimalstart: OptimalStart,
            public temperatureUnit,
            private authAccess: common.models.AuthAppAccess,
            private $scope,
            private $state: angular.ui.IStateService,
            private Messages,
            private $mdDialog,
            private DataStore: aq.common.DataStore,
            private Auth: aq.services.Auth,
            private CreateBuildingService: aq.service.CreateBuildingService,
            private BuildingService: aq.services.BuildingService,
            private buildingEnrollment: BuildingEnrollment,
            private RestangularV3: restangular.IService
        ) {
            this.currentNavItem = ($state.current.url as string).substring(1);
            this.propertySettingsAccess = this.authAccess['Property Settings'];
            this.readOnlyForm = readOnlyForm;
            this.readOnlyForm = this.building.account != this.account.id ? true : false;
            this.backButton = {
                state: 'aq.settings.properties',
                stateData: { accountId: this.account.id }
            };
            // sets the current building in the building selector component
            this.$scope.$emit('buildingContextChange', { buildingId: building.id });

            weatherNormalization = weatherNormalization;
            baselineYears = baselineYears;
            optimalstart = optimalstart;
            temperatureUnit = temperatureUnit;

            if (this.UserService.currentUser.userType == 'ADMINISTRATOR') {
                this.setEntityActions();
            }
        }

        setEntityActions = () => {
            this.entityActions = [];
            if (!this.buildingEnrollment) {
                this.entityActions.push({ label: 'Create Installation Project', cb: this.createInstallationProject, icon: 'library_add' });
            }
            this.entityActions.push({ label: 'Upload Historic Data', cb: this.openHistoricUploadModal, icon: 'cloud_upload' });
            this.entityActions.push({ label: 'Create Default Dashboard', cb: this.createDefaultDashboard, icon: 'dashboard' });
        }

        openHistoricUploadModal = () => {
            this.$mdDialog.show({
                controller: 'HistoricUploadModalCtrl as vm',
                templateUrl: 'app/properties/building/historicUpload/historicUploadModal.html',
                clickOutsideToClose: true,
                parent: angular.element(document.body),
                locals: {
                    building: this.building
                }
            });
        }

        createDefaultDashboard = () => {
            this.RestangularV3.one('buildings', this.building.id).customPOST({},
                'create-default-dashboards', { accountId: this.account.id }).then(() => {
                    this.Messages.success('Successfully created default dashboard');
                }).catch(() => {
                    this.Messages.error('Unable to create default dashboard');
                });
        }

        createInstallationProject = () => {
            this.DataStore.getList(this.account, 'users', {}).then((users) => {
                return this.$mdDialog.show({
                    controller: 'CreateInstallationProjectModalCtrl as vm',
                    templateUrl: 'app/properties/building/createInstallationProjectModal/createInstallationProjectModal.html',
                    clickOutsideToClose: true,
                    parent: angular.element(document.body),
                    locals: {
                        users,
                        building: this.building
                    }
                }).then((response) => {
                    this.buildingEnrollment = response;
                    this.setEntityActions();
                });
            });
        }

        saveBuilding = (building) => {
            if (this.building.enticImpactDate) {
                this.building.enticImpactDate = moment(this.building.enticImpactDate).format('YYYY-MM-DD');
            }
            if (this.building.dataCommissionDate) {
                this.building.dataCommissionDate = moment(this.building.dataCommissionDate).toISOString();
            }
            if (this.building.sizeInSqFt) {
                this.building.sizeInSqFt = Math.round(this.building.sizeInSqFt);
            }
            if (this.building.sizeInSqM) {
                this.building.sizeInSqM = Math.round(this.building.sizeInSqM);
            }

            this.BuildingService.updateBuilding(building)
                .then((responseBuilding) => {
                    this.Messages.success('Building updated');
                    this.building = responseBuilding;
                    this.$scope.$broadcast('BUILDING_UPDATED', { building: this.building });
                    this.$scope.$emit('BUILDING_UPDATED', { building: this.building });
                })
                .catch((error) => {
                    this.Messages.error('Error updating building');
                    return null;
                });
            }

        selectBuilding = (building): void => {
            if (building.id) {
                this.goDetail(building);
            }
        }

        goDetail = (building): void => {
            this.$state.transitionTo('aq.properties.buildings.form', {
                accountId: building.account,
                buildingId: building.id
            });
        }
    }

    angular
        .module('properties')
        .controller('BuildingDetailCtrl', BuildingDetailCtrl);
}
