angular.module('aq-main').run(function ($httpBackend) {
    const regex = {
        list: /\/automatic-issue-detection\/v1\?/,
        meta: /\/automatic-issue-detection\/v1\/(.{36})/,
        feedback: /\/automatic-issue-detection\/v1\/(.{36})\/feedback/
    };

    const issueEventTypes = [
        aq.models.aidIssue.EventType.HOLIDAY_RUN,
        aq.models.aidIssue.EventType.NIGHTTIME_RUN,
        aq.models.aidIssue.EventType.UNOCCUPIED_HOUR_RUN,
        aq.models.aidIssue.EventType.WEEKEND_RUN
    ];
    const issueFeedbackActionTypes = [
        aq.models.aidIssue.ActionType.ACKNOWLEDGE,
        aq.models.aidIssue.ActionType.MODIFY
    ];
    const aqPotentialCauseList: string[] = [
        'not an issue',
        'early start',
        'equipment cycling/noise',
        'equipment maintenance/repair',
        'equipment/BMS error',
        'freeze protection',
        'late shutdown',
        'missed shutdown',
        'tenant request',
        'unoccupied hour temperature setback for cooling',
        'unoccupied hour temperature setback for heating',
        'unscheduled equipment running'
    ];

    const getListResponse = (params: aq.models.aidIssue.IssueQueryParams) => {
        const response = new aq.models.aidIssue.IssueListResponse();
        const { start, end, buildings, types } = params;
        // noinspection TypeScriptUnresolvedFunction
        const startDate = moment(start);
        // noinspection TypeScriptUnresolvedFunction
        const endDate = moment(end);
        const range = Math.abs(startDate.diff(endDate, 'days'));
        for (let day = 0; day < range; day++) {
            for (let i = 0; i < _.random(1, 5); i++) {
                const issue = new aq.models.aidIssue.SimpleIssueResponse();
                // @ts-ignore
                issue.id = uuidv4();
                issue.building = _.sample(buildings || [22485218]);
                // noinspection TypeScriptUnresolvedFunction
                issue.date = moment().subtract({days: day}).toISOString();
                issue.eventType = _.sample(issueEventTypes);
                issue.potentialCause = _.sample(aqPotentialCauseList);
                issue.potentialSavingsEnergy = _.random(15000, true);
                response.payload.push(issue);
            }
        }
        response.count = response.payload.length;
        if (types && types.length > 0) {
            response.payload = response.payload.filter(issue => types.includes(issue.eventType));
        }
        return response;
    };

    const getMetaResponse = (id: string) => {
        const result = new aq.models.aidIssue.IssueResponse();
        result.id = id;
        result.building = 22485218;
        // noinspection TypeScriptUnresolvedFunction
        result.date = moment().subtract({days: _.random(0, 7)}).toISOString();
        result.eventType = _.sample(issueEventTypes);
        result.potentialCause = _.sample(aqPotentialCauseList);
        result.potentialSavingsEnergy = _.random(15000, true);
        result.highlightedArea = {
            max_val: 562.57359,
            time_start: '2019-01-01T05:15:00-0500',
            time_end: '2019-01-01T22:15:00-0500',
            min_val: 447.74558
        };
        result.series = [
            {
                'energy': 457.20829,
                'baseload': 447.74558,
                'baseline': 450.58194499999996,
                'temperature': 46.525999999999996,
                'time': '2019-01-01T00:00:00-0500'
            },
            {
                'energy': 446.96362,
                'baseload': 447.74558,
                'baseline': 447.1061425,
                'temperature': 47.984,
                'time': '2019-01-01T00:15:00-0500'
            },
            {
                'energy': 437.10471,
                'baseload': 447.74558,
                'baseline': 447.95024749999993,
                'temperature': 47.528,
                'time': '2019-01-01T00:30:00-0500'
            },
            {
                'energy': 445.27297999999996,
                'baseload': 447.74558,
                'baseline': 444.726625,
                'temperature': 47.072,
                'time': '2019-01-01T00:45:00-0500'
            },
            {
                'energy': 447.21385,
                'baseload': 447.74558,
                'baseline': 446.0754275,
                'temperature': 46.616,
                'time': '2019-01-01T01:00:00-0500'
            },
            {
                'energy': 445.87652,
                'baseload': 447.74558,
                'baseline': 448.9120225,
                'temperature': 48.74,
                'time': '2019-01-01T01:15:00-0500'
            },
            {
                'energy': 459.49975,
                'baseload': 447.74558,
                'baseline': 447.644395,
                'temperature': 48.332,
                'time': '2019-01-01T01:30:00-0500'
            },
            {
                'energy': 445.85277,
                'baseload': 447.74558,
                'baseline': 444.146295,
                'temperature': 47.924,
                'time': '2019-01-01T01:45:00-0500'
            },
            {
                'energy': 441.13806,
                'baseload': 447.74558,
                'baseline': 444.36194,
                'temperature': 47.516,
                'time': '2019-01-01T02:00:00-0500'
            },
            {
                'energy': 432.5652,
                'baseload': 447.74558,
                'baseline': 442.9706275,
                'temperature': 49.514,
                'time': '2019-01-01T02:15:00-0500'
            },
            {
                'energy': 430.48496,
                'baseload': 447.74558,
                'baseline': 439.432915,
                'temperature': 49.178,
                'time': '2019-01-01T02:30:00-0500'
            },
            {
                'energy': 422.35471,
                'baseload': 447.74558,
                'baseline': 437.04367249999996,
                'temperature': 48.842,
                'time': '2019-01-01T02:45:00-0500'
            },
            {
                'energy': 428.56586,
                'baseload': 447.74558,
                'baseline': 433.689145,
                'temperature': 48.506,
                'time': '2019-01-01T03:00:00-0500'
            },
            {
                'energy': 428.29116,
                'baseload': 447.74558,
                'baseline': 437.69153,
                'temperature': 51.385999999999996,
                'time': '2019-01-01T03:15:00-0500'
            },
            {
                'energy': 429.10193,
                'baseload': 447.74558,
                'baseline': 439.49537999999995,
                'temperature': 51.002,
                'time': '2019-01-01T03:30:00-0500'
            },
            {
                'energy': 425.70340000000004,
                'baseload': 447.74558,
                'baseline': 444.67623,
                'temperature': 50.618,
                'time': '2019-01-01T03:45:00-0500'
            },
            {
                'energy': 426.66776,
                'baseload': 447.74558,
                'baseline': 443.63444000000004,
                'temperature': 50.234,
                'time': '2019-01-01T04:00:00-0500'
            },
            {
                'energy': 416.82494,
                'baseload': 447.74558,
                'baseline': 441.37223250000005,
                'temperature': 52.321999999999996,
                'time': '2019-01-01T04:15:00-0500'
            },
            {
                'energy': 418.05574,
                'baseload': 447.74558,
                'baseline': 441.11841000000004,
                'temperature': 52.766,
                'time': '2019-01-01T04:30:00-0500'
            },
            {
                'energy': 429.41141,
                'baseload': 447.74558,
                'baseline': 443.15106499999996,
                'temperature': 53.21,
                'time': '2019-01-01T04:45:00-0500'
            },
            {
                'energy': 446.45378000000005,
                'baseload': 447.74558,
                'baseline': 458.20631249999997,
                'temperature': 53.653999999999996,
                'time': '2019-01-01T05:00:00-0500'
            },
            {
                'energy': 484.54301,
                'baseload': 447.74558,
                'baseline': 461.94737749999996,
                'temperature': 54.284,
                'time': '2019-01-01T05:15:00-0500'
            },
            {
                'energy': 499.05826,
                'baseload': 447.74558,
                'baseline': 454.3535175,
                'temperature': 54.392,
                'time': '2019-01-01T05:30:00-0500'
            },
            {
                'energy': 489.53585,
                'baseload': 447.74558,
                'baseline': 458.6676675,
                'temperature': 54.5,
                'time': '2019-01-01T05:45:00-0500'
            },
            {
                'energy': 460.11262,
                'baseload': 447.74558,
                'baseline': 462.62626,
                'temperature': 54.608000000000004,
                'time': '2019-01-01T06:00:00-0500'
            },
            {
                'energy': 512.95754,
                'baseload': 447.74558,
                'baseline': 474.33901,
                'temperature': 55.778000000000006,
                'time': '2019-01-01T06:15:00-0500'
            },
            {
                'energy': 494.55502,
                'baseload': 447.74558,
                'baseline': 484.681775,
                'temperature': 55.82,
                'time': '2019-01-01T06:30:00-0500'
            },
            {
                'energy': 500.07367999999997,
                'baseload': 447.74558,
                'baseline': 497.72662499999996,
                'temperature': 55.861999999999995,
                'time': '2019-01-01T06:45:00-0500'
            },
            {
                'energy': 531.26495,
                'baseload': 447.74558,
                'baseline': 502.74631250000004,
                'temperature': 55.903999999999996,
                'time': '2019-01-01T07:00:00-0500'
            },
            {
                'energy': 505.23418,
                'baseload': 447.74558,
                'baseline': 519.2684075000001,
                'temperature': 55.112,
                'time': '2019-01-01T07:15:00-0500'
            },
            {
                'energy': 534.3486700000001,
                'baseload': 447.74558,
                'baseline': 527.0163275,
                'temperature': 55.616,
                'time': '2019-01-01T07:30:00-0500'
            },
            {
                'energy': 511.99078000000003,
                'baseload': 447.74558,
                'baseline': 524.4197750000001,
                'temperature': 56.120000000000005,
                'time': '2019-01-01T07:45:00-0500'
            },
            {
                'energy': 518.32063,
                'baseload': 447.74558,
                'baseline': 519.5437900000001,
                'temperature': 56.624,
                'time': '2019-01-01T08:00:00-0500'
            },
            {
                'energy': 517.84452,
                'baseload': 447.74558,
                'baseline': 512.4370275,
                'temperature': 56.552,
                'time': '2019-01-01T08:15:00-0500'
            },
            {
                'energy': 497.41514,
                'baseload': 447.74558,
                'baseline': 510.12166499999995,
                'temperature': 56.93,
                'time': '2019-01-01T08:30:00-0500'
            },
            {
                'energy': 493.63001,
                'baseload': 447.74558,
                'baseline': 514.184895,
                'temperature': 57.308,
                'time': '2019-01-01T08:45:00-0500'
            },
            {
                'energy': 493.15292,
                'baseload': 447.74558,
                'baseline': 511.896015,
                'temperature': 57.686,
                'time': '2019-01-01T09:00:00-0500'
            },
            {
                'energy': 484.18321000000003,
                'baseload': 447.74558,
                'baseline': 511.0376575,
                'temperature': 56.912,
                'time': '2019-01-01T09:15:00-0500'
            },
            {
                'energy': 475.54605,
                'baseload': 447.74558,
                'baseline': 510.6861775,
                'temperature': 57.23,
                'time': '2019-01-01T09:30:00-0500'
            },
            {
                'energy': 475.20198999999997,
                'baseload': 447.74558,
                'baseline': 507.8777575,
                'temperature': 57.548,
                'time': '2019-01-01T09:45:00-0500'
            },
            {
                'energy': 480.91193,
                'baseload': 447.74558,
                'baseline': 514.1263025,
                'temperature': 57.866,
                'time': '2019-01-01T10:00:00-0500'
            },
            {
                'energy': 446.91583,
                'baseload': 447.74558,
                'baseline': 513.46298,
                'temperature': 55.903999999999996,
                'time': '2019-01-01T10:15:00-0500'
            },
            {
                'energy': 434.12167,
                'baseload': 447.74558,
                'baseline': 517.180625,
                'temperature': 56.402,
                'time': '2019-01-01T10:30:00-0500'
            },
            {
                'energy': 440.39263,
                'baseload': 447.74558,
                'baseline': 525.050385,
                'temperature': 56.9,
                'time': '2019-01-01T10:45:00-0500'
            },
            {
                'energy': 463.30832,
                'baseload': 447.74558,
                'baseline': 527.5222675,
                'temperature': 57.397999999999996,
                'time': '2019-01-01T11:00:00-0500'
            },
            {
                'energy': 457.00167,
                'baseload': 447.74558,
                'baseline': 530.921325,
                'temperature': 54.698,
                'time': '2019-01-01T11:15:00-0500'
            },
            {
                'energy': 474.87617,
                'baseload': 447.74558,
                'baseline': 532.6020675,
                'temperature': 55.076,
                'time': '2019-01-01T11:30:00-0500'
            },
            {
                'energy': 481.6585,
                'baseload': 447.74558,
                'baseline': 533.55737,
                'temperature': 55.454,
                'time': '2019-01-01T11:45:00-0500'
            },
            {
                'energy': 492.83016,
                'baseload': 447.74558,
                'baseline': 536.2773925,
                'temperature': 55.832,
                'time': '2019-01-01T12:00:00-0500'
            },
            {
                'energy': 506.33407,
                'baseload': 447.74558,
                'baseline': 541.5025599999999,
                'temperature': 53.366,
                'time': '2019-01-01T12:15:00-0500'
            },
            {
                'energy': 496.53052,
                'baseload': 447.74558,
                'baseline': 547.3682449999999,
                'temperature': 54.013999999999996,
                'time': '2019-01-01T12:30:00-0500'
            },
            {
                'energy': 494.79956,
                'baseload': 447.74558,
                'baseline': 549.8394375,
                'temperature': 54.662,
                'time': '2019-01-01T12:45:00-0500'
            },
            {
                'energy': 512.5929699999999,
                'baseload': 447.74558,
                'baseline': 557.2349975,
                'temperature': 55.31,
                'time': '2019-01-01T13:00:00-0500'
            },
            {
                'energy': 562.57359,
                'baseload': 447.74558,
                'baseline': 545.2003599999999,
                'temperature': 52.484,
                'time': '2019-01-01T13:15:00-0500'
            },
            {
                'energy': 481.25442,
                'baseload': 447.74558,
                'baseline': 549.0767874999999,
                'temperature': 52.502,
                'time': '2019-01-01T13:30:00-0500'
            },
            {
                'energy': 481.62584000000004,
                'baseload': 447.74558,
                'baseline': 548.4362325,
                'temperature': 52.52,
                'time': '2019-01-01T13:45:00-0500'
            },
            {
                'energy': 468.34990999999997,
                'baseload': 447.74558,
                'baseline': 549.054735,
                'temperature': 52.538,
                'time': '2019-01-01T14:00:00-0500'
            },
            {
                'energy': 480.26203000000004,
                'baseload': 447.74558,
                'baseline': 551.1897625000001,
                'temperature': 51.602000000000004,
                'time': '2019-01-01T14:15:00-0500'
            },
            {
                'energy': 499.81579,
                'baseload': 447.74558,
                'baseline': 547.6886025,
                'temperature': 50.912,
                'time': '2019-01-01T14:30:00-0500'
            },
            {
                'energy': 506.79277,
                'baseload': 447.74558,
                'baseline': 555.6645375,
                'temperature': 50.222,
                'time': '2019-01-01T14:45:00-0500'
            },
            {
                'energy': 519.36749,
                'baseload': 447.74558,
                'baseline': 550.3465175,
                'temperature': 49.532,
                'time': '2019-01-01T15:00:00-0500'
            },
            {
                'energy': 514.32292,
                'baseload': 447.74558,
                'baseline': 555.6204525,
                'temperature': 48.938,
                'time': '2019-01-01T15:15:00-0500'
            },
            {
                'energy': 521.56958,
                'baseload': 447.74558,
                'baseline': 556.3024125,
                'temperature': 48.506,
                'time': '2019-01-01T15:30:00-0500'
            },
            {
                'energy': 519.2101,
                'baseload': 447.74558,
                'baseline': 561.5530725,
                'temperature': 48.074,
                'time': '2019-01-01T15:45:00-0500'
            },
            {
                'energy': 521.18134,
                'baseload': 447.74558,
                'baseline': 553.2778875,
                'temperature': 47.641999999999996,
                'time': '2019-01-01T16:00:00-0500'
            },
            {
                'energy': 511.58353999999997,
                'baseload': 447.74558,
                'baseline': 557.3498675,
                'temperature': 46.004,
                'time': '2019-01-01T16:15:00-0500'
            },
            {
                'energy': 497.78975,
                'baseload': 447.74558,
                'baseline': 556.9137825,
                'temperature': 45.95,
                'time': '2019-01-01T16:30:00-0500'
            },
            {
                'energy': 460.128,
                'baseload': 447.74558,
                'baseline': 552.5395175,
                'temperature': 45.896,
                'time': '2019-01-01T16:45:00-0500'
            },
            {
                'energy': 477.14514,
                'baseload': 447.74558,
                'baseline': 570.0910200000001,
                'temperature': 45.842,
                'time': '2019-01-01T17:00:00-0500'
            },
            {
                'energy': 486.21128999999996,
                'baseload': 447.74558,
                'baseline': 558.3466549999999,
                'temperature': 44.132000000000005,
                'time': '2019-01-01T17:15:00-0500'
            },
            {
                'energy': 491.60814,
                'baseload': 447.74558,
                'baseline': 566.953185,
                'temperature': 44.396,
                'time': '2019-01-01T17:30:00-0500'
            },
            {
                'energy': 504.64286,
                'baseload': 447.74558,
                'baseline': 552.6338025,
                'temperature': 44.66,
                'time': '2019-01-01T17:45:00-0500'
            },
            {
                'energy': 509.0119,
                'baseload': 447.74558,
                'baseline': 551.455565,
                'temperature': 44.924,
                'time': '2019-01-01T18:00:00-0500'
            },
            {
                'energy': 505.48318,
                'baseload': 447.74558,
                'baseline': 553.3045350000001,
                'temperature': 42.908,
                'time': '2019-01-01T18:15:00-0500'
            },
            {
                'energy': 531.33669,
                'baseload': 447.74558,
                'baseline': 554.7638525000001,
                'temperature': 43.298,
                'time': '2019-01-01T18:30:00-0500'
            },
            {
                'energy': 542.83712,
                'baseload': 447.74558,
                'baseline': 558.3298325000001,
                'temperature': 43.688,
                'time': '2019-01-01T18:45:00-0500'
            },
            {
                'energy': 524.40791,
                'baseload': 447.74558,
                'baseline': 561.317885,
                'temperature': 44.078,
                'time': '2019-01-01T19:00:00-0500'
            },
            {
                'energy': 529.7598,
                'baseload': 447.74558,
                'baseline': 564.9157975,
                'temperature': 42.403999999999996,
                'time': '2019-01-01T19:15:00-0500'
            },
            {
                'energy': 482.24940999999995,
                'baseload': 447.74558,
                'baseline': 569.108425,
                'temperature': 42.818,
                'time': '2019-01-01T19:30:00-0500'
            },
            {
                'energy': 479.18158,
                'baseload': 447.74558,
                'baseline': 563.6963225,
                'temperature': 43.232,
                'time': '2019-01-01T19:45:00-0500'
            },
            {
                'energy': 492.83446999999995,
                'baseload': 447.74558,
                'baseline': 568.244875,
                'temperature': 43.646,
                'time': '2019-01-01T20:00:00-0500'
            },
            {
                'energy': 498.45931,
                'baseload': 447.74558,
                'baseline': 558.7490475,
                'temperature': 41.954,
                'time': '2019-01-01T20:15:00-0500'
            },
            {
                'energy': 515.08456,
                'baseload': 447.74558,
                'baseline': 565.3464775,
                'temperature': 42.379999999999995,
                'time': '2019-01-01T20:30:00-0500'
            },
            {
                'energy': 513.47755,
                'baseload': 447.74558,
                'baseline': 561.7347525,
                'temperature': 42.806,
                'time': '2019-01-01T20:45:00-0500'
            },
            {
                'energy': 524.61285,
                'baseload': 447.74558,
                'baseline': 563.9930975,
                'temperature': 43.232,
                'time': '2019-01-01T21:00:00-0500'
            },
            {
                'energy': 526.69862,
                'baseload': 447.74558,
                'baseline': 567.1251275,
                'temperature': 41.666,
                'time': '2019-01-01T21:15:00-0500'
            },
            {
                'energy': 534.86339,
                'baseload': 447.74558,
                'baseline': 568.6370325,
                'temperature': 41.846000000000004,
                'time': '2019-01-01T21:30:00-0500'
            },
            {
                'energy': 519.15854,
                'baseload': 447.74558,
                'baseline': 561.07419,
                'temperature': 42.025999999999996,
                'time': '2019-01-01T21:45:00-0500'
            },
            {
                'energy': 517.80801,
                'baseload': 447.74558,
                'baseline': 550.6673875,
                'temperature': 42.206,
                'time': '2019-01-01T22:00:00-0500'
            },
            {
                'energy': 476.94597999999996,
                'baseload': 447.74558,
                'baseline': 561.2416125,
                'temperature': 41.108000000000004,
                'time': '2019-01-01T22:15:00-0500'
            },
            {
                'energy': 449.86021999999997,
                'baseload': 447.74558,
                'baseline': 525.8052025000001,
                'temperature': 41.21,
                'time': '2019-01-01T22:30:00-0500'
            },
            {
                'energy': 457.98926,
                'baseload': 447.74558,
                'baseline': 518.8545899999999,
                'temperature': 41.312,
                'time': '2019-01-01T22:45:00-0500'
            },
            {
                'energy': 467.33590999999996,
                'baseload': 447.74558,
                'baseline': 508.81066,
                'temperature': 41.414,
                'time': '2019-01-01T23:00:00-0500'
            },
            {
                'energy': 445.61197999999996,
                'baseload': 447.74558,
                'baseline': 471.23141000000004,
                'temperature': 40.316,
                'time': '2019-01-01T23:15:00-0500'
            },
            {
                'energy': 445.43028000000004,
                'baseload': 447.74558,
                'baseline': 461.952405,
                'temperature': 40.136,
                'time': '2019-01-01T23:30:00-0500'
            },
            {
                'energy': 444.77756,
                'baseload': 447.74558,
                'baseline': 462.97792499999997,
                'temperature': 39.956,
                'time': '2019-01-01T23:45:00-0500'
            },
            {
                'energy': 445.12125,
                'baseload': 447.74558,
                'baseline': 450.58194499999996,
                'temperature': 39.776,
                'time': '2019-01-02T00:00:00-0500'
            },
            {
                'energy': 436.81107000000003,
                'baseload': 447.74558,
                'baseline': 447.1061425,
                'temperature': 39.38,
                'time': '2019-01-02T00:15:00-0500'
            },
            {
                'energy': 436.77574,
                'baseload': 447.74558,
                'baseline': 447.95024749999993,
                'temperature': 39.134,
                'time': '2019-01-02T00:30:00-0500'
            },
            {
                'energy': 435.08671000000004,
                'baseload': 447.74558,
                'baseline': 444.726625,
                'temperature': 38.888,
                'time': '2019-01-02T00:45:00-0500'
            },
            {
                'energy': 438.67361999999997,
                'baseload': 447.74558,
                'baseline': 446.0754275,
                'temperature': 38.642,
                'time': '2019-01-02T01:00:00-0500'
            },
            {
                'energy': 446.92388,
                'baseload': 447.74558,
                'baseline': 448.9120225,
                'temperature': 38.12,
                'time': '2019-01-02T01:15:00-0500'
            },
            {
                'energy': 449.92267,
                'baseload': 447.74558,
                'baseline': 447.644395,
                'temperature': 38.03,
                'time': '2019-01-02T01:30:00-0500'
            },
            {
                'energy': 445.35387,
                'baseload': 447.74558,
                'baseline': 444.146295,
                'temperature': 37.94,
                'time': '2019-01-02T01:45:00-0500'
            },
            {
                'energy': 429.87852000000004,
                'baseload': 447.74558,
                'baseline': 444.36194,
                'temperature': 37.85,
                'time': '2019-01-02T02:00:00-0500'
            },
            {
                'energy': 428.9401,
                'baseload': 447.74558,
                'baseline': 442.9706275,
                'temperature': 37.364,
                'time': '2019-01-02T02:15:00-0500'
            },
            {
                'energy': 429.69546,
                'baseload': 447.74558,
                'baseline': 439.432915,
                'temperature': 37.274,
                'time': '2019-01-02T02:30:00-0500'
            },
            {
                'energy': 427.0783,
                'baseload': 447.74558,
                'baseline': 437.04367249999996,
                'temperature': 37.184,
                'time': '2019-01-02T02:45:00-0500'
            },
            {
                'energy': 424.36113,
                'baseload': 447.74558,
                'baseline': 433.689145,
                'temperature': 37.094,
                'time': '2019-01-02T03:00:00-0500'
            },
            {
                'energy': 435.62553,
                'baseload': 447.74558,
                'baseline': 437.69153,
                'temperature': 36.806,
                'time': '2019-01-02T03:15:00-0500'
            },
            {
                'energy': 445.7593,
                'baseload': 447.74558,
                'baseline': 439.49537999999995,
                'temperature': 36.65,
                'time': '2019-01-02T03:30:00-0500'
            },
            {
                'energy': 438.51705,
                'baseload': 447.74558,
                'baseline': 444.67623,
                'temperature': 36.494,
                'time': '2019-01-02T03:45:00-0500'
            },
            {
                'energy': 557.1739699999999,
                'baseload': 447.74558,
                'baseline': 443.63444000000004,
                'temperature': 36.338,
                'time': '2019-01-02T04:00:00-0500'
            },
            {
                'energy': 657.41409,
                'baseload': 447.74558,
                'baseline': 441.37223250000005,
                'temperature': 36.05,
                'time': '2019-01-02T04:15:00-0500'
            },
            {
                'energy': 716.0534,
                'baseload': 447.74558,
                'baseline': 441.11841000000004,
                'temperature': 35.936,
                'time': '2019-01-02T04:30:00-0500'
            },
            {
                'energy': 744.7915899999999,
                'baseload': 447.74558,
                'baseline': 443.15106499999996,
                'temperature': 35.822,
                'time': '2019-01-02T04:45:00-0500'
            },
            {
                'energy': 838.0195699999999,
                'baseload': 447.74558,
                'baseline': 458.20631249999997,
                'temperature': 35.708,
                'time': '2019-01-02T05:00:00-0500'
            },
            {
                'energy': 869.1635500000001,
                'baseload': 447.74558,
                'baseline': 461.94737749999996,
                'temperature': 34.988,
                'time': '2019-01-02T05:15:00-0500'
            },
            {
                'energy': 849.1518599999999,
                'baseload': 447.74558,
                'baseline': 454.3535175,
                'temperature': 35.018,
                'time': '2019-01-02T05:30:00-0500'
            },
            {
                'energy': 805.07039,
                'baseload': 447.74558,
                'baseline': 458.6676675,
                'temperature': 35.048,
                'time': '2019-01-02T05:45:00-0500'
            },
            {
                'energy': 898.29486,
                'baseload': 447.74558,
                'baseline': 462.62626,
                'temperature': 35.078,
                'time': '2019-01-02T06:00:00-0500'
            },
            {
                'energy': 1063.20454,
                'baseload': 447.74558,
                'baseline': 474.33901,
                'temperature': 34.358,
                'time': '2019-01-02T06:15:00-0500'
            },
            {
                'energy': 1025.37605,
                'baseload': 447.74558,
                'baseline': 484.681775,
                'temperature': 34.286,
                'time': '2019-01-02T06:30:00-0500'
            },
            {
                'energy': 998.60209,
                'baseload': 447.74558,
                'baseline': 497.72662499999996,
                'temperature': 34.214,
                'time': '2019-01-02T06:45:00-0500'
            },
            {
                'energy': 961.85186,
                'baseload': 447.74558,
                'baseline': 502.74631250000004,
                'temperature': 34.142,
                'time': '2019-01-02T07:00:00-0500'
            },
            {
                'energy': 977.92872,
                'baseload': 447.74558,
                'baseline': 519.2684075000001,
                'temperature': 34.196,
                'time': '2019-01-02T07:15:00-0500'
            },
            {
                'energy': 968.03305,
                'baseload': 447.74558,
                'baseline': 527.0163275,
                'temperature': 34.172,
                'time': '2019-01-02T07:30:00-0500'
            },
            {
                'energy': 938.0413199999999,
                'baseload': 447.74558,
                'baseline': 524.4197750000001,
                'temperature': 34.148,
                'time': '2019-01-02T07:45:00-0500'
            },
            {
                'energy': 937.41939,
                'baseload': 447.74558,
                'baseline': 519.5437900000001,
                'temperature': 34.124,
                'time': '2019-01-02T08:00:00-0500'
            },
            {
                'energy': 926.6746800000001,
                'baseload': 447.74558,
                'baseline': 512.4370275,
                'temperature': 34.934,
                'time': '2019-01-02T08:15:00-0500'
            },
            {
                'energy': 954.52239,
                'baseload': 447.74558,
                'baseline': 510.12166499999995,
                'temperature': 35.018,
                'time': '2019-01-02T08:30:00-0500'
            },
            {
                'energy': 965.3736899999999,
                'baseload': 447.74558,
                'baseline': 514.184895,
                'temperature': 35.102,
                'time': '2019-01-02T08:45:00-0500'
            },
            {
                'energy': 941.25676,
                'baseload': 447.74558,
                'baseline': 511.896015,
                'temperature': 35.186,
                'time': '2019-01-02T09:00:00-0500'
            },
            {
                'energy': 946.31174,
                'baseload': 447.74558,
                'baseline': 511.0376575,
                'temperature': 35.546,
                'time': '2019-01-02T09:15:00-0500'
            },
            {
                'energy': 940.54196,
                'baseload': 447.74558,
                'baseline': 510.6861775,
                'temperature': 35.78,
                'time': '2019-01-02T09:30:00-0500'
            },
            {
                'energy': 970.0650899999999,
                'baseload': 447.74558,
                'baseline': 507.8777575,
                'temperature': 36.014,
                'time': '2019-01-02T09:45:00-0500'
            },
            {
                'energy': 961.01661,
                'baseload': 447.74558,
                'baseline': 514.1263025,
                'temperature': 36.248,
                'time': '2019-01-02T10:00:00-0500'
            },
            {
                'energy': 930.48689,
                'baseload': 447.74558,
                'baseline': 513.46298,
                'temperature': 35.852000000000004,
                'time': '2019-01-02T10:15:00-0500'
            },
            {
                'energy': 913.89203,
                'baseload': 447.74558,
                'baseline': 517.180625,
                'temperature': 36.29,
                'time': '2019-01-02T10:30:00-0500'
            },
            {
                'energy': 922.9400400000001,
                'baseload': 447.74558,
                'baseline': 525.050385,
                'temperature': 36.728,
                'time': '2019-01-02T10:45:00-0500'
            },
            {
                'energy': 913.49837,
                'baseload': 447.74558,
                'baseline': 527.5222675,
                'temperature': 37.166,
                'time': '2019-01-02T11:00:00-0500'
            },
            {
                'energy': 891.34248,
                'baseload': 447.74558,
                'baseline': 530.921325,
                'temperature': 37.184,
                'time': '2019-01-02T11:15:00-0500'
            },
            {
                'energy': 927.12243,
                'baseload': 447.74558,
                'baseline': 532.6020675,
                'temperature': null,
                'time': '2019-01-02T11:30:00-0500'
            },
            {
                'energy': 965.31274,
                'baseload': 447.74558,
                'baseline': 533.55737,
                'temperature': null,
                'time': '2019-01-02T11:45:00-0500'
            }
        ];
        result.feedback = [];

        for (let i = 0; i < _.random(1, 5); i++) {
            const issueFeedback = new aq.models.aidIssue.IssueFeedbackResponse();
            // @ts-ignore
            issueFeedback.id = uuidv4();
            issueFeedback.user = 12345;
            issueFeedback.actionType = _.sample(issueFeedbackActionTypes);
            issueFeedback.issue = result.id;
            // noinspection TypeScriptUnresolvedFunction
            issueFeedback.created = moment().subtract({hours: _.random(0.25, 24.00)}).toISOString();
            issueFeedback.updated = issueFeedback.created;
            if (issueFeedback.actionType === aq.models.aidIssue.ActionType.MODIFY) {
                issueFeedback.eventType = _.sample(issueEventTypes);
                issueFeedback.potentialCause = _.sample(aqPotentialCauseList);
            }
            result.feedback.push(issueFeedback);
        }
        return result;
    };

    const generateFeedbackResponse = (issue: string, dataStr: string) => {
        const data: aq.models.aidIssue.IssueFeedbackCreateRequest = JSON.parse(dataStr);
        const result = new aq.models.aidIssue.IssueFeedbackResponse();
        // @ts-ignore
        result.id = uuidv4();
        result.issue = issue;
        result.user = data.user;
        result.actionType = data.actionType;
        if (data.actionType === aq.models.aidIssue.ActionType.MODIFY.toString()) {
            result.eventType = data.eventType;
            result.potentialCause = data.potentialCause;
            result.highlightedArea = data.highlightedArea;
            result.created = moment().toISOString();
            result.updated = moment().toISOString();
        }
        return result;
    };

    MockContext('automaticIssueDetection', () => {
        $httpBackend.whenGET(regex.meta).respond((method, url, data) => {
            // @ts-ignore
            const regexMatch = regex.meta.exec(url);
            const id = regexMatch[1];
            const response = getMetaResponse(id);
            return [200, response, {}];
        });

        $httpBackend.whenPOST(regex.feedback).respond((method, url, data) => {
            // @ts-ignore
            const regexMatch = regex.meta.exec(url);
            const id = regexMatch[1];
            const response = generateFeedbackResponse(id, data);
            return [200, response, {}];
        });

        $httpBackend.whenGET(regex.list).respond((method, url, data) => {
            // @ts-ignore
            const queryString = url.substring(url.indexOf('?') + 1);
            const params = MockHelpers.queryStringParse(queryString);
            const response = getListResponse(params as any);
            return [200, response, {}];
        });
    });
});
