namespace aq.services {

    export class BudgetServiceV3 {

        /* @ngInject */
        constructor(
            private AuthToken: string,
            private $location: ng.ILocationService,
            private UserService: UserService,
            private RestangularV3: restangular.IService,
            private RestangularV3FullResponse: restangular.IService
        ) {

        }

        public async getBudgetPlannerTemplate(buildingId: number): Promise<any> {
            try {
                return this.RestangularV3FullResponse.one('budget-workbook').withHttpConfig({ responseType: 'blob', transformRequest: angular.identity })
                    .customGET('download-budget-workbook', {buildingId: buildingId});
            } catch (error) {
                return Promise.reject(error);
            }
        }

        public async verifyBudgetDataImport(accountId: number, buildingId: number, data: string): Promise<any> {
            try {
                return this.RestangularV3.one('budget-workbook').all('verify-budget-data').customPOST(data, '', { buildingId });
            } catch (error) {
                return Promise.reject(error);
            }
        }

        public async importBudgetData(accountId: number, buildingId: number, data: string): Promise<any> {
            try {
                return this.RestangularV3.one('budget-workbook').all('upload-budget-data').customPOST(data, '', { buildingId });
            } catch (error) {
                throw error;
            }
        }

    }

    angular
        .module('aq.services')
        .service('BudgetServiceV3', BudgetServiceV3);
}
