angular.module('aq.ui.onOff', []).directive('onOff', function () {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/common/directives/onOff/onOff.html',
        scope: {
            on: '&',
            model: '='
        }
    }
});