angular
    .module('aq.ui')
    .directive('isValidUrl', () => {
        return {
            restrict: 'A',
            require: 'ngModel',
            link(scope: ng.IScope, elem: ng.IAugmentedJQuery, attrs, ngModelCtrl: ng.INgModelController) {
                const validUrlRegexp = new RegExp('^' +
                    '(?:(?:https?)://)?' +
                    '(?:(?:[a-z0-9](?:[a-z0-9-]{0,63}))?(?:[a-z0-9])\\.){1,127}' +
                    '(?:(?:[a-z]{2,63})|(?:xn--[a-z0-9-]{2,59}))' +
                    '(?::\\d{2,5})?' +
                    '(?:[/?#]\\S{0,2000})?' +
                    '$', 'i');
                ngModelCtrl.$validators.isValidUrl = (modelValue, viewValue) => {
                    const url = modelValue || viewValue;
                    if (url) {
                        return url.match(validUrlRegexp) != null;
                    }
                    return true;
                };
            }
        };
    });
