namespace aq.networkingDevices {
    export class NetworkingDevices {
        /* @ngInject */
        constructor(
            $scope: ng.IScope,
            account: aq.common.models.Account,
            buildings: aq.common.models.Building[],
            private $rootScope: ng.IRootScopeService,
            private $state: ng.ui.IStateService,
            private $mdMedia: ng.material.IMedia
        ) {
            // TODO: remove scope updates when legacy building selector is no longer used
            $scope.account = account;
            $scope.buildings = buildings;
            $scope.selectDefaultBuildingWithRedirect(this.$state.current, this.$state.current);
        }
    }

    angular
        .module('networkingDevices')
        .controller('NetworkingDevicesCrtl', NetworkingDevices);
}
