namespace aq.energyInsights {

    import HighchartsBarChartSeriesOptions = __Highcharts.BarChartSeriesOptions;
    import HighchartsChartObject = __Highcharts.ChartObject;
    import HighchartsDataPoint = __Highcharts.DataPoint;
    import HighchartsLineChartSeriesOptions = __Highcharts.LineChartSeriesOptions;
    import HighchartsOptions = __Highcharts.Options;
    import HighchartsSeriesObject = __Highcharts.SeriesObject;

    export class WeatherNormalizationCtrl {
        static CURRENT_YEAR = parseInt(moment().format('YYYY'));
        static PREVIOUS_YEAR = WeatherNormalizationCtrl.CURRENT_YEAR - 1;
        static CURRENT_YEAR_ENERGY_SERIES_NAME = 'Actual Consumption';
        static CURRENT_YEAR_NORMALIZED_SERIES_NAME = 'Normalized Consumption';
        static CURRENT_YEAR_TEMP_SERIES_NAME = 'Actual Temperature';
        static CLIMATE_NORMAL_SERIES_NAME = 'Climate Normal Temperature';
        static PREVIOUS_YEAR_ENERGY_SERIES_NAME = 'Previous Actual Consumption';
        static PREVIOUS_YEAR_NORMALIZED_SERIES_NAME = 'Previous Period Normalized Consumption';
        static PREVIOUS_YEAR_TEMP_SERIES_NAME = 'Previous Period Temperature';
        private previousYearEnergy: aq.energyInsights.service.DataValue[];
        private previousYearNormalized: aq.energyInsights.service.DataValue[];
        private previousYearTemperature: aq.energyInsights.service.TempDataValue[];
        private months: string[];
        private appRestructure;

        /* @ngInject */
        constructor(private $scope: WeatherNormalizationCtrlScope,
            private metric,
            private $filter,
            private UserService,
            private $q: ng.IQService,
            private currentBuilding: aq.common.models.Building,
            private EnergyInsightsDataService: aq.energyInsights.service.EnergyInsightsDataService,
            private account: aq.common.models.Account,
            private OptionsService,
            private energyNotes: aq.common.models.EnergyNote[],
            public $mdDialog: ng.material.IDialogService,
            public $window: ng.IWindowService,
            private $state: ng.ui.IStateService,
            private loading,
            private WeatherNormalizationService: aq.energyInsights.WeatherNormalizationService,
            private $rootScope,
            private Auth: aq.services.Auth) {

            this.$scope.toastMessage = 'Improve your analysis by enabling weather normalization for this building!';
            this.$scope.currentYear = WeatherNormalizationCtrl.CURRENT_YEAR;
            this.$scope.previousYear = WeatherNormalizationCtrl.PREVIOUS_YEAR;
            this.$scope.temperatureUnit = this.OptionsService.temperatureUnit();
            this.$scope.metric = metric;
            this.$scope.showActuals = true;
            this.$scope.showTemperatures = false;
            this.$scope.timezone = currentBuilding.timeZoneId;

            this.$scope.periodSearch = {
                start: aq.common.TimeConfiguration.This.Year.start(this.currentBuilding.timeZoneId),
                end: aq.common.TimeConfiguration.This.Year.end(this.currentBuilding.timeZoneId),
                interval: _.find(aq.common.TimeConfiguration.This.Month.intervals, { value: '1mon' })
            };

            this.onPeriodChange($scope.periodSearch.start, $scope.periodSearch.end, $scope.periodSearch.interval, $scope.periodSearch.defaultPeriod);
            this.appRestructure = Auth.hasFunctionality('App Restructure');
        }

        public onPeriodChange(startDate, endDate, interval, label) {
            this.$scope.comparePreviousYear = false;
            this.$scope.showTemperatures = false;
            this.$scope.periodSearch.label = label;
            this.$scope.periodSearch.start = startDate.add(1, 'day').tz(this.currentBuilding.timeZoneId).startOf('month');
            this.$scope.periodSearch.end = endDate.add(1, 'day').tz(this.currentBuilding.timeZoneId).startOf('month');
            this.queryData(startDate, endDate, interval.value);
        }

        public goToPropertySettings = () => {
            this.$state.go('aq.properties.buildings.form', { accountId: this.account.id, buildingId: this.currentBuilding.id });
        }

        private queryData(startDate, endDate, interval) {
            this.loading.start();
            let trendStartDate = moment(startDate).subtract(1, 'years');
            let trendEndDate = moment(trendStartDate).add(1, 'years');
            this.$q.all([
                this.EnergyInsightsDataService.getEnergy(this.currentBuilding, startDate, endDate, this.metric, interval, this.account),
                this.EnergyInsightsDataService.getWeatherNormalizedEnergy(this.currentBuilding, startDate, endDate, this.metric, interval, this.account),
                this.EnergyInsightsDataService.getEnergy(this.currentBuilding, trendStartDate, trendEndDate, this.metric, interval, this.account),
                this.EnergyInsightsDataService.getWeatherNormalizedEnergy(this.currentBuilding, trendStartDate, trendEndDate, this.metric, interval, this.account),
                this.EnergyInsightsDataService.getTemperatures(this.currentBuilding, startDate, endDate, this.$scope.temperatureUnit, interval, this.account)
            ]).then((results) => {
                this.$scope.currentEnergy = results[0];
                this.$scope.currentNormalized = results[1];
                this.$scope.trendEnergy = results[2];
                this.$scope.trendNormalized = results[3];
                this.$scope.currentTemperatures = results[4]
                let uniqueValues = _.uniq(_.map(this.$scope.currentNormalized, 'value'));
                this.$scope.disableWeatherNormalization = uniqueValues.length == 1 && uniqueValues[0] == null;
                if (!this.$scope.disableWeatherNormalization) {
                    this.$scope.showWeatherNormalization = true;
                }
                this.months = this.$scope.currentEnergy.map((dataValue) => {
                    return moment(dataValue.timestamp).format('MMM');
                });
                this.$scope.energyNotes = this.sortEnergyNotes();
                this.$scope.chartConfig = this.buildChartConfig();
                this.$scope.tableData = this.buildTableData();
                this.$rootScope.$broadcast('highchartRedraw', this.$scope.chartConfig);
                if (this.$scope.periodSearch.interval.value === '1mon') {
                    return this.WeatherNormalizationService.getTargetModel(this.currentBuilding, 'ELECTRICITY', this.$scope.periodSearch, this.account);
                }
                this.$scope.showActuals = true;
            }).then((targetModel) => {
                if (targetModel) {
                    this.$scope.questionModel = targetModel;
                }
            }).finally(() => {
                this.enableWeatherToast();
                this.loading.stop();
            });
        }

        public comparePreviousYear() {
            if (this.$scope.comparePreviousYear) {
                let trendStart = moment(this.$scope.periodSearch.start).subtract(1, 'year');
                let trendEnd = moment(trendStart).add(1, 'year');
                this.$scope.chart.showLoading();
                this.$q.all([
                    this.EnergyInsightsDataService.getEnergy(this.currentBuilding, trendStart, trendEnd, this.metric, '1mon', this.account),
                    this.EnergyInsightsDataService.getWeatherNormalizedEnergy(this.currentBuilding, trendStart, trendEnd, this.metric, '1mon', this.account),
                    this.EnergyInsightsDataService.getTemperatures(this.currentBuilding, trendStart, trendEnd, this.$scope.temperatureUnit, '1mon', this.account)
                ]).then((results) => {
                    this.previousYearEnergy = results[0];
                    this.previousYearNormalized = results[1];
                    this.previousYearTemperature = results[2];

                    let previousActuals = this.$scope.chart.addSeries(this.buildPreviousYearEnergySeries(this.previousYearEnergy));
                    if (!this.$scope.showActuals) {
                        previousActuals.hide();
                    }

                    let previousNormalized = this.$scope.chart.addSeries(this.buildPreviousYearNormalizedSeries(this.previousYearNormalized));
                    if (!this.$scope.showWeatherNormalization) {
                        previousNormalized.hide();
                    }

                    let previousYearTemperatures = this.$scope.chart.addSeries(this.buildPreviousYearTemperature(this.previousYearTemperature));
                    if (!this.$scope.showTemperatures) {
                        previousYearTemperatures.hide();
                    }
                    else {
                        previousYearTemperatures.show();
                    }

                    this.$scope.chart.hideLoading();
                    this.$scope.tableData = this.buildTableData();
                });
            } else {
                _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.PREVIOUS_YEAR_ENERGY_SERIES_NAME }).remove();
                _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.PREVIOUS_YEAR_NORMALIZED_SERIES_NAME }).remove();
                _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.PREVIOUS_YEAR_TEMP_SERIES_NAME }).remove();
                this.$scope.tableData = this.buildTableData();
                this.$scope.chart.redraw();
            }
        }

        public showTemperatures() {
            let currentYearTemperatureSeries = _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.CURRENT_YEAR_TEMP_SERIES_NAME });
            this.toggleSeries(currentYearTemperatureSeries);
            let previousYearTemperatureSeries = _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.PREVIOUS_YEAR_TEMP_SERIES_NAME });
            this.toggleSeries(previousYearTemperatureSeries);
            let climateNormalSeries = _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.CLIMATE_NORMAL_SERIES_NAME });
            this.toggleSeries(climateNormalSeries);
        }

        public showWeatherNormalization() {
            let currentYearNormalized = _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.CURRENT_YEAR_NORMALIZED_SERIES_NAME });
            this.toggleSeries(currentYearNormalized);
            let previousYearNormalized = _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.PREVIOUS_YEAR_NORMALIZED_SERIES_NAME });
            this.toggleSeries(previousYearNormalized);
        }

        public showActuals() {
            let currentYearActuals = _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.CURRENT_YEAR_ENERGY_SERIES_NAME });
            this.toggleSeries(currentYearActuals);
            let previousYearActuals = _.find(this.$scope.chart.series, { name: WeatherNormalizationCtrl.PREVIOUS_YEAR_ENERGY_SERIES_NAME });
            this.toggleSeries(previousYearActuals);
        }

        public enableWeatherToast() {
            if (this.$scope.disableWeatherNormalization) {
                this.$scope.showToast = true;
            }
        }

        public downloadCsv() {
            let csvContent = 'data:text/csv;charset=utf-8,';
            let fileName = '';
            csvContent += `Date,Actual Consumption ${this.metric.unit}, Normalized Consumption ${this.metric.unit}, Temperature ${this.$scope.temperatureUnit.unit}, Climate Normal Temperature ${this.$scope.temperatureUnit.unit}\n`;
            this.$scope.tableData.forEach((row: Row) => {
                csvContent += `${moment(row.actual.timestamp).format('MMM YYYY')}, ${this.convertValueForDisplay(row.actual)}, ${this.convertValueForDisplay(row.normalized)}, ${this.convertValueForDisplay(row.temperature)}, ${this.convertValueForDisplay(row.temperature, 'climateNormal')}\n`;
            });

            csvContent += `Total,${this.$scope.totals.currentTotal},${this.$scope.totals.currentTotalNormalized},${this.$scope.totals.currentTemperature},${this.$scope.totals.currentTemperatureNormalized}\n`;

            fileName = `${this.$scope.currentYear}_WeatherNormalization_Report.csv`;
            let link = document.createElement('a');
            link.setAttribute('href', encodeURI(csvContent));
            link.setAttribute('download', fileName);
            link.click();
        }

        private goToOptimization() {
            this.account.get({ single: true }).then((thisAccount) => {
                let optimizationURI = URI('/accounts/' + thisAccount.id + '/optimization/building')
                    .search({
                        unit: 'KWH',
                        apiUnit: 'ENERGY',
                        interval: '1mon',
                        off: _.without(thisAccount.buildings, this.currentBuilding.id).join(','),
                        start: this.$scope.periodSearch.start.tz(this.currentBuilding.timeZoneId).format(),
                        end: this.$scope.periodSearch.end.tz(this.currentBuilding.timeZoneId).format(),
                        children: 'buildings'
                    });
                this.$window.location.href = optimizationURI.toString();
            });
        }

        public convertValueForDisplay(val, valueField = 'value') {
            if (!val || !val[valueField]) {
                return '-';
            }
            return val[valueField];
        }

        private toggleSeries(series: HighchartsSeriesObject) {
            if (!series) return;
            if (series.visible) {
                series.hide();
            } else {
                series.show();
            }
        }

        private sortEnergyNotes(): { month?: string, notes?: aq.common.models.EnergyNote[] } {
            let notes = {};
            for (let i = 0; i < this.$scope.currentTemperatures.length; i++) {
                let startOfMonth = moment(this.$scope.currentTemperatures[i].timestamp).tz(this.currentBuilding.timeZoneId).startOf('month');
                let endOfMonth = moment(this.$scope.currentTemperatures[i].timestamp).tz(this.currentBuilding.timeZoneId).endOf('month');
                notes[moment(this.$scope.currentTemperatures[i].timestamp).format('MMM')] = this.energyNotes.filter((note) => {
                    let noteStartDate = moment(note.dataContext.startDate);
                    let noteEndDate = moment(note.dataContext.endDate)
                    return ((noteStartDate.isAfter(startOfMonth) && noteStartDate.isBefore(endOfMonth)) ||
                        (noteEndDate.isAfter(startOfMonth) && noteStartDate.isBefore(endOfMonth)));
                });
            }
            return notes;
        }

        private buildTableData(): Row[] {
            let rows = [];
            this.$scope.totals = new Totals();

            let length = (this.$scope.currentNormalized && this.$scope.currentNormalized.length > 0) ? this.$scope.currentNormalized.length : this.$scope.currentEnergy.length;
            for (let i = 0; i < length; i++) {
                if (this.$scope.comparePreviousYear) {
                    if (this.previousYearEnergy[i]) {
                        rows.push(new Row(this.previousYearEnergy[i], this.previousYearNormalized[i], this.previousYearTemperature[i]));
                        this.$scope.totals.previousTotal += this.previousYearEnergy[i] ? this.previousYearEnergy[i].value : 0;
                        this.$scope.totals.previousTotalNormalized += this.previousYearNormalized[i] ? this.previousYearNormalized[i].value : 0;
                    }
                    else {
                        rows.push(new Row(null, null, null));
                    }
                }
                rows.push(new Row(this.$scope.currentEnergy[i], this.$scope.currentNormalized[i], this.$scope.currentTemperatures[i]));
                this.$scope.totals.currentTotal += this.$scope.currentEnergy[i] ? this.$scope.currentEnergy[i].value : 0;
                this.$scope.totals.currentTotalNormalized += this.$scope.currentNormalized[i] ? this.$scope.currentNormalized[i].value : 0;

                //push temperature data if exists
                if (this.$scope.currentTemperatures[i]) {
                    this.$scope.totals.currentTemperature += this.$scope.currentTemperatures[i].value ? this.$scope.currentTemperatures[i].value : 0;
                    this.$scope.totals.currentTemperatureNormalized += this.$scope.currentTemperatures[i].climateNormal ? this.$scope.currentTemperatures[i].climateNormal : 0;
                }
            }
            this.$scope.insightAnswer = (this.$scope.totals.currentTotalNormalized > 0) ? this.$scope.totals.currentTotalNormalized : this.$scope.totals.currentTotal;
            this.$scope.totals.currentTemperature = this.$scope.totals.currentTemperature / this.$scope.currentTemperatures.length;
            this.$scope.totals.currentTemperatureNormalized = this.$scope.totals.currentTemperatureNormalized / this.$scope.currentTemperatures.length;

            return rows;
        }

        private buildChartConfig(): any {
            let energyNotes = this.$scope.energyNotes;
            return {
                lang: {
                    drillUpText: '< Back to previous selection',
                    noData: 'No consumption data to display.'
                },
                noData: {
                    position: { 'x': 0, 'y': 0, 'align': 'center', 'verticalAlign': 'middle' }
                },
                chart: {
                    plotBorderWidth: 1,
                },
                title: '',
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    formatter: function () {
                        return `<strong>${this.series.name}</strong> <br> ${this.point.tooltip}`;
                    }
                },
                xAxis: {
                    categories: this.months,
                    type: 'category',
                    tickLength: 0,
                    labels: {
                        rotation: 0,
                        events: {
                            click: (ev) => {
                                // this doesnt work anymore!
                                let month = _.find(ev.target.attributes, { name: 'month' })['value'];
                                //assume there is an * at the end if there are energy notes
                                if (this.$scope.energyNotes[month]) {
                                    this.$mdDialog.show({
                                        /* @ngInject */
                                        controller: ($scope, energyNotes) => {
                                            $scope.energyNotes = energyNotes;
                                            $scope.cancel = () => {
                                                this.$mdDialog.cancel();
                                            }
                                        },
                                        templateUrl: 'app/energyInsights/weatherNormalization/energyNotesDialog.html',
                                        parent: angular.element(document.body),
                                        locals: {
                                            energyNotes: this.$scope.energyNotes[month]
                                        },
                                        clickOutsideToClose: true
                                    });
                                }
                            }
                        },
                        formatter: function () {
                            let month = this.value;
                            if (energyNotes[month] && energyNotes[month].length > 0) {
                                return `<span month='${month}'>${month}<br><span month='${month}' class="md-badge md-primary">${energyNotes[month].length}</span>`;
                            } else {
                                return month;
                            }
                        },
                        useHTML: true
                    }
                },
                yAxis: [{
                    min: 0,
                    title: {
                        text: `Energy Consumption (${this.$scope.metric.unit})`
                    }
                }, {
                    title: {
                        text: 'Temperature (' + this.$scope.temperatureUnit.unit
                    },
                    opposite: true
                }],
                series: this.getChartSeries()
            }
        }

        private getChartSeries() {
            if (this.$scope.disableWeatherNormalization) {
                return [this.buildCurrentYearEnergySeries(this.$scope.currentEnergy),
                this.buildCurrentYearTemperatureSeries(this.$scope.currentTemperatures),
                this.buildClimateNormalSeries(this.$scope.currentTemperatures)]
            } else {
                return [this.buildCurrentYearEnergySeries(this.$scope.currentEnergy),
                this.buildCurrentYearNormalizedSeries(this.$scope.currentNormalized),
                this.buildCurrentYearTemperatureSeries(this.$scope.currentTemperatures),
                this.buildClimateNormalSeries(this.$scope.currentTemperatures)];
            }
        }

        private buildPreviousYearEnergySeries(previousYearEnergy: aq.energyInsights.service.DataValue[]): HighchartsBarChartSeriesOptions {
            const color = '#AA3C9B';
            return {
                id: 'previousYearEnergy',
                name: WeatherNormalizationCtrl.PREVIOUS_YEAR_ENERGY_SERIES_NAME,
                type: 'column',
                color,
                legendIndex: 5,
                zIndex: 1,
                data: _.take(this.convertToDataPointsAndCheckForIntervalData(previousYearEnergy, this.$scope.metric, color), 12)
            }
        }

        private buildPreviousYearNormalizedSeries(previousYearNormalized: aq.energyInsights.service.DataValue[]) {
            return {
                id: 'previousYearNormalized',
                name: WeatherNormalizationCtrl.PREVIOUS_YEAR_NORMALIZED_SERIES_NAME,
                type: 'column',
                color: '#C9B1E2',
                legendIndex: 6,
                zIndex: 1,
                data: _.take(this.convertToDataPoints(previousYearNormalized, this.$scope.metric), 12)
            }
        }

        private buildPreviousYearTemperature(previousYearTemperatures: aq.energyInsights.service.DataValue[]) {
            return {
                id: 'previousYearTemperatures',
                name: WeatherNormalizationCtrl.PREVIOUS_YEAR_TEMP_SERIES_NAME,
                type: 'line',
                color: '#AA3C9B',
                legendIndex: 7,
                zIndex: 2,
                visible: false,
                yAxis: 1,
                data: _.take(this.convertToDataPoints(previousYearTemperatures, this.$scope.temperatureUnit), 12)
            }
        }

        private buildCurrentYearEnergySeries(currentYearEnergy: aq.energyInsights.service.DataValue[]): HighchartsBarChartSeriesOptions {
            const color = '#7ACE46';
            return {
                id: 'currentYearEnergy',
                name: WeatherNormalizationCtrl.CURRENT_YEAR_ENERGY_SERIES_NAME,
                type: 'column',
                color,
                legendIndex: 3,
                zIndex: 1,
                data: _.take(this.convertToDataPointsAndCheckForIntervalData(currentYearEnergy, this.$scope.metric, color), 12)
            }
        }

        private buildCurrentYearNormalizedSeries(currentYearNormalized: aq.energyInsights.service.DataValue[]): HighchartsBarChartSeriesOptions {
            return {
                id: 'currentYearNormalized',
                name: WeatherNormalizationCtrl.CURRENT_YEAR_NORMALIZED_SERIES_NAME,
                type: 'column',
                color: '#C2DCC2',
                legendIndex: 4,
                zIndex: 1,
                data: _.take(this.convertToDataPoints(currentYearNormalized, this.$scope.metric), 12)
            }
        }

        private buildCurrentYearTemperatureSeries(currentYearTemperatures: aq.energyInsights.service.DataValue[]): HighchartsLineChartSeriesOptions {
            return {
                id: 'currentYearTemperature',
                name: WeatherNormalizationCtrl.CURRENT_YEAR_TEMP_SERIES_NAME,
                type: 'line',
                color: '#7ACE46',
                legendIndex: 1,
                visible: false,
                yAxis: 1,
                zIndex: 2,
                data: _.take(this.convertToDataPoints(currentYearTemperatures, this.$scope.temperatureUnit), 12)
            }
        }

        private buildClimateNormalSeries(climateNormalTemperatures: aq.energyInsights.service.TempDataValue[]): HighchartsLineChartSeriesOptions {
            return {
                id: 'climateaNormalTemperature',
                name: WeatherNormalizationCtrl.CLIMATE_NORMAL_SERIES_NAME,
                type: 'line',
                color: '#F2A91D',
                dashStyle: 'longdash',
                legendIndex: 2,
                yAxis: 1,
                visible: false,
                zIndex: 2,
                data: _.take(this.convertToDataPoints(climateNormalTemperatures, this.$scope.temperatureUnit, 'climateNormal'), 12)
            }
        }

        private convertToDataPoints(dataValues: aq.energyInsights.service.DataValue[], metric, valueField = 'value'): HighchartsDataPoint[] {
            return dataValues.map((energyValue: aq.energyInsights.service.DataValue) => {
                return {
                    y: energyValue[valueField],
                    timestamp: energyValue.timestamp,
                    tooltip: `${moment(energyValue.timestamp).format('MMM YYYY')} - ${this.$filter('number')(energyValue[valueField], '0,0')} ${metric.unit}`
                }
            });
        }

        private convertToDataPointsAndCheckForIntervalData(dataValues: aq.energyInsights.service.DataValue[], metric, color, valueField = 'value'): HighchartsDataPoint[] {
            return dataValues.map((energyValue: aq.energyInsights.service.DataValue) => {
                let tooltip = `${moment(energyValue.timestamp).format('MMM YYYY')} - ${this.$filter('number')(energyValue[valueField], '0,0')} ${metric.unit}`;
                tooltip += energyValue.isMissingData ? '<br><div style="color:red">This building uses data directly <br><div style="color:red">from your utility company.<br><br><i>Some data has not been received yet.' : '';
                return {
                    y: energyValue[valueField],
                    timestamp: energyValue.timestamp,
                    tooltip,
                    color: energyValue.isMissingData ? this.EnergyInsightsDataService.transparentColor(color) : color
                }
            });
        }

    }

    export interface WeatherNormalizationCtrlScope extends ng.IScope {
        questionModel: aq.energyInsights.QuestionAnswerModel;
        comparePreviousYear: boolean;
        chartConfig: HighchartsOptions;
        chart: HighchartsChartObject;
        tableData: Row[];
        temperatureUnit: any;
        measureUnit: string;
        energyNotes: { month?: string, notes?: aq.common.models.EnergyNote[] }
        previousYear: number;
        currentYear: number;
        metric: any;
        periodSearch: PeriodSearch;
        insightAnswer: number;
        getPercent: Function;
        totals: Totals;
        showWeatherNormalization: boolean;
        disableWeatherNormalization: boolean;
        showActuals: boolean;
        showTemperatures: boolean;
        currentEnergy: any,
        currentNormalized: any,
        currentTemperatures: any,
        trendEnergy: any,
        trendNormalized: any,
        trendTemperatures: any,
        showToast: boolean;
        toastMessage: string;
        timezone: string;
    }

    export class Row {
        constructor(public actual: aq.energyInsights.service.DataValue, public normalized: aq.energyInsights.service.DataValue, public temperature: aq.energyInsights.service.TempDataValue) { }
    }

    export class Totals {
        previousTotal: number;
        previousTotalNormalized: number;
        currentTotal: number;
        currentTotalNormalized: number;
        currentTemperature: number;
        currentTemperatureNormalized: number;
        corey: boolean;

        constructor() {
            this.previousTotal = 0;
            this.previousTotalNormalized = 0;
            this.currentTotal = 0;
            this.currentTotalNormalized = 0;
            this.currentTemperature = 0;
            this.currentTemperatureNormalized = 0;
            this.corey = false;
        }
    }

    angular.module('energyInsights').controller('WeatherNormalizationCtrl', aq.energyInsights.WeatherNormalizationCtrl);
}
