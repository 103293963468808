namespace aq.models.interval {
    export class IntervalDataSyncResponse {
        public status: MeterStatus;
        public startTimestamp: string;
        public endTimestamp: string;
        public realtime: number;
        public interval: number;
        public percentageDifference: number;
    }

    export enum MeterStatus {
        NOT_SYNCED = 'NOT_SYNCED',
        NOT_INTERVAL_METER = 'NOT_INTERVAL_METER',
        SYNCED_WITH_REALTIME = 'SYNCED_WITH_REALTIME',
        ERROR = 'ERROR'
    }

    export class IntervalDataSyncObject {
        public timestamp: number;
        public interval: number;
        public realtime: number;
        public percentageDifference: number;
    }
}
