namespace aq.dashboard.widgets {
    class _AidIssueHistory {

        public issue: aq.models.aidIssue.IssueResponse;
        public historyList: aq.models.aidIssue.IssueHistoryItem[] = null;
        public userList: aq.user.UserMentionResponse[] = null;
        private dateFormat = 'ddd MMM D, YYYY [at] h:mma';

        /* @ngInject */
        constructor(
            private RestangularV3: restangular.IService
        ) {
        }

        public $onInit() {
            this.initialize();
        }

        public $onChanges(changes) {
            if (changes.issue) {
                this.issue = angular.copy(changes.issue.currentValue);
                this.initialize();
            }
        }

        public initialize() {
            if (!this.issue) return;
            this.setUserList();
            this.setHistoryList();
        }

        public setUserList() {
            if (!this.issue.feedback || this.issue.feedback.length <= 0) {
                this.userList = null;
                this.setHistoryList();
                return;
            }

            const userIdParams = Array.from(new Set(this.issue.feedback.map(f => `userId=${f.user}`))).join('&');
            this.RestangularV3.one('users')
            .customGET(`mention?${userIdParams}`)
            .then((result: aq.user.UserMentionResponse[]) => {
                this.userList = result;
                this.setHistoryList();
            });
        }

        public setHistoryList() {
            this.historyList = [];
            if (this.issue.feedback && this.issue.feedback.length > 0 && this.userList) {
                this.issue.feedback.map(f => {
                    const action = (f.actionType == aq.models.aidIssue.ActionType.ACKNOWLEDGE ? 'acknowledged' : 'changed');
                    const cause = (f.actionType == aq.models.aidIssue.ActionType.ACKNOWLEDGE ? this.issue.potentialCause : f.potentialCause);
                    const user = this.userList && this.userList.filter(u => u.user === f.user);
                    const userMention = (user && user[0]) ? user[0].mention : '{user removed}';

                    this.historyList.push({
                        date: moment(f.created),
                        username: userMention,
                        content: `${action} the cause to be ${cause}`
                    });
                });
            }

            this.historyList.push({
                date: moment(this.issue.date),
                username: 'Aquicore',
                content: `detected ${this.getFormattedEventType()} and determined the potential cause to be ${this.issue.potentialCause}`
            });
        }

        public getFormattedEventType(): string {
            return _.startCase(_.lowerCase(this.issue.eventType));
        }

        public getFormattedDate(date: moment.Moment | string): string {
            if (typeof date === 'string') {
                return moment(date).format(this.dateFormat);
            }
            return date.format(this.dateFormat);
        }
    }

    export const AidIssueHistory: ng.IComponentOptions = {
        controller: _AidIssueHistory,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/common/widgets/aidIssue/aidIssueHistory.html',
        bindings: {
            issue: '<'
        }
    };

    angular
        .module('aq.dashboard.widgets')
        .component('aidIssueHistory', AidIssueHistory);
}
