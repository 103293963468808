'use strict';

angular
    .module('accounts')
    .filter('readingClass', function () {
        return function (reading, startDate, isCurrentWeek) {
            var date = moment(startDate).add(reading.index, 'days');
            if (isCurrentWeek) {
                if (moment().isSame(date, 'day')) {
                    return 'aq-icons-dots current';
                } else if (moment().isBefore(date)) {
                    return 'aq-icons-dots future';
                } else {
                    if (!reading.reading) {
                        return 'aq-icons-delete';
                    }
                    return 'aq-icons-approved';
                }
            } else {
                if (!reading.reading) {
                    return 'aq-icons-delete';
                }
                return 'aq-icons-approved';
            }
        }
    });