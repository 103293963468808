namespace aq.propertySettings {
    export class TargetNewCtrl extends aq.common.Controllers.ModalCtrl {
        /* @ngInject */
        constructor(
            protected $scope: TargetNewCtrlScope,
            private target: Target,
            private measures: aq.common.models.Measure[],
            private building: aq.common.models.Building,
            private Restangular: restangular.IService,
            protected $mdDialog: ng.material.IDialogService,
            private $filter: any,
            private months: any,
            private years: any,
            private types: any[],
            private Messages,
            private $state,
            private Errors,
            private DataStore: aq.common.DataStore,
            private Segment: aq.services.SegmentService,
        ) {
            super($scope, $mdDialog);
            this.$scope.target = target;
            this.$scope.months = months;
            this.$scope.years = years;
            this.$scope.measures = measures;
            this.$scope.targetRequest = new TargetRequest(this.target, building);
            this.$scope.types = types;
            // TODO @canRecommend: for budgets, check if we have enough utility bill info in order to do a recommendation
            this.$scope.canRecommend = false;
            if (!this.$scope.canRecommend) {
                this.$scope.targetRequest.recommendTargets = false;
            }
        }

        public prepareTarget() {
            this.$scope.targetRequest.startDate = this.constructStartDate(this.$scope.targetRequest.month, this.$scope.targetRequest.year);
            const typeLabel: any = _.find(this.$scope.types, { value: this.$scope.target.type });
            this.$scope.target.measure = _.find(this.$scope.measures, { name: 'electricity' }).id; // assume electricitiy for now
            let measureLabel = '';
            if (this.$scope.target.type !== 'ENERGY_STAR') {
                measureLabel = this.$filter('text')(_.find(this.$scope.measures, { id: this.$scope.target.measure })['name'], 'capitalize') + ' ';
            }
            this.$scope.target.name = `${this.$scope.targetRequest.year} ${measureLabel}${typeLabel.label}`;
            delete this.$scope.targetRequest.month;
        }

        public save() {
            this.prepareTarget();
            return this.DataStore.create(this.building.all('targets'), this.$scope.targetRequest)
                .then((target) => {
                    return this.DataStore.get(this.building.one('targets', target.id), { inflate: 'measure,targetItems', single: true }, true);
                })
                .then((target) => {
                    this.Messages.success('Target created successfully.');
                    this.$state.go('aq.properties.buildings.targets.details', { targetId: target.id });
                }, (error) => {
                    this.Messages.error('Annual target already exists.');
                });
        }

        public prepareBudgetTarget() {
            this.$scope.targetRequest.startDate = this.constructStartDate(this.$scope.targetRequest.month, this.$scope.targetRequest.year);
            const typeLabel: any = _.find(this.$scope.types, { value: 'BUDGET' });
            this.$scope.targetRequest.target.type = typeLabel.value;
            const measureLabel: any = this.$filter('text')(_.find(this.$scope.measures, { id: this.$scope.target.measure })['name'], 'capitalize');
            this.$scope.target.name = `${this.$scope.targetRequest.year} ${measureLabel} ${typeLabel.label}`;
            delete this.$scope.targetRequest.month;
            this.$scope.measureLabel = measureLabel;
        }

        public budgetSave() {
            this.Segment.trackEvent('Budgets:Create Budget');
            this.prepareBudgetTarget();
            return this.DataStore.create(this.building.all('budgets'), this.$scope.targetRequest,{utility: this.$scope.measureLabel,
                year: this.$scope.targetRequest.year, recommendTargets: this.$scope.targetRequest.recommendTargets})
                .then(target => {
                    this.$scope.target = target;
                    return this.DataStore.getList(this.Restangular.one('accounts', this.building.account).one('buildings', this.building.id),
                        'budgets', { inflate: 'targetItems,measure' });
                }).then(budgets =>{
                    this.$scope.targets = budgets;
                    this.$state.go('.details', { targetId: this.$scope.target.id });
                }, error => {
                    this.Messages.error('Annual budget already exists.');
                });
        }

        private constructStartDate(month: string, year: number): string {
            if (!month || !year) return null;
            return moment.utc().year(year).startOf('year').month(month).startOf('month').format('YYYY-MM-DD');
        }
    }

    export interface TargetNewCtrlScope extends ng.IScope {
        target: Target;
        year: any;
        years: any;
        months: any;
        month: any;
        measures: aq.common.models.Measure[];
        targetRequest: TargetRequest;
        types: string[];
        canRecommend: boolean;
    }

    class TargetRequest {
        startDate: Date | moment.Moment | string;
        total: number;
        year: number;
        month: string;
        recommendTargets: boolean;
        constructor(public target: Target, building: aq.common.models.Building) {
            this.total = 0;
            this.startDate = null;
            this.recommendTargets = true;
            if (building.fiscalStartMonth) {
                this.month = moment.months(building.fiscalStartMonth - 1);
            } else {
                this.month = 'January';
            }
            this.year = parseInt(moment().format('YYYY'));
        }
    }

    angular.module('properties').controller('TargetNewCtrl', aq.propertySettings.TargetNewCtrl);
}
