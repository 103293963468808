namespace aq.dashboard.widgets {
    
    export function MeasureFormats() {
        return function (drilldown, measure) {
            let formats = [];
            if(measure == 'POWER'){
                //demand charges look for electricty measure
                measure = 'ELECTRICITY';
            }
            drilldown.forEach(function(format) {
                if(format.metrics.indexOf(measure) >= 0){
                    formats.push(format);
                }
            });
            return formats;
        }
    };

    angular.module('aq.filters').filter("measureFormats", MeasureFormats);
}