'use strict';

angular.module('properties').directive('singleChildAssignment', function (Auth) {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {
            model: '=',
            uses: '=',
            update: '&',
            sources: '=',
            connector: '='
        },
        templateUrl: 'app/properties/sources/details/common/singleChildAssignment/singleChildAssignment.html',
        controller($scope) {
            $scope.isReadonly = !Auth.check({ access: 'EDIT' });
        }
    }
});
