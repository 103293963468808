angular
    .module('aq.utilityBudgets', ['ngAnimate', 'aq.forms', 'aq.services', 'aq.services.options', 'aq.auth', 'aq.ui', 'aq.ui.loading', 'aq.binaryfilereader'])
    .config((RestangularProvider: restangular.IProvider, $stateProvider: ng.ui.IStateProvider) => {
        $stateProvider
            .state('aq.utilityBudgets', {
                url: '/accounts/:accountId/buildings/:buildingId/budget',
                templateUrl: 'app/utilityBudgets/main.html',
                controller: 'BudgetMainCtrl as vm',
                data: {
                    appName: 'Budgets'
                },
                resolve: {
                    accountId($stateParams: ng.ui.IStateParamsService) {
                        return $stateParams.accountId;
                    },
                    account(waitForAuthToken, RestangularV3: restangular.IService, accountId) {
                        return RestangularV3.one('accounts', accountId).get();
                    },
                    building(waitForAuthToken, RestangularV3: restangular.IService, $stateParams: ng.ui.IStateParamsService,
                        Segment: aq.services.SegmentService, UserService: aq.services.UserService) {
                        if (!$stateParams.buildingId) {
                            return RestangularV3.all('buildings').getList().then((response) => {
                                return _.first(response);
                            });
                        }
                        return RestangularV3.one('buildings', $stateParams.buildingId).get();
                    },
                    measures(waitForAuthToken, RestangularV3: restangular.IService) {
                        return RestangularV3.all('measures').getList()
                            .then((measures: aq.common.models.Measure[]) => {
                                return _.filter(measures, (measure: aq.common.models.Measure) => {
                                    return _.includes(['electricity', 'water', 'gas', 'steam', 'heat'], measure.name);
                                });
                            });
                    },
                    isMockedData() {
                        const enabledMocks = window['enabledMocks'] ? window['enabledMocks'].split(',') : [];
                        return _.includes(enabledMocks, 'utilitySpending');
                    },
                    options(OptionsService, account: aq.common.models.Account) {
                        return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
                    },
                    temperatureUnit(options, OptionsService) {
                        return OptionsService.temperatureUnit();
                    },
                    currencyUnitSymbol(options, OptionsService) {
                        return OptionsService.currencyUnit().symbol;
                    },
                    measureUnitMap(options, DashboardOptionsService: aq.dashboard.DashboardOptionsService) {
                        const allNonConvertedUnits = DashboardOptionsService.getUnits(false);
                        const apiUnits = ['energy', 'gas', 'heat_transfer', 'steam_mass', 'water'];
                        const blendedRateUnits = _.filter(allNonConvertedUnits, (unit) => {
                            return !unit.isIntensity
                                && unit.isDefault
                                && _.some(apiUnits, (apiUnit) => apiUnit.toLowerCase() == unit.apiUnit.toLowerCase());
                        });
                        const result = {};
                        _.each(blendedRateUnits, (unit) => {
                            const measure = unit.serviceType ? unit.serviceType : unit.apiUnit;
                            result[measure] = unit.unit;
                        });
                        return result;
                    },
                    onEnter: (
                        Segment: aq.services.SegmentService,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) => {
                        Segment.trackChurnZeroEvent('VIEW_BUDGETS', [$stateParams.buildingId]);
                    }
                }
            })
            .state('aq.utilityBudgets.home', {
                url: '/main?measure',
                templateUrl: 'app/utilityBudgets/home/budgetHome.html',
                controller: 'BudgetHomeCtrl as vm',
                resolve: {
                    budgetMeasure($stateParams: ng.ui.IStateParamsService) {
                        if (!$stateParams.measure) {
                            return 'electricity';
                        }
                        return $stateParams.measure;
                    }
                },
                onEnter: (Segment: aq.services.SegmentService) => {
                    Segment.trackPage('Utility Budgets: Home');
                }
            })
            .state('aq.utilityBudgets.settings', {
                url: '/settings',
                templateUrl: 'app/utilityBudgets/settings/budgetSettings.html',
                controller: 'BudgetSettingsCtrl as vm',
                resolve: {
                    users(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        account: aq.common.models.Account,
                        waitForAuthToken
                    ) {
                        return DataStore.getList(RestangularV3.one(''), 'users', { accountId: account.id });
                    },
                    settings(RestangularV3: restangular.IService, building, waitForAuthToken) {
                        return RestangularV3.one('buildings', building.id).customGET('budget-settings');
                    }
                },
                onEnter: (Segment: aq.services.SegmentService) => {
                    Segment.trackPage('Utility Budgets:Settings');
                }
            })
            .state('aq.utilityBudgets.year', {
                url: '/year?year&measure',
                templateUrl: 'app/utilityBudgets/year/yearBudget.html',
                controller: 'YearBudgetCtrl as vm',
                resolve: {
                    budgetYear(building: aq.common.models.Building, $stateParams: ng.ui.IStateParamsService) {
                        if (!$stateParams.year) {
                            const currentMonth = moment().month() + 1;
                            let budgetYear = moment().year();
                            const fiscalStartMonth = building.fiscalStartMonth || 1;
                            if (currentMonth < fiscalStartMonth) {
                                budgetYear--;
                            }
                            return budgetYear;
                        }
                        return $stateParams.year;
                    },
                    budgetMeasure($stateParams: ng.ui.IStateParamsService) {
                        if (!$stateParams.measure) {
                            return 'electricity';
                        }
                        return $stateParams.measure;
                    },
                    dataQueryParams(budgetYear: string, budgetMeasure: string, building: aq.common.models.Building) {
                        const month = building.fiscalStartMonth || 1;
                        const startDate = moment(`${budgetYear} ${month} 15`, 'YYYY M D').tz(building.timeZoneId).startOf('months');
                        const endDate = moment(startDate).add(1, 'year').subtract(1, 'second');
                        return {
                            start: startDate.format(`YYYY-MM-DDTHH:mm:ssZ`),
                            end: endDate.format(`YYYY-MM-DDTHH:mm:ssZ`),
                            interval: '1mon',
                            measure: budgetMeasure,
                            startDate: startDate.format(`YYYY-MM-DD`),
                            endDate: endDate.format(`YYYY-MM-DD`)
                        };
                    },
                    buildingSpending(
                        RestangularV3: restangular.IService,
                        building: aq.common.models.Building,
                        dataQueryParams: aq.utilityBudgets.MonthDataQueryParams,
                        waitForAuthToken
                    ) {
                        return RestangularV3
                            .all('utility-spending')
                            .customGET('', angular.extend(dataQueryParams, { buildingId: building.id }))
                            .then((response: aq.utilityBudgets.UtilitySpendingCumulativeResponse) => {
                                return response;
                            });
                    },
                    temperature(
                        Restangular: restangular.IService,
                        account: aq.common.models.Account,
                        building: aq.common.models.Building,
                        dataQueryParams: aq.utilityBudgets.MonthDataQueryParams,
                        waitForAuthToken
                    ) {
                        return Restangular.one('accounts', account.id)
                            .one('buildings', building.id)
                            .customGET('temperature', {
                                start: dataQueryParams.start,
                                end: moment(dataQueryParams.end).add(1, 'days').format(`YYYY-MM-DDTHH:mm:ssZ`),
                                interval: dataQueryParams.interval
                            })
                            .catch(() => {
                                return {
                                    timestamps: [],
                                    values: [],
                                    climateNormals: []
                                };
                            });
                    },
                    yearBudget(
                        Restangular: restangular.IService,
                        account: aq.common.models.Account,
                        building: aq.common.models.Building,
                        dataQueryParams: aq.utilityBudgets.MonthDataQueryParams,
                        waitForAuthToken
                    ) {
                        return Restangular.one('accounts', account.id)
                            .one('buildings', building.id)
                            .all('Targets')
                            .customGET('queryTargets', {
                                startDate: dataQueryParams.start,
                                endDate: dataQueryParams.end,
                                targetType: 'BUDGET',
                                measure: dataQueryParams.measure
                            })
                            .then((response) => {
                                // response has duplicate days!
                                response = _.sortBy(response, (item) => item.id);
                                const data = {};
                                _.each(response, (item) => {
                                    data[item.startDate] = item;
                                });
                                const result = _.map(data, (value) => value);
                                return _.sortBy(result, (item) => item.startDate);
                            });
                    },
                    occupancy(
                        RestangularV3: restangular.IService,
                        building: aq.common.models.Building,
                        waitForAuthToken
                    ) {
                        return RestangularV3.one('building', building.id)
                            .customGET('buildingOccupancy');
                    }
                },
                onEnter: (Segment: aq.services.SegmentService) => {
                    Segment.trackPage('Utility Budgets:Utility Spending:Year');
                }
            })
            .state('aq.utilityBudgets.month', {
                url: '/month?year&month&measure&blendedRate',
                templateUrl: 'app/utilityBudgets/month/monthBudget.html',
                controller: 'MonthBudgetCtrl as vm',
                resolve: {
                    budgetBlendedRate($stateParams: ng.ui.IStateParamsService) {
                        if (!$stateParams.blendedRate) {
                            return null;
                        }
                        return $stateParams.blendedRate;
                    },
                    budgetYear($stateParams: ng.ui.IStateParamsService) {
                        if (!$stateParams.year) {
                            return moment().format('YYYY');
                        }
                        return $stateParams.year;
                    },
                    budgetMonth($stateParams: ng.ui.IStateParamsService) {
                        if (!$stateParams.month) {
                            return moment().format('M');
                        }
                        return $stateParams.month;
                    },
                    budgetMeasure($stateParams: ng.ui.IStateParamsService) {
                        if (!$stateParams.measure) {
                            return 'electricity';
                        }
                        return $stateParams.measure;
                    },
                    dataQueryParams(
                        budgetYear: string,
                        budgetMonth: string,
                        budgetMeasure: string,
                        budgetBlendedRate: string,
                        building: aq.common.models.Building
                    ) {
                        const startDate = moment(`${budgetYear} ${budgetMonth} 15`, 'YYYY M D').tz(building.timeZoneId).startOf('months');
                        const endDate = moment(startDate).tz(building.timeZoneId).endOf('months');
                        if (budgetBlendedRate) {
                            return {
                                start: startDate.format(`YYYY-MM-DDTHH:mm:ssZ`),
                                end: endDate.format(`YYYY-MM-DDTHH:mm:ssZ`),
                                interval: '1d',
                                measure: budgetMeasure,
                                blendedRate: budgetBlendedRate,
                                startDate: startDate.format(`YYYY-MM-DD`),
                                endDate: endDate.format(`YYYY-MM-DD`)
                            };
                        }
                        return {
                            start: startDate.format(`YYYY-MM-DDTHH:mm:ssZ`),
                            end: endDate.format(`YYYY-MM-DDTHH:mm:ssZ`),
                            interval: '1d',
                            measure: budgetMeasure,
                            startDate: startDate.format(`YYYY-MM-DD`),
                            endDate: endDate.format(`YYYY-MM-DD`)
                        };
                    },
                    activities(
                        dataQueryParams,
                        building: aq.common.models.Building,
                        ActivityService: aq.services.ActivityService
                    ) {
                        const params: aq.models.activity.ActivityQueryParams = {
                            start: moment(dataQueryParams.start).toISOString(),
                            end: moment(dataQueryParams.end).toISOString(),
                            building: Number(building.id),
                            type: [
                                aq.models.activity.ActivityType.OBSERVATION
                            ]
                        };
                        return ActivityService.getActivities(params);
                    },
                    buildingSpending(
                        RestangularV3: restangular.IService,
                        building: aq.common.models.Building,
                        dataQueryParams: aq.utilityBudgets.MonthDataQueryParams,
                        $location: ng.ILocationService,
                        waitForAuthToken
                    ) {
                        const queryData = angular.extend(dataQueryParams, { buildingId: building.id });
                        const queryParams = $location.search();
                        if (queryParams.testForDay) {
                            queryData.testForDay = queryParams.testForDay;
                        }
                        return RestangularV3
                            .all('utility-spending')
                            .customGET('', queryData)
                            .then((response: aq.utilityBudgets.UtilitySpendingCumulativeResponse) => {
                                return response;
                            });
                    },
                    temperature(
                        Restangular: restangular.IService,
                        account: aq.common.models.Account,
                        building: aq.common.models.Building,
                        dataQueryParams: aq.utilityBudgets.MonthDataQueryParams,
                        waitForAuthToken
                    ) {
                        return Restangular.one('accounts', account.id)
                            .one('buildings', building.id)
                            .customGET('temperature', {
                                start: dataQueryParams.start,
                                end: moment(dataQueryParams.end).add(1, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
                                interval: dataQueryParams.interval
                            })
                            .catch(() => {
                                return {
                                    timestamps: [],
                                    values: [],
                                    climateNormals: []
                                };
                            });
                    },
                    monthBudget(
                        Restangular: restangular.IService,
                        account: aq.common.models.Account,
                        building: aq.common.models.Building,
                        dataQueryParams: aq.utilityBudgets.MonthDataQueryParams,
                        waitForAuthToken
                    ) {
                        return Restangular.one('accounts', account.id)
                            .one('buildings', building.id)
                            .all('Targets')
                            .customGET('queryTargets', {
                                startDate: dataQueryParams.start,
                                endDate: moment(dataQueryParams.end).subtract(1, 'day').format(`YYYY-MM-DDTHH:mm:ssZ`),
                                targetType: 'BUDGET',
                                measure: dataQueryParams.measure
                            })
                            .then((response) => {
                                // TODO: check the budget endpoint, it seems that the response can contain duplicate monthly budget entries!
                                const entries = _.sortBy(response, (item) => item.id);
                                const mostRecentEntry = _.last(entries);
                                if (mostRecentEntry) {
                                    return mostRecentEntry.value;
                                }
                                return 0;
                            });
                    }
                },
                onEnter: (Segment: aq.services.SegmentService) => {
                    Segment.trackPage('Utility Budgets:Utility Spending:Month');
                }
            }).state('aq.utilityBudgets.bill', {
                url: '/bill?billId&measure',
                templateUrl: 'app/utilityBudgets/bill/billStatement.html',
                controller: 'BillStatementCtrl as vm',
                resolve: {
                    billMeasure($stateParams: ng.ui.IStateParamsService) {
                        if (!$stateParams.measure) {
                            return 'electricity';
                        }
                        return $stateParams.measure;
                    },
                    utilityServices(
                        RestangularV3: restangular.IService,
                        building: aq.common.models.Building,
                        billMeasure: string,
                        waitForAuthToken
                    ) {
                        return RestangularV3.all('utility-services')
                            .customGET('', { buildingId: building.id })
                            .then((response) => {
                                return _.filter(response, (item) => item.type.toLowerCase() == billMeasure.toLowerCase());
                            });
                    },
                    utilityBillPeriods(
                        RestangularV3: restangular.IService,
                        utilityServices: aq.utilityBudgets.UtilityService[],
                        $q: ng.IQService,
                        waitForAuthToken
                    ) {
                        // TODO: add new endpoint to get all utility bills for all the services
                        const promises = [];
                        _.each(utilityServices, (us: aq.utilityBudgets.UtilityService) => {
                            const promise = RestangularV3.all('utility-bill-periods')
                                .customGET('by-utility-service', { serviceId: us.id })
                                .then((response) => {
                                    _.each(response, (item) => {
                                        const date = `${moment(item.startDate).format('M/D/YYYY')} - ${moment(item.endDate).format('M/D/YYYY')}`;
                                        item.name = `${us.nameAbbreviated} (${date})`;
                                        item.account = `${us.name}${us.account ? ' - ' : ''}${us.account}`;
                                    });
                                    return response;
                                });
                            promises.push(promise);
                        });
                        return $q.all(promises).then((responses) => {
                            return _.chain(responses)
                                .flatten()
                                .sortBy((item) => item.startDate)
                                .reverse()
                                .value();
                        });
                    },
                    bill(utilityBillPeriods: aq.utilityBudgets.UtilityBillPeriodCharge[], $stateParams: ng.ui.IStateParamsService) {
                        let result = null;
                        if ($stateParams.billId) {
                            result = _.find(utilityBillPeriods, (ub) => ub.id == $stateParams.billId);
                        }
                        if (!result) {
                            result = _.first(utilityBillPeriods);
                        }
                        return result;
                    },
                    billBreakdown(
                        RestangularV3: restangular.IService,
                        bill: aq.utilityBudgets.UtilityBillPeriodCharge,
                        building: aq.common.models.Building,
                        billMeasure: string,
                        waitForAuthToken
                    ) {
                        if (!bill) {
                            return {};
                        }
                        return RestangularV3.all('utility-bill-analysis/for-utility-bill-period')
                            .customGET('', {
                                utilityBillId: bill.id,
                                buildingId: building.id,
                                measure: billMeasure
                            });
                    },
                    dataQueryParams(bill: aq.utilityBudgets.UtilityBillPeriodCharge, billMeasure: string, building: aq.common.models.Building) {
                        if (!bill) {
                            return null;
                        }
                        return {
                            start: moment(bill.startDate).tz(building.timeZoneId).format('YYYY-MM-DDTHH:mm:ssZ'),
                            end: moment(bill.endDate).tz(building.timeZoneId).add(1, 'day').format('YYYY-MM-DDTHH:mm:ssZ'),
                            interval: '1d',
                            measure: billMeasure
                        };
                    },
                    temperature(
                        Restangular: restangular.IService,
                        account: aq.common.models.Account,
                        building: aq.common.models.Building,
                        dataQueryParams: aq.utilityBudgets.MonthDataQueryParams,
                        waitForAuthToken
                    ) {
                        if (!dataQueryParams) {
                            return {
                                timestamps: [],
                                values: [],
                                climateNormals: []
                            };
                        }
                        return Restangular.one('accounts', account.id)
                            .one('buildings', building.id)
                            .customGET('temperature', {
                                start: dataQueryParams.start,
                                end: dataQueryParams.end,
                                interval: dataQueryParams.interval
                            })
                            .catch(() => {
                                return {
                                    timestamps: [],
                                    values: [],
                                    climateNormals: []
                                };
                            });
                    }
                },
                onEnter: (Segment: aq.services.SegmentService) => {
                    Segment.trackPage('Utility Budgets:Utility Bill Analysis:Month');
                }
            }).state('aq.utilityBudgets.serviceBills', {
                url: '/service?serviceId&measure&year',
                templateUrl: 'app/utilityBudgets/utility/utilityBills.html',
                controller: 'UtilityBillsCtrl as vm',
                resolve: {
                    billMeasure($stateParams: ng.ui.IStateParamsService) {
                        if (!$stateParams.measure) {
                            return 'electricity';
                        }
                        return $stateParams.measure;
                    },
                    billYear($stateParams: ng.ui.IStateParamsService) {
                        if (!$stateParams.year) {
                            return moment().format('YYYY');
                        }
                        return $stateParams.year;
                    },
                    utilityServices(
                        RestangularV3: restangular.IService,
                        building: aq.common.models.Building,
                        billMeasure: string,
                        waitForAuthToken
                    ) {
                        return RestangularV3.all('utility-services')
                            .customGET('', { buildingId: building.id })
                            .then((response) => {
                                return _.filter(response, (item) => item.type.toLowerCase() == billMeasure.toLowerCase());
                            });
                    },
                    billService($stateParams: ng.ui.IStateParamsService, utilityServices: aq.utilityBudgets.UtilityService[]) {
                        if (utilityServices.length == 0) {
                            return null;
                        }
                        if (!$stateParams.serviceId) {
                            return _.first(utilityServices);
                        } else {
                            const service = _.find(utilityServices, (us: aq.utilityBudgets.UtilityService) => us.id == $stateParams.serviceId);
                            if (!service) {
                                return _.first(utilityServices);
                            } else {
                                return service;
                            }
                        }
                    },
                    dataQueryParams(billYear: string, billMeasure: string, building: aq.common.models.Building) {
                        const month = building.fiscalStartMonth || 1;
                        const startDate = moment(`${billYear} ${month} 15`, 'YYYY M D').tz(building.timeZoneId).startOf('months');
                        const endDate = moment(startDate).add(1, 'year');
                        return {
                            start: startDate.format('YYYY-MM-DDTHH:mm:ssZ'),
                            end: endDate.format('YYYY-MM-DDTHH:mm:ssZ'),
                            interval: '1d',
                            measure: billMeasure
                        };
                    },
                    utilityBillAnalysisResponse(
                        RestangularV3: restangular.IService,
                        billService: aq.utilityBudgets.UtilityService,
                        billYear: string,
                        building: aq.common.models.Building,
                        billMeasure: string,
                        dataQueryParams: aq.utilityBudgets.MonthDataQueryParams,
                        waitForAuthToken
                    ) {
                        // TODO: add/update endpoint to accept fiscal year or date interval as the request parameters
                        if (billService == null) {
                            return null;
                        }
                        return RestangularV3.all('utility-bill-analysis')
                            .customGET('', {
                                buildingId: building.id,
                                start: dataQueryParams.start,
                                end: dataQueryParams.end,
                                measure: billMeasure,
                                utilityServiceId: billService.id
                            });
                    },
                    temperature(
                        Restangular: restangular.IService,
                        account: aq.common.models.Account,
                        building: aq.common.models.Building,
                        dataQueryParams: aq.utilityBudgets.MonthDataQueryParams,
                        waitForAuthToken
                    ) {
                        if (!dataQueryParams) {
                            return {
                                timestamps: [],
                                values: [],
                                climateNormals: []
                            };
                        }
                        return Restangular.one('accounts', account.id)
                            .one('buildings', building.id)
                            .customGET('temperature', {
                                start: dataQueryParams.start,
                                end: dataQueryParams.end,
                                interval: dataQueryParams.interval
                            })
                            .catch(() => {
                                return {
                                    timestamps: [],
                                    values: [],
                                    climateNormals: []
                                };
                            });
                    }
                },
                onEnter: (Segment: aq.services.SegmentService) => {
                    Segment.trackPage('Utility Budgets:Utility Bill Analysis:Year');
                }
            })
            .state('aq.utilityBudgets.accrual', {
                url: '/accruals',
                abstract: true,
                templateUrl: 'app/utilityBudgets/accrual/main.html',
                controller: 'AccrualMainCtrl as vm'
            })
            .state('aq.utilityBudgets.accrual.calculate', {
                url: '/calculate',
                templateUrl: 'app/utilityBudgets/accrual/accrual.html',
                controller: 'AccrualCtrl as vm',
                resolve: {
                    buildings(RestangularV3: restangular.IService, waitForAuthToken) {
                        return RestangularV3.all('buildings').getList();
                    },
                    buildingGroups(RestangularV3: restangular.IService, waitForAuthToken) {
                        return RestangularV3.all('building-groups').getList();
                    }
                }
            })
            .state('aq.utilityBudgets.accrual.view', {
                url: '/view',
                template: '<div>view TODO</div>'
            })
            .state('aq.utilityBudgets.accrual.settings', {
                url: '/settings',
                template: '<div>settings TODO</div>'
            });
    })
    .run(($rootScope, loading, $state: ng.ui.IStateService) => {
        const budgetStatePrefix = 'aq.utilityBudgets';
        $rootScope.$on('$stateChangeStart',
            (event, toState) => {
                const isBudgetsNavigation = toState && toState.name && toState.name.substring(0, budgetStatePrefix.length) === budgetStatePrefix;
                if ($state.includes(budgetStatePrefix) && isBudgetsNavigation) {
                    loading.start();
                }
            });
        $rootScope.$on('$stateChangeSuccess',
            () => {
                if ($state.includes(budgetStatePrefix)) {
                    loading.stop();
                }
            });
        $rootScope.$on('$stateChangeError',
            () => {
                if ($state.includes(budgetStatePrefix)) {
                    loading.stop();
                }
            });
    });
