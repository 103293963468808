namespace aq.admin.accounts.enrollment {
    interface SelectionModel {
        name: string;
        isSelected: boolean;
    }
    interface PhaseTasksModel extends PhaseTasks {
        taskSelection: SelectionModel[];
    }
    export class TaskSelectionModal {
        public phaseTasksModel: PhaseTasksModel[];
        /* ngInject */
        constructor(
            protected $mdDialog,
            private phaseTasks: PhaseTasks[],
            private selectedTasks: string[],
            private allTasksSelected: boolean
        ) {
            this.phaseTasksModel = angular.copy(this.phaseTasks as PhaseTasksModel[]);
            _.each(this.phaseTasksModel, (item: PhaseTasksModel) => {
                item.taskSelection = [];
                _.each(item.tasks, (task: TaskItems) => {
                    item.taskSelection.push({
                        name: task.name,
                        isSelected: _.indexOf(this.selectedTasks, task.name) > -1
                    });
                });
            });
        }
        hide() {
            const data = {
                selectedTasks: this.selectedTasks,
                allTasksSelected: this.allTasksSelected
            };
            this.$mdDialog.hide(data);
        }
        cancel(data?: any) {
            this.$mdDialog.cancel(data);
        }
        public updateSelectedTasks() {
            this.selectedTasks = _.chain(this.phaseTasksModel)
                .map((item: PhaseTasksModel) => item.taskSelection)
                .flatten()
                .filter((item: SelectionModel) => item.isSelected)
                .map((item: SelectionModel) => item.name)
                .value();
        }
        public onAllTasksSelected() {
            this.phaseTasksModel = this.phaseTasksModel.map((phase) => {
                phase.taskSelection = phase.taskSelection.map((task) => {
                    if (!this.allTasksSelected) {
                        if (task.isSelected) {
                            task.isSelected = false;
                        }
                    } else {
                        if (!task.isSelected) {
                            task.isSelected = true;
                        }
                    }
                    return task;
                });
                return phase;
            });
            this.updateSelectedTasks();
        }
    }
    angular.module('aq.admin.accounts.enrollment').controller('TaskSelectionModal', TaskSelectionModal);
}
