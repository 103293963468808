namespace aq.admin.accounts.enrollment {

    class CreateNewTaskCtrl {
        public newTask: EditTask;
        public taskToEdit: aq.propertySettings.TaskEnrollmentResponse;
        public phase: PhaseDisplayModel;
        public createNewTask: (object) => void;
        public editTask: (object) => void;
        public selectedBuildings: BuildingTasks[];

        /* @ngInject */
        constructor(
            private Messages: aq.services.Messages
        ) {
        }

        public $onInit() {
            if (this.taskToEdit) {
                this.newTask = _.cloneDeep(this.taskToEdit);
                this.newTask.oldName = this.taskToEdit.name;
            } else {
                this.newTask = {};
            }
        }

        public create(phase: PhaseDisplayModel, newTask: EditTask) {
            if (this.duplicateTaskName(newTask.name)) {
                this.Messages.error('A task with this name already exists');
            } else {
                this.createNewTask({phase, newTask});
            }
        }

        public edit(newTask: EditTask, currentTask: SelectionModel) {
            this.editTask({taskToEdit: newTask, currentTask});
        }

        private duplicateTaskName(taskName: string): boolean {
            let isDuplicate = false;
            this.selectedBuildings.forEach((building) => {
                if (taskName in building.taskMap) {
                    isDuplicate = true;
                }
            });
            return isDuplicate;
        }
    }

    export const CreateNewTaskComponent: ng.IComponentOptions = {
        controller: CreateNewTaskCtrl,
        controllerAs: 'vm',
        templateUrl: 'app/admin/accounts/enrollment/createNewTask.html',
        bindings: {
            phase: '<?',
            cancelCreateNewTask: '&?',
            cancelEditTask: '&?',
            createNewTask: '&?',
            selectedBuildings: '<?',
            taskToEdit: '<?',
            editTask: '&?',
            currentTask: '<?'
        }
    };

    angular.module('aq.admin.accounts.enrollment').component('createNewTaskComponent', CreateNewTaskComponent);
}
