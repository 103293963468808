namespace aq.services {
    import UserActions = aq.models.segment.UserActions;
    export class UserManagementService {

        /* ngInject */
        constructor(
            private Restangular,
            private $mdDialog,
            private $state,
            private Messages,
            private $q,
            private DataStore: aq.common.DataStore,
            private RestangularV3: restangular.IService,
            private Segment: aq.services.SegmentService) {}


        public deleteProfile(profile) {
            return profile.remove().then((resp) => {
                this.Messages.success('Deleted');
            }).catch((err) => {
                this.Messages.error('Unable to delete item');
            });
        }

        /**
         * Resend a user invitation email.
         *
         * @param {string} email
         * @param {any} account Restangular account object
         * @returns {*}
         *
         * @memberOf UserManagementService
         */
        public reinviteUser(userId, accountId): ng.IPromise<any> {
            return this.RestangularV3.one('users', userId).customPOST({}, 'resend-invitation')
            .then(() => {
                this.Messages.info('Invitation Resent');
                this.$state.reload();
            }, () => {
                this.Messages.error('Error Resending Invitation');
            });
        }

        /**
         * Invites the email addresses you give it to the current user's account.
         *
         * @param {string[]} allEmails
         * @param {any} account an account Restangular object
         * @param {any} visibilities propertyVisibilities Restangular object
         * @returns {any}
         *
         * @memberOf UserManagementService
         */
        public inviteUsers(allEmails: string[], account, roleId, visibilities, accountId, userId): ng.IPromise<any> {
            if (allEmails == null || allEmails.length == 0) {
                this.Messages.error('Enter e-mail addresses of users to invite');
                return this.$q.reject();
            }

            if (_.isNil(roleId)) {
                this.Messages.error('Please select a role');
                return this.$q.reject();
            }

            return account.customPOST({}, 'sendInvitations', {
                allEmails,
                propertyVisibilities: _.map(visibilities, 'id'),
                roleId
            }).then((data) => {
                this.Messages.info(data);
                this.$state.reload();
            }).catch((data) => {
                this.Messages.error('Error sending invitations');
            });
        }

        /**
         * Save a user profile.
         *
         * @param {any} profile A restangular Profile object
         * @returns {any}
         *
         * @memberOf UserManagementService
         */
        public saveUser(profile): ng.IPromise<any> {
            profile.hasFullVisibility = profile.visibilities.length == 0;
            const profileCopy: any = this.RestangularV3.copy(profile);
            return this.DataStore.update(profileCopy)
                .then((resp) => {
                    this.Messages.info('Saved User');
                    return resp;
                })
                .catch((error) => {
                    this.Messages.error('Error saving user profile');
                    return null;
                });
        }
    }

angular
    .module('aq.services')
    .service('UserManagementService', UserManagementService);
}
