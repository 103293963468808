var aq;
(function (aq) {
    var deviceManagement;
    (function (deviceManagement) {
        var NewReadingCtrl = /** @class */ (function (_super) {
            __extends(NewReadingCtrl, _super);
            /* @ngInject */
            function NewReadingCtrl($mdDialog, manualReading, collector, metricLabelUnit, timeZoneId) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.manualReading = manualReading;
                _this.collector = collector;
                _this.metricLabelUnit = metricLabelUnit;
                _this.timeZoneId = timeZoneId;
                _this.metricLabelUnit = metricLabelUnit;
                _this.collector = collector;
                _this.manualReading = manualReading;
                _this.maxDate = moment().toDate();
                if (!_this.manualReading.id) {
                    var readingDatetime = moment().tz(_this.timeZoneId);
                    _this.manualReading = {
                        timestamp: readingDatetime,
                        viewOnly: {
                            date: readingDatetime.toDate(),
                            hour: readingDatetime.get('hours'),
                            minute: _this.roundMinute(readingDatetime.get('minutes'))
                        },
                        value: null,
                        imageUrl: '',
                        collector: collector.id
                    };
                }
                else {
                    var readingDatetime = moment.tz(_this.manualReading.timestamp, _this.timeZoneId);
                    _this.manualReading.viewOnly = {
                        date: readingDatetime.toDate(),
                        hour: readingDatetime.get('hours'),
                        minute: readingDatetime.get('minutes')
                    };
                }
                _this.hours = _.range(0, 24);
                _this.minutes = _.range(0, 60, 5);
                return _this;
            }
            NewReadingCtrl.prototype.saveImage = function () {
                delete this.manualReading.imageThumbnailUrl;
            };
            NewReadingCtrl.prototype.roundMinute = function (minute) {
                return 5 * Math.round(minute / 5);
            };
            NewReadingCtrl.prototype.getBuildingTimeZoneAbbr = function () {
                return moment().tz(this.timeZoneId).zoneAbbr();
            };
            return NewReadingCtrl;
        }(aq.common.Controllers.ModalCtrl));
        deviceManagement.NewReadingCtrl = NewReadingCtrl;
        angular
            .module('deviceManagement')
            .controller('NewReadingCtrl', NewReadingCtrl);
    })(deviceManagement = aq.deviceManagement || (aq.deviceManagement = {}));
})(aq || (aq = {}));
