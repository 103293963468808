var aq;
(function (aq) {
    var utilityBudgets;
    (function (utilityBudgets) {
        var AddUtilityService = /** @class */ (function (_super) {
            __extends(AddUtilityService, _super);
            /* @ngInject */
            function AddUtilityService($mdDialog, RestangularV3, Errors, Messages, utilityAccount, utilityServices, UtilityServiceHelper, buildingId) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.RestangularV3 = RestangularV3;
                _this.Errors = Errors;
                _this.Messages = Messages;
                _this.utilityAccount = utilityAccount;
                _this.utilityServices = utilityServices;
                _this.UtilityServiceHelper = UtilityServiceHelper;
                _this.buildingId = buildingId;
                _this.utilityService = {
                    utilityAccount: _this.utilityAccount.id,
                    billType: utilityBudgets.BillType.UTILITY
                };
                var existingServices = _this.utilityServices.map(function (value) { return value.type; });
                _this.measureTypes = _this.UtilityServiceHelper.getMeasureTypes()
                    .filter(function (measureType) {
                    if (existingServices.indexOf(measureType.value) < 0) {
                        return true;
                    }
                });
                return _this;
            }
            AddUtilityService.prototype.cancel = function (data) {
                this.$mdDialog.cancel();
            };
            AddUtilityService.prototype.hide = function (result) {
                this.$mdDialog.hide(this.RestangularV3.copy(result));
            };
            AddUtilityService.prototype.save = function () {
                var _this = this;
                return this.RestangularV3.one('')
                    .customPOST(this.utilityService, 'utility-services', { buildingId: this.buildingId })
                    .then(function (result) {
                    _this.Messages.success('Successfully created Utility Service');
                    _this.hide(result);
                }, this.Errors.forCRUD('CREATE'));
            };
            return AddUtilityService;
        }(aq.common.Controllers.ModalCtrl));
        utilityBudgets.AddUtilityService = AddUtilityService;
        angular.module('aq.utilityBudgets').controller('AddUtilityService', AddUtilityService);
    })(utilityBudgets = aq.utilityBudgets || (aq.utilityBudgets = {}));
})(aq || (aq = {}));
