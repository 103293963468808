angular.module('aq.reports').directive('energyStatsDiff', function () {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            current: '=',
            previous: '='
        },
        templateUrl: 'app/reports/summary/common/energyStatsDiff/energyStatsDiff.html',
        controller: function ($scope) {
        }
    }
});
