namespace aq.ui {

    import AlertData = aq.models.alerts.AlertData;
    import SeverityIcon = aq.models.alerts.SeverityIcon;
    import AlertDataLevel = aq.models.alerts.AlertDataLevel;
    import LevelSeverity = aq.models.alerts.LevelSeverity;

    class _ActivityStateAlertDisplay {
        public data: AlertData;
        public stateAlert: aq.propertySettings.StateAlert;
        public noDataCallback: () => void;
        public contextLoadedCallback: () => void;
        private readonly stateUnit = aq.services.Unit.STATE;
        private building: aq.common.models.Building;
        private activity: aq.models.activity.ActivityResponse;
        private icon: {color: string, name: SeverityIcon};
        private dataLoaded: boolean;
        private resolveAndClose: boolean;

        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private $translate,
            private RestangularV3: restangular.IService,
            private resolver: aq.services.Resolver,
            private activityAlertUtilService: aq.ui.ActivityAlertUtilService
        ) { }

        public $onInit() {
            this.resolver(async () => {
                try {
                    // latest value from alert log for this issue
                    const dataPromise = this.RestangularV3
                        .one('alert-data')
                        .one('issue', this.activity.context.issue)
                        .get({ noCache: moment().valueOf() });
                    // information about this issue
                    const stateAlertPromise = this.RestangularV3
                        .one('issues', this.activity.context.issue)
                        .get({ noCache: moment().valueOf() });
                    [this.data, this.stateAlert] = await Promise.all([dataPromise, stateAlertPromise]);
                    this.icon = this.setIcon(this.data.currentLevel.severity);
                    this.contextLoadedCallback();
                } catch (err) {
                    this.noDataCallback();
                    throw err;
                } finally {
                    this.dataLoaded = true;
                }
            });
        }

        public getBuildingName() {
            if (this.building) {
                return this.building.name;
            }
            return '';
        }

        public getCurrentName() {
            if (this.data) {
                if (!this.data.currentLevel || !this.data.currentLevel.name) {
                    return this.data.alertName;
                }
                return this.data.currentLevel.name;
            }
            return '';
        }

        public getLevelName(level: AlertDataLevel) {
            if (!level) {
                return '';
            }
            return '';
        }

        public getLevelDescription(level: AlertDataLevel) {
            if (!level) {
                return '';
            }
            if (level.lowerBoundary != null || level.upperBoundary != null) {
                const name = level.queryable && level.queryable.name ? `${level.queryable.name.toLowerCase()} ` : '';
                const symbol = level.unit && level.unit.symbol && level.unit.symbol != aq.services.Unit.STATE ? level.unit.symbol : '';
                if (level.lowerBoundary != null && level.upperBoundary != null) {
                    return `(${name}${this.$translate.instant('between')} ${level.lowerBoundary}${symbol} ${this.$translate.instant('and')} ${level.upperBoundary}${symbol})`;
                }
                if (level.upperBoundary != null) {
                    return `(${name}${this.$translate.instant('less than')} ${level.upperBoundary}${symbol})`;
                }
                if (level.lowerBoundary != null) {
                    return `(${name}${this.$translate.instant('greater than')} ${level.lowerBoundary}${symbol})`;
                }
            }
            return '';
        }

        public getValue(unit, value): string {
            const formattedUnit = unit ? unit.symbol : '';
            const formattedValue = value ? Math.round(value) : '';
            return value ? `${formattedValue} ${formattedUnit}` : null;
        }

        public closeStateAlert() {
            this.resolveAndClose = true;
        }

        public getIssueDurationFormatted(stateAlert: aq.propertySettings.StateAlert): string {
            let issueDuration = null;
            if (stateAlert.issueCreatedOn) {
                const start = moment(stateAlert.issueCreatedOn).toDate();
                const end = stateAlert.issueClosedOn ? moment(stateAlert.issueClosedOn).toDate() : moment().toDate();
                issueDuration = this.activityAlertUtilService.getDurationFormatted(start, end);
            }
            return issueDuration;
        }

        public formatAlertDate(date: string) {
            const momentDate = moment(date);
            return momentDate
                .isBefore(moment().subtract(1, 'day').valueOf())
                ? momentDate.format('MMM DD YYYY - h:mm a')
                : momentDate.format('h:mm a');
        }

        public getQueryableString(queryable): string {
            if (queryable && queryable.name) {
                return queryable.name.split(' ').map(_.capitalize).join(' ');
            }
            return 'Whole Building';
        }

        public getWheneverString(level): string {
            const symbol = level.unit ? level.unit.symbol : '';
            const name = level.unit ? `${level.unit.name} ` : '';
            let range = '';
            if (level.lowerBoundary && level.upperBoundary) {
                range = `between ${level.lowerBoundary}${symbol} and ${level.upperBoundary}${symbol}`;
            } else if (level.lowerBoundary) {
                range = `greater than ${level.lowerBoundary}${symbol}`;
            } else if (level.upperBoundary) {
                range = `lesser than ${level.upperBoundary}${symbol}`;
            }
            return range ? `${name}${range}` : 'Not specified';
        }

        private setIcon(status): {color: string; name: SeverityIcon;} {
            switch (status) {
                case LevelSeverity.NORMAL:
                    return { color: 'green', name: SeverityIcon.NORMAL };
                case LevelSeverity.WARNING:
                    return { color: 'orange', name: SeverityIcon.WARNING };
                case LevelSeverity.CRITICAL:
                    return { color: 'red', name: SeverityIcon.CRITICAL };
                case LevelSeverity.UNKNOWN:
                default:
                    return { color: 'gray', name: SeverityIcon.UNKNOWN };
            }
        }

        private hideResolveAndClose() {
            this.resolveAndClose = false;
        }
    }

    export const ActivityStateAlertDisplay: ng.IComponentOptions = {
        controller: _ActivityStateAlertDisplay,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/activities/contexts/stateAlertDisplay.html',
        bindings: {
            activity: '<',
            building: '<',
            issue: '<',
            noDataCallback: '&',
            contextLoadedCallback: '&',
            refreshActivitiesAndCloseDialog: '&'
        }
    };
    angular.module('aq.ui')
        .component('activityStateAlertDisplay', ActivityStateAlertDisplay);
}
