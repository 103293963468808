namespace aq.ui {

    // this is the type a component callback expects (it's stupid, I know)
    // in your template you have <my-component callback-arg="vm.callback(something)"></my-component>
    // and then when you call that method in the controller:
    /**
     * this.callback({something: "...whatever data should be passed as the 'something' argument"})
     */
    import ActivityMessageDraft = aq.models.activity.ActivityMessageDraft;
    import MediaType = aq.models.activity.MediaType;
    import ActivityMessageCreateRequest = aq.models.activity.ActivityMessageCreateRequest;
    import MediaResponse = aq.models.activity.MediaResponse;
    type ComponentCallback = {activity: aq.models.activity.ActivityResponse};

    interface IBindings {
        activity: string; // UUID
        createdCallback(): void;
    }

    // Reading on component lifecycle hooks https://toddmotto.com/angular-1-5-lifecycle-hooks
    class _ActivityReply implements IBindings {

        public activity: string;
        public createdCallback: () => void;
        public unsavedCallback: (bool) => void;
        public draft: ActivityMessageCreateRequest;
        public isSubmitting: boolean;
        public users: aq.common.models.User[];

        /* @ngInject */
        constructor(
            private ActivityService: aq.services.ActivityService,
            private resolver: aq.services.Resolver,
            private Messages: aq.services.Messages,
            private UserService: aq.services.UserService,
            private $scope: ng.IScope,
            private RestangularV3: restangular.IService,
            private mediaService: aq.services.MediaService
        ) { }

        public $onInit() {
            this.isSubmitting = false;
            this.initDraft();
            // Retrieve list of users so the mention directive doesn't make a request to the platform each time
            // a user tries to mention a new person
            this.RestangularV3.one('users').get()
                .then((userResult) => {
                    this.users = userResult.map(user => {
                        return {
                            first: user.firstName,
                            last: user.lastName,
                            id: user.id
                        };
                    });
                });
        }

        public initDraft() {
            this.draft = new ActivityMessageCreateRequest();
            this.UserService.getUser().then((user) =>
                this.draft.author = user.id
            );
            this.draft.body = '';
            this.draft.media = [];
        }

        public $onChanges(changes) {
            if (changes.activityID) {
                this.activity = angular.copy(changes.activityID.currentValue);
            }

            if (changes.message) {
                this.draft.body = changes.message.currentValue;
            }
        }

        public uploadMedia(activityID: string) {
            this.unsavedCallback({value: true})
            if (!this.draft.id) {
                // @ts-ignore
                this.draft.id = uuidv4();
            }
            this.mediaService.pickPhoto(activityID, this.draft.id)
            .then((files: MediaResponse[]) => {
                if (files && files.length > 0) {
                    this.draft.media = [...this.draft.media, ...files];
                }
            }).catch((err) => {
                this.unsavedCallback({value: false});
            });
            this.$scope.activityMessage.$setPristine();
            this.$scope.activityMessage.$setUntouched();
        }

        public createMessage(activityID: string, draft: ActivityMessageDraft) {
            if (this.isSubmitting) {
                return;
            }
            this.isSubmitting = true;
            this.ActivityService
                .createMessage(draft as any, activityID)
                .then(() => {
                    this.initDraft();
                    this.$scope.activityMessage.$setPristine();
                    this.$scope.activityMessage.$setUntouched();
                    this.isSubmitting = false;
                    this.createdCallback();
            })
            .catch(() => {
                this.Messages.error('Something went wrong submitting your message!');
            });
        }
    }

    export const ActivityReply: ng.IComponentOptions = {
        controller: _ActivityReply,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/activities/messages/reply.html',
        bindings:  {
            activity: '<',
            createdCallback: '&',
            unsavedCallback: '&',
            message: '<?'
        }
    };

    angular
        .module('aq.ui')
        .component('activityReply', ActivityReply);
}
