class SeriesMapping {
    private colorsForServiceType = {
        'ELECTRICITY': '#8FC55D',
        'WATER': '#0091f1',
        'GAS': '#EFAF55',
        'FEE': '#979797'
    };
    /* @ngInject */
    constructor(
        private aqColors
    ) { }

    public mapData(data) {
        const availableTypes = _.chain(data)
            .flatMap(function(c) {
                return _.keys(c);
            })
            .uniq()
            .value();
        return _.map(availableTypes, (type) => {
            return {
                name: textural(type).format('capitalize'),
                color: this.getColor(type),
                data: this.map(type, data)
            };
        });
    }

    private map(name, data) {
        return _.map(data, name).map(function (d) {
            return d ? d : null;
        });
    }

    private getColor(type) {
        if (this.colorsForServiceType[type]) {
            return this.colorsForServiceType[type];
        } else {
            return this.aqColors.getUniqueColorFromName(type);
        }
    }
}

angular
    .module('aq.reports')
    .service('SeriesMapping', SeriesMapping);
