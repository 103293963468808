angular.module('accounts').controller('RuleCategoriesCtrl', function ($scope, Restangular, Errors, $filter) {

    $scope.animation = false;
    $scope.newCategoryName = '';

    $scope.account
        .all('RuleCategories')
        .getList()
        .then(function (categories) {
            $scope.categories = _.sortBy(categories, 'name');
        });

    $scope.updateColor = function (category) {
        category.put().then(null, Errors.forPromise());
    }

    $scope.createCategory = function (newCategoryName) {

        $scope.animation = { enter: 'bounceInLeft', leave: 'bounceOutLeft' };
        $scope.working = true;
        var newCategory = {
            name: newCategoryName,
            color: $filter('color')(newCategoryName)
        }

        return $scope.account.post('RuleCategories', newCategory).then(function (category) {
            var cat = _.extend(category, category.model);
            $scope.categories.push(cat);
            $scope.categories = _.sortBy($scope.categories, 'name');
        }, Errors.forPromise()).finally(function () {
            $scope.newCategoryName = '';
            $scope.working = false;
        });
    }

    $scope.edit = function (category) {
        $scope.categories = _.sortBy($scope.categories, 'name');
        return category.put();
    }
});