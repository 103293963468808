angular.module('accounts').controller('EnergyUseCtrl', function($scope, Restangular, Errors) {

    var initNewRow = function() {
        $scope.newRow = {
            name: "",
            metric: null
        }
    }

    $scope.animation = null;
    $scope.newEnergyUseName = '';
    initNewRow();

    $scope.account
        .all('EnergyUses')
        .getList()
        .then(function(energyUses) {
            $scope.energyUses = _.sortBy(energyUses, 'name');
        });

    $scope.create = function(newRow) {
        $scope.animation = {enter: 'bounceInLeft', leave: 'bounceOutLeft'};
        $scope.working = true;
        newRow.account = $scope.account.id;

        return $scope.account.post('EnergyUses', newRow).then(function(response) {
            newRow = _.extend(response, response.model);
            $scope.energyUses.push(newRow);
            $scope.energyUses = _.sortBy($scope.energyUses, 'name');
        }, Errors.forPromise()).finally(function() {
            initNewRow();
            $scope.working = false;
        });
    }

    $scope.edit = function(energyUse) {
        $scope.energyUses = _.sortBy($scope.energyUses, 'name');
        return energyUse.put();
    }
});