namespace aq.dashboard.widgets {
    class _AidIssueDetail {

        public issue: aq.models.aidIssue.IssueResponse;
        public displaySavings: boolean;
        private savingsEnergy: string;
        private savingsDollar: string;
        private startDate: moment.Moment;

        /* @ngInject */
        constructor(
            private RestangularV3: restangular.IService
        ) {
        }

        public $onInit() {
            this.initializeValues();
        }

        public $onChanges(changes) {
            if (changes.issue) {
                this.issue = angular.copy(changes.issue.currentValue);
                this.initializeValues();
            }
        }

        public initializeValues() {
            if (!this.issue) return;

            this.displaySavings = this.issue.eventType !== aq.models.aidIssue.EventType.HOLIDAY_RUN;
            this.setStartDate();
            if (this.displaySavings) {
                this.setSavings();
            }
        }

        public setStartDate() {
            this.startDate = moment(this.issue.highlightedArea.time_start);
        }

        public setSavings() {
            if (!this.issue.potentialSavingsEnergy) return;
            const energy = (typeof this.issue.potentialSavingsEnergy === 'string' ?
                                parseFloat(this.issue.potentialSavingsEnergy) : this.issue.potentialSavingsEnergy);
            this.savingsEnergy = Math.round(energy).toString();

            this.RestangularV3.one('blended-rate-calculations')
                .customGET(
                    'by-date',
                    {
                        buildingId: this.issue.building,
                        measure: 'ELECTRICITY',
                        date: this.startDate.format('YYYY-MM-DD')
                    }
                )
                .then((result: aq.utilityBudgets.BlendedRateByDateResponse[]) => {
                    if (!result || result.length > 0) {
                        const { blendedRate } = result[0];
                        this.savingsDollar = (energy * blendedRate).toFixed(2);
                    }
                });
        }
    }

    export const AidIssueDetail: ng.IComponentOptions = {
        controller: _AidIssueDetail,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/common/widgets/aidIssue/aidIssueDetail.html',
        bindings: {
            issue: '<'
        }
    };

    angular
        .module('aq.dashboard.widgets')
        .component('aidIssueDetail', AidIssueDetail);
}
