namespace aq.tenantbilling {
    export interface TenantServiceHelperStateParamsService extends ng.ui.IStateParamsService {
        accountId: string;
        buildingId: string;
        tenantId: string;
    }
    export class TenantServiceHelper {
        /* @ngInject */
        constructor(
            private RestangularV3: restangular.IService,
            private $stateParams: TenantServiceHelperStateParamsService,
            private Messages: aq.services.Messages,
            private $q: ng.IQService,
            private DataStore: aq.common.DataStore
        ) {
        }
        /* Tenant */
        public createTenant(tenant: TenantModel) {
            return this.RestangularV3.all('tenants')
                .post(tenant, { buildingId: tenant.building })
                .then((result) => {
                    this.Messages.success('Successfully created Tenant');
                    return result;
                })
                .catch((err) => {
                    this.Messages.error('Error creating Tenant');
                });
        }
        public updateTenant(tenant: Tenant) {
            return tenant.put()
                .then((result) => {
                    this.Messages.success('Tenant has been updated');
                    return result;
                })
                .catch((err) => {
                    this.Messages.error('Error updating Tenant');
                });
        }
        public deleteTenant(tenant: Tenant) {
            return tenant.remove()
                .then(() => {
                    this.Messages.info('Tenant deleted');
                })
                .catch((err) => {
                    this.Messages.error('Error deleting Tenant');
                });
        }
        /* Device Links */
        public deleteDeviceLink(deviceLink: aq.common.models.DeviceTenantLinkFull) {
            return this.DataStore.delete(deviceLink).then(() => {
                this.Messages.success('Device link deleted');
            })
                .catch((err) => {
                    this.Messages.error('Error deleting device link');
                });
        }
        public getDeviceTenantLinks(tenant: Tenant) {
            return this.DataStore.getList(this.RestangularV3.one(''), 'device-tenant-links', { tenantId: tenant.id });
        }
        /* Leases */
        public getLeases(tenantId) {
            return this.RestangularV3.all('leases')
                .customGET('', { tenantId });
        }
        public createLease(lease: LeaseModel) {
            return this.RestangularV3.all('leases')
                .post(lease)
                .then((result) => {
                    this.Messages.success('Successfully created Lease');
                    return result;
                })
                .catch((err) => {
                    this.Messages.error('Error creating Lease');
                });
        }
        public updateLease(lease: LeaseModel) {
            return this.RestangularV3.all('leases').all(lease.id)
                .customPUT(lease);
        }
        public deleteLease(lease: LeaseModel) {
            return this.RestangularV3.one('leases', lease.id)
                .customDELETE(null)
                .then(() => {
                    this.Messages.info('Lease deleted');
                })
                .catch((err) => {
                    this.Messages.error('Error deleting Lease');
                });
        }
        /* Residents */
        public getResidents(leaseId) {
            return this.RestangularV3.all('residents')
                .customGET('', { leaseId });
        }
        public createResident(resident: ResidentModel) {
            return this.RestangularV3.all('residents')
                .post(resident)
                .then((result) => {
                    this.Messages.success('Successfully created Resident');
                    return result;
                })
                .catch((err) => {
                    this.Messages.error('Error creating Resident');
                });
        }
        public updateResident(resident: ResidentModel) {
            return this.RestangularV3.all('residents').all(resident.id)
                .customPUT(resident);
        }
        public deleteResident(resident: ResidentModel) {
            return this.RestangularV3.one('residents', resident.id)
                .customDELETE(null)
        }
        public deleteResidents(residents: ResidentModel[]) {
            residents.forEach((resident) => {
                this.deleteResident(resident)
                .catch((err) => {
                    this.Messages.error('Error deleting resident');
                    return;
                });
            });
            if (residents.length > 0) {
                const message = residents.length > 1 ? `${residents.length} Residents deleted` :  'Resident deleted';
                this.Messages.info(message);
            }
        }
        /* Tenant Invoices */
        public getTenantInvoices(tenant: Tenant) {
            return this.RestangularV3.all('tenant-invoices')
                .all('approved-for-tenant')
                .getList({ tenantId: tenant.id });
        }
        /* Tenant Charges */
        public getTenantCharges(tenant: Tenant) {
            return this.RestangularV3.all('tenant-charges')
                .getList({ tenantId: tenant.id });
        }
        public createTenantCharge(tenantCharge: TenantCharge) {
            return this.RestangularV3.all('tenant-charges')
                .post(tenantCharge, { tenantId: tenantCharge.tenant });
        }
        public updateTenantCharge(tenantCharge: TenantCharge) {
            return tenantCharge.put();
        }
        public syncTenantChargeValues(tenantCharge: TenantCharge) {
            return this.RestangularV3.one('tenant-charges', tenantCharge.id)
                .customPOST({}, 'sync-tenant-charge-values');
        }
        public deleteTenantCharge(tenantCharge) {
            return tenantCharge.remove()
                .then(() => {
                    this.Messages.info('Tenant Charge deleted');
                })
                .catch((err) => {
                    this.Messages.error('Error deleting Tenant Charge');
                });
        }
        public getCollectorAllocations(collectorId, negativeSign) {
            return this.RestangularV3.all('metered-tenant-charge-allocations')
                .customGET('for-collector', {
                    collectorId,
                    negativeSign
                });
        }

        public getDevice(collectorId) {
            return this.RestangularV3.one('devices')
                .customGET(`by-collector/${collectorId}`);
        }

        public updateCollectorAllocations(
            positiveCollectorAllocationSet: CollectorAllocationSet,
            subtractedCollectorAllocationSet: CollectorAllocationSet,
            removedAllocations: Allocation[],
            createdMeteredChargeId = null
        ) {
            const removePromises = _.map(removedAllocations, (allocation) => {
                if (allocation.id) {
                    return this.RestangularV3.one('metered-tenant-charge-allocations', allocation.id)
                        .remove();
                }
                return null;
            });
            const sanitizedCollectorAllocations = _.concat(
                this.getSanitizedCollectorAllocations(positiveCollectorAllocationSet, createdMeteredChargeId),
                this.getSanitizedCollectorAllocations(subtractedCollectorAllocationSet, createdMeteredChargeId)
            );
            const updatePromises = _.map(sanitizedCollectorAllocations, (allocation) => {
                if (allocation.id) {
                    allocation = this.RestangularV3.restangularizeElement(
                        this.RestangularV3.one(''),
                        allocation,
                        'metered-tenant-charge-allocations'
                    );
                    return allocation.put();
                }
                return this.RestangularV3.all('metered-tenant-charge-allocations')
                    .post(allocation, { tenantChargeId: allocation.meteredTenantCharge });
            });
            return this.$q.all(_.concat(removePromises, updatePromises));
        }
        public getSanitizedCollectorAllocations(collectorAllocationSet: CollectorAllocationSet, createdMeteredChargeId = null): Allocation[] {
            return _.flatMap(collectorAllocationSet, (allocations: Allocation[]) => {
                if (allocations.length === 1) {
                    allocations[0].allocationPercent = null;
                }
                _.each(allocations, function (allocation) {
                    // we need to strip payload, remove objects and set only IDs
                    allocation.collector = allocation.collector.id ? allocation.collector.id : allocation.collector;
                    if (allocation.meteredTenantCharge.id === null && createdMeteredChargeId) {
                        allocation.meteredTenantCharge = createdMeteredChargeId;
                    } else {
                        allocation.meteredTenantCharge =
                            allocation.meteredTenantCharge.id ?
                                allocation.meteredTenantCharge.id :
                                allocation.meteredTenantCharge;
                    }
                    delete allocation.originalElement;
                });
                return allocations;
            });
        }
    }
    angular.module('tenantBilling').service('TenantServiceHelper', TenantServiceHelper);
}
