var aq;
(function (aq) {
    var properties;
    (function (properties) {
        var AddOccupancyModalCtrl = /** @class */ (function (_super) {
            __extends(AddOccupancyModalCtrl, _super);
            /* @ngAnnotate */
            function AddOccupancyModalCtrl($scope, $mdDialog, buildingOccupancy) {
                var _this = _super.call(this, $scope, $mdDialog) || this;
                _this.$scope = $scope;
                _this.$mdDialog = $mdDialog;
                _this.buildingOccupancy = buildingOccupancy;
                _this.$scope.buildingOccupancy = buildingOccupancy;
                return _this;
            }
            return AddOccupancyModalCtrl;
        }(aq.common.Controllers.ModalCtrl));
        properties.AddOccupancyModalCtrl = AddOccupancyModalCtrl;
        angular
            .module('properties')
            .controller('AddOccupancyModalCtrl', AddOccupancyModalCtrl);
    })(properties = aq.properties || (aq.properties = {}));
})(aq || (aq = {}));
