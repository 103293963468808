namespace aq.integrations.mri {

    export class MRIEditModalCtrl {
        /* @ngInject */
        constructor(
            public building,
            public mriInformationForBuilding,
            public mriIntegrations,
            private MRIService: aq.integrations.mri.MRIService
        ) { }

        public saveIntegration() {
            if (this.mriInformationForBuilding.id) {
                this.MRIService.updateMRIIntegration(this.mriInformationForBuilding).then((response) => {
                    this.mriInformationForBuilding = response;
                });
            } else {
                this.mriInformationForBuilding.building = this.building.id;
                this.MRIService.createMRIIntegration(this.mriInformationForBuilding).then((response) => {
                    this.mriIntegrations.push(response);
                });
            }
        }

    }

    angular
        .module('aq.integrations')
        .controller('MRIEditModalCtrl', MRIEditModalCtrl);
}
