namespace aq.common {
    type TimeConfigType = "Trailing" | "This" | "Last" ;
    type TimeConfigPeriod = "Hour" | "Day" | "Week" | "Month" | "Year";
    type TimeConfigInterval = "MINUTE" | "FIFTEEN_MINUTE" | "HOUR" | "DAY" | "MONTH";
    type TimeConfig = {
        [TC in TimeConfigType]: {
            [TP in TimeConfigPeriod]: {
                /**
                 * @returns moment time adjusted for timezone
                 */
                start: (timezone: string) => moment.Moment;
                /**
                 * @returns moment time adjusted for timezone
                 */
                end: (timezone: string) => moment.Moment;
                /**
                 * all intervals supported by this time preset
                 */
                intervals: {label: string,value: string}[];
            }
        }
    }
    type IntervalConfig = {
        [I in TimeConfigInterval] : {label: string, value: string}
    };
    /**
     * Defines all possible choices for Intervals
     */
    export const Intervals: IntervalConfig = {
        "MINUTE": {
            label: "Minute",
            value: "1min"
        },
        "FIFTEEN_MINUTE": {
            label: "15 Minute",
            value: "15min"
        },
        "HOUR": {
            label: "Hour",
            value: "1h"
        },
        "DAY": {
            label: "Day",
            value: "1d"
        },
        "MONTH": {
            label: "Month",
            value: "1mon"
        }
    }

    /**
     * All possible time presets
     */
    export const TimeConfiguration: TimeConfig = {
        "Trailing": {
            "Hour": {
                start: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).subtract(1, 'hours');
                },
                end: (timeZoneId: string) => {
                    return moment().tz(timeZoneId);
                },
                intervals: [Intervals.MINUTE, Intervals.FIFTEEN_MINUTE]
            },
            "Day": {
                start: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).subtract(1, 'days');
                },
                end: (timeZoneId: string) => {
                    return moment().tz(timeZoneId);
                },
                intervals: [Intervals.FIFTEEN_MINUTE, Intervals.HOUR]
            },
            "Week": {
                start: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).subtract(1, 'weeks');
                },
                end: (timeZoneId: string) => {
                    return moment().tz(timeZoneId);
                },
                intervals: [Intervals.FIFTEEN_MINUTE, Intervals.HOUR, Intervals.DAY]
            },
            "Month": {
                start: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).subtract(1, 'months');
                },
                end: (timeZoneId: string) => {
                    return moment().tz(timeZoneId);
                },
                intervals: [Intervals.DAY, Intervals.MONTH]
            },
            "Year": {
                start: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).subtract(1, 'years');
                },
                end: (timeZoneId: string) => {
                    return moment().tz(timeZoneId);
                },
                intervals: [Intervals.DAY, Intervals.MONTH]
            }
        },
        "This": {
            "Hour": {
                start: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).startOf('hours');
                },
                end: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).endOf('hours');
                },
                intervals: [Intervals.MINUTE, Intervals.FIFTEEN_MINUTE]
            },
            "Day": {
                start: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).startOf('days');
                },
                end: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).endOf('days');
                },
                intervals: [Intervals.FIFTEEN_MINUTE, Intervals.HOUR]
            },
            "Week": {
                start: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).startOf('weeks');
                },
                end: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).endOf('weeks');
                },
                intervals: [Intervals.FIFTEEN_MINUTE, Intervals.HOUR, Intervals.DAY]
            },
            "Month": {
                start: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).startOf('months');
                },
                end: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).endOf('months');
                },
                intervals: [Intervals.DAY, Intervals.MONTH]
            },
            "Year": {
                start: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).startOf('years');
                },
                end: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).endOf('years');
                },
                intervals: [Intervals.DAY, Intervals.MONTH]
            }
        },
        "Last": {
            "Hour": {
                start: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).subtract(1, 'hours').startOf('hours');
                },
                end: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).subtract(1, 'hours').endOf('hours');
                },
                intervals: [Intervals.MINUTE, Intervals.FIFTEEN_MINUTE]
            },
            "Day": {
                start: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).subtract(1, 'days').startOf('days');
                },
                end: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).subtract(1, 'days').endOf('days');
                },
                intervals: [Intervals.FIFTEEN_MINUTE, Intervals.HOUR]
            },
            "Week": {
                start: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).subtract(1, 'weeks').startOf('weeks');
                },
                end: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).subtract(1, 'weeks').endOf('weeks');
                },
                intervals: [Intervals.FIFTEEN_MINUTE, Intervals.HOUR, Intervals.DAY]
            },
            "Month": {
                start: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).subtract(1, 'months').startOf('months');
                },
                end: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).subtract(1, 'months').endOf('months');
                },
                intervals: [Intervals.DAY, Intervals.MONTH]
            },
            "Year": {
                start: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).subtract(1, 'years').startOf('years');
                },
                end: (timeZoneId: string) => {
                    return moment().tz(timeZoneId).subtract(1, 'years').endOf('years');
                },
                intervals: [Intervals.DAY, Intervals.MONTH]
            }
        }
    }
}
