namespace aq.projectCenter {
    export class ProjectCenterCtrl {
        /* @ngInject */
        constructor(
            $scope: ng.IScope,
            account: aq.common.models.Account,
            buildings: aq.common.models.Building[],
            private projectStatuses,
            private projectTypes,
            private $state: ng.ui.IStateService
        ) {
            // TODO: remove scope updates when legacy building selector is no longer used
            $scope.account = account;
            $scope.buildings = buildings;
            $scope.selectDefaultBuildingWithRedirect(this.$state.current, this.$state.current);
        }
    }

    angular
        .module('projectCenter')
        .controller('ProjectCenterCtrl', ProjectCenterCtrl);
}
