'use strict';

angular.module('aq.reports').factory('DailyCharts',
    function(OptionsService, $filter) {

    const DailyCharts = function(currentValues, previousValues, expectedValues, report) {

        function calculateVariance(currentData, baseloadData) {
            return _.map(currentData, function(c: number, i) {
                if (c > 0) {
                    return baseloadData[i] - c;
                } else {
                    return 0;
                }
            });
        }

        const kW = OptionsService.getUnitByEnumName('kW');

        const day = angular.copy(report.building.currentTime);
        day.subtract(1, 'day');
        const dayLabel = $filter('formatDate')(day, 'dddd', report.building.timeZoneId);
        const baseDayLabel = dayLabel + ' Last Week';
        const expectedLabel = dayLabel + ' Predicted';

        this.getDailyDemandChart = function() {
            const series = [];
            const chartType = 'line';

            if (hasData(previousValues.power.values)){
                series.push({
                    data: $filter('arrayToUnit')(previousValues.power.values, kW),
                    color: report.colors.secondaryColor,
                    name: baseDayLabel,
                    type: chartType,
                    legendIndex: 1
                });
            }

            if (hasData(currentValues.power.values)){
                series.push({
                    data: $filter('arrayToUnit')(currentValues.power.values, kW),
                    color: report.colors.primaryColor,
                    name: dayLabel,
                    type: chartType,
                    legendIndex: 0
                });
            }

            return this.buildChart('kW', series, this.getXAxisLabels(), true, 350, null);
        };

        this.getDailyDemandChartExpected = function() {
            const series = [];

            if (hasData(expectedValues[report.expectedDemandMetric].values)){
                series.push({
                    data: getExpectedRange($filter('arrayToUnit')(expectedValues[report.expectedDemandMetric].values, kW),
                                           $filter('arrayToUnit')(expectedValues[report.expectedDemandVariance].values, kW)),
                    color: report.colors.secondaryColor,
                    name: expectedLabel,
                    type: 'arearange',
                    legendIndex: 1
                });
            }

            if (hasData(currentValues.power.values)){
                series.push({
                    data: $filter('arrayToUnit')(currentValues.power.values, kW),
                    color: report.colors.primaryColor,
                    name: dayLabel,
                    type: 'line',
                    legendIndex: 0
                });
            }

            return this.buildChart('kW', series, this.getXAxisLabels(), true, 350, null);
        };

        this.getVarianceChart = function() {
            const currentData = $filter('arrayToUnit')(currentValues.power.values, kW);
            const baseloadData = $filter('arrayToUnit')(previousValues.power.values, kW);

            const series = [];
            if (hasData(currentData) && hasData(baseloadData)){
                series.push({
                    data: calculateVariance(currentData, baseloadData),
                    name: dayLabel,
                    type: 'column',
                    legendIndex: 0,
                    zones: [{
                        value: 0,
                        color: report.colors.primaryColor
                    }, {
                        color: report.colors.secondaryColor
                    }]
                });
            }

            return this.buildChart('kW', series, this.getXAxisLabels(), false, 250, null);
        };

        this.getWaterChart = function(temperatureData) {
            const series = [];
            if (hasData(currentValues[report.usageUnit.apiUnit.toLowerCase()].values)) {
                series.push({
                    data: $filter('arrayToUnit')(currentValues[report.usageUnit.apiUnit.toLowerCase()].values, report.usageUnit),
                    name: report.usageUnit.unit,
                    color: report.colors.primaryColor,
                    type: 'column',
                    legendIndex: 0
                });
            }

            if (hasData(temperatureData.values)) {
                series.push({
                    data: $filter('arrayToTemperatureUnit')(temperatureData.values, report.temperatureUnit),
                    color: '#737778',
                    yAxis: 1,
                    position: 'right',
                    name: 'Temperature',
                    type: 'line',
                    legendIndex: 1
                });
            }

            const yAxis = [{
                title: {text: report.usageUnit.unit}
            },
            {
                title: {text: report.temperatureUnit.unit},
                opposite: true
            }];

            return this.buildChart(report.usageUnit.unit, series, this.getXAxisLabels(), true, 400, yAxis);
        };

        this.getXAxisLabels = function(){
            const dates = [];
            // only display dates for every two hours
            const interval = Math.round(currentValues[report.usageUnit.apiUnit.toLowerCase()].timestamps.length / 12);
            currentValues[report.usageUnit.apiUnit.toLowerCase()].timestamps.forEach(function(time) {
                dates.push(moment(time).tz(report.building.timeZoneId).format('ha'));
            });
            return {labels: dates, interval};
        };

        this.buildChart = function(title, series, xAxisLabels, showLegend, height, yAxis) {
            return {
                chart: {
                    height: height ? height : 400
                },
                lang: {
                    noData: 'No data to display.'
                },
                noData: {
                    position: { 'x': 0, 'y': 0, 'align': 'center', 'verticalAlign': 'middle' }
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    categories: xAxisLabels.labels,
                    labels: {
                        rotation: false
                    },
                    tickInterval: xAxisLabels.interval,
                    tickLength: 0
                },
                yAxis: yAxis ? yAxis : {title: {text: title}},
                legend: {
                    enabled: showLegend,
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal',
                    itemStyle: {
                        fontSize: '10px'
                    }
                },
                plotOptions: {
                    column: {
                        groupPadding: .1
                    },
                    series: {
                        // turn off animations because of screenshot tool
                        animation: false,
                        marker: {
                            enabled: false
                        }
                    }
                },
                series,
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                }
            };
        };

    };


    function hasData(data) {
        return _.filter(data, null).length !== 0;
    }

    function getExpectedRange(expectedPowerArray, expectedVarianceArray) {
        const expectedRangeValues: number[][] = [];
        for (let i = 0; i < expectedPowerArray.length; i++) {
            expectedRangeValues[i] = [
                calculateDataValue(expectedPowerArray[i] - expectedVarianceArray[i]),
                calculateDataValue(expectedPowerArray[i] + expectedVarianceArray[i])
            ];
        }
        return expectedRangeValues;
    }

    function calculateDataValue(value) {
        return value > 0 ? value : 0;
    }

    return DailyCharts;

});
