namespace aq.tenantbilling {
    export class AddTenantCtrl extends aq.common.Controllers.ModalCtrl {
        public tenant: TenantModel;
        /* @ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private RestangularV3: restangular.IService,
            private Errors,
            private Messages: aq.services.Messages,
            private accountId,
            private buildingId,
            private leaseTypes: any[],
            private TenantServiceHelper: TenantServiceHelper,
            private areaMeasurement
        ) {
            super({}, $mdDialog);
            this.tenant = <TenantModel>{
                building: this.buildingId
            };
        }
        public cancel(data?): void {
            this.$mdDialog.cancel();
        }
        public hide(): void {
            this.$mdDialog.hide(this.RestangularV3.copy(this.tenant));
        }
        public save() {
            return this.TenantServiceHelper.createTenant(this.tenant)
                .then((result) => {
                    _.extend(this.tenant, result);
                    this.hide();
                });
        }
        public clearImage() {
            this.tenant.imageUrl = null;
            this.tenant.imageThumbnailUrl = null;
        }
    }
    angular.module('tenantBilling').controller('AddTenantCtrl', AddTenantCtrl);
}
