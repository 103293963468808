angular.module('charting').filter('forTemperatureChart', function($filter) {
    return function(data, query, color, valuesField) {
        if (!data || _.isEmpty(data) || !query.temperature.selectedUnit) {
            return null;
        }
        if (!valuesField) {
            valuesField = "values";
        }
        var ticks = $filter('period')(query.time);

        var tempData = _.map(data, function(one) {
            var elem: any = {};
            elem.label = one.name + " = 0.00";
            elem.name = one.name;
            elem.xaxis = 1;
            elem.yaxis = 3;
            elem.stack = false;
            elem.lines = {
                show: true,
                fill: false,
                lineWidth: 2
            };
            elem.dashes = {
                show: true
            }
            elem.points = {
                show: false
            };
            elem.bars = {
                show: false
            }
            elem.shadowSize = 1;
            elem.color = color;
            elem.data = _.chain(ticks)
                .zip(one[valuesField])
                .filter(function(one) {
                    return !_.isUndefined(one[0]);
                })
                .map(function(one) {
                    var x = one[0]
                    var y = $filter('toTemperatureUnit')(one[1], query.temperature.selectedUnit);
                    return [x, y];
                })
                .value();

            return elem;

        });

        var missing = $filter('missing')(data);

        if (missing.length > 0) {
            // rename key from building name to 'Weather'
            missing[0].name = 'Weather';

            return {
                data: tempData,
                unit: query.temperature.selectedUnit,
                missing: missing[0]
            };
        }

        return {
            data: tempData,
            unit: query.temperature.selectedUnit
        };

    }
});
