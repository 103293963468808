angular.module('aq-main').run(function($httpBackend) {
    const regex = /^\/api\/v1\/accounts\/.*\/buildings\/.*\/Targets\/queryTargets/;
    const targets = [{
        value: 6000
    }, {
        value: 6000
    }, {
        value: 6000
    }, {
        value: 6000
    }, {
        value: 6000
    }, {
        value: 6000
    }, {
        value: 6000
    }, {
        value: 6000
    }, {
        value: 6000
    }, {
        value: 6000
    }, {
        value: 6000
    }];
    MockContext('targets', () => {
        $httpBackend.whenGET(regex).respond((method, url, data) => {
            return [200, targets, {}];
        });
        $httpBackend.whenPUT(regex).respond((method, url, data) => {
            data = JSON.parse(data);
            if (data.id) {
                _.remove(targets, {id: data.id});
            }
            this.targets.push(data);
            return [200, data, {}];
        });
    });
});