namespace aq.tenantbilling {
    export class AddUnitCtrl extends aq.common.Controllers.ModalCtrl {
        public tenant: TenantModel;
        public lease: LeaseModel;
        public residents: ResidentModel[] = [];
        /* @ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private RestangularV3: restangular.IService,
            private Errors,
            private Messages: aq.services.Messages,
            private accountId,
            private buildingId,
            private leaseTypes: any[],
            private TenantServiceHelper: TenantServiceHelper,
            private areaMeasurement
        ) {
            super({}, $mdDialog);
            this.tenant = {
                building: this.buildingId
            } as TenantModel;
            const primaryResident = {} as ResidentModel;
            this.residents.push(primaryResident);
        }
        public addAnotherResident(residents: ResidentModel[]) {
            const resident = {} as ResidentModel;
            residents.push(resident);
        }
        public cancel(data?): void {
            this.$mdDialog.cancel();
        }
        public hide(): void {
            this.$mdDialog.hide(this.RestangularV3.copy(this.tenant));
        }
        public save() {
            return this.TenantServiceHelper.createTenant(this.tenant)
                .then((result) => {
                    _.extend(this.tenant, result);
                    if (this.lease) {
                        this.lease.tenant = this.tenant.id;
                        this.lease.active = true;
                        this.TenantServiceHelper.createLease(this.lease)
                            .then((leaseResult) => {
                                _.extend(this.lease, leaseResult);
                                this.residents.forEach((resident) => {
                                    if (resident.name && resident.email) {
                                        resident.lease = this.lease.id;
                                        this.TenantServiceHelper.createResident(resident);
                                    }
                                });
                            });
                    }
                    this.hide();
                });
        }
    }
    angular.module('tenantBilling').controller('AddUnitCtrl', AddUnitCtrl);
}
