var aq;
(function (aq) {
    var properties;
    (function (properties) {
        var VerifyCommissioningModalCtrl = /** @class */ (function (_super) {
            __extends(VerifyCommissioningModalCtrl, _super);
            /* @ngInject */
            function VerifyCommissioningModalCtrl($scope, $mdDialog, Messages, building, RestangularV3) {
                var _this = _super.call(this, $scope, $mdDialog) || this;
                _this.$scope = $scope;
                _this.$mdDialog = $mdDialog;
                _this.Messages = Messages;
                _this.building = building;
                _this.RestangularV3 = RestangularV3;
                _this.dataCommissionDate = moment().format('YYYY-MM-DD');
                _this.sendNotifications = true;
                _this.building = building;
                return _this;
            }
            VerifyCommissioningModalCtrl.prototype.save = function () {
                var _this = this;
                var cDate = moment(this.dataCommissionDate);
                if (!cDate.isValid()) {
                    return alert('Invalid Date');
                }
                return this.RestangularV3
                    .one('buildings', this.building.id)
                    .customPOST({ dataCommissionDate: cDate.toISOString(), sendNotifications: this.sendNotifications }, 'data-commission')
                    .then(function (response) {
                    _this.Messages.success('Successfully updated commission date.');
                    _this.$mdDialog.hide();
                }).catch(function () {
                    _this.Messages.error('Unable to update data commission date.');
                });
            };
            return VerifyCommissioningModalCtrl;
        }(aq.common.Controllers.ModalCtrl));
        properties.VerifyCommissioningModalCtrl = VerifyCommissioningModalCtrl;
        angular
            .module('properties')
            .controller('VerifyCommissioningModalCtrl', VerifyCommissioningModalCtrl);
    })(properties = aq.properties || (aq.properties = {}));
})(aq || (aq = {}));
