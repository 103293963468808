namespace aq.ui {

    interface IBindings {
        media: aq.models.activity.MediaResponse[];
    }

    // Reading on component lifecycle hooks https://toddmotto.com/angular-1-5-lifecycle-hooks
    class _InlineMedia implements IBindings {
        public media: aq.models.activity.MediaResponse[];
        public optimizedURIs: {optimized: string, fullsize: string}[];

        /* @ngInject */
        constructor(private $mdDialog: ng.material.IDialogService) { }

        public $onInit(): void {
            this.optimizeImages(this.media);
        }

        public $onChanges(changes): void {
            if (changes.media) {
                this.media = angular.copy(changes.media.currentValue);
                this.optimizeImages(this.media);
            }
        }

        public optimizeImages(media: aq.models.activity.MediaResponse[]): void {
            const FILESTACK_API_KEY = 'AjTalmEdZSVySNrGphKNaz';
            const baseURL = `https://cdn.filestackcontent.com/${FILESTACK_API_KEY}`;
            this.optimizedURIs = media.map((image) => {
                return {
                    optimized: `${baseURL}/resize=width:75,align:center/crop=dim:[10,10,75,75]/${image.uri}`,
                    fullsize: `${baseURL}/resize=width:575,align:center/${image.uri}`
                };
            });
        }

        public showFullImage(uri: string): void {
            this.$mdDialog.show({
                controller: 'fullImageCtrl',
                controllerAs: 'vm',
                templateUrl: 'app/common/components/activities/inlineMedia/mediaModal.html',
                clickOutsideToClose: true,
                // @ts-ignore
                multiple: true,
                locals: {
                    uri
                }
            });
        }
    }

    class FullImageCtrl {
        /* @ngInject */
        constructor(private $mdDialog, private uri: string) { }

        public cancel(): void {
            this.$mdDialog.cancel();
        }
    }

    export const InlineMedia: ng.IComponentOptions = {
        controller: _InlineMedia,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/activities/inlineMedia/inlineMedia.html',
        bindings: {
            media: '<'
        }
    };

    angular
        .module('aq.ui')
        .controller('fullImageCtrl', FullImageCtrl)
        .component('inlineMedia', InlineMedia);
}
