var aq;
(function (aq) {
    var ui;
    (function (ui) {
        var _ActivityIssueHistory = /** @class */ (function () {
            /* @ngInject */
            function _ActivityIssueHistory(resolver, RestangularV3) {
                this.resolver = resolver;
                this.RestangularV3 = RestangularV3;
            }
            _ActivityIssueHistory.prototype.$onInit = function () {
                var _this = this;
                this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                    var historyPromise, issuePromise, _a, issue, history;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                historyPromise = this.RestangularV3
                                    .one('issues', this.issue)
                                    .one('history')
                                    .get({ noCache: moment().valueOf() });
                                issuePromise = this.RestangularV3
                                    .one('issues', this.issue)
                                    .get({ noCache: moment().valueOf() });
                                return [4 /*yield*/, Promise.all([issuePromise, historyPromise])];
                            case 1:
                                _a = _b.sent(), issue = _a[0], history = _a[1];
                                this.issueAlertHistory = this.buildCompactIssueListForAlert(issue, history);
                                if (!this.issueAlertHistory) {
                                    this.noHistory = true;
                                }
                                return [2 /*return*/];
                        }
                    });
                }); });
            };
            _ActivityIssueHistory.prototype.buildCompactIssueListForAlert = function (alert, history) {
                var issues = [];
                var group = {};
                var orderedIssues = [];
                if (!alert) {
                    return [];
                }
                var excludeIssueId = !alert.issueClosedOn ? alert.currentIssueId : null;
                history.sort(function (a, b) { return moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf(); });
                history.forEach(function (item) {
                    if (item.issueId && item.issueId != excludeIssueId) {
                        if (!group[item.issueId]) {
                            group[item.issueId] = [];
                            orderedIssues.push(item.issueId);
                        }
                        group[item.issueId].push(item);
                    }
                });
                for (var key in group) {
                    group[key].sort(function (a, b) { return moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf(); });
                }
                orderedIssues.forEach(function (issueId) {
                    var list = group[issueId];
                    var currentIssue = {
                        openedOn: list[0].timestamp,
                        closedOn: list[list.length - 1].timestamp,
                        issueId: issueId
                    };
                    issues.push(currentIssue);
                });
                issues.reverse();
                return issues;
            };
            return _ActivityIssueHistory;
        }());
        ui.ActivityIssueHistory = {
            controller: _ActivityIssueHistory,
            controllerAs: 'vm',
            templateUrl: 'app/common/components/activities/issueHistory/history-list.html',
            bindings: {
                issue: '<'
            }
        };
        angular
            .module('aq.ui')
            .component('activityIssueHistory', ui.ActivityIssueHistory);
    })(ui = aq.ui || (aq.ui = {}));
})(aq || (aq = {}));
