'use strict';
angular.module('properties.dataCollectors', ['ui.router', 'aq.auth', 'ui.select2', 'pascalprecht.translate', 'mgcrea.ngStrap', 'ngSanitize', 'ui.mention', 'aq.filters'])
    .config(
        ($stateProvider) => {
            $stateProvider
                .state('aq.properties.buildings.datacollectors', {
                    url: '/:buildingId',
                    template: '<ui-view></ui-view>',
                    abstract: true,
                    data: {
                        breadcrumb: 'Data Collectors'
                    },
                    resolve: {
                        buildingId: ($stateParams) => {
                            return $stateParams.buildingId;
                        },
                        building: (
                            DataStore,
                            Restangular,
                            buildingId,
                            accountId,
                            waitForAuthToken
                        ) => {
                            return DataStore.get(
                                Restangular.one('accounts', accountId).one('buildings', buildingId),
                                { single: true }
                            );
                        },
                        currentUser: (waitForAuthToken, initUser) => {
                            return initUser;
                        }
                    }
                })
                .state('aq.properties.buildings.datacollectors.list', {
                    url: '/datacollectors',
                    templateUrl: 'app/properties/dataCollectors/main/dataCollectors.html',
                    controller: 'DataCollectorsCtrl',
                    data: {
                        breadcrumb: '',
                        icon: '.aq-icons-data-collectors'
                    },
                    resolve: {
                        dataCollectors: (Restangular, buildingId, accountId, waitForAuthToken): any => {
                            const inflate = 'buildings,childCollectors,childCollectors.childCollectors,childCollectors.childCollectors.childCollectors';
                            return Restangular.one('accounts', accountId).one('buildings', buildingId).getList('collectors', { inflate: inflate });
                        }
                    }
                })
                .state('aq.properties.buildings.datacollectors.detail', {
                    url: '/datacollectors/:dataCollectorId',
                    templateUrl: 'app/properties/dataCollectors/details/details.html',
                    controller: 'DataCollectorDetailsCtrl',
                    data: {
                        redirectState: 'aq.properties.buildings.datacollectors.list',
                        breadcrumb: 'Detail'
                    },
                    resolve: {
                        dataCollectors: (Restangular, buildingId, accountId, waitForAuthToken) => {
                            const inflate = 'buildings,childCollectors,childCollectors.childCollectors,childCollectors.childCollectors.childCollectors';
                            return Restangular.one('accounts', accountId).one('buildings', buildingId).getList('collectors', { inflate: inflate });
                        }
                    }
                });
        }
    )
    .run(function ($rootScope, Auth, Restangular, uiSelect2Config) {
        uiSelect2Config.minimumResultsForSearch = 5;
    });
