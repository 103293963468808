'use strict';
angular.module('properties.sources').directive('sourcee', function () {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            source: '=',
            setup: '&',
            addChild: '&',
            lastReportedTimes: '=',
        },
        templateUrl: 'app/properties/sources/sourcee/sourcee.html',
        controller: function ($scope, $timeout, $tooltip, $element) {

        }
    }
});