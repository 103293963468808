namespace aq.energyInsights {

    import HighchartsAreaZone = __Highcharts.AreaZone;
    import HighchartsDataPoint = __Highcharts.DataPoint;
    import HighchartsPlotLines = __Highcharts.PlotLines;

    export class PeakDemandCtrl {
        private appRestructure;
        /* @ngInject */
        constructor(private $scope: PeakDemandCtrlScope,
                    private metric,
                    private $filter,
                    private UserService,
                    private $window: ng.IWindowService,
                    private currentBuilding: any,
                    private $state: ng.ui.IStateService,
                    private targets: aq.propertySettings.TargetItem[],
                    private account,
                    private EnergyInsightsDataService: aq.energyInsights.service.EnergyInsightsDataService,
                    private loading,
                    private PeakDemandService,
                    private Auth: aq.services.Auth,
                    ) {
            loading.start();
            $scope.drillUpPeaks = [];
            $scope.selectedPeaks = [];
            $scope.selection = {};
            $scope.timezone = this.currentBuilding.timeZoneId;
            $scope.targets = targets;
            this.appRestructure = Auth.hasFunctionality('App Restructure');

            $scope.totals = {
                peak: null,
                target: null,
                corey: false,
                percent: null
            };

            $scope.getPercent = (part, total) => {
                if(total > 0) {
                    return (part / total) * 100;
                }
                return 0;
            };

            $scope.periodSearch = {
                start: aq.common.TimeConfiguration.This.Month.start(this.currentBuilding.timeZoneId),
                end: aq.common.TimeConfiguration.This.Month.end(this.currentBuilding.timeZoneId),
                interval: _.find(aq.common.TimeConfiguration.This.Month.intervals, {value: "1d"})
            };
            this.$scope.drilldown = true;
            let frontEndFormatting = this.initChart(null);
            this.draw(frontEndFormatting);
        }

        public timePeriodSelect = (start, end, interval, label): void => {
            this.$scope.periodSearch.start = start;
            this.$scope.periodSearch.end = end;
            this.$scope.periodSearch.interval = interval;
            this.$scope.periodSearch.label = label;
            this.$scope.drilldown = true;
            let frontEndFormatting = this.initChart(interval);
            this.draw(frontEndFormatting, interval);
        };

        // entry point from md-data-table and highcharts
        public selectItem = (peak:Peak) : any => {
            let start = moment.tz(peak.date.truncated, this.currentBuilding.timeZoneId);
            let end = moment.tz(peak.date.roundUp, this.currentBuilding.timeZoneId);
            let frontEndFormatting = new FrontEndFormatting(end.unix() - start.unix(), this.UserService, null);
            this.$scope.interval = frontEndFormatting.interval;

           this.$scope.drilldown = frontEndFormatting.interval != '15 Minutes';

            return this.selectionChanged(start, end, frontEndFormatting);
        };

        // entry point from period selector
        public selectionChanged = (start: moment.Moment, end: moment.Moment, frontEndFormatting) : void => {
            return this.drawDrilldown(frontEndFormatting, start, end);
        };

        goToOptimization = () : void => {
            this.account.get({ single: true }).then((thisAccount) => {
                let optimizationURI = URI("/accounts/" + thisAccount.id + "/optimization/building")
                    .search({
                        unit: this.metric.value,
                        apiUnit: this.metric.apiUnit,
                        interval: '1h',
                        off: _.without(thisAccount.buildings, this.currentBuilding.id).join(","),
                        start: moment(this.$scope.periodSearch.start).tz(this.currentBuilding.timeZoneId).format(),
                        end: moment(this.$scope.periodSearch.end).tz(this.currentBuilding.timeZoneId).format(),
                        children: "buildings"
                    });
                this.$window.location.href = optimizationURI.toString();
            });
        };

        public initChart = (interval) : FrontEndFormatting => {
            let start = moment.tz(this.$scope.periodSearch.start, this.currentBuilding.timeZoneId);
            let end = moment.tz(this.$scope.periodSearch.end, this.currentBuilding.timeZoneId);
            let frontEndFormatting = new FrontEndFormatting(end.unix() - start.unix(), this.UserService, interval);

            this.$scope.clickable = frontEndFormatting.clickable;
            this.$scope.interval = frontEndFormatting.interval;

            return frontEndFormatting;
        };

        public calculateQuestionAnswer = (peaks) => {
            let chartData = _.map(_.map(peaks, 'metric'), 'chartValue');
            let maxPeakIndex = chartData.indexOf(Math.max.apply(Math, chartData));
            return this.PeakDemandService.getTargetModel(this.currentBuilding, 'ELECTRICITY', this.$scope.periodSearch, this.account)
            .then((targetModel) => {
                this.$scope.questionModel = targetModel;
                this.$scope.totals.percent = targetModel.percent;
                this.$scope.totals.peak = {
                    tableValue: peaks[maxPeakIndex]["metric"].tableValue,
                    chartValue: peaks[maxPeakIndex]["metric"].chartValue
                };
                this.$scope.totals.target = {
                    tableValue: peaks[maxPeakIndex]["target"].tableValue,
                    chartValue: peaks[maxPeakIndex]["target"].chartValue
                };
                if(this.$scope.totals.target.chartValue) {
                    this.$scope.hasTarget = true;
                }
                this.setupToast();
            });
        };

        public draw = (frontEndFormatting, interval?) => {
            if(this.$scope.peakChart) {
                this.$scope.peakChart.showLoading();
            }
            this.EnergyInsightsDataService.getPeakData(this.currentBuilding, this.$scope.periodSearch.start, this.$scope.periodSearch.end, this.metric, this.$scope.targets, this.account, interval).then((peaks) => {
                peaks.forEach((p) => {
                    let truncatedMoment = moment(p.date.raw).tz(this.currentBuilding.timeZoneId).startOf(frontEndFormatting.truncateTo);
                    p.date.truncated = truncatedMoment.valueOf();
                    p.date.roundUp = moment(truncatedMoment).tz(this.currentBuilding.timeZoneId).add(1, frontEndFormatting.truncateTo).valueOf();
                    p.date.tableFormat = truncatedMoment.format(frontEndFormatting.tableDateFormat);
                    p.date.chartFormat = (frontEndFormatting.truncateTo == 'month' || frontEndFormatting.truncateTo == 'day') ? p.date.roundUp : p.date.truncated;
                    p.date.tooltipFormat = (frontEndFormatting.truncateTo == 'month' || frontEndFormatting.truncateTo == 'day') ? p.date.raw : p.date.truncated;
                    p.chartFormat = truncatedMoment.format(frontEndFormatting.chartDateFormat)
                });

                if(this.$scope.peakChart) {
                    this.$scope.peakChart.hideLoading();
                }
                this.drawChart(peaks, frontEndFormatting);
                this.$scope.peaks = peaks;
                this.calculateQuestionAnswer(peaks);
                this.loading.stop();
            });

        };

        public drawDrilldown = (frontEndFormatting, start, end) : any => {
            return this.EnergyInsightsDataService.getPeakData(this.currentBuilding, start, end, this.metric, this.$scope.targets, this.account, null).then((peaks) => {
                peaks.forEach((p) => {
                    let truncatedMoment = moment(p.date.raw).tz(this.currentBuilding.timeZoneId).startOf(frontEndFormatting.truncateTo);
                    p.date.truncated = truncatedMoment.valueOf();
                    p.date.roundUp = moment(truncatedMoment).tz(this.currentBuilding.timeZoneId).add(1, frontEndFormatting.truncateTo).valueOf();
                    p.date.tableFormat = truncatedMoment.format(frontEndFormatting.tableDateFormat);
                    p.date.chartFormat = (frontEndFormatting.truncateTo == 'month' || frontEndFormatting.truncateTo == 'day') ? p.date.roundUp : p.date.truncated;
                    p.date.tooltipFormat = (frontEndFormatting.truncateTo == 'month' || frontEndFormatting.truncateTo == 'day') ? p.date.raw : p.date.truncated;
                    p.chartFormat = truncatedMoment.format(frontEndFormatting.chartDateFormat)
                });
                let chartSeries = this.buildChartSeries(peaks, frontEndFormatting);

                this.$scope.drillUpPeaks.push(this.$scope.peaks);
                this.$scope.peaks = peaks;

                this.calculateQuestionAnswer(peaks);

                return chartSeries;
            });

        };

        downloadCsv = () : void => {
            let csvContent = 'data:text/csv;charset=utf-8,';
            csvContent += [JSON.stringify(this.$scope.interval),
                    JSON.stringify('Peak ' + this.$scope.peaks[0].metric.unit),
                    JSON.stringify('Target ' + this.$scope.peaks[0].target.unit),
                    'Percent'].join(',') + '\n';

            let peakLength = this.$scope.peaks.length;
            let percentFunc = this.$scope.getPercent;

            this.$scope.peaks.forEach(function(row, index){
                csvContent += [JSON.stringify(row.date.tableFormat),
                    JSON.stringify(row.metric.chartValue || ''),
                    JSON.stringify(row.target.chartValue || ''),
                    percentFunc(row.metric.chartValue, row.target.chartValue)].join(',');
                csvContent += index < peakLength ? '\n' : '';
            });

            //add total row
            csvContent += `Total,${this.$scope.totals.peak.chartValue},${this.$scope.totals.target.chartValue},${this.$scope.totals.percent},\n`

            let link = document.createElement('a');
            link.setAttribute('href', encodeURI(csvContent));
            link.setAttribute('download', 'peak_demand_export.csv');
            link.click();
        };

        private buildChartSeries = (peaks: Peak[], frontEndFormatting): any[] => {
            return [{
                name: "Peak Below Target",
                states: {hover: {enabled: false}},
                color: peaks[0].isMissingData ? this.EnergyInsightsDataService.transparentColor('#7ACE46') : '#7ACE46',
                marker: {enabled: frontEndFormatting.dots}
            }, {
                name: "Peak Above Target",
                states: {hover: {enabled: false}},
                color: peaks[0].isMissingData ? this.EnergyInsightsDataService.transparentColor('#FC6F71') : '#FC6F71',
                marker: {enabled: frontEndFormatting.dots}
            }, {
                name: 'Target Peak',
                states: {hover: {enabled: false}},
                color: '#0091F1',
                lineWidth: 1
            }, {
                name: 'Target Peak',
                states: false,
                color: '#0091F1',
                lineWidth: frontEndFormatting.interval === "Month" ? 0 : 3,
                marker: {
                    enabled: frontEndFormatting.interval === 'Month',
                    symbol: "url(data:image/svg+xml;utf8," +
                    "<svg height=\"3\" width=\"30\" xmlns=\"http://www.w3.org/2000/svg\">" +
                    "<rect fill=\"#0091F1\" height=\"3\" width=\"30\"/></svg>)",
                },
                showInLegend: false,
                enableMouseTracking: false,
                data: this.targetDataForChart(peaks)
            }, {
                name: 'Peak Demand',
                states: {hover: {lineWidthPlus: 0}},
                color: peaks[0].isMissingData ? this.EnergyInsightsDataService.transparentColor('#A0A0A0') : '#A0A0A0',
                lineWidth: frontEndFormatting.dots ? 0 : 3,
                marker: {
                    enabled: frontEndFormatting.dots,
                    radius: frontEndFormatting.dots ? (
                        (this.$scope.periodSearch.interval.label === 'Day'
                        && (this.$scope.periodSearch.label === 'This Year'
                        || this.$scope.periodSearch.label === 'Last Year'
                        || this.$scope.periodSearch.label === 'Trailing Year')) ? 3 : 6) : 0,
                },
                cursor: frontEndFormatting.interval == '15 Minutes' ? 'cursor' : 'pointer',
                showInLegend: false,
                data: this.peakDataForChart(peaks, frontEndFormatting.dots),
                zoneAxis: 'x',
                zones: (frontEndFormatting.zones && peaks[0].target.chartValue && this.getZones(peaks) || null)
            }];
        };

        private drawChart = (peaks: Peak[], frontEndFormatting): void => {
            let timezone = this.currentBuilding.timeZoneId;
            this.$scope.peakChartConfig = {
                chart: {
                    plotBorderWidth: 1,
                    type: 'line',
                    events: {
                        drilldown: (e) => {
                            e.target.tooltip.hide();
                            this.onDrilldown(e["point"]);
                        },
                        drillupall: (e) => {
                            this.$scope.peaks = this.$scope.drillUpPeaks.pop();
                            this.$scope.interval = this.drillupInterval();
                            this.$scope.drilldown = true;
                            this.calculateQuestionAnswer(this.$scope.peaks);
                        }
                    }
                },
                lang: {
                    drillUpText: "< Back to previous selection",
                    noData: "No peak demand data to display."
                },
                drilldown: {
                    activeAxisLabelStyle: {
                        textDecoration: 'none',
                        fontWeight: 'normal',
                        color: "#666666"
                    }
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime',
                    tickLength: 0,
                    labels: {
                        formatter: function() {
                            if(this.dateTimeLabelFormat == "%b") {
                                return moment(this.value).tz(timezone).format("MMM");
                            }
                            else if(this.dateTimeLabelFormat == "%b %e") {
                                return moment(this.value).tz(timezone).format("MMM DD");
                            }
                            else if (this.dateTimeLabelFormat == "%H:%M") {
                                return moment(this.value).tz(timezone).format("hh:mma");
                            }
                            else {
                                return moment(this.value).tz(timezone).format("MMM DD YYYY hh:mma");
                            }
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'kW'
                    }
                },
                legend: {
                    itemStyle: {color: "#333333", fontSize: "9px", fontWeight: "normal"},
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: false
                        }
                    }
                },
                plotOptions: {
                    line: {
                        animation: false,
                    },
                    series: {
                        events: {
                            legendItemClick: () => {
                                return false;
                            }
                        },
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        },
                        stickyTracking: false
                    }
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    formatter: function() {
                        return '<b>' + this.point.series.name + '</b>' + '<br>' + this.point.tooltip;
                    },
                    snap: 0
                },
                series: this.buildChartSeries(peaks, frontEndFormatting)
            };
        };

        private drillupInterval() {
            switch (this.$scope.interval) {
                case 'Month':
                    return null;
                case 'Day':
                    return 'Month';
                case 'Hour':
                    return 'Day';
                case '15 Minutes':
                    return 'Hour';
            }
        }

        //entry point from table
        public onTableDrilldown = (index) => {
            if(index || index === 0) {
                let highchart = this.$scope.peakChart;
                    if(highchart) {
                    let series = _.find(highchart.series, function(series:any) {
                        return series.name == 'Peak Demand';
                    });
                    if(series) {
                        let point = series.points[index];
                        this.onDrilldown(point);
                    }
                }
            }
        };

        //entry point from chart
        public onDrilldown = (point) => {
            this.selectItem(this.$scope.peaks[point.index]).then((chartSeries) => {
                chartSeries.forEach((series) => {
                    this.$scope.peakChart["addSingleSeriesAsDrilldown"](point, series);
                });
                this.$scope.peakChart["applyDrilldown"]();
            });
        };

        // TODO: have not yet found a way to have one zone for each month represented on the chart
        private getZones = (peaks: Peak[]): HighchartsAreaZone[] => {
            const targetValue: number = peaks[0].target.chartValue;
            let zones: HighchartsAreaZone[] = [];
            let color: string = '';
            for (const peak of peaks) {
                if (peak.metric.chartValue < targetValue) {
                    color = '#7ACE46';
                } else {
                    color = '#FC6F71';
                }

                if (peak.isMissingData) {
                    color = this.transparentColor(color);
                }

                zones.push({
                    value: peak.date.truncated,
                    color: color
                });
            }
            return zones;
        };

        private plotLines = (rawDateList: number[], formatter: 'YYYY'|'MM') : HighchartsPlotLines[] => {
            let thisPeriod:string = moment.tz(rawDateList[0], this.currentBuilding.timeZoneId).format(formatter);
            let plotLines:HighchartsPlotLines[] = [];
            for (let index = 1; index < rawDateList.length; index++) {
                let nextPeriod = moment.tz(rawDateList[index], this.currentBuilding.timeZoneId).format(formatter);
                if (thisPeriod != nextPeriod) {
                    if (formatter === 'YYYY') {
                        plotLines.push({color: '#858585', width: 1, value: index - 0.5, zIndex: 5,
                            label: {text: nextPeriod, verticalAlign: 'bottom', rotation: 0, y: -4,
                                style: {color: '#858585'} }});
                    } else {
                        plotLines.push({color: '#858585', width: 1, value: index - 0.5, zIndex: 5});
                    }
                    thisPeriod = nextPeriod;
                }
            }
            return plotLines;
        };

        private peakDataForChart = (peaks: Peak[], dots: boolean) : HighchartsDataPoint[] => {
            let chartValueObjects = [];
            for (let index = 0; index < peaks.length; index++) {
                let time = moment.tz(peaks[index].date.truncated, this.currentBuilding.timeZoneId);
                let color = '';
                if (peaks[index].target.chartValue === null) {
                    color = '#858585';
                } else {
                    if (peaks[index].metric.chartValue < peaks[index].target.chartValue) {
                        if (peaks[index].isMissingData) {
                            color = this.EnergyInsightsDataService.transparentColor('#7ACE46');
                        } else {
                            color = '#7ACE46';
                        }
                    } else {
                        if (peaks[index].isMissingData) {
                            color = this.EnergyInsightsDataService.transparentColor('#FC6F71');
                        } else {
                            color = '#FC6F71';
                        }
                    }
                }
                let tooltip = `${moment(peaks[index].date.tooltipFormat).tz(this.currentBuilding.timeZoneId).format("MMM DD YYYY hh:mma")}: <b>${this.$filter('number')(peaks[index].metric.chartValue, '0,0')} kW</b> `;
                tooltip += peaks[index].isMissingData ? '<br><div style="color:red">This building uses data directly <br><div style="color:red">from your utility company.<br><i>Some data has not been received yet.' : '';
                tooltip +=  this.$scope.drilldown ? '<br><i>Drill in for more information.': '';
                let peak = {
                    x: peaks[index].date.chartFormat,
                    y: peaks[index].metric.chartValue,
                    tooltip,
                    color
                };
                peak["drilldown"] = this.$scope.drilldown;
                chartValueObjects.push(peak);
            }
            return chartValueObjects;
        };

        private targetDataForChart = (peaks: Peak[]) : HighchartsDataPoint[] => {
            let chartValueObjects = [];
            for (let index = 0; index < peaks.length; index++) {
                let time = moment.tz(peaks[index].date.truncated, this.currentBuilding.timeZoneId);
                let peak = {
                    x: peaks[index].date.chartFormat,
                    y: peaks[index].target.chartValue,
                    tooltip: this.$filter('number')(peaks[index].target.chartValue, '0,0') + ' kW'
                };
                chartValueObjects.push(peak);
            }
            return chartValueObjects;
        };

        private setupToast() {
            this.$scope.toastMessage = 'Improve your analysis by adding a peak demand target for this building!';
            this.enableToast();
        }

        private enableToast() {
            if(!this.$scope.hasTarget) {
                this.$scope.showToast = true;
            }
        }

        public goToTargets = () => {
            this.$state.go('aq.properties.buildings.targets', {
                accountId: this.account.id,
                buildingId: this.currentBuilding.id
            });
        };

        private transparentColor(color: string): string {
            const alpha = 0.5;
            const convertedAlpha = Math.floor(alpha * 255);
            const alphaString = convertedAlpha < 16 ? '0' + convertedAlpha.toString(16) : convertedAlpha.toString(16);
            return color + alphaString;
        }
    }

    export interface PeakDemandCtrlScope extends ng.IScope {
        // preventing period-selector from setting search parameters (paste url fix)
        initialized: boolean;
        // md-data-table variable that is useless but apparently required
        selectedPeaks: any[];
        // can you click and drill in further in md-data-table?  if so it will be clickable
        clickable: boolean;
        // time interval shown in first column heading on html table
        interval: string;
        // list of data to show on screen.
        peaks: Peak[];
        // selection holds capability to update period selector
        selection: any;
        targets: aq.propertySettings.TargetItem[];
        questionModel: aq.energyInsights.QuestionAnswerModel;
        drilldown: boolean;
        periodSearch: PeriodSearch;
        peakChart: any;
        peakChartConfig:any;
        drillUpPeaks:Peak[][];
        toastMessage: string;
        showToast: boolean;
        hasTarget: Boolean,
        timezone: string,
        totals: {
            peak: {
                chartValue:number;
                tableValue:string;
            };
            target: {
                chartValue:number;
                tableValue:string;
            };
            corey: boolean;
            percent: number;
        };
        getPercent: Function;
    }

    export class Peak {
        public date: IntervalDate;
        public metric: Metric;
        public target: Metric;
        public corey:boolean;
        public isMissingData: boolean;

        constructor(date: IntervalDate, metric: Metric, target: Metric, isMissingData: boolean) {
            this.date = date;
            this.metric = metric;
            this.target = target;
            this.isMissingData = isMissingData;
            this.corey = (this.metric.chartValue && this.target.chartValue && this.metric.chartValue <= this.target.chartValue);
        }
    }

    export class Metric {
        tableValue: string;
        chartValue: number;
        unit: string;
    }

    export interface IntervalDate {
        // raw used for debugging tz
        raw: number;
        truncated?: number;
        roundUp?: number;
        tableFormat?: string;
        chartFormat?: string;
        tooltipFormat?: string;
    }

    export class FrontEndFormatting {
        plotLines: 'YYYY'|'MM';
        truncateTo: string;
        interval: string;
        tableDateFormat: string;
        chartDateFormat: string;
        clickable: boolean;
        dots: boolean;
        lines: boolean;
        zones: boolean;

        constructor(differenceBetweenStartAndEnd: number, UserService, interval) {
            let timeFormat = UserService.getTimeFormat();
            if (interval !== null && interval !== undefined && interval.label !== null) {
                if (interval.label === 'Month') {
                    this.truncateTo = 'month';
                    this.interval = 'Month';
                    this.tableDateFormat = 'MMM, YYYY';
                    this.chartDateFormat = 'MMM';
                    this.clickable = true;
                    this.dots = true;
                    this.plotLines = 'YYYY';
                    this.zones = false;
                }
                if (interval.label === 'Day') {
                    this.truncateTo = 'day';
                    this.interval = 'Day';
                    this.tableDateFormat = 'ddd, MMM DD, YYYY';
                    this.chartDateFormat = 'MMM DD';
                    this.clickable = true;
                    this.dots = true;
                    this.plotLines = 'MM';
                    this.zones = false;
                }
            } else {
                // more than 6 month
                if (differenceBetweenStartAndEnd > 15724800) {
                    this.truncateTo = 'month';
                    this.interval = 'Month';
                    this.tableDateFormat = 'MMM, YYYY';
                    this.chartDateFormat = 'MMM';
                    this.clickable = true;
                    this.dots = true;
                    this.plotLines = 'YYYY';
                    this.zones = false;
                } else if (differenceBetweenStartAndEnd > 86400) {
                    this.truncateTo = 'day';
                    this.interval = 'Day';
                    this.tableDateFormat = 'ddd, MMM DD, YYYY';
                    this.chartDateFormat = 'MMM DD';
                    this.clickable = true;
                    this.dots = true;
                    this.plotLines = 'MM';
                    this.zones = false;
                } else if (differenceBetweenStartAndEnd > 3600) {
                    this.truncateTo = 'hour';
                    this.interval = 'Hour';
                    this.tableDateFormat = 'ddd, MMM DD ' + timeFormat + ', YYYY';
                    this.chartDateFormat = 'MMM DD ' + timeFormat;
                    this.clickable = true;
                    this.dots = false;
                    this.plotLines = 'MM';
                    this.zones = true;
                } else {
                    this.interval = '15 Minutes';
                    this.tableDateFormat = 'ddd, MMM DD ' + timeFormat + ', YYYY';
                    this.chartDateFormat = 'MMM DD ' + timeFormat;
                    this.clickable = false;
                    this.dots = false;
                    this.plotLines = 'MM';
                    this.zones = true;
                }
            }
        }
    }

    angular.module('energyInsights').controller('PeakDemandCtrl', aq.energyInsights.PeakDemandCtrl);
}
