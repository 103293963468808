namespace aq.projectCenter {
    export class ProjectCenterNewCtrl extends aq.common.Controllers.ModalCtrl {
        /* @ngInject */
        constructor(
            protected $scope: ng.IScope,
            protected $mdDialog: ng.material.IDialogService,
            private DataStore: aq.common.DataStore,
            private project: Project,
            private users: aq.common.models.User[],
            private Messages,
            private $state: ng.ui.IStateService,
            private building: aq.common.models.Building,
            private projectTypes: {label: string, value: string}[],
            private UserService,
            private ProjectService: ProjectService,
            private $location: ng.ILocationService
        ) {
            super($scope, $mdDialog);
            this.project = project;
            this.project.projectUrl = $location.absUrl();
            this.projectTypes = projectTypes;
        }

        public cancel() {
            this.$mdDialog.cancel();
        }

        public save() {

            return this.ProjectService.create(this.project)
            .then((project) => {
                this.Messages.success('Project created successfully');
                this.$mdDialog.hide(project);
            }).catch((error) => {
                this.Messages.error('Unable to create Project.');
            });
        }


    }

    angular.module('projectCenter').controller('ProjectCenterNewCtrl', ProjectCenterNewCtrl);
 }