namespace aq.dashboard {
    import WidgetActions = aq.models.segment.WidgetActions;

    export class ConfigurableCtrl {
        private currentRefresh: ng.IPromise<any>;
        private detectChangesInterval: ng.IPromise<any>;
        private lastDashboardStructure: any;

        /* @ngInject */
        constructor(
            protected $scope: ConfigurableCtrlScope,
            protected $rootScope,
            protected Messages: aq.services.Messages,
            protected Segment: aq.services.SegmentService,
            protected UserService: aq.services.UserService,
            protected Errors,
            protected dashboard: aq.admin.dashboards.Dashboard,
            protected $mdDialog,
            protected $stateParams,
            protected $location,
            protected $interval: ng.IIntervalService,
            protected $timeout: ng.ITimeoutService,
            protected loading,
            protected AppStorageService,
            protected Auth,
            protected account,
            protected $state: ng.ui.IStateService,
            protected Restangular
        ) {
            if (this.loading) {
                this.loading.stop();
            }
            this.$scope.dashboard = dashboard;
            this.$scope.name = dashboard.name;
            $rootScope.$navigationState.currentState.data = {
                breadcrumb: dashboard.name
            };

            this.$scope.$on('widgetConfigUpdatedCustom', () => {
                this.$interval.cancel(this.detectChangesInterval);
                this.$timeout(() => this.updateDashboard(), 0);
            });

            this.$scope.$on('adfWidgetAdded', (event, name, model, widget) => {
                Segment.trackEvent(WidgetActions.ADD_WIDGET, {
                    widget: widget.type,
                    account: event.targetScope.accountName,
                    buildingIds: widget.buildingIds,
                    user: UserService.currentUser.id
                });
                // fix: deleteTemplateUrl is not getting initialized in widget definition via dashboardProvider (widgets.js)
                if (!widget.deleteTemplateUrl) { widget.deleteTemplateUrl = 'app/dashboard/adfTemplates/widget-delete.html'; }
            });

            aq.dashboard.scope.setPassThroughScope(dashboard, {
                isPublicDisplayApp: this.$state.includes('aq.publicDisplay'), // TODO: remove when publicDisplay app is removed
                refreshRates: aq.dashboard.RefreshOptions.RATES,
                autoRefreshMinutes: dashboard.autoRefreshMinutes,
                dashboardName: dashboard.name,
                internal: true,
                editModeIndicator: false,
                editModeText: 'Edit Mode',
                extraButtonsTemplateUrl: 'app/dashboard/adfTemplates/addons/title-buttons.html',
                previewModeText: '',
                editEnabled: () => {
                    return this.Auth.check({access: 'FULL_ACCESS'});
                },
                setDefault: () => {
                    this.setDefault();
                },
                isDefault: () => {
                    return this.isDefault();
                },
                addNewDashboard: () => {
                    this.createDashboard();
                },
                deleteDashboard: () => {
                    this.deleteDashboard();
                },
                editSettings: () => {
                    this.editSettings();
                },
                shareLink: this.generateShareLink(),
                showCopiedMessage: this.showCopiedMessage
            });

            $scope.$on('$destroy', () => {
                // Make sure that the interval is destroyed too
                this.$interval.cancel(this.currentRefresh);
                this.$interval.cancel(this.detectChangesInterval);
            });

            this.changeRefresh();
            this.startDetectingChanges();
        }

        public startDetectingChanges() {
            this.detectChangesInterval = this.$interval(() => this.processDashboardStructureChange(), 1000, null, false);
        }

        public updateDashboard() {
            const clonedDashboard = this.$scope.dashboard.clone();
            aq.dashboard.scope.removePassThroughScope(clonedDashboard);
            clonedDashboard.configuration.json = JSON.stringify(clonedDashboard.configuration.json, null, 2);
            clonedDashboard.put()
                .then(() => {
                    this.Messages.info('Updated Dashboard');
                }, () => {
                    this.Messages.error('Error Updating Dashboard');
                })
                .finally(() => {
                    this.startDetectingChanges();
                });
        }

        public processDashboardStructureChange() {
            const currentStructure = this.getDashboardStructure();
            if (!this.lastDashboardStructure) {
                this.lastDashboardStructure = currentStructure;
                return;
            }
            const isEqualStructure = angular.equals(currentStructure, this.lastDashboardStructure);
            if (!isEqualStructure) {
                this.$interval.cancel(this.detectChangesInterval);
                this.lastDashboardStructure = currentStructure;
                this.updateDashboard();
            }
        }

        public getDashboardStructure() {
            const config = this.dashboard.configuration.json as any;
            const structure = { rows: [] };
            this.initRows(config, structure);
            return structure;
        }

        public initRows(config, structure) {
            for (let i = 0; i < config.rows.length; i++) {
                structure.rows.push({ columns: [] });
                const currentRow = config.rows[i];
                const currentStructureRow = structure.rows[i];
                for (let j = 0; j < currentRow.columns.length; j++) {
                    currentStructureRow.columns.push({ widgets: [], rows: [] });
                    const currentColumn = currentRow.columns[j];
                    const currentStructureColumn = currentStructureRow.columns[j];
                    if (currentColumn.widgets) {
                        for (const widget of currentColumn.widgets) {
                            currentStructureColumn.widgets.push(widget.wid);
                        }
                    }
                    if (currentColumn.rows) {
                        this.initRows(currentColumn, currentStructureColumn);
                    }
                }
            }
        }

        public editSettings() {
            this.$mdDialog.show({
                controller: 'AddEditDashboardCtrl as vm',
                templateUrl: 'app/dashboard/main/actions/addEditDashboard/addEditDashboard.html',
                clickOutsideToClose: false,
                locals: {
                    account: this.account,
                    dashboard: this.Restangular.copy(this.dashboard),
                    userId: this.$scope.user.id
                }
            }).then((updatedDashboard) => {
                if (updatedDashboard) {
                    (this.$state as any).reload('aq.dashboard.configurable');
                    this.$scope.$emit('dashboardUpdatedEmit', {
                        id: updatedDashboard.id,
                        name: updatedDashboard.name
                    });
                }
            });
        }

        public deleteDashboard() {
            const confirm = this.$mdDialog
                .confirm()
                .title(`Are you sure you want to delete the dashboard "${this.dashboard.name}"?`)
                .ok('Confirm')
                .cancel('Cancel');

            return this.$mdDialog
                .show(confirm)
                .then(() => {
                    this.dashboard.remove()
                        .then(() => {
                            this.Messages.info('Dashboard deleted');
                            this.$scope.$emit('dashboardDeletedEmit', {
                                id: this.dashboard.id
                            });
                        }, () => {
                            this.Messages.error('Unable to perform delete action');
                        });
                });
        }

        public isDefault() {
            const storage = this.AppStorageService.getStorage();
            return storage.dashboardId == this.dashboard.id;
        }

        public setDefault() {
            let dashboardId;
            if (!this.isDefault()) {
                dashboardId = this.dashboard.id;
            }
            this.AppStorageService.setAttr('dashboardId', dashboardId);
            this.Messages.info('Default dashboard set.');
        }

        public changeRefresh() {
            if (this.currentRefresh) {
                this.$interval.cancel(this.currentRefresh);
            }
            if (this.$scope.dashboard.autoRefreshMinutes) {
                this.currentRefresh = this.$interval(() => {
                    this.$scope.$broadcast('widgetReload');
                }, this.$scope.dashboard.autoRefreshMinutes * 60 * 1000);
            }
        }

        public createDashboard() {
            this.$mdDialog.show({
                controller: 'AddEditDashboardCtrl as vm',
                templateUrl: 'app/dashboard/main/actions/addEditDashboard/addEditDashboard.html',
                clickOutsideToClose: false,
                locals: {
                    account: this.account,
                    dashboard: null,
                    userId: this.$scope.user.id
                }
            }).then((createdDashboard) => {
                this.$scope.$emit('newDashboardEmit', { dashboard: createdDashboard });
            });
        }

        public showCopiedMessage = () => {
            this.Messages.info('Link copied to clipboard!');
        }

        public generateShareLink(): string {
            // From https://stage.aquicore.com/accounts/12345/dashboard/whatever
            // Get our base url: https://stage.aquicore.com
            const absUrl = this.$location.absUrl();
            const baseUrl = absUrl.substring(0, absUrl.length - this.$location.$$url.length);
            return `${baseUrl}/public-display?${this.getGETParams(this.$stateParams)}`;
        }

        // Take our object's keys and get their string representation for use in GET
        // { first: 1, second: 'b', third: 3 } ==> first=1&second=b&third=3
        private getGETParams(params): string {
            const getParams = [];
            _.forIn(params, (value, key) => {
                if (value) {
                    getParams.push(key + '=' + value);
                }
            });
            const get = getParams.join('&');
            return get;
        }
    }

    export interface ConfigurableCtrlScope extends aq.dashboard.DashboardMainCtrlScope {
        name: string;
        dashboard: any;
        shareLink: string;
        titleTemplateUrl: string;
    }

    export class RefreshOptions {
        public static RATES = [
            { label: 'None', value: null },
            { label: '1 min', value: 1 },
            { label: '5 min', value: 5 },
            { label: '10 min', value: 10 },
            { label: '20 min', value: 20 },
            { label: '1 hour', value: 60 }
        ];
    }

    angular.module('dashboard').controller('ConfigurableCtrl', ConfigurableCtrl);
}
