/**
 * In some case virtual data can be negative. It should be properly displayed in charts.
 *
 * If we only have one serie with some negative values, turn them to null
 * If we have several series with positive and negative values:
 *  * if abs(neg value) > sum(positive values) turn all values to null
 *  * if abs(neg value) ~= sum(positive values) that means that overall it is close to 0, turn all values to null
 *  * if abs(neg value) < sum(positive values) then proportionally subtract abs(neg value) from all positive values,
 *                                             and turn neg value to null
 *
 *  Accepts a `data` array which is in format of result from forChartWidget filter
 */
angular.module('charting').filter('handleNegatives', function() {
    return function(data) {
        if (!data || _.isEmpty(data)) {
            return data;
        }
        var j = data.length;
        for (var i = 0; i < data[0].data.length; i++) {
            if (j == 1 && data[0].data[i][1] < 0) {
                data[0].data[i][1] = null;
            } else {
                var negativesSum = 0;
                var positivesSum = 0;
                _.each(data, function(elem) {
                    var value = elem.data[i][1];
                    if (value < 0) {
                        negativesSum += value;
                    } else {
                        positivesSum += value;
                    }
                });
                if (negativesSum < 0) {
                    _.each(data, function(elem) {
                        if (Math.abs(negativesSum) >= 0.99 * positivesSum) {
                            elem.data[i][1] = null;
                        } else {
                            var val = elem.data[i][1];
                            elem.data[i][1] = val + negativesSum * val / positivesSum;
                        }
                    })
                }
            }
        }
        return data;
    }
});