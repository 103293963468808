namespace aq.tenantbilling {
    export class TenantDetailsCtrl {
        public useRemittanceAddress: boolean;
        public currentNavItem: string;
        public tenantCharges: TenantCharge[];
        public tenantInvoices: any[];
        public generatedInvoicesCount: number;
        private tenantListState = 'aq.tenantbilling.building.tenants';
        private entityActions: aq.common.models.EntityMenuAction[];
        private backAction: aq.components.BackButtonData;
        private leaseTypes: any[];
        private deviceLinkStatuses;
        private paging = {
            limit: 10,
            page: 1
        };
        private areaMeasurementUnit;
        private leases: LeaseModel[];
        private currentLease: LeaseModel;
        private currentDate;
        private nullLease;
        tenantLabel: string;
        /* @ngInject */
        constructor(
            private RestangularV3: restangular.IService,
            private RestangularV3FullResponse: restangular.IService,
            private tenant: TenantModel,
            private accountId: string,
            private buildingId: string,
            private tenantId: string,
            private allEnums,
            private tenants: Tenant[],
            private tenantServices: TenantService[],
            private collectors: Collector[],
            public $mdDialog: ng.material.IDialogService,
            public $state: ng.ui.IStateService,
            private $mdMedia: ng.material.IMedia,
            private loading,
            private TenantServiceHelper: TenantServiceHelper,
            private MonthlyBillingService: MonthlyBillingService,
            private deviceLinks,
            private FileHandlerService: aq.services.FileHandlerService,
            private areaMeasurement,
            private Messages: aq.services.Messages,
            private $q: ng.IQService,
            private Auth: aq.services.Auth
        ) {
            if (this.tenant.id === undefined) {
                this.$state.go(this.tenantListState, {}, { reload: true });
                return;
            }
            this.TenantServiceHelper.getTenantCharges(this.tenant).then((results) => {
                this.tenantCharges = results;
            });
            this.TenantServiceHelper.getTenantInvoices(this.tenant)
                .then((tenantInvoices) => {
                    this.tenantInvoices = _.filter(tenantInvoices, (invoice) => {
                        return !!invoice.invoiceNumber;
                    });
                    this.generatedInvoicesCount = this.tenantInvoices.length;
                });
            this.loadLeases();

            this.currentDate = new Date().toDateString();
            this.nullLease = undefined;

            this.entityActions = [];
            if (this.Auth.check({ access: 'EDIT' })) {
                if (this.Auth.hasFunctionality('Multifamily Tenant Billing')) {
                    this.entityActions.push({ label: 'Create New Lease', cb: () => this.createLease() });
                }
                this.entityActions.push({ label: 'Add Charge', cb: () => this.addTenantCharge() });
                this.entityActions.push({ label: 'Download Invoice History', cb: () => this.downloadAllInvoices(), icon: 'file_download' });
            }
            this.currentNavItem = 'general';
            const backData = {
                updatedTenantId: this.tenant.id,
                timeStamp: new Date()
            };
            this.backAction = {
                state: 'aq.tenantbilling.building.tenants',
                stateData: backData,
                isDataSetOnSaveOnly: true
            };
            this.leaseTypes = this.allEnums.leaseTypes;

            this.deviceLinkStatuses = {
                active: {
                    icon: 'check_circle',
                    color: 'green',
                    tooltip: 'Meter is associated to a tenant charge.'
                },
                inactive: {
                    icon: 'do_not_disturb_on',
                    color: 'blue',
                    tooltip: 'Meter is not yet associated to a tenant charge. Add this meter to a charge in the Tenant Charges tab.'
                }
            };
            this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'unit' : 'tenant';
        }
        public isSelectedTab(name) {
            return this.currentNavItem === name;
        }
        public save(tenant) {
            this.TenantServiceHelper.updateTenant(tenant)
                .then((result) => {
                    this.tenant = (result as any);
                });
        }
        public disableDelete() {
            return _.some(this.tenantCharges, (tenantCharge) => {
                return tenantCharge.hasSharedAllocations;
            });
        }
        public getStatus(deviceLink) {
            if (deviceLink.active) {
                return this.deviceLinkStatuses.active;
            }
            return this.deviceLinkStatuses.inactive;
        }
        public deleteDeviceLink(deviceLink: aq.common.models.DeviceTenantLinkFull) {
            const confirm = this.$mdDialog
                .confirm()
                .textContent('Are you sure you want to delete this device link?')
                .ariaLabel('delete device link')
                .ok('Ok')
                .cancel('Cancel');
            this.$mdDialog
                .show(confirm)
                .then(() => {
                    return this.TenantServiceHelper.deleteDeviceLink(deviceLink);
                });
        }
        public delete(tenant) {
            const confirm = this.$mdDialog
                .confirm()
                .title('Are you sure you want to delete this Tenant?')
                .ok('Confirm')
                .cancel('Cancel');

            return this.$mdDialog
                .show(confirm)
                .then(() => {
                    this.TenantServiceHelper.deleteTenant(tenant)
                        .then(() => {
                            this.$state.go(this.tenantListState, { deletedTenantId: tenant.id });
                        });
                });
        }
        public createLease() {
            this.$mdDialog.show({
                controller: 'AddEditLeaseCtrl as vm',
                templateUrl: 'app/tenantBilling/tenants/actions/addEditLease/addEditLease.html',
                clickOutsideToClose: false,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    accountId: this.accountId,
                    buildingId: this.buildingId,
                    tenantId: this.tenant.id,
                    leaseTypes: this.allEnums.leaseTypes,
                    areaMeasurement: this.areaMeasurement,
                    lease: this.nullLease
                }
            }).then(() => {
                this.getCurrentLease();
                this.loadLeases();
            });
        }

        public editLease(lease) {
            let editLease;
            if (lease) {
                editLease = this.RestangularV3.copy(lease);
            } else {
                editLease = this.RestangularV3.copy(this.currentLease);
            }
            this.$mdDialog.show({
                controller: 'AddEditLeaseCtrl as vm',
                templateUrl: 'app/tenantBilling/tenants/actions/addEditLease/addEditLease.html',
                clickOutsideToClose: false,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    accountId: this.accountId,
                    buildingId: this.buildingId,
                    tenantId: this.tenant.id,
                    leaseTypes: this.allEnums.leaseTypes,
                    areaMeasurement: this.areaMeasurement,
                    lease: editLease
                }
            }).then(() => {
                this.loadLeases();
            });
        }

        public deleteLease(lease) {
            const confirm = this.$mdDialog
                .confirm()
                .title('Are you sure you want to delete this lease?')
                .ok('Confirm')
                .cancel('Cancel');

            return this.$mdDialog
                .show(confirm)
                .then(() => {
                    this.TenantServiceHelper.deleteLease(lease);
                    this.loadLeases();
                });
        }

        public addTenantCharge() {
            this.$mdDialog.show({
                controller: 'AddEditTenantChargeCtrl as vm',
                templateUrl: 'app/tenantBilling/tenants/actions/addEditTenantCharge/addEditTenantCharge.html',
                clickOutsideToClose: false,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    accountId: this.accountId,
                    buildingId: this.buildingId,
                    tenantId: this.tenantId,
                    tenantName: this.tenant.name,
                    tenants: this.tenants,
                    tenantServices: this.tenantServices,
                    tenantCharge: null,
                    allocations: [],
                    collectors: this.collectors,
                    unlinkedDevices: _.filter(this.deviceLinks, (deviceLink) => {
                        return !deviceLink.active;
                    })
                }
            }).then(() => {
                this.reloadTenantChargesAndDeviceLinks();
                this.currentNavItem = 'charges';
            });
        }
        public editTenantCharge(item) {
            const editItem = this.RestangularV3.copy(item);
            this.$mdDialog.show({
                controller: 'AddEditTenantChargeCtrl as vm',
                templateUrl: 'app/tenantBilling/tenants/actions/addEditTenantCharge/addEditTenantCharge.html',
                clickOutsideToClose: false,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    accountId: this.accountId,
                    buildingId: this.buildingId,
                    tenantId: this.tenantId,
                    tenantName: this.tenant.name,
                    tenants: this.tenants,
                    tenantServices: this.tenantServices,
                    tenantCharge: editItem,
                    collectors: this.collectors,
                    unlinkedDevices: _.filter(this.deviceLinks, (deviceLink) => {
                        return !deviceLink.active;
                    })
                },
                resolve: {
                    allocations: () => {
                        if (item.type === 'FIXED') {
                            return [];
                        }
                        return this.RestangularV3.all('metered-tenant-charge-allocations')
                            .getList({ tenantChargeId: item.id });
                    }
                }
            } as any).then(() => {
                this.reloadTenantChargesAndDeviceLinks();
            });
        }
        public deleteTenantCharge(tenantChargeToDelete) {
            const confirm = this.$mdDialog
                .confirm()
                .title('Are you sure you want to delete this Tenant Charge?')
                .ok('Confirm')
                .cancel('Cancel');

            return this.$mdDialog
                .show(confirm)
                .then(() => {
                    this.loading.start();
                    this.TenantServiceHelper.deleteTenantCharge(tenantChargeToDelete)
                        .then(() => {
                            _.remove(this.tenantCharges, (tc) => tc.id == tenantChargeToDelete.id);
                        })
                        .finally(() => {
                            this.loading.stop();
                        });
                });
        }
        public clearImage() {
            this.tenant.imageUrl = null;
            this.tenant.imageThumbnailUrl = null;
        }
        public downloadInvoice(invoiceId) {
            return this.RestangularV3FullResponse.one('tenant-invoices', invoiceId)
                .all('download')
                .withHttpConfig({ responseType: 'blob' })
                .customGET('invoice')
                .then((response) => {
                    this.FileHandlerService.handleFileDownload(response, 'application/pdf');
                })
                .catch(() => {
                    this.Messages.error('Error while generating report');
                });
        }

        public downloadAllInvoices() {
            try {
                if (this.tenantInvoices != null) {
                    const csvContent = this.getInvoiceCSVData();
                    const fileName = `${this.tenant.name} Invoice History.csv`;
                    const link = document.createElement('a');
                    link.setAttribute('href', encodeURI(csvContent));
                    link.setAttribute('download', fileName);
                    link.click();
                } else {
                    setTimeout(() => { this.downloadAllInvoices(); }, 1000);
                }
            } catch (e) {
                this.Messages.error('Something went wrong downloading the invoice history!');
            }
        }

        private getInvoiceCSVData(): string {
            let csvContent = 'data:text/csv;charset=utf-8,';
            csvContent += 'invoice, invoice date, period start, period end, charge\n';
            const fields = ['invoiceNumber', 'invoiceDate', 'startReadingDate', 'readingDate', 'totalCharge'];
            this.tenantInvoices.forEach((index) => {
                const row = new Array(fields.length);
                for (const key in index) {
                    if (index.hasOwnProperty(key) && fields.includes(key)) {
                        row[fields.indexOf(key)] = `${index[key]}`;
                    }
                }
                csvContent += `${row}\n`;
            });
            return csvContent;
        }

        private getServiceName(serviceId) {
            const service = _.find(this.tenantServices, (s) => s.id == serviceId);
            return service ? service.name : '';
        }

        private reloadTenantChargesAndDeviceLinks() {
            this.loading.start();
            const promises = [];
            promises.push(this.TenantServiceHelper.getTenantCharges(this.tenant));
            promises.push(this.TenantServiceHelper.getDeviceTenantLinks(this.tenant));

            this.$q.all(promises)
                .then((results: any[]) => {
                    this.tenantCharges = results[0];
                    this.deviceLinks = results[1];
                })
                .finally(() => {
                    this.loading.stop();
                });
        }

        private getCurrentLease() {
            this.currentLease = null;
            const currentDate = new Date().getTime();
            this.leases.forEach((lease) => {
                if (lease.active) {
                    const startDate = new Date(lease.startDate).getTime();
                    const endDate = lease.endDate ? new Date(lease.endDate).getTime() : null;
                    if (currentDate >= startDate && ((endDate && currentDate <= endDate) || (endDate === null))) {
                        this.currentLease = lease;
                    }
                }
            });
        }

        private loadLeases() {
            this.TenantServiceHelper.getLeases(this.tenant.id).then((leases) => {
                leases.forEach(lease => {
                    this.TenantServiceHelper.getResidents(lease.id).then((residents) => {
                        lease.residents = residents;
                        lease.residentsNames = '';
                        residents.forEach((resident) => {
                            if (resident === [...residents].pop()) {
                                lease.residentsNames += resident.name;
                            } else {
                                lease.residentsNames += `${resident.name}, `;
                            }
                        });
                    });
                });
                this.leases = leases;
                this.getCurrentLease();
            });
        }

        private formatDate(dateString) {
            return moment.utc(dateString).format('MMM D, YYYY');
        }
    }
    angular.module('tenantBilling').controller('TenantDetailsCtrl', TenantDetailsCtrl);
}
