namespace aq.utilityBudgets {

    export class AccrualSubmitModalCtrl {
        public readonly emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        public isSendAccounting: boolean;
        public isSendApprovers: boolean;
        public isSendMyself: boolean;
        public isSendCustomEmails: boolean;
        public customEmails: string[];
        public isCurrentMonth: boolean;
        public isNotSubmittedAccrualFromPreviousMonth: boolean;
        public isSubmitting: boolean;

        /* ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private RestangularV3: restangular.IService,
            private accrual: aq.utilityBudgets.AccrualResponse,
            private settings: aq.utilityBudgets.AccrualViewSettings,
            private Messages: services.Messages
        ) {
            this.isSubmitting = false;
            this.settings.isSubmitView = true;
            this.customEmails = [];
            this.isCurrentMonth = moment().isSame(moment(this.accrual.startDate), 'month');
            this.isNotSubmittedAccrualFromPreviousMonth = moment().subtract(1, 'month').isSame(moment(this.accrual.startDate), 'month')
                && this.accrual.status != AccrualStatus.SUBMITTED;
        }
        public onAddCustomEmail(item: string) {
            if (!this.validateEmail(item)) {
                this.customEmails = _.filter(this.customEmails, (email) => email != item);
            }
        }
        public cancel(rejectData?: any) {
            this.$mdDialog.cancel(rejectData);
        }
        public save() {
            if (this.isSubmitting) {
                return;
            }
            this.isSubmitting = true;
            const accrualUpdateRequest: AccrualUpdateRequest = {
                id: this.accrual.id,
                accruedStartDate: this.accrual.accruedStartDate,
                accruedEndDate: this.accrual.accruedEndDate,
                accruedExpense: this.accrual.accruedExpense,
                accruedConsumption: this.accrual.accruedConsumption,
                currentExpense: this.accrual.currentExpense,
                currentConsumption: this.accrual.currentConsumption
            }
            this.RestangularV3.one('accruals', this.accrual.id)
                .customPUT(accrualUpdateRequest)
                .then((accrualResponse) => {
                    this.$mdDialog.hide(accrualResponse);
                    this.Messages.success('Accrued value is saved.');
                })
                .catch(() => {
                    this.Messages.error('Unable to update Accrual, please contact your Administrator');
                })
                .finally(() => this.isSubmitting = false);
        }
        public submit() {
            if (this.isSubmitting) {
                return;
            }
            this.isSubmitting = true;
            const accrualSubmitRequest: AccrualSubmitRequest = {
                id: this.accrual.id,
                sendAccounting: this.isSendAccounting,
                sendApprovers: this.isSendApprovers,
                sendMyself: this.isSendMyself,
                accruedExpense: this.accrual.accruedExpense,
                customEmails: this.isSendCustomEmails ? this.customEmails : []
            }

            this.RestangularV3.one('accruals', this.accrual.id)
                .customPOST(accrualSubmitRequest, 'submit')
                .then((accrualResponse) => {
                    this.$mdDialog.hide(accrualResponse);
                    this.Messages.success(`Successfully submitted your ${moment(this.accrual.startDate).format('MMMM')} Accrual.`);
                })
                .catch(() => {
                    this.Messages.error('Unable to submit Accrual, please contact your Administrator');
                })
                .finally(() => this.isSubmitting = false);
        }
        public validateEmail(email) {
            return this.emailRegex.test(String(email).toLowerCase());
        }
    }
    angular.module('aq.utilityBudgets').controller('AccrualSubmitModalCtrl', AccrualSubmitModalCtrl);
}
