namespace aq.crud {

    export class CrudActionCtrl {
        public onCreateFn: Function;
        public onSaveFn: Function;
        public onDeleteFn: Function;
        public showDelete: boolean;
        public downloadCsvFn: Function;
        public downloadExcel: Function;
        public bulkUpdateFn: Function;
        public showSave: boolean;
        public displayAsMenu: boolean;
        public primaryAction: string;
        public menuItems: any[];
        public onCreateLabel: string = 'Create';
        public onSaveLabel: string = 'Save';
        public onDeleteLabel: string = 'Delete';
        public downloadCsvLabel: string = 'Download CSV';
        public downloadExcelLabel: string = 'Download Excel';
        public bulkUpdateLabel: string = 'Bulk Update';
        public access: common.models.AppAccessObject;

        constructor() {
            this.menuItems = [
                this.bulkUpdateFn,
                this.downloadCsvFn,
                this.downloadExcel,
                this.onCreateFn,
                this.showSave,
                this.showDelete,
                this.onSaveFn,
                this.onDeleteFn].filter(val => !!val);

            this.displayAsMenu = this.menuItems.length - (this.primaryAction ? 1 : 0 ) > 1;
        }
        public canCreate() {
            return !this.access || this.access.FULL_ACCESS;
        }
        public canDelete() {
            return !this.access || this.access.FULL_ACCESS;
        }
        public canEdit() {
            return !this.access || this.access.EDIT;
        }
    }

    angular
        .module('aq.ui')
        .component('aqCrudActions', {
            templateUrl: 'app/common/directives/crud/actions.html',
            bindings: {
                onCreateFn: '&?',
                onCreateLabel: '<?',
                onSaveFn: '&?',
                onSaveLabel: '<?',
                saveDisabled: '<?',
                onDeleteFn: '&?',
                onDeleteLabel: '<?',
                deleteDisabled: '<?',
                downloadCsvFn: '&?',
                downloadExcel: '&?',
                downloadCsvLabel: '<?',
                showSave: '<?',
                primaryAction: '<?',
                bulkUpdateFn: '&?',
                bulkUpdateLabel: '<?',
                access: '<?'
            },
            controller: CrudActionCtrl
        });

}
