angular.module('aq.admin.meters.management').controller('AdminMeterStatusCtrl',
    function($scope, Restangular, loading, Errors, $q, accounts, Messages) {

    $scope.sortBy = 'name';
    $scope.descending = false;

    $scope.onOptionsLoad = function(data) {
        $scope.initialOptions = data.options;
        var buildings = {
            type: 'select',
            name: 'building',
            items: _(accounts).map('buildings').flatten().map('name').value()
        }
        $scope.initialOptions.push(buildings);
        return $q.when($scope.initialOptions);
    };

    $scope.queryData = function (params) {
        $scope.filterParams = params;
        loading.start();
        $scope.limit = 50;
        // we need to copy params object before assigning new property to it or dynamic filter will try to build filters for it
        var requestParams = angular.copy(params.params);
        _.extend(requestParams, {
            statistic:  ['gateway', 'device', 'pointsCount']
        });

        return Restangular.all('users').get('queryMetersStatus', requestParams).then(function(filterResponse) {

            // Adding parent collector details to the response
            _.map(filterResponse.results, function(meter) {
                if (meter.parentCollector) {
                    return _.extend(meter, { parentDevice:
                        _.find(filterResponse.results, { id: meter.parentCollector})});
                }
            });

            $scope.filterResponse = filterResponse;
        }, Errors.forPromise()).finally(loading.stop);
    };

    $scope.downloadCsv = function() {
        var requestParams = angular.copy($scope.filterParams.params);

        if (!$scope.account) return;

        Restangular.one('accounts').customGET("meterStatusCsv", requestParams).then(function (result) {
            $scope.sendFile($scope.account.getRestangularUrl() + "/meterStatusCsv" + serialize(requestParams));
        }).catch(function(error) {
            Messages.error(error.data.message);
        });;   
    }

    $scope.sendFile = function(url) {
        location.href = url;
    }

    function serialize( obj ) {
        return '?'+Object.keys(obj).reduce(function(a,k){a.push(k+'='+encodeURIComponent(obj[k]));return a},[]).join('&')
    }

    $scope.changeSort = function(sortBy) {
        if ($scope.sortBy == sortBy) {
            $scope.descending = !$scope.descending;
        } else {
            $scope.sortBy = sortBy;
            $scope.descending = false;
        }
    };

   $scope.increaseLimit = function(amount) {
        $scope.limit += amount;
    };

});
