'use strict';

angular.module('aq.reports').controller('dailyReportDemoCtrl',
    function ($filter, $q, $scope, DailyCharts, ReportApiParams, ReportService, DataService, MonthlyOccupancyData, Auth) {

        $scope.loading = true;
        ReportService.then(function (report: aq.reports.Report) {
            $scope.report = report;
            const building = report.building;
            $scope.time = angular.copy(building.currentTime);
            $scope.time.subtract(1, 'day');

            const apiParams = new ReportApiParams($scope.time, report.measures, 'daily');

            // TODO for now we must query temperature separately because /data endpoint doesn't support temperature
            // (it is from virtual meter which is excluded)
            const temperaturePromise = building.customGET('temperature', apiParams.current());
            const dayDataPromise = building.customGET('data', apiParams.current());
            const dayPreviousDataPromise = building.customGET('data', apiParams.previous());

            const dayExpectedDataPromise = DataService.expectedEnergy(building, apiParams.current().interval,
                                                                      apiParams.current().start, apiParams.current().end);

            const degreeDaysPromise = DataService.degreeDays(building,
                apiParams.currentDegreeDay().interval,
                apiParams.currentDegreeDay().start,
                apiParams.currentDegreeDay().end);
            const previousDegreeDaysPromise = DataService.degreeDays(building,
                apiParams.previousDegreeDay().interval,
                apiParams.previousDegreeDay().start,
                apiParams.previousDegreeDay().end);

            $q.all([dayDataPromise,
                dayPreviousDataPromise,
                temperaturePromise,
                degreeDaysPromise,
                previousDegreeDaysPromise,
                dayExpectedDataPromise
            ]).then(function (result) {
                $scope.loading = false;

                const dayData = result[0];
                const previousDayData = result[1];
                const temperatureData = result[2];
                const dayDegreeDaysData = result[3];
                const previousdayDegreeDaysData = result[4];
                const dayExpectedData = result[5];

                $scope.currentHDD = dayDegreeDaysData.hdd.total;
                $scope.currentCDD = dayDegreeDaysData.cdd.total;

                $scope.previousHDD = previousdayDegreeDaysData.hdd.total;
                $scope.previousCDD = previousdayDegreeDaysData.cdd.total;

                $scope.usage = _.sum(dayData[report.usageMetric].values);
                const demandFn: Function = report.isWaterOrGasReport ? _.mean : _.max;

                $scope.demand = demandFn(dayData[report.demandMetric].values);

                $scope.previousUsage = _.sum(previousDayData[report.usageMetric].values);
                $scope.previousDemand = demandFn(previousDayData[report.demandMetric].values);
                if (!$scope.report.isWaterOrGasReport) {
                    $scope.expectedUsage = getExpectedUsage(dayExpectedData[report.expectedDemandMetric].values);
                    $scope.expectedDemand = demandFn(dayExpectedData[report.expectedDemandMetric].values);
                }

                $scope.monthIndex = $scope.time.format('M');

                $scope.occupancyData = MonthlyOccupancyData.getMonthlyOccupancy(building, $scope.monthIndex);

                function checkIfHasData(elem) {
                    return _.some(report.measures, function (metric: string) {
                        return elem[metric.toLowerCase()].values.length > 0;
                    });
                }

                function getExpectedUsage(expectedDemandValues) {
                    let i = 0;
                    let expectedUsageValue: number = 0; ;
                    while (i < expectedDemandValues.length) {
                        const hourValues = _.slice(expectedDemandValues, i, i + 4);
                        const average = _.mean(hourValues);
                        expectedUsageValue += average;
                        i = i + 4;
                    }
                    // convert watt hour to watt seconds
                    return expectedUsageValue * 3600;
                }

                $scope.hasData = checkIfHasData(dayData) || checkIfHasData(previousDayData);

                const dailyCharts = new DailyCharts(dayData, previousDayData, dayExpectedData, report);

                if (!report.isWaterOrGasReport) {
                    /* Daily Demand chart data */
                    $scope.dailyDemand = dailyCharts.getDailyDemandChart();

                    /* Daily Demand chart data expected*/
                    $scope.dailyDemandExpected = dailyCharts.getDailyDemandChartExpected();

                    /* Variance chart data */
                    $scope.varianceChart = dailyCharts.getVarianceChart();
                }

                /* Water chart data */
                if (report.isWaterOrGasReport) {
                    $scope.waterChart = dailyCharts.getWaterChart(temperatureData);
                }

            });
        });
    });
