namespace aq.services {
    export class FileHandlerService {
        /* @ngInject */
        constructor(
            private $window: ng.IWindowService
        ) { }

        public handleFileDownload(response, contentType) {
            const contentDisposition = response.headers()['content-disposition'];
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const fileNameMatch = filenameRegex.exec(contentDisposition);
            let filename;
            if (fileNameMatch != null && fileNameMatch[1]) {
                filename = fileNameMatch[1].replace(/['"]/g, '');

            }
            const blob = new Blob([response.data], { type: contentType });
            const url = this.$window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.download = filename;
            a.href = url;
            document.body.appendChild(a);
            a.click();
            this.$window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }
    }
    angular.module('aq.services').service('FileHandlerService', FileHandlerService);
}
