var aq;
(function (aq) {
    var dashboard;
    (function (dashboard) {
        var widgets;
        (function (widgets) {
            var DateRange;
            (function (DateRange) {
                DateRange["ALL_TIME"] = "ALL_TIME";
                DateRange["TRAILING_3_MONTHS"] = "TRAILING_3_MONTHS";
                DateRange["TRAILING_12_MONTHS"] = "TRAILING_12_MONTHS";
            })(DateRange || (DateRange = {}));
            var ProjectSummaryWidgetCtrl = /** @class */ (function () {
                /* @ngInject */
                function ProjectSummaryWidgetCtrl($scope, config, account, buildings, $filter, ModelUtilService, ProjectService, ProjectServiceV3, users, projectTypes, buildingGroups, BuildingSelectorActions, isProjectsV3, Auth, $q) {
                    var _a;
                    this.$scope = $scope;
                    this.config = config;
                    this.account = account;
                    this.buildings = buildings;
                    this.$filter = $filter;
                    this.ModelUtilService = ModelUtilService;
                    this.ProjectService = ProjectService;
                    this.ProjectServiceV3 = ProjectServiceV3;
                    this.users = users;
                    this.projectTypes = projectTypes;
                    this.buildingGroups = buildingGroups;
                    this.BuildingSelectorActions = BuildingSelectorActions;
                    this.isProjectsV3 = isProjectsV3;
                    this.Auth = Auth;
                    this.$q = $q;
                    this.displayBuildingsCount = 5;
                    this.intervals = [
                        DateRange.TRAILING_3_MONTHS,
                        DateRange.TRAILING_12_MONTHS,
                        DateRange.ALL_TIME,
                    ];
                    this.intervalLabels = (_a = {},
                        _a[DateRange.TRAILING_3_MONTHS] = 'Trailing 3 months',
                        _a[DateRange.TRAILING_12_MONTHS] = 'Trailing 12 months',
                        _a[DateRange.ALL_TIME] = 'All time',
                        _a);
                    this.request = {};
                    this.isLoadingData = true;
                    this.selectedBuildingNames = [];
                    config.options = {
                        isProjectsV3: this.isProjectsV3,
                        buildings: ModelUtilService.pareProperties(this.buildings, ['buildingGroup']),
                        buildingGroups: ModelUtilService.pareProperties(this.buildingGroups),
                        dateRanges: this.isProjectsV3 && this.getDateRangeItems()
                    };
                    if (this.isProjectsV3 && !config.filterBy) {
                        config.filterBy = {
                            dateRange: DateRange.TRAILING_3_MONTHS
                        };
                    }
                    this.BuildingSelectorActions.initDefaultBuildingSelection(config);
                    this.$scope.config = config;
                    this.$scope.config.actions = this.BuildingSelectorActions;
                    if (this.isProjectsV3) {
                        this.selectedInterval = config.filterBy.dateRange;
                        if (this.selectedInterval !== DateRange.TRAILING_3_MONTHS
                            && this.selectedInterval !== DateRange.TRAILING_12_MONTHS
                            && this.selectedInterval !== DateRange.ALL_TIME) {
                            this.selectedInterval = DateRange.TRAILING_3_MONTHS;
                        }
                        this.selectedBuildingIds = config.buildingIds;
                        this.request.buildingIds = this.selectedBuildingIds;
                        this.initDateRangeDisplay();
                        this.populateSummaryV3();
                    }
                    else {
                        this.populateSummary();
                    }
                }
                ProjectSummaryWidgetCtrl.prototype.selectInterval = function (interval) {
                    this.selectedInterval = interval;
                    this.initDateRangeDisplay();
                    this.populateSummaryV3();
                };
                ProjectSummaryWidgetCtrl.prototype.initDisplayBuildings = function () {
                    this.displayBuildings = _.take(this.selectedBuildingNames, this.displayBuildingsCount);
                };
                ProjectSummaryWidgetCtrl.prototype.initStatusDetailsItems = function () {
                    this.statusDetailsItems = [
                        {
                            caption: 'Total Year-One Impact',
                            dataValueDisplay: '-',
                            dataValueIcon: null,
                            diffValue: 0,
                            diffValueInfo: '',
                            diffValueDisplay: '',
                            hideDiffInfo: true
                        },
                        {
                            caption: 'Total projects completed',
                            dataValueDisplay: '-',
                            dataValueIcon: 'golf_course',
                            diffValue: 0,
                            diffValueInfo: '',
                            diffValueDisplay: '',
                            hideDiffInfo: true
                        },
                        {
                            caption: 'Median time to completion',
                            dataValueDisplay: '-',
                            dataValueIcon: 'schedule',
                            diffValue: 0,
                            diffValueInfo: '',
                            diffValueDisplay: '',
                            hideDiffInfo: true
                        }
                    ];
                };
                ProjectSummaryWidgetCtrl.prototype.getDateRangeItems = function () {
                    return Object.keys(DateRange).map(function (key) { return DateRange[key]; });
                };
                ProjectSummaryWidgetCtrl.prototype.initDateRangeDisplay = function () {
                    this.request.endDate = moment().toISOString();
                    switch (this.selectedInterval) {
                        case DateRange.ALL_TIME:
                            this.request.startDate = null;
                            break;
                        case DateRange.TRAILING_12_MONTHS:
                            this.request.startDate = moment().subtract(12, 'months').toISOString();
                            break;
                        case DateRange.TRAILING_3_MONTHS:
                        default:
                            this.request.startDate = moment().subtract(3, 'months').toISOString();
                            break;
                    }
                    this.startFormatted = this.request.startDate && moment(this.request.startDate).format('M/D/YYYY');
                    this.endFormatted = 'Today';
                };
                ProjectSummaryWidgetCtrl.prototype.populateProjectsV3 = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var threeMonthsAgo, aYearAgo;
                        var _this = this;
                        return __generator(this, function (_a) {
                            this.$scope.projects = [];
                            threeMonthsAgo = moment().subtract(3, 'month');
                            aYearAgo = moment().subtract(1, 'year');
                            this.$scope.config.buildingIds.forEach(function (building) {
                                _this.ProjectServiceV3.getProjects(building).then(function (projectResponse) {
                                    var _a;
                                    var filteredProjects = projectResponse.projects.filter(function (project) {
                                        if (project.status.name === 'Completed') {
                                            var completed = project.completedOn ? moment(project.completedOn) : null;
                                            switch (_this.selectedInterval) {
                                                case DateRange.TRAILING_3_MONTHS:
                                                    return threeMonthsAgo.isSameOrBefore(completed);
                                                case DateRange.TRAILING_12_MONTHS:
                                                    return aYearAgo.isSameOrBefore(completed);
                                                case DateRange.ALL_TIME:
                                                default:
                                                    return true;
                                            }
                                        }
                                        return false;
                                    });
                                    (_a = _this.$scope.projects).push.apply(_a, filteredProjects);
                                });
                            });
                            return [2 /*return*/];
                        });
                    });
                };
                ProjectSummaryWidgetCtrl.prototype.populateSummaryV3 = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var buildingId, projectResponse, data, trailingData, trailingEnd, diff_1, trailingStart, trailingRequest, dataSavings, dataTimeList, trailingDataSavings, trailingDataTimeList, dataSavingsDiff, dataTime, trailingDataTime, dataTimeDays, trailingDataTimeDays, dataTimeDiff, dataTimeDiffAbs, dataCount, trailingDataCount, dataCountDiff, sinceInfo, diff_2;
                        var _this = this;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    this.initStatusDetailsItems();
                                    return [4 /*yield*/, this.populateProjectsV3()];
                                case 1:
                                    _a.sent();
                                    if (this.selectedBuildingIds.length == 0) {
                                        return [2 /*return*/];
                                    }
                                    if (!!this.statuses) return [3 /*break*/, 3];
                                    buildingId = this.selectedBuildingIds[0];
                                    return [4 /*yield*/, this.ProjectServiceV3.getProjects(buildingId)];
                                case 2:
                                    projectResponse = _a.sent();
                                    this.statuses = projectResponse && projectResponse.statuses;
                                    this.completedStatusId = this.statuses.find(function (status) { return status.name == 'Completed'; }).id;
                                    this.inProgressStatusId = this.statuses.find(function (status) { return status.name == 'In Progress'; }).id;
                                    _a.label = 3;
                                case 3:
                                    this.request.buildingIds = this.selectedBuildingIds;
                                    this.request.statusIds = [this.completedStatusId];
                                    return [4 /*yield*/, this.ProjectServiceV3.getStatusDetails(this.request)];
                                case 4:
                                    data = _a.sent();
                                    trailingData = null;
                                    if (!this.request.startDate) return [3 /*break*/, 6];
                                    trailingEnd = moment(this.request.startDate);
                                    diff_1 = moment(this.request.endDate).diff(moment(this.request.startDate), 'months');
                                    trailingStart = moment(trailingEnd).subtract(diff_1, 'months');
                                    trailingRequest = __assign({}, this.request);
                                    trailingRequest.startDate = trailingStart.toISOString();
                                    trailingRequest.endDate = trailingEnd.toISOString();
                                    return [4 /*yield*/, this.ProjectServiceV3.getStatusDetails(trailingRequest)];
                                case 5:
                                    trailingData = _a.sent();
                                    _a.label = 6;
                                case 6:
                                    dataSavings = 0;
                                    dataTimeList = [];
                                    if (this.Auth.hasFunctionality(PROJECT_ESTIMATION)) {
                                        this.$scope.projects.forEach(function (project) {
                                            dataSavings += _this.ProjectServiceV3.getImpactValue(project, true, true).projectImpact;
                                        });
                                    }
                                    data.forEach(function (item) {
                                        if (!_this.Auth.hasFunctionality(PROJECT_ESTIMATION)) {
                                            dataSavings += item.dollarSavingsMax || 0;
                                        }
                                        if (item.timeInStatus[_this.inProgressStatusId]) {
                                            dataTimeList.push(item.timeInStatus[_this.inProgressStatusId] || 0);
                                        }
                                    });
                                    trailingDataSavings = 0;
                                    trailingDataTimeList = [];
                                    if (trailingData) {
                                        trailingData.forEach(function (item) {
                                            trailingDataSavings += item.dollarSavings || 0;
                                            if (item.timeInStatus[_this.inProgressStatusId]) {
                                                trailingDataTimeList.push(item.timeInStatus[_this.inProgressStatusId] || 0);
                                            }
                                        });
                                    }
                                    dataSavingsDiff = dataSavings - trailingDataSavings;
                                    dataTime = this.getMedian(dataTimeList);
                                    trailingDataTime = this.getMedian(trailingDataTimeList);
                                    dataTimeDays = this.getDaysFromMilliseconds(dataTime);
                                    trailingDataTimeDays = this.getDaysFromMilliseconds(trailingDataTime);
                                    dataTimeDiff = dataTimeDays - trailingDataTimeDays;
                                    dataTimeDiffAbs = Math.abs(dataTimeDiff);
                                    dataCount = this.Auth.hasFunctionality(PROJECT_ESTIMATION) ? this.$scope.projects.length : data.length;
                                    trailingDataCount = trailingData && trailingData.length;
                                    dataCountDiff = dataCount - trailingDataCount;
                                    sinceInfo = '';
                                    if (this.request.startDate && this.request.endDate) {
                                        diff_2 = moment(this.request.endDate).diff(moment(this.request.startDate), 'months');
                                        sinceInfo = "since trailing " + diff_2 + " months";
                                    }
                                    this.statusDetailsItems = [
                                        {
                                            caption: 'Total Year-One Impact',
                                            dataValueDisplay: this.$filter('currency')(dataSavings, '$', 0),
                                            dataValueIcon: null,
                                            diffValue: dataSavingsDiff,
                                            diffValueInfo: sinceInfo,
                                            diffValueDisplay: this.$filter('currency')(Math.abs(dataSavingsDiff), '$', 0),
                                            hideDiffInfo: !trailingData
                                        },
                                        {
                                            caption: 'Total projects completed',
                                            dataValueDisplay: dataCount.toString(),
                                            dataValueIcon: 'golf_course',
                                            diffValue: dataCountDiff,
                                            diffValueInfo: sinceInfo,
                                            diffValueDisplay: Math.abs(dataCountDiff).toString(),
                                            hideDiffInfo: !trailingData
                                        },
                                        {
                                            caption: 'Median time to completion',
                                            dataValueDisplay: dataTimeDays + " day" + (dataTimeDays === 1 ? '' : 's'),
                                            dataValueIcon: 'schedule',
                                            diffValue: dataTimeDiff,
                                            diffValueInfo: sinceInfo,
                                            diffValueDisplay: dataTimeDiffAbs + " day" + (dataTimeDiffAbs === 1 ? '' : 's'),
                                            hideDiffInfo: !trailingData
                                        }
                                    ];
                                    this.$scope.$apply();
                                    return [2 /*return*/];
                            }
                        });
                    });
                };
                ProjectSummaryWidgetCtrl.prototype.getMedian = function (values) {
                    if (!values || values.length === 0) {
                        return 0;
                    }
                    var sortedValues = values.sort(function (a, b) { return a - b; });
                    if (sortedValues.length % 2 === 1) {
                        var index = (sortedValues.length - 1) / 2;
                        return sortedValues[index];
                    }
                    var index1 = Math.floor((sortedValues.length - 1) / 2);
                    var index2 = Math.ceil((sortedValues.length - 1) / 2);
                    return Math.round((sortedValues[index1] + sortedValues[index2]) / 2);
                };
                ProjectSummaryWidgetCtrl.prototype.getDaysFromMilliseconds = function (ms) {
                    var dayMilliseconds = 86400000; // 1000 * 60 * 60 * 24;
                    return Math.ceil(ms / dayMilliseconds);
                };
                ProjectSummaryWidgetCtrl.prototype.populateSummary = function () {
                    var _this = this;
                    this.$scope.numberCompletedProjects = 0;
                    this.$scope.numberInProgressProjects = 0;
                    this.$scope.numberPlannedProjects = 0;
                    this.$scope.totalInProgressCost = 0;
                    this.$scope.totalPlannedCost = 0;
                    this.$scope.totalCompletedCost = 0;
                    this.$scope.totalSavings = 0;
                    this.$scope.totalInProgressSavings = 0;
                    this.$scope.totalPlannedSavings = 0;
                    this.$scope.totalCompletedSavings = 0;
                    this.config.buildingIds.forEach(function (buildingId) {
                        _this.ProjectService.getList(buildingId, _this.users, _this.projectTypes).then(function (projects) {
                            projects.forEach(function (project) {
                                if (project.status === 'PLANNED') {
                                    _this.$scope.numberPlannedProjects++;
                                    _this.$scope.totalPlannedCost += project.cost;
                                    _this.$scope.totalPlannedSavings += project.expectedSavings;
                                }
                                else if (project.status === 'IMPLEMENTATION' || project.status === 'MEASUREMENT') {
                                    _this.$scope.numberInProgressProjects++;
                                    _this.$scope.totalInProgressCost += project.cost;
                                    _this.$scope.totalInProgressSavings += project.expectedSavings;
                                }
                                else if (project.status === 'COMPLETED') {
                                    _this.$scope.numberCompletedProjects++;
                                    _this.$scope.totalCompletedCost += project.cost;
                                    _this.$scope.totalCompletedSavings += project.expectedSavings;
                                }
                                _this.$scope.totalSavings += project.expectedSavings;
                            });
                        });
                    });
                };
                return ProjectSummaryWidgetCtrl;
            }());
            widgets.ProjectSummaryWidgetCtrl = ProjectSummaryWidgetCtrl;
            angular.module('aq.dashboard.widgets').controller('ProjectSummaryWidgetCtrl', ProjectSummaryWidgetCtrl);
        })(widgets = dashboard.widgets || (dashboard.widgets = {}));
    })(dashboard = aq.dashboard || (aq.dashboard = {}));
})(aq || (aq = {}));
