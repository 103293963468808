namespace aq.integrations.mri {

    export class MRICtrl {
        /* @ngInject */
        constructor(
            private $mdDialog,
            public buildings,
            public mriIntegrations
        ) { }

        public edit(building) {
            const mriInformationForBuilding = _.find(this.mriIntegrations, {building: building.id});
            this.$mdDialog.show({
                controller: 'MRIEditModalCtrl as vm',
                templateUrl: 'app/settings/integrations/details/mri/mriEditModal.html',
                clickOutsideToClose: true,
                locals: {
                    mriInformationForBuilding,
                    building,
                    mriIntegrations: this.mriIntegrations
                }
            });
        }

    }

    angular
        .module('aq.integrations')
        .controller('MRICtrl', MRICtrl);
}
