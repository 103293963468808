var aq;
(function (aq) {
    var utilityBudgets;
    (function (utilityBudgets) {
        var WizardSelectionModalCtrl = /** @class */ (function (_super) {
            __extends(WizardSelectionModalCtrl, _super);
            /* ngInject */
            function WizardSelectionModalCtrl($mdDialog, Messages, mode, utilityCompanies, buildingViewItems, accountId, buildingId, users, $state) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.Messages = Messages;
                _this.mode = mode;
                _this.utilityCompanies = utilityCompanies;
                _this.buildingViewItems = buildingViewItems;
                _this.accountId = accountId;
                _this.buildingId = buildingId;
                _this.users = users;
                _this.$state = $state;
                return _this;
            }
            WizardSelectionModalCtrl.prototype.startWizard = function (mode) {
                var _this = this;
                if (mode != 'auto' && mode != 'manual') {
                    this.Messages.error('Unexpected error occured while starting wizard, please contant your Administrator');
                    this.cancel();
                }
                this.mode = mode;
                if (this.mode == 'auto') {
                    this.$mdDialog.show({
                        controller: 'UtilityAccountWizardCtrl as vm',
                        templateUrl: 'app/utilityBudgets/utilityAccounts/actions/wizard/utilityAccountWizard.html',
                        parent: angular.element(document.body),
                        clickOutsideToClose: false,
                        targetEvent: event,
                        locals: {
                            utilityCompanies: this.utilityCompanies,
                            buildingViewItems: this.buildingViewItems,
                            buildingId: this.buildingId,
                            users: this.users
                        },
                        multiple: false
                    })
                        .finally(function () {
                        _this.$state.reload();
                    });
                }
                else {
                    this.$mdDialog.show({
                        controller: 'ManualUtilityAccountWizardCtrl as vm',
                        templateUrl: 'app/utilityBudgets/utilityAccounts/actions/wizard/manualUtilityAccountWizard.html',
                        parent: angular.element(document.body),
                        clickOutsideToClose: false,
                        targetEvent: event,
                        locals: {
                            utilityCompanies: this.utilityCompanies,
                            buildingViewItems: this.buildingViewItems,
                            buildingId: this.buildingId,
                            accountId: this.accountId
                        },
                        multiple: false
                    })
                        .finally(function () {
                        _this.$state.reload();
                    });
                }
            };
            return WizardSelectionModalCtrl;
        }(aq.common.Controllers.ModalCtrl));
        utilityBudgets.WizardSelectionModalCtrl = WizardSelectionModalCtrl;
        angular.module('aq.utilityBudgets').controller('WizardSelectionModalCtrl', WizardSelectionModalCtrl);
    })(utilityBudgets = aq.utilityBudgets || (aq.utilityBudgets = {}));
})(aq || (aq = {}));
