namespace aq.utilityBudgets {
    export class BillStatementCtrl {
        public billStatementChart: __Highcharts.Options;
        public billBreakdownChart: __Highcharts.Options;
        public temperatureSeries: __Highcharts.SeriesOptions;
        public chart: __Highcharts.ChartObject;
        public pieChart: __Highcharts.ChartObject;
        public selectedUtilityBill: UtilityBillPeriodCharge;
        public selectedType: string;
        public days: string[];
        public daysOfWeek: string[];
        public tableData: BillStatementTableDataItem[];
        public colors: string[];
        public periodType: string;
        public isPeriodTypeDisabled: boolean;
        public utilityBillPeriodsAccountMap: { [accout: string]: UtilityBillPeriodCharge[] };
        public utilityBillAccounts: string[];

        public totals: {
            usage: number,
            demand: number,
            usageRealUnit: string,
            demandRealUnit: string,
            usageCharge: number,
            demandCharge: number,
            taxCharge: number,
            otherCharge: number,
            lateFeeCharge: number,
            adjustmentCharge: number,
            cost: number
            demandDate: string
        };

        /* ngInject */
        constructor(
            private account: aq.common.models.Account,
            private building: aq.common.models.Building,
            private measures: aq.common.models.Measure[],
            private billMeasure: string,
            private utilityBillPeriods: UtilityBillPeriodCharge[],
            private bill: UtilityBillPeriodCharge,
            private billBreakdown: UtilityBillAnalysisData,
            private temperature: Temperature,
            private temperatureUnit: MeasureUnit,
            private currencyUnitSymbol: string,
            private $state: ng.ui.IStateService,
            private $timeout: ng.ITimeoutService,
            private $filter: ng.IFilterService,
            private $translate
        ) {

            this.init();
            if (!this.bill) {
                return;
            }
            this.buildTableData();
            this.buildBillBreakdownChart();
            this.buildBillStatementChart();
            this.$timeout(() => this.chart && this.chart.reflow(), 200);
        }
        public init() {
            this.periodType = 'bill';
            this.colors = Highcharts.getOptions().colors;
            this.selectedType = this.billMeasure;
            if (this.bill) {
                this.selectedUtilityBill = this.bill;
                this.mapDays();
                this.initConstantSeries();
            }
            this.utilityBillPeriodsAccountMap = _.groupBy(this.utilityBillPeriods, (item) => item.account);
            this.utilityBillAccounts = _.map(this.utilityBillPeriodsAccountMap, (value, key) => key);
            if (this.billBreakdown.billing) {
                const demandIndex = this.billBreakdown.billing.data.findIndex((data) => data.datum.demandCharge);
                const demandDate = this.days[demandIndex];
                this.totals = {
                    usage: this.billBreakdown.billing.usage,
                    demand: this.billBreakdown.billing.demand,
                    usageRealUnit: this.billBreakdown.billing.usageRealUnit,
                    demandRealUnit: this.billBreakdown.billing.demandRealUnit,
                    usageCharge: this.billBreakdown.billing.usageCharge,
                    demandCharge: this.billBreakdown.billing.demandCharge,
                    otherCharge: this.billBreakdown.billing.otherCharge,
                    taxCharge: this.billBreakdown.billing.taxCharge,
                    lateFeeCharge: this.billBreakdown.billing.lateFeeCharge,
                    adjustmentCharge: this.billBreakdown.billing.adjustmentCharge,
                    cost: this.billBreakdown.billing.cost,
                    demandDate
                };
            }
        }

        public mapDays() {
            this.days = [];
            this.daysOfWeek = [];
            const date = moment(this.billBreakdown.start);
            const endDate = moment(this.billBreakdown.end);
            while (date.isSameOrBefore(endDate)) {
                this.days.push(date.format('MMM DD'));
                this.daysOfWeek.push(date.format('dddd, MMMM D'));
                date.add(1, 'day');
            }
        }

        public initConstantSeries() {
            const temperatureSeriesData = [];
            _.each(this.temperature.values, (value, index) => {
                const point = {
                    x: index,
                    y: this.getTemperatureValue(value)
                };
                temperatureSeriesData.push(point);
            });
            this.temperatureSeries = {
                data: temperatureSeriesData,
                name: this.$translate.instant('budgets.Temperature'),
                type: 'line',
                stacking: 'normal',
                color: seriesColors.weather,
                dashStyle: 'ShortDash',
                states: {
                    hover: {
                        enabled: true
                    }
                },
                yAxis: 1
            };
        }
        public onChangeUtilityBill() {
            this.$state.go(this.$state.current.name, {
                billId: this.selectedUtilityBill.id
            });
        }
        public onChangeType() {
            this.$state.go(this.$state.current.name, {
                measure: this.selectedType,
                billId: ''
            });
        }
        public goToServiceBillsView() {
            this.isPeriodTypeDisabled = true;
            const fiscalMonth = this.building.fiscalStartMonth || 1;
            const date = this.billBreakdown ? moment(this.billBreakdown.end) : moment();
            let year = date.year();
            if (parseInt(date.format('M')) < fiscalMonth) {
                year -= 1;
            }
            this.$state.go('aq.utilityBudgets.serviceBills', {
                measure: this.selectedType,
                year,
                serviceId: this.bill ? this.bill.service : null
            });
        }
        public buildTableData() {
            this.tableData = [];
            const startDate = moment(this.billBreakdown.start, 'YYYY-MM-DD');
            const endDate = moment(this.billBreakdown.end, 'YYYY-MM-DD');
            const dateFormat = startDate.isSame(endDate, 'year') ? 'dddd, MMM D' : 'dddd, MMM D, YYYY';
            const symbol = this.billBreakdown.billing.unitSymbol;
            _.each(this.billBreakdown.billing.data, (item: BillStatementDataItem, index) => {
                const d = item.datum;
                const charge = this.$filter('currency')(d.charge, symbol, 0);
                const usageCharge = this.$filter('currency')(d.usageCharge, symbol, 0);
                const demandCharge = this.$filter('currency')(d.demandCharge, symbol, 0);
                const degreeValue = this.temperature.values.length > index && this.temperature.values[index] != null
                    ? this.getTemperatureValue(this.temperature.values[index])
                    : null;
                this.tableData.push({
                    date: moment(item.startTimestamp).format(dateFormat),
                    temperature: degreeValue != null ? `${degreeValue} ${this.temperatureUnit.unit}` : '-',
                    charge,
                    usageCharge,
                    demandCharge,
                    chargeValue: d.charge || 0,
                    usageValue: d.usageCharge,
                    demandValue: d.demandCharge
                });
            });
        }
        public buildBillBreakdownChart() {
            const consumption = _.sumBy(this.billBreakdown.billing.data, (item: BillStatementDataItem) => this.getPositiveValue(item.datum.usageCharge));
            const demand = _.sumBy(this.billBreakdown.billing.data, (item: BillStatementDataItem) => this.getPositiveValue(item.datum.demandCharge));
            const consumptionTitle = this.$translate.instant('budgets.Consumption');
            const demandTitle = this.$translate.instant('budgets.Demand');
            const getContext = () => this;
            const data = [
                {
                name: `${consumptionTitle}`,
                y: consumption,
                color: this.colors[0],
                events: { legendItemClick: () => false }
                }
            ];
            if (this.selectedType === 'electricity') {
                data.push({
                    name: `${demandTitle}`,
                    y: demand,
                    color: this.colors[1],
                    events: { legendItemClick: () => false }
                });
            }
            this.billBreakdownChart = {
                chart: {
                    height: 150,
                    width: 380,
                    type: 'pie',
                    plotBorderWidth: null
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true
                    }
                },
                legend: {
                    align: 'right',
                    verticalAlign: 'middle',
                    layout: 'vertical',
                    itemStyle: {
                        fontSize: '11px'
                    }
                },
                series: [{
                    name: this.$translate.instant('budgets.Bill Breakdown'),
                    colorByPoint: false,
                    data
                } as any],
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                tooltip: {
                    formatter() {
                        const context = getContext();
                        const formattedValue = context.$filter('currency')(this.y, context.billBreakdown.billing.unitSymbol, 0);
                        return `<span style="color:${this.color};">■ </span><span>${this.point.name} (${formattedValue})</span>`;
                    }
                }
            };
        }
        public getSeries() {
            const series = [];
            const consumptionData = [];
            this.days.forEach((item, index) => {
                const dataItem: BillStatementDataItem = this.billBreakdown.billing.data[index];
                consumptionData.push({ x: index, y: dataItem.datum.usageCharge, color: dataItem.datum.demandCharge ? 'orange' : null });
            });
            let index = 1;
            if (this.selectedType === 'electricity') {
                series.push({
                    data: [],
                    name: this.$translate.instant('budgets.Demand'),
                    type: 'column',
                    colorIndex: 1,
                    stacking: 'normal',
                    pointPadding: 0.1,
                    borderWidth: 0,
                    index
                });
                index++;
            }
            series.push({
                data: consumptionData,
                name: this.$translate.instant('budgets.Consumption'),
                type: 'column',
                colorIndex: 0,
                stacking: 'normal',
                pointPadding: 0.1,
                borderWidth: 0,
                index
            });
            series.push(this.temperatureSeries);
            return series;
        }
        public buildBillStatementChart() {
            const series = this.getSeries();
            this.billStatementChart = this.buildChart(series);
        }
        public buildChart(series) {
            const days = this.days;
            const getContext = () => this;
            return {
                chart: {
                    height: 400,
                    animation: false
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    tickInterval: 1,
                    min: 0,
                    max: this.days.length - 1,
                    labels: {
                        formatter() {
                            return days[this.value];
                        }
                    },
                    crosshair: true
                },
                yAxis: [{
                    title: { text: this.currencyUnitSymbol },
                    min: 0
                }, {
                    title: { text: this.temperatureUnit.unit },
                    opposite: true,
                    minTickInterval: 5
                }],
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal',
                    itemStyle: {
                        fontSize: '11px'
                    },
                    itemHoverStyle: {
                        cursor: 'pointer',
                        color: 'blue'
                    }
                },
                plotOptions: {
                    series: {
                        animation: false,
                        marker: {
                            enabled: false
                        },
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                        events: {
                            legendItemClick: () => true
                        }
                    }
                },
                series,
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                tooltip: {
                    formatter() {
                        const index = this.x;
                        const context = getContext();
                        const dayInfo = `<b>${context.daysOfWeek[index]}</b>`;
                        const tableDataItem = context.tableData[index];
                        const degreeDaysContent = tableDataItem.temperature == '-' ? '' : `<span style="color:${seriesColors.weather};">■ </span>`
                            + `<span>${context.$translate.instant('budgets.Temperature')}: ${tableDataItem.temperature}</span>`;
                        const usageLabel = context.$translate.instant('budgets.Consumption');
                        const consumptionContent = tableDataItem.usageCharge != '$0'
                            ? `<span style="color:${context.colors[0]}">■ </span>
                                <span>${usageLabel}: ${tableDataItem.usageCharge}</span>`
                            : '';
                        const demandLabel = context.$translate.instant('budgets.Demand');
                        const demandContent = tableDataItem.demandCharge != '$0'
                            ? `<span style="color:${context.colors[1]}">■ </span>
                                <span>${demandLabel}: ${tableDataItem.demandCharge}</span>`
                            : '';
                        return [
                            dayInfo,
                            degreeDaysContent,
                            consumptionContent,
                            demandContent
                        ].join('<br/>');
                    }
                }
            };
        }
        private getPositiveValue(value: number) {
            return value && value > 0 ? value : 0;
        }
        private getTemperatureValue(value) {
            if (value == null) {
                return null;
            }
            return Math.round(this.$filter<Function>('toTemperatureUnit')(value, this.temperatureUnit));
        }
    }
    angular.module('aq.utilityBudgets').controller('BillStatementCtrl', BillStatementCtrl);
}
