var aq;
(function (aq) {
    var propertySettings;
    (function (propertySettings) {
        var TargetNewCtrl = /** @class */ (function (_super) {
            __extends(TargetNewCtrl, _super);
            /* @ngInject */
            function TargetNewCtrl($scope, target, measures, building, Restangular, $mdDialog, $filter, months, years, types, Messages, $state, Errors, DataStore, Segment) {
                var _this = _super.call(this, $scope, $mdDialog) || this;
                _this.$scope = $scope;
                _this.target = target;
                _this.measures = measures;
                _this.building = building;
                _this.Restangular = Restangular;
                _this.$mdDialog = $mdDialog;
                _this.$filter = $filter;
                _this.months = months;
                _this.years = years;
                _this.types = types;
                _this.Messages = Messages;
                _this.$state = $state;
                _this.Errors = Errors;
                _this.DataStore = DataStore;
                _this.Segment = Segment;
                _this.$scope.target = target;
                _this.$scope.months = months;
                _this.$scope.years = years;
                _this.$scope.measures = measures;
                _this.$scope.targetRequest = new TargetRequest(_this.target, building);
                _this.$scope.types = types;
                // TODO @canRecommend: for budgets, check if we have enough utility bill info in order to do a recommendation
                _this.$scope.canRecommend = false;
                if (!_this.$scope.canRecommend) {
                    _this.$scope.targetRequest.recommendTargets = false;
                }
                return _this;
            }
            TargetNewCtrl.prototype.prepareTarget = function () {
                this.$scope.targetRequest.startDate = this.constructStartDate(this.$scope.targetRequest.month, this.$scope.targetRequest.year);
                var typeLabel = _.find(this.$scope.types, { value: this.$scope.target.type });
                this.$scope.target.measure = _.find(this.$scope.measures, { name: 'electricity' }).id; // assume electricitiy for now
                var measureLabel = '';
                if (this.$scope.target.type !== 'ENERGY_STAR') {
                    measureLabel = this.$filter('text')(_.find(this.$scope.measures, { id: this.$scope.target.measure })['name'], 'capitalize') + ' ';
                }
                this.$scope.target.name = this.$scope.targetRequest.year + " " + measureLabel + typeLabel.label;
                delete this.$scope.targetRequest.month;
            };
            TargetNewCtrl.prototype.save = function () {
                var _this = this;
                this.prepareTarget();
                return this.DataStore.create(this.building.all('targets'), this.$scope.targetRequest)
                    .then(function (target) {
                    return _this.DataStore.get(_this.building.one('targets', target.id), { inflate: 'measure,targetItems', single: true }, true);
                })
                    .then(function (target) {
                    _this.Messages.success('Target created successfully.');
                    _this.$state.go('aq.properties.buildings.targets.details', { targetId: target.id });
                }, function (error) {
                    _this.Messages.error('Annual target already exists.');
                });
            };
            TargetNewCtrl.prototype.prepareBudgetTarget = function () {
                this.$scope.targetRequest.startDate = this.constructStartDate(this.$scope.targetRequest.month, this.$scope.targetRequest.year);
                var typeLabel = _.find(this.$scope.types, { value: 'BUDGET' });
                this.$scope.targetRequest.target.type = typeLabel.value;
                var measureLabel = this.$filter('text')(_.find(this.$scope.measures, { id: this.$scope.target.measure })['name'], 'capitalize');
                this.$scope.target.name = this.$scope.targetRequest.year + " " + measureLabel + " " + typeLabel.label;
                delete this.$scope.targetRequest.month;
                this.$scope.measureLabel = measureLabel;
            };
            TargetNewCtrl.prototype.budgetSave = function () {
                var _this = this;
                this.Segment.trackEvent('Budgets:Create Budget');
                this.prepareBudgetTarget();
                return this.DataStore.create(this.building.all('budgets'), this.$scope.targetRequest, { utility: this.$scope.measureLabel,
                    year: this.$scope.targetRequest.year, recommendTargets: this.$scope.targetRequest.recommendTargets })
                    .then(function (target) {
                    _this.$scope.target = target;
                    return _this.DataStore.getList(_this.Restangular.one('accounts', _this.building.account).one('buildings', _this.building.id), 'budgets', { inflate: 'targetItems,measure' });
                }).then(function (budgets) {
                    _this.$scope.targets = budgets;
                    _this.$state.go('.details', { targetId: _this.$scope.target.id });
                }, function (error) {
                    _this.Messages.error('Annual budget already exists.');
                });
            };
            TargetNewCtrl.prototype.constructStartDate = function (month, year) {
                if (!month || !year)
                    return null;
                return moment.utc().year(year).startOf('year').month(month).startOf('month').format('YYYY-MM-DD');
            };
            return TargetNewCtrl;
        }(aq.common.Controllers.ModalCtrl));
        propertySettings.TargetNewCtrl = TargetNewCtrl;
        var TargetRequest = /** @class */ (function () {
            function TargetRequest(target, building) {
                this.target = target;
                this.total = 0;
                this.startDate = null;
                this.recommendTargets = true;
                if (building.fiscalStartMonth) {
                    this.month = moment.months(building.fiscalStartMonth - 1);
                }
                else {
                    this.month = 'January';
                }
                this.year = parseInt(moment().format('YYYY'));
            }
            return TargetRequest;
        }());
        angular.module('properties').controller('TargetNewCtrl', aq.propertySettings.TargetNewCtrl);
    })(propertySettings = aq.propertySettings || (aq.propertySettings = {}));
})(aq || (aq = {}));
