/* eslint-disable max-len */
var aq;
(function (aq) {
    var reports;
    (function (reports) {
        var PassThroughSummaryCtrl = /** @class */ (function (_super) {
            __extends(PassThroughSummaryCtrl, _super);
            /* @ngInject */
            function PassThroughSummaryCtrl(billing, tenantChargeValues, tenants, setup, OptionsService, building, periodTenantServices, tenantGroups, $timeout) {
                var _this = _super.call(this, $timeout) || this;
                _this.billing = billing;
                _this.tenantChargeValues = tenantChargeValues;
                _this.tenants = tenants;
                _this.setup = setup;
                _this.OptionsService = OptionsService;
                _this.building = building;
                _this.periodTenantServices = periodTenantServices;
                _this.tenantGroups = tenantGroups;
                _this.$timeout = $timeout;
                _this.currencyUnit = OptionsService.currencyUnit();
                _this.formattedCharges = _this.getTenantChargesForTenantGroups();
                _this.remitAddress = _this.getRemitAddress();
                _this.notifyDocumentReady();
                return _this;
            }
            PassThroughSummaryCtrl.prototype.getTenantChargesForTenantGroups = function () {
                var _this = this;
                var chargesByTenantGroup = [];
                this.tenantGroups.forEach(function (group) {
                    var groupCharges = _this.getChargesForGroup(group);
                    var groupObject = {
                        group: group,
                        charges: _this.formatTenantChargesForGroup(groupCharges)
                    };
                    chargesByTenantGroup.push(groupObject);
                });
                return chargesByTenantGroup;
            };
            PassThroughSummaryCtrl.prototype.getChargesForGroup = function (group) {
                return this.tenantChargeValues.filter(function (charge) { return _.includes(group.tenants, charge.tenantId); });
            };
            PassThroughSummaryCtrl.prototype.formatTenantChargesForGroup = function (groupTenantsCharges) {
                var _this = this;
                var serviceCharges = [];
                var uniqueServices = this.getUniqueServices(groupTenantsCharges);
                uniqueServices.forEach(function (serviceType) {
                    var chargesForServiceByTenant = _this.getChargesForServiceByTenant(serviceType, groupTenantsCharges);
                    var columnTotals = _this.getColumnTotalsForService(chargesForServiceByTenant);
                    var periodTenantServicesForService = _this.getPeriodTenantServicesForService(serviceType);
                    var tenantCharges = {
                        serviceType: serviceType,
                        tenantCharges: _.orderBy(chargesForServiceByTenant, ['name'], ['asc']),
                        columnTotals: columnTotals,
                        periodTenantServicesForService: periodTenantServicesForService,
                    };
                    serviceCharges.push(tenantCharges);
                });
                return serviceCharges;
            };
            PassThroughSummaryCtrl.prototype.getUniqueServices = function (groupTenantsCharges) {
                return _.uniq(groupTenantsCharges.filter(function (charge) { return charge.type === 'Metered'; }).map(function (charge) { return charge.serviceType; }));
            };
            PassThroughSummaryCtrl.prototype.getChargesForServiceByTenant = function (serviceType, groupTenantsCharges) {
                var _this = this;
                var returnvalue = [];
                var tenantChargesForServiceType = _.filter(groupTenantsCharges, ['serviceType', serviceType]);
                this.tenants.forEach(function (tenant) {
                    var chargesForTenant = _.filter(tenantChargesForServiceType, ['tenantName', tenant.name]);
                    if (chargesForTenant.length > 0) {
                        var totalConsumption = chargesForTenant.reduce(function (acc, charge) { return acc + charge.appliedTotal; }, 0);
                        var meteredCharge = chargesForTenant.reduce(function (acc, charge) { return acc + charge.cost; }, 0);
                        var meterFees = _this.getMeterFeesForTenantAndServiceType(tenant, chargesForTenant);
                        var tenantCharge = {
                            name: tenant.name,
                            leaseId: tenant.accountNumber,
                            usageUnit: chargesForTenant[0].usageUnit,
                            totalConsumption: totalConsumption,
                            meteredCharge: meteredCharge,
                            meterFees: meterFees,
                            otherFees: 0,
                            taxes: 0,
                            totalCharge: 0
                        };
                        tenantCharge.otherFees = _this.getOtherFeesForTenantAndServiceType(tenantCharge);
                        tenantCharge.taxes = _this.getTaxesForTenantAndServiceType(tenantCharge);
                        tenantCharge.totalCharge = _.sum([tenantCharge.meteredCharge, tenantCharge.meterFees, tenantCharge.otherFees, tenantCharge.taxes]);
                        returnvalue.push(tenantCharge);
                    }
                });
                return returnvalue;
            };
            PassThroughSummaryCtrl.prototype.getMeterFeesForTenantAndServiceType = function (tenant, chargesForTenant) {
                var totalMeters = _.uniq(chargesForTenant.map(function (charge) { return charge.collector; }));
                var meterFeesForTenant = _.filter(this.tenantChargeValues, {
                    tenantName: tenant.name,
                    feeType: 'PER_METER'
                });
                var totalFeePerMeter = meterFeesForTenant.reduce(function (acc, charge) { return acc + charge.costPerUnit; }, 0);
                return totalFeePerMeter * totalMeters.length;
            };
            PassThroughSummaryCtrl.prototype.getOtherFeesForTenantAndServiceType = function (tenantCharge) {
                var usageFees = _.filter(this.tenantChargeValues, {
                    tenantName: tenantCharge.name,
                    feeType: 'PCT_METERED_USAGE'
                });
                return _.sum(usageFees.map(function (fee) {
                    return fee.costPerUnit * tenantCharge.meterFees;
                }));
            };
            PassThroughSummaryCtrl.prototype.getTaxesForTenantAndServiceType = function (tenantCharge) {
                var taxesForTenant = _.filter(this.tenantChargeValues, { tenantName: tenantCharge.name, type: 'Tax' });
                return _.sum(taxesForTenant.map(function (fee) {
                    var totalChargesForTenant = _.sum([tenantCharge.meteredCharge, tenantCharge.meterFees, tenantCharge.otherFees]);
                    return (fee.costPerUnit / 100) * totalChargesForTenant;
                }));
            };
            PassThroughSummaryCtrl.prototype.getColumnTotalsForService = function (chargesForServiceByTenant) {
                return {
                    consumptionTotal: chargesForServiceByTenant.reduce(function (acc, charge) { return acc + charge.totalConsumption; }, 0),
                    meteredChargeTotal: chargesForServiceByTenant.reduce(function (acc, charge) { return acc + charge.meteredCharge; }, 0),
                    meterFeesTotal: chargesForServiceByTenant.reduce(function (acc, charge) { return acc + charge.meterFees; }, 0),
                    otherFeesTotal: chargesForServiceByTenant.reduce(function (acc, charge) { return acc + charge.otherFees; }, 0),
                    taxesTotal: chargesForServiceByTenant.reduce(function (acc, charge) { return acc + charge.taxes; }, 0),
                    totalChargeTotal: chargesForServiceByTenant.reduce(function (acc, charge) { return acc + charge.totalCharge; }, 0),
                    usageUnit: chargesForServiceByTenant[0].usageUnit
                };
            };
            PassThroughSummaryCtrl.prototype.getRemitAddress = function () {
                var remittanceAddresses = this.tenants.map(function (tenant) { return tenant.remittanceAddress; }).filter(function (address) { return address != null; });
                if (remittanceAddresses.length > 0) {
                    var uniqAddress = _.uniqBy(remittanceAddresses, 'address');
                    return uniqAddress[0];
                }
                return null;
            };
            PassThroughSummaryCtrl.prototype.getPeriodTenantServicesForService = function (serviceType) {
                var tenantServicesForService = _.filter(this.periodTenantServices, ['serviceType', serviceType]);
                return {
                    meteredCharges: _.filter(tenantServicesForService, ['method', 'METERED_USAGE']),
                    fees: tenantServicesForService.filter(function (service) { return service.method === 'FEE' && service.usageUnit !== 'metered usage'; }),
                    percentMeterUsageFees: _.filter(this.periodTenantServices, { method: 'FEE', usageUnit: 'metered usage' }),
                    taxes: _.filter(this.periodTenantServices, ['method', 'TAX'])
                };
            };
            PassThroughSummaryCtrl.prototype.generateInvoiceNumber = function (billingId, groupId, serviceType) {
                var billingSubStr = billingId.toString().substring(billingId.toString().length - 3, billingId.toString().length);
                var groupSubStr = groupId.toString().substring(groupId.toString().length - 3, groupId.toString().length);
                var serviceSubStr = serviceType.slice(0, 1);
                return "" + billingSubStr + groupSubStr + serviceSubStr;
            };
            return PassThroughSummaryCtrl;
        }(reports.BaseDocraptorReportCtrl));
        reports.PassThroughSummaryCtrl = PassThroughSummaryCtrl;
    })(reports = aq.reports || (aq.reports = {}));
})(aq || (aq = {}));
angular
    .module('aq.reports')
    .controller('PassThroughSummaryCtrl', aq.reports.PassThroughSummaryCtrl);
