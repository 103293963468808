var aq;
(function (aq) {
    var propertySettings;
    (function (propertySettings) {
        var AlertConditionsComponentCtrl = /** @class */ (function (_super) {
            __extends(AlertConditionsComponentCtrl, _super);
            /* @ngInject */
            function AlertConditionsComponentCtrl(Auth) {
                var _this = _super.call(this) || this;
                _this.Auth = Auth;
                _this.searchText = '';
                _this.alertOnOptions = [{
                        label: 'Building',
                        value: 'BUILDING'
                    }, {
                        label: 'Source',
                        value: 'SOURCE'
                    }, {
                        label: 'Meter',
                        value: 'METER'
                    }, {
                        label: 'Tenant',
                        value: 'TENANT'
                    }];
                _this.updateUnits();
                _this.selectedQueryable = _this.getQueryable();
                return _this;
            }
            AlertConditionsComponentCtrl.prototype.onAlertChange = function () {
                if (this.onChange) {
                    this.onChange({
                        $event: {
                            alert: this.alert
                        }
                    });
                }
            };
            AlertConditionsComponentCtrl.prototype.updateUnits = function () {
                this.units = this.getUnits();
            };
            AlertConditionsComponentCtrl.prototype.queryCollectionByName = function (collection, search) {
                if (search) {
                    return _.filter(collection, function (c) {
                        return c.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
                    });
                }
                return collection;
            };
            AlertConditionsComponentCtrl.prototype.onCalculationMethodChange = function () {
                if (this.alert.calculationMethod === 'PERCENTAGE') {
                    this.alert.greaterThan = null;
                    this.alert.smallerThan = null;
                }
                else if (this.alert.calculationMethod === 'ABSOLUTE') {
                    this.alert.greaterThanAverage = null;
                    this.alert.smallerThanAverage = null;
                }
                else if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                    this.alert.greaterThan = null;
                    this.alert.smallerThan = null;
                    this.alert.greaterThanAverage = null;
                    this.alert.smallerThanAverage = null;
                }
            };
            AlertConditionsComponentCtrl.prototype.getUnits = function () {
                var _this = this;
                var queryable = this.getQueryable();
                var unitsByMeasure = {};
                if (queryable) {
                    queryable.metrics.forEach(function (measure) {
                        var applicableUnits = _.filter(_this.allUnits, function (unit) {
                            // TODO open this up to more units
                            return unit.serviceType === measure && unit.field === 'total' &&
                                (unit.unit === 'kW' || unit.unit === 'CCF' || unit.unit === 'GPM' || unit.unit === 'Mlb');
                        });
                        unitsByMeasure[measure] = applicableUnits;
                    });
                }
                if (unitsByMeasure["ELECTRICITY"] == undefined && unitsByMeasure["GAS"] == undefined
                    && unitsByMeasure["WATER"] == undefined && unitsByMeasure["STEAM"] == undefined) {
                    var unitsByMeasureDefault = {
                        "ELECTRICITY": [_.find(this.allUnits, { "apiUnit": "POWER", "unit": "kW" })],
                        "GAS": [_.find(this.allUnits, { "apiUnit": "GAS", "unit": "CCF" })],
                        "WATER": [_.find(this.allUnits, { "apiUnit": "FLOW_RATE", "unit": "GPM" })],
                        "STEAM": [_.find(this.allUnits, { "apiUnit": "STEAM_MASS", "unit": "Mlb" })]
                    };
                    return unitsByMeasureDefault;
                }
                return unitsByMeasure;
            };
            return AlertConditionsComponentCtrl;
        }(propertySettings.AbstractAlertComponent));
        propertySettings.AlertConditionsComponentCtrl = AlertConditionsComponentCtrl;
        angular.module('properties')
            .component('alertConditions', {
            templateUrl: 'app/properties/alerts/directives/alertConditions.html',
            bindings: {
                building: '<',
                allUnits: '<',
                alert: '<',
                collectors: '<',
                sources: '<',
                tenants: '<',
                calendars: '<',
                buildingDrillin: '<',
                onChange: '&',
                isReadonly: '<?',
                form: '='
            },
            controller: AlertConditionsComponentCtrl
        });
    })(propertySettings = aq.propertySettings || (aq.propertySettings = {}));
})(aq || (aq = {}));
