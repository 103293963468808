namespace aq.tenantbilling {
    export class ReviewChargesContentCtrl {
        public monthlyBilling: MonthlyTenantBilling;
        public onViewChargeValueGraph: Function;
        public tenantInvoices: TenantInvoice[];
        public tenantChargeValues: TenantChargeValue[];
        public building: aq.common.models.Building;
        public currencyUnit: any;
        public selectedChargeValue: TenantChargeValue[];
        public showReadingDetails: { checked: boolean };
        public isDisabled: boolean;
        public isAqAdmin: boolean;
        tenantLabel: string;
        /* @ngAnnotate */
        constructor(
            private OptionsService,
            private MonthlyBillingService: MonthlyBillingService,
            private UserService,
            private Auth: aq.services.Auth
        ) {
            this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'unit' : 'tenant';
        }
        public $onInit() {
            this.MonthlyBillingService.getChargeValues(this.monthlyBilling).then((result) => {
                this.tenantChargeValues = result;
            });
            this.currencyUnit = this.OptionsService.currencyUnit();
            this.selectedChargeValue = [];
            this.showReadingDetails = {
                checked: false
            };
            this.isAqAdmin = this.UserService.currentUser.userType === 'ADMINISTRATOR';
        }
        public viewChargeValueGraph(event, chargeValue: TenantChargeValue) {
            event.preventDefault();
            event.stopPropagation();
            if (this.isDisabled) {
                return;
            }
            if (this.onViewChargeValueGraph) {
                this.onViewChargeValueGraph()(chargeValue);
            }
        }
        public getMeteredTenantChargeValues() {
            return _.filter(this.tenantChargeValues, { type: 'Metered' });
        }
        public getFixedTenantChargeValues() {
            return _.filter(this.tenantChargeValues, { type: 'Fixed' });
        }
        public getTaxTenantChargeValues() {
            return _.filter(this.tenantChargeValues, { type: 'Tax' });
        }
        public getMeteredChargeTotal() {
            const charges = this.getMeteredTenantChargeValues();
            return _.sum(_.map(charges, 'cost'));
        }
        public getPreviousMeteredChargeTotal() {
            const charges = this.getMeteredTenantChargeValues();
            return _.sum(_.map(charges, 'previousCharge.cost'));
        }
        public getFixedChargeTotal() {
            const charges = this.getFixedTenantChargeValues();
            return _.sum(_.map(charges, 'cost'));
        }
        public getPreviousFixedChargeTotal() {
            const charges = this.getFixedTenantChargeValues();
            return _.sum(_.map(charges, 'previousCharge.cost'));
        }
        public getTaxChargeTotal() {
            const charges = this.getTaxTenantChargeValues();
            return _.sum(_.map(charges, 'cost'));
        }
        public getPreviousTaxChargeTotal() {
            const charges = this.getTaxTenantChargeValues();
            return _.sum(_.map(charges, 'previousCharge.cost'));
        }
        public isNil(value) {
            return _.isNil(value);
        }
    }
    angular
        .module('tenantBilling')
        .component('reviewChargesContent', {
            templateUrl: 'app/tenantBilling/invoicing/run/components/reviewChargesContent/reviewChargesContent.html',
            controller: ReviewChargesContentCtrl ,
            controllerAs: 'vm',
            bindings: {
                monthlyBilling: '<',
                tenantInvoices: '<',
                tenantChargeValues: '<',
                building: '<',
                onViewChargeValueGraph: '&?',
                isDisabled: '<?'
            }
        });
}
