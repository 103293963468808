/**
 * Creates a missing data series from Data API response
 *
 * Given a data response it will create a list of missing series
 *
 * Response:
 * [{name: "name 1", timestamps: [...]}, {"name 2", timestamps: [..]}, ... ]
 *
 */
angular.module('charting').filter('missing', function() {

    return function(data) {
        if (!data || data.length == 0) {
            return [];
        }
        var missing = _(data).filter(function(elem) {
            return elem.missing && elem.missing.length > 0;
        }).map(function(elem) {
            return {
                name: elem.name,
                timestamps: elem.missing
            }
        }).value();

        return missing;
    }

});