namespace aq.utilityBudgets {
    export class UtilityAccountViewModelHelper {
        constructor(
            private utilityCompanies: aq.common.models.NamedItem[],
            private UtilityServiceHelper: UtilityServiceHelper) {
        }
        public getUtilityAccountViewModel(utilityAccount: UtilityAccount, isDisableDelete = false) {
            const model: UtilityAccountViewModel = {
                id: utilityAccount.id,
                utilityCompanyName: this.getCompanyName(utilityAccount.utilityCompany),
                accountNumber: utilityAccount.accountNumber,
                utilityUrl: utilityAccount.utilityUrl,
                shared: utilityAccount.shared,
                status: this.UtilityServiceHelper.getStatusView(utilityAccount.status),
                sortField: null,
                isDisableDelete
            };
            model.sortField = `${model.utilityCompanyName}_${model.accountNumber}`;
            if (!model.utilityCompanyName) {
                model.utilityCompanyName = 'Please fill out account details';
                model.accountNumber = '';
            }
            return model;
        }
        public getCompanyName(id: number) {
            const item = _.find(this.utilityCompanies, (uc: aq.common.models.NamedItem) => uc.id == id);
            return item ? item.name : null;
        }
    }

    export interface UtilityAccountViewModel {
        id: string;
        utilityCompanyName: string;
        accountNumber: string;
        utilityUrl: string;
        status: UtilityAccountStatusViewModel;
        sortField: string;
        isDisableDelete: boolean;
        shared: boolean;
    }

}
