declare var _;

/**
     * Diff two lists for changes
     *
     * @public
     * @param {Function} compare A function that compares the items in each array
     * @param {any[]} originalList The original, pristine array (usually from a resolve)
     * @param {any[]} modifiedList The tainted (hah) array (usually from $scope)
     * @returns {any[]} The changed items with duplicates removed
     *
     */
const diff = _.curry((compare: Function, originalList: any[], modifiedList: any[]): any[] => {
    return _.chain(_.differenceWith(modifiedList, originalList, compare))
        .flatMap()
        .uniqBy('id')
        .value();
});

/**
 * return a flat array of a given key
 *
 * @public
 * @param {string} key The object key you want
 * @param {any[]} list The list of objects we're iterating
 * @returns {any[]} The flattened array of object's property in list
 *
 */
const getListOf = _.curry((key: string, list: any[]): any[] => {
    if (list && !_.isEmpty(list)) {
        return _.chain(list)
            .map(item => item[key])
            .flatten()
            .value();
    }
});

const percent = (numerator: number, denominator: number, multiplicand = 100) => {
    if (denominator && denominator !== 0) {
        return Math.round((numerator / denominator) * multiplicand);
    }
    return 0;
};

/**
 * Sort a given array numerically
 *
 * @param {any} arr
 * @returns
 */
const sort = (arr) => {
    return _.sortBy(arr, _.identity);
};

// _.median([1,2,3,4])
//   => 2.5
//   TODO {}, [{}]
const median = (arr) => {
    arr = arr.slice(0); // create copy
    const middle = (arr.length + 1) / 2,
        sorted = _.sort(arr);
    return (sorted.length % 2) ? sorted[middle - 1] : (sorted[middle - 1.5] + sorted[middle - 0.5]) / 2;
};

_.mixin({
    sort,
    diff,
    getListOf,
    percent,
    getTitle(key: string): string {
        return _.startCase(_.toLower(key));
    },
    log(tag, param) {
        console.log(tag, param);
        return param;
    },
    hasValue() {
        return _.every(arguments, function (obj) {
            return !_.isNull(obj) && !_.isUndefined(obj) && !_.isEmpty(obj);
        });
    },
    findById(list, id) {
        if (!list) return undefined;
        if (id instanceof Object) {
            // try to extract id from object but log error in any case
            if (id['id']) {
                id = id['id'];
            }
            console.error(new Error('findById() expected id, but found object'));
        }
        return _.find(list, function (element: any) {
            return element.id == id;
        });
    },

    normalizeNewlines(str) {
        return str.replace(/(\r\n|\n|\r)/gm, '\n');
    },

    withoutInline(list, element) {
        if (!list) {
            return undefined;
        }
        return list.splice(_.indexOf(list, element), 1);
    },

    withoutInlineSearchInChildren(list, element) {
        if (!list) {
            return undefined;
        }

        function search(listToSearch) {
            let index = _.indexOf(listToSearch, element);
            if (index != -1) {
                return listToSearch.splice(index, 1);
            }
            _.each(listToSearch, function (parentElem) {
                _.each(parentElem, function (prop, propKey) {
                    if (prop instanceof Array) {
                        index = _.indexOf(prop, element);
                        if (index != -1) {
                            prop = prop.splice(index, 1);
                        } else {
                            search(prop);
                        }
                    }
                });
            });
            search(list);
            return list;
        }
    },

    withoutInlineForId(list, id) {
        const elem = _.findById(list, id);
        if (!elem) {
            return false;
        }

        return _.withoutInline(list, elem);
    },

    findInChildren(list, predicate) {
        let item = null;
        _.find(list, function (o) {
            if (item) return true;
            if (predicate(o)) {
                item = o;
                return true;
            }
            _.each(o, function (prop, propKey) {
                if (item) return;

                if (prop instanceof Array) {
                    item = _.findInChildren(prop, predicate);
                }
            });
        });

        return item;
    },

    uninflate(list) {
        return _.compact(_.map(list, 'id'));
    },

    sumArray(list) {
        return _.reduce(list, function (memo: any, num: any) { return memo + num; }, 0);
    },

    sumArrayByKey(list, key) {
        return _.reduce(list, function (memo, num) { return memo + num[key]; }, 0);
    },

    maxInArray(list) {
        return _.reduce(list, function (memo: any, num: any) { return Math.max(memo, num); });
    },

    avgForArray(list) {
        if (list.length === 0) return 0;
        return _.sumArray(list) / list.length;
    },

    extendArray(list, property) {
        return _.map(list, function (item) {
            return _.extend(item, property);
        });
    },

    removeFromObject(obj, key) {
        delete obj[key];
        return obj;
    },

    replace(list, elementToReplace, elementToReplaceItWith) {
        _.withoutInline(list, elementToReplace);
        list.unshift(elementToReplaceItWith);
        return list;
    },

    // this method will preserve order when replacing elements
    replaceInline(list, elementToReplace, elementToReplaceItWith) {
        return list.splice(_.indexOf(list, elementToReplace), 1, elementToReplaceItWith);
    },

    replaceItemById(list, element) {
        const index = _.indexOf(list, _.find(list, { id: element.id }));
        list.splice(index, 1, element);
        return list;
    },

    mapOrdinalNumbers(numbers: number[]) {
        return _.map(numbers, (n: number): string => {
            const j = Math.abs(n) % 10;
            const k = Math.abs(n) % 100;
            if (j == 1 && k != 11) return `${n}st`;
            else if (j == 2 && k != 12) return `${n}nd`;
            else if (j == 3 && k != 13) return `${n}rd`;
            return `${n}th`;
        });
    },

    getOrdinalNumbers(maxNumber: number): string[] {
        const numbers = [];
        let i = 0;
        while (i < maxNumber) {
            numbers[i++] = i;
        }
        return _.mapOrdinalNumbers(numbers);
    }
});
