namespace aq.utilityBudgets {
    export class AccrualMainCtrl {
        public currentNavItem: string;
        /* ngInject */
        constructor(
            private $state: angular.ui.IStateService
        ) {
            if (this.$state.includes('aq.utilityBudgets.accrual.calculate')) {
                this.currentNavItem = 'calculate';
            }
            if (this.$state.includes('aq.utilityBudgets.accrual.view')) {
                this.currentNavItem = 'view';
            }
            if (this.$state.includes('aq.utilityBudgets.accrual.settings')) {
                this.currentNavItem = 'settings';
            }
        }
    }
    angular.module('aq.utilityBudgets').controller('AccrualMainCtrl', AccrualMainCtrl);
}
