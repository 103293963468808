namespace aq.energyInsights {
    export class SetupToastCtrl {
        public goToFn: Function;
        public message: string;
        public show: boolean;
        /* @ngInject */
        constructor(
            // private $scope: ng.IScope,
            private $mdToast: ng.material.IToastService
        ) {
            if(this.show) {
                this.showToast();
            }
        }
        public $onChanges(changesObj) {
            if(changesObj.show && changesObj.show.currentValue) {
                this.showToast();
            }
        }

        public $onDestroy() {
            this.$mdToast.hide();
        }

        private showToast() {
            const toastOptions: ng.material.IToastOptions = {
                hideDelay: 0,
                position: 'bottom right',
                /* @ngInject */
                controller: ($scope, message, goToFn, $mdToast: ng.material.IToastService) => {
                    $scope.message = message;
                    $scope.goTo = () => {
                        $mdToast.hide();
                        this.goToFn();
                    },
                    $scope.closeToast = () => {
                        $mdToast.hide();
                    }
                },
                locals: {
                    message: this.message,
                    goToFn: this.goToFn
                },
                templateUrl: 'app/energyInsights/directives/setupToast/setupToast.html'
            };
            this.$mdToast.show(toastOptions);
        }
    }

    angular.module('energyInsights').component('eiSetupToast', {
        bindings: {
            message: "<",
            show: "<",
            goToFn: "<"
        },
        controller: SetupToastCtrl
    });


}
