namespace aq.services {

    interface Permissions {
        app: number;
        access: aq.common.models.AppAccess;
        allContainingAccesses: aq.common.models.AppAccess[];
        id: number;
    }

    interface ProfileApps {
        apiRestriction: boolean;
        name: string;
        accessScheme: string;
        accessList: aq.common.models.AppAccess[];
        description: string;
        state: string;
        id: number;
        type: string;
        priority: number;
        TENANT_BILLING_NAME: string; // not sure why this is on all of them, but it is
    }

    type Access = Record<string, aq.common.models.AppAccessObject>;

    export class Auth {
        public allApps: string[] = null;
        public allAppsWithIds: aq.common.models.App[] = null;
        public permissions: Permissions[] = null;
        public profileApps: ProfileApps[] = null;
        public functionalities: string[] = null;
        public access: Access = {};

        /* @ngInject */
        constructor (
            private Restangular: restangular.IService,
            private $state: ng.ui.IStateService,
            private $window: ng.IWindowService,
            private AuthorizationService: aq.services.AuthorizationService,
            private $q: ng.IQService
        ) {
            Restangular.all('Apps').getList()
                .then((apps) => {
                    this.allApps = _.map(apps, 'name');
                    this.allAppsWithIds = apps;
                    apps.forEach((app) => {
                        // @ts-ignore
                        this.access[app.name] = {};
                    });
                });
        }

        public buildAccessObject(profileApps, permissions): Access {
            _.each(permissions, (permission) => {
                const app = _.find(profileApps, (profileApp) => {
                    return profileApp.id === permission.app;
                });
                if (!app) {
                    return;
                }

                // @ts-ignore
                this.access[app.name] = {};
                _.each(permission.allContainingAccesses, (accessLevel) => {
                    this.access[app.name][accessLevel] = true;
                });
                _.each(app.accessList, (accessLevel) => {
                    if (!this.access[app.name][accessLevel]) {
                        this.access[app.name][accessLevel] = false;
                    }
                });
            });
            return this.access;
        }

        public isInitialized(): boolean {
            return Boolean(this.permissions && this.allApps && this.functionalities);
        }

        // this function erases everything for starting fresh
        public init(apps, isSwitchingAccount?: boolean): void {
            this.profileApps = apps;
            if (isSwitchingAccount) {
                this.permissions = null;
                this.functionalities = null;
            }
        }

        // this should only be run at root
        public initializePermissions(accountId): ng.IPromise<Access> {
            this.functionalities = null;
            this.permissions = null;
            this.access = {};
            this.profileApps = null;
            // added functionalities here to have accountID
            return this.$q.all([
                this.Restangular.one('accounts', accountId)
                    .getList('Functionalities')
                    .then((accountFunctionalities) => {
                        const functionalities = _.map(accountFunctionalities, 'function');
                        this.functionalities = functionalities;
                        return functionalities;
                    }),
                this.Restangular.one('users', 'me')
                    .get({ inflate: 'currentProfile,currentProfile.apps' })
                    .then(currentApp => {
                        const profileApps = currentApp.currentProfile.apps;
                        this.profileApps = profileApps;
                        return profileApps;
                    }),
                this.Restangular.one('accounts', accountId).one('users', 'myPermissions')
                    .get({ inflate: 'permissions' })
                    .then((serverPermissions) => {
                        this.permissions = serverPermissions;
                        return serverPermissions;
                    })
            ]).then((result) => {
                const access = this.buildAccessObject(result[1], result[2]);
                this.access = access;
                return access;
            });
        }

        public logout(): void {
            this.AuthorizationService.logout();
            this.functionalities = null;
            this.permissions = null;
            this.access = {};
            this.profileApps = null;
            this.$window.location.href = `${this.$window.origin}/login`;
        }

        public check(obj): boolean {
            if (!obj) return false;

            const appName = obj.appName || this.$state.current.data.appName;

            // we don't have this app at all, meaning it is some frontend app
            // which doesn't have a real backend app, like Profile
            if (this.allApps.indexOf(appName) === -1) {
                return true;
            }

            if (!this.permissions || !this.profileApps) return false;

            const app = _.find(this.profileApps, { name: appName });

            if (!app) {
                return false;
            }

            const appPermission = _.find(this.permissions, { app: app.id });

            if (!appPermission) return false;

            const whatAccess = obj.access || 'READ_ONLY';

            return _.includes(appPermission.allContainingAccesses, whatAccess.toUpperCase());
        }

        public hasFunctionality(functionToCheck) {
            return (this.functionalities || []).indexOf(functionToCheck) > -1;
        }
    }

    angular
        .module('aq.auth', ['aq.services', 'restangular', 'ui.router'])
        .service('Auth', Auth);
}
