namespace aq.propertySettings {
    export class MeterOfflineComponentCtrl {
        public users: aq.common.models.User[];
        public alert: any;
        public building;
        public collectors: aq.common.models.Collector[];
        public currentUser: aq.common.models.User;
        private aqUserRegex = /\@aquicore/igm;

        /* @ngInject */
        constructor(private UserService) {
            this.currentUser = UserService.currentUser;
        }

        public queryUsers(criteria) {
            return this.users.filter((user: aq.common.models.User) => {
                const match = user.fullName.toLowerCase().indexOf(criteria.toLowerCase()) > -1 && !_.includes(this.alert.selectedUsers, user);
                const aqUser = user.email.match(this.aqUserRegex) != null;
                const currentUserIsAq = this.currentUser.email.match(this.aqUserRegex) !== null;
                if (currentUserIsAq && match) {
                    return true;
                } else if (!currentUserIsAq && match && !aqUser) {
                    return true;
                } else {
                    return false;
                }
            });
        }
    }

    angular.module('properties')
    .component('meterOfflineDetails', {
        templateUrl: 'app/properties/alerts/directives/meterOfflineDetails.html',
        bindings: {
            building: '<',
            alert: '<',
            users: '<',
            collectors: '<',
            form: '='
        },
        controller: MeterOfflineComponentCtrl
    });
}
