namespace aq.dashboard.widgets {

    import AlertConfig = aq.models.alerts.AlertConfig;
    import AlertOption = aq.models.alerts.AlertOption;

    export class AlertEditService {
        public onSelectedBuildingChange(cfg: AlertConfig, data: string) {
            cfg.buildingId = data;
            cfg.alertId = null;
            cfg.alertType = null;
            if (cfg.options.getAlertOptions != undefined) {
                cfg.options.getAlertOptions(cfg.buildingId, true)
                    .then((alertOptions) => {
                        cfg.options.alerts = alertOptions;
                    });
            }
            this.generateDefaultTableTitle(cfg);
        }
        public onAlertChange(cfg: AlertConfig, item: AlertOption) {
            const selectedItem = _.find(cfg.options.alerts, (a) => a.value == cfg.alertId);
            if (selectedItem) {
                cfg.alertType = selectedItem.type;
            }
            this.generateDefaultTableTitle(cfg);
        }
        public onShowTableTitleChange(cfg: AlertConfig) {
            this.generateDefaultTableTitle(cfg);
        }
        public onIsCustomTableTitleChange(cfg: AlertConfig) {
            this.generateDefaultTableTitle(cfg);
        }
        public generateDefaultTableTitle(cfg: AlertConfig) {
            if (cfg.isCustomTitle) {
                return;
            }
            const building = _.find(cfg.options.buildings, (b) => b.id == cfg.buildingId);
            const selectedAlert = _.find(cfg.options.alerts, (a) => a.value == cfg.alertId);
            let title = '';
            if (selectedAlert) {
                title = selectedAlert.label;
            }
            if (building && building.name) {
                title = `${building.name} - ${title}`;
            }
            cfg.title = title;
        }
    }
    angular.module('aq.dashboard.widgets').service('AlertEditService', AlertEditService);
}
