var aq;
(function (aq) {
    var tenantbilling;
    (function (tenantbilling) {
        var AddTenantCtrl = /** @class */ (function (_super) {
            __extends(AddTenantCtrl, _super);
            /* @ngInject */
            function AddTenantCtrl($mdDialog, RestangularV3, Errors, Messages, accountId, buildingId, leaseTypes, TenantServiceHelper, areaMeasurement) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.RestangularV3 = RestangularV3;
                _this.Errors = Errors;
                _this.Messages = Messages;
                _this.accountId = accountId;
                _this.buildingId = buildingId;
                _this.leaseTypes = leaseTypes;
                _this.TenantServiceHelper = TenantServiceHelper;
                _this.areaMeasurement = areaMeasurement;
                _this.tenant = {
                    building: _this.buildingId
                };
                return _this;
            }
            AddTenantCtrl.prototype.cancel = function (data) {
                this.$mdDialog.cancel();
            };
            AddTenantCtrl.prototype.hide = function () {
                this.$mdDialog.hide(this.RestangularV3.copy(this.tenant));
            };
            AddTenantCtrl.prototype.save = function () {
                var _this = this;
                return this.TenantServiceHelper.createTenant(this.tenant)
                    .then(function (result) {
                    _.extend(_this.tenant, result);
                    _this.hide();
                });
            };
            AddTenantCtrl.prototype.clearImage = function () {
                this.tenant.imageUrl = null;
                this.tenant.imageThumbnailUrl = null;
            };
            return AddTenantCtrl;
        }(aq.common.Controllers.ModalCtrl));
        tenantbilling.AddTenantCtrl = AddTenantCtrl;
        angular.module('tenantBilling').controller('AddTenantCtrl', AddTenantCtrl);
    })(tenantbilling = aq.tenantbilling || (aq.tenantbilling = {}));
})(aq || (aq = {}));
