namespace aq.dashboard.models {
    export interface Dashboard {
        id?: string;
        name: string;
        configuration?: string;
        open?: boolean;
        state? : string;
    }

    export interface DashboardElement extends Dashboard, restangular.IElement {

    }

    export interface DataValue {
        timestamp: number;
        value: number;
        isMissingData?: boolean;
        color?: string;
    }

    export interface RangeDataValue {
        timestamp: number;
        value: number[];
    }

    export class DataPeriod {
        start: any;
        end: any;
    }

}
