var aq;
(function (aq) {
    var reports;
    (function (reports) {
        var CustomDateRangeBuildingReportCtrl = /** @class */ (function (_super) {
            __extends(CustomDateRangeBuildingReportCtrl, _super);
            /* ngInject */
            function CustomDateRangeBuildingReportCtrl(currentDate, account, building, energyStarScore, functionalities, OptionsService, availableMetrics, meters, $timeout, $q, ReportApiParams, DataService, MonthlyOccupancyData, CustomDateRangeChartsService, $filter, buildingPersonnels, startDate, endDate) {
                var _this = _super.call(this, currentDate, account, building, energyStarScore, functionalities, OptionsService, availableMetrics, meters, $timeout) || this;
                _this.currentDate = currentDate;
                _this.account = account;
                _this.building = building;
                _this.energyStarScore = energyStarScore;
                _this.functionalities = functionalities;
                _this.OptionsService = OptionsService;
                _this.availableMetrics = availableMetrics;
                _this.meters = meters;
                _this.$timeout = $timeout;
                _this.$q = $q;
                _this.ReportApiParams = ReportApiParams;
                _this.DataService = DataService;
                _this.MonthlyOccupancyData = MonthlyOccupancyData;
                _this.CustomDateRangeChartsService = CustomDateRangeChartsService;
                _this.$filter = $filter;
                _this.buildingPersonnels = buildingPersonnels;
                _this.startDate = startDate;
                _this.endDate = endDate;
                _this.personas = {
                    'ASSET_MANAGER': 'Asset Manager',
                    'PROPERTY_MANAGER': 'Property Manager',
                    'BUILDING_ENGINEER': 'Building Engineer'
                };
                _this.time = angular.copy(_this.buildingReport.building.currentTime);
                _this.time.subtract(1, 'month');
                _this.currentMonth = angular.copy(_this.time);
                _this.monthIndex = parseInt(_this.currentMonth.format('M')) - 1;
                _this.occupancyData = _this.MonthlyOccupancyData.getMonthlyOccupancy(building, _this.monthIndex, null);
                _this.previousTime = moment(_this.time).subtract(1, 'month');
                var buildingPersonnel = _this.getBuildingPersonnel(_this.building);
                _this.building.personnel = buildingPersonnel;
                _this.dateRangeNumDays = _this.endDate.diff(_this.startDate, 'days');
                _this.numDaysToStopUsingDailyData = 60;
                _this.olderRangeEndDate = angular.copy(_this.startDate);
                _this.olderRangeEndDate.subtract(1, 'days');
                _this.olderRangeStartDate = angular.copy(_this.olderRangeEndDate);
                _this.olderRangeStartDate.subtract(_this.dateRangeNumDays, 'days');
                _this.buildReports();
                return _this;
            }
            CustomDateRangeBuildingReportCtrl.prototype.buildReports = function () {
                var _this = this;
                var reportPromises = [];
                _.each(this.buildingReport.serviceReports, function (report) {
                    var config = report.config;
                    report.data = {
                        loading: true
                    };
                    //values used in all charts
                    var dailyDataParams = new _this.ReportApiParams(_this.time, config.measures, 'monthly', _this.startDate, _this.endDate);
                    var olderRangeDailyDataParams = new _this.ReportApiParams(_this.time, config.measures, 'monthly', _this.olderRangeStartDate, _this.olderRangeEndDate);
                    var currentDailyTemperaturePromise = _this.building.customGET('temperature', dailyDataParams.current());
                    var currentDailyDataPromise = _this.building.customGET('data', dailyDataParams.current());
                    var olderRangeDailyDataPromise = _this.building.customGET('data', olderRangeDailyDataParams.current());
                    var reportDefer = _this.$q.defer();
                    _this.$q.all([
                        currentDailyDataPromise,
                        olderRangeDailyDataPromise,
                        currentDailyTemperaturePromise,
                    ]).then(function (resultsList) {
                        var resultsHolder = { currentDailyValues: null, currentDailyTemperatureValues: null, olderRangeDailyValues: null };
                        resultsHolder.currentDailyValues = resultsList[0];
                        resultsHolder.olderRangeDailyValues = resultsList[1];
                        resultsHolder.currentDailyTemperatureValues = resultsList[2].values;
                        var handlePromises = [];
                        if (_this.dateRangeNumDays > _this.numDaysToStopUsingDailyData) {
                            _this.demandIntervalTitle = "Daily Peak";
                            if (config.usageMetric != 'gas') {
                                handlePromises.push(_this.handleDailyDemand(report.data, report.config, resultsHolder));
                            }
                        }
                        else {
                            _this.demandIntervalTitle = "15 Minute Interval";
                            if (config.usageMetric != 'gas') {
                                handlePromises.push(_this.handleHourlyDemand(report.data, report.config));
                            }
                        }
                        handlePromises.push(_this.handleDailyWeatherAndUsage(report.data, report.config, resultsHolder));
                        return _this.$q.all(handlePromises).then(function () {
                            report.data.loading = false;
                        });
                    }).then(function () {
                        reportDefer.resolve();
                    }).catch(function () {
                        reportDefer.reject();
                    });
                    reportPromises.push(reportDefer.promise);
                });
                this.$q.all(reportPromises)
                    .then(function () {
                    _this.notifyDocumentReady();
                })
                    .catch(function () {
                    _this.isDocumentError = true;
                });
            };
            CustomDateRangeBuildingReportCtrl.prototype.handleHourlyDemand = function (data, config) {
                var _this = this;
                var format = 'ddd MMM D h:mma';
                var hourlyDataParams = new this.ReportApiParams(this.time, config.measures, 'daily', this.startDate, this.endDate);
                var olderRangeHourlyDataParams = new this.ReportApiParams(this.time, config.measures, 'daily', this.olderRangeStartDate, this.olderRangeEndDate);
                var hourlyDataPromise = this.building.customGET('data', hourlyDataParams.current());
                var hourlyTemperaturePromise = this.building.customGET('temperature', hourlyDataParams.current());
                var olderRangeHourlyDataPromise = this.building.customGET('data', olderRangeHourlyDataParams.current());
                return this.$q.all([
                    hourlyDataPromise,
                    hourlyTemperaturePromise,
                    olderRangeHourlyDataPromise
                ]).then(function (resultsList) {
                    var currentHourlyValues = resultsList[0];
                    var hourlyTemperatureValues = resultsList[1] ? resultsList[1].values : null;
                    var olderRangeHourlyData = resultsList[2];
                    var currentHourlyTemperature = _this.$filter('arrayToTemperatureUnit')(hourlyTemperatureValues, config.temperatureUnit);
                    var currentDemandArr = currentHourlyValues[config.demandMetric].values;
                    var previousDemandArr = olderRangeHourlyData[config.demandMetric].values;
                    data.demand = currentDemandArr.length > 0 ? Math.max.apply(Math, currentDemandArr) : 0;
                    var demandPeakIndex = currentDemandArr.indexOf(data.demand);
                    data.demandPeakDate = moment(currentHourlyValues[config.demandMetric].timestamps[demandPeakIndex]).format(format);
                    data.previousDemand = previousDemandArr.length > 0 ? Math.max.apply(Math, previousDemandArr) : 0;
                    var currentHourlyData = {
                        data: currentHourlyValues,
                        temperature: currentHourlyTemperature
                    };
                    data.demandChart = _this.CustomDateRangeChartsService.getCustomDemandChart(currentHourlyData, config, _this.currentMonth);
                });
            };
            CustomDateRangeBuildingReportCtrl.prototype.handleDailyDemand = function (data, config, resultsHolder) {
                var _this = this;
                var format = 'ddd MMM D';
                var currentDailyTemperature = this.$filter('arrayToTemperatureUnit')(resultsHolder.currentDailyTemperatureValues, config.temperatureUnit);
                if (config.usageMetric == 'energy') {
                    var dailyDataParams = new this.ReportApiParams(this.time, config.measures, 'monthly', this.startDate, this.endDate);
                    var olderRangeDailyDataParams = new this.ReportApiParams(this.time, config.measures, 'monthly', this.olderRangeStartDate, this.olderRangeEndDate);
                    var currentRangeDailyPeakPromise = this.building.customGET('peaks', dailyDataParams.current());
                    var olderRangeDailyPeakPromise = this.building.customGET('peaks', olderRangeDailyDataParams.current());
                    return this.$q.all([
                        currentRangeDailyPeakPromise,
                        olderRangeDailyPeakPromise
                    ]).then(function (resultsList) {
                        var currentRangeDailyPeaks = resultsList[0];
                        var olderRangeDailyPeaks = resultsList[1];
                        resultsHolder.currentDailyValues[config.demandMetric].values = currentRangeDailyPeaks.values;
                        resultsHolder.olderRangeDailyValues[config.demandMetric].values = currentRangeDailyPeaks.values;
                        var currentDailyData = {
                            data: resultsHolder.currentDailyValues,
                            temperature: currentDailyTemperature
                        };
                        var currentDemandArr = currentRangeDailyPeaks.values;
                        var previousDemandArr = olderRangeDailyPeaks.values;
                        data.demand = currentDemandArr.length > 0 ? Math.max.apply(Math, currentDemandArr) : 0;
                        var demandPeakIndex = currentRangeDailyPeaks.values.indexOf(data.demand);
                        data.demandPeakDate = moment(resultsHolder.currentDailyValues[config.demandMetric].timestamps[demandPeakIndex]).format(format);
                        data.previousDemand = previousDemandArr.length > 0 ? Math.max.apply(Math, previousDemandArr) : 0;
                        data.demandChart = _this.CustomDateRangeChartsService.getCustomDemandChart(currentDailyData, config, _this.currentMonth);
                    });
                }
                else {
                    var currentDailyData = {
                        data: resultsHolder.currentDailyValues,
                        temperature: currentDailyTemperature
                    };
                    var currentDemandArr = resultsHolder.currentDailyValues[config.demandMetric].values;
                    var previousDemandArr = resultsHolder.olderRangeDailyValues[config.demandMetric].values;
                    data.demand = currentDemandArr.length > 0 ? Math.max.apply(Math, currentDemandArr) : 0;
                    var demandPeakIndex = currentDemandArr.indexOf(data.demand);
                    data.demandPeakDate = moment(resultsHolder.currentDailyValues[config.demandMetric].timestamps[demandPeakIndex]).format(format);
                    data.previousDemand = previousDemandArr.length > 0 ? Math.max.apply(Math, previousDemandArr) : 0;
                    data.demandChart = this.CustomDateRangeChartsService.getCustomDemandChart(currentDailyData, config, this.currentMonth);
                }
            };
            CustomDateRangeBuildingReportCtrl.prototype.handleDailyWeatherAndUsage = function (data, config, resultsHolder) {
                var _this = this;
                var dailyDataParams = new this.ReportApiParams(this.time, config.measures, 'monthly', this.startDate, this.endDate);
                var olderRangeDailyDataParams = new this.ReportApiParams(this.time, config.measures, 'monthly', this.olderRangeStartDate, this.olderRangeEndDate);
                var previousDailyDataPromise = this.building.customGET('data', dailyDataParams.previous());
                var currentDailyDegreeDaysPromise = this.DataService.degreeDays(this.building, dailyDataParams.currentDegreeDay().interval, dailyDataParams.currentDegreeDay().start, dailyDataParams.currentDegreeDay().end);
                var previousDailyDegreeDaysPromise = this.DataService.degreeDays(this.building, dailyDataParams.previousDegreeDay().interval, dailyDataParams.previousDegreeDay().start, dailyDataParams.previousDegreeDay().end);
                var olderRangeDailyDegreeDaysPromise = this.DataService.degreeDays(this.building, olderRangeDailyDataParams.currentDegreeDay().interval, olderRangeDailyDataParams.currentDegreeDay().start, olderRangeDailyDataParams.currentDegreeDay().end);
                return this.$q.all([
                    previousDailyDataPromise,
                    currentDailyDegreeDaysPromise,
                    previousDailyDegreeDaysPromise,
                    olderRangeDailyDegreeDaysPromise
                ]).then(function (resultsList) {
                    var previousDailyValues = resultsList[0];
                    resultsHolder.currentDailyValues['degreeDays'] = resultsList[1];
                    previousDailyValues['degreeDays'] = resultsList[2];
                    var olderRangeDailyDegreeDays = resultsList[3];
                    var currentDailyTemperature = _this.$filter('arrayToTemperatureUnit')(resultsHolder.currentDailyTemperatureValues, config.temperatureUnit);
                    var currentDailyData = {
                        data: resultsHolder.currentDailyValues,
                        temperature: currentDailyTemperature
                    };
                    var previousDailyData = {
                        data: previousDailyValues,
                        temperature: currentDailyTemperature
                    };
                    var arrSum = function (arr) { return arr.reduce(function (a, b) { return a + b; }, 0); };
                    var currentUsageArr = resultsHolder.currentDailyValues[config.usageMetric].values;
                    var previousUsageArr = resultsHolder.olderRangeDailyValues[config.usageMetric].values;
                    var currentHDDArr = resultsHolder.currentDailyValues['degreeDays'].hdd.values;
                    var olderRangeHDDArr = olderRangeDailyDegreeDays.hdd.values;
                    var currentCDDArr = resultsHolder.currentDailyValues['degreeDays'].cdd.values;
                    var olderRangeCDDArr = olderRangeDailyDegreeDays.cdd.values;
                    data.usage = arrSum(currentUsageArr);
                    data.previousUsage = arrSum(previousUsageArr);
                    data.currentHDD = arrSum(currentHDDArr);
                    data.previousHDD = arrSum(olderRangeHDDArr);
                    data.currentCDD = arrSum(currentCDDArr);
                    data.previousCDD = arrSum(olderRangeCDDArr);
                    data.usageChart = _this.CustomDateRangeChartsService.getCustomUsageChart(currentDailyData, config, _this.currentMonth);
                    data.weatherChart = _this.CustomDateRangeChartsService.getCustomWeatherChart(currentDailyData, previousDailyData, config, _this.currentMonth);
                });
            };
            CustomDateRangeBuildingReportCtrl.prototype.getServiceType = function (usageMetric) {
                switch (usageMetric) {
                    case 'energy':
                        return 'Electricity';
                    case 'steam_mass':
                        return 'Steam';
                    case 'heat_transfer':
                        return 'Heat';
                    case 'gas':
                        return 'Gas';
                    case 'water':
                        return 'Water';
                    default:
                        return '';
                }
            };
            CustomDateRangeBuildingReportCtrl.prototype.getBuildingPersonnel = function (building) {
                var data = _.find(this.buildingPersonnels, { buildingId: building.id });
                if (data && data.personnel && data.personnel.length > 0) {
                    _.each(data.personnel, function (item) {
                        if (!item.email) {
                            return;
                        }
                        var emailSplit = item.email.split('@');
                        if (emailSplit.length != 2) {
                            return;
                        }
                        item.emailUsername = emailSplit[0];
                        item.emailDomain = '@' + emailSplit[1];
                    });
                    var sortedPersonnel_1 = [];
                    _.each(this.personas, function (persona, key) {
                        var nextPerson = _.find(data.personnel, function (item) { return item.persona == key; });
                        if (nextPerson) {
                            sortedPersonnel_1.push(nextPerson);
                        }
                    });
                    return sortedPersonnel_1;
                }
                else {
                    return [];
                }
            };
            return CustomDateRangeBuildingReportCtrl;
        }(reports.BaseBuildingReportCtrl));
        reports.CustomDateRangeBuildingReportCtrl = CustomDateRangeBuildingReportCtrl;
        angular.module('aq.reports').controller('CustomDateRangeBuildingReportCtrl', CustomDateRangeBuildingReportCtrl);
    })(reports = aq.reports || (aq.reports = {}));
})(aq || (aq = {}));
