namespace aq.dashboard.widgets {
    export class Variance {
        public main: number;
        public reference: number;
        public unit: string;

        public isEnabled: boolean;
        public sign: -1 | 1 | 0;
        public percent: number;
        public delta: number;

        public $onInit() {
            if (this.main > 0 && this.reference > 0) {
                if (this.main != null && this.reference != null) {
                    this.delta = this.main - this.reference;
                    if (this.delta == 0) {
                        this.sign = 0;
                    } else {
                        this.sign = this.delta > 0 ? 1 : -1;
                    }
                    this.percent = Math.round(Math.abs(this.delta) * 10000 / this.reference) / 100;
                    this.isEnabled = true;
                    this.delta = Math.abs(this.delta);
                } else {
                    this.isEnabled = false;
                }
            }
        }
    }
    angular.module('aq.dashboard.widgets').component('variance', {
        controller: Variance,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/common/widgets/portfolioVariance/components/variance.html',
        bindings: {
            main: '<',
            reference: '<',
            unit: '<'
        }
    });
}
