angular
    .module('energyInsights', ['aq.ui', 'aq.services.user', 'aq.services.data', 'aq.filters.units', 'aq.filters.formatters', 'aq.ui.intervalSelector', 'aq.services.options', 'aq.services'])
    .config((RestangularProvider: restangular.IProvider, $stateProvider: ng.ui.IStateProvider) => {
        $stateProvider
            .state('aq.energyInsights', {
                url: '/accounts/:accountId/energy-insights/building/{buildingId}',
                templateUrl: 'app/energyInsights/main/main.html',
                controller: 'EnergyInsightsMainCtrl as energyInsights',
                data: {
                    appName: 'Energy Insights'
                },
                resolve: {
                    account(waitForAuthToken, Restangular: restangular.IService, $stateParams: ng.ui.IStateParamsService): ng.IPromise<aq.common.models.Account> {
                        return Restangular.one('accounts', $stateParams.accountId).get({ single: true });
                    },
                    buildings(waitForAuthToken, RestangularV3: restangular.IService) {
                        return RestangularV3.one('').getList('buildings', { showInactive: false });
                    },
                    currentBuilding(waitForAuthToken, $stateParams, buildings, AppStorageService: aq.services.AppStorageService) {
                        const { buildingId, accountId } = $stateParams;
                        const storage = AppStorageService.getStorage('buildingSelector', accountId);
                        if (buildingId) {
                            return _.find(buildings, { id: parseInt(buildingId, 10) });
                        }
                        if (storage) {
                            const storageBuilding = _.find(buildings, { id: storage.buildingId });
                            return storageBuilding || buildings[0];
                        }
                        return buildings[0];
                    },
                    options(OptionsService, account: aq.common.models.Account): ng.IPromise<any> {
                        return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
                    },
                    insights() {
                        // Returns an array of all insights except for the 'Utility Spending' insight
                        // TODO AQ-7515: Remove 'Utility Spending' from aq.energyInsights.models entirely and
                        //       migrate every bit of that logic to 'Utility Budgets' module
                        return aq.energyInsights.models.insights.filter((item) => { return item.id !== 1; });
                    }
                }
            })
            .state('aq.energyInsights.peakDemand', {
                url: '/peak-demand?start&end',
                templateUrl: 'app/energyInsights/peakDemand/peakDemand.html',
                controller: 'PeakDemandCtrl as peakDemand',
                data: {
                    breadcrumb: 'Peak Demand'
                },
                resolve: {
                    metric(account: aq.common.models.Account): ng.IPromise<any> {
                        return account.customGET('queryRealUnits').then((allUnits) => {
                            return _.find(allUnits, { unit: 'kW' });
                        });
                    },
                    targets(
                        DataStore: aq.common.DataStore,
                        Restangular, currentBuilding: aq.common.models.Building,
                        account: aq.common.models.Account,
                        waitForAuthToken
                    ): ng.IPromise<any> {
                        return DataStore.getList(Restangular
                            .one('accounts', account.id)
                            .one('buildings', currentBuilding.id)
                            .one('Targets'), 'queryTargets', {
                            startDate: moment().subtract(2, 'year').format(),
                            endDate: moment().format(),
                            targetType: 'PEAK_DEMAND'
                        });
                    }
                },
                onEnter(Segment: aq.services.SegmentService) {
                    Segment.trackPage('Energy Insights:Peak Demand');
                },
                reloadOnSearch: false
            })
            .state('aq.energyInsights.baseload', {
                url: '/baseload',
                templateUrl: 'app/energyInsights/baseload/baseload.html',
                controller: 'BaseloadCtrl as baseload',
                data: {
                    breadcrumb: 'Baseload'
                }
            })
            .state('aq.energyInsights.baseloadV2', {
                url: '/baseloadV2',
                templateUrl: 'app/energyInsights/baseloadV2/baseloadV2.html',
                controller: 'BaseloadV2Ctrl as baseload',
                data: {
                    breadcrumb: 'Baseload'
                }
            })
            .state('aq.energyInsights.weatherNormalization', {
                url: '/weatherNormalization',
                templateUrl: 'app/energyInsights/weatherNormalization/weatherNormalization.html',
                controller: 'WeatherNormalizationCtrl as weatherNormalization',
                data: {
                    breadcrumb: 'Consumption'
                },
                resolve: {
                    metric(account: aq.common.models.Account): ng.IPromise<any> {
                        return account.customGET('queryRealUnits').then((allUnits) => {
                            return _.find(allUnits, { unit: 'kWh' });
                        });
                    },
                    energyNotes(EnergyInsightsDataService: aq.energyInsights.service.EnergyInsightsDataService,
                        account: aq.common.models.Account, currentBuilding: aq.common.models.Building) {
                        return EnergyInsightsDataService.getEnergyNotes(account, currentBuilding);
                    }
                },
                onEnter(Segment: aq.services.SegmentService) {
                    Segment.trackPage('Energy Insight:Baseload');
                }
            })
            .state('aq.energyInsights.consumption', {
                url: '/consumption',
                templateUrl: 'app/energyInsights/consumption/consumption.html',
                controller: 'ConsumptionCtrl as consumptionCtrl',
                data: {
                    breadcrumb: 'Consumption'
                },
                resolve: {
                    metric(account: aq.common.models.Account): ng.IPromise<any> {
                        return account.customGET('queryRealUnits').then((allUnits) => {
                            return _.find(allUnits, { unit: 'kWh' });
                        });
                    },
                    energyNotes(EnergyInsightsDataService: aq.energyInsights.service.EnergyInsightsDataService,
                        account: aq.common.models.Account, currentBuilding: aq.common.models.Building) {
                        return EnergyInsightsDataService.getEnergyNotes(account, currentBuilding);
                    }
                },
                onEnter(Segment: aq.services.SegmentService) {
                    Segment.trackPage('Energy Insight:Consumption');
                }
            });
    })
    .run(($rootScope, UserService, Auth) => {
        UserService.init();
    });

namespace aq.energyInsights {

    export abstract class InsightService {
        abstract getTargetModel(building: aq.common.models.Building,
            measure: string,
            timePeriod: PeriodSearch,
            account: aq.common.models.Account,
            name: string): ng.IPromise<TargetModel>;

        abstract getGraphModel(building: aq.common.models.Building,
            measure: string,
            timePeriod: PeriodSearch,
            account: aq.common.models.Account): ng.IPromise<GraphModel>;
        abstract getTableModel(building: aq.common.models.Building,
            measure: string,
            timePeriod: PeriodSearch,
            account: aq.common.models.Account,
            name: string): ng.IPromise<TableModel>;
        abstract formatTableModel(values, targets, periodSearch, building: aq.common.models.Building);
        abstract getSimpleTargetModel(building: aq.common.models.Building, measure: string, periodSearch: PeriodSearch, name: string): SimpleTargetModel;

        getPercent(numerator: number, denominator: number): number {
            if (denominator && denominator != 0) {
                return Math.round((numerator / denominator) * 100);
            } else {
                return 0;
            }
        }

        getStatus(answer, target, percentage, percTime): string {
            if (!target) {
                return 'invalid';
            }
            if (answer > target) {
                return 'over';
            }
            if (percTime === 100) {
                if (answer === target) {
                    return 'warn';
                } else if (answer < target) {
                    return 'under';
                }
            } else {
                if (percentage < percTime) {
                    return 'under';
                } else if (percentage >= percTime) {
                    return 'warn';
                }
            }
        }

        getColor(answer, target, percentage, percTime): string {
            const status = this.getStatus(answer, target, percentage, percTime);
            switch (status) {
                case 'under':
                    return '#7ACF46';
                case 'warn':
                    return '#EFAF55';
                case 'over':
                    return '#C33';
                default:
                    return '#979797';
            }
        }

        getIcon(answer, target, percentage, percTime) {
            const status = this.getStatus(answer, target, percentage, percTime);
            if (status === 'under') {
                return 'check_box';
            } else if (status === 'warn') {
                return 'warning';
            } else if (status === 'over') {
                return 'error';
            }
        }
    }

    export class SimpleTargetModel {
        title: string;
        when: string;
        whenTooltip: string;
        name: string;
    }

    export class TargetModel {
        title: string;
        target: any;
        formattedTarget: string;
        question: string;
        answer: any;
        formattedAnswer: string;
        unit: string;
        when: string;
        whenTooltip: string;
        color: string;
        tooltip: string;
        icon: string;
        iconColor: string;
        widgetIcon: string;
        percentage: number;
        timeElapsedPercentage: number;
        timeElapsedLabel: string;
        showDonut: boolean;
        showTarget: boolean;
        buildingId: string;
        totals?: TargetModelTotals;
        measureUnit?: string;
        accountId?: number;
    }

    export class GraphModel {
        graph: any;
    }

    export class TableModel {
        rows: any[];
        stats: any;
        totals?: any;
        isMissingData?: boolean;
    }

    export interface TargetModelTotals {
        budgetSum: number;
        spend: number;
        projection: number;
        blendedRate: number;
        currencySymbol: string;
        demandRate: number;
    }
}
