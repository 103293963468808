/**
 * Merges overlapping series into one
 *
 * For example if we have two series with the same timestamp
 * [
 * { name: "Serie 1", timestamps: [1,2,3] },
 * { name: "Serie 2", timestamps: [2,3,4] }
 * ]
 *
 * it will create a new serie for overlapping timestamps 2,3 and leave non-overlapping timestamps
 * in the original series
 *
 * Will return:
 * [
 * { name: "Serie 1", timestamps: [1] },
 * { name: "Serie 2", timestamps: [4],
 * { "name": Serie 1, Serie 2", timestamps: [2,3] }
 * ]
 *
 */
angular.module('charting').filter('mergeSeries', function() {

    return function(timestamps, series) {
        var merged = [];

        _.each(timestamps, function(timestamp) {
            var matchedSeries = _.filter(series, function(serie) {
                return serie.timestamps.indexOf(timestamp) > -1;
            });

            if (matchedSeries.length > 0) {
                var name = _.map(matchedSeries, 'name').join(', ');
                var mergedSerie = _.find(merged, {name: name});
                if (!mergedSerie) {
                    merged.push({
                        name: name,
                        timestamps: [timestamp]
                    });
                } else {
                    mergedSerie.timestamps.push(timestamp);
                }
            }
        });

        return merged;
    }

});