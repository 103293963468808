'use strict';

angular.module('properties.dataCollectors')
.controller('HistoricalUploadCtrl', function ($scope, Messages, Restangular, Errors, Auth, $stateParams, $state, loading, $q, FilePicker) {

    $scope.popover = { }

    $scope.historicDataItem = null;
    $scope.fileName = null;

    $scope.historicDataShowFilePicker = function () {
        FilePicker.pickAndStore({mimetype: 'text/csv'}, {location: 'S3', access: 'public'}).then(function (result) {
            $scope.fileName = result[0].filename;
            $scope.collector.post('historicDataItems', {
                collector: $scope.collector.id,
                url: result[0].url,
                filename: result[0].filename
            }).then(function (result) {
                $scope.historicDataItem = _.extend(result, result.model);
                $scope.popover.content = $scope.historicDataItem.description;
            }, Errors.forPromise());
        }, function (result) {
            if (result.code != 101) Messages.error("Error uploading file");
        });
    }

    $scope.upload = function () {
        $scope.historicDataItem.status = 'PENDING';
        $scope.historicDataItem.put().catch(Errors.forPromise());
    }

});
