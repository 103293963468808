namespace aq.models.optimalStart {

    export class OptimalResponse {
        public isenrolledost: boolean;
        public nextdate: string;
        public nextactual: string;
        public nexttarget: string;
        public nexttargetmessage: string;
        public currentdate: string;
        public currentactual: string;
        public currenttarget: string;
        public currenttargetmessage: string;
        public prevdate: string;
        public prevactual: string;
        public prevtarget: string;
        public prevtargetmessage: string;
    }
}
