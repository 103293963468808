namespace aq.admin.accounts.enrollment {

    export class BulkUpdateCtrl {
        public phaseDisplayModel: PhaseDisplayModel[];
        public editTask: aq.projectCenter.Task;
        public modalButtonsDisabled: boolean;

        /* @ngInject */
        constructor(
            private $mdDialog,
            private phaseTasks: PhaseTasks[],
            private selectedTasks: string[],
            private selectedBuildings: BuildingTasks[],
            private buildingEnrollment: aq.propertySettings.BuildingEnrollment[],
            private allBuildings: BuildingTasks[],
            private RestangularV3: restangular.IService,
            protected Messages: aq.services.Messages,
            private $q: ng.IQService
        ) {
            this.phaseDisplayModel = this.phaseTasks.map((phase) => {
                const tempTasks = phase.tasks.map((task) => {
                    if (this.selectedTasks.indexOf(task.name) !== -1) {
                        return {
                            currentName: task.name,
                            oldName: '',
                            edit: false,
                            parentPhase: phase.phase,
                            hasEventHook: task.eventHook ? true : false
                        };
                    } else {
                        return null;
                    }
                });

                return {
                    phase: phase.phase,
                    tasks: tempTasks.filter((tempTask) => {
                        return tempTask !== null;
                    }),
                    addNewTask: false
                };
            });
        }

        public startEditTask(task: SelectionModel) {
            task.edit = true;
            this.modalButtonsDisabled = true;
            this.editTask = _.cloneDeep(this.selectedBuildings[0].taskMap[task.currentName]);
        }

        public endEditTask(taskToEdit: EditTask, currentTask: SelectionModel) {
            const taskEditFields = [
                'name',
                'details'
            ];
            const taskUpdatePromises = [];
            this.buildingEnrollment.forEach((be) => {
                be.phases.forEach((bePhase) => {
                    const taskToUpdate = bePhase.tasks.find((t) => { return t.name === taskToEdit.oldName; });
                    if (taskToUpdate) {
                        _.assign(taskToUpdate, _.pick(taskToEdit as aq.projectCenter.Task, taskEditFields));
                        delete taskToUpdate['ownerName'];
                        delete taskToUpdate['eventHandler'];
                        delete taskToUpdate['complete'];
                        taskUpdatePromises.push(
                            this.RestangularV3.one('projects', taskToUpdate.project).one('tasks', taskToUpdate.id).customPUT(taskToUpdate)
                        );
                    }
                });
            });
            currentTask.edit = false;
            this.$q.all(taskUpdatePromises)
                .then(() => {
                    this.selectedBuildings.forEach((building) => {
                        building.taskMap[taskToEdit.name] = {
                            ..._.cloneDeep(building.taskMap[taskToEdit.oldName]),
                            name: taskToEdit.name,
                            details: taskToEdit.details
                        };
                        if (taskToEdit.oldName !== taskToEdit.name) {
                            delete building.taskMap[taskToEdit.oldName];
                        }
                    });

                    this.selectedTasks = this.updateSelectedTasks(taskToEdit, this.selectedTasks);
                    currentTask.currentName = taskToEdit.name;
                    this.modalButtonsDisabled = false;
                    this.Messages.success('Successfully updated task');
                })
                .catch((err) => {
                    this.modalButtonsDisabled = false;
                    currentTask.edit = false;
                    this.Messages.error('Error updating task');
                });
        }

        public deleteTask(phase: PhaseDisplayModel, task: SelectionModel) {
            this.selectedBuildings.forEach((building) => {
                delete building.taskMap[task.currentName];
            });
            this.phaseDisplayModel.forEach((display) => {
                if (display.phase === phase.phase) {
                    display.tasks = display.tasks.filter((newTask) => {
                        return newTask.currentName !== task.currentName;
                    });
                }
            });
            this.buildingEnrollment.forEach((be) => {
                be.phases.forEach((bePhase) => {
                    bePhase.tasks = bePhase.tasks.filter((newTask) => {
                        return newTask.name !== task.currentName;
                    });
                });
            });
        }

        public createNewTask(phase: PhaseDisplayModel, newTask: EditTask) {
            phase.addNewTask = false;
            const taskFields = [
                'name',
                'details'
            ];
            const taskUpdatePromises = [];
            this.buildingEnrollment.forEach(async (be) => {
                const updatePhase = be.phases.find((p) => { return p.name === phase.phase; });
                const newTaskRequest = _.pick(newTask as aq.projectCenter.Task, taskFields);
                newTaskRequest.project = be.project.toString();
                newTaskRequest.projectPhase = updatePhase.phase.toString();
                delete newTaskRequest['ownerName'];
                delete newTaskRequest['eventHandler'];
                delete newTaskRequest['complete'];
                taskUpdatePromises.push(this.RestangularV3.one('projects', newTaskRequest.project).post('tasks', newTaskRequest));
            });

            this.$q.all(taskUpdatePromises)
                .then((results: any) => {
                    results.forEach((result) => {
                        const be = this.buildingEnrollment.find(b => { return b.project === result.project; });
                        const bePhase = be.phases.find(p => { return p.phase === result.projectPhase; });
                        bePhase.tasks.push(result);
                        const building = this.selectedBuildings.find(b => { return b.buildingId === be.building; });
                        building.taskMap[result.name] = _.cloneDeep(result);
                    });

                    this.phaseDisplayModel.forEach((display) => {
                        if (display.phase === phase.phase) {
                            display.tasks.push({
                                currentName: newTask.name,
                                oldName: '',
                                edit: false,
                                parentPhase: phase.phase,
                                hasEventHook: false
                            });
                        }
                    });
                    this.selectedTasks.push(newTask.name);
                    this.modalButtonsDisabled = false;
                    this.Messages.success('Successfully Created New task');
                })
                .catch((err) => {
                    this.Messages.error('Error creating task');
                    this.modalButtonsDisabled = false;
                });
        }

        public cancelCreateNewTask(phase: PhaseDisplayModel) {
            phase.addNewTask = false;
            this.modalButtonsDisabled = false;
        }

        public cancelEditTask(task: SelectionModel) {
            task.edit = false;
            this.modalButtonsDisabled = false;
        }

        public openCreateNewTask(phase: PhaseDisplayModel) {
            phase.addNewTask = true;
            this.modalButtonsDisabled = true;
        }

        public cancel() {
            this.$mdDialog.cancel();
        }

        public update(selectedTasks: string[], selectedBuildings: BuildingTasks[], buildingEnrollment: aq.propertySettings.BuildingEnrollment[]) {
            const data = {
                selectedTasks,
                selectedBuildings,
                buildingEnrollment
            };
            this.$mdDialog.hide(data);
        }

        private updateSelectedTasks(taskToEdit: EditTask, selectedTasks: string[]): string[] {
            const updateInPlace = !this.allBuildings.filter(building => {
                    return building.taskMap[taskToEdit.oldName];
                })
                .some(building => {
                    if (building.taskMap[taskToEdit.oldName]) {
                        return !building.isSelected;
                    }
                });
            if (updateInPlace) {
                return selectedTasks.map(task => {
                    if (task === taskToEdit.oldName) {
                        return taskToEdit.name;
                    } else {
                        return task;
                    }
                });
            } else {
                selectedTasks.push(taskToEdit.name);
                return _.cloneDeep(selectedTasks);
            }
        }
    }

    angular.module('aq.admin.accounts.enrollment').controller('BulkUpdateCtrl', BulkUpdateCtrl);
}
