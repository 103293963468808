
namespace aq.models.segment {

    export enum AidActions {
        MODIFY_AID_ISSUE = 'MODIFY_AID_ISSUE',
        RESOLVE_AND_CLOSE_ISSUE = 'RESOLVE_AND_CLOSE_ISSUE',
        ACKNOWLEDGE_AID_ISSUE = 'ACKNOWLEDGE_AID_ISSUE'
    }

    export enum ActivityActions {
        VIEW_ACTIVITY = 'VIEW_ACTIVITY',
        UPDATE_ACTIVITY_FILTER = 'UPDATE_ACTIVITY_FILTER',
        CURRENT_ACTIVITY_LIST = 'CURRENT_ACTIVITY_LIST',
        ACKNOWLEDGE_ALERT = 'ACKNOWLEDGE_ALERT'
    }

    export enum WidgetActions {
        ADD_WIDGET = 'ADD_WIDGET'
    }

    export enum UserActions {
        USER_LOGIN = 'USER_LOGIN',
        ADD_NEW_USER = 'ADD_NEW_USER',
        ACCEPT_INVITE = 'ACCEPT_INVITE'
    }

    export enum HelpActions {
        HELP_OPEN = 'HELP_OPEN',
        HELP_REQUEST_GUIDE = 'HELP_REQUEST_GUIDE',
        HELP_REQUEST_INTERCOM = 'HELP_REQUEST_INTERCOM',
        HELP_REQUEST_EMAIL = 'HELP_REQUEST_EMAIL',
        HELP_REQUEST_WEBINAR = 'HELP_REQUEST_WEBINAR'
    }
}
