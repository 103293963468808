
namespace aq.projectCenter {
    export class ProjectOverviewCtrl {
        private allProjectsLength: number;
        private emptySearch: boolean;
        private emptyFilter: boolean;
        private filtered: Project[];
        private projectsByCategory: { [key: string]: Project[] } = {};
        private currentNavItem = 'TODO';
        private currentView = 'BOARD';
        private filter = {
            selected: { label: 'None', value: 'NONE' },
            options: [
                { label: 'None', value: 'NONE' },
                { label: 'Owned By Me', value: 'OWNED_BY_ME' },
                { label: 'ADA Compliance', value: 'ADA_COMPLIANCE' },
                { label: 'Capital Repairs', value: 'CAPITAL_REPAIRS' },
                { label: 'Energy Efficiency', value: 'ENERGY_EFFICIENCY' }
            ]
        };

        /* @ngInject */
        constructor(
            private projects: Project[],
            private building: aq.common.models.Building,
            private projectStatuses: aq.common.models.SimpleEnum,
            private $state: ng.ui.IStateService,
            private $stateParams: ng.ui.IStateParamsService,
            private $mdDialog: ng.material.IDialogService,
            private RestangularV3: restangular.IService,
            private DataStore: aq.common.DataStore,
            private $location: ng.ILocationService,
            private users: aq.common.models.User[],
            private $mdMedia: ng.material.IMedia,
            private projectTypes: aq.common.models.SimpleEnum,
            private Messages: aq.services.Messages,
            private UserService: aq.services.UserService,
        ) {
            this.filtered = this.projects;
            this.refreshProjectsByCategories();
            this.allProjectsLength = this.projects.length;
        }

        createProject() {
            this.$mdDialog.show({
                controller: 'ProjectCenterNewCtrl as vm',
                templateUrl: 'app/projectCenter/new/projectCenterNewModal.html',
                clickOutsideToClose: false,
                parent: angular.element(document.body),
                locals: {
                    project: {
                        building: this.building.id
                    },
                    building: this.building,
                    projectTypes: this.projectTypes,
                    users: this.users
                }
            }).then((project: Project) => {
                this.editProject(project.id);
            });
        }

        public editProject(projectId) {
            this.$state.go('.project.configuration', { projectId });
        }

        public deleteProject(projectId) {
            const project = _.find(this.projects, { id: projectId });
            const confirm = this.$mdDialog.confirm()
                .textContent('Are you sure you want to delete this project?')
                .ariaLabel('delete project')
                .ok('Delete')
                .cancel('Cancel');
            this.$mdDialog.show(confirm).then(() => {
                return this.DataStore.delete(project);
            }).then(() => {
                this.Messages.success('Successfully deleted project');
                this.refreshProjectsByCategories();
            });
        }

        public filterBy() {
            const { selected } = this.filter;
            if (selected.value === 'NONE') {
                this.filtered = this.projects;
            } else if (selected.value === 'OWNED_BY_ME') {
                this.filtered = this.projects.filter((project) => {
                    return project.owner === this.UserService.currentUser.id.toString();
                });
            } else {
                this.filtered = this.projects.filter((project) => {
                    return project.type === selected.value;
                });
            }
            this.refreshProjectsByCategories();
        }

        public searchBy(query: string): void {
            if (query) {
                this.filter.selected = { label: 'None', value: 'NONE' };
                this.filtered = this.projects
                    .filter((project) => {
                        const searchThrough: string[] = [project.name];
                        return this.foundIn(searchThrough, query);
                    });
            } else {
                this.filtered = this.projects;
            }
            this.refreshProjectsByCategories();
        };

        // -----------------------

        private refreshProjectsByCategories() {
            Object.keys(this.projectStatuses).forEach((category) => {
                this.projectsByCategory[category] = this.filtered.filter(project => {
                    return project.status === category;
                });
            });
        }

        private foundIn(searchInArray: string[], searchFor: string): boolean {
            return searchInArray.some((searchIn) => {
                if (searchIn && _.includes(searchIn.toUpperCase(), searchFor.toUpperCase())) {
                    return true;
                }
            });
        }
    }

    angular
        .module('projectCenter')
        .controller('ProjectOverviewCtrl', ProjectOverviewCtrl);
}
