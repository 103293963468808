namespace aq.dashboard.widgets {
    export class DashboardWeatherCtrl {
        private building: aq.common.models.Building;
        private measurementSystem: string;
        /* @ngInject */
        constructor(
            private $scope,
            private forecast: any,
            private config,
            private buildings,
            private user,
            private ModelUtilService: aq.services.ModelUtilService,
            private $translate
        ) {
            this.measurementSystem = user.measurementSystem
                || (user.currentProfile && user.currentProfile.account && user.currentProfile.account.measurementSystem)
                || 'US_CUSTOMARY_UNITS';

            this.$scope.params = {
                forecast
            }

            this.$scope.config = config;

            this.building = _.find(buildings, { 'id': parseInt(config.buildingId, 10) }) || buildings[0];
            this.$scope.config.buildingId = this.building.id;
            this.$scope.config.options = {
                buildings: ModelUtilService.pareProperties(buildings),
                previousDays: _.range(15)
            };
            this.$scope.config.layout = this.$scope.config.layout || 'column';

            this.$scope.config.actions = {
                onBuildingChange: (cfg, buildingId) => {
                    cfg.buildingId = buildingId;
                }
            };
        }

        // Checks if given timestamp is within range of any of our alerts, inclusive
        // Alerts array: https://darksky.net/dev/docs/response#alerts
        public between(timestamp: number, alerts: Array<any>): boolean {
            return _.some(alerts, function (alert: any) {
                if (timestamp >= alert.time && timestamp <= alert.expires) {
                    return true;
                }
                return false;
            });
        }

        public isToday(timestamp: number): boolean {
            const today = moment();
            // Need to convert from sec to ms
            const date = moment(timestamp * 1000);
            return today.isSame(date, 'd');
        }

        public getFormat(format) {
            return this.$translate.instant('dates.' + format);
        }

    }

    angular.module('aq.dashboard.widgets').controller('DashboardWeatherCtrl', DashboardWeatherCtrl);
}
