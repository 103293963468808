namespace aq.admin.configuration.utilityCompany {
    export class UtilityCompanyNewCtrl {
        private query;
        private selectedProvider;

        /* @ngInject */
        constructor(
            private $scope: UtilityCompanyNewCtrlScope,
            private $state: ng.ui.IStateService,
            private Messages,
            private newUtilityCompany: UtilityCompany,
            private RestangularV3: restangular.IService
        ) {
            $scope.newUtilityCompany = newUtilityCompany;
            this.selectedProvider = null;
        }

        save() {
            return this.RestangularV3.all('utility-companies').post(this.$scope.newUtilityCompany).then((utilityCompany) => {
                this.Messages.success('Utility Company "' + utilityCompany.name + '" Created');
                this.$state.go('aq.admin.configuration.utilityCompany.details', { utilityCompanyId: utilityCompany.id });
                this.$scope.$emit('UtilityCompanySaved', utilityCompany);
            }, (error) => {
                this.Messages.error('Error saving utility company.');
                console.error(error);
            });
        }

        public setQuery(query: string) {
            this.query = query;
        }

        public getProviderText(provider: UrjanetProvider) {
            if (provider) {
                return `${provider.providerName} (${provider.providerAlias})`;
            }
            return '';
        }

        public searchProviders(search: string) {
            return this.RestangularV3.all('internal')
                .all('urjanet-providers')
                .getList({ search });
        }

        public selectUrjanetProvider(provider: UrjanetProvider) {
            if (provider) {
                this.$scope.newUtilityCompany.urjanetProviderId = provider.id;
                this.$scope.newUtilityCompany.urjanetProviderName = provider.providerName;
                provider = this.RestangularV3.stripRestangular(provider);
                delete (provider as any).originalElement;
                this.$scope.newUtilityCompany.urjanetProviderDetails = provider;
            } else if (this.query === '') {
                this.$scope.newUtilityCompany.urjanetProviderId = null;
                this.$scope.newUtilityCompany.urjanetProviderName = null;
                this.$scope.newUtilityCompany.urjanetProviderDetails = null;
            }
        }

        public isValidProvider() {
            if (this.$scope.newUtilityCompany.urjanetProviderDetails) {
                return this.query === this.getProviderText(this.$scope.newUtilityCompany.urjanetProviderDetails);
            } else {
                return !this.query;
            }
        }
    }

    interface UtilityCompanyNewCtrlScope extends UtilityCompanyListCtrlScope {
        newUtilityCompany: UtilityCompany;
    }
}
angular
    .module('utilityCompany')
    .controller('UtilityCompanyNewCtrl', aq.admin.configuration.utilityCompany.UtilityCompanyNewCtrl);
