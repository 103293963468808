var aq;
(function (aq) {
    var reports;
    (function (reports) {
        var MonthlyBuildingCtrl = /** @class */ (function (_super) {
            __extends(MonthlyBuildingCtrl, _super);
            /* ngInject */
            function MonthlyBuildingCtrl(currentDate, account, building, energyStarScore, functionalities, OptionsService, availableMetrics, meters, $timeout, $q, ReportApiParams, DataService, MonthlyOccupancyData, MonthlyCharts, $filter) {
                var _this = _super.call(this, currentDate, account, building, energyStarScore, functionalities, OptionsService, availableMetrics, meters, $timeout) || this;
                _this.currentDate = currentDate;
                _this.account = account;
                _this.building = building;
                _this.energyStarScore = energyStarScore;
                _this.functionalities = functionalities;
                _this.OptionsService = OptionsService;
                _this.availableMetrics = availableMetrics;
                _this.meters = meters;
                _this.$timeout = $timeout;
                _this.$q = $q;
                _this.ReportApiParams = ReportApiParams;
                _this.DataService = DataService;
                _this.MonthlyOccupancyData = MonthlyOccupancyData;
                _this.MonthlyCharts = MonthlyCharts;
                _this.$filter = $filter;
                _this.time = angular.copy(_this.buildingReport.building.currentTime);
                _this.time.subtract(1, 'month');
                var currentMonth = angular.copy(_this.time);
                _this.monthIndex = parseInt(currentMonth.format('M')) - 1;
                _this.occupancyData = _this.MonthlyOccupancyData.getMonthlyOccupancy(building, _this.monthIndex);
                // TODO for now we must query temperature separately because /data endpoint doesn't support temperature
                // (it is from virtual meter which is excluded)
                var temperatureParams = new _this.ReportApiParams(_this.time, null);
                var temperaturePromise = building.customGET('temperature', temperatureParams.current());
                var currentYearDegreeDaysPromise = DataService.degreeDays(building, temperatureParams.currentDegreeDay().interval, temperatureParams.currentDegreeDay().start, temperatureParams.currentDegreeDay().end);
                var previousYearDegreeDaysPromise = DataService.degreeDays(building, temperatureParams.previousDegreeDay().interval, temperatureParams.previousDegreeDay().start, temperatureParams.previousDegreeDay().end);
                _this.previousTime = moment(_this.time).subtract(1, 'month');
                var waterBreakdownTempPromise;
                var isWaterBreakdownTempPromiseInit = false;
                var reportPromises = [];
                _.each(_this.buildingReport.serviceReports, function (report) {
                    var config = report.config;
                    report.data = {
                        loading: true
                    };
                    var apiParams = new ReportApiParams(_this.time, config.measures);
                    var currentYearPromise = building.customGET('data', apiParams.current());
                    var previousYearPromise = building.customGET('data', apiParams.previous());
                    var currentYearPeaksPromise = building.customGET('peaks', apiParams.current());
                    var previousYearPeaksPromise = building.customGET('peaks', apiParams.previous());
                    var demandPromise = _this.DataService.peaks(building, _this.time, moment(_this.time).add(1, 'month'));
                    var waterBreakdownParams = new ReportApiParams(_this.time, config.measures, 'monthlyFull');
                    var waterBreakdownPromise = config.isWaterOrGasReport
                        ? building.customGET('data', waterBreakdownParams.current())
                        : _this.$q.when(null);
                    if (config.isWaterOrGasReport) {
                        if (!isWaterBreakdownTempPromiseInit) {
                            waterBreakdownTempPromise = building.customGET('temperature', waterBreakdownParams.current());
                            isWaterBreakdownTempPromiseInit = true;
                        }
                    }
                    else if (!isWaterBreakdownTempPromiseInit) {
                        waterBreakdownTempPromise = _this.$q.when(null);
                    }
                    var reportDefer = _this.$q.defer();
                    _this.$q.all([
                        currentYearPromise,
                        previousYearPromise,
                        waterBreakdownPromise,
                        temperaturePromise,
                        currentYearDegreeDaysPromise,
                        previousYearDegreeDaysPromise,
                        demandPromise,
                        waterBreakdownTempPromise,
                        currentYearPeaksPromise,
                        previousYearPeaksPromise
                    ]).then(function (result) {
                        var currentValues = result[0];
                        var demandValues = result[6];
                        var previousValues = result[1];
                        var waterBreakdownValues = result[2];
                        var temperatureValues = result[7] ? result[7].values : null;
                        currentValues['temperature'] = result[3] ? result[3].values : null;
                        currentValues['degreeDays'] = result[4];
                        previousValues['degreeDays'] = result[5];
                        /***
                         * Have to use the monhtly peaks from the 'findPeaks' endpoint as the
                         * demand values from the data endpoing are average demand, not peak
                         */
                        if (currentValues.power && previousValues.power) {
                            currentValues.power.values = result[8] ? result[8].values : null;
                            previousValues.power.values = result[9] ? result[9].values : null;
                        }
                        var data = report.data;
                        data.usage = currentValues[config.usageMetric].values[_this.monthIndex];
                        /***
                         * Get current month from last year as previous usage, if it doesn't
                         * exist then take previous month from this year
                         */
                        data.previousUsage = previousValues[config.usageMetric].values[_this.monthIndex] ?
                            previousValues[config.usageMetric].values[_this.monthIndex] :
                            currentValues[config.usageMetric].values[_this.monthIndex - 1];
                        /***
                         * Get current month from last year as previous demand, if it doesn't
                         * exist then take previous month from this year
                         */
                        data.previousDemand = demandValues.previous.value;
                        data.currentHDD = currentValues.degreeDays.hdd.values[_this.monthIndex];
                        data.currentCDD = currentValues.degreeDays.cdd.values[_this.monthIndex];
                        /***
                         * Get current month from last year as previous HDD/CDD, if it doesn't
                         * exist then take previous month from this year
                         */
                        data.previousHDD = previousValues.degreeDays.hdd.values[_this.monthIndex] ?
                            previousValues.degreeDays.hdd.values[_this.monthIndex] :
                            currentValues.degreeDays.hdd.values[_this.monthIndex - 1];
                        data.previousCDD = previousValues.degreeDays.cdd.values[_this.monthIndex] ?
                            previousValues.degreeDays.cdd.values[_this.monthIndex] :
                            currentValues.degreeDays.cdd.values[_this.monthIndex - 1];
                        var currentTemperature = _this.$filter('arrayToTemperatureUnit')(currentValues.temperature, config.temperatureUnit);
                        var currentData = {
                            data: currentValues,
                            temperature: currentTemperature,
                            year: currentValues.start
                        };
                        var previousTemperature = _this.$filter('arrayToTemperatureUnit')(previousValues.temperature, config.temperatureUnit);
                        var previousData = {
                            data: previousValues,
                            temperature: previousTemperature,
                            year: previousValues.start
                        };
                        var monthlyCharts = new _this.MonthlyCharts(currentData, previousData, report.config, currentMonth);
                        /* Usage chart data (both report types) */
                        data.usageChart = monthlyCharts.getUsageChart(config.usageMetric, config.usageUnit);
                        if (!config.isWaterOrGasReport) {
                            /* Demand chart data */
                            data.demandChart = monthlyCharts.getDemandChart();
                            data.demand = demandValues.current.value;
                            /* Weather chart data */
                            data.weatherChart = monthlyCharts.getWeatherChart();
                        }
                        if (config.isWaterOrGasReport) {
                            /* Water chart breakdown */
                            data.waterChart = monthlyCharts.getWaterBreakdownChart(waterBreakdownValues, temperatureValues, currentMonth.daysInMonth());
                            data.demand = Math.max.apply(null, waterBreakdownValues[config.demandMetric].values);
                            data.weatherChart = monthlyCharts.getWeatherChart();
                        }
                        data.loading = false;
                        reportDefer.resolve();
                    }).catch(function () {
                        reportDefer.reject();
                    });
                    reportPromises.push(reportDefer.promise);
                });
                _this.$q.all(reportPromises)
                    .then(function () {
                    _this.notifyDocumentReady();
                })
                    .catch(function () {
                    _this.isDocumentError = true;
                });
                return _this;
            }
            MonthlyBuildingCtrl.prototype.getServiceType = function (usageMetric) {
                switch (usageMetric) {
                    case 'energy':
                        return 'Electricity';
                    case 'steam_mass':
                        return 'Steam';
                    case 'heat_transfer':
                        return 'Heat';
                    case 'gas':
                        return 'Gas';
                    case 'water':
                        return 'Water';
                    default:
                        return '';
                }
            };
            return MonthlyBuildingCtrl;
        }(reports.BaseBuildingReportCtrl));
        reports.MonthlyBuildingCtrl = MonthlyBuildingCtrl;
        angular.module('aq.reports').controller('MonthlyBuildingCtrl', MonthlyBuildingCtrl);
    })(reports = aq.reports || (aq.reports = {}));
})(aq || (aq = {}));
