namespace aq.dashboard.widgets {
    import HighChartConfig = aq.highcharts.HighChartConfig;
    export class StatsCtrl {
        private isLoadingData: boolean;
        private isAnyBuildingInSetup: boolean;
        /* @ngInject */
        constructor(private $scope,
            private account: aq.common.models.Account,
            private buildings: Array<aq.common.models.Building>,
            private tenants: Array<aq.common.models.Tenant>,
            private config: StatsConfig,
            private GraphData: aq.dashboard.widgets.GraphData,
            private DashboardOptionsService: aq.dashboard.DashboardOptionsService,
            private Auth: aq.services.Auth,
            private meter,
            private $filter: ng.IFilterService,
            private uses,
            private source,
            private space,
            private tenant,
            private ModelUtilService: aq.services.ModelUtilService,
            private StatsEditService: StatsEditService,
            private buildingGroups,
            private $translate,
            private WidgetHelperService
        ) {
            this.isLoadingData = true;

            if (_.isEmpty(config)) {
                config.preset = TimePresets.defaultPreset();
                config.series = [{}, {}];
                config.statistic = { display: 'total', value: 'total' };
                config.comparison = true;
                config.series[0].measure = 'KWH';
                config.series[1].measure = 'KWH';
                config.series[1].trend = true;
                config.series[1].trendPeriod = 'previous week';
                config.size = 'large';
                config.title = textural(config.preset + ' ' + config.series[0].measure).format('capitalize');
                config.calculationValue = { display: `${this.$translate.instant('dashboard.common.widgets.stats.No Calculation')}`, value: 0 };
                config.buildingIds = [];
            }

            this.isAnyBuildingInSetup = _.some(config.buildingIds, (id) => {
                const building = _.find(this.buildings, (b) => b.id == id);
                if (!building) {
                    return false;
                }
                return this.WidgetHelperService.isBuildingInSetup(building);
            });

            if (!config.breakdown) {
                config.breakdown = config.series[0].drillin ? config.series[0].drillin : 'building';
            }
            if (config.breakdown == 'building' && !config.buildingSelectionMode) {
                config.buildingSelectionMode = 'individual';
            }
            const series = {
                'building': [],
                'meter': meter,
                'uses': uses,
                'source': source,
                'space': space
            };

            if (Auth.check({ appName: 'Tenant Billing' })) {
                series['tenant'] = tenant;
            }

            const options = {
                graphTypes: GraphTypes.getGraphTypes(),
                timePresets: TimePresets.getPresets(),
                breakdowns: _.map(series, (obj, key) => key),
                series,
                trendPeriods: TrendPeriods.getTrends(),
                statistics: [{ display: 'total', value: 'total' }, { display: 'average', value: 'avg' }, { display: 'maximum', value: 'max' }],
                sizes: ['small', 'medium', 'large'],
                calculations: [],
                buildings: ModelUtilService.pareProperties(buildings, ['buildingGroup', 'size', 'occupantsCount', 'account']),
                buildingGroups: ModelUtilService.pareProperties(this.buildingGroups),
                drilldownOptions: []
            };

            config.actions = this.StatsEditService;
            config.options = options;
            config.view = {
                buildingId: null,
                currentNavItem: 'basic',
                showTitle: !config.hideTitle
            };

            this.StatsEditService.initDefaultBuildingSelection(config);

            if (config.buildingIds.length > 0 && config.breakdown != 'building') {
                config.view.buildingId = config.buildingIds[0];
            }

            config.actions.buildCalculationOptions(config);

            this.$scope.config = config;

            const firstSeries = this.$scope.config.series[0];
            if (!config.drilldown && (firstSeries.drilldownId && config.breakdown != 'building')) {
                config.drilldown = this.findDrilldowntemInSelectedBuildings(config.breakdown, firstSeries.drilldownId);
            }

            this.enableBackwardsCompatibility(config);

            this.updateBuildingPrefix();
            this.resetStat();

            config.actions.onIsCustomTitleChange(config);

            this.$scope.$on('abortLoading', () => {
                this.GraphData.abort();
            });

            this.DashboardOptionsService.init(account.id, account.measurementSystem, account.currencyUnit).then(() => {
                config.options.drilldownOptions = config.actions.getDrilldownOptions(config);
                this.setMeasures();
            });

        }

        public formatValue(value) {
            let decimalPlaces = 0;
            if (this.$scope.config.calculationValue.value > 0
                && value < 10) {
                decimalPlaces = 4;
            }
            return this.$filter<Function>('numForm')(value, decimalPlaces);
        }

        public getExcludedBuildingsInfo() {
            const excludedBuildings = _.filter(this.buildings, (building) => {
                return this.WidgetHelperService.isBuildingInSetup(building);
            });
            const buildingNames = _.map(excludedBuildings, (b) => b.name);
            return `* Excluding the data for non-commissioned buildings: ${buildingNames.join(', ')}`;
        }

        private enableBackwardsCompatibility(cfg: StatsConfig) {
            if (cfg.breakdown && cfg.breakdown != 'building' && cfg.drilldown) {
                cfg.series[0].drillin = cfg.breakdown;
                cfg.series[0].drilldown = cfg.drilldown;
            }
            if (cfg.breakdown == 'building' && (cfg.series[0].drillin || cfg.series[0].drilldown)) {
                cfg.series[0].drillin = null;
                cfg.series[0].drilldown = null;
            }
        }

        private findDrilldowntemInSelectedBuildings(breakdown, drilldownId) {
            let drilldown = null;
            _.each(this.$scope.config.buildingIds, (buildingId) => {
                if (!drilldown) {
                    drilldown = _.find(this.$scope.config.options.series[breakdown][buildingId], { id: parseInt(drilldownId) });
                }
            });
            return drilldown;
        }

        private setMeasures() {
            const allNonConvertedUnits = this.DashboardOptionsService.getUnits();
            const nonBuildingUnits = _.filter(allNonConvertedUnits, (unit) => {
                return !unit.isIntensity;
            });
            this.$scope.config.options.measures = this.DashboardOptionsService.organizeUnitsByServiceType(nonBuildingUnits);
        }

        private updateBuildingPrefix() {
            const buildingId = this.$scope.config.buildingIds[0];
            this.$scope.buildingPrefix =
                [{
                    id: _.find(this.buildings, { id: parseInt(buildingId) })['account'],
                    route: 'accounts'
                },
                {
                    id: this.$scope.config.buildingIds[0],
                    route: 'buildings'
                }];
        }

        private configureQueryable() {
            this.calculateQueryable(this.$scope.config.series[0]);
        };

        private calculateQueryable(series) {
            if (this.$scope.config.drilldown) {
                let queryCursor = this.$scope.config.drilldown;
                const queryable = [];
                let parentRoute = queryCursor.route;
                series.drilldownId = queryCursor.id;

                // push selected queryable onto chain
                queryable.push({
                    id: queryCursor.id,
                    route: queryCursor.route
                });

                while (queryCursor.parent != null) {
                    // only push a queryable onto the chain if its route is different from its parent
                    if (queryCursor.route != parentRoute) {
                        queryable.push({
                            id: queryCursor.id,
                            route: queryCursor.route
                        });
                    }

                    parentRoute = queryCursor.route;
                    queryCursor = queryCursor.parent;
                }

                _.reverse(queryable);
                series.queryable = _.concat(this.$scope.buildingPrefix, queryable);
            } else {
                series.queryable = null;
                series.drilldownId = null;
            }
        }

        private resetStat() {
            const periodDetails: DatePeriod.DatePeriod = TimePresets.getPresetDetails(this.$scope.config.preset);
            const previousDetails: DatePeriod.Duration = TrendPeriods.getTrendPeriod(this.$scope.config.trendPeriod);
            this.$scope.config.interval = periodDetails.interval;
            this.$scope.config.series[0].end = periodDetails.end;
            this.$scope.config.series[0].duration = periodDetails.duration;
            this.$scope.config.unit = this.DashboardOptionsService.getUnitByEnumName(this.$scope.config.series[0].measure);
            this.$scope.config.displayUnit = this.$scope.config.unit.unit;
            this.configureQueryable();

            if (this.$scope.config.comparison) {
                this.$scope.config.series[1].end = periodDetails.end;
                this.$scope.config.series[1].duration = periodDetails.duration;
                this.$scope.config.series[1].measure = this.$scope.config.series[0].measure;
                this.$scope.config.series[1].drilldownId = this.$scope.config.series[0].drilldownId;
                if (this.$scope.config.series[0].drillin) {
                    this.$scope.config.series[1].drillin = this.$scope.config.series[0].drillin;
                }
                this.$scope.config.series[1].queryable = this.$scope.config.series[0].queryable;
            }
            this.configureStat(periodDetails, previousDetails);
        };

        private calculateValue() {
            this.$scope.config.series.forEach((series) => {
                const statValue = this.$scope.config.statistic.value;
                const calcValue = this.$scope.config.calculationValue.value;
                if (calcValue && calcValue > 0) {
                    series.data[statValue] = series.data[statValue] / calcValue;
                    this.$scope.config.displayUnit = this.$scope.config.unit.unit + ' / ' + this.$scope.config.calculationValue.display;
                }

            });
        }

        private configureStat(periodDetails, previousDetails) {
            const currentBuildings = [];
            this.$scope.config.buildingIds.forEach((id) => {
                const building = _.find(this.buildings, { 'id': parseInt(id) });
                if (!this.WidgetHelperService.isBuildingInSetup(building)) {
                    currentBuildings.push(building);
                }
            });

            if (currentBuildings.length == 0) {
                this.isLoadingData = false;
            } else {
                this.GraphData.getMultipleBuildingSeriesData(this.$scope.config, { account: this.account, buildings: currentBuildings, tenants: this.tenants })
                    .then((data) => {
                        this.combineBuildingData(data);
                        this.calculatePeriodText();
                        if (this.$scope.config.calculationValue) {
                            this.calculateValue();
                        }
                        this.convertValues();
                    })
                    .finally(() => {
                        this.isLoadingData = false;
                    });
            }
        };

        private convertValues() {
            this.$scope.config.series.forEach((series) => {
                const statValue = this.$scope.config.statistic.value;
                series.data[statValue] = this.$filter<Function>('toUnit')(series.data[statValue], this.$scope.config.unit);
            });
        }

        private combineBuildingData(data) {
            // get unique start and end dates
            const endDates = _.uniq(_.map(data, 'end'));
            const periodEnd: any = _.max(endDates);
            const trendEnd: any = _.min(endDates);

            const trendStart = _.find(data, { end: trendEnd })['start'];
            const periodStart = _.find(data, { end: periodEnd })['start'];

            const periodMax = [];
            const periodAvg = [];
            const periodTotal = [];
            const trendMax = [];
            const trendAvg = [];
            const trendTotal = [];
            // sum, avg, and max by start and end date
            data.forEach((item) => {
                if (!item.trend) {
                    periodMax.push(item.values.max);
                    periodAvg.push(item.values.avg);
                    periodTotal.push(item.values.total);
                } else {
                    trendMax.push(item.values.max);
                    trendAvg.push(item.values.avg);
                    trendTotal.push(item.values.total);
                }
            });

            this.$scope.config.series[0].data = this.combineStatistics(periodMax, periodAvg, periodTotal);
            this.$scope.config.series[0].start = periodStart;
            this.$scope.config.series[0].end = periodEnd;

            this.$scope.config.series[1].data = this.combineStatistics(trendMax, trendAvg, trendTotal);
            this.$scope.config.series[1].start = trendStart;
            this.$scope.config.series[1].end = trendEnd;
        }

        private combineStatistics(maxArr, avgArr, totalArr) {
            return {
                max: _.max(maxArr),
                total: _.sum(totalArr),
                avg: _.sum(avgArr) / avgArr.length
            };
        }

        private calculatePeriodText() {
            const highChartConfig = new HighChartConfig(this.$translate);
            let startDisplayDate;
            let endDisplayDate;
            this.$scope.momentDateFormat = highChartConfig.getMomentDateFormatByInterval(this.$scope.config.interval);

            startDisplayDate = moment(this.$scope.config.series[0].start).format(this.$scope.momentDateFormat);
            endDisplayDate = moment(this.$scope.config.series[0].end).format(this.$scope.momentDateFormat);

            this.$scope.config.periodText = `${startDisplayDate} - ${endDisplayDate}`;
        };

    }

    export class StatsConfig implements aq.services.BuildingSelectConfig {
        buildingGroupId: number;
        buildingIds: number[];
        buildingSelectionMode: string;
        statistic: {
            display: string,
            value: string
        };
        preset: string;
        comparison: boolean;
        size: string;
        title: string;
        calculationValue: {
            display: string,
            value: number
        };
        series: any[];
        options: {
            series: any;
            breakdowns: string[];
            buildings: any[];
            drilldownOptions: any[];
            buildingGroups: any[];
            calculations: any[];
        };
        buildings: any[];
        buildingLevel: boolean;
        actions: StatsEditService;
        isCustomTitle: boolean;
        hideTitle: boolean; // not using 'showTitle' because of backwards compatility
        view: {
            buildingId: number;
            currentNavItem: string;
            showTitle: boolean;
        };
        breakdown: string;
        drilldown: any;
        unit: any;
    }

    export class DataQueryable {
        id: number;
        route: string;
    }

    angular.module('aq.dashboard.widgets').controller('StatsCtrl', StatsCtrl);
}
