namespace aq {
    import HelpActions = aq.models.segment.HelpActions

    interface DrawerGroup {
        name: string;
        itemNames: string[];
        items: aq.common.models.App[];
    }

    export class Aquicore {
        public buildingsAside;
        public isBuildingSelectorVisible: boolean;
        public buildingSelectorVisibleStates: string[];
        private accountFilter = '';
        private filteredAccounts;
        private propertySettingsAccess: common.models.AppAccessObject;
        private accountSettingsAccess: common.models.AppAccessObject;
        private filteredApps: aq.common.models.App[];
        private drawerGroupApps: DrawerGroup[];
        private adminApps: aq.common.models.App[];
        private isAdmin: boolean;

        /* @ngInject */
        constructor(
            private $filter,
            private $location,
            private $mdBottomSheet,
            private $mdDialog,
            private $mdMedia,
            private $mdSidenav,
            private $rootScope,
            private $scope,
            private $state: ng.ui.IStateService,
            private $translate,
            private $window: ng.IWindowService,
            private Auth: aq.services.Auth,
            private Errors,
            private FullScreenService,
            private loading,
            private mainAppInitialized,
            private allAppsLoaded,
            private Messages: aq.services.Messages,
            private OptionsService,
            private Restangular: restangular.IService,
            private SnapshotService,
            private UserService,
            private RestangularV3: restangular.IService,
            private $cacheFactory: ng.ICacheFactoryService,
            private $localStorage,
            private $stateParams: ng.ui.IStateParamsService,
            private RedirectService: aq.services.RedirectService,
            private Segment: aq.services.SegmentService
        ) {
            this.$window.addEventListener('message', (e) => {
                if (e.origin !== this.RedirectService.getBaseURL()) {
                    return;
                }
                if (!e || !e.data || !e.data.type) {
                    return;
                }
                switch (e.data.type) {
                    case 'external-navigation':
                        const { state, params } = e.data;
                        if (state) {
                            $state.go(state, params || {});
                        }
                        break;
                    case 'begin-impersonating':
                        const { impersonateToken } = e.data;
                        this.$localStorage['onBehalfOfUserToken'] = impersonateToken;
                        this.setImpersonateToken(impersonateToken);
                        break;
                    case 'end-impersonating':
                        delete this.$localStorage['onBehalfOfUserToken'];
                        this.setImpersonateToken(null);
                        break;
                    default:
                        break;;
                }
            });

            this.propertySettingsAccess = this.Auth.access['Property Settings'];
            this.accountSettingsAccess = this.Auth.access['Account Settings'];
            $scope.isNewUser = false;
            $scope.$mdMedia = $mdMedia;


            const logoSuffix = _.includes(['my', 'data', 'partners'], $location.host().split('.')[0])
                ? '.svg'
                : '-stage.svg';
            $scope.homeLogo = `/img/brand/logo-black${logoSuffix}`;
            $scope.appLogo = `/img/brand/logo-mobile${logoSuffix}`;
            $scope.isSnapshot = SnapshotService.isSnapshotRequest;
            $scope.onBehalfOfUserToken = this.$localStorage.onBehalfOfUserToken;
            if ($scope.onBehalfOfUserToken) {
                this.Restangular.setDefaultHeaders({ 'on-behalf-of-token': $scope.onBehalfOfUserToken });
                this.RestangularV3.setDefaultHeaders({ 'on-behalf-of-token': $scope.onBehalfOfUserToken });
                $cacheFactory.get('http').removeAll();
            }
            if (this.shouldLoadUser()) {
                this.loadUser();
            }

            $scope.$on('ACCOUNT_CREATED', (event, data) => {
                this.switchProfile(data);
            });

            this.buildingSelectorVisibleStates = [
                'aq.energyInsights',
                'aq.tenantbilling',
                'aq.properties',
                'aq.projectCenter',
                'aq.deviceManagement',
                'aq.utilityBudgets',
                'aq.projects',
                'aq.networkingDevices',
                'aq.pointTrending',
                'aq.centralPlant'
            ];
            this.setBuildingSelectorVisibility(this.$state.current.name);

            this.$scope.$on('$stateChangeSuccess', (event, toState) => {
                // if we go from the page where user was not loaded we need to load user
                if (this.shouldLoadUser()) {
                    if (!$scope.user) {
                        this.loadUser();
                    } else {
                        this.detectApp();
                    }
                }
                this.setBuildingSelectorVisibility(toState.name);
            });

            this.$scope.$on('PROFILE_DATA_UPDATE', (event, data) => {
                if (data) {
                    this.$scope.user.image = data.image;
                    this.$scope.user.title = data.title;
                }
            });

            this.$scope.$on('NAVIGATE_ACROSS_ACCOUNTS', (event, data) => {
                if (data) {
                    const { accountId } = data;
                    const stateToNavigate = data.state;
                    const { stateParams } = data;
                    const targetProfile = _.find(this.$scope.user.profiles, (profile) => profile.account == accountId);
                    const isCurrentProfile = this.$scope.user.currentProfile.id == targetProfile.id;
                    if (!targetProfile) {
                        this.Messages.error('Destination Account is not accessible to the current User');
                        return;
                    }

                    if (isCurrentProfile) {
                        this.$state.transitionTo(stateToNavigate, stateParams);
                    } else {
                        this.switchProfile(targetProfile, stateToNavigate, stateParams);
                    }
                }
            });

            $scope.selectDefaultBuilding = () => {
                return $scope.buildingsPromise
                    .then(() => {
                        const buildingId = this.defaultBuildingId();
                        if (buildingId) {
                            $scope.selectBuilding(_.findById(this.$scope.buildings, buildingId));
                            return this.$scope.building;
                        }
                    });
            };

            $scope.selectDefaultBuildingWithRedirect = (redirectFrom, redirectTo) => {
                $scope.selectDefaultBuilding()
                    .then(() => {
                        if (this.$state.is(redirectFrom)) {
                            this.$state.go(redirectTo, {
                                accountId: this.$scope.account.id,
                                buildingId: this.$scope.building.id
                            });
                        }
                    });
            };

            $scope.localSelectBuilding = (building) => {
                $scope.selectBuilding(building);
                const { id } = building;
                if (this.$state.is('aq.buildingChooser')) {
                    this.$state.transitionTo(this.$state.params.originState, { accountId: this.$scope.account.id, buildingId: id });
                } else {
                    const state = this.$state.current.data.redirectState || this.$state.current.name;
                    this.hide();
                    this.$state.transitionTo(state, { accountId: this.$state.params.accountId, buildingId: id });
                }
            };

            $scope.selectBuilding = (building, record?) => {
                // states that has building as initial parent object; like
                // properties, tenant billing, notification center
                const statesToSet = ['aq.properties'];

                if (building) {
                    this.$scope.building = this.Restangular.restangularizeElement($scope.account, building, 'buildings');
                    _.each(statesToSet, (state) => {
                        this.$state.get(state).data.breadcrumb = building.name;
                    });
                }

                if (this.$scope.building && this.$scope.building.id) {
                    // move the building on top of the list
                    this.$scope.buildings = _.without(this.$scope.buildings, building);
                    this.$scope.buildings.unshift(building);
                    // update access on backend
                    if (record) {
                        this.$scope.building.customPOST({}, 'record').get();
                    }
                }
            };

            $scope.hide = () => {
                this.hide();
            };

            this.Segment.initializeSegment();
        }

        public setImpersonateToken(token: string) {
            this.Restangular.setDefaultHeaders({ 'on-behalf-of-token': token });
            this.RestangularV3.setDefaultHeaders({ 'on-behalf-of-token': token });
            this.$cacheFactory.get('http').removeAll();
            this.$scope.user = null;
            this.UserService.currentUser = null;
        }

        public formatAppIcon(app) {
            app.icon = `/frontend/svg/${app.type.replace(/ /g, '-').toLowerCase()}/${app.name.replace(/ /g, '-').toLowerCase()}.svg`;
        }

        public setBuildingSelectorVisibility(stateName) {
            this.isBuildingSelectorVisible = _.some(this.buildingSelectorVisibleStates, (state) => stateName.startsWith(state));
        }

        handleMenu($mdMenuIsOpen, $mdMenu, event) {
            $mdMenuIsOpen ? $mdMenu.close(event) : $mdMenu.open(event);
        }

        selectAccountFromInput(event, $mdMenu) {
            if (event.keyCode === 13) {
                this.switchProfile(this.filteredAccounts[0]);
                this.clearAccountFilter();
                $mdMenu.close(event);
            }
        }

        filterApps(element) {
            return !element.name.match(/Settings/);
        }

        clearAccountFilter() {
            this.accountFilter = '';
            this.filteredAccounts = _.sortBy(this.$scope.user.profiles, (a: aq.common.models.Account) => a.accountName);
        }

        filterAccount() {
            this.filteredAccounts = this.$filter('filter')(this.$scope.user.profiles, { accountName: `${this.accountFilter}` });
            this.filteredAccounts = _.sortBy(this.filteredAccounts, (a: aq.common.models.Account) => a.accountName);
        }

        shouldLoadUser() {
            return !this.$state.includes('aq.registration')
                && !this.$state.includes('aq.user.recover')
                && !this.$state.includes('aq.user.reset')
                && !this.$state.includes('aq.user.invalidToken');
        }

        openHelp() {
            let buildingIds = this.$scope.account.buildings;
            if (!this.$scope.user.currentProfile.hasFullVisibility) {
                buildingIds = this.$scope.user.currentProfile.visibilities;
            }
            this.Segment.trackChurnZeroEvent(HelpActions.HELP_OPEN, buildingIds);
            this.$mdDialog.show({
                controller: 'MainHelpCtrl',
                controllerAs: 'vm',
                templateUrl: 'app/main/help/mainHelp.html',
                clickOutsideToClose: false,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm')),
                locals: this.$scope
            });
        }

        loadUser() {
            this.loading.start();
            this.UserService
                .reloadUser({ inflate: 'currentProfile,currentProfile.account,currentProfile.apps,buildingGroups' })
                .then((user) => {
                    if (!user.currentProfile) {
                        this.$scope.isNewUser = true;
                        this.loading.stop();
                        this.mainAppInitialized.resolve();
                        return;
                    }
                    this.$scope.user = user;
                    this.$rootScope.accountName = user.currentProfile.account.accountName;
                    this.selectProfile(user.currentProfile);
                    this.loading.stop();


                    /** *
                     * We loading profiles and profiles account objects immediately on start because it takes some
                     * time to load that and later we will extend current $scope.user object with profiles objects.
                     * Since user object is loaded very fast, app will be loaded as well - later user can go and change
                     * account since in the meantime profiles objects were loaded
                     */
                    this.UserService
                        .getProfiles()
                        .then((userWithProfiles) => {
                            _.extend(this.$scope.user.profiles, userWithProfiles.profiles);
                            this.filteredAccounts = _.sortBy(this.$scope.user.profiles, (a: aq.common.models.Account) => a.accountName);
                        });
                }, () => {
                    this.loading.stop();
                    this.Messages.error('Cannot load user!');
                });
        }

        showDialog(options) {
            return this.$mdDialog.show(options).then((resp) => {
                return resp;
            });
        }

        switchProfile(profile, stateToNavigate = 'aq.home', stateParams = {}) {
            if (!stateToNavigate) {
                return;
            }
            this.loading.start();
            return this.$scope.user
                .one('selectProfile', profile.id)
                .put({ inflate: 'currentProfile' })
                .then((user) => {
                    if (user.currentProfile) {
                        this.Messages.info('Switched account', undefined, user.currentProfile.accountName);
                        // force root state reload by incrementing switchAccountTrigger parameter
                        // (in order for controller logic to wait for Auth data to be resolved before continuing)
                        const switchParam = this.$stateParams.switchAccountTrigger || 0;
                        angular.extend(stateParams, { switchAccountTrigger: switchParam + 1 });
                        this.Segment.group(user.currentProfile.account, user.currentProfile.accountName);
                        return this.$state.transitionTo(stateToNavigate, stateParams);
                    }
                }, this.Errors.forPromise())
                .finally(() => {
                    this.loading.stop();
                });
        }

        accountInactiveError() {
            if (!(this.$scope.user.userType === 'ADMINISTRATOR')) {
                this.$mdDialog.show(
                    this.$mdDialog.alert()
                        .parent(angular.element(document.querySelector('body')))
                        .clickOutsideToClose(false)
                        .title('Account not active')
                        .textContent('Contact Aquicore to make this account active.')
                        .ariaLabel('Account unavailable')
                        .ok('Got it!')
                )
                    .then(() => {
                        this.logout();
                    });
            }
        }

        selectProfile(profile) {
            this.$scope.user.currentProfile = profile;
            this.Auth.init(this.$scope.user.currentProfile.apps);
            const accountId = this.$state.params.accountId ? this.$state.params.accountId : this.$scope.user.currentProfile.account.id;
            this.detectApp();
            this.$scope.appStates = this.getAppStates('LICENSED');
            this.$scope.controlAppStates = this.getAppStates('REQUIRED');
            this.$scope.allApps = this.$scope.appStates.concat(this.$scope.controlAppStates).filter((app) => {
                if (app.name.match(/Projects/igm)) {
                    return this.Auth.hasFunctionality('Aquicore 3.0 Projects');
                }
                if (app.name.match(/Networking/igm)) {
                    return this.UserService.isAdmin();
                }
                return true;
            });
            this.$scope.displayHomeApps = this.getUpdatedAppTitles();
            this.$scope.buildingsPromise = this.loadBuildings();
            this.$scope.adminAppStates = this.getAppStates('ADMIN');
            this.$scope.accountId = accountId;
            this.$scope.setBuildingState = (buildingId): void => {
                const stateParams: any = this.$state.params ? this.$state.params : {};
                const isTransitionRequired = !stateParams.buildingId;
                _.each(this.$scope.user.currentProfile.apps, (a) => {
                    a.stateData.buildingId = buildingId;
                });
                if (isTransitionRequired) {
                    this.$state.go(this.$state.current.name, { buildingId });
                }
            };
            _.each(this.$scope.user.currentProfile.apps, (a) => {
                if (!a.stateData) {
                    a.stateData = { accountId };
                } else {
                    a.stateData.accountId = accountId;
                }
            });

            // app selector
            _.each(this.$scope.allApps, (app) => this.formatAppIcon(app));
            _.each(this.$scope.adminAppStates, (app) => this.formatAppIcon(app));
            this.filteredApps = this.$scope.allApps.filter((app) => {
                return !app.name.match(/Settings/igm)
                    && !app.name.match(/Report Center/igm)
                    && !app.name.match(/Projects/igm);
            });

            // 3.0 drawer
            this.drawerGroupApps = [
                {
                    name: '',
                    itemNames: ['Reports', 'Dashboards'],
                    items: []
                },
                {
                    name: 'Energy & Facilities',
                    itemNames: ['Project Center', 'Energy Insights', 'Optimization', 'Load Analytics', 'Central Plant', 'Point Trending', 'Equipment Analytics'],
                    items: []
                },
                {
                    name: 'Utility Bill Lifecycle',
                    itemNames: ['Budgets', 'Tenant Billing'],
                    items: []
                },
                {
                    name: 'Setup',
                    itemNames: ['Devices & Equipment', 'Networking'],
                    items: []
                }

            ];

            _.each(this.drawerGroupApps, (group: DrawerGroup) => {
                _.each(group.itemNames, (itemName: string) => {
                    const app = _.find(this.filteredApps, (app: common.models.App) => itemName.toLowerCase() == app.name.toLowerCase());
                    if (app) {
                        group.items.push(app);
                    }
                });
            });

            this.$scope.account = this.Restangular.one('accounts', accountId);
            this.$scope.account
                .get({ inflate: 'appAccess,partner,allApps,buildingGroups', single: true })
                .then((account) => {
                    // admin can get account active status here, normal user cannot
                    if (account.active === false) {
                        this.accountInactiveError();
                    }
                    _.extend(this.$scope.account, account);
                    /**
                     * Initialize option service so later we can use it with promise through fetchAll()
                     * Options service will be initialized with measurement system from user object, in case
                     * user object doesn't have selected measurement system it will take from selected account, in
                     * case of new user which doesn't have select current profile it will fallback to US_CUSTOMARY_UNITS
                     */
                    const measurementSystem = this.$scope.user.measurementSystem
                        || (this.$scope.user.currentProfile && this.$scope.user.currentProfile.account.measurementSystem)
                        || 'US_CUSTOMARY_UNITS';
                    this.OptionsService.init(accountId, measurementSystem, this.$scope.account.currencyUnit);

                    this.mainAppInitialized.resolve();
                }, (err) => {
                    if (err.status === 401) {
                        this.accountInactiveError();
                    } else {
                        this.Messages.error('We ran in to a problem, try refreshing the page');
                    }
                });
        }

        public closeAppSidenav() {
            this.$mdSidenav('app-menu-sidenav').close();
        }

        getAppStates(type) {
            return _(this.$scope.user.currentProfile.apps)
                .filter((a) => a.type == type)
                .sortBy('priority')
                .value();
        }

        updateAccount(account) {
            _.extend(this.$scope.account, account);
        }

        refreshBuilding() {
            this.$scope.buildingsPromise = this.loadBuildings();
            this.$scope.selectDefaultBuilding();
        }

        detectApp() {
            this.$rootScope.currentApp = this.$translate.instant(`apps.${this.$state.current.data.appName}`);
            const ignoreAccessCheck = _.some(['aq.admin', 'aq.sandbox', 'aq.home', 'aq.user', 'aq.buildingChooser', 'aq.notificationCenter', 'aq.reportCenter', 'reports', 'aq.acceptInvitation', 'aq.invalidInvitation'], this.$state.includes);
            if (!this.$rootScope.currentApp && !ignoreAccessCheck) {
                this.$state.go('aq.home');
                console.error(`App not found for state ${this.$state.current.name}`);
                return;
            }
            if (this.Auth.hasFunctionality('Renamed Optimization and Trending')) {
                if (this.$state.current.data.appName === 'Optimization') {
                    document.title = this.$translate.instant('apps.Load Analytics');
                } else if (this.$state.current.data.appName === 'Point Trending') {
                    document.title = this.$translate.instant('apps.Equipment Analytics');
                } else {
                    document.title = this.$translate.instant(`apps.${this.$state.current.data.appName}`);
                }
            } else {
                document.title = this.$translate.instant(`apps.${this.$state.current.data.appName}`);
            }
        }

        loadingProfiles() {
            return this.$scope.user && !_.isObject(this.$scope.user.profiles);
        }

        logout() {
            this.Auth.logout();
            this.$window.location.assign('/logout');
        }

        loadBuildings() {
            return this.RestangularV3.all('buildings?showInactive=false').getList().then((buildings) => {
                this.$scope.buildings = buildings;
                return buildings;
            });
        }

        defaultBuildingId() {
            return this.$state.params.buildingId || this.$scope.buildings[0].id;
        }

        toggleSideNav(navId) {
            this.$mdSidenav(navId).toggle();
        }

        hide() {
            this.$mdDialog.hide();
        }

        isReportCenterVisible() {
            if (!this.$scope.user || !this.$scope.user.currentProfile) {
                return false;
            }
            return (_.some(this.$scope.user.currentProfile.apps, (app) => app.name == 'Report Center'));
        }

        isEnrollment() {
            return this.$state.includes('aq.registration');
        }

        getUpdatedAppTitles() {
            const baseApps = _.filter(this.$scope.allApps, (app) => {
                return !app.name.match(/Projects/igm);
            });
            return baseApps.map(app => {
                if (app.name === 'Optimization') {
                    app.name = 'Load Analytics';
                }
                if (app.name === 'Point Trending') {
                    app.name = 'Equipment Analytics';
                }
                return app;
            });
        }
    }

    angular
        .module('aq')
        .controller('Aquicore', Aquicore);
}
