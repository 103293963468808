namespace aq.services {
    import appcues = aq.common.models.appcues;
    export class AppcuesService {
        public appcues: appcues.AppcuesApi;
        public isEnabled: boolean;
        public isInitialized: boolean;
        public apiUrl: string;
        public userId: string;
        public accountId: string;
        /* @ngInject */
        constructor(
            private $window: ng.IWindowService,
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private $location: ng.ILocationService
        ) {
            this.appcues = this.$window['Appcues'];
            if (this.appcues) {
                this.isEnabled = true;
                this.init();
            } else {
                return;
            }
        }
        public init() {
            return this.appcues.user()
                .then((data: appcues.AppcuesUserData) => {
                    this.userId = data.id;
                    this.accountId = data._appcuesId;
                    this.isInitialized = true;
                });
        }
        public getApiUrl() {
            const currentUrl = this.$location.absUrl();
            return `https://api.appcues.net/v1/accounts/${this.accountId}/users/${this.userId}/taco?url=${currentUrl}`;
        }
        public getFlows(): ng.IPromise<appcues.Flow[]> {
            if (!this.isEnabled) {
                return this.$q.when<appcues.Flow[]>(null);
            }
            if (!this.isInitialized) {
                return this.init().then(() => {
                    return this.getFlowsInternal();
                });
            } else {
                return this.getFlowsInternal();
            }
        }
        public startFlow(flow: appcues.Flow) {
            if (!this.isEnabled || !flow || !flow.id) {
                return null;
            }
            this.appcues.show(flow.id);
            return true;
        }
        private getFlowsInternal(): ng.IPromise<appcues.Flow[]> {
            const url = this.getApiUrl();
            return this.$http.get(url)
                .then((response: { data: appcues.FlowResponse }) => {
                    return _.map(response.data.contents, (item: appcues.FlowContent) => {
                        const flow: appcues.Flow = {
                            id: item.id,
                            name: item.name
                        };
                        return flow;
                    });
                })
                .catch((error) => {
                    return [];
                });
        }
    }
    angular.module('aq.services').service('AppcuesService', AppcuesService);
}
