namespace aq.ui {
    export class breadcrumbCtrl {
        private currentApp;
        public navigationState;

        /* ngInject */
        constructor() { }

        public emptyOrNull(item): boolean {
            return (!!item.data.appName || !!item.data.breadcrumb);
        }
    }

    angular
        .module('aq.ui')
        .component('aqBreadcrumb', {
            templateUrl: 'app/common/directives/breadcrumb/breadcrumb.html',
            bindings: {
                navigationState: '<',
            },
            controller: breadcrumbCtrl
        });

}