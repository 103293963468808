namespace aq.dashboard.widgets {

    export class _Prescription {
        public prescription: aq.models.prescription.Case;
        public prescriptionLink: string;

        /* @ngInject */
        constructor(
            private ENTIC_HOST_URL: string,
            private PrescriptionService: aq.services.PrescriptionService
        ) {
        }

        public $onInit() {
            this.prescriptionLink = `${this.ENTIC_HOST_URL}facility/${this.prescription.facility.facility_code}/overview/${this.prescription.id}`;
        }

        public getStatus(): string {
            return this.PrescriptionService.getStatus(this.prescription);
        }

        public getPriorityName(): string {
            return this.PrescriptionService.getPriorityName(this.prescription);
        }

        public getTitle(): string {
            return this.PrescriptionService.getTitle(this.prescription);
        }

        public getDateOpened(): string {
            return this.PrescriptionService.getDateOpened(this.prescription);
        }

        public getEstimatedSavings(): string {
            const savings = this.PrescriptionService.getEstimatedSavings(this.prescription);
            return savings && Math.round(savings).toLocaleString();
        }

        public getImpactAreas(): string[] {
            const result: string[] = [];
            return result;
        }

        public getDiagnosisDescription(): string {
            return this.PrescriptionService.getDiagnosisDescription(this.prescription);
        }
    }

    export const Prescription: ng.IComponentOptions = {
        controller: _Prescription,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/common/widgets/prescription/prescriptionView.html',
        bindings:  {
            prescription: '<'
        }
    };

    angular
        .module('aq.dashboard.widgets')
        .component('prescription', Prescription);
}
