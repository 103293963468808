angular.module('aq.admin.statistics.schedules').factory('StatsCalculatorService', function() {
    var service: any = {};
    
    service.toCalendarDistributionPlot = function(data, rangeCount) {
        if (!data) {
            return null;
        }
        
        return _.map(data.values, function(catData) {
            return calculateElementDistribution(catData.values, catData.name, rangeCount);
        });
    }

    service.toDistributionPlot = function(data, rangeCount) {
        if (!data) {
            return null;
        }
        return calculateElementDistribution(data.values.power, data.name, rangeCount);
    }



    function calculateElementDistribution(values: any[], name, rangeCount) {
        var info = _.reduce(values, function(acum, value) {
            if (!_.isNull(value)) {
                acum.count++;
                if (!acum.max || acum.max < value) {
                    acum.max = value;
                }

                if (!acum.min || acum.min > value) {
                    acum.min = value;
                }
            }
            return acum;
        }, {
            max: null,
            min: null,
            count: 0
        });

        var ranges = Range.create(info.min, info.max, rangeCount);

        _.each(values, function(value) {
            if (!_.isNull(value)) {
                _.each(ranges, function(range) {
                    range.checkAndAddValue(value);
                })
            }
        });
        
        var divider;
        var unit;
        if (info.max < 2000) {
            divider = 1;
            unit = 'W';
        } else {
            divider = 1000;
            unit = 'kW';
        }

        var newValues = [];
        _.each(ranges, function(range) {
            newValues.push(range.getCoordinates(info.count, divider));
        });

        return {
            unit: unit,
            data: newValues,
            name: name
        }
    }
    
    var Range: any = function(min, max) {
        this.min = min;
        this.max = max;
        this.values = 0;
    }
    
    Range.prototype.checkAndAddValue = function(value) {
        if (value > this.min && value <= this.max) {
            this.values++;
        }
    }
    
    Range.prototype.getCoordinates = function(count, divider) {
        var x = ((this.min + this.max) / 2) / divider;
        
        var y = this.values / count;
        
        return [x, y];
    }
    
    Range.prototype.create = function(start, end, rangeCount) {
        if (_.isNull(start) || _.isNull(end)) {
            return [];
        }
        
        var ranges = [];
        var rangeSize = Math.ceil((end - start) / rangeCount);
        
        var rangeStart = start;
        while(rangeStart < end) {
            var rangeEnd = (rangeStart + rangeSize) > end ? end : (rangeStart + rangeSize);  
            ranges.push(new Range(rangeStart, rangeEnd));
            
            rangeStart = rangeStart + rangeSize;
        }
        
        return ranges;
    }
    
    return service;
});
