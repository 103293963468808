angular.module('aq.filters.formatters')
.filter('aqnumber', ['$filter', function($filter) {
    return function(input, format, nilDisplay) {
        if (_.isNil(input) || input.length == 0)  {
            return nilDisplay || '-';
        }

        if (format) {
            return $filter('number')(input, format);
        }

        var numberFormat = '0,0';
        if ((input > 0 && input < 10) || (input < 0 && input > -10)) {
            numberFormat = '0,0.00';
        }
        if((input > 0 && input < 1) || (input < 0 && input > -1)) {
            numberFormat = '0,0.000';
        }
        //for teeny numbers
        if((input > 0 && input < 0.001) || (input < 0 && input > -0.001)) {
            numberFormat = '0,0.00000';
        }
        return $filter('number')(input, numberFormat);
    };
}]);