namespace aq.utilityBudgets {

    export interface IntervalMeterBulkRequest {
        utilityService: string;
        building: string;
        create: IntervalMeter[];
        delete: IntervalMeter[];
        subscribed: IntervalMeter[];
        unsubscribed: IntervalMeter[];
    }

    interface IntervalChangedSubscriptions {
        subscribed: aq.utilityBudgets.IntervalMeter[];
        unsubscribed: aq.utilityBudgets.IntervalMeter[];
    }

    export class ManageIntervalMeters extends aq.common.Controllers.ModalCtrl {
        public inProgress: boolean;
        public numberOfNewIntervalMeters: number;       // used for warning message displayed in the modal when adding new interval meters
        public intervalMetersToDelete: IntervalMeter[];
        public intervalMetersView: IntervalMeter[];

        /* @ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private accountId: string,
            private buildingId: string,
            private utilityAccount: UtilityAccount,
            private utilityService: UtilityService,
            private collectors: aq.common.models.Collector[],
            private intervalMeters: aq.utilityBudgets.IntervalMeter[],
            private intervalMeterSelect: aq.utilityBudgets.IntervalMeter[],
            private Messages: aq.services.Messages,
            private RestangularV3: restangular.IService,
            private $q: ng.IQService
        ) {
            super({}, $mdDialog);
            this.intervalMetersToDelete = [];
            this.intervalMetersView = angular.copy(intervalMeters);
            this.inProgress = false;
            this.numberOfNewIntervalMeters = 0;
        }

        public save(intervalMeters: IntervalMeter[]) {
            this.inProgress = true;
            this.numberOfNewIntervalMeters = 0;
            const { subscribed, unsubscribed } = this.getChangedSubscriptions(intervalMeters, this.intervalMeters);
            const intervalMeterBulkRequest: IntervalMeterBulkRequest = {
                utilityService: this.utilityService.id,
                building: this.buildingId,
                create: this.filterNew(intervalMeters),
                delete: this.intervalMetersToDelete,
                subscribed,
                unsubscribed
            };

            this.doIntervalMeterBulkRequest(intervalMeterBulkRequest)
            .then((result) => {
                this.intervalMetersView = this.updateIntervalMeterIds(result, this.intervalMetersView);
                this.Messages.success('Interval meters successfully saved');
                this.hide(this.intervalMetersView);
                this.inProgress = false;
            }).catch((error) => {
                this.Messages.error('Failed to save Interval meters');
                this.inProgress = false;
            });
        }

        public doIntervalMeterBulkRequest(requestMap: IntervalMeterBulkRequest) {
            return this.RestangularV3.one('interval').customPOST(requestMap, 'bulkUpdate');
        }

        public deleteIntervalMeterAction(intervalMeter: IntervalMeter, index: number,
                                         intervalMeters: IntervalMeter[], intervalMetersToDelete: IntervalMeter[]) {
            intervalMeters.splice(index, 1);

            if (intervalMeter.id) {
                intervalMetersToDelete.push(intervalMeter);
            }

            if (this.numberOfNewIntervalMeters > 0) {
                this.numberOfNewIntervalMeters--;
            }
        }

        public addUtilityMeterAction(intervalMeters: IntervalMeter[]) {
            this.numberOfNewIntervalMeters++;
            const intervalMeter: IntervalMeter = {
                id: undefined,
                smartMeterNumber: undefined,
                aquicoreMeterID: undefined,
                billMeterNumber: undefined,
                subscribed: true
            };
            intervalMeters.push(intervalMeter);
        }

        private updateIntervalMeterIds(newIntervalMeters: IntervalMeter[], intervalMeters: IntervalMeter[]) {
            for (let i = 0; i < intervalMeters.length; i++) {
                    const newIntervalMeterToSave = newIntervalMeters.find((newIntervalMeter) => {
                        return intervalMeters[i].smartMeterNumber === newIntervalMeter.smartMeterNumber;
                    });
                    if (newIntervalMeterToSave) {
                        intervalMeters[i] = newIntervalMeterToSave;
                    }
            }
            return intervalMeters;
        }

        private filterNew(intervalMeters: IntervalMeter[]): IntervalMeter[] {
            return _.filter(intervalMeters, (intervalMeter) => intervalMeter.id == undefined);
        }

        private getChangedSubscriptions(savedIntervalMeters: aq.utilityBudgets.IntervalMeter[], originalIntervalMeters: aq.utilityBudgets.IntervalMeter[]): IntervalChangedSubscriptions {
            const subscribed = [];
            const unsubscribed = [];
            const originalIntervalMetersMap = new Map<string, IntervalMeter>(originalIntervalMeters.map(meter => [meter.id, meter]));
            savedIntervalMeters.forEach(savedIntervalMeter => {
                const originalIntervalMeter = originalIntervalMetersMap.get(savedIntervalMeter.id);
                if (originalIntervalMeter && originalIntervalMeter.subscribed !== savedIntervalMeter.subscribed) {
                    if(savedIntervalMeter.subscribed) {
                        subscribed.push(savedIntervalMeter);
                    } else {
                        unsubscribed.push(savedIntervalMeter);
                    }
                }
            });
            return { subscribed, unsubscribed };
        }
    }
    angular.module('aq.utilityBudgets').controller('ManageIntervalMeters', ManageIntervalMeters);
}
