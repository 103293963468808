var aq;
(function (aq) {
    var propertySettings;
    (function (propertySettings) {
        var AlertNewCtrl = /** @class */ (function (_super) {
            __extends(AlertNewCtrl, _super);
            /* @ngInject */
            function AlertNewCtrl($scope, $mdDialog, alert, DataStore, building, account, Messages, $state, alertOnOptions, units, buildingDrillin, users, notificationSenders, collectors, sources, tenants, calendars, $mdStepper, RestangularV3, Auth) {
                var _this = _super.call(this, $scope, $mdDialog) || this;
                _this.$scope = $scope;
                _this.$mdDialog = $mdDialog;
                _this.alert = alert;
                _this.DataStore = DataStore;
                _this.building = building;
                _this.account = account;
                _this.Messages = Messages;
                _this.$state = $state;
                _this.alertOnOptions = alertOnOptions;
                _this.units = units;
                _this.buildingDrillin = buildingDrillin;
                _this.users = users;
                _this.notificationSenders = notificationSenders;
                _this.collectors = collectors;
                _this.sources = sources;
                _this.tenants = tenants;
                _this.calendars = calendars;
                _this.$mdStepper = $mdStepper;
                _this.RestangularV3 = RestangularV3;
                _this.Auth = Auth;
                _this.alertType = '';
                _this.$scope.collectors = collectors;
                _this.$scope.sources = sources;
                _this.$scope.tenants = tenants;
                _this.$scope.calendars = calendars;
                _this.$scope.alertOnOptions = alertOnOptions;
                _this.$scope.users = users;
                _this.$scope.units = [];
                _this.$scope.allUnits = units;
                _this.$scope.alert = alert;
                _this.$scope.vm = {
                    users: [],
                    selectedUser: {},
                    userSearchText: ''
                };
                _this.$scope.building = building;
                _this.$scope.buildingDrillin = buildingDrillin;
                _this.$scope.notificationSenders = notificationSenders;
                return _this;
            }
            AlertNewCtrl.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            AlertNewCtrl.prototype.previousStep = function () {
                this.$mdStepper('newAlertStepper').back();
            };
            AlertNewCtrl.prototype.nextStep = function () {
                this.$mdStepper('newAlertStepper').next();
            };
            AlertNewCtrl.prototype.onAlertChange = function ($event) {
                this.$scope.alertForPreview = angular.copy($event.alert);
            };
            AlertNewCtrl.prototype.setDefaultAlert = function () {
                var alert = this.getDefaultAlert(this.alertType);
                this.alert = alert;
                this.$scope.alert = this.alert;
            };
            AlertNewCtrl.prototype.getDefaultAlert = function (alertType) {
                var alert = {
                    building: this.building.id,
                    senders: [],
                    receivers: [],
                    active: true,
                    selectedUsers: []
                };
                switch (alertType) {
                    case 'State':
                        alert.type = 'STATE';
                        alert.alertOn = propertySettings.LevelSeverity.WARNING;
                        alert.defaultSeverity = propertySettings.LevelSeverity.NORMAL;
                        alert.interval = '1min';
                        alert.maxAgeMinutes = 10;
                        alert.whenCondition = propertySettings.WhenCondition.ANYTIME;
                        return alert;
                    case 'Usage':
                    default:
                        alert.type = 'REAL_TIME_ENERGY';
                        alert.interval = 'FIFTEEN_MINUTES';
                        alert.account = this.account.id;
                        return alert;
                }
            };
            AlertNewCtrl.prototype.save = function () {
                var _this = this;
                var promise = null;
                if (this.alertType === 'Usage') {
                    this.prepareAlertForUsageRequest();
                    promise = this.DataStore.create(this.building.all('realTimeEnergyAlerts'), this.$scope.alert)
                        .then(function (alert) {
                        return _this.DataStore.get(_this.building.one('realTimeEnergyAlerts', alert.id), { inflate: 'receivers,customRule', single: true }, true);
                    });
                }
                else if (this.alertType === 'State') {
                    this.prepareAlertForStateRequest();
                    promise = this.DataStore.create(this.RestangularV3.all('state-alerts'), this.$scope.alert, { buildingId: this.building.id });
                }
                promise
                    .then(function (alert) {
                    _this.Messages.success('Alert created successfully.');
                    _this.$mdDialog.hide();
                    _this.$state.go('aq.properties.buildings.alerts.details', { alertId: alert.id, isCreate: true });
                }).catch(function (error) {
                    _this.Messages.error('Unable to create Alert.');
                });
            };
            AlertNewCtrl.prototype.prepareAlertForRequest = function () {
                var _this = this;
                var notificationType = 'REAL_TIME_ENERGY';
                if (this.alertType === 'State') {
                    notificationType = 'STATE';
                }
                if (this.$scope.alert.sendTextMessage) {
                    var smsNotification = _.find(this.notificationSenders, { name: 'SMS', notificationType: notificationType });
                    this.$scope.alert.senders.push(smsNotification.id);
                }
                delete this.$scope.alert.sendTextMessage;
                if (this.$scope.alert.sendEmail) {
                    var mailNotification = _.find(this.notificationSenders, { name: 'Mail', notificationType: notificationType });
                    this.$scope.alert.senders.push(mailNotification.id);
                }
                delete this.$scope.alert.sendEmail;
                this.$scope.alert.selectedUsers.forEach(function (user) {
                    _this.$scope.alert.receivers.push({
                        user: user.id,
                        classType: 'UserReceiver'
                    });
                });
                delete this.$scope.alert.selectedUsers;
            };
            AlertNewCtrl.prototype.prepareAlertForStateRequest = function () {
                var alert = this.$scope.alert;
                _.each(alert.alertLevels, function (level) {
                    delete level.measureUnit;
                    delete level.queryable;
                });
                if (alert.customRule) {
                    if (alert.customRule.timeEnd) {
                        alert.customRule.timeEnd = this.time12HrTo24HrFormat(alert.customRule.timeEnd);
                    }
                    if (alert.customRule.timeStart) {
                        alert.customRule.timeStart = this.time12HrTo24HrFormat(alert.customRule.timeStart);
                    }
                }
                this.prepareAlertForRequest();
            };
            AlertNewCtrl.prototype.prepareAlertForUsageRequest = function () {
                var alert = this.$scope.alert;
                if (alert.drillMode === 'BUILDING') {
                    alert.drillMode = null;
                }
                alert.unit = alert.unit.value;
                this.prepareAlertForRequest();
            };
            AlertNewCtrl.prototype.time12HrTo24HrFormat = function (time) {
                if (time.match(/^\d{1,2}:\d{2} \D{2}$/)) {
                    return moment(time, 'h:mm A').format('HH:mm:ss');
                }
                return time;
            };
            return AlertNewCtrl;
        }(aq.common.Controllers.ModalCtrl));
        propertySettings.AlertNewCtrl = AlertNewCtrl;
        angular.module('properties').controller('AlertNewCtrl', aq.propertySettings.AlertNewCtrl);
    })(propertySettings = aq.propertySettings || (aq.propertySettings = {}));
})(aq || (aq = {}));
