namespace aq.ui {

    export class AqNestedSidebarNavController {
        public data: common.models.NestedSidebarNavItem[];
        private sidenavId: string = 'aq-nested-sidenav';
        /* @ngInject */
        constructor(
            private $mdMedia: angular.material.IMedia,
            private $mdSidenav: angular.material.ISidenavService
        ) { }

        public toggle() {
            const sidenav = this.$mdSidenav(this.sidenavId);
            this.isOpen() && !this.isLargeScreen() ? sidenav.close() : sidenav.open();
        };
        public isOpen() {
            return this.$mdSidenav(this.sidenavId).isOpen() || this.isLargeScreen();
        };
        public isLargeScreen() {
            return this.$mdMedia('gt-sm');
        }
        public isMediumScreen() {
            return this.$mdMedia('gt-xs');
        }
        public close() {
            const sidenav = this.$mdSidenav(this.sidenavId);
            sidenav.close();
        }
    }

    angular
        .module('aq.ui')
        .component('aqNestedSidebarNav', {
            controller: AqNestedSidebarNavController,
            controllerAs: 'vm',
            templateUrl: 'app/common/directives/aqNestedSidebarNav/aqNestedSidebarNav.html',
            bindings: {
                data: '<'
            },
            transclude: true
        });
}
