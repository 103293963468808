angular.module('aq.ui').directive('aqCrudDirtyFormPrevention', () => {
    let formController: ng.IFormController;
    return {
        restrict: 'A',
        require: '^form',
        link: (scope, element, attrs, formCtrl: ng.IFormController) => {
            formController = formCtrl;
        },
        controller: ($scope, $mdDialog: ng.material.IDialogService, $state, loading) => {
            let allow = false;
            $scope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams, options) => {
                if(!formController.$pristine && !allow) {
                    loading.stop();
                    var confirm = $mdDialog.confirm()
                        .title('It looks like you have unsaved changes')
                        .textContent('If you leave before saving, your changes will be lost.')
                        .ariaLabel('Dirty form')
                        .targetEvent(event)
                        .ok('Leave')
                        .cancel('Stay');
                    $mdDialog.show(confirm).then(function() {
                        allow = true;
                        $state.go(toState, toParams, {reload: true});
                    });
                    event.preventDefault();
                }
            });
        }
    }
});
