namespace aq.tenantbilling {
    export class RateCalculatorCtrl {
        public idCounter: number;
        public calculation: {
            charges: {
                id: number;
                label: string;
                utilityBillPeriod: number;
                startDate: string;
                endDate: string;
                invoiceDate: string;
                consumption: number;
                charge: number;
            }[];
            totalConsumption: number;
            totalCharge: number;
            blendedRate: number;
        };
        public currencyUnit: any;
        public progressText: string;
        public utilityBills: UtilityBillPeriod[];
        public selectedUtilityBillPeriod: UtilityBillPeriod;
        public utilityBillCharges;
        public manualRateCharges;
        public selectedUtilityBills;
        /* @ngInject */
        constructor(
            private service: PeriodTenantService,
            private building: aq.common.models.Building,
            private $mdDialog: ng.material.IDialogService,
            private MonthlyBillingService: MonthlyBillingService,
            private OptionsService
        ) {
            this.currencyUnit = this.OptionsService.currencyUnit();
            if (this.service.calculation) {
                this.calculation = JSON.parse(this.service.calculation);
                // start charge IDs at next number
                const currentId = _.max(_.map(this.calculation.charges, 'id')) || 0;
                this.idCounter = currentId + 1;
            } else {
                this.idCounter = 0;
                this.calculation = {
                    charges: [],
                    totalConsumption: 0,
                    totalCharge: 0,
                    blendedRate: 0
                };
            }
            this.setChargeGroups();
            this.selectedUtilityBills = [];
            if (this.utilityBillCharges.length > 0) {
                _.forEach(this.utilityBillCharges, (charge) => this.selectedUtilityBills.push(charge.utilityBillPeriod));
            }
            this.progressText = 'Loading Utility Bills...';
            this.MonthlyBillingService.getUtilityBillsForPeriodTenantService(service, building)
                .then((utilityBills) => {
                    this.utilityBills = utilityBills;
                    this.progressText = null;
                });
        }

        public cancel() {
            this.$mdDialog.cancel();
        }

        public setRate() {
            this.$mdDialog.hide(this.calculation);
        }

        public setChargeGroups() {
            this.manualRateCharges = this.calculation.charges.filter(charge => _.isNil(charge.utilityBillPeriod));
            this.utilityBillCharges = this.calculation.charges.filter(charge => !_.isNil(charge.utilityBillPeriod));
        }

        public selectUtilityBill() {
            _.forEach(this.utilityBillCharges, (charge) => this.removeCharge(charge.id))
            this.selectedUtilityBills.forEach(bill => {
                const selectedBill = _.find(this.utilityBills, (utilityBill) => {
                        return utilityBill.id == bill;
                    });
                this.calculation.charges.push({
                    id: this.idCounter++,
                    label: null,
                    utilityBillPeriod: selectedBill.id,
                    startDate: selectedBill.startDate,
                    endDate: selectedBill.endDate,
                    invoiceDate: selectedBill.invoiceDate,
                    consumption: selectedBill.usage,
                    charge: selectedBill.charge
                });
            });
            this.setChargeGroups();
            this.updateRate();
        }

        public addCharge() {
            this.calculation.charges.push({
                id: this.idCounter++,
                label: null,
                utilityBillPeriod: null,
                startDate: null,
                endDate: null,
                invoiceDate: null,
                consumption: null,
                charge: null
            });
            this.setChargeGroups();
        }

        public removeCharge(chargeId: number) {
            _.remove(this.calculation.charges, { id: chargeId });
            this.setChargeGroups();
            this.updateRate();
        }

        public updateRate() {
            const totalConsumption = _.sum(_.map(this.calculation.charges, (charge) => {
                if (_.isNil(charge.consumption)) {
                    return 0;
                }
                return charge.consumption;
            }));
            const totalCharge = _.sum(_.map(this.calculation.charges, (charge) => {
                if (_.isNil(charge.charge)) {
                    return 0;
                }
                return charge.charge;
            }));

            this.updateCalculation(totalConsumption, totalCharge);
        }

        private updateCalculation(totalConsumption, totalCharge) {
            this.calculation.totalConsumption = totalConsumption || 0;
            this.calculation.totalCharge = totalCharge || 0;
            if (this.calculation.totalConsumption !== 0) {
                let blendedRate = this.calculation.totalCharge / this.calculation.totalConsumption;
                blendedRate = parseFloat(blendedRate.toFixed(4));
                this.calculation.blendedRate = blendedRate;
            } else {
                this.calculation.blendedRate = 0;
            }
        }
    }

    angular.module('tenantBilling')
        .controller('RateCalculatorCtrl', RateCalculatorCtrl);
}
