namespace aq.ui {
    interface PersonnelCount {
        [persona: string]: number;
    }
    interface VisiblePersonnel {
        expanded: boolean;
        personnel: string;
    }
    export class AqVerifyBuildingPersonnelStepCtrl {
        public buildings: aq.common.models.Building[];
        public displayPersonnelLimit: number;
        public isAllUsersAssigned: boolean;
        public buildingPersonnelCount: {
            [buildingIndex: number]: PersonnelCount;
        };
        public hasOthers: boolean;
        private users: aq.user.User[];
        private personas: string[];
        private header: string;
        private expandPersonnel: {
            [key: number]: VisiblePersonnel
        };
        private unassignedUsers: aq.user.User[];
        private expandUnassigned: VisiblePersonnel;

        /* @ngInject */
        constructor(
            private $mdDialog: ng.material.IDialogService,
            private $scope: ng.IScope
        ) {
        }

        public $onInit() {
            this.expandPersonnel = {};
            this.expandUnassigned = {
                personnel: '',
                expanded: false
            };
            this.unassignedUsers = [];
            this.buildingPersonnelCount = {};
            this.displayPersonnelLimit = 5;
            _.each(this.buildings, (building: aq.common.models.Building) => {
                this.expandPersonnel[building.index] = {
                    personnel: this.getBuildingPersonnel(building.personnel, this.displayPersonnelLimit),
                    expanded: false
                };
                this.setBuildingPersonnelCount(building);
            });
            this.validateUsersAssignment();
        }

        public setBuildingPersonnelCount(building: aq.common.models.Building) {
            const personaMap = { 'Other': 0 };
            _.each(this.personas, (persona: string) => {
                personaMap[persona] = 0;
            });
            const visibleBuildingPersonnel = this.getVisibleBuildingPersonnel(building.personnel);
            this.buildingPersonnelCount[building.index] = angular.extend(
                personaMap,
                _.countBy(visibleBuildingPersonnel, (employee: aq.common.models.Employee) => employee.user.persona || 'Other')
            );
            if (this.buildingPersonnelCount[building.index]['Other'] > 0) {
                this.hasOthers = true;
            }
        }

        public toggleBuildingPersonnelView(building, $event: ng.IAngularEvent) {
            $event.stopPropagation();
            const expandData = this.expandPersonnel[building.index];
            expandData.expanded = !expandData.expanded;
            if (expandData.expanded) {
                expandData.personnel = this.getBuildingPersonnel(building.personnel, 100);
            } else {
                expandData.personnel = this.getBuildingPersonnel(building.personnel, this.displayPersonnelLimit);
            }
        }

        public getBuildingPersonnel(personnel: aq.common.models.Employee[], limit: number): string {
            const visibleBuildingPersonnel = this.getVisibleBuildingPersonnel(personnel);
            const limitedPersonnel = _.take(visibleBuildingPersonnel, limit);
            const names = _.map(limitedPersonnel, (employee) => `${employee.user.firstName} ${employee.user.lastName}`);
            let result = names.join(', ');
            if (visibleBuildingPersonnel.length > limit) {
                result += '...';
            }
            return result;
        }

        public editBuildingPersonnel(building: aq.common.models.Building, isNew: boolean) {
            const visiblePersonnel = this.getVisibleBuildingPersonnel(building.personnel);
            const notVisibleBuildingPersonnel = _.differenceBy(building.personnel, visiblePersonnel, (item) => item.id);
            this.$mdDialog.show({
                controller: 'EditBuildingPersonnelModalCtrl as vm',
                templateUrl: 'app/components/verifySteps/modals/editBuildingPersonnelModal.html',
                clickOutsideToClose: false,
                locals: {
                    editBuildingPersonnel: building,
                    editBuildingPersonnelUsers: this.users,
                    isNew
                }
            })
                .then((updatedBuilding: aq.common.models.Building) => {
                    const oldIds = _.map(building.personnel, (p) => p.index !== undefined ? p.index : p.id);
                    const newIds = _.map(updatedBuilding.personnel, (p) => p.index !== undefined ? p.index : p.id);
                    const intersection = _.intersection(oldIds, newIds);
                    const isDiff = intersection.length < oldIds.length || intersection.length < newIds.length;
                    if (isDiff) {
                        building.isUpdatePersonnel = true;
                        building.personnel = updatedBuilding.personnel;
                    }
                    if (notVisibleBuildingPersonnel.length > 0) {
                        building.personnel.push(...notVisibleBuildingPersonnel);
                    }
                    const expandData = this.expandPersonnel[building.index];
                    expandData.expanded = false;
                    expandData.personnel = this.getBuildingPersonnel(building.personnel, this.displayPersonnelLimit);
                    this.setBuildingPersonnelCount(building);
                })
                .catch(() => {
                    if (isNew) {
                        _.remove(this.buildings, building);
                    }
                })
                .finally(() => {
                    this.validateUsersAssignment();
                });
        }
        public validateUsersAssignment() {
            this.unassignedUsers = _.filter(this.users, (user: aq.user.User) => {
                return _.every(this.buildings, (building: aq.common.models.Building) => {
                    return _.findIndex(building.personnel,
                        (personnel: aq.common.models.Employee) => personnel.user && personnel.user.id === user.id) == -1;
                });
            });
            const isValid = this.unassignedUsers.length == 0;
            this.isAllUsersAssigned = isValid;
            this.expandUnassigned.personnel = this.getUnassignedUsersFormatted();
            this.$scope.$emit('buildingPersonnelValidation', { isValid });
        }

        public getPersonaCountHeader(persona: string) {
            if (!persona) {
                return '';
            }
            return `${persona.replace('_', ' ')}s`;
        }

        public getUnassignedUsersFormatted() {
            const users = this.expandUnassigned.expanded ? this.unassignedUsers : _.take(this.unassignedUsers, this.displayPersonnelLimit);
            let formattedUsers = _.map(users, (user: aq.user.User) => `${user.firstName} ${user.lastName}`).join(', ');
            if (!this.expandUnassigned.expanded && this.unassignedUsers.length > this.displayPersonnelLimit) {
                formattedUsers = `${formattedUsers}, and ${this.unassignedUsers.length - this.displayPersonnelLimit} more`;
            }
            return formattedUsers;
        }

        public toggleUnassignedPersonnelView() {
            this.expandUnassigned.expanded = !this.expandUnassigned.expanded;
            this.expandUnassigned.personnel = this.getUnassignedUsersFormatted();
        }

        private getVisibleBuildingPersonnel(personnel: aq.common.models.Employee[]) {
            return _.filter(personnel, (employee: aq.common.models.Employee) => {
                if (employee.user != null) {
                    return _.some(this.users, (user: aq.user.User) => user.index == employee.user.index);
                }
            });
        }
    }

    angular.module('aq.ui').component('aqVerifyBuildingPersonnelStep', {
        controller: AqVerifyBuildingPersonnelStepCtrl,
        controllerAs: 'vm',
        templateUrl: 'app/components/verifySteps/aqVerifyBuildingPersonnelStep.html',
        bindings: {
            users: '<',
            personas: '<',
            buildings: '<',
            header: '<'
        }
    });
}
