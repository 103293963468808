var aq;
(function (aq) {
    var reports;
    (function (reports) {
        var ExecutiveReportCtrl = /** @class */ (function (_super) {
            __extends(ExecutiveReportCtrl, _super);
            /* @ngInject */
            function ExecutiveReportCtrl(account, buildings, date, $timeout) {
                var _this = _super.call(this, $timeout) || this;
                _this.account = account;
                _this.buildings = buildings;
                _this.date = date;
                _this.$timeout = $timeout;
                _this.data = {};
                _this.date = moment(_this.date)
                    .tz(account.timeZoneId)
                    .format('MMMM, YYYY');
                _this.data[22485218] = {
                    spending: {
                        electricity: 863447,
                        water: 202239.32,
                        gas: 103293.45,
                        steam: 32546,
                        heat: _.random(-1, 0),
                        total: 1201525.77
                    },
                    budget: {
                        electricity: 789099,
                        water: 233543.54,
                        gas: 123345.43,
                        steam: 31721,
                        heat: _.random(-1, 0),
                        total: 1177708.97
                    },
                    contact: {
                        pm: {
                            name: 'Logan Soya',
                            email: 'logan@aquicore.com'
                        },
                        engineer: {
                            name: 'Shane Vincent',
                            email: 'shane@aquicore.com'
                        },
                        operations: {
                            name: 'Kristina Niedermayer',
                            email: 'kristina@aquicore.com'
                        }
                    }
                };
                _this.data[22984268] = {
                    spending: {
                        electricity: 301563.84,
                        water: 102435.45,
                        gas: 123321.34,
                        steam: 22454.54,
                        heat: 26789,
                        total: 576564.17
                    },
                    budget: {
                        electricity: 648000,
                        water: 110345,
                        gas: 180098.43,
                        steam: 23452.68,
                        heat: 23563.43,
                        total: 985459.54
                    },
                    contact: {
                        pm: {
                            name: 'Logan Soya',
                            email: 'logan@aquicore.com'
                        },
                        engineer: {
                            name: 'Shane Vincent',
                            email: 'shane@aquicore.com'
                        },
                        operations: {
                            name: 'Kristina Niedermayer',
                            email: 'kristina@aquicore.com'
                        }
                    }
                };
                _this.data[22489632] = {
                    spending: {
                        electricity: 1322241.75,
                        water: 98770.54,
                        gas: 54678.56,
                        steam: 236699.43,
                        heat: _.random(-1, 0),
                        total: 1712390.28
                    },
                    budget: {
                        electricity: 1501000,
                        water: 99568.65,
                        gas: 56787.68,
                        steam: 278930.5,
                        heat: _.random(-1, 0),
                        total: 1936286.83
                    },
                    contact: {
                        pm: {
                            name: 'Logan Soya',
                            email: 'logan@aquicore.com'
                        },
                        engineer: {
                            name: 'Shane Vincent',
                            email: 'shane@aquicore.com'
                        },
                        operations: {
                            name: 'Kristina Niedermayer',
                            email: 'kristina@aquicore.com'
                        }
                    }
                };
                _this.data[22489976] = {
                    spending: {
                        electricity: 1090606.30,
                        water: 3455003,
                        gas: 57898.789,
                        steam: 78904.54,
                        heat: 45638.54,
                        total: 4728051.169
                    },
                    budget: {
                        electricity: 320000,
                        water: 6354885,
                        gas: 54506,
                        steam: 81002,
                        heat: 47898,
                        total: 8085876
                    },
                    contact: {
                        pm: {
                            name: 'Logan Soya',
                            email: 'logan@aquicore.com'
                        },
                        engineer: {
                            name: 'Shane Vincent',
                            email: 'shane@aquicore.com'
                        },
                        operations: {
                            name: 'Kristina Niedermayer',
                            email: 'kristina@aquicore.com'
                        }
                    }
                };
                _this.data[22490253] = {
                    spending: {
                        electricity: 187971.30,
                        water: 68978.678,
                        gas: _.random(-1, 0),
                        steam: _.random(-1, 0),
                        heat: 46789.345,
                        total: 303739.323
                    },
                    budget: {
                        electricity: 192568,
                        water: 65234,
                        gas: _.random(-1, 0),
                        steam: _.random(-1, 0),
                        heat: 48979,
                        total: 306781
                    },
                    contact: {
                        pm: {
                            name: 'Logan Soya',
                            email: 'logan@aquicore.com'
                        },
                        engineer: {
                            name: 'Shane Vincent',
                            email: 'shane@aquicore.com'
                        },
                        operations: {
                            name: 'Kristina Niedermayer',
                            email: 'kristina@aquicore.com'
                        }
                    }
                };
                _this.data[26610647] = {
                    spending: {
                        electricity: 123456.56,
                        water: _.random(-1, 0),
                        gas: 45678.68,
                        steam: 54678.68,
                        heat: _.random(-1, 0),
                        total: 223813.92
                    },
                    budget: {
                        electricity: 150023,
                        water: _.random(-1, 0),
                        gas: 48900,
                        steam: 58789,
                        heat: _.random(-1, 0),
                        total: 257712
                    },
                    contact: {
                        pm: {
                            name: 'Logan Soya',
                            email: 'logan@aquicore.com'
                        },
                        engineer: {
                            name: 'Shane Vincent',
                            email: 'shane@aquicore.com'
                        },
                        operations: {
                            name: 'Kristina Niedermayer',
                            email: 'kristina@aquicore.com'
                        }
                    }
                };
                _this.data[22485622] = {
                    spending: {
                        electricity: 96785.67,
                        water: 34678.998,
                        gas: 87456.567,
                        steam: 23578.54,
                        heat: 32456.32,
                        total: 274956.095
                    },
                    budget: {
                        electricity: 95648,
                        water: 68769,
                        gas: 103454,
                        steam: 47345,
                        heat: 45678,
                        total: 360894
                    },
                    contact: {
                        pm: {
                            name: 'Logan Soya',
                            email: 'logan@aquicore.com'
                        },
                        engineer: {
                            name: 'Shane Vincent',
                            email: 'shane@aquicore.com'
                        },
                        operations: {
                            name: 'Kristina Niedermayer',
                            email: 'kristina@aquicore.com'
                        }
                    }
                };
                _this.notifyDocumentReady();
                return _this;
            }
            // TODO: when this report is made real, move these methods to a service
            ExecutiveReportCtrl.prototype.getAccountTotals = function (buildings) {
                return this.sumTotals(buildings);
            };
            ExecutiveReportCtrl.prototype.getAccountRollupPercent = function (buildings) {
                var _a = this.getAccountTotals(buildings), spending = _a.spending, budget = _a.budget;
                return _.percent(spending, budget);
            };
            ExecutiveReportCtrl.prototype.getGroupTotals = function (group, buildings) {
                var buildingsInGroup = buildings.filter(function (building) { return building.buildingGroup.id === group.id; });
                return this.sumTotals(buildingsInGroup);
            };
            ExecutiveReportCtrl.prototype.getGroupRollupPercent = function (group, buildings) {
                var _a = this.getGroupTotals(group, buildings), spending = _a.spending, budget = _a.budget;
                return _.percent(spending, budget);
            };
            ExecutiveReportCtrl.prototype.getBuildingRollupPercent = function (building) {
                var _a = this.sumTotals([building]), spending = _a.spending, budget = _a.budget;
                return _.percent(spending, budget);
            };
            ExecutiveReportCtrl.prototype.getTargetModel = function (percentage) {
                return { percentage: percentage, color: this.getColorFromPercent(percentage) };
            };
            ExecutiveReportCtrl.prototype.getBestBuildings = function (buildings) {
                var sorted = this.getSortedBuildingPerformance(buildings).slice(0, 3);
                return sorted
                    .map(function (id) { return buildings.filter(function (building) { return building.id == id; })[0]; })
                    .sort(function (b1, b2) { return (sorted.indexOf(b1.id) > sorted.indexOf(b2.id)) ? -1 : 1; });
            };
            ExecutiveReportCtrl.prototype.getWorstBuildings = function (buildings) {
                var sorted = this.getSortedBuildingPerformance(buildings).slice(-3);
                return sorted
                    .map(function (id) { return buildings.filter(function (building) { return building.id == id; })[0]; })
                    .sort(function (b1, b2) { return (sorted.indexOf(b1.id) > sorted.indexOf(b2.id)) ? -1 : 1; });
            };
            ExecutiveReportCtrl.prototype.sumTotals = function (buildings) {
                var _this = this;
                return {
                    spending: _.sum(buildings.map(function (building) { return _this.data[building.id].spending.total; })),
                    budget: _.sum(buildings.map(function (building) { return _this.data[building.id].budget.total; }))
                };
            };
            ExecutiveReportCtrl.prototype.getSortedBuildingPerformance = function (buildings) {
                var _this = this;
                var map = {};
                buildings.map(function (building) {
                    map[building.id] = {
                        percent: _this.getBuildingRollupPercent(building)
                    };
                });
                return Object.keys(map).sort(function (a, b) { return map[a].percent - map[b].percent; });
            };
            ExecutiveReportCtrl.prototype.getColorFromPercent = function (percentage) {
                return percentage >= 100 ? '#E70F28' : '#7ACD46';
            };
            __decorate([
                Memoize()
            ], ExecutiveReportCtrl.prototype, "getAccountTotals", null);
            __decorate([
                Memoize()
            ], ExecutiveReportCtrl.prototype, "getAccountRollupPercent", null);
            __decorate([
                Memoize()
            ], ExecutiveReportCtrl.prototype, "getGroupTotals", null);
            __decorate([
                Memoize()
            ], ExecutiveReportCtrl.prototype, "getGroupRollupPercent", null);
            __decorate([
                Memoize()
            ], ExecutiveReportCtrl.prototype, "getBuildingRollupPercent", null);
            __decorate([
                Memoize()
            ], ExecutiveReportCtrl.prototype, "getTargetModel", null);
            __decorate([
                Memoize()
            ], ExecutiveReportCtrl.prototype, "getBestBuildings", null);
            __decorate([
                Memoize()
            ], ExecutiveReportCtrl.prototype, "getWorstBuildings", null);
            __decorate([
                Memoize()
            ], ExecutiveReportCtrl.prototype, "sumTotals", null);
            __decorate([
                Memoize()
            ], ExecutiveReportCtrl.prototype, "getSortedBuildingPerformance", null);
            __decorate([
                Memoize()
            ], ExecutiveReportCtrl.prototype, "getColorFromPercent", null);
            return ExecutiveReportCtrl;
        }(reports.BaseDocraptorReportCtrl));
        reports.ExecutiveReportCtrl = ExecutiveReportCtrl;
        angular
            .module('aq.reports')
            .controller('executiveReportCtrl', ExecutiveReportCtrl);
    })(reports = aq.reports || (aq.reports = {}));
})(aq || (aq = {}));
