namespace aq.dashboard.widgets {
    export class BudgetBreakdownWidgetCtrl {
        private lastSentId: number;
        private data: any[];
        private budgets: any[];
        private budgetsLevelUp: any[];
        private currentBudgets: any[];
        private viewData: any[];
        private currentLevelNumber: number;
        private currentLevel: any;
        private currentFilters: { name: string; value: string; }[];
        private drillLevels: any[];
        private isRollUpBudget: boolean;
        private isMonthView: boolean;
        private consumptionConfig: any;

        /* @ngInject */
        constructor(
            private $scope,
            private buildings: aq.common.models.Building[],
            private buildingGroups: any[],
            private users: aq.common.models.User[],
            private Messages: aq.services.Messages,
            private RestangularV3: restangular.IService,
            private Restangular: restangular.IService,
            private config,
            private $filter,
            private MockDataService: MockDataService,
            private DrillDataService: DrillDataService,
            private $translate
        ) {
            this.isMonthView = false;
            this.budgets = [
                { property: 'elecBudget', label: 'Elec. Budget' },
                { property: 'waterBudget', label: 'Water Budget' },
                { property: 'gasBudget', label: 'Gas Budget' }
            ];
            this.isRollUpBudget = false;
            this.budgetsLevelUp = [{ property: 'allBudget', label: 'Budget' }];
            this.currentBudgets = this.budgets;
            this.drillLevels = [
                { idProperty: 'groupId', nameProperty: 'groupName', label: 'Building Group' },
                { idProperty: 'buildingId', nameProperty: 'buildingName', label: 'Building' },
                { idProperty: 'buildingId', nameProperty: 'buildingName', label: 'Building' } //quick hack
            ];
            this.currentFilters = [];
            this.currentLevelNumber = 0;
            this.generateData();
            this.processViewData();

            this.$scope.$on('dataSelectedBroadcast', ($event, message) => {
                if (this.lastSentId == message.id) {
                    return;
                }
                this.currentFilters = [];
                this.currentLevelNumber = 0;
                if (message.data && message.data.idProperty == 'buildingId') {
                    this.currentLevelNumber = 1;
                }
                this.drillDown(message.data, true);
            });
        }
        public onDrillBudgetChange() {
            if (!this.isRollUpBudget) {
                this.currentBudgets = this.budgets;
            } else {
                this.currentBudgets = this.budgetsLevelUp;
            }
        }

        public generateData() {
            this.data = this.MockDataService.getBuildingUtilityData();
        }

        public processViewData() {
            this.currentLevel = this.drillLevels[this.currentLevelNumber];
            this.viewData = [];
            const filteredData = this.currentFilters.length > 0
                ? _.filter(this.data, (dataItem) => _.every(this.currentFilters, (filter) => dataItem[filter.name] == filter.value))
                : this.data;

            this.viewData = this.DrillDataService.processData(filteredData, this.currentLevel.idProperty, this.currentLevel.nameProperty);
        }
        public emitSelectedEvent(item) {
            this.lastSentId = Math.random();
            const type = this.currentLevelNumber == 0 ? 'buildingGroup' : 'building';
            this.$scope.$emit('dataSelectedEmit', {
                id: this.lastSentId,
                type,
                data: item
            });
        }
        public drillInto(item, budgetProperty) {
            this.emitSelectedEvent(item);

            this.drillDown(item);
        }

        public getColor(percent) {
            if (percent > 100) {
                return '#E70F28';
            } else if (percent <= 100 && percent >= 98) {
                return '#F1A81D';
            } else if (percent < 98) {
                return '#7ACD46';
            }
        }

        public getColorLastMonth(percent) {
            return this.getColor(percent);
        }

        public getBudgetDescription(budgetData) {
            if (!budgetData) {
                return;
            }
            if (this.isMonthView) {
                return `${this.$translate.instant('dashboard.common.widgets.budget.Last Month')}: ${this.filterCharge(budgetData.lastMonthSumCurrent)} ${this.$translate.instant('of')} ${this.filterCharge(Math.round(budgetData.lastMonthSumTotal))}`;
            } else {
                return `${this.filterCharge(budgetData.sumCurrent)} ${this.$translate.instant('of')} ${this.filterCharge(Math.round(budgetData.sumTotalYtd))}`;
            }
        }

        public getBudgetDescriptionWithLabel(budgetData) {
            if (!budgetData) {
                return;
            }
            return `${this.filterCharge(budgetData.sumCurrent)} ${this.$translate.instant('of')} ${this.filterCharge(budgetData.sumTotal)}`;
        }

        public getTopPerformers() {
            const result = _.filter(this.viewData, (item) => {
                if (this.isMonthView) {
                    return item['allBudget'].percentLastMonth < 100;
                } else {
                    return item['allBudget'].percent < 100;
                }
            });
            if (this.isMonthView) {
                return _.orderBy(result, ['percentLastMonth'], ['asc']);
            } else {
                return _.orderBy(result, ['percent'], ['asc']);
            }
        }
        public getUnderPerformers() {
            const result = _.filter(this.viewData, (item) => {
                if (this.isMonthView) {
                    return item['allBudget'].percentLastMonth >= 100;
                } else {
                    return item['allBudget'].percent >= 100;
                }
            });
            if (this.isMonthView) {
                return _.orderBy(result, ['percentLastMonth'], ['desc']);
            } else {
                return _.orderBy(result, ['percent'], ['desc']);
            }
        }
        public getDescriptionTopWorst(budgetData, label) {
            if (!budgetData) {
                return;
            }
            if (this.isMonthView) {
                return `${budgetData.percentLastMonth}% ${this.$translate.instant('of')} ${this.filterCharge(Math.round(budgetData.lastMonthSumTotal))} ${this.$translate.instant('dashboard.common.widgets.budget.Last Month')} ${this.$translate.instant("dashboard.common.widgets.budget."+label)}`;
            } else {
                return `${budgetData.percent}% ${this.$translate.instant('of')} ${this.filterCharge(Math.round(budgetData.sumTotalYtd))} YTD ${this.$translate.instant("dashboard.common.widgets.budget."+label)}`;
            }
        }
        public getDescriptionTotal(budgetData, label) {
            return `${this.filterCharge(Math.round(budgetData.sumCurrent))} ${this.$translate.instant('of')} ${this.filterCharge(Math.round(budgetData.sumTotal))} ${this.$translate.instant('of')} ${this.$translate.instant("dashboard.common.widgets.budget."+label)}`;
        }
        // TODO: extract in service

        public setFilter(name, value) {
            this.currentFilters = [{ name, value }];
            this.processViewData();
        }
        public drillDown(item, fPreventEmit = false) {
            const nextLevel = this.getNextLevel();
            if (nextLevel < 0) {
                return;
            }
            if (!fPreventEmit) {
                this.emitSelectedEvent(item);
            }
            this.currentLevelNumber = nextLevel;
            this.currentLevel = this.drillLevels[this.currentLevelNumber];
            this.currentFilters.push({
                name: item.idProperty,
                value: item.id
            });
            this.processViewData();

            //this.isDrillByBudget = false;
            this.onDrillBudgetChange();
        }
        public getNextLevel() {
            if (this.currentLevelNumber + 1 < this.drillLevels.length) {
                return this.currentLevelNumber + 1;
            }
            return -1;
        }
        public getFilterDisplay(filter) {
            const data = _.find(this.data, (item) => item[filter.name] == filter.value);
            const drill = _.find(this.drillLevels, (item) => item.idProperty == filter.name);
            if (data && drill) {
                return `${drill.label}: ${data[drill.nameProperty]}`;
            }
            return `${filter.name}: ${filter.value}`;
        }
        public clearFilter() {
            this.currentFilters = [];
            this.currentLevelNumber = 0;
            this.processViewData();

            this.lastSentId = Math.random();
            const type = 'CLEAR';
            this.$scope.$emit('dataSelectedEmit', {
                id: this.lastSentId,
                type,
                data: null
            });
        }
        public filterUpToMe(filter) {
            const newFilters = [];
            let cnt = 0;
            let currentFilter = null;
            do {
                currentFilter = this.currentFilters[cnt];
                newFilters.push(currentFilter);
                cnt++;
            }
            while (currentFilter && currentFilter.name != filter.name);
            this.currentFilters = newFilters;
            this.currentLevelNumber = cnt;
            this.processViewData();

            const eventData = {
                id: filter.value
            };
            this.lastSentId = Math.random();
            const type = this.currentLevelNumber == 1 ? 'buildingGroup' : 'building';
            this.$scope.$emit('dataSelectedEmit', {
                id: this.lastSentId,
                type,
                data: eventData
            });
        }
        private filterCharge(charge) {
            return this.$filter('currency')(charge, '$', 2);
        };
    }
    angular.module('aq.dashboard.widgets').controller('BudgetBreakdownWidgetCtrl', BudgetBreakdownWidgetCtrl);
}
