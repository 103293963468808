var aq;
(function (aq) {
    var dashboard;
    (function (dashboard) {
        var widgets;
        (function (widgets) {
            var StatsEditService = /** @class */ (function (_super) {
                __extends(StatsEditService, _super);
                /* ngInject */
                function StatsEditService(DashboardOptionsService, $translate) {
                    var _this = _super.call(this, DashboardOptionsService, $translate) || this;
                    _this.DashboardOptionsService = DashboardOptionsService;
                    _this.$translate = $translate;
                    return _this;
                }
                StatsEditService.prototype.onBuildingIdsChange = function (cfg, buildingIds) {
                    _super.prototype.onBuildingIdsChange.call(this, cfg, buildingIds);
                    this.buildCalculationOptions(cfg);
                    this.generateDefaultTitle(cfg);
                };
                StatsEditService.prototype.onSingleBuildingChange = function (cfg, buildingId) {
                    cfg.view.buildingId = buildingId;
                    cfg.drilldown = null;
                    if (cfg.view.buildingId == null) {
                        cfg.options.drilldownOptions = [];
                        this.onBuildingIdsChange(cfg, []);
                        return;
                    }
                    cfg.options.drilldownOptions = this.getDrilldownOptions(cfg);
                    this.onBuildingIdsChange(cfg, [cfg.view.buildingId]);
                };
                StatsEditService.prototype.onTimePeriodChange = function (cfg) {
                    if (cfg.comparison) {
                        cfg.series[1].trendPeriod = TrendPeriods.getTrendPeriodForDatePeriod(cfg.preset);
                    }
                    this.generateDefaultTitle(cfg);
                };
                StatsEditService.prototype.onBreakdownChange = function (cfg) {
                    cfg.drilldown = null;
                    cfg.buildingGroupId = null;
                    if (cfg.breakdown == 'BUILDING') {
                        cfg.buildingSelectionMode = 'individual';
                        this.onBuildingIdsChange(cfg, []);
                    }
                    else {
                        this.onSingleBuildingChange(cfg, null);
                    }
                };
                StatsEditService.prototype.onQueryableChange = function (cfg, data) {
                    cfg.drilldown = data;
                    this.generateDefaultTitle(cfg);
                };
                StatsEditService.prototype.onShowTitleChange = function (cfg) {
                    cfg.hideTitle = !cfg.view.showTitle;
                    this.generateDefaultTitle(cfg);
                };
                StatsEditService.prototype.onIsCustomTitleChange = function (cfg) {
                    this.generateDefaultTitle(cfg);
                };
                StatsEditService.prototype.onMeasuresChange = function (cfg) {
                    cfg.unit = this.DashboardOptionsService.getUnitByEnumName(cfg.series[0].measure);
                    this.generateDefaultTitle(cfg);
                };
                StatsEditService.prototype.onStatisticChange = function (cfg) {
                    this.generateDefaultTitle(cfg);
                };
                StatsEditService.prototype.getDrilldownOptions = function (cfg) {
                    var measure = cfg.series[0].measure;
                    var breakdown = cfg.breakdown;
                    var buildingId = cfg.view.buildingId;
                    var breakdownOptions = cfg.options.series[breakdown][buildingId];
                    var unit = this.DashboardOptionsService.getUnitByEnumName(measure);
                    var drilldownOptions = _.filter(breakdownOptions, function (drilldown) {
                        return _.includes(drilldown.metrics, unit.serviceType);
                    });
                    return drilldownOptions;
                };
                StatsEditService.prototype.buildCalculationOptions = function (cfg) {
                    cfg.options.calculations = this.getCalculationOptions(cfg);
                    if (cfg.calculationValue && !_.find(cfg.options.calculations, function (c) { return c.value == cfg.calculationValue.value; })) {
                        cfg.calculationValue = _.last(cfg.options.calculations);
                    }
                };
                StatsEditService.prototype.getCalculationOptions = function (cfg) {
                    if (cfg.buildingIds && cfg.buildingIds.length == 1) {
                        var building = _.find(cfg.options.buildings, function (b) { return b.id == cfg.buildingIds[0]; });
                        if (building) {
                            var options = [];
                            if (building.size) {
                                options.push({
                                    display: "" + this.$translate.instant('dashboard.common.widgets.stats.Per Square Feet'), value: building.size
                                });
                            }
                            if (building.occupantsCount) {
                                options.push({
                                    display: "" + this.$translate.instant('dashboard.common.widgets.stats.Per Number of Occupants'), value: building.occupantsCount
                                });
                            }
                            options.push({ display: "" + this.$translate.instant('dashboard.common.widgets.stats.No Calculation'), value: 0 });
                            return options;
                        }
                    }
                    return [{ display: "" + this.$translate.instant('dashboard.common.widgets.stats.No Calculation'), value: 0 }];
                };
                StatsEditService.prototype.getMeasureUnit = function (measure) {
                    var unit = this.DashboardOptionsService.getUnitByEnumName(measure);
                    if (unit) {
                        return unit.unit;
                    }
                    return '';
                };
                StatsEditService.prototype.generateDefaultTitle = function (cfg) {
                    if (cfg.isCustomTitle) {
                        return;
                    }
                    var interval = this.getIntervalDescription(cfg.preset);
                    var statistic = cfg.statistic ? textural(cfg.statistic.display).format('capitalizehuman') : '';
                    var unit = this.getMeasureUnit(cfg.series[0].measure);
                    var timePeriod = this.$translate.instant('timeperiod_case3.' + textural(cfg.preset).format('capitalizehuman'));
                    var title = this.$translate.instant('dashboard.common.widgets.stats.' + statistic) + " " + unit + " " + interval + " - " + timePeriod;
                    if (cfg.breakdown == 'building') {
                        if (cfg.buildingSelectionMode == 'group') {
                            var group = _.find(cfg.options.buildingGroups, function (bg) { return bg.id == cfg.buildingGroupId; });
                            if (group) {
                                title = group.name + " - " + title;
                            }
                        }
                        else if (cfg.buildingIds && cfg.buildingIds.length == 1) {
                            var buildingId_1 = cfg.buildingIds[0];
                            var building = _.find(cfg.options.buildings, function (b) { return b.id == buildingId_1; });
                            if (building && building.name) {
                                title = building.name + " - " + title;
                            }
                        }
                    }
                    if (cfg.drilldown) {
                        var queryableName = cfg.drilldown.name;
                        if (queryableName) {
                            title = queryableName + " - " + title;
                        }
                    }
                    cfg.title = title;
                };
                StatsEditService.prototype.getIntervalDescription = function (preset) {
                    if (!preset) {
                        return '';
                    }
                    var intervalText = '';
                    var periodDetails = TimePresets.getPresetDetails(preset);
                    if (periodDetails) {
                        switch (periodDetails.interval) {
                            case '1m':
                                intervalText = this.$translate.instant('dashboard.common.widgets.stats.1 minute');
                                break;
                            case '1h':
                                intervalText = this.$translate.instant('dashboard.common.widgets.stats.1 hour');
                                break;
                            case '1d':
                                intervalText = this.$translate.instant('dashboard.common.widgets.stats.1 day');
                                break;
                            case '1mon':
                                intervalText = this.$translate.instant('dashboard.common.widgets.stats.1 month');
                                break;
                            default:
                                return '';
                        }
                        if (intervalText) {
                            return this.$translate.instant('dashboard.common.widgets.tableedit.Over') + " " + intervalText + " " + this.$translate.instant('dashboard.common.widgets.tableedit.Intervals');
                        }
                    }
                    return '';
                };
                return StatsEditService;
            }(aq.services.BuildingSelectorActions));
            widgets.StatsEditService = StatsEditService;
            angular.module('aq.dashboard.widgets').service('StatsEditService', StatsEditService);
        })(widgets = dashboard.widgets || (dashboard.widgets = {}));
    })(dashboard = aq.dashboard || (aq.dashboard = {}));
})(aq || (aq = {}));
