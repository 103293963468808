namespace aq.home {
    export class HomeAppCtrl {
        public app;
        public showDescription;
        public newFrontend;
    }
    angular
    .module('home')
    .component('app', {
        templateUrl: 'app/home/directives/app/app.html',
        bindings: {
            app: '<',
            showDescription: '<',
            newFrontend: '<'
        },
        controller: HomeAppCtrl
    });
}
