namespace aq.user {
    export class RecentEnergyNotesComponent {
        private energyNotes: EnergyNote[];
        /* @ngInject */
        constructor(
            private DataStore: aq.common.DataStore,
            private RestangularV3: restangular.IService
        ){
            DataStore.getList(RestangularV3.one('energy-notes'), 'most-recent').then((result) => {
                this.energyNotes = result;
            });
        }
    }

    angular
    .module('user')
    .component('recentEnergyNotes', {
        controller: RecentEnergyNotesComponent,
        controllerAs: 'vm',
        templateUrl: 'app/user/profile/recentEnergyNotes.html'
    });
}