var aq;
(function (aq) {
    var reports;
    (function (reports) {
        var project;
        (function (project) {
            var ProjectCtrl = /** @class */ (function (_super) {
                __extends(ProjectCtrl, _super);
                /* ngInject */
                function ProjectCtrl(account, buildings, buildingGroups, buildingPersonnels, buildingOccupancy, buildingConsumptions, buildingUtilitySpend, projects, buildingStartDates, date, OptionsService, $timeout) {
                    var _this = _super.call(this, $timeout) || this;
                    _this.account = account;
                    _this.buildings = buildings;
                    _this.buildingGroups = buildingGroups;
                    _this.buildingPersonnels = buildingPersonnels;
                    _this.buildingOccupancy = buildingOccupancy;
                    _this.buildingConsumptions = buildingConsumptions;
                    _this.buildingUtilitySpend = buildingUtilitySpend;
                    _this.projects = projects;
                    _this.buildingStartDates = buildingStartDates;
                    _this.date = date;
                    _this.OptionsService = OptionsService;
                    _this.$timeout = $timeout;
                    _this.personas = {
                        'ASSET_MANAGER': 'Asset Manager',
                        'PROPERTY_MANAGER': 'Property Manager',
                        'BUILDING_ENGINEER': 'Building Engineer'
                    };
                    _this.getBuildingTimezoneFirstDateOfMonth = function (building, year, month) {
                        var yearStartString = year + "-" + (month < 10 ? 0 : '') + month + "-01T00:00:00Z";
                        var offsetStart = moment(yearStartString).tz(building.timeZoneId).utcOffset();
                        var start = moment(yearStartString).tz(building.timeZoneId).add(-offsetStart, 'minutes').format();
                        return start;
                    };
                    _this.isAnyImpactDate = _.some(_this.buildings, function (building) { return building.enticImpactDate; });
                    _this.currencyUnit = _this.OptionsService.currencyUnit();
                    _this.currentYear = moment(_this.date).format('YYYY');
                    var formattedDate = moment(_this.date).format('YYYY-MM-DD');
                    _this.accountDate = moment(formattedDate + 'T00:00:00Z').tz(_this.account.timeZoneId);
                    _this.accountDate.subtract(_this.accountDate.utcOffset(), 'minute');
                    _this.reportName = (_this.isAnyImpactDate ? '' : 'YTD ') + "Projects Summary Report through " + _this.accountDate.format('MMMM D, YYYY');
                    _this.initReportItems();
                    _this.initBuildingGroupAndAccountSummary();
                    _this.notifyDocumentReady();
                    return _this;
                }
                ProjectCtrl.prototype.initReportItems = function () {
                    var _this = this;
                    var statusesOrder = {
                        'Declined': 0,
                        'Needs Acceptance': 1,
                        'Backlog': 2,
                        'In Progress': 3,
                        'Completed': 4
                    };
                    this.data = [];
                    this.groupData = [];
                    _.each(this.buildings, function (building) {
                        var buildingProjects = _this.projects[building.id] || [];
                        var pastSpending = _this.getPastBuildingSpend(building.id);
                        _.each(buildingProjects, function (item) {
                            item.dateStartView = (item.startDate && moment(item.startDate).format('MM/DD/YYYY')) || null;
                            item.impactClass = _this.getImpactClass(item.impact);
                        });
                        var buildingReportItem = {
                            building: _this.getBuildingViewItem(building),
                            projectItems: buildingProjects.sort(function (a, b) {
                                if (a.status == 'Completed' && b.status != 'Completed' || a.status != 'Completed' && b.status == 'Completed') {
                                    return statusesOrder[a.status] - statusesOrder[b.status];
                                }
                                else if (a.priority != b.priority) {
                                    return b.priority - a.priority;
                                }
                                else if (a.status != b.status) {
                                    return statusesOrder[a.status] - statusesOrder[b.status];
                                }
                                else {
                                    return moment(a.startDate).valueOf() - moment(b.startDate).valueOf();
                                }
                            }),
                            summary: _this.getBuildingProjectStatSummary(buildingProjects)
                        };
                        var openStatuses = ['Declined', 'Needs Acceptance', 'Backlog', 'In Progress'];
                        _.each(buildingReportItem.projectItems, function (item, index) {
                            if (index + 1 < buildingReportItem.projectItems.length) {
                                var next_1 = buildingReportItem.projectItems[index + 1];
                                var currentStatusIsOpen = _.find(openStatuses, function (status) { return status == item.status; });
                                var nextStatusIsClosed = !_.find(openStatuses, function (status) { return status == next_1.status; });
                                if (currentStatusIsOpen && nextStatusIsClosed) {
                                    item.lastOpen = true;
                                }
                            }
                        });
                        buildingReportItem.summary.consumption = _this.buildingConsumptions[building.id] * building.size || 0;
                        buildingReportItem.summary.currentSpend = _this.getCurrentBuildingSpend(building.id);
                        buildingReportItem.summary.pastSpend = pastSpending;
                        buildingReportItem.summary.isBuildingDataMissing = false;
                        _this.data.push(buildingReportItem);
                        var buildingGroupItem = _this.getOrCreateBuildingGroupItem(building);
                        buildingGroupItem.groupBuildings.push(buildingReportItem);
                    });
                };
                ProjectCtrl.prototype.getImpactClass = function (value) {
                    var impactClasses = {
                        1: 'Low',
                        2: 'Medium',
                        3: 'High'
                    };
                    if (!value || !impactClasses[value]) {
                        return 'Undefined';
                    }
                    return impactClasses[value];
                };
                ProjectCtrl.prototype.getCurrentBuildingSpend = function (buildingId) {
                    if (this.buildingUtilitySpend[buildingId]) {
                        return this.buildingUtilitySpend[buildingId][1];
                    }
                    var month = moment(this.date).month() + 1;
                    var building = _.find(this.buildings, function (b) { return b.id == buildingId; });
                    var fiscalStartMonth = building && building.fiscalStartMonth ? building.fiscalStartMonth : 1;
                    var n = fiscalStartMonth > month
                        ? 12 - (fiscalStartMonth - month) + 1
                        : month - fiscalStartMonth + 1;
                    return Math.round(n * 1000000 / 12);
                };
                ProjectCtrl.prototype.getPastBuildingSpend = function (buildingId) {
                    return this.buildingUtilitySpend[buildingId] ? this.buildingUtilitySpend[buildingId][0] : 1000000;
                };
                ProjectCtrl.prototype.initBuildingGroupAndAccountSummary = function () {
                    var _this = this;
                    _.each(this.groupData, function (group) {
                        group.summary.inProgressCount = _.sumBy(group.groupBuildings, function (item) { return item.summary.inProgressCount; });
                        group.summary.declinedCount = _.sumBy(group.groupBuildings, function (item) { return item.summary.declinedCount; });
                        group.summary.backlogCount = _.sumBy(group.groupBuildings, function (item) { return item.summary.backlogCount; });
                        group.summary.needsAcceptanceCount = _.sumBy(group.groupBuildings, function (item) { return item.summary.needsAcceptanceCount; });
                        group.summary.completedCount = _.sumBy(group.groupBuildings, function (item) { return item.summary.completedCount; });
                        group.summary.unresolvedSaving = _.sumBy(group.groupBuildings, function (item) { return item.summary.unresolvedSaving; });
                        group.summary.resolvedSaving = _.sumBy(group.groupBuildings, function (item) { return item.summary.resolvedSaving; });
                        group.summary.currentSpend = _.sumBy(group.groupBuildings, function (item) { return item.summary.currentSpend; });
                        group.summary.pastSpend = _.sumBy(group.groupBuildings, function (item) { return item.summary.pastSpend; });
                        group.summary.consumption = _.sumBy(group.groupBuildings, function (item) { return item.summary.consumption; });
                        group.summary.sqft = _.sumBy(group.groupBuildings, function (item) { return item.building.sqft || 0; });
                        group.summary.isBuildingDataMissing = false;
                        _this.setResolutionPercent(group.summary);
                        _this.setChartData(group.summary);
                    });
                    this.summaryGroupData = this.getEmptyDataSummary();
                    this.summaryGroupData.inProgressCount = _.sumBy(this.groupData, function (item) { return item.summary.inProgressCount; });
                    this.summaryGroupData.declinedCount = _.sumBy(this.groupData, function (item) { return item.summary.declinedCount; });
                    this.summaryGroupData.backlogCount = _.sumBy(this.groupData, function (item) { return item.summary.backlogCount; });
                    this.summaryGroupData.needsAcceptanceCount = _.sumBy(this.groupData, function (item) { return item.summary.needsAcceptanceCount; });
                    this.summaryGroupData.completedCount = _.sumBy(this.groupData, function (item) { return item.summary.completedCount; });
                    this.summaryGroupData.unresolvedSaving = _.sumBy(this.groupData, function (item) { return item.summary.unresolvedSaving; });
                    this.summaryGroupData.resolvedSaving = _.sumBy(this.groupData, function (item) { return item.summary.resolvedSaving; });
                    this.summaryGroupData.currentSpend = _.sumBy(this.groupData, function (item) { return item.summary.currentSpend; });
                    this.summaryGroupData.pastSpend = _.sumBy(this.groupData, function (item) { return item.summary.pastSpend; });
                    this.summaryGroupData.consumption = _.sumBy(this.groupData, function (item) { return item.summary.consumption; });
                    this.summaryGroupData.sqft = _.sumBy(this.groupData, function (item) { return item.summary.sqft; });
                    this.summaryGroupData.isBuildingDataMissing = false;
                    this.setResolutionPercent(this.summaryGroupData);
                };
                ProjectCtrl.prototype.getBuildingViewItem = function (building) {
                    var buildingState = building.state ? building.state.replace('District of Columbia', '') : '';
                    var buildingPersonnel = this.getBuildingPersonnel(building);
                    return {
                        id: building.id,
                        name: building.name,
                        imageUrl: building.imageUrl,
                        streetAddress: building.address,
                        cityAddress: building.city + ", " + buildingState + " " + building.zipCode,
                        personnel: buildingPersonnel,
                        fiscalStartMonth: building.fiscalStartMonth,
                        sqft: building.size,
                        occupancy: this.buildingOccupancy[building.id] ? this.buildingOccupancy[building.id] + "%" : '',
                        reportDateView: this.buildingStartDates[building.id].format('M/D/YY') + " - " + this.accountDate.format('M/D/YY')
                    };
                };
                ProjectCtrl.prototype.getBuildingProjectStatSummary = function (projects) {
                    var openStatuses = ['Declined', 'Needs Acceptance', 'Backlog', 'In Progress'];
                    var summary = {
                        inProgressCount: _.filter(projects, function (p) { return p.status == 'In Progress'; }).length,
                        backlogCount: _.filter(projects, function (p) { return p.status == 'Backlog'; }).length,
                        declinedCount: _.filter(projects, function (p) { return p.status == 'Declined'; }).length,
                        needsAcceptanceCount: _.filter(projects, function (p) { return p.status == 'Needs Acceptance'; }).length,
                        completedCount: _.filter(projects, function (p) { return p.status == 'Completed'; }).length,
                        resolutionPercent: null,
                        unresolvedSaving: _.sumBy(projects, function (p) { return _.find(openStatuses, function (item) { return item == p.status; }) ? p.estimatedSavings || 0 : 0; }),
                        resolvedSaving: _.sumBy(projects, function (p) { return !_.find(openStatuses, function (item) { return item == p.status; }) ? p.estimatedSavings || 0 : 0; }),
                        currentSpend: 0,
                        pastSpend: 0,
                        consumption: 0,
                        sqft: 0,
                        isIncomplete: false,
                        isBuildingDataMissing: false,
                        indicatorClass: null,
                        showResolutionPercent: null
                    };
                    this.setResolutionPercent(summary);
                    this.setChartData(summary);
                    return summary;
                };
                ProjectCtrl.prototype.setResolutionPercent = function (summary) {
                    var openCount = summary.declinedCount + summary.backlogCount + summary.needsAcceptanceCount + summary.inProgressCount;
                    summary.resolutionPercent = openCount + summary.completedCount > 0
                        ? Math.round(summary.completedCount * 100 / (openCount + summary.completedCount))
                        : 0;
                    summary.showResolutionPercent = summary.declinedCount + summary.backlogCount + summary.needsAcceptanceCount + summary.inProgressCount + summary.completedCount > 0;
                    if (summary.resolutionPercent < 75) {
                        summary.indicatorClass = 'bad';
                    }
                    else if (summary.resolutionPercent > 90) {
                        summary.indicatorClass = 'good';
                    }
                    else {
                        summary.indicatorClass = 'ok';
                    }
                };
                ProjectCtrl.prototype.getStatusColor = function (statusName) {
                    switch (statusName) {
                        case 'Draft':
                            return '#FC7B33';
                        case 'Needs Acceptance':
                            return '#A21621';
                        case 'Backlog':
                            return '#533A7B';
                        case 'In Progress':
                            return '#1E59AE';
                        case 'Completed':
                            return '#488A49';
                        default:
                            return '#3f3f3f';
                    }
                };
                ;
                ProjectCtrl.prototype.setChartData = function (summary) {
                    summary.chartData = {
                        resolution: {
                            data: [
                                {
                                    name: 'Declined',
                                    value: summary.declinedCount,
                                    color: this.getStatusColor('Declined')
                                },
                                {
                                    name: 'Needs Acceptance',
                                    value: summary.needsAcceptanceCount,
                                    color: this.getStatusColor('Needs Acceptance')
                                },
                                {
                                    name: 'Backlog',
                                    value: summary.backlogCount,
                                    color: this.getStatusColor('Backlog')
                                },
                                {
                                    name: 'In Progress',
                                    value: summary.inProgressCount,
                                    color: this.getStatusColor('In Progress')
                                },
                                {
                                    name: 'Completed',
                                    value: summary.completedCount,
                                    color: this.getStatusColor('Completed')
                                }
                            ],
                            options: {
                                width: 4 / 12 * 700,
                                height: 120,
                                categories: ['Declined', 'Needs Acceptance', 'Backlog', 'In Progress', 'Completed']
                            }
                        }
                    };
                };
                ProjectCtrl.prototype.getBuildingPersonnel = function (building) {
                    var data = _.find(this.buildingPersonnels, { buildingId: building.id });
                    if (data && data.personnel && data.personnel.length > 0) {
                        _.each(data.personnel, function (item) {
                            if (!item.email) {
                                return;
                            }
                            var emailSplit = item.email.split('@');
                            if (emailSplit.length != 2) {
                                return;
                            }
                            item.emailUsername = emailSplit[0];
                            item.emailDomain = '@' + emailSplit[1];
                        });
                        var sortedPersonnel_1 = [];
                        _.each(this.personas, function (persona, key) {
                            var nextPerson = _.find(data.personnel, function (item) { return item.persona == key; });
                            if (nextPerson) {
                                sortedPersonnel_1.push(nextPerson);
                            }
                            else {
                                sortedPersonnel_1.push({
                                    persona: key,
                                    name: '-'
                                });
                            }
                        });
                        return sortedPersonnel_1;
                    }
                    else {
                        return [];
                    }
                };
                ProjectCtrl.prototype.getOrCreateBuildingGroupItem = function (building) {
                    var buildingGroupItem = _.find(this.groupData, function (group) { return building.buildingGroup != null && group.groupId == building.buildingGroup
                        || building.buildingGroup == null && group.groupId == 0; });
                    if (!buildingGroupItem) {
                        var buildingGroup = _.find(this.buildingGroups, function (bg) { return bg.id == building.buildingGroup; });
                        buildingGroupItem = {
                            groupId: buildingGroup ? buildingGroup.id : 0,
                            groupName: buildingGroup ? buildingGroup.name : 'Other',
                            groupBuildings: [],
                            summary: this.getEmptyDataSummary()
                        };
                        if (buildingGroup && buildingGroup.isIncomplete) {
                            buildingGroupItem.summary.isIncomplete = true;
                        }
                        this.groupData.push(buildingGroupItem);
                    }
                    return buildingGroupItem;
                };
                ProjectCtrl.prototype.getGlobalReportStartDate = function () {
                    var _this = this;
                    var queryDate = moment(this.date);
                    var minDate = null;
                    _.each(this.buildings, function (building) {
                        var fiscalYearStart = _this.getFiscalYearStart(building, queryDate);
                        var tsFiscalYearStart = moment(fiscalYearStart);
                        if (minDate == null || tsFiscalYearStart.isBefore(minDate)) {
                            minDate = tsFiscalYearStart;
                        }
                    });
                    return minDate;
                };
                ProjectCtrl.prototype.getFiscalYearStart = function (building, currentDate) {
                    var currentMonth = currentDate.month() + 1;
                    var startMonth = building.fiscalStartMonth ? building.fiscalStartMonth : 1;
                    var startYear = currentDate.year();
                    if (currentMonth <= startMonth) {
                        startYear--;
                    }
                    return this.getBuildingTimezoneFirstDateOfMonth(building, startYear, startMonth);
                };
                ProjectCtrl.prototype.getEmptyDataSummary = function () {
                    var item = {
                        inProgressCount: 0,
                        backlogCount: 0,
                        completedCount: 0,
                        declinedCount: 0,
                        needsAcceptanceCount: 0,
                        resolutionPercent: null,
                        unresolvedSaving: 0,
                        resolvedSaving: 0,
                        currentSpend: 0,
                        pastSpend: 0,
                        consumption: 0,
                        sqft: 0,
                        isIncomplete: false,
                        isBuildingDataMissing: false,
                        indicatorClass: null,
                        showResolutionPercent: null
                    };
                    return item;
                };
                return ProjectCtrl;
            }(reports.BaseDocraptorReportCtrl));
            project.ProjectCtrl = ProjectCtrl;
            angular
                .module('aq.reports')
                .controller('ProjectCtrl', ProjectCtrl);
        })(project = reports.project || (reports.project = {}));
    })(reports = aq.reports || (aq.reports = {}));
})(aq || (aq = {}));
