namespace aq.components {
    import DeviceImage = aq.common.models.DeviceImage;

    export class DeviceGalleryComponent {
        device: aq.common.models.DeviceElement;
        isAdmin: boolean;
        canEdit: boolean;
        public onChange: Function;
        private static readonly BUCKET = 'com-aquicore-web';

        /* @ngInject */
        constructor(
            private Errors,
            private Messages,
            public $mdDialog: ng.material.IDialogService,
            private DeviceService: aq.services.DeviceService,
            private Auth: aq.services.Auth,
            private FilePicker,
            private Restangular
        ) {
            this.isAdmin = this.Auth.check({access: 'FULL_ACCESS'});
            this.canEdit = this.Auth.check({access: 'EDIT'});
        }
        getTimestamp(image) {
            if(image.timestamp) {
                return moment(image.timestamp).format("l LT");
            } else {
                return "Upload date N/A";
            }
        }
        addImage() {
            this.FilePicker.pickAndStore({mimetype: 'image/*', services: ['IMAGE_SEARCH', 'COMPUTER']}, {location: 'S3', access: 'public'})
            .then(result => {
                const deviceImage: DeviceImage = {url: this.FilePicker.getS3Url(result[0].key)};
                const updatedDevice = this.Restangular.copy(this.device);
                if (this.device.images && this.device.images.length) {
                    updatedDevice.images.push(deviceImage)
                } else {
                    updatedDevice.images = [deviceImage];
                }
                this.DeviceService.save(updatedDevice).then((updated) => {
                    this.device = updated;
                    this.onDeviceChange();
                })
            }, result => {
                if (result.code != 101) this.Messages.error("Error uploading file");
            });
        }
        removeImage(image): any {
            const confirm = this.$mdDialog.confirm()
                .title('Are you sure you want to delete this image?')
                .ariaLabel('Delete Image')
                .ok('Delete')
                .cancel('Cancel');
            this.$mdDialog.show(confirm).then(() => {
                const index = this.device.images.indexOf(image, 0);
                if (index > -1) {
                   this.device.images.splice(index, 1);
                }
            }).then(() => {
                this.DeviceService.save(this.device);
            });
        }
        private onDeviceChange() {
            this.onChange({
                $event: {
                    device: this.device
                }
            });
        }
        $onChanges(changes) {}
    }

    angular.module('deviceManagement')
        .component('deviceGallery', {
            templateUrl: 'app/deviceManagement/device/components/gallery/deviceGallery.html',
            controller: aq.components.DeviceGalleryComponent,
            controllerAs: 'vm',
            bindings: {
                device: '<',
                onChange: '&'
            }
        });
}
