angular.module('aq.ui').directive('aqCrudMasterChild', () => {
    return {
        restrict: 'E',
        replace: true,
        transclude: {
            'table': '?aqCrudTable',
            'list-row': '?aqCrudListRow'
        },
        templateUrl: 'app/common/directives/crud/masterChild.html',
        scope: {
            items: '=',
            onCreateFn: '&',
            noActions: '@',
            entityLabel: '@',
            onEditFn: '&',
            onDeleteFn: '&',
            orderBy: '@',
            useTable: '@'
        }
    };
});