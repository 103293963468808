angular.module('aq-main').run(function($httpBackend) {
    const regexp = /^\/api\/v1\/accounts\/.*\/buildings\/.*\/baseloadV2/;
    const baseLoadStats = {
        timestamps: [],
        values: [200, 400, 375, 400, 266]
    };
    MockContext('baseloadstatsv2', () => {
        $httpBackend.whenGET(regexp).respond((method, url, data) => {
            const params: any = MockHelpers.getParamsAsObject(url);
            const startDate = moment(params.start);
            const endDate = moment(params.end);
            const timestamps = [];
            if (params.interval === '1mon') {
                let numMonths = endDate.diff(startDate, 'months');
                for (let i = 0; i < numMonths; i++) {
                    timestamps.push(moment(startDate).add(i, 'months').valueOf());
                    if (baseLoadStats.values[i]) {
                        baseLoadStats.values[i] += 5800;
                    }
                }
            } else if (params.interval === '1d') {
                let numDays = endDate.diff(startDate, 'days');
                for (let i = 0; i < numDays; i++) {
                    timestamps.push(moment(startDate).add(i, 'days').valueOf());
                }
            }
            baseLoadStats.timestamps = timestamps;
            return [200, baseLoadStats, {}];
        });
    });
});