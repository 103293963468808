angular.module('aq.admin.statistics.schedules').controller('AccountsCtrl',
    function ($scope, Restangular, $state, $stateParams) {
    
    Restangular.all('accounts').getList({onlyCalendars: true}).then(function(accounts) {
        $scope.accounts = accounts;
    });
    
    $scope.$watch('selectedAccount', function(account) {
        if (!account) return;
        
        if (!$state.params.accountId) {
            $state.transitionTo('aq.admin.statistics.schedules.buildings', {accountId: account});
        }
    });
    
    $scope.$watch('$state.params.accountId', function(accountId) {
       if (accountId) {
           $scope.selectedAccount = accountId;
       } 
    });
});