angular
    .module('aq.reports')
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.reports.project', {
                url: '/summary/projectSummary',
                controller: 'ProjectCtrl',
                controllerAs: 'vm',
                templateUrl: 'app/reports/summary/project/project.html',
                resolve: {
                    options(OptionsService, account): any {
                        return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
                    },
                    metrics(Restangular, account: aq.common.models.Account, waitForAuthToken): ng.IPromise<any> {
                        return Restangular.one('accounts', account.id).customGET('queryRealUnits');
                    },
                    buildings(waitForAuthToken, RestangularV3: restangular.IService): ng.IPromise<any> {
                        return RestangularV3.all('buildings').getList();
                    },
                    buildingGroups(
                        RestangularV3: restangular.IService,
                        account: aq.common.models.Account,
                        waitForAuthToken
                    ): restangular.ICollectionPromise<any> {
                        return RestangularV3.all('building-groups').getList();
                    },
                    buildingPersonnels(buildings: aq.common.models.Building[], $q: ng.IQService) {
                        const promises = _.map(buildings, (building: aq.common.models.Building) => {
                            return building.all('personnel').getList()
                                .then((result) => {
                                    return {
                                        buildingId: building.id,
                                        personnel: result
                                    };
                                }, (error) => {
                                    return {
                                        buildingId: building.id,
                                        personnel: []
                                    };
                                });
                        });
                        return $q.all(promises);
                    },
                    buildingOccupancy(
                        buildings: aq.common.models.Building[],
                        $q: ng.IQService,
                        RestangularV3: restangular.IService,
                        waitForAuthToken
                    ): ng.IPromise<{}> {
                        const buildingOccupancyMap = {};
                        const promises = _.map(buildings, (building) => {
                            return RestangularV3.one('building', building.id)
                                .all('buildingOccupancy')
                                .getList()
                                .then((occupancyItems) => {
                                    if (occupancyItems && occupancyItems.length > 0) {
                                        const latest = _.last(_.sortBy(occupancyItems, (item) => item.startDate));
                                        buildingOccupancyMap[building.id] = latest.occupancyPercent;
                                    }
                                });
                        });
                        return $q.all(promises).then(() => {
                            return buildingOccupancyMap;
                        });
                    },
                    startDate($location: ng.ILocationService): number {
                        if ($location.search().startDate) {
                            return parseInt($location.search().startDate);
                        } else {
                            return null;
                        }
                    },
                    buildingStartDates(buildings: aq.common.models.Building[], date: string) {
                        const buildingStartDateMap = {};
                        _.each(buildings, (building: aq.common.models.Building) => {
                            let startDate = null;
                            if (building.enticImpactDate) {
                                startDate = moment(building.enticImpactDate, 'YYYY-MM-DD');
                            } else {
                                const fiscalStartMonth = building.fiscalStartMonth || 1;
                                const endDate = moment(date);
                                const currentMonth = parseInt(endDate.format('M'));
                                const currentYear = parseInt(endDate.format('YYYY'));
                                startDate = fiscalStartMonth > currentMonth
                                    ? moment(`${fiscalStartMonth} ${currentYear - 1}`, 'M YYYY')
                                    : moment(`${fiscalStartMonth} ${currentYear}`, 'M YYYY');
                            }
                            buildingStartDateMap[building.id] = startDate;
                        });
                        return buildingStartDateMap;
                    },
                    projects(
                        ProjectServiceV3: aq.services.ProjectServiceV3,
                        $q: ng.IQService,
                        buildings: aq.common.models.Building[],
                        buildingStartDates: { [id: string]: moment.Moment },
                        waitForAuthToken,
                        Auth: aq.services.Auth
                    ) {
                        const Priority = aq.models.projects.ProjectPriority;
                        this.priorityMap = {
                            [Priority.HIGH]: 3,
                            [Priority.MEDIUM]: 2,
                            [Priority.LOW]: 1
                        };
                        const results: ng.IPromise<aq.reports.project.BuildingsProjects>[] = _.map(buildings, (building: aq.common.models.Building) => {
                            const buildingId = parseInt(building.id);
                            const buildingStartDate = buildingStartDates[building.id];
                            let first = false;
                            let statuses = null;
                            return ProjectServiceV3.getProjects(buildingId).then(projectResponse => {
                                if (!first) {
                                    first = true;
                                    statuses = projectResponse.statuses;
                                }
                                const selectedStatuses = _.filter(statuses, (status: aq.models.projects.ProjectStatus) => {
                                    return _.includes(['Declined', 'Needs Acceptance', 'Backlog', 'In Progress', 'Completed'], status.name);
                                });
                                const projects = _.chain(projectResponse.projects)
                                    .filter((project: aq.models.projects.Project) => {
                                        return _.find(selectedStatuses, (status: aq.models.projects.ProjectStatus) => status.id == project.status.id);
                                    })
                                    .filter((project: aq.models.projects.Project) => {
                                        if (project.status.name == 'Completed') {
                                            const completed = moment(project.completedOn);
                                            return completed.isAfter(buildingStartDate, 'day');
                                        }
                                        if (!project.issue || !project.issue.identifiedDate) {
                                            return false;
                                        }
                                        const opened = moment(project.issue.identifiedDate);
                                        return opened.isAfter(buildingStartDate, 'day');
                                    })
                                    .value();
                                const projectItems = projects.map((p: aq.models.projects.Project): aq.reports.project.BuildingsProjectsItem => {
                                    const systemType = p.systemTypes && p.systemTypes[0] || null;
                                    const assignee: aq.common.models.User = _.find(this.users, (user) => user.id == p.assignee);
                                    const isCompleted = p.status.name === 'Completed';
                                    const isProjectEstimationEnabled = Auth.hasFunctionality(PROJECT_ESTIMATION);
                                    const estimatedSavings = ProjectServiceV3.getImpactValue(p, isProjectEstimationEnabled, true, 'USD').projectImpact
                                    const project: aq.reports.project.BuildingsProjectsItem = {
                                        id: p.code,
                                        name: p.title,
                                        priority: this.priorityMap[p.priority],
                                        status: p.status.name,
                                        startDate: p.issue && p.issue.identifiedDate,
                                        estimatedSavings
                                    };
                                    return project;
                                });
                                const buildingProjects: aq.reports.project.BuildingsProjects = {
                                    building: buildingId,
                                    prescriptions: projectItems
                                }
                                return buildingProjects;
                            });
                        });

                        return $q.all(results).then((responses: aq.reports.project.BuildingsProjects[]) => {
                            const buildingPrescriptionsMap = {};
                            _.each(responses, (response: aq.reports.project.BuildingsProjects) => {
                                buildingPrescriptionsMap[response.building] = response.prescriptions;
                            });
                            return buildingPrescriptionsMap;
                        });
                    },
                    buildingConsumptions(
                        account,
                        metrics,
                        $filter: ng.IFilterService,
                        date: number,
                        Restangular: restangular.IService,
                        $q: ng.IQService,
                        buildings: aq.common.models.Building[],
                        waitForAuthToken
                    ) {
                        const promises = _.map(buildings, (building: aq.common.models.Building) => {
                            const fiscalStartMonth = building.fiscalStartMonth || 1;
                            const endDate = moment(date);
                            const currentMonth = parseInt(endDate.format('M'));
                            const currentYear = parseInt(endDate.format('YYYY'));
                            const startDate = fiscalStartMonth > currentMonth
                                ? moment(`${fiscalStartMonth} ${currentYear - 1}`, 'M YYYY')
                                : moment(`${fiscalStartMonth} ${currentYear}`, 'M YYYY');
                            const metric = _.find(metrics, { unit: 'kWh' });
                            const filterKWH = $filter<Function>('toUnit');
                            // TODO: enable if/when consumption data is required on report
                            // return Restangular.one('accounts', account.id)$q.when({});
                            // .one('buildings', building.id)$q.when({});
                            // .customGET('metrics', {
                            //     start: startDate.format('YYYY-MM-DDT00:00:00Z'),$q.when({});
                            //     end: endDate.format('YYYY-MM-DDT00:00:00Z'),$q.when({});
                            //     interval: '1mon',$q.when({});
                            //     measure: 'ENERGY'$q.when({});
                            // })
                            return $q.when(null)
                                .then((data) => {
                                    let total = 0;
                                    if (data && data.values && data.values.eui != null) {
                                        total = data.values.eui;
                                    }
                                    if (total == 0) {
                                        // mock data
                                        total = Math.round(Math.random() * 50 + 50);
                                    }
                                    return {
                                        buildingId: building.id,
                                        eui: total
                                    };
                                })
                                .catch(() => {
                                    return {
                                        buildingId: building.id,
                                        eui: 0
                                    };
                                });
                        });
                        const response = {};
                        return $q.all(promises).then((responses: any[]) => {
                            _.each(responses, (item) => {
                                response[item.buildingId] = item.eui;
                            });
                            return response;
                        });
                    },
                    buildingUtilitySpend() {
                        return {};
                    }
                }
            })
    });
