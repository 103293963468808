var aq;
(function (aq) {
    var energyInsights;
    (function (energyInsights) {
        var BaseloadService = /** @class */ (function (_super) {
            __extends(BaseloadService, _super);
            /* @ngInject */
            function BaseloadService($q, EnergyInsightsDataService, $filter, $translate) {
                var _this = _super.call(this) || this;
                _this.$q = $q;
                _this.EnergyInsightsDataService = EnergyInsightsDataService;
                _this.$filter = $filter;
                _this.$translate = $translate;
                return _this;
            }
            BaseloadService.prototype.getSimpleTargetModel = function (building, measure, periodSearch) {
                var targetModel = new energyInsights.SimpleTargetModel();
                targetModel.title = 'Baseload';
                targetModel.when = this.$translate.instant('insight.when.Baseload');
                return targetModel;
            };
            BaseloadService.prototype.getTargetModel = function (building, measure, periodSearch) {
                var _this = this;
                var params = {
                    start: periodSearch.start,
                    end: periodSearch.end,
                    interval: periodSearch.interval
                };
                return this.EnergyInsightsDataService.queryBaseloadStats(building, params).then(function (result) {
                    var baseloadStats = result.months;
                    return _this.calculateTargetModel(building, baseloadStats, periodSearch);
                });
            };
            BaseloadService.prototype.getGraphModel = function (building, measure, timePeriod) {
                return null;
            };
            BaseloadService.prototype.getTableModel = function (building, measure, periodSearch) {
                return null;
            };
            // just to please contract
            BaseloadService.prototype.formatTableModel = function () {
                throw new TypeError('This is a stub method');
            };
            // -----------------
            BaseloadService.prototype.calculateTargetModel = function (building, baseloadStats, periodSearch) {
                var today = moment().tz(building.timeZoneId).format('MMM Do');
                var numberOfDaysInMonth = moment().tz(building.timeZoneId).diff(moment().tz(building.timeZoneId).startOf('month'), 'days');
                var percOfTime = this.getPercent(numberOfDaysInMonth, moment().tz(building.timeZoneId).daysInMonth());
                var targetModel = new energyInsights.TargetModel();
                targetModel.question = "How often is " + building.name + "'s baseline usage on target?";
                targetModel.unit = 'days';
                targetModel.title = 'Baseload';
                targetModel.when = this.$translate.instant('insight.when.Baseload');
                targetModel.answer = this.getAnswer(baseloadStats, building);
                targetModel.formattedAnswer = targetModel.answer + " " + this.$translate.instant('insight.answer.Days');
                targetModel.target = this.getTarget(baseloadStats, building);
                targetModel.showTarget = !_.isNull(targetModel.target);
                targetModel.showDonut = !_.isNull(targetModel.target);
                targetModel.formattedTarget = this.$translate.instant('insight.target.Target') + " " + this.$filter('number')(targetModel.target, '0') + " this.$translate.instant('insight.answer.Days')";
                targetModel.percentage = this.getPercent(targetModel.answer, targetModel.target);
                targetModel.color = this.getColor(targetModel.answer, targetModel.target, targetModel.percentage, percOfTime);
                targetModel.iconColor = targetModel.color;
                targetModel.tooltip = this.$translate.instant('insight.when_tooltip.Baseload') + " <span style=\"color:" + targetModel.color + "\">\n            " + this.$filter('number')(targetModel.percentage, '0,0') + "%</span> of total days";
                targetModel.icon = this.getIcon(targetModel.answer, targetModel.target, targetModel.percentage, percOfTime);
                targetModel.timeElapsedPercentage = percOfTime;
                targetModel.timeElapsedLabel = today;
                return this.$q.when(targetModel);
            };
            BaseloadService.prototype.getAnswer = function (data, building) {
                var now = moment().tz(building.timeZoneId).startOf('day');
                var thisMonth = parseInt(moment().tz(building.timeZoneId).format('M MM'));
                var row = data[thisMonth - 1];
                return row.numGoodDays ? row.numGoodDays : 0;
            };
            BaseloadService.prototype.getTarget = function (data, building) {
                var numberOfDaysInMonth = moment().tz(building.timeZoneId).daysInMonth();
                return Math.round(numberOfDaysInMonth * (BaseloadService.TARGET_GOOD_DAYS_PERCENT / 100));
            };
            BaseloadService.TARGET_GOOD_DAYS_PERCENT = 70;
            BaseloadService.TARGET_RATIO = 50;
            return BaseloadService;
        }(energyInsights.InsightService));
        energyInsights.BaseloadService = BaseloadService;
        angular.module('energyInsights').service('BaseloadService', BaseloadService);
    })(energyInsights = aq.energyInsights || (aq.energyInsights = {}));
})(aq || (aq = {}));
