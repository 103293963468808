namespace aq.admin {
    export class AccountAdminLicenseCtrl {
        public accountLicense;
        /* @ngInject */
        constructor(
            public account,
            private licenses,
            private functionalities,
            private reports,
            private apps
        ) {
            this.accountLicense = _.find(licenses, l => l.id === this.account.license.id);
            this.account.accountAddOns = _.filter(this.account.functionalities, f => f.test === false).map((x) => x.id);
            this.account.accountTestFeatures = _.filter(this.account.functionalities, f => f.test === true).map((x) => x.id);
        }

        public updateAccount() {
            this.account.license = this.accountLicense;
        }
    }

    angular
        .module('aq.admin.accounts.accounts')
        .controller('AccountAdminLicenseCtrl', AccountAdminLicenseCtrl);
}
