angular.module('accounts').controller('BuildingGroupCtrl', function($scope, Restangular, Errors) {

    $scope.animation = null;
    $scope.newGroupName = '';

    $scope.createGroup = function() {
        $scope.animation = {enter: 'bounceInLeft', leave: 'bounceOutLeft'};
        $scope.working = true;
        var newGroup = {
            name: $scope.newGroupName,
            account: $scope.account.id
        }

        return $scope.account.post('BuildingGroups', newGroup).then(function(response) {
            var group = _.extend(response, response.model);
            $scope.account.buildingGroups.push(group);
            $scope.account.buildingGroups = _.sortBy($scope.account.buildingGroups, 'name');
        }, Errors.forPromise()).finally(function() {
            $scope.newGroupName = '';
            $scope.working = false;
        });
    }

    $scope.isDefault = function(group) {
        return $scope.account.defaultBuildingGroup == group.id;
    }

    $scope.makeDefault = function(group) {
        $scope.account.defaultBuildingGroup = group.id;
        $scope.saveAccount();
    }

    $scope.edit = function(group) {
        $scope.account.buildingGroups = _.sortBy($scope.account.buildingGroups, 'name');
        return group.put();
    }
});