namespace aq.dashboard {
    export class DashboardSelectService {
        /* @ngInject */
        constructor(
            protected AppStorageService,
            protected UserService
        ) {

        }

        public getRedirect (currentDashboardId, account: aq.common.models.Account, dashboards: Array<aq.admin.dashboards.Dashboard>) {
            if (!currentDashboardId) {
                const redirect = {
                    state: null,
                    params: null
                };
                const selected = this.AppStorageService.getStorage('dashboards', account.id);
                if (selected.dashboardId && _.find(dashboards, {id: selected.dashboardId})) {
                    redirect.state = 'aq.dashboard.configurable';
                    redirect.params = {
                        accountId: account.id,
                        dashboardId: selected.dashboardId,
                    };
                } else if (dashboards.length > 0) {
                    redirect.state = 'aq.dashboard.configurable';
                    redirect.params = {
                        accountId: account.id,
                        dashboardId: dashboards[0].id,
                    };
                } else {
                    redirect.state = 'aq.dashboard.noAvailableDashboards';
                    redirect.params = {
                        accountId: account.id
                    };
                }

                return redirect;
            } else { // Else don't need to redirect
                return null;
            }
        }
        public getRedirectFromHome () {
            return this.UserService.getUser({ inflate: 'currentProfile,currentProfile.account,currentProfile.apps' })
                .then((user) => {
                    if (user && user.currentProfile && user.currentProfile.account) {
                        return {
                            state: 'aq.dashboard.configurable',
                            params: {
                                accountId: user.currentProfile.account.id
                            }
                        };
                    } else {
                        return null;
                    }
                });
        }
    }

    angular.module('dashboard').service('DashboardSelectService', DashboardSelectService);
}
