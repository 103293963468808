namespace aq.services {

    export class ConfirmationService {

        /* @ngInject */
        constructor(private $rootScope: ng.IRootScopeService, private $mdDialog) { }

        /**
         * Display a modal to the user for entity deletion.
         *
         * This returns mdDialog.show's promise, which will resolve if the user typed
         * DELETE, else it's unresolved.
         */
        promptForDeletion(params) {
            return this.$mdDialog.show({
                controller: ['$scope', '$mdDialog', 'params', ($scope, $mdDialog, params) => {
                    $scope.params = params;
                    $scope.cancel = (): void => { $mdDialog.cancel() };
                    $scope.remove = (): void => { $mdDialog.hide()   };
                }],
                templateUrl: 'app/common/services/ConfirmationService/deleteConfirmation.html',
                clickOutsideToClose: true,
                locals: {
                    params: params,
                }
            });
        }

    }

    angular.module('aq.services').service('ConfirmationService', ConfirmationService);

}
