namespace aq.admin {
    export class AccountAdminDetailsCtrl {
        public entityActions: aq.common.models.EntityMenuAction[];
        private appRestructure;
        private backAction: aq.components.BackButtonData;
        /* @ngInject */
        constructor(
            public account,
            public $window: ng.IWindowService,
            private Messages: aq.services.Messages,
            private RestangularV3: restangular.IService,
            private $scope,
            private $mdDialog,
            private sharedBuildings,
            private functionalities,
            private reports,
            private $state: ng.ui.IStateService,
            private Auth
        ) {
            this.appRestructure = Auth.hasFunctionality('App Restructure');
            this.entityActions = [
                { label: 'Add Shared Building', cb: () => this.addBuilding(), icon: 'edit' },
                { label: 'Create Default Dashboards', cb: () => this.createDefaultDashboards(), icon: 'dashboard' },
                { label: 'Add Me to This Account', cb: () => this.inviteUser(), icon: 'edit' }
            ];
            this.backAction = {
                state: 'aq.admin.accounts.accounts'
            };
        }
        public saveAccount() {
            if (this.account.accountAddOns && this.account.accountTestFeatures) {
                const functionalityIds = this.account.accountAddOns.concat(this.account.accountTestFeatures);
                this.account.functionalities = functionalityIds.map((f) => _.find(this.functionalities, (x) => x.id === f));
            }
            this.account.accountCustomReports = this.account.accountCustomReports.map((r) => _.find(this.reports, (x) => x.id === r.id));
            return this.account.put()
                .then((account) => {
                    this.account = account;
                    this.Messages.success('Account updated successfully');
                    this.$scope.$broadcast('ACCOUNT_UPDATED', {
                        account: this.account
                    });
                }, () => {
                    this.Messages.error('Error while saving account');
                });
        }
        public deleteAccount(ev?) {
            const confirm = this.$mdDialog.confirm()
                .title('Are you sure you want to delete this account?')
                .ariaLabel('Delete Account')
                .targetEvent(ev)
                .ok('Ok')
                .cancel('Cancel');
            this.$mdDialog.show(confirm).then(() => {
                this.account.remove().then(() => {
                    (this.$window.location as any) = '/';
                }, (error) => {
                    const errorMessage = (error && error.data && error.data.message) ? ': ' + error.data.message : '';
                    this.Messages.error('Unable to delete this account' + errorMessage);
                });
            });
        }
        public getMetricsDescription() {
            const metrics = this.account.accountMetrics;
            const names: string[] = _.map(metrics, 'displayAs');
            return names.join(', ');
        }
        public createDefaultDashboards = () => {
            this.RestangularV3
                .one('accounts', this.account.id)
                .customPOST({}, 'create-default-dashboards')
                .then(() => {
                    this.Messages.success('Successfully created default dashboards');
                })
                .catch(() => {
                    this.Messages.error('Unable to create default dashboards');
                });
        }
        public addBuilding() {
            return this.$mdDialog.show({
                controller: 'AddBuildingCtrl as vm',
                templateUrl: 'app/admin/accounts/accounts/details/addBuilding.html',
                clickOutsideToClose: true,
                locals: {
                    account: this.account,
                    sharedBuildings: this.sharedBuildings
                },
                parent: angular.element(document.body)
            });
        }

        public inviteUser() {
            if (this.appRestructure) {
                return this.RestangularV3.one('accounts', this.account.id).customPOST({}, 'add-user-only')
                    .then((response) => {
                        this.Messages.success('Successfully added user');
                        this.$window.top.postMessage({
                            type: 'account-assigned',
                            accountId: this.account.id,
                        }, '*');
                    }).catch((response) => {
                        this.Messages.error('You already are a member of this account');
                    });
            }
            return this.RestangularV3.one('accounts', this.account.id).customPOST({}, 'add-user')
                .then((response) => {
                    this.Messages.success('Successfully added user');
                    this.$state.reload();
                }).catch((response) => {
                    this.Messages.error('You already are a member of this account');
                });
        }
    }

    angular
        .module('aq.admin.accounts.accounts')
        .controller('AccountAdminDetailsCtrl', AccountAdminDetailsCtrl);
}
