'use strict';

angular.module('properties.sources', ['ui.router', 'aq.auth', 'aq.linechart'])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.properties.buildings.sources', {
                url: '/:buildingId',
                template: '<ui-view></ui-view>',
                abstract: true,
                data: {
                    breadcrumb: 'Sources'
                },
                resolve: {
                    building: (DataStore, Restangular, accountId, $stateParams, waitForAuthToken) => {
                        return DataStore.get(Restangular.one('accounts', accountId).one('buildings', $stateParams.buildingId), { single: true }, false);
                    }
                }
            })
            .state('aq.properties.buildings.sources.list', {
                url: '/sources',
                templateUrl: 'app/properties/sources/main/sources.html',
                controller: 'SourcesCtrl',
                data: {
                    breadcrumb: '',
                    icon: '.aq-icons-sources'
                }
            })
            .state('aq.properties.buildings.sources.details', {
                url: '/sources/:sourceId',
                templateUrl: 'app/properties/sources/details/details.html',
                controller: 'SourceDetailsCtrl',
                data: {
                    redirectState: 'aq.properties.buildings.sources.list',
                    breadcrumb: ''
                }
            })
            .state('aq.properties.buildings.sources.details.settings', {
                url: '/settings',
                templateUrl: 'app/properties/sources/details/settings/settings.html',
                controller: 'SourceDetailsCtrl',
                data: {
                    breadcrumb: 'Settings'
                }
            })
            .state('aq.properties.buildings.sources.details.meterassignments', {
                url: '/meterassignments',
                templateUrl: 'app/properties/sources/details/meterAssignments/meterAssignments.html',
                controller: 'MeterAssignmentsCtrl',
                data: {
                    breadcrumb: 'Layout'
                }
            });
    });
