'use strict';

angular
    .module('aq.admin.meters.meters', ['ui.router'])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.admin.meters.meters', {
                url: '/meters',
                templateUrl: 'app/admin/meters/meters/main.html',
                controller: 'AdminMetersCtrl',
                data: {
                    breadcrumb: 'Data Collectors',
                    icon: '.aq-icons-collectors'
                }
            });
    });
