namespace aq.tenantbilling {

    import UnitCreationRequest = aq.tenantbilling.TenantCreationRequest;
    import LeaseCreationRequest = aq.tenantbilling.LeaseCreationRequest;
    import UnitObject = aq.tenantbilling.UnitObject;
    import ResidentCreationRequest = aq.tenantbilling.ResidentCreationRequest;

    export class BulkUploadMoveInsCtrl extends aq.common.Controllers.ModalCtrl {
        public uploadData: string;
        public result: any[];
        private previewResponse: UnitCreationRequest[];
        private leases: LeaseCreationRequest[];
        private residents: ResidentCreationRequest[];
        private fileUploaded: boolean;
        private attemptedUpload: boolean;
        private step: number = 1;
        public valid: boolean = true;
        /* @ngInject */
        constructor(
            public $mdDialog: ng.material.IDialogService,
            private $mdStepper,
            private Messages: aq.services.Messages,
            private RestangularV3: restangular.IService,
            private building: aq.common.models.Building,
            private TenantService: aq.services.TenantService
        ) {
            super({}, $mdDialog);
        }

        downloadTemplate(): void {
            const csvColumns = [
                'residentId',
                'phoneNumber',
                'email',
                'residentName',
                'unit',
                'sqft',
                'startDate',
                'endDate'
            ];
            let csvContent = 'data:text/csv;charset=utf-8,';
            csvContent += `${csvColumns}\n`;
            const fileName = `${this.building.name} - units upload`;
            const link = document.createElement('a');
            link.setAttribute('href', encodeURI(csvContent));
            link.setAttribute('download', fileName);
            link.click();
        }

        next(): void {
            this.$mdStepper('moveInBulkUploadStepper').goto(this.step++);
        }

        upload(): void {
            this.parseCSV();
            this.validateDates(this.result);
            this.fileUploaded = this.valid;
            this.attemptedUpload = true;
            if (!this.valid) {
                this.uploadData = null;
            }
        }

        parseCSV() {
            const lines = this.uploadData.split(/\r\n|\n|\r/);
            const result = [];
            const headers = lines[0].split(',');

            lines.shift();

            lines.forEach((line) => {
                if (line.trim().length === 0) {
                    return;
                }
                const obj = {};
                const splitLine = line.split(',');
                for (let j = 0; j < headers.length; j++) {
                    obj[headers[j]] = splitLine[j];
                }
                result.push(obj);
            });
            this.result = result;
        }

        generatePreview(): void {
            this.generateUnitCreationRequestObjects(this.result);
            this.generateLeaseCreationRequestObjects(this.result);
            this.generateResidentCreationRequestObjects(this.result);
            this.bulkCreate(this.previewResponse, this.leases, this.residents);
        }

        generateUnitCreationRequestObjects(json: UnitObject[]): void {
            this.previewResponse = json.map((unit: UnitObject) => {
                if (unit.email === '' || unit.residentName === '' || unit.unit === '' || unit.startDate === '') {
                    this.$mdDialog.hide();
                    this.Messages.error('Missing required fields');
                    throw new Error('Missing required fields');
                }
                const newUnit: UnitCreationRequest = {
                    ...unit,
                    name: unit.unit,
                    size: unit.sqft,
                    building: this.building.id,
                    accountNumber: null,
                    primaryContact: null,
                    address: null,
                    city: null,
                    state: null,
                    zipCode: null,
                    country: null,
                    leaseType: null,
                    email: null,
                    phoneNumber: null
                };
                return newUnit;
            });
            this.removeDuplicates();
        }

        generateLeaseCreationRequestObjects(json: UnitObject[]): void {
            this.leases = json.map((unit: UnitObject) => {
                const newLease: LeaseCreationRequest = {
                    ...unit,
                    leaseIdentifier: null,
                    startDate: unit.startDate,
                    endDate: unit.endDate,
                    active: true,
                    tenant: '',
                    unitName: unit.unit
                };
                return newLease;
            });
        }

        generateResidentCreationRequestObjects(json: UnitObject[]): void {
            this.residents = json.map((unit: UnitObject) => {
                const newResident: ResidentCreationRequest = {
                    ...unit,
                    name: unit.residentName,
                    startDate: unit.startDate,
                    unitName: unit.unit,
                    residentIdentifier: unit.residentId
                };
                return newResident;
            });
        }

        validateDates(json: UnitObject[]) {
            this.valid = true;
            json.map((unit: UnitObject) => {
                const startDate = unit.startDate;
                const endDate = unit.endDate;
                this.checkDate(startDate);
                this.checkDate(endDate);
            });
        }

        checkDate(date: string) {
            if(date != null && date != "") {
                if(!moment(date, 'YYYY-MM-DD', true).isValid()) {
                    this.valid = false;
                }
            }
        }

        removeDuplicates() {
            let unique: UnitCreationRequest[] = [];
            let uniqueUnits: string[] = [];
            this.previewResponse.forEach((unit) => {
                if (!uniqueUnits.includes(unit.name)) {
                    uniqueUnits.push(unit.name);
                    unique.push(unit);
                }
            });
            this.previewResponse = unique;
        }

        bulkCreate(tenantList, leaseList, residentList) {
            return this.TenantService.bulkCreate(tenantList).then((units) => {
                const unitsLength = this.TenantService.getResponseLength(units);
                this.TenantService.createLeases(leaseList).then((leases) => {
                    const leasesLength = this.TenantService.getResponseLength(leases);
                    this.TenantService.createResidents(residentList).then((residents) => {
                        const residentsLength = this.TenantService.getResponseLength(residents);
                        const successMessage = `Successfully created ${ unitsLength } units, ${ leasesLength } leases, and ${ residentsLength } residents`;
                        this.$mdDialog.hide();
                        this.Messages.success(successMessage)
                    }).catch((error) => {
                        this.$mdDialog.hide();
                        this.Messages.error('Error creating residents');
                    });
                }).catch((error) => {
                    this.$mdDialog.hide();
                    this.Messages.error('Error creating leases');
                });
            }).catch((error) => {
                this.$mdDialog.hide();
                this.Messages.error('Error creating units');
            });
        }
    }

    angular
        .module('tenantBilling')
        .controller('BulkUploadMoveInsCtrl', BulkUploadMoveInsCtrl);
}
