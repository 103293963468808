angular
    .module('aq.admin.accounts.accounts', ['aq.ui', 'aq.services.restangular', 'aq.services.options', 'aq.messages', 'aq.ui.loading'])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.admin.accounts.accounts', {
                url: '/accounts',
                templateUrl: 'app/admin/accounts/accounts/main.html',
                controller: 'AdminAccountsCtrl as vm',
                resolve: {
                    accounts(Restangular, waitForAuthToken) {
                        return Restangular.one('accounts').get({
                            inflate: 'apps,license,functionalities,license.functionalities,partner,accountCustomReports',
                            page: 1,
                            orderBy: 'accountName',
                            asc: true
                        });
                    },
                    licenses(Restangular, waitForAuthToken) {
                        return Restangular.all('licenses').getList();
                    },
                    options(Restangular, waitForAuthToken) {
                        return Restangular.one('options', 'allEnums').get();
                    },
                    timeZones(Restangular, waitForAuthToken) {
                        return Restangular.one('options', 'allTimezones').get();
                    }
                },
                data: {
                    breadcrumb: 'Accounts',
                    icon: '.aq-icons-account-settings'
                }
            })
            .state('aq.admin.accounts.accounts.details', {
                url: '/:accountId',
                templateUrl: 'app/admin/accounts/accounts/details/accountAdminDetails.html',
                controller: 'AccountAdminDetailsCtrl as vm',
                resolve: {
                    account: (Restangular, $stateParams, waitForAuthToken) => {
                        return Restangular.one('accounts', $stateParams.accountId).get({
                            inflate: 'license,functionalities,license.functionalities,partner,accountCustomReports,owner,possibleOwner,buildingGroups,allApps',
                            single: true
                        });
                    },
                    sharedBuildings(RestangularV3, $stateParams, waitForAuthToken) {
                        return RestangularV3.one('accounts', $stateParams.accountId)
                            .customGET('shared-buildings');
                    },
                    primaryBuildings(RestangularV3, $stateParams, waitForAuthToken) {
                        return RestangularV3.one('accounts', $stateParams.accountId)
                            .customGET('primary-buildings');
                    },
                    timezones(Restangular, waitForAuthToken) {
                        return Restangular.one('options', 'allTimezones').get();
                    },
                    licenses: (Restangular, waitForAuthToken) => {
                        return Restangular.all('licenses').getList();
                    },
                    functionalities: (Restangular, waitForAuthToken) => {
                        return Restangular.all('functionalities').getList();
                    },
                    partners: (Restangular, waitForAuthToken) => {
                        return Restangular.all('partners').getList();
                    },
                    reports: (Restangular, waitForAuthToken) => {
                        return Restangular.all('reports').getList({ inflate: 'app' });
                    },
                    apps: (Restangular, waitForAuthToken) => {
                        return Restangular.all('apps').customGETLIST('queryOptionalApps');
                    },
                    projectTemplate: (RestangularV3, $stateParams, waitForAuthToken) => {
                        return RestangularV3.one('project-template', $stateParams.accountId).customGET('account');
                    },
                    profiles(waitForAuthToken, RestangularV3, DataStore: aq.common.DataStore, $stateParams) {
                        return DataStore.getList(RestangularV3.one(''), 'profiles', { accountId: $stateParams.accountId });
                    }
                }
            })
            .state('aq.admin.accounts.accounts.details.configuration', {
                url: '/configuration',
                templateUrl: 'app/admin/accounts/accounts/details/accountAdminConfiguration.html',
                controller: 'AccountAdminConfigurationCtrl as vm'
            })
            .state('aq.admin.accounts.accounts.details.license', {
                url: '/license',
                templateUrl: 'app/admin/accounts/accounts/details/accountAdminLicense.html',
                controller: 'AccountAdminLicenseCtrl as vm'
            })
            .state('aq.admin.accounts.accounts.details.buildings', {
                url: '/buildings',
                templateUrl: 'app/admin/accounts/accounts/details/accountAdminBuildings.html',
                controller: 'AccountAdminBuildingsCtrl as vm'
            })
            .state('aq.admin.accounts.accounts.details.defaults', {
                url: '/defaults',
                templateUrl: 'app/admin/accounts/accounts/details/defaults/accountAdminDefaults.html',
                controller: 'AccountAdminDefaultsCtrl as vm'
            })
            .state('aq.admin.accounts.create', {
                url: '/create',
                templateUrl: 'app/admin/accounts/accounts/create/accountNew.html',
                controller: 'AccountNewCtrl as vm',
                resolve: {
                    personas(RestangularV3, waitForAuthToken) {
                        return RestangularV3.one('personas').customGET();
                    },
                    licenses(Restangular, waitForAuthToken) {
                        return Restangular.all('licenses').getList();
                    },
                    options(Restangular, waitForAuthToken) {
                        return Restangular.one('options', 'allEnums').get();
                    },
                    timeZones(Restangular, waitForAuthToken) {
                        return Restangular.one('options', 'allTimezones').get();
                    }
                },
                params: {
                    newAccountObj: null
                }
            });
    });
