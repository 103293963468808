angular.module('aq-main').run(function ($httpBackend) {
    let idCounter = 1;
    const notices = [{
        id: idCounter++,
        date: moment(),
        title: 'Run Invoicing',
        description: 'It\'s time to run your invoices.',
        state: 'aq.tenantbilling.building.invoicing',
        building: 22485218,
        status: 'TODO'
    }, {
        id: idCounter++,
        date: moment().add(3, 'months'),
        description: 'Your PEPCO tariff schedule will expire in 3 months. Visit your property settings to update it.',
        building: 22485218,
        status: 'TODO'
    }, {
        id: idCounter++,
        date: moment().add(1, 'months').add(6, 'days'),
        description: 'WMATA\'s lease will expire in 37 days.',
        building: 22485218,
        status: 'TODO'
    }];

    const regex = {
        notices: /^\/api\/v3\/notices\?buildingId=\d{1,}$/
    };
    MockContext('tenantBilling', () => {
        // Notices
        $httpBackend.whenGET(regex.notices).respond((method, url, data) => {
            const queryParams = MockHelpers.getQueryParams(url);
            return [200, _.filter(notices, { building: parseInt(queryParams['buildingId'][0]) }), {}];
        });

    });
});
