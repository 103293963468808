namespace aq.settings {
    export class SettingsMetricCtrl {
        private accountSettingsAccess: common.models.AppAccessObject;
        /* @ngInject */
        constructor(
            private $scope,
            private $mdDialog: ng.material.IDialogService,
            private authAccess: common.models.AuthAppAccess
        ) {
            this.accountSettingsAccess = authAccess['Account Settings'];
            this.$scope.$on('CREATE_NEW', () => {
                this.create();
            });
        }
        create() {
            this.$mdDialog.show({
                controller: 'SettingsMetricCRUDCtrl as vm',
                templateUrl: 'app/settings/platform/metrics/crud/crud.html',
                clickOutsideToClose: true,
                locals: {
                    object: new AccountMetric(),
                    account: this.$scope.account,
                    realUnits: this.$scope.realUnits,
                    accountSettingsAccess: this.accountSettingsAccess
                }
            })
            .then((createdMetric) => {
                this.$scope.account.accountMetrics.push(createdMetric);
            });
        }
        update(metric) {
            this.$mdDialog.show({
                controller: 'SettingsMetricCRUDCtrl as vm',
                templateUrl: 'app/settings/platform/metrics/crud/crud.html',
                clickOutsideToClose: true,
                locals: {
                    object: metric,
                    account: this.$scope.account,
                    realUnits: this.$scope.realUnits,
                    accountSettingsAccess: this.accountSettingsAccess
                }
            })
            .then((updatedMetric) => {
                if (updatedMetric) {
                    _.replaceItemById(this.$scope.account.accountMetrics, updatedMetric);
                } else {
                    _.remove(this.$scope.account.accountMetrics, (m: any) => { return m.id == metric.id; });
                }
            });
        }
    }
    angular.module('aq.settings').controller('SettingsMetricCtrl', SettingsMetricCtrl);
}
