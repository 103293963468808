namespace aq.services {

    // yes, this code is terrible - it's almost a direct port of a classic angularjs factory
    // written circa 2014. I cleaned up as much as I could but it's tightly coupled in a bunch
    // of stuff and I am afraid of breaking things in subtle ways! - Trevor
    export class UserService {
        public currentUser: aq.common.models.User;
        private static readonly DEFAULT_LOCALE = 'en_us';
        private static readonly TWENTY_FOUR_HOUR_FORMAT = 'MMM DD, YYYY HH:mm:ss';
        private static readonly TWELVE_HOUR_FORMAT = 'MMM DD, YYYY h:mm:ss A';
        private static readonly TWENTYFOUR = 'TWENTYFOUR';

        /* @ngInject */
        constructor(
            private Restangular: restangular.IService,
            private Errors,
            private $q: ng.IQService,
            private $translate
        ) { }

        public getUser(params = {}): ng.IPromise<aq.common.models.User> {
            if (this.currentUser) {
                return this.$q.when(this.currentUser);
            }

            return this.reloadUser(params);
        }

        public reloadUser(params) {
            const query = {
                single: true,
                nocache: new Date().getTime(),
                ...params
            };

            return this.Restangular
                .one('users', 'me')
                .get(query)
                .then((result) => {
                    this.currentUser = result;
                    this.initLanguage();
                    return this.currentUser;
                }, this.Errors.forPromise());
        }

        public getProfiles(): ng.IPromise<aq.common.models.Profile[]> {
            return this.Restangular
                .one('users', 'me')
                .get({
                    inflate: 'profiles,profiles.apps,buildingGroups',
                    nocache: new Date().getTime()
                })
                .catch(this.Errors.forPromise());
        }

        public isAdmin() {
            return this.currentUser && this.currentUser.userType === 'ADMINISTRATOR';
        }

        public getTimeFormat() {
            return this.isTwentyFourFormat()
                ? 'HH:mm' : 'hh:mm A';
        }

        // not sure what flot is but don't want to change legacy stuff to correct this
        public getShortFlotTimeFormat() {
            return this.isTwentyFourFormat()
                ? '%H:00' : '%I%p';
        }

        // not sure what flot is but don't want to change legacy stuff to correct this
        public getLongFlotTimeFormat() {
            return this.isTwentyFourFormat()
                ? '%H:%M' : '%I:%M%p';
        }

        public isTwentyFourFormat() {
            return (this.currentUser == null)
                ? false
                : this.currentUser.timeFormat == UserService.TWENTYFOUR;

        }

        public dateToTimezone(date) {
            if (this.currentUser == null) return moment(date);
            return moment(date).tz(this.currentUser.timeZoneId);
        }

        public fullDateAdjust(date) {
            if (this.isTwentyFourFormat()) {
                return this.dateAdjust(date, UserService.TWENTY_FOUR_HOUR_FORMAT);
            } else {
                return this.dateAdjust(date, UserService.TWELVE_HOUR_FORMAT);
            }
        }

        public dateAdjust(date, format) {
            if (!this.currentUser || !date) return null;
            let dateStr = date;
            // timeZoneId is reliable between winter/summer time transition
            if (date && this.currentUser) {
                dateStr = moment(date).tz(this.currentUser.timeZoneId).format(format);
            }
            return dateStr;
        }

        public initLanguage() {
            const language = this.language().toLowerCase();
            this.$translate.use(language);
            const locale = this.locale();
            if (($ as any).datepicker) {
                ($ as any).datepicker
                    .setDefaults(($ as any)
                        .datepicker
                        .regional[locale]);
            }
            moment.locale(locale);
        }

        public language(): string {
            if (!this.currentUser || !this.currentUser.currentProfile) {
                return UserService.DEFAULT_LOCALE;
            }
            return this.currentUser.language
                ? this.currentUser.language
                : UserService.DEFAULT_LOCALE;
        }

        public locale() {
            if (
                !this.currentUser
                || !this.currentUser.currentProfile
                || !(this.currentUser.currentProfile.account && this.currentUser.currentProfile.account.localized)
            ) {
                return UserService.DEFAULT_LOCALE;
            }
            return this.language();
        }

        public updateAccount(account: aq.common.models.Account) {
            this.currentUser.currentProfile.account = account;
        }

        public init() {
        }
    }

    angular
        .module('aq.services.user', ['restangular', 'aq.services.errors'])
        .service('UserService', UserService);
}
