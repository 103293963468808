angular.module('aq-main').run(($httpBackend) => {

    let id = 1;
    const alertLevels = [
        {
            id: id++,
            order: 1,
            levelName: 'Critical Low Temp',
            actionDescription: 'RAISE THE TEMP\r\nTEST\r\nREPEAT',
            levelSeverity: aq.propertySettings.LevelSeverity.CRITICAL,
            queryableType: aq.services.Queryable.METERS,
            queryableId: 29930994,
            measure: aq.services.Measure.TEMPERATURE,
            unit: aq.services.Unit.F,
            lowerBoundary: null,
            upperBoundary: 62
        },
        {
            id: id++,
            order: 2,
            levelName: 'Warning Low Temp',
            actionDescription: 'Raise the temp',
            levelSeverity: aq.propertySettings.LevelSeverity.WARNING,
            queryableType: aq.services.Queryable.METERS,
            queryableId: 29930994,
            measure: aq.services.Measure.TEMPERATURE,
            unit: aq.services.Unit.F,
            lowerBoundary: 62,
            upperBoundary: 68
        },
        {
            id: id++,
            order: 3,
            levelName: 'Normal Temp',
            actionDescription: '',
            levelSeverity: aq.propertySettings.LevelSeverity.NORMAL,
            queryableType: aq.services.Queryable.METERS,
            queryableId: 29930994,
            measure: aq.services.Measure.TEMPERATURE,
            unit: aq.services.Unit.F,
            lowerBoundary: 68,
            upperBoundary: 76
        },
        {
            id: id++,
            order: 4,
            levelName: 'Warning High Temp',
            actionDescription: 'Lower the temp',
            levelSeverity: aq.propertySettings.LevelSeverity.WARNING,
            queryableType: aq.services.Queryable.METERS,
            queryableId: 29930994,
            measure: aq.services.Measure.TEMPERATURE,
            unit: aq.services.Unit.F,
            lowerBoundary: 76,
            upperBoundary: 80
        },
        {
            id: id++,
            order: 5,
            levelName: 'Critical High Temp',
            actionDescription: 'LOWER THE TEMP',
            levelSeverity: aq.propertySettings.LevelSeverity.CRITICAL,
            queryableType: aq.services.Queryable.METERS,
            queryableId: 29930994,
            measure: aq.services.Measure.TEMPERATURE,
            unit: aq.services.Unit.F,
            lowerBoundary: 80,
            upperBoundary: null
        }
    ];
    const alertLevelsForUnitEqState = [{
        id: id++,
        order: 1,
        levelName: 'Normal State',
        actionDescription: '',
        levelSeverity: aq.propertySettings.LevelSeverity.NORMAL,
        queryableType: aq.services.Queryable.METERS,
        queryableId: 29930994,
        measure: aq.services.Measure.STATE,
        unit: aq.services.Unit.STATE,
        lowerBoundary: 0,
        upperBoundary: 15
    },
    {
        id: id++,
        order: 1,
        levelName: 'Warning State',
        actionDescription: '',
        levelSeverity: aq.propertySettings.LevelSeverity.WARNING,
        queryableType: aq.services.Queryable.METERS,
        queryableId: 29930994,
        measure: aq.services.Measure.STATE,
        unit: aq.services.Unit.STATE,
        lowerBoundary: 15,
        upperBoundary: 20
    },
    {
        id: id++,
        order: 1,
        levelName: 'Critical State',
        actionDescription: '',
        levelSeverity: aq.propertySettings.LevelSeverity.UNKNOWN,
        queryableType: aq.services.Queryable.METERS,
        queryableId: 29930994,
        measure: aq.services.Measure.STATE,
        unit: aq.services.Unit.STATE,
        lowerBoundary: 20,
        upperBoundary: 50
    }];
    const stateAlerts = [
        {
            id: id++,
            building: 26642479,
            name: 'Temperature alert',
            description: 'temperature alert',
            senders: [
                { id: id++, name: 'mail', type: 'MAIL' }
            ],
            receivers: [
                { id: id++, user: 21336621 }
            ],
            active: true,
            admin: false,
            type: 'STATE',
            dayCount: 0,
            lifetimeCount: 0,
            notificationType: 'STATE_ALERT',
            interval: '1min',
            maxAgeMinutes: 10,
            defaultSeverity: aq.propertySettings.LevelSeverity.NORMAL,
            alertOn: aq.propertySettings.LevelSeverity.WARNING,
            whenCondition: aq.propertySettings.WhenCondition.ANYTIME,
            calendar: null,
            customRule: null,
            alertLevels
        },
        // 22485218: 100 10th Ave
        {
            id: id++,
            building: 22485218,
            name: 'Mock Temperature alert (!)',
            description: 'mock temperature alert',
            senders: [
                { id: id++, name: 'mail', type: 'MAIL' }
            ],
            receivers: [
                { id: id++, user: 21336621 }
            ],
            active: true,
            admin: false,
            type: 'STATE',
            dayCount: 0,
            lifetimeCount: 0,
            notificationType: 'STATE_ALERT',
            interval: '1min',
            maxAgeMinutes: 10,
            defaultSeverity: aq.propertySettings.LevelSeverity.NORMAL,
            alertOn: aq.propertySettings.LevelSeverity.WARNING,
            whenCondition: aq.propertySettings.WhenCondition.ANYTIME,
            calendar: null,
            customRule: null,
            alertLevels
        },
        {
            id: id++,
            building: 22485218,
            name: 'Mock Temperature alert (OK)',
            description: 'mock temperature alert',
            senders: [
                { id: id++, name: 'mail', type: 'MAIL' }
            ],
            receivers: [
                { id: id++, user: 21336621 }
            ],
            active: true,
            admin: false,
            type: 'STATE',
            dayCount: 0,
            lifetimeCount: 0,
            notificationType: 'STATE_ALERT',
            interval: '1min',
            maxAgeMinutes: 10,
            defaultSeverity: aq.propertySettings.LevelSeverity.NORMAL,
            alertOn: aq.propertySettings.LevelSeverity.WARNING,
            whenCondition: aq.propertySettings.WhenCondition.ANYTIME,
            calendar: null,
            customRule: null,
            alertLevels
        },
        {
            id: id++,
            building: 22485218,
            name: 'Mock Temperature alert (!!!)',
            description: 'mock temperature alert',
            senders: [
                { id: id++, name: 'mail', type: 'MAIL' }
            ],
            receivers: [
                { id: id++, user: 21336621 }
            ],
            active: true,
            admin: false,
            type: 'STATE',
            dayCount: 0,
            lifetimeCount: 0,
            notificationType: 'STATE_ALERT',
            interval: '1min',
            maxAgeMinutes: 10,
            defaultSeverity: aq.propertySettings.LevelSeverity.NORMAL,
            alertOn: aq.propertySettings.LevelSeverity.WARNING,
            whenCondition: aq.propertySettings.WhenCondition.ANYTIME,
            calendar: null,
            customRule: null,
            alertLevels
        },
        {
            id: id++,
            building: 22485218,
            name: 'Mock state alert',
            description: 'mock temperature alert',
            senders: [
                { id: id++, name: 'mail', type: 'MAIL' }
            ],
            receivers: [
                { id: id++, user: 21336621 }
            ],
            active: true,
            admin: false,
            type: 'STATE',
            dayCount: 0,
            lifetimeCount: 0,
            notificationType: 'STATE_ALERT',
            interval: '1min',
            maxAgeMinutes: 10,
            defaultSeverity: aq.propertySettings.LevelSeverity.NORMAL,
            alertOn: aq.propertySettings.LevelSeverity.WARNING,
            whenCondition: aq.propertySettings.WhenCondition.ANYTIME,
            calendar: null,
            customRule: null,
            alertLevels: alertLevelsForUnitEqState
        }
    ];

    const alertsToDuplicate = _.filter(stateAlerts, (alert) => alert.building == 22485218);
    _.each(alertsToDuplicate, (alert) => {
        const newAlert = angular.copy(alert);
        newAlert.id = id++;
        newAlert.building = 22490253; // Redwood Office
        stateAlerts.push(newAlert);
    });

    const mapAlertLevelSettingsModelToAlertLevel = (value, alert: aq.propertySettings.StateAlertLevel) => {
        const result: aq.models.alerts.AlertDataLevel = {
            value,
            timestamp: moment().valueOf(),
            severity: alert.levelSeverity,
            upperBoundary: alert.upperBoundary,
            lowerBoundary: alert.lowerBoundary,
            name: alert.levelName,
            description: alert.actionDescription,
            queryable: {
                id: alert.queryableId,
                name: 'q name',
                type: alert.queryableType
            },
            unit: {
                name: alert.measure,
                symbol: alert.unit
            }
        };
        return result;
    };

    const stateAlertsData: any[] = [
        // 100 10th Ave
        {
            alertId: stateAlerts[1].id,
            alertType: 'STATE_ALERT',
            alertName: 'Temperature alert A',
            alertDescription: 'temperature alert description',
            currentLevel: mapAlertLevelSettingsModelToAlertLevel(77, alertLevels[3] as any) as any,
            previousLevel: mapAlertLevelSettingsModelToAlertLevel(77, alertLevels[2] as any) as any,
            nextLevel: mapAlertLevelSettingsModelToAlertLevel(77, alertLevels[4] as any) as any
        },
        {
            alertId: stateAlerts[2].id,
            alertType: 'STATE_ALERT',
            alertName: 'Temperature alert B',
            alertDescription: 'temperature alert description',
            currentLevel: mapAlertLevelSettingsModelToAlertLevel(72, alertLevels[2] as any) as any,
            previousLevel: mapAlertLevelSettingsModelToAlertLevel(72, alertLevels[1] as any) as any,
            nextLevel: mapAlertLevelSettingsModelToAlertLevel(72, alertLevels[3] as any) as any
        },
        {
            alertId: stateAlerts[3].id,
            alertType: 'STATE_ALERT',
            alertName: 'Temperature alert C',
            alertDescription: 'temperature alert description',
            currentLevel: mapAlertLevelSettingsModelToAlertLevel(61, alertLevels[0] as any) as any,
            previousLevel: null,
            nextLevel: mapAlertLevelSettingsModelToAlertLevel(61, alertLevels[1] as any) as any
        },
        {
            alertId: stateAlerts[4].id,
            alertType: 'STATE_ALERT',
            alertName: 'Temperature alert state',
            alertDescription: 'temperature alert description',
            currentLevel: mapAlertLevelSettingsModelToAlertLevel(16, alertLevelsForUnitEqState[1] as any) as any,
            previousLevel: mapAlertLevelSettingsModelToAlertLevel(16, alertLevelsForUnitEqState[0] as any) as any,
            nextLevel: mapAlertLevelSettingsModelToAlertLevel(16, alertLevelsForUnitEqState[2] as any) as any
        },
        // Redwood Office
        {
            alertId: stateAlerts[5].id,
            alertType: 'STATE_ALERT',
            alertName: 'Temperature alert X',
            alertDescription: 'temperature alert description',
            currentLevel: mapAlertLevelSettingsModelToAlertLevel(77, alertLevels[3] as any) as any,
            previousLevel: mapAlertLevelSettingsModelToAlertLevel(77, alertLevels[2] as any) as any,
            nextLevel: mapAlertLevelSettingsModelToAlertLevel(77, alertLevels[4] as any) as any
        },
        {
            alertId: stateAlerts[6].id,
            alertType: 'STATE_ALERT',
            alertName: 'Temperature alert Y',
            alertDescription: 'temperature alert description',
            currentLevel: mapAlertLevelSettingsModelToAlertLevel(72, alertLevels[2] as any) as any,
            previousLevel: mapAlertLevelSettingsModelToAlertLevel(72, alertLevels[1] as any) as any,
            nextLevel: mapAlertLevelSettingsModelToAlertLevel(72, alertLevels[3] as any) as any
        },
        {
            alertId: stateAlerts[7].id,
            alertType: 'STATE_ALERT',
            alertName: 'Temperature alert Z',
            alertDescription: 'temperature alert description',
            currentLevel: mapAlertLevelSettingsModelToAlertLevel(61, alertLevels[0] as any) as any,
            previousLevel: null,
            nextLevel: mapAlertLevelSettingsModelToAlertLevel(61, alertLevels[1] as any) as any
        },
        {
            alertId: stateAlerts[8].id,
            alertType: 'STATE_ALERT',
            alertName: 'Temperature alert state',
            alertDescription: 'temperature alert description',
            alertNote: 'This is why.',
            currentLevel: mapAlertLevelSettingsModelToAlertLevel(40, alertLevelsForUnitEqState[2] as any) as any,
            previousLevel: mapAlertLevelSettingsModelToAlertLevel(40, alertLevelsForUnitEqState[1] as any) as any,
            nextLevel: null
        }
    ];

    const getAlertsForBuilding = (buildingId) => {
        return _.filter(stateAlerts, (alert) => alert.building == buildingId);
    };

    const getAlertById = (alertId) => {
        return _.find(stateAlerts, (alert) => alert.id == alertId);
    };

    const getAlertIndexById = (alertId) => {
        return _.findIndex(stateAlerts, (alert) => alert.id == alertId);
    };

    const removeAlertById = (alertId) => {
        _.remove(stateAlerts, (alert) => alert.id == alertId);
    };

    const getStateAlert = (alertId) => {
        return _.find(stateAlertsData, (item) => item.alertId == alertId);
    };

    const stateAlertStatsData: aq.reports.alert.AlertDataStats = {
        byBuilding: [{
            buildingId: 22485218,
            buildingName: '100 10th Ave',
            buildingGroupId: 10,
            buildingGroupName: 'DC',
            stats: {
                issuesTriggered: 5,
                escalations: 2,
                openIssues: 2,
                closedIssues: 3,
                issuesByDay: [
                    { date: '2018-10-06', numberOfIssues: 2 },
                    { date: '2018-10-08', numberOfIssues: 1 },
                    { date: '2018-10-09', numberOfIssues: 2 }
                ],
                issuesByAssignee: [
                    { user: { fullName: 'User A' }, numberOfIssues: 1 },
                    { user: { fullName: 'User B' }, numberOfIssues: 2 },
                    { user: { fullName: 'User C' }, numberOfIssues: 2 }
                ],
                alertIssues: [
                    { alertName: 'This is a clear example of some longer alert text description', openedOn: '2018-10-06T10:00:00', closedOn: '2018-10-06T11:45:00', violations: 1, escalations: 1 },
                    { alertName: 'A medium priority alert was raised for Equipment 1', openedOn: '2018-10-06T10:30:00', closedOn: '2018-10-06T11:48:00', violations: 1, escalations: 0 },
                    { alertName: 'Stack overflow error', openedOn: '2018-10-08T10:00:00', closedOn: '2018-10-08T11:45:00', violations: 1, escalations: 0 },
                    { alertName: 'Division by zero alert', openedOn: '2018-10-12T10:00:00', closedOn: null, violations: 1, escalations: 0 },
                    { alertName: 'Out of memory - needs a more thorough investigation by a responsible person',  openedOn: '2018-10-12T10:07:00', closedOn: null, violations: 1, escalations: 1 }
                ]
            } as any
        }, {
            buildingId: 22984268,
            buildingName: '1200 McLean Blvd',
            buildingGroupId: 10,
            buildingGroupName: 'DC',
            stats: {
                issuesTriggered: 2,
                escalations: 0,
                openIssues: 2,
                closedIssues: 0,
                issuesByDay: [
                    { date: '2018-10-07', numberOfIssues: 1 },
                    { date: '2018-10-08', numberOfIssues: 1 }
                ],
                issuesByAssignee: [
                    { user: { fullName: 'User One' }, numberOfIssues: 1 },
                    { user: { fullName: 'User Two' }, numberOfIssues: 1 }
                ],
                alertIssues: [
                    { alertName: 'Red alert, all hands to battle stations', type: 'Level 1', openedOn: '2018-10-07T14:00:00', closedOn: null, violations: 1, escalations: 1 },
                    { alertName: 'Yellow alert, all hands report to stations', type: 'Level 2', openedOn: '2018-10-08T14:30:00', closedOn: null, violations: 1, escalations: 0 }
                ]
            } as any
        }, {
            buildingId: 22489976,
            buildingName: 'Concord Plaza',
            buildingGroupId: 10,
            buildingGroupName: 'DC',
            stats: {
                issuesTriggered: 1,
                escalations: 1,
                openIssues: 1,
                closedIssues: 2,
                issuesByDay: [
                    { date: '2018-10-10', numberOfIssues: 1 }
                ],
                issuesByAssignee: [
                    { user: { fullName: 'User One' }, numberOfIssues: 1 },
                    { user: { fullName: 'User Two' }, numberOfIssues: 1 }
                ],
                alertIssues: [
                    { alertName: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', type: 'Level 1', openedOn: '2018-10-04T14:00:00', closedOn: '2018-10-06T15:00:00', violations: 1, escalations: 0 },
                    { alertName: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.', type: 'Level 2', openedOn: '2018-10-10T18:38:00', closedOn: null, violations: 1, escalations: 0 }
                ]
            } as any
        }, {
            buildingId: 22489632,
            buildingName: '50 Fremont',
            buildingGroupId: 11,
            buildingGroupName: 'New York',
            stats: {
                issuesTriggered: 4,
                escalations: 1,
                openIssues: 2,
                closedIssues: 2,
                issuesByDay: [
                    { date: '2018-10-06', numberOfIssues: 1 },
                    { date: '2018-10-08', numberOfIssues: 1 },
                    { date: '2018-10-10', numberOfIssues: 2 }
                ],
                issuesByAssignee: [
                    { user: { fullName: 'User 1' }, numberOfIssues: 3 },
                    { user: { fullName: 'User 2' }, numberOfIssues: 1 }
                ],
                alertIssues: [
                    { alertName: 'Meter offline', type: 'Level 1', openedOn: '2018-10-06T06:11:00', closedOn: '2018-10-06T06:20:00', violations: 1, escalations: 0 },
                    { alertName: 'Power down', type: 'Level 2', openedOn: '2018-10-08T07:30:00', closedOn: '2018-10-08T07:48:00', violations: 1, escalations: 0 },
                    { alertName: 'Meter offline', type: 'Level 1', openedOn: '2018-10-10T06:13:00', closedOn: '2018-10-10T06:15:00', violations: 1, escalations: 0 },
                    { alertName: 'Meter offline', type: 'Level 1', openedOn: '2018-10-10T06:18:00', closedOn: null, violations: 1, escalations: 1 }
                ]
            } as any
        }, {
            buildingId: 22490253,
            buildingName: 'Redwood Office',
            buildingGroupId: 11,
            buildingGroupName: 'New York',
            stats: {
                issuesTriggered: 3,
                escalations: 0,
                openIssues: 0,
                closedIssues: 3,
                issuesByDay: [
                    { date: '2018-10-07', numberOfIssues: 1 },
                    { date: '2018-10-08', numberOfIssues: 1 },
                    { date: '2018-10-09', numberOfIssues: 1 }
                ],
                issuesByAssignee: [
                    { user: { fullName: 'User 1' }, numberOfIssues: 1 },
                    { user: { fullName: 'User 2' }, numberOfIssues: 2 }
                ],
                alertIssues: [
                    { alertName: 'Alert A', type: 'Level 1', openedOn: '2018-10-07T11:12:00', closedOn: '2018-10-07T11:35:00', violations: 1, escalations: 0 },
                    { alertName: 'Alert B', type: 'Level 2', openedOn: '2018-10-08T11:18:00', closedOn: '2018-10-08T11:32:00', violations: 1, escalations: 0 },
                    { alertName: 'Alert C', type: 'Level 1', openedOn: '2018-10-09T11:15:00', closedOn: '2018-10-09T11:41:00', violations: 1, escalations: 0 }
                ]
            } as any
        }, {
            buildingId: 22485622,
            buildingName: 'UPS Distribution Center',
            buildingGroupId: 12,
            buildingGroupName: 'New Mexico',
            stats: {} as any
        }],
        byBuildingGroup: [{
            buildingGroupId: 22490418,
            buildingGroupName: 'DC',
            stats: {} as any
        }]
    };

    const regex = {
        stateAlerts: /^\/api\/v3\/state-alerts\?buildingId=\d*$/,
        stateAlertsData: /^\/api\/v3\/state-alerts\/data\?buildingId=\d*$/,
        stateAlert: /^\/api\/v3\/state-alerts\/\d*$/,
        stateAlertData: /^\/api\/v3\/state-alerts\/\d*\/data$/,
        stateAlertsData2: /^\/api\/v3\/alert-data\?buildingId=\d*$/,
        stateAlertData2: /^\/api\/v3\/alert-data\/\d*$/,

        stateAlertStats: /^\/api\/v3\/alert-data\/stats?.*$/
    };

    MockContext('stateAlerts', () => {
        /*
        // List
        $httpBackend
            .whenGET(regex.stateAlerts)
            .respond((method, url, data) => {
                const params = MockHelpers.getQueryParams(url);
                return [200, getAlertsForBuilding(params['buildingId'][0]), {}];
            });
        $httpBackend
            .whenPOST(regex.stateAlerts)
            .respond((method, url, data) => {
                const alert = MockHelpers.parseJSON(data);
                alert.id = id++;
                _.each(alert.alertLevels, (level) => {
                    level.id = id++;
                });
                stateAlerts.push(alert);
                return [200, alert, {}];
            });
        // Single
        $httpBackend
            .whenGET(regex.stateAlert)
            .respond((method, url, data) => {
                const ids = MockHelpers.getIds(url);
                return [200, getAlertById(ids[0]), {}];
            });
        $httpBackend
            .whenPUT(regex.stateAlert)
            .respond((method, url, data) => {
                const ids = MockHelpers.getIds(url);
                const index = getAlertIndexById(ids[0]);

                stateAlerts[index] = MockHelpers.parseJSON(data);
                return [200, stateAlerts[index], {}];
            });
        $httpBackend
            .whenDELETE(regex.stateAlert)
            .respond((method, url, data) => {
                const ids = MockHelpers.getIds(url);
                removeAlertById(ids[0]);
                return [200, stateAlerts, {}];
            });
        // Get Alert Data
        $httpBackend
            .whenGET(regex.stateAlertData2)
            .respond((method, url, data) => {
                const ids = MockHelpers.getIds(url);
                return [200, getStateAlert(ids[0]), {}];
            });
        */
        $httpBackend
            .whenGET(regex.stateAlertStats)
            .respond((method, url, data) => {
                return [200, stateAlertStatsData];
            });
    });
});
