namespace aq.dashboard.widgets {

    import ProjectPriority = aq.models.projects.ProjectPriority;
    import IdentificationSource = aq.models.projects.IdentificationSource;
    import ImpactType = aq.models.projects.ImpactType;
    import IdentificationMethod = aq.models.projects.IdentificationMethod;
    import BudgetType = aq.models.projects.BudgetType;
    import CostType = aq.models.projects.CostType;
    import ProjectType = aq.models.projects.ProjectType;
    import GresbProjectCategories = aq.models.projects.GresbProjectCategories;

    interface ProjectView {
        id: string;
        buildingId: string;
        buildingName: string;
        name: string;
        priority: string;
        prioritySort: number;
        type: string;
        status: string;
        buildingSystem: string;
        identifyingOrg: string;
        cost: number;
        impact: any;
        assigneeName: string;
        dateIdentified: string;
        dateIdentifiedSort: string;
        isHeader?: boolean;
    }

    enum DateRange {
        ALL_TIME = 'ALL_TIME',
        TRAILING_YEAR = 'TRAILING_YEAR',
        TRAILING_QUARTER = 'TRAILING_QUARTER',
        TRAILING_MONTH = 'TRAILING_MONTH',
        THIS_YEAR = 'THIS_YEAR',
        THIS_QUARTER = 'THIS_QUARTER',
        THIS_MONTH = 'THIS_MONTH'
    }

    const Priority = aq.models.projects.ProjectPriority;

    const GRESB_REPORTING = 'Gresb Reporting';

    export class ProjectListWidgetCtrl {
        private isLoadingData: boolean;
        private fieldsCount: number;
        private selectedBuildingNames: string[];
        private displayBuildings: string[];
        private readonly defaultStatuses = ['Needs Acceptance', 'Backlog', 'In Progress', 'Completed'];
        private sortColumn: string;
        private sortDirection: null | 'asc' | 'desc';
        private alternateSortFieldMap: { [column: string]: string };
        private priorityMap: { [priority: string]: number };
        private readonly displayBuildingsCount = 5;
        private projectGroupsCollapsedState = {};
        private isAqAdmin: boolean;

        /* @ngInject */
        constructor(
            private $scope,
            private DataStore,
            private account: aq.common.models.Account,
            private buildings: aq.common.models.Building[],
            private config,
            private Restangular,
            private $q: ng.IQService,
            private ModelUtilService: aq.services.ModelUtilService,
            private users: aq.common.models.User[],
            private projectTypes: aq.common.models.SimpleEnum,
            private projectStatuses: aq.common.models.SimpleEnum,
            private ProjectService: aq.projectCenter.ProjectService,
            private ProjectServiceV3: aq.services.ProjectServiceV3,
            private $state: ng.ui.IStateService,
            private buildingGroups,
            private BuildingSelectorActions: aq.services.BuildingSelectorActions,
            private Messages: aq.services.Messages,
            private RedirectService: aq.services.RedirectService,
            private currentUser: aq.common.models.User,
            private isProjectsV3: boolean,
            private Auth: aq.services.Auth
        ) {
            this.isLoadingData = true;
            this.isAqAdmin = this.currentUser.userType === 'ADMINISTRATOR';
            this.selectedBuildingNames = [];
            this.alternateSortFieldMap = {
                dateIdentified: 'dateIdentifiedSort',
                priority: 'prioritySort'
            };
            this.priorityMap = {
                [Priority.HIGH]: 3,
                [Priority.MEDIUM]: 2,
                [Priority.LOW]: 1
            };
            config.options = {
                isProjectsV3: this.isProjectsV3,
                buildings: ModelUtilService.pareProperties(this.buildings, ['buildingGroup']),
                buildingGroups: ModelUtilService.pareProperties(this.buildingGroups),
                statuses: !this.isProjectsV3 ? this.getProjectStatusSelectionItems() : [],
                dateRanges: this.isProjectsV3 && this.getDateRangeItems()
            };
            this.BuildingSelectorActions.initDefaultBuildingSelection(config);
            config.types = this.projectTypes;
            config.statuses = this.projectStatuses;
            if (!config.filterBy) {
                config.filterBy = {
                };
            }
            if (!config.filterBy.status) {
                config.filterBy.status = 'ALL';
            }
            if (this.isProjectsV3 && !config.filterBy.dateRange) {
                config.filterBy.dateRange = DateRange.THIS_YEAR;
            }
            if (this.isProjectsV3 && !config.filterBy.statuses) {
                config.filterBy.statuses = [...this.defaultStatuses];
            }
            if (this.isProjectsV3 && !config.columns) {
                config.columns = {
                    showPriority: true,
                    showType: true,
                    showStatus: true,
                    showSystem: true, //false,
                    showIdentifyingOrg: true, // false,
                    showCost: true,
                    showImpact: true,
                    showAssignee: true,
                    showDateIdentified: true
                };
            }
            config.columns.showProjectTitle = true;
            config.columns.showBuildingName = true;

            if (this.isProjectsV3 && !config.groupBy) {
                config.groupBy = 'building';
            }

            this.$scope.config = config;
            this.$scope.config.actions = this.BuildingSelectorActions;
            this.$scope.projects = [];
            if (this.isProjectsV3) {
                this.populateProjectsV3();
            } else {
                this.populateProjects();
            }
            this.$scope.selectedProject = [];
            this.$scope.selectProject = async (project: ProjectView) => {
                if (this.isProjectsV3) {
                    if (project.isHeader) return;
                    const destination = `accounts/${this.account.id}/buildings/${project.buildingId}/projects/board/project/${project.id}`;
                    this.RedirectService.redirect({
                        account: +this.account.id,
                        building: +project.buildingId,
                        profile: this.currentUser.currentProfile.id,
                        destination,
                    }, true);
                } else {
                    this.$state.go('aq.projectCenter.overview.project.configuration',
                        {
                            accountId: this.account.id,
                            buildingId: project.buildingId,
                            projectId: project.id
                        });
                }
            };
            this.fieldsCount = this.isProjectsV3 ?
                _.filter([
                    config.columns.showPriority, config.columns.showType, config.columns.showStatus,
                    config.columns.showSystem, config.columns.showIdentifyingOrg, config.columns.showCost,
                    config.columns.showImpact, config.columns.showAssignee, config.columns.showDateIdentified
                ], (flag) => flag).length :
                _.filter([
                    config.showStatus, config.showType, config.showCost, config.showSavings
                ], (flag) => flag).length;

            if (this.isProjectsV3 && (config.groupBy == 'building' || config.groupBy == 'status' && config.columns.showStatus)) {
                this.fieldsCount--;
            }
        }

        public getStatusesView() {
            const effectiveStatuses = this.isAqAdmin
                ? this.config.filterBy.statuses
                : this.config.filterBy.statuses.filter(status => status.toUpperCase() !== 'DRAFT');
            return effectiveStatuses.join(', ');
        }

        public onHeaderClick(event) {
            const field = event.target.attributes.field;
            if (!field || !field.value) {
                return;
            }
            if (this.sortColumn == field.value) {
                this.sortDirection = this.sortDirection == 'asc' ? 'desc' : 'asc';
            } else {
                this.sortColumn = field.value;
                this.sortDirection = 'asc';
            }
            this.groupProjects(this.$scope.projects);
            if (this.Auth.hasFunctionality(GRESB_REPORTING)) {
                this.groupProjects(this.$scope.csvProjects, true);
            }
        }

        private initDisplayBuildings() {
            this.displayBuildings = _.take(this.selectedBuildingNames, this.displayBuildingsCount);
        }

        private getDateRangeItems() {
            return Object.keys(DateRange).map(key => DateRange[key]);
        }

        private getProjectStatusSelectionItems() {
            const statuses = _.map(this.projectStatuses, (value: { label: string }, key: string) => {
                return {
                    value: key,
                    label: value.label
                };
            });
            statuses.push({
                value: 'ALL',
                label: 'All'
            });
            return statuses;
        }

        private exportProjectsToCSV() {
            try {
                let csvContent = '';
                let fileName = '';
                const processedProjects = this.Auth.hasFunctionality(GRESB_REPORTING)
                    ? 'projectsProcessedCSV' : 'projectsProcessed';
                const filteredProjects = this.$scope[processedProjects].filter(project => project.isHeader !== true);
                const projectsMinusGroups = _.cloneDeep(filteredProjects);
                if (projectsMinusGroups.length === 0) {
                    return;
                }

                const headerRow = this.trimProjectColumnsForExport(projectsMinusGroups[0]);
                csvContent += Object.keys(headerRow).join(',');
                csvContent += '\n';

                projectsMinusGroups.forEach(values => {
                    const data = this.trimProjectColumnsForExport(values);
                    csvContent += Object.values(data).map(value => {
                        if (typeof (value) === 'string') {
                            value = value.replace(/(\r\n|\n|\r)/gm, ' ');
                        }
                        return value;
                    }).join(',');
                    csvContent += '\n';
                });
                fileName = `Projects Export - ${moment().format('YYYY-MM-DD')}.csv`;
                const blob = new Blob([csvContent], { type: 'text/csv' });
                const objectUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.setAttribute('href', objectUrl);
                link.setAttribute('download', fileName);
                link.click();
            } catch (e) {
                this.Messages.error('Something went wrong generating your report!');
            }
        }

        private getCountOfGroup(group: string) {
            const projectGroup = this.$scope.projectGroups.find(projectGroup => projectGroup.name === group);
            if (projectGroup) {
                return projectGroup.projects.length;
            }
            return 0;
        }

        private trimProjectColumnsForExport(project: any) {
            delete project.$$hashKey;
            delete project.prioritySort;
            delete project.dateIdentifiedSort;
            delete project.id;
            if (!this.config.columns.showPriority) {
                delete project.priority;
            }
            if (!this.config.columns.showType) {
                delete project.type;
            }
            if (!this.config.columns.showStatus) {
                delete project.status;
            }
            if (!this.config.columns.showSystem) {
                delete project.buildingSystem;
            }
            if (!this.config.columns.showIdentifyingOrg) {
                delete project.identifyingOrg;
            }
            if (!this.config.columns.showCost) {
                delete project.cost;
            }
            if (!this.config.columns.showImpact) {
                delete project.impact;
            }
            if (!this.config.columns.showAssignee) {
                delete project.assigneeName;
            }
            if (!this.config.columns.showDateIdentified) {
                delete project.dateIdentified;
            }
            return project;
        }

        private populateProjects() {
            const results = this.config.buildingIds.map((buildingId) => {
                return this.ProjectService.getList(buildingId, this.users, this.projectTypes).then((projects) => {
                    projects = _.filter(projects, (project) => {
                        return this.$scope.config.filterBy.status == 'ALL' || project.status == this.$scope.config.filterBy.status;
                    });
                    const building = _.find(this.buildings, { id: buildingId });
                    projects.forEach((project) => {
                        project.building = building;
                    });
                    this.$scope.projects = this.$scope.projects.concat(projects);
                    return this.$q.all(projects.map((project) => {
                        return project.customGET('results');
                    }));
                }).then((projectResults: aq.projectCenter.ProjectResults[]) => {
                    projectResults.forEach((result) => {
                        const project = _.find(this.$scope.projects, { id: result.project });
                        if (project) {
                            project.totalSavings = result.totalSavings;
                        }
                    });
                });
            });
            this.$q.all(results).finally(() => {
                this.isLoadingData = false;
            });
        }

        private populateProjectsV3() {
            this.$scope.csvProjects = [];
            let first = false;
            const results = this.$scope.config.buildingIds.map((buildingId) => {
                return this.ProjectServiceV3.getProjects(buildingId).then(projectResponse => {
                    if (!first) {
                        first = true;
                        const effectiveStatuses = this.isAqAdmin
                            ? projectResponse.statuses
                            : projectResponse.statuses.filter(s => s.name.toUpperCase() !== 'DRAFT');
                        this.$scope.config.options.statuses = effectiveStatuses;
                    }
                    const selectedStatuses = _.filter(this.$scope.config.options.statuses, (status: aq.models.projects.ProjectStatus) => {
                        return _.includes(this.$scope.config.filterBy.statuses, status.name);
                    });
                    const now = moment();
                    const projects = _.chain(projectResponse.projects)
                        .filter((project: aq.models.projects.Project) => {
                            return _.find(selectedStatuses, (status: aq.models.projects.ProjectStatus) => status.id == project.status.id);
                        })
                        .filter((project: aq.models.projects.Project) => {
                            if (project.status.name == 'Completed') {
                                const completed = project.completedOn ? moment(project.completedOn) : moment(project.updatedOn);
                                switch (this.$scope.config.filterBy.dateRange) {
                                    case DateRange.THIS_MONTH:
                                        return completed.isSame(now, 'month');
                                    case DateRange.THIS_YEAR:
                                        return completed.isSame(now, 'year');
                                    case DateRange.THIS_QUARTER:
                                        return completed.year() == now.year()
                                            && Math.ceil((completed.month() + 1) / 3) == Math.ceil((now.month() + 1) / 3);
                                    case DateRange.TRAILING_MONTH:
                                        return now.subtract(1, 'month').isSameOrBefore(completed);
                                    case DateRange.TRAILING_YEAR:
                                        return now.subtract(1, 'year').isSameOrBefore(completed);
                                    case DateRange.TRAILING_QUARTER:
                                        return now.subtract(3, 'month').isSameOrBefore(completed);
                                    case DateRange.ALL_TIME:
                                    default:
                                        return true;
                                }
                            }
                            return true;
                        })
                        .value();
                    const building: aq.common.models.Building = _.find(this.buildings, { id: buildingId });
                    this.selectedBuildingNames.push(building.name);
                    if (this.Auth.hasFunctionality(GRESB_REPORTING)) {
                        this.populateProjectsGresb(projects, building);
                    }
                    return projects.map((p: aq.models.projects.Project) => {
                        const systemType = p.systemTypes && p.systemTypes[0] || null;
                        const assignee: aq.common.models.User = _.find(this.users, (user) => user.id == p.assignee);
                        const isCompleted = p.status.name === 'Completed';
                        const isGresb = this.Auth.hasFunctionality(GRESB_REPORTING);
                        let savingsRange;
                        if (this.Auth.hasFunctionality(PROJECT_ESTIMATION)) {
                            const impactValue = this.ProjectServiceV3.getImpactValue(p, true, false, 'USD');
                            const min = impactValue.projectImpactMin;
                            const max = impactValue.projectImpact;
                            const estimated = impactValue.estimated ? '~' : '';
                            savingsRange = min ? `$${min} - $${max}` : `${estimated}$${max}`;
                            savingsRange = !min && !max ? '$0' : savingsRange;
                        } else {
                            savingsRange = this.getImpact(isCompleted, p.impact, isGresb);
                        }

                        const project: ProjectView = {
                            id: p.id,
                            name: `${p.code} ${p.title}`,
                            assigneeName: assignee && assignee.fullName,
                            buildingId: building.id,
                            buildingName: building.name,
                            buildingSystem: systemType && systemType.name || null,
                            cost: p.cost && p.cost.totalCost || null,
                            impact: savingsRange,
                            identifyingOrg: p.identificationSource,
                            dateIdentified: p.issue && p.issue.identifiedDate && moment(p.issue.identifiedDate).format('MM/DD/YYYY') || '',
                            dateIdentifiedSort: p.issue && p.issue.identifiedDate && moment(p.issue.identifiedDate).format('YYYYMMDD') || '',
                            priority: p.priority,
                            prioritySort: this.priorityMap[p.priority],
                            status: p.status.name,
                            type: p.projectType
                        };
                        return project;
                    });
                });
            });
            this.$q.all(results).then((data: any[]) => {
                this.$scope.projects = _.flatten(data);
                this.initDisplayBuildings();
            }).finally(() => {
                this.groupProjects(this.$scope.projects);
                if (this.Auth.hasFunctionality(GRESB_REPORTING)) {
                    this.groupProjects(this.$scope.csvProjects, true);
                }
                this.isLoadingData = false;
            });
        }

        private getImpact(isCompleted, impact, isGresb) {
            let savingsRange = '';
            if (isGresb) {
                if (isCompleted) {
                    if (impact && impact.totalActualSavingsAmount != null && impact.totalActualSavingsAmount != undefined) {
                        savingsRange = `$${impact.totalActualSavingsAmount}`;
                    }
                } else {
                    let min = '';
                    let max = '';
                    if (impact && impact.totalEstimatedSavingsAmountMin != null && impact.totalEstimatedSavingsAmountMin != undefined) {
                        min = `$${(impact && impact.totalEstimatedSavingsAmountMin)}`;
                    }
                    if (impact && impact.totalEstimatedSavingsAmountMax != null && impact.totalEstimatedSavingsAmountMax != undefined) {
                        max = `$${(impact && impact.totalEstimatedSavingsAmountMax)}`;
                    }
                    savingsRange = min !== '' && min !== '$0' ? `${min} - ${max}` : max;
                }
            } else if (impact && impact.totalEstimatedSavingsAmountMax != null && impact.totalEstimatedSavingsAmountMax != undefined) {
                savingsRange = `$${impact.totalEstimatedSavingsAmountMax}`;
            }
            return savingsRange;
        }

        private populateProjectsGresb(projects: any[], building) {
            projects.map((p: aq.models.projects.Project) => {
                const systemType = p.systemTypes && p.systemTypes[0] || '';
                const assignee: aq.common.models.User = _.find(this.users, (user) => user.id == p.assignee);
                const project: aq.models.projects.ExportProject = {
                    accountName: `"${this.account.accountName}"` || '',
                    buildingName: `"${building.name}"` || '',
                    projectType: p.projectType && ProjectType[p.projectType] || '',
                    gresbProjectCategories: p.gresbProjectCategories && `"${GresbProjectCategories[p.gresbProjectCategories]}"` || '',
                    title: p.code && p.title && `"${p.code} ${p.title}"` || '',
                    statusName: p.status && p.status.name || '',
                    priority: p.priority && ProjectPriority[p.priority] || '',
                    issueDiagnosis: p.issue && `"${p.issue.diagnosis}"` || '',
                    issueCause: p.issue && p.issue.causes && p.issue.causes.length > 0 && `"${p.issue.causes[0]}"` || '',
                    issueCause2: p.issue && p.issue.causes && p.issue.causes.length > 1 && `"${p.issue.causes[1]}"` || '',
                    issueCause3: p.issue && p.issue.causes && p.issue.causes.length > 2 && `"${p.issue.causes[2]}"` || '',
                    issueRecommendation: p.issue && p.issue.recommendations && p.issue.recommendations.length > 0 && `"${p.issue.recommendations[0]}"` || '',
                    issueRecommendation2: p.issue && p.issue.recommendations && p.issue.recommendations.length > 1 && `"${p.issue.recommendations[1]}"` || '',
                    issueRecommendation3: p.issue && p.issue.recommendations && p.issue.recommendations.length > 2 && `"${p.issue.recommendations[2]}"` || '',
                    extraNotes: `"${p.extraNotes}"` || '',
                    systemTypeName: systemType && systemType.name || '',
                    issueStartDate: p.issue && p.issue.startDate && moment(p.issue.startDate).format('MM/DD/YYYY') || '',
                    issueIdentifiedDate: p.issue && p.issue.identifiedDate && moment(p.issue.identifiedDate).format('MM/DD/YYYY') || '',
                    issuePotentialEndDate: p.issue && p.issue.potentialEndDate && moment(p.issue.potentialEndDate).format('MM/DD/YYYY') || '',
                    netImpactLineItemType: p.impact && p.impact.impactLineItems && p.impact.impactLineItems.length > 0 && p.impact.impactLineItems[0].impactType && ImpactType[p.impact.impactLineItems[0].impactType] || '',
                    netImpactLineItemType2: p.impact && p.impact.impactLineItems && p.impact.impactLineItems.length > 1 && p.impact.impactLineItems[1].impactType && ImpactType[p.impact.impactLineItems[1].impactType] || '',
                    netImpactLineItemType3: p.impact && p.impact.impactLineItems && p.impact.impactLineItems.length > 2 && p.impact.impactLineItems[2].impactType && ImpactType[p.impact.impactLineItems[2].impactType] || '',
                    netImpactLineItemScore: p.impact && p.impact.impactLineItems && p.impact.impactLineItems.length > 0 && p.impact.impactLineItems[0].score || '',
                    netImpactLineItemScore2: p.impact && p.impact.impactLineItems && p.impact.impactLineItems.length > 1 && p.impact.impactLineItems[1].score || '',
                    netImpactLineItemScore3: p.impact && p.impact.impactLineItems && p.impact.impactLineItems.length > 2 && p.impact.impactLineItems[2].score || '',
                    netImpactLineItemEstimatedSavingsAmountMin: p.impact && p.impact.totalEstimatedSavingsAmountMin !== null ? p.impact.totalEstimatedSavingsAmountMin : '',
                    netImpactLineItemEstimatedSavingsAmountMax: p.impact && p.impact.totalEstimatedSavingsAmountMax !== null ? p.impact.totalEstimatedSavingsAmountMax : '',
                    netImpactLineItemActualSavings: p.impact && p.impact.totalActualSavingsAmount !== null ? p.impact.totalActualSavingsAmount : '',
                    netImpactLineItemEstimatedSavingsAmountEnergyMin: p.impact && p.impact.totalEstimatedSavingsAmountEnergyMin !== null ? p.impact.totalEstimatedSavingsAmountEnergyMin : '',
                    netImpactLineItemEstimatedSavingsAmountEnergyMax: p.impact && p.impact.totalEstimatedSavingsAmountEnergyMax !== null ? p.impact.totalEstimatedSavingsAmountEnergyMax : '',
                    netImpactLineItemActualSavingsAmountEnergy: p.impact && p.impact.totalActualSavingsAmountEnergy !== null ? p.impact.totalActualSavingsAmountEnergy : '',
                    netImplementationCostBudgetType: p.cost && p.cost.implementationCostLineItems && p.cost.implementationCostLineItems.length > 0 && p.cost.implementationCostLineItems[0].budgetType && BudgetType[p.cost.implementationCostLineItems[0].budgetType] || '',
                    netImplementationCostAmount: p.cost && p.cost.totalCost || '',
                    netImplementationCostCostType: p.cost && p.cost.implementationCostLineItems && p.cost.implementationCostLineItems.length > 0 && p.cost.implementationCostLineItems[0].costType && CostType[p.cost.implementationCostLineItems[0].costType] || '',
                    identificationSource: p.identificationSource && IdentificationSource[p.identificationSource] || '',
                    identificationMethod: p.identificationMethod && IdentificationMethod[p.identificationMethod] || '',
                    assignee: assignee && `"${assignee.email}"` || '',
                };
                this.$scope.csvProjects.push(project);
            })
        }

        private groupProjects(projects, csv?: boolean) {
            type projectType = aq.models.projects.ExportProject | ProjectView;
            const statusKey = csv ? 'statusName' : 'status';
            const assigneeKey = csv ? 'assignee' : 'assigneeName';
            const systemKey = csv ? 'systemTypeName' : 'buildingSystem';
            const typeKey = csv ? 'projectType' : 'type';
            const idKey = csv ? 'identificationSource' : 'identifyingOrg';
            const scopeKey = csv ? 'projectGroupsCSV' : 'projectGroups';
            const { groupBy } = this.$scope.config;
            this.$scope[scopeKey] = [];
            let groupMap;

            switch (groupBy) {
                case 'buildingName':
                    groupMap = _.groupBy(projects, (project: projectType) => project.buildingName);
                    break;
                case 'status':
                    groupMap = _.groupBy(projects, (project: projectType) => project[statusKey]);
                    break;
                case 'assigneeName':
                    groupMap = _.groupBy(projects, (project: projectType) => project[assigneeKey]);
                    break;
                case 'buildingSystem':
                    groupMap = _.groupBy(projects, (project: projectType) => project[systemKey]);
                    break;
                case 'priority':
                    groupMap = _.groupBy(projects, (project: projectType) => project.priority);
                    break;
                case 'type':
                    groupMap = _.groupBy(projects, (project: projectType) => project[typeKey]);
                    break;
                case 'identifyingOrg':
                    groupMap = _.groupBy(projects, (project: projectType) => project[idKey]);
                    break;
                default:
                    groupMap = { '': projects };
            }
            if (groupBy !== this.sortColumn) {
                _.each(groupMap, (value, key) => {
                    this.$scope[scopeKey].push({
                        name: key,
                        projects: this.sortColumn
                            ? _.orderBy(value, this.alternateSortFieldMap[this.sortColumn] || this.sortColumn, this.sortDirection)
                            : value
                    });
                });
            } else {
                const keys = _.orderBy(Object.keys(groupMap), null, this.sortDirection);
                _.each(keys, (key) => {
                    this.$scope[scopeKey].push({
                        name: key,
                        projects: groupMap[key]
                    });
                });
            }
            this.processProjects(this.$scope[scopeKey], csv);
        }

        private processProjects(projects, csv: boolean) {
            const scopeKey = csv ? 'projectsProcessedCSV' : 'projectsProcessed';
            this.$scope[scopeKey] = [];
            // wipe previously collapsed data
            this.projectGroupsCollapsedState = {};
            _.each(projects, (projectGroup) => {
                if (projectGroup.name) {
                    this.$scope[scopeKey].push({
                        isHeader: true,
                        name: projectGroup.name
                    });
                    // add in collapsable state for each group
                    if (!csv) {
                        this.projectGroupsCollapsedState[projectGroup.name] = false;
                    }
                }
                this.$scope[scopeKey].push(...projectGroup.projects);
            });
        }

        private toggleCollapseGroup(groupName: string) {
            this.projectGroupsCollapsedState[groupName] = !this.projectGroupsCollapsedState[groupName];
        }
    }
    angular.module('aq.dashboard.widgets').controller('ProjectListWidgetCtrl', ProjectListWidgetCtrl);
}
