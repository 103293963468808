namespace aq.publicDisplay {
    export class PublicDisplayMainCtrl {
        /* @ngInject */
        constructor(
            protected $scope: PublicDisplayMainCtrlScope,
            protected account: aq.common.models.Account,
            protected dashboards: Array<aq.dashboard.models.Dashboard | aq.dashboard.models.DashboardElement>,
            protected $state,
            protected loading,
            protected $mdMedia
        ) {
            dashboards.forEach((dashboard) => {
                dashboard.state = 'aq.publicDisplay.configurable'
            });

            $scope.menu = {
                dashboards: dashboards,
                open: false
            }
        }

        public linkClicked = () => {
            this.$scope.menu.open = !this.$mdMedia('gt-sm');
            this.loading.start();
            this.$scope.$broadcast('abortLoading');
        }
    }

    export interface PublicDisplayMainCtrlScope extends ng.IScope {
        menu: {
            open: boolean,
            dashboards: aq.dashboard.models.Dashboard[]
        };
    }

    angular
        .module('aq.publicDisplay')
        .controller('PublicDisplayMainCtrl', PublicDisplayMainCtrl);
}
