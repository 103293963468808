'use strict';

angular
    .module('registration')
    .controller('WelcomeCtrl', function (
        $scope,
        $window,
        RegistrationService,
        enrollmentId) {

        $scope.user = RegistrationService.getUser();

        $scope.goHome = function () {
            var loginUrl = '/';
            if (enrollmentId) {
                loginUrl += '?enrollmentId=' + enrollmentId;
            }
            $window.location = loginUrl;
            // TODO (dalibor) use state when fully angularized
        }

    });
