'use strict';

angular
.module('aq.filters.arrays')
.filter('difference', function () {
    return function(input, n) {
        return _.reject(input, function (building) {
                    return _.findById(n, building.id);
                });
    }
});