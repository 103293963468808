/**
 * Singletone service for having global access to accounts brand colors, e.g. in case of
 * Pristine we have custom colors for charts and drillin on optimization - later we can access
 * colors array via this service in colors filter we are using in charts etc on every part of app.
 * This is initialized only once, in main.js after account is loaded
 */
angular.module('aq.services').factory('BrandColors', function() {
    var colors = [];
    return {
        get: function() {
            return this.colors;
        },
        set: function (colors) {
            this.colors = colors;
        }
    }
})