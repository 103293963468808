angular.module('dashboard', [
    'adf.material',
    'aq.auth',
    'aq.dashboard.widgets',
    'aq.filters.formatters',
    'aq.filters.math',
    'aq.filters.units',
    'aq.services',
    'aq.services.cache',
    'aq.services.data',
    'aq.services.options',
    'aq.services.user',
    'aq.ui.loading',
    'charting',
    'energyInsights',
    'mapboxgl-directive',
    'ngMessages',
    'ngStorage',
    'restangular',
    'ui.router'
])
    .config((RestangularProvider, $stateProvider: ng.ui.IStateProvider, $locationProvider, dashboardProvider) => {

        $stateProvider.state('aq.dashboard', {
            abstract: true,
            url: '/accounts/:accountId/dashboard',
            templateUrl: 'app/dashboard/main/main.html',
            controller: 'DashboardMainCtrl as mainCtrl',
            data: {
                appName: 'Dashboards'
            },
            resolve: {
                account: (
                    Restangular: restangular.IService,
                    $stateParams,
                    waitForAuthToken
                ): ng.IPromise<aq.common.models.Account> => {
                    return Restangular.one('accounts', $stateParams.accountId).get({ single: true, inflate: 'users' });
                },
                dashboards: (account: aq.common.models.Account, waitForAuthToken): ng.IPromise<aq.dashboard.models.Dashboard[]> => {
                    return account.all('dashboards').getList();
                }
            }
        })
            .state('aq.dashboard.configurable', {
                url: '/configurable/:dashboardId',
                templateUrl: 'app/dashboard/configurable/configurable.html',
                controller: 'ConfigurableCtrl as configurableCtrl',
                resolve: {
                    dashboard: (account: aq.common.models.Account,
                        dashboards: aq.admin.dashboards.Dashboard[],
                        DashboardSelectService: aq.dashboard.DashboardSelectService,
                        $stateParams,
                        $state,
                        Auth,
                        resolver,
                        waitForAuthToken) => {
                        return resolver(async () => {
                            const redirect = DashboardSelectService.getRedirect($stateParams.dashboardId, account, dashboards);
                            if (redirect) {
                                $state.go(redirect.state, redirect.params);
                                return;
                            }
                            const removeWidgetTypes: string[] = Object.keys(
                                _.pickBy(dashboardProvider.$get().widgets, (widget) => {
                                    return widget.isBeta &&
                                        !(account.accountName.startsWith('AQ Demo') ||
                                            Auth.hasFunctionality(widget.title + ' Widget'));
                                })
                            );
                            const dashboard = await account.one('dashboards', $stateParams.dashboardId)
                                .get({ inflate: 'configuration,publicDisplay', single: true });

                            try {
                                dashboard.configuration.json = JSON.parse(dashboard.configuration.json);
                                if (dashboard.configuration.json.rows && removeWidgetTypes) {
                                    dashboard.configuration.json.rows.forEach((row) => {
                                        if (row.columns) {
                                            row.columns.forEach((column) => {
                                                if (column.widgets) {
                                                    _.remove(column.widgets, (widget) => removeWidgetTypes.includes(widget.type));
                                                }
                                            });
                                        }
                                    });
                                }
                            } catch (e) {
                                dashboard.configuration.json = {};
                            }
                            return dashboard;
                        });
                    },
                    token: (account: aq.common.models.Account) => {
                        return account.customGET('queryTokenForUser');
                    },
                    accountId($stateParams) {
                        return $stateParams.accountId;
                    }
                }
            })
            .state('aq.dashboard.noAvailableDashboards', {
                url: '/unavailable/',
                templateUrl: 'app/dashboard/noAvailableDashboards/noAvailableDashboards.html',
                controller: 'NoAvailableDashboardsCtrl as vm'
            });

        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });
        RestangularProvider.setBaseUrl('/api/v1');
        RestangularProvider.setDefaultHeaders({ 'auth-token': window['token'] });

        dashboardProvider
            .structure('100', {
                rows: [{
                    columns: [
                        { flex: '100' }
                    ]
                }]
            })
            .structure('50-50', {
                rows: [{
                    columns: [
                        { flex: '50' },
                        { flex: '50' }
                    ]
                }]
            })
            .structure('33-66', {
                rows: [{
                    columns: [{
                        flex: '33',
                        widgets: []
                    }, {
                            flex: '66',
                            widgets: []
                        }]
                }]
            })
            .structure('50-50/100', {
                rows: [
                    {
                        columns: [
                            { flex: '50' },
                            { flex: '50' }
                        ]
                    },
                    {
                        columns: [
                            { flex: '100' }
                        ]
                    }
                ]
            })
            .structure('100/33-33-33', {
                rows: [
                    {
                        columns: [
                            { flex: '100' }
                        ]
                    },
                    {
                        columns: [
                            { flex: '33' },
                            { flex: '33' },
                            { flex: '33' }
                        ]
                    }
                ]
            })
            .structure('33-33-33/100', {
                rows: [
                    {
                        columns: [
                            { flex: '33' },
                            { flex: '33' },
                            { flex: '33' }
                        ]
                    },
                    {
                        columns: [
                            { flex: '100' }
                        ]
                    }
                ]
            })
            .structure('100/50-50', {
                rows: [
                    {
                        columns: [
                            { flex: '100' }
                        ]
                    },
                    {
                        columns: [
                            { flex: '50' },
                            { flex: '50' }
                        ]
                    }
                ]
            })
            .structure('100/50-50/100', {
                rows: [
                    {
                        columns: [
                            { flex: '100' }
                        ]
                    },
                    {
                        columns: [
                            { flex: '50' },
                            { flex: '50' }
                        ]
                    },
                    {
                        columns: [
                            { flex: '100' }
                        ]
                    }
                ]
            })
            .structure('25-75(100/50-50)', {
                rows: [
                    {
                        columns: [
                            { flex: '25' },
                            {
                                flex: '75',
                                rows: [
                                    {
                                        columns: [
                                            { flex: '100' }
                                        ]
                                    },
                                    {
                                        columns: [
                                            { flex: '50' },
                                            { flex: '50' }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            });

    })
    .run(function ($rootScope, UserService, Auth) {
        UserService.init();
        window['mapboxgl'].accessToken = 'pk.eyJ1IjoibWRvbm92YW4yMDEwIiwiYSI6ImNqZGNnMnRvYTAydWEyenBncHp2azFwdmMifQ.lCJPiU12S_roZa_O0M6FBw';
    });
