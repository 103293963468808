angular
    .module('aq.admin.productDesign',
        ['aq.ui.confirmation', 'colorpicker.module', 'ui.select2',
            'aq.ui.image', 'aq.ui.elemSelector', 'aq.linechart', 'aq.ui.periodSelectorNew'])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.admin.productDesign', {
                url: '/productDesign',
                templateUrl: 'app/admin/productDesign/main/main.html',
                controller: 'MainCtrl',
                data: {
                    appName: 'Product Design'
                }
            })
            .state('aq.admin.productDesign.home', {
                url: '/home',
                templateUrl: 'app/admin/productDesign/home/home.html',
                controller: 'HomeCtrl',
                data: {
                    breadcrumb: 'AQ Bootstrap',
                    icon: '.aq-icons-bootstrap'
                }
            })
            .state('aq.admin.productDesign.icons', {
                url: '/icons',
                templateUrl: 'app/admin/productDesign/icons/icons.html',
                controller: 'IconsCtrl'
            })
            .state('aq.admin.productDesign.charts', {
                url: '/charts',
                templateUrl: 'app/admin/productDesign/charts/charts.html',
                controller: 'ChartsCtrl'
            })
            .state('aq.admin.productDesign.email', {
                url: '/email',
                templateUrl: 'app/admin/productDesign/email/email.html',
                controller: 'EmailCtrl',
                data: {
                    breadcrumb: 'Email',
                    icon: '.aq-icons-templates'
                }
            })
            .state('aq.admin.listFilter', {
                url: '/listFilter',
                templateUrl: 'app/admin/productDesign/listFilter/listFilter.html',
                controller: 'AdminListFilterCtrl',
                data: {
                    breadcrumb: 'List & Filter',
                    icon: '.aq-icons-templates'
                }
            })
            .state('aq.admin.productDesign.aqcharts', {
                url: '/aqcharts',
                templateUrl: 'app/admin/productDesign/aqcharts/charts.html',
                controller: 'AqChartsCtrl',
                data: {
                    breadcrumb: 'AQ Charts',
                    icon: '.aq-icons-charts'
                }
            })
            .state('aq.admin.productDesign.components', {
                url: '/components',
                templateUrl: 'app/admin/productDesign/components/components.html',
                controller: 'ComponentsCtrl',
                data: {
                    breadcrumb: 'Components',
                    icon: '.aq-icons-components'
                }
            })
            .state('aq.admin.productDesign.filters', {
                url: '/filters',
                templateUrl: 'app/admin/productDesign/filters/filters.html',
                controller: 'FiltersCtrl'
            })
            .state('aq.admin.productDesign.testCoverage', {
                url: '/testCoverage',
                templateUrl: 'app/admin/productDesign/testCoverage/testCoverage.html',
                controller: 'TestCoverageCtrl'
            });

    });
