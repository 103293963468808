angular.module('aq.ui.image', ['aq.services.filepicker', 'aq.messages', 'aq.auth'])
.controller('AqImageCtrl', ($scope, Auth, FilePicker, Messages) => {
    $scope.hasAccess = false;
    if (Auth.check({access: 'EDIT'})) {
        $scope.hasAccess = true;
    }

    $scope.placeholderClass = $scope.placeholder ? $scope.placeholder : 'basic';
    $scope.placeholderWidth = $scope.width ? parseInt($scope.width) : 300;
    $scope.placeholderHeight = $scope.height ? parseInt($scope.height) : 200;

    // TODO: change this directive to be more flexible in the returned image url/properties - right now it assumes your implementation
    $scope.selectImage = function () {
        if ($scope.onEdit) {
            $scope.onEdit();
        }
        FilePicker.pickAndStoreImage($scope.placeholderWidth, $scope.placeholderHeight, ['IMAGE_SEARCH','COMPUTER']).then(function (result) {
            // Keeping these for backwards compatibility
            $scope.model.imageThumbnailUrl = result.thumbnailImageUrl;
            $scope.model.imageUrl = result.imageUrl;

            // Now supporting fields not named 'imageUrl' and 'imageThumbnailUrl'
            $scope.thumbnailUrl = result.thumbnailImageUrl;
            $scope.url = result.imageUrl;

            if ($scope.onChange) {
                $scope.onChange();
            } else if ($scope.model.put) {
                // if given restangularized object then automatically update model
                // when url is uploaded
                $scope.model.put();
            }
        }, function (result) {
            if (result.code != 101) Messages.error('Error uploading image');
            if ($scope.onCancel) {
                $scope.onCancel();
            }
        });
    };
})
.directive('aqImage', function(FilePicker, Messages, Auth) {
    return {
        restrict : 'E',
        replace: true,
        templateUrl: 'app/common/directives/image/image.html',
        scope: {
            url: '=',
            thumbnailUrl: '=?',
            model: '=',
            editable: '=',
            placeholder: '@',
            height: '@',
            width: '@',
            onEdit: '&?',
            onCancel: '&?',
            onChange: '&?'
        },
        controller: 'AqImageCtrl'
    };
});
