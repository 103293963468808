namespace aq.reports {
    export abstract class BaseDocraptorReportCtrl {
        private isDocumentReady: boolean;
        public isDocumentError: boolean;
        private readonly defaultDelay: number = 2000;

        constructor(
            protected $timeout: ng.ITimeoutService
        ) {
            this.isDocumentReady = false;
            this.isDocumentError = false;
        }

        public notifyDocumentReady(customDelay?) {
            const delay = customDelay != null ? customDelay : this.defaultDelay;
            this.$timeout(() => {
                this.isDocumentReady = true;
            }, delay);
        }
    }
    angular.module('aq.reports').controller('BaseDocraptorReportCtrl', BaseDocraptorReportCtrl);
}
