angular.module('aq.reports').component('reportHeader', {
    templateUrl: 'app/reports/summary/common/reportHeader/reportHeader.html',
    controller: function() {},
    controllerAs: 'vm',
    bindings: {
        building: '<',
        areaMeasurementUnit: "<",
        colsize: '<',
        occupancyPercent: '<',
        personas: '<'
    },
});
