namespace aq.components {

    interface BuildingGroup {
        id: number;
        name: string;
    }
    export interface BuildingSelectorGroupItem {
        id: number;
        name: string;
        buildings: BuildingSelectorBuildingItem[];
        isHidden: boolean;
    }
    export interface BuildingSelectorBuildingItem {
        id: number | string;
        name: string;
        imageUrl: string;
        isHidden: boolean;
    }
    export class BuildingSelector {
        private accountId: number;
        private buildingGroups: BuildingGroup[];
        private buildings: aq.common.models.Building[];
        private hasGroups: boolean;
        private filter: string = '';
        private currentBuilding: aq.common.models.Building;
        private isMenuActive: boolean;
        private buildingSelectorMenu: BuildingSelectorGroupItem[];
        private isLoaded: boolean;
        private onSelectBuilding: (data: { buildingId: string }) => void;
        private enrollment: aq.propertySettings.BuildingEnrollment;


        /* @ngInject */
        constructor(
            private $state: ng.ui.IStateService,
            private $rootScope: ng.IRootScopeService,
            private BuildingSelectorHelperService: aq.services.BuildingSelectorHelperService,
            private $q: ng.IQService,
            private $timeout: ng.ITimeoutService,
            private AppStorageService: aq.services.AppStorageService,
            private Messages,
            private Auth: aq.services.Auth
        ) {
            this.buildingSelectorMenu = [];
            this.hasGroups = false;
            this.isMenuActive = false;
            this.filter = '';
            this.$rootScope.$on('$stateChangeSuccess', () => {
                this.isMenuActive = !!this.$state.params.buildingId;
            });
            this.$rootScope.$on('buildingContextChange', (event, data) => {
                const buildingId = data.buildingId;
                if (this.currentBuilding && this.currentBuilding.id == buildingId) {
                    return;
                }
                const buildingToSelect = _.find(this.buildings, (building: Building) => building.id == buildingId);
                if (!buildingToSelect) {
                    return;
                }
                this.setBuilding(buildingToSelect);
                this.generateMenuItems();
                this.filterBuildings();
            });
            this.$rootScope.$on('BUILDING_ENROLLMENT_UPDATED', (event: any, context: any) => {
                this.enrollment = context.buildingEnrollment;
            });
            this.$rootScope.$on('BUILDING_UPDATED', (event: any, context: any) => {
                this.loadEnrollmentData(this.currentBuilding.id);
            });
            this.$rootScope.$on('BUILDING_PERSONNEL_UPDATED', (event: any, context: any) => {
                this.loadEnrollmentData(this.currentBuilding.id);
            });
            this.$rootScope.$on('MANUAL_READING_CREATED', (event: any, context: any) => {
                this.loadEnrollmentData(this.currentBuilding.id);
            });
        }
        $onInit() {
            this.initData();
        }
        $onChanges(data) {
            this.initData();
        }

        public setBuilding(building: aq.common.models.Building) {
            this.currentBuilding = building;
            this.onSelectBuilding({ buildingId: building.id });
            this.setBuildingInStorage(this.currentBuilding.id);
            this.loadEnrollmentData(building.id);
        }
        public goToBuildingDetails(buildingId) {
            this.$state.go('aq.properties.buildings.form.configuration', { accountId: this.accountId, buildingId }, { reload: true });
        }
        public filterBuildings() {
            const searchTerm = this.filter.toLowerCase();
            _.forEach(this.buildingSelectorMenu, (group: BuildingSelectorGroupItem) => {
                const isGroupNameMatch = searchTerm != '' && group.name.toLowerCase().search(searchTerm) > -1;
                _.forEach(group.buildings, (building: BuildingSelectorBuildingItem) => {
                    const isBulidingMatch = isGroupNameMatch || building.name.toLowerCase().search(searchTerm) > -1;
                    building.isHidden = !isBulidingMatch;
                });
                group.isHidden = !isGroupNameMatch && !_.some(group.buildings, (building) => !building.isHidden);
            });
        }
        public selectFirstItemOnEnter(keyEvent): boolean {
            if (keyEvent.which == 13) {
                const filteredBuildings: BuildingSelectorBuildingItem[] =
                    _.filter(_.flatMap(this.buildingSelectorMenu, 'buildings'), { isHidden: false });
                if (filteredBuildings.length > 0) {
                    this.select(filteredBuildings[0]);
                    return true;
                }
            }
            return false;
        }
        public generateMenuItems() {
            const menu: BuildingSelectorGroupItem[] = [];
            const otherGroup: BuildingSelectorGroupItem = {
                id: 0,
                name: 'Ungrouped',
                buildings: [],
                isHidden: false
            };
            const currentBuildingId = this.currentBuilding ? this.currentBuilding.id : -1;
            const sortedBuildingGroups = _.sortBy(this.buildingGroups, (g: BuildingGroup) => g.name.toLowerCase());
            _.forEach(sortedBuildingGroups, (buildingGroup: BuildingGroup) => {
                menu.push({
                    id: buildingGroup.id,
                    name: buildingGroup.name,
                    buildings: [],
                    isHidden: false
                });
            });
            const sortedBuildings = _.sortBy(this.buildings, (b: Building) => b.name);
            _.forEach(sortedBuildings, (building: Building) => {
                if (building.id == currentBuildingId) {
                    return;
                }
                const buildingItem = {
                    id: building.id,
                    name: building.name,
                    imageUrl: building.imageUrl,
                    isHidden: false
                };
                const groupId = this.getBuildingGroupId(building);
                if (groupId) {
                    let group: BuildingSelectorGroupItem = _.find(menu, (item) => item.id == groupId);
                    if (!group) {
                        group = otherGroup;
                    }
                    group.buildings.push(buildingItem);
                } else {
                    otherGroup.buildings.push(buildingItem);
                }
            });
            if (menu.length == 0 || otherGroup.buildings.length > 0) {
                menu.push(otherGroup);
            }
            this.buildingSelectorMenu = menu;
        }
        public formatSizeSqFt(size: number): string {
            size = Math.round(size);
            let result = '';
            while (size >= 1000) {
                const remainder = (size % 1000);
                let strRemainder = remainder.toFixed(0);
                while (strRemainder.length < 3) {
                    strRemainder = '0' + strRemainder;
                }
                result = ',' + strRemainder;
                size = Math.round((size - remainder) / 1000);
            }
            if (size > 0) {
                result = size + result;
            }
            if (!result) {
                return null;
            }
            return `Size: ${result} sq. ft.`;
        }
        private initData() {
            if (!this.accountId) {
                return;
            }
            this.isLoaded = false;
            const buildingsPromise = this.BuildingSelectorHelperService.getBuildings();
            const buildingGroupsPromise = this.BuildingSelectorHelperService.getBuildingGroups(this.accountId);
            this.$q.all([
                buildingsPromise,
                buildingGroupsPromise
            ]).then((response) => {
                this.buildings = _.filter(response[0], { status: 'ACTIVE' });
                this.buildingGroups = response[1];
                this.hasGroups = this.buildingGroups && this.buildingGroups.length > 0;
                this.updateState();
                if (this.currentBuilding) {
                    this.onSelectBuilding({ buildingId: this.currentBuilding.id });
                    this.setBuildingInStorage(this.currentBuilding.id);
                    this.loadEnrollmentData(this.currentBuilding.id);
                }
                this.isLoaded = true;
            });
        }
        private getBuildingGroupId(building) {
            if (typeof building.buildingGroup === 'object' && building.buildingGroup !== null) {
                return building.buildingGroup.id;
            }
            return building.buildingGroup;
        }
        private getBuildingFromStorage() {
            const storage = this.AppStorageService.getStorage('buildingSelector', this.accountId);
            if (storage) {
                return _.find(this.buildings, { id: storage.buildingId });
            }
            return null;
        }
        private getBuildingFromUrl(): aq.common.models.Building {
            const building = _.find(this.buildings, (b) => b.id == this.$state.params.buildingId);
            return building;
        }
        private setBuildingInStorage(buildingId) {
            this.AppStorageService.setAttr('buildingId', buildingId, 'buildingSelector', this.accountId);
        }
        private menuHandle($mdMenuIsOpen, $mdMenu, event): void {
            if ($mdMenuIsOpen) {
                $mdMenu.close(event);
            } else {
                this.clearFilter();
                $mdMenu.open(event);
            }
        }
        private updateState(): void {
            this.isMenuActive = !!this.$state.params.buildingId;
            this.currentBuilding = this.getBuildingFromUrl() || this.getBuildingFromStorage() || this.buildings[0];
            this.generateMenuItems();
            this.filterBuildings();
        }
        private clearFilter(): void {
            this.filter = '';
            this.filterBuildings();
        }
        private getRedirect(): string {
            return this.$state.current.data.redirectState ? this.$state.current.data.redirectState : this.$state.current.name;
        }
        private navigate(building?: aq.common.models.Building) {
            const bldg = building ? building : this.currentBuilding;
            this.$state.go(this.getRedirect(), { buildingId: bldg.id }, { reload: true });
        }
        private select(buildingItem: BuildingSelectorBuildingItem): void {
            if (buildingItem.id.toString() == this.currentBuilding.id) {
                return;
            }
            const building: aq.common.models.Building = _.find(this.buildings, (b) => b.id == buildingItem.id);
            this.setBuilding(building);
            this.Messages.success('Successfully selected building');
            this.clearFilter();
            this.$timeout(() => this.generateMenuItems(), 300);
            if (!this.isMenuActive) {
                return;
            }

            this.navigate();
        }
        private isEmptyFilterBuildingList() {
            return _.every(this.buildingSelectorMenu, (item) => item.isHidden);
        }
        private loadEnrollmentData(buildingId) {
            this.BuildingSelectorHelperService.getBuildingEnrollment(parseInt(buildingId))
                .then((data) => {
                    this.enrollment = data;
                });
        }
    }

    angular
        .module('aq.ui')
        .component('buildingSelector', {
            templateUrl: 'app/common/components/buildingSelector/buildingSelector.html',
            controller: BuildingSelector,
            controllerAs: 'vm',
            bindings: {
                accountId: '<',
                onSelectBuilding: '&'
            }
        });
}
