namespace aq.deviceManagement {
    export class DeviceDetailGallery {
        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private device
        ) {
        }

        public onDeviceChange(event) {
            this.$scope.$emit('DEVICE_UPDATED', event.device);
        }
    }

    angular
        .module('deviceManagement')
        .controller('DeviceDetailGallery', DeviceDetailGallery);
}
