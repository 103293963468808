namespace aq.services {

    import IssueListResponse = aq.models.aidIssue.IssueListResponse;
    import IssueQueryParams = aq.models.aidIssue.IssueQueryParams;
    import IssueResponse = aq.models.aidIssue.IssueResponse;
    import IssueFeedbackResponse = aq.models.aidIssue.IssueFeedbackResponse;

    export class AidIssueService {

        /* @ngInject */
        constructor(
            private $http: ng.IHttpService,
            private resolver: aq.services.Resolver,
            private Messages: aq.services.Messages,
            private AQ_API_URL: string,
            private AuthToken: string
        ) {
        }

        public createFeedback(feedback: aq.models.aidIssue.IssueFeedbackCreateRequest): Promise<IssueFeedbackResponse> {
            try {
                return this.resolver(async () => {
                    const response = await this.$http({
                        method: 'POST',
                        headers: this.getHeaders(),
                        data: JSON.stringify(_.omit(feedback, 'issue')),
                        url: `${this.AQ_API_URL}automatic-issue-detection/v1/${feedback.issue}/feedback`
                    });
                    return response.data;
                });
            } catch (error) {
                throw error;
            }
        }

        public getIssue(id: string): Promise<IssueResponse> {
            try {
                return this.resolver(async () => {
                    const response = await this.$http({
                        method: 'GET',
                        headers: this.getHeaders(),
                        url: `${this.AQ_API_URL}automatic-issue-detection/v1/${id}`
                    });
                    return response.data;
                });
            } catch (error) {
                throw error;
            }
        }

        public deleteIssue(id: string): Promise<any> {
            try {
                return this.resolver(async () => {
                    const response = await this.$http({
                        method: 'DELETE',
                        headers: this.getHeaders(),
                        url: `${this.AQ_API_URL}automatic-issue-detection/v1/${id}`
                    });
                    return response.data; // TODO: If delete is ever implemented in UI, this is probably not a useful return
                });
            } catch (error) {
                throw error;
            }
        }

        public getIssues(params: IssueQueryParams): Promise<IssueListResponse> {
            try {
                return this.resolver(async () => {
                    const response = await this.$http({
                        method: 'GET',
                        headers: this.getHeaders(),
                        url: `${this.AQ_API_URL}automatic-issue-detection/v1`,
                        params
                    });
                    return response.data;
                });
            } catch (error) {
                throw error;
            }
        }

        private getHeaders(): {[key: string]: any} {
            return {
                'Content-Type': 'application/json',
                'Authorization': this.AuthToken
            };
        }
    }

angular
    .module('aq.services')
    .service('AidIssueService', AidIssueService);
}
