namespace aq.tenantbilling {

    import LeaseCreationRequest = aq.tenantbilling.LeaseCreationRequest;
    import UnitObject = aq.tenantbilling.UnitObject;

    export class BulkUploadMoveOutsCtrl extends aq.common.Controllers.ModalCtrl {
        public uploadData: string;
        public result: any[];
        private leases: LeaseCreationRequest[];
        private fileUploaded: boolean;
        private attemptedUpload: boolean;
        private step: number = 1;
        public valid: boolean = true;
        /* @ngInject */
        constructor(
            private Auth: aq.services.Auth,
            public $mdDialog: ng.material.IDialogService,
            private $mdStepper,
            private Messages: aq.services.Messages,
            private RestangularV3: restangular.IService,
            private building: aq.common.models.Building,
            private TenantService: aq.services.TenantService
        ) {
            super({}, $mdDialog);
        }

        downloadTemplate(): void {
            const csvColumns = [
                'residentId',
                'phoneNumber',
                'email',
                'residentName',
                'unit',
                'sqft',
                'startDate',
                'endDate'
            ];
            let csvContent = 'data:text/csv;charset=utf-8,';
            csvContent += `${csvColumns}\n`;
            const fileName = `${this.building.name} - units upload`;
            const link = document.createElement('a');
            link.setAttribute('href', encodeURI(csvContent));
            link.setAttribute('download', fileName);
            link.click();
        }

        next(): void {
            this.$mdStepper('moveOutBulkUploadStepper').goto(this.step++);
        }

        upload(): void {
            this.parseCSV();
            this.validateDates(this.result);
            this.fileUploaded = this.valid;
            this.attemptedUpload = true;
            if (!this.valid) {
                this.uploadData = null;
            }
        }

        parseCSV() {
            const lines = this.uploadData.split(/\r\n|\n|\r/);
            const result = [];
            const headers = lines[0].split(',');

            lines.shift();

            lines.forEach((line) => {
                if (line.trim().length === 0) {
                    return;
                }
                const obj = {};
                const splitLine = line.split(',');
                for (let j = 0; j < headers.length; j++) {
                    obj[headers[j]] = splitLine[j];
                }
                result.push(obj);
            });
            this.result = result;
        }

        generatePreview(): void {
            this.generateLeaseCreationRequestObjects(this.result);
            this.bulkUpdate(this.leases);
        }

        generateLeaseCreationRequestObjects(json: UnitObject[]): void {
            this.leases = json.map((unit: UnitObject) => {
                const newLease: LeaseCreationRequest = {
                    ...unit,
                    leaseIdentifier: null,
                    startDate: unit.startDate,
                    endDate: unit.endDate,
                    active: true,
                    tenant: '',
                    unitName: unit.unit
                };
                return newLease;
            });
        }

        validateDates(json: UnitObject[]) {
            this.valid = true;
            json.map((unit: UnitObject) => {
                const endDate = unit.endDate;
                if(endDate != null && endDate != "") {
                    if(!moment(endDate, 'YYYY-MM-DD', true).isValid()) {
                        this.valid = false;
                    }
                }
            });
        }

        bulkUpdate(leaseList) {
            return this.TenantService.updateLeases(leaseList, this.building.id).then((leases) => {
                const leasesLength = this.TenantService.getResponseLength(leases);
                const successMessage = `Successfully updated ${ leasesLength } leases`;
                this.$mdDialog.hide();
                this.Messages.success(successMessage)
            }).catch((error) => {
                this.$mdDialog.hide();
                this.Messages.error('Error updating leases');
            });
        }
    }

    angular
        .module('tenantBilling')
        .controller('BulkUploadMoveOutsCtrl', BulkUploadMoveOutsCtrl);
}
