import Auth = aq.services.Auth;

angular
    .module('deviceManagement', ['ngAnimate', 'aq.forms'])
    .config((RestangularProvider: restangular.IProvider, $stateProvider: ng.ui.IStateProvider) => {
        $stateProvider
            .state('aq.deviceManagement', {
                abstract: true,
                url: '/accounts/:accountId/devices-and-equipment',
                templateUrl: 'app/deviceManagement/deviceManagement.html',
                controller: 'DeviceManagementCtrl as deviceManagementCtrl',
                data: { appName: 'Devices & Equipment', auth: Auth },
                resolve: {
                    user(initUser) {
                        return initUser;
                    },
                    classesByCategory(RestangularV3: restangular.IService, waitForAuthToken) {
                        return RestangularV3.one('devices').one('available-device-classes').get();
                    },
                    // stub
                    deviceCategories(): IDeviceCategories[] {
                        return [
                            { label: 'Meters', value: 'METER', icon: 'aq-icons-generic-meter' },
                            { label: 'Networking', value: 'NETWORKING', icon: 'wifi' },
                            { label: 'Sensors', value: 'SENSOR', icon: 'settings_input_antenna' },
                            { label: 'Equipment', value: 'EQUIPMENT', icon: 'business' },
                            { label: 'Other', value: 'OTHER', icon: 'toys' }
                        ];
                    },
                    accountElement(
                        $stateParams: ng.ui.IStateParamsService,
                        Restangular: restangular.IService,
                        waitForAuthToken
                    ): restangular.IElement {
                        return Restangular.one('accounts', $stateParams.accountId);
                    },
                    account(
                        accountElement,
                        DataStore: aq.common.DataStore,
                        waitForAuthToken
                    ): ng.IPromise<aq.common.models.Account> {
                        return DataStore.get(accountElement, { single: true }, false);
                    },
                    buildings(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        waitForAuthToken
                    ): ng.IPromise<aq.common.models.Building[]> {
                        return DataStore.getList(RestangularV3.one(''), 'buildings', { orderByRecent: true });
                    },
                    measures(
                        DataStore: aq.common.DataStore,
                        RestangularV3: restangular.IService,
                        waitForAuthToken
                    ): ng.IPromise<aq.common.models.Measure[]> {
                        return DataStore.getList(RestangularV3.one('measures'));
                    },
                    aqVersion3NetworkingEnabled: (Auth, waitForAuthToken) => Auth.hasFunctionality('Aquicore 3.0 Networking')
                }
            })
            .state('aq.deviceManagement.building', {
                url: '/building/:buildingId?cat',
                reloadOnSearch: false,
                views: {
                    'devices@aq.deviceManagement': {
                        templateUrl: 'app/deviceManagement/building/overview.html',
                        controller: 'DeviceOverviewCtrl as vm'
                    }
                },
                resolve: {
                    newLayout: (Auth, waitForAuthToken) => Auth.hasFunctionality('New D&E Layout'),
                    building(
                        buildings, $stateParams: ng.ui.IStateParamsService,
                        $state: ng.ui.IStateService,
                        Restangular: restangular.IService,
                        waitForAuthToken
                    ): aq.common.models.Building {
                        let { accountId, buildingId } = $stateParams;
                        if (!buildingId) {
                            buildingId = buildings[0].id;
                            $state.go('aq.deviceManagement.building', { accountId, buildingId });
                        } else {
                            Restangular.one('accounts', accountId).one('buildings', buildingId).customPOST({}, 'record');
                        }
                        return _.findById(buildings, buildingId);
                    },
                    devices(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        building: aq.common.models.Building,
                        $location: ng.ILocationService,
                        waitForAuthToken
                    ): ng.IPromise<aq.common.models.Device[]> {
                        const category = $location.search().cat || 'METER';
                        return DataStore.getList(RestangularV3.one(''), 'devices', { buildingId: building.id, offset: 0, limit: 25, category });
                    },
                    categoryCounts(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        building,
                        waitForAuthToken
                    ) {
                        return RestangularV3.all('devices').customGET('counts-by-category', { buildingId: building.id });
                    },
                    tenants(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        building: aq.common.models.Building,
                        waitForAuthToken
                    ) {
                        return DataStore.getList(RestangularV3.one(''), 'tenants', { buildingId: building.id });
                    },
                    floors(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        building: aq.common.models.Building,
                        waitForAuthToken
                    ) {
                        return DataStore.getList(RestangularV3.one(''), 'floors', { buildingId: building.id });
                    },
                    spaces(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        building: aq.common.models.Building,
                        waitForAuthToken
                    ) {
                        return DataStore.getList(RestangularV3.one(''), 'spaces', { buildingId: building.id });
                    },
                    tags(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        building: aq.common.models.Building,
                        waitForAuthToken
                    ) {
                        return DataStore.getList(RestangularV3.one(''), 'building-tags', { buildingId: building.id, type: 'DEVICE_LOCATION' });
                    }
                },
                onEnter(Segment) {
                    Segment.trackPage('Devices & Equipment:List');
                }
            })
            .state('aq.deviceManagement.building.mapping', {
                url: '/mapping',
                data: {
                    redirectState: 'aq.deviceManagement.building'
                },
                views: {
                    'devices@aq.deviceManagement': {
                        templateUrl: 'app/deviceManagement/building/mapping.html',
                        controller: 'DeviceMappingCtrl as vm'
                    }
                },
                resolve: {
                    allDevices(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        building: aq.common.models.Building,
                        waitForAuthToken
                    ): ng.IPromise<any> {
                        return DataStore.getList(RestangularV3.one(''), 'devices', { buildingId: building.id });
                    },
                    links(building, DataStore, RestangularV3) {
                        return RestangularV3.one('devices').customGET('links', { buildingId: building.id });
                    }
                }
            })
            .state('aq.deviceManagement.building.device', {
                url: '/device/:deviceId',
                data: {
                    redirectState: 'aq.deviceManagement.building'
                },
                params: {
                    dataStatuses: null,
                    backButton: null
                },
                views: {
                    'devices@aq.deviceManagement': {
                        templateUrl: 'app/deviceManagement/device/deviceDetail.html',
                        controller: 'DeviceDetail as vm'
                    }
                },
                resolve: {
                    device(
                        account,
                        RestangularV3: restangular.IService,
                        $stateParams: ng.ui.IStateParamsService,
                        $state: ng.ui.IStateService,
                        waitForAuthToken
                    ): ng.IPromise<aq.common.models.Device> {
                        return RestangularV3.one('devices', $stateParams.deviceId).get()
                            .then((result) => {
                                if (!result) {
                                    $state.go('aq.deviceManagement.building', { accountId: account.id, buildingId: $stateParams.buildingId });
                                } else {
                                    return result;
                                }
                            });
                    },
                    links(DataStore: aq.common.DataStore, device: aq.common.models.DeviceElement) {
                        return DataStore.getList(device.all('links'));
                    },
                    manualReadings(
                        device: aq.common.models.DeviceElement,
                        DataStore: aq.common.DataStore,
                        RestangularV3: restangular.IService,
                        waitForAuthToken
                    ) {
                        if (device.collectorId) {
                            return DataStore.getList(RestangularV3.one('collectors', device.collectorId), 'manualReadings', {});
                        } else {
                            return [];
                        }
                    },
                    points(
                        $stateParams: ng.ui.IStateParamsService,
                        device: aq.common.models.Device,
                        Restangular: restangular.IService,
                        waitForAuthToken
                    ): ng.IPromise<aq.common.models.Point[]> {
                        if (device.collectorId) {
                            return Restangular
                                .one('accounts', $stateParams.accountId)
                                .one('buildings', $stateParams.buildingId)
                                .one('collectors', device.collectorId)
                                .getList('points');
                        } else {
                            return null;
                        }
                    }
                },
                onEnter(Segment) {
                    Segment.trackPage('Devices & Equipment:Details');
                }
            })
            .state('aq.deviceManagement.building.device.configuration', {
                url: '/configuration',
                templateUrl: 'app/deviceManagement/device/deviceDetailConfiguration.html',
                controller: 'DeviceDetailConfiguration as vm',
                onEnter(Segment) {
                    Segment.trackPage('Devices & Equipment:Details:Configuration');
                }
            })
            .state('aq.deviceManagement.building.device.topology', {
                url: '/topology',
                templateUrl: 'app/deviceManagement/device/deviceDetailTopology.html',
                controller: 'DeviceDetailTopology as vm',
                resolve: {
                    dataStatuses(RestangularV3: restangular.IService, building: aq.common.models.Building, waitForAuthToken) {
                        return RestangularV3.all('devices').customGET('data-status', { buildingId: building.id });
                    }
                },
                onEnter(Segment) {
                    Segment.trackPage('Devices & Equipment:Topology');
                }
            })
            .state('aq.deviceManagement.building.device.datacharting', {
                url: '/datacharting',
                templateUrl: 'app/deviceManagement/device/deviceDetailDataCharting.html',
                controller: 'DeviceDetailDataCharting as vm',
                resolve: {
                    collector(
                        $stateParams: ng.ui.IStateParamsService,
                        device: aq.common.models.Device,
                        accountElement: restangular.IElement,
                        Restangular: restangular.IService,
                        waitForAuthToken
                    ) {
                        if (device.collectorId) {
                            return accountElement
                                .one('buildings', $stateParams.buildingId)
                                .one('collectors', device.collectorId)
                                .get({ single: true })
                                .then((collectors) => {
                                    return Restangular.restangularizeElement(accountElement
                                        .one('buildings', $stateParams.buildingId), collectors.collector, 'collectors');
                                });
                        } else {
                            return null;
                        }
                    }
                },
                onEnter(Segment) {
                    Segment.trackPage('Devices & Equipment:Charting');
                }
            })
            .state('aq.deviceManagement.building.device.readings', {
                url: '/readings',
                templateUrl: 'app/deviceManagement/device/deviceDetailReadings.html',
                controller: 'DeviceDetailReadings as vm',
                resolve: {
                    collector(
                        $stateParams: ng.ui.IStateParamsService,
                        device: aq.common.models.Device,
                        accountElement: restangular.IElement,
                        Restangular: restangular.IService,
                        waitForAuthToken
                    ) {
                        if (device.collectorId) {
                            return accountElement
                                .one('buildings', $stateParams.buildingId)
                                .one('collectors', device.collectorId)
                                .get({ single: true })
                                .then((collectors) => {
                                    return Restangular.restangularizeElement(accountElement
                                        .one('buildings', $stateParams.buildingId), collectors.collector, 'collectors');
                                });
                        } else {
                            return null;
                        }
                    }
                },
                onEnter(Segment) {
                    Segment.trackPage('Devices & Equipment:Readings');
                }
            })
            .state('aq.deviceManagement.building.device.gallery', {
                url: '/gallery',
                templateUrl: 'app/deviceManagement/device/deviceDetailGallery.html',
                controller: 'DeviceDetailGallery as vm',
                onEnter(Segment) {
                    Segment.trackPage('Devices & Equipment:Details:Gallery');
                }
            })
    });

interface IDeviceCategories {
    label: string;
    value: string;
    icon: string;
}

interface INestedDeviceCategories {
    label: string;
    value: string;
    icon: string;
    subMenuItems: IDeviceCategories[];
}
