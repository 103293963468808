namespace aq.ui {

    export class AqSidebarNavHeaderController {

        public isLargeScreen: () => boolean;
        public parent: AqSidebarNavController
        public title: string;

        /* @ngInject */
        constructor() {
            this.title = '';
        }

        public $onInit = () => {
            this.isLargeScreen = () => this.parent.isLargeScreen();
        };
    }

    angular
        .module('aq.ui')
        .component('aqSidebarNavHeader', {
            templateUrl: 'app/common/directives/aqSidebarNav/aqSidebarNavHeader/aqSidebarNavHeader.html',
            controller: AqSidebarNavHeaderController,
            controllerAs: 'vm',
            bindings: {
                title: '@'
            },
            require: {
                parent: '^^aqSidebarNav'
            }
        });
}