namespace aq.utilityBudgets {

    export type UtilityAccountWizardMode = 'auto' | 'manual' | null;

    export class WizardSelectionModalCtrl extends aq.common.Controllers.ModalCtrl {

        /* ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private Messages,
            private mode: UtilityAccountWizardMode,
            private utilityCompanies:  aq.common.models.NamedItem[],
            private buildingViewItems: aq.common.models.NamedItem[],
            private accountId: number,
            private buildingId: number,
            private users: UserItem[],
            private $state: ng.ui.IStateService
        ) {
            super({}, $mdDialog);
        }
        public startWizard(mode: UtilityAccountWizardMode) {
            if (mode != 'auto' && mode != 'manual') {
                this.Messages.error('Unexpected error occured while starting wizard, please contant your Administrator');
                this.cancel();
            }
            this.mode = mode;
            if (this.mode == 'auto') {
                this.$mdDialog.show({
                    controller: 'UtilityAccountWizardCtrl as vm',
                    templateUrl: 'app/utilityBudgets/utilityAccounts/actions/wizard/utilityAccountWizard.html',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false,
                    targetEvent: event,
                    locals: {
                        utilityCompanies: this.utilityCompanies,
                        buildingViewItems: this.buildingViewItems,
                        buildingId: this.buildingId,
                        users: this.users
                    },
                    multiple: false
                } as any)
                    .finally(() => {
                        this.$state.reload();
                    });
            } else {
                this.$mdDialog.show({
                    controller: 'ManualUtilityAccountWizardCtrl as vm',
                    templateUrl: 'app/utilityBudgets/utilityAccounts/actions/wizard/manualUtilityAccountWizard.html',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false,
                    targetEvent: event,
                    locals: {
                        utilityCompanies: this.utilityCompanies,
                        buildingViewItems: this.buildingViewItems,
                        buildingId: this.buildingId,
                        accountId: this.accountId
                    },
                    multiple: false
                } as any)
                    .finally(() => {
                        this.$state.reload();
                    });
            }
        }
    }
    angular.module('aq.utilityBudgets').controller('WizardSelectionModalCtrl', WizardSelectionModalCtrl);
}
