namespace aq.settings {
    export class SettingsEnergyUseCtrl {
        private isInitialized: boolean;
        private isCreateNew: boolean;
        private energyUses;
        private metrics;
        private accountSettingsAccess: common.models.AppAccessObject;
        /* ngInject */
        constructor(
            private $scope,
            private $mdDialog: ng.material.IDialogService,
            private authAccess: common.models.AuthAppAccess
        ) {
            this.accountSettingsAccess = authAccess['Account Settings'];
            this.energyUses = [];
            this.$scope.$on('CREATE_NEW', () => {
                this.isCreateNew = true;
                if (this.isInitialized) {
                    this.isCreateNew = false;
                    this.create();
                }
            });
            this.loadEnergyUses().then(() => {
                if (this.isCreateNew) {
                    this.isCreateNew = false;
                    this.create();
                }
            });
            this.metrics = this.$scope.account.accountMetrics;
        }
        public loadEnergyUses(): ng.IPromise<void> {
            return this.$scope.account
                .all('EnergyUses')
                .getList()
                .then((energyUses) => {
                    this.energyUses = _.sortBy(energyUses, 'name');
                    this.isInitialized = true;
                });
        }
        public getMetric(id) {
            return _.find(this.metrics, (m) => m.id == id).displayAs;
        }
        public create() {
            this.$mdDialog.show({
                controller: 'SettingsEnergyUseCRUDCtrl as vm',
                templateUrl: 'app/settings/platform/energyUse/crud/crud.html',
                clickOutsideToClose: true,
                locals: {
                    object: new EnergyUse(),
                    account: this.$scope.account,
                    accountSettingsAccess: this.accountSettingsAccess
                }
            }).then((createdEnergyUse) => {
                this.energyUses.push(createdEnergyUse);
                this.energyUses = _.sortBy(this.energyUses, 'name');
            });
        }
        public edit(energyUse) {
            this.$mdDialog.show({
                controller: 'SettingsEnergyUseCRUDCtrl as vm',
                templateUrl: 'app/settings/platform/energyUse/crud/crud.html',
                clickOutsideToClose: true,
                locals: {
                    object: energyUse,
                    account: this.$scope.account,
                    accountSettingsAccess: this.accountSettingsAccess
                }
            }).then((updatedEnergyUse) => {
                if (updatedEnergyUse) {
                    _.replaceItemById(this.energyUses, updatedEnergyUse);
                } else {
                    _.remove(this.energyUses, (m: any) => { return m.id == energyUse.id; });
                }
            });
            this.energyUses = _.sortBy(this.energyUses, 'name');
        };
    }
    angular.module('aq.settings').controller('SettingsEnergyUseCtrl', SettingsEnergyUseCtrl);
}
