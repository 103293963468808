namespace aq.ui {
    export interface BuildingViewItem {
        id: number;
        name: string;
    }
    export class EditUserModalCtrl extends aq.common.Controllers.ModalCtrl {
        public buildingSelectionMode: string;
        public buildingViewItems: BuildingViewItem[];
        public selectedBuildingViewItems: BuildingViewItem[];
        public isDisplayConfirmation: boolean;
        private user: aq.user.User;

        /* ngInject */
        constructor(
            private editUser: aq.user.User,
            private emails: string[],
            private roles,
            private personas,
            private personaRequired,
            private isNew: boolean,
            private isGlobal: boolean,
            private isAllBuildings: boolean,
            private buildings: aq.common.models.Building[],
            private enableDelete: boolean,
            private isReadonlyUserDetails: boolean,
            protected $mdDialog: ng.material.IDialogService
        ) {
            super(null, $mdDialog);
            this.user = angular.copy(this.editUser);
            if (this.isAllBuildings !== undefined) {
                (this.user as any).isAllBuildings = this.isAllBuildings;
            }
            if (this.isGlobal) {
                (this.user as any).buildings = [];
                this.buildingSelectionMode = 'individual';
                this.buildingViewItems = this.getBuildingViewItems(this.buildings);
            }
        }

        public apply() {
            if (this.isGlobal) {
                (this.user as any).buildings = this.selectedBuildingViewItems;
            }
            this.hide(this.user);
        }

        public delete() {
            if (!this.isDisplayConfirmation) {
                this.isDisplayConfirmation = true;
                return;
            }
            (this.user as any).delete = true;
            this.hide(this.user);
        }

        public onBuildingSelectionModeChange() {
            if (this.buildingSelectionMode == 'all') {
                this.selectedBuildingViewItems = angular.copy(this.buildingViewItems);
            } else {
                this.selectedBuildingViewItems = [];
            }
        }

        public onSelectedBuildingsChange(buildings: BuildingViewItem[]) {
            this.selectedBuildingViewItems = buildings;
        }

        public isEmailAlreadyTaken() {
            return _.findIndex(this.emails, (email) => email == this.user.email) > -1;
        }

        private getPersona(persona): string {
            return persona.replace('_', ' ');
        }

        private getBuildingViewItems(buildings: any[]): BuildingViewItem[] {
            if (!buildings) {
                return [];
            }
            const items = _.map(buildings, (building) => {
                return {
                    id: building.index,
                    name: building.name
                };
            });
            return items;
        }
    }
    angular.module('aq.ui').controller('EditUserModalCtrl', EditUserModalCtrl);
}
