namespace aq.dashboard.widgets {
    export interface StatusConfig {
        title: string;
        buildingId: string;
        updateFrequencySeconds: number; // update frequency in seconds
        maxAgeMinutes: number; // number of minutes after which we consider there to be no recent data
        series: StatusSeries[];
        options: StatusConfigOptions;
        actions: StatusEditService;
    }

    export interface StatusSeries {
        title: string;
        nullClass: StatusClass;
        showValue: boolean;
        measureUnit: string;
        queryableType: aq.services.Queryable; // query param
        queryable: aq.services.FetchQueryableResult; // query param
        thresholds: {
            warning: {
                lower: number;
                upper: number;
            },
            critical: {
                lower: number;
                upper: number;
            }
        };
    }

    export interface StatusConfigOptions {
        buildings: aq.common.models.Building[];
        frequencies: { label: string, value: number }[];
        spaces: aq.services.FetchQueryableResult[];
        tenants: aq.services.FetchQueryableResult[];
        meters: aq.services.FetchQueryableResult[];
        sources: aq.services.FetchQueryableResult[];
        points: aq.services.FetchQueryableResult[];
        queryables: aq.services.Queryable[];
        measureUnits: aq.services.MeasureUnits[];
        expandedItem?: StatusSeries;
        newMeasureUnit?: string;
        statusClasses: StatusClass[];
    }

    export interface StatusSeriesData {
        id: number;
        name: string;
        unit: string;
        value: number;
        timestamp: number;
    }

    export enum StatusClass {
        NORMAL = 'normal',
        WARNING = 'warning',
        CRITICAL = 'critical',
        UNKNOWN = 'unknown'
    };

    export enum StatusIcon {
        NORMAL = 'check_circle',
        WARNING = 'warning',
        CRITICAL = 'error',
        UNKNOWN = 'help'
    }

    export interface CurrentStatus {
        class: StatusClass;
        icon: StatusIcon;
        message: string;
    }

    export class StatusCtrl {
        private statusData: StatusSeriesData[];
        private statusInterval;

        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private config: StatusConfig,
            private buildings: aq.common.models.Building[],
            private ModelUtilService: aq.services.ModelUtilService,
            private StatusEditService: StatusEditService,
            private RawService: aq.services.RawService,
            private $interval: ng.IIntervalService,
            private $translate
        ) {
            this.config.options = {
                buildings: this.ModelUtilService.pareProperties(this.buildings, ['account']),
                frequencies: [
                    { label: 'Every Minute', value: 1 * 60 },
                    { label: 'Every 3 Minutes', value: 3 * 60 },
                    { label: 'Every 5 Minutes', value: 5 * 60 },
                    { label: 'Every 10 Minutes', value: 10 * 60 },
                    { label: 'Every 15 Minutes', value: 15 * 60 }
                ],
                spaces: [],
                tenants: [],
                meters: [],
                sources: [],
                points: [],
                queryables: [],
                measureUnits: [],
                newMeasureUnit: 'NONE',
                statusClasses: [
                    StatusClass.NORMAL,
                    StatusClass.WARNING,
                    StatusClass.CRITICAL
                ]
            };
            this.StatusEditService.initOptions(this.config);
            if (!this.config.buildingId && this.buildings.length > 0) {
                this.StatusEditService.onSelectedBuildingChange(this.config, this.buildings[0].id);
            }
            if (!this.config.updateFrequencySeconds) {
                this.config.updateFrequencySeconds = this.config.options.frequencies[0].value;
            }
            if (!this.config.maxAgeMinutes) {
                this.config.maxAgeMinutes = 10;
            }
            if ((!this.config.series || this.config.series.length === 0)
                && this.buildings.length > 0) {
                this.config.series = [
                    this.StatusEditService.getDefaultSeries(this.config)
                ];
            }

            // Pass through our functions this way instead of this.config.actions = this.StatusEditService
            // Something about having RestangularV3 anywhere on our config causes infinite loops in our $digest
            this.config.actions = {
                onSelectedBuildingChange: (cfg: StatusConfig, buildingId: string) => {
                    return this.StatusEditService.onSelectedBuildingChange(cfg, buildingId);
                },
                toggleItemDetails: (cfg: StatusConfig, item: StatusSeries) => {
                    return this.StatusEditService.toggleItemDetails(cfg, item);
                },
                onQueryableChange: (cfg: StatusConfig, item: StatusSeries) => {
                    return this.StatusEditService.onQueryableChange(cfg, item);
                },
                getQueryableOptions: (cfg: StatusConfig, queryableType: aq.services.Queryable, searchText: string) => {
                    return this.StatusEditService.getQueryableOptions(cfg, queryableType, searchText);
                },
                onDelete: (cfg: StatusConfig, item: StatusSeries) => {
                    return this.StatusEditService.onDelete(cfg, item);
                },
                onNewMeasureUnit: (cfg: StatusConfig) => {
                    return this.StatusEditService.onNewMeasureUnit(cfg);
                }
            } as StatusEditService;

            this.$scope.config = this.config;

            this.startAutoRefresh();
        }

        public startAutoRefresh() {
            this.getStatusData();
            this.$scope.$on('$destroy', () => {
                if (this.statusInterval) {
                    this.$interval.cancel(this.statusInterval);
                }
            });
            this.statusInterval = this.$interval(() => this.getStatusData(), this.config.updateFrequencySeconds * 1000);
        }

        public getStatusData() {
            if (!this.config.series || this.config.series.length === 0) {
                return;
            }

            if (!this.statusData) {
                this.statusData = [];
            }

            _.each(this.config.series, (series: StatusSeries, i) => {
                const measureUnit = this.StatusEditService.getMeasureUnitFromString(series.measureUnit);
                this.RawService
                    .getLatest({
                        queryable: series.queryableType,
                        queryableId: series.queryable.id,
                        start: moment().subtract(this.config.maxAgeMinutes, 'minutes').format('YYYY-MM-DDTHH:mm:ssZ'),
                        end: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
                        measure: measureUnit.measure,
                        unit: measureUnit.unit,
                        interval: '1min'
                    })
                    .then((result) => {
                        const data = result[measureUnit.unit.toLowerCase()];
                        const value = data.data && data.data[0] ? this.formatNumber(data.data[0].datum) : null;
                        const timestamp = data.data && data.data[0] ? data.data[0].timestamp : null;
                        this.statusData[i] = {
                            id: result.id,
                            name: result.name,
                            unit: data.unitSymbol,
                            value,
                            timestamp
                        };
                    })
                    .catch((e) => {
                        // Should we fail loudly here?
                        console.error(e);
                    });
            });
        }

        public getCurrentStatus(series: StatusSeries, data: StatusSeriesData): CurrentStatus {
            if (!data || data.value === null) {
                switch (series.nullClass) {
                    case StatusClass.CRITICAL:
                        return {
                            class: StatusClass.CRITICAL,
                            icon: StatusIcon.CRITICAL,
                            message: this.$translate.instant(
                                'dashboard.common.widgets.statusview.Null Critical',
                                { number: this.config.maxAgeMinutes }
                            )
                        };
                    case StatusClass.WARNING:
                        return {
                            class: StatusClass.WARNING,
                            icon: StatusIcon.WARNING,
                            message: this.$translate.instant(
                                'dashboard.common.widgets.statusview.Null Warning',
                                { number: this.config.maxAgeMinutes }
                            )
                        };
                    case StatusClass.NORMAL:
                    default:
                        return {
                            class: StatusClass.NORMAL,
                            icon: StatusIcon.NORMAL,
                            message: this.$translate.instant('dashboard.common.widgets.statusview.Normal')
                        };
                }
            }
            const {
                critical: { lower: criticalLower, upper: criticalUpper },
                warning: { lower: warningLower, upper: warningUpper }
            } = series.thresholds;
            if (criticalLower && data.value <= criticalLower) {
                return {
                    class: StatusClass.CRITICAL,
                    icon: StatusIcon.CRITICAL,
                    message: this.$translate.instant(
                        'dashboard.common.widgets.statusview.Below Critical',
                        { value: data.value, threshold: criticalLower }
                    )
                };
            } else if (criticalUpper && data.value >= criticalUpper) {
                return {
                    class: StatusClass.CRITICAL,
                    icon: StatusIcon.CRITICAL,
                    message: this.$translate.instant(
                        'dashboard.common.widgets.statusview.Above Critical',
                        { value: data.value, threshold: criticalUpper }
                    )
                };
            } else if (warningLower && data.value <= warningLower) {
                return {
                    class: StatusClass.WARNING,
                    icon: StatusIcon.WARNING,
                    message: this.$translate.instant(
                        'dashboard.common.widgets.statusview.Below Warning',
                        { value: data.value, threshold: warningLower }
                    )
                };
            } else if (warningUpper && data.value >= warningUpper) {
                return {
                    class: StatusClass.WARNING,
                    icon: StatusIcon.WARNING,
                    message: this.$translate.instant(
                        'dashboard.common.widgets.statusview.Above Warning',
                        { value: data.value, threshold: warningUpper }
                    )
                };
            } else {
                return {
                    class: StatusClass.NORMAL,
                    icon: StatusIcon.NORMAL,
                    message: this.$translate.instant('dashboard.common.widgets.statusview.Normal')
                };
            }
        }

        public getStatusClass(series: StatusSeries, data: StatusSeriesData) {
            return this.getCurrentStatus(series, data).class;
        }

        public getStatusIcon(series: StatusSeries, data: StatusSeriesData) {
            return this.getCurrentStatus(series, data).icon;
        }

        public getStatusTooltip(series: StatusSeries, data: StatusSeriesData) {
            return this.getCurrentStatus(series, data).message;
        }

        public getTimeDisplayTooltip(timestamp: number) {
            return moment(timestamp).format('h:mm a');
        };

        private formatNumber(object) {
            const n = Number(object + 'e+2');
            if (isNaN(n)) {
                return object;
            }
            return +(Math.round(n) + 'e-2');
        }
    }

    angular.module('aq.dashboard.widgets').controller('StatusCtrl', StatusCtrl);
}
