var aq;
(function (aq) {
    var tenantbilling;
    (function (tenantbilling) {
        var AddUnitCtrl = /** @class */ (function (_super) {
            __extends(AddUnitCtrl, _super);
            /* @ngInject */
            function AddUnitCtrl($mdDialog, RestangularV3, Errors, Messages, accountId, buildingId, leaseTypes, TenantServiceHelper, areaMeasurement) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.RestangularV3 = RestangularV3;
                _this.Errors = Errors;
                _this.Messages = Messages;
                _this.accountId = accountId;
                _this.buildingId = buildingId;
                _this.leaseTypes = leaseTypes;
                _this.TenantServiceHelper = TenantServiceHelper;
                _this.areaMeasurement = areaMeasurement;
                _this.residents = [];
                _this.tenant = {
                    building: _this.buildingId
                };
                var primaryResident = {};
                _this.residents.push(primaryResident);
                return _this;
            }
            AddUnitCtrl.prototype.addAnotherResident = function (residents) {
                var resident = {};
                residents.push(resident);
            };
            AddUnitCtrl.prototype.cancel = function (data) {
                this.$mdDialog.cancel();
            };
            AddUnitCtrl.prototype.hide = function () {
                this.$mdDialog.hide(this.RestangularV3.copy(this.tenant));
            };
            AddUnitCtrl.prototype.save = function () {
                var _this = this;
                return this.TenantServiceHelper.createTenant(this.tenant)
                    .then(function (result) {
                    _.extend(_this.tenant, result);
                    if (_this.lease) {
                        _this.lease.tenant = _this.tenant.id;
                        _this.lease.active = true;
                        _this.TenantServiceHelper.createLease(_this.lease)
                            .then(function (leaseResult) {
                            _.extend(_this.lease, leaseResult);
                            _this.residents.forEach(function (resident) {
                                if (resident.name && resident.email) {
                                    resident.lease = _this.lease.id;
                                    _this.TenantServiceHelper.createResident(resident);
                                }
                            });
                        });
                    }
                    _this.hide();
                });
            };
            return AddUnitCtrl;
        }(aq.common.Controllers.ModalCtrl));
        tenantbilling.AddUnitCtrl = AddUnitCtrl;
        angular.module('tenantBilling').controller('AddUnitCtrl', AddUnitCtrl);
    })(tenantbilling = aq.tenantbilling || (aq.tenantbilling = {}));
})(aq || (aq = {}));
