var aq;
(function (aq) {
    var deviceManagement;
    (function (deviceManagement) {
        var AddDevice = /** @class */ (function (_super) {
            __extends(AddDevice, _super);
            /* @ngInject */
            function AddDevice(Auth, $mdDialog, $mdStepper, deviceCategories, Messages, classesByCategory, tenants, tags, buildingId, RestangularV3, building, createAnother, $mdMedia, DeviceService) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.Auth = Auth;
                _this.$mdDialog = $mdDialog;
                _this.$mdStepper = $mdStepper;
                _this.deviceCategories = deviceCategories;
                _this.Messages = Messages;
                _this.classesByCategory = classesByCategory;
                _this.tenants = tenants;
                _this.tags = tags;
                _this.buildingId = buildingId;
                _this.RestangularV3 = RestangularV3;
                _this.building = building;
                _this.$mdMedia = $mdMedia;
                _this.DeviceService = DeviceService;
                _this.measure = null;
                _this.createAssociation = false;
                _this.newDevice = {
                    name: '',
                    images: [],
                    building: null,
                    description: '',
                    location: '',
                    locationTag: null,
                    viewOnly: {
                        tenants: []
                    },
                    tenantLinks: [],
                    deviceCategory: null,
                    deviceClass: {
                        make: '',
                        series: '',
                        model: ''
                    },
                    fields: {}
                };
                _this.newDeviceImage = {};
                _this.step = 1;
                _this.templateFields = {};
                _this.createAnother = createAnother || false;
                _this.measures = _this.extractMeterMeasuresFromClassesByCategory(classesByCategory);
                _this.isAdmin = _this.Auth.check({ access: 'FULL_ACCESS' });
                _this.newDevice.building = buildingId;
                _this.selectedLocationTag = null;
                _this.uploading = false;
                _this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'unit' : 'tenant';
                return _this;
            }
            AddDevice.prototype.filterName = function (query) {
                return function (item) {
                    return (item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0);
                };
            };
            AddDevice.prototype.filterMeasure = function () {
                var _this = this;
                return function (item) {
                    if (_this.measure != null) {
                        return item.measure
                            ? item.measure.toLowerCase() === _this.measure.toLowerCase()
                            : false;
                    }
                    return true;
                };
            };
            AddDevice.prototype.filterDeviceClassName = function (query) {
                return this.classesByCategory[this.newDevice.deviceCategory]
                    .filter(this.filterMeasure())
                    .filter(this.filterName(query));
            };
            AddDevice.prototype.selectClass = function (deviceClassName) {
                if (deviceClassName) {
                    this.newDevice.deviceClass = _.pick(deviceClassName, ['make', 'model', 'series']);
                }
            };
            AddDevice.prototype.addTenant = function (event) {
                var _this = this;
                this.$mdDialog.show({
                    targetEvent: event,
                    templateUrl: 'app/deviceManagement/actions/addTenant/addTenant.html',
                    controller: 'AddTenant',
                    controllerAs: 'vm',
                    multiple: true,
                    fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                    clickOutsideToClose: false
                })
                    .then(function (tenant) {
                    tenant.building = _this.building.id;
                    _this.RestangularV3.all('tenants')
                        .post(tenant, { buildingId: _this.buildingId })
                        .then(function (createdTenant) {
                        _this.tenants.push(createdTenant);
                        _this.newDevice.viewOnly.tenants.push(createdTenant);
                        _this.Messages.success("New tenant " + createdTenant.name + " created");
                    });
                });
            };
            AddDevice.prototype.selectLocationTag = function (tag) {
                if (tag) {
                    if (tag.query) {
                        _.remove(this.tags, { query: tag.query });
                        tag.name = tag.query;
                        delete tag.query;
                        this.tags.push(tag);
                        this.Messages.success("New location '" + tag.name + "' added");
                    }
                    this.newDevice.locationTag = tag;
                }
                else if (this.query === '') {
                    this.newDevice.locationTag = null;
                }
            };
            AddDevice.prototype.filterLocationTag = function (query) {
                _.remove(this.tags, function (t) { return t.query; });
                if (query && !_.some(this.tags, function (t) { return t.name.toLowerCase() == query.toLowerCase(); })) {
                    var currentQuery = {
                        name: "Add new location \"" + query + "\"",
                        query: query
                    };
                    this.tags.push(currentQuery);
                }
                var tags = _.filter(this.tags, function (tag) {
                    return tag.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
                });
                return _.sortBy(tags, [function (t) { return t.query ? 1 : 0; }, 'name']);
            };
            AddDevice.prototype.isValidLocation = function () {
                if (this.newDevice.locationTag) {
                    return this.query === this.newDevice.locationTag.name;
                }
                else {
                    return !this.query;
                }
            };
            AddDevice.prototype.cancel = function (data) {
                this.$mdDialog.cancel({ tenants: this.tenants, data: data });
            };
            AddDevice.prototype.hide = function (data) {
                this.$mdDialog.hide({ tenants: this.tenants, data: data });
            };
            AddDevice.prototype.next = function () {
                if (this.step === 2 && !this.hasDeviceClass(this.newDevice.deviceCategory)) {
                    this.step++;
                }
                this.$mdStepper('createDeviceStepper').goto(this.step++);
            };
            AddDevice.prototype.save = function () {
                var _this = this;
                this.newDevice.tenantLinks = this.newDevice.viewOnly.tenants.map(function (tenant) { return ({
                    id: null,
                    active: false,
                    device: null,
                    tenant: {
                        id: tenant.id,
                        name: tenant.name
                    }
                }); });
                return this.DeviceService.create(this.newDevice).then(function (response) {
                    _this.hide({
                        device: _this.newDevice,
                        createAnother: _this.createAnother,
                        createAssociation: _this.createAssociation,
                        newDevice: response
                    });
                    return response;
                }, function (error) {
                    if (error.status === deviceManagement.DeviceDetail.HTTP_CODE_CONSTRAINT_VIOLATION) {
                        _this.Messages.error(error.data);
                    }
                    else {
                        _this.Messages.error('Error creating Device');
                    }
                });
            };
            AddDevice.prototype.hasDeviceClass = function (deviceCategory) {
                return !deviceCategory || ['METER', 'NETWORKING', 'SENSOR'].indexOf(deviceCategory) >= 0;
            };
            AddDevice.prototype.extractMeterMeasuresFromClassesByCategory = function (classesByCategory) {
                var measures = new Set(classesByCategory['METER']
                    .map(function (deviceClass) { return deviceClass.measure; })
                    .filter(function (measure) { return measure; }));
                return Array.from(measures);
            };
            AddDevice.prototype.isAddingMeter = function () {
                return this.newDevice.deviceCategory === 'METER';
            };
            AddDevice.prototype.getDeviceCategories = function () {
                return this.deviceCategories;
            };
            AddDevice.prototype.getFields = function () {
                var _this = this;
                this.RestangularV3.all('devices').customGET('device-class', this.newDevice.deviceClass).then(function (response) {
                    _this.templateFields = _this.filterFields(response.fields);
                    Object.keys(response.fields).forEach(function (key) {
                        if (response.fields[key].hasOwnProperty('value')) {
                            _this.newDevice.fields[key] = { value: response.fields[key].value };
                        }
                    });
                }, function () {
                    _this.Messages.error('Cannot find fields for device class');
                });
            };
            AddDevice.prototype.filterFields = function (fields) {
                if (fields) {
                    var visibleFields_1 = {};
                    Object.keys(fields).forEach(function (key) {
                        if (key === 'deviceIdentifier') {
                            visibleFields_1[key] = fields[key];
                        }
                    });
                    return visibleFields_1;
                }
                else {
                    return {};
                }
            };
            AddDevice.prototype.saveImage = function () {
                if (this.newDeviceImage && this.newDeviceImage.imageUrl) {
                    var deviceImage = { url: this.newDeviceImage.imageUrl };
                    this.newDevice.images = [deviceImage];
                }
                this.unsetUploadingFlag();
            };
            AddDevice.prototype.setUploadingFlag = function () {
                this.uploading = true;
            };
            AddDevice.prototype.unsetUploadingFlag = function () {
                this.uploading = false;
            };
            return AddDevice;
        }(aq.common.Controllers.ModalCtrl));
        deviceManagement.AddDevice = AddDevice;
        angular
            .module('deviceManagement')
            .controller('AddDevice', AddDevice);
    })(deviceManagement = aq.deviceManagement || (aq.deviceManagement = {}));
})(aq || (aq = {}));
