namespace aq.utilityBudgets {

    export interface UtilityMeterViewObject {
        collectorName: string;
        urjanetMeterName: string;
    }

    export class UtilityServiceDetailsMeters {
        public currentNavItem: string;
        public utilityMeterViews: UtilityMeterViewObject[];
        public utilityCollectorsMap: IMeasureArrayMap;

        /* @ngInject */
        constructor(
            private utilityAccount: UtilityAccount,
            private utilityService: UtilityService,
            private utilityServices: UtilityService[],
            private collectors,
            private accountId: string,
            private buildingId: string,
            private UtilityServiceHelper: UtilityServiceHelper,
            private readOnlyForm: boolean,
            private utilityMeters: UtilityMeter[],
            private urjanetMeters: UrjanetMeter[],
            private $mdDialog: ng.material.IDialogService,
            private RestangularV3: restangular.IService,
            private intervalMeters: aq.utilityBudgets.IntervalMeter[]
        ) {
            this.currentNavItem = 'meters';
            this.utilityCollectorsMap = this.UtilityServiceHelper.getAvailableCollectorsMeasureMap(this.utilityServices, this.collectors);
            this.utilityMeterViews = this.getUtilityMeterViewObjects();
        };

        public isIntervalSupported() {
            return !['STEAM', 'HEAT'].includes(this.utilityService.type);
        }

        public isSelectedTab(name) {
            return this.currentNavItem == name;
        }

        public showManageUtilityMetersModal() {
            this.$mdDialog.show({
                controller: 'ManageUtilityMeters as vm',
                templateUrl: 'app/utilityBudgets/utilityAccounts/actions/manageUtilityMeters/manageUtilityMeters.html',
                locals: {
                    accountId: this.accountId,
                    buildingId: this.buildingId,
                    utilityAccount: this.utilityAccount,
                    utilityService: this.utilityService,
                    utilityMeters: this.utilityMeters,
                    urjanetMeters: this.urjanetMeters,
                    collectors: this.utilityCollectorsMap[this.utilityService.type],
                    intervalMeters: this.intervalMeters
                }
            })
            .then((utilityMeters) => {
                this.utilityMeters = utilityMeters;
                this.utilityMeterViews = this.getUtilityMeterViewObjects();
                this.RestangularV3.all('interval').customGETLIST(`getIntervalMetersByUtilityService/${this.utilityService.id}`).then((results) => {
                    this.intervalMeters = results;
                });
            });
        }

        public showManageIntervalMetersModal() {
            this.$mdDialog.show({
                controller: 'ManageIntervalMeters as vm',
                templateUrl: 'app/utilityBudgets/utilityAccounts/actions/manageIntervalMeters/manageIntervalMeters.html',
                locals: {
                    accountId: this.accountId,
                    buildingId: this.buildingId,
                    utilityAccount: this.utilityAccount,
                    utilityService: this.utilityService,
                    collectors: this.utilityCollectorsMap[this.utilityService.type],
                    intervalMeters: this.intervalMeters,
                    intervalMeterSelect: []
                }
            })
            .then((intervalMeters) => {
                this.intervalMeters = intervalMeters;
            });
        }
        public getUtilityMeterViewObjects(): UtilityMeterViewObject[] {
            const buildingMeters = this.getBuildingUtilityMeters();
            return _.map(buildingMeters, (utilityMeter: UtilityMeter) => {
                const meter = {} as UtilityMeterViewObject;
                if (utilityMeter.collector) {
                    const collector = _.find(this.collectors, { id: utilityMeter.collector });
                    meter.collectorName = collector.name;
                }
                if (utilityMeter.utilityProviderMeterId) {
                    const urjanetMeter: UrjanetMeter = _.find(this.urjanetMeters, { id: Number(utilityMeter.utilityProviderMeterId)});
                    meter.urjanetMeterName = urjanetMeter.meterNumber;
                }
                return meter;
            });
        }

        private getBuildingUtilityMeters() {
            return _.filter(this.utilityMeters, (utilityMeter: UtilityMeter) => {
                // if no collector, it means that this meter is from another building, so we don't want to show it
                if (utilityMeter.collector) {
                    return !!_.find(this.collectors, { id: utilityMeter.collector });
                } else {
                    return false;
                }
            });
        }
    }
    angular.module('aq.utilityBudgets').controller('UtilityServiceDetailsMeters', UtilityServiceDetailsMeters);
}