namespace aq.tenantbilling {
    export class EditChargeValueCtrl {
        /* @ngInject */
        constructor(
            public activeTab: string,
            private chargeValue: TenantChargeValue,
            private monthlyBilling: MonthlyTenantBilling,
            private device: aq.common.models.Device,
            private isReadOnly: boolean,
            private building: aq.common.models.Building,
            private collectorHierarchy
        ) {
            if (!this.activeTab) {
                this.activeTab = 'readings';
            }
        }

        public isActiveTab(tab: string) {
            return this.activeTab == tab;
        }

    }

    angular
        .module('tenantBilling')
        .controller('EditChargeValueCtrl', EditChargeValueCtrl);
}
