angular.module('aq.ui.reports.service', ['pascalprecht.translate', 'ui.router']).service('ReportsWidgetService', function ($translate, $state) {

    var service = function(account) {

        this.periodOptions = [
            {
                value: 'DAILY',
                label: $translate.instant('Daily')
            },
            {
                value: 'WEEKLY',
                label: $translate.instant('Weekly')
            },
            {
                value: 'MONTHLY',
                label: $translate.instant('Monthly')
            },
            {
                value: 'IMMEDIATELY',
                label: $translate.instant('Immediately')
            }
        ];

        this.dowOptions = [
            {
                value: 1,
                label: $translate.instant('Sunday')
            },
            {
                value: 2,
                label: $translate.instant('Monday')
            },
            {
                value: 3,
                label: $translate.instant('Tuesday')
            },
            {
                value: 4,
                label: $translate.instant('Wednesday')
            },
            {
                value: 5,
                label: $translate.instant('Thursday')
            },
            {
                value: 6,
                label: $translate.instant('Friday')
            },
            {
                value: 7,
                label: $translate.instant('Saturday')
            }
        ];

        this.schedulingInterval = [ 'IMMEDIATELY', 'DAILY', 'WEEKLY', 'MONTHLY' ];

        /**
         * Based on modal configuration it will return SchedulingData object
         * @returns {{time: *, dayOfWeek: number, dayOfMonth: number}}
         */
        this.parseSchedulingData = function(newReport) {
            // TODO - generate this object through modal window
            return {
                dayOfWeek: newReport.dow,
                time: moment(newReport.time).format('HH:mm'),
                dayOfMonth: newReport.dayOfMonth,
                interval: newReport.schedulingInterval
            }
        }

        /**
         * Based on modal configuration it will return SchedulingData object
         * based on ScheduleReport object retrieved from backend
         * @returns {{time: *, dayOfWeek: number, dayOfMonth: number}}
         */
        this.initSchedulingData = function(newReport) {
            newReport.schedulingInterval = newReport.scheduling.interval;
            if (newReport.scheduling.dayOfWeek) newReport.dow = _.find(this.dowOptions, { value: newReport.scheduling.dayOfWeek }).value;
            if (newReport.scheduling.time) newReport.time = moment(newReport.scheduling.time, "HH:mm:ss");
            if (newReport.scheduling.dayOfMonth) newReport.dayOfMonth = newReport.scheduling.dayOfMonth;
        }

        this.getReports = function() {
            return account.getList('reports');
        }

        this.getScheduledReports = function() {
            var appName = $state.current.data ? $state.current.data.appName : null;
            return account.getList('scheduledReports', { inflate: 'creator,recipients', module: appName, onlyScheduled: true, skipEditOnly: true });
        }

        /**
         * From a given url returns a report parameters which are:
         *  url - the url without start/end parameters
         *  period - (end - start) period in minutes
         *  offset - (current date - end) period in minutes
         *  These are needed as back-end will expect them to proper fill dates for recurrent reports
         *
         * If there are no start/end parameters in the original url it will return
         * {"url": url} object with the original url
         *
         * @param url
         */
        this.getBasicParametersFromUrl = function(url) {
            if (url.indexOf('start') == -1 || url.indexOf('end') == -1) {
                return {
                    url: url
                };
            }
            var uri = URI(url);
            var params = uri.search(true);

            var start = moment(params.start);
            var end = moment(params.end);
            return {
                period: end.diff(start, 'minutes'),
                offset: moment().diff(end, 'minutes'),
                url: uri.removeSearch(['start', 'end']).toString()
            }
        }

    }

    return service;
})