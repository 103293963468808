namespace aq.admin.accounts.buildings {
    export class AdminBuildingDetailsCtrl {
        private sharedAccountsArray: any[];
        private searchText: string;
        /* @ngInject */
        constructor(
            private $scope: AdminBuildingDetailsCtrlScope,
            private Messages,
            private building: aq.common.models.Building,
            private Restangular: restangular.IService,
            private $state: ng.ui.IStateService,
            private $mdDialog: ng.material.IDialogService,
            private accounts: any[],
            private EnergyStarApiService: aq.energyStarApi.EnergyStarApiService,
            private BuildingService: aq.services.BuildingService,
        ) {
            this.building = building;
            this.accounts = accounts;
            this.setSharedAccountsArray();
        }

        public openChangeAccount() {
            this.$mdDialog.show({
                templateUrl: 'app/admin/accounts/buildings/details/changeAccount.html',
                clickOutsideToClose: true,
                scope: this.$scope,
                preserveScope: true,
                parent: angular.element(document.body)
            });
        }

        public openShareBuilding() {
            this.$mdDialog.show({
                templateUrl: 'app/admin/accounts/buildings/details/shareBuilding.html',
                clickOutsideToClose: true,
                scope: this.$scope,
                preserveScope: true,
                parent: angular.element(document.body)
            });
        }

        public delete(ev) {
            const confirm = this.$mdDialog.confirm()
                .textContent('Are you sure you want to delete this building?  All data will be deleted.')
                .ariaLabel('delete building')
                .targetEvent(ev)
                .ok('Delete')
                .cancel('Cancel');
            this.$mdDialog.show(confirm).then(() => {
                return this.Restangular.one('internal').one('buildings', this.building.id).customDELETE(null, 'archive', {});
            }).then(() => {
                this.Messages.success(`Successfully deleted ${this.building.name}.`);
                this.$state.go('^');
            }).catch(() => {
                this.Messages.error('Unable to delete building');
            });
        }

        public archive(ev) {
            const confirm = this.$mdDialog.confirm()
                .textContent('Are you sure you want to archive this building?  No data will be deleted but the building can no longer be accessed.')
                .ariaLabel('archive building')
                .targetEvent(ev)
                .ok('Archive')
                .cancel('Cancel');
            this.$mdDialog.show(confirm).then(() => {
                return this.Restangular.one('internal').one('buildings', this.building.id).customPOST(null, 'archive', {});
            }).then(() => {
                this.Messages.success(`Successfully archived ${this.building.name}.`);
                this.$state.go('^');
            }).catch(() => {
                this.Messages.error('Unable to archive building');
            });
        }

        public unarchive() {
            this.Restangular.one('internal').one('buildings', this.building.id)
                .customPOST(null, 'unarchive', {})
                .then(() => {
                    this.building.archived = false;
                    this.Messages.success(`Successfully unarchived ${this.building.name}.`);
                }).catch(() => {
                    this.Messages.error('Unable to unarchive building');
                });
        }

        public changeAccount(changeAccount) {
            this.Restangular.one('internal').one('buildings', this.building.id)
                .customPOST(null, 'changeAccount', { toAccount: changeAccount.id })
                .then(() => {
                    // if linked to energy star property, unlink it completely
                    const accountId = (typeof this.building.account) == 'object'
                        ? (this.building.account as any).id
                        : this.building.account;
                    this.Restangular.one('accounts', accountId)
                        .getList('integrations', { inflate: 'integrationApp', nocache: new Date().getTime() })
                        .then(integrations => {
                            if (integrations.find(item => item.integrationApp && item.integrationApp.name === 'EnergyStar')) {
                                return this.unlinkEnergyStarProperty(accountId, this.building.id);
                            }
                        })
                        .finally(() => {
                            this.Messages.success('Successfully changed account');
                            this.$mdDialog.hide();
                        });
                }).catch((err) => {
                    this.Messages.error('Error changing accounts');
                    this.$mdDialog.hide();
                });
        }

        public unlinkEnergyStarProperty(accountId, buildingId) {
            try {
                this.EnergyStarApiService.unlinkBuildingFromEnergyStarProperty(accountId, buildingId);
            } catch (e) {
                console.error(e);
            }
        }

        public shareBuilding() {
            const selectedAccountsArray = this.sharedAccountsArray.map(account => account.id);
            this.BuildingService.shareBuilding(this.building.id, selectedAccountsArray)
                .then((building) => {
                    this.Messages.success('Successfully shared building with accounts');
                    this.$mdDialog.hide();
                    this.building.sharedAccounts = building.sharedAccounts;
                    this.setSharedAccountsArray();
                })
                .catch((error) => {
                    this.Messages.error('Unable to share building with accounts');
                });
        }

        public removeSharedAccount(index) {
            this.sharedAccountsArray.splice(index, 1);
        }

        public setSharedAccountsArray() {
            this.sharedAccountsArray = [];
            this.building.sharedAccounts.forEach((accountId) => {
                const foundAccount = this.accounts.find((account => {
                    return account.id === accountId;
                }));
                this.sharedAccountsArray.push(foundAccount);
            });
        }

        public selectedItemChange(item) {
            if (item && _.filter(accounts, { 'id': item.id })) {
                this.sharedAccountsArray.push(item);
            }
            this.sharedAccountsArray = _.uniqBy(this.sharedAccountsArray, 'id');
            this.searchText = '';
        }

        public searchAccounts() {
            const results = this.accounts.filter((account) => {
                const name = account.name.toLowerCase();
                return name.includes(this.searchText.toLowerCase());
            });
            return results;
        }

        public cancel() {
            this.$mdDialog.cancel();
            this.setSharedAccountsArray();
        };
    }

    export interface AdminBuildingDetailsCtrlScope extends AdminBuildingListCtrlScope {
        building: aq.common.models.Building;
        changeAccount: aq.common.models.Account;
        accounts: any[];
    }

    angular.module('aq.admin.accounts.buildings').controller('AdminBuildingDetailsCtrl', AdminBuildingDetailsCtrl);
}
