namespace aq.propertySettings {
    export class AlertPreviewComponentCtrl extends AbstractAlertComponent {
        public alert;
        public calendars;
        public account: aq.common.models.Account;
        public building;
        public isActivity: boolean;
        public noDataCallback: () => void;
        public contextLoadedCallback: () => void;
        public chartConfig;
        private showOptimizationButton: boolean = false;
        private hideTitle: boolean = false;
        private displayHeight: number;
        private xaxisDateFormat: string = null;
        private xaxisLabelRotation: number;
        private eeLinearRegression = true;
        private eeWorkaround = false;

        /* @ngInject */
        constructor(
            private DataService,
            private $q,
            private BaseService,
            private $filter,
            private Auth: aq.services.Auth,
            public $window: ng.IWindowService
        ) {
            super();
        }

        $onInit() {
            try {
                this.getAlertPreviewConfig();
            } catch (err) {
                if (this.isActivity) {
                    this.noDataCallback();
                }
            }
        }

        $onChanges(changesObj) {
            if (changesObj.alert) {
                try {
                    this.getAlertPreviewConfig();
                } catch (err) {
                    if (this.isActivity) {
                        this.noDataCallback();
                    }
                }
            }
        }

        public goToOptimization() {
            const currBuilding = this.building;
            const currAlert = this.alert;

            let startDate, endDate;
            if (currAlert.issue) {
                startDate = moment(currAlert.issue.openedOn).tz(currBuilding.timeZoneId);
                endDate = moment(currAlert.issue.closedOn).tz(currBuilding.timeZoneId);
            } else {
                startDate = moment().subtract(1, 'weeks');
                endDate = moment();
            }

            this.account.get({ single: true }).then((thisAccount) => {
                const optimizationURI = URI('/accounts/' + thisAccount.id + '/optimization/building')
                    .search({
                        unit: currAlert.unit.unit,
                        apiUnit: currAlert.unit.apiUnit,
                        interval: currAlert.interval,
                        off: _.without(thisAccount.buildings, currBuilding.id).join(','),
                        start: startDate.toISOString(),
                        end: endDate.toISOString(),
                        children: 'buildings'
                    });
                this.$window.location.href = optimizationURI.toString();
            });
        }

        public getAlertPreviewConfig() {
            if (!this.hasEnoughInfoForPreview()) {
                return;
            }
            const queryable = this.getRestQueryable();
            const restangularQueryable = this.BaseService.getCurrentRestangularObject(queryable);
            let startDate, endDate;
            if (this.alert.issue) {
                startDate = this.alert.issue.openedOn;
                endDate = this.alert.issue.closedOn;
            } else {
                startDate = moment().subtract(1, 'weeks');
                endDate = moment();
            }
            const dataPromises = [];
            dataPromises.push(this.DataService.data(restangularQueryable, '15min', startDate, endDate, this.alert.unit));
            if (this.alert.whenCondition === 'CALENDAR') {
                if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                    dataPromises.push(this.DataService.expectedEnergy(restangularQueryable, '15min', startDate, endDate));
                } else {
                    dataPromises.push(this.DataService.metricsScheduling(restangularQueryable, '15min', startDate, endDate, this.alert.unit, { id: this.alert.calendar }));
                }
                this.$q.all(dataPromises).then((preliminaryResults) => {
                    this.checkDataAmount(preliminaryResults, restangularQueryable, startDate, endDate).then((results) => {
                        const categories: any[] = this.getCustomRulesByCategory();
                        const data = results[0];
                        const dataSeries = this.getSeriesForData(data);
                        if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                            const expectedEnergy = results[1];
                            const expectedRangeSeries = this.getExpectedRangeSeries(expectedEnergy);
                            const max = Math.round(_.max([this.getMaxValue(expectedEnergy, true), _.maxBy(dataSeries.data, 'y')['y']]));
                            const maxValue = this.getMaxValue(expectedEnergy, true);
                            const alertAreaSeries: any[] = categories
                                .map((category) => this.getAlertArea(category, expectedRangeSeries, null, false, max, maxValue + 1000, null, false));
                            const series = [dataSeries, expectedRangeSeries, ...alertAreaSeries];
                            this.buildChartConfig(series, [], max);
                        } else {
                            const metrics = _.find(results[1].partitions, { name: categories[0].categoryName });
                            const max = Math.round(_.max([this.getMaxValue(metrics, true), _.maxBy(dataSeries.data, 'y')['y']]));
                            const maxValue = this.getMaxValue(metrics, true);
                            const minValue = this.getMinValue(metrics, true);
                            const alertAreaSeries: any[] = categories
                                .map((category) => this.getAlertArea(category, dataSeries, metrics, true, max, minValue, maxValue, false));
                            const series = [dataSeries, ...alertAreaSeries];
                            const plotLines = this.getPlotLines(metrics);
                            this.buildChartConfig(series, plotLines, max);
                        }
                        if (this.isActivity) {
                            this.contextLoadedCallback();
                        }
                    })
                    .catch(() => {
                        this.noDataCallback();
                    });
                });
            } else if (this.alert.whenCondition === 'CUSTOM') {
                if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                    dataPromises.push(this.DataService.expectedEnergy(restangularQueryable, '15min', startDate, endDate));
                }
                this.$q.all(dataPromises).then((preliminaryResults) => {
                    this.checkDataAmount(preliminaryResults, restangularQueryable, startDate, endDate).then((results) => {
                        const category = angular.copy(this.alert.customRule);
                        category.timeStart = moment(category.timeStart, 'h:mm A').format('HH:mm:ss');
                        category.timeEnd = moment(category.timeEnd, 'h:mm A').format('HH:mm:ss');
                        const data = results[0];
                        const dataSeries = this.getSeriesForData(data);
                        if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                            const expectedEnergy = results[1];
                            const expectedRangeSeries = this.getExpectedRangeSeries(expectedEnergy);
                            const max = Math.round(_.max([this.getMaxValue(expectedEnergy, true), _.maxBy(dataSeries.data, 'y')['y']]));
                            const maxValue = this.getMaxValue(expectedEnergy, true);
                            const alertAreaSeries = this.getAlertArea(category, expectedRangeSeries, null, false, max, maxValue + 1000, null, true);
                            const series = [dataSeries, expectedRangeSeries, alertAreaSeries];
                            this.buildChartConfig(series, [], 975);
                        } else {
                            const metrics = this.getCustomMetrics(dataSeries, category);
                            const max = Math.round(_.max([this.getMaxValue(metrics, true), _.maxBy(dataSeries.data, 'y')['y']]));
                            const maxValue = this.getMaxValue(metrics, true);
                            const minValue = this.getMinValue(metrics, true);
                            const alertAreaSeries = this.getAlertArea(category, dataSeries, metrics, false, max, minValue, maxValue, true);
                            const series = [dataSeries, alertAreaSeries];
                            const plotLines = this.getPlotLines(metrics, false);
                            this.buildChartConfig(series, plotLines, max);
                        }
                        if (this.isActivity) {
                            this.contextLoadedCallback();
                        }
                    })
                    .catch(() => {
                        this.noDataCallback();
                    });
                });
            } else if (this.alert.whenCondition === 'ANYTIME') {
                if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                    dataPromises.push(this.DataService.expectedEnergy(restangularQueryable, '15min', startDate, endDate, this.eeWorkaround));
                } else {
                    dataPromises.push(this.DataService.metrics(restangularQueryable, '15min', startDate, endDate, this.alert.unit));
                }
                this.$q.all(dataPromises).then((preliminaryResults) => {
                    this.checkDataAmount(preliminaryResults, restangularQueryable, startDate, endDate).then((results) => {
                        const data = results[0];
                        const dataSeries = this.getSeriesForData(data);
                        if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                            const expectedEnergy = results[1];
                            const expectedRangeSeries = this.getExpectedRangeSeries(expectedEnergy);
                            const max = Math.round(_.max([this.getMaxValue(expectedEnergy, true), _.maxBy(dataSeries.data, 'y')['y']]));
                            const maxValue = this.getMaxValue(expectedEnergy, true);
                            const alertAreaSeries = this.getAlertArea(null, expectedRangeSeries, null, false, maxValue, maxValue + 1000, null, true);
                            const series = [dataSeries, expectedRangeSeries, alertAreaSeries];
                            this.buildChartConfig(series, [], max);
                        } else {
                            const metrics = results[1];
                            const max = Math.round(_.max([this.getMaxValue(metrics, true), _.maxBy(dataSeries.data, 'y')['y']]));
                            const maxValue = this.getMaxValue(metrics, true);
                            const minValue = this.getMinValue(metrics, true);
                            const alertAreaSeries = this.getAlertArea(null, dataSeries, metrics, true, max, minValue, maxValue, true);
                            const series = [dataSeries, alertAreaSeries];
                            const plotLines = this.getPlotLines(metrics);
                            this.buildChartConfig(series, plotLines, max);
                        }
                        if (this.isActivity) {
                            this.contextLoadedCallback();
                        }
                    });
                })
                .catch(() => {
                    this.noDataCallback();
                });
            }
        }

        private checkDataAmount(data, queryable, start, end): Promise<any[]> {
            let interval: string = '';
            /**
             * Max threshold currenly set on high charts is 1000 points. These if-statements make it so that a chart will display
             * even if our original query returns more than 1000 points.
             */
            if (data[0].timestampsWithValues.length < 1000) {
                return new Promise((resolve, reject) => {
                    resolve(data);
                });
            } else if (data[0].timestampsWithValues.length >= 1000 && data[0].timestampsWithValues.length < 4000) {
                interval = '1h';
            } else if (data[0].timestampsWithValues.length >= 4000 && data[0].timestampsWithValues.length < 96000) {
                interval = '1d';
            } else {
                interval = '1mon';
            }
            const dataPromises = [];
            dataPromises.push(this.DataService.data(queryable, interval, start, end, this.alert.unit));
            if (this.alert.whenCondition === 'ANYTIME') {
                if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                    dataPromises.push(this.DataService.expectedEnergy(queryable, interval, start, end, this.eeWorkaround));
                } else {
                    dataPromises.push(this.DataService.metrics(queryable, interval, start, end, this.alert.unit));
                }
            } else if (this.alert.whenCondition === 'CUSTOM') {
                if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                    dataPromises.push(this.DataService.expectedEnergy(queryable, interval, start, end));
                }
            } else if (this.alert.whenCondition === 'CALENDAR') {
                if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                    dataPromises.push(this.DataService.expectedEnergy(queryable, interval, start, end));
                } else {
                    dataPromises.push(
                        this.DataService.metricsScheduling(queryable, interval, start, end, this.alert.unit, { id: this.alert.calendar })
                    );
                }
            }
            return this.$q.all(dataPromises);
        }

        private hasEnoughInfoForPreview(): boolean {
            if ((!this.alert) ||
                (this.alert.drillMode !== 'BUILDING' && !this.alert.queryableId) ||
                (this.alert.whenCondition === 'CALENDAR' && (!this.alert.calendar || !this.alert.category)) ||
                (this.alert.whenCondition === 'CUSTOM' &&
                    (!this.alert.customRule || !this.alert.customRule.timeStart || !this.alert.customRule.timeEnd)) ||
                (!this.alert.unit)
            ) {
                return false;
            }
            return true;
        }

        private getCustomRulesByCategory(): any[] {
            const calendar: any = _.find(this.calendars, { id: this.alert.calendar });
            return _.filter(calendar.rules, { category: { id: this.alert.category }});
        }

        private buildChartConfig(series, plotLines, yAxisMax = null) {
            const timeZoneId = this.building.timeZoneId;
            const dateFormat = this.xaxisDateFormat || 'dd, MMM D';
            this.chartConfig = {
                chart: {
                    plotBorderWidth: 1,
                    type: 'line'
                },
                legend: {
                    enabled: false
                },
                lang: {
                    noData: ''
                },
                title: {
                    text: !this.hideTitle && 'Alert Preview'
                },
                tooltip: {
                    formatter() {
                        return `${this.series.name}<br>${moment(this.point.x).tz(timeZoneId).format('lll')}`;
                    }
                },
                xAxis: {
                    type: 'datetime',
                    tickLength: 0,
                    gridLineWidth: 1,
                    gridLineColor: '#c7c7c7',
                    labels: {
                        formatter() {
                            return moment(this.value).tz(timeZoneId).format(dateFormat);
                        },
                        rotation: this.xaxisLabelRotation || 0
                    }
                },
                yAxis: {
                    min: 0,
                    max: yAxisMax ? yAxisMax + (yAxisMax * .1) : null, // add padding to the top so you can see the alert area
                    title: {
                        text: this.alert.unit.label
                    },
                    gridLineColor: '#c7c7c7',
                    gridLineWidth: 1,
                    plotLines
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: false
                        }
                    }
                },
                plotOptions: {
                    line: {
                        animation: false
                    },
                    series: {
                        events: {
                            legendItemClick: () => {
                                return false;
                            }
                        },
                        stickyTracking: false
                    }
                },
                series
            };
        }

        private getMaxValue(metrics, convertToUnit) {
            if (this.alert.calculationMethod === 'PERCENTAGE' && this.alert.smallerThanAverage) {
                const avgValue = this.getAverage(metrics, convertToUnit);
                return avgValue + (avgValue * (this.alert.smallerThanAverage / 100));
            } else if (this.alert.calculationMethod === 'ABSOLUTE' && this.alert.smallerThan) {
                return this.alert.smallerThan;
            } else if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                let maxValue = 0;
                let maxIndex;
                _.forEach(metrics['POWER_EXPECTED'].values, (value, index) => {
                    if (maxValue < value) {
                        maxValue = value;
                        maxIndex = index;
                    }
                });
                return Math.round(this.$filter('toUnit')(maxValue + metrics['POWER_STDDEV'].values[maxIndex], this.alert.unit));
            }
            return null;
        }

        private getMinValue(metrics, convertToUnit) {
            if (this.alert.calculationMethod === 'PERCENTAGE' && this.alert.greaterThanAverage) {
                const avgValue = this.getAverage(metrics, convertToUnit);
                return avgValue - (avgValue * (this.alert.greaterThanAverage / 100));
            } else if (this.alert.calculationMethod === 'ABSOLUTE' && this.alert.greaterThan) {
                return this.alert.greaterThan;
            } else if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                let minValue = 0;
                let minIndex;
                _.forEach(metrics['POWER_EXPECTED'].values, (value, index) => {
                    if (minValue > value) {
                        minValue = value;
                        minIndex = index;
                    }
                });
                return minValue - metrics['POWER_STDDEV'].values[minIndex];
            }
            return 0;
        }

        private getAverage(metrics, convertToUnit) {
            if (convertToUnit) {
                return this.$filter('toUnit')(metrics.values.avg, this.alert.unit);
            }
            return metrics.values.avg;
        }

        private getAlertArea(category, dataSeries, metrics, convertToUnit, yAxisMax, minValue, maxValue, skipTimeframeCheck) {
            const alertAreaSeries = {
                name: 'Alert Area',
                type: 'polygon',
                data: [],
                color: 'rgba(231,15,40,.5)',
                zIndex: 0
            };
            let firstDate;
            let lastDate;

            if(dataSeries && dataSeries.data && dataSeries.data.length > 0) {
                firstDate = dataSeries.data[0].x;
                lastDate = dataSeries.data[dataSeries.data.length - 1].x;
            }

            if (!category) {
                alertAreaSeries.data = alertAreaSeries.data
                    .concat(this.createRectangleForPolygonSeries(dataSeries.data[0].x, dataSeries.data[dataSeries.data.length - 1].x, 0, minValue));
                if (maxValue) {
                    alertAreaSeries.data.push({ x: null, y: null });
                    alertAreaSeries.data = alertAreaSeries.data
                        .concat(this.createRectangleForPolygonSeries(dataSeries.data[0].x, dataSeries.data[dataSeries.data.length - 1].x, maxValue, maxValue + 1000));
                }
            } else if( skipTimeframeCheck || (firstDate && lastDate && this.categoryInTimeFrame(firstDate, lastDate, category))){
                const alertAreas = [];
                let currentAlertArea = {
                    x1: null,
                    x2: null
                };

                if(category.week && category.week.length > 0){
                    for (let i = 0; i < dataSeries.data.length; i++) {
                        const data = dataSeries.data[i];

                        if (this.matchesCalendar(category, data.x, currentAlertArea.x1)) {
                            if (!currentAlertArea.x1) {
                                currentAlertArea.x1 = data.x;
                            }
                            currentAlertArea.x2 = data.x;
                        } else if (currentAlertArea.x1) {
                            alertAreas.push(angular.copy(currentAlertArea));
                            currentAlertArea = {
                                x1: null,
                                x2: null
                            };
                        }
                    }

                    if (currentAlertArea.x1) {
                        alertAreas.push(angular.copy(currentAlertArea));
                    }
                }else{
                    let firstTimeInTimeline = this.findFirstTime(dataSeries.data,0, dataSeries.data.length-1, category.dateStart);
                    let lastTimeInTimeline = this.findLastTime(dataSeries.data,0, dataSeries.data.length-1, category.dateEnd);

                    if(!firstTimeInTimeline){
                        firstTimeInTimeline = firstDate;
                    }

                    if(!lastTimeInTimeline){
                        lastTimeInTimeline = lastDate;
                    }

                    if(this.matchesCalendar(category, firstTimeInTimeline, currentAlertArea.x1)){
                        currentAlertArea = {x1: firstTimeInTimeline, x2: lastTimeInTimeline};
                        alertAreas.push(angular.copy(currentAlertArea));
                    }
                }

                alertAreas.forEach((alertArea) => {
                    alertAreaSeries.data = alertAreaSeries.data.concat(this.createRectangleForPolygonSeries(alertArea.x1, alertArea.x2, 0, minValue));
                    alertAreaSeries.data.push({ x: null, y: null });
                    alertAreaSeries.data = alertAreaSeries.data.concat(this.createRectangleForPolygonSeries(alertArea.x1, alertArea.x2, maxValue, yAxisMax + 1000));
                    alertAreaSeries.data.push({ x: null, y: null });
                });
            }
            return alertAreaSeries;
        }

        private createRectangleForPolygonSeries(x1: number, x2: number, y1: number, y2: number) {
            const rectangle = [];
            rectangle.push({
                x: x1,
                y: y1
            });
            rectangle.push({
                x: x2,
                y: y1
            });
            rectangle.push({
                x: x2,
                y: y2
            });
            rectangle.push({
                x: x1,
                y: y2
            });
            return rectangle;
        }

        private getCustomMetrics(dataSeries, category) {
            const vals = [];
            for (let i = 0; i < dataSeries.data.length; i++) {
                const data = dataSeries.data[i];
                if (this.matchesCalendar(category, data.x) && data.y) {
                    vals.push(data.y);
                }
            }
            return {
                values: {
                    avg: _.sum(vals) / vals.length
                }
            };
        }

        private categoryInTimeFrame(firstDate, lastDate, category){
            const categoryStart = category.dateStart;
            const categoryEnd = category.dateEnd;
            const timeZoneId = this.building.timeZoneId;
            const firstDateTime = moment(firstDate).tz(timeZoneId);
            const lastDateTime = moment(lastDate).tz(timeZoneId);
            const categoryStartTime = moment(categoryStart).tz(timeZoneId);
            const categoryEndTime = moment(categoryEnd).tz(timeZoneId);

            if((categoryStartTime.isSameOrAfter(firstDateTime) && categoryStartTime.isSameOrBefore(lastDateTime))
                || (categoryEndTime.isSameOrAfter(firstDateTime) && categoryEndTime.isSameOrBefore(lastDateTime))
                || (category.week && category.week.length > 0 && ((!categoryEndTime.isBefore(firstDateTime) && categoryStartTime.isSameOrBefore(firstDateTime))
                    || (categoryEndTime.isSameOrAfter(lastDateTime)) && !categoryStartTime.isAfter(lastDateTime)))
                || (category.week && category.week.length > 0 && !categoryStart && !categoryEnd)){
                return true;
            }else{
                return false;
            }
        }

        //Binary search for first time in the timeline that's within the date range
        private findFirstTime(arr, lowIndex, highIndex, firstDate){
                if(lowIndex <= highIndex){
                    if(highIndex === 0){
                        return arr[0].x;
                    }
                    let midIndex = Math.round((lowIndex + highIndex)/2);
                    let timeZoneId = this.building.timeZoneId;
                    let midTime = moment(arr[midIndex].x).tz(timeZoneId);
                    let midTimeMinusOne = moment(arr[midIndex-1].x).tz(timeZoneId);
                    if( midIndex > 0 && midTime.isSameOrAfter(firstDate)){
                        if(midTimeMinusOne.isBefore(firstDate)) {
                            return arr[midIndex].x;
                        }
                        else{
                            return this.findFirstTime(arr, lowIndex, midIndex-1, firstDate);
                        }
                    }
                    if(midTime.isAfter(firstDate)){
                        return this.findFirstTime(arr, lowIndex, midIndex-1, firstDate);
                    }else{
                        return this.findFirstTime(arr, midIndex+1, highIndex, firstDate);
                    }
                }
                return undefined;
        }

        //Binary search for last time in the timeline that's within the date range
        private findLastTime(arr, lowIndex, highIndex, lastDate){
            if(lowIndex <= highIndex){
                let midIndex = Math.round((lowIndex + highIndex)/2);
                if(midIndex === arr.length - 1 ){
                    return arr[arr.length - 1].x;
                }
                let timeZoneId = this.building.timeZoneId;
                let midTime = moment(arr[midIndex].x).tz(timeZoneId);
                let midTimePlusOne = moment(arr[midIndex+1].x).tz(timeZoneId);
                if( midIndex <= highIndex && midTime.isSameOrBefore(lastDate)){
                    if(midTimePlusOne.isAfter(lastDate)) {
                        return arr[midIndex].x;
                    }
                    else{
                        return this.findLastTime(arr, midIndex+1, highIndex, lastDate);
                    }
                }
                if(midTime.isAfter(lastDate)){
                    return this.findLastTime(arr, lowIndex, midIndex-1, lastDate);
                }else{
                    return this.findLastTime(arr, midIndex+1, highIndex, lastDate);
                }
            }
            return undefined;
        }

        private matchesCalendar(category, timestamp, matchedBound?): boolean {
            let matches = true;
            const timeZoneId = this.building.timeZoneId;
            const dateTime = moment(timestamp).tz(timeZoneId);
            if (!matchedBound) matchedBound = dateTime;

            if (category.dateStart) {
                const start = moment(category.dateStart).tz(timeZoneId);
                matches = matches && dateTime.isSameOrAfter(start);
            }
            if (category.dateEnd) {
                const end = moment(category.dateEnd).tz(timeZoneId);
                matches = matches && dateTime.isSameOrBefore(end);
            }
            if (category.week && category.week.length > 0) {
                let matchesDayOfWeek = false;
                for (let w = 0; w < category.week.length; w++) {
                    if (dateTime.format('dddd').toLowerCase() === category.week[w].toLowerCase()) {
                        matchesDayOfWeek = true;
                        break;
                    }
                }
                matches = matches && matchesDayOfWeek;
            }
            if (category.timeStart) {
                const tokens = category.timeStart.split(':');
                const hour = parseInt(tokens[0], 10);
                const minutes = parseInt(tokens[1], 10);
                const timeStartMoment = moment(matchedBound).tz(timeZoneId).hour(hour).minute(minutes);
                matches = matches && dateTime.isSameOrAfter(timeStartMoment);
            }
            if (category.timeEnd) {
                const tokens = category.timeEnd.split(':');
                const hour = parseInt(tokens[0], 10);
                const minutes = parseInt(tokens[1], 10);
                let timeEndMoment = moment(matchedBound).tz(timeZoneId).hour(hour).minute(minutes);

                if (moment(category.timeEnd, 'hh:mm').tz(timeZoneId).isBefore(moment(category.timeStart, 'hh:mm').tz(timeZoneId)))
                    timeEndMoment = timeEndMoment.add(1, 'day');

                matches = matches && dateTime.isSameOrBefore(timeEndMoment);
            }
            return matches;
        }

        private getSeriesForData(data) {
            const series = {
                name: this.getQueryable().name,
                data: [],
                color: '#0091f1',
                zIndex: 2
            };
            for (let i = 0; i < data.timestamps.length; i++) {
                    series.data.push({
                        x: data.timestamps[i],
                        y: data.values[i] ? Math.round(this.$filter('toUnit')(data.values[i], this.alert.unit)) : 0
                    });
            }
            return series;
        }

        private getExpectedRangeSeries(data) {
            const series = {
                name: `Expected Energy ${this.getQueryable().name}`,
                data: [],
                color: '#aacee2',
                zIndex: 1,
                type: 'arearange',
                fillOpacity: 1
            };
            for (let i = 0; i < data['POWER_EXPECTED'].timestamps.length; i++) {
                const stdDev = data['POWER_STDDEV'].values[i];
                const expected = data['POWER_EXPECTED'].values[i];
                if (expected && stdDev) {
                        series.data.push({
                            x: data['POWER_EXPECTED'].timestamps[i],
                            low: Math.round(this.$filter('toUnit')(expected - stdDev, this.alert.unit)),
                            high: Math.round(this.$filter('toUnit')(expected + stdDev, this.alert.unit))
                        });
                }
            }
            return series;
        }

        private getPlotLines(metrics, convertToUnit = true) {
            const plotLines = [];
            const avgPlotLine = {
                color: '#7ACD46',
                dashStyle: 'dash',
                value: convertToUnit ? this.$filter('toUnit')(metrics.values.avg, this.alert.unit) : metrics.values.avg,
                width: 2,
                label: {
                    text: 'Avg',
                    align: 'right',
                    style: {
                        color: '#7ACD46'
                    },
                    x: -10
                }
            };
            plotLines.push(avgPlotLine);
            return plotLines;
        }

        private getRestQueryable() {
            const buildingPrefix = [
                {
                    id: this.account.id,
                    route: 'accounts'
                }, {
                    id: this.building.id,
                    route: 'buildings'
                }
            ];
            const queryable = this.getQueryable();
            let queryCursor = queryable;
            const queryables = [];
            let parentRoute = queryCursor.route;
            // push selected queryable onto chain
            if (this.alert.drillMode !== 'BUILDING') {
                queryables.push({
                    id: queryCursor.id,
                    route: queryCursor.route
                });
            }

            while (queryCursor.parent != null) {
                // only push a queryable onto the chain if its route is different from its parent
                if (queryCursor.route != parentRoute) {
                    queryables.push({
                        id: queryCursor.id,
                        route: queryCursor.route
                    });
                }

                parentRoute = queryCursor.route;
                queryCursor = queryCursor.parent;
            }
            _.reverse(queryables);
            return _.concat(buildingPrefix, queryables);
        }
    }
    angular.module('properties')
        .component('alertPreview', {
            templateUrl: 'app/properties/alerts/directives/alertPreview.html',
            bindings: {
                alert: '<',
                calendars: '<',
                account: '=',
                building: '=',
                buildingDrillin: '<',
                collectors: '<',
                sources: '<',
                tenants: '<',
                noDataCallback: '&?',
                contextLoadedCallback: '&?',
                isActivity: '<?',
                showOptimizationButton: '<?',
                hideTitle: '<',
                displayHeight: '<',
                xaxisDateFormat: '@',
                xaxisLabelRotation: '<'
            },
            controller: AlertPreviewComponentCtrl
        });
}
