var aq;
(function (aq) {
    var services;
    (function (services) {
        var BudgetServiceV3 = /** @class */ (function () {
            /* @ngInject */
            function BudgetServiceV3(AuthToken, $location, UserService, RestangularV3, RestangularV3FullResponse) {
                this.AuthToken = AuthToken;
                this.$location = $location;
                this.UserService = UserService;
                this.RestangularV3 = RestangularV3;
                this.RestangularV3FullResponse = RestangularV3FullResponse;
            }
            BudgetServiceV3.prototype.getBudgetPlannerTemplate = function (buildingId) {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        try {
                            return [2 /*return*/, this.RestangularV3FullResponse.one('budget-workbook').withHttpConfig({ responseType: 'blob', transformRequest: angular.identity })
                                    .customGET('download-budget-workbook', { buildingId: buildingId })];
                        }
                        catch (error) {
                            return [2 /*return*/, Promise.reject(error)];
                        }
                        return [2 /*return*/];
                    });
                });
            };
            BudgetServiceV3.prototype.verifyBudgetDataImport = function (accountId, buildingId, data) {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        try {
                            return [2 /*return*/, this.RestangularV3.one('budget-workbook').all('verify-budget-data').customPOST(data, '', { buildingId: buildingId })];
                        }
                        catch (error) {
                            return [2 /*return*/, Promise.reject(error)];
                        }
                        return [2 /*return*/];
                    });
                });
            };
            BudgetServiceV3.prototype.importBudgetData = function (accountId, buildingId, data) {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        try {
                            return [2 /*return*/, this.RestangularV3.one('budget-workbook').all('upload-budget-data').customPOST(data, '', { buildingId: buildingId })];
                        }
                        catch (error) {
                            throw error;
                        }
                        return [2 /*return*/];
                    });
                });
            };
            return BudgetServiceV3;
        }());
        services.BudgetServiceV3 = BudgetServiceV3;
        angular
            .module('aq.services')
            .service('BudgetServiceV3', BudgetServiceV3);
    })(services = aq.services || (aq.services = {}));
})(aq || (aq = {}));
