namespace aq.deviceManagement {
    export class AddDeviceLink extends aq.common.Controllers.ModalCtrl {
        public toId: number;

        private linkRequest: Link = {
            parent: null,
            child: null,
            inputId: null,
            protocol: null
        };
        private formModel = {
            protocol: '',
            connectionType: '',
            createLink: false
        };
        private FROM: FromTo = {
            availablePulseInputs: [],
            isParent: null,
            category: '',
            needsDeviceIdentifier: null,
            device: null,
            inputId: ''
        };
        private TO: FromTo = {
            availablePulseInputs: [],
            isParent: null,
            category: '',
            needsDeviceIdentifier: null,
            device: null,
            inputId: ''
        };
        /* @ngInject */
        constructor(
            public $mdDialog: ng.material.IDialogService,
            device: aq.common.models.DeviceElement,
            private links,
            private DeviceService: aq.services.DeviceService,
            private DataStore: aq.common.DataStore,
            private $mdStepper,
            private RestangularV3,
            createLink,
            showCreateAnother = true
        ) {
            super({}, $mdDialog);
            this.formModel.createLink = createLink || false;
            this.setFROMFromDevice(device);
            this.toId = null;
        }

        setFROMFromDevice(device: aq.common.models.DeviceElement) {
            this.FROM.device = this.RestangularV3.copy(device);
            this.FROM.category = device.deviceCategory;
            this.FROM.needsDeviceIdentifier = this.needsDeviceIdentifier(device);
            this.DeviceService.getAvailablePulseInputs(device)
                .then((val) => this.FROM.availablePulseInputs = val);
        }

        setTOFromDeviceId(deviceId) {
            this.DeviceService
                .get(deviceId)
                .then((device) => {
                    this.TO.device = device;
                    this.TO.category = device.deviceCategory;
                    this.TO.needsDeviceIdentifier = this.needsDeviceIdentifier(device);
                    return this.DeviceService.getAvailablePulseInputs(device);
                })
                .then((availablePulseInputs) => {
                    this.TO.availablePulseInputs = availablePulseInputs;
                });
        }

        needsDeviceIdentifier(device: aq.common.models.Device): boolean {
            if (_.get(device, 'deviceClass.fields.deviceIdentifier', false)) {
                return !_.get(device, 'deviceClass.fields.deviceIdentifier.value', '');
            }
            return false;
        }

        // utility methods to reset the form state
        connectionTypeChanged() {
            if (this.formModel.protocol) {
                this.formModel.protocol = null;
            }
            if (!_.isNil(this.TO.device)) {
                this.TO = null;
            }
        }

        protocolTypeChanged() {
            if (this.FROM.inputId) {
                this.FROM.inputId = null;
            }
            if (!_.isNil(this.TO.device)) {
                this.TO = null;
            }
        }

        saveFROM() {
            if (this.FROM.needsDeviceIdentifier && _.get(this.FROM.device, 'fields.deviceIdentifier.value', false)) {
                this.DeviceService.save(this.FROM.device);
            }
        }

        finalizeAndSubmit() {
            this.DeviceService.save(this.TO.device).then(() => {
                this.linkRequest.protocol = this.formModel.protocol;
                if (this.TO.device.gateway) {
                    return this.linkParentChild(this.TO, this.FROM);
                }

                if (this.FROM.device.gateway) {
                    return this.linkParentChild(this.FROM, this.TO);
                }

                if (this.FROM.category == 'METER' || this.FROM.category === 'SENSOR') {
                    return this.linkParentChild(this.TO, this.FROM);
                }

                if (this.FROM.category == 'NETWORKING' && (this.TO.category == 'METER' || this.TO.category == 'SENSOR')) {
                    return this.linkParentChild(this.FROM, this.TO);
                }

                // example case: 8911 to bridge -- need to check up/down protocols
                if (this.FROM.category == 'NETWORKING' && this.TO.category == 'NETWORKING') {
                    if (_.some(this.FROM.device.deviceClass.upwardCommunications, (protocol) => {
                        return _.toUpper(protocol) == _.toUpper(this.formModel.protocol);
                    })) {
                        return this.linkParentChild(this.TO, this.FROM);
                    } else {
                        return this.linkParentChild(this.FROM, this.TO);
                    }
                }
            });
        }

        public cancel(data?): void {
            this.$mdDialog.cancel(data);
        }

        public hide(data?): void {
            this.$mdDialog.hide(data);
        }

        public next(): void {
            this.$mdStepper('linkStepper').next();
        }

        public back(): void {
            this.$mdStepper('linkStepper').back();
        }

        private shouldUseDeviceIdentifier(protocol: string): boolean {
            return ['BACNET', 'MODBUS', 'BACNET IP'].indexOf(protocol) > -1;
        }

        private linkParentChild(parent: FromTo, child: FromTo) {
            this.linkRequest.parent = parseInt(parent.device.id);
            this.linkRequest.child = parseInt(child.device.id);
            this.linkRequest.inputId = this.shouldUseDeviceIdentifier(this.linkRequest.protocol)
                ? child.device.fields.deviceIdentifier.value
                : parent.inputId;
            return this.hide({
                createLink: this.formModel.createLink,
                link: this.linkRequest
            });
        }
    }

    export interface Link {
        parent: number;
        child: number;
        inputId: string;
        protocol: string;
    }

    export interface FromTo {
        availablePulseInputs: string[];
        isParent: boolean;
        category: string; // currently METER or NETWORKING
        needsDeviceIdentifier: boolean;
        device: aq.common.models.DeviceElement;
        inputId?: string; // pulse input id, only used if pulse is the protocol
    }

    angular
        .module('deviceManagement')
        .controller('AddDeviceLink', AddDeviceLink);
}
