var aq;
(function (aq) {
    var energyInsights;
    (function (energyInsights) {
        var EnergyStarInsightService = /** @class */ (function (_super) {
            __extends(EnergyStarInsightService, _super);
            /* @ngInject */
            function EnergyStarInsightService(DataStore, Restangular, $translate) {
                var _this = _super.call(this) || this;
                _this.DataStore = DataStore;
                _this.Restangular = Restangular;
                _this.$translate = $translate;
                return _this;
            }
            EnergyStarInsightService.prototype.getSimpleTargetModel = function (building, measure, periodSearch) {
                var targetModel = new energyInsights.SimpleTargetModel();
                targetModel.title = this.$translate.instant('insight.ENERGY STAR<sup>&reg;</sup> Score');
                var score = building.energyStarScore;
                if (score && score.lastUpdated) {
                    var date = moment(score.lastUpdated, 'YYYY-MM-DD HH:mm:ss').format('MMMM YYYY');
                    targetModel.when = this.$translate.instant('insight.when.Energy Star') + " " + date;
                    targetModel.whenTooltip = this.$translate.instant('insight.when_tooltip.Energy Star');
                }
                return targetModel;
            };
            EnergyStarInsightService.prototype.getTargetModel = function (building, measure, timePeriod, account, name) {
                var _this = this;
                return this.DataStore.getList(this.Restangular.one('accounts', account.id).one('buildings', building.id).one('Targets'), 'queryTargets', {
                    startDate: moment().startOf('month').format(),
                    endDate: moment().endOf('month').format(),
                    targetType: 'ENERGY_STAR'
                }).then(function (targets) {
                    return _this.calculateTargetModel(building, targets, name);
                });
            };
            EnergyStarInsightService.prototype.getGraphModel = function (building, measure, timePeriod) {
                throw new TypeError('This is a stub method');
            };
            EnergyStarInsightService.prototype.getTableModel = function (building, measure, timePeriod) {
                throw new TypeError('This is a stub method');
            };
            EnergyStarInsightService.prototype.formatTableModel = function (values, targets, periodSearch, building) {
                throw new TypeError('This is a stub method');
            };
            EnergyStarInsightService.prototype.getTarget = function (targets) {
                if (targets && targets.length > 0) {
                    return targets[0].value;
                }
                else {
                    return null;
                }
            };
            EnergyStarInsightService.prototype.getStatus = function (answer, target, percentage, percTime) {
                if (!answer || isNaN(parseInt(answer)) || !target) {
                    return 'invalid';
                }
                else {
                    var diff_1 = answer - target;
                    if (diff_1 >= 0) {
                        return 'under';
                    }
                    else if (diff_1 >= -5) {
                        return 'warn';
                    }
                    else {
                        return 'over';
                    }
                }
            };
            EnergyStarInsightService.prototype.calculateTargetModel = function (building, targets, name) {
                // Only filling out the items needed for our dashboard widget
                var targetModel = new energyInsights.TargetModel();
                var score = building.energyStarScore;
                var target = this.getTarget(targets);
                if (score) {
                    if (score.lastUpdated) {
                        var date = moment(score.lastUpdated, 'YYYY-MM-DD HH:mm:ss').format('MMMM YYYY');
                        targetModel.when = this.$translate.instant('insight.when.Energy Star') + " " + date;
                        targetModel.whenTooltip = this.$translate.instant('insight.when_tooltip.Energy Star');
                    }
                    targetModel.color = this.getColor(score.score, target, null, null);
                    targetModel.formattedAnswer = "" + score.score;
                    targetModel.icon = this.getIcon(score.score, target, null, null);
                }
                else {
                    targetModel.color = this.getColor(null, target, null, null);
                    targetModel.formattedAnswer = this.$translate.instant('insight.answer.No score');
                }
                targetModel.title = name;
                targetModel.showDonut = false;
                if (target) {
                    targetModel.showTarget = true;
                    targetModel.formattedTarget = this.$translate.instant('insight.target.Target') + " " + target;
                }
                targetModel.iconColor = '#20BBED'; // @energyStarBlue
                targetModel.widgetIcon = 'aq-icons-energy-star--glyph';
                return targetModel;
            };
            return EnergyStarInsightService;
        }(energyInsights.InsightService));
        energyInsights.EnergyStarInsightService = EnergyStarInsightService;
        angular.module('energyInsights').service('EnergyStarInsightService', EnergyStarInsightService);
    })(energyInsights = aq.energyInsights || (aq.energyInsights = {}));
})(aq || (aq = {}));
