namespace aq.propertySettings {
    export class AlertActionsComponentCtrl extends AbstractAlertComponent {
        public alert: any;
        public users: aq.common.models.User[];
        public isReadonly: boolean;
        public usersMissingPhoneNumber: aq.common.models.User[];
        public usersMissingPhoneNumberDisplayString: string;

        /* @ngInject */
        constructor(
            private Auth: aq.services.Auth,
        ) {
            super();
        }

        public $onInit(): void {
            this.validatePhoneNumbers();
        }

        public queryUsers(criteria): aq.common.models.User[] {
            return _.filter(this.users, (user: aq.common.models.User) => {
                return user.fullName.toLowerCase().includes(criteria.toLowerCase()) && !_.includes(this.alert.selectedUsers, user);
            });
        }

        public validatePhoneNumbers(): any {
            if (this.alert.sendTextMessage) {
                this.usersMissingPhoneNumber = this.alert.selectedUsers.filter((user: aq.common.models.User) => user.phoneNumber === null || user.phoneNumber === "" );
                if (this.usersMissingPhoneNumber.length > 0) {
                    const userNames = this.usersMissingPhoneNumber.map((user: aq.common.models.User) => {
                        return user.fullName;
                    });
                    this.usersMissingPhoneNumberDisplayString = this.toSentence(userNames);
                }
            }
        }

        public toSentence(arr): string {
            return arr.slice(0, -2).join(', ')
            + (arr.slice(0, -2).length ? ', ' : '')
            + arr.slice(-2).join(' and ');
        }
    }
    angular.module('properties')
        .component('alertActions', {
            templateUrl: 'app/properties/alerts/directives/alertActions.html',
            bindings: {
                users: '<',
                alert: '<',
                isReadonly: '<?'
            },
            controller: AlertActionsComponentCtrl
        });
}
