namespace aq.propertySettings {
    export class AlertTimeFrameComponentCtrl extends AbstractAlertComponent {
        public categoryEvents: any[];
        public calendars: any[];
        public timeFrames: any[];
        public timeOptions: string[];
        public daysOfWeek: string[];
        public onChange: Function;
        public building;
        public isReadonly: boolean;

        /* @ngInject */
        constructor() {
            super();
            this.timeFrames = [{
                label: 'Building Schedule',
                value: 'CALENDAR'
            }, {
                label: 'Anytime',
                value: 'ANYTIME'
            }, {
                label: 'Custom',
                value: 'CUSTOM'
            }];
            this.timeOptions = [];
            for (let i = 0; i < 48; i++) {
                this.timeOptions.push(moment().startOf('day').add(i * 30, 'minutes').format('h:mm A'));
            }
            this.daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
            this.updateCalendarRules();
        }

        public onAlertChange() {
            if (this.onChange) {
                this.onChange({
                    $event: {
                        alert: this.alert
                    }
                });
            }
        }

        public updateCalendarRules() {
            if (!this.alert) {
                return;
            }
            if (this.alert.whenCondition == WhenCondition.CALENDAR) {
                const calendar: any = _.find(this.calendars, {id: this.building.calendar});
                if (calendar) {
                    this.alert.calendar = calendar.id;
                    this.categoryEvents = _.uniqBy(calendar.rules.map((i) => i.category), 'id');
                }
                this.alert.customRule = null;
            } else if (this.alert.whenCondition == WhenCondition.ANYTIME) {
                this.alert.customRule = null;
                this.alert.calendar = null;
                this.alert.category = null;
            } else if (this.alert.whenCondition == WhenCondition.CUSTOM) {
                this.alert.calendar = null;
                this.alert.category = null;
            }
        }
    }

    angular.module('properties')
        .component('alertTimeFrame', {
            templateUrl: 'app/properties/alerts/directives/alertTimeFrame.html',
            bindings: {
                building: '<',
                form: '=',
                alert: '<',
                calendars: '<',
                onChange: '&',
                isReadonly: '<?'
            },
            controller: AlertTimeFrameComponentCtrl
        });
}
