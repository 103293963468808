angular.module('aq.ui').directive('droppable', function () {
    return {
        restrict: 'A',
        link: ($scope, $element, $attrs) => {
            const options: any = {
                hoverClass: 'ui-state-active',
                disabled: $attrs.disabled,
                greedy: true,
                tolerance: 'pointer',
                drop(event, ui) {
                    options.onDrop({
                        draggedIndex: angular.element(ui.draggable).data('index'),
                        draggedContainer: angular.element(ui.draggable).parent().attr('id'),
                        droppedOnIndex: angular.element(this).data('index'),
                        droppedContainer: angular.element(this).parent().attr('id')
                    });
                }
            },
            optionsOverriden = $scope.$eval($attrs.droppable);
            angular.extend(options, optionsOverriden);
            $element.droppable(options);
        }
    };
});
