var aq;
(function (aq) {
    var propertySettings;
    (function (propertySettings) {
        var AlertBulkCtrl = /** @class */ (function (_super) {
            __extends(AlertBulkCtrl, _super);
            /* @ngInject */
            function AlertBulkCtrl($scope, $mdDialog, building, timeout, active, users, allAlerts, currentUser, selectedUsers, DataStore, Restangular, Messages, $q, notificationSenders, loading) {
                var _this = _super.call(this, $scope, $mdDialog) || this;
                _this.$scope = $scope;
                _this.$mdDialog = $mdDialog;
                _this.building = building;
                _this.timeout = timeout;
                _this.active = active;
                _this.users = users;
                _this.allAlerts = allAlerts;
                _this.currentUser = currentUser;
                _this.selectedUsers = selectedUsers;
                _this.DataStore = DataStore;
                _this.Restangular = Restangular;
                _this.Messages = Messages;
                _this.$q = $q;
                _this.notificationSenders = notificationSenders;
                _this.loading = loading;
                _this.aqUserRegex = /\@aquicore/igm;
                _this.$scope.building = building;
                _this.$scope.active = active;
                _this.$scope.timeout = timeout;
                _this.$scope.sendTextMessage = false;
                _this.$scope.sendEmail = false;
                _this.$scope.users = users;
                _this.$scope.selectedUsers = [];
                _this.$scope.removedUsers = [];
                _this.$scope.allAlerts = allAlerts;
                _this.$scope.currentUser = currentUser;
                return _this;
            }
            AlertBulkCtrl.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            AlertBulkCtrl.prototype.updateAll = function () {
                var _this = this;
                this.loading.start();
                var promises = [];
                _.each(this.$scope.allAlerts, function (alert) {
                    _this.prepareAlertForRequest(alert);
                    promises.push(_this.DataStore.update(alert, { inflate: 'currentState,receivers' }));
                });
                delete this.$scope.selectedUsers;
                this.$q.all(promises).then(function (alerts) {
                    _.each(alerts, function (alert) {
                        _this.Restangular.restangularizeElement(_this.$scope.building, alert, 'recoveryNotifications');
                        alert.fromServer = true;
                    });
                    _this.$scope.allAlerts = alerts;
                    _this.loading.stop();
                    _this.Messages.info('All Offline Alerts Updated');
                    _this.$mdDialog.hide(_this.$scope.allAlerts);
                }, function (error) {
                    _this.loading.stop();
                    _this.Messages.error('Error During Bulk Update');
                    _this.$mdDialog.cancel();
                });
            };
            AlertBulkCtrl.prototype.prepareAlertForRequest = function (alert) {
                // only update timeout and active if they have been changed
                alert.receivers = this.getUserList(alert.receivers);
                alert.currentState.timeout = this.$scope.enableTimeout ? this.$scope.timeout : alert.currentState.timeout;
                alert.active = this.$scope.enableActive ? this.$scope.active : alert.active;
                alert.fromServer = true;
                var notificationType = 'GEM_METER_DOWN';
                var smsNotification = _.find(this.notificationSenders, { name: 'SMS', notificationType: notificationType });
                var mailNotification = _.find(this.notificationSenders, { name: 'Mail', notificationType: notificationType });
                if (this.$scope.enableActions && this.$scope.sendTextMessage && smsNotification && !_.includes(alert.senders, smsNotification.id)) {
                    alert.senders.push(smsNotification.id);
                }
                // @note: find selected user to send notifications to
                var selectedUsers = [];
                _.each(this.$scope.selectedUsers, function (user) {
                    selectedUsers.push({
                        user: user.id,
                        classType: 'UserReceiver'
                    });
                });
                alert.receivers = selectedUsers;
                // email code, just leave for now
                /* if (alert.sendEmail && mailNotification && !_.includes(alert.senders, mailNotification.id)) {
                    alert.senders.push(mailNotification.id);
                } else if (!alert.sendEmail) {
                    _.remove(alert.senders, s => s === mailNotification.id);
                } */
            };
            AlertBulkCtrl.prototype.getUserList = function (receivers) {
                var addUsers = [];
                var removeUsers = [];
                var existingUsers = receivers;
                if (this.$scope.enableRemoveUser) {
                    removeUsers = _.map(this.$scope.removedUsers, function (user) {
                        return {
                            user: user.id,
                            classType: 'UserReceiver'
                        };
                    });
                }
                if (this.$scope.enableAddUser) {
                    addUsers = _.map(this.$scope.selectedUsers, function (user) {
                        return {
                            user: user.id,
                            classType: 'UserReceiver'
                        };
                    });
                }
                var removeAll = _.differenceBy(existingUsers, removeUsers, 'user');
                return _.unionBy(removeAll, addUsers, 'user');
            };
            AlertBulkCtrl.prototype.queryUsers = function (criteria) {
                var _this = this;
                return this.users.filter(function (user) {
                    var match = user.fullName.toLowerCase().indexOf(criteria.toLowerCase()) > -1 && !_.includes(_this.$scope.selectedUsers, user);
                    var aqUser = user.email.match(_this.aqUserRegex) != null;
                    var currentUserIsAq = _this.$scope.currentUser.email.match(_this.aqUserRegex) !== null;
                    if (currentUserIsAq && match) {
                        return true;
                    }
                    if (!currentUserIsAq && match && !aqUser) {
                        return true;
                    }
                    return false;
                });
            };
            return AlertBulkCtrl;
        }(aq.common.Controllers.ModalCtrl));
        propertySettings.AlertBulkCtrl = AlertBulkCtrl;
        angular.module('properties').controller('AlertBulkCtrl', aq.propertySettings.AlertBulkCtrl);
    })(propertySettings = aq.propertySettings || (aq.propertySettings = {}));
})(aq || (aq = {}));
