namespace aq.DeviceManagement {
    export class CommissioningStatusComponent {
        static STATUS_INTERVAL = 5000;
        public workflowStatus: aq.common.models.WorkflowStatus;
        public device: aq.common.models.DeviceElement;
        public building: aq.common.models.Building;
        public isCommissioned: boolean;
        public canOverride: boolean;
        public canDecommission: boolean;
        public progressStyle: string;
        public changes: number;
        private updateStatusInterval: ng.IPromise<any>;
        /* @ngInject */
        constructor(
                    private Messages: aq.services.Messages,
                    private $location: ng.ILocationService,
                    private $mdDialog: ng.material.IDialogService,
                    private $interval: ng.IIntervalService,
                    private $scope: ng.IScope,
                    private Auth: aq.services.Auth,
                    private UserService) {
        }

        $onInit() {
            this.updateStatus();
            this.updateStatusInterval = this.$interval(() => this.updateStatus(), CommissioningStatusComponent.STATUS_INTERVAL);
            this.canDecommission = this.UserService.currentUser.userType === 'ADMINISTRATOR';
            this.canOverride = this.Auth.check({access: 'FULL_ACCESS'});
        }

        $onDestroy() {
            this.$interval.cancel(this.updateStatusInterval);
        }

        $onChanges(changesObj) {
            this.updateStatus();
        }

        public handleStepAction(step: aq.common.models.WorkflowStep) {
            if (step.actions && step.actions.length > 0) {
                const action = step.actions[0];
                if (action.type === 'HREF') {
                    this.$location.url(action.value);
                    const currentNavItem = this.getTabName(action.value);
                    this.$scope.$emit('DEVICE_UPDATED', { currentNavItem } );
                }
            }
        }

        public showStepHelp($event, step: aq.common.models.WorkflowStep) {
            this.$mdDialog.show(
                this.$mdDialog.alert()
                  .clickOutsideToClose(true)
                  .title(step.name)
                  .htmlContent(step.description)
                  .ariaLabel(`${step.name} description`)
                  .ok('Got it!')
                  .targetEvent($event));
        }

        complete(event) {

            const confirm = this.$mdDialog
                .confirm()
                .title(`Confirm Commissioning Override`)
                .htmlContent(`By manually overriding the automatic commissioning steps for this device,
                    you are certifying that the device:<br /><br />
                    • Has been properly installed<br />
                    • Is reporting accurate data to the Aquicore platform`)
                .targetEvent(event)
                .ok('Confirm')
                .cancel('Cancel');

            return this.$mdDialog
                .show(confirm)
                .then(() => {
                    this.device.customPOST(null, 'commissioning/complete').then((commissionedDevice: aq.common.models.Device) => {
                        this.device.commissioned = commissionedDevice.commissioned;
                        this.device.commissioningDate = commissionedDevice.commissioningDate;
                        this.onDeviceChange(this.device);
                        this.updateStatus();
                    });
                })
                .then((commissionedDevice) => {
                    this.Messages.success('Successfully Commissioned Device');
                })
                .catch((err) => {
                    if (err) {
                        this.Messages.error('Error commissioning device, please try again later');
                        return err;
                    }
                });
        }

        uncomplete(event) {

            const confirm = this.$mdDialog
                .confirm()
                .title(`Confirm Decommissioning`)
                .htmlContent(`By decommissioning this device, you are removing the certification that this device has been installed properly
                and is reporting accurate data to the Aquicore platform.
                <br /><br />
                You can re-commission the device manually, or by completing the automated commissioning steps.`)
                .targetEvent(event)
                .ok('Confirm')
                .cancel('Cancel');

            return this.$mdDialog
                .show(confirm)
                .then(() => {
                    this.device.customPOST(null, 'commissioning/uncomplete').then((uncommissionedDevice: aq.common.models.Device) => {
                        this.device.commissioned = uncommissionedDevice.commissioned;
                        this.device.commissioningDate = uncommissionedDevice.commissioningDate;
                        this.onDeviceChange(this.device);
                        this.updateStatus();
                    });
                })
                .then((commissionedDevice) => {
                    this.Messages.success('Successfully Commissioned Device');
                })
                .catch((err) => {
                    if (err) {
                        this.Messages.error('Error commissioning device, please try again later');
                        return err;
                    }
                });
        }

        public onDeviceChange(event) {
            this.$scope.$emit('DEVICE_UPDATED', event.device);
        }

        public getProgressValue() {
            if (this.workflowStatus && this.device) {
                if (this.device.commissioned) {
                    return 100;
                }
                return (this.workflowStatus.numStepsCompleted / this.workflowStatus.totalSteps) * 100;
            }
        }

        private updateStatus() {
            if (this.device.deviceCategory === 'METER') {
                this.device.customGET('commissioning/status', {ts: moment().valueOf()}).then((result) => {
                    if (result) {
                        this.workflowStatus = result;
                        this.device.commissioned = this.device.commissioned ? this.device.commissioned : result.commissioned;
                        this.device.commissioningDate = this.device.commissioningDate ? this.device.commissioningDate : result.commissioningDate;
                        this.progressStyle = this.device.commissioned ? 'md-accent' : 'md-primary';
                        this.workflowStatus.steps.forEach((step) => {
                            step.complete = step.status === 'COMPLETE' || this.device.commissioned;
                        });
                    }
                });
            }
        }

        private getTabName(value: string): string {
            const splitValue: string[] = value.split('/');
            return splitValue[splitValue.length - 1];
        }

    }

    angular
        .module('deviceManagement')
        .component('commissioningStatus', {
            controller: CommissioningStatusComponent,
            controllerAs: 'vm',
            templateUrl: 'app/deviceManagement/device/components/commissioningStatus.html',
            bindings: {
                device: '<',
                changes: '<',
                building: '<'
            }
        });

}
