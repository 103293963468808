angular
    .module('aq.forms', [])
    .directive('noDirty', function () {
        return {
            require: 'ngModel',
            link(scope, element, attrs, ngModelCtrl: ng.INgModelController) {
                // prevent input from making form dirty
                ngModelCtrl.$setDirty = angular.noop;
            }
        };
    });