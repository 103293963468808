namespace aq.tenantbilling {
    export class AddEditTenantGroupCtrl extends aq.common.Controllers.ModalCtrl {

        /* @ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            public tenantGroup,
            public tenantGroups,
            public tenants,
            private Auth,
            private TenantBillingSetupService: TenantBillingSetupService,
        ) {
            super({}, $mdDialog);
        }
        public cancel(data?): void {
            this.$mdDialog.cancel();
        }
        public hide(data): void {
            this.$mdDialog.hide(data);
        }
        public saveTenantGroup() {
            let action;
            if (this.tenantGroup.id) {
                action = this.TenantBillingSetupService.updateTenantGroup(this.tenantGroup);
            } else {
                action = this.TenantBillingSetupService.createTenantGroup(this.tenantGroup);
            }
            action.then((result) => {
                this.hide(result);
            });
        }
    }
    angular.module('tenantBilling').controller('AddEditTenantGroupCtrl', AddEditTenantGroupCtrl);
}
