var aq;
(function (aq) {
    var services;
    (function (services) {
        var DashboardWeatherForecast = /** @class */ (function () {
            /* @ngInject */
            function DashboardWeatherForecast($http, resolver, AQ_API_URL, AuthToken, $location, UserService) {
                this.$http = $http;
                this.resolver = resolver;
                this.AQ_API_URL = AQ_API_URL;
                this.AuthToken = AuthToken;
                this.$location = $location;
                this.UserService = UserService;
            }
            DashboardWeatherForecast.prototype.getForecast = function (params) {
                return __awaiter(this, void 0, void 0, function () {
                    var error_1;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.UserService.getUser()];
                            case 1:
                                _a.sent();
                                return [2 /*return*/, this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                                        var response, data, currently, result;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, this.$http({
                                                        method: 'GET',
                                                        headers: this.getHeaders(),
                                                        url: this.AQ_API_URL + "weather/v1",
                                                        params: params
                                                    })];
                                                case 1:
                                                    response = _a.sent();
                                                    data = response.data.days
                                                        .map(function (day) { return ({
                                                        humidity: Math.round(day.humidity) / 100,
                                                        icon: day.icon,
                                                        temperatureMin: day.tempmin,
                                                        temperatureMax: day.tempmax,
                                                        time: day.datetimeEpoch,
                                                    }); });
                                                    if (response.data.currentConditions) {
                                                        currently = {
                                                            pressure: response.data.currentConditions.pressure,
                                                            icon: response.data.currentConditions.icon,
                                                            summary: response.data.currentConditions.conditions,
                                                            humidity: Math.round(response.data.currentConditions.humidity) / 100,
                                                            temperature: response.data.currentConditions.temp,
                                                            windBearing: response.data.currentConditions.winddir,
                                                            windSpeed: response.data.currentConditions.windspeed,
                                                        };
                                                    }
                                                    result = {
                                                        currently: currently, daily: { data: data }
                                                    };
                                                    return [2 /*return*/, result];
                                            }
                                        });
                                    }); })];
                            case 2:
                                error_1 = _a.sent();
                                throw error_1;
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            DashboardWeatherForecast.prototype.getHeaders = function () {
                var auth = this.AuthToken ? this.AuthToken : this.$location.search().authToken;
                return {
                    'Content-Type': 'application/json',
                    Authorization: "Bearer " + auth,
                };
            };
            return DashboardWeatherForecast;
        }());
        services.DashboardWeatherForecast = DashboardWeatherForecast;
        angular.module('aq.services').service('DashboardWeatherForecast', DashboardWeatherForecast);
    })(services = aq.services || (aq.services = {}));
})(aq || (aq = {}));
