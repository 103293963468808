namespace aq.energyInsights {
    import Building = aq.common.models.Building;
    import HighchartsBarChartSeriesOptions = __Highcharts.BarChartSeriesOptions;
    import TargetItem = aq.propertySettings.TargetItem;

    export class ConsumptionService extends InsightService {

        /* @ngInject */
        constructor(
            private EnergyInsightsDataService: aq.energyInsights.service.EnergyInsightsDataService,
            private $q,
            private $filter,
            private Restangular,
            private $translate
        ) {
            super();
        }

        public getSimpleTargetModel(building: aq.common.models.Building, measure: string, periodSearch: PeriodSearch): SimpleTargetModel {
            let targetModel = new SimpleTargetModel();
            targetModel.title = this.$translate.instant('insight.Consumption (Normalized)');
            targetModel.when = this.getWhen(periodSearch);
            return targetModel;
        }

        public getKwHMetric(account) {
            return this.Restangular.one('accounts', account.id).customGET('queryRealUnits').then(function (allUnits) {
                return _.find(allUnits, { unit: 'kWh' });
            });
        }

        public getTargetModel(building: aq.common.models.Building, measure: string, periodSearch: PeriodSearch, account: aq.common.models.Account, name: string): ng.IPromise<TargetModel> {
            return this.getTableModel(building, measure, periodSearch, account, name).then((tableModel) => {
                return this.calculateTargetModel(building, tableModel, periodSearch, name);
            });
        }

        public getGraphModel(building: aq.common.models.Building, measure: string, periodSearch: PeriodSearch, account: aq.common.models.Account): ng.IPromise<GraphModel> {
            return this.getKwHMetric(account)
                .then((metric) => {
                    return this.getTableModel(building, measure, periodSearch, account, name).then((tableData) => {
                        return {
                            graph: this.buildChartConfig(tableData, metric, periodSearch, building)
                        };
                    });
                });
        }

        public getTableModel(building: Building, measure: string, periodSearch: PeriodSearch, account: aq.common.models.Account, name: string): ng.IPromise<TableModel> {
            return this.getKwHMetric(account)
                .then((metric) => {
                    return this.$q.all([
                        this.EnergyInsightsDataService
                            .getEnergy(building, periodSearch.start, periodSearch.end, metric, periodSearch.interval.value, account),
                        this.EnergyInsightsDataService
                            .getTargetConsumption(building, periodSearch.start, periodSearch.end, metric, periodSearch.interval, account)
                    ]).then((results) => {
                        const energy = results[0];
                        const targetConsumption = results[1];
                        return this.transformTableData(energy, targetConsumption, periodSearch, building);
                    });
                });
        }

        // just to please contract
        public formatTableModel() {
            throw new TypeError('This is a stub method');
        }

        public transformTableData(actualsData: any, targets: TargetItem[], periodSearch, building): TableModel {
            const tableModel = new TableModel();
            const rows = [];
            const dateFormat = periodSearch.interval.value === '1mon' ? 'MMM YYYY' : 'dd MMM Do';
            for (let i = 0; i < actualsData.length; i++) {
                const value = actualsData[i].value;
                const target = targets[i] ? targets[i].value : null;
                const percent = this.getPercent(value, target);
                rows.push({
                    timestamp: actualsData[i].timestamp,
                    formattedDate: moment(actualsData[i].timestamp).tz(building.timeZoneId).format(dateFormat),
                    value,
                    target,
                    status: this.getStatus(value, target, percent, 100),
                    percent: this.getPercent(value, target),
                    isMissingData: actualsData[i].isMissingData ? true : false
                });
            }
            tableModel.rows = rows;
            this.buildTableTotal(tableModel);
            return tableModel;
        }

        public calculateTargetModel(building, tableModel: TableModel, periodSearch, name: string): ng.IPromise<TargetModel> {
            let today = moment().tz(building.timeZoneId).format('MMM Do');
            let numberOfDaysInPeriodSoFar = moment().tz(building.timeZoneId).diff(periodSearch.start, 'days');
            let numberOfDaysInPeriod = periodSearch.end.diff(periodSearch.start, 'days');
            let percOfTime = numberOfDaysInPeriodSoFar < numberOfDaysInPeriod
                ? this.getPercent(numberOfDaysInPeriodSoFar, numberOfDaysInPeriod) : 100;
            let targetModel: TargetModel = new TargetModel();
            targetModel.question = `${this.$translate.instant('insight.question.Consumption')} ${building.name}?`;
            targetModel.title = name;

            let noTargetRows = _.filter(tableModel.rows, (row: any) => {
                return row.target === null;
            });
            if (noTargetRows.length === tableModel.rows.length) {
                targetModel.showTarget = false;
                targetModel.showDonut = false;
                targetModel.target = null;
            } else {
                targetModel.showTarget = true;
                targetModel.showDonut = true;
                targetModel.target = this.getTarget(tableModel, building, periodSearch);
            }

            targetModel.formattedTarget = `${this.$translate.instant('insight.target.Target')} ${this.$filter('number')(targetModel.target, '0,0')} kWh`;

            targetModel.answer = this.getAnswer(tableModel);
            let percent = this.getPercent(targetModel.answer, targetModel.target);
            targetModel.formattedAnswer = `${this.$filter('number')(targetModel.answer, '0,0')} kWh`;
            targetModel.percentage = this.getPercent(targetModel.answer, targetModel.target);
            targetModel.color = this.getColor(targetModel.answer, targetModel.target, targetModel.percentage, percOfTime);
            targetModel.iconColor = targetModel.color;
            targetModel.tooltip = this.getTooltip(targetModel, today, percOfTime);
            targetModel.when = this.getWhen(periodSearch);
            targetModel.icon = this.getIcon(targetModel.answer, targetModel.target, percent, percOfTime);
            targetModel.timeElapsedPercentage = percOfTime;
            targetModel.timeElapsedLabel = today;
            targetModel.buildingId = building.id;
            return this.$q.when(targetModel);
        }

        getTooltip(targetModel, today, percOfTime) {
            if (targetModel.showTarget) {
                if (percOfTime == 100) {
                    return `${this.$translate.instant('insight.when_tooltip.Actual Consumption Was')} <span style="color:${targetModel.color}">${targetModel.percentage}%` +
                        `</span> ${this.$translate.instant('insight.when_tooltip.Of Target')}`;
                } else {
                    return `${this.$translate.instant('insight.when_tooltip.Actual')} <span style="color:${targetModel.color}">${targetModel.percentage}%` +
                        `</span> ${this.$translate.instant('insight.when_tooltip.As Of')} ${today}.`;
                }
            }
        }

        getWhen(periodSearch) {
            if (periodSearch.label && (periodSearch.label.indexOf('Trailing') >= 0)) {
                return `${this.$translate.instant('insight.when.Consumption')} ${periodSearch.label}`;
            } else {
                return this.$translate.instant(`timeperiod.${periodSearch.label}`);
            }
        }

        getAnswer(data: TableModel) {
            return _.sum(_.map(data.rows, (row) => { return row.value; }));
        }

        getTarget(data: TableModel, building, periodSearch) {
            // sum only targets that are for previous or current interval period (no future targets)
            let currentDate = moment().tz(building.timeZoneId);
            return _.sum(_.map(data.rows, (row) => {
                let isFuture = moment(row.timestamp).tz(building.timeZoneId).startOf(periodSearch.interval.label);
                if (currentDate.diff(isFuture) > 0) {
                    return row.target;
                } else return row.target;
            }));
        }

        buildChartConfig(tableModel: TableModel, metric, periodSearch, building): any {
            return {
                lang: {
                    drillUpText: '< Back to previous selection',
                    noData: 'No consumption data to display.'
                },
                noData: {
                    position: { x: 0, y: 0, align: 'center', verticalAlign: 'middle' }
                },
                plotOptions: {
                    series: {
                        stacking: 'normal'
                    },
                    column: {
                        grouping: false,
                        shadow: false,
                        borderWidth: 0
                    }
                },
                chart: {
                    plotBorderWidth: 1
                },
                drilldown: {
                    activeAxisLabelStyle: {
                        textDecoration: 'none',
                        fontWeight: 'normal',
                        color: '#666666'
                    }
                },
                title: '',
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    formatter() {
                        let tooltip = '';
                        let dateFormat = this.points[0].point.drilldown ? 'MMMM YYYY' : 'MMMM DD, YYYY';
                        tooltip += `${moment(this.points[0].point.timestamp).tz(building.timeZoneId).format(dateFormat)}<br>`;
                        let actualTooltip = '';
                        let targetTooltip = '';
                        let missingTooltip = '';
                        let drillInTooltip = '';
                        this.points.forEach(function (point) {
                            if (point.series.name == ConsumptionCtrl.CURRENT_YEAR_NORMALIZED_SERIES_NAME) {
                                targetTooltip += `<br><span style="color:${point.color};" class="insight-tooltip-format">` +
                                    `• </span>${point.series.name}: <span class="insight-tooltip-format">${point.point.tooltip}</span>`;
                                if (point.point.percent) {
                                    targetTooltip += `<span class='charge-percent'>(${point.point.percent}%)</span>`;
                                }
                            } else {
                                actualTooltip += `<span style="color:${point.color};" class="insight-tooltip-format">` +
                                    `• </span>${point.series.name}: <span class="insight-tooltip-format">${point.point.tooltip}</span>`;
                            }
                            missingTooltip += point.point.isMissingData ? '<br><span style="color:red">This building uses data directly <br>from your utility company.</span><br><i>Some data has not been received yet.' : '';
                            drillInTooltip = point.point.drilldown ? '<br><i>Drill in for more information.': '';
                        });
                        tooltip = tooltip + actualTooltip + targetTooltip + missingTooltip + drillInTooltip;
                        return tooltip;
                    },
                    shared: true
                },
                xAxis: {
                    type: 'category',
                    tickLength: 0,
                    labels: {
                        formatter() {
                            return this.value;
                        }
                    }
                },
                yAxis: [{
                    min: 0,
                    title: {
                        text: `Energy Consumption (${metric.unit})`
                    }
                }],
                series: this.getChartSeries(tableModel.rows, metric, periodSearch, building)
            };
        }

        getChartSeries(tableRows, metric, periodSearch, building) {
            let noTargetRows = _.filter(tableRows, (row: any) => {
                return row.target === null;
            });
            if (noTargetRows.length === tableRows.length) {
                return [this.buildCurrentYearValueSeries(tableRows, metric, periodSearch, building)];
            } else {
                const result = [];
                if (periodSearch.interval.label !== 'Day') {
                    result.push(this.buildCurrentYearTargetSeries(tableRows, metric, periodSearch, building));
                }
                result.push(this.buildCurrentYearValueSeries(tableRows, metric, periodSearch, building));
                return result;
            }

        }

        formatTimestampForChart(timestamp: number, periodSearchIntervalValue: string, buildingTimeZoneId: string): string {
            let date = moment(timestamp).tz(buildingTimeZoneId);
            if (periodSearchIntervalValue === '1d') {
                return date.format('MMM DD');
            } else {
                return date.format('MMM');
            }
        }

        convertToDataPoints(dataValues: aq.energyInsights.service.ConsumptionDataValue[], metric, valueField, periodSearch, building, color: string): any[] {
            const drilldown = periodSearch.interval.value === '1mon';
            return dataValues.map((energyValue: aq.energyInsights.service.ConsumptionDataValue) => {
                const name = this.formatTimestampForChart(energyValue.timestamp, periodSearch.interval.value, building.timeZoneId);
                return {
                    y: energyValue[valueField],
                    timestamp: energyValue.timestamp,
                    name,
                    color: energyValue.isMissingData ? this.EnergyInsightsDataService.transparentColor(color) : color,
                    tooltip: `${this.$filter('number')(energyValue[valueField], '0,0')} ${metric.unit}`,
                    percent: energyValue.percent,
                    drilldown,
                    isMissingData: valueField === 'target' ? false : energyValue.isMissingData
                };
            });
        }

        private buildTableTotal(tableData: TableModel): void {
            let totals = {
                actualSummary: 0,
                targetSummary: 0,
                percent: null,
                status: null
            };

            for (const tableDataRow of tableData.rows) {
                totals.actualSummary += tableDataRow ? tableDataRow.value : 0;
                totals.targetSummary += tableDataRow ? tableDataRow.target : 0;
            }
            totals.percent = this.getPercent(totals.actualSummary, totals.targetSummary);
            tableData.stats = totals;
        }

        private buildCurrentYearValueSeries(tableRows: any[], metric, periodSearch, building): HighchartsBarChartSeriesOptions {
            return {
                id: 'currentYearEnergy',
                color: '#7ACE46', // Kept here so the legend on the graph will display the right color. Bar color is overriden by color field on individual point
                name: ConsumptionCtrl.CURRENT_YEAR_ENERGY_SERIES_NAME,
                type: 'column',
                stack: 'actuals',
                pointPadding: 0.3,
                data: this.convertToDataPoints(tableRows, metric, 'value', periodSearch, building, '#7ACE46')
            };
        }

        private buildCurrentYearTargetSeries(tableRows: any[], metric, periodSearch, building): HighchartsBarChartSeriesOptions {
            return {
                id: 'currentYearNormalized',
                name: ConsumptionCtrl.CURRENT_YEAR_NORMALIZED_SERIES_NAME,
                color: '#c8c8c8', // Kept here so the legend on the graph will display the right color. Bar color is overriden by color field on individual point
                type: 'column',
                stack: 'targets',
                pointPadding: 0.1,
                data: this.convertToDataPoints(tableRows, metric, 'target', periodSearch, building, '#c8c8c8')
            };
        }

    }
    angular.module('energyInsights').service('ConsumptionService', ConsumptionService);
}
