angular.module('tenantBilling').filter('formatUsage', function($filter, OptionsService) {
    return function(usage, unit) {
        if (!unit) {
            unit = '';
        }
        if (usage === undefined || usage === null) {
            return '';
        }
        const steamUnit = OptionsService.getUnitLabelByMeasure('STEAM');
        const isNegative = usage < 0;
        const absUsage = Math.abs(usage);
        let formattedUsage = $filter('number')(absUsage, '0,0');
        if (steamUnit && steamUnit.unit === unit) {
            formattedUsage = $filter('number')(absUsage, '0,0.[00]');
        }

        if (isNegative) {
            return `(${formattedUsage} ${unit})`;
        }
        return `${formattedUsage} ${unit}`;
    };
});
