var aq;
(function (aq) {
    var tenantbilling;
    (function (tenantbilling) {
        var BulkUploadMoveInsCtrl = /** @class */ (function (_super) {
            __extends(BulkUploadMoveInsCtrl, _super);
            /* @ngInject */
            function BulkUploadMoveInsCtrl($mdDialog, $mdStepper, Messages, RestangularV3, building, TenantService) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.$mdStepper = $mdStepper;
                _this.Messages = Messages;
                _this.RestangularV3 = RestangularV3;
                _this.building = building;
                _this.TenantService = TenantService;
                _this.step = 1;
                _this.valid = true;
                return _this;
            }
            BulkUploadMoveInsCtrl.prototype.downloadTemplate = function () {
                var csvColumns = [
                    'residentId',
                    'phoneNumber',
                    'email',
                    'residentName',
                    'unit',
                    'sqft',
                    'startDate',
                    'endDate'
                ];
                var csvContent = 'data:text/csv;charset=utf-8,';
                csvContent += csvColumns + "\n";
                var fileName = this.building.name + " - units upload";
                var link = document.createElement('a');
                link.setAttribute('href', encodeURI(csvContent));
                link.setAttribute('download', fileName);
                link.click();
            };
            BulkUploadMoveInsCtrl.prototype.next = function () {
                this.$mdStepper('moveInBulkUploadStepper').goto(this.step++);
            };
            BulkUploadMoveInsCtrl.prototype.upload = function () {
                this.parseCSV();
                this.validateDates(this.result);
                this.fileUploaded = this.valid;
                this.attemptedUpload = true;
                if (!this.valid) {
                    this.uploadData = null;
                }
            };
            BulkUploadMoveInsCtrl.prototype.parseCSV = function () {
                var lines = this.uploadData.split(/\r\n|\n|\r/);
                var result = [];
                var headers = lines[0].split(',');
                lines.shift();
                lines.forEach(function (line) {
                    if (line.trim().length === 0) {
                        return;
                    }
                    var obj = {};
                    var splitLine = line.split(',');
                    for (var j = 0; j < headers.length; j++) {
                        obj[headers[j]] = splitLine[j];
                    }
                    result.push(obj);
                });
                this.result = result;
            };
            BulkUploadMoveInsCtrl.prototype.generatePreview = function () {
                this.generateUnitCreationRequestObjects(this.result);
                this.generateLeaseCreationRequestObjects(this.result);
                this.generateResidentCreationRequestObjects(this.result);
                this.bulkCreate(this.previewResponse, this.leases, this.residents);
            };
            BulkUploadMoveInsCtrl.prototype.generateUnitCreationRequestObjects = function (json) {
                var _this = this;
                this.previewResponse = json.map(function (unit) {
                    if (unit.email === '' || unit.residentName === '' || unit.unit === '' || unit.startDate === '') {
                        _this.$mdDialog.hide();
                        _this.Messages.error('Missing required fields');
                        throw new Error('Missing required fields');
                    }
                    var newUnit = __assign({}, unit, { name: unit.unit, size: unit.sqft, building: _this.building.id, accountNumber: null, primaryContact: null, address: null, city: null, state: null, zipCode: null, country: null, leaseType: null, email: null, phoneNumber: null });
                    return newUnit;
                });
                this.removeDuplicates();
            };
            BulkUploadMoveInsCtrl.prototype.generateLeaseCreationRequestObjects = function (json) {
                this.leases = json.map(function (unit) {
                    var newLease = __assign({}, unit, { leaseIdentifier: null, startDate: unit.startDate, endDate: unit.endDate, active: true, tenant: '', unitName: unit.unit });
                    return newLease;
                });
            };
            BulkUploadMoveInsCtrl.prototype.generateResidentCreationRequestObjects = function (json) {
                this.residents = json.map(function (unit) {
                    var newResident = __assign({}, unit, { name: unit.residentName, startDate: unit.startDate, unitName: unit.unit, residentIdentifier: unit.residentId });
                    return newResident;
                });
            };
            BulkUploadMoveInsCtrl.prototype.validateDates = function (json) {
                var _this = this;
                this.valid = true;
                json.map(function (unit) {
                    var startDate = unit.startDate;
                    var endDate = unit.endDate;
                    _this.checkDate(startDate);
                    _this.checkDate(endDate);
                });
            };
            BulkUploadMoveInsCtrl.prototype.checkDate = function (date) {
                if (date != null && date != "") {
                    if (!moment(date, 'YYYY-MM-DD', true).isValid()) {
                        this.valid = false;
                    }
                }
            };
            BulkUploadMoveInsCtrl.prototype.removeDuplicates = function () {
                var unique = [];
                var uniqueUnits = [];
                this.previewResponse.forEach(function (unit) {
                    if (!uniqueUnits.includes(unit.name)) {
                        uniqueUnits.push(unit.name);
                        unique.push(unit);
                    }
                });
                this.previewResponse = unique;
            };
            BulkUploadMoveInsCtrl.prototype.bulkCreate = function (tenantList, leaseList, residentList) {
                var _this = this;
                return this.TenantService.bulkCreate(tenantList).then(function (units) {
                    var unitsLength = _this.TenantService.getResponseLength(units);
                    _this.TenantService.createLeases(leaseList).then(function (leases) {
                        var leasesLength = _this.TenantService.getResponseLength(leases);
                        _this.TenantService.createResidents(residentList).then(function (residents) {
                            var residentsLength = _this.TenantService.getResponseLength(residents);
                            var successMessage = "Successfully created " + unitsLength + " units, " + leasesLength + " leases, and " + residentsLength + " residents";
                            _this.$mdDialog.hide();
                            _this.Messages.success(successMessage);
                        }).catch(function (error) {
                            _this.$mdDialog.hide();
                            _this.Messages.error('Error creating residents');
                        });
                    }).catch(function (error) {
                        _this.$mdDialog.hide();
                        _this.Messages.error('Error creating leases');
                    });
                }).catch(function (error) {
                    _this.$mdDialog.hide();
                    _this.Messages.error('Error creating units');
                });
            };
            return BulkUploadMoveInsCtrl;
        }(aq.common.Controllers.ModalCtrl));
        tenantbilling.BulkUploadMoveInsCtrl = BulkUploadMoveInsCtrl;
        angular
            .module('tenantBilling')
            .controller('BulkUploadMoveInsCtrl', BulkUploadMoveInsCtrl);
    })(tenantbilling = aq.tenantbilling || (aq.tenantbilling = {}));
})(aq || (aq = {}));
