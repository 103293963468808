angular.module('properties').factory('PropertySettingsOptions', function(Restangular, $q) {
    
    var service: any = {};
    var data: any = {};

    service.descriptions = {
        "BREAKER_PANEL": "Breaker Panel distributes power into various end-uses.",
        "SWITCHGEAR": "Building power is provided by utility company and then sourced through switchgear which distributes this building to a limited set of major disconnect point before being further distributed.",
        "MASTER_ELECTRIC": "Building power is provided by utility company and sourced through a master disconnect.",
        "MASTER_GAS": "Building gas is provided by utility company and sourced through a master building feed.",
        "MASTER_WATER": "Building water is provided by utility company and sourced through a master building feed.",
        "CUSTOM_MEASUREMENT": "A generic metric input or output."
    }

    service.init = function(account) {

        var optionsPromise = Restangular.one('options', 'propertySettingsOptions').get();
        var energyUsesPromise = account.all("EnergyUses").getList({includeVirtual: true});
        var metricsPromise = account.all('accountMetrics').getList();
        var sourceClassesPromise = Restangular.all('SourceClasses').getList();

        var phases = [{value: 1, label: "Single / Split Phase"}, {value: 3, label: "Polyphase"}];
        var relationships = [{id: 1, value: true, name: "Master"}, {id:2, value : true, name: "Child"},{id:3, value: false, name: "Unmapped"}];
        return $q.all([
            optionsPromise,
            energyUsesPromise,
            metricsPromise,
            sourceClassesPromise]).then(function (result) {

            // add label and value property to meter object so we can reuse
            // array of meter object in inplace as enum type
            _.each(result[0].collectorClasses, function (collectorClass) {
                collectorClass.label = collectorClass.displayName;
                collectorClass.value = collectorClass.name;
            })

            result[0].collectorClasses = _.sortBy(result[0].collectorClasses, 'label');

            _.each(result[0].gatewayTypes, function (meterType) {
                meterType.label = meterType.displayName;
                meterType.value = meterType.name;
            })

            result[0].gatewayTypes = _.sortBy(result[0].gatewayTypes, 'label');
            // extend array with shared gateway option
            result[0].gatewayTypes = result[0].gatewayTypes.concat([{
                value: 'EXISTING',
                label: 'Access Existing Gateway'
            }])

            data = {
                voltages: result[0].voltages,
                collectorClasses: result[0].collectorClasses,
                gatewayTypes: result[0].gatewayTypes,
                energyUses: result[1],
                metrics: result[2],
                sourceClasses: result[3],
                phases: phases,
                relationships: relationships
            }

            data.energyUses.push({ id: 0, name: "Child Source" });

            return data;
        })

    }

    service.getVoltagesForPhase = function (phase) {
        data.filteredVoltages = _.filter(data.voltages, function (voltage) {
            return parseInt(voltage.phase, 10) == parseInt(phase, 10);
        })
    }

    service.getPhaseFromVoltage = function(voltageName) {
        if (!voltageName) return;

        var voltage = _.find(data.voltages, { 'value': voltageName });
        var phase = _.find(data.phases, {'value' : voltage.phase});
        return phase;
    }

    service.get = function() {
        return data;
    }

    return service;
});
