namespace aq.utilityBudgets {

    enum Step {
        UPLOAD = 0,
        RESULTS_WITH_ERRORS = 1,
        RESULTS = 2
    }

    export class MassBillUpload extends aq.common.Controllers.ModalCtrl {

        public fileAttached: boolean;
        public fileToUpload: any;
        public isLoading: boolean;
        public currentStep: number;
        public errors: string[];

        /* ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private $mdStepper,
            private RestangularV3: restangular.IService,
            private utilityAccountId: number,
            private Segment: aq.services.SegmentService
        ) {
            super({}, $mdDialog);
            this.init();
        }

        public init() {
            this.currentStep = Step.UPLOAD;
            this.errors = [];
            this.fileToUpload = null;
            this.fileAttached = false;
        }

        public onFileChange(): void {
            if (this.fileToUpload) {
                this.fileAttached = true;
            }
        }

        public uploadFile() {
            this.isLoading = true;

            const fd = new FormData();
            fd.append('bulkUploadFile', this.fileToUpload);

            const params = { accountId: this.utilityAccountId };
            const headers = { 'Content-Type': undefined };

            this.Segment.trackEvent('Utility Accounts:Bulk Bill Upload');

            return this.RestangularV3.all('utility-accounts')
                .withHttpConfig({ transformRequest: angular.identity })
                .customPOST(fd, 'bulk-upload', params, headers)
                .catch(() => {
                    return {
                        errors: ['Error occurred while uploading utility bills, please contact your administrator']
                    };
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }

        public downloadTemplate() {
            const link = document.createElement('a');
            link.href = '/api/v3/utility-accounts/bulk-upload/template';
            link.target = '_blank';
            link.click();
        }

        public next() {
            if (this.currentStep == Step.UPLOAD) {
                this.uploadFile()
                    .then((response) => {
                        if (!response.errors || response.errors.length == 0) {
                            this.currentStep++;
                            this.$mdStepper('bulk-upload-wizard').next();
                        } else {
                            this.errors = response.errors;
                        }
                    });
            } else if (this.currentStep == Step.RESULTS_WITH_ERRORS) {
                this.hide();
                return;
            } else if (this.currentStep == Step.RESULTS) {
                this.hide();
                return;
            }
            this.currentStep++;
            this.$mdStepper('bulk-upload-wizard').next();
        }
        public previous() {
            this.init();
            this.$mdStepper('bulk-upload-wizard').goto(Step.UPLOAD);
        }

        public isDisableNext() {
            switch (this.currentStep) {
                case Step.UPLOAD:
                    return !this.fileAttached;
                case Step.RESULTS_WITH_ERRORS:
                    return this.errors;
                case Step.RESULTS:
                    return this.isLoading;
                default:
                    return false;
            }
        }
        public isShowBack() {
            return this.currentStep == Step.RESULTS_WITH_ERRORS && !this.isLoading;
        }
        public getNextButtonTitle() {
            switch (this.currentStep) {
                case Step.UPLOAD:
                    return 'Next';
                case Step.RESULTS_WITH_ERRORS:
                    return this.isLoading ? 'Next' : 'Finish';
                case Step.RESULTS:
                        return 'Finish';
                default:
                    return 'Next';
            }
        }

    }
    angular.module('aq.utilityBudgets').controller('MassBillUpload', MassBillUpload);
}
