namespace aq.propertySettings {
    export class AlertConditionsComponentCtrl extends AbstractAlertComponent {
        public allUnits: aq.common.models.Unit[];
        public units: {[key: string]: aq.common.models.Unit[]};
        public calendars: any[];
        public alertOnOptions: any[];
        public onChange: Function;
        public building;
        public chartConfig;
        public searchText = '';
        public selectedQueryable;
        public isReadonly: boolean;
        public form;

        /* @ngInject */
        constructor(private Auth: aq.services.Auth) {
            super();
            this.alertOnOptions = [{
                label: 'Building',
                value: 'BUILDING'
            }, {
                label: 'Source',
                value: 'SOURCE'
            }, {
                label: 'Meter',
                value: 'METER'
            }, {
                label: 'Tenant',
                value: 'TENANT'
            }];
            this.updateUnits();
            this.selectedQueryable = this.getQueryable();
        }

        public onAlertChange() {
            if (this.onChange) {
                this.onChange({
                    $event: {
                        alert: this.alert
                    }
                });
            }
        }

        public updateUnits() {
            this.units = this.getUnits();
        }

        public queryCollectionByName(collection, search) {
            if (search) {
                return _.filter(collection, (c: any) => {
                    return c.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
                });
            }
            return collection;
        }

        public onCalculationMethodChange() {
            if (this.alert.calculationMethod === 'PERCENTAGE') {
                this.alert.greaterThan = null;
                this.alert.smallerThan = null;
            } else if (this.alert.calculationMethod === 'ABSOLUTE') {
                this.alert.greaterThanAverage = null;
                this.alert.smallerThanAverage = null;
            } else if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                this.alert.greaterThan = null;
                this.alert.smallerThan = null;
                this.alert.greaterThanAverage = null;
                this.alert.smallerThanAverage = null;
            }
        }

        private getUnits() {
            const queryable = this.getQueryable();
            const unitsByMeasure = {};
            if (queryable) {
                queryable.metrics.forEach((measure) => {
                    const applicableUnits = _.filter(this.allUnits, (unit) => {
                        // TODO open this up to more units
                        return unit.serviceType === measure && unit.field === 'total' &&
                            (unit.unit === 'kW' || unit.unit === 'CCF' || unit.unit === 'GPM' || unit.unit === 'Mlb');
                    });
                    unitsByMeasure[measure] = applicableUnits;
                });

            }
            if(unitsByMeasure["ELECTRICITY"]==undefined && unitsByMeasure["GAS"]==undefined
                && unitsByMeasure["WATER"]==undefined && unitsByMeasure["STEAM"]==undefined){
                const unitsByMeasureDefault = {
                                    "ELECTRICITY":[_.find(this.allUnits,{"apiUnit": "POWER","unit":"kW"})],
                                    "GAS":[_.find(this.allUnits,{"apiUnit": "GAS","unit":"CCF"})],
                                     "WATER":[_.find(this.allUnits,{"apiUnit": "FLOW_RATE","unit":"GPM"})],
                                     "STEAM":[_.find(this.allUnits,{"apiUnit": "STEAM_MASS","unit":"Mlb"})]
                };
                return unitsByMeasureDefault;
            }
            return unitsByMeasure;
        }
    }

    angular.module('properties')
    .component('alertConditions', {
        templateUrl: 'app/properties/alerts/directives/alertConditions.html',
        bindings: {
            building: '<',
            allUnits: '<',
            alert: '<',
            collectors: '<',
            sources: '<',
            tenants: '<',
            calendars: '<',
            buildingDrillin: '<',
            onChange: '&',
            isReadonly: '<?',
            form: '='
        },
        controller: AlertConditionsComponentCtrl
    });
}
