namespace aq.reports {
    export class UtilityProgressBar {
        private config: Config;
        private data: UtilityData;
        private colors = {
            heat: '#FC6F71',
            gas: '#7ACD46',
            water: '#0091f1',
            steam: '#B97DD3',
            electricity: '#F1A81D',
            bg: '#D8D8D8'
        };

        /* @ngInject */
        constructor(
            private $element: ng.IRootElementService,
            private $filter: ng.IFilterService
        ) {}

        getColor() {
            return this.colors[this.config.utility];
        }

        getBarWidth(n) {
            return this.convertPercentToWidth(this.getPercentOfLargestValue(n));
        }

        getPercentOfLargestValue(n: number) {
            const budget = Object.keys(this.data.budget).map(
                key => (key && key !== 'total' ? this.data.budget[key] : 0)
            );
            const spending = Object.keys(this.data.spending).map(
                key => (key && key !== 'total' ? this.data.spending[key] : 0)
            );
            const largestValue = _.max([...budget, ...spending]) || 0;
            if (n === largestValue) {
                return 100;
            }
            return _.clamp(_.percent(n, largestValue), 0, 100);
        }

        public convertPercentToWidth(percent: number) {
            const { width } = this.config;
            return _.percent(percent, 100, width);
        }

        public round(n: number, precision: number) {
            const prec = Math.pow(10, precision);
            return Math.round(n * prec) / prec;
        }

        public format(n: number) {
            const pow = Math.pow, floor = Math.floor, abs = Math.abs, log = Math.log;
            const base = Math.floor(log(abs(n)) / log(1000));
            const suffix = 'kmb'[base - 1];
            return suffix ? this.round( n / pow(1000, base), 2) + suffix : '' + n;
        }

        $onInit() {
            if (_.isNil(this.config)) {
                this.config = {
                    height: 9,
                    width: 135,
                    utility: 'flag'
                };
            }
            this.config = _.defaults(this.config, {
                height: 9,
                width: 135,
                utility: 'flag'
            });

            if (this.data.budget[this.config.utility] > 0 && this.data.spending[this.config.utility] > 0) {
                const bar = d3
                    .select(this.$element[0])
                    .append('svg')
                    .attr('height', this.config.height)
                    .attr('width', 300);

                bar
                    .append('rect')
                    .attr('class', 'budget')
                    .attr('fill', this.colors.bg)
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('height', this.config.height)
                    .attr(
                        'width',
                        this.getBarWidth(this.data.budget[this.config.utility])
                    );

                bar
                    .append('rect')
                    .attr('class', 'spending')
                    .attr('fill', this.getColor())
                    .attr('height', this.config.height / 3)
                    .attr('x', 0)
                    .attr('y', this.config.height / 3)
                    .attr(
                        'width',
                        this.getBarWidth(
                            this.data.spending[this.config.utility]
                        )
                    );

                const actual = this.format(this.data.spending[this.config.utility]);
                const budget = this.format(this.data.budget[this.config.utility]);
                const percentDiff = _.percent(
                    this.data.spending[this.config.utility] || 0,
                    this.data.budget[this.config.utility] || 0
                );

                bar
                    .append('text')
                    .attr('x', () => this.config.width + 10)
                    .attr('y', () => this.config.height / 3)
                    .attr('dy', '.5em')
                    .style('font-size', '.9em')
                    .text(() => `$${actual} of $${budget} (${percentDiff}%)`);
            }
        }
    }

    angular.module('aq.reports').component('utilityProgressBar', {
        templateUrl: 'app/reports/summary/executive/utilityProgressBar/utilityProgressBar.html',
        controller: UtilityProgressBar,
        controllerAs: 'vm',
        bindings: {
            config: '<',
            data: '<'
        }
    });

    interface Config {
        height: number;
        width: number;
        utility: Utility;
    }

    type Utility = 'water' | 'electricity' | 'gas' | 'steam' | 'heat' | 'flag';
    export interface UtilityDataMap {
        [key: string]: UtilityData;
    }

    export type UtilityData = {
        spending: {
            electricity: number;
            water: number;
            gas: number;
            steam: number;
            heat: number;
            total: number;
        };
        budget: {
            electricity: number;
            water: number;
            gas: number;
            steam: number;
            heat: number;
            total: number;
        };
    };
}
