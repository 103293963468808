var aq;
(function (aq) {
    var properties;
    (function (properties) {
        var CreateInstallationProjectModalCtrl = /** @class */ (function (_super) {
            __extends(CreateInstallationProjectModalCtrl, _super);
            /* @ngInject */
            function CreateInstallationProjectModalCtrl($scope, $mdDialog, Messages, building, RestangularV3, users) {
                var _this = _super.call(this, $scope, $mdDialog) || this;
                _this.$scope = $scope;
                _this.$mdDialog = $mdDialog;
                _this.Messages = Messages;
                _this.building = building;
                _this.RestangularV3 = RestangularV3;
                _this.users = users;
                return _this;
            }
            CreateInstallationProjectModalCtrl.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            CreateInstallationProjectModalCtrl.prototype.create = function () {
                var _this = this;
                return this.RestangularV3
                    .all('projects')
                    .customPOST({}, 'createInstallationProject', { buildingId: this.building.id, ownerId: this.owner.id })
                    .then(function (response) {
                    _this.Messages.success('Successfully created installation project.');
                    _this.$mdDialog.hide(response.plain());
                }).catch(function () {
                    _this.Messages.error('Unable to create installation project.');
                });
            };
            return CreateInstallationProjectModalCtrl;
        }(aq.common.Controllers.ModalCtrl));
        properties.CreateInstallationProjectModalCtrl = CreateInstallationProjectModalCtrl;
        angular
            .module('properties')
            .controller('CreateInstallationProjectModalCtrl', CreateInstallationProjectModalCtrl);
    })(properties = aq.properties || (aq.properties = {}));
})(aq || (aq = {}));
