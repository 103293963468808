namespace aq.propertySettings {
    export class TargetConfidenceComponent {
        public confidencePercent: number;

        private enabled: boolean = false;
        private confidenceLevels: ConfidenceLevel[] = [];
        private confidenceLevel: ConfidenceLevel;

        /*@ngInject*/
        constructor(
        ) {
            this.confidenceLevels = [
                {icon: 'star', iconColor: 'green', confidence: 'high', order: 1},
                {icon: 'star_half', iconColor: 'yellow', confidence: 'medium', order: 2},
                {icon: 'star_border', iconColor: 'red', confidence: 'low', order: 3}
            ];
        }

        //
        public $onInit() {
            this.initialize();
        }

        public $onChanges() {
            this.initialize();
        }

        private initialize(): void {
            if (this.confidencePercent !== null) {
                this.enabled = true;
                const confidence = this.getConfidenceCategory(this.confidencePercent);
                this.confidenceLevel = this.confidenceLevels.find(level => level.confidence === confidence);
            } else {
                this.enabled = false;
            }
        }

        private getConfidenceCategory(confidencePercent: number): string {
            if (confidencePercent > 90) {
                return 'high';
            }
            if (confidencePercent > 50 && confidencePercent <= 90) {
                return 'medium';
            }
            if (confidencePercent <= 50) {
                return 'low';
            }
            return 'unknown';
        }

    }

    class ConfidenceLevel {
        icon: string;
        iconColor: string;
        confidence: string;
        order: number;
    }

    angular.module('properties')
        .component('targetConfidence', {
            templateUrl: 'app/properties/targets/targetConfidence.html',
            bindings: {
                confidencePercent: '<'
            },
            controller: TargetConfidenceComponent,
            controllerAs: 'vm'
        });
}
