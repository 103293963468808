angular.module('aq-main').run(function($httpBackend) {
    const regex = /^\/api\/v3\/accounts\/.*\/buildings\/.*\/utilityServices/;
    let idCounter = 1;
    const utilityServices = [{
        rateCalculation: 'MANUAL_ENTRY',
        defaultBlendedRate: 1.120,
        type: 'ELECTRICITY',
        accountDefault: true,
        account: '1234ABC',
        id: idCounter++,
        utilityCompany: 434625, //PEPCO electricity
        sources: [{
            name: 'Main Feed B',
            id: 1
        }],
        collectors: []
    }];
    MockContext('utilityServices', () => {
        $httpBackend.whenGET(regex).respond((method, url, data) => {
            console.log(method, url, data);
            return [200, utilityServices, {}];
        });

        $httpBackend.whenPUT(regex).respond((method, url, data) => {
            console.log(method, url, data);
            data = JSON.parse(data);
            if (data.id) {
                _.remove(utilityServices, { id: data.id });
            }
            else {
                return [400, {message: 'missing id'}, {}];
            }
            utilityServices.push(data);
            return [200, data, {}];
        });

        $httpBackend.whenPOST(regex).respond((method, url, data) => {
            console.log(method, url, data);
            data = JSON.parse(data);
            if (data.id) {
                return [400, { message: 'id should not be provided' }, {}];
            }
            data.id = idCounter++;
            utilityServices.push(data);
            return [200, data, {}];
        });

        $httpBackend.whenDELETE(regex).respond((method, url, data) => {
            console.log(method, url, data);
            let id = url.split('utilityServices/')[1];
            if (id) {
                _.remove(utilityServices, { id: id });
            }
            else {
                return [400, { message: 'missing id' }, {}];
            }
            return [200, {}, {}];
        });
    });
});
