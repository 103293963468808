namespace aq.properties {

    export class HistoricUploadModalCtrl extends aq.common.Controllers.ModalCtrl {

        public csvHistoricData;
        public measure: string;
        public calendarize: boolean = true;
        public measureTypes: aq.utilityBudgets.MeasureType[];
        public previewResponse: aq.propertySettings.HistoricUploadResponse;
        public previewNumberDataPoints: number;
        public fileUploaded: boolean;
        public chartConfigs;
        public chartConfig;
        public chart;
        public loading = false;

        /* @ngAnnotate */
        constructor(
            protected $scope: any,
            protected $mdDialog,
            private $mdStepper,
            private UtilityServiceHelper: aq.utilityBudgets.UtilityServiceHelper,
            private RestangularV3: restangular.IService,
            private building: aq.common.models.Building,
            private $state: ng.ui.IStateService,
            private Messages: aq.services.Messages
        ) {
            super($scope, $mdDialog);
            this.measureTypes = this.UtilityServiceHelper.getMeasureTypes().filter((type) => {
                if (type.value === 'ELECTRICITY') {
                    return true;
                }
            });
            $scope.$on('$destroy', () => {
                if (this.csvHistoricData) {
                    this.csvHistoricData = null;
                }
                if (this.previewResponse) {
                    this.previewResponse = null;
                }
            });
            this.chartConfigs = {};
        }

        upload(){
            this.fileUploaded = true;
        }

        cancel() {
            this.$mdDialog.cancel();
        }

        previousStep() {
            this.$mdStepper('historicUploadStepper').back();
        }

        previewStep() {
            this.loading = true;
            this.previewHistoricUpload().then(() => {
                this.$mdStepper('historicUploadStepper').next();
            }).catch((err) => {
                this.Messages.error('Unable to preview your data.  Double check your CSV format.  If the issue persists please message support');
            }).finally(() => {
                this.loading = false;
            });

        }

        goToDevice(deviceId) {
            this.$state.go('aq.properties.buildings.datacollectors.detail', {buildingId: this.building.id, dataCollectorId: deviceId});
        }

        submitData() {
            this.loading = true;
            const datums = this.mapCSVToRequest();
            return this.RestangularV3.one('buildings', this.building.id).customPOST({
                options: {
                    calendarize: this.calendarize,
                    preview: false,
                    measure: this.measure
                },
                datums
            }, 'historic-upload').then((result) => {
                this.$mdStepper('historicUploadStepper').next();
            }).catch((err) => {
                this.Messages.error('Error submitting data.  Please message support');
            }).finally(() => {
                this.loading = false;
            }) ;
        }

        private previewHistoricUpload() {
            const datums = this.mapCSVToRequest();
            return this.RestangularV3.one('buildings', this.building.id).customPOST({
                options: {
                    calendarize: this.calendarize,
                    preview: true,
                    measure: this.measure
                },
                datums
            }, 'historic-upload').then((result: aq.propertySettings.HistoricUploadResponse) => {
                this.previewResponse = result;
                this.chartConfig = this.buildChartConfig(result);
            });
        }

        private mapCSVToRequest() {
            const lines = this.csvHistoricData.split('\n');
            const headers = lines[0].split(',');
            lines.shift(); // remove headers;
            const datums = _.map(lines, (line) => {
                const cells = line.split(',');
                return {
                    startDate: cells[0],
                    endDate: cells[1],
                    value: cells[2]
                };
            });
            return datums;
        }

        private buildChartConfig(response: aq.propertySettings.HistoricUploadResponse) {
            const series = _.map(response.devices, (device) => {
                return this.buildSeries(device);
            });
            return {
                chart: {
                    seriesBoostThreshold: 1,
                    plotBorderWidth: 1,
                    zoomType: 'x',
                    panning: 'true',
                    panKey: 'shift',
                    events: {

                    }
                },
                title: {
                    text: 'Historic Data Preview'
                },
                tooltip: {
                    xDateFormat: '%B %e, %Y %l:%M %p',
                    shared: true
                },
                xAxis: {
                    type: 'datetime',
                    tickLength: 0,
                    gridLineWidth: 1,
                    events: {
                    }
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: false
                        }
                    }
                },
                plotOptions: {
                    line: {
                        animation: false
                    },
                    series: {
                        turboThreshold: 1
                    }
                },
                navigator: {
                    enabled: false,
                    series: {
                        includeInCSVExport: false
                    }
                },
                scrollbar: {
                    enabled: true,
                    liveRedraw: false
                },
                rangeSelector: {
                    enabled: true,
                    inputEnabled: true,
                    inputDateFormat: '%b %e %Y',
                    inputEditDateFormat: '%b %e %Y',
                    buttons: [
                        { type: 'hour', text: 'Hr', count: 1},
                        { type: 'day', text: 'Day', count: 1},
                        { type: 'week', text: 'Wk', count: 1},
                        { type: 'month', text: 'Mon', count: 1},
                        { type: 'all', text: 'All' }
                    ]
                },
                yAxis: {
                    title: {
                        text: null
                    }
                },
                series
            };
        }

        private buildSeries(device: aq.propertySettings.HistoricUploadDeviceResponse) {
            const data = [];
            for (const datum in device.data) {
                data.push([parseInt(datum), device.data[datum].power]);
            }
            return {
                name: `${device.deviceName} - ${device.pointName}`,
                data
            };
        }
    }
    angular
        .module('properties')
        .controller('HistoricUploadModalCtrl', HistoricUploadModalCtrl);
}
