angular.module('aq-main').run(function($httpBackend) {
    const singlePolicyRegex = /^\/api\/v3\/escalation-policies\/\d{1,}$/;
    const singlePolicyPutRegex = /^\/api\/v3\/escalation-policies\/\d{1,}$/;
    const policyListRegex = /^\/api\/v3\/escalation-policies$/;
    const ruleListRegex = /^\/api\/v3\/escalation-policies\/\d{1,}\/rules/;
    const singleRuleRegex = /^\/api\/v3\/escalation-policies\/\d{1,}\/rules\/\d{1,}$/;
    let idCounter = 1;
    const escalationPolicies = [{

    }];

    MockContext('escalation-policies', () => {
        $httpBackend.whenGET(policyListRegex).respond((method, url, data) => {
            // const queryParams = MockHelpers.getQueryParams(url);
            return [200, escalationPolicies, {}];
        });
        $httpBackend.whenGET(singlePolicyRegex).respond((method, url, data) => {
            const tokens = url.split('/');
            const finalToken = tokens[tokens.length - 1];
            const idToken = finalToken.split('?')[0];
            if (parseInt(idToken)) {
                return [200, _.find(escalationPolicies, {id: parseInt(idToken)}), {}];
            }
            return [200, null, {}];
        });

        $httpBackend.whenPUT(singlePolicyPutRegex).respond((method, url, data) => {
            data = JSON.parse(data);
            if (data.id) {
                _.remove(escalationPolicies, {id: data.id});
            }
            escalationPolicies.push(data);
            return [200, data, {}];
        });

        $httpBackend.whenPOST(policyListRegex).respond((method, url, data) => {
            data = JSON.parse(data);
            data.id = ++idCounter;
            escalationPolicies.push(data);
            return [200, data, {}];
        });

        $httpBackend.whenPOST(ruleListRegex).respond((method, url, data) => {
            data = JSON.parse(data);
            data.id = ++idCounter;
            const escalationPolicyId = parseInt(MockHelpers.getIds(url)[0]);
            const escalationPolicy = _.find(escalationPolicies, {id: escalationPolicyId});
            if (!escalationPolicy.rules) {
                escalationPolicy.rules = [];
            }
            escalationPolicy.rules.push(data);
            return [200, data, {}];
        });

        $httpBackend.whenPUT(singleRuleRegex).respond((method, url, data) => {
            data = JSON.parse(data);
            const escalationPolicyId = parseInt(MockHelpers.getIds(url)[0]);
            const escalationPolicy = _.find(escalationPolicies, {id: escalationPolicyId});
            _.remove(escalationPolicy.rules, {id: data.id});
            escalationPolicy.rules.push(data);
            return [200, data, {}];
        });

        $httpBackend.whenDELETE(singleRuleRegex).respond((method, url, data) => {
            const escalationPolicyId = parseInt(MockHelpers.getIds(url)[0]);
            const ruleId = parseInt(MockHelpers.getIds(url)[1]);
            const escalationPolicy = _.find(escalationPolicies, {id: escalationPolicyId});
            _.remove(escalationPolicy.rules, {id: ruleId});
            return [200, {}, {}];
        });
    });
});
