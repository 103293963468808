'use strict';

angular
.module('aq.admin.configuration.integrationApps')
.controller('IntegrationAppsCtrl', function(Restangular, $scope) {

    Restangular.one('IntegrationApps').customGET('queryServices').then(function(workers) {
        $scope.workers = workers;

        Restangular.one('IntegrationApps').customGET('queryUsage').then(function(usage) {
            $scope.utilization = usage;
        });

        Restangular.one('IntegrationApps').get({admin: true, inflate: 'workers'}).then(function(integrationApps) {
            $scope.integrationApps = integrationApps;

            _.each(integrationApps, function (app) {
                app.workerOptions = _.map(app.workers, function (worker) {
                    return _.findById(worker, worker.workerClassName);
                });
            });

            Restangular.restangularizeCollection(null, $scope.integrationApps, 'IntegrationApps');
        });
    });

    $scope.idSelectedIntegrationApp = null;

    $scope.setSelected = function(idSelectedIntegrationApp) {
        $scope.idSelectedIntegrationApp = idSelectedIntegrationApp;
    };

    $scope.integrationStatusTypes = [
        { value: 'true', label: 'Active'},
        { value: 'false', label: 'Inactive'}

    ]

    $scope.integrationAppTypes = [
        { value: 'INTERNAL', label: 'Internal' },
        { value: 'EXTERNAL', label: 'External' }
    ]

});