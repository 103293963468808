namespace aq.reports {
    export class DailyBuildingCtrl extends BaseBuildingReportCtrl {
        public time: moment.Moment;
        public previousTime: moment.Moment;
        public occupancyData: any;
        public monthIndex: number;
        /* ngInject */
        constructor(
            protected currentDate,
            protected account,
            protected building,
            protected energyStarScore,
            protected functionalities,
            protected OptionsService,
            protected availableMetrics,
            protected meters,
            protected $timeout: ng.ITimeoutService,
            private $q: ng.IQService,
            private ReportApiParams,
            private DataService,
            private MonthlyOccupancyData,
            private DailyCharts
        ) {
            super(
                currentDate,
                account,
                building,
                energyStarScore,
                functionalities,
                OptionsService,
                availableMetrics,
                meters,
                $timeout
            );
            this.time = angular.copy(this.buildingReport.building.currentTime);
            this.time.subtract(1, 'day');
            this.monthIndex = parseInt(this.time.format('M'));
            this.occupancyData = this.MonthlyOccupancyData.getMonthlyOccupancy(building, this.monthIndex);
            // TODO for now we must query temperature separately because /data endpoint doesn't support temperature
            // (it is from virtual meter which is excluded)
            const temperatureParams = new this.ReportApiParams(this.time, null, 'daily');
            const temperaturePromise = building.customGET('temperature', temperatureParams.current());
            const previousTemperaturePromise = building.customGET('temperature', temperatureParams.previous());
            this.previousTime = moment(temperatureParams.previousDegreeDay().start);

            const reportPromises = [];
            _.each(this.buildingReport.serviceReports, (report: BuildingServiceReport) => {
                const config = report.config;
                report.data = {
                    loading: true
                } as any;
                const apiParams = new this.ReportApiParams(this.time, config.measures, 'daily');
                const dayDataPromise = building.customGET('data', apiParams.current());
                const dayPreviousDataPromise = building.customGET('data', apiParams.previous());

                const dayExpectedDataPromise = this.DataService.expectedEnergy(building, apiParams.current().interval,
                    apiParams.current().start, apiParams.current().end);

                const degreeDaysPromise = this.DataService.degreeDays(building,
                    apiParams.currentDegreeDay().interval,
                    apiParams.currentDegreeDay().start,
                    apiParams.currentDegreeDay().end);
                const previousDegreeDaysPromise = this.DataService.degreeDays(building,
                    apiParams.previousDegreeDay().interval,
                    apiParams.previousDegreeDay().start,
                    apiParams.previousDegreeDay().end);

                const reportDefer = this.$q.defer();
                this.$q.all([
                    dayDataPromise,
                    dayPreviousDataPromise,
                    temperaturePromise,
                    previousTemperaturePromise,
                    degreeDaysPromise,
                    previousDegreeDaysPromise,
                    dayExpectedDataPromise
                ]).then((result) => {
                    const dayData = result[0];
                    const previousDayData = result[1];
                    const temperatureData = result[2];
                    const previousTemperatureData = result[3];
                    const dayDegreeDaysData = result[4];
                    const previousdayDegreeDaysData = result[5];
                    const dayExpectedData = result[6];

                    const data = report.data;

                    data.currentHDD = dayDegreeDaysData.hdd.total;
                    data.currentCDD = dayDegreeDaysData.cdd.total;

                    data.previousHDD = previousdayDegreeDaysData.hdd.total;
                    data.previousCDD = previousdayDegreeDaysData.cdd.total;

                    data.usage = _.sum(dayData[config.usageMetric].values);
                    data.demand = this.getDemand(config, dayData[config.demandMetric].values);
                    data.averageTemperature = _.mean(temperatureData.values);

                    data.previousUsage = _.sum(previousDayData[config.usageMetric].values);
                    data.previousDemand = this.getDemand(config, previousDayData[config.demandMetric].values);
                    data.previousAverageTemperature = _.mean(previousTemperatureData.values);

                    data.hasData = this.checkIfHasData(config, dayData) || this.checkIfHasData(config, previousDayData);
                    data.hasExpectedDemandData = !config.isWaterOrGasReport
                        && this.hasNonNullData(dayExpectedData[config.expectedDemandMetric].values);

                    if (!config.isWaterOrGasReport) {
                        data.expectedUsage = this.getExpectedUsage(dayExpectedData[config.expectedDemandMetric].values);
                        data.expectedDemand = this.getDemand(config, dayExpectedData[config.expectedDemandMetric].values);
                    }

                    const dailyCharts = new this.DailyCharts(dayData, previousDayData, dayExpectedData, report.config);
                    if (!config.isWaterOrGasReport) {
                        /* Daily Demand chart data */
                        data.dailyDemand = dailyCharts.getDailyDemandChart();
                        /* Daily Demand chart data expected*/
                        data.dailyDemandExpected = dailyCharts.getDailyDemandChartExpected();
                        /* Variance chart data */
                        data.varianceChart = dailyCharts.getVarianceChart();
                    }

                    /* Water chart data */
                    if (config.isWaterOrGasReport) {
                        data.waterChart = dailyCharts.getWaterChart(temperatureData);
                    }

                    data.loading = false;

                    reportDefer.resolve();
                }).catch(() => {
                    reportDefer.reject();
                });
                reportPromises.push(reportDefer.promise);
            });
            this.$q.all(reportPromises)
                .then(() => {
                    const delay = 3000;
                    
                    this.$timeout(() => {
                        this.notifyDocumentReady();
                    }, delay);
                })
                .catch(() => {
                    this.isDocumentError = true;
                });
        }

        public getServiceType(usageMetric: string) {
            switch (usageMetric) {
                case 'energy':
                    return 'Electricity';
                case 'steam_mass':
                    return 'Steam';
                case 'heat_transfer':
                    return 'Heat';
                case 'gas':
                    return 'Gas';
                case 'water':
                    return 'Water';
                default:
                    return '';
            }
        }

        private checkIfHasData(config: Report, elem) {
            return _.some(config.measures, (metric: string) => elem[metric.toLowerCase()].values.length > 0);
        }


        private hasNonNullData(data) {
            return _.filter(data, null).length !== 0;
        }

        private getExpectedUsage(expectedDemandValues) {
            let i = 0;
            let expectedUsageValue: number = 0;
            while (i < expectedDemandValues.length) {
                const hourValues = _.slice(expectedDemandValues, i, i + 4);
                const average = _.mean(hourValues);
                expectedUsageValue += average;
                i = i + 4;
            }
            // convert watt hour to watt seconds
            return expectedUsageValue * 3600;
        }

        private getDemand(config: Report, values) {
            if (config.isWaterOrGasReport) {
                return _.mean(values);
            } else {
                return _.max(values);
            }
        }
    }
    angular.module('aq.reports').controller('DailyBuildingCtrl', DailyBuildingCtrl);
}
