(Highcharts as any).theme = {
    colors: ['#28ccdd', '#fd7b33', '#7ace46', '#1e59af', '#f2a91d', '#aa3c9b', '#c5e063', '#e51730', '#ba7dd4'],
    chart: {
        plotBorderWidth: 1
    },
    title: {
    },
    legend: {
        backgroundColor: '#FFFFFF',
        shadow: false,
        borderRadius: 2,
        borderWidth: 1,
        itemStyle: { "color": "#333333", cursor: "default", fontSize: "9px", fontWeight: "normal" },
        itemHoverStyle: { cursor: "default" }
    },
    drilldown: {
        series: [],
        drillUpButton: {
            position: {
                align: "left",
                x: 10,
                y: 10
            },
            theme: {
                r: 3,
                fill: "white",
                style: {
                    color: "#0091f1"
                },
                states: {
                    hover: {
                        fill: "#0091f1",
                        style: {
                            color: "#ffffff"
                        }
                    },
                    select: {
                        style: {
                            color: "#ffffff"
                        },
                        fill: "#0091f1"
                    }
                }
            }
        }
    },
    plotOptions: {
        marker: {
            symbol: "circle"
        },
        series: {
            events: {
                legendItemClick: function () {
                    return false;
                }
            }
        }
    },
    xAxis: {
        dateTimeLabelFormats: {
            month: "%b",
            day: "%b %e"
        }
    },
    noData: {
        position: { "x": 0, "y": 0, "align": "center", "verticalAlign": "middle" }
    },
    credits: {
        enabled: false
    },
    lang: {
        thousandsSep: ','
    },
    global: {
        useUTC: true
    }
};

// Apply the theme
Highcharts.setOptions((Highcharts as any).theme);
