namespace aq.deviceManagement {
    export class DeviceDetailReadings {

        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private user,
            private device,
            private collector,
            private manualReadings,
            private building
        ) {

        }

        public onDeviceChange(event) {
            this.$scope.$emit('DEVICE_UPDATED', event.device);
        }
    }

    angular
        .module('deviceManagement')
        .controller('DeviceDetailReadings', DeviceDetailReadings);
}

