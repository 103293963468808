angular.module('tenantBilling').filter('formatQuantity', function($filter, OptionsService) {
    return function(quantity, unit) {
        const steamUnit = OptionsService.getUnitLabelByMeasure('STEAM');
        const currencyUnitSymbol = OptionsService.currencyUnit().symbol;
        const isNegative = quantity < 0;
        const absQuantity = Math.abs(quantity);

        let formattedQuantity = Math.round(absQuantity);
        if (steamUnit.unit === unit || unit == currencyUnitSymbol) {
            formattedQuantity = formattedQuantity ? Math.round(absQuantity * 100) / 100 : 0;
        }

        if (isNegative) {
            return formattedQuantity * -1;
        }
        return formattedQuantity;
    };
});
