namespace aq.services {
    export class AppStorageService {
        /* @ngInject */
        constructor(
            private $localStorage,
            private $state
        ) {

        }

        public getStorage = (app?: string, account?: number) => {
            let stateAppName = 'noApp';
            if (this.$state && this.$state.current && this.$state.current.data && this.$state.current.data.appName) {
                stateAppName = this.$state.current.data.appName.toLowerCase();
            }
            let defaultAccount = 0;
            if (this.$state && this.$state.params && this.$state.params.accountId) {
                defaultAccount = this.$state.params.accountId;
            }
            let appName = app || stateAppName;
            let accountId = account || defaultAccount;

            if (!this.$localStorage[accountId]) {
                this.$localStorage[accountId] = {};
            }
            if (!this.$localStorage[accountId][appName]) {
                this.$localStorage[accountId][appName] = {};
            }

            return this.$localStorage[accountId][appName];
        }

        public setAttr = (attribute: string, value: any, app?: string, account?: number) => {

            let def = this.getStorage(app, account);

            // Delete so we keep the same object reference
            if (def[attribute]) {
                delete def[attribute];
            }

            if (value) {
                def[attribute] = value;
            }
        }
    }

    angular.module('aq.services').service('AppStorageService', AppStorageService);
}