'use strict';

angular.module('aq.admin.productDesign').controller('ComponentsCtrl', function ($scope, $state, Messages, $q) {

    $scope.select = [
        {
            id: 1,
            name: "test 1"
        },
        {
            id: 2,
            name: "test 2"
        },
        {
            id: 3,
            name: "Type"
        }
    ]

    $scope.enums = [
        { value: 'AQ_GATEWAY', label: 'AQ Gateway' },
        { value: 'MASTER_ELECTRIC', label: 'Master Electric' },
        { value: 'BREAKER_PANEL', label: 'Breaker Panel' }
    ]

    $scope.date = moment();
    $scope.minDate = moment().subtract(2, 'days');
    $scope.maxDate = moment().add(2, 'days');


    $scope.selection = {
        interval: '1h'
    }

    $scope.tagString = [{ value: 'tag1' }, { value: 'tag2' }, { value: 'tag3' }];
    $scope.tagsObject = [
        { id: 0, name: 'tag1' },
        { id: 1, name: 'tag2' },
        { id: 2, name: 'tag3' }
    ];

    $scope.inplaceChange = function () {
        var deferred = $q.defer();

        Messages.info('Test inplace model updated');

        deferred.resolve();

        return deferred.promise;
    }


    $scope.removeFromList = function(item) {
        _.withoutInline($scope.select, item);
    }

    $scope.inplaceModel = {
        selected: 1
    }

});
