var aq;
(function (aq) {
    var ui;
    (function (ui) {
        var SeverityIcon = aq.models.alerts.SeverityIcon;
        var LevelSeverity = aq.models.alerts.LevelSeverity;
        var _ActivityStateAlertDisplay = /** @class */ (function () {
            /* @ngInject */
            function _ActivityStateAlertDisplay($scope, $translate, RestangularV3, resolver, activityAlertUtilService) {
                this.$scope = $scope;
                this.$translate = $translate;
                this.RestangularV3 = RestangularV3;
                this.resolver = resolver;
                this.activityAlertUtilService = activityAlertUtilService;
                this.stateUnit = aq.services.Unit.STATE;
            }
            _ActivityStateAlertDisplay.prototype.$onInit = function () {
                var _this = this;
                this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                    var dataPromise, stateAlertPromise, err_1;
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, 3, 4]);
                                dataPromise = this.RestangularV3
                                    .one('alert-data')
                                    .one('issue', this.activity.context.issue)
                                    .get({ noCache: moment().valueOf() });
                                stateAlertPromise = this.RestangularV3
                                    .one('issues', this.activity.context.issue)
                                    .get({ noCache: moment().valueOf() });
                                return [4 /*yield*/, Promise.all([dataPromise, stateAlertPromise])];
                            case 1:
                                _a = _b.sent(), this.data = _a[0], this.stateAlert = _a[1];
                                this.icon = this.setIcon(this.data.currentLevel.severity);
                                this.contextLoadedCallback();
                                return [3 /*break*/, 4];
                            case 2:
                                err_1 = _b.sent();
                                this.noDataCallback();
                                throw err_1;
                            case 3:
                                this.dataLoaded = true;
                                return [7 /*endfinally*/];
                            case 4: return [2 /*return*/];
                        }
                    });
                }); });
            };
            _ActivityStateAlertDisplay.prototype.getBuildingName = function () {
                if (this.building) {
                    return this.building.name;
                }
                return '';
            };
            _ActivityStateAlertDisplay.prototype.getCurrentName = function () {
                if (this.data) {
                    if (!this.data.currentLevel || !this.data.currentLevel.name) {
                        return this.data.alertName;
                    }
                    return this.data.currentLevel.name;
                }
                return '';
            };
            _ActivityStateAlertDisplay.prototype.getLevelName = function (level) {
                if (!level) {
                    return '';
                }
                return '';
            };
            _ActivityStateAlertDisplay.prototype.getLevelDescription = function (level) {
                if (!level) {
                    return '';
                }
                if (level.lowerBoundary != null || level.upperBoundary != null) {
                    var name_1 = level.queryable && level.queryable.name ? level.queryable.name.toLowerCase() + " " : '';
                    var symbol = level.unit && level.unit.symbol && level.unit.symbol != aq.services.Unit.STATE ? level.unit.symbol : '';
                    if (level.lowerBoundary != null && level.upperBoundary != null) {
                        return "(" + name_1 + this.$translate.instant('between') + " " + level.lowerBoundary + symbol + " " + this.$translate.instant('and') + " " + level.upperBoundary + symbol + ")";
                    }
                    if (level.upperBoundary != null) {
                        return "(" + name_1 + this.$translate.instant('less than') + " " + level.upperBoundary + symbol + ")";
                    }
                    if (level.lowerBoundary != null) {
                        return "(" + name_1 + this.$translate.instant('greater than') + " " + level.lowerBoundary + symbol + ")";
                    }
                }
                return '';
            };
            _ActivityStateAlertDisplay.prototype.getValue = function (unit, value) {
                var formattedUnit = unit ? unit.symbol : '';
                var formattedValue = value ? Math.round(value) : '';
                return value ? formattedValue + " " + formattedUnit : null;
            };
            _ActivityStateAlertDisplay.prototype.closeStateAlert = function () {
                this.resolveAndClose = true;
            };
            _ActivityStateAlertDisplay.prototype.getIssueDurationFormatted = function (stateAlert) {
                var issueDuration = null;
                if (stateAlert.issueCreatedOn) {
                    var start = moment(stateAlert.issueCreatedOn).toDate();
                    var end = stateAlert.issueClosedOn ? moment(stateAlert.issueClosedOn).toDate() : moment().toDate();
                    issueDuration = this.activityAlertUtilService.getDurationFormatted(start, end);
                }
                return issueDuration;
            };
            _ActivityStateAlertDisplay.prototype.formatAlertDate = function (date) {
                var momentDate = moment(date);
                return momentDate
                    .isBefore(moment().subtract(1, 'day').valueOf())
                    ? momentDate.format('MMM DD YYYY - h:mm a')
                    : momentDate.format('h:mm a');
            };
            _ActivityStateAlertDisplay.prototype.getQueryableString = function (queryable) {
                if (queryable && queryable.name) {
                    return queryable.name.split(' ').map(_.capitalize).join(' ');
                }
                return 'Whole Building';
            };
            _ActivityStateAlertDisplay.prototype.getWheneverString = function (level) {
                var symbol = level.unit ? level.unit.symbol : '';
                var name = level.unit ? level.unit.name + " " : '';
                var range = '';
                if (level.lowerBoundary && level.upperBoundary) {
                    range = "between " + level.lowerBoundary + symbol + " and " + level.upperBoundary + symbol;
                }
                else if (level.lowerBoundary) {
                    range = "greater than " + level.lowerBoundary + symbol;
                }
                else if (level.upperBoundary) {
                    range = "lesser than " + level.upperBoundary + symbol;
                }
                return range ? "" + name + range : 'Not specified';
            };
            _ActivityStateAlertDisplay.prototype.setIcon = function (status) {
                switch (status) {
                    case LevelSeverity.NORMAL:
                        return { color: 'green', name: SeverityIcon.NORMAL };
                    case LevelSeverity.WARNING:
                        return { color: 'orange', name: SeverityIcon.WARNING };
                    case LevelSeverity.CRITICAL:
                        return { color: 'red', name: SeverityIcon.CRITICAL };
                    case LevelSeverity.UNKNOWN:
                    default:
                        return { color: 'gray', name: SeverityIcon.UNKNOWN };
                }
            };
            _ActivityStateAlertDisplay.prototype.hideResolveAndClose = function () {
                this.resolveAndClose = false;
            };
            return _ActivityStateAlertDisplay;
        }());
        ui.ActivityStateAlertDisplay = {
            controller: _ActivityStateAlertDisplay,
            controllerAs: 'vm',
            templateUrl: 'app/common/components/activities/contexts/stateAlertDisplay.html',
            bindings: {
                activity: '<',
                building: '<',
                issue: '<',
                noDataCallback: '&',
                contextLoadedCallback: '&',
                refreshActivitiesAndCloseDialog: '&'
            }
        };
        angular.module('aq.ui')
            .component('activityStateAlertDisplay', ui.ActivityStateAlertDisplay);
    })(ui = aq.ui || (aq.ui = {}));
})(aq || (aq = {}));
