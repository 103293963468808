var _this = this;
angular
    .module('aq.utilityBudgets')
    .config(function ($stateProvider) {
    $stateProvider
        .state('aq.utilityBudgets.utilityaccounts', {
        url: '/utility-accounts',
        templateUrl: 'app/utilityBudgets/utilityAccounts/utilityAccountsList.html',
        controller: 'UtilityAccountsListCtrl as vm',
        data: {
            hide: false,
            breadcrumb: 'Utility Accounts',
            icon: '.aq-icons-utilities'
        },
        params: {
            timeStamp: null
        },
        resolve: {
            authAccess: function (Auth, waitForAuthToken) {
                return Auth.access;
            },
            utilityCompanies: function (RestangularV3, DataStore, authAccess) {
                return DataStore.getList(RestangularV3.all('utility-companies'));
            },
            utilityAccounts: function (RestangularV3, $stateParams, DataStore, authAccess) {
                return DataStore.getList(RestangularV3.one(''), 'utility-accounts', { buildingId: $stateParams.buildingId });
            },
            mapUtilityAccountBuildingIds: function (utilityAccounts, RestangularV3, DataStore, $q) {
                var result = {};
                var sharedUtilityAccounts = _.filter(utilityAccounts, function (ua) { return ua.shared; });
                var requests = _.map(sharedUtilityAccounts, function (ua) {
                    return RestangularV3
                        .one('utility-accounts', ua.id)
                        .customGET('buildings')
                        .then(function (buildingIds) {
                        return {
                            id: ua.id,
                            buildingIds: buildingIds
                        };
                    });
                });
                return $q.all(requests).then(function (results) {
                    _.each(results, function (r) {
                        result[r.id] = r.buildingIds;
                    });
                    return result;
                });
            },
            buildingId: function ($stateParams) {
                return $stateParams.buildingId;
            },
            buildings: function (RestangularV3, waitForAuthToken) {
                return RestangularV3.all('buildings').getList();
            },
            users: function (RestangularV3, DataStore, account, waitForAuthToken) {
                return DataStore.getList(RestangularV3.one(''), 'users', { accountId: account.id });
            },
            tariffSchedules: function (RestangularV3, utilityAccounts, waitForAuthToken) {
                var utilityCompanyId = utilityAccounts.filter(function (ua) { return ua.utilityCompany; }).map(function (ua) { return String(ua.utilityCompany); });
                return RestangularV3.all('energy-tariff-schedules/by-utility-company').customGET('', { utilityCompanyId: utilityCompanyId });
            },
            utilityServices: function (RestangularV3, buildingId, waitForAuthToken) {
                return RestangularV3.all('utility-services').all('by-utility-account').customGET('', { buildingId: buildingId });
            }
        },
        onEnter: function (Segment) {
            Segment.trackEvent('Budgets:Utility Accounts');
        }
    })
        .state('aq.utilityBudgets.utilityaccounts.utilityAccountServiceBills', {
        url: '/with-bills/:utilityAccountId/service-bills/:serviceId',
        views: {
            'utility-bills': {
                templateUrl: 'app/utilityBudgets/utilityAccounts/utilityAccountListServiceBills.html',
                controller: 'UtilityAccountListServiceBills as vm',
                resolve: {
                    utilityAccountId: function ($stateParams) {
                        return $stateParams['utilityAccountId'];
                    },
                    utilityAccount: function (RestangularV3, utilityAccountId, DataStore, utilityAccounts, waitForAuthToken) {
                        var isValid = _.some(utilityAccounts, function (ua) { return ua.id == utilityAccountId; });
                        if (!isValid) {
                            return null;
                        }
                        return DataStore.get(RestangularV3.one('utility-accounts', utilityAccountId));
                    },
                    tariffSchedules: function (DataStore, RestangularV3, utilityAccount, waitForAuthToken) {
                        if (utilityAccount.utilityCompany) {
                            var utilityCompanyId = String(utilityAccount.utilityCompany);
                            return DataStore.getList(RestangularV3.one(''), 'energy-tariff-schedules', { utilityCompanyId: utilityCompanyId });
                        }
                        return [];
                    },
                    readOnlyForm: function (Auth, waitForAuthToken) {
                        return !Auth.check({ appName: 'Budgets', access: 'EDIT' });
                    },
                    utilityAccountBuildingIds: function (RestangularV3, utilityAccountId, waitForAuthToken) {
                        return RestangularV3.one('utility-accounts', utilityAccountId).customGET('buildings');
                    },
                    utilityService: function (RestangularV3, $stateParams, DataStore, utilityServices, waitForAuthToken) {
                        var utilityAccountId = parseInt($stateParams.utilityAccountId);
                        var serviceId = $stateParams.serviceId;
                        var isValid = _.some(utilityServices[utilityAccountId], function (us) { return us.id == serviceId; });
                        if (!isValid) {
                            return null;
                        }
                        return DataStore.get(RestangularV3.one('utility-services', serviceId), {});
                    },
                    collectors: function (Restangular, accountId, $stateParams, waitForAuthToken) {
                        var params = { utilityMetersOnly: true };
                        return Restangular.one('accounts', accountId)
                            .one('buildings', $stateParams.buildingId)
                            .customGET('queryAllCollectors', params);
                    },
                    utilityServiceBillPeriods: function (RestangularV3, utilityService, DataStore) {
                        if (!utilityService) {
                            return null;
                        }
                        return DataStore.getList(RestangularV3.one('utility-bill-periods'), 'by-utility-service', { serviceId: utilityService.id });
                    },
                    utilityMeters: function (RestangularV3, utilityService) {
                        if (!utilityService) {
                            return null;
                        }
                        return RestangularV3
                            .all('utility-meters').customGET('', { utilityServiceId: utilityService.id });
                    },
                    urjanetMeters: function (RestangularV3, utilityService, DataStore) {
                        if (!utilityService) {
                            return null;
                        }
                        return DataStore.getList(RestangularV3.one(''), 'urjanet-meters', { utilityServiceId: utilityService.id });
                    }
                }
            }
        }
    })
        .state('aq.utilityBudgets.utilityaccounts.details', {
        url: '/:utilityAccountId',
        template: '<ui-view></ui-view>',
        controller: 'UtilityAccountDetails as vm',
        abstract: true,
        data: {
            hide: false,
            breadcrumb: 'Utility Account',
            redirectState: 'aq.utilityBudgets.utilityaccounts'
        },
        resolve: {
            utilityAccountId: function ($stateParams) {
                return $stateParams['utilityAccountId'];
            },
            utilityAccount: function (RestangularV3, utilityAccountId, DataStore, utilityAccounts, waitForAuthToken) {
                var isValid = _.some(utilityAccounts, function (ua) { return ua.id == utilityAccountId; });
                if (!isValid) {
                    return null;
                }
                return DataStore.get(RestangularV3.one('utility-accounts', utilityAccountId));
            },
            tariffSchedules: function (DataStore, RestangularV3, utilityAccount, waitForAuthToken) {
                if (utilityAccount.utilityCompany) {
                    var utilityCompanyId = String(utilityAccount.utilityCompany);
                    return DataStore.getList(RestangularV3.one(''), 'energy-tariff-schedules', { utilityCompanyId: utilityCompanyId });
                }
                return [];
            },
            utilityServices: function (RestangularV3, DataStore, buildingId, utilityAccountId, waitForAuthToken) {
                return DataStore.getList(RestangularV3.one(''), 'utility-services', { buildingId: buildingId, utilityAccountId: utilityAccountId });
            },
            readOnlyForm: function (Auth, waitForAuthToken) {
                return !Auth.check({ appName: 'Budgets', access: 'EDIT' });
            },
            utilityAccountBuildingIds: function (RestangularV3, utilityAccountId, waitForAuthToken) {
                return RestangularV3.one('utility-accounts', utilityAccountId).customGET('buildings');
            }
        },
        onEnter: function (Segment) {
            Segment.trackEvent('Budgets:Utility Accounts:Details');
        }
    })
        .state('aq.utilityBudgets.utilityaccounts.details.utilityaccount', {
        url: '/account',
        templateUrl: 'app/utilityBudgets/utilityAccounts/utilityAccountDetails.html',
        controller: 'UtilityAccountDetails as vm',
        abstract: true,
        data: {
            hide: false,
            breadcrumb: 'Utility Account',
            redirectState: 'aq.utilityBudgets.utilityaccounts'
        },
        resolve: {
            urjanetEnrollment: function (RestangularV3, utilityAccountId, waitForAuthToken) {
                return RestangularV3.one('utility-accounts', utilityAccountId).one('urjanet-enrollment').get();
            },
            urjanetEnrollmentAccount: function (RestangularV3, utilityAccountId, waitForAuthToken) {
                return RestangularV3.one('utility-accounts', utilityAccountId).one('urjanet-enrollment-account').get();
            }
        },
        onEnter: function (Segment) {
            Segment.trackEvent('Budgets:Utility Accounts:Details');
        }
    })
        .state('aq.utilityBudgets.utilityaccounts.details.utilityaccount.info', {
        url: '/info',
        templateUrl: 'app/utilityBudgets/utilityAccounts/utilityAccountDetailsInfo.html',
        controller: 'UtilityAccountDetailsInfo as vm'
    })
        .state('aq.utilityBudgets.utilityaccounts.details.utilityaccount.changelog', {
        url: '/changelog',
        templateUrl: 'app/utilityBudgets/utilityAccounts/utilityAccountDetailsChangelog.html',
        controller: 'UtilityAccountDetailsChangelog as vm',
        resolve: {
            urjanetEnrollmentChangelog: function (RestangularV3, utilityAccount, utilityAccountId, DataStore, resolver, waitForAuthToken) {
                try {
                    return resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                        var result, _a, _b;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    _b = (_a = DataStore).getList;
                                    return [4 /*yield*/, RestangularV3.one('utility-accounts', utilityAccountId).one('changelog').get()];
                                case 1:
                                    result = _b.apply(_a, [_c.sent()]);
                                    if (result === null) {
                                        return [2 /*return*/, []];
                                    }
                                    return [2 /*return*/, result];
                            }
                        });
                    }); });
                }
                catch (error) {
                    return [];
                }
            }
        }
    })
        .state('aq.utilityBudgets.utilityaccounts.details.utilityaccount.admin', {
        url: '/admin',
        templateUrl: 'app/utilityBudgets/utilityAccounts/utilityAccountDetailsAdmin.html',
        controller: 'UtilityAccountDetailsAdmin as vm'
    })
        .state('aq.utilityBudgets.utilityaccounts.details.utilityservice', {
        url: '/service/:serviceId',
        templateUrl: 'app/utilityBudgets/utilityAccounts/utilityServiceDetails.html',
        controller: 'UtilityServiceDetails as vm',
        data: {
            hide: false,
            breadcrumb: 'Utility Service',
            redirectState: 'aq.utilityBudgets.utilityaccounts'
        },
        params: {
            utilityCollectorsMap: null
        },
        resolve: {
            utilityService: function (RestangularV3, $stateParams, DataStore, utilityServices, waitForAuthToken) {
                var isValid = _.some(utilityServices, function (us) { return us.id == $stateParams.serviceId; });
                if (!isValid) {
                    return null;
                }
                return DataStore.get(RestangularV3.one('utility-services', $stateParams.serviceId), {});
            },
            collectors: function (Restangular, accountId, $stateParams, waitForAuthToken) {
                var params = { utilityMetersOnly: true };
                return Restangular.one('accounts', accountId)
                    .one('buildings', $stateParams.buildingId)
                    .customGET('queryAllCollectors', params);
            },
            utilityServiceBillPeriods: function (RestangularV3, utilityService, DataStore) {
                return DataStore.getList(RestangularV3.one('utility-bill-periods'), 'by-utility-service', { serviceId: utilityService.id });
            },
            readOnlyForm: function (Auth, waitForAuthToken) {
                return !Auth.check({ appName: 'Budgets', access: 'EDIT' });
            },
            utilityMeters: function (RestangularV3, utilityService) {
                return RestangularV3
                    .all('utility-meters').customGET('', { utilityServiceId: utilityService.id });
            },
            urjanetMeters: function (RestangularV3, utilityService, DataStore) {
                return DataStore.getList(RestangularV3.one(''), 'urjanet-meters', { utilityServiceId: utilityService.id });
            }
        },
        onEnter: function (Segment) {
            Segment.trackEvent('Budgets:Utility Accounts:Details');
        }
    })
        .state('aq.utilityBudgets.utilityaccounts.details.utilityservice.info', {
        url: '/info',
        templateUrl: 'app/utilityBudgets/utilityAccounts/utilityServiceDetailsInfo.html',
        controller: 'UtilityServiceDetailsInfo as vm',
        onEnter: function (Segment) {
            Segment.trackEvent('Budgets:Utility Accounts:Information');
        }
    })
        .state('aq.utilityBudgets.utilityaccounts.details.utilityservice.meters', {
        url: '/meters',
        templateUrl: 'app/utilityBudgets/utilityAccounts/utilityServiceDetailsMeters.html',
        controller: 'UtilityServiceDetailsMeters as vm',
        onEnter: function (Segment) {
            Segment.trackEvent('Budgets:Utility Accounts:Meters');
        },
        resolve: {
            intervalMeters: function (RestangularV3, $stateParams) {
                return RestangularV3.all('interval').customGETLIST("getIntervalMetersByUtilityService/" + $stateParams.serviceId);
            }
        }
    })
        .state('aq.utilityBudgets.utilityaccounts.details.utilityservice.bills', {
        url: '/bills',
        templateUrl: 'app/utilityBudgets/utilityAccounts/utilityServiceDetailsBills.html',
        controller: 'UtilityServiceDetailsBills as vm',
        onEnter: function (Segment) {
            Segment.trackEvent('Budgets:Utility Accounts:Bills');
        }
    });
});
