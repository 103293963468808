namespace aq.services {
    export interface BuildingItemView {
        id: number;
        name: string;
        buidingGroup: number;
    }
    export interface BuildingSelectConfig {
        buildingGroupId: number;
        buildingIds: number[];
        options: {
            buildings: BuildingItemView[];
        };
        buildingSelectionMode: string;
    }
    export class BuildingSelectorActions {
        /* ngInject */
        constructor(
            protected DashboardOptionsService: aq.dashboard.DashboardOptionsService,
            protected $translate
        ) {
        }
        public initDefaultBuildingSelection(cfg: BuildingSelectConfig) {
            if (!cfg.buildingIds || cfg.buildingIds.length == 0) {
                if (cfg.options.buildings && cfg.options.buildings.length > 0) {
                    cfg.buildingIds = [cfg.options.buildings[0].id];
                }
            }
            this.initBuildingSelectionMode(cfg);
        }
        public initBuildingSelectionMode(cfg: BuildingSelectConfig) {
            if (!cfg.buildingSelectionMode) {
                cfg.buildingSelectionMode = 'individual';
            }
            const allBuildingIds = _.map(cfg.options.buildings, 'id');
            if (cfg.buildingSelectionMode == 'all') {
                cfg.buildingIds = allBuildingIds;
            }
        }
        public onBuildingIdsChange(cfg: BuildingSelectConfig, buildingIds: number[], customHandler: Function = null) {
            cfg.buildingIds = buildingIds;
            if (customHandler != null && typeof customHandler == "function") {
                customHandler();
            }
        }
        public onBuildingSelectionModeChange(cfg: BuildingSelectConfig, customHandler: Function = null) {
            let buildingIds = [];
            switch (cfg.buildingSelectionMode) {
                case 'all':
                    buildingIds = _.map(cfg.options.buildings, 'id');
                    break;
                case 'individual':
                case 'group':
                    cfg.buildingGroupId = null;
                    buildingIds = [];
                    break;
                default:
                    break;
            }
            this.onBuildingIdsChange(cfg, buildingIds, customHandler);
        }
        public onBuildingGroupChange(cfg: BuildingSelectConfig, buildingGroupId: number, customHandler: Function = null) {
            cfg.buildingGroupId = buildingGroupId;
            let buildingIds = [];
            if (cfg.buildingGroupId) {
                buildingIds = _.map(_.filter(cfg.options.buildings, (b) => b.buildingGroup == cfg.buildingGroupId), 'id');
            }
            this.onBuildingIdsChange(cfg, buildingIds, customHandler);
        }
    }
    angular.module('aq.services').service('BuildingSelectorActions', BuildingSelectorActions);
}
