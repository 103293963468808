angular.module('aq.ui').directive('draggable', function () {
    return {
        restrict: 'A',
        link: ($scope: ng.IScope, $element, $attrs: angular.IAttributes) => {
            const options = {
                revert: false,
                helper(event) {
                    const jqueryElement = $($element);
                    const clone = jqueryElement.clone();
                    if ($attrs.copyCss !== undefined) {
                        const styles = jqueryElement.getStyles(null, ['cursor']);
                        clone.css(styles);
                        if (jqueryElement.children()) {
                            jqueryElement.children().each((index) => {
                                const childStyle = $(jqueryElement.children()[index]).getStyles(null, ['left', 'right', 'top', 'bottom', 'cursor']);
                                $(clone.children()[index]).css(childStyle);
                            });
                        }
                    }
                    return clone;
                },
                disabled: $attrs.disabled == 'true',
                appendTo: 'body',
                cursor: 'move'
            };
            $element.draggable(options);
        }
    };
});

