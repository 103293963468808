angular
.module('accounts')
.controller('CreateAccountCtrl', function ($scope, Restangular, Messages, licenses) {

    $scope.newAccount = {
        accountName: '',
        timeZoneId: null,
        measurementSystem: null,
        license: null
    }    
    
    $scope.licenses = licenses;
    
    $scope.createAccount = function () {
        return Restangular.all('accounts').post($scope.newAccount).then(function(account) {
            Messages.success('Account created successfully.');
            Restangular.one('accounts', account.model.id)
                       .one('profiles', account.model.profiles[0])
                       .get({inflate: 'account,apps'})
                       .then(function(profiles) {
                $scope.user.profiles.push(profiles[0]);
                $scope.switchProfile(profiles[0]);
            });
        }, function(response) {
            var accountInUse = _.some(response.data.errors, function (error) {
                return error.field === 'updated.accountName';
            });
            Messages.error(accountInUse ? $scope.newAccount.accountName + ' is already in use' : response.data.message);
        });
    }

});