namespace aq.ui {

    import IComponent = aq.models.IComponent;

    interface IBindings {
        activities: aq.models.activity.SimpleActivityResponse[];
        listTitle: string;
    }
    // Reading on component lifecycle hooks https://toddmotto.com/angular-1-5-lifecycle-hooks
    class SectionedListController implements IComponent {
        public activities;
        public accountId: number;
        public buildingId: number;
        public listTitle: string;

        $onInit(): void {
            this.activities = [];
        }

        $postLink(): void {}

        $onDestroy(): void {}

        $onChanges(changes): void {
            if (changes.listTitle) {
                this.listTitle = changes.listTitle.currentValue;
            }
            if (changes.activities) {
                this.activities = angular.copy(changes.activities.currentValue);
            }
        }
    }

    export const SectionedList: ng.IComponentOptions = {
        controller: SectionedListController,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/activities/list/sectioned-list.html',
        bindings: {
            activities: '<',
            listTitle: '<',
            accountId: '<',
            buildingId: '<',
            refreshActivities: '&'
        }
    };

    angular.module('aq.ui').component('sectionedList', SectionedList);
}
