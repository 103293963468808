var aq;
(function (aq) {
    var ui;
    (function (ui) {
        var EditUserModalCtrl = /** @class */ (function (_super) {
            __extends(EditUserModalCtrl, _super);
            /* ngInject */
            function EditUserModalCtrl(editUser, emails, roles, personas, personaRequired, isNew, isGlobal, isAllBuildings, buildings, enableDelete, isReadonlyUserDetails, $mdDialog) {
                var _this = _super.call(this, null, $mdDialog) || this;
                _this.editUser = editUser;
                _this.emails = emails;
                _this.roles = roles;
                _this.personas = personas;
                _this.personaRequired = personaRequired;
                _this.isNew = isNew;
                _this.isGlobal = isGlobal;
                _this.isAllBuildings = isAllBuildings;
                _this.buildings = buildings;
                _this.enableDelete = enableDelete;
                _this.isReadonlyUserDetails = isReadonlyUserDetails;
                _this.$mdDialog = $mdDialog;
                _this.user = angular.copy(_this.editUser);
                if (_this.isAllBuildings !== undefined) {
                    _this.user.isAllBuildings = _this.isAllBuildings;
                }
                if (_this.isGlobal) {
                    _this.user.buildings = [];
                    _this.buildingSelectionMode = 'individual';
                    _this.buildingViewItems = _this.getBuildingViewItems(_this.buildings);
                }
                return _this;
            }
            EditUserModalCtrl.prototype.apply = function () {
                if (this.isGlobal) {
                    this.user.buildings = this.selectedBuildingViewItems;
                }
                this.hide(this.user);
            };
            EditUserModalCtrl.prototype.delete = function () {
                if (!this.isDisplayConfirmation) {
                    this.isDisplayConfirmation = true;
                    return;
                }
                this.user.delete = true;
                this.hide(this.user);
            };
            EditUserModalCtrl.prototype.onBuildingSelectionModeChange = function () {
                if (this.buildingSelectionMode == 'all') {
                    this.selectedBuildingViewItems = angular.copy(this.buildingViewItems);
                }
                else {
                    this.selectedBuildingViewItems = [];
                }
            };
            EditUserModalCtrl.prototype.onSelectedBuildingsChange = function (buildings) {
                this.selectedBuildingViewItems = buildings;
            };
            EditUserModalCtrl.prototype.isEmailAlreadyTaken = function () {
                var _this = this;
                return _.findIndex(this.emails, function (email) { return email == _this.user.email; }) > -1;
            };
            EditUserModalCtrl.prototype.getPersona = function (persona) {
                return persona.replace('_', ' ');
            };
            EditUserModalCtrl.prototype.getBuildingViewItems = function (buildings) {
                if (!buildings) {
                    return [];
                }
                var items = _.map(buildings, function (building) {
                    return {
                        id: building.index,
                        name: building.name
                    };
                });
                return items;
            };
            return EditUserModalCtrl;
        }(aq.common.Controllers.ModalCtrl));
        ui.EditUserModalCtrl = EditUserModalCtrl;
        angular.module('aq.ui').controller('EditUserModalCtrl', EditUserModalCtrl);
    })(ui = aq.ui || (aq.ui = {}));
})(aq || (aq = {}));
