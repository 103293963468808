var aq;
(function (aq) {
    var utilityBudgets;
    (function (utilityBudgets) {
        var Step;
        (function (Step) {
            Step[Step["DOWNLOAD_PLANNER"] = 0] = "DOWNLOAD_PLANNER";
            Step[Step["IMPORT_FROM_PLANNER"] = 1] = "IMPORT_FROM_PLANNER";
            Step[Step["REVIEW"] = 2] = "REVIEW";
            Step[Step["DONE"] = 3] = "DONE";
        })(Step || (Step = {}));
        var BudgetPlanningWizardCtrl = /** @class */ (function (_super) {
            __extends(BudgetPlanningWizardCtrl, _super);
            /* ngInject */
            function BudgetPlanningWizardCtrl($mdDialog, $mdStepper, $timeout, accountId, buildingId, BudgetServiceV3, Messages, FileHandlerService) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.$mdStepper = $mdStepper;
                _this.$timeout = $timeout;
                _this.accountId = accountId;
                _this.buildingId = buildingId;
                _this.BudgetServiceV3 = BudgetServiceV3;
                _this.Messages = Messages;
                _this.FileHandlerService = FileHandlerService;
                _this.init();
                return _this;
            }
            BudgetPlanningWizardCtrl.prototype.init = function () {
                this.currentStep = 0;
                this.isDownloading = false;
                this.isSubmitting = false;
                this.importData = null;
                this.viewImportData = null;
                this.budgetExistsWarnings = [];
            };
            BudgetPlanningWizardCtrl.prototype.downloadBudgetPlanner = function () {
                var _this = this;
                this.isDownloading = true;
                return this.BudgetServiceV3.getBudgetPlannerTemplate(this.buildingId)
                    .then(function (response) {
                    _this.$timeout(function () {
                        _this.FileHandlerService.handleFileDownload(response, 'application/vnd.ms-excel');
                        _this.isDownloading = false;
                    }, 0);
                })
                    .catch(function () {
                    _this.Messages.error('Error while downloading budget planner');
                    _this.isDownloading = false;
                });
            };
            BudgetPlanningWizardCtrl.prototype.create = function () {
                return this.BudgetServiceV3.importBudgetData(this.accountId, this.buildingId, this.importData);
            };
            BudgetPlanningWizardCtrl.prototype.verifyImport = function () {
                return this.BudgetServiceV3.verifyBudgetDataImport(this.accountId, this.buildingId, this.importData);
            };
            BudgetPlanningWizardCtrl.prototype.next = function () {
                var _this = this;
                if (this.currentStep == Step.IMPORT_FROM_PLANNER) {
                    this.isSubmitting = true;
                    this.verifyImport()
                        .then(function (result) {
                        _this.$timeout(function () {
                            _this.setViewImportData(result);
                            _this.$mdStepper('budget-planning-wizard').next();
                            _this.currentStep++;
                        }, 0);
                    })
                        .catch(function (responseresponse) {
                        var message = 'We don\'t recognize this format. Please be sure to copy the yellow highlighted cells on Step 4 of the Budget Workbook';
                        _this.Messages.error(message);
                        _this.importData = null;
                    })
                        .finally(function () {
                        _this.isSubmitting = false;
                    });
                }
                else if (this.currentStep == Step.REVIEW) {
                    this.isSubmitting = true;
                    this.create()
                        .then(function () {
                        _this.$timeout(function () {
                            _this.$mdStepper('budget-planning-wizard').next();
                            _this.currentStep++;
                        }, 0);
                    })
                        .catch(function (response) {
                        var message = 'Error while importing budget data.';
                        _this.Messages.error(message);
                    })
                        .finally(function () {
                        _this.isSubmitting = false;
                    });
                }
                else if (this.currentStep == Step.DONE) {
                    this.done();
                }
                else {
                    this.$mdStepper('budget-planning-wizard').next();
                    this.currentStep++;
                }
            };
            BudgetPlanningWizardCtrl.prototype.setViewImportData = function (data) {
                var _this = this;
                this.viewImportData = [];
                var sortedData = _.sortBy(data.workbookItems, function (item) { return item.date; });
                this.viewTotals = {
                    month: 'Total',
                    electricity: 0,
                    water: 0,
                    gas: 0,
                    steam: 0
                };
                _.each(sortedData, function (item) {
                    var viewDataRow = {
                        month: moment(item.date).format('MMM YYYY'),
                        electricity: item.electricity,
                        water: item.water,
                        gas: item.gas,
                        steam: item.steam
                    };
                    _this.viewTotals.electricity += viewDataRow.electricity || 0;
                    _this.viewTotals.water += viewDataRow.water || 0;
                    _this.viewTotals.gas += viewDataRow.gas || 0;
                    _this.viewTotals.steam += viewDataRow.steam || 0;
                    _this.viewImportData.push(viewDataRow);
                });
                if (this.viewTotals.electricity > 0 && data.electricBudgetExists) {
                    this.budgetExistsWarnings.push('You already have a budget for Electricity. Completing this step will update that budget.');
                }
                if (this.viewTotals.water > 0 && data.waterBudgetExists) {
                    this.budgetExistsWarnings.push('You already have a budget for Water. Completing this step will update that budget.');
                }
                if (this.viewTotals.gas > 0 && data.gasBudgetExists) {
                    this.budgetExistsWarnings.push('You already have a budget for Gas. Completing this step will update that budget.');
                }
                if (this.viewTotals.steam > 0 && data.steamBudgetExists) {
                    this.budgetExistsWarnings.push('You already have a budget for Steam. Completing this step will update that budget.');
                }
            };
            BudgetPlanningWizardCtrl.prototype.previous = function () {
                this.$mdStepper('budget-planning-wizard').back();
                this.currentStep = Math.max(0, this.currentStep - 1);
            };
            BudgetPlanningWizardCtrl.prototype.done = function () {
                this.hide();
            };
            BudgetPlanningWizardCtrl.prototype.confirmCancel = function (event) {
                var _this = this;
                var confirm = this.$mdDialog.confirm()
                    .title('Do you want to exit wizard? Your progress will be lost.')
                    .ariaLabel('Exit Wizard')
                    .targetEvent(event)
                    .ok('Exit')
                    .cancel('Stay');
                confirm.multiple(true);
                this.$mdDialog.show(confirm).then(function () {
                    _this.cancel();
                });
            };
            BudgetPlanningWizardCtrl.prototype.isShowBack = function () {
                return this.currentStep > Step.DOWNLOAD_PLANNER && this.currentStep < Step.DONE;
            };
            BudgetPlanningWizardCtrl.prototype.isDisableNext = function () {
                if (this.isSubmitting) {
                    return true;
                }
                switch (this.currentStep) {
                    case Step.IMPORT_FROM_PLANNER:
                        return !this.importData;
                    default:
                        return false;
                }
            };
            BudgetPlanningWizardCtrl.prototype.getNextButtonTitle = function () {
                switch (this.currentStep) {
                    case Step.REVIEW:
                        return 'Create';
                    case Step.DONE:
                        return 'Done';
                    default:
                        return 'Next';
                }
            };
            return BudgetPlanningWizardCtrl;
        }(aq.common.Controllers.ModalCtrl));
        utilityBudgets.BudgetPlanningWizardCtrl = BudgetPlanningWizardCtrl;
        angular.module('aq.utilityBudgets').controller('BudgetPlanningWizardCtrl', BudgetPlanningWizardCtrl);
    })(utilityBudgets = aq.utilityBudgets || (aq.utilityBudgets = {}));
})(aq || (aq = {}));
