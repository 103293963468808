namespace aq.settings {
    export class SettingsEnergyUseCRUDCtrl {
        private svc: PlatformSettingsCrudModal;
        private metrics: any[];
        /* @ngInject */
        constructor(
            private $mdDialog: any,
            private $q: ng.IQService,
            private Messages: any,
            private Restangular: restangular.IService,
            private Errors: any,
            private account: any,
            private object: any,
            private accountSettingsAccess: common.models.AppAccessObject
        ) {
            const restangularParams: ModalCrudRestangularParameters = {
                objectName: 'EnergyUse',
                restangularName: 'EnergyUses'
            };
            this.svc = new PlatformSettingsCrudModal(
                $mdDialog,
                $q,
                Restangular,
                Messages,
                Errors,
                restangularParams,
                object,
                this.account
            );
            this.metrics = this.account.accountMetrics;
        }
    }

    angular
        .module('aq.settings')
        .controller('SettingsEnergyUseCRUDCtrl', SettingsEnergyUseCRUDCtrl);
}
