/**
 * Access directives: add/edit/delete
 * Use them on buttons to mark for corresponding access
 */
(function() {
    const makeDirective = function (Auth, access) {
        return {
            restrict: 'A',
            link(scope, el) {
                // initially hide/disable the element and only show it in case we have a proper permission
                el.attr('type') === 'checkbox' ? el.attr('disabled', true) : el.hide();
                if (Auth.check({access})) {
                    el.attr('type') === 'checkbox' ? el.removeAttr('disabled') : el.show();
                }
            }
        };
    };

    angular.module('aq.ui')
        .directive('add', function (Auth) {
            return makeDirective(Auth, 'FULL_ACCESS');
        })
        .directive('edit', function (Auth) {
            return makeDirective(Auth, 'EDIT');
        })
        .directive('delete', function (Auth) {
            return makeDirective(Auth, 'FULL_ACCESS');
        })
        // when has-functionality="functionality" is defined, the element will be shown
        // only if the account has the functionality
        .directive('hasFunctionality', function (Auth) {
            return {
                restrict: 'A',
                link(scope, el, attr) {
                    el.hide();
                    if (Auth.hasFunctionality(attr.hasFunctionality)) {
                        el.show();
                    }
                }
            };
        });
})();
