namespace aq.utilityBudgets {
    interface AccrualWizardConfig extends aq.services.BuildingSelectConfig {
        actions: aq.services.BuildingSelectorActions;
    }
    interface AccrualPeriod {
        name: string;
        start: moment.Moment;
        end: moment.Moment;
    }
    interface BuildingUtilityAccount {
        building: aq.services.BuildingItemView;
        utilityAccountsMeasureMap: {
            [measure: string]: UtilityAccountMeasureData
        };
    }
    interface UtilityAccountMeasureData {
        utilityAccounts: UtilityAccountData[];
        predictedExpense: number;
        predictedConsumption: number;
        measureUnit: string;
    }
    interface UtilityAccountData {
        id: number;
        accruedExpense: number;
        initialAccruedExpense: number;
        currentExpense: number;
    }
    export class AccrualWizardCtrl {
        public readonly emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        public config: AccrualWizardConfig;
        public buildingOptions: aq.services.BuildingItemView[];
        public availablePeriods: AccrualPeriod[];
        public selectedPeriod: AccrualPeriod;
        public buildingUtilityAccountsMap: {
            [buildingId: number]: BuildingUtilityAccount;
        };
        public accruals: AccrualResponse[];
        public customEmails: string[];
        public selectedBuildings: string[];
        public displayBuildings: string[];
        public updateSelectedBuildingsDisplay: () => void;
        public skipLastStep: boolean;
        public isSubmitting: boolean;
        /* ngInject */
        constructor(
            private buildings: aq.common.models.Building[],
            private buildingGroups,
            private measures,
            private measureUnitMap: { [measure: string]: string },
            private currencyUnitSymbol: string,
            private $mdDialog: ng.material.IDialogService,
            private $mdStepper,
            private ModelUtilService: aq.services.ModelUtilService,
            private BuildingSelectorActions: aq.services.BuildingSelectorActions,
            private RestangularV3: restangular.IService,
            private $q: ng.IQService,
            private Messages: aq.services.Messages,
            private AppStorageService: aq.services.AppStorageService,
            private $state: ng.ui.IStateService
        ) {
            this.updateSelectedBuildingsDisplay = () => {
                this.selectedBuildings = _.map(this.config.buildingIds, (id) => {
                    const building = _.find(this.buildings, (b) => b.id == id);
                    return building && building.name || '';
                });
                this.displayBuildings = _.take(this.selectedBuildings, 5);
            };
            this.buildingOptions = _.map(this.buildings, (building: aq.common.models.Building) => {
                const item: aq.services.BuildingItemView = {
                    id: parseInt(building.id),
                    name: building.name,
                    buidingGroup: building.buildingGroup
                };
                return item;
            });
            this.config = {
                buildingGroupId: null,
                buildingSelectionMode: null,
                buildingIds: [],
                options: {
                    buildings: this.ModelUtilService.pareProperties(this.buildings, ['buildingGroup']),
                    buildingGroups: this.ModelUtilService.pareProperties(this.buildingGroups)
                } as any,
                actions: this.BuildingSelectorActions
            };
            this.BuildingSelectorActions.initDefaultBuildingSelection(this.config);
            this.initAvailablePeriods();
            this.buildingUtilityAccountsMap = {};
            this.customEmails = [];
            this.updateSelectedBuildingsDisplay();
            this.skipLastStep = this.getSkipPreferenceFromStorage();
        }
        public initAvailablePeriods() {
            this.availablePeriods = [];
            let monthCounter = 12;
            let date = moment();
            while (monthCounter > 0) {
                this.availablePeriods.push({
                    name: date.format('MMMM YYYY'),
                    start: date.startOf('month'),
                    end: date.endOf('month')
                });
                date = moment(date).subtract(1, 'month');
                monthCounter--;
            }
            this.selectedPeriod = _.first(this.availablePeriods);
        }
        public goToAccrualStep() {
            this.next();
            this.RestangularV3.all('utility-services').customGET('', { buildingId: this.config.buildingIds })
                .then((response) => {
                    _.each(this.config.buildingIds, (id) => {
                        const buildingUtilityAccounts = _.filter(response, (service) => {
                            return _.some(service.buildings, (building) => building == id);
                        });
                        this.buildingUtilityAccountsMap[id] = {
                            building: _.find(this.buildingOptions, (b) => b.id == id),
                            utilityAccountsMeasureMap: this.mapUtilityAccountsByMeasure(buildingUtilityAccounts)
                        };
                    });
                })
                .then(() => {
                    const accrualRequestParams = {
                        buildingIds: this.config.buildingIds,
                        start: this.selectedPeriod.start.format('YYYY-MM-DD'),
                        end: this.selectedPeriod.end.format('YYYY-MM-DD')
                    };
                    return this.RestangularV3.all('accruals').customGET('', accrualRequestParams)
                        .then((response: AccrualResponse[]) => {
                            this.accruals = response;
                        });
                })
                .then(() => {
                    this.next();
                })
                .catch(() => {
                    this.previous();
                    this.Messages.error('Error ocured while generating accruals, please contact your Administrator');
                });
        }
        public mapUtilityAccountsByMeasure(utilityAccounts) {
            const groups = _.groupBy(utilityAccounts, (ua) => ua.type.toLowerCase());
            const result = {};
            _.each(groups, (utilityAccountDataItems: UtilityAccountData[], key) => {
                // TODO: remove mock calculations data when AQ-7338 is implemented
                _.each(utilityAccountDataItems, (ua) => {
                    ua.currentExpense = 9000;
                    ua.initialAccruedExpense = 4500;
                    ua.accruedExpense = 5000;
                });
                result[key] = {
                    utilityAccounts: utilityAccountDataItems,
                    predictedExpense: _.sumBy(utilityAccountDataItems, (item) => item.accruedExpense),
                    predictedConsumption: Math.round(_.sumBy(utilityAccountDataItems, (item) => item.accruedExpense) / 0.12),
                    measureUnit: this.measureUnitMap[key.toUpperCase()]
                };
            });
            return result;
        }
        public submitAccruals() {
            this.isSubmitting = true;
            const submitAccrualsRequest: SubmitAccrualRequest = {
                sendAccounting: false,
                sendApprovers: false,
                sendMyself: false,
                customEmails: this.customEmails,
                accruals: this.getSubmitAccrualItems()
            };
            this.RestangularV3.all('accruals')
                .customPOST(submitAccrualsRequest, 'bulk-submit')
                .then(() => {
                    this.Messages.success(`Successfully submitted accruals.`);
                })
                .catch(() => {
                    this.Messages.error('Unable to submit Accrual, please contact your Administrator');
                })
                .finally(() => this.isSubmitting = false);
        }
        public getSubmitAccrualItems() {
            const items = _.map(this.accruals, (accrual: AccrualResponse) => {
                const item: SubmitAccrualItem = {
                    id: accrual.id,
                    accruedExpense: this.findAccruedExpenseForUtilityService(accrual.utilityServiceId)
                };
                return item;
            });
            return items;
        }
        public findAccruedExpenseForUtilityService(utilityServiceId: number) {
            let accruedExpense = null;
            _.each(this.buildingUtilityAccountsMap, (buildingUtilityAccount: BuildingUtilityAccount) => {
                _.each(buildingUtilityAccount.utilityAccountsMeasureMap, (utilityAccountsMeasureData: UtilityAccountMeasureData) => {
                    const utilityAccount: UtilityAccountData = _.find(utilityAccountsMeasureData.utilityAccounts, (ua: UtilityAccountData) => {
                        return ua.id == utilityServiceId;
                    });
                    if (utilityAccount != null) {
                        accruedExpense = utilityAccount.accruedExpense;
                    }
                });
            });
            return accruedExpense;
        }
        public goToSettings() {
            this.close();
            this.$state.go('aq.utilityBudgets.accrual.settings', null, {reload: true});
        }
        public isMissingBuildings() {
            return !this.config.buildingIds || this.config.buildingIds.length == 0;
        }
        public next() {
            this.$mdStepper('submit-accruals-wizard').next();
        }
        public previous() {
            this.$mdStepper('submit-accruals-wizard').back();
        }
        public close(rejectData?: any) {
            this.$mdDialog.cancel(rejectData);
        }
        public onAddCustomEmail(item: string) {
            if (!this.validateEmail(item)) {
                this.customEmails = _.filter(this.customEmails, (email) => email != item);
            }
        }
        public validateEmail(email) {
            return this.emailRegex.test(String(email).toLowerCase());
        }
        public getShortMonthFormatted() {
            return this.selectedPeriod && moment(this.selectedPeriod.name, 'MMMM YYYY').format('MMM.') || '';
        }
        public showInfo($event, measureItem) {
            this.$mdDialog.show({
                clickOutsideToClose: true,
                templateUrl: 'app/utilityBudgets/accrual/wizard/accrualInfo.html',
                controller: 'AccrualInfoCtrl as vm',
                locals: {
                    measureItem,
                    currencyUnitSymbol: this.currencyUnitSymbol
                },
                targetEvent: $event,
                multiple: true
            } as any);
        }
        public getSkipPreferenceFromStorage() {
            const accountId = _.first(this.buildings).account;
            const storage = this.AppStorageService.getStorage('accrualWizard', accountId);
            const skipLastStep = storage && storage.skipLastStep || false;
            return skipLastStep;
        }
        public setSkipPreferenceInStorage(isSkip: boolean) {
            const accountId = _.first(this.buildings).account;
            this.AppStorageService.setAttr('skipLastStep', isSkip, 'accrualWizard', accountId);
        }
        public onChangeSkipStep() {
            this.setSkipPreferenceInStorage(this.skipLastStep);
        }
    }
    angular.module('aq.utilityBudgets').controller('AccrualWizardCtrl', AccrualWizardCtrl);
}
