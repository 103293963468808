namespace aq.tenantbilling {
    export interface CollectorAllocationSet {
        [collectorId: string]: Allocation[];
    };
    export interface InvalidAllocation {
        name: string;
        totalPercent: number;
    }
    export class AddEditTenantChargeCtrl extends aq.common.Controllers.ModalCtrl {
        public positiveCollectors: any[];
        public subtractedCollectors: any[];
        public removedAllocations: Allocation[];
        public isRemoveMeterError: boolean;
        public invalidAllocations: InvalidAllocation[];
        public positiveCollectorAllocationSet: CollectorAllocationSet;
        public subtractedCollectorAllocationSet: CollectorAllocationSet;
        private collectorSearchText: string;
        private collectorSubtractedSearchText: string;
        private selectedPositiveCollector: any;
        private selectedNegativeCollector: any;
        private selectedAllocation;
        private selectedDevice: aq.common.models.Device;
        private isLoadingCollectorAllocation: boolean;
        private isSavingTenantCharge: boolean;
        /* @ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private $state: ng.ui.IStateService,
            private Auth: aq.services.Auth,
            private accountId,
            private buildingId,
            private tenantId,
            private tenantName,
            private tenantServices: TenantService[],
            private tenantCharge: TenantCharge,
            private allocations: Allocation[],
            private collectors: Collector[],
            private loading,
            public unlinkedDevices,
            private TenantServiceHelper: TenantServiceHelper
        ) {
            super({}, $mdDialog);
            if (!this.tenantCharge) {
                this.tenantCharge = {
                    id: null,
                    tenant: this.tenantId,
                    active: true,
                    service: ''
                } as any;
            }
            this.positiveCollectors = [];
            this.subtractedCollectors = [];
            this.removedAllocations = [];
            if (this.tenantCharge.type == 'METERED') {
                this.positiveCollectors = this.mapCollectorFromChargeAllocations(this.allocations, false);
                this.subtractedCollectors = this.mapCollectorFromChargeAllocations(this.allocations, true);
            }
            this.positiveCollectorAllocationSet = {};
            this.subtractedCollectorAllocationSet = {};
            this.tenantCharge.service = this.tenantCharge.service.toString();
        }
        public isMeterAddedOrFee() {
            if (this.isTenantChargeServiceMetered()) {
                return this.positiveCollectors.length > 0 || this.subtractedCollectors.length > 0;
            }
            return true;
        }
        public cancel(data?): void {
            this.$mdDialog.cancel();
        }
        public hide(): void {
            this.$mdDialog.hide();
        }

        public getTypeForMethod(service: TenantService) {
            if (service.method == 'FEE') {
                return 'FIXED';
            } else if (service.method == 'METERED_USAGE') {
                return 'METERED';
            } else {
                return service.method;
            }
        }
        public saveTenantCharge() {
            this.getInvalidMeterAllocations();
            if (this.invalidAllocations.length > 0) {
                return;
            }
            const service: TenantService = _.findById(this.tenantServices, this.tenantCharge.service);
            this.tenantCharge.type = this.getTypeForMethod(service);
            this.loading.start();
            let updatePromise;
            this.isSavingTenantCharge = true;
            if (this.tenantCharge.id) {
                updatePromise = this.TenantServiceHelper.updateTenantCharge(this.tenantCharge)
                    .then(() => {
                        return this.TenantServiceHelper.updateCollectorAllocations(
                            this.positiveCollectorAllocationSet,
                            this.subtractedCollectorAllocationSet,
                            this.removedAllocations
                        );
                    });
            } else {
                updatePromise = this.TenantServiceHelper.createTenantCharge(this.tenantCharge)
                    .then((tenantCharge) => {
                        this.tenantCharge = tenantCharge;
                        return this.TenantServiceHelper.updateCollectorAllocations(
                            this.positiveCollectorAllocationSet,
                            this.subtractedCollectorAllocationSet,
                            this.removedAllocations,
                            tenantCharge.id
                        );
                    });
            }
            updatePromise
                .then(() => {
                    return this.TenantServiceHelper.syncTenantChargeValues(this.tenantCharge);
                })
                .then(() => {
                    this.hide();
                })
                .finally(() => {
                    this.loading.stop();
                    this.isSavingTenantCharge = false;
                });
        }
        /*
         * Find all collectors whose name contains searchText,
         *  support the metric which the serviceId supports,
         *  and is not already contained in the filter list
         */
        public queryCollectors(searchText, serviceId, filtered) {
            const tenantService: TenantService = _.find(this.tenantServices, (service) => service.id == serviceId);
            const metric = tenantService.serviceType;
            const collectors: Collector[] = _.filter(this.collectors, (collector: Collector) => {
                return collector.name && collector.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
                    && !_.find(filtered, (item) => item.id == collector.id)
                    && collector.metrics.indexOf(metric) > -1;
            });
            return collectors;
        }
        public mapCollectorFromChargeAllocations(chargeAllocations: Allocation[], negativeSign) {
            return _(chargeAllocations).filter((allocation: Allocation) => {
                return allocation.negativeSign === negativeSign;
            }).map((allocation: Allocation) => {
                const collector = angular.copy(_.find(this.collectors, (c: Collector) => allocation.collector == c.id));
                collector.canRemove = _.isNil(allocation.allocationPercent)
                    || allocation.allocationPercent == 0;
                return collector;
            }).value();
        }
        public allocatedAcrossTenants(collectorId) {
            const allocation: Allocation = _.find(this.allocations, (a: Allocation) => a.collector == collectorId);
            return allocation && !_.isNil(allocation.allocationPercent) && allocation.allocationPercent < 100;
        }
        public onMeterAllocationAdd(chip, negativeSign) {
            this.isRemoveMeterError = false;
            const newAllocation: Allocation = {
                collector: chip,
                meteredTenantCharge: this.tenantCharge,
                tenantName: this.tenantName,
                negativeSign,
                allocationPercent: null
            };
            chip.canRemove = true;
            this.findAllocationsByCollectorId(chip, negativeSign).then((sharedAllocations) => {
                const collectorId = chip.id.toString();
                this.processCollectorAllocations(collectorId, sharedAllocations, newAllocation);
                this.selectedAllocation = newAllocation.negativeSign ?
                    this.subtractedCollectorAllocationSet[collectorId] :
                    this.positiveCollectorAllocationSet[collectorId];
                if (this.selectedAllocation.length > 1) {
                    chip.isShared = true;
                }

                this.TenantServiceHelper.getDevice(collectorId).then((device) => {
                    this.selectedDevice = device;
                    this.unlinkedDevices = _.filter(this.unlinkedDevices, (deviceLink) => {
                        return deviceLink.device.id != this.selectedDevice.id;
                    });
                });
            });
        }
        public onMeterAllocationRemove(chip, negativeSign) {
            this.isRemoveMeterError = false;
            const collectorId = chip.id.toString();
            const collectorAllocationSet = negativeSign ?
                this.subtractedCollectorAllocationSet :
                this.positiveCollectorAllocationSet;
            if (collectorAllocationSet[collectorId]) {
                this.processRemovingMeterAllocation(this.tenantName, collectorId, collectorAllocationSet[collectorId], negativeSign);
            } else {
                this.findAllocationsByCollectorId(chip, negativeSign)
                    .then((sharedAllocations) => {
                        collectorAllocationSet[collectorId] = sharedAllocations;
                        this.processRemovingMeterAllocation(this.tenantName, collectorId, collectorAllocationSet[collectorId], negativeSign);
                    });
            }

        }
        public onMeterAllocationSelect(chip, negativeSign) {
            this.isRemoveMeterError = false;
            const collectorId = chip.id.toString();
            this.TenantServiceHelper.getDevice(collectorId).then((device) => {
                this.selectedDevice = device;
            });
            const collectorAllocationSet = negativeSign ?
                this.subtractedCollectorAllocationSet :
                this.positiveCollectorAllocationSet;
            if (!collectorAllocationSet[collectorId]) {
                this.findAllocationsByCollectorId(chip, negativeSign)
                    .then((sharedAllocations) => {
                        collectorAllocationSet[collectorId] = sharedAllocations;
                        this.selectedAllocation = collectorAllocationSet[collectorId];
                    });
                return;
            }
            this.selectedAllocation = collectorAllocationSet[collectorId];
        }
        public findAllocationsByCollectorId(chip, negativeSign) {
            const collectorId = chip.id.toString();
            this.isLoadingCollectorAllocation = true;
            return this.TenantServiceHelper.getCollectorAllocations(collectorId, negativeSign)
                .then((sharedAllocations) => {
                    if (sharedAllocations.length <= 1) {
                        chip.canRemove = true;
                        chip.isShared = false;
                        if (sharedAllocations.length === 1) {
                            sharedAllocations[0].allocationPercent = null;
                        }
                    } else {
                        chip.isShared = true;
                    }
                    return sharedAllocations;
                })
                .finally(() => {
                    this.isLoadingCollectorAllocation = false;
                });
        }
        public navigateToDeviceDetails() {
            this.$state.transitionTo('aq.deviceManagement.building.device.configuration', {
                accountId: this.accountId,
                buildingId: this.buildingId,
                deviceId: this.selectedDevice.id
            });
            this.cancel();
        }
        public navigateToDeviceManagement() {
            this.$state.transitionTo('aq.deviceManagement.building', {
                accountId: this.accountId,
                buildingId: this.buildingId
            });
            this.cancel();
        }
        public onAllocationPercentChange(editedAllocation: Allocation) {
            this.getInvalidMeterAllocations();
            if (editedAllocation.tenantName == this.tenantName) {
                const existingAllocation = _.find(this.allocations, (a: Allocation) => a.collector == editedAllocation.collector);
                if (existingAllocation) {
                    existingAllocation.allocationPercent = editedAllocation.allocationPercent;
                }
            }
        }
        public getInvalidAllocationsFromSet(allocationSet, collectors) {
            return _.filter(allocationSet, (allocations, collectorId) => {
                if (allocations.length <= 1) {
                    return false;
                }

                const invalidAllocation = this.getAllocationPercent(allocations) != 100;

                const collector = _.find(collectors, { id: parseInt(collectorId) });
                if (collector) {
                    const tenantAllocation = _.find(allocations, (a: Allocation) => {
                        return a.tenantName == this.tenantName;
                    });
                    collector.canRemove = !invalidAllocation &&
                        (!tenantAllocation || tenantAllocation.allocationPercent == 0);
                }

                return invalidAllocation;
            });
        }
        public getInvalidMeterAllocations() {
            const invalidPositiveAllocations = this.getInvalidAllocationsFromSet(this.positiveCollectorAllocationSet, this.positiveCollectors);
            const invalidSubtractedAllocations = this.getInvalidAllocationsFromSet(this.subtractedCollectorAllocationSet, this.subtractedCollectors);
            const invalidAllocations = _.concat(invalidPositiveAllocations, invalidSubtractedAllocations);
            this.invalidAllocations = _.map(invalidAllocations, (allocations: Allocation[]) => {
                return {
                    name: _.find(this.collectors, (c: Collector) => allocations[0].collector == c.id).name,
                    totalPercent: this.getAllocationPercent(allocations)
                };
            });
        }
        public isTenantChargeServiceMetered() {
            const selectedTenantService = _.find(this.tenantServices, (s) => this.tenantCharge.service == s.id);
            if (!selectedTenantService) {
                return false;
            }
            return selectedTenantService.method == 'METERED_USAGE';
        }
        private processRemovingMeterAllocation(tenantName, collectorId, currentCollectorAllocations, negativeSign) {
            const sumWithoutCurrentMeter = this.getAllocationPercent(currentCollectorAllocations, tenantName);
            this.isRemoveMeterError = currentCollectorAllocations.length > 1 && sumWithoutCurrentMeter != 100;
            if (this.isRemoveMeterError) {
                if (negativeSign) {
                    this.subtractedCollectors = this.mapCollectorFromChargeAllocations(this.allocations, true);
                } else {
                    this.positiveCollectors = this.mapCollectorFromChargeAllocations(this.allocations, false);
                }
                this.selectedAllocation = currentCollectorAllocations;
                return;
            }

            _.remove(currentCollectorAllocations, (a: Allocation) => a.tenantName == tenantName);
            this.getInvalidMeterAllocations();

            const removed = _.remove(this.allocations, (a: Allocation) => a.collector == collectorId && a.negativeSign == negativeSign);
            this.removedAllocations = _.concat(this.removedAllocations, removed);
            this.selectedAllocation = null;
        }
        private processCollectorAllocations(collectorId, existingAllocations, newAllocation: Allocation) {
            const collectorAllocationSet = newAllocation.negativeSign ?
                this.subtractedCollectorAllocationSet :
                this.positiveCollectorAllocationSet;
            collectorAllocationSet[collectorId] = [];
            if (existingAllocations && existingAllocations.length > 0) {
                collectorAllocationSet[collectorId] = _.filter(existingAllocations, (a: Allocation) => a.tenantName != this.tenantName);
                if (collectorAllocationSet[collectorId].length === 1
                    && _.isNil(collectorAllocationSet[collectorId][0].allocationPercent)) {
                    collectorAllocationSet[collectorId][0].allocationPercent = 100;
                }
            }
            collectorAllocationSet[collectorId].push(newAllocation);
            if (collectorAllocationSet[collectorId].length == 1) {
                newAllocation.allocationPercent = null;
            } else {
                newAllocation.allocationPercent = 0;
            }
        }
        private getAllocationPercent(allocations, withoutTenantName = null) {
            const maxDecimalPlaces = _.max(_.map(allocations, (allocation) => {
                if (!allocation.allocationPercent) {
                    return 0;
                }
                const splitAlloc = allocation.allocationPercent.toString().split('.');
                if (splitAlloc.length > 1) {
                    return splitAlloc[1].length;
                }
                return 0;
            }));
            const percent = _.sumArray(_.map(allocations, (allocation: Allocation) => {
                if (withoutTenantName && allocation.tenantName == withoutTenantName) {
                    return 0;
                }
                return allocation.allocationPercent ? allocation.allocationPercent : 0;
            }));
            return _.round(percent, maxDecimalPlaces);
        };
        private getInvalidAllocationText(allocation: InvalidAllocation) {
            return `${allocation.name} (percentage sum: ${allocation.totalPercent}%)`;
        }
    }
    angular.module('tenantBilling').controller('AddEditTenantChargeCtrl', AddEditTenantChargeCtrl);
}
