namespace aq.services {

    import Case = aq.models.prescription.Case;
    import CaseUser = aq.models.prescription.CaseUser;

    export class PrescriptionService {
        public getStatus(rx: Case): string {
            switch (_.lowerCase(rx.status)) {
                case null:
                case 'published':
                case 're-opened':
                    return 'New';
                default:
                    return rx.status;
            }
        }

        public getPriorityName(rx: Case): string {
            const priority = rx.priority || 1;
            switch (priority) {
                case 1:
                    return 'Low';
                case 2:
                    return 'Medium';
                case 3:
                    return 'High';
                default:
                    return null;
            }
        }

        public isUrgent(rx: Case): boolean {
            const status = rx.status && _.lowerCase(rx.status);
            if (rx.priority
                && rx.priority === 3
                && (!status || status === 'in progress' || status === 'published' || status === 're-opened')
            ) {
               return true;
            }
            return false;
        }

        public getTitle(rx: Case): string {
            if (rx.prescription_title) {
                return rx.prescription_title;
            }

            if (rx.p2_prescription_title) {
                return rx.p2_prescription_title;
            }

            if (rx.id) {
                return `Rx-${rx.id.toString()}`;
            }

            return `Rx`;
        }

        public getDateOpened(rx: Case): string {
            return rx.first_published ? moment(rx.first_published).format('lll') : 'N/A';
        }

        public getEstimatedSavings(rx: Case): number {
            if (rx.calculated_utility_savings_override) {
                return rx.calculated_utility_savings_override;
            }
            return rx.calculated_utility_savings_dollar;
        }

        public getUserDisplayName(user: CaseUser): string {
            return `${user.first_name} ${user.last_name}`;
        }

        public getDiagnosisDescription(rx: Case): string {
            if (rx.diagnosis_description) {
                return rx.diagnosis_description;
            }
            if (rx.p2_details) {
                return rx.p2_details;
            }
            return null;
        }
    }

    angular
        .module('aq.services')
        .service('PrescriptionService', PrescriptionService);
}
