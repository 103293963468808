angular
    .module('utilityCompany', [])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.admin.configuration.utilityCompany', {
                url: '/utility-company',
                templateUrl: 'app/admin/configuration/utilityCompany/list/list.html',
                controller: 'UtilityCompanyListCtrl as utilityCompanyListCtrl',
                data: {
                    breadcrumb: 'Utility Companies',
                    icon: '.aq-icons-utilities'
                },
                resolve: {
                    allUtilityCompanies: (RestangularV3: restangular.IService, waitForAuthToken) => {
                        return RestangularV3.all('utility-companies')
                            .getList();
                    },
                    measures: (Restangular: restangular.IService, waitForAuthToken) => {
                        return Restangular.one('measures')
                            .getList();
                    }
                }
            })
            .state('aq.admin.configuration.utilityCompany.details', {
                url: '/:utilityCompanyId',
                templateUrl: 'app/admin/configuration/utilityCompany/details/details.html',
                controller: 'UtilityCompanyDetailsCtrl as vm',
                data: {
                    breadcrumb: 'Utility Company'
                },
                resolve: {
                    utilityCompanyId: ($stateParams: ng.ui.IStateParamsService): String => {
                        return $stateParams['utilityCompanyId'];
                    },
                    utilityCompany: (RestangularV3: restangular.IService, utilityCompanyId, waitForAuthToken) => {
                        return RestangularV3.one('utility-companies', utilityCompanyId).get();
                    },
                    utilityAccounts: (RestangularV3: restangular.IService, utilityCompanyId, waitForAuthToken) => {
                        return RestangularV3.all('utility-accounts')
                            .all('for-utility-company')
                            .getList({ utilityCompanyId, onlyErrors: true });
                    },
                    tariffSchedules: (RestangularV3: restangular.IService, utilityCompanyId, waitForAuthToken) => {
                        return RestangularV3.all('energy-tariff-schedules').getList({ utilityCompanyId });
                    }
                }
            })
            .state('aq.admin.configuration.utilityCompany.details.buildings', {
                url: '/buildings',
                templateUrl: 'app/admin/configuration/utilityCompany/details/buildings.html',
                controller: 'UtilityCompanyBuildingsCtrl as vm',
                data: {
                    breadcrumb: 'Buildings'
                },
                resolve: {
                    utilityCompanyBuildings: (RestangularV3: restangular.IService, $stateParams: ng.ui.IStateParamsService, waitForAuthToken) => {
                        return RestangularV3.one('utility-companies', $stateParams['utilityCompanyId'])
                            .customGET('buildings');
                    }
                }
            })
            .state('aq.admin.configuration.utilityCompany.details.tariffSchedule', {
                url: '/tariff/:tariffId',
                templateUrl: 'app/admin/configuration/utilityCompany/tariffSchedule/details/detail.html',
                controller: 'TariffScheduleDetailsCtrl as tariffScheduleDetailsCtrl',
                data: {
                    breadcrumb: 'Tariff Schedule'
                },
                resolve: {
                    tariffBase: (Restangular: restangular.IService, $stateParams: ng.ui.IStateParamsService, waitForAuthToken) => {
                        return Restangular.one('EnergyTariffSchedules', $stateParams['tariffId']);
                    },
                    tariffSchedule: (tariffBase: restangular.IElement, waitForAuthToken) => {
                        return tariffBase.get({ inflate: 'charges,charges.tariffRule' });
                    },
                    chargeIntervals: (Restangular: restangular.IService, waitForAuthToken) => {
                        return Restangular.one('options', 'chargeIntervals')
                            .getList();
                    }
                }
            });
    });
