
namespace aq.deviceManagement {
    export class DeviceOverviewCtrl {
        static STATUS_INTERVAL = 180000;
        public goToCategory: (category: any) => void;
        private categories: {[index: string]: aq.common.models.Device[]};
        private allDevicesLength: number;
        private dataStatuses: aq.common.models.DataStatus[];
        private emptyCategories: boolean;
        private emptySearch: boolean;
        private emptyFilter: boolean;
        private currentNavItem: string;
        private dataStatusesById: {[id: number]: aq.common.models.DataStatus};
        private updateStatusInterval: ng.IPromise<any>;
        private filtered: aq.common.models.Device[];
        private commissioningStatus;
        private filter = {
            selected: { label: 'None', value: 'NONE' },
            options: [
                { label: 'None', value: 'NONE' },
                { label: 'Offline', value: 'OFFLINE' },
                { label: 'Online', value: 'ONLINE' },
                { label: 'Never Reported', value: 'NEVER_REPORTED' },
                { label: 'Waiting for Connection', value: 'WAITING' },
                { label: 'Not Web Enabled', value: 'NOT_WEB_ENABLED' }
            ]
        };
        private fetchingDevices: boolean;
        private offset: number;
        private limit: number = 25;
        private fetchedAll: boolean;
        private query: string;

        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private devices: aq.common.models.Device[],
            private building: aq.common.models.Building,
            private $state: ng.ui.IStateService,
            private $stateParams: ng.ui.IStateParamsService,
            private $mdDialog: ng.material.IDialogService,
            private RestangularV3: restangular.IService,
            private DataStore: aq.common.DataStore,
            private categoryCounts: { [category: string]: number },
            private newLayout: boolean,
            private tenants,
            private measures,
            private floors,
            private spaces,
            private tags,
            private deviceCategories,
            private classesByCategory: aq.common.models.DeviceClassNameByCategory,
            private $location: ng.ILocationService,
            private DeviceService: aq.services.DeviceService,
            private $mdMedia: ng.material.IMedia,
            private Messages: aq.services.Messages,
            private $interval: ng.IIntervalService,
            private $q: ng.IQService,
            private UserService: aq.services.UserService
        ) {
            this.fetchingDevices = false;
            this.fetchedAll = this.devices.length < this.limit;
            this.offset = this.limit; // assumes we've already fetched our first 25 devices in the resolve, so start from here
            this.filtered = devices;
            this.allDevicesLength = _.sum(_.values(this.categoryCounts));
            this.categories = this.categorizeDevices(devices);
            this.currentNavItem = this.$stateParams['cat'] || (this.getCategories()[0] || 'METER');
            this.dataStatusesById = {};
            this.commissioningStatus = {};
            this.updateDataStatuses();
            this.resetInterval();
            $scope.$on('$destroy', () => {
                this.$interval.cancel(this.updateStatusInterval);
            });
            this.goToCategory = (category) => {
                if (this.currentNavItem !== category.value) {
                    this.currentNavItem = category.value;
                    this.$location.search({cat: category.value});
                    this.resetDevices();
                    this.updateDataStatuses();
                    this.resetInterval();
                }
            };
        }

        public updateDataStatuses() {
            // Separate these queries out since our commissioning status query is so slow
            this.RestangularV3.all('devices')
                .customGET('data-status', {buildingId: this.building.id, category: this.currentNavItem, ts: moment().valueOf()})
                .then((dataStatuses) => {
                    this.dataStatuses = dataStatuses;
                    this.dataStatusesById = _.keyBy(dataStatuses, 'id');
                })
                .catch(() => {
                    this.dataStatusesById = {};
                    this.Messages.error('Error during data query.  Reload page');
                });
        }

        public fetchCommissioning() {
            this.RestangularV3.one('devices')
                .one('commissioning')
                .customGET('status', {
                    buildingId: this.building.id,
                    offset: this.offset,
                    limit: this.limit,
                    category: this.currentNavItem || 'METER',
                    ...this.getSearchParams()
                })
                .then((commissioningStatus) => {
                    this.commissioningStatus = {
                        ...this.commissioningStatus,
                        ...commissioningStatus
                    };
                });
        }

        public saveDevice(device: aq.common.models.DeviceCreationRequest): ng.IPromise<aq.common.models.DeviceElement> {
            device.building = this.building.id;
            return this.DeviceService.create(device);
        }

        public resetDevices() {
            this.fetchedAll = false;
            this.offset = 0;
            this.devices = [];
            this.categories = this.categorizeDevices(this.devices);
            const queryParams: any = {
                buildingId: this.building.id,
                ...this.getSearchParams()
            };
            this.RestangularV3.all('devices')
                .customGET('counts-by-category', queryParams)
                .then((counts) => {
                    this.categoryCounts = counts;
                });
            return this.nextPage();
        }

        public resetInterval() {
            if (this.updateStatusInterval) {
                this.$interval.cancel(this.updateStatusInterval);
            }
            this.updateStatusInterval = this.$interval(() => this.updateDataStatuses(), DeviceOverviewCtrl.STATUS_INTERVAL);
        }

        createDevice(createAnother: boolean): void {
            // have to use any because the type def is out of date and missing the 'multiple' field
            (this.$mdDialog as any).show({
                controller: 'AddDevice as vm',
                templateUrl: 'app/deviceManagement/actions/addDevice/addDevice.html',
                clickOutsideToClose: false,
                multiple: true,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    deviceCategories: this.deviceCategories,
                    classesByCategory: this.classesByCategory,
                    tenants: this.tenants,
                    buildingId: this.building.id,
                    RestangularV3: this.RestangularV3,
                    tags: this.tags,
                    createAnother,
                    measures: this.measures,
                    devices: this.devices,
                    building: this.building
                }
            }).then((response) => {
                const {
                    newDevice,
                    createAnother,
                    createAssociation
                } = response.data;

                if (createAssociation) {
                    this.addAssocation(newDevice);
                    return;
                }
                if (createAnother) {
                    this.createDevice(createAnother);
                    return;
                }
                this.editDevice(newDevice.id);
            });
        }

        bulkUploadDevices(createAnother: boolean): void {
            (this.$mdDialog as any).show({
                controller: 'BulkUploadCtrl as vm',
                templateUrl: 'app/deviceManagement/actions/bulkUpload/bulkUpload.html',
                clickOutsideToClose: false,
                escapeToClose: false,
                multiple: true,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    deviceCategories: this.deviceCategories,
                    classesByCategory: this.classesByCategory,
                    tenants: this.tenants,
                    buildingId: this.building.id,
                    RestangularV3: this.RestangularV3,
                    tags: this.tags,
                    createAnother,
                    measures: this.measures,
                    devices: this.devices,
                    building: this.building
                }
            }).then((response) => {
                this.$state.reload();
            });
        }

        public editDevice(deviceId: number | string): void {
            this.$state.go('aq.deviceManagement.building.device.configuration', { deviceId, cat: undefined, dataStatuses: this.dataStatuses });
        }

        public goToMapping(): void {
            this.$state.go('aq.deviceManagement.building.mapping', { });
        }

        public searchBy(query: string): void {
            this.query = query;
            this.emptySearch = false;
            this.resetDevices()
                .then(() => {
                    this.emptySearch = (this.devices.length === 0 && this.allDevicesLength > 0);
                    this.emptyCategories = Object.keys(this.categories).length === 0;
                });
        }

        public filterBy() {
            this.emptyFilter = false;
            this.resetDevices()
                .then(() => {
                    this.emptyFilter = (this.devices.length === 0 && this.allDevicesLength > 0);
                });
        }

        public getSearchParams() {
            const params: any = {};
            if (this.query) {
                params.query = this.query;
            }
            if (this.filter.selected.value !== 'NONE') {
                params.filter = this.filter.selected.value;
            }
            return params;
        }

        public nextPage() {
            if (!this.fetchingDevices && !this.fetchedAll) {
                this.fetchingDevices = true;
                const queryParams: any = {
                    buildingId: this.building.id,
                    offset: this.offset,
                    limit: this.limit,
                    category: this.currentNavItem || 'METER',
                    ...this.getSearchParams()
                };
                this.fetchCommissioning();
                return this.DataStore.getList(this.RestangularV3.one(''), 'devices', queryParams)
                    .then((results) => {
                        this.devices = [...this.devices, ...results];
                        this.categories = this.categorizeDevices(this.devices);
                        this.offset = this.offset + this.limit;
                        this.fetchingDevices = false;
                        if (results.length < this.limit) {
                            this.fetchedAll = true;
                        } else {
                            this.fetchedAll = false;
                        }
                    });
            } else {
                return this.$q.resolve();
            }
        }

        private isCategoryEmpty(category: string) {
            const cat = this.categorizeDevices(this.devices)[category];
            return !(cat && cat.length > 0);
        }

        private addAssocation(device) {
            this.DataStore.get(device.all('available-links'))
            .then((availableLinks) => {
                return (this.$mdDialog as any).show({
                    controller: 'AddDeviceLink as vm',
                    templateUrl: 'app/deviceManagement/actions/addDeviceLink/addDeviceLink.html',
                    clickOutsideToClose: false,
                    multiple: true,
                    openFrom: '#entity-actions',
                    closeTo: '#entity-actions',
                    fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                    locals: {
                        device,
                        createLink: false,
                        links: availableLinks,
                        showCreateAnother: false
                    }
                });
            })
            .then((model: {link: aq.deviceManagement.Link, createLink: boolean}) => {
                return this.DataStore.create(this.RestangularV3.all('devices').all('links'), model.link);
            })
            .then((model: {link: aq.deviceManagement.Link, createLink: boolean}) => {
                this.Messages.success('New link created');
                this.editDevice(device.id);
            });
        }

        private foundIn(searchInArray: string[], searchFor: string): boolean {
            return searchInArray.some((searchIn) => {
                if (searchIn && _.includes(searchIn.toUpperCase(), searchFor.toUpperCase())) {
                    return true;
                }
            });
        }

        private categorizeDevices(devices: aq.common.models.Device[]): { [index: string]: aq.common.models.Device[] } {
            return _.groupBy(_.sortBy(devices, 'name'), 'deviceCategory');
        }

        private getCategories(): string[] {
            return _.sortBy(_.keys(this.categories), this.categoryOrder);
        }

        private categoryOrder(category: aq.common.models.DeviceCategory): number {
            switch (category) {
                case 'METER': return 1;
                case 'NETWORKING': return 2;
                case 'SENSOR': return 3;
                case 'EQUIPMENT': return 4;
                case 'OTHER': return 5;
                default: return 6;
            }
        }

    }

    angular
        .module('deviceManagement')
        .controller('DeviceOverviewCtrl', DeviceOverviewCtrl);
}
