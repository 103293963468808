namespace aq.dashboard.widgets {
    declare var Highcharts: __Highcharts.Static;
    import AidActions = aq.models.segment.AidActions;

    export class _AidIssue {
        public issue: aq.models.aidIssue.IssueResponse;
        public callToActionMessage: string;
        public isAcknowledged: boolean = false;
        public isBeingAcknowledged: boolean = false;
        public isEditMode: boolean = false;
        public isBeingSaved: boolean = false;
        public allEventTypes: IRadioOption[];
        public allPotentialCauses: IRadioOption[];
        public selectedPotentialCause: string;
        public customPotentialCause: string;

        public issueHighlightSeries: __Highcharts.SeriesOptions;
        public energySeries: __Highcharts.SeriesOptions;
        public temperatureSeries: __Highcharts.SeriesOptions;

        private building: aq.common.models.Building;
        private account: aq.common.models.Account;
        private currentUserId: number;
        private issueFeedbackForm;
        private otherString: string = 'other';
        private aqPotentialCauseList: string[] = [
            'not an issue',
            'early start',
            'equipment cycling/noise',
            'equipment maintenance/repair',
            'equipment/BMS error',
            'freeze protection',
            'late shutdown',
            'missed shutdown',
            'tenant request',
            'unoccupied hour temperature setback for cooling',
            'unoccupied hour temperature setback for heating',
            'unscheduled equipment running'
        ];

        private chartDataParams: IChartDataParams = {
            measure: 'electricity',
            unit: 'kW',
            start: moment().subtract(3, 'days').startOf('day').toISOString(),
            end: moment().toISOString(),
            interval: '15min'
        };
        private isChartDataLoading: boolean = true;
        private chartData;
        private chartConfig: __Highcharts.Options;
        private chartMinTime: moment.Moment = moment().subtract(2, 'days').startOf('day');
        private chartMaxTime: moment.Moment = moment().add(2, 'days').endOf('day');
        private chartMinTemp: number = 0;
        private chartMaxTemp: number = 100;
        private chartColors: any = {
            issueHighlight: 'rgba(255,40,40,.50)',
            energy: 'rgb(28,108,171)',
            temperature: 'rgb(217,223,32)'
        };


        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private $translate,
            private RestangularV3: restangular.IService,
            private resolver: aq.services.Resolver,
            private Messages: aq.services.Messages,
            private UserService: aq.services.UserService,
            private Segment: aq.services.SegmentService,
            private AidIssueService: aq.services.AidIssueService,
            public $window: ng.IWindowService
        ) {
        }

        public $onInit() {
            this.isAcknowledged = (this.issue.feedback && this.issue.feedback.length > 0);
            this.isChartDataLoading = true;
            this.currentUserId = this.UserService.currentUser.id;
            this.initCallToAction();
            this.initSeriesBounds();
            this.chartDataParams.start = this.chartMinTime.toISOString();
            this.chartDataParams.end = this.chartMaxTime.toISOString();

            this.resolver(async () => {
                try {
                    this.chartData = await this.RestangularV3.all(`buildings/${this.building.id}`).customGET('raw', this.chartDataParams);
                    this.energySeries = this.buildElectricitySeries(this.chartData[this.chartDataParams.unit.toLowerCase()].data);
                    this.issueHighlightSeries = this.buildIssueHighlightSeries(this.issue.highlightedArea);
                    this.temperatureSeries = this.buildTemperatureSeries(this.issue.series);

                    this.chartConfig = this.buildChartConfig();
                    this.isChartDataLoading = false;
                    this.$scope.$apply();
                } catch (err) {
                    this.isChartDataLoading = false;
                    this.Messages.error('Failed to load chart data for chart');
                    throw err;
                }
            });
        }

        public getEventTypes(): IRadioOption[] {
            if (!this.allEventTypes) {
                this.allEventTypes = [];
                _.each(aq.models.aidIssue.EventType, (type) => {
                    const option: IRadioOption = {
                        label: _.startCase(_.lowerCase(type)),
                        value: type
                    };
                    this.allEventTypes.push( option );
                });
            }
            return this.allEventTypes;
        }

        public getPotentialCauses(): IRadioOption[] {
            if (!this.allPotentialCauses) {
                this.allPotentialCauses = [];
                _.each([...this.aqPotentialCauseList, this.otherString], (cause) => {
                    const option: IRadioOption = {
                        label: _.startCase(cause),
                        value: cause
                    };
                    this.allPotentialCauses.push( option );
                });
            }
            return this.allPotentialCauses;
        }

        public makeIssueUpdates() {
            this.isEditMode = true;
        }

        public cancelIssueUpdates() {
            this.isEditMode = false;
        }

        public onPotentialCauseChange() {
            if (this.otherString !== this.selectedPotentialCause) {
                this.customPotentialCause = null;
            }
        }

        public saveIssueUpdates() {
            if (this.otherString === this.selectedPotentialCause) {
                this.customPotentialCause = _.trim(this.customPotentialCause);
            }

            const feedbackRequest = new aq.models.aidIssue.IssueFeedbackCreateRequest();
            feedbackRequest.issue = this.issue.id;
            feedbackRequest.user = this.currentUserId;
            feedbackRequest.actionType = aq.models.aidIssue.ActionType.MODIFY;
            feedbackRequest.eventType = this.issue.eventType;

            if (_.includes(this.aqPotentialCauseList, this.selectedPotentialCause)) {
                feedbackRequest.potentialCause = this.selectedPotentialCause;
            } else {
                feedbackRequest.potentialCause = this.customPotentialCause;
            }

            this.resolver(async () => {
                try {
                    this.isBeingSaved = true;
                    const feedbackResponse = await this.AidIssueService.createFeedback(feedbackRequest);
                    this.Segment.trackChurnZeroEvent(AidActions.MODIFY_AID_ISSUE, [this.building]);
                    this.Messages.success('Successfully updated issue');
                    this.issue.feedback.push(feedbackResponse);
                    this.issueFeedbackForm.$setPristine();
                    this.initCallToAction();
                    this.isBeingSaved = false;
                    this.isEditMode = false;
                    this.isAcknowledged = true;
                    this.$scope.$apply();
                } catch (err) {
                    this.isBeingSaved = false;
                    this.Messages.error('Something went wrong updating this issue!');
                    throw err;
                }
            });
        }

        public acknowledgeIssue() {
            const feedbackRequest = new aq.models.aidIssue.IssueFeedbackCreateRequest();
            feedbackRequest.issue = this.issue.id;
            feedbackRequest.user = this.currentUserId;
            feedbackRequest.actionType = aq.models.aidIssue.ActionType.ACKNOWLEDGE;

            this.resolver(async () => {
                try {
                    this.isBeingAcknowledged = true;
                    await this.AidIssueService.createFeedback(feedbackRequest);
                    this.Segment.trackChurnZeroEvent(AidActions.ACKNOWLEDGE_AID_ISSUE, [this.building]);
                    this.isBeingAcknowledged = false;
                    this.isAcknowledged = true;
                    this.Messages.success('Successfully acknowledged issue');
                    this.$scope.$apply();
                } catch (err) {
                    this.isBeingAcknowledged = false;
                    this.Messages.error('Something went wrong acknowledging this issue!');
                    throw err;
                }
            });
        }
        public getOptimizationDates (issue) {
            if (issue.date) {
                return {
                    startDate : moment(issue.date).startOf('day').subtract(1, 'days'),
                    endDate : moment(issue.date).startOf('days').add(2, 'days')
                };
            }
            return {
                startDate: moment().startOf('days').subtract(1, 'days'),
                endDate: moment().startOf('days').add(2, 'days')
            };
        }

        public goToOptimization() {
            const {startDate, endDate} = this.getOptimizationDates(this.issue);

            this.account.get({ single: true }).then((thisAccount) => {
                const optimizationURI = URI('/accounts/' + thisAccount.id + '/optimization/building')
                    .search({
                        unit: 'kW',
                        apiUnit: 'POWER',
                        interval: '15min',
                        off: _.without(thisAccount.buildings, this.building.id).join(','),
                        start: startDate.toISOString(),
                        end: endDate.toISOString(),
                        children: 'buildings'
                    });
                window.open(optimizationURI.toString());
            });
        }

        private initCallToAction() {
            if (this.issue && this.issue.potentialCause) {
                let fbMsg: string = null;
                if (this.issue.feedback && this.issue.feedback.length > 0) {
                    fbMsg = this.getLatestFeedbackPotentialCause(this.issue.feedback);
                }
                const latestCause = fbMsg || this.issue.potentialCause;

                if (_.includes(this.aqPotentialCauseList, latestCause)) {
                    this.selectedPotentialCause = latestCause;
                } else {
                    this.selectedPotentialCause = this.otherString;
                }
                this.callToActionMessage = `Was this ${latestCause}?`;
            }
        }

        private getLatestFeedbackPotentialCause(feedbackList): string {
            let result: string = null;
            const filteredFeedback = _.filter(feedbackList, (f) => { return !(!f.potentialCause); } );
            if (filteredFeedback.length > 0) {
                const orderedFeedback: aq.models.aidIssue.IssueFeedbackResponse[] =
                    _.sortBy(filteredFeedback, [(f) => { return moment(f.created).valueOf(); }]).reverse();
                result = orderedFeedback[0].potentialCause;
            }

            return result;
        }

        private initSeriesBounds() {
            this.chartMinTime = moment.parseZone(_.minBy(this.issue.series, s => moment.parseZone(s.time)).time);
            this.chartMaxTime = moment.parseZone(_.maxBy(this.issue.series, s => moment.parseZone(s.time)).time);

            const minTemp = _.minBy(this.issue.series, s => s.temperature).temperature;
            this.chartMinTemp = this.roundToNearest(minTemp, 5, true);

            const maxTemp = _.maxBy(this.issue.series, s => s.temperature).temperature;
            this.chartMaxTemp = this.roundToNearest(maxTemp, 5);
        }

        private buildElectricitySeries(data): __Highcharts.SeriesOptions {
            const seriesDataArray: __Highcharts.DataPoint[] = [];
            data.forEach(point => {
                const seriesDataPoint: __Highcharts.DataPoint = {
                    x: point.timestamp,
                    y: point.datum
                };
                seriesDataArray.push(seriesDataPoint);
            });
            const series: __Highcharts.SeriesOptions = {
                name: this.building.name,
                data: seriesDataArray,
                color: '#0091f1'
            };
            return series;
        }

        private buildIssueHighlightSeries(datum: aq.models.aidIssue.HighlightAreaDatum): __Highcharts.SeriesOptions {
            const issueSelectionRectangle: __Highcharts.DataPoint[] = [];
            const min = datum.min_val;
            const max = datum.max_val;
            const start = moment(datum.time_start).valueOf();
            const end = moment(datum.time_end).valueOf();

            issueSelectionRectangle.push({
                x: start,
                y: min
            });
            issueSelectionRectangle.push({
                x: end,
                y: min
            });
            issueSelectionRectangle.push({
                x: end,
                y: max
            });
            issueSelectionRectangle.push({
                x: start,
                y: max
            });

            const selectionSeries: __Highcharts.SeriesOptions = {
                name: 'Issue Detected',
                type: 'polygon',
                tooltip: null,
                data: issueSelectionRectangle,
                color: this.chartColors.issueHighlight,
                zIndex: 0,
                showInLegend: false
            };
            return selectionSeries;
        }

        private buildTemperatureSeries(datum: aq.models.aidIssue.SeriesDatum[]): __Highcharts.SeriesOptions {
            // FIXME: AQ-6081 - Need endpoint for weather instead of getting from series ouput
            const seriesData: __Highcharts.DataPoint[] = [];
            _.each(this.issue.series, (seriesDatum: aq.models.aidIssue.SeriesDatum) => {
                const xPointValue: number = moment.parseZone(seriesDatum.time).valueOf();
                const temperaturePoint: __Highcharts.DataPoint = {
                    y: seriesDatum.temperature,
                    x: xPointValue
                };
                seriesData.push(temperaturePoint);
            });

            const series: __Highcharts.SeriesOptions = {
                data: seriesData,
                name: this.$translate.instant('Temperature'),
                type: 'line',
                color: this.chartColors.temperature,
                dashStyle: 'ShortDash',
                states: {
                    hover: { enabled: true }
                },
                yAxis: 1
            };
            return series;
        }

        private buildChartConfig(): __Highcharts.Options {
            const series: __Highcharts.SeriesOptions[] = [];
            series.push(this.issueHighlightSeries);
            series.push(this.temperatureSeries);
            series.push(this.energySeries);

            const getContext = () => this;
            const result: __Highcharts.Options = {
                chart: {
                    animation: false,
                    plotBorderWidth: 1,
                    type: 'line'
                },
                title: { text: null },
                subtitle: { text: null },
                legend: { enabled: false },
                tooltip: {
                    shared: true,
                    // tslint:disable-next-line:object-literal-shorthand
                    formatter: function (): string {
                        try {
                            const context = getContext();
                            const dateStr: string = moment(this.x).tz(context.building.timeZoneId).format('lll');
                            const tooltipMarkup: string[] = [];
                            tooltipMarkup.push(`${dateStr}`);
                            _.each(this.points, (p) => {
                                const isTempData = (_.toLower(p.series.name) === 'temperature');
                                // tslint:disable-next-line:max-line-length
                                tooltipMarkup.push(`<span style="color:${p.series.color}">\u25CF</span> ${p.series.name}: <b>${p.y.toFixed(0)} ${isTempData ? '\u00B0F' : 'kW'}</b></span>`);
                            });
                            return tooltipMarkup.join('<br/>');
                        } catch (err) {
                            return null;
                        }
                    }
                },
                xAxis: {
                    type: 'datetime',
                    startOfWeek: 0, // Sunday
                    tickInterval: 6 * 3600 * 1000, // 6-hr increments
                    labels: {
                        formatter() {
                            const context = getContext();
                            return moment(this.value).tz(context.building.timeZoneId).format('ddd MMM D<br>h:mma');
                        },
                        rotation: -45
                    },
                    tickLength: 0,
                    gridLineWidth: 1,
                    gridLineColor: '#c7c7c7'
                },
                yAxis: [
                    {
                        title: { text: 'Issue Detected' },
                        gridLineWidth: 0,
                        minorGridLineWidth: 0
                    },
                    {
                        title: { text: 'Temperature \u00B0F' },
                        min: this.chartMinTemp,
                        max: this.chartMaxTemp,
                        opposite: true,
                        gridLineWidth: 0,
                        minorGridLineWidth: 0
                    },
                    { // Building energy
                        min: 0,
                        title: {
                            text: this.chartDataParams.unit,
                            style: {
                                fontSize: '14px'
                            }
                        },
                        labels: {
                            style: {
                                fontSize: '14px'
                            }
                        }
                    }
                ],
                series,
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                }
            };
            return result;
        }

        private roundToNearest(val: number, roundBy: number, roundDown: boolean = false) {
            let result = val;
            if (roundDown) {
                result = Math.floor((result - 0.01) / roundBy) * roundBy;
            } else {
                result = Math.ceil((result + 0.01) / roundBy) * roundBy;
            }
            return result;
        }
    }

    export const AidIssue: ng.IComponentOptions = {
        controller: _AidIssue,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/common/widgets/aidIssue/aidIssueView.html',
        bindings:  {
            issue: '<',
            building: '<',
            account: '<'
        }
    };

    angular
        .module('aq.dashboard.widgets')
        .component('aidIssue', AidIssue);
}

interface IRadioOption {
    label: string;
    value: aq.models.aidIssue.EventType;
}

interface IChartDataParams {
    measure: string;
    unit: string;
    start: string;
    end: string;
    interval: string;
};
