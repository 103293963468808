namespace aq.dashboard {
    // TODO: do a real extends instead of this weak-ass thing I've got here
    // will involve converting OptionsService.js to typescript
    export class DashboardOptionsService {
        /* @ngInject */
        constructor (
            private OptionsService,
            private $translate
        ) {

        }

        public init = (accountId, measurementSystemEnum, currencyUnitEnum) => {
            return this.OptionsService.init(accountId, measurementSystemEnum, currencyUnitEnum);
        }

        /**
          * Sort and filter out some of our unwanted units.
          *
          * Some of our filter conditions:
          * - Don't want water measured in liters, only gallons
          * - Don't want any subunits, only the main ones (dollars instead of cents, etc.)
          * - Ignore BTU units for certain service types (ELECTRICITY and GAS)
          *     - the only BTUs we care about are for HEAT
          * Sort by service type then currency/flow rate
          */
        public getUnits = (withTemperature?: boolean) => {
            var defaultApiUnits = ['energy', 'currency_electricity', 'currency_gas', 'currency_water', 'power'];
            var metricsSupported = ['GAS', 'WATER', 'STEAM', 'HEAT', 'ENVIRONMENT'];
            var ignoreBTUServiceTypes = ['ELECTRICITY', 'GAS'];
            var allNonConvertedUnits = _.filter(this.OptionsService.getAllUnits(), function (unit: any) {
                return !unit.converted
                    && (_.includes(defaultApiUnits, unit.apiUnit.toLowerCase())
                        || _.includes(metricsSupported, unit.serviceType)
                        || (withTemperature && unit.apiUnit == 'TEMPERATURE')
                    )
                    && unit.value != 'WATER_L'
                    && !_.includes(unit.value, '_SUBUNIT_')
                    && (!_.includes(ignoreBTUServiceTypes, unit.serviceType)
                        || (_.includes(ignoreBTUServiceTypes, unit.serviceType)
                            && !_.includes(unit.value, 'BTU')
                        )
                    );
            });
            allNonConvertedUnits = _.sortBy(allNonConvertedUnits, [
                'serviceType',
                (unit) => {
                    return unit.apiUnit.indexOf("CURRENCY") == 0 || unit.apiUnit !== "FLOW_RATE" ? 1 : 0;
                }
            ]);

            return allNonConvertedUnits;
        }

        /**
          * Take our units and index them by their service type in the order they come in.
          *
          * units: [
          *   { serviceType: 'ELECTRICITY', unit: 'kWh' },
          *   { serviceType: 'ELECTRICITY', unit: 'kW' },
          *   { serviceType: 'WATER', unit: 'Gal' }
          * ]
          *
          * returns: {
          *     ELECTRICITY: [units[0], units[1]],
          *     WATER: [units[2]]
          * }
          */
        public organizeUnitsByServiceType = (units) => {
            let unitsMap = {};
            _.forEach(units, (unit) => {
                let unitType = unit.serviceType ? unit.serviceType : unit.apiUnit;
                if (!unitsMap[unitType]) {
                    unitsMap[unitType] = [];
                }
                unitsMap[unitType].push(unit);
            });
            return unitsMap;
        }

        public getUnitByEnumName = (name) => {
            return this.OptionsService.getUnitByEnumName(name);
        }
    }

    angular.module('dashboard').service('DashboardOptionsService', DashboardOptionsService);
}
