/***
 * ng-translude inside ng-repeat is not supported in new angular so this is just
 * temporarily workaround until we figure out best solution
 * for cases where ng-translude was used inside ng-repeat
 */
angular.module('aq.ui').directive('transX', function() {
    return {
    link: {
            pre: function(scope, element, attr, ctrl, transclude) {
                if (transclude) {
                    transclude(scope, function(clone) {
                        element.append(clone);
                    });
                }
            }
        }
    };
});