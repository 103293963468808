angular
        .module('dashboard')
        .directive('aqDonutChartV2', () => {
     return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/dashboard/common/directives/donutchartv2/donutchart.html',
        scope: {
                backgroundColor: '@',
                emptyColor: '@',
                percentColor: '<',
                textColor: '<',
                fontSize: '@',
                percent: '<',
                percentOuter: '@',
                widgetIcon: '@'
        },
        link: ($scope: any) => {
            // determine fill for donut chart
            const strokeLength360 = 2 * 3.14 * 38;
            const strokeLength360Outer = 2 * 3.14 * 48;
            let strokeSize = strokeLength360 * $scope.percent / 100;
            //if outer line is present, show fill relative to it
            if ($scope.percentOuter) {
                strokeSize *= $scope.percentOuter / 100;
            }
            $scope.strokeOffset = strokeLength360 * 0.25;
            $scope.strokeDasharray = `${strokeSize}, ${strokeLength360 - strokeSize}`;

            const strokeSizeOuter = $scope.percentOuter ? strokeLength360Outer * $scope.percentOuter / 100 : 0;
            $scope.strokeOffsetOuter = strokeLength360Outer * 0.25;
            $scope.strokeDasharrayOuter = `${strokeSizeOuter}, ${strokeLength360Outer - strokeSizeOuter}`;
        }
     };
});
