angular.module('aq.admin.meters.devices', ['ui.router', 'aq.services.restangular', 'aq.services', 'aq.services.errors', 'aq.messages'])
    .config((RestangularProvider: restangular.IService, $stateProvider) => {
        $stateProvider.state('aq.admin.meters.devices', {
            url: '/devices',
            templateUrl: 'app/admin/meters/devices/main.html',
            controller: 'AdminDevicesCtrl as vm',
            resolve: {
                deviceClasses: (RestangularV3, DataStore: aq.common.DataStore, waitForAuthToken) => {
                    return DataStore.getList(RestangularV3.one('devices'), 'device-classes')
                        .then((result) => _.map(result, (item) => {
                            return {
                                id: item.id,
                                vendor: item.vendor ? item.vendor : '',
                                series: item.series ? item.series : '',
                                model: item.model ? item.model : ''
                            };
                        }));
                }
            },
            data: {
                breadcrumb: 'Devices',
                icon: '.aq-icons-meter-management'
            }
        });
        $stateProvider.state('aq.admin.meters.devices.forDeviceClass', {
            url: '/for-device-class/:deviceClassId',
            templateUrl: 'app/admin/meters/devices/adminDevicesDetails.html',
            controller: 'AdminDevicesDetailsCtrl as vm',
            resolve: {
                deviceClass: (RestangularV3, $stateParams, waitForAuthToken) => {
                    return RestangularV3.one('devices').one('device-classes', $stateParams.deviceClassId).get()
                        .then((data) => {
                            return {
                                id: data.id,
                                vendor: data.vendor ? data.vendor : '',
                                series: data.series ? data.series : '',
                                model: data.model ? data.model : ''
                            };
                        });
                },
                deviceClassStatistics: (RestangularV3: restangular.IService, $stateParams, waitForAuthToken) => {
                    return RestangularV3.one('internal').one('devices').one('offline-statistics')
                        .get({ deviceClass: $stateParams.deviceClassId })
                        .then((data) => {
                            return data[parseInt($stateParams.deviceClassId)];
                        });
                },
                devices: (RestangularV3: restangular.IService, $stateParams, waitForAuthToken) => {
                    return RestangularV3.one('internal').all('devices')
                        .getList({ deviceClass: $stateParams.deviceClassId });
                },
                accounts: (Restangular: restangular.IService, waitForAuthToken) => {
                    return Restangular.all('accounts').get('queryAllAccountNames');
                }
            },
            data: {
                breadcrumb: 'Device Class Drill-in',
                icon: '.aq-icons-meter-management'
            }
        });
    });
