angular.module('aq.filters', ['aq.filters.tools','aq.filters.arrays','aq.filters.color',
	'aq.filters.formatters','aq.filters.time','aq.filters.transformers','aq.filters.units']);

angular.module('aq.filters.arrays', []);

angular.module('aq.filters.color', []);

angular.module('aq.filters.formatters', []);

angular.module('aq.filters.math', []);

angular.module('aq.filters.tools', []);

angular.module('aq.filters.time', ['aq.services.user']);

angular.module('aq.filters.transformers', ['aq.ui', 'aq.ui.color']);

angular.module('aq.filters.units', []);