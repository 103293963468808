namespace aq.utilityBudgets {

    export class UtilityServiceDetailsInfo {
        public currentNavItem: string;
        public measureTypes: MeasureType[];
        public utilityCompaniesMap: IMeasureArrayMap;

        /* @ngInject */
        constructor(
            public tariffSchedules: aq.admin.configuration.utilityCompany.TariffSchedule[],
            private utilityAccount: UtilityAccount,
            private utilityService: UtilityService,
            private utilityServices: UtilityService[],
            private accountId: string,
            private buildingId: string,
            private utilityCompanies,
            private measures: aq.common.models.Measure[],
            private UtilityServiceHelper: UtilityServiceHelper,
            private readOnlyForm: boolean
        ) {
            this.currentNavItem = 'info';
            this.utilityCompaniesMap = this.UtilityServiceHelper.getUtilityCompaniesMeasureMap(this.utilityCompanies);
            this.measureTypes = this.UtilityServiceHelper.getMeasureTypes();
            const measure: aq.common.models.Measure = _.find(this.measures, (m) => {
                return m.name.toUpperCase() === this.utilityService.type.toUpperCase();
            });
            this.tariffSchedules = this.tariffSchedules.filter((tariffSchedule) => {
                return tariffSchedule.measure == measure.id;
            });
        };

        public isSelectedTab(name) {
            return this.currentNavItem == name;
        }
    }
    angular.module('aq.utilityBudgets').controller('UtilityServiceDetailsInfo', UtilityServiceDetailsInfo);
}
