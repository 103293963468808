namespace aq.ui {

    class _ActivityIssueHistoryItem {
        public openedOn: string;
        public closedOn: string;

        private item: {
            openedOn: string;
            closedOn: string;
        };

        /* @ngInject */
        constructor() {
            this.openedOn = `Opened on: ${moment(this.item.openedOn).format('MMM Do YYYY, h:mm a')}`;
            this.closedOn = `Closed on: ${moment(this.item.closedOn).format('MMM Do YYYY, h:mm a')}`;
        }


    }

    export const ActivityIssueHistoryItem: ng.IComponentOptions = {
        controller: _ActivityIssueHistoryItem,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/activities/issueHistory/history-item.html',
        bindings: {
            item: '<'
        }
    };

    angular
        .module('aq.ui')
        .component('activityIssueHistoryItem', ActivityIssueHistoryItem);
}
