var aq;
(function (aq) {
    var tenantbilling;
    (function (tenantbilling) {
        var AddEditTenantServiceFeeCtrl = /** @class */ (function (_super) {
            __extends(AddEditTenantServiceFeeCtrl, _super);
            /* @ngInject */
            function AddEditTenantServiceFeeCtrl($mdDialog, serviceFee, serviceMethod, tenantServices, billingSetupId, utilityServices, TenantBillingSetupService, OptionsService, Auth) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.serviceFee = serviceFee;
                _this.serviceMethod = serviceMethod;
                _this.tenantServices = tenantServices;
                _this.billingSetupId = billingSetupId;
                _this.utilityServices = utilityServices;
                _this.TenantBillingSetupService = TenantBillingSetupService;
                _this.OptionsService = OptionsService;
                _this.Auth = Auth;
                _this.feeTypes = _this.TenantBillingSetupService.getFeeTypes();
                _this.serviceTypes = _(_this.OptionsService.getAllUnits())
                    .map('serviceType')
                    .compact()
                    .uniq()
                    .filter(function (serviceType) { return serviceType !== 'ENVIRONMENT'; })
                    .value();
                if (!_this.serviceFee) {
                    if (_this.serviceMethod != 'METERED_USAGE' && _this.serviceMethod != 'FEE' && _this.serviceMethod != 'TAX') {
                        _this.serviceMethod = null;
                    }
                    _this.serviceFee = {
                        method: _this.serviceMethod,
                        feeType: null,
                        name: '',
                        serviceId: null,
                        rate: null,
                        utilityService: null,
                        serviceType: null,
                        setup: _this.billingSetupId
                    };
                }
                else {
                    _this.initUnit();
                }
                return _this;
            }
            AddEditTenantServiceFeeCtrl.prototype.cancel = function (data) {
                this.$mdDialog.cancel();
            };
            AddEditTenantServiceFeeCtrl.prototype.hide = function (data) {
                this.$mdDialog.hide(data);
            };
            AddEditTenantServiceFeeCtrl.prototype.saveServiceFee = function () {
                var _this = this;
                var action;
                var untypedService = this.serviceFee;
                if (untypedService.id) {
                    action = this.TenantBillingSetupService.updateTenantFee(untypedService);
                }
                else {
                    action = this.TenantBillingSetupService.createTenantFee(untypedService);
                }
                action.then(function (result) {
                    _this.hide(result);
                });
            };
            AddEditTenantServiceFeeCtrl.prototype.getUtilityServices = function () {
                return _.filter(this.utilityServices, { type: this.serviceFee.serviceType });
            };
            AddEditTenantServiceFeeCtrl.prototype.getUtilityServiceName = function (id) {
                var utilityService = _.find(this.utilityServices, function (us) { return us.id == id; });
                return utilityService ? utilityService.name + " - Account: " + utilityService.account : '';
            };
            AddEditTenantServiceFeeCtrl.prototype.onChangeServiceType = function () {
                this.serviceFee.utilityService = null;
            };
            AddEditTenantServiceFeeCtrl.prototype.onChangeServiceFeeMethod = function () {
                this.serviceFee.rate = null;
                this.serviceFee.utilityService = null;
                this.serviceFee.feeType = null;
                this.unit = '';
            };
            AddEditTenantServiceFeeCtrl.prototype.onChangeUtilityService = function () {
                var _this = this;
                if (!this.serviceFee.utilityService) {
                    return;
                }
                var utilityService = _.find(this.utilityServices, function (us) { return us.id == _this.serviceFee.utilityService; });
                this.unit = this.TenantBillingSetupService.getPrefixForServiceType(utilityService.type);
            };
            AddEditTenantServiceFeeCtrl.prototype.onChangeFeeType = function () {
                this.unit = this.TenantBillingSetupService.getPrefixForFeeType(this.serviceFee.feeType);
            };
            AddEditTenantServiceFeeCtrl.prototype.initUnit = function () {
                if (this.serviceFee.method == 'FEE') {
                    this.onChangeFeeType();
                }
                else {
                    this.onChangeUtilityService();
                }
            };
            return AddEditTenantServiceFeeCtrl;
        }(aq.common.Controllers.ModalCtrl));
        tenantbilling.AddEditTenantServiceFeeCtrl = AddEditTenantServiceFeeCtrl;
        angular.module('tenantBilling').controller('AddEditTenantServiceFeeCtrl', AddEditTenantServiceFeeCtrl);
    })(tenantbilling = aq.tenantbilling || (aq.tenantbilling = {}));
})(aq || (aq = {}));
