namespace aq.propertySettings {
    export class AlertNewCtrl extends aq.common.Controllers.ModalCtrl {
        public alertType = '';
        /* @ngInject */
        constructor(
            protected $scope: AlertNewCtrlScope,
            protected $mdDialog: ng.material.IDialogService,
            private alert: RealTimeEnergyAlert | StateAlert,
            private DataStore: aq.common.DataStore,
            private building: aq.common.models.Building,
            private account: aq.common.models.Account,
            private Messages,
            private $state: ng.ui.IStateService,
            private alertOnOptions,
            private units: aq.common.models.Unit[],
            private buildingDrillin: any,
            private users: aq.common.models.User[],
            private notificationSenders: aq.common.models.NotificationSender[],
            private collectors,
            private sources,
            private tenants,
            private calendars,
            private $mdStepper,
            private RestangularV3,
            private Auth: aq.services.Auth
        ) {
            super($scope, $mdDialog);
            this.$scope.collectors = collectors;
            this.$scope.sources = sources;
            this.$scope.tenants = tenants;
            this.$scope.calendars = calendars;
            this.$scope.alertOnOptions = alertOnOptions;
            this.$scope.users = users;
            this.$scope.units = [];
            this.$scope.allUnits = units;
            this.$scope.alert = alert;
            this.$scope.vm = {
                users: [],
                selectedUser: {},
                userSearchText: ''
            };
            this.$scope.building = building;
            this.$scope.buildingDrillin = buildingDrillin;
            this.$scope.notificationSenders = notificationSenders;
        }

        public cancel() {
            this.$mdDialog.cancel();
        }

        public previousStep() {
            this.$mdStepper('newAlertStepper').back();
        }

        public nextStep() {
            this.$mdStepper('newAlertStepper').next();
        }

        public onAlertChange($event) {
            this.$scope.alertForPreview = angular.copy($event.alert);
        }

        public setDefaultAlert() {
            const alert = this.getDefaultAlert(this.alertType);
            this.alert = alert;
            this.$scope.alert = this.alert;
        }

        public getDefaultAlert(alertType: string) {
            const alert: any = {
                building: this.building.id,
                senders: [],
                receivers: [],
                active: true,
                selectedUsers: []
            };
            switch (alertType) {
                case 'State':
                    alert.type = 'STATE';
                    alert.alertOn = LevelSeverity.WARNING;
                    alert.defaultSeverity = LevelSeverity.NORMAL;
                    alert.interval = '1min';
                    alert.maxAgeMinutes = 10;
                    alert.whenCondition = WhenCondition.ANYTIME;
                    return alert;
                case 'Usage':
                default:
                    alert.type = 'REAL_TIME_ENERGY';
                    alert.interval = 'FIFTEEN_MINUTES';
                    alert.account = this.account.id;
                    return alert;
            }
        }

        public save() {
            let promise = null;
            if (this.alertType === 'Usage') {
                this.prepareAlertForUsageRequest();
                promise = this.DataStore.create(this.building.all('realTimeEnergyAlerts'), this.$scope.alert)
                    .then((alert) => {
                        return this.DataStore.get(this.building.one('realTimeEnergyAlerts', alert.id),
                            { inflate: 'receivers,customRule', single: true }, true);
                    });
            } else if (this.alertType === 'State') {
                this.prepareAlertForStateRequest();
                promise = this.DataStore.create(this.RestangularV3.all('state-alerts'), this.$scope.alert, { buildingId: this.building.id });
            }
            promise
                .then((alert) => {
                    this.Messages.success('Alert created successfully.');
                    this.$mdDialog.hide();
                    this.$state.go('aq.properties.buildings.alerts.details', { alertId: alert.id, isCreate: true });
                }).catch((error) => {
                    this.Messages.error('Unable to create Alert.');
                });
        }

        private prepareAlertForRequest() {
            let notificationType = 'REAL_TIME_ENERGY';
            if (this.alertType === 'State') {
                notificationType = 'STATE';
            }
            if (this.$scope.alert.sendTextMessage) {
                const smsNotification = _.find(this.notificationSenders, { name: 'SMS', notificationType });
                this.$scope.alert.senders.push(smsNotification.id);
            }
            delete this.$scope.alert.sendTextMessage;
            if (this.$scope.alert.sendEmail) {
                const mailNotification = _.find(this.notificationSenders, { name: 'Mail', notificationType });
                this.$scope.alert.senders.push(mailNotification.id);
            }
            delete this.$scope.alert.sendEmail;
            this.$scope.alert.selectedUsers.forEach((user) => {
                this.$scope.alert.receivers.push({
                    user: user.id,
                    classType: 'UserReceiver'
                });
            });
            delete this.$scope.alert.selectedUsers;
        }

        private prepareAlertForStateRequest() {
            const alert = this.$scope.alert as StateAlert;
            _.each(alert.alertLevels, (level) => {
                delete level.measureUnit;
                delete level.queryable;
            });

            if (alert.customRule) {
                if (alert.customRule.timeEnd) {
                    alert.customRule.timeEnd = this.time12HrTo24HrFormat(alert.customRule.timeEnd);
                }
                if (alert.customRule.timeStart) {
                    alert.customRule.timeStart = this.time12HrTo24HrFormat(alert.customRule.timeStart);
                }
            }
            this.prepareAlertForRequest();
        }

        private prepareAlertForUsageRequest() {
            const alert = this.$scope.alert as RealTimeEnergyAlert;
            if (alert.drillMode === 'BUILDING') {
                alert.drillMode = null;
            }
            alert.unit = alert.unit.value;
            this.prepareAlertForRequest();
        }

        private time12HrTo24HrFormat(time: string): string {
            if (time.match(/^\d{1,2}:\d{2} \D{2}$/)) {
                return moment(time, 'h:mm A').format('HH:mm:ss');
            }
            return time;
        }
    }

    export interface AlertNewCtrlScope extends ng.IScope {
        alert: RealTimeEnergyAlert | StateAlert;
        alertForPreview: RealTimeEnergyAlert;
        alertOnOptions: { label: string, value: string }[];
        units: aq.common.models.Unit[];
        allUnits: aq.common.models.Unit[];
        vm: any;
        notificationSenders: aq.common.models.NotificationSender[];
        calendarRules: any[];
        buildingDrillin: any;
        building: aq.common.models.Building;
        formCtrl: ng.IFormController;
    }
    angular.module('properties').controller('AlertNewCtrl', aq.propertySettings.AlertNewCtrl);
}
