angular.module(
    'aq.dashboard.widgets',
    [
        'adf.provider',
        'hc.marked',
        'mdColorPicker',
        'textAngular',
        'aq.filters',
        'aq.ui.loading',
        'aq.services',
        'dashboard'
    ]
)
    .config((dashboardProvider, $provide) => {
        dashboardProvider.widget('markdown', {
            title: 'Markdown',
            description: 'Produce and show customized content, binding it with the available building information',
            sampleImg: '/img/widgets/markdown.png',
            controller: 'MarkdownCtrl',
            resolve: {
                /* @ngInject */
                buildings(configBuildingProvider, $stateParams) {
                    return configBuildingProvider.getBuildings($stateParams.accountId);
                }
            },
            titleTemplateUrl: 'app/dashboard/adfTemplates/widget-title.html',
            loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
            templateUrl: 'app/dashboard/common/widgets/markdown/markdownview.html',
            footerTemplateUrl: 'app/dashboard/adfTemplates/widget-footer.html',
            editTemplateUrl: 'app/dashboard/adfTemplates/widget-edit.html',
            deleteTemplateUrl: 'app/dashboard/adfTemplates/widget-delete.html',
            edit: {
                templateUrl: 'app/dashboard/common/widgets/markdown/markdownedit.html',
                reload: true
            }
        });
        dashboardProvider.widget('emissions', {
            title: 'Emissions',
            description: 'Visualize savings in power, water and CO2 emissions, calculated from meter data or entered manually',
            sampleImg: '/img/widgets/emissions.png',
            controller: 'EmissionsCtrl',
            resolve: {
                /* @ngInject */
                account(accountProvider, $stateParams) {
                    return accountProvider.getAccount($stateParams.accountId);
                },
                /* @ngInject */
                buildings(configBuildingProvider, $stateParams) {
                    return configBuildingProvider.getBuildings($stateParams.accountId);
                }
            },
            titleTemplateUrl: 'app/dashboard/adfTemplates/widget-title.html',
            loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
            templateUrl: 'app/dashboard/common/widgets/emissions/emissionsview.html',
            footerTemplateUrl: 'app/dashboard/adfTemplates/widget-footer.html',
            editTemplateUrl: 'app/dashboard/adfTemplates/widget-edit.html',
            deleteTemplateUrl: 'app/dashboard/adfTemplates/widget-delete.html',
            edit: {
                templateUrl: 'app/dashboard/common/widgets/emissions/emissionsedit.html',
                reload: true
            }
        });
        dashboardProvider.widget('stats', {
            title: 'Statistics',
            description: 'Show Total, Average or Maximum for a single queryable measure for predefined interval over some time period',
            sampleImg: '/img/widgets/stats.png',
            controller: 'StatsCtrl as statsCtrl',
            resolve: {
                /* @ngInject */
                account(accountProvider, $stateParams) {
                    return accountProvider.getAccount($stateParams.accountId);
                },
                /* @ngInject */
                buildings(configBuildingProvider, $stateParams) {
                    return configBuildingProvider.getBuildings($stateParams.accountId);
                },
                /* @ngInject */
                tenants(tenantProvider, $stateParams) {
                    return tenantProvider.getTenants($stateParams.accountId);
                },
                /* @ngInject */
                options(OptionsService, Restangular, $stateParams) {
                    return Restangular.one('accounts', $stateParams.accountId).get({ single: true }).then((account) => {
                        return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
                    });
                },
                /* @ngInject */
                meter(drillinProvider, $stateParams) {
                    return drillinProvider.getDrillin($stateParams.accountId, 'METER');
                },
                /* @ngInject */
                uses(drillinProvider, $stateParams) {
                    return drillinProvider.getDrillin($stateParams.accountId, 'USES');
                },
                /* @ngInject */
                source(drillinProvider, $stateParams) {
                    return drillinProvider.getDrillin($stateParams.accountId, 'SOURCE');
                },
                /* @ngInject */
                space(drillinProvider, $stateParams) {
                    return drillinProvider.getDrillin($stateParams.accountId, 'SPACE');
                },
                /* @ngInject */
                tenant(drillinProvider, $stateParams) {
                    return drillinProvider.getDrillin($stateParams.accountId, 'TENANT');
                },
                /* @ngInject */
                buildingGroups(buildingGroupProvider, $stateParams) {
                    return buildingGroupProvider.getGroups($stateParams.accountId);
                }
            },
            titleTemplateUrl: 'app/dashboard/adfTemplates/widget-title.html',
            loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
            templateUrl: 'app/dashboard/common/widgets/stats/statsview.html',
            footerTemplateUrl: 'app/dashboard/adfTemplates/widget-footer.html',
            editTemplateUrl: 'app/dashboard/adfTemplates/widget-edit.html',
            deleteTemplateUrl: 'app/dashboard/adfTemplates/widget-delete.html',
            edit: {
                templateUrl: 'app/dashboard/common/widgets/stats/statsedit.html',
                reload: true
            }
        });
        dashboardProvider.widget('graph', {
            title: 'Graph',
            description: 'Visualize multiple queryable measures and trends for a single building over time',
            sampleImg: '/img/widgets/graph.png',
            controller: 'GraphCtrl as graphCtrl',
            resolve: {
                /* @ngInject */
                account(accountProvider, $stateParams) {
                    return accountProvider.getAccount($stateParams.accountId);
                },
                /* @ngInject */
                buildings(configBuildingProvider, $stateParams) {
                    return configBuildingProvider.getBuildings($stateParams.accountId);
                },
                /* @ngInject */
                tenants(tenantProvider, $stateParams) {
                    return tenantProvider.getTenants($stateParams.accountId);
                },
                /* @ngInject */
                options(OptionsService, Restangular, $stateParams) {
                    return Restangular.one('accounts', $stateParams.accountId).get({ single: true }).then((account) => {
                        return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
                    });
                },
                /* @ngInject */
                meter(drillinProvider, $stateParams) {
                    return drillinProvider.getDrillin($stateParams.accountId, 'METER');
                },
                /* @ngInject */
                uses(drillinProvider, $stateParams) {
                    return drillinProvider.getDrillin($stateParams.accountId, 'USES');
                },
                /* @ngInject */
                source(drillinProvider, $stateParams) {
                    return drillinProvider.getDrillin($stateParams.accountId, 'SOURCE');
                },
                /* @ngInject */
                space(drillinProvider, $stateParams) {
                    return drillinProvider.getDrillin($stateParams.accountId, 'SPACE');
                },
                /* @ngInject */
                tenant(drillinProvider, $stateParams) {
                    return drillinProvider.getDrillin($stateParams.accountId, 'TENANT');
                }
            },
            titleTemplateUrl: 'app/dashboard/adfTemplates/widget-title.html',
            loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
            templateUrl: 'app/dashboard/common/widgets/graph/graphview.html',
            footerTemplateUrl: 'app/dashboard/adfTemplates/widget-footer.html',
            editTemplateUrl: 'app/dashboard/adfTemplates/widget-edit.html',
            deleteTemplateUrl: 'app/dashboard/adfTemplates/widget-delete.html',
            edit: {
                templateUrl: 'app/dashboard/common/widgets/graph/graphedit.html',
                reload: true
            }
        });
        dashboardProvider.widget('weather', {
            title: 'Weather',
            description: 'Display current weather and forecast for the selected building',
            sampleImg: '/img/widgets/weather.png',
            controller: 'DashboardWeatherCtrl as weather',
            resolve: {
                /* @ngInject */
                user(UserService) {
                    return UserService.getUser({ inflate: 'currentProfile,currentProfile.account,currentProfile.apps' });
                },
                /* @ngInject */
                buildings(configBuildingProvider, $stateParams) {
                    return configBuildingProvider.getBuildings($stateParams.accountId);
                },
                /* @ngInject */
                forecast(DashboardWeatherForecast, config, configBuildingProvider) {
                    return configBuildingProvider.getBuilding(config.buildingId)
                        .then((building) => {
                            const previousDays = config.previousDays ? config.previousDays : 1;
                            const start = new Date();
                            start.setDate(start.getDate() - previousDays);
                            const startDate = start.toISOString().slice(0, 10);
                            const end = new Date()
                            end.setDate(end.getDate() + 7);
                            const endDate = end.toISOString().slice(0, 10);
                            const { latitude, longitude } = building;
                            return DashboardWeatherForecast.getForecast({ latitude, longitude, startDate, endDate });
                        });
                },
            },
            titleTemplateUrl: 'app/dashboard/adfTemplates/widget-title.html',
            loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
            templateUrl: 'app/dashboard/common/widgets/weather/weatherview.html',
            footerTemplateUrl: 'app/dashboard/adfTemplates/widget-footer.html',
            editTemplateUrl: 'app/dashboard/adfTemplates/widget-edit.html',
            deleteTemplateUrl: 'app/dashboard/adfTemplates/widget-delete.html',
            edit: {
                templateUrl: 'app/dashboard/common/widgets/weather/weatheredit.html',
                reload: true
            }
        });
        dashboardProvider.widget('insight', {
            title: 'Insight',
            description: 'Gain insight into Peak Demand, Utility Spending, Baseload, Consumption and more for the selected building',
            sampleImg: '/img/widgets/insight.png',
            controller: 'DashboardInsightCtrl as insight',
            resolve: {
                /* @ngInject */
                buildings(configBuildingProvider, $stateParams) {
                    return configBuildingProvider.getBuildings($stateParams.accountId);
                },
                /* @ngInject */
                account(accountProvider, $stateParams) {
                    return accountProvider.getAccount($stateParams.accountId);
                },
                /* @ngInject */
                measureUnitMap(MeasureProvider, $stateParams) {
                    return MeasureProvider.getMeasureMap($stateParams.accountId);
                }
            },
            titleTemplateUrl: 'app/dashboard/adfTemplates/widget-title.html',
            loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
            templateUrl: 'app/dashboard/common/widgets/insight/insightview.html',
            footerTemplateUrl: 'app/dashboard/adfTemplates/widget-footer.html',
            editTemplateUrl: 'app/dashboard/adfTemplates/widget-edit.html',
            deleteTemplateUrl: 'app/dashboard/adfTemplates/widget-delete.html',
            edit: {
                templateUrl: 'app/dashboard/common/widgets/insight/insightedit.html',
                reload: true
            }
        });

        dashboardProvider.widget('table', {
            title: 'Table',
            description: 'Show totals for multiple queryables or show interval breakdown for a single queryable',
            sampleImg: '/img/widgets/table.png',
            controller: 'TableCtrl as tableCtrl',
            resolve: {
                /* @ngInject */
                account(accountProvider, $stateParams) {
                    return accountProvider.getAccount($stateParams.accountId);
                },
                /* @ngInject */
                buildings(configBuildingProvider, $stateParams) {
                    return configBuildingProvider.getBuildings($stateParams.accountId);
                },
                /* @ngInject */
                tenants(tenantProvider, $stateParams) {
                    return tenantProvider.getTenants($stateParams.accountId);
                },
                /* @ngInject */
                meter(drillinProvider, $stateParams) {
                    return drillinProvider.getDrillin($stateParams.accountId, 'METER');
                },
                /* @ngInject */
                uses(drillinProvider, $stateParams) {
                    return drillinProvider.getDrillin($stateParams.accountId, 'USES');
                },
                /* @ngInject */
                source(drillinProvider, $stateParams) {
                    return drillinProvider.getDrillin($stateParams.accountId, 'SOURCE');
                },
                /* @ngInject */
                space(drillinProvider, $stateParams) {
                    return drillinProvider.getDrillin($stateParams.accountId, 'SPACE');
                },
                /* @ngInject */
                tenant(drillinProvider, $stateParams) {
                    return drillinProvider.getDrillin($stateParams.accountId, 'TENANT');
                },
                /* @ngInject */
                buildingGroups(buildingGroupProvider, $stateParams) {
                    return buildingGroupProvider.getGroups($stateParams.accountId);
                }
            },
            titleTemplateUrl: 'app/dashboard/adfTemplates/widget-title.html',
            loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
            templateUrl: 'app/dashboard/common/widgets/table/tableview.html',
            footerTemplateUrl: 'app/dashboard/adfTemplates/widget-footer.html',
            editTemplateUrl: 'app/dashboard/adfTemplates/widget-edit.html',
            deleteTemplateUrl: 'app/dashboard/adfTemplates/widget-delete.html',
            edit: {
                templateUrl: 'app/dashboard/common/widgets/table/tableedit.html',
                reload: true
            }
        });

        dashboardProvider.widget('projectList', {
            title: 'Project List',
            description: 'Filter out and display list of projects, optionaly showing their status, type, cost or savings',
            sampleImg: '/img/widgets/project-list.png',
            controller: 'ProjectListWidgetCtrl as projectListWidgetCtrl',
            resolve: {
                /* @ngInject */
                account(accountProvider, $stateParams) {
                    return accountProvider.getAccount($stateParams.accountId);
                },
                /* @ngInject */
                buildings(configBuildingProvider, $stateParams) {
                    return configBuildingProvider.getBuildings($stateParams.accountId);
                },
                /* @ngInject */
                users(DataStore, Restangular, $stateParams) {
                    return DataStore.getList(Restangular.one('accounts', $stateParams.accountId), 'users', {});
                },
                /* @ngInject */
                projectTypes(RestangularV3) {
                    return RestangularV3.one('projects').one('types').get().then((result) => {
                        return result.originalElement;
                    });
                },
                /* @ngInject */
                projectStatuses(RestangularV3) {
                    return RestangularV3.one('projects').one('statuses').get().then((result) => {
                        return result.originalElement;
                    });
                },
                /* @ngInject */
                buildingGroups(buildingGroupProvider, $stateParams) {
                    return buildingGroupProvider.getGroups($stateParams.accountId);
                },
                /* @ngInject */
                currentUser(UserService: aq.services.UserService) {
                    return UserService.getUser();
                },
                /* @ngInject */
                isProjectsV3(Auth) {
                    return Auth.hasFunctionality('Aquicore 3.0 Projects');
                }
            },
            titleTemplateUrl: 'app/dashboard/adfTemplates/widget-title.html',
            loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
            templateUrl: 'app/dashboard/common/widgets/projectList/projectListView.html',
            footerTemplateUrl: 'app/dashboard/adfTemplates/widget-footer.html',
            editTemplateUrl: 'app/dashboard/adfTemplates/widget-edit.html',
            deleteTemplateUrl: 'app/dashboard/adfTemplates/widget-delete.html',
            edit: {
                templateUrl: 'app/dashboard/common/widgets/projectList/projectListEdit.html',
                reload: true
            }
        });

        dashboardProvider.widget('projectSummary', {
            title: 'Project Summary',
            description: 'Display summary of cost and savings for all your projects related to selected buildings',
            sampleImg: '/img/widgets/project-summary.png',
            controller: 'ProjectSummaryWidgetCtrl as projectSummaryWidgetCtrl',
            resolve: {
                /* @ngInject */
                account(accountProvider, $stateParams) {
                    return accountProvider.getAccount($stateParams.accountId);
                },
                /* @ngInject */
                buildings(configBuildingProvider, $stateParams) {
                    return configBuildingProvider.getBuildings($stateParams.accountId);
                },
                /* @ngInject */
                users(DataStore, Restangular, $stateParams) {
                    return DataStore.getList(Restangular.one('accounts', $stateParams.accountId), 'users', {});
                },
                /* @ngInject */
                projectTypes(RestangularV3) {
                    return RestangularV3.one('projects').one('types').get().then((result) => {
                        return result.originalElement;
                    });
                },
                /* @ngInject */
                projectStatuses(RestangularV3) {
                    return RestangularV3.one('projects').one('statuses').get().then((result) => {
                        return result.originalElement;
                    });
                },
                /* @ngInject */
                buildingGroups(buildingGroupProvider, $stateParams) {
                    return buildingGroupProvider.getGroups($stateParams.accountId);
                },
                /* @ngInject */
                isProjectsV3(Auth) {
                    return Auth.hasFunctionality('Aquicore 3.0 Projects');
                }
            },
            titleTemplateUrl: 'app/dashboard/adfTemplates/widget-title.html',
            loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
            templateUrl: 'app/dashboard/common/widgets/projectSummary/projectSummaryView.html',
            footerTemplateUrl: 'app/dashboard/adfTemplates/widget-footer.html',
            editTemplateUrl: 'app/dashboard/adfTemplates/widget-edit.html',
            deleteTemplateUrl: 'app/dashboard/adfTemplates/widget-delete.html',
            edit: {
                templateUrl: 'app/dashboard/common/widgets/projectSummary/projectSummaryEdit.html',
                reload: true
            }
        });

        dashboardProvider.widget('myTasks', {
            title: 'My Tasks',
            description: 'Show the list of tasks for the current user, with the option to mark them as being complete or not',
            sampleImg: '/img/widgets/tasks.png',
            controller: 'MyTasksWidgetCtrl as myTasksWidgetCtrl',
            resolve: {
                /* @ngInject */
                user(UserService) {
                    return UserService.getUser({ inflate: 'currentProfile,currentProfile.account,currentProfile.apps' });
                }
            },
            titleTemplateUrl: 'app/dashboard/adfTemplates/widget-title.html',
            loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
            templateUrl: 'app/dashboard/common/widgets/myTasks/myTasksView.html',
            footerTemplateUrl: 'app/dashboard/adfTemplates/widget-footer.html',
            editTemplateUrl: 'app/dashboard/adfTemplates/widget-edit.html',
            deleteTemplateUrl: 'app/dashboard/adfTemplates/widget-delete.html',
            edit: {
                templateUrl: 'app/dashboard/common/widgets/myTasks/myTasksEdit.html',
                reload: true
            }
        });

        dashboardProvider.widget('buildingEngagement', {
            title: 'Building Engagement',
            description: 'Show interactive breakdown of people activity across buildings',
            sampleImg: '/img/widgets/people.png',
            isBeta: true,
            controller: 'BuildingEngagementWidgetCtrl as vm',
            resolve: {
                /* @ngInject */
                buildings(configBuildingProvider, $stateParams) {
                    return configBuildingProvider.getBuildings($stateParams.accountId);
                },
                /* @ngInject */
                users(DataStore, Restangular, $stateParams) {
                    return DataStore.getList(Restangular.one('accounts', $stateParams.accountId), 'users', {});
                },
                /* @ngInject */
                buildingGroups(buildingGroupProvider, $stateParams) {
                    return buildingGroupProvider.getGroups($stateParams.accountId);
                }
            },
            titleTemplateUrl: 'app/dashboard/adfTemplates/widget-title.html',
            loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
            templateUrl: 'app/dashboard/common/widgets/buildingEngagement/buildingEngagementView.html',
            footerTemplateUrl: 'app/dashboard/adfTemplates/widget-footer.html',
            editTemplateUrl: 'app/dashboard/adfTemplates/widget-edit.html',
            deleteTemplateUrl: 'app/dashboard/adfTemplates/widget-delete.html',
            edit: {
                templateUrl: 'app/dashboard/common/widgets/buildingEngagement/buildingEngagementEdit.html',
                reload: true
            }
        });

        dashboardProvider.widget('budgetBreakdown', {
            title: 'Budget Breakdown',
            description: 'Show interactive breakdown of budget across multiple services for different building groups',
            sampleImg: '/img/widgets/assets.png',
            isBeta: true,
            controller: 'BudgetBreakdownWidgetCtrl as vm',
            resolve: {
                /* @ngInject */
                buildings(configBuildingProvider, $stateParams) {
                    return configBuildingProvider.getBuildings($stateParams.accountId);
                },
                /* @ngInject */
                users(DataStore, Restangular, $stateParams) {
                    return DataStore.getList(Restangular.one('accounts', $stateParams.accountId), 'users', {});
                },
                /* @ngInject */
                buildingGroups(buildingGroupProvider, $stateParams) {
                    return buildingGroupProvider.getGroups($stateParams.accountId);
                }
            },
            titleTemplateUrl: 'app/dashboard/adfTemplates/widget-title.html',
            loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
            templateUrl: 'app/dashboard/common/widgets/budgetBreakdown/budgetBreakdownView.html',
            footerTemplateUrl: 'app/dashboard/adfTemplates/widget-footer.html',
            editTemplateUrl: 'app/dashboard/adfTemplates/widget-edit.html',
            deleteTemplateUrl: 'app/dashboard/adfTemplates/widget-delete.html',
            edit: {
                templateUrl: 'app/dashboard/common/widgets/budgetBreakdown/budgetBreakdownEdit.html',
                reload: true
            }
        });

        dashboardProvider.widget('portfolioMap', {
            title: 'Portfolio Map',
            description: 'Show geographic representation of various performance indicators for different building groups',
            sampleImg: '/img/widgets/map.png',
            isBeta: true,
            controller: 'PortfolioMapWidgetCtrl as vm',
            resolve: {
                /* @ngInject */
                buildings(configBuildingProvider, $stateParams) {
                    return configBuildingProvider.getBuildings($stateParams.accountId);
                },
                /* @ngInject */
                users(DataStore, Restangular, $stateParams) {
                    return DataStore.getList(Restangular.one('accounts', $stateParams.accountId), 'users', {});
                },
                /* @ngInject */
                buildingGroups(buildingGroupProvider, $stateParams) {
                    return buildingGroupProvider.getGroups($stateParams.accountId);
                }
            },
            titleTemplateUrl: 'app/dashboard/adfTemplates/widget-title.html',
            loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
            templateUrl: 'app/dashboard/common/widgets/portfolioMap/portfolioMapView.html',
            footerTemplateUrl: 'app/dashboard/adfTemplates/widget-footer.html',
            editTemplateUrl: 'app/dashboard/adfTemplates/widget-edit.html',
            deleteTemplateUrl: 'app/dashboard/adfTemplates/widget-delete.html',
            edit: {
                templateUrl: 'app/dashboard/common/widgets/portfolioMap/portfolioMapEdit.html',
                reload: true
            }
        });

        dashboardProvider.widget('status', {
            title: 'Status',
            description: 'Show the status of one or more queryable measures',
            sampleImg: '/img/widgets/status.png',
            controller: 'StatusCtrl as vm',
            resolve: {
                /* @ngInject */
                buildings(configBuildingProvider, $stateParams) {
                    return configBuildingProvider.getBuildings($stateParams.accountId);
                }
            },
            titleTemplateUrl: 'app/dashboard/adfTemplates/widget-title.html',
            loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
            templateUrl: 'app/dashboard/common/widgets/status/statusView.html',
            footerTemplateUrl: 'app/dashboard/adfTemplates/widget-footer.html',
            editTemplateUrl: 'app/dashboard/adfTemplates/widget-edit.html',
            deleteTemplateUrl: 'app/dashboard/adfTemplates/widget-delete.html',
            edit: {
                templateUrl: 'app/dashboard/common/widgets/status/statusEdit.html',
                reload: true
            }
        });

        dashboardProvider.widget('alert', {
            title: 'Alert',
            description: 'Show the building alert status',
            sampleImg: '/img/widgets/alert.png',
            controller: 'AlertCtrl as vm',
            resolve: {
                /* @ngInject */
                accountId($stateParams) {
                    return $stateParams.accountId;
                },
                /* @ngInject */
                buildings(configBuildingProvider, $stateParams) {
                    return configBuildingProvider.getBuildings($stateParams.accountId);
                }
            },
            titleTemplateUrl: 'app/dashboard/adfTemplates/widget-title.html',
            loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
            templateUrl: 'app/dashboard/common/widgets/alert/alertView.html',
            footerTemplateUrl: 'app/dashboard/adfTemplates/widget-footer.html',
            editTemplateUrl: 'app/dashboard/adfTemplates/widget-edit.html',
            deleteTemplateUrl: 'app/dashboard/adfTemplates/widget-delete.html',
            edit: {
                templateUrl: 'app/dashboard/common/widgets/alert/alertEdit.html',
                reload: true
            }
        });

        dashboardProvider.widget('expense', {
            title: 'Utility Expense',
            description: 'Show account utility expenses for the current year',
            sampleImg: '/img/widgets/alert.png',
            controller: 'ExpenseCtrl as vm',
            isBeta: true,
            resolve: {
                /* @ngInject */
                account(accountProvider, $stateParams) {
                    return accountProvider.getAccount($stateParams.accountId);
                },
                /* @ngInject */
                buildings(configBuildingProvider, $stateParams) {
                    return configBuildingProvider.getBuildings($stateParams.accountId);
                },
                /* @ngInject */
                measureUnitMap(MeasureProvider, $stateParams) {
                    return MeasureProvider.getMeasureMap($stateParams.accountId);
                }
            },
            titleTemplateUrl: 'app/dashboard/adfTemplates/widget-title.html',
            loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
            templateUrl: 'app/dashboard/common/widgets/expense/expenseView.html',
            footerTemplateUrl: 'app/dashboard/adfTemplates/widget-footer.html',
            editTemplateUrl: 'app/dashboard/adfTemplates/widget-edit.html',
            deleteTemplateUrl: 'app/dashboard/adfTemplates/widget-delete.html',
            edit: {
                templateUrl: 'app/dashboard/common/widgets/expense/expenseEdit.html',
                reload: true
            }
        });

        dashboardProvider.widget('onboarding', {
            title: 'Onboarding',
            description: 'Show the building onboarding status',
            sampleImg: '/img/widgets/onboarding.png',
            controller: 'OnboardingWidgetCtrl as vm',
            resolve: {
                /* @ngInject */
                account(accountProvider, $stateParams) {
                    return accountProvider.getAccount($stateParams.accountId);
                },
                /* @ngInject */
                buildings(configBuildingProvider, $stateParams) {
                    return configBuildingProvider.getBuildings($stateParams.accountId);
                },
                /* @ngInject */
                buildingGroups(buildingGroupProvider, $stateParams) {
                    return buildingGroupProvider.getGroups($stateParams.accountId);
                }
            },
            titleTemplateUrl: 'app/dashboard/adfTemplates/widget-title.html',
            loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
            templateUrl: 'app/dashboard/common/widgets/onboarding/onboardingView.html',
            footerTemplateUrl: 'app/dashboard/adfTemplates/widget-footer.html',
            editTemplateUrl: 'app/dashboard/adfTemplates/widget-edit.html',
            deleteTemplateUrl: 'app/dashboard/adfTemplates/widget-delete.html',
            edit: {
                templateUrl: 'app/dashboard/common/widgets/onboarding/onboardingEdit.html',
                reload: true
            }
        });

        dashboardProvider.widget('activities', {
            // title and description are fed by the en_{lang}.json files - what you put here is
            // just a key!
            title: 'Activities',
            description: 'Activities',
            sampleImg: '/img/widgets/activity.png',
            controller: 'ActivityWidgetCtrl as vm',
            resolve: {
                /* @ngInject */
                accountId($stateParams) {
                    return parseInt($stateParams.accountId);
                },
                /* @ngInject */
                buildings(configBuildingProvider, $stateParams) {
                    return configBuildingProvider.getBuildings($stateParams.accountId);
                }
            },
            titleTemplateUrl: 'app/dashboard/adfTemplates/widget-title.html',
            loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
            templateUrl: 'app/dashboard/common/widgets/activities/activities-view.html',
            footerTemplateUrl: 'app/dashboard/adfTemplates/widget-footer.html',
            editTemplateUrl: 'app/dashboard/adfTemplates/widget-edit.html',
            deleteTemplateUrl: 'app/dashboard/adfTemplates/widget-delete.html',
            edit: {
                templateUrl: 'app/dashboard/common/widgets/activities/activities-edit.html',
                reload: true
            }
        });

        dashboardProvider.widget('portfolioVariance', {
            title: 'Portfolio Variance',
            description: 'Portfolio Variance Analysis',
            sampleImg: '/img/widgets/table.png',
            controller: 'PortfolioVarianceCtrl as vm',
            resolve: {
                /* @ngInject */
                account(accountProvider, $stateParams) {
                    return accountProvider.getAccount($stateParams.accountId);
                },
                /* @ngInject */
                buildings(configBuildingProvider, $stateParams) {
                    return configBuildingProvider.getBuildings($stateParams.accountId);
                },
                /* @ngInject */
                buildingGroups(buildingGroupProvider, $stateParams) {
                    return buildingGroupProvider.getGroups($stateParams.accountId);
                }
            },
            titleTemplateUrl: 'app/dashboard/adfTemplates/widget-title.html',
            loadingTemplateUrl: 'app/dashboard/adfTemplates/widget-loading.html',
            templateUrl: 'app/dashboard/common/widgets/portfolioVariance/portfolioVarianceView.html',
            footerTemplateUrl: 'app/dashboard/adfTemplates/widget-footer.html',
            editTemplateUrl: 'app/dashboard/adfTemplates/widget-edit.html',
            deleteTemplateUrl: 'app/dashboard/adfTemplates/widget-delete.html',
            edit: {
                templateUrl: 'app/dashboard/common/widgets/portfolioVariance/portfolioVarianceEdit.html',
                reload: true
            }
        });

        function createTextAngularIconButton(name: string, icon: string) {
            return `<md-button
                        name="${name} insertVideo"
                        class="md-raised ta-button ta-button-icon"
                        aria-label="Format ${name}"
                    >
                        <md-icon class="material-icons format_${name}">${icon}</md-icon>
                    </md-button>`;
        }

        function createTextAngularTextButton(name: string, text: string) {
            return `<md-button
                        name="${name}"
                        class="md-raised ta-button"
                        aria-label="Format ${name}"
                    >${text}</md-button>`;
        }

        $provide.decorator('taTools', ['$delegate', (taTools) => {
            taTools.h1.display = createTextAngularTextButton('h1', 'H1');
            taTools.h2.display = createTextAngularTextButton('h2', 'H2');
            taTools.h3.display = createTextAngularTextButton('h3', 'H3');
            taTools.h4.display = createTextAngularTextButton('h4', 'H4');
            taTools.h5.display = createTextAngularTextButton('h5', 'H5');
            taTools.h6.display = createTextAngularTextButton('h6', 'H6');
            taTools.bold.display = createTextAngularIconButton('bold', 'format_bold');
            taTools.italics.display = createTextAngularIconButton('italic', 'format_italic');
            taTools.underline.display = createTextAngularIconButton('underline', 'format_underline');
            taTools.html.display = createTextAngularIconButton('html', 'code');
            taTools.insertLink.display = createTextAngularIconButton('insertLink', 'link');
            taTools.insertLink.onElementSelect = {};
            taTools.insertImage.display = createTextAngularIconButton('insertImage', 'add_a_photo');
            taTools.insertImage.onElementSelect = {};
            taTools.insertVideo.display = createTextAngularIconButton('insertVideo', 'video_library');
            taTools.insertVideo.onElementSelect = {};
            return taTools;
        }]);

        $provide.provider('drillinProvider', function () {
            const data = {};
            this.$get = ['TableData', function (TableData) {
                return {
                    getDrillin(accountId, drillinName) {
                        if (!data[drillinName]) {
                            data[drillinName] = TableData.getDrillin(accountId, drillinName);
                        }
                        return data[drillinName];
                    }
                };
            }];
        });

        $provide.provider('tenantProvider', function () {
            const memoize: Record<string, ng.IPromise<any>> = {};
            this.$get = ['RestangularV3', '$location', (RestangularV3: restangular.IService) => {
                return {
                    getTenants(accountId) {
                        const key = `tenants-for-account-${accountId}`;
                        if (key in memoize) {
                            return memoize[key];
                        }
                        memoize[key] = RestangularV3.all('tenants')
                            .all('for-account')
                            .getList({ accountId });

                        return memoize[key];
                    }
                };
            }];
        });

        $provide.provider('buildingGroupProvider', function () {
            const memoize: Record<string, ng.IPromise<any>> = {};
            this.$get = ['Restangular', '$location', (Restangular: restangular.IService) => {
                return {
                    getGroups(accountId) {
                        const key = `building-groups-for-account-${accountId}`;
                        if (key in memoize) {
                            return memoize[key];
                        }
                        memoize[key] = Restangular.one('accounts', accountId).one('BuildingGroups').get();

                        return memoize[key];
                    }
                };
            }];
        });

        $provide.provider('accountProvider', function () {
            const memoize: Record<string, ng.IPromise<any>> = {};
            this.$get = ['Restangular', '$location', (Restangular: restangular.IService) => {
                return {
                    getAccount(accountId) {
                        const key = `account-${accountId}`;
                        if (key in memoize) {
                            return memoize[key];
                        }
                        memoize[key] = Restangular.one('accounts', accountId).get({ single: true });

                        return memoize[key];
                    }
                };
            }];
        });

        $provide.provider('configBuildingProvider', function () {
            const memoize: Record<string, ng.IPromise<any>> = {};
            this.$get = ['RestangularV3', '$location', (RestangularV3: restangular.IService) => {
                return {
                    getBuildings(accountId) {
                        if (!accountId) {
                            return RestangularV3.all('buildings').getList();
                        }
                        const key = `buildings-by-account-${accountId}`;
                        if (key in memoize) {
                            return memoize[key];
                        }
                        memoize[key] = RestangularV3.all('buildings').getList({ accountId });
                        return memoize[key];
                    },
                    getBuilding(buildingId) {
                        if (buildingId) {
                            const key = `building-${buildingId}`;
                            if (key in memoize) {
                                return memoize[key];
                            }
                            memoize[key] = RestangularV3.one('buildings', buildingId).get();
                            return memoize[key];
                        }
                        return RestangularV3.all('buildings').getList()
                            .then((buildings) => {
                                return buildings[0];
                            });
                    }
                };
            }];
        });

        $provide.provider('WidgetHelperService', function () {
            this.$get = function () {
                return {
                    isBuildingInSetup(building) {
                        if (!building) {
                            return false;
                        }
                        if (building.dataCommissionDate === void 0) {
                            return false;
                        }
                        return !(building.dataCommissionDate && moment(building.dataCommissionDate).isBefore(moment()));
                    }
                };
            };
        });

        $provide.provider('MeasureProvider', function () {
            const memoize: Record<string, ng.IPromise<any>> = {};
            this.$get = ['OptionsService', 'DashboardOptionsService', (OptionsService, DashboardOptionsService) => {
                return {
                    getMeasureMap(accountId) {
                        if (!accountId) {
                            return null;
                        }
                        const key = `measures-for-account-${accountId}`;
                        if (key in memoize) {
                            return memoize[key];
                        }
                        memoize[key] = OptionsService.init(accountId).then(() => {
                            const allNonConvertedUnits = DashboardOptionsService.getUnits(false);
                            const apiUnits = ['energy', 'gas', 'heat_transfer', 'steam_mass', 'water'];
                            const blendedRateUnits = _.filter(allNonConvertedUnits, (unit) => {
                                return !unit.isIntensity
                                    && unit.isDefault
                                    && _.some(apiUnits, (apiUnit) => apiUnit.toLowerCase() == unit.apiUnit.toLowerCase());
                            });
                            const measureUnitMap = {};
                            _.each(blendedRateUnits, (unit) => {
                                const measure = unit.serviceType ? unit.serviceType : unit.apiUnit;
                                measureUnitMap[measure] = unit.unit;
                            });
                            return measureUnitMap;
                        });
                        return memoize[key];
                    }
                };
            }];
        });
    });
