namespace aq.tenantbilling {
    export interface BillingViewItemsHashSet {
        [status: string]: BillingViewItem[];
    }
    export class TenantBillingInvoicingCtrl {
        public billingViewItems: BillingViewItemsHashSet;
        public filteredBillingViewItems: BillingViewItemsHashSet;
        public isEmptySearch: { [status: string]: boolean };
        public hasMeteredTenantServices: boolean;
        public currencyUnit: any;
        public billings: MonthlyTenantBilling[];
        public processing: boolean;
        public currentNavItem: string;
        private lastSearchQuery: string;
        private invoiceDetailsState = 'aq.tenantbilling.building.invoicing.run';
        private tabs = [
            { name: 'Drafts', value: 'DRAFT' },
            { name: 'Approved', value: 'APPROVED' }
        ];
        private steps = {
            none: 0,
            configuration: 1,
            rates: 2,
            charges: 3,
            approve: 4,
            summary: 5,
            manualMeters: 3
        };
        /* @ngInject */
        constructor(
            private $state: ng.ui.IStateService,
            private Messages: aq.services.Messages,
            private ConfirmationService: aq.services.ConfirmationService,
            private MonthlyBillingService: MonthlyBillingService,
            private OptionsService,
            private building: aq.common.models.Building,
            private meteredTenantServices: TenantService[],
            private updatedBillingId: string,
            private deletedBillingId: string,
            private Auth: aq.services.Auth,
            private loading: aq.services.Loading
        ) {
            this.hasMeteredTenantServices = this.meteredTenantServices.length > 0;
            this.processing = true;
            this.currencyUnit = this.OptionsService.currencyUnit();
            this.currentNavItem = this.tabs[0].value;
            this.MonthlyBillingService.getList({ buildingId: this.building.id })
                .then((results) => {
                    this.billings = results;
                    this.processing = false;
                    this.buildViewItems();
                });
        }
        public isSelectedTab(name) {
            return this.currentNavItem == name;
        }
        public buildViewItems() {
            this.billingViewItems = {};
            this.isEmptySearch = {};
            _.each(this.tabs, (tab) => {
                const type = tab.value;
                this.billingViewItems[type] = [];
                this.isEmptySearch[type] = false;
            });
            _.each(this.billings, (billing: MonthlyTenantBilling) => {
                const currentStepNumber = this.getCompletedStep(billing);
                const isApprovedStatus = this.MonthlyBillingService.isApprovedStatus(billing.status);
                const isDraftStatus = this.MonthlyBillingService.isDraftStatus(billing.status);
                if (!isApprovedStatus && !isDraftStatus) {
                    return;
                }
                const type = isApprovedStatus ? 'APPROVED' : 'DRAFT';
                const datePeriod = billing.startReadingDate ?
                    moment(billing.startReadingDate).format('MM/DD/YYYY') + ' - ' + moment(billing.readingDate).format('MM/DD/YYYY')
                    : moment(billing.readingDate).format('MM/DD/YYYY');
                const viewItem: BillingViewItem = {
                    id: billing.id,
                    title: billing.name,
                    datePeriod,
                    invoiceDate: moment(billing.invoiceDate).format('MM/DD/YYYY'),
                    step: currentStepNumber,
                    progressInfo: '',
                    nextStepInfo: '',
                    isApproved: isApprovedStatus,
                    statusInfo: '',
                    sortColumn: billing.startReadingDate ? moment(billing.startReadingDate) : moment(billing.invoiceDate)
                };
                if (!viewItem.isApproved) {
                    viewItem.progressInfo = `${currentStepNumber}/${this.getNumberOfSteps(viewItem)} steps`;
                    viewItem.nextStepInfo = this.getNextStepTitle(viewItem);
                }
                this.billingViewItems[type].push(viewItem);
            });
            this.filteredBillingViewItems = angular.copy(this.billingViewItems);
        }
        public searchBy(query: string): void {
            this.lastSearchQuery = query;
            if (query) {
                this.filteredBillingViewItems = {};
                _.each(this.billingViewItems, (items: BillingViewItem[], key: string) => {
                    this.filteredBillingViewItems[key] = _.filter(items, (billingItem: BillingViewItem) => {
                        const searchThrough: string[] = [billingItem.title, billingItem.datePeriod];
                        return this.foundIn(searchThrough, query);
                    });
                });
            } else {
                this.filteredBillingViewItems = angular.copy(this.billingViewItems);
            }
            _.each(this.billingViewItems, (items: BillingViewItem[], key: string) => {
                this.isEmptySearch[key] = !!query && this.filteredBillingViewItems[key].length === 0;
            });
        }
        public createBilling() {
            this.$state.go(this.invoiceDetailsState);
        }
        public editBilling(billingItem: BillingViewItem) {
            this.loading.start(true);
            const billing = _.find(this.billings, (b) => b.id == billingItem.id);
            this.$state.go(this.invoiceDetailsState, { monthlyBillingId: billing.id, step: this.getCompletedStep(billing) + 1 })
                .then(this.loading.stop)
                .catch(this.loading.stop);
        }
        public deleteBilling(billingItem: BillingViewItem) {
            if (billingItem.isApproved) {
                this.Messages.error('Only billing periods in Draft status can be deleted.');
                return;
            }
            const billing = _.find(this.billings, (b) => b.id == billingItem.id);
            this.MonthlyBillingService.delete(billing)
                .then(() => {
                    _.remove(this.billings, (b) => b.id == billing.id);
                    _.each(this.billingViewItems, (billings: BillingViewItem[]) => {
                        _.remove(billings, (b) => b.id == billing.id);
                    });
                    _.each(this.filteredBillingViewItems, (billings: BillingViewItem[]) => {
                        _.remove(billings, (b) => b.id == billing.id);
                    });
                    this.Messages.success('Billing period successfully deleted.');
                })
                .catch((error) => {
                    this.Messages.error('Error deleting billing period. Please contact support.');
                });
        }
        public getNumberOfSteps(billingItem: BillingViewItem) {
            const billing = _.find(this.billings, (b) => b.id == billingItem.id);
            if (!billing) {
                return;
            }
            return this.getNumberOfStepsInternal(billing);
        }
        public getNextStepTitle(billingItem: BillingViewItem) {
            const billing = _.find(this.billings, (b) => b.id == billingItem.id);
            const stepTitles = billing.hasManualMeters
                ? ['Configuration', 'Rates', 'Manual Meters', 'Charges', 'Approve', 'Summary']
                : ['Configuration', 'Rates', 'Charges', 'Approve', 'Summary'];
            const index = this.getCompletedStep(billing);
            return stepTitles[index];
        }
        public getCompletedStep(monthlyBilling: MonthlyTenantBilling) {
            const correction = monthlyBilling.hasManualMeters ? 1 : 0;
            if (this.MonthlyBillingService.isApprovedStatus(monthlyBilling.status)) {
                return this.steps.approve + correction;
            }
            if (!monthlyBilling.needsUserAction && monthlyBilling.ratesConfirmed
                && (!monthlyBilling.hasManualMeters || monthlyBilling.hasManualMeters && monthlyBilling.hasEnteredManualMeters)) {
                return this.steps.charges + correction;
            }
            if (monthlyBilling.hasManualMeters && monthlyBilling.hasEnteredManualMeters && monthlyBilling.ratesConfirmed) {
                return this.steps.manualMeters;
            }
            if (monthlyBilling.ratesConfirmed) {
                return this.steps.rates;
            }
            return this.steps.configuration;
        }
        public editTenants() {
            window.top.postMessage({ type: 'monolith-navigate', data: { 'page': 'editTenants', 'buildingId': this.building.id } }, '*');
        }
        public editSettings() {
            window.top.postMessage({ type: 'monolith-navigate', data: { 'page': 'tenantBillingSettings', 'buildingId': this.building.id } }, '*');
        }

        private foundIn(searchInArray: string[], searchFor: string): boolean {
            return searchInArray.some((searchIn) => {
                if (searchIn && _.includes(searchIn.toUpperCase(), searchFor.toUpperCase())) {
                    return true;
                }
            });
        }
        private getNumberOfStepsInternal(billing: MonthlyTenantBilling) {
            const stepCount = 4;
            if (billing.hasManualMeters) {
                return stepCount + 1;
            }
            return stepCount;
        }
    }
    angular
        .module('tenantBilling')
        .controller('TenantBillingInvoicingCtrl', TenantBillingInvoicingCtrl);
}
