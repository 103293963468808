angular.module('aq-main').run(function($httpBackend) {
    const regexp = /^\/api\/v1\/accounts\/.*\/buildings\/.*\/baseloadStats/;
    const monthBaseLoadStats: any = {
        months: [{
            date: new Date('2015-01-01').getTime(),
            numGoodDays: 10,
            numBadDays: 20,
            avgRatio: 85,
            avgBaseload: 420,
            corey: false
        }, {
            date: new Date('2015-02-01').getTime(),
            numGoodDays: 11,
            numBadDays: 17,
            avgRatio: 82,
            avgBaseload: 413,
            corey: false
        }, {
            date: new Date('2015-03-01').getTime(),
            numGoodDays: 25,
            numBadDays: 6,
            avgRatio: 60,
            avgBaseload: 255,
            corey: true
        }, {
            date: new Date('2015-04-01').getTime(),
            numGoodDays: 28,
            numBadDays: 2,
            avgRatio: 54,
            avgBaseload: 253,
            corey: true
        }]
    };
    const dayBaseLoadStats: any = {};
    MockContext('baseloadstats', () => {
        $httpBackend.whenGET(regexp).respond((method, url, data) => {
        let params = MockHelpers.getParamsAsObject(url);
        let days = [];
        let start = moment(params.start);
        _.times(start.daysInMonth(), function(n) {
                let baseload = _.random(300, 500);
                let topload = _.random(500, 600);
                let ratio = Math.floor(baseload / topload);
                start = moment(params.start);
                dayBaseLoadStats.days.push({
                    date: start.add(n, 'days').valueOf(),
                    ratio,
                    baseload,
                    topload,
                    corey: ratio <= 50
                });
            });
        monthBaseLoadStats.days = days;
        return [200, monthBaseLoadStats, {}];
    });
    });

});