'use strict';

angular.module('accounts').controller('AccountsMainCtrl',
    function ($scope, Messages, Restangular, Errors, OptionsService, invitations) {
        $scope.accountLoaded = false;
        $scope.invitations = invitations;

        $scope.loadAccount = function () {
            var inflateAccount = 'buildingGroups,accountMetrics,owner,possibleOwners,license,license.functionalities,allApps,functionalities';
            $scope.account.get({ single: true, inflate: inflateAccount }).then(function (account) {
                $scope.account = account;

                Restangular.restangularizeCollection(account, account.buildingGroups, 'BuildingGroups');
                Restangular.restangularizeCollection(account, account.accountMetrics, 'AccountMetrics');
                Restangular.restangularizeCollection(account, account.profiles, 'profiles');

                $scope.accountLoaded = true;
            });

            $scope.account.all('UserRoles').getList({ inflate: 'permissions' }).then(function (roles) {
                $scope.roles = roles;
                _.map($scope.roles, function (role) {
                    role.value = role.id;
                    role.label = role.name;
                    return role;
                });
            });
        }

        OptionsService.fetchAll().then(function (results) {
            $scope.options = results;
            $scope.mainCurrencyUnit = _.filter($scope.options.currencyUnits, function (currencyUnit) {
                return currencyUnit.mainUnit;
            });
            $scope.areaMeasurementUnit = OptionsService.areaMeasurementUnit();
        })

        Restangular.one('options', 'allTimezones').get().then(function (timezones) {
            $scope.timezones = timezones;
        });

        $scope.account.customGET('queryRealUnits').then(function (results) {
            $scope.realUnits = results;
        });

        $scope.loadAccount();

    });
