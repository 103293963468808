namespace aq.components {

    import MeterStatus = aq.models.interval.MeterStatus;

    export class DeviceReadingsComponent {
        building: aq.common.models.Building;
        user: any;
        device: aq.common.models.Device;
        manualReadings: aq.common.models.ManualReading[];
        interval: aq.models.interval.IntervalDataSyncResponse;
        hasIntervalData: boolean;
        collector: aq.common.models.Collector;
        readingsComparisonTool;
        timeZoneId;
        shortDateFormat: string;
        longDateFormat: string;
        readingsComparison;
        public onChange: Function;
        metricLabel: { unit: string };
        private loading: boolean;
        /* @ngInject */
        constructor(
            private $scope,
            private $state,
            private $filter,
            private Errors,
            private RestangularV3,
            private $stateParams,
            private Messages,
            private $interval,
            public $mdDialog: ng.material.IDialogService,
            private OptionsService,
            private $modal,
            private DeviceService: aq.services.DeviceService,
            private Auth: aq.services.Auth,
        ) {
        }

        // Reading on component lifecycle hooks https://toddmotto.com/angular-1-5-lifecycle-hooks
        $onInit() {
            // manual reading and comparison tool timezones
            this.shortDateFormat = 'lll';
            this.longDateFormat = 'llll';
            this.timeZoneId = this.building.timeZoneId;
            if (this.user.timeZoneId !== this.building.timeZoneId) {
                this.shortDateFormat += ' z';
                this.longDateFormat += ' z';
            }

            this.readingsComparisonTool = {
                startReading: {},
                endReading: {},
                startReadingList: this.manualReadings,
                endReadingList: this.manualReadings
            };
            this.OptionsService.init(this.building.account, 'US_CUSTOMARY_UNITS', null).then(() => {
                this.metricLabel = this.OptionsService.getUnitLabelByMeasure(this.collector.metrics[0]);
            });
            this.hasIntervalData = false;
            this.getSyncData();
        };

        public async getSyncData() {
            this.loading = true;
            try {
                const response = await this.RestangularV3.one('interval').customGET(`getSyncData/${this.device.id}`);
                if (response.status !== MeterStatus.NOT_INTERVAL_METER) {
                    this.hasIntervalData = true;
                    this.interval = response;
                } else {
                    this.hasIntervalData = false;
                }
                this.loading = false;
            } catch (err) {
                this.hasIntervalData = false;
                this.loading = false;
            }
        }

        addManualReading() {
            (this.$mdDialog as any).show({
                targetEvent: event,
                templateUrl: 'app/deviceManagement/device/components/Readings/newReading.html',
                controller: 'NewReadingCtrl',
                controllerAs: 'vm',
                multiple: true,
                clickOutsideToClose: true,
                locals: {
                    collector: this.collector,
                    manualReading: {},
                    metricLabelUnit: this.metricLabel.unit,
                    timeZoneId: this.building.timeZoneId
                }
            })
            .then((newManualReading) => {
                const manualReading = angular.copy(newManualReading);
                const zonelessDate = moment(manualReading.viewOnly.date).set('hours', manualReading.viewOnly.hour).set('minutes',
                manualReading.viewOnly.minute).format('YYYY-MM-DD HH:mm:ss');
                manualReading.timestamp = moment.tz(zonelessDate, this.timeZoneId).format();
                this.DeviceService.saveReading(manualReading).then(() => {
                    this.$scope.$emit('MANUAL_READING_CREATED', { manualReading });
                });
                this.onDeviceChange();
            });
        };

        updateStartReadingList() {
            this.readingsComparisonTool.startReadingList = this.manualReadings.filter(
                (reading) => {
                    if (
                        reading.id ===
                        this.readingsComparisonTool.endReading.id
                    ) {
                        return false;
                    }
                    if (
                        moment(reading.timestamp).isAfter(
                            moment(
                                this.readingsComparisonTool
                                    .endReading.timestamp
                            )
                        )
                    ) {
                        return false;
                    }
                    return true;
                }
            );
        };

        calculateReadingComparison() {
            this.collector
                .customGET('readingsComparison', {
                    startReading: this.readingsComparisonTool
                        .startReading.id,
                    endReading: this.readingsComparisonTool
                        .endReading.id,
                    date: new Date()
                })
                .then((readingsComparison) => {
                    this.readingsComparison = readingsComparison;
                    this.Messages.success(
                        'Successfully calculated readings comparison.'
                    );
                })
                .catch((error) => {
                    this.Messages.error('Error calculating comparison.');
                });
        };

        formatTimestamp(timestamp) {
            return moment(timestamp).format('MMM D, YYYY hh:mm a');
        };

        deleteReading(event, manualReading: aq.common.models.ManualReadingElement) {
            this.DeviceService.deleteReading(event, manualReading).then(() => {
                this.onDeviceChange();
            });
        };

        updateEndReadingList() {
            this.readingsComparisonTool.endReadingList = this.manualReadings.filter(
                (reading) => {
                    if (
                        reading.id ===
                        this.readingsComparisonTool.startReading.id
                    ) {
                        return false;
                    }
                    if (
                        moment(reading.timestamp).isBefore(
                            moment(
                                this.readingsComparisonTool
                                    .startReading.timestamp
                            )
                        )
                    ) {
                        return false;
                    }
                    return true;
                }
            );
        };

        editManualReading(manualReading) {
            (this.$mdDialog as any).show({
                targetEvent: event,
                templateUrl: 'app/deviceManagement/device/components/Readings/newReading.html',
                controller: 'NewReadingCtrl',
                controllerAs: 'vm',
                multiple: true,
                clickOutsideToClose: true,
                    locals: {
                        collector: this.collector,
                        manualReading,
                        metricLabelUnit: this.metricLabel.unit,
                        timeZoneId: this.building.timeZoneId
                    }
                })
                .then((newManualReading) => {
                    const manualReading = angular.copy(newManualReading);
                    const zonelessDate = moment(manualReading.viewOnly.date).set('hours', manualReading.viewOnly.hour).set('minutes',
                    manualReading.viewOnly.minute).format('YYYY-MM-DD HH:mm:ss');
                    manualReading.timestamp = moment.tz(zonelessDate, this.timeZoneId).format();
                    return this.DeviceService.updateReading(manualReading).then(() => {
                        this.onDeviceChange();
                    });
                });
        };

        private onDeviceChange() {
            this.onChange({
                $event: {
                    device: this.device
                }
            });
        }

        $onChanges(changes) {}
        $postLink() {}
    }

    angular.module('aq.ui').component('deviceReadings', {
        templateUrl:
            'app/deviceManagement/device/components/Readings/readings.html',
        controller: aq.components.DeviceReadingsComponent,
        controllerAs: 'vm',
        bindings: {
            building: '<',
            user: '<',
            device: '<',
            manualReadings: '<',
            collector: '<',
            onChange: '&'
        }
    });
}
