namespace aq.admin.configuration {
    import Account = aq.common.models.Account;
    import Building = aq.common.models.Building;

    export class TemplatesCtrl {
        private selectedAccount: Account;
        private selectedBuilding: Building;
        private accounts: Account[];
        private buildings: Building[];
        private isDownloading: boolean;

        /* @ngInject */
        constructor(
            private DocumentTemplateService: aq.services.DocumentTemplateService,
            private Restangular: restangular.IService,
            private BudgetServiceV3: aq.services.BudgetServiceV3,
            private $timeout: ng.ITimeoutService,
            private FileHandlerService: aq.services.FileHandlerService
        ) {
            this.getAccounts();
            this.isDownloading = false;
        }

        public uploadTemplateDocument() {
            this.DocumentTemplateService.pickDocumentTemplate(aq.services.DocumentTemplateType.BUDGET_PLANNER);
        }

        public downloadTemplateDocument() {
            const link = document.createElement('a');
            link.href = this.DocumentTemplateService.getTemplateDownloadUrl(
                aq.services.DocumentTemplateType.BUDGET_PLANNER
            );
            link.target = '_blank';
            link.click();
        }

        public getAccounts() {
            this.Restangular.all('accounts').get('queryAllAccounts').then((accounts) => {
                    this.accounts = accounts;
            }).catch((error) => {
                console.error(error);
            });
        }

        public selectAccount() {
            this.selectedBuilding = null;
            this.buildings = null;
            this.Restangular.one('accounts', this.selectedAccount.id).getList('buildings')
                .then((buildings) => {
                    this.buildings = buildings;
            });
        }

        public showDownload() {
            if(this.selectedAccount && this.selectedBuilding) {
                return true;
            }
            return false;
        }

        public downloadBudgetPlanner() {
            this.isDownloading = true;
            return this.BudgetServiceV3.getBudgetPlannerTemplate(parseInt(this.selectedBuilding.id))
                .then((response) => {
                    this.$timeout(() => {
                        this.FileHandlerService.handleFileDownload(response, 'application/vnd.ms-excel');
                        this.isDownloading = false;
                    }, 0);
                })
                .catch((error) => {
                    console.error(error);
                    this.isDownloading = false;
                });
        }
    }

    angular
        .module('aq.admin.configuration')
        .controller('TemplatesCtrl', TemplatesCtrl);
}
