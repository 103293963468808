namespace aq.dashboard.widgets {

    export interface SeriesDataOptions {
        building: aq.common.models.Building;
        series: any;
        period: any;
        config: any;
        unit: any;
        momentDateFormat: any;
        timeZoneId: any;
        noCache?: any;
        tenants: aq.common.models.Tenant[];
        isMissingData?: boolean;
    }

    export interface SingleBuildingSeriesOptions {
        account: aq.common.models.Account;
        building: aq.common.models.Building;
        tenants: aq.common.models.Tenant[];
    }

    export interface MultiBuildingSeriesOptions {
        account: aq.common.models.Account;
        buildings: aq.common.models.Building[];
        tenants: aq.common.models.Tenant[];
    }

    export class GraphData {
        private abortDefers;
        /* @ngInject */
        constructor(
            private DataService,
            private $q: any,
            private $filter: ng.IFilterService,
            private DashboardOptionsService,
            private Restangular,
            private BaseService,
            private Auth: aq.services.Auth,
            private EmptyDataService: aq.dashboard.widgets.EmptyDataService
        ) {
            this.abortDefers = [];
        }

        public abort() {
            _.each(this.abortDefers, (defer: any) => {
                defer.resolve();
            });
        }

        public getAllSeriesDataEmpty(config, options: SingleBuildingSeriesOptions, momentDateFormat: string): any {
            let period = {};

            return _.map(config.series, (series: any) => {
                const unit = this.DashboardOptionsService.getUnitByEnumName(series.measure);

                // no cache param forces a new request to the data api so that we can get the most recent data
                period = this.calculateGraphPeriod(series, options.building.timeZoneId);
                return this.getSeriesDataEmpty({
                    building: options.building,
                    series,
                    period,
                    config,
                    unit,
                    momentDateFormat,
                    timeZoneId: options.building.timeZoneId,
                    noCache: true,
                    tenants: options.tenants
                });
            });
        }

        public getAllSeriesData(config, options: SingleBuildingSeriesOptions, momentDateFormat: string): any {
            let period = {};
            let promise;
            const promises: ng.IPromise<any[]>[] = [];
            const { account } = options;
            if (!account) {
                throw new Error('Account required to get series data');
            }

            return this.DashboardOptionsService.init(account.id, account.measurementSystem, account.currencyUnit).then(() => {
                config.series.filter((series) => {
                    return series.expected ? config.preset !== 'trailing year' : true;
                }).map((series) => {
                    const unit = this.DashboardOptionsService.getUnitByEnumName(series.measure);
                    // no cache param forces a new request to the data api so that we can get the most recent data
                    const noCache: boolean = this.checkForCaching(config.preset, series.trend);
                    period = this.calculateGraphPeriod(series, options.building.timeZoneId);
                    promise = this.getSeriesData({
                        building: options.building,
                        series,
                        period,
                        config,
                        unit,
                        momentDateFormat,
                        timeZoneId: options.building.timeZoneId,
                        noCache,
                        tenants: options.tenants
                    }, account);
                    promises.push(promise);
                });

                return this.$q.all(promises);
            });
        }

        public getMultipleBuildingSeriesData(config, options: MultiBuildingSeriesOptions, momentDateFormat?: string): any {
            let period = {};
            let promise;
            const promises: ng.IPromise<any[]>[] = [];
            const { account } = options;
            if (!account) {
                throw new Error('Account required to get series data');
            }

            return this.DashboardOptionsService.init(account.id, account.measurementSystem, account.currencyUnit).then(() => {
                _.map(config.series, (series: any) => {
                    const unit = this.DashboardOptionsService.getUnitByEnumName(series.measure);

                    // no cache param forces a new request to the data api so that we can get the most recent data
                    const noCache: boolean = this.checkForCaching(config.preset, series.trend);
                    options.buildings.forEach((building) => {
                        period = this.calculateGraphPeriod(series, building.timeZoneId);

                        promise = this.getSeriesData({
                            building,
                            series,
                            period,
                            config,
                            unit,
                            momentDateFormat,
                            timeZoneId: building.timeZoneId,
                            noCache,
                            tenants: options.tenants
                        }, account);
                        promises.push(promise);
                    });
                });

                return this.$q.all(promises);
            });
        }

        public checkForCaching(preset, trend): boolean {
            return (preset === 'today' || preset === 'trailing day' || preset === 'trailing hour') && !trend;
        }

        /**
            Calculate graph period for series,return data period start and end for each series
        */
        public calculateGraphPeriod(series, timeZoneId): aq.dashboard.models.DataPeriod {
            const dataPeriod = new aq.dashboard.models.DataPeriod();
            // Note: this is for hardcoded widgets only - not available to set through UI
            if (series.customDate) {
                dataPeriod.end = moment(series.customDate.end).tz(timeZoneId);
                dataPeriod.start = moment(series.customDate.start).tz(timeZoneId);
                return dataPeriod;
            }
            if (series.customTrend && series.trend) {
                // if series is trend data and we have custom Date range configured
                dataPeriod.start = moment(series.customTrend.start).tz(timeZoneId).startOf(series.end.round);
                dataPeriod.end = moment(series.customTrend.start).clone().tz(timeZoneId).startOf(series.end.round)
                    .add(series.duration.offset, series.duration.unit);
                return dataPeriod;
            }
            if (series.end) {
                if (series.end.date === 'now') {
                    const { offset } = series.end;
                    const { unit } = series.end;
                    const { round } = series.end;
                    const { operation } = series.end;
                    if (operation === 'add') {
                        dataPeriod.end = moment().tz(timeZoneId).startOf(round).add(offset, unit);
                    } else {
                        dataPeriod.end = moment().tz(timeZoneId).startOf(round).subtract(offset, unit);
                    }
                } else if (series.end.date === 'currentweek') {
                    const offset = series.end.offset;
                    const unit = series.end.unit;
                    const round = series.end.round;
                    dataPeriod.end = moment().tz(timeZoneId).endOf(round).add(offset, unit);
                } else {
                    dataPeriod.end = moment(series.end).tz(timeZoneId);
                }
                if (series.trend && series.trendPeriod != null) {
                    const trend = TrendPeriods.getTrendPeriod(series.trendPeriod.toLowerCase());
                    dataPeriod.end = moment(dataPeriod.end).tz(timeZoneId).subtract(trend.offset, trend.unit);
                }
                if (series.expected) {
                    dataPeriod.end = moment(dataPeriod.end).tz(timeZoneId);
                }
            }

            if (series.duration) {
                const duration = series.duration.offset ? series.duration.offset : null;
                const durationUnit = series.duration.unit ? series.duration.unit : null;
                if (series.end.date === 'currentmonth') {
                    const round = series.end.round;
                    dataPeriod.start = moment(dataPeriod.end).tz(timeZoneId).subtract(duration, durationUnit).endOf(round);
                } else {
                    dataPeriod.start = moment(dataPeriod.end).tz(timeZoneId).subtract(duration, durationUnit);
                }
            }
            return dataPeriod;
        }

        public getSeriesDataEmpty(seriesDataOptions: SeriesDataOptions): any[] {
            const { building, period, config } = seriesDataOptions;
            const step = config.interval;
            const emptyData = this.EmptyDataService.getEmptyTimestampsAndValuesForPeriod(step, period);
            const data = {
                values: emptyData.values,
                timestamps: emptyData.timestamps,
                start: period.start,
                end: period.end,
                name: building.name,
                id: building.id
            };
            return this.mapToDataValues(data, 'values', this.$filter<Function>('toUnit'), seriesDataOptions);
        }

        public getSeriesData(seriesDataOptions: SeriesDataOptions, account: aq.common.models.Account): ng.IPromise<any[]> {
            const {
                building, series, period, config, unit, noCache
            } = seriesDataOptions;
            const buildingQueryable = [
                {
                    route: 'accounts',
                    id: account.id
                },
                {
                    route: 'buildings',
                    id: building.id
                }
            ];
            const moreParams: any = {};
            if (noCache) {
                moreParams.timestamp = moment().valueOf();
            }

            // get queryable from config, if absent use building-level data
            const currentObj = series.queryable ? series.queryable : buildingQueryable;

            const collection = this.BaseService.getCurrentRestangularObject(currentObj);
            const defer = this.$q.defer();
            collection.withHttpConfig({ timeout: defer.promise });
            this.abortDefers.push(defer);

            if (unit.apiUnit === 'TEMPERATURE') {
                return this.DataService.temperature(collection, config.interval, period.start, period.end, moreParams).then((data) => {
                    return this.mapToDataValues(data, 'values', this.$filter<Function>('toTemperatureUnit'), seriesDataOptions);
                });
            } if (unit.apiUnit === 'OUTDOOR_HUMIDITY') {
                return this.DataService.outdoorHumidity(collection, config.interval, period.start, period.end, moreParams).then((data) => {
                    return this.mapToDataValues(data, 'values', this.$filter<Function>('toHumidityUnit'), seriesDataOptions);
                });
            } if (unit.apiUnit === 'WET_BULB') {
                return this.DataService.wetBulbTemperature(collection, config.interval, period.start, period.end, moreParams).then((data) => {
                    return this.mapToDataValues(data, 'values', this.$filter<Function>('toTemperatureUnit'), seriesDataOptions);
                });
            } if (series.expected) {
                return this.DataService.expectedEnergy(collection, config.interval, period.start, period.end).then((data) => {
                    return this.mapToExpectedDataValues(data, 'POWER_EXPECTED', 'POWER_STDDEV', this.$filter<Function>('toUnit'), seriesDataOptions);
                });
            } if (config.statistic) {
                // Don't need anything related to 'per square foot' here since this is only used in Stats widget,
                // which takes care of this in a different way.
                if (unit.isSummable) {
                    return this.DataService.metrics(collection, config.interval, period.start, period.end, unit, moreParams).then((data) => {
                        return {
                            values: data.values, start: period.start, end: period.end, building: building.id, trend: series.trend
                        };
                    });
                }
                return this.DataService.metrics(collection, '15min', period.start, period.end, unit, moreParams).then((data) => {
                    return {
                        values: data.values, start: period.start, end: period.end, building: building.id, trend: series.trend
                    };
                });
            }
            return this.DataService.data(collection, config.interval, period.start, period.end, unit, moreParams).then((data) => {
                if (seriesDataOptions.series.type !== 'column') {
                    const dataValues = this.mapToDataValues(data, 'values', this.$filter<Function>('toUnit'), seriesDataOptions, 'missingIntervalValues');
                    seriesDataOptions.isMissingData = true;
                    const missingDataValues = this.mapToDataValues(data, 'missingIntervalValues', this.$filter<Function>('toUnit'), seriesDataOptions, 'values');
                    return [
                        dataValues,
                        missingDataValues
                    ];
                }
                seriesDataOptions.isMissingData = false;
                const columnDataValues = this.mapToDataValuesForColumn(data, this.$filter<Function>('toUnit'), seriesDataOptions);
                return [
                    columnDataValues
                ];
            });
        }

        public mapToDataValuesForColumn(data, filter, seriesDataOptions: SeriesDataOptions): any {
            if (data.values.length !== data.missingIntervalValues.length) {
                return;
            }
            const dataValues: aq.dashboard.models.DataValue[] = [];
            for (let i = 0; i < data.values.length; i++) {
                if (data.missingIntervalValues[i] !== null) {
                    dataValues.push({
                        timestamp: data.timestamps[i],
                        value: this.calculateDataValue(data.timestamps[i], data.missingIntervalValues[i], filter, seriesDataOptions),
                        color: this.transparentColor(seriesDataOptions.series.color),
                        isMissingData: true
                    });
                } else {
                    dataValues.push({
                        timestamp: data.timestamps[i],
                        value: this.calculateDataValue(data.timestamps[i], data.values[i], filter, seriesDataOptions),
                        color: seriesDataOptions.series.color,
                        isMissingData: false
                    });
                }
            }
            return this.buildSeries(dataValues, data, seriesDataOptions);
        }

        public mapToDataValues(data, valuesField: string, filter, seriesDataOptions: SeriesDataOptions, otherValuesField?: string): any {
            const dataValues: aq.dashboard.models.DataValue[] = [];
            for (let i = 0; i < data[valuesField].length; i++) {
                if (i > 0 && seriesDataOptions.series.type !== 'column' && otherValuesField) {
                    if (data[valuesField][i - 1] === null && data[otherValuesField][i - 1] !== null && data[valuesField][i] !== null) {
                        dataValues[i - 1] = {
                            timestamp: data.timestamps[i - 1],
                            value: this.calculateDataValue(data.timestamps[i], data[otherValuesField][i - 1], filter, seriesDataOptions)
                        };
                        dataValues.push({
                            timestamp: data.timestamps[i],
                            value: this.calculateDataValue(data.timestamps[i], data[valuesField][i], filter, seriesDataOptions)
                        });
                    } else {
                        dataValues.push({
                            timestamp: data.timestamps[i],
                            value: this.calculateDataValue(data.timestamps[i], data[valuesField][i], filter, seriesDataOptions)
                        });
                    }
                } else {
                    dataValues.push({
                        timestamp: data.timestamps[i],
                        value: this.calculateDataValue(data.timestamps[i], data[valuesField][i], filter, seriesDataOptions)
                    });
                }
            }
            return this.buildSeries(dataValues, data, seriesDataOptions);
        }

        public mapToExpectedDataValues(data, powerField, stdevField, filter, seriesDataOptions: SeriesDataOptions): any {
            // expected data is at 15m interval, take average on frontend to be compatible with other graph series
            let step = null;
            switch (seriesDataOptions.config.interval) {
                case '15min':
                    step = 1;
                    break;
                case '1h':
                default:
                    step = 4;
                    break;
            }
            const dataValues: aq.dashboard.models.RangeDataValue[] = [];
            let i = 0;
            while (i < data[powerField].values.length) {
                if (step === 1) {
                    // series interval is 15min: no averaging is required
                    const dataValue = data[powerField].values[i];
                    const stdevValue = stdevField ? data[stdevField].values[i] : null;
                    const stdev = stdevField ? stdevValue : dataValue / 5;
                    dataValues.push({
                        timestamp: data[powerField].timestamps[i],
                        value: [
                            this.calculateExpectedDataValue(data[powerField].timestamps[i], (dataValue - stdev), filter, seriesDataOptions),
                            this.calculateDataValue(data[powerField].timestamps[i], (dataValue + stdev), filter, seriesDataOptions)
                        ]
                    });
                } else {
                    const hourValues = _.slice(data[powerField].values, i, i + step);
                    const stdevValues = stdevField ? _.slice(data[stdevField].values, i, i + step) : null;
                    const average = _.mean(hourValues);
                    const stdev = stdevField ? _.mean(stdevValues) : average / 5;
                    dataValues.push({
                        timestamp: data[powerField].timestamps[i],
                        value: [
                            this.calculateExpectedDataValue(data[powerField].timestamps[i], (average - stdev), filter, seriesDataOptions),
                            this.calculateDataValue(data[powerField].timestamps[i], (average + stdev), filter, seriesDataOptions)
                        ]
                    });
                }

                i += step;
            }

            return this.buildExpectedSeries(dataValues, data[powerField], seriesDataOptions);
        }

        public calculateExpectedDataValue(timestamp, value, filter, seriesDataOptions: SeriesDataOptions) {
            return (value && value > 0) ? this.roundDataValue(filter(value, seriesDataOptions.unit)) : 0;
        }

        public calculateDataValue(timestamp, value, filter, seriesDataOptions: SeriesDataOptions) {
            const { timeZoneId, unit, config: { interval } } = seriesDataOptions;
            let timeUnit = 'days';
            switch (interval) {
                case '1min': timeUnit = 'minutes'; break;
                case '1h': timeUnit = 'hours'; break;
                case '1d': timeUnit = 'days'; break;
                case '1mon': timeUnit = 'months'; break;
                default:
            }

            const isFuture = moment().tz(timeZoneId).startOf(timeUnit).diff(moment(timestamp).tz(timeZoneId), timeUnit);
            if (isFuture <= 0 && !value) {
                return null;
            } if (!value) {
                return null;
            }
            return this.roundDataValue(filter(value, unit));
        }

        public roundDataValue(input: number) {
            let value = 0;
            if (input == null) value = 0;
            if (input > 10 || input < -10) return parseFloat(input.toFixed(0));
            if ((input > 0 && input < 10) || (input < 0 && input > -10)) {
                value = parseFloat(input.toFixed(2));
            }
            if ((input > 0 && input < 1) || (input < 0 && input > -1)) {
                value = parseFloat(input.toFixed(3));
            }
            // for teeny numbers
            if ((input > 0 && input < 0.001) || (input < 0 && input > -0.001)) {
                value = parseFloat(input.toFixed(5));
            }

            return value;
        }

        public buildSeries(series, data, seriesDataOptions: SeriesDataOptions) {
            const { timeZoneId, unit, series: seriesConfig } = seriesDataOptions;
            return {
                name: seriesConfig.title ? seriesConfig.title : data.name,
                type: seriesConfig.expected ? 'arearange' : seriesConfig.type,
                linkedTo: seriesDataOptions.isMissingData ? ':previous' : undefined,
                color: seriesConfig.color != 'none'
                    ? (seriesDataOptions.isMissingData ? this.transparentColor(seriesConfig.color) : seriesConfig.color)
                    : (seriesDataOptions.isMissingData ? this.transparentColor(seriesConfig.customColor) : seriesConfig.customColor),
                legendIndex: 3,
                zIndex: 1,
                yAxis: seriesConfig.yAxis ? parseInt(seriesConfig.yAxis) : 0,
                startTime: series[0] ? moment(series[0].timestamp).tz(timeZoneId) : null,
                endTime: series[0] ? moment(series[series.length - 1].timestamp).tz(timeZoneId) : null,
                data: seriesConfig.expected ? this.convertToRangePoints(series, seriesDataOptions)
                    : this.convertToDataPoints(series, seriesDataOptions),
                tooltip: {
                    valuePrefix: unit.unit == '$' ? ` ${unit.unit}` : null,
                    valueSuffix: unit.unit == '$' ? null : ` ${unit.unit}`
                }
            };
        }

        public buildExpectedSeries(series, data, seriesDataOptions: SeriesDataOptions) {
            const { timeZoneId, unit, series: seriesConfig } = seriesDataOptions;
            return {
                name: seriesConfig.title ? seriesConfig.title : data.name,
                type: seriesConfig.expected ? 'arearange' : seriesConfig.type,
                color: seriesConfig.color,
                legendIndex: 3,
                zIndex: 1,
                yAxis: seriesConfig.yAxis ? parseInt(seriesConfig.yAxis) : 0,
                startTime: series[0] ? moment(series[0].timestamp).tz(timeZoneId) : null,
                endTime: series[0] ? moment(series[series.length - 1].timestamp).tz(timeZoneId) : null,
                data: seriesConfig.expected ? this.convertToRangePoints(series, seriesDataOptions)
                    : this.convertToDataPoints(series, seriesDataOptions),
                tooltip: {
                    valuePrefix: unit.unit == '$' ? ` ${unit.unit}` : null,
                    valueSuffix: unit.unit == '$' ? null : ` ${unit.unit}`
                }
            };
        }

        public convertToDataPoints(dataValues: aq.dashboard.models.DataValue[], seriesDataOptions: SeriesDataOptions, valueField = 'value'): any[] {
            const { unit, timeZoneId, momentDateFormat } = seriesDataOptions;
            let unitSuffix = true;
            if (unit.apiUnit.indexOf('CURRENCY') >= 0) {
                unitSuffix = false;
            }
            const maxDecimalPlaces = this.isSFMeasure(seriesDataOptions.series.measure) ? 5 : 0;
            return dataValues.map((energyValue: any) => {
                const value = this.convertIfValuePerSF(energyValue[valueField], seriesDataOptions);
                const dataPoint = {
                    y: value,
                    timestamp: moment(energyValue.timestamp).tz(timeZoneId).format(momentDateFormat),
                    rawTime: moment(energyValue.timestamp).tz(timeZoneId),
                    isMissingData: energyValue.isMissingData,
                    tooltip: this.$filter<Function>('numForm')(value, maxDecimalPlaces),
                    color: null
                };
                if (seriesDataOptions.series.type === 'column') {
                    dataPoint.color = energyValue.color;
                }
                if (unitSuffix) {
                    dataPoint.tooltip += ` ${unit.unit}`;
                } else {
                    dataPoint.tooltip = `${unit.unit} ${dataPoint.tooltip}`;
                }
                return dataPoint;
            });
        }

        public convertToRangePoints(dataValues: aq.dashboard.models.RangeDataValue[], seriesDataOptions: SeriesDataOptions, valueField = 'value'): any[] {
            return dataValues.map((energyValue: any) => {
                return this.convertIfValuePerSF(energyValue[valueField], seriesDataOptions);
            });
        }

        public getDrillin(accountId, mode, filter = this.$filter<Function>('flattenHierarchy')): any {
            return this.Restangular.one('accounts', accountId).all('drillin')
                .getList({ mode })
                .then((drillin) => {
                    const drillinObj = {};
                    _.each(drillin, (d) => {
                        d.route = 'buildings';
                        drillinObj[d.id] = filter([d], 'children', ['id', 'route', 'parent']);
                        drillinObj[d.id] = _.filter(drillinObj[d.id], (drilldown: any) => {
                            return drilldown.level != 1 && (drilldown.route != 'resources' || drilldown.childrenRoute != 'points');
                        });
                        drillinObj[d.id] = _.map(drillinObj[d.id], (drilldown) => {
                            return _.pick(drilldown, ['metrics', 'name', 'parent', 'id', 'route']);
                        });
                    });
                    return drillinObj;
                });
        }

        public convertIfValuePerSF(value, seriesDataOptions: SeriesDataOptions) {
            if (this.isSFMeasure(seriesDataOptions.series.measure)) {
                // up to max of 5 decimal places to match formatting in table widget
                if (seriesDataOptions.series.drillin === '') {
                    const { building } = seriesDataOptions;
                    return parseFloat((value / building.size).toFixed(5));
                }
                if (seriesDataOptions.series.drillin === 'tenant') {
                    const queryableId = seriesDataOptions.series.queryable[seriesDataOptions.series.queryable.length - 1].id;
                    const tenant = _.find(seriesDataOptions.tenants, { id: queryableId });
                    if (tenant) {
                        return parseFloat((value / tenant.size).toFixed(5));
                    }
                }
            }
            return value;
        }

        public isSFMeasure(measure) {
            return _.includes(measure, 'SQFT') || _.includes(measure, 'AREA');
        }

        private transparentColor(color: string): string {
            const alpha = 0.75;
            const convertedAlpha = Math.floor(alpha * 255);
            const alphaString = convertedAlpha < 16 ? `0${convertedAlpha.toString(16)}` : convertedAlpha.toString(16);
            return color + alphaString;
        }
    }

    angular.module('aq.dashboard.widgets').service('GraphData', GraphData);
}
