/**
 *  Report directive

 *  @param account - current account
 *  @param reportsMetadata - expects an array with report information:
 *   For now each object in that array should have two properties:
 *      name: report name
 *      url: function which will return URL parameter for this report
` *  @param recipients - array of Aquicore users who will receive a report
 *  @param user - creator of the report
 *  @param csvUrl - function that will return csvUrl for downloading CSV data (when clicking it in the dropdown)
 */
angular
    .module('aq.ui.reports', ['aq.ui.reports.service', 'aq.ui.reports.modal', 'aq.ui.reports.aside'])
    .directive('report', function () {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/common/directives/report/report.html',
            scope: {
                account: '=',
                user: '=',
                reportsMetadata: '=',
                recipients: '=',
                csvUrl: '&'
            },
            controller: function ($scope, ReportsWidgetService) {
                $scope.reportData = new ReportsWidgetService($scope.account);
            }
        }
    });