namespace mocks.data.devicemgmt {
    export const devices: aq.common.models.Device[] = [];
    export const spaces = [];
    export const floors = [];
    export let realHub: aq.common.models.Device = null;
    export let realBridge: aq.common.models.Device = null;
    export let realObviusPulseMeter: aq.common.models.Device = null;
    export let realMeterConnectedToHub1: aq.common.models.Device = null;
    export let realMeterConnectedToObvius: aq.common.models.Device = null;
    export let realMeterConnectedToBridge: aq.common.models.Device = null;
    const deviceIdentifierPattern = '(([0-9a-fA-F]{2})[\:|\\s|\-]?){6}$|^(([0-9a-fA-F]{2})[\:|\\s|\-]?){8}';
    let idCounter = 0;

    const getRandomMeasure = (): aq.common.models.DeviceMeasure =>
    MockHelpers.randomString([
        'ELECTRICITY',
        'WATER',
        'GAS',
        'HEAT',
        'STEAM'
    ]);
    const getRandomCategory = (): aq.common.models.DeviceCategory =>
        MockHelpers.randomString([
            'METER',
            'NETWORKING',
            'SENSOR',
            'EQUIPMENT',
            'OTHER'
        ]);

    const createSpace = (n: number) => {
        return {
            building: 22485218,
            devices: _.take(devices, 0, _.random(1, devices.length - 1)),
            floor: null,
            id: n,
            name: 'Common Area',
            size: _.random(100, 50000)
        };
    };

    const createFloor = (n: number) => {
        return {
            building: 22485218,
            id: n,
            name: `Floor ${n}`,
            spaces: _.take(spaces, 0, _.random(1, spaces.length - 1))
        };
    };


    for (let i = 0; i < 100; i++) {
        const created = moment()
            .dayOfYear(_.random(0, 364))
            .format();
        const device: aq.common.models.Device = <aq.common.models.Device> {
            id: `${idCounter++}`,
            measure: getRandomMeasure(),
            serialNumber: '',
            building: '22485218',
            name: MockHelpers.getRandomName(),
            enabled: !!_.random(0, 1),
            image: 'https://i.imgur.com/yyNfLhA.png',
            description: '',
            location: '',
            created,
            gateway: false,
            tenants: [
                {
                    id: _.random(0, 999999999),
                    name: MockHelpers.getRandomName()
                }
            ],
            deviceCategory: getRandomCategory(),
            deviceClass: {
                model: 'not-real',
                make: 'made-up',
                series: 'stuff',
                fields: {
                    deviceIdentifier: {
                        label: 'MAC Address',
                        value: '',
                        name: 'deviceIdentifier',
                        icon: '',
                        type: 'input',
                        hint: 'Unique ID for device',
                        pattern: deviceIdentifierPattern
                    }
                }
            },
            collectorMetric: '',
            links: []
        };
        devices.push(device);
    }
    realHub =  <aq.common.models.Device> {
        id: `${idCounter++}`,
        measure: null,
        serialNumber: 'ce272ccde59b807d7b1c19d7349b24be',
        building: '22485218',
        name: 'AQ - Hub 1',
        enabled: true,
        image: 'https://i.imgur.com/yyNfLhA.png',
        description: '',
        location: 'On the roof',
        deviceCategory: 'NETWORKING',
        gateway: true,
        deviceClass: {
            model: 'Aquicore',
            make: 'Aquicore Hub',
            series: '3i',
            fields: {
                deviceIdentifier: {
                    label: 'MAC Address',
                    value: '',
                    name: 'deviceIdentifier',
                    icon: '',
                    type: 'input',
                    hint: 'Unique ID for device',
                    pattern: deviceIdentifierPattern
                }
            }
        },
        collectorId: 26514919,
        links: [{
            device: realHub,
            protocol: 'AQUAMESH'
        }, {
            device: realObviusPulseMeter,
            protocol: 'MODBUS'
        }, {
            device: realMeterConnectedToHub1,
            protocol: 'PULSE',
            pulseId: 'PL01'
        }]
    };
    realBridge = <aq.common.models.Device> {
        id: `${idCounter++}`,
        measure: null,
        serialNumber: null,
        building: '22485218',
        name: 'bridge 1',
        enabled: true,
        image: 'https://i.imgur.com/yyNfLhA.png',
        description: '',
        location: '2nd Floor CLoset',
        deviceCategory: 'NETWORKING',
        gateway: false,
        deviceClass: {
            model: 'Aquicore',
            make: 'Aquicore Bridge',
            series: '3i',
            fields: {
                deviceIdentifier: {
                    label: 'MAC Address',
                    value: '',
                    name: 'deviceIdentifier',
                    icon: '',
                    type: 'input',
                    hint: 'Unique ID for device',
                    pattern: deviceIdentifierPattern
                }
            }
        },
        collectorId: 26587839,
        links: [{
            device: realHub,
            protocol: 'AQUAMESH'
        }, {
            device: realMeterConnectedToBridge,
            protocol: 'MODBUS'
        }]
    };
    realObviusPulseMeter = <aq.common.models.Device> {
        id: `${idCounter++}`,
        measure: null,
        serialNumber: null,
        building: '22485218',
        name: 'high density module',
        enabled: true,
        image: 'https://i.imgur.com/yyNfLhA.png',
        description: '',
        location: 'Next to the toilet',
        deviceCategory: 'METER',
        gateway: false,
        deviceClass: {
            model: 'Obvius',
            make: 'Obvius A8911-23 Pulse Meter',
            series: null,
            fields: {
                deviceIdentifier: {
                    label: 'MAC Address',
                    value: '',
                    name: 'deviceIdentifier',
                    icon: '',
                    type: 'input',
                    hint: 'Unique ID for device',
                    pattern: deviceIdentifierPattern
                }
            }
        },
        collectorId: 26586826,
        links: [{
            device: realHub,
            protocol: 'MODBUS'
        }, {
            device: realMeterConnectedToObvius,
            protocol: 'MODBUS'
        }]
    };
    realMeterConnectedToHub1 = <aq.common.models.Device> {
        id: `${idCounter++}`,
        measure: 'STEAM',
        serialNumber: null,
        building: '22485218',
        name: 'Julia',
        enabled: true,
        image: 'https://i.imgur.com/yyNfLhA.png',
        description: '',
        location: '1st floor tenant closet',
        deviceCategory: 'METER',
        gateway: false,
        deviceClass: {
            model: 'Generic',
            make: 'Generic Steam Meter',
            series: null,
            fields: {
                deviceIdentifier: {
                    label: 'MAC Address',
                    value: '',
                    name: 'deviceIdentifier',
                    icon: '',
                    type: 'input',
                    hint: 'Unique ID for device',
                    pattern: deviceIdentifierPattern
                }
            }
        },
        collectorId: 26603154,
        links: [{
            device: realHub,
            protocol: 'PULSE',
            pulseId: 'PL01'
        }]
    };
    realMeterConnectedToObvius = <aq.common.models.Device> {
        id: `${idCounter++}`,
        measure: 'ELECTRICITY',
        serialNumber: null,
        building: '22485218',
        name: 'Pulse 1',
        enabled: true,
        image: 'https://i.imgur.com/yyNfLhA.png',
        description: '',
        location: 'Another bathroom',
        deviceCategory: 'METER',
        gateway: false,
        deviceClass: {
            model: 'Generic',
            make: 'Generic Power Meter',
            series: null,
            fields: {
                deviceIdentifier: {
                    label: 'MAC Address',
                    value: '',
                    name: 'deviceIdentifier',
                    icon: '',
                    type: 'input',
                    hint: 'Unique ID for device',
                    pattern: deviceIdentifierPattern
                }
            }
        },
        collectorId: 26586852,
        links: [{
            device: realObviusPulseMeter,
            protocol: 'PULSE'
        }]
    };
    realMeterConnectedToBridge = <aq.common.models.Device> {
        id: `${idCounter++}`,
        measure: 'ELECTRICITY',
        serialNumber: null,
        building: '22485218',
        name: 'Pulse 1',
        enabled: true,
        image: 'https://i.imgur.com/yyNfLhA.png',
        description: '',
        location: 'Underneath the desk next to that thing',
        deviceCategory: 'METER',
        gateway: false,
        deviceClass: {
            model: 'Aquicore',
            make: 'Aquicore Meter',
            series: null,
            fields: {
                deviceIdentifier: {
                    label: 'MAC Address',
                    value: '',
                    name: 'deviceIdentifier',
                    icon: '',
                    type: 'input',
                    hint: 'Unique ID for device',
                    pattern: deviceIdentifierPattern
                }
            }
        },
        collectorId: 26587847,
        links: [{
            device: realBridge,
            protocol: 'MODBUS'
        }]
    };
    devices.push(realHub);
    devices.push(realBridge);
    devices.push(realMeterConnectedToBridge);
    devices.push(realMeterConnectedToHub1);
    devices.push(realMeterConnectedToObvius);
    devices.push(realObviusPulseMeter);

    devices.map(function(device: any) {
        const links = devices
            .filter(deviceLink => {
                return deviceLink.deviceCategory == 'NETWORKING';
            })
            .map(linkedDevice =>  _.omit(linkedDevice, 'links'));

        if (device.links.length === 0) {
            device.links.push({
                device: links[0],
                protocol: _.random(0, 1) > 0 ? 'MODBUS' : 'PULSE'
            });
            device.links.push({
                device: links[1],
                protocol: _.random(0, 1) > 0 ? 'MODBUS' : 'PULSE'
            });
        }

        device.fieldGroups = [
            {
                label: 'Billing',
                hint: '',
                fields: [
                    {
                        label: 'Utility Company',
                        type: 'input',
                        value: 'Pepco'
                    },
                    {
                        label: 'Utility Account #',
                        type: 'input',
                        value: '123456789'
                    }
                ]
            },
            {
                label: 'Location and tenant',
                hint:
                    'Where this device is located and who it reports data for',
                fields: [
                    {
                        label: 'Riser',
                        name: 'riser',
                        icon: 'location_on',
                        type: 'input',
                        value: 'NW',
                        hint: 'The riser this device is connected to'
                    },
                    {
                        label: 'Load monitored',
                        name: 'load',
                        icon: '',
                        type: 'input',
                        value: 400,
                        hint: 'The load this meter monitors'
                    }
                ]
            },
            {
                label: 'Multipliers and transformations',
                hint:
                    'These fields are used to determine the exact values this device reports - do not change these ' +
                    'unless you know what you are doing!',
                fields: [
                    {
                        label: 'CT Size',
                        name: 'ct-size',
                        icon: '',
                        type: 'input',
                        value: 400,
                        hint: 'CT size is a measure of amps'
                    },
                    {
                        label: 'Multiplier',
                        name: 'multiplier',
                        icon: '',
                        type: 'input',
                        value: 1.0,
                        hint: 'Multiplies the values this Meter reports'
                    },
                    {
                        label: 'Manual reading multiplier',
                        name: 'manualMultiplier',
                        icon: '',
                        type: 'input',
                        value: 1.0,
                        hint: 'Multiplier to use for manual readings'
                    }
                ]
            }
        ];
        return device;
    });

    for (let i = 0; i < _.random(1, 20); i++) {
        spaces.push(createSpace(i));
    }

    for (let i = 0; i < _.random(1, 20); i++) {
        floors.push(createFloor(i));
    }

}
