namespace aq.dashboard.widgets {
    interface BuildingEnrollmentView extends aq.propertySettings.BuildingEnrollment {
        imageUrl: string;
        buildingName: string;
        viewPhases: PhaseView[];
        taskMap: {
            [taskName: string]: TaskView;
        };
        orderValue?: number | string;
        nextTask: TaskView;
    }
    interface PhaseView extends aq.propertySettings.BuildingEnrollmentPhase {
        viewTasks: TaskView[];
        completedTasks: number;
        totalActiveTasks: number;
        nextTask: TaskView;
    }
    interface TaskView {
        phaseName: string;
        task: aq.propertySettings.TaskEnrollmentResponse;
        dueDateFormatted: string;
        completionDateFormatted: string;
        icon?: string;
        iconColor?: string;
        iconDescription?: string;
        status?: string;
    }
    interface BuildingPersonaItem {
        name: string;
        phone?: string;
        email?: string;
        persona: 'PROPERTY_MANAGER' | 'BUILDING_ENGINEER';
    }
    interface BuildingPersonnelMap {
        [buildingId: string]: BuildingPersonaItem[];
    }
    interface BuildingPersonas {
        propertyManager: string;
        propertyManagerPhone: string;
        propertyManagerEmail: string;
        buildingEngineer: string;
        buildingEngineerPhone: string;
        buildingEngineerEmail: string;
    }
    interface BuildingPersonasMap {
        [buildingId: string]: BuildingPersonas;
    }
    interface BuildingUsersMap {
        [buildingId: string]: aq.common.models.User[];
    }
    interface PhaseProgressMap {
        [phaseName: string]: PhaseProgress;
    }
    interface PhaseProgress {
        total: number;
        completed: number;
    }
    type LayoutSize = 'small' | 'medium' | 'large';
    enum EnrollmentStatus {
        PLANNED = 'PLANNED',
        IN_PROGRESS = 'IN_PROGRESS',
        COMPLETE = 'COMPLETE'
    }
    export class OnboardingWidgetCtrl {
        public maxTasks = 4;
        public sortBy: string;
        public sortAscending: boolean;
        public overallPhaseProgress: PhaseProgressMap;
        public buildingEnrollmentView: BuildingEnrollmentView[];
        public filteredBuildingEnrollmentView: BuildingEnrollmentView[];
        public buildingPersonnels: BuildingPersonnelMap;
        public buildingPersonas: BuildingPersonasMap;
        public buildingUsersMap: BuildingUsersMap;
        private isLoadingData: boolean;
        private isLoadingStaff: boolean;
        private isExportingData: boolean;
        private isBuildingDrill: boolean;
        private loadingPercent: number;
        private totalPromises: number;
        private completedPromises: number;
        private phases = [
            'Setup', 'Connect', 'Verify', 'Train'
        ];
        private buildingEnrollment: aq.propertySettings.BuildingEnrollment[];
        private drillPhase: string;
        private drillPhaseTasks: string[];
        private displayDrillPhaseTasks: string[];
        private isNavigationVisible: boolean;
        private currentTaskIndex: number;
        private currentLayoutSize: LayoutSize;
        /* @ngInject */
        constructor(
            private $scope,
            private config,
            private account,
            private buildings: aq.common.models.Building[],
            private buildingGroups,
            private ModelUtilService: aq.services.ModelUtilService,
            private BuildingSelectorActions: aq.services.BuildingSelectorActions,
            private RestangularV3: restangular.IService,
            private $q: ng.IQService,
            private $mdDialog: ng.material.IDialogService,
            private $state: ng.ui.IStateService,
            private Messages: services.Messages,
            private $mdMedia: ng.material.IMedia
        ) {
            $scope.$watch(() => { return this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('gt-md'); }, () => {
                let currentLayoutSize: LayoutSize = 'medium';
                if (this.$mdMedia('xs') || this.$mdMedia('sm')) { currentLayoutSize = 'small'; }
                if (this.$mdMedia('gt-md')) { currentLayoutSize = 'large'; }
                if (currentLayoutSize !== this.currentLayoutSize) {
                    switch (currentLayoutSize) {
                        case 'small':
                            this.maxTasks = 3;
                            break;
                        case 'large':
                            this.maxTasks = 5;
                            break;
                        case 'medium':
                        default:
                            this.maxTasks = 4;
                            break;
                    }
                    if (this.drillPhaseTasks) {
                        this.displayDrillPhaseTasks = _.take(this.drillPhaseTasks, this.maxTasks);
                        this.currentTaskIndex = 0;
                        this.isNavigationVisible = this.displayDrillPhaseTasks.length < this.drillPhaseTasks.length;
                    }
                    this.currentLayoutSize = currentLayoutSize;
                }
            });
            this.config.options = {
                buildings: this.ModelUtilService.pareProperties(this.buildings, ['buildingGroup']),
                buildingGroups: this.ModelUtilService.pareProperties(this.buildingGroups)
            };
            this.BuildingSelectorActions.initDefaultBuildingSelection(this.config);

            this.$scope.config = this.config;
            this.$scope.config.actions = this.BuildingSelectorActions;
            if (this.config.showBuildingImage == null) {
                this.config.showBuildingImage = true;
            }

            this.isLoadingData = true;
            this.isLoadingStaff = true;
            this.loadingPercent = 0;
            this.totalPromises = this.$scope.config.buildingIds.length;
            this.completedPromises = 0;
            this.buildingEnrollment = _.map(this.$scope.config.buildingIds, (id) => {
                return { building: id, progress: 0 };
            });
            this.buildingPersonnels = {};
            this.buildingPersonas = {};
            this.buildingUsersMap = {};
            this.initViewData();
            this.loadBuildingEnrollment()
                .then((data) => {
                    this.buildingEnrollment = data;
                    this.initViewData();
                    this.sortByTotalCompletion();
                    this.sortAscending = true;
                    this.isLoadingData = false;
                    return this.getBuildingPersonnels();
                })
                .then((personaData) => {
                    this.initBuildingPersonas(personaData);
                    this.isLoadingStaff = false;
                });
        }
        public loadBuildingEnrollment() {
            return this.$q.all<aq.propertySettings.BuildingEnrollment>(_.map(this.$scope.config.buildingIds, (id) => {
                return this.loadBuildingEnrollmentById(id)
                    .finally(() => {
                        this.completedPromises++;
                        this.loadingPercent = Math.round(this.completedPromises * 100 / this.totalPromises);
                    });
            }));
        }
        public loadBuildingEnrollmentById(id) {
            return this.RestangularV3
                .one('buildings', id)
                .one('enrollment')
                .get()
                .then((response) => {
                    return response || { building: id, progress: 0 };
                });
        }
        public initBuildingPersonas(data) {
            _.each(data, (dataItem) => {
                if (!this.buildingPersonnels[dataItem.buildingId]) {
                    this.buildingPersonnels[dataItem.buildingId] = dataItem.personnel;
                }
                const personnel = this.buildingPersonnels[dataItem.buildingId] || [];
                const propertyManager: BuildingPersonaItem = _.find(personnel, (item) => item.persona == 'PROPERTY_MANAGER');
                const buildingEngineer: BuildingPersonaItem = _.find(personnel, (item) => item.persona == 'BUILDING_ENGINEER');
                this.buildingPersonas[dataItem.buildingId] = {
                    propertyManager: propertyManager ? this.getShortName(propertyManager.name) : '',
                    propertyManagerPhone: propertyManager ? propertyManager.phone : '-',
                    propertyManagerEmail: propertyManager ? propertyManager.email : '-',
                    buildingEngineer: buildingEngineer ? this.getShortName(buildingEngineer.name) : '',
                    buildingEngineerPhone: buildingEngineer ? buildingEngineer.phone : '-',
                    buildingEngineerEmail: buildingEngineer ? buildingEngineer.email : '-'
                };
            });
        }
        public getShortName(name: string) {
            if (!name) {
                return '';
            }
            const parts = name.split(' ');
            if(parts.length == 1) {
                return name;
            }
            if (parts.length < 2 || !parts[1].length) {
                return '';
            }
            const shortName = `${parts[0]} ${parts[1].substr(0, 1)}.`;
            return shortName;
        }
        public initViewData() {
            this.buildingEnrollmentView = _.map(this.buildingEnrollment, (item: aq.propertySettings.BuildingEnrollment) => {
                return this.getBuildingEnrollmentViewItem(item);
            });
            this.calculatePhasesOverallProgress();
            this.filteredBuildingEnrollmentView = this.buildingEnrollmentView;
        }
        public getBuildingEnrollmentViewItem(item: aq.propertySettings.BuildingEnrollment): BuildingEnrollmentView {
            const building: aq.common.models.Building = _.find(this.buildings, (b) => b.id == item.building);
            let nextTask = null;
            const viewPhases = _.map(this.phases, (phaseName) => {
                const phase: aq.propertySettings.BuildingEnrollmentPhase =
                    _.find(item.phases, (p: aq.propertySettings.BuildingEnrollmentPhase) => p.name.toLowerCase() == phaseName.toLowerCase());
                if (!phase) {
                    return {
                        name: phaseName,
                        progress: 0,
                        viewTasks: [],
                        totalActiveTasks: 0,
                        nextTask: null
                    };
                }
                const viewPhase = this.getPhaseView(phase);
                if (nextTask == null) {
                    nextTask = viewPhase.nextTask;
                }
                return viewPhase;
            });

            return angular.extend(item, {
                imageUrl: building ? building.imageUrl : null,
                buildingName: building ? building.name : '',
                viewPhases,
                nextTask
            });
        }
        public calculatePhasesOverallProgress() {
            this.overallPhaseProgress = {};
            _.each(this.buildingEnrollmentView, (item: BuildingEnrollmentView) => {
                _.each(item.viewPhases, (viewPhase: PhaseView) => {
                    this.addOverallPhaseProgress(viewPhase);
                });
            });
        }
        public getPhaseView(phase: aq.propertySettings.BuildingEnrollmentPhase, recalcProgress = false) {
            const viewTasks = this.getTasksView(phase);
            const completedTasks = _.filter(viewTasks, (vt: TaskView) => vt.task.complete).length;
            const totalActiveTasks = _.filter(viewTasks, (vt: TaskView) => vt.task.status != 'IGNORED').length;
            if (phase.progress == null || recalcProgress) {
                if (phase.status == EnrollmentStatus.COMPLETE && !recalcProgress) {
                    phase.progress = 100;
                } else {
                    if(totalActiveTasks != 0) {
                        phase.progress = Math.round(completedTasks * 100 / totalActiveTasks);
                    } else {
                        phase.progress = viewTasks.length == 0 ? 0 : 100;
                    }
                }
            }
            return angular.extend(phase, {
                viewTasks,
                completedTasks,
                totalActiveTasks,
                nextTask: this.getNextTask(viewTasks)
            });
        }
        public navigateToPropertySettings(item: aq.propertySettings.BuildingEnrollment, isPersonnel = false) {
            const state = isPersonnel ? 'aq.properties.buildings.form.personnel' : 'aq.properties.buildings.form.configuration';
            this.$state.go(state, {
                accountId: this.account.id,
                buildingId: item.building
            }, { reload: true });
        }
        public onClickViewPhase(phaseName, buildingId: number = null, event: ng.IAngularEvent = null) {
            this.drillPhase = phaseName;
            this.drillPhaseTasks = this.getAllDrillPhaseTasks(phaseName);
            this.displayDrillPhaseTasks = _.take(this.drillPhaseTasks, this.maxTasks);
            this.currentTaskIndex = 0;
            this.isNavigationVisible = this.displayDrillPhaseTasks.length < this.drillPhaseTasks.length;
            this.rebuildBuildingTaskMap();
            if (buildingId) {
                this.filteredBuildingEnrollmentView = _.filter(this.buildingEnrollmentView, (item: BuildingEnrollmentView) => {
                    return item.building == buildingId;
                });
                this.isBuildingDrill = true;
            }
            if(event) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
        public onSelectNextTask(offset) {
            if (this.isTaskOffsetValid(offset)) {
                this.currentTaskIndex += offset;
                this.displayDrillPhaseTasks = this.drillPhaseTasks.slice(this.currentTaskIndex, this.currentTaskIndex + this.maxTasks);
            }
        }
        public isNextTaskDisabled(offset) {
            const isValid = this.isTaskOffsetValid(offset);
            return !isValid;
        }
        public isTaskOffsetValid(offset) {
            return this.currentTaskIndex + offset + this.maxTasks <= this.drillPhaseTasks.length && this.currentTaskIndex + offset >= 0;
        }
        public getAllDrillPhaseTasks(phaseName) {
            const taskNames: string[] = [];
            _.each(this.buildingEnrollmentView, (item: BuildingEnrollmentView) => {
                const phase: PhaseView = _.find(item.viewPhases, (viewPhase: PhaseView) => viewPhase.name == phaseName);
                if (!phase) {
                    return;
                }
                _.each(phase.viewTasks, (viewTask: TaskView) => {
                    if (!_.find(taskNames, (taskName: string) => viewTask.task.name == taskName)) {
                        taskNames.push(viewTask.task.name);
                    }
                });
            });
            return taskNames;
        }
        public rebuildBuildingTaskMap() {
            _.each(this.buildingEnrollmentView, (item: BuildingEnrollmentView) => {
                item.taskMap = this.getBuildingPhaseTaskMap(item, this.drillPhase);
            });
        }
        public getBuildingPhaseTaskMap(buildingEnrollmentItem: BuildingEnrollmentView, phaseName) {
            const taskMap = {};
            const phase: PhaseView = _.find(buildingEnrollmentItem.viewPhases, (viewPhase: PhaseView) => {
                return viewPhase.name.toLowerCase() == phaseName.toLowerCase();
            });
            if (!phase) {
                return;
            }
            _.each(phase.viewTasks, (viewTask: TaskView) => {
                taskMap[viewTask.task.name] = viewTask;
            });
            return taskMap;
        }
        public onTaskCompleteChange(buildingEnrollmentItem: BuildingEnrollmentView, task, drillPhase) {
            if (task.status != 'IGNORED') {
                if (!task.complete) {
                    task.completionDate = null;
                    task.status = 'INCOMPLETE';
                } else {
                    task.completionDate = moment().format('YYYY-MM-DD');
                    task.status = 'COMPLETE';
                }
            }

            this.updateBuildingEnrollment(buildingEnrollmentItem)
                .then((updatedItem) => {
                    this.Messages.success('Task updated');
                    this.updateCurrentViewPhase(buildingEnrollmentItem, drillPhase);
                    this.calculatePhasesOverallProgress();
                    buildingEnrollmentItem.progress = updatedItem.progress;
                })
                .catch(() => {
                    this.Messages.error('Error while updating task, please reload the page and try again');
                });
        }
        public onTaskIgnoreChange(buildingEnrollmentItem: BuildingEnrollmentView, task: aq.propertySettings.TaskEnrollmentResponse, drillPhase) {
            if (task.status != 'IGNORED') {
                task.status = 'IGNORED';
                task.complete = false;
            } else if (task.status === 'IGNORED' && task.completionDate) {
                task.status = 'COMPLETE';
                task.complete = true;
            } else if (task.status === 'IGNORED' && !task.completionDate) {
                task.status = 'INCOMPLETE';
                task.complete = false;
            }
            this.onTaskCompleteChange(buildingEnrollmentItem, task, drillPhase);
        }
        public editProjectTask(buildingEnrollmentItem: BuildingEnrollmentView, task: aq.propertySettings.TaskEnrollmentResponse) {
            this.getBuildingUsers(buildingEnrollmentItem.building)
                .then((users) => {
                    return this.showProjectTaskEditModal(buildingEnrollmentItem, task, users);
                })
                .then((updatedTask) => {
                    if (updatedTask.status != task.status) {
                        return this.loadBuildingEnrollmentById(buildingEnrollmentItem.building);
                    } else {
                        task.details = updatedTask.details;
                        task.dueDate = updatedTask.dueDate ? moment(updatedTask.dueDate).format('YYYY-MM-DD') : null;
                        task.owner = updatedTask.owner;
                        const buildingUsers = this.buildingUsersMap[buildingEnrollmentItem.building];
                        if (buildingUsers) {
                            const ownerUser: aq.common.models.User = _.find(buildingUsers, (user) => user.id == task.owner);
                            if (ownerUser) {
                                task.ownerName = ownerUser.fullName;
                            }
                        }
                        return this.$q.when(null);
                    }
                })
                .then((item: aq.propertySettings.BuildingEnrollment) => {
                    if (item != null) {
                        const updatedViewItem = this.getBuildingEnrollmentViewItem(item);
                        if (this.drillPhase) {
                            updatedViewItem.taskMap = this.getBuildingPhaseTaskMap(updatedViewItem, this.drillPhase);
                        }
                        const index = _.findIndex(this.buildingEnrollment, (viewItem: BuildingEnrollmentView) => {
                            return viewItem.building == updatedViewItem.building;
                        });
                        if (index > -1) {
                            this.buildingEnrollment.splice(index, 1, updatedViewItem);
                        }
                        const indexFiltered = _.findIndex(this.filteredBuildingEnrollmentView, (viewItem: BuildingEnrollmentView) => {
                            return viewItem.building == updatedViewItem.building;
                        });
                        if (indexFiltered > -1) {
                            this.filteredBuildingEnrollmentView.splice(index, 1, updatedViewItem);
                        }
                        this.calculatePhasesOverallProgress();
                    }
                });
        }
        public showProjectTaskEditModal(
            buildingEnrollmentItem: BuildingEnrollmentView,
            task: aq.propertySettings.TaskEnrollmentResponse,
            users: aq.common.models.User[]
            ) {
            const project: any = {
                id: buildingEnrollmentItem.project,
                building: buildingEnrollmentItem.building
            };
            const mappedTask: aq.projectCenter.Task = _.omit(task, ['ownerName', 'eventHandler']);
            const options: aq.projectCenter.TaskEditModalOptions = {
                isNameReadonly: true,
                isActionReadonly: true,
                isStatusReadonly: true
            };
            return this.$mdDialog.show({
                controller: 'ProjectCenterTaskEditCtrl as vm',
                templateUrl: 'app/projectCenter/project/task/projectCenterTaskEditModal.html',
                clickOutsideToClose: true,
                parent: angular.element(document.body),
                locals: {
                    project,
                    task: mappedTask,
                    users: _.sortBy(users, ['fullName']),
                    account: buildingEnrollmentItem.account,
                    options
                }
            });
        }
        public getBuildingUsers(buildingId) {
            if (this.buildingUsersMap[buildingId]) {
                return this.$q.when(this.buildingUsersMap[buildingId]);
            }
            return this.RestangularV3
                .all('users/users-for-building')
                .getList({ 'buildingId': buildingId, 'showAdmin': true })
                .then((users) => {
                    this.buildingUsersMap[buildingId] = users;
                    return users;
                });
        }
        public updateCurrentViewPhase(buildingEnrollmentItem: BuildingEnrollmentView, drillPhase) {
            const phase: aq.propertySettings.BuildingEnrollmentPhase =
                _.find(buildingEnrollmentItem.phases, (p: aq.propertySettings.BuildingEnrollmentPhase) => {
                    return p.name.toLowerCase() == drillPhase.toLowerCase();
                });
            const updatedViewPhase = this.getPhaseView(phase, true);
            const replaceIndex = _.findIndex(buildingEnrollmentItem.viewPhases, (viewPhase: PhaseView) => {
                return viewPhase.name.toLowerCase() == drillPhase.toLowerCase();
            });
            buildingEnrollmentItem.viewPhases.splice(replaceIndex, 1, updatedViewPhase);
            let nextTask = null;
            _.each(buildingEnrollmentItem.viewPhases, (viewPhase) => {
                if (nextTask == null) {
                    nextTask = viewPhase.nextTask;
                }
            });
            buildingEnrollmentItem.nextTask = nextTask;
            this.calculatePhasesOverallProgress();
        }
        public goBackToSummary() {
            this.drillPhase = '';
            this.sortBy = '';
            this.filteredBuildingEnrollmentView = this.buildingEnrollmentView;
            this.isBuildingDrill = false;
        }
        public sortByTotalCompletion() {
            this.setSortByValue('Building');
            this.updateViewItemsOrderByTotalCompletion();
        }
        public sortByPhaseCompletion(phaseName) {
            this.setSortByValue(phaseName);
            this.updateViewItemsOrderByPhaseCompletion();
        }
        public sortByTaskCompletion(taskName) {
            this.setSortByValue(taskName);
            this.updateViewItemsOrderByTaskCompletion();
        }
        public sortByStaff() {
            this.setSortByValue('Staff');
            this.updateViewItemsOrderByStaff();
        }
        public updateViewItemsOrderByTotalCompletion() {
            _.each(this.buildingEnrollment, (item) => {
                if (!item.project) {
                    item.orderValue = 2000;
                    if (!this.sortAscending) {
                        item.orderValue *= -1;
                    }
                } else {
                    if (_.every(item.phases, (phase) => phase.tasks.length == 0)) {
                        item.orderValue = 1000;
                    } else {
                        item.orderValue = (item.progress || 0);
                    }
                }
            });
        }
        public updateViewItemsOrderByPhaseCompletion() {
            _.each(this.buildingEnrollment, (item) => {
                const phaseName = this.sortBy;
                const phase: aq.propertySettings.BuildingEnrollmentPhase =
                    _.find(item.phases, (p: aq.propertySettings.BuildingEnrollmentPhase) => {
                        return p.name.toLowerCase() == phaseName.toLowerCase();
                    });
                // ordering: show items without any tasks at the bottom, but above items without project
                if (!item.project) {
                    item.orderValue = 2000;
                    if (!this.sortAscending) {
                        item.orderValue *= -1;
                    }
                } else {
                    if (phase.tasks.length == 0) {
                        item.orderValue = 1000;
                    } else {
                        item.orderValue = phase.progress;
                    }
                }
            });
        }
        public updateViewItemsOrderByTaskCompletion() {
            _.each(this.buildingEnrollmentView, (item: BuildingEnrollmentView) => {
                const phaseName = this.drillPhase;
                const taskName = this.sortBy;
                const phase: aq.propertySettings.BuildingEnrollmentPhase =
                    _.find(item.phases, (p: aq.propertySettings.BuildingEnrollmentPhase) => {
                        return p.name.toLowerCase() == phaseName.toLowerCase();
                    });
                // ordering: show items without any tasks at the bottom, but above items without project
                if (!item.project) {
                    item.orderValue = 20;
                    if (!this.sortAscending) {
                        item.orderValue *= -1;
                    }
                } else {
                    if (phase.tasks.length == 0) {
                        item.orderValue = 10;
                    } else {
                        item.orderValue = item.taskMap[taskName] && item.taskMap[taskName].task.complete ? 2 : 1;
                    }
                }
            });
        }
        public updateViewItemsOrderByStaff() {
            _.each(this.buildingEnrollmentView, (item: BuildingEnrollmentView) => {
                const personas = this.buildingPersonas[item.building];
                const propertyManager = personas ? personas.propertyManager.toLowerCase() : '';
                const buildingEngineer = personas ? personas.buildingEngineer.toLowerCase() : '';
                item.orderValue = `${propertyManager || 'ZZZ'}#${buildingEngineer || 'ZZZ'}`;
                if (!propertyManager && !buildingEngineer) {
                    item.orderValue = this.sortAscending ? 'ZZZZ' : '';
                }
            });
        }
        public updateBuildingEnrollment(buildingEnrollment: aq.propertySettings.BuildingEnrollment): ng.IPromise<any> {
            const updateRequest = this.transformForRequest(buildingEnrollment);
            return this.RestangularV3
                .one('buildings', buildingEnrollment.building)
                .one('enrollment')
                .customPUT(updateRequest);
        }
        public getBuildingPersonnels() {
            const promises = _.map(this.buildings, (building: aq.common.models.Building) => {
                if (this.buildingPersonnels[building.id]) {
                    return {
                        buildingId: building.id,
                        personnel: this.buildingPersonnels[building.id]
                    };
                }
                return building.all('personnel').getList()
                    .then((result) => {
                        return {
                            buildingId: building.id,
                            personnel: result
                        };
                    }, (error) => {
                        return {
                            buildingId: building.id,
                            personnel: []
                        };
                    });
            });
            return this.$q.all(promises);
        }
        public toggleBuildingData(item) {
            if(this.$mdMedia('gt-md') && !item.isShowPersonnel) {
                return;
            }
            item.isShowPersonnel = !item.isShowPersonnel;
        }
        private setSortByValue(value) {
            if (this.sortBy == value) {
                this.sortAscending = !this.sortAscending;
            } else {
                this.sortAscending = true;
            }
            this.sortBy = value;
        }
        private addOverallPhaseProgress(phase: PhaseView) {
            const name = phase.name.toLowerCase();
            if (!this.overallPhaseProgress[name]) {
                this.overallPhaseProgress[name] = {
                    total: 0,
                    completed: 0
                };
            }
            if (phase.completedTasks != null) {
                this.overallPhaseProgress[name].total++;
            }
            if (phase.totalActiveTasks === phase.completedTasks) {
                this.overallPhaseProgress[name].completed++;
            }
        }
        private getTasksView(phase: aq.propertySettings.BuildingEnrollmentPhase) {
            const tasks = phase.tasks;
            const sortedTasks = _.sortBy(tasks, ['dueDate', 'task']);
            return _.map(sortedTasks, (task: aq.propertySettings.TaskEnrollmentResponse) => {
                if (task.status === 'COMPLETE') {
                    task.complete = true;
                }
                const taskView: TaskView = {
                    phaseName: phase.name,
                    task,
                    dueDateFormatted: task.dueDate ? moment(task.dueDate).format('MM/DD/YYYY') : '',
                    completionDateFormatted: task.completionDate ? moment(task.completionDate).format('MM/DD/YYYY') : ''
                };
                this.setOverdueInfo(taskView);
                return taskView;
            });
        }
        private getNextTask(tasks: TaskView[]): TaskView {
            const viewTask: TaskView = _.find(tasks, (t: TaskView) => !t.task.complete);
            return viewTask;
        }
        private setOverdueInfo(taskView: TaskView) {
            if (!taskView.task.complete && taskView.task.dueDate) {
                const diff = moment().diff(moment(taskView.task.dueDate), 'day');
                if (diff > 0) {
                    taskView.icon = 'cancel';
                    taskView.iconColor = 'red';
                    taskView.iconDescription = 'Task is overdue';
                } else if (diff <= 2) {
                    taskView.icon = 'warning';
                    taskView.iconColor = 'orange';
                    taskView.iconDescription = 'Task due date is near';
                }
            }
        }
        private transformForRequest(be: aq.propertySettings.BuildingEnrollment): aq.propertySettings.BuildingEnrollmentUpdateRequest {
            let request: aq.propertySettings.BuildingEnrollmentUpdateRequest = null;
            if (be) {
                request = { project: be.project, phases: [] };
                _.each(be.phases, (phase: aq.propertySettings.BuildingEnrollmentPhase) => {
                    const phaseUpdate: aq.propertySettings.BuildingEnrollmentPhaseUpdateRequest = { phase: phase.phase, tasks: [] };
                    _.each(phase.tasks, (task: aq.propertySettings.TaskEnrollmentResponse) => {
                        phaseUpdate.tasks.push(_.pick(task, ['id', 'completionDate', 'sortOrder', 'status']));
                    });
                    request.phases.push(phaseUpdate);
                });
            }
            return request;
        }
        private getExportCsv() {
            this.isExportingData = true;
            this.getBuildingPersonnels()
                .then((data) => {
                    _.each(data, (item) => {
                        if (!this.buildingPersonnels[item.buildingId]) {
                            this.buildingPersonnels[item.buildingId] = item.personnel;
                        }
                    });
                })
                .finally(() => {
                    this.isExportingData = false;
                    this.buildAndDownloadExportCsv();
                });
        }
        private buildAndDownloadExportCsv() {
            const content = this.buildCsvContent();
            const fileName = `Building Enrollment Tasks - ${this.account.accountName} - ${moment().format('YYYY-MM-DD')}.csv`;
            const link = document.createElement('a');
            link.setAttribute('href', encodeURI(content));
            link.setAttribute('download', fileName);
            link.click();
        }
        private buildCsvContent() {
            const header = 'data:text/csv;charset=utf-8,Building Enrollment Tasks';
            const csvItems = [];
            const taskNamesList = [];
            const taskNamesMap = {};
            const buildingInformation = [
                'Address',
                'City',
                'State',
                'SQ Footage',
                'Building',
                'Property Manager',
                'Building Engineer'
            ];
            // order tasks by phase
            _.each(this.phases, (phaseName: string) => {
                _.each(this.buildingEnrollmentView, (buildingItem: BuildingEnrollmentView) => {
                    const phase: PhaseView = _.find(buildingItem.viewPhases, (viewPhase: PhaseView) => viewPhase.name == phaseName);
                    if (!phase) {
                        return;
                    }
                    _.each(phase.viewTasks, (viewTask: TaskView) => {
                        if (viewTask.task.name && !taskNamesMap[viewTask.task.name]) {
                            taskNamesMap[viewTask.task.name] = true;
                            taskNamesList.push(viewTask.task.name);
                        }
                    });
                });
            });
            const csvHeader = buildingInformation.join(',') + ',' + taskNamesList.join(',');
            _.each(this.buildingEnrollmentView, (buildingItem: BuildingEnrollmentView) => {
                if (!buildingItem.project) {
                    return;
                }
                const currentBuildingMap = {};
                _.each(this.phases, (phaseName: string) => {
                    const phaseTaskMap = this.getBuildingPhaseTaskMap(buildingItem, phaseName);
                    angular.extend(currentBuildingMap, phaseTaskMap);
                });
                const buildingTaskCompletionList = _.map(taskNamesList, (taskName: string) => {
                    if (!currentBuildingMap[taskName]) {
                        return 'N/A';
                    }
                    return currentBuildingMap[taskName].task.complete ? 'completed' : '';
                });
                const personnel = this.buildingPersonnels[buildingItem.building] || [];
                const propertyManager: BuildingPersonaItem = _.find(personnel, (item: BuildingPersonaItem) => item.persona == 'PROPERTY_MANAGER');
                const buildingEngineer: BuildingPersonaItem = _.find(personnel, (item: BuildingPersonaItem) => item.persona == 'BUILDING_ENGINEER');
                const building: aq.common.models.Building = this.buildings.find(b => {
                    return b.id.toString() === buildingItem.building.toString();
                });
                const buildingCsvRow = [
                    building ? building.address : '',
                    building ? building.city : '',
                    building ? building.state : '',
                    building ? building.size.toString() : '',
                    buildingItem.buildingName ? buildingItem.buildingName : '',
                    propertyManager ? propertyManager.name : '',
                    buildingEngineer ? buildingEngineer.name : '',
                    ...buildingTaskCompletionList
                ].join(',');
                csvItems.push(buildingCsvRow);
            });
            return `${header}\n${csvHeader}\n${csvItems.join('\n')}`;
        }
    }
    angular.module('aq.dashboard.widgets').controller('OnboardingWidgetCtrl', OnboardingWidgetCtrl);
}
