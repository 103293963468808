namespace aq.crud {

    export class CrudListCtrl {
        public onEditFn: Function;
        public onDeleteFn: Function;
        // Disable delete based on truthiness of this field - field name as string
        public disableDelete: string;
        public itemLabel: string;
        public items: any[];
        public access: common.models.AppAccessObject;
        public sortable;
        public sortableConf;
        public infiniteScroll: boolean;
        public nextPage: Function;

        /* ngInject */
        constructor(private $mdDialog: ng.material.IDialogService, private $transclude) {
            this.sortable = false;
        }

        public delete(item, ev) {
            if (this.disableDelete && item[this.disableDelete]) {
                return;
            }
            // search through item for the specified label prop, currently limited to 2 properties deep.
            const properties = (this.itemLabel && this.itemLabel.length > 0) ? this.itemLabel.split('.') : [];
            const label = (properties.length > 1 ? item.item[properties[0]][properties[1]] : item.item[properties[0]]);
            this.$mdDialog.show(
                this.$mdDialog.confirm()
                    .title(`Are you sure you want to delete ${(label && label.length > 0) ? label : 'this'}?`)
                    .ariaLabel('Delete')
                    .targetEvent(ev)
                    .ok('Delete')
                    .cancel('Cancel'))
                .then(() => {
                    this.onDeleteFn(item);
                });
        }
        public isHeaderVisible() {
            return this.$transclude.isSlotFilled('header') && this.items && this.items.length > 0;
        }
        public canDelete() {
            return !this.access || this.access.FULL_ACCESS;
        }
    }

    angular
        .module('aq.ui')
        .component('aqCrudList', {
            templateUrl: 'app/common/directives/crud/list.html',
            transclude: {
                'header': '?aqCrudListHeader',
                'leftActions': '?aqCrudListLeftActions',
                'content': 'aqCrudListItemContent',
                'rightActions': '?aqCrudListRightActions'
            },
            controller: CrudListCtrl,
            bindings: {
                onEditFn: '&',
                onDeleteFn: '&?',
                infiniteScroll: '<?',
                nextPage: '&?',
                disableDelete: '<?',
                itemLabel: '<?',
                items: '<',
                orderBy: '<',
                filterBy: '<',
                access: '<?',
                sortable: '<?',
                sortableConf: '<?'
            }
        });

}
