'use strict';

angular
.module('user')
.controller('ResetPasswordCtrl', function ($scope, $stateParams, Restangular, Messages, Errors,$window) {

    $scope.resetPassword = {
        token: $stateParams.token
    };

    $scope.goHome = function () {
        $window.location = "/";
        // TODO (dalibor) change when app refactored to AngularJS completely
    }

    $scope.change = function () {
        Restangular.all('users').customPOST($scope.resetPassword, 'resetPassword').then(function () {
            Messages.success('Password reset successfully.');
            $scope.goHome();
        }, function(response) {
            $scope.formErrors = response.data.errors;
        });
    }

    $scope.$watch('resetPassword.confirmPassword', function() {
        if (!$scope.resetPassword.password) return;
        doMatch($scope.resetPassword.password, $scope.resetPassword.confirmPassword);
    });

    function doMatch(password, confirm) {
        var match = (password === confirm);
        $scope.match = match;
    }

});
