var aq;
(function (aq) {
    var admin;
    (function (admin) {
        var accounts;
        (function (accounts) {
            var enrollment;
            (function (enrollment) {
                var BulkUpdateCtrl = /** @class */ (function () {
                    /* @ngInject */
                    function BulkUpdateCtrl($mdDialog, phaseTasks, selectedTasks, selectedBuildings, buildingEnrollment, allBuildings, RestangularV3, Messages, $q) {
                        var _this = this;
                        this.$mdDialog = $mdDialog;
                        this.phaseTasks = phaseTasks;
                        this.selectedTasks = selectedTasks;
                        this.selectedBuildings = selectedBuildings;
                        this.buildingEnrollment = buildingEnrollment;
                        this.allBuildings = allBuildings;
                        this.RestangularV3 = RestangularV3;
                        this.Messages = Messages;
                        this.$q = $q;
                        this.phaseDisplayModel = this.phaseTasks.map(function (phase) {
                            var tempTasks = phase.tasks.map(function (task) {
                                if (_this.selectedTasks.indexOf(task.name) !== -1) {
                                    return {
                                        currentName: task.name,
                                        oldName: '',
                                        edit: false,
                                        parentPhase: phase.phase,
                                        hasEventHook: task.eventHook ? true : false
                                    };
                                }
                                else {
                                    return null;
                                }
                            });
                            return {
                                phase: phase.phase,
                                tasks: tempTasks.filter(function (tempTask) {
                                    return tempTask !== null;
                                }),
                                addNewTask: false
                            };
                        });
                    }
                    BulkUpdateCtrl.prototype.startEditTask = function (task) {
                        task.edit = true;
                        this.modalButtonsDisabled = true;
                        this.editTask = _.cloneDeep(this.selectedBuildings[0].taskMap[task.currentName]);
                    };
                    BulkUpdateCtrl.prototype.endEditTask = function (taskToEdit, currentTask) {
                        var _this = this;
                        var taskEditFields = [
                            'name',
                            'details'
                        ];
                        var taskUpdatePromises = [];
                        this.buildingEnrollment.forEach(function (be) {
                            be.phases.forEach(function (bePhase) {
                                var taskToUpdate = bePhase.tasks.find(function (t) { return t.name === taskToEdit.oldName; });
                                if (taskToUpdate) {
                                    _.assign(taskToUpdate, _.pick(taskToEdit, taskEditFields));
                                    delete taskToUpdate['ownerName'];
                                    delete taskToUpdate['eventHandler'];
                                    delete taskToUpdate['complete'];
                                    taskUpdatePromises.push(_this.RestangularV3.one('projects', taskToUpdate.project).one('tasks', taskToUpdate.id).customPUT(taskToUpdate));
                                }
                            });
                        });
                        currentTask.edit = false;
                        this.$q.all(taskUpdatePromises)
                            .then(function () {
                            _this.selectedBuildings.forEach(function (building) {
                                building.taskMap[taskToEdit.name] = __assign({}, _.cloneDeep(building.taskMap[taskToEdit.oldName]), { name: taskToEdit.name, details: taskToEdit.details });
                                if (taskToEdit.oldName !== taskToEdit.name) {
                                    delete building.taskMap[taskToEdit.oldName];
                                }
                            });
                            _this.selectedTasks = _this.updateSelectedTasks(taskToEdit, _this.selectedTasks);
                            currentTask.currentName = taskToEdit.name;
                            _this.modalButtonsDisabled = false;
                            _this.Messages.success('Successfully updated task');
                        })
                            .catch(function (err) {
                            _this.modalButtonsDisabled = false;
                            currentTask.edit = false;
                            _this.Messages.error('Error updating task');
                        });
                    };
                    BulkUpdateCtrl.prototype.deleteTask = function (phase, task) {
                        this.selectedBuildings.forEach(function (building) {
                            delete building.taskMap[task.currentName];
                        });
                        this.phaseDisplayModel.forEach(function (display) {
                            if (display.phase === phase.phase) {
                                display.tasks = display.tasks.filter(function (newTask) {
                                    return newTask.currentName !== task.currentName;
                                });
                            }
                        });
                        this.buildingEnrollment.forEach(function (be) {
                            be.phases.forEach(function (bePhase) {
                                bePhase.tasks = bePhase.tasks.filter(function (newTask) {
                                    return newTask.name !== task.currentName;
                                });
                            });
                        });
                    };
                    BulkUpdateCtrl.prototype.createNewTask = function (phase, newTask) {
                        var _this = this;
                        phase.addNewTask = false;
                        var taskFields = [
                            'name',
                            'details'
                        ];
                        var taskUpdatePromises = [];
                        this.buildingEnrollment.forEach(function (be) { return __awaiter(_this, void 0, void 0, function () {
                            var updatePhase, newTaskRequest;
                            return __generator(this, function (_a) {
                                updatePhase = be.phases.find(function (p) { return p.name === phase.phase; });
                                newTaskRequest = _.pick(newTask, taskFields);
                                newTaskRequest.project = be.project.toString();
                                newTaskRequest.projectPhase = updatePhase.phase.toString();
                                delete newTaskRequest['ownerName'];
                                delete newTaskRequest['eventHandler'];
                                delete newTaskRequest['complete'];
                                taskUpdatePromises.push(this.RestangularV3.one('projects', newTaskRequest.project).post('tasks', newTaskRequest));
                                return [2 /*return*/];
                            });
                        }); });
                        this.$q.all(taskUpdatePromises)
                            .then(function (results) {
                            results.forEach(function (result) {
                                var be = _this.buildingEnrollment.find(function (b) { return b.project === result.project; });
                                var bePhase = be.phases.find(function (p) { return p.phase === result.projectPhase; });
                                bePhase.tasks.push(result);
                                var building = _this.selectedBuildings.find(function (b) { return b.buildingId === be.building; });
                                building.taskMap[result.name] = _.cloneDeep(result);
                            });
                            _this.phaseDisplayModel.forEach(function (display) {
                                if (display.phase === phase.phase) {
                                    display.tasks.push({
                                        currentName: newTask.name,
                                        oldName: '',
                                        edit: false,
                                        parentPhase: phase.phase,
                                        hasEventHook: false
                                    });
                                }
                            });
                            _this.selectedTasks.push(newTask.name);
                            _this.modalButtonsDisabled = false;
                            _this.Messages.success('Successfully Created New task');
                        })
                            .catch(function (err) {
                            _this.Messages.error('Error creating task');
                            _this.modalButtonsDisabled = false;
                        });
                    };
                    BulkUpdateCtrl.prototype.cancelCreateNewTask = function (phase) {
                        phase.addNewTask = false;
                        this.modalButtonsDisabled = false;
                    };
                    BulkUpdateCtrl.prototype.cancelEditTask = function (task) {
                        task.edit = false;
                        this.modalButtonsDisabled = false;
                    };
                    BulkUpdateCtrl.prototype.openCreateNewTask = function (phase) {
                        phase.addNewTask = true;
                        this.modalButtonsDisabled = true;
                    };
                    BulkUpdateCtrl.prototype.cancel = function () {
                        this.$mdDialog.cancel();
                    };
                    BulkUpdateCtrl.prototype.update = function (selectedTasks, selectedBuildings, buildingEnrollment) {
                        var data = {
                            selectedTasks: selectedTasks,
                            selectedBuildings: selectedBuildings,
                            buildingEnrollment: buildingEnrollment
                        };
                        this.$mdDialog.hide(data);
                    };
                    BulkUpdateCtrl.prototype.updateSelectedTasks = function (taskToEdit, selectedTasks) {
                        var updateInPlace = !this.allBuildings.filter(function (building) {
                            return building.taskMap[taskToEdit.oldName];
                        })
                            .some(function (building) {
                            if (building.taskMap[taskToEdit.oldName]) {
                                return !building.isSelected;
                            }
                        });
                        if (updateInPlace) {
                            return selectedTasks.map(function (task) {
                                if (task === taskToEdit.oldName) {
                                    return taskToEdit.name;
                                }
                                else {
                                    return task;
                                }
                            });
                        }
                        else {
                            selectedTasks.push(taskToEdit.name);
                            return _.cloneDeep(selectedTasks);
                        }
                    };
                    return BulkUpdateCtrl;
                }());
                enrollment.BulkUpdateCtrl = BulkUpdateCtrl;
                angular.module('aq.admin.accounts.enrollment').controller('BulkUpdateCtrl', BulkUpdateCtrl);
            })(enrollment = accounts.enrollment || (accounts.enrollment = {}));
        })(accounts = admin.accounts || (admin.accounts = {}));
    })(admin = aq.admin || (aq.admin = {}));
})(aq || (aq = {}));
