angular.module('charting').filter('period', ['$filter', function($filter) {
    return function(interval, callPeriod, offset) {
        return _.map($filter('momentPeriod')(interval, callPeriod, offset), function(tick) {
            return tick.valueOf();
        });
    }
}]);

angular.module('charting').filter('momentPeriod', function() {
    return function(interval, callPeriod, offset) {
        var period = callPeriod || interval.aggregation;
        var startDate = moment(interval.cleanDate(interval.start())).milliseconds(0);
        if (offset) {
            startDate.add(offset);
        }
        var endDate = moment(interval.cleanDate(interval.end())).milliseconds(0);
        var ticks = [];
        while(startDate.valueOf() <= endDate.valueOf()) {
            ticks.push(startDate);
            startDate = moment(startDate).add(period);
        }
        return ticks;
    }
});
