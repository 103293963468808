namespace aq.dashboard.widgets {
    angular
        .module('dashboard')
        .directive('aqBuildingInfo', (): ng.IDirective => {
            return {
                restrict: 'E',
                replace: true,
                templateUrl:
                    'app/dashboard/common/directives/buildingInfo/buildingInfo.html',
                scope: {
                    building: '=',
                    propertyHighlights: '@'
                },
                link($scope: BuildingInfoScope, instanceElement: ng.IAugmentedJQuery, instanceAttributes: ng.IAttributes, controller: {}, transclude) {
                    if ($scope.propertyHighlights) {
                        $scope.propertyHighlightsObj = $scope.propertyHighlights.split(',');
                    }
                }
            };
        });

    export interface BuildingInfoScope extends ng.IScope {
        building: aq.common.models.Building;
        propertyHighlights: string;
        propertyHighlightsObj: string[];
    }
}
