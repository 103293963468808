namespace aq {
    class MonthPickerCtrl {
        public months: any[];
        private from: moment.Moment;
        private to: moment.Moment;
        private endYear: number;
        private endMonth: number;
        private numYears: number;
        private numMonths: number;
        private order: string;
        /* @ngInject */
        constructor() { }

        $onInit() {
            this.months = [];
            this.numYears = this.numYears || 3; // go back 3 years by default
            this.numMonths = this.numMonths || 0;

            const year = this.endYear || moment().year();
            const month = this.endMonth || (moment().month() + 1);
            this.to = moment().year(year).month(month - 1).startOf('month');

            this.from = this.to.clone().subtract(Math.abs(this.numYears), 'years').subtract(Math.abs(this.numMonths), 'months');

            let current = this.to.clone();
            while (current.isAfter(this.from)) {
                this.months.push({
                    label: current.format('MMMM YYYY'),
                    value: current.clone()
                });
                current.subtract(1, 'months');
            }

            if (this.order === 'asc') {
                this.months = _.reverse(this.months);
            }
        }
    }

    angular
        .module('aq.ui')
        .component('monthPicker', {
            templateUrl: 'app/common/directives/monthPicker/monthPicker.html',
            controller: MonthPickerCtrl,
            controllerAs: 'vm',
            bindings: {
                model: '=',
                placeholder: '@?',
                order: '@?',
                endYear: '<?',
                endMonth: '<?',
                numYears: '<?',
                numMonths: '<?'
            }
        });
}