angular.module('aq.ui').directive('digitalClock', ($interval: ng.IIntervalService, $filter) => {
    function setTimestamp(scope) {
        scope.timestamp = moment().valueOf();
    }
    return {
        restrict: 'E',
        templateUrl: 'app/common/directives/digitalClock/digitalClock.html',
        scope: {
            dateFormat: '@',
            timeFormat: '@'
        },
        link: ($scope: ng.IScope, element: any) => {
            // Angular date formats: https://docs.angularjs.org/api/ng/filter/date
            $scope.dateFormat = $scope.dateFormat ? $scope.dateFormat : $filter('translate')('MM/dd/yyyy');
            $scope.timeFormat = $scope.timeFormat ? $scope.timeFormat : 'h:mm:ss a';

            setTimestamp($scope);

            const clockInterval = $interval(() => {
                setTimestamp($scope);
            }, 1000);

            $scope.$on('$destroy', () => {
                $interval.cancel(clockInterval);
            });
        }
    };
});
