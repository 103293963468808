namespace aq.settings {
    export class SettingsController {
        private accountSettingsAccess: common.models.AppAccessObject;
        private propertySettingsAccess: common.models.AppAccessObject;
        /* @ngInject */
        constructor(
            private $mdSidenav,
            private $scope,
            private $state,
            private account,
            private areaMeasurementUnit,
            private Errors,
            private Messages,
            private options,
            private realUnits,
            private Restangular,
            private timezones,
            private UserService,
            private authAccess: common.models.AuthAppAccess,
            private Auth: aq.services.Auth,
        ) {
            Restangular.restangularizeCollection(account, account.buildingGroups, 'BuildingGroups');
            Restangular.restangularizeCollection(account, account.accountMetrics, 'AccountMetrics');
            Restangular.restangularizeCollection(account, account.profiles, 'profiles');

            $scope.account = account;
            $scope.areaMeasurementUnit = areaMeasurementUnit;
            $scope.options = options;
            $scope.realUnits = realUnits;
            $scope.timezones = timezones;
            this.accountSettingsAccess = this.authAccess['Account Settings'];
            this.propertySettingsAccess = this.authAccess['Property Settings'];
        }

        public isSettingMenuItemActive() {
            return this.$state.includes('aq.settings.platform');
        }

        saveAccount() {
            return this.$scope.account.put()
                .then((account) => {
                    this.UserService.updateAccount(account.model);
                    _.extend(this.$scope.account, account.model);
                    this.$scope.updateAccount(account.model);
                    this.UserService.initLanguage();
                    this.Messages.success('Account updated successfully');
                }, this.Errors.forPromise());
        }

        public toggleSideNav() {
            this.$mdSidenav('aq-sidenav').toggle();
        }

        public isAdmin() {
            return this.UserService.currentUser.userType === 'ADMINISTRATOR';
        }
    }

    angular
        .module('aq.settings')
        .controller('SettingsController', SettingsController);
}
