namespace aq.ui {

    import SimpleActivityResponse = aq.models.activity.SimpleActivityResponse;
    import ActivityContextType = aq.models.activity.ActivityContextType;
    import IComponent = aq.models.IComponent;

    interface IBindings {
        activity: SimpleActivityResponse;
    }

    interface IIcon {
        name: string;
        color: string;
    }

    // Reading on component lifecycle hooks https://toddmotto.com/angular-1-5-lifecycle-hooks
    class SectionedListItemController implements IBindings, IComponent {
        public activity: SimpleActivityResponse;
        public accountId: number;
        public buildingId: number;
        public compact: boolean;
        private icon: { color: string; name: string };
        private formattedTitle: string;
        private formattedDate: string;
        private refreshActivities;
        private dateFormat = 'ddd, MMM D [at] h:mma';
        private showIcon: boolean;

        /* @ngInject */
        constructor(
            private ActivityService: aq.services.ActivityService,
            private PrescriptionService: aq.services.PrescriptionService,
            protected resolver: aq.services.Resolver,
            protected $mdDialog: ng.material.IDialogService
        ) { }

        public $onInit(): void {
            this.setIcon(this.activity.context);
            this.setFormattedTitle();
            this.formattedDate = moment(this.activity.createdAt).format(this.dateFormat);
        }

        public $onChanges(changes): void {
            if (changes.activity) {
                this.setIcon(changes.activity.currentValue.type); // FIXME: need to update this also
                this.activity = changes.activity.currentValue;
                this.formattedDate = moment(this.activity.createdAt).format(this.dateFormat);
            }
        }

        public $postLink(): void { }

        public $onDestroy(): void { }

        public onClick(activity: SimpleActivityResponse): void {
            this.$mdDialog.show({
                controller: 'activityModal as vm',
                templateUrl: 'app/common/components/activities/modal/modal.html',
                clickOutsideToClose: false,
                escapeToClose: false,
                locals: {
                    simpleActivityResponse: activity,
                    activityId: null,
                    accountId: this.accountId,
                    buildingId: this.buildingId,
                    refreshActivities: this.refreshActivities
                }
            });
        }

        private setIcon(context: aq.models.activity.IActivityContext): void {
            const { type } = context;
            const icon: IIcon = {name: 'description', color: 'blue'};
            switch (type) {
                case ActivityContextType.ALERT:
                    icon.name = 'error';
                    if (context.acknowledgedStatus && context.acknowledgedStatus == 'OPEN') {
                        icon.color = 'red';
                    }
                    break;
                case ActivityContextType.AID_ISSUE:
                    icon.name = 'help';
                    break;
                case ActivityContextType.ENERGY_NOTE:
                    icon.name = 'description';
                    break;
                case ActivityContextType.OPTIMAL_START:
                    icon.name = 'alarm';
                    break;
                case ActivityContextType.RX:
                    icon.name = 'healing';
                    if (context.contextObject
                        && this.PrescriptionService.isUrgent(context.contextObject)
                    ) {
                        icon.color = 'red';
                    }
                    break;
                default:
                    break;
            }
            this.icon = icon;
        }

        private setFormattedTitle(): void {
            const contextTypeStr = this.ActivityService.getContextTypeStringForActivity(this.activity);
            this.formattedTitle = this.compact
                ? (contextTypeStr && `${contextTypeStr}: ${this.activity.title}`)
                : this.activity.title;
        }
    }

    export const SectionedListItem: ng.IComponentOptions = {
        controller: SectionedListItemController,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/activities/list/sectioned-list-item.html',
        bindings: {
            showIcon: '<?',
            activity: '<',
            accountId: '<',
            buildingId: '<',
            compact: '<?',
            refreshActivities: '&'
        }
    };

    angular
        .module('aq.ui')
        .component('sectionedListItem', SectionedListItem);
}
