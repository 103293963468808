angular
.module('aq.admin.configuration.integrationApps')
.controller('IntegrationAppsDetailsCtrl', function ($scope, Restangular, $state, Errors) {

    Restangular.one('IntegrationApps', $state.params.integrationAppId)
        .get({ single: true, inflate: 'workers' }).then(function(integrationApp) {
        $scope.integrationApp = integrationApp;
    });

    Restangular.one('IntegrationApps').customGET('queryServices').then(function(workers) {
        $scope.workers = workers;
    });

    $scope.updateConfig = function(integrationApp) {
        return integrationApp.put().catch(Errors.forPromise());
    };


    $scope.deleteApp = function(app) {
        return Restangular.one('IntegrationApps', app.id).remove().then(function(integrationApp) {
            $scope.integrationApps = _.reject($scope.integrationApps, function(deleted) {
                return deleted.id === integrationApp.id;
            });
            $state.go('aq.admin.configuration.integrationApps');
        })
    };


    $scope.addService = function(newWorker, integrationApp) {
        var defaults = _(newWorker.params).keyBy('name').mapValues('default').value();
        integrationApp.workers.push({
            integrationApp: integrationApp.id,
            workerClassName: newWorker.id,
            enabled: true,
            parameters: defaults
        });
        return integrationApp.put().catch(Errors.forPromise());
    };

    $scope.deleteService = function(oldWorker, integrationApp) {
        integrationApp.workers = _.without(integrationApp.workers, _.find(integrationApp.workers, {id: oldWorker.id}));
       return integrationApp.put().catch(Errors.forPromise());
    };

    $scope.integrationAppTypes = [
        { value: 'INTERNAL', label: 'Internal' },
        { value: 'EXTERNAL', label: 'External' }
    ]

});
