angular
    .module('aq.reports')
    .directive('reportBar', () => {
        return {
            restrict: 'E',
            replace: false,
            templateUrl: 'app/reports/summary/project/directives/reportBar.html',
            scope: {
                aqData: '='
            },
            link($scope, element, $attrs) {
                $scope.$watch('aqData', (aqData: any) => {

                    Highcharts.setOptions({
                        lang: {
                            thousandsSep: ','
                        }
                    });

                    if (aqData) {
                        $scope.plotOptions = {
                            chart: {
                                plotBackgroundColor: null,
                                plotBorderWidth: null,
                                plotShadow: false,
                                type: 'column',
                                inverted: true,
                                height: aqData.options && aqData.options.height ? aqData.options.height : 100,
                                width: aqData.options && aqData.options.width ? aqData.options.width : 160
                            },
                            title: {
                                text: ''
                            },
                            xAxis: {
                                categories: aqData.options.categories
                            },
                            yAxis: {
                                title: {
                                    text: ''
                                },
                                tickInterval: aqData.options.step || 1
                            },
                            legend: { enabled: false },
                            exporting: {
                                enabled: false
                            },
                            series: [{
                                name: ' ',
                                colorByPoint: true,
                                data: _.map(aqData.data, (item, index) => {
                                    return {
                                        x: index,
                                        name: item.name,
                                        y: item.value,
                                        color: item.color
                                    };
                                })
                            }]
                        };
                    }
                });
            }
        };
    });
