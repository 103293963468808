namespace aq.admin.configuration.utilityCompany {

    export class UtilityCompanyListCtrl {
        private searchText: string;
        /* @ngInject */
        constructor(
            private $scope: UtilityCompanyListCtrlScope,
            private allUtilityCompanies: UtilityCompany[],
            private measures: aq.common.models.Measure[],
            private $state: ng.ui.IStateService,
            private $mdDialog: ng.material.IDialogService,
            private Messages
        ) {
            $scope.utilityCompanies = allUtilityCompanies;
            $scope.measures = measures.filter(this.supportedMeasure);
            $scope.measureMap = this.createMeasureMap(this.$scope.measures);
            $scope.defaultUnitMap = this.createDefaultUnits(this.$scope.measures);
            $scope.$on('UtilityCompanySaved', (event, data) => {
                const existingUtilityCompany = allUtilityCompanies.find((utilityCompany) => {
                    return utilityCompany.id == data.id;
                });
                if (existingUtilityCompany) {
                    Object.assign(existingUtilityCompany, data);
                } else {
                    allUtilityCompanies.push(data);
                }
            });
        }

        create() {
            this.$mdDialog.show({
                controller: 'UtilityCompanyNewCtrl as vm',
                templateUrl: 'app/admin/configuration/utilityCompany/new/new.html',
                clickOutsideToClose: true,
                parent: angular.element(document.body),
                scope: this.$scope,
                preserveScope: true,
                locals: {
                    newUtilityCompany: { name: '' }
                }
            });
        }

        searchBy(searchText) {
            this.searchText = searchText;
            if (searchText) {
                this.$scope.utilityCompanies = this.allUtilityCompanies.filter((utilityCompany: UtilityCompany) => {
                    return _.includes(utilityCompany.name.toUpperCase(), searchText.toUpperCase());
                });
            } else {
                this.$scope.utilityCompanies = this.allUtilityCompanies;
            }
        };

        edit(utilityCompany) {
            this.$state.go('.details', {utilityCompanyId: utilityCompany.id});
        }

        delete(utilityCompany: UtilityCompanyElement) {
            utilityCompany.remove().then(() => {
                this.Messages.success('Successfully deleted utility company');
                _.remove(this.$scope.utilityCompanies, {id: utilityCompany.id});
                _.remove(this.allUtilityCompanies, {id: utilityCompany.id});
            }).catch((err) => {
                if (err.data && err.data.message) {
                    this.Messages.error(err.data.message);
                } else {
                    this.Messages.error('Unable to delete utility company');
                }
            });
        }

        supportedMeasure(measure: aq.common.models.Measure) {
            switch (measure.name) {
                case 'electricity':
                case 'water':
                case 'steam':
                case 'gas' :
                case 'heat':
                    return true;
                default:
                    return false;
            }
        };

        /* Displaying measure based on id instead of inflating by measure. */
        createMeasureMap(measures: aq.common.models.Measure[]) {
            const measureMap: { [key: string]: string } = {};
            measures.forEach((measure) => {
                measureMap[measure.id] = measure.name;
            });
            return measureMap;
        };

        /* default units shown to administrator - may not be same as account units */
        createDefaultUnits(measures: aq.common.models.Measure[]) {
            const defaultUnitMap: { [key: string]: { usage: string, demand: string } } = {};
            measures.forEach((measure) => {
                switch (measure.name) {
                    case 'electricity': defaultUnitMap[measure.id] = {usage: 'kWh', demand: 'kW'};
                        break;
                    case 'water': defaultUnitMap[measure.id] = {usage: 'GAL', demand: 'GPM'};
                        break;
                    case 'steam': defaultUnitMap[measure.id] = {usage: 'Mlb', demand: 'Mlb/h'};
                        break;
                    case 'heat': defaultUnitMap[measure.id] = {usage: 'BTU', demand: 'BTU/h'};
                        break;
                    case 'gas': defaultUnitMap[measure.id] = {usage: 'CCF', demand: null};
                        break;
                }
            });
            return defaultUnitMap;
        };

        getUtilityCompanyUrl(utilityUrl: string): string {
            if (utilityUrl) {
                if (utilityUrl.startsWith('http')) {
                    return utilityUrl;
                } else {
                    return 'http://' + utilityUrl;
                }
            }
        }
    }

    export interface UtilityCompanyListCtrlScope extends ng.IScope {
        vm: {
            searchText: string
        };
        utilityCompanies: UtilityCompany[];
        measureMap: {[key: string]: string};
        measures: aq.common.models.Measure[];
        // following needs more thought..
        defaultUnitMap: {[key: string]: {usage: string, demand: string}};
        timeOptions: any[];
    }
}

angular
    .module('utilityCompany')
    .controller('UtilityCompanyListCtrl', aq.admin.configuration.utilityCompany.UtilityCompanyListCtrl);
