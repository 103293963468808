namespace aq.ui {
    import AidActions = aq.models.segment.AidActions;

    class _IssueResolveAndClose {
        public resolutionMessage: string;
        public issueResolveAndCloseForm;
        public isBeingSaved: boolean;

        private activity: string;
        private issue: string;
        private refreshActivitiesAndCloseDialog;
        private hideResolveAndClose;

        /* @ngInject */
        constructor(
            private RestangularV3: restangular.IService,
            private UserService: aq.services.UserService,
            private ActivityService: aq.services.ActivityService,
            private resolver: aq.services.Resolver,
            private Segment: aq.services.SegmentService
        ) {
        }

        public cancelResolveAndClose() {
            this.hideResolveAndClose();
        }

        public resolveAndClose() {
            this.resolver(async () => {
                this.Segment.trackEvent (AidActions.RESOLVE_AND_CLOSE_ISSUE, { 
                        account_id: this.UserService.currentUser.currentProfile.account.id,
                        user_id: this.UserService.currentUser.id 
                    });
                this.isBeingSaved = true;
                const createMessagePromise = this.addResolutionMessageToActivity(this.activity, this.resolutionMessage,
                    this.UserService.currentUser.id);
                const closeIssuePromise = this.closeIssue(this.issue);
                await Promise.all([createMessagePromise, closeIssuePromise]);
                this.refreshActivitiesAndCloseDialog();
            });
        }

        private addResolutionMessageToActivity(parent: string, body: string, author: number): Promise<aq.models.activity.ActivityResponse> {
            const request: aq.models.activity.ActivityMessageCreateRequest = { body, author };
            return this.ActivityService.createMessage(request, parent);
        }

        private closeIssue(issue: string): angular.IPromise<any> {
            return this.RestangularV3.one('issues', issue).all('close').customPOST();
        }

    }

    export const IssueResolveAndClose: ng.IComponentOptions = {
        controller: _IssueResolveAndClose,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/activities/contexts/issueResolveAndClose.html',
        bindings: {
            activity: '<',
            issue: '<',
            refreshActivitiesAndCloseDialog: '&',
            hideResolveAndClose: '&'
        }
    };
    angular.module('aq.ui')
        .component('issueResolveAndClose', IssueResolveAndClose);
}
