var aq;
(function (aq) {
    var energyStarApi;
    (function (energyStarApi) {
        var EnergyStarApiService = /** @class */ (function () {
            /* @ngInject */
            function EnergyStarApiService(Auth, AuthToken, $location, $http, AQ_API_URL, resolver) {
                this.Auth = Auth;
                this.AuthToken = AuthToken;
                this.$location = $location;
                this.$http = $http;
                this.AQ_API_URL = AQ_API_URL;
                this.resolver = resolver;
                this.baseUrl = this.AQ_API_URL + "energy-star/v1";
            }
            EnergyStarApiService.prototype.getEnergyStarScoreForBuilding = function (accountId, buildingId) {
                return __awaiter(this, void 0, void 0, function () {
                    var energyStarUrl;
                    return __generator(this, function (_a) {
                        energyStarUrl = this.getEnergyStarUrl(accountId, false) + ("/scores/" + buildingId);
                        return [2 /*return*/, this.sendRequest(energyStarUrl)];
                    });
                });
            };
            EnergyStarApiService.prototype.getEnergyStarUrl = function (accountId, isAccountsParentRoute) {
                return isAccountsParentRoute
                    ? this.baseUrl + "/accounts/" + accountId
                    : this.baseUrl;
            };
            EnergyStarApiService.prototype.sendRequest = function (reqUrl) {
                return __awaiter(this, void 0, void 0, function () {
                    var _this = this;
                    return __generator(this, function (_a) {
                        return [2 /*return*/, this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                                var response;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.$http({
                                                method: 'GET',
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    Authorization: "Bearer " + (this.AuthToken ? this.AuthToken : this.$location.search().authToken)
                                                },
                                                url: reqUrl
                                            })];
                                        case 1:
                                            response = _a.sent();
                                            return [2 /*return*/, response.data];
                                    }
                                });
                            }); })];
                    });
                });
            };
            EnergyStarApiService.prototype.sendPutRequest = function (reqUrl, data) {
                return __awaiter(this, void 0, void 0, function () {
                    var _this = this;
                    return __generator(this, function (_a) {
                        return [2 /*return*/, this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                                var response;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.$http({
                                                method: 'PUT',
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    Authorization: "Bearer " + (this.AuthToken ? this.AuthToken : this.$location.search().authToken)
                                                },
                                                data: JSON.stringify(data),
                                                url: reqUrl,
                                                transformResponse: function (raw) { return raw; }
                                            })];
                                        case 1:
                                            response = _a.sent();
                                            return [2 /*return*/, response.data];
                                    }
                                });
                            }); })];
                    });
                });
            };
            EnergyStarApiService.prototype.unlinkBuildingFromEnergyStarProperty = function (accountId, buildingId) {
                return __awaiter(this, void 0, void 0, function () {
                    var esPropertiesUrl, esProperties, e_1;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                esPropertiesUrl = this.baseUrl + "/accounts/" + accountId + "/properties";
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.sendRequest(esPropertiesUrl)];
                            case 2:
                                esProperties = _a.sent();
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _a.sent();
                                return [2 /*return*/];
                            case 4: return [4 /*yield*/, Promise.all(esProperties.filter(function (p) { return !!p; }).map(function (esProperty) { return __awaiter(_this, void 0, void 0, function () {
                                    var esPropertyId, propertyId, buildings, unlinkUrl, remainingBuildingIds, remainingBuildingData, esMetersUrl, esMeters;
                                    var _this = this;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                esPropertyId = esProperty.id, propertyId = esProperty.propertyId, buildings = esProperty.buildings;
                                                if (!propertyId || !buildings || !buildings.find(function (b) { return b.buildingId === buildingId; })) {
                                                    return [2 /*return*/];
                                                }
                                                unlinkUrl = this.baseUrl + "/accounts/" + accountId + "/properties/" + propertyId + "/link";
                                                remainingBuildingIds = buildings.map(function (b) { return b.buildingId; }).filter(function (id) { return id !== buildingId; });
                                                if (remainingBuildingIds.length > 0) {
                                                    unlinkUrl += "?buildings=" + remainingBuildingIds.join(',');
                                                }
                                                remainingBuildingData = { buildingIds: remainingBuildingIds };
                                                return [4 /*yield*/, this.sendPutRequest(unlinkUrl, remainingBuildingData)];
                                            case 1:
                                                _a.sent();
                                                if (!(remainingBuildingIds.length === 0)) return [3 /*break*/, 4];
                                                esMetersUrl = this.baseUrl + "/properties/" + esPropertyId + "/meters";
                                                return [4 /*yield*/, this.sendRequest(esMetersUrl)];
                                            case 2:
                                                esMeters = _a.sent();
                                                return [4 /*yield*/, Promise.all(esMeters.map(function (esMeter) { return __awaiter(_this, void 0, void 0, function () {
                                                        var esMeterId, updateTimePeriodUrl, timePeriodData;
                                                        return __generator(this, function (_a) {
                                                            switch (_a.label) {
                                                                case 0:
                                                                    esMeterId = esMeter.id;
                                                                    updateTimePeriodUrl = this.baseUrl + "/meters/" + esMeterId + "/time-periods";
                                                                    timePeriodData = { timePeriods: [] };
                                                                    return [4 /*yield*/, this.sendPutRequest(updateTimePeriodUrl, timePeriodData)];
                                                                case 1:
                                                                    _a.sent();
                                                                    return [2 /*return*/];
                                                            }
                                                        });
                                                    }); }))];
                                            case 3:
                                                _a.sent();
                                                _a.label = 4;
                                            case 4: return [2 /*return*/];
                                        }
                                    });
                                }); }))];
                            case 5:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                });
            };
            return EnergyStarApiService;
        }());
        energyStarApi.EnergyStarApiService = EnergyStarApiService;
        angular
            .module('aq.energyStarApi', ['aq.auth'])
            .service('EnergyStarApiService', EnergyStarApiService);
    })(energyStarApi = aq.energyStarApi || (aq.energyStarApi = {}));
})(aq || (aq = {}));
