angular.module('aq-main').run(function($httpBackend) {
    const regexp = /^\/api\/v1\/accounts\/.*\/buildings\/.*\/energyInsights\/cost/;

    MockContext('cost', () => {
        $httpBackend.whenGET(regexp).respond((method, url, data) => {
            const costBreakdown = {
                timestamps: [],
                accounts: [{
                    utilityCompany: 'Pepco',
                    accountNumber: '123456789',
                    charges: [{
                        name: 'Taxes & Fees',
                        values: []
                    }, {
                        name: 'Demand',
                        values: []
                    }, {
                        name: 'Usage',
                        values: []
                    }]
                }]
            };
            const params: any = MockHelpers.getParamsAsObject(url);
            const start = moment(params.start);
            const end = moment(params.end);
            let intervalString;
            if (params.interval == '1d') {
                intervalString = 'days';
            }else {
                intervalString = 'months';
            }
            const numMonths = end.diff(start, intervalString);

            _.times(numMonths, (n) => {
                costBreakdown.timestamps.push(moment(start).add(n, intervalString).valueOf());
                costBreakdown.accounts.forEach(function(account) {
                    account.charges.forEach(function(charge) {
                        if (charge.name === 'Taxes & Fees') {
                            charge.values.push(100);
                        } else if (charge.name === 'Demand') {
                            charge.values.push(_.random(100, 200));
                        } else if (charge.name === 'Usage') {
                            charge.values.push(_.random(500, 1000));
                        }
                    });
                });
            });
            return [200, costBreakdown, {}];
        });
    });
});