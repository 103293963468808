namespace aq.ui {

    class AqSpinnerCtrl {
        public size: number;
        public viewSize: number;
        public center: number;
        public radius: number;
        public strokeWidth: number;
        /* @ngInject */
        constructor() {}

        $onInit() {
            if (!this.size) {
                this.size = 65;
            }
            this.viewSize = this.size + Math.floor(this.size * .1);
            this.center = Math.round(this.size / 2);
            this.strokeWidth = Math.floor(Math.max(this.size / 10, 1));
            this.radius = Math.floor(this.center - (this.strokeWidth / 2));
            this.radius = this.radius - Math.floor(this.radius * .1);
        }
    }

    angular
        .module('aq.ui')
        .component('aqSpinner', {
            templateUrl: 'app/common/directives/aqSpinner/aqSpinner.html',
            controller: AqSpinnerCtrl,
            controllerAs: 'vm',
            bindings: {
                size: '<?'
            }
        });
}
