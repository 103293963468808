var aq;
(function (aq) {
    var reports;
    (function (reports) {
        var projectStatus;
        (function (projectStatus) {
            var ProjectStatusCtrl = /** @class */ (function (_super) {
                __extends(ProjectStatusCtrl, _super);
                /* ngInject */
                function ProjectStatusCtrl(account, buildings, startDate, statuses, projects, newProjectsSummaryData, completedProjectsSummaryData, inProgressProjectsSummaryData, $filter, OptionsService, $timeout) {
                    var _this = _super.call(this, $timeout) || this;
                    _this.account = account;
                    _this.buildings = buildings;
                    _this.startDate = startDate;
                    _this.statuses = statuses;
                    _this.projects = projects;
                    _this.newProjectsSummaryData = newProjectsSummaryData;
                    _this.completedProjectsSummaryData = completedProjectsSummaryData;
                    _this.inProgressProjectsSummaryData = inProgressProjectsSummaryData;
                    _this.$filter = $filter;
                    _this.OptionsService = OptionsService;
                    _this.$timeout = $timeout;
                    var longMonthYearFormat = 'MMMM D, YYYY';
                    _this.newProjectsSummary = _this.getProjectsSummary(_this.newProjectsSummaryData);
                    _this.completedProjectsSummary = _this.getProjectsSummary(_this.completedProjectsSummaryData);
                    _this.inProgressProjectsSummary = _this.getProjectsSummary(_this.inProgressProjectsSummaryData);
                    _this.currencyUnit = _this.OptionsService.currencyUnit();
                    var currentStartMoment = moment(_this.startDate);
                    var pastStartMoment = moment(_this.startDate).subtract(1, 'month');
                    _this.currentYear = currentStartMoment.format('YYYY');
                    _this.currentMonth = currentStartMoment.format('MMMM');
                    _this.currentEndOfMonth = currentStartMoment.endOf('month').format(longMonthYearFormat);
                    _this.pastYear = pastStartMoment.format('YYYY');
                    _this.pastMonth = pastStartMoment.format('MMMM');
                    _this.pastEndOfMonth = pastStartMoment.endOf('month').format(longMonthYearFormat);
                    var formattedStartDate = currentStartMoment.format(longMonthYearFormat);
                    var formattedEndDate = currentStartMoment.endOf('month').format(longMonthYearFormat);
                    _this.reportName = "Projects updates " + formattedStartDate + " through " + formattedEndDate;
                    _this.initReportItems();
                    _this.notifyDocumentReady();
                    return _this;
                }
                ProjectStatusCtrl.prototype.getProjectsSummary = function (projectsSummaryData) {
                    var currentData = projectsSummaryData.current;
                    var trailingData = projectsSummaryData.trailing;
                    var result = {
                        current: {
                            count: currentData.length,
                            projectIds: currentData.map(function (r) { return r.projectId; }),
                            savings: _.sumBy(currentData, function (r) { return r.dollarSavingsMax || 0; }) || 0,
                            savingsEnergy: _.sumBy(currentData, function (r) { return r.energySavingsMax || 0; }) || 0
                        },
                        past: {
                            count: trailingData.length,
                            projectIds: trailingData.map(function (r) { return r.projectId; }),
                            savings: _.sumBy(trailingData, function (r) { return r.dollarSavingsMax || 0; }) || 0,
                            savingsEnergy: _.sumBy(trailingData, function (r) { return r.energySavingsMax || 0; }) || 0
                        }
                    };
                    result.countIndicator = result.current.count - result.past.count;
                    result.countDiff = Math.abs(result.current.count - result.past.count);
                    result.countInfo = result.countIndicator == 0
                        ? 'no change'
                        : this.getIndicatorText(result.countIndicator) + " " + result.countDiff + " projects";
                    result.savingsIndicator = result.current.savings - result.past.savings;
                    result.savingsDiff = Math.abs(result.current.savings - result.past.savings);
                    var savings = this.$filter('currency')(result.savingsDiff, '$', 0);
                    result.savingsInfo = result.savingsIndicator == 0
                        ? 'no $ change'
                        : this.getIndicatorText(result.savingsIndicator) + " " + savings;
                    result.savingsEnergyIndicator = result.current.savingsEnergy - result.past.savingsEnergy;
                    result.savingsEnergyDiff = Math.abs(result.current.savingsEnergy - result.past.savingsEnergy);
                    var savingsEnergy = this.$filter('aqnumber')(result.savingsEnergyDiff);
                    result.savingsEnergyInfo = result.savingsEnergyIndicator == 0
                        ? '(no kWh change)'
                        : "(" + this.getIndicatorText(result.savingsEnergyIndicator) + " " + savingsEnergy + " kWh)";
                    if (result.savingsIndicator == 0 && result.savingsEnergyIndicator == 0) {
                        result.savingsInfo = '';
                        result.savingsEnergyInfo = 'no change';
                    }
                    return result;
                };
                ProjectStatusCtrl.prototype.getIndicatorText = function (value) {
                    return "" + (value > 0 ? 'UP' : 'DOWN');
                };
                ProjectStatusCtrl.prototype.initReportItems = function () {
                    var _this = this;
                    this.newProjectsItems = [];
                    this.completedProjectsItems = [];
                    this.inProgressProjectsItems = [];
                    Object.keys(this.projects).forEach(function (key) {
                        var building = _this.buildings.find(function (b) { return b.id == key; });
                        var newBuildingProjects = _this.projects[key].filter(function (p) {
                            return _this.newProjectsSummary.current.projectIds.includes(p.projectId);
                        });
                        _this.initBuildingGroupItems(_this.newProjectsItems, building.name, newBuildingProjects);
                        var completedBuildingProjects = _this.projects[key].filter(function (p) {
                            return _this.completedProjectsSummary.current.projectIds.includes(p.projectId);
                        });
                        _this.initBuildingGroupItems(_this.completedProjectsItems, building.name, completedBuildingProjects);
                        var inProgressBuildingProjects = _this.projects[key].filter(function (p) {
                            return _this.inProgressProjectsSummary.current.projectIds.includes(p.projectId);
                        });
                        _this.initBuildingGroupItems(_this.inProgressProjectsItems, building.name, inProgressBuildingProjects);
                    });
                };
                ProjectStatusCtrl.prototype.initBuildingGroupItems = function (resultProjectItems, buildingName, buildingProjects) {
                    this.sortProjects(buildingProjects);
                    if (buildingProjects.length > 0) {
                        var buildingHeaderItem = {
                            name: buildingName,
                            projectId: 'BUILDING',
                            code: 'BUILDING',
                            estimatedSavings: _.sumBy(buildingProjects, function (bp) { return bp.estimatedSavings || 0; }) || 0,
                            estimatedSavingsEnergy: _.sumBy(buildingProjects, function (bp) { return bp.estimatedSavingsEnergy || 0; }) || 0,
                            estimatedCost: _.sumBy(buildingProjects, function (bp) { return bp.estimatedCost || 0; }) || 0,
                        };
                        resultProjectItems.push(buildingHeaderItem);
                        resultProjectItems.push.apply(resultProjectItems, buildingProjects);
                    }
                };
                ProjectStatusCtrl.prototype.sortProjects = function (projects) {
                    var statusOrder = {
                        'Declined': 5,
                        'Needs Acceptance': 4,
                        'Needs Acc.': 4,
                        'Backlog': 3,
                        'In Progress': 2,
                        'Completed': 1
                    };
                    projects.sort(function (a, b) {
                        if (a.priorityOrder != b.priorityOrder) {
                            return b.priorityOrder - a.priorityOrder;
                        }
                        if (a.status != b.status) {
                            return statusOrder[a.status] - statusOrder[b.status];
                        }
                        return moment(a.dateCreated).valueOf() - moment(b.dateCreated).valueOf();
                    });
                };
                return ProjectStatusCtrl;
            }(reports.BaseDocraptorReportCtrl));
            projectStatus.ProjectStatusCtrl = ProjectStatusCtrl;
            angular
                .module('aq.reports')
                .controller('ProjectStatusCtrl', ProjectStatusCtrl);
        })(projectStatus = reports.projectStatus || (reports.projectStatus = {}));
    })(reports = aq.reports || (aq.reports = {}));
})(aq || (aq = {}));
