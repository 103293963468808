namespace aq.dashboard.widgets {
    export class EmptyDataService {
        // this class is used for generation of timestamps and empty values arrays for
        // the purpose of visualizing placeholders for the data while the real data is fetched (table and graph widgets)
        constructor() { }
        public getEmptyTimestampsAndValuesForPeriod(step: string, period: { start: string, end: string }) {
            const timestamps = [];
            const values = [];
            let stepObj;
            switch (step) {
                case '15min':
                    stepObj = { n: 15, type: 'minute' };
                    break;
                case '1h':
                    stepObj = { n: 1, type: 'hour' };
                    break;
                case '1d':
                    stepObj = { n: 1, type: 'day' };
                    break;
                case '1w':
                    stepObj = { n: 7, type: 'day' };
                    break;
                case '1m':
                    stepObj = { n: 1, type: 'month' };
                    break;
                default:
                    stepObj = { n: 1, type: 'day' };
                    break;
            };
            let currentTs = moment(period.start);
            let end = moment(period.end);
            while (currentTs.isBefore(end)) {
                timestamps.push(currentTs.valueOf());
                values.push(null);
                currentTs.add(stepObj.n, stepObj.type);
            }
            return {
                timestamps,
                values
            };
        }
    }
    angular.module('aq.dashboard.widgets').service('EmptyDataService', EmptyDataService);
}