namespace aq.reports {

    export class BaseBuildingReportCtrl extends BaseDocraptorReportCtrl {
        public buildingReport: BuildingReport;
        public measurementType;
        private readonly supportedTypes = [
            'electricity',
            'water',
            'gas',
            'steam',
            'heat'
        ];
        constructor(
            protected currentDate,
            protected account,
            protected building: aq.common.models.Building,
            protected energyStarScore,
            protected functionalities,
            protected OptionsService,
            protected availableMetrics,
            protected meters,
            protected $timeout: ng.ITimeoutService
        ) {
            super($timeout);
            const serviceReports: BuildingServiceReport[] = [];
            let colors;
            if (account && account.accountName.toLowerCase() === 'under armour') {
                colors = {
                    primaryColor: '#F05660',
                    secondaryColor: '#B4B5B5',
                    weatherPrimaryColor: '#F05660',
                    weatherSecondaryColor: '#FFDD00'
                };
            } else {
                colors = {
                    primaryColor: '#008BF5',
                    secondaryColor: '#B4B5B5',
                    currentYearColor: '#008BF5',
                    secondYearColor: '#ba7dd4',
                    thirdYearColor: '#FFA100',
                    weatherPrimaryColor: '#FFA100',
                    weatherSecondaryColor: '#FFDD00'
                };
            }
            const reportBuilding = this.building;
            reportBuilding.energyStarScore = this.energyStarScore;
            _.extend(reportBuilding, { currentTime: moment(this.currentDate).tz(this.building.timeZoneId) });

            const types = _.filter(this.supportedTypes, (type: string) => {
                const hasMetric = _.some(this.availableMetrics, (metric: string) => metric.toLowerCase() == type);
                if (!hasMetric) {
                    return false;
                }
                if (type == 'water') {
                    const waterMeters = _.filter(this.meters, (meter) => meter.measure == 'water');
                    const isOnlyManualMeters = _.every(waterMeters, (meter) => meter.manualMeter == true);
                    return isOnlyManualMeters ? false : true;
                } else {
                    return true;
                }
            });

            const measurementSystem = this.OptionsService.measurementSystem;
            const typeUnit = {
                electricity: ['kWh', 'kW'],
                water: [measurementSystem.waterUnit, measurementSystem.waterFlowRateUnit],
                gas: [measurementSystem.gasUnit],
                steam: [measurementSystem.steamUnit, measurementSystem.steamRateUnit],
                heat: [measurementSystem.heatUnit, measurementSystem.heatRateUnit]
            };

            this.measurementType = {
                electricity: ['ENERGY', 'POWER', 'POWER_EXPECTED', 'POWER_STDDEV'],
                water: ['WATER', 'FLOW_RATE'],
                gas: ['GAS', 'FLOW_RATE'],
                steam: ['STEAM_MASS', 'STEAM_MASS_RATE'],
                heat: ['HEAT_TRANSFER', 'HEAT_TRANSFER_RATE']
            };

            _.each(types, (type) => {
                const reportConfig: Report = {
                    account,
                    colors,
                    functionalities: _.map(this.functionalities, 'function'),
                    building: reportBuilding,
                    areaMeasurementUnit: this.OptionsService.areaMeasurementUnit(),
                    temperatureUnit: this.OptionsService.temperatureUnit(),
                    measures: this.measurementType[type],
                    metrics: this.measurementType[type].toString().toLowerCase(),
                    usageMetric: this.measurementType[type][0].toString().toLowerCase(),
                    demandMetric: this.measurementType[type][1].toString().toLowerCase(),
                    usageUnit: this.OptionsService.getUnitByEnumName(typeUnit[type][0]),
                    isWaterOrGasReport: type != 'electricity'
                };
                if (type == 'electricity') {
                    reportConfig.expectedDemandMetric = this.measurementType[type][2].toString();
                    reportConfig.expectedDemandVariance = this.measurementType[type][3].toString();
                }
                // we don't have demand on gas
                if (type != 'gas') {
                    reportConfig.demandUnit = this.OptionsService.getUnitByEnumName(typeUnit[type][1]);
                }
                serviceReports.push({
                    config: reportConfig,
                    data: null
                });
            });

            this.buildingReport = {
                building: reportBuilding,
                serviceReports
            };
        }
    }
    angular.module('aq.reports').controller('BaseBuildingReportCtrl', BaseBuildingReportCtrl);
}
