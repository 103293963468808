namespace aq.dashboard {
    declare var Skycons:any;

    angular.module('dashboard')
        .factory('CurrentWeatherService', (Restangular) => {
            function getCurrentWeather(latitude, longitude) {
                return Restangular.one('forecast').get({ latitude: latitude, longitude: longitude });
            }
            return {
                getCurrentWeather: getCurrentWeather
            }
        })
        .directive('currentWeather', (CurrentWeatherService) => {
            return {
                restrict: 'E',
                templateUrl: 'app/dashboard/common/directives/currentWeather/currentWeather.html',
                scope: {
                    latitude: '@',
                    longitude: '@'
                },
                link: ($scope: any, element: any) => {
                    if ($scope.latitude && $scope.longitude) {
                        CurrentWeatherService.getCurrentWeather($scope.latitude, $scope.longitude)
                            .then((data) => {
                                if (data.currently) {
                                    $scope.weather = data.currently;

                                    let skycons = new Skycons({ color: '#444444' });

                                    skycons.add(element.find('canvas')[0], $scope.weather.icon);

                                    skycons.play();
                                }
                            });
                    }
                }
            }
        });
}