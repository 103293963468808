var aq;
(function (aq) {
    var services;
    (function (services) {
        var RedirectService = /** @class */ (function () {
            /* @ngInject */
            function RedirectService(AuthToken, Messages) {
                this.AuthToken = AuthToken;
                this.Messages = Messages;
                this.routeParamsRegex = /\/:\w+\//g;
            }
            RedirectService.prototype.isLocalhost = function () {
                return Boolean(window.location.hostname === 'localhost' ||
                    // [::1] is the IPv6 localhost address.
                    window.location.hostname === '[::1]' ||
                    // 127.0.0.1/8 is considered localhost for IPv4.
                    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));
            };
            RedirectService.prototype.isStage = function () {
                return Boolean(window.location.hostname.indexOf('stage.') > -1 ||
                    window.location.hostname.indexOf('qa') > -1);
            };
            RedirectService.prototype.isProd = function () {
                return Boolean(window.location.hostname.indexOf('my.') > -1);
            };
            RedirectService.prototype.getBaseURL = function () {
                if (this.isProd()) {
                    return 'https://app.aquicore.com';
                }
                if (this.isStage()) {
                    return 'https://stageapp.aquicore.com';
                }
                return 'http://localhost:3030';
            };
            RedirectService.prototype.getOnboardingBaseURL = function () {
                if (this.isProd()) {
                    return 'https://onboarding.aquicore.com';
                }
                if (this.isStage()) {
                    return 'https://stageonboarding.aquicore.com';
                }
                return 'http://localhost:3040';
            };
            RedirectService.prototype.getAuth = function (token) {
                return "authToken=" + (token ? token : this.AuthToken);
            };
            RedirectService.prototype.validateParams = function (params) {
                for (var key in params) {
                    if (params.hasOwnProperty(key) && params[key] == void 0) {
                        return false;
                    }
                }
                return true;
            };
            RedirectService.prototype.redirect = function (redirectParams, openInNewWindow) {
                if (openInNewWindow === void 0) { openInNewWindow = false; }
                if (!this.validateParams(redirectParams)) {
                    this.Messages.error('Something went wrong, please try again later.');
                }
                var destination = redirectParams.destination, building = redirectParams.building, account = redirectParams.account, profile = redirectParams.profile, authToken = redirectParams.authToken, payload = redirectParams.payload;
                var url = this.getBaseURL() + "/" + destination + "?";
                var params = [
                    "building=" + building,
                    "account=" + account,
                    "profile=" + profile,
                    this.getAuth(authToken),
                    "origin=" + window.location.href,
                    "redirect=true",
                ];
                if (payload)
                    params.push("payload=" + payload);
                var result = "" + url + params.join('&');
                if (!this.isProd()) {
                    console.log('redirect:', result);
                }
                if (openInNewWindow) {
                    var link = document.createElement("a");
                    link.setAttribute("href", result);
                    link.setAttribute("target", "_blank");
                    link.click();
                }
                else {
                    window.location.href = result;
                }
            };
            RedirectService.prototype.handleIncomingRedirect = function (redirectParams) {
                console.log('RedirectService.handleIncomingRedirect is not implemented', redirectParams);
            };
            RedirectService.prototype.redirectWithoutParams = function (destination) {
                window.location.href = this.getBaseURL() + "/" + destination;
            };
            RedirectService.prototype.redirectOnboardingWithoutParams = function (destination) {
                window.location.href = this.getOnboardingBaseURL() + "/" + destination;
            };
            RedirectService.prototype.getFrontendRedirectUrl = function (path) {
                var _this = this;
                var redirectUrl = '';
                services.mappedRoutes.forEach(function (item) {
                    // skip if redirect url is already found
                    if (redirectUrl) {
                        return;
                    }
                    // get parameters for current route
                    // '/account/:accountId/building/:buildingId/' => [':accountId', ':buildingId']
                    var monolithUrl = item.monolithUrl.endsWith('/')
                        ? item.monolithUrl
                        : item.monolithUrl + "/";
                    var paramNames = monolithUrl.match(_this.routeParamsRegex);
                    if (paramNames) {
                        paramNames = paramNames.map(function (param) { return param.substring(1, param.length - 1); });
                    }
                    // build custom route regex to try matching input path parameter values
                    // '/account/:accountId/building/:buildingId/' => '\/account\/(\d+)\/building\/(\d+)\/
                    var monolithUrlRegexString = item.monolithUrl;
                    if (paramNames) {
                        paramNames.forEach(function (param) {
                            monolithUrlRegexString = monolithUrlRegexString.replace(param, '(\\d+)');
                        });
                    }
                    monolithUrlRegexString = monolithUrlRegexString.replace('?', '\\?');
                    monolithUrlRegexString = monolithUrlRegexString.split('/').join('\\/');
                    monolithUrlRegexString = "^" + monolithUrlRegexString + "$";
                    // try matching input path with current route
                    var monolithUrlRegex = new RegExp(monolithUrlRegexString);
                    var matches = path.match(monolithUrlRegex);
                    // if regex matched, but there were no params, set redirect url as is
                    if (!paramNames && matches && matches.length > 0) {
                        redirectUrl = item.frontendUrl;
                    }
                    // if regex matched, but there are route params
                    // replace matching parameter values in the redirect url
                    // (first matched value is the entire route, and the remaining ones are ordered parameter values)
                    else if (paramNames && matches && matches.length == paramNames.length + 1) {
                        var frontendUrl = item.frontendUrl;
                        var counter_1 = 1;
                        while (counter_1 < matches.length) {
                            var currentParam = paramNames[counter_1 - 1];
                            var currentValue = matches[counter_1];
                            frontendUrl = frontendUrl.replace(currentParam, currentValue);
                            counter_1++;
                        }
                        redirectUrl = frontendUrl;
                    }
                });
                return redirectUrl;
            };
            __decorate([
                Memoize()
            ], RedirectService.prototype, "isLocalhost", null);
            __decorate([
                Memoize()
            ], RedirectService.prototype, "isStage", null);
            __decorate([
                Memoize()
            ], RedirectService.prototype, "isProd", null);
            __decorate([
                Memoize()
            ], RedirectService.prototype, "getBaseURL", null);
            return RedirectService;
        }());
        services.RedirectService = RedirectService;
        angular
            .module('aq.services')
            .service('RedirectService', RedirectService);
    })(services = aq.services || (aq.services = {}));
})(aq || (aq = {}));
