namespace aq.user {
    export interface User extends restangular.IElement {
        id?: string;
        user?: string;
        existingUserId?: number;
        index?: number;
        email?: string;
        image?: string;
        firstName?: string;
        lastName?: string;
        fullName?: string;
        userName?: string;
        measurementSystem?: string;
        timeFormat?: string;
        profiles?: Profile[];
        timeZoneId?: string;
        authToken?: string;
        defaultMetric?: string;
        drillMode?: string;
        supportTeamRole?: string;
        phoneNumber?: string;
        language?: string;
        userCreated?: string;
        title?: string;
        accountId?: number;
        userId?: string;
        profileId?: number;
        persona?: 'ASSET_MANAGER' | 'PROPERTY_MANAGER' | 'BUILDING_ENGINEER';
        personnelId?: number;
        department?: string;
        role?: Role;
        imageUrl?: string;
        imageThumbnailUrl?: string;
        onboarded: boolean;
    }

    export interface Role {
        id?: number;
        name: string;
        type?: string;
    }

    export interface Profile {
        id?: string;
        buildings: aq.common.models.Building[];
        accountName?: string;
        hasFullVisibility?: boolean;
    }

    export interface EnergyNote {
        id?: string;
        text?: string;
        title?: string;
        start?: string;
        end?: string;
        taken?: string;
        image?: string;
        url?: string;
        building?: number;
        account?: number;
        createdBy?: number;
    }

    export class UserMentionResponse {
        user: number;
        mention: string;
    }
}
