namespace aq.ui {
    export class AqVerifyUsersStepCtrl {
        public users: aq.user.User[];
        private buildings: aq.common.models.Building[];
        private roles;
        private personas;
        private header: string;
        private isValidUserList: boolean;
        private personaRequired: boolean;

        /* @ngInject */
        constructor(
            protected $scope,
            protected $mdDialog: ng.material.IDialogService) {
        }
        public $onInit() {
            this.updateValidation();
        }

        public editUser(user: aq.user.User, isNew: boolean) {
            const emails = _.map(this.users, (u: aq.user.User) => u.email);
            _.pull(emails, user.email);
            this.$mdDialog.show({
                controller: 'EditUserModalCtrl as vm',
                templateUrl: 'app/components/verifySteps/modals/editUserModal.html',
                clickOutsideToClose: false,
                locals: {
                    editUser: user,
                    emails: _.compact(emails),
                    roles: this.roles,
                    personas: this.personas,
                    isNew,
                    isAllBuildings: undefined,
                    isGlobal: undefined,
                    buildings: [],
                    personaRequired: this.personaRequired,
                    enableDelete: false,
                    isReadonlyUserDetails: false
                }
            })
                .then((updatedUser) => {
                    const isDiff = user.firstName != updatedUser.firstName
                        || user.lastName != updatedUser.lastName
                        || user.email != updatedUser.email
                        || user.phoneNumber != updatedUser.phoneNumber
                        || user.persona != updatedUser.persona
                        || user.role != updatedUser.role;
                    if (isDiff) {
                        (user as any).isUpdate = true;
                        user.firstName = updatedUser.firstName;
                        user.lastName = updatedUser.lastName;
                        user.email = updatedUser.email;
                        user.phoneNumber = updatedUser.phoneNumber;
                        user.persona = updatedUser.persona;
                        user.role = updatedUser.role;

                        this.updatePersonnel(user);
                    }
                })
                .catch(() => {
                    if (isNew) {
                        this.removeAssociatedPersonnel(user);
                        _.remove(this.users, user);
                    }
                }).finally(() => {
                    this.updateValidation();
                });
        }

        public deleteUser(user, event): void {
            event.stopPropagation();
            this.$mdDialog.show({
                controller: 'NestedConfirmDialog as vm',
                templateUrl: 'app/admin/accounts/accounts/create/nestedConfirmDialog.html',
                clickOutsideToClose: false,
                locals: {
                    promptQuestion: 'Are you sure you want to delete this user?'
                }
            })
                .then(() => {
                    this.removeAssociatedPersonnel(user);
                    _.remove(this.users, user);
                }).finally(() => {
                    this.updateValidation();
                });
        }

        public updatePersonnel(person): void {
            _.each(this.buildings, (building: aq.common.models.Building) => {
                const personIndex = _.findIndex(building.personnel, (p) => p.index == person.index);
                if (personIndex >= 0) {
                    building.personnel[personIndex].user.user = person.id;
                    building.personnel[personIndex].user.firstName = person.firstName;
                    building.personnel[personIndex].user.lastName = person.lastName;
                    building.personnel[personIndex].user.email = person.email;
                    building.personnel[personIndex].user.phoneNumber = person.phoneNumber;
                    building.personnel[personIndex].user.persona = person.persona;
                }
            });
        }

        public removeAssociatedPersonnel(person): void {
            _.each(this.buildings, (building: aq.common.models.Building) => {
                _.remove(building.personnel, (p) => p.index == person.index);
            });
        }

        public removeUser(parentIndex: number, index: number): void {
            this.buildings[parentIndex].personnel.splice(index, 1);
        }

        public isMissingData(user: aq.user.User) {
            if (this.personaRequired) {
                return user.email == null
                    || user.persona == null
                    || user.role == null;
            }
            return user.email == null
                || user.role == null;
        }

        public createUser() {
            let index = 1;
            if (this.users.length > 0) {
                index = this.users[this.users.length - 1].index + 1;
            }
            const user = <aq.user.User>{};
            user.index = index;

            this.editUser(user, true);
            this.users.push(user);
        }


        private updateValidation() {
            this.isValidUserList = this.validateUsers(this.users);
            this.$scope.$emit('isValidUserListUpdated', this.isValidUserList);
        }

        private validateUsers(users: aq.user.User[]): boolean {
            for (const user of users) {
                if (this.isMissingData(user)) {
                    return false;
                }
            }
            return true;
        }

        private getPersona(persona): string {
            return persona ? persona.replace('_', ' ') : '';
        }
    }
    angular.module('aq.ui').component('aqVerifyUsersStep', {
        controller: AqVerifyUsersStepCtrl,
        controllerAs: 'vm',
        templateUrl: 'app/components/verifySteps/aqVerifyUsersStep.html',
        bindings: {
            users: '<',
            buildings: '<',
            roles: '<',
            personas: '<',
            header: '<',
            isValidUserList: '<',
            personaRequired: '<'
        }
    });
}
