namespace aq.deviceManagement {
    import OutgoingRedirect = aq.services.OutgoingRedirect;
    import Device = aq.common.models.Device;

    export class DeviceDetail {
        static HTTP_CODE_CONSTRAINT_VIOLATION = 422;
        private crudCategories: string[];
        private dataStatuses: aq.common.models.DataStatus[];
        private backButton: aq.components.BackButtonData;
        private currentNavItem: string;
        private entityActions: aq.common.models.EntityMenuAction[];
        private changes: number;
        private oldMultiplier;
        private nonFormDataChangeIndicator;
        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private user,
            private tenants,
            private floors,
            private spaces,
            private buildings,
            private manualReadings,
            private device: aq.common.models.DeviceElement,
            private points: aq.common.models.Point[],
            private DataStore: aq.common.DataStore,
            private Messages,
            private $state: ng.ui.IStateService,
            private $stateParams: ng.ui.IStateParamsService,
            private measures,
            private DeviceService: aq.services.DeviceService,
            private building,
            private $interval: ng.ITimeoutService,
            private $location: ng.ILocationService,
            private RestangularV3: restangular.IService,
            private UserService: aq.services.UserService,
            private RedirectService: aq.services.RedirectService,
        ) {
            this.currentNavItem = ($state.current.url as string).substring(1);
            this.device = this.refreshDevice(this.device);
            $scope.$on('DEVICE_UPDATED', (event, changesObj) => {
                if (changesObj && changesObj.currentNavItem) {
                    this.currentNavItem = changesObj.currentNavItem;
                }
                if (changesObj && changesObj.images) {
                    this.device.images = changesObj.images;
                }
                this.changes = this.changes ? this.changes++ : 0;
                this.$scope.$broadcast('DEVICE_UPDATED_CHILD');
            });
            $scope.$on('NON_FORM_DATA_UPDATED', (event, changesObj) => {
                if (changesObj && changesObj.nonFormDataChangeIndicator) {
                    this.nonFormDataChangeIndicator = changesObj.nonFormDataChangeIndicator;
                }

                this.changes = this.changes ? this.changes++ : 0;
                this.$scope.$broadcast('DEVICE_UPDATED_CHILD');
            });
            this.entityActions = (this.device.deviceCategory == 'METER' || this.device.deviceCategory == 'NETWORKING' ||
                this.device.deviceCategory == 'SENSOR') ? [
                { label: 'Add Link', cb: this.addLinkCallback() }
            ] : null;
            if (UserService.isAdmin()) {
                if (this.entityActions === null) {
                    this.entityActions = [];
                }
                this.entityActions.push({ label: 'View Audit Log', cb: this.viewAuditLog(), icon: 'library_books' });
            }
            this.dataStatuses = $stateParams.dataStatuses;
            this.backButton = $stateParams.backButton ? $stateParams.backButton : { state: 'aq.deviceManagement.building' };
            this.oldMultiplier = this.getDeviceMultiplier(this.device);
        }

        public save(event, device) {
            const newMultiplier = this.getDeviceMultiplier(this.device);
            if (this.oldMultiplier != newMultiplier) {
                this.oldMultiplier = newMultiplier;
                this.DeviceService.updateMultiplier(this.device.collectorId, newMultiplier, event).then(() => {
                    this.DeviceService.save(this.device, false)
                        .then(
                            (updated) => {
                                this.Messages.info('Device Saved and pulse multiplier updated.');
                                this.device.tenantLinks = updated.tenantLinks;
                                this.device = this.refreshDevice(this.device);
                                this.$scope.$emit('DEVICE_UPDATED', {
                                    $event: {
                                        device: this.device
                                    }
                                });
                            },
                            (err) => {
                                if (err.status === DeviceDetail.HTTP_CODE_CONSTRAINT_VIOLATION) {
                                    this.Messages.error(err.data);
                                } else {
                                    this.Messages.error('Error saving Device');
                                }
                                this.refreshDevice(this.device);
                            }
                        );
                });
            } else {
                this.DeviceService.save(this.device)
                    .then(
                        (updated) => {
                            this.device.tenantLinks = updated.tenantLinks;
                            this.device = this.refreshDevice(this.device);
                            this.$scope.$emit('DEVICE_UPDATED', {
                                $event: {
                                    device: this.device
                                }
                            });
                        },
                        (err) => {
                            if (err.status === DeviceDetail.HTTP_CODE_CONSTRAINT_VIOLATION) {
                                this.Messages.error(err.data);
                            } else {
                                this.Messages.error('Error saving Device');
                            }
                            this.refreshDevice(this.device);
                        }
                    );
            }
        }

        public delete(event, device: aq.common.models.DeviceElement) {
            this.DeviceService.delete(event, device);
        }

        private getDeviceMultiplier(device: aq.common.models.DeviceElement) {
            if (device.fields && device.fields['multiplier']) {
                return this.device.fields['multiplier'].value;
            }
        }

        private addLinkCallback() {
            return () => this.DeviceService.addLink(this.device).then(() => {
                this.$scope.$emit('DEVICE_UPDATED');
            });
        }

        private viewAuditLog() {
            return () => this.DeviceService.viewAuditLogs(this.device);
        }

        private viewDeviceConfigurationFile() {
            return () => {
                const building = this.building.id;
                const profile = this.user.currentProfile.id;
                const account = this.user.currentProfile.account;
                const destination = `accounts/${account}/buildings/${building}/networking/configuration/${this.device.id}`;
                const redirectParams: OutgoingRedirect = { destination, building, account, profile };
                this.RedirectService.redirect(redirectParams);
            };
        }

        private getIcon(device: aq.common.models.DeviceElement): string {
            return this.DeviceService.getIcon(device);
        }

        private refreshDevice(device: aq.common.models.DeviceElement): aq.common.models.DeviceElement {
            return this.DeviceService.refreshViewFields(device);
        }

        private redirectToPointsInNewFrontend() {
            const building = this.building.id;
            const profile = this.user.currentProfile.id;
            const account = this.user.currentProfile.account;
            const destination = `accounts/${account}/buildings/${building}/networking/points/${this.device.id}`;
            const redirectParams: OutgoingRedirect = { destination, building, account, profile };
            return () => this.RedirectService.redirect(redirectParams);
        }

        private isEcom(device: Device): boolean {
            return device != null &&
                device.deviceClass != null &&
                device.deviceClass.make === 'Aquicore' &&
                device.deviceClass.series === 'ECOM';
        }
    }

    angular
        .module('deviceManagement')
        .controller('DeviceDetail', DeviceDetail);
}
