namespace aq.ui {
    export class EditBuildingPersonnelModalCtrl extends aq.common.Controllers.ModalCtrl {
        private building: aq.common.models.Building;
        private users: aq.user.User[];
        private userItems: SelectionItem[];
        private selectedUsers: SelectionItem[];
        private searchText;
        /* ngInject */
        constructor(
            private editBuildingPersonnel: aq.common.models.Building,
            private editBuildingPersonnelUsers: aq.user.User[],
            protected $mdDialog: ng.material.IDialogService
        ) {
            super(null, $mdDialog);
            this.building = angular.copy(this.editBuildingPersonnel);
            this.users = angular.copy(this.editBuildingPersonnelUsers);
            this.initUserItems();

        }

        public queryUsers(searchText: string): SelectionItem[] {
            if (!searchText) {
                searchText = '';
            }
            searchText = searchText.toLowerCase();
            return _.filter(this.userItems, (usr: SelectionItem) => {
                return usr.name && usr.name.toLowerCase().indexOf(searchText) > -1;
            });
        }

        public initUserItems() {
            this.userItems = _.map(this.users, (u: aq.user.User) => {
                const item: SelectionItem = {
                    id: u.index !== undefined ? u.index : parseInt(u.id),
                    name: u.firstName + ' ' + u.lastName,
                    personnelId: u.personnelId
                };
                return item;
            });
            this.selectedUsers = _.filter(this.userItems, (u) => _.some(this.building.personnel, (p) => {
                if (!p.user) {
                    return false;
                }
                if (p.user.index !== undefined  && p.user.index == u.id) {
                    u.personnelId = p.id;
                    return true;
                } else if (p.user.user && p.user.user == u.id) {
                    u.personnelId = p.user.id;
                    return true;
                } else if (p.user.id == u.id) {
                    u.personnelId = p.user.id;
                    return true;
                } else {
                    return false;
                }
            }));
        }

        public apply() {
            this.building.personnel = _.filter(this.users, (u) => _.some(this.selectedUsers, (su) => {
                if (u.index !== undefined && u.index == su.id) {
                    u.personnelId = su.personnelId;
                    return true;
                } else if (u.id == su.id) {
                    u.personnelId = su.personnelId;
                    return true;
                } else {
                    return false;
                }
            })).map((user) => {
                return <aq.common.models.Employee>{
                    id: user.personnelId,
                    user
                };
            });
            this.hide(this.building);
        }
    }

    export interface SelectionItem {
        id: number;
        name: string;
        personnelId: number;
    }

    angular.module('aq.ui').controller('EditBuildingPersonnelModalCtrl', EditBuildingPersonnelModalCtrl);
}
