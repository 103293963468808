'use strict';

angular
    .module('registration', ['ui.router', 'restangular', 'aq.services.registration'])
    .config(($stateProvider, RestangularProvider) => {
        $stateProvider
            .state('aq.registration', {
                abstract: true,
                url: '/register',
                templateUrl: 'app/registration/main.html',
                data: {
                    appName: ''
                }
            })
            .state('aq.registration.new', {
                url: '/new',
                templateUrl: 'app/registration/register/register.html',
                controller: 'RegisterCtrl as vm'
            })
            .state('aq.registration.welcome', {
                url: '/welcome',
                templateUrl: 'app/registration/welcome/welcome.html',
                controller: 'WelcomeCtrl',
                resolve: {
                    enrollmentId: function ($stateParams) {
                        return $stateParams.enrollmentId;
                    }
                },
                params: {
                    enrollmentId: null
                }
            })
            .state('aq.registration.invalid', {
                templateUrl: 'app/registration/invalid/invalid.html',
                controller: 'InvalidCtrl'
            });

        RestangularProvider.setDefaultHttpFields({ cache: false });
        RestangularProvider.setBaseUrl('/api/v1');
    })
    .directive('aqUsernameUnique', ($http, $q, RegistrationService, $timeout) => {
        return {
            require: 'ngModel',
            link: (scope, element, attrs, ngModel: ng.INgModelController) => {
                let pendingAsyncValidation;
                ngModel.$asyncValidators.aqUsernameUnique = (modelValue, viewValue) => {
                    if (pendingAsyncValidation) $timeout.cancel(pendingAsyncValidation);
                    var config = scope.$eval(attrs.aqUsernameUnique);
                    var delay = (config && config.delay) ? config.delay : 500;
                    pendingAsyncValidation = $timeout(() => {
                        pendingAsyncValidation = null;
                        return RegistrationService.validateUsername(viewValue)
                            .then(() => true)
                            .catch((err) => {
                                // CONFLICT status code
                                if (err.status === 409) {
                                    return $q.reject();
                                }
                                // NOTE: Returning true on all other error statuses might lead to dupe usernames.
                                //       Also other potential downstream issues since we hit another v3 endpoint when we submit.
                                //       No other options here though since any promise rejection will make 'existing user' message show.
                                return true;
                            });
                    }, delay);
                    return pendingAsyncValidation;
                };
            }
        };
    });
