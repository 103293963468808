angular.module('aq.filters.units')
.filter('withLabel', function() {
    return function(converted, unit, stripIntensity) {
        if(!unit) {
            return;
        }
        if (unit.unit === '$' || unit.unit === '¢') {
            // '-' should be before dollar sign
            if (converted < 0) {
                return '-' + unit.unit + (-1*converted);
            }
            return unit.unit + converted;
        } else if (unit.unit === '$/SF') {
            return '$' + converted + '/SF';
        } else if (unit.unit === 'kWhCalculated') {
            return converted + ' ' +  'kWh';
        } else if (unit.unit === 'WhCalculated') {
            return converted + ' ' +  'Wh';
        } else {
            return converted + ' ' + (stripIntensity ? unit.unit.replace('/SF', '') : unit.unit);
        }
    }
})