angular.module('user', ['aq.ui', 'aq.services.restangular', 'aq.ui.error', 'aq.filters.common', 'aq.services.user'])
    .config((RestangularProvider, $stateProvider) => {
        $stateProvider
            .state('aq.user', {
                url: '/users',
                templateUrl: 'app/user/user.html',
                abstract: true,
                data: {
                    appName: 'User Profile'
                }
            })
            .state('aq.user.profile', {
                url: '/profile',
                templateUrl: 'app/user/profile/userDetail.html',
                controller: 'UserDetailCtrl as vm',
                resolve: {
                    currentUser(UserService, waitForAuthToken) {
                        return UserService.getUser({ inflate: 'currentProfile,currentProfile.account,currentProfile.apps,buildingGroups' });
                    },
                    units(waitForAuthToken, DataStore: aq.common.DataStore, RestangularV3: restangular.IElement) {
                        return RestangularV3.one('users').one('units').get().then((result) => {
                            return result.originalElement;
                        });
                    },
                    timeZones(waitForAuthToken, DataStore: aq.common.DataStore, RestangularV3: restangular.IElement) {
                        return RestangularV3.one('users').one('time-zones').get();
                    },
                    drillModes(waitForAuthToken, DataStore: aq.common.DataStore, RestangularV3: restangular.IElement) {
                        return RestangularV3.one('users').one('drill-modes').get().then((result) => {
                            return result.originalElement;
                        });
                    },
                    measurementSystems(waitForAuthToken, DataStore: aq.common.DataStore, RestangularV3: restangular.IElement) {
                        return RestangularV3.one('users').one('measurement-systems').get().then((result) => {
                            return result.originalElement;
                        });
                    },
                    supportTeamRoles(waitForAuthToken, DataStore: aq.common.DataStore, RestangularV3: restangular.IElement) {
                        return RestangularV3.one('users').one('support-team-roles').get().then((result) => {
                            return result.originalElement;
                        });
                    },
                    user(waitForAuthToken, currentUser: aq.common.models.User, DataStore: aq.common.DataStore, RestangularV3: restangular.IService) {
                        return DataStore.get(RestangularV3.one('users', currentUser.id));
                    }
                }
            })
            .state('aq.user.recover', {
                url: '/recover',
                templateUrl: 'app/user/password/recover/recoverPassword.html',
                controller: 'RecoverPasswordCtrl'
            })
            .state('aq.user.invalidToken', {
                url: '/invalid',
                templateUrl: 'app/user/password/invalidToken/invalidToken.html'
            })
            .state('aq.user.reset', {
                url: '/reset/:token',
                resolve: {
                    validateToken($stateParams, $state, Restangular, waitForAuthToken) {
                        return Restangular.all('users').get('validateToken', { token: $stateParams.token })
                            .then((valid) => {
                                if (valid === 'false') $state.go('aq.user.invalidToken');
                            }, () => { $state.go('aq.user.invalidToken'); });
                    }
                },
                templateUrl: 'app/user/password/reset/resetPassword.html',
                controller: 'ResetPasswordCtrl'
            });
        RestangularProvider.setBaseUrl('/api/v1');
    })
    .run((UserService, Auth, $rootScope) => {
        UserService.init();
    });
