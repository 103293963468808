namespace aq.dashboard.widgets {
    export class GraphEditService {
        private trendSuffix = ' (trend)';
        private predictedSuffix = ' (expected)';
        /* ngInject */
        constructor(
            protected DashboardOptionsService: DashboardOptionsService,
            protected $translate
        ) {
        }
        public getMeasuresForDrillin(cfg: BaseGraphConfigModel, drillin) {
            // building or tenant
            if (drillin === '' || drillin === 'tenant') {
                return cfg.options.allMeasures;
            }
            return cfg.options.measures;
        }
        public onSelectedBuildingChange(cfg: BaseGraphConfigModel, data: string) {
            cfg.buildingId = data;
            _.each(cfg.series, (s: GraphSeries) => {
                if (s.drillin) {
                    s.drilldown = null;
                }
            });
        }
        public onNewMeasure(cfg: BaseGraphConfigModel) {
            if (cfg.view.newMeasure === 'NONE') {
                return;
            }
            const yAxis = this.findOrCreateYAxisForMeasure(cfg.view.newMeasure, cfg);

            const newMeasureSeries = {
                measure: cfg.view.newMeasure,
                title: this.getMeasureLabel(cfg.view.newMeasure),
                type: this.getGraphTypeForMeasure(cfg.view.newMeasure),
                color: this.getFirstAvailableColor(cfg),
                colorOptions: angular.copy(cfg.options.colors),
                drillin: '',
                drillinView: 'selected building',
                yAxis
            };
            cfg.series.push(newMeasureSeries);
            if (cfg.view.isShowTrend) {
                if (!_.find(cfg.series, (s) => s.trend && s.measure == newMeasureSeries.measure)) {
                    this.addTrendForMeasure(newMeasureSeries, cfg);
                }
            }
            this.generateDefaultChartTitle(cfg);
            cfg.view.newMeasure = 'NONE';
            // TODO: check if add extra kW!
        }
        public onMeasureChange(item: GraphSeries, cfg: BaseGraphConfigModel) {
            if (item.measure !== 'KW') {
                item.expected = false;
            }
            if (item.measure === 'TEMPERATURE') {
                item.drillin = '';
                item.drilldown = null;
            }
            const drilldownName = item.drilldown ? item.drilldown.name : '';
            item.title = this.getMeasureLabel(item.measure, drilldownName);
            item.type = this.getGraphTypeForMeasure(item.measure);
            const previousAxis = cfg.yAxis[item.yAxis];
            item.yAxis = this.findOrCreateYAxisForMeasure(item.measure, cfg);
            if (cfg.view.isShowTrend) {
                const orphanTrendSeries = _.find(cfg.series, (s) => {
                    return s.trend
                        && !_.find(cfg.series, (main) => !main.trend && !main.expected && main.measure == s.measure);
                });
                if (!_.find(cfg.series, (s) => s.trend && s.measure == item.measure)) {
                    if (orphanTrendSeries) {
                        orphanTrendSeries.measure = item.measure;
                        orphanTrendSeries.title = item.title + this.trendSuffix;
                        orphanTrendSeries.type = item.type;
                        orphanTrendSeries.yAxis = item.yAxis;
                    } else {
                        this.addTrendForMeasure(item, cfg);
                    }
                }
            }
            this.removeAxisIfEmpty(previousAxis.measure, cfg);
            this.generateDefaultChartTitle(cfg);
        }
        public onColorChange(item: GraphSeries, cfg: BaseGraphConfigModel) {
            if (cfg.view.isShowTrend) {
                const trendSeries = _.find(cfg.series, (s) => s.trend && s.measure == item.measure);
                if (trendSeries) {
                    trendSeries.color = this.getLighterColor(item.color);
                }
            }
        }
        public onTitleChange(item: GraphSeries, cfg: BaseGraphConfigModel) {
            if (cfg.view.isShowTrend) {
                const trendSeries = _.find(cfg.series, (s) => s.trend && s.measure === item.measure);
                if (trendSeries) {
                    trendSeries.title = item.title + this.trendSuffix;
                }
            }
            if (item.measure === 'KW' && cfg.view.isPredictedEnergy) {
                const expectedSeries = _.find(cfg.series, (s) => s.expected && s.measure === item.measure);
                if (expectedSeries) {
                    expectedSeries.title = item.title + this.predictedSuffix;
                }
            }
        }
        public onGraphChange(item: GraphSeries, cfg: BaseGraphConfigModel) {
            if (cfg.view.isShowTrend) {
                const trendSeries = _.find(cfg.series, (s) => s.trend && s.measure === item.measure);
                if (trendSeries) {
                    trendSeries.type = item.type;
                }
            }
        }
        public onDrillInChange(item: GraphSeries, cfg: BaseGraphConfigModel) {
            if (item.drillinView !== 'selected building') {
                item.drillin = item.drillinView;
            } else {
                item.drillin = '';
            }
            if (item.drillin) {
                if (item.measure === 'KW' && cfg.view.isPredictedEnergy) {
                    cfg.view.isPredictedEnergy = false;
                    this.handleIsPredictEnergyChange(false, cfg);
                }
            }
            item.drilldown = null;
            const drilldownName = item.drilldown ? item.drilldown.name : '';
            item.title = this.getMeasureLabel(item.measure, drilldownName);
            if (cfg.view.isShowTrend) {
                const trendSeries = _.find(cfg.series, (s) => s.trend && s.measure === item.measure);
                if (trendSeries) {
                    trendSeries.title = item.title + this.trendSuffix;
                    trendSeries.drillin = item.drillin;
                    trendSeries.drilldown = item.drilldown;
                }
            }
            this.generateDefaultChartTitle(cfg);
        }
        public onQueryableChange(item: GraphSeries, cfg: BaseGraphConfigModel) {
            const drilldownName = item.drilldown ? item.drilldown.name : '';
            item.title = this.getMeasureLabel(item.measure, drilldownName);
            if (cfg.view.isShowTrend) {
                const trendSeries = _.find(cfg.series, (s) => s.trend && s.measure === item.measure);
                if (trendSeries) {
                    trendSeries.title = item.title + this.trendSuffix;
                    trendSeries.drilldown = item.drilldown;
                }
            }
            this.generateDefaultChartTitle(cfg);
        }
        public getCurrentTrendPeriod(cfg: BaseGraphConfigModel) {
            return TrendPeriods.getTrendPeriodForDatePeriod(cfg.preset);
        }
        public onShowTrendChange(isShowTrend: boolean, cfg: BaseGraphConfigModel) {
            if (isShowTrend) {
                const mainMeasureSeries = _.filter(cfg.series, (s) => !s.trend && !s.expected);
                _.each(mainMeasureSeries, (main) => {
                    if (!_.find(cfg.series, (s) => s.trend && s.measure === main.measure)) {
                        this.addTrendForMeasure(main, cfg);
                    }
                });
            } else {
                _.remove(cfg.series, (s) => s.trend);
            }
        }
        public onTimePeriodChange(timePeriod: string, cfg: BaseGraphConfigModel) {
            _.each(cfg.series, (s) => {
                if (s.trend) {
                    if (cfg.customTrend && cfg.isFixedTrendDate) {
                        s.customTrend.start = moment(cfg.customTrend);
                    }
                    s.trendPeriod = TrendPeriods.getTrendPeriodForDatePeriod(timePeriod);
                }
            });
            if (cfg.preset === 'trailing year') {
                cfg.view.isPredictedEnergy = false;
            }
            this.generateDefaultChartTitle(cfg);
        }
        public onFixedTrendChange(config: BaseGraphConfigModel) {
            if (!config.isFixedTrendDate) {
                config.series.forEach(s => {
                    if (s.trend) {
                        delete s.customTrend;
                    }
                });
            } else if (config.customTrend) {
                config.series.forEach(s => {
                    if (s.trend) {
                        s.customTrend = { start: moment(config.customTrend) };
                    }
                });
            }
        }

        /**
            Custom trend date range setting
        */
        public onTrendPeriodChange(timePeriod: string, trendStart: string, cfg: BaseGraphConfigModel) {
            cfg.series.forEach((s) => {
                if (s.trend) {
                    if (cfg.isFixedTrendDate) {
                        if (!s.customTrend) {
                            s.customTrend = {};
                        }
                        s.customTrend.start = moment(trendStart);
                    }
                }
            });

            this.generateDefaultChartTitle(cfg);
        }

        public onShowWeatherChange(isShowWeather: boolean, cfg: BaseGraphConfigModel) {
            const weatherMeasure = 'F';
            if (isShowWeather) {
                let weatherSeries = _.find(cfg.series, (s) => s.measure === weatherMeasure && !s.trend);
                if (!weatherSeries) {
                    weatherSeries = {
                        measure: weatherMeasure,
                        title: this.getMeasureLabel(weatherMeasure),
                        type: this.getGraphTypeForMeasure(weatherMeasure),
                        color: cfg.options.presetColorWeather,
                        colorOptions: angular.copy(cfg.options.colors),
                        drillin: '',
                        drillinView: 'selected building',
                        yAxis: this.findOrCreateYAxisForMeasure(weatherMeasure, cfg)
                    };
                    cfg.series.push(weatherSeries);
                }
                if (cfg.view.isShowTrend) {
                    this.addTrendForMeasure(weatherSeries, cfg);
                }
            } else {
                _.remove(cfg.series, (s) => s.measure === weatherMeasure);
                if (cfg.view.isShowTrend) {
                    _.remove(cfg.series, (s) => s.measure === weatherMeasure && s.trend);
                }
            }
        }
        public onShowChartTitleChange(cfg: BaseGraphConfigModel) {
            this.generateDefaultChartTitle(cfg);
        }
        public onIsCustomChartTitleChange(cfg: BaseGraphConfigModel) {
            this.generateDefaultChartTitle(cfg);
        }
        public onDelete(item: GraphSeries, cfg: BaseGraphConfigModel) {
            const { measure } = item;
            if (cfg.view.isShowTrend) {
                _.remove(cfg.series, (s) => s.measure === measure && s.trend);
            }
            if (measure === 'KW' && cfg.view.isPredictedEnergy) {
                cfg.view.isPredictedEnergy = false;
                this.handleIsPredictEnergyChange(false, cfg);
            }
            _.remove(cfg.series, (s) => s === item);
            this.removeAxisIfEmpty(measure, cfg);
            this.generateDefaultChartTitle(cfg);
        }
        public toggleItemDetails(item, cfg) {
            if (cfg.view.expandedItem === item) {
                cfg.view.expandedItem = null;
            } else {
                cfg.view.expandedItem = item;
            }
        }
        public isCustomColorSelected(item: GraphSeries) {
            const selectedColor = _.find(item.colorOptions, (option) => option.hex === item.color);
            if (selectedColor && selectedColor.value !== 'custom') {
                return false;
            }
            return true;
        }
        public updateCustomColor(item: GraphSeries) {
            const isOkHexColor = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(item.color);
            if (!isOkHexColor) {
                return;
            }
            if (!_.find(item.colorOptions, (option) => option.hex === item.color && option.value !== 'custom')) {
                const otherOption = _.find(item.colorOptions, (option) => option.value === 'custom');
                if (otherOption) {
                    otherOption.hex = item.color;
                }
            }
        }
        public isShowPredictedEnergy(cfg: BaseGraphConfigModel) {
            const seriesKwArray = _.filter(cfg.series, (s) => s.measure === 'KW' && !s.expected && !s.trend);
            const isExactlyOneKW = seriesKwArray.length === 1;
            if (!isExactlyOneKW) {
                return false;
            }
            const seriesKW = _.first(seriesKwArray);
            const isDataForEntireBuilding = !seriesKW.drillin;
            if (!isDataForEntireBuilding) {
                return false;
            }
            return true;
        }
        public onPredictedEnergyChange(isPredictedEnergy: boolean, cfg: BaseGraphConfigModel) {
            this.handleIsPredictEnergyChange(isPredictedEnergy, cfg);
        }
        public findOrCreateYAxisForMeasure(measure: string, cfg: BaseGraphConfigModel) {
            const index = _.findIndex(cfg.yAxis, (axis) => axis.measure === measure);
            if (index !== -1) {
                return index;
            }
            const text = this.getMeasureUnit(measure);
            cfg.yAxis.push({
                measure,
                title: { text },
                opposite: (measure === 'F')
            });
            if (cfg.view && !cfg.view.selectedYAxis) {
                cfg.view.selectedYAxis = _.first(cfg.yAxis);
            }
            return cfg.yAxis.length - 1;
        }
        public getDrilldownOptions(measure: string, drillin: string, searchText: string, buildingId: string, cfg: BaseGraphConfigModel) {
            const seriesOptions = cfg.options.series[drillin][buildingId];
            const unit = this.DashboardOptionsService.getUnitByEnumName(measure);
            let drilldownOptions = seriesOptions || [];
            if (!searchText) {
                searchText = '';
            }
            drilldownOptions = _.filter(drilldownOptions, (drilldown: any) => {
                return _.includes(drilldown.metrics, unit.serviceType)
                    && _.includes(drilldown.name.toLowerCase(), searchText.toLowerCase());
            });
            return drilldownOptions;
        }
        public getMeasureLabel(measure: string, queryable?: string) {
            const unit = this.DashboardOptionsService.getUnitByEnumName(measure);
            if (!unit) {
                return '';
            }
            let label = unit.serviceType ? this.$translate.instant(`measures.${unit.serviceType}`) : this.$translate.instant(`measures.${unit.apiUnit}`);
            label = textural(label).format('capitalizehuman');
            label = `${label} (${unit.unit})`;
            if (queryable) {
                label = `${queryable} - ${label}`;
            }
            return label;
        }
        public buildColorOptionsForSeries(ser: GraphSeries, cfg: BaseGraphConfigModel) {
            const options = angular.copy(cfg.options.colors);
            if (!_.some(options, (option) => option.hex === ser.color)) {
                const otherOption = _.find(options, (option) => option.value === 'custom');
                if (otherOption) {
                    otherOption.hex = ser.color;
                }
            }
            return options;
        }
        public getLighterColor(hexColor: string) {
            if (!hexColor) {
                return '';
            }
            const rgb = hexColor.replace('#', '');
            if (rgb.length != 3 && rgb.length != 6) {
                return;
            }
            const size = rgb.length / 3;
            const max = size == 1 ? 15 : 255;
            let lighterRgb = '#';
            for (let i = 0; i < 3; i++) {
                const component = parseInt(rgb.substr(i * size, size), 16);
                if (isNaN(component)) {
                    return;
                }
                lighterRgb += (Math.floor((max + component) / 2)).toString(16);
            }

            return lighterRgb;
        }
        private getGraphTypeForMeasure(measure: string) {
            const columnMeasures = [
                'KWH', 'CURRENCY_ELECTRICITY',
                'WATER_GAL', 'CURRENCY_WATER',
                'CCF', 'CURRENCY_GAS',
                'MLB', 'CURRENCY_STEAM_MASS',
                'KBTU', 'BTU', 'CURRENCY_KBTU'
            ];
            if (_.find(columnMeasures, (item) => item == measure)) {
                return 'column';
            }
            return 'line';
        }
        private getMeasureUnit(measure: string) {
            const unit = this.DashboardOptionsService.getUnitByEnumName(measure);
            if (unit) {
                return unit.unit;
            }
            return '';
        }
        private getFirstAvailableColor(cfg: BaseGraphConfigModel) {
            const { colors } = cfg.options;
            const { series } = cfg;
            let availableColor = _.find(colors, (c) => !_.some(series, (s) => s.color == c.hex));
            if (!availableColor) {
                availableColor = _.first(colors);
            }
            return availableColor.hex;
        }
        private addTrendForMeasure(measureSeries: GraphSeries, cfg: BaseGraphConfigModel) {
            const trendSeries = angular.copy(measureSeries);
            trendSeries.trend = true;
            trendSeries.trendPeriod = TrendPeriods.getTrendPeriodForDatePeriod(cfg.preset);
            trendSeries.title += this.trendSuffix;
            trendSeries.color = this.getLighterColor(measureSeries.color);
            cfg.series.push(trendSeries);
        }
        private handleIsPredictEnergyChange(isPredictedEnergy: boolean, cfg: BaseGraphConfigModel) {
            const mainSeriesKW = _.find(cfg.series, (s) => s.measure == 'KW' && !s.expected);
            if (!mainSeriesKW) {
                return;
            }
            let expectedSeriesKW = _.find(cfg.series, (s) => s.measure == 'KW' && s.expected);
            if (isPredictedEnergy) {
                if (!expectedSeriesKW) {
                    expectedSeriesKW = angular.copy(mainSeriesKW);
                    expectedSeriesKW.expected = true;
                    expectedSeriesKW.color = cfg.options.presetColorExpectedEnergy;
                    cfg.series.push(expectedSeriesKW);
                }
                expectedSeriesKW.title += this.predictedSuffix;
            } else if (expectedSeriesKW) {
                _.remove(cfg.series, (s) => s.measure == 'KW' && s.expected);
            }
        }
        private removeAxisIfEmpty(measure: string, cfg: BaseGraphConfigModel) {
            if (!_.some(cfg.series, (s) => s.measure == measure)) {
                const index = _.findIndex(cfg.yAxis, (axis) => axis.measure == measure);
                if (index == -1) {
                    return;
                }
                _.each(cfg.series, (s) => {
                    if (s.yAxis >= index) {
                        s.yAxis--;
                    }
                });
                cfg.yAxis.splice(index, 1);
                if (cfg.view.selectedYAxis.measure == measure && cfg.yAxis.length > 0) {
                    cfg.view.selectedYAxis = cfg.yAxis[0];
                } else if (cfg.yAxis.length == 0) {
                    cfg.view.selectedYAxis = null;
                }
            }
        }
        private generateDefaultChartTitle(cfg: BaseGraphConfigModel) {
            if (cfg.isCustomTitle) {
                return;
            }
            const mainMeasures: GraphSeries = _.filter(cfg.series, (s: GraphSeries) => !s.trend && !s.expected && s.measure != 'F');
            const queryables = _.uniq(_.map(mainMeasures, (m: GraphSeries) => {
                if (m.drillin && m.drilldown) {
                    return m.drilldown.name;
                }
                return '';
            }));
            const queryableName = queryables.length == 1 ? _.first(queryables) : '';
            const units = _.map(mainMeasures, (m: GraphSeries) => this.getMeasureUnit(m.measure));
            const unitNames = _.filter(units, (u) => u).join(', ');
            const timePeriod = textural(cfg.preset).format('capitalizehuman');
            let title = `${unitNames} - ${this.$translate.instant(`timeperiod_case3.${timePeriod}`)}`;
            if (queryableName) {
                title = `${queryableName} - ${title}`;
            }
            cfg.titleText = title;
        }
    }
    angular.module('aq.dashboard.widgets').service('GraphEditService', GraphEditService);
}
