angular.module('aq-main').run(function($httpBackend) {
    const regexp = /^\/api\/v1\/accounts\/.*\/buildings\/.*\/utilityBillPeriods/;
    let idCounter = 1;
    const utilityBillingPeriods = [{
        id: idCounter++,
        startDate: new Date(),
        endDate: new Date(),
        totalCharges: 500,
        invoiceDate: new Date(),
        service: 17590314,
        charges: [{
            id: idCounter++,
            demand: 1000,
            usage: 900,
            charge: 500,
            meter: 1
        }]
    }, {
        id: idCounter++,
        startDate: new Date(),
        endDate: new Date(),
        totalCharges: 500,
        invoiceDate: new Date(),
        service: 17590314, // FIX mocks because right now we are overriding other mocks
        charges: [{
            id: idCounter++,
            demand: 1000,
            usage: 900,
            charge: 500,
            meter: 1
        }]
    }];

    MockContext('utilityBillPeriods', () => {
        $httpBackend.whenGET(regexp).respond((method, url, data) => {
            return [200, utilityBillingPeriods, {}];
        });

        $httpBackend.whenPUT(regexp).respond((method, url, data) => {
            data = JSON.parse(data);
            if (data.id) {
                _.remove(utilityBillingPeriods, {id: data.id});
            }
            utilityBillingPeriods.push(data);
            return [200, data, {}];
        });

        $httpBackend.whenDELETE(regexp).respond((method, url, data) => {
            let tokens = url.split('/');
            let id = tokens[tokens.length - 1];
            _.remove(utilityBillingPeriods, {id});
            return [200, {}, {}];
        });

        $httpBackend.whenPOST(/^\/api\/v1\/accounts\/.*\/buildings\/.*\/utilityBillPeriods\/upload/).respond((method, url, data) => {
            return [200, {}, {}];
        });
    });
});