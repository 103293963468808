namespace aq.admin.meters.devices {
    export interface StatusCount {
        description: string;
        count: number;
    }
    export interface DeviceClassViewItem {
        id: number;
        name: string;
        total: number;
        statuses: StatusCount[];
        isFavorite: boolean;
        isLoading: boolean;
    }
    export class AdminDevicesCtrl {
        public searchText: string;
        public selectedDeviceClasses: DeviceClassModel[];
        public deviceClassViewItems: DeviceClassViewItem[];
        /* @ngInject */
        constructor(
            private deviceClasses: DeviceClassModel[],
            private AppStorageService: aq.services.AppStorageService,
            private RestangularV3
        ) {
            this.init();
            this.loadAllViewItems();
        }
        public queryDeviceClasses(searchText) {
            searchText = searchText.toLowerCase();
            return _.chain(this.deviceClasses)
                .filter((item: DeviceClassModel) => {
                    return _.every(this.selectedDeviceClasses, (selectedItem) => selectedItem.id != item.id)
                        && (item.vendor.toLowerCase().indexOf(searchText) > -1
                            || item.series.toLowerCase().indexOf(searchText) > -1
                            || item.model.toLowerCase().indexOf(searchText) > -1);
                })
                .sortBy('series')
                .value();
        }
        public onAddDeviceClass(deviceClass) {
            const newViewItem = this.getDeviceClassViewItem(deviceClass);
            this.deviceClassViewItems.push(newViewItem);
            this.deviceClassViewItems = _.sortBy(this.deviceClassViewItems, 'name');
            const ids = [newViewItem.id];
            this.loadViewItems(ids);
        }
        public onRemoveDeviceClass(deviceClass) {
            _.remove(this.deviceClassViewItems, (item) => item.id == deviceClass.id);
        }
        public formatDeviceClassText(deviceClass: DeviceClassModel) {
            if (deviceClass.model) {
                return `${deviceClass.series} (${deviceClass.model})`;
            } else {
                return deviceClass.series;
            }
        }
        public toggleFavorite(item: DeviceClassViewItem) {
            item.isFavorite = !item.isFavorite;
            const ids = _.chain(this.deviceClassViewItems)
                .filter((viewItem) => viewItem.isFavorite)
                .map((viewItem) => viewItem.id)
                .value();
            this.AppStorageService.setAttr('selectedDeviceClasses', ids);
        }
        private init() {
            const storage = this.AppStorageService.getStorage();
            const ids = storage.selectedDeviceClasses;
            if (ids && ids.length > 0) {
                this.selectedDeviceClasses = _.filter(this.deviceClasses, (deviceClass) => _.some(ids, (id) => id == deviceClass.id));
            } else {
                this.selectedDeviceClasses = [];
            }
            this.deviceClassViewItems = _.chain(this.selectedDeviceClasses)
                .map((deviceClass) => this.getDeviceClassViewItem(deviceClass))
                .each((deviceClass) => deviceClass.isFavorite = true)
                .sortBy('name')
                .value();
        }
        private loadAllViewItems() {
            const ids = _.map(this.deviceClassViewItems, (item) => item.id);
            this.loadViewItems(ids);
        }
        private loadViewItems(ids: number[]) {
            if (!ids || ids.length == 0) {
                return;
            }
            _.each(ids, (id) => {
                this.getViewItemAndSetLoadingStatus(id, true);
            });
            this.getData(ids)
                .then((data) => {
                    _.each(ids, (id: number) => {
                        const viewItem = this.getViewItemAndSetLoadingStatus(id, false);
                        this.buildStatsForViewItem(viewItem, data[id]);
                    });
                }, () => {
                    _.each(ids, (id) => {
                        this.getViewItemAndSetLoadingStatus(id, false);
                    });
                });
        }
        private getDeviceClassViewItem(deviceClass: DeviceClassModel): DeviceClassViewItem {
            const item: DeviceClassViewItem = {
                id: deviceClass.id,
                name: this.formatDeviceClassText(deviceClass),
                isFavorite: false,
                statuses: null,
                total: null,
                isLoading: false
            };
            return item;
        }
        private buildStatsForViewItem(viewItem: DeviceClassViewItem, data: DeviceClassStatisticsModel) {
            if (!viewItem || !data) {
                return;
            }
            viewItem.total = data.totalDevices;
            viewItem.statuses = [];
            viewItem.statuses.push({ description: 'Last 30 minutes', count: data.numberOnlineLast30Min });
            viewItem.statuses.push({ description: 'Last 60 minutes', count: data.numberOnlineLast60Min });
            viewItem.statuses.push({ description: 'Last 1 day', count: data.numberOnlineLastDay });
            viewItem.statuses.push({ description: 'More than 1 day', count: data.numberOfflineMoreThanOneDay });
            viewItem.statuses.push({ description: 'Never Reported', count: data.numberNeverReported });
            viewItem.statuses.push({ description: 'Not Commissioned', count: data.numberNotCommissioned});
        }
        private getViewItemAndSetLoadingStatus(id: number, isLoading: boolean): DeviceClassViewItem {
            const viewItem: DeviceClassViewItem = _.find(this.deviceClassViewItems, (item) => item.id == id);
            viewItem.isLoading = isLoading;
            return viewItem;
        }
        private getData(ids): ng.IPromise<DeviceClassOfflineStatisticsResponse> {
            return this.RestangularV3.one('internal').one('devices').one('offline-statistics').get({ deviceClass: ids });
        }
    }
    angular.module('aq.admin.meters.devices').controller('AdminDevicesCtrl', AdminDevicesCtrl);
}
