namespace aq.admin.configuration.utilityCompany {
    export class RecreateEnrollmentsCtrl {
        public selectedUtilityAccounts;

        /* @ngInject */
        constructor(
            private utilityAccounts: aq.utilityBudgets.UtilityAccount[],
            private $mdDialog: ng.material.IDialogService
        ) {
            this.selectedUtilityAccounts = {};
        }

        public getSelectedAccountIds() {
            return _.keys(this.selectedUtilityAccounts)
                .filter((accountId) => this.selectedUtilityAccounts[accountId]);
        }

        public recreateEnrollments() {
            this.$mdDialog.hide(this.getSelectedAccountIds());
        }

        public cancel() {
            this.$mdDialog.cancel();
        }
    }

    angular
        .module('utilityCompany')
        .controller('RecreateEnrollmentsCtrl', RecreateEnrollmentsCtrl);
}
