angular.module('properties.sources')
    .filter('sourceClass', function($filter, PropertySettingsOptions) {
        return function(sourceClassObjectOrId, property) {
            if (!(sourceClassObjectOrId instanceof Object)) {
                sourceClassObjectOrId = _.findById(PropertySettingsOptions.get().sourceClasses, sourceClassObjectOrId);
            }

        	return sourceClassObjectOrId[property];
        }
    });
