var aq;
(function (aq) {
    var utilityBudgets;
    (function (utilityBudgets) {
        var UpdateUtilityAccount = /** @class */ (function (_super) {
            __extends(UpdateUtilityAccount, _super);
            /* @ngInject */
            function UpdateUtilityAccount($mdDialog, RestangularV3, Errors, Messages, utilityCompanies, utilityAccount, UtilityServiceHelper, Segment) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.RestangularV3 = RestangularV3;
                _this.Errors = Errors;
                _this.Messages = Messages;
                _this.utilityCompanies = utilityCompanies;
                _this.utilityAccount = utilityAccount;
                _this.UtilityServiceHelper = UtilityServiceHelper;
                _this.Segment = Segment;
                _this.autoUtilityUploadEnabled = false;
                _this.utilityAccount = _this.RestangularV3.copy(_this.utilityAccount);
                if (_this.utilityAccount.utilityUrl && _this.utilityAccount.username) {
                    _this.autoUtilityUploadEnabled = true;
                }
                _this.status = _this.UtilityServiceHelper.getStatusView(_this.utilityAccount.status);
                return _this;
            }
            UpdateUtilityAccount.prototype.cancel = function (data) {
                this.$mdDialog.cancel();
            };
            UpdateUtilityAccount.prototype.hide = function (result) {
                this.$mdDialog.hide(result);
            };
            UpdateUtilityAccount.prototype.save = function () {
                var _this = this;
                this.Segment.trackEvent('Utility Accounts:Update Utility Account');
                delete this.utilityAccount.status;
                if (!this.autoUtilityUploadEnabled) {
                    delete this.utilityAccount.username;
                    delete this.utilityAccount.password;
                    delete this.utilityAccount.utilityUrl;
                }
                return this.utilityAccount
                    .save()
                    .then(function (result) {
                    _this.Messages.success('Successfully updated Utility Account');
                    _this.hide(result);
                }, this.Errors.forCRUD('CREATE'));
            };
            return UpdateUtilityAccount;
        }(aq.common.Controllers.ModalCtrl));
        utilityBudgets.UpdateUtilityAccount = UpdateUtilityAccount;
        angular.module('aq.utilityBudgets').controller('UpdateUtilityAccount', UpdateUtilityAccount);
    })(utilityBudgets = aq.utilityBudgets || (aq.utilityBudgets = {}));
})(aq || (aq = {}));
