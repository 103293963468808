/**
 * If account has custom brand colors, this service will set color values for all items that are used
 * e.g.: for each item in drillin base on its level:

    Buildings:
       - building 1 : red
       - building 2 : blue
        - meter 1: red
        - meter 2: blue

    In case there is not enought brand colors (if we have more items in drillin or on chart) then
    it will generate brighter version of base colors (on each iteration color will be more brighter)

 */
angular.module('aq.filters')
.factory('brandColorsService', function($filter) {
    var colors = {};
    var countNo = 0;

    return {
        get: function(route, id) {
            return colors[route][id];
        },
        set: function (route, id, pallete) {

        	if (!colors[route]) {
                colors[route] = {};
                countNo = 0;
            }

            if (!colors[route][id]) {
                countNo++;

                // get color index e.g. count 9 / colors no 4 = will result 1
                // which means that we should use first color from pallete
                // and make it brighter
                var n = countNo % pallete.length; 
                
                // get multiplier for brightness percentage
                var b = Math.floor(countNo/pallete.length);

                if (countNo > pallete.length) {
                    if (n == 0) n = pallete.length - 1;
                    colors[route][id] = $filter('singleColor')(pallete[n - 1], b * 15, -15);
                } else {
                    colors[route][id] = pallete[countNo - 1];
                }
            }

        }
    }
})

angular.module('aq.filters.transformers')
.filter("color", function(aqColors, brandColorsService, BrandColors) {
    return function(input, route, id) {

        var pallete = BrandColors.get();

        if (pallete && !route) {
            console.error('Missing route and id param for brand colors filter');
            return null;
        }

        /** if no brand color pallete set the use default color generating **/
        if (pallete && pallete.length > 0) {
            var hash = input.replace(' ', '').toLowerCase() + id;

            brandColorsService.set(route, hash, pallete);
            return brandColorsService.get(route, hash);
        } else {
            return aqColors.getUniqueColorFromName(input);
        }

    }
});
