namespace aq.accounts.companySettings.metric {

    export class MetricCtrl {
        /* @ngInject */
        constructor(
            private $scope,
            private Restangular,
            private Errors,
            private $mdDialog
        ) {

        }

        create() {
            this.$mdDialog.show({
                controller: 'MetricCRUDCtrl as ctrl',
                templateUrl: 'app/accounts/companySettings/metrics/crud/crud.html',
                clickOutsideToClose: true,
                locals: {
                    object: new AccountMetric(),
                    account: this.$scope.account,
                    realUnits: this.$scope.realUnits
                }
            })
                .then((createdMetric) => {
                    this.$scope.account.accountMetrics.push(createdMetric);
                });
        }

        update(metric) {
            this.$mdDialog.show({
                controller: 'MetricCRUDCtrl as ctrl',
                templateUrl: 'app/accounts/companySettings/metrics/crud/crud.html',
                clickOutsideToClose: true,
                locals: {
                    object: metric,
                    account: this.$scope.account,
                    realUnits: this.$scope.realUnits
                }
            })
                .then((updatedMetric) => {
                    if (updatedMetric) {
                        _.replaceItemById(this.$scope.account.accountMetrics, updatedMetric);
                    } else {
                        _.remove(this.$scope.account.accountMetrics, (m: any) => { return m.id == metric.id; });
                    }
                });
        }
    }
}

angular.module('accounts').controller('MetricCtrl', aq.accounts.companySettings.metric.MetricCtrl);