namespace aq.service {
    export class CreateBuildingServiceDialogCtrl extends aq.common.Controllers.ModalCtrl {
        public showManual;
        /* @ngInject */
        constructor(
            public $scope,
            public $mdDialog,
            public newBuilding,
            public BuildingService: aq.services.BuildingService,
            private Auth: aq.services.Auth,
        ) {
            super($scope, $mdDialog);
            this.showManual = false;
            $scope.newBuilding = this.newBuilding;
        }

        selectedItemChange(item) {
            if (item) {
                const { street, city, stateCode, postalCode, countryCode} = item.place.properties;
                this.newBuilding.address = street;
                this.newBuilding.city = city;
                this.newBuilding.state = stateCode;
                this.newBuilding.zipCode = postalCode;
                this.newBuilding.country = countryCode;
            }
        }

        queryAddress(searchText) {
            return this.BuildingService.findAddress(searchText);
        };

        toggleManualAddress() {
            this.newBuilding.address = null;
            this.newBuilding.city = null;
            this.newBuilding.state = null;
            this.newBuilding.zipCode = null;
            this.newBuilding.country = null;
            if (this.showManual) {
                this.showManual = false;
            } else {
                this.showManual = true;
            }
        }
    }

    angular
    .module('aq.services')
    .controller('CreateBuildingServiceDialogCtrl', CreateBuildingServiceDialogCtrl);
}
