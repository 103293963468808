angular.module('aq.ui').directive('aqLeftSideNavItem', ($mdMedia) => {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/common/directives/leftSideNav/leftSideNavItem.html',
        scope: {
            title: '@',
            icon: '@',
            materialIcon: '@?'
        },
        link: ($scope: any, $element, $attrs: any) => {
            $scope.$mdMedia = $mdMedia;
            $scope.idFromTitle = $scope.title.replace(/\s/g, '-').toLowerCase();
        }
    };
});
