angular
.module('aq.admin.configuration.integrationApps')
.controller('IntegrationAppsNewCtrl', function ($scope, $state, Restangular) {

    $scope.integrationApp = { workers: [] };

    Restangular.one('IntegrationApps').customGET('queryServices').then(function(workers) {
        $scope.workers = workers;
    });

    $scope.deleteApp = function () {
        $state.go('aq.admin.configuration.integrationApps');
    };

    $scope.addApp = function(app) {
        return Restangular.all('IntegrationApps').post(app).then(function() {
            $state.go('aq.admin.configuration.integrationApps');
        });
    };

    $scope.addService = function(newService, integrationApp) {
        let defaults = _(newService.params).keyBy('name').mapValues('default').value();
        integrationApp.workers.push({
            integrationApp: integrationApp.id,
            workerClassName: newService.id,
            enabled: true,
            parameters: defaults
        });
    };

    $scope.deleteService = function(oldWorker, integrationApp) {
        integrationApp.workers = _.without(integrationApp.workers, _.find(integrationApp.workers, {id: oldWorker.id}));
    };

    $scope.integrationAppTypes = [
        { value: 'INTERNAL', label: 'Internal' },
        { value: 'EXTERNAL', label: 'External' }
    ];

});
