namespace aq.deviceManagement {
    import DeviceImage = aq.common.models.DeviceImage;

    export class AddDevice extends aq.common.Controllers.ModalCtrl {
        private createAnother: boolean;
        private measures: Array<aq.common.models.DeviceMeasure>;
        private measure: string = null;
        private createAssociation: boolean = false;
        private newDevice: aq.common.models.DeviceCreationRequest = {
            name: '',
            images: [],
            building: null,
            description: '',
            location: '',
            locationTag: null,
            viewOnly: {
                tenants: []
            },
            tenantLinks: [],
            deviceCategory: null,
            deviceClass: {
                make: '',
                series: '',
                model: ''
            },
            fields: {}
        };
        private newDeviceImage: { imageUrl?:string } = {};
        private step: number = 1;
        private templateFields: aq.common.models.DeviceExtraFields = {};
        private isAdmin;
        private query;
        private selectedLocationTag;
        private uploading;
        tenantLabel: string;
        /* @ngInject */
        constructor(
            private Auth: aq.services.Auth,
            public $mdDialog: ng.material.IDialogService,
            private $mdStepper,
            private deviceCategories: Array<IDeviceCategories>,
            private Messages,
            private classesByCategory: aq.common.models.DeviceClassNameByCategory,
            private tenants,
            private tags: aq.common.models.BuildingTag[],
            private buildingId: string,
            private RestangularV3,
            private building,
            createAnother,
            private $mdMedia: ng.material.IMedia,
            private DeviceService: aq.services.DeviceService
        ) {
            super({}, $mdDialog);
            this.createAnother = createAnother || false;
            this.measures = this.extractMeterMeasuresFromClassesByCategory(classesByCategory);
            this.isAdmin = this.Auth.check({access: 'FULL_ACCESS'});
            this.newDevice.building = buildingId;
            this.selectedLocationTag = null;
            this.uploading = false;
            this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'unit' : 'tenant';
        }

        filterName(query: string): (item: aq.common.models.DeviceClassName) => boolean {
            return (item) => {
                return (item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0);
            };
        }

        filterMeasure(): (item: aq.common.models.DeviceClassName) => boolean {
            return(item) => {
                if (this.measure != null) {
                    return item.measure
                        ? item.measure.toLowerCase() === this.measure.toLowerCase()
                        : false;
                }
                return true;
            };
        }

        filterDeviceClassName(query: string): Array<aq.common.models.DeviceClassName> {
            return this.classesByCategory[this.newDevice.deviceCategory]
                .filter(this.filterMeasure())
                .filter(this.filterName(query));
        }

        selectClass(deviceClassName: aq.common.models.DeviceClassName): void {
            if (deviceClassName) {
                this.newDevice.deviceClass = _.pick(deviceClassName, ['make', 'model', 'series']);
            }
        }

        addTenant(event): void {
            (this.$mdDialog as any).show({
                targetEvent: event,
                templateUrl: 'app/deviceManagement/actions/addTenant/addTenant.html',
                controller: 'AddTenant',
                controllerAs: 'vm',
                multiple: true,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                clickOutsideToClose: false
            })
            .then((tenant) => {
                tenant.building = this.building.id;
                this.RestangularV3.all('tenants')
                .post(tenant, {buildingId: this.buildingId})
                .then((createdTenant) => {
                    this.tenants.push(createdTenant);
                    this.newDevice.viewOnly.tenants.push(createdTenant);
                    this.Messages.success(`New tenant ${createdTenant.name} created`);
                });
            });
        }

        selectLocationTag(tag) {
            if (tag) {
                if (tag.query) {
                    _.remove(this.tags, { query: tag.query });
                    tag.name = tag.query;
                    delete tag.query;
                    this.tags.push(tag);
                    this.Messages.success(`New location '${tag.name}' added`);
                }
                this.newDevice.locationTag = tag;
            } else if (this.query === '') {
                this.newDevice.locationTag = null;
            }
        }

        filterLocationTag(query: string) {
            _.remove(this.tags, (t) => t.query);
            if (query && !_.some(this.tags, (t) => t.name.toLowerCase() == query.toLowerCase())) {
                const currentQuery = {
                    name: `Add new location "${query}"`,
                    query
                } as any;
                this.tags.push(currentQuery);
            }
            const tags = _.filter(this.tags, (tag) => {
                return tag.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
            });
            return _.sortBy(tags, [(t) => t.query ? 1 : 0, 'name']);
        }

        isValidLocation() {
            if (this.newDevice.locationTag) {
                return this.query === this.newDevice.locationTag.name;
            } else {
                return !this.query;
            }
        }

        public cancel(data?): void {
            this.$mdDialog.cancel({tenants: this.tenants, data});
        }

        public hide(data?): void {
            this.$mdDialog.hide({tenants: this.tenants, data});
        }

        public next(): void {
            if (this.step === 2 && !this.hasDeviceClass(this.newDevice.deviceCategory)) {
                this.step++;
            }
            this.$mdStepper('createDeviceStepper').goto(this.step++);
        }

        public save(): ng.IPromise<aq.common.models.DeviceElement> {
            this.newDevice.tenantLinks = this.newDevice.viewOnly.tenants.map((tenant) => ({
                id: null,
                active: false,
                device: null,
                tenant: {
                    id: tenant.id,
                    name: tenant.name
                }
            }));
            return this.DeviceService.create(this.newDevice).then(
                (response) => {
                    this.hide({
                        device: this.newDevice,
                        createAnother: this.createAnother,
                        createAssociation: this.createAssociation,
                        newDevice: response
                    });
                    return response;
                },
                (error) => {
                    if (error.status === DeviceDetail.HTTP_CODE_CONSTRAINT_VIOLATION) {
                        this.Messages.error(error.data);
                    } else {
                        this.Messages.error('Error creating Device');
                    }
                });
        }

        public hasDeviceClass(deviceCategory: string): boolean {
            return !deviceCategory || ['METER', 'NETWORKING', 'SENSOR'].indexOf(deviceCategory) >= 0;
        }

        public extractMeterMeasuresFromClassesByCategory(
            classesByCategory: aq.common.models.DeviceClassNameByCategory): Array<aq.common.models.DeviceMeasure> {
            const measures = new Set(classesByCategory['METER']
                .map(deviceClass => deviceClass.measure)
                .filter(measure => measure));
            return Array.from(measures);

        }

        public isAddingMeter(): boolean {
            return this.newDevice.deviceCategory === 'METER';
        }

        public getDeviceCategories(): Array<IDeviceCategories> {
            return this.deviceCategories;
        }

        public getFields(): void {
            this.RestangularV3.all('devices').customGET('device-class', this.newDevice.deviceClass).then(
                (response: aq.common.models.DeviceClass) => {
                    this.templateFields = this.filterFields(response.fields);
                    Object.keys(response.fields).forEach((key) => {
                        if (response.fields[key].hasOwnProperty('value')) {
                            this.newDevice.fields[key] = { value: response.fields[key].value };
                        }
                    });
                },
                () => {
                    this.Messages.error('Cannot find fields for device class');
                }
            );
        }

        public filterFields(fields: aq.common.models.DeviceExtraFields): aq.common.models.DeviceExtraFields {
            if (fields) {
                const visibleFields: aq.common.models.DeviceExtraFields = {};
                Object.keys(fields).forEach(key => {
                    if (key === 'deviceIdentifier') {
                        visibleFields[key] = fields[key];
                    }
                });
                return visibleFields;
            } else {
                return {};
            }
        }

        private saveImage() {
            if (this.newDeviceImage && this.newDeviceImage.imageUrl) {
                const deviceImage: DeviceImage = {url: this.newDeviceImage.imageUrl};
                this.newDevice.images = [deviceImage];
            }
            this.unsetUploadingFlag();
        }

        private setUploadingFlag() {
            this.uploading = true;
        }

        private unsetUploadingFlag() {
            this.uploading = false;
        }

    }

    angular
        .module('deviceManagement')
        .controller('AddDevice', AddDevice);
}
