var aq;
(function (aq) {
    var utilityBudgets;
    (function (utilityBudgets) {
        var UtilityAccountDetails = /** @class */ (function () {
            /* @ngInject */
            function UtilityAccountDetails(utilityAccount, utilityServices, buildingId, utilityCompanies, tariffSchedules, Messages, Errors, $mdDialog, $state, $mdMedia, UtilityServiceHelper, UserService, users, Auth, authAccess, readOnlyForm, buildings, RestangularV3, $scope, Segment, utilityAccountBuildingIds) {
                var _this = this;
                this.utilityAccount = utilityAccount;
                this.utilityServices = utilityServices;
                this.buildingId = buildingId;
                this.utilityCompanies = utilityCompanies;
                this.tariffSchedules = tariffSchedules;
                this.Messages = Messages;
                this.Errors = Errors;
                this.$mdDialog = $mdDialog;
                this.$state = $state;
                this.$mdMedia = $mdMedia;
                this.UtilityServiceHelper = UtilityServiceHelper;
                this.UserService = UserService;
                this.users = users;
                this.Auth = Auth;
                this.authAccess = authAccess;
                this.readOnlyForm = readOnlyForm;
                this.buildings = buildings;
                this.RestangularV3 = RestangularV3;
                this.$scope = $scope;
                this.Segment = Segment;
                this.utilityAccountBuildingIds = utilityAccountBuildingIds;
                this.tariffScheduleDisplayNameById = {};
                this.isAqAdmin = this.UserService.currentUser.userType === 'ADMINISTRATOR';
                this.budgetAccess = this.authAccess.Budgets;
                if (!this.utilityAccount) {
                    this.$state.go('aq.utilityBudgets.utilityaccounts', {}, { reload: true });
                }
                this.entityActions = [];
                this.currentNavItem = 'info';
                if (this.$state.current.name.endsWith('.changelog')) {
                    this.currentNavItem = 'changelog';
                }
                if (this.$state.current.name.endsWith('.admin')) {
                    this.currentNavItem = 'admin';
                }
                this.backAction = {
                    state: 'aq.utilityBudgets.utilityaccounts',
                    isDataSetOnSaveOnly: true,
                    stateData: {
                        timeStamp: moment().valueOf()
                    }
                };
                this.buildingIdToName = [];
                var visibleBuildingIds = this.buildings
                    .map(function (visibleBuilding) { return Number(visibleBuilding.id); });
                this.buildingsForUtilityAccount = this.utilityAccountBuildingIds
                    .filter(function (id) { return id !== _this.buildingId; })
                    .filter(function (id) { return visibleBuildingIds.indexOf(id) > -1; });
                this.buildings.forEach(function (visibleBuilding) {
                    _this.buildingIdToName[Number(visibleBuilding.id)] = visibleBuilding.name;
                });
                this.utilityAccountForStatus = angular.copy(this.utilityAccount);
                this.legendItems = [
                    this.UtilityServiceHelper.getStatusView(null)
                ].concat(_.map(this.UtilityServiceHelper.getAllStatuses(), function (status) { return _this.UtilityServiceHelper.getStatusView(status); }));
            }
            UtilityAccountDetails.prototype.save = function (account) {
                var _this = this;
                this.Segment.trackEvent('Utility Accounts:Update Utility Account');
                delete this.utilityAccount.status;
                if (!this.utilityAccount.autoUploadEnabled) {
                    delete this.utilityAccount.username;
                    delete this.utilityAccount.password;
                }
                return account.save()
                    .then(function (updatedAccount) {
                    _this.Messages.success('Successfully updated Utility Account');
                })
                    .catch(function () {
                    _this.Messages.error('Error updating Utility Account, please contact your Administrator');
                });
            };
            UtilityAccountDetails.prototype.delete = function (account) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Are you sure you want to delete this Utility Account?')
                    .ok('Confirm')
                    .cancel('Cancel');
                return this.$mdDialog
                    .show(confirm)
                    .then(function () {
                    _this.Segment.trackEvent('Utility Accounts:Delete Utility Account');
                    account.remove({ buildingId: _this.buildingId })
                        .then(function () {
                        _this.Messages.info('Utility Account deleted');
                        _this.$scope.$emit('UTILITY_ACCOUNT_REMOVED', account.id);
                        _this.$state.go('aq.utilityBudgets.utilityaccounts');
                    })
                        .catch(function () {
                        _this.Messages.error('Unable to perform delete action');
                    });
                });
            };
            return UtilityAccountDetails;
        }());
        utilityBudgets.UtilityAccountDetails = UtilityAccountDetails;
        angular.module('aq.utilityBudgets').controller('UtilityAccountDetails', UtilityAccountDetails);
    })(utilityBudgets = aq.utilityBudgets || (aq.utilityBudgets = {}));
})(aq || (aq = {}));
