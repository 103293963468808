// configuration objects for preset values for various chart configuration options
namespace aq.dashboard.widgets {
    import HighChartConfig = aq.highcharts.HighChartConfig;
    export interface BaseGraphConfigModel {
        options: GraphConfigModelOptions;
        view: GraphConfigModelView;
        legend: any;
        newMeasure: string;
        yAxis: any[];
        interval: string;
        series: GraphSeries[];
        preset: string;
        buildingId: string;
        title: boolean;
        titleText: string;
        isCustomTitle: boolean;
        customTrend: any;
        isFixedTrendDate: boolean;
    }

    export interface GraphConfigModel extends BaseGraphConfigModel {
        actions: GraphEditService;
    }
    export interface GraphConfigModelOptions {
        currentNavItem: string;
        graphTypes: string[];
        timePresets: string[];
        series: Record<string, any>;
        xAxisFormat: Record<string, any>;
        trendPeriods: string[];
        buildings: any[];
        measures: any[];
        allMeasures: any[];
        colors: { value: string; hex: string }[];
        presetColorWeather: string;
        presetColorExpectedEnergy: string;
    }
    export interface GraphConfigModelView {
        isShowWeather: boolean;
        isShowTrend: boolean;
        isPredictedEnergy: boolean;
        expandedItem: any;
        selectedYAxis: any;
        newMeasure: string;
    }
    export interface GraphSeries {
        measure: string;
        title?: string;
        type: string;
        color: string;
        colorOptions?: any[];
        yAxis?: number;
        trend?: boolean;
        trendPeriod?: string;
        end?: any;
        duration?: any;
        expected?: boolean;
        drillin?: string;
        drillinView?: string;
        drilldown?: {
            name: string;
        };
        customTrend?: any;
    }
    export class GraphCtrl {
        private building: aq.common.models.Building;
        private chart;
        private chartTimeout;
        private dataSeries;
        private chartConfig;
        private isLoadingData: boolean;
        private isBuildingInSetup: boolean;
        /* @ngInject */
        constructor(
            private $scope,
            private account: aq.common.models.Account,
            private buildings: aq.common.models.Building[],
            private tenants: aq.common.models.Tenant[],
            private config: GraphConfigModel,
            private $filter: ng.IFilterService,
            private DashboardOptionsService: aq.dashboard.DashboardOptionsService,
            private GraphData: aq.dashboard.widgets.GraphData,
            private Auth: aq.services.Auth,
            private meter,
            private uses,
            private source,
            private space,
            private tenant,
            private $timeout,
            private ModelUtilService: aq.services.ModelUtilService,
            private GraphEditService: GraphEditService,
            private $translate,
            private WidgetHelperService
        ) {
            this.isLoadingData = true;
            const series = {
                'selected building': null,
                meter,
                uses,
                source,
                space,
                tenant: null,
                customTrend: null
            };

            if (Auth.check({ appName: 'Tenant Billing' })) {
                series.tenant = tenant;
            }

            // fix: existing widgets show legend by default, but checkbox is not checked
            if (angular.equals(config, {}) || !config.legend) {
                config.legend = { enabled: true };
            }
            config.options = {
                currentNavItem: 'basic',
                graphTypes: GraphTypes.getGraphTypes(),
                timePresets: TimePresets.getPresets(),
                series,
                xAxisFormat: DateFormats.getDateFormats(),
                trendPeriods: _.concat(['none'], TrendPeriods.getTrends()),
                buildings: ModelUtilService.pareProperties(buildings, ['account']),
                colors: [
                    {
                        value: 'red',
                        hex: '#E70F28'
                    },
                    {
                        value: 'orange',
                        hex: '#F1A81D'
                    },
                    {
                        value: 'yellow',
                        hex: '#FFDA06'
                    },
                    {
                        value: 'green',
                        hex: '#7ACD46'
                    },
                    {
                        value: 'blue',
                        hex: '#0091F1'
                    },
                    {
                        value: 'purple',
                        hex: '#C148B0'
                    },
                    {
                        value: 'dark gray',
                        hex: '#4D4D4D'
                    },
                    {
                        value: 'gray',
                        hex: '#979797'
                    },
                    {
                        value: 'custom',
                        hex: ''
                    }
                ],
                presetColorWeather: '#EE6C4D',
                presetColorExpectedEnergy: '#BCDDE2',
                measures: [],
                allMeasures: []
            };

            config.actions = GraphEditService;

            config.yAxis = config.yAxis || [];
            this.cleanupEmptyYAxis(config);

            // set defaults for required properties
            if (!config.interval) {
                config.interval = '1d';
            }

            if (!config.series) {
                config.series = [];
                config.preset = TimePresets.defaultPreset();
                config.series[0] = GraphTemplates.getDefaultTemplate();
            }

            config.series.forEach((s) => {
                s.colorOptions = config.actions.buildColorOptionsForSeries(s, config);

                // hacky fix for getting the correct titles for Tishman Speyer Demo see:
                // https://aquicore.atlassian.net/browse/AQ-10692
                if (s.measure === 'WET_BULB') {
                    s.title = 'Wet Bulb (°F)';
                }
                if (s.measure === 'PERCENT') {
                    s.title = 'Outdoor Humidity (%)';
                }
                if (!s.title) {
                    s.title = config.actions.getMeasureLabel(s.measure);
                }
                if (!s.drillin) {
                    s.drillin = '';
                    s.drillinView = 'selected building';
                } else {
                    s.drillinView = s.drillin;
                }
                if (s.yAxis == null || s.yAxis >= config.yAxis.length) {
                    s.yAxis = config.actions.findOrCreateYAxisForMeasure(s.measure, config);
                }
            });

            config.view = this.getConfigView(config);

            this.building = _.find(buildings, { id: parseInt(config.buildingId) }) || buildings[0];
            config.buildingId = this.building.id;

            this.$scope.config = config;

            this.$scope.config.series.forEach((series) => {
                if (series.drilldown || (series.drilldownId && series.drillin)) {
                    series.drilldown = series.drilldown ? series.drilldown
                        : _.find(this.$scope.config.options.series[series.drillin][this.$scope.config.buildingId], { id: parseInt(series.drilldownId) });
                }
            });

            this.isBuildingInSetup = this.WidgetHelperService.isBuildingInSetup(this.building);

            this.DashboardOptionsService.init(account.id, account.measurementSystem, account.currencyUnit).then(() => {
                this.setMeasures();
            });

            this.drawGraph();
        }

        public isSFMeasure(measure) {
            return _.includes(measure, 'SQFT') || _.includes(measure, 'AREA');
        }

        public cleanupEmptyYAxis(cfg) {
            for (let i = cfg.yAxis.length - 1; i >= 0; i--) {
                if (!_.some(cfg.series, (s) => s.yAxis == i)) {
                    const removeIndex = i;
                    cfg.yAxis.splice(removeIndex, 1);
                    _.each(cfg.series, (s) => {
                        if (s.yAxis > removeIndex) {
                            s.yAxis--;
                        }
                    });
                }
            }
        }

        public getConfigView(cfg) {
            const mainMeasureSeries = _.filter(cfg.series, (s) => !s.trend && !s.expected);
            const isShowTrend = mainMeasureSeries.length > 0 && _.every(mainMeasureSeries, (main) => _.find(cfg.series, (s) => s.trend && s.measure == main.measure));
            let isPredictedEnergy = false;
            const mainSeriesKW = _.find(cfg.series, (s) => s.measure == 'KW' && !s.expected);
            if (mainSeriesKW) {
                const expectedSeriesKW = _.find(cfg.series, (s) => s.measure == 'KW' && s.expected);
                if (expectedSeriesKW && cfg.preset !== 'trailing year') {
                    isPredictedEnergy = true;
                }
            }
            const isShowWeather = _.some(cfg.series, (s) => s.measure == 'F' && !s.trend);

            return {
                isShowWeather,
                isShowTrend,
                isPredictedEnergy,
                expandedItem: null,
                selectedYAxis: cfg.yAxis[0],
                newMeasure: 'NONE'
            };
        }

        public calculateTitleAndAxis(series) {
            // date format is user-specified or determined by the chart interval
            const dateFormat = this.$scope.config.xAxisFormat ? this.$scope.config.xAxisFormat : this.$scope.momentDateFormat;

            if (series && series.length > 0) {
                // configure title based on start and end dates
                if (series[0].data && series[0].data.length > 0) {
                    const noRangeSeries = _.filter(series, (s: any) => {
                        return s.type != 'arearange';
                    });
                    if (noRangeSeries && noRangeSeries.length > 0) {
                        this.formatDates(noRangeSeries, dateFormat);
                        this.configureTitle(noRangeSeries);
                    }
                    this.configurePlotlines(series, dateFormat);
                }
            }
        }

        public configureTitle(series) {
            if (this.$scope.config.title) {
                this.$scope.title = this.$scope.config.titleText;
            } else {
                this.$scope.title = null;
            }
        }

        public formatDates(series, dateFormat) {
            if (dateFormat == 'MMM DD') {
                dateFormat = this.$translate.instant(`dates.${dateFormat}`);
            }
            this.$scope.xAxisLabels = _.map(series[0].data, (d: any) => {
                return d.rawTime.format(dateFormat);
            });
        }

        public configurePlotlines(series, dateFormat) {
            const tickInterval = series[0] ? Math.round(series[0].data.length / _.intersection(this.$scope.xAxisLabels).length) : null;
            const plotLines = [];

            // add midnight marker to charts displaying hourly data
            if (this.$scope.config.interval == '1h' && this.$scope.config.preset != 'trailing month' && this.$scope.xAxisLabels) {
                this.$scope.xAxisLabels.forEach((date, index) => {
                    if (moment(date, dateFormat).format('HH') == '00') {
                        plotLines.push({
                            color: '#4d4d4d',
                            value: index * (tickInterval > 0 ? tickInterval : 1),
                            width: 1
                        });
                    }
                });
            }
            this.$scope.tickInterval = tickInterval;
            this.$scope.plotLines = plotLines;
        }

        private setMeasures() {
            const allNonConvertedUnits = this.DashboardOptionsService.getUnits(true);
            const nonBuildingUnits = _.filter(allNonConvertedUnits, (unit) => {
                return !unit.isIntensity;
            });
            this.$scope.config.options.allMeasures = this.DashboardOptionsService.organizeUnitsByServiceType(allNonConvertedUnits);
            this.$scope.config.options.measures = this.DashboardOptionsService.organizeUnitsByServiceType(nonBuildingUnits);
        }

        private calculateTimePeriod() {
            const periodDetails: DatePeriod.DatePeriod = TimePresets.getPresetDetails(this.$scope.config.preset);
            this.$scope.config.interval = periodDetails.interval;
            this.$scope.config.series.forEach((series) => {
                series.end = periodDetails.end;
                series.duration = periodDetails.duration;
                // change interval to 1h if power is present on graph
                if (series.measure == 'KW' && this.$scope.config.preset != 'trailing year' && this.$scope.config.preset != 'custom' && this.$scope.config.preset != 'trailing hour') {
                    this.$scope.config.interval = '1h';
                }
            });
        }

        private configureyAxis() {
            this.$scope.config.yAxis.forEach((axis) => {
                axis.maxPadding = 0;
                axis.minPadding = 0;
                axis.showLastLabel = true;
            });
        }

        private configureQueryable() {
            this.$scope.config.series.forEach((series) => {
                this.calculateQueryable(series);
            });
        }

        private calculateQueryable(series) {
            if (series.drilldown) {
                let queryCursor = series.drilldown;
                const queryable = [];
                let parentRoute = queryCursor.route;
                series.drilldownId = queryCursor.id;

                // push selected queryable onto chain
                queryable.push({
                    id: queryCursor.id,
                    route: queryCursor.route
                });

                while (queryCursor.parent != null) {
                    // only push a queryable onto the chain if its route is different from its parent
                    if (queryCursor.route != parentRoute) {
                        queryable.push({
                            id: queryCursor.id,
                            route: queryCursor.route
                        });
                    }

                    parentRoute = queryCursor.route;
                    queryCursor = queryCursor.parent;
                }

                _.reverse(queryable);
                series.queryable = _.concat(this.$scope.buildingPrefix, queryable);
            } else {
                series.queryable = this.$scope.buildingPrefix;
            }
        }

        private configureColor() {
            this.$scope.config.series.forEach((series) => {
                if (series.presetColor) {
                    series.color = series.presetColor;
                }
            });
        }

        private drawGraph() {
            if (this.$scope.config.preset != 'custom') {
                this.calculateTimePeriod();
            }
            // if there is a custom date range, find the date range for the first series and apply it to all new series
            else if (this.$scope.config.series[0]) {
                this.$scope.config.series.forEach((series) => {
                    if (!this.$scope.config.series.end) {
                        // no time period info, so copy series[0] period
                        series.end = this.$scope.config.series[0].end;
                        series.duration = this.$scope.config.series[0].duration;
                    }
                });
            }
            this.building = _.find(this.buildings, { id: parseInt(this.$scope.config.buildingId) }) || this.buildings[0];
            this.$scope.config.buildingId = this.building.id; // This is in case we didn't have a building selected
            this.$scope.buildingPrefix = [
                {
                    id: this.account.id,
                    route: 'accounts'
                },
                {
                    id: this.building.id,
                    route: 'buildings'
                }
            ];
            this.$scope.config.index = -1;
            this.configureyAxis();
            this.configureQueryable();
            this.configureColor();

            const period = this.GraphData.calculateGraphPeriod(this.$scope.config.series[0], this.building.timeZoneId);
            const highChartConfig = new HighChartConfig(this.$translate);
            this.$scope.momentDateFormat = highChartConfig.getAxisMomentDateFormatByPeriodAndInterval(period.start, period.end, this.$scope.config.interval);
            this.$scope.titleDateFormat = highChartConfig.getMomentDateFormatByInterval(this.$scope.config.interval);

            this.$scope.$on('abortLoading', () => {
                this.GraphData.abort();
            });


            const emptySeries = this.buildEmptyChartConfigAndGetEmptySeries();

            const config = angular.copy(this.$scope.config);
            let emptySeriesIndexes = [];
            if (this.isBuildingInSetup) {
                // storing indexes to be replaced with empty data
                const indexMap = _.map(config.series, (s, index) => {
                    return {
                        index,
                        isMissing: s.drillinView == 'selected building'
                    };
                });
                emptySeriesIndexes = _.filter(indexMap, (item) => item.isMissing);
                // not loading data for building-level series
                config.series = _.filter(config.series, (s) => s.drillinView != 'selected building');
            }

            this.GraphData.getAllSeriesData(
                config,
                { account: this.account, building: this.building, tenants: this.tenants },
                this.$scope.momentDateFormat
            ).then((series) => {
                const flattenedSeries = _.flatten(series);
                emptySeriesIndexes.forEach((es) => {
                    flattenedSeries.splice(es.index, 0, emptySeries[es.index]);
                });
                this.calculateTitleAndAxis(flattenedSeries);
                this.dataSeries = this.getSortedSeries(flattenedSeries);
                if (this.$scope.config.customExport) {
                    this.chartConfig = this.buildChartConfig(true, this.$scope.config.customExport.enabled);
                } else {
                    this.chartConfig = this.buildChartConfig(this.isBuildingInSetup, false);
                }
            }).finally(() => {
                this.isLoadingData = false;
            });
        }

        private buildEmptyChartConfigAndGetEmptySeries(): any[] {
            // generates an empty chart with all series visible in the legend, to serve as placeholder until the data is fetched
            // empty series for commissioning building-level data will be re-used in the final chart (no real data will be fetched for those)
            const series = this.GraphData.getAllSeriesDataEmpty(
                this.$scope.config,
                { account: this.account, building: this.building, tenants: this.tenants },
                this.$scope.momentDateFormat
            );
            this.calculateTitleAndAxis(series);
            // re-draw chart
            this.dataSeries = this.getSortedSeries(series);
            if (this.$scope.config.customExport) {
                this.chartConfig = this.buildChartConfig(true, this.$scope.config.customExport.enabled);
            } else {
                this.chartConfig = this.buildChartConfig(true, false);
            }
            return series;
        }

        private getSortedSeries(series) {
            return _.sortBy(series, (s) => {
                let order1;
                switch (s.type) {
                    case 'column':
                        order1 = 1;
                        break;
                    case 'arearange':
                        order1 = 2;
                        break;
                    case 'line':
                        order1 = 3;
                        break;
                    default:
                        order1 = 0;
                }
                let order2;
                if (s.trend) {
                    order2 = 1;
                } else {
                    order2 = 2;
                }
                return `${order1}_${order2}_${s.name}`;
            });
        }

        private buildChartConfig(hideVerticalLines: boolean, exportingEnabled: boolean): any {
            const chartOptions = {};
            if (this.$scope.config.type == 'gauge') {
                return {
                    chart: {
                        type: 'solidgauge'
                    },
                    title: null,
                    pane: {
                        center: ['50%', '55%'],
                        size: '90%',
                        startAngle: -90,
                        endAngle: 90,
                        background: {
                            backgroundColor: '#EEE',
                            innerRadius: '60%',
                            outerRadius: '100%',
                            shape: 'arc'
                        }
                    },
                    tooltip: {
                        enabled: false
                    },
                    // the value axis
                    yAxis: {
                        stops: this.$scope.config.stops ? this.$scope.config.stops
                            : [
                                [0.1, '#008BF5'], // green
                                [0.5, '#DDDF0D'], // yellow
                                [0.9, '#DF5353'] // red
                            ],
                        lineWidth: 0,
                        minorTickInterval: null,
                        tickAmount: 2,
                        labels: {
                            y: 16
                        },
                        min: this.$scope.config.targetMin ? this.$scope.config.targetMin : 0,
                        max: this.$scope.config.targetMax ? this.$scope.config.targetMax : 100
                    },
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                y: 5,
                                borderWidth: 0,
                                useHTML: true
                            }
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        data: this.$scope.config.targetValue ? [this.$scope.config.targetValue] : [0],
                        dataLabels: {
                            format: '<div style="text-align:center"><span style="font-size:25px;color:"black">{y}</span><br/></div>'
                        }
                    }]
                };
            }
            const yAxes = angular.copy(this.$scope.config.yAxis);
            if (hideVerticalLines) {
                _.each(yAxes, (axis) => {
                    axis.gridLineColor = 'transparent';
                });
            }
            return {
                chart: {
                    plotBorderWidth: 1,
                    height: this.$scope.config.height ? this.$scope.config.height : null
                },
                tooltip: {
                    formatter() {
                        let tooltip = `${moment(this.point.rawTime).format('ddd, MMM DD')}`;
                        tooltip += `<br><span style="color:${this.color}">●</span> ${this.series.name}: `;
                        if (this.point.options.low != null) {
                            tooltip += `<b>${this.point.options.low} kW - ${this.point.options.high} kW`;
                        } else {
                            tooltip += `<b>${this.point.tooltip}`;
                        }
                        if (this.point.isMissingData) {
                            tooltip += '<br><div style="color:#ff0000">This building uses data directly <br><div style="color:red">from your utility company.';
                            tooltip += '<br><i>Some data has not been received yet.';
                        }
                        return tooltip;
                    }
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        }
                    },
                    column: {
                        stacking: this.$scope.config.stacked ? 'normal' : null
                    }
                },
                title: {
                    text: this.$scope.title,
                    style: {
                        fontSize: '12px',
                        fontFamily: 'sans-serif'
                    }
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: exportingEnabled
                        }
                    }
                },
                legend: this.$scope.config.legend ? this.$scope.config.legend : { enabled: true },
                xAxis: {
                    labels: {
                        rotation: -45
                    },
                    type: 'category',
                    categories: this.$scope.xAxisLabels,
                    tickLength: 0,
                    tickInterval: !hideVerticalLines && this.$scope.tickInterval,
                    plotLines: !hideVerticalLines && this.$scope.plotLines
                },
                yAxis: yAxes && yAxes.length > 0 ? yAxes : [{ min: 0, maxPadding: 0, minPadding: 0 }],
                series: this.dataSeries
            };
        }
    }

    angular.module('aq.dashboard.widgets').controller('GraphCtrl', GraphCtrl);
}
