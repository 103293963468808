namespace aq.reports {
    class _VarianceDisplay {
        public value: string;
        public up: boolean;
        public suffix: string;

        /* @ngInject */
        constructor() {

        }
    }

    export const VarianceDisplay: ng.IComponentOptions = {
        controller: _VarianceDisplay,
        controllerAs: 'vm',
        templateUrl: 'app/reports/summary/portfolio/components/varianceDisplay/varianceDisplay.html',
        bindings: {
            value: '<',
            up: '<',
            suffix: '@'
        }
    };
    angular.module('aq.reports')
        .component('varianceDisplay', VarianceDisplay);
}
