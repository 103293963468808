'use strict';

angular.module('aq.accounts.calendars')
    .filter('intToTime', function () {
    return function (value, type) {
        var step, hour, minute, partOfDay;
        step = value * 15; // 15 min step
        hour = Math.floor(value/4);
        minute = step - (hour * 60);

        if (hour.toString().length == 1) hour = "0" + hour;
        if (minute.toString().length == 1) minute = "0" + minute;

        if (type == '12hour') {
            if (hour >= 13) hour = hour - 12;

            if(value < 48) {
                partOfDay = "am";
            }
            else {
                partOfDay = "pm";
            }
            return hour + ":" + minute + partOfDay;
        } else {
            if (type == '24hour') {
                if (hour == 24) hour = 0;
                return hour + ":" + minute;
            } else {
                return hour + ":" + minute + ':00';
            }

        }
    };
});
