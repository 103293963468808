namespace aq.propertySettings {
    export class BulkUpdateCollectorsCtrl {
        /* @ngAnnotate */
        constructor(private $scope: BulkUpdateCollectorsCtrlScope, private $mdDialog, private $filter: ng.IFilterService,
                    private collectors: BulkUpdateCollector[], private accountId, private buildingId) {
            $scope.filteredCollectors = collectors;
            $scope.searchText = '';
            $scope.showInstructions = false;
            $scope.accountId = accountId;
            $scope.buildingId = buildingId;
        }

        searchCollectors($event?) {
            if (!$event || ($event && $event.which === 13)) {
                this.$scope.filteredCollectors = _.filter(this.collectors, (c) => {
                    return c.name.toLowerCase().indexOf(this.$scope.searchText.toLowerCase()) > -1;
                });
            }
        }

        populateCollectorsFromCsv() {
            let lines = this.$scope.csvCollectors.split('\n');
            lines.shift();
            this.$scope.filteredCollectors = lines.map(line => {
                let fields = line.split(',');
                if (fields.length === 14) {
                    return {
                        id: parseInt(fields[0]),
                        name: fields[3],
                        timeout: parseInt(fields[13]),
                        multiplier: parseFloat(fields[10])
                    } as any;
                }
            }).filter((c) => {
                return c !== undefined;
            });
        }

        hide() {
            let collectorsToUpdate = this.$scope.filteredCollectors.map((c) => {
                return {
                    id: c.id,
                    multiplier: c.multiplier,
                    timeout: c.timeout,
                    name: c.name
                };
            });
            this.$mdDialog.hide({
                collectors: collectorsToUpdate,
                applyMultiplierToAllData: this.$scope.updateHistoricData
            });
        }

        cancel() {
            this.$mdDialog.cancel();
        }
    }

    class BulkUpdateCollector {
        id: string;
        multiplier: number;
        timeout: number;
        name: string;
    }

    interface BulkUpdateCollectorsCtrlScope extends ng.IScope {
        csvCollectors: string;
        searchText: string;
        updateHistoricData: boolean;
        filteredCollectors: BulkUpdateCollector[];
        showInstructions: boolean;
        buildingId: number;
        accountId: number;
    }

    angular.module('properties.dataCollectors').controller('BulkUpdateCollectorsCtrl', BulkUpdateCollectorsCtrl);
}
