namespace aq.reports {

    export class Docraptor {
        /* @ngInject */
        constructor(private $window, private $timeout) { }

        $onInit() {
            this.$timeout(() => {
                this.$window.doneLoading = true;
            }, 25000);
        }
    }

    angular
        .module('aq.ui.docraptor', [])
        .component('docraptor', {
            bindings: {
                landscape: '<',
                isDocumentReady: '<',
                isDocumentError: '<'
            },
            controller: Docraptor,
            controllerAs: 'vm',
            templateUrl: 'app/common/directives/docraptor/docraptor.html'
        });
}
