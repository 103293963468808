namespace aq.dashboard {
    export interface TemplateLayout {
        editable: boolean;
        hideBorders: boolean;
        structure: string;
        rows: TemplateLayoutRow[];
        titleTemplateUrl: string;
        isCompact: boolean;
    }
    export interface TemplateLayoutRow {
        columns: Object[];
    }
    export interface LayoutView {
        id: number;
        templateLayout: TemplateLayout;
    }
    export interface UserViewItem {
        id: number;
        name: string;
    }
    export class AddEditDashboardCtrl extends aq.common.Controllers.ModalCtrl {
        public templateLayouts: TemplateLayout[];
        public layoutViews: LayoutView[];
        public selectedLayoutView: LayoutView;
        public currentNavItem: string;
        public userViewItems: UserViewItem[];
        public visibilityMode: string;
        private supportedStructures: string[];
        private hideBorders: boolean;
        private isCompact: boolean;
        private autoRefreshMinutes: number;
        private autoRefreshEnabled: boolean;
        private dashboardConfiguration: TemplateLayout; // used during update only
        /* @ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private account: aq.common.models.Account,
            private dashboard: aq.admin.dashboards.Dashboard,
            private loading,
            private Messages: aq.services.Messages,
            private Errors,
            private Restangular,
            private userId: number
        ) {
            super({}, $mdDialog);
            this.supportedStructures = [
                '100',
                '50-50',
                '33-66',
                '50-50/100',
                '33-33-33/100',
                '100/50-50',
                '100/33-33-33',
                '100/50-50/100',
                '25-75(100/50-50)',
                '25-25-25-25/100',
                '20-20-20-20-20/100',
                '100/20-20-20-20-20/100/20-20-20-20-20/100/20-20-20-20-20'
            ];
            this.currentNavItem = 'general';
            this.initLayouts();
            this.initUsers();
            if (!this.dashboard) {
                this.dashboard = {
                    id: null,
                    name: '',
                    account: this.account,
                    users: [userId],
                    building: null,
                    configuration: {
                        json: ''
                    },
                    publicDisplay: {
                        headingImageUrl: ''
                    }
                } as any;
                this.visibilityMode = 'private';
            } else {
                this.dashboardConfiguration = this.dashboard.configuration.json as any; // JSON.parse has already occurred in dashboard resolve
                this.hideBorders = this.dashboardConfiguration.hideBorders;
                this.isCompact = this.dashboardConfiguration.isCompact;
                this.autoRefreshMinutes = this.dashboard.autoRefreshMinutes || 10; // default to ten minute if not set
                this.autoRefreshEnabled = this.dashboard.autoRefreshMinutes != null;
                if (!this.dashboard.users) {
                    this.dashboard.users = [];
                }
                if (this.dashboard.users.length == 0) {
                    this.visibilityMode = 'all';
                } else if (this.dashboard.users.length == 1 && _.first(this.dashboard.users) == this.userId) {
                    this.visibilityMode = 'private';
                } else {
                    this.visibilityMode = 'individual';
                }
            }
        }
        public buildLayoutFromStructure(structure: string): TemplateLayout {

            const structureObject = this.parseStructure(structure);
            const layout: TemplateLayout = {
                editable: false,
                hideBorders: true,
                structure,
                rows: angular.copy(structureObject),
                titleTemplateUrl: '../src/templates/material/dashboard-title.html',
                isCompact: false
            };
            return layout;
        }
        public parseStructure(structure: string): TemplateLayoutRow[] {
            const rows: TemplateLayoutRow[] = [];
            let currentRow: TemplateLayoutRow = { columns: [] };
            let currentColumn: { flex: string, rows?: any[] } = {} as any;

            for (let i = 0; i < structure.length; i++) {
                let currentColumnFlex = '';
                let c = structure[i];
                while (c != '/' && c != '(' && i < structure.length && c != '-') {
                    currentColumnFlex += c;
                    i++;
                    c = structure[i];
                }
                if (c == '-' || i == structure.length) {
                    if (!currentColumn.flex) {
                        currentColumn.flex = currentColumnFlex;
                    }
                    currentRow.columns.push(angular.copy(currentColumn));
                    currentColumn = {} as any;
                    continue;
                }
                if (c == '/') {
                    if (!currentColumn.flex) {
                        currentColumn.flex = currentColumnFlex;
                    }
                    currentRow.columns.push(angular.copy(currentColumn));
                    currentColumn = {} as any;
                    rows.push(angular.copy(currentRow));
                    currentRow = { columns: [] };
                    continue;
                }
                if (c == '(') {
                    currentColumn.flex = currentColumnFlex;
                    i++;
                    let subStructure = '';
                    do {
                        subStructure += structure[i];
                        i++;
                    } while (structure[i] != ')');
                    // recursive
                    const subStructureObj = this.parseStructure(subStructure);
                    currentColumn.rows = angular.copy(subStructureObj);
                    if (i == structure.length - 1) {
                        // string end
                        currentRow.columns.push(currentColumn);
                    }
                }
            }
            rows.push(currentRow);
            return rows;
        }
        public initLayouts() {
            this.templateLayouts = _.map(this.supportedStructures, (structure) => this.buildLayoutFromStructure(structure));
            this.layoutViews = [];
            let counter = 0;
            _.each(this.templateLayouts, (layout) => {
                this.layoutViews.push({
                    id: counter++,
                    templateLayout: layout
                });
            });
        }
        public save() {
            this.loading.start();
            this.dashboard.autoRefreshMinutes = this.autoRefreshEnabled ? this.autoRefreshMinutes : null;
            if (!this.dashboard.id) {
                this.selectedLayoutView.templateLayout.hideBorders = this.hideBorders;
                this.selectedLayoutView.templateLayout.isCompact = this.isCompact;
                this.dashboard.configuration.json = JSON.stringify(this.selectedLayoutView.templateLayout);
                this.dashboard.enabled = true;
                this.create(this.dashboard)
                    .then((newDashboard: any) => {
                        this.loading.stop();
                        this.Messages.success('Created Dashboard');
                        this.$mdDialog.hide(this.getFreshDashboard(newDashboard.id));
                    }, (error) => {
                        this.loading.stop();
                        this.Errors.forCRUD('CREATE')(error);
                        this.$mdDialog.cancel();
                    });
            } else {
                this.dashboardConfiguration.hideBorders = this.hideBorders;
                this.dashboardConfiguration.isCompact = this.isCompact;
                this.dashboard.configuration.json = JSON.stringify(this.dashboardConfiguration);
                this.update(this.dashboard)
                    .then((updatedDashboard) => {
                        this.loading.stop();
                        this.Messages.info('Updated Dashboard');
                        this.$mdDialog.hide(updatedDashboard);
                    }, (error) => {
                        this.loading.stop();
                        this.Messages.error('Error while updating Dashboard');
                        this.$mdDialog.cancel();
                    });
            }
        }
        public hide() {
            this.$mdDialog.hide();
        }
        public cancel() {
            this.$mdDialog.cancel();
        }
        public getFreshDashboard(id): ng.IPromise<restangular.IElement> {
            return this.Restangular.one('Dashboards', id)
                .get()
                .then((dashboard) => {
                    return this.Restangular.restangularizeElement(null, dashboard[0], 'Dashboards');
                });
        }
        public getRowLayouts(rowCount: number) {
            return _.filter(this.layoutViews, (layout: LayoutView) => layout.templateLayout.rows.length == rowCount);
        }
        public importHeadingImageFromAccount() {
            if (this.account.imageUrl) {
                this.dashboard.publicDisplay.headingImageUrl = this.account.imageUrl;
            } else {
                this.Messages.error('No image to import.');
            }
        }
        public initUsers() {
            this.Restangular
                .one('accounts', this.account.id)
                .getList('users')
                .then((data) => {
                    this.userViewItems = _.map(data, (user: aq.common.models.User) => {
                        const item: UserViewItem = {
                            id: user.id,
                            name: user.fullName
                        };
                        return item;
                    });
                });
        }
        public onVisibilityModeChange() {
            let userIds = [];
            switch (this.visibilityMode) {
                case 'all':
                case 'individual':
                    userIds = [];
                    break;
                case 'private':
                    userIds = [this.userId];
                    break;
                default:
                    break;
            }
            this.onSelectedUsersChange(userIds);
        }
        public onIndividualUserSelectorClose() {
            if (this.dashboard.users.length == 1 && _.first(this.dashboard.users) == this.userId) {
                this.visibilityMode = 'private';
                return;
            } else if (this.dashboard.users.length > 0 && _.indexOf(this.dashboard.users, this.userId) == -1) {
                this.dashboard.users.push(this.userId);
            }
            if (this.userViewItems && this.dashboard.users.length == this.userViewItems.length) {
                this.visibilityMode = 'all';
                this.onVisibilityModeChange();
            }
        }
        public onSelectedUsersChange(data: number[]) {
            this.dashboard.users = data;
        }
        private create(dashboard) {
            return this.Restangular.all('Dashboards').post(dashboard);
        }
        private update(dashboard) {
            return dashboard.put();
        }
    }
    angular.module('dashboard').controller('AddEditDashboardCtrl', AddEditDashboardCtrl);
}
