namespace aq.ui {
    export class AqMultiSelectController {
        public selectionModel: any;
        public isRequired: boolean;
        public isDisabled: boolean;
        public items: any[];
        public filteredItems: any[];
        public searchText: string;
        public mode: string;
        public label: string;
        public multiple: any;
        public onCloseDropdown: () => void;
        public onUpdate: (obj: { data: any, context: any }) => void;
        public initSelectionModel: any;
        public context: any;
        constructor() {
        }
        public $onInit() {
            if (this.initSelectionModel) {
                this.selectionModel = angular.copy(this.initSelectionModel);
            }
            if (!this.items) {
                this.items = [];
            }
            this.filteredItems = this.getFilteredItems();
        }
        public $onChanges(changesObj) {
            if (changesObj.initSelectionModel) {
                this.selectionModel = changesObj.initSelectionModel.currentValue;
            }
            if (changesObj.items) {
                this.items = changesObj.items.currentValue;
                this.filteredItems = this.getFilteredItems();
            }
        }
        public onClose() {
            this.searchText = '';
            this.filteredItems = this.getFilteredItems();
            if (this.onCloseDropdown) {
                this.onCloseDropdown();
            }
        }
        public onChange() {
            this.onUpdate({ data: this.selectionModel, context: this.context });
        };
        public mdSelectOnKeyDownOverride(event: ng.IAngularEvent) {
            event.stopPropagation();
        }
        public onSearchChange() {
            this.filteredItems = this.getFilteredItems();
        }
        public clearAll() {
            if (this.multiple === 'true') {
                this.selectionModel = [];
            } else {
                this.selectionModel = null;
            }
            this.onChange();
        }
        public getFilteredItems() {
            const searchText = this.searchText ? this.searchText : '';
            const options = _.filter(this.items, (item) => _.includes(item.name.toLowerCase(), searchText.toLowerCase()));
            return options;
        }
    }
    angular.module('aq.ui').component('aqMultiSelect', {
        controller: AqMultiSelectController,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/aqMultiSelect/aqMultiSelect.html',
        bindings: {
            initSelectionModel: '<',
            context: '<?',
            onUpdate: '&',
            onCloseDropdown: '&?',
            items: '<',
            isRequired: '<?',
            mode: '@?', // when mode is set to 'object', ngModel will hold item object array, otherwise it will hold array of item ids only
            label: '@?',
            multiple: '@?',
            isDisabled: '<?'
        }
    });
}
