'use strict';

angular
    .module('aq.admin.accounts.schema', [])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.admin.accounts.schema', {
                url: '/schema',
                templateUrl: 'app/admin/accounts/schema/main.html',
                controller: 'SchemaCtrl',
                data: {
                    breadcrumb: 'Device Schema',
                    icon: '.aq-icons-device-schema'
                }
            });
    });
