angular
    .module('aq.settings')
    .controller('PlatformGeneralCtrl',
    function ($scope, $window, Restangular, Messages, UserService, Errors, authAccess) {

        $scope.accountSettingsAccess = authAccess['Account Settings'];

        if ($scope.user.id == $scope.user.currentProfile.account.owner) {
            $scope.account.customGET('queryPossibleOwners')
                .then((owners) => {
                    $scope.possibleOwners = owners;
                });
        }
    });
