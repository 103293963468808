angular.module('aq.admin.accounts.schema').controller('SchemaCtrl',
    function($scope, Restangular, loading) {

    loading.start();

    $scope.collectorClasses = [];
    $scope.sortBy = 'displayName';
    $scope.descending = false;

    $scope.changeSort = function(sortBy) {
        if ($scope.sortBy == sortBy) {
            $scope.descending = !$scope.descending;
        } else {
            $scope.sortBy = sortBy;
            $scope.descending = false;
        }
    }

    // TODO: add API once device schema is fully implemented: https://github.com/aquicore/aquicore/issues/1539
    Restangular.one('options', 'collectorClasses').get().then(function(allCollectorClasses) {
        $scope.gatewayCount = 0;
        $scope.collectorCount = 0;
        $scope.connectorCount = 0;
        $scope.manualCount = 0;
        $scope.maxPoints = 0;
        $scope.vendors = [];
        _.each(allCollectorClasses, function (collectorClass) {
            if (collectorClass.gateway) {
                $scope.gatewayCount++;
                collectorClass.classType = "Gateway";
            } else if (_.includes(collectorClass.upwardCommunications, "WEB_CONNECTOR")){
                $scope.connectorCount++;
                collectorClass.classType = "Web Connector"
            } else if (_.includes(collectorClass.upwardCommunications, "MANUAL")){
                $scope.manualCount++;
                collectorClass.classType = "Manual Upload";
            } else {
                $scope.collectorCount++;
                collectorClass.classType = "Collector";
            }

            if (!_.includes($scope.vendors, collectorClass.vendor)) {
                $scope.vendors.push(collectorClass.vendor);
            }
            $scope.collectorClasses.push(_.pick(collectorClass, ['vendor', 'displayName', 'classType']));
        });

        loading.stop();
    });

});