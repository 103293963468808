angular.module('aq.ui.intervalSelector').directive('intervalSelector', function() {
    return {
        restrict : 'E',
        replace: true,
        templateUrl: 'app/common/directives/intervalSelector/intervalSelector.html',
        scope: {
            onChange: '&',
            interval: '=',
            start: '=',
            end: '='
        },
        controller: function ($scope, intervalService) {
            $scope.intervalService = intervalService;
        }
    }
});