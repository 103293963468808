namespace aq.admin {
    export class AdminUsersCtrl {
        /* @ngInject */
        constructor(
            private $scope: AdminUsersCtrlScope,
            private $filter,
            private users,
            private supportTeamRoles,
            private personas,
            private Restangular: restangular.IService,
            private Messages,
            private $mdDialog,
            private ConfirmationService,
            private loading,
            private $state,
            private UserService,
            private Auth,
            private $localStorage,
        ) {
            $scope.isAppRestructure = this.Auth.hasFunctionality('App Restructure');
            $scope.userOrder = 'userName';
            $scope.searchUsers = users.users;
            $scope.search = {
                text: ''
            };
            $scope.personas = this.personas;
            $scope.paging = {
                page: 1,
                limit: 10,
                total: users.count
            };
        }

        showModalDeleteUser = (user): void => {
            this.ConfirmationService.promptForDeletion({
                title: `Delete User: ${user.fullName}`,
                headline: `You are about to delete the user ${user.fullName}`,
                tagline: 'If you are sure you want to delete this user, please type the word DELETE:'
            }).then(() => {
                this.remove(user);
            });
        }

        loginOnBehalfOf(user) {
            this.Restangular.oneUrl('authToken', '/api/v1/users/me/authTokenForUser').get({ userId: user.id })
                .then((authToken) => {
                    this.$localStorage['onBehalfOfUserToken'] = authToken;
                    this.Restangular.setDefaultHeaders({ 'on-behalf-of-token': authToken });
                    this.$scope.user = null;
                    this.UserService.currentUser = null;
                    this.$state.go('aq.home', {}, { reload: true });
                });
        }

        remove = (user): void => {
            this.loading.start();
            this.Restangular.one('users', user.id).remove().then(() => {
                this.Messages.success('User deleted successfully');
                this.loading.stop();
                this.search();
            }, (result) => {
                this.Messages.error('Error while deleting user; ' + captureError(result));
                this.loading.stop();
            });
        }

        search = () => {
            const desc = this.$scope.userOrder.indexOf('-') === 0;
            const orderBy = desc ? this.$scope.userOrder.substring(1) : this.$scope.userOrder;
            this.loading.start(true);
            return this.Restangular.one('users').get({
                page: this.$scope.paging.page,
                pageSize: this.$scope.paging.limit,
                orderBy,
                search: this.$scope.search.text,
                asc: !desc
            }).then((result) => {
                this.$scope.searchUsers = result.users;
                this.$scope.paging.total = result.count;
                this.loading.stop();
            });
        }

        selectItem = (user): void => {
            const firstProfileId = user['profiles'][0] || null;
            this.$mdDialog.show({
                controller: 'AdminUsersDialogCtrl',
                templateUrl: 'app/admin/users/adminUsersDialog.html',
                clickOutsideToClose: true,
                locals: {
                    thisUser: this.Restangular.copy(user),
                    profile: firstProfileId
                        ? this.Restangular.one('Profiles', firstProfileId)
                            .get({ single: true, inflate: 'visibilities,apps,roles,roles.permissions,roles.permissions.app,reports,reports.app' })
                        : null,
                    userTypes: this.Restangular.one('Users', 'queryUserTypes').get(),
                    supportTeamRoles: this.supportTeamRoles,
                    personas: this.personas
                }
            }).then(() => {
                this.search();
            });
        }
    }

    export function captureError(reason): String {
        if (_.isString(reason.data)) {
            return reason.data;
        } else if (reason.data != null && _.isString(reason.data.errorText)) {
            return reason.data.errorText;
        } else {
            return reason.statusText;
        }
    }

    export interface AdminUsersCtrlScope extends ng.IScope {
        userOrder: String;
        searchUsers: any[];
        paging: { page: number, limit: number, total: number };
    }

    angular.module('aq.admin').controller('AdminUsersCtrl', AdminUsersCtrl);
}



