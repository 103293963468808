namespace aq {
    export abstract class OnBehalfOfComponent {
        public currentUser;
        public adminUser;

        /* @ngInject */
        constructor(private $localStorage,
                    private Restangular: restangular.IService,
                    private RestangularV3: restangular.IService,
                    private $state: ng.ui.IStateService,
                    private $cacheFactory: ng.ICacheFactoryService,
                    private UserService) {

        };

        public exitOnBehalfOf() {
            delete this.$localStorage['onBehalfOfUserToken'];
            this.Restangular.setDefaultHeaders({'on-behalf-of-token': null});
            this.RestangularV3.setDefaultHeaders({'on-behalf-of-token': null});
            this.$cacheFactory.get('http').removeAll();
            this.UserService.currentUser = null;
            this.$state.go('aq.admin.users', {}, {reload: true});
        }
    }

    angular.module('aq')
    .component('onBehalfOf', {
        templateUrl: 'app/main/components/onbehalfof/onbehalfof.html',
        bindings: {
            currentUser: '<',
            adminUser: '<'
        },
        controller: OnBehalfOfComponent
    });
}