/**
 * Controls whether or not mocks should be enabled.  Usage:
 *
 * MockContext('mock', () => {
 *  $httpBackend.whenGET(regex).respond((method, url, data) => {
 *      return [200, values, {}];
 *  });
 * })
 * @param mockName - name of the mock
 * @param callback - the function that will execute when mocks are enabled.
 */
function MockContext(mockName, callback) {
    const enabledMocks = window['enabledMocks'] ? window['enabledMocks'].split(',') : [];
    if (_.includes(enabledMocks, mockName)) {
        callback();
    }
}