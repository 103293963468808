namespace aq.energyInsights {
     export interface BaseloadCtrlScope extends ng.IScope {
        selectedMonthBaseload: MonthBaseloadStats;
        selectedMonthBaseloadStats: MonthBaseloadStats[];
        questionModel: QuestionAnswerModel;
        monthBaseloadStats: MonthBaseloadStats[];
        monthBaseloadChartConfig: __Highcharts.Options;
        monthBaseloadChart: __Highcharts.ChartObject;
        dayBaseloadStats: DayBaseloadStats[];
        dayBaseloadChartConfig: __Highcharts.Options;
        periodSearch: PeriodSearch;
        drillUp?: {start: any, end: any};
        getPercent: Function;
        targetRatio: number;
        targetGoodDays: number;
        monthTotals: MonthBaseloadSummary;
        dayTotals: DayBaseloadSummary;
    }

    export class MonthBaseloadSummary implements MonthBaseloadStats{
        numGoodDays: number;
        numBadDays: number;
        avgRatio: number;
        avgBaseload: number;
    }

    export class DayBaseloadSummary implements DayBaseloadStats{
        ratio: number;
        baseload: number;
        topload: number;
    }

    export interface BaseloadStats {
        corey?: boolean;
        date?: number;
        point?: __Highcharts.DataPoint;
    }

    export interface MonthBaseloadStatsResult {
        months?: MonthBaseloadStats[];
    }

    export interface MonthBaseloadStats extends BaseloadStats {
        numGoodDays?: number;
        numBadDays?: number;
        avgRatio?: number;
        avgBaseload?: number;
        days?: DayBaseloadStats[];
    }

    export interface DayBaseloadStats extends BaseloadStats{
        ratio?: number;
        baseload?: number;
        topload?: number;
    }
}