let WIND_DIRECTIONS = ["N","NNE","NE","ENE","E","ESE","SE","SSE","S","SSW","SW","WSW","W","WNW","NW","NNW","N"];

// Convert from degrees (0 is true north) to directions, N/S/E/W/etc.
// Solution adapted from https://www.campbellsci.com/blog/convert-wind-directions
angular.module('aq.dashboard.widgets').filter('direction', function() {
    return function(degrees: number) {
        let index = degrees % 360;
        index = Math.round(index / 22.5);
        let direction = WIND_DIRECTIONS[index];
        return direction;
    }
});