namespace aq.utilityBudgets {

    export class UrjanetService {
        private currencyUnitSymbol;
        /* @ngInject */
        constructor(
            private OptionsService,
            private $filter
        ) {
            this.currencyUnitSymbol = this.OptionsService.currencyUnit().symbol;
        }

        public getTotalCharge(statement, service: UtilityService): number {
            const meters = statement.json;
            if (meters) {
                let totalCharge = 0;
                meters.forEach((meter) => {
                    if (_.includes(this.getUrjanetServiceTypes(service), meter['ServiceType'])) {
                        const charge = this.findMeterCharge(meter);
                        if (charge) {
                            totalCharge += charge;
                        }
                    }
                });
                return this.$filter('formatQuantity')(totalCharge, this.currencyUnitSymbol);
            }
        }

        public getStartDate(statement): string {
            if (statement && statement.json && statement.json[0]) {
                return this.transformDateForBill(statement.json[0]['StartDate']);
            }
            return null;
        }

        public getEndDate(statement): string {
            if (statement && statement.json && statement.json[0]) {
                return this.transformDateForBill(statement.json[0]['EndDate']);
            }
            return null;
        }

        public getInvoiceDate(statement): string {
            if (statement.json && statement.json[0]) {
                return this.transformDateForBill(statement.json[0]['StatementDate']);
            }
            return null;
        }

        public getStartDateView(statement): string {
            if (statement && statement.json && statement.json[0]) {
                return this.transformDateForView(statement.json[0]['StartDate']);
            }
            return null;
        }

        public getEndDateView(statement): string {
            if (statement && statement.json && statement.json[0]) {
                return this.transformDateForView(statement.json[0]['EndDate']);
            }
            return null;
        }

        public getSourceUrl(statement): string {
            if (statement.json && statement.json[0]) {
                return statement.json[0]['Url'];
            }
            return null;
        }
        public getUsage(statement, service: UtilityService): number {
            const meters = statement.json;
            if (meters) {
                let totalUsage = 0;
                let usageUnit = null;
                meters.forEach((meter) => {
                    if (_.includes(this.getUrjanetServiceTypes(service), meter['ServiceType'])
                        && meter['ServiceType'] !== 'sewer') {
                        const usageObj = this.findMeterUsage(meter);
                        if (usageObj && usageObj.usage) {
                            totalUsage += usageObj.usage;
                            usageUnit = usageObj.unit;
                        }
                    }
                });

                return this.$filter('formatQuantity')(totalUsage, usageUnit);
            }
        }
        public getDemand(statement, service: UtilityService): number {
            if (service.type == 'ELECTRICITY') {
                const meters = statement.json;
                if (meters) {
                    let totalDemand = 0;
                    let demandUnit = null;
                    meters.forEach((meter) => {
                        const demandObj = this.findMeterDemand(meter);
                        if (demandObj && demandObj.demand && demandObj.demand > totalDemand) {
                            totalDemand = demandObj.demand;
                            demandUnit = demandObj.unit;
                        }
                    });
                    return this.$filter('formatQuantity')(totalDemand, demandUnit);
                }
            }
            return null;
        }

        public getDemandUnit(statement, service: UtilityService): string {
            let unit = null;
            if (service.type == 'ELECTRICITY') {
                const meters = statement.json;
                if (meters) {
                    meters.forEach((meter) => {
                        const demandObj = this.findMeterDemand(meter);
                        if (demandObj && demandObj.unit) {
                            unit = demandObj.unit;
                        }
                    });
                }
            }
            return unit;
        }

        public getUsageUnit(statement, service: UtilityService): string {
            const meters = statement.json;
            let unit = null;
            if (meters) {
                meters.forEach((meter) => {
                    if (_.includes(this.getUrjanetServiceTypes(service), meter['ServiceType'])) {
                        const usageObj = this.findMeterUsage(meter);
                        if (usageObj && usageObj.unit) {
                            unit = usageObj.unit;
                        }
                    }
                });
            }
            return unit;
        }

        public getMeters(statement, service: UtilityService): UrjanetMeterViewItem[] {
            const meters = statement.json;
            if (meters) {
                const viewMeters: aq.utilityBudgets.UrjanetMeterViewItem[] = [];
                meters.forEach((meter) => {
                    if (_.includes(this.getUrjanetServiceTypes(service, true), meter['ServiceType'])) {
                        const usage = this.findMeterUsage(meter);
                        const demand = this.findMeterDemand(meter);
                        const viewMeter: aq.utilityBudgets.UrjanetMeterViewItem = {
                            meterNumber: meter['MeterNumber'],
                            serviceType: meter['ServiceType'],
                            startDate: meter['StartDate'] ? this.transformDateForView(meter['StartDate']) : null,
                            endDate: meter['EndDate'] ? this.transformDateForView(meter['EndDate']) : null,
                            usage: usage ? this.$filter('formatUsage')(usage.usage, usage.unit) : null,
                            demand: demand ? this.$filter('formatUsage')(demand.demand, demand.unit) : null,
                            charge: this.findMeterCharge(meter)
                        };
                        viewMeters.push(viewMeter);
                    }
                });
                return viewMeters;
            }
        }

        private getUrjanetServiceTypes(service: UtilityService, includeAll: boolean = false): string[] {
            switch (service.type) {
                case 'GAS':
                    return ['natural_gas'];
                case 'ELECTRICITY':
                    return ['electric'];
                case 'WATER':
                    if (service.includeSewer || includeAll) {
                        return ['water', 'sewer'];
                    } else {
                        return ['water'];
                    }
                default:
                    return [_.toLower(service.type)];
            }
        }

        private findMeterCharge(meter): number {
            try {
                let charge = parseFloat(meter['TotalNewCharges']);
                charge = this.$filter('formatQuantity')(charge, this.currencyUnitSymbol);
                return charge;
            } catch {
                return null;
            }
        }

        private transformDateForBill(dateValue) {
            return dateValue ? moment(dateValue).format('YYYY-MM-DD HH:mm:ss') : null;
        }

        private transformDateForView(dateValue) {
            return dateValue ? moment(dateValue).format('YYYY-MM-DD') : null;
        }

        private findMeterUsage(meter) {
            try {
                const csvConsumption = parseFloat(meter['Consumption']);
                const csvUnit = meter['ConsumptionUnit'];
                return {
                    usage: csvConsumption,
                    unit: csvUnit
                };
            } catch (e) {
                return null;
            }
        }

        private findMeterDemand(meter) {
            if (meter['ServiceType'] == 'electric') {
                try {
                    const csvDemand = parseFloat(meter['Demand']);
                    const csvUnit = meter['DemandUnit'];
                    return {
                        demand: csvDemand,
                        unit: csvUnit
                    };
                } catch (e) {
                    return null;
                }
            }
            return null;
        }
    }
    angular.module('aq.utilityBudgets').service('UrjanetService', UrjanetService);
}
