namespace aq.energyInsights {
    angular.module('energyInsights').directive('aqQuestionAnswer', () => {
        return {
            restrict: 'E',
            transclude: true,
            replace: true,
            scope: {
                model: "="
            },
            templateUrl: 'app/energyInsights/directives/questionAnswer/questionAnswer.html',
            controller: ($scope, intervalService) => {
                $scope.getIcon = function(status) {
                    if(status === 'under-budget') {
                        return 'check_box';
                    } else if(status === 'warn-budget') {
                        return 'warning';
                    } else if(status === 'over-budget') {
                        return 'error';
                    }
                }
            }
        }
    });
    export interface QuestionAnswerModel {
        question?: string;
        answer?: string | number;
        unit?: string;
        when?: string;
        tooltip?: any;
        color?:string;
    }
}