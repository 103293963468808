angular.module('meterManagement').controller('MeterManagementCtrl',
    function($location, $scope, $state, accountAndBuildings, $q) {

        // ideally admin will be somewhere else.  will likely move before this is over.
        if ($state.is('aq.meterManagement')) {
            if ($state.params.accountId) {
                $state.transitionTo('aq.meterManagement.status', {accountId: $state.params.accountId}, {location: 'replace'});
            } else {
                $state.go('aq.meterManagement.admin');
            }
        }

        $scope.onOptionsLoad = function(data) {
            $scope.initialOptions = data.options;
            var buildingFilter = _.find($scope.initialOptions, {'name': 'building'});
            buildingFilter.items = _.map(accountAndBuildings.buildings, 'name');
            return $q.when($scope.initialOptions)
        };

    });
