namespace aq.propertySettings {
    const EQ_TEST_VAL = '5.3';
    angular.module('properties.dataCollectors').directive('virtualMeterMention', () => {
        return {
            require: ['uiMention', 'ngModel'],
            scope: {
                collectors: '=',
                newCollector: '=',
                selectedMetric: '=',
                ngModel: '='
            },
            link($scope: any, $element, $attrs, controllers) {
                const uiMentionCtrl = controllers[0];
                const modelCtrl = controllers[1];

                if ($scope.newCollector.virtualMeterEquation && $scope.newCollector.virtualMeterEquation.collectors) {
                    $scope.newCollector.virtualMeterEquation.collectors.forEach((collectorId) => {
                        const fullCollector: any = _.find($scope.collectors, {id: collectorId});
                        uiMentionCtrl.mentions.push(fullCollector);
                        const collectorStr = `Collector[${fullCollector.id}]`;
                        const updatedCollectorStr = `@[${fullCollector.name}:${fullCollector.id}]`;
                        $scope.ngModel = $scope.ngModel.split(collectorStr).join(updatedCollectorStr);
                    });
                }

                uiMentionCtrl.label = function (collector) {
                    return collector.name;
                };

                uiMentionCtrl.findChoices = function (match, mentions) {
                    let choices = $scope.collectors.filter((collector) => {
                        return collector.name && collector.name.toLowerCase().indexOf(match[1].toLowerCase()) > -1;
                    });
                    if ($scope.selectedMetric) {
                        choices = choices.filter((collector) => {
                            return collector.metrics.indexOf($scope.selectedMetric) > -1;
                        });
                    }
                    return choices;
                };
                modelCtrl.$validators.javascript = (modelValue, viewValue) => {
                    if (modelCtrl.$isEmpty(modelValue)) {
                        return true;
                    }
                    try {
                        let equation = modelValue;
                        uiMentionCtrl.mentions.forEach((collector) => {
                            const stringToReplace = '@[' + collector.name + ':' + collector.id + ']';
                            equation = equation.split(stringToReplace).join(EQ_TEST_VAL);
                        });
                        eval(equation);
                    } catch (err) {
                        return false;
                    }
                    return true;
                };
                $scope.$watch('ngModel', (value) => {
                    if (!value) {
                        return;
                    }
                    let virtualMeterEquation = value;
                    uiMentionCtrl.mentions.forEach(function(collector) {
                        const stringToReplace = '@[' + collector.name + ':' + collector.id + ']';
                        virtualMeterEquation = virtualMeterEquation.split(stringToReplace).join('Collector[' + collector.id + ']');
                    });

                    const virtualMeterCollectors = uiMentionCtrl.mentions.map((collector) => {
                        return collector.id;
                    });

                    $scope.newCollector.virtualMeterEquation = {
                        equation: virtualMeterEquation,
                        collectors: virtualMeterCollectors
                    };
                });
            }
        };
    });
}
