angular.module('aq').controller('BuildingsListCtrl', function ($scope, $state) {

    $scope.currentBuildingId = $state.params.buildingId;

    $scope.localSelectBuilding = function(building) {
        $scope.selectBuilding(building);
        var id = building.id;
        if ($state.is('aq.buildingChooser')) {
            $state.transitionTo($state.params.originState, { accountId: $scope.account.id, buildingId: id });
        } else {
            var state = $state.current.data.redirectState || $state.current.name;
            $scope.$hide();
            $state.transitionTo(state, { accountId: $state.params.accountId, buildingId: id });
        }
    };

});