declare const CryptoJS: any;
/*
 * AqUI - UI routines
 */
angular.module('aq.ui.color', []).factory('aqColors', function () {

    var allChartColors = [
        "#3182bd", "#6baed6", "#9ecae1", "#c6dbef",
        "#e6550d", "#fd8d3c", "#fdae6b", "#fdd0a2",
        "#31a354", "#74c476", "#a1d99b", "#c7e9c0",
        "#756bb1", "#9e9ac8", "#bcbddc", "#dadaeb",
        "#636363", "#969696", "#bdbdbd", "#d9d9d9",
        "#393b79", "#5254a3", "#6b6ecf", "#9c9ede",
        "#637939", "#8ca252", "#b5cf6b", "#cedb9c",
        "#8c6d31", "#bd9e39", "#e7ba52", "#e7cb94",
        "#843c39", "#ad494a", "#d6616b", "#e7969c",
        "#7b4173", "#a55194", "#ce6dbd", "#de9ed6"
    ];

    function getChartColorsFromTheme(count, colorRetriever) {

        var colorsResult = [];

        while (colorsResult.length < count) {
            colorsResult.push(colorRetriever(colorsResult.length));
        }

        return colorsResult;
    }

    function getThemeChartColors(count: number) {

        if (!count) {
            return allChartColors;
        }

        return getChartColorsFromTheme(count, function (n: number) {
            const offset = ((count / n)) - 1;
            return allChartColors[offset.toFixed()];
        });
    }

    function hsv2rgb(h: number, s: number, v: number) {
        s = s / 100;
        v = v / 100;
        var hi = Math.floor((h / 60) % 6);
        var f = (h / 60) - hi;
        var p = v * (1 - s);
        var q = v * (1 - f * s);
        var t = v * (1 - (1 - f) * s);
        var rgb = [];
        switch (hi) {
            case 0:
                rgb = [v, t, p];
                break;
            case 1:
                rgb = [q, v, p];
                break;
            case 2:
                rgb = [p, v, t];
                break;
            case 3:
                rgb = [p, q, v];
                break;
            case 4:
                rgb = [t, p, v];
                break;
            case 5:
                rgb = [v, p, q];
                break
        }
        return [Math.min(255, Math.floor(rgb[0] * 256)), Math.min(255, Math.floor(rgb[1] * 256)),
            Math.min(255, Math.floor(rgb[2] * 256))];
    }

    function hex(x, n) {
        var leadingZeroes = Array(n).join('0');
        return (leadingZeroes + x.toString(16)).substr(-n);
    }

    function hsl2hex(h, s, l) {
        var rgb = hsv2rgb(h, s, l),
            r = hex(rgb[0], 2),
            g = hex(rgb[1], 2),
            b = hex(rgb[2], 2);
        return '#' + r + g + b;
    }

    function getChartColors(count, theme) {

        var themeFunc = {
            'default': function (angle) {
                return hsl2hex(angle, 70, 80)
            },
            'kid': function (angle) {
                return hsl2hex(angle, 100, 100)
            },
            'dark': function (angle) {
                return hsl2hex(angle, 100, 40)
            },
            'cool': function (angle) {
                return hsl2hex(angle < 180 ? angle + 180 : angle - 90, 70, 80)
            },
            'bw': function (angle) {
                return hsl2hex(0, 0, (angle / 3.6).toFixed())
            }
        }

        if (!theme || !themeFunc[theme]) {
            theme = 'default';
        }

        return getChartColorsFromTheme(count, function (n) {
            var step = 360 / count,
                angle = (n * step).toFixed();
            return themeFunc[theme](angle);
        });
    }

    function getChartColorsAlternating(count, theme) {
        var i, tmp,
            colors = getChartColors(count, theme),
            halfLength = (colors.length / 2).toFixed();
        for (i = 0; i < halfLength; i += 2) {
            tmp = colors[i];
            colors[i] = colors[halfLength + i];
            colors[halfLength + i] = tmp;
        }
        return colors;
    }

    function getForegroundColor(backgroundColor, light, dark) {
        var r = parseInt(backgroundColor.substr(1, 2), 16),
            g = parseInt(backgroundColor.substr(3, 2), 16),
            b = parseInt(backgroundColor.substr(5, 2), 16),
            brightnessFactor = r * g * b,
            isBrighterThanAverage = brightnessFactor > 0xffffff / 2;

        return isBrighterThanAverage ? dark : light;
    }

    function getMostContractingColor(color) {
        return getForegroundColor(color, "#ffffff", "#000000");
    }

    // Not ideal for similar named items
    var simpleHash = function (str) {
        var hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + (hash << 6) + (hash << 16) - hash;
        }
        return hash;
    }

    // Not ideal for similar named items
    // MD5 can be used https://github.com/wbond/md5-js/blob/master/md5.js
    var sha1Hash = function (str) {
        return CryptoJS.SHA1(str).toString(CryptoJS.enc.Hex);
    }

    // implements the Dopplr color generation algorithm
    // http://blog.dopplr.com/2007/10/23/in-rainbows/
    function getUniqueColorFromName(name) {
        var hashFunc = typeof(CryptoJS) !== 'undefined' ? sha1Hash : simpleHash,
            hex6 = hex(hashFunc(name), 6);
        return '#' + hex6.split("").reverse().join("");
    }
    
    function colorLuminance(hex, lum) {

        // validate hex string
        hex = String(hex).replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6) {
            hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
        }
        lum = lum || 0;

        // convert to decimal and change luminosity
        var rgb = "#", c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i*2,2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ("00"+c).substr(c.length);
        }

        return rgb;
    }

    return {
        getThemeChartColors: getThemeChartColors,
        getChartColors: getChartColors,
        getChartColorsAlternating: getChartColorsAlternating,
        getUniqueColorFromName: getUniqueColorFromName,
        getMostContractingColor: getMostContractingColor,
        getForegroundColor: getForegroundColor,
        colorLuminance: colorLuminance
    }
});
