namespace aq.user {
    export class RegisterCtrl {
        private confirmPassword: string;
        private userReg: common.models.UserCreateRequest = <common.models.UserCreateRequest>{};
        private titleSearchText = '';
        private titles: string[];
        private usernamePattern = /^[a-zA-Z0-9]*$/;
        private phoneNumberPattern = /^\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$/;
        public phoneNumber: string;
        private isNewUser: boolean;
        /* ngInject */
        constructor(
            private Messages: aq.services.Messages,
            private $state: ng.ui.IStateService,
            private RegistrationService: aq.services.registration.RegistrationService,
            private loading,
            private $window,
            private $location: ng.ILocationService,
            private Restangular,
            private $timeout,
            private $scope,
            private AuthorizationService: aq.services.AuthorizationService,
            private initUser: aq.user.User
        ) {
            const qp = this.$location.search();
            this.$scope.email=initUser.email;
            this.RegistrationService.queryInvitedUser(initUser.email).then((user) => {
                if (!user) {
                    return;
                }
                if (user.userCreated != null) {
                    let loginUrl = '/login';
                    if (qp.enrollmentId) {
                        loginUrl += '?enrollmentId=' + qp.enrollmentId;
                    }
                    this.$window.location = loginUrl;
                } else {
                    this.userReg.email = user.email;
                    this.userReg.firstName = user.firstName;
                    this.userReg.lastName = user.lastName;
                    this.userReg.phoneNumber = user.phoneNumber;
                    this.userReg.title = user.title;
                    this.isNewUser = true;
                }
            });
            this.titles = [
                'Accounting',
                'Building Engineer',
                'Asst. Building Engineer',
                'Chief Engineer',
                'Operations Manager',
                'Property Manager',
                'Asst. Property Manager',
                'Asset Manager',
                'IT'
            ];
        }

        public getUserReg() {
            return this.userReg;
        }

        public clearImage() {
            this.userReg.image = null;
        }

        public onImageUpload() {
            this.userReg.image = this.userReg.imageUrl;
            delete this.userReg.imageThumbnailUrl;
            delete this.userReg.imageUrl;
        }

        public setState(name, params) {
            this.$state.transitionTo(name, params);
        }

        public registerUser() {
            this.loading.start();
            this.RegistrationService.registerUser(this.userReg)
                .then(() => {
                    const params = this.$location.search();
                    if (params.enrollmentId) {
                        return this.autoLoginUser().then(() => {
                            this.Messages.info('Please wait while logging in...');
                            this.$state.go('aq.enrollment', { enrollmentId: params.enrollmentId }, { reload: true });
                        });
                    } else {
                        this.setState('aq.registration.welcome', params);
                    }
                })
                .catch(() => {
                    this.Messages.error('Unable to complete registration. Please contact support@aquicore.com');
                })
                .finally(() => {
                    this.loading.stop();
                });
        }

        public queryTitles(query: string) {
            return this.titles.filter((title) => {
                return title.toLowerCase().indexOf(query.toLowerCase()) > -1;
            });
        }

        public selectedTitleChange(title: string) {
            this.userReg.title = title;
        }

        public newTitle(title: string) {
            this.userReg.title = title;
        }

        public setPhoneNumber() {
            if (!this.phoneNumberPattern.test(this.phoneNumber)) {
                this.userReg.phoneNumber = '';
                return;
            }
            this.userReg.phoneNumber = this.phoneNumber.replace(this.phoneNumberPattern, '$1$2$3');
        }

        private autoLoginUser() {
            const loginData = {
                user: this.userReg.userName,
                password: this.userReg.password,
                remember: false
            };
            return this.Restangular
                .all('session')
                .customPOST(loginData, 'login');
        }
    }

    angular.module('registration').controller('RegisterCtrl', RegisterCtrl);
}
