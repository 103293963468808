namespace aq.projectCenter {
    export class ProjectOverviewListItemComponent {
        public project: Project;

        /* @ngInject */
        constructor() {}
    }
    angular.module('projectCenter')
        .component('projectOverviewListItem', {
            templateUrl: 'app/projectCenter/overview/projectOverviewListItem.html',
            bindings: {
                project: '<'
            },
            controller: ProjectOverviewListItemComponent,
            controllerAs: 'vm'
        });
}
