class LoginCtrl {
    private error: string;

    /* @ngInject */
    constructor(
        private AuthorizationService: aq.services.AuthorizationService,
        private $window: ng.IWindowService,
        private $location: ng.ILocationService
    ) {
        this.AuthorizationService.getClientMeta().then((meta) => {
            // @ts-ignore
            const lock = new Auth0Lock(meta.client_id, meta.domain, {
                container: 'root',
                auth: {
                    redirect: true,
                    responseType: 'token',
                    params: {
                        scope: meta.scope // Learn about scopes: https://auth0.com/docs/scopes
                    },
                },
                configurationBaseUrl: 'https://cdn.auth0.com',
                languageDictionary: { title: '' },
                theme: {
                    logo: `${$window.location.origin}/img/brand/full-color-logomark.png`,
                    primaryColor: '#0496f7'
                },
            });
            lock.show();
            lock.on('authenticated', this.login);
            lock.on('hash_parsed', this.login);
        });
    }

    private login = (token) => {
        return token && token.accessToken && this.seedBackendSession(token.accessToken);
    };

    private seedBackendSession = (accessToken: string): void => {
        this.AuthorizationService.seedSession(accessToken)
            .catch((err: Error) => {
                this.error = err.message;
            });
    };
}

angular
    .module('login')
    .controller('LoginCtrl', LoginCtrl);
