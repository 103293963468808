/**
 * Issue with select2 required validation on a multiselect field is that
 * the model value is equal to an empty array.  This directive checks for empty
 * array and then marks the model invalid.
 */
angular.module('aq.ui').directive('select2ValidationRequired', function () {
    return {
        restrict: 'A',
        require: "ngModel",
        link: function ($scope, $element, $attrs, modelCtrl: ng.INgModelController) {
            var isRequired = false;
            $scope.$watch($attrs.ngRequired, function(required) {
                isRequired = !!required;
            });
            modelCtrl.$validators.selectRequired = function(modelValue, viewValue) {
                if(isRequired && _.isArray(modelValue)) {
                    return modelValue.length > 0;
                }
                return true;
            }
        }
    }
});

