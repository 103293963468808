angular
    .module('aq.reports')
    .directive('reportStack', () => {
        return {
            restrict: 'E',
            replace: false,
            templateUrl: 'app/reports/summary/project/directives/reportStack.html',
            scope: {
                aqData: '='
            },
            link($scope, element, $attrs) {
                $scope.$watch('aqData', (aqData: any) => {

                    Highcharts.setOptions({
                        lang: {
                            thousandsSep: ','
                        }
                    });

                    if (aqData) {
                        const series = _.map(aqData.data, (item) => {
                            return {
                                data: [item.value],
                                color: item.color,
                                name: item.name,
                                stack: 0
                            };
                        });
                        $scope.plotOptions = {
                            chart: {
                                plotBackgroundColor: null,
                                plotBorderWidth: null,
                                plotShadow: false,
                                type: 'column',
                                inverted: true,
                                height: 100,
                                width: aqData.options && aqData.options.width ? aqData.options.width : 160
                            },
                            title: {
                                text: ''
                            },
                            xAxis: {
                            },
                            yAxis: {
                                title: {
                                    text: ''
                                },
                                tickInterval: aqData.options.step || 1
                            },
                            legend: { enabled: false },
                            exporting: {
                                enabled: false
                            },
                            series: _.reverse(series),
                            plotOptions: {
                                column: {
                                    stacking: 'normal',
                                    dataLabels: {
                                        enabled: false
                                    }
                                }
                            }
                        };
                    }
                });
            }
        };
    });
