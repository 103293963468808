angular.module('aq.ui.listSelector', []).directive('listSelector', function() {
    return {
        restrict : 'E',
        replace: true,
        transclude: true,
        templateUrl: 'app/common/directives/listSelector/listSelector.html',
        scope: {
            title: '@title',
            onClickParamName: '=onClickParamName',
            hideAddListItem: '=',
            addListItem: '&'
        },
        controller: function($scope, Auth) {
            $scope.hasAccess = false;
            if (Auth.check({access: 'FULL_ACCESS'})) {
                $scope.hasAccess = true;
            }

        }
    }
});
