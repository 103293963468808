'use strict';
angular.module('properties').directive('collector', function () {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            collector: '=',
            setup: '&',
            addChild: '&',
            showAddChild: '&',
            status: '=',
            energyStar: '<?',
            access: '<?'
        },
        templateUrl: 'app/properties/common/collector/collector.html',
        controller: function ($scope, $timeout, $tooltip, $element, $filter) {

            $scope.isConnected = true;

            $scope.$watch('collector', function (collector) {
                if (!collector) return;

                $scope.type = collector.type.toLowerCase();
            })

            $scope.getMarginOffset = function () {
                if ($scope.type == 'collector') {
                    var result = (78 * ($scope.collector.childCollectors.length - 1)) + 10;
                    return result > 0 ? result : 0;
                }
            }

            $scope.showAddChildModal = function () {
                $scope.showAddChild({ parentCollector: $scope.collector });
            }

            $scope.addCollector = function (parentCollector, newCollector, dismiss) {
                $scope.addChild({ parent: $scope.collector, child: newCollector, dismiss: dismiss });
            }

        }
    }
});
