namespace aq.publicDisplay {
    export class DisplayCtrl {
        private currentRefresh: ng.IPromise<any>;
        /* @ngInject */
        constructor(
            private $scope: DisplayCtrlScope,
            private $rootScope,
            private Messages,
            private dashboard,
            private account,
            private building,
            private $interval: ng.IIntervalService,
            private UserService,
            private Auth: aq.services.Auth,
        ) {
            // FIXME see frontend/app/publicDisplay/configurable/PublicDisplayConfigurableCtrl.ts for details
            aq.dashboard.scope.setPassThroughScope(dashboard, {
                account,
                building,
                publicDisplay: dashboard.publicDisplay,
                titleHeadingTemplateUrl: 'app/publicDisplay/adfTemplates/addons/title-heading.html'
            });
            this.$scope.titleTemplateUrl = 'app/dashboard/adfTemplates/dashboard-title.html';
            this.$scope.dashboard = dashboard;
            this.$scope.name = dashboard.name;
            this.$scope.account = account;

            UserService.initLanguage();

            if (this.$scope.dashboard.autoRefreshMinutes) {
                this.currentRefresh = $interval(() => {
                    $scope.$broadcast('widgetReload');
                }, this.$scope.dashboard.autoRefreshMinutes * 60 * 1000);

                $scope.$on('$destroy', () => {
                    this.$interval.cancel(this.currentRefresh);
                });
            }
        }
    }

    export interface DisplayCtrlScope extends ng.IScope {
        name: string;
        dashboard: any;
        account: any;
        titleTemplateUrl: string;
    }

    angular.module('aq.publicDisplay.display').controller('DisplayCtrl', DisplayCtrl);
}
