'use strict';

angular.module('aq.accounts.calendars')
    .service('CalendarData', function () {

    this.allWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    this.workDays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];
    this.workCalendarRules = null;

    this.daysOfWeek = [
        { 'day': 'SUNDAY', 'selected': false },
        { 'day': 'MONDAY', 'selected': false },
        { 'day': 'TUESDAY', 'selected': false },
        { 'day': 'WEDNESDAY', 'selected': false },
        { 'day': 'THURSDAY', 'selected': false },
        { 'day': 'FRIDAY', 'selected': false },
        { 'day': 'SATURDAY', 'selected': false }
    ];

    this.newRule = {
        name: 'New Rule',
        activeState: true,
        dateEnd: null,
        dateStart: null,
        isPublic: false,
        isDefault: true,
        priority: 1,
        timeStart: '09:00:00',
        timeEnd: '17:00:00',
        week: this.workDays
    };

});
