namespace aq.dashboard.widgets {

    export class OptimalStartCtrl {
        public optimalStart: aq.models.optimalStart.OptimalResponse;
        private building: aq.common.models.Building;
        private account: aq.common.models.Account;
        private nextDayStr: string;
        private currentDayStr: string;
        private prevDayStr: string;
        private showActual: boolean = true;
        private showTarget: boolean = true;
        private showActions: boolean;

        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private $translate,
            private UserService,
            public $window: ng.IWindowService
        ) {
        }

        public $onInit() {
            this.prevDayStr = this.getDay(this.optimalStart.prevdate);
            this.currentDayStr = this.getDay(this.optimalStart.currentdate);
            this.nextDayStr = this.getDay(this.optimalStart.nextdate);
            if (this.optimalStart.isenrolledost
                && !this.isValidDateString(this.optimalStart.currentactual)
                && !this.isValidDateString(this.optimalStart.currenttarget)
            ) {
                this.showActual = false;
            }

            // turn off targets when not enrolled, or when there is no "nextdate" value
            if (!this.optimalStart.isenrolledost || this.nextDayStr === null) {
                this.showTarget = false;
            }
            this.showActions = this.isValidDateString(this.optimalStart.currentactual);
        }

        private goToOptimalStartConfig(): void {
            const buildingId = this.building.id;
            this.account.get({ single: true }).then((thisAccount) => {
                this.$window.location.href = `/accounts/${thisAccount.id}/property-settings/buildings/${buildingId}/building/optimal-start`;
            });
        }

        private getFormattedStartTime(dateStr: string): string {
            const dateFormat = 'h:mm a';
            if (!dateStr) {
                return null;
            }

            if (!this.building.timeZoneId) {
                return moment(dateStr).format(dateFormat);
            }

            return moment(dateStr).tz(this.building.timeZoneId).format(dateFormat);
        }

        private isValidDateString(value: string) {
            return value && moment(value).isValid();
        }

        private getDay(dateStr: string) {
            if (!dateStr || !moment(dateStr).isValid()) {
                return null;
            }
            const time = moment(dateStr);
            return time.format('ddd MMM D, YYYY');
        }

        private getTargetStartString(): string {
            if (!this.optimalStart.nexttarget) {
                return 'Still Calculating. Check back later!';
            }
            return this.getFormattedStartTime(this.optimalStart.nexttarget);
        }

        private getSuggestedTimeColor(): string {
            if (!this.isValidDateString(this.optimalStart.currentactual) || !this.isValidDateString(this.optimalStart.currenttarget)) {
                return null;
            }
            const mActual = moment(this.optimalStart.currentactual);
            const mTarget = moment(this.optimalStart.currenttarget);
            const timeDiff = Math.abs(mTarget.diff(mActual, 'minutes'));
            if (timeDiff === 0) {
                return 'evergreen';
            } else if (timeDiff > 0 && timeDiff <= 30) {
                return 'yellow';
            }
            return 'red';
        };
    }

    export const OptimalStart: ng.IComponentOptions = {
        controller: OptimalStartCtrl,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/common/widgets/optimalStart/optimalStartView.html',
        bindings:  {
            optimalStart: '<',
            building: '<',
            account: '<'
        }
    };

    angular
        .module('aq.dashboard.widgets')
        .component('optimalStart', OptimalStart);
}
