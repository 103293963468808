namespace aq.ui {
    export class EmailModalCtrl {
        public readonly emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        public email: string;
        constructor(
            protected $mdDialog: ng.material.IDialogService,
        ) {
        }

        public validateEmail() {
            return this.email && this.emailRegex.test(String(this.email).toLowerCase());
        }

        public cancel(rejectData?: any) {
            this.$mdDialog.cancel(rejectData);
        }

        public add() {
            this.$mdDialog.hide(this.email);
        }
    }
    angular.module('aq.ui').controller('EmailModalCtrl', EmailModalCtrl);
}

