'use strict';

angular
    .module('aq.admin.reports.performancereport', [])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.admin.reports.performancereport', {
                url: '/performancereport',
                templateUrl: 'app/admin/reports/performancereport/performancereport.html',
                controller: 'PerformanceReportCtrl as vm',
                resolve: {
                    accounts(Restangular: restangular.IService, waitForAuthToken) {
                        return Restangular.all('accounts').get('queryAllAccounts');
                    }
                },
                data: {
                    breadcrumb: 'Performance Report',
                    icon: '.aq-icons-charts'
                }
            });
    });
