namespace aq.reportCenter {
    export class ReportRecipientsCtrl extends aq.common.Controllers.ModalCtrl {
        public interval: 'IMMEDIATELY' | 'DAILY' | 'WEEKLY' | 'BIWEEKLY' | 'MONTHLY';
        public selectedPeriod: number;
        public selectedTime: string;
        public periods: any[];
        public times: any[];
        public initPeriodDisplay: string;
        public initTimeDisplay: string;
        public initSchedule: {
            interval: string;
            period: number;
            time: string;
        };
        public isIntervalReadOnly: boolean;
        public isCustomDateRange: boolean;
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private userAssignment: UserAssignmentModel,
            private userItems: SelectionItem[],
            private scheduledReport: ScheduledReport,
            private report: Report
        ) {
            super({}, $mdDialog);
            if (_.includes([ReportName.SUMMARY_DAILY, ReportName.SUMMARY_MONTHLY, ReportName.MONTHLY_BUILDING_ENGINEER], this.report.name)) {
                this.isIntervalReadOnly = true;
            } else {
                this.isIntervalReadOnly = false;
            }
            this.isCustomDateRange = this.report.name == ReportName.CUSTOM_DATE_RANGE ? true : false;
            this.initScheduleControls();
        }
        public ok(): void {
            this.userAssignment.scheduling = this.buildScheduleFromControls();
            this.hide(this.userAssignment);
        }
        public queryUsers(searchText: string): SelectionItem[] {
            if (!searchText) {
                searchText = '';
            }
            searchText = searchText.toLowerCase();
            return _.filter(this.userItems, (usr: SelectionItem) => {
                return usr.name && usr.name.toLowerCase().indexOf(searchText) > -1
                    && _.every(this.userAssignment.reportUsers, (ru) => ru.id != usr.id);
            });
        }
        public onChangeInterval() {
            this.periods = this.getPeriods(this.interval);
            this.selectedPeriod = null;
        }

        private buildScheduleFromControls(): SchedulingModel {
            const scheduling: SchedulingModel = {
                dayOfMonth: this.interval == 'MONTHLY' ? this.selectedPeriod : null,
                dayOfWeek: this.interval == 'WEEKLY' || this.interval == 'BIWEEKLY' ? this.selectedPeriod : null,
                interval: this.interval,
                time: this.interval != 'IMMEDIATELY' ? this.selectedTime : null
            };
            return scheduling;
        }

        private initScheduleControls() {
            const scheduledReport: ScheduledReport = this.scheduledReport;
            const schedule = scheduledReport.scheduling;
            this.interval = scheduledReport.scheduling && scheduledReport.scheduling.interval ? scheduledReport.scheduling.interval : 'IMMEDIATELY';
            this.periods = this.getPeriods(this.interval);
            if (this.interval == 'WEEKLY' || this.interval == 'BIWEEKLY') {
                this.selectedPeriod = schedule.dayOfWeek;
                this.initPeriodDisplay = 'on ' + _.find(this.periods, (p) => p.value == this.selectedPeriod).display;
            } else if (this.interval == 'MONTHLY') {
                this.selectedPeriod = schedule.dayOfMonth;
                this.initPeriodDisplay = 'on ' + _.find(this.periods, (p) => p.value == this.selectedPeriod).display;
            } else {
                this.selectedPeriod = null;
                if (this.interval == 'DAILY') {
                    this.initPeriodDisplay = 'every day';
                } else {
                    this.initPeriodDisplay = 'on demand';
                }
            }
            this.times = this.getTimes(schedule.time);
            this.selectedTime = schedule.time;
            if (this.interval != 'IMMEDIATELY') {
                this.initTimeDisplay = ', at ' + _.find(this.times, (t) => t.value == this.selectedTime).display;
            } else {
                this.initTimeDisplay = '';
            }
            this.initSchedule = {
                interval: this.interval,
                period: this.selectedPeriod,
                time: this.selectedTime
            };
        }

        private getTimes(timeToCreateIfMissing = null) {
            // NOTE:
            // minimum supported time interval is 1-hour (e.g. 01:00, 02:00, 03:00, etc.)
            // if existing schedule time is not on the full hour (e.g. 10:25 am), a separate dropdown option is generated for backwards compatibilliy
            const result = [];
            const time = moment('2018-01-01T00:00:00Z');
            for (let i = 0; i < 24; i++) {
                result.push({
                    value: time.utc().format('HH:mm:ss'),
                    display: time.utc().format('h:mm a')
                });
                time.add(1, 'hour');
            }
            if (timeToCreateIfMissing && !_.find(result, (item) => item.value == timeToCreateIfMissing)) {
                let insertIndex = 0;
                while (insertIndex < result.length && result[insertIndex].value < timeToCreateIfMissing) {
                    insertIndex++;
                }
                const missingTime = {
                    value: timeToCreateIfMissing,
                    display: moment(`2018-01-01T${timeToCreateIfMissing}Z`).utc().format('h:mm a')
                };
                result.splice(insertIndex, 0, missingTime);
            }
            return result;
        }

        private getPeriods(interval) {
            if (interval != 'WEEKLY' && interval != 'MONTHLY' && interval != 'BIWEEKLY') {
                return [];
            }
            const numberOfDays = interval == 'WEEKLY' || interval == 'BIWEEKLY' ? 7 : 28;
            const name = interval == 'WEEKLY' || interval == 'BIWEEKLY' ? 'week' : 'month';
            const ordinalDays = _.getOrdinalNumbers(numberOfDays);
            return _.map(ordinalDays, (ordinalDay, index) => {
                return { value: index + 1, display: `${ordinalDay} day of the ${name}` };
            });
        }
    }
    angular.module('reportCenter').controller('ReportRecipientsCtrl', ReportRecipientsCtrl);
}
