namespace aq.services {
    export class FullScreenService {
        public fullScreen:boolean;

        /* @ngInject */
        constructor(private $rootScope: ng.IRootScopeService, private $mdMedia) {
            $rootScope.$watch(() => {
                return $mdMedia('xs') || $mdMedia('sm');
            }, (wantsFullScreen) => {
                this.fullScreen = (wantsFullScreen === true);
            });
        }
    }
    angular.module('aq.services').service('FullScreenService', FullScreenService);
}

