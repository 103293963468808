var aq;
(function (aq) {
    var tenantbilling;
    (function (tenantbilling) {
        var BulkUploadMoveOutsCtrl = /** @class */ (function (_super) {
            __extends(BulkUploadMoveOutsCtrl, _super);
            /* @ngInject */
            function BulkUploadMoveOutsCtrl(Auth, $mdDialog, $mdStepper, Messages, RestangularV3, building, TenantService) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.Auth = Auth;
                _this.$mdDialog = $mdDialog;
                _this.$mdStepper = $mdStepper;
                _this.Messages = Messages;
                _this.RestangularV3 = RestangularV3;
                _this.building = building;
                _this.TenantService = TenantService;
                _this.step = 1;
                _this.valid = true;
                return _this;
            }
            BulkUploadMoveOutsCtrl.prototype.downloadTemplate = function () {
                var csvColumns = [
                    'residentId',
                    'phoneNumber',
                    'email',
                    'residentName',
                    'unit',
                    'sqft',
                    'startDate',
                    'endDate'
                ];
                var csvContent = 'data:text/csv;charset=utf-8,';
                csvContent += csvColumns + "\n";
                var fileName = this.building.name + " - units upload";
                var link = document.createElement('a');
                link.setAttribute('href', encodeURI(csvContent));
                link.setAttribute('download', fileName);
                link.click();
            };
            BulkUploadMoveOutsCtrl.prototype.next = function () {
                this.$mdStepper('moveOutBulkUploadStepper').goto(this.step++);
            };
            BulkUploadMoveOutsCtrl.prototype.upload = function () {
                this.parseCSV();
                this.validateDates(this.result);
                this.fileUploaded = this.valid;
                this.attemptedUpload = true;
                if (!this.valid) {
                    this.uploadData = null;
                }
            };
            BulkUploadMoveOutsCtrl.prototype.parseCSV = function () {
                var lines = this.uploadData.split(/\r\n|\n|\r/);
                var result = [];
                var headers = lines[0].split(',');
                lines.shift();
                lines.forEach(function (line) {
                    if (line.trim().length === 0) {
                        return;
                    }
                    var obj = {};
                    var splitLine = line.split(',');
                    for (var j = 0; j < headers.length; j++) {
                        obj[headers[j]] = splitLine[j];
                    }
                    result.push(obj);
                });
                this.result = result;
            };
            BulkUploadMoveOutsCtrl.prototype.generatePreview = function () {
                this.generateLeaseCreationRequestObjects(this.result);
                this.bulkUpdate(this.leases);
            };
            BulkUploadMoveOutsCtrl.prototype.generateLeaseCreationRequestObjects = function (json) {
                this.leases = json.map(function (unit) {
                    var newLease = __assign({}, unit, { leaseIdentifier: null, startDate: unit.startDate, endDate: unit.endDate, active: true, tenant: '', unitName: unit.unit });
                    return newLease;
                });
            };
            BulkUploadMoveOutsCtrl.prototype.validateDates = function (json) {
                var _this = this;
                this.valid = true;
                json.map(function (unit) {
                    var endDate = unit.endDate;
                    if (endDate != null && endDate != "") {
                        if (!moment(endDate, 'YYYY-MM-DD', true).isValid()) {
                            _this.valid = false;
                        }
                    }
                });
            };
            BulkUploadMoveOutsCtrl.prototype.bulkUpdate = function (leaseList) {
                var _this = this;
                return this.TenantService.updateLeases(leaseList, this.building.id).then(function (leases) {
                    var leasesLength = _this.TenantService.getResponseLength(leases);
                    var successMessage = "Successfully updated " + leasesLength + " leases";
                    _this.$mdDialog.hide();
                    _this.Messages.success(successMessage);
                }).catch(function (error) {
                    _this.$mdDialog.hide();
                    _this.Messages.error('Error updating leases');
                });
            };
            return BulkUploadMoveOutsCtrl;
        }(aq.common.Controllers.ModalCtrl));
        tenantbilling.BulkUploadMoveOutsCtrl = BulkUploadMoveOutsCtrl;
        angular
            .module('tenantBilling')
            .controller('BulkUploadMoveOutsCtrl', BulkUploadMoveOutsCtrl);
    })(tenantbilling = aq.tenantbilling || (aq.tenantbilling = {}));
})(aq || (aq = {}));
