angular.module('properties').controller('PropertiesCtrl',
    function ($scope, $state, OptionsService, buildings) {

    // helper variable to transfer values between scopes that ng-if creates
    $scope.data = {};

    $scope.account.all('BuildingPurposes').getList({inflate: 'purposes'}).then(function (buildingPurposes) {
        $scope.purposes = [];
        // temporary until we support grouping in inplace
        _.each(buildingPurposes, function(purpose) {
            $scope.purposes.push(purpose);
            $scope.purposes = $scope.purposes.concat(purpose.purposes);
        })
    });

    $scope.account.all('workCalendars').getList().then(function (calendars) {
        $scope.workCalendars = calendars;
    });

    OptionsService.fetchAll().then(function () {
        $scope.areaMeasurementUnit = OptionsService.areaMeasurementUnit();
        $scope.currencyUnit = OptionsService.currencyUnit();
        $scope.getUnitLabelByMeasure = OptionsService.getUnitLabelByMeasure;
    });
    if(buildings && buildings.length > 0) {
        $scope.selectDefaultBuildingWithRedirect('aq.properties.buildings', 'aq.properties.buildings.form');
    } else {
        $state.go('aq.properties.buildings.form', {building: {accountId: $scope.account.id}});
    }
});
