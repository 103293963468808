angular.module('aq.ui', ['aq.filters', 'aq.auth', 'ngMaterial', 'md.data.table', 'mdSteppers', 'ngMaterialDateRangePicker', 'aq.ui.loading'])
    .config(function($mdThemingProvider) {
        var aqGreenMap = $mdThemingProvider.definePalette('aqGreen', {
            '50': '7ACF46',
            '100': '7ACF46',
            '200': '7ACF46',
            '300': '7ACF46',
            '400': '7ACF46',
            '500': '7ACF46',
            '600': '7ACF46',
            '700': '7ACF46',
            '800': '7ACF46',
            '900': '7ACF46',
            'A100': '7ACF46',
            'A200': '7ACF46',
            'A400': '7ACF46',
            'A700': '7ACF46',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': ['50', '100', '200', '300', '400'],
            'contrastLightColors': ['500', '600', '700', '800', '900'],
        });
        var aqBlueMap = $mdThemingProvider.definePalette('aqBlue', {
            '50': '0091f1',
            '100': '273242',
            '200': '0091f1',
            '300': '0091f1',
            '400': '0091f1',
            '500': '0091f1',
            '600': '0091f1',
            '700': '0091f1',
            '800': '0091f1',
            '900': '0091f1',
            'A100': '0091f1',
            'A200': '0091f1',
            'A400': '0091f1',
            'A700': '0091f1',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': ['50', '100', '200', '300', '400'],
            'contrastLightColors': ['500', '600', '700', '800', '900'],
        });

        var aqRedMap = $mdThemingProvider.definePalette('aqRed', {
            '50': 'CC3333',
            '100': 'CC3333',
            '200': 'CC3333',
            '300': 'CC3333',
            '400': 'CC3333',
            '500': 'CC3333',
            '600': 'CC3333',
            '700': 'CC3333',
            '800': 'CC3333',
            '900': 'CC3333',
            'A100': 'CC3333',
            'A200': 'CC3333',
            'A400': 'CC3333',
            'A700': 'CC3333',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': ['50', '100', '200', '300', '400'],
            'contrastLightColors': ['500', '600', '700', '800', '900'],
        });

        $mdThemingProvider.theme('default')
            .primaryPalette('aqBlue', {
                'default': '50',
                'hue-1': '900',
            })
            .accentPalette('aqGreen', {
                'default': '50',
                'hue-1': '900',
            })
            .warnPalette('aqRed', {
                'default': '50',
                'hue-1': '900',
            })
            .backgroundPalette('grey', { 'default': 'A100' });
    });
angular.module('aq.services', ['ngMaterial', 'ui.router', 'restangular', 'aq.messages', 'aq.services.errors']);
