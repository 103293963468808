namespace aq.ui {

    export class EnergyStarScoreCtrl {
        private building: aq.common.models.Building;
        private currentScore: any = {};
        private trend;

        /* @ngInject */
        constructor() {
            this.currentScore = this.building.energyStarScore;
            this.trend = this.getTrend(this.currentScore.score);
        }

        getTrend(current) {
            return  'aq-success';
        }

        formatDate(month) {
            return moment(month, 'YYYY-MM-DD HH:mm:ss').format('MMMM YYYY');
        }

    }

    angular.module('aq.ui')
        .component('energyStarScore', {
            controller: EnergyStarScoreCtrl,
            controllerAs: 'vm',
            templateUrl: 'app/common/directives/energyStarScore/energyStarScore.html',
            bindings: {
                building: '<'
            }
        });
}
