var aq;
(function (aq) {
    var deviceManagement;
    (function (deviceManagement) {
        var ViewAuditLogsCtrl = /** @class */ (function (_super) {
            __extends(ViewAuditLogsCtrl, _super);
            /* @ngInject */
            function ViewAuditLogsCtrl(Auth, $mdDialog, device, revisions) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.Auth = Auth;
                _this.$mdDialog = $mdDialog;
                _this.device = device;
                _this.revisions = revisions;
                _this.headerValue = [];
                if (revisions) {
                    for (var key in revisions[0].fields) {
                        _this.headerValue.push(key);
                    }
                }
                _this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'units' : 'tenants';
                return _this;
            }
            ViewAuditLogsCtrl.prototype.getTenants = function (tenants) {
                var tenantString = '';
                if (tenants) {
                    for (var i = 0; i < tenants.length; i++) {
                        tenantString = tenantString === '' ? tenants[i].tenant.name : tenantString + ', ' + tenants[i].tenant.name;
                    }
                }
                return tenantString;
            };
            ViewAuditLogsCtrl.prototype.getExtraFieldsValue = function (device, hv) {
                if (device.fields) {
                    var fields = device.fields[hv];
                    return fields ? fields.value : null;
                    ;
                }
                return null;
            };
            ViewAuditLogsCtrl.prototype.mapHeaderKeyToString = function (hv) {
                //TODO get this information from device class
                switch (hv) {
                    case 'deviceIdentifier':
                        return '(Device ID|Modbus ID)';
                    case 'deviceTemplateId':
                        return 'Device Template ID';
                    case 'multiplier':
                        return 'Multiplier';
                    case 'readingMultiplier':
                        return 'Reading Multiplier';
                    case 'forceUpdate':
                        return 'Force Update';
                    case 'currentTransformerSize':
                        return "Curr Transformer Size";
                    case 'modbusSerialPortBaudRate':
                        return 'Modbus Serial Port BR';
                    case 'modbusLocalAddress':
                        return 'Modbus Local';
                    case 'pulseAvailable':
                        return 'Pulse Available';
                    case 'powerAvailable':
                        return 'Power Available';
                    case 'pin1PulseRate':
                        return 'Pin1 Pulse Rate';
                    case 'pin2PulseRate':
                        return 'Pin2 Pulse Rate';
                    case 'pin3PulseRate':
                        return 'Pin3 Pulse Rate';
                    case 'pin4PulseRate':
                        return 'Pin4 Pulse Rate';
                    case 'quickResponseMetaData':
                        return 'QR Scan Meta Data';
                    default:
                        return hv;
                }
            };
            ViewAuditLogsCtrl.prototype.cancel = function (data) {
                this.$mdDialog.cancel(data);
            };
            ViewAuditLogsCtrl.prototype.hide = function (data) {
                this.$mdDialog.hide(data);
            };
            ViewAuditLogsCtrl.prototype.checkUserName = function (userFullName, userId) {
                var userFullNameToReturn = userFullName && userFullName;
                if (!userFullNameToReturn) {
                    userFullNameToReturn = userId ? "Removed User (" + userId + ")" : 'System';
                }
                return userFullNameToReturn;
            };
            ViewAuditLogsCtrl.prototype.checkIfChanged = function (currentString, pastString, index) {
                currentString = currentString === null || currentString === '' ? undefined : currentString;
                pastString = pastString === null || pastString === '' ? undefined : pastString;
                if (currentString === pastString) {
                    return '';
                }
                else if ((currentString === undefined) && (pastString !== undefined)) {
                    return 'deleted';
                }
                else if ((currentString !== undefined) && (pastString === undefined)) {
                    return 'created';
                }
                else if (currentString !== pastString) {
                    return 'modified';
                }
                return '';
            };
            return ViewAuditLogsCtrl;
        }(aq.common.Controllers.ModalCtrl));
        deviceManagement.ViewAuditLogsCtrl = ViewAuditLogsCtrl;
        angular
            .module('deviceManagement')
            .controller('ViewAuditLogsCtrl', ViewAuditLogsCtrl);
    })(deviceManagement = aq.deviceManagement || (aq.deviceManagement = {}));
})(aq || (aq = {}));
