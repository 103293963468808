namespace aq.tenantbilling {
    export class EditPeriodServicesStepCtrl {
        private monthlyBilling: aq.tenantbilling.MonthlyTenantBilling;
        private tenantServices;
        private tenantCharges;
        private tenants;
        private enabledSettings: aq.tenantbilling.EnabledSettings;
        constructor() { }

        public toggleService(service: any): void {
            const enabled = this.enabledSettings.services[service.id];
            _.each(this.tenants, (tenant) => {
                if (!this.tenantHasService(service, tenant)) {
                    return;
                }

                _.each(this.getCharges(tenant), (charge) => {
                    if (service.id === charge.service) {
                        this.enabledSettings.charges[charge.id] = enabled;
                    }
                });

                if (enabled || this.allTenantChargesAreDisabled(tenant)) {
                    this.enabledSettings.tenants[tenant.id] = enabled;
                }
            });
        }

        public getCharges(tenant: aq.tenantbilling.Tenant): any[] {
            return _.filter(this.tenantCharges, { tenant: tenant.id });
        }

        public tenantHasService(service: any, tenant: any): boolean {
            return _.some(this.getCharges(tenant), (charge) => {
                return charge.service === service.id;
            });
        }

        public allTenantChargesAreDisabled(tenant: any): boolean {
            return _.every(this.getCharges(tenant), (charge) => {
                return !this.enabledSettings.charges[charge.id];
            });
        }
    }

    angular
        .module('tenantBilling')
        .component('editPeriodServicesStep', {
            templateUrl: 'app/tenantBilling/invoicing/run/editInvoicingPeriod/components/editPeriodServicesStep/editPeriodServicesStep.html',
            controller: EditPeriodServicesStepCtrl,
            controllerAs: 'vm',
            bindings: {
                monthlyBilling: '<',
                tenantServices: '<',
                tenantCharges: '<',
                tenants: '<',
                enabledSettings: '='
            }
        });
}
