var aq;
(function (aq) {
    var dashboard;
    (function (dashboard_1) {
        var AddEditDashboardCtrl = /** @class */ (function (_super) {
            __extends(AddEditDashboardCtrl, _super);
            /* @ngInject */
            function AddEditDashboardCtrl($mdDialog, account, dashboard, loading, Messages, Errors, Restangular, userId) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.account = account;
                _this.dashboard = dashboard;
                _this.loading = loading;
                _this.Messages = Messages;
                _this.Errors = Errors;
                _this.Restangular = Restangular;
                _this.userId = userId;
                _this.supportedStructures = [
                    '100',
                    '50-50',
                    '33-66',
                    '50-50/100',
                    '33-33-33/100',
                    '100/50-50',
                    '100/33-33-33',
                    '100/50-50/100',
                    '25-75(100/50-50)',
                    '25-25-25-25/100',
                    '20-20-20-20-20/100',
                    '100/20-20-20-20-20/100/20-20-20-20-20/100/20-20-20-20-20'
                ];
                _this.currentNavItem = 'general';
                _this.initLayouts();
                _this.initUsers();
                if (!_this.dashboard) {
                    _this.dashboard = {
                        id: null,
                        name: '',
                        account: _this.account,
                        users: [userId],
                        building: null,
                        configuration: {
                            json: ''
                        },
                        publicDisplay: {
                            headingImageUrl: ''
                        }
                    };
                    _this.visibilityMode = 'private';
                }
                else {
                    _this.dashboardConfiguration = _this.dashboard.configuration.json; // JSON.parse has already occurred in dashboard resolve
                    _this.hideBorders = _this.dashboardConfiguration.hideBorders;
                    _this.isCompact = _this.dashboardConfiguration.isCompact;
                    _this.autoRefreshMinutes = _this.dashboard.autoRefreshMinutes || 10; // default to ten minute if not set
                    _this.autoRefreshEnabled = _this.dashboard.autoRefreshMinutes != null;
                    if (!_this.dashboard.users) {
                        _this.dashboard.users = [];
                    }
                    if (_this.dashboard.users.length == 0) {
                        _this.visibilityMode = 'all';
                    }
                    else if (_this.dashboard.users.length == 1 && _.first(_this.dashboard.users) == _this.userId) {
                        _this.visibilityMode = 'private';
                    }
                    else {
                        _this.visibilityMode = 'individual';
                    }
                }
                return _this;
            }
            AddEditDashboardCtrl.prototype.buildLayoutFromStructure = function (structure) {
                var structureObject = this.parseStructure(structure);
                var layout = {
                    editable: false,
                    hideBorders: true,
                    structure: structure,
                    rows: angular.copy(structureObject),
                    titleTemplateUrl: '../src/templates/material/dashboard-title.html',
                    isCompact: false
                };
                return layout;
            };
            AddEditDashboardCtrl.prototype.parseStructure = function (structure) {
                var rows = [];
                var currentRow = { columns: [] };
                var currentColumn = {};
                for (var i = 0; i < structure.length; i++) {
                    var currentColumnFlex = '';
                    var c = structure[i];
                    while (c != '/' && c != '(' && i < structure.length && c != '-') {
                        currentColumnFlex += c;
                        i++;
                        c = structure[i];
                    }
                    if (c == '-' || i == structure.length) {
                        if (!currentColumn.flex) {
                            currentColumn.flex = currentColumnFlex;
                        }
                        currentRow.columns.push(angular.copy(currentColumn));
                        currentColumn = {};
                        continue;
                    }
                    if (c == '/') {
                        if (!currentColumn.flex) {
                            currentColumn.flex = currentColumnFlex;
                        }
                        currentRow.columns.push(angular.copy(currentColumn));
                        currentColumn = {};
                        rows.push(angular.copy(currentRow));
                        currentRow = { columns: [] };
                        continue;
                    }
                    if (c == '(') {
                        currentColumn.flex = currentColumnFlex;
                        i++;
                        var subStructure = '';
                        do {
                            subStructure += structure[i];
                            i++;
                        } while (structure[i] != ')');
                        // recursive
                        var subStructureObj = this.parseStructure(subStructure);
                        currentColumn.rows = angular.copy(subStructureObj);
                        if (i == structure.length - 1) {
                            // string end
                            currentRow.columns.push(currentColumn);
                        }
                    }
                }
                rows.push(currentRow);
                return rows;
            };
            AddEditDashboardCtrl.prototype.initLayouts = function () {
                var _this = this;
                this.templateLayouts = _.map(this.supportedStructures, function (structure) { return _this.buildLayoutFromStructure(structure); });
                this.layoutViews = [];
                var counter = 0;
                _.each(this.templateLayouts, function (layout) {
                    _this.layoutViews.push({
                        id: counter++,
                        templateLayout: layout
                    });
                });
            };
            AddEditDashboardCtrl.prototype.save = function () {
                var _this = this;
                this.loading.start();
                this.dashboard.autoRefreshMinutes = this.autoRefreshEnabled ? this.autoRefreshMinutes : null;
                if (!this.dashboard.id) {
                    this.selectedLayoutView.templateLayout.hideBorders = this.hideBorders;
                    this.selectedLayoutView.templateLayout.isCompact = this.isCompact;
                    this.dashboard.configuration.json = JSON.stringify(this.selectedLayoutView.templateLayout);
                    this.dashboard.enabled = true;
                    this.create(this.dashboard)
                        .then(function (newDashboard) {
                        _this.loading.stop();
                        _this.Messages.success('Created Dashboard');
                        _this.$mdDialog.hide(_this.getFreshDashboard(newDashboard.id));
                    }, function (error) {
                        _this.loading.stop();
                        _this.Errors.forCRUD('CREATE')(error);
                        _this.$mdDialog.cancel();
                    });
                }
                else {
                    this.dashboardConfiguration.hideBorders = this.hideBorders;
                    this.dashboardConfiguration.isCompact = this.isCompact;
                    this.dashboard.configuration.json = JSON.stringify(this.dashboardConfiguration);
                    this.update(this.dashboard)
                        .then(function (updatedDashboard) {
                        _this.loading.stop();
                        _this.Messages.info('Updated Dashboard');
                        _this.$mdDialog.hide(updatedDashboard);
                    }, function (error) {
                        _this.loading.stop();
                        _this.Messages.error('Error while updating Dashboard');
                        _this.$mdDialog.cancel();
                    });
                }
            };
            AddEditDashboardCtrl.prototype.hide = function () {
                this.$mdDialog.hide();
            };
            AddEditDashboardCtrl.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            AddEditDashboardCtrl.prototype.getFreshDashboard = function (id) {
                var _this = this;
                return this.Restangular.one('Dashboards', id)
                    .get()
                    .then(function (dashboard) {
                    return _this.Restangular.restangularizeElement(null, dashboard[0], 'Dashboards');
                });
            };
            AddEditDashboardCtrl.prototype.getRowLayouts = function (rowCount) {
                return _.filter(this.layoutViews, function (layout) { return layout.templateLayout.rows.length == rowCount; });
            };
            AddEditDashboardCtrl.prototype.importHeadingImageFromAccount = function () {
                if (this.account.imageUrl) {
                    this.dashboard.publicDisplay.headingImageUrl = this.account.imageUrl;
                }
                else {
                    this.Messages.error('No image to import.');
                }
            };
            AddEditDashboardCtrl.prototype.initUsers = function () {
                var _this = this;
                this.Restangular
                    .one('accounts', this.account.id)
                    .getList('users')
                    .then(function (data) {
                    _this.userViewItems = _.map(data, function (user) {
                        var item = {
                            id: user.id,
                            name: user.fullName
                        };
                        return item;
                    });
                });
            };
            AddEditDashboardCtrl.prototype.onVisibilityModeChange = function () {
                var userIds = [];
                switch (this.visibilityMode) {
                    case 'all':
                    case 'individual':
                        userIds = [];
                        break;
                    case 'private':
                        userIds = [this.userId];
                        break;
                    default:
                        break;
                }
                this.onSelectedUsersChange(userIds);
            };
            AddEditDashboardCtrl.prototype.onIndividualUserSelectorClose = function () {
                if (this.dashboard.users.length == 1 && _.first(this.dashboard.users) == this.userId) {
                    this.visibilityMode = 'private';
                    return;
                }
                else if (this.dashboard.users.length > 0 && _.indexOf(this.dashboard.users, this.userId) == -1) {
                    this.dashboard.users.push(this.userId);
                }
                if (this.userViewItems && this.dashboard.users.length == this.userViewItems.length) {
                    this.visibilityMode = 'all';
                    this.onVisibilityModeChange();
                }
            };
            AddEditDashboardCtrl.prototype.onSelectedUsersChange = function (data) {
                this.dashboard.users = data;
            };
            AddEditDashboardCtrl.prototype.create = function (dashboard) {
                return this.Restangular.all('Dashboards').post(dashboard);
            };
            AddEditDashboardCtrl.prototype.update = function (dashboard) {
                return dashboard.put();
            };
            return AddEditDashboardCtrl;
        }(aq.common.Controllers.ModalCtrl));
        dashboard_1.AddEditDashboardCtrl = AddEditDashboardCtrl;
        angular.module('dashboard').controller('AddEditDashboardCtrl', AddEditDashboardCtrl);
    })(dashboard = aq.dashboard || (aq.dashboard = {}));
})(aq || (aq = {}));
