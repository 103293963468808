namespace aq.dashboard {

    export class ChangeCtrl {
        private input: number;
        private compare: number;
        private noPercent: boolean;
        private diff: number;
        private deltaUnit: string;
        private alwaysShowSign: boolean;
        private sign: string;
        private icon: string;
        /* @ngInject */
        constructor(private $filter: ng.IFilterService) {}

        getIcon(diff) {
            if (diff > 0) {
                return 'change_history';
            } else if (diff < 0) {
                return 'details';
            } else {
                return 'remove';
            }
        }

        public $onChanges() {
            this.changeDiff();
        }

        public $onInit() {
            this.changeDiff();
        }

        public changeDiff() {
            if (this.noPercent) {
                this.diff = this.input - this.compare;
            } else {
                this.diff = this.$filter<Function>('compareValues')(this.input, this.compare);
            }
            if (!this.deltaUnit && !this.noPercent) {
                this.deltaUnit = '%';
            }

            this.icon = this.getIcon(this.diff);

            if (this.alwaysShowSign && this.diff > 0) {
                this.sign = '+';
            }
        }
    }

    angular.module('dashboard').component('change', {
        controller: ChangeCtrl,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/common/directives/change/change.html',
        bindings: {
            input: '<',
            compare: '<',
            noPercent: '<?',
            deltaUnit: '@?',
            alwaysShowSign: '<?'
        }
    });
}
