angular.module('charting').filter('forChartWidget', function($filter) {
    var previousData: any = {
        input: null,
        output: null
    }

    return function(data, callUnit, time, subject, disableLegendUpdate, colors, sizes, lines, valuesField) {
        if (!data) {
            previousData.input = data;
            return null;
        }

        if (!valuesField) {
            valuesField = "values";
        }

        if (_.isEmpty(data)) {
            previousData.input = data;
            return {
                data: [],
                missing: [],
                unit: callUnit
            }
        }

        var getValue = function(val, unit, dataElem) {
            if (unit.field === 'intensity' && !_.isNull(val)) {
                return val / (sizes[dataElem.id] || 1);
            } else {
                return val;
            }
        };

        var maxValue = 0;
        _.each(data, function(one) {
            _.each(one.values, function(value) {
                maxValue = Math.max(maxValue, getValue(value, callUnit, one));
            });
        });

        var unit = $filter('bestFitUnit')(maxValue, callUnit);
        var convertedMaxValue = $filter('toUnit') (maxValue, unit);

        if (angular.equals(data, previousData.input) && angular.equals(unit, previousData.unit)) {
            return previousData.output;
        }

        previousData.input = data;

        var index = 0;
        var ticks = $filter('period')(time);
        var retData = _.map(data, function(one, key) {
            var elem: any = {};
            elem.label = disableLegendUpdate ? one.name : one.name + "= 0.00";
            elem.name = one.name;
            elem.xaxis = 1;
            elem.yaxis = 1;

            elem.number = index;
            elem.color = colors ? colors[key] : $filter('color')(one.name, one.route, one.id);
            if (lines || one.line) {
                elem.lines = {
                    show: true,
                    fill: false,
                    lineWidth: 2
                };
                elem.bars = {
                    show: false
                }
                elem.stack = false;
            }
            elem.data = _.chain(ticks)
                .zip(one[valuesField])
                .filter(function(one) {
                    return !_.isUndefined(one[0]);
                }).map(function(val) {
                    var x = val[0];
                    var y = getValue($filter('toUnit') (val[1], unit), unit, one);
                    return [x, y];
                }).value();
            elem.timestamps = one.timestamps; // supports trend line so that we can display the trend date in the tooltip
            index++;

            return elem;
        });

        var missing = $filter('missing')(data);
        var partial = $filter('partial')(data);

        previousData.output = {
            data: $filter('handleNegatives')(retData),
            unit: unit,
            missing: missing,
            partial: partial,
            max: convertedMaxValue < 1 ? 1 : undefined
        };

        return previousData.output;
    }
});
