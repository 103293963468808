var aq;
(function (aq) {
    var utilityBudgets;
    (function (utilityBudgets) {
        var BudgetCreateWizardMode;
        (function (BudgetCreateWizardMode) {
            BudgetCreateWizardMode["PLANNING"] = "planning";
            BudgetCreateWizardMode["MANUAL"] = "manual";
        })(BudgetCreateWizardMode = utilityBudgets.BudgetCreateWizardMode || (utilityBudgets.BudgetCreateWizardMode = {}));
        ;
        var BudgetCreateWizardSelectionModalCtrl = /** @class */ (function (_super) {
            __extends(BudgetCreateWizardSelectionModalCtrl, _super);
            /* ngInject */
            function BudgetCreateWizardSelectionModalCtrl($mdDialog, Messages, mode, $state, 
            // the following properties are for backwards compatility (used during manual budget creation wizard)
            building, measures, months, years, types) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.Messages = Messages;
                _this.mode = mode;
                _this.$state = $state;
                _this.building = building;
                _this.measures = measures;
                _this.months = months;
                _this.years = years;
                _this.types = types;
                return _this;
            }
            BudgetCreateWizardSelectionModalCtrl.prototype.startWizard = function (mode) {
                var _this = this;
                if (mode != BudgetCreateWizardMode.PLANNING && mode != BudgetCreateWizardMode.MANUAL) {
                    this.Messages.error('Unexpected error occured while starting wizard, please contant your Administrator');
                    this.cancel();
                }
                this.mode = mode;
                if (this.mode == BudgetCreateWizardMode.PLANNING) {
                    this.$mdDialog.show({
                        controller: 'BudgetPlanningWizardCtrl as vm',
                        templateUrl: 'app/utilityBudgets/budgets/wizard/budgetPlanningWizard.html',
                        parent: angular.element(document.body),
                        clickOutsideToClose: false,
                        targetEvent: event,
                        locals: {
                            buildingId: this.building.id,
                            accountId: this.building.account
                        },
                        multiple: false
                    })
                        .finally(function () {
                        _this.$state.reload();
                    });
                }
                else {
                    this.$mdDialog.show({
                        controller: 'TargetNewCtrl as targetNewCtrl',
                        templateUrl: 'app/utilityBudgets/budgets/new/budgetNewModal.html',
                        clickOutsideToClose: true,
                        parent: angular.element(document.body),
                        locals: {
                            target: {
                                building: this.building.id
                            },
                            measures: this.measures,
                            building: this.building,
                            months: this.months,
                            years: this.years,
                            types: this.types
                        },
                        multiple: false
                    })
                        .finally(function () {
                        _this.$state.reload();
                    });
                }
            };
            return BudgetCreateWizardSelectionModalCtrl;
        }(aq.common.Controllers.ModalCtrl));
        utilityBudgets.BudgetCreateWizardSelectionModalCtrl = BudgetCreateWizardSelectionModalCtrl;
        angular.module('aq.utilityBudgets').controller('BudgetCreateWizardSelectionModalCtrl', BudgetCreateWizardSelectionModalCtrl);
    })(utilityBudgets = aq.utilityBudgets || (aq.utilityBudgets = {}));
})(aq || (aq = {}));
