angular.module('aq.admin.meters.updatemultiplier').controller('AdminMeterUpdateMultiplierCtrl',
    function($scope, Restangular, Messages, $interval, Errors) {

    $scope.working = false;
    $scope.fromDate = moment().utc().add(-1, 'month');
    $scope.toDate = moment().utc();
    $scope.building = null;

    Restangular.all('accounts').get('queryAllAccounts').then(function(accounts) {
        $scope.accounts = accounts;
    });

    var checkIfCopyMeterDataIsRunning = function () {
        Restangular.one('internal').customGET('updateMeterDataStatus',  { type: 'update_multiplier',
                nocache : new Date().getTime() }).then(function (result) {
            if (result == 'INPROGRESS') {
                $scope.working = true;
            } else {
                if ($scope.working == true) {
                    Messages.info('Meter multiplier updated for selected time range!');
                    $interval.cancel(intervalPromise);
                }
                $scope.working = false;
            }
        });
    }
    var intervalPromise = null;

    var getUserLocalTimeWithTimeZone = function(date) {
        return moment.tz(moment(date).local(), $scope.building.timeZoneId).format('YYYY-MM-DDTHH:mm:ss');
    }

    $scope.checkEnabled = function() {
        if (!$scope.collector || $scope.working || !$scope.building) return false;

        var mandatoryValues = [
            $scope.collector.id,
            $scope.fromDate,
            $scope.toDate,
            $scope.oldMultiplier,
            $scope.newMultiplier
        ]

        mandatoryValues = _.filter(mandatoryValues, function(value) {
            return value;
        })
        return mandatoryValues.length == 5;        
    }

    $scope.updateMultiplier = function() {
        if ($scope.working == true || !$scope.collector || !$scope.building) return;

        $scope.working = true;
        $interval.cancel(intervalPromise);
        intervalPromise = $interval(checkIfCopyMeterDataIsRunning, 1000);

        var start = getUserLocalTimeWithTimeZone($scope.fromDate);
        var end = getUserLocalTimeWithTimeZone($scope.toDate);

        var data = {
            meterId: $scope.collector.id,
            startDate: start,
            endDate: end,
            oldMultiplier: $scope.oldMultiplier,
            newMultiplier: $scope.newMultiplier
        }

        Restangular.one('internal').customPOST(null, 'updateMeterMultiplier', data)
            .catch(Errors.forPromise())
            .finally(function() {
                $scope.working = false;
            });
    }

    $scope.$watchCollection('[fromDate,toDate,intervalValue,intervalType]', function(data) {
        if (!$scope.fromDate || !$scope.toDate) return;

        if ($scope.intervalValue && $scope.intervalType) {
            $scope.destinationToDate = moment($scope.toDate).add($scope.intervalValue, $scope.intervalType);
            $scope.destinationFromDate = moment($scope.fromDate).add($scope.intervalValue, $scope.intervalType);
        } else {
            $scope.destinationToDate = moment($scope.toDate);
            $scope.destinationFromDate = moment($scope.fromDate);
        }
    });

});