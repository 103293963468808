namespace aq.utilityBudgets {
    export class BudgetMainCtrl {
        public currentYear: string;
        public currentMonth: string;
        /* ngInject */
        constructor(
            private $state: ng.ui.IStateService,
            private Auth: aq.services.Auth
        ) {
            this.currentYear = moment().format('YYYY');
            this.currentMonth = moment().format('M');
            if (this.$state.current.name == 'aq.utilityBudgets') {
                $state.go('aq.utilityBudgets.home');
            }
        }
        public isCurrentState(name) {
            return this.$state.current.name == name;
        }
        public isChildStateOf(name) {
            return this.$state.includes(name);
        }
        public isInsightState() {
            return this.isCurrentState('aq.utilityBudgets.serviceBills')
                || this.isCurrentState('aq.utilityBudgets.bill')
                || this.isCurrentState('aq.utilityBudgets.year')
                || this.isCurrentState('aq.utilityBudgets.month');
        }
    }
    angular.module('aq.utilityBudgets').controller('BudgetMainCtrl', BudgetMainCtrl);
}
