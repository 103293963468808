namespace aq.ui {
    export class ActivityAlertUtilService {

        public getDurationFormatted(start: Date, end: Date): string {
            let result: string;
            const duration = moment.duration(moment(end).valueOf() - moment(start).valueOf());
            const years = this.formatDuration(duration, 'years');
            const months = this.formatDuration(duration, 'months');
            const days = this.formatDuration(duration, 'days');
            const hours = this.formatDuration(duration, 'hours');
            const minutes = this.formatDuration(duration, 'minutes');
            let showMinutes = false;
            result = years ? `${years}, ` : '';
            result += months ? `${months}, ` : '';
            result += days ? `${days}, ` : '';
            if (result == '') {
                showMinutes = true;
            }
            result += hours ? `${hours}, ` : '';
            if (showMinutes) {
                result += minutes ? `${minutes}, ` : '';
            }
            if (result && result.endsWith(', ')) {
                result = result.substr(0, result.length - 2);
            }
            if (result == '') {
                result = 'less than a minute';
            }
            return result;
        }

        private formatDuration(duration: moment.Duration, division: 'years' | 'months' | 'days' | 'hours' | 'minutes'): string {
            return (() => {
                switch (duration[division]()) {
                    case 0:
                        return '';
                    case 1:
                        return `${duration[division]()} ${division.replace(/s$/, '')}`;
                    default:
                        return `${duration[division]()} ${division}`;
                }
            })();
        }
    }
    angular.module('aq.ui').service('activityAlertUtilService', ActivityAlertUtilService);
}
