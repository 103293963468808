angular.module('aq.ui').directive('sortable', function() {
    return {
        restrict: 'A',
        scope: false,
        link: function($scope, $element, $attrs) {

            var startIndex;

            $element.sortable({
                placeholder: 'sortable-placeholder',
                items: 'li[unsortable!="true"]',
                helper: 'clone',
                appendTo: 'body',
                start: function(event, ui) {
                    startIndex = ui.item.index();
                },
                update: function(event, ui) {
                    $scope.$apply(function() {
                        $scope.$event = {
                            item: ui.item,
                            from: startIndex,
                            to: ui.item.index()
                        }
                        $scope.$eval($attrs.sortable);
                    });
                }
            });

            $scope.$watch($attrs.readonly, function (value){
                if (!value) {
                    $element.sortable('enable');
                } else {
                    $element.sortable('disable');
                }
            });

        }
    }
});