angular.module('aq.services.data', ['restangular']).factory('DataService', function (Restangular: restangular.IService, $filter, $q: angular.IQService) {
    const service: any = {};

    const emptyPromise = $q.when(null);

    const apiCall = function (method, collection, interval, start, end, unit, moreParams) {
        const params: any = {
            interval: interval,
            start: moment(start).format(),
            end: moment(end).format()
        };

        if (_.isPlainObject(unit)) {
            params.measure = unit.apiUnit;
        } else if (_.isArray(unit)) {
            params.measure = unit.join();
        } else {
            params.measure = unit;
        }
        params.measure = params.measure.toUpperCase();
        if (moreParams) {
            _.assign(params, moreParams);
        }
        return collection.customGET(method, params);
    };

    /**
     * Returns monthly utility bill data uploaded to the platform
     * @param building - Restangular element representing the building for which data will be returned
     * @param start - start date (Date string, Date or moment)
     * @param end - end date (Date string, Date or moment)
     * @param measure - array of units (Strings or Unit objects) e.g. POWER, ENERGY
     */
    service.utilityBillData = function (building, start, end, measure) {
        if (!building) {
            return $q.when(null);
        }
        return building.customGET('utilityBillData', {
            measure: measure,
            start: moment(start).format(),
            end: moment(end).format()
        })
    };

    /**
     * Returns raw or aggregated data
     * @param collection - Restangular collection / element for which data will be returned
     * @param {string} interval - API interval (raw, 1min, 15min, 1h, 1d, 1mon)
     * @param start - start date (Date string, Date or moment)
        // IMPORTANT: if you are working with timezone ALWAYS send moment object to start and end param
     * @param end - end date (Date string, Date or moment)
     * @param units - array of units (Strings or Unit objects)
     * @param moreParams - (optional) object with additional parameters
     */
    service.data = function (collection, interval, start, end, units, moreParams) {
        return apiCall('data', collection, interval, start, end, units, moreParams);
    };

    /**
     * Returns temperature data
     * @param element - Restangular element for which data will be returned
     * @param {string} interval - API interval (raw, 1min, 15min, 1h, 1d, 1mon)
     * @param start - start date (Date string, Date or moment)
     * @param end - end date (Date string, Date or moment)
     * @param moreParams - extra api parameters (object)
     */
    service.temperature = function (element, interval, start, end, moreParams) {
        if (!element) {
            return emptyPromise;
        }

        const params = {
            interval: interval,
            start: moment(start).format(),
            end: moment(end).format()
        };

        if (moreParams) {
            _.assign(params, moreParams);
        }

        return element.customGET('temperature', params);
    };

    /**
     * Returns outdoor humidity data
     * @param element - Restangular element for which data will be returned
     * @param {string} interval - API interval (raw, 1min, 15min, 1h, 1d, 1mon)
     * @param start - start date (Date string, Date or moment)
     * @param end - end date (Date string, Date or moment)
     * @param moreParams - extra api parameters (object)
     */
    service.outdoorHumidity = function (element, interval, start, end, moreParams) {
        if (!element) {
            return emptyPromise;
        }

        const params = {
            interval: interval,
            start: moment(start).format(),
            end: moment(end).format()
        };

        if (moreParams) {
            _.assign(params, moreParams);
        }

        return element.customGET('outdoorHumidity', params);
    };

        /**
     * Returns wet bulb temperature data
     * @param element - Restangular element for which data will be returned
     * @param {string} interval - API interval (raw, 1min, 15min, 1h, 1d, 1mon)
     * @param start - start date (Date string, Date or moment)
     * @param end - end date (Date string, Date or moment)
     * @param moreParams - extra api parameters (object)
     */
    service.wetBulbTemperature = function (element, interval, start, end, moreParams) {
        if (!element) {
            return emptyPromise;
        }

        const params = {
            interval: interval,
            start: moment(start).format(),
            end: moment(end).format()
        };

        if (moreParams) {
            _.assign(params, moreParams);
        }

        return element.customGET('wetBulbTemperature', params);
    };

    /**
     * Returns Weather Normalized data for the building over the time period
     * @param building Restangular element
     * @param {string} interval - API interval (1d, 1mon)
     * @param start - start date (Date string, Date or moment)
     * @param end - end date (Date string, Date or moment)
     */
    service.weatherNormalization = function (building, interval, start, end) {
        if (!building) {
            return $q.when(null);
        }
        return building.customGET('weatherNormalization', {
            interval: interval,
            start: moment(start).format(),
            end: moment(end).format()
        })
    };

    /**
     * Returns Expected energy data for the building over the time period
     * @param building Restangular element
     * @param {string} interval - API interval (1d, 1mon)
     * @param start - start date (Date string, Date or moment)
     * @param end - end date (Date string, Date or moment)
     */
    service.expectedEnergy = function (building, interval, start, end) {
        if (!building) {
            return $q.when(null);
        }

        return building.customGET('expectedEnergy', {
            interval: "15min",
            start: moment(start).format(),
            end: moment(end).format()
        });
    };

    /**
     * Returns HDD & CDD data
     * @param element - Restangular element for which data will be returned
     * @param {string} interval - API interval (1d, 1mon)
     * @param start - start date (Date string, Date or moment)
     * @param end - end date (Date string, Date or moment)
     */
    service.degreeDays = function (element, interval, start, end) {
        if (!element) {
            return emptyPromise;
        }
        const degreeDayInterval = interval == '1mon' ? interval : '1d';
        return element.customGET('degreeDays', {
            interval: degreeDayInterval,
            start: moment(start).format(),
            end: moment(end).format()
        });
    };

    /**
     * Returns Metrics data
     * @param collection - Restangular collection / element for which data will be returned
     * @param {string} interval - API interval (raw, 1min, 15min, 1h, 1d, 1mon)
     * @param start - start date (Date string, Date or moment)
     * @param end - end date (Date string, Date or moment)
     * @param units - array of units (Strings or Unit objects)
     * @param moreParams - (optional) object with additional parameters
     */
    service.metrics = function (collection, interval, start, end, units, moreParams) {
        return apiCall('metrics', collection, interval, start, end, units, moreParams);
    };

    /**
     * Returns Metrics Scheduling data for a calendar
     * @param collection - Restangular collection / element for which data will be returned
     * @param interval - API interval (raw, 1min, 15min, 1h, 1d, 1mon)
     * @param start - start date (Date string, Date or moment)
     * @param end - end date (Date string, Date or moment)
     * @param units - array of units (Strings or Unit objects)
     * @param calendar - work calendar
     * @param moreParams - (optional) object with additional parameters
     */
    service.metricsScheduling = function (collection, interval, start, end, units, calendar, moreParams?) {
        if (!calendar) {
            throw new Error("Calendar is not set for metricsScheduling");
        }
        return apiCall('metrics', collection, interval, start, end, units, _.extend(moreParams || {}, {
            partitionType: 'workcalendar',
            partitionId: calendar.id
        }));
    };

    /**
     * Returns Evaluate data
     * @param collection - Restangular collection / element for which data will be returned
     * @param {string} interval - API interval (raw, 1min, 15min, 1h, 1d, 1mon)
     * @param start - start date (Date string, Date or moment)
     * @param end - end date (Date string, Date or moment)
     * @param baseStart - baseline start date (Date string, Date or moment)
     * @param baseEnd - baseline end date (Date string, Date or moment)
     * @param units - array of units (Strings or Unit objects)
     * @param moreParams - (optional) object with additional parameters
     */
    service.evaluate = function (collection, interval, start, end, baseStart, baseEnd, units, moreParams) {
        baseStart = moment(baseStart).format();
        baseEnd = moment(baseEnd).format();
        return $q.all([
            apiCall('metrics', collection, interval, start, end, units, moreParams),
            apiCall('metrics', collection, interval, baseStart, baseEnd, units, moreParams)
        ]).then(function (results) {
            return {
                report: results[0],
                baseline: results[1]
            }
        });
    };

    /**
     * Returns peak kW data
     * @param queryable - Restangular element for which data will be returned (not collection)
     * @param start - start date (Date string, Date or moment)
     * @param end - end date (Date string, Date or moment)
     */
    service.peak = function (queryable, start, end) {
        return queryable.customGET('peak', {
            start: moment(start).format(),
            end: moment(end).format()
        });
    };

    /**
     * Returns peak kW data for current and previous period.
     * Dates can be omitted. In that case it will take start and end date from queryable.
     *
     * @param start - start date (Date string, Date or moment)
     * @param end - end date (Date string, Date or moment)
     * @param baseStart - baseline start date (Date string, Date or moment)
     * @param baseEnd - baseline end date (Date string, Date or moment)
     *
     * @param queryable - Restangular element for which data will be returned (not collection)
     */
    service.peaks = function (queryable, start, end, baseStart, baseEnd) {
        if (!_.hasValue(start, end)) {
            start = queryable.start;
            end = queryable.end;
        }
        if (!_.hasValue(baseStart, baseEnd)) {
            // this is probably wrong as any
            const diff: any = moment(end).diff(moment(start));
            baseStart = moment(start).subtract(diff);
            baseEnd = moment(end).subtract(diff);
        }
        return $q.all([
            service.peak(queryable, start, end),
            service.peak(queryable, baseStart, baseEnd)
        ]).then(function (peaks) {
            return {
                current: peaks[0],
                previous: peaks[1]
            }
        });
    };


    return service;
});
