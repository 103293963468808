namespace aq.admin {
    export class ProjectLeadModalCtrl {

        public projectLead: aq.user.User;
        public emailScheduledDate: Date;
        /* @ngInject */
        constructor(
            private $mdDialog: ng.material.IDialogService,
            private users: aq.user.User[]
        ) {
            this.emailScheduledDate = new Date();
        }

        public selectProjectLead() {
            if (!this.projectLead || !this.projectLead.email || !this.emailScheduledDate) {
                return;
            }
            const data = {
                isProjectLead: true,
                projectLead: this.projectLead,
                emailScheduledDate: this.emailScheduledDate
            };
            this.$mdDialog.hide(data);
        }

        public noProjectLead() {
            if (!this.emailScheduledDate) {
                return;
            }
            const data = {
                isProjectLead: false,
                projectLead: null,
                emailScheduledDate: this.emailScheduledDate
            };
            this.$mdDialog.hide(data);
        }

        public cancel() {
            this.$mdDialog.cancel();
        }
    }
    angular.module('aq.admin.accounts.accounts').controller('ProjectLeadModalCtrl', ProjectLeadModalCtrl);
}

