namespace aq.ui {

    export class AqNestedSidebarNavItemController implements common.models.SidebarNavItem {

        public isLargeScreen: () => boolean;
        public isMediumScreen: () => boolean;
        public sidebarNavClose: () => void;
        public parent: AqNestedSidebarNavController;
        public title: string;
        public state: string;
        public category: any;
        public stateData: any;
        public stateOptions: any;
        public icon: string;
        public aria: string;
        public subMenuItems: any[];
        public activeItem: string;
        public goToCategoryFn: (category: string) => void;
        public actionFn: () => boolean;
        private isTooltipVisible: boolean;
        private htmlColor: string;

        /* @ngInject */
        constructor(private NestedSidebarNavHelperService: aq.services.NestedSidebarNavHelperService, private $mdMedia: angular.material.IMedia) {
            this.title = '';
            this.state = '';
            this.stateData = null;
            this.stateOptions = null;
            this.icon = '';
            this.aria = '';
            this.subMenuItems = [];
        }
        public $onChanges(changesObj) {
            const navItem: common.models.SidebarNavItem = {
                title: changesObj.title ? changesObj.title.currentValue : '',
                state: changesObj.state ? changesObj.state.currentValue : '',
                stateData: changesObj.stateData ? changesObj.stateData.currentValue : null,
                stateOptions: changesObj.stateOptions ? changesObj.stateOptions.currentValue : null,
                icon: changesObj.icon ? changesObj.icon.currentValue : '',
                aria: changesObj.aria ? changesObj.aria.currentValue : ''
            };

            this.NestedSidebarNavHelperService.populateMissingMenuItemData(navItem);
            // prevent resetting values which have already been initialized (i.e. late binding of building selector)
            if (!this.title) { this.title = navItem.title; }
            if (!this.state) { this.state = navItem.state; }
            if (!this.icon) { this.icon = navItem.icon; }
            if (!this.aria) { this.aria = navItem.aria; }
            if (!this.stateData) { this.stateData = navItem.stateData; }
            if (!this.stateOptions) { this.stateOptions = navItem.stateOptions; }
            if (!this.icon) {
                this.htmlColor = this.NestedSidebarNavHelperService.getColorFromString(this.title);
            }
        }
        public $onInit() {
            this.isLargeScreen = () => this.parent.isLargeScreen();
            this.isMediumScreen = () => this.parent.isMediumScreen();
            this.sidebarNavClose = () => this.parent.close();
        }
        private setMouseOver(isOver: boolean) {
            this.isTooltipVisible = isOver;
        }
        private isTooltipEnabled(): boolean {
            return !this.isLargeScreen() && this.isMediumScreen() || (this.title && this.title.length > 22) && this.$mdMedia('min-width: 457px');
        }
        private isMaterialIcon(): boolean {
            return this.icon && this.icon.substring(0, 1) != '.';
        }
        private parseNonMaterialIcon(): string {
            let iconClass = this.icon.substring(1);
            const sizeClass = this.NestedSidebarNavHelperService.getIconSizeClass(iconClass);
            if (sizeClass) {
                iconClass = iconClass + ' ' + sizeClass;
            }
            return iconClass;
        }
        private getIconLetter() {
            return (this.title && this.title.length > 0) ? this.title.substring(0, 1) : '';
        }
        private isCategoryActive(category: string): boolean {
            return category === this.activeItem;
        }
        private navItemClick(value: any) {
            this.goToCategoryFn(value);
            this.closeIfMobile();
        }
        private closeIfMobile() {
            if (!this.isLargeScreen()) {
                this.sidebarNavClose();
                this.isTooltipVisible = false;
            }
        }
    }

    angular
        .module('aq.ui')
        .component('aqNestedSidebarNavItem', {
            templateUrl: 'app/common/directives/aqNestedSidebarNav/aqNestedSidebarNavItem/aqNestedSidebarNavItem.html',
            controller: AqNestedSidebarNavItemController,
            controllerAs: 'vm',
            bindings: {
                title: '@',
                state: '@?',
                stateData: '<?',
                stateOptions: '<?',
                icon: '@?',
                aria: '@?',
                activeItem: '@',
                actionFn: '&?',
                subMenuItems: '<?',
                goToCategoryFn: '=',
                category: '<?'
            },
            require: {
                parent: '^^aqNestedSidebarNav'
            }
        });
}
