namespace aq.settings {
    export class SettingsIntegrationDetailsCtrl {
        private page: number = 1;
        private endOfLogs: boolean = false;
        private fetchInProgress: boolean = false;
        private addInProgress: boolean = false;
        private viewIntegration: {
            username: string;
            password: string;
            authToken: string;
            workers: any[];
        };
        private changesMade: number = 0;
        private accountSettingsAccess: common.models.AppAccessObject;
        /* @ngInject */
        constructor(
            private $scope,
            private $mdDialog: any,
            private Restangular: restangular.IService,
            private Messages: any,
            private allServices: any[],
            private integration: any,
            private $state: any,
            private Errors: any,
            private FullScreenService: aq.services.FullScreenService,
            private integrationLogs,
            private integrationApp,
            private accountId,
            private account: aq.common.models.Account,
            private loading,
            private authAccess: common.models.AuthAppAccess
        ) {
            if (integration.id) {
                $scope.integration = integration;
            } else {
                $scope.integration = this.attachServicesToIntegration(accountId, integrationApp);
            }
            $scope.accountId = accountId;

            $scope.logs = integrationLogs;
            $scope.workers = allServices;
            $scope.logStatusCount = _.countBy(integrationLogs, (log: any) => log.logStatus);
            if ($scope.logStatusCount.WARNING === undefined) $scope.logStatusCount.WARNING = 0;
            if ($scope.logStatusCount.ERROR === undefined) $scope.logStatusCount.ERROR = 0;
            if ($scope.logStatusCount.INFO === undefined) $scope.logStatusCount.INFO = 0;
            $scope.integrationApp = integrationApp;
            this.resetView();
            this.accountSettingsAccess = this.authAccess['Account Settings'];
        }

        resetView() {
            this.viewIntegration = {
                username: this.$scope.integration.username,
                password: this.$scope.integration.password,
                authToken: this.$scope.integration.authToken,
                workers: _.cloneDeep(this.$scope.integration.workers)
            };
            this.changesMade = 0;
        }

        changesPerformed() {
            this.changesMade++;
        }

        attachServicesToIntegration(accountId, integrationApp) {
            let workers;
            // workaround because current setup is depending on parameters to identify source
            if (integrationApp.mandatoryParameters.length > 0) {
                workers = _(integrationApp.mandatoryParameters).map('service').uniq().map(service => {
                    return {
                        workerClassName: service,
                        enabled: true
                    };
                }).value();
            } else {
                workers = _(integrationApp.workers).map(worker => {
                    return {
                        workerClassName: worker.workerClassName,
                        enabled: true
                    };
                }).value();
            }
            return {
                account: accountId,
                integrationApp: integrationApp.id,
                workers
            };
        }


        deleteIntegration(ev) {
            const confirm = this.$mdDialog.confirm()
                .title('Are you sure you want to delete this integration?')
                .ariaLabel('Delete Integration')
                .targetEvent(ev)
                .ok('Ok')
                .cancel('Cancel');
            this.$mdDialog.show(confirm)
                .then(() => {
                    this.$scope.integration.remove()
                        .then(() => {
                            const integrationApp = _.find(this.$scope.integrationApps, { id: this.$scope.integration.integrationApp.id });
                            integrationApp['installed'] = false;
                            _.remove(this.$scope.myIntegrationApps, { id: this.$scope.integration.integrationApp.id });
                            this.Messages.success('Integration successfully deleted.');
                            this.$state.go('aq.settings.integrations');
                        });
                });

        }

        updateIntegration() {
            this.changesMade = 0;
            if (this.$scope.integration.integrationApp.authType === 'BASIC_AUTH') {
                this.$scope.integration.username = this.viewIntegration.username;
                this.$scope.integration.password = this.viewIntegration.password;
            } else if (this.$scope.integration.integrationApp.authType === 'AUTH_TOKEN') {
                this.$scope.integration.authToken = this.viewIntegration.authToken;
            }
            this.$scope.integration.workers = this.viewIntegration.workers;
            // GSON does not accept date time with Z suffix, moment format removes the Z
            _.each(this.$scope.integration.workers, function (worker) {
                if (worker.lastRun != null) {
                    worker.lastRun = moment(worker.lastRun).format();
                }
            });
            this.$scope.integration.put().then(() => {
                this.Messages.success('Integration successfully updated');
                this.resetView();
            }).catch(this.Errors.forPromise());
        }

        createIntegration(ev) {
            this.$mdDialog.show({
                preserveScope: true,
                scope: this.$scope,
                templateUrl: 'app/settings/integrations/details/createIntegrationDialog.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                fullscreen: this.FullScreenService.fullScreen
            });
        }

        cancel() {
            this.$mdDialog.hide();
        }

        getWorker(name) {
            return _.find(this.$scope.integration.workers, function (worker: any) {
                return worker.workerClassName === name;
            });
        }

        add() {
            if (this.addInProgress) {
                return;
            }
            this.addInProgress = true;
            this.Restangular.one('accounts', this.$scope.accountId).post('integrations', this.$scope.integration)
                .then((result) => {
                    const integrationApp: any = _.find(this.$scope.integrationApps, { id: this.$scope.integration.integrationApp });
                    integrationApp['installed'] = true;
                    integrationApp['integrationId'] = result.id;
                    this.$scope.myIntegrationApps.push(integrationApp);
                    this.Messages.success('Integration created successfully.');
                    this.$state.go('aq.settings.integrations.details', { accountId: this.$scope.accountId, integrationId: result.id });
                    let state = 'aq.settings.integrations.details';
                    if (integrationApp.uiState) {
                        state = `${state}.${integrationApp.uiState}`;
                    }
                    this.$state.go(state, { accountId: this.$scope.accountId, integrationId: result.id });
                }, () => {
                    this.cancel();
                    this.Messages.error('Integration could not be created. Please contact an administrator.');
                })
                .finally(() => {
                    this.addInProgress = false;
                });
        }

        splitFeatures(features: string): string[] {
            return _.split(features, '|');
        }

        fetchMoreLogs(): void {
            if (this.integration && this.integration.id && !this.endOfLogs && !this.fetchInProgress) {
                this.loading.start();
                this.page += 1;
                this.fetchInProgress = true;
                this.account.one('integrations', this.integration.id).customGET('logs', { page: this.page, pageSize: 25 })
                    .then((logs) => {
                        if (logs && logs.length > 0) {
                            this.$scope.logs = _.concat(this.$scope.logs, logs);
                        } else {
                            this.endOfLogs = true;
                        }
                        this.fetchInProgress = false;
                        this.loading.stop();
                    })
                    .catch(() => {
                        this.loading.stop();
                    });
            }
        }
    }

    angular
        .module('aq.settings')
        .controller('SettingsIntegrationDetailsCtrl', SettingsIntegrationDetailsCtrl);
}
