namespace aq.energyInsights {
    export class PeakDemandService extends InsightService {
        /* @ngInject */
        constructor(
            private EnergyInsightsDataService: aq.energyInsights.service.EnergyInsightsDataService,
            private $q: ng.IQService,
            private $filter,
            private Restangular,
            private DataStore,
            private Auth: aq.services.Auth,
            private $translate
        ) {
            super();
        }

        public getSimpleTargetModel(building: aq.common.models.Building, measure: string, periodSearch: PeriodSearch, name: string): SimpleTargetModel {
            let targetModel = new SimpleTargetModel();
            targetModel.title = this.$translate.instant('insight.Peak Demand');
            return targetModel;
        }

        public getTargetModel(
                    building: aq.common.models.Building,
                    measure: string,
                    periodSearch: PeriodSearch,
                    account: aq.common.models.Account,
                    name: string
                ): ng.IPromise<TargetModel> {
            let metric;
            return this.getKwMetric(account).then((m) => {
                metric = m;
                return this.DataStore.getList(
                        this.Restangular
                        .one('accounts', account.id)
                        .one('buildings', building.id)
                        .one('Targets'), 'queryTargets', {
                    startDate: moment().subtract(2, 'year').format(),
                    endDate: moment().format(),
                    targetType: 'PEAK_DEMAND'
                });
            }).then((target) => {
                return this.EnergyInsightsDataService.getPeakData(building, periodSearch.start, periodSearch.end, metric, target, account, null);
            })
                .then((peaks) => {
                    return this.calculateTargetModel(building, peaks, name);
                });
        }

        public getGraphModel(building: aq.common.models.Building, measure: string, timePeriod: PeriodSearch): ng.IPromise<GraphModel> {
            return null;
        }

        public getTableModel(building: aq.common.models.Building, measure: string, periodSearch: PeriodSearch): ng.IPromise<TableModel> {
            return null;
        }

        public formatTableModel() {
            throw new TypeError('This is a stub method');
        }

        // ----------------------

        private getKwMetric(account: aq.common.models.Account) {
            return this.Restangular.one('accounts', account.id).customGET('queryRealUnits').then(function (allUnits) {
                return _.find(allUnits, { unit: 'kW' });
            });
        }

        public calculateTargetModel(building, peaks, name): ng.IPromise<TargetModel> {
            // Q&A
            const targetModel = new TargetModel();
            targetModel.title = name;
            const totals: any = {};
            const chartData = _.map(_.map(peaks, 'metric'), 'chartValue');
            const maxPeakIndex = chartData.indexOf(Math.max.apply(Math, chartData));
            targetModel.question = `${this.$translate.instant('insight.question.Peak')} ${building.name}?`;
            targetModel.buildingId = building.id;
            if (maxPeakIndex !== -1) {
                targetModel.answer = peaks[maxPeakIndex]['metric'].chartValue;
                targetModel.formattedAnswer = `${targetModel.answer} kW`;
                targetModel.when = `${this.$translate.instant('insight.when.Peak')} ${moment.tz(peaks[maxPeakIndex].date.raw, building.timeZoneId).format('llll z')}`,
                    // update total row
                    totals.peak = {
                        tableValue: peaks[maxPeakIndex]['metric'].tableValue,
                        chartValue: peaks[maxPeakIndex]['metric'].chartValue
                    };
                totals.target = {
                    tableValue: peaks[maxPeakIndex]['target'].tableValue,
                    chartValue: peaks[maxPeakIndex]['target'].chartValue
                };

                totals.corey = (peaks[maxPeakIndex]['metric'].chartValue <= peaks[maxPeakIndex]['target'].chartValue);
                targetModel.target = peaks[maxPeakIndex]['target'].chartValue;
                targetModel.showTarget = !_.isNull(targetModel.target);
                targetModel.color = this.getColor(peaks[maxPeakIndex]['metric'].chartValue, peaks[maxPeakIndex].target.chartValue);
                targetModel.iconColor = targetModel.color;
                if (targetModel.target) {
                    totals.percent = this.getPercent(peaks[maxPeakIndex]['metric'].chartValue, peaks[maxPeakIndex]['target'].chartValue);
                    targetModel.tooltip = `<b>${this.$translate.instant('insight.tooltip.Peak1')}</b> <span style="color: ${targetModel.color}">
                        ${this.$filter('number')(totals.percent, '0,0')}%</span> <b>${this.$translate.instant('insight.tooltip.Peak2')}</b>
                        ${peaks[maxPeakIndex].target.tableValue}
                        ${peaks[maxPeakIndex].target.unit}`;
                    targetModel.percentage = totals.percent;
                    targetModel.icon = this.getIcon(targetModel.answer, targetModel.target);
                    targetModel.formattedTarget = `${this.$translate.instant('insight.target.Target')} ${this.$filter('number')(targetModel.target, '0,0')} kW`;
                }
            }
            return this.$q.when(targetModel);
        }

        getIcon(peak, targetPeak) {
            const status = this.getStatus(peak, targetPeak);
            if (status === 'under') {
                return 'check_box';
            } else if (status === 'warn') {
                return 'warning';
            } else if (status === 'over') {
                return 'error';
            }
        }

        getPercent(part, total) {
            if (total > 0) {
                return (part / total) * 100;
            } else {
                return 0;
            }
        }

        getStatus(maxPeak, targetPeak): string {
            const ratio = maxPeak / targetPeak;
            if (maxPeak > targetPeak) {
                return 'over';
            } else if ((1 - ratio) <= .2) {
                return 'warn';
            } else {
                return 'under';
            }
        }

        getColor(peak, targetPeak): string {
            if (!targetPeak) {
                return '#979797';
            }
            const status = this.getStatus(peak, targetPeak);
            switch (status) {
                case 'under':
                    return '#7ACF46';
                case 'warn':
                    return '#EFAF55';
                case 'over':
                    return '#C33';
                default:
                    return '';
            }
        }
    }

    angular.module('energyInsights').service('PeakDemandService', PeakDemandService);
}
