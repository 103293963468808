namespace aq.projectCenter {
    export class ProjectOverviewCardComponent {
        public project: Project;
        public onDelete: Function;
        public onEdit: Function;

        /* @ngInject */
        constructor() {}

        deleteProject(ev) {
            this.onDelete({projectId: this.project.id});
        }

        editProject() {
            this.onEdit({projectId: this.project.id});
        };

    }
    angular.module('projectCenter')
    .component('projectOverviewCard', {
        templateUrl:  'app/projectCenter/overview/projectOverviewCard.html',
        bindings: {
            project:  '<',
            onDelete: '&',
            onEdit: '&'
        },
        controller: ProjectOverviewCardComponent,
        controllerAs: 'vm'
    });
}