'use strict';

angular
.module('aq.admin.accounts.sources')
.controller('AdminSourcesCtrl', function($scope, Restangular, Errors, $timeout, Messages, $modal, $q, Pager, $aq) {

    Restangular.all('accounts').get('queryAllAccounts').then(function(accounts) {
        $scope.accounts = accounts;
        Restangular.one('users', 'me').get().then(function(user) {
            $scope.selectedAccount = _.find(accounts, { id: $scope.account.id });
            getSourcesForAccount($scope.selectedAccount);
        });
    });

    $scope.modal = {};
    $scope.queryValue = null;
    $scope.isLoading = true;

    $scope.querySources = function(value) {
        $scope.isLoading = true;
        $scope.sources = null;
        $scope.scroller = Pager.scroller(1, function(page) {
            return Restangular.all('accounts').get('querySources', {value: value, page: page});
        }, function(sources) {
            if(!$scope.sources) {
                $scope.sources = sources;
            } else {
                _.each(sources, function(iteratee) {
                    $scope.sources.push(iteratee);
                });
            }
            $scope.isLoading = false;
        }, function() {
            $scope.isLoading = false;
            Messages.error('Error occured while trying to find a source.');
        });
        $scope.scroller.nextPage();
    }

    $scope.deleteSource = function(source) {
        source = restangularizeSource($scope.selectedAccount, source.building, source);
        $aq.delete($scope.sources, source);
    }

    $scope.showModal = function(model) {
        $scope.modal.model = model.originalElement;
        $scope.modal.title = model.name;
        modal.$promise.then(modal.show);
    }

    $scope.isTableVisible = function() {
        return _.size($scope.sources) > 0 ? true : false;
    }

    $scope.getSourcesForAccount = function(account) {
        getSourcesForAccount(account);
    }

    function getSourcesForAccount(account) {
        $scope.isLoading = true;
        if(account.id != account[0]) $scope.sources = null;
        $scope.scroller = Pager.scroller(1, function(page) {
            return Restangular.one('Accounts', account.id).getList('queryAllSources', {page: page});
        }, function(sources) {
            if(!$scope.sources) {
                $scope.sources = sources;
            } else {
                _.each(sources, function(iteratee) {
                    $scope.sources.push(iteratee);
                });
            }
            $scope.isLoading = false;
        });
        $scope.scroller.nextPage();
    }

    function restangularizeSource(account, buildingId, source) {
        var account = Restangular.restangularizeElement(null, account, 'accounts');
        var building = account.one('buildings', buildingId);
        return Restangular.restangularizeElement(building, source, 'sources');      
    }

    var modal = $modal({
        templateUrl: 'app/admin/custom/directives/modalFieldParser/modal.html',
        persist: true, 
        show: false, 
        backdrop: 'static', 
        scope: $scope
    });
});