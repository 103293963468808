namespace aq.tenantbilling {
    export class DeviceDataChartingCtrl {
        public chartConfig: any;
        private device: aq.common.models.Device;
        private monthlyBilling: MonthlyTenantBilling;
        private chargeValue: TenantChargeValue;
        private building: aq.common.models.Building;
        private collectorHierarchy;
        /* @ngInject */
        constructor(
            private $q: ng.IQService,
            private Restangular: restangular.IService,
            private $filter: ng.IFilterService,
            private GraphData: aq.dashboard.widgets.GraphData,
            private DataService,
            private OptionsService,
            private Auth: aq.services.Auth
        ) {

        }

        public $onInit() {
            const promises = [];
            const buildingElem = this.Restangular.one('accounts', this.building.account).one('buildings', this.monthlyBilling.building);
            const collectorElem = this.Restangular.copy(buildingElem).one('collectors', this.chargeValue.collector);
            const previousStartDate = this.getPreviousStartDate();
            const previousEndDate = this.getPreviousEndDate();
            const currentStartDate = this.getCurrentStartDate();
            const currentEndDate = this.getCurrentEndDate();

            // Query temperature
            if (previousStartDate && previousEndDate) {
                promises.push(this.DataService.temperature(buildingElem, '1d', previousStartDate, previousEndDate));
            }
            promises.push(this.DataService.temperature(buildingElem, '1d', currentStartDate, currentEndDate));

            // Query consumption
            let measure = this.getMeasure();
            switch (measure.toUpperCase()) {
                case 'STEAM':
                    measure = 'STEAM_MASS';
                    break;
                case 'HEAT':
                    measure = 'HEAT_TRANSFER';
                    break;
                default:
            }
            if (previousStartDate && previousEndDate) {
                promises.push(this.DataService.data(collectorElem, '1d', previousStartDate, previousEndDate, measure));
            }
            promises.push(this.DataService.data(collectorElem, '1d', currentStartDate, currentEndDate, measure));

            this.$q.all(promises).then((data) => {
                    this.chartConfig = this.buildChartConfig(data);
                });
        }

        // if data.length == 2:
        //      data[0] == current period temperature data
        //      data[1] == current period consumption data
        // if data.length == 4:
        //      data[0] == previous period temperature data
        //      data[1] == current period temperature data
        //      data[2] == previous period consumption data
        //      data[3] == current period consumption data
        public generateSeries(data: any[]): any[] {
            const unit = this.OptionsService.getUnitLabelByMeasure(this.getMeasure().toUpperCase());
            const temperatureUnit = this.OptionsService.temperatureUnit();
            let yAxis = 0;
            const series = [{
                name: `${this.chargeValue.meterName}`,
                type: 'column',
                tooltip: {
                    valueSuffix: ` ${unit.unit}`
                },
                yAxis: yAxis++,
                data: []
            }, {
                name: `Temperature`,
                type: 'line',
                dashStyle: 'shortdash',
                color: '#FFDA06',
                tooltip: {
                    valueSuffix: ` ${temperatureUnit.unit}`
                },
                yAxis: yAxis++,
                data: []
            }];
            const hasPrevious = data.length == 4;
            if (hasPrevious) {
                // previous
                series[1].data = _.concat(series[1].data, this.getTemperatureSeriesData(data, 0, temperatureUnit));
                series[0].data = _.concat(series[0].data, this.getConsumptionSeriesData(data, 2, unit));

                // current
                series[1].data = _.concat(series[1].data, this.getTemperatureSeriesData(data, 1, temperatureUnit));
                series[0].data = _.concat(series[0].data, this.getConsumptionSeriesData(data, 3, unit));
            } else {
                series[1].data = _.concat(series[1].data, this.getTemperatureSeriesData(data, 0, temperatureUnit));
                series[0].data = _.concat(series[0].data, this.getConsumptionSeriesData(data, 1, unit));
            }

            return series;
        }

        public getTemperatureSeriesData(data, dataIndex, unit) {
            const seriesData = [];
            for (let i = 0; i < data[dataIndex].timestamps.length; i++) {
                let value = this.$filter<Function>('toTemperatureUnit')(data[dataIndex].values[i], unit);
                if (value) {
                    value = Math.round(value);
                }
                seriesData.push({
                    x: data[dataIndex].timestamps[i],
                    y: value
                });
            }
            return seriesData;
        }

        public getConsumptionSeriesData(data, dataIndex, unit) {
            const seriesData = [];
            for (let i = 0; i < data[dataIndex].timestamps.length; i++) {
                let value = this.$filter<Function>('toUnit')(data[dataIndex].values[i], unit);
                value = this.GraphData.roundDataValue(value);
                seriesData.push({
                    x: data[dataIndex].timestamps[i],
                    y: value
                });
            }
            return seriesData;
        }

        public createYAxis() {
            const unit = this.OptionsService.getUnitLabelByMeasure(this.getMeasure().toUpperCase());
            const temperatureUnit = this.OptionsService.temperatureUnit();
            return [{
                apiUnit: unit.apiUnit,
                title: {
                    text: unit.label
                },
                labels: {
                    format: `{value} ${unit.unit}`
                }
            }, {
                apiUnit: temperatureUnit.apiUnit,
                title: {
                    text: 'Temperature'
                },
                labels: {
                    format: `{value} ${temperatureUnit.unit}`
                },
                opposite: true
            }];
        }

        public getOptimizationLink() {
            const unit = this.OptionsService.getUnitLabelByMeasure(this.getMeasure().toUpperCase());
            const startDate = this.getComparisonStartDate();
            const endDate = this.getComparisonEndDate();
            const collectorIds = _.join(_.map(this.collectorHierarchy.parents, id => `${id}::collectors`), '::::');
            const optimizationURI = URI('/accounts/' + this.building.account + '/optimization/building')
                .search({
                    unit: unit.unit,
                    apiUnit: unit.apiUnit,
                    interval: '1d',
                    off: _.without(this.collectorHierarchy.siblings, this.chargeValue.collector).join(','),
                    subject: `${this.building.account}::accounts::::${this.building.id}::buildings::::${collectorIds}`,
                    drill: 'meter',
                    start: startDate.tz(this.building.timeZoneId).format(),
                    end: endDate.tz(this.building.timeZoneId).format(),
                    timePreset: 'Custom',
                    children: 'collectors'
                });
            return optimizationURI.toString();
        }

        public buildChartConfig(data) {
            const series = this.generateSeries(data);
            const currentStartDate = this.getCurrentStartDate();
            const currentEndDate = this.getCurrentEndDate();
            if (!currentStartDate || !currentEndDate) {
                return {};
            }
            let subtitle = `${currentStartDate.format('MM/DD/YYYY')} - ${currentEndDate.format('MM/DD/YYYY')}`;
            const previousStartDate = this.getPreviousStartDate();
            const previousEndDate = this.getPreviousEndDate();
            if (previousStartDate && previousEndDate) {
                subtitle = `${previousStartDate.format('MM/DD/YYYY')} - ${previousEndDate.format('MM/DD/YYYY')} vs. ${subtitle}`;
            }
            const plotBands: any = [{
                from: currentStartDate.valueOf(),
                to: currentEndDate.valueOf(),
                label: {
                    text: 'Current Billing Period'
                }
            }];
            const plotLines = [{
                color: 'gray',
                dashStyle: 'longdash',
                value: currentStartDate.valueOf(),
                width: 1
            }, {
                color: 'gray',
                dashStyle: 'longdash',
                value: currentEndDate.valueOf(),
                width: 1
            }];
            const breaks = [];
            if (previousEndDate && previousStartDate) {
                plotBands.push({
                    from: previousStartDate.valueOf(),
                    to: previousEndDate.valueOf(),
                    color: '#e0faff',
                    label: {
                        text: 'Comparison Billing Period'
                    }
                });
                if (!currentStartDate.isSame(previousEndDate)) {
                    breaks.push({
                        from: previousEndDate.valueOf(),
                        to: currentStartDate.valueOf()
                    });
                }
                plotLines.push({
                    color: 'gray',
                    dashStyle: 'longdash',
                    value: previousEndDate.valueOf(),
                    width: 1
                });
                plotLines.push({
                    color: 'gray',
                    dashStyle: 'longdash',
                    value: previousStartDate.valueOf(),
                    width: 1
                });
            }
            return {
                chart: {
                    plotBorderWidth: 1,
                    zoomType: 'x',
                    panning: 'true',
                    panKey: 'shift'
                },
                legend: {
                    enabled: series.length > 1
                },
                title: {
                    text: `${this.chargeValue.meterName}`
                },
                subtitle: {
                    text: subtitle
                },
                tooltip: {
                    xDateFormat: '%A, %B %e, %Y',
                    shared: true
                },
                xAxis: [{
                    type: 'datetime',
                    tickLength: 0,
                    gridLineWidth: 1,
                    gridLineColor: '#c7c7c7',
                    plotLines,
                    plotBands,
                    breaks
                }],
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: true
                        }
                    }
                },
                plotOptions: {
                    line: {
                        animation: false,
                        marker: {
                            enabled: false
                        }
                    },
                    series: {
                        turboThreshold: 21000
                    }
                },
                navigator: {
                    enabled: true,
                    series: {
                        includeInCSVExport: false
                    }
                },
                scrollbar: {
                    enabled: true
                },
                rangeSelector: {
                    enabled: true,
                    inputEnabled: false,
                    buttons: [
                        { type: 'week', text: '1w', count: 1 },
                        { type: 'month', text: '1m', count: 1 },
                        { type: 'all', text: 'All' }
                    ]
                },
                yAxis: this.createYAxis(),
                series
            };
        }

        public getCurrentStartDate() {
            return moment(this.monthlyBilling.startReadingDate);
        }

        public getCurrentEndDate() {
            return moment(this.monthlyBilling.readingDate);
        }

        public getPreviousStartDate() {
            if (!this.monthlyBilling.lastStartReadingDate) {
                return null;
            }
            return moment(this.monthlyBilling.lastStartReadingDate);
        }

        public getPreviousEndDate() {
            if (!this.monthlyBilling.lastReadingDate) {
                return null;
            }
            return moment(this.monthlyBilling.lastReadingDate);
        }

        public getComparisonStartDate() {
            let startDate = this.getPreviousStartDate();
            if (!startDate) {
                startDate = this.getCurrentStartDate();
            }
            return startDate;
        }

        public getComparisonEndDate() {
            return this.getCurrentEndDate();
        }

        public getMeasure() {
            let measure = this.chargeValue.serviceType;
            if (this.device && this.device.measure) {
                measure = this.device.measure;
            }
            return measure;
        }
    }

    angular
        .module('tenantBilling')
        .component('deviceDataCharting', {
            templateUrl: 'app/tenantBilling/invoicing/run/editChargeValue/components/deviceDataCharting/deviceDataCharting.html',
            controller: DeviceDataChartingCtrl,
            controllerAs: 'vm',
            bindings: {
                device: '<',
                monthlyBilling: '<',
                chargeValue: '<',
                building: '<',
                collectorHierarchy: '<'
            }
        });
}
