namespace aq.services {
    export class DeviceService {
        /* @ngInject */
        constructor(
            private DataStore: aq.common.DataStore,
            private Messages: aq.services.Messages,
            private RestangularV3: restangular.IService,
            private $state: ng.ui.IStateService,
            private $mdDialog: ng.material.IDialogService,
            private $mdMedia: ng.material.IMedia
        ) {}

        public get(id: number): ng.IPromise<aq.common.models.DeviceElement> {
            return this.DataStore.get(this.RestangularV3.one('devices', id), {}, true);
        }

        public create(device: aq.common.models.DeviceCreationRequest): ng.IPromise<aq.common.models.DeviceElement> {
            const cleanDevice = this.cleanFields(device);
            return this.DataStore.create(this.RestangularV3.all('devices'), cleanDevice)
                .then((newDevice) => {
                    this.Messages.info('Device created');
                    return newDevice;
                });
        }

        public bulkCreate(deviceList: Array<aq.common.models.DeviceCreationRequest>) {
            const cleanDeviceList = deviceList.map((device) => {
                return this.cleanFields(device);
            });
            return this.RestangularV3.one('devices').customPOST(cleanDeviceList, 'bulk-create-devices')
        }

        public save(device: aq.common.models.DeviceElement, showMessage = true): ng.IPromise<aq.common.models.DeviceElement> {
            const deviceCopy: aq.common.models.DeviceElement = this.RestangularV3.copy(device);
            if (deviceCopy.viewOnly) {
                if (deviceCopy.viewOnly.tenants) {
                    deviceCopy.tenantLinks = deviceCopy.viewOnly.tenants
                    .map((tenant) => ({
                        id: null,
                        active: false,
                        tenant: {
                            id: tenant.id,
                            name: tenant.name
                        }
                    }));
                }
                deviceCopy.deviceClass = deviceCopy.viewOnly.selectedDeviceClass;
                delete deviceCopy.viewOnly;
            }

            if (deviceCopy.linkedBuildings) {
                deviceCopy.linkedBuildings = deviceCopy.linkedBuildings
                .map((linked) => ({
                    id: linked.id,
                    name: linked.name
                }));
            }

            return this.DataStore
                .update(deviceCopy)
                .then((updated) => {
                    this.refreshViewFields(updated);
                    if (showMessage) {
                        this.Messages.info('Device Saved');
                    }
                    return updated;
                });
        }

        public updateMultiplier(collectorId: number, newMultiplier, event) {
            const confirm = this.$mdDialog
                .confirm()
                .title(`The pulse multiplier for this device has been updated.
                Please choose if this multiplier should be applied to all historic and future data, or future data only.`)
                .targetEvent(event)
                .ok('Historic and Future Data')
                .cancel('Future Data Only');

            return this.$mdDialog
                .show(confirm)
                .then(() => {
                    this.RestangularV3.one('collectors', collectorId).customPOST(null, 'updateMultiplier', {newMultiplier});
                })
                .catch((err) => {
                    if (err) {
                        this.Messages.error('Error updating pulse multiplier');
                    }
                });
        }

        public delete(event, device: aq.common.models.DeviceElement): ng.IPromise<aq.common.models.DeviceElement[]> {
            const cleanDevice: aq.common.models.Device = this.cleanFields(device);
            const confirm = this.$mdDialog
                .confirm()
                .title('Are you sure you want to delete this device?')
                .targetEvent(event)
                .ok('Confirm')
                .cancel('Cancel');

            return this.$mdDialog
                .show(confirm)
                .then(() => {
                    this.DataStore.delete(this.RestangularV3.one('devices', cleanDevice.id));
                })
                .then((devices) => {
                    this.Messages.info('Device Deleted');
                    this.$state.go('aq.deviceManagement.building', {}, {reload: true});
                    return devices;
                })
                .catch((err) => {
                    this.Messages.error('Error deleting Device, please try again later');
                    return err;
                });
        }

        public saveReading(manualReading: aq.common.models.ManualReading) {
            delete manualReading.viewOnly;
            return this.DataStore.create(this.RestangularV3.one('collectors', manualReading.collector).all('manualReadings'), manualReading)
                .then((manualReadings: aq.common.models.ManualReading[]) => {
                    this.Messages.success('New reading created');
                    return manualReadings;
                })
                .catch((error) => {
                    this.Messages.error('Error creating reading');
                    return null;
                });
        }

        public updateReading(manualReading: aq.common.models.ManualReadingElement) {
            const readingCopy: any = this.RestangularV3.copy(manualReading);
            delete readingCopy.viewOnly;
            return this.DataStore.update(readingCopy)
                .then((manualReadings: aq.common.models.ManualReading[]) => {
                    this.Messages.success('Reading updated');
                    return manualReadings;
                })
                .catch((error) => {
                    this.Messages.error('Error updating reading');
                    return null;
                });
        }

        public deleteReading(event, manualReading: aq.common.models.ManualReadingElement) {
            const confirm = this.$mdDialog
                .confirm()
                .title('Are you sure you want to delete this reading?')
                .targetEvent(event)
                .ok('Confirm')
                .cancel('Cancel');

            return this.$mdDialog
                .show(confirm)
                .then(() => {
                    return this.DataStore.delete(manualReading);
                })
                .then(() => {
                    this.Messages.success('Reading removed successfully');
                    return true;
                });
        }

        public getAvailablePulseInputs(device: aq.common.models.Device): ng.IPromise<string[]> {
            return this.RestangularV3
                .one('devices', device.id)
                .all('unassigned-pulse-points')
                .getList();
        }

        public getAvailableLinks(device: aq.common.models.DeviceElement) {
            return this.DataStore.get(device.all('available-links'));
        }

        public getAuditLog(device: aq.common.models.DeviceElement) {
            return this.DataStore.get(device.all('audit'));
        }

        public viewAuditLogs(device: aq.common.models.DeviceElement) {
            return this.getAuditLog(device)
                .then((revisions) => {
                    return (this.$mdDialog).show({
                        controller: 'ViewAuditLogsCtrl as vm',
                        templateUrl: 'app/deviceManagement/actions/viewAuditLogs/viewAuditLogs.html',
                        clickOutsideToClose: false,
                        fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                        locals: {
                            device,
                            revisions: revisions
                        }
                    });
                })
        }

        public addLink(device: aq.common.models.DeviceElement, createLink: boolean = false): ng.IPromise<aq.common.models.DeviceLink[]> {
            return this.getAvailableLinks(device)
                .then((availableLinks) => {
                    return (this.$mdDialog as any).show({
                        controller: 'AddDeviceLink as vm',
                        templateUrl: 'app/deviceManagement/actions/addDeviceLink/addDeviceLink.html',
                        clickOutsideToClose: false,
                        multiple: true,
                        openFrom: '#action',
                        closeTo: '#action',
                        fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                        locals: {
                            device,
                            createLink,
                            links: availableLinks,
                            showCreateAnother: true
                        }
                    });
                })
                .then((model: {link: aq.deviceManagement.Link, createLink: boolean}) => {
                    return this.DataStore.create(this.RestangularV3.all('devices').all('links'), model.link)
                    .then(() => {
                        return model;
                    });
                })
                .then((model: {link: aq.deviceManagement.Link, createLink: boolean}) => {
                    this.Messages.success('New link created');
                    if (model.createLink) {
                        this.addLink(device, true);
                    } else {
                        return this.DataStore.getList(device.all('links'));
                    }
                });
        }

        public removeLink($event, device: aq.common.models.DeviceElement, linkDevice: aq.common.models.Device): ng.IPromise<any> {
            const confirm = this.$mdDialog
                .confirm()
                .title('Are you sure you want to delete this link?')
                .textContent('The devices will still exist, but Aquicore will stop receiving data from them until a new link is made.')
                .targetEvent($event)
                .ok('Confirm')
                .cancel('Cancel');

            return this.$mdDialog.show(confirm)
                .then(() => {
                    return this.DataStore.delete(device.one('links', linkDevice.id));
                })
                .then(() => {
                    this.Messages.success(`Link to ${linkDevice.name} removed successfully`);
                })
                .catch((err) => {
                    this.Messages.error('Error deleting link');
                });
        }

        refreshViewFields(device: aq.common.models.DeviceElement): aq.common.models.DeviceElement {
            const { deviceClass: selectedDeviceClass, deviceClass: {make, series, model}} = device;
            const selectedDeviceClassName = `${make} ${series} ${model}`;
            let tenants = null;
            if (device.tenantLinks) {
                tenants = device.tenantLinks.map((tenantLink) => {
                    return tenantLink.tenant;
                });
            }

            device.viewOnly = {
                selectedDeviceClass,
                selectedDeviceClassName,
                tenants
            };

            const fields = device.fields;
            if (fields) {
                const keys: any[] = Object.keys(fields);
                keys.forEach((key) => {
                    const value = selectedDeviceClass.fields[key];
                    fields[key].value = fields[key].value ? fields[key].value : value.value;
                });
            }

            return device;
        }

        public isGateway(device: aq.common.models.Device): boolean {
            return device.gateway;
        }

        public navigateTo(device: aq.common.models.DeviceElement): void {
            this.$state.go('.', { buildingId: device.building, deviceId: device.id }, {reload: true});
        }

        public getIcon(device: aq.common.models.DeviceElement): string {
            if (device) {
                if (device.icon) return device.icon;

                switch (device.deviceCategory) {
                    case 'METER':
                        return 'aq-icons-generic-meter';
                    default:
                        return 'aq-icons-data-center';
                }
            }
        }

        public removeSpike(spike: aq.common.models.SpikeData) {
            return this.RestangularV3.one('internal/data-copy/collector', spike.collectorId).customPOST({}, '', {
                start: spike.start,
                end: spike.end,
                measure: spike.utility
            })
            .then(() => {
                this.Messages.info('Removing spike. This may take a few minutes.');
            });
        }

        private cleanFields(device: any): aq.common.models.Device {
            return _.pick(device, aq.common.models.DeviceProperties);
        }

    }

    angular
        .module('aq.services')
        .service('DeviceService', DeviceService);
}
