angular.module('aq.admin.meters').directive('meterSelection', function(Restangular) {
    return {
        restrict : 'E',
        templateUrl: 'app/admin/meters/common/meterSelection/meterSelection.html',
        scope: {
            accounts: '=',
            accountId: '=',
            collector: '=',
            point: '=?',
            mergePoints: '=',
            multiplier: '=',
            newMultiplier: '=?',
            intervalValue: '=',
            intervalType: '=',
            isSource: '=',
            hideMultiplierCheckbox: '=',
            showPoints: '=?',
            dontFilterPulse: '=?',
            showNewMultiplier: '=?',
            building: '=?'
        },
        controller: function ($scope) {
            $scope.points = [];
            $scope.buildings = [];
            $scope.collectorModel = [];
            $scope.collectors = [];

            $scope.intervalTypeOptions = ['Days', 'Weeks', 'Months', 'Years'];

            $scope.selectAccount = function(account) {
                $scope.buildings = [];
                reset(0);

                Restangular.one('accounts', account.id).getList('buildings').then(function(buildings) {
                    $scope.buildings = buildings;
                    $scope.building = null;
                });
            }

            $scope.selectBuilding = function(building) {
                reset(0);

                building.getList('collectors', { inflate: 'points' }).then(function(collectors) {
                    $scope.collectors[0] = _.filter(collectors, function(c) {
                        return c.collectorClass !== "VIRTUAL_METER";
                    })
                });
            }

            $scope.updateCollector = function (collector, level) {
                reset(level + 1);
                $scope.point = null;
                $scope.collector = collector;
                $scope.multiplier = collector.multiplier;
                $scope.newMultiplier = collector.multiplier;

                collector.getList('collectors', { inflate: 'points' }).then(function (collectors) {
                    $scope.points[level + 1] = [];
                    if (!$scope.dontFilterPulse) {
                        collectors = _.filter(collectors, function (c) {
                            return c.upwardCommunications.indexOf('PULSE') == -1;
                        });
                    }
                    $scope.collectors[level + 1] = collectors;
                })

                // we should remove 'children' as it switches inplace select into parent/child mode
                $scope.points[level] = _.chain(collector.points)
                    .filter({ canHaveData: true })
                    .forEach(function (p) {
                        delete p.children;
                    })
                    .sortBy('name')
                    .value();
            }

            $scope.resetMultiplier = function() {
                $scope.multiplier = null;
                $scope.newMultiplier = null;
            }

            $scope.resetOffsetInterval = function() {
                $scope.intervalValue = null;
                $scope.intervalType = null;
            }

            var reset = function(level) {
                $scope.collector = null;
                $scope.point = null;

                for (var i = level; i < 3; i++) {
                    $scope.collectorModel[i] = null;
                    $scope.collectors[i] = [];
                    $scope.points[i] = [];
                }
            }

        }
    }
});