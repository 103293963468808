angular
    .module('aq.admin.productDesign')
    .controller('AdminListFilterCtrl', AdminListFilterCtrl);

function AdminListFilterCtrl($scope, $interval) {

    // chart config, can be found on: http://www.highcharts.com/demo/column-parsed
    var chartConfig = {
        chart: {
            type: 'line'
        },
        title: {
            text: 'What is Boston\'s Green House Gas Emissions by Commodity'
        },
        subtitle: {
            text: '\<b>2%\</b> lower emissions compared to last year'
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        yAxis: {
            title: {
                text: 'GHG'
            }
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                },
                enableMouseTracking: false
            }
        },
        series: []
    }

    // get random number between 100000 and 1000000
    function getRandomNumber() {
        return Math.floor((Math.random() * 1000000) + 100000);
    }

    // generate random data for chart and table
    function generateRandomData() {
        // ngModel.render() doesn't do deep watch so we need to
        // create new config object each time
        // https://docs.angularjs.org/api/ng/type/ngModel.NgModelController
        var config: any = angular.copy(chartConfig);
        var time = moment().subtract(12, 'months');
        var gasEmissions = [];
        _.times(12, function() {
            var o = {
                electric: getRandomNumber(),
                fuel: getRandomNumber(),
                gas: getRandomNumber()
            }

            // total property should be first in object so it is bellow all others since
            // those are bars - so this is reason why I'm creating new object (wasn't sure
            // if there is easy way to swap property positions in object)
            var data = {
                total: o.electric + o.fuel + o.gas,
                time: time.add(1, 'months').format('MMM YYYY')
            }
            _.extend(data, o);

            gasEmissions.push(data);
        });

        var series = _.map(_.keys(gasEmissions[0]), function(e) {
            var seriesObject: any = {};
            seriesObject.type = e == 'total' ? 'column' : 'spline';
            seriesObject.name = textural(e).format('capitalize');
            seriesObject.data = _.map(gasEmissions, e);
            return seriesObject;
        })

        config.series = series;
        $scope.chartConfig = config;
        $scope.tableData = gasEmissions;

    }

    generateRandomData();
    // simulate that data is changed every 3 seconds for the testing purpose
    $interval(function() {
        generateRandomData();
    }, 3000)

}
