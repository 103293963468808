namespace aq.services {

    export class DashboardWeatherForecast {

        /* @ngInject */
        constructor(
            private $http: ng.IHttpService,
            private resolver: aq.services.Resolver,
            private AQ_API_URL: string,
            private AuthToken: string,
            private $location: ng.ILocationService,
            private UserService: UserService,
        ) {
        }

        public async getForecast(params: DashboardWeatherForecastRequest): Promise<DashboardWeatherForecastResponse> {
            try {
                await this.UserService.getUser();
                return this.resolver(async () => {
                    const response = await this.$http<VcTimelineResponse>({
                        method: 'GET',
                        headers: this.getHeaders(),
                        url: `${this.AQ_API_URL}weather/v1`,
                        params
                    });
                    const data = response.data.days
                        .map(day => ({
                            humidity: Math.round(day.humidity) / 100,
                            icon: day.icon,
                            temperatureMin: day.tempmin,
                            temperatureMax: day.tempmax,
                            time: day.datetimeEpoch,
                        }));
                    let currently;
                    if (response.data.currentConditions) {
                        currently = {
                            pressure: response.data.currentConditions.pressure,
                            icon: response.data.currentConditions.icon,
                            summary: response.data.currentConditions.conditions,
                            humidity: Math.round(response.data.currentConditions.humidity) / 100,
                            temperature: response.data.currentConditions.temp,
                            windBearing: response.data.currentConditions.winddir,
                            windSpeed: response.data.currentConditions.windspeed,
                        };
                    }
                    const result: DashboardWeatherForecastResponse = {
                        currently, daily: { data }
                    }
                    return result;
                });
            } catch (error) {
                throw error;
            }
        }

        private getHeaders(): Record<string, any> {
            let auth = this.AuthToken ? this.AuthToken : this.$location.search().authToken;
            return {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth}`,
            };
        }

    }

    angular.module('aq.services').service('DashboardWeatherForecast', DashboardWeatherForecast);
}

type DashboardWeatherForecastRequest = {
    longitude: number,
    latitude: number,
    startDate: string,
    endDate: string,
}

type DashboardWeatherForecastResponse = {
    currently?: {
        icon: string;
        temperature: number;
        summary: string;
        humidity: number;
        windBearing: number;
        windSpeed: number;
        pressure: number;
    }
    daily: {
        data: Array<{
            humidity: number;
            icon: string;
            temperatureMin: number;
            temperatureMax: number;
            time: number;
        }>
    }
};

type VcTimelineResponse = {
    currentConditions?: {
        datetimeEpoch: number;
        temp: number;
        humidity: number;
        windspeed: number;
        winddir: number;
        pressure: number;
        conditions: string;
        icon: string;
    };
    days: Array<{
        datetimeEpoch: number;
        tempmax: number;
        tempmin: number;
        temp: number;
        humidity: number;
        windspeed: number;
        winddir: number;
        pressure: number;
        conditions: string;
        icon: string;
    }>;
};
