namespace aq.DeviceManagement {
    declare var OrgChart;
    export class NetworkTopologyComponent {
        private device: aq.common.models.DeviceElement;
        private topology: aq.common.models.DeviceTopology;
        private datastatus: aq.common.models.DataStatus[];
        private lastupdated: Date;
        private chart: any;
        private refreshIntervalPromise: ng.IPromise<any>;
        // https://github.com/google/material-design-icons/issues/434
        static GOOD_SIGNAL_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M1 16h15V1z"/></svg>`;
        static AVERAGE_SIGNAL_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path fill-opacity=".3" d="M1 16h15V1z"/><path d="M1 16h11V5z"/></svg>`;
        static POOR_SIGNAL_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path fill-opacity=".3" d="M1 16h15V1z"/><path d="M1 16h7V9z"/></svg>`;
        /* @ngInject */
        constructor(private $window: ng.IWindowService,
                    private $state: ng.ui.IStateService,
                    private $stateParams: ng.ui.IStateParamsService,
                    private $interval: ng.IIntervalService) {

        }

        $onInit() {
            this.updateTopology(true);
        }

        $onChanges(changesObj) {
            if (changesObj.device.currentValue) {
                this.device = changesObj.device.currentValue;
                this.updateTopology(false);
            }
        }

        private updateTopology = (firstRender = false) => {
            this.device.customGET('network-topology', {'date': new Date()}).then((topology) => {
                this.topology = topology;
                if (firstRender) {
                    this.render(this.topology);
                } else {
                    this.updateChartData(topology);
                }
                this.lastupdated = new Date();
            });
        }

        private updateChartData(topology) {
            if (!topology) {
                return;
            }
            this.prepareTopologyForOrgChart(topology);
            this.chart.init({data: topology});
        }

        private render(topology) {
            if (!topology) {
                return;
            }
            this.prepareTopologyForOrgChart(topology);
            this.chart = $('.network-topology-root').orgchart({
                data : topology,
                pan: true,
                nodeTemplate: (data: aq.common.models.NetworkTopology) => {
                    const dataStatus =  _.find(this.datastatus, {id: data.deviceId});
                    let networkClassName = 'no-status';
                    const dataClassName = 'never-reported';
                    let dataIcon = '';
                    let dataColor = '';
                    let dataStatusText = '';
                    let template = '<div>';
                    let icon = ``;
                    if (data.parentNetworkConnectionStatus) {
                        switch (data.parentNetworkConnectionStatus) {
                            case 'AVERAGE':
                                networkClassName = 'average-status';
                                icon = NetworkTopologyComponent.AVERAGE_SIGNAL_ICON;
                                break;
                            case 'POOR':
                                networkClassName = 'poor-status';
                                icon = NetworkTopologyComponent.POOR_SIGNAL_ICON;
                                break;
                            case 'GOOD':
                                networkClassName = 'good-status';
                                icon = NetworkTopologyComponent.GOOD_SIGNAL_ICON;
                                break;
                            default:
                                networkClassName = 'no-status';
                                break;
                        }
                        template += `<span class="rssi ${networkClassName}">${icon}</span>`;
                    }
                    if (dataStatus) {
                        switch (dataStatus.status) {
                            case 'OFFLINE':
                                dataIcon = 'error';
                                dataColor = 'red';
                                dataStatusText = 'Offline';
                                break;
                            case 'ONLINE':
                                dataIcon = 'check_circle';
                                dataColor = 'green';
                                dataStatusText = 'Online';
                                break;
                            case 'NEVER_REPORTED':
                                dataIcon = 'warning';
                                dataColor = 'yellow';
                                dataStatusText = 'Never Reported';
                                break;
                            default:
                        }
                    }
                    template += `
                        <div class="title ${dataClassName}">
                            <i class="device-icon ${this.getIcon(data.deviceCategory)}"></i>
                            ${this.formatText(data.deviceName)}
                            <a href="${this.$state.href('aq.deviceManagement.building.device', {accountId: this.$stateParams['accountId'], buildingId: this.$stateParams['buildingId'], deviceId: data.deviceId,  cat: undefined})}/configuration">
                            <md-icon class="md-secondary material-icons" tabindex="-1">open_in_new</md-icon>
                            </a>
                        </div>
                        <div class="content content-lg ${dataClassName}">
                            <label>Protocol: </label>${data.protocol ? data.protocol : ''}<br>
                            <label>Input ID: </label>${data.inputId ? data.inputId : ''}<br>
                            <label>Location: </label>${data.deviceLocation ? data.deviceLocation : ''}<br>
                            <label>Status: </label> <md-icon class="md-secondary material-icons ${dataColor} data-icon" tabindex="-1">${dataIcon}</md-icon>${dataStatusText}
                        </div>
                    `;
                    template += `</div>`;
                    return template;
                }
            });
        }

        private prepareTopologyForOrgChart(topology: aq.common.models.DeviceTopology) {
            if (topology.deviceId === this.device.id) {
                topology['className'] = 'device-node queried-element';
            } else {
                topology['className'] = 'device-node';
            }

            if (topology.children) {
                topology.children = _.sortBy(topology.children, 'deviceName');
                topology.children.forEach(child => {
                    this.prepareTopologyForOrgChart(child);
                });
            }
        }

        private formatText(text: string) {
            if (text) {
                return text.length > 16 ? `${text.slice(0, 16)}…` : text;
            }
            return '';
        }

        private getIcon(category) {
            switch (category) {
                case 'METER':
                    return 'aq-icons-collector-aquicore-meter';
                case 'NETWORKING':
                    return 'aq-icons-aq-gateway';
                default:
                    return 'aq-icons-aq-gateway';
            }
        }
    }

    angular
        .module('deviceManagement')
        .component('networkTopology', {
            controller: NetworkTopologyComponent,
            controllerAs: 'vm',
            templateUrl: 'app/deviceManagement/device/components/networkTopology.html',
            bindings: {
                device: '<',
                datastatus: '<'
            }
        });
}
