namespace aq.propertySettings {

    export class AddCollectorModalCtrl extends aq.common.Controllers.ModalCtrl {
        /* @ngAnnotate */
        constructor(protected $scope: any, protected $mdDialog, private unassignedPulsePoints, private Restangular, private building) {
            super($scope, $mdDialog);
            $scope.meterMetrics = [{label: 'Electricity', value: 'ELECTRICITY'}, {label: 'Gas', value: 'GAS'}, {label: 'Water', value: 'WATER'}, {label: 'Steam', value: 'STEAM'}, {label: 'Heat Transfer', value: 'HEAT'}];
            $scope.selectedMetric = 'ELECTRICITY';
            $scope.post = {
                message: ''
            };
            $scope.unassignedPulsePoints = unassignedPulsePoints;
            $scope.equationValidated = true;
            $scope.equationValidationStatus = '';
            if (this.$scope.typeTitle == 'Virtual Meter') {
                this.$scope.newCollector.collectorClass = 'VIRTUAL_METER';
            }
            this.updateCollectorClasses();
        }

        updateCollectorClasses() {
            if (this.$scope.typeTitle == 'Web Connector') {
                this.$scope.collectorClasses = _.filter(this.$scope.options.collectorClasses, (cl: any) => {
                    // if the collectors upwardCommunication contains the specified type title.
                    return _.includes(cl.upwardCommunications, textural(this.$scope.typeTitle).format('uppersnake'));
                });
            }
        }

        updateShowPointSelect() {
            const colClass = this.$scope.newCollector.collectorClass;
            if (!colClass) return;
            const collectorClass = _.find(this.$scope.collectorClasses, (cc: any) => {
                return cc.name == colClass;
            });
            this.$scope.showPointSelect =  _.includes(collectorClass.upwardCommunications , 'PULSE');
        }

    }
    angular.module('properties.dataCollectors').controller('AddCollectorModalCtrl', AddCollectorModalCtrl);
}
