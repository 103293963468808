declare const checkNoEdit: any;
/**
 * Edit in place directive for angular
 * @param label - label
 * @param {string} prefix - prefix at the start of input
 * @param {string} suffix - suffix at the end of input API interval (raw, 1min, 15min, 1h, 1d, 1mon)
 * @param {string} suffixSeparator - in case we need some separator before suffix for non-edit mode e.g. 100 / L
                                     slash is separator in this case; same thing is for prefixSeparator
 * @param type - date, select, number, tag, if blank then it will be text
 * @param {bool} bulk - it sets control to edit state but it never calls change function, control
                   will have same behaviour as regular html control
 * @param change - pass function from controller to be called on model change (function must return promise)
 * @param value - use when using radio on checkbox type
 * @param minDate - minimal date selection for datepicker (Date string, Date or moment)
 * @param maxDate - maximum date selection for datepickere (Date string, Date or moment)
 * @param timeType - use timepicker as a string e.g. 10:00pm (instead of full date object)
 * @param allowClear - add extra empty field at begging of values array in options
 * @param minuteStep - default minute step for time picker.
 * @param {bool} edit - if set to true it will allow control to be edited on click and after model is changed
                 it will call change function, if set to false control will behave as if it is disabled for editing
 * @param placeholder - placeholder text to be shown if no value
 * @param {array} options - (mandatory if select type) array of objects passed if select type of input,
                            by default it will take name property as value in dropdown,
                            if optionDisplayField is provided then it will take that as property
                            value in dropdown e.g. source.identifier
 * @param {array} optionsToExclude - (optional) array of objects to exclude from dropdown, this variable has watch which
                                     means it will dinamically filter options if array is changed. Filter is based ONLY on
                                     objects that have id property.
 * @param {string} optionDisplayField - refer to options description above
 * @param {string} tagProperty - if provided, it will use array of objects (options) as array of values; all objects will be converted
                                 to values based on property name provided in this attribute e.g. if 'value' provided as value
                                 [{ value: 'EMAIL', label: 'e-Mail' }] -> ['EMAIL']
 * @param {string} isRequired - acts as ngRequired
 * @param {string} updateOn - used for ng-model-options in search type of inplace; if not defined, 'blur' is used
 * @param {bool} forceUpdate - calls change handler even in bulk mode
 */
angular.module('aq.ui').directive('inplace', function($filter, $q, Auth, $timeout) {
    return {
        restrict : 'E',
        replace: true,
        require: 'ngModel',
        template: '<div class="aq-inplace">' +
                    '<label ng-if="showLabel">{{ label | translate}}<span ng-hide="keepLabelAlignments">:</span></label>' +
                    '<div class="aq-controls" ng-class="{ \'suffix-wrapper\': suffix, \'prefix-wrapper\': prefix }">' +
                      '<div ng-if="prefix" ng-show="editing || bulk" class="prefix">{{ prefix }}</div>' +
                      '<div class="non-edit" ' +
                        'ng-class="{ \'no-edit\': noEdit }"' +
                        'ng-show="!bulk && !editing"' +
                        'ng-click="setEditMode()">{{ getNonEditingValue().toString() | translate }}' +
                      '</div>' +
                      '<div ng-include="(editing || bulk) && getTemplateUrl()"></div>' +
                      '<div ng-if="suffix" ng-show="editing || bulk" class="suffix">{{ suffix }}</div>' +
                    '</div>' +
                    '<div ng-if="help" class="help" bs-tooltip="help" data-placement="top">?</div>' +
                   '</div>',
        scope: {
            name: '@',
            label: '@',
            keepLabelAlignments: '=',
            prefix: '@',
            prefixSeparator: '@',
            suffix: '@',
            suffixSeparator: '@',
            change: '&',
            value: '@',
            type: '@',
            highlight: '=',
            bulk: '=',
            forceUpdate: '=',
            updateOn: '@',
            minDate: '=',
            allowClear: '@',
            maxDate: '=',
            dateFormat: '@',
            timeType: '@',
            minuteStep: '@',
            edit: '=',
            placeholder: '@',
            options: '=',
            orderBy: '=',
            lazyOptions: '&',
            optionsToExclude: '=',
            optionDisplayField: '@',
            tagProperty: '@',
            filterName: '@',
            filterParams: '=',
            isRequired: '=',
            humanize: '@',
            help: '@',
            min: '@',
            max: '@',
            minLength: '@',
            maxLength: '@',
            allowAll: '<',
            // TODO: drop this after line oldValue and init on change Handler is fixed - this problem is only on energUse on account settings
            // and in admin accounts app, check there
            skipUpdateOnLoad: '='
        },
        link: function ($scope, $element, $attrs, ngModel: ng.INgModelController) {
            const checkNoEdit = function() {
                $scope.noEdit = $scope.edit == false || !haveAccess;
            };

            if (!$scope.name){
                $scope.name = $attrs.ngModel;
            }
            $scope.noEdit = true;
            let haveAccess = false;

            haveAccess = Auth.check({access: 'EDIT'}) || $scope.allowAll;
            checkNoEdit();

            $scope.showLabel = ($scope.keepLabelAlignments || $scope.label) && ($scope.type != 'radio');

            const type = $attrs.type ? $attrs.type : 'text';
            const shouldHaveOptions = function(type) {
                return (type == 'select' || type == 'enum' || type == 'tag');
            };

            $scope.updateOn = (type == 'search' && $attrs.updateOn) ? $attrs.updateOn : 'blur';

            $scope.timeType = $attrs.timeType && ($attrs.timeType == 'string') ? 'string' : 'date';
            $scope.minuteStep = $attrs.minuteStep ? $attrs.minuteStep : 5;

            $scope.optionDisplayField = $attrs.optionDisplayField ? $attrs.optionDisplayField : 'name';

            $scope.dateFormat = $attrs.dateFormat || 'l';

            // if tagProperty provided, options array will have array of values instead objects,
            // select2 by default converts all values to objects with id and text property - this is
            // handler for that case
            if ($scope.tagProperty) {
                $attrs.optionDisplayField = 'text';
            }

            $scope.getTemplateUrl = function() {
                return 'app/common/directives/inplace/' + type + '.html';
            };

            ngModel.$render = function() {
                // this will allow to use inplace enum type on boolean ng-model, we need to convert
                // boolean value to string so select2 can found selected value
                if (type == 'enum' && (ngModel.$modelValue === true || ngModel.$modelValue === false)) {
                    $scope.model = ngModel.$modelValue ? 'true' : 'false';
                } else {
                    $scope.model = ngModel.$modelValue;
                }
            };

            ngModel.$parsers.push(function(value){

                // this prevents initializing of ngModel in case when
                // options are still not loaded
                if (shouldHaveOptions(type) && !$attrs.lazyOptions) {
                    if (!_.isArray($scope.options)) return;
                    if ($scope.options.length == 0) {
                        return type == 'tag' ? value : undefined;
                    }
                    // if value is empty return null instead of ""
                    if (!value) return;
                }

                if (type == 'number') {
                    return value;
                } else {

                    if (type == 'select') {
                        if (!value) return null;
                        try {
                            return JSON.parse(value);
                        } catch (e) {
                            return value;
                        }
                    }

                    // this probably can be dropped after we upgrade to new ui-select directive
                    // this condition only exist because current ui-select2 directive creates
                    // selected objects in ngModel as strings so we need to parse again in order
                    // to get proper output
                    if (type == 'tag') {
                        return value = _.map(value, function(v) {
                            try {
                                return JSON.parse(v);
                            } catch (e) {
                                return v;
                            }
                        });
                    } else {
                        return value;
                    }
                }
            });

            $scope.$watch('model', function(model) {
                ngModel.$setViewValue($scope.model);
            });

            $scope.$watch('edit', function(edit) {
                checkNoEdit();
            });

            /**
             * if option-display-field attribute exist it will take it as a property name of
             * option object to display in select control, otherwise it will default to 'name';
             * ng-options="option as option[optionDisplayField] for option in options">
             */
            $scope.optionLabel = function(option) {
                let text;
                if (_.isObject(option)) {
                    text = option[$scope.optionDisplayField];
                } else {
                    text = option;
                }
                return $scope.humanize ? textural(text).format('capitalizehuman') : text;
            };

            $scope.onEnter = function(event){
                if (event && event.keyCode == 13) {
                    $scope.changeHandler();
                }
            };

            let oldvalue = '';
            // TODO: drop this once we upgrade to new ui-select, upgrade to new ui-select after all app is on inplace
            // this workaround is because of this https://github.com/angular-ui/ui-select2/issues/84
            let init = true;
            $scope.changeHandler = function() {

                if ($scope.isRequired && !$scope.model && $scope.model !== 0) {
                    $element.addClass('required');
                    return;
                }

                if (shouldHaveOptions(type) && init == true && $scope.skipUpdateOnLoad) {
                    init = false;
                    return;
                }

                $scope.editing = false;
                if (angular.equals(oldvalue, $scope.model)) {
                    return;
                }

                /** if allowClear is set and if label value is set to '' that means we want to set
                 * $scope.model to null
                 */
                if (type == 'select' && $scope.allowClear && $scope.model[$scope.optionDisplayField] == '') {
                    $scope.model = null;
                }

                oldvalue = angular.copy($scope.model);
                ngModel.$setViewValue($scope.model);

                if (!$scope.allowClear && type != 'text' && !$scope.model && $scope.model !== 0) return;

                if ($scope.bulk === true && !$scope.forceUpdate) return;

                if ($scope.options && $scope.selectCfg.data) {
                    $scope.selectCfg.data.results = $filter('inplaceOptionsFilter')($scope.options, $scope.filterParams, $scope.filterName);
                    if ($scope.orderBy) $scope.selectCfg.data.results = $filter('orderBy')($scope.selectCfg.data.results, $scope.orderBy);
                }

                const func = $scope.eval($scope.change);
                if (!func) return;

                $q.when(func).then(function () {
                }, function (a) {
                    console.log('we have error');
                });
            };

            // for unit tests
            $scope.eval = function(change) {
                return $scope.$eval(change);
            };

            $scope.keyDown = function(event) {
                // save on enter key
                if (event.keyCode == 13) {
                    $element.find('input')[0].blur();
                }
            };

            $scope.editing = false;
            $scope.setEditMode = function() {
                if ($scope.noEdit) return;
                $scope.editing = true;
            };

            $scope.showEdit = function(type) {
                return ($scope.bulk || $scope.editing) && type == $attrs.type;
            };

            $scope.getNonEditingValue = function() {
                if (!$scope.model && $scope.placeholder) {
                    return $scope.placeholder;
                }
                let value = $scope.model != null ? $scope.model : $scope.placeholder;
                /***
                 * Select fields usually contains whole object as model so this is
                 * handler for that cases
                 */
                if ($scope.model instanceof Object) {
                    value = $scope.optionLabel($scope.model);
                } else if ($scope.type == 'select' && $scope.options) {
                    /***
                     * If model is not object and type is 'select' then found manually object.
                     */
                    const modelObject = _.findById($scope.options, $scope.model);
                    value = $scope.optionLabel(modelObject);
                } else if ($scope.type == 'selectbyid' && $scope.options) {
                    return $scope.optionLabel(_.findById($scope.options, $scope.model)) || '';
                }

                /***
                 * Parse date and display properly formatted on non-edit mode
                 */
                if ($scope.type == 'date') {
                    value = $scope.model === null ? null : moment($scope.model).format($scope.dateFormat);
                }

                // Parse view valuer properly if enum type
                if ($scope.type == 'enum' && $scope.options) {

                    const enumValue = _.find($scope.options, function(o) {
                        if (!o) return;
                        return o.value.toString() == $scope.model;
                    });

                    if (!enumValue) return '';
                    value = enumValue.label;
                }

                let result = $scope.humanize ? textural(value).format('capitalizehuman') : value;

                // add prefix if needed
                result = $scope.prefix ? $scope.prefix + ($scope.prefixSeparator ? $scope.prefixSeparator + ' ' : '') + result : result;
                // add sufix if needed
                result = $scope.suffix ? result + ' ' + ($scope.suffixSeparator ? $scope.suffixSeparator + ' ' : '') + $scope.suffix : result;

                return result || '';
            };

            $scope.$watch('options', function (options) {
                if (!options) return;
                // TODO: why we need this and try to drop it
                $scope.isObject = _.isObject(options[0]) || !!$attrs.lazyOptions;
                filterOptions(options);
            });

            function filterOptions(options) {
                // TODO: drop inplace options filter
                let filteredOptions = $filter('inplaceOptionsFilter')(options, $scope.filterParams, $scope.filterName);
                if ($scope.orderBy) {
                    filteredOptions = $filter('orderBy')(filteredOptions, $scope.orderBy);
                }
                setOptions(filteredOptions);

                if (!$scope.optionsToExclude) return;
                const idsToExclude = _.map($scope.optionsToExclude, 'id');
                filteredOptions = _.filter($scope.options, function(o) {
                    return !_.includes(idsToExclude, o.id);
                });
                if ($scope.orderBy) {
                    filteredOptions = $filter('orderBy')(filteredOptions, $scope.orderBy);
                }
                setOptions(filteredOptions);
            }

            function setOptions(options) {
                if ($scope.tagProperty) {
                    $scope.selectCfg.tags = _.map(options, $scope.tagProperty);
                    $scope.selectCfg.simple_tags = true;
                    delete $scope.selectCfg.data;
                } else {

                    if ($scope.allowClear) {
                        const emptyOption = { id: 0 };
                        emptyOption[$scope.optionDisplayField] = '';
                        options.unshift(emptyOption);
                    }

                    $scope.selectCfg.data.results = options;
                }
            }

            // TODO - adjust tenant form to use this instead of inplaceOptionsFilter and drop it from inplace
            $scope.$watch('optionsToExclude', function(optionsToExclude) {
                if (!optionsToExclude || !$scope.options) return;
                filterOptions(optionsToExclude);
            }, true);

            $scope.selectCfg = {
                data: {results: $scope.options, text: $scope.optionDisplayField},
                minimumResultsForSearch: 10,
                formatResult: function (item) {
                    const htmlItem = function (content) {
                        return '<div class=\'select2-user-result\'>' + content + '</div>';
                    };

                    let content = $scope.optionLabel(item);
                    if (item.color) {
                        content = '<span ng-if=\'item.color\' class=\'color-box\' style=\'background: ' + item.color + '\'>  </span>' + $scope.optionLabel(item);
                    }

                    return htmlItem(content);
                },
                formatSelection: function (item) {
                    return $scope.optionLabel(item);
                },
                multiple: type == 'tag' ? true : false
            };

            if (!!$attrs.lazyOptions) {
                $scope.selectCfg.minimumInputLength = 1;
                $scope.selectCfg.query = function (query) {
                    $scope.lazyOptions({search: query.term}).then(function (response) {
                        query.callback({results: response});
                    });
                };
            }



            const emailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)+$/i;
            $scope.emailsOptions = {
                tags: [],
                containerCssClass: 'emailsContainer',
                dropdownCssClass: 'emailsDrop',
                multiple: true,
                simple_tags: true,
                selectOnBlur: true,
                tokenSeparators: [',', ';', ' '],
                createSearchChoice : function(term, data) {
                    if ($(data).filter(function() {
                            return this.text.localeCompare(term) === 0;
                        }).length === 0) {
                        if (emailRegex.test(term)) {
                            return {
                                id : term,
                                text : term
                            };
                        } else {
                            return null;
                        }
                    }
                }
            };

            // hack: I'm not sure how that display: none style property was attached there in the first place
            $timeout(function () { $element.css('display', ''); }, 0);

        }
    };
})

.filter('inplaceOptionsFilter', function($filter) {
    return function(input, filterParams, filterName) {

        if (filterParams) {

            if (filterParams.length == 3) {
                return $filter(filterName)(input, filterParams[0], filterParams[1], filterParams[2]);
            }

            if (filterParams.length == 2) {
                return $filter(filterName)(input, filterParams[0], filterParams[1]);
            }

            if (filterParams.length == 1) {
                return $filter(filterName)(input, filterParams[0]);
            }

        }

        return input;
    };

});
