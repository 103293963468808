namespace aq.utilityBudgets {
    export class ShareUtilityAccount extends aq.common.Controllers.ModalCtrl {

        private buildingsForUtilityAccount: number[];
        private buildingIdToName = [];

        /* @ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private buildings: aq.common.models.Building[],
            private buildingId,
            private utilityAccountBuildingIds: number[]
        ) {
            super({}, $mdDialog);
            const visibleBuildingIds = this.buildings
                .map(visibleBuilding => Number(visibleBuilding.id));
            this.buildingsForUtilityAccount = this.utilityAccountBuildingIds
                .filter(id => id != this.buildingId)
                .filter(id => visibleBuildingIds.indexOf(id) > -1);
            this.buildings.forEach((visibleBuilding) => {
                this.buildingIdToName[Number(visibleBuilding.id)] = visibleBuilding.name;
            });
        }
        public cancel(): void {
            this.$mdDialog.cancel();
        }
        public hide(result): void {
            this.$mdDialog.hide(result);
        }
        public save() {
            const removeBuildings = this.utilityAccountBuildingIds
                .filter(id => id != this.buildingId)
                .filter(id => this.buildingsForUtilityAccount.indexOf(id) < 0);
            const addBuildings = this.buildingsForUtilityAccount
                .filter(id => this.utilityAccountBuildingIds.indexOf(id) < 0);
            this.hide({addBuildings, removeBuildings});
        }
        public queryBuildings(searchText) {
            return this.buildings
                .filter(building => building.id != this.buildingId)
                .filter(building => building.name.toLocaleLowerCase().indexOf(searchText.toString().toLowerCase()) > -1);
        }
    }
    angular.module('aq.utilityBudgets').controller('ShareUtilityAccount', ShareUtilityAccount);
}
