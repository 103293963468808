angular
.module('aq.settings.calendars')
.controller('SettingsCalendarFormCtrl',
    function(
        $scope,
        $state,
        Messages,
        Restangular,
        Errors
    ) {
        $scope.saveCalendar = function() {
            $scope.calendar.put().then(function() {
                Messages.success('The calendar was updated correctly');
                $scope.calendar.rules = [];
                $scope.workCalendarRules = [];
            }, Errors.forPromise());
        };
    });
