namespace aq.services {

    import ImpactType = aq.models.projects.ImpactType;
    import NetImpact = aq.models.projects.NetImpact;
    import NetImpactLineItem = aq.models.projects.NetImpactLineItem;

    export class ProjectServiceV3 {

        /* @ngInject */
        constructor(
            private $http: ng.IHttpService,
            private resolver: aq.services.Resolver,
            private AQ_API_URL: string,
            private AuthToken: string,
            private $location: ng.ILocationService,
            private UserService: UserService,
            private RestangularV3: restangular.IService
        ) {
        }

        public async getProjects(building: number): Promise<aq.models.projects.ProjectResponse> {
            try {
                await this.UserService.getUser();
                return this.resolver(async () => {
                    const response = await this.$http({
                        method: 'GET',
                        headers: this.getHeaders(),
                        url: `${this.AQ_API_URL}projects/v1`,
                        params: {
                            buildingId: building
                        }
                    });
                    return response.data;
                });
            } catch (error) {
                throw error;
            }
        }

        public async getStatusDetails(request: aq.models.projects.ProjectStatusDetailsRequest): Promise<aq.models.projects.ProjectStatusDetails[]> {
            try {
                await this.UserService.getUser();
                return this.resolver(async () => {
                    const response = await this.$http({
                        method: 'POST',
                        headers: this.getHeaders(),
                        data: JSON.stringify(request),
                        url: `${this.AQ_API_URL}projects/v1/status-details`,
                    });
                    return response.data;
                });
            } catch (error) {
                throw error;
            }
        }

        private getHeaders(): aq.models.projects.HeaderResponse {
            let profile;
            let auth = this.AuthToken ? this.AuthToken : this.$location.search().authToken;

            if (this.UserService.currentUser.currentProfile.id) {
                profile = this.UserService.currentUser.currentProfile.id;
            } else if (this.UserService.currentUser.currentProfile) {
                profile = this.UserService.currentUser.currentProfile;
            } else {
                profile = this.$location.search().profileId;
            }
            return {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth}`,
                'x-aq-profile': profile
            };
        }

        public getEstimatedImpact(impact: NetImpact | NetImpactLineItem, minField: string, maxField: string):
            { amount: number; isEstimated: boolean } {
            const retVal = {
                amount: 0,
                isEstimated: false
            };
            const min = (impact && impact[minField]) || null;
            const max = (impact && impact[maxField]) || null;
            if (min !== null && max !== null && min !== 0 && max !== 0) {
                retVal.amount = Math.round(((min + max) / 2) / 10) * 10; // Round average to nearest 10
                retVal.isEstimated = true;
            } else if ((min === null || min === 0) && (max !== null && max !== 0)) {
                retVal.amount = max;
            } else if ((max === null || max === 0) && (min !== null && min !== 0)) {
                retVal.amount = min;
            }
            return retVal;
        }

        public getImpactValue(project: aq.models.projects.Project, isProjectEstimationEnabled: boolean,
                              isAggregation?: boolean, unit?: 'KWH' | 'USD') {
            const value = project.impact ? project.impact.impactLineItems : [];
            const utilityItem = value.find(item => item.impactType === ImpactType.UTILITY);
            const impact: NetImpact | NetImpactLineItem = unit === 'KWH' ? utilityItem : project.impact;

            const maxField = unit === 'KWH' ? 'estimatedSavingsAmountEnergyMax' : 'totalEstimatedSavingsAmountMax';
            const minField = unit === 'KWH' ? 'estimatedSavingsAmountEnergyMin' : 'totalEstimatedSavingsAmountMin';
            const actualField = unit === 'KWH' ? 'actualSavingsAmountEnergy' : 'totalActualSavingsAmount';

            const completed = project.status.name === 'Completed';

            let projectImpact = (impact && impact[maxField]) || 0;
            let estimated = false;
            let projectImpactMin = 0;
            if (isProjectEstimationEnabled && completed) {
                if (impact && impact[actualField]) {
                    projectImpact = impact[actualField];
                } else {
                    const estimatedImpact = this.getEstimatedImpact(impact, minField, maxField);
                    projectImpact = estimatedImpact.amount;
                    estimated = estimatedImpact.isEstimated;
                }
            } else if (isProjectEstimationEnabled && !completed && isAggregation) {
                const estimatedImpact = this.getEstimatedImpact(impact, minField, maxField);
                projectImpact = estimatedImpact.amount;
                estimated = estimatedImpact.isEstimated;
            } else {
                projectImpactMin = (impact && impact[minField]) || 0;
            }
            // If there is a min but not a max, treat it like there's only a max
            if (projectImpactMin !== 0 && (projectImpact === 0 || projectImpact === null)) {
                return {projectImpact: projectImpactMin, estimated, projectImpactMin: projectImpact};
            }
            return {
                projectImpact, estimated, projectImpactMin
            };
        }
    }

    angular
        .module('aq.services')
        .service('ProjectServiceV3', ProjectServiceV3);
}
