var aq;
(function (aq) {
    var services;
    (function (services) {
        var SegmentService = /** @class */ (function () {
            /* @ngInject */
            function SegmentService(RestangularV3, UserService, $window, SnapshotService) {
                this.RestangularV3 = RestangularV3;
                this.UserService = UserService;
                this.$window = $window;
                this.SnapshotService = SnapshotService;
            }
            SegmentService.prototype.initializeSegment = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var segmentKeyResponse, _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                if (!!(this.$window.analytics)) return [3 /*break*/, 3];
                                return [4 /*yield*/, this.getSegmentKey()];
                            case 1:
                                segmentKeyResponse = _b.sent();
                                if (!(segmentKeyResponse.segmentKey != null && !this.SnapshotService.isSnapshotRequest())) return [3 /*break*/, 3];
                                this.initializeAnalytics();
                                _a = this;
                                return [4 /*yield*/, this.UserService.getUser()];
                            case 2:
                                _a.currentUser = _b.sent();
                                if (this.$window.analytics && this.currentUser != null && this.currentUser.currentProfile != null) {
                                    this.loadAnalytics(segmentKeyResponse.segmentKey);
                                    this.identify(this.currentUser);
                                    this.groupProfile(this.currentUser.currentProfile);
                                    this.ready();
                                }
                                if (!this.$window.analytics) {
                                    console.warn('Failed to initialize segment');
                                }
                                _b.label = 3;
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            SegmentService.prototype.initializeAnalytics = function () {
                analytics = this.$window.analytics = this.$window.analytics || [];
                if (!analytics.initialize)
                    if (analytics.invoked)
                        this.$window.console && console.error && console.error("Segment snippet included twice.");
                    else {
                        analytics.invoked = !0;
                        analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on"];
                        analytics.factory = function (t) { return function () { var e = Array.prototype.slice.call(arguments); e.unshift(t); analytics.push(e); return analytics; }; };
                        for (var t = 0; t < analytics.methods.length; t++) {
                            var e = analytics.methods[t];
                            analytics[e] = analytics.factory(e);
                        }
                        analytics.load = function (t, e) { var n = document.createElement("script"); n.type = "text/javascript"; n.async = !0; n.src = ("https:" === document.location.protocol ? "https://" : "http://") + "cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js"; var o = document.getElementsByTagName("script")[0]; o.parentNode.insertBefore(n, o); analytics._loadOptions = e; };
                        analytics.SNIPPET_VERSION = "4.1.0";
                    }
            };
            // public for testing
            SegmentService.prototype.getSegmentKey = function () {
                return this.RestangularV3.one('segment').customGET('key');
            };
            // public for testing
            SegmentService.prototype.loadAnalytics = function (segmentKey) {
                if (this.$window.analytics) {
                    this.$window.analytics.load(segmentKey);
                }
            };
            // public for testing
            SegmentService.prototype.identify = function (user) {
                if (this.$window.analytics && user) {
                    var isPorfolioUser = false;
                    if (user.currentProfile && user.currentProfile.portfolioUser) {
                        isPorfolioUser = true;
                    }
                    var body = {
                        name: user.fullName,
                        email: user.email,
                        persona: user.persona,
                        username: user.userName,
                        portfolioUser: isPorfolioUser
                    };
                    if (user.userCreated != null) {
                        body['createdAt'] = moment(user.userCreated).valueOf().toString();
                    }
                    this.$window.analytics.identify(user.id, body);
                }
            };
            // public for testing
            SegmentService.prototype.groupProfile = function (currentProfile) {
                if (this.$window.analytics && currentProfile && currentProfile.account) {
                    this.group(Number(currentProfile.account.id), currentProfile.account.accountName);
                }
            };
            SegmentService.prototype.group = function (accountId, accountName) {
                if (this.$window.analytics) {
                    this.$window.analytics.group(accountId.toString(), {
                        name: accountName
                    });
                }
            };
            // public for testing
            SegmentService.prototype.ready = function () {
                if (this.$window.analytics) {
                    this.$window.analytics.ready(function () { });
                }
            };
            //    For ChurnZero we need to pass custom properties and differentiate between portfolio users and non-portfolio users
            //    "cz_identifier" is either an account or a building id
            //    For portfolio users this will be the account id for the profile
            //    For non portfolio users we send a track event for the list of buildings passed in to the method
            //    Events by AQ Admins are ignored by ChurnZero. We note this with the "cz_ignore" field
            //    see AQ-9918
            SegmentService.prototype.trackChurnZeroEvent = function (eventName, buildingIds) {
                return __awaiter(this, void 0, void 0, function () {
                    var user;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.UserService.getUser()];
                            case 1:
                                user = _a.sent();
                                if (user.currentProfile.portfolioUser) {
                                    this.trackPortfolioUserEvent(eventName, user);
                                }
                                else {
                                    this.trackNonPortfolioUserEvent(eventName, user, buildingIds);
                                }
                                return [2 /*return*/];
                        }
                    });
                });
            };
            SegmentService.prototype.trackPortfolioUserEvent = function (eventName, user) {
                var trackingProperties = {
                    cz_identifier: user.currentProfile.account.id,
                    cz_ignore: user.currentProfile.user.userType !== 'CLIENT'
                };
                this.trackEvent(eventName, trackingProperties);
            };
            SegmentService.prototype.trackNonPortfolioUserEvent = function (eventName, user, buildingIds) {
                var _this = this;
                if (!buildingIds || buildingIds.length === 0) {
                    buildingIds = user.currentProfile.hasFullVisibility ? user.currentProfile.account.buildings : user.currentProfile.visibilities;
                }
                buildingIds.map(function (buildingId) {
                    var trackingProperties = {
                        cz_identifier: buildingId,
                        cz_ignore: user.userType !== 'CLIENT'
                    };
                    _this.trackEvent(eventName, trackingProperties);
                });
            };
            SegmentService.prototype.trackEvent = function (eventName, properties) {
                if (this.$window.analytics) {
                    var integrations_1 = {
                        Amplitude: { session_id: this.getNow().getTime() }
                    };
                    this.$window.analytics.track(eventName, properties, integrations_1);
                }
            };
            SegmentService.prototype.trackPage = function (page) {
                if (this.$window.analytics) {
                    var integrations_2 = {
                        Amplitude: { session_id: this.getNow().getTime() }
                    };
                    this.$window.analytics.page(page, null, null, integrations_2);
                }
            };
            SegmentService.prototype.getNow = function () {
                return new Date();
            };
            return SegmentService;
        }());
        services.SegmentService = SegmentService;
        angular.module('aq.services').service('Segment', SegmentService);
    })(services = aq.services || (aq.services = {}));
})(aq || (aq = {}));
