angular.module('aq.admin.reports.summaryreports').controller('SummaryReportsCtrl',
    function ($scope, $state, Restangular, RestangularV3: restangular.IService, DataStore: aq.common.DataStore, Errors, $q, $element, Messages, $timeout, $location, $window: ng.IWindowService, Auth, $mdDialog) {
        $scope.filterTerm = '';
        $scope.reports = {};
        $scope.dateRangeDecision = 'lastMonth';

        $scope.isProjectSummaryReportEnabled = Auth.hasFunctionality('Aquicore 3.0 Projects');

        $element.find('.account-filter').on('keydown', function (ev) {
            ev.stopPropagation();
        });
        $scope.summaryReportType = [{
            value: 'electricity',
            label: 'Electricity'
        },
        {
            value: 'water',
            label: 'Water'
        },
        {
            value: 'gas',
            label: 'Gas'
        },
        {
            value: 'steam',
            label: 'Steam'
        },
        {
            value: 'heat',
            label: 'Heat'
        }
        ];
        $scope.buildings = [];
        $scope.links = {};
        $scope.type = $scope.summaryReportType[0].value;

        $scope.projects = [];
        Restangular.all('accounts').get('queryAllAccounts').then(function (accounts) {
            $scope.accounts = accounts;
        });

        $scope.clearFilter = function () {
            $scope.filterTerm = '';
        };

        $scope.accountSelected = function (account) {
            $scope.selectedAccount = account;
            $scope.links = {};
            $q.all([
                Restangular.one('accounts', account.id).getList('buildings'),
                Restangular.one('accounts', account.id).customGET('queryTokenForSnapshot'),
                Restangular.one('Profiles').customGET('queryProfileForAccountUser', { accountId: account.id })
            ]).then(function (result) {
                $scope.buildings = result[0];
                $scope.authToken = result[1];
                $scope.profile = result[2];
            }, Errors.forPromise());
        };

        $scope.buildingSelected = function (building) {
            DataStore.getList<aq.projectCenter.Project>(RestangularV3.one(''), 'projects', { buildingId: building.id })
                .then((result) => {
                    $scope.projects = result;
                    $scope.getSnapshotLink();
                }, Errors.forPromise());
        };

        $scope.projectSelected = function (project) {
            $scope.getProjectCenterReportLink();
        };

        function reportsPath(type) {
            let path = getLocationOrigin() + '/reports/summary/' + type + '?authToken=' + $scope.authToken + '&accountId=' + $scope.selectedAccount.id + '&buildingId=' + $scope.selectedBuilding.id + '&type=' + $scope.type;

            if ($scope.simulatedDate) {
                path += '&date=' + moment($scope.simulatedDate).valueOf();
            }
            if ($scope.startDate) {
                path += '&startDate=' + moment($scope.startDate).valueOf();
            }
            if ($scope.endDate) {
                path += '&endDate=' + moment($scope.endDate).valueOf();
            }
            if (type === 'projectSummary') {
                path += '&profileId=' + $scope.profile.id;
            }
            path += '&manual=true';
            return path;
        }

        function projectReportsPath() {
            const path = getLocationOrigin() + '/reports/project/projectDetails' + '?authToken=' + $scope.authToken + '&accountId=' + $scope.selectedAccount.id + '&buildingId=' + $scope.selectedBuilding.id + '&projectId=' + $scope.selectedProject.id;
            return path;
        }

        $scope.getProjectCenterReportLink = function () {
            $scope.links.project = projectReportsPath();
        };
        $scope.getSnapshotLink = function () {
            $scope.links.executive = reportsPath('executive');
            $scope.links.daily = reportsPath('daily');
            $scope.links.monthly = reportsPath('monthly');
            $scope.links.customDateRange = reportsPath('customDateRange');
            $scope.links.monthlyBuildingEngineer = reportsPath('monthlyBuildingEngineer');
            $scope.links.portfolio = reportsPath('portfolio');
            $scope.links.projectSummary = reportsPath('projectSummary');
            $scope.links.projectStatus = reportsPath('projectMonthlyStatus');
        };

        $scope.populatePortfolioReportCacheForYear = function (ev) {
            const confirm = $mdDialog.confirm()
                .title('Are you sure you want to update the entire cache?')
                .textContent('This will populate the cache for all buildings in this account and could take upto 30 minutes to complete. Only select this option if populating one month at a time is not viable.')
                .ariaLabel('Update entire cache')
                .targetEvent(ev)
                .ok('Yes')
                .cancel('Cancel');
            $mdDialog.show(confirm).then(() => {
                if (!$scope.simulatedDate) {
                    $scope.simulatedDate = new Date();
                }
                RestangularV3.one('internal').one('update-reports-cache')
                    .customPOST(null, 'byAccount-all-types', { accountId: $scope.selectedAccount.id, date: $scope.simulatedDate });
            });
        };

        $scope.populatePortfolioReportCacheForMonth = function () {
            Restangular.one('internal').one('accounts', $scope.selectedAccount.id)
                .customPOST(null, 'populatePortfolioReportCache', { date: $scope.simulatedDate })
                .then(function () {
                    Messages.success('Portfolio report cache updating...');
                });
        };

        const downloadURI = function (uri, fileName) {
            const link = document.createElement('a');
            link.download = fileName;
            link.href = uri;
            link.click();
        };

        $scope.reportStatus = function (report, name) {
            Restangular
                .one('accounts', $scope.account.id)
                .one('ScheduledReports')
                .customGET('reportStatus', { statusId: report.statusId, noCache: new Date().getTime() })
                .then(function (res) {
                    if (!$scope.reports[report.statusId]) {
                        $scope.reports[report.statusId] = {};
                    }
                    if (name) {
                        $scope.reports[report.statusId].name = name;
                    }
                    _.assignIn($scope.reports[report.statusId], res);
                    if (res.status !== 'completed' && res.status !== 'failed') {
                        $timeout($scope.reportStatus, 1000, true, res);
                        return;
                    }
                    if (!res.downloadUrl) {
                        Messages.error('Failed to generate ' + $scope.reports[report.statusId].name);
                    } else {
                        Messages.success('Report complete');
                        downloadURI(res.downloadUrl, $scope.reports[report.statusId].name + '.pdf');
                        delete $scope.reports[report.statusId];
                    }
                });
        };

        $scope.generateReport = function (url, name) {
            Restangular
                .one('accounts', $scope.account.id)
                .one('ScheduledReports')
                .one('generateReport')
                .customPOST({}, '', { url, noCache: new Date().getTime() })
                .then(function (status) {
                    Messages.info('Generating the requested report...');
                    $scope.reportStatus(status, name);
                });
        };

        $scope.switchProfileAndGenerateReport = function (url, name) {
            $scope.switchProfileIfRequired()
                .then((isOk) => {
                    if (isOk) {
                        $scope.generateReport(url, name);
                    }
                });
        };

        $scope.switchProfileAndOpenReport = function (url) {
            const newTab = $window.open('', '_blank');
            $scope.switchProfileIfRequired()
                .then((isOk) => {
                    if (isOk) {
                        newTab.location.href = url;
                    }
                });
        };

        $scope.switchProfileIfRequired = function () {
            const accountId = $scope.selectedAccount.id;
            const isCurrentProfile = $scope.user.currentProfile.account.id == accountId;
            if (isCurrentProfile) {
                return $q.when(true);
            }
            const targetProfile = _.find($scope.user.profiles, (p) => p.account == accountId);
            if (!targetProfile) {
                Messages.error('Selected Account is not accessible to the current User');
                return $q.when(false);
            }
            return $scope.user.one('selectProfile', targetProfile.id)
                .put({ inflate: 'currentProfile,currentProfile.account,currentProfile.apps,buildingGroups' })
                .then(() => {
                    return true;
                }, (error) => {
                    Messages.error('Unable to access selected User Account');
                    return false;
                });
        };

        function getLocationOrigin() {
            return $location.protocol() + '://' + $location.host() + ':' + $location.port();
        }

        $scope.setStartAndEndToPreviousMonth = function () {
            $scope.endDate = moment().subtract(1, 'month').endOf('month');
            $scope.startDate = moment().subtract(1, 'month').startOf('month');
            if ($scope.selectedAccount && $scope.selectedAccount.id) {
                $scope.getSnapshotLink();
            }
        };

        $scope.onChangeDateRange = function () {
            if ($scope.dateRangeDecision == 'lastMonth') {
                $scope.setStartAndEndToPreviousMonth()
            }
        };

        $scope.updateStartDate = function (newDate) {
            $scope.startDate = newDate;
            $scope.getSnapshotLink();
        };

        $scope.updateEndDate = function (newDate) {
            $scope.endDate = newDate;
            $scope.getSnapshotLink();
        };

        $scope.setStartAndEndToPreviousMonth();
    })
    .directive('forceSelectFocus', function () {
        return {
            restrict: 'A',
            require: ['^^mdSelect', '^ngModel'],
            link: function (scope, element) {
                scope.$watch(function () {
                    let foundElement: any = element;
                    while (!foundElement.hasClass('md-select-menu-container')) {
                        foundElement = foundElement.parent();
                    }
                    return foundElement.hasClass('md-active');
                }, function (newVal) {
                    if (newVal) {
                        element.focus();
                    }
                });
            }
        };
    });
