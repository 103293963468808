namespace aq.deviceManagement {
    export class AddClassification extends aq.common.Controllers.ModalCtrl {
        private newClassification: any;
        private measures;
        /* @ngInject */
        constructor(public $mdDialog: ng.material.IDialogService, category: string, make: string, series: string, model: string, measures) {
            super({}, $mdDialog);

            // remove measures that shouldn't be used for device classes
            this.measures = _.remove(measures, function (measure) {
                return _.indexOf(['weather', 'network_connection', 'expected_energy'], measure.name) === -1;
            });
            this.newClassification = {
                category,
                make,
                series,
                model
            };
        }

    }

    angular
        .module('deviceManagement')
        .controller('AddClassification', AddClassification);
}