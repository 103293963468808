namespace aq.admin {
    export class AdminAccountsCtrl {
        public accountOrder;
        public paging;
        public searchText;
        public accountResults;
        public user;
        public measurementSystem;
        /* @ngInject */
        constructor(
            public $scope,
            public Restangular,
            public $mdDialog,
            public accounts,
            public loading,
            public licenses,
            public Messages,
            public options,
            public timeZones,
            public $state
        ) {
            this.user = $scope.user;
            this.accountOrder = 'accountName';
            this.paging = {
                page: 1,
                limit: 10,
                total: this.accounts.count
            };
            this.accountResults = this.accounts.accounts;
            this.measurementSystem = _.find(this.options.measurementSystems, (option) => option.value === this.user.measurementSystem);
        }

        search(searchText) {
            const desc = this.accountOrder.indexOf('-') === 0;
            const orderBy = desc ? this.accountOrder.substring(1) : this.accountOrder;
            this.loading.start(true);
            return this.Restangular.one('accounts').get({
                inflate: 'apps,license,functionalities,license.functionalities,partner,accountCustomReports',
                page: this.paging.page,
                pageSize: this.paging.limit,
                orderBy,
                search: searchText,
                asc: !desc
            }).then((result) => {
                this.accountResults = result.accounts;
                this.paging.total = result.count;
                this.loading.stop();
            });
        };

        goToAccount(account) {
            this.$state.go('aq.admin.accounts.accounts.details.configuration', { accountId: account.id, account });
        }

        createAccount() {
            this.$state.go('aq.admin.accounts.create',
                {
                    newAccountObj: {
                        account: {
                            license: null,
                            measurementSystem: 'US_CUSTOMARY_UNITS',
                            timeZoneId: 'America/New_York',
                            accountName: ''
                        },
                        accountsPage: true
                    }
                });
        };
    }

    angular
        .module('aq.admin.accounts.accounts')
        .controller('AdminAccountsCtrl', AdminAccountsCtrl);
}

