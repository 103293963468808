angular.module('aq.ui.elemSelector', ['infinite-scroll']).directive('elemSelector', function(Auth) {
    return {
        restrict : 'E',
        replace: true,
        transclude: true,
        templateUrl: 'app/common/directives/listSelector/elemSelector.html',
        scope: {
            title: '@',
            showAdd: '=',
            showHeader: '=',
            addListItem: "&",
            items: '=',
            selected: '=',
            orderBy: '=',
            infiniteScroller: '=',
            onShouldShow: "&",
            onSelect: '&',
            groupByItems: '=',
            selectedGroup: '=',
            groupBy: '&',
            isSortable: '@',
            reorderItem: '&',
            overrideAccess: '@?'
        },
        // Todo: Change to ng-model once we move to 1.2. ngModel doesn't work well with isolated scope now
        link: function($scope, $element, $attrs) {
            $scope.showAll = !$attrs.onShouldShow;
            $scope.select = function(item) {
                $scope.selected = item;

                if ($scope.onSelect) {
                    $scope.onSelect({ item: item });
                }

            }

            $scope.hasAccess = false;
            if (!$scope.overrideAccess) {
                if (Auth.check({ access: 'FULL_ACCESS' })) {
                    $scope.hasAccess = true;
                }

            } else {
                $scope.hasAccess = true;
            }

        }
    }
});
