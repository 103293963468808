namespace aq.reportCenter {
    export class ReportParamsCtrl extends aq.common.Controllers.ModalCtrl {
        public selectedReport: any;
        public selectedDate: Date;
        public isGenerating: boolean;
        private today: Date;
        private startDate: Date;
        private endDate: Date;
        private endYear;
        private endMonth;
        /* ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            protected $scope: ng.IScope,
            private buildingItems: SelectionItem[],
            private reportName: string,
            private monthlyReport: boolean,
            private customDateReport: boolean
        ) {
            super({}, $mdDialog);
            this.today = moment().toDate();
            this.selectedDate = this.today;
            this.endYear = moment().year();
            this.endMonth = moment().month() + 1;

            this.endDate = moment(this.selectedDate).endOf('month').toDate();
            this.startDate = moment(this.selectedDate).startOf('month').toDate();

            this.$scope.$watch(() => this.selectedDate, () => {
                if (this.monthlyReport) {
                    this.endDate = moment(this.selectedDate).endOf('month').toDate();
                    this.startDate = moment(this.selectedDate).startOf('month').toDate();
                }
            });
        }
        public onClickGenerateReport() {
            this.isGenerating = true;
            const reportParams: GenerateReportParams = {
                scheduledReportId: this.selectedReport ? this.selectedReport.scheduledReportId : null,
                date: this.selectedDate ? moment(this.selectedDate).valueOf() : null,
                startDate: this.startDate ? moment(this.startDate).valueOf() : null,
                endDate: this.endDate ? moment(this.endDate).valueOf() : null
            };
            this.hide(reportParams);
        }
        public onSelectedBuildingChange(data) {
            this.selectedReport = data;
        }
    }
    angular.module('reportCenter').controller('ReportParamsCtrl', ReportParamsCtrl);
}
