angular.module('properties.sources').controller('SourceDetailsCtrl',
    function ($scope, Restangular, $stateParams, $q, loading, Errors, ParentSourceHandler, Messages, Auth, $state, SourcesList, PropertySettingsOptions, $timeout, account, building, authAccess) {

        $scope.propertySettingsAccess = authAccess['Property Settings'];

        loading.start();

        var sources = SourcesList;
        $scope.parentSource = {};

        $scope.getSource = function () {
            var inflate = 'childSources,circuits,circuits.inputs,circuits.inputs.point,circuits.inputs.point.collector,circuits.point,circuits.point.collector,circuits.resource,circuits.resource.type,parentSource,parentSource.circuits,parentSource.circuits.resource,sourceClass,possibleParentSources,possibleParentSources.circuits';
            Restangular.one("accounts", account.id).one("buildings", building.id).one('sources', $stateParams.sourceId).get({ single: true, inflate: inflate }).then(function (source) {
                /***
                 * assign source model to the new selectedChildSource property on circuit so we can properly detect
                 * selected model in dropdown
                 */
                _.each(source.circuits, function (circuit) {
                    if (!circuit.resource) return;
                    var childSourcesIds = _.map(source.childSources, 'id');
                    if (_.includes(childSourcesIds, circuit.resource.id)) {
                        circuit.selectedChildSource = _.findById(source.childSources, circuit.resource.id);

                        circuit.resource.type = { id: 0, name: "Child Source" };
                    }
                })

                $scope.source = source;

                $scope.metricName = getMetricFromId($scope.source.metric);

                PropertySettingsOptions.getVoltagesForPhase($scope.source.phase);
                $scope.phase = $scope.source.phase;
                $scope.energyUse = $scope.options.energyUses[0];
                $scope.options.energyUses = _.filter($scope.options.energyUses, function (eu) {
                    return $scope.source.metric == eu.metric || eu.id === 0;
                })

                $scope.loadCircuitsCount = _.filter($scope.source.circuits, { 'type': 'LOAD' }).length;
                $scope.source.relationship = getRelationship(source);

                if ($scope.source.parentSource) {
                    $scope.updateParentSourceSelected($scope.source);
                }

                loading.stop();
            });
        }

        PropertySettingsOptions.init($scope.account).then(function (data) {
            $scope.options = data;

            $scope.selectDefaultBuilding().then(function () {
                $scope.getSource();
            });
        })

        // load async all sources
        $scope.$watch('building', function (building) {
            if (!building) return;
            Restangular.one("accounts", account.id).one("buildings", building.id).getList('sources', { inflate: 'circuits', child: true }).then(function (sources) {
                $scope.allSources = sources;
            })
        })

        $scope.updateParentSourceSelected = function (source) {
            $scope.parentSource.circuit = ParentSourceHandler.selectedCircuit(source);
            $scope.parentSource.circuits = ParentSourceHandler.circuits(source.parentSource.circuits, false);
            if ($scope.parentSource.circuit) {
                $scope.parentSource.circuits.push($scope.parentSource.circuit);
            }
        }

        $scope.updateRelationship = function (relationship) {
            //set mapped flag, we need this to set relationship
            $scope.source.mapped = $scope.source.relationship.value;
            // filter out from parent sources options selected source and all
            // unmapped sources when child is selected as relationship
            if ($scope.source.relationship.name == 'Child') {
                if (!$scope.source.parentSource) return $q.when([]);

                if ($scope.parentSource.circuit) {
                    ParentSourceHandler.attachToParent($scope.source, $scope.parentSource.circuit)
                    return $scope.update();
                }
            }

            if ($scope.source.relationship.name == 'Unmapped' || $scope.source.relationship.name == 'Master') {
                if ($scope.source.parentSource) {
                    return ParentSourceHandler.detachFromParent($scope.source, $scope.source.mapped).then(function () {
                        $scope.getSource(); //infalte all dependecies
                    });
                }
                return $scope.update();
            }
        }

        $scope.update = function () {
            if (!$scope.source || !$scope.source.put) return;
            $scope.source.phase = parseInt($scope.source.phase);
            return $scope.source.put({ inflate: 'circuits,circuits.resource' })
                .catch(Errors.forCRUD('UPDATE', 'Source with the same name already exists in the building'));
        }

        $scope.delete = function () {
            $scope.source.remove().then(function () {
                $state.transitionTo('aq.properties.buildings.sources.list', {
                    accountId: $stateParams.accountId,
                    buildingId: $stateParams.buildingId
                });
            }, Errors.forCRUD('DELETE'));
        }

        var getMetricFromId = function (metricID) {
            return _.find($scope.options.metrics, { id: metricID }).name;
        }

        var getRelationship = function (source) {
            if (source.mapped == false) {
                return _.find($scope.options.relationships, { name: "Unmapped" });
            } else if (!source.parentSource) {
                return _.find($scope.options.relationships, { name: "Master" });
            } else {
                return _.find($scope.options.relationships, { name: "Child" });
            }
        }

        $scope.updateVirtualPoint = function (circuit) {
            var circuit = Restangular.restangularizeElement($scope.source, circuit.circuit, 'circuits');
            return circuit.put({ inflate: 'resource' });
        }

    });
