namespace aq.tenantbilling {
    export class AddEditTenantServiceFeeCtrl extends aq.common.Controllers.ModalCtrl {
        public unit: string;
        public feeTypes;
        public serviceTypes: string[];
        /* @ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            public serviceFee: TenantService,
            public serviceMethod: string,
            private tenantServices: TenantService[],
            private billingSetupId,
            private utilityServices: UtilityService[],
            private TenantBillingSetupService: TenantBillingSetupService,
            private OptionsService,
            private Auth: aq.services.Auth,
        ) {
            super({}, $mdDialog);
            this.feeTypes = this.TenantBillingSetupService.getFeeTypes();
            this.serviceTypes = _(this.OptionsService.getAllUnits())
                .map('serviceType')
                .compact()
                .uniq()
                .filter(serviceType => serviceType !== 'ENVIRONMENT')
                .value();
            if (!this.serviceFee) {
                if (this.serviceMethod != 'METERED_USAGE' && this.serviceMethod != 'FEE' && this.serviceMethod != 'TAX') {
                    this.serviceMethod = null;
                }
                this.serviceFee = {
                    method: this.serviceMethod,
                    feeType: null,
                    name: '',
                    serviceId: null,
                    rate: null,
                    utilityService: null,
                    serviceType: null,
                    setup: this.billingSetupId
                } as TenantService;
            } else {
                this.initUnit();
            }
        }
        public cancel(data?): void {
            this.$mdDialog.cancel();
        }
        public hide(data): void {
            this.$mdDialog.hide(data);
        }
        public saveServiceFee() {
            let action;
            const untypedService: any = this.serviceFee;
            if (untypedService.id) {
                action = this.TenantBillingSetupService.updateTenantFee(untypedService);
            } else {
                action = this.TenantBillingSetupService.createTenantFee(untypedService);
            }
            action.then((result) => {
                this.hide(result);
            });
        }
        public getUtilityServices() {
            return _.filter(this.utilityServices, { type: this.serviceFee.serviceType });
        }
        public getUtilityServiceName(id: number): string {
            const utilityService = _.find(this.utilityServices, (us) => us.id == id);
            return utilityService ? `${utilityService.name} - Account: ${utilityService.account}` : '';
        }
        public onChangeServiceType() {
            this.serviceFee.utilityService = null;
        }
        public onChangeServiceFeeMethod() {
            this.serviceFee.rate = null;
            this.serviceFee.utilityService = null;
            this.serviceFee.feeType = null;
            this.unit = '';
        }
        public onChangeUtilityService() {
            if (!this.serviceFee.utilityService) {
                return;
            }
            const utilityService: UtilityService = _.find(this.utilityServices, (us) => us.id == this.serviceFee.utilityService);
            this.unit = this.TenantBillingSetupService.getPrefixForServiceType(utilityService.type);
        }
        public onChangeFeeType() {
            this.unit = this.TenantBillingSetupService.getPrefixForFeeType(this.serviceFee.feeType);
        }
        private initUnit() {
            if (this.serviceFee.method == 'FEE') {
                this.onChangeFeeType();
            } else {
                this.onChangeUtilityService();
            }
        }
    }
    angular.module('tenantBilling').controller('AddEditTenantServiceFeeCtrl', AddEditTenantServiceFeeCtrl);
}
