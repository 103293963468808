angular
    .module('aq.ui.reports.aside', ['aq.ui.reports.service', 'mgcrea.ngStrap.aside', 'ui.router.state', 'aq.services.errors']) // TODO - is it possible to fix tests so we don't need to include deps that are included on main app.js - also does it makes sense?
    .directive('reportAside', function ($aside, $state, $location, Errors, $modal, $aq) {
        return {
            restrict: 'A',
            scope: {
                account: '=',
                reportData: '=',
                recipients: '=',
                reportsMetadata: '=',
                user: '='
            },
            link: reportAside
        }

        function reportAside($scope, $element) {

            $scope.loadReports = function () {
                return $scope.reportData.getScheduledReports().then(function (reports) {
                    $scope.reports = reports;
                })
            }

            $element.bind('click', function () {
                $scope.loadReports().then(function () {
                    $aside({
                        templateUrl: 'app/common/directives/report/reportAside.html',
                        show: true,
                        backdrop: true,
                        scope: $scope
                    });
                })
            });

            $scope.openDeleteScheduleReport = function(report) {
                $scope.currentReportToDelete = report;
                $scope.$aq = $aq;
                $modal({
                    show: true,
                    templateUrl: 'app/common/directives/report/reportDeleteConfirmation.html',
                    scope: $scope
                });
            }

            $scope.viewReport = function (report, $hide) {

                var currentStateParams = {
                    accountId: $scope.account.id
                }

                _.extend(currentStateParams, $location.search());

                // TODO - check if it is possible to do back with ui-router
                // and preselected parameters
                var currentState = {
                    title: 'Back to ' + $state.current.data.appName,
                    state: $state.current.name + '(' + JSON.stringify(currentStateParams) + ')'
                }

                $state.get('aq').data.goBack = currentState;

                $state.transitionTo('aq.reportCenter.history', {
                    accountId: $scope.account.id,
                    name: report.name,
                    module: $state.current.data.appName
                })

                $hide();

            }

            $scope.viewReportConfig = function ( $hide) {
                $state.transitionTo('aq.reportCenter.reports', {
                    accountId: $scope.account.id,
                    module: $state.current.data.appName
                })
                $hide();
            }

        }

    });