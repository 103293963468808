namespace aq.dashboard.widgets {
    
    export function RemoveSources() {
        return function (drilldown) {
            _.remove(drilldown, function(n:any) {
                return (n.route == "resources" && n.childrenRoute == "points");
            });
            return drilldown;
        }
    };

    angular.module('aq.filters').filter("removeSources", RemoveSources);
}