/**
 * Creates a partial data series from Data API response
 *
 * Given a data response it will create a list of partial data series
 * (one or several per each data item which has partial data for that unit)
 *
 * Response:
 * [{name: "name 1", timestamps: [...]}, {"name 2", timestamps: [..]}, ... ]
 *
 */
angular.module('charting').filter('partial', function() {

    return function(data) {
        if (!data || data.length == 0) {
            return [];
        }

        var partials = _(data).filter(function(elem) {
            return elem.partial;
        }).map('partial').flatten().map(function(elem) {
            return {
                name: elem.collectors.join(", "),
                timestamps: elem.timestamps
            }
        }).value();

        return partials;
    }

});