namespace aq.energyInsights {
    export class EnergyInsightsMainCtrl {
        private appRestructure;
        /* @ngInject */
        constructor(private $scope,
                    private account: aq.common.models.Account,
                    private buildings: aq.common.models.Building[],
                    private currentBuilding: aq.common.models.Building,
                    private insights,
                    private UserService,
                    private $mdDialog,
                    private $state: ng.ui.IStateService,
                    Auth: aq.services.Auth,
                    private $timeout) {
            $scope.showBuildingSelector = buildings.length > 1;
            $scope.currentBuilding = currentBuilding;
            $scope.showBetaInsights = UserService.currentUser.userType === 'ADMINISTRATOR';
            $scope.availableBuildings = buildings;
            this.appRestructure = Auth.hasFunctionality('App Restructure');
            const fullInsights = _.filter(insights, (insight: any) => {
                return !_.isNil(insight.state);
            });
            $scope.menu = {
                open: false,
                insights: fullInsights
            };

            $scope.$watch('menu.open', (open) => {
                // broadcast after our sidenav resizes, we have a 0.5sec animation
                this.$timeout(() => {
                    this.$scope.$broadcast('highchartResize');
                }, 500);
            });
        }

        public showCreateNewInsightModal = (ev) => {
            this.$mdDialog.show({
                /* @ngInject */
                controller: function ($scope, $mdDialog) {
                    $scope.hide = function () {
                        $mdDialog.hide();
                    };
                },
                templateUrl: 'app/energyInsights/main/newInsightModal/newInsightModal.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true
            });
        }
    }
    angular.module('energyInsights').controller('EnergyInsightsMainCtrl', EnergyInsightsMainCtrl);
}
