/**
 * Calculate how many meters has last node and based on that calculate margin-bottom
 * offset for vertical blue connector line
 */
angular.module('properties.dataCollectors').filter('lastNodeMetersCount', function () {
    return function(nodes) {
        if (!nodes || nodes.length == 0) return;

        var metersCount = _.last(nodes).childCollectors.length;
        if (metersCount <= 1) {
            return 46;
        } else {
            return metersCount * 78 - 32;
        }
    }
})