var aq;
(function (aq) {
    var reports;
    (function (reports) {
        var BaseBuildingReportCtrl = /** @class */ (function (_super) {
            __extends(BaseBuildingReportCtrl, _super);
            function BaseBuildingReportCtrl(currentDate, account, building, energyStarScore, functionalities, OptionsService, availableMetrics, meters, $timeout) {
                var _this = _super.call(this, $timeout) || this;
                _this.currentDate = currentDate;
                _this.account = account;
                _this.building = building;
                _this.energyStarScore = energyStarScore;
                _this.functionalities = functionalities;
                _this.OptionsService = OptionsService;
                _this.availableMetrics = availableMetrics;
                _this.meters = meters;
                _this.$timeout = $timeout;
                _this.supportedTypes = [
                    'electricity',
                    'water',
                    'gas',
                    'steam',
                    'heat'
                ];
                var serviceReports = [];
                var colors;
                if (account && account.accountName.toLowerCase() === 'under armour') {
                    colors = {
                        primaryColor: '#F05660',
                        secondaryColor: '#B4B5B5',
                        weatherPrimaryColor: '#F05660',
                        weatherSecondaryColor: '#FFDD00'
                    };
                }
                else {
                    colors = {
                        primaryColor: '#008BF5',
                        secondaryColor: '#B4B5B5',
                        currentYearColor: '#008BF5',
                        secondYearColor: '#ba7dd4',
                        thirdYearColor: '#FFA100',
                        weatherPrimaryColor: '#FFA100',
                        weatherSecondaryColor: '#FFDD00'
                    };
                }
                var reportBuilding = _this.building;
                reportBuilding.energyStarScore = _this.energyStarScore;
                _.extend(reportBuilding, { currentTime: moment(_this.currentDate).tz(_this.building.timeZoneId) });
                var types = _.filter(_this.supportedTypes, function (type) {
                    var hasMetric = _.some(_this.availableMetrics, function (metric) { return metric.toLowerCase() == type; });
                    if (!hasMetric) {
                        return false;
                    }
                    if (type == 'water') {
                        var waterMeters = _.filter(_this.meters, function (meter) { return meter.measure == 'water'; });
                        var isOnlyManualMeters = _.every(waterMeters, function (meter) { return meter.manualMeter == true; });
                        return isOnlyManualMeters ? false : true;
                    }
                    else {
                        return true;
                    }
                });
                var measurementSystem = _this.OptionsService.measurementSystem;
                var typeUnit = {
                    electricity: ['kWh', 'kW'],
                    water: [measurementSystem.waterUnit, measurementSystem.waterFlowRateUnit],
                    gas: [measurementSystem.gasUnit],
                    steam: [measurementSystem.steamUnit, measurementSystem.steamRateUnit],
                    heat: [measurementSystem.heatUnit, measurementSystem.heatRateUnit]
                };
                _this.measurementType = {
                    electricity: ['ENERGY', 'POWER', 'POWER_EXPECTED', 'POWER_STDDEV'],
                    water: ['WATER', 'FLOW_RATE'],
                    gas: ['GAS', 'FLOW_RATE'],
                    steam: ['STEAM_MASS', 'STEAM_MASS_RATE'],
                    heat: ['HEAT_TRANSFER', 'HEAT_TRANSFER_RATE']
                };
                _.each(types, function (type) {
                    var reportConfig = {
                        account: account,
                        colors: colors,
                        functionalities: _.map(_this.functionalities, 'function'),
                        building: reportBuilding,
                        areaMeasurementUnit: _this.OptionsService.areaMeasurementUnit(),
                        temperatureUnit: _this.OptionsService.temperatureUnit(),
                        measures: _this.measurementType[type],
                        metrics: _this.measurementType[type].toString().toLowerCase(),
                        usageMetric: _this.measurementType[type][0].toString().toLowerCase(),
                        demandMetric: _this.measurementType[type][1].toString().toLowerCase(),
                        usageUnit: _this.OptionsService.getUnitByEnumName(typeUnit[type][0]),
                        isWaterOrGasReport: type != 'electricity'
                    };
                    if (type == 'electricity') {
                        reportConfig.expectedDemandMetric = _this.measurementType[type][2].toString();
                        reportConfig.expectedDemandVariance = _this.measurementType[type][3].toString();
                    }
                    // we don't have demand on gas
                    if (type != 'gas') {
                        reportConfig.demandUnit = _this.OptionsService.getUnitByEnumName(typeUnit[type][1]);
                    }
                    serviceReports.push({
                        config: reportConfig,
                        data: null
                    });
                });
                _this.buildingReport = {
                    building: reportBuilding,
                    serviceReports: serviceReports
                };
                return _this;
            }
            return BaseBuildingReportCtrl;
        }(reports.BaseDocraptorReportCtrl));
        reports.BaseBuildingReportCtrl = BaseBuildingReportCtrl;
        angular.module('aq.reports').controller('BaseBuildingReportCtrl', BaseBuildingReportCtrl);
    })(reports = aq.reports || (aq.reports = {}));
})(aq || (aq = {}));
