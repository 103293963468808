angular
        .module('dashboard')
        .directive('aqDonutChart', () => {
     return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/dashboard/common/directives/donutchart/donutchart.html',
        scope: {
                backgroundColor: '=',
                emptyColor: '=',
                percentColor: '=',
                textColor: '=',
                fontSize: '=',
                percent: '='
        },
        link: ($scope: any) => {
                // determine fill for donut chart
                $scope.strokeOffset = 251.2 - (251.2 * ($scope.percent / 100));
        }
     }
});