angular
    .module('aq.ui')
    .directive('formatMac', () => {
        return {
            restrict: 'A',
            require: 'ngModel',
            link(scope: ng.IScope, elem: ng.IAugmentedJQuery, attrs, ngModelCtrl: ng.INgModelController) {
                // formats text going to user (from model to view)
                const formatFromModel = (value: string) => {
                    if (value) {
                        // strip special characters and standardise format with a whitelist
                        value = value
                            .toLowerCase()
                            .replace(/[^0-9a-f]/g, '')
                            .substring(0, 16)
                            .replace(/(.{2})/g, '$1:')
                            .replace(/:$/, '');
                        return value;
                    }

                    return value;
                };
                // parses text going to model (from view to model)
                const parseToModel = (value: string) => {
                    return value
                        .trim()
                        .replace(/:/g, ' ')
                        .toLowerCase();
                };

                ngModelCtrl.$formatters.push(formatFromModel);
                ngModelCtrl.$parsers.push(parseToModel);

                elem.on('input', () => {
                    const value = formatFromModel(elem.val());
                    ngModelCtrl.$setViewValue(value);
                    ngModelCtrl.$render();
                    scope.$apply();
                });
            }
        };
    });
