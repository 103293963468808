namespace aq.ui {
    export class AqVerifyBuildingsStepCtrl {
        public buildings: aq.common.models.Building[];
        private isValidBuildingList: boolean;
        private header: string;

        /* @ngInject */
        constructor(
            protected $scope,
            private BuildingService: aq.services.BuildingService,
            protected $mdDialog: ng.material.IDialogService) {
        }
        public $onInit() {
            this.updateValidation();
        }

        public editBuilding(building: aq.common.models.Building, isNew: boolean) {
            this.$mdDialog.show({
                controller: 'EditBuildingModalCtrl as vm',
                templateUrl: 'app/components/verifySteps/modals/editBuildingModal.html',
                clickOutsideToClose: false,
                locals: {
                    editBuilding: building,
                    isNew
                }
            })
                .then((updatedBuilding: aq.common.models.Building) => {
                    const isDiff = building.name != updatedBuilding.name
                        || building.address != updatedBuilding.address
                        || building.city != updatedBuilding.city
                        || building.state != updatedBuilding.state
                        || building.zipCode != updatedBuilding.zipCode
                        || building.country != updatedBuilding.country
                        || building.size != updatedBuilding.size;
                    if (isDiff) {
                        building.isUpdate = true;
                        building.name = updatedBuilding.name;
                        building.address = updatedBuilding.address;
                        building.city = updatedBuilding.city;
                        building.state = updatedBuilding.state;
                        building.zipCode = updatedBuilding.zipCode;
                        building.country = updatedBuilding.country;
                        building.size = updatedBuilding.size;
                    }
                })
                .catch(() => {
                    if (isNew) {
                        _.remove(this.buildings, building);
                    }
                }).finally(() => {
                    this.updateValidation();
                });
        }

        public createBuilding(): void {
            const building = <aq.common.models.Building>{};
            building.personnel = [];
            let index = 1;
            if (this.buildings.length > 0) {
                index = this.buildings[this.buildings.length - 1].index + 1;
            }
            building.index = index;
            this.buildings.push(building);
            this.editBuilding(building, true);
        }

        public deleteBuilding(building, event): void {
            event.stopPropagation();
            (this.$mdDialog as any).show({
                controller: 'NestedConfirmDialog as vm',
                templateUrl: 'app/admin/accounts/accounts/create/nestedConfirmDialog.html',
                clickOutsideToClose: false,
                multiple: true,
                locals: {
                    promptQuestion: 'Are you sure you want to delete this building?'
                }
            })
            .then(() => {
                _.remove(this.buildings, building);
            }).finally(() => {
                this.updateValidation();
            });
        }

        public isMissingData(building: aq.common.models.Building) {
            return building.name == null
                || building.address == null
                || building.city == null
                || building.state == null
                || building.zipCode == null
                || !building.size;
        }

        public getBuildingAddressView(building: aq.common.models.Building) {
            const data = [building.address, building.city, building.state, building.zipCode, building.country];
            return _.compact(data).join(', ');
        }

        private updateValidation() {
            this.isValidBuildingList = this.validateBuildings(this.buildings);
            this.$scope.$emit('isValidBuildingListUpdated', this.isValidBuildingList);
        }

        private validateBuildings(buildings: aq.common.models.Building[]): boolean {
            for (const building of buildings) {
                if (this.isMissingData(building)) {
                    return false;
                }
            }
            return true;
        }

    }
    angular
        .module('aq.ui')
        .component('aqVerifyBuildingsStep', {
            controller: AqVerifyBuildingsStepCtrl,
            controllerAs: 'vm',
            templateUrl: 'app/components/verifySteps/aqVerifyBuildingsStep.html',
            bindings: {
                buildings: '<',
                isValidBuildingList: '<',
                header: '<'
            }
        });
}
