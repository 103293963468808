var aq;
(function (aq) {
    var energyInsights;
    (function (energyInsights) {
        var WeatherNormalizationService = /** @class */ (function (_super) {
            __extends(WeatherNormalizationService, _super);
            function WeatherNormalizationService(EnergyInsightsDataService, $q, $filter, Restangular, $translate) {
                var _this = _super.call(this) || this;
                _this.EnergyInsightsDataService = EnergyInsightsDataService;
                _this.$q = $q;
                _this.$filter = $filter;
                _this.Restangular = Restangular;
                _this.$translate = $translate;
                return _this;
            }
            WeatherNormalizationService.prototype.getSimpleTargetModel = function (building, measure, periodSearch) {
                var targetModel = new energyInsights.SimpleTargetModel();
                targetModel.title = this.$translate.instant('insight.Weather Normalization');
                targetModel.when = this.$translate.instant('insight.when.Weather');
                return targetModel;
            };
            WeatherNormalizationService.prototype.getTargetModel = function (building, measure, periodSearch, account) {
                var _this = this;
                var trendPeriodStart = moment(periodSearch.start).subtract(1, 'years');
                var trendPeriodEnd = moment(trendPeriodStart).add(1, 'years');
                return this.getKwHMetric(account)
                    .then(function (metric) {
                    return _this.$q.all([
                        _this.EnergyInsightsDataService.getWeatherNormalizedEnergy(building, periodSearch.start, periodSearch.end, metric, '1mon', account),
                        _this.EnergyInsightsDataService.getWeatherNormalizedEnergy(building, trendPeriodStart, trendPeriodEnd, metric, '1mon', account)
                    ]);
                }).then(function (results) {
                    var currentNorm = results[0];
                    var previousNorm = results[1];
                    return _this.calculateTargetModel(building, currentNorm, previousNorm, periodSearch);
                });
            };
            WeatherNormalizationService.prototype.getGraphModel = function (building, measure, periodSearch) {
                return null;
            };
            WeatherNormalizationService.prototype.getTableModel = function (building, measure, periodSearch) {
                return null;
            };
            WeatherNormalizationService.prototype.formatTableModel = function () {
                throw new TypeError('This is a stub method');
            };
            // ------------------------
            WeatherNormalizationService.prototype.getKwHMetric = function (account) {
                return this.Restangular.one('accounts', account.id).customGET('queryRealUnits').then(function (allUnits) {
                    return _.find(allUnits, { unit: 'kWh' });
                });
            };
            WeatherNormalizationService.prototype.calculateTargetModel = function (building, currentNorm, trendNorm, periodSearch) {
                var today = moment().tz(building.timeZoneId).format('MMM Do');
                var numberOfDaysInMonth = moment().tz(building.timeZoneId).diff(moment().tz(building.timeZoneId).startOf('month'), 'days');
                var percOfTime = this.getPercent(numberOfDaysInMonth, moment().tz(building.timeZoneId).daysInMonth());
                var targetModel = new energyInsights.TargetModel();
                targetModel.question = this.$translate.instant('insight.question.Weather') + " " + building.name + "?";
                targetModel.title = this.$translate.instant('insight.Weather Normalization');
                targetModel.target = this.getTarget(trendNorm, building);
                targetModel.showTarget = !_.isNull(targetModel.target);
                targetModel.showDonut = !_.isNull(targetModel.target);
                targetModel.formattedTarget = this.$translate.instant('insight.target.Target') + " " + this.$filter('number')(targetModel.target, '0,0') + " kWh";
                targetModel.answer = this.getAnswer(currentNorm, building);
                targetModel.formattedAnswer = this.$filter('number')(targetModel.answer, '0,0') + " kWh";
                targetModel.percentage = this.getPercent(targetModel.answer, targetModel.target);
                targetModel.color = this.getColor(targetModel.answer, targetModel.target, targetModel.percentage, percOfTime);
                targetModel.tooltip = this.$translate.instant('insight.when_tooltip.Actual') + " <span style=\"color:" + targetModel.color + "\">" + targetModel.percentage + "%</span> " + this.$translate.instant('insight.when_tooltip.As Of') + " " + today + ".";
                targetModel.when = this.$translate.instant('insight.when.Weather');
                targetModel.icon = this.getIcon(targetModel.answer, targetModel.target, targetModel.percentage, percOfTime);
                targetModel.timeElapsedPercentage = percOfTime;
                targetModel.timeElapsedLabel = today;
                return this.$q.when(targetModel);
            };
            WeatherNormalizationService.prototype.getAnswer = function (data, building) {
                var thisMonth = moment().tz(building.timeZoneId).startOf('month');
                var row = _.find(data, { timestamp: thisMonth.valueOf() });
                if (row) {
                    return row.value;
                }
                else {
                    return 0;
                }
            };
            WeatherNormalizationService.prototype.getTarget = function (data, building) {
                var trendMonth = moment().tz(building.timeZoneId).startOf('month').subtract('years', 1);
                var row = _.find(data, { timestamp: trendMonth.valueOf() });
                if (row) {
                    return row.value;
                }
                else {
                    return 0;
                }
            };
            return WeatherNormalizationService;
        }(energyInsights.InsightService));
        energyInsights.WeatherNormalizationService = WeatherNormalizationService;
        angular.module('energyInsights').service('WeatherNormalizationService', WeatherNormalizationService);
    })(energyInsights = aq.energyInsights || (aq.energyInsights = {}));
})(aq || (aq = {}));
