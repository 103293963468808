'use strict';

angular
    .module('aq.admin.accounts.sources', [])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.admin.accounts.sources', {
                url: '/sources',
                templateUrl: 'app/admin/accounts/sources/main.html',
                controller: 'AdminSourcesCtrl',
                data: {
                    breadcrumb: 'Sources',
                    icon: '.aq-icons-sources'
                }
            });
    });
