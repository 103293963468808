namespace aq.services {
    // this is a global provided by the auth0 spa JS
    import IHttpPromiseCallbackArg = angular.IHttpPromiseCallbackArg;
    declare const createAuth0Client: (options: ClientMeta & { realm: string }) => ng.IPromise<Auth0Client>;
    import Auth0Client = aq.models.Auth0Client;
    import ClientMeta = aq.models.authorization.ClientMeta;

    export class AuthorizationService {
        private clientMeta: aq.models.authorization.ClientMeta;
        private client: Auth0Client;
        private ready: ng.IPromise<void>;
        private sessionTimer: number;
        private isDisabled: boolean;

        /* @ngInject */
        constructor(
            private $http: ng.IHttpService,
            private $window: ng.IWindowService,
            private Messages: aq.services.Messages,
            private $q: ng.IQService,
            private $location: ng.ILocationService,
        ) {
            this.isDisabled = $location.search().authToken && $location.path().startsWith('/reports/');
            this.ready = $q((resolve, reject) => {
                if (this.isDisabled) {
                    reject();
                } else {
                    this.initMetadata()
                        .then((data) => {
                            this.clientMeta = data;
                            return this.initClient(data);
                        })
                        .then((client) => {
                            this.client = client;
                            resolve();
                        })
                        .catch(() => {
                            this.Messages.error('Fatal error initializing the application. Please contact support.');
                            this.logout();
                            reject();
                        });
                }
            });
        }

        private checkSession = (expiration: number) => () => {
            const now = new Date().getTime() / 1000; // convert ms to seconds
            if (expiration < now) {
                this.$window.clearInterval(this.sessionTimer);
                this.$window.location.reload();
            }
        };

        /**
         * initSessionTimer
         * @param expiration epoch timestamp our session will expire at
         * tracks the expiration time of our session, and reloads the page when we reach it.
         * this is to prevent users from leaving a browser window open over a prolonged period of time
         * and timing their session out without reloading the page (which would force a token refresh)
         */
        private initSessionTimer = (expiration: number): void => {
            if (this.sessionTimer) {
                this.$window.clearInterval(this.sessionTimer);
            }
            this.sessionTimer = this.$window.setInterval(this.checkSession(expiration), 60000);
        };

        private initMetadata(): ng.IPromise<ClientMeta> {
            return this.$http.get(`${this.$window.location.origin}/api/v3/identity/client-meta`)
                .then((res) => res.data)
                .catch((err) => {
                    this.Messages.error('Failure fetching auth client metadata');
                    throw err;
                });
        }

        private initClient(metadata: ClientMeta): ng.IPromise<Auth0Client> {
            return createAuth0Client({ ...metadata, realm: 'db-aquicore' })
                .then((client) => client)
                .catch((err) => {
                    this.Messages.error('Failure building auth client');
                    throw err;
                });
        }

        private onReady<T>(cb: (val: void) => T): ng.IPromise<T> {
            if (this.isDisabled) {
                return this.$q.when(void 0);
            }
            return this.ready.then(cb);
        }

        public getClientMeta(): ng.IPromise<ClientMeta> {
            return this.onReady(() => this.clientMeta);
        }

        public seedSession = (accessToken: string): ng.IPromise<void | Error> => {
            return this.onReady(() => {
                this.$http({
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    },
                    url: `${this.$window.location.origin}/api/v3/identity/`
                }).then((res: IHttpPromiseCallbackArg<{ userName: string; onboarded: boolean }>) => {
                    if (res.status < 300) {
                        if (res.data && !res.data.onboarded) {
                            // Must be nested for new 2021 onboarding experience
                            if (!res.data.userName) {
                                this.$window.location.href = '/register/new';
                                return;
                            }
                        }
                        const { enrollmentId } = this.$location.search();
                        if (enrollmentId) {
                            this.$window.location.href = `/users/enrollment/${enrollmentId}`;
                            return;
                        }
                        // @ts-ignore
                        this.$window.location = this.$window.location.origin;
                    }
                }).catch((err: Error) => {
                    this.Messages.error('There was a problem initializing your session');
                    return err;
                });
            });
        };

        public getTokenSilently(): ng.IPromise<string> {
            if (this.isDisabled) {
                const { authToken } = this.$location.search();
                return this.$q.when(authToken);
            }
            // sigh. typescript not understanding wrapped promises. (may be fixed if we bump version)
            // @ts-ignore
            return this.onReady(() => this.client.getTokenSilently(this.clientMeta)).then((token: string) => {
                try {
                    // token payload is middle part of the token xxx.here.yyy
                    const [, p] = token.split('.');
                    // payload is base64 encoded
                    const payload: {
                        aud: string[];
                        azp: string;
                        exp: number; // expiration timestamp in seconds
                        iat: number; // issued timestamp in seconds
                        iss: string;
                        scope: string;
                        sub: string;
                        'https://api.aquicore.com/email': string;
                    } = JSON.parse(this.$window.atob(p));
                    this.initSessionTimer(payload.exp);
                } catch (e) { }
                return token;
            });
        }

        public reAuthenticate(): ng.IPromise<any> {
            return this.$http.post(`${this.$window.location.origin}/api/v3/identity/logout`, {})
                .then(() => {
                    return this.onReady(() => {
                        sessionStorage.clear();
                        localStorage.clear();
                        return this.client
                            .loginWithPopup({ ...this.clientMeta, display: 'page' })
                            .then(this.seedSession);
                    });
                });
        }

        public login(): ng.IPromise<void> {
            return this.onReady(() => {
                sessionStorage.clear();
                localStorage.clear();
                this.$http.post(`${this.$window.location.origin}/api/v3/identity/logout`, {});
                return this.client.loginWithPopup({ ...this.clientMeta, display: 'page' }).then(this.seedSession);
            });
        }

        public logout(url?: string): ng.IPromise<void> {
            return this.onReady(() => {
                sessionStorage.clear();
                localStorage.clear();
                if (url) {
                    localStorage.setItem('url', url);
                }
                this.$http.post(`${this.$window.location.origin}/api/v3/identity/logout`, {})
                    .then(() => {
                        this.client.logout({ returnTo: `${this.$window.origin}/login` });
                    });
            });
        }
    }

    angular
        .module('aq.services')
        .service('AuthorizationService', AuthorizationService);
}
