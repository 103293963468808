namespace aq.admin {
    export class AccountAdminBuildingsCtrl {

        /* @ngInject */
        constructor(
            public account,
            private primaryBuildings,
            private sharedBuildings,
            private Messages,
            private BuildingService: aq.services.BuildingService,
            private $mdDialog
        ) {}

        public removeSharedBuilding(building) {
            this.BuildingService.removeSharedBuilding(this.account.id, building.id)
                .then((account) => {
                    _.remove(this.sharedBuildings, sharedBuilding => sharedBuilding.id === building.id);
                    this.Messages.success('Removed shared building');
                })
                .catch((error) => {
                    this.Messages.error('Unable to remove shared building');
                });
        }

        public addBuilding() {
            return this.$mdDialog.show({
                controller: 'AddBuildingCtrl as vm',
                templateUrl: 'app/admin/accounts/accounts/details/addBuilding.html',
                clickOutsideToClose: true,
                locals: {
                    account: this.account,
                    sharedBuildings: this.sharedBuildings
                },
                parent: angular.element(document.body)
            });
        }
    }

    angular
        .module('aq.admin.accounts.accounts')
        .controller('AccountAdminBuildingsCtrl', AccountAdminBuildingsCtrl);
}
