namespace aq.admin.accounts.enrollment {
    export interface BuildingTasks {
        buildingId: number;
        buildingName: string;
        buildingPhase: string;
        taskMap: {
            [name: string]: aq.propertySettings.TaskEnrollmentResponse;
        };
        isSelected: boolean;
    }
    export class AdminEnrollmentDetails {
        public selectedTasks: string[];
        public phaseTasks: PhaseTasks[];
        public viewItems: BuildingTasks[];
        public filteredViewItems: BuildingTasks[];
        public taskStatusesView: any[];
        public selectedStatusValue: string;
        public searchText: string;
        public backStateData: any;
        public allBuildingsSelected: boolean;
        private allTasksSelected: boolean;

        private phases = [
            'Setup', 'Connect', 'Verify', 'Train', 'Complete', 'Not Specified'
        ];
        private statuses: StatusCount[];
        private statusesMap: { [description: string]: number; };

        /* ngInject */
        constructor(
            private account: { id: number; accountName: string; },
            private buildings: any[],
            private buildingsEnrollment: aq.propertySettings.BuildingEnrollment[],
            private RestangularV3: restangular.IService,
            private Messages: aq.services.Messages,
            private $mdDialog: ng.material.IDialogService,
            private AppStorageService: aq.services.AppStorageService,
            private $stateParams: any
        ) {
            if (this.$stateParams.buildingsEnrollment == null) {
                this.setReloadParentState();
            }
            this.viewItems = [];
            this.filteredViewItems = [];
            this.statusesMap = {};
            this.phaseTasks = this.getAllPhaseTasks();
            this.allBuildingsSelected = false;

            _.each(this.buildingsEnrollment, (buildingEnrollment: aq.propertySettings.BuildingEnrollment) => {
                this.transformForView(buildingEnrollment);
                const viewItem: BuildingTasks = {
                    buildingId: buildingEnrollment.building,
                    buildingName: _.find(this.buildings, (b) => b.id == buildingEnrollment.building).name,
                    buildingPhase: null,
                    taskMap: {},
                    isSelected: false
                };
                _.each(buildingEnrollment.phases, (phase: aq.propertySettings.BuildingEnrollmentPhase) => {
                    _.each(phase.tasks, (task: aq.propertySettings.TaskEnrollmentResponse) => {
                        viewItem.taskMap[task.name] = task;
                    });
                });

                if (buildingEnrollment.status == 'COMPLETE') {
                    // if completed, override current phase
                    buildingEnrollment.currentPhase = 'Complete';
                }
                viewItem.buildingPhase = buildingEnrollment.currentPhase;
                if (!this.statusesMap[buildingEnrollment.currentPhase]) {
                    this.statusesMap[buildingEnrollment.currentPhase] = 0;
                }
                this.statusesMap[buildingEnrollment.currentPhase]++;

                this.viewItems.push(viewItem);
            });
            this.selectedTasks = this.getStoredSelectedTasks();
            this.filteredViewItems = angular.copy(this.viewItems);

            this.taskStatusesView = _.map(this.phases, (phase: string) => {
                const count = this.statusesMap[phase] ? this.statusesMap[phase] : 0;
                return {
                    title: phase,
                    percent: this.viewItems.length > 0 ? Math.round(count * 100 / this.viewItems.length) : 0,
                    color: this.getColor(phase)
                };
            });
            this.allTasksSelected = false;
        }

        public toggleStatusOption(item: any) {
            if (this.selectedStatusValue == item.title) {
                this.selectedStatusValue = null;
            } else {
                this.selectedStatusValue = item.title;
            }
            this.searchBy(this.searchText);
        }
        public searchBy(text: string) {
            if (!text) {
                text = '';
            }
            text = text.toLowerCase();
            this.searchText = text;
            this.filteredViewItems = _.filter(this.viewItems, (item: BuildingTasks) => {
                return (!this.selectedStatusValue || item.buildingPhase == this.selectedStatusValue)
                    && (!this.searchText || item.buildingName.toLowerCase().indexOf(this.searchText) > -1);
            });
        }
        public onTaskCompleteChange(task: aq.propertySettings.TaskEnrollmentResponse, buildingId: number) {
            if (task.status != 'IGNORED') {
                if (!task.complete) {
                    task.completionDate = null;
                    task.status = 'INCOMPLETE';
                } else {
                    task.completionDate = moment().format('YYYY-MM-DD');
                    task.status = 'COMPLETE';
                }
            }
            const buildingEnrollment = _.find(this.buildingsEnrollment, (be: aq.propertySettings.BuildingEnrollment) => be.building == buildingId);
            _.each(buildingEnrollment.phases, (phase: aq.propertySettings.BuildingEnrollmentPhase) => {
                const taskToUpdate = _.find(phase.tasks, (t: aq.propertySettings.TaskEnrollmentResponse) => t.id == task.id);
                if (taskToUpdate) {
                    taskToUpdate.completionDate = task.completionDate;
                }
            });
            this.setReloadParentState();
            this.updateBuildingEnrollment(buildingEnrollment)
                .then((response) => {
                    this.Messages.success('Task updated successfully');
                })
                .catch(() => {
                    this.Messages.error('Error occurred while updating the task');
                });
        }
        public onTaskIgnoreChange(task: aq.propertySettings.TaskEnrollmentResponse, buildingId: number) {
            if (task.status != 'IGNORED') {
                task.status = 'IGNORED';
                task.complete = false;
            } else if (task.status === 'IGNORED' && task.completionDate) {
                task.status = 'COMPLETE';
                task.complete = true;
            } else if (task.status === 'IGNORED' && !task.completionDate) {
                task.status = 'INCOMPLETE';
                task.complete = false;
            }
            this.onTaskCompleteChange(task, buildingId);
        }
        public setupColumns() {
            this.$mdDialog.show({
                templateUrl: 'app/admin/accounts/enrollment/taskSelectionModal.html',
                controller: 'TaskSelectionModal as vm',
                clickOutsideToClose: false,
                locals: {
                    phaseTasks: this.phaseTasks,
                    selectedTasks: this.selectedTasks,
                    allTasksSelected: this.allTasksSelected
                }
            }).then((result) => {
                const {selectedTasks, allTasksSelected} = result;
                this.selectedTasks = selectedTasks;
                this.allTasksSelected = allTasksSelected;
                this.updateDisplayEnrollmentTasks(selectedTasks);
            });
        }

        public openBulkUpdate() {
            const filteredBuildings = this.filteredViewItems.filter((item) => { return item.isSelected; });
            const filteredBuildingEnrollment = this.filterBuildingEnrollment(filteredBuildings);
            const filteredTasks = this.filterTasks(filteredBuildings);

            this.$mdDialog.show({
                templateUrl: 'app/admin/accounts/enrollment/bulkUpdateModal.html',
                controller: 'BulkUpdateCtrl as vm',
                clickOutsideToClose: false,
                locals: {
                    phaseTasks: _.cloneDeep(this.phaseTasks),
                    selectedTasks: _.cloneDeep(filteredTasks),
                    selectedBuildings: _.cloneDeep(filteredBuildings),
                    buildingEnrollment: _.cloneDeep(filteredBuildingEnrollment),
                    allBuildings: this.filteredViewItems
                }
            })
            .then((result) => {
                const {selectedTasks, selectedBuildings, buildingEnrollment} = result;
                this.filteredViewItems = this.filteredViewItems.map((item) => {
                    return item.isSelected ? selectedBuildings.find(building => { return item.buildingId === building.buildingId; }) : item;
                });

                this.buildingsEnrollment = this.buildingsEnrollment.map(be => {
                    const returnElement = buildingEnrollment.find(building => { return building.building === be.building; });
                    return returnElement ? returnElement : be;
                });

                this.phaseTasks = this.getAllPhaseTasks();
                buildingEnrollment.forEach((be) => {
                    this.updateBuildingEnrollment(be);
                });
                this.selectedTasks = this.combineSelectedTasks(this.selectedTasks, selectedTasks);
                this.selectedTasks = this.removeUnreferencedTasks(this.selectedTasks);
                this.updateDisplayEnrollmentTasks(this.selectedTasks);
            });
        }

        public buildingsSelected() {
            let buildingSelected = false;
            this.filteredViewItems.forEach(viewItem => {
                if (!buildingSelected) {
                    if (viewItem.isSelected) {
                        buildingSelected = true;
                    }
                }
            });
            return buildingSelected;
        }

        public onAllBuildingsSelected() {
            this.filteredViewItems = this.filteredViewItems.map((item) => {
                if (!this.allBuildingsSelected) {
                    if (item.isSelected) {
                        item.isSelected = false;
                    }
                } else {
                    if (!item.isSelected) {
                        item.isSelected = true;
                    }
                }
                return item;
            });
        }

        private combineSelectedTasks(selectedTasks: string[], newTasks: string[]): string[] {
            const newSelectedTasks = newTasks.filter(task => {
                return !selectedTasks.some(t => { return t === task; });
            });
            return [...selectedTasks, ...newSelectedTasks];
        }

        private removeUnreferencedTasks(currentTasks: string[]): string[] {
            return currentTasks.filter(currentTask => {
                let isReferenced = false;
                this.filteredViewItems.forEach(item => {
                    if (item.taskMap[currentTask]) {
                        isReferenced = true;
                    }
                });
                return isReferenced;
            });
        }

        private updateDisplayEnrollmentTasks(displayTasks: string[]) {
            const storage = this.AppStorageService.getStorage();
            let tasks = storage.displayEnrollmentTasks;
            if (!tasks) {
                tasks = {};
            }
            tasks[this.account.id] = displayTasks;
            this.AppStorageService.setAttr('displayEnrollmentTasks', tasks);
        }

        private filterBuildingEnrollment(filteredBuildings: BuildingTasks[]): aq.propertySettings.BuildingEnrollment[] {
            return this.buildingsEnrollment.filter((be) => {
                let shouldInclude = false;
                filteredBuildings.forEach((fb) => {
                    if (be.building === fb.buildingId) {
                        shouldInclude = true;
                    }
                });
                return shouldInclude;
            });
        }

        private filterTasks(filteredBuildings: BuildingTasks[]): string[] {
            return this.selectedTasks.filter((task) => {
                return this.isCommonTask(task, filteredBuildings);
            });
        }

        private isCommonTask(task: string, filteredBuildings: BuildingTasks[]): boolean {
            let common = true;
            filteredBuildings.forEach((building) => {
                if (!building.taskMap[task]) {
                    common = false;
                }
            });
            return common;
        }

        private getStoredSelectedTasks() {
            const storage = this.AppStorageService.getStorage();
            const tasks = storage.displayEnrollmentTasks;
            if (!tasks || !tasks[this.account.id]) {
                return [];
            } else {
                return tasks[this.account.id];
            }
        }
        private getAllPhaseTasks() {
            const phaseTasks: PhaseTasks[] = [];
            _.each(this.buildingsEnrollment, (be: aq.propertySettings.BuildingEnrollment) => {
                _.each(be.phases, (phase: aq.propertySettings.BuildingEnrollmentPhase) => {
                    let phaseTasksItem = _.find(phaseTasks, (p: PhaseTasks) => p.phase == phase.name);
                    if (!phaseTasksItem) {
                        phaseTasksItem = {
                            phase: phase.name,
                            tasks: []
                        };
                        phaseTasks.push(phaseTasksItem);
                    }
                    _.each(phase.tasks, (task: aq.propertySettings.TaskEnrollmentResponse) => {
                        if (!_.find(phaseTasksItem.tasks, (t) => t.name == task.name)) {
                            phaseTasksItem.tasks.push({
                                name: task.name,
                                eventHook: task.eventHandler ? true : false
                            });
                        }
                    });
                });
            });
            return phaseTasks;
        }
        private getColor(phase: string) {
            if (phase == 'Complete') {
                return 'green';
            }
            if (phase == 'Not Specified') {
                return 'orange';
            }
            return 'blue';
        }
        private updateBuildingEnrollment(buildingEnrollment: aq.propertySettings.BuildingEnrollment): ng.IPromise<any> {
            const updateRequest = this.transformForRequest(buildingEnrollment);
            return this.RestangularV3
                .one('buildings', buildingEnrollment.building)
                .one('enrollment')
                .customPUT(updateRequest);
        }
        private transformForView(be: aq.propertySettings.BuildingEnrollment): aq.propertySettings.BuildingEnrollment {
            if (be) {
                _.each(be.phases, (phase: aq.propertySettings.BuildingEnrollmentPhase) => {
                    phase.tasks = _.sortBy(phase.tasks, 'sortOrder');
                    _.each(phase.tasks, (task: aq.propertySettings.TaskEnrollmentResponse) => {
                        task.complete = (task.completionDate ? true : false);
                    });
                });
            }
            return be;
        }
        private transformForRequest(be: aq.propertySettings.BuildingEnrollment): aq.propertySettings.BuildingEnrollmentUpdateRequest {
            let request: aq.propertySettings.BuildingEnrollmentUpdateRequest = null;
            if (be) {
                request = {
                    project: be.project,
                    phases: _.map(be.phases, (phase: aq.propertySettings.BuildingEnrollmentPhase) => {
                        const phaseUpdate: aq.propertySettings.BuildingEnrollmentPhaseUpdateRequest = {
                            phase: phase.phase,
                            tasks: _.map(phase.tasks, (task: aq.propertySettings.TaskEnrollmentResponse) => {
                                const taskUpdate: aq.propertySettings.EnrollmentPhaseTaskUpdateRequest = {
                                    id: task.id,
                                    completionDate: task.completionDate,
                                    sortOrder: task.sortOrder,
                                    status: task.status
                                };
                                return taskUpdate;
                            })
                        };
                        return phaseUpdate;
                    })
                };
            }
            return request;
        }
        private setReloadParentState() {
            this.backStateData = {
                reload: moment().valueOf()
            };
        }
        private getEnrollmentCsv() {
            let csvContent = 'data:text/csv;charset=utf-8,';
            let fileName = '';
            csvContent += `,${this.selectedTasks}\n`;

            this.filteredViewItems.forEach(viewItem => {
                const taskArray = [];
                taskArray.push(viewItem.buildingName);
                this.selectedTasks.forEach(task => {
                    if (viewItem.taskMap && viewItem.taskMap[task]) {
                        taskArray.push(viewItem.taskMap[task].complete ? 'Done' : '');
                    } else {
                        taskArray.push('n/a');
                    }
                });
                csvContent += `${taskArray}\n`;
            });

            fileName = `Enrollment Report - ${this.account.accountName} - ${moment().format('YYYY MM DD')}.csv`;
            const link = document.createElement('a');
            link.setAttribute('href', encodeURI(csvContent));
            link.setAttribute('download', fileName);
            link.click();
        }

    }
    angular.module('aq.admin.accounts.enrollment').controller('AdminEnrollmentDetails', AdminEnrollmentDetails);
}
