var aq;
(function (aq) {
    var propertySettings;
    (function (propertySettings) {
        var AlertTimeFrameComponentCtrl = /** @class */ (function (_super) {
            __extends(AlertTimeFrameComponentCtrl, _super);
            /* @ngInject */
            function AlertTimeFrameComponentCtrl() {
                var _this = _super.call(this) || this;
                _this.timeFrames = [{
                        label: 'Building Schedule',
                        value: 'CALENDAR'
                    }, {
                        label: 'Anytime',
                        value: 'ANYTIME'
                    }, {
                        label: 'Custom',
                        value: 'CUSTOM'
                    }];
                _this.timeOptions = [];
                for (var i = 0; i < 48; i++) {
                    _this.timeOptions.push(moment().startOf('day').add(i * 30, 'minutes').format('h:mm A'));
                }
                _this.daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
                _this.updateCalendarRules();
                return _this;
            }
            AlertTimeFrameComponentCtrl.prototype.onAlertChange = function () {
                if (this.onChange) {
                    this.onChange({
                        $event: {
                            alert: this.alert
                        }
                    });
                }
            };
            AlertTimeFrameComponentCtrl.prototype.updateCalendarRules = function () {
                if (!this.alert) {
                    return;
                }
                if (this.alert.whenCondition == propertySettings.WhenCondition.CALENDAR) {
                    var calendar = _.find(this.calendars, { id: this.building.calendar });
                    if (calendar) {
                        this.alert.calendar = calendar.id;
                        this.categoryEvents = _.uniqBy(calendar.rules.map(function (i) { return i.category; }), 'id');
                    }
                    this.alert.customRule = null;
                }
                else if (this.alert.whenCondition == propertySettings.WhenCondition.ANYTIME) {
                    this.alert.customRule = null;
                    this.alert.calendar = null;
                    this.alert.category = null;
                }
                else if (this.alert.whenCondition == propertySettings.WhenCondition.CUSTOM) {
                    this.alert.calendar = null;
                    this.alert.category = null;
                }
            };
            return AlertTimeFrameComponentCtrl;
        }(propertySettings.AbstractAlertComponent));
        propertySettings.AlertTimeFrameComponentCtrl = AlertTimeFrameComponentCtrl;
        angular.module('properties')
            .component('alertTimeFrame', {
            templateUrl: 'app/properties/alerts/directives/alertTimeFrame.html',
            bindings: {
                building: '<',
                form: '=',
                alert: '<',
                calendars: '<',
                onChange: '&',
                isReadonly: '<?'
            },
            controller: AlertTimeFrameComponentCtrl
        });
    })(propertySettings = aq.propertySettings || (aq.propertySettings = {}));
})(aq || (aq = {}));
