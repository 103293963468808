var aq;
(function (aq) {
    var tenantbilling;
    (function (tenantbilling) {
        var AddEditLeaseCtrl = /** @class */ (function (_super) {
            __extends(AddEditLeaseCtrl, _super);
            /* @ngInject */
            function AddEditLeaseCtrl($mdDialog, RestangularV3, Errors, Messages, accountId, buildingId, tenantId, leaseTypes, TenantServiceHelper, areaMeasurement, lease, Auth) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.RestangularV3 = RestangularV3;
                _this.Errors = Errors;
                _this.Messages = Messages;
                _this.accountId = accountId;
                _this.buildingId = buildingId;
                _this.tenantId = tenantId;
                _this.leaseTypes = leaseTypes;
                _this.TenantServiceHelper = TenantServiceHelper;
                _this.areaMeasurement = areaMeasurement;
                _this.lease = lease;
                _this.Auth = Auth;
                _this.allLeases = [];
                _this.residents = [];
                _this.invalidFields = false;
                _this.residentsToDelete = [];
                var primaryResident = {};
                _this.residents.push(primaryResident);
                if (!_this.lease) {
                    _this.lease = {
                        tenant: _this.tenantId,
                        active: true
                    };
                }
                else {
                    _this.lease.startDate = _this.lease.startDate != null ? _this.convertToDate(_this.lease.startDate) : null;
                    _this.lease.endDate = _this.lease.endDate != null ? _this.convertToDate(_this.lease.endDate) : null;
                    if (!_.isEmpty(_this.lease.residents)) {
                        _this.residents = _this.lease.residents;
                    }
                }
                _this.TenantServiceHelper.getLeases(_this.tenantId).then(function (leases) {
                    _this.allLeases = leases;
                });
                return _this;
            }
            AddEditLeaseCtrl.prototype.addAnotherResident = function (residents) {
                var resident = {};
                residents.push(resident);
            };
            AddEditLeaseCtrl.prototype.deleteResident = function (resident, index, residents, residentsToDelete) {
                residents.splice(index, 1);
                if (resident.id) {
                    residentsToDelete.push(resident);
                }
            };
            AddEditLeaseCtrl.prototype.cancel = function (data) {
                this.$mdDialog.cancel();
            };
            AddEditLeaseCtrl.prototype.hide = function () {
                this.$mdDialog.hide(this.RestangularV3.copy(this.lease));
            };
            AddEditLeaseCtrl.prototype.convertToDate = function (dateString) {
                var parts = dateString.split('-');
                // JavaScript counts months from 0 to 11.
                return new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]));
            };
            AddEditLeaseCtrl.prototype.dateOverlap = function (leaseA, leaseB) {
                var startDateA = new Date(leaseA.startDate).getTime();
                var startDateB = new Date(leaseB.startDate).getTime();
                var endDateA;
                var endDateB;
                if (!leaseA.endDate && !leaseB.endDate) { // both have no end dates
                    return leaseA.starDate === leaseB.startDate;
                }
                if (!leaseA.endDate) { // new/modified lease had no end date
                    endDateB = new Date(leaseB.endDate).getTime();
                    if ((startDateA < startDateB) || (startDateA >= endDateB)) {
                        return false;
                    }
                }
                else if (!leaseB.endDate) { // existing lease had no end date
                    endDateA = new Date(leaseA.endDate).getTime();
                    if ((startDateA < endDateA) && ((endDateA <= startDateB) || (startDateA > startDateB))) {
                        return false;
                    }
                }
                endDateA = new Date(leaseA.endDate).getTime();
                endDateB = new Date(leaseB.endDate).getTime();
                if ((startDateA < endDateA) && ((endDateA <= startDateB) || (startDateA >= endDateB))) {
                    return false;
                }
                return true;
            };
            AddEditLeaseCtrl.prototype.updateCreate = function () {
                var _this = this;
                if (this.lease.id) {
                    this.TenantServiceHelper.deleteResidents(this.residentsToDelete);
                    delete this.lease.residents;
                    delete this.lease.residentsNames;
                    return this.TenantServiceHelper.updateLease(this.lease)
                        .then(function () {
                        _this.residents.forEach(function (resident) {
                            if (!resident.id && resident.name && resident.email) {
                                resident.lease = _this.lease.id;
                                _this.TenantServiceHelper.createResident(resident);
                            }
                            else if (resident.id) {
                                _this.TenantServiceHelper.updateResident(resident);
                            }
                        });
                        _this.hide();
                    });
                }
                return this.TenantServiceHelper.createLease(this.lease)
                    .then(function (leaseResult) {
                    _.extend(_this.lease, leaseResult);
                    _this.residents.forEach(function (resident) {
                        if (resident.name && resident.email) {
                            resident.lease = _this.lease.id;
                            _this.TenantServiceHelper.createResident(resident);
                        }
                    });
                    _this.hide();
                });
            };
            AddEditLeaseCtrl.prototype.save = function () {
                var _this = this;
                var overlap = false;
                this.allLeases.forEach(function (leaseItem) {
                    if (_this.lease.id && (_this.lease.id !== leaseItem.id)) { // skip date validation on itself
                        overlap = _this.dateOverlap(_this.lease, leaseItem);
                    }
                    else if (!_this.lease.id) {
                        overlap = _this.dateOverlap(_this.lease, leaseItem);
                    }
                });
                var endDate = this.lease.endDate;
                if (overlap || (endDate && !(this.lease.startDate < endDate))) {
                    return this.Messages
                        .error('The start or end date overlaps with another lease. Please adjust dates');
                }
                return this.updateCreate();
            };
            AddEditLeaseCtrl.prototype.validateFields = function (resident) {
                var hasEmail = (resident.email != null && resident.email != "") ? true : false;
                var hasName = (resident.name != null && resident.name != "") ? true : false;
                this.invalidFields = true;
                if (!hasEmail && hasName) {
                    this.missingField = "Email";
                    return true;
                }
                else if (hasEmail && !hasName) {
                    this.missingField = "Name";
                    return true;
                }
                this.invalidFields = false;
                return false;
            };
            return AddEditLeaseCtrl;
        }(aq.common.Controllers.ModalCtrl));
        tenantbilling.AddEditLeaseCtrl = AddEditLeaseCtrl;
        angular.module('tenantBilling').controller('AddEditLeaseCtrl', AddEditLeaseCtrl);
    })(tenantbilling = aq.tenantbilling || (aq.tenantbilling = {}));
})(aq || (aq = {}));
