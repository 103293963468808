angular
    .module('aq.admin.notifications', [])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.admin.notifications', {
                url: '/notifications',
                templateUrl: 'app/admin/notifications/main.html',
                controller: 'AdminNotificationsCtrl',
                data: {
                    appName: 'Notifications'
                }
            });
    });
