'use strict';

angular.module('properties').controller('SpacesCtrl',
  function ($scope, Auth, $state, $filter, Restangular, loading, Messages, Errors, building, authAccess) {

    $scope.canEditProperties = false;
    $scope.propertySettingsAccess = authAccess['Property Settings'];
    $scope.canEditProperties = $scope.propertySettingsAccess.EDIT;

    loading.start();

    $scope.noFloor = {};
    $scope.floorsShown = [];
    $scope.floorSelected = $scope.noFloor;

    $scope.noSpace = {};
    $scope.commonArea = $scope.noSpace;
    $scope.spacesShown = [];
    $scope.spaceSelected = $scope.noSpace;
    $scope.spaceEdited = $scope.noSpace;
    $scope.areaUnit = '';

    $scope.componentsShown = [];

    $scope.$watch('user', function() {

        if (!$scope.user) return;

        $scope.canEditProperties = Auth.check({access: 'EDIT'});
    })

    $scope.$watch('building', function() {

        if (!$scope.building || !$scope.building.id) return;

        $scope.floorSelected = $scope.noFloor;
        $scope.floorsShown = [];

        $scope.spaceSelected = $scope.noSpace;
        $scope.spacesShown = [];
        $scope.componentsShown = [];

        Restangular.one('accounts', building.account).one('buildings', building.id).getList('spaces').then(function(spaces) {
            $scope.spaces = spaces;
            $scope.commonArea = _.find(spaces, {commonArea: true});
            $scope.spaceSelected = $scope.commonArea;
        }).finally(loading.stop);

        Restangular.one('accounts', building.account).one('buildings', building.id).getList('floors').then(function(floors) {
            $scope.floorsShown = floors;
        });

    });

    $scope.selectFloor = function(floor) {
        $scope.floorSelected = floor;
    };

    $scope.$watch('floorSelected', function() {

        if (!$scope.floorSelected) return;

        $scope.spaceSelected = $scope.noSpace;
        $scope.componentsShown = [];

        if (!$scope.floorSelected.spaces || $scope.floorSelected.spaces.length === 0) {
            $scope.spacesShown = [];
        } else {
            $scope.spacesShown = $filter('filterByIds')($scope.spaces, $scope.floorSelected.spaces);
            _.each($scope.spacesShown, function (space) {
                if (!space.calendar) {
                    space.calendar = $scope.building.calendar;
                }
            });
        }
    });

    $scope.addFloor = function() {
        var newFloor = {
            building: $scope.building.id,
            name: 'Floor ' + $scope.floorsShown.length,
            spaces: [],
            orderIndex: calculateLastIndex($scope.floorsShown)
        };

        Restangular.one('accounts', building.account).one('buildings', building.id).post('floors', newFloor).then(function(result) {
            newFloor = _.extend(result, result.model);
            $scope.floorsShown.push(newFloor);
            $scope.floorSelected = newFloor;
        });
    }

    $scope.isFloorEmpty = function() {
        return $scope.floorSelected != $scope.noFloor
            && $scope.spaceSelected != $scope.commonArea
            && $scope.spacesShown.length == 0;
    }

    $scope.editFloor = function(updatedFloor) {
        if (updatedFloor.name.trim().length === 0) {
            Messages.warn('Please enter floor name!');
            return;
        }

        return updatedFloor.put();
    }

    $scope.deleteFloor = function() {

        $scope.floorSelected.remove().then(function() {
            _.withoutInline($scope.floorsShown, $scope.floorSelected);
            $scope.floorSelected = $scope.noFloor;
            $scope.spaceSelected = $scope.noSpace;
        });
    }

    $scope.selectSpace = function(space) {

        $scope.spaceSelected = space;
    };

    $scope.selectCommonArea = function() {

        $scope.spaceSelected = $scope.commonArea;
    };

    $scope.$watch('spaceSelected', function() {

        if (!$scope.spaceSelected || $scope.spaceSelected === $scope.noSpace) return;

        $scope.componentsShown = [];

        $scope.spaceEdited = $scope.spaceSelected;
        $scope.spaceEdited.buildingPurpose = $scope.spaceEdited.buildingPurpose ? $scope.spaceEdited.buildingPurpose : null;

        $scope.spaceSelected.getList('components').then(function(components) {
            $scope.componentsShown = _.filter(components, function(c) {
                return !c.type || (c.type.name != 'Virtual' && c.type.name != 'Master Electric');
            });
        });

    });

    $scope.addSpace = function() {

        if ($scope.floorSelected == $scope.noFloor) {
            Messages.warn('Please select floor first!');
            return;
        }

        var newSpace: any = {
            building: $scope.building.id,
            calendar: $scope.building.calendar,
            commonArea: false,
            components: [],
            floor: $scope.floorSelected.id,
            name: 'New Space',
            orderIndex: calculateLastIndex($scope.spacesShown),
            propertyType: 'SPACE',
            buildingPurpose: $scope.building.buildingPurpose,
            size: 1
        };

        Restangular.one('accounts', building.account).one('buildings', building.id).all('spaces').post(newSpace)
            .then(function(result) {
                newSpace = _.extend(result, result.model);
                $scope.spaces.push(newSpace);
                $scope.spacesShown.push(newSpace);
                $scope.floorSelected.spaces.push(newSpace.id);
                $scope.spaceSelected = newSpace;
            });
    }

    $scope.editSpace = function(updatedSpace, idx) {
        if (updatedSpace.name.trim().length === 0) {
            Messages.warn('Please enter space name!');
            return;
        }

        var index = idx !== undefined ? idx : _.indexOf($scope.spacesShown, $scope.spaceSelected);
        updatedSpace = angular.copy(updatedSpace);

        $scope.spacesShown[index] = $scope.spaceSelected = angular.copy(updatedSpace);

        return updateSpace(updatedSpace);
    }

    $scope.isSpaceEmpty = function() {
        return $scope.spaceSelected !== $scope.noSpace
            && $scope.spaceSelected.components
            && $scope.spaceSelected.components.length == 0;
    }

    $scope.isCommonAreaSelected = function() {
        return $scope.spaceSelected == $scope.commonArea;
    }

    $scope.deleteSpace = function() {
        var spaceToDelete = $scope.spaceSelected,
            idxToDelete = _.indexOf($scope.spacesShown, spaceToDelete);
            Restangular.one('accounts', building.account).one('buildings', building.id).one('spaces', spaceToDelete.id).remove().then(function() {
            $scope.commonArea.components = $scope.commonArea.components.concat(spaceToDelete.components);
            $scope.floorSelected.spaces.splice(idxToDelete, 1);
            $scope.spacesShown.splice(idxToDelete, 1);
            $scope.spaceSelected = $scope.noSpace;
        }).catch(Errors.forPromise());
    }

    $scope.reorderFloor = function($event) {

        var floorMoved = $scope.floorsShown[$event.from],
            newIndex = calculateNewIndex($scope.floorsShown, $event.from, $event.to);

        floorMoved.orderIndex = newIndex;

        floorMoved.spaces = _.map(floorMoved.spaces, function(space) {
            return space.id ? space.id : space;
        });

        floorMoved.put();
    }

    $scope.reorderSpace = function($event) {

        var spaceMoved = $scope.spacesShown[$event.from],
            newIndex = calculateNewIndex($scope.spacesShown, $event.from, $event.to);

        if (!spaceMoved) return; // workaround for sortable called on drag-drop

        spaceMoved.orderIndex = newIndex;
        updateSpace(spaceMoved);
    }

    $scope.droppedOnFloor = function($event) {
        var draggedSpace = $scope.spacesShown[$event.draggedIndex],
            droppedOnFloor = $scope.floorsShown[$event.droppedOnIndex];

        if ($scope.floorSelected == $scope.floorsShown[$event.droppedOnIndex]) return;

        moveSpaceToFloor(draggedSpace, $scope.floorSelected, droppedOnFloor);
        $scope.spacesShown.splice($event.draggedIndex, 1);
    }

    $scope.droppedOnSpace = function($event) {
        var draggedComponent = $scope.componentsShown[$event.draggedIndex],
            droppedOnSpace = $scope.spacesShown[$event.droppedOnIndex];

        if ($scope.spaceSelected == $scope.spacesShown[$event.droppedOnIndex]) return;
        moveComponentToSpace(draggedComponent, $scope.spaceSelected, droppedOnSpace);
        $scope.componentsShown.splice($event.draggedIndex, 1);
    }

    $scope.droppedOnCommonArea = function($event) {
        var draggedComponent = $scope.componentsShown[$event.draggedIndex];

        if ($scope.spaceSelected == $scope.commonArea) return;

        moveComponentToSpace(draggedComponent, $scope.spaceSelected, $scope.commonArea);
        $scope.componentsShown.splice($event.draggedIndex, 1);
    }


    var moveComponentToSpace = function(component, fromSpace, toSpace) {
        var componentIdx = _.indexOf(fromSpace.components, component.id);

        fromSpace.components.splice(componentIdx, 1);
        toSpace.components.push(component.id);

        component.space = toSpace.id;
        component.building = toSpace.building;

        return component.put();
    }

    var moveSpaceToFloor = function(space, fromFloor, toFloor) {

        var spaceIdx = _.indexOf(fromFloor.spaces, space.id);

        fromFloor.spaces.splice(spaceIdx, 1);
        toFloor.spaces.push(space.id);

        space.floor = toFloor.id;
        return updateSpace(space);
    }

    var updateSpace = function(space) {
        space.components = _.map(space.components, function(component) {
            return component.id ? component.id : component;
        });
        return space.put();
    }

    var calculateNewIndex = function(list, from, to) {

        if (to == 0) return list[to].orderIndex / 2;
        if (to + 1 == list.length) return list[to].orderIndex + 1000;

        if (from > to) to--;

        var before = list[to].orderIndex,
          after = list[to + 1].orderIndex;

        return (after + before) / 2;
    }

    var calculateLastIndex = function(list) {
        var lastItem = list[list.length - 1];
        return list.length ? lastItem.orderIndex + 1000 : 1000;
    }

  });
