namespace aq.services {
    export class BuildingService {
        /* @ngInject */
        constructor(
            private DataStore: aq.common.DataStore,
            private RestangularV3: restangular.IService
        ) { }

        public updateBuilding(building): ng.IPromise<any> {
            if (building.zipCode) {
                building.zipCode = building.zipCode.trim();
            }
            const buildingCopy: any = this.RestangularV3.copy(building);
            return this.DataStore.update(buildingCopy);
        }

        public archiveBuilding(building): restangular.IPromise<any> {
            return this.RestangularV3.one('buildings', building.id).customPUT(null, 'archive');
        }

        public updateOccupancy(buildingOccupancy): ng.IPromise<any> {
            const occupancyCopy: any = this.RestangularV3.copy(buildingOccupancy);
            return this.DataStore.update(occupancyCopy);
        }

        public deleteOccupancy(buildingOccupancy): ng.IPromise<any> {
            return this.DataStore.delete(buildingOccupancy);
        }

        public createOccupancy(buildingOccupancy): ng.IPromise<any> {
            return this.DataStore.create(this.RestangularV3.one('building', buildingOccupancy.building).all('buildingOccupancy'), buildingOccupancy);
        }

        public updateOptimalStart(optimalstart: aq.propertySettings.OptimalStart): restangular.IPromise<any> {
            return this.RestangularV3.one('buildings', optimalstart.building).one('optimal-start').customPUT(optimalstart);
        }

        public createOptimalStart(optimalstart: aq.propertySettings.OptimalStart): restangular.IPromise<any> {
            return this.RestangularV3.one('buildings', optimalstart.building).one('optimal-start').customPOST(optimalstart);
        }

        public findAddress(query): ng.IPromise<any> {
            const timeZone = this.getTimeZone();
            return this.RestangularV3.one('buildings').customPOST({ timeZone, query }, 'addresses').then((response) => {
                return response.results;
            });
        }

        getTimeZone(): string {
            return moment.tz.guess();
        }

        public shareBuilding(buildingId, selectedAccountsArray): ng.IPromise<any> {
            return this.RestangularV3.one('buildings', buildingId).customPUT(null, 'share-with-account', { accountId: selectedAccountsArray })
                .then((response) => {
                    return response;
                });
        }

        public shareBuildings(accountId, selectedBuildingsArray): ng.IPromise<any> {
            return this.RestangularV3.one('accounts', accountId).customPUT(null, 'share-buildings', { buildingId: selectedBuildingsArray })
                .then((response) => {
                    return response;
                });
        }

        public removeSharedBuilding(accountId, buildingId): ng.IPromise<any> {
            return this.RestangularV3.one('accounts', accountId).customPUT(null, 'remove-shared-building', { buildingId })
                .then((response) => {
                    return response;
                });
        }
    }

    angular
        .module('aq.services')
        .service('BuildingService', BuildingService);
}
