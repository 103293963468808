'use strict';

angular
    .module('aq.admin.meters.copydata', ['restangular', 'ui.router', 'aq.messages'])
    .config((RestangularProvider, $stateProvider) => {
        $stateProvider
            .state('aq.admin.meters.copydata', {
                url: '/copydata',
                templateUrl: 'app/admin/meters/copyData/main.html',
                controller: 'AdminMeterCopyDataCtrl',
                resolve: {
                    accounts: (Restangular, waitForAuthToken) => {
                        return Restangular.all('accounts').get('queryAllAccounts');
                    }
                },
                data: {
                    breadcrumb: 'Copy Data',
                    icon: '.aq-icons-copy-data'
                }
            });
    });
