'use strict';

angular
    .module('aq.admin.meters.weather', ['restangular', 'ui.router', 'aq.messages'])
    .config((RestangularProvider, $stateProvider) => {
        $stateProvider
            .state('aq.admin.meters.weather', {
                url: '/weather',
                templateUrl: 'app/admin/meters/weather/main.html',
                controller: 'AdminMeterWeatherCtrl',
                data: {
                    breadcrumb: 'Weather',
                    icon: '.aq-icons-property-settings'
                },
                resolve: {
                    accounts: (Restangular, waitForAuthToken) => {
                        return Restangular.all('accounts').get('queryAllAccounts');
                    }
                }
            });
    });
