namespace aq.services {

    import OptimalResponse = aq.models.optimalStart.OptimalResponse;

    export class OptimalStartService {

        /* @ngInject */
        constructor(
            private $http: ng.IHttpService,
            private resolver: aq.services.Resolver,
            private Messages: aq.services.Messages,
            private AQ_API_URL: string,
            private AuthToken: string
        ) {
        }

        public getOptimalStartById(id: string): Promise<OptimalResponse> {
            try {
                return this.resolver(async () => {
                    const response = await this.$http({
                        method: 'GET',
                        headers: this.getHeaders(),
                        url: `${this.AQ_API_URL}optimalstart/v1/optimal/${id}`
                    });
                    return response.data;
                });
            } catch (error) {
                throw error;
            }
        }

        private getHeaders(): {[key: string]: any} {
            return {
                'Content-Type': 'application/json',
                'Authorization': this.AuthToken
            };
        }
    }

angular
    .module('aq.services')
    .service('OptimalStartService', OptimalStartService);
}
