namespace aq.utilityBudgets {

    enum Step {
        DOWNLOAD_PLANNER = 0,
        IMPORT_FROM_PLANNER = 1,
        REVIEW = 2,
        DONE = 3
    }

    interface ViewImportDataRow {
        month: string;
        electricity: number;
        water: number;
        gas: number;
        steam: number;
    }

    type ViewImportData = ViewImportDataRow[];

    export class BudgetPlanningWizardCtrl extends aq.common.Controllers.ModalCtrl {
        private currentStep: number;
        private isDownloading: boolean;
        private isSubmitting: boolean;
        private importData: string;
        private viewImportData: ViewImportData;
        private viewTotals: ViewImportDataRow;
        private budgetExistsWarnings: string[];

        /* ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private $mdStepper,
            private $timeout,
            private accountId: number,
            private buildingId: number,
            private BudgetServiceV3: aq.services.BudgetServiceV3,
            private Messages: aq.services.Messages,
            private FileHandlerService: aq.services.FileHandlerService
        ) {
            super({}, $mdDialog);
            this.init();
        }

        public init() {
            this.currentStep = 0;
            this.isDownloading = false;
            this.isSubmitting = false;
            this.importData = null;
            this.viewImportData = null;
            this.budgetExistsWarnings = [];
        }

        public downloadBudgetPlanner() {
            this.isDownloading = true;
            return this.BudgetServiceV3.getBudgetPlannerTemplate(this.buildingId)
                .then((response) => {
                    this.$timeout(() => {
                        this.FileHandlerService.handleFileDownload(response, 'application/vnd.ms-excel');
                        this.isDownloading = false;
                    }, 0);
                })
                .catch(() => {
                    this.Messages.error('Error while downloading budget planner');
                    this.isDownloading = false;
                });
        }

        public create() {
            return this.BudgetServiceV3.importBudgetData(this.accountId, this.buildingId, this.importData);
        }

        public verifyImport() {
            return this.BudgetServiceV3.verifyBudgetDataImport(this.accountId, this.buildingId, this.importData);
        }

        public next() {
            if (this.currentStep == Step.IMPORT_FROM_PLANNER) {
                this.isSubmitting = true;
                this.verifyImport()
                    .then((result) => {
                        this.$timeout(() => {
                            this.setViewImportData(result);
                            this.$mdStepper('budget-planning-wizard').next();
                            this.currentStep++;
                        }, 0);

                    })
                    .catch((responseresponse) => {
                        const message = 'We don\'t recognize this format. Please be sure to copy the yellow highlighted cells on Step 4 of the Budget Workbook';
                        this.Messages.error(message);
                        this.importData = null;
                    })
                    .finally(() => {
                        this.isSubmitting = false;
                    });

            } else if (this.currentStep == Step.REVIEW) {
                this.isSubmitting = true;
                this.create()
                    .then(() => {
                        this.$timeout(() => {
                            this.$mdStepper('budget-planning-wizard').next();
                            this.currentStep++;
                        }, 0);
                    })
                    .catch((response) => {
                        const message = 'Error while importing budget data.';
                        this.Messages.error(message);
                    })
                    .finally(() => {
                        this.isSubmitting = false;
                    });
            } else if (this.currentStep == Step.DONE) {
                this.done();
            } else {
                this.$mdStepper('budget-planning-wizard').next();
                this.currentStep++;
            }
        }

        public setViewImportData(data) {
            this.viewImportData = [];
            const sortedData = _.sortBy(data.workbookItems, (item) => item.date)
            this.viewTotals = {
                month: 'Total',
                electricity: 0,
                water: 0,
                gas: 0,
                steam: 0
            };
            _.each(sortedData, (item) => {
                const viewDataRow: ViewImportDataRow = {
                    month: moment(item.date).format('MMM YYYY'),
                    electricity: item.electricity,
                    water: item.water,
                    gas: item.gas,
                    steam: item.steam
                };

                this.viewTotals.electricity += viewDataRow.electricity || 0;
                this.viewTotals.water += viewDataRow.water || 0;
                this.viewTotals.gas += viewDataRow.gas || 0;
                this.viewTotals.steam += viewDataRow.steam || 0;

                this.viewImportData.push(viewDataRow);
            });

            if (this.viewTotals.electricity > 0 && data.electricBudgetExists) {
                this.budgetExistsWarnings.push('You already have a budget for Electricity. Completing this step will update that budget.');
            }

            if (this.viewTotals.water > 0 && data.waterBudgetExists) {
                this.budgetExistsWarnings.push('You already have a budget for Water. Completing this step will update that budget.');
            }

            if (this.viewTotals.gas > 0 && data.gasBudgetExists) {
                this.budgetExistsWarnings.push('You already have a budget for Gas. Completing this step will update that budget.');

            }

            if (this.viewTotals.steam > 0 && data.steamBudgetExists) {
                this.budgetExistsWarnings.push('You already have a budget for Steam. Completing this step will update that budget.');
            }
        }

        public previous() {
            this.$mdStepper('budget-planning-wizard').back();
            this.currentStep = Math.max(0, this.currentStep - 1);
        }

        public done() {
            this.hide();
        }

        public confirmCancel(event) {
            const confirm = this.$mdDialog.confirm()
                .title('Do you want to exit wizard? Your progress will be lost.')
                .ariaLabel('Exit Wizard')
                .targetEvent(event)
                .ok('Exit')
                .cancel('Stay');
            (confirm as any).multiple(true);
            this.$mdDialog.show(confirm).then(() => {
                this.cancel();
            });
        }

        public isShowBack() {
            return this.currentStep > Step.DOWNLOAD_PLANNER && this.currentStep < Step.DONE;
        }

        public isDisableNext() {
            if (this.isSubmitting) {
                return true;
            }
            switch (this.currentStep) {
                case Step.IMPORT_FROM_PLANNER:
                    return !this.importData;
                default:
                    return false;
            }
        }

        public getNextButtonTitle() {
            switch (this.currentStep) {
                case Step.REVIEW:
                    return 'Create';
                case Step.DONE:
                    return 'Done';
                default:
                    return 'Next';
            }
        }
    }

    angular.module('aq.utilityBudgets').controller('BudgetPlanningWizardCtrl', BudgetPlanningWizardCtrl);
}
