'use strict';

angular
    .module('aq.admin.reports.summaryreports', [])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.admin.reports.summaryreports', {
                url: '/summaryreports',
                templateUrl: 'app/admin/reports/summaryreports/main.html',
                controller: 'SummaryReportsCtrl',
                data: {
                    breadcrumb: 'Summary Reports',
                    icon: '.aq-icons-reports'
                }
            });
    });
