angular
.module('aq.settings.calendars')
.controller('SettingsRuleMainCtrl',
    function ($scope, $state, $stateParams, CalendarData, Restangular, EventPriority, Messages, Sortable, authAccess) {

        $scope.accountSettingsAccess = authAccess['Account Settings'];

        $scope.showFormLabel = 'Show Form';

        $scope.$watch('rule', function (rule) {
            if (!rule) return;
            $state.transitionTo('aq.settings.calendars.rules.views.form', {
                accountId: $scope.account.id,
                calendarId: $scope.calendar.id,
                ruleId: $scope.rule.id
            });
        });

        $scope.$watch('workCalendars', function () {
            if (!$scope.workCalendars) return;
            $scope.calendar = (_ as any).findById($scope.workCalendars, $stateParams.calendarId);
            $state.get('aq.settings.calendars.rules').data.breadcrumb = $scope.calendar.name;
            $scope.account
                .one('workCalendars', $scope.calendar.id)
                .getList('rules')
                .then(function(workCalendarRules) {
                    $scope.workCalendarRules = workCalendarRules;
                }, function () {
                    Messages.error('Error while getting list of events');
                });
        });

        $scope.addRule = function () {
            $state.transitionTo('aq.settings.calendars.rules.views.form', { accountId: $scope.account.id , calendarId: $scope.calendar.id, ruleId: null});
        }

        $scope.reorderEvent = function ($event) {
            const ruleFrom = $scope.workCalendarRules[$event.from];

            ruleFrom.priority = Sortable.calculatePriority($scope.workCalendarRules, $event.from, $event.to);
            ruleFrom.put();
        };
    });
