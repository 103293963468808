angular.module('aq.admin.notifications').controller('AdminNotificationsCtrl',
    function($scope, Restangular, Errors, Messages, Pager) {

        Restangular.all('accounts').get('queryAllAccounts').then(function(accounts) {
            $scope.accounts = accounts;
            Restangular.one('users', 'me').get().then(function(user) {
                getNotificationsForAccount($scope.selectedAccount);
            });
        });

        $scope.isLoading = true;
        $scope.queryValue = null;

        $scope.queryNotifications = function(value) {
            $scope.selectedAccount = null;
            queryNotifications(value);
        }

        $scope.getNotificationsForAccount = function(account) {
            $scope.notifications = null;
            getNotificationsForAccount(account);
        }

        $scope.isTableVisible = function() {
            return _.size($scope.notifications) > 0 ? true : false;
        }

        $scope.isAccountNameVisible = function() {
            return $scope.selectedAccount != null ? false : true;
        }

        function getNotificationsForAccount(account) {
            $scope.isLoading = true;
            $scope.scroller = Pager.scroller(1, function(page) {
                if (account) {
                    if(account.id != account[0]) $scope.sources = null;
                    return Restangular.one('accounts', account.id).getList('queryNotificationsByAccountId', {page: page, inflate: 'account'});
                } else {
                    return Restangular.all('accounts').get('queryAllAbstractNotifications', {page: page, inflate: 'account'});
                }
            }, function(notifications) {
                if (!$scope.notifications) {
                    $scope.notifications = notifications;
                } else {
                    _.each(notifications, function(iteratee) {
                        $scope.notifications.push(iteratee);
                    });
                }
                $scope.isLoading = false;
            });
            $scope.scroller.nextPage();
        }

        function queryNotifications(value) {
            $scope.isLoading = true;
            $scope.notifications = null;
            $scope.scroller = Pager.scroller(1, function(page) {
                return Restangular.all('accounts').get('queryAbstractNotifications', {value: value, page: page, inflate: 'account'});
            }, function(notifications) {
                if (!$scope.notifications) {
                    $scope.notifications = notifications;
                } else {
                    _.each(notifications, function(iteratee) {
                        $scope.notifications.push(iteratee);
                    });
                }
                $scope.isLoading = false;
            }, function() {
                $scope.isLoading = false;
                Messages.error('Error occured while trying to fetch notifications.');
            });
            $scope.scroller.nextPage();
        }
    }
);