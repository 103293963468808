namespace aq.utilityBudgets {

    export class BudgetSettingsCtrl {
        public currentNavItem: string;
        public userSearchText: string;
        public selectedUser: UserItem;
        public userSearchTextAccounting: string;
        public selectedUserAccounting: UserItem;
        public userSearchTextApprovers: string;
        public selectedUserApprovers: UserItem;
        public monthDays: {
            value: number;
            label: string
        }[];
        public intervalOptions: {
            value: BudgetWorkflowScheduleFrequency;
            label: string;
        }[];
        public accrualReminderUsers: aq.user.User[];
        public accrualAccountingUsers: UserItem[];
        public accrualApproversUsers: UserItem[];

        /* ngInject */
        constructor (
            protected $mdDialog: ng.material.IDialogService,
            private users: aq.user.User[],
            private settings: BudgetSettingsModel,
            private RestangularV3: restangular.IService,
            private Messages: services.Messages,
            private Auth: aq.services.Auth,
        ) {
            this.currentNavItem = 'accruals';
            this.accrualReminderUsers = [];
            this.accrualAccountingUsers = [];
            this.accrualApproversUsers = [];
            this.buildMonthDays();
            this.initSelectedUsers();
            this.intervalOptions = [
                { value: BudgetWorkflowScheduleFrequency.MONTHLY, label: 'Month' },
                { value: BudgetWorkflowScheduleFrequency.QUARTERLY, label: 'Quarter' }
            ];
        }

        public getMonthLabel(value) {
            const dayItem = _.find(this.monthDays, (item) => item.value == value);
            return dayItem ? dayItem.label : '';
        }

        public getIntervalLabel(value) {
            const intervalItem = _.find(this.intervalOptions, (item) => item.value == value);
            return intervalItem ? intervalItem.label.toLowerCase() : '';
        }

        public getFormattedUsers(userItems: UserItem[] | aq.user.User[]) {
            if (!userItems || userItems.length == 0) {
                return '-';
            }
            const items: string[] = _.map(userItems, (user: UserItem | aq.user.User) => user.fullName);
            return this.formatUsers(items);
        }

        public formatUsers(users: string[]) {
            if (users.length > 1) {
                const last = users.pop();
                return `${users.join(', ')} and ${last}`;
            }
            return _.first(users);
        }

        public buildMonthDays() {
            this.monthDays = [];
            for (let i = 1; i <= 28; i++) {
                let suffix = 'th';
                if (i == 1 || i == 21) suffix = 'st';
                if (i == 2 || i == 22) suffix = 'nd';
                if (i == 3 || i == 23) suffix = 'rd';
                this.monthDays.push({
                    value: i,
                    label: i + suffix
                });
            }
        }

        public queryUsers(search: string, assignedUsers: UserItem) {
            const searchText = search.toLowerCase();
            const users = this.users.filter((user) => {
                return user.fullName.toLowerCase().indexOf(searchText) > -1
                    && _.findIndex(assignedUsers, { fullName: user.fullName }) === -1;
            });
            return users;
        }

        public isSelectedTab(name) {
            return this.currentNavItem == name;
        }

        public onAddCustomUserEmail(assignedUsers) {
            this.$mdDialog.show({
                controller: 'EmailModalCtrl as vm',
                templateUrl: 'app/common/components/modalInput/emailModal.html',
                parent: angular.element(document.body),
                clickOutsideToClose: false
            })
            .then((email) => {
                this.addUserEmailItem(assignedUsers, email);
                this.forceRefreshReceiversChips();
            });
        }

        public initSelectedUsers() {
            _.each(this.settings.reminderUsers, (userId) => {
                const user = _.find(this.users, (u) => u.id == userId);
                if (user) {
                    this.accrualReminderUsers.push(user);
                }
            });
            _.each(this.settings.accountantReceivers, (email) => this.addUserEmailItem(this.accrualAccountingUsers, email));
            _.each(this.settings.approverReceivers, (email) => this.addUserEmailItem(this.accrualApproversUsers, email));
        }

        public addUserEmailItem(assignedUsers, email) {
            if(!_.find(assignedUsers, (u) => u.email == email)) {
                const user = _.find(this.users, (u) => u.email == email);
                assignedUsers.push({
                    fullName: user ? user.fullName : email,
                    email
                });
            }
        }

        public save(context: BudgetSettingsCtrl) {
            context.settings.reminderUsers = _.map(context.accrualReminderUsers, (user: aq.user.User) => user.id);
            context.settings.accountantReceivers = _.map(context.accrualAccountingUsers, (user: UserItem) => user.email);
            context.settings.approverReceivers = _.map(context.accrualApproversUsers, (user: UserItem) => user.email);

            this.RestangularV3.one('buildings', this.settings.buildingId)
                .customPUT(this.settings, 'budget-settings')
                .then(() => {
                    this.Messages.success('Successfully saved settings');
                })
                .catch(() => {
                    this.Messages.error('Unable to save settings, please contact your Administrator');
                });
        }

        private forceRefreshReceiversChips() {
            this.accrualAccountingUsers = angular.copy(this.accrualAccountingUsers);
            this.accrualApproversUsers = angular.copy(this.accrualApproversUsers);
        }
    }
    angular.module('aq.utilityBudgets').controller('BudgetSettingsCtrl', BudgetSettingsCtrl);
}
