namespace aq.ui {

    class _ActivityOptimalStartDisplay {
        public noDataCallback: () => void;
        public contextLoadedCallback: () => void;
        public activity: aq.models.activity.ActivityResponse;
        public optimalStart: aq.models.optimalStart.OptimalResponse;
        public isLoading: boolean;
        private building: aq.common.models.Building;
        private accountId: number;
        private account: aq.common.models.Account;

        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private $translate,
            private Restangular: restangular.IService,
            private resolver: aq.services.Resolver,
            private Messages: aq.services.Messages,
            private OptimalStartService: aq.services.OptimalStartService
        ) {
        }

        public $onInit() {
            this.isLoading = true;
            this.resolver(async () => {
                try {
                    const ostPromise = this.OptimalStartService.getOptimalStartById( this.activity.context.optimalstart );
                    const accountPromise = this.Restangular.one('accounts', this.accountId).get({single: true});
                    [this.optimalStart, this.account] = await Promise.all([ostPromise, accountPromise]);
                    this.isLoading = false;
                    this.contextLoadedCallback();
                } catch (err) {
                    this.isLoading = false;
                    this.noDataCallback();
                    this.Messages.error('Data failed to load');
                    throw err;
                }
            });
        }
    }

    export const ActivityOptimalStartDisplay: ng.IComponentOptions = {
        controller: _ActivityOptimalStartDisplay,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/activities/contexts/optimalStartDisplay.html',
        bindings: {
            activity: '<',
            accountId: '<',
            building: '<',
            noDataCallback: '&',
            contextLoadedCallback: '&'
        }
    };

    angular
        .module('aq.ui')
        .component('activityOptimalStartDisplay', ActivityOptimalStartDisplay);
}
