var aq;
(function (aq) {
    var propertySettings;
    (function (propertySettings) {
        var AddCollectorModalCtrl = /** @class */ (function (_super) {
            __extends(AddCollectorModalCtrl, _super);
            /* @ngAnnotate */
            function AddCollectorModalCtrl($scope, $mdDialog, unassignedPulsePoints, Restangular, building) {
                var _this = _super.call(this, $scope, $mdDialog) || this;
                _this.$scope = $scope;
                _this.$mdDialog = $mdDialog;
                _this.unassignedPulsePoints = unassignedPulsePoints;
                _this.Restangular = Restangular;
                _this.building = building;
                $scope.meterMetrics = [{ label: 'Electricity', value: 'ELECTRICITY' }, { label: 'Gas', value: 'GAS' }, { label: 'Water', value: 'WATER' }, { label: 'Steam', value: 'STEAM' }, { label: 'Heat Transfer', value: 'HEAT' }];
                $scope.selectedMetric = 'ELECTRICITY';
                $scope.post = {
                    message: ''
                };
                $scope.unassignedPulsePoints = unassignedPulsePoints;
                $scope.equationValidated = true;
                $scope.equationValidationStatus = '';
                if (_this.$scope.typeTitle == 'Virtual Meter') {
                    _this.$scope.newCollector.collectorClass = 'VIRTUAL_METER';
                }
                _this.updateCollectorClasses();
                return _this;
            }
            AddCollectorModalCtrl.prototype.updateCollectorClasses = function () {
                var _this = this;
                if (this.$scope.typeTitle == 'Web Connector') {
                    this.$scope.collectorClasses = _.filter(this.$scope.options.collectorClasses, function (cl) {
                        // if the collectors upwardCommunication contains the specified type title.
                        return _.includes(cl.upwardCommunications, textural(_this.$scope.typeTitle).format('uppersnake'));
                    });
                }
            };
            AddCollectorModalCtrl.prototype.updateShowPointSelect = function () {
                var colClass = this.$scope.newCollector.collectorClass;
                if (!colClass)
                    return;
                var collectorClass = _.find(this.$scope.collectorClasses, function (cc) {
                    return cc.name == colClass;
                });
                this.$scope.showPointSelect = _.includes(collectorClass.upwardCommunications, 'PULSE');
            };
            return AddCollectorModalCtrl;
        }(aq.common.Controllers.ModalCtrl));
        propertySettings.AddCollectorModalCtrl = AddCollectorModalCtrl;
        angular.module('properties.dataCollectors').controller('AddCollectorModalCtrl', AddCollectorModalCtrl);
    })(propertySettings = aq.propertySettings || (aq.propertySettings = {}));
})(aq || (aq = {}));
