namespace aq.admin {
    export class NestedConfirmDialog{

        /* @ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private promptQuestion
        ) {}

        public cancel(): void{
            this.$mdDialog.cancel();
        }

        public confirm(): void{
            this.$mdDialog.hide();
        }
    }
    angular
        .module('aq.admin.accounts.accounts')
        .controller('NestedConfirmDialog', NestedConfirmDialog);
}

