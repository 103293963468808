angular.module('aq.accounts.companySettings', [])
    .config(function ($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.when('/accounts/:accountId/company-settings', '/accounts/:accountId/company-settings/general');
        $stateProvider
            .state('aq.accounts.companySettings', {
                templateUrl: 'app/accounts/companySettings/account.html',
                url: '/company-settings',
                controller: 'AccountCtrl',
                data: {
                    hide: false,
                    breadcrumb: 'Company Settings'
                }
            })
            .state('aq.accounts.companySettings.generalSettings', {
                templateUrl: 'app/accounts/companySettings/generalSettings/generalSettings.html',
                url: '/general',
                controller: 'GeneralSettingsCtrl',
                data: {
                    hide: true
                }
            })
            .state('aq.accounts.companySettings.metrics', {
                templateUrl: 'app/accounts/companySettings/metrics/metrics.html',
                url: '/metrics',
                controller: 'MetricCtrl as ctrl',
                data: {
                    hide: true
                }
            })
            .state('aq.accounts.companySettings.energyUse', {
                templateUrl: 'app/accounts/companySettings/energyUse/energyUse.html',
                url: '/energy-use',
                controller: 'EnergyUseCtrl',
                data: {
                    hide: true
                }
            })
            .state('aq.accounts.companySettings.buildingPurposes', {
                templateUrl: 'app/accounts/companySettings/buildingPurposes/buildingPurpose.html',
                url: '/building-purposes',
                controller: 'BuildingPurposeCtrl',
                data: {
                    hide: true
                }
            })
            .state('aq.accounts.companySettings.buildingGroups', {
                templateUrl: 'app/accounts/companySettings/buildingGroups/buildingGroup.html',
                url: '/building-groups',
                controller: 'BuildingGroupCtrl',
                data: {
                    hide: true
                }
            })
            .state('aq.accounts.companySettings.scheduleCategories', {
                templateUrl: 'app/accounts/companySettings/scheduleCategories/ruleCategory.html',
                url: '/schedule-categories',
                controller: 'RuleCategoriesCtrl',
                data: {
                    hide: true
                }
            });
    });
