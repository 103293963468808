namespace aq.utilityBudgets {

    enum Step {
        UTILITY_ACCOUNTS = 0,
        CREDENTIALS = 1,
        NOTIFICATIONS = 2,
        SHARED_BUILDINGS_AND_METERS = 3,
        DONE = 4
    }

    interface AccountCredential {
        username: string;
        password: string;
    }

    interface AccountMeter {
        accountNumber: string;
        number: string;
        type: string;
    }

    export class UtilityAccountWizardCtrl extends aq.common.Controllers.ModalCtrl {
        public readonly emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        public utilityCompany: string;
        public utilityCompanyUrl: string;
        public utilityAccounts: string[];
        public credentials: AccountCredential;
        public selectedBuildingViewItems: {
            [accountNumber: string]: aq.common.models.NamedItem[]
        };
        public personas: any[];
        public meters: AccountMeter[];
        public intervalMeasureTypes: aq.utilityBudgets.MeasureType[];
        public customEmails: string[];
        public isSendCustomEmails: boolean;
        public isSendMyself: boolean;
        public isSendBuildingPersona: boolean;
        public isSendUsers: boolean;
        public buildingPersonas: string[];
        public isAddAnother: boolean;
        public isCreating: boolean;
        public isIntercomEnabled: boolean;
        public userSearchText: string;
        public selectedUser: UserItem;
        public notificationUsers: UserItem[];
        public filterTerm: string;

        private intercom: any;
        private currentStep: number;
        private readonly mode: UtilityAccountWizardMode = 'auto';

        /* ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private $mdStepper,
            private RestangularV3: restangular.IService,
            private UtilityServiceHelper: UtilityServiceHelper,
            private UserService: aq.services.UserService,
            private Messages,
            private utilityCompanies: aq.common.models.NamedItem[],
            private buildingViewItems: aq.common.models.NamedItem[],
            private buildingId: number,
            private users: UserItem[],
            private $window: ng.IWindowService,
            private $q: ng.IQService,
            private Segment: aq.services.SegmentService
        ) {
            super({}, $mdDialog);
            this.init();
            this.intercom = this.$window['Intercom'];
            if (this.intercom) {
                this.isIntercomEnabled = true;
            }
            this.personas = [
                { label: 'Asset Manager', value: 'ASSET_MANAGER' },
                { label: 'Property Manager', value: 'PROPERTY_MANAGER' },
                { label: 'Building Engineer', value: 'BUILDING_ENGINEER' }
            ];
            this.customEmails = [];
            this.notificationUsers = [];
            this.buildingPersonas = [];
        }
        public init() {
            this.utilityCompany = null;
            this.utilityCompanyUrl = null;
            this.utilityAccounts = [];
            this.selectedBuildingViewItems = {};
            this.credentials = this.getNewCredentials();
            this.intervalMeasureTypes = this.UtilityServiceHelper.getIntervalMeasureTypes();
            this.meters = [];
            this.isAddAnother = false;
            this.filterTerm = '';
            this.currentStep = 0;
        }
        public onAddUtilityAccount(item: string) {
            this.selectedBuildingViewItems[item] = [];
        }
        public getNewCredentials() {
            return { username: '', password: '' };
        }
        public addMeter() {
            this.meters.push({
                accountNumber: _.first(this.utilityAccounts),
                number: null,
                type: null
            });
        }
        public removeMeter(meter) {
            _.remove(this.meters, (item) => item === meter);
        }
        public next() {
            if (this.currentStep == Step.SHARED_BUILDINGS_AND_METERS) {
                this.create()
                    .then((result) => {
                        this.$mdStepper('utility-account-wizard').next();
                        this.currentStep++;
                    });
            } else if (this.currentStep == Step.DONE) {
                this.done();
            } else {
                this.$mdStepper('utility-account-wizard').next();
                this.currentStep++;
            }
        }
        public previous() {
            this.$mdStepper('utility-account-wizard').back();
            this.currentStep = Math.max(0, this.currentStep - 1);
        }
        public create() {
            this.Segment.trackEvent('Utility Accounts:Auto Sync Utility Account Creation');
            this.isCreating = true;
            const requests = [];
            _.each(this.utilityAccounts, (accountNumber) => {
                const utilityAccountRequest: UtilityAccountRequest = {
                    accountNumber,
                    utilityCompany: parseInt(this.utilityCompany),
                    username: this.credentials.username,
                    password: this.credentials.password,
                    utilityUrl: this.utilityCompanyUrl,
                    sharedBuildingIds: _.map(this.selectedBuildingViewItems[accountNumber], (building: aq.common.models.NamedItem) => building.id),
                    meters: this.findAccountMeters(accountNumber),
                    emailRecipients: this.getEmailRecipients(),
                    buildingPersonas: this.isSendBuildingPersona ? this.buildingPersonas : [],
                    customEmails: this.isSendCustomEmails ? this.customEmails : []
                };
                const request = this.RestangularV3
                    .all('utility-accounts')
                    .post(utilityAccountRequest, { buildingId: this.buildingId });
                requests.push(request);
            });
            return this.$q.all(requests)
                .catch((err) => {
                    this.Messages.error('Error occurred while creating utility account, please contact your Administrator');
                })
                .finally(() => {
                    this.isCreating = false;
                });
        }
        public done() {
            if (this.isAddAnother) {
                this.init();
                this.$mdStepper('utility-account-wizard').goto(Step.UTILITY_ACCOUNTS);
            } else {
                this.hide();
            }
        }
        public confirmCancel(event) {
            const confirm = this.$mdDialog.confirm()
                .title('Do you want to exit wizard? Your progress will be lost.')
                .ariaLabel('Exit Wizard')
                .targetEvent(event)
                .ok('Exit')
                .cancel('Stay');
            (confirm as any).multiple(true);
            this.$mdDialog.show(confirm).then(() => {
                this.cancel();
            });
        }
        public findAccountMeters(accountNumber): Meter[] {
            const meters: AccountMeter[] = _.filter(this.meters, (meter: AccountMeter) => meter.accountNumber == accountNumber);
            return _.map(meters, (meter: AccountMeter) => {
                return <Meter>{
                    id: meter.number,
                    type: meter.type
                };
            });
        }
        public getEmailRecipients() {
            const userIds = this.isSendUsers ? _.map(this.notificationUsers, 'id') : [];
            const currentUserId = this.isSendMyself ? [this.UserService.currentUser.id] : [];
            return _.union(userIds, currentUserId);
        }
        public isValidCredentials() {
            return !!(this.credentials && this.credentials.username && this.credentials.password);
        }
        public isValidNotifications() {
            const isValidCustomEmails = !(this.isSendCustomEmails && this.customEmails.length == 0);
            const isValidUsers = !(this.isSendUsers && this.notificationUsers.length == 0);
            const isValidBuildingPersona = !(this.isSendBuildingPersona && this.buildingPersonas.length == 0);
            const isSend = this.isSendMyself
                || this.isSendBuildingPersona
                || this.isSendUsers
                || this.isSendCustomEmails;

            return isSend
                && isValidBuildingPersona
                && isValidUsers
                && isValidCustomEmails;
        }
        public isValidMeters() {
            return this.meters.length == 0 || _.every(this.meters, (meter: AccountMeter) => this.isValidMeter(meter));
        }
        public isValidMeter(meter: AccountMeter) {
            return meter.accountNumber && meter.number && meter.type;
        }
        public onSelectedBuildingsChange(buildings, accountNumber) {
            this.selectedBuildingViewItems[accountNumber] = buildings;
        }
        public onAddCustomEmail(item: string) {
            if (!this.validateEmail(item)) {
                this.customEmails = _.filter(this.customEmails, (email) => email != item);
            }
        }
        public validateEmail(email) {
            return this.emailRegex.test(String(email).toLowerCase());
        }
        public isShowBack() {
            return this.currentStep > Step.UTILITY_ACCOUNTS && this.currentStep < Step.DONE;
        }
        public isLastStep() {
            return this.currentStep == Step.DONE;
        }
        public openChat() {
            this.intercom('showMessages');
            this.$mdDialog.hide();
        }
        public queryUsers(search: string, assignedUsers: UserItem) {
            const searchText = search.toLowerCase();
            const users = this.users.filter((user) => {
                return user.fullName.toLowerCase().indexOf(searchText) > -1
                    && _.findIndex(assignedUsers, { fullName: user.fullName }) === -1;
            });
            return users;
        }
        public isDisableNext() {
            switch (this.currentStep) {
                case Step.UTILITY_ACCOUNTS:
                    return !this.utilityAccounts || this.utilityAccounts.length == 0;
                case Step.CREDENTIALS:
                    return !this.isValidCredentials();
                case Step.NOTIFICATIONS:
                    return !this.isValidNotifications();
                case Step.SHARED_BUILDINGS_AND_METERS:
                    return this.isCreating || !this.isValidMeters();
                default:
                    return false;
            }
        }
        public getNextButtonTitle() {
            switch (this.currentStep) {
                case Step.SHARED_BUILDINGS_AND_METERS:
                    return 'Next';
                case Step.DONE:
                    return 'Done';
                default:
                    return 'Next';
            }
        }
        public getFilteredCompanies() {
            return _.filter(this.utilityCompanies, (utilityCompany: aq.common.models.NamedItem) => {
                return !this.filterTerm || utilityCompany.name && utilityCompany.name.toLowerCase().indexOf(this.filterTerm.toLowerCase()) > -1;
            });
        }
    }
    angular.module('aq.utilityBudgets').controller('UtilityAccountWizardCtrl', UtilityAccountWizardCtrl);
}
