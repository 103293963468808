var aq;
(function (aq) {
    var tenantbilling;
    (function (tenantbilling) {
        var BulkResolveAnomaliesCtrl = /** @class */ (function () {
            /* @ngInject */
            function BulkResolveAnomaliesCtrl(tenantChargeValues, MonthlyBillingService, $mdDialog, $q, Messages, Auth) {
                this.tenantChargeValues = tenantChargeValues;
                this.MonthlyBillingService = MonthlyBillingService;
                this.$mdDialog = $mdDialog;
                this.$q = $q;
                this.Messages = Messages;
                this.Auth = Auth;
                this.setProgress(false);
                this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'unit' : 'tenant';
            }
            BulkResolveAnomaliesCtrl.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            BulkResolveAnomaliesCtrl.prototype.getAnomalies = function () {
                return _.filter(this.tenantChargeValues, function (chargeValue) {
                    return chargeValue.needsUserAction;
                });
            };
            BulkResolveAnomaliesCtrl.prototype.setProgress = function (inProgress) {
                this.inProgress = inProgress;
            };
            BulkResolveAnomaliesCtrl.prototype.resolveAnomalies = function (notes) {
                return __awaiter(this, void 0, void 0, function () {
                    var chargeValues;
                    var _this = this;
                    return __generator(this, function (_a) {
                        if (!notes || notes === '') {
                            this.Messages.error('Must fill out a note');
                            return [2 /*return*/];
                        }
                        this.setProgress(true);
                        chargeValues = this.getAnomalies();
                        chargeValues.forEach(function (chargeValue) {
                            chargeValue.notes = notes;
                        });
                        this.MonthlyBillingService.saveChargeValues(chargeValues, chargeValues[0].route)
                            .then(function (results) {
                            _this.$mdDialog.hide(results);
                        }).catch(function () {
                            _this.Messages.error('Failed to update resolutions for our anomalies');
                        }).finally(function () {
                            _this.setProgress(false);
                        });
                        return [2 /*return*/];
                    });
                });
            };
            return BulkResolveAnomaliesCtrl;
        }());
        tenantbilling.BulkResolveAnomaliesCtrl = BulkResolveAnomaliesCtrl;
        angular.module('tenantBilling')
            .controller('BulkResolveAnomaliesCtrl', BulkResolveAnomaliesCtrl);
    })(tenantbilling = aq.tenantbilling || (aq.tenantbilling = {}));
})(aq || (aq = {}));
