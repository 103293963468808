var aq;
(function (aq) {
    var components;
    (function (components) {
        var MeterStatus = aq.models.interval.MeterStatus;
        var DeviceReadingsComponent = /** @class */ (function () {
            /* @ngInject */
            function DeviceReadingsComponent($scope, $state, $filter, Errors, RestangularV3, $stateParams, Messages, $interval, $mdDialog, OptionsService, $modal, DeviceService, Auth) {
                this.$scope = $scope;
                this.$state = $state;
                this.$filter = $filter;
                this.Errors = Errors;
                this.RestangularV3 = RestangularV3;
                this.$stateParams = $stateParams;
                this.Messages = Messages;
                this.$interval = $interval;
                this.$mdDialog = $mdDialog;
                this.OptionsService = OptionsService;
                this.$modal = $modal;
                this.DeviceService = DeviceService;
                this.Auth = Auth;
            }
            // Reading on component lifecycle hooks https://toddmotto.com/angular-1-5-lifecycle-hooks
            DeviceReadingsComponent.prototype.$onInit = function () {
                var _this = this;
                // manual reading and comparison tool timezones
                this.shortDateFormat = 'lll';
                this.longDateFormat = 'llll';
                this.timeZoneId = this.building.timeZoneId;
                if (this.user.timeZoneId !== this.building.timeZoneId) {
                    this.shortDateFormat += ' z';
                    this.longDateFormat += ' z';
                }
                this.readingsComparisonTool = {
                    startReading: {},
                    endReading: {},
                    startReadingList: this.manualReadings,
                    endReadingList: this.manualReadings
                };
                this.OptionsService.init(this.building.account, 'US_CUSTOMARY_UNITS', null).then(function () {
                    _this.metricLabel = _this.OptionsService.getUnitLabelByMeasure(_this.collector.metrics[0]);
                });
                this.hasIntervalData = false;
                this.getSyncData();
            };
            ;
            DeviceReadingsComponent.prototype.getSyncData = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var response, err_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.loading = true;
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.RestangularV3.one('interval').customGET("getSyncData/" + this.device.id)];
                            case 2:
                                response = _a.sent();
                                if (response.status !== MeterStatus.NOT_INTERVAL_METER) {
                                    this.hasIntervalData = true;
                                    this.interval = response;
                                }
                                else {
                                    this.hasIntervalData = false;
                                }
                                this.loading = false;
                                return [3 /*break*/, 4];
                            case 3:
                                err_1 = _a.sent();
                                this.hasIntervalData = false;
                                this.loading = false;
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            DeviceReadingsComponent.prototype.addManualReading = function () {
                var _this = this;
                this.$mdDialog.show({
                    targetEvent: event,
                    templateUrl: 'app/deviceManagement/device/components/Readings/newReading.html',
                    controller: 'NewReadingCtrl',
                    controllerAs: 'vm',
                    multiple: true,
                    clickOutsideToClose: true,
                    locals: {
                        collector: this.collector,
                        manualReading: {},
                        metricLabelUnit: this.metricLabel.unit,
                        timeZoneId: this.building.timeZoneId
                    }
                })
                    .then(function (newManualReading) {
                    var manualReading = angular.copy(newManualReading);
                    var zonelessDate = moment(manualReading.viewOnly.date).set('hours', manualReading.viewOnly.hour).set('minutes', manualReading.viewOnly.minute).format('YYYY-MM-DD HH:mm:ss');
                    manualReading.timestamp = moment.tz(zonelessDate, _this.timeZoneId).format();
                    _this.DeviceService.saveReading(manualReading).then(function () {
                        _this.$scope.$emit('MANUAL_READING_CREATED', { manualReading: manualReading });
                    });
                    _this.onDeviceChange();
                });
            };
            ;
            DeviceReadingsComponent.prototype.updateStartReadingList = function () {
                var _this = this;
                this.readingsComparisonTool.startReadingList = this.manualReadings.filter(function (reading) {
                    if (reading.id ===
                        _this.readingsComparisonTool.endReading.id) {
                        return false;
                    }
                    if (moment(reading.timestamp).isAfter(moment(_this.readingsComparisonTool
                        .endReading.timestamp))) {
                        return false;
                    }
                    return true;
                });
            };
            ;
            DeviceReadingsComponent.prototype.calculateReadingComparison = function () {
                var _this = this;
                this.collector
                    .customGET('readingsComparison', {
                    startReading: this.readingsComparisonTool
                        .startReading.id,
                    endReading: this.readingsComparisonTool
                        .endReading.id,
                    date: new Date()
                })
                    .then(function (readingsComparison) {
                    _this.readingsComparison = readingsComparison;
                    _this.Messages.success('Successfully calculated readings comparison.');
                })
                    .catch(function (error) {
                    _this.Messages.error('Error calculating comparison.');
                });
            };
            ;
            DeviceReadingsComponent.prototype.formatTimestamp = function (timestamp) {
                return moment(timestamp).format('MMM D, YYYY hh:mm a');
            };
            ;
            DeviceReadingsComponent.prototype.deleteReading = function (event, manualReading) {
                var _this = this;
                this.DeviceService.deleteReading(event, manualReading).then(function () {
                    _this.onDeviceChange();
                });
            };
            ;
            DeviceReadingsComponent.prototype.updateEndReadingList = function () {
                var _this = this;
                this.readingsComparisonTool.endReadingList = this.manualReadings.filter(function (reading) {
                    if (reading.id ===
                        _this.readingsComparisonTool.startReading.id) {
                        return false;
                    }
                    if (moment(reading.timestamp).isBefore(moment(_this.readingsComparisonTool
                        .startReading.timestamp))) {
                        return false;
                    }
                    return true;
                });
            };
            ;
            DeviceReadingsComponent.prototype.editManualReading = function (manualReading) {
                var _this = this;
                this.$mdDialog.show({
                    targetEvent: event,
                    templateUrl: 'app/deviceManagement/device/components/Readings/newReading.html',
                    controller: 'NewReadingCtrl',
                    controllerAs: 'vm',
                    multiple: true,
                    clickOutsideToClose: true,
                    locals: {
                        collector: this.collector,
                        manualReading: manualReading,
                        metricLabelUnit: this.metricLabel.unit,
                        timeZoneId: this.building.timeZoneId
                    }
                })
                    .then(function (newManualReading) {
                    var manualReading = angular.copy(newManualReading);
                    var zonelessDate = moment(manualReading.viewOnly.date).set('hours', manualReading.viewOnly.hour).set('minutes', manualReading.viewOnly.minute).format('YYYY-MM-DD HH:mm:ss');
                    manualReading.timestamp = moment.tz(zonelessDate, _this.timeZoneId).format();
                    return _this.DeviceService.updateReading(manualReading).then(function () {
                        _this.onDeviceChange();
                    });
                });
            };
            ;
            DeviceReadingsComponent.prototype.onDeviceChange = function () {
                this.onChange({
                    $event: {
                        device: this.device
                    }
                });
            };
            DeviceReadingsComponent.prototype.$onChanges = function (changes) { };
            DeviceReadingsComponent.prototype.$postLink = function () { };
            return DeviceReadingsComponent;
        }());
        components.DeviceReadingsComponent = DeviceReadingsComponent;
        angular.module('aq.ui').component('deviceReadings', {
            templateUrl: 'app/deviceManagement/device/components/Readings/readings.html',
            controller: aq.components.DeviceReadingsComponent,
            controllerAs: 'vm',
            bindings: {
                building: '<',
                user: '<',
                device: '<',
                manualReadings: '<',
                collector: '<',
                onChange: '&'
            }
        });
    })(components = aq.components || (aq.components = {}));
})(aq || (aq = {}));
