angular
    .module('notificationCenter', ['aq.ui', 'aq.ui.dynamicFilter'])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.notificationCenter', {
                url: '/accounts/:accountId/notification-center',
                template: '<div />',
                controller: function () { },
                data: {
                    appName: 'Notification Center'
                },
                resolve: {
                    redirect:
                        (
                            $q: ng.IQService,
                            accountId: number,
                            RedirectService: aq.services.RedirectService
                        ) => {
                            const defer = $q.defer();
                            const destination = `accounts/${accountId}/notification-center`;
                            RedirectService.redirectWithoutParams(destination);
                            return defer.promise;
                        }
                }
            });
    });
