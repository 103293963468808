/**
 * Once you start mocking angular-mocks assumes that all http requests should be mocked unless explicitly set to passthrough.
 * This file says to passthrough any request that isn't mocked.
 */
angular.module('aq-main').run(function($httpBackend) {
    $httpBackend.whenGET(/[\s\S]*/).passThrough();
    $httpBackend.whenPOST(/[\s\S]*/).passThrough();
    $httpBackend.whenPUT(/[\s\S]*/).passThrough();
    $httpBackend.whenDELETE(/[\s\S]*/).passThrough();
});
