angular
    .module('aq.reports')
    .directive('reportPie', function () {
        return {
            restrict: 'E',
            replace: false,
            templateUrl: 'app/reports/summary/portfolio/directives/reportPie/reportPie.html',
            scope: {
                aqData: '='
            },
            link: function ($scope, element, $attrs) {
                $scope.$watch('aqData', function (aqData: any) {

                    Highcharts.setOptions({
                        lang: {
                            thousandsSep: ','
                        }
                    });

                    if (aqData) {
                        $scope.plotOptions = {
                            chart: {
                                plotBackgroundColor: null,
                                plotBorderWidth: null,
                                plotShadow: false,
                                type: 'pie',
                                height: 160,
                                width: aqData.options && aqData.options.width ? aqData.options.width : 160
                            },
                            title: {
                                text: ''
                            },
                            plotOptions: {
                                pie: {
                                    animation: false,
                                    allowPointSelect: false,
                                    cursor: 'pointer',
                                    borderColor: 'rgba(255,255,255,.5)',
                                    borderWidth: 2,
                                    dataLabels: {
                                        enabled: false
                                    },
                                    showInLegend: true
                                }
                            },
                            legend: aqData.options && aqData.options.legend ? aqData.options.legend : { enabled: false },
                            exporting: {
                                enabled: false
                            },
                            series: [{
                                name: ' ',
                                colorByPoint: true,
                                data: aqData.data.map(function (data) {
                                    return {
                                        name: data.label,
                                        y: data.data,
                                        color: data.color
                                    }
                                }),
                            }]
                        }
                    }
                })
            }
        }
    });
