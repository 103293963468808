'use strict';

angular.module('aq.reports').factory('ReportApiParams',
    function() {

    var ReportApiParams = function(now, measures, reportType, startDate, endDate) {

        var format = 'YYYY-MM-DDTHH:mm:ssZ';
        var period;
        var length;
        var interval;

        if (reportType === 'monthlyFull') {
            period = 'month';
            length = 1;
            interval = '15min';
        } else if (reportType === 'daily') {
            period = 'day';
            length = 7;
            interval = '15min';
        } else if (reportType === 'monthly') {
            period = 'month';
            length = 1;
            interval = '1d';
        } else {
            period = 'year';
            length = 1;
            interval = '1mon';
        }

        function getDateRange(now, startDate, endDate) {
            var endTemp = angular.copy(now);
            var end = endTemp.endOf(period);

            if(startDate && endDate) {
                var startTime = startDate.format(format);
                var endTime = endDate.format(format);
                return {
                    end: endTime,
                    start: startTime
                }
            }
            return {
                end: end.format(format),
                start: now.startOf(period).format(format)
            }
        }

        function getApiParams(now, start, end) {
            var time = getDateRange(now, start, end);
            return {
                start: time.start,
                end: time.end,
                interval: interval,
                measure: measures && measures.length ? measures.join() : null
            }
        }

        this.current = function() {
            var time = angular.copy(now);
            var start = angular.copy(startDate);
            var end = angular.copy(endDate);

            var currentApiParams =  getApiParams(time, start, end);
            if (reportType !== 'daily' && reportType !== 'monthly') {
                var endTime = angular.copy(now);
                // return data only through the month of the current local date
                currentApiParams.end = endTime.add(1, 'months').startOf('month').format(format);
            }
            return currentApiParams;
        };

        this.previous = function() {
            var time = angular.copy(now);
            var start = angular.copy(startDate);
            var end = angular.copy(endDate);
            time.subtract(length, period);
            if(start && end){
                start.subtract(1, 'years');
                end.subtract(1, 'years');
            }
            return getApiParams(time, start, end);
        };

        this.third = function() {
            var time = angular.copy(now);
            var start = angular.copy(startDate);
            var end = angular.copy(endDate);
            time.subtract(length * 2, period);
            return getApiParams(time, start, end);
        };

        this.currentDegreeDay = function() {
            var time = angular.copy(now);
            var start = angular.copy(startDate);
            var end = angular.copy(endDate);
            var currentDegreeDay = {
                start: time.startOf(period).format(format),
                end: time.startOf(period).add(1, period).format(format),
                interval: interval
            };
            if(start && end){
                var startTime = start.format(format);
                var endTime = end.format(format);
                currentDegreeDay = {
                    start: startTime,
                    end: endTime,
                    interval: interval
                };
            }
            if (reportType !== 'daily' && reportType !== 'monthly') {
                var endTime = angular.copy(now);
                // return data only through the month of the current local date
                currentDegreeDay.end = endTime.add(1, 'months').startOf('month').format(format);
            }
            return currentDegreeDay;
        };

        this.previousDegreeDay = function() {
            var time = angular.copy(now);
            var start = angular.copy(startDate);
            var end = angular.copy(endDate);
            time.subtract(length, period);
            var currentDegreeDay ={
                start: time.startOf(period).format(format),
                end: time.startOf(period).add(1, period).format(format),
                interval: interval
            };
            if(start && end){
                start.subtract(1, 'year');
                end.subtract(1, 'year');
                var startTime = start.format(format);
                var endTime = end.format(format);

                currentDegreeDay = {
                    start: startTime,
                    end: endTime,
                    interval: interval
                };
            }
            return currentDegreeDay;
        };

        this.thirdDegreeDay = function() {
            var time = angular.copy(now);
            time.subtract(length * 2, period);
            return {
                start: time.startOf(period).format(format),
                end: time.startOf(period).add(1, period).format(format),
                interval: interval
            };
        }

    };

    return ReportApiParams;

});
