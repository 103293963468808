namespace aq.tenantbilling {
    export class AddEditReminderCtrl extends aq.common.Controllers.ModalCtrl {
        public usersToRemind: any[];
        public isUsersToRemindError: boolean;
        public userSearchText;
        private selectedUser;
        /* @ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            public reminder: Reminder,
            private reminders: Reminder[],
            private users,
            private billingReminderTypes,
            private timeOptions,
            private billingSetupId,
            private TenantBillingSetupService: TenantBillingSetupService,
            private Auth: aq.services.Auth
        ) {
            super({}, $mdDialog);
            if (!this.reminder) {
                this.setupDefaultReminder();
            }
            this.usersToRemind = _.map(this.reminder.usersToRemind, (userId) => {
                const targetUser = _.find(this.users, (user) => user.id == userId);
                return {
                    id: targetUser.id,
                    fullName: targetUser.fullName
                };
            });
        }
        public cancel(data?): void {
            this.$mdDialog.cancel();
        }
        public hide(data): void {
            this.$mdDialog.hide(data);
        }
        public saveReminder() {
            if (this.usersToRemind.length == 0) {
                this.isUsersToRemindError = true;
                return;
            }
            let action;
            const untypedReminder: any = this.reminder;
            if (untypedReminder.id) {
                action = this.TenantBillingSetupService.updateReminder(untypedReminder);
            } else {
                action = this.TenantBillingSetupService.createReminder(untypedReminder);
            }
            action.then((result) => {
                this.hide(result);
            });
        }
        public queryUsers() {
            const searchText = this.userSearchText.toLowerCase();
            const users = this.users.filter((user) => {
                return user.fullName.toLowerCase().indexOf(searchText) > -1
                    && _.findIndex(this.usersToRemind, { fullName: user.fullName }) === -1;
            });
            return users;
        }
        public consolidateReminderUsers() {
            this.isUsersToRemindError = this.usersToRemind.length == 0;
            const userIds = _.map(this.usersToRemind, (u) => u.id);
            this.reminder.usersToRemind = userIds;
        }

        private setupDefaultReminder() {
            this.reminder = {
                setup: this.billingSetupId,
                daysBefore: null,
                time: '09:00:00',
                type: 'INVOICES',
                usersToRemind: []
            } as Reminder;
        }
    }
    angular.module('tenantBilling').controller('AddEditReminderCtrl', AddEditReminderCtrl);
}
