angular.module('aq.calendar', ['aq.filters']).directive('ruleForm', function($q) {
    return {
        restrict : 'E',
        //replace: true,
        require: '',
        templateUrl: 'app/common/directives/ruleForm/ruleForm.html',
        scope: {
            rule: '=ngModel',
            amPm: '=',
            onChange: '&',
            editable: '='
        },
        controller: ['$scope', '$filter', 'Auth', function ($scope, $filter, Auth) {

            $scope.editRule = Auth.check({access: 'EDIT'});
            
            $scope.repeats = [{value: 'Does not repeat', label: 'Does not repeat'},
                              {value: 'Weekly', label: 'Weekly'}];

            $scope.allDaysOfWeek = [
                { 'day': 'SUNDAY', 'selected': false },
                { 'day': 'MONDAY', 'selected': false },
                { 'day': 'TUESDAY', 'selected': false },
                { 'day': 'WEDNESDAY', 'selected': false },
                { 'day': 'THURSDAY', 'selected': false },
                { 'day': 'FRIDAY', 'selected': false },
                { 'day': 'SATURDAY', 'selected': false }
            ];

            var init = true;

            $scope.allWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
            $scope.workDays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];

            function setDate(ruleDate) {
                var date = null
                if (ruleDate) date = moment(ruleDate).format('MMM DD, YYYY');
                return date;
            }

            function setDaysOfWeek(ruleWeek) {
                // reset daysOfWeek array
                var daysOfWeek = $scope.allDaysOfWeek;
                _.each(daysOfWeek, function (day) { day.selected = false; });
                // set new values
                _.each(daysOfWeek, function (dayOfWeek) {
                    if (_.includes(ruleWeek, dayOfWeek.day)) {
                        dayOfWeek.selected = true;
                    };
                });
                return daysOfWeek;
            }

            $scope.form = {};

            $scope.$watch('rule', function(rule) {
                if (!rule) return;

                if(!$scope.rule.week) return;

                if (init) {

                    setFormTime(rule.timeStart, rule.timeEnd);

                    $scope.form.dateStart = setDate($scope.rule.dateStart);
                    $scope.form.dateEnd = setDate($scope.rule.dateEnd);

                    $scope.form.daysOfWeek = setDaysOfWeek($scope.rule.week);

                    if ($scope.rule.week.length === 7) { 
                        $scope.isAllWeek = true;
                        $scope.form.repeatMode = 'Does not repeat';
                    } else {
                        $scope.form.repeatMode = 'Weekly'
                    }
                    if (!$scope.rule.dateStart && !$scope.rule.dateEnd) $scope.form.repeatMode = 'Weekly';   
                    init = false;             
                }

            }, true);

            $scope.setAllDay = function() {
                if (!$scope.rule.timeStart && !$scope.rule.timeEnd) {
                    setFormTime('09:00:00', '17:00:00');
                } else {
                    setFormTime(null, null);
                }
                $scope.onChange({ rule: formDataToRule($scope.form) });
            }

            function setFormTime(timeStart, timeEnd) {
                if (timeStart && timeEnd) {
                    $scope.form.timeStart = moment(timeStart, 'HH:mm:ss');
                    $scope.form.timeEnd = moment(timeEnd, 'HH:mm:ss');
                } else {
                    $scope.form.timeStart = null;
                    $scope.form.timeEnd = null;
                }

               // if ($scope.rule.dateStart) $scope.startdate = moment($scope.rule.dateStart).format('MMM DD, YYYY');
            }

            $scope.setAllYear = function() {
                if ($scope.rule.dateStart == null && $scope.rule.dateEnd == null) {
                    if ($scope.showDatePickers) {
                        $scope.showDatePickers = false;
                    } else {
                        $scope.showDatePickers = true;
                    }
                } else {
                    $scope.form.dateStart = null;
                    $scope.form.dateEnd = null;
                    $scope.showDatePickers = false;
                }
                $scope.onChange({ rule: formDataToRule($scope.form) });
            }

            $scope.setAllWeek = function() {
                if ($scope.rule.week.length === 7 || $scope.isAllWeek) {
                    $scope.form.daysOfWeek = setDaysOfWeek($scope.workDays);
                    $scope.isAllWeek = false;
                } else {
                    $scope.form.daysOfWeek = setDaysOfWeek($scope.allWeek);
                    $scope.isAllWeek = true;
                }
            }

            $scope.update = function() {
                $scope.onChange({ rule: formDataToRule($scope.form) });
                return $q.when();
            }

            function daysOfWeekToWeek(daysOfWeek) {
                var week = [];
                _.each(daysOfWeek, function (day) { 
                    if(day.selected) {
                        week.push(day.day);
                    }
                });
                return week;
            }

            function parseHours(value) {
                if (!value) return null;
                return moment(value).format('HH:mm:ss');
            }

            function formDataToRule(formData) {
                
                var ruleData: any = {};

                ruleData.timeStart = parseHours(formData.timeStart);
                ruleData.timeEnd = parseHours(formData.timeEnd);
                
                ruleData.dateStart = formData.dateStart ? moment(formData.dateStart).format("YYYY-MM-DD") : null;
                ruleData.dateEnd = formData.dateEnd ? moment(formData.dateEnd).format("YYYY-MM-DD") : null;

                ruleData.week = daysOfWeekToWeek(formData.daysOfWeek);

                return ruleData;
            }

            $scope.changeRepeatMode = function() {

                var deferred = $q.defer();

                if ($scope.form.repeatMode === 'Weekly') {
                    setDaysOfWeek($scope.workDays);
                }

                if ($scope.form.repeatMode === 'Does not repeat') {
                    
                    $scope.showDatePickers = true;
                    setDaysOfWeek($scope.allWeek);
                }

                deferred.resolve();
                return deferred.promise;
            }

        }]
    }
});
