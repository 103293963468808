'use strict';

angular.module('aq.admin.dataViewer').controller('DataViewerCtrl', function ($q, runtimeStates, $filter, $timeout, $location, $scope, PropertyGenerator, $state, Restangular, DataService, Messages, $rootScope, loading) {

    var host = "http://" + window.location.hostname;
    var currentRoute;
    // default fields in resources table
    var defaultFields = ['id', 'name', 'type', 'appName', 'access', 'email','primaryContact'];

    $scope.chartData = [];
    $scope.listData = [];
    $scope.data = {};
    $scope.q = {};
    $scope.apiUrl = host + '/api/v1/accounts';
    $scope.showSchema =  false;
    $scope.toggleSchema = function() {
        if ($scope.chartData.length == 0) initialQuery();
        $scope.showSchema = !$scope.showSchema;
    }
    $scope.headerText = 'Data Viewer';

    // load object or list if reload or given url
    if ($state.current.name != 'aq.admin.dataViewer') {
        $state.current.data.current.then(function(a) {
            if ($state.current.data.type == 'object') {
                setCurrentObject(a, true);
            } else {
                $state.current.data.parent.then(function(a) {
                    setCurrentObject(a, true);
                    $state.current.data.current.then(function(list) {
                        setList(list);
                    })
                })
            }
        })
    }

    function setList(list) {
        $scope.currentList = list;

        var objects = _.map(list, function(row) {
            return _.pick(row, defaultFields);
        })

        // Table header values
        $scope.listHeader = [];
        _.each(objects[0], function(value, key) {
            $scope.listHeader.push(key);
        })

        var listData = [];
        _.each(objects, function(object) {
            var row = [];
            _.each(object, function(value, key) {
                row.push(value);
            })
            listData.push(row);
        })
        $scope.listData = listData;
    }

    function getAccounts() {
        Restangular.all('accounts').get('queryAllAccounts').then(function(accounts) {
            Restangular.restangularizeCollection(null, accounts, "accounts");
            if ($state.current.name == 'aq.admin.dataViewer') {
                setList(accounts);
            }
        });
    }
    getAccounts();

    $scope.account.customGET('queryRealUnits').then(function(results) {
        $scope.allUnits = _.map(results, function(unit, index) {
            unit.id = index;
            return unit;
        })
    })

    $scope.selectItem = function(value) {
        if (isDataTable) {
            $scope.queryData(value);
        } else {
            $scope.selectByObject(value);
        }
    }

    var isDataTable = false;
    $scope.queryData = function(single) {
        isDataTable = true;
        if (!single) {
            // we don't need loading when we fetch single result because response
            // is received immediately
            loading.start(true);
            $scope.listData = [];
        }
        // we need to put timeout because ng-repeat keeps data in doom in case
        // we added/removed column to table, column is added/removed but ng-repeat
        // is not rerendered, it only removes certain column which means that onFinishRender
        // directive doesn't works properly because it looks if last tr is rendered which is
        // not the case at this scenario - timeout will tell angular
        // to remove all items from ng-repeat and rerdender it again
        $timeout( function() {
            queryData(single);
        }, 0);
    }
    var initialQuery = function() {// This is used to query the database in order to show the tree schema
        var toParsed = moment().subtract(5, 'hours');
        var fromParsed = moment().subtract(7, 'hours');
        var timezone = $scope.account.timeZoneId;
        var from = moment.tz(fromParsed, timezone);
        var to = moment.tz(toParsed, timezone);
        var params: any = { breakdown : true };
        DataService.data($scope.currentObject, "1min", from, to, 'energy', params).then(function(result) {
            $scope.chartData = result;
            $scope.unitToShowOnChart = "kWh";
        });
    }
    var queryData = function(single) {

        var params: any = { breakdown: true };
        var fromParsed = moment($scope.q.from).format('YYYY-MM-DD HH:mm');
        var toParsed = moment($scope.q.to).format('YYYY-MM-DD HH:mm');
        var timezone = $scope.account.timeZoneId;

        var from = moment.tz(fromParsed, timezone);
        var to = moment.tz(toParsed, timezone);

        // if clicked on some row on table
        if (single) {

            var fromSingleParsed = moment(single).format('YYYY-MM-DD HH:mm');
            from = moment.tz(fromSingleParsed, timezone);

            // copy start data so it lose reference to from moment variable,
            // so later we can manipulate with dates in switch bellow
            to = angular.copy(from);

            // add one more interval from start value so we can receive only one result
            // for selected time on data table
            switch ($scope.q.interval) {
                case '1min':
                    to = to.add(1, 'minutes');
                    break;
                case '15min':
                    to = to.add(15, 'minutes');
                    break;
                case '1h':
                    to = to.add(1, 'hours');
                    break;
                case '1d':
                    to = to.add(1, 'days');
                    break;
                case '1mon':
                    to = to.add(1, 'months');
                    break;
                default:
                    to = to.add(1, 'seconds');
            }

            params.breakdownValues = true;
        }

        $scope.unitToShowOnChart = $scope.metric;

        function getValueByMeasureAndUnit (value) {
            return $filter('toUnit')(value, $scope.metric, true);
        }

        var sendDate = (new Date()).getTime();
        DataService.data($scope.currentObject, $scope.q.interval, from, to, $scope.metric, params).then(function(result) {

            var receiveDate = (new Date()).getTime();
            $scope.responseTimeMs = receiveDate - sendDate;

            // generate chart
            $scope.chartData = result;
            $scope.apiDataUrl = result.getRestangularUrl() + '?interval=' + $scope.q.interval + '&start=' + from.format() + '&end=' + to.format() + '&measure=' + $scope.metric.apiUnit;

            if (!single) {
                var listData = [];
                // we need different handler when receiving raw data
                if ($scope.q.interval == 'raw') {

                    $scope.listHeader = [];
                    _.each(result.values, function(values, key) {
                        _.each(values, function(values, keyValue) {
                            $scope.listHeader.push(keyValue + '-' + key);
                        })
                    })

                    // energy, power....
                    var rows = [];
                    _.each(result.values, function(object, index) {
                        // values, timestamps
                        _.each(object, function(list) {
                            _.each(list, function(value, index) {
                                if(rows[index]) {
                                    rows[index].push(value);
                                } else {
                                    rows.push([value]);
                                }
                            })
                        })
                    })

                    listData = rows;

                } else {

                    $scope.data = result;
                    $scope.listHeader = ['timestamps'];
                    _.each(result.values, function(values, key) {
                        $scope.listHeader.push(key);
                    })

                    _.each(result.timestamps, function(value, index) {
                        var row = [moment(value).tz(timezone).format('llll')];
                        _.each(result.values, function(values, key) {
                            var value = getValueByMeasureAndUnit(key);
                            row.push(value);
                        })
                        listData.push(row);
                    })
                }
                $scope.listData = listData;
            }

            if (_.isArray(result)) {
                Messages.warn('Received format is not correct');
            }

        }, function(result) {
            if (result.status == 404) {
                Messages.error('Requested api route doesn\'t exist');
            }
            // by default loading is always in finally but since I need to wait for
            // angular to render ng-repeat I'm waiting for that event inside $scope.$on('ngRepeatFinished'
            loading.stop();
        })
    };

    $scope.select = function(property) {

        var stateName = property;
        var url = property;
        var data = {
            appName: null
        }

        runtimeStates.addState(stateName, url, data);
        $state.transitionTo(runtimeStates.last(), {});

        isDataTable = false;
        $scope.currentObject.getList(property).then(function(list) {
            currentRoute = property;
            $scope.showSchema = false;
            $scope.chartData = [];
            $scope.data.values = null;
            setList(list);
            $scope.apiUrl = host + $scope.currentList.getRestangularUrl();
        })
    };

    function setCurrentObject(object, skip = false) {
        $scope.chartData = [];
        $scope.data.values = null;
        $scope.currentList = [];
        $scope.listData = [];
        $scope.currentObject = object;
        $scope.properties = PropertyGenerator.generateProperties(object);
        $scope.details = PropertyGenerator.generateDetails(object);
        currentRoute = $scope.currentObject.route;
        $scope.apiUrl = host + $scope.currentObject.getRestangularUrl();

        var stateName = object.route.toLowerCase();
        var url = object.id;
        var data = {
            appName: object.accountName || object.name
        }
        runtimeStates.addState(stateName, url, data);

        if (!skip) {
            $state.transitionTo(runtimeStates.last(), {});
        }

    }

    $scope.selectByObject = function(id) {
        var object = _.findById($scope.currentList, id);
        object.get({ single: true }).then(function(currentObject) {
            setCurrentObject(currentObject);
        })
    }

    // generate csv file from $scope.currentList object array
    $scope.download = function() {
        var header =  angular.copy($scope.listHeader);
        var data = angular.copy($scope.listData);
        data.unshift(header);

        var csvContent = "data:text/csv;charset=utf-8,";
        data.forEach(function(infoArray, index){
            var dataString = infoArray.join(",");
            csvContent += index < data.length ? dataString+ "\n" : dataString;
        });

        var encodedUri = encodeURI(csvContent);

        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "data_viewer_export.csv");
        link.click();
    }

    $scope.$on('ngRepeatFinished', function(ngRepeatFinishedEvent) {
        loading.stop();
    })

});
