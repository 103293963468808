module aq.ui.highChart {
    angular.module('aq.ui.highChart.service', ['aq.ui.color', 'aq.ui.intervalSelector', 'aq.filters.units']);
}

/**
 * Service for generic methods used with HighCharts
 */
namespace aq.ui.highChart {

    import HighchartsDataPoint = __Highcharts.DataPoint;
    import Moment = moment.Moment;

    export class HighChartService {
        /* @ngInject */
        constructor(private intervalService, private $filter, private aqColors) {

        }

        /**
         * Returns array of data for HighChart series property
         * @param data - Array of objects received from data API
         * @param unit - Unit object
         * @param type
         * @param timezone
         * @param buildingSizes
         * @returns {Array}
         */
        public generateSeriesForMultipleObjects(
            dataSeries: Data[],
            unit: Unit,
            type: string,
            timezone: string,
            buildingSizes: any
        ): HighchartsDataPoint[] {
            const { aqColors } = this;
            const { $filter } = this;
            const seriesArray = _.map(dataSeries, (data: Data) => {
                const convertedValues = $filter('arrayToUnit')(data.values, unit, buildingSizes ? buildingSizes[data.id] : null);
                const convertedMissingValues = $filter('arrayToUnit')(data.missingIntervalValues, unit, buildingSizes ? buildingSizes[data.id] : null);
                const dataSource: string = HighChartService.getDataSourceForTooltip(data.dataSources);
                const seriesColor = aqColors.getUniqueColorFromName(data.name);
                const missingDataColor = this.transparentColor(seriesColor);
                const dataPoints = [];
                const zones = [];
                let firstZone = true;
                let previousZoneIndex;
                convertedValues.forEach((value, i) => {
                    const datum = {
                        x: moment(data.timestamps[i]).tz(timezone).valueOf(),
                        y: value,
                        tooltip: dataSource === 'Real Time'
                            ? ''
                            : `Source: ${dataSource}`
                    };
                    if (convertedMissingValues[i] != null) {
                        datum.y = convertedMissingValues[i];
                        datum.tooltip = dataSource === 'Real Time'
                            ? ''
                            // eslint-disable-next-line max-len
                            : `<div style="color: red; font-style: italic">Some data has not been received yet</div><br>Source: ${dataSource}`;

                        // The zones allow the missing data to be colored differently than the rest of the line
                        // See: https://api.highcharts.com/highcharts/plotOptions.line.zones
                        if (firstZone) {
                            // If this is a line chart we start the zone one timestamp
                            // before the missing value because the part of the line we are
                            // coloring starts after the value (to the left). For column
                            // charts, this is not necessary
                            const lineIndex = type === 'line' ? i - 1 : i;
                            zones.push(
                                {
                                    value: moment(data.timestamps[lineIndex]).tz(timezone).valueOf()
                                }
                            );
                            // We push the next timestamp to ensure the last item in the zone
                            // uses the missing Data Color. If we are at the fine item, we use
                            // undefined so that the zone continues for the rest of the data.
                            const index = i === data.timestamps.length - 1 ? undefined : i + 1;
                            zones.push(
                                {
                                    value: moment(data.timestamps[index]).tz(timezone).valueOf(),
                                    color: missingDataColor
                                }
                            );
                            firstZone = false;
                            previousZoneIndex = i;
                            // If the next timestamp is missing we continue the zone
                        } else if (i - previousZoneIndex === 1) {
                            const index = i === data.timestamps.length - 1 ? undefined : i + 1;
                            zones.push(
                                {
                                    value: moment(data.timestamps[index]).tz(timezone).valueOf(),
                                    color: missingDataColor
                                }
                            );
                            previousZoneIndex = i;
                            // If more than one timestamp has passed we are starting a new zone
                        } else if (i - previousZoneIndex > 1) {
                            const lineIndex = type === 'line' ? i - 1 : i;
                            zones.push(
                                {
                                    value: moment(data.timestamps[lineIndex]).tz(timezone).valueOf()
                                }
                            );
                            zones.push(
                                {
                                    value: moment(data.timestamps[i + 1]).tz(timezone).valueOf(),
                                    color: missingDataColor
                                }
                            );
                            previousZoneIndex = i;
                        }
                    }
                    dataPoints.push(datum);
                });
                const series = {
                    type,
                    data: dataPoints,
                    name: data.name,
                    color: seriesColor,
                    marker: {
                        radius: 0
                    },
                    zIndex: 1,
                    zoneAxis: 'x',
                    zones
                };
                return series;
            });
            return [...seriesArray];
        }

        /**
         * Generate HighchartsPointObjects for Pie chart
         * @param metricData
         * @param customColors
         * @returns {HighchartsDataPoint[]}
         */
        public generatePieChartSeries(metricData: MetricData[], customColors: string[]): HighchartsDataPoint[] {
            const monthlyGenerationDataValues = _(metricData).map('values').map('total').value();

            const sum = _.reduce(monthlyGenerationDataValues, (memo: number, num: number) => {
                return memo + num;
            }, 0);

            const result = _.map(metricData, function (r, idx) {
                const a = r.values.total / sum * 100;
                const b = this.numeral(a).format('0.00');
                const c: HighchartsDataPoint = {};

                c.name = r.name;
                c.y = parseFloat(b);
                if (customColors) {
                    c.color = customColors[idx];
                }
                return c;
            });

            return result;
        }

        /**
         * Use this function with timeseries data charts to set
         * xAxis time labels based on start period and aggregation interval
         */
        public setXAxisForTimeSeriesChart(series: any, start: Moment, interval: string) {
            series.pointStart = start.valueOf();
            series.pointInterval = this.intervalService.getIntervalByDuration(interval).duration.asMilliseconds();
        }

        private transparentColor(color: string): string {
            const alpha = 0.5;
            const convertedAlpha = Math.floor(alpha * 255);
            const alphaString = convertedAlpha < 16 ? '0' + convertedAlpha.toString(16) : convertedAlpha.toString(16);
            return color + alphaString;
        }

        /**
         * Gets the data source string to be used in the tooltip.
         * @param dataSources - Currently can only be [] or ['interval'].
         * @returns The desired string for the corresponding data source.
         */
        private static getDataSourceForTooltip(dataSources?: string[]): string {
            if (dataSources === undefined || dataSources.length === 0) {
                return 'Real Time';
            }

            /* TODO: Add additional data sources */
            switch (dataSources[0].toLowerCase()) {
                case 'interval':
                    return 'Utility Interval Data';
                default:
                    return 'Real Time';
            }
        }
    }

    angular.module('aq.ui.highChart.service').service('HighChartService', HighChartService);
}
