namespace aq.tenantbilling {
    export interface TenantViewModel {
        id: string;
        name: string;
        imageUrl: string;
        accountNumber: string;
    }

    export class TenantBillingTenantListCtrl {
        public filteredTenantViewItems: TenantViewModel[];
        public tenantViewItems: TenantViewModel[];
        public isEmptySearch: boolean;
        private lastSearchQuery: string;
        private tenantDetailsState = 'aq.tenantbilling.building.tenants.details';
        private isAqAdmin: boolean;
        tenantLabel: string;
        /* @ngInject */
        constructor(
            private tenants: TenantModel[],
            private accountId,
            private buildingId,
            private allEnums,
            public $state: ng.ui.IStateService,
            public $mdDialog: ng.material.IDialogService,
            private $mdMedia: ng.material.IMedia,
            private building: aq.common.models.Building,
            private deletedTenantId,
            private updatedTenantId,
            private areaMeasurement,
            private UserService,
            private Auth: aq.services.Auth
        ) {
            this.buildViewItems();
            this.isAqAdmin = this.UserService.currentUser.userType === 'ADMINISTRATOR';
            this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'unit' : 'tenant';
        }
        public buildViewItems() {
            this.tenantViewItems = _.map(this.tenants, (tenant) => this.getTenantViewModel(tenant));
            this.filteredTenantViewItems = this.tenantViewItems;
        }
        public createTenant() {
            if (this.Auth.hasFunctionality("Multifamily Tenant Billing")) {
                this.createUnit();
                return;
            }
            this.$mdDialog.show({
                controller: 'AddTenantCtrl as vm',
                templateUrl: 'app/tenantBilling/tenants/actions/addTenant/addTenant.html',
                clickOutsideToClose: false,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    accountId: this.accountId,
                    buildingId: this.buildingId,
                    leaseTypes: this.allEnums.leaseTypes,
                    areaMeasurement: this.areaMeasurement
                }
            }).then((newTenant: TenantModel) => {
                this.tenants.push(newTenant);
                const newViewItem = this.getTenantViewModel(newTenant);
                this.tenantViewItems.push(newViewItem);
                this.searchBy(this.lastSearchQuery);
                this.editTenant(newViewItem);
            });
        }
        public createUnit() {
            this.$mdDialog.show({
                controller: 'AddUnitCtrl as vm',
                templateUrl: 'app/tenantBilling/tenants/actions/addUnit/addUnit.html',
                clickOutsideToClose: false,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    accountId: this.accountId,
                    buildingId: this.buildingId,
                    leaseTypes: this.allEnums.leaseTypes,
                    areaMeasurement: this.areaMeasurement
                }
            }).then((newTenant: TenantModel) => {
                this.tenants.push(newTenant);
                const newViewItem = this.getTenantViewModel(newTenant);
                this.tenantViewItems.push(newViewItem);
                this.searchBy(this.lastSearchQuery);
                this.editTenant(newViewItem);
            });
        }

        public bulkUploadTenants() {
            this.$mdDialog.show({
                controller: 'BulkUploadTenantCtrl as vm',
                templateUrl: 'app/tenantBilling/tenants/actions/bulkUpload/bulkUploadTenant.html',
                clickOutsideToClose: false,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    accountId: this.accountId,
                    building: this.building,
                    leaseTypes: this.allEnums.leaseTypes,
                    areaMeasurement: this.areaMeasurement
                }
            }).then((response) => {
                this.$state.reload();
            });
        }
        public bulkUploadMoveIns() {
            this.$mdDialog.show({
                controller: 'BulkUploadMoveInsCtrl as vm',
                templateUrl: 'app/tenantBilling/tenants/actions/bulkUploadMoveIns/bulkUploadMoveIns.html',
                clickOutsideToClose: false,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    accountId: this.accountId,
                    building: this.building,
                    leaseTypes: this.allEnums.leaseTypes,
                    areaMeasurement: this.areaMeasurement
                }
            }).then((response) => {
                this.$state.reload();
            });
        }
        public bulkUploadMoveOuts() {
            this.$mdDialog.show({
                controller: 'BulkUploadMoveOutsCtrl as vm',
                templateUrl: 'app/tenantBilling/tenants/actions/bulkUploadMoveOuts/bulkUploadMoveOuts.html',
                clickOutsideToClose: false,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    accountId: this.accountId,
                    building: this.building,
                    leaseTypes: this.allEnums.leaseTypes,
                    areaMeasurement: this.areaMeasurement
                }
            }).then((response) => {
                this.$state.reload();
            });
        }
        public editTenant(viewItem: TenantViewModel) {
            this.$state.go(this.tenantDetailsState, { tenantId: viewItem.id });
        }

        public searchBy(query: string): void {
            this.lastSearchQuery = query;
            if (query) {
                this.filteredTenantViewItems = this.tenantViewItems
                    .filter((tenant) => {
                        const searchThrough: string[] = [tenant.name, tenant.accountNumber];
                        return this.foundIn(searchThrough, query);
                    });
            } else {
                this.filteredTenantViewItems = this.tenantViewItems;
            }
            this.isEmptySearch = !!query && this.filteredTenantViewItems.length === 0;
        }
        private getTenantViewModel(tenant: TenantModel) {
            return {
                id: tenant.id,
                name: tenant.name,
                imageUrl: tenant.imageThumbnailUrl,
                accountNumber: tenant.accountNumber
            };
        }
        private foundIn(searchInArray: string[], searchFor: string): boolean {
            return searchInArray.some((searchIn) => {
                if (searchIn && _.includes(searchIn.toUpperCase(), searchFor.toUpperCase())) {
                    return true;
                }
            });
        }
        private clearTenant(id) {
            _.remove(this.tenants, (s) => s.id == id);
            _.remove(this.tenantViewItems, (s) => s.id == id);
            _.remove(this.filteredTenantViewItems, (s) => s.id == id);
        }
    }
    angular.module('tenantBilling').controller('TenantBillingTenantListCtrl', TenantBillingTenantListCtrl);
}
