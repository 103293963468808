namespace aq.utilityBudgets {
    export class AccrualInfoCtrl {
        /* ngInject */
        constructor(
            private measureItem: any,
            private currencyUnitSymbol: string
        ) {
        }
    }
    angular.module('aq.utilityBudgets').controller('AccrualInfoCtrl', AccrualInfoCtrl);
}
