namespace aq.projectCenter {
    export interface TaskEditModalOptions {
        isNameReadonly?: boolean;
        isActionReadonly?: boolean;
        isStatusReadonly?: boolean;
    }
    export class ProjectCenterTaskEditCtrl extends aq.common.Controllers.ModalCtrl {
        public ignoreTask: boolean;
        private actionOptions: TaskActionOptions;
        /* @ngInject */
        constructor(
            protected $scope: ng.IScope,
            protected $mdDialog: ng.material.IDialogService,
            private project: Project,
            private task: Task,
            private users: aq.common.models.User[],
            private DataStore: aq.common.DataStore,
            private ProjectService: ProjectService,
            private Messages: aq.services.Messages,
            private RestangularV3: restangular.IService,
            private $state: ng.ui.IStateService,
            private account: aq.common.models.Account,
            private $location: ng.ILocationService,
            private options: TaskEditModalOptions
        ) {
            super($scope, $mdDialog);
            this.task.dueDate = task.dueDate ? moment(task.dueDate).toDate() : null;
            if (this.task.completionDate) {
                this.task.complete = true;
            }
            this.ignoreTask = (this.task.status == 'IGNORED');
            const host = this.getHost();
            this.actionOptions = {
                'PROFILE': {
                    label: 'Go to Profile',
                    actionUrl: `${host}${this.$state.href('aq.user.profile')}`
                },
                'DEVICES_AND_EQUIPMENT': {
                    label: 'Go to Devices & Equipment',
                    actionUrl: `${host}${this.$state.href('aq.deviceManagement.building', { accountId: account.id, buildingId: project.building })}`
                },
                'UTILITY_BILLS': {
                    label: 'Go to Utility Bill Management',
                    actionUrl: `${host}${this.$state.href('aq.utilityBudgets.utilityaccounts', { accountId: account.id, buildingId: project.building })}`
                },
                'USERS': {
                    label: 'Go to User Management',
                    actionUrl: `${host}${this.$state.href('aq.accounts.members', { accountId: account.id })}`
                },
                'BUILDING': {
                    label: 'Go to Property Settings',
                    actionUrl: `${host}${this.$state.href('aq.properties.buildings.form', { accountId: account.id, buildingId: project.building })}`
                },
                'BUDGETS': {
                    label: 'Go to Budgets',
                    actionUrl: `${host}${this.$state.href('aq.utilityBudgets.budgets', { accountId: account.id, buildingId: project.building })}`
                },
                'TARGETS': {
                    label: 'Go to Targets',
                    actionUrl: `${host}${this.$state.href('aq.properties.buildings.targets', { accountId: account.id, buildingId: project.building })}`
                }
            };
        }

        public onTaskIgnoreChange() {
            if (this.task.status != 'IGNORED') {
                this.task.status = 'IGNORED';
                this.task.complete = false;
            } else if (this.task.status === 'IGNORED' && this.task.completionDate) {
                this.task.status = 'COMPLETE';
                this.task.complete = true;
            } else if (this.task.status === 'IGNORED' && !this.task.completionDate) {
                this.task.status = 'INCOMPLETE';
                this.task.complete = false;
            }
        }

        public save() {
            if (this.task.status != 'IGNORED') {
                if (this.task.complete && !this.task.completionDate) {
                    this.task.completionDate = moment().format('YYYY-MM-DD');
                }
                if (!this.task.complete && this.task.completionDate) {
                    this.task.completionDate = null;
                }
            }

            if (!this.task.actionUrl) {
                const host = this.getHost();
                this.task.actionUrl = `${host}${this.$state.href('aq.projectCenter.overview.project.tasks', {
                    accountId: this.account.id,
                    buildingId: this.project.building,
                    projectId: this.project.id
                })}`;
            }
            const successMessage = this.task.id ? 'Task succesfully updated' : 'Successfully created Task';
            return this.ProjectService.saveTask(this.project, this.task)
                .then((result) => {
                    this.Messages.success(successMessage);
                    return result;
                })
                .catch((err) => {
                    this.Messages.error('Unable to save task');
                });
        }

        private getHost() {
            let host = `${this.$location.protocol()}://${this.$location.host()}`;
            // if we are onlocal add port
            if (host.includes('localhost')) {
                host += `:${this.$location.port()}`;
            }
            return host;
        }
    }
    angular
        .module('projectCenter')
        .controller('ProjectCenterTaskEditCtrl', ProjectCenterTaskEditCtrl);
}
