namespace aq.deviceManagement {
    declare const Highcharts: __Highcharts.Static;

    export class DeviceDataComponent  {
        private static color = {
            missingRawData: '#ff7f81',
            gridLineColor: '#c7c7c7'
        };

        public building: aq.common.models.Building;
        public device: aq.common.models.DeviceElement;
        public modbusMeterDataParameters: MeterDataParameters;
        public pulseMeterDataParameters: DeviceDataParameters;
        public isAqAdmin: boolean;
        public spikeData: aq.common.models.SpikeData;
        public chart: __Highcharts.ChartObject;
        public showSpike = false;
        private deviceDataQueryables: DeviceDataQueryable[];
        private queryables: DeviceDataQueryable[];
        private collector: aq.common.models.Collector;
        private links: any[];
        private axisList: string[] = [];
        private plotBandList: string[] = [];
        private chartConfig = null;
        private points: aq.common.models.Point[];
        private filteredPoints: MultiPhasePoint[];
        private queryInProgress: boolean = false;
        private selectedInterval: Interval;
        private intervals: Interval[];
        private chartHasBeenLoaded: boolean;
        private fifteenMinuteParameterOptions: { interval: string; start: moment.Moment; end: moment.Moment };
        private multiPhaseUnits: MultiPhaseUnits[];
        private fullMeterDataLabel = '3-Phase Data';

        /* @ngInject */
        constructor(
                    private DataService,
                    private Messages,
                    private DataStore: aq.common.DataStore,
                    private DeviceDataUnits: aq.deviceManagement.DeviceDataUnits,
                    private $q,
                    private DeviceService: aq.services.DeviceService,
                    private UserService,
                    private $mdDialog: any) {}

        $onInit() {
            this.intervals = [
                {name: '15 Minute', resolution: '15min'},
                {name: '1 Minute', resolution: '1min'}
            ];
            this.queryables = [];
            this.selectedInterval = this.intervals[0];
            this.multiPhaseUnits = [MultiPhaseUnits.AMPERAGE, MultiPhaseUnits.LINE_VOLTAGE, MultiPhaseUnits.POWER_FACTOR, MultiPhaseUnits.VOLTAGE];
            this.filteredPoints = this.filterAndSortPoints(this.points);
            const threePhaseDataPoint: MultiPhasePoint = {
                ... {} as restangular.IElement,
                name: this.fullMeterDataLabel,
                dataScope: DataScope.FULL_METER,
                collector: null,
                collectorName: null
            };
            this.filteredPoints.push(threePhaseDataPoint);
            this.modbusMeterDataParameters = {
                points: this.filteredPoints.slice(0, 1),
                interval: '15min',
                start: moment().subtract(2, 'months'),
                end: moment(),
                units: null
            };
            this.setFifteenMinuteIntervalOptions();
            this.pulseMeterDataParameters = this.getFifteenMinuteIntervalOptions();
            const timezoneOffset = 0 - moment().tz(this.building.timeZoneId).utcOffset();
            Highcharts.setOptions({global: {timezoneOffset}});

            if (this.DeviceDataUnits) {
                this.deviceDataQueryables = this.DeviceDataUnits.getDeviceDataQueryables(this.device, this.links, this.points, this.collector);
            }

            this.chartConfig = this.buildChartConfig();
            this.isAqAdmin = this.UserService.currentUser.userType === 'ADMINISTRATOR';
        }

        getChartConfig() {
            return this.chartConfig;
        }

        getDeviceDataQueryables(): DeviceDataQueryable[] {
            return this.deviceDataQueryables;
        }

        getIntervals(): any[] {
            return this.intervals;
        }

        getPoints() {
            return this.filteredPoints;
        }

        filterAndSortPoints(allPoints: Point[]) {
            let filteredPoints: MultiPhasePoint[] = [];
            allPoints.forEach((point) => {
                const multiPhasePoint: MultiPhasePoint = {
                    ...point,
                    dataScope: DataScope.SINGLE_POINT
                };
                filteredPoints.push(multiPhasePoint);
            });
            filteredPoints = _.filter(allPoints, (point) => point.type === 'ENERGY');
            return _.sortBy(filteredPoints, 'name');
        }

        intervalSelect() {
            if (this.selectedInterval.resolution !== this.modbusMeterDataParameters.interval ||
                this.selectedInterval.resolution !== this.pulseMeterDataParameters.interval) {
                if (this.selectedInterval.resolution === '15min') {
                    this.setFifteenMinuteIntervalOptions();
                    this.pulseMeterDataParameters = this.getFifteenMinuteIntervalOptions();
                } else {
                    const extremes = this.chart.xAxis[0].getExtremes();
                    this.modbusMeterDataParameters.start = moment.tz(extremes.min, this.building.timeZoneId);
                    this.modbusMeterDataParameters.end = moment.tz(extremes.max, this.building.timeZoneId);
                    this.pulseMeterDataParameters.start = moment.tz(extremes.min, this.building.timeZoneId);
                    this.pulseMeterDataParameters.end = moment.tz(extremes.max, this.building.timeZoneId);
                }
                this.getDeviceDataConfig();
            }
        }

        getQueryInProgress(): boolean {
            return this.queryInProgress;
        }

        unitSelect() {
            if (this.queryables.length !== 0) {
                this.getDeviceDataConfig();
            }
        }

        getDeviceDataConfig(): Promise<any> {
            if (this.chart) {
                this.chartHasBeenLoaded
                    ? this.chart.showLoading()
                    : this.chartHasBeenLoaded = true;

                this.queryInProgress = true;

                let index = 0;

                for (const axis of this.axisList) {
                    this.chart.get(axis).remove(false);
                }
                this.axisList = [];

                const promiseList = [];

                if (this.selectedInterval.resolution) {
                    this.modbusMeterDataParameters.interval = this.selectedInterval.resolution;
                    this.pulseMeterDataParameters.interval = this.selectedInterval.resolution;
                }

                if (this.queryables.map((queryable) => queryable.unit.apiUnit).indexOf('PULSE') < 0) {
                    for (const id of this.plotBandList) {
                        this.chart.xAxis[0].removePlotBand(id);
                    }
                    this.plotBandList = [];
                    const seriesRemoval = this.chart.get('plotBandSeries');
                    if (seriesRemoval) seriesRemoval.remove(false);
                }

                this.modbusMeterDataParameters.points = _.sortBy(this.modbusMeterDataParameters.points, [function(point) {
                    return point.name;
                }]);
                let legendIndex = 0;
                for (const queryable of this.queryables) {
                    if (this.isModbusMeter(this.collector.collectorClass)) {
                        if (this.multiPhaseUnits.indexOf(MultiPhaseUnits[queryable.unit.apiUnit]) > -1) {
                            let arrIndex = 0;
                            for (const point of this.modbusMeterDataParameters.points) {
                                if (point.dataScope === DataScope.FULL_METER) {
                                    promiseList.push(this.createPulsePromise(queryable, queryable.unit, index, legendIndex));
                                    legendIndex++;
                                } else {
                                    promiseList.push(this.createModbusPromise(queryable.unit, point, index, legendIndex, arrIndex));
                                    legendIndex++;
                                    arrIndex++;
                                }
                            }
                        } else {
                            promiseList.push(this.createPulsePromise(queryable, queryable.unit, index, legendIndex));
                            legendIndex++;
                        }
                    } else {
                        promiseList.push(this.createPulsePromise(queryable, queryable.unit, index, legendIndex));
                        legendIndex++;
                    }
                    index++;
                }

                return this.$q.all(promiseList).then(() => {
                    this.chart.series = _.sortBy(this.chart.series, [function(series) {
                        return series.index;
                    }]);
                    this.queryInProgress = false;
                    this.chart.hideLoading();
                    // reset the navigator scrollbar to 6 months if not modbus meter 2 months otherwise
                    let min = null;
                    if (this.isModbusMeter(this.collector.collectorClass)) {
                        min = parseInt(moment.tz(this.building.timeZoneId).subtract(2, 'months').format('x'));
                    } else {
                        min = parseInt(moment.tz(this.building.timeZoneId).subtract(6, 'months').format('x'));
                    }
                    this.chart.xAxis[0].update({
                        min,
                        max: parseInt(moment.tz(this.building.timeZoneId).format('x'))
                    });
                    if (this.plotBandList.length > 0 && !this.chart.get('plotBandSeries')) {
                        this.chart.addSeries({
                            id: 'plotBandSeries',
                            name: 'Missing Raw Data',
                            color: DeviceDataComponent.color.missingRawData,
                            marker: {
                                symbol: 'square'
                            },
                            lineWidth: 0,
                            animation: false
                        });
                    }
                });
            }
        }

        createPulsePromise(queryable, unit, index, legendIndex) {
            return this.DataService.data(queryable.queryable, this.pulseMeterDataParameters.interval,
                this.pulseMeterDataParameters.start, this.pulseMeterDataParameters.end,
                this.deviceDataUnitToApiUnits(unit), this.getMoreApiParameters())
                .then((dataResult: DeviceDataResult) => {
                    const axisName = unit.apiUnit;
                    const isFifteenMinutePulse: boolean = (this.pulseMeterDataParameters.interval !== '1min' &&
                                    queryable.unit.apiUnit === 'PULSE');

                    if (this.axisList.indexOf(axisName) == -1) {
                        const yAxis: __Highcharts.AxisOptions = _.extend({}, unit.yAxis, {
                            id: axisName,
                            gridLineColor: DeviceDataComponent.color.gridLineColor,
                            gridLineWidth: 1,
                            opposite: index % 2
                        });
                        if (isFifteenMinutePulse) {
                            const plotBands = this.redPlotBandsForNulls(dataResult['pulse']);
                            for (const plotBand of plotBands) {
                                this.chart.xAxis[0].addPlotBand(plotBand);
                            }

                        }

                        this.chart.addAxis(yAxis, false, false);
                        this.axisList.push(axisName);
                    }

                    const series: __Highcharts.LineChartSeriesOptions = _.extend({}, unit.series, {
                        data: this.createSeriesData(dataResult, unit),
                        type: 'line',
                        connectNulls: !isFifteenMinutePulse,
                        yAxis: axisName,
                        index: legendIndex
                    });
                    this.chart.addSeries(series);
                })
                .catch((error) => {
                    let errorMessage: string = null;
                    if (error.data.errorText === 'metric.validation.invalid.time-window') {
                        errorMessage = 'Time window too large for query';
                        this.setFifteenMinuteIntervalOptions();
                        this.selectedInterval = this.intervals[0];
                        this.getDeviceDataConfig();
                    } else {
                        errorMessage = 'Problem when querying data, try again';
                    }
                    this.Messages.error(errorMessage);
                });
        }

        createModbusPromise(unit, point, index, legendIndex, arrIndex) {
            return this.DataService.data(point, this.modbusMeterDataParameters.interval,
                this.modbusMeterDataParameters.start, this.modbusMeterDataParameters.end,
                this.deviceDataUnitToApiUnits(unit), this.getMoreApiParameters())
                .then((dataResult: DeviceDataResult) => {
                    const axisName = unit.apiUnit;

                    if (this.axisList.indexOf(axisName) == -1) {
                        const yAxis: __Highcharts.AxisOptions = _.extend({}, unit.yAxis, {
                            id: axisName,
                            gridLineColor: DeviceDataComponent.color.gridLineColor,
                            gridLineWidth: 1,
                            opposite: index % 2
                        });

                        this.chart.addAxis(yAxis, false, false);
                        this.axisList.push(axisName);
                    }

                    const currentSeries = _.cloneDeep(unit.series);
                    currentSeries.name = currentSeries.name.concat('-' + point.name);
                    const shadeColor = Math.ceil(arrIndex / 2) * 40 * (arrIndex % 2 === 0 ? 1 : -1);
                    currentSeries.color = this.shadeColor(currentSeries.color, shadeColor);
                    const series: __Highcharts.LineChartSeriesOptions = _.extend({}, currentSeries, {
                        data: this.createSeriesData(dataResult, unit),
                        type: 'line',
                        yAxis: axisName,
                        color: currentSeries.color,
                        index: legendIndex
                    });
                    this.chart.addSeries(series);
                })
                .catch((error) => {
                    let errorMessage: string = null;
                    if (error.data.errorText === 'metric.validation.invalid.time-window') {
                        errorMessage = 'Time window too large for query';
                        this.setFifteenMinuteIntervalOptions();
                        this.selectedInterval = this.intervals[0];
                        this.getDeviceDataConfig();
                    } else {
                        errorMessage = 'Problem when querying data, try again';
                    }
                    this.Messages.error(errorMessage);
                });
        }

        isModbusMeter(collectorClass): Boolean {
            if (CollectorType[collectorClass] === CollectorType.RAIL_350v_MODBUS) {
                return true;
            } else if (CollectorType[collectorClass] === CollectorType.AQUICORE_METER) {
                if (this.device.deviceClass.measure.toLowerCase() === 'electricity') {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }

        shadeColor(color, amount) {
            if (!color) {
                return color;
            }
            if (color.length !== 7) {
                return color;
            }
            color = color.slice(1);
            const num = parseInt(color , 16);
            let r = (num >> 16) + amount;
            if ( r > 255 ) {
                r = 255;
            } else if  (r < 0) {
                r = 0;
            }

            let b = ((num >> 8) & 0x00FF) + amount;
            if ( b > 255 ) {
                b = 255;
            } else if  (b < 0) {
                b = 0;
            }

            let g = (num & 0x0000FF) + amount;
            if ( g > 255 ) {
                g = 255;
            } else if  ( g < 0 ) {
                g = 0;
            }
            // Fixes bug where toString truncates leading 0 causing display color to change
            let newColor = '000000' + (g | (b << 8) | (r << 16)).toString(16);
            newColor = newColor.substr(newColor.length - 6);
            return '#' + newColor;
        }

        buildChartConfig(): object {
            return {
                chart: {
                    seriesBoostThreshold: 1,
                    plotBorderWidth: 1,
                    zoomType: 'x',
                    panning: 'true',
                    panKey: 'shift',
                    events: {
                        render: () => {
                            this.setSpikeData();
                        }
                    }
                },
                title: {
                    text: this.device.name
                },
                tooltip: {
                    xDateFormat: '%B %e, %Y %l:%M %p',
                    shared: true
                },
                xAxis: {
                    type: 'datetime',
                    tickLength: 0,
                    gridLineWidth: 1,
                    gridLineColor: DeviceDataComponent.color.gridLineColor,
                    events: {
                        afterSetExtremes: (e) => this.resetMeterDataParameters(moment.tz(e.min, this.building.timeZoneId),
                            moment.tz(e.max, this.building.timeZoneId))
                    }
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: true
                        }
                    }
                },
                plotOptions: {
                    line: {
                        animation: false
                    },
                    series: {
                        turboThreshold: 1
                    }
                },
                navigator: {
                    enabled: false,
                    series: {
                        includeInCSVExport: false
                    }
                },
                scrollbar: {
                    enabled: true,
                    liveRedraw: false
                },
                rangeSelector: {
                    enabled: true,
                    inputEnabled: true,
                    inputDateFormat: '%b %e %Y',
                    inputEditDateFormat: '%b %e %Y',
                    buttons: [
                        { type: 'hour', text: 'Hr', count: 1},
                        { type: 'day', text: 'Day', count: 1},
                        { type: 'week', text: 'Wk', count: 1},
                        { type: 'month', text: 'Mon', count: 1},
                        { type: 'all', text: 'All' }
                    ]
                },
                yAxis: {
                    title: {
                        text: null
                    }
                }
            };
        }

        deviceDataUnitToApiUnits(unit: DeviceDataUnit): string[] {
            const apiUnits: string[] = [unit.apiUnit];
            if (unit.apiUnit.toString() === 'PULSE' && this.pulseMeterDataParameters.interval === '1min') {
                apiUnits.push('INTERPOLATED');
            }
            return apiUnits;
        }

        showSpikeZapper() {
            this.showSpike = true;
            this.setSpikeData();
        }

        setSpikeData() {
            if (this.chart && this.chart.xAxis && this.chart.xAxis[0] && this.chart.xAxis[0].getExtremes()) {
                const utility = this.device.deviceClass.measure;
                const collectorId = this.device.collectorId;
                const extremes = this.chart.xAxis[0].getExtremes();
                const start = moment.utc(extremes.min).format('YYYY-MM-DD HH:mm').split(' ').join('T') + 'Z';
                const end = moment.utc(extremes.max).format('YYYY-MM-DD HH:mm').split(' ').join('T') + 'Z';
                this.spikeData = {
                    utility,
                    collectorId,
                    start,
                    end
                };
            }
        }

        checkBoundsForSpike(spike: aq.common.models.SpikeData) {
            const diff = moment(spike.end).diff(moment(spike.start), 'days');
            if (diff < 7) {
                return true;
            }
            return false;
        }

        removeSpike(ev) {
            const isValidRange = this.checkBoundsForSpike(this.spikeData);
            if (isValidRange) {
                const confirm = this.$mdDialog.confirm()
                    .title('Are you sure you want to remove this spike?  This cannot be undone.')
                    .ariaLabel('Remove Spike')
                    .targetEvent(ev)
                    .ok('Ok')
                    .cancel('Cancel');
                this.$mdDialog.show(confirm).then(() => {
                    this.DeviceService.removeSpike(this.spikeData);
                });
            } else {
                this.Messages.error('Error removing spike: difference between start and end must be less than one week.');
            }
        }

        private createSeriesData(data: DeviceDataResult, unit: DeviceDataUnit): any[] {
            return (unit.apiUnit.toString() === 'PULSE')
                ? this.getRawSeriesForData(data)
                : this.getSeriesForData(data[unit.apiUnit.toLowerCase()], unit);
        }

        private getRawSeriesForData(data: DeviceDataResult): object[] {
            const series = [];
            for (let i = 0; i < data['pulse'].timestamps.length; i++) {
                if ((data['interpolated'] && !data['interpolated'].values[i]) || this.pulseMeterDataParameters.interval === '15min') {
                    series.push([
                        data['pulse'].timestamps[i],
                        data['pulse'].values[i]
                    ]);
                }
            }
            return series;
        }

        private getSeriesForData(meterData: DeviceData, unit: DeviceDataUnit): object[] {
            const series = [];
            for (let i = 0; i < meterData.timestamps.length; i++) {
                series.push([
                    meterData.timestamps[i],
                    unit.scale ? Number((meterData.values[i] * unit.scale).toFixed(2)) : meterData.values[i]
                ]);
            }
            return series;
        }

        private resetMeterDataParameters(min: moment.Moment, max: moment.Moment) {
            if (this.modbusMeterDataParameters.interval === '1min') {
                    this.modbusMeterDataParameters.start = min;
                    this.modbusMeterDataParameters.end = max;
                    this.pulseMeterDataParameters.start = min;
                    this.pulseMeterDataParameters.end = max;
                    this.getDeviceDataConfig();
            } else {
                this.setFifteenMinuteIntervalOptions();
                this.pulseMeterDataParameters = this.getFifteenMinuteIntervalOptions();
            }
        }

        private setFifteenMinuteIntervalOptions() {
            this.modbusMeterDataParameters.interval = '15min';
            this.modbusMeterDataParameters.start = moment().subtract(2, 'months');
            this.modbusMeterDataParameters.end = moment();
            if (this.isModbusMeter(this.collector.collectorClass)) {
                this.fifteenMinuteParameterOptions = {
                    interval: '15min',
                    start: moment().subtract(2, 'months'),
                    end: moment()
                };
            } else {
                this.fifteenMinuteParameterOptions = {
                    interval: '15min',
                    start: moment().subtract(6, 'months'),
                    end: moment()
                };
            }
        }

        private getFifteenMinuteIntervalOptions() {
            return angular.copy(this.fifteenMinuteParameterOptions);
        }

        private redPlotBandsForNulls(deviceData: DeviceData): __Highcharts.PlotBands[] {
            const plotBands: __Highcharts.PlotBands[] = [];
            let plotBandStartTime: number = null;

            for (let i = 0; i < deviceData.timestamps.length; i++) {
                if (plotBandStartTime) {
                   if (deviceData.values[i] !== null) {
                       const id = 'pb' + plotBandStartTime;
                       plotBands.push({ id, from: plotBandStartTime, to: deviceData.timestamps[i], color: DeviceDataComponent.color.missingRawData });
                       this.plotBandList.push(id);
                       plotBandStartTime = null;
                   }
                } else {
                    if (deviceData.values[i] === null) {
                        plotBandStartTime = deviceData.timestamps[i - 1];
                    }
                }
            }
            return plotBands;
        }

        private getMoreApiParameters(): object {
            return {
                multi: true,
                shouldNotGetIntervalData: true
            }
        }
    }

    interface Interval {
        name: string;
        resolution: string;
    }

    interface DeviceDataParameters {
        interval: string;
        start: moment.Moment;
        end: moment.Moment;
    }

    interface MeterDataParameters {
        points: MultiPhasePoint[];
        interval: string;
        start: moment.Moment;
        end: moment.Moment;
        units: aq.common.models.MeterDataUnit[];
    }

    interface DeviceDataResult {
        [unit: string]: DeviceData;
    }

    interface DeviceData {
        timestamps: number[];
        values: number[];
    }

    interface MultiPhasePoint extends aq.common.models.Point {
        dataScope: DataScope;
    }

    enum DataScope {
        FULL_METER,
        SINGLE_POINT
    }

    export enum CollectorType {
        RAIL_350v_MODBUS = 'RAIL_350v_MODBUS',
        AQUICORE_METER = 'AQUICORE_METER'
    }

    enum MultiPhaseUnits {
        AMPERAGE = 'AMPERAGE',
        VOLTAGE = 'VOLTAGE',
        POWER_FACTOR = 'POWER_FACTOR',
        LINE_VOLTAGE = 'LINE_VOLTAGE'
    }

    angular.module('deviceManagement')
        .component('deviceData', {
            templateUrl: 'app/deviceManagement/device/components/deviceData/deviceData.html',
            bindings: {
                building: '<',
                collector: '<',
                device: '<',
                links: '<',
                points: '<'
            },
            controller: aq.deviceManagement.DeviceDataComponent
        });
}
