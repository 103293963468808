angular.module('charting').filter('forBillData', function($filter) {

    function getValue(val, unit, dataElem, sizes) {
        if (unit.field === 'intensity' && !_.isNull(val)) {
            return val / (sizes[dataElem.id] || 1);
        } else {
            return val;
        }
    }

    return function(data, callUnit, time, subject, disableLegendUpdate, colors, sizes, lines, valuesField) {
        if (_.isEmpty(data)) {
            return {
                data: [],
                unit: callUnit
            }
        }
        var maxValue = 0;
        _.each(data, function(one) {
            _.each(one.values, function(value) {
                maxValue = Math.max(maxValue, getValue(value, callUnit, one, sizes));
            });
        });
        var unit = $filter('bestFitUnit')(maxValue, callUnit);
        var convertedMaxValue = maxValue;
        var index = 0;
        var ticks = $filter('period')(time);
        var retData = _.map(data, function(one, key) {
            var elem: any = {};
            elem.label = disableLegendUpdate ? one.name : one.name + "= 0.00";
            elem.name = one.name;
            elem.xaxis = 1;
            elem.yaxis = 1;
            elem.color = colors ? colors[key] : $filter('color')(one.name, one.route, one.id);
            if (lines || one.line) {
                elem.lines = {
                    show: true,
                    fill: false,
                    lineWidth: 2
                };
                elem.bars = {
                    show: false
                }
                elem.stack = false;
            }
            elem.data = _.chain(ticks)
                .zip(one.values)
                .filter(function(one) {
                    return !_.isUndefined(one[0]);
                }).map(function(val) {
                    var x = val[0];
                    var y = getValue(val[1], unit, one, sizes);
                    return [x, y];
                }).value();
            return elem;
        });
        return {
            data: retData,
            max: maxValue
        }
    }
});
