namespace aq.components {

    export interface BackButtonData {
        state: string;
        stateData?: any;
        isDataSetOnSaveOnly?: boolean;
        onBackAction?: () => void;
    }

    export class EntityEditor {
        private entityForm;
        private onSave;
        private showBack = true;
        private backAction: BackButtonData;
        private backActionCalculated: BackButtonData;
        private hideErrorMsg: boolean;

        /* @ngInject */
        constructor(private $mdSticky, private Auth: aq.services.Auth) {
            this.backActionCalculated = { state: '^' };
            this.showBack = (this.backAction
                && (this.backAction.state === 'aq.utilityBudgets.utilityaccounts'
                || this.backAction.state === 'aq.deviceManagement.building'
                || this.backAction.state === 'aq.properties.buildings.alerts'
                || this.backAction.state === 'aq.admin.accounts.accounts'
                || this.backAction.state === 'aq.tenantbilling.building.tenants'))
                || !Auth.hasFunctionality('App Restructure');
        }

        // Reading on component lifecycle hooks https://toddmotto.com/angular-1-5-lifecycle-hooks

        $onInit() {
            if (!this.backAction) {
                return;
            }
            this.backActionCalculated.state = this.backAction.state;
            this.backActionCalculated.isDataSetOnSaveOnly = this.backAction.isDataSetOnSaveOnly;
            if (!this.backAction.isDataSetOnSaveOnly) {
                this.backActionCalculated.stateData = this.backAction.stateData;
            }
        }

        $onChanges(changes) {
            if (changes.nonFormData) {
                this.entityForm.$setDirty();
            }
        }

        $postLink() { }

        public save() {
            this.onSave();
            this.entityForm.$setPristine();
            if (this.backActionCalculated.isDataSetOnSaveOnly) {
                this.backActionCalculated.stateData = this.backAction.stateData;
            }
        }

        public onBackButtonClick() {
            if (this.backAction.onBackAction != null) {
                this.backAction.onBackAction();
            }
        }
    }

    angular
        .module('aq.ui')
        .component('entityEditor', {
            templateUrl: 'app/common/components/EntityEditor/EntityEditor.html',
            controller: EntityEditor,
            controllerAs: 'vm',
            bindings: {
                entity: '<',
                onSave: '&',
                onDelete: '&?',
                disableDeleteCondition: '&?',
                disableDeleteTooltip: '@?',
                entityIcon: '<',
                entityLabel: '<',
                showBack: '<?',
                backAction: '<?',
                actions: '<', // aq.common.models.EntityMenuAction[]
                nonFormData: '<?',
                hideErrorMsg: '<?'
            },
            transclude: {
                'editor': 'section',
                'actionCardContent': '?actionCardContent',
                'aside': '?aside'
            }
        });
};
