'use strict';

angular
    .module('aq.admin.statistics.schedules', [])
    .config(($stateProvider, RestangularProvider) => {
        $stateProvider
            .state('aq.admin.statistics.schedules', {
                url: '/schedules',
                templateUrl: 'app/admin/statistics/schedules/controllers/accounts/accounts.html',
                controller: 'AccountsCtrl'
            })
            .state('aq.admin.statistics.schedules.buildings', {
                url: '/:accountId/buildings',
                templateUrl: 'app/admin/statistics/schedules/controllers/buildings/buildings.html',
                controller: 'BuildingsCtrl'
            })
            .state('aq.admin.statistics.schedules.buildings.data', {
                url: '/:buildingId/data',
                templateUrl: 'app/admin/statistics/schedules/controllers/data/data.html',
                controller: 'DataCtrl'
            });

        RestangularProvider.addElementTransformer('buildings', true, (elem) => {
            elem.addRestangularMethod('metrics', 'get', 'metrics');
            elem.addRestangularMethod('data', 'get', 'data');
            return elem;
        });
    });
