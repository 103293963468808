namespace aq.properties {
    export class VerifyCommissioningModalCtrl extends aq.common.Controllers.ModalCtrl {
        private dataCommissionDate: string | Date = moment().format('YYYY-MM-DD');
        private sendNotifications: Boolean = true;
        /* @ngInject */
        constructor(
            protected $scope: ng.IScope,
            protected $mdDialog: ng.material.IDialogService,
            private Messages: aq.services.Messages,
            private building: aq.common.models.Building,
            private RestangularV3: restangular.IService
        ) {
            super($scope, $mdDialog);
            this.building = building;
        }

        save() {
            const cDate = moment(this.dataCommissionDate);
            if (!cDate.isValid()) {
                return alert('Invalid Date');
            }
            return this.RestangularV3
                .one('buildings', this.building.id)
                .customPOST({dataCommissionDate: cDate.toISOString(), sendNotifications: this.sendNotifications}, 'data-commission')
                .then((response) => {
                    this.Messages.success('Successfully updated commission date.');
                    this.$mdDialog.hide();
                }).catch(() => {
                    this.Messages.error('Unable to update data commission date.');
                });
        }
    }
    angular
    .module('properties')
    .controller('VerifyCommissioningModalCtrl', VerifyCommissioningModalCtrl);
 }
