namespace aq.utilityBudgets {

    enum Step {
        UTILITY_ACCOUNTS = 0,
        UTILITY_SERVICES = 1,
        SHARED_BUILDINGS = 2,
        DONE = 3
    }

    export class ManualUtilityAccountWizardCtrl extends aq.common.Controllers.ModalCtrl {
        public utilityCompany: string;
        public utilityCompanyUrl: string;
        public utilityAccounts: string[];
        public selectedBuildingViewItems: {
            [accountNumber: string]:  aq.common.models.NamedItem[]
        };
        public measureTypes: aq.utilityBudgets.MeasureType[];
        public isAddAnother: boolean;
        public isCreating: boolean;
        public isIntercomEnabled: boolean;
        public filterTerm: string;
        public accountServiceMap: {
            [account: string]: {
                [type: string]: boolean;
            }
        };

        private intercom: any;
        private currentStep: number;
        private readonly mode: UtilityAccountWizardMode = 'manual';

        /* ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private $mdStepper,
            private RestangularV3: restangular.IService,
            private UtilityServiceHelper: UtilityServiceHelper,
            private Messages,
            private utilityCompanies:  aq.common.models.NamedItem[],
            private buildingViewItems: aq.common.models.NamedItem[],
            private accountId: number,
            private buildingId: number,
            private $window: ng.IWindowService,
            private $q: ng.IQService,
            private Segment: aq.services.SegmentService
        ) {
            super({}, $mdDialog);
            this.init();
            this.intercom = this.$window['Intercom'];
            if (this.intercom) {
                this.isIntercomEnabled = true;
            }
        }
        public init() {
            this.utilityCompany = null;
            this.utilityCompanyUrl = null;
            this.utilityAccounts = [];
            this.selectedBuildingViewItems = {};
            this.measureTypes = this.UtilityServiceHelper.getMeasureTypes();
            this.isAddAnother = false;
            this.filterTerm = '';
            this.currentStep = 0;
            this.accountServiceMap = {};
        }
        public onAddUtilityAccount(item: string) {
            this.selectedBuildingViewItems[item] = [];
            this.accountServiceMap[item] = this.getEmptyServiceMap();
        }
        public getEmptyServiceMap() {
            const serviceMap = {};
            _.each(this.measureTypes, (type: MeasureType) => {
                serviceMap[type.value] = false;
            });
            return serviceMap;
        }
        public next() {
            if (this.currentStep == Step.SHARED_BUILDINGS) {
                this.create()
                    .then((result) => {
                        this.currentStep++;
                        this.$mdStepper('utility-account-wizard').next();
                    });
            } else if (this.currentStep == Step.DONE) {
                this.done();
            } else {
                this.currentStep++;
                this.$mdStepper('utility-account-wizard').next();
            }
        }
        public previous() {
            this.$mdStepper('utility-account-wizard').back();
            this.currentStep = Math.max(0, this.currentStep - 1);
        }
        public create() {
            this.Segment.trackEvent('Utility Accounts:Manual Utility Account Creation');
            this.isCreating = true;
            const requests = [];
            _.each(this.utilityAccounts, (accountNumber) => {
                const utlityAccountRequest: UtilityAccountRequest = {
                    accountNumber,
                    utilityCompany: parseInt(this.utilityCompany),
                    sharedBuildingIds: _.map(this.selectedBuildingViewItems[accountNumber], (building: aq.common.models.NamedItem) => building.id),
                    services: this.findAccountServices(accountNumber)
                };
                const request = this.RestangularV3
                    .all('utility-accounts')
                    .post(utlityAccountRequest, { buildingId: this.buildingId });
                requests.push(request);
            });
            return this.$q.all(requests)
                .catch((err) => {
                    this.Messages.error('Error occurred while creating utility account, please contact your Administrator');
                })
                .finally(() => {
                    this.isCreating = false;
                });
        }
        public done() {
            if (this.isAddAnother) {
                this.init();
                this.$mdStepper('utility-account-wizard').goto(Step.UTILITY_ACCOUNTS);
            } else {
                this.hide();
            }
        }
        public confirmCancel(event) {
            const confirm = this.$mdDialog.confirm()
                .title('Do you want to exit wizard? Your progress will be lost.')
                .ariaLabel('Exit Wizard')
                .targetEvent(event)
                .ok('Exit')
                .cancel('Stay');
            (confirm as any).multiple(true);
            this.$mdDialog.show(confirm).then(() => {
                this.cancel();
            });
        }
        public isValidServices() {
            return _.every(this.utilityAccounts, (account: string) => this.isAccountAssignedToService(account));
        }
        public isAccountAssignedToService(account: string) {
            return _.some(this.measureTypes, (type: MeasureType) => {
                return this.accountServiceMap[account] && this.accountServiceMap[account][type.value] == true;
            });
        }
        public findAccountServices(account: string) {
            const servicesMap = this.accountServiceMap[account];
            const types = _.filter(this.measureTypes, (type: MeasureType) => servicesMap[type.value] == true);
            return _.map(types, (type: MeasureType) => type.value);
        }
        public onSelectedBuildingsChange(buildings, accountNumber) {
            this.selectedBuildingViewItems[accountNumber] = buildings;
        }
        public isDisableNext() {
            switch (this.currentStep) {
                case Step.UTILITY_ACCOUNTS:
                    return !this.utilityAccounts || this.utilityAccounts.length == 0;
                case Step.UTILITY_SERVICES:
                    return !this.isValidServices();
                default:
                    return false;
            }
        }
        public isShowBack() {
            return this.currentStep > Step.UTILITY_ACCOUNTS && this.currentStep < Step.DONE;
        }
        public isLastStep() {
            return this.currentStep == Step.DONE;
        }
        public openChat() {
            this.intercom('showMessages');
            this.$mdDialog.hide();
        }
        public getNextButtonTitle() {
            switch (this.currentStep) {
                case Step.SHARED_BUILDINGS:
                    return 'Next';
                case Step.DONE:
                    return 'Done';
                default:
                    return 'Next';
            }
        }
        public getFilteredCompanies() {
            return _.filter(this.utilityCompanies, (utilityCompany: aq.common.models.NamedItem) => {
                return !this.filterTerm || utilityCompany.name && utilityCompany.name.toLowerCase().indexOf(this.filterTerm.toLowerCase()) > -1;
            });
        }
    }
    angular.module('aq.utilityBudgets').controller('ManualUtilityAccountWizardCtrl', ManualUtilityAccountWizardCtrl);
}
