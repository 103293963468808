namespace aq.properties {

    export class AddOccupancyModalCtrl extends aq.common.Controllers.ModalCtrl {
        /* @ngAnnotate */
        constructor(
            protected $scope: any,
            protected $mdDialog,
            private buildingOccupancy
        ) {
            super($scope, $mdDialog);
            this.$scope.buildingOccupancy = buildingOccupancy;
        }

    }
    angular
        .module('properties')
        .controller('AddOccupancyModalCtrl', AddOccupancyModalCtrl);
}
