'use strict';
declare const add: Function;
declare const getBuildingData: Function;

angular
.module('accounts')
.controller('CompetitionCtrl',
    function ($stateParams, $scope, $state, Messages, Errors, $filter, $q, $modal) {

        $scope.confirm = {
            value: '',
            disabled: true
        };

        $scope.editable = function () {
            return $scope.status === 'PLANNED';
        };

        $scope.canEnd = function () {
            return $scope.status === 'PLANNED' || $scope.status === 'RUNNING';
        };

        $scope.hasCompleted = function () {
            return $scope.status === 'COMPLETED';
        };

        $scope.bulkMode = function () {
            return ($stateParams.competitionId && $scope.competition.id) ? false : true;
        };

        $scope.disableCompetitionDates = function () {
            return !$scope.editable() || (!$scope.competition.baselineStartDate || !$scope.competition.baselineEndDate);
        };

        $scope.$watch('competition', function (competition) {
            if (!competition) return;
            if (competition.id && (competition.id != $stateParams.competitionId)) {
                $scope.loadCompetition();
            }
            $scope.setCompetitionStatus(competition);
            $scope.editable();
            if (competition.id) {
                getBuildingData();
            }
        });

        $scope.$watch('confirm.value', function (confirmation) {
            if (!confirmation) return;
            $scope.confirm.disabled = (confirmation === 'END' ? false : true);
        });

        $scope.saveCompetition = function () {
            if (!$scope.competition) return;
            if ($scope.competition.id) {
                return updateCompetition();
            } else {
                return createCompetition();
            }
        };

        $scope.addBuilding = function (building) {
            $scope.competition.buildings.push(building);
            if (!$scope.bulkMode()) $scope.saveCompetition();
        };

        $scope.disableAddBuildingButton = function(building) {
            if (!building) return false;
            let invalidBuilding = false;
            _.each($scope.competition.buildings, function(obj) {
                if (building.id == obj.id) invalidBuilding = true;
            });
            return $scope.buildings.length == 0 || invalidBuilding;
        };

        $scope.removeBuilding = function (building) {
            _.withoutInline($scope.competition.buildings, building);
            if (!$scope.bulkMode()) $scope.saveCompetition();
        };

        $scope.updateStartDate = function () {
            $scope.competition.startDate = formatDate($scope.competition.startDate);
            if ($scope.competition.endDate && isDateSameOrAfterDate($scope.competition.startDate, $scope.competition.endDate)) {
                $scope.competition.endDate = add($scope.competition.startDate, 1);
            }
            if (!$scope.bulkMode()) return $scope.saveCompetition();
        };

        $scope.updateEndDate = function () {
            $scope.competition.endDate = formatDate($scope.competition.endDate);
            if ($scope.competition.startDate && isDateSameOrBeforeDate($scope.competition.endDate, $scope.competition.startDate)) {
                $scope.competition.startDate = subDate($scope.competition.endDate, 1);
            }
            if (!$scope.bulkMode()) return $scope.saveCompetition();
        };

        $scope.updateBaselineStartDate = function () {
            $scope.competition.baselineStartDate = formatDate($scope.competition.baselineStartDate);
            if (isDateSameOrAfterDate($scope.competition.baselineStartDate, $scope.competition.baselineEndDate)) {
                $scope.competition.baselineEndDate = addDate($scope.competition.baselineStartDate, 1);
                $scope.minCompetitionStart = $scope.competition.baselineEndDate;
                $scope.minCompetitionEnd = addDate($scope.competition.baselineEndDate, 1);
            }
            if ($scope.competition.startDate && isDateSameOrAfterDate($scope.competition.baselineEndDate, $scope.competition.startDate)) {
                $scope.competition.startDate = addDate($scope.competition.baselineEndDate, 1);
                if ($scope.competition.endDate && isDateSameOrAfterDate($scope.competition.startDate, $scope.competition.endDate)) {
                    $scope.competition.endDate = addDate($scope.competition.startDate, 1);
                }
            }
            getBuildingData();
            if (!$scope.bulkMode()) return $scope.saveCompetition();
        };

        $scope.updateBaselineEndDate = function () {
            $scope.competition.baselineEndDate = formatDate($scope.competition.baselineEndDate);
            $scope.minCompetitionStart = $scope.competition.baselineEndDate;
            $scope.minCompetitionEnd = addDate($scope.competition.baselineEndDate, 1);
            if (isDateSameOrBeforeDate($scope.competition.baselineEndDate, $scope.competition.baselineStartDate)) {
                $scope.competition.baselineStartDate = subDate($scope.competition.baselineEndDate, 1);
            }
            if ($scope.competition.startDate && isDateSameOrAfterDate($scope.competition.baselineEndDate, $scope.competition.startDate)) {
                $scope.competition.startDate = addDate($scope.competition.baselineEndDate, 1);
                if ($scope.competition.endDate && isDateSameOrAfterDate($scope.competition.startDate, $scope.competition.endDate)) {
                    $scope.competition.endDate = addDate($scope.competition.startDate, 1);
                }
            }
            if (!$scope.bulkMode()) return $scope.saveCompetition();
        };

        function createCompetition () {
            $scope.competition.account = $scope.account.id;
            $scope.competition.startDate = formatDate($scope.competition.startDate);
            $scope.competition.endDate = formatDate($scope.competition.endDate);
            $scope.competition.baselineStartDate = formatDate($scope.competition.baselineStartDate);
            $scope.competition.baselineEndDate = formatDate($scope.competition.baselineEndDate);
            return $scope.account.post('competitions', $scope.competition, { inflate: 'buildings' })
                .then(function (result) {
                    _.extend(result, result.model);
                    _.extend($scope.competition, result);
                    $scope.competitions.push($scope.competition);
                    $scope.loadCompetition();
                    getBuildingData();
                    Messages.success('Competition saved successfully.');
                }, Errors.forCRUD('CREATE'));
        }

        function updateCompetition () {
            $scope.competition.startDate = formatDate($scope.competition.startDate);
            $scope.competition.endDate = formatDate($scope.competition.endDate);
            $scope.competition.baselineStartDate = formatDate($scope.competition.baselineStartDate);
            $scope.competition.baselineEndDate = formatDate($scope.competition.baselineEndDate);
            return $scope.competition.put().then(function() {
                getBuildingData();
                Messages.success('Competition updated successfully.');
            }, Errors.forPromise());
        }

        function formatDate (date) {
            return moment(date).format('YYYY-MM-DD');
        }

        function showModal (template) {
            const modal = $modal({
                templateUrl: template,
                persist: true,
                show: false,
                scope: $scope
            });
            modal.$promise.then(modal.show);
        }

        function addDate(date, days) {
            date = moment(date).add(days, 'days');
            date = formatDate(date);
            return date;
        }

        function subDate(date, days) {
            date = moment(date).subtract(days, 'days');
            date = formatDate(date);
            return date;
        }

        function isDateSameOrAfterDate (date, dateToCompare) {
            const same = moment(date).isSame(moment(dateToCompare), 'day');
            const after = moment(date).isAfter(moment(dateToCompare));
            return same || after;
        }

        function isDateSameOrBeforeDate (date, dateToCompare) {
            const same = moment(date).isSame(moment(dateToCompare), 'day');
            const before = moment(date).isBefore(moment(dateToCompare));
            return same || before;
        }

        $scope.showEndCompetitionModal = function() {
            showModal('app/accounts/competitions/modal/endCompetitionModal.html');
        };

        $scope.showDeleteCompetitionModal = function() {
            showModal('app/accounts/competitions/modal/deleteCompetitionModal.html');
        };

        $scope.endCompetition = function (dismiss) {
            $scope.competition.endDate = formatDate(moment());
            return $scope.competition.put().then(function(competition) {
                dismiss();
                $scope.setCompetitionStatus(competition);
                Messages.success('Competition updated successfully.');
            }, Errors.forPromise());
        };

        $scope.deleteCompetition = function (dismiss) {
            return $scope.competition.remove().then(function() {
                _.withoutInline($scope.competitions, $scope.competition);
                $scope.competition = null;
                dismiss();
                Messages.success('Competition deleted successfully.');
                $state.transitionTo('aq.accounts.competitions');
                if ($scope.competitions.length > 0) { 
                    $scope.setCompetitionValue(_.first($scope.competitions)); 
                } else { 
                  $scope.setCompetitionValue(null); 
                }
            }, Errors.forCRUD('DELETE'));
        };

        function getBuildingData() {
            const weekEndDay = moment().endOf('week');
            const format = 'YYYY-MM-DDTHH:mm:ssZ';
            const params = {
                interval: '1d',
                measure: 'ENERGY',
                start: moment($scope.competition.baselineStartDate).format(format),
                end: moment(weekEndDay).format(format)
            };

            $scope.account.customGET('buildings/data', params).then(function(buildings) {
                $scope.competition.buildings = buildings.filter(function(building) {
                    return _.findById($scope.competition.buildings, building.id);
                }).map(function(building) {
                    const buildingObj = _.findById($scope.competition.buildings, building.id);
                    buildingObj.energy = mapBuildingData(building.values.energy);
                    _.extend(building, buildingObj);
                    return building;
                });
            }, Errors.forPromise());


        function mapBuildingData(values) {
            return _.map(values, function (value, index) {
                return { 'index': index, 'reading': value };
            });
        }

    }
});
