'use strict';

angular
    .module('aq.admin.meters.management', ['ui.router', 'restangular', 'aq.ui.loading', 'aq.services.errors'])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.admin.meters.management', {
                url: '/management',
                templateUrl: 'app/admin/meters/management/main.html',
                controller: 'AdminMeterStatusCtrl',
                data: {
                    breadcrumb: 'Status',
                    icon: '.aq-icons-meter-management'
                },
                resolve: {
                    accounts: (Restangular, waitForAuthToken) => {
                        return Restangular
                            .all('accounts')
                            .get('queryAllAccounts', { inflate: 'buildings' });
                    }
                },
                reloadOnSearch: false
            });
    });
