var aq;
(function (aq) {
    var deviceManagement;
    (function (deviceManagement) {
        var AddTenant = /** @class */ (function (_super) {
            __extends(AddTenant, _super);
            /* @ngInject */
            function AddTenant(Auth, $mdDialog) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.Auth = Auth;
                _this.newTenant = { name: '' };
                _this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'unit' : 'tenant';
                return _this;
            }
            return AddTenant;
        }(aq.common.Controllers.ModalCtrl));
        deviceManagement.AddTenant = AddTenant;
        angular
            .module('deviceManagement')
            .controller('AddTenant', AddTenant);
    })(deviceManagement = aq.deviceManagement || (aq.deviceManagement = {}));
})(aq || (aq = {}));
