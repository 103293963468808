angular.module('aq.publicDisplay', ['dashboard'])
    .config((RestangularProvider, $stateProvider, $locationProvider) => {

        $stateProvider.state('aq.publicDisplay', {
            abstract: true,
            url: '/accounts/:accountId/public-display',
            templateUrl: 'app/dashboard/main/main.html',
            controller: 'PublicDisplayMainCtrl as mainCtrl',
            data: {
                appName: 'Public Display'
            },
            resolve: {
                account(
                    Restangular: restangular.IService,
                    $stateParams,
                    waitForAuthToken
                ): ng.IPromise<aq.common.models.Account> {
                    return Restangular
                        .one('accounts', $stateParams.accountId)
                        .get({ single: true });
                },
                dashboards(account: aq.common.models.Account, waitForAuthToken): ng.IPromise<aq.dashboard.models.Dashboard[]> {
                    return account
                        .all('dashboards')
                        .getList({ publicDisplay: true });
                }
            }
        }).state('aq.publicDisplay.configurable', {
            url: '/configurable/:dashboardId',
            templateUrl: 'app/publicDisplay/configurable/configurable.html',
            controller: 'PublicDisplayConfigurableCtrl as configurableCtrl',
            resolve: {
                dashboard(
                    account: aq.common.models.Account,
                    $stateParams: ng.ui.IStateParamsService,
                    dashboards,
                    $state,
                    PublicDisplaySelectService
                ) {
                    const { dashboardId } = $stateParams;
                    const redirect = PublicDisplaySelectService.getRedirect(dashboardId, account, dashboards);
                    if (redirect) {
                        $state.go(redirect.state, redirect.params);
                        return;
                    }
                    return account
                        .one('dashboards', dashboardId)
                        .get({ single: true, inflate: 'publicDisplay,configuration' })
                        .then((dashboard) => {
                            try {
                                dashboard.configuration.json = JSON.parse(dashboard.configuration.json);
                            } catch (e) {
                                dashboard.configuration.json = {};
                            }
                            return dashboard;
                        });
                }
            }
        }).state('aq.publicDisplay.noAvailableDisplays', {
            url: '/unavailable/',
            templateUrl: 'app/publicDisplay/noAvailableDisplays/noAvailableDisplays.html'
        });

        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });
    })
    .run((UserService) => {
        UserService.init();
    });
