namespace aq.admin {
    export class AddBuildingCtrl {
        public searchText;
        public responseList;
        public buildingsToAddArray;
        /* @ngInject */
        constructor(
            public BuildingService: aq.services.BuildingService,
            public $mdDialog,
            public Restangular,
            public account,
            public sharedBuildings,
            private Messages
        ) {
            this.buildingsToAddArray = [];
        }

        selectedItemChange(item) {
            if (item && _.filter(this.buildingsToAddArray, {'id': item.id})) {
                this.buildingsToAddArray.push(item);
            }
            this.buildingsToAddArray = _.uniqBy(this.buildingsToAddArray, 'id');
            this.searchText = '';
        }

        getAllAccountBuildingIds() {
            return this.account.buildings.concat(this.account.sharedBuildings);
        }

        queryBuildings(searchText) {
            return this.Restangular.one('internal').customGET('buildings', {
                inflate: 'account',
                page: 1,
                pageSize: 10,
                search: searchText,
                asc: true
            }).then((result) => {
                return _.filter(result.buildings, (b) => !_.includes(this.getAllAccountBuildingIds(), b.id));
            });
        };

        removeSharedBuilding(building) {
            _.remove(this.buildingsToAddArray, b => building.id === b.id);
        }

        addBuildings() {
            const buildingsToAddIds = this.buildingsToAddArray.map(building => building.id);
            this.BuildingService.shareBuildings(this.account.id, buildingsToAddIds)
            .then((response) => {
                _.each(this.buildingsToAddArray, (b) => this.sharedBuildings.push(b));
                this.$mdDialog.hide();
                this.Messages.success('Buildings shared');
            })
            .catch((error) => {
                this.Messages.error('Unable to share buildings');
            });
        }

        cancel() {
            this.buildingsToAddArray = [];
            this.$mdDialog.hide();
        }

    }

    angular
        .module('aq.admin.accounts.accounts')
        .controller('AddBuildingCtrl', AddBuildingCtrl);
}
