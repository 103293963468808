namespace aq.tenantbilling {
    export class MonthlyBillingService {
        public approvedStatuses = ['APPROVED', 'APPROVAL_ACTIONS_COMPLETED'];
        private DATE_FORMAT = 'YYYY-MM-DD';
        private draftStatuses = ['SETUP'];
        /* @ngInject */
        constructor(
            private DataStore: aq.common.DataStore,
            private RestangularV3: restangular.IService,
            private Restangular: restangular.IService
        ) { }

        public isApprovedStatus(status: string) {
            return _.includes(this.approvedStatuses, status);
        }
        public isDraftStatus(status: string) {
            return _.includes(this.draftStatuses, status);
        }

        public initMonthlyTenantBilling(buildingId: string): MonthlyTenantBilling {
            const monthlyBilling = <MonthlyTenantBilling>{};

            monthlyBilling.building = buildingId;

            return monthlyBilling;
        }

        public initDefaultValues(monthlyBilling: MonthlyTenantBilling, tenantServices: TenantService[], lastBilling: LastBillingMap, timeZoneId: string) {
            monthlyBilling.invoiceDate = moment().tz(timeZoneId).toDate();
            if (!monthlyBilling.id) {
                monthlyBilling.startReadingDate = null;
            }
            _.each(tenantServices, (tenantService: TenantService) => {
                const bill: MonthlyTenantBilling = lastBilling[tenantService.id];
                if (!bill) {
                    return;
                }
                if (!monthlyBilling.startReadingDate || moment(bill.readingDate).isAfter(moment(monthlyBilling.startReadingDate))) {
                    monthlyBilling.startReadingDate = moment(bill.readingDate).toDate();
                }
            });
            monthlyBilling.name = this.getSuggestedMonthlyBillingName(monthlyBilling.invoiceDate, tenantServices);
            monthlyBilling.tenantServiceIds = _.map(tenantServices, (service) => {
                return service.id;
            });
            return monthlyBilling;
        }

        public getSuggestedMonthlyBillingName(invoiceDate, tenantServices: TenantService[]) {
            const measures = _(tenantServices).map((tenantService: TenantService) => {
                return tenantService.serviceType.charAt(0).toUpperCase() + tenantService.serviceType.slice(1).toLowerCase();
            }).uniq().join(', ');
            const month = moment(invoiceDate).format('MMMM');
            return `${month} - ${measures}`;
        }

        public create(monthlyBilling: MonthlyTenantBilling) {
            let copy = angular.copy(monthlyBilling);
            copy = this.transformForRequest(copy);
            return this.DataStore.create(this.RestangularV3.all('billings'), copy, { buildingId: monthlyBilling.building })
                .then((result) => {
                    return this.transformForView(result);
                });
        }

        public confirmRates(monthlyBilling: MonthlyTenantBilling) {
            return this.RestangularV3.one('billings', monthlyBilling.id)
                .customPOST({}, 'confirm-rates')
                .then((result) => {
                    result.parentResource = { route: '' };
                    result.route = 'billings';
                    return this.transformForView(result);
                });
        }

        public approve(monthlyBilling: MonthlyTenantBilling) {
            return this.RestangularV3.one('billings', monthlyBilling.id)
                .customPOST({}, 'approve')
                .then((result) => {
                    return this.transformForView(result);
                });
        }

        public modify(monthlyBilling: MonthlyTenantBilling) {
            return this.RestangularV3.one('billings', monthlyBilling.id)
                .customPOST({}, 'modify')
                .then((result) => {
                    return this.transformForView(result);
                });
        }

        public recalculate(monthlyBilling: MonthlyTenantBilling) {
            return this.RestangularV3.one('billings', monthlyBilling.id)
                .customPOST({}, 'recalculate', { t: Date.now() });
        }

        public save(monthlyBilling: MonthlyTenantBilling) {
            let copy: any = this.RestangularV3.copy(monthlyBilling);
            copy = this.transformForRequest(copy);
            return this.DataStore.update(copy)
                .then((result) => {
                    return this.transformForView(result);
                });
        }

        public updatePastMonthlyBilling(monthlyBilling: MonthlyTenantBilling, pastMonthlyBillingId: string) {
            return this.RestangularV3.one('billings', monthlyBilling.id)
                .customPOST({ pastMonthlyBillingId }, 'update-past-monthly-billing');
        }

        public get(monthlyBillingId) {
            return this.DataStore
                .get(
                    this.RestangularV3.one('billings', monthlyBillingId),
                    { t: Date.now() },
                    true
                )
                .then((result) => {
                    return this.transformForView(result);
                });
        }

        public getCalculating(monthlyBillingId) {
            return this.RestangularV3.one('billings', monthlyBillingId)
                .customGET('calculating', { t: Date.now() });
        }

        public getList(queryParams?: any) {
            if (!queryParams) {
                queryParams = {};
            }
            return this.DataStore.getList(this.RestangularV3.one(''), 'billings', queryParams)
                .then((monthlyBillings) => {
                    monthlyBillings.forEach((monthlyBilling) => {
                        this.transformForView(monthlyBilling);
                    });
                    return monthlyBillings;
                });
        }

        public delete(monthlyBilling: MonthlyTenantBilling) {
            return this.DataStore.delete(monthlyBilling);
        }

        public savePeriodTenantService(periodTenantService: PeriodTenantService) {
            const copy: any = angular.copy(periodTenantService);
            return this.RestangularV3.one('period-tenant-services', periodTenantService.id)
                .customPUT(copy)
                .then((result) => {
                    return result;
                });
        }

        public getInvoice(tenantInvoiceId) {
            return this.DataStore.get(this.RestangularV3.one('tenant-invoices', tenantInvoiceId), { t: Date.now() }, true);
        }

        public getInvoices(monthlyBilling: MonthlyTenantBilling) {
            return this.DataStore.getList(this.RestangularV3.one(''), 'tenant-invoices', { monthlyBillingId: monthlyBilling.id, t: Date.now() });
        }

        public getChargeValues(monthlyBilling: MonthlyTenantBilling) {
            return this.DataStore.getList(this.RestangularV3.one(''), 'tenant-charge-values', { monthlyBillingId: monthlyBilling.id, t: Date.now() });
        }

        public getPeriodTenantServices(monthlyBilling: MonthlyTenantBilling) {
            return this.DataStore.getList(this.RestangularV3.one(''), 'period-tenant-services', { monthlyBillingId: monthlyBilling.id, t: Date.now() });
        }

        public saveChargeValue(chargeValue: TenantChargeValue) {
            return this.DataStore.update(chargeValue);
        }

        public saveChargeValues(chargeValues: TenantChargeValue[], route) {
            const copy: any = this.RestangularV3.copy(chargeValues);
            copy.route = route;
            return this.DataStore.updateWithList(copy);
        }

        public getDependentChargeValues(chargeValue: TenantChargeValue) {
            return this.DataStore.getList(this.RestangularV3.one('tenant-charge-values', chargeValue.id), 'dependent-charges', {})
                .then((results) => {
                    return _.map(results, (relatedCharge) => {
                        relatedCharge.parentResource = { route: '' };
                        relatedCharge.route = 'tenant-charge-values';
                        return relatedCharge;
                    });
                });
        }

        public getChargeCategories(monthlyBilling: MonthlyTenantBilling) {
            return this.RestangularV3.one('billings', monthlyBilling.id)
                .getList('charge-categories');
        }

        public getIsManualMeterPresentForAnyService(tenantServiceIds, buildingId) {
            return this.RestangularV3
                .all('billings')
                .one('has-manual-meters')
                .get({
                    tenantServiceIds,
                    buildingId
                });
        }

        public saveApprovalActionSetup(approvalActionSetup) {
            const copy: any = this.RestangularV3.copy(approvalActionSetup);
            return this.DataStore.update(copy);
        }

        public saveBillingReportSetup(billingReportSetup) {
            if (billingReportSetup.multiFamilyName) {
                delete billingReportSetup["multiFamilyName"];
            }
            return this.DataStore.update(billingReportSetup);
        }

        public getUtilityBillsForPeriodTenantService(periodTenantService, building) {
            return this.DataStore.getList(
                this.RestangularV3.one('utility-bill-periods'), 'by-period-tenant-service',
                {
                    periodTenantServiceId: periodTenantService.id,
                    buildingId: building.id
                }
            );
        }

        public getTenantConsumption(accountId, buildingId, startDate, endDate, measure) {
            return this.Restangular.one('accounts', accountId).one('buildings', buildingId).customGET('tenants/metrics', {
                                start: moment(startDate).format('YYYY-MM-DDT00:00:00Z'),
                                end: moment(endDate).format('YYYY-MM-DDT00:00:00Z'),
                                interval: '1d',
                                measure
                            });
        }
        // ------------------------

        public transformForRequest(monthlyBilling: MonthlyTenantBilling) {
            monthlyBilling.startReadingDate = monthlyBilling.startReadingDate ? moment(monthlyBilling.startReadingDate).format(this.DATE_FORMAT) : null;
            monthlyBilling.readingDate = moment(monthlyBilling.readingDate).format(this.DATE_FORMAT);
            monthlyBilling.invoiceDate = moment(monthlyBilling.invoiceDate).format(this.DATE_FORMAT);
            return monthlyBilling;
        }

        public transformForView(monthlyBilling: MonthlyTenantBilling) {
            monthlyBilling.startReadingDate = monthlyBilling.startReadingDate ? moment(monthlyBilling.startReadingDate).toDate() : null;
            monthlyBilling.readingDate = moment(monthlyBilling.readingDate).toDate();
            monthlyBilling.invoiceDate = moment(monthlyBilling.invoiceDate).toDate();
            return monthlyBilling;
        }
    }

    angular
        .module('tenantBilling')
        .service('MonthlyBillingService', MonthlyBillingService);
}
