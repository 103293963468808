var aq;
(function (aq) {
    var tenantBilling;
    (function (tenantBilling) {
        var BulkUploadTenantCtrl = /** @class */ (function (_super) {
            __extends(BulkUploadTenantCtrl, _super);
            /* @ngInject */
            function BulkUploadTenantCtrl(Auth, $mdDialog, $mdStepper, Messages, RestangularV3, building, TenantService) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.Auth = Auth;
                _this.$mdDialog = $mdDialog;
                _this.$mdStepper = $mdStepper;
                _this.Messages = Messages;
                _this.RestangularV3 = RestangularV3;
                _this.building = building;
                _this.TenantService = TenantService;
                _this.step = 1;
                return _this;
            }
            BulkUploadTenantCtrl.prototype.downloadTemplate = function () {
                var csvColumns = [
                    'name',
                    'leaseId',
                    'primaryContact',
                    'phoneNumber',
                    'email',
                    'address',
                    'city',
                    'state',
                    'zipCode',
                    'country',
                    'size',
                    'leaseType'
                ];
                var csvContent = 'data:text/csv;charset=utf-8,';
                csvContent += csvColumns + "\n";
                var fileName = this.building.name + " - tenants upload";
                var link = document.createElement('a');
                link.setAttribute('href', encodeURI(csvContent));
                link.setAttribute('download', fileName);
                link.click();
            };
            BulkUploadTenantCtrl.prototype.next = function () {
                this.$mdStepper('bulkUploadStepper').goto(this.step++);
            };
            BulkUploadTenantCtrl.prototype.upload = function () {
                this.fileUploaded = true;
            };
            BulkUploadTenantCtrl.prototype.generatePreview = function () {
                var lines = this.uploadData.split(/\r\n|\n|\r/);
                var result = [];
                var headers = lines[0].split(',');
                lines.shift();
                lines.forEach(function (line) {
                    var obj = {};
                    var splitLine = line.split(',');
                    for (var j = 0; j < headers.length; j++) {
                        obj["" + headers[j]] = splitLine[j];
                    }
                    result.push(obj);
                });
                this.generateTenantCreationRequestObjects(result);
            };
            BulkUploadTenantCtrl.prototype.generateTenantCreationRequestObjects = function (json) {
                var _this = this;
                this.previewResponse = json.map(function (tenant) {
                    if (tenant.name === '' || tenant.leaseId === '') {
                        _this.$mdDialog.hide();
                        _this.Messages.error('Missing required fields');
                        throw new Error('Missing required fields');
                    }
                    var newTenant = __assign({}, tenant, { accountNumber: tenant.leaseId, building: _this.building.id });
                    return newTenant;
                });
                this.createTenants(this.previewResponse);
            };
            BulkUploadTenantCtrl.prototype.createTenants = function (tenants) {
                var _this = this;
                return this.TenantService.bulkCreate(tenants).then(function (response) {
                    _this.$mdDialog.hide();
                    _this.Messages.success('Successfully created tenants');
                }).catch(function (error) {
                    _this.$mdDialog.hide();
                    _this.Messages.error('Error creating tenants');
                });
            };
            return BulkUploadTenantCtrl;
        }(aq.common.Controllers.ModalCtrl));
        tenantBilling.BulkUploadTenantCtrl = BulkUploadTenantCtrl;
        angular
            .module('tenantBilling')
            .controller('BulkUploadTenantCtrl', BulkUploadTenantCtrl);
    })(tenantBilling = aq.tenantBilling || (aq.tenantBilling = {}));
})(aq || (aq = {}));
