var aq;
(function (aq) {
    var energyInsights;
    (function (energyInsights) {
        var PeakDemandService = /** @class */ (function (_super) {
            __extends(PeakDemandService, _super);
            /* @ngInject */
            function PeakDemandService(EnergyInsightsDataService, $q, $filter, Restangular, DataStore, Auth, $translate) {
                var _this = _super.call(this) || this;
                _this.EnergyInsightsDataService = EnergyInsightsDataService;
                _this.$q = $q;
                _this.$filter = $filter;
                _this.Restangular = Restangular;
                _this.DataStore = DataStore;
                _this.Auth = Auth;
                _this.$translate = $translate;
                return _this;
            }
            PeakDemandService.prototype.getSimpleTargetModel = function (building, measure, periodSearch, name) {
                var targetModel = new energyInsights.SimpleTargetModel();
                targetModel.title = this.$translate.instant('insight.Peak Demand');
                return targetModel;
            };
            PeakDemandService.prototype.getTargetModel = function (building, measure, periodSearch, account, name) {
                var _this = this;
                var metric;
                return this.getKwMetric(account).then(function (m) {
                    metric = m;
                    return _this.DataStore.getList(_this.Restangular
                        .one('accounts', account.id)
                        .one('buildings', building.id)
                        .one('Targets'), 'queryTargets', {
                        startDate: moment().subtract(2, 'year').format(),
                        endDate: moment().format(),
                        targetType: 'PEAK_DEMAND'
                    });
                }).then(function (target) {
                    return _this.EnergyInsightsDataService.getPeakData(building, periodSearch.start, periodSearch.end, metric, target, account, null);
                })
                    .then(function (peaks) {
                    return _this.calculateTargetModel(building, peaks, name);
                });
            };
            PeakDemandService.prototype.getGraphModel = function (building, measure, timePeriod) {
                return null;
            };
            PeakDemandService.prototype.getTableModel = function (building, measure, periodSearch) {
                return null;
            };
            PeakDemandService.prototype.formatTableModel = function () {
                throw new TypeError('This is a stub method');
            };
            // ----------------------
            PeakDemandService.prototype.getKwMetric = function (account) {
                return this.Restangular.one('accounts', account.id).customGET('queryRealUnits').then(function (allUnits) {
                    return _.find(allUnits, { unit: 'kW' });
                });
            };
            PeakDemandService.prototype.calculateTargetModel = function (building, peaks, name) {
                // Q&A
                var targetModel = new energyInsights.TargetModel();
                targetModel.title = name;
                var totals = {};
                var chartData = _.map(_.map(peaks, 'metric'), 'chartValue');
                var maxPeakIndex = chartData.indexOf(Math.max.apply(Math, chartData));
                targetModel.question = this.$translate.instant('insight.question.Peak') + " " + building.name + "?";
                targetModel.buildingId = building.id;
                if (maxPeakIndex !== -1) {
                    targetModel.answer = peaks[maxPeakIndex]['metric'].chartValue;
                    targetModel.formattedAnswer = targetModel.answer + " kW";
                    targetModel.when = this.$translate.instant('insight.when.Peak') + " " + moment.tz(peaks[maxPeakIndex].date.raw, building.timeZoneId).format('llll z'),
                        // update total row
                        totals.peak = {
                            tableValue: peaks[maxPeakIndex]['metric'].tableValue,
                            chartValue: peaks[maxPeakIndex]['metric'].chartValue
                        };
                    totals.target = {
                        tableValue: peaks[maxPeakIndex]['target'].tableValue,
                        chartValue: peaks[maxPeakIndex]['target'].chartValue
                    };
                    totals.corey = (peaks[maxPeakIndex]['metric'].chartValue <= peaks[maxPeakIndex]['target'].chartValue);
                    targetModel.target = peaks[maxPeakIndex]['target'].chartValue;
                    targetModel.showTarget = !_.isNull(targetModel.target);
                    targetModel.color = this.getColor(peaks[maxPeakIndex]['metric'].chartValue, peaks[maxPeakIndex].target.chartValue);
                    targetModel.iconColor = targetModel.color;
                    if (targetModel.target) {
                        totals.percent = this.getPercent(peaks[maxPeakIndex]['metric'].chartValue, peaks[maxPeakIndex]['target'].chartValue);
                        targetModel.tooltip = "<b>" + this.$translate.instant('insight.tooltip.Peak1') + "</b> <span style=\"color: " + targetModel.color + "\">\n                        " + this.$filter('number')(totals.percent, '0,0') + "%</span> <b>" + this.$translate.instant('insight.tooltip.Peak2') + "</b>\n                        " + peaks[maxPeakIndex].target.tableValue + "\n                        " + peaks[maxPeakIndex].target.unit;
                        targetModel.percentage = totals.percent;
                        targetModel.icon = this.getIcon(targetModel.answer, targetModel.target);
                        targetModel.formattedTarget = this.$translate.instant('insight.target.Target') + " " + this.$filter('number')(targetModel.target, '0,0') + " kW";
                    }
                }
                return this.$q.when(targetModel);
            };
            PeakDemandService.prototype.getIcon = function (peak, targetPeak) {
                var status = this.getStatus(peak, targetPeak);
                if (status === 'under') {
                    return 'check_box';
                }
                else if (status === 'warn') {
                    return 'warning';
                }
                else if (status === 'over') {
                    return 'error';
                }
            };
            PeakDemandService.prototype.getPercent = function (part, total) {
                if (total > 0) {
                    return (part / total) * 100;
                }
                else {
                    return 0;
                }
            };
            PeakDemandService.prototype.getStatus = function (maxPeak, targetPeak) {
                var ratio = maxPeak / targetPeak;
                if (maxPeak > targetPeak) {
                    return 'over';
                }
                else if ((1 - ratio) <= .2) {
                    return 'warn';
                }
                else {
                    return 'under';
                }
            };
            PeakDemandService.prototype.getColor = function (peak, targetPeak) {
                if (!targetPeak) {
                    return '#979797';
                }
                var status = this.getStatus(peak, targetPeak);
                switch (status) {
                    case 'under':
                        return '#7ACF46';
                    case 'warn':
                        return '#EFAF55';
                    case 'over':
                        return '#C33';
                    default:
                        return '';
                }
            };
            return PeakDemandService;
        }(energyInsights.InsightService));
        energyInsights.PeakDemandService = PeakDemandService;
        angular.module('energyInsights').service('PeakDemandService', PeakDemandService);
    })(energyInsights = aq.energyInsights || (aq.energyInsights = {}));
})(aq || (aq = {}));
