namespace aq.properties {
    export class WeatherNormalizationCtrl {
        public building;
        public weatherNormalization;
        public baselineYears;
        public baselineYear;
        public weatherNormalizationCalculated;
        public temperatureUnit;
        private appRestructure;
        constructor(
            public Messages,
            public Restangular,
            private RestangularV3: restangular.IService,
            private Auth
        ) {
            this.appRestructure = Auth.hasFunctionality('App Restructure');
        }

        $onInit() {
            this.weatherNormalizationCalculated = null;
        }

        calculateWeatherNormalizationCoefficients = (year): void => {
            if (!year) {
                return;
            }
            this.Restangular
                .one('accounts', this.building.account)
                .one('buildings', this.building.id)
                .customGET('calculateWeatherNormalization', { baselineYear: year })
                .then((result) => {
                    this.weatherNormalizationCalculated = true;
                    this.Messages.success(result.message);
                }).catch((error) => {
                    if (this.building.weatherNormalization != null && this.building.weatherNormalization.stationId != null) {
                        this.weatherNormalizationCalculated = false;
                    }
                    this.Messages.error(error.data.message);
                });
        }

        save() {
            this.RestangularV3.one('weather-normalization').customPUT(null, this.weatherNormalization.id, {
                recommendBalancePoints: this.weatherNormalization.recommendBalancePoints
            })
            .then((result) => {
                this.Messages.success('Updated Weather Normalization');
            }).catch((error) => {
                this.Messages.error('Error updating Weather Normalization');
            });
        }
    }

    angular
        .module('properties')
        .component('weatherNormalization', {
            controller: WeatherNormalizationCtrl,
            controllerAs: 'vm',
            templateUrl: 'app/properties/building/weatherNormalization/weatherNormalization.html',
            bindings: {
                weatherNormalization: '<',
                baselineYears: '<',
                building: '<',
                temperatureUnit: '<'
            }
        });
}
