namespace aq.reportCenter {
    export class BuildingReportsCtrl {
        /* @ngInject */
        constructor(
            private $scope,
            private Errors,
            private Messages,
            private profiles,
            private userRoles,
            private reports) {
            $scope.userRoles = userRoles;
            $scope.summaryMonthly = _.find(reports, {name: 'summaryMonthly'})['id'];
            $scope.customDateRange = _.find(reports, {name: 'customDateRange'})['id'];
            $scope.summaryDaily = _.find(reports, {name: 'summaryDaily'})['id'];
            $scope.monthlyBuildingEngineer = _.find(reports, {name: 'monthlyBuildingEngineer'})['id'];
            $scope.monthlyPortfolioBudget = _.find(reports, {name: 'portfolio'})['id'];
            $scope.weeklyPortfolioAlert = _.find(reports, {name: 'portfolio-alert'})['id'];
        }

        public profileContainsReport(profile, report: number): boolean {
            if (profile.reports.length === 0) {
                return false;
            } else {
                const thing = _.find(profile.reports, (r) => r.id === report || r === report) !== undefined;
                if (thing) {
                    return true;
                } else {
                    return false;
                }
            }
        }

        public saveProfile(profile, report: number) {
            if (this.reportExists(profile, report)) {
                const newReportList = _.filter(profile.reports, (r) => r.id != report);
                profile.reports = newReportList;
            } else {
                profile.reports.push({id: report});
            }
            const profileObjects = angular.copy(profile.reports);
            profile.reports = _.map(profile.reports, 'id').filter(r => r != null);
            profile.put({simple: true}).then(() => {
                profile.reports = profileObjects;
                this.Messages.success('Changes saved successfully');
            }, this.Errors.forCRUD('UPDATE'));
        }

        private reportExists (profile, reportId): boolean {
            for (let i = 0; i < profile.reports.length; i++) {
                if (profile.reports[i].id === reportId) {
                    return true;
                }
            }
            return false;
        }

    }
    angular
        .module('reportCenter')
        .controller('BuildingReportsCtrl', BuildingReportsCtrl);
}
