angular
    .module('aq.reports')
    .config(function ($stateProvider) {
    $stateProvider
        .state('aq.reports.project', {
        url: '/summary/projectSummary',
        controller: 'ProjectCtrl',
        controllerAs: 'vm',
        templateUrl: 'app/reports/summary/project/project.html',
        resolve: {
            options: function (OptionsService, account) {
                return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
            },
            metrics: function (Restangular, account, waitForAuthToken) {
                return Restangular.one('accounts', account.id).customGET('queryRealUnits');
            },
            buildings: function (waitForAuthToken, RestangularV3) {
                return RestangularV3.all('buildings').getList();
            },
            buildingGroups: function (RestangularV3, account, waitForAuthToken) {
                return RestangularV3.all('building-groups').getList();
            },
            buildingPersonnels: function (buildings, $q) {
                var promises = _.map(buildings, function (building) {
                    return building.all('personnel').getList()
                        .then(function (result) {
                        return {
                            buildingId: building.id,
                            personnel: result
                        };
                    }, function (error) {
                        return {
                            buildingId: building.id,
                            personnel: []
                        };
                    });
                });
                return $q.all(promises);
            },
            buildingOccupancy: function (buildings, $q, RestangularV3, waitForAuthToken) {
                var buildingOccupancyMap = {};
                var promises = _.map(buildings, function (building) {
                    return RestangularV3.one('building', building.id)
                        .all('buildingOccupancy')
                        .getList()
                        .then(function (occupancyItems) {
                        if (occupancyItems && occupancyItems.length > 0) {
                            var latest = _.last(_.sortBy(occupancyItems, function (item) { return item.startDate; }));
                            buildingOccupancyMap[building.id] = latest.occupancyPercent;
                        }
                    });
                });
                return $q.all(promises).then(function () {
                    return buildingOccupancyMap;
                });
            },
            startDate: function ($location) {
                if ($location.search().startDate) {
                    return parseInt($location.search().startDate);
                }
                else {
                    return null;
                }
            },
            buildingStartDates: function (buildings, date) {
                var buildingStartDateMap = {};
                _.each(buildings, function (building) {
                    var startDate = null;
                    if (building.enticImpactDate) {
                        startDate = moment(building.enticImpactDate, 'YYYY-MM-DD');
                    }
                    else {
                        var fiscalStartMonth = building.fiscalStartMonth || 1;
                        var endDate = moment(date);
                        var currentMonth = parseInt(endDate.format('M'));
                        var currentYear = parseInt(endDate.format('YYYY'));
                        startDate = fiscalStartMonth > currentMonth
                            ? moment(fiscalStartMonth + " " + (currentYear - 1), 'M YYYY')
                            : moment(fiscalStartMonth + " " + currentYear, 'M YYYY');
                    }
                    buildingStartDateMap[building.id] = startDate;
                });
                return buildingStartDateMap;
            },
            projects: function (ProjectServiceV3, $q, buildings, buildingStartDates, waitForAuthToken, Auth) {
                var _a;
                var _this = this;
                var Priority = aq.models.projects.ProjectPriority;
                this.priorityMap = (_a = {},
                    _a[Priority.HIGH] = 3,
                    _a[Priority.MEDIUM] = 2,
                    _a[Priority.LOW] = 1,
                    _a);
                var results = _.map(buildings, function (building) {
                    var buildingId = parseInt(building.id);
                    var buildingStartDate = buildingStartDates[building.id];
                    var first = false;
                    var statuses = null;
                    return ProjectServiceV3.getProjects(buildingId).then(function (projectResponse) {
                        if (!first) {
                            first = true;
                            statuses = projectResponse.statuses;
                        }
                        var selectedStatuses = _.filter(statuses, function (status) {
                            return _.includes(['Declined', 'Needs Acceptance', 'Backlog', 'In Progress', 'Completed'], status.name);
                        });
                        var projects = _.chain(projectResponse.projects)
                            .filter(function (project) {
                            return _.find(selectedStatuses, function (status) { return status.id == project.status.id; });
                        })
                            .filter(function (project) {
                            if (project.status.name == 'Completed') {
                                var completed = moment(project.completedOn);
                                return completed.isAfter(buildingStartDate, 'day');
                            }
                            if (!project.issue || !project.issue.identifiedDate) {
                                return false;
                            }
                            var opened = moment(project.issue.identifiedDate);
                            return opened.isAfter(buildingStartDate, 'day');
                        })
                            .value();
                        var projectItems = projects.map(function (p) {
                            var systemType = p.systemTypes && p.systemTypes[0] || null;
                            var assignee = _.find(_this.users, function (user) { return user.id == p.assignee; });
                            var isCompleted = p.status.name === 'Completed';
                            var isProjectEstimationEnabled = Auth.hasFunctionality(PROJECT_ESTIMATION);
                            var estimatedSavings = ProjectServiceV3.getImpactValue(p, isProjectEstimationEnabled, true, 'USD').projectImpact;
                            var project = {
                                id: p.code,
                                name: p.title,
                                priority: _this.priorityMap[p.priority],
                                status: p.status.name,
                                startDate: p.issue && p.issue.identifiedDate,
                                estimatedSavings: estimatedSavings
                            };
                            return project;
                        });
                        var buildingProjects = {
                            building: buildingId,
                            prescriptions: projectItems
                        };
                        return buildingProjects;
                    });
                });
                return $q.all(results).then(function (responses) {
                    var buildingPrescriptionsMap = {};
                    _.each(responses, function (response) {
                        buildingPrescriptionsMap[response.building] = response.prescriptions;
                    });
                    return buildingPrescriptionsMap;
                });
            },
            buildingConsumptions: function (account, metrics, $filter, date, Restangular, $q, buildings, waitForAuthToken) {
                var promises = _.map(buildings, function (building) {
                    var fiscalStartMonth = building.fiscalStartMonth || 1;
                    var endDate = moment(date);
                    var currentMonth = parseInt(endDate.format('M'));
                    var currentYear = parseInt(endDate.format('YYYY'));
                    var startDate = fiscalStartMonth > currentMonth
                        ? moment(fiscalStartMonth + " " + (currentYear - 1), 'M YYYY')
                        : moment(fiscalStartMonth + " " + currentYear, 'M YYYY');
                    var metric = _.find(metrics, { unit: 'kWh' });
                    var filterKWH = $filter('toUnit');
                    // TODO: enable if/when consumption data is required on report
                    // return Restangular.one('accounts', account.id)$q.when({});
                    // .one('buildings', building.id)$q.when({});
                    // .customGET('metrics', {
                    //     start: startDate.format('YYYY-MM-DDT00:00:00Z'),$q.when({});
                    //     end: endDate.format('YYYY-MM-DDT00:00:00Z'),$q.when({});
                    //     interval: '1mon',$q.when({});
                    //     measure: 'ENERGY'$q.when({});
                    // })
                    return $q.when(null)
                        .then(function (data) {
                        var total = 0;
                        if (data && data.values && data.values.eui != null) {
                            total = data.values.eui;
                        }
                        if (total == 0) {
                            // mock data
                            total = Math.round(Math.random() * 50 + 50);
                        }
                        return {
                            buildingId: building.id,
                            eui: total
                        };
                    })
                        .catch(function () {
                        return {
                            buildingId: building.id,
                            eui: 0
                        };
                    });
                });
                var response = {};
                return $q.all(promises).then(function (responses) {
                    _.each(responses, function (item) {
                        response[item.buildingId] = item.eui;
                    });
                    return response;
                });
            },
            buildingUtilitySpend: function () {
                return {};
            }
        }
    });
});
