angular.module('aq.admin.dashboards', ['models', 'aq.ui.loading', 'ng.jsoneditor'])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.admin.dashboards', {
                url: '/dashboards',
                templateUrl: 'app/admin/dashboards/index.html',
                controller: 'AdminDashboardsCtrl as adminDashboardsCtrl',
                data: {
                    appName: 'Dashboards'
                },
                resolve: {
                    accounts: (Restangular: restangular.IService, waitForAuthToken) => {
                        return Restangular.all('accounts').get('queryAllAccountNames');
                    },
                    dashboards: (DashboardService: aq.models.IDashboardService, waitForAuthToken) => {
                        return DashboardService.list();
                    }
                }
            });
    });
