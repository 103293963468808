'use strict';

angular
    .module('aq.admin.reports.reportutils', [])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.admin.reports.reportutils', {
                url: '/report-utils',
                templateUrl: 'app/admin/reports/reportutils/reportutils.html',
                controller: 'ReportUtilsCtrl as vm',
                resolve: {
                    accounts(Restangular: restangular.IService, waitForAuthToken) {
                        return Restangular.all('accounts').get('queryAllAccounts');
                    }
                },
                data: {
                    breadcrumb: 'Report Utils',
                    icon: '.aq-icons-account-settings'
                }
            });
    });
