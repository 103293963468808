var aq;
(function (aq) {
    var propertySettings;
    (function (propertySettings) {
        var AddEditScheduleEvent = /** @class */ (function (_super) {
            __extends(AddEditScheduleEvent, _super);
            /* @ngInject */
            function AddEditScheduleEvent($mdDialog, scheduleEvent, buildingSchedule, ruleCategories, Messages, RestangularV3, BuildingScheduleHelperService, isReadonly) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.scheduleEvent = scheduleEvent;
                _this.buildingSchedule = buildingSchedule;
                _this.ruleCategories = ruleCategories;
                _this.Messages = Messages;
                _this.RestangularV3 = RestangularV3;
                _this.BuildingScheduleHelperService = BuildingScheduleHelperService;
                _this.isReadonly = isReadonly;
                _this.initView();
                return _this;
            }
            AddEditScheduleEvent.prototype.saveEvent = function () {
                var _this = this;
                this.processViewModel();
                if (this.scheduleEvent.id) {
                    this.RestangularV3.one('calendars', this.buildingSchedule.id).one('rules', this.scheduleEvent.id).customPUT(this.scheduleEvent, '').then(function (result) {
                        _.extend(result, result.model);
                        _this.Messages.success('Successfully saved schedule event');
                        _this.hide(result);
                    });
                }
                else {
                    this.RestangularV3.one('calendars', this.buildingSchedule.id).all('rules').customPOST(this.scheduleEvent, '').then(function (result) {
                        _.extend(result, result.model);
                        _this.Messages.success('Successfully saved schedule event');
                        _this.hide(result);
                    });
                }
            };
            AddEditScheduleEvent.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            AddEditScheduleEvent.prototype.hide = function (data) {
                this.$mdDialog.hide(data);
            };
            AddEditScheduleEvent.prototype.initView = function () {
                var _this = this;
                this.timeOptions = this.BuildingScheduleHelperService.getTimeOptions();
                this.weekdaySelectionModel = angular.copy(this.BuildingScheduleHelperService.daysOfWeek);
                var selectedDays = this.scheduleEvent.id
                    ? this.scheduleEvent.week
                    : this.BuildingScheduleHelperService.workDays;
                _.forEach(selectedDays, function (selectedDay) {
                    var day = _.find(_this.weekdaySelectionModel, function (dd) { return dd.day == selectedDay; });
                    if (day) {
                        day.selected = true;
                    }
                });
                if (this.scheduleEvent.dateStart) {
                    this.dateStartView = new Date(this.scheduleEvent.dateStart);
                }
                if (this.scheduleEvent.dateEnd) {
                    this.dateEndView = new Date(this.scheduleEvent.dateEnd);
                }
                if (!this.scheduleEvent.id) {
                    this.scheduleEvent.timeStart = '09:00:00';
                    this.scheduleEvent.timeEnd = '17:00:00';
                }
                this.isAllYear = this.scheduleEvent.id
                    ? !this.scheduleEvent.dateStart && !this.scheduleEvent.dateEnd
                    : true;
                this.isAllDay = this.scheduleEvent.id
                    ? !this.scheduleEvent.timeStart || !this.scheduleEvent.timeEnd
                    : false;
            };
            AddEditScheduleEvent.prototype.getHumanReadableEventDescription = function () {
                return this.BuildingScheduleHelperService.getHumanReadableEventDescription(this.getSelectedWeekdays(), this.isAllDay ? '' : this.scheduleEvent.timeStart, this.isAllDay ? '' : this.scheduleEvent.timeEnd, this.isAllYear ? '' : moment(this.dateStartView).format('YYYY-MM-DD'), this.isAllYear ? '' : moment(this.dateEndView).format('YYYY-MM-DD'));
            };
            AddEditScheduleEvent.prototype.processViewModel = function () {
                this.scheduleEvent.week = this.getSelectedWeekdays();
                if (this.isAllDay) {
                    this.scheduleEvent.timeStart = null;
                    this.scheduleEvent.timeEnd = null;
                }
                if (this.isAllYear) {
                    this.scheduleEvent.dateStart = null;
                    this.scheduleEvent.dateEnd = null;
                }
                else {
                    this.scheduleEvent.dateStart = moment(this.dateStartView).format('YYYY-MM-DD');
                    this.scheduleEvent.dateEnd = moment(this.dateEndView).format('YYYY-MM-DD');
                }
            };
            AddEditScheduleEvent.prototype.getSelectedWeekdays = function () {
                var selectedModels = _.filter(this.weekdaySelectionModel, function (weekday) { return weekday.selected == true; });
                return _.map(selectedModels, function (selected) { return selected.day; });
            };
            AddEditScheduleEvent.prototype.capitalize = function (day) {
                return this.BuildingScheduleHelperService.capitalizeString(day);
            };
            return AddEditScheduleEvent;
        }(aq.common.Controllers.ModalCtrl));
        propertySettings.AddEditScheduleEvent = AddEditScheduleEvent;
        angular.module('properties').controller('AddEditScheduleEvent', AddEditScheduleEvent);
    })(propertySettings = aq.propertySettings || (aq.propertySettings = {}));
})(aq || (aq = {}));
