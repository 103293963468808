namespace aq.reportCenter {
    export interface Building {
        id: number;
        name: string;
    }
    export interface User {
        id: number;
        userType: string;
        fullName: string;
    }
    export interface Report {
        id: number;
        name: string;
        fullName: string;
        app: number;
        reportType: string;
    }
    export interface ScheduledReport {
        id: number;
        name: string;
        parameters: {
            buildingId?: string
        };
        scheduling: SchedulingModel;
        recipients: number[];
        report: number;
    }
    export interface ScheduledReportsMap {
        [key: string]: ScheduledReport[];
    }
    export interface SchedulingModel {
        dayOfMonth: number;
        dayOfWeek: number;
        interval: 'IMMEDIATELY' | 'DAILY' | 'WEEKLY' | 'BIWEEKLY' | 'MONTHLY';
        time: string;
    };
    export interface ReportItem {
        id: number;
        name: string;
        generated: string;
        reportId: number;
        reportName: string;
        scheduledReport: number;
        generatedOnDisplay?: string;
        reportFullName?: string;
    }
    export interface ReportFilter {
        dateStart: moment.Moment;
        dateEnd: moment.Moment;
        buildingId: number;
        reportTypeId: number;
        searchText: string;
    }
    export interface ReportQueryParameters {
        startDate?: string;
        endDate?: string;
        buildingId?: number;
        reportTypeId?: number;
    }
    export interface SelectionItem {
        id: number;
        name: string;
    }
    export interface DateRangeModel {
        selectedTemplate?: string;
        selectedTemplateName?: string;
        dateStart?: Date;
        dateEnd?: Date;
    }
    export interface UserAssignmentModel {
        id: number;
        buildingName: string;
        reportUsers: SelectionItem[];
        isSignUp: boolean;
        isEditing: boolean;
        hiddenUserIds: number[];
        relatedScheduledReports: ScheduledReport[];
        scheduling: SchedulingModel;
    }
    export interface GenerateReportParams {
        buildingId?: number;
        date?: number;
        scheduledReportId?: number;
        startDate?: number;
        endDate?: number;
    }
    export enum ReportName {
        INVOICE = 'invoice',
        ACCOUNT_SUMMARY = 'accountSummary',
        OPTIMIZATION = 'optimization',
        INVOICE_COB = 'invoiceCoB',
        ACCOUNT_SUMMARY_COB = 'accountSummaryCoB',
        CHARGES_SUMMARY_COB = 'chargesSummaryCoB',
        VORNADO_TENANT_REPORT = 'vornadoTenantReport',
        METER_DATA = 'meterData',
        OPTIMIZATION_CSV = 'optimizationCsv',
        ANGUS = 'angus',
        CHARGES_SUMMARY = 'chargesSummary',
        SUMMARY_DAILY = 'summaryDaily',
        SUMMARY_MONTHLY = 'summaryMonthly',
        CUSTOM_DATE_RANGE = 'customDateRange',
        MONTHLY_BUILDING_ENGINEER = 'monthlyBuildingEngineer',
        PORTFOLIO = 'portfolio',
        PORTFOLIO_ALERT = 'portfolio-alert',
        PROJECT_SUMMARY = 'projectSummary',
        PORTFOLIO_UTILITY_NOTES = 'utilityNotesMonthlyStatus'
    }
}
