namespace aq.dashboard.widgets {
    
    export function XAxisFormats() {
        return function (xAxisFormats, preset) {
            let formats = [];
            if(preset == 'custom'){
                return xAxisFormats;
            }
            else {
                xAxisFormats.forEach(function(format) {
                    if(format.intervals.indexOf(TimePresets.getPresetDetails(preset).interval) >= 0){
                        formats.push(format);
                    }
                });
                return formats;
            }
        }
    };

    angular.module('aq.filters').filter("xAxisFormats", XAxisFormats);
}