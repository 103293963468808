namespace aq.utilityBudgets {
    export class AddUtilityAccount extends aq.common.Controllers.ModalCtrl {
        private utilityAccount: UtilityAccount;
        private autoUtilityUploadEnabled: boolean;
        private selectedServices: { [serviceType: string]: boolean };
        private meters: Meter[] = [];
        private filterTerm: string;
        private saved: boolean = false;

        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private RestangularV3: restangular.IService,
            private Errors,
            private Messages,
            private utilityCompanies: aq.common.models.NamedItem[],
            private accountId,
            private buildingId,
            private UtilityServiceHelper: UtilityServiceHelper,
            private loading,
            private Segment: aq.services.SegmentService
        ) {
            super({}, $mdDialog);
            this.utilityAccount = <UtilityAccount>{};
            this.autoUtilityUploadEnabled = true;
            this.selectedServices = {};
            this.filterTerm = '';
        }
        public cancel(data?): void {
            this.$mdDialog.cancel();
        }
        public hide(result?): void {
            this.$mdDialog.hide(this.RestangularV3.copy(result));
        }
        public getMeasureTypes(): MeasureType[] {
            return this.UtilityServiceHelper.getMeasureTypes();
        }
        public clearFilter() {
            this.filterTerm = '';
        }
        public getSelectedServices() {
            return _.filter(_.keys(this.selectedServices), (serviceType) => {
                return this.selectedServices[serviceType];
            });
        }
        public save() {
            this.Segment.trackEvent('Utility Accounts:Manual Utility Account Creation');
            this.saved = true;
            this.loading.start(true);
            this.utilityAccount.meters = this.meters.filter((meter) => meter.id !== '' && meter.type !== '');
            if (!this.autoUtilityUploadEnabled) {
                this.utilityAccount.services = this.getSelectedServices();
                delete this.utilityAccount.password;
                delete this.utilityAccount.username;
                delete this.utilityAccount.utilityUrl;
            }
            return this.RestangularV3
                .all('utility-accounts')
                .post(this.utilityAccount, { buildingId: this.buildingId })
                .then((result) => {
                    this.Messages.success('Successfully created Utility Account');
                    this.hide(result);
                    this.loading.stop();
                })
                .catch((err) => {
                    this.Messages.error('Unable to perform create action');
                    this.loading.stop();
                });
        }
        public addMeter(meters: Meter[]) {
            meters.push({id: '', type: 'ELECTRICITY'});
        }
        public deleteMeter(meters: Meter[], index: number) {
            meters.splice(index, 1);
        }
        public getFilteredCompanies() {
            return _.filter(this.utilityCompanies, (utilityCompany: aq.common.models.NamedItem) => {
                return !this.filterTerm || utilityCompany.name && utilityCompany.name.toLowerCase().indexOf(this.filterTerm.toLowerCase()) > -1;
            });
        }
    }
    angular.module('aq.utilityBudgets').controller('AddUtilityAccount', AddUtilityAccount);
}
