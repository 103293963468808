
namespace aq.deviceManagement {
    export class DeviceMappingCtrl {
        private allDevicesLength: number;
        private dataStatuses: aq.common.models.DataStatus[];
        private dataStatusesById: {[id: number]: aq.common.models.DataStatus};
        private updateStatusInterval: ng.IPromise<any>;
        private orderedDevices: any[];
        private filtered: aq.common.models.Device[];
        private emptySearch;
        private emptyFilter;
        private unlinkedMeters: any[];
        private commissioningStatus;
        private filter = {
            selected: { label: 'None', value: 'NONE' },
            options: [
                { label: 'None', value: 'NONE' },
                { label: 'Offline', value: 'OFFLINE' },
                { label: 'Online', value: 'ONLINE' },
                { label: 'Never Reported', value: 'NEVER_REPORTED' },
                { label: 'Waiting for Connection', value: 'WAITING' },
                { label: 'Not Web Enabled', value: 'NOT_WEB_ENABLED' }
            ]
        };

        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private allDevices: aq.common.models.Device[],
            private building: aq.common.models.Building,
            private $state: ng.ui.IStateService,
            private $stateParams: ng.ui.IStateParamsService,
            private $mdDialog: ng.material.IDialogService,
            private RestangularV3: restangular.IService,
            private DataStore: aq.common.DataStore,
            private tenants,
            private links,
            private measures,
            private floors,
            private spaces,
            private deviceCategories,
            private classesByCategory: aq.common.models.DeviceClassNameByCategory,
            private $location: ng.ILocationService,
            private DeviceService: aq.services.DeviceService,
            private $mdMedia: ng.material.IMedia,
            private Messages: aq.services.Messages,
            private $interval: ng.IIntervalService,
            private $q
        ) {
            this.updateDataStatuses();
            this.updateStatusInterval = this.$interval(() => this.updateDataStatuses(), DeviceOverviewCtrl.STATUS_INTERVAL);
            $scope.$on('$destroy', () => {
                this.$interval.cancel(this.updateStatusInterval);
            });
            this.filtered = this.allDevices;
            this.orderDevices(this.filtered, this.links);
        }

        public updateDataStatuses() {
            this.$q.all([
                this.RestangularV3.all('devices').customGET('data-status', {buildingId: this.building.id, ts: moment().valueOf()}),
                this.RestangularV3.one('devices').one('commissioning').customGET('status', { buildingId: this.building.id })
            ]).then((results) => {
                this.dataStatuses = results[0];
                this.commissioningStatus = results[1];
                this.dataStatusesById = {};
                this.dataStatuses.forEach((dataStatus) => {
                    this.dataStatusesById[dataStatus.id] = dataStatus;
                });
            }).catch(() => {
                this.Messages.error('Error during data query.  Reload page');
            });
        }

        public shouldDisplayDeviceIdentifier(device: aq.common.models.Device, field: string) {
            return device && device.fields && device.fields[field]
                && device.deviceClass && device.deviceClass.fields && device.deviceClass.fields[field];
        }

        public getNextStep(device: aq.common.models.Device) {
            if (this.commissioningStatus && this.commissioningStatus[device.id]) {
                for (let i = 0; i < this.commissioningStatus[device.id].steps.length; i++) {
                    if (!(this.commissioningStatus[device.id].steps[i].status == 'COMPLETE')) {
                        return this.commissioningStatus[device.id].steps[i].name;
                    }
                }
            }
            return null;
        }

        public truncateDescription(description: string) {
            if (description && description.length > 25) {
                return description.substring(0, 25).concat(`...`);
            } else return description;
        }

        public orderDevices(devices, links) {
            const gatewayDevices: aq.common.models.Device[] = _.filter(devices, {deviceCategory: 'NETWORKING', gateway: true});
            const bridgeDevices: aq.common.models.Device[] = _.filter(devices, {deviceCategory: 'NETWORKING', gateway: false});
            const meters: aq.common.models.Device[] = _.filter(devices, {deviceCategory: 'METER'});
            const orderedDevices: any[] = [];
            _.forEach(gatewayDevices, (device: aq.common.models.Device) => {
                const id = device.id;
                const linkedMeters: aq.common.models.Device[] = _.remove(meters, (meter) => {
                    return _.find(links[meter.id], (link) => {
                        return link.device.id == id;
                    });
                });
                orderedDevices.push(_.extend(device, {children: linkedMeters}));

                const linkedBridges: aq.common.models.Device[] = _.remove(bridgeDevices, (bridge) => {
                    return _.find(links[bridge.id], (link) => {
                        return link.device.id == id;
                    });
                });

                // push any bridges linked to this gateway onto the list
                _.forEach(linkedBridges, (bridge: aq.common.models.Device) => {
                        const bridgeid = bridge.id;
                        const linkedMetersToBridge: aq.common.models.Device[] = _.remove(meters, (meter) => {
                            return _.find(links[meter.id], (link) => {
                                return link.device.id == bridgeid;
                            });
                        });
                        orderedDevices.push(_.extend(bridge, {children: linkedMetersToBridge}));
                });
            });

            // push any bridges that are not linked to any gateways onto the list
            _.forEach(bridgeDevices, (bridge: aq.common.models.Device) => {
                const bridgeid = bridge.id;
                const linkedMetersToBridge: aq.common.models.Device[] = _.remove(meters, (meter) => {
                    return _.find(links[meter.id], (link) => {
                        return link.device.id == bridgeid;
                    });
                });
                orderedDevices.push(_.extend(bridge, {children: linkedMetersToBridge}));
            });

            this.orderedDevices = _.sortBy(orderedDevices, (deviceGroup) => {
                return deviceGroup.children ? -deviceGroup.children.length : 1;
            });

            this.unlinkedMeters = meters;
        }

        public editDevice(deviceId: number | string): void {
            const backButton: aq.components.BackButtonData = {
                state: 'aq.deviceManagement.building.mapping',
                stateData: {}
            };
            this.$state.go('aq.deviceManagement.building.device.configuration', { deviceId, cat: undefined, dataStatuses: this.dataStatuses, backButton});
        }

        public getIcon(device: aq.common.models.DeviceElement): string {
            return this.DeviceService.getIcon(device);
        }

        public getCommissioningProgress(device: aq.common.models.Device) {
            if (this.commissioningStatus) {
                const percentDone = Math.round((this.commissioningStatus[device.id].numStepsCompleted /
                    this.commissioningStatus[device.id].totalSteps) * 100);
                return percentDone;
            }
        }

        public getPulseInput(device: aq.common.models.Device) {
            if (device.deviceCategory == 'METER') {
                if (this.links[device.id].length == 1 && this.links[device.id][0].protocol == 'PULSE') {
                    return this.links[device.id][0].inputId;
                }
            } else {
                return 0;
            }
        }

        public tenantNames(tenants: aq.common.models.Tenant[]) {
            return _.map(tenants, 'name').join();
        }

        public searchBy(query: string): void {
            if (query) {
                this.filter.selected = { label: 'None', value: 'NONE' };
                this.filtered = this.allDevices
                    .filter((device) => {
                        const searchThrough: string[] =
                            [device.name, device.location, device.tenantLinks.map((deviceTenantLink) => deviceTenantLink.tenant.name).join(' ')];
                        return this.foundIn(searchThrough, query);
                    });
                this.orderDevices(this.filtered, this.links);
            } else {
                this.filtered = this.allDevices;
                this.orderDevices(this.filtered, this.links);
            }
            this.emptySearch = (this.filtered.length === 0 && this.allDevicesLength > 0);
        }

        public filterBy() {
            const { selected } = this.filter;
                // if the filter selected is 'NONE', reset the filter to include everything, otherwise filter based on selected.
            if (selected.value == 'NONE') {
                this.filtered = this.allDevices;
                this.orderDevices(this.filtered, this.links);
            } else {
                this.filtered = this.allDevices.filter((device) => {
                    const deviceStatus = this.dataStatusesById[device.id];
                    if (deviceStatus) {
                        return deviceStatus.status === selected.value;
                    }
                    return false;
                });
                this.orderDevices(this.filtered, this.links);
            }
            this.emptyFilter = (this.filtered.length === 0 && this.allDevicesLength > 0);
        }

        private foundIn(searchInArray: string[], searchFor: string): boolean {
            return searchInArray.some((searchIn) => {
                if (searchIn && _.includes(searchIn.toUpperCase(), searchFor.toUpperCase())) {
                    return true;
                }
            });
        }
    }

    angular
        .module('deviceManagement')
        .controller('DeviceMappingCtrl', DeviceMappingCtrl);
}
