'use strict';

angular.module('accounts').controller('GeneralSettingsCtrl', function($scope, $window, Restangular, Messages) {

    if ($scope.user.id == $scope.user.currentProfile.account.owner) {
        $scope.account.customGET('queryPossibleOwners').then(function (owners) {
            $scope.possibleOwners = owners;
        })
    }

    $scope.deleteAccount = function (account) {
        account.remove().then(function () {
            $window.location = "/";
        }, function () {
            Messages.error("Unable to delete this account");
        });
    };

});
