namespace aq.tenantbilling {
    export class TenantBillingSetupCtrl {
        public accountApprovalActions: AccountApprovalAction[];
        public setupApprovalActionUsers: any[];
        public monthDays: {
            value: number;
            label: string
        }[];
        public entityActions: aq.common.models.EntityMenuAction[];
        public timeOptions: {
            value: string;
            label: string;
        }[];
        public readingSchemeOptions;
        public currentNavItem: string;
        public selectedUser;
        public userSearchText;
        public isPendingAddService: boolean;
        public isPendingAddTenantGroup: boolean;
        public nonFormDataChangeIndicator: number;
        public currencyUnit;
        /* @ngInject */
        constructor(
            public account,
            public building,
            public reportSetups,
            private Auth: aq.services.Auth,
            public approvalActionSetups: ApprovalActionSetup[],
            public Errors,
            public Messages: aq.services.Messages,
            public billingReminderTypes,
            public users,
            public tenantServiceMethods,
            public utilityServices: UtilityService[],
            public tenantServices: TenantService[],
            public billingSetup: BillingSetup,
            public $mdDialog: ng.material.IDialogService,
            public $mdMedia: ng.material.IMedia,
            public TenantBillingSetupService: TenantBillingSetupService,
            public MonthlyBillingService: MonthlyBillingService,
            public RestangularV3FullResponse,
            public FileHandlerService,
            public $q: ng.IQService,
            public OptionsService,
            public tenantGroups,
            public tenants
        ) {
            this.initController();
            this.currencyUnit = this.OptionsService.currencyUnit();
        }

        initController() {
            this.currentNavItem = 'general';
            if (this.Auth.check({ access: 'EDIT' })) {
                this.entityActions = [
                    {
                        label: 'Create Billable Service',
                        cb: () => {
                            if (this.tenantServices !== undefined) {
                                this.addServiceFee();
                            } else {
                                this.isPendingAddService = true;
                            }
                        }
                    }
                ];
                if (this.Auth.hasFunctionality('Tenant Groups')) {
                    this.entityActions.push({
                        label: 'Create Tenant Group',
                        cb: () => {
                            if (this.tenantGroups !== undefined) {
                                this.addTenantGroup();
                            } else {
                                this.isPendingAddTenantGroup = true;
                            }
                        },
                        icon: 'group'
                    });
                }
            }
            this.accountApprovalActions = this.account.approvalActions;
            this.buildApprovalActionUsers();
            this.buildMonthDays();
            this.buildTimeOptions();
            this.selectedUser = null;
            this.userSearchText = '';
            this.readingSchemeOptions = [
                {
                    value: 'LAST',
                    label: 'last'
                },
                {
                    value: 'CURRENT',
                    label: 'this'
                }
            ];
            this.nonFormDataChangeIndicator = 0;
            this.loadData();
        }

        public isSelectedTab(name) {
            return this.currentNavItem == name;
        }
        public loadData() {
            if (this.isPendingAddService) {
                this.isPendingAddService = false;
                this.addServiceFee();
            }

            if (this.isPendingAddTenantGroup) {
                this.isPendingAddTenantGroup = false;
                this.addTenantGroup();
            }
        }
        public buildTimeOptions() {
            this.timeOptions = [];
            for (let i = 0; i < 48; i++) {
                const m = moment().startOf('day').add(i * 30, 'minutes');
                this.timeOptions.push({
                    label: m.format('h:mm A'),
                    value: m.format('HH:mm:ss')
                });
            }
        }

        public getMeteredServicesLength() {
            return this.tenantServices.filter((service) => {
                return service.method == 'METERED_USAGE';
            }).length;
        }

        public getFeeServicesLength() {
            return this.tenantServices.filter((service) => {
                return service.method == 'FEE';
            }).length;
        }

        public getTaxServicesLength() {
            return this.tenantServices.filter((service) => {
                return service.method == 'TAX';
            }).length;
        }

        public getUtilityServiceName(fee) {
            const utilityService: UtilityService = _.find(this.utilityServices, (us) => us.id == fee.utilityService);
            let utilityServiceName = fee.serviceType;
            if (utilityService) {
                utilityServiceName = `${utilityService.name} - Account: ${utilityService.account}`;
            }
            return utilityServiceName;
        }

        public previewReport(reportSetup: BillingReportSetup) {
            return this.RestangularV3FullResponse.one('reports', reportSetup.report)
                .withHttpConfig({ responseType: 'blob' })
                .customGET('preview-report')
                .then((response) => {
                    this.FileHandlerService.handleFileDownload(response, 'application/pdf');
                })
                .catch(() => {
                    this.Messages.error('Error while generating report');
                });
        }

        public buildMonthDays() {
            this.monthDays = [];
            for (let i = 1; i <= 28; i++) {
                let suffix = 'th';
                if (i == 1 || i == 21) suffix = 'st';
                if (i == 2 || i == 22) suffix = 'nd';
                if (i == 3 || i == 23) suffix = 'rd';
                this.monthDays.push({
                    value: i,
                    label: i + suffix
                });
            }
        }
        public buildApprovalActionUsers(): void {
            this.setupApprovalActionUsers = [];
            const emailUsersApprovalAction = this.getEmailUsersApprovalAction();
            if (!emailUsersApprovalAction) {
                return;
            }
            this.setupApprovalActionUsers = _.map(emailUsersApprovalAction.users, (userId) => {
                const targetUser = _.find(this.users, (user) => user.id == userId);
                // Side-effect of the if statement: if Client user is editing the list, it will remove Admin users
                if (targetUser) {
                    return {
                        id: targetUser.id,
                        fullName: targetUser.fullName
                    };
                }
                return null;
            }).filter(user => !!user);
        }
        public queryUsers() {
            const searchText = this.userSearchText.toLowerCase();
            const users = this.users.filter((user) => {
                return user.fullName.toLowerCase().indexOf(searchText) > -1
                    && _.findIndex(this.setupApprovalActionUsers, { fullName: user.fullName }) === -1;
            });
            return users;
        }
        public clearImage() {
            this.billingSetup.imageUrl = null;
            this.billingSetup.imageThumbnailUrl = null;
            this.onImageChange();
        }
        public onImageChange() {
            this.nonFormDataChangeIndicator++;
        }
        /* Details */
        public save() {
            const promises = [];
            promises.push(this.TenantBillingSetupService.saveTenantBillingSetupDetails(this.billingSetup));
            this.updateApprovalActionUsers();
            this.approvalActionSetups.forEach(approvalSetup => {
                promises.push(this.MonthlyBillingService.saveApprovalActionSetup(approvalSetup));
            });
            this.reportSetups.forEach(reportSetup => {
                promises.push(this.MonthlyBillingService.saveBillingReportSetup(reportSetup));
            });
        }
        public updateApprovalActionUsers() {
            const emailUsersApprovalAction = this.getEmailUsersApprovalAction();
            if (!emailUsersApprovalAction) {
                return;
            }
            if (emailUsersApprovalAction.enabled) {
                emailUsersApprovalAction.users = _.map(this.setupApprovalActionUsers || [], 'id');
            } else {
                emailUsersApprovalAction.users = [];
            }
        }
        /* Service Fee CRUD */
        public addServiceFee(method = '') {
            this.$mdDialog.show({
                controller: 'AddEditTenantServiceFeeCtrl as vm',
                templateUrl: 'app/tenantBilling/setup/actions/addEditTenantServiceFee/addEditTenantServiceFee.html',
                clickOutsideToClose: false,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    serviceFee: null,
                    serviceMethod: method,
                    billingSetupId: this.billingSetup.id,
                    tenantServices: this.tenantServices,
                    utilityServices: this.utilityServices
                }
            }).then(() => {
                this.processServiceFeeChange();
            });
        }
        public editServiceFee(serviceFeeItem: TenantService) {
            const serviceFee = angular.copy(_.find(this.tenantServices, (s) => s.id == serviceFeeItem.id));
            this.$mdDialog.show({
                controller: 'AddEditTenantServiceFeeCtrl as vm',
                templateUrl: 'app/tenantBilling/setup/actions/addEditTenantServiceFee/addEditTenantServiceFee.html',
                clickOutsideToClose: false,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    serviceFee,
                    serviceMethod: null,
                    billingSetupId: this.billingSetup.id,
                    tenantServices: this.tenantServices,
                    utilityServices: this.utilityServices
                }
            }).then(() => {
                this.processServiceFeeChange();
            });
        }
        public deleteServiceFee(serviceFeeItem: TenantService) {
            const confirm = this.$mdDialog
                .confirm()
                .textContent('Are you sure you want to delete this tenant service? This will remove this service from all of your tenants.')
                .ariaLabel('delete tenant service')
                .ok('Ok')
                .cancel('Cancel');
            this.$mdDialog
                .show(confirm)
                .then(() => {
                    return this.TenantBillingSetupService.deleteTenantFee(serviceFeeItem);
                })
                .then((result) => {
                    this.processServiceFeeChange();
                });
        }

        /* Tenant Group CRUD */
        public addTenantGroup() {
            this.$mdDialog.show({
                controller: 'AddEditTenantGroupCtrl as vm',
                templateUrl: 'app/tenantBilling/setup/actions/addEditTenantGroup/addEditTenantGroup.html',
                clickOutsideToClose: false,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    tenantGroup: null,
                    tenantGroups: this.tenantGroups,
                    tenants: this.tenants,
                }
            }).then(() => {
                this.processTenantGroupChange();
            });
        }
        public editTenantGroup(tenantGroupItem) {
            const tenantGroup = angular.copy(_.find(this.tenantGroups, (s) => s.id === tenantGroupItem.id));
            this.$mdDialog.show({
                controller: 'AddEditTenantGroupCtrl as vm',
                templateUrl: 'app/tenantBilling/setup/actions/addEditTenantGroup/addEditTenantGroup.html',
                clickOutsideToClose: false,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    tenantGroup,
                    tenantGroups: this.tenantGroups,
                    tenants: this.tenants,
                }
            }).then(() => {
                this.processTenantGroupChange();
            });
        }
        public deleteTenantGroup(tenantGroupItem) {
            const confirm = this.$mdDialog
                .confirm()
                .textContent('Are you sure you want to delete this tenant group?')
                .ariaLabel('delete tenant group')
                .ok('Ok')
                .cancel('Cancel');
            this.$mdDialog
                .show(confirm)
                .then(() => {
                    return this.TenantBillingSetupService.deleteTenantGroup(tenantGroupItem);
                })
                .then(() => {
                    this.processTenantGroupChange();
                });
        }

        public processServiceFeeChange() {
            this.currentNavItem = 'fees';
        }

        public processTenantGroupChange() {
            this.currentNavItem = 'tenantGroups';
        }

        /* Report */
        public addReport() {
            this.$mdDialog.show({
                controller: /* @ngInject */ ($scope, $mdDialog) => {
                    $scope.hide = () => {
                        $mdDialog.hide();
                    };
                },
                templateUrl: 'app/tenantBilling/setup/add-report.html',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                fullscreen: this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')
            });
        }
        private getEmailUsersApprovalAction(): ApprovalActionSetup {
            return _.find(this.approvalActionSetups, (a: ApprovalActionSetup) => a.action.type == 'EMAIL_USERS');
        }
    }
    angular.module('tenantBilling').controller('TenantBillingSetupCtrl', TenantBillingSetupCtrl);
}
