angular.module('aq-main').run(function ($httpBackend) {
    const buildingSpendingRegex = /^\/api\/v3\/utility-spending\?buildingId=\d+.*$/;
    const accountSpendingRegex = /^\/api\/v3\/utility-spending\/by-utility-account\?buildingId=\d+.*$/;
    const blendedRateRegex = /^\/api\/v3\/blended-rate-calculations\?buildingId=\d+.*$/;
    const buildingBillingPeriodsRegex = /^\/api\/v3\/utility-bill-analysis\?buildingId=\d+.*$/;
    const billStatementRegex = /^\/api\/v3\/utility-bill-analysis\?billPeriodId=\d+.*$/;

    // utility-spending?buildingId&start&end&measure&interval
    // utility-spending/by-utility-account?buildingId&start&end&measure&interval
    // blended-rate-calculations?buildingId
    // utility-bill-analysis?billPeriodId

    const getTimestamps = (startDate, endDate) => {
        const result = [];
        let currentDate = moment(startDate).valueOf();
        const totalDays = moment(currentDate).add(1, 'day').daysInMonth();
        for (let i = 0; i < totalDays; i++) {
            result.push(currentDate);
            currentDate = moment(currentDate).add(1, 'day').valueOf();
        }
        return result;
    };

    const getTimestampsForYear = (startDate, endDate) => {
        const result = [];
        let currentDate = moment(startDate).valueOf();
        for (let i = 0; i < 12; i++) {
            result.push(currentDate);
            currentDate = moment(currentDate).add(1, 'month').valueOf();
        }
        return result;
    };

    const data = {};

    const getAccountData = (buildingId, startDate, endDate, key, referentDay): aq.utilityBudgets.UtilitySpendingAccountsResponse => {
        if (data[key]) {
            return data[key];
        }
        const timestamps = getTimestamps(startDate, endDate);
        const response: aq.utilityBudgets.UtilitySpendingAccountsResponse = {
            start: startDate,
            end: endDate,
            accounts: [
                { utilityCompanyName: 'Utility Company', accountNumber: '12345', spending: null },
                { utilityCompanyName: 'Utility Company', accountNumber: '54321', spending: null },
                { utilityCompanyName: 'Utility Company', accountNumber: '55555', spending: null }
            ]
        };
        _.each(response.accounts, (acc: aq.utilityBudgets.UtilitySpendingAccount) => {
            acc.spending = {
                data: [],
                unitName: 'CURRENCY_ELECTRICITY',
                unitSymbol: '$'
            };
            const spendingData = acc.spending.data;
            const refDate = referentDay ? moment().date(referentDay) : moment();
            const budgetRatio = 0.9 + 0.2 * Math.random();
            _.each(timestamps, (ts: number) => {
                spendingData.push({
                    timestamp: ts,
                    datum: {
                        projected: moment(ts).isBefore(moment(refDate)) ? 0 : 100,
                        consumption: moment(ts).isBefore(moment(refDate)) ? Math.round((0.8 + 0.7 * Math.random()) * 100) : 0,
                        utilityBill: refDate.diff(moment(ts), 'day') > 7 ? budgetRatio * 100 : 0,
                        blendedRate: 0.15,
                        blendedRateCalculation: 1,
                        isMissingData: false
                    }
                });
            });
        });
        data[key] = response;
        return response;
    };

    const getAccountDataForYear = (buildingId, startDate, endDate, key): aq.utilityBudgets.UtilitySpendingAccountsResponse => {
        if (data[key]) {
            return data[key];
        }
        const timestamps = getTimestampsForYear(startDate, endDate);
        const response: aq.utilityBudgets.UtilitySpendingAccountsResponse = {
            start: startDate,
            end: endDate,
            accounts: [
                { utilityCompanyName: 'Utility Company', accountNumber: '12345', spending: null }
            ]
        };
        _.each(response.accounts, (acc: aq.utilityBudgets.UtilitySpendingAccount) => {
            acc.spending = {
                data: [],
                unitName: 'CURRENCY_ELECTRICITY',
                unitSymbol: '$'
            };
            const spendingData = acc.spending.data;
            _.each(timestamps, (ts: number) => {
                const datum = {
                    projected: 0,
                    consumption: 0,
                    utilityBill: 0,
                    blendedRate: 0.15,
                    blendedRateCalculation: 1,
                    isMissingData: false
                };
                const totalDays = moment(ts).daysInMonth();
                const budgetRatio = 0.85 + 0.25 * Math.random();
                if (moment(ts).isSame(moment().subtract(1, 'month'), 'month')) {
                    datum.utilityBill = budgetRatio * 10000;
                    const today = moment().get('date');
                    if (today < 7) {
                        const remainderDays = 7 - today;
                        datum.utilityBill = (totalDays - remainderDays) / totalDays * budgetRatio * 10000;
                        datum.consumption = remainderDays / totalDays * (0.9 + 0.2 * Math.random()) * 10000;
                    }
                } else if (moment(ts).isBefore(moment(), 'month')) {
                    datum.utilityBill = budgetRatio * 10000;
                } else if (moment(ts).isSame(moment(), 'month')) {
                    const today = moment().get('date');
                    const billDays = Math.max(today - 7, 0);
                    datum.utilityBill = billDays / totalDays * budgetRatio * 10000;
                    const consumptionDays = today - billDays;
                    datum.consumption = consumptionDays / totalDays * (0.9 + 0.2 * Math.random()) * 10000;
                    const projectionDays = totalDays - today;
                    datum.projected = projectionDays / totalDays * (0.9 + 0.2 * Math.random()) * 10000;
                }
                spendingData.push({
                    timestamp: ts,
                    datum
                });
            });
        });
        data[key] = response;
        return response;
    };

    const getBuildingData = (buildingId, start, end, key, referentDay, isYear = false): aq.utilityBudgets.UtilitySpendingCumulativeResponse => {
        let localData: aq.utilityBudgets.UtilitySpendingAccountsResponse = null;
        if (data[key]) {
            localData = data[key];
        } else {
            localData = isYear
                ? getAccountDataForYear(buildingId, start, end, key)
                : getAccountData(buildingId, start, end, key, referentDay);
        }
        const response: aq.utilityBudgets.UtilitySpendingCumulativeResponse = {
            start,
            end,
            spending: {
                data: [],
                unitName: 'CURRENCY_ELECTRICITY',
                unitSymbol: '$'
            },
            lastAvailableFullDataTimestamp: parseInt(moment().format('D'))
        };
        const spendingData = response.spending.data;
        const timestamps = _.map(_.first(localData.accounts).spending.data, (item: aq.utilityBudgets.UtilitySpendingDataItem) => item.timestamp);
        _.each(timestamps, (ts, index) => {
            const dataItem: aq.utilityBudgets.UtilitySpendingDataItem = {
                timestamp: ts,
                datum: {
                    blendedRate: 0.15,
                    blendedRateCalculation: 1
                } as any
            } as any;
            const projectedVals = _.map(localData.accounts,
                (acc: aq.utilityBudgets.UtilitySpendingAccount) => acc.spending.data[index].datum.projected);
            dataItem.datum.projected = _.sum(projectedVals);
            const consumptionVals = _.map(localData.accounts,
                (acc: aq.utilityBudgets.UtilitySpendingAccount) => acc.spending.data[index].datum.consumption);
            dataItem.datum.consumption = _.sum(consumptionVals);
            const utilityBillVals = _.map(localData.accounts,
                (acc: aq.utilityBudgets.UtilitySpendingAccount) => acc.spending.data[index].datum.utilityBill);
            dataItem.datum.utilityBill = _.sum(utilityBillVals);
            spendingData.push(dataItem);
        });
        return response;
    };



    MockContext('utilitySpending', () => {
        $httpBackend.whenGET(accountSpendingRegex).respond((method, url, dat) => {
            const params: any = MockHelpers.getParamsAsObject(url);
            const startDate = moment(params.start);
            const endDate = moment(params.end);
            if (params.interval == '1mon') {
                const year = moment(startDate).format('YYYY');
                const key = `${params.buildingId}-${year}`;
                const response = getAccountDataForYear(params.buildingId, startDate, endDate, key);
                return [200, response, {}];
            } else {
                const month = moment(startDate).format('M');
                const key = `${params.buildingId}-${month}`;
                const response = getAccountData(params.buildingId, startDate, endDate, key, params.testForDay);
                return [200, response, {}];
            }
        });
        $httpBackend.whenGET(buildingSpendingRegex).respond((method, url, dat) => {
            const params: any = MockHelpers.getParamsAsObject(url);
            const startDate = moment(params.start);
            const endDate = moment(params.end);
            if (params.interval == '1mon') {
                const year = moment(startDate).format('YYYY');
                const key = `${params.buildingId}-${year}`;
                const response = getBuildingData(params.buildingId, startDate, endDate, key, params.testForDay, true);
                return [200, response, {}];
            } else {
                const month = moment(startDate).format('M');
                const key = `${params.buildingId}-${month}`;
                const response = getBuildingData(params.buildingId, startDate, endDate, key, params.testForDay);
                return [200, response, {}];
            }
        });
        $httpBackend.whenGET(billStatementRegex).respond((method, url, dat) => {
            const params: any = MockHelpers.getParamsAsObject(url);
            const startDate = moment(params.start);
            const endDate = moment(params.end);
            const billData: aq.utilityBudgets.BillStatementDataItem[] = [];
            if (!startDate.isValid || !endDate.isValid) {
                return [200, {}, {}];
            }
            let isFirst = true;
            let idIndex = 0;
            while (startDate.isBefore(endDate)) {
                const charge = Math.round((0.8 + 0.4 * Math.random()) * 100);
                const taxCharge = Math.round(0.09 * charge);
                const usageCharge = charge - taxCharge;
                const demandCharge = isFirst ? Math.round((0.6 + 0.4 * Math.random()) * 100) : 0;
                const otherCharge = 0;
                const adjustmentCharge = -5;
                const lateFeeCharge = 5;
                const cost = charge;
                isFirst = false;
                billData.push({
                    id: idIndex,
                    startTimestamp: startDate.format('YYYY-MM-DD'),
                    endTimestamp: endDate.format('YYYY-MM-DD'),
                    datum: {
                        charge,
                        usageCharge,
                        demandCharge,
                        taxCharge,
                        otherCharge,
                        adjustmentCharge,
                        lateFeeCharge,
                        cost
                    }
                });
                startDate.add(1, 'day');
                idIndex++;
            }
            const response: aq.utilityBudgets.BillStatementData = {
                data: billData,
                unitSymbol: '$',
                unitName: '',
                usage: 0,
                usageRealUnit: 'kWh',
                usageCharge: 0,
                demand: 0,
                demandRealUnit: 'kW',
                demandCharge: 0,
                otherCharge: 0,
                taxCharge: 0,
                lateFeeCharge: 0,
                adjustmentCharge: 0,
                cost: 0
            };
            return [200, response, {}];
        });
    });
});
