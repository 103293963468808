var aq;
(function (aq) {
    var utilityBudgets;
    (function (utilityBudgets) {
        var Step;
        (function (Step) {
            Step[Step["UTILITY_ACCOUNTS"] = 0] = "UTILITY_ACCOUNTS";
            Step[Step["UTILITY_SERVICES"] = 1] = "UTILITY_SERVICES";
            Step[Step["SHARED_BUILDINGS"] = 2] = "SHARED_BUILDINGS";
            Step[Step["DONE"] = 3] = "DONE";
        })(Step || (Step = {}));
        var ManualUtilityAccountWizardCtrl = /** @class */ (function (_super) {
            __extends(ManualUtilityAccountWizardCtrl, _super);
            /* ngInject */
            function ManualUtilityAccountWizardCtrl($mdDialog, $mdStepper, RestangularV3, UtilityServiceHelper, Messages, utilityCompanies, buildingViewItems, accountId, buildingId, $window, $q, Segment) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.$mdStepper = $mdStepper;
                _this.RestangularV3 = RestangularV3;
                _this.UtilityServiceHelper = UtilityServiceHelper;
                _this.Messages = Messages;
                _this.utilityCompanies = utilityCompanies;
                _this.buildingViewItems = buildingViewItems;
                _this.accountId = accountId;
                _this.buildingId = buildingId;
                _this.$window = $window;
                _this.$q = $q;
                _this.Segment = Segment;
                _this.mode = 'manual';
                _this.init();
                _this.intercom = _this.$window['Intercom'];
                if (_this.intercom) {
                    _this.isIntercomEnabled = true;
                }
                return _this;
            }
            ManualUtilityAccountWizardCtrl.prototype.init = function () {
                this.utilityCompany = null;
                this.utilityCompanyUrl = null;
                this.utilityAccounts = [];
                this.selectedBuildingViewItems = {};
                this.measureTypes = this.UtilityServiceHelper.getMeasureTypes();
                this.isAddAnother = false;
                this.filterTerm = '';
                this.currentStep = 0;
                this.accountServiceMap = {};
            };
            ManualUtilityAccountWizardCtrl.prototype.onAddUtilityAccount = function (item) {
                this.selectedBuildingViewItems[item] = [];
                this.accountServiceMap[item] = this.getEmptyServiceMap();
            };
            ManualUtilityAccountWizardCtrl.prototype.getEmptyServiceMap = function () {
                var serviceMap = {};
                _.each(this.measureTypes, function (type) {
                    serviceMap[type.value] = false;
                });
                return serviceMap;
            };
            ManualUtilityAccountWizardCtrl.prototype.next = function () {
                var _this = this;
                if (this.currentStep == Step.SHARED_BUILDINGS) {
                    this.create()
                        .then(function (result) {
                        _this.currentStep++;
                        _this.$mdStepper('utility-account-wizard').next();
                    });
                }
                else if (this.currentStep == Step.DONE) {
                    this.done();
                }
                else {
                    this.currentStep++;
                    this.$mdStepper('utility-account-wizard').next();
                }
            };
            ManualUtilityAccountWizardCtrl.prototype.previous = function () {
                this.$mdStepper('utility-account-wizard').back();
                this.currentStep = Math.max(0, this.currentStep - 1);
            };
            ManualUtilityAccountWizardCtrl.prototype.create = function () {
                var _this = this;
                this.Segment.trackEvent('Utility Accounts:Manual Utility Account Creation');
                this.isCreating = true;
                var requests = [];
                _.each(this.utilityAccounts, function (accountNumber) {
                    var utlityAccountRequest = {
                        accountNumber: accountNumber,
                        utilityCompany: parseInt(_this.utilityCompany),
                        sharedBuildingIds: _.map(_this.selectedBuildingViewItems[accountNumber], function (building) { return building.id; }),
                        services: _this.findAccountServices(accountNumber)
                    };
                    var request = _this.RestangularV3
                        .all('utility-accounts')
                        .post(utlityAccountRequest, { buildingId: _this.buildingId });
                    requests.push(request);
                });
                return this.$q.all(requests)
                    .catch(function (err) {
                    _this.Messages.error('Error occurred while creating utility account, please contact your Administrator');
                })
                    .finally(function () {
                    _this.isCreating = false;
                });
            };
            ManualUtilityAccountWizardCtrl.prototype.done = function () {
                if (this.isAddAnother) {
                    this.init();
                    this.$mdStepper('utility-account-wizard').goto(Step.UTILITY_ACCOUNTS);
                }
                else {
                    this.hide();
                }
            };
            ManualUtilityAccountWizardCtrl.prototype.confirmCancel = function (event) {
                var _this = this;
                var confirm = this.$mdDialog.confirm()
                    .title('Do you want to exit wizard? Your progress will be lost.')
                    .ariaLabel('Exit Wizard')
                    .targetEvent(event)
                    .ok('Exit')
                    .cancel('Stay');
                confirm.multiple(true);
                this.$mdDialog.show(confirm).then(function () {
                    _this.cancel();
                });
            };
            ManualUtilityAccountWizardCtrl.prototype.isValidServices = function () {
                var _this = this;
                return _.every(this.utilityAccounts, function (account) { return _this.isAccountAssignedToService(account); });
            };
            ManualUtilityAccountWizardCtrl.prototype.isAccountAssignedToService = function (account) {
                var _this = this;
                return _.some(this.measureTypes, function (type) {
                    return _this.accountServiceMap[account] && _this.accountServiceMap[account][type.value] == true;
                });
            };
            ManualUtilityAccountWizardCtrl.prototype.findAccountServices = function (account) {
                var servicesMap = this.accountServiceMap[account];
                var types = _.filter(this.measureTypes, function (type) { return servicesMap[type.value] == true; });
                return _.map(types, function (type) { return type.value; });
            };
            ManualUtilityAccountWizardCtrl.prototype.onSelectedBuildingsChange = function (buildings, accountNumber) {
                this.selectedBuildingViewItems[accountNumber] = buildings;
            };
            ManualUtilityAccountWizardCtrl.prototype.isDisableNext = function () {
                switch (this.currentStep) {
                    case Step.UTILITY_ACCOUNTS:
                        return !this.utilityAccounts || this.utilityAccounts.length == 0;
                    case Step.UTILITY_SERVICES:
                        return !this.isValidServices();
                    default:
                        return false;
                }
            };
            ManualUtilityAccountWizardCtrl.prototype.isShowBack = function () {
                return this.currentStep > Step.UTILITY_ACCOUNTS && this.currentStep < Step.DONE;
            };
            ManualUtilityAccountWizardCtrl.prototype.isLastStep = function () {
                return this.currentStep == Step.DONE;
            };
            ManualUtilityAccountWizardCtrl.prototype.openChat = function () {
                this.intercom('showMessages');
                this.$mdDialog.hide();
            };
            ManualUtilityAccountWizardCtrl.prototype.getNextButtonTitle = function () {
                switch (this.currentStep) {
                    case Step.SHARED_BUILDINGS:
                        return 'Next';
                    case Step.DONE:
                        return 'Done';
                    default:
                        return 'Next';
                }
            };
            ManualUtilityAccountWizardCtrl.prototype.getFilteredCompanies = function () {
                var _this = this;
                return _.filter(this.utilityCompanies, function (utilityCompany) {
                    return !_this.filterTerm || utilityCompany.name && utilityCompany.name.toLowerCase().indexOf(_this.filterTerm.toLowerCase()) > -1;
                });
            };
            return ManualUtilityAccountWizardCtrl;
        }(aq.common.Controllers.ModalCtrl));
        utilityBudgets.ManualUtilityAccountWizardCtrl = ManualUtilityAccountWizardCtrl;
        angular.module('aq.utilityBudgets').controller('ManualUtilityAccountWizardCtrl', ManualUtilityAccountWizardCtrl);
    })(utilityBudgets = aq.utilityBudgets || (aq.utilityBudgets = {}));
})(aq || (aq = {}));
