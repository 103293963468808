angular.module('aq.linechart', ['aq.tooltip']).directive('lineChart', function () {
    return {
        restrict: 'E',
        replace: true,
        template: '<div><div class="line-chart"></div><div ng-transclude></div>',
        transclude: true,
        scope: {
            min: "=",
            max: "=",
            data: "=",
            tooltipTitle: "@",
            tooltipUnit: "@",
            xaxisLabel: "=",
            yaxisLabel: "=",
            xaxisTimeFormat: "@",
            hideAxisDecorations: "=",
            hidePoints: "=",
            xaxisMode: '@',
            tooltipFunction: '='
        },
        controller: ['$scope', '$element', '$filter', 'TooltipService', '$window',
                     function($scope, $element, $filter, TooltipService, $window) {
          
            var chartElement = $element.find('.line-chart');
            var plot = function () {

                var options: any = {
                    grid: {
                        hoverable: true,
                        autoHighlight: true,
                        borderWidth: {
                            top: 1,
                            left: 1,
                            bottom: 1,
                            right: 1
                        },
                        color: '#c9c8c8'
                    },
                    points: { show: true },
                    lines: { show: true },
                    colors: ["#0091f1"],
                    xaxis: { 
                    },
                    yaxis: {},
                    xaxes: [{
                        axisLabel: $scope.xaxisLabel,
                        axisLabelPadding: 5,
                        max: $scope.max,
                        min: $scope.min,
                        timeformat: $scope.xaxisTimeFormat,
                        mode: $scope.xaxisMode
                    }],
                    yaxes: [{
                        position: 'left',
                        axisLabel: $scope.yaxisLabel,
                        axisLabelPadding: 5
                    }],
                    shadowSize: 0
                };
                
                if ($scope.hidePoints) {
                    options.points.show = false;
                }
                if ($scope.hideAxisDecorations) {
                    options.xaxes.axisLabelPadding = 0;
                    options.yaxes.axisLabelPadding = 0;
                    options.grid.minBorderMargin = 0;
                    options.grid.labelMargin = 0;
                    options.grid.axisMargin = 0;
                    options.xaxis.show = false;
                    options.yaxis.show = false;
                }
                
                var data = $scope.data == null ? [] : [$scope.data];
                chartElement.plot(data, options);
                
                var hoverFn = function (event, pos, item) {
                    if (item) {
                        TooltipService.hide();
                        var content;
                        if ($scope.tooltipFunction) {
                            content = $scope.tooltipFunction(item.datapoint[0], item.datapoint[1], $scope.tooltipUnit); 
                        } else {
                            content = '<h3>' + $scope.tooltipTitle + '</h3>';
                            content += '<h5>' + moment(item.datapoint[0]).format('MMMM Do YYYY, h:mm:ss a') + '</h5>';
                            content += '<h5>' + $filter('aqnumber')(item.datapoint[1]) + ' ' +  $scope.tooltipUnit + '</h5>';
                        }
                        TooltipService.show(pos.pageX, pos.pageY, content);
                    } else {
                        TooltipService.hide();
                    }
                };
                
                chartElement.unbind("plothover", hoverFn);
                chartElement.bind("plothover", hoverFn);
            }
            $scope.$watch("[data, min, max, xaxisLabel, yaxisLabel, tooltipFunction]", plot, true);
        }]
    }
});
