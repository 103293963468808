angular
    .module('aq.reports')
    .config(function ($stateProvider) {
    $stateProvider
        .state('aq.reports.projectStatus', {
        url: '/summary/projectMonthlyStatus',
        controller: 'ProjectStatusCtrl',
        controllerAs: 'vm',
        templateUrl: 'app/reports/summary/projectStatus/projectStatus.html',
        resolve: {
            options: function (OptionsService, account) {
                return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
            },
            metrics: function (Restangular, account, waitForAuthToken) {
                return Restangular.one('accounts', account.id).customGET('queryRealUnits');
            },
            buildings: function (waitForAuthToken, RestangularV3) {
                return RestangularV3.all('buildings').getList().then(function (buildings) {
                    return buildings.filter(function (b) { return b.status == 'ACTIVE' && b.archived == false; });
                });
            },
            users: function (Restangular, DataStore, account, waitForAuthToken) {
                return DataStore.getList(Restangular.one('accounts', account.id), 'users', {});
            },
            startDate: function ($location) {
                moment.locale('en-us');
                var date = null;
                if ($location.search().startDate) {
                    date = moment(parseInt($location.search().startDate));
                }
                else {
                    date = moment().subtract(1, 'month');
                }
                return date.startOf('month');
            },
            statuses: function (ProjectServiceV3, buildings, waitForAuthToken) {
                if (buildings.length === 0) {
                    return [];
                }
                var buildingId = parseInt(_.first(buildings).id);
                return ProjectServiceV3.getProjects(buildingId).then(function (projectResponse) {
                    return projectResponse.statuses;
                });
            },
            completedProjectsTimeInProgressMap: function (ProjectServiceV3, buildings, startDate, statuses) {
                var dateFormat = 'MM/DD/YYYY';
                var startDateFormatted = startDate.format(dateFormat);
                var endDateFormatted = moment(startDate).add(1, 'month').format(dateFormat);
                var completedStatusId = _.find(statuses, function (s) { return s.name === 'Completed'; }).id;
                var buildingIds = _.map(buildings, function (b) { return b.id; });
                var requestCompleted = {
                    buildingIds: buildingIds,
                    statusIds: [completedStatusId],
                    startDate: startDateFormatted,
                    endDate: endDateFormatted
                };
                var inProgressStatusId = _.find(statuses, function (s) { return s.name === 'In Progress'; }).id;
                return ProjectServiceV3.getStatusDetails(requestCompleted)
                    .then(function (results) {
                    var projectTimeMap = {};
                    results.forEach(function (item) {
                        projectTimeMap[item.projectId] = item.timeInStatus && item.timeInStatus[inProgressStatusId] || 0;
                    });
                    return projectTimeMap;
                });
            },
            projects: function (ProjectServiceV3, $q, buildings, statuses, startDate, users, completedProjectsTimeInProgressMap, waitForAuthToken, Auth) {
                var _a, _b;
                var pastStartDate = moment(startDate).subtract(1, 'month');
                var Priority = aq.models.projects.ProjectPriority;
                var priorityMap = (_a = {},
                    _a[Priority.HIGH] = 3,
                    _a[Priority.MEDIUM] = 2,
                    _a[Priority.LOW] = 1,
                    _a);
                var priorityLabel = (_b = {},
                    _b[Priority.HIGH] = 'High',
                    _b[Priority.MEDIUM] = 'Medium',
                    _b[Priority.LOW] = 'Low',
                    _b);
                var selectedStatuses = _.filter(statuses, function (status) {
                    return _.includes(['Declined', 'Needs Acceptance', 'Backlog', 'In Progress', 'Completed'], status.name);
                });
                var getImpactDisplayItem = function (lineItem) {
                    var display = lineItem && lineItem.score ? Math.abs(lineItem.score).toString() : '';
                    var cssClass = null;
                    if (lineItem && lineItem.score) {
                        cssClass = lineItem.score > 0 ? 'dark-green' : 'red';
                    }
                    return {
                        display: display,
                        cssClass: cssClass
                    };
                };
                var results = _.map(buildings, function (building) {
                    var buildingId = parseInt(building.id);
                    return ProjectServiceV3.getProjects(buildingId).then(function (projectResponse) {
                        var projects = projectResponse.projects.filter(function (project) {
                            return _.find(selectedStatuses, function (status) { return status.id == project.status.id; });
                        });
                        var projectItems = projects
                            .map(function (p) {
                            var assignee = _.find(users, function (user) { return user.id == p.assignee; });
                            var assigneeName = p.assignee
                                ? (assignee && assignee.firstName + " " + assignee.lastName) || 'Unknown'
                                : '-';
                            var impactLineItems = p.impact && p.impact.impactLineItems || [];
                            var reliabilityLineItem = impactLineItems.find(function (item) { return item.impactType == aq.models.projects.ImpactType.RELIABILITY; });
                            var comfortLineItem = impactLineItems.find(function (item) { return item.impactType == aq.models.projects.ImpactType.COMFORT; });
                            var safetyLineItem = impactLineItems.find(function (item) { return item.impactType == aq.models.projects.ImpactType.SAFETY; });
                            var isProjectEstimationEnabled = Auth.hasFunctionality(PROJECT_ESTIMATION);
                            var estimatedSavings = ProjectServiceV3.getImpactValue(p, isProjectEstimationEnabled, true, 'USD').projectImpact;
                            var estimatedSavingsEnergy = ProjectServiceV3.getImpactValue(p, isProjectEstimationEnabled, true, 'KWH').projectImpact;
                            var project = {
                                code: p.code,
                                projectId: p.id,
                                name: p.title,
                                priorityOrder: priorityMap[p.priority],
                                priorityLabel: priorityLabel[p.priority],
                                status: p.status.name === 'Needs Acceptance' ? 'Needs Acc.' : p.status.name,
                                startDate: p.issue && p.issue.identifiedDate && moment(p.issue.identifiedDate).format('MM/DD/YYYY') || null,
                                dateCreated: moment(p.createdOn).format('MM/DD/YYYY'),
                                dateCompleted: p.completedOn ? moment(p.completedOn).format('MM/DD/YYYY') : '',
                                estimatedCost: p.cost && p.cost.totalCost || 0,
                                estimatedSavings: estimatedSavings,
                                estimatedSavingsEnergy: estimatedSavingsEnergy,
                                suffix: '',
                                isNew: moment(p.createdOn).isSame(startDate, 'month'),
                                isPastNew: moment(p.createdOn).isSame(pastStartDate, 'month'),
                                isCompleted: p.status.name == 'Completed',
                                isInProgress: p.status.name === 'In Progress',
                                assignee: assigneeName,
                                impact: {
                                    reliability: getImpactDisplayItem(reliabilityLineItem),
                                    comfort: getImpactDisplayItem(comfortLineItem),
                                    safety: getImpactDisplayItem(safetyLineItem)
                                },
                                daysInProgress: completedProjectsTimeInProgressMap[p.id] > 0
                                    ? Math.ceil(completedProjectsTimeInProgressMap[p.id] / 86400000)
                                    : 0
                            };
                            if (p.projectType == aq.models.projects.ProjectType.IMPROVEMENT) {
                                project.suffix = '/yr';
                            }
                            return project;
                        });
                        var buildingProjects = {
                            building: buildingId,
                            projectItems: projectItems
                        };
                        return buildingProjects;
                    });
                });
                return $q.all(results).then(function (responses) {
                    var buildingProjectsMap = {};
                    _.each(responses, function (response) {
                        buildingProjectsMap[response.building] = response.projectItems;
                    });
                    return buildingProjectsMap;
                });
            },
            newProjectsSummaryData: function (projects) {
                var newItems = [];
                var pastNewItems = [];
                Object.keys(projects).forEach(function (key) {
                    var projectItems = projects[key];
                    newItems.push.apply(newItems, _.filter(projectItems, function (p) { return p.isNew; }));
                    pastNewItems.push.apply(pastNewItems, _.filter(projectItems, function (p) { return p.isPastNew; }));
                });
                var mapBuildingsProjectsItemToProjectStatusDetails = function (item) {
                    var mappedItem = {
                        buildingId: null,
                        currentStatusId: null,
                        dollarSavingsMax: item.estimatedSavings,
                        energySavingsMax: item.estimatedSavingsEnergy,
                        projectId: item.projectId,
                        timeInStatus: null
                    };
                    return mappedItem;
                };
                return {
                    current: newItems.map(function (item) { return mapBuildingsProjectsItemToProjectStatusDetails(item); }),
                    trailing: pastNewItems.map(function (item) { return mapBuildingsProjectsItemToProjectStatusDetails(item); })
                };
            },
            completedProjectsSummaryData: function (ProjectServiceV3, buildings, startDate, statuses) {
                var dateFormat = 'MM/DD/YYYY';
                var startDateFormatted = startDate.format(dateFormat);
                var endDateFormatted = moment(startDate).add(1, 'month').format(dateFormat);
                var trailingStartDate = moment(startDate).subtract(1, 'month').format(dateFormat);
                var trailingEndDate = startDateFormatted;
                var buildingIds = _.map(buildings, function (b) { return b.id; });
                var completedStatusId = _.find(statuses, function (s) { return s.name === 'Completed'; }).id;
                var requestCompleted = {
                    buildingIds: buildingIds,
                    statusIds: [completedStatusId],
                    startDate: startDateFormatted,
                    endDate: endDateFormatted
                };
                var requestCompletedTrailing = {
                    buildingIds: buildingIds,
                    statusIds: [completedStatusId],
                    startDate: trailingStartDate,
                    endDate: trailingEndDate
                };
                var results = [];
                results.push(ProjectServiceV3.getStatusDetails(requestCompleted));
                results.push(ProjectServiceV3.getStatusDetails(requestCompletedTrailing));
                return Promise.all(results).then(function (_a) {
                    var resultCompleted = _a[0], resultCompletedTrailing = _a[1];
                    return {
                        current: resultCompleted,
                        trailing: resultCompletedTrailing
                    };
                });
            },
            inProgressProjectsSummaryData: function (ProjectServiceV3, buildings, startDate, statuses) {
                var dateFormat = 'MM/DD/YYYY';
                var startDateFormatted = startDate.format(dateFormat);
                var endDateFormatted = moment(startDate).add(1, 'month').format(dateFormat);
                var trailingEndDate = startDateFormatted;
                var buildingIds = _.map(buildings, function (b) { return b.id; });
                var inProgressStatusId = _.find(statuses, function (s) { return s.name === 'In Progress'; }).id;
                var requestInProgress = {
                    buildingIds: buildingIds,
                    statusIds: [inProgressStatusId],
                    startDate: null,
                    endDate: endDateFormatted
                };
                var requestInProgressTrailing = {
                    buildingIds: buildingIds,
                    statusIds: [inProgressStatusId],
                    startDate: null,
                    endDate: trailingEndDate
                };
                var results = [];
                results.push(ProjectServiceV3.getStatusDetails(requestInProgress));
                results.push(ProjectServiceV3.getStatusDetails(requestInProgressTrailing));
                return Promise.all(results).then(function (_a) {
                    var resultInProgress = _a[0], resultInProgressTrailing = _a[1];
                    return {
                        current: resultInProgress,
                        trailing: resultInProgressTrailing
                    };
                });
            }
        }
    });
});
