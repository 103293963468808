var aq;
(function (aq) {
    var HelpActions = aq.models.segment.HelpActions;
    var MainHelpCtrl = /** @class */ (function (_super) {
        __extends(MainHelpCtrl, _super);
        /* @ngInject */
        function MainHelpCtrl($mdDialog, AppcuesService, Messages, $window, Segment, account, UserService, Auth) {
            var _this = _super.call(this, {}, $mdDialog) || this;
            _this.$mdDialog = $mdDialog;
            _this.AppcuesService = AppcuesService;
            _this.Messages = Messages;
            _this.$window = $window;
            _this.Segment = Segment;
            _this.account = account;
            _this.UserService = UserService;
            _this.Auth = Auth;
            _this.helpLink = 'http://help.aquicore.com/';
            _this.webinarLink = 'https://aquicore.zoom.us/webinar/register/WN_Np8xE6EoTN6dvzqexlyo_w';
            _this.isAppcuesEnabled = _this.AppcuesService.isEnabled;
            if (_this.isAppcuesEnabled && !Auth.hasFunctionality('App Restructure')) {
                _this.AppcuesService.getFlows().then(function (flows) {
                    _this.appcueFlows = flows;
                });
            }
            _this.intercom = _this.$window['Intercom'];
            if (_this.intercom) {
                _this.isIntercomEnabled = true;
            }
            _this.account = account;
            return _this;
        }
        MainHelpCtrl.prototype.onFlowClick = function (flow) {
            if (this.AppcuesService.startFlow(flow)) {
                this.$mdDialog.hide();
            }
            else {
                this.Messages.error("Guide '" + flow.name + "' is not available");
            }
        };
        MainHelpCtrl.prototype.openChat = function () {
            var buildingIds = this.account.buildings;
            if (!this.UserService.currentUser.currentProfile.hasFullVisibility) {
                buildingIds = this.UserService.currentUser.currentProfile.visbilities;
            }
            this.Segment.trackChurnZeroEvent(HelpActions.HELP_REQUEST_INTERCOM, buildingIds);
            this.intercom('showMessages');
            this.$mdDialog.hide();
        };
        MainHelpCtrl.prototype.openDocs = function () {
            var buildingIds = this.account.buildings;
            if (!this.UserService.currentUser.currentProfile.hasFullVisibility) {
                buildingIds = this.UserService.currentUser.currentProfile.visbilities;
            }
            this.Segment.trackChurnZeroEvent(HelpActions.HELP_REQUEST_GUIDE, buildingIds);
            this.$window.open(this.helpLink, '_blank');
            this.$mdDialog.hide();
        };
        MainHelpCtrl.prototype.openWebinarRegistration = function () {
            var buildingIds = this.account.buildings;
            if (!this.UserService.currentUser.currentProfile.hasFullVisibility) {
                buildingIds = this.UserService.currentUser.currentProfile.visbilities;
            }
            this.Segment.trackChurnZeroEvent(HelpActions.HELP_REQUEST_WEBINAR, buildingIds);
            this.$window.open(this.webinarLink, '_blank');
            this.$mdDialog.hide();
        };
        return MainHelpCtrl;
    }(aq.common.Controllers.ModalCtrl));
    aq.MainHelpCtrl = MainHelpCtrl;
    angular.module('aq').controller('MainHelpCtrl', MainHelpCtrl);
})(aq || (aq = {}));
