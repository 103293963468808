namespace aq.tenantbilling {
    export class ReviewChargesStepCtrl {
        public monthlyBilling: MonthlyTenantBilling;
        public comparisonPeriod: string;
        public billings: MonthlyTenantBilling[];
        public onComplete: Function;
        public tenantInvoices: TenantInvoice[];
        public tenantChargeValues: TenantChargeValue[];
        public periodTenantServices: PeriodTenantService[];
        public building: aq.common.models.Building;
        public currencyUnit: any;
        public selectedChargeValue: TenantChargeValue[];
        public showReadingDetails: { checked: boolean };
        public status: Status;
        public loadingBillings: boolean;
        public calcInterval = null;
        private CALC_MAX_CHECKS = 60;
        private CALC_CHECK_INTERVAL_MS = 1000;
        tenantLabel: string;
        /* @ngAnnotate */
        constructor(
            private $q: ng.IQService,
            private $location: ng.ILocationService,
            public $mdDialog: ng.material.IDialogService,
            private $interval: ng.IIntervalService,
            private Messages: aq.services.Messages,
            private $mdStepper,
            private OptionsService,
            private RestangularV3: restangular.IService,
            private MonthlyBillingService: MonthlyBillingService,
            private $scope,
            private Auth: aq.services.Auth
        ) {
            this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'units' : 'tenants';
        }

        public $onInit() {
            this.currencyUnit = this.OptionsService.currencyUnit();
            this.selectedChargeValue = [];
            this.showReadingDetails = {
                checked: false
            };
            this.status = {
                querying: false,
                calculating: false,
                text: null,
                counter: 0,
                isDisabled: false
            };
        }
        public previousStep() {
            const previousStepNumber = this.$mdStepper('runInvoicingStepper').currentStep;
            this.$location.search('step', previousStepNumber);
            this.$mdStepper('runInvoicingStepper').back();
        }
        public needsUserAction() {
            return _.some(this.tenantChargeValues, { needsUserAction: true });
        }
        public onStepLoad() {
            this.resetComparisonPeriod();
            this.calculatingCheck();
            if (!this.billings) {
                this.loadingBillings = true;
                this.MonthlyBillingService.getList({
                    buildingId: this.building.id,
                    status: this.MonthlyBillingService.approvedStatuses
                })
                    .then((billings) => {
                        this.billings = this.filterUnapprovedAndInvalidPeriods(billings);
                    })
                    .finally(() => {
                        this.loadingBillings = false;
                    });
            } else {
                this.billings = this.filterUnapprovedAndInvalidPeriods(this.billings);
            }
        }

        // Invalid periods are ones that overlap with current or are in the future compared to current
        public filterUnapprovedAndInvalidPeriods(billings: MonthlyTenantBilling[]) {
            return _.filter(billings, (billing: MonthlyTenantBilling) => {
                return this.MonthlyBillingService.isApprovedStatus(billing.status)
                    && !moment(billing.readingDate).isAfter(moment(this.monthlyBilling.startReadingDate));
            });
        }

        public resetComparisonPeriod() {
            this.comparisonPeriod = this.monthlyBilling.pastMonthlyBilling;
        }

        public updateComparisonPeriod(pastMonthlyBillingId: string) {
            this.status.text = 'Updating Comparison Period...';
            this.onStatusChange();
            this.status.isDisabled = true;
            this.MonthlyBillingService.updatePastMonthlyBilling(this.monthlyBilling, pastMonthlyBillingId)
                .then(() => {
                    this.startCalculatingCheck();
                });
        }

        public calculatingCheck() {
            if (this.monthlyBilling && this.monthlyBilling.id) {
                this.MonthlyBillingService.getCalculating(this.monthlyBilling.id)
                    .then((calc) => {
                        if (calc === 'true' && !this.calcInterval) {
                            this.startCalculatingCheck();
                        }
                    });
            }
        }

        public saveAndContinue(ev) {
            const currentStepNumber = this.$mdStepper('runInvoicingStepper').currentStep + 1;
            this.$location.search('step', currentStepNumber + 1);
            this.$mdStepper('runInvoicingStepper').next();
        }

        viewChargeValueGraph = (chargeValue: TenantChargeValue) => {
            this.editChargeValue(chargeValue);
        }

        public showModalBulkResolveAnomalies() {
            this.$mdDialog.show({
                controller: 'BulkResolveAnomaliesCtrl as vm',
                templateUrl: 'app/tenantBilling/invoicing/run/bulkResolveAnomalies/bulkResolveAnomalies.html',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                locals: {
                    tenantChargeValues: this.tenantChargeValues
                }
            }).then((results) => {
                _.each(results, (chargeValue) => {
                    const index = _.findIndex(this.tenantChargeValues, { id: chargeValue.id });
                    this.tenantChargeValues.splice(index, 1, chargeValue);
                });
                this.Messages.success('Anomalies have been resolved');
            });
        }

        public recalculate() {
            this.status.text = 'Starting recalculation';
            this.onStatusChange();
            this.status.isDisabled = true;
            this.MonthlyBillingService.recalculate(this.monthlyBilling)
                .then(() => {
                    this.calculatingCheck();
                })
                .catch(() => {
                    this.Messages.error('Error starting recalculation');
                    this.status.text = null;
                    this.onStatusChange();
                    this.status.isDisabled = false;
                });
        }

        public showModalEditPeriod() {
            this.$mdDialog.show({
                controller: 'EditInvoicingPeriodCtrl as vm',
                templateUrl: 'app/tenantBilling/invoicing/run/editInvoicingPeriod/editInvoicingPeriod.html',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                locals: {
                    monthlyBilling: this.monthlyBilling
                },
                resolve: {
                    tenantServices: () => {
                        return this.RestangularV3.all('tenant-services')
                            .getList({ buildingId: this.building.id });
                    },
                    tenants: () => {
                        return this.RestangularV3.all('tenants')
                            .getList({ buildingId: this.building.id });
                    },
                    tenantCharges: () => {
                        return this.RestangularV3.all('tenant-charges').all('by-building')
                            .getList({ buildingId: this.building.id });
                    },
                    enabledSettings: () => {
                        return this.RestangularV3.one('billings', this.monthlyBilling.id)
                            .one('enabled-settings').get();
                    }
                }
            } as any).then(() => {
                this.startCalculatingCheck();
            });
        }

        public startCalculatingCheck(chargeValue?: TenantChargeValue) {
            this.status.text = 'Calculating charges. This can take up to a minute.';
            this.onStatusChange();
            this.status.isDisabled = true;
            this.status.calculating = true;
            this.calcInterval = this.$interval(() => this.getCalculating(chargeValue), this.CALC_CHECK_INTERVAL_MS);
            this.$scope.$on('$destroy', () => {
                if (this.calcInterval) {
                    this.$interval.cancel(this.calcInterval);
                }
            });
        };

        public endCalculatingCheck(erroredOut?: boolean) {
            if (this.calcInterval) {
                this.$interval.cancel(this.calcInterval);
                this.calcInterval = null;
            }
            if (erroredOut) {
                this.status.calculating = false;
                this.status.text = null;
                this.onStatusChange();
                this.status.isDisabled = false;
                this.status.counter = 0;
                this.Messages.error('There was an error calculating your charges. Please try again, or contact an administrator if issue persists.', 15000);
            }
        };

        public updateChargeValue(chargeValue) {
            this.status.text = 'Updating charge value...';
            this.onStatusChange();
            this.status.isDisabled = true;
            return this.MonthlyBillingService.saveChargeValue(chargeValue)
                .then((result) => {
                    this.startCalculatingCheck(chargeValue);
                });
        }

        public editChargeValue(chargeValue: TenantChargeValue, activeTab = 'readings') {
            this.$mdDialog.show({
                templateUrl: 'app/tenantBilling/invoicing/run/editChargeValue/editChargeValue.html',
                controller: 'EditChargeValueCtrl',
                controllerAs: 'vm',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                locals: {
                    monthlyBilling: this.monthlyBilling,
                    building: this.building,
                    chargeValue,
                    activeTab,
                    isReadOnly: false
                },
                resolve: {
                    device: () => {
                        return this.RestangularV3.all('devices').one('by-collector', chargeValue.collector).get()
                            .then((device) => {
                                if (!device) {
                                    return null;
                                }
                                return this.RestangularV3.restangularizeElement('', device, 'devices');
                            });
                    },
                    collectorHierarchy: () => {
                        return this.RestangularV3.one('collectors', chargeValue.collector).customGET('hierarchy');
                    }
                }
            } as any).then((res) => {
                return this.updateChargeValue(res);
            }).finally(() => {
                this.selectedChargeValue = [];
            });
        }

        public getCalculating(chargeValue?: TenantChargeValue) {
            if (this.monthlyBilling && this.monthlyBilling.id && !this.status.querying && this.status.calculating) {
                this.status.querying = true;
                this.MonthlyBillingService.getCalculating(this.monthlyBilling.id)
                    .then((calc) => {
                        return this.handleCalculating(calc, chargeValue);
                    })
                    .then((results) => {
                        this.handleFetchResults(results, chargeValue);
                    })
                    .catch(() => {
                        this.endCalculatingCheck(true);
                    });
            }
        }

        public handleCalculating(calc, chargeValue: TenantChargeValue) {
            this.status.counter++;
            this.status.querying = false;
            const isCalculating = calc === 'true';
            // When finished calculating, update our charges
            if (this.status.calculating && !isCalculating) {
                this.status.counter = 0;
                this.status.calculating = isCalculating;
                this.endCalculatingCheck();
                if (chargeValue) {
                    return this.$q.all([
                        this.MonthlyBillingService.get(this.monthlyBilling.id),
                        this.MonthlyBillingService.getInvoice(chargeValue.tenantInvoice),
                        this.MonthlyBillingService.getDependentChargeValues(chargeValue),
                        this.MonthlyBillingService.getPeriodTenantServices(this.monthlyBilling)
                    ]);
                } else {
                    return this.$q.all([
                        this.MonthlyBillingService.get(this.monthlyBilling.id),
                        this.MonthlyBillingService.getInvoices(this.monthlyBilling),
                        this.MonthlyBillingService.getChargeValues(this.monthlyBilling),
                        this.MonthlyBillingService.getPeriodTenantServices(this.monthlyBilling)
                    ]);
                }
            } else if (this.status.counter > this.CALC_MAX_CHECKS) {
                this.endCalculatingCheck(true);
            }
            return null;
        }

        public handleFetchResults(results, chargeValue: TenantChargeValue) {
            if (results) {
                if (chargeValue) {
                    this.parseChargeValueResults(results);
                } else {
                    this.parseAllResults(results);
                }
                this.resetComparisonPeriod();
                this.onComplete({
                    $event: {
                        monthlyBilling: this.monthlyBilling,
                        tenantInvoices: this.tenantInvoices,
                        tenantChargeValues: this.tenantChargeValues,
                        periodTenantServices: this.periodTenantServices
                    }
                });
                this.status.text = null;
                this.onStatusChange();
                this.status.isDisabled = false;
                this.Messages.success('Successfully updated billing period. Charges have been updated.');
            }
        }

        public parseChargeValueResults(results) {
            this.monthlyBilling = results[0];
            const invoiceIndex = _.findIndex(this.tenantInvoices, { id: results[1].id });
            this.tenantInvoices.splice(invoiceIndex, 1, results[1]);
            _.each(results[2], (updatedCharge) => {
                const chargeIndex = _.findIndex(this.tenantChargeValues, { id: updatedCharge.id });
                this.tenantChargeValues.splice(chargeIndex, 1, updatedCharge);
            });
            // need this or else our values won't update on our table
            this.tenantInvoices = this.tenantInvoices.slice();
            this.tenantChargeValues = this.tenantChargeValues.slice();
            this.periodTenantServices = results[3];
        }

        public parseAllResults(results) {
            this.monthlyBilling = results[0];
            this.tenantInvoices = results[1];
            this.tenantChargeValues = results[2];
            this.periodTenantServices = results[3];
        }

        public onStatusChange() {
            this.$scope.$emit('STATUS_CHANGE', {statusText: this.status.text});
        }
    }

    export interface Status {
        querying: boolean;
        calculating: boolean;
        text: string;
        counter: number;
        isDisabled: boolean;
    }

    angular
        .module('tenantBilling')
        .component('reviewChargesStep', {
            templateUrl: 'app/tenantBilling/invoicing/run/components/reviewChargesStep/reviewChargesStep.html',
            controller: ReviewChargesStepCtrl,
            controllerAs: 'vm',
            bindings: {
                monthlyBilling: '<',
                billings: '<',
                tenantInvoices: '<',
                tenantChargeValues: '<',
                periodTenantServices: '<',
                building: '<',
                onComplete: '&'
            }
        });
}
