'use strict';

angular.module('aq.binaryfilereader', [])
.directive('aqBinaryFilereader', function($q) {

    return {
        restrict : 'A',
        require : '?ngModel',
        link : function(scope, element, attrs, ngModel: ng.INgModelController) {
            if (!ngModel)
                return;

            ngModel.$render = function() {
                if((element[0].parentNode as any).reset) {
                    (element[0].parentNode as any).reset();
                }
            }

            element.bind('change', function(e) {
                scope.$apply(function () {
                    ngModel.$setViewValue((e.target as any).files[0]);
                });
            });
        }
    };
});
