angular.module('aq.accounts.calendars').controller('RuleFormCtrl',
    function($scope, $stateParams, $state, $filter, CalendarData, EventPriority, Messages, Errors, UserService) {

    $scope.isTwelveHourFormat = !UserService.isTwentyFourFormat();


    $scope.newRule = {
        name: '',
        activeState: true,
        dateEnd: null,
        dateStart: null,
        isPublic: false,
        isDefault: true,
        priority: 1,
        timeStart: '09:00:00',
        timeEnd: '17:00:00',
        week: CalendarData.workDays
    };

    $scope.$watch('rule.category', function(category) {
       if (!category || !$scope.ruleCategories) return;

       var category = category.id || category;

       $scope.categoryObj  = _.find($scope.ruleCategories, {id: parseInt(category)});

       $scope.rule.color = $scope.categoryObj.color;

    }, true);

    $scope.$watch('workCalendarRules', function (workCalendarRules){

        if (!workCalendarRules) return;

        if ($stateParams.ruleId) {
            $scope.bulkMode = false;
            $scope.rule = _.findById($scope.workCalendarRules, $stateParams.ruleId);

            $state.$current.data.breadcrumb = $scope.rule.name;
        } else {
            $scope.rule = $scope.newRule;
            $scope.bulkMode = true;
        }

    })

    $scope.createRule = function (rule) {
        $scope.create = true;
        $scope.saveRule(rule);
    }

    $scope.cancelRule = function() {
        $state.transitionTo('aq.accounts.calendars.rules.views', { accountId: $scope.account.id, calendarId: $scope.calendar.id });
    }

    $scope.saveRule = function (model) {
        if (!$scope.rule) return;
        if ($scope.rule.id) {
            _.extend($scope.rule, model);
            return $scope.rule.put().then(function() {
                Messages.success('Calendar event saved successfully');
            }, Errors.forCRUD('UPDATE'));
        } else {
            model.priority = EventPriority.getEventLevel($scope.rule);
            _.extend($scope.rule, model);

            if ($scope.rule.week.length === 0) {
                $scope.rule.week = CalendarData.allWeek;
            };

            if ($scope.create) {
                $scope.account
                    .one('workCalendars', $scope.calendar.id)
                    .post('rules', $scope.rule)
                    .then(function (result) {
                        $scope.rule.id = result.id;
                        $scope.workCalendarRules.push(_.extend(result, result.model));
                        $scope.workCalendarRules.sort(function(a,b) {
                            return b.priority - a.priority;
                        });
                        $scope.newRule = false;
                        Messages.success("Successfully created event!");
                        $state.transitionTo('aq.accounts.calendars.rules.views.form', { accountId: $scope.account.id , calendarId: $scope.calendar.id, ruleId: result.id});
                    }, function (errors) {
                        if (_.find(errors.data.errors, {field: "updated.name"})) {
                            Messages.error("Please include an event name!");
                        } else {
                            Messages.error("Cannot create event!");
                        }
                    });
            };
        }
    };

        $scope.deleteRule = function (rule) {
            rule.remove().then(
                function () {
                    if ($scope.workCalendarRules.length != 0) {
                        _.withoutInline($scope.workCalendarRules, rule);
                        _.withoutInline($scope.calendar.rules, rule);
                        var ruleId = null;
                        if ($scope.workCalendarRules.length != 0) {
                            ruleId = _.first($scope.workCalendarRules).id
                        } else {
                            $scope.newRuleForm = false;
                        }
                        Messages.success("Successfully deleted event!");
                        $state.transitionTo('aq.accounts.calendars.rules.views.form', { calendarId: $scope.calendar.id, accountId: $scope.account.id, ruleId: ruleId });
                    }

                    if ($scope.workCalendarRules == 0) {
                        $state.transitionTo('aq.accounts.calendars.rules.views', { calendarId: $scope.calendar.id , accountId: $scope.account.id});
                    };
                },
                function () {
                    Messages.error("Error deleting rule");
                }
            );
        }

});
