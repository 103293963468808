namespace aq.dashboard.widgets {
    export class MarkdownCtrl {
        private building: aq.common.models.Building;
        /* @ngInject */
        constructor(
            private $scope,
            private buildings: Array<aq.common.models.Building>,
            private config,
            private ModelUtilService: aq.services.ModelUtilService
        ) {
            this.$scope.config = config;
            this.$scope.config.actions = {
                onBuildingChange: (cfg, buildingId) => {
                    cfg.buildingId = buildingId;
                }
            };

            this.setBuilding(this.$scope.config.buildingId);

            config.options = {
                buildings: ModelUtilService.pareProperties(buildings)
            };

            if (!config.content) {
                config.content = '';
            }

            this.$scope.params = {
                building: this.building
            };
        }

        public setBuilding(buildingId) {
            this.building = _.find(this.buildings, {'id': parseInt(buildingId)}) || this.buildings[0];
            this.$scope.config.buildingId = this.building.id;
        }
    }
    angular.module('aq.dashboard.widgets').controller('MarkdownCtrl', MarkdownCtrl);
}
