var aq;
(function (aq) {
    var reports;
    (function (reports) {
        var alert;
        (function (alert) {
            var AlertReportCtrl = /** @class */ (function (_super) {
                __extends(AlertReportCtrl, _super);
                function AlertReportCtrl(date, schedulingInterval, startDate, account, buildings, buildingGroups, alerts, buildingPersonnels, $filter, $timeout) {
                    var _this = _super.call(this, $timeout) || this;
                    _this.date = date;
                    _this.schedulingInterval = schedulingInterval;
                    _this.startDate = startDate;
                    _this.account = account;
                    _this.buildings = buildings;
                    _this.buildingGroups = buildingGroups;
                    _this.alerts = alerts;
                    _this.buildingPersonnels = buildingPersonnels;
                    _this.$filter = $filter;
                    _this.$timeout = $timeout;
                    _this.pieChartOptions = {
                        width: 380,
                        legend: {
                            enabled: true,
                            align: 'right',
                            verticalAlign: 'middle',
                            layout: 'vertical'
                        }
                    };
                    _this.alertTimeStampFormat = 'MMM Do, H:mm a';
                    _this.personas = {
                        'ASSET_MANAGER': 'Asset Manager',
                        'PROPERTY_MANAGER': 'Property Manager',
                        'BUILDING_ENGINEER': 'Building Engineer'
                    };
                    var formattedDate = moment(_this.date).utc().format('YYYY-MM-DD');
                    var reportDate = moment.tz(formattedDate, _this.account.timeZoneId);
                    var start = _this.getStartDate(reportDate);
                    _this.reportDateView = start.format('M/D/YY') + " - " + reportDate.format('M/D/YY');
                    _this.reportName = "Weekly Summary (" + _this.reportDateView + ")";
                    _this.initReportItems();
                    _this.initBuildingGroupAndAccountSummary();
                    _this.notifyDocumentReady();
                    return _this;
                }
                AlertReportCtrl.prototype.initReportItems = function () {
                    var _this = this;
                    this.data = [];
                    this.groupData = [];
                    _.each(this.buildings, function (building) {
                        var buildingAlertItem = _.find(_this.alerts.byBuilding, function (item) { return item.buildingId == building.id; });
                        var buildingReportItem = {
                            building: _this.getBuildingViewItem(building),
                            alertItems: _this.getBuildingAlertViewItems(buildingAlertItem),
                            summary: _this.getBuildingAlertStatSummary(buildingAlertItem)
                        };
                        _this.data.push(buildingReportItem);
                        var buildingGroupItem = _this.getOrCreateBuildingGroupItem(building);
                        buildingGroupItem.groupBuildings.push(buildingReportItem);
                    });
                };
                AlertReportCtrl.prototype.initBuildingGroupAndAccountSummary = function () {
                    var _this = this;
                    var totalOpenTime = 0;
                    var totalCount = 0;
                    _.each(this.groupData, function (group) {
                        var totalGroupOpenTime = 0;
                        var totalGroupCount = 0;
                        var aggData0;
                        var aggData1;
                        _.each(group.groupBuildings, function (item) {
                            totalGroupCount += item.summary.closed;
                            totalGroupOpenTime += item.summary.avgCloseTimeValue * item.summary.closed;
                            var byDayData = item.summary.byDayData;
                            if (byDayData) {
                                if (!aggData0) {
                                    aggData0 = angular.copy(byDayData.series[0].data);
                                    aggData1 = angular.copy(byDayData.series[1].data);
                                }
                                else {
                                    _.each(aggData0, function (item, index) {
                                        aggData0[index] += byDayData.series[0].data[index];
                                        aggData1[index] += byDayData.series[1].data[index];
                                    });
                                }
                            }
                        });
                        group.summary.total = _.sumBy(group.groupBuildings, function (item) { return item.summary.total; });
                        group.summary.open = _.sumBy(group.groupBuildings, function (item) { return item.summary.open; });
                        group.summary.closed = _.sumBy(group.groupBuildings, function (item) { return item.summary.closed; });
                        group.summary.escalated = _.sumBy(group.groupBuildings, function (item) { return item.summary.escalated; });
                        var avg = totalGroupCount ? Math.round(totalGroupOpenTime / totalGroupCount) : 0;
                        group.summary.avgCloseTimeValue = avg;
                        group.summary.avgCloseTime = _this.getTime(avg);
                        totalOpenTime += totalGroupOpenTime;
                        totalCount += totalGroupCount;
                        var groupByDayData = angular.copy(_.first(group.groupBuildings).summary.byDayData);
                        if (groupByDayData) {
                            groupByDayData.series[0].data = aggData0;
                            groupByDayData.series[1].data = aggData1;
                        }
                        group.summary.byDayData = groupByDayData;
                    });
                    this.summaryGroupData = this.getEmptyDataSummary();
                    this.summaryGroupData.total = _.sumBy(this.groupData, function (item) { return item.summary.total; });
                    this.summaryGroupData.open = _.sumBy(this.groupData, function (item) { return item.summary.open; });
                    this.summaryGroupData.closed = _.sumBy(this.groupData, function (item) { return item.summary.closed; });
                    this.summaryGroupData.escalated = _.sumBy(this.groupData, function (item) { return item.summary.escalated; });
                    var avgTime = totalCount ? Math.round(totalOpenTime / totalCount) : 0;
                    this.summaryGroupData.avgCloseTimeValue = avgTime;
                    this.summaryGroupData.avgCloseTime = this.getTime(avgTime);
                };
                AlertReportCtrl.prototype.getOrCreateBuildingGroupItem = function (building) {
                    var buildingGroupItem = _.find(this.groupData, function (group) { return building.buildingGroup != null && group.groupId == building.buildingGroup
                        || building.buildingGroup == null && group.groupId == 0; });
                    if (!buildingGroupItem) {
                        var buildingGroup = _.find(this.buildingGroups, function (bg) { return bg.id == building.buildingGroup; });
                        buildingGroupItem = {
                            groupId: buildingGroup ? buildingGroup.id : 0,
                            groupName: buildingGroup ? buildingGroup.name : 'Other',
                            groupBuildings: [],
                            summary: this.getEmptyDataSummary()
                        };
                        if (buildingGroup && buildingGroup.isIncomplete) {
                            buildingGroupItem.summary.isIncomplete = true;
                        }
                        this.groupData.push(buildingGroupItem);
                    }
                    return buildingGroupItem;
                };
                AlertReportCtrl.prototype.getTime = function (totalSeconds) {
                    return this.$filter('humanizeDurationDetailed')(totalSeconds);
                };
                AlertReportCtrl.prototype.getBuildingViewItem = function (building) {
                    var buildingState = building.state ? building.state.replace('District of Columbia', '') : '';
                    var buildingPersonnel = this.getBuildingPersonnel(building);
                    return {
                        id: building.id,
                        name: building.name,
                        imageUrl: building.imageUrl,
                        streetAddress: building.address,
                        cityAddress: building.city + ", " + buildingState + " " + building.zipCode,
                        personnel: buildingPersonnel
                    };
                };
                AlertReportCtrl.prototype.getBuildingAlertViewItems = function (buildingAlertItem) {
                    var _this = this;
                    if (!buildingAlertItem) {
                        return [];
                    }
                    var buildingStats = buildingAlertItem.stats;
                    var viewItems = _.map(buildingStats.alertIssues, function (item) {
                        var viewItem = {
                            name: item.alertName,
                            timeOpened: item.openedOn,
                            timeClosed: item.closedOn,
                            timeOpenedView: moment(item.openedOn).format(_this.alertTimeStampFormat),
                            timeClosedView: item.closedOn ? moment(item.closedOn).format(_this.alertTimeStampFormat) : null,
                            escalations: item.escalations,
                            violations: item.violations
                        };
                        return viewItem;
                    });
                    return viewItems;
                };
                AlertReportCtrl.prototype.getBuildingAlertStatSummary = function (buildingAlertItem) {
                    if (!buildingAlertItem || !buildingAlertItem.stats) {
                        return this.getEmptyDataSummary();
                    }
                    var stats = buildingAlertItem.stats;
                    var avgCloseTimeSeconds = this.getAvgCloseTime(stats);
                    var summary = {
                        total: stats.issuesTriggered ? stats.issuesTriggered : 0,
                        open: stats.openIssues ? stats.openIssues : 0,
                        closed: stats.closedIssues ? stats.closedIssues : 0,
                        escalated: stats.escalations ? stats.escalations : 0,
                        isIncomplete: false,
                        byAssigneeData: this.getByAssigneeData(stats),
                        byDayData: this.getByDayData(stats),
                        avgCloseTimeValue: avgCloseTimeSeconds,
                        avgCloseTime: this.getTime(avgCloseTimeSeconds)
                    };
                    return summary;
                };
                AlertReportCtrl.prototype.getAvgCloseTime = function (stats) {
                    var openTime = _.sumBy(stats.alertIssues, function (issue) {
                        if (!issue.closedOn) {
                            return 0;
                        }
                        var endTime = moment(issue.closedOn);
                        var diff = endTime.diff(issue.openedOn);
                        var duration = moment.duration(diff);
                        return duration.asSeconds();
                    });
                    var count = _.filter(stats.alertIssues, function (issue) { return issue.closedOn; }).length;
                    var avg = count ? Math.round(openTime / count) : 0;
                    return avg;
                };
                AlertReportCtrl.prototype.getByAssigneeData = function (buildingStats) {
                    var data = _.map(buildingStats.issuesByAssignee, function (item) {
                        return {
                            label: item.user ? item.user.fullName : 'none',
                            data: item.numberOfIssues
                        };
                    });
                    return { data: data, options: this.pieChartOptions };
                };
                AlertReportCtrl.prototype.getByDayData = function (buildingStats) {
                    var endDate = moment(this.date).utc().startOf('day').valueOf();
                    var startDate = this.getStartDate().valueOf();
                    var seriesData = [];
                    var seriesDataClosed = [];
                    var currentDate = moment(startDate);
                    var categories = [];
                    while (currentDate.isBefore(endDate)) {
                        var currentDateValue = _.find(buildingStats.issuesByDay, function (item) { return item.date == currentDate.format('YYYY-MM-DD'); });
                        var value = currentDateValue ? currentDateValue.numberOfIssues : 0;
                        seriesData.push(value);
                        categories.push(currentDate.format('MMM D'));
                        var closedIssues = _.filter(buildingStats.alertIssues, function (item) {
                            return moment(item.closedOn).isSame(currentDate, 'day');
                        });
                        currentDate.add(1, 'day');
                        seriesDataClosed.push(closedIssues.length);
                    }
                    var series = [{
                            name: 'Closed',
                            data: seriesDataClosed,
                            color: '#7ACD46'
                        }, {
                            name: 'Open',
                            data: seriesData,
                            color: '#E70F28'
                        }];
                    return this.getByDayChartConfigData(categories, series);
                };
                AlertReportCtrl.prototype.getByDayChartConfigData = function (categories, series) {
                    return {
                        chart: {
                            width: 380,
                            height: 160,
                            type: 'column'
                        },
                        plotOptions: {
                            column: {
                                stacking: 'normal'
                            }
                        },
                        xAxis: {
                            categories: categories
                        },
                        yAxis: {
                            title: { text: '' }
                        },
                        title: { text: '' },
                        legend: { enabled: true },
                        series: series,
                        navigation: {
                            buttonOptions: {
                                enabled: false
                            }
                        }
                    };
                };
                AlertReportCtrl.prototype.getBuildingPersonnel = function (building) {
                    var data = _.find(this.buildingPersonnels, { buildingId: building.id });
                    if (data && data.personnel && data.personnel.length > 0) {
                        _.each(data.personnel, function (item) {
                            if (!item.email) {
                                return;
                            }
                            var emailSplit = item.email.split('@');
                            if (emailSplit.length != 2) {
                                return;
                            }
                            item.emailUsername = emailSplit[0];
                            item.emailDomain = '@' + emailSplit[1];
                        });
                        var sortedPersonnel_1 = [];
                        _.each(this.personas, function (persona, key) {
                            var nextPerson = _.find(data.personnel, function (item) { return item.persona == key; });
                            if (nextPerson) {
                                sortedPersonnel_1.push(nextPerson);
                            }
                            else {
                                sortedPersonnel_1.push({
                                    persona: key,
                                    name: '-'
                                });
                            }
                        });
                        return sortedPersonnel_1;
                    }
                    else {
                        return [];
                    }
                };
                AlertReportCtrl.prototype.getStartDate = function (fromDate) {
                    if (this.startDate) {
                        var localDate = moment
                            .unix(this.startDate)
                            .utc()
                            .format('YYYY-MM-DD');
                        return moment.tz(localDate, this.account.timeZoneId);
                    }
                    else {
                        var epoch = fromDate
                            ? fromDate.valueOf()
                            : this.date;
                        var localDate = moment(epoch).utc().format('YYYY-MM-DD');
                        return moment.tz(localDate, this.account.timeZoneId).subtract(1, this.schedulingInterval);
                    }
                };
                AlertReportCtrl.prototype.getEmptyDataSummary = function () {
                    var item = {
                        isIncomplete: false,
                        total: 0,
                        open: 0,
                        closed: 0,
                        escalated: 0,
                        avgCloseTimeValue: null,
                        avgCloseTime: '',
                        byAssigneeData: null,
                        byDayData: null
                    };
                    return item;
                };
                return AlertReportCtrl;
            }(reports.BaseDocraptorReportCtrl));
            alert.AlertReportCtrl = AlertReportCtrl;
            angular
                .module('aq.reports')
                .controller('AlertReportCtrl', AlertReportCtrl);
        })(alert = reports.alert || (reports.alert = {}));
    })(reports = aq.reports || (aq.reports = {}));
})(aq || (aq = {}));
