namespace aq.reports {
    export class ProjectDetailController {
        private resultsChartConfig;
        private unit;
        private chartMeasures: aq.common.models.Measure[];

        /* @ngInject */
        constructor(
            private account,
            private date,
            private project: aq.projectCenter.Project,
            private building,
            private users: aq.common.models.User[],
            private measures: aq.common.models.SimpleEnum,
            private units: aq.common.models.Unit[],
            private projectResults: aq.projectCenter.ProjectResults,
            private $q: ng.IQService,
            private projectStatuses: aq.common.models.SimpleEnum,
            private projectTypes: aq.common.models.SimpleEnum
        ) {
            this.date = moment(this.date)
                .tz(account.timeZoneId)
                .format('DD MMMM YYYY');
            this.unit = _.find(units, { value: 'KW' });
            this.chartMeasures = _.filter(this.measures, { utility: true });
            this.fetchResultsConfig(this.project, this.building);
        }
        public getOwnerName(ownerId) {
            return _.find(this.users, { id: ownerId });
        }

        public getProjectTypeValue(type) {
            if (type != '') {
                return this.projectTypes[type].label;
            }
        }

        public getProjectStatusValue(status) {
            if (status != '') {
                return this.projectStatuses[status].label;
            }
        }

        public getOutStandingTasks(project){
            return project.tasks.filter(task => {
                return task.completionDate == null;
            });
        }
        private fetchResultsConfig(project: aq.projectCenter.Project, building) {
            return this.$q.all([
                this.project.customGET('data', {
                    start: moment(this.project.basePeriodStartDate).format(),
                    end: moment(this.project.reportingPeriodEndDate).format(),
                    unit: 'Expected Utility Spending'
                }),
                this.project.customGET('data', {
                    start: moment(this.project.basePeriodStartDate).format(),
                    end: moment(this.project.reportingPeriodEndDate).format(),
                    unit: 'Actual Utility Spending'
                })
            ])
                .then((result) => {
                    this.resultsChartConfig = this.buildChartConfig(this.project, result[0], result[1], building);
                });
        }

        getUtilitySavings(utility) {
            return _.find(this.projectResults.savingsPerUtility, { utility: utility.name });
        }

        private buildChartConfig(project: aq.projectCenter.Project, expectedData: aq.common.models.DataResponse, actualData: aq.common.models.DataResponse, building) {
            const expectedSeriesArea = this.buildExpectedSeriesArea(expectedData, actualData);
            const actualSeries = this.buildActualSeries(actualData, building);
            const expectedSeriesLine = this.buildExpectedSeriesLine(expectedData, actualData);
            return {
                chart: {
                    plotBorderWidth: 1
                },
                lang: {
                    noData: ''
                },
                title: {
                    text: ''
                },
                tooltip: {
                    formatter() {
                        return `${this.series.name}<br>${moment(this.point.x).format('MMM')} - $${this.point.y}`;
                    }
                },
                xAxis: {
                    type: 'datetime',
                    tickLength: 0,
                    gridLineWidth: 1,
                    gridLineColor: '#c7c7c7',
                    labels: {
                        formatter() {
                            return moment(this.value).add(1, 'day').format('MMM');
                        }
                    },
                    plotLines: [{
                        color: 'gray',
                        dashStyle: 'longdash',
                        value: moment(this.project.reportingPeriodStartDate).valueOf(),
                        width: 1,
                        label: {
                            text: 'Reporting Period Start',
                            verticalAlign: 'middle',
                            textAlign: 'center'
                        }
                    },
                    {
                        color: 'green',
                        dashStyle: 'longdash',
                        value: moment(this.project.reportingPeriodEndDate).valueOf(),
                        width: 2,
                        label: {
                            text: 'Reporting Period End',
                            verticalAlign: 'middle',
                            textAlign: 'center'
                        }
                    }]
                },
                yAxis: {
                    title: {
                        text: 'Spending ($)'
                    },
                    min: 0,
                    gridLineColor: '#c7c7c7',
                    gridLineWidth: 1
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: false
                        }
                    }
                },
                plotOptions: {
                    line: {
                        animation: false
                    },
                    series: {
                        events: {
                            legendItemClick: () => {
                                return false;
                            }
                        },
                        stickyTracking: false
                    }
                },
                series: [expectedSeriesArea, expectedSeriesLine, actualSeries]
            };
        }

        private buildExpectedSeriesLine(expectedData: aq.common.models.DataResponse, actualData: aq.common.models.DataResponse) {
            const dataSeries = {
                name: `Expected Utility Cost`,
                data: [],
                color: '#0091f1',
                zIndex: 2,
                dashStyle: 'shortdot',
                type: 'line'
            };
            expectedData.data.forEach(datum => {
                if (datum.value !== 0) {
                    const expectedTimestamp = moment(datum.timestamp).startOf('day').add(1, 'day').valueOf();
                    dataSeries.data.push({
                        x: expectedTimestamp,
                        y: datum.value ? Math.round(datum.value) : 0
                    });
                }
            });
            return dataSeries;
        }

        public buildExpectedSeriesArea(expectedData: aq.common.models.DataResponse, actualData: aq.common.models.DataResponse) {
            const dataSeries = {
                name: `Savings`,
                data: [],
                color: '#7ACD46',
                zIndex: 2,
                type: 'arearange'
            };
            expectedData.data.forEach(datum => {
                const expectedTimestamp = datum.timestamp; // moment(datum.timestamp).startOf('day').add(1, 'day').valueOf();
                const actualDatum = _.find(actualData.data, { timestamp: expectedTimestamp });
                if (actualDatum && actualDatum.value !== 0) {
                    dataSeries.data.push([expectedTimestamp, Math.round(actualDatum.value), Math.round(datum.value)]);
                }
            });
            return dataSeries;
        }

        private buildActualSeries(actualData: aq.common.models.DataResponse, building) {
            const dataSeries = {
                name: `Actual Utility Cost`,
                data: [],
                color: '#0091f1',
                zIndex: 2
            };
            actualData.data.forEach(datum => {
                if (datum.value !== 0) {
                    dataSeries.data.push({
                        x: moment(datum.timestamp).tz(building.timeZoneId).startOf('day').valueOf(),
                        y: datum.value ? Math.round(datum.value) : 0
                    });
                }
            });
            return dataSeries;
        }
    }
    angular
        .module('aq.reports')
        .controller('ProjectDetailController', ProjectDetailController);
}
