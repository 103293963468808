namespace aq.admin.configuration.utilityCompany.tariffSchedule {
    export class TariffChargeEditCtrl {
        /* @ngInject */
        constructor(
            private $scope: TariffChargeEditCtrlScope,
            private Messages,
            private tariffCharge: TariffCharge,
            private tariffBase
        ) {
            $scope.tariffCharge = tariffCharge;
            $scope.timeOptions = [''];
            for (let i = 0; i < 48; i++) {
                $scope.timeOptions.push(moment().startOf('day').add(i * 30, 'minutes').format('h:mm A'));
            }
            if ($scope.tariffCharge.tariffRule.timeStart && $scope.tariffCharge.tariffRule.timeStart.indexOf('M') == -1) {
                $scope.tariffCharge.tariffRule.timeStart = this.time24hTo12HrFormat($scope.tariffCharge.tariffRule.timeStart);
            }
            if ($scope.tariffCharge.tariffRule.timeEnd && $scope.tariffCharge.tariffRule.timeEnd.indexOf('M') == -1) {
                $scope.tariffCharge.tariffRule.timeEnd = this.time24hTo12HrFormat($scope.tariffCharge.tariffRule.timeEnd);
            }
        }

        save() {
            if (!this.$scope.tariffCharge.id) { // create
                this.tariffBase.all(this.$scope.tariffCharge.type.route).customPOST(this.$scope.tariffCharge).then((tariffCharge) => {
                    this.Messages.success(`Tariff Charge ${tariffCharge.model.name} Created`);
                    tariffCharge.model.type = this.$scope.tariffCharge.type;
                    tariffCharge.model.tariffRule = this.$scope.tariffCharge.tariffRule;
                    this.$scope.tariffSchedule.charges.push(tariffCharge.model);
                });
            } else { // edit
                this.tariffBase
                    .one(this.$scope.tariffCharge.type.route, this.$scope.tariffCharge.id)
                    .customPUT(this.$scope.tariffCharge)
                    .then((tariffCharge) => {
                    this.Messages.success(`Tariff Charge ${tariffCharge.model.name} Updated`);
                });
            }
        }

        /* input time is formatted as 24h with leading 0, e.g. 04:00:00 */
        /* output time is formatted as 12h without leading 0, e.g. 4:00 AM */
        public time24hTo12HrFormat(time: string): string {
            return moment(time, 'HH:mm:ss').format('h:mm A');
        }
    }

    interface TariffChargeEditCtrlScope extends  TariffScheduleDetailsCtrlScope {
        tariffCharge: TariffCharge;
        timeOptions: any;
    }
}
angular
    .module('utilityCompany')
    .controller('TariffChargeEditCtrl',
        aq.admin.configuration.utilityCompany.tariffSchedule.TariffChargeEditCtrl);


