angular.module('aq.admin.modal', []).directive('modalFieldParser', function () {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/admin/custom/directives/modalFieldParser/modalFieldParser.html',
        scope: {
            model: '=',
        },
        link: function($scope) {

            $scope.checkIfArray = function(value) {
                return _.isArray(value);
            }

            $scope.checkIfObject = function(value) {
                return _.isObject(value);
            }

        }

    }
});
