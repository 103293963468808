namespace aq.energyInsights {
    export class CostService extends InsightService {
        /* @ngInject */
        constructor(
            private $filter: ng.IFilterService,
            private $q: ng.IQService,
            private EnergyInsightsDataService: aq.energyInsights.service.EnergyInsightsDataService,
            private $translate,
            private Auth: aq.services.Auth,
        ) {
            super();
        }

        public getSimpleTargetModel(building: aq.common.models.Building, measure: string, periodSearch: PeriodSearch): SimpleTargetModel {
            const targetModel = new SimpleTargetModel();
            const measureTxt = this.$filter<Function>('text')(measure, 'capitalize');
            const insightUnit = this.$translate.instant(`measures.${measureTxt}`);
            targetModel.title = this.$translate.instant('insight.Spending', { val: insightUnit });
            targetModel.when = this.$translate.instant('insight.when.Cost');
            return targetModel;
        }

        public getTargetModel(building: aq.common.models.Building,
            measure: string,
            periodSearch: PeriodSearch,
            account: aq.common.models.Account): ng.IPromise<TargetModel> {
            return this.getTableModel(building, measure, periodSearch, account)
                .then((transformedData) => {
                    return this.calculateTargetModel(transformedData, building, periodSearch, measure);
                });
        }

        public getGraphModel(building: aq.common.models.Building,
            measure: string,
            timePeriod: PeriodSearch,
            account: aq.common.models.Account): ng.IPromise<GraphModel> {
            return null;
        }

        public getTableModel(building: aq.common.models.Building,
            measure: string,
            periodSearch: PeriodSearch,
            account: aq.common.models.Account): ng.IPromise<TableModel> {
            const measureText = measure.toLowerCase();
            return this.$q.all([
                this.EnergyInsightsDataService.getUtilitySpendingCostBreakdown(
                    building, periodSearch.start, periodSearch.end, periodSearch.interval.value, measure),
                this.EnergyInsightsDataService.getBudgetItems(
                    building, measureText, periodSearch.start, periodSearch.end, periodSearch.interval, account)
            ]).then((values) => {
                const costData = values[0];
                const budgetData: aq.propertySettings.TargetItem[] = values[1];
                return this.transformData(building, costData, budgetData, periodSearch);
            });
        }

        public formatTableModel() {
            throw new TypeError('This is a stub method');
        }

        public calculateTargetModel(transformedData: TableModel, building: aq.common.models.Building,
            periodSearch: PeriodSearch, measure: string, todaysDate?: moment.Moment): ng.IPromise<TargetModel> {
            let reportDate: moment.Moment;
            if (todaysDate) {
                reportDate = todaysDate;
            } else {
                reportDate = moment();
            }
            const today = reportDate.format('MMM Do');
            const startOfMonth = reportDate.clone().startOf('month');
            const numberOfDaysInMonth = reportDate.diff(startOfMonth, 'days');
            const percOfTime = this.getPercent(numberOfDaysInMonth, reportDate.daysInMonth());
            const targetModel = new TargetModel();
            const insight_unit = `${this.$translate.instant(`measures.${this.$filter<Function>('text')(measure, 'capitalize')}`)}`;
            targetModel.title = this.$translate.instant('insight.Spending', { val: insight_unit });
            const data = transformedData.rows;
            targetModel.totals = transformedData.stats;
            targetModel.target = this.getTarget(data, reportDate);
            targetModel.showDonut = !_.isNull(targetModel.target);
            targetModel.showTarget = !_.isNull(targetModel.target);
            targetModel.formattedTarget = !_.isNull(targetModel.target) ? `${this.$translate.instant('insight.target.Budget')} $${this.$filter('number')(targetModel.target, '0,0')}` : null;
            targetModel.answer = this.getAnswer(data, reportDate);
            targetModel.percentage = this.getPercent(targetModel.answer, targetModel.target);
            targetModel.question = `${this.$translate.instant('insight.question.Cost')} ${building.name}?`;
            targetModel.formattedAnswer = `$${this.$filter('number')(targetModel.answer, '0,0')}`;
            targetModel.unit = this.$translate.instant('insight.unit.Cost');
            targetModel.unit += this.$translate.instant(`measures.${measure}`).toLowerCase();
            targetModel.when = this.$translate.instant('insight.when.Cost');
            const accountText = this.$translate.instant('insight.when.Account Text Plural');
            targetModel.when += ` ${this.$translate.instant('insight.when.Cost 2')} ${this.$translate.instant('insight.when.Cost 3')} ${accountText}`;
            targetModel.color = this.getColor(targetModel.answer, targetModel.target, targetModel.percentage, percOfTime);
            targetModel.iconColor = targetModel.color;
            targetModel.tooltip = targetModel.percentage ?
                `${this.$translate.instant('insight.tooltip.Cost1')} <span style="color:${targetModel.color}">${targetModel.percentage}%</span> ${this.$translate.instant('insight.tooltip.Cost2')} ${today}.`
                : '';
            targetModel.icon = this.getIcon(targetModel.answer, targetModel.target, targetModel.percentage, percOfTime);
            targetModel.timeElapsedPercentage = percOfTime;
            targetModel.timeElapsedLabel = today;
            targetModel.buildingId = building.id;
            targetModel.accountId = building.account;
            return this.$q.when(targetModel);
        }

        public transformData(
            building: aq.common.models.Building,
            costData: aq.utilityBudgets.UtilitySpendingCumulativeResponse,
            budgetData: aq.propertySettings.TargetItem[],
            periodSearch
        ): TableModel {
            const timezone = building.timeZoneId;
            const totals: TargetModelTotals = {
                budgetSum: 0,
                projection: 0,
                spend: 0,
                blendedRate: null,
                currencySymbol: costData.spending.unitSymbol,
                demandRate: null
            };
            const rows = [];
            let chargeTypes = [
                { name: 'usage', order: this.orderSeries('usage') },
                { name: 'utility', order: this.orderSeries('utility') },
                { name: 'projection', order: this.orderSeries('projection') }
            ];
            _.each(costData.spending.data, (item: aq.utilityBudgets.UtilitySpendingDataItem) => {
                const row = new CostInsightRow(item.timestamp);

                if (periodSearch.interval.value === '1mon') {
                    row.formattedTimestamp = moment(item.timestamp).tz(timezone).format('MMM YYYY');
                } else if (periodSearch.interval.value === '1d') {
                    row.formattedTimestamp = moment(item.timestamp).tz(timezone).format('dd MMM Do');
                }
                row.sum = 0;
                row.localDate = item.localDate;
                row.values['usage'] = item.datum.consumption;
                row.values['utility'] = item.datum.utilityBill;
                row.values['projection'] = item.datum.projected;
                row.values['blendedRate'] = item.datum.blendedRate;

                row.budgetSum = this.findBudget(row.localDate, budgetData);
                row.sum = _.sum(_.values(row.values));

                rows.push(row);

                totals.budgetSum += row.budgetSum;
                totals.projection += item.datum.projected;
                totals.spend += item.datum.consumption + item.datum.utilityBill;

                if (totals.blendedRate == null) {
                    totals.blendedRate = item.datum.blendedRate;
                }

                if (totals.demandRate == null || totals.demandRate === 0) {
                    totals.demandRate = item.datum.demandRate;
                }
            });
            chargeTypes = _.sortBy(chargeTypes, ['order']);
            return {
                rows,
                stats: totals
            };
        }

        private findBudget(localDate, budgets: aq.propertySettings.TargetItem[]) {
            const budget = _.find(budgets, (budgetToCheck) => {
                if (budgetToCheck) {
                    const startDate = moment(budgetToCheck.startDate);
                    const endDate = moment(budgetToCheck.endDate);
                    const whenDate = moment(localDate, 'MM/DD/YYYY');
                    return whenDate.isBetween(startDate, endDate) || whenDate.isSame(startDate) || whenDate.isSame(endDate);
                }

            });
            if (budget) {
                return budget.value;
            } else {
                return null;
            }
        }

        private getAnswer(data, date: moment.Moment) {
            const start = _.cloneDeep(date.startOf('month'));
            const end = date.endOf('month');
            const row = _.find(data, (d) => {
                const localDate = moment(d.localDate, 'MM/DD/YYYY');
                return localDate.isBetween(start, end) || localDate.isSame(start) || localDate.isSame(end);
            });
            if (row) {
                return row['sum'];
            } else {
                return 0;
            }
        }

        private getTarget(data, date: moment.Moment) {
            const start = _.cloneDeep(date.startOf('month'));
            const end = date.endOf('month');
            const row = _.find(data, (d) => {
                const localDate = moment(d.localDate, 'MM/DD/YYYY');
                return localDate.isBetween(start, end) || localDate.isSame(start) || localDate.isSame(end);
            });
            if (row) {
                return row['budgetSum'];
            } else {
                return null;
            }
        }

        private orderSeries(name): number {
            if (name === 'Utility') {
                return 1;
            } else if (name === 'Usage') {
                return 2;
            } else if (name === 'Projection') {
                return 3;
            } else {
                return 0;
            }
        };
    }

    class CostInsightRow {
        public sum;
        public formattedTimestamp;
        public budgetSum;
        public localDate: string;
        public values: { [key: string]: number };
        constructor(public timestamp: number) {
            this.values = {};
            this.sum = 0;
            this.budgetSum = null;
        };
    }

    angular.module('energyInsights').service('CostService', CostService);
}
