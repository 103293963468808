namespace aq.dashboard.widgets {
    export class MyTasksWidgetCtrl {
        /* @ngInject */
        constructor(
            private $scope,
            private ProjectService: aq.projectCenter.ProjectService,
            private user: aq.common.models.User,
            private Messages: aq.services.Messages,
            private RestangularV3: restangular.IService,
            private $mdDialog,
            private $location: ng.ILocationService,
            private $state: ng.ui.IStateService,
            private config,
            private $translate
        ) {
            $scope.user = user;
            this.populateTasks();
            $scope.saveTask = (task) => {
                if (task.completionDate) {
                    this.RestangularV3.one('projects', task.projectId).one('tasks', task.id).customPOST(null, 'uncomplete').then(() => {
                        this.Messages.success('Task Not Complete');
                        this.populateTasks();
                    });
                } else {
                    this.RestangularV3.one('projects', task.projectId).one('tasks', task.id).customPOST(null, 'complete').then(() => {
                        this.Messages.success('Task Completed');
                        this.populateTasks();
                    });
                }
            };

            $scope.uncompleteTask = (task) => {
                this.RestangularV3.one('projects', task.projectId).one('tasks', task.id).customPOST(null, 'uncomplete').then(() => {
                    this.Messages.success('Task Successfully Completed');
                });
            };

            $scope.clickMoreInfo = (task) => {
                let confirm;
                if (task.actionUrl) {
                    confirm = $mdDialog.confirm()
                        .title(task.name)
                        .textContent(task.details)
                        .ariaLabel('Task Details')
                        .ok(this.$translate.instant('Go Complete Task'))
                        .cancel(this.$translate.instant('Cancel'));

                    $mdDialog.show(confirm).then(() => {
                        $location.url(task.actionUrl);
                    });
                } else {
                    confirm = $mdDialog.confirm()
                        .title(task.name)
                        .textContent(task.details)
                        .ariaLabel('Task Details')
                        .ok(this.$translate.instant('Got It'));
                    $mdDialog.show(confirm);
                }
            };

            $scope.goToProject = (task, event) => {
                $state.go('aq.projectCenter.overview.project.tasks', {
                    accountId: user.currentProfile.account.id,
                    buildingId: task.buildingId,
                    projectId: task.projectId
                });
                event.stopPropagation();
            };

            $scope.getFormat = (format) =>{
                return this.$translate.instant('dates.'+format);
            };
        };

        private populateTasks() {
            this.ProjectService.getTasksAssignedToUser(this.user).then((tasks: aq.projectCenter.TaskAssignedToUser[]) => {
                tasks.forEach((task) => {
                    if (task.completionDate) {
                        task.complete = true;
                    }
                });
                this.$scope.tasks = tasks;
                this.$scope.tasksOverdue = _.filter(tasks, (task) => {
                    const days = moment(task.dueDate).diff(moment(), 'days');
                    return days < 0 && !task.complete;
                });
                this.$scope.tasksDueSoon = _.filter(tasks, (task) => {
                    const days = moment(task.dueDate).diff(moment(), 'days');
                    return days <= 7 && days >= 0 && !task.complete;
                });
                this.$scope.futureTasks = _.filter(tasks, (task) => {
                    return moment(task.dueDate).diff(moment(), 'days') > 7 && !task.complete;
                });
                this.$scope.completedTasks = _.filter(tasks, (task) => {
                    return task.complete;
                });
            });
        }
    }
    angular.module('aq.dashboard.widgets').controller('MyTasksWidgetCtrl', MyTasksWidgetCtrl);
}
