namespace aq.dashboard.widgets {
    import HighChartConfig = aq.highcharts.HighChartConfig;

    export interface BaseTableConfigModel extends aq.services.BuildingSelectConfig {
        view: TableConfigModelView;
        options: any;
        measures: string[];
        breakdown: string;
        interval: string;
        drilldown: { id: number; name: string };
        drilldownItems: { id: number; name: string }[];
        drilldownItemsSelectionMode: string;
        isIntervalBreakdown: boolean;
        trend: boolean;
        trendPeriod: string;
        timePeriod: string;
        showTitle: boolean;
        isCustomTitle: boolean;
        titleText: string;
        isStarEnergyRating: boolean;
        sortBy: string;
        hideTotals: boolean;
    }
    export interface TableConfigModelView {
        buildingId: number;
        currentNavItem: string;
        availableSortBys: any;
    }
    export class TableCtrl {
        private TREND_APPEND = '_TREND';
        private VALID_CURRENCY_MEASURES = ['ELECTRICITY', 'WATER', 'GAS'];
        private abortDefers;
        private intervals;
        private isLoadingData: boolean;
        private loadingPercent: number;
        private totalPromises: number;
        private completedPromises: number;

        /* @ngInject */
        constructor(
            private $scope,
            private $q,
            private $filter: ng.IFilterService,
            private account: aq.common.models.Account,
            private buildings: aq.common.models.Building[],
            private tenants: aq.common.models.Tenant[],
            private config,
            private DashboardOptionsService: aq.dashboard.DashboardOptionsService,
            private BaseService,
            private DataService,
            private TableData: aq.dashboard.widgets.TableData,
            private meter,
            private uses,
            private source,
            private space,
            private tenant,
            private Auth: aq.services.Auth,
            private ModelUtilService: aq.services.ModelUtilService,
            private intervalService,
            private TableEditService: TableEditService,
            private buildingGroups,
            private $translate,
            private WidgetHelperService,
            private EmptyDataService: aq.dashboard.widgets.EmptyDataService
        ) {
            this.isLoadingData = true;
            this.loadingPercent = 0;

            this.intervals = this.intervalService.Interval;

            if (_.isEmpty(config)) {
                config.breakdown = 'INTERVAL';
                config.timePeriod = TimePresets.defaultPreset();
                config.interval = 'default';
                config.measures = ['KWH'];
                config.showTitle = true;
            }

            if (config.breakdown == 'INTERVAL') {
                config.breakdown = 'BUILDING';
            }
            if (!config.drilldownItems) {
                config.drilldownItems = config.drilldown ? [config.drilldown] : [];
            }
            if (config.breakdown == 'BUILDING' && !config.buildingSelectionMode) {
                config.buildingSelectionMode = 'individual';
            }
            if (config.breakdown != 'BUILDING' && !config.drilldownItemsSelectionMode) {
                config.drilldownItemsSelectionMode = 'individual';
            }

            const breakdownOptions = {
                'BUILDING': [],
                'METER': this.meter,
                'USES': this.uses,
                'SOURCE': this.source,
                'SPACE': this.space
            };

            if (this.Auth.check({ appName: 'Tenant Billing' })) {
                breakdownOptions['TENANT'] = this.tenant;
            }

            config.options = {
                intervals: {
                    'default': 'Default',
                    '15min': '15 min',
                    '1h': '1 hour',
                    '1d': '1 day',
                    '1mon': '1 month'
                },
                breakdownOptions,
                timePresets: TimePresets.getPresets(),
                breakdowns: Breakdowns.getBreakdowns(Auth),
                sortByOrders: ['desc', 'asc'],
                buildings: this.ModelUtilService.pareProperties(this.buildings, ['buildingGroup']),
                buildingAttributes: BuildingAttributes.getAttributes(),
                buildingGroups: this.ModelUtilService.pareProperties(this.buildingGroups),
                drilldownOptions: []
            };

            config.actions = this.TableEditService;

            config.view = {
                buildingId: null,
                currentNavItem: 'basic',
                availableSortBys: {
                    'GENERAL': [{ value: 'BREAKDOWN', label: 'BREAKDOWN' }]
                }
            };

            this.TableEditService.initDefaultBuildingSelection(config);

            if (config.buildingIds.length == 1 && config.breakdown != 'BUILDING') {
                config.view.buildingId = config.buildingIds[0];
            }

            if (config.breakdown == 'BUILDING' && config.buildingIds && config.buildingIds.length == 1
                || config.breakdown != 'BUILDING' && config.drilldownItems && config.drilldownItems.length == 1) {
                config.isIntervalBreakdown = true;
                if (!config.interval) {
                    config.interval = 'default';
                }
            }

            if (config.showTitle && !config.titleText) {
                this.TableEditService.generateDefaultTableTitle(config);
            }

            this.$scope.units = {};
            this.$scope.config = config;

            this.$scope.selectedMeasures = this.getSelectedMeasures(this.config.trend, this.config.measures);
            this.$scope.selectedAttributes = [];
            if (config.breakdown === 'BUILDING') {
                if (config.isStarEnergyRating) {
                    this.$scope.selectedAttributes.push('energyStarScore');
                    if (config.trend) {
                        this.$scope.selectedAttributes.push('energyStarScoreTrend');
                    }
                }
            }

            this.abortDefers = [];
            this.$scope.$on('abortLoading', () => {
                _.each(this.abortDefers, (defer: any) => {
                    defer.resolve();
                });
            });

            this.DashboardOptionsService.init(account.id, account.measurementSystem, account.currencyUnit).then(() => {
                config.options.drilldownOptions = config.actions.getDrilldownOptions(config.measures, config.breakdown, config.view.searchText, config.buildingIds, config);
                this.setUnitAndSortByOptions();

                this.$q.all(this.queryAllEmptyData())
                    .then((res) => {
                        this.$scope.data = this.formatForBreakdown(res, this.config.breakdown, this.config, this.$filter<Function>('toUnit'), this.config.sortBy, this.config.sortByOrder, this.config.limit);
                    });

                return this.$q.all(this.queryAllData())
                    .then((res) => {
                        // Reorganize and aggregate based on our breakdown
                        const data = this.formatForBreakdown(res, this.config.breakdown, this.config, this.$filter<Function>('toUnit'), this.config.sortBy, this.config.sortByOrder, this.config.limit);
                        this.$scope.data = data;
                        if (!this.$scope.config.hideTotals) {
                            this.calculateTotals();
                        }
                    });

            }).finally(() => {
                this.isLoadingData = false;
            });
        }

        public calculateTotals() {
            this.$scope.totals = {};
            _.each(this.$scope.selectedMeasures, (measure) => {
                if (this.isSFMeasure(measure)) {
                    this.$scope.totals[measure] = _.mean(this.$scope.data
                        .map(item => item[measure])
                        .filter(item => item != null));
                } else {
                    this.$scope.totals[measure] = _.sumBy(this.$scope.data, (item) => item[measure]);
                }
            });
        }

        public renameHeader(measure) {
            let header = measure;
            const unit = this.DashboardOptionsService.getUnitByEnumName(this.getMeasureCol(measure));
            if (unit) {
                let measureType = unit.apiUnit == 'POWER' ? unit.apiUnit : unit.serviceType;
                switch (measureType) {
                    case 'POWER':
                        measureType = this.$translate.instant('measures.DEMAND');
                        break;
                    case 'ELECTRICITY':
                        measureType = this.$translate.instant('measures.CONSUMPTION');
                        break;
                    default:
                        measureType = this.$translate.instant('measures.' + measureType);
                }
                const trendText = this.isTrend(measure) ? this.$translate.instant('measures.TREND') : '';
                header = `${measureType} (${unit.unit})${trendText}`;
            }
            return header;
        }

        getAttribute(attr) {
            return BuildingAttributes.getAttribute(attr);
        }

        public getTrendPercentCol(trend) {
            return trend + '_PERCENT';
        }

        public getMeasureCol(field) {
            if (this.isTrend(field)) {
                return field.substring(0, field.length - this.TREND_APPEND.length);
            } else {
                return field;
            }
        }

        public isTrend(measure) {
            return _.endsWith(measure, this.TREND_APPEND);
        }

        public isSFMeasure(measure) {
            return _.includes(measure, 'SQFT') || _.includes(measure, 'AREA');
        }

        public isCurrency(measure) {
            return _.includes(measure, 'CURRENCY');
        }

        private setUnitAndSortByOptions() {
            const allNonConvertedUnits = this.DashboardOptionsService.getUnits();
            const nonBuildingUnits = _.filter(allNonConvertedUnits, (unit) => {
                return !unit.isIntensity;
            });
            this.$scope.config.options.allUnits = this.DashboardOptionsService.organizeUnitsByServiceType(allNonConvertedUnits);
            this.$scope.config.options.allSortBys = this.getSortBys(this.$scope.config.options.allUnits);
            this.$scope.config.options.units = this.DashboardOptionsService.organizeUnitsByServiceType(nonBuildingUnits);
            this.$scope.config.options.sortBys = this.getSortBys(this.$scope.config.options.units);

            this.$scope.config.actions.getAvailableSortBys(this.$scope.config);
        }

        private getSortBys(allMeasures) {
            const sortBys = {};
            sortBys['GENERAL'] = [{ value: 'BREAKDOWN', label: 'BREAKDOWN' }];
            sortBys['BUILDING'] = [
                { value: 'energyStarScore', label: 'Energy Star' },
                { value: 'energyStarScoreTrend', label: 'Energy Star TREND' }
            ];
            _.forOwn(allMeasures, (measures, group) => {
                sortBys[group] = [];
                _.forEach(measures, (unit: any) => {
                    if (unit.serviceType === 'ENVIRONMENT') {
                        sortBys[group].push({ value: unit.value, label: unit.label });
                        const trendPercentCol = this.getTrendPercentCol(this.formatTrendLabel(unit.value));
                        sortBys[group].push({ value: trendPercentCol, label: `${unit.label} TREND` });
                    } else {
                        sortBys[group].push({ value: unit.value, label: unit.unit });
                        const trendPercentCol = this.getTrendPercentCol(this.formatTrendLabel(unit.value));
                        sortBys[group].push({ value: trendPercentCol, label: `${unit.unit} TREND` });
                    }
                });
            });
            return sortBys;
        }

        private getSelectedMeasures(isTrend, measures) {
            let selectedMeasures = [];
            if (isTrend) {
                _.forEach(measures, (measure) => {
                    selectedMeasures.push(measure);
                    const trendCol = this.formatTrendLabel(this.getMeasureCol(measure));
                    if (!_.includes(selectedMeasures, trendCol)) {
                        selectedMeasures.push(trendCol);
                    }
                });

            } else {
                selectedMeasures = measures;
            }

            if (this.config.breakdown != 'BUILDING' && this.config.breakdown != 'TENANT') {
                selectedMeasures = this.removeSizeSpecificMeasures(selectedMeasures);
            }

            return selectedMeasures;
        }

        private removeSizeSpecificMeasures(measures) {
            const newMeasures = [];

            _.forEach(measures, (measure) => {
                if (!this.isSFMeasure(measure)) {
                    newMeasures.push(measure);
                }
            });

            return newMeasures;
        }

        private getTrendPreset(preset) {
            const trendPreset = angular.copy(preset);
            trendPreset.trend = this.config.trend;
            trendPreset.trendPeriod = this.config.trendPeriod;
            return trendPreset;
        }

        private getTitle(period?, interval?, timeZoneId?) {
            let title = null;
            if (this.config.showTitle) {
                title = this.config.titleText;
            }
            return title;
        }

        private queryAllEmptyData() {
            return this.queryAllData(true);
        }

        private queryAllData(isEmptyData = false) {
            const highChartConfig = new HighChartConfig(this.$translate);
            const promises: ng.IPromise<any[]>[] = [];
            let promise;

            const preset = TimePresets.getPresetDetails(this.config.timePeriod);
            if (!preset) {
                return promises;
            }

            let trendPreset;
            if (this.config.trend) {
                trendPreset = this.getTrendPreset(preset);
            }
            const queryables = this.getAllQueryables(this.config.breakdown);
            let interval = preset.interval;

            if ((this.config.breakdown == 'INTERVAL' || this.config.isIntervalBreakdown) && this.config.interval != 'default') {
                interval = this.config.interval;
            }
            const momentDateFormat = highChartConfig.getMomentDateFormatByInterval(interval);
            let titleSet = false;

            if (this.config.breakdown === 'BUILDING' && this.config.isStarEnergyRating) {
                const starEnergyScoreAttribute = 'energyStarScore';
                const starEnergyScoreTrendAttribute = 'energyStarScoreTrend';
                _.forEach(queryables, (queryable) => {
                    const building = this.getQueryableBuilding(queryable);
                    if (!building) {
                        return;
                    }
                    const starEnergyScorePromise = this.queryAttributeData(building, starEnergyScoreAttribute);
                    promises.push(starEnergyScorePromise);
                    if (this.config.trend) {
                        const starEnergyScoreTrendPromise = this.queryAttributeData(building, starEnergyScoreTrendAttribute);
                        promises.push(starEnergyScoreTrendPromise);
                    }
                });
            }


            _.each(this.config.measures, (measure: string) => {
                const unit = this.DashboardOptionsService.getUnitByEnumName(measure);
                this.$scope.units[measure] = unit;
                _.each(queryables, (queryable) => {
                    const building = this.getQueryableBuilding(queryable);
                    if (!building) {
                        return;
                    }
                    let period = this.TableData.calculatePeriod(preset, building.timeZoneId);
                    if (!titleSet) {
                        this.$scope.title = this.getTitle(period, preset.interval, building.timeZoneId);
                        titleSet = true;
                    }
                    // Use the same interval as optimization to get peak demand
                    if (measure === 'KW') interval = '15min';
                    promise = isEmptyData
                        ? this.queryEmptyData(this.config.breakdown, this.config.isIntervalBreakdown, queryable, period, interval, unit, measure, momentDateFormat)
                        : this.queryData(this.config.breakdown, this.config.isIntervalBreakdown, queryable, period, interval, unit, measure, momentDateFormat);
                    promises.push(promise);
                    if (trendPreset) {
                        period = this.TableData.calculatePeriod(trendPreset, building.timeZoneId);
                        promise = isEmptyData
                            ? this.queryEmptyData(this.config.breakdown, this.config.isIntervalBreakdown, queryable, period, interval, unit, measure, momentDateFormat, true)
                            : this.queryData(this.config.breakdown, this.config.isIntervalBreakdown, queryable, period, interval, unit, measure, momentDateFormat, true);
                        promises.push(promise);
                    }
                });
            });
            if (!isEmptyData) {
                this.totalPromises = promises.length;
                this.completedPromises = 0;
                _.each(promises, (p) => {
                    p.finally(() => {
                        this.completedPromises++;
                        this.loadingPercent = Math.round(this.completedPromises * 100 / this.totalPromises);
                    });
                });
            }
            return promises;
        }

        private formatTrendLabel(measure) {
            return measure + this.TREND_APPEND;
        }

        // queryable for each building
        // Depending on the type of dashboard we're on, we may want queryables for mult buildings or not
        private getAllQueryables(breakdown) {
            const allQueryables = [];

            if (this.config.buildingIds) {
                const drilldownId = this.config.drilldown ? this.config.drilldown.id : null;
                const breakdownOptions = this.config.options.breakdownOptions[breakdown];
                _.forEach(this.config.buildingIds, (buildingId) => {
                    if (drilldownId && !breakdownOptions[buildingId]) {
                        return;
                    }
                    if (drilldownId && !_.some(breakdownOptions[buildingId], (option) => option.id == drilldownId)) {
                        return;
                    }
                    allQueryables.push(this.generateQueryable(this.account.id, buildingId, breakdown, drilldownId));
                });
            }

            return allQueryables;
        }

        private generateQueryable(accountId, buildingId, breakdown, drilldownId) {
            const queryable: any[] = [
                {
                    route: 'accounts',
                    id: accountId
                },
                {
                    route: 'buildings',
                    id: buildingId
                }
            ];

            switch (breakdown) {
                case 'METER':
                    queryable.push({
                        route: 'collectors',
                        id: drilldownId
                    });
                    break;
                case 'USES':
                    queryable.push({
                        route: 'energyuses',
                        id: drilldownId
                    });
                    break;
                case 'SOURCE':
                    queryable.push({
                        route: 'sources',
                        id: drilldownId
                    });
                    break;
                case 'SPACE':
                    queryable.push({
                        route: 'spaces',
                        id: drilldownId
                    });
                    break;
                case 'TENANT':
                    queryable.push({
                        route: 'tenants',
                        id: drilldownId
                    });
                    break;
                // default 'INTERVAL' and 'BUILDING', nothing extra to do here
                default:
            }

            return queryable;
        }

        // Not the most efficient way, but the most straightforward to get buildings alongside their queryables
        private getQueryableBuilding(queryable) {
            return _.find(this.buildings, { id: queryable[1].id });
        }

        private queryAttributeData(building: aq.common.models.Building, attr: string) {
            const attribute = this.getAttribute(attr);
            return this.$q.resolve(attribute.getValue(building))
                .then((res) => {
                    return {
                        data: {
                            id: building.id,
                            name: building.name,
                            value: res
                        },
                        attribute: attr
                    };
                });
        }

        private queryEmptyData(breakdown, isIntervalBreakdown, queryable, period, interval, unit, measure: string, momentDateFormat, trend?) {
            const building = this.getQueryableBuilding(queryable);

            const data = this.BaseService.getCurrentRestangularObject(queryable);
            const defer = this.$q.defer();
            data.withHttpConfig({ timeout: defer.promise });
            this.abortDefers.push(defer);
            if (breakdown == 'INTERVAL' || isIntervalBreakdown) {
                const isKw = (measure.toLowerCase() == 'KW'.toLowerCase());
                const step = isKw ? '15min' : interval;
                const emptyData = this.EmptyDataService.getEmptyTimestampsAndValuesForPeriod(step, period);
                return this.$q.when({
                    values: emptyData.values,
                    timestamps: emptyData.timestamps,
                    start: period.start,
                    end: period.end,
                    name: building.name,
                    id: building.id
                }).then((res) => {
                    const obj: ProcessedData = {
                        measure: trend ? this.formatTrendLabel(measure) : measure,
                        building,
                        queryable,
                        data: this.formatIntervalResultData(res, this.$filter<Function>('toUnit'), unit, building, momentDateFormat)
                    };

                    if (isKw) obj.data = this.adjustPeakForKw(obj, interval);
                    return obj;
                });
            } else {
                const res = data.route == 'buildings'
                    ? {
                        missing: true,
                        name: _.find(this.buildings, (b) => b.id == data.id).name,
                        id: data.id,
                        imgUrl: _.find(this.buildings, (b) => b.id == data.id).imageUrl,
                        values: {
                            total: 0
                        }
                    }
                    : _.map(this.config.drilldownItems, (item) => {
                        return {
                            missing: true,
                            name: item.name,
                            id: item.id,
                            values: {
                                total: 0
                            }
                        }
                    });
                return this.$q.when(res).then((res) => {
                    return {
                        data: res,
                        measure: trend ? this.formatTrendLabel(measure) : measure,
                        unit
                    };
                });
            }
        }

        private queryData(breakdown, isIntervalBreakdown, queryable, period, interval, unit, measure: string, momentDateFormat, trend?) {
            const building = this.getQueryableBuilding(queryable);
            const data = this.BaseService.getCurrentRestangularObject(queryable);
            const defer = this.$q.defer();
            data.withHttpConfig({ timeout: defer.promise });
            this.abortDefers.push(defer);
            if (breakdown == 'INTERVAL' || isIntervalBreakdown) {
                const isKw = (measure.toLowerCase() == 'KW'.toLowerCase());
                return this.DataService.data(
                    data,
                    isKw ? '15min' : interval,
                    period.start,
                    period.end,
                    unit
                )
                    .then((res) => {
                        const obj: ProcessedData = {
                            measure: trend ? this.formatTrendLabel(measure) : measure,
                            building,
                            queryable,
                            data: this.formatIntervalResultData(res, this.$filter<Function>('toUnit'), unit, building, momentDateFormat)
                        };

                        if (isKw) obj.data = this.adjustPeakForKw(obj, interval);
                        return obj;
                    });
            } else {
                const params = this.getExtraParamsForQueryable(queryable);
                return this.DataService.metrics(data, interval, period.start, period.end, unit, params).then((res) => {
                    return {
                        data: res,
                        measure: trend ? this.formatTrendLabel(measure) : measure,
                        unit
                    };
                });
            }
        }

        private getExtraParamsForQueryable(queryable) {
            if (_.some(queryable, (item) => item.route == 'sources' || item.route == 'collectors')) {
                return { includeChildren: true };
            }
            return null;
        }

        private adjustPeakForKw(obj: ProcessedData, interval: string): Array<ProcessedData> {
            const truncateTo: string = (() => {
                if (interval === this.intervals.ONE_MONTH.value) {
                    return 'month';
                } else if (interval === this.intervals.ONE_DAY.value) {
                    return 'day';
                } else if (interval === this.intervals.ONE_HOUR.value) {
                    return 'hour';
                }
            })();

            const peaks = new Array();
            // seed
            let timestamp = moment.tz(
                obj.data[0].rawTime
                , obj.building.timeZoneId)
                .startOf(truncateTo)
                .valueOf();
            let peakObj = obj.data[0];

            obj.data.forEach(datum => {
                const currTimestamp = moment.tz(
                    datum.rawTime
                    , obj.building.timeZoneId)
                    .startOf(truncateTo)
                    .valueOf();

                if (timestamp === currTimestamp) {
                    peakObj = peakObj.value > datum.value
                        ? peakObj
                        : datum;
                } else {
                    peaks.push(peakObj);
                    timestamp = currTimestamp;
                    peakObj = datum;
                }
            });

            return peaks;
        }

        private getMergedValues(data): number[] {
            let mergedValues: number[] = [];
            if (data.missingIntervalValues) {
                for (let i = 0; i < data.values.length; i++) {
                    if (i >= data.missingIntervalValues.length) {
                        break;
                    }
                    if (data.missingIntervalValues[i] !== null) {
                        mergedValues.push(data.missingIntervalValues[i]);
                    } else {
                        mergedValues.push(data.values[i]);
                    }
                }
            } else {
                mergedValues = data.values.map((value) => {
                    return value;
                });
            }
            return mergedValues;
        };

        private formatIntervalResultData(res, filterFunc, unit, building, momentDateFormat) {
            const data = [];
            const mergedValues = this.getMergedValues(res);
            // If every value is missing, the res.missing will be empty
            // So we want to treat it like everything is missing
            const allMissing = _.every(mergedValues, (val) => {
                return val === null;
            });
            _.forEach(mergedValues, (value, i) => {
                const timestamp = res.timestamps[i];
                const datum = {
                    timestamp: moment(timestamp).tz(building.timeZoneId).format(momentDateFormat),
                    rawTime: timestamp,
                    value: value !== null ? filterFunc(value, unit) : 0,
                    missing: _.includes(res.missing, timestamp) || allMissing
                };

                data.push(datum);
            });
            return data;
        }

        // Need to format like: [
        //     { breakdown: name, measure1: measure1val, measure2: measure2val }
        // ]
        private formatForBreakdown(res, breakdown, config, filterFunc, sortBy, sortByOrder, limit) {
            let data;
            switch (breakdown) {
                case 'INTERVAL':
                    data = this.formatIntervalBreakdown(res);
                    break;
                case 'BUILDING':
                    data = this.formatBuildingBreakdown(res, config, filterFunc);
                    break;
                default:
                    data = this.formatGeneralBreakdown(res, config, filterFunc);
            }
            if (breakdown == 'BUILDING') {
                data = this.calculatePerSFBuildingData(data);
            } else if (breakdown == 'TENANT') {
                data = this.calculatePerSFTenantData(data);
            }
            data = this.config.trend ? this.calculateTrendPercentages(data) : data;
            data = this.sortData(data, breakdown, sortBy, sortByOrder);
            data = this.limitData(data, limit);
            return data;
        }

        private calculatePerSFBuildingData(data) {
            const getSize = (building: aq.common.models.Building) => building.size;
            return this.calculatePerSFData(data, this.buildings, getSize);
        }

        private calculatePerSFTenantData(data) {
            const getSize = (tenant: aq.common.models.Tenant) => tenant.size;
            return this.calculatePerSFData(data, this.tenants, getSize);
        }

        private calculatePerSFData(data, entities, getSizeFunction) {
            _.forEach(this.config.measures, (measure) => {
                let calcFormula;
                if (this.isSFMeasure(measure)) {
                    calcFormula = (entity, value: number) => {
                        if (!value) {
                            return value;
                        }
                        return value / getSizeFunction(entity);
                    };
                }
                if (calcFormula) {
                    _.forEach(data, (datum) => {
                        const val = datum[measure];
                        const entity = _.find(entities, { id: datum.ID });
                        datum[measure] = calcFormula(entity, val);
                        if (this.config.trend) {
                            const trendMeasure = this.formatTrendLabel(measure);
                            const trendVal = datum[trendMeasure];
                            datum[trendMeasure] = calcFormula(entity, trendVal);
                        }
                    });
                }
            });
            return data;
        }

        // Calculate our percentages for our trend cols for use in sorting
        private calculateTrendPercentages(data) {
            _.forEach(data, (d) => {
                _.forEach(d, (value, measure) => {
                    if (this.isTrend(measure)) {
                        const trendVal = value;
                        const measureVal = d[this.getMeasureCol(measure)];
                        d[this.getTrendPercentCol(measure)] = this.$filter<Function>('compareValues')(measureVal, trendVal);
                    }
                });
            });

            return data;
        }

        private sortData(data, breakdown, sortBy, sortByOrder) {

            if (sortBy == 'BREAKDOWN') {
                if (breakdown == 'INTERVAL') {
                    if (!sortByOrder || sortByOrder == 'desc') {
                        data = _.reverse(data); // comes out sorted asc but we want desc
                    }
                } else {
                    data = _.orderBy(data, [breakdown], [sortByOrder || 'asc']);
                }
            } else if (sortBy && sortBy != 'NONE') {
                data = _.orderBy(data, [sortBy], [sortByOrder || 'desc']);
            }

            return data;
        }

        private limitData(data, limit) {
            if (limit > 0) {
                data = _.slice(data, 0, limit);
            }

            return data;
        }

        // Each res item is a queryable
        // each queryable will be a metric data query for a specific building and metric
        // res = [
        //     building1queryable = {
        //         measure: 'POWER',
        //         unit: unitObj,
        //         data: [
        //             {
        //                 name: 'breakdown1',
        //                 values: {
        //                     total: 123456,
        //                     avg: 1234,
        //                     ...
        //                 }
        //             },
        //             ...
        //         ]
        //     },
        //     building1queryable = {
        //         ...
        //     },
        //     building2queryable = {
        //         ...
        //     }
        // ]
        private formatGeneralBreakdown(res, config: BaseTableConfigModel, filterFunc) {
            const data = [];

            let isBuildingInSetup = false;
            if (this.config.buildingIds.length == 1) {
                const building = _.find(this.buildings, (b) => b.id == this.config.buildingIds[0]);
                isBuildingInSetup = this.WidgetHelperService.isBuildingInSetup(building);
            }

            if (config.isIntervalBreakdown) {
                // todo: group by queryables, if multiple can be selected
                const specificQueryable = _.last(_.first(res).queryable);
                const buildingOptions = _.flatten(_.map(config.options.breakdownOptions[config.breakdown], (item) => item));
                const specificBreakdownOption = _.find(buildingOptions, (option) => option.id == specificQueryable.id);
                const name = specificBreakdownOption ? specificBreakdownOption.name : '';
                const searchItem = {};
                searchItem[config.breakdown] = name;
                const intervalData = this.formatIntervalBreakdown(res);
                _.each(intervalData, (item) => {
                    _.extend(item, searchItem);
                });
                return intervalData;
            }

            const filterQueryableIds = _.map(config.drilldownItems, 'id');

            const measures = {};

            _.forEach(res, (d) => {
                if (!d.data.length) {
                    d.data = [d.data];
                }
                measures[d.measure] = true;
                _.forEach(d.data, (datum) => {
                    if (!_.some(filterQueryableIds, (id) => id == datum.id)) {
                        return;
                    }
                    const searchItem: any = {};
                    searchItem[config.breakdown] = datum.name;
                    searchItem.ID = datum.id;
                    let item = _.find(data, searchItem);
                    if (!item) {
                        item = searchItem;
                        data.push(item);
                    }

                    if (item[d.measure] === undefined) {
                        item[d.measure] = null;
                    }

                    if (datum.values && !datum.missing || isBuildingInSetup) {
                        if (_.includes(d.measure, 'POWER')) {
                            const val = filterFunc(datum.values.max, d.unit);
                            // Check for nulls here so that negative or 0 values still count as our max
                            if (item[d.measure] === null) {
                                item[d.measure] = val;
                            } else {
                                item[d.measure] = val > item[d.measure] ? val : item[d.measure];
                            }
                        } else {
                            // Set to 0 to avoid any errors with our adding
                            if (item[d.measure] === null) {
                                item[d.measure] = 0;
                            }
                            item[d.measure] += filterFunc(datum.values.total, d.unit);
                        }
                    }

                });
            });

            if (isBuildingInSetup && this.$scope.data) {
                _.each(data, (item) => {
                    item.isBuildingInSetup = true;
                    item.isMissingData = _.every(_.keysIn(measures), (measure) => item[measure] == null);
                });
                _.each(data, (item) => {
                    item.isSpanAcrossRows = _.every(data, (d) => d.isMissingData);
                });
            }

            return data;
        }

        private formatBuildingBreakdown(res, config: BaseTableConfigModel, filterFunc) {
            const data = [];
            if (config.isIntervalBreakdown) {
                // todo: group by building, if multiple can be selected
                const building = _.first(res).building;
                const searchItem = { BUILDING: building.name, ID: building.id, IMG: building.imageUrl };
                const intervalData = this.formatIntervalBreakdown(res);
                _.each(intervalData, (item) => {
                    _.extend(item, searchItem);
                });
                return intervalData;
            }

            _.forEach(res, (d) => {
                const building = _.find(this.buildings, { id: d.data.id });
                const searchItem = { BUILDING: building.name, ID: building.id, IMG: building.imageUrl };

                let item = _.find(data, searchItem);
                if (!item) {
                    item = searchItem;
                    data.push(item);
                }

                if (this.WidgetHelperService.isBuildingInSetup(building)) {
                    item.isBuildingInSetup = true;
                    item.isMissingData = true;
                }

                if (d.attribute) {
                    const value = d.data.value;
                    if (value !== undefined) {
                        item[d.attribute] = value;
                    } else {
                        item[d.attribute] = null;
                    }
                    return;
                }

                if (item[d.measure] === undefined) {
                    item[d.measure] = null;
                }

                if (d.data && d.data.values && !d.data.missing) {
                    // Add KW and KW_TREND so they aren't uselessly summed up.
                    if (_.includes(d.measure, 'POWER') || (d.measure === 'KW' || d.measure === 'KW_TREND')) {
                        const val = filterFunc(d.data.values.max, d.unit);
                        // Check for nulls here so that negative or 0 values still count as our max
                        if (item[d.measure] === null) {
                            item[d.measure] = val;
                        } else {
                            item[d.measure] = val > item[d.measure] ? val : item[d.measure];
                        }
                    } else {
                        // Set to 0 to avoid any errors with our adding
                        if (item[d.measure] === null) {
                            item[d.measure] = 0;
                        }
                        item[d.measure] += filterFunc(d.data.values.total, d.unit);
                    }
                }
            });
            return data;
        }

        private formatIntervalBreakdown(res) {
            const data = [];

            let isBuildingInSetup = false;
            if (this.config.buildingIds.length == 1) {
                const building = _.find(this.buildings, (b) => b.id == this.config.buildingIds[0]);
                isBuildingInSetup = this.WidgetHelperService.isBuildingInSetup(building);
            }
            if (this.config.breakdown == 'BUILDING' && isBuildingInSetup && this.$scope.data) {
                data.push(...this.$scope.data);
                _.each(data, (item) => {
                    item.isBuildingInSetup = true;
                    item.isMissingData = true;
                    item.isSpanAcrossRows = true;
                });
                return data;
            }

            const measures = {};

            if (res.length > 0) {
                // Aggregrate by interval & format for our table
                _.forEach(res[0].data, (datum, index) => {

                    const searchItem = { INTERVAL: datum.timestamp, TIMESTAMP: datum.rawTime };
                    let item = _.find(data, searchItem);
                    if (!item) {
                        item = searchItem;
                        data.push(item);
                    }
                    _.forEach(res, (d) => {
                        if (item[d.measure] === undefined) {
                            item[d.measure] = null;
                        }

                        measures[d.measure] = true;

                        if (d.data && d.data[index] && !d.data[index].missing) {
                            if (_.includes(d.measure, 'POWER')) {
                                // Check for nulls here so that negative or 0 values still count as our max
                                if (item[d.measure] === null) {
                                    item[d.measure] = d.data[index].value;
                                } else {
                                    item[d.measure] = d.data[index].value > item[d.measure] ? d.data[index].value : item[d.measure];
                                }
                            } else {
                                // Set to 0 to avoid any errors with our adding
                                if (item[d.measure] === null) {
                                    item[d.measure] = 0;
                                }
                                item[d.measure] += d.data[index].value;
                            }
                        }
                    });

                    if (this.config.breakdown != 'BUILDING' && isBuildingInSetup && this.$scope.data) {
                        item.isMissingData = _.every(_.keysIn(measures), (measure) => item[measure] == null);
                    }
                });
            }
            if (isBuildingInSetup) {
                _.each(data, (item) => {
                    item.isBuildingInSetup = true;
                    item.isSpanAcrossRows = _.every(data, (d) => d.isMissingData);
                });
            }
            return data;
        }

        private calculateQueryable(series) {
            if (series.drilldown) {
                let queryCursor = series.drilldown;
                const queryable = [];
                let parentRoute = queryCursor.route;
                series.drilldownId = queryCursor.id;

                // push selected queryable onto chain
                queryable.push({
                    id: queryCursor.id,
                    route: queryCursor.route
                });

                while (queryCursor.parent != null) {
                    // only push a queryable onto the chain if its route is different from its parent
                    if (queryCursor.route != parentRoute) {
                        queryable.push({
                            id: queryCursor.id,
                            route: queryCursor.route
                        });
                    }

                    parentRoute = queryCursor.route;
                    queryCursor = queryCursor.parent;
                }

                _.reverse(queryable);
                series.queryable = _.concat(this.$scope.buildingPrefix, queryable);
            } else {
                series.queryable = this.$scope.buildingPrefix;
            }
        }

    }

    angular.module('aq.dashboard.widgets').controller('TableCtrl', TableCtrl);
}

type ProcessedData = {
    measure: string;
    building: aq.common.models.Building;
    queryable: { route: string; id: number }[];
    data: any[]
};

