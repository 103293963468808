var aq;
(function (aq) {
    var propertySettings;
    (function (propertySettings) {
        var StateAlertContextComponentCtrl = /** @class */ (function (_super) {
            __extends(StateAlertContextComponentCtrl, _super);
            /* @ngInject */
            function StateAlertContextComponentCtrl() {
                var _this = _super.call(this) || this;
                _this.intervalOptions = [{
                        label: '1 Min',
                        value: '1min'
                    }, {
                        label: '15 Min',
                        value: '15min'
                    }, {
                        label: 'Hour',
                        value: '1h'
                    }, {
                        label: 'Day',
                        value: '1d'
                    }];
                _this.severityOptions = [propertySettings.LevelSeverity.NORMAL, propertySettings.LevelSeverity.WARNING, propertySettings.LevelSeverity.CRITICAL];
                _this.alertOnOptions = [propertySettings.LevelSeverity.WARNING, propertySettings.LevelSeverity.CRITICAL];
                return _this;
            }
            StateAlertContextComponentCtrl.prototype.onAlertChange = function () {
                if (this.onChange) {
                    this.onChange({
                        $event: {
                            alert: this.alert
                        }
                    });
                }
            };
            StateAlertContextComponentCtrl.prototype.isValidMin = function () {
                return this.alert.maxAgeMinutes >= this.getMinForInterval();
            };
            StateAlertContextComponentCtrl.prototype.setDefaultMaxAgeForInterval = function () {
                this.alert.maxAgeMinutes = this.getDefaultMaxAgeForInterval();
            };
            StateAlertContextComponentCtrl.prototype.getDefaultMaxAgeForInterval = function () {
                switch (this.alert.interval) {
                    case '1min':
                        return 10;
                    case '15min':
                        return 60;
                    case '1h':
                        return 180;
                    case '1d':
                    default:
                        return 2880;
                }
            };
            StateAlertContextComponentCtrl.prototype.getMinForInterval = function () {
                switch (this.alert.interval) {
                    case '1min':
                        return 2;
                    case '15min':
                        return 30;
                    case '1h':
                        return 120;
                    case '1d':
                    default:
                        return 2880;
                }
            };
            return StateAlertContextComponentCtrl;
        }(propertySettings.AbstractAlertComponent));
        propertySettings.StateAlertContextComponentCtrl = StateAlertContextComponentCtrl;
        angular.module('properties')
            .component('stateAlertContext', {
            templateUrl: 'app/properties/alerts/directives/stateAlertContext.html',
            bindings: {
                form: '<',
                alert: '<',
                onChange: '&',
                isReadonly: '<?',
                editMode: '<'
            },
            controller: StateAlertContextComponentCtrl
        });
    })(propertySettings = aq.propertySettings || (aq.propertySettings = {}));
})(aq || (aq = {}));
