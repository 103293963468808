angular.module('aq.filters.units')
.filter('toTemperatureUnit', ['$filter', function($filter) {
    return function(input, temperatureUnit, format) {
        if (_.isNull(input) || _.isUndefined(input) || _.isUndefined(temperatureUnit)) {
            return null;
        }
        var converted = input * temperatureUnit.conversionMultiplier;
        if (temperatureUnit.value === 'F') {
            converted = converted + 32;
        }

        if (format) {
            converted = $filter('number')(converted, format);
        }
        return converted;
    }
}]);