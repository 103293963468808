namespace aq.settings {
    export class SettingsBuildingGroupCtrl {
        private accountSettingsAccess: common.models.AppAccessObject;
        constructor(
            private $scope,
            private $mdDialog: ng.material.IDialogService,
            private authAccess: common.models.AuthAppAccess
        ) {
            this.accountSettingsAccess = authAccess['Account Settings'];
            this.$scope.$on('CREATE_NEW', () => {
                this.create();
            });
        }
        public create() {
            this.$mdDialog.show({
                controller: 'SettingsBuildingGroupsCRUDCtrl as vm',
                templateUrl: 'app/settings/platform/buildingGroups/crud/crud.html',
                clickOutsideToClose: true,
                locals: {
                    object: { name: '' },
                    account: this.$scope.account,
                    accountSettingsAccess: this.accountSettingsAccess
                }
            }).then((createdGroup) => {
                this.$scope.account.buildingGroups.push(createdGroup);
                this.$scope.account.buildingGroups = _.sortBy(this.$scope.account.buildingGroups, 'name');
            });
        }
        public edit(group) {
            this.$mdDialog.show({
                controller: 'SettingsBuildingGroupsCRUDCtrl as vm',
                templateUrl: 'app/settings/platform/buildingGroups/crud/crud.html',
                clickOutsideToClose: true,
                locals: {
                    object: group,
                    account: this.$scope.account,
                    accountSettingsAccess: this.accountSettingsAccess
                }
            }).then((updatedGroup) => {
                if (updatedGroup) {
                    _.replaceItemById(this.$scope.account.buildingGroups, updatedGroup);
                } else {
                    _.remove(this.$scope.account.buildingGroups, (m: any) => { return m.id == group.id; });
                }
            });
        }
        public isDefault(group) {
            return this.$scope.account.defaultBuildingGroup === group.id;
        }
        public makeDefault(group) {
            this.$scope.account.defaultBuildingGroup = group.id;
            this.$scope.$emit('SAVE_ACCOUNT');
        }
    }
    angular.module('aq.settings').controller('SettingsBuildingGroupCtrl', SettingsBuildingGroupCtrl);
}
