var aq;
(function (aq) {
    var propertySettings;
    (function (propertySettings) {
        var AlertPreviewComponentCtrl = /** @class */ (function (_super) {
            __extends(AlertPreviewComponentCtrl, _super);
            /* @ngInject */
            function AlertPreviewComponentCtrl(DataService, $q, BaseService, $filter, Auth, $window) {
                var _this = _super.call(this) || this;
                _this.DataService = DataService;
                _this.$q = $q;
                _this.BaseService = BaseService;
                _this.$filter = $filter;
                _this.Auth = Auth;
                _this.$window = $window;
                _this.showOptimizationButton = false;
                _this.hideTitle = false;
                _this.xaxisDateFormat = null;
                _this.eeLinearRegression = true;
                _this.eeWorkaround = false;
                return _this;
            }
            AlertPreviewComponentCtrl.prototype.$onInit = function () {
                try {
                    this.getAlertPreviewConfig();
                }
                catch (err) {
                    if (this.isActivity) {
                        this.noDataCallback();
                    }
                }
            };
            AlertPreviewComponentCtrl.prototype.$onChanges = function (changesObj) {
                if (changesObj.alert) {
                    try {
                        this.getAlertPreviewConfig();
                    }
                    catch (err) {
                        if (this.isActivity) {
                            this.noDataCallback();
                        }
                    }
                }
            };
            AlertPreviewComponentCtrl.prototype.goToOptimization = function () {
                var _this = this;
                var currBuilding = this.building;
                var currAlert = this.alert;
                var startDate, endDate;
                if (currAlert.issue) {
                    startDate = moment(currAlert.issue.openedOn).tz(currBuilding.timeZoneId);
                    endDate = moment(currAlert.issue.closedOn).tz(currBuilding.timeZoneId);
                }
                else {
                    startDate = moment().subtract(1, 'weeks');
                    endDate = moment();
                }
                this.account.get({ single: true }).then(function (thisAccount) {
                    var optimizationURI = URI('/accounts/' + thisAccount.id + '/optimization/building')
                        .search({
                        unit: currAlert.unit.unit,
                        apiUnit: currAlert.unit.apiUnit,
                        interval: currAlert.interval,
                        off: _.without(thisAccount.buildings, currBuilding.id).join(','),
                        start: startDate.toISOString(),
                        end: endDate.toISOString(),
                        children: 'buildings'
                    });
                    _this.$window.location.href = optimizationURI.toString();
                });
            };
            AlertPreviewComponentCtrl.prototype.getAlertPreviewConfig = function () {
                var _this = this;
                if (!this.hasEnoughInfoForPreview()) {
                    return;
                }
                var queryable = this.getRestQueryable();
                var restangularQueryable = this.BaseService.getCurrentRestangularObject(queryable);
                var startDate, endDate;
                if (this.alert.issue) {
                    startDate = this.alert.issue.openedOn;
                    endDate = this.alert.issue.closedOn;
                }
                else {
                    startDate = moment().subtract(1, 'weeks');
                    endDate = moment();
                }
                var dataPromises = [];
                dataPromises.push(this.DataService.data(restangularQueryable, '15min', startDate, endDate, this.alert.unit));
                if (this.alert.whenCondition === 'CALENDAR') {
                    if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                        dataPromises.push(this.DataService.expectedEnergy(restangularQueryable, '15min', startDate, endDate));
                    }
                    else {
                        dataPromises.push(this.DataService.metricsScheduling(restangularQueryable, '15min', startDate, endDate, this.alert.unit, { id: this.alert.calendar }));
                    }
                    this.$q.all(dataPromises).then(function (preliminaryResults) {
                        _this.checkDataAmount(preliminaryResults, restangularQueryable, startDate, endDate).then(function (results) {
                            var categories = _this.getCustomRulesByCategory();
                            var data = results[0];
                            var dataSeries = _this.getSeriesForData(data);
                            if (_this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                                var expectedEnergy = results[1];
                                var expectedRangeSeries_1 = _this.getExpectedRangeSeries(expectedEnergy);
                                var max_1 = Math.round(_.max([_this.getMaxValue(expectedEnergy, true), _.maxBy(dataSeries.data, 'y')['y']]));
                                var maxValue_1 = _this.getMaxValue(expectedEnergy, true);
                                var alertAreaSeries = categories
                                    .map(function (category) { return _this.getAlertArea(category, expectedRangeSeries_1, null, false, max_1, maxValue_1 + 1000, null, false); });
                                var series = [dataSeries, expectedRangeSeries_1].concat(alertAreaSeries);
                                _this.buildChartConfig(series, [], max_1);
                            }
                            else {
                                var metrics_1 = _.find(results[1].partitions, { name: categories[0].categoryName });
                                var max_2 = Math.round(_.max([_this.getMaxValue(metrics_1, true), _.maxBy(dataSeries.data, 'y')['y']]));
                                var maxValue_2 = _this.getMaxValue(metrics_1, true);
                                var minValue_1 = _this.getMinValue(metrics_1, true);
                                var alertAreaSeries = categories
                                    .map(function (category) { return _this.getAlertArea(category, dataSeries, metrics_1, true, max_2, minValue_1, maxValue_2, false); });
                                var series = [dataSeries].concat(alertAreaSeries);
                                var plotLines = _this.getPlotLines(metrics_1);
                                _this.buildChartConfig(series, plotLines, max_2);
                            }
                            if (_this.isActivity) {
                                _this.contextLoadedCallback();
                            }
                        })
                            .catch(function () {
                            _this.noDataCallback();
                        });
                    });
                }
                else if (this.alert.whenCondition === 'CUSTOM') {
                    if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                        dataPromises.push(this.DataService.expectedEnergy(restangularQueryable, '15min', startDate, endDate));
                    }
                    this.$q.all(dataPromises).then(function (preliminaryResults) {
                        _this.checkDataAmount(preliminaryResults, restangularQueryable, startDate, endDate).then(function (results) {
                            var category = angular.copy(_this.alert.customRule);
                            category.timeStart = moment(category.timeStart, 'h:mm A').format('HH:mm:ss');
                            category.timeEnd = moment(category.timeEnd, 'h:mm A').format('HH:mm:ss');
                            var data = results[0];
                            var dataSeries = _this.getSeriesForData(data);
                            if (_this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                                var expectedEnergy = results[1];
                                var expectedRangeSeries = _this.getExpectedRangeSeries(expectedEnergy);
                                var max = Math.round(_.max([_this.getMaxValue(expectedEnergy, true), _.maxBy(dataSeries.data, 'y')['y']]));
                                var maxValue = _this.getMaxValue(expectedEnergy, true);
                                var alertAreaSeries = _this.getAlertArea(category, expectedRangeSeries, null, false, max, maxValue + 1000, null, true);
                                var series = [dataSeries, expectedRangeSeries, alertAreaSeries];
                                _this.buildChartConfig(series, [], 975);
                            }
                            else {
                                var metrics = _this.getCustomMetrics(dataSeries, category);
                                var max = Math.round(_.max([_this.getMaxValue(metrics, true), _.maxBy(dataSeries.data, 'y')['y']]));
                                var maxValue = _this.getMaxValue(metrics, true);
                                var minValue = _this.getMinValue(metrics, true);
                                var alertAreaSeries = _this.getAlertArea(category, dataSeries, metrics, false, max, minValue, maxValue, true);
                                var series = [dataSeries, alertAreaSeries];
                                var plotLines = _this.getPlotLines(metrics, false);
                                _this.buildChartConfig(series, plotLines, max);
                            }
                            if (_this.isActivity) {
                                _this.contextLoadedCallback();
                            }
                        })
                            .catch(function () {
                            _this.noDataCallback();
                        });
                    });
                }
                else if (this.alert.whenCondition === 'ANYTIME') {
                    if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                        dataPromises.push(this.DataService.expectedEnergy(restangularQueryable, '15min', startDate, endDate, this.eeWorkaround));
                    }
                    else {
                        dataPromises.push(this.DataService.metrics(restangularQueryable, '15min', startDate, endDate, this.alert.unit));
                    }
                    this.$q.all(dataPromises).then(function (preliminaryResults) {
                        _this.checkDataAmount(preliminaryResults, restangularQueryable, startDate, endDate).then(function (results) {
                            var data = results[0];
                            var dataSeries = _this.getSeriesForData(data);
                            if (_this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                                var expectedEnergy = results[1];
                                var expectedRangeSeries = _this.getExpectedRangeSeries(expectedEnergy);
                                var max = Math.round(_.max([_this.getMaxValue(expectedEnergy, true), _.maxBy(dataSeries.data, 'y')['y']]));
                                var maxValue = _this.getMaxValue(expectedEnergy, true);
                                var alertAreaSeries = _this.getAlertArea(null, expectedRangeSeries, null, false, maxValue, maxValue + 1000, null, true);
                                var series = [dataSeries, expectedRangeSeries, alertAreaSeries];
                                _this.buildChartConfig(series, [], max);
                            }
                            else {
                                var metrics = results[1];
                                var max = Math.round(_.max([_this.getMaxValue(metrics, true), _.maxBy(dataSeries.data, 'y')['y']]));
                                var maxValue = _this.getMaxValue(metrics, true);
                                var minValue = _this.getMinValue(metrics, true);
                                var alertAreaSeries = _this.getAlertArea(null, dataSeries, metrics, true, max, minValue, maxValue, true);
                                var series = [dataSeries, alertAreaSeries];
                                var plotLines = _this.getPlotLines(metrics);
                                _this.buildChartConfig(series, plotLines, max);
                            }
                            if (_this.isActivity) {
                                _this.contextLoadedCallback();
                            }
                        });
                    })
                        .catch(function () {
                        _this.noDataCallback();
                    });
                }
            };
            AlertPreviewComponentCtrl.prototype.checkDataAmount = function (data, queryable, start, end) {
                var interval = '';
                /**
                 * Max threshold currenly set on high charts is 1000 points. These if-statements make it so that a chart will display
                 * even if our original query returns more than 1000 points.
                 */
                if (data[0].timestampsWithValues.length < 1000) {
                    return new Promise(function (resolve, reject) {
                        resolve(data);
                    });
                }
                else if (data[0].timestampsWithValues.length >= 1000 && data[0].timestampsWithValues.length < 4000) {
                    interval = '1h';
                }
                else if (data[0].timestampsWithValues.length >= 4000 && data[0].timestampsWithValues.length < 96000) {
                    interval = '1d';
                }
                else {
                    interval = '1mon';
                }
                var dataPromises = [];
                dataPromises.push(this.DataService.data(queryable, interval, start, end, this.alert.unit));
                if (this.alert.whenCondition === 'ANYTIME') {
                    if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                        dataPromises.push(this.DataService.expectedEnergy(queryable, interval, start, end, this.eeWorkaround));
                    }
                    else {
                        dataPromises.push(this.DataService.metrics(queryable, interval, start, end, this.alert.unit));
                    }
                }
                else if (this.alert.whenCondition === 'CUSTOM') {
                    if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                        dataPromises.push(this.DataService.expectedEnergy(queryable, interval, start, end));
                    }
                }
                else if (this.alert.whenCondition === 'CALENDAR') {
                    if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                        dataPromises.push(this.DataService.expectedEnergy(queryable, interval, start, end));
                    }
                    else {
                        dataPromises.push(this.DataService.metricsScheduling(queryable, interval, start, end, this.alert.unit, { id: this.alert.calendar }));
                    }
                }
                return this.$q.all(dataPromises);
            };
            AlertPreviewComponentCtrl.prototype.hasEnoughInfoForPreview = function () {
                if ((!this.alert) ||
                    (this.alert.drillMode !== 'BUILDING' && !this.alert.queryableId) ||
                    (this.alert.whenCondition === 'CALENDAR' && (!this.alert.calendar || !this.alert.category)) ||
                    (this.alert.whenCondition === 'CUSTOM' &&
                        (!this.alert.customRule || !this.alert.customRule.timeStart || !this.alert.customRule.timeEnd)) ||
                    (!this.alert.unit)) {
                    return false;
                }
                return true;
            };
            AlertPreviewComponentCtrl.prototype.getCustomRulesByCategory = function () {
                var calendar = _.find(this.calendars, { id: this.alert.calendar });
                return _.filter(calendar.rules, { category: { id: this.alert.category } });
            };
            AlertPreviewComponentCtrl.prototype.buildChartConfig = function (series, plotLines, yAxisMax) {
                if (yAxisMax === void 0) { yAxisMax = null; }
                var timeZoneId = this.building.timeZoneId;
                var dateFormat = this.xaxisDateFormat || 'dd, MMM D';
                this.chartConfig = {
                    chart: {
                        plotBorderWidth: 1,
                        type: 'line'
                    },
                    legend: {
                        enabled: false
                    },
                    lang: {
                        noData: ''
                    },
                    title: {
                        text: !this.hideTitle && 'Alert Preview'
                    },
                    tooltip: {
                        formatter: function () {
                            return this.series.name + "<br>" + moment(this.point.x).tz(timeZoneId).format('lll');
                        }
                    },
                    xAxis: {
                        type: 'datetime',
                        tickLength: 0,
                        gridLineWidth: 1,
                        gridLineColor: '#c7c7c7',
                        labels: {
                            formatter: function () {
                                return moment(this.value).tz(timeZoneId).format(dateFormat);
                            },
                            rotation: this.xaxisLabelRotation || 0
                        }
                    },
                    yAxis: {
                        min: 0,
                        max: yAxisMax ? yAxisMax + (yAxisMax * .1) : null,
                        title: {
                            text: this.alert.unit.label
                        },
                        gridLineColor: '#c7c7c7',
                        gridLineWidth: 1,
                        plotLines: plotLines
                    },
                    exporting: {
                        buttons: {
                            contextButton: {
                                enabled: false
                            }
                        }
                    },
                    plotOptions: {
                        line: {
                            animation: false
                        },
                        series: {
                            events: {
                                legendItemClick: function () {
                                    return false;
                                }
                            },
                            stickyTracking: false
                        }
                    },
                    series: series
                };
            };
            AlertPreviewComponentCtrl.prototype.getMaxValue = function (metrics, convertToUnit) {
                if (this.alert.calculationMethod === 'PERCENTAGE' && this.alert.smallerThanAverage) {
                    var avgValue = this.getAverage(metrics, convertToUnit);
                    return avgValue + (avgValue * (this.alert.smallerThanAverage / 100));
                }
                else if (this.alert.calculationMethod === 'ABSOLUTE' && this.alert.smallerThan) {
                    return this.alert.smallerThan;
                }
                else if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                    var maxValue_3 = 0;
                    var maxIndex_1;
                    _.forEach(metrics['POWER_EXPECTED'].values, function (value, index) {
                        if (maxValue_3 < value) {
                            maxValue_3 = value;
                            maxIndex_1 = index;
                        }
                    });
                    return Math.round(this.$filter('toUnit')(maxValue_3 + metrics['POWER_STDDEV'].values[maxIndex_1], this.alert.unit));
                }
                return null;
            };
            AlertPreviewComponentCtrl.prototype.getMinValue = function (metrics, convertToUnit) {
                if (this.alert.calculationMethod === 'PERCENTAGE' && this.alert.greaterThanAverage) {
                    var avgValue = this.getAverage(metrics, convertToUnit);
                    return avgValue - (avgValue * (this.alert.greaterThanAverage / 100));
                }
                else if (this.alert.calculationMethod === 'ABSOLUTE' && this.alert.greaterThan) {
                    return this.alert.greaterThan;
                }
                else if (this.alert.calculationMethod === 'EXPECTED_ENERGY') {
                    var minValue_2 = 0;
                    var minIndex_1;
                    _.forEach(metrics['POWER_EXPECTED'].values, function (value, index) {
                        if (minValue_2 > value) {
                            minValue_2 = value;
                            minIndex_1 = index;
                        }
                    });
                    return minValue_2 - metrics['POWER_STDDEV'].values[minIndex_1];
                }
                return 0;
            };
            AlertPreviewComponentCtrl.prototype.getAverage = function (metrics, convertToUnit) {
                if (convertToUnit) {
                    return this.$filter('toUnit')(metrics.values.avg, this.alert.unit);
                }
                return metrics.values.avg;
            };
            AlertPreviewComponentCtrl.prototype.getAlertArea = function (category, dataSeries, metrics, convertToUnit, yAxisMax, minValue, maxValue, skipTimeframeCheck) {
                var _this = this;
                var alertAreaSeries = {
                    name: 'Alert Area',
                    type: 'polygon',
                    data: [],
                    color: 'rgba(231,15,40,.5)',
                    zIndex: 0
                };
                var firstDate;
                var lastDate;
                if (dataSeries && dataSeries.data && dataSeries.data.length > 0) {
                    firstDate = dataSeries.data[0].x;
                    lastDate = dataSeries.data[dataSeries.data.length - 1].x;
                }
                if (!category) {
                    alertAreaSeries.data = alertAreaSeries.data
                        .concat(this.createRectangleForPolygonSeries(dataSeries.data[0].x, dataSeries.data[dataSeries.data.length - 1].x, 0, minValue));
                    if (maxValue) {
                        alertAreaSeries.data.push({ x: null, y: null });
                        alertAreaSeries.data = alertAreaSeries.data
                            .concat(this.createRectangleForPolygonSeries(dataSeries.data[0].x, dataSeries.data[dataSeries.data.length - 1].x, maxValue, maxValue + 1000));
                    }
                }
                else if (skipTimeframeCheck || (firstDate && lastDate && this.categoryInTimeFrame(firstDate, lastDate, category))) {
                    var alertAreas = [];
                    var currentAlertArea = {
                        x1: null,
                        x2: null
                    };
                    if (category.week && category.week.length > 0) {
                        for (var i = 0; i < dataSeries.data.length; i++) {
                            var data = dataSeries.data[i];
                            if (this.matchesCalendar(category, data.x, currentAlertArea.x1)) {
                                if (!currentAlertArea.x1) {
                                    currentAlertArea.x1 = data.x;
                                }
                                currentAlertArea.x2 = data.x;
                            }
                            else if (currentAlertArea.x1) {
                                alertAreas.push(angular.copy(currentAlertArea));
                                currentAlertArea = {
                                    x1: null,
                                    x2: null
                                };
                            }
                        }
                        if (currentAlertArea.x1) {
                            alertAreas.push(angular.copy(currentAlertArea));
                        }
                    }
                    else {
                        var firstTimeInTimeline = this.findFirstTime(dataSeries.data, 0, dataSeries.data.length - 1, category.dateStart);
                        var lastTimeInTimeline = this.findLastTime(dataSeries.data, 0, dataSeries.data.length - 1, category.dateEnd);
                        if (!firstTimeInTimeline) {
                            firstTimeInTimeline = firstDate;
                        }
                        if (!lastTimeInTimeline) {
                            lastTimeInTimeline = lastDate;
                        }
                        if (this.matchesCalendar(category, firstTimeInTimeline, currentAlertArea.x1)) {
                            currentAlertArea = { x1: firstTimeInTimeline, x2: lastTimeInTimeline };
                            alertAreas.push(angular.copy(currentAlertArea));
                        }
                    }
                    alertAreas.forEach(function (alertArea) {
                        alertAreaSeries.data = alertAreaSeries.data.concat(_this.createRectangleForPolygonSeries(alertArea.x1, alertArea.x2, 0, minValue));
                        alertAreaSeries.data.push({ x: null, y: null });
                        alertAreaSeries.data = alertAreaSeries.data.concat(_this.createRectangleForPolygonSeries(alertArea.x1, alertArea.x2, maxValue, yAxisMax + 1000));
                        alertAreaSeries.data.push({ x: null, y: null });
                    });
                }
                return alertAreaSeries;
            };
            AlertPreviewComponentCtrl.prototype.createRectangleForPolygonSeries = function (x1, x2, y1, y2) {
                var rectangle = [];
                rectangle.push({
                    x: x1,
                    y: y1
                });
                rectangle.push({
                    x: x2,
                    y: y1
                });
                rectangle.push({
                    x: x2,
                    y: y2
                });
                rectangle.push({
                    x: x1,
                    y: y2
                });
                return rectangle;
            };
            AlertPreviewComponentCtrl.prototype.getCustomMetrics = function (dataSeries, category) {
                var vals = [];
                for (var i = 0; i < dataSeries.data.length; i++) {
                    var data = dataSeries.data[i];
                    if (this.matchesCalendar(category, data.x) && data.y) {
                        vals.push(data.y);
                    }
                }
                return {
                    values: {
                        avg: _.sum(vals) / vals.length
                    }
                };
            };
            AlertPreviewComponentCtrl.prototype.categoryInTimeFrame = function (firstDate, lastDate, category) {
                var categoryStart = category.dateStart;
                var categoryEnd = category.dateEnd;
                var timeZoneId = this.building.timeZoneId;
                var firstDateTime = moment(firstDate).tz(timeZoneId);
                var lastDateTime = moment(lastDate).tz(timeZoneId);
                var categoryStartTime = moment(categoryStart).tz(timeZoneId);
                var categoryEndTime = moment(categoryEnd).tz(timeZoneId);
                if ((categoryStartTime.isSameOrAfter(firstDateTime) && categoryStartTime.isSameOrBefore(lastDateTime))
                    || (categoryEndTime.isSameOrAfter(firstDateTime) && categoryEndTime.isSameOrBefore(lastDateTime))
                    || (category.week && category.week.length > 0 && ((!categoryEndTime.isBefore(firstDateTime) && categoryStartTime.isSameOrBefore(firstDateTime))
                        || (categoryEndTime.isSameOrAfter(lastDateTime)) && !categoryStartTime.isAfter(lastDateTime)))
                    || (category.week && category.week.length > 0 && !categoryStart && !categoryEnd)) {
                    return true;
                }
                else {
                    return false;
                }
            };
            //Binary search for first time in the timeline that's within the date range
            AlertPreviewComponentCtrl.prototype.findFirstTime = function (arr, lowIndex, highIndex, firstDate) {
                if (lowIndex <= highIndex) {
                    if (highIndex === 0) {
                        return arr[0].x;
                    }
                    var midIndex = Math.round((lowIndex + highIndex) / 2);
                    var timeZoneId = this.building.timeZoneId;
                    var midTime = moment(arr[midIndex].x).tz(timeZoneId);
                    var midTimeMinusOne = moment(arr[midIndex - 1].x).tz(timeZoneId);
                    if (midIndex > 0 && midTime.isSameOrAfter(firstDate)) {
                        if (midTimeMinusOne.isBefore(firstDate)) {
                            return arr[midIndex].x;
                        }
                        else {
                            return this.findFirstTime(arr, lowIndex, midIndex - 1, firstDate);
                        }
                    }
                    if (midTime.isAfter(firstDate)) {
                        return this.findFirstTime(arr, lowIndex, midIndex - 1, firstDate);
                    }
                    else {
                        return this.findFirstTime(arr, midIndex + 1, highIndex, firstDate);
                    }
                }
                return undefined;
            };
            //Binary search for last time in the timeline that's within the date range
            AlertPreviewComponentCtrl.prototype.findLastTime = function (arr, lowIndex, highIndex, lastDate) {
                if (lowIndex <= highIndex) {
                    var midIndex = Math.round((lowIndex + highIndex) / 2);
                    if (midIndex === arr.length - 1) {
                        return arr[arr.length - 1].x;
                    }
                    var timeZoneId = this.building.timeZoneId;
                    var midTime = moment(arr[midIndex].x).tz(timeZoneId);
                    var midTimePlusOne = moment(arr[midIndex + 1].x).tz(timeZoneId);
                    if (midIndex <= highIndex && midTime.isSameOrBefore(lastDate)) {
                        if (midTimePlusOne.isAfter(lastDate)) {
                            return arr[midIndex].x;
                        }
                        else {
                            return this.findLastTime(arr, midIndex + 1, highIndex, lastDate);
                        }
                    }
                    if (midTime.isAfter(lastDate)) {
                        return this.findLastTime(arr, lowIndex, midIndex - 1, lastDate);
                    }
                    else {
                        return this.findLastTime(arr, midIndex + 1, highIndex, lastDate);
                    }
                }
                return undefined;
            };
            AlertPreviewComponentCtrl.prototype.matchesCalendar = function (category, timestamp, matchedBound) {
                var matches = true;
                var timeZoneId = this.building.timeZoneId;
                var dateTime = moment(timestamp).tz(timeZoneId);
                if (!matchedBound)
                    matchedBound = dateTime;
                if (category.dateStart) {
                    var start = moment(category.dateStart).tz(timeZoneId);
                    matches = matches && dateTime.isSameOrAfter(start);
                }
                if (category.dateEnd) {
                    var end = moment(category.dateEnd).tz(timeZoneId);
                    matches = matches && dateTime.isSameOrBefore(end);
                }
                if (category.week && category.week.length > 0) {
                    var matchesDayOfWeek = false;
                    for (var w = 0; w < category.week.length; w++) {
                        if (dateTime.format('dddd').toLowerCase() === category.week[w].toLowerCase()) {
                            matchesDayOfWeek = true;
                            break;
                        }
                    }
                    matches = matches && matchesDayOfWeek;
                }
                if (category.timeStart) {
                    var tokens = category.timeStart.split(':');
                    var hour = parseInt(tokens[0], 10);
                    var minutes = parseInt(tokens[1], 10);
                    var timeStartMoment = moment(matchedBound).tz(timeZoneId).hour(hour).minute(minutes);
                    matches = matches && dateTime.isSameOrAfter(timeStartMoment);
                }
                if (category.timeEnd) {
                    var tokens = category.timeEnd.split(':');
                    var hour = parseInt(tokens[0], 10);
                    var minutes = parseInt(tokens[1], 10);
                    var timeEndMoment = moment(matchedBound).tz(timeZoneId).hour(hour).minute(minutes);
                    if (moment(category.timeEnd, 'hh:mm').tz(timeZoneId).isBefore(moment(category.timeStart, 'hh:mm').tz(timeZoneId)))
                        timeEndMoment = timeEndMoment.add(1, 'day');
                    matches = matches && dateTime.isSameOrBefore(timeEndMoment);
                }
                return matches;
            };
            AlertPreviewComponentCtrl.prototype.getSeriesForData = function (data) {
                var series = {
                    name: this.getQueryable().name,
                    data: [],
                    color: '#0091f1',
                    zIndex: 2
                };
                for (var i = 0; i < data.timestamps.length; i++) {
                    series.data.push({
                        x: data.timestamps[i],
                        y: data.values[i] ? Math.round(this.$filter('toUnit')(data.values[i], this.alert.unit)) : 0
                    });
                }
                return series;
            };
            AlertPreviewComponentCtrl.prototype.getExpectedRangeSeries = function (data) {
                var series = {
                    name: "Expected Energy " + this.getQueryable().name,
                    data: [],
                    color: '#aacee2',
                    zIndex: 1,
                    type: 'arearange',
                    fillOpacity: 1
                };
                for (var i = 0; i < data['POWER_EXPECTED'].timestamps.length; i++) {
                    var stdDev = data['POWER_STDDEV'].values[i];
                    var expected = data['POWER_EXPECTED'].values[i];
                    if (expected && stdDev) {
                        series.data.push({
                            x: data['POWER_EXPECTED'].timestamps[i],
                            low: Math.round(this.$filter('toUnit')(expected - stdDev, this.alert.unit)),
                            high: Math.round(this.$filter('toUnit')(expected + stdDev, this.alert.unit))
                        });
                    }
                }
                return series;
            };
            AlertPreviewComponentCtrl.prototype.getPlotLines = function (metrics, convertToUnit) {
                if (convertToUnit === void 0) { convertToUnit = true; }
                var plotLines = [];
                var avgPlotLine = {
                    color: '#7ACD46',
                    dashStyle: 'dash',
                    value: convertToUnit ? this.$filter('toUnit')(metrics.values.avg, this.alert.unit) : metrics.values.avg,
                    width: 2,
                    label: {
                        text: 'Avg',
                        align: 'right',
                        style: {
                            color: '#7ACD46'
                        },
                        x: -10
                    }
                };
                plotLines.push(avgPlotLine);
                return plotLines;
            };
            AlertPreviewComponentCtrl.prototype.getRestQueryable = function () {
                var buildingPrefix = [
                    {
                        id: this.account.id,
                        route: 'accounts'
                    }, {
                        id: this.building.id,
                        route: 'buildings'
                    }
                ];
                var queryable = this.getQueryable();
                var queryCursor = queryable;
                var queryables = [];
                var parentRoute = queryCursor.route;
                // push selected queryable onto chain
                if (this.alert.drillMode !== 'BUILDING') {
                    queryables.push({
                        id: queryCursor.id,
                        route: queryCursor.route
                    });
                }
                while (queryCursor.parent != null) {
                    // only push a queryable onto the chain if its route is different from its parent
                    if (queryCursor.route != parentRoute) {
                        queryables.push({
                            id: queryCursor.id,
                            route: queryCursor.route
                        });
                    }
                    parentRoute = queryCursor.route;
                    queryCursor = queryCursor.parent;
                }
                _.reverse(queryables);
                return _.concat(buildingPrefix, queryables);
            };
            return AlertPreviewComponentCtrl;
        }(propertySettings.AbstractAlertComponent));
        propertySettings.AlertPreviewComponentCtrl = AlertPreviewComponentCtrl;
        angular.module('properties')
            .component('alertPreview', {
            templateUrl: 'app/properties/alerts/directives/alertPreview.html',
            bindings: {
                alert: '<',
                calendars: '<',
                account: '=',
                building: '=',
                buildingDrillin: '<',
                collectors: '<',
                sources: '<',
                tenants: '<',
                noDataCallback: '&?',
                contextLoadedCallback: '&?',
                isActivity: '<?',
                showOptimizationButton: '<?',
                hideTitle: '<',
                displayHeight: '<',
                xaxisDateFormat: '@',
                xaxisLabelRotation: '<'
            },
            controller: AlertPreviewComponentCtrl
        });
    })(propertySettings = aq.propertySettings || (aq.propertySettings = {}));
})(aq || (aq = {}));
