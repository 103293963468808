angular.module('aq.admin.meters.copydata').controller('AdminMeterCopyDataCtrl',
    function($scope, Restangular, Messages, $interval, accounts) {

    $scope.copying = false;
    $scope.fromDate = moment().add(-1, 'month');
    $scope.toDate = moment();
    $scope.accounts = accounts;
    $scope.mergePoints = false;

    // this is check in case someone else run this job so we don't have multiple jobs
    // running at the same time, or in case if you refresh
    // page after you run copy meter data job so you can have reference when job is done
    $scope.checkIfCopyMeterDataIsRunning = function () {
        Restangular.one('internal').customGET('updateMeterDataStatus',  { nocache : new Date().getTime() }).then(function (result) {
            if (result == 'INPROGRESS') {
                $scope.copying = true;
            } else {
                if ($scope.copying == true) {
                    Messages.info('Meter data copied!');
                }
                $scope.copying = false;
            }
        });
    }
    var stopInterval = $interval($scope.checkIfCopyMeterDataIsRunning, 1000);
    $scope.checkIfCopyMeterDataIsRunning();

    $scope.$on('$destroy', function() {
        $interval.cancel(stopInterval);
    });

    $scope.checkEnabled = function() {
        if (!$scope.fromPoint && !$scope.mergePoints || !$scope.fromMeter && $scope.mergePoints ||
            !$scope.toPoint || $scope.copying) return false;

        var mandatoryValues = [
            $scope.toPoint.id,
            $scope.fromDate,
            $scope.toDate
        ]

        mandatoryValues = _.filter(mandatoryValues, function(value) {
            return value;
        })
        return mandatoryValues.length == 3;
    }

    $scope.copyMeterData = function() {
        if ($scope.copying == true) {
            Messages.warn('Cannot copy meter data while another copying is in progress');
            return;
        }

        var start = moment($scope.fromDate).format('YYYY-MM-DD HH:mm:ss+00');
        var end = moment($scope.toDate).format('YYYY-MM-DD HH:mm:ss+00');

        var data: any = {
            toPointId: $scope.toPoint.id,
            startDate: start,
            endDate: end
        }

        if ($scope.mergePoints) {
            data.fromMeterId = $scope.fromMeter.id;
            data.fromPointId = null;
        } else {
            data.fromPointId = $scope.fromPoint.id;
        }

        if ($scope.multiplier) {
            data.multiplier = $scope.multiplier;
        }

        if ($scope.intervalValue && $scope.intervalType) {
            data.intervalValue = $scope.intervalValue;
            data.intervalType = $scope.intervalType;
        }

        $scope.copying = true;
        Restangular.one('internal').customPOST(null, 'copyMeterData', data).then(function() {
            $scope.copying = true;
        }, function(error) {
            $scope.copying = false;
            Messages.error(error.data);
        });

    }

    $scope.updateDestinationDates = function() {
        if (!$scope.fromDate || !$scope.toDate) return;

        if ($scope.intervalValue && $scope.intervalType) {
            $scope.destinationToDate = moment($scope.toDate).add($scope.intervalValue, $scope.intervalType);
            $scope.destinationFromDate = moment($scope.fromDate).add($scope.intervalValue, $scope.intervalType);
        } else {
            $scope.destinationToDate = moment($scope.toDate);
            $scope.destinationFromDate = moment($scope.fromDate);
        }
    };

    $scope.$watchCollection('[intervalValue,intervalType]', $scope.updateDestinationDates);

});
