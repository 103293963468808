var aq;
(function (aq) {
    var dashboard;
    (function (dashboard) {
        var widgets;
        (function (widgets) {
            var AidActions = aq.models.segment.AidActions;
            var _AidIssue = /** @class */ (function () {
                /* @ngInject */
                function _AidIssue($scope, $translate, RestangularV3, resolver, Messages, UserService, Segment, AidIssueService, $window) {
                    this.$scope = $scope;
                    this.$translate = $translate;
                    this.RestangularV3 = RestangularV3;
                    this.resolver = resolver;
                    this.Messages = Messages;
                    this.UserService = UserService;
                    this.Segment = Segment;
                    this.AidIssueService = AidIssueService;
                    this.$window = $window;
                    this.isAcknowledged = false;
                    this.isBeingAcknowledged = false;
                    this.isEditMode = false;
                    this.isBeingSaved = false;
                    this.otherString = 'other';
                    this.aqPotentialCauseList = [
                        'not an issue',
                        'early start',
                        'equipment cycling/noise',
                        'equipment maintenance/repair',
                        'equipment/BMS error',
                        'freeze protection',
                        'late shutdown',
                        'missed shutdown',
                        'tenant request',
                        'unoccupied hour temperature setback for cooling',
                        'unoccupied hour temperature setback for heating',
                        'unscheduled equipment running'
                    ];
                    this.chartDataParams = {
                        measure: 'electricity',
                        unit: 'kW',
                        start: moment().subtract(3, 'days').startOf('day').toISOString(),
                        end: moment().toISOString(),
                        interval: '15min'
                    };
                    this.isChartDataLoading = true;
                    this.chartMinTime = moment().subtract(2, 'days').startOf('day');
                    this.chartMaxTime = moment().add(2, 'days').endOf('day');
                    this.chartMinTemp = 0;
                    this.chartMaxTemp = 100;
                    this.chartColors = {
                        issueHighlight: 'rgba(255,40,40,.50)',
                        energy: 'rgb(28,108,171)',
                        temperature: 'rgb(217,223,32)'
                    };
                }
                _AidIssue.prototype.$onInit = function () {
                    var _this = this;
                    this.isAcknowledged = (this.issue.feedback && this.issue.feedback.length > 0);
                    this.isChartDataLoading = true;
                    this.currentUserId = this.UserService.currentUser.id;
                    this.initCallToAction();
                    this.initSeriesBounds();
                    this.chartDataParams.start = this.chartMinTime.toISOString();
                    this.chartDataParams.end = this.chartMaxTime.toISOString();
                    this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                        var _a, err_1;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    _b.trys.push([0, 2, , 3]);
                                    _a = this;
                                    return [4 /*yield*/, this.RestangularV3.all("buildings/" + this.building.id).customGET('raw', this.chartDataParams)];
                                case 1:
                                    _a.chartData = _b.sent();
                                    this.energySeries = this.buildElectricitySeries(this.chartData[this.chartDataParams.unit.toLowerCase()].data);
                                    this.issueHighlightSeries = this.buildIssueHighlightSeries(this.issue.highlightedArea);
                                    this.temperatureSeries = this.buildTemperatureSeries(this.issue.series);
                                    this.chartConfig = this.buildChartConfig();
                                    this.isChartDataLoading = false;
                                    this.$scope.$apply();
                                    return [3 /*break*/, 3];
                                case 2:
                                    err_1 = _b.sent();
                                    this.isChartDataLoading = false;
                                    this.Messages.error('Failed to load chart data for chart');
                                    throw err_1;
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); });
                };
                _AidIssue.prototype.getEventTypes = function () {
                    var _this = this;
                    if (!this.allEventTypes) {
                        this.allEventTypes = [];
                        _.each(aq.models.aidIssue.EventType, function (type) {
                            var option = {
                                label: _.startCase(_.lowerCase(type)),
                                value: type
                            };
                            _this.allEventTypes.push(option);
                        });
                    }
                    return this.allEventTypes;
                };
                _AidIssue.prototype.getPotentialCauses = function () {
                    var _this = this;
                    if (!this.allPotentialCauses) {
                        this.allPotentialCauses = [];
                        _.each(this.aqPotentialCauseList.concat([this.otherString]), function (cause) {
                            var option = {
                                label: _.startCase(cause),
                                value: cause
                            };
                            _this.allPotentialCauses.push(option);
                        });
                    }
                    return this.allPotentialCauses;
                };
                _AidIssue.prototype.makeIssueUpdates = function () {
                    this.isEditMode = true;
                };
                _AidIssue.prototype.cancelIssueUpdates = function () {
                    this.isEditMode = false;
                };
                _AidIssue.prototype.onPotentialCauseChange = function () {
                    if (this.otherString !== this.selectedPotentialCause) {
                        this.customPotentialCause = null;
                    }
                };
                _AidIssue.prototype.saveIssueUpdates = function () {
                    var _this = this;
                    if (this.otherString === this.selectedPotentialCause) {
                        this.customPotentialCause = _.trim(this.customPotentialCause);
                    }
                    var feedbackRequest = new aq.models.aidIssue.IssueFeedbackCreateRequest();
                    feedbackRequest.issue = this.issue.id;
                    feedbackRequest.user = this.currentUserId;
                    feedbackRequest.actionType = aq.models.aidIssue.ActionType.MODIFY;
                    feedbackRequest.eventType = this.issue.eventType;
                    if (_.includes(this.aqPotentialCauseList, this.selectedPotentialCause)) {
                        feedbackRequest.potentialCause = this.selectedPotentialCause;
                    }
                    else {
                        feedbackRequest.potentialCause = this.customPotentialCause;
                    }
                    this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                        var feedbackResponse, err_2;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    this.isBeingSaved = true;
                                    return [4 /*yield*/, this.AidIssueService.createFeedback(feedbackRequest)];
                                case 1:
                                    feedbackResponse = _a.sent();
                                    this.Segment.trackChurnZeroEvent(AidActions.MODIFY_AID_ISSUE, [this.building]);
                                    this.Messages.success('Successfully updated issue');
                                    this.issue.feedback.push(feedbackResponse);
                                    this.issueFeedbackForm.$setPristine();
                                    this.initCallToAction();
                                    this.isBeingSaved = false;
                                    this.isEditMode = false;
                                    this.isAcknowledged = true;
                                    this.$scope.$apply();
                                    return [3 /*break*/, 3];
                                case 2:
                                    err_2 = _a.sent();
                                    this.isBeingSaved = false;
                                    this.Messages.error('Something went wrong updating this issue!');
                                    throw err_2;
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); });
                };
                _AidIssue.prototype.acknowledgeIssue = function () {
                    var _this = this;
                    var feedbackRequest = new aq.models.aidIssue.IssueFeedbackCreateRequest();
                    feedbackRequest.issue = this.issue.id;
                    feedbackRequest.user = this.currentUserId;
                    feedbackRequest.actionType = aq.models.aidIssue.ActionType.ACKNOWLEDGE;
                    this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                        var err_3;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    this.isBeingAcknowledged = true;
                                    return [4 /*yield*/, this.AidIssueService.createFeedback(feedbackRequest)];
                                case 1:
                                    _a.sent();
                                    this.Segment.trackChurnZeroEvent(AidActions.ACKNOWLEDGE_AID_ISSUE, [this.building]);
                                    this.isBeingAcknowledged = false;
                                    this.isAcknowledged = true;
                                    this.Messages.success('Successfully acknowledged issue');
                                    this.$scope.$apply();
                                    return [3 /*break*/, 3];
                                case 2:
                                    err_3 = _a.sent();
                                    this.isBeingAcknowledged = false;
                                    this.Messages.error('Something went wrong acknowledging this issue!');
                                    throw err_3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); });
                };
                _AidIssue.prototype.getOptimizationDates = function (issue) {
                    if (issue.date) {
                        return {
                            startDate: moment(issue.date).startOf('day').subtract(1, 'days'),
                            endDate: moment(issue.date).startOf('days').add(2, 'days')
                        };
                    }
                    return {
                        startDate: moment().startOf('days').subtract(1, 'days'),
                        endDate: moment().startOf('days').add(2, 'days')
                    };
                };
                _AidIssue.prototype.goToOptimization = function () {
                    var _this = this;
                    var _a = this.getOptimizationDates(this.issue), startDate = _a.startDate, endDate = _a.endDate;
                    this.account.get({ single: true }).then(function (thisAccount) {
                        var optimizationURI = URI('/accounts/' + thisAccount.id + '/optimization/building')
                            .search({
                            unit: 'kW',
                            apiUnit: 'POWER',
                            interval: '15min',
                            off: _.without(thisAccount.buildings, _this.building.id).join(','),
                            start: startDate.toISOString(),
                            end: endDate.toISOString(),
                            children: 'buildings'
                        });
                        window.open(optimizationURI.toString());
                    });
                };
                _AidIssue.prototype.initCallToAction = function () {
                    if (this.issue && this.issue.potentialCause) {
                        var fbMsg = null;
                        if (this.issue.feedback && this.issue.feedback.length > 0) {
                            fbMsg = this.getLatestFeedbackPotentialCause(this.issue.feedback);
                        }
                        var latestCause = fbMsg || this.issue.potentialCause;
                        if (_.includes(this.aqPotentialCauseList, latestCause)) {
                            this.selectedPotentialCause = latestCause;
                        }
                        else {
                            this.selectedPotentialCause = this.otherString;
                        }
                        this.callToActionMessage = "Was this " + latestCause + "?";
                    }
                };
                _AidIssue.prototype.getLatestFeedbackPotentialCause = function (feedbackList) {
                    var result = null;
                    var filteredFeedback = _.filter(feedbackList, function (f) { return !(!f.potentialCause); });
                    if (filteredFeedback.length > 0) {
                        var orderedFeedback = _.sortBy(filteredFeedback, [function (f) { return moment(f.created).valueOf(); }]).reverse();
                        result = orderedFeedback[0].potentialCause;
                    }
                    return result;
                };
                _AidIssue.prototype.initSeriesBounds = function () {
                    this.chartMinTime = moment.parseZone(_.minBy(this.issue.series, function (s) { return moment.parseZone(s.time); }).time);
                    this.chartMaxTime = moment.parseZone(_.maxBy(this.issue.series, function (s) { return moment.parseZone(s.time); }).time);
                    var minTemp = _.minBy(this.issue.series, function (s) { return s.temperature; }).temperature;
                    this.chartMinTemp = this.roundToNearest(minTemp, 5, true);
                    var maxTemp = _.maxBy(this.issue.series, function (s) { return s.temperature; }).temperature;
                    this.chartMaxTemp = this.roundToNearest(maxTemp, 5);
                };
                _AidIssue.prototype.buildElectricitySeries = function (data) {
                    var seriesDataArray = [];
                    data.forEach(function (point) {
                        var seriesDataPoint = {
                            x: point.timestamp,
                            y: point.datum
                        };
                        seriesDataArray.push(seriesDataPoint);
                    });
                    var series = {
                        name: this.building.name,
                        data: seriesDataArray,
                        color: '#0091f1'
                    };
                    return series;
                };
                _AidIssue.prototype.buildIssueHighlightSeries = function (datum) {
                    var issueSelectionRectangle = [];
                    var min = datum.min_val;
                    var max = datum.max_val;
                    var start = moment(datum.time_start).valueOf();
                    var end = moment(datum.time_end).valueOf();
                    issueSelectionRectangle.push({
                        x: start,
                        y: min
                    });
                    issueSelectionRectangle.push({
                        x: end,
                        y: min
                    });
                    issueSelectionRectangle.push({
                        x: end,
                        y: max
                    });
                    issueSelectionRectangle.push({
                        x: start,
                        y: max
                    });
                    var selectionSeries = {
                        name: 'Issue Detected',
                        type: 'polygon',
                        tooltip: null,
                        data: issueSelectionRectangle,
                        color: this.chartColors.issueHighlight,
                        zIndex: 0,
                        showInLegend: false
                    };
                    return selectionSeries;
                };
                _AidIssue.prototype.buildTemperatureSeries = function (datum) {
                    // FIXME: AQ-6081 - Need endpoint for weather instead of getting from series ouput
                    var seriesData = [];
                    _.each(this.issue.series, function (seriesDatum) {
                        var xPointValue = moment.parseZone(seriesDatum.time).valueOf();
                        var temperaturePoint = {
                            y: seriesDatum.temperature,
                            x: xPointValue
                        };
                        seriesData.push(temperaturePoint);
                    });
                    var series = {
                        data: seriesData,
                        name: this.$translate.instant('Temperature'),
                        type: 'line',
                        color: this.chartColors.temperature,
                        dashStyle: 'ShortDash',
                        states: {
                            hover: { enabled: true }
                        },
                        yAxis: 1
                    };
                    return series;
                };
                _AidIssue.prototype.buildChartConfig = function () {
                    var _this = this;
                    var series = [];
                    series.push(this.issueHighlightSeries);
                    series.push(this.temperatureSeries);
                    series.push(this.energySeries);
                    var getContext = function () { return _this; };
                    var result = {
                        chart: {
                            animation: false,
                            plotBorderWidth: 1,
                            type: 'line'
                        },
                        title: { text: null },
                        subtitle: { text: null },
                        legend: { enabled: false },
                        tooltip: {
                            shared: true,
                            // tslint:disable-next-line:object-literal-shorthand
                            formatter: function () {
                                try {
                                    var context = getContext();
                                    var dateStr = moment(this.x).tz(context.building.timeZoneId).format('lll');
                                    var tooltipMarkup_1 = [];
                                    tooltipMarkup_1.push("" + dateStr);
                                    _.each(this.points, function (p) {
                                        var isTempData = (_.toLower(p.series.name) === 'temperature');
                                        // tslint:disable-next-line:max-line-length
                                        tooltipMarkup_1.push("<span style=\"color:" + p.series.color + "\">\u25CF</span> " + p.series.name + ": <b>" + p.y.toFixed(0) + " " + (isTempData ? '\u00B0F' : 'kW') + "</b></span>");
                                    });
                                    return tooltipMarkup_1.join('<br/>');
                                }
                                catch (err) {
                                    return null;
                                }
                            }
                        },
                        xAxis: {
                            type: 'datetime',
                            startOfWeek: 0,
                            tickInterval: 6 * 3600 * 1000,
                            labels: {
                                formatter: function () {
                                    var context = getContext();
                                    return moment(this.value).tz(context.building.timeZoneId).format('ddd MMM D<br>h:mma');
                                },
                                rotation: -45
                            },
                            tickLength: 0,
                            gridLineWidth: 1,
                            gridLineColor: '#c7c7c7'
                        },
                        yAxis: [
                            {
                                title: { text: 'Issue Detected' },
                                gridLineWidth: 0,
                                minorGridLineWidth: 0
                            },
                            {
                                title: { text: 'Temperature \u00B0F' },
                                min: this.chartMinTemp,
                                max: this.chartMaxTemp,
                                opposite: true,
                                gridLineWidth: 0,
                                minorGridLineWidth: 0
                            },
                            {
                                min: 0,
                                title: {
                                    text: this.chartDataParams.unit,
                                    style: {
                                        fontSize: '14px'
                                    }
                                },
                                labels: {
                                    style: {
                                        fontSize: '14px'
                                    }
                                }
                            }
                        ],
                        series: series,
                        navigation: {
                            buttonOptions: {
                                enabled: false
                            }
                        }
                    };
                    return result;
                };
                _AidIssue.prototype.roundToNearest = function (val, roundBy, roundDown) {
                    if (roundDown === void 0) { roundDown = false; }
                    var result = val;
                    if (roundDown) {
                        result = Math.floor((result - 0.01) / roundBy) * roundBy;
                    }
                    else {
                        result = Math.ceil((result + 0.01) / roundBy) * roundBy;
                    }
                    return result;
                };
                return _AidIssue;
            }());
            widgets._AidIssue = _AidIssue;
            widgets.AidIssue = {
                controller: _AidIssue,
                controllerAs: 'vm',
                templateUrl: 'app/dashboard/common/widgets/aidIssue/aidIssueView.html',
                bindings: {
                    issue: '<',
                    building: '<',
                    account: '<'
                }
            };
            angular
                .module('aq.dashboard.widgets')
                .component('aidIssue', widgets.AidIssue);
        })(widgets = dashboard.widgets || (dashboard.widgets = {}));
    })(dashboard = aq.dashboard || (aq.dashboard = {}));
})(aq || (aq = {}));
;
