'use strict'

angular.module('properties').directive('meteredUnmetered', function () {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            source: '=',
            uses: '=',
            edit: '=',
            update: '&'
        },
        templateUrl: 'app/properties/sources/details/common/meteredUnmetered/meteredUnmetered.html',
        controller: function ($scope) {

            var getConnectedCount = function (source, type) {
                return _.filter(source.circuits, function (c) {
                    var haveUsedInputs = _.find(c.inputs, function (i) {
                        return i.point;
                    });
                    return c.type == type && (haveUsedInputs || c.point);
                }).length
            }

            var getCircuitCount = function (source, type) {
                return _.filter(source.circuits, function (circuit) {
                    return circuit.type == type;
                }).length
            }

            var updateChart = function (source) {

                $scope.virtualPoint = _(source.circuits).filter(function (c) {
                    return c.type == "VIRTUAL"
                }).first();

                var connectedLoads = getConnectedCount(source, 'LOAD')
                var loadsCount = getCircuitCount(source, 'LOAD');
                var meteredRatio = connectedLoads == 0 ? 0 : connectedLoads / loadsCount;
                var meteredLoads = meteredRatio * 100;
                $scope.connectedFeed = getConnectedCount(source, 'FEED');

                $scope.pieFeedData = [
                    {
                        data: $scope.connectedFeed,
                        color: "#3DAEFA"
                    },
                    {
                        data: 1 - $scope.connectedFeed,
                        color: "#D9D9D9"
                    }
                ];
                $scope.pieLoadData = [
                    {
                        data: meteredLoads,
                        color: "#3DAEFA"
                    },
                    {
                        data: 100 - meteredLoads,
                        color: "#D9D9D9"
                    }
                ];
                $scope.connectedLoads = connectedLoads;
                $scope.meteredLoadsPercentage = numeral(meteredRatio).format('0%')
                $scope.remainingLoadsPercentage = numeral(1 - meteredRatio).format('0%');
                $scope.connectedFeedPercentage = numeral($scope.connectedFeed).format('0%');
                $scope.remainingLoads = loadsCount - connectedLoads;

                if (!$scope.pieLoadData.refresh) return;
                $scope.pieLoadData.refresh();

                if (!$scope.pieFeedData.refresh) return;
                $scope.pieFeedData.refresh();
            }

            $scope.$watch('source', function (source) {
                if (!source) return;
                updateChart(source);
            })
        }
    }
});