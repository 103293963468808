angular
    .module('aq.admin.accounts.buildings', [])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.admin.accounts.buildings', {
                url: '/buildings',
                templateUrl: 'app/admin/accounts/buildings/list/list.html',
                controller: 'AdminBuildingListCtrl as adminBuildingListCtrl',
                resolve: {
                    allBuildings: (Restangular, waitForAuthToken) => {
                        return Restangular.one('internal').customGET('buildings', {
                            inflate: 'account',
                            page: 1,
                            pageSize: 10,
                            orderBy: 'name',
                            asc: true
                        });
                    }
                },
                data: {
                    breadcrumb: 'Buildings',
                    icon: '.aq-icons-buildings'
                }
            })
            .state('aq.admin.accounts.buildings.details', {
                url: '/:buildingId',
                templateUrl: 'app/admin/accounts/buildings/details/details.html',
                controller: 'AdminBuildingDetailsCtrl as adminBuildingDetailsCtrl',
                resolve: {
                    building: (Restangular, $stateParams, waitForAuthToken) => {
                        return Restangular.one('internal').one('buildings', $stateParams["buildingId"]).get({inflate: 'account'});
                    },
                    accounts: (Restangular, waitForAuthToken) => {
                        return Restangular.one('accounts').customGET('queryAllAccountNames');
                    }
                }
            });
});
