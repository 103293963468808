namespace aq.energyInsights {

    export class EnergyStarInsightService extends InsightService {
        /* @ngInject */
        constructor(
            private DataStore,
            private Restangular,
            private $translate
        ) {
            super();
        }

        public getSimpleTargetModel(building: aq.common.models.Building, measure: string, periodSearch: PeriodSearch): SimpleTargetModel {
            let targetModel = new SimpleTargetModel();
            targetModel.title = this.$translate.instant('insight.ENERGY STAR<sup>&reg;</sup> Score');
            const score = building.energyStarScore;
            if (score && score.lastUpdated) {
                const date = moment(score.lastUpdated, 'YYYY-MM-DD HH:mm:ss').format('MMMM YYYY');
                targetModel.when = `${this.$translate.instant('insight.when.Energy Star')} ${date}`;
                targetModel.whenTooltip = this.$translate.instant('insight.when_tooltip.Energy Star');
            }
            return targetModel;
        }

        getTargetModel(building: common.models.Building, measure: string,
            timePeriod: energyInsights.PeriodSearch, account: aq.common.models.Account, name: string): ng.IPromise<energyInsights.TargetModel> {
            return this.DataStore.getList(this.Restangular.one('accounts', account.id).one('buildings', building.id).one('Targets'), 'queryTargets', {
                startDate: moment().startOf('month').format(),
                endDate: moment().endOf('month').format(),
                targetType: 'ENERGY_STAR'
            }).then((targets) => {
                return this.calculateTargetModel(building, targets, name);
            });
        }

        getGraphModel(building: common.models.Building, measure: string,
            timePeriod: energyInsights.PeriodSearch): ng.IPromise<energyInsights.GraphModel> {
            throw new TypeError('This is a stub method');
        }

        getTableModel(building: common.models.Building, measure: string,
            timePeriod: energyInsights.PeriodSearch): ng.IPromise<energyInsights.TableModel> {
            throw new TypeError('This is a stub method');
        }

        formatTableModel(values: any, targets: any, periodSearch: any, building: any) {
            throw new TypeError('This is a stub method');
        }

        getTarget(targets: any[]) {
            if (targets && targets.length > 0) {
                return targets[0].value;
            } else {
                return null;
            }
        }

        getStatus(answer, target, percentage, percTime): string {
            if (!answer || isNaN(parseInt(answer)) || !target) {
                return 'invalid';
            } else {
                const diff = answer - target;
                if (diff >= 0) {
                    return 'under';
                } else if (diff >= -5) {
                    return 'warn';
                } else {
                    return 'over';
                }
            }
        }

        calculateTargetModel(building: aq.common.models.Building, targets: any[], name: string) {
            // Only filling out the items needed for our dashboard widget
            const targetModel = new TargetModel();
            const score = building.energyStarScore;
            const target = this.getTarget(targets);
            if (score) {
                if (score.lastUpdated) {
                    const date = moment(score.lastUpdated, 'YYYY-MM-DD HH:mm:ss').format('MMMM YYYY');
                    targetModel.when = `${this.$translate.instant('insight.when.Energy Star')} ${date}`;
                    targetModel.whenTooltip = this.$translate.instant('insight.when_tooltip.Energy Star');
                }
                targetModel.color = this.getColor(score.score, target, null, null);
                targetModel.formattedAnswer = `${score.score}`;
                targetModel.icon = this.getIcon(score.score, target, null, null);
            } else {
                targetModel.color = this.getColor(null, target, null, null);
                targetModel.formattedAnswer = this.$translate.instant('insight.answer.No score');
            }
            targetModel.title = name;
            targetModel.showDonut = false;
            if (target) {
                targetModel.showTarget = true;
                targetModel.formattedTarget = `${this.$translate.instant('insight.target.Target')} ${target}`;
            }
            targetModel.iconColor = '#20BBED'; // @energyStarBlue
            targetModel.widgetIcon = 'aq-icons-energy-star--glyph';

            return targetModel;
        }

    }

    angular.module('energyInsights').service('EnergyStarInsightService', EnergyStarInsightService);
}
