namespace aq.home {
    export class EnrollmentCtrl {
        /* ngInject */
        constructor(
            private enrollmentId,
            private account,
            private projectLead,
            private $state: ng.ui.IStateService,
            private $scope,
            private loading,
            private RestangularV3,
            private Messages: aq.services.Messages,
        ) {
            if (this.isCurrentUserProjectLead(this.projectLead)) {
                this.loading.start();
                this.RestangularV3.one('enrollments', this.enrollmentId).one('step').customGET().then((res) => {
                    if (res != null) {
                        this.$state.go('aq.enrollment.project-lead');
                    } else {
                        this.Messages.info('Enrollment already completed');
                        this.$state.go('aq.dashboard.configurable', { accountId: this.account.id });
                    }
                });
            } else {
                this.$state.go('aq.dashboard.configurable', { accountId: this.account.id });
            }
        }
        private isCurrentUserProjectLead(projectLead) {
            return projectLead && this.$scope.user.id == projectLead.id;
        }
    }
    angular
        .module('home')
        .controller('EnrollmentCtrl', EnrollmentCtrl);
}
