'use strict';
/**
 * Application start point.
 * 
 * Note, we use minifyer, so all dependencies should be explicitly defined with ['<dependency>',
 * function(<dependency>) {}];
 */
angular.module('charting',
        [ 'templates-app', 'restangular', 'aq.messages',
          'aq.filters.formatters', 'aq.filters.units', 'aq.ui.loading', 'aq.tooltip', 'aq.services.user',
          'aq.filters.transformers', 'aq.services.options'])
        .config(
         function(RestangularProvider) {
            RestangularProvider.setBaseUrl('/api/v1');
            RestangularProvider.setExtraFields(["name"]);
            
            RestangularProvider.setOnElemRestangularized(function(elem, isCollection, route) {
                if (isCollection) {
                    _.each(['metrics', 'data', 'csv'], function(method) {
                        elem.addRestangularMethod(method, 'getList', method);
                    });
                    
                }
                return elem;
            })
            
        });
