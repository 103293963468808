namespace aq.utilityBudgets {

    export class UtilityServiceDetails {
        public currentNavItem: string;
        private entityActions: aq.common.models.EntityMenuAction[];
        private backAction: aq.components.BackButtonData;
        private utilityAccountForStatus: UtilityAccount;
        private budgetAccess: common.models.AppAccessObject;
        private isAqAdmin;

        /* @ngInject */
        constructor(
            private utilityAccount: UtilityAccount,
            private utilityService: UtilityService,
            private utilityServiceBillPeriods: aq.utilityBudgets.UtilityBillPeriod[],
            private utilityCompanies: aq.admin.configuration.utilityCompany.UtilityCompany[],
            private Messages: aq.services.Messages,
            private building: aq.common.models.Building,
            private Errors,
            private $mdDialog: ng.material.IDialogService,
            private $state: ng.ui.IStateService,
            private authAccess: common.models.AuthAppAccess,
            private UserService,
            private Auth: aq.services.Auth,
        ) {
            this.budgetAccess = this.authAccess['Budgets'];
            this.isAqAdmin = this.UserService.currentUser.userType === 'ADMINISTRATOR';
            if (!this.utilityService) {
                this.$state.go('aq.utilityBudgets.utilityaccounts', {}, { reload: true });
            }
            if(utilityServiceBillPeriods.length == 0){
                this.entityActions = [];
            }
            else{
                this.entityActions = [
                    { label: 'Export utility bills as CSV', cb: () => this.exportBillCSV(), icon: 'file_download' }
                ];
            }
            this.currentNavItem = 'info';
            if (this.$state.current.name.endsWith('.bills')) {
                this.currentNavItem = 'bills';
            }
            if (this.$state.current.name.endsWith('.meters')) {
                this.currentNavItem = 'meters';
            }

            const backData = {
                updatedServiceId: this.utilityService.id,
                timeStamp: moment().valueOf()
            };

            this.backAction = {
                state: 'aq.utilityBudgets.utilityaccounts',
                stateData: backData,
                isDataSetOnSaveOnly: true
            };

            this.utilityAccountForStatus = angular.copy(this.utilityAccount);
        };

        public save(utilityService) {
            if (this.utilityService.startDate) {
                this.utilityService.startDate = moment(this.utilityService.startDate).toISOString();
            }

            if (this.utilityService.endDate) {
                this.utilityService.endDate = moment(this.utilityService.endDate).toISOString();
            }
            return this.utilityService.put().then(() => {
                this.Messages.success('Utility Service has been updated.');
            }, this.Errors.forCRUD('UPDATE'));
        }

        public exportBillCSV() {
            try {
                if (this.utilityServiceBillPeriods != null) {
                    const csvContent = this.getInvoiceCSVData();
                    const fileName = `${this.building.name} ${this.utilityService.type} Billing History ${(moment().format('MM/DD/YYYY'))}.csv`;
                    const link = document.createElement('a');
                    link.setAttribute('href', encodeURI(csvContent));
                    link.setAttribute('download', fileName);
                    link.click();
                } else {
                    setTimeout(() => { this.exportBillCSV(); }, 1000);
                }
            } catch (e) {
                this.Messages.error('Something went wrong downloading the utility bill history!');
            }
        }

        private getInvoiceCSVData(): string {
            let csvContent = 'data:text/csv;charset=utf-8,';
            csvContent += 'invoice date, start date, end date, total charge, consumption, consumption charge, demand, demand charge, taxes , other charge, adjustment charge, late fee charge, total cost \n';
            const fields = ['invoiceDate', 'startDate', 'endDate', 'charge', 'usage', 'usageCharge', 'demand', 'demandCharge', 'taxCharge', 'otherCharge', 'adjustmentCharge', 'lateFeeCharge', 'cost'];
            this.utilityServiceBillPeriods.forEach((index) => {
                if (index.startDate instanceof Date) {
                    var momentObj = moment(index.startDate);
                    index.startDate = momentObj;
                } else if (typeof index.startDate === 'string') {
                    var momentObj = moment(index.startDate, 'YYYY-MM-DD');
                    index.startDate = momentObj;
                }
            });
            this.utilityServiceBillPeriods.sort((a, b) => ((a.startDate < b.startDate) ? 1 : -1));
            this.utilityServiceBillPeriods.forEach((index) => {
                const row = new Array(fields.length);
                for (const key in index) {
                    if (index.hasOwnProperty(key) && fields.includes(key)) {
                        if (`${index[key]}` == 'null') {
                            if (key == 'invoiceDate' || key == 'startDate' || key == 'endDate') {
                                row[fields.indexOf(key)] = 'n/a';
                            } else {
                                row[fields.indexOf(key)] = '0';
                            }
                        } else if (index[key] instanceof moment) {
                            row[fields.indexOf(key)] = `${index[key].format('YYYY-MM-DD')}`;
                        } else {
                            row[fields.indexOf(key)] = `${index[key]}`;
                        }
                    }
                }
                csvContent += `${row}\n`;
            });
            return csvContent;
        }

        public delete(service) {
            const confirm = this.$mdDialog
                .confirm()
                .title('Are you sure you want to delete this Utility Service?')
                .ok('Confirm')
                .cancel('Cancel');

            return this.$mdDialog
                .show(confirm)
                .then(() => {
                    service.remove().then(() => {
                        this.Messages.info('Utility Service deleted');
                        this.$state.go('aq.utilityBudgets.utilityaccounts', { timeStamp: new Date() });
                    });
                })
                .catch(() => {
                    this.Errors.forCRUD('DELETE');
                });
        }

        private getCompanyName(id: number) {
            const item = _.find(this.utilityCompanies, (uc) => uc.id == id);
            return item ? item.name : null;
        }
    }

    angular.module('aq.utilityBudgets').controller('UtilityServiceDetails', UtilityServiceDetails);
}
