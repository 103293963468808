namespace aq.utilityBudgets {
    export class UtilityAccountStatusComponent {
        private utilityAccount: aq.utilityBudgets.UtilityAccount;
        private status: UtilityAccountStatusViewModel;
        private utilityCompanyName: string;
        private companyName: string;
        private accountNumber: string;

        /* @ngInject */
        constructor(
            private UtilityServiceHelper: UtilityServiceHelper,
            private $mdDialog: ng.material.IDialogService
        ) {
        }

        $onInit() {
            this.setViewModel();
        }

        $onChanges() {
            this.setViewModel();
        }

        private showConnectionHelp($event) {
            this.$mdDialog.show(
                this.$mdDialog.alert()
                    .clickOutsideToClose(true)
                    .title(this.status.description)
                    .htmlContent(this.status.helpText)
                    .ariaLabel(`${this.status.description} description`)
                    .ok('Got it!')
                    .targetEvent($event));
        }

        private setViewModel() {
            this.status = this.UtilityServiceHelper.getStatusView(this.utilityAccount.status);
            this.companyName = this.utilityCompanyName;
            this.accountNumber = this.utilityAccount.accountNumber;
        }

    }

    angular
        .module('aq.utilityBudgets')
        .component('utilityAccountStatus', {
            controller: UtilityAccountStatusComponent,
            controllerAs: 'vm',
            templateUrl: 'app/utilityBudgets/utilityAccounts/utilityAccountStatus.html',
            bindings: {
                utilityAccount: '<',
                utilityCompanyName: '<'
            }
        });
}
