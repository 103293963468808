'use strict';
angular.module('aq.ui').directive('collectorStatus', function () {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            status: '=',
            lastReportedTime: '='
        },
        template: '<span><i id="type-indicator"' +
                    'ng-if="collector.type != \'MANUAL\'"' +
                    'class="{{ getIcon(status) }}"' +
                    'data-animation="am-fade-and-scale"' +
                    'data-placement="{{ tooltip.placement }}"' +
                    'bs-tooltip="tooltip">' +
                '</i></span>',
        controller: function ($scope, $attrs, $timeout, $filter, $translate) {

            var placement = $attrs.placement || 'left';
            $scope.tooltip = { title: '', html: true, placement: placement };

            $scope.getIcon = function (status) {
                if (!status) return;
                $scope.tooltip.title = getTooltip(status);
                switch (status) {
                    case 'WAITING':
                        return 'aq-icons-collector-connecting blue';
                    case 'OFFLINE':
                        return 'aq-icons-meter-down red';
                    case 'NEVER_REPORTED':
                        return 'aq-icons-never-reported red';
                    case 'ONLINE':
                        return 'aq-icons-collector-connected green';
                }
            }

            var getTooltip = function (status) {
                switch (status) {
                    case 'WAITING':
                        return $translate.instant('Waiting for Data');
                    case 'NEVER_REPORTED':
                        return $translate.instant('Never Reported');
                    case 'OFFLINE':
                        var formatted = $filter('formatDate')($scope.lastReportedTime, 'llll');
                        return $translate.instant('Last Reported:') + '\n' + formatted;
                    case 'ONLINE':
                        return $translate.instant('Receiving Data');
                }
            }

        }
    }
});