angular.module('aq.filters.units')
    .filter('toUnit', ['$filter', function ($filter) {
        return function (input, unit, format) {
            if (_.isNull(input) || _.isUndefined(input)) {
                return null;
            }
            var converted = input * unit.conversionMultiplier;
            // FIXME: do this proper after demo
            if (unit.value === 'ENV_FAHRENHEIT' || unit.value === 'F') {
                converted += 32;
            }
            if (format) {
                converted = $filter('aqnumber')(converted);
            }
            return converted;
        };
    }]);
