var aq;
(function (aq) {
    var utilityBudgets;
    (function (utilityBudgets) {
        var ManageUtilityMeters = /** @class */ (function (_super) {
            __extends(ManageUtilityMeters, _super);
            /* @ngInject */
            function ManageUtilityMeters($mdDialog, accountId, buildingId, utilityAccount, utilityService, utilityMeters, urjanetMeters, intervalMeters, collectors, Messages, RestangularV3, $q) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.accountId = accountId;
                _this.buildingId = buildingId;
                _this.utilityAccount = utilityAccount;
                _this.utilityService = utilityService;
                _this.utilityMeters = utilityMeters;
                _this.urjanetMeters = urjanetMeters;
                _this.intervalMeters = intervalMeters;
                _this.collectors = collectors;
                _this.Messages = Messages;
                _this.RestangularV3 = RestangularV3;
                _this.$q = $q;
                _this.utilityMetersToDelete = [];
                _this.inProgress = false;
                _this.utilityMetersView = _this.initUtilityMetersView(_this.utilityMeters, _this.urjanetMeters, _this.intervalMeters);
                var urjanetMetersIdsInView = _this.utilityMetersView.map(function (x) { return x.utilityProviderMeterId; });
                var intervalIdsInView = _this.utilityMetersView.map(function (x) { return x.intervalMeter ? x.intervalMeter.id : null; }).filter(function (meter) {
                    return meter != null;
                });
                _this.urjanetMetersAvailable = _this.getUrjanetMetersNotInUse(urjanetMetersIdsInView);
                _this.intervalMetersAvailable = _this.getIntervalMetersNotInUse(intervalIdsInView);
                _this.urjanetMetersUnavailable = _this.getUrjanetMetersInUse(urjanetMetersIdsInView);
                _this.intervalMetersUnavailable = _this.getIntervalMetersInUse(intervalIdsInView);
                return _this;
            }
            ManageUtilityMeters.prototype.isIntervalSupported = function () {
                return !['STEAM', 'HEAT'].includes(this.utilityService.type);
            };
            ManageUtilityMeters.prototype.save = function () {
                var _this = this;
                if (this.inProgress) {
                    return;
                }
                this.inProgress = true;
                var requestMap = this.getUtilityMeterRequestMap(this.utilityMetersView, this.utilityMetersToDelete);
                this.doUtilityMeterBulkRequest(requestMap)
                    .then(function () {
                    return _this.RestangularV3.all('utility-statements')
                        .customPOST({}, 'update-charges-for-service', { utilityServiceId: _this.utilityService.id });
                })
                    .then(function () {
                    return _this.RestangularV3.all('utility-meters')
                        .getList({ utilityServiceId: _this.utilityService.id, ts: moment().valueOf() });
                })
                    .then(function (utilityMeters) {
                    _this.Messages.success('Utility meters successfully saved');
                    _this.hide(utilityMeters);
                })
                    .catch(function (e) {
                    console.error(e);
                    _this.Messages.error('Error when saving utility meters. Please try again.');
                })
                    .finally(function () {
                    _this.inProgress = false;
                });
            };
            ManageUtilityMeters.prototype.getUtilityMeterRequestMap = function (utilityMeters, utilityMetersToDelete) {
                var _this = this;
                var addList = [];
                var updateList = [];
                var deleteList = utilityMetersToDelete.slice();
                _.each(utilityMeters, function (utilityMeter) {
                    if (utilityMeter.intervalMeter && !utilityMeter.intervalMeter.measure) {
                        utilityMeter.intervalMeter.measure = _this.utilityService.type.toUpperCase();
                    }
                    if (utilityMeter.collector) {
                        if (utilityMeter.id) {
                            updateList.push(utilityMeter);
                        }
                        else {
                            addList.push(utilityMeter);
                        }
                    }
                    else if (utilityMeter.id && utilityMeter.utilityProviderMeterId) {
                        // Delete existing UtilityMeters that have gone from matched to unmatched
                        deleteList.push(utilityMeter);
                    }
                });
                return {
                    add: addList,
                    update: updateList,
                    delete: deleteList
                };
            };
            ManageUtilityMeters.prototype.doUtilityMeterBulkRequest = function (requestMap) {
                var _this = this;
                var addPromises = _.map(requestMap.add, function (utilityMeter) { return _this.updateOrCreateUtilityMeter(utilityMeter); });
                var updatePromises = _.map(requestMap.update, function (utilityMeter) { return _this.updateOrCreateUtilityMeter(utilityMeter); });
                var deletePromises = _.map(requestMap.delete, function (utilityMeter) { return _this.deleteUtilityMeter(utilityMeter); });
                return this.$q.all(addPromises.concat(updatePromises, deletePromises));
            };
            ManageUtilityMeters.prototype.shouldShowAutoIngestionFields = function () {
                return this.utilityAccount.username
                    && this.utilityAccount.utilityUrl
                    && this.urjanetMeters.length > 0;
            };
            ManageUtilityMeters.prototype.isIntervalSelectedInAnotherMeter = function (currentUtilityMeter, currentIntervalMeter) {
                return _.some(this.utilityMetersView, function (utilityMeterView) {
                    return utilityMeterView.intervalMeter
                        && utilityMeterView.intervalMeter.smartMeterNumber === currentIntervalMeter.smartMeterNumber
                        // and this isnt the one that we are looking at currently
                        && currentUtilityMeter.intervalMeter
                        && currentUtilityMeter.intervalMeter.smartMeterNumber !== currentIntervalMeter.smartMeterNumber;
                });
            };
            // is aquicoremeterID already assigned an interval meter
            ManageUtilityMeters.prototype.isAquicoreMeterAlreadyAssignedIntervalMeter = function (currentUtilityMeter, index) {
                return _.some(this.utilityMetersView, function (utilityMeterView, loopIndex) {
                    return utilityMeterView.collector === currentUtilityMeter.collector
                        // make sure we're not talking about the row we're currently on
                        && loopIndex !== index
                        && utilityMeterView.intervalMeter !== null;
                });
            };
            ManageUtilityMeters.prototype.deleteUtilityMeterAction = function (utilityMeter) {
                if (utilityMeter.id) {
                    var deleted = _.remove(this.utilityMetersView, { id: utilityMeter.id });
                    this.utilityMetersToDelete = this.utilityMetersToDelete.concat(deleted);
                }
                else {
                    _.remove(this.utilityMetersView, { collector: utilityMeter.collector });
                }
            };
            ManageUtilityMeters.prototype.getCollectorDisplay = function (utilityMeter) {
                var collector = _.find(this.collectors, { id: utilityMeter.collector });
                if (collector) {
                    return collector.name;
                }
                else {
                    return 'Unmatched';
                }
            };
            ManageUtilityMeters.prototype.getUrjanetMeterDisplay = function (utilityMeter) {
                var urjanetMeter = _.find(this.urjanetMeters, { id: utilityMeter.utilityProviderMeterId });
                if (urjanetMeter) {
                    return urjanetMeter.meterNumber;
                }
                else {
                    return 'Unmatched';
                }
            };
            ManageUtilityMeters.prototype.getUrjanetMetersNotInUse = function (urjanetMetersIdsInView) {
                var value = this.urjanetMeters.filter(function (urjanetMeter) {
                    return !(urjanetMetersIdsInView.indexOf(urjanetMeter.id) >= 0);
                });
                return value;
            };
            ManageUtilityMeters.prototype.getUrjanetMetersInUse = function (urjanetMetersIdsInView) {
                var value = this.urjanetMeters.filter(function (urjanetMeter) {
                    return (urjanetMetersIdsInView.indexOf(urjanetMeter.id) >= 0);
                });
                return value;
            };
            ManageUtilityMeters.prototype.getIntervalMetersNotInUse = function (intervalIdsInView) {
                var value = this.intervalMeters.filter(function (intervalMeter) {
                    return !(intervalIdsInView.indexOf(intervalMeter.id) >= 0);
                });
                return value;
            };
            ManageUtilityMeters.prototype.getIntervalMetersInUse = function (intervalIdsInView) {
                var value = this.intervalMeters.filter(function (intervalMeter) {
                    return (intervalIdsInView.indexOf(intervalMeter.id) >= 0);
                });
                return value;
            };
            ManageUtilityMeters.prototype.addBlankMeter = function (urjanetMeter) {
                var newUtilityMeter = {
                    collector: null,
                    utilityProviderMeterId: null,
                    intervalMeter: null
                };
                this.utilityMetersView.push(newUtilityMeter);
                this.selectedBillMeter = null;
                this.billMeterSearchText = null;
            };
            ManageUtilityMeters.prototype.isCollectorFromCurrentBuilding = function (utilityMeter) {
                if (utilityMeter.collector) {
                    return !!_.find(this.collectors, { id: utilityMeter.collector });
                }
                else {
                    return true;
                }
            };
            ManageUtilityMeters.prototype.initUtilityMetersView = function (utilityMeters, urjanetMeters, intervalMeters) {
                var meters = [];
                // show our urjanet meters first
                urjanetMeters.forEach(function (urjanetMeter) {
                    var meter = _.find(utilityMeters, { utilityProviderMeterId: String(urjanetMeter.id) });
                    if (meter) {
                        meter.intervalMeter = null;
                        // check if we have an interval meter on it
                        if (intervalMeters && intervalMeters.length > 0) {
                            var matchByUtility = _.find(intervalMeters, function (intervalMeter) {
                                // matches AQ meter, bring it on
                                return meter.deviceId === intervalMeter.aquicoreMeterID;
                            });
                            if (matchByUtility !== void 0) {
                                meter.intervalMeter = matchByUtility;
                            }
                        }
                        meters.push(meter);
                    }
                    else {
                        var intervalMeter = null;
                        // If it doesn't exist, create a dummy record to show as 'Unmatched'
                        if (intervalMeters && intervalMeters.length > 0) {
                            var matchByUrjanetId = _.find(intervalMeters, function (intervalMeterObject) {
                                // matches AQ meter, bring it on
                                return urjanetMeter.id === intervalMeterObject.billMeterNumber;
                            });
                            if (matchByUrjanetId !== void 0) {
                                intervalMeter = matchByUrjanetId;
                            }
                        }
                        intervalMeter !== null && meters.push({
                            collector: null,
                            utilityProviderMeterId: String(urjanetMeter.id),
                            intervalMeter: intervalMeter
                        });
                    }
                });
                // then show our unmatched meters
                var unmatchedMeters = utilityMeters.filter(function (utilityMeter) {
                    return !meters.some(function (meter) { return meter.id === utilityMeter.id; });
                })
                    .map(function (utilityMeter) {
                    utilityMeter.intervalMeter = intervalMeters.find(function (intervalMeter) {
                        var aqMeterId = intervalMeter.aquicoreMeterID;
                        return aqMeterId ? utilityMeter.deviceId === aqMeterId : false;
                    });
                    if (!utilityMeter.intervalMeter) {
                        // This is here so that the UI will correctly display "Unmatched" if there is no interval meter matched to this meter
                        // arr.find() returns undefined and breaks this behaviour
                        utilityMeter.intervalMeter = null;
                    }
                    return utilityMeter;
                });
                return meters.concat(unmatchedMeters);
            };
            ManageUtilityMeters.prototype.deleteUtilityMeter = function (utilityMeter) {
                if (utilityMeter.id) {
                    return this.RestangularV3.one('utility-meters', utilityMeter.id).customDELETE('', { deviceId: utilityMeter.deviceId });
                }
                return null;
            };
            ManageUtilityMeters.prototype.updateOrCreateUtilityMeter = function (utilityMeter) {
                if (utilityMeter.id) {
                    return this.RestangularV3.one('utility-meters', utilityMeter.id)
                        .customPUT(utilityMeter);
                }
                else {
                    return this.RestangularV3.all('utility-meters')
                        .post(utilityMeter, { utilityServiceId: this.utilityService.id });
                }
            };
            return ManageUtilityMeters;
        }(aq.common.Controllers.ModalCtrl));
        utilityBudgets.ManageUtilityMeters = ManageUtilityMeters;
        angular.module('aq.utilityBudgets').controller('ManageUtilityMeters', ManageUtilityMeters);
    })(utilityBudgets = aq.utilityBudgets || (aq.utilityBudgets = {}));
})(aq || (aq = {}));
