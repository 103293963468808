namespace aq.filters {

    export function numForm() {
        return function(input, decimalPlaces, forceTrailingZeros = false){
            if (input) {
                const output = forceTrailingZeros ?
                    input.toLocaleString(moment.locale(), {minimumFractionDigits : decimalPlaces, maximumFractionDigits : decimalPlaces}) :
                    input.toLocaleString(moment.locale(), {maximumFractionDigits : decimalPlaces});
                return output;
            }
            return input;
        };
    };

    angular.module('aq.filters').filter('numForm', numForm);
}
