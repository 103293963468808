namespace aq.energyInsights {
    angular.module('energyInsights').directive('aqQueryPeriodSelector', () => {
        return {
            restrict: 'E',
            transclude: true,
            replace: true,
            scope: {
                periodBlackList: '<',
                periodWhiteList: '<',
                intervalWhiteList: '<',
                intervalBlackList: '<',
                onChange: '&',
                defaultPeriod: '<',
                disableIntervalSelection: '<',
                defaultIntervals: '<',
                timeZoneId: '<'
            },
            templateUrl: 'app/energyInsights/directives/queryPeriodSelector/queryPeriodSelector.html',
            controller: ($scope, intervalService) => {
                $scope.availablePeriods = [];
                _.keys(aq.common.TimeConfiguration).forEach((type) => {
                    _.keys(aq.common.TimeConfiguration[type]).forEach((period) => {
                        const label = `${type} ${period}`;
                        if ($scope.periodWhiteList && !matchesRegex($scope.periodWhiteList, label)) {
                            return;
                        } else if ($scope.periodBlackList && matchesRegex($scope.periodBlackList, label)) {
                            return;
                        }
                        const periodObj = {
                            label: `${type} ${period}`,
                            value: aq.common.TimeConfiguration[type][period]
                        };
                        if (periodObj.label === $scope.defaultPeriod) {
                            $scope.selectedPeriod = periodObj;
                            if ($scope.defaultIntervals) {
                                $scope.selectedInterval = pickDefaultInterval($scope.defaultIntervals, $scope.selectedPeriod);
                            }
                        }
                        $scope.availablePeriods.push(periodObj);
                    });
                });

                $scope.onPeriodChange = (pickInterval) => {
                    const startDate = $scope.selectedPeriod.value.start($scope.timeZoneId);
                    const endDate = $scope.selectedPeriod.value.end($scope.timeZoneId);
                    if (pickInterval) {
                        $scope.selectedInterval = pickDefaultInterval($scope.defaultIntervals, $scope.selectedPeriod);
                    }
                    $scope.onChange({startDate, endDate, interval: $scope.selectedInterval, label: $scope.selectedPeriod.label});
                };

                $scope.onIntervalChange = (interval) => {
                    const startDate = $scope.selectedPeriod.value.start($scope.timeZoneId);
                    const endDate = $scope.selectedPeriod.value.end($scope.timeZoneId);
                    $scope.onChange({startDate, endDate, interval, label: $scope.selectedPeriod.label});
                };

                // -----------------------

                function pickDefaultInterval(defaultIntervals, selectedPeriod) {
                    const match = matchesRegex(Object.keys(defaultIntervals), selectedPeriod.label);
                    return _.find(selectedPeriod.value.intervals,
                            {value: defaultIntervals[match]}) || selectedPeriod.value.intervals[0];
                }

                function matchesRegex(listOfRegexes: string[], value: string) {
                    return _.find(listOfRegexes, (regexString) => {
                        const regexp = new RegExp(regexString);
                        return value.match(regexp);
                    });
                }
            }
        };
    });
    export interface PeriodSearch {
        start?: moment.Moment;
        end?: moment.Moment;
        interval?: any;
        additionalIntervals?: any;
        intervalOverrides?: any;
        label?: any;
        defaultPeriod?: string;
        timeZoneId?: string;
    }
}
