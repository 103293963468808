angular.module('aq.tooltip', []).factory('TooltipService', ['$window', function($window) {
    
    // Tooltip creation
    var $tip = $('<div />').attr('id', 'singletonTooltip');
    $tip.appendTo('body').hide().css({position: 'absolute'});
    $tip.css({
        'background': '#fff',
        'z-index': '100',
        'padding': '0.4em 0.6em',
        'border-radius': '0.5em',
        'font-size': '0.8em',
        'border': '1px solid #111'
    });
    
    var service: any = {};
    
    service.hide = function() {
        $tip.hide();
    }
    
    service.show = function(x, y, content) {
        var windowHeight = $($window).height() + $($window).scrollTop();
        var top = y + 10;
        if (windowHeight - y <= 100) {
            top = y - 100;
        }

        $tip.html( content )
        .css({
            left: x + 20,
            top: top
        })
        .show();
    }
    
    return service;
    
}]);
