angular
    .module('aq.reports')
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.reports.projectStatus', {
                url: '/summary/projectMonthlyStatus',
                controller: 'ProjectStatusCtrl',
                controllerAs: 'vm',
                templateUrl: 'app/reports/summary/projectStatus/projectStatus.html',
                resolve: {
                    options(OptionsService, account): any {
                        return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
                    },
                    metrics(Restangular, account: aq.common.models.Account, waitForAuthToken): ng.IPromise<any> {
                        return Restangular.one('accounts', account.id).customGET('queryRealUnits');
                    },
                    buildings(waitForAuthToken, RestangularV3: restangular.IService): ng.IPromise<any> {
                        return RestangularV3.all('buildings').getList().then(buildings => {
                            return buildings.filter(b => b.status == 'ACTIVE' && b.archived == false);
                        });
                    },
                    users(
                        Restangular,
                        DataStore: aq.common.DataStore,
                        account,
                        waitForAuthToken
                    ): ng.IPromise<any> {
                        return DataStore.getList(Restangular.one('accounts', account.id), 'users', {});
                    },
                    startDate($location: ng.ILocationService): moment.Moment {
                        moment.locale('en-us');
                        let date: moment.Moment = null;
                        if ($location.search().startDate) {
                            date = moment(parseInt($location.search().startDate));
                        } else {
                            date = moment().subtract(1, 'month');
                        }
                        return date.startOf('month');
                    },
                    statuses(
                        ProjectServiceV3: aq.services.ProjectServiceV3,
                        buildings: aq.common.models.Building[],
                        waitForAuthToken
                    ) {
                        if (buildings.length === 0) {
                            return [];
                        }
                        const buildingId = parseInt(_.first(buildings).id);
                        return ProjectServiceV3.getProjects(buildingId).then(projectResponse => {
                            return projectResponse.statuses;
                        });
                    },
                    completedProjectsTimeInProgressMap(
                        ProjectServiceV3: aq.services.ProjectServiceV3,
                        buildings: aq.common.models.Building[],
                        startDate: moment.Moment,
                        statuses: aq.models.projects.ProjectStatus[]
                    ) {
                        const dateFormat = 'MM/DD/YYYY';
                        const startDateFormatted = startDate.format(dateFormat);
                        const endDateFormatted = moment(startDate).add(1, 'month').format(dateFormat);
                        const completedStatusId = _.find(statuses, s => s.name === 'Completed').id;
                        const buildingIds = _.map(buildings, b => b.id);
                        const requestCompleted: aq.models.projects.ProjectStatusDetailsRequest = {
                            buildingIds,
                            statusIds: [completedStatusId],
                            startDate: startDateFormatted,
                            endDate: endDateFormatted
                        };
                        const inProgressStatusId = _.find(statuses, s => s.name === 'In Progress').id;

                        return ProjectServiceV3.getStatusDetails(requestCompleted)
                            .then((results: aq.models.projects.ProjectStatusDetails[]) => {
                                const projectTimeMap = {};
                                results.forEach(item => {
                                    projectTimeMap[item.projectId] = item.timeInStatus && item.timeInStatus[inProgressStatusId] || 0;
                                });
                                return projectTimeMap;
                            })
                    },
                    projects(
                        ProjectServiceV3: aq.services.ProjectServiceV3,
                        $q: ng.IQService,
                        buildings: aq.common.models.Building[],
                        statuses: aq.models.projects.ProjectStatus[],
                        startDate: moment.Moment,
                        users: aq.common.models.User[],
                        completedProjectsTimeInProgressMap: Record<string, number>,
                        waitForAuthToken,
                        Auth: aq.services.Auth
                    ) {
                        const pastStartDate = moment(startDate).subtract(1, 'month');
                        const Priority = aq.models.projects.ProjectPriority;
                        const priorityMap = {
                            [Priority.HIGH]: 3,
                            [Priority.MEDIUM]: 2,
                            [Priority.LOW]: 1
                        };
                        const priorityLabel = {
                            [Priority.HIGH]: 'High',
                            [Priority.MEDIUM]: 'Medium',
                            [Priority.LOW]: 'Low'
                        };
                        const selectedStatuses: aq.models.projects.ProjectStatus[] = _.filter(
                            statuses,
                            (status: aq.models.projects.ProjectStatus) => {
                                return _.includes(['Declined', 'Needs Acceptance', 'Backlog', 'In Progress', 'Completed'], status.name);
                            }
                        );
                        const getImpactDisplayItem = (lineItem: aq.models.projects.NetImpactLineItem): aq.reports.projectStatus.ImpactDisplay => {
                            const display = lineItem && lineItem.score ? Math.abs(lineItem.score).toString() : '';
                            let cssClass = null;
                            if (lineItem && lineItem.score) {
                                cssClass = lineItem.score > 0 ? 'dark-green' : 'red';
                            }
                            return {
                                display,
                                cssClass
                            };
                        };
                        const results: ng.IPromise<aq.reports.projectStatus.BuildingsProjects>[] = _.map(buildings, (building: aq.common.models.Building) => {
                            const buildingId = parseInt(building.id);
                            return ProjectServiceV3.getProjects(buildingId).then(projectResponse => {
                                const projects = projectResponse.projects.filter((project: aq.models.projects.Project) => {
                                    return _.find(selectedStatuses, (status: aq.models.projects.ProjectStatus) => status.id == project.status.id);
                                });
                                const projectItems = projects
                                    .map((p: aq.models.projects.Project): aq.reports.projectStatus.BuildingsProjectsItem => {
                                        const assignee: aq.common.models.User = _.find(users, (user) => user.id == p.assignee);
                                        const assigneeName = p.assignee
                                            ? (assignee && `${assignee.firstName} ${assignee.lastName}`) || 'Unknown'
                                            : '-';
                                        const impactLineItems = p.impact && p.impact.impactLineItems || [];
                                        const reliabilityLineItem = impactLineItems.find(item => item.impactType == aq.models.projects.ImpactType.RELIABILITY);
                                        const comfortLineItem = impactLineItems.find(item => item.impactType == aq.models.projects.ImpactType.COMFORT);
                                        const safetyLineItem = impactLineItems.find(item => item.impactType == aq.models.projects.ImpactType.SAFETY);
                                        const isProjectEstimationEnabled = Auth.hasFunctionality(PROJECT_ESTIMATION);
                                        const estimatedSavings = ProjectServiceV3.getImpactValue(p, isProjectEstimationEnabled, true, 'USD').projectImpact;
                                        const estimatedSavingsEnergy = ProjectServiceV3.getImpactValue(p, isProjectEstimationEnabled, true, 'KWH').projectImpact;
                                        const project: aq.reports.projectStatus.BuildingsProjectsItem = {
                                            code: p.code,
                                            projectId: p.id,
                                            name: p.title,
                                            priorityOrder: priorityMap[p.priority],
                                            priorityLabel: priorityLabel[p.priority],
                                            status: p.status.name === 'Needs Acceptance' ? 'Needs Acc.' : p.status.name,
                                            startDate: p.issue && p.issue.identifiedDate && moment(p.issue.identifiedDate).format('MM/DD/YYYY') || null,
                                            dateCreated: moment(p.createdOn).format('MM/DD/YYYY'),
                                            dateCompleted: p.completedOn ? moment(p.completedOn).format('MM/DD/YYYY') : '',
                                            estimatedCost: p.cost && p.cost.totalCost || 0,
                                            estimatedSavings,
                                            estimatedSavingsEnergy,
                                            suffix: '',
                                            isNew: moment(p.createdOn).isSame(startDate, 'month'),
                                            isPastNew: moment(p.createdOn).isSame(pastStartDate, 'month'),
                                            isCompleted: p.status.name == 'Completed',
                                            isInProgress: p.status.name === 'In Progress',
                                            assignee: assigneeName,
                                            impact: {
                                                reliability: getImpactDisplayItem(reliabilityLineItem),
                                                comfort: getImpactDisplayItem(comfortLineItem),
                                                safety: getImpactDisplayItem(safetyLineItem)
                                            },
                                            daysInProgress: completedProjectsTimeInProgressMap[p.id] > 0
                                                ? Math.ceil(completedProjectsTimeInProgressMap[p.id] / 86400000)
                                                : 0
                                        };
                                        if (p.projectType == aq.models.projects.ProjectType.IMPROVEMENT) {
                                            project.suffix = '/yr';
                                        }
                                        return project;
                                    });
                                const buildingProjects: aq.reports.projectStatus.BuildingsProjects = {
                                    building: buildingId,
                                    projectItems: projectItems
                                }
                                return buildingProjects;
                            });
                        });

                        return $q.all(results).then((responses: aq.reports.projectStatus.BuildingsProjects[]) => {
                            const buildingProjectsMap = {};
                            _.each(responses, (response: aq.reports.projectStatus.BuildingsProjects) => {
                                buildingProjectsMap[response.building] = response.projectItems;
                            });
                            return buildingProjectsMap;
                        });
                    },
                    newProjectsSummaryData(
                        projects: Record<number, aq.reports.projectStatus.BuildingsProjects[]>
                    ) {
                        type BPI = aq.reports.projectStatus.BuildingsProjectsItem;
                        type PSD = aq.models.projects.ProjectStatusDetails;

                        const newItems = [];
                        const pastNewItems = [];
                        Object.keys(projects).forEach(key => {
                            const projectItems: BPI[] = projects[key];
                            newItems.push(..._.filter(projectItems, (p) => p.isNew));
                            pastNewItems.push(..._.filter(projectItems, (p) => p.isPastNew));
                        });

                        const mapBuildingsProjectsItemToProjectStatusDetails = (item: BPI): PSD => {
                            const mappedItem: PSD = {
                                buildingId: null,
                                currentStatusId: null,
                                dollarSavingsMax: item.estimatedSavings,
                                energySavingsMax: item.estimatedSavingsEnergy,
                                projectId: item.projectId,
                                timeInStatus: null
                            };
                            return mappedItem;
                        };

                        return {
                            current: newItems.map(item => mapBuildingsProjectsItemToProjectStatusDetails(item)),
                            trailing: pastNewItems.map(item => mapBuildingsProjectsItemToProjectStatusDetails(item))
                        };
                    },
                    completedProjectsSummaryData(
                        ProjectServiceV3: aq.services.ProjectServiceV3,
                        buildings: aq.common.models.Building[],
                        startDate: moment.Moment,
                        statuses: aq.models.projects.ProjectStatus[]
                    ) {
                        const dateFormat = 'MM/DD/YYYY';
                        const startDateFormatted = startDate.format(dateFormat);
                        const endDateFormatted = moment(startDate).add(1, 'month').format(dateFormat);
                        const trailingStartDate = moment(startDate).subtract(1, 'month').format(dateFormat);
                        const trailingEndDate = startDateFormatted;
                        const buildingIds = _.map(buildings, b => b.id);

                        const completedStatusId = _.find(statuses, s => s.name === 'Completed').id;

                        const requestCompleted: aq.models.projects.ProjectStatusDetailsRequest = {
                            buildingIds,
                            statusIds: [completedStatusId],
                            startDate: startDateFormatted,
                            endDate: endDateFormatted
                        };
                        const requestCompletedTrailing: aq.models.projects.ProjectStatusDetailsRequest = {
                            buildingIds,
                            statusIds: [completedStatusId],
                            startDate: trailingStartDate,
                            endDate: trailingEndDate
                        };

                        type PSD = aq.models.projects.ProjectStatusDetails;
                        const results: Promise<PSD[]>[] = [];
                        results.push(ProjectServiceV3.getStatusDetails(requestCompleted));
                        results.push(ProjectServiceV3.getStatusDetails(requestCompletedTrailing));

                        return Promise.all(results).then(([resultCompleted, resultCompletedTrailing]) => {
                            return {
                                current: resultCompleted,
                                trailing: resultCompletedTrailing
                            };
                        });
                    },
                    inProgressProjectsSummaryData(
                        ProjectServiceV3: aq.services.ProjectServiceV3,
                        buildings: aq.common.models.Building[],
                        startDate: moment.Moment,
                        statuses: aq.models.projects.ProjectStatus[]
                    ) {
                        const dateFormat = 'MM/DD/YYYY';
                        const startDateFormatted = startDate.format(dateFormat);
                        const endDateFormatted = moment(startDate).add(1, 'month').format(dateFormat);
                        const trailingEndDate = startDateFormatted;
                        const buildingIds = _.map(buildings, b => b.id);

                        const inProgressStatusId = _.find(statuses, s => s.name === 'In Progress').id;

                        const requestInProgress: aq.models.projects.ProjectStatusDetailsRequest = {
                            buildingIds,
                            statusIds: [inProgressStatusId],
                            startDate: null,
                            endDate: endDateFormatted
                        };
                        const requestInProgressTrailing: aq.models.projects.ProjectStatusDetailsRequest = {
                            buildingIds,
                            statusIds: [inProgressStatusId],
                            startDate: null,
                            endDate: trailingEndDate
                        };

                        type PSD = aq.models.projects.ProjectStatusDetails;
                        const results: Promise<PSD[]>[] = [];
                        results.push(ProjectServiceV3.getStatusDetails(requestInProgress));
                        results.push(ProjectServiceV3.getStatusDetails(requestInProgressTrailing));

                        return Promise.all(results).then(([resultInProgress, resultInProgressTrailing]) => {
                            return {
                                current: resultInProgress,
                                trailing: resultInProgressTrailing
                            };
                        });
                    }
                }
            })
    });
