namespace aq.components {
    export class PrimarySideNav {
        private showBuildingSelector;
        /* @ngInject */
        constructor(private $mdMedia) { }

    }

    angular
        .module('aq.ui')
        .component('primarySideNav', {
            templateUrl: 'app/common/components/PrimarySideNav/PrimarySideNav.html',
            controller: PrimarySideNav,
            controllerAs: 'vm',
            bindings: {
                showBuildingSelector: '<',
                account: '<?',
                buildings: '<?'
            },
            transclude: {
                'primarySideNavContent': '?primarySideNavContent'
            }
        });
};
