namespace aq.models.projects {

    export enum ProjectPriority {
        LOW = 'LOW',
        MEDIUM = 'MEDIUM',
        HIGH = 'HIGH'
    }

    export enum ProjectType {
        CORRECTION = 'CORRECTION',
        IMPROVEMENT = 'IMPROVEMENT'
    }

    export enum IdentificationSource {
        CUSTOMER = 'CUSTOMER',
        AQUICORE = 'AQUICORE'
    }

    export enum IdentificationMethod {
        OPTIMIZATION = 'OPTIMIZATION',
        MANUAL = 'MANUAL'
    }

    export enum BudgetType {
        OPEX = 'OPEX',
        CAPEX = 'CAPEX'
    }

    export enum CostType {
        LABOR = 'LABOR',
        REBATE = 'REBATE',
        PARTS = 'PARTS'
    }

    export enum ImpactType {
        UTILITY = 'UTILITY',
        COMFORT = 'COMFORT',
        SAFETY = 'SAFETY',
        RELIABILITY = 'RELIABILITY'
    }

    export enum GresbProjectCategories {
        ENERGY = 'Energy',
        WATER = 'Water',
        WASTE = 'Waste',
        OTHER = 'Other',
        TECHNICAL_BUILDING_ASSESSMENT = 'Technical Building Assessment',
        TECHNICAL_BUILDING_ASSESSMENT_WATER = 'Technical Building Assessment - Water',
        TECHNICAL_BUILDING_ASSESSMENT_WASTE = 'Technical Building Assessment - Waste',
        AUTOMATIC_METER_READINGS = 'Automatic Meter Readings (AMR)',
        AUTOMATIC_SYSTEM_UPGRADES_REPLACEMENTS = 'Automatic System Upgrades/Replacements',
        MANAGEMENT_SYSTEM_UPGRADES_REPLACEMENTS = 'Management System Upgrades/Replacements',
        INSTALL_HIGH_EFFICIENCY_EQUIPMENT= 'Installation of High-Efficiency Equipment And Appliances',
        INSTALLATION_OF_ONSITE_RENEWABLEENERGY = 'Installation of On-Site Renewable Energy',
        OCCUPIER_ENGAGEMENT_IT = 'Occupier Engagement/Informational Technologies',
        SMARTGRID_SMARTBUILDING_TECHNOLOGIES = 'Smart Grid/Smart Building Technologies',
        SYSTEMS_COMMISSIONING_OR_RETROCOMMISSIONING = 'Systems Commissioning or Retro Commissioning',
        WALL_ROOF_INSULATION = 'Wall/Roof Insulation',
        WINDOW_REPLACEMENTS = 'Window Replacements',
        COOLING_TOWER = 'Cooling Tower',
        DRIP_SMART_IRRIGATION = 'Drip/Smart Irrigation',
        DROUGHT_TOLERANT_NATIVELANDSCAPING = 'Drought Tolerant/Native Landscaping',
        HIGH_EFFICIENCY_DRYFIXTURES = 'High Efficiency/Dry Fixtures',
        LEAK_DETECTIONSYSTEM = 'Leak Detection System',
        METERING_OF_WATER_SUBSYSTEMS = 'Metering of Water Subsystems',
        ON_SITE_WASTE_WATER_TREATMENT = 'On Site Waste Water Treatment',
        REUSE_OF_STORMWATER_AND_OR_GREYWATER = 'Reuse of Storm Water and/or Grey Water',
        COMPOSTING_LANDSCAPE_AND_OR_FOOD_WASTE = 'Composting Landscape and/or Food Waste',
        ONGOING_WASTE_PERFORMANCE_MONITORING = 'Ongoing Waste Performance Monitoring',
        RECYCLING = 'Recycling',
        WASTE_MANAGEMENT = 'Waste Management',
        WASTE_STREAM_AUDIT = 'Waste Stream Audit'
    }

    export class ProjectStatus {
        id: string;
        name: string;
    }

    export class ImplementationCostLineItem {
        public id: string;
        public budgetType?: BudgetType;
        public costType?: CostType;
        public amount?: number;

    }

    export class NetImplementationCost {
        public id: string;
        public totalCost: number;
        public implementationCostLineItems?: ImplementationCostLineItem[];
    }

    export class NetImpactLineItem {
        public impactType?: ImpactType;
        public estimatedSavingsAmountMin?: number;
        public estimatedSavingsAmountMax?: number;
        public actualSavingsAmount?: number;
        public estimatedSavingsAmountEnergyMin?: number;
        public estimatedSavingsAmountEnergyMax?: number;
        public actualSavingsAmountEnergy?: number;
        public score?: number;
    }

    export class NetImpact {
        public id: string;
        public totalEstimatedSavingsAmountMin?: number;
        public totalEstimatedSavingsAmountMax?: number;
        public totalEstimatedSavingsAmountEnergyMin?: number;
        public totalEstimatedSavingsAmountEnergyMax?: number;
        public totalActualSavingsAmount?: number;
        public totalActualSavingsAmountEnergy?: number;
        public impactLineItems?: NetImpactLineItem[];
    }

    export class Issue {
        public id: string;
        public identifiedDate?: string;
        public potentialEndDate?: string;
        public startDate?: string;
        public causes?: string[];
        public diagnosis?: string;
        public recommendations?: string[];
    }

    export class ProjectSystemType {
        id: string;
        name: string;
    }

    export class ExportProject {
        public accountName: string;
        public buildingName: string;
        public projectType?: ProjectType | string;
        public gresbProjectCategories?: GresbProjectCategories | string;
        public title: string;
        public statusName: ProjectStatus | string;
        public priority: ProjectPriority | string;
        public issueDiagnosis?: string;
        public issueCause?: string;
        public issueCause2?: string;
        public issueCause3?: string;
        public issueRecommendation?: string;
        public issueRecommendation2?: string;
        public issueRecommendation3?: string;
        public extraNotes?: string;
        public systemTypeName?: ProjectSystemType | string;
        public issueStartDate?: string;
        public issueIdentifiedDate?: string;
        public issuePotentialEndDate?: string;
        public netImpactLineItemType?: ImpactType | string;
        public netImpactLineItemType2?: ImpactType | string;
        public netImpactLineItemType3?: ImpactType | string;
        public netImpactLineItemScore?: number | string;
        public netImpactLineItemScore2?: number | string;
        public netImpactLineItemScore3?: number | string;
        public netImpactLineItemEstimatedSavingsAmountMin?: number | string;
        public netImpactLineItemEstimatedSavingsAmountMax?: number | string;
        public netImpactLineItemActualSavings?: number | string;
        public netImpactLineItemEstimatedSavingsAmountEnergyMin?: number | string;
        public netImpactLineItemEstimatedSavingsAmountEnergyMax?: number | string;
        public netImpactLineItemActualSavingsAmountEnergy?: number | string;
        public netImplementationCostBudgetType?: BudgetType | string;
        public netImplementationCostAmount?: number | string;
        public netImplementationCostCostType?: CostType | string;
        public identificationSource?: IdentificationSource | string;
        public identificationMethod?: IdentificationMethod | string;
        public assignee?: string;
    }

    export class Project {
        public id: string;
        public code: string;
        public title: string;
        public extraNotes: string;
        public index: number;
        public status: ProjectStatus;
        public reporter: number;
        public assignee: number;
        public startedOn: string;
        public completedOn: string;
        public createdOn: string;
        public updatedOn: string;
        public priority: ProjectPriority;
        public projectType: ProjectType;
        public gresbProjectCategories: GresbProjectCategories;
        public buildingId: number;
        public devices: number[];
        public primaryFileId: string;
        public cost: NetImplementationCost;
        public impact: NetImpact;
        public issue: Issue;
        public systemTypes?: ProjectSystemType[];
        public identificationSource: IdentificationSource;
        public identificationMethod?: IdentificationMethod;
    }

    export class ProjectResponse {
        projects: Project[];
        statuses: ProjectStatus[];
        systemTypes: ProjectSystemType[];
    }

    export class HeaderResponse {
        [key: string]: string | number | boolean
    }

    export interface ProjectStatusDetailsRequest {
        startDate: string;
        endDate: string;
        statusIds: string[];
        buildingIds: string[];
    }

    export interface ProjectStatusDetails {
        projectId: string;
        buildingId: number;
        dollarSavingsMax: number;
        energySavingsMax: number;
        currentStatusId: string;
        timeInStatus: Record<string, number>;
    }
}
