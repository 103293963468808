var aq;
(function (aq) {
    var utilityBudgets;
    (function (utilityBudgets) {
        var MonthBudgetCtrl = /** @class */ (function () {
            /* ngInject */
            function MonthBudgetCtrl(account, building, budgetMonth, budgetYear, budgetMeasure, monthBudget, activities, buildingSpending, temperature, temperatureUnit, currencyUnitSymbol, $timeout, $filter, GraphEditService, $state, measures, $location, $translate, $interval, $scope, measureUnitMap, Auth) {
                var _this = this;
                this.account = account;
                this.building = building;
                this.budgetMonth = budgetMonth;
                this.budgetYear = budgetYear;
                this.budgetMeasure = budgetMeasure;
                this.monthBudget = monthBudget;
                this.activities = activities;
                this.buildingSpending = buildingSpending;
                this.temperature = temperature;
                this.temperatureUnit = temperatureUnit;
                this.currencyUnitSymbol = currencyUnitSymbol;
                this.$timeout = $timeout;
                this.$filter = $filter;
                this.GraphEditService = GraphEditService;
                this.$state = $state;
                this.measures = measures;
                this.$location = $location;
                this.$translate = $translate;
                this.$interval = $interval;
                this.$scope = $scope;
                this.measureUnitMap = measureUnitMap;
                this.Auth = Auth;
                this.MAX_ACTIVITIES_TO_SHOW = 1;
                this.init();
                this.initConstantSeries();
                this.buildBreakdownChart();
                this.buildTableData();
                this.$timeout(function () { return _this.chart && _this.chart.reflow(); }, 200);
            }
            MonthBudgetCtrl.prototype.init = function () {
                var _this = this;
                this.periodType = 'month';
                this.initBlendedRate();
                this.colors = Highcharts.getOptions().colors;
                this.initAvailableMonths();
                this.totalDays = moment(this.budgetYear + " " + this.budgetMonth, 'YYYY M').daysInMonth();
                this.isCurrentMonth = moment().format('YYYY') == this.budgetYear && moment().format('M') == this.budgetMonth;
                this.selectedType = this.budgetMeasure;
                this.today = this.buildingSpending.lastAvailableFullDataTimestamp !== null ? parseInt(moment(this.buildingSpending.lastAvailableFullDataTimestamp).format('D')) : this.totalDays;
                this.isNoProjectionDataVisible = this.isNoProjectionData();
                this.now = moment().valueOf();
                this.$scope.$on('$destroy', function () {
                    if (_this.interval) {
                        _this.$interval.cancel(_this.interval);
                    }
                });
                this.interval = this.$interval(function () {
                    _this.now = moment().valueOf();
                }, 60000);
                // TMP
                var queryParams = this.$location.search();
                if (this.isCurrentMonth && queryParams.testForDay) {
                    this.today = parseInt(queryParams.testForDay);
                }
                this.monthBudgetFormatted = this.$filter('currency')(this.monthBudget, this.currencyUnitSymbol, 0);
            };
            MonthBudgetCtrl.prototype.initBlendedRate = function () {
                this.customBlendedRate = _.first(this.buildingSpending.spending.data).datum.blendedRate;
            };
            MonthBudgetCtrl.prototype.initAvailableMonths = function () {
                var _this = this;
                this.availableBudgetMonths = [];
                var monthCounter = 24;
                var date = moment();
                while (monthCounter > 0) {
                    this.availableBudgetMonths.push({
                        name: date.format('MMMM YYYY'),
                        year: date.format('YYYY'),
                        month: date.format('M')
                    });
                    date = date.subtract(1, 'month');
                    monthCounter--;
                }
                this.selectedMonthPeriod = _.find(this.availableBudgetMonths, function (item) {
                    return item.year == _this.budgetYear && item.month == _this.budgetMonth;
                });
                if (!this.selectedMonthPeriod) {
                    var currentDate = moment(this.budgetMonth + " " + this.budgetYear, 'M YYYY');
                    this.selectedMonthPeriod = {
                        name: currentDate.format('MMMM YYYY'),
                        year: this.budgetYear,
                        month: this.budgetMonth
                    };
                    this.availableBudgetMonths[this.availableBudgetMonths.length - 1] = this.selectedMonthPeriod;
                }
            };
            MonthBudgetCtrl.prototype.recalculateProjection = function () {
                this.$state.go(this.$state.current.name, {
                    blendedRate: this.customBlendedRate
                });
            };
            MonthBudgetCtrl.prototype.onChangePeriod = function () {
                this.$state.go(this.$state.current.name, {
                    year: this.selectedMonthPeriod.year,
                    month: this.selectedMonthPeriod.month,
                    blendedRate: null
                });
            };
            MonthBudgetCtrl.prototype.onChangeType = function () {
                this.$state.go(this.$state.current.name, {
                    measure: this.selectedType,
                    blendedRate: null
                });
            };
            MonthBudgetCtrl.prototype.goToYearlyView = function () {
                this.isPeriodTypeDisabled = true;
                var fiscalMonth = this.building.fiscalStartMonth || 1;
                var year = parseInt(this.budgetYear);
                if (parseInt(this.budgetMonth) < fiscalMonth) {
                    year -= 1;
                }
                this.$state.go('aq.utilityBudgets.year', {
                    measure: this.selectedType,
                    year: year
                });
            };
            MonthBudgetCtrl.prototype.initConstantSeries = function () {
                this.temperatureSeries = this.getTemperatureSeries(this.temperature);
                this.temperatureForecastSeries = this.getTemperatureForecastSeries(this.temperature);
            };
            MonthBudgetCtrl.prototype.getTotalProjection = function () {
                var total = 0;
                _.each(this.buildingSpending.spending.data, function (item) {
                    if (item.datum.projected) {
                        total += item.datum.projected;
                    }
                    if (item.datum.utilityBill) {
                        total += item.datum.utilityBill;
                    }
                    if (item.datum.consumption) {
                        total += item.datum.consumption;
                    }
                });
                return total;
            };
            MonthBudgetCtrl.prototype.getSpendDiffPercent = function () {
                if (this.monthBudget > 0) {
                    var total = this.getTotalProjection();
                    var diff_1 = Math.abs(this.monthBudget - total);
                    return (diff_1 / this.monthBudget * 100).toFixed(1);
                }
                return null;
            };
            MonthBudgetCtrl.prototype.getTotalProjectionPercent = function () {
                var percent = this.getTotalProjection() * 1000 / this.monthBudget;
                return (Math.round(percent) / 10).toFixed(1) + "%";
            };
            MonthBudgetCtrl.prototype.getTotalProjectionFormatted = function () {
                var total = this.getTotalProjection();
                return this.$filter('currency')(total, this.currencyUnitSymbol, 0);
            };
            MonthBudgetCtrl.prototype.resetProjection = function () {
                this.$state.go(this.$state.current.name, {
                    blendedRate: null
                });
            };
            MonthBudgetCtrl.prototype.buildTableData = function () {
                var _this = this;
                this.tableData = [];
                var now = moment();
                _.each(this.buildingSpending.spending.data, function (utilitySpendingDataItem, index) {
                    var date = moment(utilitySpendingDataItem.timestamp);
                    var charge = (utilitySpendingDataItem.datum.utilityBill || 0)
                        + (utilitySpendingDataItem.datum.consumption || 0)
                        + (utilitySpendingDataItem.datum.projected || 0);
                    var type = null;
                    if (charge > 0 && !utilitySpendingDataItem.datum.utilityBill) {
                        type = utilitySpendingDataItem.datum.projected > 0 ? 'projection' : 'consumption';
                    }
                    var degreeValue, temperatureType;
                    if (date.isAfter(now)) {
                        var temp = _.isNil(_this.temperature.values[index]) ?
                            _this.temperature.climateNormals[index] : _this.temperature.values[index];
                        degreeValue = _this.getTemperatureValue(temp);
                        temperatureType = 'forecast';
                    }
                    else {
                        degreeValue = _this.getTemperatureValue(_this.temperature.values[index]);
                        temperatureType = 'historical';
                    }
                    var activities = _this.getActivities(moment(utilitySpendingDataItem.timestamp), moment(utilitySpendingDataItem.timestamp).add(1, 'day').subtract(1, 'second'));
                    // Only show activities list if our list is small enough. Too many and it makes our table uggo
                    var showActivities = activities.length <= _this.MAX_ACTIVITIES_TO_SHOW;
                    _this.tableData.push({
                        date: moment(utilitySpendingDataItem.timestamp).add(12, 'hour').format('dddd, MMM D'),
                        temperature: degreeValue != null ? degreeValue + " " + _this.temperatureUnit.unit : '-',
                        temperatureType: temperatureType,
                        timestamp: utilitySpendingDataItem.timestamp,
                        activities: activities,
                        showActivities: showActivities,
                        charge: _this.$filter('currency')(charge, _this.currencyUnitSymbol, 0),
                        type: type
                    });
                });
            };
            MonthBudgetCtrl.prototype.toggleActivities = function (item) {
                item.showActivities = !item.showActivities;
            };
            MonthBudgetCtrl.prototype.getActivitiesRemainingCount = function (activities) {
                return activities.length - this.MAX_ACTIVITIES_TO_SHOW;
            };
            MonthBudgetCtrl.prototype.isNoProjectionData = function () {
                if (!this.isCurrentMonth) {
                    return false;
                }
                if (this.today >= this.totalDays) {
                    return false;
                }
                return _.every(this.buildingSpending.spending.data, function (item) { return !item.datum.projected; });
            };
            // Utility Account Charges
            MonthBudgetCtrl.prototype.buildBreakdownChart = function () {
                var series = [];
                var seriesTotals = [];
                var billTotalsData = [];
                var consumptionTotalsData = [];
                var projectionTotalsData = [];
                var current = 1;
                var data = [];
                var sumCharge = 0;
                var currentDataItem = this.buildingSpending.spending.data[current - 1];
                while (current <= this.today) {
                    if (currentDataItem && currentDataItem.datum.utilityBill != null) {
                        sumCharge += currentDataItem.datum.utilityBill || 0;
                        data.push({
                            x: current,
                            y: sumCharge,
                            name: "temp-" + current
                        });
                        this.addTotalPoint(billTotalsData, current, sumCharge);
                    }
                    current++;
                    currentDataItem = this.buildingSpending.spending.data[current - 1];
                }
                var isFirst = true;
                current = 1;
                currentDataItem = this.buildingSpending.spending.data[current - 1];
                var consumptionChargeData = [];
                var sumConsumptionCharge = 0;
                while (current <= this.today) {
                    var billItem = _.find(data, function (item) { return item.x == current; });
                    if (billItem) {
                        sumConsumptionCharge = billItem.y;
                    }
                    else {
                        if (isFirst) {
                            isFirst = false;
                            var firstIndex = current - 1;
                            consumptionChargeData.push({
                                x: firstIndex,
                                y: sumConsumptionCharge
                            });
                            this.addTotalPoint(consumptionTotalsData, firstIndex, sumConsumptionCharge);
                        }
                        sumConsumptionCharge += currentDataItem.datum.consumption;
                        // Combine today's projected data with today's consumption
                        if (current == this.today) {
                            sumConsumptionCharge += currentDataItem.datum.projected || 0;
                        }
                        consumptionChargeData.push({
                            x: current,
                            y: sumConsumptionCharge,
                            name: "temp-" + current
                        });
                        this.addTotalPoint(consumptionTotalsData, current, sumConsumptionCharge);
                    }
                    current++;
                    currentDataItem = this.buildingSpending.spending.data[current - 1];
                }
                if (consumptionChargeData.length > 0) {
                    var consumptionChargeSeriesConfig = {
                        data: consumptionChargeData,
                        showInLegend: false,
                        name: this.$translate.instant('budgets.Consumption'),
                        type: 'area',
                        dashStyle: 'Dash'
                    };
                    series.push(consumptionChargeSeriesConfig);
                    series.push(this.getFakeSeriesConfigForLegend(consumptionChargeSeriesConfig, 'area'));
                }
                var dataProjected = [];
                var fixPoint = null;
                var sumProjectionCharge = sumConsumptionCharge;
                if (current <= this.totalDays) {
                    fixPoint = {
                        x: current - 1,
                        y: sumProjectionCharge
                    };
                    this.addTotalPoint(projectionTotalsData, current - 1, sumConsumptionCharge);
                    while (current <= this.totalDays) {
                        sumProjectionCharge += currentDataItem.datum.projected;
                        dataProjected.push({
                            x: current,
                            y: sumProjectionCharge,
                            name: "temp-" + current
                        });
                        this.addTotalPoint(projectionTotalsData, current, sumProjectionCharge);
                        current++;
                        currentDataItem = this.buildingSpending.spending.data[current - 1];
                    }
                    // fix rendering of continuous area charts
                    var fix = [];
                    if (fixPoint) {
                        fix.push(fixPoint);
                    }
                    var projectedSeriesConfig = {
                        data: fix,
                        showInLegend: false,
                        name: this.$translate.instant('budgets.Projection'),
                        type: 'area',
                        dashStyle: 'Dot',
                        fillOpacity: 0
                    };
                    series.push(projectedSeriesConfig);
                    series.push(this.getFakeSeriesConfigForLegend(projectedSeriesConfig, 'line'));
                }
                var total = this.getTotalProjection();
                var color = total > this.monthBudget ? utilityBudgets.seriesColors.red : utilityBudgets.seriesColors.green;
                var name = total > this.monthBudget ? 'budgets.Over Budget' : 'budgets.Under Budget';
                this.budgetTargetSeries = {
                    data: [
                        { x: -1, y: this.monthBudget },
                        { x: 32, y: this.monthBudget }
                    ],
                    name: this.$translate.instant(name),
                    type: 'line',
                    dashStyle: 'DashDot',
                    color: color
                };
                series.push(this.budgetTargetSeries);
                series.push(this.temperatureSeries);
                series.push(this.temperatureForecastSeries);
                this.runningTotal = [];
                var _loop_1 = function (i) {
                    var valueItem = _.find(billTotalsData, function (item) { return item.x == i; });
                    if (!valueItem) {
                        valueItem = _.find(consumptionTotalsData, function (item) { return item.x == i; });
                    }
                    if (!valueItem) {
                        valueItem = _.find(projectionTotalsData, function (item) { return item.x == i; });
                    }
                    this_1.runningTotal.push(valueItem ? valueItem.y : 0);
                };
                var this_1 = this;
                for (var i = 1; i <= this.totalDays; i++) {
                    _loop_1(i);
                }
                var billTotalsSeriesConfig = {
                    data: billTotalsData,
                    name: this.$translate.instant('budgets.Current Utility Expenses'),
                    type: 'area',
                    colorIndex: 0,
                    index: 0
                };
                seriesTotals.push(billTotalsSeriesConfig);
                if (consumptionTotalsData.length > 0) {
                    var consumptionTotalsSeriesConfig = {
                        data: consumptionTotalsData,
                        showInLegend: false,
                        name: this.$translate.instant('budgets.Current Consumption Expenses'),
                        type: 'area',
                        dashStyle: 'Dash',
                        lineColor: this.colors[0],
                        color: this.GraphEditService.getLighterColor(this.colors[0]),
                        index: 1
                    };
                    seriesTotals.push(consumptionTotalsSeriesConfig);
                    seriesTotals.push(this.getFakeSeriesConfigForLegend(consumptionTotalsSeriesConfig, 'area'));
                }
                if (projectionTotalsData.length > 0) {
                    var projectionTotalsSeriesConfig = {
                        data: projectionTotalsData,
                        showInLegend: false,
                        name: this.$translate.instant('budgets.Projected Expenses'),
                        type: 'area',
                        dashStyle: 'Dot',
                        colorIndex: 0,
                        index: 2,
                        fillOpacity: 0
                    };
                    seriesTotals.push(projectionTotalsSeriesConfig);
                    seriesTotals.push(this.getFakeSeriesConfigForLegend(projectionTotalsSeriesConfig, 'line'));
                }
                seriesTotals.push(this.budgetTargetSeries);
                seriesTotals.push(this.temperatureSeries);
                seriesTotals.push(this.temperatureForecastSeries);
                this.monthBudgetChartTotal = this.buildChart(seriesTotals);
            };
            MonthBudgetCtrl.prototype.getFakeSeriesConfigForLegend = function (seriesConfig, type) {
                var config = angular.copy(seriesConfig);
                config.data = [];
                if (type) {
                    config.type = type;
                }
                config.showInLegend = true;
                return config;
            };
            MonthBudgetCtrl.prototype.addTotalPoint = function (series, day, value) {
                var item = _.find(series, function (s) { return s.x == day; });
                if (!item) {
                    series.push({
                        x: day,
                        y: value,
                        name: "temp-" + day
                    });
                }
                else {
                    item.y += value;
                }
            };
            MonthBudgetCtrl.prototype.buildChart = function (series) {
                var _this = this;
                var monthFormatted = moment(this.budgetMonth, 'M').format('MMM');
                var getContext = function () { return _this; };
                return {
                    chart: {
                        height: 400,
                        animation: false
                    },
                    title: {
                        text: null
                    },
                    subtitle: {
                        text: null
                    },
                    xAxis: {
                        tickInterval: 1,
                        min: 1,
                        max: this.totalDays,
                        labels: {
                            // tslint:disable-next-line:object-literal-shorthand
                            formatter: function () {
                                var paddedDay = _.padStart(this.value, 2, '0');
                                return monthFormatted + " " + paddedDay;
                            }
                        },
                        plotLines: [{
                                color: '#0000FF',
                                width: 2,
                                value: this.today
                            }],
                        crosshair: true
                    },
                    yAxis: [{
                            title: { text: this.currencyUnitSymbol },
                            min: 0,
                            max: this.getTotalProjection() > this.monthBudget ? null : this.monthBudget
                        }, {
                            title: { text: this.temperatureUnit.unit },
                            opposite: true,
                            minTickInterval: 5
                        }],
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal',
                        itemStyle: {
                            fontSize: '11px'
                        }
                    },
                    plotOptions: {
                        column: {
                            stacking: null
                        },
                        series: {
                            animation: false,
                            marker: {
                                enabled: false
                            },
                            states: {
                                hover: {
                                    enabled: false
                                }
                            }
                        }
                    },
                    series: series,
                    navigation: {
                        buttonOptions: {
                            enabled: false
                        }
                    },
                    tooltip: {
                        // tslint:disable-next-line:object-literal-shorthand
                        formatter: function () {
                            if (this.key && typeof (this.key) == 'string' && this.key.startsWith('temp-')) {
                                var day = this.x;
                                var context = getContext();
                                var utilitySpendingDataItem = context.buildingSpending.spending.data[day - 1];
                                var utilityBillValue = utilitySpendingDataItem.datum.utilityBill || 0;
                                var consumptionValue = utilitySpendingDataItem.datum.consumption || 0;
                                var projectedValue = utilitySpendingDataItem.datum.projected || 0;
                                var billData = {
                                    value: utilityBillValue,
                                    valueFormatted: context.$filter('currency')(utilityBillValue, context.currencyUnitSymbol, 0)
                                };
                                var consumptionData = {
                                    value: consumptionValue,
                                    valueFormatted: context.$filter('currency')(consumptionValue, context.currencyUnitSymbol, 0)
                                };
                                var projectionData = {
                                    value: projectedValue,
                                    valueFormatted: context.$filter('currency')(projectedValue, context.currencyUnitSymbol, 0)
                                };
                                var date = moment(context.budgetYear + " " + context.budgetMonth + " " + day, 'YYYY M D');
                                var dayInfo = "<b>" + date.format('dddd, MMMM D') + "</b>";
                                var total = 0;
                                total += (billData.value + consumptionData.value + projectionData.value);
                                var totalContent = '';
                                var totalFormatted = context.$filter('currency')(total, context.currencyUnitSymbol, 0);
                                var color = context.colors[0];
                                var titleTranslationKey = day < context.today ? 'budgets.Today Spend' : 'budgets.Projected Spend';
                                var title = context.$translate.instant(titleTranslationKey);
                                totalContent = "<span style=\"color:" + color + ";\">\u25A0 </span><span>" + title + ": " + totalFormatted + "</span>";
                                var temperature = context.tableData[day - 1].temperature;
                                var temperatureContent = void 0;
                                if (temperature == '-') {
                                    temperatureContent = "<span style=\"color:" + utilityBudgets.seriesColors.weather + ";\">\u25A0 </span>";
                                }
                                else if (day > context.today) {
                                    temperatureContent = "<span>" + context.$translate.instant('budgets.Temperature Forecast') + ": " + temperature + "</span>";
                                }
                                else {
                                    temperatureContent = "<span>" + context.$translate.instant('budgets.Temperature') + ": " + temperature + "</span>";
                                }
                                var budgetToDate = Math.round(context.monthBudget * day / context.totalDays);
                                var runningSpend = context.runningTotal[day - 1];
                                var runningSpendFormatted = context.$filter('currency')(runningSpend, context.currencyUnitSymbol, 0);
                                var totalTranslationKey = day <= context.today ? 'budgets.Total Spend' : 'budgets.Projected Total';
                                var runningSpendContent = "<span>" + context.$translate.instant(totalTranslationKey) + ": " + runningSpendFormatted + "</span>";
                                var budgetToDatePercent = context.monthBudget > 0 ? context.runningTotal[day - 1] * 1000 / context.monthBudget : 0;
                                var budgetToDatePercentFormatted = (Math.round(budgetToDatePercent) / 10).toFixed(1) + "%";
                                var budgetToDatePercentContent = '';
                                if (budgetToDate > 0) {
                                    budgetToDatePercentContent = "<span>" + context.$translate.instant('budgets.Budget Utilization') + ": "
                                        + (budgetToDatePercentFormatted + "</span>");
                                }
                                return [
                                    dayInfo,
                                    temperatureContent,
                                    totalContent,
                                    runningSpendContent,
                                    budgetToDatePercentContent
                                ].join('<br/>');
                            }
                            return false;
                        }
                    }
                };
            };
            MonthBudgetCtrl.prototype.getTemperatureSeries = function (temperature) {
                var _this = this;
                var temperatureSeriesData = _.map(temperature.values, function (value, index) {
                    if (_this.isCurrentMonth && index >= _this.today) {
                        return null;
                    }
                    var point = {
                        x: index + 1,
                        y: _this.getTemperatureValue(value),
                        name: "temp-" + (index + 1)
                    };
                    return point;
                });
                return {
                    data: _.compact(temperatureSeriesData),
                    name: this.$translate.instant('budgets.Temperature'),
                    type: 'line',
                    color: utilityBudgets.seriesColors.weather,
                    dashStyle: 'ShortDash',
                    states: {
                        hover: {
                            enabled: true
                        }
                    },
                    yAxis: 1
                };
            };
            MonthBudgetCtrl.prototype.getTemperatureForecastSeries = function (temperature) {
                var _this = this;
                var temperatureForecastSeriesData = _.map(temperature.values, function (value, index) {
                    var temp = _.isNil(value) ? temperature.climateNormals[index] : value;
                    if (!_this.isCurrentMonth || _this.isCurrentMonth && index < _this.today - 1) {
                        return null;
                    }
                    var point = {
                        x: index + 1,
                        y: _this.getTemperatureValue(temp),
                        name: "temp-forecast-" + (index + 1)
                    };
                    return point;
                });
                return {
                    data: _.compact(temperatureForecastSeriesData),
                    name: this.$translate.instant('budgets.Temperature Forecast'),
                    type: 'line',
                    color: utilityBudgets.seriesColors.weather,
                    dashStyle: 'ShortDot',
                    states: {
                        hover: {
                            enabled: true
                        }
                    },
                    yAxis: 1
                };
            };
            MonthBudgetCtrl.prototype.getTemperatureValue = function (value) {
                if (value == null) {
                    return null;
                }
                return Math.round(this.$filter('toTemperatureUnit')(value, this.temperatureUnit));
            };
            MonthBudgetCtrl.prototype.getActivities = function (startDate, endDate) {
                var activities = this.activities.payload
                    .filter(function (activity) { return activity.context && activity.context.data && activity.context.data.selection && activity.context.data.selection.startDate; })
                    .filter(function (activity) { return moment(activity.context.data.selection.startDate).isSame(startDate, 'day'); });
                return activities;
            };
            return MonthBudgetCtrl;
        }());
        utilityBudgets.MonthBudgetCtrl = MonthBudgetCtrl;
        angular.module('aq.utilityBudgets').controller('MonthBudgetCtrl', MonthBudgetCtrl);
    })(utilityBudgets = aq.utilityBudgets || (aq.utilityBudgets = {}));
})(aq || (aq = {}));
