namespace MockHelpers {
    /**
     * converts the request parameters in a url to an object.
     * @param url 'http://my.aquicore.com/api/v3/devices/123456?someOtherThing=654321&building=1234567'
     * @returns Object {someOtherThing: "654321", building: "1234567"}
     */
    export function getParamsAsObject(url) {
        const queryString = url.split('?')[1].split('&');
        return _
            .chain(queryString)
            .map((params) => {
                const p = params.split('=');
                return [p[0], decodeURIComponent(p[1])];
            })
            .fromPairs()
            .value();
    }


    /**
     * converts the request parameters in a url to an object.
     * this helper also supports arrays in the form of &thing=1&thing=2
     * @param url
     *  'http://my.aquicore.com/api/v3/devices/123456?someOtherThing=654321&building=1234567&arrayParam=value&arrayParam=other'
     * @returns Object
     * {
     *    someOtherThing: "654321",
     *    building: "1234567",
     *    arrayParam: ['value', 'other']
     * }
     */
    export function queryStringParse(str) {
        const pairs = (str[0] === '?' ? str.substr(1) : str).split('&');
        const result = {};
        pairs.forEach((pair) => {
            pair = pair.split('=');
            const key = pair[0];
            const value = pair[1];
            if (key.length)
                if (result[key] !== void 0) {
                    if (!result[key].push) {
                        result[key] = [result[key]];
                    }
                    result[key].push(value || '');
                } else {
                    result[key] = value || '';
                }
        });
        return result;
    }

    export function parseJSON(data: string) {
        let result;
        try {
            result = JSON.parse(data);
        } catch (error) {
            console.log('error parsing data', error);
        }
        return result;
    }

    // takes a url -> http://my.aquicore.com/api/v3/accounts/123456789/devices/123456?someOtherThing=654321
    // returns the id's from that url -> ['123456789', '123456']
    export function getIds(url: string): string[] {
        const tokens = url.split('?');
        const resourcePathTokens = tokens[0].split(/\//);
        const ids = resourcePathTokens
            .filter((token) => !isNaN(parseInt(token)));
        return ids;
    }

    export function getQueryParams(url: string) {
        const queryParams = {};
        const tokens = url.split('?');
        if (tokens.length < 2) {
            return queryParams;
        }
        const queryParamTokens = tokens[1].split('&');
        queryParamTokens.forEach((token) => {
            const splitToken = token.split('=');
            const name = splitToken[0];
            const value = splitToken[1];
            if (!queryParams[name]) {
                queryParams[name] = [];
            }
            queryParams[name].push(value);
        });
        return queryParams;
    }

    const adjectives = [
        'adamant', 'adroit', 'amatory', 'animistic', 'antic', 'arcadian', 'baleful', 'bellicose', 'bilious', 'boorish',
        'calamitous', 'caustic', 'cerulean', 'comely', 'concomitant', 'contumacious', 'corpulent', 'crapulous',
        'defamatory', 'didactic', 'dilatory', 'dowdy', 'efficacious', 'effulgent', 'egregious', 'endemic',
        'equanimous', 'execrable', 'fastidious', 'feckless', 'fecund', 'friable', 'fulsome', 'garrulous', 'guileless',
        'gustatory', 'heuristic', 'histrionic', 'hubristic', 'incendiary', 'insidious', 'insolent', 'intransigent',
        'inveterate', 'invidious', 'irksome', 'jejune', 'jocular', 'judicious', 'lachrymose', 'limpid', 'loquacious',
        'luminous', 'mannered', 'mendacious', 'meretricious', 'minatory', 'mordant', 'munificent', 'nefarious',
        'noxious', 'obtuse', 'parsimonious', 'pendulous', 'pernicious', 'pervasive', 'petulant', 'platitudinous',
        'precipitate', 'propitious', 'puckish', 'querulous', 'quiescent', 'rebarbative', 'recalcitant', 'redolent',
        'rhadamanthine', 'risible', 'ruminative', 'sagacious', 'salubrious', 'sartorial', 'sclerotic', 'serpentine',
        'spasmodic', 'strident', 'taciturn', 'tenacious', 'tremulous', 'trenchant', 'turbulent', 'turgid',
        'ubiquitous', 'uxorious', 'verdant', 'voluble', 'voracious', 'wheedling', 'withering', 'zealous'
    ];
    const nouns = [
        'ninja', 'chair', 'pancake', 'statue', 'unicorn', 'rainbows', 'laser', 'senor', 'bunny', 'captain',
        'nibblets', 'cupcake', 'carrot', 'gnomes', 'glitter', 'potato', 'salad', 'toejam', 'curtains', 'beets',
        'toilet', 'exorcism', 'stick figures', 'mermaid eggs', 'sea barnacles', 'dragons', 'jellybeans', 'snakes',
        'dolls', 'bushes', 'cookies', 'apples', 'ice cream', 'ukulele', 'kazoo', 'banjo', 'opera singer', 'circus',
        'trampoline', 'carousel', 'carnival', 'locomotive', 'hot air balloon', 'praying mantis', 'animator', 'artisan',
        'artist', 'colorist', 'inker', 'coppersmith', 'director', 'designer', 'flatter', 'stylist', 'leadman', 'limner',
        'make-up artist', 'model', 'musician', 'penciller', 'producer', 'scenographer', 'set decorator', 'silversmith',
        'teacher', 'auto mechanic', 'beader', 'bobbin boy', 'clerk of the chapel', 'filling station attendant', 'foreman',
        'maintenance engineering', 'mechanic', 'miller', 'moldmaker', 'panel beater', 'patternmaker', 'plant operator',
        'plumber', 'sawfiler', 'shop foreman', 'soaper', 'stationary engineer', 'wheelwright', 'woodworkers'
    ];

    export const randomString = (strings: string[]): any => strings[_.random(0, strings.length - 1 )];

    export const getRandomName = (): string => {
        return `${randomString(adjectives)} ${randomString(nouns)}`;
    };


}
