angular.module('tenantBilling').filter('formatRate', ($filter) => {
    return (charge, currencyUnit, isShowDecimals = true) => {
        const isNegative = charge < 0;
        if (!isShowDecimals) {
            charge = Math.round(charge);
        }
        const absCharge = Math.abs(charge);

        const symbol = currencyUnit ? currencyUnit.symbol : '';

        let decimals = 0;
        if (isShowDecimals) {
            // convert number to string but prevent it being displayed in exponential format
            // toFixed(12) because we only store up to 12 decimal places in the db
            const splitNum = charge.toFixed(12).replace(/0+$/g, '').split('.');
            if (splitNum.length > 1) {
                decimals = Math.max(splitNum[1].length, 2);
            }
        }

        const formattedRate = $filter('currency')(absCharge, symbol, decimals);
        if (isNegative) {
            return `(${formattedRate})`;
        }
        return formattedRate;
    };
});
