/**
 * This service is set of common functions that are used for handling parent source
 * selection when creating or editing some source
 */
angular.module('properties.sources').service('ParentSourceHandler', function (Messages) {

    var service: any = {};

    /**
     * Return selected parent circuit on which child source is assigned to.
     * Object is manually built so it can display load name properly in dropdown: "Load X"
     * @return {Object.<id, string>}
     */
    service.selectedCircuit = function(source) {

        if (!source.parentSource) return;

        return _.chain(source.parentSource.circuits).filter(function(circuit) { 
                if (!circuit.resource) return;
                return circuit.resource.id == source.id 
            })
            .map(function (item) {
                return {
                    id: item.id,
                    name: "Load " + item.number
                }
            })
            .first()
            .value();
    }

    /**
     * Return all circuits from selected parent source
     * Array of objects are manually built so they can display load names properly in dropdown: "Load X"
     * @return {Array.<Object.<id, string>}
     */
    service.circuits = function(circuits, showAll) {

        return _.chain(circuits)
            .filter(function (item) {
                // show only load with no resource connected
                return item.type == 'LOAD' && ( showAll ? true : !item.resource );
            })
            .map(function (item) {
                if (item) {
                    return {
                        id: item.id,
                        name: "Load " + item.number
                    }
                }
            })
            .value();
    }

    /**
     * Call this function to attach child source to the parent circuit
     * @param {Object} circuitToAttach - selected circuit on parent source, this object is generated by
     *                                   circuits function in this service
     * @param {Object} source - child source
     */
    service.attachToParent = function(source, circuitToAttach) {
        return source.customPUT(null, 'attachToParent', {parentCircuitId: circuitToAttach.id}).catch(function() {
            Messages.error('Error while adding child relationship, contact administrator.');
        });
    }

    /**
     * Call this function to detach child source from the parent
     * @param {Object} source - child source
     */
    service.detachFromParent = function(source, mapped) {
        return source.customPUT(null, 'detachFromParent', { mapped: mapped }).catch(function() {
            Messages.error('Error while detaching child relationship, contact administrator.');
        });
    }

    return service;

});
