'use strict';

angular
    .module('aq.admin',
        [
            'aq.admin.accounts',
            'aq.admin.configuration',
            'aq.admin.dashboards',
            'aq.admin.dataViewer',
            'aq.admin.meters',
            'aq.admin.modal',
            'aq.admin.notifications',
            'aq.admin.platformSettings',
            'aq.admin.productDesign',
            'aq.admin.reports',
            'aq.admin.statistics',
            'aq.calendar',
            'aq.filereader',
            'aq.linechart',
            'aq.mousetrap',
            'aq.services.filepicker',
            'aq.sortable',
            'aq.ui',
            'aq.ui.confirmation',
            'aq.ui.elemSelector',
            'aq.ui.highChart',
            'aq.ui.image',
            'aq.ui.listSelector',
            'aq.ui.loading',
            'aq.ui.docraptor',
            'infinite-scroll',
            'infinite-scroll-pager',
            'restangular',
            'ui.router',
            'ui.select2',
            'aq.services.user',
            'ngStorage'
        ])
    .config(($stateProvider, $urlRouterProvider, RestangularProvider) => {
        $stateProvider
            .state('aq.admin', {
                url: '/admin',
                templateUrl: 'app/admin/main.html',
                data: {
                    appName: 'Admin'
                },
                resolve: {
                    checkIsAdmin(initUser, $state) {
                        const isAdmin = initUser && initUser.userType == 'ADMINISTRATOR';
                        if (!isAdmin) {
                            $state.go('aq.accessDeniedState');
                        }
                    }
                }
            })
            .state('aq.accessDeniedState', {
                templateUrl: 'app/admin/accessDenied.html'
            })
            .state('aq.admin.home', {
                url: '/home',
                templateUrl: 'app/admin/home.html'
            })
            .state('aq.admin.users', {
                url: '/users',
                templateUrl: 'app/admin/users/users.html',
                controller: 'AdminUsersCtrl as users',
                data: {
                    appName: 'Users'
                },
                resolve: {
                    users: (Restangular, waitForAuthToken) => {
                        return Restangular.one('users').get({
                            page: 1,
                            orderBy: 'userName',
                            asc: true
                        });
                    },
                    supportTeamRoles(waitForAuthToken, DataStore: aq.common.DataStore, RestangularV3: restangular.IElement) {
                        return RestangularV3.one('users').one('support-team-roles').get().then((result) => {
                            return result.originalElement;
                        });
                    },
                    personas(waitForAuthToken, DataStore: aq.common.DataStore, RestangularV3: restangular.IElement) {
                        return RestangularV3.one('users').one('personas').get().then((result) => {
                            return result.originalElement;
                        });
                    },
                }
            });

        RestangularProvider.setRequestInterceptor((elem) => {
            if (elem && elem.errors) {
                delete elem.errors;
            }
            return elem;
        });

        RestangularProvider.addResponseInterceptor(function (response) {
            var newResponse = response;
            if (angular.isArray(response)) {
                angular.forEach(newResponse, function (value, key) {
                    if (angular.isObject(newResponse[key])) {
                        newResponse[key].originalElement = angular.copy(value);
                    }
                });
            } else if (angular.isObject(response)) {
                newResponse.originalElement = angular.copy(response);
            }
            return newResponse;
        });

    });
