namespace aq {
    import UserActions = aq.models.segment.UserActions;
    export class InvitationAcceptCtrl {

        /* @ngInject */
        constructor(private $state, private $scope, private $window) {
            $scope.accountName = $state.params['accountName'];
        }

        public goHome() {
            this.$window.location = '/';
        }
    }
    angular
        .module('aq')
        .controller('InvitationAcceptCtrl', InvitationAcceptCtrl);
}