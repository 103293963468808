namespace aq.admin.configuration.utilityCompany {
    import TariffSchedule = aq.admin.configuration.utilityCompany.TariffSchedule;
    export class UtilityCompanyDetailsCtrl {

        private meterConnectivityTypes;
        private measureToIcon: {[key: string]: string} = {};
        private query;
        private selectedProvider;
        private savedProvider;

        /* @ngInject */
        constructor(
            private $scope: UtilityCompanyDetailsCtrlScope,
            private Messages,
            private utilityAccounts: aq.utilityBudgets.UtilityAccount[],
            private utilityCompany: UtilityCompanyElement,
            private RestangularV3: restangular.IService,
            public tariffSchedules: TariffSchedule[],
            private measures: aq.common.models.Measure[],
            private $state: ng.ui.IStateService,
            private $mdDialog: ng.material.IDialogService
        ) {
            this.meterConnectivityTypes = Object.keys(MeterConnectivityTypes);
            this.measures.forEach((measure) => {
                this.measureToIcon[measure.id] = this.getMeasureIcon(measure.id);
            });
            this.selectedProvider = this.utilityCompany.urjanetProviderDetails;
            this.savedProvider = this.selectedProvider;
        }

        save() {
            this.utilityCompany.save().then((utilityCompany) => {
                this.savedProvider = utilityCompany.urjanetProviderDetails;
                this.Messages.info('Utility Company Saved');
                this.$scope.$emit('UtilityCompanySaved', utilityCompany);
            }, () => {
                this.Messages.info('Error saving Utility Company');
            });
        }

        editTariffSchedule(tariffSchedule) {
            this.$state.go('.tariffSchedule', {tariffId: tariffSchedule.id});
        }

        deleteTariffSchedule(tariffSchedule: TariffScheduleElement) {
            this.RestangularV3.one('energy-tariff-schedules', tariffSchedule.id)
                .remove()
                .then( () => {
                    this.Messages.success('Successfully deleted tariff schedule');
                    _.remove(this.tariffSchedules, {id: tariffSchedule.id});
                })
                .catch(() => {
                    this.Messages.error('Unable to delete tariff schedule');
                });
        }

        createTariffSchedule() {
            this.$mdDialog.show({
                controller: 'TariffScheduleNewCtrl as vm',
                templateUrl: 'app/admin/configuration/utilityCompany/tariffSchedule/new/tariffScheduleNew.html',
                clickOutsideToClose: true,
                locals: {
                    measures: this.measures,
                    newTariffSchedule: {utilityCompany: this.utilityCompany.id, name: '', code: ''}
                }
            }).then((newTariffSchedule: TariffSchedule) => {
                this.Messages.success('Tariff "' + newTariffSchedule.name + '" Created');
                this.tariffSchedules.push(newTariffSchedule);
                this.editTariffSchedule(newTariffSchedule);
            }).catch((error) => {
                this.Messages.error('Tariff Schedule already exists.');
                console.error(error);
            });
        }

        public hasSavedProvider() {
            return _.isEqual(this.savedProvider, this.utilityCompany.urjanetProviderDetails);
        }

        public setQuery(query: string) {
            this.query = query;
        }

        public getMeterConnectivityTypeDisplay(meterConnectivityType) {
            return MeterConnectivityTypes[meterConnectivityType];
        }

        public getMeasureIcon(measureId): string {
            const foundMeasure = _.findById(this.measures, measureId);
            if (foundMeasure) {
                return 'aq-icons-' + foundMeasure.name.toString().toLowerCase();
            }
            return null;
        }

        public getProviderText(provider: UrjanetProvider) {
            if (provider) {
                return `${provider.providerName} (${provider.providerAlias})`;
            }
            return '';
        }

        public searchProviders(search: string) {
            return this.RestangularV3.all('internal')
                .all('urjanet-providers')
                .getList({ search });
        }

        public selectUrjanetProvider(provider: UrjanetProvider) {
            if (provider) {
                this.utilityCompany.urjanetProviderId = provider.id;
                this.utilityCompany.urjanetProviderName = provider.providerName;
                provider = this.RestangularV3.stripRestangular(provider);
                delete (provider as any).originalElement;
                this.utilityCompany.urjanetProviderDetails = provider;
            } else if (this.query === '') {
                this.utilityCompany.urjanetProviderId = null;
                this.utilityCompany.urjanetProviderName = null;
                this.utilityCompany.urjanetProviderDetails = null;
            }
        }

        public isValidProvider() {
            if (this.utilityCompany.urjanetProviderDetails) {
                return this.query === this.getProviderText(this.utilityCompany.urjanetProviderDetails);
            } else {
                return !this.query;
            }
        }

        public showRecreateEnrollmentsModal() {
            this.$mdDialog.show({
                controller: 'RecreateEnrollmentsCtrl as vm',
                templateUrl: 'app/admin/configuration/utilityCompany/details/actions/recreateEnrollments/recreateEnrollments.html',
                clickOutsideToClose: true,
                locals: {
                    utilityAccounts: this.utilityAccounts
                }
            }).then((accounts) => {
                if (accounts && accounts.length > 0) {
                    return this.RestangularV3.all('internal')
                        .all('urjanet-requests')
                        .all('accounts')
                        .customPOST({ utilityAccounts: accounts }, 'recreate')
                        .then(() => {
                            this.Messages.info('Recreating the selected accounts. Urjanet may take a few days to register them.');
                        })
                        .catch(() => {
                            this.Messages.error('Error while trying to recreate accounts. Please contact support.');
                        });
                }
            });
        }
    }

    export enum MeterConnectivityTypes {
        PULSE = 'Pulse',
        WIRELESS_SEP = 'Wireless SEP',
        NOT_AVAILABLE = 'Not Available',
        UNKNOWN = 'Unknown'
    }

    export interface UtilityCompanyDetailsCtrlScope extends UtilityCompanyListCtrlScope {
        utilityCompany: UtilityCompanyElement;
        tariffSchedules: TariffSchedule[];
    }
}
angular
    .module('utilityCompany')
    .controller('UtilityCompanyDetailsCtrl', aq.admin.configuration.utilityCompany.UtilityCompanyDetailsCtrl);
