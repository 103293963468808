namespace aq.services {

    import ActivityContextType = aq.models.activity.ActivityContextType;
    import ActivityListResponse = aq.models.activity.ActivityListResponse;
    import ActivityQueryParams = aq.models.activity.ActivityQueryParams;
    import ActivityResponse = aq.models.activity.ActivityResponse;
    import SimpleActivityResponse = aq.models.activity.SimpleActivityResponse;

    export class ActivityService {

        /* @ngInject */
        constructor(
            private $http: ng.IHttpService,
            private resolver: aq.services.Resolver,
            private Messages: aq.services.Messages,
            private AQ_API_URL: string,
            private AuthToken: string,
            private $location: ng.ILocationService
        ) { }

        public getContextTypeStringForActivity(activity: ActivityResponse | SimpleActivityResponse): string {
            if (!activity || !activity.context || !activity.context.type) {
                return null;
            }

            switch (activity.context.type) {
                case ActivityContextType.ALERT:
                    return 'Alert Fired';
                case ActivityContextType.AID_ISSUE:
                    return 'Load Analytics';
                case ActivityContextType.ENERGY_NOTE:
                    return 'Note';
                case ActivityContextType.RX:
                    return 'HVAC Analytics';
                case ActivityContextType.OPTIMAL_START:
                    return 'Optimization Strategy';
                default:
                    return null;
            }
        }

        public getAnnualizedSavings(start, end, building: aq.common.models.Building): Promise<aq.models.activity.SavingsByAidType> {
            try {
                return this.resolver(async () => {
                    const now = new Date();
                    const response = await this.$http({
                        method: 'GET',
                        headers: this.getHeaders(),
                        url: `${this.AQ_API_URL}activities/v1/annualized-aid-savings`,
                        params: {
                            start: moment(start).toDate(),
                            end: now,
                            building: building.id
                        }
                    });
                    return response.data;
                });
            } catch (error) {
                throw error;
            }
        }

        public createMessage(message: aq.models.activity.ActivityMessageCreateRequest, parentID: string): Promise<ActivityResponse> {
            try {
                return this.resolver(async () => {
                    const response = await this.$http({
                        method: 'POST',
                        headers: this.getHeaders(),
                        data: JSON.stringify(message),
                        url: `${this.AQ_API_URL}activities/v1/${parentID}/messages`
                    });
                    return response.data;
                });
            } catch (error) {
                throw error;
            }
        }

        public getActivity(id: string): Promise<ActivityResponse> {
            try {
                return this.resolver(async () => {
                    const response = await this.$http({
                        method: 'GET',
                        headers: this.getHeaders(),
                        url: `${this.AQ_API_URL}activities/v1/${id}`
                    });
                    return response.data;
                });
            } catch (error) {
                throw error;
            }
        }

        public getActivities(params: ActivityQueryParams): Promise<ActivityListResponse> {
            try {
                return this.resolver(async () => {
                    const response = await this.$http({
                        method: 'GET',
                        headers: this.getHeaders(),
                        url: `${this.AQ_API_URL}activities/v1`,
                        params
                    });
                    return response.data;
                });
            } catch (error) {
                throw error;
            }
        }

        private getHeaders(): {[key: string]: string | number | boolean} {
            return {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.AuthToken ? this.AuthToken : this.$location.search().authToken}`
            };
        }
    }

    angular
        .module('aq.services')
        .service('ActivityService', ActivityService);
}

