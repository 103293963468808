namespace aq.services {

    import TenantCreationRequest = aq.tenantbilling.TenantCreationRequest;
    import LeaseCreationRequest = aq.tenantbilling.LeaseCreationRequest;
    import ResidentCreationRequest = aq.tenantbilling.ResidentCreationRequest;

    export class TenantService {
        private buildingId: string;
        private TENANT_PROPERTIES = [
            'id',
            'name',
            'building',
            'accountNumber',
            'primaryContact',
            'phoneNumber',
            'email',
            'address',
            'city',
            'state',
            'zipCode',
            'country',
            'size',
            'leaseType'
        ];
        private LEASE_PROPERTIES = [
            'id',
            'leaseIdentifier',
            'startDate',
            'endDate',
            'active',
            'tenant',
            'unitName'
        ];
        private RESIDENT_PROPERTIES = [
            'id',
            'name',
            'email',
            'lease',
            'phoneNumber',
            'residentIdentifier',
            'startDate',
            'unitName'
        ];
        /* @ngInject */
        constructor(
            private RestangularV3: restangular.IService,
        ) {}

        public bulkCreate(tenantList: TenantCreationRequest[]): ng.IPromise<restangular.IElement> {
            const cleanTenantList = tenantList.map((tenant) => {
                this.buildingId = tenant.building;
                return this.cleanFields(tenant);
            });
            return this.RestangularV3.one('tenants').customPOST(cleanTenantList, 'bulk-create', { buildingId: this.buildingId });
        }

        public createLeases(leaseList: LeaseCreationRequest[]): ng.IPromise<restangular.IElement> {
            const cleanLeaseList = leaseList.map((lease) => {
                return this.cleanLeaseFields(lease);
            });
            return this.RestangularV3.one('leases').customPOST(cleanLeaseList, 'bulk-create', { buildingId: this.buildingId });
        }

        public createResidents(residentList: ResidentCreationRequest[]): ng.IPromise<restangular.IElement> {
            const cleanResidentList = residentList.map((resident) => {
                return this.cleanResidentFields(resident);
            });
            return this.RestangularV3.one('residents').customPOST(cleanResidentList, 'bulk-create', { buildingId: this.buildingId });
        }

        public updateLeases(leaseList: LeaseCreationRequest[], buildingId: string): ng.IPromise<restangular.IElement> {
            const cleanLeaseList = leaseList.map((lease) => {
                return this.cleanLeaseFields(lease);
            });
            return this.RestangularV3.one('leases').customPUT(cleanLeaseList, 'process-move-outs', { buildingId: buildingId });
        }

        private cleanFields(tenant: any): aq.tenantbilling.TenantModel {
            return _.pick(tenant, this.TENANT_PROPERTIES);
        }

        private cleanLeaseFields(lease: any): aq.tenantbilling.BulkLeaseModel {
            return _.pick(lease, this.LEASE_PROPERTIES);
        }

        private cleanResidentFields(resident: any): aq.tenantbilling.BulkResidentModel {
            return _.pick(resident, this.RESIDENT_PROPERTIES);
        }

        public getResponseLength(response): number {
            return response != undefined ? response.length : 0;
        }
    }

    angular
        .module('aq.services')
        .service('TenantService', TenantService);
}
