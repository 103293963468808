namespace aq.tenantbilling {
    export class EditPeriodSummaryStepCtrl {
        private monthlyBilling;
        private tenantServices;
        private tenantCharges;
        private tenants;
        private enabledSettings: aq.tenantbilling.EnabledSettings;
        tenantLabel: string;
        constructor(
            private Auth: aq.services.Auth
        ) {
            this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'unit' : 'tenant';
        }

        public getService(serviceId: number): any {
            return _.find(this.tenantServices, { id: serviceId });
        }

        public getEnabledTenants() {
            return _.filter(this.tenants, (tenant) => {
                return this.enabledSettings.tenants[tenant.id];
            });
        }

        public getCharges(tenant: aq.tenantbilling.Tenant): any[] {
            return _.filter(this.tenantCharges, { tenant: tenant.id });
        }

        public getSortedCharges(tenant: aq.tenantbilling.Tenant): any[] {
            const charges = this.getCharges(tenant);
            return this.sortCharges(charges);
        }

        public sortCharges(charges: any[]): any[] {
            return _.orderBy(charges, ['type', (charge) => {
                const service = this.getService(charge.service);
                return service.serviceId;
            }], ['desc', 'asc']);
        }

        public getEnabledCharges(tenant: any): any[] {
            return _.filter(this.getSortedCharges(tenant), (charge) => {
                return this.enabledSettings.charges[charge.id];
            });
        }
    }

    angular
        .module('tenantBilling')
        .component('editPeriodSummaryStep', {
            templateUrl: 'app/tenantBilling/invoicing/run/editInvoicingPeriod/components/editPeriodSummaryStep/editPeriodSummaryStep.html',
            controller: EditPeriodSummaryStepCtrl,
            controllerAs: 'vm',
            bindings: {
                monthlyBilling: '<',
                tenantServices: '<',
                tenantCharges: '<',
                tenants: '<',
                enabledSettings: '='
            }
        });
}
