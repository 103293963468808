type Building = aq.common.models.Building;
type BaseloadResponse = aq.energyInsights.models.BaseloadResponse;
type TargetItem = aq.propertySettings.TargetItem;
type BarChartSeriesOptions = __Highcharts.BarChartSeriesOptions;
type DataValue = aq.energyInsights.service.DataValue;

namespace aq.energyInsights {
    export class BaseloadV2Service extends InsightService {
        constructor(
            private $q: ng.IQService,
            private EnergyInsightsDataService: aq.energyInsights.service.EnergyInsightsDataService,
            private $filter,
            private DataStore: aq.common.DataStore,
            private OptionsService,
            private Restangular,
            private $translate
        ) {
            super();
        }

        public getSimpleTargetModel(building: aq.common.models.Building, measure: string, periodSearch: PeriodSearch, name: string): SimpleTargetModel {
            let targetModel = new SimpleTargetModel();
            targetModel.title = this.$translate.instant('insight.Baseload');
            this.$translate.instant(`timeperiod.${periodSearch.label}`).toLowerCase();
            return targetModel;
        }

        public getTargetModel(
                    building: Building,
                    measure: string,
                    periodSearch: PeriodSearch,
                    account: aq.common.models.Account,
                    name: string
                ): ng.IPromise<TargetModel> {
            return this.getTableModel(building, measure, periodSearch, account).then((tableData) => {
                return this.getTargetData(building, tableData, periodSearch, name);
            });
        }

        public getGraphModel(
                    building: Building,
                    measure: string,
                    periodSearch: PeriodSearch,
                    account: aq.common.models.Account
            ): ng.IPromise<GraphModel> {
            return this.getTableModel(building, measure, periodSearch, account).then((tableData) => {
                return {
                    graph: this.getChartConfig(tableData, building, periodSearch)
                };
            });
        }

        public getTableModel(
                    building: Building,
                    measure: string,
                    periodSearch: PeriodSearch,
                    account: aq.common.models.Account
            ): ng.IPromise<TableModel> {
            return this.getKwHMetric(account)
                .then((metric) => {
                    return this.$q.all([
                        this.EnergyInsightsDataService.queryBaseload(
                            building,
                            periodSearch.start,
                            periodSearch.end,
                            periodSearch.interval.value,
                            metric,
                            account),
                        this.DataStore.getList(
                            this.Restangular
                            .one('accounts', account.id)
                            .one('buildings', building.id)
                            .one('Targets'), 'queryTargets', {
                            startDate: periodSearch.start.format(),
                            endDate: periodSearch.end.format(),
                            targetType: 'BASELOAD'
                        })
                    ]);
                })
                .then((result) => {
                    const values = result[0];
                    const targets = result[1];
                    return this.formatTableModel(values, targets, periodSearch, building);
                });
        }

        public formatTableModel(values, targets, periodSearch, building) {
            if (periodSearch.interval.value === '1d' && targets.length > 0) {
                const firstTarget = targets[0];
                let targetDays = 0;
                const numDays = periodSearch.end.diff(periodSearch.start, 'days');
                targets = [];
                for (let i = 0; i <= numDays; i++) {
                    const targetDate = moment(firstTarget.startDate).tz(building.timeZoneId).add(i, 'days');
                    if (targetDate.isoWeekday() !== 6 && targetDate.isoWeekday() !== 7) {
                        targetDays++;
                        targets.push({
                            startDate: targetDate.startOf('days').format(),
                            endDate: targetDate.endOf('days').format()
                        });
                    }
                }
                if (targetDays > 0) {
                    const dailyValue = Math.round(firstTarget.value / targetDays);
                    targets.forEach((target) => {
                        target.value = dailyValue;
                    });
                }
            }
            return this.transformTableData(values, targets, building, periodSearch);
        }

        // --------------------------

        public transformTableData(baseloadData: DataValue[], targets: TargetItem[], building: aq.common.models.Building, periodSearch): TableModel {
            const tableModel = new TableModel();
            const rows = [];
            for (let i = 0; i < baseloadData.length; i++) {
                const row: any = {
                    timestamp: baseloadData[i].timestamp,
                    formattedDate: this.formatTimestampForChart(baseloadData[i].timestamp, building, periodSearch),
                    value: baseloadData[i].value,
                    isMissingData: baseloadData[i].isMissingData ? baseloadData[i].isMissingData : false
                };
                const rowTime = moment(row.timestamp).tz(building.timeZoneId);
                const target = _.find(targets, (t) => {
                    const startDate = moment(t.startDate).tz(building.timeZoneId).startOf('day');
                    const endDate = moment(t.endDate).tz(building.timeZoneId).endOf('day');
                    return rowTime.isBetween(startDate, endDate) || rowTime.isSame(startDate) || rowTime.isSame(endDate);
                });
                if (target) {
                    row.target = target.value;
                    row.status = this.getRowStatus(baseloadData[i].value, target.value);
                    row.percentage = this.getPercent(baseloadData[i].value, target.value);
                }
                rows.push(row);
            }
            tableModel.rows = rows;
            tableModel.totals = {
                consumptionTotal: this.getAnswer(tableModel),
                targetTotal: this.getTarget(tableModel)
            };
            return tableModel;
        }

        public getTargetData(building: aq.common.models.Building, tableData: TableModel, periodSearch: aq.energyInsights.PeriodSearch, name: string): TargetModel {
            const today = moment().tz(building.timeZoneId).format('MMM Do');
            const numberOfDaysInMonth = moment().tz(building.timeZoneId).diff(moment().tz(building.timeZoneId).startOf('month'), 'days');
            const percOfTime = this.getPercent(numberOfDaysInMonth, moment().tz(building.timeZoneId).daysInMonth());
            const targetModel = new TargetModel();
            targetModel.question = `${this.$translate.instant('insight.question.Baseload')} ${building.name}?`;
            targetModel.title = name;
            targetModel.target = this.getTarget(tableData);
            targetModel.answer = this.getAnswer(tableData);
            targetModel.percentage = this.getPercent(targetModel.answer, targetModel.target);
            targetModel.showDonut = !_.isNull(targetModel.target);
            targetModel.showTarget = !_.isNull(targetModel.target);
            targetModel.formattedAnswer = `${this.$filter('number')(targetModel.answer, '0,0')} kWh`;
            targetModel.formattedTarget = `${this.$translate.instant('insight.target.Target')} ${this.$filter('number')(targetModel.target, '0,0')} kWh`;
            targetModel.when = this.$translate.instant(`timeperiod.${periodSearch.label}`).toLowerCase();
            targetModel.icon = this.getIcon(targetModel.answer, targetModel.target, targetModel.percentage, percOfTime);
            targetModel.color = this.getColor(targetModel.answer, targetModel.target, targetModel.percentage, percOfTime);
            targetModel.iconColor = targetModel.color;
            let tooltipWhen = targetModel.when;
            if (targetModel.when === this.$translate.instant('timeperiod.This Month')) {
                tooltipWhen = `${today}`;
            } else {
                tooltipWhen = tooltipWhen.toLowerCase();
            }
            targetModel.tooltip = `${this.$translate.instant('insight.tooltip.Baseload1')} <span style="color:${targetModel.color}">${this.$filter('number')(targetModel.percentage, '0,0')}%</span> ${this.$translate.instant('insight.tooltip.Baseload2')} ${tooltipWhen}.`;
            targetModel.timeElapsedPercentage = percOfTime;
            targetModel.timeElapsedLabel = today;
            targetModel.buildingId = building.id;
            return targetModel;
        }

        private formatTimestampForChart(timestamp: number, building: aq.common.models.Building, periodSearch): string {
            if (periodSearch.interval.value === '1d') {
                return moment(timestamp).tz(building.timeZoneId).format('dd MMM Do');
            } else {
                return moment(timestamp).tz(building.timeZoneId).format('MMM');
            }
        }
        private getChartConfig(tableData: TableModel, building: aq.common.models.Building, periodSearch) {
            return {
                lang: {
                    drillUpText: '< Back to previous selection',
                    noData: 'No baseload data to display.'
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: false
                        }
                    }
                },
                title: '',
                chart: {
                    type: 'column',
                    plotBorderWidth: 1,
                    drilldown: {
                        activeAxisLabelStyle: {
                            textDecoration: 'none',
                            fontWeight: 'normal',
                            color: '#666666'
                        }
                    }
                },
                plotOptions: {
                    line: {
                        animation: false
                    },
                    series: {
                        stacking: 'normal',
                        stickyTracking: false
                    },
                    column: {
                        grouping: false,
                        shadow: false,
                        borderWidth: 0
                    }
                },
                tooltip: {
                    useHtml: true,
                    formatter: function () {
                        if (this.points && this.points.length > 0) {
                            let seriesString = '';;
                            let targetString = '';
                            let totalString = '';
                            let titleString = '';
                            const dateFormat = this.points[0].point.drilldown ? 'MMMM YYYY' : 'dd MMM Do';
                            const actualTotalPoint = _.find(this.points, (p: any) => {
                                return p.series.name != 'Target';
                            });

                            if (this.points.length > 1 && this.points[0].key !== this.points[1].key) {
                                this.points.shift();
                            }

                            let actualTotal = 0;
                            if (actualTotalPoint) {
                                actualTotal = actualTotalPoint.total;
                            }
                            this.points.forEach((point) => {
                                const formattedValue = Math.round(point.point.y).toLocaleString();
                                if (point.series.name != 'Target') {
                                    seriesString += `<span class="tooltip-series" style="color:${point.series.color};">• </span><span>${point.series.name}</span>: ${formattedValue} kWh <br/><br/>`;
                                } else {
                                    targetString += `<span class="tooltip-bold">${point.series.name}: ${formattedValue} kWh</span><br/>`;
                                }
                                titleString += `<span class="tooltip-bold">${moment(point.point.timestamp).tz(building.timeZoneId).format(dateFormat)}</span>`;
                                targetString += point.point.isMissingData ? '<br><div style="color:red">This building uses data directly <br><div style="color:red">from your utility company.<br><br><i>Some data has not been received yet.' : '';
                            });
                            const currentYear = moment().year();
                            const formattedTotal = Math.round(actualTotal).toLocaleString();
                            totalString += `<span class="tooltip-bold">Total: ${formattedTotal} kWh</span>`;
                            const formattedString = `${titleString}<br/><br/>${seriesString}<br/><br/>${totalString}<br/><br/>${targetString}`;
                            return formattedString;
                        }
                    },
                    shared: true
                },
                xAxis: {
                    type: 'category'
                },
                yAxis: [{
                    min: 0,
                    title: {
                        text: `Consumption (kWh)`
                    },
                    plotLines: [{
                        value: 200,
                        width: 2
                    }]
                }],
                drilldown: {
                    series: []
                },
                series: this.buildSeries(tableData, periodSearch, building)
            };
        }

        private buildSeries(tableData: TableModel, periodSearch, building: aq.common.models.Building): BarChartSeriesOptions[] {
            const underSeries = {
                name: 'Under Target',
                color: '#7ACD46',
                data: [],
                index: 2
            };
            const overSeries = {
                name: 'Over Target',
                color: '#E70F28',
                data: [],
                index: 1
            };
            const targetSeries = {
                type: 'scatter',
                name: 'target',
                color: '#0091f1',
                data: [],
                showInLegend: false,
                enableMouseTracking: false,
                marker: {
                    symbol: 'url(data:image/svg+xml;utf8,' +
                        '<svg height=\"3\" width=\"55\" xmlns=\"http://www.w3.org/2000/svg\">' +
                        '<rect fill=\"#0091F1\" height=\"3\" width=\"55\"/></svg>)'
                },
                index: 0
            };
            const drilldown = periodSearch.interval.value === '1mon';
            let hasTarget = false;
            tableData.rows.forEach((row) => {
                const category = this.formatTimestampForChart(row.timestamp, building, periodSearch);
                if (row.target) {
                    targetSeries.data.push({
                        name: category,
                        timestamp: row.timestamp,
                        y: row.target
                    });
                    hasTarget = true;
                }
                if (row.target && row.target < row.value) {
                    overSeries.data.push({
                        name: category,
                        y: (row.value - row.target),
                        timestamp: row.timestamp,
                        drilldown,
                        color: row.isMissingData ?this.EnergyInsightsDataService.transparentColor('#E70F28') : '#E70F28',
                        isMissingData: row.isMissingData
                    });
                    underSeries.data.push({
                        name: category,
                        y: row.target,
                        timestamp: row.timestamp,
                        drilldown,
                        color: row.isMissingData ? this.EnergyInsightsDataService.transparentColor('#7ACD46') : '#7ACD46',
                        isMissingData: row.isMissingData
                    });
                } else {
                    underSeries.data.push({
                        name: category,
                        y: row.value,
                        timestamp: row.timestamp,
                        drilldown,
                        color: row.isMissingData ? this.EnergyInsightsDataService.transparentColor('#7ACD46') : '#7ACD46',
                        isMissingData: row.isMissingData
                    });
                }
            });
            const series = [underSeries];
            if (overSeries.data.length > 0) {
                series.push(overSeries);
            }
            if (targetSeries.data.length > 0) {
                series.push(targetSeries);
            }

            if (!hasTarget) {
                underSeries.color = '#979797';
                underSeries.name = 'Consumption';
            }
            return series;
        }

        private getRowStatus(answer, target): string {
            const percentage = this.getPercent(answer, target);
            if (percentage < 80) {
                return 'under';
            } else if (percentage <= 100) {
                return 'warn';
            } else if (percentage > 100) {
                return 'over';
            }
        }

        private getKwHMetric(account: aq.common.models.Account) {
            return this.Restangular.one('accounts', account.id).customGET('queryRealUnits').then(function (allUnits) {
                return _.find(allUnits, { unit: 'kWh' });
            });
        }
        private getTarget(tableData: TableModel) {
            return _.sum(_.map(tableData.rows, (row) => { return row.target; }));
        }

        private getAnswer(tableData: TableModel) {
            return _.sum(_.map(tableData.rows, (row) => { return row.value; }));
        }

    }
    angular.module('energyInsights').service('BaseloadV2Service', BaseloadV2Service);
}
