namespace aq.reports {
    export class CustomDateRangeBuildingReportCtrl extends BaseBuildingReportCtrl {
        public time: moment.Moment;
        public previousTime: moment.Moment;
        public occupancyData: any;
        public monthIndex: number;
        public personas: any;
        public summaryGroupData: DataSummary;
        public groupData: BuildingGroupReportItem[];
        public olderRangeEndDate: moment.Moment;
        public olderRangeStartDate: moment.Moment;
        public demandIntervalTitle: string;
        public currentMonth: moment.Moment;
        public dateRangeNumDays: number;
        public numDaysToStopUsingDailyData: number;
        /* ngInject */
        constructor(
            protected currentDate: moment.Moment,
            protected account: Account,
            protected building: Building,
            protected energyStarScore: aq.common.models.EnergyStarScoreResponse,
            protected functionalities: string[],
            protected OptionsService,
            protected availableMetrics: string[],
            protected meters: aq.utilityBudgets.Meter[],
            protected $timeout: ng.ITimeoutService,
            private $q: ng.IQService,
            private ReportApiParams,
            private DataService,
            private MonthlyOccupancyData: aq.reports.summary.MonthlyOccupancyData,
            private CustomDateRangeChartsService: aq.reports.CustomDateRangeChartsService,
            private $filter: ng.IFilterService,
            private buildingPersonnels: aq.common.models.BuildingPersonnel[],
            protected startDate: moment.Moment,
            protected endDate: moment.Moment
        ) {
            super(
                currentDate,
                account,
                building,
                energyStarScore,
                functionalities,
                OptionsService,
                availableMetrics,
                meters,
                $timeout
            );
            this.personas = {
                'ASSET_MANAGER': 'Asset Manager',
                'PROPERTY_MANAGER': 'Property Manager',
                'BUILDING_ENGINEER': 'Building Engineer'
            };
            this.time = angular.copy(this.buildingReport.building.currentTime);
            this.time.subtract(1, 'month');
            this.currentMonth = angular.copy(this.time);
            this.monthIndex = parseInt(this.currentMonth.format('M')) - 1;
            this.occupancyData = this.MonthlyOccupancyData.getMonthlyOccupancy(building, this.monthIndex, null);
            this.previousTime = moment(this.time).subtract(1, 'month');
            const buildingPersonnel = this.getBuildingPersonnel(this.building);
            this.building.personnel = buildingPersonnel;
            this.dateRangeNumDays = this.endDate.diff(this.startDate, 'days');
            this.numDaysToStopUsingDailyData = 60;
            this.olderRangeEndDate = angular.copy(this.startDate);
            this.olderRangeEndDate.subtract(1, 'days');
            this.olderRangeStartDate = angular.copy(this.olderRangeEndDate);
            this.olderRangeStartDate.subtract(this.dateRangeNumDays, 'days');
            this.buildReports();
        }

        public buildReports() {
            const reportPromises = [];
            _.each(this.buildingReport.serviceReports, (report: BuildingServiceReport) => {
                const config = report.config;
                report.data = {
                    loading: true
                } as any;

                //values used in all charts
                const dailyDataParams = new this.ReportApiParams(this.time, config.measures, 'monthly', this.startDate, this.endDate);

                const olderRangeDailyDataParams = new this.ReportApiParams(this.time, config.measures, 'monthly', this.olderRangeStartDate, this.olderRangeEndDate);

                const currentDailyTemperaturePromise = this.building.customGET('temperature', dailyDataParams.current());
                const currentDailyDataPromise = this.building.customGET('data', dailyDataParams.current());
                const olderRangeDailyDataPromise = this.building.customGET('data', olderRangeDailyDataParams.current());

                const reportDefer = this.$q.defer();
                this.$q.all([
                    currentDailyDataPromise,
                    olderRangeDailyDataPromise,
                    currentDailyTemperaturePromise,
                ]).then((resultsList: CustomReportResultObject[]) => {
                    let resultsHolder = { currentDailyValues: null, currentDailyTemperatureValues: null, olderRangeDailyValues: null };
                    resultsHolder.currentDailyValues = resultsList[0];
                    resultsHolder.olderRangeDailyValues = resultsList[1];
                    resultsHolder.currentDailyTemperatureValues = resultsList[2].values;

                    const handlePromises = [];
                    if (this.dateRangeNumDays > this.numDaysToStopUsingDailyData) {
                        this.demandIntervalTitle = "Daily Peak";
                        if (config.usageMetric != 'gas') {
                            handlePromises.push(this.handleDailyDemand(report.data, report.config, resultsHolder));
                        }
                    } else {
                        this.demandIntervalTitle = "15 Minute Interval";
                        if (config.usageMetric != 'gas') {
                            handlePromises.push(this.handleHourlyDemand(report.data, report.config));
                        }
                    }
                    handlePromises.push(this.handleDailyWeatherAndUsage(report.data, report.config, resultsHolder));
                    return this.$q.all(handlePromises).then(() => {
                        report.data.loading = false;
                    });
                }).then(() => {
                    reportDefer.resolve();
                }).catch(() => {
                    reportDefer.reject();
                });
                reportPromises.push(reportDefer.promise);
            });
            this.$q.all(reportPromises)
                .then(() => {
                    this.notifyDocumentReady();
                })
                .catch(() => {
                    this.isDocumentError = true;
                });
        }

        public handleHourlyDemand(data: ReportData, config: Report) {
            const format = 'ddd MMM D h:mma';
            const hourlyDataParams = new this.ReportApiParams(this.time, config.measures, 'daily', this.startDate, this.endDate);
            const olderRangeHourlyDataParams = new this.ReportApiParams(this.time, config.measures, 'daily', this.olderRangeStartDate, this.olderRangeEndDate);
            let hourlyDataPromise = this.building.customGET('data', hourlyDataParams.current());
            let hourlyTemperaturePromise = this.building.customGET('temperature', hourlyDataParams.current());
            let olderRangeHourlyDataPromise = this.building.customGET('data', olderRangeHourlyDataParams.current());

            return this.$q.all([
                hourlyDataPromise,
                hourlyTemperaturePromise,
                olderRangeHourlyDataPromise
            ]).then((resultsList: CustomReportResultObject[]) => {
                const currentHourlyValues = resultsList[0];
                const hourlyTemperatureValues = resultsList[1] ? resultsList[1].values : null;
                const olderRangeHourlyData = resultsList[2];
                const currentHourlyTemperature = this.$filter<Function>('arrayToTemperatureUnit')(hourlyTemperatureValues, config.temperatureUnit);
                let currentDemandArr = currentHourlyValues[config.demandMetric].values;
                let previousDemandArr = olderRangeHourlyData[config.demandMetric].values;

                data.demand = currentDemandArr.length > 0 ? Math.max(...currentDemandArr) : 0;
                let demandPeakIndex = currentDemandArr.indexOf(data.demand);
                data.demandPeakDate = moment(currentHourlyValues[config.demandMetric].timestamps[demandPeakIndex]).format(format);
                data.previousDemand = previousDemandArr.length > 0 ? Math.max(...previousDemandArr) : 0;

                const currentHourlyData = {
                    data: currentHourlyValues,
                    temperature: currentHourlyTemperature
                };
                data.demandChart = this.CustomDateRangeChartsService.getCustomDemandChart(currentHourlyData, config, this.currentMonth);
            });

        }

        public handleDailyDemand(data: ReportData, config: Report, resultsHolder: CustomReportResultsSharedDataHolder) {
            const format = 'ddd MMM D';
            const currentDailyTemperature = this.$filter<Function>('arrayToTemperatureUnit')(resultsHolder.currentDailyTemperatureValues, config.temperatureUnit);

            if (config.usageMetric == 'energy') {
                const dailyDataParams = new this.ReportApiParams(this.time, config.measures, 'monthly', this.startDate, this.endDate);
                const olderRangeDailyDataParams = new this.ReportApiParams(this.time, config.measures, 'monthly', this.olderRangeStartDate, this.olderRangeEndDate);
                const currentRangeDailyPeakPromise = this.building.customGET('peaks', dailyDataParams.current());
                const olderRangeDailyPeakPromise = this.building.customGET('peaks', olderRangeDailyDataParams.current());

                return this.$q.all([
                    currentRangeDailyPeakPromise,
                    olderRangeDailyPeakPromise
                ]).then((resultsList: CustomReportResultObject[]) => {
                    const currentRangeDailyPeaks = resultsList[0];
                    const olderRangeDailyPeaks = resultsList[1];
                    resultsHolder.currentDailyValues[config.demandMetric].values = currentRangeDailyPeaks.values;
                    resultsHolder.olderRangeDailyValues[config.demandMetric].values = currentRangeDailyPeaks.values;

                    const currentDailyData = {
                        data: resultsHolder.currentDailyValues,
                        temperature: currentDailyTemperature
                    };
                    let currentDemandArr = currentRangeDailyPeaks.values;
                    let previousDemandArr = olderRangeDailyPeaks.values;

                    data.demand = currentDemandArr.length > 0 ? Math.max(...currentDemandArr) : 0;
                    let demandPeakIndex = currentRangeDailyPeaks.values.indexOf(data.demand);
                    data.demandPeakDate = moment(resultsHolder.currentDailyValues[config.demandMetric].timestamps[demandPeakIndex]).format(format);
                    data.previousDemand = previousDemandArr.length > 0 ? Math.max(...previousDemandArr) : 0;

                    data.demandChart = this.CustomDateRangeChartsService.getCustomDemandChart(currentDailyData, config, this.currentMonth);

                });
            } else {
                const currentDailyData = {
                    data: resultsHolder.currentDailyValues,
                    temperature: currentDailyTemperature
                };
                let currentDemandArr = resultsHolder.currentDailyValues[config.demandMetric].values;
                let previousDemandArr = resultsHolder.olderRangeDailyValues[config.demandMetric].values;

                data.demand = currentDemandArr.length > 0 ? Math.max(...currentDemandArr) : 0;
                let demandPeakIndex = currentDemandArr.indexOf(data.demand);
                data.demandPeakDate = moment(resultsHolder.currentDailyValues[config.demandMetric].timestamps[demandPeakIndex]).format(format);
                data.previousDemand = previousDemandArr.length > 0 ? Math.max(...previousDemandArr) : 0;

                data.demandChart = this.CustomDateRangeChartsService.getCustomDemandChart(currentDailyData, config, this.currentMonth);
            }
        }

        public handleDailyWeatherAndUsage(data: ReportData, config: Report, resultsHolder: CustomReportResultsSharedDataHolder) {

            const dailyDataParams = new this.ReportApiParams(this.time, config.measures, 'monthly', this.startDate, this.endDate);
            const olderRangeDailyDataParams = new this.ReportApiParams(this.time, config.measures, 'monthly', this.olderRangeStartDate, this.olderRangeEndDate);

            const previousDailyDataPromise = this.building.customGET('data', dailyDataParams.previous());

            const currentDailyDegreeDaysPromise = this.DataService.degreeDays(this.building,
                dailyDataParams.currentDegreeDay().interval,
                dailyDataParams.currentDegreeDay().start,
                dailyDataParams.currentDegreeDay().end);

            const previousDailyDegreeDaysPromise = this.DataService.degreeDays(this.building,
                dailyDataParams.previousDegreeDay().interval,
                dailyDataParams.previousDegreeDay().start,
                dailyDataParams.previousDegreeDay().end);

            const olderRangeDailyDegreeDaysPromise = this.DataService.degreeDays(this.building,
                olderRangeDailyDataParams.currentDegreeDay().interval,
                olderRangeDailyDataParams.currentDegreeDay().start,
                olderRangeDailyDataParams.currentDegreeDay().end);

            return this.$q.all([
                previousDailyDataPromise,
                currentDailyDegreeDaysPromise,
                previousDailyDegreeDaysPromise,
                olderRangeDailyDegreeDaysPromise
            ]).then((resultsList: CustomReportResultObject[]) => {
                const previousDailyValues = resultsList[0];
                resultsHolder.currentDailyValues['degreeDays'] = resultsList[1];
                previousDailyValues['degreeDays'] = resultsList[2];

                const olderRangeDailyDegreeDays = resultsList[3];
                const currentDailyTemperature = this.$filter<Function>('arrayToTemperatureUnit')(resultsHolder.currentDailyTemperatureValues, config.temperatureUnit);

                const currentDailyData = {
                    data: resultsHolder.currentDailyValues,
                    temperature: currentDailyTemperature
                };
                const previousDailyData = {
                    data: previousDailyValues,
                    temperature: currentDailyTemperature
                };

                const arrSum = arr => arr.reduce((a, b) => a + b, 0);

                let currentUsageArr = resultsHolder.currentDailyValues[config.usageMetric].values;
                let previousUsageArr = resultsHolder.olderRangeDailyValues[config.usageMetric].values;
                let currentHDDArr = resultsHolder.currentDailyValues['degreeDays'].hdd.values;
                let olderRangeHDDArr = olderRangeDailyDegreeDays.hdd.values;
                let currentCDDArr = resultsHolder.currentDailyValues['degreeDays'].cdd.values;
                let olderRangeCDDArr = olderRangeDailyDegreeDays.cdd.values;

                data.usage = arrSum(currentUsageArr);
                data.previousUsage = arrSum(previousUsageArr);
                data.currentHDD = arrSum(currentHDDArr);
                data.previousHDD = arrSum(olderRangeHDDArr);
                data.currentCDD = arrSum(currentCDDArr);
                data.previousCDD = arrSum(olderRangeCDDArr);
                data.usageChart = this.CustomDateRangeChartsService.getCustomUsageChart(currentDailyData, config, this.currentMonth);
                data.weatherChart = this.CustomDateRangeChartsService.getCustomWeatherChart(currentDailyData, previousDailyData, config, this.currentMonth);
            });
        }


        public getServiceType(usageMetric: string) {
            switch (usageMetric) {
                case 'energy':
                    return 'Electricity';
                case 'steam_mass':
                    return 'Steam';
                case 'heat_transfer':
                    return 'Heat';
                case 'gas':
                    return 'Gas';
                case 'water':
                    return 'Water';
                default:
                    return '';
            }
        }

        public getBuildingPersonnel(building: aq.common.models.Building) {
            const data = _.find(this.buildingPersonnels, { buildingId: building.id });
            if (data && data.personnel && data.personnel.length > 0) {
                _.each(data.personnel, (item) => {
                    if (!item.email) {
                        return;
                    }
                    const emailSplit = item.email.split('@');
                    if (emailSplit.length != 2) {
                        return;
                    }
                    item.emailUsername = emailSplit[0];
                    item.emailDomain = '@' + emailSplit[1];
                });
                const sortedPersonnel = [];
                _.each(this.personas, (persona, key) => {
                    const nextPerson = _.find(data.personnel, (item) => item.persona == key);
                    if (nextPerson) {
                        sortedPersonnel.push(nextPerson);
                    }
                });
                return sortedPersonnel;
            } else {
                return [];
            }
        }
    }
    angular.module('aq.reports').controller('CustomDateRangeBuildingReportCtrl', CustomDateRangeBuildingReportCtrl);
}
