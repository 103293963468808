var aq;
(function (aq) {
    var properties;
    (function (properties) {
        var HistoricUploadModalCtrl = /** @class */ (function (_super) {
            __extends(HistoricUploadModalCtrl, _super);
            /* @ngAnnotate */
            function HistoricUploadModalCtrl($scope, $mdDialog, $mdStepper, UtilityServiceHelper, RestangularV3, building, $state, Messages) {
                var _this = _super.call(this, $scope, $mdDialog) || this;
                _this.$scope = $scope;
                _this.$mdDialog = $mdDialog;
                _this.$mdStepper = $mdStepper;
                _this.UtilityServiceHelper = UtilityServiceHelper;
                _this.RestangularV3 = RestangularV3;
                _this.building = building;
                _this.$state = $state;
                _this.Messages = Messages;
                _this.calendarize = true;
                _this.loading = false;
                _this.measureTypes = _this.UtilityServiceHelper.getMeasureTypes().filter(function (type) {
                    if (type.value === 'ELECTRICITY') {
                        return true;
                    }
                });
                $scope.$on('$destroy', function () {
                    if (_this.csvHistoricData) {
                        _this.csvHistoricData = null;
                    }
                    if (_this.previewResponse) {
                        _this.previewResponse = null;
                    }
                });
                _this.chartConfigs = {};
                return _this;
            }
            HistoricUploadModalCtrl.prototype.upload = function () {
                this.fileUploaded = true;
            };
            HistoricUploadModalCtrl.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            HistoricUploadModalCtrl.prototype.previousStep = function () {
                this.$mdStepper('historicUploadStepper').back();
            };
            HistoricUploadModalCtrl.prototype.previewStep = function () {
                var _this = this;
                this.loading = true;
                this.previewHistoricUpload().then(function () {
                    _this.$mdStepper('historicUploadStepper').next();
                }).catch(function (err) {
                    _this.Messages.error('Unable to preview your data.  Double check your CSV format.  If the issue persists please message support');
                }).finally(function () {
                    _this.loading = false;
                });
            };
            HistoricUploadModalCtrl.prototype.goToDevice = function (deviceId) {
                this.$state.go('aq.properties.buildings.datacollectors.detail', { buildingId: this.building.id, dataCollectorId: deviceId });
            };
            HistoricUploadModalCtrl.prototype.submitData = function () {
                var _this = this;
                this.loading = true;
                var datums = this.mapCSVToRequest();
                return this.RestangularV3.one('buildings', this.building.id).customPOST({
                    options: {
                        calendarize: this.calendarize,
                        preview: false,
                        measure: this.measure
                    },
                    datums: datums
                }, 'historic-upload').then(function (result) {
                    _this.$mdStepper('historicUploadStepper').next();
                }).catch(function (err) {
                    _this.Messages.error('Error submitting data.  Please message support');
                }).finally(function () {
                    _this.loading = false;
                });
            };
            HistoricUploadModalCtrl.prototype.previewHistoricUpload = function () {
                var _this = this;
                var datums = this.mapCSVToRequest();
                return this.RestangularV3.one('buildings', this.building.id).customPOST({
                    options: {
                        calendarize: this.calendarize,
                        preview: true,
                        measure: this.measure
                    },
                    datums: datums
                }, 'historic-upload').then(function (result) {
                    _this.previewResponse = result;
                    _this.chartConfig = _this.buildChartConfig(result);
                });
            };
            HistoricUploadModalCtrl.prototype.mapCSVToRequest = function () {
                var lines = this.csvHistoricData.split('\n');
                var headers = lines[0].split(',');
                lines.shift(); // remove headers;
                var datums = _.map(lines, function (line) {
                    var cells = line.split(',');
                    return {
                        startDate: cells[0],
                        endDate: cells[1],
                        value: cells[2]
                    };
                });
                return datums;
            };
            HistoricUploadModalCtrl.prototype.buildChartConfig = function (response) {
                var _this = this;
                var series = _.map(response.devices, function (device) {
                    return _this.buildSeries(device);
                });
                return {
                    chart: {
                        seriesBoostThreshold: 1,
                        plotBorderWidth: 1,
                        zoomType: 'x',
                        panning: 'true',
                        panKey: 'shift',
                        events: {}
                    },
                    title: {
                        text: 'Historic Data Preview'
                    },
                    tooltip: {
                        xDateFormat: '%B %e, %Y %l:%M %p',
                        shared: true
                    },
                    xAxis: {
                        type: 'datetime',
                        tickLength: 0,
                        gridLineWidth: 1,
                        events: {}
                    },
                    exporting: {
                        buttons: {
                            contextButton: {
                                enabled: false
                            }
                        }
                    },
                    plotOptions: {
                        line: {
                            animation: false
                        },
                        series: {
                            turboThreshold: 1
                        }
                    },
                    navigator: {
                        enabled: false,
                        series: {
                            includeInCSVExport: false
                        }
                    },
                    scrollbar: {
                        enabled: true,
                        liveRedraw: false
                    },
                    rangeSelector: {
                        enabled: true,
                        inputEnabled: true,
                        inputDateFormat: '%b %e %Y',
                        inputEditDateFormat: '%b %e %Y',
                        buttons: [
                            { type: 'hour', text: 'Hr', count: 1 },
                            { type: 'day', text: 'Day', count: 1 },
                            { type: 'week', text: 'Wk', count: 1 },
                            { type: 'month', text: 'Mon', count: 1 },
                            { type: 'all', text: 'All' }
                        ]
                    },
                    yAxis: {
                        title: {
                            text: null
                        }
                    },
                    series: series
                };
            };
            HistoricUploadModalCtrl.prototype.buildSeries = function (device) {
                var data = [];
                for (var datum in device.data) {
                    data.push([parseInt(datum), device.data[datum].power]);
                }
                return {
                    name: device.deviceName + " - " + device.pointName,
                    data: data
                };
            };
            return HistoricUploadModalCtrl;
        }(aq.common.Controllers.ModalCtrl));
        properties.HistoricUploadModalCtrl = HistoricUploadModalCtrl;
        angular
            .module('properties')
            .controller('HistoricUploadModalCtrl', HistoricUploadModalCtrl);
    })(properties = aq.properties || (aq.properties = {}));
})(aq || (aq = {}));
