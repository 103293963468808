namespace aq.admin {
    interface VisiblePersonnel {
        expanded: boolean;
        personnel: string;
    }
    export class AccountNewCtrl {
        public account: aq.common.models.Account;
        public accountsPage: boolean;
        public newAccount;
        public accountInfoObject: aq.admin.AccountInfoObject;

        public expandedBuildingIndex: number;
        public collapsedBuildingIndex: number;
        public selectedPersonnelUsers: any[];
        public availablePersonnelUsers: any[];
        public isAutofill: boolean;
        public sortedBuildings: any[];
        public invalidBuildings: { [buildingIndex: number]: boolean };
        public searchText: string;
        public unassignedUsers: aq.user.User[];
        public expandUnassigned: VisiblePersonnel;
        public isAllUsersAssigned: boolean;

        private uploadMode: boolean = true;
        private fileAttached: boolean = false;
        private badUpload: boolean = false;
        private fileInfo: string = 'Click here to upload a file';
        private fileToUpload;
        private roles;
        private isLoading: boolean = false;
        private errorMessage;
        private thisUser;
        private fileToUploadObject;

        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private $state: ng.ui.IStateService,
            private $mdDialog: ng.material.IDialogService,
            private Messages: aq.services.Messages,
            private Restangular: restangular.IService,
            private RestangularV3: restangular.IService,
            private $stateParams: ng.ui.IStateParamsService,
            private loading,
            private personas,
            private licenses,
            private options,
            private timeZones,
            private $translate,
            private $timeout,
            private BuildingService: aq.services.BuildingService,
            private $element: ng.IAugmentedJQuery,
            private $filter
        ) {
            this.thisUser = $scope.user;
            if (!$stateParams.newAccountObj) {
                $stateParams.newAccountObj = {
                    account: {
                        license: null,
                        measurementSystem: 'US_CUSTOMARY_UNITS',
                        timeZoneId: 'America/New_York',
                        accountName: ''
                    },
                    accountsPage: true
                };
            }

            this.account = $stateParams.newAccountObj.account;
            this.accountsPage = $stateParams.newAccountObj.accountsPage;

            this.expandUnassigned = {
                personnel: '',
                expanded: false
            };
            this.unassignedUsers = [];
        }

        public cancel(): void {
            if (this.accountInfoObject) {
                this.isLoading = true;
                this.loading.start();
                this.RestangularV3.one('accounts/auto-generate?transitoryAccountId=' + this.newAccount.transitoryAccountId).customDELETE(null)
                    .catch((error) => {
                        this.catchError(error);
                    }).finally(() => {
                        if (this.accountsPage) {
                            this.$state.go('aq.admin.accounts.accounts');
                        } else {
                            this.$state.go('aq.settings.platform');
                        }
                        this.isLoading = false;
                        this.loading.stop();
                    });
            } else if (this.accountsPage) {
                this.$state.go('aq.admin.accounts.accounts');
            } else {
                this.$state.go('aq.settings.platform');
            }
        }

        public onFileChange(): void {
            this.badUpload = false;
            try {
                this.fileToUploadObject = JSON.parse(this.fileToUpload);
                this.fileAttached = true;
            } catch (e) {
                this.fileAttached = false;
                this.Messages.error('Invalid data format');
            }
            if (this.fileAttached) {
                this.fileInfo = 'File Attached';
            }
        }

        public selectFile(): void {
            const fileInput = document.getElementById('uploader');
            fileInput.click();
        }

        public uploadFile(): void {
            this.isLoading = true;
            this.loading.start();
            const url = this.accountsPage ? 'accounts/auto-generate/upload' : 'accounts/auto-generate/upload?accountId=' + this.account.id;
            const fd = new FormData();
            fd.append('opportunityFile', JSON.stringify(this.fileToUploadObject));
            // Used this solution based on https://github.com/mgonto/restangular/issues/420

            this.RestangularV3.one(url)
                .withHttpConfig({ transformRequest: angular.identity })
                .customPOST(fd, '', undefined, { 'Content-Type': undefined }) // Linked GitHub issue above for reasoning behind this
                .then((res) => {
                    this.newAccount = res.account;
                    this.accountInfoObject = new AccountInfoObject();
                    this.accountInfoObject.buildings = res.buildings;
                    this.accountInfoObject.users = res.users;
                    this.roles = res.roles;
                    this.processUsers();
                    this.processBuildings();
                    this.Messages.success('Upload successful');
                    this.updateBuildingValidation();
                    this.validateUsersAssignment();
                }).catch((error) => {
                    this.badUpload = true;
                    this.catchError(error);
                }).finally(() => {
                    this.isLoading = false;
                    this.loading.stop();
                });
        }

        public assignProjectLeadAndDoImport() {
            this.$mdDialog.show({
                controller: 'ProjectLeadModalCtrl as vm',
                templateUrl: 'app/admin/accounts/accounts/create/projectLeadModal.html',
                clickOutsideToClose: false,
                locals: {
                    users: angular.copy(this.accountInfoObject.users)
                }
            })
                .then((data) => {
                    if (data.isProjectLead) {
                        this.accountInfoObject.projectLead = data.projectLead;
                        this.updatePersonnel(this.accountInfoObject.projectLead);
                    } else {
                        this.accountInfoObject.projectLead = null;
                    }
                    this.accountInfoObject.emailScheduledDate = this.$filter('date')(data.emailScheduledDate, 'yyyy-MM-dd');
                    this.createAutoGeneratedAccount();
                });
        }

        public createAutoGeneratedAccount(): void {
            this.isLoading = true;
            this.loading.start();
            const url = 'accounts/auto-generate?transitoryAccountId=' + this.newAccount.transitoryAccountId;
            this.RestangularV3
                .one(url)
                .customPOST(this.accountInfoObject)
                .then(() => {
                    if (this.accountsPage) {
                        this.Messages.success('Account information submitted, please allow a few minutes for the account to be created.');
                        this.$state.go('aq.admin.accounts.accounts');
                    } else {
                        this.Messages.success('Account information submitted, please allow a few minutes for the users and buildings to be created.');
                        this.$state.go('aq.settings.platform');
                    }
                })
                .catch((response) => {
                    this.Messages.error(response.data);
                })
                .finally(() => {
                    this.isLoading = false;
                    this.loading.stop();
                });
        }

        public createManualAccount() {
            this.isLoading = true;
            this.loading.start();
            this.Restangular.all('accounts').post(this.account).then((account) => {
                if (account) {
                    this.Restangular.one('accounts', account.id)
                        .one('profiles', account.profiles[0])
                        .get({ inflate: 'account,apps' })
                        .then((profiles) => {
                            if (profiles) {
                                const profile = profiles[0];
                                this.thisUser.profiles.push(profile);
                                this.Messages.success('Account created successfully.');
                            }
                        }).finally(() => {
                            this.loading.stop();
                            this.$state.go('aq.admin.accounts.accounts.details.configuration', { accountId: account.id, account });
                        });
                } else {
                    this.isLoading = false;
                    this.loading.stop();
                }
            }).catch((response) => {
                const accountInUse = _.some(response.data.errors, function (error) {
                    return error.field === 'updated.accountName';
                });
                this.isLoading = false;
                this.loading.stop();
                this.Messages.error(accountInUse ? `Account name '${this.account.accountName}' is already in use` : response.data.message);
            });
        }

        public getBuildingAddressView(building: aq.common.models.TransientBuilding) {
            const data = [building.address, building.city, building.state, building.zipCode, building.country];
            return _.compact(data).join(', ');
        }

        public onPersonnelChange() {
            const building = _.find(this.accountInfoObject.buildings, (b) => b.index == this.expandedBuildingIndex);
            if (!building) {
                return;
            }
            const users = _.filter(this.accountInfoObject.users, (user) => {
                return _.findIndex(this.selectedPersonnelUsers, (person) => person.id == user.index) > -1;
            });
            const personnel = _.map(users, (user) => {
                return <aq.common.models.Employee>{
                    user
                };
            });
            building.personnel = personnel;
            this.validateUsersAssignment();
        }

        public toggleExpandedBuilding(buildingIndex: number) {
            this.collapsedBuildingIndex = this.expandedBuildingIndex;
            if (this.expandedBuildingIndex == buildingIndex) {
                this.expandedBuildingIndex = null;
                this.scrollBuildingHeaderIntoView();
            } else {
                this.expandedBuildingIndex = buildingIndex;
                this.hideChipsInput();
                this.$timeout(() => {
                    this.scrollBuildingHeaderIntoView();
                }, 300);
            }
            const building = _.find(this.accountInfoObject.buildings, (b) => b.index == buildingIndex);
            if (!building) {
                return;
            }
            this.selectedPersonnelUsers = this.getPersonnelViewItems(building.personnel);
            this.availablePersonnelUsers = this.getPersonnelViewItemsFromUsers(this.accountInfoObject.users);
            this.isAutofill = true;
            this.updateBuildingValidation();
        }

        public createBuilding(): void {
            const building = <aq.common.models.TransientBuilding>{};
            building.personnel = [];
            let index = 1;
            if (this.accountInfoObject.buildings.length > 0) {
                index = this.accountInfoObject.buildings[this.accountInfoObject.buildings.length - 1].index + 1;
            }
            building.index = index;
            this.accountInfoObject.buildings.push(building);
            this.sortedBuildings.splice(0, 0, building);
            this.toggleExpandedBuilding(building.index);
        }

        public deleteBuilding(building): void {
            this.$mdDialog.show({
                controller: 'NestedConfirmDialog as vm',
                templateUrl: 'app/admin/accounts/accounts/create/nestedConfirmDialog.html',
                clickOutsideToClose: false,
                locals: {
                    promptQuestion: 'Are you sure you want to delete this building?'
                }
            })
                .then(() => {
                    _.remove(this.accountInfoObject.buildings, building);
                    _.remove(this.sortedBuildings, building);
                }).finally(() => {
                    this.updateBuildingValidation();
                });
        }

        public updateBuildingValidation() {
            this.invalidBuildings = {};
            _.each(this.accountInfoObject.buildings, (building: aq.common.models.TransientBuilding) => {
                if (this.isMissingBuildingData(building)) {
                    this.invalidBuildings[building.index] = true;
                }
            });
        }

        public queryAddress(searchText) {
            return this.BuildingService.findAddress(searchText);
        }
        public selectedBuildingAddressItemChange(item, building: aq.common.models.TransientBuilding) {
            if (item && item.place) {
                const { street, city, stateCode, postalCode, countryCode } = item.place.properties;
                building.address = street;
                building.city = city;
                building.state = stateCode;
                building.zipCode = postalCode;
                building.country = countryCode;
            }
        }
        public createUser(isGlobal = false) {
            let index = 1;
            if (this.accountInfoObject.users.length > 0) {
                index = this.accountInfoObject.users[this.accountInfoObject.users.length - 1].index + 1;
            }
            const user = <aq.user.User>{ index };
            this.accountInfoObject.users.push(user);
            this.editUser(user, true, isGlobal);
        }
        public createUserGlobally() {
            this.createUser(true);
        }
        public editUser(user: aq.user.User, isNew: boolean, isGlobal: boolean) {
            let isAllBuildings = false;
            if (!isNew) {
                isAllBuildings = _.every(this.accountInfoObject.buildings, (building: aq.common.models.TransientBuilding) => {
                    return _.some(building.personnel, (employee: aq.common.models.Employee) => employee.user.index == user.index);
                });
            }
            const emails = _.map(this.accountInfoObject.users, (u: aq.user.User) => u.email);
            _.pull(emails, user.email);
            this.$mdDialog.show({
                controller: 'EditUserModalCtrl as vm',
                templateUrl: 'app/components/verifySteps/modals/editUserModal.html',
                clickOutsideToClose: false,
                locals: {
                    editUser: user,
                    emails: _.compact(emails),
                    roles: this.roles,
                    personas: this.personas,
                    isNew,
                    isAllBuildings,
                    isGlobal,
                    buildings: isGlobal ? this.accountInfoObject.buildings : [],
                    personaRequired: false,
                    enableDelete: !isNew && user.existingUserId == null,
                    isReadonlyUserDetails: !isNew && user.existingUserId != null
                },
                multiple: true
            } as any)
                .then((updatedUser) => {
                    if (updatedUser.delete) {
                        this.removeAssociatedPersonnel(user);
                        _.remove(this.selectedPersonnelUsers, (p) => p.id == user.index);
                        _.remove(this.availablePersonnelUsers, (p) => p.id == user.index);
                        _.remove(this.accountInfoObject.users, user);
                        return;
                    }

                    const isDiff = user.firstName != updatedUser.firstName
                        || user.lastName != updatedUser.lastName
                        || user.email != updatedUser.email
                        || user.phoneNumber != updatedUser.phoneNumber
                        || user.persona != updatedUser.persona
                        || user.role != updatedUser.role;
                    if (isDiff) {
                        (user as any).isUpdate = true;
                        user.firstName = updatedUser.firstName;
                        user.lastName = updatedUser.lastName;
                        user.email = updatedUser.email;
                        user.phoneNumber = updatedUser.phoneNumber;
                        user.persona = updatedUser.persona;
                        user.role = updatedUser.role;
                        this.updatePersonnel(user);
                    }
                    if (isNew) {
                        const building = _.find(this.sortedBuildings, (b) => b.index == this.expandedBuildingIndex);
                        if (building) {
                            this.selectedPersonnelUsers.push(this.getPersonnelViewItem(user));
                            this.availablePersonnelUsers.push(this.getPersonnelViewItem(user));
                            this.$timeout(() => {
                                const employee = <aq.common.models.Employee>{ user };
                                building.personnel.push(employee);
                                this.validateUsersAssignment();
                            }, 0);
                        }
                    }
                    if ((updatedUser as any).isAllBuildings) {
                        _.each(this.accountInfoObject.buildings, (building: aq.common.models.TransientBuilding) => {
                            if (!_.find(building.personnel, (employee: aq.common.models.Employee) => employee.user.index == user.index)) {
                                if (this.expandedBuildingIndex != building.index) {
                                    const employee = <aq.common.models.Employee>{ user };
                                    building.personnel.push(employee);
                                }
                            }
                        });
                    } else if ((updatedUser as any).buildings) {
                        _.each((updatedUser as any).buildings, (buildingViewItem) => {
                            const building = _.find(this.sortedBuildings, (b) => b.index == buildingViewItem.id);
                            if (!building) {
                                return;
                            }
                            const employee = <aq.common.models.Employee>{ user };
                            building.personnel.push(employee);
                        });
                    }
                })
                .catch(() => {
                    if (isNew) {
                        this.removeAssociatedPersonnel(user);
                        _.remove(this.accountInfoObject.users, user);
                    }
                }).finally(() => {
                    this.validateUsersAssignment();
                });
        }
        public updatePersonnel(person: aq.user.User): void {
            _.each(this.accountInfoObject.buildings, (building: aq.common.models.TransientBuilding) => {
                const personIndex = _.findIndex(building.personnel, (employee: aq.common.models.Employee) => employee.user.index == person.index);
                if (personIndex >= 0) {
                    building.personnel[personIndex].user.firstName = person.firstName;
                    building.personnel[personIndex].user.lastName = person.lastName;
                    building.personnel[personIndex].user.index = person.index;
                    building.personnel[personIndex].user.email = person.email;
                    building.personnel[personIndex].user.phoneNumber = person.phoneNumber;
                    building.personnel[personIndex].user.id = person.id;
                    building.personnel[personIndex].user.persona = person.persona;
                }
            });
        }
        public removeAssociatedPersonnel(person): void {
            _.each(this.accountInfoObject.buildings, (building: aq.common.models.TransientBuilding) => {
                _.remove(building.personnel, (p) => p.user.index == person.index);
            });
            _.each(this.sortedBuildings, (building: aq.common.models.TransientBuilding) => {
                _.remove(building.personnel, (p) => p.user.index == person.index);
            });
        }
        public onUserSelect(employee) {
            const user = _.find(this.accountInfoObject.users, (usr) => usr.index == employee.user.index);
            this.editUser(user, false, false);
        }
        public onUserRemove(employee) {
            _.remove(this.selectedPersonnelUsers, (p) => p.id == employee.user.index);
            this.validateUsersAssignment();
        }
        public validateUsersAssignment() {
            this.unassignedUsers = _.filter(this.accountInfoObject.users, (user: aq.user.User) => {
                return _.every(this.accountInfoObject.buildings, (building: aq.common.models.TransientBuilding) => {
                    return _.findIndex(building.personnel, (employee: aq.common.models.Employee) => employee.user.index === user.index) == -1;
                });
            });
            const isValid = this.unassignedUsers.length == 0;
            this.isAllUsersAssigned = isValid;
            this.expandUnassigned.personnel = this.getUnassignedUsersFormatted();
        }
        public getUnassignedUsersFormatted() {
            const limit = 5;
            const users = this.expandUnassigned.expanded ? this.unassignedUsers : _.take(this.unassignedUsers, limit);
            let formattedUsers = _.map(users, (user: aq.user.User) => `${user.firstName} ${user.lastName}`).join(', ');
            if (!this.expandUnassigned.expanded && this.unassignedUsers.length > limit) {
                formattedUsers = `${formattedUsers}, and ${this.unassignedUsers.length - limit} more`;
            }
            return formattedUsers;
        }
        public toggleUnassignedPersonnelView() {
            this.expandUnassigned.expanded = !this.expandUnassigned.expanded;
            this.expandUnassigned.personnel = this.getUnassignedUsersFormatted();
        }

        public isImportDataValid() {
            const hasInvalidBuildings = _.some(_.map(this.invalidBuildings));
            return this.isAllUsersAssigned && !hasInvalidBuildings;
        }

        private processUsers() {
            // default role based on the department?
            const defaultRole = this.getManagerRole();
            _.each(this.accountInfoObject.users, (user: aq.user.User, index: number) => {
                user.index = index;
                user.role = defaultRole;
                this.indexPersonnel(user);
            });
        }
        private processBuildings() {
            _.each(this.accountInfoObject.buildings, (building: aq.common.models.TransientBuilding, index: number) => {
                building.index = index;
            });
            this.sortedBuildings = _.sortBy(this.accountInfoObject.buildings, ['name']);
        }
        private getManagerRole(): aq.user.Role {
            return this.roles.find(role => role.name.includes('Manager')) || undefined;
        }
        private indexPersonnel(user: aq.user.User) {
            _.each(this.accountInfoObject.buildings, (building: aq.common.models.TransientBuilding) => {
                _.each(building.personnel, (employee: aq.common.models.Employee) => {
                    if (!employee.id && !user.id && employee.user.email && employee.user.email.toLowerCase() == user.email.toLowerCase()) {
                        employee.user.index = user.index;
                    } else if (employee.user.id === user.id) {
                        employee.user.index = user.index;
                    }
                });
            });
        }
        private catchError(error): void {
            switch (error.status) {
                case 400:
                    this.errorMessage = error.data;
                    this.Messages.error(error.data);
                    break;
                default:
                    this.errorMessage = 'Error';
                    this.Messages.error('Error');
                    break;
            }
        }
        private getPersonnelViewItems(employees: aq.common.models.Employee[]) {
            return _.map(employees, (employee) => this.getPersonnelViewItem(employee.user));
        }
        private getPersonnelViewItemsFromUsers(users: aq.user.User[]) {
            return _.map(users, (user) => this.getPersonnelViewItem(user));
        }
        private getPersonnelViewItem(user: aq.user.User) {
            return {
                id: user.index,
                name: user.firstName + ' ' + user.lastName
            };
        }
        private hideChipsInput() {
            this.$timeout(() => {
                this.$element.find('md-chips input').hide();
            }, 0);
        }
        private scrollBuildingHeaderIntoView() {
            const headerElement = this.$element.find('.building-item.expanded .building-header');
            if (headerElement.length > 0 && headerElement[0].scrollIntoView) {
                headerElement[0].scrollIntoView();
                window.scrollBy(0, -74);
            }
        }
        private isMissingBuildingData(building: aq.common.models.TransientBuilding) {
            return building.name == null
                || building.address == null
                || building.city == null
                || building.state == null
                || building.zipCode == null
                || !building.size;
        }
    }

    export class AccountInfoObject {
        public buildings: aq.common.models.TransientBuilding[];
        public users: aq.user.User[];
        public projectLead: aq.user.User;
        public roles: aq.user.Role[];
        public isValidBuildingList: boolean;
        public isValidUserList: boolean;
        public emailScheduledDate: Date;
    }

    angular
        .module('aq.admin.accounts')
        .controller('AccountNewCtrl', AccountNewCtrl);
}


