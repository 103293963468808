namespace aq.services {

    export class MediaService {
        private static readonly BUCKET = 'aq-activities-media';
        /* @ngInject */
        constructor(
            private $q: ng.IQService
        ) { }

        private getS3Path(activityID: string, messageID: string): string {
            return `activity-${activityID}/message-${messageID}/`;
        };

        private getMediaType(mimetype: string) {
            if (mimetype.includes('image')) {
                return aq.models.activity.MediaType.IMAGE;
            }
            if (mimetype.includes('xls')) {
                return aq.models.activity.MediaType.EXCEL;
            }
            return aq.models.activity.MediaType.PDF
        };

        public pickPhoto(activityID: string, messageID: string): ng.IPromise<aq.models.activity.MediaResponse[]> {
            const defer = this.$q.defer();
            const path = this.getS3Path(activityID, messageID);
            const options = {
                maxFiles: 5,
                accept: [
                    'image/jpeg',
                    'image/jpg',
                    'image/png',
                    'image/bmp',
                    'image/gif'
                ],
                cleanupImageExif: {
                    keepOrientation: true,
                    keepICCandAPP: true
                },
                onFileSelected: (file) => {
                    // @ts-ignore
                    return { ...file, name: `${uuidv4()}.${file.mimetype.split('/')[1]}` };
                },
                storeTo: {
                    container: MediaService.BUCKET,
                    path,
                    region: 'us-east-1'
                },
                fromSources: [
                    'local_file_system'
                ],
                uploadInBackground: false,
                onCancel: () => {
                    defer.reject('Cancelled');
                },
                onUploadDone: (res: MediaPickerResult) => {
                    if (res.filesUploaded && res.filesUploaded.length > 0) {
                        const files = res.filesUploaded.map((file) => {
                            return {
                                type: this.getMediaType(file.mimetype),
                                uri: file.url
                            }
                        });
                        defer.resolve(files);
                    }
                }
            };

            // _filestackClient is initialized in app/main.html
            // @ts-ignore
            const picker = _filestackClient.picker(options);
            picker.open();
            return defer.promise as ng.IPromise<aq.models.activity.MediaResponse[]>;
        }
    }

    angular
        .module('aq.services')
        .service('mediaService', MediaService);
}
