namespace aq.dashboard.widgets {
    export class TableEditService extends aq.services.BuildingSelectorActions {
        private TREND_APPEND = '_TREND';
        /* @ngInject */
        constructor(
            protected DashboardOptionsService: DashboardOptionsService,
            protected $translate
        ) {
            super(DashboardOptionsService, $translate);
        }
        public onBuildingIdsChange(context: BaseTableConfigModel, data: number[]) {
            super.onBuildingIdsChange(context, data);
            const cfg = context;
            if (cfg.breakdown == 'BUILDING') {
                const isIntervalBreakdownInvalid = !cfg.buildingIds || cfg.buildingIds.length != 1;
                if (isIntervalBreakdownInvalid) {
                    cfg.isIntervalBreakdown = false;
                    cfg.interval = null;
                } else if (!cfg.isIntervalBreakdown) {
                    cfg.isIntervalBreakdown = true;
                    cfg.interval = 'default';
                    cfg.isStarEnergyRating = false;
                }
            }
            this.generateDefaultTableTitle(cfg);
        }
        public onSingleBuildingChange(context: BaseTableConfigModel, data: number) {
            const cfg = context;
            cfg.view.buildingId = data;
            if (cfg.view.buildingId == null) {
                this.onBuildingIdsChange(cfg, []);
                return;
            }
            cfg.buildingIds = [cfg.view.buildingId];
            cfg.drilldown = null;
            cfg.drilldownItems = [];
            cfg.drilldownItemsSelectionMode = 'individual';
            cfg.isIntervalBreakdown = false;
            cfg.interval = null;
            cfg.options.drilldownOptions = this.getDrilldownOptions(cfg.measures, cfg.breakdown, cfg.buildingIds, cfg);
        }
        public onTimePeriodChange(cfg: BaseTableConfigModel) {
            if (cfg.trend) {
                cfg.trendPeriod = TrendPeriods.getTrendPeriodForDatePeriod(cfg.timePeriod);
            }
            this.generateDefaultTableTitle(cfg);
        }
        public onBreakdownChange(cfg: BaseTableConfigModel) {
            cfg.drilldown = null;
            cfg.drilldownItems = [];
            cfg.isIntervalBreakdown = false;
            if (cfg.breakdown == 'INTERVAL') {
                cfg.interval = 'default';
            } else {
                cfg.interval = null;
            }
            if (cfg.breakdown != 'BUILDING') {
                cfg.isStarEnergyRating = false;
            }

            cfg.buildingGroupId = null;
            if (cfg.breakdown == 'BUILDING') {
                cfg.buildingSelectionMode = 'individual';
                this.onBuildingIdsChange(cfg, []);
            } else {
                cfg.drilldownItemsSelectionMode = 'individual';
                this.onSingleBuildingChange(cfg, null);
            }

            this.getAvailableSortBys(cfg);
            this.generateDefaultTableTitle(cfg);
        }
        public onDrilldownItemsSelectionModeChange(cfg: BaseTableConfigModel) {
            let drilldownItems = [];
            switch (cfg.drilldownItemsSelectionMode) {
                case 'all':
                    const options = this.getDrilldownOptions(cfg.measures, cfg.breakdown, cfg.buildingIds, cfg);
                    drilldownItems = options;
                    break;
                case 'individual':
                    drilldownItems = [];
                    break;
                default:
                    break;
            }
            this.onQueryableChange(cfg, drilldownItems);
        }
        public onTrendChange(cfg: BaseTableConfigModel) {
            cfg.trendPeriod = cfg.trend ? TrendPeriods.getTrendPeriodForDatePeriod(cfg.timePeriod) : null;
            this.getAvailableSortBys(cfg);
        }
        public onQueryableChange(context: BaseTableConfigModel, data: any[]) {
            const cfg = context;
            cfg.drilldownItems = data;
            if (cfg.drilldownItems && cfg.drilldownItems.length == 1) {
                cfg.drilldown = cfg.drilldownItems[0];
                cfg.isIntervalBreakdown = true;
                if (cfg.interval == null) {
                    cfg.interval = 'default';
                }
            } else {
                cfg.drilldown = null;
                cfg.isIntervalBreakdown = false;
                cfg.interval = null;
            }
            this.generateDefaultTableTitle(cfg);
        }
        public onShowTableTitleChange(cfg: BaseTableConfigModel) {
            this.generateDefaultTableTitle(cfg);
        }
        public onIsCustomTableTitleChange(cfg: BaseTableConfigModel) {
            this.generateDefaultTableTitle(cfg);
        }
        public onMeasuresChange(cfg: BaseTableConfigModel) {
            if (cfg.sortBy != 'BREAKDOWN') {
                const isSortByMatchingMeasureOrTrend = _.some(cfg.measures, (measure) => {
                    return cfg.sortBy == measure
                        || cfg.trend && cfg.sortBy == this.getTrendPercentCol(this.formatTrendLabel(measure));
                });
                const isSortBySelectedEnergyStar = cfg.isStarEnergyRating &&
                    (cfg.sortBy == 'energyStarScore' || cfg.sortBy == 'energyStarScoreTrend' && cfg.trend);
                if (!isSortByMatchingMeasureOrTrend && !isSortBySelectedEnergyStar) {
                    cfg.sortBy = null;
                }
            }
            cfg.options.drilldownOptions = this.getDrilldownOptions(cfg.measures, cfg.breakdown, cfg.buildingIds, cfg);
            this.getAvailableSortBys(cfg);
            this.generateDefaultTableTitle(cfg);
        }
        public getDrilldownOptions(measures: string[], breakdown: string, buildingIds: number[], cfg: BaseTableConfigModel) {
            let breakdownOptions = _.flatten(_.map(buildingIds, (buildingId) => {
                return cfg.options.breakdownOptions[breakdown][buildingId];
            }));
            breakdownOptions = _.filter(breakdownOptions, (b) => b && b.id);
            breakdownOptions = _.uniqBy(breakdownOptions, (item) => item.id);
            const units = _.flatten(_.map(measures, (measure) => this.DashboardOptionsService.getUnitByEnumName(measure)));
            const drilldownOptions = _.filter(breakdownOptions, (drilldown: any) => {
                return _.some(units, (unit) => _.includes(drilldown.metrics, unit.serviceType))
            });
            return drilldownOptions;
        }
        public getAvailableSortBys(cfg: BaseTableConfigModel) {
            const availableOptions = {};
            const sortByOptions = cfg.breakdown == 'BUILDING' ? cfg.options.allSortBys : cfg.options.sortBys;
            _.each(sortByOptions, (measureItems, key) => {
                if (key == 'GENERAL') {
                    availableOptions['GENERAL'] = measureItems;
                    return;
                }
                if (key == 'BUILDING') {
                    const availableBuildingAttributes = _.filter(measureItems, (item) => {
                        return cfg.isStarEnergyRating && (item.value == 'energyStarScore' || item.value == 'energyStarScoreTrend' && cfg.trend);
                    });
                    if (availableBuildingAttributes.length > 0) {
                        availableOptions[key] = availableBuildingAttributes;
                    }
                    return;
                }
                const selectedMeasures = _.filter(measureItems, (item) => _.some(cfg.measures, (measure) => {
                    return item.value == measure ||
                        cfg.trend && item.value == this.getTrendPercentCol(this.formatTrendLabel(measure));
                }));
                if (selectedMeasures.length > 0) {
                    availableOptions[key] = selectedMeasures;
                }
            });
            cfg.view.availableSortBys = availableOptions;
        }
        public getTrendPercentCol(trend) {
            return trend + '_PERCENT';
        }
        public generateDefaultTableTitle(cfg: BaseTableConfigModel) {
            if (cfg.isCustomTitle) {
                return;
            }
            const units = _.map(cfg.measures, (m) => this.getMeasureUnit(m));
            const unitNames = _.filter(units, (u) => u).join(', ');
            const timePeriod = textural(cfg.timePeriod).format('capitalizehuman');
            let title = `${unitNames} - ${this.$translate.instant('timeperiod_case3.' + timePeriod)}`;
            if (cfg.drilldownItems && cfg.drilldownItems.length == 1) {
                const queryableName = _.first(cfg.drilldownItems).name;
                title = `${queryableName} - ${title}`;
            } else if (cfg.buildingIds && cfg.buildingIds.length == 1) {
                const building = _.find(cfg.options.buildings, (b) => b.id == _.first(cfg.buildingIds));
                if (building && building.name) {
                    title = `${building.name} - ${title}`;
                }
            } else if (cfg.breakdown == 'BUILDING') {
                if (cfg.buildingSelectionMode == 'all') {
                    title = `${this.$translate.instant('labels.All buildings')} - ${title}`;
                } else if (cfg.buildingSelectionMode == 'group' && cfg.buildingGroupId) {
                    const group = _.find(cfg.options.buildingGroups, (g) => g.label == 'Regions');
                    if (group && group.items) {
                        const item = _.find(group.items, (gi) => gi.id == cfg.buildingGroupId);
                        if (item && item.label) {
                            title = `${item.label} - ${title}`;
                        }
                    }
                }
            }
            cfg.titleText = title;
        }
        private getMeasureUnit = (measure: string) => {
            const unit = this.DashboardOptionsService.getUnitByEnumName(measure);
            if (unit) {
                return unit.unit;
            }
            return '';
        }
        private formatTrendLabel(measure) {
            return measure + this.TREND_APPEND;
        }
    }
    angular.module('aq.dashboard.widgets').service('TableEditService', TableEditService);
}
