/* eslint-disable max-len */
var aq;
(function (aq) {
    var dashboard;
    (function (dashboard) {
        var widgets;
        (function (widgets) {
            var DataItemType;
            (function (DataItemType) {
                DataItemType["VALUE"] = "VALUE";
                DataItemType["VARIANCE"] = "VARIANCE";
            })(DataItemType || (DataItemType = {}));
            var Comparison;
            (function (Comparison) {
                Comparison["PREVIOUS_PERIOD"] = "PREVIOUS_PERIOD";
                Comparison["SAME_MONTH_PRIOR_YEAR"] = "SAME_MONTH_PRIOR_YEAR";
                Comparison["TARGETS_AND_BUDGETS"] = "TARGETS_AND_BUDGETS";
                Comparison["NONE"] = "NONE";
            })(Comparison || (Comparison = {}));
            var TimePeriod;
            (function (TimePeriod) {
                TimePeriod["LAST_MONTH"] = "LAST_MONTH";
                TimePeriod["CURRENT_MONTH"] = "CURRENT_MONTH";
                TimePeriod["LAST_YEAR"] = "LAST_YEAR";
                TimePeriod["CURRENT_YEAR"] = "CURRENT_YEAR";
            })(TimePeriod || (TimePeriod = {}));
            var PortfolioVarianceCtrl = /** @class */ (function () {
                /* @ngInject */
                function PortfolioVarianceCtrl($scope, config, account, buildings, buildingGroups, ModelUtilService, DashboardOptionsService, BuildingSelectorActions, EnergyInsightsDataService, DataStore, Restangular, $q, Messages) {
                    var _this = this;
                    this.$scope = $scope;
                    this.config = config;
                    this.account = account;
                    this.buildings = buildings;
                    this.buildingGroups = buildingGroups;
                    this.ModelUtilService = ModelUtilService;
                    this.DashboardOptionsService = DashboardOptionsService;
                    this.BuildingSelectorActions = BuildingSelectorActions;
                    this.EnergyInsightsDataService = EnergyInsightsDataService;
                    this.DataStore = DataStore;
                    this.Restangular = Restangular;
                    this.$q = $q;
                    this.Messages = Messages;
                    this.displayBuildingsCount = 5;
                    this.promises = [];
                    this.loadingPercent = 0;
                    this.sortOrder = 'asc';
                    this.unitOrder = { '$': 1, 'kWh': 2, 'kW': 3 };
                    this.selectedBuildingNames = [];
                    if (typeof this.config.comparison === 'string') {
                        var originalComp = this.config.comparison;
                        this.config.comparison = [];
                        if (originalComp !== Comparison.NONE) {
                            this.config.comparison.push(originalComp);
                        }
                    }
                    if (!this.config.comparison) {
                        this.config.comparison = [Comparison.PREVIOUS_PERIOD];
                    }
                    if (this.config.comparison.includes(Comparison.NONE)) {
                        this.config.comparison = [];
                    }
                    if (!this.config.timePeriod) {
                        this.config.timePeriod = TimePeriod.LAST_MONTH;
                    }
                    var timePresets = [
                        {
                            value: TimePeriod.LAST_MONTH,
                            label: 'Last month'
                        },
                        {
                            value: TimePeriod.CURRENT_MONTH,
                            label: 'Current month'
                        },
                        {
                            value: TimePeriod.LAST_YEAR,
                            label: 'Last year'
                        },
                        {
                            value: TimePeriod.CURRENT_YEAR,
                            label: 'Current year'
                        },
                    ];
                    var comparisons = [
                        {
                            value: Comparison.PREVIOUS_PERIOD,
                            id: Comparison.PREVIOUS_PERIOD,
                            name: "Previous " + (this.config.timePeriod.indexOf('_MONTH') > -1 ? 'month' : 'year'),
                            disabled: false
                        },
                        {
                            value: Comparison.SAME_MONTH_PRIOR_YEAR,
                            id: Comparison.SAME_MONTH_PRIOR_YEAR,
                            name: 'Same month prior year',
                            disabled: this.config.timePeriod.indexOf('_MONTH') === -1
                        },
                        {
                            value: Comparison.TARGETS_AND_BUDGETS,
                            id: Comparison.TARGETS_AND_BUDGETS,
                            name: 'Targets & Budgets',
                            disabled: false
                        }
                    ];
                    this.config.options = {
                        buildings: this.ModelUtilService.pareProperties(this.buildings, ['buildingGroup']),
                        buildingGroups: this.ModelUtilService.pareProperties(this.buildingGroups),
                        timePresets: timePresets,
                        comparisons: comparisons
                    };
                    this.BuildingSelectorActions.initDefaultBuildingSelection(this.config);
                    this.config.actions = this.BuildingSelectorActions;
                    this.config.actions.onTimePeriodChange = function (config) {
                        if (config.timePeriod.indexOf('_MONTH') > -1) {
                            config.options.comparisons[0].name = 'Previous month';
                            config.options.comparisons[1].disabled = false;
                        }
                        else {
                            config.options.comparisons[0].name = 'Previous year';
                            config.options.comparisons[1].disabled = true;
                        }
                    };
                    this.config.actions.onComparisonChange = function (config, data) {
                        if (!data || data.length === 0) {
                            config.isVariance = false;
                        }
                        config.comparison = data;
                    };
                    if (this.config.showBuildingImage == null) {
                        this.config.showBuildingImage = true;
                    }
                    this.$scope.config = this.config;
                    this.initOptions()
                        .then(function () {
                        _this.$scope.selectedMeasures = _this.getSelectedMeasures(_this.$scope.config.measures);
                        _this.initDataItems();
                        _this.getData();
                        _this.initDisplayBuildings();
                    });
                }
                PortfolioVarianceCtrl.prototype.initDisplayBuildings = function () {
                    this.displayBuildings = _.take(this.selectedBuildingNames, this.displayBuildingsCount);
                };
                PortfolioVarianceCtrl.prototype.getData = function () {
                    var _this = this;
                    this.isLoadingData = true;
                    var buildingIds = this.$scope.config.buildingIds;
                    this.selectedBuildingNames = buildingIds.map(function (buildingId) {
                        var building = _this.buildings.find(function (b) { return b.id === buildingId; });
                        return building.name;
                    });
                    this.$scope.selectedMeasures.forEach(function (measure) {
                        buildingIds.forEach(function (buildingId) {
                            var _a;
                            var building = _this.buildings.find(function (b) { return b.id === buildingId; });
                            var period = _this.getTimePeriodForBuilding(building);
                            if (measure.indexOf('CURRENCY_') > -1) {
                                _this.promises.push(_this.EnergyInsightsDataService.getUtilitySpendingCostBreakdown(building, period.start, period.end, '1mon', measure.replace('CURRENCY_', '')).then(function (response) {
                                    var value = _this.getUtilitySpendingResponseValue(response);
                                    _this.populateValue(buildingId, measure, value);
                                }));
                            }
                            else {
                                var metric = _this.getMeasureMetric(measure);
                                if (measure === 'KW') {
                                    var metricRequests = _this.EnergyInsightsDataService.getPeakDataPromises(building, period.start, period.end, metric, _this.account);
                                    if (metricRequests.length > 0) {
                                        (_a = _this.promises).push.apply(_a, metricRequests);
                                        _this.$q.all(metricRequests).then(function (responses) {
                                            var maxValue = 0;
                                            responses.forEach(function (response) {
                                                var value = _this.getPeakDemandResponseValue(response);
                                                if (value > maxValue) {
                                                    maxValue = value;
                                                }
                                            });
                                            _this.populateValue(buildingId, measure, maxValue);
                                        });
                                    }
                                }
                                else {
                                    _this.promises.push(_this.EnergyInsightsDataService.getEnergy(building, period.start, period.end, metric, '1mon', _this.account).then(function (data) {
                                        var value = _this.getConsumptionOrBudgetItemsResponseValue(data);
                                        if (_this.$scope.config.isProjections && _this.$scope.config.timePeriod.indexOf('CURRENT') > -1) {
                                            // WORKAROUND: get consumption projections from utility spending projections for current month
                                            var currentMonthStart = aq.common.TimeConfiguration.This.Month.start(building.timeZoneId);
                                            return _this.EnergyInsightsDataService.getUtilitySpendingCostBreakdown(building, currentMonthStart, period.end, '1mon', 'ELECTRICITY').then(function (response) {
                                                var projectedValue = 0;
                                                if (response.spending && response.spending.data) {
                                                    response.spending.data.forEach(function (d) {
                                                        projectedValue += (d.datum.projected || 0) / d.datum.blendedRate;
                                                    });
                                                }
                                                return value + projectedValue;
                                            });
                                        }
                                        return _this.$q.when(value);
                                    }).then(function (value) {
                                        _this.populateValue(buildingId, measure, value);
                                    }));
                                }
                            }
                            _this.$scope.period = period;
                            if (_this.$scope.config.comparison.includes(Comparison.PREVIOUS_PERIOD)) {
                                var trendPeriod = _this.getTrendPeriod(period, Comparison.PREVIOUS_PERIOD);
                                if (measure.indexOf('CURRENCY_') > -1) {
                                    _this.promises.push(_this.EnergyInsightsDataService.getUtilitySpendingCostBreakdown(building, trendPeriod.start, trendPeriod.end, '1mon', measure.replace('CURRENCY_', '')).then(function (response) {
                                        var value = _this.getUtilitySpendingResponseValue(response);
                                        _this.populateValue(buildingId, _this.getMeasureReferenceIdentifier(measure, Comparison.PREVIOUS_PERIOD), value);
                                    }));
                                }
                                else {
                                    var metric = _this.getMeasureMetric(measure);
                                    if (measure === 'KW') {
                                        if (_this.$scope.config.timePeriod.indexOf('_MONTH') > -1) {
                                            var metricRequests = _this.EnergyInsightsDataService.getPeakDataPromises(building, trendPeriod.start, trendPeriod.end, metric, _this.account);
                                            if (metricRequests.length > 0) {
                                                _this.promises.push(metricRequests[0]);
                                                metricRequests[0].then(function (response) {
                                                    var value = _this.getPeakDemandResponseValue(response);
                                                    _this.populateValue(buildingId, _this.getMeasureReferenceIdentifier(measure, Comparison.PREVIOUS_PERIOD), value);
                                                });
                                            }
                                        }
                                    }
                                    else {
                                        _this.promises.push(_this.EnergyInsightsDataService.getEnergy(building, trendPeriod.start, trendPeriod.end, metric, '1mon', _this.account).then(function (data) {
                                            var value = _this.getConsumptionOrBudgetItemsResponseValue(data);
                                            _this.populateValue(buildingId, _this.getMeasureReferenceIdentifier(measure, Comparison.PREVIOUS_PERIOD), value);
                                        }));
                                    }
                                }
                                _this.$scope.trendPeriod = trendPeriod;
                            }
                            if (_this.$scope.config.comparison.includes(Comparison.SAME_MONTH_PRIOR_YEAR)) {
                                var trendPeriod = _this.getTrendPeriod(period, Comparison.SAME_MONTH_PRIOR_YEAR);
                                if (measure.indexOf('CURRENCY_') > -1) {
                                    _this.promises.push(_this.EnergyInsightsDataService.getUtilitySpendingCostBreakdown(building, trendPeriod.start, trendPeriod.end, '1mon', measure.replace('CURRENCY_', '')).then(function (response) {
                                        var value = _this.getUtilitySpendingResponseValue(response);
                                        _this.populateValue(buildingId, _this.getMeasureReferenceIdentifier(measure, Comparison.SAME_MONTH_PRIOR_YEAR), value);
                                    }));
                                }
                                else {
                                    var metric = _this.getMeasureMetric(measure);
                                    if (measure === 'KW') {
                                        if (_this.$scope.config.timePeriod.indexOf('_MONTH') > -1) {
                                            var metricRequests = _this.EnergyInsightsDataService.getPeakDataPromises(building, trendPeriod.start, trendPeriod.end, metric, _this.account);
                                            if (metricRequests.length > 0) {
                                                _this.promises.push(metricRequests[0]);
                                                metricRequests[0].then(function (response) {
                                                    var value = _this.getPeakDemandResponseValue(response);
                                                    _this.populateValue(buildingId, _this.getMeasureReferenceIdentifier(measure, Comparison.SAME_MONTH_PRIOR_YEAR), value);
                                                });
                                            }
                                        }
                                    }
                                    else {
                                        _this.promises.push(_this.EnergyInsightsDataService.getEnergy(building, trendPeriod.start, trendPeriod.end, metric, '1mon', _this.account).then(function (data) {
                                            var value = _this.getConsumptionOrBudgetItemsResponseValue(data);
                                            _this.populateValue(buildingId, _this.getMeasureReferenceIdentifier(measure, Comparison.SAME_MONTH_PRIOR_YEAR), value);
                                        }));
                                    }
                                }
                                _this.$scope.trendPeriod = trendPeriod;
                            }
                            if (_this.$scope.config.comparison.includes(Comparison.TARGETS_AND_BUDGETS)) {
                                if (!_this.$scope.config.comparison.includes(Comparison.SAME_MONTH_PRIOR_YEAR) && !_this.$scope.config.comparison.includes(Comparison.PREVIOUS_PERIOD)) {
                                    _this.$scope.trendPeriod = null;
                                }
                                if (measure.indexOf('CURRENCY_') > -1) {
                                    _this.promises.push(_this.EnergyInsightsDataService.getBudgetItems(building, measure.replace('CURRENCY_', '').toLowerCase(), period.start, period.end, '1mon', _this.account).then(function (budgetData) {
                                        var value = _this.getConsumptionOrBudgetItemsResponseValue(budgetData, period.end.date(), building.timeZoneId);
                                        _this.populateValue(buildingId, _this.getMeasureReferenceIdentifier(measure, Comparison.TARGETS_AND_BUDGETS), value);
                                    }));
                                }
                                else if (measure === 'KWH') { // currently targets exist only for 'kW and kWh'
                                    var metric = _this.getMeasureMetric(measure);
                                    _this.promises.push(_this.EnergyInsightsDataService.getTargetConsumption(building, period.start, period.end, metric, '1mon', _this.account).then(function (targetConsumptionData) {
                                        var value = _this.getConsumptionOrBudgetItemsResponseValue(targetConsumptionData, period.end.date(), building.timeZoneId);
                                        _this.populateValue(buildingId, _this.getMeasureReferenceIdentifier(measure, Comparison.TARGETS_AND_BUDGETS), value);
                                    }));
                                }
                                else if (measure === 'KW') {
                                    if (_this.$scope.config.timePeriod.indexOf('_MONTH') > -1) {
                                        _this.promises.push(_this.DataStore.getList(_this.Restangular
                                            .one('accounts', _this.account.id)
                                            .one('buildings', building.id)
                                            .one('Targets'), 'queryTargets', {
                                            startDate: period.start.format(),
                                            endDate: period.end.format(),
                                            targetType: 'PEAK_DEMAND'
                                        }).then(function (targets) {
                                            var value = _this.getPeakDemandTargetValue(targets);
                                            _this.populateValue(buildingId, _this.getMeasureReferenceIdentifier(measure, Comparison.TARGETS_AND_BUDGETS), value);
                                        }));
                                    }
                                }
                            }
                        });
                    });
                    var totalPromises = this.promises.length;
                    var completedPromises = 0;
                    _.each(this.promises, function (p) {
                        p.finally(function () {
                            completedPromises++;
                            _this.loadingPercent = Math.round(completedPromises * 100 / totalPromises);
                            if (completedPromises === totalPromises) {
                                _this.isLoadingData = false;
                            }
                        });
                    });
                };
                PortfolioVarianceCtrl.prototype.toggleSortByField = function (index) {
                    if (index === this.sortBy) {
                        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
                    }
                    else {
                        this.sortBy = index;
                        this.sortOrder = 'asc';
                    }
                    var items = this.$scope.buildingItems;
                    this.sortBuildingItemsByDataItemFieldIndex(items, index);
                };
                PortfolioVarianceCtrl.prototype.sortBuildingItemsByDataItemFieldIndex = function (items, index) {
                    var _this = this;
                    items.sort(function (a, b) {
                        var value = 0;
                        if (a.dataItems[index].type === DataItemType.VALUE) {
                            value = _this.getOrderByDataItemValue(a.dataItems[index], b.dataItems[index]);
                        }
                        else if (a.dataItems[index].type === DataItemType.VARIANCE) {
                            value = _this.getOrderByDataItemVariance(a.dataItems[index], b.dataItems[index]);
                        }
                        if (_this.sortOrder === 'desc') {
                            value = -1 * value;
                        }
                        return value;
                    });
                };
                PortfolioVarianceCtrl.prototype.getOrderByDataItemValue = function (dataItemA, dataItemB) {
                    var value;
                    if (!dataItemA.value) {
                        value = this.sortOrder === 'desc' ? -1 : 1;
                    }
                    else if (!dataItemB.value) {
                        value = this.sortOrder === 'desc' ? 1 : -1;
                    }
                    else {
                        value = Math.sign(dataItemA.value - dataItemB.value);
                    }
                    return value;
                };
                PortfolioVarianceCtrl.prototype.getOrderByDataItemVariance = function (dataItemA, dataItemB) {
                    var varianceItemA = dataItemA.value;
                    var varianceItemB = dataItemB.value;
                    var value;
                    if (!varianceItemA.mainItem.value || !varianceItemA.referenceItem.value) {
                        value = this.sortOrder === 'desc' ? -1 : 1;
                    }
                    else if (!varianceItemB.mainItem.value || !varianceItemB.referenceItem.value) {
                        value = this.sortOrder === 'desc' ? 1 : -1;
                    }
                    else {
                        var deltaA = varianceItemA.mainItem.value - varianceItemA.referenceItem.value;
                        var ratioA = deltaA / varianceItemA.referenceItem.value;
                        var deltaB = varianceItemB.mainItem.value - varianceItemB.referenceItem.value;
                        var ratioB = deltaB / varianceItemB.referenceItem.value;
                        value = Math.sign(ratioA - ratioB);
                    }
                    return value;
                };
                PortfolioVarianceCtrl.prototype.getPeakDemandResponseValue = function (data) {
                    var max = null;
                    if (data && data.values) {
                        data.values.forEach(function (v) {
                            if (v > 0 && (max == null || max < v)) {
                                max = v;
                            }
                        });
                    }
                    return max ? Math.round(max / 1000) : null;
                };
                PortfolioVarianceCtrl.prototype.getPeakDemandTargetValue = function (data) {
                    if (data && data.length > 0) {
                        return (data[0].value || 0);
                    }
                    return null;
                };
                PortfolioVarianceCtrl.prototype.getConsumptionOrBudgetItemsResponseValue = function (data, currentDay, timezone) {
                    var _this = this;
                    if (currentDay === void 0) { currentDay = null; }
                    if (timezone === void 0) { timezone = null; }
                    var value = 0;
                    if (data) {
                        data.forEach(function (d, index) {
                            if (d) {
                                if (index === data.length - 1 && !_this.$scope.config.isProjections && currentDay && timezone) {
                                    // calculate ratio of the last item => current month without projections
                                    var selectedMonth = moment(d['endDate']);
                                    var totalDays = selectedMonth.daysInMonth();
                                    var totalMinutesInDay = 1440;
                                    var currentMinutes = moment().tz(timezone).hours() * 60 + moment().tz(timezone).minutes();
                                    var currentDayRatio = (currentMinutes - 1) / totalMinutesInDay;
                                    var totalRatio = currentDay === totalDays ? currentDay / totalDays : (currentDay - 1 + currentDayRatio) / totalDays;
                                    value += Math.round((d.value || 0) * totalRatio);
                                }
                                else {
                                    value += (d.value || 0);
                                }
                            }
                        });
                    }
                    return Math.round(value);
                };
                PortfolioVarianceCtrl.prototype.getUtilitySpendingResponseValue = function (response) {
                    var _this = this;
                    var value = 0;
                    if (response.spending && response.spending.data) {
                        response.spending.data.forEach(function (d) {
                            value += (d.datum.utilityBill || 0) + (d.datum.consumption || 0);
                            if (_this.$scope.config.isProjections) {
                                value += (d.datum.projected || 0);
                            }
                        });
                    }
                    return value;
                };
                PortfolioVarianceCtrl.prototype.getTrendPeriod = function (mainPeriod, comparison) {
                    var timePeriod = this.$scope.config.timePeriod;
                    var period = {
                        start: null,
                        end: null
                    };
                    if (comparison === Comparison.PREVIOUS_PERIOD) {
                        if (timePeriod.indexOf('_MONTH') > -1) {
                            period.start = moment(mainPeriod.start).subtract(1, 'month');
                            period.end = moment(mainPeriod.end).subtract(1, 'month');
                        }
                        else if (timePeriod.indexOf('_YEAR') > -1) {
                            period.start = moment(mainPeriod.start).subtract(1, 'year');
                            period.end = moment(mainPeriod.end).subtract(1, 'year');
                        }
                    }
                    if (comparison === Comparison.SAME_MONTH_PRIOR_YEAR) {
                        if (timePeriod.indexOf('_MONTH') > -1) {
                            period.start = moment(mainPeriod.start).subtract(1, 'year');
                            period.end = moment(mainPeriod.end).subtract(1, 'year');
                        }
                    }
                    return period;
                };
                PortfolioVarianceCtrl.prototype.populateValue = function (buildingId, measure, value) {
                    var buildingItem = this.$scope.buildingItems.find(function (item) { return item.buildingId == buildingId; });
                    var dataItem = buildingItem.dataItems.find(function (item) { return item.type == DataItemType.VALUE && item.measure == measure; });
                    dataItem.value = value;
                };
                PortfolioVarianceCtrl.prototype.getMeasureMetric = function (measure) {
                    var allUnits = this.$scope.config.options.allUnits;
                    var result = null;
                    Object.keys(allUnits).forEach(function (key) {
                        var units = allUnits[key];
                        var metric = units ? units.find(function (unit) { return unit.value == measure; }) : null;
                        if (metric) {
                            result = metric;
                        }
                    });
                    return result;
                };
                PortfolioVarianceCtrl.prototype.getTimePeriodForBuilding = function (building) {
                    var tz = building.timeZoneId;
                    var period = {
                        start: null,
                        end: null
                    };
                    switch (this.$scope.config.timePeriod) {
                        case 'LAST_MONTH':
                            period.start = aq.common.TimeConfiguration.Last.Month.start(tz);
                            period.end = aq.common.TimeConfiguration.Last.Month.end(tz);
                            break;
                        case 'CURRENT_MONTH':
                            period.start = aq.common.TimeConfiguration.This.Month.start(tz);
                            period.end = this.$scope.config.isProjections ? aq.common.TimeConfiguration.This.Month.end(tz) : moment().tz(tz);
                            break;
                        case 'LAST_YEAR':
                            period.start = aq.common.TimeConfiguration.Last.Year.start(tz);
                            period.end = aq.common.TimeConfiguration.Last.Year.end(tz);
                            break;
                        case 'CURRENT_YEAR':
                            period.start = aq.common.TimeConfiguration.This.Year.start(tz);
                            // NOTE: we calculate projections only until the end of month, so we can't set interval at the end of year
                            period.end = this.$scope.config.isProjections ? aq.common.TimeConfiguration.This.Month.end(tz) : moment().tz(tz);
                            break;
                        default:
                            break;
                    }
                    return period;
                };
                PortfolioVarianceCtrl.prototype.initDataItems = function () {
                    var _this = this;
                    this.$scope.buildingItems = this.buildings
                        .filter(function (b) { return _this.$scope.config.buildingIds.indexOf(b.id) > -1; })
                        .map(function (building) {
                        return {
                            buildingId: building.id,
                            name: building.name,
                            imageUrl: building.imageUrl,
                            dataItems: _this.getEmptyDataItems(building.id)
                        };
                    });
                    this.$scope.headers = this.getHeaders();
                    this.$scope.headerGroups = this.getHeaderGroups();
                };
                PortfolioVarianceCtrl.prototype.getHeaders = function () {
                    var _this = this;
                    var headers = [];
                    this.$scope.selectedMeasures.forEach(function (m, index) {
                        var isLastMeasure = index === _this.$scope.selectedMeasures.length - 1;
                        var mainItemHeader = _this.getMainItemHeader(m);
                        headers.push({
                            name: mainItemHeader,
                            isBorder: !isLastMeasure && _this.$scope.config.comparison.length === 0,
                            measurement: m
                        });
                        _this.$scope.config.comparison.forEach(function (comp, i) {
                            var referenceItemHeader = _this.getReferenceItemHeader(m, comp);
                            var referenceLength = _this.$scope.selectedMeasures.length * _this.$scope.config.comparison.length;
                            var selectedMeasurePosition = index + 1;
                            var selectedCompPosition = i + 1;
                            var combinedIndex = selectedMeasurePosition * selectedCompPosition;
                            headers.push({
                                name: referenceItemHeader,
                                isBorder: !_this.$scope.config.isVariance && selectedCompPosition === _this.$scope.config.comparison.length
                                    && combinedIndex < referenceLength,
                                measurement: m
                            });
                            if (_this.$scope.config.isVariance) {
                                var varianceItemHeader = _this.getVarianceItemHeader(m, comp);
                                headers.push({
                                    name: varianceItemHeader,
                                    isBorder: !isLastMeasure && (i === _this.$scope.config.comparison.length - 1),
                                    measurement: m
                                });
                            }
                        });
                    });
                    return headers;
                };
                PortfolioVarianceCtrl.prototype.getHeaderGroups = function () {
                    var _this = this;
                    var groups = [];
                    this.$scope.selectedMeasures.forEach(function (m) {
                        var metric = _this.getMeasureMetric(m);
                        var header = _this.getMeasureHeader(m);
                        groups.push({
                            name: metric.serviceType + " " + header + " (" + metric.unit + ")",
                            colspan: 1 + _this.$scope.config.comparison.length + (_this.$scope.config.isVariance ? _this.$scope.config.comparison.length : 0)
                        });
                    });
                    return groups;
                };
                PortfolioVarianceCtrl.prototype.getVarianceItemHeader = function (measure, comparison) {
                    var referencePrefix = this.getReferenceItemPrefix(measure, comparison);
                    return "variance (over " + referencePrefix + ")";
                };
                PortfolioVarianceCtrl.prototype.getMainItemHeader = function (measure) {
                    var name = this.getMeasureHeader(measure);
                    var isEstimated = this.$scope.config.timePeriod.indexOf('CURRENT_') > -1
                        && measure.indexOf('CURRENCY_') > -1
                        && this.$scope.config.isProjections;
                    if (isEstimated) {
                        return "estimated " + name;
                    }
                    return name;
                };
                PortfolioVarianceCtrl.prototype.getReferenceItemHeader = function (measure, comparison) {
                    var name = this.getMeasureHeader(measure);
                    var prefix = this.getReferenceItemPrefix(measure, comparison);
                    name = prefix + " " + name;
                    return name;
                };
                PortfolioVarianceCtrl.prototype.getMeasureHeader = function (measure) {
                    if (measure.indexOf('CURRENCY_') > -1) {
                        return 'spend';
                    }
                    if (measure == 'KW') {
                        return 'peak demand';
                    }
                    return 'consumption';
                };
                PortfolioVarianceCtrl.prototype.getReferenceItemPrefix = function (measure, comparison) {
                    var timePeriod = this.$scope.config.timePeriod;
                    var prefix = '';
                    if (comparison === Comparison.PREVIOUS_PERIOD) {
                        prefix = (timePeriod == TimePeriod.CURRENT_MONTH || timePeriod == TimePeriod.LAST_MONTH)
                            ? 'previous month'
                            : 'previous year';
                    }
                    if (comparison === Comparison.SAME_MONTH_PRIOR_YEAR) {
                        prefix = 'prior year';
                    }
                    if (comparison === Comparison.TARGETS_AND_BUDGETS) {
                        prefix = measure.indexOf('CURRENCY_') > -1 ? 'budgeted' : 'target';
                    }
                    return prefix;
                };
                PortfolioVarianceCtrl.prototype.getEmptyDataItems = function (buildingId) {
                    var _this = this;
                    var dataItems = [];
                    this.$scope.selectedMeasures.forEach(function (m, index) {
                        var isLastMeasure = index === _this.$scope.selectedMeasures.length - 1;
                        var metric = _this.getMeasureMetric(m);
                        var mainItem = {
                            buildingId: buildingId,
                            measure: m,
                            type: DataItemType.VALUE,
                            value: null,
                            unit: metric.unit,
                            display: null,
                            isBorder: !isLastMeasure && _this.$scope.config.comparison.length === 0
                        };
                        dataItems.push(mainItem);
                        _this.$scope.config.comparison.forEach(function (comp, i) {
                            var referenceLength = _this.$scope.selectedMeasures.length * _this.$scope.config.comparison.length;
                            var selectedMeasurePosition = index + 1;
                            var selectedCompPosition = i + 1;
                            var combinedIndex = selectedMeasurePosition * selectedCompPosition;
                            var referenceItem = {
                                buildingId: buildingId,
                                measure: _this.getMeasureReferenceIdentifier(m, comp),
                                type: DataItemType.VALUE,
                                value: null,
                                unit: metric.unit,
                                display: null,
                                isBorder: !_this.$scope.config.isVariance && selectedCompPosition === _this.$scope.config.comparison.length
                                    && combinedIndex < referenceLength
                            };
                            dataItems.push(referenceItem);
                            if (_this.$scope.config.isVariance) {
                                var varianceItem = {
                                    buildingId: buildingId,
                                    measure: '',
                                    type: DataItemType.VARIANCE,
                                    value: {
                                        mainItem: mainItem,
                                        referenceItem: referenceItem
                                    },
                                    unit: metric.unit,
                                    isBorder: !isLastMeasure && (i === _this.$scope.config.comparison.length - 1)
                                };
                                dataItems.push(varianceItem);
                            }
                        });
                    });
                    return dataItems;
                };
                PortfolioVarianceCtrl.prototype.getMeasureReferenceIdentifier = function (measure, comparisonPeriod) {
                    return measure + "/REFERENCE/" + comparisonPeriod;
                };
                PortfolioVarianceCtrl.prototype.initOptions = function () {
                    var _this = this;
                    return this.DashboardOptionsService.init(this.account.id, this.account.measurementSystem, this.account.currencyUnit).then(function () {
                        var allNonConvertedUnits = _this.DashboardOptionsService.getUnits();
                        var allEligibleUnits = allNonConvertedUnits.filter(function (unit) {
                            return unit.field == 'total' && unit.isSummable == true && unit.isDefault == true || unit.unit == 'kW';
                        });
                        allEligibleUnits.forEach(function (unit) { return delete unit.originalElement; });
                        var allUnits = _this.DashboardOptionsService.organizeUnitsByServiceType(allEligibleUnits);
                        if (allUnits.ENVIRONMENT) {
                            delete allUnits.ENVIRONMENT;
                        }
                        _this.$scope.config.options.allUnits = _this.reorderUnits(allUnits);
                    });
                };
                PortfolioVarianceCtrl.prototype.sortUnits = function (unit) {
                    var _this = this;
                    unit.sort(function (a, b) {
                        var aUnit = _this.unitOrder[a.unit] != null ? _this.unitOrder[a.unit] : 3;
                        var bUnit = _this.unitOrder[b.unit] != null ? _this.unitOrder[b.unit] : 3;
                        return aUnit - bUnit;
                    });
                };
                PortfolioVarianceCtrl.prototype.reorderUnits = function (allUnits) {
                    var _this = this;
                    Object.keys(allUnits).forEach(function (measure) {
                        _this.sortUnits(allUnits[measure]);
                    });
                    return allUnits;
                };
                PortfolioVarianceCtrl.prototype.getSelectedMeasures = function (measures) {
                    // match measure selector ordering
                    var result = [];
                    var allUnits = this.$scope.config.options.allUnits;
                    Object.keys(allUnits).forEach(function (key) {
                        if (allUnits[key]) {
                            allUnits[key].forEach(function (unit) {
                                if (measures.indexOf(unit.value) > -1) {
                                    result.push(unit.value);
                                }
                            });
                        }
                    });
                    return result;
                };
                PortfolioVarianceCtrl.prototype.parseBuildingData = function (dataItems) {
                    return dataItems.map(function (datum) {
                        if (datum.type !== DataItemType.VARIANCE) {
                            return "" + datum.value;
                        }
                        var varianceObj = datum.value;
                        var mainItem = varianceObj.mainItem, referenceItem = varianceObj.referenceItem;
                        var currVal = mainItem.value;
                        var targetVal = referenceItem.value;
                        var variance = (currVal - targetVal) / targetVal;
                        return variance;
                    });
                };
                PortfolioVarianceCtrl.prototype.exportToCsv = function () {
                    var _this = this;
                    try {
                        var csvContent_1 = 'data:text/csv;charset=utf-8,';
                        var fileName = "Portfolio_Variance_" + moment().format('YYYY-MM-DD') + ".csv";
                        var _a = this.$scope, headers = _a.headers, buildingItems = _a.buildingItems;
                        var headerTitles = ['building'];
                        headerTitles = headerTitles.concat(headers.map(function (header) { return header.measurement.includes('CURRENCY') ? header.name + " ($)" : header.name + " (" + header.measurement + ")"; }));
                        csvContent_1 += headerTitles.join();
                        csvContent_1 += '\n';
                        buildingItems.forEach(function (building) {
                            var buildingRow = [building.name];
                            var buildingData = _this.parseBuildingData(building.dataItems);
                            buildingRow = buildingRow.concat(buildingData);
                            csvContent_1 += buildingRow.join();
                            csvContent_1 += '\n';
                        });
                        var link = document.createElement('a');
                        link.setAttribute('href', encodeURI(csvContent_1));
                        link.setAttribute('download', fileName);
                        link.click();
                    }
                    catch (_b) {
                        this.Messages.error('An error occurred while attempting to export the data');
                    }
                };
                return PortfolioVarianceCtrl;
            }());
            widgets.PortfolioVarianceCtrl = PortfolioVarianceCtrl;
            angular.module('aq.dashboard.widgets').controller('PortfolioVarianceCtrl', PortfolioVarianceCtrl);
        })(widgets = dashboard.widgets || (dashboard.widgets = {}));
    })(dashboard = aq.dashboard || (aq.dashboard = {}));
})(aq || (aq = {}));
