angular.module('properties.dataCollectors').controller('DataCollectorDetailsCtrl',
    function($scope, $state, $filter, Errors, RestangularV3, Restangular, $stateParams, Messages, $interval,
        PropertySettingsOptions, loading, $modal, $mdDialog, OptionsService, dataCollectors, building, currentUser, account, authAccess) {

    $scope.propertySettingsAccess = authAccess['Property Settings'];

    // manual reading and comparison tool timezones
    $scope.shortDateFormat = 'lll';
    $scope.longDateFormat = 'llll';
    if(currentUser.timeZoneId !== building.timeZoneId) {
        $scope.shortDateFormat += ' z';
        $scope.longDateFormat += ' z';
    }

    $scope.flatCollectors = $filter("flattenHierarchy")(dataCollectors, 'childCollectors').filter(function(collector){
        return collector.isPhysicalMeter;
    });

    $scope.readingsComparisonTool = {
        startReading: {},
        endReading: {},
        startReadingList: [],
        endReadingList: []
    }

    PropertySettingsOptions.init($scope.account).then(function () {
        $scope.options = PropertySettingsOptions.get();
    });

    var updateCollector = function () {
        Restangular.one("accounts", account.id).one("buildings", building.id).one('collectors', $stateParams.dataCollectorId).get({
            single: true,
            inflate: 'buildings,childCollectors,parentPoint,parentCollector,manualReadings,virtualMeterEquation'
        }).then(function (collectorWithStats) {
            $scope.collector = Restangular.restangularizeElement(Restangular.one("accounts", account.id).one("buildings", building.id), collectorWithStats.collector, 'collectors');
            $scope.stats = collectorWithStats.stats;
            if ($scope.collector.virtualMeterEquation) {
                $scope.collector.equation = $scope.collector.virtualMeterEquation.equation;
            }
            $scope.collector.metricLabel = OptionsService.getUnitLabelByMeasure($scope.collector.metrics[0]);
            $state.$current.data.breadcrumb = $scope.collector.name;
            $scope.updatePoints($scope.collector);
            $scope.readingsComparisonTool.startReadingList = $scope.collector.manualReadings;
            $scope.readingsComparisonTool.endReadingList = $scope.collector.manualReadings;
            $scope.parentPointCollectorInfo = $scope.collector.parentPoint ?
                $scope.collector.parentCollector.name + ' - ' + $scope.collector.parentPoint.name : '';
            $scope.data.multiplier = $scope.collector.multiplier;
        });
    }
    $scope.buildingsPromise.then(updateCollector);

    $scope.updateStartReadingList = function () {
        $scope.readingsComparisonTool.startReadingList = $scope.collector.manualReadings.filter(function (reading) {
            if (reading.id === $scope.readingsComparisonTool.endReading.id) {
                return false;
            }
            if (moment(reading.timestamp).isAfter(moment($scope.readingsComparisonTool.endReading.timestamp))) {
                return false;
            }
            return true;
        });
    }

    $scope.updateEndReadingList = function () {
        $scope.readingsComparisonTool.endReadingList = $scope.collector.manualReadings.filter(function (reading) {
            if (reading.id === $scope.readingsComparisonTool.startReading.id) {
                return false;
            }
            if (moment(reading.timestamp).isBefore(moment($scope.readingsComparisonTool.startReading.timestamp))) {
                return false;
            }
            return true;
        });
    }

    $scope.calculateReadingComparison = function () {
        $scope.collector.customGET('readingsComparison', {
            startReading: $scope.readingsComparisonTool.startReading.id,
            endReading: $scope.readingsComparisonTool.endReading.id
        }).then(function (readingsComparison) {
            $scope.readingsComparison = readingsComparison;
            Messages.success("Successfully calculated readings comparison.");
        }).catch(function (error) {
            Messages.error("Error calculating comparison.");
        });
    }

    $scope.hasDeviceTemplateId = function () {
        var isGateway = $scope.collector.collectorClass === 'AQUICORE_GATEWAY';
        var isRadioNode = $scope.collector.collectorClass === 'AQUICORE_RADIO_NODE';
        var isAquicoreMeter = $scope.collector.collectorClass === 'AQUICORE_METER';
        var isBACnetConverter = $scope.collector.collectorClass === 'BACNET_CONVERTER';
        return isGateway || isRadioNode || isAquicoreMeter || isBACnetConverter;
    };

    var modalUpdateMultiplier = $modal({
        templateUrl: 'app/properties/dataCollectors/details/partials/updateMultiplier.html',
        show: false,
        backdrop: 'static',
        scope: $scope
    });

    var modalHistoricalUpload = $modal({
        templateUrl: 'app/properties/dataCollectors/details/partials/historicalUpload.html',
        show: false,
        backdrop: 'static',
        scope: $scope
    });

    var historicDataUpdateStatusPromise = null;
    $scope.buildingId = $stateParams.buildingId;
    $scope.waitingResponse = false;
    $scope.showAddFacility = false;
    $scope.data.multiplier = null;

    // TODO - finish when backend is done
    $scope.tagOptions = ['Some location', 'Other'];

    $scope.$on('$destroy', function () {
        $interval.cancel(historicDataUpdateStatusPromise);
    });

    $scope.$watch('collector', function (collector) {
        if (!collector) return;

        historicDataUpdateStatus();

        $scope.collector.previousDeviceIdentifier = $scope.collector.deviceIdentifier;
    });

    $scope.cancelHistoricDataUpdateStatus = function () {
        if (historicDataUpdateStatusPromise != null) {
            $interval.cancel(historicDataUpdateStatusPromise);
            historicDataUpdateStatusPromise = null;
        }
    };

    var modalUpdateDeviceIdentifier = $modal({
        templateUrl: 'app/properties/dataCollectors/details/partials/updateDeviceIdentifier.html',
        show: false,
        backdrop: 'static',
        scope: $scope
    });

    $scope.showUpdateDeviceIdentifierModal = function () {
        return modalUpdateDeviceIdentifier.$promise
            .then(modalUpdateDeviceIdentifier.show);
    }

    $scope.hideUpdateDeviceIdentifierModal = function (userApprovedUpdate) {
        modalUpdateDeviceIdentifier.hide();
        if (userApprovedUpdate === true) {
            $scope.collector.previousDeviceIdentifier = $scope.collector.deviceIdentifier;
            $scope.update();
        } else {
            $scope.collector.deviceIdentifier = $scope.collector.previousDeviceIdentifier;
        }
    }

    $scope.update = function (data) {
        if (data && data.applyMultiplierToAllData != null) {
            var params = { applyMultiplierToAllData: data.applyMultiplierToAllData };
            $scope.collector.multiplier = $scope.data.multiplier;
        }

        //need to deflate child collectors before saving to backend
        var childlessCollector = Restangular.copy($scope.collector);
        var childCollectorIds = _.map(childlessCollector.childCollectors, function (collector) {
            return collector.id;
        });
        childlessCollector.childCollectors = childCollectorIds;

        //need to deflate building before saving to backend
        var buildinglessCollector = Restangular.copy(childlessCollector);
        var buildingIds = _.map(childlessCollector.buildings, function (building) {
            return building.id;
        });
        buildinglessCollector.buildings = buildingIds;

        return buildinglessCollector.put(params).then(function () {
            Messages.success("Successfully updated collector");
        }).catch(function (exception) {
            Errors.forPromise()(exception);
            updateCollector();
        });
    }

    $scope.showUpdateMultiplierModal = function () {
        return modalUpdateMultiplier.$promise.then(modalUpdateMultiplier.show);
    }

    $scope.showHistoricalUploadModal = function () {
        return modalHistoricalUpload.$promise.then(modalHistoricalUpload.show);
    }

    $scope.showMeterType = function () {
        // TODO: Show temporarily for collector that have meter enabled regardless of collector class
        var temporarilyShow = $scope.collector.isUtilityMeter;
        return _.startsWith($scope.collector.collectorClass, 'GENERIC_')
            || ($scope.collector.collectorClass === 'VIRTUAL_METER' && $scope.collector.virtualMeterEquation)
            || temporarilyShow;
    }

    $scope.enableAddFacility = function () {
        $scope.waitingResponse = true;
        $scope.showAddFacility = true;
        $scope.filteredBuildings = _.filter($scope.buildings, function (building) {
            var found = _.findById($scope.collector.buildings, building.id);
            return !found;
        });
        $scope.waitingResponse = false;
    }

    $scope.addFacility = function (building) {
        $scope.waitingResponse = true;
        $scope.collector.customPUT(null, 'addFacility', { buildingId: building.id }).then(function () {
            $scope.collector.buildings.push(building);
        }, Errors.forPromise()).finally(function () {
            $scope.waitingResponse = false;
            $scope.showAddFacility = false;
        });
    }

    $scope.removeFacility = function (building) {
        $scope.showAddFacility = false;
        $scope.collector.customPUT(building, 'removeFacility').then(function () {
            _.withoutInline($scope.collector.buildings, building);
        }, Errors.forPromise());
    }

    $scope.makeFacilityPrimary = function (building) {
        $scope.collector.primaryBuilding = building.id;
        $scope.collector.customPUT(null, 'makePrimary', { targetId: building.id }).catch(Errors.forPromise());
    }

    $scope.historicDataCancel = function (historicDataItem) {
        historicDataItem.status = 'CANCELED';
        historicDataItem.put().catch(Errors.forPromise());
    }

    var getCSVContentForPoints = function () {
        var data = angular.copy($scope.points);
        var csvContent = "data:text/csv;charset=utf-8, Collector, Name, Type, Child Collector, Last Value, Last Reported Time \n";
        data.forEach(function (point, index) {
            // must add last value and last reported time when feasible
            var dataString = [point.collector.name, point.name, point.type, point.childCollector == null ? "" : point.childCollector.name].join(",");
            csvContent += index < data.length ? dataString + "\n" : dataString;
        });
        return csvContent;
    }

    var getCSVContentForChildCollectors = function () {
        var data = angular.copy($scope.collector.childCollectors);
        var csvContent = "data:text/csv;charset=utf-8,Name, Identifier, Physical Serial Number, Last Reported Time, Multiplier \n";
        data.forEach(function (child, index) {
            var date = '"' + $filter('formatDate')(child.lastReportedTime, 'llll') + '"'
            var dataString = [child.name, child.deviceIdentifier, child.physicalSerialNumber, date, child.multiplier].join(",");
            csvContent += index < data.length ? dataString + "\n" : dataString;
        });
        return csvContent;
    }

    var encodeAndDownloadCsvContent = function (csvContent, fileName) {
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName);
        link.click();
        return link;
    }

    $scope.downloadCSVForPoints = function () {
        var fileName = $scope.collector.name + " - Points.csv";
        return encodeAndDownloadCsvContent(getCSVContentForPoints(), fileName);
    }

    $scope.downloadCSVForChildCollectors = function () {
        var fileName = $scope.collector.name + " - Child Collectors.csv";
        return encodeAndDownloadCsvContent(getCSVContentForChildCollectors(), fileName);
    }

    $scope.hideAddFacility = function () {
        $scope.showAddFacility = false;
    }

    $scope.shouldShowMeterData = function() {
        return _.includes(['AQ_MODBUS','MODBUS'], $scope.collector.upwardCommunications.toString()) &&
            _.includes($scope.collector.metrics, 'ELECTRICITY');
    };

    var historicDataUpdateStatus = function () {
        Restangular.one("accounts", account.id).one("buildings", building.id).one('collectors', $stateParams.dataCollectorId).getList('historicDataItems', { nocache: new Date().getTime() }).then(function (historicDataItems) {
            $scope.historicDataItems = historicDataItems;
            if (historicDataItems.length > 0 && !historicDataUpdateStatusPromise) {
                historicDataUpdateStatusPromise = $interval(historicDataUpdateStatus, 5000);
            }
        }, Errors.forPromise());
    }

    $scope.updatePoints = function (collector) {
        if (collector) {
            collector
                .all('points')
                .getList({ inflate: "childCollector,collector,inputs,inputs.source", nocache: new Date().getTime() })
                .then(function (points) {
                    $scope.points = points;
                });
        }
    }

    var getDevice = function(collector){
        return RestangularV3.all('devices').one('by-collector', collector.id).get()
            .then(function(device){
                return device.id;
            });
    }


    $scope.goToDevices = function(collector){
        getDevice(collector).then(function(devID){
            var backButton = {
                state: 'aq.properties.buildings.datacollectors.detail',
                stateData: {accountId: $scope.account.id, buildingId: $scope.collector.primaryBuilding, dataCollectorId: collector.id}
            };
            $state.transitionTo("aq.deviceManagement.building.device.readings", {accountId: $scope.account.id, buildingId: $scope.collector.primaryBuilding, deviceId: devID, backButton: backButton})
        })}

});
