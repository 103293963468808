var aq;
(function (aq) {
    var dashboard;
    (function (dashboard) {
        var widgets;
        (function (widgets) {
            var ProjectPriority = aq.models.projects.ProjectPriority;
            var IdentificationSource = aq.models.projects.IdentificationSource;
            var ImpactType = aq.models.projects.ImpactType;
            var IdentificationMethod = aq.models.projects.IdentificationMethod;
            var BudgetType = aq.models.projects.BudgetType;
            var CostType = aq.models.projects.CostType;
            var ProjectType = aq.models.projects.ProjectType;
            var GresbProjectCategories = aq.models.projects.GresbProjectCategories;
            var DateRange;
            (function (DateRange) {
                DateRange["ALL_TIME"] = "ALL_TIME";
                DateRange["TRAILING_YEAR"] = "TRAILING_YEAR";
                DateRange["TRAILING_QUARTER"] = "TRAILING_QUARTER";
                DateRange["TRAILING_MONTH"] = "TRAILING_MONTH";
                DateRange["THIS_YEAR"] = "THIS_YEAR";
                DateRange["THIS_QUARTER"] = "THIS_QUARTER";
                DateRange["THIS_MONTH"] = "THIS_MONTH";
            })(DateRange || (DateRange = {}));
            var Priority = aq.models.projects.ProjectPriority;
            var GRESB_REPORTING = 'Gresb Reporting';
            var ProjectListWidgetCtrl = /** @class */ (function () {
                /* @ngInject */
                function ProjectListWidgetCtrl($scope, DataStore, account, buildings, config, Restangular, $q, ModelUtilService, users, projectTypes, projectStatuses, ProjectService, ProjectServiceV3, $state, buildingGroups, BuildingSelectorActions, Messages, RedirectService, currentUser, isProjectsV3, Auth) {
                    var _a;
                    var _this = this;
                    this.$scope = $scope;
                    this.DataStore = DataStore;
                    this.account = account;
                    this.buildings = buildings;
                    this.config = config;
                    this.Restangular = Restangular;
                    this.$q = $q;
                    this.ModelUtilService = ModelUtilService;
                    this.users = users;
                    this.projectTypes = projectTypes;
                    this.projectStatuses = projectStatuses;
                    this.ProjectService = ProjectService;
                    this.ProjectServiceV3 = ProjectServiceV3;
                    this.$state = $state;
                    this.buildingGroups = buildingGroups;
                    this.BuildingSelectorActions = BuildingSelectorActions;
                    this.Messages = Messages;
                    this.RedirectService = RedirectService;
                    this.currentUser = currentUser;
                    this.isProjectsV3 = isProjectsV3;
                    this.Auth = Auth;
                    this.defaultStatuses = ['Needs Acceptance', 'Backlog', 'In Progress', 'Completed'];
                    this.displayBuildingsCount = 5;
                    this.projectGroupsCollapsedState = {};
                    this.isLoadingData = true;
                    this.isAqAdmin = this.currentUser.userType === 'ADMINISTRATOR';
                    this.selectedBuildingNames = [];
                    this.alternateSortFieldMap = {
                        dateIdentified: 'dateIdentifiedSort',
                        priority: 'prioritySort'
                    };
                    this.priorityMap = (_a = {},
                        _a[Priority.HIGH] = 3,
                        _a[Priority.MEDIUM] = 2,
                        _a[Priority.LOW] = 1,
                        _a);
                    config.options = {
                        isProjectsV3: this.isProjectsV3,
                        buildings: ModelUtilService.pareProperties(this.buildings, ['buildingGroup']),
                        buildingGroups: ModelUtilService.pareProperties(this.buildingGroups),
                        statuses: !this.isProjectsV3 ? this.getProjectStatusSelectionItems() : [],
                        dateRanges: this.isProjectsV3 && this.getDateRangeItems()
                    };
                    this.BuildingSelectorActions.initDefaultBuildingSelection(config);
                    config.types = this.projectTypes;
                    config.statuses = this.projectStatuses;
                    if (!config.filterBy) {
                        config.filterBy = {};
                    }
                    if (!config.filterBy.status) {
                        config.filterBy.status = 'ALL';
                    }
                    if (this.isProjectsV3 && !config.filterBy.dateRange) {
                        config.filterBy.dateRange = DateRange.THIS_YEAR;
                    }
                    if (this.isProjectsV3 && !config.filterBy.statuses) {
                        config.filterBy.statuses = this.defaultStatuses.slice();
                    }
                    if (this.isProjectsV3 && !config.columns) {
                        config.columns = {
                            showPriority: true,
                            showType: true,
                            showStatus: true,
                            showSystem: true,
                            showIdentifyingOrg: true,
                            showCost: true,
                            showImpact: true,
                            showAssignee: true,
                            showDateIdentified: true
                        };
                    }
                    config.columns.showProjectTitle = true;
                    config.columns.showBuildingName = true;
                    if (this.isProjectsV3 && !config.groupBy) {
                        config.groupBy = 'building';
                    }
                    this.$scope.config = config;
                    this.$scope.config.actions = this.BuildingSelectorActions;
                    this.$scope.projects = [];
                    if (this.isProjectsV3) {
                        this.populateProjectsV3();
                    }
                    else {
                        this.populateProjects();
                    }
                    this.$scope.selectedProject = [];
                    this.$scope.selectProject = function (project) { return __awaiter(_this, void 0, void 0, function () {
                        var destination;
                        return __generator(this, function (_a) {
                            if (this.isProjectsV3) {
                                if (project.isHeader)
                                    return [2 /*return*/];
                                destination = "accounts/" + this.account.id + "/buildings/" + project.buildingId + "/projects/board/project/" + project.id;
                                this.RedirectService.redirect({
                                    account: +this.account.id,
                                    building: +project.buildingId,
                                    profile: this.currentUser.currentProfile.id,
                                    destination: destination,
                                }, true);
                            }
                            else {
                                this.$state.go('aq.projectCenter.overview.project.configuration', {
                                    accountId: this.account.id,
                                    buildingId: project.buildingId,
                                    projectId: project.id
                                });
                            }
                            return [2 /*return*/];
                        });
                    }); };
                    this.fieldsCount = this.isProjectsV3 ?
                        _.filter([
                            config.columns.showPriority, config.columns.showType, config.columns.showStatus,
                            config.columns.showSystem, config.columns.showIdentifyingOrg, config.columns.showCost,
                            config.columns.showImpact, config.columns.showAssignee, config.columns.showDateIdentified
                        ], function (flag) { return flag; }).length :
                        _.filter([
                            config.showStatus, config.showType, config.showCost, config.showSavings
                        ], function (flag) { return flag; }).length;
                    if (this.isProjectsV3 && (config.groupBy == 'building' || config.groupBy == 'status' && config.columns.showStatus)) {
                        this.fieldsCount--;
                    }
                }
                ProjectListWidgetCtrl.prototype.getStatusesView = function () {
                    var effectiveStatuses = this.isAqAdmin
                        ? this.config.filterBy.statuses
                        : this.config.filterBy.statuses.filter(function (status) { return status.toUpperCase() !== 'DRAFT'; });
                    return effectiveStatuses.join(', ');
                };
                ProjectListWidgetCtrl.prototype.onHeaderClick = function (event) {
                    var field = event.target.attributes.field;
                    if (!field || !field.value) {
                        return;
                    }
                    if (this.sortColumn == field.value) {
                        this.sortDirection = this.sortDirection == 'asc' ? 'desc' : 'asc';
                    }
                    else {
                        this.sortColumn = field.value;
                        this.sortDirection = 'asc';
                    }
                    this.groupProjects(this.$scope.projects);
                    if (this.Auth.hasFunctionality(GRESB_REPORTING)) {
                        this.groupProjects(this.$scope.csvProjects, true);
                    }
                };
                ProjectListWidgetCtrl.prototype.initDisplayBuildings = function () {
                    this.displayBuildings = _.take(this.selectedBuildingNames, this.displayBuildingsCount);
                };
                ProjectListWidgetCtrl.prototype.getDateRangeItems = function () {
                    return Object.keys(DateRange).map(function (key) { return DateRange[key]; });
                };
                ProjectListWidgetCtrl.prototype.getProjectStatusSelectionItems = function () {
                    var statuses = _.map(this.projectStatuses, function (value, key) {
                        return {
                            value: key,
                            label: value.label
                        };
                    });
                    statuses.push({
                        value: 'ALL',
                        label: 'All'
                    });
                    return statuses;
                };
                ProjectListWidgetCtrl.prototype.exportProjectsToCSV = function () {
                    var _this = this;
                    try {
                        var csvContent_1 = '';
                        var fileName = '';
                        var processedProjects = this.Auth.hasFunctionality(GRESB_REPORTING)
                            ? 'projectsProcessedCSV' : 'projectsProcessed';
                        var filteredProjects = this.$scope[processedProjects].filter(function (project) { return project.isHeader !== true; });
                        var projectsMinusGroups = _.cloneDeep(filteredProjects);
                        if (projectsMinusGroups.length === 0) {
                            return;
                        }
                        var headerRow = this.trimProjectColumnsForExport(projectsMinusGroups[0]);
                        csvContent_1 += Object.keys(headerRow).join(',');
                        csvContent_1 += '\n';
                        projectsMinusGroups.forEach(function (values) {
                            var data = _this.trimProjectColumnsForExport(values);
                            csvContent_1 += Object.values(data).map(function (value) {
                                if (typeof (value) === 'string') {
                                    value = value.replace(/(\r\n|\n|\r)/gm, ' ');
                                }
                                return value;
                            }).join(',');
                            csvContent_1 += '\n';
                        });
                        fileName = "Projects Export - " + moment().format('YYYY-MM-DD') + ".csv";
                        var blob = new Blob([csvContent_1], { type: 'text/csv' });
                        var objectUrl = window.URL.createObjectURL(blob);
                        var link = document.createElement('a');
                        link.setAttribute('href', objectUrl);
                        link.setAttribute('download', fileName);
                        link.click();
                    }
                    catch (e) {
                        this.Messages.error('Something went wrong generating your report!');
                    }
                };
                ProjectListWidgetCtrl.prototype.getCountOfGroup = function (group) {
                    var projectGroup = this.$scope.projectGroups.find(function (projectGroup) { return projectGroup.name === group; });
                    if (projectGroup) {
                        return projectGroup.projects.length;
                    }
                    return 0;
                };
                ProjectListWidgetCtrl.prototype.trimProjectColumnsForExport = function (project) {
                    delete project.$$hashKey;
                    delete project.prioritySort;
                    delete project.dateIdentifiedSort;
                    delete project.id;
                    if (!this.config.columns.showPriority) {
                        delete project.priority;
                    }
                    if (!this.config.columns.showType) {
                        delete project.type;
                    }
                    if (!this.config.columns.showStatus) {
                        delete project.status;
                    }
                    if (!this.config.columns.showSystem) {
                        delete project.buildingSystem;
                    }
                    if (!this.config.columns.showIdentifyingOrg) {
                        delete project.identifyingOrg;
                    }
                    if (!this.config.columns.showCost) {
                        delete project.cost;
                    }
                    if (!this.config.columns.showImpact) {
                        delete project.impact;
                    }
                    if (!this.config.columns.showAssignee) {
                        delete project.assigneeName;
                    }
                    if (!this.config.columns.showDateIdentified) {
                        delete project.dateIdentified;
                    }
                    return project;
                };
                ProjectListWidgetCtrl.prototype.populateProjects = function () {
                    var _this = this;
                    var results = this.config.buildingIds.map(function (buildingId) {
                        return _this.ProjectService.getList(buildingId, _this.users, _this.projectTypes).then(function (projects) {
                            projects = _.filter(projects, function (project) {
                                return _this.$scope.config.filterBy.status == 'ALL' || project.status == _this.$scope.config.filterBy.status;
                            });
                            var building = _.find(_this.buildings, { id: buildingId });
                            projects.forEach(function (project) {
                                project.building = building;
                            });
                            _this.$scope.projects = _this.$scope.projects.concat(projects);
                            return _this.$q.all(projects.map(function (project) {
                                return project.customGET('results');
                            }));
                        }).then(function (projectResults) {
                            projectResults.forEach(function (result) {
                                var project = _.find(_this.$scope.projects, { id: result.project });
                                if (project) {
                                    project.totalSavings = result.totalSavings;
                                }
                            });
                        });
                    });
                    this.$q.all(results).finally(function () {
                        _this.isLoadingData = false;
                    });
                };
                ProjectListWidgetCtrl.prototype.populateProjectsV3 = function () {
                    var _this = this;
                    this.$scope.csvProjects = [];
                    var first = false;
                    var results = this.$scope.config.buildingIds.map(function (buildingId) {
                        return _this.ProjectServiceV3.getProjects(buildingId).then(function (projectResponse) {
                            if (!first) {
                                first = true;
                                var effectiveStatuses = _this.isAqAdmin
                                    ? projectResponse.statuses
                                    : projectResponse.statuses.filter(function (s) { return s.name.toUpperCase() !== 'DRAFT'; });
                                _this.$scope.config.options.statuses = effectiveStatuses;
                            }
                            var selectedStatuses = _.filter(_this.$scope.config.options.statuses, function (status) {
                                return _.includes(_this.$scope.config.filterBy.statuses, status.name);
                            });
                            var now = moment();
                            var projects = _.chain(projectResponse.projects)
                                .filter(function (project) {
                                return _.find(selectedStatuses, function (status) { return status.id == project.status.id; });
                            })
                                .filter(function (project) {
                                if (project.status.name == 'Completed') {
                                    var completed = project.completedOn ? moment(project.completedOn) : moment(project.updatedOn);
                                    switch (_this.$scope.config.filterBy.dateRange) {
                                        case DateRange.THIS_MONTH:
                                            return completed.isSame(now, 'month');
                                        case DateRange.THIS_YEAR:
                                            return completed.isSame(now, 'year');
                                        case DateRange.THIS_QUARTER:
                                            return completed.year() == now.year()
                                                && Math.ceil((completed.month() + 1) / 3) == Math.ceil((now.month() + 1) / 3);
                                        case DateRange.TRAILING_MONTH:
                                            return now.subtract(1, 'month').isSameOrBefore(completed);
                                        case DateRange.TRAILING_YEAR:
                                            return now.subtract(1, 'year').isSameOrBefore(completed);
                                        case DateRange.TRAILING_QUARTER:
                                            return now.subtract(3, 'month').isSameOrBefore(completed);
                                        case DateRange.ALL_TIME:
                                        default:
                                            return true;
                                    }
                                }
                                return true;
                            })
                                .value();
                            var building = _.find(_this.buildings, { id: buildingId });
                            _this.selectedBuildingNames.push(building.name);
                            if (_this.Auth.hasFunctionality(GRESB_REPORTING)) {
                                _this.populateProjectsGresb(projects, building);
                            }
                            return projects.map(function (p) {
                                var systemType = p.systemTypes && p.systemTypes[0] || null;
                                var assignee = _.find(_this.users, function (user) { return user.id == p.assignee; });
                                var isCompleted = p.status.name === 'Completed';
                                var isGresb = _this.Auth.hasFunctionality(GRESB_REPORTING);
                                var savingsRange;
                                if (_this.Auth.hasFunctionality(PROJECT_ESTIMATION)) {
                                    var impactValue = _this.ProjectServiceV3.getImpactValue(p, true, false, 'USD');
                                    var min = impactValue.projectImpactMin;
                                    var max = impactValue.projectImpact;
                                    var estimated = impactValue.estimated ? '~' : '';
                                    savingsRange = min ? "$" + min + " - $" + max : estimated + "$" + max;
                                    savingsRange = !min && !max ? '$0' : savingsRange;
                                }
                                else {
                                    savingsRange = _this.getImpact(isCompleted, p.impact, isGresb);
                                }
                                var project = {
                                    id: p.id,
                                    name: p.code + " " + p.title,
                                    assigneeName: assignee && assignee.fullName,
                                    buildingId: building.id,
                                    buildingName: building.name,
                                    buildingSystem: systemType && systemType.name || null,
                                    cost: p.cost && p.cost.totalCost || null,
                                    impact: savingsRange,
                                    identifyingOrg: p.identificationSource,
                                    dateIdentified: p.issue && p.issue.identifiedDate && moment(p.issue.identifiedDate).format('MM/DD/YYYY') || '',
                                    dateIdentifiedSort: p.issue && p.issue.identifiedDate && moment(p.issue.identifiedDate).format('YYYYMMDD') || '',
                                    priority: p.priority,
                                    prioritySort: _this.priorityMap[p.priority],
                                    status: p.status.name,
                                    type: p.projectType
                                };
                                return project;
                            });
                        });
                    });
                    this.$q.all(results).then(function (data) {
                        _this.$scope.projects = _.flatten(data);
                        _this.initDisplayBuildings();
                    }).finally(function () {
                        _this.groupProjects(_this.$scope.projects);
                        if (_this.Auth.hasFunctionality(GRESB_REPORTING)) {
                            _this.groupProjects(_this.$scope.csvProjects, true);
                        }
                        _this.isLoadingData = false;
                    });
                };
                ProjectListWidgetCtrl.prototype.getImpact = function (isCompleted, impact, isGresb) {
                    var savingsRange = '';
                    if (isGresb) {
                        if (isCompleted) {
                            if (impact && impact.totalActualSavingsAmount != null && impact.totalActualSavingsAmount != undefined) {
                                savingsRange = "$" + impact.totalActualSavingsAmount;
                            }
                        }
                        else {
                            var min = '';
                            var max = '';
                            if (impact && impact.totalEstimatedSavingsAmountMin != null && impact.totalEstimatedSavingsAmountMin != undefined) {
                                min = "$" + (impact && impact.totalEstimatedSavingsAmountMin);
                            }
                            if (impact && impact.totalEstimatedSavingsAmountMax != null && impact.totalEstimatedSavingsAmountMax != undefined) {
                                max = "$" + (impact && impact.totalEstimatedSavingsAmountMax);
                            }
                            savingsRange = min !== '' && min !== '$0' ? min + " - " + max : max;
                        }
                    }
                    else if (impact && impact.totalEstimatedSavingsAmountMax != null && impact.totalEstimatedSavingsAmountMax != undefined) {
                        savingsRange = "$" + impact.totalEstimatedSavingsAmountMax;
                    }
                    return savingsRange;
                };
                ProjectListWidgetCtrl.prototype.populateProjectsGresb = function (projects, building) {
                    var _this = this;
                    projects.map(function (p) {
                        var systemType = p.systemTypes && p.systemTypes[0] || '';
                        var assignee = _.find(_this.users, function (user) { return user.id == p.assignee; });
                        var project = {
                            accountName: "\"" + _this.account.accountName + "\"" || '',
                            buildingName: "\"" + building.name + "\"" || '',
                            projectType: p.projectType && ProjectType[p.projectType] || '',
                            gresbProjectCategories: p.gresbProjectCategories && "\"" + GresbProjectCategories[p.gresbProjectCategories] + "\"" || '',
                            title: p.code && p.title && "\"" + p.code + " " + p.title + "\"" || '',
                            statusName: p.status && p.status.name || '',
                            priority: p.priority && ProjectPriority[p.priority] || '',
                            issueDiagnosis: p.issue && "\"" + p.issue.diagnosis + "\"" || '',
                            issueCause: p.issue && p.issue.causes && p.issue.causes.length > 0 && "\"" + p.issue.causes[0] + "\"" || '',
                            issueCause2: p.issue && p.issue.causes && p.issue.causes.length > 1 && "\"" + p.issue.causes[1] + "\"" || '',
                            issueCause3: p.issue && p.issue.causes && p.issue.causes.length > 2 && "\"" + p.issue.causes[2] + "\"" || '',
                            issueRecommendation: p.issue && p.issue.recommendations && p.issue.recommendations.length > 0 && "\"" + p.issue.recommendations[0] + "\"" || '',
                            issueRecommendation2: p.issue && p.issue.recommendations && p.issue.recommendations.length > 1 && "\"" + p.issue.recommendations[1] + "\"" || '',
                            issueRecommendation3: p.issue && p.issue.recommendations && p.issue.recommendations.length > 2 && "\"" + p.issue.recommendations[2] + "\"" || '',
                            extraNotes: "\"" + p.extraNotes + "\"" || '',
                            systemTypeName: systemType && systemType.name || '',
                            issueStartDate: p.issue && p.issue.startDate && moment(p.issue.startDate).format('MM/DD/YYYY') || '',
                            issueIdentifiedDate: p.issue && p.issue.identifiedDate && moment(p.issue.identifiedDate).format('MM/DD/YYYY') || '',
                            issuePotentialEndDate: p.issue && p.issue.potentialEndDate && moment(p.issue.potentialEndDate).format('MM/DD/YYYY') || '',
                            netImpactLineItemType: p.impact && p.impact.impactLineItems && p.impact.impactLineItems.length > 0 && p.impact.impactLineItems[0].impactType && ImpactType[p.impact.impactLineItems[0].impactType] || '',
                            netImpactLineItemType2: p.impact && p.impact.impactLineItems && p.impact.impactLineItems.length > 1 && p.impact.impactLineItems[1].impactType && ImpactType[p.impact.impactLineItems[1].impactType] || '',
                            netImpactLineItemType3: p.impact && p.impact.impactLineItems && p.impact.impactLineItems.length > 2 && p.impact.impactLineItems[2].impactType && ImpactType[p.impact.impactLineItems[2].impactType] || '',
                            netImpactLineItemScore: p.impact && p.impact.impactLineItems && p.impact.impactLineItems.length > 0 && p.impact.impactLineItems[0].score || '',
                            netImpactLineItemScore2: p.impact && p.impact.impactLineItems && p.impact.impactLineItems.length > 1 && p.impact.impactLineItems[1].score || '',
                            netImpactLineItemScore3: p.impact && p.impact.impactLineItems && p.impact.impactLineItems.length > 2 && p.impact.impactLineItems[2].score || '',
                            netImpactLineItemEstimatedSavingsAmountMin: p.impact && p.impact.totalEstimatedSavingsAmountMin !== null ? p.impact.totalEstimatedSavingsAmountMin : '',
                            netImpactLineItemEstimatedSavingsAmountMax: p.impact && p.impact.totalEstimatedSavingsAmountMax !== null ? p.impact.totalEstimatedSavingsAmountMax : '',
                            netImpactLineItemActualSavings: p.impact && p.impact.totalActualSavingsAmount !== null ? p.impact.totalActualSavingsAmount : '',
                            netImpactLineItemEstimatedSavingsAmountEnergyMin: p.impact && p.impact.totalEstimatedSavingsAmountEnergyMin !== null ? p.impact.totalEstimatedSavingsAmountEnergyMin : '',
                            netImpactLineItemEstimatedSavingsAmountEnergyMax: p.impact && p.impact.totalEstimatedSavingsAmountEnergyMax !== null ? p.impact.totalEstimatedSavingsAmountEnergyMax : '',
                            netImpactLineItemActualSavingsAmountEnergy: p.impact && p.impact.totalActualSavingsAmountEnergy !== null ? p.impact.totalActualSavingsAmountEnergy : '',
                            netImplementationCostBudgetType: p.cost && p.cost.implementationCostLineItems && p.cost.implementationCostLineItems.length > 0 && p.cost.implementationCostLineItems[0].budgetType && BudgetType[p.cost.implementationCostLineItems[0].budgetType] || '',
                            netImplementationCostAmount: p.cost && p.cost.totalCost || '',
                            netImplementationCostCostType: p.cost && p.cost.implementationCostLineItems && p.cost.implementationCostLineItems.length > 0 && p.cost.implementationCostLineItems[0].costType && CostType[p.cost.implementationCostLineItems[0].costType] || '',
                            identificationSource: p.identificationSource && IdentificationSource[p.identificationSource] || '',
                            identificationMethod: p.identificationMethod && IdentificationMethod[p.identificationMethod] || '',
                            assignee: assignee && "\"" + assignee.email + "\"" || '',
                        };
                        _this.$scope.csvProjects.push(project);
                    });
                };
                ProjectListWidgetCtrl.prototype.groupProjects = function (projects, csv) {
                    var _this = this;
                    var statusKey = csv ? 'statusName' : 'status';
                    var assigneeKey = csv ? 'assignee' : 'assigneeName';
                    var systemKey = csv ? 'systemTypeName' : 'buildingSystem';
                    var typeKey = csv ? 'projectType' : 'type';
                    var idKey = csv ? 'identificationSource' : 'identifyingOrg';
                    var scopeKey = csv ? 'projectGroupsCSV' : 'projectGroups';
                    var groupBy = this.$scope.config.groupBy;
                    this.$scope[scopeKey] = [];
                    var groupMap;
                    switch (groupBy) {
                        case 'buildingName':
                            groupMap = _.groupBy(projects, function (project) { return project.buildingName; });
                            break;
                        case 'status':
                            groupMap = _.groupBy(projects, function (project) { return project[statusKey]; });
                            break;
                        case 'assigneeName':
                            groupMap = _.groupBy(projects, function (project) { return project[assigneeKey]; });
                            break;
                        case 'buildingSystem':
                            groupMap = _.groupBy(projects, function (project) { return project[systemKey]; });
                            break;
                        case 'priority':
                            groupMap = _.groupBy(projects, function (project) { return project.priority; });
                            break;
                        case 'type':
                            groupMap = _.groupBy(projects, function (project) { return project[typeKey]; });
                            break;
                        case 'identifyingOrg':
                            groupMap = _.groupBy(projects, function (project) { return project[idKey]; });
                            break;
                        default:
                            groupMap = { '': projects };
                    }
                    if (groupBy !== this.sortColumn) {
                        _.each(groupMap, function (value, key) {
                            _this.$scope[scopeKey].push({
                                name: key,
                                projects: _this.sortColumn
                                    ? _.orderBy(value, _this.alternateSortFieldMap[_this.sortColumn] || _this.sortColumn, _this.sortDirection)
                                    : value
                            });
                        });
                    }
                    else {
                        var keys = _.orderBy(Object.keys(groupMap), null, this.sortDirection);
                        _.each(keys, function (key) {
                            _this.$scope[scopeKey].push({
                                name: key,
                                projects: groupMap[key]
                            });
                        });
                    }
                    this.processProjects(this.$scope[scopeKey], csv);
                };
                ProjectListWidgetCtrl.prototype.processProjects = function (projects, csv) {
                    var _this = this;
                    var scopeKey = csv ? 'projectsProcessedCSV' : 'projectsProcessed';
                    this.$scope[scopeKey] = [];
                    // wipe previously collapsed data
                    this.projectGroupsCollapsedState = {};
                    _.each(projects, function (projectGroup) {
                        var _a;
                        if (projectGroup.name) {
                            _this.$scope[scopeKey].push({
                                isHeader: true,
                                name: projectGroup.name
                            });
                            // add in collapsable state for each group
                            if (!csv) {
                                _this.projectGroupsCollapsedState[projectGroup.name] = false;
                            }
                        }
                        (_a = _this.$scope[scopeKey]).push.apply(_a, projectGroup.projects);
                    });
                };
                ProjectListWidgetCtrl.prototype.toggleCollapseGroup = function (groupName) {
                    this.projectGroupsCollapsedState[groupName] = !this.projectGroupsCollapsedState[groupName];
                };
                return ProjectListWidgetCtrl;
            }());
            widgets.ProjectListWidgetCtrl = ProjectListWidgetCtrl;
            angular.module('aq.dashboard.widgets').controller('ProjectListWidgetCtrl', ProjectListWidgetCtrl);
        })(widgets = dashboard.widgets || (dashboard.widgets = {}));
    })(dashboard = aq.dashboard || (aq.dashboard = {}));
})(aq || (aq = {}));
