namespace aq.projectCenter {
    export class ProjectService {
        private DATE_FORMAT = 'YYYY-MM-DD';

        /* @ngInject */
        constructor(
            private DataStore: aq.common.DataStore,
            private RestangularV3: restangular.IService
        ) {

        }

        public create(project: Project) {
            let copy = angular.copy(project);
            copy = this.transformForRequest(copy);
            return this.DataStore.create(this.RestangularV3.all('projects'), copy, {buildingId: project.building});
        }

        public get(projectId: number, users: aq.common.models.User[], projectTypes: aq.common.models.SimpleEnum) {
            return this.DataStore.get( this.RestangularV3.one('projects', projectId), { }, false).then((result) => {
                return this.transformForView(result, users, projectTypes);
            });
        }

        public getList(buildingId: number, users: aq.common.models.User[], projectTypes: aq.common.models.SimpleEnum) {
            return this.DataStore.getList(this.RestangularV3.one(''), 'projects', { buildingId }).then((projects) => {
                projects.forEach((project) => {
                    this.transformForView(project, users, projectTypes);
                });
                return projects;
            });
        }

        public save(project: Project, users: aq.common.models.User[], projectTypes: aq.common.models.SimpleEnum) {
            const projectCopy: any = this.RestangularV3.copy(project);
            this.transformForRequest(projectCopy);
            return this.DataStore.update(projectCopy).then(result => {
                return this.transformForView(result, users, projectTypes);
            });
        }

        public delete(project) {
            return this.DataStore.delete(project);
        }

        public saveTask(project: Project, task: Task) {
            const taskCopy: any = this.RestangularV3.copy(task);
            taskCopy.dueDate = taskCopy.dueDate ? moment(taskCopy.dueDate).format(this.DATE_FORMAT) : null;
            if (!task.id) {
                return this.DataStore.create(this.RestangularV3.one('projects', project.id).all('tasks'), taskCopy);
            } else {
                if (taskCopy.complete && !taskCopy.completionDate) {
                    taskCopy.completionDate = moment().format(this.DATE_FORMAT);
                }
                if (!taskCopy.complete && taskCopy.completionDate) {
                    taskCopy.completionDate = null;
                }
                delete taskCopy.complete;
                return this.RestangularV3
                    .one('projects', project.id)
                    .one('tasks', task.id)
                    .customPUT(taskCopy)
                    .then((result) => {
                        result.dueDate = result.dueDate ? moment(result.dueDate).toDate() : null;
                        return result;
                    });
            }
        }

        public deleteTask(project: Project, task: Task) {
            const restangularizedTask = this.RestangularV3.restangularizeElement(project, task, 'tasks');
            return this.DataStore.delete(restangularizedTask);
        }

        public getTasksAssignedToUser(user: aq.common.models.User) {
            return this.DataStore.getList(this.RestangularV3.one('projects'), 'all-tasks', {userId: user.id});
        }

        // ------------------------

        private transformForView(project: Project, users: aq.common.models.User[], projectTypes: aq.common.models.SimpleEnum) {
            project.basePeriodEndDate = project.basePeriodEndDate ? moment(project.basePeriodEndDate).toDate() : null;
            project.basePeriodStartDate = project.basePeriodStartDate ? moment(project.basePeriodStartDate).toDate() : null;
            project.reportingPeriodEndDate = project.reportingPeriodEndDate ? moment(project.reportingPeriodEndDate).toDate() : null;
            project.reportingPeriodStartDate = project.reportingPeriodStartDate ? moment(project.reportingPeriodStartDate).toDate() : null;
            project.implementationPeriodEndDate = project.implementationPeriodEndDate ? moment(project.implementationPeriodEndDate).toDate() : null;
            project.implementationPeriodStartDate = project.implementationPeriodStartDate ? moment(project.implementationPeriodStartDate).toDate() : null;
            project.user = _.find(users, {id: project.owner});
            if (project.status === 'MEASUREMENT') {
                project.currentStartDate = project.reportingPeriodStartDate ? moment(project.reportingPeriodStartDate).toDate() : null;
                project.currentEndDate = project.reportingPeriodEndDate ? moment(project.reportingPeriodEndDate).toDate() : null;
            } else if (project.status === 'TODO') {
                project.currentStartDate = project.basePeriodStartDate ? moment(project.basePeriodStartDate).toDate() : null;
                project.currentEndDate = project.basePeriodEndDate ? moment(project.basePeriodEndDate).toDate() : null;
            } else if (project.status === 'IMPLEMENTATION') {
                project.currentStartDate = project.implementationPeriodStartDate ? moment(project.implementationPeriodStartDate).toDate() : null;
                project.currentEndDate = project.implementationPeriodEndDate ? moment(project.implementationPeriodEndDate).toDate() : null;
            }
            if (project.tasks) {
                project.totalTasks = project.tasks.length;
                project.completedTasks = project.tasks.filter(task => {
                    return task.completionDate;
                }).length;
                if (project.totalTasks > 0) {
                    project.completedTaskRatio = (project.completedTasks / project.totalTasks) * 100;
                }
            }
            const projectType = projectTypes[project.type];
            if (projectType) {
                project.typeLabel = projectType.label;
            }
            return project;
        }

        private transformForRequest(project: Project) {
            delete project.user;
            delete project.tasks;
            delete project.currentStartDate;
            delete project.currentEndDate;
            delete project.totalTasks;
            delete project.completedTasks;
            delete project.completedTaskRatio;
            delete project.typeLabel;
            project.basePeriodStartDate = project.basePeriodStartDate ?
                moment(project.basePeriodStartDate).format(this.DATE_FORMAT) : null;
            project.basePeriodEndDate = project.basePeriodEndDate ?
                moment(project.basePeriodEndDate).format(this.DATE_FORMAT) : null;
            project.implementationPeriodEndDate = project.implementationPeriodEndDate ?
                moment(project.implementationPeriodEndDate).format(this.DATE_FORMAT) : null;
            project.implementationPeriodStartDate = project.implementationPeriodStartDate ?
                moment(project.implementationPeriodStartDate).format(this.DATE_FORMAT) : null;
            project.reportingPeriodStartDate = project.reportingPeriodStartDate ?
                moment(project.reportingPeriodStartDate).format(this.DATE_FORMAT) : null;
            project.reportingPeriodEndDate = project.reportingPeriodEndDate ?
                moment(project.reportingPeriodEndDate).format(this.DATE_FORMAT) : null;
            return project;
        }
    }
    angular
    .module('projectCenter')
    .service('ProjectService', ProjectService);
}
