namespace aq.energyInsights {

    import HighchartsBarChartSeriesOptions = __Highcharts.BarChartSeriesOptions;
    import HighchartsDataPoint = __Highcharts.DataPoint;
    import HighchartsLineChartSeriesOptions = __Highcharts.LineChartSeriesOptions;
    import HighchartsOptions = __Highcharts.Options;
    import HighchartsPlotLines = __Highcharts.PlotLines;

    export class BaseloadCtrl {
        /* @ngInject */
        constructor(private $scope: BaseloadCtrlScope,
                    private Restangular: restangular.IService,
                    private account: any,
                    private currentBuilding: any,
                    private $filter:ng.IFilterService,
                    private $window: ng.IWindowService,
                    private EnergyInsightsDataService: aq.energyInsights.service.EnergyInsightsDataService,
                    private loading,
                    private BaseloadService: aq.energyInsights.BaseloadService,
                    private Auth: aq.services.Auth

        ) {
            loading.start();
            $scope.selectedMonthBaseloadStats = [];
            $scope.periodSearch = {
                start: aq.common.TimeConfiguration.This.Year.start(this.currentBuilding.timeZoneId),
                end: aq.common.TimeConfiguration.This.Year.end(this.currentBuilding.timeZoneId),
                interval: angular.copy(_.find(aq.common.TimeConfiguration.This.Year.intervals, {value: "1mon"}))
            };
            let params = {
                start: this.$scope.periodSearch.start,
                end: this.$scope.periodSearch.end,
                interval: "1d"
            };
            this.EnergyInsightsDataService.queryBaseloadStats(currentBuilding, params).then((result) => {
                this.$scope.monthBaseloadStats = result.months;
                this.calculateQuestionModel();
                this.$scope.monthBaseloadChartConfig = this.buildMonthBaseloadChartConfig(this.$scope.monthBaseloadStats);
                loading.stop();
            });

            //TODO: update this to pull from targets once implemented
            $scope.targetRatio = 50;
            $scope.targetGoodDays = 70;

            $scope.getPercent = (part, total) => {
                if(total > 0) {
                    return (part / total) * 100;
                }
                else return 0;
            };
        }

        selectMonth = (monthBaseloadStats: MonthBaseloadStats) => {
            if(monthBaseloadStats) {
                this.internalSelectMonth(monthBaseloadStats);
                let point = _.find(this.$scope.monthBaseloadChart.series[0].data, {x : monthBaseloadStats.date});
                if (point) {
                    point["doDrilldown"](); // access "private" function to trigger drilldown
                }
            }
        };

        private internalSelectMonth = (monthBaseloadStats: MonthBaseloadStats) => {
            this.$scope.selectedMonthBaseload = monthBaseloadStats;
            this.$scope.periodSearch.start = moment.tz(monthBaseloadStats.date, this.currentBuilding.timeZoneId).startOf('month');
            this.$scope.periodSearch.end = moment.tz(monthBaseloadStats.date, this.currentBuilding.timeZoneId).endOf('month');
            this.$scope.periodSearch.interval.value = "1d";
            this.$scope.dayBaseloadStats = monthBaseloadStats.days;
            this.calculateQuestionModel();
        };

        goToOptimization() {
            this.account.get({ single: true }).then((thisAccount) => {
                let optimizationURI = URI("/accounts/" + thisAccount.id + "/optimization/building")
                    .search({
                        unit: "KW",
                        apiUnit: "POWER",
                        interval: this.$scope.periodSearch.interval.value === '1mon' ? '1mon' : '1h',
                        off: _.without(thisAccount.buildings, this.currentBuilding.id).join(","),
                        start: this.$scope.periodSearch.start.tz(this.currentBuilding.timeZoneId).format(),
                        end: this.$scope.periodSearch.end.tz(this.currentBuilding.timeZoneId).format(),
                        children: "buildings"
                    });
                this.$window.location.href = optimizationURI.toString();
            });
        }

        queryPeriodSelectorChange(startDate, endDate, interval, label) {
            this.$scope.periodSearch.label = label;
            let noTzFormat = "YYYY-MM-DDTHH:mm:ss";
            // select this month, this year, this month, this year - then see label 1 month ~= value 1d
            let params = {
                start:moment(startDate).tz(this.currentBuilding.timeZoneId).add(1, 'days').format(),
                end: moment(endDate).tz(this.currentBuilding.timeZoneId).format(),
                interval: interval.value
            };
            this.$scope.periodSearch.start = startDate;
            this.$scope.periodSearch.end = endDate;
            this.$scope.monthBaseloadChart.showLoading();
            this.EnergyInsightsDataService.queryBaseloadStats(this.currentBuilding, params).then((result: MonthBaseloadStatsResult) => {
                this.$scope.monthBaseloadChart.hideLoading();
                if (this.$scope.monthBaseloadChart.get('goodDays')) {
                    this.$scope.monthBaseloadChart.get('goodDays').remove();
                }
                if (this.$scope.monthBaseloadChart.get('dayBaseloadRatio')) {
                    this.$scope.monthBaseloadChart.get('dayBaseloadRatio').remove();
                }
                if (this.$scope.monthBaseloadChart.get('baseloadRatio')) {
                    this.$scope.monthBaseloadChart.get('baseloadRatio').remove();
                }
                if (this.$scope.monthBaseloadChart.get('targetRatio')) {
                    this.$scope.monthBaseloadChart.get('targetRatio').remove();
                }
                if(params.interval === '1d') {
                    this.$scope.monthBaseloadChart.yAxis[1].update({
                        opposite: false
                    });
                    this.$scope.monthBaseloadChart.yAxis[0].update({
                        title: {
                            text: ""
                        }
                    });
                    this.$scope.monthBaseloadChart.xAxis[0].update({
                        plotLines: null
                    });
                    let days = _.flatten(_.map(result.months, (month) => {
                        return month.days;
                    }));
                    this.$scope.dayBaseloadStats = days;
                    this.$scope.monthBaseloadChart.addSeries(this.buildDayBaseloadRatioSeries(this.$scope.dayBaseloadStats));
                    this.$scope.monthBaseloadChart.addSeries(this.buildTargetRatioSeries(this.$scope.dayBaseloadStats));
                    if (result.months[0]) {
                        this.internalSelectMonth(result.months[0]);
                    }
                } else if(params.interval === '1mon') {
                    this.$scope.monthBaseloadStats = result.months;
                    this.$scope.monthBaseloadChart.xAxis[0].update({
                        plotLines: this.plotLines(<number[]> _.map(this.$scope.monthBaseloadStats, 'date'), 'YYYY')
                    });
                    this.$scope.monthBaseloadChart.yAxis[1].update({
                        opposite: true
                    });
                    this.$scope.monthBaseloadChart.yAxis[0].update({
                        title: {
                            text: "Good Days"
                        }
                    });
                    this.$scope.monthBaseloadChart.addSeries(this.buildGoodDaysSeries(result.months));
                    this.$scope.monthBaseloadChart.addSeries(this.buildMonthBaseloadRatioSeries(result.months));
                }
                this.calculateQuestionModel();
            });
        }

        downloadCsv() {
            let csvContent = 'data:text/csv;charset=utf-8,';
            let fileName = "";
            if(this.$scope.periodSearch.interval.value === "1mon") {
                csvContent += [
                    "Month", "Good Days", "Missed Days", "Baseload Average", "Average Ratio"
                ].join(',') + '\n';
                this.$scope.monthBaseloadStats.forEach((month) => {
                    csvContent += [
                        moment(month.date).format("MM/DD/YYYY"), month.numGoodDays, month.numBadDays, (month.avgBaseload ? month.avgBaseload : 0) + ' kW', (month.avgRatio ? month.avgRatio + '%' : null)
                    ].join(',') + '\n';
                });
                fileName = `${moment(this.$scope.periodSearch.start).format('YYYY')}_Baseload_Ratio_Export.csv`;
            } else if(this.$scope.periodSearch.interval.value === '1d') {
                csvContent += [
                    "Day", "Top Load", "Base Load", "Ratio"
                ].join(',') + '\n';
                this.$scope.dayBaseloadStats.forEach((day) => {
                    csvContent += [
                        moment(day.date).format('MM/DD/YYYY'), (day.topload ? day.topload : 0) + ' kW', (day.baseload ? day.baseload : 0) + ' kW',  (day.ratio ? day.ratio + '%' : null)
                    ].join(',') + '\n';
                });
                fileName = `${moment(this.$scope.periodSearch.start).format("MMMM_YYYY")}_Baseload_Ratio_Export.csv`;
            }
            let link = document.createElement('a');
            link.setAttribute('href', encodeURI(csvContent));
            link.setAttribute('download', fileName);
            link.click();
        }

        private calculateQuestionModel = () => {
            return this.BaseloadService.getTargetModel(this.currentBuilding, 'ELECTRICITY', this.$scope.periodSearch)
            .then((targetModel) => {
                this.$scope.monthTotals = new MonthBaseloadSummary;
                this.$scope.dayTotals = new DayBaseloadSummary;
                let goodDays = 0;
                let totalDays = 0;
                let badDays = 0;
                let monthsWithData = 0;
                this.$scope.questionModel = targetModel;
                if(this.$scope.periodSearch.interval.value === '1mon') {
                    this.$scope.monthBaseloadStats.forEach((stat) => {
                        goodDays += stat.numGoodDays ? stat.numGoodDays : 0;
                        badDays += stat.numBadDays ? stat.numBadDays : 0;
                        if(stat.numGoodDays + stat.numBadDays > 0){
                            monthsWithData++;
                        }
                    });
                    totalDays = goodDays + badDays;
                    this.$scope.monthTotals.numGoodDays = goodDays;
                    this.$scope.monthTotals.numBadDays = badDays;
                    this.$scope.monthTotals.avgBaseload = _.sumBy(this.$scope.monthBaseloadStats, 'avgBaseload') / monthsWithData;
                    this.$scope.monthTotals.avgRatio = _.sumBy(this.$scope.monthBaseloadStats, 'avgRatio') / monthsWithData;
                } else if(this.$scope.periodSearch.interval.value === '1d') {
                    this.$scope.dayBaseloadStats.forEach((stat) => {
                        if(stat.ratio != null) {
                            if(stat.ratio <= this.$scope.targetRatio) {
                                goodDays++;
                            } else {
                                badDays++;
                            }
                        }
                    })
                    totalDays = goodDays + badDays;
                    this.$scope.dayTotals.topload = _.sumBy(this.$scope.dayBaseloadStats, 'topload');
                    this.$scope.dayTotals.baseload = _.sumBy(this.$scope.dayBaseloadStats, 'baseload');
                    this.$scope.dayTotals.ratio = _.sumBy(this.$scope.dayBaseloadStats, 'ratio') / totalDays;
                }
            });
        };

        private buildMonthBaseloadChartConfig(data: MonthBaseloadStats[]) : HighchartsOptions {
            return {
                lang: {
                    drillUpText: "< Back to previous selection",
                    noData: "No baseload data to display."
                },
                drilldown: {
                    activeAxisLabelStyle: {
                        textDecoration: 'none',
                        fontWeight: 'normal',
                        color: "#666666"
                    }
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: false
                        }
                    }
                },
                plotOptions: {
                    series: {
                        stickyTracking: false
                    }
                },
                tooltip: {
                    snap: 0
                },
                chart: {
                    plotBorderWidth: 1,
                    events: {
                        drilldown: (e:any) => {
                            let month = moment(e.point.x);
                            this.$scope.periodSearch.interval = {value: "1d"};

                            this.$scope.drillUp = {
                                start: this.$scope.periodSearch.start, end: this.$scope.periodSearch.end
                            };
                            this.$scope.periodSearch.start = moment.tz(month, this.currentBuilding.timeZoneId).startOf('month');
                            this.$scope.periodSearch.end = moment.tz(month, this.currentBuilding.timeZoneId).add(1, 'month').startOf('month');
                            this.$scope.monthBaseloadChart.yAxis[1].update({
                                opposite: false
                            });
                            this.$scope.monthBaseloadChart.yAxis[0].update({
                                title: {
                                    text: ""
                                }
                            });
                            let days = _.find(this.$scope.monthBaseloadStats, {date: e.point.x}).days;
                            this.$scope.dayBaseloadStats = days;
                            this.$scope.monthBaseloadChart["addSingleSeriesAsDrilldown"](e.point, this.buildTargetRatioSeries(this.$scope.dayBaseloadStats));
                            this.$scope.monthBaseloadChart["addSingleSeriesAsDrilldown"](e.point, this.buildDayBaseloadRatioSeries(days));
                            this.$scope.monthBaseloadChart.xAxis[0].removePlotLine("yearSeparator");
                            this.$scope.monthBaseloadChart["applyDrilldown"]();
                            this.calculateQuestionModel();
                        },
                        drillup: (e) => {
                            this.$scope.periodSearch.interval.value = "1mon";
                            if (this.$scope.drillUp) {
                                this.$scope.periodSearch.start = this.$scope.drillUp.start;
                                this.$scope.periodSearch.end = this.$scope.drillUp.end;
                                delete this.$scope.drillUp;
                            }

                            this.$scope.monthBaseloadChart.xAxis[0].update({
                                plotLines: this.plotLines(<number[]> _.map(this.$scope.monthBaseloadStats, 'date'), 'YYYY')
                            });
                            this.$scope.monthBaseloadChart.yAxis[1].removePlotLine("targetRatio");
                            this.$scope.monthBaseloadChart.yAxis[1].removePlotLine("dayBaseloadRadio");
                            this.$scope.monthBaseloadChart.yAxis[1].update({
                                opposite: true
                            });
                            this.$scope.monthBaseloadChart.yAxis[0].update({
                                title: {
                                    text: "Good Days"
                                }
                            });
                            this.calculateQuestionModel();
                        }
                    }
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime',
                    tickLength: 0,
                },
                yAxis: [{
                    title: {
                        text: 'Good Days'
                    },
                    min: 0
                },{
                    title: {
                        text: 'Baseload Ratio (%)'
                    },
                    min: 0,
                    opposite: true
                }],
                series: [ this.buildGoodDaysSeries(data), this.buildMonthBaseloadRatioSeries(data)]
            }
        }

        private buildGoodDaysSeries(monthBaseloadStats: MonthBaseloadStats[]): HighchartsBarChartSeriesOptions {
            return {
                id: "goodDays",
                name: "Good Days",
                type: 'column',
                pointWidth: 20,
                tooltip: {
                    pointFormatter: function() {
                        return `<b>${this.series.name}: ${this.tooltip}</b><br/>`;
                    }
                },
                color: '#0091F1',
                legendIndex: 1,
                data: this.buildGoodDaysData(monthBaseloadStats)
            }
        }

        private buildGoodDaysData(monthBaseloadStats: MonthBaseloadStats[]) : HighchartsDataPoint[]{
            let data = monthBaseloadStats.map((monthBaseloadStat) =>{
                let dataPoint = {
                    x: monthBaseloadStat.date,
                    y: monthBaseloadStat.numGoodDays,
                    name: moment(monthBaseloadStat.date).tz(this.currentBuilding.timeZoneId).format('MMMM YYYY'),
                    tooltip: monthBaseloadStat.numGoodDays
                };
                dataPoint["drilldown"] = true; // because the type declaration is wrong
                monthBaseloadStat.point = dataPoint;
                return dataPoint;
            });
            return data;
        }

        private buildTargetRatioSeries(dayBaseloadStats: DayBaseloadStats[]): HighchartsLineChartSeriesOptions {
            let firstDay = dayBaseloadStats[0];
            let lastDay = dayBaseloadStats[dayBaseloadStats.length - 1];
            return {
                id: "targetRatio",
                name: "Target Daily Ratio",
                type: "line",
                color: "#0091f1",
                marker: {
                    symbol: "circle"
                },
                data: [{
                    x: firstDay.date,
                    y: this.$scope.targetRatio
                }, {
                    x: lastDay.date,
                    y: this.$scope.targetRatio
                }]
            }
        }

        private buildMonthBaseloadRatioSeries(monthBaseloadStats: MonthBaseloadStats[]): HighchartsLineChartSeriesOptions {
            return {
                id: "baseloadRatio",
                name: "Baseload Ratio",
                type: 'line',
                marker: {
                    symbol: "circle"
                },
                color: '#7ACD46',
                tooltip: {
                    pointFormatter: function() {
                        return `<span>Average Baseload: ${this.tooltip.avgBaseload}</span><br /><span>   </span><br />
                        <b>${this.series.name}: ${this.tooltip.avgRatio}</b><br/>`;
                    }
                },
                legendIndex: 2,
                data: this.buildMonthBaseloadRatioData(monthBaseloadStats),
                yAxis: 1,
            }
        }

        private buildMonthBaseloadRatioData(monthBaseloadStats: MonthBaseloadStats[]): HighchartsDataPoint[] {
            let data = monthBaseloadStats.map( (monthBaseloadStat)=>{
                if (monthBaseloadStat && monthBaseloadStat.avgRatio != null) {
                    let dataPoint = {
                        x: monthBaseloadStat.date,
                        y: monthBaseloadStat.avgRatio,
                        name: moment(monthBaseloadStat.date).format('MMMM YYYY'),
                        tooltip: {  avgRatio: monthBaseloadStat.avgRatio ? this.$filter('number')(monthBaseloadStat.avgRatio, '0,0') + '%' : '-',
                                    avgBaseload: monthBaseloadStat.avgBaseload ? this.$filter('number')(monthBaseloadStat.avgBaseload, '0,0') + ' kW' : '-'
                                }
                    };
                    dataPoint["drilldown"] = true; //because the type declaration is wrong
                    return dataPoint;
                }
            });
            return _.without(data, undefined);
        }

        private buildDayBaseloadRatioSeries(dayBaseloadStats: DayBaseloadStats[]): HighchartsLineChartSeriesOptions {
            return {
                id: "dayBaseloadRatio",
                name: "Daily Baseload Ratio",
                type: 'line',
                marker: {
                    symbol: "circle"
                },
                tooltip: {
                    pointFormatter: function() {
                        return `<span>Baseload: ${this.tooltip.baseload}</span><br />
                        <span>Topload: ${this.tooltip.topload}</span><br /><span>   </span><br />
                        <b>${this.series.name}: ${this.tooltip.ratio}</b><br/>`;
                    }
                },
                color: '#488B49',
                data: this.buildDayBaseloadRatioData(dayBaseloadStats),
                zones: [{
                    value: this.$scope.targetRatio
                }, {
                    color: '#D0D2D3'
                }]
            }
        }

        private buildDayBaseloadRatioData(dayBaseloadStats: DayBaseloadStats[]): HighchartsDataPoint[] {
            let data = dayBaseloadStats.map( (dayBaseloadStat)=>{
                if (dayBaseloadStat && dayBaseloadStat.ratio != null) {
                    let dataPoint = {
                        x: dayBaseloadStat.date,
                        y: dayBaseloadStat.ratio,
                        name: moment(dayBaseloadStat.date).format('ll'),
                        tooltip: {  ratio: dayBaseloadStat.ratio ? this.$filter('number')(dayBaseloadStat.ratio, '0,0') + '%' : '-',
                                    baseload: dayBaseloadStat.baseload ? this.$filter('number')(dayBaseloadStat.baseload, '0,0') + ' kW' : '-',
                                    topload: dayBaseloadStat.topload ? this.$filter('number')(dayBaseloadStat.topload, '0,0') + ' kW' : '-'
                                }
                    };
                    return dataPoint;
                }
            });
            return _.without(data, undefined);
        }

        private plotLines = (rawDateList: number[], formatter: 'YYYY'|'MM') : HighchartsPlotLines[] => {
            let thisPeriod:string = moment.tz(rawDateList[0], this.currentBuilding.timeZoneId).format(formatter);
            let plotLines:HighchartsPlotLines[] = [];
            for (var index = 1; index < rawDateList.length; index++) {
                let nextPeriod = moment.tz(rawDateList[index], this.currentBuilding.timeZoneId).format(formatter);
                if (thisPeriod != nextPeriod) {
                    if (formatter === 'YYYY') {
                        let plotLineLocation = rawDateList[index] - ((rawDateList[index] - rawDateList[index - 1]) / 2);
                        plotLines.push({
                            id: 'yearSeparator',
                            color: '#858585',
                            width: 1,
                            value: plotLineLocation,
                            zIndex: 5,
                            label: {text: nextPeriod, verticalAlign: 'bottom', rotation: 0, y: -4,
                                style: {color: '#858585'} }});
                    } else {
                        plotLines.push({
                            id: 'monthSeparator',
                            color: '#858585',
                            width: 1,
                            value: index - 0.5,
                            zIndex: 5});
                    }
                    thisPeriod = nextPeriod;
                }
            }
            return plotLines;
        };
    }

    angular.module('energyInsights').controller('BaseloadCtrl', BaseloadCtrl);
}
