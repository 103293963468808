var aq;
(function (aq) {
    var energyInsights;
    (function (energyInsights) {
        var CostService = /** @class */ (function (_super) {
            __extends(CostService, _super);
            /* @ngInject */
            function CostService($filter, $q, EnergyInsightsDataService, $translate, Auth) {
                var _this = _super.call(this) || this;
                _this.$filter = $filter;
                _this.$q = $q;
                _this.EnergyInsightsDataService = EnergyInsightsDataService;
                _this.$translate = $translate;
                _this.Auth = Auth;
                return _this;
            }
            CostService.prototype.getSimpleTargetModel = function (building, measure, periodSearch) {
                var targetModel = new energyInsights.SimpleTargetModel();
                var measureTxt = this.$filter('text')(measure, 'capitalize');
                var insightUnit = this.$translate.instant("measures." + measureTxt);
                targetModel.title = this.$translate.instant('insight.Spending', { val: insightUnit });
                targetModel.when = this.$translate.instant('insight.when.Cost');
                return targetModel;
            };
            CostService.prototype.getTargetModel = function (building, measure, periodSearch, account) {
                var _this = this;
                return this.getTableModel(building, measure, periodSearch, account)
                    .then(function (transformedData) {
                    return _this.calculateTargetModel(transformedData, building, periodSearch, measure);
                });
            };
            CostService.prototype.getGraphModel = function (building, measure, timePeriod, account) {
                return null;
            };
            CostService.prototype.getTableModel = function (building, measure, periodSearch, account) {
                var _this = this;
                var measureText = measure.toLowerCase();
                return this.$q.all([
                    this.EnergyInsightsDataService.getUtilitySpendingCostBreakdown(building, periodSearch.start, periodSearch.end, periodSearch.interval.value, measure),
                    this.EnergyInsightsDataService.getBudgetItems(building, measureText, periodSearch.start, periodSearch.end, periodSearch.interval, account)
                ]).then(function (values) {
                    var costData = values[0];
                    var budgetData = values[1];
                    return _this.transformData(building, costData, budgetData, periodSearch);
                });
            };
            CostService.prototype.formatTableModel = function () {
                throw new TypeError('This is a stub method');
            };
            CostService.prototype.calculateTargetModel = function (transformedData, building, periodSearch, measure, todaysDate) {
                var reportDate;
                if (todaysDate) {
                    reportDate = todaysDate;
                }
                else {
                    reportDate = moment();
                }
                var today = reportDate.format('MMM Do');
                var startOfMonth = reportDate.clone().startOf('month');
                var numberOfDaysInMonth = reportDate.diff(startOfMonth, 'days');
                var percOfTime = this.getPercent(numberOfDaysInMonth, reportDate.daysInMonth());
                var targetModel = new energyInsights.TargetModel();
                var insight_unit = "" + this.$translate.instant("measures." + this.$filter('text')(measure, 'capitalize'));
                targetModel.title = this.$translate.instant('insight.Spending', { val: insight_unit });
                var data = transformedData.rows;
                targetModel.totals = transformedData.stats;
                targetModel.target = this.getTarget(data, reportDate);
                targetModel.showDonut = !_.isNull(targetModel.target);
                targetModel.showTarget = !_.isNull(targetModel.target);
                targetModel.formattedTarget = !_.isNull(targetModel.target) ? this.$translate.instant('insight.target.Budget') + " $" + this.$filter('number')(targetModel.target, '0,0') : null;
                targetModel.answer = this.getAnswer(data, reportDate);
                targetModel.percentage = this.getPercent(targetModel.answer, targetModel.target);
                targetModel.question = this.$translate.instant('insight.question.Cost') + " " + building.name + "?";
                targetModel.formattedAnswer = "$" + this.$filter('number')(targetModel.answer, '0,0');
                targetModel.unit = this.$translate.instant('insight.unit.Cost');
                targetModel.unit += this.$translate.instant("measures." + measure).toLowerCase();
                targetModel.when = this.$translate.instant('insight.when.Cost');
                var accountText = this.$translate.instant('insight.when.Account Text Plural');
                targetModel.when += " " + this.$translate.instant('insight.when.Cost 2') + " " + this.$translate.instant('insight.when.Cost 3') + " " + accountText;
                targetModel.color = this.getColor(targetModel.answer, targetModel.target, targetModel.percentage, percOfTime);
                targetModel.iconColor = targetModel.color;
                targetModel.tooltip = targetModel.percentage ?
                    this.$translate.instant('insight.tooltip.Cost1') + " <span style=\"color:" + targetModel.color + "\">" + targetModel.percentage + "%</span> " + this.$translate.instant('insight.tooltip.Cost2') + " " + today + "."
                    : '';
                targetModel.icon = this.getIcon(targetModel.answer, targetModel.target, targetModel.percentage, percOfTime);
                targetModel.timeElapsedPercentage = percOfTime;
                targetModel.timeElapsedLabel = today;
                targetModel.buildingId = building.id;
                targetModel.accountId = building.account;
                return this.$q.when(targetModel);
            };
            CostService.prototype.transformData = function (building, costData, budgetData, periodSearch) {
                var _this = this;
                var timezone = building.timeZoneId;
                var totals = {
                    budgetSum: 0,
                    projection: 0,
                    spend: 0,
                    blendedRate: null,
                    currencySymbol: costData.spending.unitSymbol,
                    demandRate: null
                };
                var rows = [];
                var chargeTypes = [
                    { name: 'usage', order: this.orderSeries('usage') },
                    { name: 'utility', order: this.orderSeries('utility') },
                    { name: 'projection', order: this.orderSeries('projection') }
                ];
                _.each(costData.spending.data, function (item) {
                    var row = new CostInsightRow(item.timestamp);
                    if (periodSearch.interval.value === '1mon') {
                        row.formattedTimestamp = moment(item.timestamp).tz(timezone).format('MMM YYYY');
                    }
                    else if (periodSearch.interval.value === '1d') {
                        row.formattedTimestamp = moment(item.timestamp).tz(timezone).format('dd MMM Do');
                    }
                    row.sum = 0;
                    row.localDate = item.localDate;
                    row.values['usage'] = item.datum.consumption;
                    row.values['utility'] = item.datum.utilityBill;
                    row.values['projection'] = item.datum.projected;
                    row.values['blendedRate'] = item.datum.blendedRate;
                    row.budgetSum = _this.findBudget(row.localDate, budgetData);
                    row.sum = _.sum(_.values(row.values));
                    rows.push(row);
                    totals.budgetSum += row.budgetSum;
                    totals.projection += item.datum.projected;
                    totals.spend += item.datum.consumption + item.datum.utilityBill;
                    if (totals.blendedRate == null) {
                        totals.blendedRate = item.datum.blendedRate;
                    }
                    if (totals.demandRate == null || totals.demandRate === 0) {
                        totals.demandRate = item.datum.demandRate;
                    }
                });
                chargeTypes = _.sortBy(chargeTypes, ['order']);
                return {
                    rows: rows,
                    stats: totals
                };
            };
            CostService.prototype.findBudget = function (localDate, budgets) {
                var budget = _.find(budgets, function (budgetToCheck) {
                    if (budgetToCheck) {
                        var startDate = moment(budgetToCheck.startDate);
                        var endDate = moment(budgetToCheck.endDate);
                        var whenDate = moment(localDate, 'MM/DD/YYYY');
                        return whenDate.isBetween(startDate, endDate) || whenDate.isSame(startDate) || whenDate.isSame(endDate);
                    }
                });
                if (budget) {
                    return budget.value;
                }
                else {
                    return null;
                }
            };
            CostService.prototype.getAnswer = function (data, date) {
                var start = _.cloneDeep(date.startOf('month'));
                var end = date.endOf('month');
                var row = _.find(data, function (d) {
                    var localDate = moment(d.localDate, 'MM/DD/YYYY');
                    return localDate.isBetween(start, end) || localDate.isSame(start) || localDate.isSame(end);
                });
                if (row) {
                    return row['sum'];
                }
                else {
                    return 0;
                }
            };
            CostService.prototype.getTarget = function (data, date) {
                var start = _.cloneDeep(date.startOf('month'));
                var end = date.endOf('month');
                var row = _.find(data, function (d) {
                    var localDate = moment(d.localDate, 'MM/DD/YYYY');
                    return localDate.isBetween(start, end) || localDate.isSame(start) || localDate.isSame(end);
                });
                if (row) {
                    return row['budgetSum'];
                }
                else {
                    return null;
                }
            };
            CostService.prototype.orderSeries = function (name) {
                if (name === 'Utility') {
                    return 1;
                }
                else if (name === 'Usage') {
                    return 2;
                }
                else if (name === 'Projection') {
                    return 3;
                }
                else {
                    return 0;
                }
            };
            ;
            return CostService;
        }(energyInsights.InsightService));
        energyInsights.CostService = CostService;
        var CostInsightRow = /** @class */ (function () {
            function CostInsightRow(timestamp) {
                this.timestamp = timestamp;
                this.values = {};
                this.sum = 0;
                this.budgetSum = null;
            }
            ;
            return CostInsightRow;
        }());
        angular.module('energyInsights').service('CostService', CostService);
    })(energyInsights = aq.energyInsights || (aq.energyInsights = {}));
})(aq || (aq = {}));
