angular.module('aq-main').run(function ($httpBackend) {
    const reportsRegex = /^\/api\/v3\/reports$/;
    const scheduledReportsRegex = /^\/api\/v3\/reports\/\d+\/scheduled-reports$/;
    const reportItemsRegex = /^\/api\/v3\/reports\/\d+\/report-items?.*$/;
    const downloadReportItemsRegex = /^\/api\/v3\/reports\/\d+\/report-items\/download?.*$/;
    const downloadSingleReportItemRegex = /^\/api\/v3\/reports\/\d+\/report-items\/\d+\/download$/;

    const reportsData = [
        { id: 25696578, name: 'portfolio', fullName: 'Portfolio Report', format: 'PDF', app: 18951660, reportType: 'portfolio', fixedInterval: true },
        { id: 19984671, name: 'summaryDaily', fullName: 'Daily Status', format: 'PDF', app: 18951660, reportType: 'daily', fixedInterval: true },
        { id: 19984672, name: 'summaryMonthly', fullName: 'Monthly Status', format: 'PDF', app: 18951660, reportType: 'monthly', fixedInterval: true },
        { id: 19984668, name: 'invoice', fullName: 'Tenant Invoice', format: 'PDF', app: 26816172, reportType: 'invoice', fixedInterval: false }
    ];

    const scheduledReportsData = [
        { id: 26510506, name: 'AQ Demo 2.0 Portfolio Report', report: 25696578, parameters: {}, recipients: [], account: 22485145 },
        { id: 26302717, name: 'Daily Status: 50 Fremont', report: 19984671, parameters: { buildingId: '22489632' }, recipients: [20958414, 19429932], account: 22485145 },
        { id: 26510509, name: 'Daily Status: 1200 McLean Blvd', report: 19984671, parameters: { buildingId: '22984268' }, recipients: [20958414], account: 22485145 },
        { id: 26510511, name: 'Daily Status: Redwood Office', report: 19984671, parameters: { buildingId: '22490253' }, recipients: [], account: 22485145 },
        { id: 12340000, name: 'Monthly Status: 50 Fremont', report: 19984672, parameters: { buildingId: '22489632' }, recipients: [19429932], account: 22485145 },
        { id: 56780000, name: 'Monthly Status: 1200 McLean Blvd', report: 19984672, parameters: { buildingId: '22984268' }, recipients: [20958414], account: 22485145 },
        { id: 12345678, name: 'Monthly Status: Redwood Office', report: 19984672, parameters: { buildingId: '22490253' }, recipients: [], account: 22485145 }
    ];

    _.each(scheduledReportsData, (item: aq.reportCenter.ScheduledReport) => item.scheduling = {
        dayOfMonth: 5,
        dayOfWeek: null,
        interval: 'MONTHLY',
        time: '07:00:00'
    });

    const reportItemsData = [
        //'AQ Demo 2.0 Portfolio Report'
        { id: 1, report: 25696578, reportName: 'portfolio', scheduledReport: 26510506, name: 'AQ Demo 2.0 Portfolio Report', generated: '2018-08-01T12:00:00Z' },
        { id: 2, report: 25696578, reportName: 'portfolio', scheduledReport: 26510506, generated: '2018-07-01T12:00:00Z' },
        { id: 3, report: 25696578, reportName: 'portfolio', scheduledReport: 26510506, generated: '2018-06-01T12:00:00Z' },
        //'Daily Status: 50 Fremont'
        { id: 4, report: 19984671, reportName: 'summaryDaily', scheduledReport: 26302717, name: 'Daily Status: 50 Fremont', generated: '2018-08-01T08:00:00Z' },
        { id: 5, report: 19984671, reportName: 'summaryDaily', scheduledReport: 26302717, name: 'Daily Status: 50 Fremont', generated: '2018-08-02T08:00:00Z' },
        { id: 6, report: 19984671, reportName: 'summaryDaily', scheduledReport: 26302717, name: 'Daily Status: 50 Fremont', generated: '2018-08-03T08:00:00Z' },
        //'Daily Status: 1200 McLean Blvd'
        { id: 7, report: 19984671, reportName: 'summaryDaily', scheduledReport: 26510509, name: 'Daily Status: 1200 McLean Blvd', generated: '2018-08-01T08:00:00Z' },
        { id: 8, report: 19984671, reportName: 'summaryDaily', scheduledReport: 26510509, name: 'Daily Status: 1200 McLean Blvd', generated: '2018-08-02T08:00:00Z' },
        { id: 9, report: 19984671, reportName: 'summaryDaily', scheduledReport: 26510509, name: 'Daily Status: 1200 McLean Blvd', generated: '2018-08-03T08:00:00Z' },
        //'Daily Status: Redwood Office'
        { id: 10, report: 19984671, reportName: 'summaryDaily', scheduledReport: 26510511, name: 'Daily Status: Redwood Office', generated: '2018-08-01T08:00:00Z' },
        { id: 11, report: 19984671, reportName: 'summaryDaily', scheduledReport: 26510511, name: 'Daily Status: Redwood Office', generated: '2018-08-02T08:00:00Z' },
        { id: 12, report: 19984671, reportName: 'summaryDaily', scheduledReport: 26510511, name: 'Daily Status: Redwood Office', generated: '2018-08-03T08:00:00Z' }
    ];

    MockContext('reportCenterV2', () => {
        $httpBackend.whenGET(reportsRegex).respond((method, url, data) => {
            return [200, reportsData, {}];
        });
        $httpBackend.whenGET(scheduledReportsRegex).respond((method, url, data) => {
            //_.each(scheduledReportsData, (item) => {
            //    if (typeof item.report === 'number') {
            //        item.report = _.find(reportsData, (br) => br.id == item.report);
            //    }
            //});
            return [200, scheduledReportsData, {}];
        });
        $httpBackend.whenPUT(scheduledReportsRegex).respond((method, url, data) => {
            const dataObj = JSON.parse(data);
            _.each(scheduledReportsData, (sr: aq.reportCenter.ScheduledReport) => {
                const dataItem: aq.reportCenter.ScheduledReport = _.find(dataObj, (item) => item.id == sr.id);
                if (!dataItem) {
                    return;
                }
                sr.recipients = dataItem.recipients;
                sr.scheduling = dataItem.scheduling;
            });
            return [200, scheduledReportsData, {}];
        });
        $httpBackend.whenGET(reportItemsRegex).respond((method, url, data) => {
            //_.each(reportItemsData, (item) => {
            //    if (typeof item.report === 'number') {
            //        item.report = _.find(reportsData, (br) => br.id == item.report);
            //    }
            //    if (typeof item.scheduledReport === 'number') {
            //        item.scheduledReport = _.find(scheduledReportsData, (sr) => sr.id == item.scheduledReport);
            //    }
            //});
            const queryParams = MockHelpers.getQueryParams(url) as any;
            const filteredReportItemsData = _.filter(reportItemsData, (item) => {
                const time = moment(item.generated);
                if (queryParams.dateStart && queryParams.dateEnd) {
                    const start = moment(parseInt(queryParams.dateStart[0]));
                    const end = moment(parseInt(queryParams.dateEnd[0])).add(1, 'day');
                    if (!time.isBetween(start, end)) {
                        return false;
                    }
                }
                if (queryParams.buildingId) {
                    if (!item.scheduledReport.parameters || item.scheduledReport.parameters.buildingId != queryParams.buildingId) {
                        return false;
                    }
                }
                if (queryParams.reportTypeId) {
                    if (!item.report || item.report.id != queryParams.reportTypeId) {
                        return false;
                    }
                }
                return true;
            });
            return [200, filteredReportItemsData, {}];
        });
        $httpBackend.whenGET(downloadReportItemsRegex).respond((method, url, data) => {
            return [200, '', {}];
        });
        $httpBackend.whenGET(downloadSingleReportItemRegex).respond((method, url, data) => {
            return [200, '', {}];
        });
    });
});
