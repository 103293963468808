'use strict';

angular.module('aq.admin.configuration', ['aq.admin.configuration.integrationApps', 'utilityCompany', 'aq.services'])
    .config(($stateProvider, $urlRouterProvider) => {
        $urlRouterProvider.when('/admin/configuration', '/admin/configuration/logs');
        $stateProvider.state('aq.admin.configuration', {
            url: '/configuration',
            templateUrl: 'app/admin/configuration/main.html',
            data: {
                appName: 'Global Defaults'
            }
        })
            .state('aq.admin.configuration.templateroles', {
                url: '/template-roles',
                templateUrl: 'app/accounts/roles/roles.html',
                controller: 'RolesCtrl as rolesCtrl',
                data: {
                    breadcrumb: 'Template Roles',
                    icon: '.aq-icons-roles'
                },
                resolve: {
                    roles: (Restangular, waitForAuthToken) => {
                        return Restangular.all('TemplateRoles').getList({ inflate: 'permissions' });
                    },
                    apps: (Restangular, waitForAuthToken) => {
                        return Restangular.one('Apps', 'queryNonAdminApps').get();
                    },
                    roleType: () => {
                        return 'Templates';
                    },
                    accesses: (Restangular, waitForAuthToken) => {
                        return Restangular.one('options', 'accesses').get();
                    }
                }
            })
            .state('aq.admin.configuration.templates', {
                url: '/document-templates',
                templateUrl: 'app/admin/configuration/templates/templates.html',
                controller: 'TemplatesCtrl as vm',
                data: {
                    breadcrumb: 'Templates',
                    icon: 'format_size'
                },
            });
    });
