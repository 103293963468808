namespace aq.crud {

    export class CrudEditModalCtrl {
        public onSaveFn: Function;
        public onDeleteFn: Function;
        public form;

        /* ngInject */
        constructor(
            private $scope,
            private $mdDialog: ng.material.IDialogService) {}

        cancel() {
            this.$mdDialog.cancel();
        }

        submitForm() {
            const onSaveResult = this.onSaveFn();
            if (onSaveResult && typeof onSaveResult.then === 'function') {
                onSaveResult.then((result) => {
                    this.$mdDialog.hide(result);
                });
            } else {
                this.$mdDialog.hide(this.$scope);
            }
        }

        close() {
            this.$mdDialog.hide(this.$scope);
        }

        $postLink() {
            this.form = this.$scope['crudEditModalForm'];
        }
    }

    angular
        .module('aq.ui')
        .component('aqCrudEditModal', {
            templateUrl: 'app/common/directives/crud/editModal.html',
            transclude: true,
            controller: CrudEditModalCtrl,
            bindings: {
                isEdit: '<',
                entityLabel: '@',
                onDeleteFn: '&',
                onSaveFn: '&',
                form: '=?'
            }
        });

}
