angular.module('aq.admin.meters.deviceclasses', ['ui.router', 'aq.services.restangular', 'aq.services', 'aq.services.errors', 'aq.messages', 'ng.jsoneditor'])
    .config(($stateProvider) => {
        $stateProvider.state('aq.admin.meters.deviceclasses', {
            url: '/device-classes',
            templateUrl: 'app/admin/meters/deviceClasses/main.html',
            controller: 'AdminDeviceClassesCtrl as deviceClassesCtrl',
            resolve: {
                deviceClasses: (RestangularV3, DataStore: aq.common.DataStore, waitForAuthToken) => {
                    return DataStore.getList(RestangularV3.one('devices'), 'device-classes');
                },
                collectorClasses: (Restangular, waitForAuthToken) => {
                    return Restangular.one('options', 'collectorClasses').getList();
                },
                identifierNames: (RestangularV3, waitForAuthToken) => {
                    return RestangularV3.one('devices').getList('identifier-names').then(identifierNames => {
                        identifierNames.map(identifierName => {
                            delete identifierName['originalElement'];
                        });
                        return RestangularV3.stripRestangular(identifierNames);
                    });
                },
                vendors: (RestangularV3, waitForAuthToken) => {
                    return RestangularV3.one('devices').getList('vendors');
                },
                deviceClassTypes: (Restangular, waitForAuthToken) => {
                    return Restangular.one('devices').getList('DeviceClassTypes').then(deviceClassTypes => {
                        deviceClassTypes.map(deviceClassType => {
                            delete deviceClassType['originalElement'];
                        });
                        return Restangular.stripRestangular(deviceClassTypes);
                    });
                },
                sensorTypes: (Restangular, waitForAuthToken) => {
                    return Restangular.one('devices').getList('SensorTypes');
                },
                networkConnectionTypes: (Restangular, waitForAuthToken) => {
                    return Restangular.one('devices').getList('NetworkConnectionTypes');
                },
                measures: (Restangular, waitForAuthToken) => {
                    return Restangular.one('measures').getList();
                },
                protocols: (DataStore: aq.common.DataStore, Restangular: restangular.IService, waitForAuthToken) => {
                    return DataStore.getList(Restangular.one('devices').all('protocols'));
                }
            },
            data: {
                breadcrumb: 'Device Classes',
                icon:'.aq-icons-meter-management'
            }
        });
    });
