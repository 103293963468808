namespace aq.ui {

    class _ActivityMetaDisplay {
        public activity: aq.models.activity.ActivityResponse;
        public accountId: number;
        private isStateAlert: boolean;
        private isAidIssue: boolean;
        private isEnergyNote: boolean;
        private isOptimalStart: boolean;
        private isPrescription: boolean;
        private realTimeEnergyAlert: aq.propertySettings.RealTimeEnergyAlert;
        private building: aq.common.models.Building;
        private issue: aq.models.activity.AlertIssue;
        private alertLoaded: Boolean;
        private noData: boolean;
        private contextLoaded: boolean;
        private noContext: boolean;
        private refreshActivities;
        private closeDialog;

        /* @ngInject */
        constructor(
            private RestangularV3: restangular.IService,
            private Restangular: restangular.IService,
            private DataStore: aq.common.DataStore,
            private resolver: aq.services.Resolver,
            private $scope: ng.IScope
        ) {
            this.noContext = false;
        }

        public $onChanges(changes) {
            if (changes.activity) {
                this.activity = angular.copy(changes.activity.currentValue);
            }
        }

        public $onInit() {
            if (aq.models.activity.ActivityContextType.NONE === this.activity.context.type) {
                this.noContext = true;
            }
            this.alertLoaded = false;
            this.noData = false;
            this.contextLoaded = false;

            this.isPrescription = (aq.models.activity.ActivityContextType.RX === this.activity.context.type);
            if (this.isPrescription) return;

            this.resolver(async () => {
                this.building = await this.RestangularV3.one('buildings', this.activity.building).get();
                this.isAidIssue = (aq.models.activity.ActivityContextType.AID_ISSUE === this.activity.context.type);
                this.isEnergyNote = (aq.models.activity.ActivityContextType.ENERGY_NOTE === this.activity.context.type);
                this.isOptimalStart = (aq.models.activity.ActivityContextType.OPTIMAL_START === this.activity.context.type);

                if (this.activity.context.type === aq.models.activity.ActivityContextType.ALERT) {
                    try {
                        const alertData = await this.RestangularV3.one('alert-data', this.activity.context.alert).get();
                        if (AlertType[alertData.alertType] === AlertType.REAL_TIME_ENERGY) {
                            const alertPromise = await this.RestangularV3
                                .one('usage-alerts', alertData.alertId)
                                .get();
                            const issuePromise = await this.RestangularV3
                                .one('building-alert-histories', this.activity.context.issue)
                                .one('summary')
                                .get();
                            const [alert, issue] = await Promise.all([alertPromise, issuePromise]);

                            if (!alert.drillMode) {
                                alert.drillMode = 'BUILDING';
                            }
                            if (!alert.queryableId && alert.queryable) {
                                alert.queryableId = alert.queryable.id;
                            }

                            this.realTimeEnergyAlert = alert;
                            this.realTimeEnergyAlert.issue = issue;

                            // setting openedOn and closedOn to values that make sense for activity charting.
                            // Used directly in alert-preview component
                            this.realTimeEnergyAlert.issue.closedOn = moment(this.realTimeEnergyAlert.issue.openedOn)
                                .add(4, 'hours')
                                .toISOString();
                            this.realTimeEnergyAlert.issue.openedOn = moment(this.realTimeEnergyAlert.issue.openedOn)
                                .subtract(4, 'hours')
                                .toISOString();
                            this.alertLoaded = true;
                        } else {
                            this.isStateAlert = true;
                            this.alertLoaded = true;
                        }
                        // AngularJS is not smart enough to follow chains of await calls so we need to manually tell it to update the UI with new data
                        this.$scope.$apply();
                    } catch (err) {
                        this.noDataCallback();
                        throw err;
                    }
                }
            });
        }

        public noDataCallback() {
            this.noData = true;
            if (!this.isPrescription) {
                this.$scope.$apply();
            }
        }

        public contextLoadedCallback() {
            this.contextLoaded = true;
            if (!this.isPrescription) {
                this.$scope.$apply();
            }
        }

        private refreshActivitiesAndCloseDialog() {
            this.refreshActivities();
            this.closeDialog();
        }
    }

    export const ActivityMetaDisplay: ng.IComponentOptions = {
        controller: _ActivityMetaDisplay,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/activities/contexts/activityMetaDisplay.html',
        bindings:  {
            activity: '<',
            accountId: '<',
            refreshActivities: '&',
            closeDialog: '&'
        }
    };

    export enum AlertType {
        STATE = 'STATE',
        REAL_TIME_ENERGY = 'REAL_TIME_ENERGY'
    }

    angular
        .module('aq.ui')
        .component('activityMetaDisplay', ActivityMetaDisplay);
}
