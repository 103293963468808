namespace aq.admin {
    export class AccountAdminConfigurationCtrl {
        public portfolioUsers;
        public selectedUser;
        private isAqAdmin = false;
        private userSearchText: string;

        /* @ngInject */
        constructor(
            private account,
            private $scope,
            private options,
            private timezones,
            private profiles,
            private UserManagementService: aq.services.UserManagementService,

        ) {
            this.$scope.$on('ACCOUNT_UPDATED', (event: any, context: any) => {
                this.account = context.account;
            });
            if (this.profiles.length > 0) {
                this.portfolioUsers = this.profiles.filter(profile => profile.portfolioUser);
            }
        }

        public queryUsers(search: string, portfolioUsers) {
            const searchText = search.toLowerCase();
            const users = this.profiles.filter((profile) => {
                return profile.user.fullName.toLowerCase().indexOf(searchText) > -1
                    && _.findIndex(portfolioUsers, { fullName: profile.user.fullName }) === -1;
            });
            return users;
        }

        public updatePortfolioUser(profile) {
            profile.portfolioUser = !profile.portfolioUser;
            return this.UserManagementService.saveUser(profile);
        }
    }

    angular
        .module('aq.admin.accounts.accounts')
        .controller('AccountAdminConfigurationCtrl', AccountAdminConfigurationCtrl);
}
