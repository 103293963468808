namespace aq.utilityBudgets {
    export class Meter {
        constructor(public id: string, public type: string) { }
    }

    export class MeasureType {
        constructor(public value: string, public label: string, public id: number) { }
    }

    export class RateCalculation {
        constructor(public value: string, public label: string, public id: number) { }
    }

    export class Unit {
        constructor(public usageRealUnit: string, public demandRealUnit: string) { }
    }

    export interface IMeasureArrayMap {
        [key: string]: any[];
    }

    export class UtilityServiceHelper {
        private measureTypes: MeasureType[];
        private intervalMeasureTypes: MeasureType[];
        private currencyUnit;
        private currencyUnitSymbol: string;

        /* @ngInject */
        constructor(
            private OptionsService
        ) {
            this.currencyUnit = this.OptionsService.currencyUnit();
            this.currencyUnitSymbol = this.currencyUnit.symbol;
            this.measureTypes = [
                new MeasureType('ELECTRICITY', 'Electricity', 1),
                new MeasureType('WATER', 'Water', 2),
                new MeasureType('GAS', 'Gas', 3),
                new MeasureType('STEAM', 'Steam', 4),
                new MeasureType('HEAT', 'Heat', 5)
            ];
            this.intervalMeasureTypes = [
                new MeasureType('ELECTRICITY', 'Electricity', 1),
                new MeasureType('WATER', 'Water', 2),
                new MeasureType('GAS', 'Gas', 3)
            ]
        }

        public getMeasureTypes(): MeasureType[] {
            return this.measureTypes;
        }
        public getIntervalMeasureTypes(): MeasureType[] {
            return this.intervalMeasureTypes;
        }
        public getCurrencyUnit() {
            return this.currencyUnit;
        }
        public getCurrencyUnitSymbol(): string {
            return this.currencyUnitSymbol;
        }
        public getMeasureUnit(measure: string): string {
            const measureUnit = this.OptionsService.getUnitLabelByMeasure(measure);
            return measureUnit ? measureUnit.unit : '';
        }
        public getMeasureTypeLabel(type: string): string {
            const measure = _.find(this.measureTypes, (m) => m.value == type);
            return measure ? measure.label : '';
        }
        public getUtilityCompaniesMeasureMap(utilityCompanies): IMeasureArrayMap {
            const map = this.initMeasureMap();
            utilityCompanies.forEach((utilityCompany) => {
                if (!utilityCompany.measure || !utilityCompany.measure.name) {
                    return;
                }
                const measure = utilityCompany.measure.name.toUpperCase();
                if (!map[measure]) {
                    return;
                }
                map[measure].push(utilityCompany);
            });
            return map;
        }
        public getAvailableCollectorsMeasureMap(utilityServices, collectors): IMeasureArrayMap {
            const map = this.initMeasureMap();
            const eligibleCollectors = _.filter(collectors, (collector: any) => {
                return collector.type !== 'VIRTUAL'
                    && !_.find(_['flatMap'](utilityServices, 'collectors'), { id: collector.id });
            });
            eligibleCollectors.forEach((collector) => {
                let belongsToMeasures = angular.copy(collector.metrics);
                const isVirtualMeter = collector.collectorClass === 'VIRTUAL_METER'
                    && collector.isUtilityMeter
                    && collector.virtualMeterEquation
                    && collector.virtualMeterEquation.collectors;
                if (isVirtualMeter) {
                    collector.virtualMeterEquation.collectors.forEach((vc) => {
                        belongsToMeasures = _.union(belongsToMeasures, vc.metrics);
                    });
                }
                belongsToMeasures.forEach((measure) => {
                    if (!map[measure]) {
                        return;
                    }
                    map[measure].push(collector);
                });
            });
            return map;
        }
        public getAllStatuses() {
            return [
                'ACTIVATED',
                'PENDING',
                'ERROR',
                'AUTHENTICATION_ERROR',
                'ACCOUNT_NOT_FOUND',
                'INACTIVE'
            ];
        }
        public getStatusView(status: string): UtilityAccountStatusViewModel {
            switch (status) {
                case 'ACTIVATED':
                    return {
                        value: 'activated',
                        icon: 'check_circle',
                        description: 'Activated',
                        helpText: 'Connection to Utility Provider is established'
                    };
                case 'PENDING':
                case 'SENT':
                    return {
                        value: 'pending',
                        icon: 'cached',
                        description: 'Pending',
                        helpText: 'Connection to Utility Provider is being established.<br>This process may take a week to complete.'
                    };
                case 'ERROR': {
                    return {
                        value: 'error',
                        icon: 'remove_circle',
                        description: 'Error',
                        helpText: 'Connection to Utility Provider could not be established.<br>If your Bill Pay URL and credentials are verified, reach out to Aquicore support to get things working!'
                    };
                }
                case 'AUTHENTICATION_ERROR': {
                    return {
                        value: 'error',
                        icon: 'account_circle',
                        description: 'Login Failure',
                        helpText: 'Supplied username and password are not valid for the selected Utility Provider.<br>Verify the username and password and URL to correct this error.'
                    };
                }
                case 'PROVIDER_NOT_FOUND': {
                    return {
                        value: 'error',
                        icon: 'cancel',
                        description: 'Utility Provider Not Found',
                        helpText: 'Could not identify the Utility Provider based on the selected Utility Company and URL.<br>The Correct Utility Company URL should be the same as used to login to the Utility Company'
                    };
                }
                case 'ACCOUNT_NOT_FOUND': {
                    return {
                        value: 'error',
                        icon: 'help',
                        description: 'Account Number Not Found',
                        helpText: 'Could not locate the Utility Account Number using the Utility Login provided<br>Verify the correct Account Number is saved to continue.'
                    };
                }
                case 'INACTIVE': {
                    return {
                        value: 'inactive',
                        icon: 'check_circle',
                        description: 'Account Inactive',
                        helpText: 'This account has not recieved bills for at least 100 days and may have been deactivated by your utility company'
                    };
                }
                default:
                    return {
                        value: 'not-connected',
                        icon: 'do_not_disturb_on',
                        description: 'Auto Sync Not Enabled',
                        helpText: 'Setup Auto Utility Upload to take advantage of Aquicore to Utility Provider synchronization'
                    };
            }
        }
        private initMeasureMap(): IMeasureArrayMap {
            const map = <IMeasureArrayMap>{};
            this.measureTypes.forEach((measureType) => {
                map[measureType.value] = [];
            });
            return map;
        }
    }
    angular.module('aq.utilityBudgets').service('UtilityServiceHelper', UtilityServiceHelper);
}
