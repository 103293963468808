namespace aq.propertySettings {

    export function FilterCollectorTable() {
        return function(collectors:any, searchText:String) {
            if(collectors && searchText){
                var filtered = searchTable(collectors, searchText);
                return filtered;
            }
            else{
                return collectors;
            } 
        }
    };

    angular.module('properties.dataCollectors').filter("filterCollectorTable", FilterCollectorTable);

    function searchTable(collectors, searchText) {
        return collectors.filter(function (item) {
            let propertyConcat:String = `${item.id} ${item.name} ${textural(item.status).format('humancapitalize')} ${item.collectorClassDetails.displayName} 
            ${textural(item.collectorClassDetails.upwardCommunications.join()).format('humancapitalize')} ${item.parent ? item.parent.name : ""} 
            ${item.parent && item.parent.parent ? item.parent.parent.name : ""}`;

            propertyConcat = propertyConcat.toLowerCase();
            searchText = searchText.toLowerCase();

            return (propertyConcat.indexOf(searchText) > -1);
        })
    }

}