namespace aq.dashboard.widgets {
    export class TableData {
        /* @ngInject */
        constructor(
            public Restangular,
            public $filter: ng.IFilterService
        ) {
        }
        public calculatePeriod(series, timeZoneId): aq.dashboard.models.DataPeriod {
            let dataPeriod = new aq.dashboard.models.DataPeriod();
            if (series.end) {
                if (series.end.date == "now") {
                    let offset = series.end.offset;
                    let unit = series.end.unit;
                    let round = series.end.round;
                    let operation = series.end.operation;

                    if (operation == "add") {
                        dataPeriod.end = moment().tz(timeZoneId).startOf(round).add(offset, unit);
                    } else {
                        dataPeriod.end = moment().tz(timeZoneId).startOf(round).subtract(offset, unit);
                    }
                } else {
                    dataPeriod.end = moment(series.end).tz(timeZoneId);
                }

                if (series.trend && series.trendPeriod != null) {
                    let trend = TrendPeriods.getTrendPeriod(series.trendPeriod.toLowerCase());
                    dataPeriod.end = moment(dataPeriod.end).tz(timeZoneId).subtract(trend.offset, trend.unit);
                }

                if (series.expected) {
                    dataPeriod.end = moment(dataPeriod.end).tz(timeZoneId);
                }
            }

            if (series.duration) {
                let duration = series.duration.offset ? series.duration.offset : null;
                let durationUnit = series.duration.unit ? series.duration.unit : null;
                dataPeriod.start = moment(dataPeriod.end).tz(timeZoneId).subtract(duration, durationUnit);
            }

            return dataPeriod;
        }
        public getDrillin(accountId, mode, filter = this.$filter<Function>('flattenHierarchy')): any {
            return this.Restangular.one('accounts', accountId).all('drillin')
                .getList({ mode })
                .then(function (drillin) {
                    const drillinObj = {};
                    _.each(drillin, function (d) {
                        d.route = 'buildings';
                        drillinObj[d.id] = filter([d], 'children', ['id', 'route', 'parent']);
                        drillinObj[d.id] = _.filter(drillinObj[d.id], (drilldown: any) => {
                            return drilldown.level != 1 && (drilldown.route != 'resources' || drilldown.childrenRoute != 'points');
                        });
                        drillinObj[d.id] = _.map(drillinObj[d.id], (drilldown) => {
                            return _.pick(drilldown, ['metrics', 'name', 'parent', 'id', 'route']);
                        });
                    });
                    return drillinObj;
                });
        }
    }
    angular.module('aq.dashboard.widgets').service('TableData', TableData);
}
