var aq;
(function (aq) {
    var ui;
    (function (ui) {
        var _ActivityUsageAlertDisplay = /** @class */ (function () {
            /* @ngInject */
            function _ActivityUsageAlertDisplay(RestangularV3, Restangular, DataStore, $filter, OptionsService, resolver, $scope, activityAlertUtilService) {
                this.RestangularV3 = RestangularV3;
                this.Restangular = Restangular;
                this.DataStore = DataStore;
                this.$filter = $filter;
                this.OptionsService = OptionsService;
                this.resolver = resolver;
                this.$scope = $scope;
                this.activityAlertUtilService = activityAlertUtilService;
            }
            _ActivityUsageAlertDisplay.prototype.$onInit = function () {
                var _this = this;
                this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                    var _a, _b, drillin, buildingDrillin, meters, tenants, sources, _c, units, err_1;
                    return __generator(this, function (_d) {
                        switch (_d.label) {
                            case 0:
                                _d.trys.push([0, 8, , 9]);
                                _a = this;
                                return [4 /*yield*/, this.Restangular.one('accounts', this.accountId).get({ single: true })];
                            case 1:
                                _a.account = _d.sent();
                                // information about this issue
                                _b = this;
                                return [4 /*yield*/, this.RestangularV3
                                        .one('issues', this.activity.context.issue)
                                        .get({ noCache: moment().valueOf() })];
                            case 2:
                                // information about this issue
                                _b.usageAlert = _d.sent();
                                return [4 /*yield*/, this.DataStore.getList(this.account, 'drillin', { mode: 'METER' })];
                            case 3:
                                drillin = _d.sent();
                                buildingDrillin = _.find(drillin, { id: this.building.id });
                                meters = this.$filter('flattenHierarchy')([buildingDrillin], 'children', null);
                                meters.shift();
                                meters = _.filter(meters, function (meter) {
                                    return meter.route !== 'points';
                                });
                                this.collectors = meters;
                                this.buildingDrillin = _.clone(buildingDrillin);
                                return [4 /*yield*/, this.DataStore.getList(this.account, 'drillin', { mode: 'TENANT' })];
                            case 4:
                                drillin = _d.sent();
                                buildingDrillin = _.find(drillin, { id: this.building.id });
                                tenants = this.$filter('flattenHierarchy')([buildingDrillin], 'children', null);
                                tenants.shift();
                                this.tenants = tenants;
                                return [4 /*yield*/, this.DataStore.getList(this.account, 'drillin', { mode: 'SOURCE' })];
                            case 5:
                                drillin = _d.sent();
                                buildingDrillin = _.find(drillin, { id: this.building.id });
                                sources = this.$filter('flattenHierarchy')([buildingDrillin], 'children', null);
                                sources.shift();
                                this.sources = sources;
                                _c = this;
                                return [4 /*yield*/, this.DataStore.getList(this.account, 'workCalendars', { inflate: 'rules,rules.category' })];
                            case 6:
                                _c.calendars = _d.sent();
                                return [4 /*yield*/, this.OptionsService.init(this.account.id, this.account.measurementSystem, this.account.currencyUnit)];
                            case 7:
                                _d.sent();
                                units = this.OptionsService.getAllUnits();
                                this.alert.unit = _.find(units, { value: this.alert.unit.toUpperCase() });
                                this.alertLoaded = true;
                                this.$scope.$apply();
                                return [3 /*break*/, 9];
                            case 8:
                                err_1 = _d.sent();
                                this.noDataCallback();
                                // TODO Change this to display an error message to users. Currently for debug purposes only
                                throw err_1;
                            case 9: return [2 /*return*/];
                        }
                    });
                }); });
            };
            _ActivityUsageAlertDisplay.prototype.getIssueDurationFormatted = function (usageAlert) {
                var issueDuration = null;
                if (usageAlert.issueCreatedOn) {
                    var start = moment(usageAlert.issueCreatedOn).toDate();
                    var end = usageAlert.issueClosedOn ? moment(usageAlert.issueClosedOn).toDate() : moment().toDate();
                    issueDuration = this.activityAlertUtilService.getDurationFormatted(start, end);
                }
                return issueDuration;
            };
            _ActivityUsageAlertDisplay.prototype.showResolveAndClose = function () {
                this.resolveAndClose = true;
            };
            _ActivityUsageAlertDisplay.prototype.hideResolveAndClose = function () {
                this.resolveAndClose = false;
            };
            return _ActivityUsageAlertDisplay;
        }());
        ui.ActivityAlertDisplay = {
            controller: _ActivityUsageAlertDisplay,
            controllerAs: 'vm',
            templateUrl: 'app/common/components/activities/contexts/usageAlertDisplay.html',
            bindings: {
                activity: '<',
                accountId: '<',
                alert: '<',
                building: '<',
                noDataCallback: '&',
                contextLoadedCallback: '&',
                refreshActivitiesAndCloseDialog: '&'
            }
        };
        angular
            .module('aq.ui')
            .component('activityUsageAlertDisplay', ui.ActivityAlertDisplay);
    })(ui = aq.ui || (aq.ui = {}));
})(aq || (aq = {}));
