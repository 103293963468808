/**
 * Created this just for redirect to V3
 */
angular
    .module('centralPlant', [])
    .config((RestangularProvider: restangular.IProvider, $stateProvider: ng.ui.IStateProvider) => {
        $stateProvider
            .state('aq.centralPlant', {
                url: '/accounts/:accountId/buildings/:buildingId/central-plant',
                controller: 'CentralPlantCtrl as vm',
                data: { appName: 'Central Plant', auth: Auth },
                resolve: {
                    accountElement(
                        $stateParams: ng.ui.IStateParamsService,
                        Restangular: restangular.IService,
                        waitForAuthToken,
                    ): restangular.IElement {
                        return Restangular.one('accounts', $stateParams.accountId);
                    },
                    account(accountElement, DataStore: aq.common.DataStore, waitForAuthToken): Promise<aq.common.models.Account> {
                        return DataStore.get(accountElement, { single: true }, false);
                    },
                    accountId(account) {
                        return account.id;
                    },
                    buildings(waitForAuthToken, RestangularV3: restangular.IService) {
                        return RestangularV3.one('').getList('buildings', { showInactive: false });
                    },
                    building(
                        buildings,
                        $stateParams: ng.ui.IStateParamsService,
                        $state: ng.ui.IStateService,
                        Restangular: restangular.IService,
                        waitForAuthToken
                    ): aq.common.models.Building {
                        let { accountId, buildingId } = $stateParams;
                        if (!buildingId) {
                            buildingId = buildings[0].id;
                        } else {
                            Restangular.one('accounts', accountId).one('buildings', buildingId).customPOST({}, 'record');
                        }
                        return _.findById(buildings, buildingId);
                    },
                    buildingId(building): number {
                        return building.id;
                    },
                    redirect: (buildingId, accountId, Auth: aq.services.Auth, RedirectService: aq.services.RedirectService) => {
                        const destination = `accounts/${accountId}/buildings/${buildingId}/central-plant`;
                        RedirectService.redirectWithoutParams(destination);
                    }
                },
                onEnter: (Segment: aq.services.SegmentService) => {
                    Segment.trackPage('Central Plant');
                }
            });
    });
