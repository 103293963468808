var aq;
(function (aq) {
    var utilityBudgets;
    (function (utilityBudgets) {
        var Step;
        (function (Step) {
            Step[Step["UTILITY_ACCOUNTS"] = 0] = "UTILITY_ACCOUNTS";
            Step[Step["CREDENTIALS"] = 1] = "CREDENTIALS";
            Step[Step["NOTIFICATIONS"] = 2] = "NOTIFICATIONS";
            Step[Step["SHARED_BUILDINGS_AND_METERS"] = 3] = "SHARED_BUILDINGS_AND_METERS";
            Step[Step["DONE"] = 4] = "DONE";
        })(Step || (Step = {}));
        var UtilityAccountWizardCtrl = /** @class */ (function (_super) {
            __extends(UtilityAccountWizardCtrl, _super);
            /* ngInject */
            function UtilityAccountWizardCtrl($mdDialog, $mdStepper, RestangularV3, UtilityServiceHelper, UserService, Messages, utilityCompanies, buildingViewItems, buildingId, users, $window, $q, Segment) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.$mdStepper = $mdStepper;
                _this.RestangularV3 = RestangularV3;
                _this.UtilityServiceHelper = UtilityServiceHelper;
                _this.UserService = UserService;
                _this.Messages = Messages;
                _this.utilityCompanies = utilityCompanies;
                _this.buildingViewItems = buildingViewItems;
                _this.buildingId = buildingId;
                _this.users = users;
                _this.$window = $window;
                _this.$q = $q;
                _this.Segment = Segment;
                _this.emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                _this.mode = 'auto';
                _this.init();
                _this.intercom = _this.$window['Intercom'];
                if (_this.intercom) {
                    _this.isIntercomEnabled = true;
                }
                _this.personas = [
                    { label: 'Asset Manager', value: 'ASSET_MANAGER' },
                    { label: 'Property Manager', value: 'PROPERTY_MANAGER' },
                    { label: 'Building Engineer', value: 'BUILDING_ENGINEER' }
                ];
                _this.customEmails = [];
                _this.notificationUsers = [];
                _this.buildingPersonas = [];
                return _this;
            }
            UtilityAccountWizardCtrl.prototype.init = function () {
                this.utilityCompany = null;
                this.utilityCompanyUrl = null;
                this.utilityAccounts = [];
                this.selectedBuildingViewItems = {};
                this.credentials = this.getNewCredentials();
                this.intervalMeasureTypes = this.UtilityServiceHelper.getIntervalMeasureTypes();
                this.meters = [];
                this.isAddAnother = false;
                this.filterTerm = '';
                this.currentStep = 0;
            };
            UtilityAccountWizardCtrl.prototype.onAddUtilityAccount = function (item) {
                this.selectedBuildingViewItems[item] = [];
            };
            UtilityAccountWizardCtrl.prototype.getNewCredentials = function () {
                return { username: '', password: '' };
            };
            UtilityAccountWizardCtrl.prototype.addMeter = function () {
                this.meters.push({
                    accountNumber: _.first(this.utilityAccounts),
                    number: null,
                    type: null
                });
            };
            UtilityAccountWizardCtrl.prototype.removeMeter = function (meter) {
                _.remove(this.meters, function (item) { return item === meter; });
            };
            UtilityAccountWizardCtrl.prototype.next = function () {
                var _this = this;
                if (this.currentStep == Step.SHARED_BUILDINGS_AND_METERS) {
                    this.create()
                        .then(function (result) {
                        _this.$mdStepper('utility-account-wizard').next();
                        _this.currentStep++;
                    });
                }
                else if (this.currentStep == Step.DONE) {
                    this.done();
                }
                else {
                    this.$mdStepper('utility-account-wizard').next();
                    this.currentStep++;
                }
            };
            UtilityAccountWizardCtrl.prototype.previous = function () {
                this.$mdStepper('utility-account-wizard').back();
                this.currentStep = Math.max(0, this.currentStep - 1);
            };
            UtilityAccountWizardCtrl.prototype.create = function () {
                var _this = this;
                this.Segment.trackEvent('Utility Accounts:Auto Sync Utility Account Creation');
                this.isCreating = true;
                var requests = [];
                _.each(this.utilityAccounts, function (accountNumber) {
                    var utilityAccountRequest = {
                        accountNumber: accountNumber,
                        utilityCompany: parseInt(_this.utilityCompany),
                        username: _this.credentials.username,
                        password: _this.credentials.password,
                        utilityUrl: _this.utilityCompanyUrl,
                        sharedBuildingIds: _.map(_this.selectedBuildingViewItems[accountNumber], function (building) { return building.id; }),
                        meters: _this.findAccountMeters(accountNumber),
                        emailRecipients: _this.getEmailRecipients(),
                        buildingPersonas: _this.isSendBuildingPersona ? _this.buildingPersonas : [],
                        customEmails: _this.isSendCustomEmails ? _this.customEmails : []
                    };
                    var request = _this.RestangularV3
                        .all('utility-accounts')
                        .post(utilityAccountRequest, { buildingId: _this.buildingId });
                    requests.push(request);
                });
                return this.$q.all(requests)
                    .catch(function (err) {
                    _this.Messages.error('Error occurred while creating utility account, please contact your Administrator');
                })
                    .finally(function () {
                    _this.isCreating = false;
                });
            };
            UtilityAccountWizardCtrl.prototype.done = function () {
                if (this.isAddAnother) {
                    this.init();
                    this.$mdStepper('utility-account-wizard').goto(Step.UTILITY_ACCOUNTS);
                }
                else {
                    this.hide();
                }
            };
            UtilityAccountWizardCtrl.prototype.confirmCancel = function (event) {
                var _this = this;
                var confirm = this.$mdDialog.confirm()
                    .title('Do you want to exit wizard? Your progress will be lost.')
                    .ariaLabel('Exit Wizard')
                    .targetEvent(event)
                    .ok('Exit')
                    .cancel('Stay');
                confirm.multiple(true);
                this.$mdDialog.show(confirm).then(function () {
                    _this.cancel();
                });
            };
            UtilityAccountWizardCtrl.prototype.findAccountMeters = function (accountNumber) {
                var meters = _.filter(this.meters, function (meter) { return meter.accountNumber == accountNumber; });
                return _.map(meters, function (meter) {
                    return {
                        id: meter.number,
                        type: meter.type
                    };
                });
            };
            UtilityAccountWizardCtrl.prototype.getEmailRecipients = function () {
                var userIds = this.isSendUsers ? _.map(this.notificationUsers, 'id') : [];
                var currentUserId = this.isSendMyself ? [this.UserService.currentUser.id] : [];
                return _.union(userIds, currentUserId);
            };
            UtilityAccountWizardCtrl.prototype.isValidCredentials = function () {
                return !!(this.credentials && this.credentials.username && this.credentials.password);
            };
            UtilityAccountWizardCtrl.prototype.isValidNotifications = function () {
                var isValidCustomEmails = !(this.isSendCustomEmails && this.customEmails.length == 0);
                var isValidUsers = !(this.isSendUsers && this.notificationUsers.length == 0);
                var isValidBuildingPersona = !(this.isSendBuildingPersona && this.buildingPersonas.length == 0);
                var isSend = this.isSendMyself
                    || this.isSendBuildingPersona
                    || this.isSendUsers
                    || this.isSendCustomEmails;
                return isSend
                    && isValidBuildingPersona
                    && isValidUsers
                    && isValidCustomEmails;
            };
            UtilityAccountWizardCtrl.prototype.isValidMeters = function () {
                var _this = this;
                return this.meters.length == 0 || _.every(this.meters, function (meter) { return _this.isValidMeter(meter); });
            };
            UtilityAccountWizardCtrl.prototype.isValidMeter = function (meter) {
                return meter.accountNumber && meter.number && meter.type;
            };
            UtilityAccountWizardCtrl.prototype.onSelectedBuildingsChange = function (buildings, accountNumber) {
                this.selectedBuildingViewItems[accountNumber] = buildings;
            };
            UtilityAccountWizardCtrl.prototype.onAddCustomEmail = function (item) {
                if (!this.validateEmail(item)) {
                    this.customEmails = _.filter(this.customEmails, function (email) { return email != item; });
                }
            };
            UtilityAccountWizardCtrl.prototype.validateEmail = function (email) {
                return this.emailRegex.test(String(email).toLowerCase());
            };
            UtilityAccountWizardCtrl.prototype.isShowBack = function () {
                return this.currentStep > Step.UTILITY_ACCOUNTS && this.currentStep < Step.DONE;
            };
            UtilityAccountWizardCtrl.prototype.isLastStep = function () {
                return this.currentStep == Step.DONE;
            };
            UtilityAccountWizardCtrl.prototype.openChat = function () {
                this.intercom('showMessages');
                this.$mdDialog.hide();
            };
            UtilityAccountWizardCtrl.prototype.queryUsers = function (search, assignedUsers) {
                var searchText = search.toLowerCase();
                var users = this.users.filter(function (user) {
                    return user.fullName.toLowerCase().indexOf(searchText) > -1
                        && _.findIndex(assignedUsers, { fullName: user.fullName }) === -1;
                });
                return users;
            };
            UtilityAccountWizardCtrl.prototype.isDisableNext = function () {
                switch (this.currentStep) {
                    case Step.UTILITY_ACCOUNTS:
                        return !this.utilityAccounts || this.utilityAccounts.length == 0;
                    case Step.CREDENTIALS:
                        return !this.isValidCredentials();
                    case Step.NOTIFICATIONS:
                        return !this.isValidNotifications();
                    case Step.SHARED_BUILDINGS_AND_METERS:
                        return this.isCreating || !this.isValidMeters();
                    default:
                        return false;
                }
            };
            UtilityAccountWizardCtrl.prototype.getNextButtonTitle = function () {
                switch (this.currentStep) {
                    case Step.SHARED_BUILDINGS_AND_METERS:
                        return 'Next';
                    case Step.DONE:
                        return 'Done';
                    default:
                        return 'Next';
                }
            };
            UtilityAccountWizardCtrl.prototype.getFilteredCompanies = function () {
                var _this = this;
                return _.filter(this.utilityCompanies, function (utilityCompany) {
                    return !_this.filterTerm || utilityCompany.name && utilityCompany.name.toLowerCase().indexOf(_this.filterTerm.toLowerCase()) > -1;
                });
            };
            return UtilityAccountWizardCtrl;
        }(aq.common.Controllers.ModalCtrl));
        utilityBudgets.UtilityAccountWizardCtrl = UtilityAccountWizardCtrl;
        angular.module('aq.utilityBudgets').controller('UtilityAccountWizardCtrl', UtilityAccountWizardCtrl);
    })(utilityBudgets = aq.utilityBudgets || (aq.utilityBudgets = {}));
})(aq || (aq = {}));
