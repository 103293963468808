namespace aq.dashboard.widgets {
    declare var Highcharts: __Highcharts.Static;
    export class ProjectedSpend {
        public accountId: number;
        public buildingId: number;
        public currentSpend: number;
        public projectedSpend: number;
        public totalSpend: number;
        public blendedRate: number;
        public demandRate: number;
        public budget: number;
        public currencySymbol: string;
        public budgetPercent: string;
        public measureUnit: string;
        public demandUnit: string;
        public colors: string[];
        public projectedSpendChart: __Highcharts.Options;
        public targetModel: aq.energyInsights.TargetModel

        /* @ngInject */
        constructor(
            private GraphEditService: aq.dashboard.widgets.GraphEditService
        ) {
            this.colors = Highcharts.getOptions().colors;
        }

        public $onInit() {
            this.init();
        }

        public $onChanges() {
            this.init();
        }

        public init() {
            if (this.targetModel) {
                this.buildViewItems(this.targetModel);
                const chartSeries = this.getSeries();
                if (chartSeries.length > 0) {
                    this.projectedSpendChart = this.buildChart(chartSeries);
                }
            }
        }

        public buildViewItems(model: aq.energyInsights.TargetModel) {
            const totals = model.totals;
            this.budget = totals.budgetSum;
            this.currentSpend = totals.spend || 0;
            this.projectedSpend = totals.projection || 0;
            this.totalSpend = this.currentSpend + this.projectedSpend;
            this.blendedRate = totals.blendedRate;
            this.currencySymbol = totals.currencySymbol;
            this.measureUnit = model.measureUnit;
            this.buildingId = model.buildingId as any;
            this.accountId = model.accountId;
            this.demandRate = totals.demandRate || 0;
            this.demandUnit = this.measureUnit.match('kWh') ? 'kW' : '';
            if (this.budget > 0) {
                const diff = Math.abs(this.budget - this.totalSpend);
                this.budgetPercent = `${(diff / this.budget * 100).toFixed(1)}%`;
            }
        }

        public getSeries() {
            const series = [];
            if (this.budget > 0) {
                series.push({
                    data: [{ x: 1, y: 0.001 }],
                    name: 'Budget',
                    type: 'column',
                    color: this.budget > this.totalSpend ? aq.utilityBudgets.seriesColors.green : aq.utilityBudgets.seriesColors.red,
                    stack: 'budget',
                    pointPadding: 0,
                    borderWidth: 0,
                    showInLegend: false,
                    index: 3,
                    minPointLength: 15,
                    dataLabels: {
                        enabled: true,
                        // tslint:disable-next-line:object-literal-shorthand
                        formatter: function () {
                            return '<span style="">BUDGET</span>';
                        },
                        style: {
                            color: 'contrast',
                            fontSize: '12px',
                            fontWeight: 'normal',
                            textOutline: 'none'
                        }
                    }
                });
                series.push({
                    data: [{ x: 1, y: this.budget }],
                    name: '',
                    type: 'column',
                    color: 'rgba(255, 255, 255, 0.0)',
                    stack: 'budget',
                    borderWidth: 0,
                    pointPadding: 0,
                    index: 4,
                    showInLegend: false
                });
            }
            if (this.projectedSpend > 0) {
                series.push({
                    data: [{ x: 1, y: this.projectedSpend }],
                    name: '',
                    type: 'column',
                    color: this.GraphEditService.getLighterColor(this.colors[0]),
                    stack: 'expense',
                    stacking: 'normal',
                    pointPadding: 0.1,
                    borderWidth: 2,
                    borderColor: '#FFF',
                    minPointLength: 10,
                    index: 1
                });
            }
            if (this.currentSpend > 0) {
                series.push({
                    data: [{ x: 1, y: this.currentSpend }],
                    name: '',
                    type: 'column',
                    colorIndex: 0,
                    stack: 'expense',
                    stacking: 'normal',
                    pointPadding: 0.1,
                    borderWidth: 2,
                    borderColor: '#FFF',
                    minPointLength: 10,
                    index: 2
                });
            }
            return series;
        }

        public buildChart(series) {
            return {
                chart: {
                    height: 300,
                    animation: false,
                    backgroundColor: 'transparent',
                    plotBorderColor: '#DADADA',
                    plotBorderWidth: 1
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    min: 1,
                    max: 1,
                    visible: false
                },
                yAxis: [{
                    title: { text: '' },
                    visible: false,
                    min: 0
                }],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    column: {
                        grouping: false,
                        stacking: 'normal',
                        groupPadding: 0,
                        pointPadding: 0
                    },
                    series: {
                        animation: false,
                        marker: {
                            enabled: false
                        },
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    }
                },
                series,
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                tooltip: {
                    enabled: false
                }
            };
        }
    }

    angular.module('aq.dashboard.widgets').component('projectedSpend', {
        controller: ProjectedSpend,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/common/components/projectedSpend/projectedSpend.html',
        bindings: {
            targetModel: '<'
        }
    });
}
