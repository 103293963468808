namespace aq.admin.accounts.enrollment {
    export interface StatusCount {
        description: string;
        count: number;
    }
    export interface AccountViewItem {
        id: number;
        name: string;
        total: number;
        completed: number;
        percent: number;
        statuses: StatusCount[];
        statusesMap: { [description: string]: number; };
        isFavorite: boolean;
        isLoading: boolean;

        loadingPercent: number;
        totalPromises: number;
        completedPromises: number;
    }
    export class AdminAccountEnrollmentCtrl {
        public searchText: string;
        public selectedAccounts: AccountModel[];
        public accountViewItems: AccountViewItem[];
        public filteredAccounts: AccountModel[];
        private phases = [
            'Setup', 'Connect', 'Verify', 'Train', 'Complete'
        ];
        private buildingsEnrollmentMap: {
            [accountId: number]: aq.propertySettings.BuildingEnrollment[];
        };
        private buildingsMap: {
            [accountId: number]: any[];
        };
        /* @ngInject */
        constructor(
            private AppStorageService: aq.services.AppStorageService,
            private Restangular: restangular.IService,
            private RestangularV3: restangular.IService,
            private $q: ng.IQService,
            private reload: number
        ) {
            this.init();
            this.filteredAccounts = [];
            this.loadAllViewItems();
        }
        public queryAccounts(searchText) {
            searchText = searchText.toLowerCase();
            return this.Restangular.one('accounts').get({
                page: 1,
                pageSize: 10,
                search: searchText
            })
                .then((data) => {
                    return _.map(data.accounts, (account) => {
                        return {
                            id: account.id,
                            name: account.accountName
                        };
                    });
                })
                .then((accounts) => {
                    return _.chain(accounts)
                        .filter((item: AccountModel) => {
                            return _.every(this.selectedAccounts, (selectedItem) => selectedItem.id != item.id);
                        })
                        .sortBy('name')
                        .value();
                });
        }
        public onAddAccount(account: AccountModel) {
            const newViewItem = this.getAccountViewItem(account);
            this.accountViewItems.push(newViewItem);
            this.accountViewItems = _.sortBy(this.accountViewItems, 'name');
            const ids = [newViewItem.id];
            this.loadViewItems(ids);
        }
        public onRemoveAccount(account: AccountViewItem) {
            _.remove(this.accountViewItems, (item: AccountViewItem) => item.id == account.id);
            // clear selected tasks/columns for that account
            const storage = this.AppStorageService.getStorage();
            const tasks = storage.displayEnrollmentTasks;
            if (tasks) {
                delete tasks[account.id];
                this.AppStorageService.setAttr('displayEnrollmentTasks', tasks);
            }
        }
        public toggleFavorite(item: AccountViewItem) {
            item.isFavorite = !item.isFavorite;
            const accountItems = _.chain(this.accountViewItems)
                .filter((viewItem: AccountViewItem) => viewItem.isFavorite)
                .map((viewItem: AccountViewItem) => {
                    return <AccountModel>{
                        id: viewItem.id,
                        name: viewItem.name
                    };
                })
                .value();
            this.AppStorageService.setAttr('selectedAccountsEnrollment', accountItems);
        }
        private init() {
            this.buildingsEnrollmentMap = {};
            this.buildingsMap = {};
            const storage = this.AppStorageService.getStorage();
            const accountItems = storage.selectedAccountsEnrollment;
            if (accountItems && accountItems.length > 0) {
                this.selectedAccounts = accountItems;
            } else {
                this.selectedAccounts = [];
            }
            this.accountViewItems = _.chain(this.selectedAccounts)
                .map((account) => this.getAccountViewItem(account))
                .each((account) => account.isFavorite = true)
                .sortBy('name')
                .value();
        }
        private loadAllViewItems() {
            const ids = _.map(this.accountViewItems, (item) => item.id);
            this.loadViewItems(ids);
        }
        private loadViewItems(ids: number[]) {
            if (!ids || ids.length == 0) {
                return;
            }
            _.each(ids, (id) => {
                const accountViewItem = this.getViewItemAndSetLoadingStatus(id, true);
                this.getAccountBuildings(id)
                    .then((buildings) => {
                        this.buildingsMap[id] = buildings;
                        return this.getBuildingsEnrollment(accountViewItem, buildings);
                    })
                    .then((buildingsEnrollment) => {
                        this.buildingsEnrollmentMap[id] = buildingsEnrollment;
                    })
                    .finally(() => {
                        accountViewItem.statuses = _.map(this.phases, (phase: string) => {
                            const data: StatusCount = {
                                description: phase,
                                count: accountViewItem.statusesMap[phase] || 0
                            };
                            return data;
                        });
                        if (accountViewItem.statusesMap['Not Specified'] !== undefined) {
                            accountViewItem.statuses.push({
                                description: 'Not Specified',
                                count: accountViewItem.statusesMap['Not Specified']
                            });
                        }
                        accountViewItem.percent = Math.round(accountViewItem.completed * 100 / accountViewItem.total);
                        accountViewItem.isLoading = false;
                    });
            });
        }
        private getAccountViewItem(account: AccountModel): AccountViewItem {
            const item: AccountViewItem = {
                id: account.id,
                name: account.name,
                isFavorite: false,
                statuses: null,
                statusesMap: {},
                total: null,
                completed: null,
                percent: null,
                isLoading: false,
                loadingPercent: 0,
                totalPromises: null,
                completedPromises: null
            };
            return item;
        }
        private getViewItemAndSetLoadingStatus(id: number, isLoading: boolean): AccountViewItem {
            const viewItem: AccountViewItem = _.find(this.accountViewItems, (item) => item.id == id);
            viewItem.isLoading = isLoading;
            return viewItem;
        }
        private getBuildingsEnrollment(accountViewItem: AccountViewItem, buildings): ng.IPromise<aq.propertySettings.BuildingEnrollment[]> {
            accountViewItem.total = buildings.length;
            accountViewItem.completed = 0;
            accountViewItem.completedPromises = 0;
            accountViewItem.totalPromises = buildings.length;
            return this.$q.all<aq.propertySettings.BuildingEnrollment>(_.map(buildings, (building) => {
                return this.RestangularV3
                    .one('buildings', building.id)
                    .one('enrollment')
                    .get()
                    .then((response) => {
                        const data = response || { building: building.id, progress: 0, currentPhase: 'Not Specified', status: 'UNKNOWN' };
                        return data;
                    })
                    .then((data) => {
                        if (data.status == 'COMPLETE') {
                            accountViewItem.completed++;
                            // if completed, override current phase
                            data.currentPhase = 'Complete';
                        }
                        if (!accountViewItem.statusesMap[data.currentPhase]) {
                            accountViewItem.statusesMap[data.currentPhase] = 0;
                        }
                        accountViewItem.statusesMap[data.currentPhase]++;
                        return data;
                    })
                    .finally(() => {
                        accountViewItem.completedPromises++;
                        accountViewItem.loadingPercent = Math.round(accountViewItem.completedPromises * 100 / accountViewItem.totalPromises);
                    });
            }));
        }
        private getAccountBuildings(id: number) {
            return this.Restangular.one('accounts', id).all('buildings').getList();
        }
    }
    angular.module('aq.admin.accounts.enrollment').controller('AdminAccountEnrollmentCtrl', AdminAccountEnrollmentCtrl);
}
