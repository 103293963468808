namespace aq.common {

    export module Controllers {

        export class ModalCtrl {

            constructor(protected $scope, protected $mdDialog) {}

            // the args to hide can be used in the .then() after the $mdDialog.show()
            hide(thenData?: any) {
                this.$mdDialog.hide(thenData);
            }

            // the args to cancel can be used in the .reject() after the $mdDialog.show()
            cancel(rejectData?: any) {
                this.$mdDialog.cancel(rejectData);
            }

        }

    }

}