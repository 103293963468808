var aq;
(function (aq) {
    var utilityBudgets;
    (function (utilityBudgets) {
        var AddUtilityAccount = /** @class */ (function (_super) {
            __extends(AddUtilityAccount, _super);
            function AddUtilityAccount($mdDialog, RestangularV3, Errors, Messages, utilityCompanies, accountId, buildingId, UtilityServiceHelper, loading, Segment) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.RestangularV3 = RestangularV3;
                _this.Errors = Errors;
                _this.Messages = Messages;
                _this.utilityCompanies = utilityCompanies;
                _this.accountId = accountId;
                _this.buildingId = buildingId;
                _this.UtilityServiceHelper = UtilityServiceHelper;
                _this.loading = loading;
                _this.Segment = Segment;
                _this.meters = [];
                _this.saved = false;
                _this.utilityAccount = {};
                _this.autoUtilityUploadEnabled = true;
                _this.selectedServices = {};
                _this.filterTerm = '';
                return _this;
            }
            AddUtilityAccount.prototype.cancel = function (data) {
                this.$mdDialog.cancel();
            };
            AddUtilityAccount.prototype.hide = function (result) {
                this.$mdDialog.hide(this.RestangularV3.copy(result));
            };
            AddUtilityAccount.prototype.getMeasureTypes = function () {
                return this.UtilityServiceHelper.getMeasureTypes();
            };
            AddUtilityAccount.prototype.clearFilter = function () {
                this.filterTerm = '';
            };
            AddUtilityAccount.prototype.getSelectedServices = function () {
                var _this = this;
                return _.filter(_.keys(this.selectedServices), function (serviceType) {
                    return _this.selectedServices[serviceType];
                });
            };
            AddUtilityAccount.prototype.save = function () {
                var _this = this;
                this.Segment.trackEvent('Utility Accounts:Manual Utility Account Creation');
                this.saved = true;
                this.loading.start(true);
                this.utilityAccount.meters = this.meters.filter(function (meter) { return meter.id !== '' && meter.type !== ''; });
                if (!this.autoUtilityUploadEnabled) {
                    this.utilityAccount.services = this.getSelectedServices();
                    delete this.utilityAccount.password;
                    delete this.utilityAccount.username;
                    delete this.utilityAccount.utilityUrl;
                }
                return this.RestangularV3
                    .all('utility-accounts')
                    .post(this.utilityAccount, { buildingId: this.buildingId })
                    .then(function (result) {
                    _this.Messages.success('Successfully created Utility Account');
                    _this.hide(result);
                    _this.loading.stop();
                })
                    .catch(function (err) {
                    _this.Messages.error('Unable to perform create action');
                    _this.loading.stop();
                });
            };
            AddUtilityAccount.prototype.addMeter = function (meters) {
                meters.push({ id: '', type: 'ELECTRICITY' });
            };
            AddUtilityAccount.prototype.deleteMeter = function (meters, index) {
                meters.splice(index, 1);
            };
            AddUtilityAccount.prototype.getFilteredCompanies = function () {
                var _this = this;
                return _.filter(this.utilityCompanies, function (utilityCompany) {
                    return !_this.filterTerm || utilityCompany.name && utilityCompany.name.toLowerCase().indexOf(_this.filterTerm.toLowerCase()) > -1;
                });
            };
            return AddUtilityAccount;
        }(aq.common.Controllers.ModalCtrl));
        utilityBudgets.AddUtilityAccount = AddUtilityAccount;
        angular.module('aq.utilityBudgets').controller('AddUtilityAccount', AddUtilityAccount);
    })(utilityBudgets = aq.utilityBudgets || (aq.utilityBudgets = {}));
})(aq || (aq = {}));
