angular.module('aq-main').run(function($httpBackend) {
    const regex = {
        utilityMeters: /^\/api\/v3\/utility-meters\?utilityServiceId=\d{1,}$/,
        utilityMeter: /^\/api\/v3\/utility-meters\/\d{1,}$/,
        urjanetMeters: /^\/api\/v3\/urjanet-meters\?utilityServiceId=\d{1,}$/,
        utilityBillPeriodCharges: /^\/api\/v3\/utility-bill-period-charges\?utilityBillPeriodId=\d{1,}$/
    };
    let idCounter = 0;
    const urjanetMeters = [
        {
            id: ++idCounter,
            serviceType: 'ELECTRICITY',
            meterNumber: `00000${idCounter}`,
            logicalMeterId: MockHelpers.getRandomName(),
            // 100 10th ave, electricity. doesn't actually exist on model, this is just for mocks
            utilityService: 39381109
        },
        {
            id: ++idCounter,
            serviceType: 'ELECTRICITY',
            meterNumber: `00000${idCounter}`,
            logicalMeterId: MockHelpers.getRandomName(),
            // 100 10th ave, electricity. doesn't actually exist on model, this is just for mocks
            utilityService: 39381109
        },
        {
            id: ++idCounter,
            serviceType: 'ELECTRICITY',
            meterNumber: `00000${idCounter}`,
            logicalMeterId: MockHelpers.getRandomName(),
            // 100 10th ave, electricity. doesn't actually exist on model, this is just for mocks
            utilityService: 39381109
        },
        {
            id: ++idCounter,
            serviceType: 'ELECTRICITY',
            meterNumber: 'KZD357697233',
            logicalMeterId: MockHelpers.getRandomName(),
            // LPC, 1000 vermont ave, electricity. doesn't actually exist on model, this is just for mocks
            utilityService: 22687404
        }
    ];
    const utilityMeters = [
        {
            id: ++idCounter,
            collector: 23131517, // pepco office
            utilityService: 39381109, // 100 10th ave, electricity
            urjanetMeter: urjanetMeters[0].id
        },
        {
            id: ++idCounter,
            collector: 23131535, // pepco pavillion
            utilityService: 39381109, // 100 10th ave, electricity
            urjanetMeter: urjanetMeters[1].id
        },
        {
            id: ++idCounter,
            collector: 22687948, // lpc Main Electric - KZD357697233
            utilityService: 22687404, // lpc, 1000 vermont ave, electricity
            urjanetMeter: urjanetMeters[3].id
        }
    ];
    const utilityBillPeriodCharges = [
        {
            id: ++idCounter,
            charge: 5509.68,
            demand: 258,
            usage: 63600,
            usageCharge: 4839.44,
            demandCharge: 520.24,
            otherCharge: 50,
            taxCharge: 100,
            startDate: '10/13/2018',
            endDate: '11/13/2018',
            utilityBillPeriod: 44711756, // lpc, 1000 vermont ave, 10/13/2018-11/13/2018
            utilityMeter: utilityMeters[2].id
        }
    ];
    MockContext('utilityMeters', () => {
        $httpBackend.whenGET(regex.utilityMeters).respond((method, url, data) => {
            const queryParams = MockHelpers.getQueryParams(url);
            return [200, _.filter(utilityMeters, { utilityService: parseInt(queryParams['utilityServiceId'][0])}), {}];
        });

        $httpBackend.whenPOST(regex.utilityMeters).respond((method, url, data) => {
            console.log(method, url, data);
            data = JSON.parse(data);
            if (data.id) {
                return [400, { message: 'id should not be provided' }, {}];
            }
            data.id = ++idCounter;
            utilityMeters.push(data);
            return [200, data, {}];
        });

        $httpBackend.whenGET(regex.utilityMeter).respond((method, url, data) => {
            const ids = MockHelpers.getIds(url);
            return [200, _.find(utilityMeters, { id: parseInt(ids[0])}), {}];
        });

        $httpBackend.whenPUT(regex.utilityMeter).respond((method, url, data) => {
            console.log(method, url, data);
            data = JSON.parse(data);
            if (data.id) {
                _.remove(utilityMeters, { id: data.id });
            } else {
                return [400, {message: 'missing id'}, {}];
            }
            utilityMeters.push(data);
            return [200, data, {}];
        });

        $httpBackend.whenDELETE(regex.utilityMeter).respond((method, url, data) => {
            console.log(method, url, data);
            const ids = MockHelpers.getIds(url);
            if (ids[0]) {
                _.remove(utilityMeters, { id: ids[0] });
            } else {
                return [400, { message: 'missing id' }, {}];
            }
            return [200, {}, {}];
        });

        $httpBackend.whenGET(regex.urjanetMeters).respond((method, url, data) => {
            const queryParams = MockHelpers.getQueryParams(url);
            return [
                200,
                _.filter(urjanetMeters, {
                    utilityService: parseInt(queryParams['utilityServiceId'][0])
                }),
                {}
            ];
        });

        $httpBackend.whenGET(regex.utilityBillPeriodCharges).respond((method, url, data) => {
            const queryParams = MockHelpers.getQueryParams(url);
            return [
                200,
                _.filter(utilityBillPeriodCharges, {
                    utilityBillPeriod: parseInt(queryParams['utilityBillPeriodId'][0])
                }),
                {}
            ];
        });
    });
});
