angular.module('aq.admin.statistics.schedules').controller('DataCtrl', ['$scope', 'Restangular', '$stateParams','StatsCalculatorService',
    'aqnumberFilter', '$timeout',
  function($scope, Restangular, $stateParams, StatsCalculatorService, aqNumber, $timeout) {

    $scope.$watch('buildings', function(buildings) {
        if (!buildings) return;
        
        $scope.building = _.findById(buildings, $stateParams.buildingId);
        
        $scope.selectedCalendar = $scope.building.calendar || -1;
    });
    
    $scope.aggregationLevel = '15min';
    $scope.historiagramCount = 30;
    $scope.selectedDurationDays = 7;
    var now = moment();
    $scope.end = moment(now);
    $scope.start = moment($scope.end).add(-7, 'days');
    
    var inputNumberFix = function(func) {
        return _.debounce(function() {
            $timeout(func, 0);
        }, 400);
    }
    
    var durationFix = inputNumberFix(function() {
        $scope.start = moment(now).subtract(moment.duration($scope.selectedDurationDays, 'days'))
    })
    
    $scope.$watch('selectedDurationDays', function(days) {
        if (!days) return;
        
        durationFix();
    });


    var rangeFix = inputNumberFix(function() {
        $scope.rangeCount =  $scope.historiagramCount;
    });
    
    $scope.$watch('historiagramCount', function(count) {
        if (!count) return;

        rangeFix();
    })

    // Dates parsing
    
    $scope.startDate = $scope.start.toDate();
    $scope.endDate = $scope.end.toDate();
    
    $scope.$watch('startDate', function(startDate) {
        if (!startDate) return;
        
        $scope.start = moment(startDate);
    } , true);
    
    $scope.$watch('endDate', function(endDate) {
        if (!endDate) return;
        
        if (endDate.getTime() <= $scope.startDate.getTime()) {
            $scope.startDate = moment(endDate).add(-1, 'days').toDate();
        }
        
        $scope.end = moment(endDate);
    } , true);
    
    // Date picker changer
    
    $scope.changeToDays = function() {
        $scope.selectedDurationDays = 7;
        $scope.end = moment(now);
    }
    
    $scope.changeToPicker = function() {
        $scope.selectedDurationDays = null;
    }
    
    $scope.hasCalendar = function() {
        return $scope.selectedCalendar != '-1';
    }

    $scope.$watch('[end, start, aggregationLevel, selectedCalendar, building, rangeCount]', function() {
       if (!$scope.end || !$scope.start || !$scope.aggregationLevel || !$scope.selectedCalendar
               || !$scope.building || !$scope.rangeCount) {
           return;
       } 
       
       var params ={
           interval: $scope.aggregationLevel,
           end: $scope.end.format(),
           start: $scope.start.format(),
           measure: 'POWER'
       };
       
       $scope.building.metrics(params).then(function(data) {
           $scope.metrics = data;
       });
       
       $scope.building.data(params).then(function(data) {
           $scope.distribution = StatsCalculatorService.toDistributionPlot(data, $scope.rangeCount);

       });
       
       if ($scope.hasCalendar()) {
           const calendarParams = _.extend({}, params, {
               partitionType: 'workcalendar',
               partitionId: $scope.selectedCalendar,
           });
           
           $scope.building.metrics(calendarParams).then(function(data) {
               $scope.calendarMetrics = data;
           });
           
           $scope.building.data(calendarParams).then(function(data) {
               $scope.calendarDistribution = StatsCalculatorService.toCalendarDistributionPlot(data, $scope.rangeCount);
           });
       }
       
       $scope.tooltip = function(x, y, unit) {
           return "<p> For " + Math.round(x) + " " + unit + " distribution is " + aqNumber(y) + "</p>";
       }
       
       
       
    }, true);
}]);
