var aq;
(function (aq) {
    var projectCenter;
    (function (projectCenter) {
        var ProjectCenterNewCtrl = /** @class */ (function (_super) {
            __extends(ProjectCenterNewCtrl, _super);
            /* @ngInject */
            function ProjectCenterNewCtrl($scope, $mdDialog, DataStore, project, users, Messages, $state, building, projectTypes, UserService, ProjectService, $location) {
                var _this = _super.call(this, $scope, $mdDialog) || this;
                _this.$scope = $scope;
                _this.$mdDialog = $mdDialog;
                _this.DataStore = DataStore;
                _this.project = project;
                _this.users = users;
                _this.Messages = Messages;
                _this.$state = $state;
                _this.building = building;
                _this.projectTypes = projectTypes;
                _this.UserService = UserService;
                _this.ProjectService = ProjectService;
                _this.$location = $location;
                _this.project = project;
                _this.project.projectUrl = $location.absUrl();
                _this.projectTypes = projectTypes;
                return _this;
            }
            ProjectCenterNewCtrl.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            ProjectCenterNewCtrl.prototype.save = function () {
                var _this = this;
                return this.ProjectService.create(this.project)
                    .then(function (project) {
                    _this.Messages.success('Project created successfully');
                    _this.$mdDialog.hide(project);
                }).catch(function (error) {
                    _this.Messages.error('Unable to create Project.');
                });
            };
            return ProjectCenterNewCtrl;
        }(aq.common.Controllers.ModalCtrl));
        projectCenter.ProjectCenterNewCtrl = ProjectCenterNewCtrl;
        angular.module('projectCenter').controller('ProjectCenterNewCtrl', ProjectCenterNewCtrl);
    })(projectCenter = aq.projectCenter || (aq.projectCenter = {}));
})(aq || (aq = {}));
