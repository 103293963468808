'use strict';

class TenantBillingReportCtrl {
    public accounts;
    public selectedAccount;
    public buildings;
    public selectedBuilding;
    public periods;
    public period;
    public tenants;
    public tenant;
    public tenantReportBuilding;
    public invoices;
    public reports;
    public authToken;
    /* @ngInject */
    constructor(
        private Restangular,
        private RestangularV3,
        private Errors,
        private $q,
        private Messages,
        private $timeout,
        private $location
    ) {
        this.buildings = [];
        this.periods = [];
        this.tenants = [];
        this.tenantReportBuilding = [];
        this.reports = {};

        this.Restangular.all('accounts')
            .customGET('queryAllAccounts')
            .then((accounts) => {
                this.accounts = accounts;
            });
    }

    public accountSelected(account) {
        this.buildings = [];
        this.selectedBuilding = null;
        this.resetPeriods();
        this.$q.all([
            this.RestangularV3.all('buildings').getList({ accountId: account.id }),
            this.Restangular.one('accounts', account.id)
                .customGET('queryTokenForSnapshot')
        ]).then((result) => {
            this.buildings = result[0];
            this.authToken = result[1];
            this.selectedBuilding = _.first(this.buildings);
            this.buildingSelected(this.selectedBuilding);
        }, this.Errors.forPromise());
    }

    public buildingSelected(building) {
        this.resetPeriods();
        this.RestangularV3.all('billings')
            .getList({ buildingId: building.id })
            .then((periods) => {
                this.periods = periods;
                this.period = _.first(periods);
                this.periodSelected(this.period);
            }, this.Errors.forPromise());
    }

    public periodSelected(period) {
        this.resetTenants();
        this.RestangularV3.all('tenant-invoices')
            .getList({ monthlyBillingId: period.id })
            .then((result) => {
                this.invoices = result;
                this.tenants = _.map(this.invoices, (invoice) => {
                    return {
                        id: invoice.tenant,
                        name: invoice.tenantName
                    };
                });
                this.tenant = _.first(this.tenants);
            }, this.Errors.forPromise());
    }

    public getUrl() {
        if (!this.period) return;
        return this.period.getRestangularUrl() + '/TenantBillingReport';
    }

    public getTenantBillingReportUrl(type) {
        if (!this.tenant) return;
        const invoiceId = _.find(this.invoices, (invoice: any) => {
            return invoice.tenant === this.tenant.id;
        }).id;
        const accountId = this.selectedAccount.id;
        const buildingId = this.selectedBuilding.id;
        const billingId = this.period.id;
        const tenantId = this.tenant.id;
        return this.getLocationOrigin() + '/reports/billing/' + type + '?accountId=' + accountId + '&buildingId=' + buildingId +
            '&billingId=' + billingId + '&invoiceId=' + invoiceId + '&tenantId=' + tenantId +
            '&authToken=' + this.authToken;
    }

    public getInvoiceUrl() {
        return this.getTenantBillingReportUrl('invoice');
    }

    public getTenantReportUrl() {
        return this.getTenantBillingReportUrl('vornadoTenantReport');
    }

    public getLocationOrigin() {
        return this.$location.protocol() + '://' + this.$location.host() + ':' + this.$location.port();
    }

    public resetPeriods = () => {
        this.periods = [];
        this.period = null;
        this.resetTenants();
    }

    public resetTenants = () => {
        this.tenants = [];
        this.tenant = null;
    }

    public downloadURI = (uri, fileName) => {
        const link = document.createElement('a');
        link.download = fileName;
        link.href = uri;
        link.click();
    }

    public reportStatus = (report, name) => {
        this.Restangular
            .one('accounts', this.selectedAccount.id)
            .one('ScheduledReports')
            .customGET('reportStatus', { statusId: report.statusId, noCache: new Date().getTime() })
            .then((res) => {
                if (!this.reports[report.statusId]) {
                    this.reports[report.statusId] = {};
                }
                if (name) {
                    this.reports[report.statusId].name = name;
                }
                _.assignIn(this.reports[report.statusId], res);
                if (res.status !== 'completed' && res.status !== 'failed') {
                    this.$timeout(this.reportStatus, 1000, true, res);
                    return;
                }
                if (!res.downloadUrl) {
                    this.Messages.error('Failed to generate ' + this.reports[report.statusId].name);
                } else {
                    this.Messages.success('Report complete');
                    this.downloadURI(res.downloadUrl, this.reports[report.statusId].name + '.pdf');
                    delete this.reports[report.statusId];
                }
            });
    }

    public generateReport = (url, name) => {
        this.Restangular
            .one('accounts', this.selectedAccount.id)
            .one('ScheduledReports')
            .one('generateReport')
            .customPOST({}, '', { url, noCache: new Date().getTime() })
            .then((status) => {
                this.Messages.info('Generating the requested report...');
                this.reportStatus(status, name);
            });
    }
}

angular.module('aq.admin.reports.tenantbillingreport')
    .controller('TenantBillingReportCtrl', TenantBillingReportCtrl);
