namespace aq.reports.summary {
    export class MonthlyOccupancyData {
        /* @ngInject */
        constructor() {

        }

        public getMonthlyOccupancy(building, monthIndex, time): any {
            let occupancyData = [];
            let currentYr = moment(time).format('YYYY');
            let secondYr = moment(time).subtract(1, 'years').format('YYYY');
            let end = 0;
            let currentYearOccupancies = [];

            occupancyData[0] = null;

            if (building.buildingOccupancies && building.buildingOccupancies.length > 0) {
                currentYearOccupancies = building.buildingOccupancies.filter(function (o) {
                    return moment.utc(o.startDate).format('YYYY') == currentYr;
                });

                //previous year occupancies - get the latest value from the previous year and set that as the first occupancy value for current year
                //example: occupancy was updated in Dec, 2015 - want to show this as occupancy for Jan 2016
                let lastYearValue = _.find(building.buildingOccupancies, function (o: any) {
                    return moment.utc(o.startDate).format('YYYY') == secondYr;
                });

                if(lastYearValue){
                    //cast most recent previous year value at 12/31
                    lastYearValue.startDate = secondYr + '-12-31';
                    currentYearOccupancies.push(lastYearValue);
                }

                if (currentYearOccupancies.length > 0) {
                    end = currentYearOccupancies.length;
                }
            }

            for (var i = 1; i <= monthIndex; i++) {
                if (end == 0) {
                    occupancyData[i] = null;
                } else {
                    let endMonth:any = moment.utc(currentYearOccupancies[end - 1].startDate).subtract(1, 'days').format('M');

                    //find the building most recent building occupancy entry that is closest to the first of each month
                    if (endMonth == 12) {
                        endMonth = 0;
                    }

                    if (endMonth >= i) {
                        occupancyData[i] = null;
                    } else {
                        for (var j = 0; j < end; j++) {
                            let month:any = moment.utc(currentYearOccupancies[j].startDate).subtract(1, 'days').format('M');
                            let year:any = moment.utc(currentYearOccupancies[j].startDate).subtract(1, 'days').format('YYYY');
                            if (month == 12 && year == secondYr) {
                                month = 0;
                            }
                            if ((month < i)) {
                                occupancyData[i] = currentYearOccupancies[j].occupancyPercent;
                                break;
                            };
                        }
                    }
                }
            }
            return occupancyData;
        };

    }
    angular.module('aq.reports').service('MonthlyOccupancyData', MonthlyOccupancyData);
}