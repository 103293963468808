angular.module('aq-main').run(function($httpBackend) {
    const regex = /^\/api\/v1\/accounts\/.*\/dashboards\/(\d+)\?.*/;
    const dashboards = [{
        id: 1,
        name: 'Engineer Dashboard',
        buildingLevel: true,
        config: {
            editable: false,
            structure: "50-50",
            rows: [{
                columns: [{
                    flex: "30",
                    widgets: [{
                        flex: "30",
                        fullScreen: false,
                        modalSize: 'lg',
                        type: "markdown",
                        config: {
                            content: "<img style='width:100px; height:100px;' src='{{params.building.imageUrl}}'></img> <br/> Building info: {{params.building.name}} "
                        },
                        title: "Building Info"
                    },{
                        flex: "10",
                        fullScreen: false,
                        modalSize: 'lg',
                        type: "markdown",
                        config: {
                            content: "Another widget"
                        },
                        title: "Widget 2"
                    }]
                }, {
                    flex: '30',
                    widgets: [{
                        flex: '100',
                        fullScreen: false,
                        modalSize: 'lg',
                        type: 'markdown',
                        config: {
                            content: 'Yet ANOTHER widget'
                        },
                        title: 'Widget 3'
                    }, {
                        flex: '100',
                        fullScreen: false,
                        modalSize: 'lg',
                        type: 'graph',
                        config: {
                            content: 'Graph widget',
                            start: moment().startOf('year'),
                            end: moment().add(1, 'year').startOf('year'),
                            interval: '1mon',
                            color: '#7ACE46',
                            type: 'line',
                            measure: 'POWER'
                        },
                        title: 'KW: Year to date by month'
                    }]
                },
                {
                    flex: '40',
                    widgets: [{
                        flex: '100',
                        fullScreen: false,
                        modalSize: 'lg',
                        type: 'graph',
                        config: {
                            content: 'Graph widget',
                            start: moment().startOf('month'),
                            end: moment().add(1, 'month').startOf('month'),
                            interval: '1d',
                            color: '#B4B5B5',
                            type: 'column',
                            measure: 'WATER'
                        },
                        title: 'WATER: Past month by day'
                    }, {
                        flex: '100',
                        fullScreen: false,
                        modalSize: 'lg',
                        type: 'graph',
                        config: {
                            content: 'Graph widget',
                            start: moment().subtract(12, 'day').startOf('day'),
                            end: moment().subtract(10, 'day').startOf('day'),
                            interval: '1h',
                            color: '#008BF5',
                            type: 'column',
                            measure: 'ELECTRICITY'
                        },
                        title: 'ELECTRICITY: Past 2 days by hour'
                    }]
                }]
            }]
        }
    }, {
        id: 2,
        name: 'Public Display',
        buildingLevel: false,
        config: {

        }
    }, {
        id: 3,
        name: 'Weather Test',
        config: {
            editable: false,
            structure: '50-50',
            rows: [
                {
                columns: [
                // {
                //     flex: "30",
                //     widgets: [{
                //         flex: "30",
                //         fullScreen: false,
                //         modalSize: 'lg',
                //         type: "weather",
                //         config: {

                //         },
                //         title: "Weather 1"
                //     },{
                //         flex: "10",
                //         fullScreen: false,
                //         modalSize: 'lg',
                //         type: "weather",
                //         config: {

                //         },
                //         title: "Weather 2"
                //     }]
                // },
                {
                    flex: '70',
                    widgets: [{
                        flex: '100',
                        fullScreen: false,
                        modalSize: 'lg',
                        type: 'weather',
                        config: {

                        },
                        title: 'Weather 3'
                    }]
                }]
            }]
        }
    }];

    MockContext('dashboards', () => {
        $httpBackend.whenGET(regex).respond((method, url, data) => {
            const id = parseInt(url.match(regex)[1]);
            const dashboard = _.find(dashboards, { id });
            if (dashboard) {
                return [200, dashboard, {}];
            } else {
                return [500, {}, {}];
            }
        });
    });
});
