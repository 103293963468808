var aq;
(function (aq) {
    var energyInsights;
    (function (energyInsights) {
        var BaseloadV2Service = /** @class */ (function (_super) {
            __extends(BaseloadV2Service, _super);
            function BaseloadV2Service($q, EnergyInsightsDataService, $filter, DataStore, OptionsService, Restangular, $translate) {
                var _this = _super.call(this) || this;
                _this.$q = $q;
                _this.EnergyInsightsDataService = EnergyInsightsDataService;
                _this.$filter = $filter;
                _this.DataStore = DataStore;
                _this.OptionsService = OptionsService;
                _this.Restangular = Restangular;
                _this.$translate = $translate;
                return _this;
            }
            BaseloadV2Service.prototype.getSimpleTargetModel = function (building, measure, periodSearch, name) {
                var targetModel = new energyInsights.SimpleTargetModel();
                targetModel.title = this.$translate.instant('insight.Baseload');
                this.$translate.instant("timeperiod." + periodSearch.label).toLowerCase();
                return targetModel;
            };
            BaseloadV2Service.prototype.getTargetModel = function (building, measure, periodSearch, account, name) {
                var _this = this;
                return this.getTableModel(building, measure, periodSearch, account).then(function (tableData) {
                    return _this.getTargetData(building, tableData, periodSearch, name);
                });
            };
            BaseloadV2Service.prototype.getGraphModel = function (building, measure, periodSearch, account) {
                var _this = this;
                return this.getTableModel(building, measure, periodSearch, account).then(function (tableData) {
                    return {
                        graph: _this.getChartConfig(tableData, building, periodSearch)
                    };
                });
            };
            BaseloadV2Service.prototype.getTableModel = function (building, measure, periodSearch, account) {
                var _this = this;
                return this.getKwHMetric(account)
                    .then(function (metric) {
                    return _this.$q.all([
                        _this.EnergyInsightsDataService.queryBaseload(building, periodSearch.start, periodSearch.end, periodSearch.interval.value, metric, account),
                        _this.DataStore.getList(_this.Restangular
                            .one('accounts', account.id)
                            .one('buildings', building.id)
                            .one('Targets'), 'queryTargets', {
                            startDate: periodSearch.start.format(),
                            endDate: periodSearch.end.format(),
                            targetType: 'BASELOAD'
                        })
                    ]);
                })
                    .then(function (result) {
                    var values = result[0];
                    var targets = result[1];
                    return _this.formatTableModel(values, targets, periodSearch, building);
                });
            };
            BaseloadV2Service.prototype.formatTableModel = function (values, targets, periodSearch, building) {
                if (periodSearch.interval.value === '1d' && targets.length > 0) {
                    var firstTarget = targets[0];
                    var targetDays = 0;
                    var numDays = periodSearch.end.diff(periodSearch.start, 'days');
                    targets = [];
                    for (var i = 0; i <= numDays; i++) {
                        var targetDate = moment(firstTarget.startDate).tz(building.timeZoneId).add(i, 'days');
                        if (targetDate.isoWeekday() !== 6 && targetDate.isoWeekday() !== 7) {
                            targetDays++;
                            targets.push({
                                startDate: targetDate.startOf('days').format(),
                                endDate: targetDate.endOf('days').format()
                            });
                        }
                    }
                    if (targetDays > 0) {
                        var dailyValue_1 = Math.round(firstTarget.value / targetDays);
                        targets.forEach(function (target) {
                            target.value = dailyValue_1;
                        });
                    }
                }
                return this.transformTableData(values, targets, building, periodSearch);
            };
            // --------------------------
            BaseloadV2Service.prototype.transformTableData = function (baseloadData, targets, building, periodSearch) {
                var tableModel = new energyInsights.TableModel();
                var rows = [];
                var _loop_1 = function (i) {
                    var row = {
                        timestamp: baseloadData[i].timestamp,
                        formattedDate: this_1.formatTimestampForChart(baseloadData[i].timestamp, building, periodSearch),
                        value: baseloadData[i].value,
                        isMissingData: baseloadData[i].isMissingData ? baseloadData[i].isMissingData : false
                    };
                    var rowTime = moment(row.timestamp).tz(building.timeZoneId);
                    var target = _.find(targets, function (t) {
                        var startDate = moment(t.startDate).tz(building.timeZoneId).startOf('day');
                        var endDate = moment(t.endDate).tz(building.timeZoneId).endOf('day');
                        return rowTime.isBetween(startDate, endDate) || rowTime.isSame(startDate) || rowTime.isSame(endDate);
                    });
                    if (target) {
                        row.target = target.value;
                        row.status = this_1.getRowStatus(baseloadData[i].value, target.value);
                        row.percentage = this_1.getPercent(baseloadData[i].value, target.value);
                    }
                    rows.push(row);
                };
                var this_1 = this;
                for (var i = 0; i < baseloadData.length; i++) {
                    _loop_1(i);
                }
                tableModel.rows = rows;
                tableModel.totals = {
                    consumptionTotal: this.getAnswer(tableModel),
                    targetTotal: this.getTarget(tableModel)
                };
                return tableModel;
            };
            BaseloadV2Service.prototype.getTargetData = function (building, tableData, periodSearch, name) {
                var today = moment().tz(building.timeZoneId).format('MMM Do');
                var numberOfDaysInMonth = moment().tz(building.timeZoneId).diff(moment().tz(building.timeZoneId).startOf('month'), 'days');
                var percOfTime = this.getPercent(numberOfDaysInMonth, moment().tz(building.timeZoneId).daysInMonth());
                var targetModel = new energyInsights.TargetModel();
                targetModel.question = this.$translate.instant('insight.question.Baseload') + " " + building.name + "?";
                targetModel.title = name;
                targetModel.target = this.getTarget(tableData);
                targetModel.answer = this.getAnswer(tableData);
                targetModel.percentage = this.getPercent(targetModel.answer, targetModel.target);
                targetModel.showDonut = !_.isNull(targetModel.target);
                targetModel.showTarget = !_.isNull(targetModel.target);
                targetModel.formattedAnswer = this.$filter('number')(targetModel.answer, '0,0') + " kWh";
                targetModel.formattedTarget = this.$translate.instant('insight.target.Target') + " " + this.$filter('number')(targetModel.target, '0,0') + " kWh";
                targetModel.when = this.$translate.instant("timeperiod." + periodSearch.label).toLowerCase();
                targetModel.icon = this.getIcon(targetModel.answer, targetModel.target, targetModel.percentage, percOfTime);
                targetModel.color = this.getColor(targetModel.answer, targetModel.target, targetModel.percentage, percOfTime);
                targetModel.iconColor = targetModel.color;
                var tooltipWhen = targetModel.when;
                if (targetModel.when === this.$translate.instant('timeperiod.This Month')) {
                    tooltipWhen = "" + today;
                }
                else {
                    tooltipWhen = tooltipWhen.toLowerCase();
                }
                targetModel.tooltip = this.$translate.instant('insight.tooltip.Baseload1') + " <span style=\"color:" + targetModel.color + "\">" + this.$filter('number')(targetModel.percentage, '0,0') + "%</span> " + this.$translate.instant('insight.tooltip.Baseload2') + " " + tooltipWhen + ".";
                targetModel.timeElapsedPercentage = percOfTime;
                targetModel.timeElapsedLabel = today;
                targetModel.buildingId = building.id;
                return targetModel;
            };
            BaseloadV2Service.prototype.formatTimestampForChart = function (timestamp, building, periodSearch) {
                if (periodSearch.interval.value === '1d') {
                    return moment(timestamp).tz(building.timeZoneId).format('dd MMM Do');
                }
                else {
                    return moment(timestamp).tz(building.timeZoneId).format('MMM');
                }
            };
            BaseloadV2Service.prototype.getChartConfig = function (tableData, building, periodSearch) {
                return {
                    lang: {
                        drillUpText: '< Back to previous selection',
                        noData: 'No baseload data to display.'
                    },
                    exporting: {
                        buttons: {
                            contextButton: {
                                enabled: false
                            }
                        }
                    },
                    title: '',
                    chart: {
                        type: 'column',
                        plotBorderWidth: 1,
                        drilldown: {
                            activeAxisLabelStyle: {
                                textDecoration: 'none',
                                fontWeight: 'normal',
                                color: '#666666'
                            }
                        }
                    },
                    plotOptions: {
                        line: {
                            animation: false
                        },
                        series: {
                            stacking: 'normal',
                            stickyTracking: false
                        },
                        column: {
                            grouping: false,
                            shadow: false,
                            borderWidth: 0
                        }
                    },
                    tooltip: {
                        useHtml: true,
                        formatter: function () {
                            if (this.points && this.points.length > 0) {
                                var seriesString_1 = '';
                                ;
                                var targetString_1 = '';
                                var totalString = '';
                                var titleString_1 = '';
                                var dateFormat_1 = this.points[0].point.drilldown ? 'MMMM YYYY' : 'dd MMM Do';
                                var actualTotalPoint = _.find(this.points, function (p) {
                                    return p.series.name != 'Target';
                                });
                                if (this.points.length > 1 && this.points[0].key !== this.points[1].key) {
                                    this.points.shift();
                                }
                                var actualTotal = 0;
                                if (actualTotalPoint) {
                                    actualTotal = actualTotalPoint.total;
                                }
                                this.points.forEach(function (point) {
                                    var formattedValue = Math.round(point.point.y).toLocaleString();
                                    if (point.series.name != 'Target') {
                                        seriesString_1 += "<span class=\"tooltip-series\" style=\"color:" + point.series.color + ";\">\u2022 </span><span>" + point.series.name + "</span>: " + formattedValue + " kWh <br/><br/>";
                                    }
                                    else {
                                        targetString_1 += "<span class=\"tooltip-bold\">" + point.series.name + ": " + formattedValue + " kWh</span><br/>";
                                    }
                                    titleString_1 += "<span class=\"tooltip-bold\">" + moment(point.point.timestamp).tz(building.timeZoneId).format(dateFormat_1) + "</span>";
                                    targetString_1 += point.point.isMissingData ? '<br><div style="color:red">This building uses data directly <br><div style="color:red">from your utility company.<br><br><i>Some data has not been received yet.' : '';
                                });
                                var currentYear = moment().year();
                                var formattedTotal = Math.round(actualTotal).toLocaleString();
                                totalString += "<span class=\"tooltip-bold\">Total: " + formattedTotal + " kWh</span>";
                                var formattedString = titleString_1 + "<br/><br/>" + seriesString_1 + "<br/><br/>" + totalString + "<br/><br/>" + targetString_1;
                                return formattedString;
                            }
                        },
                        shared: true
                    },
                    xAxis: {
                        type: 'category'
                    },
                    yAxis: [{
                            min: 0,
                            title: {
                                text: "Consumption (kWh)"
                            },
                            plotLines: [{
                                    value: 200,
                                    width: 2
                                }]
                        }],
                    drilldown: {
                        series: []
                    },
                    series: this.buildSeries(tableData, periodSearch, building)
                };
            };
            BaseloadV2Service.prototype.buildSeries = function (tableData, periodSearch, building) {
                var _this = this;
                var underSeries = {
                    name: 'Under Target',
                    color: '#7ACD46',
                    data: [],
                    index: 2
                };
                var overSeries = {
                    name: 'Over Target',
                    color: '#E70F28',
                    data: [],
                    index: 1
                };
                var targetSeries = {
                    type: 'scatter',
                    name: 'target',
                    color: '#0091f1',
                    data: [],
                    showInLegend: false,
                    enableMouseTracking: false,
                    marker: {
                        symbol: 'url(data:image/svg+xml;utf8,' +
                            '<svg height=\"3\" width=\"55\" xmlns=\"http://www.w3.org/2000/svg\">' +
                            '<rect fill=\"#0091F1\" height=\"3\" width=\"55\"/></svg>)'
                    },
                    index: 0
                };
                var drilldown = periodSearch.interval.value === '1mon';
                var hasTarget = false;
                tableData.rows.forEach(function (row) {
                    var category = _this.formatTimestampForChart(row.timestamp, building, periodSearch);
                    if (row.target) {
                        targetSeries.data.push({
                            name: category,
                            timestamp: row.timestamp,
                            y: row.target
                        });
                        hasTarget = true;
                    }
                    if (row.target && row.target < row.value) {
                        overSeries.data.push({
                            name: category,
                            y: (row.value - row.target),
                            timestamp: row.timestamp,
                            drilldown: drilldown,
                            color: row.isMissingData ? _this.EnergyInsightsDataService.transparentColor('#E70F28') : '#E70F28',
                            isMissingData: row.isMissingData
                        });
                        underSeries.data.push({
                            name: category,
                            y: row.target,
                            timestamp: row.timestamp,
                            drilldown: drilldown,
                            color: row.isMissingData ? _this.EnergyInsightsDataService.transparentColor('#7ACD46') : '#7ACD46',
                            isMissingData: row.isMissingData
                        });
                    }
                    else {
                        underSeries.data.push({
                            name: category,
                            y: row.value,
                            timestamp: row.timestamp,
                            drilldown: drilldown,
                            color: row.isMissingData ? _this.EnergyInsightsDataService.transparentColor('#7ACD46') : '#7ACD46',
                            isMissingData: row.isMissingData
                        });
                    }
                });
                var series = [underSeries];
                if (overSeries.data.length > 0) {
                    series.push(overSeries);
                }
                if (targetSeries.data.length > 0) {
                    series.push(targetSeries);
                }
                if (!hasTarget) {
                    underSeries.color = '#979797';
                    underSeries.name = 'Consumption';
                }
                return series;
            };
            BaseloadV2Service.prototype.getRowStatus = function (answer, target) {
                var percentage = this.getPercent(answer, target);
                if (percentage < 80) {
                    return 'under';
                }
                else if (percentage <= 100) {
                    return 'warn';
                }
                else if (percentage > 100) {
                    return 'over';
                }
            };
            BaseloadV2Service.prototype.getKwHMetric = function (account) {
                return this.Restangular.one('accounts', account.id).customGET('queryRealUnits').then(function (allUnits) {
                    return _.find(allUnits, { unit: 'kWh' });
                });
            };
            BaseloadV2Service.prototype.getTarget = function (tableData) {
                return _.sum(_.map(tableData.rows, function (row) { return row.target; }));
            };
            BaseloadV2Service.prototype.getAnswer = function (tableData) {
                return _.sum(_.map(tableData.rows, function (row) { return row.value; }));
            };
            return BaseloadV2Service;
        }(energyInsights.InsightService));
        energyInsights.BaseloadV2Service = BaseloadV2Service;
        angular.module('energyInsights').service('BaseloadV2Service', BaseloadV2Service);
    })(energyInsights = aq.energyInsights || (aq.energyInsights = {}));
})(aq || (aq = {}));
