namespace aq.tenantbilling {
    export class ManualMetersStepCtrl {
        public monthlyBilling: MonthlyTenantBilling;
        public onComplete: Function;
        public tenantInvoices: TenantInvoice[];
        public tenantChargeValues: TenantChargeValue[];
        public progressText: string;
        public formattedSharedCharges: any[];
        tenantLabel: string;
        /* @ngAnnotate */
        constructor(
            private $q: ng.IQService,
            private $location: ng.ILocationService,
            private Messages: aq.services.Messages,
            private $mdStepper,
            private MonthlyBillingService: MonthlyBillingService,
            private $scope,
            private Auth: aq.services.Auth,
            private $stateParams: ng.ui.IStateParamsService
        ) {
            this.formattedSharedCharges = this.formatSharedCharges();
            this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'unit' : 'tenant';
        }

        public $onInit() {
            this.progressText = null;
        }

        public $onChanges(changes) {
            if (changes.tenantChargeValues.currentValue !== changes.tenantChargeValues.previousValue) {
                this.formattedSharedCharges = this.formatSharedCharges();
            }
        }

        public onStepLoad() {
            this.$scope.$emit('STATUS_CHANGE', {statusText: 'Calculating charges. This can take up to a minute.'});
        }

        public previousStep() {
            this.$mdStepper('runInvoicingStepper').back();
        }

        public saveAndContinue() {
            this.progressText = 'Saving readings...';
            let charges = _.filter(this.tenantChargeValues, { manualReading: true });
            if (this.Auth.hasFunctionality('Matan Manual Billing')) {
                const allocatedCharges = [];
                this.formattedSharedCharges.forEach(charge => charge.chargeValues.forEach(value => allocatedCharges.push(value)));
                charges = allocatedCharges;
            }
            const promises = _.map(charges, chargeValue => {
                chargeValue.automatedReading = false;
                this.MonthlyBillingService.saveChargeValue(chargeValue);
            });
            return this.$q.all(promises)
                .then((results) => {
                    this.monthlyBilling.hasEnteredManualMeters = true;
                    return this.$q.all([
                        this.MonthlyBillingService.save(this.monthlyBilling),
                        this.MonthlyBillingService.getInvoices(this.monthlyBilling),
                        this.MonthlyBillingService.getChargeValues(this.monthlyBilling),
                        this.MonthlyBillingService.getPeriodTenantServices(this.monthlyBilling)
                    ]);
                }).then((results) => {
                    this.onComplete({
                        $event: {
                            monthlyBilling: results[0],
                            tenantInvoices: results[1],
                            tenantChargeValues: results[2],
                            periodTenantServices: results[3]
                        }
                    });
                    this.Messages.success('Readings successfully saved');
                    this.$location.search('step', 5);
                    this.$mdStepper('runInvoicingStepper').next();
                })
                .finally(() => {
                    this.progressText = null;
                });
        }

        public getTenantInvoices() {
            return _.filter(this.tenantInvoices, (invoice: TenantInvoice) => {
                return this.getChargeValues(invoice).length > 0;
            });
        }

        public getChargeValues(invoice: TenantInvoice) {
            return _.filter(this.tenantChargeValues, { tenantInvoice: invoice.id, manualReading: true });
        }

        public updateTotal(chargeValue: TenantChargeValue) {
            // this does not currently support rollovers
            if (!_.isNil(chargeValue.currentReading) && !_.isNil(chargeValue.previousReading)
                && chargeValue.currentReading >= chargeValue.previousReading
                && chargeValue.currentReading >= 0 && chargeValue.previousReading >= 0) {
                chargeValue.total = (chargeValue.currentReading - chargeValue.previousReading) * chargeValue.meterMultiplier;
            } else {
                chargeValue.total = null;
            }
        }

        public isNil(value: any): boolean {
            return _.isNil(value);
        }

        public formatSharedCharges() {
            const manualCharges = _.filter(this.tenantChargeValues, {manualReading: true});

            const sharedCollectors = _.uniqBy(manualCharges, 'collector').map(charge => {
                return {
                    collector: charge.collector,
                    service: charge.serviceName,
                    meterName: charge.meterName,
                    previousReading: null,
                    currentReading: null,
                    total: null,
                    usageUnit: charge.usageUnit,
                    meterMultiplier: 1
                };
            });

            sharedCollectors.forEach(collector => {
                const chargeValuesForCollector = _.filter(manualCharges, {collector: collector.collector});
                collector.chargeValues = chargeValuesForCollector;
                collector.previousReading = chargeValuesForCollector.reduce((acc, charge) =>
                acc + charge.previousReading, 0
                );
                this.setTenantAllocations(collector);
            });
            return sharedCollectors;
        }

        public updateAllocatedCharges(totalCharge) {
            this.updateTotal(totalCharge);
            totalCharge.chargeValues.forEach(chargeValue => {
                chargeValue.currentReading = _.round(chargeValue.previousReading + (totalCharge.total * (chargeValue.allocationPercent / 100)), 0);
                this.updateTotal(chargeValue);
            });
        }

        public setTenantAllocations(collector) {
            this.getTenantConsumption(collector).then((response) => {
                const totalTenantConsumption = response.reduce((acc, tenant) => acc + tenant.values.total, 0);
                collector.chargeValues.forEach((tenant) => {
                    const tenantMetrics = _.filter(response, ['name', tenant.tenantName]);
                    tenant.allocationPercent = _.round((tenantMetrics[0].values.total / totalTenantConsumption) * 100, 2);
                });
            });
        }

        public getTenantConsumption(collector) {
            const startReading = this.monthlyBilling.startReadingDate;
            const endReading = this.monthlyBilling.readingDate;
            const measure = collector.chargeValues[0].serviceType === 'ELECTRICITY' ? 'ENERGY' : collector.chargeValues[0].serviceType;
            return this.MonthlyBillingService.getTenantConsumption(
                this.$stateParams.accountId,
                this.$stateParams.buildingId,
                startReading,
                endReading,
                measure
            );
        }
    }

    angular
        .module('tenantBilling')
        .component('manualMetersStep', {
            templateUrl: 'app/tenantBilling/invoicing/run/components/manualMetersStep/manualMetersStep.html',
            controller: ManualMetersStepCtrl,
            controllerAs: 'vm',
            bindings: {
                monthlyBilling: '<',
                tenantInvoices: '<',
                tenantChargeValues: '<',
                onComplete: '&'
            }
        });
}
