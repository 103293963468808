namespace aq.propertySettings {

    export class BreakerPanel {

        public uses;
        public merge;
        public update;
        public split;
        public droppedOnCircuit;
        public sources;
        public configure;

        public isReadOnly: boolean;
        public leftGroup;
        public rightGroup;
        public filteredUses;

        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private Auth: aq.services.Auth
        ) {
            this.isReadOnly = !Auth.check({access: 'EDIT'});
            this.filteredUses = this.uses.filter((use) => use.name);

            $scope.$watch('model.circuits', (circuits) => {

                if (! circuits) return;
                const loads = _.filter(circuits, (c) => {
                    return c.type == 'LOAD';
                });

                this.rightGroup = _.chain(loads)
                    .reject((num) => { return num.number % 2 != 0; })
                    .sortBy('number')
                    .value();

                this.leftGroup = _.chain(loads)
                    .reject((num) => { return num.number % 2 == 0; })
                    .sortBy('number')
                    .value();
            });
        }

        public onDrop(argument) {
            this.droppedOnCircuit({event: argument});
        }

        public isInputBridgeable(inputsCount) {
            const maxInputsBridged = this.$scope.model.phase == 3 ? 3 : 2;
            return inputsCount < maxInputsBridged;
        }

    }
    angular.module('properties').directive('breakerPanel', () => {
        return {
            restrict: 'E',
            replace: true,
            transclude: true,
            bindToController: {
                model: '=',
                uses: '=',
                merge: '&',
                split: '&',
                update: '&',
                droppedOnCircuit: '&',
                sources: '=',
                configure: '='
            },
            scope: {
                model: '='
            },
            templateUrl: 'app/properties/sources/details/common/breakerPanel/breakerPanel.html',
            controller: BreakerPanel,
            controllerAs: 'vm'
        };
    });
}
