namespace aq.ui {
    export class EditBuildingModalCtrl extends aq.common.Controllers.ModalCtrl {

        private building: aq.common.models.Building;
        private isAutofill: boolean;
        private searchText: string;
        /* ngInject */
        constructor(
            private editBuilding: aq.common.models.Building,
            private isNew: boolean,
            private BuildingService: aq.services.BuildingService,
            protected $mdDialog: ng.material.IDialogService
        ) {
            super(null, $mdDialog);
            this.building = angular.copy(this.editBuilding);
            this.isAutofill = true;
        }

        public apply() {
            if (!this.isAutofill) {
                this.building.address = this.searchText;
            }
            this.hide(this.building);
        }

        public selectedItemChange(item) {
            if (item && item.place) {
                const { street, city, stateCode, postalCode, countryCode } = item.place.properties;
                this.building.address = street;
                this.building.city = city;
                this.building.state = stateCode;
                this.building.zipCode = postalCode;
                this.building.country = countryCode;
            }
        }

        public queryAddress(searchText) {
            return this.BuildingService.findAddress(searchText);
        }
    }
    angular.module('aq.ui').controller('EditBuildingModalCtrl', EditBuildingModalCtrl);
}
