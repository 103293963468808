namespace aq.ui {
    angular.module('aq.ui').directive('aqSideNav', ($state, $mdMedia) => {
        return {
            restrict: 'E',
            transclude: true,
            templateUrl: 'app/common/directives/aqSideNav/aqSideNav.html',
            scope: {
                showBuildingSelector: '=',
                disableBuildingSelectorCond: '=?',
                buildings: '=',
                currentBuilding: '=',
                account: '=',
                hideMenuAfterSelect: '@',
                startMenuHidden: '@',
                conditionalRedirect: '=?',
                menuOpen: '=?'
            },
            link: ($scope: any) => {
                if ($scope.disableBuildingSelectorCond == undefined) {
                    $scope.disableBuildingSelectorCond = false;
                }
                if ($scope.menuOpen == undefined) {
                    if ($scope.startMenuHidden) {
                        $scope.menuOpen = false;
                    } else {
                        $scope.menuOpen = true;
                    }
                }
                if ($scope.conditionalRedirect == undefined) {
                    $scope.conditionalRedirect = true;
                }
                $scope.menu = {
                    buildingSelector: {
                        open: false,
                        searchText: ''
                    }
                };

                $scope.$mdMedia = $mdMedia;
                if (!$mdMedia('gt-sm')) {
                    $scope.menuOpen = true;
                }
                $scope.$watch(() => { return $mdMedia('gt-sm'); }, (isLarge) => {
                    $scope.menuOpen = !isLarge;
                });

                $scope.selectBuilding = function(building) {
                    if ($scope.hideMenuAfterSelect) {
                        $scope.menuOpen = false;
                    }
                    if ($scope.conditionalRedirect) {
                        $state.go($state.current, {accountId: $scope.account.id, buildingId: building.id});
                    }
                };

                $scope.toggleSelectBuilding = function() {
                    if (!$scope.disableBuildingSelectorCond) {
                        $scope.menu.buildingSelector.open = !$scope.menu.buildingSelector.open;
                    }
                };

                $scope.filterBuildings = function() {
                    let searchText = $scope.menu.buildingSelector.searchText.toLowerCase();
                    $scope.availableBuildings = {};
                    if ($scope.buildings) {
                        $scope.buildings.forEach((building) => {
                            if (!$scope.currentBuilding || (
                                    building.id !== $scope.currentBuilding.id
                                    && (!searchText || building.name.toLowerCase().indexOf(searchText) > -1))) {
                                let buildingGroupName = building.buildingGroup ? building.buildingGroup.name : 'nogroup';
                                if ($scope.availableBuildings[buildingGroupName]) {
                                    $scope.availableBuildings[buildingGroupName].push(building);
                                } else {
                                    $scope.availableBuildings[buildingGroupName] = [building];
                                }
                            }
                        });
                    }
                };

                $scope.filterBuildings();
            }
        };
    });
}