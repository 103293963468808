angular.module('aq.services.restangular', ['restangular']).provider('RestangularDates', function(RestangularProvider) {

    var config = {
        dateFields: [],
        dateTimeFields: [],
        custom: []
    }

    function registerMomentFields(model, format, fields) {
        return function(elem, operation, what) {
            if (elem && what === model) {
                _.each(fields, function(field) {
                    if (elem[field]) elem[field] = moment(elem[field]).format(format);
                })
            }
            return elem;
        }
    }

    function toInterceptors(elems, format) {
        return _.keys(elems).map(function(key) {
            return registerMomentFields(key, format, elems[key]);
        });
    }

    function combineInterceptors(functions) {
        return function(elem, operations, what) {
            var e = elem;
            _.each(functions, function(f) {
                f(e, operations, what);
            })
            return e;
        }
    }
    const provider = {

        dateFields: function (fields) {
            _.extend(config.dateFields, fields);
            return this;
        },

        dateTimeFields: function (fields) {
            _.extend(config.dateTimeFields, fields);
            return this;
        },

        custom: function (func) {
            config.custom.push(func);
            return this;
        },

        register: function () {
            var interceptors = toInterceptors(config.dateFields, 'YYYY-MM-DD').concat(
                toInterceptors(config.dateTimeFields, moment.defaultFormat),
                config.custom
            );
            RestangularProvider.setRequestInterceptor(combineInterceptors(interceptors));
        },

        $get: function () {
        }
    }
    return provider;

});
