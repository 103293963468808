namespace aq.ui {

    export class AqSidebarNavNestedMenu implements common.models.SidebarNavItem {
        public title: string;
        public state: string;
        public stateData: any;
        public stateOptions: any;
        public icon: string;
        public aria: string;
        public isActiveFn: () => boolean;
        public actionFn: () => boolean;

        /* @ngInject */
        constructor(private SidebarNavHelperService: aq.services.SidebarNavHelperService, private $mdMedia: angular.material.IMedia) {
            this.title = '';
            this.state = '';
            this.stateData = null;
            this.stateOptions = null;
            this.icon = '';
            this.aria = '';
        }
    }

    angular
        .module('aq.ui')
        .component('aqSidebarNavNestedMenu', {
            templateUrl: 'app/common/directives/aqSidebarNav/aqSidebarNavNestedMenu/aqSidebarNavNestedMenu.html',
            controller: AqSidebarNavNestedMenu,
            controllerAs: 'vm',
            bindings: {
                title: '@',
                state: '@?',
                stateData: '<?',
                stateOptions: '<?',
                icon: '@?',
                aria: '@?',
                isActiveFn: '&?',
                actionFn: '&?'
            },
            require: {
                parent: '^^aqSidebarNav'
            },
            transclude: true
        });
}
