namespace aq.deviceManagement {
    export class DeviceStatusComponent {
        static STATUS_INTERVAL_DETAILS = 10000;
        static STATUS_INTERVAL_OVERVIEW = 180000;
        public datastatus: any;
        public status: string;
        public statusDescription: string;
        public displayStatus: string;
        public lastReportedTime: Date;
        public powerStatistics: PowerStatistics;
        public temperatureStatistics;
        public rssiStatistics;
        public dcIcon: string;
        public batteryIcon: string;
        public batteryIconColor: string;
        public batteryCapacity: number;
        public powerStatusSummary: string;
        public icon: string;
        public color: string;
        public timeZone: string;
        public message: string;
        public iconOnly: boolean;
        public changes: number;
        public description: string;
        public device: aq.common.models.DeviceElement;
        public updateStatusInterval: ng.IPromise<any>;
        public disableStepHelp: boolean;
        public userId: any;
        public lastUpdated: any;
        public lastUpdatedBy: any;
        public showLastUpdate: boolean;
        public onDataStatusLoaded: (param: { data: aq.common.models.DeviceDataStatus }) => void;
        public skipAutoRefresh: boolean;
        private isAdmin: boolean;
        /* @ngInject */
        constructor(private $filter, private $mdDialog,
                    private $interval: ng.IIntervalService,
                    private UserService) {
        }

        $onInit() {
            this.calculateStatus();
            if (!this.iconOnly && !this.skipAutoRefresh) {
                this.updateStatusInterval = this.$interval(() => this.calculateStatus(), DeviceStatusComponent.STATUS_INTERVAL_DETAILS);
                if (this.showLastUpdate) {
                    this.getLastUpdated();
                }
            } else if (!this.skipAutoRefresh) {
                this.updateStatusInterval = this.$interval(() => this.calculateStatus(), DeviceStatusComponent.STATUS_INTERVAL_OVERVIEW);
            }
        }

        $onChanges() {
            this.calculateStatus();
        }

        $onDestroy() {
            this.$interval.cancel(this.updateStatusInterval);
        }

        public showStepHelp($event, step: aq.common.models.WorkflowStep) {
            if ((this.device.deviceCategory === 'NETWORKING' || this.device.deviceCategory === 'METER') && !this.disableStepHelp) {
                this.$mdDialog.show(
                    this.$mdDialog.alert()
                        .clickOutsideToClose(true)
                        .title(this.displayStatus)
                        .htmlContent(this.description)
                        .ariaLabel(`${this.displayStatus} description`)
                        .ok('Got it!')
                        .targetEvent($event));
            }
        }

        public showPowerDetail(targetEvent) {
            this.$mdDialog.show({
                targetEvent,
                controller: 'powerStatusCtrl as vm',
                templateUrl: 'app/deviceManagement/device/components/powerStatusCtrl.html',
                clickOutsideToClose: true,
                locals: {
                    powerStatistics: this.powerStatistics,
                    timeZone: this.timeZone
                }
            });
        }

        private updateDataStatus(dataStatus) {
            this.status = dataStatus ? dataStatus.status : null;
            this.statusDescription = dataStatus ? dataStatus.statusDescription : null;
            this.lastReportedTime = dataStatus ? dataStatus.lastReportedTime : null;
            this.updateStatusDisplay();
        }

        private updatePowerStatus(powerStatistics: PowerStatistics) {
            this.powerStatistics = powerStatistics;
            if (powerStatistics) {
                const batteryIndex = powerStatistics.powerSource.map(source => source.source).indexOf('BATTERY');
                const dcIndex = powerStatistics.powerSource.map(source => source.source).indexOf('DC');
                if (batteryIndex > -1) {
                    this.batteryCapacity = powerStatistics.powerSource[batteryIndex].capacity;
                    this.batteryIcon = this.getPowerIconFromBatteryCapacity(this.batteryCapacity);
                    this.batteryIconColor = this.getPowerIconColorFromBatteryCapacity(this.batteryCapacity);
                    this.powerStatusSummary = this.getPowerStatusFromBatteryCapacity(this.batteryCapacity);
                } else {
                    this.batteryIcon = '';
                    this.powerStatusSummary = '';
                }
                if (dcIndex > -1) {
                    this.dcIcon = 'power';
                } else {
                    this.dcIcon = '';
                }
            }
        }

        private getPowerStatusFromBatteryCapacity(batteryCapacity: number): string {
            if (batteryCapacity > 10) {
                return '';
            }
            if (batteryCapacity > 5) {
                return 'Low Battery';
            }
            return 'Critically Low Battery';
        }

        private getPowerIconFromBatteryCapacity(batteryCapacity: number): string {
            if (batteryCapacity > 10) {
                return 'battery_std';
            }
            return 'battery_alert';
        }

        private getPowerIconColorFromBatteryCapacity(batteryCapacity: number): string {
            if (batteryCapacity > 20) {
                return 'green';
            }
            if (batteryCapacity > 5) {
                return 'orange';
            }
            return 'red';
        }

        private calculateStatus() {
            if (this.skipAutoRefresh) {
                this.updateDataStatus(this.datastatus);
                if (this.datastatus) {
                    this.updatePowerStatus(this.datastatus.powerStatistics);
                    this.temperatureStatistics = this.datastatus.temperatureStatistics;
                    this.rssiStatistics = this.datastatus.rssiStatistics;
                }
                return;
            }
            this.device.customGET('data-status', {ts: moment().valueOf()}).then((result) => {
                this.updateDataStatus(result);
                this.updatePowerStatus(result.powerStatistics);
                this.temperatureStatistics = result.temperatureStatistics;
                this.rssiStatistics = result.rssiStatistics;
                this.updateStatusDisplay();
            });
        }

        private getLastUpdated() {
            if (this.UserService.currentUser.userType === 'ADMINISTRATOR') {
                this.device.customGET('audit/last').then((result) => {
                    if (result) {
                        this.lastUpdated = result.timestamp && result.timestamp;
                        this.userId = result.userId && result.userId;
                        this.lastUpdatedBy = result.userFullName && result.userFullName;
                        if (!this.lastUpdatedBy) {
                            this.lastUpdatedBy = this.userId ? `Removed User (${this.userId})` : 'System';
                        }
                    } else {
                        this.lastUpdated = null;
                        this.lastUpdatedBy = null;
                        this.userId = null;
                    }
                });
            }
        }

        private updateStatusDisplay() {
            this.isAdmin = this.UserService.currentUser.userType === 'ADMINISTRATOR';
            if (this.status) {
                switch (this.status) {
                    case 'ONLINE':
                        if (this.batteryCapacity !== null && this.batteryCapacity <= 10) {
                            this.displayStatus = 'Low Battery';
                            this.icon = 'warning';
                            this.color = 'yellow';
                            this.message = 'Reporting Data';
                            this.description = `The device is connected to the network and receiving data.  Battery capacity is low`;
                        } else {
                            this.displayStatus = 'Online';
                            this.icon = 'check_circle';
                            this.color = 'green';
                            this.message = 'Reporting Data';
                            this.description = `The device is connected to the network and receiving data.`;
                        }
                        break;
                    case 'OFFLINE':
                        this.displayStatus = 'Offline';
                        this.icon = 'error';
                        this.color = 'red';
                        this.message = `Last Reported on ${this.$filter('date')(this.lastReportedTime, 'short', this.timeZone)}`;
                        let statusDescription = '';
                        if (this.isAdmin && this.statusDescription != null) {
                            statusDescription = '<br /><br />Error reported: ';
                            statusDescription += this.statusDescription;
                        }
                        this.description = this.device.deviceCategory === 'NETWORKING' ?
                        'This status means that the networking device has a valid networking ID, but is not ' +
                        'connected to the network for one of the following reasons:<br /><br />' +
                        '   • If the networking device is a gateway, the device itself is offline<br />'  +
                        '   • If the networking device is not a gateway, either the device itself or the gateway it is connected to is offline' +
                        statusDescription :
                        'This status means that the meter is offline because it is linked to a networking device ' +
                        'and/or gateway with a valid networking ID, but either the networking device or the gateway are offline.' +
                        statusDescription;
                        break;
                    case 'NEVER_REPORTED':
                        this.displayStatus = 'Never Reported';
                        this.icon = 'warning';
                        this.color = 'yellow';
                        this.message = `Not Reported Yet`;
                        this.description = `This status means that the device has a valid networking ID, but has never reported to Aquicore.`;
                        break;
                    case 'WAITING':
                        this.displayStatus = 'Waiting for Connection';
                        this.icon = 'cached';
                        this.color = 'blue';
                        this.message = `Waiting for first connection`;
                        this.description = `This status means that the device has a valid networking ID,
                        but not enough time has passed for the device to have reported to Aquicore`;
                        break;
                    default:
                        this.displayStatus = 'Not Web Enabled';
                        this.icon = 'do_not_disturb_on';
                        this.color = 'blue';
                        this.message = `Not Connected To Networking Device`;
                        this.description = this.device.deviceCategory === 'NETWORKING' ?
                        `This status could be due to one of the following reasons:<br /><br />
                            • The networking device does not have a valid networking ID<br />
                            • The networking device is not linked to a gateway<br />
                            • The networking device is linked to a gateway with an invalid networking ID` :
                        `This status could be due to one of the following reasons:<br /><br />
                            • The meter is not linked to a networking device yet<br />
                            • The meter's linked networking device does not have a valid networking ID<br />
                            • The meter's linked networking device is not linked to a gateway<br />
                            • The meter's linked networking device is also linked to a gateway,
                            but the gateway does not have a valid networking ID`;
                }
                if (this.onDataStatusLoaded) {
                    const data: aq.common.models.DeviceDataStatus = {
                        id: this.device.id,
                        status: this.status,
                        lastReportedTime: this.lastReportedTime as any
                    };
                    this.onDataStatusLoaded({ data });
                }
            } else {
                this.displayStatus = 'Not Web Enabled';
                this.icon = 'do_not_disturb_on';
                this.color = 'blue';
                this.message = `Not Connected To Networking Device`;
                this.description = this.device.deviceCategory === 'NETWORKING' ?
                        `This status could be due to one of the following reasons:<br /><br />
                            • The networking device does not have a valid networking ID<br />
                            • The networking device is not linked to a gateway<br />
                            • The networking device is linked to a gateway with an invalid networking ID` :
                        `This status could be due to one of the following reasons:<br /><br />
                            • The meter is not linked to a networking device yet<br />
                            • The meter's linked networking device does not have a valid networking ID<br />
                            • The meter's linked networking device is not linked to a gateway<br />
                            • The meter's linked networking device is also linked to a gateway,
                            but the gateway does not have a valid networking ID`;
            }
        }
    }

    angular.module('deviceManagement')
        .component('deviceStatus', {
            templateUrl: 'app/deviceManagement/device/components/DeviceStatus.html',
            bindings: {
                timeZone: '<',
                iconOnly: '<',
                device: '<',
                changes: '<',
                disableStepHelp: '<',
                skipAutoRefresh: '<?',
                onDataStatusLoaded: '&?',
                datastatus: '<?',
                showLastUpdate: '<'
            },
            controller: aq.deviceManagement.DeviceStatusComponent,
            controllerAs: 'vm'
        });
}

