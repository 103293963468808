namespace aq.properties.dataCollectors {
    import Point = aq.common.models.Point;
    import Unit = aq.common.models.Unit;
    declare let Highcharts: __Highcharts.Static;

    export class MeterDataComponentCtrl  {
        collector;
        meterDataParameters: MeterDataParameters;
        private chartConfig = null;
        private points: Point[];
        private filteredPoints: Point[] = [];
        private queryInProgress: boolean = false;
        private units: aq.common.models.MeterDataUnit[] = this.getMeterDataUnits();

        /* @ngInject */
        constructor(private DataService, private Messages) {
            this.meterDataParameters = {
                point: null,
                interval: '1min',
                start: moment().subtract(1, 'week'),
                end: moment(),
                units: this.units.filter((unit) => _.includes(['AMPERAGE', 'POWER_FACTOR'], unit.apiUnit))
            };
            // TODO: timezone support
            const timezoneOffset = 0 - moment().tz(moment.tz.guess()).utcOffset();
            Highcharts.setOptions({global: {timezoneOffset}});
        }

        $onInit() {
            this.filteredPoints = this.filterAndSortPoints(this.points);
        }

        $onChanges(changesObj) {
            if (changesObj.points) {
                this.filteredPoints = this.filterAndSortPoints(this.points);
            }
        }

        getChartConfig() {
            return this.chartConfig;
        }

        getPoints(): Point[] {
            return this.filteredPoints;
        }

        getUnits(): aq.common.models.MeterDataUnit[] {
            return this.units;
        }

        getQueryInProgress(): boolean {
            return this.queryInProgress;
        }

        pointSelect() {
            if (this.meterDataParameters.point && this.meterDataParameters.units.length > 0) {
                this.getMeterDataConfig();
            } else {
                this.chartConfig = null;
            }
        }

        getMeterDataConfig() {
            this.queryInProgress = true;
            this.DataService.data(this.meterDataParameters.point, this.meterDataParameters.interval,
                this.meterDataParameters.start, this.meterDataParameters.end,
                this.transformUnitsToApiUnits(this.meterDataParameters.units), {multi: true})
                .then((data: MeterDataResult) => {
                    this.chartConfig = this.buildChartConfig(data, this.meterDataParameters.units);
                })
                .catch(() => {
                    this.chartConfig = null;
                    this.Messages.error('Problem when querying data, try again');
                })
                .finally(() => {
                    this.queryInProgress = false;
                });
        }

        filterAndSortPoints(allPoints: Point[]): Point[] {
            const filteredPoints = _.filter(allPoints, (point) => point.type === 'ENERGY');
            return _.sortBy(filteredPoints, 'name');
        }

        buildChartConfig(data, units: aq.common.models.MeterDataUnit[]) {
            const series = this.createSeries(data, units);
            return {
                chart: {
                    plotBorderWidth: 1,
                    type: 'line',
                    zoomType: 'x',
                    panning: 'true',
                    panKey: 'shift'
                },
                legend: {
                    enabled: series.length > 1
                },
                title: {
                    text: this.getChartTitle()
                },
                tooltip: {
                    xDateFormat: '%B %e, %Y %l:%M %p',
                    shared: true
                },
                xAxis: {
                    type: 'datetime',
                    tickLength: 0,
                    gridLineWidth: 1,
                    gridLineColor: '#c7c7c7'
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: true
                        }
                    }
                },
                plotOptions: {
                    line: {
                        animation: false
                    },
                    series: {
                        turboThreshold: 21000,
                        connectNulls: true
                    }
                },
                navigator: {
                    enabled: true,
                    series: {
                        includeInCSVExport: false
                    }
                },
                scrollbar: {
                    enabled: true
                },
                rangeSelector: {
                    enabled: true,
                    inputEnabled: false,
                    buttons: [
                        { type: 'hour', text: '1h', count: 1},
                        { type: 'day', text: '1d', count: 1},
                        { type: 'all', text: 'All' }
                    ]
                },
                yAxis: this.createYAxis(units),
                series
            };
        }

        transformUnitsToApiUnits(units: aq.common.models.MeterDataUnit[]): string[] {
            return units.map((unit) => unit.apiUnit.toString());
        }

        private getMeterDataUnits(): aq.common.models.MeterDataUnit[] {
            const amperage = {
                apiUnit: 'AMPERAGE',
                series: {
                    color: '#0091f1',
                    name: 'Current'
                },
                yAxis: {
                    title: {
                        text: 'Current'
                    },
                    labels: {
                        format: '{value} A',
                        style: {
                            color: '#0091f1'
                        }
                    }
                }
            };
            const powerFactor = {
                apiUnit: 'POWER_FACTOR',
                series: {
                    color: '#fc6f71',
                    name: 'Power Factor'
                },
                yAxis: {
                    title: {
                        text: 'Power Factor'
                    },
                    labels: {
                        format: '{value} PF',
                        style: {
                            color: '#fc6f71'
                        }
                    }
                }
            };
            const voltage = {
                apiUnit: 'VOLTAGE',
                series: {
                    color: '#f1a81d',
                    name: 'Voltage'
                },
                yAxis: {
                    title: {
                        text: 'Voltage'
                    },
                    labels: {
                        format: '{value} V',
                        style: {
                            color: '#f1a81d'
                        }
                    }
                }
            };
            const lineVoltage = {
                apiUnit: 'LINE_VOLTAGE',
                series: {
                    color: '#7acd46',
                    name: 'Line Voltage'
                },
                yAxis: {
                    title: {
                        text: 'Line Voltage'
                    },
                    labels: {
                        format: '{value} V',
                        style: {
                            color: '#7acd46'
                        }
                    }
                }
            };
            return [amperage, powerFactor, voltage, lineVoltage];

        }

        private createSeries(data: MeterDataResult, units: aq.common.models.MeterDataUnit[]): any[] {
            let yAxis = 0;
            return units.map((unit) => {
                let series = this.getSeriesForData(data[unit.apiUnit.toLowerCase()], unit);
                _.extend(series, { yAxis: yAxis++ });
                return series;
            });
        }

        private getSeriesForData(meterData: MeterData, unit: aq.common.models.MeterDataUnit) {
            const series = _.extend({}, unit.series, {data: []});
            for (let i = 0; i < meterData.timestamps.length; i++) {
                series.data.push({
                    x: meterData.timestamps[i],
                    y: meterData.values[i]
                });
            }
            return series;
        }

        private createYAxis(enabledUnits: aq.common.models.MeterDataUnit[]) {
           let axisCount = 0;
           return enabledUnits.map((unit) => {
               return _.extend({}, unit.yAxis, {
                   gridLineColor: '#c7c7c7',
                   gridLineWidth: 1,
                   opposite: axisCount++ % 2 != 0
               });
           });
        }

        private getChartTitle(): string {
            return this.collector.name + ': ' + this.meterDataParameters.point.name;
        }
    }

    export interface MeterDataResult {
        [unit: string]: MeterData;
    }

    export interface MeterData {
        timestamps: number[];
        values: number[];
    }

    interface MeterDataParameters {
        point: Point;
        interval: string;
        start: moment.Moment;
        end: moment.Moment;
        units: aq.common.models.MeterDataUnit[];
    }

    angular.module('properties.dataCollectors')
        .component('meterData', {
            templateUrl: 'app/properties/dataCollectors/details/collector/tabs/meterData.html',
            bindings: {
                collector: '<',
                points: '<'
            },
            controller: aq.properties.dataCollectors.MeterDataComponentCtrl
        });
}
