namespace aq.DeviceManagement {
    export class ExtraFields {
        public templateFields: aq.common.models.DeviceExtraFields;
        public valueFields: {[k: string]: {value: string}};
        public isAdmin;
    }

    angular
        .module('deviceManagement')
        .component('extraFields', {
            controller: ExtraFields,
            controllerAs: 'vm',
            templateUrl: 'app/deviceManagement/device/components/ExtraFields/extraFields.html',
            bindings: {
                templateFields: '<',
                valueFields: '=',
                isAdmin: '<'
            }
        });
}
