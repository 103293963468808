var aq;
(function (aq) {
    var ui;
    (function (ui) {
        var EditBuildingPersonnelModalCtrl = /** @class */ (function (_super) {
            __extends(EditBuildingPersonnelModalCtrl, _super);
            /* ngInject */
            function EditBuildingPersonnelModalCtrl(editBuildingPersonnel, editBuildingPersonnelUsers, $mdDialog) {
                var _this = _super.call(this, null, $mdDialog) || this;
                _this.editBuildingPersonnel = editBuildingPersonnel;
                _this.editBuildingPersonnelUsers = editBuildingPersonnelUsers;
                _this.$mdDialog = $mdDialog;
                _this.building = angular.copy(_this.editBuildingPersonnel);
                _this.users = angular.copy(_this.editBuildingPersonnelUsers);
                _this.initUserItems();
                return _this;
            }
            EditBuildingPersonnelModalCtrl.prototype.queryUsers = function (searchText) {
                if (!searchText) {
                    searchText = '';
                }
                searchText = searchText.toLowerCase();
                return _.filter(this.userItems, function (usr) {
                    return usr.name && usr.name.toLowerCase().indexOf(searchText) > -1;
                });
            };
            EditBuildingPersonnelModalCtrl.prototype.initUserItems = function () {
                var _this = this;
                this.userItems = _.map(this.users, function (u) {
                    var item = {
                        id: u.index !== undefined ? u.index : parseInt(u.id),
                        name: u.firstName + ' ' + u.lastName,
                        personnelId: u.personnelId
                    };
                    return item;
                });
                this.selectedUsers = _.filter(this.userItems, function (u) { return _.some(_this.building.personnel, function (p) {
                    if (!p.user) {
                        return false;
                    }
                    if (p.user.index !== undefined && p.user.index == u.id) {
                        u.personnelId = p.id;
                        return true;
                    }
                    else if (p.user.user && p.user.user == u.id) {
                        u.personnelId = p.user.id;
                        return true;
                    }
                    else if (p.user.id == u.id) {
                        u.personnelId = p.user.id;
                        return true;
                    }
                    else {
                        return false;
                    }
                }); });
            };
            EditBuildingPersonnelModalCtrl.prototype.apply = function () {
                var _this = this;
                this.building.personnel = _.filter(this.users, function (u) { return _.some(_this.selectedUsers, function (su) {
                    if (u.index !== undefined && u.index == su.id) {
                        u.personnelId = su.personnelId;
                        return true;
                    }
                    else if (u.id == su.id) {
                        u.personnelId = su.personnelId;
                        return true;
                    }
                    else {
                        return false;
                    }
                }); }).map(function (user) {
                    return {
                        id: user.personnelId,
                        user: user
                    };
                });
                this.hide(this.building);
            };
            return EditBuildingPersonnelModalCtrl;
        }(aq.common.Controllers.ModalCtrl));
        ui.EditBuildingPersonnelModalCtrl = EditBuildingPersonnelModalCtrl;
        angular.module('aq.ui').controller('EditBuildingPersonnelModalCtrl', EditBuildingPersonnelModalCtrl);
    })(ui = aq.ui || (aq.ui = {}));
})(aq || (aq = {}));
