'use strict';

angular.module('aq.reports').factory('MonthlyCharts',
    function(OptionsService, $filter) {

    let MonthlyCharts = function(currentData, previousData, report, currentMonth) {
        this.getUsageChart = function(metric, unit) {
            let series = [];
            currentData.data = mergeRealAndMissingDataSets(currentData.data, metric);
            if(hasData(currentData.data[metric].mergedValues)) {
                series.push({
                    data: _.take(currentData.data[metric].mergedValues.map((value) => {
                        return setValue(value, report.colors.primaryColor, unit);
                    }), 12),
                    name: moment(currentMonth).tz(report.building.timeZoneId).format('YYYY'), // TODO - drop all relations to year property
                    color: report.colors.primaryColor,
                    type: 'column',
                    legendIndex: 0
                })
            };

            previousData.data = mergeRealAndMissingDataSets(previousData.data, metric);
            if(hasData(previousData.data[metric].mergedValues)) {
                series.push({
                    data: _.take(previousData.data[metric].mergedValues.map((value) => {
                        return setValue(value, report.colors.secondaryColor, unit);
                    }), 12),
                    name: moment(currentMonth).tz(report.building.timeZoneId).subtract(1, 'year').format('YYYY'),  // TODO - drop all relations to year property
                    color: report.colors.secondaryColor,
                    type: 'column',
                    legendIndex: 1
                })
            };
            let yAxis = {
                title: {text: report.usageUnit.unit},
                tickAmount: 6
            };

            let height = report.usageUnit.apiUnit == 'ENERGY' ? 250 : 300;

            return this.buildChart(series,  this.getXAxisLabels('monthly'), height, false, yAxis)
        }

        this.getDemandChart = function() {
            let series = [];
            currentData.data = mergeRealAndMissingDataSets(currentData.data, 'power');
            if(hasData(currentData.data.power.mergedValues)) {
                series.push({
                    data: _.take(currentData.data.power.mergedValues.map((value) => {
                        return setValue(value, report.colors.primaryColor, report.demandUnit)
                    }), 12),
                    name: moment(currentMonth).tz(report.building.timeZoneId).format('YYYY'),
                    color: report.colors.primaryColor,
                    type: 'line',
                    legendIndex: 0
                });
            };

            previousData.data = mergeRealAndMissingDataSets(previousData.data, 'power');
            const processedData = _.take(previousData.data.power.mergedValues.map((value) => {
                return setValue(value, report.colors.secondaryColor, report.demandUnit)
            }), 12);
            if(hasData(previousData.data.power.mergedValues)) {
                series.push({
                    data: processedData,
                    name: moment(currentMonth).tz(report.building.timeZoneId).subtract(1, 'year').format('YYYY'),
                    color: report.colors.secondaryColor,
                    type: 'line',
                    legendIndex: 1,
                    zoneAxis: 'x',
                    zones: getZones(processedData, previousData.data.power.timestamps)
                });
            };

            let yAxis = {
                title: {text: 'kW'},
                tickAmount: 6
            };

            return this.buildChart(series,  this.getXAxisLabels('monthly'), 300, false, yAxis);
        }

        this.getWeatherChart = function() {
            let series = [];
            if(hasData(currentData.data.degreeDays.hdd.values)) {
                series.push({
                    data: _.take(currentData.data.degreeDays.hdd.values, 12),
                    name: moment(currentMonth).tz(report.building.timeZoneId).format('YYYY') + " HDD",
                    color: report.colors.weatherPrimaryColor,
                    stack: 'current',
                    type: 'column',
                    legendIndex: 0
                })
            };

            if(hasData(currentData.data.degreeDays.cdd.values)) {
                series.push({
                    data: _.take(currentData.data.degreeDays.cdd.values, 12),
                    name: moment(currentMonth).tz(report.building.timeZoneId).format('YYYY') + " CDD",
                    color: '#008AF4',
                    stack: 'current',
                    type: 'column',
                    legendIndex: 1
                })
            };

            if(hasData(previousData.data.degreeDays.hdd.values)) {
                series.push({
                    data: _.take(previousData.data.degreeDays.hdd.values, 12),
                    name: moment(currentMonth).tz(report.building.timeZoneId).subtract(1, 'year').format('YYYY') + " HDD",
                    color: '#B4B5B5',
                    stack: 'previous',
                    type: 'column',
                    legendIndex: 2
                })
            };

            if(hasData(previousData.data.degreeDays.cdd.values)) {
                series.push({
                    data: _.take(previousData.data.degreeDays.cdd.values, 12),
                    name: moment(currentMonth).tz(report.building.timeZoneId).subtract(1, 'year').format('YYYY') + " CDD",
                    color: '#737778',
                    stack: 'previous',
                    type: 'column',
                    legendIndex: 3
                })
            };

            if(hasData(currentData.temperature)) {
                series.push({
                    data: _.take(_.map(currentData.temperature, _.round), 12),
                    color: '#737778',
                    yAxis: 1,
                    name: moment(currentMonth).tz(report.building.timeZoneId).format('YYYY') + " Temperature",
                    stack: 'temperature',
                    type: 'line',
                    legendIndex: 4
                })
            };

            let yAxis = [{
                title: {text: 'HDD-CDD'},
                tickAmount: 6
            },
            {
                title: {text: report.temperatureUnit.unit},
                opposite: true,
                tickAmount: 6
            }];

            return this.buildChart(series,  this.getXAxisLabels('monthly'), 300, true, yAxis)
        }

        this.getWaterBreakdownChart = function(values, temperatureValues, days) {
            let series = [];
            if(hasData(values[report.usageUnit.apiUnit.toLowerCase()].values)) {
                series.push({
                    data: $filter('arrayToUnit')(values[report.usageUnit.apiUnit.toLowerCase()].values, report.usageUnit),
                    name: report.usageUnit.unit,
                    color: '#008BF5',
                    type: 'column',
                    legendIndex: 0
                })
            };

            if(hasData(temperatureValues)) {
                series.push({
                    data:  $filter('arrayToTemperatureUnit')(temperatureValues, report.temperatureUnit),
                    color: '#53D844',
                    yAxis: 1,
                    name: moment(currentMonth).tz(report.building.timeZoneId).format('MMMM') + " Temperature",
                    type: 'line',
                    legendIndex: 1
                })
            };

            let yAxis = [{
                title: {text: report.usageUnit.unit},
                tickAmount: 6
            },
            {
                title: {text: report.temperatureUnit.unit},
                opposite: true,
                tickAmount: 6
            }];

            return this.buildChart(series,  this.getXAxisLabels('daily', values[report.usageUnit.apiUnit.toLowerCase()]), 300, false, yAxis)
        }

        this.getXAxisLabels = function(interval, data){
            if(interval == 'monthly') {
                return {labels: moment.monthsShort(), interval: 1};
            } else {
                let dates = [];
                //only display every 5 days
                let intervalNum = 5;
                data.timestamps.forEach(function(time) {
                    dates.push(moment(time).tz(report.building.timeZoneId).format('MMM D'));
                });
                return {labels: dates, interval: intervalNum};
            } ;
        }

        this.buildChart = function(series, xAxisLabels, height, stacked, yAxis) {
            return {
                chart: {
                    height: 225
                },
                lang: {
                    noData: "No data to display."
                },
                noData: {
                    position: { "x": 0, "y": 0, "align": "center", "verticalAlign": "middle" }
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    categories: xAxisLabels.labels,
                    labels: {
                        rotation: false
                    },
                    tickInterval:xAxisLabels.interval,
                    tickLength:0
                },
                yAxis,
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal',
                    itemStyle: {
                        fontSize: "11px"
                    }
                },
                plotOptions: {
                    column: {
                        stacking: stacked ? 'normal' : null
                    },
                    series: {
                        // turn off animations because of screenshot tool
                        animation: false
                    }
                },
                series,
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                }
            };
        }
    }

    function hasData(data) {
        return _.filter(data, null).length !== 0;
    }

    function mergeRealAndMissingDataSets(data, metric) {
        data[metric].mergedValues = [];
        if (data[metric].missingIntervalValues) {
            data[metric].mergedValues = [];
            for (let i = 0; i < data[metric].values.length; i++) {
                if (i >= data[metric].missingIntervalValues.length) {
                    break;
                }
                if (data[metric].missingIntervalValues[i] !== null) {
                    data[metric].mergedValues.push({
                        value: data[metric].missingIntervalValues[i],
                        isMissingData: true
                    });
                } else {
                    data[metric].mergedValues.push({
                        value: data[metric].values[i],
                        isMissingData: false
                    });
                }
            }
        } else {
            data[metric].mergedValues = data[metric].values.map((value) => {
                return {
                    value,
                    isMissingData: false
                }
            })
        }
        return data;
    }

    function setValue(value, color, unit) {
        return {
            y: $filter('toUnit')(value.value, unit),
            color: value.isMissingData ? transparentColor(color) : color
        };
    }

    function transparentColor(color) {
        const alpha = 0.5;
        const convertedAlpha = Math.floor(alpha * 255);
        const alphaString = convertedAlpha < 16 ? '0' + convertedAlpha.toString(16) : convertedAlpha.toString(16);
        return color + alphaString;
    }

    function getZones(data, timestamps) {
        const result = [];
        let currentColor = data[0].color;
        result.push({
            color: currentColor
        });
        for (let i = 1; i < data.length; i++) {
            if (i >= timestamps.length) {
                break;
            }
            if (data[i].color !== currentColor) {
                result.push({
                    value: i,
                    color: data[i].color
                });
                currentColor = data[i - 1].color
            }
        }
        result.push({
            value: data.length - 1,
            color: data[data.length - 1].color
        });
        return result;
    }

    return MonthlyCharts;

});
