namespace aq.energyInsights.service {

    export class EnergyInsightsDataService {

        /* @ngInject */
        constructor(
            private DataService,
            private $filter: ng.IFilterService,
            private DataStore: aq.common.DataStore,
            private Restangular: restangular.IService,
            private RestangularV3: restangular.IService,
            private $q) {
        }

        public getEnergy(building: aq.common.models.Building, startTime: moment.Moment, endTime: moment.Moment,
            metric, interval, account: aq.common.models.Account): ng.IPromise<DataValue[]> {
            return this.DataService.data(this.Restangular.one('accounts', account.id).one('buildings', building.id), interval, startTime, endTime, metric).then((data) => {
                return this.mapToDataValues(data, 'values', this.$filter<Function>('toUnit'), metric, true);
            });
        }

        public getWeatherNormalizedEnergy(building: aq.common.models.Building, startTime: moment.Moment,
            endTime: moment.Moment, metric, interval, account: aq.common.models.Account): ng.IPromise<DataValue[]> {
            return this.DataService.weatherNormalization(this.Restangular.one('accounts', account.id).one('buildings', building.id), interval, startTime, endTime, metric).then((data) => {
                return this.mapToDataValues(data, 'weatherNormalizedValues', this.$filter<Function>('toUnit'), metric, true);
            });
        }

        public getTargetConsumption(building: aq.common.models.Building, startTime: moment.Moment, endTime: moment.Moment, metric, interval, account: aq.common.models.Account): any {
            if (startTime.date() != 1) {
                startTime.add(1, interval.label).startOf(interval.label);
            }
            return this.DataStore.getList(this.Restangular.one('accounts', account.id).one('buildings', building.id).one('Targets'), 'queryTargets', {
                startDate: startTime.format(),
                endDate: endTime.format(),
                targetType: 'CONSUMPTION',
                measure: 'electricity'
            }).then((targets) => {
                let pointer = startTime.clone();
                const targetArr = [];
                while (pointer.valueOf() < endTime.valueOf()) {
                    const monthYear = pointer.format('MMM YYYY');
                    const targetItem = _.find(targets, function (target) {
                        if (target) {
                            return moment(target.startDate).format('MMM YYYY') == monthYear;
                        }
                    });
                    if (targetItem) {
                        targetArr.push(targetItem);
                    } else {
                        targetArr.push(null);
                    }
                    pointer = pointer.add(1, 'month');
                }
                return targetArr;
            });
        }

        public getTemperatures(building: aq.common.models.Building, startTime: moment.Moment, endTime: moment.Moment,
            temperatureUnit, interval, account: aq.common.models.Account): ng.IPromise<TempDataValue[]> {
            const noTemperatureData = { 'climateNormals': [], 'timestamps': [], 'values': [] };
            return this.DataService.temperature(this.Restangular.one('accounts', account.id).one('buildings', building.id), interval, startTime, endTime, temperatureUnit).then((data) => {
                return this.mapToTempDataValues(data, temperatureUnit);
            })
                .catch(() => {
                    return this.mapToTempDataValues(noTemperatureData, temperatureUnit);
                });
        }

        public getEnergyNotes(account: aq.common.models.Account, building: aq.common.models.Building): ng.IPromise<aq.common.models.EnergyNote[]> {
            const queryParams = {
                buildingId: building.id
            };
            return this.RestangularV3.all('notes').all('for-building').getList(queryParams)
                .then((notes) => {
                    return _(notes)
                        .filter((note) => note.type === 'OPTIMIZATION' && !note.parent && note.dataContext)
                        .map((note) => {
                            if (note.dataContext.docraptorUrl) {
                                note.dataContext.docraptorUrl = note.dataContext.docraptorUrl.replace(/https?:\/\//i, '');
                                // timePreset will overwrite the selected start/end time, so we remove it
                                note.dataContext.docraptorUrl = note.dataContext.docraptorUrl.replace(/(&timePreset=[\w%]+)/g, '');
                                note.dataContext.docraptorUrl = note.dataContext.docraptorUrl.substring(note.dataContext.docraptorUrl.indexOf('/'), note.dataContext.docraptorUrl.length);
                            }
                            return note;
                        })
                        .value();
                }).catch((error) => {
                    return [];
                });

        }

        public getCostBreakdown(building: aq.common.models.Building, startTime: moment.Moment, endTime: moment.Moment,
            interval: any, measure: string, account: aq.common.models.Account): ng.IPromise<any> {
            return this.Restangular.one('accounts', account.id).one('buildings', building.id).customGET('energyInsights/cost', {
                start: startTime.format(),
                end: endTime.format(),
                interval,
                measure
            })
                .then((data) => {
                    return data;
                })
                .catch(() => {
                    return 'No data for building';
                });
        }

        public getUtilitySpendingCostBreakdown(building: aq.common.models.Building, startTime: moment.Moment, endTime: moment.Moment,
            interval: any, measure: string): ng.IPromise<any> {
            const dataQueryParams = {
                start: startTime.format(`YYYY-MM-DDTHH:mm:ssZ`),
                end: endTime.format(`YYYY-MM-DDTHH:mm:ssZ`),
                interval,
                measure
            };

            const queryData = angular.extend(dataQueryParams, { buildingId: building.id });
            return this.RestangularV3
                .all('utility-spending')
                .customGET('', queryData)
                .then((data) => {
                    return data;
                })
                .catch(() => {
                    return 'No data for building';
                });
        }

        public queryBaseloadStats(building, params): ng.IPromise<MonthBaseloadStatsResult> {
            const theseParams = angular.copy(params);
            theseParams.start = moment(theseParams.start).format();
            theseParams.end = moment(theseParams.end).format();
            theseParams.interval = '1h';
            return building.one('energyinsights').customGET('BaseloadStats', theseParams);
        }

        public queryBaseload(building: aq.common.models.Building, startTime: moment.Moment, endTime: moment.Moment,
            interval: string, metric, account: aq.common.models.Account): ng.IPromise<DataValue[]> {
            return this.Restangular.one('accounts', account.id).one('buildings', building.id).customGET('energyInsights/BaseloadV2', {
                start: startTime.format(),
                end: endTime.format(),
                interval
            }).then((baseloadResponse) => {
                if (baseloadResponse) {
                    return this.mapToDataValues(baseloadResponse, 'values', this.$filter<Function>('toUnit'), metric, true);
                } else {
                    return [];
                }
            });
        }

        public getPeakData(building: aq.common.models.Building, start, end, metric, targets: aq.propertySettings.TargetItem[], account: aq.common.models.Account, interval): ng.IPromise<any> {
            const dataPromises = this.getPeakDataPromises(building, start, end, metric, account);
            return this.$q.all(dataPromises).then((result: DataServiceData[]) => {
                const processedData = this.combineMultipleDataServiceResultsByDate(result);
                let truncateTo;
                if (interval !== null && interval !== undefined) {
                    truncateTo = interval.label;
                } else {
                    const diff = end.unix() - start.unix();
                    if (diff > 15724800) {
                        truncateTo = 'month';
                    } else if (diff > 86400) {
                        truncateTo = 'day';
                    } else if (diff > 3600) {
                        truncateTo = 'hour';
                    }
                }
                const peaks = this.createPeaksArray(processedData, metric, building, targets, truncateTo);
                return peaks;
            });
        }

        public combineMultipleDataServiceResultsByDate(result: DataServiceData[]): DataServiceData {
            const sortedResult = result.sort((a: DataServiceData, b: DataServiceData) => {
                const aStart = moment(a.start);
                const bStart = moment(b.start);
                if (aStart.isAfter(bStart)) {
                    return 1;
                }
                if (bStart.isAfter(aStart)) {
                    return -1;
                }
                return 0;
            });
            const processedData: DataServiceData = {
                id: sortedResult[0].id,
                name: sortedResult[0].name,
                missing: [],
                timestamps: [],
                missingIntervalValues: [],
                values: [],
                partial: []
            };
            sortedResult.forEach((r) => {
                processedData.missing = processedData.missing.concat(r.missing);
                processedData.timestamps = processedData.timestamps.concat(r.timestamps);
                processedData.missingIntervalValues = processedData.missingIntervalValues.concat(r.missingIntervalValues);
                processedData.values = processedData.values.concat(r.values);
            });
            return processedData;
        }

        /**
         * Takes all data from DataService.data query and formats as an array of Peak[].
         * This Peak[] array is used for both the chart and the data table for CSV export
         * @param data all 15 minute intervals from start to end
         * @returns {Peak[]}
         */
        public createPeaksArray = (data: DataServiceData, metric, building, targets: aq.propertySettings.TargetItem[], truncateTo): Peak[] => {
            const peaks: Peak[] = [];
            let i = 0;
            let maxValue = data.values[i];
            let missingData = false;
            let maxPeakForInterval = this.getInterval(data.timestamps[i], building, truncateTo);
            for (i; i < data.timestamps.length; i++) {
                const thisInterval = this.getInterval(data.timestamps[i], building, truncateTo);
                if (maxPeakForInterval.truncated !== thisInterval.truncated) {
                    // record max for previous interval
                    const target = this.getTargetKw(maxPeakForInterval.truncated, targets, building);
                    peaks.push(new Peak(maxPeakForInterval,
                        this.getValueByMeasureAndUnit(maxValue, metric),
                        {
                            chartValue: target,
                            tableValue: target ? `${target}` : '-',
                            unit: metric.unit
                        },
                        missingData
                    ));
                    // start looking for peak of next interval
                    maxValue = data.values[i] !== null ? data.values[i] : data.missingIntervalValues[i];
                    missingData = data.values[i] === null ? true : false;
                    maxPeakForInterval = thisInterval;
                } else {
                    if (data.values[i] > maxValue || data.missingIntervalValues[i] > maxValue) {
                        // new peak found from this interval
                        maxValue = data.values[i] !== null ? data.values[i] : data.missingIntervalValues[i];
                        missingData = data.values[i] === null ? true : false;
                        maxPeakForInterval = thisInterval;
                    }
                }
            }
            const target = this.getTargetKw(maxPeakForInterval.truncated, targets, building);
            peaks.push(new Peak(maxPeakForInterval,
                this.getValueByMeasureAndUnit(maxValue, metric),
                {
                    tableValue: target ? `${target}` : '-',
                    chartValue: target,
                    unit: metric.unit
                },
                missingData
            ));
            return this.markMissingIntervals(peaks, data, truncateTo, building);
        }

        public getInterval = (timestamp: number, building, truncateTo): IntervalDate => {
            const truncatedMoment = moment.tz(timestamp, building.timeZoneId)
                .startOf(truncateTo);
            return {
                raw: timestamp,
                truncated: truncatedMoment.valueOf()
            };
        }

        public getValueByMeasureAndUnit = (value: number, metric): Metric => {
            const filter: any = this.$filter('toUnit');
            if (value == null || metric == null) {
                return { chartValue: null, tableValue: '', unit: '-' };
            } else {
                return {
                    chartValue: Math.round(filter(value, metric, false)),
                    tableValue: filter(value, metric, true), unit: metric.unit
                };
            }
        }

        public getTargetKw = (when: number, targets: aq.propertySettings.TargetItem[], building): number => {
            const targetKw = _.find(targets, (target) => {
                const startDate = moment.tz(target.startDate, building.timeZoneId);
                const endDate = moment.tz(target.endDate, building.timeZoneId);
                const whenDate = moment.tz(when, building.timeZoneId);
                return whenDate.isBetween(startDate, endDate) || whenDate.isSame(startDate) || whenDate.isSame(endDate);
            });
            if (targetKw) {
                return targetKw.value;
            } else {
                return null;
            }
        }

        public hasBudgetsForMeasure(building: aq.common.models.Building, targets, measureName: string) {
            const hasBudgetsForMeasure = _.some(targets, (budget) => {
                return budget.measure.name.toLowerCase() === measureName.toLowerCase();
            });
            return hasBudgetsForMeasure;
        }

        public getBudgetItems(building: aq.common.models.Building, measureName: string, startTime: moment.Moment, endTime: moment.Moment, interval, account: aq.common.models.Account) {
            if (startTime.date() != 1) {
                startTime.add(1, interval.label).startOf(interval.label);
            }
            return this.DataStore.getList(this.Restangular.one('accounts', account.id).one('buildings', building.id).one('Targets'), 'queryTargets', {
                startDate: startTime.format(),
                endDate: endTime.format(),
                targetType: 'BUDGET',
                measure: measureName
            }).then((targets) => {
                let pointer = startTime.clone();
                const targetArr = [];
                while (pointer.valueOf() < endTime.valueOf()) {
                    const monthYear = pointer.format('MMM YYYY');
                    const targetItem = _.find(targets, function (target) {
                        if (target) {
                            return moment(target.startDate).format('MMM YYYY') == monthYear;
                        }
                    });
                    if (targetItem) {
                        targetArr.push(targetItem);
                    } else {
                        targetArr.push(null);
                    }
                    pointer = pointer.add(1, 'month');
                }
                return targetArr;
            });
        }

        public mapToDataValues(data, valuesField, filter, unit, mapNulls): DataValue[] {
            const dataValues: DataValue[] = [];
            for (let i = 0; i < data[valuesField].length; i++) {
                if (data[valuesField][i] != null) {
                    dataValues.push({
                        timestamp: data.timestamps[i],
                        value: Math.round(filter(data[valuesField][i], unit)),
                        isMissingData: false
                    });
                } else if (data['missingIntervalValues'] && data['missingIntervalValues'][i] !== null) {
                    dataValues.push({
                        timestamp: data.timestamps[i],
                        value: Math.round(filter(data['missingIntervalValues'][i], unit)),
                        isMissingData: true
                    });
                } else if (mapNulls) {
                    // option to keep null values in data array if they have a timestamp
                    if (data['timestamps'][i] != null) {
                        dataValues.push({
                            timestamp: data.timestamps[i],
                            value: null,
                            isMissingData: true
                        });
                    }
                }
            }
            return dataValues;
        }

        public transparentColor(color: string): string {
            const alpha = 0.5;
            const convertedAlpha = Math.floor(alpha * 255);
            const alphaString = convertedAlpha < 16 ? '0' + convertedAlpha.toString(16) : convertedAlpha.toString(16);
            return color + alphaString;
        }

        // ------------------------

        public getPeakDataPromises(
            building: aq.common.models.Building,
            start,
            end,
            metric,
            account: aq.common.models.Account
        ): Promise<any>[] {
            const result = [];
            if (end.diff(start, 'month') > 0) {
                let monthCounter = _.cloneDeep(start);
                while (end.diff(monthCounter, 'month') !== 0) {
                    const intermittentStart = _.cloneDeep(monthCounter);
                    const intermittentEnd = _.cloneDeep(monthCounter).add(1, 'month');

                    result.push(this.DataService.data(this.Restangular.one('accounts', account.id).one('buildings', building.id), '15min', intermittentStart, intermittentEnd, metric));
                    monthCounter = monthCounter.add(1, 'month');
                }
                if (end.diff(monthCounter, 'day') !== 0) {
                    result.push(this.DataService.data(this.Restangular.one('accounts', account.id).one('buildings', building.id), '15min', monthCounter, end, metric))
                }
            } else {
                result.push(this.DataService.data(this.Restangular.one('accounts', account.id).one('buildings', building.id), '15min', start, end, metric));
            }
            return result;
        }

        private mapToTempDataValues(data, unit): TempDataValue[] {
            const filter = this.$filter<Function>('toTemperatureUnit');
            const dataValues: TempDataValue[] = [];
            for (let i = 0; i < data['values'].length; i++) {
                if (data['values'][i] != null) {
                    dataValues.push({
                        timestamp: data.timestamps[i],
                        value: Math.round(filter(data['values'][i], unit)),
                        climateNormal: Math.round(filter(data.climateNormals[i], unit)),
                        isMissingData: false
                    });
                } else if (data['missingIntervalValues'] && data['missingIntervalValues'][i] !== null) {
                    dataValues.push({
                        timestamp: data.timestamps[i],
                        value: Math.round(filter(data['missingIntervalValues'][i], unit)),
                        climateNormal: Math.round(filter(data.climateNormals[i], unit)),
                        isMissingData: true
                    });
                } else {
                    if (data['timestamps'][i] != null) {
                        dataValues.push({
                            timestamp: data.timestamps[i],
                            value: null,
                            climateNormal: data['climateNormals'][i] ? Math.round(filter(data.climateNormals[i], unit)) : null,
                            isMissingData: true
                        });
                    }
                }
            }
            return dataValues;
        }

        private markMissingIntervals(peaks: Peak[], data: DataServiceData, truncateTo: string, building): Peak[] {
            data.missingIntervalValues.forEach((d, i) => {
                const interval = this.getInterval(data.timestamps[i], building, truncateTo);
                const index = peaks.findIndex((p) => p.date.truncated === interval.truncated);
                if (d !== null && index !== -1) {
                    peaks[index].isMissingData = true;
                }
            });
            return peaks;
        }
    }

    angular.module('energyInsights').service('EnergyInsightsDataService', EnergyInsightsDataService);

    export interface CostResponse {
        timestamps: number[];
        accounts: {
            utilityCompany: string,
            accountNumber: string,
            charges: [{
                name: string,
                order: number,
                values: number[]
            }]
        }[];
    }

    export interface DataValue {
        timestamp: number;
        value: number;
        isMissingData?: boolean;
    }

    export interface TempDataValue extends DataValue {
        climateNormal: number;
    }

    export interface ConsumptionDataValue extends DataValue {
        status: string;
        percent: number;
        drilldown: boolean;
    }

    // Single queryable Data API Response
    export class DataServiceData {
        values: number[];
        missingIntervalValues: number[];
        timestamps: number[];
        missing: number[];
        partial: number[];
        id: number;
        name: string;
        start?: string;
        end?: string;
    }
}
