angular.module('meterManagement').controller('MeterDataCtrl',
    function($scope, $location, $state, $q, DataService, Messages, $filter, loading,
        allUnits, collectorsDetail, recipients) {


    // used to show building name, collector name, and measures on report
    $scope.collectors = collectorsDetail[0];

    $scope.recipients = recipients;

    // union drillIn for each collector for a view of what measures are supported
    // candidate for speedup
    var drillInMetrics = [];
    _.each($scope.collectors, function(collector) {
        if (collector && collector.measures) {
            drillInMetrics = _.union(collector.measures, drillInMetrics);
        }
    });
    var measures = [];
    if (_.includes(drillInMetrics, 'ELECTRICITY')) measures.push('POWER', 'ENERGY');
    if (_.includes(drillInMetrics, 'WATER')) measures.push('WATER', 'FLOW_RATE');
    if (_.includes(drillInMetrics, 'GAS')) measures.push('GAS');
    if (_.includes(drillInMetrics, 'STEAM')) measures.push('STEAM_MASS');
    if (_.includes(drillInMetrics, 'HEAT')) measures.push('HEAT_TRANSFER', 'HEAT_TRANSFER_RATE');
    measures.push('TEMPERATURE', 'PULSE');
    if ($scope.user.userType == 'ADMINISTRATOR') {
        measures.push('INTERPOLATED');
    }

    var index = 1;
    var units = [];
    _.each(allUnits, function(unit) {
        if (measures.indexOf(unit.apiUnit) != -1) {
            unit.id = index++;
            units.push(unit);
        }
    });

    $scope.metric = [];
    $scope.metric = [_.find(allUnits, {unit: 'kW'})];

    $scope.allUnits = units;

    // input is raw value and metric
    // returns converted value and unit type
    function getValueByMeasureAndUnit(value, metric) {
        if (value == null) {
            return { value: "", unit: "-"};
        }
        if (metric.apiUnit == 'TEMPERATURE') {
            return { value: $filter('toTemperatureUnit')(value, metric, '0,0'), unit: metric.unit};
        } else if (metric.apiUnit == 'PULSE') {
            return { value: value, unit: metric.unit};
        } else {
                return { value: $filter('toUnit')(value, metric, true), unit: metric.unit};
        }
    }

    $scope.updatePeriod = function(start, end, interval) {
        if (!$scope.selection) $scope.selection = {};
        $scope.selection.from = start;
        $scope.selection.to = end;
        $scope.selection.interval = interval;
        $scope.queryData();
    };

    $scope.updateInterval = function(interval) {
        $scope.selection.interval = interval.value;
        $scope.queryData();
    };

    $scope.queryData = function() {
        loading.start();
        $scope.limit = 50;
        $scope.querySuccess = false;

        var params = {name: $location.search().name, building: $location.search().building};
        var distinctApiUnits = _.uniq(_.map($scope.metric, 'apiUnit'));

        return DataService.data(collectorsDetail, $scope.selection.interval,
            $scope.selection.from, $scope.selection.to, distinctApiUnits, params).then(function(data) {

            // if single apiUnit is queried, the result is an array
            var multiApiUnits = distinctApiUnits.length > 1;

            // depends on multi api units
            var dataWithMeasures = multiApiUnits
                ? data[$scope.metric[0].apiUnit.toLowerCase()]
                : data;

            var emptyData = multiApiUnits
                ? Object.keys(dataWithMeasures).length == 0
                : dataWithMeasures.length == 0;

            if (emptyData) {
                $scope.rows = [];
                Messages.info('No data found');
                loading.stop();
                return;
            }

            // re-index by id
            if (multiApiUnits) {
                _.each(distinctApiUnits, function(apiUnit) {
                    var dataCopy = [];
                    _.each(data[apiUnit.toLowerCase()], function(collector) {
                        dataCopy[collector.id] = collector;
                    });
                    data[apiUnit.toLowerCase()] = dataCopy;
                })
            }
            // depends on multi api units
            var firstDataObject = multiApiUnits
                ? dataWithMeasures[Object.keys(dataWithMeasures)[0]]
                : data[0];

            var rows = [];
            var columns = [{name: 'Timestamp'}, {name: 'Name'}, {name: 'Building'}, {name: 'Measure'}];
            // add column for each selected unit
            _.each($scope.metric, function(metric) {
                columns.push({name: metric.label});
            });

            // can operate off of the first data timestamp reference
            _.each(firstDataObject.timestamps, function(timestamp, timeIndex) {
                _.each(dataWithMeasures, function(dataWithMeasure) {
                    var row: any = {};
                    row.timestamp = timestamp;
                    row.collectorId = dataWithMeasure.id;
                    row.metrics = [];

                    _.each($scope.metric, function(metric) {
                        // depends on multi api units.
                        var metricReading = multiApiUnits
                            ? data[metric.apiUnit.toLowerCase()][dataWithMeasure.id]
                            : dataWithMeasure;

                        row.metrics.push(getValueByMeasureAndUnit(metricReading.values[timeIndex], metric));
                    });

                    rows.push(row);
                });
            });

            $scope.columns = columns;
            $scope.rows = rows;
            $scope.querySuccess = true;
            loading.stop();
        }, function(data) {
            if (data.status == 404) {
                Messages.error('Data Service response not available.');
            } else {
                Messages.error('Unknown Data Service query error.');
            }
            $scope.querySuccess = false;
            loading.stop();
        })
    };

    // run when the user clicks "APPLY FILTER" from dynamic filters.  also run on startup.
    $scope.dynamicFilterApply = function() {
        // in a vie between dynamic-filter and period-selector, the $scope.selection is not ready
        if (typeof $scope.selection != 'undefined') {
            // only queryData when the $scope.selection is available
            return $scope.queryData();
        } else {
            // return empty to the dynamicFilter when $scope.selection is not created
            return $q.when();
        }
    };

    $scope.searchCollectors = function(params) {
        return $scope.account.customGET('queryAllCollectorsSearch', params.params);
    };

    // This url used both for CSV download and for scheduling reports
    $scope.csvUrl = function() {
        var dateFormat = $scope.user.timeFormat === "TWENTYFOUR"
            ? "MMM dd, Y HH:mm:ss"
            : "MMM dd, Y h:mm:ss a";

        var csvDownloadUrl = URI("/api/v1/accounts/" + $scope.account.id + "/meterDataCollectors/csv")
            .search({
                metrics: _.map($scope.metric, 'value'),
                interval: $scope.selection.interval,
                dateFormat: dateFormat,
                start: moment($scope.selection.from).tz($scope.user.timeZoneId).format(),
                end: moment($scope.selection.to).tz($scope.user.timeZoneId).format(),
                name: $location.search().name,
                building: $location.search().building
            })
            .toString();
        return csvDownloadUrl;
    };

    $scope.download = function() {
        $scope.sendFile($scope.csvUrl());
    };

    $scope.sendFile = function(url) {
        location.href = url;
    };

    $scope.increaseLimit = function(amount) {
        $scope.limit += amount;
    };

});
