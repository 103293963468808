namespace aq.dashboard.widgets {
    export class BuildingEngagementWidgetCtrl {
        private groups: {
            type: number;
            name: string;
            id: number;
            buildings: number[];
        }[];
        private groupTypes: {
            id: number;
            name: string;
        }[];
        private data: {
            id: number;
            userId: number;
            userFullName: string;
            userType: string;
            buildingId: number;
            buildingName: string;
            imageUrl: string;
            groupId: number;
            groupName: string;
            time: number;
            noteCount: number;
        }[];
        private viewData: any[];
        private currentLevelNumber: number;
        private currentLevel: any;
        private currentFilters: { name: string; value: string; }[];
        private drillLevels: any[];
        private sortAsc: boolean;
        private sortProperty: string;
        /* @ngInject */
        constructor(
            private $scope,
            private buildings: aq.common.models.Building[],
            private buildingGroups: any[],
            private users: aq.common.models.User[],
            private Messages: aq.services.Messages,
            private RestangularV3: restangular.IService,
            private Restangular: restangular.IService,
            private config,
            private MockDataService: MockDataService,
            private DrillDataService: DrillDataService,
            private $translate
        ) {
            this.drillLevels = [
                { idProperty: 'groupId', nameProperty: 'groupName', label: 'Building Group' },
                { idProperty: 'buildingId', nameProperty: 'buildingName', label: 'Building' },
                {
                    idProperty: 'userId',
                    nameProperty: 'userFullName',
                    label: 'User',
                    include: [
                        { name: 'userType', label: 'Role', action: (item) => { this.setFilter('userType', item.userType); } },
                        { name: 'time', label: 'Session Time' },
                        { name: 'noteCount', label: 'Notes Taken' },
                        { name: 'certifications', label: 'Certifications' }
                    ]
                }
            ];

            this.generateData();

            this.$scope.showBuildings = true;
            this.$scope.buildings = buildings;
            this.$scope.users = users;
            this.$scope.showUsers = () => {
                this.$scope.showBuildings = false;

            };
            this.$scope.backToBuildings = () => {
                this.$scope.showBuildings = true;
            };

            this.currentFilters = [];
            this.currentLevelNumber = 0;
            this.processViewData();
            this.sortData('score');

            this.$scope.$on('dataSelectedBroadcast', ($event, message) => {
                if (!message) {
                    return;
                }
                if (message.type == 'CLEAR') {
                    this.clearFilter();
                    return;
                }
                if (message.type == 'building') {
                    this.currentLevelNumber = 2;
                    this.currentFilters = [
                        { name: 'buildingId', value: message.data.id }
                    ];
                    this.processViewData();
                    this.sortData(null);
                } else if (message.type == 'buildingGroup') {
                    this.currentLevelNumber = 1;
                    this.currentFilters = [
                        { name: 'groupId', value: message.data.id }
                    ];
                    this.processViewData();
                    this.sortData(null);
                }
            });
        }
        public generateData() {
            this.data = this.MockDataService.getUserEngagementData();
        }

        public processViewData(config = null) {
            this.currentLevel = this.drillLevels[this.currentLevelNumber];
            this.viewData = [];
            const filteredData = this.currentFilters.length > 0
                ? _.filter(this.data, (dataItem) => _.every(this.currentFilters, (filter) => dataItem[filter.name] == filter.value))
                : this.data;

            this.viewData = this.DrillDataService.processUserEngagementData(filteredData, this.currentLevel.idProperty, this.currentLevel.nameProperty);
        }
        public sortData(property) {
            if (property) {
                if (property == this.sortProperty) {
                    this.sortAsc = !this.sortAsc;
                } else {
                    this.sortAsc = true;
                }
                this.sortProperty = property;
            }
            const order = this.sortAsc ? 'asc' : 'desc';
            this.viewData = _.orderBy(this.viewData, [this.sortProperty], [order]);
        }
        public getBuildingGroupName(groupId) {
            const buildingGroup = _.find(this.buildingGroups, (group) => group.id == groupId);
            return buildingGroup ? buildingGroup.name : 'Other';
        }
        public setFilter(name, value) {
            this.currentFilters = [{ name, value }];
            this.processViewData();
            this.sortData(null);
        }
        public drillDown(item) {
            const nextLevel = this.getNextLevel();
            if (nextLevel < 0) {
                return;
            }
            this.currentLevelNumber = nextLevel;
            this.currentLevel = this.drillLevels[this.currentLevelNumber];
            this.currentFilters.push({
                name: item.idProperty,
                value: item.id
            });
            this.processViewData();
            this.sortData(null);
        }
        public getNextLevel() {
            if (this.currentLevelNumber + 1 < this.drillLevels.length) {
                return this.currentLevelNumber + 1;
            }
            return -1;
        }
        public getFilterDisplay(filter) {
            const data = _.find(this.data, (item) => item[filter.name] == filter.value);
            const drill = _.find(this.drillLevels, (item) => item.idProperty == filter.name);
            if (data && drill) {
                return `${drill.label}: ${data[drill.nameProperty]}`;
            }
            // tmp hack
            if (filter.name == 'userType') {
                return `${this.$translate.instant('dashboard.common.widgets.Role')} ${filter.value}`;
            }
            return `${filter.name}: ${filter.value}`;
        }
        public clearFilter() {
            this.currentFilters = [];
            this.currentLevelNumber = 0;
            this.processViewData();
            this.sortData(null);
        }
        public filterUpToMe(filter) {
            if (filter.name == 'userType') {
                return;
            }
            const newFilters = [];
            let cnt = 0;
            let currentFilter = null;
            do {
                currentFilter = this.currentFilters[cnt];
                newFilters.push(currentFilter);
                cnt++;
            }
            while (currentFilter && currentFilter.name != filter.name);
            this.currentFilters = newFilters;
            this.currentLevelNumber = cnt;
            this.processViewData();
            this.sortData(null);
        }
    }
    angular.module('aq.dashboard.widgets').controller('BuildingEngagementWidgetCtrl', BuildingEngagementWidgetCtrl);
}
