var aq;
(function (aq) {
    var energyInsights;
    (function (energyInsights) {
        var ConsumptionService = /** @class */ (function (_super) {
            __extends(ConsumptionService, _super);
            /* @ngInject */
            function ConsumptionService(EnergyInsightsDataService, $q, $filter, Restangular, $translate) {
                var _this = _super.call(this) || this;
                _this.EnergyInsightsDataService = EnergyInsightsDataService;
                _this.$q = $q;
                _this.$filter = $filter;
                _this.Restangular = Restangular;
                _this.$translate = $translate;
                return _this;
            }
            ConsumptionService.prototype.getSimpleTargetModel = function (building, measure, periodSearch) {
                var targetModel = new energyInsights.SimpleTargetModel();
                targetModel.title = this.$translate.instant('insight.Consumption (Normalized)');
                targetModel.when = this.getWhen(periodSearch);
                return targetModel;
            };
            ConsumptionService.prototype.getKwHMetric = function (account) {
                return this.Restangular.one('accounts', account.id).customGET('queryRealUnits').then(function (allUnits) {
                    return _.find(allUnits, { unit: 'kWh' });
                });
            };
            ConsumptionService.prototype.getTargetModel = function (building, measure, periodSearch, account, name) {
                var _this = this;
                return this.getTableModel(building, measure, periodSearch, account, name).then(function (tableModel) {
                    return _this.calculateTargetModel(building, tableModel, periodSearch, name);
                });
            };
            ConsumptionService.prototype.getGraphModel = function (building, measure, periodSearch, account) {
                var _this = this;
                return this.getKwHMetric(account)
                    .then(function (metric) {
                    return _this.getTableModel(building, measure, periodSearch, account, name).then(function (tableData) {
                        return {
                            graph: _this.buildChartConfig(tableData, metric, periodSearch, building)
                        };
                    });
                });
            };
            ConsumptionService.prototype.getTableModel = function (building, measure, periodSearch, account, name) {
                var _this = this;
                return this.getKwHMetric(account)
                    .then(function (metric) {
                    return _this.$q.all([
                        _this.EnergyInsightsDataService
                            .getEnergy(building, periodSearch.start, periodSearch.end, metric, periodSearch.interval.value, account),
                        _this.EnergyInsightsDataService
                            .getTargetConsumption(building, periodSearch.start, periodSearch.end, metric, periodSearch.interval, account)
                    ]).then(function (results) {
                        var energy = results[0];
                        var targetConsumption = results[1];
                        return _this.transformTableData(energy, targetConsumption, periodSearch, building);
                    });
                });
            };
            // just to please contract
            ConsumptionService.prototype.formatTableModel = function () {
                throw new TypeError('This is a stub method');
            };
            ConsumptionService.prototype.transformTableData = function (actualsData, targets, periodSearch, building) {
                var tableModel = new energyInsights.TableModel();
                var rows = [];
                var dateFormat = periodSearch.interval.value === '1mon' ? 'MMM YYYY' : 'dd MMM Do';
                for (var i = 0; i < actualsData.length; i++) {
                    var value = actualsData[i].value;
                    var target = targets[i] ? targets[i].value : null;
                    var percent_1 = this.getPercent(value, target);
                    rows.push({
                        timestamp: actualsData[i].timestamp,
                        formattedDate: moment(actualsData[i].timestamp).tz(building.timeZoneId).format(dateFormat),
                        value: value,
                        target: target,
                        status: this.getStatus(value, target, percent_1, 100),
                        percent: this.getPercent(value, target),
                        isMissingData: actualsData[i].isMissingData ? true : false
                    });
                }
                tableModel.rows = rows;
                this.buildTableTotal(tableModel);
                return tableModel;
            };
            ConsumptionService.prototype.calculateTargetModel = function (building, tableModel, periodSearch, name) {
                var today = moment().tz(building.timeZoneId).format('MMM Do');
                var numberOfDaysInPeriodSoFar = moment().tz(building.timeZoneId).diff(periodSearch.start, 'days');
                var numberOfDaysInPeriod = periodSearch.end.diff(periodSearch.start, 'days');
                var percOfTime = numberOfDaysInPeriodSoFar < numberOfDaysInPeriod
                    ? this.getPercent(numberOfDaysInPeriodSoFar, numberOfDaysInPeriod) : 100;
                var targetModel = new energyInsights.TargetModel();
                targetModel.question = this.$translate.instant('insight.question.Consumption') + " " + building.name + "?";
                targetModel.title = name;
                var noTargetRows = _.filter(tableModel.rows, function (row) {
                    return row.target === null;
                });
                if (noTargetRows.length === tableModel.rows.length) {
                    targetModel.showTarget = false;
                    targetModel.showDonut = false;
                    targetModel.target = null;
                }
                else {
                    targetModel.showTarget = true;
                    targetModel.showDonut = true;
                    targetModel.target = this.getTarget(tableModel, building, periodSearch);
                }
                targetModel.formattedTarget = this.$translate.instant('insight.target.Target') + " " + this.$filter('number')(targetModel.target, '0,0') + " kWh";
                targetModel.answer = this.getAnswer(tableModel);
                var percent = this.getPercent(targetModel.answer, targetModel.target);
                targetModel.formattedAnswer = this.$filter('number')(targetModel.answer, '0,0') + " kWh";
                targetModel.percentage = this.getPercent(targetModel.answer, targetModel.target);
                targetModel.color = this.getColor(targetModel.answer, targetModel.target, targetModel.percentage, percOfTime);
                targetModel.iconColor = targetModel.color;
                targetModel.tooltip = this.getTooltip(targetModel, today, percOfTime);
                targetModel.when = this.getWhen(periodSearch);
                targetModel.icon = this.getIcon(targetModel.answer, targetModel.target, percent, percOfTime);
                targetModel.timeElapsedPercentage = percOfTime;
                targetModel.timeElapsedLabel = today;
                targetModel.buildingId = building.id;
                return this.$q.when(targetModel);
            };
            ConsumptionService.prototype.getTooltip = function (targetModel, today, percOfTime) {
                if (targetModel.showTarget) {
                    if (percOfTime == 100) {
                        return this.$translate.instant('insight.when_tooltip.Actual Consumption Was') + " <span style=\"color:" + targetModel.color + "\">" + targetModel.percentage + "%" +
                            ("</span> " + this.$translate.instant('insight.when_tooltip.Of Target'));
                    }
                    else {
                        return this.$translate.instant('insight.when_tooltip.Actual') + " <span style=\"color:" + targetModel.color + "\">" + targetModel.percentage + "%" +
                            ("</span> " + this.$translate.instant('insight.when_tooltip.As Of') + " " + today + ".");
                    }
                }
            };
            ConsumptionService.prototype.getWhen = function (periodSearch) {
                if (periodSearch.label && (periodSearch.label.indexOf('Trailing') >= 0)) {
                    return this.$translate.instant('insight.when.Consumption') + " " + periodSearch.label;
                }
                else {
                    return this.$translate.instant("timeperiod." + periodSearch.label);
                }
            };
            ConsumptionService.prototype.getAnswer = function (data) {
                return _.sum(_.map(data.rows, function (row) { return row.value; }));
            };
            ConsumptionService.prototype.getTarget = function (data, building, periodSearch) {
                // sum only targets that are for previous or current interval period (no future targets)
                var currentDate = moment().tz(building.timeZoneId);
                return _.sum(_.map(data.rows, function (row) {
                    var isFuture = moment(row.timestamp).tz(building.timeZoneId).startOf(periodSearch.interval.label);
                    if (currentDate.diff(isFuture) > 0) {
                        return row.target;
                    }
                    else
                        return row.target;
                }));
            };
            ConsumptionService.prototype.buildChartConfig = function (tableModel, metric, periodSearch, building) {
                return {
                    lang: {
                        drillUpText: '< Back to previous selection',
                        noData: 'No consumption data to display.'
                    },
                    noData: {
                        position: { x: 0, y: 0, align: 'center', verticalAlign: 'middle' }
                    },
                    plotOptions: {
                        series: {
                            stacking: 'normal'
                        },
                        column: {
                            grouping: false,
                            shadow: false,
                            borderWidth: 0
                        }
                    },
                    chart: {
                        plotBorderWidth: 1
                    },
                    drilldown: {
                        activeAxisLabelStyle: {
                            textDecoration: 'none',
                            fontWeight: 'normal',
                            color: '#666666'
                        }
                    },
                    title: '',
                    exporting: {
                        buttons: {
                            contextButton: {
                                enabled: false
                            }
                        }
                    },
                    tooltip: {
                        useHTML: true,
                        formatter: function () {
                            var tooltip = '';
                            var dateFormat = this.points[0].point.drilldown ? 'MMMM YYYY' : 'MMMM DD, YYYY';
                            tooltip += moment(this.points[0].point.timestamp).tz(building.timeZoneId).format(dateFormat) + "<br>";
                            var actualTooltip = '';
                            var targetTooltip = '';
                            var missingTooltip = '';
                            var drillInTooltip = '';
                            this.points.forEach(function (point) {
                                if (point.series.name == energyInsights.ConsumptionCtrl.CURRENT_YEAR_NORMALIZED_SERIES_NAME) {
                                    targetTooltip += "<br><span style=\"color:" + point.color + ";\" class=\"insight-tooltip-format\">" +
                                        ("\u2022 </span>" + point.series.name + ": <span class=\"insight-tooltip-format\">" + point.point.tooltip + "</span>");
                                    if (point.point.percent) {
                                        targetTooltip += "<span class='charge-percent'>(" + point.point.percent + "%)</span>";
                                    }
                                }
                                else {
                                    actualTooltip += "<span style=\"color:" + point.color + ";\" class=\"insight-tooltip-format\">" +
                                        ("\u2022 </span>" + point.series.name + ": <span class=\"insight-tooltip-format\">" + point.point.tooltip + "</span>");
                                }
                                missingTooltip += point.point.isMissingData ? '<br><span style="color:red">This building uses data directly <br>from your utility company.</span><br><i>Some data has not been received yet.' : '';
                                drillInTooltip = point.point.drilldown ? '<br><i>Drill in for more information.' : '';
                            });
                            tooltip = tooltip + actualTooltip + targetTooltip + missingTooltip + drillInTooltip;
                            return tooltip;
                        },
                        shared: true
                    },
                    xAxis: {
                        type: 'category',
                        tickLength: 0,
                        labels: {
                            formatter: function () {
                                return this.value;
                            }
                        }
                    },
                    yAxis: [{
                            min: 0,
                            title: {
                                text: "Energy Consumption (" + metric.unit + ")"
                            }
                        }],
                    series: this.getChartSeries(tableModel.rows, metric, periodSearch, building)
                };
            };
            ConsumptionService.prototype.getChartSeries = function (tableRows, metric, periodSearch, building) {
                var noTargetRows = _.filter(tableRows, function (row) {
                    return row.target === null;
                });
                if (noTargetRows.length === tableRows.length) {
                    return [this.buildCurrentYearValueSeries(tableRows, metric, periodSearch, building)];
                }
                else {
                    var result = [];
                    if (periodSearch.interval.label !== 'Day') {
                        result.push(this.buildCurrentYearTargetSeries(tableRows, metric, periodSearch, building));
                    }
                    result.push(this.buildCurrentYearValueSeries(tableRows, metric, periodSearch, building));
                    return result;
                }
            };
            ConsumptionService.prototype.formatTimestampForChart = function (timestamp, periodSearchIntervalValue, buildingTimeZoneId) {
                var date = moment(timestamp).tz(buildingTimeZoneId);
                if (periodSearchIntervalValue === '1d') {
                    return date.format('MMM DD');
                }
                else {
                    return date.format('MMM');
                }
            };
            ConsumptionService.prototype.convertToDataPoints = function (dataValues, metric, valueField, periodSearch, building, color) {
                var _this = this;
                var drilldown = periodSearch.interval.value === '1mon';
                return dataValues.map(function (energyValue) {
                    var name = _this.formatTimestampForChart(energyValue.timestamp, periodSearch.interval.value, building.timeZoneId);
                    return {
                        y: energyValue[valueField],
                        timestamp: energyValue.timestamp,
                        name: name,
                        color: energyValue.isMissingData ? _this.EnergyInsightsDataService.transparentColor(color) : color,
                        tooltip: _this.$filter('number')(energyValue[valueField], '0,0') + " " + metric.unit,
                        percent: energyValue.percent,
                        drilldown: drilldown,
                        isMissingData: valueField === 'target' ? false : energyValue.isMissingData
                    };
                });
            };
            ConsumptionService.prototype.buildTableTotal = function (tableData) {
                var totals = {
                    actualSummary: 0,
                    targetSummary: 0,
                    percent: null,
                    status: null
                };
                for (var _i = 0, _a = tableData.rows; _i < _a.length; _i++) {
                    var tableDataRow = _a[_i];
                    totals.actualSummary += tableDataRow ? tableDataRow.value : 0;
                    totals.targetSummary += tableDataRow ? tableDataRow.target : 0;
                }
                totals.percent = this.getPercent(totals.actualSummary, totals.targetSummary);
                tableData.stats = totals;
            };
            ConsumptionService.prototype.buildCurrentYearValueSeries = function (tableRows, metric, periodSearch, building) {
                return {
                    id: 'currentYearEnergy',
                    color: '#7ACE46',
                    name: energyInsights.ConsumptionCtrl.CURRENT_YEAR_ENERGY_SERIES_NAME,
                    type: 'column',
                    stack: 'actuals',
                    pointPadding: 0.3,
                    data: this.convertToDataPoints(tableRows, metric, 'value', periodSearch, building, '#7ACE46')
                };
            };
            ConsumptionService.prototype.buildCurrentYearTargetSeries = function (tableRows, metric, periodSearch, building) {
                return {
                    id: 'currentYearNormalized',
                    name: energyInsights.ConsumptionCtrl.CURRENT_YEAR_NORMALIZED_SERIES_NAME,
                    color: '#c8c8c8',
                    type: 'column',
                    stack: 'targets',
                    pointPadding: 0.1,
                    data: this.convertToDataPoints(tableRows, metric, 'target', periodSearch, building, '#c8c8c8')
                };
            };
            return ConsumptionService;
        }(energyInsights.InsightService));
        energyInsights.ConsumptionService = ConsumptionService;
        angular.module('energyInsights').service('ConsumptionService', ConsumptionService);
    })(energyInsights = aq.energyInsights || (aq.energyInsights = {}));
})(aq || (aq = {}));
