import UtilityAccount = aq.utilityBudgets.UtilityAccount;
import TariffSchedule = aq.admin.configuration.utilityCompany.TariffSchedule;

angular
    .module('aq.utilityBudgets')
    .config(($stateProvider: ng.ui.IStateProvider) => {
        $stateProvider
            .state('aq.utilityBudgets.utilityaccounts', {
                url: '/utility-accounts',
                templateUrl: 'app/utilityBudgets/utilityAccounts/utilityAccountsList.html',
                controller: 'UtilityAccountsListCtrl as vm',
                data: {
                    hide: false,
                    breadcrumb: 'Utility Accounts',
                    icon: '.aq-icons-utilities'
                },
                params: {
                    timeStamp: null
                },
                resolve: {
                    authAccess(Auth, waitForAuthToken) {
                        return Auth.access;
                    },
                    utilityCompanies: (RestangularV3: restangular.IService, DataStore: aq.common.DataStore, authAccess) => {
                        return DataStore.getList(
                            RestangularV3.all('utility-companies'));
                    },
                    utilityAccounts: (
                        RestangularV3: restangular.IService,
                        $stateParams: ng.ui.IStateParamsService,
                        DataStore: aq.common.DataStore,
                        authAccess
                    ) => {
                        return DataStore.getList(
                            RestangularV3.one(''),
                            'utility-accounts',
                            { buildingId: $stateParams.buildingId }
                        );
                    },
                    mapUtilityAccountBuildingIds: (
                        utilityAccounts,
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        $q: ng.IQService
                    ) => {
                        const result = {};
                        const sharedUtilityAccounts = _.filter(utilityAccounts, (ua: UtilityAccount) => ua.shared);
                        const requests = _.map(sharedUtilityAccounts, (ua: UtilityAccount) => {
                            return RestangularV3
                                .one('utility-accounts', ua.id)
                                .customGET('buildings')
                                .then((buildingIds) => {
                                    return {
                                        id: ua.id,
                                        buildingIds
                                    };
                                });
                        })
                        return $q.all(requests).then((results) => {
                            _.each(results, r => {
                                result[r.id] = r.buildingIds;
                            });
                            return result;
                        })
                    },
                    buildingId: ($stateParams: ng.ui.IStateParamsService) => {
                        return $stateParams.buildingId;
                    },
                    buildings: (RestangularV3: restangular.IService, waitForAuthToken) => {
                        return RestangularV3.all('buildings').getList();
                    },
                    users(
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        account: aq.common.models.Account,
                        waitForAuthToken
                    ) {
                        return DataStore.getList(RestangularV3.one(''), 'users', { accountId: account.id });
                    },
                    tariffSchedules(
                        RestangularV3: restangular.IService,
                        utilityAccounts: aq.utilityBudgets.UtilityAccount[],
                        waitForAuthToken
                    ): ng.IPromise<Record<number, TariffSchedule>> {
                        const utilityCompanyId = utilityAccounts.filter((ua) => ua.utilityCompany).map((ua) => String(ua.utilityCompany));
                        return RestangularV3.all('energy-tariff-schedules/by-utility-company').customGET('', { utilityCompanyId });
                    },
                    utilityServices: (
                        RestangularV3: restangular.IService,
                        buildingId: number,
                        waitForAuthToken
                    ): ng.IPromise<Record<number, UtilityAccount>> => {
                        return RestangularV3.all('utility-services').all('by-utility-account').customGET('', { buildingId });
                    }
                },
                onEnter: (Segment: aq.services.SegmentService) => {
                    Segment.trackEvent('Budgets:Utility Accounts');
                }
            })
            .state('aq.utilityBudgets.utilityaccounts.utilityAccountServiceBills', {
                url: '/with-bills/:utilityAccountId/service-bills/:serviceId',
                views: {
                    'utility-bills': {
                        templateUrl: 'app/utilityBudgets/utilityAccounts/utilityAccountListServiceBills.html',
                        controller: 'UtilityAccountListServiceBills as vm',
                        resolve: {
                            utilityAccountId: ($stateParams: ng.ui.IStateParamsService) => {
                                return $stateParams['utilityAccountId'];
                            },
                            utilityAccount: (
                                RestangularV3: restangular.IService,
                                utilityAccountId: number,
                                DataStore: aq.common.DataStore,
                                utilityAccounts: aq.utilityBudgets.UtilityAccount[],
                                waitForAuthToken
                            ) => {
                                const isValid = _.some(utilityAccounts, (ua) => ua.id == utilityAccountId);
                                if (!isValid) {
                                    return null;
                                }
                                return DataStore.get(RestangularV3.one('utility-accounts', utilityAccountId));
                            },
                            tariffSchedules: (
                                DataStore: aq.common.DataStore,
                                RestangularV3: restangular.IService,
                                utilityAccount: aq.utilityBudgets.UtilityAccount,
                                waitForAuthToken
                            ) => {
                                if (utilityAccount.utilityCompany) {
                                    const utilityCompanyId = String(utilityAccount.utilityCompany);
                                    return DataStore.getList(
                                        RestangularV3.one(''), 'energy-tariff-schedules', { utilityCompanyId });
                                }
                                return [];
                            },
                            readOnlyForm: (Auth, waitForAuthToken) => {
                                return !Auth.check({ appName: 'Budgets', access: 'EDIT' });
                            },
                            utilityAccountBuildingIds: (
                                RestangularV3: restangular.IService,
                                utilityAccountId: number,
                                waitForAuthToken
                            ) => {
                                return RestangularV3.one('utility-accounts', utilityAccountId).customGET('buildings');
                            },
                            utilityService: (
                                RestangularV3: restangular.IService,
                                $stateParams: ng.ui.IStateParamsService,
                                DataStore: aq.common.DataStore,
                                utilityServices: aq.common.models.UtilityService[],
                                waitForAuthToken
                            ) => {
                                const utilityAccountId = parseInt($stateParams.utilityAccountId);
                                const serviceId = $stateParams.serviceId;
                                const isValid = _.some(utilityServices[utilityAccountId], (us) => us.id == serviceId);
                                if (!isValid) {
                                    return null;
                                }
                                return DataStore.get(RestangularV3.one('utility-services', serviceId), {});
                            },
                            collectors: (
                                Restangular: restangular.IService,
                                accountId: number,
                                $stateParams: ng.ui.IStateParamsService,
                                waitForAuthToken
                            ) => {
                                const params = { utilityMetersOnly: true };
                                return Restangular.one('accounts', accountId)
                                    .one('buildings', $stateParams.buildingId)
                                    .customGET('queryAllCollectors', params);
                            },
                            utilityServiceBillPeriods: (
                                RestangularV3: restangular.IService,
                                utilityService: aq.common.models.UtilityService,
                                DataStore: aq.common.DataStore
                            ) => {
                                if (!utilityService) {
                                    return null;
                                }
                                return DataStore.getList(
                                    RestangularV3.one('utility-bill-periods'), 'by-utility-service',
                                    { serviceId: utilityService.id }
                                );
                            },
                            utilityMeters: (
                                RestangularV3: restangular.IService,
                                utilityService: aq.utilityBudgets.UtilityService
                            ) => {
                                if (!utilityService) {
                                    return null;
                                }
                                return RestangularV3
                                    .all('utility-meters').customGET('', { utilityServiceId: utilityService.id });
                            },
                            urjanetMeters: (
                                RestangularV3: restangular.IService,
                                utilityService: aq.utilityBudgets.UtilityService,
                                DataStore: aq.common.DataStore
                            ) => {
                                if (!utilityService) {
                                    return null;
                                }
                                return DataStore.getList(
                                    RestangularV3.one(''),
                                    'urjanet-meters',
                                    { utilityServiceId: utilityService.id }
                                );
                            }
                        }
                    }
                }
            })
            .state('aq.utilityBudgets.utilityaccounts.details', {
                url: '/:utilityAccountId',
                template: '<ui-view></ui-view>',
                controller: 'UtilityAccountDetails as vm',
                abstract: true,
                data: {
                    hide: false,
                    breadcrumb: 'Utility Account',
                    redirectState: 'aq.utilityBudgets.utilityaccounts'
                },
                resolve: {
                    utilityAccountId: ($stateParams: ng.ui.IStateParamsService) => {
                        return $stateParams['utilityAccountId'];
                    },
                    utilityAccount: (
                        RestangularV3: restangular.IService,
                        utilityAccountId: number,
                        DataStore: aq.common.DataStore,
                        utilityAccounts: aq.utilityBudgets.UtilityAccount[],
                        waitForAuthToken
                    ) => {
                        const isValid = _.some(utilityAccounts, (ua) => ua.id == utilityAccountId);
                        if (!isValid) {
                            return null;
                        }
                        return DataStore.get(RestangularV3.one('utility-accounts', utilityAccountId));
                    },
                    tariffSchedules: (
                        DataStore: aq.common.DataStore,
                        RestangularV3: restangular.IService,
                        utilityAccount: aq.utilityBudgets.UtilityAccount,
                        waitForAuthToken
                    ) => {
                        if (utilityAccount.utilityCompany) {
                            const utilityCompanyId = String(utilityAccount.utilityCompany);
                            return DataStore.getList(
                                RestangularV3.one(''), 'energy-tariff-schedules', { utilityCompanyId });
                        }
                        return [];
                    },
                    utilityServices: (
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        buildingId: number,
                        utilityAccountId: number,
                        waitForAuthToken
                    ) => {
                        return DataStore.getList(
                            RestangularV3.one(''), 'utility-services', { buildingId, utilityAccountId });
                    },
                    readOnlyForm: (Auth, waitForAuthToken) => {
                        return !Auth.check({ appName: 'Budgets', access: 'EDIT' });
                    },
                    utilityAccountBuildingIds: (
                        RestangularV3: restangular.IService,
                        utilityAccountId: number,
                        waitForAuthToken
                    ) => {
                        return RestangularV3.one('utility-accounts', utilityAccountId).customGET('buildings');
                    }
                },
                onEnter: (Segment: aq.services.SegmentService) => {
                    Segment.trackEvent('Budgets:Utility Accounts:Details');
                }
            })
            .state('aq.utilityBudgets.utilityaccounts.details.utilityaccount', {
                url: '/account',
                templateUrl: 'app/utilityBudgets/utilityAccounts/utilityAccountDetails.html',
                controller: 'UtilityAccountDetails as vm',
                abstract: true,
                data: {
                    hide: false,
                    breadcrumb: 'Utility Account',
                    redirectState: 'aq.utilityBudgets.utilityaccounts'
                },
                resolve: {
                    urjanetEnrollment: (
                        RestangularV3: restangular.IService,
                        utilityAccountId: number,
                        waitForAuthToken
                    ) => {
                        return RestangularV3.one('utility-accounts', utilityAccountId).one('urjanet-enrollment').get();
                    },
                    urjanetEnrollmentAccount: (
                        RestangularV3: restangular.IService,
                        utilityAccountId: number,
                        waitForAuthToken
                    ) => {
                        return RestangularV3.one('utility-accounts', utilityAccountId).one('urjanet-enrollment-account').get();
                    }
                },
                onEnter: (Segment: aq.services.SegmentService) => {
                    Segment.trackEvent('Budgets:Utility Accounts:Details');
                }
            })
            .state('aq.utilityBudgets.utilityaccounts.details.utilityaccount.info', {
                url: '/info',
                templateUrl: 'app/utilityBudgets/utilityAccounts/utilityAccountDetailsInfo.html',
                controller: 'UtilityAccountDetailsInfo as vm'
            })
            .state('aq.utilityBudgets.utilityaccounts.details.utilityaccount.changelog', {
                url: '/changelog',
                templateUrl: 'app/utilityBudgets/utilityAccounts/utilityAccountDetailsChangelog.html',
                controller: 'UtilityAccountDetailsChangelog as vm',
                resolve: {
                    urjanetEnrollmentChangelog: (
                        RestangularV3: restangular.IService,
                        utilityAccount: aq.utilityBudgets.UtilityAccount,
                        utilityAccountId: number,
                        DataStore: aq.common.DataStore,
                        resolver,
                        waitForAuthToken
                    ) => {
                        try {
                            return resolver(async () => {
                                const result = DataStore.getList(await RestangularV3.one('utility-accounts', utilityAccountId).one('changelog').get());
                                if (result === null) { return [] }
                                return result;
                            });
                        } catch (error) {
                            return [];
                        }
                    }
                }
            })
            .state('aq.utilityBudgets.utilityaccounts.details.utilityaccount.admin', {
                url: '/admin',
                templateUrl: 'app/utilityBudgets/utilityAccounts/utilityAccountDetailsAdmin.html',
                controller: 'UtilityAccountDetailsAdmin as vm'
            })
            .state('aq.utilityBudgets.utilityaccounts.details.utilityservice', {
                url: '/service/:serviceId',
                templateUrl: 'app/utilityBudgets/utilityAccounts/utilityServiceDetails.html',
                controller: 'UtilityServiceDetails as vm',
                data: {
                    hide: false,
                    breadcrumb: 'Utility Service',
                    redirectState: 'aq.utilityBudgets.utilityaccounts'
                },
                params: {
                    utilityCollectorsMap: null
                },
                resolve: {
                    utilityService: (
                        RestangularV3: restangular.IService,
                        $stateParams: ng.ui.IStateParamsService,
                        DataStore: aq.common.DataStore,
                        utilityServices: aq.common.models.UtilityService[],
                        waitForAuthToken
                    ) => {
                        const isValid = _.some(utilityServices, (us) => us.id == $stateParams.serviceId);
                        if (!isValid) {
                            return null;
                        }
                        return DataStore.get(RestangularV3.one('utility-services', $stateParams.serviceId), {});
                    },
                    collectors: (
                        Restangular: restangular.IService,
                        accountId: number,
                        $stateParams: ng.ui.IStateParamsService,
                        waitForAuthToken
                    ) => {
                        const params = { utilityMetersOnly: true };
                        return Restangular.one('accounts', accountId)
                            .one('buildings', $stateParams.buildingId)
                            .customGET('queryAllCollectors', params);
                    },
                    utilityServiceBillPeriods: (
                        RestangularV3: restangular.IService,
                        utilityService: aq.common.models.UtilityService,
                        DataStore: aq.common.DataStore
                    ) => {
                        return DataStore.getList(
                            RestangularV3.one('utility-bill-periods'), 'by-utility-service',
                            { serviceId: utilityService.id }
                        );
                    },
                    readOnlyForm: (Auth, waitForAuthToken) => {
                        return !Auth.check({ appName: 'Budgets', access: 'EDIT' });
                    },
                    utilityMeters: (RestangularV3: restangular.IService,
                        utilityService: aq.utilityBudgets.UtilityService) => {
                        return RestangularV3
                            .all('utility-meters').customGET('', { utilityServiceId: utilityService.id });
                    },
                    urjanetMeters: (RestangularV3: restangular.IService,
                        utilityService: aq.utilityBudgets.UtilityService,
                        DataStore: aq.common.DataStore) => {
                        return DataStore.getList(
                            RestangularV3.one(''),
                            'urjanet-meters',
                            { utilityServiceId: utilityService.id }
                        );
                    }
                },
                onEnter: (Segment: aq.services.SegmentService) => {
                    Segment.trackEvent('Budgets:Utility Accounts:Details');
                }
            })
            .state('aq.utilityBudgets.utilityaccounts.details.utilityservice.info', {
                url: '/info',
                templateUrl: 'app/utilityBudgets/utilityAccounts/utilityServiceDetailsInfo.html',
                controller: 'UtilityServiceDetailsInfo as vm',
                onEnter: (Segment: aq.services.SegmentService) => {
                    Segment.trackEvent('Budgets:Utility Accounts:Information');
                }
            })
            .state('aq.utilityBudgets.utilityaccounts.details.utilityservice.meters', {
                url: '/meters',
                templateUrl: 'app/utilityBudgets/utilityAccounts/utilityServiceDetailsMeters.html',
                controller: 'UtilityServiceDetailsMeters as vm',
                onEnter: (Segment: aq.services.SegmentService) => {
                    Segment.trackEvent('Budgets:Utility Accounts:Meters');
                },
                resolve: {
                    intervalMeters: (RestangularV3: restangular.IService, $stateParams) => {
                        return RestangularV3.all('interval').customGETLIST(`getIntervalMetersByUtilityService/${$stateParams.serviceId}`);
                    }
                }
            })
            .state('aq.utilityBudgets.utilityaccounts.details.utilityservice.bills', {
                url: '/bills',
                templateUrl: 'app/utilityBudgets/utilityAccounts/utilityServiceDetailsBills.html',
                controller: 'UtilityServiceDetailsBills as vm',
                onEnter: (Segment: aq.services.SegmentService) => {
                    Segment.trackEvent('Budgets:Utility Accounts:Bills');
                }
            });
    });
