'use strict';

angular.module('aq.reports').factory('MonthlyBuildingEngineerCharts',
    function (OptionsService, $filter) {

        var MonthlyBuildingEngineerCharts = function (currentData, secondData, thirdData, report, currentMonth) {
            this.getUsageChart = function (metric, unit) {
                var divide = 1000;
                var series = [];

                if (_.filter(currentData.data[metric].values, null).length !== 0) {
                    series.push({
                        data: _.take(currentData.data[metric].values.map((value) => {
                            return this.setDataValue(value, report.colors.currentYearColor);
                        }), 12),
                        name: moment(currentMonth).format('YYYY'), // TODO - drop all relations to year property
                        color: report.colors.currentYearColor
                    });
                }

                if (_.filter(secondData.data[metric].values, null).length !== 0) {
                    series.push({
                        data: _.take(secondData.data[metric].values.map((value) => {
                            return this.setDataValue(value, report.colors.secondYearColor);
                        }), 12),
                        name: moment(currentMonth).subtract(1, 'year').format('YYYY'),  // TODO - drop all relations to year property
                        color: report.colors.secondYearColor
                    });
                }

                if (_.filter(thirdData.data[metric].values, null).length !== 0) {
                    series.push({
                        data: _.take(thirdData.data[metric].values.map((value) => {
                            return this.setDataValue(value, report.colors.thirdYearColor);
                        }), 12),
                        name: moment(currentMonth).subtract(2, 'year').format('YYYY'),  // TODO - drop all relations to year property,
                        color: report.colors.thirdYearColor
                    });
                }
                return this.buildChart(unit.unit + (" (" + divide + "s)"), series);
            };

            this.getTotalActualsVsBudgetedChart = function () {
                var actualsData = _.map(
                    _.zip(currentData.data.energy.actuals, currentData.data.water.actuals, currentData.data.gas.actuals, currentData.data.steam.actuals),
                    function (month) {
                        return _.reduce(month, function (sum: number, num: number) {
                            return sum + num;
                        });
                    }
                );
                var budgetedData = _.map(
                    _.zip(currentData.data.energy.budgetItems, currentData.data.water.budgetItems, currentData.data.gas.budgetItems, currentData.data.steam.budgetItems),
                    function (month) {
                        return _.reduce(month, function (sum: number, num: number) {
                            return sum + num;
                        });
                    }
                );

                var actuals = {
                    data: actualsData,
                    name: 'Actuals',
                    color: report.colors.currentYearColor
                };

                var budgeted = {
                    data: budgetedData,
                    name: 'Budgeted',
                    color: report.colors.secondYearColor
                };

                return this.buildChart('Cost', [actuals, budgeted]);
            };

            this.getEnergyEfficiencyChart = function (metric, unit) {
                var series = [];
                if (hasData(currentData.data.energyEfficiency.values)) {
                    series.push({
                        data: _.take(currentData.data.energyEfficiency.values.map((value) => {
                            return this.setDataValue(value, report.colors.currentYearColor);
                        }), 12),
                        name: moment(currentMonth).format('YYYY'), // TODO - drop all relations to year property
                        color: report.colors.currentYearColor
                    });
                }
                if (hasData(secondData.data.energyEfficiency.values)) {
                    series.push({
                        data: _.take(secondData.data.energyEfficiency.values.map((value) => {
                            return this.setDataValue(value, report.colors.secondYearColor);
                        }), 12),
                        name: moment(currentMonth).subtract(1, 'year').format('YYYY'),  // TODO - drop all relations to year property
                        color: report.colors.secondYearColor
                    });
                }

                if (hasData(thirdData.data.energyEfficiency.values)) {
                    series.push({
                        data: _.take(thirdData.data.energyEfficiency.values.map((value) => {
                            return this.setDataValue(value, report.colors.thirdYearColor);
                        }), 12),
                        name: moment(currentMonth).subtract(2, 'year').format('YYYY'),  // TODO - drop all relations to year property
                        color: report.colors.thirdYearColor
                    });
                }
                return this.buildChart('kWh/degree days (1000s)', series);
            };

            this.setDataValue = (value, color) => {
                return {
                    y: value.value,
                    color: value.isMissingData ? this.transparentColor(color) : color
                };
            }

            this.transparentColor = (color) => {
                const alpha = 0.5;
                const convertedAlpha = Math.floor(alpha * 255);
                const alphaString = convertedAlpha < 16 ? '0' + convertedAlpha.toString(16) : convertedAlpha.toString(16);
                return color + alphaString;
            }

            this.buildChart = function (title, series) {
                return {
                    chart: {
                        height: 225,
                        type: 'column'
                    },
                    title: {
                        text: null
                    },
                    subtitle: {
                        text: null
                    },
                    xAxis: {
                        categories: moment.monthsShort(),
                        type: 'category',
                        crosshair: true
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: title
                        }
                    },
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal',
                        itemStyle: {
                            fontSize: "10px"
                        },
                    },
                    plotOptions: {
                        column: {
                            groupPadding: .1
                        },
                        series: {
                            // turn off animations because of screenshot tool
                            animation: false
                        }
                    },
                    series: series,
                    navigation: {
                        buttonOptions: {
                            enabled: false
                        }
                    }
                }
            }
        };

        function hasData(data) {
            return _.filter(data, null).length !== 0;
        }

        return MonthlyBuildingEngineerCharts;
    });
