namespace aq.propertySettings {
    export class BuildingDetailPersonnelCtrl {
        private personas;
        constructor(
            private building,
            private readOnlyForm,
            private personnel: aq.common.models.BuildingPersonnel[],
            private users: aq.common.models.User[],
            private $q: ng.IQService,
            private DataStore: aq.common.DataStore,
            private $scope: ng.IScope,
            private Messages: aq.services.Messages,
            private Auth: aq.services.Auth,
            private RestangularV3: restangular.IService,
            private $mdDialog: ng.material.IDialogService
        ) {
            this.personnel = this.initPersonnel();
            this.personas = [{
                label: 'Asset Manager',
                value: 'ASSET_MANAGER'
            }, {
                label: 'Property Manager',
                value: 'PROPERTY_MANAGER'
            }, {
                label: 'Building Engineer',
                value: 'BUILDING_ENGINEER'
            }];
        }

        public save(event) {
            this.updatePersonnel()
                .then(() => {
                    const confirm = this.$mdDialog
                        .confirm()
                        .title(`Do you want to subscribe these personnel to our recommended reports?`)
                        .targetEvent(event)
                        .ok('Subscribe')
                        .cancel('Not Now');
                    return this.$mdDialog.show(confirm);
                })
                .then(() => {
                    const employeeIds = _.filter(this.personnel, (employee) => { return !_.isNil(employee.user) && employee.id; })
                        .map((employee) => employee.id);
                    return this.RestangularV3.all('reports')
                        .customPOST(null, 'recommended/subscribe/for-employees', { employeeId: employeeIds })
                        .catch(() => {
                            this.Messages.error('Unable to update building personnel');
                        });
                })
                .then(() => {
                    this.Messages.success('Successfully updated building personnel');
                });
        }

        public delete(employee) {
            this.DataStore.delete(employee)
            .then((result) => {
                _.remove(this.personnel, {id: employee.id});
                this.personnel.push({
                    persona: employee.persona,
                    building: this.building.id,
                    user: null,
                    sortOrder: employee.sortOrder
                });
                this.Messages.success('Successfully removed building personnel');
            });
        }

        private updatePersonnel() {
            return this.$q.all(
                _.reject(this.personnel, ['user', null])
                .map((employee) => {
                    if (employee.id) {
                        const employeeCopy: any = this.RestangularV3.copy(employee);
                        return this.DataStore.update(employeeCopy);
                    } else {
                        return this.DataStore.create(this.building.all('personnel'), employee);
                    }
                })
            )
            .then((results) => {
                results.forEach((result: aq.common.models.BuildingPersonnel) => {
                    // This remove() assumes we will only have 1 user per distinct persona.
                    // If we ever have multiple users per persona then we need to update this
                    _.remove(this.personnel, {persona: result.persona});
                    this.personnel.push(result);
                });
                this.personnel = this.initPersonnel();
                this.$scope.$emit('BUILDING_PERSONNEL_UPDATED', { building: this.building });
            });
        }

        private initPersonnel() {
            const updatedPersonnelList = [];
            updatedPersonnelList.push(this.initEmployee('ASSET_MANAGER', 1));
            updatedPersonnelList.push(this.initEmployee('PROPERTY_MANAGER', 2));
            updatedPersonnelList.push(this.initEmployee('BUILDING_ENGINEER', 3));
            return updatedPersonnelList;
        }

        private initEmployee(employeePersona, sortOrder) {
            let employee = _.find(this.personnel, {persona: employeePersona});
            if (!employee) {
                employee = {
                    persona: employeePersona,
                    building: this.building.id,
                    sortOrder,
                    user: null
                };
            } else {
                employee.sortOrder = sortOrder;
            }
            return employee;
        }
    }
    angular
        .module('properties')
        .controller('BuildingDetailPersonnelCtrl', BuildingDetailPersonnelCtrl);
}
