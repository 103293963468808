namespace aq.admin.accounts.buildings {
    export class AdminBuildingListCtrl {
        /* @ngInject */
        constructor(
            private $scope: AdminBuildingListCtrlScope,
            private allBuildings: {count: number, buildings: aq.common.models.Building[]},
            private $state: ng.ui.IStateService,
            private $mdDialog: ng.material.IDialogService,
            private Messages,
            private Restangular: restangular.IService,
            private loading
        ) {
            $scope.buildings = allBuildings.buildings;
            $scope.paging = {
                limit: 10,
                page: 1,
                total: allBuildings.count
            };
            $scope.buildingOrder = "name";
            $scope.vm = {
                searchText: ""
            }
        }

        search = () => {
            let desc = this.$scope.buildingOrder.indexOf('-') === 0;
            let orderBy = desc ? this.$scope.buildingOrder.substring(1) : this.$scope.buildingOrder;
            this.loading.start(true);
            return this.Restangular.one("internal").customGET('buildings', {
                inflate: 'account',
                page: this.$scope.paging.page,
                pageSize: this.$scope.paging.limit,
                orderBy: orderBy,
                search: this.$scope.vm.searchText,
                asc: !desc
            }).then((result) => {
                this.$scope.buildings = result.buildings;
                this.$scope.paging.total = result.count;
                this.loading.stop();
            });
        }

        reOrder = (orderBy) => {
            this.$scope.buildingOrder = orderBy;
            this.search();
        }

         edit(building) {
            this.$state.go('.details', {buildingId: building.id});
        }
    }

    export interface AdminBuildingListCtrlScope extends ng.IScope {
        buildings: aq.common.models.Building[];
        vm: {searchText: string};
        paging: {limit:number,page:number,total:number};
        buildingOrder: string;
    }
    angular.module('aq.admin.accounts.buildings').controller('AdminBuildingListCtrl', AdminBuildingListCtrl);
}