var aq;
(function (aq) {
    var propertySettings;
    (function (propertySettings) {
        var StateAlertLevelsComponentCtrl = /** @class */ (function (_super) {
            __extends(StateAlertLevelsComponentCtrl, _super);
            /* @ngInject */
            function StateAlertLevelsComponentCtrl($mdDialog, RawService, $element) {
                var _this = _super.call(this) || this;
                _this.$mdDialog = $mdDialog;
                _this.RawService = RawService;
                _this.$element = $element;
                _this.sortableConf = {
                    onSort: function (event) {
                        _this.moveAlertItemIntoNewPosition(event.oldIndex, event.newIndex);
                    }
                };
                return _this;
            }
            StateAlertLevelsComponentCtrl.prototype.$onInit = function () {
                var _this = this;
                this.severityOptions = [propertySettings.LevelSeverity.NORMAL, propertySettings.LevelSeverity.WARNING, propertySettings.LevelSeverity.CRITICAL];
                this.measureUnitOptions = this.RawService.getValidMeasureUnits();
                _.each(this.alert.alertLevels, function (level) {
                    // set this for our UI
                    level.measureUnit = _this.toMeasureUnitString(level.measure, level.unit);
                });
                this.queryableTypeOptions = this.RawService.getQueryableTypesWithBmsPoints();
                this.queryableOptionsCache = {};
                this.RawService.fetchSpaces(this.building.id)
                    .then(function (results) { return _this.updateQueryableCache(aq.services.Queryable.SPACES, results); });
                this.RawService.fetchTenants(this.building.id)
                    .then(function (results) { return _this.updateQueryableCache(aq.services.Queryable.TENANTS, results); });
                this.RawService.fetchMeters(this.building.id)
                    .then(function (results) { return _this.updateQueryableCache(aq.services.Queryable.METERS, results); });
                this.RawService.fetchSources(this.building.id)
                    .then(function (results) { return _this.updateQueryableCache(aq.services.Queryable.SOURCES, results); });
                this.RawService.fetchPoints(this.building.id)
                    .then(function (results) { return _this.updateQueryableCache(aq.services.Queryable.POINTS, results); });
                this.RawService.fetchBmsPoints(this.building.id)
                    .then(function (results) { return _this.updateQueryableCache(aq.services.Queryable.BMS_POINTS, results); });
            };
            StateAlertLevelsComponentCtrl.prototype.$onChanges = function (changes) {
                var _this = this;
                if (changes.alert) {
                    _.each(this.alert.alertLevels, function (level) {
                        if (level.queryableType) {
                            level.queryableType = level.queryableType.toLowerCase();
                        }
                        if (!level.queryable && level.queryableId && _this.queryableOptionsCache[level.queryableType]) {
                            if (level.queryableType === aq.services.Queryable.BMS_POINTS) {
                                var queryable = _.find(_this.queryableOptionsCache[level.queryableType], function (q) {
                                    return q.id == level.queryableId && q.bmsPointName == level.bmsPointName;
                                });
                                if (queryable) {
                                    level.queryable = {
                                        id: level.queryableId,
                                        bmsPointName: level.bmsPointName,
                                        name: queryable.name
                                    };
                                }
                            }
                            if (level.queryableType !== aq.services.Queryable.BMS_POINTS) {
                                var queryable = _.find(_this.queryableOptionsCache[level.queryableType], function (q) {
                                    return q.id == level.queryableId;
                                });
                                if (queryable) {
                                    level.queryable = {
                                        id: level.queryableId,
                                        name: queryable.name
                                    };
                                }
                            }
                        }
                        if (!level.measureUnit && level.measure && level.unit) {
                            level.measureUnit = _this.toMeasureUnitString(level.measure, level.unit);
                        }
                    });
                }
            };
            StateAlertLevelsComponentCtrl.prototype.updateQueryableCache = function (queryableType, results) {
                var _this = this;
                this.queryableOptionsCache[queryableType] = results;
                // For our UI, we need the full {id, name} object, but we only store id
                // so here we find the full object from our list based on our selected id
                _(this.alert.alertLevels)
                    .filter(function (level) { return level.queryableId && level.queryableType == queryableType; })
                    .each(function (level) {
                    if (level.queryableType === aq.services.Queryable.BMS_POINTS) {
                        level.queryable = _.find(_this.queryableOptionsCache[queryableType], function (result) {
                            return result.id == level.queryableId && result.bmsPointName == level.bmsPointName;
                        });
                        if (!level.queryable) {
                            level.queryable = {
                                name: "Configuration Error: " + level.bmsPointName,
                                id: level.queryableId
                            };
                        }
                    }
                    if (level.queryableType !== aq.services.Queryable.BMS_POINTS) {
                        level.queryable = _.find(_this.queryableOptionsCache[queryableType], function (result) {
                            return result.id == level.queryableId;
                        });
                    }
                });
                // Remove queryableType option from list if there are no values for the queryableType
                if (!results.length) {
                    var index = this.queryableTypeOptions.indexOf(queryableType);
                    this.queryableTypeOptions.splice(index, 1);
                    return;
                }
            };
            StateAlertLevelsComponentCtrl.prototype.onAlertChange = function () {
                if (this.onChange) {
                    this.onChange({
                        $event: {
                            alert: this.alert
                        }
                    });
                }
            };
            StateAlertLevelsComponentCtrl.prototype.expandLevel = function (level) {
                if (this.expandedLevel == level) {
                    this.expandedLevel = null;
                }
                else {
                    this.expandedLevel = level;
                }
            };
            StateAlertLevelsComponentCtrl.prototype.getMeasureUnitOptionsForQueryable = function (level) {
                // TODO: filter this based on our queryable? not sure how we would do this just yet
                return this.measureUnitOptions;
            };
            StateAlertLevelsComponentCtrl.prototype.updateMeasureUnit = function (level) {
                var measureUnit = this.getMeasureUnitFromString(level.measureUnit);
                level.measure = measureUnit.measure;
                level.unit = measureUnit.unit;
            };
            StateAlertLevelsComponentCtrl.prototype.toMeasureUnitString = function (measure, unit) {
                return measure + "::" + unit;
            };
            StateAlertLevelsComponentCtrl.prototype.getMeasureUnitFromString = function (measureUnit) {
                var split = measureUnit.split('::');
                if (split.length != 2) {
                    throw new Error("Invalid measure unit: " + measureUnit);
                }
                return {
                    measure: split[0],
                    unit: split[1]
                };
            };
            StateAlertLevelsComponentCtrl.prototype.onQueryableTypeChange = function (level) {
                if (level.queryableType === aq.services.Queryable.BUILDINGS) {
                    level.queryableId = Number(this.building.id);
                }
                else {
                    level.queryableId = null;
                }
                level.queryable = null;
                // TODO: clear measure/unit when we change???
            };
            StateAlertLevelsComponentCtrl.prototype.onQueryableIdChange = function (level) {
                if (level.queryable && level.queryable.id) {
                    level.queryableId = level.queryable.id;
                }
                if (level.queryableType === aq.services.Queryable.BMS_POINTS) {
                    if (level.queryable) {
                        level.bmsPointName = level.queryable.bmsPointName;
                    }
                }
            };
            StateAlertLevelsComponentCtrl.prototype.getQueryableOptions = function (queryable, searchText) {
                if (!this.queryableOptionsCache[queryable]) {
                    return [];
                }
                if (!searchText) {
                    searchText = '';
                }
                searchText = searchText.toLowerCase();
                return _.filter(this.queryableOptionsCache[queryable], function (option) {
                    return _.includes(option.name.toLowerCase(), searchText);
                });
            };
            StateAlertLevelsComponentCtrl.prototype.hasBoundary = function (level) {
                return !(_.isNil(level.lowerBoundary) && _.isNil(level.upperBoundary));
            };
            StateAlertLevelsComponentCtrl.prototype.moveAlertItemIntoNewPosition = function (oldIndex, newIndex) {
                var alertLevelCopy = angular.copy(this.alert.alertLevels[oldIndex]);
                this.alert.alertLevels.splice(oldIndex, 1);
                this.alert.alertLevels.splice(newIndex, 0, alertLevelCopy);
                this.updateLevelOrders();
                this.onAlertChange();
            };
            StateAlertLevelsComponentCtrl.prototype.deleteLevelConfirm = function (ev, level) {
                var _this = this;
                // don't need to confirm if this alert hasn't been created yet
                if (!this.alert.id) {
                    return this.deleteLevel(level);
                }
                var confirm = this.$mdDialog.confirm()
                    .title('Are you sure you want to delete this level?')
                    .ariaLabel('Delete Level')
                    .targetEvent(ev)
                    .ok('Ok')
                    .cancel('Cancel');
                this.$mdDialog.show(confirm)
                    .then(function () {
                    _this.deleteLevel(level);
                });
            };
            StateAlertLevelsComponentCtrl.prototype.deleteLevel = function (level) {
                _.remove(this.alert.alertLevels, function (l) { return l.order == level.order; });
                this.updateLevelOrders();
                this.onAlertChange();
            };
            StateAlertLevelsComponentCtrl.prototype.updateLevelOrders = function () {
                _.each(this.alert.alertLevels, function (l, i) {
                    l.order = i + 1;
                });
            };
            StateAlertLevelsComponentCtrl.prototype.addLevel = function () {
                if (!this.alert.alertLevels || this.alert.alertLevels.length == 0) {
                    this.alert.alertLevels = [
                        {
                            order: 1,
                            levelSeverity: propertySettings.LevelSeverity.NORMAL
                        }
                    ];
                    this.expandLevel(this.alert.alertLevels[0]);
                }
                else {
                    var lastLevel = angular.copy(this.alert.alertLevels[this.alert.alertLevels.length - 1]);
                    lastLevel.levelName = null;
                    lastLevel.actionDescription = null;
                    lastLevel.lowerBoundary = lastLevel.upperBoundary;
                    lastLevel.upperBoundary = null;
                    delete lastLevel.id;
                    this.alert.alertLevels.push(lastLevel);
                    this.expandLevel(lastLevel);
                }
                this.updateLevelOrders();
                this.onAlertChange();
            };
            StateAlertLevelsComponentCtrl.prototype.isNil = function (val) {
                return _.isNil(val);
            };
            StateAlertLevelsComponentCtrl.prototype.isErrorLevel = function (index) {
                if (this.hideError) {
                    return false;
                }
                //invalid elements which are not becoming valid
                var invalidElements = this.$element.find('.ng-invalid').not('.ng-invalid-remove');
                var invalidElementsParents = invalidElements.parents("md-input-container[data-level=\"" + index + "\"]");
                if (invalidElementsParents.length > 0) {
                    return true;
                }
                //valid elements which are becoming invalid
                invalidElements = this.$element.find('.ng-invalid-add');
                invalidElementsParents = invalidElements.parents("md-input-container[data-level=\"" + index + "\"]");
                if (invalidElementsParents.length > 0) {
                    return true;
                }
                return false;
            };
            return StateAlertLevelsComponentCtrl;
        }(propertySettings.AbstractAlertComponent));
        propertySettings.StateAlertLevelsComponentCtrl = StateAlertLevelsComponentCtrl;
        angular.module('properties')
            .component('stateAlertLevels', {
            templateUrl: 'app/properties/alerts/directives/stateAlertLevels.html',
            bindings: {
                alert: '<',
                building: '<',
                form: '=',
                onChange: '&',
                isReadonly: '<?',
                hideError: '<?'
            },
            controller: StateAlertLevelsComponentCtrl
        });
    })(propertySettings = aq.propertySettings || (aq.propertySettings = {}));
})(aq || (aq = {}));
