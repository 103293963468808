angular.module('aq.filters.units')
.filter('toHumidityUnit', ['$filter', function($filter) {
    return function(input, format) {
        if (_.isNull(input) || _.isUndefined(input)) {
            return null;
        }
        let converted = input * 100;

        // default to whole numbers if no format provided
        const numberFormat = format ? format : '0,0';
        converted = $filter('number')(converted, numberFormat);

        return converted;
    }
}]);
