/**
 * This can be removed once this is resolved -
 * https://github.com/angular/material/issues/3857
 *
 */
angular.module('aq.ui').directive('mdChipsChangeTrigger', function () {
    return {
        restrict: 'A',
        require: "ngModel",
        link: function ($scope, $element, $attrs, modelCtrl) {
            $scope.$watch($attrs.ngModel, function(newVal, oldVal){
                if(newVal !== oldVal){
                    $scope.$eval($attrs.ngChange);
                }
            }, true);
        }
    }
});

