var aq;
(function (aq) {
    var projectCenter;
    (function (projectCenter) {
        var ProjectCenterTaskEditCtrl = /** @class */ (function (_super) {
            __extends(ProjectCenterTaskEditCtrl, _super);
            /* @ngInject */
            function ProjectCenterTaskEditCtrl($scope, $mdDialog, project, task, users, DataStore, ProjectService, Messages, RestangularV3, $state, account, $location, options) {
                var _this = _super.call(this, $scope, $mdDialog) || this;
                _this.$scope = $scope;
                _this.$mdDialog = $mdDialog;
                _this.project = project;
                _this.task = task;
                _this.users = users;
                _this.DataStore = DataStore;
                _this.ProjectService = ProjectService;
                _this.Messages = Messages;
                _this.RestangularV3 = RestangularV3;
                _this.$state = $state;
                _this.account = account;
                _this.$location = $location;
                _this.options = options;
                _this.task.dueDate = task.dueDate ? moment(task.dueDate).toDate() : null;
                if (_this.task.completionDate) {
                    _this.task.complete = true;
                }
                _this.ignoreTask = (_this.task.status == 'IGNORED');
                var host = _this.getHost();
                _this.actionOptions = {
                    'PROFILE': {
                        label: 'Go to Profile',
                        actionUrl: "" + host + _this.$state.href('aq.user.profile')
                    },
                    'DEVICES_AND_EQUIPMENT': {
                        label: 'Go to Devices & Equipment',
                        actionUrl: "" + host + _this.$state.href('aq.deviceManagement.building', { accountId: account.id, buildingId: project.building })
                    },
                    'UTILITY_BILLS': {
                        label: 'Go to Utility Bill Management',
                        actionUrl: "" + host + _this.$state.href('aq.utilityBudgets.utilityaccounts', { accountId: account.id, buildingId: project.building })
                    },
                    'USERS': {
                        label: 'Go to User Management',
                        actionUrl: "" + host + _this.$state.href('aq.accounts.members', { accountId: account.id })
                    },
                    'BUILDING': {
                        label: 'Go to Property Settings',
                        actionUrl: "" + host + _this.$state.href('aq.properties.buildings.form', { accountId: account.id, buildingId: project.building })
                    },
                    'BUDGETS': {
                        label: 'Go to Budgets',
                        actionUrl: "" + host + _this.$state.href('aq.utilityBudgets.budgets', { accountId: account.id, buildingId: project.building })
                    },
                    'TARGETS': {
                        label: 'Go to Targets',
                        actionUrl: "" + host + _this.$state.href('aq.properties.buildings.targets', { accountId: account.id, buildingId: project.building })
                    }
                };
                return _this;
            }
            ProjectCenterTaskEditCtrl.prototype.onTaskIgnoreChange = function () {
                if (this.task.status != 'IGNORED') {
                    this.task.status = 'IGNORED';
                    this.task.complete = false;
                }
                else if (this.task.status === 'IGNORED' && this.task.completionDate) {
                    this.task.status = 'COMPLETE';
                    this.task.complete = true;
                }
                else if (this.task.status === 'IGNORED' && !this.task.completionDate) {
                    this.task.status = 'INCOMPLETE';
                    this.task.complete = false;
                }
            };
            ProjectCenterTaskEditCtrl.prototype.save = function () {
                var _this = this;
                if (this.task.status != 'IGNORED') {
                    if (this.task.complete && !this.task.completionDate) {
                        this.task.completionDate = moment().format('YYYY-MM-DD');
                    }
                    if (!this.task.complete && this.task.completionDate) {
                        this.task.completionDate = null;
                    }
                }
                if (!this.task.actionUrl) {
                    var host = this.getHost();
                    this.task.actionUrl = "" + host + this.$state.href('aq.projectCenter.overview.project.tasks', {
                        accountId: this.account.id,
                        buildingId: this.project.building,
                        projectId: this.project.id
                    });
                }
                var successMessage = this.task.id ? 'Task succesfully updated' : 'Successfully created Task';
                return this.ProjectService.saveTask(this.project, this.task)
                    .then(function (result) {
                    _this.Messages.success(successMessage);
                    return result;
                })
                    .catch(function (err) {
                    _this.Messages.error('Unable to save task');
                });
            };
            ProjectCenterTaskEditCtrl.prototype.getHost = function () {
                var host = this.$location.protocol() + "://" + this.$location.host();
                // if we are onlocal add port
                if (host.includes('localhost')) {
                    host += ":" + this.$location.port();
                }
                return host;
            };
            return ProjectCenterTaskEditCtrl;
        }(aq.common.Controllers.ModalCtrl));
        projectCenter.ProjectCenterTaskEditCtrl = ProjectCenterTaskEditCtrl;
        angular
            .module('projectCenter')
            .controller('ProjectCenterTaskEditCtrl', ProjectCenterTaskEditCtrl);
    })(projectCenter = aq.projectCenter || (aq.projectCenter = {}));
})(aq || (aq = {}));
