namespace aq.deviceManagement {
    export class DataApiV1Units {
        public static getUnits(): DataApiV1Unit[] {
            return [
                { name: 'Raw Counter', measure: 'pulse', apiUnit: 'PULSE', color: '#000000' },
                { name: 'State', measure: 'state', apiUnit: 'STATE', color: '#F1A81D' },
                { name: 'CO2', measure: 'environment', apiUnit: 'K30_CO2', label: 'ppm', color: '#D1F07D' },
                { name: 'Relative Humidity', measure: 'environment', apiUnit: 'HUMIDITY', label: '%', color: '#0091f1' },
                { name: 'Temperature', measure: 'environment', apiUnit: 'ENV_TEMPERATURE', label: '°C', color: '#fc6f71' },
                { name: 'Gallons', measure: 'water', apiUnit: 'WATER', label: 'gal', scale: 0.264172, color: '#0091f1' },
                { name: 'Gallons per minute', measure: 'water', apiUnit: 'FLOW_RATE', label: 'GPM', scale: 0.264172 * 60, color: '#1E59AE' },
                { name: 'Cubic Centum-Feet', measure: 'gas', apiUnit: 'GAS', label: 'CCF', scale: 0.353147, color: '#7ACD46' },
                { name: 'Kilowatt-hours', measure: 'electricity', apiUnit: 'ENERGY', label: 'kW·h', scale: 0.00000028, color: '#F1A81D' },
                { name: 'Kilowatts', measure: 'electricity', apiUnit: 'POWER', label: 'kW', scale: 0.001, color: '#B0A81D'  },
                { name: 'Current', measure: 'electricity', apiUnit: 'AMPERAGE', label: 'A', multiMetric: true, color: '#0091f1' },
                { name: 'Power Factor', measure: 'electricity', apiUnit: 'POWER_FACTOR', label: 'PF', multiMetric: true, color: '#fc6f71' },
                { name: 'Voltage', measure: 'electricity', apiUnit: 'VOLTAGE', label: 'V', multiMetric: true, color: '#D1F07D' },
                { name: 'Line Voltage', measure: 'electricity', apiUnit: 'LINE_VOLTAGE', label: 'V', multiMetric: true, color: '#7acd46' },
                { name: 'Thousand Pound', measure: 'steam', apiUnit: 'STEAM_MASS', label: 'Mlb', color: '#1E59AE' },
                { name: 'Thousand Pound per hour', measure: 'steam', apiUnit: 'STEAM_MASS_RATE', label: 'Mlb/h', scale: 3600, color: '#0091f1' },
                { name: 'Thousand BTU', measure: 'heat', apiUnit: 'HEAT_TRANSFER', label: 'kBTU', color: '#1E59AE' },
                { name: 'Thousand BTU per hour', measure: 'heat', apiUnit: 'HEAT_TRANSFER_RATE', label: 'kBTU/h', scale: 3600, color: '#0091f1' }
            ];
        }
    }

    export interface DataApiV1Unit {
        name: string;
        measure: string;
        apiUnit: string;
        label?: string;
        scale?: number;
        color?: any;
        multiMetric?: boolean;
    }
}

angular
    .module('deviceManagement')
    .factory('DataApiV1Units', [() => aq.deviceManagement.DataApiV1Units.getUnits()]);

