namespace aq.ui {

    export class AqNestedSidebarNavHeaderController {

        public isLargeScreen: () => boolean;
        public parent: AqNestedSidebarNavController;
        public title: string;

        /* @ngInject */
        constructor() {
            this.title = '';
        }

        public $onInit = () => {
            this.isLargeScreen = () => this.parent.isLargeScreen();
        };
    }

    angular
        .module('aq.ui')
        .component('aqNestedSidebarNavHeader', {
            templateUrl: 'app/common/directives/aqNestedSidebarNav/aqNestedSidebarNavHeader/aqNestedSidebarNavHeader.html',
            controller: AqNestedSidebarNavHeaderController,
            controllerAs: 'vm',
            bindings: {
                title: '@'
            },
            require: {
                parent: '^^aqNestedSidebarNav'
            }
        });
}
