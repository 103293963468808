angular.module('aq.accounts.calendars').controller('CalendarListCtrl',
    function($scope, $state, CalendarData, Messages, workCalendars, ruleCategories) {

    $scope.calendar = null;
    $scope.workCalendars = workCalendars;

    if (workCalendars.length > 0) {
        $scope.calendar = _.first(workCalendars);
        $state.transitionTo('aq.accounts.calendars.rules.views', {
            accountId: $scope.account.id, calendarId : $scope.calendar.id
        });
    }

    $scope.ruleCategories = ruleCategories;

    $scope.changeCalendar = function(calendar) {
        $scope.calendar = calendar;
        $state.transitionTo('aq.accounts.calendars.rules.views', { accountId: $scope.account.id, calendarId : calendar.id });
    }

    $scope.addCalendar = function () {
        var model = {
            account: $scope.account,
            name: 'New Calendar',
            rules: []
        };

        $scope.account.all('workCalendars').post(model).then(
            function (result) {
                result = _.extend(result, result.model);
                $scope.workCalendars.push(result);
                $scope.calendar = result;
                $state.transitionTo('aq.accounts.calendars.rules.views', { accountId: $scope.account.id, calendarId: result.id });

                //create default rule
                $scope.nonWorkingCategory  = _.find($scope.ruleCategories, { name: 'Non-Working Hours' });

                $scope.newDefaultRule = {
                    name: 'Non-working Hours',
                    activeState: true,
                    dateEnd: null,
                    dateStart: null,
                    isPublic: false,
                    isDefault: true,
                    priority: 1,
                    category: $scope.nonWorkingCategory.id,
                    timeStart: null,
                    timeEnd: null,
                    week: CalendarData.allWeek
                };

                $scope.account
                    .one('workCalendars', result.id)
                    .post('rules', $scope.newDefaultRule)
                    .then(function (resultRule) {
                        result.rules.push(resultRule.id);
                        Messages.info("Default Non-Working Event has been created");
                    }, function () {
                        Messages.error("Cannot create event!");
                    });

            },
            function (result) {
                Messages.error("Error while creating calendar");
            }
        );
    }

    $scope.deleteCalendar = function (calendar) {
        calendar.remove().then(
            function (result) {
                $scope.workCalendars = _.without($scope.workCalendars, calendar);
                Messages.success("Successfully deleted calendar.");
                $state.transitionTo('aq.accounts.calendars', {accountId : $scope.account.id});
            },
            function (result) {
                if (result && result.data.errorText) {
                    Messages.error(result.data.errorText);
                } else {
                    Messages.error("Failed to delete. Unknown error occurred, we've been notified & are looking into it!");
                }
            }
        );
    }

    $scope.droppedOnCalendars = function (event) {
        var rule = CalendarData.workCalendarRules[event.draggedIndex];
        $scope.workCalendars[event.droppedOnIndex].all('rules').post(_.removeFromObject(rule, 'id')).then(
            function (result) {
                $scope.workCalendars[event.droppedOnIndex].rules.push(_.extend(result, result.model));
            },
            function (result) {
                Messages.error("Unable to copy rule");
            }
        );
    }

    $scope.saveCalendar = function (model) {
        return model.put().then(function() {
            Messages.info("Calendar updated");
        }, function() {
            Messages.error("Cannot update calendar");
        })
    };

});