namespace aq.deviceManagement {
    export class NewReadingCtrl extends aq.common.Controllers.ModalCtrl {
        private maxDate: Date;
        private hours: number;
        private minutes: number;
        /* @ngInject */
        constructor(public $mdDialog: ng.material.IDialogService,
                    private manualReading: aq.common.models.ManualReading,
                    private collector: any,
                    private metricLabelUnit: string,
                    private timeZoneId: any) {
            super({}, $mdDialog);
            this.metricLabelUnit = metricLabelUnit;
            this.collector = collector;
            this.manualReading = manualReading;
            this.maxDate = moment().toDate();
            if (!this.manualReading.id) {
                const readingDatetime = moment().tz(this.timeZoneId);
                this.manualReading = {
                    timestamp: readingDatetime,
                    viewOnly: {
                        date: readingDatetime.toDate(),
                        hour: readingDatetime.get('hours'),
                        minute: this.roundMinute(readingDatetime.get('minutes'))
                    },
                    value: null,
                    imageUrl: '',
                    collector: collector.id
                };
            } else {
                const readingDatetime = moment.tz(this.manualReading.timestamp, this.timeZoneId);
                this.manualReading.viewOnly = {
                    date: readingDatetime.toDate(),
                    hour: readingDatetime.get('hours'),
                    minute: readingDatetime.get('minutes')
                };
            }
            this.hours = _.range(0, 24);
            this.minutes = _.range(0, 60, 5);
        }

        saveImage() {
            delete this.manualReading.imageThumbnailUrl;
        }

        roundMinute(minute) {
            return 5 * Math.round(minute / 5);
        }

        getBuildingTimeZoneAbbr() {
            return moment().tz(this.timeZoneId).zoneAbbr();
        }

    }

    angular
        .module('deviceManagement')
        .controller('NewReadingCtrl', NewReadingCtrl);
}
