namespace aq.dashboard.widgets {

    import AlertData = aq.models.alerts.AlertData;
    import AlertConfig = aq.models.alerts.AlertConfig;
    import LevelSeverity = aq.models.alerts.LevelSeverity;
    import SeverityIcon = aq.models.alerts.SeverityIcon;
    import AlertDataLevel = aq.models.alerts.AlertDataLevel;

    export class AlertCtrl {
        public data: AlertData;
        public timestamp: number;
        private statusInterval;

        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private accountId: string,
            private config: AlertConfig,
            private buildings: aq.common.models.Building[],
            private ModelUtilService: aq.services.ModelUtilService,
            private AlertEditService: AlertEditService,
            private $interval: ng.IIntervalService,
            private $translate,
            private RestangularV3: restangular.IService,
            private Restangular: restangular.IService,
            private DataStore: aq.common.DataStore,
            private $q: ng.IQService,
            private $state: ng.ui.IStateService
        ) {
            this.config.options = {
                buildings: this.ModelUtilService.pareProperties(this.buildings, ['account']),
                frequencies: [
                    { label: 'Every Minute', value: 1 * 60 },
                    { label: 'Every 3 Minutes', value: 3 * 60 },
                    { label: 'Every 5 Minutes', value: 5 * 60 },
                    { label: 'Every 10 Minutes', value: 10 * 60 },
                    { label: 'Every 15 Minutes', value: 15 * 60 }
                ],
                alerts: [],
                getAlertOptions: (buildingId: string) => {
                    const stateAlerts = this.DataStore.getList(
                        this.RestangularV3.one(''),
                        'state-alerts',
                        { buildingId }
                    );
                    const usageAlerts = this.DataStore.getList(
                        this.Restangular.one('accounts', this.config.accountId).one('buildings', buildingId),
                        'realTimeEnergyAlerts',
                        {}
                    );
                    return this.$q.all([stateAlerts, usageAlerts]).then((responses) => {
                        const state = _.filter(responses[0], (item) => item.active);
                        const usage = _.filter(responses[1], (item) => item.active);
                        const data = _.union(state, usage);
                        return _.map(data, (item) => {
                            return {
                                label: item.name,
                                value: item.id,
                                type: item.type
                            };
                        });
                    });
                }
            };
            this.config.actions = this.AlertEditService;
            this.config.accountId = this.accountId;
            if (!this.config.buildingId) {
                this.config.buildingId = _.first(this.buildings).id;
            }
            if (!this.config.updateFrequencySeconds) {
                this.config.updateFrequencySeconds = this.config.options.frequencies[0].value;
            }
            this.$scope.config = this.config;
            this.config.options.getAlertOptions(this.config.buildingId, false)
                .then((alertOptions) => {
                    this.config.options.alerts = alertOptions;
                });
            this.startAutoRefresh();
        }
        public startAutoRefresh() {
            this.getData();
            this.$scope.$on('$destroy', () => {
                if (this.statusInterval) {
                    this.$interval.cancel(this.statusInterval);
                }
            });
            this.statusInterval = this.$interval(() => {
                this.getData();
            }, this.config.updateFrequencySeconds * 1000);
        }
        public getData() {
            if (!this.config.alertId) {
                return;
            }
            this.RestangularV3.one('alert-data', this.config.alertId).get({ noCache: moment().valueOf() })
                .then((data) => {
                    this.data = data;
                    this.timestamp = moment().valueOf();
                });
        }
        public getStatusIcon(status) {
            switch (status) {
                case LevelSeverity.NORMAL:
                    return SeverityIcon.NORMAL;
                case LevelSeverity.WARNING:
                    return SeverityIcon.WARNING;
                case LevelSeverity.CRITICAL:
                    return SeverityIcon.CRITICAL;
                default:
                    return SeverityIcon.UNKNOWN;
            }
        }
        public getStatusLabel(status) {
            switch (status.toUpperCase()) {
                case LevelSeverity.NORMAL:
                    return 'Normal';
                case LevelSeverity.WARNING:
                    return 'Warning';
                case LevelSeverity.CRITICAL:
                    return 'Critical';
                default:
                    return 'Unknown';
            }
        }
        public getBuildingName() {
            const building = _.find(this.config.options.buildings, (b) => b.id == this.config.buildingId);
            if (building) {
                return building.name;
            }
            return '';
        }
        public getCurrentName() {
            if (!this.data.currentLevel || !this.data.currentLevel.name) {
                return this.data.alertName;
            }
            return this.data.currentLevel.name;
        }
        public getLevelName(level: AlertDataLevel) {
            if (!level) {
                return '';
            }
            return level.name || this.getStatusLabel(level.severity);
        }
        public getLevelDescription(level: AlertDataLevel) {
            if (!level) {
                return '';
            }
            if (level.lowerBoundary != null || level.upperBoundary != null) {
                const name = level.queryable && level.queryable.name ? level.queryable.name.toLowerCase() + ' ' : '';
                const symbol = level.unit && level.unit.symbol && level.unit.symbol != aq.services.Unit.STATE ? level.unit.symbol : '';
                if (level.lowerBoundary != null && level.upperBoundary != null) {
                    return `when in ${this.$translate.instant('between')} ${level.lowerBoundary}${symbol} ${this.$translate.instant('and')} ${level.upperBoundary}${symbol}`;
                } else if (level.upperBoundary != null) {
                    return `if ${this.$translate.instant('less than')} ${level.upperBoundary}${symbol}`;
                } else if (level.lowerBoundary != null) {
                    return `if ${this.$translate.instant('greater than')} ${level.lowerBoundary}${symbol}`;
                }
            }
        }
        public getTimeDisplayTooltip(timestamp: number) {
            return moment(timestamp).format('h:mm a');
        }
    }
    angular.module('aq.dashboard.widgets').controller('AlertCtrl', AlertCtrl);
}
