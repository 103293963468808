'use strict';

angular.module('aq.reports').controller('monthlyReportCtrl',
    function ($rootScope, $filter, $q, $scope, MonthlyCharts, ReportApiParams, ReportService, DataService, MonthlyOccupancyData) {

        $scope.loading = true;
        ReportService.then(function (report: aq.reports.Report) {
            $scope.report = report;
            let building = report.building;
            $scope.time = angular.copy(building.currentTime);
            $scope.time.subtract(1, 'month');

            let apiParams = new ReportApiParams($scope.time, report.measures);

            let waterBreakdownParams = new ReportApiParams($scope.time, report.measures, 'monthlyFull');
            let currentMonth = angular.copy($scope.time);

            let currentYearPromise = building.customGET('data', apiParams.current());
            let demandPromise = DataService.peaks(building, $scope.time, moment($scope.time).add(1, 'month'));

            let previousYearPromise = building.customGET('data', apiParams.previous());
            let waterBreakdownPromise = building.customGET('data', waterBreakdownParams.current());
            let waterBreakdownTempPromise = building.customGET('temperature', waterBreakdownParams.current());
            // TODO for now we must query temperature separately because /data endpoint doesn't support temperature
            // (it is from virtual meter which is excluded)
            let temperaturePromise = building.customGET('temperature', apiParams.current());
            let currentYearDegreeDaysPromise = DataService.degreeDays(building,
                apiParams.currentDegreeDay().interval,
                apiParams.currentDegreeDay().start,
                apiParams.currentDegreeDay().end);
            let previousYearDegreeDaysPromise = DataService.degreeDays(building,
                apiParams.previousDegreeDay().interval,
                apiParams.previousDegreeDay().start,
                apiParams.previousDegreeDay().end);

            function clearCurrentValuesByArrayIndex(currentValues: any, arrayIndex: number) {
                currentValues[report.usageMetric].values[arrayIndex] = null;
                currentValues[report.demandMetric].values[arrayIndex] = null;
                currentValues.degreeDays.cdd.values[arrayIndex] = 0;
                currentValues.degreeDays.hdd.values[arrayIndex] = 0;
                if (currentValues.temperature) {
                    currentValues.temperature[arrayIndex] = null;
                }
            }

            $q.all([
                currentYearPromise,
                previousYearPromise,
                waterBreakdownPromise,
                temperaturePromise,
                currentYearDegreeDaysPromise,
                previousYearDegreeDaysPromise,
                demandPromise,
                waterBreakdownTempPromise
            ]).then(function (result) {
                $scope.loading = false;
                $scope.monthIndex = currentMonth.format('M') - 1;
                let currentValues = result[0];
                let demandValues = result[6];
                let previousValues = result[1];
                let waterBreakdownValues = result[2];
                let temperatureValues = result[7] ? result[7].values : null;
                currentValues['temperature'] = result[3] ? result[3].values : null;
                currentValues['degreeDays'] = result[4];
                previousValues['degreeDays'] = result[5];

                $scope.occupancyData = MonthlyOccupancyData.getMonthlyOccupancy(building, $scope.monthIndex);

                $scope.usage = currentValues[report.usageMetric].values[$scope.monthIndex];

                /***
                 * Get current month from last year as previous usage, if it doesn't
                 * exist then take previous month from this year
                 */
                $scope.previousUsage = previousValues[report.usageMetric].values[$scope.monthIndex] ?
                    previousValues[report.usageMetric].values[$scope.monthIndex] :
                    currentValues[report.usageMetric].values[$scope.monthIndex - 1];

                /***
                 * Get current month from last year as previous demand, if it doesn't
                 * exist then take previous month from this year
                 */
                $scope.previousDemand = demandValues.previous.value;

                $scope.currentHDD = currentValues.degreeDays.hdd.values[$scope.monthIndex];
                $scope.currentCDD = currentValues.degreeDays.cdd.values[$scope.monthIndex];

                /***
                 * Get current month from last year as previous HDD/CDD, if it doesn't
                 * exist then take previous month from this year
                 */
                $scope.previousHDD = previousValues.degreeDays.hdd.values[$scope.monthIndex] ?
                    previousValues.degreeDays.hdd.values[$scope.monthIndex] :
                    currentValues.degreeDays.hdd.values[$scope.monthIndex - 1];

                $scope.previousCDD = previousValues.degreeDays.cdd.values[$scope.monthIndex] ?
                    previousValues.degreeDays.cdd.values[$scope.monthIndex] :
                    currentValues.degreeDays.cdd.values[$scope.monthIndex - 1];

                clearCurrentValuesByArrayIndex(currentValues, $scope.monthIndex + 1);

                let currentTemperature = $filter('arrayToTemperatureUnit')(currentValues.temperature, report.temperatureUnit);
                let currentData = {
                    data: currentValues,
                    temperature: currentTemperature,
                    year: result[0].start
                };

                let previousTemperature = $filter('arrayToTemperatureUnit')(previousValues.temperature, report.temperatureUnit);
                let previousData = {
                    data: previousValues,
                    temperature: previousTemperature,
                    year: result[1].start
                };


                let monthlyCharts = new MonthlyCharts(currentData, previousData, report, currentMonth);

                /* Usage chart data (both report types) */
                $scope.usageChart = monthlyCharts.getUsageChart(report.usageMetric, report.usageUnit);

                if (!report.isWaterOrGasReport) {
                    /* Demand chart data */
                    $scope.demandChart = monthlyCharts.getDemandChart();
                    $scope.demand = demandValues.current.value;

                    /* Weather chart data */
                    $scope.weatherChart = monthlyCharts.getWeatherChart();
                }

                if (report.isWaterOrGasReport) {
                    /* Water chart breakdown */
                    $scope.waterChart = monthlyCharts.getWaterBreakdownChart(waterBreakdownValues, temperatureValues, currentMonth.daysInMonth());
                    $scope.demand = Math.max.apply(null, waterBreakdownValues[report.demandMetric].values);
                    $scope.weatherChart = monthlyCharts.getWeatherChart();
                }

            });

        });

    });

