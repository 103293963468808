namespace aq.tenantbilling {

    export class EditInvoicingPeriodCtrl {
        tenantLabel: string;
        /* @ngInject */
        constructor(
            private $mdDialog: ng.material.IDialogService,
            private $mdStepper,
            private Messages: aq.services.Messages,
            private monthlyBilling,
            private tenantServices,
            private tenantCharges,
            private tenants,
            private enabledSettings: aq.tenantbilling.EnabledSettings,
            private Auth: aq.services.Auth
        ) {
            this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'unit' : 'tenant';
        }

        public cancel() {
            this.$mdDialog.cancel();
        }

        public previousStep() {
            this.$mdStepper('editPeriodStepper').back();
        }

        public nextStep() {
            this.$mdStepper('editPeriodStepper').next();
        }

        public apply() {
            this.enabledSettings.put()
                .then(() => {
                    this.$mdDialog.hide();
                })
                .catch(() => {
                    this.Messages.error('Failed to update enabled settings. Please try again or contact an administrator.');
                });
        }
    }


    angular.module('tenantBilling').controller('EditInvoicingPeriodCtrl', EditInvoicingPeriodCtrl);
}
