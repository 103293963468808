var aq;
(function (aq) {
    var ui;
    (function (ui) {
        var _ActivityMetaDisplay = /** @class */ (function () {
            /* @ngInject */
            function _ActivityMetaDisplay(RestangularV3, Restangular, DataStore, resolver, $scope) {
                this.RestangularV3 = RestangularV3;
                this.Restangular = Restangular;
                this.DataStore = DataStore;
                this.resolver = resolver;
                this.$scope = $scope;
                this.noContext = false;
            }
            _ActivityMetaDisplay.prototype.$onChanges = function (changes) {
                if (changes.activity) {
                    this.activity = angular.copy(changes.activity.currentValue);
                }
            };
            _ActivityMetaDisplay.prototype.$onInit = function () {
                var _this = this;
                if (aq.models.activity.ActivityContextType.NONE === this.activity.context.type) {
                    this.noContext = true;
                }
                this.alertLoaded = false;
                this.noData = false;
                this.contextLoaded = false;
                this.isPrescription = (aq.models.activity.ActivityContextType.RX === this.activity.context.type);
                if (this.isPrescription)
                    return;
                this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                    var _a, alertData, alertPromise, issuePromise, _b, alert_1, issue, err_1;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                _a = this;
                                return [4 /*yield*/, this.RestangularV3.one('buildings', this.activity.building).get()];
                            case 1:
                                _a.building = _c.sent();
                                this.isAidIssue = (aq.models.activity.ActivityContextType.AID_ISSUE === this.activity.context.type);
                                this.isEnergyNote = (aq.models.activity.ActivityContextType.ENERGY_NOTE === this.activity.context.type);
                                this.isOptimalStart = (aq.models.activity.ActivityContextType.OPTIMAL_START === this.activity.context.type);
                                if (!(this.activity.context.type === aq.models.activity.ActivityContextType.ALERT)) return [3 /*break*/, 10];
                                _c.label = 2;
                            case 2:
                                _c.trys.push([2, 9, , 10]);
                                return [4 /*yield*/, this.RestangularV3.one('alert-data', this.activity.context.alert).get()];
                            case 3:
                                alertData = _c.sent();
                                if (!(AlertType[alertData.alertType] === AlertType.REAL_TIME_ENERGY)) return [3 /*break*/, 7];
                                return [4 /*yield*/, this.RestangularV3
                                        .one('usage-alerts', alertData.alertId)
                                        .get()];
                            case 4:
                                alertPromise = _c.sent();
                                return [4 /*yield*/, this.RestangularV3
                                        .one('building-alert-histories', this.activity.context.issue)
                                        .one('summary')
                                        .get()];
                            case 5:
                                issuePromise = _c.sent();
                                return [4 /*yield*/, Promise.all([alertPromise, issuePromise])];
                            case 6:
                                _b = _c.sent(), alert_1 = _b[0], issue = _b[1];
                                if (!alert_1.drillMode) {
                                    alert_1.drillMode = 'BUILDING';
                                }
                                if (!alert_1.queryableId && alert_1.queryable) {
                                    alert_1.queryableId = alert_1.queryable.id;
                                }
                                this.realTimeEnergyAlert = alert_1;
                                this.realTimeEnergyAlert.issue = issue;
                                // setting openedOn and closedOn to values that make sense for activity charting.
                                // Used directly in alert-preview component
                                this.realTimeEnergyAlert.issue.closedOn = moment(this.realTimeEnergyAlert.issue.openedOn)
                                    .add(4, 'hours')
                                    .toISOString();
                                this.realTimeEnergyAlert.issue.openedOn = moment(this.realTimeEnergyAlert.issue.openedOn)
                                    .subtract(4, 'hours')
                                    .toISOString();
                                this.alertLoaded = true;
                                return [3 /*break*/, 8];
                            case 7:
                                this.isStateAlert = true;
                                this.alertLoaded = true;
                                _c.label = 8;
                            case 8:
                                // AngularJS is not smart enough to follow chains of await calls so we need to manually tell it to update the UI with new data
                                this.$scope.$apply();
                                return [3 /*break*/, 10];
                            case 9:
                                err_1 = _c.sent();
                                this.noDataCallback();
                                throw err_1;
                            case 10: return [2 /*return*/];
                        }
                    });
                }); });
            };
            _ActivityMetaDisplay.prototype.noDataCallback = function () {
                this.noData = true;
                if (!this.isPrescription) {
                    this.$scope.$apply();
                }
            };
            _ActivityMetaDisplay.prototype.contextLoadedCallback = function () {
                this.contextLoaded = true;
                if (!this.isPrescription) {
                    this.$scope.$apply();
                }
            };
            _ActivityMetaDisplay.prototype.refreshActivitiesAndCloseDialog = function () {
                this.refreshActivities();
                this.closeDialog();
            };
            return _ActivityMetaDisplay;
        }());
        ui.ActivityMetaDisplay = {
            controller: _ActivityMetaDisplay,
            controllerAs: 'vm',
            templateUrl: 'app/common/components/activities/contexts/activityMetaDisplay.html',
            bindings: {
                activity: '<',
                accountId: '<',
                refreshActivities: '&',
                closeDialog: '&'
            }
        };
        var AlertType;
        (function (AlertType) {
            AlertType["STATE"] = "STATE";
            AlertType["REAL_TIME_ENERGY"] = "REAL_TIME_ENERGY";
        })(AlertType = ui.AlertType || (ui.AlertType = {}));
        angular
            .module('aq.ui')
            .component('activityMetaDisplay', ui.ActivityMetaDisplay);
    })(ui = aq.ui || (aq.ui = {}));
})(aq || (aq = {}));
