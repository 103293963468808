angular
    .module('aq.admin.platformSettings', [])
    .config(($stateProvider, $urlRouterProvider) => {
        $urlRouterProvider.when('/admin/platformSettings', '/admin/platformSettings/logs');
        $stateProvider
            .state('aq.admin.platformSettings', {
                url: '/platformSettings',
                template: '<ui-view></ui-view>',
                data: {
                    appName: 'Global Defaults'
                }
            })
            .state('aq.admin.platformSettings.logs', {
                url: '/logs',
                template: '<div />',
                resolve: {
                    redirect:
                        ($q: ng.IQService, RedirectService: aq.services.RedirectService) => {
                            const defer = $q.defer();
                            const destination = 'admin/platformSettings/logs';
                            RedirectService.redirectWithoutParams(destination);
                            return defer.promise;
                        }
                },
                data: {
                    breadcrumb: 'Logs',
                    icon: '.aq-icons-logs'
                }
            })
            .state('aq.admin.platformSettings.setup', {
                url: '/setup',
                template: '<div />',
                resolve: {
                    redirect:
                        ($q: ng.IQService, RedirectService: aq.services.RedirectService) => {
                            const defer = $q.defer();
                            const destination = 'admin/platformSettings/setup';
                            RedirectService.redirectWithoutParams(destination);
                            return defer.promise;
                        }
                },
                data: {
                    breadcrumb: 'Setup',
                    icon: '.aq-icons-setup'
                }
            });
    });
