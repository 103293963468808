namespace aq.dashboard {
    export class DashboardMainCtrl {
        public dashboardFilter: string;
        /* @ngInject */
        constructor(
            protected $scope: DashboardMainCtrlScope,
            protected account: aq.common.models.Account,
            protected dashboards: Array<aq.dashboard.models.Dashboard | aq.dashboard.models.DashboardElement>,
            protected $mdDialog: ng.material.IDialogService,
            protected Messages: aq.services.Messages,
            protected $state: ng.ui.IStateService,
            protected loading,
            protected $timeout: ng.ITimeoutService,
            protected AppStorageService: aq.services.AppStorageService,
            private Auth: aq.services.Auth
        ) {
            _.each(this.dashboards, (d) => d.state = 'aq.dashboard.configurable');
            this.dashboardFilter = '';
            $scope.menu = {
                dashboards,
                account,
                open: false
            };
            $scope.showNewDashboardButton = true;
            $scope.$watch('menu.open', (open) => {
                // broadcast after our sidenav resizes, we have a 0.5sec animation
                this.$timeout(() => {
                    this.$scope.$broadcast('highchartResize');
                }, 500);
            });
            this.$scope.$on('dataSelectedEmit', ($event, message) => {
                $event.stopPropagation();
                this.$scope.$broadcast('dataSelectedBroadcast', message);
            });
            this.$scope.$on('dashboardUpdatedEmit', ($event, data) => {
                $event.stopPropagation();
                if (data.id && data.name) {
                    const updatedDashboard = _.find(this.dashboards, (d) => { return d.id == data.id; });
                    if (updatedDashboard) {
                        updatedDashboard.name = data.name;
                    }
                }
            });
            this.$scope.$on('dashboardDeletedEmit', ($event, data) => {
                $event.stopPropagation();
                if (data.id) {
                    _.remove(this.dashboards, (d) => { return d.id == data.id; });
                    if (this.dashboards.length == 0) {
                        this.$state.go('aq.dashboard.noAvailableDashboards');
                    } else {
                        this.$state.go('aq.dashboard.configurable', { dashboardId: _.first(this.dashboards).id });
                    }
                }
            });
            this.$scope.$on('newDashboardEmit', ($event, data) => {
                if (data) {
                    const createdDashboard = data.dashboard;
                    createdDashboard.state = 'aq.dashboard.configurable';
                    createdDashboard.isHidden = createdDashboard.name.toLowerCase().indexOf(this.dashboardFilter.toLowerCase()) == -1;
                    this.dashboards.push(createdDashboard);
                    this.$state.go(createdDashboard.state, { dashboardId: createdDashboard.id });
                }
            });
        }
        public isDefaultDashboard(id) {
            const storage = this.AppStorageService.getStorage();
            return storage && storage.dashboardId == id;
        }
        public linkClicked = (dashboardId) => {
            if (dashboardId == this.$state.params.dashboardId) {
                return;
            }
            this.$scope.menu.open = false;
            this.loading.start();
            this.$scope.$broadcast('abortLoading');
        }
        public filterDashboards() {
            const searchTerm = this.dashboardFilter.toLowerCase();
            _.each(this.dashboards, (d) => {
                d.isHidden = d.name.toLowerCase().indexOf(searchTerm) == -1;
            });
        }
        public clearDashboardFilter() {
            this.dashboardFilter = '';
            this.filterDashboards();
        }
    }

    export interface DashboardMainCtrlScope extends ng.IScope {
        menu: {
            open: boolean,
            dashboards: aq.dashboard.models.Dashboard[],
            account: aq.common.models.Account
        };
        loading: any;
        showNewDashboardButton: boolean;
        allowSelectDefault: boolean;
        user: aq.common.models.User;
    }

    angular.module('dashboard').controller('DashboardMainCtrl', DashboardMainCtrl);
}
