angular.module('aq.services.cache', ['restangular']).factory('HTTPCache', ['Restangular', '$cacheFactory',
  function(Restangular, $cacheFactory) {
    var service: any = {};
    var cache = $cacheFactory('http');

    // Creates the cache
    service.init = function() {
        Restangular.setDefaultHttpFields({cache: cache});

        Restangular.addResponseInterceptor(function(response, operation) {
           if (operation === 'put' || operation === 'post' || operation === 'remove') {
               cache.removeAll();
           }
           return response;
        });
    }

    service.removeAll = function() {
        cache.removeAll();
    }

    return service;

}]);
