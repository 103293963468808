angular.module('aq.admin.meters.meters').controller('AdminMetersCtrl',
    function($scope, Restangular, Errors, Messages, $modal, $q, Pager, $aq) {

    $scope.modal = {};
    $scope.modal2 = {};

    $scope.sending = false;

    $scope.deleteData = {
        fromTime: "00:00:00",
        toTime: "00:00:00",
        aggregations: []
    }

    // TODO - this should be removed once app is fully on angular, account object
    // should be fetched from home app
    Restangular.all('Accounts').get('queryAllAccounts').then(function(accounts) {

        Restangular.one('users', 'me').get().then(function(user) {
            $scope.selectedAccount = _.find(accounts, { id: $scope.account.id });
            $scope.getAccountMeters($scope.selectedAccount);
        });
        $scope.accounts = accounts;

    });

    $scope.deleteReplication = function(replication, meter) {
        meter = getMeterRestangularized(meter.primaryBuilding.account, meter.primaryBuilding, meter);
        var replication = Restangular.restangularizeElement(meter, replication, 'replications');
        $aq.delete(meter.replications, replication);
    }

    $scope.getLastReport = function(meter) {

        var last = moment(meter.lastReportedTime);
        var current = moment();
        var diff = current.diff(last);

        return diff < 20000 ?
            true :
            moment(meter.lastReportedTime).fromNow();
    }

    function getMeterRestangularized(account, buildingId, meter) {
        var account = Restangular.restangularizeElement(null, account, 'accounts');
        var building = account.one('buildings', buildingId);
        return Restangular.restangularizeElement(building, meter, 'collectors');
    }

    $scope.deleteMeterWithData = function (dismiss, meter, confirmation) {

        const meterObj = getMeterRestangularized(meter.primaryBuilding.account, meter.primaryBuilding, meter);

        meterObj.remove({confirmation: confirmation}).then(function (result) {
            dismiss();
            _.withoutInline($scope.meters, meter);
        }, Errors.forPromise());

    }

    $scope.addReplication = function(meter, multiplier, sn) {
        meter = getMeterRestangularized(meter.primaryBuilding.account, meter.primaryBuilding, meter);

        var replication = {
            multiplier: multiplier,
            deviceIdentifierOverride: sn,
            collector: meter.id
        }

        meter.post('replications', replication).then(function(response) {
            meter.replications.push(response.model);
        }, Errors.forPromise());

    }

    var inflateMeter = 'primaryBuilding,primaryBuilding.account,replications';
    $scope.search = function(search) {
        if (!search) {
            $scope.getAccountMeters($scope.selectedAccount);
        } else {
            Restangular.all('accounts').get('queryMeters', { id: $scope.selectedAccount.id, search: search, inflate: inflateMeter }).then(function(meters) {
                $scope.meters = meters;
            }, Errors.forPromise());
        }
    }

    $scope.getAccountMeters = function(account) {
        $scope.meters = [];

        $scope.scroller = Pager.scroller(1, function(page) {
            var params = {
                page: page, inflate:
                inflateMeter,
                excludeClasses: ['VIRTUAL_METER', 'MANUAL_UPLOAD', 'WORLD_WEATHER_ONLINE', 'GENERIC_POWER_METER', 'GENERIC_WATER_METER', 'GENERIC_GAS_METER']
            }
            return Restangular.one('accounts', account.id).getList('queryAllCollectors', params);
        }, function(meters) {
            $scope.meters = $scope.meters.concat(meters);
        },Errors.forPromise());
        $scope.scroller.nextPage();
    }

    var modal = $modal({
        templateUrl: 'app/admin/custom/directives/modalFieldParser/modal.html',
        persist: true,
        show: false,
        backdrop: 'static',
        scope: $scope });

    $scope.showModalViaService = function(model) {
        $scope.modal.model = model.originalElement;
        $scope.modal.title = model.name;
        modal.$promise.then(modal.show);
    };

    $scope.showModal = function(meter, templatePath) {
        var modal2 = $modal({
            templateUrl: templatePath,
            persist: true,
            show: false,
            backdrop: 'static',
            scope: $scope
        });
        $scope.meter = meter;
        modal2.$promise.then(modal2.show);
    }

});
