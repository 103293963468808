namespace aq.utilityBudgets {
    export class UtilityAccountDetailsChangelog {
        public currentNavItem: string;
        public changelogViewItems: UrjanetEnrollmentChangelog[]

        /* @ngInject */
        constructor(
            private urjanetEnrollmentChangelog: UrjanetEnrollmentChangelog[]
        ) {
            this.changelogViewItems = _.sortBy(urjanetEnrollmentChangelog, ['timestamp']).reverse();

            this.currentNavItem = 'changelog';
        };

        public isSelectedTab(name) {
            return this.currentNavItem == name;
        }
    }
    angular.module('aq.utilityBudgets').controller('UtilityAccountDetailsChangelog', UtilityAccountDetailsChangelog);
}
