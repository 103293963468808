var aq;
(function (aq) {
    var utilityBudgets;
    (function (utilityBudgets) {
        var ShareUtilityAccount = /** @class */ (function (_super) {
            __extends(ShareUtilityAccount, _super);
            /* @ngInject */
            function ShareUtilityAccount($mdDialog, buildings, buildingId, utilityAccountBuildingIds) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.buildings = buildings;
                _this.buildingId = buildingId;
                _this.utilityAccountBuildingIds = utilityAccountBuildingIds;
                _this.buildingIdToName = [];
                var visibleBuildingIds = _this.buildings
                    .map(function (visibleBuilding) { return Number(visibleBuilding.id); });
                _this.buildingsForUtilityAccount = _this.utilityAccountBuildingIds
                    .filter(function (id) { return id != _this.buildingId; })
                    .filter(function (id) { return visibleBuildingIds.indexOf(id) > -1; });
                _this.buildings.forEach(function (visibleBuilding) {
                    _this.buildingIdToName[Number(visibleBuilding.id)] = visibleBuilding.name;
                });
                return _this;
            }
            ShareUtilityAccount.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            ShareUtilityAccount.prototype.hide = function (result) {
                this.$mdDialog.hide(result);
            };
            ShareUtilityAccount.prototype.save = function () {
                var _this = this;
                var removeBuildings = this.utilityAccountBuildingIds
                    .filter(function (id) { return id != _this.buildingId; })
                    .filter(function (id) { return _this.buildingsForUtilityAccount.indexOf(id) < 0; });
                var addBuildings = this.buildingsForUtilityAccount
                    .filter(function (id) { return _this.utilityAccountBuildingIds.indexOf(id) < 0; });
                this.hide({ addBuildings: addBuildings, removeBuildings: removeBuildings });
            };
            ShareUtilityAccount.prototype.queryBuildings = function (searchText) {
                var _this = this;
                return this.buildings
                    .filter(function (building) { return building.id != _this.buildingId; })
                    .filter(function (building) { return building.name.toLocaleLowerCase().indexOf(searchText.toString().toLowerCase()) > -1; });
            };
            return ShareUtilityAccount;
        }(aq.common.Controllers.ModalCtrl));
        utilityBudgets.ShareUtilityAccount = ShareUtilityAccount;
        angular.module('aq.utilityBudgets').controller('ShareUtilityAccount', ShareUtilityAccount);
    })(utilityBudgets = aq.utilityBudgets || (aq.utilityBudgets = {}));
})(aq || (aq = {}));
