namespace aq.utilityBudgets {
    export class AccrualViewModalCtrl {
        constructor(
            private accrual: aq.utilityBudgets.AccrualResponse,
            private settings: aq.utilityBudgets.AccrualViewSettings
        ) {
        }
    }
    angular.module('aq.utilityBudgets').controller('AccrualViewModalCtrl', AccrualViewModalCtrl);
}
