angular.module('properties.sources').factory('SourcesList', function () {
  return {
        get: function (building) {
            // currently I have hardcoded level of child sources inflate, it is 3 level down, can we have even more ?
            var inflate1stLvl = 'childSources,childSources.sourceClass,';
            var inflate2ndLvl = 'childSources.childSources,childSources.childSources.sourceClass,';
            var inflate3rdLvl = 'childSources.childSources.childSources,childSources.childSources.childSources.sourceClass,';
            var inflate4thLvl = 'childSources.childSources.childSources.childSources,childSources.childSources.childSources.childSources.sourceClass';
            var inflate5thLvl = 'childSources.childSources.childSources.childSources.childSources,childSources.childSources.childSources.childSources.childSources.sourceClass';

            var inflate = 'sourceClass,circuits,circuits.resource,metric,' + inflate1stLvl + inflate2ndLvl + inflate3rdLvl + inflate4thLvl + inflate5thLvl;

            return building.getList('sources', { inflate: inflate }).then(function(sources) {

                var allMapped = _.filter(sources, function (s) { return s.mapped == true; });

                _.each(allMapped, function  (source) {
                    var level = -1;
                    // update levels for children
                    function setExpanded(items) {
                        level = level + 1;
                        _.each(items, function(item) {
                            if (!item) return;
                            item.level = level;
                            if (item && item.childSources) {
                                setExpanded(item.childSources);
                            }
                        })
                    }
                    setExpanded([source]);
                })

                return {
                    mapped: {
                        childSources: allMapped,
                    },
                    unmapped: _.filter(sources, function (s) { return s.mapped == false; })
                }

            })
        }
    };
});