namespace aq.admin.configuration.utilityCompany {
    export class UtilityCompanyBuildingsCtrl {

        /* @ngInject */
        constructor(
            private $state: ng.ui.IStateService,
            private utilityCompany: UtilityCompany,
            private utilityCompanyBuildings: UtilityCompanyBuilding[]
        ) {
        }

        clickThrough(item: UtilityCompanyBuilding) {
            this.$state.go('aq.utilityBudgets.utilityaccounts', {accountId: item.accountId, buildingId: item.id});
        }
    }

    interface UtilityCompanyBuilding {
        id: number;
        accountId: number;
        accountName: string;
        name: string;
    }

    angular
        .module('utilityCompany')
        .controller('UtilityCompanyBuildingsCtrl', UtilityCompanyBuildingsCtrl);
}
