namespace aq.tenantbilling {
    // copied from ChargeValueRequiresActionDialogCtrl.ts
    export class ReadingsTabCtrl {
        public manualPreviousReading;
        public manualCurrentReading;
        public manualQuantity;
        public newQuantity;
        public previousDisplayReading;
        public currentDisplayReading;
        public quantityPattern;
        public isSteam;
        public isAqAdmin: boolean;
        public currencyUnit: any;
        private chargeValue: TenantChargeValue;
        private monthlyBilling: MonthlyTenantBilling;
        private readOnly: boolean;
        private collectorHierarchy;
        private building: aq.common.models.Building;
        private device: aq.common.models.Device;
        /* @ngInject */
        constructor(
            public $mdDialog: ng.material.IDialogService,
            public $filter: ng.IFilterService,
            public OptionsService,
            private UserService
        ) {
            const steamUnit = this.OptionsService.getUnitLabelByMeasure('STEAM');
            this.quantityPattern = '\\d+';
            if (steamUnit.unit === this.chargeValue.usageUnit) {
                this.isSteam = true;
                this.quantityPattern = '';
            }

            this.previousDisplayReading = this.chargeValue.previousReading;
            this.currentDisplayReading = this.chargeValue.currentReading;

            this.newQuantity = this.$filter<Function>('formatQuantity')
                (this.chargeValue.total, this.chargeValue.usageUnit);
            this.manualPreviousReading = this.$filter<Function>('formatQuantity')
                (this.chargeValue.previousReading, this.chargeValue.usageUnit);
            this.manualCurrentReading = this.$filter<Function>('formatQuantity')
                (this.chargeValue.currentReading, this.chargeValue.usageUnit);
            this.manualQuantity = this.chargeValue.manualQuantity;
            this.isAqAdmin = this.UserService.currentUser.userType === 'ADMINISTRATOR';
            this.currencyUnit = this.OptionsService.currencyUnit();
        }

        public save() {
            if (this.readOnly) {
                return;
            }
            if (!this.chargeValue.automatedReading || this.chargeValue.manualReading) {
                this.chargeValue.manualQuantity = this.manualQuantity;
                this.chargeValue.total = this.newQuantity;
                this.chargeValue.previousReading = this.chargeValue.previousReading ? this.chargeValue.previousReading : 0;
                this.chargeValue.currentReading = this.chargeValue.previousReading + this.newQuantity;
                if (this.chargeValue.manualReading) {
                    this.chargeValue.previousReading = this.manualPreviousReading;
                    this.chargeValue.currentReading = this.manualCurrentReading;
                    this.chargeValue.total = (this.manualCurrentReading - this.manualPreviousReading) * this.chargeValue.meterMultiplier;
                }
            }
            this.$mdDialog.hide(this.chargeValue);
        }

        public cancel() {
            this.$mdDialog.cancel();
        }

        public changeReadingType() {
            if (!this.chargeValue.automatedReading) {
                this.manualQuantity = true;
            }
        }

        public isNil(value) {
            return _.isNil(value);
        }

    }

    angular
        .module('tenantBilling')
        .component('readingsTab', {
            templateUrl: 'app/tenantBilling/invoicing/run/editChargeValue/components/readingsTab/readingsTab.html',
            controller: ReadingsTabCtrl,
            controllerAs: 'vm',
            bindings: {
                chargeValue: '<',
                monthlyBilling: '<',
                readOnly: '<?',
                building: '<',
                collectorHierarchy: '<',
                device: '<'
            }
        });
}
