namespace aq.settings {

    export class EnergyUse {
        name: string;
        metric: string;

        constructor(name = '', metric = '') {
            this.name = name;
            this.metric = metric;
        }
    }

}
