namespace aq.projects {
    export class ProjectsController {
        /* @ngInject */
        constructor(
            private buildings,
            private $scope,
            private $state,
            private buildingId,
            private account: aq.common.models.Account,
            private building: aq.common.models.Building,
            private RedirectService: aq.services.RedirectService
        ) { }

        private redirect(destination: string) {
            this.RedirectService.redirect({
                account: +this.account.id,
                building: +this.buildingId,
                profile: this.$scope.user.currentProfile.id,
                destination,
            })
        }
    }

    angular
        .module('projects')
        .controller('ProjectsController', ProjectsController);
}
