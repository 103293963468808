angular.module('aq-main').run(function($httpBackend) {
    const regex = /^\/api\/v1\/devices\/classifications\/?$/;

    const classifications = {
        'METER': {
            'Aquicore': {
                'Power-1': ['MP2E1', 'SP2E1'],
                'Power-2': ['MP2E2', 'SP2E2'],
                'Generic Power Meter': [],
                'Generic Water Meter': [],
                'Generic Gas Meter': [],
                'Generic Steam Meter': [],
                'Generic BTU Meter': []
            }
        },
        'NETWORK_APPLIANCE': {
            'Aquicore': {
                'AQ-Connect': ['LP4', 'MLP4', 'MP2', 'MP25', 'SP4'],
                'Gateway': ['GCLL', 'GCOMP2', 'GCSS', 'GCTT', 'GOMP2', 'GSS']
            }
        },
        'SENSOR': {
            'Aquicore': {
                'Element': ['v1'],
                'Air': ['v1']
            }
        },
        'EQUIPMENT': {},
        'OTHER': {
            'Aquicore': {
                'Generic Power Meter': [],
                'Generic Water Meter': [],
                'Generic Gas Meter': [],
                'Generic Steam Meter': [],
                'Generic BTU Meter': []
            }
        }
    };

    function getClassifications(deviceCategory?) {
        return deviceCategory ? classifications[deviceCategory] : classifications;
    }

    function addClassification(data) {
        // no destructuring makes me a sad boy
        // categories are not user creatable so we skip validating them
        data = JSON.parse(data);
        if (data.make && !classifications[data.category][data.make]) {
            classifications[data.category][data.make] = {};
        }
        if (data.series && !classifications[data.category][data.make][data.series]) {
            classifications[data.category][data.make][data.series] = [];
        }
        if (data.model && classifications[data.category][data.make][data.series].indexOf(data.model) < 1) {
            classifications[data.category][data.make][data.series].push(data.model);
        }
        return getClassifications();
    }

    // get all of em
    MockContext('devicemgmt', () => {
        $httpBackend.whenGET(regex).respond((method, url, data) => {
            return [200, getClassifications(), {}];
        });

        // get a specific one of em
        // $httpBackend.whenGET(regex).respond(function(method, url, data) {
        //     return [200, 'true', getClassifications()];
        // });

        // create a new one
        $httpBackend.whenPOST(regex).respond((method, url, data) => {
            // do some manipulation and return the new structure
            return [200, addClassification(data), {}];
        });
    });
});
