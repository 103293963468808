/**
 * This file is connection to all enums available on backend
 * TODO - check if there is automatic way to generate ts files from java
 * files that contains available enums (https://www.npmjs.com/package/ts-java)
 */
namespace aq.common {

    export module Enums {

        /**
         * This is label of Realunit object (2nd parameter in RealUnit enum)
         */
        export enum RealUnit {
            KW,
            KWH,
            KWSQFT,
            KWHSQFT,
            KBTUSQFT,
            CURRENCY_ELECTRICITY,
            CURRENCY_ELECTRICITY_SUBUNIT,
            CURRENCY_WATER,
            CURRENCY_SUBUNIT_WATER,
            CURRENCY_GAS,
            CURRENCY_SUBUNIT_GAS,
            CURRENCY_AREA,
            CCF,
            GPM,
            WATER_GAL,
            WATER_M3,
            KWM2,
            KJM2,
            KWHM2,
            M3,
            M3H,
            MLBS,
            MLBS_PER_HOUR,
            KBTU,
            KBTU_PER_HOUR,
            ENV_CELSIUS,
            ENV_FAHRENHEIT,
            PPM,
            PERCENT
        }

        export enum DrillMode {
            SOURCE,
            METER,
            SPACE,
            USES,
            TENANT
        }

        export enum ServiceType {
            ELECTRICITY,
            GAS,
            WATER,
            STEAM,
            HEAT,
            ENVIRONMENT
        }

        export enum ApiUnit {
            ENERGY,
            PULSE,
            POWER,
            VOLTS,
            CURRENCY_ELECTRICITY,
            CURRENCY_GAS,
            CURRENCY_WATER,
            LITERS,
            TEMPERATURE,
            GAS,
            WATER,
            FLOW_RATE,
            STEAM_MASS,
            HEAT_TRANSFER,
            HEAT_TRANSFER_RATE,
            ENV_TEMPERATURE,
            RELATIVE_HUMIDITY,
            K30_CO2
        }

        // TODO - implement enum objects here, once feature is discussed and developed
        // https://github.com/Microsoft/TypeScript/issues/1206
        export enum ApiInterval {
            RAW, //("raw"),
            ONE_MINUTE, //("1min"),
            FIFTEEN_MINUTES, //("15min"),
            ONE_HOUR, //("1h"),
            ONE_DAY, //("1d"),
            ONE_MONTH //("1mon")
        }

        enum MeasurementSystem {
            METRIC,
            US_CUSTOMARY_UNITS
        }

        export interface MeasurementSystemObject {
            areaMeasurementUnit: string,
            energyUseIntensityUnit: string,
            gasUnit: string;
            label: string,
            temperatureUnit: string,
            value: MeasurementSystem,
            waterFlowRateUnit: 'M3H',
            steamRateUnit: 'MLBS_PER_HOUR',
            heatRateUnit: 'KBTU_PER_HOUR',
            waterUnit: 'WATER_M3',
            steamUnit: string;
            heatUnit: string;
            co2Unit: string;
            humidityUnit: string;
        }

    }

}
