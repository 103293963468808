angular
    .module('aq.admin.dataViewer', [])
    .provider('runtimeStates', function runtimeStates($stateProvider) {
        // service which will add states dynamically on the runtime or
        // through the call via controller
        let last = 'aq.admin.dataViewer';
        const provider = {
            $get: function() {
                return {
                    addState: function(name, url, data) {
                        last += '.' + name;
                        $stateProvider.state(last, {
                            url: '/' + url,
                            controller: 'DataViewerCtrl',
                            templateUrl: 'app/admin/dataViewer/main/dataViewer.html',
                            data: data,
                        });
                    },
                    last: function() {
                        return last;
                    }
                }
            }
        }
        return provider;
    })
    .config(function ($stateProvider) {

        $stateProvider
            .state('aq.admin.dataViewer', {
                url: '/dataViewer/accounts',
                templateUrl: 'app/admin/dataViewer/main/dataViewer.html',
                controller: 'DataViewerCtrl',
                data: {
                    appName: 'Data Viewer'
                }
            })

    }).run(function ($state, $location, Restangular, runtimeStates) {

        var path = $location.$$path;
        var paths = path.replace('/admin/dataViewer/', '').split('/'); // extract path after initial path
        var currentResolver; // this will be filled with object or list, depending on url path

        // run this only if data viewer opened
        if ($location.$$path.indexOf('/admin/dataViewer') > -1 ) {

            // iterate through paths and create states
            _.each(paths, function (value, index) {
                // skip processing on first level since accounts level should be loaded by default
                if (index == 0) return;
                function setState(path, currentResolver, parentObject, type) {
                    var stateName = value.toLowerCase();
                    var url = path;
                    var dataObj = {
                        appName: path,
                        current: currentResolver,
                        type: type,
                        parent: parentObject
                    }
                    runtimeStates.addState(stateName, url, dataObj);
                }

                if (index % 2) {
                    // handle object
                    currentResolver = Restangular.one('accounts', value);
                    setState(value, currentResolver.get({single: true}), null, 'object');
                } else {
                    // handle list
                    setState(value, currentResolver.getList(value), currentResolver.get({single: true}), 'list');
                }

            })
        }


    })
