namespace aq.propertySettings {
    export class DataCollectorsCtrl {
        constructor(
            private $scope,
            private $state,
            private $location,
            private $tooltip,
            private $modal,
            private Restangular,
            private Errors,
            private Messages,
            private DataCollectorList,
            private PropertySettingsOptions,
            private loading,
            private $interval,
            private $translate,
            private $mdDialog,
            private accountId,
            private buildingId,
            private $q,
            private $filter,
            private dataCollectors,
            private authAccess,
            private building
        ) {

            this.$scope.propertySettingsAccess = authAccess['Property Settings'];

            this.$scope.building = this.building;

            this.$scope.waitingResponse = false;
            this.$scope.esLinkedCollectors = [];

            this.$scope.isEsLinked = (collectorId) => {
                return _.includes(this.$scope.esLinkedCollectors, collectorId);
            }

            this.PropertySettingsOptions.init(this.$scope.account).then((options) => {
                this.$scope.options = this.PropertySettingsOptions.get();
            })

            this.$scope.openBulkUpdate = (ev) => {
                var collectors = [];
                this.$scope.dataCollectors.forEach((collector) => {
                    this.getCollectors(collectors, collector);
                });
                collectors = _.flatMap(collectors);
                this.$mdDialog.show({
                    locals: {
                        collectors: collectors,
                        accountId: accountId,
                        buildingId: buildingId
                    },
                    controller: "BulkUpdateCollectorsCtrl",
                    controllerAs: "bulkUpdateCollectors",
                    templateUrl: "app/properties/dataCollectors/main/bulkUpdateCollectors.html",
                    parent: angular.element(document.body),
                    targetEvent: ev,
                    clickOutsideToClose: true
                }).then((bulkUpdateData) => {
                    this.Restangular.one("accounts", accountId).one("buildings", buildingId).all("collectors").all('bulkUpdate').customPOST({ collectors: bulkUpdateData.collectors }, "", { applyMultiplierToAllData: bulkUpdateData.applyMultiplierToAllData }).then(() => {
                        this.Messages.success("Successfully updated all collectors");
                    }).catch(() => {
                        this.Messages.error("Error updating collectors");
                    });
                });
            }

            this.$scope.addCollectorModal = (title, collector) => {
                this.$scope.newCollector = {};
                if (collector) {
                    this.$scope.newCollector = {
                        parentCollector: collector
                    };
                }
                this.$scope.typeTitle = title;
                this.$mdDialog.show({
                    preserveScope: true,
                    controller: "AddCollectorModalCtrl as addCollectorModalCtrl",
                    scope: this.$scope,
                    templateUrl: "app/properties/dataCollectors/main/modals/modal-add-collector.html",
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    locals: {
                        building: this.$scope.building
                    },
                    resolve: {
                        unassignedPulsePoints: () => {
                            if (collector) {
                                return this.Restangular.one("accounts", accountId).one("buildings", buildingId).one('collectors', collector.id).customGET('queryUnassignedPulsePoints');
                            }
                            return [];
                        }
                    }
                }).then(() => {
                    this.$scope.addCollector(this.$scope.newCollector.parentCollector, this.$scope.newCollector);
                    this.Messages.success("Successfully added collector.");
                });
            }

            this.$scope.setup = (collector) => {
                this.$state.transitionTo('aq.properties.buildings.datacollectors.detail', {
                    accountId: this.$scope.account.id,
                    buildingId: this.$scope.building.id,
                    dataCollectorId: collector.id
                });
            }

            var updateCollectors = (parent?, collector?) => {
                if (collector && parent) {
                    parent.childCollectors.push(collector);
                }
                this.$scope.dataCollectors = _(dataCollectors).filter((collector) => {
                    return collector.collectorClass !== 'VIRTUAL_METER';
                }).sortBy((collector) => {
                    return ['MANUAL', 'WEB_CONNECTOR', 'GATEWAY'].indexOf(collector.type) + collector.collectorClass + collector.name;
                }).value();

                this.$scope.virtualCollectors = _.filter(dataCollectors, (collector) => {
                    return collector.collectorClass == 'VIRTUAL_METER';
                });

                this.$scope.possibleParentCollectors = _.filter(this.$scope.dataCollectors, (collector) => {
                    return collector.downwardCommunications != null;
                });

                this.$scope.flatCollectors = $filter("flattenHierarchy")(dataCollectors, 'childCollectors').filter((collector) => {
                    return collector.isPhysicalMeter;
                });

                _.each(this.$scope.possibleParentCollectors, (collector) => {
                    // restangularize collector childs
                    Restangular.restangularizeCollection(Restangular.one("accounts", accountId).one("buildings", buildingId), collector.childCollectors, "collectors");

                    // add collector child to list of posible parents
                    _.each(collector.childCollectors, (collector) => {
                        if (collector.downwardCommunications != null) {
                            this.$scope.possibleParentCollectors.push(collector)
                        }
                    });
                });
                return this.$scope.dataCollectors;
            }

            updateCollectors();

            var queryStatus = () => {
                var collectorIds = _.map($filter('flattenHierarchy')(this.$scope.dataCollectors, 'childCollectors'), (c) => {
                    return c.id
                });
                Restangular.one("accounts", accountId).one("buildings", buildingId).customGET('queryCollectorStatus', { nocache: new Date().getTime(), collectors: collectorIds.join(',') }).then((result) => {
                    this.$scope.status = result;
                })
            }

            this.$scope.$watch('building', (building) => {
                if (!building) return;

                queryStatus();

            })

            this.$scope.addCollector = (parent, child) => {
                if (child.collectorClass === 'EXISTING') {
                    return child.targetMeter.customPUT(null, 'addFacility', { buildingId: this.$scope.building.id }).then(() => {
                        updateCollectors();
                    }, this.Errors.forPromise());
                } else {
                    var params: any = {};
                    if (child.parentPoint) {
                        params.parentPointId = child.parentPoint.id;
                    }
                    child.childCollectors = [];
                    child.primaryBuilding = this.$scope.building.id;
                    child.parentCollector = parent ? parent.id : null;

                    return Restangular.one("accounts", accountId).one("buildings", buildingId).post('collectors', child, params).then((result) => {
                        updateCollectors(parent, result);
                    }, this.Errors.forPromise());
                }
            }

            var queryStatusPromise = this.$interval(queryStatus, 300000);

            this.$scope.isIdentifierRequired = (collectorClassName) => {
                var collectorClass = _.find(this.$scope.options.collectorClasses, { 'name': collectorClassName });
                if (collectorClass == null) return false;
                else return collectorClass.identifierName;
            }

            this.$scope.getUrl = () => {
                if (!this.$scope.building) return;
                return Restangular.one("accounts", accountId).one("buildings", buildingId).getRestangularUrl() + '/queryMetersCsv';
            }

            this.$scope.showBulkHistoricalUploadModal = () => {
                var modal = this.$modal({
                    templateUrl: 'app/properties/dataCollectors/main/modals/bulkHistoricalUpload.html',
                    show: false,
                    backdrop: 'static',
                    scope: this.$scope,
                });
                return modal.$promise.then(modal.show);
            }

            this.$scope.$on('$destroy', () => {
                this.$interval.cancel(queryStatusPromise);
            })

            this.$scope.onOptionsLoad = (data) => {
                this.$scope.initialOptions = data.options;
                return $q.when(this.$scope.initialOptions);
            };

        }

        private getCollectors(arr, collector) {
            arr.push(collector.childCollectors);
            if (collector.childCollectors) {
                collector.childCollectors.forEach((child) => {
                    this.getCollectors(arr, child);
                });
            }
        }
    }

    angular
        .module('properties.dataCollectors')
        .controller('DataCollectorsCtrl', DataCollectorsCtrl);
}
