namespace aq.services {
    export type Resolver = (asyncFunction: () => Promise<any>) => Promise<any>;
    /* @ngInject */
    angular.module('aq.services')
        .factory('resolver', ($q: ng.IQService) =>
        (asyncFunc: Function) => {
            const deferred = $q.defer();
            asyncFunc()
                .then(deferred.resolve)
                .catch(deferred.reject);
            return deferred.promise;
        });
}
