'use strict';

angular.module('aq.filters.time').filter('timeSince', function () {
    return function (miliseconds) {
        var seconds = miliseconds / 1000;
        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
            return interval + "yr";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            return interval + "mon";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            return interval + "d";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            return interval + "h";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            return interval + "min";
        }
        return Math.floor(seconds) + "s";
    }
});