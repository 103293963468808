namespace aq.propertySettings {
    export class BuildingDetailOptimalStartCtrl {
        public defaultOst: any;
        public initialOst: OptimalStart;
        public buildingStartAsDate: any;
        public isCurrentUserAdmin: Boolean;
        public defaultPeakSeasons: PeakSeason[];
        public monthOptions: { label: string, value: number }[];
        public timeOptions: { label: string, value: string }[];
        public peakSeasonsEnabled: boolean;
        private userItems: SelectionItem[];
        private insulationPreviews: any;
        private canActivate: Boolean;
        private timeBounds: any;
        private selectedUsers: SelectionItem[];
        private NUM_OPTIONS_PER_HOUR: number = 2;
        constructor(
            public BuildingService: aq.services.BuildingService,
            public $scope: ng.IScope,
            public building: Building,
            public optimalstart: OptimalStart,
            public Messages: aq.services.Messages,
            public users: aq.common.models.User[],
            public UserService,
            private Auth: aq.services.Auth
        ) {
            this.isCurrentUserAdmin = this.UserService.currentUser.userType === 'ADMINISTRATOR';
            this.initialOst = this.optimalstart ? {...this.optimalstart} : null;

            this.defaultOst = {
                active: false,
                building: this.building.id,
                buildingStart: 8.0,
                buffer: 0,
                indoorSetpoint: 73.0,
                vavOrCav: 'VAV',
                waterCooled: false,
                insulationLevel: 3,
                maxoutRateCooling: -3.5,
                maxoutRateHeating: 3.5,
                staggerCoolingEnabled: false,
                staggerCoolingSteps: 0,
                staggerCoolingInterval: 0,
                staggerHeatingEnabled: false,
                staggerHeatingSteps: 0,
                staggerHeatingInterval: 0,
                emailRecipients: null,
                startThreshold: 0.05,
                recursiveSearch: true,
                peakSeasons: []
            };

            if (this.initialOst && this.initialOst.peakSeasons && this.initialOst.peakSeasons.length > 0) {
                this.peakSeasonsEnabled = true;
            } else {
                this.peakSeasonsEnabled = false;
            }

            this.defaultPeakSeasons = [
                {
                    monthStart: 6,
                    dayStart: 1,
                    monthEnd: 9,
                    dayEnd: 30,
                    timeStart: '10:00:00',
                    timeEnd: '22:00:00'
                },
                {
                    monthStart: 10,
                    dayStart: 1,
                    monthEnd: 5,
                    dayEnd: 31,
                    timeStart: '07:00:00',
                    timeEnd: '22:00:00'
                }
            ];

            this.monthOptions = _.range(12)
                .map((i) => {
                    return {
                        value: i + 1,
                        label: moment(`${i + 1}`, 'M').format('MMMM')
                    };
                });

            this.timeOptions = _.flatMap(_.range(24), (i) => {
                const hour = _.padStart(i, 2, '0');
                return _.range(this.NUM_OPTIONS_PER_HOUR)
                    .map((j) => {
                        const minute = _.padStart(j * Math.floor(60 / this.NUM_OPTIONS_PER_HOUR), 2, '0');
                        const time = `${hour}:${minute}:00`;
                        return {
                            label: moment(time, 'HH:mm:ss').format('h:mm A'),
                            value: time
                        };
                    });
            });

            this.canActivate = this.isCurrentUserAdmin || (this.optimalstart && this.optimalstart.active);
            this.initUserItems();
            this.timeBounds = {min: moment(`00:00`, 'HH:mm').toDate(), max: moment(`23:45`, 'HH:mm').toDate()};
            this.insulationPreviews = {
                1: {
                    img: '/img/optimal-start/insulation-level-1.png',
                    title: 'Poor',
                    details: [
                        '90-100% of the exterior wall is glass',
                        'Shares one or no walls with other buildings'
                    ]
                },
                2: {
                    img: '/img/optimal-start/insulation-level-2.png',
                    title: 'Moderate',
                    details: [
                        '70-90% of the exterior wall is glass',
                        'Shares one or no walls with other buildings',
                        'Buildings less than 5 floors'
                    ]
                },
                3: {
                    img: '/img/optimal-start/insulation-level-3.png',
                    title: 'Good',
                    details: [
                        '50-70% of the exterior wall is glass',
                        'Two or more walls are shared with other buildings'
                    ]
                },
                4: {
                    img: '/img/optimal-start/insulation-level-4.png',
                    title: 'Very Good',
                    details: [
                        'Less than 50% of the exterior wall is glass',
                        'Thick brick wall with good insulation material'
                    ]
                }
            };

            this.optimalstart = this.transformForView(this.optimalstart || this.defaultOst);
        }

        public getDayOptions(month: number) {
            // Ignoring leap days for feb by hardcoding to a non-leap year
            return _.map(_.range(moment(`2019-${month}`, 'YYYY-M').daysInMonth()), (d) => d + 1);
        }

        public initUserItems() {
            this.userItems = _.map(this.users, (u: aq.common.models.User) => {
                const item: SelectionItem = {
                    id: u.id,
                    name: u.fullName
                };
                return item;
            });
            if (this.optimalstart && this.optimalstart.emailRecipients) {
                this.selectedUsers = _.filter(this.userItems, (u) => _.some(this.optimalstart.emailRecipients, (id: number) => id == u.id));
            } else {
                this.selectedUsers = [];
            }
        }

        public queryUsers(searchText: string): SelectionItem[] {
            if (!searchText) {
                searchText = '';
            }
            searchText = searchText.toLowerCase();
            return _.filter(this.userItems, (usr: SelectionItem) => {
                return usr.name && usr.name.toLowerCase().indexOf(searchText) > -1;
            });
        }

        public onOstToggled() {
            if (!this.optimalstart.active) {
                const tmpOst: aq.propertySettings.OptimalStart = this.initialOst || this.defaultOst;
                const newOst: aq.propertySettings.OptimalStart = {...tmpOst, active: this.optimalstart.active};
                this.optimalstart = this.transformForView(newOst);
            } else {
                if (!this.initialOst) {
                    this.optimalstart = this.transformForView({...this.defaultOst, active: this.optimalstart.active});
                }
            }
        }

        public onPeakSeasonsToggled() {
            if (!this.peakSeasonsEnabled) {
                this.optimalstart.peakSeasons = [];
            } else {
                const defaultSeasonsCopy: PeakSeason[] = _.map(this.defaultPeakSeasons, (season: PeakSeason) => {
                    return {...season};
                });
                this.optimalstart.peakSeasons = defaultSeasonsCopy;
            }
        }

        public addPeakSeason() {
            this.optimalstart.peakSeasons.push({...this.defaultPeakSeasons[0]});
        }

        public removePeakSeason(index: number) {
            this.optimalstart.peakSeasons.splice(index, 1);
        }

        public onStaggeredCoolingToggled() {
            if (!this.optimalstart.staggerCoolingEnabled) {
                this.optimalstart.staggerCoolingSteps = 0;
                this.optimalstart.staggerCoolingInterval = 0;
            } else {
                const tmpOst = this.initialOst ? this.initialOst : this.defaultOst;
                this.optimalstart.staggerCoolingSteps = tmpOst.staggerCoolingSteps;
                this.optimalstart.staggerCoolingInterval = tmpOst.staggerCoolingInterval;
            }
        }

        public onStaggeredHeatingToggled() {
            if (!this.optimalstart.staggerHeatingEnabled) {
                this.optimalstart.staggerHeatingSteps = 0;
                this.optimalstart.staggerHeatingInterval = 0;
            } else {
                const tmpOst = this.initialOst ? this.initialOst : this.defaultOst;
                this.optimalstart.staggerHeatingSteps = tmpOst.staggerHeatingSteps;
                this.optimalstart.staggerHeatingInterval = tmpOst.staggerHeatingInterval;
            }
        }

        public updateBuildingStart() {
            if (!moment(this.buildingStartAsDate).isValid()) {
                return;
            }
            const timeInput = moment(this.buildingStartAsDate);
            const hr = timeInput.hours();
            const min = timeInput.minutes() / 60;
            this.optimalstart.buildingStart = hr + min;
        }

        public isFormInvalid() {
            return this.peakSeasonsEnabled && this.optimalstart.peakSeasons.length === 0;
        }

        public save() {
            const recipients = _.map(this.selectedUsers, (u: SelectionItem) => u.id);
            this.optimalstart.emailRecipients = recipients;

            let osCopy: OptimalStart = {...this.optimalstart};
            osCopy = this.transformForRequest(osCopy);

            const action: ng.IPromise<any> = (
                osCopy.id ? this.BuildingService.updateOptimalStart(osCopy) : this.BuildingService.createOptimalStart(osCopy)
            );
            const msgSuccess: string = osCopy.id ? 'Optimal Start Time updated' : 'Successfully enabled Optimal Start Time';
            const msgFailure: string  = osCopy.id ? 'Unable to update Optimal Start Time' : 'Error enabling Optimal Start Time';

            return action
                .then((result) => {
                    this.initialOst = result.plain();
                    this.optimalstart = this.transformForView(result.plain());
                    this.Messages.success(msgSuccess);
                }).catch(() => {
                    this.Messages.error(msgFailure);
                });
        }

        public transformForView(os: OptimalStart): any {
            if (os && os.buildingStart !== null) {
                // The odd wrapper on duration is a workaround to our version of type definitions for moment.duration
                this.buildingStartAsDate = moment((<any>moment.duration(os.buildingStart, 'h')).format('HH:mm'), 'HH:mm').toDate();
            }

            if (os && os.maxoutRateCooling < 0) {
                os.maxoutRateCooling = os.maxoutRateCooling * -1;
            }
            return os;
        }

        public transformForRequest(os: OptimalStart) {
            if (os && os.maxoutRateCooling > 0) {
                os.maxoutRateCooling = os.maxoutRateCooling * -1;
            }
            return os;
        }
    }

    angular
    .module('properties')
    .controller('BuildingDetailOptimalStartCtrl', BuildingDetailOptimalStartCtrl);
}
