namespace aq.dashboard.widgets {
    export class WidgetChart {
        public model;
        public chart;
        public noUpdate: boolean;
        public showLoading: boolean;
        public showCommissioning: boolean;
        private isInitialized: boolean;

        /* @ngInject */
        constructor(
            private $element: ng.IAugmentedJQuery
        ) {
            this.isInitialized = false;
        }
        public $onInit() {
            if (this.model) {
                this.buildChart(this.model);
            }
        }
        public $onChanges(changesObj) {
            if (this.isInitialized && this.noUpdate) {
                return;
            }
            if (changesObj.model && changesObj.model.currentValue) {
                this.buildChart(changesObj.model.currentValue);
            }
            if (changesObj.showCommissioning) {
                if (this.chart) {
                    if (changesObj.showCommissioning.currentValue) {
                        this.chart.hideLoading();
                        this.chart.showLoading('<div class="commissioning-info"><i class="material-icons">build</i><span>Installation in progress</span></div>');
                    }
                }
            }
            if (changesObj.showLoading) {
                if (this.chart) {
                    if (!changesObj.showLoading.currentValue && (!changesObj.showCommissioning || !changesObj.showCommissioning.currentValue)) {
                        this.chart.hideLoading();
                    }
                }
            }
        }
        private buildChart(model) {
            if (this.chart) {
                this.chart.destroy();
            }
            this.chart = new Highcharts.Chart(this.$element[0], model);
            this.isInitialized = true;
            this.chart.showLoading('<div class="data-loading-div"><i class="material-icons">timelapse</i><span class="loading-text">Fetching data...</span></div>');
        }
    }
    angular.module('dashboard').component('widgetChart', {
        controller: WidgetChart,
        controllerAs: 'vm',
        require: 'ngModel',
        template: '<div></div>',
        bindings: {
            model: '<ngModel',
            noUpdate: '<?',
            showLoading: '<?',
            showCommissioning: '<?'
        }
    });
}
