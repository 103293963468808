namespace aq.services {
    export interface Loading {
        start(noDelay?: boolean): void;
        stop(): void;
    }
}

angular.module('aq.ui.loading', ['aq.messages'])
    .provider('loading', function () {

        const spinner = $('<div id="loading-spinner" class="loading-spinner chart-loading">' +
            '<img src="/img/full-color-propeller.png" class="spinner aquispin" />' +
            '</div>');

        const provider: any = {
            $get: ['$document', '$timeout', 'Messages', function ($document, $timeout, Messages) {
                const $body = $document.find('body');

                // The ID for the interval controlling stop()
                let timerPromise, errorPromise;

                const stop = function () {
                    $timeout.cancel(timerPromise);
                    $timeout.cancel(errorPromise);
                    const elm = $document.find('.loading-spinner');
                    elm.remove();
                    timerPromise = errorPromise = null;
                };

                return {
                    start: function (noDelay) {
                        // if there are several loading calls on the page subsequent one should cancel previous
                        stop();
                        if (timerPromise) return;

                        // warning: use noDelay always with onFinishRender directive
                        if (noDelay) {
                            $body.append(spinner);
                        } else {
                            timerPromise = $timeout(function () {
                                // Add the element to body
                                $body.append(spinner);
                            }, 1000);
                        }

                        errorPromise = $timeout(function () {
                            stop();
                            Messages.error("There's an error while loading the page. Please try again later.");
                            console.error("Loading directive has not finished, have you forgot to call loading.stop?")
                        }, 30000);
                    },
                    stop: stop
                }
            }]
        };

        return provider;
    });
