angular.module('aq.sortable', [])
    .factory('Sortable', [function () {
        
    var service: any = {};
    
    service.calculatePriority = function(list, from, to) {
        var newIndex = 0;
        var a = list[to].priority;
        var b = 0;
        if (from > to) {
            if (to > 0) {
                a = list[to - 1].priority
                b = list[to].priority;
                newIndex = a + ((a - b) / 2);
            } else {
                newIndex = list[to].priority + 1000;
            }
            
        } else {
            if (to + 1 == list.length) {                
                newIndex = list[to].priority - 1000;
            } else {
                b = list[to + 1].priority;
                newIndex = a - ((a - b) / 2);
            }
        }

        if (list[to].priority === 0) {
            newIndex = 1000;
        };

        return Math.floor(newIndex);
    }
    
    return service;
}]);
