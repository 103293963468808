declare var analytics;
namespace aq.services {
    export class SegmentService {

        private currentUser: aq.common.models.User;

        /* @ngInject */
        constructor(
            private RestangularV3: restangular.IService,
            private UserService: aq.services.UserService,
            private $window: ng.IWindowService,
            private SnapshotService
        ) { }

        public async initializeSegment() {
            if (!(this.$window.analytics)) {
                const segmentKeyResponse = await this.getSegmentKey();

                // If segmentKeyResponse.segmentKey is null segment is not enabled
                if (segmentKeyResponse.segmentKey != null && !this.SnapshotService.isSnapshotRequest()) {
                    this.initializeAnalytics();

                    this.currentUser = await this.UserService.getUser();

                    if (this.$window.analytics && this.currentUser != null && this.currentUser.currentProfile != null) {
                        this.loadAnalytics(segmentKeyResponse.segmentKey);
                        this.identify(this.currentUser);
                        this.groupProfile(this.currentUser.currentProfile);
                        this.ready();
                    }
                    if (!this.$window.analytics) {
                        console.warn('Failed to initialize segment');
                    }
                }
            }
        }

        private initializeAnalytics() {
            analytics = this.$window.analytics = this.$window.analytics || []; if (!analytics.initialize) if (analytics.invoked) this.$window.console && console.error && console.error("Segment snippet included twice."); else {
                analytics.invoked = !0; analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on"]; analytics.factory = function (t) { return function () { var e = Array.prototype.slice.call(arguments); e.unshift(t); analytics.push(e); return analytics } }; for (var t = 0; t < analytics.methods.length; t++) { var e = analytics.methods[t]; analytics[e] = analytics.factory(e) } analytics.load = function (t, e) { var n = document.createElement("script"); n.type = "text/javascript"; n.async = !0; n.src = ("https:" === document.location.protocol ? "https://" : "http://") + "cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js"; var o = document.getElementsByTagName("script")[0]; o.parentNode.insertBefore(n, o); analytics._loadOptions = e }; analytics.SNIPPET_VERSION = "4.1.0";
            }
        }

        // public for testing
        public getSegmentKey() {
            return this.RestangularV3.one('segment').customGET('key');
        }

        // public for testing
        public loadAnalytics(segmentKey: string) {
            if (this.$window.analytics) {
                this.$window.analytics.load(segmentKey);
            }
        }

        // public for testing
        public identify(user: aq.common.models.User) {
            if (this.$window.analytics && user) {
                let isPorfolioUser = false;
                if (user.currentProfile && user.currentProfile.portfolioUser) {
                    isPorfolioUser = true;
                }
                const body = {
                    name: user.fullName,
                    email: user.email,
                    persona: user.persona,
                    username: user.userName,
                    portfolioUser: isPorfolioUser
                };

                if (user.userCreated != null) {
                    body['createdAt'] = moment(user.userCreated).valueOf().toString();
                }

                this.$window.analytics.identify(user.id, body);
            }
        }

        // public for testing
        public groupProfile(currentProfile: aq.common.models.Profile) {
            if (this.$window.analytics && currentProfile && currentProfile.account) {
                this.group(Number(currentProfile.account.id), currentProfile.account.accountName);
            }
        }

        public group(accountId: number, accountName: string) {
            if (this.$window.analytics) {
                this.$window.analytics.group(accountId.toString(), {
                    name: accountName
                });
            }
        }

        // public for testing
        public ready() {
            if (this.$window.analytics) {
                this.$window.analytics.ready(function () { });
            }
        }

        //    For ChurnZero we need to pass custom properties and differentiate between portfolio users and non-portfolio users
        //    "cz_identifier" is either an account or a building id
        //    For portfolio users this will be the account id for the profile
        //    For non portfolio users we send a track event for the list of buildings passed in to the method
        //    Events by AQ Admins are ignored by ChurnZero. We note this with the "cz_ignore" field
        //    see AQ-9918

        public async trackChurnZeroEvent(eventName: string, buildingIds) {
            const user = await this.UserService.getUser();
            if (user.currentProfile.portfolioUser) {
                this.trackPortfolioUserEvent(eventName, user);
            } else {
                this.trackNonPortfolioUserEvent(eventName, user, buildingIds);
            }
        }

        private trackPortfolioUserEvent(eventName: string, user: aq.common.models.User) {
            const trackingProperties = {
                cz_identifier: user.currentProfile.account.id,
                cz_ignore: user.currentProfile.user.userType !== 'CLIENT'
            };
            this.trackEvent(eventName, trackingProperties);
        }

        private trackNonPortfolioUserEvent(eventName: string, user: aq.common.models.User, buildingIds) {
            if (!buildingIds || buildingIds.length === 0) {
                buildingIds = user.currentProfile.hasFullVisibility ? user.currentProfile.account.buildings : user.currentProfile.visibilities;
            }
            buildingIds.map((buildingId) => {
                const trackingProperties = {
                    cz_identifier: buildingId,
                    cz_ignore: user.userType !== 'CLIENT'
                };
                this.trackEvent(eventName, trackingProperties);
            });
        }


        public trackEvent(eventName: string, properties?: Object) {
            if (this.$window.analytics) {
                const integrations = {
                    Amplitude: { session_id: this.getNow().getTime() }
                };

                this.$window.analytics.track(eventName, properties, integrations);
            }
        }

        public trackPage(page: string) {
            if (this.$window.analytics) {
                const integrations = {
                    Amplitude: { session_id: this.getNow().getTime() }
                };

                this.$window.analytics.page(page, null, null, integrations);
            }
        }

        private getNow(): Date {
            return new Date();
        }
    }
    angular.module('aq.services').service('Segment', SegmentService);
}
