namespace aq.reports.monthlyBuildingEngineer {
    export class MonthlyBuildingEngineerData {
        private currentDate: string;

        /* @ngInject */
        constructor(private ReportApiParams, private DataService, private $q: any, $location: any, private RestangularV3: restangular.IService) {
            this.currentDate = moment().utc().format('YYYY-MM-DDTHH:mm');
            if ($location.search().date) {
                this.currentDate = moment
                    .unix(parseInt($location.search().date) / 1000)
                    .utc()
                    .format('YYYY-MM-DDTHH:mm');
            }
        }


        public availableMetrics(building): any {
            let availableMetrics = [];
            getAvailableMetrics(building.collectors);

            function getAvailableMetrics(collectors) {
                const available = _(collectors).map('metrics').flatten().uniq().value();
                availableMetrics = availableMetrics.concat(available);
                _.each(collectors, function (d) {
                    if (d.children && d.children.length > 0) {
                        getAvailableMetrics(d.children);
                    }
                });
            }

            return availableMetrics;
        }

        public getElectricityData(building, availableMetrics): any {
            const time = angular.copy(moment.tz(this.currentDate, building.timeZoneId));
            time.subtract(1, 'month');
            const promises = new ElectricityDataObj;
            const apiParams = new this.ReportApiParams(time, []);
            const energyApiParams = new this.ReportApiParams(time, ['ENERGY']);
            const powerApiParams = new this.ReportApiParams(time, ['POWER']);
            const electricApiParams = new this.ReportApiParams(time, ['ELECTRICITY']);
            // @ts-ignore
            promises.currentYearElectricityUtility = this.RestangularV3.all('utility-spending').
                customGET('', angular.extend(electricApiParams.current(), {buildingId : building.id}));

            promises.currentYearEnergyUtility = this.DataService
                .utilityBillData(building, moment(apiParams.current().start), moment(apiParams.current().end), 'ENERGY');

            const currentYearEnergyPromise = building.customGET('data', energyApiParams.current());
            const secondYearEnergyPromise = building.customGET('data', energyApiParams.previous());
            const thirdYearEnergyPromise = building.customGET('data', energyApiParams.third());
            const currentYearPowerPromise = building.customGET('peaks', powerApiParams.current());
            const secondYearPowerPromise = building.customGET('peaks', powerApiParams.previous());
            const thirdYearPowerPromise = building.customGET('peaks', powerApiParams.third());
            promises.currentYearEnergy = currentYearEnergyPromise;
            promises.secondYearEnergy = secondYearEnergyPromise;
            promises.thirdYearEnergy = thirdYearEnergyPromise;
            promises.currentYearPower = currentYearPowerPromise;
            promises.secondYearPower = secondYearPowerPromise;
            promises.thirdYearPower = thirdYearPowerPromise;

            // use utility bill data for electricity
            const secondYearEnergyUtilityPromise = this.DataService
                .utilityBillData(building, moment(apiParams.previous().start), moment(apiParams.previous().end), 'ENERGY');
            const thirdYearEnergyUtilityPromise = this.DataService
                .utilityBillData(building, moment(apiParams.third().start), moment(apiParams.third().end), 'ENERGY');
            const currentYearPowerUtilityPromise = this.DataService
                .utilityBillData(building, moment(apiParams.current().start), moment(apiParams.current().end), 'POWER');
            const secondYearPowerUtilityPromise = this.DataService
                .utilityBillData(building, moment(apiParams.previous().start), moment(apiParams.previous().end), 'POWER');
            const thirdYearPowerUtilityPromise = this.DataService
                .utilityBillData(building, moment(apiParams.third().start), moment(apiParams.third().end), 'POWER');
            promises.secondYearEnergyUtility = secondYearEnergyUtilityPromise;
            promises.thirdYearEnergyUtility = thirdYearEnergyUtilityPromise;
            promises.currentYearPowerUtility = currentYearPowerUtilityPromise;
            promises.secondYearPowerUtility = secondYearPowerUtilityPromise;
            promises.thirdYearPowerUtility = thirdYearPowerUtilityPromise;

            return this.$q.all(promises);
        }

        public getWaterData(building, availableMetrics): any {
            const time = angular.copy(moment.tz(this.currentDate, building.timeZoneId));
            time.subtract(1, 'month');
            const promises = new WaterDataObj;
            const apiParams = new this.ReportApiParams(time, []);
            const waterApiParams = new this.ReportApiParams(time, ['WATER']);
            // @ts-ignore
            promises.currentYearWaterUtilityV3 = this.RestangularV3.all('utility-spending').
                customGET('', angular.extend(waterApiParams.current(), {buildingId : building.id}));

            promises.currentYearWaterUtility = this.DataService
                .utilityBillData(building, moment(apiParams.current().start), moment(apiParams.current().end), 'WATER');

            const currentYearWaterPromise = building.customGET('data', waterApiParams.current());
            const secondYearWaterPromise = building.customGET('data', waterApiParams.previous());
            const thirdYearWaterPromise = building.customGET('data', waterApiParams.third());
            promises.currentYearWater = currentYearWaterPromise;
            promises.secondYearWater = secondYearWaterPromise;
            promises.thirdYearWater = thirdYearWaterPromise;

            // use utility bill data for electricity
            const secondYearWaterUtilityPromise = this.DataService
                .utilityBillData(building, moment(apiParams.previous().start), moment(apiParams.previous().end), 'WATER');
            const thirdYearWaterUtilityPromise = this.DataService
                .utilityBillData(building, moment(apiParams.third().start), moment(apiParams.third().end), 'WATER');
            promises.secondYearWaterUtility = secondYearWaterUtilityPromise;
            promises.thirdYearWaterUtility = thirdYearWaterUtilityPromise;

            return this.$q.all(promises);
        }

        public getGasData(building, availableMetrics): any {
            const time = angular.copy(moment.tz(this.currentDate, building.timeZoneId));
            time.subtract(1, 'month');
            const promises = new GasDataObj;
            const apiParams = new this.ReportApiParams(time, []);
            const gasApiParams = new this.ReportApiParams(time, ['GAS']);
            // @ts-ignore
            promises.currentYearGasUtilityV3 = this.RestangularV3.all('utility-spending')
                .customGET('', angular.extend(gasApiParams.current(), {buildingId : building.id}));

            promises.currentYearGasUtility = this.DataService
                .utilityBillData(building, moment(apiParams.current().start), moment(apiParams.current().end), 'GAS');

            const currentYearGasPromise = building.customGET('data', gasApiParams.current());
            const secondYearGasPromise = building.customGET('data', gasApiParams.previous());
            const thirdYearGasPromise = building.customGET('data', gasApiParams.third());
            promises.currentYearGas = currentYearGasPromise;
            promises.secondYearGas = secondYearGasPromise;
            promises.thirdYearGas = thirdYearGasPromise;

            const secondYearGasUtilityPromise = this.DataService
                .utilityBillData(building, moment(apiParams.previous().start), moment(apiParams.previous().end), 'GAS');
            const thirdYearGasUtilityPromise = this.DataService
                .utilityBillData(building, moment(apiParams.third().start), moment(apiParams.third().end), 'GAS');
            promises.secondYearGasUtility = secondYearGasUtilityPromise;
            promises.thirdYearGasUtility = thirdYearGasUtilityPromise;

            return this.$q.all(promises);
        }

        public getSteamData(building, availableMetrics): any {
            const time = angular.copy(moment.tz(this.currentDate, building.timeZoneId));
            time.subtract(1, 'month');
            const promises = new SteamDataObj;
            const apiParams = new this.ReportApiParams(time, ['STEAM']);
            // @ts-ignore
            promises.currentYearSteamUtilityV3 = this.RestangularV3.all('utility-spending')
                .customGET('', angular.extend(apiParams.current(), {buildingId : building.id}));

            promises.currentYearSteamUtility = this.DataService
                .utilityBillData(building, moment(apiParams.current().start), moment(apiParams.current().end), 'STEAM');

            const steamApiParams = new this.ReportApiParams(time, ['STEAM_MASS']);
            const currentYearSteamPromise = building.customGET('data', steamApiParams.current());
            const secondYearSteamPromise = building.customGET('data', steamApiParams.previous());
            const thirdYearSteamPromise = building.customGET('data', steamApiParams.third());
            promises.currentYearSteam = currentYearSteamPromise;
            promises.secondYearSteam = secondYearSteamPromise;
            promises.thirdYearSteam = thirdYearSteamPromise;

            const secondYearSteamUtilityPromise = this.DataService
                .utilityBillData(building, moment(apiParams.previous().start), moment(apiParams.previous().end), 'STEAM_MASS');
            const thirdYearSteamUtilityPromise = this.DataService
                .utilityBillData(building, moment(apiParams.third().start), moment(apiParams.third().end), 'STEAM_MASS');
            promises.secondYearSteamUtility = secondYearSteamUtilityPromise;
            promises.thirdYearSteamUtility = thirdYearSteamUtilityPromise;

            return this.$q.all(promises);
        }

        public getDegreeDayData(building): any {
            const time = angular.copy(moment.tz(this.currentDate, building.timeZoneId));
            time.subtract(1, 'month');
            const promises = new DegreeDayDataObj;
            const apiParams = new this.ReportApiParams(time, []);

            promises.currentYearDegreeDays = this.DataService.degreeDays(building,
                apiParams.currentDegreeDay().interval,
                apiParams.currentDegreeDay().start,
                apiParams.currentDegreeDay().end);
            promises.secondYearDegreeDays = this.DataService.degreeDays(building,
                apiParams.previousDegreeDay().interval,
                apiParams.previousDegreeDay().start,
                apiParams.previousDegreeDay().end);
            promises.thirdYearDegreeDays = this.DataService.degreeDays(building,
                apiParams.thirdDegreeDay().interval,
                apiParams.thirdDegreeDay().start,
                apiParams.thirdDegreeDay().end);

            return this.$q.all(promises);
        }
    }

    export class WaterDataObj {
        currentYearWater: {};
        secondYearWater: {};
        thirdYearWater: {};
        currentYearWaterUtilityV3: {
            spending: { 
                data: any[]
            }
        };
        currentYearWaterUtility: { charges: any[]}
        secondYearWaterUtility: {};
        thirdYearWaterUtility: {};
    }

    export class GasDataObj {
        currentYearGas: {};
        secondYearGas: {};
        thirdYearGas: {};
        currentYearGasUtilityV3: {
            spending: { 
                data: any[]
            }
        };
        currentYearGasUtility: { charges: any[] }
        secondYearGasUtility: {};
        thirdYearGasUtility: {};
    }

    export class SteamDataObj {
        currentYearSteam: {};
        secondYearSteam: {};
        thirdYearSteam: {};
        currentYearSteamUtility: { charges: any[] };
        currentYearSteamUtilityV3: {
            spending: { 
                data: any[]
            }
        };
        secondYearSteamUtility: {};
        thirdYearSteamUtility: {};
    }

    export class ElectricityDataObj {
        currentYearEnergy: {
            reqParams: {
                start: any,
                end: any
            }
        };
        secondYearEnergy: {
            reqParams: {
                start: any,
                end: any
            }
        };
        thirdYearEnergy: {
            reqParams: {
                start: any,
                end: any
            }
        };
        currentYearPower: {};
        secondYearPower: {};
        thirdYearPower: {};
        currentYearElectricityUtility: {
            spending: { 
                data: any[]
            }
        };
        currentYearEnergyUtility: { charges: any[] };
        secondYearEnergyUtility: {};
        thirdYearEnergyUtility: {};
        currentYearPowerUtility: {};
        secondYearPowerUtility: {};
        thirdYearPowerUtility: {};
    }

    export class DegreeDayDataObj {
        currentYearDegreeDays: {};
        secondYearDegreeDays: {};
        thirdYearDegreeDays: {};
    }

    angular.module('aq.reports').service('MonthlyBuildingEngineerData', MonthlyBuildingEngineerData);

}
