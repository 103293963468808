namespace aq.utilityBudgets {
    export class UpdateUtilityAccount extends aq.common.Controllers.ModalCtrl {

        private autoUtilityUploadEnabled: boolean = false;
        private status;

        /* @ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private RestangularV3: restangular.IService,
            private Errors,
            private Messages,
            private utilityCompanies: any[],
            private utilityAccount: UtilityAccount,
            private UtilityServiceHelper: UtilityServiceHelper,
            private Segment: aq.services.SegmentService
        ) {
            super({}, $mdDialog);
            this.utilityAccount = <UtilityAccount> this.RestangularV3.copy(this.utilityAccount);
            if (this.utilityAccount.utilityUrl && this.utilityAccount.username) {
                this.autoUtilityUploadEnabled = true;
            }
            this.status = this.UtilityServiceHelper.getStatusView(this.utilityAccount.status);
        }
        public cancel(data?): void {
            this.$mdDialog.cancel();
        }
        public hide(result?): void {
            this.$mdDialog.hide(result);
        }
        public save() {
            this.Segment.trackEvent('Utility Accounts:Update Utility Account');
            delete this.utilityAccount.status;
            if (!this.autoUtilityUploadEnabled) {
                delete this.utilityAccount.username;
                delete this.utilityAccount.password;
                delete this.utilityAccount.utilityUrl;
            }
            return this.utilityAccount
                .save()
                .then((result) => {
                    this.Messages.success('Successfully updated Utility Account');
                    this.hide(result);
                }, this.Errors.forCRUD('CREATE'));
        }
    }
    angular.module('aq.utilityBudgets').controller('UpdateUtilityAccount', UpdateUtilityAccount);
}
