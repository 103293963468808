namespace aq.ui {

    class _ActivityUsageAlertDisplay {
        public activity: aq.models.activity.ActivityResponse;
        public noDataCallback: () => void;
        public contextLoadedCallback: () => void;
        private accountId: number;
        private account: aq.common.models.Account;
        private building: aq.common.models.Building;
        private calendars: Object[];
        private buildingDrillin;
        private collectors;
        private sources;
        private tenants;
        private alert: aq.propertySettings.RealTimeEnergyAlert;
        private alertLoaded: Boolean;
        private usageAlert;
        private resolveAndClose: boolean;

        /* @ngInject */
        constructor(
            private RestangularV3: restangular.IService,
            private Restangular: restangular.IService,
            private DataStore: aq.common.DataStore,
            private $filter: ng.IFilterService,
            private OptionsService,
            private resolver: aq.services.Resolver,
            private $scope: ng.IScope,
            private activityAlertUtilService: aq.ui.ActivityAlertUtilService
        ) {}

        public $onInit() {
            this.resolver(async () => {
                try {
                    this.account = await this.Restangular.one('accounts', this.accountId).get({single: true});

                    // information about this issue
                    this.usageAlert = await this.RestangularV3
                        .one('issues', this.activity.context.issue)
                        .get({ noCache: moment().valueOf() });

                    let drillin = await this.DataStore.getList(this.account, 'drillin', { mode: 'METER' });
                    let buildingDrillin = _.find(drillin, { id: this.building.id });
                    let meters = this.$filter<Function>('flattenHierarchy')([buildingDrillin], 'children', null);
                    meters.shift();
                    meters = _.filter(meters, (meter: any) => {
                        return meter.route !== 'points';
                    });
                    this.collectors =  meters;
                    this.buildingDrillin = _.clone(buildingDrillin);

                    drillin = await this.DataStore.getList(this.account, 'drillin', { mode: 'TENANT' });
                    buildingDrillin = _.find(drillin, { id: this.building.id });
                    const tenants = this.$filter<Function>('flattenHierarchy')([buildingDrillin], 'children', null);
                    tenants.shift();
                    this.tenants =  tenants;

                    drillin = await this.DataStore.getList(this.account, 'drillin', { mode: 'SOURCE' });
                    buildingDrillin = _.find(drillin, { id: this.building.id });
                    const sources = this.$filter<Function>('flattenHierarchy')([buildingDrillin], 'children', null);
                    sources.shift();
                    this.sources =  sources;

                    this.calendars = await this.DataStore.getList(this.account, 'workCalendars', { inflate: 'rules,rules.category' });

                    await this.OptionsService.init(
                        this.account.id,
                        this.account.measurementSystem,
                        this.account.currencyUnit
                    );
                    const units = this.OptionsService.getAllUnits();
                    this.alert.unit = _.find(units, { value: this.alert.unit.toUpperCase() });
                    this.alertLoaded = true;

                    this.$scope.$apply();
                } catch (err) {
                    this.noDataCallback();
                    // TODO Change this to display an error message to users. Currently for debug purposes only
                    throw err;
                }
            });
        }

        public getIssueDurationFormatted(usageAlert: aq.propertySettings.RealTimeEnergyAlert): string {
            let issueDuration = null;
            if (usageAlert.issueCreatedOn) {
                const start = moment(usageAlert.issueCreatedOn).toDate();
                const end = usageAlert.issueClosedOn ? moment(usageAlert.issueClosedOn).toDate() : moment().toDate();
                issueDuration = this.activityAlertUtilService.getDurationFormatted(start, end);
            }
            return issueDuration;
        }

        private showResolveAndClose() {
            this.resolveAndClose = true;
        }

        private hideResolveAndClose() {
            this.resolveAndClose = false;
        }
    }

    export const ActivityAlertDisplay: ng.IComponentOptions = {
        controller: _ActivityUsageAlertDisplay,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/activities/contexts/usageAlertDisplay.html',
        bindings: {
            activity: '<',
            accountId: '<',
            alert: '<',
            building: '<',
            noDataCallback: '&',
            contextLoadedCallback: '&',
            refreshActivitiesAndCloseDialog: '&'
        }
    };

    angular
        .module('aq.ui')
        .component('activityUsageAlertDisplay', ActivityAlertDisplay);
}
