angular
    .module('aq.settings.calendars',
    [
        'aq.calendar.widget.eventGenerator',
        'ui.router',
        'aq.auth',
        'aq.messages'
    ])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.settings.calendars.rules', {
                url: '/:calendarId',
                abstract: true,
                templateUrl: 'app/settings/calendars/controllers/rules/ruleMain.html',
                controller: 'SettingsRuleMainCtrl'
            })
            .state('aq.settings.calendars.rules.views', {
                url: '/rules',
                views: {
                    'calendarWidget' : {
                        templateUrl: 'app/settings/calendars/controllers/calendars/widget/calendarWidget.html'
                    },
                    'form' : {
                        templateUrl: 'app/settings/calendars/controllers/calendars/form/calendarForm.html',
                        controller: 'CalendarFormCtrl'
                    }
                },
                data: {
                    breadcrumb: 'Events'
                }
            })
            .state('aq.settings.calendars.rules.views.form', {
                url: '/:ruleId',
                views: {
                    'form@aq.settings.calendars.rules': {
                        templateUrl: 'app/settings/calendars/controllers/rules/form/ruleForm.html',
                        controller: 'RuleFormCtrl'
                    }
                }

            });
    });
