'use strict';

angular
    .module('accounts')
    .directive('baselineData', function () {
        return {
            restrict: 'E',
            replace: true,
            transclude: true,
            templateUrl: 'app/accounts/competitions/directive/baselineData.html',
            scope: {
                building: "=",
                currentWeek: "=",
                startDate: "=",
                endDate: "=",
                showReadingsStatus: "=",
                format:"="
            },
            controller: function ($scope) {

                $scope.readings = function () {
                    var readings = $scope.building.energy;
                    var index = null;
                    if ($scope.currentWeek == true) {
                        var weekStartDay = moment().startOf('week');
                        index = moment(weekStartDay).diff($scope.startDate, 'days');
                        return readings.slice(index, readings.length);
                    }
                    index = moment($scope.endDate).diff($scope.startDate, 'days');
                    return readings.slice(0, index + 1);
                }

                $scope.isToday = function (reading) {
                    var date = moment($scope.startDate).add(reading.index, 'days');
                    return moment().isSame(date, 'day');
                }

                $scope.getMissingReadings = function () {
                    var readings = $scope.building.energy;
                    var readingsCountToNow = moment().diff($scope.startDate, 'days');
                    var readingsToNow = readings.slice(0, readingsCountToNow);
                    var missingReadings = _.filter(readingsToNow, function(reading) {
                        return reading.reading == null;
                    });
                    return missingReadings;
                }

            }
        }
    });