namespace aq.tenantbilling {
    export class VariancePercentCtrl {
        public initialValue: number;
        public compareValue: number;
        public warningPercent: number;
        public errorPercent: number;
        public percent: number;
        public valid: boolean;
        /* @ngAnnotate */
        constructor() { }

        public $onInit() {
            this.warningPercent = this.warningPercent || 30;
            this.errorPercent = this.errorPercent || 50;
            this.valid = this.isValid();
            // Calc based on initialValue and compareValue if we didn't pass in a percent
            if (_.isNil(this.percent)) {
                this.percent = this.getVariance();
            }
        }

        public $onChanges() {
            if (!_.isNil(this.initialValue) && !_.isNil(this.compareValue)) {
                this.percent = this.getVariance();
            }
            this.valid = this.isValid();
        }

        public getClass() {
            if (Math.abs(this.percent) > this.errorPercent) {
                return 'error-variance';
            } else if (Math.abs(this.percent) > this.warningPercent) {
                return 'warning-variance';
            } else {
                return '';
            }
        }

        public getVariance() {
            if (_.isNil(this.initialValue) || _.isNil(this.compareValue)) {
                return null;
            }
            // If going from 0 to non-0, 100% diff
            if (this.initialValue === 0 && this.compareValue !== 0) {
                return 100;
            }
            // if both are 0, return 0 - this avoids divide by 0 error
            if (this.initialValue === 0 && this.compareValue === 0) {
                return 0;
            }
            // Otherwise do our normal diff calculation
            // ((compareTo - initial) / initial) * 100
            return Math.round(((this.compareValue - this.initialValue) / this.initialValue) * 100);
        }

        public isValid() {
            return !_.isNil(this.percent) || (!_.isNil(this.compareValue) && !_.isNil(this.initialValue));
        }
    }

    angular
        .module('tenantBilling')
        .component('variancePercent', {
            templateUrl: 'app/tenantBilling/invoicing/run/components/variancePercent/variancePercent.html',
            controller: VariancePercentCtrl,
            controllerAs: 'vm',
            bindings: {
                initialValue: '<?',
                compareValue: '<?',
                percent: '<?',
                warningPercent: '<?',
                errorPercent: '<?'
            }
        });
}
