namespace aq.deviceManagement {
    export class DeviceDetailConfiguration {
        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private user,
            private device,
            private floors,
            private links,
            private tenants,
            private spaces,
            private building,
            private measures,
            private buildings,
            private DataStore,
            private tags
        ) {
            $scope.$on('DEVICE_UPDATED_CHILD', (event) => {
                this.refreshLinks(); // changes the reference so other components can refresh
            });
        }

        refreshLinks() {
            this.DataStore
                .getList(this.device.all('links'))
                .then((links) => {
                    this.links = links;
                });
        }

        public onDeviceChange(event) {
            if (event.device) {
                this.$scope.$emit('DEVICE_UPDATED', event.device);
            } else if (event.nonFormDataChangeIndicator) {
                this.$scope.$emit('NON_FORM_DATA_UPDATED', { nonFormDataChangeIndicator: event.nonFormDataChangeIndicator });
            }
        }
    }

    angular
        .module('deviceManagement')
        .controller('DeviceDetailConfiguration', DeviceDetailConfiguration);
}

