namespace aq.services {
    export class Messages {
        /* @ngInject */
        constructor(
            private DEFAULT_MESSAGE_TIMEOUT: number,
            private $mdToast: ng.material.IToastService,
            private $state: ng.ui.IStateService,
            private $translate) { }

        public prompt(message, callback) {
            this.showToast(message, 'warn', void 0, 'Close Anyway', callback)
        }

        public info(message: string, timeout?: number, value?: JQueryStatic) {
            this.showToast(message, 'info', timeout, undefined, undefined, value);
        }
        public warn(message: string, timeout?: number, value?: JQueryStatic) {
            this.showToast(message, 'warn', timeout, undefined, undefined, value);
        }
        public error(message: string, timeout?: number, value?: JQueryStatic) {
            this.showToast(message, 'error', timeout, undefined, undefined, value);
        }
        public success(message: string, timeout?: number, actionLabel?: string, action?: Function, value?: JQueryStatic,) {
            this.showToast(message, 'success', timeout, actionLabel, action, value);
        }

        private showToast(message: string, type: ToastType, timeout?: number, actionLabel?: string, action?: Function, value?: JQueryStatic, position?: string) {
            if (this.$state.includes('aq.reports')) {
                return;
            }
            if (!timeout) {
                timeout = this.DEFAULT_MESSAGE_TIMEOUT;
            }
            if (timeout < this.DEFAULT_MESSAGE_TIMEOUT) {
                // timeout is in seconds, convert to ms
                timeout = timeout * 1000;
            }
            this.$mdToast.show({
                template: `<md-toast  id="qa-message" class="md-toast md-toast-${type}">
                               <md-icon class="icons-sm" flex="10">${this.getIcon(type)}</md-icon>
                               <span class="pyxs">${this.translate(message, value)}</span>
                               <md-button class="md-highlight" ng-if="action" ng-click="takeAction()">${actionLabel}</md-button>
                            </md-toast>`,
                position: position ? position : 'bottom left',
                hideDelay: timeout,
                locals: {
                    action
                },
                controller: 'MessagesToastCtrl'
            });
        }

        private getIcon(type: ToastType) {
            switch (type) {
                case 'warn':
                    return 'warning';
                case 'error':
                    return 'error_outline';
                case 'success':
                    return 'thumb_up';
                default:
                    return 'lightbulb_outline';
            }
        }

        private translate(text: string, value?: JQueryStatic) {
            if (!value) {
                return this.$translate.instant(text);
            }
            return this.$translate.instant(text, { val: value });
        }
    }

    type ToastType = 'info' | 'warn' | 'error' | 'success';

    angular
        .module('aq.messages', ['pascalprecht.translate', 'ngMaterial', 'mgcrea.ngStrap'])
        .constant('DEFAULT_MESSAGE_TIMEOUT', 5000)
        .service('Messages', Messages)
        .controller('MessagesToastCtrl', ($scope, action) => {
            $scope.action = action;
            $scope.takeAction = function () {
                if ($scope.action) {
                    $scope.action();
                }
            };
        });

}
