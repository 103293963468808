angular.module('aq-main').run(($httpBackend) => {
    const regex = /^\/api\/v3\/devices\/\d{1,}\/data-status/;
    const deviceStatusesRegex = /^\/api\/v3\/devices\/data-status/;
    const networkStatusRegex = /^\/api\/v3\/devices\/\d{1,}\/network-status/;
    MockContext('devicemgmt', () => {
        $httpBackend.whenGET(regex).respond((method, url, data) => {
            let ids = MockHelpers.getIds(url);
            return [200, {
                id: ids[1],
                status: 'OFFLINE',
                lastReportedTime: moment().format()
            }, {}];
        });
        $httpBackend.whenGET(deviceStatusesRegex).respond((method, url, data) => {
            let statuses = mocks.data.devicemgmt.devices.map((device) => {
                return {
                    id: device.id,
                    status: 'OFFLINE',
                    lastReportedTime: moment().format()
                };
            });
            return [200, statuses, {}];
        });
        $httpBackend.whenGET(networkStatusRegex).respond((method, url, data) => {
            let statuses: aq.common.models.NetworkStatus[] = [{
                startDeviceId: mocks.data.devicemgmt.realHub.id,
                endDeviceId: mocks.data.devicemgmt.realBridge.id,
                rssi: -50,
                status: 'GOOD'
            }, {
                startDeviceId: mocks.data.devicemgmt.realBridge.id,
                endDeviceId: mocks.data.devicemgmt.realObviusPulseMeter.id,
                rssi: -77,
                status: 'AVERAGE'
            }];
            return [200, statuses , {}];
        });
    });
});