declare const google: any;
angular.module('aq.admin.dataViewer').directive('orgChart', function(TooltipService, $filter) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            data: '=',
            unit: '='
        },
        templateUrl: 'app/admin/dataViewer/orgChart/orgChart.html',
        controller: function($scope, $element) {

            var wordtreeData = [];

            $scope.getWidth = function() {
                return $element.width();
            }

            $scope.$watch($scope.getWidth, function (width) {
                if(width == 0) return;
                $element.height(width);
            });

            function getValue(value) {
                if (value) {
                    var convertedValue = $filter('toUnit')(value, $scope.unit);
                    if (convertedValue > 1) {
                        return $filter('number')(convertedValue, '0,0.00') + ' ' + $scope.unit.unit;
                    } else {
                        return convertedValue + ' ' + $scope.unit.unit;
                    }
                } else {
                    return 'null';
                }
            }

            function generateBreakdownChartData(breakdown) {
                var name = breakdown.name;
                var id = breakdown.id;
                // use name + id as a parent reference
                _.each(breakdown.breakdown, function(breakdown) {

                    var dataElem = '';
                    // generate dataElement if single timestamp is selected in data table
                    // this element will show actual value on every child element on chart
                    if (breakdown.values) {
                        var measure = $scope.unit.apiUnit.toLowerCase(); // power, energy ...
                        var value = breakdown.values[measure][0]; // integer
                        var state = textural(breakdown.state).format('lowersnake'); // DERIVED, NON_METERED, METERED...
                        dataElem = '<div class="data-elem source-state ' + state + '">' + getValue(value) + '</div>';
                    }

                    wordtreeData.push([{ v:breakdown.name + breakdown.id, f: '<span class="child-name source-state ' + state + '">' + textural(name).format('capitalizehuman') + '.' + textural(breakdown.name).format('capitalizehuman') + '</span>' + dataElem }, name + id, '']);
                    generateBreakdownChartData(breakdown);
                })
            }

            var chart = new google.visualization.OrgChart(document.getElementById('chart_div'));
            google.load("visualization", "1", {packages:["orgchart"]});
            google.setOnLoadCallback(drawChart);
            function drawChart() {
                var data = new google.visualization.DataTable();
                data.addColumn('string', 'Name');
                data.addColumn('string', 'Manager');
                data.addColumn('string', 'ToolTip');
                data.addRows(wordtreeData);
                chart.draw(data, {allowHtml:true});
            }

            $scope.$watch('data', function(data) {
                wordtreeData = [];
                generateBreakdownChartData(data);
                drawChart();
            })

        }
    }
});
