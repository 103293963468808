namespace aq.reportCenter {
    interface ReportsToSignUp {
        subscribeRecommended: boolean;
        subscribedReports: ReportView[];
    }
    export class GeneratedReportsCtrl {
        public dateRange: DateRangeModel;
        public selectedBuilding: SelectionItem;
        public selectedReportType: SelectionItem;
        public currentTimeStep: moment.Moment;
        public reportItems: ReportItem[];
        public filteredReportItems: ReportItem[];
        public pagedReportItems: ReportItem[];
        public filter: ReportFilter;
        public reportTypeItems: SelectionItem[];
        public buildingItems: SelectionItem[];
        public initialStartDate: Date;
        public initialEndDate: Date;
        private dateRangePlaceholderText = 'Date Interval';
        private isLoading: boolean;
        private showBulkSignUp: boolean = true;
        private page: {
            size: number;
            index: number;
            total: number;
        };
        constructor(
            private $localStorage,
            private $mdDialog: ng.material.IDialogService,
            private baseReports: Report[],
            private recommendedReports: Report[],
            private buildings: Building[],
            private $mdSidenav: ng.material.ISidenavService,
            private RestangularV3: restangular.IService,
            private RestangularV3FullResponse: restangular.IService,
            private $q: ng.IQService,
            private FileHandlerService: aq.services.FileHandlerService,
            private Messages: aq.services.Messages
        ) {
            this.initialStartDate = moment(moment().format('YYYY-MM-01')).toDate();
            this.initialEndDate = moment(this.initialStartDate).add(1, 'month').subtract(1, 'day').toDate();
            this.initStartPeriod();
            this.initSelectionItems();
            this.initFilterFromControls();
            this.filterData();
            this.page = {
                size: 10,
                index: 1,
                total: 0
            };
            if (this.$localStorage['myReportsBulkSignUp']) {
                this.showBulkSignUp = this.$localStorage['myReportsBulkSignUp'].show;
            }
        }

        public dismissReportSignUp() {
            this.$localStorage['myReportsBulkSignUp'] = { show: false };
            this.showBulkSignUp = false;
        }

        public showReportSignUpModal() {
            this.$mdDialog.show({
                controller: 'ReportSignUpDialogCtrl as vm',
                templateUrl: 'app/reportCenter/generated/reportSignUpDialog.html',
                clickOutsideToClose: false,
                locals: {
                    baseReports: this.baseReports,
                    recommendedReports: this.recommendedReports
                }
            }).then((reportsToSignUp: ReportsToSignUp) => {
                this.reportSignUp(reportsToSignUp);
            });
        }

        public reportSignUp(reportsToSignUp: ReportsToSignUp) {
            const promises = [];
            if (reportsToSignUp.subscribeRecommended) {
                promises.push(this.RestangularV3.all('reports').customPOST(null, 'recommended/subscribe'));
            }
            if (reportsToSignUp.subscribedReports.length > 0) {
                const reportIds = _.map(reportsToSignUp.subscribedReports, 'reportId');
                promises.push(this.RestangularV3.all('reports').customPOST(null, 'bulk-subscribe', { reportId: reportIds }));
            }
            this.$q.all(promises)
                .then(() => {
                    this.Messages.success('Successfully signed up for reports');
                    this.dismissReportSignUp();
                });
        }

        public initStartPeriod() {
            this.dateRange = {
                selectedTemplate: '',
                selectedTemplateName: this.formatDateRangeDisplay(this.initialStartDate, this.initialEndDate, null, null),
                dateStart: this.initialStartDate,
                dateEnd: this.initialEndDate
            };
        }

        public initFilterFromControls() {
            if (!this.filter) {
                this.filter = {
                    dateStart: null,
                    dateEnd: null,
                    buildingId: null,
                    reportTypeId: null,
                    searchText: ''
                };
            }
            this.filter.dateStart = this.dateRange && this.dateRange.dateStart ? moment(this.dateRange.dateStart) : null;
            this.filter.dateEnd = this.dateRange && this.dateRange.dateEnd ? moment(this.dateRange.dateEnd) : null;
            this.filter.buildingId = this.selectedBuilding ? this.selectedBuilding.id : null;
            this.filter.reportTypeId = this.selectedReportType ? this.selectedReportType.id : null;
        }

        public initSelectionItems() {
            this.reportTypeItems = _.map(this.baseReports, (rpt: Report) => {
                return {
                    id: rpt.id,
                    name: rpt.fullName
                };
            });
            this.buildingItems = _.map(this.buildings, (b: Building) => {
                return {
                    id: b.id,
                    name: b.name
                };
            });
        }

        public initReportItems() {
            _.each(this.reportItems, (item: ReportItem) => {
                item.generatedOnDisplay = moment(item.generated).format('MMM D, YYYY h:mm a');
                const report = _.find(this.baseReports, (br: Report) => br.id == item.reportId);
                item.reportFullName = report ? report.fullName : item.reportName;
            });
        }

        public isFilterSet() {
            const isInitFilterValues = moment(this.dateRange.dateStart).valueOf() == moment(this.initialStartDate).valueOf()
                && moment(this.dateRange.dateEnd).valueOf() == moment(this.initialEndDate).valueOf();
            return this.selectedBuilding || (this.dateRange.dateStart && this.dateRange.dateEnd && !isInitFilterValues) || this.selectedReportType;
        }

        public filterData() {
            this.isLoading = true;
            this.getReportItems()
                .then((response) => {
                    this.reportItems = response;
                    this.initReportItems();
                    this.filterBySearchText();
                    this.page.index = 1;
                    this.setPagingItems();
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }

        public formatDateRangeDisplay(dateStart, dateEnd, template, templateName) {
            if (!dateStart || !dateEnd) {
                return this.dateRangePlaceholderText;
            }
            return moment(dateStart).format('MMM D, YYYY') + ' - ' + moment(dateEnd).format('MMM D, YYYY');
        }

        public getFilterStatusDisplay() {
            let dateRangeDisplay = '';
            let buildingDisplay = '';
            let reportTypeDisplay = ' all';
            if (this.filter.dateStart && this.filter.dateEnd) {
                const from = ' from ' + moment(this.filter.dateStart).format('MMMM Do YYYY');
                const to = ' to ' + moment(this.filter.dateEnd).format('MMMM Do YYYY');
                dateRangeDisplay = from + to;
            }
            if (this.filter.buildingId) {
                const building: Building = _.find(this.buildings, (b: Building) => b.id == this.filter.buildingId);
                buildingDisplay = ' for ' + building.name;
            }
            if (this.filter.reportTypeId) {
                const reportType: Report = _.find(this.baseReports, (b: Report) => b.id == this.filter.reportTypeId);
                reportTypeDisplay = ' ' + reportType.fullName;
            }
            if (dateRangeDisplay || buildingDisplay || this.filter.reportTypeId) {
                return `Showing${reportTypeDisplay} reports${dateRangeDisplay}${buildingDisplay}.`;
            }
            return '';
        }

        public onSelectedBuildingChange(data: Building) {
            this.selectedBuilding = data;
        }

        public onSelectedReportTypeChange(data: Report) {
            this.selectedReportType = data;
        }

        public toggleFilterPanel() {
            this.$mdSidenav('right').toggle();
        }

        public applyFilters() {
            this.initFilterFromControls();
            this.$mdSidenav('right').close()
                .then(() => {
                    this.filterData();
                });
        }

        public clearAndApplyFilters() {
            this.clearFilters();
            this.$mdSidenav('right').close()
                .then(() => {
                    this.filterData();
                });
        }

        public cancelFilter() {
            this.dateRange.dateStart = this.filter.dateStart ? this.filter.dateStart.toDate() : null;
            this.dateRange.dateEnd = this.filter.dateEnd ? this.filter.dateEnd.toDate() : null;
            this.selectedBuilding = this.filter.buildingId ?
                _.find(this.buildingItems, (b: Building) => b.id == this.filter.buildingId) :
                null;
            this.selectedReportType = this.filter.reportTypeId ?
                _.find(this.reportTypeItems, (rt) => rt.id == this.filter.reportTypeId) :
                null;
            this.$mdSidenav('right').close();
        }

        public clearFilters() {
            this.selectedBuilding = null;
            this.selectedReportType = null;
            this.initStartPeriod();
            this.initFilterFromControls();
        }

        public clearFiltersExternal() {
            this.clearFilters();
            this.filterData();
        }

        public searchBy(searchText: string) {
            if (!searchText) {
                searchText = '';
            }
            this.filter.searchText = searchText;
            this.filterBySearchText();
            this.page.index = 1;
            this.setPagingItems();
        }

        public filterBySearchText() {
            this.filteredReportItems = _.chain(this.reportItems)
                .filter((item: ReportItem) => {
                    if (this.filter.searchText) {
                        const text = this.filter.searchText.toLowerCase();
                        const isReportNameMismatch = !item.reportFullName || item.reportFullName.toLowerCase().indexOf(text) == -1;
                        const isNameMismatch = !item.name || item.name.toLowerCase().indexOf(text) == -1;
                        if (isReportNameMismatch && isNameMismatch) {
                            return false;
                        }
                    }
                    return true;
                })
                .sortBy((item: ReportItem) => -moment(item.generated).valueOf())
                .value();
        }

        public getReportItems() {
            return this.getReportItemsForAllReports()
                .then((responses: ReportItem[][]) => {
                    const result: ReportItem[] = _.flatten(responses);
                    return result;
                });
        }

        public getReportItemsForAllReports() {
            const allReportItems = [];
            _.each(this.baseReports, (report: Report) => {
                if (this.filter.reportTypeId && this.filter.reportTypeId != report.id) {
                    return;
                }
                const queryParams = this.getQueryParamsFromFilter();
                const promise = this.RestangularV3.one('reports', report.id)
                    .withHttpConfig({ headers: { Accept: 'application/json' } })
                    .getList('report-items', queryParams)
                    .then((items) => {
                        const filteredItems: ReportItem[] = _.filter(items, (item: ReportItem) => item.reportName == report.name);
                        _.each(filteredItems, (fi: ReportItem) => fi.reportId = report.id);
                        return filteredItems;
                    }, (error) => {
                        return [];
                    });
                allReportItems.push(promise);
            });
            return this.$q.all(allReportItems);
        }

        public downloadFiltered() {
            const queryParams = this.getQueryParamsFromFilter();
            const grouppedReports = _.groupBy(this.reportItems, (item: ReportItem) => item.reportId);
            _.each(grouppedReports, (reports: ReportItem[], key) => {
                this.RestangularV3FullResponse.one('reports', key)
                    .all('report-items')
                    .withHttpConfig({ responseType: 'blob', transformRequest: angular.identity })
                    .customGET('', queryParams, { 'Accept': 'application/pdf' })
                    .then((response) => {
                        this.FileHandlerService.handleFileDownload(response, 'application/zip');
                    })
                    .catch(() => {
                        this.Messages.error('Error while generating report');
                    });
            });
        }

        public download(item: ReportItem) {
            this.RestangularV3FullResponse.one('reports', item.reportId)
                .one('report-items', item.id)
                .withHttpConfig({ responseType: 'blob', transformRequest: angular.identity })
                .customGET('', {}, { 'Accept': 'application/pdf' })
                .then((response) => {
                    this.FileHandlerService.handleFileDownload(response, 'application/pdf');
                })
                .catch(() => {
                    this.Messages.error('Error while generating report');
                });
        }

        public getQueryParamsFromFilter() {
            const queryParams: ReportQueryParameters = {};
            if (this.filter.dateStart && this.filter.dateEnd) {
                queryParams.startDate = this.filter.dateStart.format();
                queryParams.endDate = this.filter.dateEnd.format();
            }
            if (this.filter.buildingId) {
                queryParams.buildingId = this.filter.buildingId;
            }
            if (this.filter.reportTypeId) {
                queryParams.reportTypeId = this.filter.reportTypeId;
            }
            return queryParams;
        }

        public onPagingChange(data) {
            this.page.index = data.index;
            this.page.size = data.size;
            this.setPagingItems();
        }

        public setPagingItems() {
            this.page.total = this.filteredReportItems.length;
            const start = (this.page.index - 1) * this.page.size;
            this.pagedReportItems = _.slice(this.filteredReportItems, start, start + this.page.size);
        }

        public getPageInfo() {
            const from = (this.page.index - 1) * this.page.size + 1;
            const to = Math.min((this.page.index) * this.page.size, this.page.total);
            let search = '';
            if (this.filter.searchText) {
                search = `, containing text '${this.filter.searchText}'`;
            }
            return `(Showing items ${from} - ${to}${search})`;
        }
    }
    angular.module('reportCenter').controller('GeneratedReportsCtrl', GeneratedReportsCtrl);
}
