namespace aq.services.registration {

    export class RegistrationService {
        private user;
        private token: string;
        /* ngInject */
        constructor(private Restangular: restangular.IService,
            private Errors,
            private Messages: Messages,
            private RestangularV3: restangular.IService) {
        }

        queryInvitedUser(email) {
            return this.Restangular.all('users').customGET('queryInvitedUser', { email }).then((user) => {
                this.user = user;
                return user;
            }, () => {
                this.Messages.warn('Account not found.');
            });
        }

        registerUser(userObj: common.models.UserCreateRequest) {
            return this.RestangularV3.all('users')
                .customPOST(userObj, 'register')
                .then((registeredUser) => {
                    this.user = registeredUser;
                });
        }

        validateToken(regToken: string) {
            this.token = regToken;
            return this.Restangular.all('registrations').get('validateToken', { token: this.token });
        }

        validateUsername(username: string) {
            return this.RestangularV3.all('users').all('validate-username').post({ userName: username }, null, { authorization: this.token });
        }

        confirm(id) {
            return this.Restangular.all('registrations').customPUT(null, 'confirmation', { uuid: id }).then((saved) => {
                this.user = saved;
            });
        }

        getToken() {
            return this.token;
        }

        getUser() {
            return this.user;
        }
    }

    angular.module('aq.services.registration', ['aq.services.errors', 'aq.messages']).service('RegistrationService', RegistrationService);
}
