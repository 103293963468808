namespace aq.ui {

    angular.module('aq.ui')
        .directive('activityMention', () => {
            return {
                require: 'uiMention',
                scope: {
                    users: '<'
                },
                link: ($scope: ng.IScope, $element: ng.IRootElementService, $attrs, uiMention: any) => {
                    uiMention.label = (choice) => {
                        return `@${choice.first.toLowerCase()}.${choice.last.toLowerCase()}`;
                    };
                    uiMention.findChoices = (match, mentions) => {
                            return $scope.users
                                .filter((choice) => {
                                    return choice.first !== null && choice.last !== null && choice.id !== null;
                                })
                                // Remove users who were already mentioned
                                .filter((choice) => {
                                    return !mentions.some((mention) => {
                                        return mention.id === choice.id;
                                    });
                                })
                                .filter((choice) => {
                                    return (`${choice.first.toLowerCase()} ${choice.last.toLowerCase()}`).indexOf(match[1]) >= 0
                                            || (`${choice.first} ${choice.last}`).indexOf(match[1]) >= 0;
                                });
                    };
                    uiMention.encode = (choice) => {
                        return `[${uiMention.label(choice)}:${choice.id}]`;
                    };
                    uiMention.customLabelRegex = new RegExp(/(^|\s)@\w+\.\w+(?!\S)/im);
                }
            };
        });
}
