namespace aq.energyInsights.models {
    export let insights = [{
        id: 1,
        name: 'Utility Spending',
        service: 'CostService',
        state: 'aq.energyInsights.cost',
        beta: false,
        question: 'What are the primary reasons for this building\'s utility spending?',
        img: '/img/ei/ei-cost.png',
        widgetIcon: 'aq-icons-budget',
        class: '',
        widgetConfigurationOptions: {
            displayOptions: ['Target'],
            measures: ['ELECTRICITY', 'WATER', 'GAS', 'STEAM', 'HEAT'],
            periods: ['This Month']
        }
    }, {
        id: 4,
        name: 'Consumption',
        service: 'ConsumptionService',
        state: 'aq.energyInsights.consumption',
        beta: false,
        question: 'How is this building\'s consumption trending?',
        img: '/img/ei/ei-consumption.png',
        widgetIcon: 'aq-icons-consumption',
        class: 'consumption-insight',
        widgetConfigurationOptions: {
            displayOptions: ['Target'],
            measures: ['ELECTRICITY'],
            periods: ['This Month']
        }
    }, {
        id: 2,
        name: 'Peak Demand',
        service: 'PeakDemandService',
        state: 'aq.energyInsights.peakDemand',
        beta: false,
        question: 'When and how much is this building\'s maximum energy demand?',
        img: '/img/ei/ei-peak.png',
        widgetIcon: 'aq-icons-peak',
        class: 'peak-demand-insight',
        widgetConfigurationOptions: {
            displayOptions: ['Target'],
            measures: ['ELECTRICITY'],
            periods: ['Last Year', 'This Year', 'Last Month', 'This Month']
        }
    }, {
        id: 5,
        name: 'Baseload',
        service: 'BaseloadV2Service',
        state: 'aq.energyInsights.baseloadV2',
        beta: false,
        question: 'How often is my building\'s baseline usage on target?',
        img: '/img/ei/ei-baseload.png',
        widgetIcon: 'aq-icons-baseload',
        class: 'baseload-insight',
        widgetConfigurationOptions: {
            displayOptions: ['Target'],
            measures: ['ELECTRICITY'],
            periods: ['This Month']
        }
    }, {
        id: 6,
        name: 'Weather Normalization',
        service: 'WeatherNormalizationService',
        state: 'aq.energyInsights.weatherNormalization',
        beta: false,
        question: 'How is this building\'s consumption trending?',
        img: '/img/ei/ei-normalization.png',
        widgetIcon: null,
        materialIcon: 'wb_sunny',
        class: 'weather-normalization-insight'

    }, {
        id: 7,
        name: 'Energy Star',
        service: 'EnergyStarInsightService',
        state: null,
        beta: true,
        question: 'How is this building\'s ENERGY STAR score trending?',
        img: null,
        widgetIcon: 'aq-icons-energy-star--glyph',
        class: 'energy-star-insight',
        widgetConfigurationOptions: {
            displayOptions: ['Target'],
            measures: ['ELECTRICITY'],
            periods: ['This Year']
        }
    }];

    export class ToastObj {
        options: ng.material.IToastOptions;
        message: string;
        type: string;
        actionText: string;
    };

    export interface BaseloadResponse {
        timestamps: number[];
        values: number[];
    }

    export enum TargetStatus {
        INVALID, UNDER, WARN, OVER
    }
}
