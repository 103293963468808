angular.module('aq.calendar.widget.eventGenerator', []);

namespace aq.services {

    export interface CalendarEventModel {
        buildingScheduleEventId: string;
        title: string;
        start: Date;
        end: Date;
        allDay: boolean;
        color: string;
    }

    export class EventGenerator {
        public allWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

        public getEvents(
            start: moment.Moment,
            end: moment.Moment,
            workCalendarRules: common.models.BuildingScheduleEvent[],
            ruleCategories,
            calendarViewMode: string
        ): CalendarEventModel[] {
            const isCompactEvents = calendarViewMode == 'month';
            const initStartTime = moment(start);
            const weekArray = calendarViewMode == 'month'
                ? [1, 2, 3, 4, 5, 6]
                : [1];

            let calendarEvents = [];
            if (calendarViewMode == 'day') {
                _.each(_.sortBy(workCalendarRules, 'priority'), (rule: common.models.BuildingScheduleEvent) => {
                    const ruleCategory = _.find(ruleCategories, { id: rule.category });
                    const currentEvent = this.generateEventForDay(initStartTime, rule, ruleCategory);
                    if (currentEvent) {
                        calendarEvents.push(currentEvent);
                    }
                });
            } else {
                _.each(_.sortBy(workCalendarRules, 'priority'), (rule: common.models.BuildingScheduleEvent) => {
                    const ruleCategory = _.find(ruleCategories, { id: rule.category });
                    calendarEvents = _.concat(
                        calendarEvents,
                        this.generateEventsForEachWeekOnCalendar(weekArray, initStartTime, rule, ruleCategory, isCompactEvents)
                    );
                });
            }
            return calendarEvents;
        }

        private generateEventsForEachWeekOnCalendar(weekArray, calendarStartDateTime, rule, ruleCategory, isCompactEvents): CalendarEventModel[] {
            const events = _.map(weekArray, (week: number) => {
                const weekStartDateTime = calendarStartDateTime.clone().add((week - 1) * 7, 'days');
                return this.generateEventsForWeek(weekStartDateTime, rule, week, ruleCategory, isCompactEvents);
            });
            return _.flatten(events);
        }
        private generateEventsForWeek(
            weekStart: moment.Moment,
            rule: common.models.BuildingScheduleEvent,
            week: number,
            ruleCategory,
            isCompactEvents: boolean
        ): CalendarEventModel[] {
            const events: CalendarEventModel[] = [];
            let currentEvent: CalendarEventModel;
            let currentEventEndDate: moment.Moment;

            const isAllDay = this.isAllDay(rule);
            _.each(this.allWeek, (day, index) => {
                const currentDate = weekStart.clone().add(index, 'days');
                if (this.isNotApplicableDay(currentDate, day, rule)) {
                    if (currentEvent) {
                        this.closeEvent(currentEvent, currentEventEndDate, events);
                        currentEvent = null;
                    }
                    return;
                }
                if (!currentEvent) {
                    currentEvent = this.createCalendarEventModel(rule, currentDate);
                    const eventEndSeconds = this.timeToSeconds(rule.timeEnd);
                    currentEventEndDate = currentDate.clone().add(eventEndSeconds, 'seconds');
                    if (isAllDay) {
                        currentEventEndDate.add(23, 'hours').add(59, 'minutes');
                    }
                    if (!isCompactEvents && !isAllDay) {
                        this.closeEvent(currentEvent, currentEventEndDate, events);
                        currentEvent = null;
                    }
                } else {
                    currentEventEndDate.add(1, 'days');
                }
            });
            if (currentEvent) {
                this.closeEvent(currentEvent, currentEventEndDate, events);
                currentEvent = null;
            }
            return events;
        }
        private generateEventForDay(
            currentDate: moment.Moment,
            rule: common.models.BuildingScheduleEvent,
            ruleCategory
        ): CalendarEventModel {
            const dow = currentDate.isoWeekday();
            const currentDay = this.allWeek[dow % 7];
            if (this.isNotApplicableDay(currentDate, currentDay, rule)) {
                return null;
            }
            const currentEvent = this.createCalendarEventModel(rule, currentDate);
            const eventEndSeconds = this.timeToSeconds(rule.timeEnd);
            const currentEventEndDate = currentDate.clone().add(eventEndSeconds, 'seconds');
            if (this.isAllDay(rule)) {
                currentEventEndDate.add(23, 'hours').add(59, 'minutes');
            }
            currentEvent.end = new Date(currentEventEndDate.toISOString());
            this.adaptToTimezoneOffsets(currentEvent, currentEventEndDate);
            return currentEvent;
        }
        private isNotApplicableDay(currentDate: moment.Moment, day: string, rule: common.models.BuildingScheduleEvent) {
            const ruleDateStart = rule.dateStart ? moment(moment(rule.dateStart).format('YYYY-MM-DD')) : null;
            const ruleDateEnd = rule.dateEnd ? moment(moment(rule.dateEnd).format('YYYY-MM-DD')) : null;

            return ruleDateStart && currentDate.isBefore(ruleDateStart)
                || ruleDateEnd && currentDate.isAfter(ruleDateEnd)
                || !_.includes(rule.week, day);
        }
        private createCalendarEventModel(
            rule: common.models.BuildingScheduleEvent,
            currentDate: moment.Moment
        ): CalendarEventModel {
            const eventStartSeconds = this.timeToSeconds(rule.timeStart);
            const allDay = this.isAllDay(rule);
            return <CalendarEventModel>{
                buildingScheduleEventId: rule.id,
                title: allDay
                    ? rule.name
                    : this.getEventTitleWithHours(rule),
                start: new Date(currentDate.clone().add(eventStartSeconds, 'seconds').toISOString()),
                end: null,
                allDay,
                color: rule.color
            };
        }
        private isAllDay(rule: common.models.BuildingScheduleEvent) {
            return this.timeToSeconds(rule.timeStart) == 0
                && this.timeToSeconds(rule.timeEnd) == 0;
        }
        private timeToSeconds(hms: string) {
            if (!hms) {
                return 0;
            }
            const a = hms.split(':');
            if (a.length != 3) {
                return 0;
            }
            const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
            return seconds;
        }
        private getEventTitleWithHours(rule: common.models.BuildingScheduleEvent) {
            const startTimeShort = rule.timeStart.substring(0, 5);
            const endTimeShort = rule.timeEnd.substring(0, 5);
            return `${startTimeShort}-${endTimeShort} ${rule.name}`;
        }
        private closeEvent(eventToClose: CalendarEventModel, currentEventEndDate: moment.Moment, events: CalendarEventModel[]) {
            if (eventToClose) {
                eventToClose.end = new Date(currentEventEndDate.toISOString());
                this.adaptToTimezoneOffsets(eventToClose, currentEventEndDate);
                events.push(angular.copy(eventToClose));
            }
        }
        private adaptToTimezoneOffsets(event, endDate: moment.Moment) {
            const diff = event.start.getTimezoneOffset() - event.end.getTimezoneOffset();
            if (diff != 0) {
                endDate.add(-1 * diff, 'minutes');
                event.end = new Date(endDate.toISOString());
            }
        }
    }
    angular.module('aq.calendar.widget.eventGenerator').service('EventGenerator', EventGenerator);
}

