// @NOTE: this route and everything under it is completely separate from the rest of the application
// your services and components will not work in here!
angular
    .module('login', ['ngMaterial', 'templates-app', 'aq.messages', 'ui.router', 'pascalprecht.translate'])
    .service('AuthorizationService', aq.services.AuthorizationService)
    .config(($stateProvider, $locationProvider) => {
        $stateProvider.state('main', {
            url: '/login',
            templateUrl: 'app/login/login.html',
            controller: 'LoginCtrl as vm',

        });
        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });
    });
