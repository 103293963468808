namespace aq.propertySettings {
    export class OnboardingCtrl {
        public building: aq.common.models.Building;
        public buildingEnrollment: BuildingEnrollment;
        public users: aq.user.User[];
        public selectedPhase: BuildingEnrollmentPhase;

        private currentNavItem: string;
        private selectedPhaseIndex: number;
        private isValidData: boolean;
        private showDetails: false;
        private isAqAdmin: boolean;

        /* ngInject */
        constructor(
            private $rootScope: ng.IRootScopeService,
            private $scope,
            public UserService,
            public Messages: aq.services.Messages,
            public $mdDialog: ng.material.IDialogService,
            public RestangularV3: restangular.IService
        ) {
            this.isAqAdmin = this.UserService.currentUser.userType === 'ADMINISTRATOR';
            this.loadUsers();

            this.$scope.$on('BUILDING_UPDATED', (event: any, context: any) => {
                this.loadEnrollmentData();
            });
            this.$rootScope.$on('BUILDING_PERSONNEL_UPDATED', (event: any, context: any) => {
                this.loadEnrollmentData();
            });

            this.$scope.dropTaskConf = {
                onDrop: (event) => {
                    this.onDropTask(event);
                },
                accept: '.project-task'
            };
        }

        $onInit() {
            this.initData();
        }

        $onChanges(changesObj) {
            this.initData();
        }

        public goToPhase(index: number) {
            if (index < 0 || index >= this.buildingEnrollment.phases.length) {
                return;
            }
            this.selectedPhaseIndex = index;
            this.selectCurrentPhase();
            this.setCurrentNavItem();
        }

        public onSelectNextPhase(offset: number) {
            if (this.isNextPhaseDisabled(offset)) {
                return;
            }
            this.selectedPhaseIndex += offset;
            this.selectCurrentPhase();
            this.setCurrentNavItem();
        }

        public isNextPhaseDisabled(offset: number) {
            const testIndex = this.selectedPhaseIndex + offset;
            return testIndex < 0 || testIndex >= this.buildingEnrollment.phases.length;
        }

        public onDropTask(event) {
            const from = event.draggedIndex;
            const to = event.droppedOnIndex;
            const tasksCopy = angular.copy(this.selectedPhase.tasks);
            tasksCopy.splice(to, 0, tasksCopy.splice(from, 1)[0]);

            this.buildingEnrollment.phases[this.selectedPhaseIndex].tasks = tasksCopy
                .map((task: aq.propertySettings.TaskEnrollmentResponse, idx: number) => {
                    return { ...task, sortOrder: idx };
                });

            this.transformForView(this.buildingEnrollment);

            this.updateBuildingEnrollment()
                .then((response) => {
                    this.Messages.success('Task order updated');
                    this.initData(response.plain());
                })
                .catch(() => {
                    this.Messages.error('Error updating task');
                });
        }

        public onTaskCompleteChange(task: TaskEnrollmentResponse) {
            if (task.status != 'IGNORED') {
                if (!task.complete) {
                    task.completionDate = null;
                    task.status = 'INCOMPLETE';
                } else {
                    task.completionDate = moment().format('YYYY-MM-DD');
                    task.status = 'COMPLETE';
                }
            }

            this.updateBuildingEnrollment()
                .then((response) => {
                    this.Messages.success('Task updated');
                    this.initData(response.plain());
                })
                .then(() => {
                    if (task.complete && task.eventHandler === 'METERS_COMMISSIONED_AND_ONLINE') {
                        this.onCommissioningTaskComplete();
                    }
                })
                .catch(() => {
                    this.Messages.error('Error updating task');
                });
        }

        public onTaskIgnoreChange(task: TaskEnrollmentResponse) {
            if (task.status != 'IGNORED') {
                task.status = 'IGNORED';
                task.complete = false;
            } else if (task.status === 'IGNORED' && task.completionDate) {
                task.status = 'COMPLETE';
                task.complete = true;
            } else if (task.status === 'IGNORED' && !task.completionDate) {
                task.status = 'INCOMPLETE';
                task.complete = false;
            }
            this.onTaskCompleteChange(task);
        }

        public onCommissioningTaskComplete() {
            this.$mdDialog.show({
                controller: 'VerifyCommissioningModalCtrl as vm',
                templateUrl: 'app/properties/building/onboarding/verifyCommissioningModal.html',
                clickOutsideToClose: false,
                escapeToClose: false,
                locals: {
                    building: this.building
                }
            }).then((response) => {
                console.log(response);
            });
        }

        public onDeliver() {
            const confirm = this.$mdDialog.confirm()
                .title('Deliver Entire Project')
                .textContent('This will complete all pending tasks and finish the Onboarding process. Do you wish to continue?')
                .ariaLabel('mark project as complete')
                .ok('Ok')
                .cancel('Cancel');

            this.$mdDialog.show(confirm)
                .then(() => {
                    _.each(this.buildingEnrollment.phases, (phase: BuildingEnrollmentPhase) => {
                        _.each(phase.tasks, (task: aq.propertySettings.TaskEnrollmentResponse) => {
                            if (!task.complete && task.status != 'IGNORED') {
                                task.complete = true;
                                task.completionDate = moment().format('YYYY-MM-DD');
                            }
                        });
                    });
                    return this.updateBuildingEnrollment()
                        .then((response) => {
                            this.Messages.success('Project updated');
                            this.initData(response.plain());
                        })
                        .then(() => {
                            this.onCommissioningTaskComplete();
                        })
                        .catch(() => {
                            this.Messages.error('Error updating project');
                        });
                });
        }

        public showBuildingEnrollmentHelp(ev) {
            this.$mdDialog.show({
                controller: 'EnrollmentGuideCtrl as vm',
                templateUrl: 'app/settings/properties/enrollmentGuide.html',
                targetEvent: ev,
                clickOutsideToClose: true
            });
        }

        public editProjectTask(task: aq.propertySettings.TaskEnrollmentResponse) {
            const project: any = {
                id: this.buildingEnrollment.project.toString(),
                building: this.buildingEnrollment.building
            };
            const mappedTask: aq.projectCenter.Task = _.omit(task, ['ownerName', 'eventHandler']);
            const options: aq.projectCenter.TaskEditModalOptions = {
                isStatusReadonly: true
            };
            return this.$mdDialog.show({
                controller: 'ProjectCenterTaskEditCtrl as vm',
                templateUrl: 'app/projectCenter/project/task/projectCenterTaskEditModal.html',
                clickOutsideToClose: true,
                parent: angular.element(document.body),
                locals: {
                    project,
                    task: mappedTask,
                    users: this.users,
                    account: this.buildingEnrollment.account.toString(),
                    options
                }
            }).then((updatedTask: aq.projectCenter.Task) => {
                this.loadEnrollmentData();
            });
        }

        public deleteProjectTask(task: aq.propertySettings.TaskEnrollmentResponse) {
            const html: string =
                `<p>You are about to permanently delete the task "${task.name}".</p>` +
                `<p><b>This action cannot be undone.</b>` +
                `<p>Are you sure you want to delete this task?</p>`;
            const confirm = this.$mdDialog.confirm()
                .title('Delete Task')
                .htmlContent(html)
                .ariaLabel('delete task')
                .ok('Delete')
                .cancel('Cancel');

            this.$mdDialog.show(confirm)
                .then(() => {
                    return this.RestangularV3
                        .one('projects', this.buildingEnrollment.project)
                        .one('tasks', task.id)
                        .customDELETE(null)
                        .then(() => {
                            this.Messages.success('Task deleted');
                            this.loadEnrollmentData();
                        })
                        .catch(() => {
                            this.Messages.error('Unable to delete task');
                        });
                });
        }

        private loadEnrollmentData() {
            return this.RestangularV3
                .one('buildings', this.building.id)
                .one('enrollment')
                .get()
                .then((data) => {
                    this.initData(data);
                });
        }

        private loadUsers() {
            return this.RestangularV3
                .all('users/users-for-building')
                .getList({ 'buildingId': this.building.id, 'showAdmin': true })
                .then((data) => {
                    this.users = _.sortBy(data, ['fullName']);
                });
        }

        private updateBuildingEnrollment(): ng.IPromise<any> {
            const updateRequest = this.transformForRequest(this.buildingEnrollment);
            return this.RestangularV3
                .one('buildings', this.building.id)
                .one('enrollment')
                .customPUT(updateRequest);
        }

        private transformForView(be: BuildingEnrollment): BuildingEnrollment {
            if (be) {
                _.each(be.phases, (phase: BuildingEnrollmentPhase) => {
                    phase.tasks = _.sortBy(phase.tasks, 'sortOrder');
                    phase.tasks = phase.tasks.map((task: aq.propertySettings.TaskEnrollmentResponse, idx: number) => {
                        return {
                            ...task,
                            complete: (task.completionDate && task.status != 'IGNORED' ? true : false)
                        };
                    });
                });
            }
            return be;
        }

        private transformForRequest(be: BuildingEnrollment): BuildingEnrollmentUpdateRequest {
            let request: BuildingEnrollmentUpdateRequest = null;
            if (be) {
                request = { project: be.project, phases: [] };
                _.each(be.phases, (phase: BuildingEnrollmentPhase) => {
                    const phaseUpdate: BuildingEnrollmentPhaseUpdateRequest = { phase: phase.phase, tasks: [] };
                    _.each(phase.tasks, (task: aq.propertySettings.TaskEnrollmentResponse) => {
                        phaseUpdate.tasks.push(_.pick(task, ['id', 'completionDate', 'sortOrder', 'status']));
                    });
                    request.phases.push(phaseUpdate);
                });
            }
            return request;
        }

        private selectCurrentPhase() {
            this.selectedPhase = this.buildingEnrollment.phases[this.selectedPhaseIndex];
        }

        private setCurrentNavItem() {
            if (this.buildingEnrollment.phases.length) {
                this.currentNavItem = this.buildingEnrollment.phases[this.selectedPhaseIndex].name;
            }
        }

        private isInvalidData() {
            return !this.buildingEnrollment || !this.buildingEnrollment.phases;
        }

        private initData(response?: BuildingEnrollment) {
            if (!this.buildingEnrollment) {
                return;
            }
            if (!response) {
                this.buildingEnrollment = this.transformForView(this.buildingEnrollment);
                this.currentNavItem = this.buildingEnrollment && this.buildingEnrollment.currentPhase;
            } else {
                this.buildingEnrollment = this.transformForView(response);
            }

            this.isValidData = !this.isInvalidData();

            if (this.currentNavItem) {
                this.selectedPhaseIndex = _.findIndex(this.buildingEnrollment.phases, (item) => item.name == this.currentNavItem);
                this.selectCurrentPhase();
            }

            if (this.buildingEnrollment.progress && this.buildingEnrollment.progress == 100) {
                this.showDetails = false;
            }

            this.$scope.$emit('BUILDING_ENROLLMENT_UPDATED', {
                buildingEnrollment: this.buildingEnrollment
            });
        }
    }
    angular
        .module('properties')
        .component('onboarding', {
            controller: OnboardingCtrl,
            controllerAs: 'vm',
            templateUrl: 'app/properties/building/onboarding/onboarding.html',
            bindings: {
                building: '<',
                buildingEnrollment: '<'
            }
        });
}
