namespace aq.admin.meters.weather {
    angular.module('aq.admin.meters.weather').controller('AdminMeterWeatherCtrl',
        function($scope: AdminMeterWeatherCtrlScope, accounts, Restangular: restangular.IService, Messages) {

        $scope.accounts = accounts;
        $scope.weatherLoading = false;

        $scope.getWeatherData = function() {
            const building = _.find($scope.buildings, function(thisBuilding) {
                return thisBuilding.id = $scope.historical.buildingId;
            });
            const start = this.getDateInBuildingTz(moment($scope.historical.start), building);
            const end = this.getDateInBuildingTz(moment($scope.historical.end), building);

            if (end.diff(start) > 0) {
                $scope.weatherLoading = true;
                const queryObj = {
                    start: start.format(),
                    end: end.format()
                };

                Restangular
                    .one('accounts', $scope.historical.accountId)
                    .one('buildings', $scope.historical.buildingId)
                    .customPOST(null, 'backfillWeather', queryObj)
                    .then(function() {
                        $scope.weatherLoading = false;
                        Messages.info('Weather data should be updated within 10 minutes');
                }, function() {
                    Messages.error('There\'s some error while fetching historical weather data, please contact administrator');
                });
            } else {
                Messages.error('End date must be after start date');
            }
        };

        $scope.getExpectedData = function() {
            const building = _.find($scope.buildings, function(thisBuilding) {
                return thisBuilding.id = $scope.historical.buildingId;
            });
            const start = this.getDateInBuildingTz(moment($scope.historical.start), building);
            const end = this.getDateInBuildingTz(moment($scope.historical.end), building);

            if (end.diff(start) > 0) {
                $scope.expectedLoading = true;
                const queryObj = {
                    start: start.format(),
                    end: end.format()
                };

                Restangular
                    .one('accounts', $scope.historical.accountId)
                    .one('buildings', $scope.historical.buildingId)
                    .customPOST(null, 'backfillExpectedEnergy', queryObj)
                    .then(function() {
                        $scope.expectedLoading = false;
                        Messages.info('Expected energy data should be updated within 10 minutes');
                }, function() {
                    Messages.error('There\'s some error while fetching expected energy data, please contact administrator');
                });
            } else {
                Messages.error('End date must be after start date');
            }
        };

        $scope.getBuildingList = function() {
            if (!$scope.historical.accountId) return;
            $scope.loadingBuildings = true;
            Restangular.one('accounts', $scope.historical.accountId).getList('buildings').then(function(buildings) {
                $scope.buildings = buildings;
                const firstBuilding: any = _.first(buildings);
                $scope.historical.buildingId = firstBuilding.id;
                $scope.loadingBuildings = false;
            });
        };

        $scope.getDateInBuildingTz = function(date, building: aq.common.models.Building) {
            const year = date.get('year');
            const month = date.get('month');
            const day = date.get('date');
            return moment().tz(building.timeZoneId).set('year', year).set('month', month).set('date', day).startOf('day');
        };

    });

    export interface AdminMeterWeatherCtrlScope extends ng.IScope {
        accounts: aq.common.models.Account;
        buildings: aq.common.models.Building[];
        historical: {
            start;
            end;
            latitude: number;
            longitude: number;
            accountId: string;
            buildingId: string;
        };
        getData: Function;
        getBuildingList: Function;
        getDateInBuildingTz: Function;
        loadingBuildings;
        weatherLoading: boolean;
    }
}
