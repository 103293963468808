namespace aq.propertySettings {
    export abstract class AbstractAlertComponent {
        public alert;
        public buildingDrillin;
        public collectors;
        public sources;
        public tenants;

        constructor() {}

        protected getQueryable(): any {
            if (this.alert) {
                switch (this.alert.drillMode) {
                    case 'BUILDING':
                        return this.buildingDrillin;
                    case 'METER':
                        return _.find(this.collectors, {id: this.alert.queryableId});
                    case 'SOURCE':
                        return _.find(this.sources, {id: this.alert.queryableId});
                    case 'TENANT':
                        return _.find(this.tenants, {id: this.alert.queryableId});
                    default:
                        return null;
                }
            }
        }
    }
}
