namespace aq.filters {

    export function FlattenHierarchy() {
        return function (collectors, childName, parentObj) {
            if (collectors) {
                collectors = _.filter(collectors, (collector) => !_.isNil(collector));
                const flatten = flattenRow(collectors, null, [], childName, parentObj);
                return flatten;
            }
        };
    };

    angular.module('aq.filters').filter('flattenHierarchy', FlattenHierarchy);



    function flattenRow(collectors, parent, flatten, childName, parentObj){
        collectors.forEach((collector) => {
            flatten.push(addParent(collector, parentObj, parent));
            if (collector[childName]) {
                flattenRow(collector[childName], collector, flatten, childName, parentObj);
            }
        });
        return flatten;
    }

    // parentObj parameter is a list of keys that should be pushed onto the formatted parent object for each item.
    // Default is to push entire parent object onto each item
    function addParent(collector, parentObj, parent) {
        if (parent) {
            if (parentObj) {
                const formattedCollector = angular.extend(collector, {parent: {}});
                parentObj.forEach((key) => {
                    formattedCollector.parent[key] = parent[key] ? parent[key] : null;
                });
                return formattedCollector;
            } else {
                return angular.extend(collector, {parent});
            }
        } else {
            return angular.extend(collector, {parent: null}); ;
        }


    }
}