angular.module('tenantBilling').filter('formatCharge', function($filter) {
    return function (charge, currencyUnit, isShowDecimals = true) {
        const isNegative = charge < 0;
        if (!isShowDecimals) {
            charge = Math.round(charge);
        }
        const absCharge = Math.abs(charge);

        const symbol = currencyUnit ? currencyUnit.symbol : '';

        const decimals = isShowDecimals ? 2 : 0;
        const formattedCharge = $filter('currency')(absCharge, symbol, decimals);
        if (isNegative) {
            return `(${formattedCharge})`;
        }
        return formattedCharge;
    };
});
