namespace aq.ui {
    declare var Highcharts: __Highcharts.Static;

    export class _ActivityEnergyNoteDisplay {
        public activity: aq.models.activity.ActivityResponse;
        public noDataCallback: () => void;
        public contextLoadedCallback: () => void;
        private accountId: number;
        private account: aq.common.models.Account;
        private building: aq.common.models.Building;
        private chartConfig;

        /* @ngInject */
        constructor (
            private RestangularV3: restangular.IService,
            private Restangular: restangular.IService,
            private resolver: aq.services.Resolver,
            private HighChartService: aq.ui.highChart.HighChartService
        ) {}

        public $onInit() {
            this.resolver(async () => {
                try {
                    // this.account = await this.Restangular.one('accounts', this.accountId).get({single: true});

                    const unit = this.activity.context.data.unit;
                    const start = moment(this.activity.context.data.startDate).toISOString();
                    const end = moment(this.activity.context.data.endDate).toISOString();
                    const queryParams = {
                        measure: this.activity.context.data.measure,
                        unit,
                        start,
                        end,
                        interval: this.activity.context.data.interval
                    };
                    const queryString = 'buildings/' + this.building.id;
                    const contextualData = await this.RestangularV3.all(queryString).customGET('raw', queryParams);

                    const dataSeries = this.getDataSeries(contextualData[unit.toLowerCase()].data);
                    const selectionSeries = this.getSelectionArea(this.activity.context.data.selection);
                    const series = [dataSeries];
                    if (selectionSeries) series.push(selectionSeries);
                    this.chartConfig = this.buildChartConfig(series, unit);
                    this.contextLoadedCallback();
                } catch (err) {
                    console.log('NO DATA OR ERR', err);
                    this.noDataCallback();
                    // TODO Change this to display an error message to users. Currently for debug purposes only
                    throw err;
                }
            });
        }

        private buildChartConfig(series, unit): __Highcharts.Options {
            const getContext = () => this;
            const result: __Highcharts.Options = {
                chart: {
                    plotBorderWidth: 1,
                    type: 'line'
                },
                title: { text: null },
                subtitle: { text: null },
                legend: { enabled: false },
                tooltip: {
                    shared: true,
                    // tslint:disable-next-line:object-literal-shorthand
                    formatter: function (): string {
                        try {
                            const context = getContext();
                            const dateStr: string = moment(this.x).tz(context.building.timeZoneId).format('lll');
                            const tooltipMarkup: string[] = [];
                            tooltipMarkup.push(`${dateStr}`);
                            _.each(this.points, (p) => {
                                // tslint:disable-next-line:max-line-length
                                tooltipMarkup.push(`<span style="color:${p.series.color}">\u25CF</span> ${p.series.name}: <b>${p.y.toFixed(0)}</b>${unit}</span>`);
                            });
                            return tooltipMarkup.join('<br/>');
                        } catch (err) {
                            return null;
                        }
                    }
                },
                xAxis: {
                    type: 'datetime',
                    startOfWeek: 0, // Sunday
                    labels: {
                        formatter() {
                            const context = getContext();
                            return moment(this.value).tz(context.building.timeZoneId).format('ddd MMM D<br>h:mma');
                        },
                        rotation: -45
                    },
                    tickLength: 0,
                    gridLineWidth: 1,
                    gridLineColor: '#c7c7c7'
                },
                yAxis: [
                    {
                        min: 0,
                        title: {
                            text: unit,
                            style: {
                                fontSize: '14px'
                            }
                        },
                        labels: {
                            style: {
                                fontSize: '14px'
                            }
                        }
                    }
                ],
                series,
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                }
            };
            return result;
        }

        private getDataSeries(data): __Highcharts.SeriesOptions {
            const seriesDataArray: __Highcharts.DataPoint[] = [];
            data.forEach(point => {
                const seriesDataPoint: __Highcharts.DataPoint = {
                    x: point.timestamp,
                    y: point.datum
                };
                seriesDataArray.push(seriesDataPoint);
            });
            const series: __Highcharts.SeriesOptions = {
                name: this.building.name,
                data: seriesDataArray,
                color: '#0091f1'
            };
            return series;
        }

        private isNumber(n: number): boolean {
            return typeof n === 'number';
        }

        public getSelectionArea(data): __Highcharts.SeriesOptions {
            const series: __Highcharts.DataPoint[] = [];
            if (!data) return null;

            const {xFrom, yFrom, xTo, yTo} = data;
            if (
                !(this.isNumber(xFrom)
                && this.isNumber(yFrom)
                && this.isNumber(xTo)
                && this.isNumber(yTo))
            ) {
                return null;
            }
            const xFromTrunc = Math.trunc(data.xFrom);
            const xToTrunc = Math.trunc(data.xTo);

            series.push({
                x: xFromTrunc,
                y: data.yFrom
            });
            series.push({
                x: xToTrunc,
                y: data.yFrom
            });
            series.push({
                x: xToTrunc,
                y: data.yTo
            });
            series.push({
                x: xFromTrunc,
                y: data.yTo
            });

            return {
                name: 'Issue Detected',
                type: 'polygon',
                tooltip: null,
                data: series,
                color: 'rgba(255,40,40,.50)',
                zIndex: 0,
                showInLegend: false
            };
        }
    }

    export const ActivityEnergyNoteDisplay: ng.IComponentOptions = {
        controller: _ActivityEnergyNoteDisplay,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/activities/contexts/energyNoteDisplay.html',
        bindings: {
            activity: '<',
            accountId: '<',
            building: '<',
            noDataCallback: '&',
            contextLoadedCallback: '&'
        }
    };

    angular
        .module('aq.ui')
        .component('activityEnergyNoteDisplay', ActivityEnergyNoteDisplay);
}
