namespace aq.reportCenter {
    export class ScheduledReportCtrl {
        public groupedScheduledReports: ScheduledReportsMap;
        public userAssignmentList: UserAssignmentModel[];
        public isSignUpAll: boolean;
        public isEditPermission: boolean;
        public reportCenterAccess: common.models.AppAccessObject;
        public userItems: SelectionItem[];
        public displayUsers: SelectionItem[];
        public isBuildingReport: boolean;
        public isIntervalReadOnly: boolean;
        public accessibleScheduledReports: ScheduledReport[];
        public nonFormDataChangeIndicator: number;
        constructor(
            private accountId: number,
            private report: Report,
            private scheduledReports: ScheduledReport[],
            private buildings: Building[],
            private authAccess: common.models.AuthAppAccess,
            private users: User[],
            private user: User,
            private RestangularV3: restangular.IService,
            private Messages: aq.services.Messages,
            private $mdDialog: ng.material.IDialogService,
            private Restangular: restangular.IService,
            private $location: ng.ILocationService,
            private authToken: string,
            private $timeout: ng.ITimeoutService,
            private $q: ng.IQService,
            private loading
        ) {
            this.nonFormDataChangeIndicator = 0;
            this.isBuildingReport = _.some(this.scheduledReports, (sr: ScheduledReport) => sr.parameters.buildingId);
            this.groupedScheduledReports = this.getAccessibleScheduledReportsMap();
            this.reportCenterAccess = this.authAccess['Report Center'];
            this.initUserItems();
            this.initUserAssignmentList();
            if (_.includes([ReportName.SUMMARY_DAILY, ReportName.SUMMARY_MONTHLY, ReportName.MONTHLY_BUILDING_ENGINEER], this.report.name)) {
                this.isIntervalReadOnly = true;
            } else {
                this.isIntervalReadOnly = false;
            }
        }

        public getTimes(timeToCreateIfMissing = null) {
            // NOTE:
            // minimum supported time interval is 1-hour (e.g. 01:00, 02:00, 03:00, etc.)
            // if existing schedule time is not on the full hour (e.g. 10:25 am), a separate dropdown option is generated for backwards compatibilliy
            const result = [];
            const time = moment('2018-01-01T00:00:00Z');
            for (let i = 0; i < 24; i++) {
                result.push({
                    value: time.utc().format('HH:mm:ss'),
                    display: time.utc().format('h:mm a')
                });
                time.add(1, 'hour');
            }
            if (timeToCreateIfMissing && !_.find(result, (item) => item.value == timeToCreateIfMissing)) {
                let insertIndex = 0;
                while (insertIndex < result.length && result[insertIndex].value < timeToCreateIfMissing) {
                    insertIndex++;
                }
                const missingTime = {
                    value: timeToCreateIfMissing,
                    display: moment(`2018-01-01T${timeToCreateIfMissing}Z`).utc().format('h:mm a')
                };
                result.splice(insertIndex, 0, missingTime);
            }
            return result;
        }

        public buildPeriodDisplay(scheduling: SchedulingModel) {
            let periodDisplay;
            let timeDisplay;
            const periods = this.getPeriods(scheduling.interval);
            const times = this.getTimes(scheduling.time);
            if (scheduling.interval == 'WEEKLY') {
                periodDisplay = 'On ' + _.find(periods, (p) => p.value == scheduling.dayOfWeek).display;
            } else if(scheduling.interval == 'BIWEEKLY'){
                periodDisplay = 'On ' + _.find(periods, (p) => p.value == scheduling.dayOfWeek).display + " bi-weekly";
            } else if (scheduling.interval == 'MONTHLY') {
                periodDisplay = 'On ' + _.find(periods, (p) => p.value == scheduling.dayOfMonth).display;
            } else if (scheduling.interval == 'DAILY') {
                periodDisplay = 'Every day';
            }
            timeDisplay = ', at ' + _.find(times, (t) => t.value == scheduling.time).display;
            return periodDisplay + timeDisplay;
        }

        public initUserAssignmentList() {
            this.userAssignmentList = _.chain(this.groupedScheduledReports)
                .map((srs: ScheduledReport[], key: string) => {
                    const sr = _.first(srs);
                    const reportUsers = _.filter(this.userItems, (u) => _.some(sr.recipients, (id: number) => id == u.id));
                    const hiddenUserIds = _.filter(sr.recipients, (id: number) => !_.some(this.userItems, (item) => item.id == id));
                    const building: Building = this.getBuildingById(sr.parameters.buildingId);
                    const item: UserAssignmentModel = {
                        id: sr.id,
                        buildingName: building ? building.name : '',
                        reportUsers,
                        isSignUp: null,
                        isEditing: false,
                        hiddenUserIds,
                        relatedScheduledReports: srs,
                        scheduling: sr.scheduling
                    };
                    this.initIsSignup(item);
                    return item;
                })
                .sortBy((item: UserAssignmentModel) => item.buildingName)
                .value();
            this.isSignUpAll = _.every(this.userAssignmentList, (item: UserAssignmentModel) => item.isSignUp);
        }

        public initIsSignup(item: UserAssignmentModel) {
            item.isSignUp = _.some(item.reportUsers, (u) => u.id == this.user.id);
        }

        public getAccessibleScheduledReportsMap() {
            let accessibleScheduledReports: ScheduledReport[];
            if (this.isBuildingReport) {
                accessibleScheduledReports = _.filter(this.scheduledReports, (sr: ScheduledReport) => {
                    return sr.parameters && _.some(this.buildings, (b) => b.id == sr.parameters.buildingId);
                });
            } else {
                accessibleScheduledReports = _.filter(this.scheduledReports), (sr: ScheduledReport) => {
                    return !sr.parameters || !sr.parameters.buildingId;
                };
            }
            const result: ScheduledReportsMap = _.groupBy(accessibleScheduledReports, (sr: ScheduledReport) => {
                if (sr.parameters && sr.parameters.buildingId) {
                    return sr.parameters.buildingId;
                } else {
                    return '0';
                }
            });
            return result;
        }
        public initUserItems() {
            const isCurrentUserAdmin = this.user.userType == 'ADMINISTRATOR';
            const availableUsers = _.filter(this.users, (u: User) => {
                if (!isCurrentUserAdmin && u.userType == 'ADMINISTRATOR') {
                    return u.id == this.user.id;
                }
                return true;
            });
            this.userItems = _.map(availableUsers, (usr: User) => {
                const item: SelectionItem = {
                    id: usr.id,
                    name: usr.fullName
                };
                return item;
            });
        }
        public queryUsers(searchText: string): SelectionItem[] {
            if (!searchText) {
                searchText = '';
            }
            searchText = searchText.toLowerCase();
            return _.filter(this.userItems, (usr: SelectionItem) => {
                return usr.name && usr.name.toLowerCase().indexOf(searchText) > -1;
            });
        }

        public signUpAll() {
            _.each(this.userAssignmentList, (item: UserAssignmentModel) => {
                if (!item.isSignUp) {
                    this.toggleSignUp(item);
                }
            });
        }
        public signOutAll() {
            _.each(this.userAssignmentList, (item) => {
                if (item.isSignUp) {
                    this.toggleSignUp(item);
                }
            });
        }
        public toggleSignUp(item: UserAssignmentModel, $event: JQueryEventObject = null) {
            if ($event) {
                $event.stopPropagation();
            }
            item.isSignUp = !item.isSignUp;
            if (!item.isSignUp) {
                this.removeCurrentUserFromList(item);
                this.isSignUpAll = false;
            } else {
                this.addCurrentUserToList(item);
                if (_.every(this.userAssignmentList, (itm) => itm.isSignUp)) {
                    this.isSignUpAll = true;
                }
            }
            item.isEditing = true;
            this.nonFormDataChangeIndicator++;
        }
        public editRecipients(item: UserAssignmentModel) {
            if (!this.reportCenterAccess.EDIT) {
                return;
            }
            this.$mdDialog.show({
                controller: 'ReportRecipientsCtrl as vm',
                templateUrl: 'app/reportCenter/config/actions/reportRecipients.html',
                clickOutsideToClose: false,
                locals: {
                    userAssignment: angular.copy(item),
                    userItems: this.userItems,
                    report: this.report,
                    scheduledReport: _.find(this.scheduledReports, { id: item.id })
                }
            }).then((updatedUserAssignment: UserAssignmentModel) => {
                const userAssignment = _.find(this.userAssignmentList, (item) => item.id == updatedUserAssignment.id);
                userAssignment.reportUsers = updatedUserAssignment.reportUsers;
                userAssignment.scheduling = updatedUserAssignment.scheduling;
                userAssignment.isEditing = true;
                this.initIsSignup(userAssignment);
                this.isSignUpAll = _.every(this.userAssignmentList, (itm) => itm.isSignUp);
                this.nonFormDataChangeIndicator++;
            });
        }
        public saveScheduledReports() {
            this.loading.start(true);
            const updateList: ScheduledReport[] = [];
            const editedUserAssignements = _.filter(this.userAssignmentList, { isEditing: true });
            _.each(editedUserAssignements, (item: UserAssignmentModel) => {
                const recipients = _.map(item.reportUsers, (u: SelectionItem) => u.id);
                const totalRecipients = _.union(recipients, item.hiddenUserIds);
                _.each(item.relatedScheduledReports, (sr) => {
                    sr.recipients = totalRecipients;
                    sr.scheduling = item.scheduling;
                    updateList.push(sr);
                });
                if (item.isEditing) {
                    item.isEditing = false;
                }
            });
            const updatePromises = _.map(updateList, (item: ScheduledReport) => {
                return this.RestangularV3.one('reports', this.report.id)
                    .customPUT(item, 'scheduled-reports');
            });
            this.$q.all(updatePromises)
                .then(() => {
                    this.Messages.success('Configuration saved successfully');
                }, (error) => {
                    this.Messages.error('Error while saving configuration');
                })
                .finally(() => this.loading.stop());
        }
        public getReportParams() {
            if (this.report.name === ReportName.PORTFOLIO_UTILITY_NOTES) {
                this.generateReport({});
                return;
            }
            this.$mdDialog.show({
                controller: 'ReportParamsCtrl as vm',
                templateUrl: 'app/reportCenter/config/actions/reportParams.html',
                clickOutsideToClose: false,
                locals: {
                    monthlyReport: (this.report.name !== ReportName.SUMMARY_DAILY
                        && this.report.name !== ReportName.PORTFOLIO
                        && this.report.name !== ReportName.PORTFOLIO_ALERT
                        && this.report.name !== ReportName.PROJECT_SUMMARY
                        && this.report.name !== ReportName.CUSTOM_DATE_RANGE),
                    reportName: this.report.fullName,
                    buildingItems: this.isBuildingReport ? this.getBuildingItems() : null,
                    customDateReport: this.report.name == ReportName.CUSTOM_DATE_RANGE
                }
            }).then((params: GenerateReportParams) => {
                this.generateReport(params);
            });
        }
        public generateReport(params: GenerateReportParams) {
            let scheduledReportId = params.scheduledReportId;
            if (!scheduledReportId) {
                scheduledReportId = this.scheduledReports[0].id;
            }
            return this.RestangularV3.one('reports', this.report.id)
                .one('scheduled-reports', scheduledReportId).customPOST({
                    date: moment(params.date).format('YYYY-MM-DD'),
                    startDate: moment(params.startDate).format('YYYY-MM-DD'),
                    endDate: moment(params.endDate).format('YYYY-MM-DD') }, 'email-report').then(() => {
                    this.Messages.info('Generating the requested report. You will receive an email when it is done.');
                }, (err) => {
                    this.Messages.error('Error generating report');
                });
        }
        public getReportUrl(params: GenerateReportParams) {
            const type = this.report.reportType;
            const locationOrigin = this.$location.protocol() + '://' + this.$location.host() + ':' + this.$location.port();
            const queryParamsObject = {
                authToken: this.authToken,
                accountId: this.accountId,
                manual: 'true'
            } as any;
            if (params && params.buildingId) { queryParamsObject.buildingId = params.buildingId; }
            if (params && params.date) { queryParamsObject.date = params.date; }
            const path = locationOrigin + '/reports/summary/' + type + '?' + $.param(queryParamsObject);
            return path;
        }
        public getBuildingItems(): SelectionItem[] {
            const items: SelectionItem[] = _.map(this.scheduledReports, (sr: ScheduledReport) => {
                const building: Building = this.getBuildingById(sr.parameters.buildingId);
                if (!building) {
                    return null;
                }
                return {
                    id: building.id,
                    name: building.name,
                    scheduledReportId: sr.id
                };
            });
            return _(items).compact().uniqBy('id').value();
        }
        public checkReportStatus(report, name) {
            this.Restangular
                .one('accounts', this.accountId)
                .one('ScheduledReports')
                .customGET('reportStatus', { statusId: report.statusId, noCache: new Date().getTime() })
                .then((res) => {
                    if (name) {
                        res.name = name;
                    }
                    if (res.status !== 'completed' && res.status !== 'failed') {
                        this.$timeout(this.checkReportStatus, 1000, true, res);
                        return;
                    }
                    if (!res.downloadUrl) {
                        this.Messages.error('Failed to generate ' + res.name);
                    } else {
                        this.Messages.success('Report complete');
                    }
                });
        };
        public getVisibleRecipientCount(item: UserAssignmentModel) {
            const sr: ScheduledReport = _.first(item.relatedScheduledReports);
            const recipientIds = sr.recipients;
            return _.filter(recipientIds, (id) => _.some(this.userItems, (item) => item.id == id)).length;
        }
        private addCurrentUserToList(context: UserAssignmentModel) {
            if (!_.find(context.reportUsers, (usr: SelectionItem) => usr.id == this.user.id)) {
                const result = angular.copy(context.reportUsers);
                result.push(_.find(this.userItems, (usr) => usr.id == this.user.id));
                context.reportUsers = result;
            }
        }
        private removeCurrentUserFromList(context: UserAssignmentModel) {
            const result = angular.copy(context.reportUsers);
            _.remove(result, (usr: SelectionItem) => usr.id == this.user.id);
            context.reportUsers = result;
        }
        private getBuildingById(buildingId) {
            return _.find(this.buildings, (b) => b.id == buildingId);
        }

        private getPeriods(interval) {
            if (interval != 'WEEKLY' && interval != 'MONTHLY' && interval != 'BIWEEKLY') {
                return [];
            }
            const numberOfDays = interval == 'WEEKLY' || interval == 'BIWEEKLY' ? 7 : 28;
            const name = interval == 'WEEKLY' || interval == 'BIWEEKLY' ? 'week' : 'month';
            const ordinalDays = _.getOrdinalNumbers(numberOfDays);
            return _.map(ordinalDays, (ordinalDay, index) => {
                return { value: index + 1, display: `${ordinalDay} day of the ${name}` };
            });
        }
    }
    angular.module('reportCenter').controller('ScheduledReportCtrl', ScheduledReportCtrl);
}
