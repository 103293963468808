'use strict';

angular
.module('user')
.controller('RecoverPasswordCtrl', function ($scope, $window, Restangular, Messages) {

    $scope.sendPassword = function () {
        return Restangular.all('users').customPOST(null, 'recoverPassword', {email: $scope.email}).then(function () {
            $window.location = "/";
        }, function() {
            Messages.error('Oops: We couldn\'t find that email address');
        });
    }

    $scope.login = function () {
        $window.location = "/login";
    }

});