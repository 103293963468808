/**
 *
 * @param apply pass function from controller to be called when user press 'Apply' button
 * @param search pass function from controller to be called while user write to get contextual menu
 TODO - why we cannot reuse apply here ?
 * @param change pass function from controller to be called when user update filter parameters
 * @param filterClass this represent backend java class on which model will filtering be applied
 * @param filterFields list of fields that will be available to user to do filtering
 * @param onOptionsLoad function from controller that will be called when filter schema is loaded from backend
 */
angular
    .module('aq.ui.dynamicFilter', ['ngMaterial', 'ui.router', 'restangular', 'aq.filters.formatters'])
    .directive('dynamicFilter', function ($location, $stateParams, Restangular) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/common/directives/dynamicFilter/dynamicFilter.html',
            scope: {
                accountId: '=?',
                apply: '&',
                search: '&',
                change: '&',
                filterClass: '@',
                filterFields: '=',
                onOptionsLoad: '&?'
            },
            link: function ($scope, elem, attr) {

                $scope.filterApplied = false;
                var initDone = false;
                // this will retrieve schema for selected model, after schema is loaded it will trigger onOptionsLoad event in
                // case schema needs to be changed in controller
                Restangular.all('dynamicFilters').getList({
                    accountId: $scope.accountId,
                    filterClass: $scope.filterClass,
                    fields: $scope.filterFields
                }).then(function (options) {
                    if (attr.onOptionsLoad) {
                        $scope.onOptionsLoad({options: options}).then(function (newOptions) {
                            $scope.initialOptions = newOptions;
                            init();
                            initDone = true;
                        });
                    } else {
                        $scope.initialOptions = options;
                        init();
                        initDone = true;
                    }
                });

                $scope.filters = [];

                $scope.allFiltersUsed = function () {
                    if (!initDone) return false;

                    var usedFilters = _.filter($scope.filters, function (f) {
                        return f.value;
                    });
                    return $scope.initialOptions.length == usedFilters.length;
                };

                $scope.addFilter = function (key, value) {
                    var usedFilters = _.map($scope.filters, 'initialValue');
                    var options = _.difference($scope.initialOptions, usedFilters);
                    var filter: any = {
                        initialValue: null,
                        initialOptions: options
                    };

                    if (key) {
                        var selectedFilter = _.find($scope.initialOptions, {name: key});
                        if (selectedFilter) {
                            filter.initialValue = selectedFilter;
                            // prevent value to be converted to String if only one search param selected
                            filter.value = _.isString(value) ? [value] : value;
                            $scope.filters.push(filter);
                        }
                    } else {
                        $scope.filters.push(filter);
                    }
                    $scope.filterApplied = false;
                };

                $scope.clearFilter = function (filter) {
                    _.withoutInline($scope.filters, filter);
                    $scope.filterChanged(filter);
                    $scope.filterApplied = false;
                };

                $scope.edit = function () {
                    $scope.filterApplied = false;
                };

                var buildParams = function () {
                    var params = {};
                    $scope.filters.forEach(function (filter) {
                        var key = textural(filter.initialValue.name).format('camel');
                        params[key] = filter.value.text || filter.value.join(',');
                    });
                    return params;
                };

                $scope.applyFilter = function (clear) {
                    if (clear) $scope.filters = [];
                    var params = buildParams();

                    $location.search(params);

                    $scope.apply({params: params}).then(function () {
                        $scope.filterApplied = true;
                    }, null);
                };

                $scope.filterChanged = function (filter) {
                    var params = buildParams();

                    $scope.change({
                        params: params,
                        filters: $scope.filters,
                        filterName: filter.initialValue.name
                    });
                };

                $scope.searchItems = function (param) {

                    var searchObject = {
                        searchParam: param.param,
                        searchValue: param.search
                    };

                    var params = $stateParams;

                    return $scope.search({params: _.extend(params, searchObject)});
                };

                $scope.searchPredefined = function (items, query, used) {
                    var availableItems = _.difference(items, used);
                    return _.filter(availableItems, function (i) {
                        return i.toLowerCase().indexOf(query.toLowerCase()) >= 0;
                    });
                };

                $scope.getAvailableEnums = function (params) {
                    return _.difference(params.items, params.used);
                };

                var init = function () {
                    // build filter if params exist and apply search
                    $scope.apply({params: $stateParams}).then(function () {
                        _.each($location.search(), function (value, key) {
                            if (value) {
                                var param = _.find($scope.initialOptions, {name: key});

                                if (param && param.type == 'select') {
                                    value = _.find(param.items, function (i) {
                                        return i == value;
                                    });
                                }

                                if (param && param.type == 'text') {
                                    param.items = value;
                                }

                                $scope.addFilter(key, value);
                            }
                        });
                        $scope.filterApplied = true;
                    }, null);
                };

            }
        };
    });
