namespace aq.deviceManagement {

    export class DeviceConfigurationComponent {
        device: aq.common.models.DeviceElement;
        tenants: aq.common.models.Tenant[];
        buildings: aq.common.models.Building[];
        building: aq.common.models.Building;
        tags: aq.common.models.BuildingTag[];
        measures: aq.common.models.Measure[];
        onChange: Function;
        isAqAdmin: boolean;
        isAdmin: boolean;
        links: aq.common.models.DeviceLink;
        canEdit: boolean;
        selectedFloor;
        templateFields: aq.common.models.DeviceExtraFields;
        nonFormDataChangeIndicator: number;
        activeTenant;
        query: string;
        selectedLocationTag;
        hardwareRevisionFields;
        hardwareRevisionTemplateFields;
        private hardwareDetailSupportedFields: ReadonlyArray<string> = [
            'gatewayPartNumber',
            'gatewayFirmwareVersion',
            'radioPartNumber',
            'radioFirmwareVersion'
        ];
        tenantLabel: string;
        /* @ngInject */
        constructor(
            private Auth: aq.services.Auth,
            private $filter,
            public $mdDialog: ng.material.IDialogService,
            private DeviceService: aq.services.DeviceService,
            private DataStore,
            public UserService,
            private Messages: aq.services.Messages,
            private RestangularV3,
            private $mdMedia
        ) {
            this.isAqAdmin = this.UserService.currentUser.userType === 'ADMINISTRATOR';
            if (this.device.deviceCategory != 'OTHER') {
                this.templateFields = this.filterFields(this.device.viewOnly.selectedDeviceClass.fields);
                this.selectedLocationTag = this.device.locationTag;
            }
            this.templateFields = this.filterFields(this.device.viewOnly.selectedDeviceClass.fields);
            this.isAdmin = this.Auth.check({access: 'FULL_ACCESS'});
            this.canEdit = this.Auth.check({access: 'EDIT'});
            this.setActiveTenants();
            this.hardwareRevisionFields = this.getHardwareRevisionFields();
            this.hardwareRevisionTemplateFields = this.getHardwareRevisionTemplateFields();
            this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'unit' : 'tenant';
        }

        setActiveTenants() {
            this.activeTenant = {};
            this.device.tenantLinks.forEach((tenantLink) => {
                this.activeTenant[tenantLink.tenant.id] = tenantLink.active;
            });
        }

        formatLinkProtocol(link: aq.common.models.DeviceLink): string {
            return `${link.protocol.replace(/[^A-Za-z]/g, ' ').toLowerCase()} ${link.pulseId || ''}`;
        }

        isCommissioned() {
            if (this.Auth.check({ access: 'FULL_ACCESS' })) {
                // todo logic to determine if a meter is commissioned
                return false;
            }
            return true;
        }

        filterFields(fields: aq.common.models.DeviceExtraFields) {
            if (fields) {
                const keys = Object.keys(fields);
                const visibleFields = {};
                keys.forEach(key => {
                    if (fields[key].hide || (fields[key].admin && !this.isAqAdmin)) {
                        return;
                    }
                    visibleFields[key] = fields[key];
                });
                return visibleFields;
            } else {
                return null;
            }
        }

        addTenant(event): void {
            (this.$mdDialog as any).show({
                targetEvent: event,
                templateUrl: 'app/deviceManagement/actions/addTenant/addTenant.html',
                controller: 'AddTenant',
                controllerAs: 'vm',
                multiple: true,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                clickOutsideToClose: false
            })
            .then((tenant) => {
                tenant.building = this.building.id;
                this.RestangularV3.all('tenants')
                .post(tenant, {buildingId: this.building.id})
                .then((createdTenant) => {
                    this.tenants.push(createdTenant);
                    this.device.viewOnly.tenants.push(createdTenant);
                    this.nonFormDataChangeIndicator = this.nonFormDataChangeIndicator ? this.nonFormDataChangeIndicator + 1 : 1;
                    this.nonFormChange();
                    this.Messages.success(`New tenant ${createdTenant.name} created`);
                });
            });
        }

        selectLocationTag(tag) {
            if (tag) {
                if (tag.query) {
                    _.remove(this.tags, { query: tag.query });
                    tag.name = tag.query;
                    delete tag.query;
                    this.tags.push(tag);
                    this.Messages.success(`New location '${tag.name}' added`);
                }
                this.device.locationTag = tag;
            } else if (this.query === '') {
                this.device.locationTag = null;
            }
            this.nonFormDataChangeIndicator = this.nonFormDataChangeIndicator ? this.nonFormDataChangeIndicator + 1 : 1;
            this.nonFormChange();
        }

        filterLocationTag(query: string) {
            _.remove(this.tags, (t) => t.query);
            if (query && !_.some(this.tags, (t) => t.name.toLowerCase() == query.toLowerCase())) {
                const currentQuery = {
                    name: `Add new location "${query}"`,
                    query
                } as any;
                this.tags.push(currentQuery);
            }
            const tags = _.filter(this.tags, (tag) => {
                return tag.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
            });
            return _.sortBy(tags, [(t) => t.query ? 1 : 0, 'name']);
        }

        isValidLocation() {
            if (this.device.locationTag) {
                return this.query === this.device.locationTag.name;
            } else {
                return !this.query;
            }
        }

        private getIcon(device: aq.common.models.DeviceElement) {
            return this.DeviceService.getIcon(device);
        }

        private removeLink($event, device, linkDevice) {
            this.DeviceService.removeLink($event, device, linkDevice)
                .then(() => {
                    _.remove(this.links, {device : {id: linkDevice.id}});
                    this.onDeviceChange();
                });
        }

        private onDeviceChange() {
            this.onChange({
                $event: {
                    device: this.device
                }
            });
        }

        private nonFormChange() {
            this.onChange({
                $event: {
                    nonFormDataChangeIndicator: this.nonFormDataChangeIndicator
                }
            });
        }

        private hardwareRevisionInfoExists(): boolean {
            if (this.device.deviceCategory === 'OTHER') {
                return false;
            }
            return this.hardwareDetailSupportedFields.some((field) => field in this.device.fields);
        }

        private getHardwareRevisionFields() {
            return _.pick(this.device.fields, this.hardwareDetailSupportedFields);
        }

        private getHardwareRevisionTemplateFields():  aq.common.models.DeviceExtraFields {
            const allHardwareRevisionTemplateFields = {
                gatewayPartNumber: { label: 'Device Part Number', icon: 'label_outline'},
                gatewayFirmwareVersion: { label: 'Firmware Version', icon: 'label_outline'},
                radioPartNumber: { label: 'Device Part Number', icon: 'label_outline'},
                radioFirmwareVersion: { label: 'Firmware Version', icon: 'label_outline'}
            };
            if (this.device.deviceCategory === 'OTHER') {
                return {key: {}};
            }
            const availableFields = this.hardwareDetailSupportedFields.filter((field) => field in this.device.fields);
            return _.pick(allHardwareRevisionTemplateFields, availableFields);

        }
    }
    angular.module('deviceManagement')
    .component('deviceConfiguration', {
        templateUrl: 'app/deviceManagement/device/components/deviceConfiguration.html',
        bindings: {
            device: '=',
            user: '<',
            tenants: '<',
            building: '<',
            buildings: '<',
            tags: '<',
            measures: '<',
            onChange: '&',
            links: '<',
            nonFormDataChangeIndicator: '<'
        },
        controller: DeviceConfigurationComponent,
        controllerAs: 'vm'
        });
}
