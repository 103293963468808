angular.module('charting').filter('forPie', ['$filter', function($filter) {
    return function(data, query, singleColor) {
        if (!data || _.isEmpty(data)) {
            return null;
        }
        var unitGet = 'kWh';
        if (query.unit.selectedUnit && query.unit.selectedUnit.unit.indexOf('$') === 0) {
            unitGet = '$';
        }
        return $filter('forPieWidget')(data, query.unit.get ? query.unit.get(unitGet) : query.unit.selectedUnit, singleColor);
    }
}]);

angular.module('charting').filter('forPieWidget', ['$filter', function($filter) {
    return function(data, callUnit, singleColor) {
        if (!data || _.isEmpty(data)) {
            return null;
        }
        var maxValue = _.maxBy(data, function(elem) {
            return elem.values[callUnit.field];
        }).values[callUnit.field];


        var unit = $filter('bestFitUnit')(maxValue, callUnit);
        var pieData = _.map(data, function(elem, index) {

            var value = elem.values[unit.field];

            return {
                label: elem.name,
                data: $filter('toUnit')(value < 0 ? 0 : value, unit),
                color: singleColor ? $filter('singleColor')(singleColor, index*15) : $filter('color')(elem.name, elem.route, elem.id)
            }
        });

        return {
            data: pieData,
            unit: unit
        }
    }
}]);