namespace Breakdowns {
    const breakdowns = {
        'BUILDING': 0,
        'METER': 2, // collectors - building-specific
        'USES': 3, // energyuses - building-specific
        'SOURCE': 4, // sources - building-specific
        'SPACE': 5, // spaces - building-specific
        'TENANT': 6 // tenants - building-specific
    };

    export type Breakdowns =
        'BUILDING' |
        'METER' |
        'USES' |
        'SOURCE' |
        'SPACE' |
        'TENANT';

    export function getBreakdowns(Auth) {
        const keys = Object.keys(breakdowns);
        if (!Auth || !(Auth.check({ appName: 'Tenant Billing' }))) {
            keys.pop();
        }

        return keys;
    }
}

namespace BuildingAttributes {
    export class BuildingAttribute {
        value: string;
        label: string;
        prefix?: string;
        postfix?: string;
        isTrend: boolean;
        trendCompareCol?: string;
        getValue: Function;
    }

    const energyStarScore: BuildingAttribute = {
        value: 'energyStarScore',
        label: 'Energy Star Score',
        prefix: '<i class="aq-icons-energy-star--glyph energy-star-blue"><\i>',
        getValue: (building) => {
            let value = null;
            if (building.energyStarScore) {
                value = building.energyStarScore.score;
            }

            return value;
        },
        isTrend: false
    };

    const attributes = {
        energyStarScore,
    };

    export function getAttributes() {
        return attributes;
    }

    export function getAttribute(attribute): BuildingAttribute {
        return attributes[attribute];
    }
};
