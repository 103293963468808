namespace aq.admin {
    export class AccountAdminDefaultsCtrl {

        /* @ngInject */
        constructor(
            private account,
            private $scope,
            private projectTemplate,
            private $mdDialog,
            private ProjectTemplateService: aq.services.ProjectTemplateService,
            private Messages
        ) {}

        public addProjectTemplate() {
            return this.ProjectTemplateService.createProjectTemplate(this.account).then((response) => {
                this.projectTemplate = response;
            }).catch((err) => {
                this.Messages.error('Couldn\'t Create Project Template');
            });
        }

        public editProjectTemplate(item) {
            return this.$mdDialog.show({
                controller: 'EditProjectTemplateCtrl as vm',
                templateUrl: 'app/admin/accounts/accounts/details/defaults/editProjectTemplateModal.html',
                clickOutsideToClose: true,
                locals: {
                    account: this.account,
                    projectTemplate: item
                },
                parent: angular.element(document.body)
            });
        }

        public deleteProjectTemplate(projectTemplate) {
            return this.ProjectTemplateService.deleteProjectTemplate(projectTemplate).then((response) => {
                this.Messages.info('Project Template Deleted');
                this.projectTemplate = null;
            }).catch((err) => {
                this.Messages.error('Couldn\'t Create Project Template');
            });
        }
    }

    angular
        .module('aq.admin.accounts.accounts')
        .controller('AccountAdminDefaultsCtrl', AccountAdminDefaultsCtrl);
}
