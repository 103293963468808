'use strict';

angular
    .module('aq.admin.configuration.integrationApps', [])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.admin.configuration.integrationApps', {
                url: '/integrationApps',
                templateUrl: 'app/admin/configuration/integrationApps/main.html',
                controller: 'IntegrationAppsCtrl',
                data: {
                    breadcrumb: 'Integration Apps',
                    icon: '.aq-icons-integrations'
                }
            })
            .state('aq.admin.configuration.integrationAppsNew', {
                url: '/integrationApps/new',
                templateUrl: 'app/admin/configuration/integrationApps/new/newIntegrationApp.html',
                controller: 'IntegrationAppsNewCtrl'
            })
            .state('aq.admin.configuration.integrationAppsDetails', {
                url: '/integrationApps/:integrationAppId',
                templateUrl: 'app/admin/configuration/integrationApps/details/details.html',
                controller: 'IntegrationAppsDetailsCtrl'
            });
    });
