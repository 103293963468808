namespace aq.settings {
    export class PlatformSettingsCtrl {
        public entityActions: aq.common.models.EntityMenuAction[];
        public displayEnergyUses: string[];
        public displayScheduleCategories: string[];
        public energyUses: string[];
        public scheduleCategories: string[];
        private accountSettingsAccess: common.models.AppAccessObject;
        /* ngInject */
        constructor(
            private $scope,
            private $window,
            private Restangular,
            private Messages: aq.services.Messages,
            private UserService,
            private $state: ng.ui.IStateService,
            private $mdDialog: ng.material.IDialogService,
            private authAccess: common.models.AuthAppAccess,
            private RestangularV3: restangular.IService
        ) {
            if ($scope.user.id == $scope.user.currentProfile.account.owner) {
                $scope.account.customGET('queryPossibleOwners').then((owners) => {
                    $scope.possibleOwners = owners;
                });
            }
            this.accountSettingsAccess = authAccess['Account Settings'];
            this.initDetails();
            if (this.accountSettingsAccess.FULL_ACCESS) {
                this.entityActions = [
                    { label: 'Upload Salesforce Opp', cb: () => this.uploadSalesforceOpp(), icon: 'file_upload' },
                    { label: 'Add Metric', cb: () => this.addNewSettingItem('aq.settings.platform.metrics'), icon: 'edit' },
                    { label: 'Add Energy Category', cb: () => this.addNewSettingItem('aq.settings.platform.energyUse'), icon: 'edit' },
                    { label: 'Add Building Group', cb: () => this.addNewSettingItem('aq.settings.platform.buildingGroups'), icon: 'edit' },
                    { label: 'Add Schedule Category', cb: () => this.addNewSettingItem('aq.settings.platform.scheduleCategories'), icon: 'edit' },
                    { label: 'Delete Account', cb: () => this.deleteAccount(), icon: 'delete' },
                    { label: 'Create Default Dashboards', cb: () => this.createDefaultDashboards(), icon: 'dashboard' }
                ];
            }
            this.$scope.$on('SAVE_ACCOUNT', () => {
                this.$scope.account.put()
                    .then((account) => {
                        this.UserService.updateAccount(account);
                        this.Messages.success('Account updated successfully');
                    }, () => {
                        this.Messages.error('Error while saving account');
                    });
            });
        }
        public initDetails() {
            this.$scope.account
                .all('EnergyUses')
                .getList()
                .then((energyUses) => {
                    this.energyUses = _.chain(energyUses).map('name').sort().value();
                    this.displayEnergyUses = _.take(this.energyUses, 5);
                });
            this.$scope.account
                .all('RuleCategories')
                .getList()
                .then((categories) => {
                    this.scheduleCategories = _.chain(categories).map('name').sort().value();
                    this.displayScheduleCategories = _.take(this.scheduleCategories, 5);
                });
        }
        public uploadSalesforceOpp(): void {
            this.$state.go('aq.settings.import',
                {
                    newAccountObj: {
                        account: this.$scope.account,
                        accountsPage: false
                    }
                });
        }
        public addNewSettingItem(state: string) {
            if (this.$state.includes(state)) {
                this.$scope.$broadcast('CREATE_NEW');
            } else {
                this.$state.go(state).then(() => {
                    this.$scope.$broadcast('CREATE_NEW');
                });
            }
        }
        public saveAccount() {
            return this.$scope.account.put()
                .then((account) => {
                    this.UserService.updateAccount(account);
                    this.UserService.initLanguage();
                    this.Messages.success('Account updated successfully');
                }, () => {
                    this.Messages.error('Error while saving account');
                });
        }
        public deleteAccount() {
            this.$scope.account.remove().then(() => {
                this.$window.location = '/';
            }, (error) => {
                const errorMessage = (error && error.data && error.data.message) ? ': ' + error.data.message : '';
                this.Messages.error('Unable to delete this account' + errorMessage);
            });
        }
        public getMetricsDescription() {
            const metrics = this.$scope.account.accountMetrics;
            const names: string[] = _.map(metrics, 'displayAs');
            return names.join(', ');
        }
        public createDefaultDashboards = () => {
            this.RestangularV3.one('accounts', this.$scope.account.id).customPOST({}, 'create-default-dashboards').then(() => {
                this.Messages.success('Successfully created default dashboards');
            }).catch(() => {
                this.Messages.error('Unable to create default dashboards');
            });
        }
    }
    angular.module('aq.settings').controller('PlatformSettingsCtrl', PlatformSettingsCtrl);
}
