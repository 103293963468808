/**
 * Angular wrapper for JS HighCharts library
 * On this link you can find how to create config object
 * http://www.highcharts.com/demo/column-parsed
 */
angular.module('aq.ui.highChart', []).directive('highChart', function () {
    return {
        restrict: 'E',
        replace: true,
        require: 'ngModel',
        template: '<div></div>',
        scope: {
            model: '=ngModel',
            chart: '=?',
            callback: '='
        },
        link: highChart
    }

    function highChart($scope, $element, attrs, ngModel) {
        var initialCreation = true;
        $scope.noUpdate = attrs.noUpdate == undefined ? false : true;
        $scope.$watch('model', function(model) {
            if (!model || (!initialCreation && $scope.noUpdate)) return;
            initialCreation = false;

            $scope.chart = new Highcharts.Chart($element[0], model);
            if ($scope.callback) {
                $scope.callback($scope.chart);
            }
        }, true);

        $scope.$on('highchartResize', function(ev) {
            // get size of parent container and animate our transition to the new size
            if ($scope.chart) {
                var container = angular.element($scope.chart.container);
                var width = container.parent().width();
                var height = container.parent().height();
                $scope.chart.setSize(width, height, true);
            }
        });

        $scope.$on('highchartRedraw', function(ev, chart) {
            $scope.chart = new Highcharts.Chart($element[0], chart);
        });
    }
});
