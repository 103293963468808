namespace aq.energyInsights {
    export class BaseloadService extends InsightService {
        static TARGET_GOOD_DAYS_PERCENT = 70;
        static TARGET_RATIO = 50;
        /* @ngInject */
        constructor(
            private $q,
            private EnergyInsightsDataService,
            private $filter,
            private $translate
        ) {
            super();
        }

        public getSimpleTargetModel(building: aq.common.models.Building, measure: string, periodSearch: PeriodSearch): SimpleTargetModel {
            let targetModel = new SimpleTargetModel();
            targetModel.title = 'Baseload';
            targetModel.when = this.$translate.instant('insight.when.Baseload');
            return targetModel;
        }

        public getTargetModel(building: aq.common.models.Building, measure: string, periodSearch: PeriodSearch): ng.IPromise<TargetModel> {
            let params = {
                start: periodSearch.start,
                end: periodSearch.end,
                interval: periodSearch.interval
            };
            return this.EnergyInsightsDataService.queryBaseloadStats(building, params).then((result) => {
                let baseloadStats = result.months;
                return this.calculateTargetModel(building, baseloadStats, periodSearch);
            });
        }

        public getGraphModel(building: aq.common.models.Building, measure: string, timePeriod: PeriodSearch): ng.IPromise<GraphModel> {
            return null;
        }

        public getTableModel(building: aq.common.models.Building, measure: string, periodSearch: PeriodSearch): ng.IPromise<TableModel> {
            return null;
        }
        // just to please contract
        public formatTableModel() {
            throw new TypeError('This is a stub method');
        }

        // -----------------

        private calculateTargetModel(building, baseloadStats, periodSearch): TargetModel {
            let today = moment().tz(building.timeZoneId).format('MMM Do');
            let numberOfDaysInMonth = moment().tz(building.timeZoneId).diff(moment().tz(building.timeZoneId).startOf('month'), 'days');
            let percOfTime = this.getPercent(numberOfDaysInMonth, moment().tz(building.timeZoneId).daysInMonth());
            let targetModel = new TargetModel();
            targetModel.question = `How often is ${building.name}'s baseline usage on target?`;
            targetModel.unit = 'days';
            targetModel.title = 'Baseload';
            targetModel.when = this.$translate.instant('insight.when.Baseload');
            targetModel.answer = this.getAnswer(baseloadStats, building);
            targetModel.formattedAnswer = `${targetModel.answer} ${this.$translate.instant('insight.answer.Days')}`;
            targetModel.target = this.getTarget(baseloadStats, building);
            targetModel.showTarget = !_.isNull(targetModel.target);
            targetModel.showDonut = !_.isNull(targetModel.target);
            targetModel.formattedTarget = `${this.$translate.instant('insight.target.Target')} ${this.$filter('number')(targetModel.target, '0')} this.$translate.instant('insight.answer.Days')`;
            targetModel.percentage = this.getPercent(targetModel.answer, targetModel.target);
            targetModel.color = this.getColor(targetModel.answer, targetModel.target, targetModel.percentage, percOfTime);
            targetModel.iconColor = targetModel.color;
            targetModel.tooltip = `${this.$translate.instant('insight.when_tooltip.Baseload')} <span style="color:${targetModel.color}">
            ${this.$filter('number')(targetModel.percentage, '0,0')}%</span> of total days`;
            targetModel.icon = this.getIcon(targetModel.answer, targetModel.target, targetModel.percentage, percOfTime);
            targetModel.timeElapsedPercentage = percOfTime;
            targetModel.timeElapsedLabel = today;
            return this.$q.when(targetModel);
        }

        private getAnswer(data, building) {
            let now = moment().tz(building.timeZoneId).startOf('day');
            let thisMonth = parseInt(moment().tz(building.timeZoneId).format('M MM'));
            let row = data[thisMonth - 1];
            return row.numGoodDays ? row.numGoodDays : 0;
        }

        private getTarget(data, building) {
            let numberOfDaysInMonth = moment().tz(building.timeZoneId).daysInMonth();
            return Math.round(numberOfDaysInMonth * (BaseloadService.TARGET_GOOD_DAYS_PERCENT / 100));
        }
    }
    angular.module('energyInsights').service('BaseloadService', BaseloadService);
}
