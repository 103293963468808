namespace aq.ui {
    class _ActivityIssueHistory {

        private issue: string;
        private issueAlertHistory: object[];
        private noHistory: boolean;

        /* @ngInject */
        constructor(
            private resolver: aq.services.Resolver,
            private RestangularV3: restangular.IService
        ) {
        }

        public $onInit() {
            this.resolver(async () => {
                const historyPromise = this.RestangularV3
                    .one('issues', this.issue)
                    .one('history')
                    .get({noCache: moment().valueOf()});
                const issuePromise = this.RestangularV3
                    .one('issues', this.issue)
                    .get({noCache: moment().valueOf()});
                const [issue, history] = await Promise.all([issuePromise, historyPromise]);
                this.issueAlertHistory = this.buildCompactIssueListForAlert(issue, history);
                if (!this.issueAlertHistory) {
                    this.noHistory = true;
                }
            });
        }

        private buildCompactIssueListForAlert(alert, history): IssueHistory[] {
            const issues: IssueHistory[] = [];
            const group = {};
            const orderedIssues = [];
            if (!alert) {
                return [];
            }
            const excludeIssueId = !alert.issueClosedOn ? alert.currentIssueId : null;
            history.sort((a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf());
            history.forEach((item) => {
                if (item.issueId && item.issueId != excludeIssueId) {
                    if (!group[item.issueId]) {
                        group[item.issueId] = [];
                        orderedIssues.push(item.issueId);
                    }
                    group[item.issueId].push(item);
                }
            });
            for (const key in group) {
                group[key].sort((a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf());
            }
            orderedIssues.forEach((issueId) => {
                const list = group[issueId];
                const currentIssue = {
                    openedOn: list[0].timestamp,
                    closedOn: list[list.length - 1].timestamp,
                    issueId
                } as any;
                issues.push(currentIssue);
            });
            issues.reverse();
            return issues;
        }

    }

    export const ActivityIssueHistory: ng.IComponentOptions = {
        controller: _ActivityIssueHistory,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/activities/issueHistory/history-list.html',
        bindings: {
            issue: '<'
        }
    };

    angular
        .module('aq.ui')
        .component('activityIssueHistory', ActivityIssueHistory);
}
