angular.module('charting')
    .filter('bestFitUnit', ['$filter', 'OptionsService', function ($filter, OptionsService) {
        return function (maxValue, unit) {
            var converted = $filter('toUnit')(maxValue, unit);
            if (converted > 0 && converted < 2 && unit.subunit) {
                unit = OptionsService.getUnitByEnumName(unit.subunit);
            }
            return unit;
        };
    }])
    .filter('backFromUnit', ['$filter', function ($filter) {
        return function (input, unit) {
            if (_.isNull(input) || _.isUndefined(input)) {
                return null;
            }
            var converted = input / unit.conversionMultiplier;
            return converted;
        };
    }])
    .filter('toBestFitUnitWithoutLabel', ['$filter', function ($filter) {
        return function (input, callUnit, stripIntensity) {
            var unit = $filter('bestFitUnit')(input, callUnit);
            return $filter('toUnit')(input, unit, true);
        };
    }])
    .filter('toBestFitUnitWithLabel', ['$filter', function ($filter) {
        return function (input, callUnit, stripIntensity) {
            var unit = $filter('bestFitUnit')(input, callUnit);
            return $filter('toUnitWithLabel')(input, unit, stripIntensity);
        };
    }])
    .filter('stringDate', ['UserService', function (UserService) {
        return function (period, dateStart, dateEnd) {
            if (!period || !dateStart || !dateEnd) {
                return '';
            }
            var startDateString;
            var endDateString;

            var hourFormat = UserService.getTimeFormat();

            if (+period === +moment.duration(1, 'hours')
                || +period === +moment.duration(15, 'minutes')
                || +period === +moment.duration(1, 'minutes')) {
                startDateString = dateStart.format('ddd MMM Do, ' + hourFormat);
                if (dateStart.day() === dateEnd.day()) {
                    endDateString = dateEnd.format(hourFormat);
                } else {
                    endDateString = dateEnd.format('MMM Do ' + hourFormat);
                }
            } else if (+period === +moment.duration(1, 'days')) {
                endDateString = dateEnd.format('ddd MMM Do ' + hourFormat);
                if (dateStart.hours() !== dateEnd.hours() || dateStart.minutes() !== dateEnd.minutes()) {
                    startDateString = dateStart.format('ddd MMM Do ' + hourFormat);
                } else {
                    startDateString = dateStart.format('ddd MMM Do');
                }
            } else if (+period === +moment.duration(1, 'weeks')) {
                startDateString = dateStart.format('ddd MMM Do');
                endDateString = dateEnd.format('MMM Do');
            } else if (+period === +moment.duration(1, 'months')) {
                if (dateEnd.month() == dateStart.month()) {
                    startDateString = dateStart.format('MMM YYYY');
                } else {
                    startDateString = dateStart.format('MMM YYYY');
                    endDateString = dateEnd.format('MMM YYYY');
                }
            } else if (+period === +moment.duration(1, 'years')) {
                startDateString = dateStart.format('MMM YYYY');
                endDateString = dateEnd.format('MMM YYYY');
            } else {
                endDateString = dateEnd.format('ddd MMM Do H:mm');
                if (dateStart.hours() !== dateEnd.hours() || dateStart.minutes() !== dateEnd.minutes()) {
                    startDateString = dateStart.format('ddd MMM Do H:mm');
                } else {
                    startDateString = dateStart.format('ddd MMM Do');
                }
            }

            var dateString = startDateString;
            if (endDateString) {
                dateString += ' - ' + endDateString;
            }
            return dateString;

        };
    }]);
