/**
 * Service that provides intervals based on period selection
 * Types of period intervals are configured in timeSelector array
 * Configuration of all period intervals can be found at
 * https://docs.google.com/spreadsheets/d/1-HADO6HJ-UpcIDuPT7A_jfGqpWT__PhCTajsVDV7eX0/edit#gid=292020152
 */
    angular.module('aq.ui.intervalSelector').service('intervalService', function () {

    var service: any = {};

    /**
     * Enum interval durations objects based on
     * app/models/api/data/common/parameters/ApiInterval.java
     *   0: 1min interval
     *   1: 15min interval
     *   2: 1h interval
     *   3: 1d interval
     *   4: 1m interval
     * @type {*}
     */
    var Interval = {
        'ONE_MIN': {
            duration: moment.duration(1, 'minutes'),
            label: '1 Min',
            value: '1min'
        },
        'FIFTEEN_MINUTES': {
            duration: moment.duration(15, 'minutes'),
            label: '15 Min',
            value: '15min'
        ,},
        'ONE_HOUR': {
            duration: moment.duration(1, 'hours'),
            label: 'Hour',
            value: '1h'
        },
        'ONE_DAY': {
            duration: moment.duration(1, 'days'),
            label: 'Day',
            value: '1d'
        },
        'ONE_MONTH': {
            duration: moment.duration(1, 'months'),
            label: 'Month',
            value: '1mon'
        }
    }
    service.Interval = Interval;

    /**
     * Enumeration for time period types, details config can be found here:
     * https://docs.google.com/spreadsheets/d/1-HADO6HJ-UpcIDuPT7A_jfGqpWT__PhCTajsVDV7eX0/edit#gid=292020152
     */
    var TimePeriodType = {
        TRAILING: {
            start: function() { return this.timeStart; },
            end: function() { return this.timeEnd; }
        },
        THIS: {
            start: function() { return this.timeStart.startOf(this.momentUnit).add(this.duration); },
            end: function() { return this.timeEnd.startOf(this.momentUnit).add(this.duration); }
        },
        LAST: {
            start: function() { return this.timeStart.startOf(this.momentUnit); },
            end: function() { return this.timeEnd.startOf(this.momentUnit); }
        },
        TO_DATE: {
            start: function() { return this.timeStart.startOf(this.momentUnit); },
            end: function() { return moment(); },
            byUnit: true
        }
    };

    /**
     * https://docs.google.com/spreadsheets/d/1-HADO6HJ-UpcIDuPT7A_jfGqpWT__PhCTajsVDV7eX0/edit#gid=292020152
     * Array of constraints objects:
     * @property period - Period name
     * @property duration - Moment duration object, with this is less complex to do calculations
     * @property momentUnit - moment string when using it with startOf method: http://momentjs.com/docs/#/manipulating/start-of/
     * @property availableIntervals - Array of available interval enums for certain constraint
     * @function matchesDuration - returns boolean if selected period is within timeframe
     * @function getDefaultInterval - returns Interval we want to use as the default for the given unit and duration, null otherwise
     * @type {*[]}
     */
    var constraints = [
        {
            period: 'Hour',
            duration: moment.duration(1, 'hours'),
            momentUnit: 'hour',
            availableIntervals: [Interval.ONE_MIN, Interval.FIFTEEN_MINUTES],
            matchesDuration: function(duration) {
                // we add one minute because on initialization there is the possibility
                // that there will be 1 millisecond difference between start and end period
                // initialization which will result that 1 hour selection will not match - this
                // difference will manifest as 1hour and 1millisecond and will not match
                // condition bellow
                var durationMinutes = Math.floor(duration.asMinutes());
                return durationMinutes >= 0 && durationMinutes <= 61;
            },
            getDefaultInterval: function(unit) {
                return Interval.FIFTEEN_MINUTES;
            }
        },
        {
            period: 'Day',
            duration: moment.duration(1, 'days'),
            momentUnit: 'day',
            availableIntervals: [Interval.FIFTEEN_MINUTES, Interval.ONE_HOUR],
            matchesDuration: function(duration) {
                var durationHours = Math.floor(duration.asHours());
                return durationHours >= 1 && durationHours <= 24;
            },
            getDefaultInterval: function(unit) {
                if (unit.isSummable) {
                    return Interval.ONE_HOUR;
                } else {
                    return Interval.FIFTEEN_MINUTES;
                }
            }
        },
        {
            period: 'Week',
            duration: moment.duration(7, 'days'),
            momentUnit: 'week',
            availableIntervals: [Interval.FIFTEEN_MINUTES, Interval.ONE_HOUR, Interval.ONE_DAY],
            matchesDuration: function(duration) {
                var durationDays = Math.floor(duration.asDays());
                return durationDays >= 1 && durationDays <= 7;
            },
            getDefaultInterval: function(unit) {
                if (unit.isSummable) {
                    return Interval.ONE_HOUR;
                } else {
                    return Interval.FIFTEEN_MINUTES;
                }
            }
        },
        {
            period: 'Month',
            duration: moment.duration(1, 'months'),
            momentUnit: 'month',
            availableIntervals: [Interval.ONE_HOUR, Interval.ONE_DAY],
            matchesDuration: function(duration) {
                var durationDays = Math.floor(duration.asDays());
                return durationDays >= 7 && durationDays <= 31;
            },
            getDefaultInterval: function(unit) {
                if (unit.isSummable) {
                    return Interval.ONE_DAY;
                } else {
                    return Interval.ONE_HOUR;
                }
            }
        },
        {
            period: 'Year',
            duration: moment.duration(1, 'years'),
            momentUnit: 'year',
            availableIntervals: [Interval.ONE_DAY, Interval.ONE_MONTH],
            matchesDuration: function(duration) {
                var durationDays = Math.floor(duration.asDays());
                return durationDays >= 60;
            },
            getDefaultInterval: function(unit) {
                if (unit.isSummable) {
                    return Interval.ONE_MONTH;
                } else {
                    return Interval.ONE_DAY;
                }
            }
        },
        {
            period: 'DTD',
            duration: moment.duration(1, 'days'),
            momentUnit: 'day',
            availableIntervals: [Interval.ONE_MIN, Interval.FIFTEEN_MINUTES, Interval.ONE_HOUR],
            matchesDuration: function(duration) {
                var durationDays = Math.floor(duration.asDays());
                return durationDays >= 60;
            },
            getDefaultInterval: function(unit) {
                return null;
            }
        },
        {
            period: 'WTD',
            momentUnit: 'week',
            duration: moment.duration(1, 'weeks'),
            availableIntervals: [Interval.FIFTEEN_MINUTES, Interval.ONE_HOUR, Interval.ONE_DAY],
            matchesDuration: function(duration) {
                var durationDays = Math.floor(duration.asDays());
                return durationDays >= 60;
            },
            getDefaultInterval: function(unit) {
                return null;
            }
        },
        {
            period: 'MTD',
            momentUnit: 'month',
            duration: moment.duration(1, 'months'),
            availableIntervals: [Interval.ONE_HOUR, Interval.ONE_DAY],
            matchesDuration: function(duration) {
                var durationDays = Math.floor(duration.asDays());
                return durationDays >= 31;
            },
            getDefaultInterval: function(unit) {
                if (unit.isSummable) {
                    return Interval.ONE_DAY;
                } else {
                    return Interval.ONE_HOUR;
                }
            }
        },
        {
            period: 'QTD',
            momentUnit: 'quarter',
            duration: moment.duration(6, 'months'),
            availableIntervals: [Interval.ONE_DAY, Interval.ONE_MONTH],
            matchesDuration: function(duration) {
                var durationDays = Math.floor(duration.asDays());
                return durationDays >= 60;
            },
            getDefaultInterval: function(unit) {
                return null;
            }
        },
        {
            period: 'All Time',
            momentUnit: 'year',
            availableIntervals: [Interval.ONE_DAY, Interval.ONE_MONTH],
            matchesDuration: function(duration) {
                var durationDays = Math.floor(duration.asDays());
                return durationDays >= 60;
            },
            getDefaultInterval: function(unit) {
                return null;
            }
        }
    ];

    /**
     * Get constraint object by period property name, creates copy of constraint object and
     * extends it with defaultInterval, start and end properties
     * @param name - period constraint.period
     * @param interval - enum
     * @param type - {TimePeriodType} a one of the enumeration values.
     * @returns {*}
     */
    function getConstraintByName(name, defaultInterval, type) {
        var constraint = _.find(constraints, { 'period': name });
        constraint.defaultInterval = defaultInterval;

        var c = angular.copy(constraint);

        c.prev = function() {
            if (type.byUnit) {
                c.timeStart.subtract(1, this.momentUnit);
            } else {
                c.timeStart.subtract(this.duration);
            }
            c.timeEnd.subtract(this.duration);
        }

        c.next = function() {
            if (type.byUnit) {
                c.timeStart.add(1, this.momentUnit);
            } else {
                c.timeStart.add(this.duration);
            }
            c.timeEnd.add(this.duration);
        }

        c.init = function() {
            c.timeStart = moment();
            c.timeEnd = moment().add(c.duration);
            c.prev();
        }

        c.defaultInterval = defaultInterval.value;
        c.start = type.start;
        c.end = type.end;

        return c;
    }

    /**
     * Array of period types for time selection
     * @type {*[]}
     */
    service.getTimeSelector = function() {
        return [
            {
                name: 'Trailing',
                keepSelectedConstraint: true,
                periods: [
                    getConstraintByName('Hour', Interval.ONE_MIN, TimePeriodType.TRAILING),
                    getConstraintByName('Day', Interval.FIFTEEN_MINUTES, TimePeriodType.TRAILING),
                    getConstraintByName('Week', Interval.ONE_HOUR, TimePeriodType.TRAILING),
                    getConstraintByName('Month', Interval.ONE_HOUR, TimePeriodType.TRAILING),
                    getConstraintByName('Year', Interval.ONE_DAY, TimePeriodType.TRAILING)
                ]
            },
            {
                name: 'This',
                keepSelectedConstraint: true,
                periods: [
                    getConstraintByName('Hour', Interval.ONE_MIN, TimePeriodType.THIS),
                    getConstraintByName('Day', Interval.FIFTEEN_MINUTES, TimePeriodType.THIS),
                    getConstraintByName('Week', Interval.FIFTEEN_MINUTES, TimePeriodType.THIS),
                    getConstraintByName('Month', Interval.ONE_DAY, TimePeriodType.THIS),
                    getConstraintByName('Year', Interval.ONE_MONTH, TimePeriodType.THIS)
                ]

            },
            {
                name: 'Last',
                keepSelectedConstraint: true,
                periods: [
                    getConstraintByName('Hour', Interval.ONE_MIN, TimePeriodType.LAST),
                    getConstraintByName('Day', Interval.FIFTEEN_MINUTES, TimePeriodType.LAST),
                    getConstraintByName('Week', Interval.FIFTEEN_MINUTES, TimePeriodType.LAST),
                    getConstraintByName('Month', Interval.ONE_DAY, TimePeriodType.LAST),
                    getConstraintByName('Year', Interval.ONE_MONTH, TimePeriodType.LAST)
                ]
            },
            {
                name: 'To Date',
                periods: [
                    getConstraintByName('DTD', Interval.FIFTEEN_MINUTES, TimePeriodType.TO_DATE),
                    getConstraintByName('WTD', Interval.FIFTEEN_MINUTES, TimePeriodType.TO_DATE),
                    getConstraintByName('MTD', Interval.ONE_DAY, TimePeriodType.TO_DATE),
                    // TODO: enable this once we have quarter aggregation supported
                    // getConstraintByName('QTD', Interval.ONE_MONTH, TimePeriodType.TO_DATE),
                    getConstraintByName('All Time', Interval.ONE_MONTH, TimePeriodType.TO_DATE)
                ]
            }
        ];
    }

    /**
     * Based on duration object that should be calculated from start and end date it will
     * return array of available intervals
     * @param duration - Moment object calculate from start and end date selection
     * @returns {Array}
     */
    service.getAvailableIntervals = function(start, end) {

        if (!start || !end) return;

        var duration = moment.duration(moment(end).diff(moment(start)));

        var constraint = _(constraints)
            .filter(function(c) {
                return c.matchesDuration(duration);
            })
            .first();

        return constraint.availableIntervals;
    }

    /**
     * Based on duration object that should be calculated from start and end date it will
     * return the sensible default for the given unit
     * @param start - Moment object calculate from start and end date selection
     * @param end - Moment object calculate from start and end date selection
     * @param unit - RealUnit
     * @returns {Interval}
     */
    service.getDefaultIntervalForUnit = function(start, end, unit) {

        if (!start || !end) return;

        if (!unit) {
            return service.getAvailableIntervals(start, end)[0];
        }

        var duration = moment.duration(moment(end).diff(moment(start)));

        var constraint = _(constraints)
            .filter(function(c) {
                return c.matchesDuration(duration);
            })
            .first();

        return constraint.getDefaultInterval(unit);
    }

    /**
     * Returns enum from Interval based on selected aggregation.
     * @param aggregation
     * @returns {*}
     */
    service.getIntervalByDuration = function (interval) {
        var key = _.findKey(Interval, function(i) {
            return i.value === interval;
        });

        return Interval[key];
    }

    return service;
});
