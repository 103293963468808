declare const numeral: Function;
angular.module('aq.admin.dataViewer').factory('PropertyGenerator', function() {

    var service: any = {};

    // TODO - this object is temporarily hardcoded, it will be deleted
    // one backed part is added
    var settings = {
        accounts: {
            buildings: {
                order: 1,
                label: "Buildings"
            },
            buildingGroups: {
                order: 2,
                label: "Building Groups"
            },
            roles: {
                order: 3,
                label: "Roles"
            },
            accountMetrics: {
                order: 99,
                label: "Account Metrics"
            },
            appAccess: {
                order: 99,
                label: "App Access"
            },
            profiles: {
                order: 99,
                label: "Profiles"
            },
            details: {
                name: {
                    field: "accountName",
                    label: "Name"
                }
            }
        },
        buildings: {

            sources: {
                order: 1,
                label: "Sources"
            },
            meters: {
                order: 2,
                label: "Meters"
            },
            floors: {
                order: 1,
                label: "Floors"
            },
            spaces: {
                order: 1,
                label: "Spaces"
            },
            tenants: {
                order: 1,
                label: "Tenants"
            },
            collectors: {
                order: 1,
                label: "Collectors"
            },
            details: {
                name: {
                    field: "name",
                    label: "Name"
                },
                occupancy: {
                    field: "occupancyPercentage",
                    label: "Occupancy"
                },
                size: {
                    field: "size",
                    label: "Square Feet",
                    format: '0,0'
                },
                year: {
                    field: "constructionYear",
                    label: "Year"
                },
                address: {
                    field: "address",
                    label: "Address"
                }

            }
        },
        meters: {
            points: {
                order: 1,
                label: "Points"
            },
            details: {
                name: {
                    field: "name",
                    label: "Name"
                }
            }
        },
        sources: {
            inputs: {
                order: 1,
                label: "Inputs"
            },
            circuits: {
                label: "Circuits"
            },
            details: {
                name: {
                    field: "name",
                    label: "Name"
                }
            }
        }

    }

    /**
     * Generate list properties for selected object, e.g. account object can have buildings list
     * property, building groups, metrics etc.
     * List generation is based on settings for individual object, so we can set order of list 
     * items in list and we can put alias for each property
     */
    service.generateProperties = function(object) {
        // determine which settings should we use for generating list
        var current = settings[object.route];
        var result = [];

        _.each(object, function(value, key) {
            //if (_.isArray(value)) { // if this is enabled it will show all array properties on object,
                                      // this is just a temporarily code until we don't have backend implemented
            if (_.isArray(value) && current && current[key]) {
                var prop = {
                    key: key,
                    // set label, if custom label is not present in settings then take
                    // property name
                    label: current ? current[key] ? current[key].label : key : key,
                    // set order of item in list, if order is not present then add order no 99
                    // which will set item always at the end of the list
                    order: current ? current[key] ? current[key].order : 99 : 99,
                    count: value.length, // calculate how many items are in list
                    object: object
                }
                result.push(prop);
            };
        })
        return _.sortBy(result, function(item) {
            return item.order;
        });    
    }

    /**
     * Based on individual settings it will generate list of
     * properties that will be shown in details section of selected object, we can choose
     * which property we want to show in details section and we can set custom alias for each one
     */
    service.generateDetails = function(object) {
        // determine which settings should we use for generating details list
        var current = settings[object.route];
        if (!current) return;
        var details = _.map(current.details, function(item) {
            return {
                label: item.label,
                value: item.format ? numeral(object[item.field]).format(item.format) : object[item.field]
            }
        })

        var result = [];
        var columnSize = 3; // number of items in column

        // split all details into columns (each with three items)
        _.times(3, function(n) {
            var slice = details.slice((columnSize * n), (columnSize * n) + columnSize);
            result.push(slice);
        });
        return result;
    }

    return service;
})
