var aq;
(function (aq) {
    var ui;
    (function (ui) {
        var AqVerifyBuildingPersonnelStepCtrl = /** @class */ (function () {
            /* @ngInject */
            function AqVerifyBuildingPersonnelStepCtrl($mdDialog, $scope) {
                this.$mdDialog = $mdDialog;
                this.$scope = $scope;
            }
            AqVerifyBuildingPersonnelStepCtrl.prototype.$onInit = function () {
                var _this = this;
                this.expandPersonnel = {};
                this.expandUnassigned = {
                    personnel: '',
                    expanded: false
                };
                this.unassignedUsers = [];
                this.buildingPersonnelCount = {};
                this.displayPersonnelLimit = 5;
                _.each(this.buildings, function (building) {
                    _this.expandPersonnel[building.index] = {
                        personnel: _this.getBuildingPersonnel(building.personnel, _this.displayPersonnelLimit),
                        expanded: false
                    };
                    _this.setBuildingPersonnelCount(building);
                });
                this.validateUsersAssignment();
            };
            AqVerifyBuildingPersonnelStepCtrl.prototype.setBuildingPersonnelCount = function (building) {
                var personaMap = { 'Other': 0 };
                _.each(this.personas, function (persona) {
                    personaMap[persona] = 0;
                });
                var visibleBuildingPersonnel = this.getVisibleBuildingPersonnel(building.personnel);
                this.buildingPersonnelCount[building.index] = angular.extend(personaMap, _.countBy(visibleBuildingPersonnel, function (employee) { return employee.user.persona || 'Other'; }));
                if (this.buildingPersonnelCount[building.index]['Other'] > 0) {
                    this.hasOthers = true;
                }
            };
            AqVerifyBuildingPersonnelStepCtrl.prototype.toggleBuildingPersonnelView = function (building, $event) {
                $event.stopPropagation();
                var expandData = this.expandPersonnel[building.index];
                expandData.expanded = !expandData.expanded;
                if (expandData.expanded) {
                    expandData.personnel = this.getBuildingPersonnel(building.personnel, 100);
                }
                else {
                    expandData.personnel = this.getBuildingPersonnel(building.personnel, this.displayPersonnelLimit);
                }
            };
            AqVerifyBuildingPersonnelStepCtrl.prototype.getBuildingPersonnel = function (personnel, limit) {
                var visibleBuildingPersonnel = this.getVisibleBuildingPersonnel(personnel);
                var limitedPersonnel = _.take(visibleBuildingPersonnel, limit);
                var names = _.map(limitedPersonnel, function (employee) { return employee.user.firstName + " " + employee.user.lastName; });
                var result = names.join(', ');
                if (visibleBuildingPersonnel.length > limit) {
                    result += '...';
                }
                return result;
            };
            AqVerifyBuildingPersonnelStepCtrl.prototype.editBuildingPersonnel = function (building, isNew) {
                var _this = this;
                var visiblePersonnel = this.getVisibleBuildingPersonnel(building.personnel);
                var notVisibleBuildingPersonnel = _.differenceBy(building.personnel, visiblePersonnel, function (item) { return item.id; });
                this.$mdDialog.show({
                    controller: 'EditBuildingPersonnelModalCtrl as vm',
                    templateUrl: 'app/components/verifySteps/modals/editBuildingPersonnelModal.html',
                    clickOutsideToClose: false,
                    locals: {
                        editBuildingPersonnel: building,
                        editBuildingPersonnelUsers: this.users,
                        isNew: isNew
                    }
                })
                    .then(function (updatedBuilding) {
                    var _a;
                    var oldIds = _.map(building.personnel, function (p) { return p.index !== undefined ? p.index : p.id; });
                    var newIds = _.map(updatedBuilding.personnel, function (p) { return p.index !== undefined ? p.index : p.id; });
                    var intersection = _.intersection(oldIds, newIds);
                    var isDiff = intersection.length < oldIds.length || intersection.length < newIds.length;
                    if (isDiff) {
                        building.isUpdatePersonnel = true;
                        building.personnel = updatedBuilding.personnel;
                    }
                    if (notVisibleBuildingPersonnel.length > 0) {
                        (_a = building.personnel).push.apply(_a, notVisibleBuildingPersonnel);
                    }
                    var expandData = _this.expandPersonnel[building.index];
                    expandData.expanded = false;
                    expandData.personnel = _this.getBuildingPersonnel(building.personnel, _this.displayPersonnelLimit);
                    _this.setBuildingPersonnelCount(building);
                })
                    .catch(function () {
                    if (isNew) {
                        _.remove(_this.buildings, building);
                    }
                })
                    .finally(function () {
                    _this.validateUsersAssignment();
                });
            };
            AqVerifyBuildingPersonnelStepCtrl.prototype.validateUsersAssignment = function () {
                var _this = this;
                this.unassignedUsers = _.filter(this.users, function (user) {
                    return _.every(_this.buildings, function (building) {
                        return _.findIndex(building.personnel, function (personnel) { return personnel.user && personnel.user.id === user.id; }) == -1;
                    });
                });
                var isValid = this.unassignedUsers.length == 0;
                this.isAllUsersAssigned = isValid;
                this.expandUnassigned.personnel = this.getUnassignedUsersFormatted();
                this.$scope.$emit('buildingPersonnelValidation', { isValid: isValid });
            };
            AqVerifyBuildingPersonnelStepCtrl.prototype.getPersonaCountHeader = function (persona) {
                if (!persona) {
                    return '';
                }
                return persona.replace('_', ' ') + "s";
            };
            AqVerifyBuildingPersonnelStepCtrl.prototype.getUnassignedUsersFormatted = function () {
                var users = this.expandUnassigned.expanded ? this.unassignedUsers : _.take(this.unassignedUsers, this.displayPersonnelLimit);
                var formattedUsers = _.map(users, function (user) { return user.firstName + " " + user.lastName; }).join(', ');
                if (!this.expandUnassigned.expanded && this.unassignedUsers.length > this.displayPersonnelLimit) {
                    formattedUsers = formattedUsers + ", and " + (this.unassignedUsers.length - this.displayPersonnelLimit) + " more";
                }
                return formattedUsers;
            };
            AqVerifyBuildingPersonnelStepCtrl.prototype.toggleUnassignedPersonnelView = function () {
                this.expandUnassigned.expanded = !this.expandUnassigned.expanded;
                this.expandUnassigned.personnel = this.getUnassignedUsersFormatted();
            };
            AqVerifyBuildingPersonnelStepCtrl.prototype.getVisibleBuildingPersonnel = function (personnel) {
                var _this = this;
                return _.filter(personnel, function (employee) {
                    if (employee.user != null) {
                        return _.some(_this.users, function (user) { return user.index == employee.user.index; });
                    }
                });
            };
            return AqVerifyBuildingPersonnelStepCtrl;
        }());
        ui.AqVerifyBuildingPersonnelStepCtrl = AqVerifyBuildingPersonnelStepCtrl;
        angular.module('aq.ui').component('aqVerifyBuildingPersonnelStep', {
            controller: AqVerifyBuildingPersonnelStepCtrl,
            controllerAs: 'vm',
            templateUrl: 'app/components/verifySteps/aqVerifyBuildingPersonnelStep.html',
            bindings: {
                users: '<',
                personas: '<',
                buildings: '<',
                header: '<'
            }
        });
    })(ui = aq.ui || (aq.ui = {}));
})(aq || (aq = {}));
