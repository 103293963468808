var aq;
(function (aq) {
    var tenantbilling;
    (function (tenantbilling) {
        ;
        var AddEditTenantChargeCtrl = /** @class */ (function (_super) {
            __extends(AddEditTenantChargeCtrl, _super);
            /* @ngInject */
            function AddEditTenantChargeCtrl($mdDialog, $state, Auth, accountId, buildingId, tenantId, tenantName, tenantServices, tenantCharge, allocations, collectors, loading, unlinkedDevices, TenantServiceHelper) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.$state = $state;
                _this.Auth = Auth;
                _this.accountId = accountId;
                _this.buildingId = buildingId;
                _this.tenantId = tenantId;
                _this.tenantName = tenantName;
                _this.tenantServices = tenantServices;
                _this.tenantCharge = tenantCharge;
                _this.allocations = allocations;
                _this.collectors = collectors;
                _this.loading = loading;
                _this.unlinkedDevices = unlinkedDevices;
                _this.TenantServiceHelper = TenantServiceHelper;
                if (!_this.tenantCharge) {
                    _this.tenantCharge = {
                        id: null,
                        tenant: _this.tenantId,
                        active: true,
                        service: ''
                    };
                }
                _this.positiveCollectors = [];
                _this.subtractedCollectors = [];
                _this.removedAllocations = [];
                if (_this.tenantCharge.type == 'METERED') {
                    _this.positiveCollectors = _this.mapCollectorFromChargeAllocations(_this.allocations, false);
                    _this.subtractedCollectors = _this.mapCollectorFromChargeAllocations(_this.allocations, true);
                }
                _this.positiveCollectorAllocationSet = {};
                _this.subtractedCollectorAllocationSet = {};
                _this.tenantCharge.service = _this.tenantCharge.service.toString();
                return _this;
            }
            AddEditTenantChargeCtrl.prototype.isMeterAddedOrFee = function () {
                if (this.isTenantChargeServiceMetered()) {
                    return this.positiveCollectors.length > 0 || this.subtractedCollectors.length > 0;
                }
                return true;
            };
            AddEditTenantChargeCtrl.prototype.cancel = function (data) {
                this.$mdDialog.cancel();
            };
            AddEditTenantChargeCtrl.prototype.hide = function () {
                this.$mdDialog.hide();
            };
            AddEditTenantChargeCtrl.prototype.getTypeForMethod = function (service) {
                if (service.method == 'FEE') {
                    return 'FIXED';
                }
                else if (service.method == 'METERED_USAGE') {
                    return 'METERED';
                }
                else {
                    return service.method;
                }
            };
            AddEditTenantChargeCtrl.prototype.saveTenantCharge = function () {
                var _this = this;
                this.getInvalidMeterAllocations();
                if (this.invalidAllocations.length > 0) {
                    return;
                }
                var service = _.findById(this.tenantServices, this.tenantCharge.service);
                this.tenantCharge.type = this.getTypeForMethod(service);
                this.loading.start();
                var updatePromise;
                this.isSavingTenantCharge = true;
                if (this.tenantCharge.id) {
                    updatePromise = this.TenantServiceHelper.updateTenantCharge(this.tenantCharge)
                        .then(function () {
                        return _this.TenantServiceHelper.updateCollectorAllocations(_this.positiveCollectorAllocationSet, _this.subtractedCollectorAllocationSet, _this.removedAllocations);
                    });
                }
                else {
                    updatePromise = this.TenantServiceHelper.createTenantCharge(this.tenantCharge)
                        .then(function (tenantCharge) {
                        _this.tenantCharge = tenantCharge;
                        return _this.TenantServiceHelper.updateCollectorAllocations(_this.positiveCollectorAllocationSet, _this.subtractedCollectorAllocationSet, _this.removedAllocations, tenantCharge.id);
                    });
                }
                updatePromise
                    .then(function () {
                    return _this.TenantServiceHelper.syncTenantChargeValues(_this.tenantCharge);
                })
                    .then(function () {
                    _this.hide();
                })
                    .finally(function () {
                    _this.loading.stop();
                    _this.isSavingTenantCharge = false;
                });
            };
            /*
             * Find all collectors whose name contains searchText,
             *  support the metric which the serviceId supports,
             *  and is not already contained in the filter list
             */
            AddEditTenantChargeCtrl.prototype.queryCollectors = function (searchText, serviceId, filtered) {
                var tenantService = _.find(this.tenantServices, function (service) { return service.id == serviceId; });
                var metric = tenantService.serviceType;
                var collectors = _.filter(this.collectors, function (collector) {
                    return collector.name && collector.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
                        && !_.find(filtered, function (item) { return item.id == collector.id; })
                        && collector.metrics.indexOf(metric) > -1;
                });
                return collectors;
            };
            AddEditTenantChargeCtrl.prototype.mapCollectorFromChargeAllocations = function (chargeAllocations, negativeSign) {
                var _this = this;
                return _(chargeAllocations).filter(function (allocation) {
                    return allocation.negativeSign === negativeSign;
                }).map(function (allocation) {
                    var collector = angular.copy(_.find(_this.collectors, function (c) { return allocation.collector == c.id; }));
                    collector.canRemove = _.isNil(allocation.allocationPercent)
                        || allocation.allocationPercent == 0;
                    return collector;
                }).value();
            };
            AddEditTenantChargeCtrl.prototype.allocatedAcrossTenants = function (collectorId) {
                var allocation = _.find(this.allocations, function (a) { return a.collector == collectorId; });
                return allocation && !_.isNil(allocation.allocationPercent) && allocation.allocationPercent < 100;
            };
            AddEditTenantChargeCtrl.prototype.onMeterAllocationAdd = function (chip, negativeSign) {
                var _this = this;
                this.isRemoveMeterError = false;
                var newAllocation = {
                    collector: chip,
                    meteredTenantCharge: this.tenantCharge,
                    tenantName: this.tenantName,
                    negativeSign: negativeSign,
                    allocationPercent: null
                };
                chip.canRemove = true;
                this.findAllocationsByCollectorId(chip, negativeSign).then(function (sharedAllocations) {
                    var collectorId = chip.id.toString();
                    _this.processCollectorAllocations(collectorId, sharedAllocations, newAllocation);
                    _this.selectedAllocation = newAllocation.negativeSign ?
                        _this.subtractedCollectorAllocationSet[collectorId] :
                        _this.positiveCollectorAllocationSet[collectorId];
                    if (_this.selectedAllocation.length > 1) {
                        chip.isShared = true;
                    }
                    _this.TenantServiceHelper.getDevice(collectorId).then(function (device) {
                        _this.selectedDevice = device;
                        _this.unlinkedDevices = _.filter(_this.unlinkedDevices, function (deviceLink) {
                            return deviceLink.device.id != _this.selectedDevice.id;
                        });
                    });
                });
            };
            AddEditTenantChargeCtrl.prototype.onMeterAllocationRemove = function (chip, negativeSign) {
                var _this = this;
                this.isRemoveMeterError = false;
                var collectorId = chip.id.toString();
                var collectorAllocationSet = negativeSign ?
                    this.subtractedCollectorAllocationSet :
                    this.positiveCollectorAllocationSet;
                if (collectorAllocationSet[collectorId]) {
                    this.processRemovingMeterAllocation(this.tenantName, collectorId, collectorAllocationSet[collectorId], negativeSign);
                }
                else {
                    this.findAllocationsByCollectorId(chip, negativeSign)
                        .then(function (sharedAllocations) {
                        collectorAllocationSet[collectorId] = sharedAllocations;
                        _this.processRemovingMeterAllocation(_this.tenantName, collectorId, collectorAllocationSet[collectorId], negativeSign);
                    });
                }
            };
            AddEditTenantChargeCtrl.prototype.onMeterAllocationSelect = function (chip, negativeSign) {
                var _this = this;
                this.isRemoveMeterError = false;
                var collectorId = chip.id.toString();
                this.TenantServiceHelper.getDevice(collectorId).then(function (device) {
                    _this.selectedDevice = device;
                });
                var collectorAllocationSet = negativeSign ?
                    this.subtractedCollectorAllocationSet :
                    this.positiveCollectorAllocationSet;
                if (!collectorAllocationSet[collectorId]) {
                    this.findAllocationsByCollectorId(chip, negativeSign)
                        .then(function (sharedAllocations) {
                        collectorAllocationSet[collectorId] = sharedAllocations;
                        _this.selectedAllocation = collectorAllocationSet[collectorId];
                    });
                    return;
                }
                this.selectedAllocation = collectorAllocationSet[collectorId];
            };
            AddEditTenantChargeCtrl.prototype.findAllocationsByCollectorId = function (chip, negativeSign) {
                var _this = this;
                var collectorId = chip.id.toString();
                this.isLoadingCollectorAllocation = true;
                return this.TenantServiceHelper.getCollectorAllocations(collectorId, negativeSign)
                    .then(function (sharedAllocations) {
                    if (sharedAllocations.length <= 1) {
                        chip.canRemove = true;
                        chip.isShared = false;
                        if (sharedAllocations.length === 1) {
                            sharedAllocations[0].allocationPercent = null;
                        }
                    }
                    else {
                        chip.isShared = true;
                    }
                    return sharedAllocations;
                })
                    .finally(function () {
                    _this.isLoadingCollectorAllocation = false;
                });
            };
            AddEditTenantChargeCtrl.prototype.navigateToDeviceDetails = function () {
                this.$state.transitionTo('aq.deviceManagement.building.device.configuration', {
                    accountId: this.accountId,
                    buildingId: this.buildingId,
                    deviceId: this.selectedDevice.id
                });
                this.cancel();
            };
            AddEditTenantChargeCtrl.prototype.navigateToDeviceManagement = function () {
                this.$state.transitionTo('aq.deviceManagement.building', {
                    accountId: this.accountId,
                    buildingId: this.buildingId
                });
                this.cancel();
            };
            AddEditTenantChargeCtrl.prototype.onAllocationPercentChange = function (editedAllocation) {
                this.getInvalidMeterAllocations();
                if (editedAllocation.tenantName == this.tenantName) {
                    var existingAllocation = _.find(this.allocations, function (a) { return a.collector == editedAllocation.collector; });
                    if (existingAllocation) {
                        existingAllocation.allocationPercent = editedAllocation.allocationPercent;
                    }
                }
            };
            AddEditTenantChargeCtrl.prototype.getInvalidAllocationsFromSet = function (allocationSet, collectors) {
                var _this = this;
                return _.filter(allocationSet, function (allocations, collectorId) {
                    if (allocations.length <= 1) {
                        return false;
                    }
                    var invalidAllocation = _this.getAllocationPercent(allocations) != 100;
                    var collector = _.find(collectors, { id: parseInt(collectorId) });
                    if (collector) {
                        var tenantAllocation = _.find(allocations, function (a) {
                            return a.tenantName == _this.tenantName;
                        });
                        collector.canRemove = !invalidAllocation &&
                            (!tenantAllocation || tenantAllocation.allocationPercent == 0);
                    }
                    return invalidAllocation;
                });
            };
            AddEditTenantChargeCtrl.prototype.getInvalidMeterAllocations = function () {
                var _this = this;
                var invalidPositiveAllocations = this.getInvalidAllocationsFromSet(this.positiveCollectorAllocationSet, this.positiveCollectors);
                var invalidSubtractedAllocations = this.getInvalidAllocationsFromSet(this.subtractedCollectorAllocationSet, this.subtractedCollectors);
                var invalidAllocations = _.concat(invalidPositiveAllocations, invalidSubtractedAllocations);
                this.invalidAllocations = _.map(invalidAllocations, function (allocations) {
                    return {
                        name: _.find(_this.collectors, function (c) { return allocations[0].collector == c.id; }).name,
                        totalPercent: _this.getAllocationPercent(allocations)
                    };
                });
            };
            AddEditTenantChargeCtrl.prototype.isTenantChargeServiceMetered = function () {
                var _this = this;
                var selectedTenantService = _.find(this.tenantServices, function (s) { return _this.tenantCharge.service == s.id; });
                if (!selectedTenantService) {
                    return false;
                }
                return selectedTenantService.method == 'METERED_USAGE';
            };
            AddEditTenantChargeCtrl.prototype.processRemovingMeterAllocation = function (tenantName, collectorId, currentCollectorAllocations, negativeSign) {
                var sumWithoutCurrentMeter = this.getAllocationPercent(currentCollectorAllocations, tenantName);
                this.isRemoveMeterError = currentCollectorAllocations.length > 1 && sumWithoutCurrentMeter != 100;
                if (this.isRemoveMeterError) {
                    if (negativeSign) {
                        this.subtractedCollectors = this.mapCollectorFromChargeAllocations(this.allocations, true);
                    }
                    else {
                        this.positiveCollectors = this.mapCollectorFromChargeAllocations(this.allocations, false);
                    }
                    this.selectedAllocation = currentCollectorAllocations;
                    return;
                }
                _.remove(currentCollectorAllocations, function (a) { return a.tenantName == tenantName; });
                this.getInvalidMeterAllocations();
                var removed = _.remove(this.allocations, function (a) { return a.collector == collectorId && a.negativeSign == negativeSign; });
                this.removedAllocations = _.concat(this.removedAllocations, removed);
                this.selectedAllocation = null;
            };
            AddEditTenantChargeCtrl.prototype.processCollectorAllocations = function (collectorId, existingAllocations, newAllocation) {
                var _this = this;
                var collectorAllocationSet = newAllocation.negativeSign ?
                    this.subtractedCollectorAllocationSet :
                    this.positiveCollectorAllocationSet;
                collectorAllocationSet[collectorId] = [];
                if (existingAllocations && existingAllocations.length > 0) {
                    collectorAllocationSet[collectorId] = _.filter(existingAllocations, function (a) { return a.tenantName != _this.tenantName; });
                    if (collectorAllocationSet[collectorId].length === 1
                        && _.isNil(collectorAllocationSet[collectorId][0].allocationPercent)) {
                        collectorAllocationSet[collectorId][0].allocationPercent = 100;
                    }
                }
                collectorAllocationSet[collectorId].push(newAllocation);
                if (collectorAllocationSet[collectorId].length == 1) {
                    newAllocation.allocationPercent = null;
                }
                else {
                    newAllocation.allocationPercent = 0;
                }
            };
            AddEditTenantChargeCtrl.prototype.getAllocationPercent = function (allocations, withoutTenantName) {
                if (withoutTenantName === void 0) { withoutTenantName = null; }
                var maxDecimalPlaces = _.max(_.map(allocations, function (allocation) {
                    if (!allocation.allocationPercent) {
                        return 0;
                    }
                    var splitAlloc = allocation.allocationPercent.toString().split('.');
                    if (splitAlloc.length > 1) {
                        return splitAlloc[1].length;
                    }
                    return 0;
                }));
                var percent = _.sumArray(_.map(allocations, function (allocation) {
                    if (withoutTenantName && allocation.tenantName == withoutTenantName) {
                        return 0;
                    }
                    return allocation.allocationPercent ? allocation.allocationPercent : 0;
                }));
                return _.round(percent, maxDecimalPlaces);
            };
            ;
            AddEditTenantChargeCtrl.prototype.getInvalidAllocationText = function (allocation) {
                return allocation.name + " (percentage sum: " + allocation.totalPercent + "%)";
            };
            return AddEditTenantChargeCtrl;
        }(aq.common.Controllers.ModalCtrl));
        tenantbilling.AddEditTenantChargeCtrl = AddEditTenantChargeCtrl;
        angular.module('tenantBilling').controller('AddEditTenantChargeCtrl', AddEditTenantChargeCtrl);
    })(tenantbilling = aq.tenantbilling || (aq.tenantbilling = {}));
})(aq || (aq = {}));
