angular.module('aq.dashboard.widgets')

// Convert pressure from millibars to inches
.filter('mbarToInches', function() {
    return function(mbar: number) {
        return (29.92 * mbar) / 1013.25;
    }
})
.filter('toPercent', function() {
    return function(n: number) {
        return n * 100;
    }
})
.filter('timestampToMS', function() {
    return function(timestamp: number) {
        return timestamp * 1000;
    }
});
