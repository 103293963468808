namespace aq.tenantbilling {
    export interface BillingMenuAction extends aq.common.models.EntityMenuAction {
        isSummary: boolean;
        isIntegration: boolean;
        inProgress?: number;
        generated?: boolean;
    }
    export class RunInvoicingCtrl {
        public showSummaryOnly = false;
        public backActionStateData;
        public actions: BillingMenuAction[];
        public currencyUnit: any;
        public totals: { serviceName: string; total: number }[];
        public status;
        public calcInterval = null;
        public CALC_MAX_CHECKS = 60;
        public CALC_CHECK_INTERVAL_MS = 1000;
        public actionLabels = {
            INVOICES: 'Download Invoices',
            ACCOUNT_SUMMARY: 'Download Account Summary',
            CHARGES_SUMMARY: 'Download Tenant Charges',
            DOWNLOAD_YARDI: 'Yardi Export',
            DOWNLOAD_ANGUS: 'Download Angus File',
            SEND_ANGUS: 'Send to Angus',
            MODIFY: 'Modify Period',
            DELETE: 'Delete Billing Period',
            DOWNLOAD_MRI: 'Download MRI File'
        };
        public chargeCategories: ChargeCategory[];
        public showCompletionMessage: boolean;
        private DOWNLOAD_TIMEOUT = 90;
        tenantLabel: string;
        /* @ngInject */
        constructor(
            private MonthlyBillingService: MonthlyBillingService,
            private RestangularV3FullResponse: restangular.IService,
            private FileHandlerService: aq.services.FileHandlerService,
            private OptionsService,
            private $mdDialog: ng.material.IDialogService,
            private Messages: aq.services.Messages,
            private $state: ng.ui.IStateService,
            private $stateParams: ng.ui.IStateParamsService,
            private $timeout: ng.ITimeoutService,
            private lastBilling: LastBillingMap,
            private meteredTenantServices: TenantService[],
            private building: aq.common.models.Building,
            private integrations: aq.common.models.Integration[],
            public step: string,
            public monthlyBilling: MonthlyTenantBilling,
            public tenantInvoices: TenantInvoice[],
            public periodTenantServices: PeriodTenantService[],
            public tenantChargeValues: TenantChargeValue[],
            public $scope,
            public $interval,
            public $q,
            private Auth: aq.services.Auth
        ) {
            if (!this.monthlyBilling) {
                this.monthlyBilling = MonthlyBillingService.initMonthlyTenantBilling(building.id);
            }
            this.currencyUnit = this.OptionsService.currencyUnit();
            this.actions = [
                {
                    isSummary: true,
                    isIntegration: false,
                    label: this.actionLabels.INVOICES,
                    generated: false,
                    icon: 'file_download',
                    cb: () => this.downloadInvoicesStatus()
                },
                {
                    isSummary: true,
                    isIntegration: false,
                    label: this.actionLabels.ACCOUNT_SUMMARY,
                    generated: false,
                    icon: 'assignment_returned',
                    cb: () => this.downloadAccountSummaryStatus()
                },
                {
                    isSummary: true,
                    isIntegration: false,
                    label: this.actionLabels.CHARGES_SUMMARY,
                    icon: 'assignment_returned',
                    cb: () => this.downloadChargesSummary()
                },
                {
                    isSummary: true,
                    isIntegration: true,
                    label: this.actionLabels.DOWNLOAD_YARDI,
                    icon: 'assignment_returned',
                    cb: () => this.downloadYardiFile()
                },
                {
                    isSummary: true,
                    isIntegration: true,
                    label: this.actionLabels.DOWNLOAD_ANGUS,
                    icon: 'content_copy',
                    cb: () => this.downloadAngusFile()
                },
                {
                    isSummary: true,
                    isIntegration: true,
                    label: this.actionLabels.SEND_ANGUS,
                    icon: 'send',
                    cb: () => this.sendToAngus()
                },
                {
                    isSummary: true,
                    isIntegration: true,
                    label: this.actionLabels.DOWNLOAD_MRI,
                    icon: 'assignment_returned',
                    cb: () => this.downloadMRIFile()
                },
                {
                    isSummary: true,
                    isIntegration: false,
                    label: this.actionLabels.MODIFY,
                    icon: 'settings',
                    cb: () => this.modifyApprovedBilling()
                },
                {
                    isSummary: false,
                    isIntegration: false,
                    label: this.actionLabels.DELETE,
                    icon: 'delete',
                    cb: () => this.deleteBilling()
                }
            ];

            this.switchToSummaryIfComplete();
            this.buildTotals();
            this.checkGenerated();

            this.status = {
                calculating: false,
                text: null,
                counter: 0
            };

            this.$scope.$on('STATUS_CHANGE', (event, changesObj) => {
                if (changesObj) {
                    this.status.text = changesObj.statusText;
                    this.status.counter = 0;
                    if (changesObj.statusText != null) {
                        this.startCalculatingCheck();
                    } else {
                        this.endCalculatingCheck();
                    }
                }
            });
            this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'unit' : 'tenant';
        }

        public getPeriodDatesDisplay() {
            if (this.monthlyBilling.startReadingDate) {
                return `${moment(this.monthlyBilling.startReadingDate).format('MM/DD/YYYY')}`
                    + ` - ${moment(this.monthlyBilling.readingDate).format('MM/DD/YYYY')}`;
            } else {
                return `${moment(this.monthlyBilling.readingDate).format('MM/DD/YYYY')}`;
            }
        }



        public getActions() {
            let actions = _.filter(this.actions, (action) => (action.isSummary === this.showSummaryOnly));
            if (this.showSummaryOnly) {
                const angusIntegration = this.getAngusIntegration();
                const mriIntegration = this.getMriIntegration();
                actions = _.filter(actions, (action) => {
                    return this.returnAction(action, angusIntegration, mriIntegration);
                });
            }
            return actions;
        }
        public returnAction(action, angusIntegration, mriIntegration) {
            if (action.generated != undefined && !action.generated) {
                return false;
            }
            if (!action.isIntegration) {
                return true;
            }
            if (this.Auth.hasFunctionality('Multifamily Tenant Billing') && (action.label === this.actionLabels.DOWNLOAD_YARDI)) {
                return true;
            }
            if (angusIntegration && (action.label === this.actionLabels.DOWNLOAD_ANGUS || action.label == this.actionLabels.SEND_ANGUS)) {
                return true;
            }
            if (mriIntegration && action.label === this.actionLabels.DOWNLOAD_MRI) {
                return true;
            }
            return false;
        }
        public getActionsInProgress() {
            return _.filter(this.actions, (action) => action.inProgress > 0);
        }
        public update(event) {
            if (event.monthlyBilling) {
                this.monthlyBilling = angular.extend({}, event.monthlyBilling);
            }
            if (event.tenantInvoices) {
                this.tenantInvoices = event.tenantInvoices;
            }
            if (event.tenantChargeValues) {
                this.tenantChargeValues = event.tenantChargeValues;
            }
            if (event.chargeCategories) {
                this.chargeCategories = event.chargeCategories;
            }
            if (event.periodTenantServices) {
                this.periodTenantServices = event.periodTenantServices;
            }
            if (event.backActionStateData) {
                this.backActionStateData = event.backActionStateData;
            }
            this.switchToSummaryIfComplete(true);
            this.buildTotals();
            this.checkGenerated();
        }
        public buildTotals() {
            this.totals = [];
            this.addToTotals(this.getMeteredTenantChargeValues());
            this.addToTotals(this.getFixedTenantChargeValues());
        }
        public checkGenerated() {
            if (this.showSummaryOnly) {
                this.checkInvoicesStatus()
                    .then((response) => {
                        const action = this.getAction(this.actionLabels.INVOICES);
                        action.generated = response.data === 'true';
                    });
                this.checkAccountSummaryStatus()
                    .then((response) => {
                        const action = this.getAction(this.actionLabels.ACCOUNT_SUMMARY);
                        action.generated = response.data === 'true';
                    });
            }
        }
        public getMeteredTenantChargeValues() {
            return _.filter(this.tenantChargeValues, { type: 'Metered' });
        }
        public getFixedTenantChargeValues() {
            return _.filter(this.tenantChargeValues, { type: 'Fixed' });
        }
        public getGrandTotal() {
            return _.sumBy(this.totals, (t) => t.total);
        }
        public deleteBilling() {
            const confirm = this.$mdDialog
                .confirm()
                .title('Are you sure you want to delete this Billing period?')
                .ok('Confirm')
                .cancel('Cancel');

            return this.$mdDialog
                .show(confirm)
                .then(() => {
                    return this.MonthlyBillingService.delete(this.monthlyBilling)
                        .then(() => {
                            this.Messages.info('Billing period successfully deleted.');
                            this.$state.go('aq.tenantbilling.building.invoicing', { deletedBillingId: this.monthlyBilling.id });
                        })
                        .catch((err) => {
                            this.Messages.error('Error deleting billing period. Please contact support.');
                        });
                });
        }
        public modifyApprovedBilling() {
            const confirm = this.$mdDialog
                .confirm()
                .title(`Confirm billing period modification`)
                .textContent(`This action will update the status of your billing period and allow you to make changes to its setup,
                review charges, and re-generate invoices.`)
                .ok('Confirm')
                .cancel('Cancel');

            return this.$mdDialog
                .show(confirm)
                .then(() => {
                    this.MonthlyBillingService.modify(this.monthlyBilling)
                        .then((result) => {
                            // result route ends with /modify, which breaks subsequent put() calls
                            return this.MonthlyBillingService.get(this.monthlyBilling.id);
                        })
                        .then((result) => {
                            this.handleModifyResult(result);
                        });
                })
                .catch((err) => {
                    if (err) {
                        this.Messages.error('Error modifying billing period');
                    }
                });
        }
        public handleModifyResult(result) {
            this.monthlyBilling = result;
            this.backActionStateData = {
                updatedBillingId: this.monthlyBilling.id,
                timeStamp: new Date()
            };
            let step = 3;
            if (this.monthlyBilling.hasManualMeters) {
                step++;
            }
            this.step = step.toString();
            this.switchToSummaryIfComplete();
        }
        public getAction(label) {
            return _.find(this.actions, { label });
        }
        public checkInvoicesStatus() {
            return this.RestangularV3FullResponse.one('billings', this.monthlyBilling.id)
                .all('download')
                .all('invoices')
                .customGET('status', { t: Date.now() });
        }
        public downloadInvoicesStatus() {
            const action = this.getAction(this.actionLabels.INVOICES);
            return this.checkInvoicesStatus()
                .then((response) => {
                    if (!action.inProgress) {
                        action.inProgress = 0;
                    }
                    if (response.data === 'true' || action.inProgress > this.DOWNLOAD_TIMEOUT) {
                        return this.downloadInvoices();
                    }
                    action.inProgress++;
                    this.$timeout(() => this.downloadInvoicesStatus(), 1000);
                })
                .catch(() => {
                    this.Messages.error('Error while generating report');
                    action.inProgress = 0;
                });
        }
        public downloadInvoices() {
            const action = this.getAction(this.actionLabels.INVOICES);
            return this.RestangularV3FullResponse.one('billings', this.monthlyBilling.id)
                .all('download')
                .withHttpConfig({ responseType: 'blob' })
                .customGET('invoices')
                .then((response) => {
                    this.FileHandlerService.handleFileDownload(response, 'application/zip');
                })
                .catch(() => {
                    this.Messages.error('Error while generating report');
                })
                .finally(() => {
                    action.inProgress = 0;
                });
        }
        public checkAccountSummaryStatus() {
            return this.RestangularV3FullResponse.one('billings', this.monthlyBilling.id)
                .all('download')
                .all('account-summary')
                .customGET('status', { t: Date.now() });
        }
        public downloadAccountSummaryStatus() {
            const action = this.getAction(this.actionLabels.ACCOUNT_SUMMARY);
            return this.checkAccountSummaryStatus()
                .then((response) => {
                    if (!action.inProgress) {
                        action.inProgress = 0;
                    }
                    if (response.data === 'true' || action.inProgress > this.DOWNLOAD_TIMEOUT) {
                        return this.downloadAccountSummary();
                    }
                    action.inProgress++;
                    this.$timeout(() => this.downloadAccountSummaryStatus(), 1000);
                })
                .catch(() => {
                    this.Messages.error('Error while generating report');
                    action.inProgress = 0;
                });
        }
        public downloadAccountSummary() {
            const action = this.getAction(this.actionLabels.ACCOUNT_SUMMARY);
            return this.RestangularV3FullResponse.one('billings', this.monthlyBilling.id)
                .all('download')
                .withHttpConfig({ responseType: 'blob' })
                .customGET('account-summary')
                .then((response) => {
                    this.FileHandlerService.handleFileDownload(response, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                })
                .catch(() => {
                    this.Messages.error('Error while generating report');
                })
                .finally(() => {
                    action.inProgress = 0;
                });
        }
        public downloadChargesSummary() {
            const action = this.getAction(this.actionLabels.CHARGES_SUMMARY);
            return this.RestangularV3FullResponse.all('reports')
                .all('charges-summary')
                .get(this.monthlyBilling.id)
                .then((response) => {
                    this.FileHandlerService.handleFileDownload(response, 'text/csv');
                })
                .catch(() => {
                    this.Messages.error('Error while generating report');
                })
                .finally(() => {
                    action.inProgress = 0;
                });
        }
        public downloadYardiFile() {
            const action = this.getAction(this.actionLabels.DOWNLOAD_YARDI);
            return this.RestangularV3FullResponse.all('reports')
                .all('yardi')
                .get(this.monthlyBilling.id)
                .then((response) => {
                    this.FileHandlerService.handleFileDownload(response, 'text/csv');
                })
                .catch(() => {
                    this.Messages.error('Error while generating report');
                })
                .finally(() => {
                    action.inProgress = 0;
                });
        }
        public downloadAngusFile() {
            const angusIntegration = this.getAngusIntegration();
            if (!angusIntegration) {
                return;
            }
            return this.RestangularV3FullResponse.one('billings', this.monthlyBilling.id)
                .one('integration', angusIntegration.id)
                .withHttpConfig({ responseType: 'blob' })
                .customGET('download')
                .then((response) => {
                    this.FileHandlerService.handleFileDownload(response, 'text/csv');
                });
        }
        public sendToAngus() {
            const angusIntegration = this.getAngusIntegration();
            if (!angusIntegration) {
                return;
            }
            const confirm = this.$mdDialog.confirm()
                .title('Are you sure you want to send data to Angus?')
                .ariaLabel('Send Data to Angus')
                .ok('Ok')
                .cancel('Cancel');
            this.$mdDialog.show(confirm)
                .then(() => {
                    this.RestangularV3FullResponse.one('billings', this.monthlyBilling.id)
                        .one('integration', angusIntegration.id)
                        .customPOST({}, 'do-job')
                        .then((response) => {
                            this.Messages.success('Successfully delivered to Angus!');
                        });
                });
        }
        public shouldShowTotalCharges() {
            if (!this.monthlyBilling || !this.monthlyBilling.id) {
                return false;
            }
            // want to show on manual meters step, or review charges if no manual meters present
            return parseInt(this.$stateParams.step) >= 3;
        }
        public getAngusIntegration() {
            return _.find(this.integrations, { appName: 'Angus' });
        }
        public switchToSummaryIfComplete(showCompletionMessage = false) {
            this.showSummaryOnly = this.MonthlyBillingService.isApprovedStatus(this.monthlyBilling.status);
            if (this.showSummaryOnly && showCompletionMessage) {
                this.showCompletionMessage = true;
            }
            if (this.showSummaryOnly && (!this.chargeCategories || this.chargeCategories.length == 0)) {
                this.MonthlyBillingService.getChargeCategories(this.monthlyBilling)
                    .then((categories) => {
                        this.chargeCategories = categories;
                    });
            }
        }
        public addToTotals(charges) {
            _.each(charges, (charge) => {
                const item = _.find(this.totals, (t) => t.serviceName == charge.serviceName);
                if (!item) {
                    this.totals.push({ serviceName: charge.serviceName, total: charge.cost });
                } else {
                    item.total += charge.cost;
                }
            });
        }
        public getServiceTotal(service: PeriodTenantService) {
            return service.usage * service.rate;
        }

        public downloadMRIFile() {
            const mriIntegration = this.getMriIntegration();
            if (!mriIntegration) {
                return;
            }
            return this.RestangularV3FullResponse.one('mri')
            .customGET('csv', {monthlyBillingId: this.monthlyBilling.id})
            .then((response) => {
                this.FileHandlerService.handleFileDownload(response, 'text/csv');
            })
            .catch((err) => {
                this.Messages.error('Error Generating Report. Please contact support.');
            });
        }

        public getMriIntegration() {
            return _.find(this.integrations, { appName: 'MRI' });
        }

        public startCalculatingCheck() {
            this.status.calculating = true;
            this.calcInterval = this.$interval(() => this.getCalculating(), this.CALC_CHECK_INTERVAL_MS);
        };

        public endCalculatingCheck(erroredOut?: boolean) {
            if (this.calcInterval) {
                this.$interval.cancel(this.calcInterval);
                this.calcInterval = null;
            }
            this.status.calculating = false;
            if (erroredOut) {
                this.status.text = null;
                this.status.counter = 0;
                this.status.calculating = false;
                this.Messages.error('There was an error calculating your total charges. Please try again, or contact an administrator if issue persists.', 15000);
            }
        };

        public getCalculating() {
            if (this.monthlyBilling && this.monthlyBilling.id && this.status.calculating) {
                this.MonthlyBillingService.getCalculating(this.monthlyBilling.id)
                    .then((calc) => {
                        return this.handleCalculating(calc);
                    })
                    .then((results) => {
                        this.handleFetchResults(results);
                    })
                    .catch(() => {
                        this.endCalculatingCheck(true);
                    });
            }
        }

        public handleFetchResults(results) {
            if (results) {
                this.parseAllResults(results);
                this.update({
                    $event: {
                        monthlyBilling: this.monthlyBilling,
                        tenantInvoices: this.tenantInvoices,
                        tenantChargeValues: this.tenantChargeValues,
                        periodTenantServices: this.periodTenantServices
                    }
                });
                this.status.text = null;
                this.status.counter = 0;
            }
        }

        public parseAllResults(results) {
            this.monthlyBilling = results[0];
            this.tenantInvoices = results[1];
            this.tenantChargeValues = results[2];
            this.periodTenantServices = results[3];
        }

        public handleCalculating(calc) {
            this.status.counter++;
            const isCalculating = calc === 'true';
            // When finished calculating, update our charges
            if (this.status.calculating && !isCalculating) {
                this.status.counter = 0;
                this.status.calculating = isCalculating;
                this.endCalculatingCheck();
                return this.$q.all([
                    this.MonthlyBillingService.get(this.monthlyBilling.id),
                    this.MonthlyBillingService.getInvoices(this.monthlyBilling),
                    this.MonthlyBillingService.getChargeValues(this.monthlyBilling),
                    this.MonthlyBillingService.getPeriodTenantServices(this.monthlyBilling)
                ]);
            } else if (this.status.counter > this.CALC_MAX_CHECKS) {
                this.endCalculatingCheck(true);
            }
            return null;
        }
    }

    angular
        .module('tenantBilling')
        .controller('RunInvoicingCtrl', RunInvoicingCtrl);
}
