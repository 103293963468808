namespace aq.energyInsights {
    export class WeatherNormalizationService extends InsightService {
        constructor(
            private EnergyInsightsDataService: aq.energyInsights.service.EnergyInsightsDataService,
            private $q,
            private $filter,
            private Restangular,
            private $translate
        ) {
            super();
        }

        public getSimpleTargetModel(building: aq.common.models.Building, measure: string, periodSearch: PeriodSearch): SimpleTargetModel {
            let targetModel = new SimpleTargetModel();
            targetModel.title = this.$translate.instant('insight.Weather Normalization');
            targetModel.when = this.$translate.instant('insight.when.Weather');
            return targetModel;
        }

        public getTargetModel(
                    building: aq.common.models.Building,
                    measure: string,
                    periodSearch: PeriodSearch,
                    account: aq.common.models.Account
                ): ng.IPromise<TargetModel> {
            let trendPeriodStart = moment(periodSearch.start).subtract(1, 'years');
            let trendPeriodEnd = moment(trendPeriodStart).add(1, 'years');
            return this.getKwHMetric(account)
                .then((metric) => {
                    return this.$q.all([
                        this.EnergyInsightsDataService.getWeatherNormalizedEnergy(building, periodSearch.start, periodSearch.end, metric, '1mon', account),
                        this.EnergyInsightsDataService.getWeatherNormalizedEnergy(building, trendPeriodStart, trendPeriodEnd, metric, '1mon', account)
                    ]);
                }).then((results) => {
                    let currentNorm = results[0];
                    let previousNorm = results[1];
                    return this.calculateTargetModel(building, currentNorm, previousNorm, periodSearch);
                });
        }

        public getGraphModel(building: aq.common.models.Building, measure: string, periodSearch: PeriodSearch): ng.IPromise<GraphModel> {
            return null;
        }

        public getTableModel(building: aq.common.models.Building, measure: string, periodSearch: PeriodSearch): ng.IPromise<TableModel> {
            return null;
        }

        public formatTableModel() {
            throw new TypeError('This is a stub method');
        }

        // ------------------------

        private getKwHMetric(account: aq.common.models.Account) {
            return this.Restangular.one('accounts', account.id).customGET('queryRealUnits').then(function (allUnits) {
                return _.find(allUnits, { unit: 'kWh' });
            });
        }

        private calculateTargetModel(building, currentNorm: aq.energyInsights.service.DataValue[], trendNorm: aq.energyInsights.service.DataValue[], periodSearch) {
            let today = moment().tz(building.timeZoneId).format('MMM Do');
            let numberOfDaysInMonth = moment().tz(building.timeZoneId).diff(moment().tz(building.timeZoneId).startOf('month'), 'days');
            let percOfTime = this.getPercent(numberOfDaysInMonth, moment().tz(building.timeZoneId).daysInMonth());
            let targetModel = new TargetModel();
            targetModel.question = `${this.$translate.instant('insight.question.Weather')} ${building.name}?`;
            targetModel.title = this.$translate.instant('insight.Weather Normalization');
            targetModel.target = this.getTarget(trendNorm, building);
            targetModel.showTarget = !_.isNull(targetModel.target);
            targetModel.showDonut = !_.isNull(targetModel.target);
            targetModel.formattedTarget = `${this.$translate.instant('insight.target.Target')} ${this.$filter('number')(targetModel.target, '0,0')} kWh`;
            targetModel.answer = this.getAnswer(currentNorm, building);
            targetModel.formattedAnswer = `${this.$filter('number')(targetModel.answer, '0,0')} kWh`;
            targetModel.percentage = this.getPercent(targetModel.answer, targetModel.target);
            targetModel.color = this.getColor(targetModel.answer, targetModel.target, targetModel.percentage, percOfTime);
            targetModel.tooltip = `${this.$translate.instant('insight.when_tooltip.Actual')} <span style="color:${targetModel.color}">${targetModel.percentage}%</span> ${this.$translate.instant('insight.when_tooltip.As Of')} ${today}.`;
            targetModel.when = this.$translate.instant('insight.when.Weather');
            targetModel.icon = this.getIcon(targetModel.answer, targetModel.target, targetModel.percentage, percOfTime);
            targetModel.timeElapsedPercentage = percOfTime;
            targetModel.timeElapsedLabel = today;
            return this.$q.when(targetModel);
        }

        private getAnswer(data: aq.energyInsights.service.DataValue[], building) {
            let thisMonth = moment().tz(building.timeZoneId).startOf('month');
            let row = _.find(data, { timestamp: thisMonth.valueOf() });
            if (row) {
                return row.value;
            } else {
                return 0;
            }
        }

        private getTarget(data: aq.energyInsights.service.DataValue[], building) {
            let trendMonth = moment().tz(building.timeZoneId).startOf('month').subtract('years', 1);
            let row = _.find(data, { timestamp: trendMonth.valueOf() });
            if (row) {
                return row.value;
            } else {
                return 0;
            }
        }
    }
    angular.module('energyInsights').service('WeatherNormalizationService', WeatherNormalizationService);
}
