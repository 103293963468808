// NOTE - this will be dropped after we decide layout and usability, this is just test version

/**
 * Period selector widget
 * @param {customCreatedObject} defaultPeriod - set default period on initialization
 *         - customCreatedObject is object created from values in intervalService
 *           structure: key - name from object in getTimeSelector array
 *                      value - period name from constraint object (constraints array)
 *                      example: { "Trailing": "Hour" }
 */
angular.module('aq.ui.periodSelectorNew', ['aq.ui.intervalSelector']).directive('periodSelectorNew', function() {
    return {
        restrict : 'E',
        replace: true,
        templateUrl: 'app/common/directives/periodSelectorNew/periodSelector.html',
        scope: {
            onChange: '&',
            aggregation: '=',
            start: '=',
            end: '=',
            skipUpdateOnInit: '@', // this is specially developed because of meter mangement page
            // currently dynamic filter does querying data on load - we need to think of how to manage
            // priorities when multiple widgets wants to query data on load, which will take priority etc...
            excludePeriods: '=',
            defaultPeriod: '='
        },
        controller: function ($scope, intervalService, $aq) {

            var callOnChange = $scope.skipUpdateOnInit == 'true' ? false : true;

            $scope.timeOptions = $aq.timeOptions();

            $scope.timeSelector = intervalService.getTimeSelector();

            if ($scope.excludePeriods) {
                _.each($scope.timeSelector, function(o) {
                    o.periods = _.filter(o.periods, function(period) {
                        return $scope.excludePeriods.indexOf(period.period) == -1;
                    })
                })
            }

            function setTime() {
                $scope.startTime = moment($scope.start).startOf('hour').format('HH:mm A');
                $scope.endTime = moment($scope.end).startOf('hour').format('HH:mm A');
            }

            $scope.changeTime = function (time, type) {
                var hour = moment(time, 'HH:mm A').format('H');
                var minute = moment(time, 'HH:mm A').format('m');
                if (type == 'start') {
                    $scope.start.hour(hour);
                    $scope.start.minute(minute);
                } else {
                    $scope.end.hour(hour);
                    $scope.end.minute(minute);
                }
            }

            $scope.select = function(t, p) {
                $scope.selected = t;

                var period = _.first(t.periods);
                if ($scope.selectedPeriod && $scope.selected.keepSelectedConstraint) {
                    // in case period is not found fallback to first item
                    period = _.find(t.periods, { period: $scope.selectedPeriod.period }) || period;
                }

                // override everything if period is passed
                // this case is actual only on initialization
                if (p) period = p;

                $scope.selectPeriod(period);
            }

            // TODO: add unit tests
            $scope.selectPeriod = function(p) {
                $scope.selectedPeriod = p;
                $scope.aggregation = p.defaultInterval;
                p.init();
                update();
            }

            $scope.prev = function() {
                $scope.selectedPeriod.prev();
                update();
            }

            $scope.next = function() {
                $scope.selectedPeriod.next();
                update();
            }

            /**
             * if custom selected period with datepickers
             */
            $scope.onCustomSelection = function () {
                var availableIntervals = intervalService.getAvailableIntervals($scope.start, $scope.end);
                var aggregation = _.find(availableIntervals, { value: $scope.aggregation });
                /**
                 * if aggregation not found in availableIntervals then pick
                 * first in array
                 */
                if (!aggregation) {
                    $scope.aggregation = _.first(availableIntervals).value;
                }

                $scope.start = new Date($scope.monthlyBilling.readingDate);
                
                
                $scope.onChange({ start: $scope.start, end: $scope.end, interval: $scope.aggregation });

                setTime();
            }

            function update() {
                // TODO: why we need to initialize moment object again when it is already moment object
                // hint: something is wrong with reference
                $scope.start = moment($scope.selectedPeriod.start());
                $scope.end = moment($scope.selectedPeriod.end());

                $scope.onChange({ start: $scope.start, end: $scope.end, interval: $scope.aggregation });
                $scope.hideNext = $scope.end.isAfter(moment());

                if (callOnChange == true) {
                    $scope.onChange({ start: $scope.start, end: $scope.end, interval: $scope.aggregation });
                }
                
                if ($scope.skipUpdateOnInit == 'true') {
                    callOnChange = true;
                }

                setTime();
            }

            if ($scope.defaultPeriod) {
                var presetName = _($scope.defaultPeriod).keys().first();
                var preset = _.find($scope.timeSelector, { name: presetName });
                var periodName = _($scope.defaultPeriod).values().first();
                var period = _.find(preset.periods, { period: periodName });
                $scope.select(preset, period);
            } else {
                $scope.select(_.first($scope.timeSelector));
            }

        }
    }
})
    .directive('utcParser', function () {

        function link(scope, element, attrs, ngModel) {
            var parser = function (val) {
                val = moment.utc(val).format();
                return val;
            };

            var formatter = function (val) {
                if (!val) {
                    return val;
                }
                val = moment(val).toDate();
                return val;
            };

            ngModel.$parsers.unshift(parser);
            ngModel.$formatters.unshift(formatter);
        }

        return {
            require: 'ngModel',
            link: link,
            restrict: 'A'
        }
    });