angular.module('aq.ui').directive('aqCrudMasterDetail', () => {
    return {
        restrict: 'E',
        replace: true,
        transclude: {
            'identifier': 'aqCrudIdentifier',
            'details': 'aqCrudDetails',
            'moreActions': '?aqCrudMoreActions'
        },
        link: ($scope, element, attrs) => {
            $scope.form = $scope.crudMasterDetailForm;
        },
        templateUrl: 'app/common/directives/crud/masterDetail.html',
        scope: {
            onSaveFn: '&?',
            onDeleteFn: '&',
            entityLabel: '<',
            hideSave: '<',
            hideDelete: '<',
            downloadExcel: '&?',
            form: '=?'
        }
    }
});
