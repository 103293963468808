namespace aq.user {
    export class UserDetailCtrl {
        private filterTerm = '';
        /* @ngInject */
        constructor(
            private $rootScope: ng.IScope,
            private user: User,
            private DataStore: aq.common.DataStore,
            private Messages: aq.services.Messages,
            private timeZones: aq.common.models.TimeZone[],
            private units: aq.common.models.SimpleEnum,
            private drillModes: aq.common.models.SimpleEnum,
            private measurementSystems: aq.common.models.SimpleEnum,
            private supportTeamRoles: aq.common.models.SimpleEnum,
            private $translate: any,
            private $window: ng.IWindowService,
            private RestangularV3: restangular.IService,

        ) {
        }

        public clearImage(): void {
            this.user.image = null;
            this.save();
        }

        public onImageUpload(): void{
            this.user.image = this.user.imageUrl;
            delete this.user.imageThumbnailUrl;
            delete this.user.imageUrl;
            this.save();
        }

        public save(): void {
            if (this.user.language != undefined && this.user.language != null) {
                this.$translate.use(this.user.language.toLowerCase());
                moment.locale(this.user.language.toLowerCase().split('_').join('-'));
            }
            this.DataStore.update(this.user).then(() => {
                const data = {
                    image: this.user.image,
                    title: this.user.title
                };
                this.$rootScope.$emit('PROFILE_DATA_UPDATE', data);
            }).then(() => { this.Messages.success('Successfully updated profile'); })
                .catch((err) => {
                    this.Messages.error('Unable to update profile');
                });
        }

        public changePassword() {
            return this.RestangularV3.one('users', this.user.id).customPOST({
                email: this.user.email,
            }, 'change-password').then((resetLink) => {
                this.$window.location.href = resetLink;
            }).catch((err) => {
                this.Messages.error(err.data.message);
            });
        }

        public copiedToClipboard(ev?) {
            this.Messages.success('API Key Copied to Clipboard');
        }
    }
    angular.module('user').controller('UserDetailCtrl', UserDetailCtrl);
}
