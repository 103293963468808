/**
 * Tooltip config for highcharts
 */
namespace aq.highcharts {

    import Moment = moment.Moment;

    class HighchartTooltipConfig {
        interval: string; // should be api interval, check comment in Enums.ApiInterval
        pattern: string; // HighchartsTooltipOptions.xDateFormat why it doesn't work ?
        momentFormat: string; // Moment formatting for date
    }

    class HighchartAxisLabelConfig {
        startPeriod: number; // should be minimum number of hours in given period to use this pattern
        endPeriod: number; // should be maximum number of hours in given period to use this pattern
        intervals: string[]; // list of api intervals
        pattern: string; // HighchartsTooltipOptions.xDateFormat why it doesn't work ?
        momentFormat: string; // Moment formatting for date
    }

    export class HighChartConfig {
        private tooltipByInterval:HighchartTooltipConfig[];
        private axisLabelByPeriod:HighchartAxisLabelConfig[];

        private MINIMUM_HOURS = 0;
        private TWO_DAYS = 24 * 2;
        private TWO_WEEKS = 24 * 14;
        private TWO_MONTHS = 24 * 62;
        private SIX_MONTHS = 24 * 180;

        constructor(private $translate) {
                this.axisLabelByPeriod = [
                    /**
                     * less than 2 days
                     * <hh:mm>
                     */
                    {
                        startPeriod: this.MINIMUM_HOURS,
                        endPeriod: this.TWO_DAYS,
                        intervals: ['1min', '15min'],
                        pattern: "%l:%M%P",
                        momentFormat: 'h:mma'
                    },
                    /**
                     * less than 2 days
                     * <hh:mm>
                     */
                    {
                        startPeriod: this.MINIMUM_HOURS,
                        endPeriod: this.TWO_DAYS,
                        intervals: ['1h'],
                        pattern: "%l%P",
                        momentFormat: 'ha'
                    },
                    /**
                     * between 2 days and 2 weeks
                     * <week day hh:mm>
                     */
                    {
                        startPeriod: this.TWO_DAYS,
                        endPeriod: this.TWO_WEEKS,
                        intervals: ['15min', '1h'],
                        pattern: "%a<br />%l%P",
                        momentFormat: 'ddd\nha'
                    },
                    /**
                     * between 2 days and 2 weeks
                     * <week day hh:mm>
                     */
                    {
                        startPeriod: this.TWO_DAYS,
                        endPeriod: this.TWO_WEEKS,
                        intervals: ['1d'],
                        pattern: "%a",
                        momentFormat: 'ddd'
                    },
                    /**
                     * between 2 weeks and 2 months
                     * <week day month date>
                     */
                    {
                        startPeriod: this.TWO_WEEKS,
                        endPeriod: this.TWO_MONTHS,
                        intervals: ['1h', '1d'],
                        pattern: this.$translate.instant('dates.%m/%d'),
                        momentFormat: this.$translate.instant('dates.M/D')
                    },
                    /**
                     * between 2 months and 6 months
                     * <month date>
                     */
                    {
                        startPeriod: this.TWO_MONTHS,
                        endPeriod: this.SIX_MONTHS,
                        intervals: ['1d'],
                        pattern: this.$translate.instant('dates.%m/%d'),
                        momentFormat: this.$translate.instant('dates.M/D')
                    },
                    /**
                     * between 2 months and 6 months
                     * <month>
                     */
                    {
                        startPeriod: this.TWO_MONTHS,
                        endPeriod: this.SIX_MONTHS,
                        intervals: ['1mon'],
                        pattern: "%b",
                        momentFormat: 'MMM'
                    },
                    /**
                     * greater than 6 months
                     * <month>
                     */
                    {
                        startPeriod: this.SIX_MONTHS,
                        endPeriod: null,
                        intervals: ['1d', '1mon'],
                        pattern: "%b",
                        momentFormat: 'MMM'
                    }
                ];
                this.tooltipByInterval = [
                    /**
                     * <week day, month&day, hh:mm> e.g. Tuesday, May 31, 2:43pm
                     */
                    {
                        interval: "1min",
                        pattern: this.$translate.instant('dates.%A, %b %e, %l:%M%P'),
                        momentFormat: this.$translate.instant('dates.dddd, MMM D, h:mma')
                    },
                    /**
                     * <week day, month&day, hh:mm>
                     */
                    {
                        interval: "15min",
                        pattern: this.$translate.instant('dates.%A, %b %e, %l:%M%P'),
                        momentFormat: this.$translate.instant('dates.dddd, MMM D, h:mma')
                    },
                    /**
                     * <week day, month&day, hh:00>
                     */
                    {
                        interval: "1h",
                        pattern: this.$translate.instant('dates.%A, %b %e, %l:%M%P'),
                        momentFormat: this.$translate.instant('dates.dddd, MMM D, h:mma')
                    },
                    /**
                     * <week day, month&day>
                     */
                    {
                        interval: "1d",
                        pattern: this.$translate.instant('dates.%A, %b %e'),
                        momentFormat: this.$translate.instant('dates.dddd, MMM D')
                    },
                    /**
                     * <month, year>
                     */
                    {
                        interval: "1mon",
                        pattern: this.$translate.instant('dates.%B %Y'),
                        momentFormat: this.$translate.instant('dates.MMMM YYYY')
                    }
                ]
        }

        /**
         * Returns xDateFormat for highcharts
         * @param interval
         * @returns {T}
         */
        public getXDateFormatByInterval(interval:string) {
            return _.find(this.tooltipByInterval, { interval: interval }).pattern;
        }

        /**
         * Returns moment date format to be used in highcharts
         * @param interval
         * @returns {T}
         */
        public getMomentDateFormatByInterval(interval:string) {
            return _.find(this.tooltipByInterval, { interval: interval }).momentFormat;
        }

        /**
         * Returns xDateFormat for highcharts
         * @param period
         * @returns {T}
         */
        public getAxisXDateFormatByPeriodAndInterval(start:Moment, end:Moment, interval:string) {
            let hours = Math.abs(end.diff(start, 'hours'));
            let period = _.find(this.axisLabelByPeriod, (period) => {
                let withinPeriod = hours >= period.startPeriod;
                if (period.endPeriod) {
                    withinPeriod = withinPeriod && hours < period.endPeriod;
                }
                return withinPeriod && _.includes(period.intervals, interval);
            });
            if (period) {
                return period.pattern;
            } else {
                return null;
            }
        }

        /**
         * Returns xDateFormat for highcharts
         * @param period
         * @returns {T}
         */
        public getAxisMomentDateFormatByPeriodAndInterval(start:Moment, end:Moment, interval:string) {
            let hours = Math.abs(end.diff(start, 'hours'));
            let period = _.find(this.axisLabelByPeriod, (period) => {
                let withinPeriod = hours >= period.startPeriod;
                if (period.endPeriod) {
                    withinPeriod = withinPeriod && hours < period.endPeriod;
                }
                return withinPeriod && _.includes(period.intervals, interval);
            });
            if (period) {
                return period.momentFormat;
            } else {
                return null;
            }
        }

    }

}