namespace aq.dashboard.widgets {
    export interface BuildingGroup {
        id: number;
        name: string;
        buildings: number[];
    }
    export interface BuildingGroupDefinition {
        id: number;
        name: string;
        colors: string[];
        groups: BuildingGroup[];
    }
    export class MockDataService {
        public groups: BuildingGroupDefinition[];
        private buildingData: any;
        private userData: any;
        private budgets: any[];
        public addresses: any[];
        public tmpBuildings: any[];
        constructor(
        ) {
            const regionGroup = {
                name: 'Region',
                id: 1,
                colors: ['#DB4C40', '#3E6990', '#419D78'],
                groups: [
                    {
                        id: 22490418, name: 'DC', buildings: [22485218, 22984268, 22489976
                            , 27001384
                            , 27001402
                            , 27001393
                            , 27001231
                            , 27001321
                            , 27027033
                            , 27001357
                            , 27001339
                            , 27001330
                            , 27001258
                            , 27001249
                            , 27001222
                            , 27001213
                            , 27001276
                            , 27001294]
                    },
                    {
                        id: 22858959, name: 'New York', buildings: [22489632, 22490253,
                            27001267,
                            27001312,
                            27017090,
                            27001366]
                    },
                    {
                        id: 22490426, name: 'New Mexico', buildings: [22485622,
                            27001303,
                            27001375,
                            27001348,
                            27001420,
                            27001447]
                    }
                ]
            };
            const fundGroup = {
                name: 'Fund',
                id: 2,
                colors: ['#9fc3e7', '#406196', '#4ecdc4', '#e0e5e9', '#406196'],
                groups: [
                    { id: 1, name: 'Direct Core', buildings: [22984268, 22489632] },
                    { id: 2, name: 'Opportunistic', buildings: [22489976, 22490253] },
                    { id: 3, name: 'Value Add', buildings: [22485218, 22485622] }
                ]
            };
            const jvPartnerGroup = {
                name: 'JV Partner',
                id: 3,
                colors: ['#008baa', '#e0b90b', '#c15920', '#c8cc8e', '#ffe1a8'],
                groups: [
                    { id: 3, name: 'US Real Estate Partners', buildings: [22485218, 22984268] },
                    { id: 4, name: 'ARA Realty', buildings: [22489632, 22490253, 22489976] },
                    { id: 5, name: 'Jefferson Equities', buildings: [22485622] }
                ]
            };
            this.groups = [regionGroup, fundGroup, jvPartnerGroup];

            this.budgets = [
                { property: 'elecBudget', label: 'Elec. Budget' },
                { property: 'waterBudget', label: 'Water Budget' },
                { property: 'gasBudget', label: 'Gas Budget' }
            ];

            this.addresses = [{ address: '1000 Thomas Jefferson St, NW', lat: 38.902848, lon: -77.060494 },
                { address: '100 10th Ave', lat: 40.743830, lon: -74.006725 },
                { address: '1050 Thomas Jefferson St, NW', lat: 38.903490, lon: -77.060494 },
                { address: '1200 McLean Blvd', lat: 44.026645, lon: -123.111011 },
                { address: '1225 South Clark Street ', lat: 41.866851, lon: -87.630424 },
                { address: '1730 M Street, NW', lat: 38.905384, lon: -77.039845 },
                { address: '1750 K Street, NW', lat: 38.902296, lon: -77.040926 },
                { address: '1776 K Street, NW', lat: 38.897052, lon: -77.041287 },
                { address: '1801 South Bell Street', lat: 38.857255, lon: -77.050600 },
                { address: '1825 Connecticut Ave, NW', lat: 38.915336, lon: -77.045922 },
                { address: '1875 Connecticut Ave, NW', lat: 38.915336, lon: -77.045922 },
                { address: '1919 M Street, NW', lat: 38.906095, lon: -77.044460 },
                { address: '1919 South Eads Street', lat: 38.856353, lon: -77.052579 },
                { address: '2101 L Street, NW', lat: 38.903959, lon: -77.047018 },
                { address: '2115 Wisconsin Ave', lat: 41.851647, lon: -87.800828 },
                { address: '1640 K St NW', lat: 38.902184, lon: -77.037697 },
                { address: '875 N St NW #205', lat: 42.464070, lon: -73.246519 },
                { address: '875 N St NW #205', lat: 42.464070, lon: -73.246519 },
                { address: '875 N St NW', lat: 42.464070, lon: -73.246519 },
                { address: '345 Park Avenue', lat: 40.757926, lon: -73.972213 },
                { address: '241 18th St S', lat: 38.858181, lon: -77.050217 },
                { address: '1200 New Jersey Ave, SE', lat: 38.875888, lon: -77.002826 },
                { address: '300 M Street', lat: 45.235149, lon: -93.467244 },
                { address: '470 L\'Enfant Plaza SW', lat: 38.884185, lon: -77.024806 },
                { address: '955 L\'Enfant Paza SW', lat: 38.884482, lon: -77.025193 },
                { address: '500 L\'Enfant Plaza SW', lat: 38.883824, lon: -77.024617 },
                { address: '54 W 34th St', lat: 40.749631, lon: -73.987412 },
                { address: '875 15th Street', lat: 40.003291, lon: -105.274014 },
                { address: '1629 K Street', lat: 40.092288, lon: -74.941850 },
                { address: '1399 New York Ave', lat: 40.848955, lon: -73.411690 },
                { address: '1055 Thomas Jefferson St NW', lat: 38.903731, lon: -77.059774 },
                { address: '1501 K Street ', lat: 38.903047, lon: -77.035127 },
                { address: '1299 Pennsylvania Ave, NW', lat: 38.896460, lon: -77.028885 },
                { address: '66 NM-344 #344', lat: 35.070979, lon: -106.188053 },
                { address: '1101 4th Street', lat: 37.774291, lon: -122.391408 }];

            this.buildingData = [
                this.getDataDc(27001384, 'L\'Enfant Plaza - North', 1.01),
                this.getDataDc(27001402, 'L\'Enfant Plaza - East', 1.02),
                this.getDataDc(27001393, 'L\'Enfant Plaza - Southeast', 1.03),
                this.getDataDc(27001231, '1776 K Street', 1.04),
                this.getDataDc(27001321, 'The Warner Building', 1.05),
                this.getDataDc(27027033, 'Aquicore HQ', 0.99),
                this.getDataDc(27001357, 'The Foundry', 0.98),
                this.getDataDc(27001339, '1050 Thomas Jefferson St', 0.97),
                this.getDataDc(27001330, '1000 Thomas Jefferson St', 0.96),
                this.getDataDc(27001258, '2101 L Street', 0.95),
                this.getDataDc(27001249, '1919 M Street', 0.94),
                this.getDataDc(27001222, '1750 K Street', 0.93),
                this.getDataDc(27001213, '1730 M Street', 0.92),
                this.getDataDc(27001276, 'The Investment Building', 0.91),
                this.getDataDc(27001294, '1875 Connecticut', 0.90),
                {
                    'buildingId': 22485218,
                    'buildingName': '100 10th Ave',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/PCQDxcAkRmuHFzO6YHO0_FOgzdV9VStWTftxFdOHk_Vroe1eXVQ8Sv24M37LSu_new york city.jpg',
                    'elecBudget': {
                        'total': 29880,
                        'projectedMonths': [
                            2490,
                            4980,
                            7470,
                            9960,
                            12450,
                            14940,
                            17430,
                            19920,
                            22410,
                            24900,
                            27390,
                            29880
                        ],
                        'actualMonths': [
                            2397,
                            5000,
                            7558,
                            10098,
                            13430,
                            17106
                        ],
                        'current': 17106
                    },
                    'waterBudget': {
                        'total': 95280,
                        'projectedMonths': [
                            7940,
                            15880,
                            23820,
                            31760,
                            39700,
                            47640,
                            55580,
                            63520,
                            71460,
                            79400,
                            87340,
                            95280
                        ],
                        'actualMonths': [
                            7909,
                            16017,
                            24016,
                            31566,
                            39747,
                            47640
                        ],
                        'current': 47640
                    },
                    'gasBudget': {
                        'total': 74520,
                        'projectedMonths': [
                            6210,
                            12420,
                            18630,
                            24840,
                            31050,
                            37260,
                            43470,
                            49680,
                            55890,
                            62100,
                            68310,
                            74520
                        ],
                        'actualMonths': [
                            6433,
                            12604,
                            18807,
                            24770,
                            30607,
                            37101
                        ],
                        'current': 37401
                    }
                },
                {
                    'buildingId': 22984268,
                    'buildingName': '1200 McLean Blvd',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/DlDonWHSTcyz52VdkiY4_1800_tysons.jpg',
                    'elecBudget': {
                        'total': 14400,
                        'projectedMonths': [
                            1200,
                            2400,
                            3600,
                            4800,
                            6000,
                            7200,
                            8400,
                            9600,
                            10800,
                            12000,
                            13200,
                            14400
                        ],
                        'actualMonths': [
                            1180,
                            2335,
                            3521,
                            4723,
                            5823,
                            6976
                        ],
                        'current': 6976
                    },
                    'waterBudget': {
                        'total': 75360,
                        'projectedMonths': [
                            6280,
                            12560,
                            18840,
                            25120,
                            31400,
                            37680,
                            43960,
                            50240,
                            56520,
                            62800,
                            69080,
                            75360
                        ],
                        'actualMonths': [
                            6279,
                            12302,
                            18319,
                            24349,
                            30504,
                            36900
                        ],
                        'current': 36900
                    },
                    'gasBudget': {
                        'total': 94080,
                        'projectedMonths': [
                            7840,
                            15680,
                            23520,
                            31360,
                            39200,
                            47040,
                            54880,
                            62720,
                            70560,
                            78400,
                            86240,
                            94080
                        ],
                        'actualMonths': [
                            7940,
                            15487,
                            23279,
                            30728,
                            38823,
                            47039
                        ],
                        'current': 47039
                    }
                },
                this.getDataNewYork(27001267, 'The Davis Building', 1.20),
                this.getDataNewYork(27001312, 'The Executive Building', 1.15),
                this.getDataNewYork(27017090, 'Aquicore HQ', 1.17),
                this.getDataNewYork(27001366, 'Aquicore HQ', 0.80),
                {
                    'buildingId': 22489632,
                    'buildingName': '50 Fremont',
                    'groupId': 22858959,
                    'groupName': 'New York',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/pJotB4a1TzeVDUzWiasj_5EkJmydhTbq00qgz3V40_1HotelWashington.jpg',
                    'elecBudget': {
                        'total': 22800,
                        'projectedMonths': [
                            1900,
                            3800,
                            5700,
                            7600,
                            9500,
                            11400,
                            13300,
                            15200,
                            17100,
                            19000,
                            20900,
                            22800
                        ],
                        'actualMonths': [
                            1976,
                            3871,
                            5806,
                            7782,
                            8756,
                            9900
                        ],
                        'current': 9900
                    },
                    'waterBudget': {
                        'total': 41280,
                        'projectedMonths': [
                            3440,
                            6880,
                            10320,
                            13760,
                            17200,
                            20640,
                            24080,
                            27520,
                            30960,
                            34400,
                            37840,
                            41280
                        ],
                        'actualMonths': [
                            3468,
                            6853,
                            10328,
                            13712,
                            17060,
                            20040
                        ],
                        'current': 20040
                    },
                    'gasBudget': {
                        'total': 96600,
                        'projectedMonths': [
                            8050,
                            16100,
                            24150,
                            32200,
                            40250,
                            48300,
                            56350,
                            64400,
                            72450,
                            80500,
                            88550,
                            96600
                        ],
                        'actualMonths': [
                            8222,
                            15997,
                            24413,
                            32733,
                            39033,
                            45037
                        ],
                        'current': 45037
                    }
                },
                {
                    'buildingId': 22489976,
                    'buildingName': 'Concord Plaza',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/o5IffiuqQKC2Atbae9V2_Building 4 bis.jpg',
                    'elecBudget': {
                        'total': 60720,
                        'projectedMonths': [
                            5060,
                            10120,
                            15180,
                            20240,
                            25300,
                            30360,
                            35420,
                            40480,
                            45540,
                            50600,
                            55660,
                            60720
                        ],
                        'actualMonths': [
                            5255,
                            10073,
                            14967,
                            20232,
                            25093,
                            29612
                        ],
                        'current': 29612
                    },
                    'waterBudget': {
                        'total': 17160,
                        'projectedMonths': [
                            1430,
                            2860,
                            4290,
                            5720,
                            7150,
                            8580,
                            10010,
                            11440,
                            12870,
                            14300,
                            15730,
                            17160
                        ],
                        'actualMonths': [
                            1414,
                            2796,
                            4256,
                            5688,
                            6935,
                            8050
                        ],
                        'current': 8050
                    },
                    'gasBudget': {
                        'total': 79200,
                        'projectedMonths': [
                            6600,
                            13200,
                            19800,
                            26400,
                            33000,
                            39600,
                            46200,
                            52800,
                            59400,
                            66000,
                            72600,
                            79200
                        ],
                        'actualMonths': [
                            6689,
                            13059,
                            19585,
                            26120,
                            30553,
                            35177
                        ],
                        'current': 35177
                    }
                },
                {
                    'buildingId': 22490253,
                    'buildingName': 'Redwood Office',
                    'groupId': 22858959,
                    'groupName': 'New York',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/aqWwdoCSGmBH0PTx2bAY_PsZmEfOvRregFVQpiRBR_bethesda apartment building.jpg',
                    'elecBudget': {
                        'total': 54480,
                        'projectedMonths': [
                            4540,
                            9080,
                            13620,
                            18160,
                            22700,
                            27240,
                            31780,
                            36320,
                            40860,
                            45400,
                            49940,
                            54480
                        ],
                        'actualMonths': [
                            4496,
                            8837,
                            13438,
                            18063,
                            22742,
                            27181
                        ],
                        'current': 27181
                    },
                    'waterBudget': {
                        'total': 67320,
                        'projectedMonths': [
                            5610,
                            11220,
                            16830,
                            22440,
                            28050,
                            33660,
                            39270,
                            44880,
                            50490,
                            56100,
                            61710,
                            67320
                        ],
                        'actualMonths': [
                            5415,
                            11027,
                            16916,
                            22740,
                            26500,
                            31000
                        ],
                        'current': 31000
                    },
                    'gasBudget': {
                        'total': 70680,
                        'projectedMonths': [
                            5890,
                            11780,
                            17670,
                            23560,
                            29450,
                            35340,
                            41230,
                            47120,
                            53010,
                            58900,
                            64790,
                            70680
                        ],
                        'actualMonths': [
                            6061,
                            12211,
                            17814,
                            23436,
                            29485,
                            36101
                        ],
                        'current': 36101
                    }
                },
                this.getDataNewMexico(27001303, 'The Bowen Building', 1.20),
                this.getDataNewMexico(27001375, 'Federal Center', 1.18),
                this.getDataNewMexico(27001348, '2115 Wisconsin', 1.15),
                this.getDataNewMexico(27001420, '1225 South Clark Street', 0.92),
                this.getDataNewMexico(27001447, '1919 South Eads Street', 0.85),
                {
                    'buildingId': 22485622,
                    'buildingName': 'UPS Distribution Center',
                    'groupId': 22490426,
                    'groupName': 'New Mexico',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/s3omjmguQ3uYooGypgtY_j0uwTdtwQsKN4M2Ylkn3_soka-pac-exterior.jpg',
                    'elecBudget': {
                        'total': 90120,
                        'projectedMonths': [
                            7510,
                            15020,
                            22530,
                            30040,
                            37550,
                            45060,
                            52570,
                            60080,
                            67590,
                            75100,
                            82610,
                            90120
                        ],
                        'actualMonths': [
                            7773,
                            15621,
                            23431,
                            30994,
                            36500,
                            42500
                        ],
                        'current': 42500
                    },
                    'waterBudget': {
                        'total': 107520,
                        'projectedMonths': [
                            8960,
                            17920,
                            26880,
                            35840,
                            44800,
                            53760,
                            62720,
                            71680,
                            80640,
                            89600,
                            98560,
                            107520
                        ],
                        'actualMonths': [
                            9027,
                            17760,
                            27109,
                            35668,
                            42000,
                            51000
                        ],
                        'current': 51000
                    },
                    'gasBudget': {
                        'total': 81840,
                        'projectedMonths': [
                            6820,
                            13640,
                            20460,
                            27280,
                            34100,
                            40920,
                            47740,
                            54560,
                            61380,
                            68200,
                            75020,
                            81840
                        ],
                        'actualMonths': [
                            6582,
                            13593,
                            20685,
                            27656,
                            33560,
                            40000
                        ],
                        'current': 40000
                    }
                }
            ];

            this.userData = [
                {
                    'id': 0,
                    'userId': 26928956,
                    'userFullName': 'Andrew Wachter',
                    'userType': 'Building Engineer',
                    'buildingId': 22485218,
                    'buildingName': '100 10th Ave',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/PCQDxcAkRmuHFzO6YHO0_FOgzdV9VStWTftxFdOHk_Vroe1eXVQ8Sv24M37LSu_new york city.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 793,
                    'noteCount': 24,
                    'timeBefore': 325,
                    'noteCountBefore': 75,
                    'certifications': ''
                },
                {
                    'id': 1,
                    'userId': 9091598,
                    'userFullName': 'Anna Buglaeva',
                    'userType': 'Building Engineer',
                    'buildingId': 22984268,
                    'buildingName': '1200 McLean Blvd',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/DlDonWHSTcyz52VdkiY4_1800_tysons.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 592,
                    'noteCount': 91,
                    'timeBefore': 645,
                    'noteCountBefore': 95,
                    'certifications': ''
                },
                {
                    'id': 2,
                    'userId': 26939039,
                    'userFullName': 'Arda Mohamed',
                    'userType': 'Building Engineer',
                    'buildingId': 22489632,
                    'buildingName': '50 Fremont',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/pJotB4a1TzeVDUzWiasj_5EkJmydhTbq00qgz3V40_1HotelWashington.jpg',
                    'groupId': 22858959,
                    'groupName': 'New York',
                    'time': 506,
                    'noteCount': 83,
                    'timeBefore': 488,
                    'noteCountBefore': 75,
                    'certifications': ''
                },
                {
                    'id': 3,
                    'userId': 24303644,
                    'userFullName': 'Ashley Boswell',
                    'userType': 'Building Engineer',
                    'buildingId': 22489976,
                    'buildingName': 'Concord Plaza',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/o5IffiuqQKC2Atbae9V2_Building 4 bis.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 724,
                    'noteCount': 88,
                    'timeBefore': 739,
                    'noteCountBefore': 91,
                    'certifications': ''
                },
                {
                    'id': 4,
                    'userId': 26675224,
                    'userFullName': 'Brendan O\'Connor',
                    'userType': 'Building Engineer',
                    'buildingId': 22490253,
                    'buildingName': 'Redwood Office',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/aqWwdoCSGmBH0PTx2bAY_PsZmEfOvRregFVQpiRBR_bethesda apartment building.jpg',
                    'groupId': 22858959,
                    'groupName': 'New York',
                    'time': 602,
                    'noteCount': 59,
                    'timeBefore': 657,
                    'noteCountBefore': 63,
                    'certifications': ''
                },
                {
                    'id': 5,
                    'userId': 20958414,
                    'userFullName': 'Caitlin Henry',
                    'userType': 'Building Engineer',
                    'buildingId': 22485622,
                    'buildingName': 'UPS Distribution Center',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/s3omjmguQ3uYooGypgtY_j0uwTdtwQsKN4M2Ylkn3_soka-pac-exterior.jpg',
                    'groupId': 22490426,
                    'groupName': 'New Mexico',
                    'time': 887,
                    'noteCount': 85,
                    'timeBefore': 960,
                    'noteCountBefore': 79,
                    'certifications': ''
                },
                {
                    'id': 6,
                    'userId': 26995877,
                    'userFullName': 'Caitlin Henry',
                    'userType': 'Property Manager',
                    'buildingId': 22485218,
                    'buildingName': '100 10th Ave',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/PCQDxcAkRmuHFzO6YHO0_FOgzdV9VStWTftxFdOHk_Vroe1eXVQ8Sv24M37LSu_new york city.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 625,
                    'noteCount': 82,
                    'timeBefore': 631,
                    'noteCountBefore': 83,
                    'certifications': ''
                },
                {
                    'id': 7,
                    'userId': 26761904,
                    'userFullName': 'Charles Cox',
                    'userType': 'Building Engineer',
                    'buildingId': 22984268,
                    'buildingName': '1200 McLean Blvd',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/DlDonWHSTcyz52VdkiY4_1800_tysons.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 724,
                    'noteCount': 84,
                    'timeBefore': 773,
                    'noteCountBefore': 87,
                    'certifications': ''
                },
                {
                    'id': 8,
                    'userId': 26968282,
                    'userFullName': 'Chelsea Mattioda',
                    'userType': 'Asset Manager',
                    'buildingId': 22489632,
                    'buildingName': '50 Fremont',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/pJotB4a1TzeVDUzWiasj_5EkJmydhTbq00qgz3V40_1HotelWashington.jpg',
                    'groupId': 22858959,
                    'groupName': 'New York',
                    'time': 716,
                    'noteCount': 63,
                    'timeBefore': 663,
                    'noteCountBefore': 61,
                    'certifications': ''
                },
                {
                    'id': 9,
                    'userId': 20373388,
                    'userFullName': 'Connor Gray',
                    'userType': 'Building Engineer',
                    'buildingId': 22489976,
                    'buildingName': 'Concord Plaza',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/o5IffiuqQKC2Atbae9V2_Building 4 bis.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 640,
                    'noteCount': 61,
                    'timeBefore': 643,
                    'noteCountBefore': 57,
                    'certifications': ''
                },
                {
                    'id': 10,
                    'userId': 26695003,
                    'userFullName': 'Darshan Hosakote',
                    'userType': 'Property Manager',
                    'buildingId': 22490253,
                    'buildingName': 'Redwood Office',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/aqWwdoCSGmBH0PTx2bAY_PsZmEfOvRregFVQpiRBR_bethesda apartment building.jpg',
                    'groupId': 22858959,
                    'groupName': 'New York',
                    'time': 729,
                    'noteCount': 60,
                    'timeBefore': 701,
                    'noteCountBefore': 60,
                    'certifications': ''
                },
                {
                    'id': 11,
                    'userId': 24007498,
                    'userFullName': 'David Priddy',
                    'userType': 'Building Engineer',
                    'buildingId': 22485622,
                    'buildingName': 'UPS Distribution Center',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/s3omjmguQ3uYooGypgtY_j0uwTdtwQsKN4M2Ylkn3_soka-pac-exterior.jpg',
                    'groupId': 22490426,
                    'groupName': 'New Mexico',
                    'time': 950,
                    'noteCount': 80,
                    'timeBefore': 907,
                    'noteCountBefore': 75,
                    'certifications': ''
                },
                {
                    'id': 12,
                    'userId': 26258649,
                    'userFullName': 'Dee Wong',
                    'userType': 'Property Manager',
                    'buildingId': 22485218,
                    'buildingName': '100 10th Ave',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/PCQDxcAkRmuHFzO6YHO0_FOgzdV9VStWTftxFdOHk_Vroe1eXVQ8Sv24M37LSu_new york city.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 804,
                    'noteCount': 99,
                    'timeBefore': 742,
                    'noteCountBefore': 106,
                    'certifications': ''
                },
                {
                    'id': 13,
                    'userId': 23137000,
                    'userFullName': 'Demo Account',
                    'userType': 'Property Manager',
                    'buildingId': 22984268,
                    'buildingName': '1200 McLean Blvd',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/DlDonWHSTcyz52VdkiY4_1800_tysons.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 958,
                    'noteCount': 80,
                    'timeBefore': 1015,
                    'noteCountBefore': 81,
                    'certifications': ''
                },
                {
                    'id': 14,
                    'userId': 25375585,
                    'userFullName': 'Elle Roberts',
                    'userType': 'Property Manager',
                    'buildingId': 22489632,
                    'buildingName': '50 Fremont',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/pJotB4a1TzeVDUzWiasj_5EkJmydhTbq00qgz3V40_1HotelWashington.jpg',
                    'groupId': 22858959,
                    'groupName': 'New York',
                    'time': 801,
                    'noteCount': 62,
                    'timeBefore': 787,
                    'noteCountBefore': 62,
                    'certifications': ''
                },
                {
                    'id': 15,
                    'userId': 26936460,
                    'userFullName': 'Hiwot Kassaye',
                    'userType': 'Asset Manager',
                    'buildingId': 22489976,
                    'buildingName': 'Concord Plaza',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/o5IffiuqQKC2Atbae9V2_Building 4 bis.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 571,
                    'noteCount': 74,
                    'timeBefore': 624,
                    'noteCountBefore': 68,
                    'certifications': ''
                },
                {
                    'id': 16,
                    'userId': 21746643,
                    'userFullName': 'James Cohen',
                    'userType': 'Building Engineer',
                    'buildingId': 22490253,
                    'buildingName': 'Redwood Office',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/aqWwdoCSGmBH0PTx2bAY_PsZmEfOvRregFVQpiRBR_bethesda apartment building.jpg',
                    'groupId': 22858959,
                    'groupName': 'New York',
                    'time': 846,
                    'noteCount': 96,
                    'timeBefore': 922,
                    'noteCountBefore': 99,
                    'certifications': ''
                },
                {
                    'id': 17,
                    'userId': 23207600,
                    'userFullName': 'Justin Vitarello',
                    'userType': 'Property Manager',
                    'buildingId': 22485622,
                    'buildingName': 'UPS Distribution Center',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/s3omjmguQ3uYooGypgtY_j0uwTdtwQsKN4M2Ylkn3_soka-pac-exterior.jpg',
                    'groupId': 22490426,
                    'groupName': 'New Mexico',
                    'time': 525,
                    'noteCount': 91,
                    'timeBefore': 482,
                    'noteCountBefore': 82,
                    'certifications': ''
                },
                {
                    'id': 18,
                    'userId': 26724479,
                    'userFullName': 'Kaitlin Tomlinson',
                    'userType': 'Building Engineer',
                    'buildingId': 22485218,
                    'buildingName': '100 10th Ave',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/PCQDxcAkRmuHFzO6YHO0_FOgzdV9VStWTftxFdOHk_Vroe1eXVQ8Sv24M37LSu_new york city.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 600,
                    'noteCount': 18,
                    'timeBefore': 893,
                    'noteCountBefore': 64,
                    'certifications': ''
                },
                {
                    'id': 19,
                    'userId': 24753405,
                    'userFullName': 'Kristina Niedermayer',
                    'userType': 'Property Manager',
                    'buildingId': 22984268,
                    'buildingName': '1200 McLean Blvd',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/DlDonWHSTcyz52VdkiY4_1800_tysons.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 764,
                    'noteCount': 89,
                    'timeBefore': 735,
                    'noteCountBefore': 89,
                    'certifications': ''
                },
                {
                    'id': 20,
                    'userId': 221,
                    'userFullName': 'Logan Soya',
                    'userType': 'Asset Engineer',
                    'buildingId': 22489632,
                    'buildingName': '50 Fremont',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/pJotB4a1TzeVDUzWiasj_5EkJmydhTbq00qgz3V40_1HotelWashington.jpg',
                    'groupId': 22858959,
                    'groupName': 'New York',
                    'time': 721,
                    'noteCount': 69,
                    'timeBefore': 711,
                    'noteCountBefore': 68,
                    'certifications': ''
                },
                {
                    'id': 21,
                    'userId': 21336621,
                    'userFullName': 'Mark Sakauye',
                    'userType': 'Building Engineer',
                    'buildingId': 22489976,
                    'buildingName': 'Concord Plaza',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/o5IffiuqQKC2Atbae9V2_Building 4 bis.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 950,
                    'noteCount': 91,
                    'timeBefore': 732,
                    'noteCountBefore': 67,
                    'certifications': ''
                },
                {
                    'id': 22,
                    'userId': 22481615,
                    'userFullName': 'Matthew Cherry',
                    'userType': 'Property Manager',
                    'buildingId': 22490253,
                    'buildingName': 'Redwood Office',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/aqWwdoCSGmBH0PTx2bAY_PsZmEfOvRregFVQpiRBR_bethesda apartment building.jpg',
                    'groupId': 22858959,
                    'groupName': 'New York',
                    'time': 582,
                    'noteCount': 75,
                    'timeBefore': 621,
                    'noteCountBefore': 78,
                    'certifications': ''
                },
                {
                    'id': 23,
                    'userId': 26541006,
                    'userFullName': 'Mercy Daniel Aguebor',
                    'userType': 'Building Engineer',
                    'buildingId': 22485622,
                    'buildingName': 'UPS Distribution Center',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/s3omjmguQ3uYooGypgtY_j0uwTdtwQsKN4M2Ylkn3_soka-pac-exterior.jpg',
                    'groupId': 22490426,
                    'groupName': 'New Mexico',
                    'time': 603,
                    'noteCount': 64,
                    'timeBefore': 565,
                    'noteCountBefore': 66,
                    'certifications': ''
                },
                {
                    'id': 24,
                    'userId': 19429932,
                    'userFullName': 'Michael Donovan',
                    'userType': 'Building Engineer',
                    'buildingId': 22485218,
                    'buildingName': '100 10th Ave',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/PCQDxcAkRmuHFzO6YHO0_FOgzdV9VStWTftxFdOHk_Vroe1eXVQ8Sv24M37LSu_new york city.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 749,
                    'noteCount': 81,
                    'timeBefore': 752,
                    'noteCountBefore': 79,
                    'certifications': ''
                },
                {
                    'id': 25,
                    'userId': 23132449,
                    'userFullName': 'Michael Payne',
                    'userType': 'Property Manager',
                    'buildingId': 22984268,
                    'buildingName': '1200 McLean Blvd',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/DlDonWHSTcyz52VdkiY4_1800_tysons.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 805,
                    'noteCount': 63,
                    'timeBefore': 741,
                    'noteCountBefore': 64,
                    'certifications': ''
                },
                {
                    'id': 26,
                    'userId': 26284129,
                    'userFullName': 'Mike Dudley',
                    'userType': 'Building Engineer',
                    'buildingId': 22489632,
                    'buildingName': '50 Fremont',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/pJotB4a1TzeVDUzWiasj_5EkJmydhTbq00qgz3V40_1HotelWashington.jpg',
                    'groupId': 22858959,
                    'groupName': 'New York',
                    'time': 883,
                    'noteCount': 56,
                    'timeBefore': 917,
                    'noteCountBefore': 51,
                    'certifications': ''
                },
                {
                    'id': 27,
                    'userId': 24438109,
                    'userFullName': 'Mike Geddes',
                    'userType': 'Property Manager',
                    'buildingId': 22489976,
                    'buildingName': 'Concord Plaza',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/o5IffiuqQKC2Atbae9V2_Building 4 bis.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 525,
                    'noteCount': 71,
                    'timeBefore': 533,
                    'noteCountBefore': 74,
                    'certifications': ''
                },
                {
                    'id': 28,
                    'userId': 21547510,
                    'userFullName': 'Mike Koscinski',
                    'userType': 'Building Engineer',
                    'buildingId': 22490253,
                    'buildingName': 'Redwood Office',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/aqWwdoCSGmBH0PTx2bAY_PsZmEfOvRregFVQpiRBR_bethesda apartment building.jpg',
                    'groupId': 22858959,
                    'groupName': 'New York',
                    'time': 500,
                    'noteCount': 94,
                    'timeBefore': 477,
                    'noteCountBefore': 97,
                    'certifications': ''
                },
                {
                    'id': 29,
                    'userId': 25238756,
                    'userFullName': 'Minkyung Kang',
                    'userType': 'Building Engineer',
                    'buildingId': 22485622,
                    'buildingName': 'UPS Distribution Center',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/s3omjmguQ3uYooGypgtY_j0uwTdtwQsKN4M2Ylkn3_soka-pac-exterior.jpg',
                    'groupId': 22490426,
                    'groupName': 'New Mexico',
                    'time': 556,
                    'noteCount': 92,
                    'timeBefore': 606,
                    'noteCountBefore': 92,
                    'certifications': ''
                },
                {
                    'id': 30,
                    'userId': 17854670,
                    'userFullName': 'Natacha Dumondel',
                    'userType': 'Asset Manager',
                    'buildingId': 22485218,
                    'buildingName': '100 10th Ave',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/PCQDxcAkRmuHFzO6YHO0_FOgzdV9VStWTftxFdOHk_Vroe1eXVQ8Sv24M37LSu_new york city.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 988,
                    'noteCount': 71,
                    'timeBefore': 1048,
                    'noteCountBefore': 70,
                    'certifications': ''
                },
                {
                    'id': 31,
                    'userId': 26885102,
                    'userFullName': 'Nikhil Jyothinagaram',
                    'userType': 'Building Engineer',
                    'buildingId': 22984268,
                    'buildingName': '1200 McLean Blvd',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/DlDonWHSTcyz52VdkiY4_1800_tysons.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 776,
                    'noteCount': 74,
                    'timeBefore': 829,
                    'noteCountBefore': 72,
                    'certifications': ''
                },
                {
                    'id': 32,
                    'userId': 18820039,
                    'userFullName': 'Patrick Doyle',
                    'userType': 'Building Engineer',
                    'buildingId': 22489632,
                    'buildingName': '50 Fremont',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/pJotB4a1TzeVDUzWiasj_5EkJmydhTbq00qgz3V40_1HotelWashington.jpg',
                    'groupId': 22858959,
                    'groupName': 'New York',
                    'time': 848,
                    'noteCount': 93,
                    'timeBefore': 795,
                    'noteCountBefore': 93,
                    'certifications': ''
                },
                {
                    'id': 33,
                    'userId': 26627985,
                    'userFullName': 'Payne Train',
                    'userType': 'Property Manager',
                    'buildingId': 22489976,
                    'buildingName': 'Concord Plaza',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/o5IffiuqQKC2Atbae9V2_Building 4 bis.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 757,
                    'noteCount': 54,
                    'timeBefore': 788,
                    'noteCountBefore': 53,
                    'certifications': ''
                },
                {
                    'id': 34,
                    'userId': 21888695,
                    'userFullName': 'Roy Illingworth',
                    'userType': 'Property Manager',
                    'buildingId': 22490253,
                    'buildingName': 'Redwood Office',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/aqWwdoCSGmBH0PTx2bAY_PsZmEfOvRregFVQpiRBR_bethesda apartment building.jpg',
                    'groupId': 22858959,
                    'groupName': 'New York',
                    'time': 616,
                    'noteCount': 55,
                    'timeBefore': 614,
                    'noteCountBefore': 53,
                    'certifications': ''
                },
                {
                    'id': 35,
                    'userId': 24749815,
                    'userFullName': 'Roy Illingworth',
                    'userType': 'Building Engineer',
                    'buildingId': 22485622,
                    'buildingName': 'UPS Distribution Center',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/s3omjmguQ3uYooGypgtY_j0uwTdtwQsKN4M2Ylkn3_soka-pac-exterior.jpg',
                    'groupId': 22490426,
                    'groupName': 'New Mexico',
                    'time': 527,
                    'noteCount': 52,
                    'timeBefore': 511,
                    'noteCountBefore': 49,
                    'certifications': ''
                },
                {
                    'id': 36,
                    'userId': 455903,
                    'userFullName': 'Sam Dib',
                    'userType': 'Asset Manager',
                    'buildingId': 22485218,
                    'buildingName': '100 10th Ave',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/PCQDxcAkRmuHFzO6YHO0_FOgzdV9VStWTftxFdOHk_Vroe1eXVQ8Sv24M37LSu_new york city.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 585,
                    'noteCount': 82,
                    'timeBefore': 550,
                    'noteCountBefore': 84,
                    'certifications': ''
                },
                {
                    'id': 37,
                    'userId': 26954739,
                    'userFullName': 'Tim Waddill',
                    'userType': 'Asset Manager',
                    'buildingId': 22984268,
                    'buildingName': '1200 McLean Blvd',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/DlDonWHSTcyz52VdkiY4_1800_tysons.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 645,
                    'noteCount': 94,
                    'timeBefore': 611,
                    'noteCountBefore': 95,
                    'certifications': ''
                },
                {
                    'id': 38,
                    'userId': 18392651,
                    'userFullName': 'Tony Knight',
                    'userType': 'Building Engineer',
                    'buildingId': 22489632,
                    'buildingName': '50 Fremont',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/pJotB4a1TzeVDUzWiasj_5EkJmydhTbq00qgz3V40_1HotelWashington.jpg',
                    'groupId': 22858959,
                    'groupName': 'New York',
                    'time': 844,
                    'noteCount': 57,
                    'timeBefore': 822,
                    'noteCountBefore': 56,
                    'certifications': ''
                },
                {
                    'id': 39,
                    'userId': 26649103,
                    'userFullName': 'Travis Outten',
                    'userType': 'Asset Manager',
                    'buildingId': 22489976,
                    'buildingName': 'Concord Plaza',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/o5IffiuqQKC2Atbae9V2_Building 4 bis.jpg',
                    'groupId': 22490418,
                    'groupName': 'DC',
                    'time': 740,
                    'noteCount': 58,
                    'timeBefore': 763,
                    'noteCountBefore': 60,
                    'certifications': ''
                },
                {
                    'id': 40,
                    'userId': 21888863,
                    'userFullName': 'Trevor Atlas',
                    'userType': 'Building Engineer',
                    'buildingId': 22490253,
                    'buildingName': 'Redwood Office',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/aqWwdoCSGmBH0PTx2bAY_PsZmEfOvRregFVQpiRBR_bethesda apartment building.jpg',
                    'groupId': 22858959,
                    'groupName': 'New York',
                    'time': 974,
                    'noteCount': 90,
                    'timeBefore': 999,
                    'noteCountBefore': 86,
                    'certifications': ''
                },
                {
                    'id': 41,
                    'userId': 26704697,
                    'userFullName': 'Venkata Dinesh Jakkampudi',
                    'userType': 'Building Engineer',
                    'buildingId': 22485622,
                    'buildingName': 'UPS Distribution Center',
                    'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/s3omjmguQ3uYooGypgtY_j0uwTdtwQsKN4M2Ylkn3_soka-pac-exterior.jpg',
                    'groupId': 22490426,
                    'groupName': 'New Mexico',
                    'time': 985,
                    'noteCount': 66,
                    'timeBefore': 975,
                    'noteCountBefore': 71,
                    'certifications': ''
                }
            ];
        }
        public assignUsersToBuildings(fromGroupName, toBuildings) {
            const users = _.filter(this.userData, (user) => user.groupName == fromGroupName);
            let counter = 0;
            _.each(users, (u) => {
                const item1 = angular.copy(u);
                let building = toBuildings[counter % toBuildings.length];
                counter++;
                item1.buildingId = building.id;
                item1.buildingName = building.name;
                this.userData.push(item1);

                const item2 = angular.copy(u);
                building = toBuildings[counter % toBuildings.length];
                counter++;
                item2.buildingId = building.id;
                item2.buildingName = building.name;
                this.userData.push(item2);

                const item3 = angular.copy(u);
                building = toBuildings[counter % toBuildings.length];
                counter++;
                item3.buildingId = building.id;
                item3.buildingName = building.name;
                this.userData.push(item3);
            });
        }
        public getBudgets() {
            return this.budgets;
        }
        public getBuildingUtilityData() {
            //add mock groups to buildings
            _.each(this.buildingData, (building) => {
                building.groups = [];
                _.each(this.groups, (groupDefinition: BuildingGroupDefinition) => {
                    const buildingGroup: BuildingGroup = _.find(groupDefinition.groups, (g: BuildingGroup) => _.some(g.buildings, (b) => b == building.buildingId));
                    if (!buildingGroup) {
                        return;
                    }
                    building.groups.push({
                        groupTypeId: groupDefinition.id,
                        groupId: buildingGroup.id,
                        groupName: buildingGroup.name
                    });
                });
            });
            return angular.copy(this.buildingData);
        }
        public getUserEngagementData() {
            const dcBuildings = [
                { id: 27001384, name: 'L\'Enfant Plaza - North' },
                { id: 27001402, name: 'L\'Enfant Plaza - East' },
                { id: 27001393, name: 'L\'Enfant Plaza - Southeast' },
                { id: 27001231, name: '1776 K Street' },
                { id: 27001321, name: 'The Warner Building' },
                { id: 27027033, name: 'Aquicore HQ' },
                { id: 27001339, name: '1050 Thomas Jefferson St' },
                { id: 27001330, name: '1000 Thomas Jefferson St' },
                { id: 27001258, name: '2101 L Street' },
                { id: 27001249, name: '1919 M Street' },
                { id: 27001222, name: '1750 K Street' },
                { id: 27001213, name: '1730 M Street' },
                { id: 27001276, name: 'The Investment Building' },
                { id: 27001294, name: '1875 Connecticut' }];
            this.assignUsersToBuildings('DC', dcBuildings);
            const newYorkBuildings = [
                { id: 27001267, name: 'The Davis Building' },
                { id: 27001312, name: 'The Executive Building' },
                { id: 27017090, name: 'Aquicore HQ' },
                { id: 27001366, name: 'Aquicore HQ' }
            ];
            this.assignUsersToBuildings('New York', newYorkBuildings);
            const newMexicoBuildings = [
                { id: 27001303, name: 'The Bowen Building' },
                { id: 27001375, name: 'Federal Center' },
                { id: 27001348, name: '2115 Wisconsin' },
                { id: 27001420, name: '1225 South Clark Street' },
                { id: 27001447, name: '1919 South Eads Street' }
            ];
            this.assignUsersToBuildings('New Mexico', newMexicoBuildings);
            //add mock groups to buildings
            _.each(this.userData, (user) => {
                user.groups = [];
                _.each(this.groups, (groupDefinition: BuildingGroupDefinition) => {
                    const buildingGroup: BuildingGroup = _.find(groupDefinition.groups, (g: BuildingGroup) => _.some(g.buildings, (b) => b == user.buildingId));
                    if (!buildingGroup) {
                        return;
                    }
                    user.groups.push({
                        groupTypeId: groupDefinition.id,
                        groupId: buildingGroup.id,
                        groupName: buildingGroup.name
                    });
                });
            });
            return angular.copy(this.userData);
        }

        public extendBuildings(buildings) {
            _.each(this.tmpBuildings, (t) => {
                if (!_.find(buildings, (b) => b.id == t.id)) {
                    buildings.push(t);
                };
            });
        }

        private getBuildingUtilityDataRandom(buildings, buildingGroups) {
            const data = [];
            _.each(buildings, (building) => {
                const newItem = {
                    buildingId: building.id,
                    buildingName: building.name,
                    groupId: building.buildingGroup,
                    groupName: this.getBuildingGroupName(building.buildingGroup, buildingGroups),
                    imageUrl: building.imageUrl
                };
                _.each(this.budgets, (budget) => {
                    newItem[budget.property] = {
                        total: Math.round(Math.random() * 1000) / 100 * 12000
                    };
                    newItem[budget.property].projectedMonths = this.getProjectedBudget(newItem[budget.property].total);
                    newItem[budget.property].actualMonths = this.generateActualSpendings(newItem[budget.property].projectedMonths, 6, true, true);
                    newItem[budget.property].current = newItem[budget.property].actualMonths[5];
                });
                data.push(newItem);
            });
            return data;
        }
        private getBuildingGroupName(groupId, buildingGroups) {
            const buildingGroup = _.find(buildingGroups, (group) => group.id == groupId);
            return buildingGroup ? buildingGroup.name : 'Other';
        }
        private getProjectedBudget(total) {
            const result = [0];
            for (let month = 0; month < 11; month++) {
                result[month] = Math.round(total / 12) + (month > 0 ? result[month - 1] : 0);
            }
            result.push(total);
            return result;
        }
        private generateActualSpendings(projected: number[], numberOfMonths: number, hasGoodMonths: boolean, hasBadMonths: boolean) {
            const result = [];
            if (!projected || projected.length < numberOfMonths) {
                return result;
            }
            for (let i = 0; i < numberOfMonths; i++) {
                const current = projected[i] - (i > 0 ? projected[i - 1] : 0);
                let offset = 0;
                if (hasGoodMonths || !hasBadMonths) {
                    offset -= 0.5;
                }
                if (!hasGoodMonths || hasBadMonths) {
                    offset += 0.5;
                }
                const random = ((Math.random() - 0.5) + offset) / 10;
                const currentTotal = Math.round((1 + random) * current) + (result.length > 0 ? result[result.length - 1] : 0);
                result.push(currentTotal);
            }
            return result;
        }
        private generateUserEngagementDataRandom(users, buildings, buildingGroups) {
            let cnt = 0;
            const data = [];
            _.each(users, (user) => {
                const userType = user.title ? user.title : this.generateRole(user);
                const currentBuilding = buildings[cnt % buildings.length];
                const generatedItem = {
                    id: cnt,
                    userId: user.id,
                    userFullName: user.fullName,
                    userType,
                    buildingId: parseInt(currentBuilding.id),
                    buildingName: currentBuilding.name,
                    imageUrl: currentBuilding.imageUrl,
                    groupId: currentBuilding.buildingGroup,
                    groupName: this.getBuildingGroupName(currentBuilding.buildingGroup, buildingGroups),
                    time: Math.floor((0.5 + Math.random() * 0.5) * 1000),
                    noteCount: Math.floor((0.5 + Math.random() * 0.5) * 100),
                    timeBefore: 0,
                    noteCountBefore: 0,
                    certifications: ''
                };
                generatedItem.timeBefore = (1 - (Math.random() - 0.5) * 0.2) * generatedItem.time;
                generatedItem.noteCountBefore = (1 - (Math.random() - 0.5) * 0.2) * generatedItem.noteCount;
                data.push(generatedItem);
                cnt++;
            });
            return data;
        }
        private generateRole(user) {
            if (user.userType == 'ADMINISTRATOR') {
                return 'Building Engineer';
            }
            if (user.userType == 'CLIENT') {
                return 'Property Manager';
            }
            return 'Asset Manager';
        }
        private getDataDc(id, name, percent) {
            const data = {
                'buildingId': 22485218,
                'buildingName': '100 10th Ave',
                'groupId': 22490418,
                'groupName': 'DC',
                'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/pJotB4a1TzeVDUzWiasj_5EkJmydhTbq00qgz3V40_1HotelWashington.jpg',
                'elecBudget': {
                    'total': 29880,
                    'projectedMonths': [
                        2490,
                        4980,
                        7470,
                        9960,
                        12450,
                        14940,
                        17430,
                        19920,
                        22410,
                        24900,
                        27390,
                        29880
                    ],
                    'actualMonths': [
                        2397,
                        5000,
                        7558,
                        10098,
                        13430,
                        17106
                    ],
                    'current': 17106
                },
                'waterBudget': {
                    'total': 95280,
                    'projectedMonths': [
                        7940,
                        15880,
                        23820,
                        31760,
                        39700,
                        47640,
                        55580,
                        63520,
                        71460,
                        79400,
                        87340,
                        95280
                    ],
                    'actualMonths': [
                        7909,
                        16017,
                        24016,
                        31566,
                        39747,
                        47640
                    ],
                    'current': 47640
                },
                'gasBudget': {
                    'total': 74520,
                    'projectedMonths': [
                        6210,
                        12420,
                        18630,
                        24840,
                        31050,
                        37260,
                        43470,
                        49680,
                        55890,
                        62100,
                        68310,
                        74520
                    ],
                    'actualMonths': [
                        6433,
                        12604,
                        18807,
                        24770,
                        30607,
                        37101
                    ],
                    'current': 37401
                }
            };
            data.buildingId = id;
            data.buildingName = name;
            data.elecBudget.actualMonths[5] = Math.floor(data.elecBudget.actualMonths[5] * percent);
            data.elecBudget.current = data.elecBudget.actualMonths[5];
            data.waterBudget.actualMonths[5] = Math.floor(data.waterBudget.actualMonths[5] * percent);
            data.waterBudget.current = data.waterBudget.actualMonths[5];
            data.gasBudget.actualMonths[5] = Math.floor(data.gasBudget.actualMonths[5] * percent);
            data.gasBudget.current = data.gasBudget.actualMonths[5];
            return data;
        }
        private getDataNewYork(id, name, percent) {
            const data = {
                'buildingId': 22489632,
                'buildingName': '50 Fremont',
                'groupId': 22858959,
                'groupName': 'New York',
                'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/pJotB4a1TzeVDUzWiasj_5EkJmydhTbq00qgz3V40_1HotelWashington.jpg',
                'elecBudget': {
                    'total': 22800,
                    'projectedMonths': [
                        1900,
                        3800,
                        5700,
                        7600,
                        9500,
                        11400,
                        13300,
                        15200,
                        17100,
                        19000,
                        20900,
                        22800
                    ],
                    'actualMonths': [
                        1976,
                        3871,
                        5806,
                        7782,
                        8756,
                        9900
                    ],
                    'current': 9900
                },
                'waterBudget': {
                    'total': 41280,
                    'projectedMonths': [
                        3440,
                        6880,
                        10320,
                        13760,
                        17200,
                        20640,
                        24080,
                        27520,
                        30960,
                        34400,
                        37840,
                        41280
                    ],
                    'actualMonths': [
                        3468,
                        6853,
                        10328,
                        13712,
                        17060,
                        20040
                    ],
                    'current': 20040
                },
                'gasBudget': {
                    'total': 96600,
                    'projectedMonths': [
                        8050,
                        16100,
                        24150,
                        32200,
                        40250,
                        48300,
                        56350,
                        64400,
                        72450,
                        80500,
                        88550,
                        96600
                    ],
                    'actualMonths': [
                        8222,
                        15997,
                        24413,
                        32733,
                        39033,
                        45037
                    ],
                    'current': 45037
                }
            };
            data.buildingId = id;
            data.buildingName = name;
            data.elecBudget.actualMonths[5] = Math.floor(data.elecBudget.actualMonths[5] * percent);
            data.elecBudget.current = data.elecBudget.actualMonths[5];
            data.waterBudget.actualMonths[5] = Math.floor(data.waterBudget.actualMonths[5] * percent);
            data.waterBudget.current = data.waterBudget.actualMonths[5];
            data.gasBudget.actualMonths[5] = Math.floor(data.gasBudget.actualMonths[5] * percent);
            data.gasBudget.current = data.gasBudget.actualMonths[5];
            return data;
        }
        private getDataNewMexico(id, name, percent) {
            const data = {
                'buildingId': 22485622,
                'buildingName': 'UPS Distribution Center',
                'groupId': 22490426,
                'groupName': 'New Mexico',
                'imageUrl': 'https://com-aquicore-web.s3.amazonaws.com/s3omjmguQ3uYooGypgtY_j0uwTdtwQsKN4M2Ylkn3_soka-pac-exterior.jpg',
                'elecBudget': {
                    'total': 90120,
                    'projectedMonths': [
                        7510,
                        15020,
                        22530,
                        30040,
                        37550,
                        45060,
                        52570,
                        60080,
                        67590,
                        75100,
                        82610,
                        90120
                    ],
                    'actualMonths': [
                        7773,
                        15621,
                        23431,
                        30994,
                        36500,
                        42500
                    ],
                    'current': 42500
                },
                'waterBudget': {
                    'total': 107520,
                    'projectedMonths': [
                        8960,
                        17920,
                        26880,
                        35840,
                        44800,
                        53760,
                        62720,
                        71680,
                        80640,
                        89600,
                        98560,
                        107520
                    ],
                    'actualMonths': [
                        9027,
                        17760,
                        27109,
                        35668,
                        42000,
                        51000
                    ],
                    'current': 51000
                },
                'gasBudget': {
                    'total': 81840,
                    'projectedMonths': [
                        6820,
                        13640,
                        20460,
                        27280,
                        34100,
                        40920,
                        47740,
                        54560,
                        61380,
                        68200,
                        75020,
                        81840
                    ],
                    'actualMonths': [
                        6582,
                        13593,
                        20685,
                        27656,
                        33560,
                        40000
                    ],
                    'current': 40000
                }
            };
            data.buildingId = id;
            data.buildingName = name;
            data.elecBudget.actualMonths[5] = Math.floor(data.elecBudget.actualMonths[5] * percent);
            data.elecBudget.current = data.elecBudget.actualMonths[5];
            data.waterBudget.actualMonths[5] = Math.floor(data.waterBudget.actualMonths[5] * percent);
            data.waterBudget.current = data.waterBudget.actualMonths[5];
            data.gasBudget.actualMonths[5] = Math.floor(data.gasBudget.actualMonths[5] * percent);
            data.gasBudget.current = data.gasBudget.actualMonths[5];
            return data;
        }
    }
    angular.module('aq.dashboard.widgets').service('MockDataService', MockDataService);
}
