'use strict';

angular
    .module('aq.admin.statistics', ['aq.admin.statistics.schedules'])
    .config(($stateProvider, $urlRouterProvider) => {
        $urlRouterProvider.when('/admin/statistics', '/admin/statistics/notifications');
        $stateProvider
            .state('aq.admin.statistics', {
                url: '/statistics',
                templateUrl: 'app/admin/statistics/main.html'
            });
    });
