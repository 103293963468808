namespace aq.dashboard.widgets {
    angular
        .module('dashboard')
        .directive('aqMapbox', (): ng.IDirective => {
            return {
                restrict: 'E',
                replace: true,
                templateUrl: 'app/dashboard/common/directives/mapbox/mapbox.html',
                scope: {
                    data: '<',
                    onSelect: '&'
                },
                link(
                    $scope: any,
                    instanceElement: ng.IAugmentedJQuery,
                    instanceAttributes: ng.IAttributes,
                    controller: {},
                    transclude
                ) {
                    $scope.counter = 0; //hack to fix map issue (not showing layer which has been removed previously)
                    $scope.$on('mapboxglMap:click', function (angEvent, mapEvent) {
                        const map = mapEvent.target;
                        const features = map.queryRenderedFeatures(mapEvent.point);
                        const buildingFeature = getBuildingFeature(features);
                        if (!buildingFeature) {
                            return;
                        }
                        const buildingId = buildingFeature.properties['buildingId'];
                        $scope.onSelect({ id: buildingId });
                        $scope.$apply();
                    });
                    $scope.$watch('data', () => {
                        try {
                            buildLayers();
                        } catch (e) {
                        }
                    }, true);

                    const recalculateColors = (highlightId = null) => {
                        const colors = {
                            r: 'rgba(231, 15, 40, #OPACITY#)',
                            g: 'rgba(122, 205, 70, #OPACITY#)',
                            y: 'rgba(241, 168, 29, #OPACITY#)'
                        };
                        const expression = ['match', ['get', 'STATEFP']];
                        _.each($scope.data, (item) => {
                            let opacity = '0.5';
                            let color = '';
                            if (highlightId == item.id) {
                                opacity = '0.8';
                            }
                            color = colors[item.color];
                            expression.push(item.id.toString(), color.replace('#OPACITY#', opacity));
                        });
                        expression.push('rgba(0,0,0,0)');
                        return expression;
                    };

                    const getBuildingFeature = (features) => {
                        return _.find(features, (feature) => feature.layer.id.startsWith('unclustered-point-'));
                    }

                    const buildLayers = () => {
                        $scope.layers = [];
                        $scope.sources = [];

                        _.each($scope.data.layers, (layer) => {
                            const sourceId = 'source-' + $scope.counter++;
                            const source = {
                                id: sourceId,
                                type: 'geojson',
                                data: {
                                    type: 'FeatureCollection',
                                    features: []
                                },
                                cluster: true
                            };
                            _.each(layer.points, (point) => {
                                const feature = {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Point',
                                        coordinates: [point.lon, point.lat]
                                    },
                                    properties: {
                                        title: point.name,
                                        buildingId: point.id
                                    }
                                };
                                source.data.features.push(feature);
                            });
                            $scope.sources.push(source);

                            const clusterLayerId = 'cluster-' + layer.id + '-' + $scope.counter++;
                            $scope.layers.push({
                                id: clusterLayerId,
                                type: 'circle',
                                source: sourceId,
                                filter: ['has', 'point_count'],
                                paint: {
                                    'circle-color': {
                                        property: 'point_count',
                                        type: 'interval',
                                        stops: [
                                            [0, layer.color],
                                            [5, layer.color],
                                            [1000, layer.color]
                                        ]
                                    },
                                    'circle-radius': {
                                        property: 'point_count',
                                        type: 'interval',
                                        stops: [
                                            [0, 20],
                                            [5, 30],
                                            [1000, 40]
                                        ]
                                    }
                                }
                            });

                            const clusterCountLayerId = 'count-cluster-' + layer.id + '-' + $scope.counter++;
                            $scope.layers.push({
                                id: clusterCountLayerId,
                                type: 'symbol',
                                source: sourceId,
                                filter: ['has', 'point_count'],
                                layout: {
                                    'text-field': '{point_count_abbreviated}',
                                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                                    'text-size': 12
                                }
                            });

                            const unclusteredLayerId = 'unclustered-point-' + layer.id + '-' + $scope.counter++;
                            $scope.layers.push({
                                id: unclusteredLayerId,
                                type: 'circle',
                                source: sourceId,
                                filter: ['!has', 'point_count'],
                                paint: {
                                    'circle-color': layer.color,
                                    'circle-radius': 8,
                                    'circle-stroke-width': 1,
                                    'circle-stroke-color': '#fff'
                                }
                            });
                        });
                    };
                }
            };
        });
}
