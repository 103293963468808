namespace aq.admin.meters.devices {
    export interface StatusViewItem {
        title: string;
        percent: number;
        color: string;
        value: string;
    }
    export interface DeviceViewItem {
        id: number;
        name: string;
        category: string;
        restangularizedDevice: any;
        status: string;
        lastReported: moment.Moment;
        lastReportedFormatted: string;
        macAddress: string;
        accountName: string;
        buildingId: number;
        buildingName: string;
        onDataStatusLoaded: (data: aq.common.models.DeviceDataStatus) => void;
    }
    export class AdminDevicesDetailsCtrl {
        public deviceClassView: StatusViewItem[];
        public deviceViewItems: DeviceViewItem[];
        public filteredDeviceViewItems: DeviceViewItem[];
        public searchText: string;
        public selectedStatusValue: string;
        private orderByProperty: string;
        constructor(
            private accounts,
            private devices: DeviceModel[],
            private deviceClass: DeviceClassModel,
            private deviceClassStatistics: DeviceClassStatisticsModel,
            private RestangularV3,
            private $scope: ng.IScope,
            private Messages: aq.services.Messages,
            private loading
        ) {
            this.initDeviceClassSummary();
            this.initDevices();
            this.orderByProperty = '-lastReported';
        }
        public initDeviceClassSummary() {
            const data = this.deviceClassStatistics;
            this.deviceClassView = [];
            this.deviceClassView.push({ title: 'Last 30m', percent: this.getPercent(data.numberOnlineLast30Min, data), color: '#7ACD46', value: 'LAST_30M' });
            this.deviceClassView.push({ title: 'Last 60m', percent: this.getPercent(data.numberOnlineLast60Min, data), color: '#7ACD46', value: 'LAST_60M' });
            this.deviceClassView.push({ title: 'Last 1d', percent: this.getPercent(data.numberOnlineLastDay, data), color: '#F1A81D', value: 'LAST_1D' });
            this.deviceClassView.push({ title: 'More than 1d', percent: this.getPercent(data.numberOfflineMoreThanOneDay, data), color: '#E70F28', value: 'MORE_THAN_1D' });
            this.deviceClassView.push({ title: 'Never', percent: this.getPercent(data.numberNeverReported, data), color: '#E70F28', value: 'NEVER' });
            this.deviceClassView.push({ title: 'Not Commissioned', percent: this.getPercent(data.numberNotCommissioned, data), color: '#0091f1', value: 'NOT_COMMISSIONED' });
        }
        public initDevices() {
            this.deviceViewItems = _.map(this.devices, (device: DeviceModel) => {
                const deviceViewItem: DeviceViewItem = {
                    id: device.id,
                    name: device.name,
                    category: device.deviceCategory,
                    restangularizedDevice: this.RestangularV3.one('devices', device.id),
                    macAddress: this.getFieldIdentifier(device),
                    buildingId: device.building,
                    buildingName: device.buildingName,
                    accountName: device.accountName,
                    lastReported: null,
                    lastReportedFormatted: null,
                    status: null,
                    onDataStatusLoaded: (data: aq.common.models.DeviceDataStatus) => {
                        deviceViewItem.lastReported = data.lastReportedTime ? moment(data.lastReportedTime) : null;
                        deviceViewItem.lastReportedFormatted = deviceViewItem.lastReported ? deviceViewItem.lastReported.format('hh:mm A MM/DD/YYYY') : '';
                        deviceViewItem.status = data.status;
                        const item: DeviceViewItem = _.find(this.filteredDeviceViewItems, (fi) => fi.id == deviceViewItem.id);
                        if (!item) {
                            return;
                        } else {
                            item.lastReported = deviceViewItem.lastReported;
                            item.lastReportedFormatted = deviceViewItem.lastReportedFormatted;
                            item.status = deviceViewItem.status;
                        }
                        if (!this.isItemFiltered(deviceViewItem, moment())) {
                            _.remove(this.filteredDeviceViewItems, (fi) => fi.id == deviceViewItem.id);
                        }
                    }
                };
                return deviceViewItem;
            });
            this.filteredDeviceViewItems = angular.copy(this.deviceViewItems);
        }
        public navigateToDeviceDetails(item: DeviceViewItem) {
            const accountId = this.getAccountId(item.accountName);
            if (!accountId) {
                this.Messages.error('Destination Account is not accessible to the current User');
                return;
            }
            const data = {
                accountId,
                state: 'aq.deviceManagement.building.device.configuration',
                stateParams: {
                    accountId,
                    buildingId: item.buildingId,
                    deviceId: item.id
                }
            };
            this.$scope.$emit('NAVIGATE_ACROSS_ACCOUNTS', data);
        }
        public searchBy(text: string) {
            if (!text) {
                text = '';
            }
            text = text.toLowerCase();
            this.searchText = text;
            const now = moment();
            this.filteredDeviceViewItems = _.filter(this.deviceViewItems, (item: DeviceViewItem) => {
                return this.isItemFiltered(item, now);
            });
        }
        public isItemFiltered(item: DeviceViewItem, now: moment.Moment) {
            return this.isDeviceInSelectedStatus(item, now)
                && (item.name.toLowerCase().indexOf(this.searchText) > -1
                    || !this.searchText
                    || item.accountName.toLowerCase().indexOf(this.searchText) > -1
                    || item.buildingName.toLowerCase().indexOf(this.searchText) > -1
                    || item.macAddress.toLowerCase().indexOf(this.searchText) > -1
                    || item.lastReportedFormatted.toLowerCase().indexOf(this.searchText) > -1);
        }
        public toggleStatusOption(option: StatusViewItem) {
            if (this.selectedStatusValue == option.value) {
                this.selectedStatusValue = null;
            } else {
                this.selectedStatusValue = option.value;
            }
            this.searchBy(this.searchText);
        }
        public isDeviceInSelectedStatus(item: DeviceViewItem, now: moment.Moment) {
            if (!this.selectedStatusValue) {
                return true;
            }
            if (!item.status) {
                return true;
            }
            const statuses = ['ONLINE', 'OFFLINE', 'NEVER_REPORTED', 'WAITING'];
            if (this.selectedStatusValue == 'NEVER') {
                return item.status == 'NEVER_REPORTED' || item.status == 'WAITING';
            }
            if (this.selectedStatusValue == 'NOT_COMMISSIONED') {
                return !_.some(statuses, (s) => s == item.status);
            }
            const minutes = this.getMinuteOffsets(this.selectedStatusValue);
            if (minutes.max > 0) {
                const startTime = moment(now).add(-minutes.max, 'minute');
                const endTime = moment(now).add(-minutes.min, 'minute');
                return moment(item.lastReported).isBetween(startTime, endTime);
            } else {
                return moment(item.lastReported).isBefore(moment(now).add(-minutes.min, 'minute'));
            }
        }
        private getMinuteOffsets(value: string) {
            switch (value) {
                case 'LAST_30M':
                    return { min: 0, max: 30 };
                case 'LAST_60M':
                    return { min: 30, max: 60 };
                case 'LAST_1D':
                    return { min: 60, max: 1440 };
                default:
                    return { min: 1440, max: -1 };
            }
        }
        private getPercent(value, data: DeviceClassStatisticsModel) {
            return Math.floor(100 * value / data.totalDevices);
        }
        private getFieldIdentifier(device: DeviceModel) {
            if (!device.fields || !device.fields.deviceIdentifier || !device.fields.deviceIdentifier.value) {
                return '';
            }
            return device.fields.deviceIdentifier.value;
        }
        private getAccountId(name) {
            const account = _.find(this.accounts, (a) => a.name == name);
            if (!account) {
                return -1;
            }
            return account.id;
        }
    }
    angular.module('aq.admin.meters.devices').controller('AdminDevicesDetailsCtrl', AdminDevicesDetailsCtrl);
}
