var aq;
(function (aq) {
    var service;
    (function (service) {
        var CreateBuildingServiceDialogCtrl = /** @class */ (function (_super) {
            __extends(CreateBuildingServiceDialogCtrl, _super);
            /* @ngInject */
            function CreateBuildingServiceDialogCtrl($scope, $mdDialog, newBuilding, BuildingService, Auth) {
                var _this = _super.call(this, $scope, $mdDialog) || this;
                _this.$scope = $scope;
                _this.$mdDialog = $mdDialog;
                _this.newBuilding = newBuilding;
                _this.BuildingService = BuildingService;
                _this.Auth = Auth;
                _this.showManual = false;
                $scope.newBuilding = _this.newBuilding;
                return _this;
            }
            CreateBuildingServiceDialogCtrl.prototype.selectedItemChange = function (item) {
                if (item) {
                    var _a = item.place.properties, street = _a.street, city = _a.city, stateCode = _a.stateCode, postalCode = _a.postalCode, countryCode = _a.countryCode;
                    this.newBuilding.address = street;
                    this.newBuilding.city = city;
                    this.newBuilding.state = stateCode;
                    this.newBuilding.zipCode = postalCode;
                    this.newBuilding.country = countryCode;
                }
            };
            CreateBuildingServiceDialogCtrl.prototype.queryAddress = function (searchText) {
                return this.BuildingService.findAddress(searchText);
            };
            ;
            CreateBuildingServiceDialogCtrl.prototype.toggleManualAddress = function () {
                this.newBuilding.address = null;
                this.newBuilding.city = null;
                this.newBuilding.state = null;
                this.newBuilding.zipCode = null;
                this.newBuilding.country = null;
                if (this.showManual) {
                    this.showManual = false;
                }
                else {
                    this.showManual = true;
                }
            };
            return CreateBuildingServiceDialogCtrl;
        }(aq.common.Controllers.ModalCtrl));
        service.CreateBuildingServiceDialogCtrl = CreateBuildingServiceDialogCtrl;
        angular
            .module('aq.services')
            .controller('CreateBuildingServiceDialogCtrl', CreateBuildingServiceDialogCtrl);
    })(service = aq.service || (aq.service = {}));
})(aq || (aq = {}));
