angular.module('properties.dataCollectors')
    .filter('collectorIcon', function() {
        return function(collector) {
            if (!collector) return;
            if (collector.type == "COLLECTOR") {
                return collector.isUtilityMeter ? 'aq-icons-collector-utility-meter' : 'aq-icons-collector-' + textural(collector.collectorClass).format('slug');
            } else {
                return 'aq-icons-' + textural(collector.collectorClass).format('slug');
            }
        }
    });
