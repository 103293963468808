namespace aq.dashboard.widgets {
    export class DrillDataService {
        constructor(
            private MockDataService: MockDataService
        ) {
        }
        //group by idProperty values
        public processData(data, idProperty, nameProperty, groupTypeId = 1) {
            _.each(data, (item) => {
                const currentGroup = _.find(item.groups, (g) => g.groupTypeId == groupTypeId);
                item.groupId = currentGroup ? currentGroup.groupId : null;
                item.groupName = currentGroup ? currentGroup.groupName : null;
            });
            const viewData = [];
            const budgets = this.MockDataService.getBudgets();
            _.each(data, (item) => {
                if (!_.find(viewData, (viewItem) => viewItem.id == item[idProperty])) {
                    const filteredItems = _.filter(data, (filterItem) => filterItem[idProperty] == item[idProperty]);
                    const newItem = {
                        id: item[idProperty],
                        idProperty,
                        name: item[nameProperty],
                        imageUrl: '',
                        cumulativePercent: 0
                    };
                    let grandSumTotal = 0;
                    let grandSumTotalYtd = 0;
                    let grandSumCurrent = 0;
                    let grandLastMonthSumTotal = 0;
                    let grandLastMonthSumCurrent = 0;
                    let grandBeforeLastMonthSumTotal = 0;
                    let grandBeforeLastMonthSumCurrent = 0;
                    _.each(budgets, (budget) => {
                        const minPercent = 0;
                        const maxPercent = 0;
                        newItem[budget.property] = {
                            sumTotal: _.sumBy(filteredItems, (sumItem) => sumItem[budget.property].total),
                            sumTotalYtd: _.sumBy(filteredItems, (sumItem) => sumItem[budget.property].projectedMonths[5]),
                            sumCurrent: _.sumBy(filteredItems, (sumItem) => sumItem[budget.property].current),
                            lastMonthSumTotal: _.sumBy(filteredItems, (sumItem) => sumItem[budget.property].projectedMonths[5] - sumItem[budget.property].projectedMonths[4]),
                            lastMonthSumCurrent: _.sumBy(filteredItems, (sumItem) => sumItem[budget.property].actualMonths[5] - sumItem[budget.property].actualMonths[4]),
                            percent: 0,
                            lastMonthPercent: 0,
                            minPercent: 0,
                            maxPercent: 0,
                            diffPercentLastMonth: 0
                        };
                        grandSumTotal += newItem[budget.property].sumTotal;
                        grandSumTotalYtd += newItem[budget.property].sumTotalYtd;
                        grandSumCurrent += newItem[budget.property].sumCurrent;
                        grandLastMonthSumTotal += newItem[budget.property].lastMonthSumTotal;
                        grandLastMonthSumCurrent += newItem[budget.property].lastMonthSumCurrent;
                        newItem[budget.property].percent = Math.ceil(newItem[budget.property].sumCurrent / newItem[budget.property].sumTotalYtd * 100);
                        newItem[budget.property].percentLastMonth = Math.ceil(newItem[budget.property].lastMonthSumCurrent / newItem[budget.property].lastMonthSumTotal * 100);

                        const beforeLastMonthSumTotal = _.sumBy(filteredItems, (sumItem) => sumItem[budget.property].projectedMonths[4] - sumItem[budget.property].projectedMonths[3]);
                        const beforeLastMonthSumCurrent = _.sumBy(filteredItems, (sumItem) => sumItem[budget.property].actualMonths[4] - sumItem[budget.property].actualMonths[3]);
                        grandBeforeLastMonthSumTotal += beforeLastMonthSumTotal;
                        grandBeforeLastMonthSumCurrent += beforeLastMonthSumCurrent;
                        const beforeLastMonthPercent = Math.ceil(beforeLastMonthSumCurrent / beforeLastMonthSumTotal * 100);
                        newItem[budget.property].diffPercentLastMonth = newItem[budget.property].percentLastMonth - beforeLastMonthPercent;
                    });
                    newItem['allBudget'] = {
                        sumTotal: grandSumTotal,
                        sumTotalYtd: grandSumTotalYtd,
                        sumCurrent: grandSumCurrent,
                        lastMonthSumTotal: grandLastMonthSumTotal,
                        lastMonthSumCurrent: grandLastMonthSumCurrent,
                        percent: Math.ceil(grandSumCurrent / grandSumTotalYtd * 100),
                        percentLastMonth: Math.ceil(grandLastMonthSumCurrent / grandLastMonthSumTotal * 100),
                        minPercent: 0,
                        maxPercent: 0,
                        diffPercentLastMonth: 0
                    };
                    newItem.cumulativePercent = newItem['allBudget'].percent;
                    newItem['allBudget'].diffPercentLastMonth = newItem['allBudget'].percentLastMonth - Math.ceil(grandBeforeLastMonthSumCurrent / grandBeforeLastMonthSumTotal * 100)
                    if (idProperty == 'buildingId' && filteredItems.length > 0) {
                        newItem.imageUrl = filteredItems[0].imageUrl;
                    }
                    viewData.push(newItem);
                }
            });
            return viewData;
        }
        public processUserEngagementData(data, idProperty, nameProperty, groupTypeId = 1) {
            _.each(data, (item) => {
                const currentGroup = _.find(item.groups, (g) => g.groupTypeId == groupTypeId);
                item.groupId = currentGroup ? currentGroup.groupId : null;
                item.groupName = currentGroup ? currentGroup.groupName : null;
            });
            const viewData = [];
            _.each(data, (item) => {
                if (!_.find(viewData, (viewItem) => viewItem.id == item[idProperty])) {
                    const filteredItems = _.filter(data, (filterItem) => filterItem[idProperty] == item[idProperty]);
                    const newItem = {
                        id: item[idProperty],
                        idProperty: idProperty,
                        name: item[nameProperty],
                        imageUrl: '',
                        sumTime: _.sumBy(filteredItems, (sumItem) => sumItem.time),
                        sumNoteCount: _.sumBy(filteredItems, (sumItem) => sumItem.noteCount),
                        sumTimeBefore: _.sumBy(filteredItems, (sumItem) => sumItem.timeBefore),
                        sumNoteCountBefore: _.sumBy(filteredItems, (sumItem) => sumItem.noteCountBefore),
                        totalCount: filteredItems.length,
                        score: 0,
                        diff: 0
                    };
                    if (idProperty == 'buildingId' && filteredItems.length > 0) {
                        newItem.imageUrl = filteredItems[0].imageUrl;
                    }
                    newItem.score = this.calculateScore(newItem.sumTime, newItem.sumNoteCount, newItem.totalCount);
                    const scoreBefore = this.calculateScore(newItem.sumTimeBefore, newItem.sumNoteCountBefore, newItem.totalCount);
                    newItem.diff = newItem.score - scoreBefore;
                    if (idProperty == 'userId') {
                        const include = [
                            { name: 'userType', label: 'Role' },
                            { name: 'time', label: 'Session Time' },
                            { name: 'noteCount', label: 'Notes Taken' },
                            { name: 'certifications', label: 'Certifications' }
                        ];
                        _.each(include, (includeProperty) => {
                            newItem[includeProperty.name] = item[includeProperty.name];
                        });
                    }
                    viewData.push(newItem);
                }
            });
            return viewData;
        }
        public calculateScore(time, noteCount, userCount) {
            //0-100
            const avgTime = time / userCount;
            const avgNoteCount = noteCount / userCount;
            const score = Math.round((avgTime / 200 + avgNoteCount / 20) * 10);
            return score;
        }
    }
    angular.module('aq.dashboard.widgets').service('DrillDataService', DrillDataService);
}
