namespace aq.utilityBudgets {
    export class UtilityAccountDetailsAdmin {
        public currentNavItem: string;

        private isAqAdmin: boolean;

        /* @ngInject */
        constructor(
            private UserService: aq.services.UserService,
            private utilityAccount: UtilityAccount,
            private urjanetEnrollment: UrjanetEnrollment,
            private urjanetEnrollmentAccount: UrjanetEnrollmentAccount
        ){
            this.isAqAdmin = this.UserService.currentUser.userType === 'ADMINISTRATOR';

            this.currentNavItem = 'admin';
        };

        public isSelectedTab(name) {
            return this.currentNavItem == name;
        }


    }

    angular.module('aq.utilityBudgets').controller('UtilityAccountDetailsAdmin', UtilityAccountDetailsAdmin);
}
