'use strict';

angular.module('accounts').controller('AccountCtrl',
    function($scope, Messages, Restangular, Errors, UserService) {
        $scope.saveAccount = function() {
            return $scope.account.put().then(function(account) {
                UserService.updateAccount(account.model);
                _.extend($scope.account, account.model);
                $scope.updateAccount(account.model);
                UserService.initLanguage();
                Messages.success('Account updated successfully');
            }, Errors.forPromise());
        };
    });
