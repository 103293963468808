namespace aq.propertySettings {
    export enum AlertType {
        USAGE = 'Usage',
        OFFLINE = 'Meter Offline',
        STATE = 'State'
    }

    export class AlertDetailsCtrl {
        public alertType: AlertType;
        public actions: aq.common.models.EntityMenuAction[];
        private adminReceivers = [];
        private propertySettingsAccess: common.models.AppAccessObject;
        private editMode: 'basic' | 'advanced';
        private nonFormDataChangeIndicator: number;
        private backAction: aq.components.BackButtonData;
        private hideError: boolean;
        private entityLabel: string;
        /* @ngInject */
        constructor(
            private $scope: AlertDetailsCtrlScope,
            private stateAlert: StateAlert,
            private usageAlert: RealTimeEnergyAlert,
            private recoveryNotification,
            private Messages: aq.services.Messages,
            private DataStore: aq.common.DataStore,
            private Restangular: restangular.IService,
            private building: aq.common.models.Building,
            private users: aq.common.models.User[],
            private units: aq.common.models.Unit[],
            private buildingDrillin: any,
            private notificationSenders: aq.common.models.NotificationSender[],
            private UserService,
            private authAccess: common.models.AuthAppAccess,
            private RestangularV3: restangular.IService,
            private isNewAlert: boolean,
            private $state: ng.ui.IStateService,
            private $timeout: ng.ITimeoutService,
            private $mdDialog: ng.material.IDialogService,
            private account: aq.common.models.Account
        ) {
            this.propertySettingsAccess = this.authAccess['Property Settings'];
            this.$scope.isAdmin = UserService.currentUser.userType === 'ADMINISTRATOR';
            this.$scope.building = building;
            this.$scope.recoveryNotification = recoveryNotification;
            this.$scope.allUnits = units;
            this.$scope.buildingDrillin = buildingDrillin;
            this.actions = [
                { icon: 'build', label: 'Test', cb: () => this.sendTestAlert() }
            ];
            if (this.$scope.isAdmin) {
                this.actions.push({ icon: 'build', label: 'Test Save History', cb: () => this.sendTestAlertSaveHistory() });
            }

            this.prepareAlertForView();
            if (!this.$scope.alert) {
                this.Messages.info('Alert does not exist');
                this.$state.go('aq.properties.buildings.alerts', { changeTimeStamp: moment().valueOf() });
                return;
            }
            this.entityLabel = this.$scope.alert.name;

            this.$scope.alertForPreview = angular.copy(this.$scope.alert);
            this.editMode = 'basic';
            this.nonFormDataChangeIndicator = 0;

            const backData = {
                changeTimeStamp: moment().valueOf()
            };
            this.backAction = {
                state: 'aq.properties.buildings.alerts',
                stateData: backData,
                isDataSetOnSaveOnly: !this.isNewAlert,
                onBackAction: () => {
                    this.hideError = true;
                    this.prepareAlertForRequest();
                }
            };
        }

        public onAlertChange($event) {
            this.$scope.alertForPreview = angular.copy($event.alert);
            this.nonFormDataChangeIndicator++;
        }

        public save() {
            this.hideError = true;
            this.prepareAlertForRequest();
            let promise;
            if (this.alertType === AlertType.USAGE) {
                promise = this.DataStore.update(this.$scope.alert, { inflate: 'receivers,customRule' });
            } else if (this.alertType === AlertType.OFFLINE) {
                promise = this.DataStore.update(this.$scope.alert, { inflate: 'currentState,receivers' });
            } else if (this.alertType === AlertType.STATE) {
                promise = this.DataStore.update(this.$scope.alert);
            }
            promise.then((alert) => {
                if (this.alertType === AlertType.USAGE) {
                    this.Restangular.restangularizeElement(this.building, alert, 'realTimeEnergyAlerts');
                } else if (this.alertType === AlertType.OFFLINE) {
                    this.Restangular.restangularizeElement(this.building, alert, 'recoveryNotifications');
                } else if (this.alertType === AlertType.STATE) {
                    this.stateAlert = alert;
                }
                alert.fromServer = true; // FIXME - set this so restangular doesn't execute a POST on next save
                this.Messages.info('Alert Saved');
            }, (error) => {
                if (this.alertType === AlertType.STATE) {
                    this.stateAlert = this.RestangularV3.copy(this.stateAlert) as any; // FIXME - force child component update
                }
                this.Messages.error('Error saving Alert');
            }).finally(() => {
                this.prepareAlertForView();
                // prevent flickering of 'ghost' validation errors while alert view is digested after update
                this.$timeout(() => this.hideError = false, 1000);
            });
        }

        public delete() {
            const confirm = this.$mdDialog.confirm()
                .title('Are you sure you want to delete this alert?')
                .ariaLabel('Delete Alert')
                .ok('Ok')
                .cancel('Cancel');
            this.$mdDialog.show(confirm)
                .then(() => this.DataStore.delete(this.$scope.alert)
                    .then(() => {
                        this.Messages.info('Alert has been deleted');
                        this.$state.go('aq.properties.buildings.alerts', { changeTimeStamp: moment().valueOf() });
                    }, (err) => {
                        this.Messages.error('Error deleting alert');
                    }));
        }

        public sendTestAlert() {
            let promise;
            if (this.alertType === AlertType.STATE) {
                promise = this.RestangularV3.one('state-alerts', this.$scope.alert.id)
                    .post('send-test-alert');
            } else {
                promise = this.$scope.alert.customPOST(null, 'sendTestAlert');
            }
            promise
                .then(() => {
                    this.Messages.success('Test alert sent.');
                }).catch((err) => {
                    this.Messages.error('Error sending test alert.');
                });
        }

        public sendTestAlertSaveHistory() {
            let promise;
            if (this.alertType === AlertType.STATE) {
                promise = this.RestangularV3.one('state-alerts', this.stateAlert.id)
                    .post('send-test-alert');
            } else {
                promise = this.RestangularV3.one('usage-alerts', this.usageAlert.id)
                    .post('test-notification');
            }
            promise
                .then(() => {
                    this.Messages.success('Test alert sent.');
                }).catch((err) => {
                    this.Messages.error('Error sending test alert.');
                });
        }

        private prepareAlertForView() {
            let notificationType = 'GEM_METER_DOWN';
            if (this.usageAlert) {
                this.alertType = AlertType.USAGE;
                this.$scope.alert = this.usageAlert;
                notificationType = 'REAL_TIME_ENERGY';
                this.prepareUsageAlertForView();
            } else if (this.recoveryNotification) {
                this.alertType = AlertType.OFFLINE;
                // hold onto any admin receivers
                this.adminReceivers = _.filter(this.recoveryNotification.receivers, { classType: 'AdminReceiver' });
                _.remove(this.recoveryNotification.receivers, { classType: 'AdminReceiver' });
                this.$scope.alert = this.recoveryNotification;
            } else if (this.stateAlert) {
                this.alertType = AlertType.STATE;
                notificationType = 'STATE';
                this.$scope.alert = this.stateAlert;
                this.prepareStateAlertForView();
            }
            if (!this.$scope.alert) {
                return;
            }
            if (!this.$scope.alert.selectedUsers) {
                const mappedUsers = _.map(this.$scope.alert.receivers, (receiver) => {
                    return _.find(this.users, { id: receiver.user });
                });
                this.$scope.alert.selectedUsers = _.compact(mappedUsers);
            }

            if (this.$scope.alert.sendTextMessage === undefined) {
                const smsNotification = _.find(this.notificationSenders, { name: 'SMS', notificationType });
                if (smsNotification && _.includes(this.$scope.alert.senders, smsNotification.id)) {
                    this.$scope.alert.sendTextMessage = true;
                } else {
                    this.$scope.alert.sendTextMessage = false;
                }
            }
            if (this.$scope.alert.sendEmail === undefined) {
                const mailNotification = _.find(this.notificationSenders, { name: 'Mail', notificationType });
                if (mailNotification && _.includes(this.$scope.alert.senders, mailNotification.id)) {
                    this.$scope.alert.sendEmail = true;
                } else {
                    this.$scope.alert.sendEmail = false;
                }
            }
        }

        private prepareTimeFrameForView() {
            // whenCondition
            if (!this.$scope.alert.whenCondition) {
                this.$scope.alert.whenCondition = 'CALENDAR';
            }
            // date
            if (this.$scope.alert.customRule) {
                if (this.$scope.alert.customRule.dateEnd) {
                    this.$scope.alert.customRule.dateEnd = new Date(this.$scope.alert.customRule.dateEnd);
                }
                if (this.$scope.alert.customRule.dateStart) {
                    this.$scope.alert.customRule.dateStart = new Date(this.$scope.alert.customRule.dateStart);
                }
                if (this.$scope.alert.customRule.timeEnd) {
                    this.$scope.alert.customRule.timeEnd = this.time24hTo12HrFormat(this.$scope.alert.customRule.timeEnd);
                }
                if (this.$scope.alert.customRule.timeStart) {
                    this.$scope.alert.customRule.timeStart = this.time24hTo12HrFormat(this.$scope.alert.customRule.timeStart);
                }
            }
        }

        private prepareStateAlertForView() {
            this.prepareTimeFrameForView();
            const alert = this.$scope.alert as StateAlert;
            _.each(alert.alertLevels, (level) => {
                if (level.queryableType) {
                    level.queryableType = level.queryableType.toLowerCase();
                }
            });
            alert.alertLevels = _.sortBy(alert.alertLevels, 'order');
            // prepping measure/unit/queryable for alertLevels for view is in StateAlertLevels.$onChanges
            // needed to be there since we have our cache of queryable names there
        }

        private prepareUsageAlertForView() {
            this.prepareTimeFrameForView();
            // drillmode
            const alert = this.$scope.alert as RealTimeEnergyAlert;
            if (!alert.drillMode) {
                alert.drillMode = 'BUILDING';
            }
            if (typeof alert.unit === 'string') {
                alert.unit = _.find(this.units, { value: alert.unit });
            }
        }

        private time24hTo12HrFormat(time: string): string {
            if (time.match(/^\d{2}:\d{2}:\d{2}$/)) {
                return moment(time, 'kk:mm:ss').format('h:mm A');
            }
            return time;
        }

        private time12HrTo24HrFormat(time: string): string {
            if (time.match(/^\d{1,2}:\d{2} \D{2}$/)) {
                return moment(time, 'h:mm A').format('HH:mm:ss');
            }
            return time;
        }

        private prepareStateAlertForRequest(alert: StateAlert): void {
            if (alert.customRule) {
                if (alert.customRule.timeEnd) {
                    alert.customRule.timeEnd = this.time12HrTo24HrFormat(alert.customRule.timeEnd);
                }
                if (alert.customRule.timeStart) {
                    alert.customRule.timeStart = this.time12HrTo24HrFormat(alert.customRule.timeStart);
                }
            }
            delete alert.dayCount;
            delete alert.lifetimeCount;
            _.each(alert.alertLevels, (level) => {
                delete level.queryable;
                delete level.measureUnit;
            });
        }

        private prepareAlertForRequest() {
            let notificationType = 'GEM_METER_DOWN';
            if (this.alertType === AlertType.USAGE) {
                const alert = this.$scope.alert as RealTimeEnergyAlert;
                if (alert.drillMode === 'BUILDING') {
                    alert.drillMode = null;
                }
                alert.unit = alert.unit.value;
                notificationType = 'REAL_TIME_ENERGY';
            } else if (this.alertType === AlertType.STATE) {
                notificationType = 'STATE';
                this.prepareStateAlertForRequest(this.$scope.alert as StateAlert);
            }
            // @note: determine if we have mail, sms, push notifications enabled
            const smsNotification = _.find(this.notificationSenders, { name: 'SMS', notificationType });
            const mailNotification = _.find(this.notificationSenders, { name: 'Mail', notificationType });
            if (this.$scope.alert.sendTextMessage && smsNotification && !_.includes(this.$scope.alert.senders, smsNotification.id)) {
                this.$scope.alert.senders.push(smsNotification.id);
            } else if (!this.$scope.alert.sendTextMessage) {
                _.remove(this.$scope.alert.senders, s => s === smsNotification.id);
            }
            delete this.$scope.alert.sendTextMessage;
            if (this.$scope.alert.sendEmail && mailNotification && !_.includes(this.$scope.alert.senders, mailNotification.id)) {
                this.$scope.alert.senders.push(mailNotification.id);
            } else if (!this.$scope.alert.sendEmail) {
                _.remove(this.$scope.alert.senders, s => s === mailNotification.id);
            }
            delete this.$scope.alert.sendEmail;

            // @note: find selected user to send notifications to
            const selectedUsers = [];
            _.each(this.$scope.alert.selectedUsers, (user) => {
                selectedUsers.push({
                    user: user.id,
                    classType: 'UserReceiver'
                });
            });
            this.$scope.alert.receivers = selectedUsers;

            if (this.adminReceivers.length > 0) {
                this.$scope.alert.receivers = this.$scope.alert.receivers.concat(this.adminReceivers);
            }
            delete this.$scope.alert.selectedUsers;
        }
    }

    export interface AlertDetailsCtrlScope extends AlertListCtrlScope {
        alert: RealTimeEnergyAlert | StateAlert;
        recoveryNotification;
        units: aq.common.models.Unit[];
        calendarRules: any[];
        unitLabel: string;
        allUnits: aq.common.models.Unit[];
        buildingDrillin: any;
        building: aq.common.models.Building;
        formCtrl: ng.IFormController;
    }

    angular.module('properties').controller('AlertDetailsCtrl', aq.propertySettings.AlertDetailsCtrl);
}
