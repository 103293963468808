namespace aq.settings {
    export class SettingsMetricCRUDCtrl {
        private svc: PlatformSettingsCrudModal;
        /* @ngInject */
        constructor(
            private $scope,
            private $mdDialog: any,
            private $state,
            private Messages: any,
            private ConfirmationService,
            private Restangular: restangular.IService,
            private Errors: any,
            private account: any,
            private object: any,
            private realUnits: any[],
            private accountSettingsAccess: common.models.AppAccessObject,
            private $q: ng.IQService
        ) {
            this.$scope.options = {
                realUnits
            };
            const restangularParams: ModalCrudRestangularParameters = {
                objectName: 'Metric',
                restangularName: 'AccountMetrics'
            };
            this.svc = new PlatformSettingsCrudModal(
                $mdDialog,
                $q,
                Restangular,
                Messages,
                Errors,
                restangularParams,
                object,
                this.account
            );
        }
    }
    angular.module('aq.settings').controller('SettingsMetricCRUDCtrl', SettingsMetricCRUDCtrl);
}
