namespace aq.dashboard.scope {

    // FIXME Couple of hacky methods to create and remove our pass through scope.
    // We pass these through as we need to use them in our custom ADF templates.
    // The template uses an isolated ADF scope, but we need values from our outer scope.
    // Decided to go this path instead of modifying the directive as it would be adding
    // a far too AQ-specific functionality than should be in the general library.
    // Looking for a better way to do this.
    export function setPassThroughScope(dashboard, obj) {
        dashboard.configuration.json.addTemplateUrl = 'app/dashboard/adfTemplates/widget-add.html';
        dashboard.configuration.json.editTemplateUrl = 'app/dashboard/adfTemplates/dashboard-edit.html';
        dashboard.configuration.json.titleTemplateUrl = 'app/dashboard/adfTemplates/dashboard-title.html';
        dashboard.configuration.json.ctrl = obj;
        if (dashboard.configuration.json.rows) {
            dashboard.configuration.json.rows.forEach((row) => {
                if (row.columns) {
                    row.columns.forEach((column) => {
                        if (column.widgets) {
                            column.widgets.forEach((widget) => {
                                widget.titleTemplateUrl = 'app/dashboard/adfTemplates/widget-title.html';
                                widget.editTemplateUrl = 'app/dashboard/adfTemplates/widget-edit.html';
                                widget.deleteTemplateUrl = 'app/dashboard/adfTemplates/widget-delete.html';
                                widget.footerTemplateUrl = 'app/dashboard/adfTemplates/widget-footer.html';
                            });
                        }
                    });
                }
            });
        }
    }
    export function removePassThroughScope(dashboard) {
        if (dashboard.configuration.json.ctrl) {
            delete dashboard.configuration.json.ctrl;
        }
        delete dashboard.configuration.json.addTemplateUrl;
        delete dashboard.configuration.json.editTemplateUrl;
        delete dashboard.configuration.json.titleTemplateUrl;
        if (dashboard.configuration.json.rows) {
            dashboard.configuration.json.rows.forEach((row) => {
                if (row.columns) {
                    row.columns.forEach((column) => {
                        if (column.widgets) {
                            column.widgets.forEach((widget) => {
                                this.removeWidgetPassThroughScope(widget);
                            });
                        }
                    });
                }
            });
        }
    }
    export function removeWidgetPassThroughScope(widget) {
        delete widget.titleTemplateUrl;
        delete widget.editTemplateUrl;
        delete widget.deleteTemplateUrl;
        delete widget.footerTemplateUrl;
        if (widget.config) {
            delete widget.config.options;
            delete widget.config.actions;
            delete widget.config.building;
            delete widget.config.buildings;
            if (widget.config.series) {
                widget.config.series.forEach((series) => {
                    delete series.drilldown;
                });
            }
        }
    }
}
