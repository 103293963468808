angular
.module('aq.settings.calendars')
.filter('intToTime', function () {
    return function (value, type) {
        const step = value * 15; // 15 min step
        let hour: any = Math.floor(value / 4);
        let minute: any = step - (hour * 60);
        let partOfDay;

        if (hour.toString().length === 1) hour = `0${hour}`;
        if (minute.toString().length === 1) minute = `0${minute}`;

        if (type === '12hour') {
            if (hour >= 13) {
                hour = hour - 12;
            }

            if (value < 48) {
                partOfDay = 'am';
            }
            else { partOfDay = 'pm'; }
            return `${hour}:${minute}${partOfDay}`;
        } else if (type === '24hour' && hour === 24) {
            hour = 0;
            return `${hour}:${minute}`;
        }
        return `${hour}:${minute}:00`;
    };
});
