namespace aq.energyInsights {

    export class ConsumptionCtrl {
        static CURRENT_YEAR = parseInt(moment().format('YYYY'));
        static CURRENT_YEAR_ENERGY_SERIES_NAME = `Actual Consumption`;
        static CURRENT_YEAR_NORMALIZED_SERIES_NAME = `Target Consumption`;
        private appRestructure;

        /* @ngInject */
        constructor(
            private $scope: ConsumptionCtrlScope,
            private metric,
            private $filter,
            private currentBuilding: aq.common.models.Building,
            private account: aq.common.models.Account,
            private $state: ng.ui.IStateService,
            public $window: ng.IWindowService,
            private loading,
            private $q,
            private ConsumptionService: aq.energyInsights.ConsumptionService,
            private Messages,
            private Auth: aq.services.Auth
        ) {
            this.$scope.currentYear = ConsumptionCtrl.CURRENT_YEAR;
            this.$scope.metric = metric;
            this.$scope.timezone = this.currentBuilding.timeZoneId;
            this.$scope.periodSearch = {
                start: aq.common.TimeConfiguration.This.Year.start(this.$scope.timezone),
                end: aq.common.TimeConfiguration.This.Year.end(this.$scope.timezone),
                interval: _.find(aq.common.TimeConfiguration.This.Year.intervals, {value: '1mon'}),
                label: 'This Year'
            };
            this.appRestructure = Auth.hasFunctionality('App Restructure');
            $scope.toastMessage = 'Improve your analysis by adding a consumption target for this building!';
            this.onPeriodChange($scope.periodSearch.start, $scope.periodSearch.end, $scope.periodSearch.interval, $scope.periodSearch.label);
        }

        public onPeriodChange(startDate: moment.Moment, endDate: moment.Moment, interval: any, label: string) {
            this.$scope.periodSearch.start = startDate;
            this.$scope.periodSearch.end = endDate;
            this.$scope.periodSearch.interval = interval;
            this.$scope.periodSearch.label = label;
            this.$scope.drilldown = false;
            this.queryData();
        }

        public goToTargets = () => {
            this.$state.go('aq.properties.buildings.targets', {accountId: this.account.id, buildingId: this.currentBuilding.id});
        }

        public queryData(e?) {
            this.loading.start();
            this.$q.all([
                this.ConsumptionService.getTableModel(this.currentBuilding, 'ELECTRICITY', this.$scope.periodSearch, this.account, ''),
                this.ConsumptionService.getTargetModel(this.currentBuilding, 'ELECTRICITY', this.$scope.periodSearch, this.account, ''),
                this.ConsumptionService.getGraphModel(this.currentBuilding, 'ELECTRICITY', this.$scope.periodSearch, this.account)
            ]).then((results) => {
                const tableResults = results[0];
                const targetModel = results[1];
                const graphResults = results[2];

                this.processTableResults(tableResults);

                if (targetModel) {
                    this.$scope.questionModel = targetModel;
                }

                this.processGraphResults(e, graphResults);
            }).catch(() => {
                this.Messages.error('Error during data query.  Reload page');
            }).finally(() => {
                if (this.$scope.chart) {
                    this.$scope.chart.redraw();
                }
                this.loading.stop();
            });
        };

        public addDrillin() {
            if (this.$scope.periodSearch.interval.value === '1mon') {
                this.$scope.chartConfig.chart.events = {
                    drilldown: (e: any) => {
                        const noTzFormat = 'YYYY-MM-DD';
                        const month = moment(e.point.timestamp).tz(this.currentBuilding.timeZoneId).format(noTzFormat);
                        this.$scope['previousPeriodSearch'] = angular.copy(this.$scope.periodSearch);
                        this.$scope.periodSearch.start = moment.tz(month, this.currentBuilding.timeZoneId)
                            .startOf('month');
                        this.$scope.periodSearch.end = moment.tz(month, this.currentBuilding.timeZoneId)
                            .add(1, 'month').startOf('month').subtract(1, 'second');
                        this.$scope.periodSearch.interval = _.find(aq.common.TimeConfiguration.This.Month.intervals, {value: '1d'});
                        this.$scope.periodSearch.label = `in ${this.$filter('text')(moment(e.point.timestamp).format('MMMM'), 'humancapitalize')}`;
                        this.$scope.drilldown = true;
                        this.queryData(e);
                    },
                    drillup: () => {
                        this.$scope.periodSearch = this.$scope.previousPeriodSearch;
                        this.$scope.drilldown = false;
                        this.queryData();
                    }
                };
            }
        }

        public downloadCsv() {
            let csvContent = 'data:text/csv;charset=utf-8,';
            csvContent += `Date,Actual Consumption (${this.metric.unit}), Target Consumption (${this.metric.unit})\n`;
            this.$scope.tableData.rows.forEach((row: any) => {
                csvContent += `${moment(row.timestamp).format('MMM YYYY')}, ${row.value ? row.value : '-'}, ${row.target ? row.target : '-'}\n`;
            });

            csvContent += `Total,${this.$scope.tableData.stats.actualSummary},${this.$scope.tableData.stats.targetSummary}\n`;

            const fileName = `${this.$scope.currentYear}_Consumption_Report.csv`;
            let link = this.getAnchor();
            link.setAttribute('href', encodeURI(csvContent));
            link.setAttribute('download', fileName);
            link.click();
        }

        public goToOptimization() {
            this.account.get({ single: true }).then((thisAccount) => {
                let optimizationURI = URI('/accounts/' + thisAccount.id + '/optimization/building')
                    .search({
                        unit: 'KWH',
                        apiUnit: 'ENERGY',
                        interval: '1mon',
                        off: _.without(thisAccount.buildings, this.currentBuilding.id).join(','),
                        start: this.$scope.periodSearch.start.tz(this.$scope.timezone).format(),
                        end: this.$scope.periodSearch.end.tz(this.$scope.timezone).format(),
                        children: 'buildings'
                    });
                this.$window.location.href = optimizationURI.toString();
            });
        }

        public convertValueForDisplay(val, valueField = 'value') {
            if (!val || !val[valueField]) {
                return '-';
            }
            return val[valueField];
        }

        processGraphResults(e, graphResults: GraphModel) {
            if (this.$scope.drilldown) {
                this.$scope.chart.addSingleSeriesAsDrilldown(e.point, _.find(graphResults.graph.series, function (s: any) {
                    return s.name == 'Actual Consumption';
                }));
                this.$scope.chart.applyDrilldown();
            } else {
                this.$scope.chartConfig = graphResults.graph;
                this.addDrillin();
            }
        }

        processTableResults(tableResults: TableModel) {
            this.$scope.tableData = tableResults;
            let noTargetRows = _.filter(this.$scope.tableData.rows, (row: any) => {
                return row.target === null;
            });
            if (noTargetRows.length === this.$scope.tableData.rows.length) {
                this.$scope.showToast = !this.appRestructure;
            }
        }

        private getAnchor(): HTMLElement {
            return document.createElement('a');
        }

        private shouldShowTargetConsumption() {
            return !this.$scope.drilldown && this.$scope.periodSearch.interval.label !== 'Day';
        }
    }

    export interface ConsumptionCtrlScope extends ng.IScope {
        questionModel: QuestionAnswerModel;
        chartConfig: __Highcharts.Options;
        previousPeriodSearch: PeriodSearch;
        chart;
        tableData: TableModel;
        energyNotes: {month?: string, notes?: aq.common.models.EnergyNote[]};
        currentYear: number;
        metric: any;
        showToast: boolean;
        toastMessage: string;
        periodSearch: PeriodSearch;
        toast: aq.energyInsights.models.ToastObj;
        timezone: string;
        drilldown: boolean;
    }

    angular.module('energyInsights').controller('ConsumptionCtrl', aq.energyInsights.ConsumptionCtrl);
}
