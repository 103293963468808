var aq;
(function (aq) {
    var energyInsights;
    (function (energyInsights) {
        var service;
        (function (service) {
            var EnergyInsightsDataService = /** @class */ (function () {
                /* @ngInject */
                function EnergyInsightsDataService(DataService, $filter, DataStore, Restangular, RestangularV3, $q) {
                    var _this = this;
                    this.DataService = DataService;
                    this.$filter = $filter;
                    this.DataStore = DataStore;
                    this.Restangular = Restangular;
                    this.RestangularV3 = RestangularV3;
                    this.$q = $q;
                    /**
                     * Takes all data from DataService.data query and formats as an array of Peak[].
                     * This Peak[] array is used for both the chart and the data table for CSV export
                     * @param data all 15 minute intervals from start to end
                     * @returns {Peak[]}
                     */
                    this.createPeaksArray = function (data, metric, building, targets, truncateTo) {
                        var peaks = [];
                        var i = 0;
                        var maxValue = data.values[i];
                        var missingData = false;
                        var maxPeakForInterval = _this.getInterval(data.timestamps[i], building, truncateTo);
                        for (i; i < data.timestamps.length; i++) {
                            var thisInterval = _this.getInterval(data.timestamps[i], building, truncateTo);
                            if (maxPeakForInterval.truncated !== thisInterval.truncated) {
                                // record max for previous interval
                                var target_1 = _this.getTargetKw(maxPeakForInterval.truncated, targets, building);
                                peaks.push(new energyInsights.Peak(maxPeakForInterval, _this.getValueByMeasureAndUnit(maxValue, metric), {
                                    chartValue: target_1,
                                    tableValue: target_1 ? "" + target_1 : '-',
                                    unit: metric.unit
                                }, missingData));
                                // start looking for peak of next interval
                                maxValue = data.values[i] !== null ? data.values[i] : data.missingIntervalValues[i];
                                missingData = data.values[i] === null ? true : false;
                                maxPeakForInterval = thisInterval;
                            }
                            else {
                                if (data.values[i] > maxValue || data.missingIntervalValues[i] > maxValue) {
                                    // new peak found from this interval
                                    maxValue = data.values[i] !== null ? data.values[i] : data.missingIntervalValues[i];
                                    missingData = data.values[i] === null ? true : false;
                                    maxPeakForInterval = thisInterval;
                                }
                            }
                        }
                        var target = _this.getTargetKw(maxPeakForInterval.truncated, targets, building);
                        peaks.push(new energyInsights.Peak(maxPeakForInterval, _this.getValueByMeasureAndUnit(maxValue, metric), {
                            tableValue: target ? "" + target : '-',
                            chartValue: target,
                            unit: metric.unit
                        }, missingData));
                        return _this.markMissingIntervals(peaks, data, truncateTo, building);
                    };
                    this.getInterval = function (timestamp, building, truncateTo) {
                        var truncatedMoment = moment.tz(timestamp, building.timeZoneId)
                            .startOf(truncateTo);
                        return {
                            raw: timestamp,
                            truncated: truncatedMoment.valueOf()
                        };
                    };
                    this.getValueByMeasureAndUnit = function (value, metric) {
                        var filter = _this.$filter('toUnit');
                        if (value == null || metric == null) {
                            return { chartValue: null, tableValue: '', unit: '-' };
                        }
                        else {
                            return {
                                chartValue: Math.round(filter(value, metric, false)),
                                tableValue: filter(value, metric, true), unit: metric.unit
                            };
                        }
                    };
                    this.getTargetKw = function (when, targets, building) {
                        var targetKw = _.find(targets, function (target) {
                            var startDate = moment.tz(target.startDate, building.timeZoneId);
                            var endDate = moment.tz(target.endDate, building.timeZoneId);
                            var whenDate = moment.tz(when, building.timeZoneId);
                            return whenDate.isBetween(startDate, endDate) || whenDate.isSame(startDate) || whenDate.isSame(endDate);
                        });
                        if (targetKw) {
                            return targetKw.value;
                        }
                        else {
                            return null;
                        }
                    };
                }
                EnergyInsightsDataService.prototype.getEnergy = function (building, startTime, endTime, metric, interval, account) {
                    var _this = this;
                    return this.DataService.data(this.Restangular.one('accounts', account.id).one('buildings', building.id), interval, startTime, endTime, metric).then(function (data) {
                        return _this.mapToDataValues(data, 'values', _this.$filter('toUnit'), metric, true);
                    });
                };
                EnergyInsightsDataService.prototype.getWeatherNormalizedEnergy = function (building, startTime, endTime, metric, interval, account) {
                    var _this = this;
                    return this.DataService.weatherNormalization(this.Restangular.one('accounts', account.id).one('buildings', building.id), interval, startTime, endTime, metric).then(function (data) {
                        return _this.mapToDataValues(data, 'weatherNormalizedValues', _this.$filter('toUnit'), metric, true);
                    });
                };
                EnergyInsightsDataService.prototype.getTargetConsumption = function (building, startTime, endTime, metric, interval, account) {
                    if (startTime.date() != 1) {
                        startTime.add(1, interval.label).startOf(interval.label);
                    }
                    return this.DataStore.getList(this.Restangular.one('accounts', account.id).one('buildings', building.id).one('Targets'), 'queryTargets', {
                        startDate: startTime.format(),
                        endDate: endTime.format(),
                        targetType: 'CONSUMPTION',
                        measure: 'electricity'
                    }).then(function (targets) {
                        var pointer = startTime.clone();
                        var targetArr = [];
                        var _loop_1 = function () {
                            var monthYear = pointer.format('MMM YYYY');
                            var targetItem = _.find(targets, function (target) {
                                if (target) {
                                    return moment(target.startDate).format('MMM YYYY') == monthYear;
                                }
                            });
                            if (targetItem) {
                                targetArr.push(targetItem);
                            }
                            else {
                                targetArr.push(null);
                            }
                            pointer = pointer.add(1, 'month');
                        };
                        while (pointer.valueOf() < endTime.valueOf()) {
                            _loop_1();
                        }
                        return targetArr;
                    });
                };
                EnergyInsightsDataService.prototype.getTemperatures = function (building, startTime, endTime, temperatureUnit, interval, account) {
                    var _this = this;
                    var noTemperatureData = { 'climateNormals': [], 'timestamps': [], 'values': [] };
                    return this.DataService.temperature(this.Restangular.one('accounts', account.id).one('buildings', building.id), interval, startTime, endTime, temperatureUnit).then(function (data) {
                        return _this.mapToTempDataValues(data, temperatureUnit);
                    })
                        .catch(function () {
                        return _this.mapToTempDataValues(noTemperatureData, temperatureUnit);
                    });
                };
                EnergyInsightsDataService.prototype.getEnergyNotes = function (account, building) {
                    var queryParams = {
                        buildingId: building.id
                    };
                    return this.RestangularV3.all('notes').all('for-building').getList(queryParams)
                        .then(function (notes) {
                        return _(notes)
                            .filter(function (note) { return note.type === 'OPTIMIZATION' && !note.parent && note.dataContext; })
                            .map(function (note) {
                            if (note.dataContext.docraptorUrl) {
                                note.dataContext.docraptorUrl = note.dataContext.docraptorUrl.replace(/https?:\/\//i, '');
                                // timePreset will overwrite the selected start/end time, so we remove it
                                note.dataContext.docraptorUrl = note.dataContext.docraptorUrl.replace(/(&timePreset=[\w%]+)/g, '');
                                note.dataContext.docraptorUrl = note.dataContext.docraptorUrl.substring(note.dataContext.docraptorUrl.indexOf('/'), note.dataContext.docraptorUrl.length);
                            }
                            return note;
                        })
                            .value();
                    }).catch(function (error) {
                        return [];
                    });
                };
                EnergyInsightsDataService.prototype.getCostBreakdown = function (building, startTime, endTime, interval, measure, account) {
                    return this.Restangular.one('accounts', account.id).one('buildings', building.id).customGET('energyInsights/cost', {
                        start: startTime.format(),
                        end: endTime.format(),
                        interval: interval,
                        measure: measure
                    })
                        .then(function (data) {
                        return data;
                    })
                        .catch(function () {
                        return 'No data for building';
                    });
                };
                EnergyInsightsDataService.prototype.getUtilitySpendingCostBreakdown = function (building, startTime, endTime, interval, measure) {
                    var dataQueryParams = {
                        start: startTime.format("YYYY-MM-DDTHH:mm:ssZ"),
                        end: endTime.format("YYYY-MM-DDTHH:mm:ssZ"),
                        interval: interval,
                        measure: measure
                    };
                    var queryData = angular.extend(dataQueryParams, { buildingId: building.id });
                    return this.RestangularV3
                        .all('utility-spending')
                        .customGET('', queryData)
                        .then(function (data) {
                        return data;
                    })
                        .catch(function () {
                        return 'No data for building';
                    });
                };
                EnergyInsightsDataService.prototype.queryBaseloadStats = function (building, params) {
                    var theseParams = angular.copy(params);
                    theseParams.start = moment(theseParams.start).format();
                    theseParams.end = moment(theseParams.end).format();
                    theseParams.interval = '1h';
                    return building.one('energyinsights').customGET('BaseloadStats', theseParams);
                };
                EnergyInsightsDataService.prototype.queryBaseload = function (building, startTime, endTime, interval, metric, account) {
                    var _this = this;
                    return this.Restangular.one('accounts', account.id).one('buildings', building.id).customGET('energyInsights/BaseloadV2', {
                        start: startTime.format(),
                        end: endTime.format(),
                        interval: interval
                    }).then(function (baseloadResponse) {
                        if (baseloadResponse) {
                            return _this.mapToDataValues(baseloadResponse, 'values', _this.$filter('toUnit'), metric, true);
                        }
                        else {
                            return [];
                        }
                    });
                };
                EnergyInsightsDataService.prototype.getPeakData = function (building, start, end, metric, targets, account, interval) {
                    var _this = this;
                    var dataPromises = this.getPeakDataPromises(building, start, end, metric, account);
                    return this.$q.all(dataPromises).then(function (result) {
                        var processedData = _this.combineMultipleDataServiceResultsByDate(result);
                        var truncateTo;
                        if (interval !== null && interval !== undefined) {
                            truncateTo = interval.label;
                        }
                        else {
                            var diff_1 = end.unix() - start.unix();
                            if (diff_1 > 15724800) {
                                truncateTo = 'month';
                            }
                            else if (diff_1 > 86400) {
                                truncateTo = 'day';
                            }
                            else if (diff_1 > 3600) {
                                truncateTo = 'hour';
                            }
                        }
                        var peaks = _this.createPeaksArray(processedData, metric, building, targets, truncateTo);
                        return peaks;
                    });
                };
                EnergyInsightsDataService.prototype.combineMultipleDataServiceResultsByDate = function (result) {
                    var sortedResult = result.sort(function (a, b) {
                        var aStart = moment(a.start);
                        var bStart = moment(b.start);
                        if (aStart.isAfter(bStart)) {
                            return 1;
                        }
                        if (bStart.isAfter(aStart)) {
                            return -1;
                        }
                        return 0;
                    });
                    var processedData = {
                        id: sortedResult[0].id,
                        name: sortedResult[0].name,
                        missing: [],
                        timestamps: [],
                        missingIntervalValues: [],
                        values: [],
                        partial: []
                    };
                    sortedResult.forEach(function (r) {
                        processedData.missing = processedData.missing.concat(r.missing);
                        processedData.timestamps = processedData.timestamps.concat(r.timestamps);
                        processedData.missingIntervalValues = processedData.missingIntervalValues.concat(r.missingIntervalValues);
                        processedData.values = processedData.values.concat(r.values);
                    });
                    return processedData;
                };
                EnergyInsightsDataService.prototype.hasBudgetsForMeasure = function (building, targets, measureName) {
                    var hasBudgetsForMeasure = _.some(targets, function (budget) {
                        return budget.measure.name.toLowerCase() === measureName.toLowerCase();
                    });
                    return hasBudgetsForMeasure;
                };
                EnergyInsightsDataService.prototype.getBudgetItems = function (building, measureName, startTime, endTime, interval, account) {
                    if (startTime.date() != 1) {
                        startTime.add(1, interval.label).startOf(interval.label);
                    }
                    return this.DataStore.getList(this.Restangular.one('accounts', account.id).one('buildings', building.id).one('Targets'), 'queryTargets', {
                        startDate: startTime.format(),
                        endDate: endTime.format(),
                        targetType: 'BUDGET',
                        measure: measureName
                    }).then(function (targets) {
                        var pointer = startTime.clone();
                        var targetArr = [];
                        var _loop_2 = function () {
                            var monthYear = pointer.format('MMM YYYY');
                            var targetItem = _.find(targets, function (target) {
                                if (target) {
                                    return moment(target.startDate).format('MMM YYYY') == monthYear;
                                }
                            });
                            if (targetItem) {
                                targetArr.push(targetItem);
                            }
                            else {
                                targetArr.push(null);
                            }
                            pointer = pointer.add(1, 'month');
                        };
                        while (pointer.valueOf() < endTime.valueOf()) {
                            _loop_2();
                        }
                        return targetArr;
                    });
                };
                EnergyInsightsDataService.prototype.mapToDataValues = function (data, valuesField, filter, unit, mapNulls) {
                    var dataValues = [];
                    for (var i = 0; i < data[valuesField].length; i++) {
                        if (data[valuesField][i] != null) {
                            dataValues.push({
                                timestamp: data.timestamps[i],
                                value: Math.round(filter(data[valuesField][i], unit)),
                                isMissingData: false
                            });
                        }
                        else if (data['missingIntervalValues'] && data['missingIntervalValues'][i] !== null) {
                            dataValues.push({
                                timestamp: data.timestamps[i],
                                value: Math.round(filter(data['missingIntervalValues'][i], unit)),
                                isMissingData: true
                            });
                        }
                        else if (mapNulls) {
                            // option to keep null values in data array if they have a timestamp
                            if (data['timestamps'][i] != null) {
                                dataValues.push({
                                    timestamp: data.timestamps[i],
                                    value: null,
                                    isMissingData: true
                                });
                            }
                        }
                    }
                    return dataValues;
                };
                EnergyInsightsDataService.prototype.transparentColor = function (color) {
                    var alpha = 0.5;
                    var convertedAlpha = Math.floor(alpha * 255);
                    var alphaString = convertedAlpha < 16 ? '0' + convertedAlpha.toString(16) : convertedAlpha.toString(16);
                    return color + alphaString;
                };
                // ------------------------
                EnergyInsightsDataService.prototype.getPeakDataPromises = function (building, start, end, metric, account) {
                    var result = [];
                    if (end.diff(start, 'month') > 0) {
                        var monthCounter = _.cloneDeep(start);
                        while (end.diff(monthCounter, 'month') !== 0) {
                            var intermittentStart = _.cloneDeep(monthCounter);
                            var intermittentEnd = _.cloneDeep(monthCounter).add(1, 'month');
                            result.push(this.DataService.data(this.Restangular.one('accounts', account.id).one('buildings', building.id), '15min', intermittentStart, intermittentEnd, metric));
                            monthCounter = monthCounter.add(1, 'month');
                        }
                        if (end.diff(monthCounter, 'day') !== 0) {
                            result.push(this.DataService.data(this.Restangular.one('accounts', account.id).one('buildings', building.id), '15min', monthCounter, end, metric));
                        }
                    }
                    else {
                        result.push(this.DataService.data(this.Restangular.one('accounts', account.id).one('buildings', building.id), '15min', start, end, metric));
                    }
                    return result;
                };
                EnergyInsightsDataService.prototype.mapToTempDataValues = function (data, unit) {
                    var filter = this.$filter('toTemperatureUnit');
                    var dataValues = [];
                    for (var i = 0; i < data['values'].length; i++) {
                        if (data['values'][i] != null) {
                            dataValues.push({
                                timestamp: data.timestamps[i],
                                value: Math.round(filter(data['values'][i], unit)),
                                climateNormal: Math.round(filter(data.climateNormals[i], unit)),
                                isMissingData: false
                            });
                        }
                        else if (data['missingIntervalValues'] && data['missingIntervalValues'][i] !== null) {
                            dataValues.push({
                                timestamp: data.timestamps[i],
                                value: Math.round(filter(data['missingIntervalValues'][i], unit)),
                                climateNormal: Math.round(filter(data.climateNormals[i], unit)),
                                isMissingData: true
                            });
                        }
                        else {
                            if (data['timestamps'][i] != null) {
                                dataValues.push({
                                    timestamp: data.timestamps[i],
                                    value: null,
                                    climateNormal: data['climateNormals'][i] ? Math.round(filter(data.climateNormals[i], unit)) : null,
                                    isMissingData: true
                                });
                            }
                        }
                    }
                    return dataValues;
                };
                EnergyInsightsDataService.prototype.markMissingIntervals = function (peaks, data, truncateTo, building) {
                    var _this = this;
                    data.missingIntervalValues.forEach(function (d, i) {
                        var interval = _this.getInterval(data.timestamps[i], building, truncateTo);
                        var index = peaks.findIndex(function (p) { return p.date.truncated === interval.truncated; });
                        if (d !== null && index !== -1) {
                            peaks[index].isMissingData = true;
                        }
                    });
                    return peaks;
                };
                return EnergyInsightsDataService;
            }());
            service.EnergyInsightsDataService = EnergyInsightsDataService;
            angular.module('energyInsights').service('EnergyInsightsDataService', EnergyInsightsDataService);
            // Single queryable Data API Response
            var DataServiceData = /** @class */ (function () {
                function DataServiceData() {
                }
                return DataServiceData;
            }());
            service.DataServiceData = DataServiceData;
        })(service = energyInsights.service || (energyInsights.service = {}));
    })(energyInsights = aq.energyInsights || (aq.energyInsights = {}));
})(aq || (aq = {}));
