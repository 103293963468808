'use strict';

angular.module('aq.accounts.calendars', ['aq.calendar.widget.eventGenerator', 'ui.router', 'aq.auth', 'aq.messages'])
    .config(($stateProvider) => {
        $stateProvider
            .state('aq.accounts.calendars', {
                url: '/calendars',
                templateUrl: 'app/accounts/calendars/controllers/calendars/calendarList.html',
                controller: 'CalendarListCtrl',
                data: {
                    breadcrumb: 'Calendars'
                },
                resolve: {
                    workCalendars: (Restangular, accountId, waitForAuthToken) => {
                        return Restangular.one('accounts', accountId).all('workCalendars').getList({inflate: 'rates'});
                    },
                    ruleCategories: (Restangular, accountId, waitForAuthToken) => {
                        return Restangular.one('accounts', accountId).all('RuleCategories').getList();
                    }
                }
            })
            .state('aq.accounts.calendars.rules', {
                url: '/:calendarId',
                abstract: true,
                templateUrl: 'app/accounts/calendars/controllers/rules/ruleMain.html',
                controller: 'RuleMainCtrl'
            })
            .state('aq.accounts.calendars.rules.views', {
                url: '/rules',
                views: {
                    'ruleList' : {
                        templateUrl: 'app/accounts/calendars/controllers/rules/list/ruleList.html',
                        controller: 'RuleListCtrl'
                    },
                    'calendarWidget' : {
                        templateUrl: 'app/accounts/calendars/controllers/calendars/widget/calendarWidget.html',
                        controller: 'CalendarWidgetCtrl'
                    },
                    'form' : {
                        templateUrl: 'app/accounts/calendars/controllers/calendars/form/calendarForm.html',
                        controller: 'CalendarFormCtrl'
                    }
                },
                data: {
                    breadcrumb: 'Events'
                }
            })
            .state('aq.accounts.calendars.rules.views.form', {
                url: '/:ruleId',
                views: {
                    'form@aq.accounts.calendars.rules': {
                        templateUrl: 'app/accounts/calendars/controllers/rules/form/ruleForm.html',
                        controller: 'RuleFormCtrl'
                    }
                }

            });
    });
