namespace aq {
    import HelpActions = aq.models.segment.HelpActions;

    export class MainHelpCtrl extends aq.common.Controllers.ModalCtrl {
        public isAppcuesEnabled: boolean;
        public isIntercomEnabled: boolean;
        public appcueFlows: aq.common.models.appcues.Flow[];
        public readonly helpLink = 'http://help.aquicore.com/';
        public readonly webinarLink = 'https://aquicore.zoom.us/webinar/register/WN_Np8xE6EoTN6dvzqexlyo_w';
        private intercom: any;
        /* @ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private AppcuesService: aq.services.AppcuesService,
            private Messages: services.Messages,
            private $window: ng.IWindowService,
            private Segment: aq.services.SegmentService,
            private account,
            private UserService,
            private Auth: aq.services.Auth,
        ) {
            super({}, $mdDialog);
            this.isAppcuesEnabled = this.AppcuesService.isEnabled;
            if (this.isAppcuesEnabled && !Auth.hasFunctionality('App Restructure')) {
                this.AppcuesService.getFlows().then((flows) => {
                    this.appcueFlows = flows;
                });
            }
            this.intercom = this.$window['Intercom'];
            if (this.intercom) {
                this.isIntercomEnabled = true;
            }
            this.account = account;
        }
        public onFlowClick(flow: aq.common.models.appcues.Flow) {
            if (this.AppcuesService.startFlow(flow)) {
                this.$mdDialog.hide();
            } else {
                this.Messages.error(`Guide '${flow.name}' is not available`);
            }
        }
        public openChat() {
            let buildingIds = this.account.buildings;
            if (!this.UserService.currentUser.currentProfile.hasFullVisibility) {
                buildingIds = this.UserService.currentUser.currentProfile.visbilities;
            }
            this.Segment.trackChurnZeroEvent(HelpActions.HELP_REQUEST_INTERCOM, buildingIds);
            this.intercom('showMessages');
            this.$mdDialog.hide();
        }
        public openDocs() {
            let buildingIds = this.account.buildings;
            if (!this.UserService.currentUser.currentProfile.hasFullVisibility) {
                buildingIds = this.UserService.currentUser.currentProfile.visbilities;
            }
            this.Segment.trackChurnZeroEvent(HelpActions.HELP_REQUEST_GUIDE, buildingIds);
            this.$window.open(this.helpLink, '_blank');
            this.$mdDialog.hide();
        }
        public openWebinarRegistration() {
            let buildingIds = this.account.buildings;
            if (!this.UserService.currentUser.currentProfile.hasFullVisibility) {
                buildingIds = this.UserService.currentUser.currentProfile.visbilities;
            }
            this.Segment.trackChurnZeroEvent(HelpActions.HELP_REQUEST_WEBINAR, buildingIds);
            this.$window.open(this.webinarLink, '_blank');
            this.$mdDialog.hide();
        }
    }
    angular.module('aq').controller('MainHelpCtrl', MainHelpCtrl);
}
