namespace aq.utilityBudgets {
    declare var Highcharts: __Highcharts.Static;

    export class MonthBudgetCtrl {
        public monthBudgetChartTotal: __Highcharts.Options;
        public temperatureSeries: __Highcharts.SeriesOptions;
        public temperatureForecastSeries: __Highcharts.SeriesOptions;
        public budgetTargetSeries: __Highcharts.SeriesOptions;
        public chart: __Highcharts.ChartObject;
        public totalDays: number;
        public today: number;
        public tableData: MonthTableDataItem[];
        public selectedType: string;
        public isCurrentMonth: boolean;
        public colors: string[];
        public selectedMonthPeriod: BudgetMonthPeriod;
        public availableBudgetMonths: BudgetMonthPeriod[];
        public monthBudgetFormatted: string;
        public runningTotal: number[];
        public customBlendedRate: number;
        public periodType: string;
        public isPeriodTypeDisabled: boolean;
        public isNoProjectionDataVisible: boolean;
        private now: number;
        private interval: ng.IPromise<void>;
        private MAX_ACTIVITIES_TO_SHOW = 1;

        /* ngInject */
        constructor(
            private account: aq.common.models.Account,
            private building: aq.common.models.Building,
            private budgetMonth: string,
            private budgetYear: string,
            private budgetMeasure: string,
            private monthBudget: number,
            private activities: aq.models.activity.ActivityListResponse,
            private buildingSpending: UtilitySpendingCumulativeResponse,
            private temperature: Temperature,
            private temperatureUnit: MeasureUnit,
            private currencyUnitSymbol: string,
            private $timeout: ng.ITimeoutService,
            private $filter: ng.IFilterService,
            private GraphEditService: aq.dashboard.widgets.GraphEditService,
            private $state: ng.ui.IStateService,
            private measures: aq.common.models.Measure[],
            private $location: ng.ILocationService,
            private $translate,
            private $interval: ng.IIntervalService,
            private $scope: ng.IScope,
            private measureUnitMap: { [measure: string]: string },
            private Auth: aq.services.Auth,
        ) {
            this.init();
            this.initConstantSeries();

            this.buildBreakdownChart();
            this.buildTableData();
            this.$timeout(() => this.chart && this.chart.reflow(), 200);
        }

        public init() {
            this.periodType = 'month';
            this.initBlendedRate();
            this.colors = Highcharts.getOptions().colors;
            this.initAvailableMonths();
            this.totalDays = moment(`${this.budgetYear} ${this.budgetMonth}`, 'YYYY M').daysInMonth();
            this.isCurrentMonth = moment().format('YYYY') == this.budgetYear && moment().format('M') == this.budgetMonth;
            this.selectedType = this.budgetMeasure;
            this.today = this.buildingSpending.lastAvailableFullDataTimestamp !== null ? parseInt(moment(this.buildingSpending.lastAvailableFullDataTimestamp).format('D')) : this.totalDays;
            this.isNoProjectionDataVisible = this.isNoProjectionData();

            this.now = moment().valueOf();
            this.$scope.$on('$destroy', () => {
                if (this.interval) {
                    this.$interval.cancel(this.interval);
                }
            });
            this.interval = this.$interval(() => {
                this.now = moment().valueOf();
            }, 60000);

            // TMP
            const queryParams = this.$location.search();
            if (this.isCurrentMonth && queryParams.testForDay) {
                this.today = parseInt(queryParams.testForDay);
            }

            this.monthBudgetFormatted = this.$filter('currency')(this.monthBudget, this.currencyUnitSymbol, 0);
        }

        public initBlendedRate() {
            this.customBlendedRate = _.first(this.buildingSpending.spending.data).datum.blendedRate;
        }

        public initAvailableMonths() {
            this.availableBudgetMonths = [];
            let monthCounter = 24;
            let date = moment();
            while (monthCounter > 0) {
                this.availableBudgetMonths.push({
                    name: date.format('MMMM YYYY'),
                    year: date.format('YYYY'),
                    month: date.format('M')
                });
                date = date.subtract(1, 'month');
                monthCounter--;
            }
            this.selectedMonthPeriod = _.find(this.availableBudgetMonths, (item: BudgetMonthPeriod) => {
                return item.year == this.budgetYear && item.month == this.budgetMonth;
            });
            if (!this.selectedMonthPeriod) {
                const currentDate = moment(`${this.budgetMonth} ${this.budgetYear}`, 'M YYYY');
                this.selectedMonthPeriod = {
                    name: currentDate.format('MMMM YYYY'),
                    year: this.budgetYear,
                    month: this.budgetMonth
                };
                this.availableBudgetMonths[this.availableBudgetMonths.length - 1] = this.selectedMonthPeriod;
            }
        }

        public recalculateProjection() {
            this.$state.go(this.$state.current.name, {
                blendedRate: this.customBlendedRate
            });
        }

        public onChangePeriod() {
            this.$state.go(this.$state.current.name, {
                year: this.selectedMonthPeriod.year,
                month: this.selectedMonthPeriod.month,
                blendedRate: null
            });
        }

        public onChangeType() {
            this.$state.go(this.$state.current.name, {
                measure: this.selectedType,
                blendedRate: null
            });
        }

        public goToYearlyView() {
            this.isPeriodTypeDisabled = true;
            const fiscalMonth = this.building.fiscalStartMonth || 1;
            let year = parseInt(this.budgetYear);
            if (parseInt(this.budgetMonth) < fiscalMonth) {
                year -= 1;
            }
            this.$state.go('aq.utilityBudgets.year', {
                measure: this.selectedType,
                year
            });
        }

        public initConstantSeries() {
            this.temperatureSeries = this.getTemperatureSeries(this.temperature);
            this.temperatureForecastSeries = this.getTemperatureForecastSeries(this.temperature);
        }

        public getTotalProjection() {
            let total = 0;
            _.each(this.buildingSpending.spending.data, (item: UtilitySpendingDataItem) => {
                if (item.datum.projected) {
                    total += item.datum.projected;
                }
                if (item.datum.utilityBill) {
                    total += item.datum.utilityBill;
                }
                if (item.datum.consumption) {
                    total += item.datum.consumption;
                }
            });
            return total;
        }

        public getSpendDiffPercent() {
            if (this.monthBudget > 0) {
                const total = this.getTotalProjection();
                const diff = Math.abs(this.monthBudget - total);
                return (diff / this.monthBudget * 100).toFixed(1);
            }
            return null;
        }

        public getTotalProjectionPercent() {
            const percent = this.getTotalProjection() * 1000 / this.monthBudget;
            return `${(Math.round(percent) / 10).toFixed(1)}%`;
        }

        public getTotalProjectionFormatted() {
            const total = this.getTotalProjection();
            return this.$filter('currency')(total, this.currencyUnitSymbol, 0);
        }

        public resetProjection() {
            this.$state.go(this.$state.current.name, {
                blendedRate: null
            });
        }

        public buildTableData() {
            this.tableData = [];
            const now = moment();
            _.each(this.buildingSpending.spending.data, (utilitySpendingDataItem: UtilitySpendingDataItem, index) => {
                const date = moment(utilitySpendingDataItem.timestamp);
                const charge = (utilitySpendingDataItem.datum.utilityBill || 0)
                    + (utilitySpendingDataItem.datum.consumption || 0)
                    + (utilitySpendingDataItem.datum.projected || 0);
                let type = null;
                if (charge > 0 && !utilitySpendingDataItem.datum.utilityBill) {
                    type = utilitySpendingDataItem.datum.projected > 0 ? 'projection' : 'consumption';
                }
                let degreeValue, temperatureType;
                if (date.isAfter(now)) {
                    const temp = _.isNil(this.temperature.values[index]) ?
                        this.temperature.climateNormals[index] : this.temperature.values[index];
                    degreeValue = this.getTemperatureValue(temp);
                    temperatureType = 'forecast';
                } else {
                    degreeValue = this.getTemperatureValue(this.temperature.values[index]);
                    temperatureType = 'historical';
                }

                const activities = this.getActivities(moment(utilitySpendingDataItem.timestamp),
                    moment(utilitySpendingDataItem.timestamp).add(1, 'day').subtract(1, 'second'));
                // Only show activities list if our list is small enough. Too many and it makes our table uggo
                const showActivities = activities.length <= this.MAX_ACTIVITIES_TO_SHOW;
                this.tableData.push({
                    date: moment(utilitySpendingDataItem.timestamp).add(12, 'hour').format('dddd, MMM D'),
                    temperature: degreeValue != null ? `${degreeValue} ${this.temperatureUnit.unit}` : '-',
                    temperatureType,
                    timestamp: utilitySpendingDataItem.timestamp,
                    activities,
                    showActivities,
                    charge: this.$filter('currency')(charge, this.currencyUnitSymbol, 0),
                    type
                });
            });
        }

        public toggleActivities(item: MonthTableDataItem): void {
            item.showActivities = !item.showActivities;
        }

        public getActivitiesRemainingCount(activities: aq.models.activity.SimpleActivityResponse[]): number {
            return activities.length - this.MAX_ACTIVITIES_TO_SHOW;
        }

        public isNoProjectionData() {
            if (!this.isCurrentMonth) {
                return false;
            }
            if (this.today >= this.totalDays) {
                return false;
            }
            return _.every(this.buildingSpending.spending.data, (item: UtilitySpendingDataItem) => !item.datum.projected);
        }

        // Utility Account Charges
        public buildBreakdownChart() {
            const series = [];
            const seriesTotals = [];
            const billTotalsData = [];
            const consumptionTotalsData = [];
            const projectionTotalsData = [];
            let current = 1;
            const data = [];
            let sumCharge = 0;
            let currentDataItem = this.buildingSpending.spending.data[current - 1];
            while (current <= this.today) {
                if (currentDataItem && currentDataItem.datum.utilityBill != null) {
                    sumCharge += currentDataItem.datum.utilityBill || 0;
                    data.push({
                        x: current,
                        y: sumCharge,
                        name: `temp-${current}`
                    });
                    this.addTotalPoint(billTotalsData, current, sumCharge);
                }
                current++;
                currentDataItem = this.buildingSpending.spending.data[current - 1];
            }

            let isFirst = true;
            current = 1;
            currentDataItem = this.buildingSpending.spending.data[current - 1];
            const consumptionChargeData = [];
            let sumConsumptionCharge = 0;
            while (current <= this.today) {
                const billItem = _.find(data, item => item.x == current);
                if (billItem) {
                    sumConsumptionCharge = billItem.y;
                }
                else {
                    if (isFirst) {
                        isFirst = false;
                        const firstIndex = current - 1;
                        consumptionChargeData.push({
                            x: firstIndex,
                            y: sumConsumptionCharge
                        });

                        this.addTotalPoint(consumptionTotalsData, firstIndex, sumConsumptionCharge);
                    }
                    sumConsumptionCharge += currentDataItem.datum.consumption;
                    // Combine today's projected data with today's consumption
                    if (current == this.today) {
                        sumConsumptionCharge += currentDataItem.datum.projected || 0;
                    }
                    consumptionChargeData.push({
                        x: current,
                        y: sumConsumptionCharge,
                        name: `temp-${current}`
                    });
                    this.addTotalPoint(consumptionTotalsData, current, sumConsumptionCharge);
                }

                current++;
                currentDataItem = this.buildingSpending.spending.data[current - 1];
            }

            if (consumptionChargeData.length > 0) {
                const consumptionChargeSeriesConfig = {
                    data: consumptionChargeData,
                    showInLegend: false,
                    name: this.$translate.instant('budgets.Consumption'),
                    type: 'area',
                    dashStyle: 'Dash'
                };
                series.push(consumptionChargeSeriesConfig);
                series.push(this.getFakeSeriesConfigForLegend(consumptionChargeSeriesConfig, 'area'));
            }

            const dataProjected = [];
            let fixPoint = null;
            let sumProjectionCharge = sumConsumptionCharge;
            if (current <= this.totalDays) {
                fixPoint = {
                    x: current - 1,
                    y: sumProjectionCharge
                };

                this.addTotalPoint(projectionTotalsData, current - 1, sumConsumptionCharge);

                while (current <= this.totalDays) {
                    sumProjectionCharge += currentDataItem.datum.projected;
                    dataProjected.push({
                        x: current,
                        y: sumProjectionCharge,
                        name: `temp-${current}`
                    });

                    this.addTotalPoint(projectionTotalsData, current, sumProjectionCharge);

                    current++;
                    currentDataItem = this.buildingSpending.spending.data[current - 1];
                }

                // fix rendering of continuous area charts
                const fix = [];
                if (fixPoint) {
                    fix.push(fixPoint);
                }
                const projectedSeriesConfig = {
                    data: fix,
                    showInLegend: false,
                    name: this.$translate.instant('budgets.Projection'),
                    type: 'area',
                    dashStyle: 'Dot',
                    fillOpacity: 0
                };
                series.push(projectedSeriesConfig);
                series.push(this.getFakeSeriesConfigForLegend(projectedSeriesConfig, 'line'));
            }

            const total = this.getTotalProjection();
            const color = total > this.monthBudget ? seriesColors.red : seriesColors.green;
            const name = total > this.monthBudget ? 'budgets.Over Budget' : 'budgets.Under Budget';
            this.budgetTargetSeries = {
                data: [
                    { x: -1, y: this.monthBudget },
                    { x: 32, y: this.monthBudget }
                ],
                name: this.$translate.instant(name),
                type: 'line',
                dashStyle: 'DashDot',
                color
            };
            series.push(this.budgetTargetSeries);
            series.push(this.temperatureSeries);
            series.push(this.temperatureForecastSeries);

            this.runningTotal = [];
            for (let i = 1; i <= this.totalDays; i++) {
                let valueItem = _.find(billTotalsData, (item) => item.x == i);
                if (!valueItem) {
                    valueItem = _.find(consumptionTotalsData, (item) => item.x == i);
                }
                if (!valueItem) {
                    valueItem = _.find(projectionTotalsData, (item) => item.x == i);
                }
                this.runningTotal.push(valueItem ? valueItem.y : 0);
            }

            const billTotalsSeriesConfig = {
                data: billTotalsData,
                name: this.$translate.instant('budgets.Current Utility Expenses'),
                type: 'area',
                colorIndex: 0,
                index: 0
            };
            seriesTotals.push(billTotalsSeriesConfig);
            if (consumptionTotalsData.length > 0) {
                const consumptionTotalsSeriesConfig = {
                    data: consumptionTotalsData,
                    showInLegend: false,
                    name: this.$translate.instant('budgets.Current Consumption Expenses'),
                    type: 'area',
                    dashStyle: 'Dash',
                    lineColor: this.colors[0],
                    color: this.GraphEditService.getLighterColor(this.colors[0]),
                    index: 1
                };
                seriesTotals.push(consumptionTotalsSeriesConfig);
                seriesTotals.push(this.getFakeSeriesConfigForLegend(consumptionTotalsSeriesConfig, 'area'));
            }
            if (projectionTotalsData.length > 0) {
                const projectionTotalsSeriesConfig = {
                    data: projectionTotalsData,
                    showInLegend: false,
                    name: this.$translate.instant('budgets.Projected Expenses'),
                    type: 'area',
                    dashStyle: 'Dot',
                    colorIndex: 0,
                    index: 2,
                    fillOpacity: 0
                };
                seriesTotals.push(projectionTotalsSeriesConfig);
                seriesTotals.push(this.getFakeSeriesConfigForLegend(projectionTotalsSeriesConfig, 'line'));
            }

            seriesTotals.push(this.budgetTargetSeries);
            seriesTotals.push(this.temperatureSeries);
            seriesTotals.push(this.temperatureForecastSeries);
            this.monthBudgetChartTotal = this.buildChart(seriesTotals);
        }

        public getFakeSeriesConfigForLegend(seriesConfig, type) {
            const config = angular.copy(seriesConfig);
            config.data = [];
            if (type) {
                config.type = type;
            }
            config.showInLegend = true;
            return config;
        }

        public addTotalPoint(series, day, value) {
            const item = _.find(series, (s) => s.x == day);
            if (!item) {
                series.push({
                    x: day,
                    y: value,
                    name: `temp-${day}`
                });
            } else {
                item.y += value;
            }
        }

        public buildChart(series) {
            const monthFormatted = moment(this.budgetMonth, 'M').format('MMM');
            const getContext = () => this;
            return {
                chart: {
                    height: 400,
                    animation: false
                },
                title: {
                    text: null
                },
                subtitle: {
                    text: null
                },
                xAxis: {
                    tickInterval: 1,
                    min: 1,
                    max: this.totalDays,
                    labels: {
                        // tslint:disable-next-line:object-literal-shorthand
                        formatter: function () {
                            const paddedDay = _.padStart(this.value, 2, '0');
                            return `${monthFormatted} ${paddedDay}`;
                        }
                    },
                    plotLines: [{
                        color: '#0000FF',
                        width: 2,
                        value: this.today
                    }],
                    crosshair: true
                },
                yAxis: [{
                    title: { text: this.currencyUnitSymbol },
                    min: 0,
                    max: this.getTotalProjection() > this.monthBudget ? null : this.monthBudget

                }, {
                    title: { text: this.temperatureUnit.unit },
                    opposite: true,
                    minTickInterval: 5
                }],
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal',
                    itemStyle: {
                        fontSize: '11px'
                    }
                },
                plotOptions: {
                    column: {
                        stacking: null
                    },
                    series: {
                        animation: false,
                        marker: {
                            enabled: false
                        },
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    }
                },
                series,
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                tooltip: {
                    // tslint:disable-next-line:object-literal-shorthand
                    formatter: function () {
                        if (this.key && typeof (this.key) == 'string' && this.key.startsWith('temp-')) {
                            const day = this.x;
                            const context = getContext();
                            const utilitySpendingDataItem: UtilitySpendingDataItem = context.buildingSpending.spending.data[day - 1];
                            const utilityBillValue = utilitySpendingDataItem.datum.utilityBill || 0;
                            const consumptionValue = utilitySpendingDataItem.datum.consumption || 0;
                            const projectedValue = utilitySpendingDataItem.datum.projected || 0;

                            const billData = {
                                value: utilityBillValue,
                                valueFormatted: context.$filter('currency')(utilityBillValue, context.currencyUnitSymbol, 0)
                            };
                            const consumptionData = {
                                value: consumptionValue,
                                valueFormatted: context.$filter('currency')(consumptionValue, context.currencyUnitSymbol, 0)
                            };
                            const projectionData = {
                                value: projectedValue,
                                valueFormatted: context.$filter('currency')(projectedValue, context.currencyUnitSymbol, 0)
                            };
                            const date = moment(`${context.budgetYear} ${context.budgetMonth} ${day}`, 'YYYY M D');
                            const dayInfo = `<b>${date.format('dddd, MMMM D')}</b>`;
                            let total = 0;
                            total += (billData.value + consumptionData.value + projectionData.value);
                            let totalContent = '';
                            const totalFormatted = context.$filter('currency')(total, context.currencyUnitSymbol, 0);
                            const color = context.colors[0];
                            const titleTranslationKey = day < context.today ? 'budgets.Today Spend' : 'budgets.Projected Spend';
                            const title = context.$translate.instant(titleTranslationKey);
                            totalContent = `<span style="color:${color};">■ </span><span>${title}: ${totalFormatted}</span>`;

                            const temperature = context.tableData[day - 1].temperature;
                            let temperatureContent;
                            if (temperature == '-') {
                                temperatureContent = `<span style="color:${seriesColors.weather};">■ </span>`;
                            } else if (day > context.today) {
                                temperatureContent = `<span>${context.$translate.instant('budgets.Temperature Forecast')}: ${temperature}</span>`;
                            } else {
                                temperatureContent = `<span>${context.$translate.instant('budgets.Temperature')}: ${temperature}</span>`;
                            }

                            const budgetToDate = Math.round(context.monthBudget * day / context.totalDays);

                            const runningSpend = context.runningTotal[day - 1];
                            const runningSpendFormatted = context.$filter('currency')(runningSpend, context.currencyUnitSymbol, 0);
                            const totalTranslationKey = day <= context.today ? 'budgets.Total Spend' : 'budgets.Projected Total';
                            const runningSpendContent = `<span>${context.$translate.instant(totalTranslationKey)}: ${runningSpendFormatted}</span>`;
                            const budgetToDatePercent = context.monthBudget > 0 ? context.runningTotal[day - 1] * 1000 / context.monthBudget : 0;
                            const budgetToDatePercentFormatted = `${(Math.round(budgetToDatePercent) / 10).toFixed(1)}%`;
                            let budgetToDatePercentContent = '';
                            if (budgetToDate > 0) {
                                budgetToDatePercentContent = `<span>${context.$translate.instant('budgets.Budget Utilization')}: `
                                    + `${budgetToDatePercentFormatted}</span>`;
                            }

                            return [
                                dayInfo,
                                temperatureContent,
                                totalContent,
                                runningSpendContent,
                                budgetToDatePercentContent
                            ].join('<br/>');
                        }
                        return false;
                    }
                }
            };
        }

        private getTemperatureSeries(temperature: Temperature) {
            const temperatureSeriesData = _.map(temperature.values, (value, index) => {
                if (this.isCurrentMonth && index >= this.today) {
                    return null;
                }
                const point = {
                    x: index + 1,
                    y: this.getTemperatureValue(value),
                    name: `temp-${index + 1}`
                };
                return point;
            });
            return {
                data: _.compact(temperatureSeriesData),
                name: this.$translate.instant('budgets.Temperature'),
                type: 'line',
                color: seriesColors.weather,
                dashStyle: 'ShortDash',
                states: {
                    hover: {
                        enabled: true
                    }
                },
                yAxis: 1
            };
        }

        private getTemperatureForecastSeries(temperature: Temperature) {
            const temperatureForecastSeriesData = _.map(temperature.values, (value, index) => {
                const temp = _.isNil(value) ? temperature.climateNormals[index] : value;
                if (!this.isCurrentMonth || this.isCurrentMonth && index < this.today - 1) {
                    return null;
                }
                const point = {
                    x: index + 1,
                    y: this.getTemperatureValue(temp),
                    name: `temp-forecast-${index + 1}`
                };
                return point;
            });
            return {
                data: _.compact(temperatureForecastSeriesData),
                name: this.$translate.instant('budgets.Temperature Forecast'),
                type: 'line',
                color: seriesColors.weather,
                dashStyle: 'ShortDot',
                states: {
                    hover: {
                        enabled: true
                    }
                },
                yAxis: 1
            };
        }

        private getTemperatureValue(value) {
            if (value == null) {
                return null;
            }
            return Math.round(this.$filter<Function>('toTemperatureUnit')(value, this.temperatureUnit));
        }

        private getActivities(startDate: moment.Moment, endDate: moment.Moment): aq.models.activity.SimpleActivityResponse[] {
            const activities = this.activities.payload
            .filter((activity) => activity.context && activity.context.data && activity.context.data.selection && activity.context.data.selection.startDate)
            .filter((activity) => moment(activity.context.data.selection.startDate).isSame(startDate, 'day'));
            return activities;
        }
    }
    angular.module('aq.utilityBudgets').controller('MonthBudgetCtrl', MonthBudgetCtrl);
}
