namespace aq.reports.alert {
    export class AlertReportCtrl extends BaseDocraptorReportCtrl {
        public data: BuildingsReportItem[];
        public groupData: BuildingGroupReportItem[];
        public summaryGroupData: DataSummary;
        public reportDateView: string;
        public reportName: string;
        public personas;
        private readonly pieChartOptions = {
            width: 380,
            legend: {
                enabled: true,
                align: 'right',
                verticalAlign: 'middle',
                layout: 'vertical'
            }
        };
        private readonly alertTimeStampFormat = 'MMM Do, H:mm a';
        constructor(
            private date: number,
            private schedulingInterval: string,
            private startDate: number,
            private account,
            private buildings: aq.common.models.Building[],
            private buildingGroups,
            private alerts: AlertDataStats,
            private buildingPersonnels,
            private $filter: ng.IFilterService,
            protected $timeout: ng.ITimeoutService
        ) {
            super($timeout);
            this.personas = {
                'ASSET_MANAGER': 'Asset Manager',
                'PROPERTY_MANAGER': 'Property Manager',
                'BUILDING_ENGINEER': 'Building Engineer'
            };
            const formattedDate: string = moment(this.date).utc().format('YYYY-MM-DD');
            const reportDate = moment.tz(formattedDate, this.account.timeZoneId);
            const start = this.getStartDate(reportDate);
            this.reportDateView = `${start.format('M/D/YY')} - ${reportDate.format('M/D/YY')}`;
            this.reportName = `Weekly Summary (${this.reportDateView})`;

            this.initReportItems();
            this.initBuildingGroupAndAccountSummary();

            this.notifyDocumentReady();
        }
        public initReportItems() {
            this.data = [];
            this.groupData = [];
            _.each(this.buildings, (building: aq.common.models.Building) => {
                const buildingAlertItem = _.find(this.alerts.byBuilding, (item) => item.buildingId == building.id);
                const buildingReportItem: BuildingsReportItem = {
                    building: this.getBuildingViewItem(building),
                    alertItems: this.getBuildingAlertViewItems(buildingAlertItem),
                    summary: this.getBuildingAlertStatSummary(buildingAlertItem)
                };
                this.data.push(buildingReportItem);
                const buildingGroupItem = this.getOrCreateBuildingGroupItem(building);
                buildingGroupItem.groupBuildings.push(buildingReportItem);
            });
        }
        public initBuildingGroupAndAccountSummary() {
            let totalOpenTime = 0;
            let totalCount = 0;
            _.each(this.groupData, (group: BuildingGroupReportItem) => {
                let totalGroupOpenTime = 0;
                let totalGroupCount = 0;
                let aggData0;
                let aggData1;
                _.each(group.groupBuildings, (item: BuildingsReportItem) => {
                    totalGroupCount += item.summary.closed;
                    totalGroupOpenTime += item.summary.avgCloseTimeValue * item.summary.closed;
                    const byDayData = item.summary.byDayData;
                    if (byDayData) {
                        if (!aggData0) {
                            aggData0 = angular.copy(byDayData.series[0].data);
                            aggData1 = angular.copy(byDayData.series[1].data);
                        } else {
                            _.each(aggData0, (item, index) => {
                                aggData0[index] += byDayData.series[0].data[index];
                                aggData1[index] += byDayData.series[1].data[index];
                            });
                        }
                    }
                });
                group.summary.total = _.sumBy(group.groupBuildings, (item: BuildingsReportItem) => item.summary.total);
                group.summary.open = _.sumBy(group.groupBuildings, (item: BuildingsReportItem) => item.summary.open);
                group.summary.closed = _.sumBy(group.groupBuildings, (item: BuildingsReportItem) => item.summary.closed);
                group.summary.escalated = _.sumBy(group.groupBuildings, (item: BuildingsReportItem) => item.summary.escalated);
                const avg = totalGroupCount ? Math.round(totalGroupOpenTime / totalGroupCount) : 0;
                group.summary.avgCloseTimeValue = avg;
                group.summary.avgCloseTime = this.getTime(avg);
                totalOpenTime += totalGroupOpenTime;
                totalCount += totalGroupCount;
                const groupByDayData = angular.copy(_.first(group.groupBuildings).summary.byDayData);
                if (groupByDayData) {
                    groupByDayData.series[0].data = aggData0;
                    groupByDayData.series[1].data = aggData1;
                }
                group.summary.byDayData = groupByDayData;
            });
            this.summaryGroupData = this.getEmptyDataSummary();
            this.summaryGroupData.total = _.sumBy(this.groupData, (item: BuildingGroupReportItem) => item.summary.total);
            this.summaryGroupData.open = _.sumBy(this.groupData, (item: BuildingGroupReportItem) => item.summary.open);
            this.summaryGroupData.closed = _.sumBy(this.groupData, (item: BuildingGroupReportItem) => item.summary.closed);
            this.summaryGroupData.escalated = _.sumBy(this.groupData, (item: BuildingGroupReportItem) => item.summary.escalated);
            const avgTime = totalCount ? Math.round(totalOpenTime / totalCount) : 0;
            this.summaryGroupData.avgCloseTimeValue = avgTime;
            this.summaryGroupData.avgCloseTime = this.getTime(avgTime);
        }
        public getOrCreateBuildingGroupItem(building) {
            let buildingGroupItem: BuildingGroupReportItem = _.find(this.groupData,
                (group) => building.buildingGroup != null && group.groupId == building.buildingGroup
                    || building.buildingGroup == null && group.groupId == 0
            );
            if (!buildingGroupItem) {
                const buildingGroup = _.find(this.buildingGroups, (bg) => bg.id == building.buildingGroup);
                buildingGroupItem = {
                    groupId: buildingGroup ? buildingGroup.id : 0,
                    groupName: buildingGroup ? buildingGroup.name : 'Other',
                    groupBuildings: [],
                    summary: this.getEmptyDataSummary()
                };
                if (buildingGroup && buildingGroup.isIncomplete) {
                    buildingGroupItem.summary.isIncomplete = true;
                }
                this.groupData.push(buildingGroupItem);
            }
            return buildingGroupItem;
        }
        public getTime(totalSeconds: number) {
            return this.$filter<Function>('humanizeDurationDetailed')(totalSeconds);
        }
        public getBuildingViewItem(building: aq.common.models.Building) {
            const buildingState = building.state ? building.state.replace('District of Columbia', '') : '';
            const buildingPersonnel = this.getBuildingPersonnel(building);
            return {
                id: building.id as any,
                name: building.name,
                imageUrl: building.imageUrl,
                streetAddress: building.address,
                cityAddress: `${building.city}, ${buildingState} ${building.zipCode}`,
                personnel: buildingPersonnel
            };
        }
        public getBuildingAlertViewItems(buildingAlertItem) {
            if (!buildingAlertItem) {
                return [];
            }
            const buildingStats: StatsItem = buildingAlertItem.stats;
            const viewItems = _.map(buildingStats.alertIssues, (item) => {
                const viewItem: AlertDataItem = {
                    name: item.alertName,
                    timeOpened: item.openedOn,
                    timeClosed: item.closedOn,
                    timeOpenedView: moment(item.openedOn).format(this.alertTimeStampFormat),
                    timeClosedView: item.closedOn ? moment(item.closedOn).format(this.alertTimeStampFormat) : null,
                    escalations: item.escalations,
                    violations: item.violations
                };
                return viewItem;
            });
            return viewItems;
        }
        public getBuildingAlertStatSummary(buildingAlertItem) {
            if (!buildingAlertItem || !buildingAlertItem.stats) {
                return this.getEmptyDataSummary();
            }
            const stats: StatsItem = buildingAlertItem.stats;
            const avgCloseTimeSeconds = this.getAvgCloseTime(stats);
            const summary: ItemDataSummary = {
                total: stats.issuesTriggered ? stats.issuesTriggered : 0,
                open: stats.openIssues ? stats.openIssues : 0,
                closed: stats.closedIssues ? stats.closedIssues : 0,
                escalated: stats.escalations ? stats.escalations : 0,
                isIncomplete: false,
                byAssigneeData: this.getByAssigneeData(stats),
                byDayData: this.getByDayData(stats),
                avgCloseTimeValue: avgCloseTimeSeconds,
                avgCloseTime: this.getTime(avgCloseTimeSeconds)
            };
            return summary;
        }
        public getAvgCloseTime(stats: StatsItem) {
            const openTime = _.sumBy(stats.alertIssues, (issue: AlertIssue) => {
                if (!issue.closedOn) {
                    return 0;
                }
                const endTime = moment(issue.closedOn);
                const diff = endTime.diff(issue.openedOn);
                const duration = moment.duration(diff);
                return duration.asSeconds();
            });
            const count = _.filter(stats.alertIssues, (issue: AlertIssue) => issue.closedOn).length;
            const avg = count ? Math.round(openTime / count) : 0;
            return avg;
        }
        public getByAssigneeData(buildingStats: StatsItem) {
            const data = _.map(buildingStats.issuesByAssignee, (item) => {
                return {
                    label: item.user ? item.user.fullName : 'none',
                    data: item.numberOfIssues
                };
            });
            return { data, options: this.pieChartOptions };
        }
        public getByDayData(buildingStats: StatsItem) {
            const endDate = moment(this.date).utc().startOf('day').valueOf();
            const startDate = this.getStartDate().valueOf();
            const seriesData = [];
            const seriesDataClosed = [];
            const currentDate = moment(startDate);

            const categories = [];
            while (currentDate.isBefore(endDate)) {
                const currentDateValue = _.find(buildingStats.issuesByDay, (item) => item.date == currentDate.format('YYYY-MM-DD'));
                const value = currentDateValue ? currentDateValue.numberOfIssues : 0;
                seriesData.push(value);
                categories.push(currentDate.format('MMM D'));
                const closedIssues = _.filter(buildingStats.alertIssues, (item) => {
                    return moment(item.closedOn).isSame(currentDate, 'day');
                });
                currentDate.add(1, 'day');
                seriesDataClosed.push(closedIssues.length);
            }
            const series = [{
                name: 'Closed',
                data: seriesDataClosed,
                color: '#7ACD46'
            }, {
                name: 'Open',
                data: seriesData,
                color: '#E70F28'
            }];
            return this.getByDayChartConfigData(categories, series);
        }
        public getByDayChartConfigData(categories, series) {
            return {
                chart: {
                    width: 380,
                    height: 160,
                    type: 'column'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal'
                    }
                },
                xAxis: {
                    categories
                },
                yAxis: {
                    title: { text: '' }
                },
                title: { text: '' },
                legend: { enabled: true },
                series,
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                }
            };
        }
        public getBuildingPersonnel(building: aq.common.models.Building) {
            const data = _.find(this.buildingPersonnels, { buildingId: building.id });
            if (data && data.personnel && data.personnel.length > 0) {
                _.each(data.personnel, (item) => {
                    if (!item.email) {
                        return;
                    }
                    const emailSplit = item.email.split('@');
                    if (emailSplit.length != 2) {
                        return;
                    }
                    item.emailUsername = emailSplit[0];
                    item.emailDomain = '@' + emailSplit[1];
                });
                const sortedPersonnel = [];
                _.each(this.personas, (persona, key) => {
                    const nextPerson = _.find(data.personnel, (item) => item.persona == key);
                    if (nextPerson) {
                        sortedPersonnel.push(nextPerson);
                    } else {
                        sortedPersonnel.push({
                            persona: key,
                            name: '-'
                        });
                    }
                });
                return sortedPersonnel;
            } else {
                return [];
            }
        }
        private getStartDate(fromDate?: moment.Moment) {
            if (this.startDate) {
                const localDate = moment
                    .unix(this.startDate)
                    .utc()
                    .format('YYYY-MM-DD');
                return moment.tz(localDate, this.account.timeZoneId);
            } else {
                const epoch = fromDate
                    ? fromDate.valueOf()
                    : this.date;
                const localDate = moment(epoch).utc().format('YYYY-MM-DD');
                return moment.tz(localDate, this.account.timeZoneId).subtract(1, this.schedulingInterval);
            }
        }
        private getEmptyDataSummary() {
            const item: ItemDataSummary = {
                isIncomplete: false,
                total: 0,
                open: 0,
                closed: 0,
                escalated: 0,
                avgCloseTimeValue: null,
                avgCloseTime: '',
                byAssigneeData: null,
                byDayData: null
            };
            return item;
        }
    }
    angular
        .module('aq.reports')
        .controller('AlertReportCtrl', AlertReportCtrl);
}
