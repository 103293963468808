angular.module('charting').factory('BaseService', ['Restangular', '$q', function(Restangular, $q) {
    var service: any = {};
    
    service.getCurrentRestangularObject = function(currentObj) {
        var current = Restangular;
        _.each(currentObj, function(parent) {
           current = current.one(parent.route, parent.id); 
        });
        return current; 
    }
    
    service.toCsv = function(collection, field) {
        return _.chain(collection)
            .map(function(elem) {return (field ? elem[field] : elem)})
            .uniq()
            .reduce(function(acum, elem) {
                if (acum.length > 0) {
                    acum += ',';
                }
                acum += elem;
                return acum;
            }, '').value();
    }
    
    service.fromCsv = function(text, number) {
        var list = text.split(',');
        if (number) {
            list = _.map(list, function(one) {
                return parseInt(one, 10);
            });
        }
        return list;
    }
    
    service.getCurrentAccount = function(currentObj) {
        return _.first(currentObj);
    }

    var intervalTextToDuration: any = {};
    intervalTextToDuration["1min"] = moment.duration(1, 'minutes');
    intervalTextToDuration["15min"] = moment.duration(15, 'minutes');
    intervalTextToDuration["1h"] = moment.duration(1, 'hours');
    intervalTextToDuration["1d"]= moment.duration(1, 'days');
    intervalTextToDuration["1mon"] = moment.duration(1, 'months');
    service.intervalToDuration = function(interval) {
        return intervalTextToDuration[interval];
    }

    service.toShortIntervalApiFormat = function(aggregation, onlyDateShorthand) {
        if (!aggregation) return null;
        if (aggregation.years()) {
            return onlyDateShorthand ? 'y' : aggregation.years() + 'y';
        }
        if (aggregation.months()) {
            return onlyDateShorthand ? 'mon' : aggregation.months() + 'mon';
        }
        if (aggregation.weeks()) {
            return onlyDateShorthand ? 'w' : aggregation.weeks() + 'w';
        }
        if (aggregation.days()) {
            return onlyDateShorthand ? 'd' : aggregation.days() + 'd';
        }
        if (aggregation.hours()) {
            return onlyDateShorthand ? 'h' : aggregation.hours() + 'h';
        }
        if (aggregation.minutes()) {
            return onlyDateShorthand ? 'min' : aggregation.minutes() + 'min';
        }
    }

    service.fromPeriodToReportPeriodEnum = function(aggregation) {
        return service.toShortIntervalApiFormat(aggregation, true).substr(0, 1).toUpperCase();
    }

    return service;
}]);
