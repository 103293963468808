'use strict';

class ReportUtilsCtrl {
    public selectedAccount: aq.common.models.Account;
    public filterTerm: string;
    /* @ngInject */
    constructor(
        private accounts: aq.common.models.Account[],
        private RestangularV3: restangular.IService,
        private Messages: aq.services.Messages,
        private $mdDialog: ng.material.IDialogService

    ) {
        this.filterTerm = '';
        this.selectedAccount = null;
    }

    public clearFilter() {
        this.filterTerm = '';
    }

    public confirmUpdateScheduledReports(ev) {
        const accountName = this.selectedAccount ? this.selectedAccount.name : 'all accounts';
        const confirm = this.$mdDialog.confirm()
            .title(`Are you sure you want to update scheduled reports for ${accountName}?`)
            .ariaLabel('Update Scheduled Reports')
            .targetEvent(ev)
            .ok('Ok')
            .cancel('Cancel');
        this.$mdDialog.show(confirm).then(() => {
            this.updateScheduledReports();
        });
    }

    public updateScheduledReports() {
        const accountId = this.selectedAccount ? this.selectedAccount.id : undefined;
        this.RestangularV3.all('internal')
            .one('update-scheduled-reports', accountId)
            .customPOST({})
            .then(() => {
                this.Messages.success('Scheduled reports are updating. This can take up to several minutes');
            })
            .catch(() => {
                this.Messages.error('Error while trying to update scheduled reports');
            });
    }
}

angular.module('aq.admin.reports.reportutils')
    .controller('ReportUtilsCtrl', ReportUtilsCtrl);
