namespace aq.deviceManagement {
    export class ViewAuditLogsCtrl extends aq.common.Controllers.ModalCtrl {
        
        private headerValue: any[];
        tenantLabel: string;
        /* @ngInject */
        constructor(
            private Auth: aq.services.Auth,
            protected $mdDialog: ng.material.IDialogService,
            private device: aq.common.models.DeviceElement,
            private revisions: any[]
        ) {
            super({}, $mdDialog);

            this.headerValue = [];
            if (revisions) {
                for(var key in revisions[0].fields) {
                   this.headerValue.push(key)
                }
            }
            this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'units' : 'tenants';
        }

        public getTenants(tenants: any[]): string {
            var tenantString = '';
            if(tenants) {
                for(var i = 0; i < tenants.length; i++) {
                    tenantString = tenantString === '' ? tenants[i].tenant.name : tenantString + ', ' + tenants[i].tenant.name;
                }
            }
            return tenantString;
        }

        public getExtraFieldsValue(device: any, hv: string): string {
            if (device.fields) {
                const fields = device.fields[hv];
                return fields ? fields.value : null;;
            }
            return null;
        }

        public mapHeaderKeyToString(hv: string): string {
            //TODO get this information from device class
            switch(hv) {
                case 'deviceIdentifier':
                    return '(Device ID|Modbus ID)';
                case 'deviceTemplateId':
                    return 'Device Template ID';
                case 'multiplier':
                    return 'Multiplier';
                case 'readingMultiplier':
                    return 'Reading Multiplier';
                case 'forceUpdate':
                    return 'Force Update';
                case 'currentTransformerSize':
                    return "Curr Transformer Size";
                case 'modbusSerialPortBaudRate':
                    return 'Modbus Serial Port BR';
                case 'modbusLocalAddress':
                    return 'Modbus Local';
                case 'pulseAvailable':
                    return 'Pulse Available';
                case 'powerAvailable':
                    return 'Power Available';
                case 'pin1PulseRate':
                    return 'Pin1 Pulse Rate';
                case 'pin2PulseRate':
                    return 'Pin2 Pulse Rate';
                case 'pin3PulseRate':
                    return 'Pin3 Pulse Rate';
                case 'pin4PulseRate':
                    return 'Pin4 Pulse Rate';
                case 'quickResponseMetaData':
                    return 'QR Scan Meta Data'
                default:
                    return hv;
            }
        }

        public cancel(data?): void {
            this.$mdDialog.cancel(data);
        }

        public hide(data?): void {
            this.$mdDialog.hide(data);
        }

        public checkUserName(userFullName: string, userId: number) : string {
            var userFullNameToReturn = userFullName && userFullName;
            if(!userFullNameToReturn) {
                userFullNameToReturn = userId ? `Removed User (${userId})`: 'System';
            }
            return userFullNameToReturn;
        }

        public checkIfChanged(currentString: string, pastString: string, index: number): string {
            currentString = currentString === null || currentString === '' ? undefined : currentString;
            pastString = pastString === null || pastString === '' ? undefined : pastString;

            if (currentString === pastString) {
                return '';
            }
            else if ((currentString === undefined) && (pastString !== undefined)) {
                return 'deleted';
            }
            else if ((currentString !== undefined) && (pastString === undefined)) {
                return 'created';
            }
            else if (currentString !== pastString) {
                return 'modified';
            }
            return ''
        }
    }

    angular
        .module('deviceManagement')
        .controller('ViewAuditLogsCtrl', ViewAuditLogsCtrl);
}

