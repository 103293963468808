var aq;
(function (aq) {
    var ui;
    (function (ui) {
        var AidActions = aq.models.segment.AidActions;
        var _IssueResolveAndClose = /** @class */ (function () {
            /* @ngInject */
            function _IssueResolveAndClose(RestangularV3, UserService, ActivityService, resolver, Segment) {
                this.RestangularV3 = RestangularV3;
                this.UserService = UserService;
                this.ActivityService = ActivityService;
                this.resolver = resolver;
                this.Segment = Segment;
            }
            _IssueResolveAndClose.prototype.cancelResolveAndClose = function () {
                this.hideResolveAndClose();
            };
            _IssueResolveAndClose.prototype.resolveAndClose = function () {
                var _this = this;
                this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                    var createMessagePromise, closeIssuePromise;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.Segment.trackEvent(AidActions.RESOLVE_AND_CLOSE_ISSUE, {
                                    account_id: this.UserService.currentUser.currentProfile.account.id,
                                    user_id: this.UserService.currentUser.id
                                });
                                this.isBeingSaved = true;
                                createMessagePromise = this.addResolutionMessageToActivity(this.activity, this.resolutionMessage, this.UserService.currentUser.id);
                                closeIssuePromise = this.closeIssue(this.issue);
                                return [4 /*yield*/, Promise.all([createMessagePromise, closeIssuePromise])];
                            case 1:
                                _a.sent();
                                this.refreshActivitiesAndCloseDialog();
                                return [2 /*return*/];
                        }
                    });
                }); });
            };
            _IssueResolveAndClose.prototype.addResolutionMessageToActivity = function (parent, body, author) {
                var request = { body: body, author: author };
                return this.ActivityService.createMessage(request, parent);
            };
            _IssueResolveAndClose.prototype.closeIssue = function (issue) {
                return this.RestangularV3.one('issues', issue).all('close').customPOST();
            };
            return _IssueResolveAndClose;
        }());
        ui.IssueResolveAndClose = {
            controller: _IssueResolveAndClose,
            controllerAs: 'vm',
            templateUrl: 'app/common/components/activities/contexts/issueResolveAndClose.html',
            bindings: {
                activity: '<',
                issue: '<',
                refreshActivitiesAndCloseDialog: '&',
                hideResolveAndClose: '&'
            }
        };
        angular.module('aq.ui')
            .component('issueResolveAndClose', ui.IssueResolveAndClose);
    })(ui = aq.ui || (aq.ui = {}));
})(aq || (aq = {}));
