namespace aq.settings {
    export class SettingsScheduleCategoryCtrl {
        private isInitialized: boolean;
        private isCreateNew: boolean;
        private categories: any[];
        private accountSettingsAccess: common.models.AppAccessObject;
        constructor(
            private $scope,
            private $mdDialog: ng.material.IDialogService,
            private authAccess: common.models.AuthAppAccess
        ) {
            this.accountSettingsAccess = authAccess['Account Settings'];
            this.categories = [];
            this.$scope.$on('CREATE_NEW', () => {
                this.isCreateNew = true;
                if (this.isInitialized) {
                    this.isCreateNew = false;
                    this.create();
                }
            });
            this.loadCategories().then(() => {
                if (this.isCreateNew) {
                    this.isCreateNew = false;
                    this.create();
                }
            });
        }
        public loadCategories(): ng.IPromise<void> {
            return this.$scope.account
                .all('RuleCategories')
                .getList()
                .then((categories) => {
                    this.categories = _.sortBy(categories, 'name');
                    this.isInitialized = true;
                });
        }
        public create() {
            this.$mdDialog.show({
                controller: 'SettingsScheduleCategoriesCRUDCtrl as vm',
                templateUrl: 'app/settings/platform/scheduleCategories/crud/crud.html',
                clickOutsideToClose: true,
                locals: {
                    object: { name: '', parent: null },
                    account: this.$scope.account,
                    accountSettingsAccess: this.accountSettingsAccess
                }
            }).then((createdCategory) => {
                this.categories.push(createdCategory);
                this.categories = _.sortBy(this.categories, 'name');
            });
        }
        public edit(category) {
            this.$mdDialog.show({
                controller: 'SettingsScheduleCategoriesCRUDCtrl as vm',
                templateUrl: 'app/settings/platform/scheduleCategories/crud/crud.html',
                clickOutsideToClose: true,
                locals: {
                    object: category,
                    account: this.$scope.account,
                    accountSettingsAccess: this.accountSettingsAccess
                }
            }).then((updatedCategory) => {
                if (updatedCategory) {
                    _.replaceItemById(this.categories, updatedCategory);
                } else {
                    _.remove(this.categories, (m: any) => { return m.id == category.id; });
                }
            });
            this.categories = _.sortBy(this.categories, 'name');
        };
    }
    angular.module('aq.settings').controller('SettingsScheduleCategoryCtrl', SettingsScheduleCategoryCtrl);
}
