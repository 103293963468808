namespace aq.utilityBudgets {
    export class AccrualCtrl {
        /* ngInject */
        constructor(
            private buildings: aq.common.models.Building[],
            private buildingGroups,
            private measures: aq.common.models.Measure[],
            private $mdDialog: angular.material.IDialogService,
            private measureUnitMap: { [measure: string]: string },
            private currencyUnitSymbol: string
        ) {
        }
        public startAccrualWizard(event: MouseEvent) {
            this.$mdDialog.show({
                controller: 'AccrualWizardCtrl as vm',
                templateUrl: 'app/utilityBudgets/accrual/wizard/accrualWizard.html',
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                targetEvent: event,
                locals: {
                    buildings: this.buildings,
                    buildingGroups: this.buildingGroups,
                    measures: this.measures,
                    measureUnitMap: this.measureUnitMap,
                    currencyUnitSymbol: this.currencyUnitSymbol
                },
                multiple: true
            } as any);
        }
    }
    angular.module('aq.utilityBudgets').controller('AccrualCtrl', AccrualCtrl);
}
