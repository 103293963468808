namespace aq.ui {
    export class AqPaginationController {
        public total: number;
        public current: number;
        public size: number;
        public maxPageNumber: number;
        public fixedPageSize: number;
        public onPagingChange: (arg: { data: { index: number, size: number } }) => void;
        public sizes: number[];
        public pages: number[];
        public pagesFull: number[];
        constructor() {
            this.sizes = [5, 10, 20, 50, 100];
        }
        public $onInit() {
            if (!this.size) {
                this.size = 20;
            }
            if (!this.current) {
                this.current = 1;
            }
            if (this.fixedPageSize != null) {
                this.sizes = [this.fixedPageSize];
            }
            this.buildPages();
        }
        public $onChanges(changesObj) {
            let isRebuild = false;
            if (changesObj.size) {
                this.size = changesObj.size.currentValue;
                isRebuild = true;
            }
            if (changesObj.current) {
                this.current = changesObj.current.currentValue;
            }
            if (changesObj.total) {
                this.total = changesObj.total.currentValue;
                isRebuild = true;
            }
            if (isRebuild) {
                this.buildPages();
            }
        }
        public onPageIndexChange() {
            this.buildPages();
            this.onPagingChange({ data: { index: this.current, size: this.size } });
        }
        public onPageSizeChange() {
            this.current = 1;
            this.buildPages();
            this.onPagingChange({ data: { index: this.current, size: this.size } });
        }
        public onPrevious() {
            if (this.current > 1) {
                this.current--;
                this.onPageIndexChange();
            }
        }
        public onNext() {
            if (this.current < this.maxPageNumber) {
                this.current++;
                this.onPageIndexChange();
            }
        }
        public onFirst() {
            if (this.current > 1) {
                this.current = 1;
                this.onPageIndexChange();
            }
        }
        public onLast() {
            if (this.current < this.maxPageNumber) {
                this.current = this.maxPageNumber;
                this.onPageIndexChange();
            }
        }
        public goTo(page) {
            this.current = page;
            this.onPageIndexChange();
        }
        public buildPages() {
            this.pages = [];
            this.maxPageNumber = this.getMaxPageNumber();
            this.pagesFull = [];
            for (let i = 1; i <= this.maxPageNumber; i++) {
                this.pagesFull.push(i);
            }
            if (this.maxPageNumber < 2) {
                return;
            }
            const minPages = (this.maxPageNumber - this.current < 3) ? this.maxPageNumber - 6 : this.current - 3;
            let i = Math.max(1, minPages);
            for (; i <= this.current; i++) {
                this.pages.push(i);
            }
            while (this.pages.length < 7 && i <= this.maxPageNumber) {
                this.pages.push(i++);
            }
        }
        public getMaxPageNumber() {
            return (this.total % this.size == 0) ? Math.round(this.total / this.size) : Math.ceil(this.total / this.size);
        }
    }
    angular.module('aq.ui').component('aqPagination', {
        controller: AqPaginationController,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/aqPagination/aqPagination.html',
        bindings: {
            total: '<',
            size: '<?',
            fixedPageSize: '<?',
            current: '<?',
            onPagingChange: '&',
            isDisabled: '<?'
        }
    });
}
