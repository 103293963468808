var aq;
(function (aq) {
    var utilityBudgets;
    (function (utilityBudgets) {
        var Step;
        (function (Step) {
            Step[Step["UPLOAD"] = 0] = "UPLOAD";
            Step[Step["RESULTS_WITH_ERRORS"] = 1] = "RESULTS_WITH_ERRORS";
            Step[Step["RESULTS"] = 2] = "RESULTS";
        })(Step || (Step = {}));
        var MassBillUpload = /** @class */ (function (_super) {
            __extends(MassBillUpload, _super);
            /* ngInject */
            function MassBillUpload($mdDialog, $mdStepper, RestangularV3, utilityAccountId, Segment) {
                var _this = _super.call(this, {}, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.$mdStepper = $mdStepper;
                _this.RestangularV3 = RestangularV3;
                _this.utilityAccountId = utilityAccountId;
                _this.Segment = Segment;
                _this.init();
                return _this;
            }
            MassBillUpload.prototype.init = function () {
                this.currentStep = Step.UPLOAD;
                this.errors = [];
                this.fileToUpload = null;
                this.fileAttached = false;
            };
            MassBillUpload.prototype.onFileChange = function () {
                if (this.fileToUpload) {
                    this.fileAttached = true;
                }
            };
            MassBillUpload.prototype.uploadFile = function () {
                var _this = this;
                this.isLoading = true;
                var fd = new FormData();
                fd.append('bulkUploadFile', this.fileToUpload);
                var params = { accountId: this.utilityAccountId };
                var headers = { 'Content-Type': undefined };
                this.Segment.trackEvent('Utility Accounts:Bulk Bill Upload');
                return this.RestangularV3.all('utility-accounts')
                    .withHttpConfig({ transformRequest: angular.identity })
                    .customPOST(fd, 'bulk-upload', params, headers)
                    .catch(function () {
                    return {
                        errors: ['Error occurred while uploading utility bills, please contact your administrator']
                    };
                })
                    .finally(function () {
                    _this.isLoading = false;
                });
            };
            MassBillUpload.prototype.downloadTemplate = function () {
                var link = document.createElement('a');
                link.href = '/api/v3/utility-accounts/bulk-upload/template';
                link.target = '_blank';
                link.click();
            };
            MassBillUpload.prototype.next = function () {
                var _this = this;
                if (this.currentStep == Step.UPLOAD) {
                    this.uploadFile()
                        .then(function (response) {
                        if (!response.errors || response.errors.length == 0) {
                            _this.currentStep++;
                            _this.$mdStepper('bulk-upload-wizard').next();
                        }
                        else {
                            _this.errors = response.errors;
                        }
                    });
                }
                else if (this.currentStep == Step.RESULTS_WITH_ERRORS) {
                    this.hide();
                    return;
                }
                else if (this.currentStep == Step.RESULTS) {
                    this.hide();
                    return;
                }
                this.currentStep++;
                this.$mdStepper('bulk-upload-wizard').next();
            };
            MassBillUpload.prototype.previous = function () {
                this.init();
                this.$mdStepper('bulk-upload-wizard').goto(Step.UPLOAD);
            };
            MassBillUpload.prototype.isDisableNext = function () {
                switch (this.currentStep) {
                    case Step.UPLOAD:
                        return !this.fileAttached;
                    case Step.RESULTS_WITH_ERRORS:
                        return this.errors;
                    case Step.RESULTS:
                        return this.isLoading;
                    default:
                        return false;
                }
            };
            MassBillUpload.prototype.isShowBack = function () {
                return this.currentStep == Step.RESULTS_WITH_ERRORS && !this.isLoading;
            };
            MassBillUpload.prototype.getNextButtonTitle = function () {
                switch (this.currentStep) {
                    case Step.UPLOAD:
                        return 'Next';
                    case Step.RESULTS_WITH_ERRORS:
                        return this.isLoading ? 'Next' : 'Finish';
                    case Step.RESULTS:
                        return 'Finish';
                    default:
                        return 'Next';
                }
            };
            return MassBillUpload;
        }(aq.common.Controllers.ModalCtrl));
        utilityBudgets.MassBillUpload = MassBillUpload;
        angular.module('aq.utilityBudgets').controller('MassBillUpload', MassBillUpload);
    })(utilityBudgets = aq.utilityBudgets || (aq.utilityBudgets = {}));
})(aq || (aq = {}));
