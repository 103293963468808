/**
 * @param form - form that is evaluated, keeps the button disabled until the form is filled in correctly
 * @param isDisabled - additional conditions (besides the form itself) which keep the button disabled
 * @param call - function to call on click
 * @param text - text to display while button is idle
 * @param textDisabled - button text to display after click, while it is operating in the background
 * @param buttonClass - other classes to be applied to an element next to 'aq-button'; 'green' will be applied if undifined
 * @param confirmMessage - confirmation text in the confirmation popup; has to be present alongside confirmAction
 * @param confirmAction - function to call if confirmation popup ended with a positive reply
 */

angular.module('aq.ui').directive('submit', function() {
    return {
        restrict: 'E',
        template: '<button type="submit" class="aq-button {{ buttonClass || \'green\' }}" ng-click="click()" ng-disabled="form.$invalid || isDisabled">' +
                    '{{ buttonText | translate }}' +
                  '</button>',
        replace: true,
        scope: {
            form: '=',
            isDisabled: '=',
            call: '&',
            requireValidForm: '=',
            confirmAction: '&',
            buttonClass: '@'
        },
        require: "^form",
        link: function($scope, $elem, $attrs, formController: ng.IFormController) {
            var mainText = $attrs.text || 'Save';
            var savingText = $attrs.textDisabled || 'Saving';
            $scope.buttonText = mainText;

            $scope.click = function() {
                if(!formController.$valid && $scope.requireValidForm){
                    return;
                }

                if ($attrs.confirmMessage) {
                    bootbox.confirm({
                        message: $attrs.confirmMessage,
                        buttons: {
                            confirm: {
                                className: "aq-button red"
                            },
                            cancel: {
                                className: "aq-button"
                            }
                        },
                        callback: function(res) {
                            if (res) $scope.$eval($scope.confirmAction);
                        }
                    });
                } else {
                    $elem.attr('disabled', 'true');
                    $scope.buttonText = savingText;
                    $elem.attr('style', 'pointer-events: none;');

                    var func = $scope.$eval($scope.call);
                    if (!func) return;

                    func.finally(function() {
                        $elem.attr('disabled', 'false');
                        $scope.buttonText = mainText;
                        $elem.attr('style', 'pointer-events: auto;');
                    });
                }
            };
        }
    }
});
