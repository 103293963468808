angular
    .module('aq.reports')
    .directive('priorityIndicator', () => {
        return {
            restrict: 'E',
            replace: false,
            templateUrl: 'app/reports/summary/project/directives/priorityIndicator.html',
            scope: {
                priority: '='
            },
            link($scope, element, $attrs) {
                $scope.colors = ['#DDD', '#DDD', '#DDD'];
                $scope.$watch('priority', (priority: number) => {
                    switch (priority) {
                        case 1: // Low
                            $scope.colors = ['#DDD', '#DDD', '#FFD400'];
                            break;
                        case 2: // Medium
                            $scope.colors = ['#DDD', '#FF9D00', '#FF9D00'];
                            break;
                        case 3: // High
                            $scope.colors = ['#FF541F', '#FF541F', '#FF541F'];
                            break;
                        default:
                            $scope.colors = ['#DDD', '#DDD', '#DDD'];
                    }
                });
            }
        }
    });
