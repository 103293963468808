namespace aq.dashboard.widgets {
    export class StatsEditService extends aq.services.BuildingSelectorActions {
        /* ngInject */
        constructor(
            protected DashboardOptionsService: DashboardOptionsService,
            protected $translate
        ) {
            super(DashboardOptionsService, $translate);
        }
        public onBuildingIdsChange(cfg: StatsConfig, buildingIds: number[]) {
            super.onBuildingIdsChange(cfg, buildingIds);
            this.buildCalculationOptions(cfg);
            this.generateDefaultTitle(cfg);
        }
        public onSingleBuildingChange(cfg: StatsConfig, buildingId: number) {
            cfg.view.buildingId = buildingId;
            cfg.drilldown = null;
            if (cfg.view.buildingId == null) {
                cfg.options.drilldownOptions = [];
                this.onBuildingIdsChange(cfg, []);
                return;
            }
            cfg.options.drilldownOptions = this.getDrilldownOptions(cfg);
            this.onBuildingIdsChange(cfg, [cfg.view.buildingId]);
        }
        public onTimePeriodChange(cfg: StatsConfig) {
            if (cfg.comparison) {
                cfg.series[1].trendPeriod = TrendPeriods.getTrendPeriodForDatePeriod(cfg.preset);
            }
            this.generateDefaultTitle(cfg);
        }
        public onBreakdownChange(cfg: StatsConfig) {
            cfg.drilldown = null;
            cfg.buildingGroupId = null;
            if (cfg.breakdown == 'BUILDING') {
                cfg.buildingSelectionMode = 'individual';
                this.onBuildingIdsChange(cfg, []);
            } else {
                this.onSingleBuildingChange(cfg, null);
            }
        }
        public onQueryableChange(cfg: StatsConfig, data: any) {
            cfg.drilldown = data;
            this.generateDefaultTitle(cfg);
        }
        public onShowTitleChange(cfg: StatsConfig) {
            cfg.hideTitle = !cfg.view.showTitle;
            this.generateDefaultTitle(cfg);
        }
        public onIsCustomTitleChange(cfg: StatsConfig) {
            this.generateDefaultTitle(cfg);
        }
        public onMeasuresChange(cfg: StatsConfig) {
            cfg.unit = this.DashboardOptionsService.getUnitByEnumName(cfg.series[0].measure);
            this.generateDefaultTitle(cfg);
        }
        public onStatisticChange(cfg: StatsConfig) {
            this.generateDefaultTitle(cfg);
        }
        public getDrilldownOptions(cfg: StatsConfig) {
            const measure = cfg.series[0].measure;
            const breakdown = cfg.breakdown;
            const buildingId = cfg.view.buildingId;
            const breakdownOptions = cfg.options.series[breakdown][buildingId];
            const unit = this.DashboardOptionsService.getUnitByEnumName(measure);
            const drilldownOptions = _.filter(breakdownOptions, (drilldown: any) => {
                return _.includes(drilldown.metrics, unit.serviceType);
            });
            return drilldownOptions;
        }
        public buildCalculationOptions(cfg: StatsConfig) {
            cfg.options.calculations = this.getCalculationOptions(cfg);
            if (cfg.calculationValue && !_.find(cfg.options.calculations, (c) => c.value == cfg.calculationValue.value)) {
                cfg.calculationValue = _.last(cfg.options.calculations);
            }
        }
        private getCalculationOptions(cfg: StatsConfig) {
            if (cfg.buildingIds && cfg.buildingIds.length == 1) {
                const building = _.find(cfg.options.buildings, (b) => b.id == cfg.buildingIds[0]);
                if (building) {
                    const options = [];
                    if (building.size) {
                        options.push({
                            display: `${this.$translate.instant(
                                'dashboard.common.widgets.stats.Per Square Feet')}`, value: building.size
                        });
                    }
                    if (building.occupantsCount) {
                        options.push({
                            display: `${this.$translate.instant(
                                'dashboard.common.widgets.stats.Per Number of Occupants')}`, value: building.occupantsCount
                        });
                    }
                    options.push({ display: `${this.$translate.instant('dashboard.common.widgets.stats.No Calculation')}`, value: 0 });
                    return options;
                }
            }
            return [{ display: `${this.$translate.instant('dashboard.common.widgets.stats.No Calculation')}`, value: 0 }];
        }
        private getMeasureUnit(measure: string) {
            const unit = this.DashboardOptionsService.getUnitByEnumName(measure);
            if (unit) {
                return unit.unit;
            }
            return '';
        }
        private generateDefaultTitle(cfg: StatsConfig) {
            if (cfg.isCustomTitle) {
                return;
            }
            const interval = this.getIntervalDescription(cfg.preset);
            const statistic = cfg.statistic ? textural(cfg.statistic.display).format('capitalizehuman') : '';
            const unit = this.getMeasureUnit(cfg.series[0].measure);
            const timePeriod = this.$translate.instant('timeperiod_case3.' + textural(cfg.preset).format('capitalizehuman'));
            let title = `${this.$translate.instant('dashboard.common.widgets.stats.' + statistic)} ${unit} ${interval} - ${timePeriod}`;
            if (cfg.breakdown == 'building') {
                if (cfg.buildingSelectionMode == 'group') {
                    const group = _.find(cfg.options.buildingGroups, (bg) => bg.id == cfg.buildingGroupId);
                    if (group) {
                        title = `${group.name} - ${title}`;
                    }
                } else if (cfg.buildingIds && cfg.buildingIds.length == 1) {
                    const buildingId = cfg.buildingIds[0];
                    const building = _.find(cfg.options.buildings, (b) => b.id == buildingId);
                    if (building && building.name) {
                        title = `${building.name} - ${title}`;
                    }
                }
            }
            if (cfg.drilldown) {
                const queryableName = cfg.drilldown.name;
                if (queryableName) {
                    title = `${queryableName} - ${title}`;
                }
            }
            cfg.title = title;
        }
        private getIntervalDescription(preset: string) {
            if (!preset) {
                return '';
            }
            let intervalText = '';
            const periodDetails: DatePeriod.DatePeriod = TimePresets.getPresetDetails(preset);
            if (periodDetails) {
                switch (periodDetails.interval) {
                    case '1m':
                        intervalText = this.$translate.instant('dashboard.common.widgets.stats.1 minute');
                        break;
                    case '1h':
                        intervalText = this.$translate.instant('dashboard.common.widgets.stats.1 hour');
                        break;
                    case '1d':
                        intervalText = this.$translate.instant('dashboard.common.widgets.stats.1 day');
                        break;
                    case '1mon':
                        intervalText = this.$translate.instant('dashboard.common.widgets.stats.1 month');
                        break;
                    default:
                        return '';
                }
                if (intervalText) {
                    return `${this.$translate.instant('dashboard.common.widgets.tableedit.Over')} ${intervalText} ${this.$translate.instant('dashboard.common.widgets.tableedit.Intervals')}`;
                }
            }
            return '';
        }
    }
    angular.module('aq.dashboard.widgets').service('StatsEditService', StatsEditService);
}
