namespace aq.ui {

    import CategorizedActivities = aq.models.activity.CategorizedActivities;
    import ActivityContextType = aq.models.activity.ActivityContextType;
    import IComponent = aq.models.IComponent;

    interface IBindings {
        categorizedActivities: CategorizedActivities;
        listTitle: string;
    }

    interface IIcon {
        name: string;
        color: string;
    }

    enum ApplicableCategories {
        LOAD_ANALYTICS = 'Load Analytics',
        HVAC_ANALYTICS = 'HVAC Analytics',
        OPTIMIZATION_STRATEGIES = 'Optimization Strategies'
    }

    // Reading on component lifecycle hooks https://toddmotto.com/angular-1-5-lifecycle-hooks
    class _CollapsibleList implements IBindings, IComponent {
        public categorizedActivities: CategorizedActivities;
        public accountId: number;
        public buildingId: number;
        public listTitle: string;
        public isOpen: boolean;
        private icon: { color: string; name: string };
        private category: string;
        /* @ngInject */
        constructor(
            private PrescriptionService: aq.services.PrescriptionService
        ) { }

        $onInit(): void {
            const activity = this.categorizedActivities.getActivities()[0];
            if (activity && activity.context) {
                this.setIcon(activity.context);
            }
            this.isOpen = false;
            this.categorizedActivities = new CategorizedActivities();
        }

        $postLink(): void {}

        $onDestroy(): void {}

        $onChanges(changes): void {
            if (changes.listTitle) {
                this.listTitle = changes.listTitle.currentValue;
            }
            if (changes.categorizedActivities) {

                this.categorizedActivities = angular.copy(changes.categorizedActivities.currentValue);
                const activity = this.categorizedActivities.getActivities()[0];
                if (activity && activity.context) {
                    this.setIcon(activity.context);
                }
            }
        }

        public onClick(): void {
            this.isOpen = !this.isOpen;
        }

        public applicableCategory(): boolean {
            return Object.values(ApplicableCategories).includes(this.category)
                && !(this.category === ApplicableCategories.LOAD_ANALYTICS && this.listTitle === 'Holiday Run' );
        }

        private setIcon(context: aq.models.activity.IActivityContext): void {
            const { type } = context;
            const icon: IIcon = { name: 'description', color: 'blue' };
            switch (type) {
                case ActivityContextType.ALERT:
                    icon.name = 'error';
                    if (context.acknowledgedStatus && context.acknowledgedStatus === 'OPEN') {
                        icon.color = 'red';
                    }
                    break;
                case ActivityContextType.AID_ISSUE:
                    icon.name = 'help';
                    break;
                case ActivityContextType.ENERGY_NOTE:
                    icon.name = 'description';
                    break;
                case ActivityContextType.OPTIMAL_START:
                    icon.name = 'alarm';
                    break;
                case ActivityContextType.RX:
                    icon.name = 'healing';
                    if (context.contextObject
                        && this.PrescriptionService.isUrgent(context.contextObject)
                    ) {
                        icon.color = 'red';
                    }
                    break;
                default:
                    break;
            }
            this.icon = icon;
        }
    }

    export const CollapsibleList: ng.IComponentOptions = {
        controller: _CollapsibleList,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/activities/list/collapsible-list.html',
        bindings: {
            categorizedActivities: '<',
            listTitle: '<',
            accountId: '<',
            buildingId: '<',
            refreshActivities: '&',
            category: '<'
        }
    };

    angular.module('aq.ui').component('collapsibleList', CollapsibleList);
}
