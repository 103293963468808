/**
*
* This filter will return all available sources that can be assigned as child to circuit.
* It will take in consideration currently selected source, currently assigned source and
* parent relation to prevent circular reference
*
* @param sources All sources
* @param currentSource Source that is currently edited in details page
* @param currentlyAssigned Source that is currently assigned to circuit
* @return Returns filtered sources
*/
angular.module('properties.sources')
    .filter('availableSources', function($filter) {
        return function(sources, currentSource, currentlyAssigned) {

            // Traverse through all parents, if sourceIdToCheck is found then exclude this source
            // as he is parent from currentSource
            var hasParentalConnections = function(sourceOrId, sourceIdToCheck) {
                var source = sourceOrId;
                if (!(source instanceof Object)) {
                    source = _.findById(sources, sourceOrId);
                }
                if (source.id === sourceIdToCheck) return true;

                if (source.parentSource) {
                    return hasParentalConnections(source.parentSource, sourceIdToCheck);
                }
                return false;
            }

            return _.filter(sources, function(source) {
                var isNewSource = !currentSource.id;
                // only mapped source can be parent
                if (!source.mapped) return false;
                // can not have self as child
                var notCurrentlySelectedSource = currentSource ? currentSource.id != source.id : false;
                // we need to show currently connected child to circuit
                var isCurrentlyAssigned = currentlyAssigned ? currentlyAssigned.id == source.id : false;
                // we only can connect sources that don't have parents in case this is not new source
                var haveNoParent = !source.parentSource || isNewSource;
                // check for circular reference, we can't have parent as child
                var noParentalConnections = !hasParentalConnections(currentSource, source.id)
                // is same measure type
                var isSameMeasure = currentSource.sourceClass.measure === $filter('sourceClass')(source.sourceClass, 'measure');

                return isCurrentlyAssigned || (notCurrentlySelectedSource && haveNoParent && noParentalConnections && isSameMeasure);
            });
        }
    });
