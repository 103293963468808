'use strict';

angular.module('accounts').controller('BuildingPurposeCtrl', function($scope, Restangular, Errors, $aq) {

    $scope.animation = false;
    $scope.newPurposeName = '';

    $scope.account.all('BuildingPurposes').getList({inflate: 'purposes'}).then(function(buildingPurposes) {
        _.each(buildingPurposes, function (purposeParent) {
            purposeParent.purposes = _.sortBy(purposeParent.purposes, 'name');
            Restangular.restangularizeCollection($scope.account, purposeParent.purposes, 'BuildingPurposes');
        });

        $scope.purposes = _.sortBy(buildingPurposes, 'name');
    });

    $scope.createPurpose = function(parent) {
        $scope.animation = {enter: 'bounceInLeft', leave: 'bounceOutLeft'};
        $scope.working = true;
        const newPurpose = {
            name: $scope.newPurposeName,
            account: $scope.account.id,
            parent: void 0
        };
        if ($scope.subcategory) {
            newPurpose.parent = parent;
        }

        $scope.newPurposeName = '';

        return $scope.account.post('BuildingPurposes', newPurpose).then(function(response) {
            const purpose = _.extend(response, response.model);
            if ($scope.subcategory && parent) {
                if (!parent.purposes) {
                    parent.purposes = [];
                }
                parent.purposes.push(purpose);
                parent.purposes = _.sortBy(parent.purposes, 'name');
            } else {
                $scope.purposes.push(purpose);
                $scope.purposes = _.sortBy($scope.purposes, 'name');
            }
        }, Errors.forPromise()).finally(function() {
            $scope.newPurposeName = '';
            $scope.working = false;
        });
    };

    $scope.edit = function(purpose) {
        $scope.purposes = _.sortBy($scope.purposes, 'name');
        return purpose.put();
    };
});
