namespace aq.deviceManagement {

    export class DeviceDataUnits {
        private units: Map<string, DeviceDataUnit[]> = new Map<string, DeviceDataUnit[]>();
        private multiMetricUnits: Map<string, DeviceDataUnit[]> = new Map<string, DeviceDataUnit[]>();
        private collator: Intl.Collator;

        /* ngInject */
        constructor(private DataApiV1Units: DataApiV1Unit[]) {
            this.initializeUnits(this.DataApiV1Units);
            this.collator = new Intl.Collator(undefined, {numeric: true});
        }

        getDeviceUnits(device: aq.common.models.Device, protocol: string): DeviceDataUnit[] {
            switch (device.deviceCategory) {
                case 'METER': {
                    switch (protocol.toUpperCase()) {
                        case 'MODBUS': return this.getModbusMeterUnits(device.measure);
                        default: return this.getPulseMeterUnits(device.measure);
                    }
                }
                case 'NETWORKING': {
                    return this.getUnits('pulse');
                }
                case 'SENSOR': {
                    return [...this.getUnits('pulse'), ...this.getUnits('state'), ...this.getUnits('environment')];
                }
                default: {
                    return [];
                }
            }
        }

        getDeviceDataQueryables(device: aq.common.models.DeviceElement,
                                links: any[],
                                points: aq.common.models.Point[],
                                collector: aq.common.models.Collector): DeviceDataQueryable[] {

            const linkProtocol = this.findLinkProtocol(device, links, collector);
            const units = this.getDeviceUnits(device, linkProtocol);
            const deviceDataQueryables: DeviceDataQueryable[] = [];


            switch (device.deviceCategory) {
                case 'SENSOR':
                    deviceDataQueryables.push(...this.getEnvironmentPointDataQueryables(points, units),
                        ...this.getStateSensorQueryables(collector, points, units));
                    break;
                case 'NETWORKING':
                    deviceDataQueryables.push(...this.getPulsePointDataQueryables(points, units));
                    break;
                case 'METER':
                    for (const unit of units) {
                        deviceDataQueryables.push({unit, name: unit.series.name, queryable: collector });
                    }
                    break;
                case 'OTHER':
                    break;
                default: throw new Error('device category ' + device.deviceCategory + ' not recognized');
            }
            return deviceDataQueryables.sort((a, b) => this.collator.compare(a.name, b.name));
        }

        private getStateSensorQueryables(collector, points, units) {
            const queryables = [];
            if (points.filter(pointFilter => pointFilter.name.startsWith('X')).length > 0) {
                for (const unit of units.filter((unitFilter) => ['STATE', 'PULSE'].indexOf(unitFilter.apiUnit) > -1)) {
                    queryables.push({
                        unit,
                        name: unit.series.name,
                        queryable: collector
                    });
                }
            }
            return queryables;
        }

        private getPulsePointDataQueryables(points, units): DeviceDataQueryable[]  {
            const colors = ['#000000', '#28ccdd', '#fd7b33', '#7ace46', '#1e59af', '#f2a91d', '#aa3c9b', '#c5e063', '#e51730', '#ba7dd4'];
            const queryables = [];
            for (const point of points.filter((pointFilter) => pointFilter.name.startsWith('PL'))) {
                const pointMultiplier = points.indexOf(point) + 1;
                for (const unit of units.filter((unitFilter) => ['STATE', 'PULSE'].indexOf(unitFilter.apiUnit) > -1)) {
                    const thisUnit = angular.copy(unit);
                    const pointNumber = parseInt(point.name.substr(2));
                    thisUnit.series.name = 'Input ' + pointNumber + ' ' + thisUnit.series.name;
                    thisUnit.series.color = colors[(pointNumber * pointMultiplier) - 1 % 10];
                    queryables.push({
                        unit: thisUnit,
                        name: thisUnit.series.name,
                        queryable: point
                    });
                }
            }
            return queryables;
        }

        private getEnvironmentPointDataQueryables(points, units): DeviceDataQueryable[] {
            const queryables = [];
            for (const point of points.filter((pointFilter) => pointFilter.name.startsWith('ENV01'))) {
                for (const unit of units.filter((unitFilter) => ['K30_CO2', 'ENV_TEMPERATURE', 'HUMIDITY'].indexOf(unitFilter.apiUnit) > -1)) {
                    queryables.push({unit, name: unit.series.name, queryable: point });
                }
            }
            return queryables;
        }

        // Determine if is a MODBUS, PULSE, or OTHER connected device based on links
        private findLinkProtocol(device: aq.common.models.Device, links: any[], collector: aq.common.models.Collector): string {
            let linkProtocol: string = 'OTHER';
            if (links && links[0]) {
                if (device.deviceCategory === 'METER') {
                    linkProtocol = 'PULSE';
                    if (links[0]) {
                        if (links[0].protocol === 'MODBUS') {
                            linkProtocol = 'MODBUS';
                        }
                    }
                }
            } else {
                if (<CollectorType>collector.collectorClass === CollectorType.RAIL_350v_MODBUS) {
                    linkProtocol = 'MODBUS';
                } else if (<CollectorType>collector.collectorClass === CollectorType.AQUICORE_METER &&
                        device.deviceClass.measure.toLowerCase === 'electricity') {
                    linkProtocol = 'MODBUS';
                }
            }
            return linkProtocol;
        }

        private getUnits(unit: string): DeviceDataUnit[] {
            const units: DeviceDataUnit[] = this.units.get(unit);
            return units ? units : [];
        }

        private getMultiMetricUnits(unit: string): DeviceDataUnit[] {
            const units: DeviceDataUnit[] = this.multiMetricUnits.get(unit);
            return units ? units : [];
        }

        private getPulseMeterUnits(measure: aq.common.models.DeviceMeasure): DeviceDataUnit[] {
            return [...this.getUnits('pulse'), ...this.getUnits(measure.toLowerCase())];
        }

        private getModbusMeterUnits(measure: aq.common.models.DeviceMeasure) {
            return [...this.getUnits('pulse'), ...this.getUnits(measure.toLowerCase()), ...this.getMultiMetricUnits(measure.toLowerCase())];
        }

        private transformUnit(dataApiV1Unit: DataApiV1Unit): DeviceDataUnit {
            return {
                apiUnit: dataApiV1Unit.apiUnit,
                series: {
                    name: dataApiV1Unit.name,
                    color: dataApiV1Unit.color
                },
                scale: dataApiV1Unit.scale,
                yAxis: {
                    title: {
                        text: dataApiV1Unit.name
                    },
                    labels: {
                        style: {
                            color: dataApiV1Unit.color
                        },
                        format: dataApiV1Unit.label
                            ? '{value} ' + dataApiV1Unit.label
                            : '{value}'
                    }
                }
            };
        }

        private initializeUnits(dataApiV1Units: DataApiV1Unit[]) {
            dataApiV1Units.forEach((dataApiV1Unit) => {
                if (!dataApiV1Unit.multiMetric) {
                    this.addToUnitMap(this.units, dataApiV1Unit);
                } else {
                    this.addToUnitMap(this.multiMetricUnits, dataApiV1Unit);
                }
            });

        }

        private addToUnitMap(unitMap: Map<string, DeviceDataUnit[]>, dataApiV1Unit: DataApiV1Unit): void {
            let unitsByMeasure = unitMap.get(dataApiV1Unit.measure);
            if (unitsByMeasure === undefined) {
                unitsByMeasure = [];
            }
            const deviceDataUnit = this.transformUnit(dataApiV1Unit);
            unitsByMeasure.push(deviceDataUnit);
            unitMap.set(dataApiV1Unit.measure, unitsByMeasure);
        }
    }

    export interface DeviceDataUnit {
        apiUnit: string;
        scale?: number;
        series: {
            name: string;
            data?: any[];
            color?: string;
        };
        yAxis: any;
    }

    export interface DeviceDataQueryable {
        name?: string;
        queryable?: restangular.IElement;
        unit?: DeviceDataUnit;
    }
}

angular
    .module('deviceManagement')
    .factory('DeviceDataUnits', ['DataApiV1Units', (DataApiV1Units) => new aq.deviceManagement.DeviceDataUnits(DataApiV1Units)]);
