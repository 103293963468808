namespace aq.crud {

    export class CrudSearchCtrl {
        public includeSearch: boolean;
        public searchText: string;
        public placeholderText: string;
        public onSearchFn: Function;

        /* ngInject */
        constructor() {
            if (this.includeSearch === undefined) {
                this.includeSearch = true;
            }
        }

        public $onInit() {
            if (!this.placeholderText) {
                this.placeholderText = 'Search';
            }
        }

        resetSearch() {
            this.searchText = '';
            this.onSearchFn(this.searchText);
        }
    }

    angular
        .module('aq.ui')
        .component('aqCrudSearch', {
            templateUrl: 'app/common/directives/crud/search.html',
            controller: CrudSearchCtrl,
            transclude: {
                'filters': '?aqCrudSearchFilters'
            },
            bindings: {
                onSearchFn: '&',
                debounce: '<?',
                searchText: '<',
                entityLabel: '<',
                placeholderText: '@',
                includeSearch: '<?'
            }
        });

}
