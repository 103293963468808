'use strict';
var PROJECT_ESTIMATION = 'Project Estimation';
angular
    .module('aq.reports', [
    'aq-main',
    'restangular',
    'ui.router',
    'aq.ui.docraptor',
    'aq.services.options',
    'aq.filters.formatters',
    'aq.filters.units',
    'aq.filters.tools',
    'aq.filters.math',
    'aq.filters.time',
    'aq.services.data',
    'aq.ui.highChart',
    'energyInsights',
    'aq.ui.color'
])
    .config(function (RestangularProvider, $stateProvider) {
    $stateProvider
        .state('aq.reports', {
        url: '/reports',
        abstract: true,
        template: '<ui-view></ui-view>',
        data: {
            hideNavbar: true,
            appName: 'reports'
        },
        resolve: {
            accountId: function ($location) {
                return $location.search().accountId;
            },
            account: function (waitForAuthToken, RestangularV3, accountId) {
                if (accountId) {
                    return RestangularV3.one('accounts', accountId).get();
                }
                else {
                    return null;
                }
            },
            buildingId: function ($location) {
                return $location.search().buildingId;
            },
            building: function (waitForAuthToken, Restangular, buildingId, accountId) {
                if (buildingId && accountId) {
                    return Restangular
                        .one('accounts', accountId)
                        .one('buildings', buildingId)
                        .get({
                        single: true,
                        inflate: 'buildingPurpose,collectors,buildingOccupancies,targets,targets.measure,targets.targetItems'
                    });
                }
                else {
                    return null;
                }
            },
            date: function ($location) {
                var dateParam = $location.search().date;
                if (dateParam) {
                    return parseInt(dateParam);
                }
                return moment().utc().startOf('day').valueOf();
            }
        }
    }).state('aq.reports.BER', {
        url: '/summary/monthlyBuildingEngineer',
        controller: 'MonthlyBuildingEngineerMainCtrl',
        controllerAs: 'vm',
        templateUrl: 'app/reports/summary/monthlyBuildingEngineer/controllers/main.html',
        data: {
            hideNavbar: true
        },
        resolve: {
            availableMetrics: function (building, MonthlyBuildingEngineerData) {
                return MonthlyBuildingEngineerData.availableMetrics(building);
            },
            electricityData: function (building, MonthlyBuildingEngineerData, availableMetrics) {
                return MonthlyBuildingEngineerData.getElectricityData(building, availableMetrics);
            },
            waterData: function (building, MonthlyBuildingEngineerData, availableMetrics) {
                return MonthlyBuildingEngineerData.getWaterData(building, availableMetrics);
            },
            gasData: function (building, MonthlyBuildingEngineerData, availableMetrics) {
                return MonthlyBuildingEngineerData.getGasData(building, availableMetrics);
            },
            steamData: function (building, MonthlyBuildingEngineerData, availableMetrics) {
                return MonthlyBuildingEngineerData.getSteamData(building, availableMetrics);
            },
            degreeDaysData: function (building, MonthlyBuildingEngineerData) {
                return MonthlyBuildingEngineerData.getDegreeDayData(building);
            },
            reportTime: function ($location, building) {
                if ($location.search().date) {
                    var epoch = parseInt($location.search().date);
                    var localDate = moment(epoch).utc().format('YYYY-MM-DDTHH:mm');
                    return moment.tz(localDate, building.timeZoneId);
                }
                return moment();
            }
        }
    })
        .state('aq.reports.executive', {
        url: '/summary/executive',
        controller: 'executiveReportCtrl',
        controllerAs: 'vm',
        templateUrl: 'app/reports/summary/executive/executive.html',
        data: {
            hideNavbar: true
        },
        resolve: {
            metric: function (Restangular, account, waitForAuthToken) {
                return Restangular.one('accounts', account.id).customGET('queryRealUnits');
            },
            buildings: function (Restangular, account, waitForAuthToken) {
                return Restangular.one('accounts', account.id)
                    .all('buildings')
                    .getList({ inflate: 'buildingGroup' }).then(function (buildings) {
                    return buildings.filter(function (building) {
                        return building.status === 'ACTIVE';
                    });
                });
            }
        }
    })
        .state('aq.reports.alert', {
        url: '/summary/portfolio-alert',
        controller: 'AlertReportCtrl',
        controllerAs: 'vm',
        templateUrl: 'app/reports/summary/alert/alert.html',
        resolve: {
            buildings: function (waitForAuthToken, RestangularV3) {
                return RestangularV3.all('buildings').getList()
                    .then(function (buildings) { return _.filter(buildings, function (b) { return b.status === 'ACTIVE'; }); });
            },
            buildingGroups: function (RestangularV3, account, waitForAuthToken) {
                return RestangularV3.all('building-groups').getList();
            },
            buildingPersonnels: function (buildings, $q) {
                var promises = _.map(buildings, function (building) {
                    return building.all('personnel').getList()
                        .then(function (result) {
                        return {
                            buildingId: building.id,
                            personnel: result
                        };
                    }, function (error) {
                        return {
                            buildingId: building.id,
                            personnel: []
                        };
                    });
                });
                return $q.all(promises);
            },
            startDate: function ($location) {
                if ($location.search().startDate) {
                    return parseInt($location.search().startDate);
                }
                else {
                    return null;
                }
            },
            schedulingInterval: function ($location) {
                if ($location.search().schedulingInterval) {
                    return $location.search().schedulingInterval;
                }
                else {
                    return null;
                }
            },
            alerts: function (RestangularV3, date, startDate, account, schedulingInterval, waitForAuthToken) {
                var interval = schedulingInterval ? schedulingInterval : 'week';
                var timeZoneId = account.timeZoneId, accountId = account.id;
                var localEndDate = moment(date).utc().format('YYYY-MM-DD');
                var end = moment.tz(localEndDate, timeZoneId).format();
                var localStartDate = moment(startDate).utc().format('YYYY-MM-DD');
                var start = startDate
                    ? moment.tz(localStartDate, timeZoneId).format()
                    : moment.tz(localEndDate, timeZoneId).subtract(1, interval).format();
                return RestangularV3.one('alert-data')
                    .one('stats')
                    .one('for-account')
                    .get({ start: start, end: end, accountId: accountId });
            }
        }
    })
        .state('aq.reports.building', {
        abstract: true,
        template: '<ui-view></ui-view>',
        resolve: {
            lang: function (waitForAuthToken, UserService) {
                return UserService.initLanguage();
            },
            currentDate: function (lang, $location, building) {
                if ($location.search().date) {
                    var localDate = moment
                        .unix(parseInt($location.search().date) / 1000)
                        .utc()
                        .format('YYYY-MM-DDTHH:mm');
                    return moment.tz(localDate, building.timeZoneId);
                }
                return moment();
            },
            startDate: function (lang, $location, building) {
                if ($location.search().startDate) {
                    var localDate = moment
                        .unix(parseInt($location.search().startDate) / 1000)
                        .utc()
                        .format('YYYY-MM-DDTHH:mm');
                    return moment.tz(localDate, building.timeZoneId);
                }
                var tempMoment = moment();
                tempMoment.subtract(1, 'month');
                return tempMoment;
            },
            endDate: function (lang, $location, building) {
                if ($location.search().endDate) {
                    var localDate = moment
                        .unix(parseInt($location.search().endDate) / 1000)
                        .utc()
                        .format('YYYY-MM-DDTHH:mm');
                    return moment.tz(localDate, building.timeZoneId);
                }
                return moment();
            },
            building: function (waitForAuthToken, accountId, buildingId, Restangular) {
                return Restangular.one('accounts', accountId).one('buildings', buildingId)
                    .get({ single: true, inflate: 'buildingPurpose,buildingOccupancies,collectors' });
            },
            energyStarScore: function (waitForAuthToken, accountId, buildingId, EnergyStarApiService) {
                return EnergyStarApiService.getEnergyStarScoreForBuilding(accountId, buildingId);
            },
            functionalities: function (waitForAuthToken, accountId, Restangular) {
                return Restangular.one('accounts', accountId).getList('Functionalities');
            },
            options: function (OptionsService, accountId, account) {
                return OptionsService.init(accountId, account.measurementSystem, account.currencyUnit);
            },
            availableMetrics: function (building, MonthlyBuildingEngineerData) {
                return MonthlyBuildingEngineerData.availableMetrics(building);
            },
            meters: function (waitForAuthToken, buildingId, RestangularV3) {
                return RestangularV3.all('devices').getList({ buildingId: buildingId, category: 'METER' });
            }
        }
    })
        .state('aq.reports.building.daily', {
        url: '/summary/daily',
        templateUrl: 'app/reports/summary/building/dailyBuildingReport.html',
        controller: 'DailyBuildingCtrl',
        controllerAs: 'vm'
    })
        .state('aq.reports.building.monthly', {
        url: '/summary/monthly',
        templateUrl: 'app/reports/summary/building/monthlyBuildingReport.html',
        controller: 'MonthlyBuildingCtrl',
        controllerAs: 'vm'
    })
        .state('aq.reports.building.customDateRange', {
        url: '/summary/customDateRange',
        templateUrl: 'app/reports/summary/customDateRange/controllers/customDateRangeBuildingReport.html',
        controller: 'CustomDateRangeBuildingReportCtrl',
        controllerAs: 'vm',
        resolve: {
            buildings: function (waitForAuthToken, RestangularV3) {
                return RestangularV3.all('buildings').getList()
                    .then(function (buildings) { return _.filter(buildings, function (b) { return b.status === 'ACTIVE'; }); });
            },
            buildingGroups: function (RestangularV3, account, waitForAuthToken) {
                return RestangularV3.all('building-groups').getList();
            },
            buildingPersonnels: function (buildings, $q) {
                var promises = _.map(buildings, function (building) {
                    return building.all('personnel').getList()
                        .then(function (result) {
                        return {
                            buildingId: building.id,
                            personnel: result
                        };
                    }, function (error) {
                        return {
                            buildingId: building.id,
                            personnel: []
                        };
                    });
                });
                return $q.all(promises);
            }
        }
    })
        .state('aq.reports.dailyDemo', {
        url: '/summary/dailyDemo',
        controller: 'dailyReportDemoCtrl',
        templateUrl: 'app/reports/summary/dailyDemo/controllers/main.html',
        data: {
            hideNavbar: true
        }
    })
        .state('aq.reports.vornadoTenantReport', {
        url: '/billing/vornadoTenantReport?accountId&buildingId&billingId&invoiceId&tenantId',
        controller: 'VornadoTenantReportCtrl',
        controllerAs: 'vm',
        templateUrl: 'app/reports/billing/vornadoTenantReport/controllers/vornadoTenantReportCtrl.html',
        data: {
            hideNavbar: true
        },
        resolve: {
            invoice: function (waitForAuthToken, $stateParams, RestangularV3) {
                return RestangularV3.one('tenant-invoices', $stateParams.invoiceId)
                    .get();
            },
            billing: function (waitForAuthToken, $stateParams, RestangularV3) {
                return RestangularV3.one('billings', $stateParams.billingId)
                    .get();
            },
            tenant: function (waitForAuthToken, $stateParams, RestangularV3) {
                return RestangularV3.one('tenants', $stateParams.tenantId)
                    .get();
            },
            tenantChargeValues: function (waitForAuthToken, $stateParams, RestangularV3) {
                return RestangularV3.all('tenant-charge-values')
                    .all('for-tenant-invoice')
                    .getList({ tenantInvoiceId: $stateParams.invoiceId });
            },
            historicalUsage: function (waitForAuthToken, $stateParams, RestangularV3) {
                return RestangularV3.all('tenant-invoices')
                    .all('for-historical-usage')
                    .getList({ tenantInvoiceId: $stateParams.invoiceId });
            },
            setup: function (waitForAuthToken, $stateParams, RestangularV3) {
                return RestangularV3.one('billing-setups')
                    .get({ buildingId: $stateParams.buildingId });
            },
            options: function (OptionsService, account) {
                return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
            }
        }
    })
        .state('aq.reports.passThroughSummary', {
        url: '/custom/passThroughSummary?accountId&buildingId&billingId',
        controller: 'PassThroughSummaryCtrl',
        controllerAs: 'vm',
        templateUrl: 'app/reports/billing/passThroughSummary/controllers/passThroughSummaryCtrl.html',
        data: {
            hideNavbar: true
        },
        resolve: {
            billing: function (waitForAuthToken, $stateParams, RestangularV3) {
                return RestangularV3.one('billings', $stateParams.billingId)
                    .get();
            },
            tenants: function (waitForAuthToken, RestangularV3, buildingId) {
                return RestangularV3.all('tenants').getList({ buildingId: buildingId });
            },
            tenantChargeValues: function (waitForAuthToken, RestangularV3, DataStore, $stateParams) {
                return DataStore.getList(RestangularV3.one(''), 'tenant-charge-values', { monthlyBillingId: $stateParams.billingId });
            },
            setup: function (waitForAuthToken, $stateParams, RestangularV3) {
                return RestangularV3.one('billing-setups')
                    .get({ buildingId: $stateParams.buildingId });
            },
            building: function (waitForAuthToken, DataStore, RestangularV3, $stateParams) {
                return DataStore.get(RestangularV3.one('buildings', $stateParams.buildingId), {}, true);
            },
            periodTenantServices: function (waitForAuthToken, RestangularV3, DataStore, $stateParams) {
                return DataStore.getList(RestangularV3.one(''), 'period-tenant-services', { monthlyBillingId: $stateParams.billingId });
            },
            tenantGroups: function (waitForAuthToken, RestangularV3, $stateParams) {
                return RestangularV3.all('tenant-groups').getList({ buildingId: $stateParams.buildingId });
            },
            options: function (OptionsService, account) {
                return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
            }
        }
    })
        .state('aq.reports.billingInvoice', {
        url: '/billing/invoice?accountId&buildingId&billingId&invoiceId&tenantId',
        controller: 'reportsInvoiceCtrl',
        controllerAs: 'vm',
        templateUrl: 'app/reports/billing/invoice/controllers/invoiceCtrl.html',
        data: {
            hideNavbar: true
        },
        resolve: {
            invoice: function (waitForAuthToken, $stateParams, RestangularV3) {
                return RestangularV3.one('tenant-invoices', $stateParams.invoiceId)
                    .get();
            },
            billing: function (waitForAuthToken, $stateParams, RestangularV3) {
                return RestangularV3.one('billings', $stateParams.billingId)
                    .get();
            },
            tenant: function (waitForAuthToken, $stateParams, RestangularV3) {
                return RestangularV3.one('tenants', $stateParams.tenantId)
                    .get();
            },
            tenantChargeValues: function (waitForAuthToken, $stateParams, RestangularV3) {
                return RestangularV3.all('tenant-charge-values')
                    .all('for-tenant-invoice')
                    .getList({ tenantInvoiceId: $stateParams.invoiceId });
            },
            historicalUsage: function (waitForAuthToken, $stateParams, RestangularV3) {
                return RestangularV3.all('tenant-invoices')
                    .all('for-historical-usage')
                    .getList({ tenantInvoiceId: $stateParams.invoiceId });
            },
            setup: function (waitForAuthToken, $stateParams, RestangularV3) {
                return RestangularV3.one('billing-setups')
                    .get({ buildingId: $stateParams.buildingId });
            },
            options: function (OptionsService, account) {
                return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
            },
            residents: function (waitForAuthToken, invoice, RestangularV3) {
                if (invoice.lease) {
                    return RestangularV3.all('residents')
                        .customGET('', { leaseId: invoice.lease.id });
                }
                return null;
            }
        }
    })
        .state('aq.reports.projectDetails', {
        url: '/project/projectDetails',
        controller: 'ProjectDetailController',
        controllerAs: 'vm',
        templateUrl: 'app/reports/projectCenter/controllers/projectDetail.html',
        data: {
            hideNavbar: true
        },
        resolve: {
            users: function (Restangular, DataStore, account, waitForAuthToken) {
                return DataStore.getList(Restangular.one('accounts', account.id), 'users', {});
            },
            projectId: function ($location) {
                return $location.search().projectId;
            },
            project: function (waitForAuthToken, RestangularV3, projectId) {
                // returns a project object, you can use this to get the details of the project by passing it into controller
                return RestangularV3.one('projects', projectId).get();
            },
            measures: function (waitForAuthToken, RestangularV3, DataStore) {
                return DataStore.getList(RestangularV3.one(''), 'measures');
            },
            projectResults: function (project, waitForAuthToken) {
                return project.customGET('results');
            },
            units: function (OptionsService, account) {
                return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit).then(function () {
                    return OptionsService.getAllUnits();
                });
            },
            projectStatuses: function (waitForAuthToken, RestangularV3) {
                return RestangularV3.one('projects').one('statuses').get().then(function (result) {
                    return result.originalElement;
                });
            },
            projectTypes: function (waitForAuthToken, RestangularV3) {
                return RestangularV3.one('projects').one('types').get().then(function (result) {
                    return result.originalElement;
                });
            }
        }
    })
        .state('aq.reports.portfolio', {
        url: '/summary/portfolio',
        templateUrl: 'app/reports/summary/portfolio/portfolioYTD.html',
        controller: 'PortfolioControllerYTD',
        controllerAs: 'vm',
        data: {
            hideNavbar: true
        },
        resolve: {
            options: function (OptionsService, account) {
                return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
            },
            reportMonth: function (date) {
                moment.locale('en-us');
                return moment(date).subtract(1, 'month').format('MMMM');
            },
            allBuildings: function (account) {
                // TODO: retreive all buildings for the account
                // alternatively, modify buildingGroups endpoint to include info about 'incomplete' groups directly
                return [];
            },
            buildings: function (waitForAuthToken, RestangularV3) {
                return RestangularV3.one('').getList('buildings', { showInactive: false });
            },
            buildingIds: function (buildings) {
                return buildings.map(function (b) { return b.id; });
            },
            buildingGroups: function (RestangularV3, allBuildings, buildings, waitForAuthToken) {
                return RestangularV3.all('building-groups').getList().then(function (groups) {
                    _.each(groups, function (group) {
                        var filteredAllBuildings = _.filter(allBuildings, function (b) { return b.buildingGroup === group.id; });
                        var filteredVisibleBuildings = _.filter(buildings, function (b) { return b.buildingGroup === group.id; });
                        if (filteredAllBuildings.length > filteredVisibleBuildings.length) {
                            group.isIncomplete = true;
                        }
                    });
                    return groups;
                });
            },
            utilitySpendingBudgets: function (waitForAuthToken, $q, RestangularV3, buildingIds, date) {
                var correctedDate = moment(date).dayOfYear() === 1 ? moment(date).subtract(1, 'day') : moment(date);
                var start = moment(correctedDate).startOf('year').format('YYYY-MM-DDTHH:mm:ssZ');
                var end = moment(correctedDate).endOf('year').format('YYYY-MM-DDTHH:mm:ssZ');
                return RestangularV3.one('utility-spending').customGET('for-buildings', {
                    start: start,
                    end: end,
                    buildingIds: buildingIds,
                    interval: '1mon',
                    measure: 'electricity'
                });
            },
            utilitySpendingBudgetsPastYear: function ($q, RestangularV3, buildings, buildingIds, date, waitForAuthToken, utilitySpendingBudgets) {
                var correctedDate = moment(date).dayOfYear() === 1 ? moment(date).subtract(1, 'day') : moment(date);
                var isPastDataRequired = _.some(buildings, function (building) {
                    var start = getFiscalYearStart(building, moment(correctedDate));
                    return moment(start).year() < moment(correctedDate).year();
                });
                if (isPastDataRequired) {
                    var pastYear = moment(correctedDate).subtract(1, 'year');
                    var pastYearStartDate = pastYear.startOf('year').format('YYYY-MM-DDTHH:mm:ssZ');
                    var pastYearEndDate = pastYear.endOf('year').format('YYYY-MM-DDTHH:mm:ssZ');
                    return RestangularV3.one('utility-spending').customGET('for-buildings', {
                        start: pastYearStartDate,
                        end: pastYearEndDate,
                        buildingIds: buildingIds,
                        interval: '1mon',
                        measure: 'electricity'
                    });
                }
                else {
                    return [];
                }
            },
            buildingTargets: function (Restangular, account, buildingIds, date, waitForAuthToken) {
                var correctedDate = moment(date).dayOfYear() === 1 ? moment(date).subtract(1, 'day') : moment(date);
                return Restangular.one('accounts', account.id).all('targets')
                    .customGET('aggregate', {
                    inflate: 'targetItems,measure',
                    buildings: buildingIds,
                    date: correctedDate.format('YYYY-MM-DD')
                });
            },
            buildingTargetsPastYear: function (Restangular, account, buildingIds, date, waitForAuthToken, buildings) {
                var correctedDate = moment(date).dayOfYear() === 1 ? moment(date).subtract(1, 'day') : moment(date);
                var isPastDataRequired = _.some(buildings, function (building) {
                    var start = getFiscalYearStart(building, moment(correctedDate));
                    return moment(start).year() < moment(correctedDate).year();
                });
                if (isPastDataRequired) {
                    return Restangular.one('accounts', account.id).all('targets')
                        .customGET('aggregate', {
                        inflate: 'targetItems,measure',
                        buildings: buildingIds,
                        date: correctedDate.subtract(1, 'year').format('YYYY-MM-DD')
                    });
                }
            },
            buildingConsumptions: function (Restangular, account, buildings, $q, options, OptionsService, date, waitForAuthToken, utilitySpendingBudgets, utilitySpendingBudgetsPastYear) {
                var promises = getMonthlyDataForType(Restangular, account, buildings, date, 'CONSUMPTION');
                return $q.all(promises);
            },
            buildingPeakDemands: function (Restangular, account, buildings, $q, $filter, options, OptionsService, date, waitForAuthToken, utilitySpendingBudgets, utilitySpendingBudgetsPastYear) {
                var promises = getMonthlyDataForType(Restangular, account, buildings, date, 'PEAK_DEMAND');
                return $q.all(promises);
            },
            buildingSchedules: function (RestangularV3, buildings, waitForAuthToken) {
                return RestangularV3.one('calendars').get()
                    .then(function (data) {
                    return _.map(buildings, function (building) {
                        return {
                            buildingId: building.id,
                            schedule: _.find(data, function (item) { return item.id == building.calendar; })
                        };
                    });
                }, function (error) {
                    return [];
                });
            },
            buildingSchedulePartitionedConsumptions: function (Restangular, account, buildings, $q, date, waitForAuthToken, buildingConsumptions, buildingPeakDemands) {
                var counter = 0;
                var batchSize = 5;
                var defs = [$q.defer()];
                var result = [];
                var _loop_1 = function () {
                    var nextDefer = $q.defer();
                    var lastDefer = _.last(defs);
                    defs.push(nextDefer);
                    var closure = function (cnt, nextDef) {
                        lastDefer.promise.then(function () {
                            var batchPromises = getNextRequestBatch(Restangular, account, buildings, date, cnt, cnt + batchSize);
                            $q.all(batchPromises).then(function (data) {
                                result = _.concat(result, data);
                                nextDef.resolve();
                            });
                        });
                    };
                    closure(counter, nextDefer);
                    counter += batchSize;
                };
                while (buildings.length > 0 && counter < buildings.length) {
                    _loop_1();
                }
                var finalDefer = _.last(defs);
                _.first(defs).resolve([]);
                return finalDefer.promise.then(function () {
                    return result;
                });
            },
            buildingPersonnels: function (buildings, $q, RestangularV3, waitForAuthToken) {
                var promises = _.map(buildings, function (building) {
                    return RestangularV3.one('buildings', building.id).customGET('personnel')
                        .then(function (result) {
                        return {
                            buildingId: building.id,
                            personnel: result
                        };
                    }, function (error) {
                        return {
                            buildingId: building.id,
                            personnel: []
                        };
                    });
                });
                return $q.all(promises);
            },
            buildingOccupancy: function (buildingIds, Restangular, account, waitForAuthToken) {
                return Restangular.one('accounts', account.id).customGET('building-occupancy', { buildings: buildingIds });
            },
            buildingDegreeDays: function (Restangular, account, buildings, $q, date, waitForAuthToken) {
                var promises = _.map(buildings, function (building) {
                    var start = getFiscalYearStart(building, moment(date));
                    var end = moment(date).format();
                    return Restangular.one('accounts', account.id).one('buildings', building.id)
                        .customGET('degreeDays', { start: start, end: end, interval: '1mon' })
                        .then(function (result) {
                        return {
                            buildingId: building.id,
                            degreeDays: result
                        };
                    }, function (error) {
                        return {
                            buildingId: building.id,
                            degreeDays: {
                                hdd: { values: [] },
                                cdd: { values: [] }
                            }
                        };
                    });
                });
                return $q.all(promises);
            }
        }
    });
    var getNextRequestBatch = function (Restangular, account, buildings, date, index, count) {
        var items = _.slice(buildings, index, count);
        var promises = _.map(items, function (building) {
            var start = moment(date).subtract(1, 'month').tz(building.timeZoneId).startOf('month').format();
            var end = moment(start).endOf('month').format();
            return Restangular.one('accounts', account.id).one('buildings', building.id)
                .customGET('metrics', {
                interval: '1h',
                start: start,
                end: end,
                partitionId: building.calendar,
                partitionType: 'workcalendar',
                measure: 'ENERGY'
            })
                .catch(function () {
                return {
                    id: building.id,
                    start: start,
                    end: end,
                    partitions: []
                };
            });
        });
        return promises;
    };
    var getBuildingTimezoneFirstDateOfMonth = function (building, year, month) {
        var yearStartString = year + "-" + (month < 10 ? 0 : '') + month + "-01T00:00:00Z";
        var offsetStart = moment(yearStartString).tz(building.timeZoneId).utcOffset();
        var start = moment(yearStartString).tz(building.timeZoneId).add(-offsetStart, 'minutes').format();
        return start;
    };
    var getFiscalYearStart = function (building, currentDate) {
        var currentMonth = currentDate.month() + 1;
        var startMonth = building.fiscalStartMonth ? building.fiscalStartMonth : 1;
        var startYear = currentDate.year();
        if (currentMonth <= startMonth) {
            startYear--;
        }
        return getBuildingTimezoneFirstDateOfMonth(building, startYear, startMonth);
    };
    var getFiscalYearStartMoment = function (building, currentDate) {
        var result = moment(currentDate);
        var startMonth = building.fiscalStartMonth ? building.fiscalStartMonth : 1;
        var monthDiff = startMonth - (currentDate.month() + 1);
        if (monthDiff > 0) {
            result.subtract(1, 'year');
        }
        result.add(monthDiff, 'month');
        return result;
    };
    var getMonthlyDataForType = function (Restangular, account, buildings, date, type) {
        var correctedDate = moment(date).dayOfYear() === 1 ? moment(date).subtract(1, 'day') : moment(date);
        var minDate;
        _.each(buildings, function (building) {
            var start = getFiscalYearStartMoment(building, correctedDate);
            if (!minDate || start.isBefore(minDate)) {
                minDate = start;
            }
        });
        var promises = [];
        var monthDate = moment(minDate);
        while (monthDate.isSameOrBefore(correctedDate)) {
            promises.push(Restangular.one('accounts', account.id).one('portfolio')
                .customGET('insightValues', { type: type, date: moment(monthDate).valueOf() })
                .catch(function () { }));
            monthDate.add(1, 'month');
        }
        return promises;
    };
});
