angular.module('aq.ui').directive('aqLeftSideNav', () => {
    return {
        restrict: 'E',
        transclude: true,
        replace: true,
        templateUrl: 'app/common/directives/leftSideNav/leftSideNav.html',
        link: ($scope: any, $element, $attrs: any) => {
            if($attrs.showBuildingSelector) {
                $scope.showBuildingSelector = $attrs.showBuildingSelector;
            } else {
                $scope.showBuildingSelector = false;
            }
        }
    }
});