namespace aq.deviceManagement {
    export class DeviceDetailDataCharting {

        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private building,
            private collector,
            private device,
            private links,
            private points
        ) {
        }

        public onDeviceChange(event) {
            this.$scope.$emit('DEVICE_UPDATED', event.device);
        }
    }

    angular
        .module('deviceManagement')
        .controller('DeviceDetailDataCharting', DeviceDetailDataCharting);
}

