'use strict';

angular
    .module('aq.admin.accounts',
        ['aq.admin.accounts.accounts', 'aq.admin.accounts.buildings', 'aq.admin.accounts.sources', 'aq.admin.accounts.schema', , 'aq.admin.accounts.enrollment'])
    .config(($stateProvider, $urlRouterProvider) => {
        $urlRouterProvider.when('/admin/accounts', '/admin/accounts/accounts');
        $stateProvider
            .state('aq.admin.accounts', {
                url: '/accounts',
                templateUrl: 'app/admin/accounts/main.html',
                data: {
                    appName: 'Accounts'
                }
            });
    });
