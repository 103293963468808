namespace aq.tenantbilling {
    export class BulkResolveAnomaliesCtrl {
        private notes: string;
        private inProgress: boolean;
        tenantLabel: string;

        /* @ngInject */
        constructor(
            private tenantChargeValues: TenantChargeValue[],
            private MonthlyBillingService: MonthlyBillingService,
            private $mdDialog: ng.material.IDialogService,
            private $q: ng.IQService,
            private Messages: aq.services.Messages,
            private Auth: aq.services.Auth
        ) {
            this.setProgress(false);
            this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'unit' : 'tenant';
        }

        public cancel() {
            this.$mdDialog.cancel();
        }

        public getAnomalies(): TenantChargeValue[] {
            return _.filter(this.tenantChargeValues, (chargeValue) => {
                return chargeValue.needsUserAction;
            });
        }

        public setProgress(inProgress) {
            this.inProgress = inProgress;
        }

        public async resolveAnomalies(notes) {
            if (!notes || notes === '') {
                this.Messages.error('Must fill out a note');
                return;
            }
            this.setProgress(true);
            // update our anomalies with the given note
            const chargeValues = this.getAnomalies();
            chargeValues.forEach(chargeValue => {
                chargeValue.notes = notes;
            });
            this.MonthlyBillingService.saveChargeValues(chargeValues, chargeValues[0].route)
                .then((results) => {
                    this.$mdDialog.hide(results);
                }).catch(() => {
                    this.Messages.error('Failed to update resolutions for our anomalies');
                }).finally(() => {
                    this.setProgress(false);
                });
        }
    }

    angular.module('tenantBilling')
        .controller('BulkResolveAnomaliesCtrl', BulkResolveAnomaliesCtrl);
}
