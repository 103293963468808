namespace aq.ui {
    angular
        .module('aq.ui')
        .component('diffCounter', {
            templateUrl: 'app/common/directives/diffCounter/diffCounter.html',
            controller: function() {},
            controllerAs: 'vm',
            bindings: {
                items: '<', // List of items we are counting
                missing: '<', // List of items that are not yet selected
                itemLabel: '<' // The name to use for items
            }
        });

}
