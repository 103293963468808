namespace aq.services {
    export interface OutgoingRedirect {
        destination: string;
        building: number;
        account: number;
        profile: number;
        authToken?: string;
        payload?: string // serialized JSON data for the other side to parse
    }

    export interface IncomingRedirect {
        destination: string;
        building: number;
        account: number;
        profile: number;
        authToken?: string;
        payload?: string // serialized JSON data for us
    }

    export class RedirectService {
        private readonly routeParamsRegex = /\/:\w+\//g;
        /* @ngInject */
        constructor(
            private AuthToken: string,
            private Messages: aq.services.Messages
        ) { }

        @Memoize()
        public isLocalhost(): boolean {
            return Boolean(
                window.location.hostname === 'localhost' ||
                // [::1] is the IPv6 localhost address.
                window.location.hostname === '[::1]' ||
                // 127.0.0.1/8 is considered localhost for IPv4.
                window.location.hostname.match(
                    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
                )
            );
        }

        @Memoize()
        public isStage(): boolean {
            return Boolean(
                window.location.hostname.indexOf('stage.') > -1 ||
                window.location.hostname.indexOf('qa') > -1
            );
        }

        @Memoize()
        public isProd(): boolean {
            return Boolean(
                window.location.hostname.indexOf('my.') > -1
            );
        }

        @Memoize()
        public getBaseURL() {
            if (this.isProd()) {
                return 'https://app.aquicore.com';
            }
            if (this.isStage()) {
                return 'https://stageapp.aquicore.com';
            }
            return 'http://localhost:3030';
        }

        private getOnboardingBaseURL() {
            if (this.isProd()) {
                return 'https://onboarding.aquicore.com';
            }
            if (this.isStage()) {
                return 'https://stageonboarding.aquicore.com';
            }
            return 'http://localhost:3040';
        }

        private getAuth(token?: string) {
            return `authToken=${token ? token : this.AuthToken}`;
        }

        private validateParams(params: OutgoingRedirect) {
            for (let key in params) {
                if (params.hasOwnProperty(key) && params[key] == void 0) {
                    return false;
                }
            }
            return true;
        }

        public redirect(redirectParams: OutgoingRedirect, openInNewWindow = false) {
            if (!this.validateParams(redirectParams)) {
                this.Messages.error('Something went wrong, please try again later.');
            }
            const { destination, building, account, profile, authToken, payload } = redirectParams;
            const url = `${this.getBaseURL()}/${destination}?`;
            const params = [
                `building=${building}`,
                `account=${account}`,
                `profile=${profile}`,
                this.getAuth(authToken),
                `origin=${window.location.href}`,
                `redirect=true`,
            ];

            if (payload) params.push(`payload=${payload}`);

            const result = `${url}${params.join('&')}`;
            if (!this.isProd()) {
                console.log('redirect:', result);
            }
            if (openInNewWindow) {
                var link = document.createElement("a");
                link.setAttribute("href", result);
                link.setAttribute("target", "_blank");
                link.click();
            } else {
                window.location.href = result;
            }
        }

        public handleIncomingRedirect(redirectParams: IncomingRedirect) {
            console.log('RedirectService.handleIncomingRedirect is not implemented', redirectParams)
        }

        public redirectWithoutParams(destination: string) {
            window.location.href = `${this.getBaseURL()}/${destination}`;
        }

        public redirectOnboardingWithoutParams(destination: string) {
            window.location.href = `${this.getOnboardingBaseURL()}/${destination}`;
        }

        public getFrontendRedirectUrl(path: string) {
            let redirectUrl = '';
            mappedRoutes.forEach(item => {
                // skip if redirect url is already found
                if (redirectUrl) {
                    return;
                }

                // get parameters for current route
                // '/account/:accountId/building/:buildingId/' => [':accountId', ':buildingId']
                const monolithUrl = item.monolithUrl.endsWith('/')
                    ? item.monolithUrl
                    : `${item.monolithUrl}/`;
                let paramNames = monolithUrl.match(this.routeParamsRegex);
                if (paramNames) {
                    paramNames = paramNames.map(param => param.substring(1, param.length - 1));
                }

                // build custom route regex to try matching input path parameter values
                // '/account/:accountId/building/:buildingId/' => '\/account\/(\d+)\/building\/(\d+)\/
                let monolithUrlRegexString = item.monolithUrl;
                if (paramNames) {
                    paramNames.forEach(param => {
                        monolithUrlRegexString = monolithUrlRegexString.replace(param, '(\\d+)');
                    });
                }
                monolithUrlRegexString = monolithUrlRegexString.replace('?', '\\?');
                monolithUrlRegexString = monolithUrlRegexString.split('/').join('\\/');
                monolithUrlRegexString = `^${monolithUrlRegexString}$`;

                // try matching input path with current route
                const monolithUrlRegex = new RegExp(monolithUrlRegexString);
                const matches = path.match(monolithUrlRegex);

                // if regex matched, but there were no params, set redirect url as is
                if (!paramNames && matches && matches.length > 0) {
                    redirectUrl = item.frontendUrl;
                }
                // if regex matched, but there are route params
                // replace matching parameter values in the redirect url
                // (first matched value is the entire route, and the remaining ones are ordered parameter values)
                else if (paramNames && matches && matches.length == paramNames.length + 1) {
                    let frontendUrl = item.frontendUrl;
                    let counter = 1;
                    while (counter < matches.length) {
                        const currentParam = paramNames[counter - 1];
                        const currentValue = matches[counter];
                        frontendUrl = frontendUrl.replace(currentParam, currentValue);
                        counter++;
                    }
                    redirectUrl = frontendUrl;
                }
            });

            return redirectUrl;
        }
    }

    angular
        .module('aq.services')
        .service('RedirectService', RedirectService);
}
