var aq;
(function (aq) {
    var services;
    (function (services) {
        var AidIssueService = /** @class */ (function () {
            /* @ngInject */
            function AidIssueService($http, resolver, Messages, AQ_API_URL, AuthToken) {
                this.$http = $http;
                this.resolver = resolver;
                this.Messages = Messages;
                this.AQ_API_URL = AQ_API_URL;
                this.AuthToken = AuthToken;
            }
            AidIssueService.prototype.createFeedback = function (feedback) {
                var _this = this;
                try {
                    return this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                        var response;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.$http({
                                        method: 'POST',
                                        headers: this.getHeaders(),
                                        data: JSON.stringify(_.omit(feedback, 'issue')),
                                        url: this.AQ_API_URL + "automatic-issue-detection/v1/" + feedback.issue + "/feedback"
                                    })];
                                case 1:
                                    response = _a.sent();
                                    return [2 /*return*/, response.data];
                            }
                        });
                    }); });
                }
                catch (error) {
                    throw error;
                }
            };
            AidIssueService.prototype.getIssue = function (id) {
                var _this = this;
                try {
                    return this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                        var response;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.$http({
                                        method: 'GET',
                                        headers: this.getHeaders(),
                                        url: this.AQ_API_URL + "automatic-issue-detection/v1/" + id
                                    })];
                                case 1:
                                    response = _a.sent();
                                    return [2 /*return*/, response.data];
                            }
                        });
                    }); });
                }
                catch (error) {
                    throw error;
                }
            };
            AidIssueService.prototype.deleteIssue = function (id) {
                var _this = this;
                try {
                    return this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                        var response;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.$http({
                                        method: 'DELETE',
                                        headers: this.getHeaders(),
                                        url: this.AQ_API_URL + "automatic-issue-detection/v1/" + id
                                    })];
                                case 1:
                                    response = _a.sent();
                                    return [2 /*return*/, response.data]; // TODO: If delete is ever implemented in UI, this is probably not a useful return
                            }
                        });
                    }); });
                }
                catch (error) {
                    throw error;
                }
            };
            AidIssueService.prototype.getIssues = function (params) {
                var _this = this;
                try {
                    return this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                        var response;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.$http({
                                        method: 'GET',
                                        headers: this.getHeaders(),
                                        url: this.AQ_API_URL + "automatic-issue-detection/v1",
                                        params: params
                                    })];
                                case 1:
                                    response = _a.sent();
                                    return [2 /*return*/, response.data];
                            }
                        });
                    }); });
                }
                catch (error) {
                    throw error;
                }
            };
            AidIssueService.prototype.getHeaders = function () {
                return {
                    'Content-Type': 'application/json',
                    'Authorization': this.AuthToken
                };
            };
            return AidIssueService;
        }());
        services.AidIssueService = AidIssueService;
        angular
            .module('aq.services')
            .service('AidIssueService', AidIssueService);
    })(services = aq.services || (aq.services = {}));
})(aq || (aq = {}));
