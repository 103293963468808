/**
 * Period selector widget
 * @param {customCreatedObject} defaultPeriod - set default period on initialization
 *         - customCreatedObject is object created from values in intervalService
 *           structure: key - name from object in getTimeSelector array
 *                      value - period name from constraint object (constraints array)
 *                      example: { "Trailing": "Hour" }
 */
angular.module('aq.ui.periodSelector', ['aq.ui.intervalSelector']).directive('periodSelector', function() {
    return {
        restrict : 'E',
        replace: true,
        templateUrl: 'app/common/directives/periodSelector/periodSelector.html',
        scope: {
            onChange: '&',
            aggregation: '=',
            start: '=',
            end: '=',
            skipUpdateOnInit: '@', // this is specially developed because of meter mangement page
            // currently dynamic filter does querying data on load - we need to think of how to manage
            // priorities when multiple widgets wants to query data on load, which will take priority etc...
            excludePeriods: '=',
            defaultPeriod: '='
        },
        controller: function ($scope, intervalService) {

            var callOnChange = $scope.skipUpdateOnInit == 'true' ? false : true;

            $scope.timeSelector = intervalService.getTimeSelector();

            if ($scope.excludePeriods) {
                _.each($scope.timeSelector, function(o) {
                    o.periods = _.filter(o.periods, function(period) {
                        return $scope.excludePeriods.indexOf(period.period) == -1;
                    })
                })
            }

            $scope.select = function(t, p) {
                $scope.selected = t;

                var period = _.first(t.periods);
                if ($scope.selectedPeriod && $scope.selected.keepSelectedConstraint) {
                    // in case period is not found fallback to first item
                    period = _.find(t.periods, { period: $scope.selectedPeriod.period }) || period;
                }

                // override everything if period is passed
                // this case is actual only on initialization
                if (p) period = p;

                $scope.selectPeriod(period);
            }

            // TODO: add unit tests
            $scope.selectPeriod = function(p) {
                $scope.selectedPeriod = p;
                $scope.aggregation = p.defaultInterval;
                p.init();
                update();
            }

            $scope.prev = function() {
                $scope.selectedPeriod.prev();
                update();
            }

            $scope.next = function() {
                $scope.selectedPeriod.next();
                update();
            }

            /**
             * if custom selected period with datepickers
             */
            $scope.onCustomSelection = function () {
                var availableIntervals = intervalService.getAvailableIntervals($scope.start, $scope.end);
                var aggregation = _.find(availableIntervals, { value: $scope.aggregation });
                /**
                 * if aggregation not found in availableIntervals then pick
                 * first in array
                 */
                if (!aggregation) {
                    $scope.aggregation = _.first(availableIntervals).value;
                }
                $scope.onChange({ start: $scope.start, end: $scope.end, interval: $scope.aggregation });
            }

            function update() {
                // TODO: why we need to initialize moment object again when it is already moment object
                // hint: something is wrong with reference
                $scope.start = moment($scope.selectedPeriod.start());
                $scope.end = moment($scope.selectedPeriod.end());

                $scope.onChange({ start: $scope.start, end: $scope.end, interval: $scope.aggregation });
                $scope.hideNext = $scope.end.isAfter(moment());

                if (callOnChange == true) {
                    $scope.onChange({ start: $scope.start, end: $scope.end, interval: $scope.aggregation });
                }
                
                if ($scope.skipUpdateOnInit == 'true') {
                    callOnChange = true;
                }
            }

            if ($scope.defaultPeriod) {
                var presetName = _($scope.defaultPeriod).keys().first();
                var preset = _.find($scope.timeSelector, { name: presetName });
                var periodName = _($scope.defaultPeriod).values().first();
                var period = _.find(preset.periods, { period: periodName });
                $scope.select(preset, period);
            } else {
                $scope.select(_.first($scope.timeSelector));
            }

        }
    }
});