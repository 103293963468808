namespace aq.ui {

    import ActivityFilter = aq.models.activity.ActivityFilter;
    import ActivityContextType = aq.models.activity.ActivityContextType;
    interface IBindings {
        filter: ActivityFilter;
    }
    interface LocalFilter {
        range: 'MONTH' | 'CUSTOM' | 'CURRENT_YEAR' | 'LAST_YEAR';
        customRange: {
            dateStart: string;
            dateEnd: string;
        };
        types: Partial<Record<ActivityContextType, boolean>>;
    }

    // Reading on component lifecycle hooks https://toddmotto.com/angular-1-5-lifecycle-hooks
    class ActivityFilterCtrl implements IBindings {
        public filter: ActivityFilter;
        public updateFilter: Function;
        private localFilter: LocalFilter;

        public $onInit() {
            this.localFilter = {
                types: { ...this.filter.types },
                customRange: { dateStart: void 0, dateEnd: void 0 },
                range: 'MONTH'
            };
        }

        public $onChanges(changes) {
            if (changes.filter) {
                this.localFilter = {
                    types: { ...changes.filter.currentValue.types },
                    customRange: this.localFilter.customRange,
                    range: this.localFilter.range
                };
            }
        }

        public getContextTypeDisplayValue(str: string): string {
            switch (str) {
                case ActivityContextType.OPTIMAL_START:
                    return 'Optimization Strategy';
                case ActivityContextType.ENERGY_NOTE:
                    return 'Note';
                case ActivityContextType.AID_ISSUE:
                    return 'Load Analytics';
                case ActivityContextType.RX:
                    return 'HVAC Analytics';
                case ActivityContextType.NONE:
                    return 'Other';
                default:
                    return _.getTitle(str);
            }
        }

        public applyFilter($mdMenu, ev) {
            if (this.localFilter.range === 'CUSTOM') {
                this.updateFilter({
                    filter: {
                        range: {
                            start: moment(this.localFilter.customRange.dateStart).startOf('day').toString(),
                            end: moment(this.localFilter.customRange.dateEnd).endOf('day').toString()
                        },
                        types: {...this.localFilter.types}
                    }
                });
            } else if(this.localFilter.range === 'CURRENT_YEAR') {
                this.updateFilter({
                    filter: {
                        range: {
                            start: moment().startOf('year'),
                            end: moment().endOf('day')
                        },
                        types: {...this.localFilter.types}
                    }
                });
            } else if(this.localFilter.range === 'LAST_YEAR') {
                this.updateFilter({
                    filter: {
                        range: {
                            start: moment().startOf('year').subtract({year: 1}),
                            end: moment().endOf('year').subtract({year: 1})
                        },
                        types: {...this.localFilter.types}
                    }
                });
            } else {
                this.updateFilter({
                    filter: {
                        range: {
                            start: moment().subtract({months: 1}).startOf('day'),
                            end: moment().endOf('day')
                        },
                        types: {...this.localFilter.types}
                    }
                });
            }
            if($mdMenu) {
                $mdMenu.close(ev);
            }
        }

        public openMenu($mdMenu, ev) {
            $mdMenu.open(ev);
        }
    }

    export const Filter: ng.IComponentOptions = {
        controller: ActivityFilterCtrl,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/activities/filter/activity-filter.html',
        bindings: {
            filter: '<',
            updateFilter: '&'
        }
    };

    angular.module('aq.ui').component('activityFilter', Filter);
}
