// TODO - drop this filter once switched to new APIV2
angular.module('aq.filters.units')
    .filter('arrayToUnit', ($filter) => {
        return function (items, unit, sizes?) {
            return _.map(items, (i) => {
                if (sizes && unit.field === 'intensity' && !_.isNull(i)) {
                    return $filter('toUnit')(i, unit) / (sizes || 1);
                }
                return $filter('toUnit')(i, unit);
            });
        };
    });
