namespace aq.models.activity {

    export enum ProductCategory {
        LOAD_ANALYTICS = 'Load Analytics',
        HVAC_ANALYTICS = 'HVAC Analytics',
        OPTIMIZATION_STRATEGY = 'Optimization Strategies',
        NOTE = 'Notes',
        ALERT = 'Alerts',
        OTHER = 'Other'
    }

    export class ActivityListResponse {
        public count: number;
        public payload: SimpleActivityResponse[];
        constructor() {
            this.payload = [];
            this.count = void 0;
        }
    }

    export enum ActivityContextType {
        NONE = 'NONE',
        ALERT = 'ALERT',
        AID_ISSUE = 'AID_ISSUE',
        ENERGY_NOTE = 'ENERGY_NOTE',
        OPTIMAL_START = 'OPTIMAL_START',
        RX = 'RX'
    }

    export enum ActivityType {
        ISSUE = 'ISSUE',
        SUGGESTION = 'SUGGESTION',
        OBSERVATION = 'OBSERVATION',
        EVENT = 'EVENT',
        REMINDER = 'REMINDER'
    }

    export class SimpleActivityResponse {
        public id: string;
        public title: string;
        public type: ActivityType;
        public context: IActivityContext;
        public createdAt: string;
        public updatedAt: string;
        public messages: number; // count of messages
        public savingsProfile?: AnnualizedSavings;
    }

    export interface IActivityContext {
        type: ActivityContextType;
        alert?: number;
        issue?: string;
        acknowledgedStatus?: string;
        data?; // This field is to accommodate energy note context
        aidissue?: string;
        optimalstart?: string;
        contextId?: string | number;
        contextObject?: any;
    }

    export interface SavingsByAidType {
        [key: string]: SavingsByActivity;
    }

    export interface SavingsByActivity {
        [key: string]: AnnualizedSavings | AnnualizedSavingsProfile;
    }

    export interface AnnualizedSavingsProfile {
        kwh: number;
        savings: AnnualizedSavings;
    }

    export interface AnnualizedSavings {
        savingsForActivity: number;
        savingsPerSqFt: number;
        ytdActivities?: string[];
    }

    export interface ActivityQueryParams {
        start: string;
        end: string;
        building: number;
        type: ActivityType[];
    }


    export enum FilterDateRange {
        WEEK = 'WEEK',
        MONTH = 'MONTH',
        QUARTER = 'QUARTER'
    }

    export interface ActivityFilter {
        range: {
            start: moment.Moment;
            end: moment.Moment;
        };
        ytdRange: {
            start: moment.Moment,
            end: moment.Moment
        };
        types: Partial<Record<ActivityContextType, boolean>>;
    }

    export enum ActivityCategory {
        URGENT = 'URGENT',
        TODAY = 'TODAY',
        THIS_WEEK = 'THIS_WEEK',
        THIS_MONTH = 'THIS_MONTH',
        THIS_QUARTER = 'THIS_QUARTER'
    }

    export const getActivitySavings = (activity) => {
        // TODO expand to include getting annualized savings for AID issues
        if (activity.context) {
            if (activity.context.contextObject) {
                // RX activity
                const { context: { contextObject: { calculated_utility_savings_dollar, calculated_utility_savings_override } } } = activity;
                if (calculated_utility_savings_dollar && calculated_utility_savings_dollar > 0) {
                    return calculated_utility_savings_dollar;
                } else if (calculated_utility_savings_override && calculated_utility_savings_override > 0) {
                    return calculated_utility_savings_override;
                }
            } else if (activity.context.type === ActivityContextType.AID_ISSUE) {
                if (!activity.savingsProfile || !activity.savingsProfile.savings || !activity.savingsProfile.savings.savingsForActivity) {
                    console.error(`No savings profile for activity with Id: ${activity.id}`);
                    return 0;
                }
                return activity.savingsProfile.savings.savingsForActivity;
            }
        }
        return 0;
    };

    export class CategorizedActivities {
        private activities: SimpleActivityResponse[];
        private messageCount: number;
        private totalSavings: number;
        private totalCardActivities: string[];
        private annualizedSavingsCategory: number;

        constructor() {
            this.activities = [];
            this.messageCount = 0;
            this.totalSavings = 0;
            this.annualizedSavingsCategory = 0;
        }

        public isEmpty = () => {
            return this.activities.length === 0;
        };

        public add = (activity: SimpleActivityResponse, mode: aq.ui.ActivityMode): CategorizedActivities => {
            this.activities.push(activity);
            this.messageCount += activity.messages;
            if (activity.context && activity.context.type === ActivityContextType.AID_ISSUE && mode === aq.ui.ActivityMode.CARD) {
                this.annualizedSavingsCategory = activity.savingsProfile.savingsForActivity;
                this.totalCardActivities = activity.savingsProfile.ytdActivities;
            } else {
                this.totalSavings += getActivitySavings(activity);
            }
            return this;
        };

        public sort = (fn: (a, b) => number): CategorizedActivities => {
            this.activities = this.activities.sort(fn);
            return this;
        };

        public reverse = (): CategorizedActivities => {
            this.activities = this.activities.reverse();
            return this;
        };

        public getActivities(): SimpleActivityResponse[] {
            return this.activities;
        }

        public getCardSavingsActivities(): string[] {
            return this.totalCardActivities;
        }

        // TODO hacky remove soon
        public annualizedSavings(fiscalStartMonth: number): void {
            const end = new Date();
            const startYear = end.getMonth() - 1 < fiscalStartMonth ? end.getFullYear() - 1 : end.getFullYear();
            const start = new Date(startYear, fiscalStartMonth);
            const numberOfDays = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);
            this.annualizedSavingsCategory = (this.totalSavings / numberOfDays) * 365;
        }
    }

    export class Category {
        constructor(public category: ProductCategory, public key: string) { }
    }

    export class GroupedActivities {
        private activities: Record<ProductCategory, Record<string, CategorizedActivities>>;

        constructor() {
            this.activities = {
                [ProductCategory.LOAD_ANALYTICS]: {},
                [ProductCategory.HVAC_ANALYTICS]: {},
                [ProductCategory.OPTIMIZATION_STRATEGY]: {},
                [ProductCategory.ALERT]: {},
                [ProductCategory.NOTE]: {},
                [ProductCategory.OTHER]: {}
            };
        }

        public remove(category: ProductCategory) {
            if (category && category in this.activities) {
                delete this.activities[category];
            }
            return this;
        }

        public add(category: ProductCategory, key: string, value: SimpleActivityResponse, mode: aq.ui.ActivityMode): GroupedActivities {
            if (
                value.context
                && value.context.data
                && value.context.data.selection
                && (value.context.data.selection.minValue < 1 || value.context.data.selection.maxValue < 1)
            ) {
                return this;
            }
            if (category in this.activities && key in this.activities[category]) {
                this.activities[category][key].add(value, mode);
            } else {
                this.activities[category][key] = new CategorizedActivities();
                this.activities[category][key].add(value, mode);
            }
            return this;
        }

        public removeEmpty() {
            Object.keys(this.activities)
                .forEach((category) => {
                    if (Object.keys(this.activities[category]).length === 0) {
                        return delete this.activities[category];
                    }
                    Object.keys(this.activities[category]).forEach((key) => {
                        if (this.activities[category][key] === void 0 || this.activities[category][key].isEmpty()) {
                            delete this.activities[category][key];
                        }
                    });
                });
            return this;
        }

        public isEmpty(): boolean {
            return Object.keys(this.activities).length <= 0;
        }

        public getClassification(activity: SimpleActivityResponse): Category {
            const { context: { type }, title: _title } = activity;
            const title = _title.toLowerCase();
            const defaultCat = new Category(ProductCategory.OTHER, 'Other');
            switch (activity.type) {
                case ActivityType.OBSERVATION:
                    if (ActivityContextType.AID_ISSUE === type) {
                        if (title.includes('weekend')) {
                            return new Category(ProductCategory.LOAD_ANALYTICS, 'Weekend Run');
                        }
                        if (title.includes('nighttime')) {
                            return new Category(ProductCategory.LOAD_ANALYTICS, 'Nighttime Run');
                        }
                        if (title.includes('holiday')) {
                            return new Category(ProductCategory.LOAD_ANALYTICS, 'Holiday Run');
                        }
                        if (title.includes('unoccupied')) {
                            return new Category(ProductCategory.LOAD_ANALYTICS, 'Unoccupied Hour Run');
                        }
                    }
                    if (ActivityContextType.ENERGY_NOTE === type) {
                        return new Category(ProductCategory.NOTE, 'Note');
                    }
                    break;
                case ActivityType.SUGGESTION:
                    if (ActivityContextType.OPTIMAL_START === type) {
                        return new Category(ProductCategory.OPTIMIZATION_STRATEGY, 'Optimal Start');
                    }
                    return new Category(ProductCategory.OTHER, 'Other');
                case ActivityType.ISSUE:
                    if (type === 'RX') {
                        return new Category(ProductCategory.HVAC_ANALYTICS, this.getPrescriptionGroup(activity));
                    }
                    return new Category(ProductCategory.ALERT, _.getTitle(activity.context.acknowledgedStatus));
                default: return defaultCat;
            }
            return defaultCat;
        }

        public groupByClassification(
            filter: ActivityFilter,
            activities: SimpleActivityResponse[],
            annualizedSavings: SavingsByAidType,
            mode: aq.ui.ActivityMode,
            building: aq.common.models.Building
        ): GroupedActivities {
            activities.forEach((activity) => {
                const { category, key } = this.getClassification(activity);
                if (category === ProductCategory.HVAC_ANALYTICS) {
                    activity.title = this.getPrescriptionTitle(activity);
                } else if (this.isAnnualizedSavingsCategory(category)) {
                    // TODO this should be expanded to include sub categories for HVAC and Optimal Start
                    let savingsKey: string;
                    switch (key) {
                        case 'Weekend Run':
                            savingsKey = aq.models.aidIssue.EventType.WEEKEND_RUN;
                            break;
                        case 'Nighttime Run':
                            savingsKey = aq.models.aidIssue.EventType.NIGHTTIME_RUN;
                            break;
                        case 'Holiday Run':
                            savingsKey = aq.models.aidIssue.EventType.HOLIDAY_RUN;
                            break;
                        case 'Unoccupied Hour Run':
                            savingsKey = aq.models.aidIssue.EventType.UNOCCUPIED_HOUR_RUN;
                            break;
                        default:
                            break;
                    }
                    if (annualizedSavings) {
                        if (savingsKey && annualizedSavings[savingsKey]) {
                            if (mode === aq.ui.ActivityMode.CARD) {
                                activity.savingsProfile = annualizedSavings[savingsKey]['annualizedSavings'] as AnnualizedSavings;
                            } else {
                                activity.savingsProfile = annualizedSavings[savingsKey][activity.id] as AnnualizedSavings;
                            }
                        }
                    }
                }
                this.add(category, key, activity, mode);
            });

            // TODO HACKS ON HACKS ON HACKS
            if (this.activities[ProductCategory.HVAC_ANALYTICS]['Uncategorized Prescription']) {
                delete this.activities[ProductCategory.HVAC_ANALYTICS]['Uncategorized Prescription'];
            }

            // sort the resulting categories by creation date
            this.sort(mode, building.fiscalStartMonth);
            return this;
        }

        public sort(mode: aq.ui.ActivityMode, fiscalStartMonth: number) {
            Object.keys(this.activities)
                .forEach((category) => {
                    Object.keys(this.activities[category])
                        .forEach((key) => {
                            const val = this.activities[category][key];
                            if (val && !val.isEmpty()) {
                                this.activities[category][key]
                                    .sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt))).reverse();
                                // TODO this is a hack to get HVAC savings to be annualized for the 10/4 demo. Future work
                                // should remove this and make it so HVAC annualized savings are returned in the same way
                                // Load analytics savings are
                                if (mode === aq.ui.ActivityMode.CARD && category === ProductCategory.HVAC_ANALYTICS) {
                                    this.activities[category][key].annualizedSavings(fiscalStartMonth);
                                }
                            }
                        });
                });
        }

        public getActivities() {
            return this.activities;
        }

        public isAnnualizedSavingsCategory(category: ProductCategory): boolean {
            const annualizedSavingsCategories = this.getAnnualizedSavingsCategories();
            return annualizedSavingsCategories.includes(category);
        }

        private getAnnualizedSavingsCategories(): ProductCategory[] {
            return [
                ProductCategory.HVAC_ANALYTICS,
                ProductCategory.LOAD_ANALYTICS,
                ProductCategory.OPTIMIZATION_STRATEGY
            ];
        }

        private getPrescriptionGroup(activity: SimpleActivityResponse): string {
            if (activity.context && activity.context.contextObject) {
                // eslint-disable-next-line @typescript-eslint/camelcase
                const { diagnosis_category, device_id, system_type } = activity.context.contextObject;
                // eslint-disable-next-line @typescript-eslint/camelcase
                if (system_type) {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    return system_type;
                }
                // eslint-disable-next-line @typescript-eslint/camelcase
                if (device_id) {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    return device_id.replace('_', ' ');
                }
                // eslint-disable-next-line @typescript-eslint/camelcase
                if (diagnosis_category) {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    return diagnosis_category;
                }
            }
            return 'Uncategorized Prescription';
        }

        private getPrescriptionTitle(activity: SimpleActivityResponse): string {
            if (activity.context.contextObject) {
                // eslint-disable-next-line @typescript-eslint/camelcase
                const { device_id } = activity.context.contextObject;
                // eslint-disable-next-line @typescript-eslint/camelcase
                if (device_id) {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    return `${device_id.replace('_', ' ')}: ${activity.title}`;
                }
            }
            return activity.title;
        }
    }

    export enum MediaType {
        PDF = 'PDF',
        IMAGE = 'IMAGE',
        VIDEO = 'VIDEO',
        AUDIO = 'AUDIO',
        EXCEL = 'EXCEL'
    }

    export class MediaResponse {
        public uri: string;
        public type: MediaType;
    }

    export class ActivityMessageAuthor {
        public id: number; // uuid
        public firstName: string;
        public lastName: string;
        public userName?: string;
        public image?: string;
        public persona?: string;
        public title?: string;
    }

    export class AlertIssue {
        public issueId?: string; // uuid
        public buildingId: number;
        public alertId: number;
        public openedOn: string;
        public closedOn: string;
        public violations: number;
        public escalations: number;

    }

    export class ActivityMessage {
        public id: string; // uuid
        public parent: string; // uuid
        public authorId: number;
        public author: ActivityMessageAuthor;
        public createdAt: string;
        public updatedAt: string;
        public body: string;
        public media: MediaResponse[];
    }

    export class ActivityResponse {
        public building: number;
        public id: number;
        public title: string;
        public type: ActivityType;
        public context: IActivityContext;
        public createdAt: string;
        public updatedAt: string;
        public messages: ActivityMessage[]; // count of messages
    }

    export class ActivityMessageDraft {
        id?: string;
        body: string;
        author: number;
        media?: string[];
    }

    export class ActivityMessageCreateRequest {
        id?: string;
        body: string;
        author: number;
        media?: MediaResponse[];
    }
}
