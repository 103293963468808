namespace aq.projectCenter {
   export class ProjectDetailResultsCtrl {
        private resultsChartConfig;
        private selectedUtility: aq.common.models.Measure;
        private unit;
        private chartMeasures: aq.common.models.Measure[];

       /* @ngInject */
        constructor(
            private project: Project,
            private building: aq.common.models.Building,
            private measures: aq.common.models.SimpleEnum,
            private DataService,
            private units: aq.common.models.Unit[],
            private projectResults: ProjectResults,
            private $filter: ng.IFilterService,
            private RestangularV3: restangular.IService,
            private $q: ng.IQService
        ) {
            this.unit = _.find(units, {value: 'KW'});
            this.chartMeasures = _.filter(this.measures, {utility: true});
            this.selectedUtility = this.chartMeasures[0]; // _.find(measures, {name: 'electricity'});
            this.fetchResultsConfig(this.project);
        }

        getUtilitySavings(utility) {
            return _.find(this.projectResults.savingsPerUtility, {utility: utility.name});
        }

        private fetchResultsConfig(project: Project) {
            if (this.selectedUtility.name === 'Utility Spending') {
                return this.$q.all([
                    this.project.customGET('data', {
                        start: moment(this.project.basePeriodStartDate).format(),
                        end: moment(this.project.reportingPeriodEndDate).format(),
                        unit: 'Expected Utility Spending'}),
                    this.project.customGET('data', {
                        start: moment(this.project.basePeriodStartDate).format(),
                        end: moment(this.project.reportingPeriodEndDate).format(),
                        unit: 'Actual Utility Spending'
                    })
                ])
                .then((result) => {
                    this.resultsChartConfig = this.buildChartConfig(this.project, result[0], result[1]);
                });
            }
        }

        private buildChartConfig(project: Project, expectedData: aq.common.models.DataResponse, actualData: aq.common.models.DataResponse) {
            const timeZoneId = this.building.timeZoneId;
            const expectedSeriesArea = this.buildExpectedSeriesArea(project, expectedData, actualData);
            const actualSeries = this.buildActualSeries(project, actualData);
            const expectedSeriesLine = this.buildExpectedSeriesLine(project, expectedData, actualData);
            return {
                chart: {
                    plotBorderWidth: 1
                },
                lang: {
                    noData: ''
                },
                title: {
                    text: ''
                },
                tooltip: {
                    formatter() {
                        return `${this.series.name}<br>${moment(this.point.x).format('MMM')} - $${this.point.y}`;
                    }
                },
                xAxis: {
                    type: 'datetime',
                    tickLength: 0,
                    gridLineWidth: 1,
                    gridLineColor: '#c7c7c7',
                    labels: {
                        formatter() {
                            return moment(this.value).add(1, 'day').format('MMM');
                        }
                    },
                    plotLines: [{
                        color: 'gray',
                        dashStyle: 'longdash',
                        value: moment(this.project.reportingPeriodStartDate).valueOf(),
                        width: 1,
                        label: {
                            text: 'Reporting Period',
                            verticalAlign: 'middle',
                            textAlign: 'center'
                        }
                    }]
                },
                yAxis: {
                    title: {
                        text: 'Spending ($)'
                    },
                    min: 0,
                    gridLineColor: '#c7c7c7',
                    gridLineWidth: 1
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: false
                        }
                    }
                },
                plotOptions: {
                    line: {
                        animation: false
                    },
                    series: {
                        events: {
                            legendItemClick: () => {
                                return false;
                            }
                        },
                        stickyTracking: false
                    }
                },
                series: [expectedSeriesArea, expectedSeriesLine, actualSeries]
            };
        }

        private buildExpectedSeriesLine(project: Project, expectedData: aq.common.models.DataResponse, actualData: aq.common.models.DataResponse) {
            const dataSeries = {
                name: `Expected Utility Cost`,
                data: [],
                color: '#0091f1',
                zIndex: 2,
                dashStyle: 'shortdot',
                type: 'line'
            };
            expectedData.data.forEach(datum => {
                if (datum.value !== 0) {
                    const expectedTimestamp = moment(datum.timestamp).startOf('day').add(1, 'day').valueOf();
                    dataSeries.data.push({
                        x: expectedTimestamp,
                        y: datum.value ? Math.round(datum.value) : 0
                    });
                }
            });
            return dataSeries;
        }

        private buildExpectedSeriesArea(project: Project, expectedData: aq.common.models.DataResponse, actualData: aq.common.models.DataResponse) {
            const dataSeries = {
                name: `Savings`,
                data: [],
                color: '#7ACD46',
                zIndex: 2,
                type: 'arearange'
            };
            expectedData.data.forEach(datum => {
                const expectedTimestamp = datum.timestamp; // moment(datum.timestamp).startOf('day').add(1, 'day').valueOf();
                const actualDatum = _.find(actualData.data, {timestamp: expectedTimestamp});
                if (actualDatum && actualDatum.value !== 0) {
                    dataSeries.data.push([expectedTimestamp, Math.round(actualDatum.value), Math.round(datum.value)]);
                }
            });
            return dataSeries;
        }

        private buildActualSeries(project: Project, actualData: aq.common.models.DataResponse){
            const dataSeries = {
                name: `Actual Utility Cost`,
                data: [],
                color: '#0091f1',
                zIndex: 2
            };
            actualData.data.forEach(datum => {
                if (datum.value !== 0) {
                   dataSeries.data.push({
                       x: moment(datum.timestamp).startOf('day').valueOf(),
                       y: datum.value ? Math.round(datum.value) : 0
                   });
                }
            });
            return dataSeries;
        }
    }
    angular
    .module('projectCenter')
    .controller('ProjectDetailResultsCtrl', ProjectDetailResultsCtrl);
}
