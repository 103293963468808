namespace aq.propertySettings {

    export class SwitchGear {

        public uses;
        public update;
        public droppedOnCircuit;
        public sources;
        public configure;

        private loads;
        private isReadOnly;
        private filteredUses;

        /* @ngInject */
        constructor(
            private Auth: aq.services.Auth,
            private $scope: ng.IScope
        ) {
            this.isReadOnly = !Auth.check({access: 'EDIT'});
            this.filteredUses = this.uses.filter((use) => use.name);
            this.$scope.$watch('model.circuits', (circuits) => {

                if (!circuits) return;

                this.loads = _.filter(circuits, function (c) {
                    return c.type == 'LOAD';
                });

            });

            this.$scope.onDrop = (event) => {
                this.droppedOnCircuit({ event });
            };

        }
    }
    angular.module('properties').directive('switchGear', ()  => {
        return {
            restrict: 'E',
            replace: true,
            transclude: true,
            bindToController: {
                uses: '=',
                update: '&',
                droppedOnCircuit: '&',
                sources: '=',
                configure: '='
            },
            scope: {
                model: '='
            },
            templateUrl: 'app/properties/sources/details/common/switchGear/switchGear.html',
            controller: SwitchGear,
            controllerAs: 'vm'
        };
    });
}
