var aq;
(function (aq) {
    var ui;
    (function (ui) {
        var ActivityActions = aq.models.segment.ActivityActions;
        var ActivityModal = /** @class */ (function () {
            /* @ngInject */
            function ActivityModal(Messages, $mdDialog, ActivityService, PrescriptionService, resolver, simpleActivityResponse, activityId, accountId, buildingId, $scope, Segment, UserService, refreshActivities) {
                this.Messages = Messages;
                this.$mdDialog = $mdDialog;
                this.ActivityService = ActivityService;
                this.PrescriptionService = PrescriptionService;
                this.resolver = resolver;
                this.simpleActivityResponse = simpleActivityResponse;
                this.activityId = activityId;
                this.accountId = accountId;
                this.buildingId = buildingId;
                this.$scope = $scope;
                this.Segment = Segment;
                this.UserService = UserService;
                this.refreshActivities = refreshActivities;
            }
            ActivityModal.prototype.$onInit = function () {
                this.getActivity();
                this.unsavedChanges = false;
                var localThis = this;
                this.$scope.$on('ActivityContextLoaded', function (evt, data) {
                    localThis.setActivityContextObject(data);
                });
            };
            ActivityModal.prototype.setUnsavedChanges = function (value) {
                this.unsavedChanges = value;
            };
            ActivityModal.prototype.getActivity = function () {
                var _this = this;
                var activityId;
                if (this.simpleActivityResponse !== null) {
                    activityId = this.simpleActivityResponse.id;
                }
                else if (this.activityId) {
                    activityId = this.activityId;
                }
                var trackedEvent = {
                    account: this.accountId,
                    building: this.buildingId,
                    user: this.UserService.currentUser.id
                };
                if (activityId) {
                    this.resolver(function () { return __awaiter(_this, void 0, void 0, function () {
                        var _a, _b, type, contextType;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    _a = this;
                                    return [4 /*yield*/, this.ActivityService.getActivity(activityId)];
                                case 1:
                                    _a.activity = _c.sent();
                                    _b = this.activity, type = _b.type, contextType = _b.context.type;
                                    if (contextType === 'ALERT') {
                                        this.Segment.trackChurnZeroEvent(ActivityActions.ACKNOWLEDGE_ALERT, [this.buildingId]);
                                    }
                                    else {
                                        this.Segment.trackChurnZeroEvent(ActivityActions.VIEW_ACTIVITY, [this.buildingId]);
                                    }
                                    this.$scope.$apply();
                                    return [2 /*return*/];
                            }
                        });
                    }); });
                }
                else {
                    // Rx's do not natively get ActivityId because they're pulled from external source
                    var _a = this.simpleActivityResponse, id = _a.id, title = _a.title, type = _a.type, context = _a.context, createdAt = _a.createdAt, updatedAt = _a.updatedAt;
                    var prescription = context.contextObject && context.contextObject;
                    var case_comments = prescription.case_comments;
                    var messages = case_comments.map(function (cc) {
                        var result = {
                            id: null,
                            parent: null,
                            authorId: null,
                            author: {
                                id: null,
                                firstName: cc.created_by.first_name,
                                lastName: cc.created_by.last_name,
                                userName: _this.PrescriptionService.getUserDisplayName(cc.created_by)
                            },
                            createdAt: cc.created,
                            updatedAt: cc.updated,
                            body: cc.content,
                            media: []
                        };
                        return result;
                    });
                    this.activity = {
                        building: this.buildingId,
                        id: parseInt(id),
                        title: title,
                        type: type,
                        context: context,
                        createdAt: createdAt,
                        updatedAt: updatedAt,
                        messages: messages
                    };
                    var contextType = context.type;
                    this.Segment.trackChurnZeroEvent(ActivityActions.VIEW_ACTIVITY, [this.buildingId]);
                }
            };
            ActivityModal.prototype.error = function (message) {
                this.Messages.error(message);
            };
            ActivityModal.prototype.success = function (message) {
                this.Messages.success(message);
            };
            ActivityModal.prototype.warn = function (message) {
                this.Messages.warn(message);
            };
            ActivityModal.prototype.hide = function (result) {
                this.$mdDialog.hide(result);
            };
            ActivityModal.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            ActivityModal.prototype.callback = function () {
                this.getActivity();
                this.unsavedChanges = false;
            };
            ActivityModal.prototype.closeDialog = function () {
                var _this = this;
                if (this.unsavedChanges) {
                    this.Messages.prompt('You have unsaved changes', function () { return _this.cancel(); });
                }
                else {
                    this.cancel();
                }
            };
            ActivityModal.prototype.getModalTitle = function () {
                var activity = this.activity || this.simpleActivityResponse;
                if (!activity.title) {
                    return 'Activity';
                }
                var contextTypeStr = this.ActivityService.getContextTypeStringForActivity(activity);
                return (contextTypeStr && contextTypeStr + ": " + activity.title) || activity.title;
            };
            ActivityModal.prototype.setActivityContextObject = function (data) {
                if (!data)
                    return;
                this.activityContextObject = data;
                this.$scope.$apply();
            };
            ActivityModal.prototype.allowMessageReplies = function () {
                var type = this.activity.context.type;
                if (type && aq.models.activity.ActivityContextType.RX === type) {
                    return false;
                }
                return true;
            };
            return ActivityModal;
        }());
        ui.ActivityModal = ActivityModal;
        angular
            .module('aq.ui')
            .controller('activityModal', ActivityModal);
    })(ui = aq.ui || (aq.ui = {}));
})(aq || (aq = {}));
