namespace aq.deviceManagement {

    import DeviceClassName = aq.common.models.DeviceClassName;
    import DeviceExtraFields = aq.common.models.DeviceExtraFields;
    import DeviceClassNameByCategory = aq.common.models.DeviceClassNameByCategory;

    export class BulkUploadCtrl extends aq.common.Controllers.ModalCtrl {
        public uploadData: string;
        public newDeviceList = {
            name: '',
            images: [],
            building: null,
            description: '',
            location: '',
            locationTag: null,
            viewOnly: {
                tenants: []
            },
            tenantLinks: [],
            deviceCategory: null,
            deviceClass: {
                make: '',
                series: '',
                model: ''
            },
            serialNumber: null,
            fields: {}
        };
        private measures: Array<aq.common.models.DeviceMeasure>;
        private measure: string = null;
        private step: number = 1;
        private query: string;
        private previewResponse: any[];
        private fileUploaded: boolean;
        /* @ngInject */
        constructor(
            private Auth: aq.services.Auth,
            public $mdDialog: ng.material.IDialogService,
            private $mdStepper,
            private deviceCategories: Array<IDeviceCategories>,
            private Messages: aq.services.Messages,
            private classesByCategory: DeviceClassNameByCategory,
            private tenants: any[],
            private buildingId: string,
            private RestangularV3: restangular.IService,
            private building: aq.common.models.Building,
            private DeviceService: aq.services.DeviceService,
        ) {
            super({}, $mdDialog);

            this.measures = this.extractMeterMeasuresFromClassesByCategory(classesByCategory);
            this.newDeviceList.building = buildingId;
        }

        filterName(query: string): (item: DeviceClassName) => boolean {
            return (item) => {
                return (item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0);
            };
        }

        filterMeasure(): (item: DeviceClassName) => boolean {
            return(item) => {
                if (this.measure != null) {
                    return item.measure
                        ? item.measure.toLowerCase() === this.measure.toLowerCase()
                        : false;
                }
                return true;
            };
        }

        filterDeviceClassName(query: string): Array<DeviceClassName> {
            return this.classesByCategory[this.newDeviceList.deviceCategory]
                .filter(this.filterMeasure())
                .filter(this.filterName(query));
        }

        selectClass(deviceClassName: DeviceClassName): void {
            if (deviceClassName) {
                this.newDeviceList.deviceClass = _.pick(deviceClassName, ['make', 'model', 'series']);
            }
        }

        public cancel(data?): void {
            this.$mdDialog.cancel({tenants: this.tenants, data});
        }

        public hide(data?): void {
            this.$mdDialog.hide({tenants: this.tenants, data});
        }

        public next(): void {
            if (this.step === 2 && !this.hasDeviceClass(this.newDeviceList.deviceCategory)) {
                this.step++;
            }
            this.$mdStepper('bulkUploadStepper').goto(this.step++);
        }

        public hasDeviceClass(deviceCategory: string): boolean {
            return !deviceCategory || ['METER', 'NETWORKING', 'SENSOR'].indexOf(deviceCategory) >= 0;
        }

        public extractMeterMeasuresFromClassesByCategory(
            classesByCategory: DeviceClassNameByCategory): Array<aq.common.models.DeviceMeasure> {
            const measures = new Set(classesByCategory['METER']
                .map(deviceClass => deviceClass.measure)
                .filter(measure => measure));
            return Array.from(measures);

        }

        public isAddingMeter(): boolean {
            return this.newDeviceList.deviceCategory === 'METER';
        }

        public getDeviceCategories(): Array<IDeviceCategories> {
            return this.deviceCategories;
        }

        public getFields(): void {
            const deviceClassForRequest = _.pick(this.newDeviceList.deviceClass, ['make', 'model', 'series']);

            this.RestangularV3.all('devices').customGET('device-class', deviceClassForRequest).then(
                (response: aq.common.models.DeviceClass) => {
                    Object.keys(response.fields).forEach((key) => {
                        if (response.fields[key].hasOwnProperty('value')) {
                            this.newDeviceList.fields[key] = { value: response.fields[key].value };
                        } else {
                            this.newDeviceList.fields[key] = response.fields[key];
                        }
                    });
                }
            ).catch((err) => {
                this.Messages.error('Cannot find fields for device class');
            });
        }

        public downloadTemplate() {
            const csvColumns = ['name', 'serialNumber', ...Object.keys(this.newDeviceList.fields)];

            let csvContent = 'data:text/csv;charset=utf-8,';
            csvContent += `${csvColumns}\n`;
            const fileName = `${this.building.name} - ${this.newDeviceList.deviceClass.make} ${this.newDeviceList.deviceClass.series} ${this.newDeviceList.deviceClass.model} upload`;
            const link = document.createElement('a');
            link.setAttribute('href', encodeURI(csvContent));
            link.setAttribute('download', fileName);
            link.click();
        }

        upload() {
            this.fileUploaded = true;
        }

        public generatePreview() {
            const lines = this.uploadData.split(/\r\n|\n|\r/);
            const result = [];
            const headers = lines[0].split(',');

            lines.shift();

            lines.forEach((line) => {
                const obj = {};
                const splitLine = line.split(',');
                for (let j = 0; j < headers.length; j++) {
                    obj[`${headers[j]}`] = splitLine[j];
                }
                result.push(obj);
            });
            this.generateDeviceCreationRequestObjects(result);
        }

        public generateDeviceCreationRequestObjects(json) {
            this.previewResponse = json.map((device) => {
                if (device.name === '') {
                    this.$mdDialog.hide();
                    this.Messages.error('Missing required field');
                    throw new Error('Missing required field');
                }
                const newDevice = JSON.parse(JSON.stringify(this.newDeviceList))
                newDevice.name = device['name'];
                newDevice.serialNumber = device['serialNumber'];
                Object.keys(newDevice.fields).forEach((key) => {
                    newDevice.fields[key].value = device[key];
                });
                return newDevice;
            });
            this.createDevices(this.previewResponse);
        };

        public createDevices(devices) {
            return this.DeviceService.bulkCreate(devices).then((response) => {
                this.$mdDialog.hide();
                this.Messages.success('Successfully created devices');
            }).catch((error) => {
                this.$mdDialog.hide();
                if (error.status === DeviceDetail.HTTP_CODE_CONSTRAINT_VIOLATION) {
                    this.Messages.error(error.data);
                } else {
                    this.Messages.error('Error creating devices');
                }
            });
        }
    }

    angular
        .module('deviceManagement')
        .controller('BulkUploadCtrl', BulkUploadCtrl);
}
