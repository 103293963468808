// configuration objects for preset values for various chart configuration options
var aq;
(function (aq) {
    var dashboard;
    (function (dashboard) {
        var widgets;
        (function (widgets) {
            var HighChartConfig = aq.highcharts.HighChartConfig;
            var GraphCtrl = /** @class */ (function () {
                /* @ngInject */
                function GraphCtrl($scope, account, buildings, tenants, config, $filter, DashboardOptionsService, GraphData, Auth, meter, uses, source, space, tenant, $timeout, ModelUtilService, GraphEditService, $translate, WidgetHelperService) {
                    var _this = this;
                    this.$scope = $scope;
                    this.account = account;
                    this.buildings = buildings;
                    this.tenants = tenants;
                    this.config = config;
                    this.$filter = $filter;
                    this.DashboardOptionsService = DashboardOptionsService;
                    this.GraphData = GraphData;
                    this.Auth = Auth;
                    this.meter = meter;
                    this.uses = uses;
                    this.source = source;
                    this.space = space;
                    this.tenant = tenant;
                    this.$timeout = $timeout;
                    this.ModelUtilService = ModelUtilService;
                    this.GraphEditService = GraphEditService;
                    this.$translate = $translate;
                    this.WidgetHelperService = WidgetHelperService;
                    this.isLoadingData = true;
                    var series = {
                        'selected building': null,
                        meter: meter,
                        uses: uses,
                        source: source,
                        space: space,
                        tenant: null,
                        customTrend: null
                    };
                    if (Auth.check({ appName: 'Tenant Billing' })) {
                        series.tenant = tenant;
                    }
                    // fix: existing widgets show legend by default, but checkbox is not checked
                    if (angular.equals(config, {}) || !config.legend) {
                        config.legend = { enabled: true };
                    }
                    config.options = {
                        currentNavItem: 'basic',
                        graphTypes: GraphTypes.getGraphTypes(),
                        timePresets: TimePresets.getPresets(),
                        series: series,
                        xAxisFormat: DateFormats.getDateFormats(),
                        trendPeriods: _.concat(['none'], TrendPeriods.getTrends()),
                        buildings: ModelUtilService.pareProperties(buildings, ['account']),
                        colors: [
                            {
                                value: 'red',
                                hex: '#E70F28'
                            },
                            {
                                value: 'orange',
                                hex: '#F1A81D'
                            },
                            {
                                value: 'yellow',
                                hex: '#FFDA06'
                            },
                            {
                                value: 'green',
                                hex: '#7ACD46'
                            },
                            {
                                value: 'blue',
                                hex: '#0091F1'
                            },
                            {
                                value: 'purple',
                                hex: '#C148B0'
                            },
                            {
                                value: 'dark gray',
                                hex: '#4D4D4D'
                            },
                            {
                                value: 'gray',
                                hex: '#979797'
                            },
                            {
                                value: 'custom',
                                hex: ''
                            }
                        ],
                        presetColorWeather: '#EE6C4D',
                        presetColorExpectedEnergy: '#BCDDE2',
                        measures: [],
                        allMeasures: []
                    };
                    config.actions = GraphEditService;
                    config.yAxis = config.yAxis || [];
                    this.cleanupEmptyYAxis(config);
                    // set defaults for required properties
                    if (!config.interval) {
                        config.interval = '1d';
                    }
                    if (!config.series) {
                        config.series = [];
                        config.preset = TimePresets.defaultPreset();
                        config.series[0] = GraphTemplates.getDefaultTemplate();
                    }
                    config.series.forEach(function (s) {
                        s.colorOptions = config.actions.buildColorOptionsForSeries(s, config);
                        // hacky fix for getting the correct titles for Tishman Speyer Demo see:
                        // https://aquicore.atlassian.net/browse/AQ-10692
                        if (s.measure === 'WET_BULB') {
                            s.title = 'Wet Bulb (°F)';
                        }
                        if (s.measure === 'PERCENT') {
                            s.title = 'Outdoor Humidity (%)';
                        }
                        if (!s.title) {
                            s.title = config.actions.getMeasureLabel(s.measure);
                        }
                        if (!s.drillin) {
                            s.drillin = '';
                            s.drillinView = 'selected building';
                        }
                        else {
                            s.drillinView = s.drillin;
                        }
                        if (s.yAxis == null || s.yAxis >= config.yAxis.length) {
                            s.yAxis = config.actions.findOrCreateYAxisForMeasure(s.measure, config);
                        }
                    });
                    config.view = this.getConfigView(config);
                    this.building = _.find(buildings, { id: parseInt(config.buildingId) }) || buildings[0];
                    config.buildingId = this.building.id;
                    this.$scope.config = config;
                    this.$scope.config.series.forEach(function (series) {
                        if (series.drilldown || (series.drilldownId && series.drillin)) {
                            series.drilldown = series.drilldown ? series.drilldown
                                : _.find(_this.$scope.config.options.series[series.drillin][_this.$scope.config.buildingId], { id: parseInt(series.drilldownId) });
                        }
                    });
                    this.isBuildingInSetup = this.WidgetHelperService.isBuildingInSetup(this.building);
                    this.DashboardOptionsService.init(account.id, account.measurementSystem, account.currencyUnit).then(function () {
                        _this.setMeasures();
                    });
                    this.drawGraph();
                }
                GraphCtrl.prototype.isSFMeasure = function (measure) {
                    return _.includes(measure, 'SQFT') || _.includes(measure, 'AREA');
                };
                GraphCtrl.prototype.cleanupEmptyYAxis = function (cfg) {
                    var _loop_1 = function (i) {
                        if (!_.some(cfg.series, function (s) { return s.yAxis == i; })) {
                            var removeIndex_1 = i;
                            cfg.yAxis.splice(removeIndex_1, 1);
                            _.each(cfg.series, function (s) {
                                if (s.yAxis > removeIndex_1) {
                                    s.yAxis--;
                                }
                            });
                        }
                    };
                    for (var i = cfg.yAxis.length - 1; i >= 0; i--) {
                        _loop_1(i);
                    }
                };
                GraphCtrl.prototype.getConfigView = function (cfg) {
                    var mainMeasureSeries = _.filter(cfg.series, function (s) { return !s.trend && !s.expected; });
                    var isShowTrend = mainMeasureSeries.length > 0 && _.every(mainMeasureSeries, function (main) { return _.find(cfg.series, function (s) { return s.trend && s.measure == main.measure; }); });
                    var isPredictedEnergy = false;
                    var mainSeriesKW = _.find(cfg.series, function (s) { return s.measure == 'KW' && !s.expected; });
                    if (mainSeriesKW) {
                        var expectedSeriesKW = _.find(cfg.series, function (s) { return s.measure == 'KW' && s.expected; });
                        if (expectedSeriesKW && cfg.preset !== 'trailing year') {
                            isPredictedEnergy = true;
                        }
                    }
                    var isShowWeather = _.some(cfg.series, function (s) { return s.measure == 'F' && !s.trend; });
                    return {
                        isShowWeather: isShowWeather,
                        isShowTrend: isShowTrend,
                        isPredictedEnergy: isPredictedEnergy,
                        expandedItem: null,
                        selectedYAxis: cfg.yAxis[0],
                        newMeasure: 'NONE'
                    };
                };
                GraphCtrl.prototype.calculateTitleAndAxis = function (series) {
                    // date format is user-specified or determined by the chart interval
                    var dateFormat = this.$scope.config.xAxisFormat ? this.$scope.config.xAxisFormat : this.$scope.momentDateFormat;
                    if (series && series.length > 0) {
                        // configure title based on start and end dates
                        if (series[0].data && series[0].data.length > 0) {
                            var noRangeSeries = _.filter(series, function (s) {
                                return s.type != 'arearange';
                            });
                            if (noRangeSeries && noRangeSeries.length > 0) {
                                this.formatDates(noRangeSeries, dateFormat);
                                this.configureTitle(noRangeSeries);
                            }
                            this.configurePlotlines(series, dateFormat);
                        }
                    }
                };
                GraphCtrl.prototype.configureTitle = function (series) {
                    if (this.$scope.config.title) {
                        this.$scope.title = this.$scope.config.titleText;
                    }
                    else {
                        this.$scope.title = null;
                    }
                };
                GraphCtrl.prototype.formatDates = function (series, dateFormat) {
                    if (dateFormat == 'MMM DD') {
                        dateFormat = this.$translate.instant("dates." + dateFormat);
                    }
                    this.$scope.xAxisLabels = _.map(series[0].data, function (d) {
                        return d.rawTime.format(dateFormat);
                    });
                };
                GraphCtrl.prototype.configurePlotlines = function (series, dateFormat) {
                    var tickInterval = series[0] ? Math.round(series[0].data.length / _.intersection(this.$scope.xAxisLabels).length) : null;
                    var plotLines = [];
                    // add midnight marker to charts displaying hourly data
                    if (this.$scope.config.interval == '1h' && this.$scope.config.preset != 'trailing month' && this.$scope.xAxisLabels) {
                        this.$scope.xAxisLabels.forEach(function (date, index) {
                            if (moment(date, dateFormat).format('HH') == '00') {
                                plotLines.push({
                                    color: '#4d4d4d',
                                    value: index * (tickInterval > 0 ? tickInterval : 1),
                                    width: 1
                                });
                            }
                        });
                    }
                    this.$scope.tickInterval = tickInterval;
                    this.$scope.plotLines = plotLines;
                };
                GraphCtrl.prototype.setMeasures = function () {
                    var allNonConvertedUnits = this.DashboardOptionsService.getUnits(true);
                    var nonBuildingUnits = _.filter(allNonConvertedUnits, function (unit) {
                        return !unit.isIntensity;
                    });
                    this.$scope.config.options.allMeasures = this.DashboardOptionsService.organizeUnitsByServiceType(allNonConvertedUnits);
                    this.$scope.config.options.measures = this.DashboardOptionsService.organizeUnitsByServiceType(nonBuildingUnits);
                };
                GraphCtrl.prototype.calculateTimePeriod = function () {
                    var _this = this;
                    var periodDetails = TimePresets.getPresetDetails(this.$scope.config.preset);
                    this.$scope.config.interval = periodDetails.interval;
                    this.$scope.config.series.forEach(function (series) {
                        series.end = periodDetails.end;
                        series.duration = periodDetails.duration;
                        // change interval to 1h if power is present on graph
                        if (series.measure == 'KW' && _this.$scope.config.preset != 'trailing year' && _this.$scope.config.preset != 'custom' && _this.$scope.config.preset != 'trailing hour') {
                            _this.$scope.config.interval = '1h';
                        }
                    });
                };
                GraphCtrl.prototype.configureyAxis = function () {
                    this.$scope.config.yAxis.forEach(function (axis) {
                        axis.maxPadding = 0;
                        axis.minPadding = 0;
                        axis.showLastLabel = true;
                    });
                };
                GraphCtrl.prototype.configureQueryable = function () {
                    var _this = this;
                    this.$scope.config.series.forEach(function (series) {
                        _this.calculateQueryable(series);
                    });
                };
                GraphCtrl.prototype.calculateQueryable = function (series) {
                    if (series.drilldown) {
                        var queryCursor = series.drilldown;
                        var queryable = [];
                        var parentRoute = queryCursor.route;
                        series.drilldownId = queryCursor.id;
                        // push selected queryable onto chain
                        queryable.push({
                            id: queryCursor.id,
                            route: queryCursor.route
                        });
                        while (queryCursor.parent != null) {
                            // only push a queryable onto the chain if its route is different from its parent
                            if (queryCursor.route != parentRoute) {
                                queryable.push({
                                    id: queryCursor.id,
                                    route: queryCursor.route
                                });
                            }
                            parentRoute = queryCursor.route;
                            queryCursor = queryCursor.parent;
                        }
                        _.reverse(queryable);
                        series.queryable = _.concat(this.$scope.buildingPrefix, queryable);
                    }
                    else {
                        series.queryable = this.$scope.buildingPrefix;
                    }
                };
                GraphCtrl.prototype.configureColor = function () {
                    this.$scope.config.series.forEach(function (series) {
                        if (series.presetColor) {
                            series.color = series.presetColor;
                        }
                    });
                };
                GraphCtrl.prototype.drawGraph = function () {
                    var _this = this;
                    if (this.$scope.config.preset != 'custom') {
                        this.calculateTimePeriod();
                    }
                    // if there is a custom date range, find the date range for the first series and apply it to all new series
                    else if (this.$scope.config.series[0]) {
                        this.$scope.config.series.forEach(function (series) {
                            if (!_this.$scope.config.series.end) {
                                // no time period info, so copy series[0] period
                                series.end = _this.$scope.config.series[0].end;
                                series.duration = _this.$scope.config.series[0].duration;
                            }
                        });
                    }
                    this.building = _.find(this.buildings, { id: parseInt(this.$scope.config.buildingId) }) || this.buildings[0];
                    this.$scope.config.buildingId = this.building.id; // This is in case we didn't have a building selected
                    this.$scope.buildingPrefix = [
                        {
                            id: this.account.id,
                            route: 'accounts'
                        },
                        {
                            id: this.building.id,
                            route: 'buildings'
                        }
                    ];
                    this.$scope.config.index = -1;
                    this.configureyAxis();
                    this.configureQueryable();
                    this.configureColor();
                    var period = this.GraphData.calculateGraphPeriod(this.$scope.config.series[0], this.building.timeZoneId);
                    var highChartConfig = new HighChartConfig(this.$translate);
                    this.$scope.momentDateFormat = highChartConfig.getAxisMomentDateFormatByPeriodAndInterval(period.start, period.end, this.$scope.config.interval);
                    this.$scope.titleDateFormat = highChartConfig.getMomentDateFormatByInterval(this.$scope.config.interval);
                    this.$scope.$on('abortLoading', function () {
                        _this.GraphData.abort();
                    });
                    var emptySeries = this.buildEmptyChartConfigAndGetEmptySeries();
                    var config = angular.copy(this.$scope.config);
                    var emptySeriesIndexes = [];
                    if (this.isBuildingInSetup) {
                        // storing indexes to be replaced with empty data
                        var indexMap = _.map(config.series, function (s, index) {
                            return {
                                index: index,
                                isMissing: s.drillinView == 'selected building'
                            };
                        });
                        emptySeriesIndexes = _.filter(indexMap, function (item) { return item.isMissing; });
                        // not loading data for building-level series
                        config.series = _.filter(config.series, function (s) { return s.drillinView != 'selected building'; });
                    }
                    this.GraphData.getAllSeriesData(config, { account: this.account, building: this.building, tenants: this.tenants }, this.$scope.momentDateFormat).then(function (series) {
                        var flattenedSeries = _.flatten(series);
                        emptySeriesIndexes.forEach(function (es) {
                            flattenedSeries.splice(es.index, 0, emptySeries[es.index]);
                        });
                        _this.calculateTitleAndAxis(flattenedSeries);
                        _this.dataSeries = _this.getSortedSeries(flattenedSeries);
                        if (_this.$scope.config.customExport) {
                            _this.chartConfig = _this.buildChartConfig(true, _this.$scope.config.customExport.enabled);
                        }
                        else {
                            _this.chartConfig = _this.buildChartConfig(_this.isBuildingInSetup, false);
                        }
                    }).finally(function () {
                        _this.isLoadingData = false;
                    });
                };
                GraphCtrl.prototype.buildEmptyChartConfigAndGetEmptySeries = function () {
                    // generates an empty chart with all series visible in the legend, to serve as placeholder until the data is fetched
                    // empty series for commissioning building-level data will be re-used in the final chart (no real data will be fetched for those)
                    var series = this.GraphData.getAllSeriesDataEmpty(this.$scope.config, { account: this.account, building: this.building, tenants: this.tenants }, this.$scope.momentDateFormat);
                    this.calculateTitleAndAxis(series);
                    // re-draw chart
                    this.dataSeries = this.getSortedSeries(series);
                    if (this.$scope.config.customExport) {
                        this.chartConfig = this.buildChartConfig(true, this.$scope.config.customExport.enabled);
                    }
                    else {
                        this.chartConfig = this.buildChartConfig(true, false);
                    }
                    return series;
                };
                GraphCtrl.prototype.getSortedSeries = function (series) {
                    return _.sortBy(series, function (s) {
                        var order1;
                        switch (s.type) {
                            case 'column':
                                order1 = 1;
                                break;
                            case 'arearange':
                                order1 = 2;
                                break;
                            case 'line':
                                order1 = 3;
                                break;
                            default:
                                order1 = 0;
                        }
                        var order2;
                        if (s.trend) {
                            order2 = 1;
                        }
                        else {
                            order2 = 2;
                        }
                        return order1 + "_" + order2 + "_" + s.name;
                    });
                };
                GraphCtrl.prototype.buildChartConfig = function (hideVerticalLines, exportingEnabled) {
                    var chartOptions = {};
                    if (this.$scope.config.type == 'gauge') {
                        return {
                            chart: {
                                type: 'solidgauge'
                            },
                            title: null,
                            pane: {
                                center: ['50%', '55%'],
                                size: '90%',
                                startAngle: -90,
                                endAngle: 90,
                                background: {
                                    backgroundColor: '#EEE',
                                    innerRadius: '60%',
                                    outerRadius: '100%',
                                    shape: 'arc'
                                }
                            },
                            tooltip: {
                                enabled: false
                            },
                            // the value axis
                            yAxis: {
                                stops: this.$scope.config.stops ? this.$scope.config.stops
                                    : [
                                        [0.1, '#008BF5'],
                                        [0.5, '#DDDF0D'],
                                        [0.9, '#DF5353'] // red
                                    ],
                                lineWidth: 0,
                                minorTickInterval: null,
                                tickAmount: 2,
                                labels: {
                                    y: 16
                                },
                                min: this.$scope.config.targetMin ? this.$scope.config.targetMin : 0,
                                max: this.$scope.config.targetMax ? this.$scope.config.targetMax : 100
                            },
                            plotOptions: {
                                solidgauge: {
                                    dataLabels: {
                                        y: 5,
                                        borderWidth: 0,
                                        useHTML: true
                                    }
                                }
                            },
                            credits: {
                                enabled: false
                            },
                            series: [{
                                    data: this.$scope.config.targetValue ? [this.$scope.config.targetValue] : [0],
                                    dataLabels: {
                                        format: '<div style="text-align:center"><span style="font-size:25px;color:"black">{y}</span><br/></div>'
                                    }
                                }]
                        };
                    }
                    var yAxes = angular.copy(this.$scope.config.yAxis);
                    if (hideVerticalLines) {
                        _.each(yAxes, function (axis) {
                            axis.gridLineColor = 'transparent';
                        });
                    }
                    return {
                        chart: {
                            plotBorderWidth: 1,
                            height: this.$scope.config.height ? this.$scope.config.height : null
                        },
                        tooltip: {
                            formatter: function () {
                                var tooltip = "" + moment(this.point.rawTime).format('ddd, MMM DD');
                                tooltip += "<br><span style=\"color:" + this.color + "\">\u25CF</span> " + this.series.name + ": ";
                                if (this.point.options.low != null) {
                                    tooltip += "<b>" + this.point.options.low + " kW - " + this.point.options.high + " kW";
                                }
                                else {
                                    tooltip += "<b>" + this.point.tooltip;
                                }
                                if (this.point.isMissingData) {
                                    tooltip += '<br><div style="color:#ff0000">This building uses data directly <br><div style="color:red">from your utility company.';
                                    tooltip += '<br><i>Some data has not been received yet.';
                                }
                                return tooltip;
                            }
                        },
                        plotOptions: {
                            series: {
                                marker: {
                                    enabled: false
                                }
                            },
                            column: {
                                stacking: this.$scope.config.stacked ? 'normal' : null
                            }
                        },
                        title: {
                            text: this.$scope.title,
                            style: {
                                fontSize: '12px',
                                fontFamily: 'sans-serif'
                            }
                        },
                        exporting: {
                            buttons: {
                                contextButton: {
                                    enabled: exportingEnabled
                                }
                            }
                        },
                        legend: this.$scope.config.legend ? this.$scope.config.legend : { enabled: true },
                        xAxis: {
                            labels: {
                                rotation: -45
                            },
                            type: 'category',
                            categories: this.$scope.xAxisLabels,
                            tickLength: 0,
                            tickInterval: !hideVerticalLines && this.$scope.tickInterval,
                            plotLines: !hideVerticalLines && this.$scope.plotLines
                        },
                        yAxis: yAxes && yAxes.length > 0 ? yAxes : [{ min: 0, maxPadding: 0, minPadding: 0 }],
                        series: this.dataSeries
                    };
                };
                return GraphCtrl;
            }());
            widgets.GraphCtrl = GraphCtrl;
            angular.module('aq.dashboard.widgets').controller('GraphCtrl', GraphCtrl);
        })(widgets = dashboard.widgets || (dashboard.widgets = {}));
    })(dashboard = aq.dashboard || (aq.dashboard = {}));
})(aq || (aq = {}));
