namespace TimePresets {
    const trailingYearObj: DatePeriod.DatePeriod = {
        end: {
            date: 'now',
            operation: 'subtract',
            offset: 0,
            unit: 'years',
            round: 'month',
        },
        duration: {
            offset: 1,
            unit: 'years'
        },
        interval: '1mon'
    };

    const trailingMonthObj: DatePeriod.DatePeriod = {
        end: {
            date: 'now',
            operation: 'subtract',
            offset: 0,
            unit: 'months',
            round: 'day',
        },
        duration: {
            offset: 1,
            unit: 'months'
        },
        interval: '1d'
    };

    const trailingWeekObj: DatePeriod.DatePeriod = {
        end: {
            date: 'now',
            operation: 'subtract',
            offset: 0,
            unit: 'days',
            round: 'day',
        },
        duration: {
            offset: 7,
            unit: 'days'
        },
        interval: '1d'
    };

    const trailingDayObj: DatePeriod.DatePeriod = {
        end: {
            date: 'now',
            operation: 'subtract',
            offset: 1,
            unit: 'hours',
            round: 'hour',
        },
        duration: {
            offset: 1,
            unit: 'days'
        },
        interval: '1h'
    };

    const yesterdayObj: DatePeriod.DatePeriod = {
        end: {
            date: 'now',
            operation: 'subtract',
            offset: 0,
            unit: 'days',
            round: 'day',
        },
        duration: {
            offset: 1,
            unit: 'days'
        },
        interval: '1h'
    };

    const todayObj: DatePeriod.DatePeriod = {
        end: {
            date: 'now',
            operation: 'add',
            offset: 1,
            unit: 'days',
            round: 'day',
        },
        duration: {
            offset: 1,
            unit: 'days'
        },
        interval: '1h'
    };

    const trailingHourObj: DatePeriod.DatePeriod = {
        end: {
            date: 'now',
            operation: 'subtract',
            offset: 1,
            unit: 'minutes',
            round: 'minute',
        },
        duration: {
            offset: 1,
            unit: 'hours'
        },
        interval: '1min'
    };

    const thisWeekObj:DatePeriod.DatePeriod = {
        end: {
                date: 'currentweek',
                operation: '',
                offset: 1,
                unit: 'days',
                round: 'week',
            },
            duration: {
                offset: 7,
                unit: 'days'
            },
            interval: '1d'
        };

    const presets = {
        'trailing year': trailingYearObj,
        'trailing month': trailingMonthObj,
        'trailing week': trailingWeekObj,
        'trailing day': trailingDayObj,
        yesterday: yesterdayObj,
        today: todayObj,
        'trailing hour': trailingHourObj,
        'this week': thisWeekObj,
        custom: {}
    };

    export function getPresets() {
        return Object.keys(presets);
    }

    export function defaultPreset() {
        return 'trailing week';
    }

    export function getPresetDetails(preset): DatePeriod.DatePeriod {
        return presets[preset];
    }
}

namespace TrendPeriods {

    const previousYearObj: DatePeriod.Duration = {
        offset: 1,
        unit: 'years'
    };

    const previousMonthObj: DatePeriod.Duration = {
        offset: 1,
        unit: 'months'
    };

    const previousWeekObj: DatePeriod.Duration = {
        offset: 7,
        unit: 'days'
    };

    const previousDayObj: DatePeriod.Duration = {
        offset: 1,
        unit: 'days'
    };

    const previousHourObj: DatePeriod.Duration = {
        offset: 1,
        unit: 'hours'
    };


    const trends = {
        'previous year': previousYearObj,
        'previous month': previousMonthObj,
        'previous week': previousWeekObj,
        'previous day': previousDayObj,
        'previous hour': previousHourObj,
    };

    export function getTrends() {
        return Object.keys(trends);
    }

    export function getTrendPeriod(trend): DatePeriod.Duration {
        return trends[trend];
    }

    export function getTrendPeriodForDatePeriod(datePeriodPreset): string {
        switch (datePeriodPreset) {
            case 'trailing year':
                return 'previous year';
            case 'trailing month':
                return 'previous month';
            case 'trailing week':
                return 'previous week';
            case 'trailing day':
                return 'previous day';
            case 'yesterday':
                return 'previous day';
            case 'today':
                return 'previous day';
            case 'trailing hour':
                return 'previous hour';
            case 'custom':
                return 'previous day';
            case 'this week':
                return 'previous week';
            default:
                return 'previous day';
        }
    }
}

namespace Measures {
    const measures = {
        ELECTRICITY: 0,
        POWER: 1,
        WATER: 2,
        GAS: 3,
        TEMPERATURE: 4,
        STEAM: 5,
        HEAT: 6,
        CO2: 7,
        OUTDOOR_HUMIDITY: 8,
        WET_BULB: 9,
        'INDOOR TEMPERATURE': 10
    };

    export type Measures =
        'ELECTRICITY' |
        'POWER' |
        'WATER' |
        'GAS' |
        'TEMPERATURE' |
        'STEAM' |
        'HEAT' |
        'CO2' |
        'OUTDOOR HUMIDITY' |
        'WET_BULB' |
        'INDOOR TEMPERATURE';
    export function getMeasures() {
        return Object.keys(measures);
    }
}

namespace GraphTypes {
    const graphTypes = {
        line: 0,
        column: 1
    };

    export type GraphTypes =
        'line' |
        'column';

    export function getGraphTypes() {
        return Object.keys(graphTypes);
    }
}

namespace DateFormats {
    const dateFormats = [
        {
            format: 'dddd',
            display: 'Full Day of Week: (Monday...Sunday)',
            intervals: ['1d', '1h']
        },
        {
            format: 'ddd',
            display: 'Short Day of Week (Mon...Sun)',
            intervals: ['1d', '1h']
        },
        {
            format: 'MMMM',
            display: 'Full Month (January...December)',
            intervals: ['1d', '1mon']
        },
        {
            format: 'MMM',
            display: 'Short Month (Jan...Dec)',
            intervals: ['1d', '1mon']
        },
        {
            format: 'MMMM D',
            display: 'Month and Day (April 1...April 31)',
            intervals: ['1d', '1h']
        },
        {
            format: 'DD',
            display: 'Day of Month (01...31)',
            intervals: ['1d', '1h']
        },
        {
            format: 'hh:mm a',
            display: 'Hour and Minute (12:00am...11:59pm)',
            intervals: ['1h', '1min']
        }
    ];

    export function getDateFormats() {
        return dateFormats;
    }
}

namespace GraphTemplates {
    const defaultTemplate: aq.dashboard.widgets.GraphSeries = {
        measure: 'KWH',
        color: '#28CCDD',
        type: 'column',
        end: {
            date: 'now',
            operation: 'subtract',
            offset: 1,
            unit: 'days',
            round: 'day',
        },
        duration: {
            offset: 7,
            unit: 'days'
        }
    };

    export function getDefaultTemplate() {
        return defaultTemplate;
    }
}

namespace DatePeriod {
    export class DatePeriod {
        end: EndPeriod;
        duration: Duration;
        interval: string;
    }

    class EndPeriod {
        date: any;
        operation = 'subtract';
        offset = 0;
        unit = 'milliseconds';
        round = 'millisecond';
    }

    export class Duration {
        offset: number = null;
        unit: string = null;
    }
}
