angular
    .module('projectCenter', ['ngAnimate', 'aq.forms'])
    .config((RestangularProvider: restangular.IProvider, $stateProvider: ng.ui.IStateProvider) => {
        $stateProvider
            .state('aq.projectCenter', {
                abstract: true,
                url: '/accounts/:accountId/project-center',
                templateUrl: 'app/projectCenter/projectCenter.html',
                controller: 'ProjectCenterCtrl as projectCenterCtrl',
                data: { appName: 'Project Center' },
                resolve: {
                    buildings(waitForAuthToken, RestangularV3: restangular.IService) {
                        return RestangularV3.one('').getList('buildings', {showInactive: false});
                    },
                    profile(UserService: aq.services.UserService, waitForAuthToken,) {
                        return UserService.reloadUser({inflate: 'currentProfile'})
                            .then((currentUser) => {
                                return currentUser.currentProfile;
                            });
                    },
                    redirectBuildingId(RestangularV3, profile, $stateParams, buildings) {
                        const currentAccountId = profile.account;
                        let {accountId, buildingId} = $stateParams;
                        if (currentAccountId == accountId) {
                            return buildingId || (buildings.length > 0 ? buildings[0].id : null);
                        }
                        return RestangularV3.one('').getList('buildings', {showInactive: false})
                            .then((buildings) => {
                                return buildings.length > 0 ? buildings[0].id : null;
                            });
                    },
                    redirect: ($q, redirectBuildingId, profile, Auth: aq.services.Auth, RedirectService: aq.services.RedirectService) => {
                        var defer = $q.defer();
                        if (Auth.hasFunctionality('Aquicore 3.0 Projects')) {
                            const destination = `accounts/${profile.account}/buildings/${redirectBuildingId}/projects/board`;
                            RedirectService.redirect({
                                account: profile.account,
                                building: redirectBuildingId,
                                profile: profile.id,
                                destination,
                            });
                        } else {
                            defer.resolve();
                        }
                        return defer.promise;
                    },
                    accountElement(
                        $stateParams: ng.ui.IStateParamsService,
                        Restangular: restangular.IService,
                        waitForAuthToken,
                        redirect
                    ): restangular.IElement {
                        return Restangular.one('accounts', $stateParams.accountId);
                    },
                    account(
                        accountElement,
                        DataStore: aq.common.DataStore,
                        waitForAuthToken,
                        redirect
                    ): ng.IPromise<aq.common.models.Account> {
                        return DataStore.get(accountElement, {single: true}, false);
                    },
                    measures(
                        waitForAuthToken,
                        DataStore: aq.common.DataStore,
                        RestangularV3: restangular.IService,
                        redirect
                    ): ng.IPromise<aq.common.models.Measure[]> {
                        return DataStore.getList(RestangularV3.one('measures'));
                    },
                    users(DataStore: aq.common.DataStore, account, waitForAuthToken, redirect) {
                        return DataStore.getList(account, 'users', {});
                    },
                    units(OptionsService, account, redirect) {
                        return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit).then(function () {
                            return OptionsService.getAllUnits();
                        });
                    },
                    projectStatuses(waitForAuthToken, RestangularV3: restangular.IService, redirect) {
                        return RestangularV3.one('projects').one('statuses').get().then((result) => {
                            return result.originalElement;
                        });
                    },
                    projectTypes(waitForAuthToken, RestangularV3: restangular.IService, redirect) {
                        return RestangularV3.one('projects').one('types').get().then((result) => {
                            return result.originalElement;
                        });
                    }
                }
            })
            .state('aq.projectCenter.overview', {
                url: '/building/:buildingId',
                reloadOnSearch: false,
                views: {
                    'projects@aq.projectCenter': {
                        templateUrl: 'app/projectCenter/overview/projectOverview.html',
                        controller: 'ProjectOverviewCtrl as vm'
                    }
                },
                resolve: {
                    building(
                        buildings,
                        $stateParams: ng.ui.IStateParamsService,
                        $state: ng.ui.IStateService,
                        Restangular: restangular.IService,
                        waitForAuthToken,
                        redirect
                    ): aq.common.models.Building {
                        let { accountId, buildingId } = $stateParams;
                        if (!buildingId) {
                            buildingId = buildings[0].id;
                        } else {
                            Restangular.one('accounts', accountId).one('buildings', buildingId).customPOST({}, 'record');
                        }
                        return _.findById(buildings, buildingId);
                    },
                    projects(
                        ProjectService: aq.projectCenter.ProjectService,
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        building, users, projectTypes,
                        waitForAuthToken,
                        redirect
                    ): ng.IPromise<aq.projectCenter.Project[]> {
                        return ProjectService.getList(building.id, users, projectTypes);
                    },
                    users: (RestangularV3: restangular.IService, building, waitForAuthToken, redirect) => {
                        return RestangularV3.all('users/users-for-building').getList({ 'buildingId': building.id, 'showAdmin': true });
                    },
                    accountId($stateParams, redirect): ng.IPromise<aq.common.models.Account> {
                        let { accountId } = $stateParams;
                        return accountId;
                    },
                },
                onEnter(Segment) {
                    Segment.trackPage('Project Center:Overview');
                }
            })
            .state('aq.projectCenter.overview.project', {
                url: '/project/:projectId',
                views: {
                    'projects@aq.projectCenter': {
                        templateUrl: 'app/projectCenter/project/projectDetail.html',
                        controller: 'ProjectDetail as vm'
                    }
                },
                resolve: {
                    project(
                        projects,
                        building,
                        RestangularV3,
                        ProjectService: aq.projectCenter.ProjectService,
                        $stateParams,
                        users,
                        projectTypes,
                        waitForAuthToken
                    ) {
                        return ProjectService.get($stateParams.projectId, users, projectTypes);
                    }
                },
                onEnter(Segment) {
                    Segment.trackPage('Project Center:Details');
                }
            })
            .state('aq.projectCenter.overview.project.configuration', {
                url: '/configuration',
                data: {
                    redirectState: 'aq.projectCenter.overview'
                },
                templateUrl: 'app/projectCenter/project/projectDetailConfiguration.html',
                controller: 'ProjectDetailConfigurationCtrl as vm',
                onEnter(Segment) {
                    Segment.trackPage('Project Center:Details:Configuration');
                }
            })
            .state('aq.projectCenter.overview.project.tasks', {
                url: '/tasks',
                data: {
                    redirectState: 'aq.projectCenter.overview'
                },
                templateUrl: 'app/projectCenter/project/projectDetailTasks.html',
                controller: 'ProjectDetailTasksCtrl as vm',
                onEnter(Segment) {
                    Segment.trackPage('Project Center:Details:Tasks');
                }
            })
            .state('aq.projectCenter.overview.project.results', {
                url: '/results',
                data: {
                    redirectState: 'aq.projectCenter.overview'
                },
                templateUrl: 'app/projectCenter/project/projectDetailResults.html',
                controller: 'ProjectDetailResultsCtrl as vm',
                resolve: {
                    // convert to API
                    dataChartingOptions(RestangularV3: restangular.IService, project: aq.projectCenter.Project, waitForAuthToken) {
                        return []; // project.customGET('data-charting-options');
                    },
                    measures(RestangularV3: restangular.IService, DataStore: aq.common.DataStore, waitForAuthToken) {
                        return DataStore.getList(RestangularV3.one(''), 'measures');
                    },
                    projectResults(project: aq.projectCenter.Project) {
                        return project.customGET('results');
                    },
                    onEnter(Segment) {
                        Segment.trackPage('Project Center:Details:Results');
                    }
                }
            });
    });
