angular.module('aq-main').run(function ($httpBackend) {
    const enrollmentListRegex = /^\/api\/v3\/buildings\/enrollment$/;
    const enrollmentRegex = /^\/api\/v3\/buildings\/\d+\/enrollment$/;

    const enrollmentListData: aq.settings.BuildingEnrollmentProgress[] = [
        { building: 22485218, progress: 38, phase: 'Installation' },
        { building: 27008098, progress: 2, phase: 'Building Setup' },
        { building: 27008080, progress: 39, phase: 'Installation' },
        { building: 27008089, progress: 100, phase: 'Training' },
        { building: 22984268, progress: 100, phase: 'Training' },
        { building: 27008116, progress: 20, phase: 'Building Setup' },
        { building: 27008107, progress: 23, phase: 'Building Setup' },
        { building: 27008125, progress: 10, phase: 'Building Setup' },
        { building: 27008134, progress: 19, phase: 'Building Setup' },
        { building: 27008152, progress: 2, phase: 'Building Setup' },
        { building: 22489632, progress: 36, phase: 'Delivery & Installation' },
        { building: 27008143, progress: 7, phase: 'Building Setup' },
        { building: 27008170, progress: 59, phase: 'Platform Setup' },
        { building: 27008161, progress: 9, phase: 'Building Setup' },
        { building: 22489976, progress: 61, phase: 'Platform Setup' },
        { building: 27008179, progress: 12, phase: 'Building Setup' },
        { building: 27008197, progress: 100, phase: 'Training' },
        { building: 27008215, progress: 24, phase: 'Building Setup' },
        { building: 27008188, progress: 10, phase: 'Building Setup' },
        { building: 27008206, progress: 100, phase: 'Training' },
        { building: 27008224, progress: 51, phase: 'Delivery & Installation' },
        { building: 27008233, progress: 25, phase: 'Building Setup' },
        { building: 27008242, progress: 100, phase: 'Training' },
        { building: 27008251, progress: 100, phase: 'Training' },
        { building: 27008269, progress: 65, phase: 'Platform Setup' },
        { building: 29595775, progress: 100, phase: 'Training' },
        { building: 44647480, progress: 100, phase: 'Training' },
        { building: 22490253, progress: 30, phase: 'Building Setup' },
        { building: 27008278, progress: 7, phase: 'Building Setup' },
        { building: 27008260, progress: 100, phase: 'Training' },
        { building: 27008287, progress: 100, phase: 'Training' },
        { building: 27008296, progress: 59, phase: 'Platform Setup' },
        { building: 22485622, progress: 100, phase: 'Training' },
        { building: 44661727, progress: 0, phase: 'Building Setup' },
        { building: 27008305, progress: 100, phase: 'Training' },
        { building: 27008314, progress: 52, phase: 'Delivery & Installation' },
        { building: 27008323, progress: 53, phase: 'Delivery & Installation' },
        { building: 27008332, progress: 14, phase: 'Building Setup' }
    ];
    const enrollmentData: aq.propertySettings.BuildingEnrollment = {
        project: 1,
        account: 1,
        building: 1,
        name: 'Install Aquicore at Mock Building',
        status: 'PLANNED',
        progress: 39,
        currentPhase: 'Delivery & Installation',
        phases: [
            {
                phase: 1,
                name: 'Building Setup',
                description: 'During this stage Aquicore is configuring your intial property settings.',
                sortOrder: 0,
                status: 'COMPLETE',
                progress: 100,
                tasks: [
                    { id: '1', completionDate: moment().toISOString(), name: 'Enter Building Information'},
                    { id: '2', completionDate: moment().toISOString(), name: 'Configure Schedule' },
                    { id: '3', completionDate: moment().toISOString(), name: 'Configure Dashboard' },
                    { id: '4', completionDate: moment().toISOString(), name: 'Invite Users' },
                    { id: '5', completionDate: moment().toISOString(), name: 'Assign Personnel' }
                ]
            },
            {
                phase: 2,
                name: 'Delivery & Installation',
                description: 'The delivery, installation, and verification of the Aquicore infrastructure is taking place.',
                sortOrder: 1,
                status: 'COMPLETE',
                progress: 50,
                tasks: [
                    { id: '6', completionDate: moment().toISOString(), name: 'Scope Project' },
                    { id: '7', completionDate: moment().toISOString(), name: 'Deliver Hardware'},
                    { id: '8', completionDate: moment().toISOString(), name: 'Install Hardware' },
                    { id: '9', completionDate: moment().toISOString(), name: 'Install KYZ Pulse Output' },
                    { id: '10', completionDate: null, name: 'Take First Meter Reading' },
                    { id: '11', completionDate: null, name: 'Take Second Meter Reading' },
                    { id: '12', completionDate: null, name: 'Confirm All Meters Online & Commissioned' }
                ]
            },
            {
                phase: 3,
                name: 'Platform Setup',
                description: 'The configuration of the advanced Aquicore platform features is happening.',
                sortOrder: 2,
                status: 'COMPLETE',
                progress: null,
                tasks: [
                    { id: '13', completionDate: null, name: 'Enable Utility Bill Ingestion' },
                    { id: '14', completionDate: null, name: 'Configure Sources' },
                    { id: '15', completionDate: null, name: 'Create Budgets' },
                    { id: '16', completionDate: null, name: 'Set Performance Targets' },
                    { id: '17', completionDate: null, name: 'Enter Tariff Schedule' },
                    { id: '18', completionDate: null, name: 'Subscribe to Reports' },
                    { id: '19', completionDate: null, name: 'Configure Basic Alerts' }
                ]
            },
            {
                phase: 4,
                name: 'Training',
                description: 'Time to on-board your team members and take advantage of all this new data!',
                sortOrder: 3,
                status: 'COMPLETE',
                progress: null,
                tasks: [
                    { id: '20', completionDate: null, name: 'Schedule Product Intro' },
                    { id: '21', completionDate: null, name: 'Product Intro' },
                    { id: '22', completionDate: null, name: 'Schedule Real Time Data Training' },
                    { id: '23', completionDate: null, name: 'Real Time Data Training' }
                ]
            }
        ]
    };
    MockContext('buildingEnrollment', () => {
        $httpBackend.whenGET(enrollmentListRegex).respond((method, url, data) => {
            return [200, enrollmentListData, {}];
        });
        $httpBackend.whenGET(enrollmentRegex).respond((method, url, data) => {
            return [200, enrollmentData, {}];
        });
        $httpBackend.whenPUT(enrollmentRegex).respond((method, url, data: string) => {
            const response = JSON.parse(data);
            let total = 0;
            let count = 0;
            let current = null;
            _.each(response.phases, (phase: aq.propertySettings.BuildingEnrollmentPhase) => {
                if (!phase.tasks || phase.tasks.length == 0) {
                    return;
                }
                const totalTasks = phase.tasks.length;
                total += totalTasks;
                const completedTasks = _.filter(phase.tasks, (task: aq.propertySettings.TaskEnrollmentResponse) => task.complete).length;
                count += completedTasks;
                if (current == null && totalTasks != completedTasks) {
                    current = phase.name;
                }
            });
            response.progress = total == 0 ? 0 : Math.round(count * 100 / total);
            if (total == count) {
                response.currentPhase = 'Complete';
            } else {
                response.currentPhase = current;
            }
            return [200, response, {}];
        });
    });
});
