namespace aq.publicDisplay {
    export class PublicDisplayConfigurableCtrl extends aq.dashboard.ConfigurableCtrl {
        private openMenu;

        /* @ngInject */
        constructor(
            protected $scope: PublicDisplayConfigurableCtrlScope,
            protected $rootScope,
            protected Messages,
            protected Segment: aq.services.SegmentService,
            protected UserService: aq.services.UserService,
            protected Errors,
            protected dashboard,
            protected $mdDialog,
            protected $stateParams,
            protected $location,
            protected $interval: ng.IIntervalService,
            protected $timeout: ng.ITimeoutService,
            protected account,
            protected loading,
            protected AppStorageService,
            protected Auth,
            protected $state: ng.ui.IStateService,
            protected Restangular
        ) {
            super(
                $scope,
                $rootScope,
                Messages,
                Segment,
                UserService,
                Errors,
                dashboard,
                $mdDialog,
                $stateParams,
                $location,
                $interval,
                $timeout,
                loading,
                AppStorageService,
                Auth,
                account,
                $state,
                Restangular
            );

            $rootScope.$navigationState.currentState.data = {
                breadcrumb: this.$scope.name ? this.$scope.name : 'Choose Display'
            };
        }
    }

    export interface PublicDisplayConfigurableCtrlScope extends aq.dashboard.ConfigurableCtrlScope {
        shareLink: string;
        titleTemplateUrl: string;
    }

    angular.module('aq.publicDisplay').controller('PublicDisplayConfigurableCtrl', PublicDisplayConfigurableCtrl);
}
