namespace aq.utilityBudgets {

    export interface UtilityMeterBulkRequest {
        add: UtilityMeter[];
        update: UtilityMeter[];
        delete: UtilityMeter[];
    }

    export class ManageUtilityMeters extends aq.common.Controllers.ModalCtrl {
        public inProgress: boolean;
        public selectedCollector: aq.common.models.Collector;
        public selectedBillMeter: UrjanetMeter;
        public collectorSearchText: string;
        public billMeterSearchText: string;
        public utilityMetersToDelete: UtilityMeter[];
        public utilityMetersView: UtilityMeter[];
        public urjanetMetersAvailable: UrjanetMeter[];
        public intervalMetersAvailable: aq.utilityBudgets.IntervalMeter[];
        public urjanetMetersUnavailable: UrjanetMeter[];
        public intervalMetersUnavailable: aq.utilityBudgets.IntervalMeter[];

        /* @ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private accountId: string,
            private buildingId: string,
            private utilityAccount: UtilityAccount,
            private utilityService: UtilityService,
            private utilityMeters: UtilityMeter[],
            private urjanetMeters: UrjanetMeter[],
            private intervalMeters: aq.utilityBudgets.IntervalMeter[],
            private collectors: aq.common.models.Collector[],
            private Messages: aq.services.Messages,
            private RestangularV3: restangular.IService,
            private $q: ng.IQService
        ) {
            super({}, $mdDialog);
            this.utilityMetersToDelete = [];
            this.inProgress = false;
            this.utilityMetersView = this.initUtilityMetersView(this.utilityMeters, this.urjanetMeters,
                this.intervalMeters);

            const urjanetMetersIdsInView: string[] = this.utilityMetersView.map(x => x.utilityProviderMeterId);
            const intervalIdsInView: string[] = this.utilityMetersView.map(x => x.intervalMeter ? x.intervalMeter.id : null).filter(meter => {
                return meter != null
            });

            this.urjanetMetersAvailable = this.getUrjanetMetersNotInUse(urjanetMetersIdsInView);
            this.intervalMetersAvailable = this.getIntervalMetersNotInUse(intervalIdsInView);
            this.urjanetMetersUnavailable = this.getUrjanetMetersInUse(urjanetMetersIdsInView);
            this.intervalMetersUnavailable = this.getIntervalMetersInUse(intervalIdsInView);
        }

        public isIntervalSupported() {
            return !['STEAM', 'HEAT'].includes(this.utilityService.type);
        }

        public save() {
            if (this.inProgress) {
                return;
            }
            this.inProgress = true;
            const requestMap = this.getUtilityMeterRequestMap(this.utilityMetersView, this.utilityMetersToDelete);
            this.doUtilityMeterBulkRequest(requestMap)
                .then(() => {
                    return this.RestangularV3.all('utility-statements')
                        .customPOST({}, 'update-charges-for-service', { utilityServiceId: this.utilityService.id });
                })
                .then(() => {
                    return this.RestangularV3.all('utility-meters')
                        .getList({ utilityServiceId: this.utilityService.id, ts: moment().valueOf() });
                })
                .then((utilityMeters) => {
                    this.Messages.success('Utility meters successfully saved');
                    this.hide(utilityMeters);
                })
                .catch((e) => {
                    console.error(e);
                    this.Messages.error('Error when saving utility meters. Please try again.');
                })
                .finally(() => {
                    this.inProgress = false;
                });
        }

        public getUtilityMeterRequestMap(utilityMeters: UtilityMeter[], utilityMetersToDelete: UtilityMeter[]): UtilityMeterBulkRequest {
            const addList = [];
            const updateList = [];
            const deleteList = [...utilityMetersToDelete];
            _.each(utilityMeters, (utilityMeter: UtilityMeter) => {
                if (utilityMeter.intervalMeter && !utilityMeter.intervalMeter.measure) {
                    utilityMeter.intervalMeter.measure = this.utilityService.type.toUpperCase();
                }
                if (utilityMeter.collector) {
                    if (utilityMeter.id) {
                        updateList.push(utilityMeter);
                    } else {
                        addList.push(utilityMeter);
                    }
                } else if (utilityMeter.id && utilityMeter.utilityProviderMeterId) {
                    // Delete existing UtilityMeters that have gone from matched to unmatched
                    deleteList.push(utilityMeter);
                }
            });

            return {
                add: addList,
                update: updateList,
                delete: deleteList
            };
        }

        public doUtilityMeterBulkRequest(requestMap: UtilityMeterBulkRequest) {
            const addPromises = _.map(requestMap.add, (utilityMeter: UtilityMeter) => this.updateOrCreateUtilityMeter(utilityMeter));
            const updatePromises = _.map(requestMap.update, (utilityMeter: UtilityMeter) => this.updateOrCreateUtilityMeter(utilityMeter));
            const deletePromises = _.map(requestMap.delete, (utilityMeter: UtilityMeter) => this.deleteUtilityMeter(utilityMeter));
            return this.$q.all([
                ...addPromises,
                ...updatePromises,
                ...deletePromises
            ]);
        }

        public shouldShowAutoIngestionFields() {
            return this.utilityAccount.username
                && this.utilityAccount.utilityUrl
                && this.urjanetMeters.length > 0;
        }

        public isIntervalSelectedInAnotherMeter( currentUtilityMeter: UtilityMeter, currentIntervalMeter: IntervalMeter ) {
            return _.some(this.utilityMetersView, (utilityMeterView: UtilityMeter) => {
                return utilityMeterView.intervalMeter
                    && utilityMeterView.intervalMeter.smartMeterNumber === currentIntervalMeter.smartMeterNumber
                    // and this isnt the one that we are looking at currently
                    && currentUtilityMeter.intervalMeter
                    && currentUtilityMeter.intervalMeter.smartMeterNumber !== currentIntervalMeter.smartMeterNumber;
            });
        }

        // is aquicoremeterID already assigned an interval meter
        public isAquicoreMeterAlreadyAssignedIntervalMeter(currentUtilityMeter: UtilityMeter, index: number) {
            return _.some(this.utilityMetersView, (utilityMeterView: UtilityMeter, loopIndex: number) => {
                return utilityMeterView.collector === currentUtilityMeter.collector
                    // make sure we're not talking about the row we're currently on
                    && loopIndex !== index
                    && utilityMeterView.intervalMeter !== null;
            });
        }

        public deleteUtilityMeterAction(utilityMeter: UtilityMeter) {
            if (utilityMeter.id) {
                const deleted = _.remove(this.utilityMetersView, { id: utilityMeter.id });
                this.utilityMetersToDelete = this.utilityMetersToDelete.concat(deleted);
            } else {
                _.remove(this.utilityMetersView, { collector: utilityMeter.collector });
            }
        }

        public getCollectorDisplay(utilityMeter: UtilityMeter) {
            const collector: aq.common.models.Collector = _.find(this.collectors, { id: utilityMeter.collector });
            if (collector) {
                return collector.name;
            } else {
                return 'Unmatched';
            }
        }

        public getUrjanetMeterDisplay(utilityMeter: UtilityMeter) {
            const urjanetMeter: UrjanetMeter = _.find(this.urjanetMeters, { id: utilityMeter.utilityProviderMeterId });
            if (urjanetMeter) {
                return urjanetMeter.meterNumber;
            } else {
                return 'Unmatched';
            }
        }


        private getUrjanetMetersNotInUse(urjanetMetersIdsInView: string[]): UrjanetMeter[] {
            const value = this.urjanetMeters.filter(urjanetMeter => {
                return !(urjanetMetersIdsInView.indexOf(urjanetMeter.id) >= 0);
            });
            return value;
        }

        private getUrjanetMetersInUse(urjanetMetersIdsInView: string[]): UrjanetMeter[] {
            const value = this.urjanetMeters.filter(urjanetMeter => {
                return (urjanetMetersIdsInView.indexOf(urjanetMeter.id) >= 0);
            });
            return value;
        }

        private getIntervalMetersNotInUse(intervalIdsInView: string[]): IntervalMeter[] {
            const value= this.intervalMeters.filter(intervalMeter => {
                return !(intervalIdsInView.indexOf(intervalMeter.id) >= 0);
            });
            return value;
        }

        private getIntervalMetersInUse(intervalIdsInView: string[]): IntervalMeter[] {
            const value= this.intervalMeters.filter(intervalMeter => {
                return (intervalIdsInView.indexOf(intervalMeter.id) >= 0);
            });
            return value;
        }

        public addBlankMeter(urjanetMeter: UrjanetMeter) {
            const newUtilityMeter: UtilityMeter = {
                collector: null,
                utilityProviderMeterId: null,
                intervalMeter: null
            } as UtilityMeter;
            this.utilityMetersView.push(newUtilityMeter);
            this.selectedBillMeter = null;
            this.billMeterSearchText = null;
        }

        public isCollectorFromCurrentBuilding(utilityMeter: UtilityMeter) {
            if (utilityMeter.collector) {
                return !!_.find(this.collectors, { id: utilityMeter.collector });
            } else {
                return true;
            }
        }

        private initUtilityMetersView(utilityMeters: UtilityMeter[], urjanetMeters: UrjanetMeter[], intervalMeters?: IntervalMeter[]) {
            const meters: UtilityMeter[] = [];
            // show our urjanet meters first
            urjanetMeters.forEach((urjanetMeter: UrjanetMeter) => {
                const meter: UtilityMeter = _.find(utilityMeters, { utilityProviderMeterId: String(urjanetMeter.id) });
                if (meter) {
                    meter.intervalMeter = null;
                    // check if we have an interval meter on it
                    if (intervalMeters && intervalMeters.length > 0) {
                        const matchByUtility: IntervalMeter = _.find(intervalMeters, function(intervalMeter: IntervalMeter) {
                            // matches AQ meter, bring it on
                            return meter.deviceId === intervalMeter.aquicoreMeterID;
                        });

                        if (matchByUtility !== void 0) {
                            meter.intervalMeter = matchByUtility;
                        }
                    }
                    meters.push(meter);
                } else {
                    let intervalMeter: IntervalMeter = null;
                    // If it doesn't exist, create a dummy record to show as 'Unmatched'

                    if (intervalMeters && intervalMeters.length > 0) {
                        const matchByUrjanetId: IntervalMeter = _.find(intervalMeters, function (intervalMeterObject: IntervalMeter) {
                            // matches AQ meter, bring it on
                            return urjanetMeter.id === intervalMeterObject.billMeterNumber;
                        });
                        if (matchByUrjanetId !== void 0) {
                            intervalMeter = matchByUrjanetId;
                        }
                    }
                    intervalMeter !== null && meters.push(<UtilityMeter>{
                        collector: null,
                        utilityProviderMeterId: String(urjanetMeter.id),
                        intervalMeter
                    });
                }
            });
            // then show our unmatched meters
            const unmatchedMeters = utilityMeters.filter((utilityMeter: UtilityMeter) => {
                    return !meters.some((meter) => { return meter.id === utilityMeter.id; })
                })
                .map((utilityMeter) => {
                    utilityMeter.intervalMeter = intervalMeters.find((intervalMeter: IntervalMeter) => {
                        const aqMeterId = intervalMeter.aquicoreMeterID;
                        return aqMeterId ? utilityMeter.deviceId === aqMeterId : false;
                    });
                    if (!utilityMeter.intervalMeter) {
                        // This is here so that the UI will correctly display "Unmatched" if there is no interval meter matched to this meter
                        // arr.find() returns undefined and breaks this behaviour
                        utilityMeter.intervalMeter = null;
                    }
                    return utilityMeter;
                });
            return [...meters, ...unmatchedMeters];
        }

        private deleteUtilityMeter(utilityMeter: UtilityMeter) {
            if (utilityMeter.id) {
                return this.RestangularV3.one('utility-meters', utilityMeter.id).customDELETE('', { deviceId: utilityMeter.deviceId });
            }
            return null;
        }

        private updateOrCreateUtilityMeter(utilityMeter: UtilityMeter) {
            if (utilityMeter.id) {
                return this.RestangularV3.one('utility-meters', utilityMeter.id)
                    .customPUT(utilityMeter);
            } else {
                return this.RestangularV3.all('utility-meters')
                    .post(utilityMeter, { utilityServiceId: this.utilityService.id });
            }
        }
    }
    angular.module('aq.utilityBudgets').controller('ManageUtilityMeters', ManageUtilityMeters);
}
