angular.module('aq-main').run(function ($httpBackend) {
    const singleRegex = /^\/api\/v3\/accruals\/\d{1,}$/;
    const submitRegex = /^\/api\/v3\/accruals\/bulk-submit$/;
    const listRegex = /^\/api\/v3\/accruals\?buildingIds=\d{1,}.*$/;
    const budgetSettingsRegex = /^\/api\/v3\/buildings\/.*\/budget-settings$/;

    MockContext('accruals', () => {
        $httpBackend.whenGET(listRegex).respond((method, url, data) => {
            const queryParams = MockHelpers.getQueryParams(url);
            const buildingIds = queryParams['buildingIds'] ? queryParams['buildingIds'] : null;
            const measure = queryParams['measure'] ? queryParams['measure'][0] : null;
            const startDate = queryParams['start'] ? queryParams['start'][0] : null;
            const endDate = queryParams['end'] ? queryParams['end'][0] : null;

            if (!buildingIds || !startDate || !endDate) {
                return [500, {error: 'missing required params'}, {}];
            }
            const now = moment();
            const responseData = [];
            const start = moment(startDate);
            let id = 1;
            _.each(buildingIds, buildingId => {
                while (now.isAfter(start)) {
                    let item: aq.utilityBudgets.AccrualResponse = {
                        id,
                        utilityServiceId: 0,
                        measure,
                        building: buildingId,
                        startDate: moment(start).format('YYYY-MM-DD'),
                        endDate: moment(start).endOf('month').format('YYYY-MM-DD'),
                        accruedStartDate: moment(start).add(24, 'day').format('YYYY-MM-DD'),
                        accruedEndDate: moment(start).endOf('month').format('YYYY-MM-DD'),
                        currentConsumption: 300000,
                        currentDemand: 600,
                        currentExpense: 30000,
                        accruedConsumption: 100000,
                        accruedDemand: 600,
                        accruedExpense: 10000,
                        totalExpense: 40000,
                        status: aq.utilityBudgets.AccrualStatus.SUBMITTED,
                        submitDate: moment(start).add(25, 'day').format('YYYY-MM-DD')
                    };
                    if (now.isBetween(moment(start), moment(start).add(1, 'month'))) {
                        const ratio = now.date() / now.daysInMonth();
                        const totalConsumption = 400000;
                        const totalExpense = 40000;
                        item = {
                            id,
                            utilityServiceId: 0,
                            measure,
                            building: buildingId,
                            startDate: moment(start).format('YYYY-MM-DD'),
                            endDate: moment(start).add(1, 'month').format('YYYY-MM-DD'),
                            accruedStartDate: now.format('YYYY-MM-DD'),
                            accruedEndDate: moment(start).endOf('month').format('YYYY-MM-DD'),
                            currentConsumption: ratio * totalConsumption,
                            currentDemand: null,
                            currentExpense: ratio * totalExpense,
                            accruedConsumption: totalConsumption - (ratio * totalConsumption),
                            accruedDemand: null,
                            accruedExpense: totalExpense - (ratio * totalExpense),
                            totalExpense: totalExpense,
                            status: aq.utilityBudgets.AccrualStatus.PENDING,
                            submitDate: null
                        };
                    }
                    responseData.push(item);
                    start.add(1, 'month');
                    id++;
                }
            });
            return [200, responseData, {}];
        });
        $httpBackend.whenPOST(submitRegex).respond((method, url, data: string) => {
            const response = JSON.parse(data);
            return [200, response, {}];
        });
        $httpBackend.whenPUT(singleRegex).respond((method, url, data: string) => {
            const response = JSON.parse(data);
            return [200, response, {}];
        });
        $httpBackend.whenGET(budgetSettingsRegex).respond((method, url, data) => {
            const response: aq.utilityBudgets.BudgetSettingsModel = {
                buildingId: 1,
                autoEnabled: false,
                frequency: aq.utilityBudgets.BudgetWorkflowScheduleFrequency.MONTHLY,
                dayOfMonth: 24,
                reminderDays: 2,
                reminderUsers: [],
                accountantReceivers: ['accounting@test.com'],
                approverReceivers: ['approver@test.com']
            };
            return [200, response, {}];
        });
    });
});
