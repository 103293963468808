namespace aq.models.aidIssue {

    export enum ActionType {
        ACKNOWLEDGE = 'ACKNOWLEDGE',
        MODIFY = 'MODIFY'
    }

    export enum EventType {
        HOLIDAY_RUN = 'HOLIDAY_RUN',
        WEEKEND_RUN = 'WEEKEND_RUN',
        NIGHTTIME_RUN = 'NIGHTTIME_RUN',
        UNOCCUPIED_HOUR_RUN = 'UNOCCUPIED_HOUR_RUN'
    }

    export interface IssueQueryParams {
        start: string;
        end: string;
        buildings: number[];
        types: EventType[];
    }

    export class HighlightAreaDatum {
        public max_val: number;
        public time_start: string;
        public time_end: string;
        public min_val: number;
    }

    export class SeriesDatum {
        public energy: number;
        public baseload?: number;
        public baseline?: number;
        public temperature: number;
        public time: string;
    }

    export class IssueFeedbackCreateRequest {
        public issue: string;
        public user: number;
        public actionType: ActionType;
        public eventType?: EventType;
        public potentialCause?: string;
        public highlightedArea?: HighlightAreaDatum;
    }

    export class IssueFeedbackResponse {
        public id: string;
        public issue: string;
        public user: number;
        public created: string;
        public updated: string;
        public actionType: ActionType;
        public eventType: EventType;
        public potentialCause: string;
        public highlightedArea: HighlightAreaDatum;
    }

    export class IssueResponse {
        public id: string;
        public building: number;
        public date: string;
        public eventType: EventType;
        public potentialCause: string;
        public potentialSavingsEnergy: number;
        public series: SeriesDatum[];
        public highlightedArea: HighlightAreaDatum;
        public feedback: IssueFeedbackResponse[];
    }

    export class SimpleIssueResponse {
        public id: string;
        public building: number;
        public date: string;
        public eventType: EventType;
        public potentialCause: string;
        public potentialSavingsEnergy: number;
    };

    export class IssueListResponse {
        public count: number;
        public payload: SimpleIssueResponse[];
        constructor() {
            this.payload = [];
            this.count = void 0;
        }
    };

    export class IssueHistoryItem {
        public date: moment.Moment;
        public username: string;
        public content: string;
    };
}
