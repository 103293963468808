namespace aq.tenantbilling {
    export class AddEditLeaseCtrl extends aq.common.Controllers.ModalCtrl {
        public allLeases = [];
        public residents: ResidentModel[] = [];
        public residentsToDelete: ResidentModel[];
        public missingField: string;
        public invalidFields = false;
        /* @ngInject */
        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private RestangularV3: restangular.IService,
            private Errors,
            private Messages: aq.services.Messages,
            private accountId,
            private buildingId,
            private tenantId,
            private leaseTypes: any[],
            private TenantServiceHelper: TenantServiceHelper,
            private areaMeasurement,
            public lease,
            private Auth: aq.services.Auth
        ) {
            super({}, $mdDialog);
            this.residentsToDelete = [];
            const primaryResident = {} as ResidentModel;
            this.residents.push(primaryResident);
            if (!this.lease) {
                this.lease = {
                    tenant: this.tenantId,
                    active: true
                };
            } else {
                this.lease.startDate = this.lease.startDate != null ? this.convertToDate(this.lease.startDate) : null;
                this.lease.endDate = this.lease.endDate != null ? this.convertToDate(this.lease.endDate) : null;
                if (!_.isEmpty(this.lease.residents)) {
                    this.residents = this.lease.residents;
                }
            }
            this.TenantServiceHelper.getLeases(this.tenantId).then((leases) => {
                this.allLeases = leases;
            });
        }
        public addAnotherResident(residents: ResidentModel[]) {
            const resident = {} as ResidentModel;
            residents.push(resident);
        }

        public deleteResident(resident, index, residents, residentsToDelete) {
            residents.splice(index, 1);
            if (resident.id) {
                residentsToDelete.push(resident);
            }
        }

        public cancel(data?): void {
            this.$mdDialog.cancel();
        }
        public hide(): void {
            this.$mdDialog.hide(this.RestangularV3.copy(this.lease));
        }
        public convertToDate(dateString: string): Date {
            const parts = dateString.split('-');
            // JavaScript counts months from 0 to 11.
            return new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]));
        }
        public dateOverlap(leaseA, leaseB): boolean {
            const startDateA = new Date(leaseA.startDate).getTime();
            const startDateB = new Date(leaseB.startDate).getTime();

            let endDateA;
            let endDateB;

            if (!leaseA.endDate && !leaseB.endDate) { // both have no end dates
                return leaseA.starDate === leaseB.startDate;
            }
            if (!leaseA.endDate) { // new/modified lease had no end date
                endDateB = new Date(leaseB.endDate).getTime();
                if ((startDateA < startDateB) || (startDateA >= endDateB)) {
                    return false;
                }
            } else if (!leaseB.endDate) { // existing lease had no end date
                endDateA = new Date(leaseA.endDate).getTime();
                if ((startDateA < endDateA) && ((endDateA <= startDateB) || (startDateA > startDateB))) {
                    return false;
                }
            }

            endDateA = new Date(leaseA.endDate).getTime();
            endDateB = new Date(leaseB.endDate).getTime();
            if ((startDateA < endDateA) && ((endDateA <= startDateB) || (startDateA >= endDateB))) {
                return false;
            }
            return true;
        }
        public updateCreate() {
            if (this.lease.id) {
                this.TenantServiceHelper.deleteResidents(this.residentsToDelete);
                delete this.lease.residents;
                delete this.lease.residentsNames;
                return this.TenantServiceHelper.updateLease(this.lease)
                    .then(() => {
                        this.residents.forEach((resident) => {
                            if (!resident.id && resident.name && resident.email) {
                                resident.lease = this.lease.id;
                                this.TenantServiceHelper.createResident(resident);
                            } else if (resident.id) {
                                this.TenantServiceHelper.updateResident(resident);
                            }
                        });
                        this.hide();
                    });
            }
            return this.TenantServiceHelper.createLease(this.lease)
                .then((leaseResult) => {
                    _.extend(this.lease, leaseResult);
                    this.residents.forEach((resident) => {
                        if (resident.name && resident.email) {
                            resident.lease = this.lease.id;
                            this.TenantServiceHelper.createResident(resident);
                        }
                    });
                    this.hide();
                });
        }
        public save() {
            let overlap = false;
            this.allLeases.forEach((leaseItem) => {
                if (this.lease.id && (this.lease.id !== leaseItem.id)) { // skip date validation on itself
                    overlap = this.dateOverlap(this.lease, leaseItem);
                } else if (!this.lease.id) {
                    overlap = this.dateOverlap(this.lease, leaseItem);
                }
            });
            const endDate = this.lease.endDate;
            if (overlap || (endDate && !(this.lease.startDate < endDate))) {
                return this.Messages
                    .error('The start or end date overlaps with another lease. Please adjust dates');
            }
            return this.updateCreate();
        }
        public validateFields(resident: ResidentModel): boolean {
            const hasEmail = (resident.email != null && resident.email != "") ? true : false;
            const hasName = (resident.name != null && resident.name != "") ? true : false;
            this.invalidFields = true;
            if (!hasEmail && hasName) {
                this.missingField = "Email";
                return true;
            } else if (hasEmail && !hasName) {
                this.missingField = "Name";
                return true;
            }
            this.invalidFields = false;
            return false;
        }
    }
    angular.module('tenantBilling').controller('AddEditLeaseCtrl', AddEditLeaseCtrl);
}
