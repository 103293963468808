namespace aq.energyInsights {
    export class BaseloadV2Ctrl {
        private appRestructure;
        /* @ngInject */
        constructor(
            private $scope: BaseloadV2CtrlScope,
            private loading,
            private account: aq.common.models.Account,
            private currentBuilding: aq.common.models.Building,
            private $state: ng.ui.IStateService,
            private BaseloadV2Service: BaseloadV2Service,
            private Auth: aq.services.Auth,
            private $filter,
            private $window
        ) {
            $scope.selectedRow = [];
            $scope.chart = {};
            $scope.building = currentBuilding;
            $scope.periodSearch = {
                start: aq.common.TimeConfiguration.This.Month.start(this.currentBuilding.timeZoneId),
                end: aq.common.TimeConfiguration.This.Month.end(this.currentBuilding.timeZoneId),
                interval: aq.common.Intervals.DAY,
                label: 'This Month'
            };
            $scope.toastMessage = 'Improve your analysis by adding a baseload target for this building!';
            this.appRestructure = Auth.hasFunctionality('App Restructure');
            this.updateData();
        }

        public queryPeriodSelectorChange(startDate: moment.Moment, endDate: moment.Moment, interval: any, label: string) {
            this.$scope.periodSearch.start = startDate;
            this.$scope.periodSearch.end = endDate;
            this.$scope.periodSearch.interval = interval;
            this.$scope.periodSearch.label = label;
            this.updateData();
        }

        public goToTargets = () => {
            this.$state.go('aq.properties.buildings.targets', { accountId: this.account.id, buildingId: this.currentBuilding.id });
        }

        public goToOptimization() {
            this.account.get({ single: true }).then((thisAccount) => {
                const optimizationURI = URI('/accounts/' + thisAccount.id + '/optimization/building')
                    .search({
                        unit: 'KWH',
                        apiUnit: 'ENERGY',
                        interval: '1h',
                        off: _.without(thisAccount.buildings, this.currentBuilding.id).join(','),
                        start: this.$scope.periodSearch.start.tz(this.currentBuilding.timeZoneId).format(),
                        end: this.$scope.periodSearch.end.tz(this.currentBuilding.timeZoneId).format(),
                        children: 'buildings'
                    });
                this.$window.location.href = optimizationURI.toString();
            });
        }

        public downloadCsv() {
            let titleContent = 'data:text/csv;charset=utf-8,';
            let csvContent = '';
            let fileName = '';
            const keys = _.keys(this.$scope.tableData.rows[0]).filter((key) => {
                return key !== 'timestamp' && key != '$$hashKey';
            });
            titleContent += `Date`;
            keys.forEach((key) => {
                titleContent += `,${key}`;
            });
            this.$scope.tableData.rows.forEach((row) => {
                csvContent += `${moment(row.timestamp).format('MM/DD/YYYY')}`;
                keys.forEach((key) => {
                    csvContent += `,${row[key]}`;
                });
                csvContent += '\n';
            });
            const finalContent = `${titleContent}\n${csvContent}`;
            fileName = `BaseloadInsight_Report.csv`;
            const link = document.createElement('a');
            link.setAttribute('href', encodeURI(finalContent));
            link.setAttribute('download', fileName);
            link.click();
        }

        private updateData(drillIn = false, e?) {
            this.loading.start();
            const measure = 'ELECTRICITY';
            this.BaseloadV2Service.getTableModel(this.currentBuilding, measure, this.$scope.periodSearch, this.account).then((tableData) => {
                this.$scope.tableData = tableData;
                const noTargetRows = _.filter(this.$scope.tableData.rows, (row: any) => {
                    return !row.target;
                });
                if (noTargetRows.length === this.$scope.tableData.rows.length) {
                    this.$scope.showToast = true;
                }
                return this.BaseloadV2Service.getGraphModel(this.currentBuilding, measure, this.$scope.periodSearch, this.account);
            }).then((graphModel) => {
                if (drillIn) {
                    graphModel.graph.series.forEach((series) => {
                        this.$scope.chart.addSingleSeriesAsDrilldown(e.point, series);
                    });
                    this.$scope.chart.applyDrilldown();
                } else {
                    this.$scope.chartConfig = graphModel.graph;
                    this.addDrillin();
                }
                return this.BaseloadV2Service.getTargetModel(this.currentBuilding, measure, this.$scope.periodSearch, this.account, '');
            }).then((targetModel) => {
                this.$scope.targetModel = targetModel;
                if (this.$scope.chart) {
                    this.$scope.chart.redraw();
                }
                this.loading.stop();
            }).catch((err) => {
                this.loading.stop();
            });
        }

        private addDrillin() {
            if (this.$scope.periodSearch.interval.value === '1mon') {
                this.$scope.chartConfig.chart.events = {
                    drilldown: (e) => {
                        const noTzFormat = 'YYYY-MM-DD';
                        const month = moment(e.point.timestamp).format(noTzFormat);
                        this.$scope['previousPeriodSearch'] = angular.copy(this.$scope.periodSearch);
                        this.$scope.periodSearch.start = moment.tz(month, this.currentBuilding.timeZoneId).startOf('month');
                        this.$scope.periodSearch.end = moment.tz(month, this.currentBuilding.timeZoneId).add(1, 'month').startOf('month').subtract(1, 'second');
                        this.$scope.periodSearch.interval = aq.common.Intervals.DAY;
                        this.$scope.periodSearch.label = `in ${moment(e.point.timestamp).format('MMMM')}`;
                        this.updateData(true, e);
                    },
                    drillup: (e) => {
                        this.$scope.periodSearch = this.$scope.previousPeriodSearch;
                        this.updateData();
                    }
                };
            }
        }
    }

    export interface BaseloadV2CtrlScope extends ng.IScope {
        chartConfig;
        tableData;
        targetModel;
        periodSearch;
        selectedRow;
        chart;
        toastMessage: string;
        showToast: boolean;
        building: aq.common.models.Building;
        previousPeriodSearch;
    }

    angular.module('energyInsights').controller('BaseloadV2Ctrl', BaseloadV2Ctrl);
}
