namespace aq.deviceManagement {
    export class OverviewListItemComponent {
        public datastatus: aq.common.models.DataStatus;
        public device: aq.common.models.Device;
        public commissioningStatus;

        /* @ngInject */
        constructor(private DeviceService: aq.services.DeviceService) {}

        getIcon(device: aq.common.models.DeviceElement): string {
            return this.DeviceService.getIcon(device);
        }

        tenantNames(tenantLinks: aq.common.models.DeviceTenantLink[]): string {
            return tenantLinks.map((tenantLink) => tenantLink.tenant.name).sort().join(', ');
        }

        public getCommissioningProgress() {
            if (this.commissioningStatus) {
                const percentDone = Math.round((this.commissioningStatus.numStepsCompleted
                    / this.commissioningStatus.totalSteps) * 100);
                return percentDone;
            }
        }

        public getNextStep(device: aq.common.models.Device) {
            if (this.commissioningStatus && this.commissioningStatus.steps) {
                for (let i = 0; i < this.commissioningStatus.steps.length; i++) {
                    if (!(this.commissioningStatus.steps[i].status == 'COMPLETE')) {
                        return this.commissioningStatus.steps[i].name;
                    }
                }
            }
            return null;
        }
    }
    angular.module('deviceManagement')
        .component('overviewListItem', {
            templateUrl: 'app/deviceManagement/building/overviewListItem.html',
            bindings: {
                device: '<',
                datastatus: '<',
                commissioningStatus: '<'
            },
            controller: OverviewListItemComponent,
            controllerAs: 'vm'
        });
}
