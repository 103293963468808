angular.module('meterManagement').controller('MeterStatusCtrl',
function($scope, metersWithStatus, meterSummary, Restangular, $state, loading, Errors, Messages) {

    $scope.meterSummary = meterSummary;
    $scope.metersWithStatus = metersWithStatus.results;
    $scope.meterOrder= "collector.name";
    $scope.orderBy = 'c.name';
    $scope.paging = {
        limit: 20,
        page: 1,
        total: metersWithStatus.total
    }
    $scope.vm = {
        searchText: ""
    };
    $scope.search = function () {
        var desc = $scope.meterOrder.indexOf('-') === 0;
        var searchText = $scope.vm.searchText;
        var orderBy = desc ? $scope.meterOrder.substring(1) : $scope.meterOrder;
        loading.start();
        return Restangular.all('users').get('queryMeterStatusWithPaging', {
            page: $scope.paging.page,
            pageSize: $scope.paging.limit,
            orderBy: orderBy,
            search: searchText,
            asc: !desc
        }).then(function(metersWithStatus) {
            $scope.metersWithStatus = metersWithStatus.results;
            $scope.paging.total  = metersWithStatus.total;
        }, Errors.forPromise()).finally(loading.stop);
    };

    $scope.selectItem = function(value) {
        $state.go('aq.meterManagement.data', {name: value.name});
    }

    $scope.increaseLimit = function(amount) {
        $scope.limit += amount;
    };

    $scope.downloadCsv = function() {
        var params = {
            orderBy: 'collector.name',
            search: $scope.vm.searchText,
            asc: false
        };
        Restangular.one('accounts', $scope.account.id).customGET("meterStatusCsv", params).then(function (result) {
            $scope.sendFile(result);
        }).catch(function(error) {
            Messages.error(error.data.message);
        });;
    }

    $scope.sendFile = function(result) {
        var fileName = "meter_list.csv"
        var link = document.createElement('a');
        link.setAttribute('href', encodeURI(result));
        link.setAttribute('download', fileName);
        link.click();
    }

    function serialize( obj ) {
        return '?'+Object.keys(obj).reduce(function(a,k){a.push(k+'='+encodeURIComponent(obj[k]));return a},[]).join('&')
    }

});
