namespace aq.properties {
    export class CreateInstallationProjectModalCtrl extends aq.common.Controllers.ModalCtrl {
        private owner: aq.common.models.User;
        /* @ngInject */
        constructor(
            protected $scope: ng.IScope,
            protected $mdDialog: ng.material.IDialogService,
            private Messages: aq.services.Messages,
            private building: aq.common.models.Building,
            private RestangularV3: restangular.IService,
            private users: aq.common.models.User
        ) {
            super($scope, $mdDialog);
        }

        cancel() {
            this.$mdDialog.cancel();
        }

        create() {
            return this.RestangularV3
                .all('projects')
                .customPOST({}, 'createInstallationProject', {buildingId: this.building.id, ownerId: this.owner.id})
                .then((response) => {
                    this.Messages.success('Successfully created installation project.');
                    this.$mdDialog.hide(response.plain());
                }).catch(() => {
                    this.Messages.error('Unable to create installation project.');
                });
        }
    }
    angular
    .module('properties')
    .controller('CreateInstallationProjectModalCtrl', CreateInstallationProjectModalCtrl);
 }
