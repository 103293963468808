namespace aq.ui {

    interface IBindings {
        message: aq.models.activity.ActivityMessage;
    }

    // Reading on component lifecycle hooks https://toddmotto.com/angular-1-5-lifecycle-hooks
    class _ActivityMessage implements IBindings {
        public message: aq.models.activity.ActivityMessage;
        private formattedDate: string;
        private dateFormat: string = 'ddd, MMM D [at] h:mma';
        private parsedMessageBody: string;


        /* @ngInject */
        constructor() { }

        public $onInit() {
            this.formattedDate = moment(this.message.createdAt).format(this.dateFormat);
            this.parsedMessageBody = this.parseMessage(this.message.body);
        }

        public $onChanges(changes) {
            if (changes.message) {
                this.message = angular.copy(changes.message.currentValue);
                this.formattedDate = moment(this.message.createdAt).format(this.dateFormat);
                this.parsedMessageBody = this.parseMessage(this.message.body);
            }
        }

        private parseMessage(text: string): string {
            /**
             * text is a message body that looks like this:
             * "this is some message body [@tony.knight:1234567] with mentions that look like
             * this [@michael.payne:987654321]"
             *
             * and gets parsed into nice, user readable text:
             * "this is some message body @tony.knight with mentions that look like
             * this @michael.payne"
             */
            const pattern = /\[(@[^:]+):([^\]]+)\]/igm;
            return text.replace(pattern, '$1');
        }
    }

    const ActivityMessage: ng.IComponentOptions = {
        controller: _ActivityMessage,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/activities/messages/message.html',
        bindings: {
            message: '<'
        }
    };

    angular
        .module('aq.ui')
        .component('activityMessage', ActivityMessage);
}
