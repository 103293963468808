namespace aq.tenantbilling {
    export class ConfigurationStepCtrl {
        public isErrorTenantService: boolean;
        public monthlyBilling: MonthlyTenantBilling;
        public lastBilling: LastBillingMap;
        public tenantServices: TenantService[];
        public periodTenantServices: PeriodTenantService[];
        public building: aq.common.models.Building;
        public step: number;
        public onComplete: Function;
        public selectedTenantServices: { [serviceId: string]: boolean };
        public progressText: string;
        public showServiceSelectionOnly: boolean;
        tenantLabel: string;
        /* @ngAnnotate */
        constructor(
            private $mdStepper,
            private $location: ng.ILocationService,
            private $q: ng.IQService,
            private $timeout: ng.ITimeoutService,
            private Messages: aq.services.Messages,
            private DataStore: aq.common.DataStore,
            private RestangularV3: restangular.IService,
            private MonthlyBillingService: MonthlyBillingService,
            private $element: ng.IAugmentedJQuery,
            private Auth: aq.services.Auth
        ) {
            this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'unit' : 'tenant';
        }

        public $onInit() {
            if (!this.monthlyBilling.id && (!this.periodTenantServices || this.periodTenantServices.length === 0)) {
                this.showServiceSelectionOnly = true;
            }
            this.progressText = null;
            this.selectedTenantServices = this.initSelectedTenantServices();
            if (this.step) {
                this.$timeout(() => {
                    this.$mdStepper('runInvoicingStepper').goto(this.step - 1);
                }, 1);
            }
        }

        public $onChanges(changes) {
            if (this.monthlyBilling && this.monthlyBilling.id) {
                this.selectedTenantServices = this.initSelectedTenantServices();
            }
        }

        public onStepLoad() {
            this.MonthlyBillingService.getPeriodTenantServices(this.monthlyBilling)
            .then((periodServices) => {
                this.periodTenantServices = periodServices;
                this.selectedTenantServices = this.initSelectedTenantServices();
            });
        }

        public initSelectedTenantServices() {
            const selectedTenantServices = {};
            if (this.periodTenantServices) {
                _.forEach(this.periodTenantServices, (service) => {
                    selectedTenantServices[service.tenantService] = true;
                });
            }
            return selectedTenantServices;
        }

        public saveAndContinue() {
            const tenantServiceIds = this.getSelectedTenantServices().map((service) => {
                return service.id;
            });
            if (tenantServiceIds.length === 0) {
                this.isErrorTenantService = true;
                return;
            }
            this.monthlyBilling.tenantServiceIds = tenantServiceIds;
            this.progressText = 'Saving configuration...';
            let savePromise;
            if (!this.monthlyBilling.id) {
                savePromise = this.MonthlyBillingService.getIsManualMeterPresentForAnyService(tenantServiceIds, this.building.id)
                    .then((value) => {
                        this.monthlyBilling.hasManualMeters = value === 'true';
                        return this.MonthlyBillingService.create(this.monthlyBilling);
                    });
            } else {
                savePromise = this.MonthlyBillingService.save(this.monthlyBilling);
            }
            savePromise.then((billing) => {
                const promises = [];
                promises.push(this.MonthlyBillingService.get(billing.id));
                promises.push(this.MonthlyBillingService.getInvoices(billing));
                promises.push(this.MonthlyBillingService.getChargeValues(billing));
                promises.push(this.MonthlyBillingService.getPeriodTenantServices(billing));
                return this.$q.all(promises);
            }).then((results) => {
                this.onComplete({
                    $event: {
                        monthlyBilling: results[0],
                        tenantInvoices: results[1],
                        tenantChargeValues: results[2],
                        periodTenantServices: results[3],
                        backActionStateData: {
                            updatedBillingId: results[0].id,
                            timeStamp: new Date()
                        }
                    }
                });
                this.Messages.success('Configuration saved successfully');
                this.$location.search('monthlyBillingId', results[0].id);
                this.$location.search('step', 2);
                this.$mdStepper('runInvoicingStepper').next();
            }).catch((err) => {
                this.onComplete({
                    $event: {
                        backActionStateData: {
                            updatedBillingId: -1,
                            timeStamp: new Date()
                        }
                    }
                });
                this.Messages.error('Error while saving billing configuration. Please contact support.');
            }).finally(() => {
                this.progressText = null;
            });
        }

        public showValueChangeAnimation(originalBilling: MonthlyTenantBilling, currentBilling: MonthlyTenantBilling) {
            const targetElements = [];
            const propertiesToCheck = [
                'startReadingDate',
                'readingDate',
                'invoiceDate',
                'name'
            ];
            _.each(propertiesToCheck, (property) => {
                const original = originalBilling[property];
                const current = currentBilling[property];
                let isChange = false;
                if (original instanceof Date) {
                    const duration = moment.duration(moment(original).diff(moment(current)));
                    if (Math.floor(Math.abs(duration.asDays())) != 0) {
                        isChange = true;
                    }
                } else {
                    isChange = original != current;
                }
                if (isChange) {
                    targetElements.push(this.$element.find(`[ng-model="vm.monthlyBilling.${property}"]`));
                }
            });
            _.each(targetElements, (t) => t.addClass('highlight'));
            this.$timeout(() => {
                _.each(targetElements, (t) => t.removeClass('highlight'));
            }, 200);
        }

        public onChangeTenantServiceSelection() {
            this.showServiceSelectionOnly = false;
            const tenantServices = this.getSelectedTenantServices();
            this.isErrorTenantService = tenantServices.length === 0;
            if (this.isErrorTenantService) {
                return;
            }
            if (!this.monthlyBilling.id) {
                const originalData = angular.copy(this.monthlyBilling);
                this.monthlyBilling = this.MonthlyBillingService.initDefaultValues(this.monthlyBilling, tenantServices, this.lastBilling, this.building.timeZoneId);
                this.showValueChangeAnimation(originalData, this.monthlyBilling);
            }
        }

        public onChangeInvoiceDate() {
            if (!this.monthlyBilling.id) {
                this.setSuggestedName();
            }
        }

        public setSuggestedName() {
            const tenantServices = this.getSelectedTenantServices();
            this.monthlyBilling.name = this.MonthlyBillingService.getSuggestedMonthlyBillingName(this.monthlyBilling.invoiceDate, tenantServices);
        }

        public checkErrorMessage() {
            if (this.isErrorTenantService) {
                const tenantServices = this.getSelectedTenantServices();
                if (tenantServices.length != 0) {
                    this.isErrorTenantService = false;
                }
            }
        }

        public getSelectedTenantServices() {
            const tenantServices = _.filter(_.keys(this.selectedTenantServices), (serviceId) => {
                return this.selectedTenantServices[serviceId];
            });
            const services = _.map(tenantServices, (serviceId) => {
                return _.find(this.tenantServices, { id: parseInt(serviceId) });
            });
            return _.filter(services, (s) => s !== undefined);
        }
    }

    angular
        .module('tenantBilling')
        .component('configurationStep', {
            templateUrl: 'app/tenantBilling/invoicing/run/components/configurationStep/configurationStep.html',
            controller: ConfigurationStepCtrl ,
            controllerAs: 'vm',
            bindings: {
                monthlyBilling: '<',
                lastBilling: '<',
                tenantServices: '<',
                periodTenantServices: '<',
                building: '<',
                step: '<',
                onComplete: '&'
            }
        });
}
