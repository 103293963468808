namespace aq.ui {

    import ActivityMessage = aq.models.activity.ActivityMessage;

    interface IBindings {
        messages: ActivityMessage[];
    }

    // Reading on component lifecycle hooks https://toddmotto.com/angular-1-5-lifecycle-hooks
    class _ActivityMessages implements IBindings {
        public messages;
        public noMessagesText: string;
        private currentUser: aq.common.models.User;
        private interval: ng.IPromise<void>;
        private now: number;

        /* @ngInject */
        constructor(
            private UserService,
            private resolver: aq.services.Resolver,
            private ActivityService: aq.services.ActivityService,
            private $interval: ng.IIntervalService
        ) {
            this.now = moment().valueOf();
            this.interval = this.$interval(() => {
                this.now = moment().valueOf();
            }, 60000);
            this.resolver(async () => {
                // @ts-ignore
                this.currentUser = await UserService.getUser();
            });
        }

        public $onDestroy() {
            if (this.interval) {
                this.$interval.cancel(this.interval);
            }
        }

        public $onChanges(changes) {
            if (changes.messages) {
                this.messages = angular.copy(changes.messages.currentValue);
            }
        }
    }

    const ActivityMessages: ng.IComponentOptions = {
        controller: _ActivityMessages,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/activities/messages/messages.html',
        bindings:  {
            messages: '<',
            noMessagesText: '<'
        }
    };

    angular
        .module('aq.ui')
        .component('activityMessages', ActivityMessages);

}
