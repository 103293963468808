namespace aq.energyStarApi {
    interface EsProperty {
        id: string;
        propertyId: number;
        buildings: [{
            buildingId: number;
            energyStarPropertyId: string;
        }];
    }

    export class EnergyStarApiService {
        private baseUrl: string;
        /* @ngInject */
        constructor(
            private Auth: aq.services.Auth,
            private AuthToken: string,
            private $location: ng.ILocationService,
            private $http: ng.IHttpService,
            private AQ_API_URL: string,
            private resolver: aq.services.Resolver
        ) {
            this.baseUrl = `${this.AQ_API_URL}energy-star/v1`;
        }

        public async getEnergyStarScoreForBuilding(
            accountId: string,
            buildingId: string
        ): Promise<aq.common.models.EnergyStarScoreResponse> {
            const energyStarUrl = this.getEnergyStarUrl(accountId, false) + `/scores/${buildingId}`;
            return this.sendRequest(energyStarUrl);
        }

        public getEnergyStarUrl(accountId: string, isAccountsParentRoute?: boolean) {
            return isAccountsParentRoute
                ? `${this.baseUrl}/accounts/${accountId}`
                : this.baseUrl;
        }

        public async sendRequest(reqUrl: string) {
            return this.resolver(async () => {
                const response = await this.$http({
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.AuthToken ? this.AuthToken : this.$location.search().authToken}`
                    },
                    url: reqUrl
                });
                return response.data;
            });
        }

        public async sendPutRequest(reqUrl: string, data: object) {
            return this.resolver(async () => {
                const response = await this.$http({
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.AuthToken ? this.AuthToken : this.$location.search().authToken}`
                    },
                    data: JSON.stringify(data),
                    url: reqUrl,
                    transformResponse: (raw) => raw
                });
                return response.data;
            });
        }

        public async unlinkBuildingFromEnergyStarProperty(accountId, buildingId) {
            const esPropertiesUrl = `${this.baseUrl}/accounts/${accountId}/properties`;
            let esProperties;
            try {
                esProperties = await this.sendRequest(esPropertiesUrl);
            } catch (e) {
                return;
            }
            await Promise.all(esProperties.filter(p => !!p).map(async (esProperty: EsProperty) => {
                const { id: esPropertyId, propertyId, buildings } = esProperty;
                if (!propertyId || !buildings || !buildings.find(b => b.buildingId === buildingId)) {
                    return;
                }
                let unlinkUrl = `${this.baseUrl}/accounts/${accountId}/properties/${propertyId}/link`;
                const remainingBuildingIds = buildings.map(b => b.buildingId).filter(id => id !== buildingId);
                if (remainingBuildingIds.length > 0) {
                    unlinkUrl += `?buildings=${remainingBuildingIds.join(',')}`;
                }
                const remainingBuildingData = { buildingIds: remainingBuildingIds };
                await this.sendPutRequest(unlinkUrl, remainingBuildingData);
                if (remainingBuildingIds.length === 0) {
                    // unlink all time periods/collectors
                    const esMetersUrl = `${this.baseUrl}/properties/${esPropertyId}/meters`;
                    const esMeters = await this.sendRequest(esMetersUrl);

                    await Promise.all(esMeters.map(async (esMeter) => {
                        const { id: esMeterId } = esMeter;
                        const updateTimePeriodUrl = `${this.baseUrl}/meters/${esMeterId}/time-periods`;
                        const timePeriodData = { timePeriods: [] };
                        await this.sendPutRequest(updateTimePeriodUrl, timePeriodData);
                    }));
                }
            }));
        }

    }

    angular
        .module('aq.energyStarApi', ['aq.auth'])
        .service('EnergyStarApiService', EnergyStarApiService);
}
