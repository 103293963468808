namespace aq.propertySettings {
    export class StateAlertContextComponentCtrl extends AbstractAlertComponent {
        public intervalOptions: { label: string, value: string }[];
        public severityOptions: string[];
        public alertOnOptions: string[];
        // bound variables
        public onChange: Function;
        public isReadonly: boolean;
        public form;
        public editMode: 'basic' | 'advanced';

        /* @ngInject */
        constructor() {
            super();
            this.intervalOptions = [{
                label: '1 Min',
                value: '1min'
            }, {
                label: '15 Min',
                value: '15min'
            }, {
                label: 'Hour',
                value: '1h'
            }, {
                label: 'Day',
                value: '1d'
            }];
            this.severityOptions = [LevelSeverity.NORMAL, LevelSeverity.WARNING, LevelSeverity.CRITICAL];
            this.alertOnOptions = [LevelSeverity.WARNING, LevelSeverity.CRITICAL];
        }

        public onAlertChange() {
            if (this.onChange) {
                this.onChange({
                    $event: {
                        alert: this.alert
                    }
                });
            }
        }

        public isValidMin() {
            return this.alert.maxAgeMinutes >= this.getMinForInterval();
        }

        public setDefaultMaxAgeForInterval() {
            this.alert.maxAgeMinutes = this.getDefaultMaxAgeForInterval();
        }

        public getDefaultMaxAgeForInterval() {
            switch (this.alert.interval) {
                case '1min':
                    return 10;
                case '15min':
                    return 60;
                case '1h':
                    return 180;
                case '1d':
                default:
                    return 2880;
            }
        }

        public getMinForInterval() {
            switch (this.alert.interval) {
                case '1min':
                    return 2;
                case '15min':
                    return 30;
                case '1h':
                    return 120;
                case '1d':
                default:
                    return 2880;
            }
        }
    }

    angular.module('properties')
        .component('stateAlertContext', {
            templateUrl: 'app/properties/alerts/directives/stateAlertContext.html',
            bindings: {
                form: '<',
                alert: '<',
                onChange: '&',
                isReadonly: '<?',
                editMode: '<'
            },
            controller: StateAlertContextComponentCtrl
        });
}
