namespace aq.ui {
    class _ActivityIssueDetail {

        private issue;
        private issueDetail;

        /* @ngInject */
        constructor(
            private resolver: aq.services.Resolver,
            private RestangularV3: restangular.IService,
            private activityAlertUtilService: aq.ui.ActivityAlertUtilService
        ) {
        }

        public $onInit(): void {
            this.resolver(async () => {
                this.issueDetail = await this.RestangularV3
                    .one('issues', this.issue)
                    .get({ noCache: moment().valueOf() });
            });
        }

        public getIssueDurationFormatted(issueCreatedOn, issueClosedOn): string {
            let issueDuration = null;
            if (issueCreatedOn) {
                const start = moment(issueCreatedOn).toDate();
                const end = issueClosedOn ? moment(issueClosedOn).toDate() : moment().toDate();
                issueDuration = this.activityAlertUtilService.getDurationFormatted(start, end);
            }
            return issueDuration;
        }

        public formatAlertDate(date: string): string {
            const momentDate = moment(date);
            return momentDate
                .isBefore(moment().subtract(1, 'day').valueOf())
                ? momentDate.format('MMM DD YYYY - h:mm a')
                : momentDate.format('h:mm a');
        }

    }

    export const ActivityIssueDetail: ng.IComponentOptions = {
        controller: _ActivityIssueDetail,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/activities/issueDetail/detail.html',
        bindings: {
            issue: '<'
        }
    };

    angular
        .module('aq.ui')
        .component('activityIssueDetail', ActivityIssueDetail);
}
