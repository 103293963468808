namespace aq.propertySettings {
    export interface ScheduleEventViewModel {
        id: string;
        name: string;
        category: string;
        color: string;
        description: string;
    }
    export class BuildingScheduleCtrl {
        public isCalendarView: boolean;
        public scheduleEventsViewList: ScheduleEventViewModel[];
        public filter: {
            selected: any[];
            options: any[];
        };
        private scheduleEvents: any[];
        private scheduleEventsFiltered: any[];
        private propertySettingsAccess: common.models.AppAccessObject;
        private isAqAdmin: boolean;
        /* @ngInject */
        constructor(
            public $mdDialog: ng.material.IDialogService,
            private $mdMedia: ng.material.IMedia,
            private buildingSchedule,
            private ruleCategories,
            private BuildingScheduleHelperService: BuildingScheduleHelperService,
            private Messages: aq.services.Messages,
            private Errors,
            private Restangular: restangular.IService,
            private RestangularV3: restangular.IService,
            private authAccess: common.models.AuthAppAccess,
            private UserService,
            private buildingId: number
        ) {
            this.propertySettingsAccess = this.authAccess['Property Settings'];
            this.isCalendarView = false;
            this.buildingSchedule.all('rules').getList().then((result) => {
                this.scheduleEvents = result;
                this.scheduleEventsFiltered = angular.copy(this.scheduleEvents);
                if (this.scheduleEvents.length > 0) {
                    this.isCalendarView = true;
                }
                this.buildView();
            });
            this.isAqAdmin = this.UserService.isAdmin();
        }
        public createEvent() {
            const scheduleEvent: common.models.BuildingScheduleEvent =
                <common.models.BuildingScheduleEvent> this.Restangular.restangularizeElement(this.buildingSchedule, {}, 'rules');
            (this.$mdDialog as any).show({
                controller: 'AddEditScheduleEvent as vm',
                templateUrl: 'app/properties/schedule/actions/addEditScheduleEvent/addEditScheduleEvent.html',
                clickOutsideToClose: false,
                multiple: true,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    scheduleEvent,
                    buildingSchedule: this.buildingSchedule,
                    ruleCategories: this.ruleCategories,
                    isReadonly: false
                }
            }).then((newScheduleEvent: common.models.BuildingScheduleEvent) => {
                if (newScheduleEvent) {
                    const scheduleEventViewModel = this.getScheduleEventViewModel(newScheduleEvent);
                    this.scheduleEventsViewList.push(scheduleEventViewModel);
                    this.scheduleEvents.push(newScheduleEvent);
                    this.buildEventCategoryFilter();
                    this.selectAll();
                }
            });
        }
        public editEvent(scheduleEventModel: ScheduleEventViewModel) {
            const scheduleEvent: common.models.BuildingScheduleEvent = <common.models.BuildingScheduleEvent> this.Restangular.copy(
                _.find(this.scheduleEvents, (se) => se.id == scheduleEventModel.id)
            );
            (this.$mdDialog as any).show({
                controller: 'AddEditScheduleEvent as vm',
                templateUrl: 'app/properties/schedule/actions/addEditScheduleEvent/addEditScheduleEvent.html',
                clickOutsideToClose: false,
                multiple: true,
                fullscreen: (this.$mdMedia('xs') || this.$mdMedia('sm') || this.$mdMedia('md')),
                locals: {
                    scheduleEvent,
                    buildingSchedule: this.buildingSchedule,
                    ruleCategories: this.ruleCategories,
                    isReadonly: !this.propertySettingsAccess.EDIT
                }
            }).then((updatedScheduleEvent: common.models.BuildingScheduleEvent) => {
                if (updatedScheduleEvent) {
                    _.remove(this.scheduleEvents, (se) => se.id == updatedScheduleEvent.id);
                    this.scheduleEvents.push(updatedScheduleEvent);
                    _.remove(this.scheduleEventsViewList, (se) => se.id == updatedScheduleEvent.id);
                    const scheduleEventViewModel = this.getScheduleEventViewModel(updatedScheduleEvent);
                    this.scheduleEventsViewList.push(scheduleEventViewModel);
                    this.buildEventCategoryFilter();
                    this.selectAll();
                }
            });
        }
        public deleteEvent(scheduleEventModel: ScheduleEventViewModel) {
            const confirm = this.$mdDialog
                .confirm()
                .title('Are you sure you want to delete this Building Schedule Event?')
                .textContent('This will also remove any alerts associated with the event.')
                .ok('Confirm')
                .cancel('Cancel');

            return this.$mdDialog
                .show(confirm)
                .then(() => {
                    const eventToDelete: common.models.BuildingScheduleEvent = _
                        .find(this.scheduleEvents, (event) => event.id == scheduleEventModel.id);
                    this.RestangularV3.one("calendars", this.buildingSchedule.id).one("rules", eventToDelete.id).customDELETE("", {buildingId: this.buildingId}).then(() => {
                        this.Messages.info('Building Schedule Event deleted');
                        _.remove(this.scheduleEvents, (se) => se.id == scheduleEventModel.id);
                        _.remove(this.scheduleEventsFiltered, (se) => se.id == scheduleEventModel.id);
                        _.remove(this.scheduleEventsViewList, (se) => se.id == scheduleEventModel.id);
                        this.buildEventCategoryFilter();
                    }, (err) => {
                        this.Errors.forCRUD('DELETE');
                    });
                });
        }
        public setListView() {
            this.isCalendarView = false;
        }
        public setCalendarView() {
            this.isCalendarView = true;
        }
        public buildView() {
            this.buildEventListView();
            this.buildEventCategoryFilter();
        }
        public onEventClickFn(calendarEvent: aq.services.CalendarEventModel) {
            if (!calendarEvent || !calendarEvent.buildingScheduleEventId) {
                return;
            }
            const targetEvent: ScheduleEventViewModel = _.find(this.scheduleEventsViewList, (e) => e.id == calendarEvent.buildingScheduleEventId);
            if (!targetEvent) {
                return;
            }
            this.editEvent(targetEvent);
        }
        public applyFilter() {
            this.scheduleEventsFiltered = _.filter(this.scheduleEvents, (se) => {
                return _.some(this.filter.selected, (selectedCategory) => selectedCategory.id == se.category);
            });
            this.buildEventListView();
        }
        public selectAll() {
            this.filter.selected = this.filter.options;
            this.applyFilter();
        }
        public showCreateDefaultEventsConfirmation() {
            const confirm = this.$mdDialog
                .confirm()
                .title('Are you sure you want to create all missing default events?')
                .ok('Confirm')
                .cancel('Cancel');

            return this.$mdDialog
                .show(confirm)
                .then(() => {
                    this.RestangularV3.one('buildings', this.buildingId).customPUT(null, 'backfill-calendar')
                        .then(() => {
                            this.Messages.success('Successfully created default calendar events');
                        })
                        .catch(() => {
                            this.Messages.error('Error creating default calendar events');
                        });
                });
        }
        private buildEventListView() {
            this.scheduleEventsViewList = [];
            _.forEach(this.scheduleEventsFiltered, (se) => {
                const scheduleEventViewModel = this.getScheduleEventViewModel(se);
                this.scheduleEventsViewList.push(scheduleEventViewModel);
            });
        }
        private buildEventCategoryFilter() {
            const categoryIds = _.uniq(_.map(this.scheduleEvents, (se) => se.category));
            const availableRuleCategories = _.filter(this.ruleCategories, (rc) => _.some(categoryIds, (id) => id == rc.id));
            this.filter = {
                selected: availableRuleCategories,
                options: availableRuleCategories
            };
        }
        private getScheduleEventViewModel(scheduleEvent: common.models.BuildingScheduleEvent): ScheduleEventViewModel {
            return {
                id: scheduleEvent.id,
                name: scheduleEvent.name,
                category: scheduleEvent.categoryName,
                color: scheduleEvent.color,
                description: this.getEventDescription(scheduleEvent)
            };
        }
        private getEventDescription(scheduleEvent: common.models.BuildingScheduleEvent): string {
            return this.BuildingScheduleHelperService
                .getHumanReadableEventDescription(
                    scheduleEvent.week,
                    scheduleEvent.timeStart,
                    scheduleEvent.timeEnd,
                    scheduleEvent.dateStart,
                    scheduleEvent.dateEnd
                );
        }
    }
    angular.module('properties').controller('BuildingScheduleCtrl', BuildingScheduleCtrl);
}
