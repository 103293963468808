namespace aq.dashboard.widgets {
    export class EmissionsData {
        /* @ngInject */
        constructor(private DataService, private $q: any, private OptionsService, private Restangular, private $filter: ng.IFilterService) {

        }

        public getAllSavingsData(config, building, tileRef): any {
            let promises = {};
            let currentPeriod = {};
            if(config.duration) {
                currentPeriod = {
                    end: moment().startOf('month').subtract(1, 'months'),
                    start: moment().startOf('month').subtract(config.duration + 1, 'months')
                };
            }
            else {
                currentPeriod = {
                    end: moment().startOf('month').subtract(1, 'months'),
                    start: moment().startOf('month').subtract(25, 'months')
                };
                let interval = "1mon";
            }

            return this.Restangular.one("accounts", building.account).get({single: true}).then((account) => {
                    return this.OptionsService.init(account.id, account.measurementSystem, account.currencyUnit).then(() => {
                        _.map(config.tiles, (tile:any) => {
                            let unit = this.OptionsService.getUnitLabelByMeasure(tileRef[tile].measure);
                            let multiplier = tileRef[tile].multiplier;
                            let rawUnitMultiplier = tileRef[tile].rawUnitMultiplier;
                            let promiseCurrent = this.getSavingsData(building, currentPeriod, unit, tile, multiplier, rawUnitMultiplier, this.$filter<Function>('toUnit'));

                            promises[tile] = promiseCurrent;
                        })
                        return this.$q.all(promises);
                    });
            });
        }

        public getSavingsData(building, period, unit, tile, multiplier, rawUnitMultiplier, filter): ng.IPromise<any[]> {
            const collection = this.Restangular.one("accounts", building.account).one("buildings", building.id);
            return this.DataService.data(collection, '1mon', period.start, period.end, unit).then((data) => {
                let rawValue = this.getSavingsTotal(data, filter, unit);
                let sum = 0;
                if(rawUnitMultiplier) {
                    sum = rawUnitMultiplier * rawValue;
                }
                else {
                    sum = rawValue;
                }

                let convertedSum = rawValue * multiplier;

                return  {
                            tile: tile,
                            sum: sum,
                            convertedSum: convertedSum
                        };
            });
        }

        public getSavingsTotal(data, filter, unit):any {
            let savingsSum = 0;
            let offset = Math.floor(data["values"].length / 2);
            for(let i = 0; i < data["values"].length / 2; i++) {
                let currentMonthVal = Math.round(filter(data.values[i+offset], unit));
                let previousMonthVal = Math.round(filter(data.values[i], unit));
                if(currentMonthVal > 0 && previousMonthVal > 0) {
                    savingsSum += (previousMonthVal - currentMonthVal);
                }
            }
            return savingsSum;
        }
    }
    angular.module('aq.dashboard.widgets').service('EmissionsData', EmissionsData);
}
