angular.module('aq-main').run(function ($httpBackend) {
    const regex = {
        list: /\/activities\/v1\?/,
        meta: /\/activities\/v1\/.{36}/
    };

    const activityTypes = [
        aq.models.activity.ActivityType.ISSUE,
        aq.models.activity.ActivityType.EVENT,
        aq.models.activity.ActivityType.OBSERVATION,
        aq.models.activity.ActivityType.REMINDER,
        aq.models.activity.ActivityType.SUGGESTION
    ];

    const activityContextTypes = [
        aq.models.activity.ActivityContextType.ALERT,
        aq.models.activity.ActivityContextType.AID_ISSUE
    ];

    const generateSimpleActivityResponse = (day: number) => {
        const contextType = _.sample(activityContextTypes);

        const activity = new aq.models.activity.SimpleActivityResponse();
        // @ts-ignore
        activity.id = uuidv4();
        activity.context = { type: contextType };
        // noinspection TypeScriptUnresolvedFunction
        activity.createdAt = moment().subtract({days: day}).toISOString();
        // noinspection TypeScriptUnresolvedFunction
        activity.updatedAt = moment(activity.createdAt).add({days: _.random(0, (day - 1 || 0))}).toISOString();

        if (contextType === aq.models.activity.ActivityContextType.AID_ISSUE) {
            // Generate an AID Issue activity
            activity.type = aq.models.activity.ActivityType.OBSERVATION;
            activity.title = `[MOCK] Aquicore detected an {issue} on {date}`;
        } else {
            // Generate a random activity
            activity.type = _.sample(activityTypes);
            activity.title = `[MOCK] This is a '${activity.type.toLowerCase()}' activity`;
        }

        activity.messages = _.random(0, 10);
        return activity;
    };

    const getListResponse = (params: aq.models.activity.ActivityQueryParams) => {
        const response = new aq.models.activity.ActivityListResponse();
        const { start, end, type } = params;
        // noinspection TypeScriptUnresolvedFunction
        const startDate = moment(start);
        // noinspection TypeScriptUnresolvedFunction
        const endDate = moment(end);
        const range = Math.abs(startDate.diff(endDate, 'days'));
        for (let day = 0; day < range; day++) {
            for (let i = 0; i < _.random(1, 5); i++) {
                const activity = generateSimpleActivityResponse(day);
                response.payload.push(activity);
            }
        }
        response.count = response.payload.length;
        if (type && type.length > 0) {
            response.payload = response.payload.filter(activity => type.includes(activity.type));
        }
        return response;
    };

    const getMetaResponse = () => {
        const result = new aq.models.activity.ActivityResponse();
        result.building = 22485218;
        result.context = {
            type: _.sample(activityContextTypes)
        };

        // @ts-ignore
        const newUuid: string = uuidv4();
        if (result.context.type === aq.models.activity.ActivityContextType.AID_ISSUE) {
            result.context.aidissue = newUuid;
        }

        result.messages = [];
        for (let i = 0; i < _.random(15, 20); i++) {
            const message = new aq.models.activity.ActivityMessage();
            // @ts-ignore
            message.id = uuidv4();
            const whoReplied = i % 2 === 0;
            const body: string[] = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`.split(' ');
            message.body = body.slice(0, _.random(5, body.length)).join(' ');
            message.author = new aq.models.activity.ActivityMessageAuthor();
            message.author.id = whoReplied ? 1234567 : 21888863;
            message.author.firstName = whoReplied ? 'joe' : 'trevor';
            message.author.lastName = whoReplied ? 'otherguy' : 'atlas';
            message.author.image = whoReplied ? 'https://fillmurray.com/300/300' : 'https://avatars2.githubusercontent.com/u/5009188?s=460&v=4';
            message.author.userName = `${message.author.firstName}.${message.author.lastName}`;

            message.createdAt = moment().subtract({days: i}).subtract({hours: _.random(0, 24)}).subtract({minutes: _.random(0, 60)}).toISOString();
            message.media = [];
            result.messages.push(message);
        }
        return result;
    };

    MockContext('activities', () => {
        $httpBackend.whenGET(regex.meta).respond((method, url, data) => {
            // @ts-ignore
            const response = getMetaResponse();
            return [200, response, {}];
        });

        $httpBackend.whenGET(regex.list).respond((method, url, data) => {
            // @ts-ignore
            const queryString = url.substring(url.indexOf('?') + 1);
            const params = MockHelpers.queryStringParse(queryString);
            const response = getListResponse(params as any);
            return [200, response, {}];
        });
    });
});
