namespace aq.common {
    export class DataStore {

        private cache: {[key: string]: any[]};

        /* @ngInject */
        constructor(private $q: ng.IQService) {
            this.cache = {};
        }

        // always refreshes the cache
        public getList<T>(resource: restangular.IElement, route?: string, params?: any) {
            return resource.getList(route, params).then((results) => {
                const key = route || resource.route;
                this.cache[key] = results;
                return this.cache[key];
            });
        }

        public get(resource: restangular.IElement, params?, refreshCache?) {
            const id = parseInt(resource.id);
            if (refreshCache) {
                const indexOf = _.findIndex(this.cache[resource.route], {id});
                if (indexOf  != -1) {
                    this.cache[resource.route].splice(indexOf, 1);
                }
            }
            if (this.cache[resource.route] && _.find(this.cache[resource.route], {id})) {
                return this.$q.when(_.find(this.cache[resource.route], {id}));
            }
            let getPromise;
            if (params) {
                getPromise = resource.get(params);
            } else {
                getPromise = resource.get('');
            }
            return getPromise.then((result) => {
                if (_.isArrayLike(result) && result.length === 0 || _.isNil(result)) {
                    return null;
                }
                this.upsertCache(resource, id, result);
                let response = _.find(this.cache[resource.route], {id: result.id});
                // in the case where the object returned is not a standard entity
                if (!response) {
                    response = result;
                }
                return response;
            });
        }

        public update(resource: restangular.IElement, params?): ng.IPromise<any> {
            return resource.save(params).then((result) => {
                this.upsertCache(resource, result.id, result.model || result);
                return _.find(this.cache[resource.route], { id: result.id });
            }).catch((error) => {
                console.log('Error updating:', error);
            });
        }

        public updateWithList(resource: restangular.IElement, params?): ng.IPromise<any> {
            return resource.save(params).then((result) => {
                const ids = [];
                // since a list has no id and we need each indiviual charge id
                result.forEach(element => {
                    ids.push(element.id);
                });
                this.upsertCacheWithList(resource, ids, result.model || result);
                const changes = [];
                ids.forEach(id => {
                    changes.push(_.find(this.cache[resource.route], { id }));
                });
                return changes;
            }).catch((error) => {
                console.log('Error updating:', error);
            });
        }

        public delete(resource: restangular.IElement) {
            return resource.remove().then(() => {
                this.deleteCache(resource, parseInt(resource.id));
            });
        }

        public create(resource: restangular.IElement, elementToCreate, queryParams = {}) {
            return resource.post(elementToCreate, queryParams).then((result) => {
                if (result.model) {
                    angular.merge(result, result.model);
                }
                this.upsertCache(resource, result.id, result);
                return _.find(this.cache[resource.route], {id: result.id});
            });
        }

        private upsertCache(resource: restangular.IElement, id: number, value: any) {
            const { route } = resource;
            if (!this.cache[route]) {
                this.cache[route] = [];
            }
            const indexOf = _.findIndex(this.cache[route], {id});
            if (indexOf != -1) {
                this.cache[route].splice(indexOf, 1, value);
            } else {
                this.cache[route].push(value);
            }
        }

        private upsertCacheWithList(resource: restangular.IElement, ids: number[], value: any) {
            const { route } = resource;
            if (!this.cache[route]) {
                this.cache[route] = [];
            }
            let index = 0;
            ids.forEach(id => {
                const indexOf = _.findIndex(this.cache[route], { id });
                if (indexOf != -1) {
                    this.cache[route].splice(indexOf, 1, value[index]);
                } else {
                    this.cache[route].push(value[index]);
                }
                index += 1;
            });
        }

        private deleteCache(resource: restangular.IElement, id: number) {
            const indexOf = _.findIndex(this.cache[resource.route], id);
            if (this.cache[resource.route]) {
                this.cache[resource.route].splice(indexOf, 1);
            }
        }

        private printCache() {
            console.log(this.cache);
        }
    }
    angular.module('aq.services').service('DataStore', DataStore);
}
