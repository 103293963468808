namespace aq.services {

    export type MappedRoute = {
        monolithUrl: string;
        frontendUrl: string;
    };

    // this route structure should be generated from frontend app
    // (currently, by adding 'logRoutes=true' query parameter in the new frontend url, structure gets logged to console)

    const frontendMappedRoutes: MappedRoute[] = [
        {
            frontendUrl: '/accounts/:accountId/buildings/:buildingId/budget/service',
            monolithUrl: '/accounts/:accountId/buildings/:buildingId/budget/service'
        },
        {
            frontendUrl: '/accounts/:accountId/buildings/:buildingId/energy-insights/consumption',
            monolithUrl: '/accounts/:accountId/energy-insights/building/:buildingId/consumption'
        },
        {
            frontendUrl: '/accounts/:accountId/buildings/:buildingId/tenant-billing/invoicing',
            monolithUrl: '/accounts/:accountId/tenant-billing/building/:buildingId/invoicing'
        },
        {
            frontendUrl: '/accounts/:accountId/buildings/:buildingId/energy-insights/baseloadV2',
            monolithUrl: '/accounts/:accountId/energy-insights/building/:buildingId/baseloadV2'
        },
        {
            frontendUrl: '/accounts/:accountId/buildings/:buildingId/energy-insights/peak-demand',
            monolithUrl: '/accounts/:accountId/energy-insights/building/:buildingId/peak-demand'
        },
        {
            frontendUrl: '/accounts/:accountId/buildings/:buildingId/budget/year',
            monolithUrl: '/accounts/:accountId/buildings/:buildingId/budget/year'
        },
        {
            frontendUrl: '/accounts/:accountId/buildings/:buildingId/energy-insights/weatherNormalization',
            monolithUrl: '/accounts/:accountId/energy-insights/building/:buildingId/weatherNormalization'
        },
        {
            frontendUrl: '/accounts/:accountId/buildings/:buildingId/tenant-billing/setup',
            monolithUrl: '/accounts/:accountId/tenant-billing/building/:buildingId/setup'
        },
        {
            frontendUrl: '/accounts/:accountId/buildings/:buildingId/property-settings/building/configuration',
            monolithUrl: '/accounts/:accountId/property-settings/buildings/:buildingId/building/configuration'
        },
        {
            frontendUrl: '/accounts/:accountId/buildings/:buildingId/tenant-billing/tenants',
            monolithUrl: '/accounts/:accountId/tenant-billing/building/:buildingId/tenants'
        },
        {
            frontendUrl: '/accounts/:accountId/buildings/:buildingId/property-settings/spaces',
            monolithUrl: '/accounts/:accountId/property-settings/buildings/:buildingId/spaces'
        },
        {
            frontendUrl: '/accounts/:accountId/buildings/:buildingId/property-settings/alerts',
            monolithUrl: '/accounts/:accountId/property-settings/buildings/:buildingId/alerts'
        },
        {
            frontendUrl: '/accounts/:accountId/buildings/:buildingId/budget/budgets',
            monolithUrl: '/accounts/:accountId/buildings/:buildingId/budget/budgets'
        },
        {
            frontendUrl: '/accounts/:accountId/buildings/:buildingId/property-settings/targets',
            monolithUrl: '/accounts/:accountId/property-settings/buildings/:buildingId/targets'
        },
        {
            frontendUrl: '/accounts/:accountId/buildings/:buildingId/property-settings/schedule',
            monolithUrl: '/accounts/:accountId/property-settings/buildings/:buildingId/schedule'
        },
        {
            frontendUrl: '/accounts/:accountId/buildings/:buildingId/budget/utility-accounts',
            monolithUrl: '/accounts/:accountId/buildings/:buildingId/budget/utility-accounts'
        },
        {
            frontendUrl: '/accounts/:accountId/buildings/:buildingId/devices-and-equipment',
            monolithUrl: '/accounts/:accountId/devices-and-equipment/building/:buildingId'
        },
        {
            frontendUrl: '/accounts/:accountId/buildings/:buildingId/property-settings/datacollectors',
            monolithUrl: '/accounts/:accountId/property-settings/buildings/:buildingId/datacollectors'
        },
        {
            frontendUrl: '/accounts/:accountId/buildings/:buildingId/property-settings/sources',
            monolithUrl: '/accounts/:accountId/property-settings/buildings/:buildingId/sources'
        },
        {
            frontendUrl: '/accounts/:accountId/report-center/generated-reports',
            monolithUrl: '/accounts/:accountId/reportCenter/generated-reports'
        },
        {
            frontendUrl: '/accounts/:accountId/report-center/:baseReportId/config',
            monolithUrl: '/accounts/:accountId/reportCenter/:baseReportId/config'
        },
        {
            frontendUrl: '/accounts/:accountId/meter-management/status',
            monolithUrl: '/accounts/:accountId/meter-management/status'
        },
        {
            frontendUrl: '/accounts/:accountId/meter-management/data?name&building',
            monolithUrl: '/accounts/:accountId/meter-management/data?name&building'
        },
        {
            frontendUrl: '/accounts/:accountId/optimization/building',
            monolithUrl: '/accounts/:accountId/optimization/building'
        },
        {
            frontendUrl: '/accounts/:accountId/dashboard/configurable/:dashboardId',
            monolithUrl: '/accounts/:accountId/dashboard/configurable/:dashboardId'
        },
        {
            frontendUrl: '/accounts/:accountId/account-settings',
            monolithUrl: '/accounts/:accountId/settings/platform/general'
        },
        {
            frontendUrl: '/admin/generalSettings/integrationApps',
            monolithUrl: '/admin/configuration/integrationApps'
        },
        {
            frontendUrl: '/admin/generalSettings/templateRoles',
            monolithUrl: '/admin/configuration/template-roles'
        },
        {
            frontendUrl: '/admin/generalSettings/templates',
            monolithUrl: '/admin/configuration/document-templates'
        },
        {
            frontendUrl: '/admin/generalSettings/utilityCompanies',
            monolithUrl: '/admin/configuration/utility-company'
        },
        {
            frontendUrl: '/admin/data',
            monolithUrl: '/admin/dataViewer/accounts'
        },
        {
            frontendUrl: '/admin/userView',
            monolithUrl: '/admin/users'
        },
        {
            frontendUrl: '/admin/accountsView/accounts',
            monolithUrl: '/admin/accounts/accounts'
        },
        {
            frontendUrl: '/admin/accountsView/buildings',
            monolithUrl: '/admin/accounts/buildings'
        },
        {
            frontendUrl: '/admin/accountsView/sources',
            monolithUrl: '/admin/accounts/sources'
        },
        {
            frontendUrl: '/admin/accountsView/schema',
            monolithUrl: '/admin/accounts/schema'
        },
        {
            frontendUrl: '/admin/accountsView/enrollment',
            monolithUrl: '/admin/accounts/enrollment'
        },
        {
            frontendUrl: '/admin/dashboardSettings',
            monolithUrl: '/admin/dashboards'
        },
        {
            frontendUrl: '/admin/internalReports/summaryReports',
            monolithUrl: '/admin/reports/summaryreports'
        },
        {
            frontendUrl: '/admin/internalReports/tenantBillingReport',
            monolithUrl: '/admin/reports/tenantbillingreport'
        },
        {
            frontendUrl: '/admin/internalReports/report-utils',
            monolithUrl: '/admin/reports/report-utils'
        },
        {
            frontendUrl: '/admin/internalReports/performanceReport',
            monolithUrl: '/admin/reports/performancereport'
        },
        {
            frontendUrl: '/admin/internalReports/covidReport',
            monolithUrl: '/admin/reports/covidreport'
        },
        {
            frontendUrl: '/admin/meterManagement/dataCollectors',
            monolithUrl: '/admin/meters/meters'
        },
        {
            frontendUrl: '/admin/meterManagement/weather',
            monolithUrl: '/admin/meters/weather'
        },
        {
            frontendUrl: '/admin/meterManagement/copyData',
            monolithUrl: '/admin/meters/copydata'
        },
        {
            frontendUrl: '/admin/meterManagement/updatemultiplier',
            monolithUrl: '/admin/meters/updatemultiplier'
        },
        {
            frontendUrl: '/admin/meterManagement/management',
            monolithUrl: '/admin/meters/management'
        },
        {
            frontendUrl: '/admin/meterManagement/deviceClasses',
            monolithUrl: '/admin/meters/device-classes'
        },
        {
            frontendUrl: '/admin/meterManagement/devices',
            monolithUrl: '/admin/meters/devices'
        }
    ];

    // these routes are for the migrated pages
    const monolithMappedRoutes: MappedRoute[] = [
        {
            monolithUrl: '/admin/platformSettings/logs',
            frontendUrl: '/admin/platformSettings/logs'
        },
        {
            monolithUrl: 'admin/platformSettings/setup',
            frontendUrl: 'admin/platformSettings/setup'
        },
        {
            monolithUrl: '/accounts/:accountId/notification-center',
            frontendUrl: '/accounts/:accountId/notification-center'
        },
        {
            monolithUrl: '/accounts/:accountId/settings/users',
            frontendUrl: '/accounts/:accountId/settings/users'
        },
        {
            monolithUrl: '/accounts/:accountId/settings/roles',
            frontendUrl: '/accounts/:accountId/settings/roles'
        },
        {
            monolithUrl: '/accounts/:accountId/settings/integrations',
            frontendUrl: '/accounts/:accountId/settings/integrations'
        },
        {
            monolithUrl: '/accounts/:accountId/settings/marketplace',
            frontendUrl: '/accounts/:accountId/settings/marketplace'
        },
        {
            monolithUrl: '/accounts/:accountId/settings/properties',
            frontendUrl: '/accounts/:accountId/settings/properties'
        },
        {
            monolithUrl: '/accounts/:accountId/settings/escalation-policies',
            frontendUrl: '/accounts/:accountId/settings/escalation-policies'
        }
    ];

    export const mappedRoutes = [...frontendMappedRoutes, ...monolithMappedRoutes];

}
