var aq;
(function (aq) {
    var reports;
    (function (reports) {
        var monthlyBuildingEngineer;
        (function (monthlyBuildingEngineer) {
            var MonthlyBulidingEngineerMainCtrl = /** @class */ (function (_super) {
                __extends(MonthlyBulidingEngineerMainCtrl, _super);
                /* @ngInject */
                function MonthlyBulidingEngineerMainCtrl($scope, $filter, MonthlyBuildingEngineerCharts, ReportService, OptionsService, building, electricityData, waterData, gasData, steamData, degreeDaysData, MonthlyOccupancyData, reportTime, $timeout) {
                    var _this = _super.call(this, $timeout) || this;
                    _this.$scope = $scope;
                    _this.$filter = $filter;
                    _this.MonthlyBuildingEngineerCharts = MonthlyBuildingEngineerCharts;
                    _this.ReportService = ReportService;
                    _this.OptionsService = OptionsService;
                    _this.building = building;
                    _this.electricityData = electricityData;
                    _this.waterData = waterData;
                    _this.gasData = gasData;
                    _this.steamData = steamData;
                    _this.degreeDaysData = degreeDaysData;
                    _this.MonthlyOccupancyData = MonthlyOccupancyData;
                    _this.reportTime = reportTime;
                    _this.$timeout = $timeout;
                    _this.latestMonthIndex = parseInt(moment(reportTime).format('M')) - 1;
                    $scope.building = building;
                    $scope.loading = true;
                    ReportService.then(function (report) {
                        $scope.report = report;
                        var reportBuilding = $scope.report.building;
                        $scope.time = angular.copy(reportBuilding.currentTime);
                        $scope.time.subtract(1, 'month');
                        $scope.currentMonth = angular.copy($scope.time);
                        $scope.currentYr = angular.copy($scope.time).format('YYYY');
                        $scope.secondYr = angular.copy($scope.time).subtract(1, 'year').format('YYYY');
                        $scope.thirdYr = angular.copy($scope.time).subtract(2, 'year').format('YYYY');
                        $scope.waterUnit = OptionsService.getUnitLabelByMeasure('WATER');
                        $scope.gasUnit = OptionsService.getUnitLabelByMeasure('GAS');
                        $scope.electricityUnit = OptionsService.getUnitLabelByMeasure('ELECTRICITY');
                        $scope.powerUnit = OptionsService.getUnitLabelByMeasure('POWER');
                        $scope.steamUnit = OptionsService.getUnitLabelByMeasure('STEAM');
                        $scope.currencyUnit = OptionsService.currencyUnit();
                        $scope.loading = false;
                        $scope.monthIndex = $scope.currentMonth.format('M');
                        $scope.occupancyData = MonthlyOccupancyData.getMonthlyOccupancy(reportBuilding, $scope.monthIndex, $scope.time);
                        _this.setData();
                        _this.calculateEnergyEfficiency();
                        _this.formatTableValues();
                        _this.setChartData();
                        _this.notifyDocumentReady();
                    }).catch(function () {
                        _this.isDocumentError = true;
                    });
                    return _this;
                }
                MonthlyBulidingEngineerMainCtrl.prototype.setData = function () {
                    var monthIndex = this.$scope.currentMonth.format('M') - 1;
                    var currentMonth = this.$scope.currentMonth.format('M');
                    var charges = {
                        electric: [],
                        gas: [],
                        steam: [],
                        water: []
                    };
                    this.$scope.data = {};
                    this.$scope.data.currentYear = {};
                    this.$scope.data.secondYear = {};
                    this.$scope.data.thirdYear = {};
                    this.$scope.data.thirdYear.energy = this.combineChartData(this.electricityData.thirdYearEnergy, this.electricityData.thirdYearEnergyUtility, this.$filter('toUnit'), this.$scope.electricityUnit);
                    this.$scope.data.thirdYear.power = this.combineChartData(this.electricityData.thirdYearPower, this.electricityData.thirdYearPowerUtility, this.$filter('toUnit'), this.$scope.powerUnit);
                    this.$scope.data.thirdYear.water = this.combineChartData(this.waterData.thirdYearWater, this.waterData.thirdYearWaterUtility, this.$filter('toUnit'), this.$scope.waterUnit);
                    this.$scope.data.thirdYear.gas = this.combineChartData(this.gasData.thirdYearGas, this.gasData.thirdYearGasUtility, this.$filter('toUnit'), this.$scope.gasUnit);
                    this.$scope.data.thirdYear.steam = this.combineChartData(this.steamData.thirdYearSteam, this.steamData.thirdYearSteamUtility, this.$filter('toUnit'), this.$scope.steamUnit);
                    this.$scope.data.thirdYear.degreeDays = this.degreeDaysData.thirdYearDegreeDays;
                    this.$scope.data.thirdYear.startDate = this.electricityData.thirdYearEnergy.reqParams.start;
                    this.$scope.data.secondYear.energy = this.combineChartData(this.electricityData.secondYearEnergy, this.electricityData.secondYearEnergyUtility, this.$filter('toUnit'), this.$scope.electricityUnit);
                    this.$scope.data.secondYear.power = this.combineChartData(this.electricityData.secondYearPower, this.electricityData.secondYearPowerUtility, this.$filter('toUnit'), this.$scope.powerUnit);
                    this.$scope.data.secondYear.water = this.combineChartData(this.waterData.secondYearWater, this.waterData.secondYearWaterUtility, this.$filter('toUnit'), this.$scope.waterUnit);
                    this.$scope.data.secondYear.gas = this.combineChartData(this.gasData.secondYearGas, this.gasData.secondYearGasUtility, this.$filter('toUnit'), this.$scope.gasUnit);
                    this.$scope.data.secondYear.steam = this.combineChartData(this.steamData.secondYearSteam, this.steamData.secondYearSteamUtility, this.$filter('toUnit'), this.$scope.steamUnit);
                    this.$scope.data.secondYear.degreeDays = this.degreeDaysData.secondYearDegreeDays;
                    this.$scope.data.secondYear.startDate = this.electricityData.secondYearEnergy.reqParams.start;
                    this.$scope.data.currentYear.energy = this.combineChartData(this.electricityData.currentYearEnergy, this.electricityData.currentYearEnergyUtility, this.$filter('toUnit'), this.$scope.electricityUnit);
                    this.$scope.data.currentYear.power = this.combineChartData(this.electricityData.currentYearPower, this.electricityData.currentYearPowerUtility, this.$filter('toUnit'), this.$scope.powerUnit);
                    this.$scope.data.currentYear.water = this.combineChartData(this.waterData.currentYearWater, this.waterData.currentYearWaterUtility, this.$filter('toUnit'), this.$scope.waterUnit);
                    this.$scope.data.currentYear.gas = this.combineChartData(this.gasData.currentYearGas, this.gasData.currentYearGasUtility, this.$filter('toUnit'), this.$scope.gasUnit);
                    this.$scope.data.currentYear.steam = this.combineChartData(this.steamData.currentYearSteam, this.steamData.currentYearSteamUtility, this.$filter('toUnit'), this.$scope.steamUnit);
                    this.$scope.data.currentYear.degreeDays = this.degreeDaysData.currentYearDegreeDays;
                    this.$scope.data.currentYear.startDate = this.electricityData.currentYearEnergy.reqParams.start;
                    charges.electric = this.electricityData.currentYearElectricityUtility.spending.data.map(function (data) { return data.datum.utilityBill; });
                    charges.gas = this.gasData.currentYearGasUtilityV3.spending.data.map(function (data) { return data.datum.utilityBill; });
                    charges.steam = this.steamData.currentYearSteamUtilityV3.spending.data.map(function (data) { return data.datum.utilityBill; });
                    charges.water = this.waterData.currentYearWaterUtilityV3.spending.data.map(function (data) { return data.datum.utilityBill; });
                    this.$scope.data.currentYear.energy.budgetItems = this.getBudgetData('electricity');
                    this.$scope.data.currentYear.gas.budgetItems = this.getBudgetData('gas');
                    this.$scope.data.currentYear.water.budgetItems = this.getBudgetData('water');
                    this.$scope.data.currentYear.steam.budgetItems = this.getBudgetData('steam');
                    // in the off chance the endpoint didn't return data, lets use the other data 
                    this.$scope.data.currentYear.energy.actuals = (charges.electric.length != 0) ? charges.electric : this.electricityData.currentYearEnergyUtility.charges;
                    this.$scope.data.currentYear.gas.actuals = (charges.gas.length != 0) ? charges.gas : this.gasData.currentYearGasUtility.charges;
                    this.$scope.data.currentYear.water.actuals = (charges.water.length != 0) ? charges.water : this.waterData.currentYearWaterUtility.charges;
                    this.$scope.data.currentYear.steam.actuals = (charges.steam.length != 0) ? charges.water : this.steamData.currentYearSteamUtility.charges;
                    /**
                     * Get current month from last year as second usage, if it doesn't
                     * exist then take second month from this year
                     */
                    if (this.$scope.data.currentYear.energy.values[monthIndex]) {
                        this.$scope.energyUsage = this.$scope.data.currentYear.energy.values[monthIndex].value;
                        this.$scope.secondEnergyUsage = this.$scope.data.secondYear.energy.values[monthIndex].value !== null
                            ? this.$scope.data.secondYear.energy.values[monthIndex].value
                            : this.$scope.data.currentYear.energy.values[monthIndex - 1].value;
                    }
                    if (this.$scope.data.currentYear.gas.values[monthIndex]) {
                        this.$scope.gasUsage = this.$scope.data.currentYear.gas.values[monthIndex].value;
                        this.$scope.secondGasUsage = this.$scope.data.secondYear.gas.values[monthIndex].value !== null
                            ? this.$scope.data.secondYear.gas.values[monthIndex].value
                            : this.$scope.data.currentYear.gas.values[monthIndex - 1].value;
                    }
                    if (this.$scope.data.currentYear.water.values[monthIndex]) {
                        this.$scope.waterUsage = this.$scope.data.currentYear.water.values[monthIndex].value;
                        this.$scope.secondWaterUsage = this.$scope.data.secondYear.water.values[monthIndex].value !== null
                            ? this.$scope.data.secondYear.water.values[monthIndex].value
                            : this.$scope.data.currentYear.water.values[monthIndex - 1].value;
                    }
                    if (this.$scope.data.currentYear.steam.values[monthIndex]) {
                        this.$scope.steamUsage = this.$scope.data.currentYear.steam.values[monthIndex].value;
                        this.$scope.secondSteamUsage = this.$scope.data.secondYear.steam.values[monthIndex].value !== null
                            ? this.$scope.data.secondYear.steam.values[monthIndex].value
                            : this.$scope.data.currentYear.steam.values[monthIndex - 1].value;
                    }
                    /**
                     * Get current month from last year as second demand, if it doesn't
                     * exist then take second month from this year
                     */
                    this.$scope.secondDemand = this.$scope.data.secondYear.power.values[monthIndex] !== null
                        ? this.$scope.data.secondYear.power.values[monthIndex]
                        : this.$scope.data.currentYear.power.values[monthIndex - 1];
                    this.$scope.currentHDD = this.$scope.data.currentYear.degreeDays.hdd.values[monthIndex];
                    this.$scope.currentCDD = this.$scope.data.currentYear.degreeDays.cdd.values[monthIndex];
                    /**
                     * Get current month from last year as second HDD/CDD, if it doesn't
                     * exist then take second month from this year
                     */
                    this.$scope.secondHDD = this.$scope.data.secondYear.degreeDays.hdd.values[monthIndex] !== null
                        ? this.$scope.data.secondYear.degreeDays.hdd.values[monthIndex]
                        : this.$scope.data.currentYear.degreeDays.hdd.values[monthIndex - 1];
                    this.$scope.secondCDD = this.$scope.data.secondYear.degreeDays.cdd.values[monthIndex] !== null
                        ? this.$scope.data.secondYear.degreeDays.cdd.values[monthIndex]
                        : this.$scope.data.currentYear.degreeDays.cdd.values[monthIndex - 1];
                };
                MonthlyBulidingEngineerMainCtrl.prototype.combineChartData = function (realTimeValues, utilityBillValues, filter, unit) {
                    var combinedData = {};
                    var length = realTimeValues.values.length;
                    combinedData.values = [];
                    for (var i = 0; i < length; i++) {
                        if (realTimeValues.values[i]) {
                            var monthData = filter(realTimeValues.values[i], unit);
                            combinedData.values.push({
                                value: monthData,
                                isMissingData: false
                            });
                        }
                        else if (realTimeValues.missingIntervalValues && realTimeValues.missingIntervalValues[i]) {
                            var monthData = filter(realTimeValues.missingIntervalValues[i], unit);
                            combinedData.values.push({
                                value: monthData,
                                isMissingData: true
                            });
                        }
                        else {
                            // if realTimeValues do not exist for month, use utilityBillValues
                            var monthData = utilityBillValues.values[i];
                            combinedData.values.push({
                                value: monthData,
                                isMissingData: false
                            });
                        }
                    }
                    return combinedData;
                };
                MonthlyBulidingEngineerMainCtrl.prototype.calculateEnergyEfficiency = function () {
                    var currentYear = this.$scope.data.currentYear;
                    var secondYear = this.$scope.data.secondYear;
                    var thirdYear = this.$scope.data.thirdYear;
                    /* calculate energy efficiency values */
                    currentYear.degreeDays.total = {};
                    currentYear.degreeDays.total.values = [];
                    currentYear.energyEfficiency = angular.copy(currentYear.energy);
                    currentYear.energyEfficiency.values = [];
                    secondYear.degreeDays.total = {};
                    secondYear.degreeDays.total.values = [];
                    secondYear.energyEfficiency = angular.copy(secondYear.energy);
                    secondYear.energyEfficiency.values = [];
                    thirdYear.degreeDays.total = {};
                    thirdYear.degreeDays.total.values = [];
                    thirdYear.energyEfficiency = angular.copy(thirdYear.energy);
                    thirdYear.energyEfficiency.values = [];
                    for (var i = 0; i < currentYear.degreeDays.hdd.values.length; i++) {
                        currentYear.degreeDays.total.values[i] = currentYear.degreeDays.hdd.values[i] + currentYear.degreeDays.cdd.values[i];
                        currentYear.energyEfficiency.values[i] = {
                            value: currentYear.degreeDays.total.values[i] > 0
                                ? currentYear.energy.values[i].value / currentYear.degreeDays.total.values[i]
                                : 0,
                            isMissingData: currentYear.energy.values[i].isMissingData
                        };
                    }
                    for (var i = 0; i < secondYear.degreeDays.hdd.values.length; i++) {
                        secondYear.degreeDays.total.values[i] = secondYear.degreeDays.hdd.values[i] + secondYear.degreeDays.cdd.values[i];
                        secondYear.energyEfficiency.values[i] = {
                            value: secondYear.degreeDays.total.values[i] > 0
                                ? secondYear.energy.values[i].value / secondYear.degreeDays.total.values[i]
                                : 0,
                            isMissingData: secondYear.energy.values[i].isMissingData
                        };
                    }
                    for (var i = 0; i < thirdYear.degreeDays.hdd.values.length; i++) {
                        thirdYear.degreeDays.total.values[i] = thirdYear.degreeDays.hdd.values[i] + thirdYear.degreeDays.cdd.values[i];
                        thirdYear.energyEfficiency.values[i] = {
                            value: thirdYear.degreeDays.total.values[i] > 0
                                ? thirdYear.energy.values[i].value / thirdYear.degreeDays.total.values[i]
                                : 0,
                            isMissingData: thirdYear.energy.values[i].isMissingData
                        };
                    }
                    var monthIndex = this.$scope.currentMonth.format('M') - 1;
                    this.$scope.energyEfficiency = currentYear.energyEfficiency.values[monthIndex].value;
                    this.$scope.secondEnergyEfficiency = secondYear.energyEfficiency.values[monthIndex].value !== null
                        ? secondYear.energyEfficiency.values[monthIndex].value
                        : currentYear.energyEfficiency.values[monthIndex - 1].value;
                };
                MonthlyBulidingEngineerMainCtrl.prototype.formatTableValues = function () {
                    this.$scope.tableData = [];
                    var predicate = function (item) {
                        return item === null || item === 0;
                    };
                    var energyTableJoin = (this.$scope.data.currentYear.energy.values)
                        .concat(this.$scope.data.secondYear.energy.values, this.$scope.data.thirdYear.energy.values);
                    this.$scope.tableData.isEnergyEmpty = _.every(energyTableJoin, predicate);
                    var energyEfficiencyTableJoin = (this.$scope.data.currentYear.energyEfficiency.values)
                        .concat(this.$scope.data.secondYear.energyEfficiency.values, this.$scope.data.thirdYear.energyEfficiency.values);
                    this.$scope.tableData.isEnergyEfficiencyEmpty = _.every(energyEfficiencyTableJoin, predicate);
                    var gasTableJoin = (this.$scope.data.currentYear.gas.values)
                        .concat(this.$scope.data.secondYear.gas.values, this.$scope.data.thirdYear.gas.values);
                    this.$scope.tableData.isGasEmpty = _.every(gasTableJoin, predicate);
                    var steamTableJoin = (this.$scope.data.currentYear.steam.values)
                        .concat(this.$scope.data.secondYear.steam.values, this.$scope.data.thirdYear.steam.values);
                    this.$scope.tableData.isSteamEmpty = _.every(steamTableJoin, predicate);
                    var waterTableJoin = (this.$scope.data.currentYear.water.values)
                        .concat(this.$scope.data.secondYear.water.values, this.$scope.data.thirdYear.water.values);
                    this.$scope.tableData.isWaterEmpty = _.every(waterTableJoin, predicate);
                    var totalTableJoin = (this.$scope.data.currentYear.water.values)
                        .concat(this.$scope.data.currentYear.energy.budgetItems, this.$scope.data.currentYear.gas.budgetItems, this.$scope.data.currentYear.water.budgetItems, this.$scope.data.currentYear.steam.budgetItems, this.$scope.data.currentYear.energy.actuals, this.$scope.data.currentYear.gas.actuals, this.$scope.data.currentYear.water.actuals, this.$scope.data.currentYear.steam.actuals);
                    this.$scope.tableData.isTotalEmpty = _.every(totalTableJoin, predicate);
                    for (var i = 0; i < 12; i++) {
                        this.$scope.tableData[i] = {};
                        this.$scope.tableData[i].waterTable = {};
                        this.$scope.tableData[i].gasTable = {};
                        this.$scope.tableData[i].steamTable = {};
                        this.$scope.tableData[i].energyTable = {};
                        this.$scope.tableData[i].totalTable = {};
                        this.$scope.tableData[i].energyEfficiencyTable = {};
                        this.$scope.tableData[i].month = this.getMonth(i);
                        this.$scope.tableData[i].energyTable.formattedThirdValues = this.$scope.data.thirdYear.energy.values[i];
                        this.$scope.tableData[i].energyTable.formattedSecondValues = this.$scope.data.secondYear.energy.values[i];
                        this.$scope.tableData[i].energyTable.formattedCurrentValues = this.$scope.data.currentYear.energy.values[i];
                        this.$scope.tableData[i].energyTable.formattedCurrentDemand = this.$scope.data.currentYear.power.values[i];
                        this.$scope.tableData[i].energyTable.formattedSecondDemand = this.$scope.data.secondYear.power.values[i];
                        this.$scope.tableData[i].energyTable.formattedThirdDemand = this.$scope.data.thirdYear.power.values[i];
                        this.$scope.tableData[i].energyTable.formattedCurrentBudgeted = this.$scope.data.currentYear.energy.budgetItems[i];
                        this.$scope.tableData[i].energyTable.formattedCurrentActual = this.$scope.data.currentYear.energy.actuals[i];
                        this.$scope.tableData[i].energyEfficiencyTable.formattedSecondEfficiencyValues = this.$scope.data.secondYear.energyEfficiency.values[i];
                        this.$scope.tableData[i].energyEfficiencyTable.formattedCurrentEfficiencyValues = this.$scope.data.currentYear.energyEfficiency.values[i];
                        this.$scope.tableData[i].energyEfficiencyTable.formattedThirdEfficiencyValues = this.$scope.data.thirdYear.energyEfficiency.values[i];
                        this.$scope.tableData[i].energyEfficiencyTable.currentHdd = this.$scope.data.currentYear.degreeDays.hdd.values[i];
                        this.$scope.tableData[i].energyEfficiencyTable.secondHdd = this.$scope.data.secondYear.degreeDays.hdd.values[i];
                        this.$scope.tableData[i].energyEfficiencyTable.thirdHdd = this.$scope.data.thirdYear.degreeDays.hdd.values[i];
                        this.$scope.tableData[i].energyEfficiencyTable.currentCdd = this.$scope.data.currentYear.degreeDays.cdd.values[i];
                        this.$scope.tableData[i].energyEfficiencyTable.secondCdd = this.$scope.data.secondYear.degreeDays.cdd.values[i];
                        this.$scope.tableData[i].energyEfficiencyTable.thirdCdd = this.$scope.data.thirdYear.degreeDays.cdd.values[i];
                        this.$scope.tableData[i].waterTable.formattedSecondWaterValues = this.$scope.data.secondYear.water.values[i];
                        this.$scope.tableData[i].waterTable.formattedCurrentWaterValues = this.$scope.data.currentYear.water.values[i];
                        this.$scope.tableData[i].waterTable.formattedThirdWaterValues = this.$scope.data.thirdYear.water.values[i];
                        this.$scope.tableData[i].waterTable.formattedCurrentBudgeted = this.$scope.data.currentYear.water.budgetItems[i];
                        this.$scope.tableData[i].waterTable.formattedCurrentActual = this.$scope.data.currentYear.water.actuals[i];
                        this.$scope.tableData[i].gasTable.formattedSecondGasValues = this.$scope.data.secondYear.gas.values[i];
                        this.$scope.tableData[i].gasTable.formattedCurrentGasValues = this.$scope.data.currentYear.gas.values[i];
                        this.$scope.tableData[i].gasTable.formattedThirdGasValues = this.$scope.data.thirdYear.gas.values[i];
                        this.$scope.tableData[i].gasTable.formattedCurrentBudgeted = this.$scope.data.currentYear.gas.budgetItems[i];
                        this.$scope.tableData[i].gasTable.formattedCurrentActual = this.$scope.data.currentYear.gas.actuals[i];
                        this.$scope.tableData[i].steamTable.formattedSecondSteamValues = this.$scope.data.secondYear.steam.values[i];
                        this.$scope.tableData[i].steamTable.formattedCurrentSteamValues = this.$scope.data.currentYear.steam.values[i];
                        this.$scope.tableData[i].steamTable.formattedThirdSteamValues = this.$scope.data.thirdYear.steam.values[i];
                        this.$scope.tableData[i].steamTable.formattedCurrentBudgeted = this.$scope.data.currentYear.steam.budgetItems[i];
                        this.$scope.tableData[i].steamTable.formattedCurrentActual = this.$scope.data.currentYear.steam.actuals[i];
                        var cy = this.$scope.data.currentYear;
                        this.$scope.tableData[i].totalTable.formattedCurrentBudgeted = cy.energy.budgetItems[i] + cy.gas.budgetItems[i] + cy.water.budgetItems[i] + cy.steam.budgetItems[i];
                        this.$scope.tableData[i].totalTable.formattedCurrentActual = cy.energy.actuals[i] + cy.gas.actuals[i] + cy.water.actuals[i] + cy.steam.actuals[i];
                    }
                };
                MonthlyBulidingEngineerMainCtrl.prototype.setChartData = function () {
                    var currentData = {
                        data: this.$scope.data.currentYear,
                        year: this.$scope.data.currentYear.startDate
                    };
                    var secondData = {
                        data: this.$scope.data.secondYear,
                        year: this.$scope.data.secondYear.startDate
                    };
                    var thirdData = {
                        data: this.$scope.data.thirdYear,
                        year: this.$scope.data.thirdYear.startDate
                    };
                    var monthlyCharts = new this.MonthlyBuildingEngineerCharts(currentData, secondData, thirdData, this.$scope.report, this.$scope.currentMonth);
                    /* Usage chart data (both report types) */
                    this.$scope.usageEnergyChart = monthlyCharts.getUsageChart('energy', this.$scope.electricityUnit);
                    this.$scope.usageGasChart = monthlyCharts.getUsageChart('gas', this.$scope.gasUnit);
                    this.$scope.usageWaterChart = monthlyCharts.getUsageChart('water', this.$scope.waterUnit);
                    this.$scope.usageSteamChart = monthlyCharts.getUsageChart('steam', this.$scope.steamUnit);
                    /* Efficiency chart data (both report types) */
                    this.$scope.energyEfficiencyChart = monthlyCharts.getEnergyEfficiencyChart('energy', this.$scope.electricityUnit);
                    /* Budgets vs Actuals */
                    this.$scope.totalActualsVsBudgetedChart = monthlyCharts.getTotalActualsVsBudgetedChart();
                };
                MonthlyBulidingEngineerMainCtrl.prototype.getBudgetData = function (measureName) {
                    var _this = this;
                    var budgetItems = [];
                    var viableBudgetOptions = this.$scope.building.targets.filter(function (budget) {
                        return budget.measure.name === measureName && budget.type === 'BUDGET';
                    });
                    var viableBudgetItemOptions = _.flatMap(viableBudgetOptions, function (budget) {
                        return budget.targetItems;
                    });
                    var _loop_1 = function (i) {
                        var budgetItem = _.find(viableBudgetItemOptions, function (item) {
                            var targetedMonth = moment().year(_this.$scope.currentYr).month(i);
                            var budgetItemMonth = moment.utc(item.startDate);
                            return budgetItemMonth.isSame(targetedMonth, 'month');
                        });
                        if (budgetItem) {
                            budgetItems.push(budgetItem.value);
                        }
                        else {
                            budgetItems.push(null);
                        }
                    };
                    for (var i = 0; i < 12; i++) {
                        _loop_1(i);
                    }
                    return budgetItems;
                };
                MonthlyBulidingEngineerMainCtrl.prototype.getMonth = function (index) {
                    return moment.months()[index];
                };
                return MonthlyBulidingEngineerMainCtrl;
            }(reports.BaseDocraptorReportCtrl));
            monthlyBuildingEngineer.MonthlyBulidingEngineerMainCtrl = MonthlyBulidingEngineerMainCtrl;
            angular.module('aq.reports').controller('MonthlyBuildingEngineerMainCtrl', MonthlyBulidingEngineerMainCtrl);
        })(monthlyBuildingEngineer = reports.monthlyBuildingEngineer || (reports.monthlyBuildingEngineer = {}));
    })(reports = aq.reports || (aq.reports = {}));
})(aq || (aq = {}));
