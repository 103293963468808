angular.module('infinite-scroll-pager', ['aq.services.errors']).factory('Pager', function(Errors) {
    var service: any = {};
    
    service.scroller = function(distance, elemGetter, on) {
        return {
                busy: false,
                distance: distance,
                page: 1,
                previousResponse: undefined,
                reset: function() {
                    this.page = 1;
                    this.previousResponse = undefined;
                    this.busy = false;
                },
                nextPage: function() {
                    if (this.busy) return;
                    // do not call api again if number of items is 0 or if it is bellow pageSize (which is 10 by default)
                    if (this.previousResponse && (this.previousResponse.length === 0  || this.previousResponse.length < 10)) return;
                    this.busy = true;
                    var currentPage = angular.copy(this.page);
                    var __this = this;
                    elemGetter(this.page).then(function(elements) {
                        __this.previousResponse = elements;
                        on(elements);
                        __this.busy = false;
                    }, function(response) {
                       __this.busy = false;
                       Errors.forPromise()(response);
                    });
                    this.page = this.page + 1;
                }
            }
    }
    
    return service;
})
