namespace aq.propertySettings {
    export class BuildingDetailOccupancyCtrl {
        public currentOccupancy;
        constructor(
            private $mdDialog: ng.material.IDialogService,
            private BuildingService: aq.services.BuildingService,
            private occupancies,
            private Messages,
            private building,
            private Auth: aq.services.Auth
        ) {
            this.updateOccupancyListAndCurrent();
        }

        public deleteOccupancy(event, occupancy) {
            const confirm = this.$mdDialog
                .confirm()
                .title('Are you sure you want to delete this occupancy?')
                .targetEvent(event)
                .ok('Confirm')
                .cancel('Cancel');

            return this.$mdDialog
                .show(confirm)
                .then(() => {
                    this.processDeleteOccupancy(occupancy);
                });
        }

        public showAddUpdateModal(buildingOccupancy) {
            this.$mdDialog.show({
                controller: 'AddOccupancyModalCtrl as vm',
                templateUrl: 'app/properties/building/addOccupancyModal/addOccupancyModal.html',
                clickOutsideToClose: true,
                locals: {
                    buildingOccupancy
                }
            }).then((occupancy) => {
                this.processAddUpdateOccupancy(occupancy);
            });
        }

        public showEditOccupancyModal(occupancy) {
            occupancy.startDate = new Date(moment(occupancy.startDate).format('YYYY/MM/DD HH:mm:ss ZZ'));
            this.showAddUpdateModal(angular.copy(occupancy));
        }

        public showAddOccupancyModal() {
            const newOccupancy = new BuildingOccupancy();
            newOccupancy.building = this.building.id;
            newOccupancy.startDate = new Date();
            this.showAddUpdateModal(newOccupancy);
        }

        public updateOccupancyListAndCurrent() {
            let latestOccupancy = null;
            const now = moment();
            this.occupancies.forEach((item) => {
                item.startDate = new Date(moment(item.startDate).format('YYYY/MM/DD HH:mm:ss ZZ'));
                const testDate = moment(item.startDate);
                if (testDate.isSameOrBefore(now)) {
                    if (!latestOccupancy || moment(latestOccupancy.startDate).isSameOrBefore(testDate)) {
                        latestOccupancy = item;
                    }
                }
            });
            this.currentOccupancy = latestOccupancy;
        }

        public processDeleteOccupancy(occupancy) {
            this.BuildingService.deleteOccupancy(occupancy)
                .then(() => {
                    this.updateOccupancyListAndCurrent();
                    this.Messages.success('Occupancy removed successfully');
                }).catch(() => {
                    this.Messages.error('Error deleting occupancy');
                });
        }

        public processAddUpdateOccupancy(occupancy) {
            const formattedDate = moment(occupancy.startDate).format('YYYY-MM-DD');
            occupancy.startDate = formattedDate;
            let action: ng.IPromise<any> = null;
            if (occupancy.id) {
                action = this.BuildingService.updateOccupancy(occupancy);
            } else {
                action = this.BuildingService.createOccupancy(occupancy);
            }
            return action
                .then(() => {
                    this.updateOccupancyListAndCurrent();
                    this.Messages.success('Occupancy updated');
                }).catch(() => {
                    this.Messages.error('Error updating occupancy');
                });
        }
    }

    angular
        .module('properties')
        .controller('BuildingDetailOccupancyCtrl', BuildingDetailOccupancyCtrl);
}
